/* eslint-disable import/no-extraneous-dependencies */
import { CellWithLink } from '@components/table'
import React from 'react'
import { Asset } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { AssetRoutes } from '@lib/router'

interface Props {
  asset: Asset
}

export function PolicyLinkCell({ asset }: Props) {
  const displayedValue = asset.name || asset.awsId
  const displayedLabel = asset.name ? asset.awsId : asset.name
  const itemUrl = AssetRoutes.assetId(asset.id)
  return (
    <CellWithLink
      href={itemUrl}
      value={displayedValue}
      label={displayedLabel}
    />
  )
}
