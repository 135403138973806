export const FORM_FIELDS = {
  REPORT_TYPE: 'reportType',
  NAME: 'name',
  DESCRIPTION: 'description',
  OVA_ACCOUNTS: 'ovaAccounts',
  BACKUP_PROVIDER: 'backupProvider',
  RECIPIENTS: 'recipients',
  FREQUENCY: 'frequency',
  START_TIME_OF_DATA_RANGE: 'startTimeOfDataRange',
  RANGE_OF_DATA: 'rangeOfData',
  FORMATS: 'formats',
  CUSTOM_SCHEDULE: 'customSchedule',
} as const
