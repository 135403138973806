import { ListThreatsForAssetClient } from '@lib/clients/threats/list-threats-for-asset'
import SystemHelper from '@lib/helpers/system.helper'
import { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Threat } from 'blues-corejs/dist'
import { ObjHelper } from '@lib/helpers'

const threats = new ListThreatsForAssetClient()

function useThreatsByIds(threatsIds: Array<string>) {
  const [isLoadingThreats, setIsLoadingThreats] = useState(false)
  const [dataThreats, setDataThreats] = useState<Array<Threat>>([])

  const previousThreatsIds = useRef<Array<string>>([])

  const fetchDataThreats = async (isUpdateData = false) => {
    if (!threatsIds.length) {
      return
    }

    const isSameThreatIds = ObjHelper.isEqual(
      previousThreatsIds.current,
      threatsIds
    )
    if (isSameThreatIds && !isUpdateData) {
      return
    }
    if (!isUpdateData) {
      setIsLoadingThreats(true)
    }
    try {
      const threatsResponse = await threats.listThreats(null, threatsIds)
      previousThreatsIds.current = threatsIds
      setDataThreats(threatsResponse.threatsList)
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        `Something went wrong in ${__filename} : ${error}`
      )
    } finally {
      if (!isUpdateData) {
        setIsLoadingThreats(false)
      }
    }
  }

  const fetchThreatsWithoutLoader = () => {
    fetchDataThreats(true)
  }

  useEffect(() => {
    fetchDataThreats()

    return () => {
      setDataThreats([])
    }
  }, [threatsIds.length])

  return {
    isLoadingThreats,
    dataThreats,
    fetchThreatsWithoutLoader,
  }
}

export default useThreatsByIds
