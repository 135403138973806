import { SvgIcon, SvgIconProps } from '@mui/material'

const AssetsActive = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        fill: 'none',
        strokeWidth: 0,
        ...props.sx,
      }}
      {...props}
    >
      <g clipPath="url(#clip0_40000070_6785)">
        <path
          d="M19 13H5C3.9 13 3 13.9 3 15V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V15C21 13.9 20.1 13 19 13ZM7 19C5.9 19 5 18.1 5 17C5 15.9 5.9 15 7 15C8.1 15 9 15.9 9 17C9 18.1 8.1 19 7 19ZM19 3H5C3.9 3 3 3.9 3 5V9C3 10.1 3.9 11 5 11H19C20.1 11 21 10.1 21 9V5C21 3.9 20.1 3 19 3ZM7 9C5.9 9 5 8.1 5 7C5 5.9 5.9 5 7 5C8.1 5 9 5.9 9 7C9 8.1 8.1 9 7 9Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_40000070_6785">
          <rect width={size} height={size} />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default AssetsActive
