import { styled, Typography } from '@mui/material'

const PROPS_TO_NOT_FORWARD = ['selected']

interface StyledScanDateProps {
  selected: boolean
}

export const StyledScanDate = styled(Typography, {
  shouldForwardProp: (prop) => !PROPS_TO_NOT_FORWARD.includes(prop as string),
})<StyledScanDateProps>(({ theme, selected }) => {
  return {
    fontSize: '14px',
    width: '100%',
    variant: 'caption',
    padding: '8px',
    fontWeight: '500',
    cursor: 'pointer',
    color: theme.palette.blue['500'],
    backgroundColor: selected ? theme.palette.blue['50'] : 'transparent',
  }
})
