import { Box, Stack, Typography } from '@mui/material'
import { VIRow } from 'ui-v2/src/lib/engine-types'
import CopyCommandButton from './copy-command-button'
import { CopyButtonVariant } from 'ui-v2/src/lib/models/settings/sources/sources'

const CopyButtonBlocks = ({
  blockHeader,
  blockText,
  blockToastText,
  commands,
}: CopyButtonBlocksProps) => {
  return (
    <Stack spacing={2}>
      <Typography fontSize={16} fontWeight={600}>
        {blockHeader}
      </Typography>
      {!!blockText && (
        <Typography variant="body2" fontWeight={600} color="text.secondary">
          Optionally you can copy the command below and run it to deploy CFN
        </Typography>
      )}
      <Box display={'flex'} flexWrap={'wrap'} gap={1.5}>
        {commands.map((command) => (
          <CopyCommandButton
            key={command.type}
            commandText={command.name}
            variant={command.type ?? CopyButtonVariant.DEFAULT}
            disabled={!!command.disabled}
            toastText={blockToastText}
          />
        ))}
      </Box>
    </Stack>
  )
}
interface CopyButtonBlocksProps {
  blockHeader: string
  blockText?: string
  blockToastText: string
  commands: VIRow
}
export default CopyButtonBlocks
