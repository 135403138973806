"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportGenerated = void 0;
const generated_kind_1 = require("./generated-kind");
class ReportGenerated {
    #id;
    #origin;
    #status;
    #reportKindWithParams;
    #availableFormatsList;
    #createdAt;
    #updatedAt;
    constructor(args) {
        this.#id = args.id;
        this.#origin = args.origin;
        this.#status = args.status;
        this.#reportKindWithParams = args.reportKindWithParams;
        this.#availableFormatsList = args.availableFormatsList;
        this.#createdAt = args.createdAt;
        this.#updatedAt = args.updatedAt;
    }
    get id() {
        return this.#id;
    }
    get origin() {
        return this.#origin;
    }
    get status() {
        return this.#status;
    }
    get reportKindWithParams() {
        return this.#reportKindWithParams;
    }
    get availableFormatsList() {
        return this.#availableFormatsList;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    getReadableReportType() {
        const reportKindWithParams = this.#reportKindWithParams;
        if (!reportKindWithParams) {
            return '';
        }
        switch (reportKindWithParams.kind) {
            case generated_kind_1.ReportKindCase.AWS_ACCOUNT_SUMMARY:
                return 'AWS Account Summary';
            case generated_kind_1.ReportKindCase.AWS_COMPLETED_SCANS:
                return 'AWS All Scans';
            case generated_kind_1.ReportKindCase.AWS_SCANNED_ASSETS_SUMMARY:
                return 'AWS Assets Scanned';
            case generated_kind_1.ReportKindCase.AWS_INFECTED_ASSETS:
                return 'AWS Infected Assets';
            case generated_kind_1.ReportKindCase.INFECTED_FILES:
                return 'AWS Infected Files';
            case generated_kind_1.ReportKindCase.FAILED_SCANS:
                return 'AWS Failed Scans';
            case generated_kind_1.ReportKindCase.OVA_MSP_OPERATIONAL:
                return 'OVA Operational Report';
            case generated_kind_1.ReportKindCase.OVA_MSP_EXECUTIVE:
                return 'OVA Ransomware Readiness';
            case generated_kind_1.ReportKindCase.AWS_EXECUTIVE:
                return 'AWS Ransomware Readiness';
            case generated_kind_1.ReportKindCase.AWS_TENANT_SUMMARY:
                return 'AWS Operational Summary';
            case generated_kind_1.ReportKindCase.OVA_MSP_TENANT_SUMMARY:
                return 'OVA Operational Summary';
            case generated_kind_1.ReportKindCase.OVA_MSP_ACCOUNT_SUMMARY:
                return 'OVA Account Summary';
            case generated_kind_1.ReportKindCase.OVA_MSP_COMPLETED_SCANS:
                return 'OVA All Scans';
            case generated_kind_1.ReportKindCase.OVA_MSP_SCANNED_ASSETS_SUMMARY:
                return 'OVA Assets Scanned';
            case generated_kind_1.ReportKindCase.OVA_MSP_INFECTED_ASSETS:
                return 'OVA Infected Assets';
            case generated_kind_1.ReportKindCase.OVA_MSP_INFECTED_FILES:
                return 'OVA Infected Files';
            case generated_kind_1.ReportKindCase.OVA_MSP_FAILED_SCANS:
                return 'OVA Failed Scans';
            default:
                return '';
        }
    }
    getReadableReportDescription() {
        const reportKindWithParams = this.#reportKindWithParams;
        if (!reportKindWithParams) {
            return '';
        }
        switch (reportKindWithParams.kind) {
            case generated_kind_1.ReportKindCase.AWS_ACCOUNT_SUMMARY:
                return 'Summary of scanned assets by account (PDF)';
            case generated_kind_1.ReportKindCase.AWS_COMPLETED_SCANS:
                return 'Detailed information about all scans to monitor Elastio`s protection  (CSV)';
            case generated_kind_1.ReportKindCase.AWS_SCANNED_ASSETS_SUMMARY:
                return 'Detailed information about protected assets (CSV)';
            case generated_kind_1.ReportKindCase.AWS_INFECTED_ASSETS:
                return 'Comprehensive list of compromised assets for targeted remediation efforts (CSV)';
            case generated_kind_1.ReportKindCase.INFECTED_FILES:
                return 'Comprehensive list of compromised files for targeted remediation efforts (CSV)';
            case generated_kind_1.ReportKindCase.FAILED_SCANS:
                return 'Detailed information about any failed scans to support troubleshooting (CSV)';
            case generated_kind_1.ReportKindCase.OVA_MSP_OPERATIONAL:
                return 'Executive Summary on the results and performance of Elastio’s scans';
            case generated_kind_1.ReportKindCase.OVA_MSP_EXECUTIVE:
                return 'Executive Summary on your business’ ransomware readiness posture and risk of data loss (PDF)';
            case generated_kind_1.ReportKindCase.AWS_EXECUTIVE:
                return 'Executive Summary on your business’ ransomware readiness posture and risk of data loss (PDF)';
            case generated_kind_1.ReportKindCase.AWS_TENANT_SUMMARY:
                return 'A high-level overview of scanning outcomes, active threats, and protected data over the reporting period, helping you quickly assess security posture and operational effectiveness (PDF)';
            case generated_kind_1.ReportKindCase.OVA_MSP_TENANT_SUMMARY:
                return 'A high-level overview of scanning outcomes, active threats, and protected data over the reporting period, helping you quickly assess security posture and operational effectiveness (PDF)';
            case generated_kind_1.ReportKindCase.OVA_MSP_ACCOUNT_SUMMARY:
                return 'Consolidated overview of account details for resource management and usage evaluation (PDF)';
            case generated_kind_1.ReportKindCase.OVA_MSP_COMPLETED_SCANS:
                return 'Detailed information about all scans to monitor Elastio`s protection (CSV)';
            case generated_kind_1.ReportKindCase.OVA_MSP_SCANNED_ASSETS_SUMMARY:
                return 'Detailed information about protected assets (CSV)';
            case generated_kind_1.ReportKindCase.OVA_MSP_INFECTED_ASSETS:
                return 'Comprehensive list of compromised assets for targeted remediation efforts (CSV)';
            case generated_kind_1.ReportKindCase.OVA_MSP_INFECTED_FILES:
                return 'Comprehensive list of compromised files for targeted remediation efforts (CSV)';
            case generated_kind_1.ReportKindCase.OVA_MSP_FAILED_SCANS:
                return 'Detailed information about any failed scans to support troubleshooting (CSV)';
            default:
                return '';
        }
    }
}
exports.ReportGenerated = ReportGenerated;
