import { useCallback, useEffect, useRef, useState } from 'react'

interface LongPressOptions {
  onHoldComplete: () => void
  holdDuration?: number
}

interface LongPressState {
  progress: number
  isHolding: boolean
}

interface LongPressHandlers {
  onMouseDown: () => void
  onMouseUp: () => void
  onMouseLeave: () => void
}

const INITIAL_HOLD_DURATION = 1000
const INTERVAL_DURATION = 20

/**
 * useLongPress Hook
 *
 * Detects a long press action and triggers a callback upon completion.
 * Could be extended and reused for other components, if so - consider moving to a shared location.
 *
 * @param {Object} options - Configuration options.
 * @param {Function} options.onHoldComplete - Callback to execute when hold completes.
 * @param {number} [options.holdDuration=1000] - Duration (in ms) to detect a hold.
 * @returns {Object} Event handlers and progress state.
 */
function useLongPress({
  onHoldComplete,
  holdDuration = INITIAL_HOLD_DURATION,
}: LongPressOptions): LongPressHandlers & LongPressState {
  if (holdDuration <= 0) {
    console.warn('holdDuration must be a positive number.')
  }

  const [progress, setProgress] = useState(0)
  const [isHolding, setIsHolding] = useState(false)
  const actionCompleted = useRef(false)

  const resetProgress = useCallback(() => {
    setProgress(0)
    setIsHolding(false)
  }, [])

  const handleMouseDown = useCallback(() => {
    setIsHolding(true)
    setProgress(0)
    actionCompleted.current = false
  }, [])

  const handleMouseUp = useCallback(() => {
    resetProgress()
  }, [])

  const handleActionComplete = useCallback(() => {
    if (actionCompleted.current) {
      return
    }

    onHoldComplete()
    actionCompleted.current = true
    resetProgress()
  }, [resetProgress, onHoldComplete])

  useEffect(() => {
    if (!isHolding) {
      return
    }

    const incrementProgress = () => {
      setProgress((prevProgress) => {
        const newProgress =
          prevProgress + 100 / (holdDuration / INTERVAL_DURATION)
        if (newProgress >= 100) {
          if (!actionCompleted.current) {
            handleActionComplete()
          }
          return 100
        }
        return newProgress
      })
    }

    const intervalId = setInterval(incrementProgress, INTERVAL_DURATION)

    return () => {
      clearInterval(intervalId)
    }
  }, [isHolding, handleActionComplete])

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseUp,
    progress,
    isHolding,
  }
}

export { useLongPress }
