/* eslint-disable import/no-extraneous-dependencies */
import { EBS, EC2, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function computeAssetType(asset: Asset): string {
  if (asset instanceof EC2) {
    return 'EC2'
  }

  if (asset instanceof EBS) {
    return 'EBS'
  }

  if (asset instanceof EFS) {
    return 'EFS'
  }

  if (asset instanceof S3Bucket) {
    return 'S3 Bucket'
  }

  if (asset instanceof GenericHost) {
    return 'Generic Host'
  }

  sentryReThrowCatchHandler('computeAssetType: Unknown asset type')
  throw new Error('computeAssetType: Unknown asset type')
}
