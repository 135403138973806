/* eslint-disable import/no-extraneous-dependencies */
import { GenericHostProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { GenericHost } from 'blues-corejs/dist'

type HostPt = GenericHostProto.AsObject

export class GenericHostTransformer {
  #genericHost: HostPt

  constructor(genericHost: HostPt) {
    this.#genericHost = genericHost
  }

  transform(): GenericHost {
    return this.#transformGenericHost(this.#genericHost)
  }

  #transformGenericHost(genericHost: HostPt): GenericHost {
    return new GenericHost({
      id: genericHost.id,
      hostname: genericHost.hostname,
      osName: genericHost.osName,
      cpuCoresCount: genericHost.cpuCoresCount,
      retentionPolicy: genericHost.retentionPoliciesList,
      createdAt: secondsToDate(genericHost.createdAt?.seconds),
      updatedAt: secondsToDate(genericHost.updatedAt?.seconds),
    })
  }
}
