import { useDispatch, useSelector } from 'react-redux'
import {
  getS3AssetsSelectAllFilters,
  getS3AssetsSelectFilters,
  getS3AssetsSelectSearch,
} from '@store/selectors/assets-select.selector'
import ValueInterface from '@lib/interfaces/value.interface'
import {
  setS3AssetsSelectFilters,
  setS3AssetsSelectSearch,
} from '@store/actions/assets-select.action'

export function useS3Filter() {
  const dispatch = useDispatch()

  const possibleFilters = useSelector(getS3AssetsSelectAllFilters)
  const selectedFilters = useSelector(getS3AssetsSelectFilters)
  const searchVal = useSelector(getS3AssetsSelectSearch)

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    dispatch(setS3AssetsSelectFilters(newFilters))
  }

  const handleSearchChange = (value: string) => {
    dispatch(setS3AssetsSelectSearch(value))
  }

  return {
    possibleFilters,
    selectedFilters,
    searchVal,
    onFiltersChange,
    handleSearchChange,
  }
}
