import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'

const buildDefaultFormData = (): FormPeriodInterface => ({
  period: [
    {
      periodValue: 'All',
      countDuration: '',
      duration: '',
      removable: false,
    },
  ],
})

export default buildDefaultFormData
