/* eslint-disable import/no-extraneous-dependencies */
import { AssetWithRelatedAssets } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { EFS } from '@lib/models/assets'
import { useDispatch } from 'react-redux'
import {
  findAssetById,
  getDataForSelectedAllAssetsWithPaths,
} from '@features/policies/policy/policy-tables'
import SystemHelper from '@lib/helpers/system.helper'
import {
  addEfsSelectedAsset,
  removeEfsSelectedAsset,
  setAllEfsSelectedAssets,
} from '@store/actions/assets-select.action'
import { SelectAllPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import { EmptyAllEfsSelectedAssets } from '@lib/constants/app.constant'

interface Props {
  efsAssets: Array<AssetWithRelatedAssets<EFS>>
  onSelectAllPaths: (params: SelectAllPathsInterface) => void
}

export function useEfsSelectRows({ efsAssets, onSelectAllPaths }: Props) {
  const dispatch = useDispatch()

  const handleEfsSelect = ({
    assetId,
    checked,
  }: {
    assetId: string
    checked: boolean
  }) => {
    const foundAsset = findAssetById({
      assets: efsAssets,
      assetId: assetId,
    })
    if (!foundAsset) {
      SystemHelper.throwErrorInLocalEnv(
        'Cannot find an EFS in the assets selector in EfsForPolicy'
      )
      return
    }
    let actionToRun = addEfsSelectedAsset
    if (!checked) {
      onSelectAllPaths({
        assetId: assetId,
        cbValue: true,
      })
      actionToRun = removeEfsSelectedAsset
    } else {
      onSelectAllPaths({
        assetId: assetId,
        cbValue: false,
      })
    }
    dispatch(actionToRun(foundAsset))
    return
  }

  const handleSelectAllEfsRows = (isSelected: boolean) => {
    if (isSelected) {
      dispatch(
        setAllEfsSelectedAssets(
          getDataForSelectedAllAssetsWithPaths({ assets: efsAssets })
        )
      )
      return
    }

    dispatch(setAllEfsSelectedAssets(EmptyAllEfsSelectedAssets()))
  }

  return {
    handleEfsSelect,
    handleSelectAllEfsRows,
  }
}
