import { BaseRoutes } from '../base'
import { RouteFragments } from '@lib/router/routes/asset/asset'

export class PublicRoutes extends BaseRoutes {

  public static get relogin(): string {
    return this.#RELOGIN
  }

    public static get initError(): string {
        return this.#INIT_ERROR
    }

    public static get accessDenied(): string {
        return this.#ACCESS_DENIED
    }

    public static get pageNotFound(): string {
        return this.#PAGE_NOT_FOUND
    }

    public static get e2eHost(): string {
        return this.#E2E_HOST
    }

    public  static get logout(): string {
      return this.#LOGOUT
    }

    public  static get verification(): string {
        return this.#VERIFICATION
    }

    public static get verificationStatusIdPlaceholder(): string {
        return this.verificationStatusBuildRoute(RouteFragments.DYNAMIC_ID)
    }

    public static verificationStatusId(statusId: string): string {
        return this.verificationStatusBuildRoute(this.base64Encode(statusId))
    }


    public  static get verificationLoading(): string {
        return this.#VERIFICATION_LOADING
    }

    private static verificationStatusBuildRoute(...fragments: Array<string>): string {
        return `${this.#VERIFICATION}/${fragments.join('/')}`
    }

    static #RELOGIN = '/relogin'

    static #INIT_ERROR = '/init-error'

    static #ACCESS_DENIED = '/access-denied'

    static #E2E_HOST = '/e2e-host'

    static #LOGOUT = '/api/auth/logout'

    static #PAGE_NOT_FOUND = '*'

    static #VERIFICATION = '/verification'


    static #VERIFICATION_LOADING = '/verification-loading'
}
