import Divider from '@mui/material/Divider'
import { Box, Skeleton } from '@mui/material'

export function RpoRetentionSkeletonLoader() {
  return (
    <table width="100%">
      <tbody>
        <tr>
          <td colSpan={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="4px"
            >
              <Skeleton width={28} height={32} />
              <Skeleton width={33} height={26} />
              <Skeleton width={80} height={26} />
            </Box>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tr>
          <td>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display="flex" alignItems="center" gap="4px">
                <Skeleton width={28} height={35} />
                <Skeleton width={33} height={26} />
              </Box>
              <Skeleton width={86} height={19} />
            </Box>
          </td>
          <td>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display="flex" alignItems="center" gap="4px">
                <Skeleton width={28} height={35} />
                <Skeleton width={33} height={26} />
              </Box>
              <Skeleton width={86} height={19} />
            </Box>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
