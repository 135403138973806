import { useShallow } from 'zustand/react/shallow'
import { useCloudConnectorJobsFiltersStore } from '@features/jobs/cloud-connector-jobs/stores/filters-store/cloud-connector-jobs-filters-store'

const getSelectedFilters = () => ({
  selectedFilters: useCloudConnectorJobsFiltersStore.getState().selectedFilters,
  timeRange: useCloudConnectorJobsFiltersStore.getState().timeRange,
})

const useCloudConnectorJobsTimerange = () =>
  useCloudConnectorJobsFiltersStore(useShallow((state) => state.timeRange))

const usePossibleCloudConnectorJobFilters = () =>
  useCloudConnectorJobsFiltersStore(
    useShallow((state) => state.possibleFilters)
  )

const useSelectedCloudConnectorJobFilters = () =>
  useCloudConnectorJobsFiltersStore(
    useShallow((state) => state.selectedFilters)
  )

const useIsCloudConnectorFiltersNotEmpty = () => {
  const selectedFilters = useCloudConnectorJobsFiltersStore(
    (state) => state.selectedFilters
  )

  return Object.values(selectedFilters).some((filter) => filter.size > 0)
}

const useShouldDisableFilterButton = () => {
  const possibleFilters = useCloudConnectorJobsFiltersStore(
    (state) => state.possibleFilters
  )

  return Object.values(possibleFilters).every((filter) => filter.size === 0)
}

const useCloudConnectorJobsFiltersActions = () =>
  useCloudConnectorJobsFiltersStore((state) => state.actions)

export {
  useCloudConnectorJobsTimerange,
  usePossibleCloudConnectorJobFilters,
  useSelectedCloudConnectorJobFilters,
  useIsCloudConnectorFiltersNotEmpty,
  useShouldDisableFilterButton,
  useCloudConnectorJobsFiltersActions,
  getSelectedFilters,
}
