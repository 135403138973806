import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PagePathConstant } from '@lib/constants'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grow from '@mui/material/Grow'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ArrowDropDownBtnIcon from '@inline-img/general/arrow-drop-down-btn-icon'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface Props {
  updatePolicyDisabled: boolean
  onUpdatePolicy: (runPolicy: boolean) => void
}

export function EditPolicyMenu({
  onUpdatePolicy,
  updatePolicyDisabled,
}: Props) {
  const router = useNavigation()

  const handleBack = useCallback(
    () => router.push(PagePathConstant.POLICIES),
    []
  )

  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = useCallback(() => {
    setIsOpen((prevOpen) => !prevOpen)
  }, [])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen)

  useEffect(() => {
    if (prevOpen.current === true && !isOpen) {
      anchorRef.current?.focus()
    }
    prevOpen.current = isOpen
  }, [isOpen])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setIsOpen(false)
  }

  return (
    <Box className="portalBetweenButtons">
      <Box>
        <Button
          onClick={handleBack}
          variant="text"
          color="primary"
          className="showButton jsCloseEditPolicyPage"
        >
          Cancel
        </Button>
      </Box>

      <Stack direction="row" className="buttonDropdown">
        <Button
          className="mainBtn jsSaveRunPolicyButton"
          id="mainsave"
          disabled={updatePolicyDisabled}
          onClick={() => onUpdatePolicy(true)}
          variant="contained"
          color="primary"
        >
          Save & Run
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          className="dropdownBtn"
          disabled={updatePolicyDisabled}
          ref={anchorRef}
          id="secondary-save"
          aria-controls={isOpen ? 'save-menu' : undefined}
          aria-expanded={isOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ArrowDropDownBtnIcon />
        </Button>
        <Popper
          open={isOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'right bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={isOpen}
                    id="save-menu"
                    aria-labelledby="secondary-save"
                  >
                    <MenuItem onClick={() => onUpdatePolicy(false)}>
                      Save
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
    </Box>
  )
}
