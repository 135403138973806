import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { NotificationsClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useNotificationSettingsQuery() {
  const notificationsClient = useGrpcClient(NotificationsClient)
  return useQuery({
    queryKey: ['notifications', 'notification-settings'],
    queryFn: () => {
      if (!notificationsClient) {
        throw new Error('Notifications client not initialized')
      }
      return notificationsClient.getCustomerNotificationSettings()
    },
    enabled: !!notificationsClient,
  })
}
