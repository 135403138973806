import { DateRangeTuple, EngineCallback } from '@lib/engine-types'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import moment from 'moment'
import styles from './styles.module.css'
import Stack from '@mui/material/Stack'
import DateRangeSelect from '@components/components-composite/date-range-select/DateRangeSelect'
import React from 'react'

interface DateRangePickerOwnProps {
  onDateChange: EngineCallback<DateRangeTuple>
  dateRange: DateRangeTuple
}

function DateRangePicker({ onDateChange, dateRange }: DateRangePickerOwnProps) {
  const handleDateRangeChange = (newDateRange: DateRangeTuple) => {
    onDateChange(newDateRange)
  }

  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()

  const minMaxRange: DateRangeTuple = [
    moment().tz(timeZone.value).subtract(1, 'year').startOf('month'),
    moment().endOf('day').tz(timeZone.value),
  ]

  return (
    <Stack direction="row" alignItems="flex-start">
      <DateRangeSelect
        className={styles.root}
        endLabel=""
        label=""
        onChange={handleDateRangeChange}
        value={dateRange}
        minMaxRange={minMaxRange}
      />
    </Stack>
  )
}

export default DateRangePicker
