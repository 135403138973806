/* eslint-disable import/no-extraneous-dependencies */
import { ListScansClient } from '@lib/clients/scans/list-scans'
import { ListScans } from '@lib/clients/scans/types'
import { ScansForBackup } from 'blues-corejs/dist/use_cases/list_recovery_points_for_asset_item_generic/types'
import SystemHelper from '@lib/helpers/system.helper'
import { useEffect, useState } from 'react'
import { Nullable } from '@lib/engine-types'
import { DEFAULT_SCANS_FOR_BACKUP } from './types'

const scanClient = new ListScansClient()

type UseScansForAssetItemsRpOutput = {
  dataScans: Nullable<ScansForBackup>
  isScansLoading: boolean
}

function useFetchScansByIds(
  scanIds: Array<string>
): UseScansForAssetItemsRpOutput {
  const [isScansLoading, setIsScansLoading] = useState(false)
  const [dataScans, setDataScans] = useState<Nullable<ScansForBackup>>(null)

  useEffect(() => {
    async function fetchDataScans() {
      if (!scanIds.length) {
        setDataScans(null)
        return
      }

      setIsScansLoading(true)
      try {
        let scansResponse: ListScans = {
          filesystemChecks: [],
          malwares: [],
          ransomwares: [],
        }

        scansResponse = await scanClient.listScans(scanIds)

        const scans: ScansForBackup = {
          malwareScansForBackup: [],
          ransomWareScansForBackup: [],
          fsCheckScansForBackup: [],
        }

        scansResponse.malwares.forEach((scan) =>
          scans.malwareScansForBackup.push(scan)
        )
        scansResponse.ransomwares.forEach((scan) =>
          scans.ransomWareScansForBackup.push(scan)
        )
        scansResponse.filesystemChecks.forEach((scan) =>
          scans.fsCheckScansForBackup.push(scan)
        )

        if (!scans) {
          setDataScans(null)
        }
        setDataScans(scans)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong in ${__filename}: ${error}`
        )
      } finally {
        setIsScansLoading(false)
      }
    }

    fetchDataScans()

    return () => {
      setDataScans(DEFAULT_SCANS_FOR_BACKUP)
    }
  }, [scanIds.length])

  return {
    dataScans,
    isScansLoading,
  }
}

export default useFetchScansByIds
