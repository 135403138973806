import { Nullable } from '@lib/engine-types'
import { useState } from 'react'

export enum CollapsebleFields {
  TAGS = 'tags',
  STATE = 'state',
}

interface UseCollapsedFieldsOutput {
  handleChipClick: (updatedCollapsedState: Nullable<CollapsebleFields>) => void
  getIsFieldCollapsed: (field: CollapsebleFields) => boolean
}

export function useCollapsedFields(): UseCollapsedFieldsOutput {
  const [collapsedState, setCollapsedState] =
    useState<Nullable<CollapsebleFields>>(null)

  function getIsFieldCollapsed(field: CollapsebleFields) {
    return collapsedState === field
  }

  function handleChipClick(updatedCollapsedState: Nullable<CollapsebleFields>) {
    setCollapsedState(updatedCollapsedState)
  }

  return {
    handleChipClick,
    getIsFieldCollapsed,
  }
}
