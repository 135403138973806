export const COLUMN_SIZES = {
  SCHEDULE_NAME: 80,
  DESCRIPTION: 60,
  REPORT_TYPE: 60,
  RANGE_OF_DATA: 60,
  GENERATED: 60,
  HISTORY_ACTIONS: 40,
} as const

export const COLUMN_IDS = {
  SCHEDULE_NAME: 'schedule-name',
  DESCRIPTION: 'description',
  REPORT_TYPE: 'report-type',
  RANGE_OF_DATA: 'range-of-data',
  GENERATED: 'generated',
  HISTORY_ACTIONS: 'history-actions',
} as const

export const COLUMN_HEADERS = {
  SCHEDULE_NAME: 'Schedule Name',
  DESCRIPTION: 'Description',
  REPORT_TYPE: 'Report Type',
  RANGE_OF_DATA: 'Range of Data',
  GENERATED: 'Generated',
} as const

export const COLUMN_ACCESSOR = {
  SCHEDULE_NAME: 'scheduleName',
  DESCRIPTION: 'description',
  REPORT_TYPE: 'reportType',
  RANGE_OF_DATA: 'rangeOfData',
  GENERATED: 'generated',
} as const
