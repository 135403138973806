"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobModel = exports.JobStatusConstant = void 0;
exports.createFakeJob = createFakeJob;
var JobStatusConstant;
(function (JobStatusConstant) {
    JobStatusConstant[JobStatusConstant["UNDEFINED"] = -1] = "UNDEFINED";
    JobStatusConstant[JobStatusConstant["SCHEDULED"] = 0] = "SCHEDULED";
    JobStatusConstant[JobStatusConstant["STARTING"] = 1] = "STARTING";
    JobStatusConstant[JobStatusConstant["IN_PROGRESS"] = 2] = "IN_PROGRESS";
    JobStatusConstant[JobStatusConstant["FAILED"] = 3] = "FAILED";
    JobStatusConstant[JobStatusConstant["SUCCEEDED"] = 4] = "SUCCEEDED";
    JobStatusConstant[JobStatusConstant["ABORTED"] = 5] = "ABORTED";
    JobStatusConstant[JobStatusConstant["CUSTOM"] = 6] = "CUSTOM";
})(JobStatusConstant || (exports.JobStatusConstant = JobStatusConstant = {}));
class JobModel {
    createdAt;
    finishedAt;
    status;
    kind;
    assetId;
    progressPercent;
    childrenList;
    constructor({ createdAt, finishedAt, status, kind, assetId, progressPercent, childrenList, }) {
        this.createdAt = createdAt;
        this.finishedAt = finishedAt;
        this.status = status;
        this.kind = kind;
        this.assetId = assetId;
        this.progressPercent = progressPercent;
        this.childrenList = childrenList;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getFinishedAt() {
        return this.finishedAt;
    }
    getStatus() {
        return this.status;
    }
    getKind() {
        return this.kind;
    }
    getAssetId() {
        return this.assetId;
    }
    getProgressPercent() {
        return this.progressPercent;
    }
    getChildrenList() {
        return this.childrenList;
    }
    isJobFinished() {
        const finishedStatuses = [
            JobStatusConstant.SUCCEEDED,
            JobStatusConstant.ABORTED,
        ];
        return finishedStatuses.includes(this.status);
    }
    isJobInProgress() {
        const inProgressStatuses = [
            JobStatusConstant.IN_PROGRESS,
            JobStatusConstant.CUSTOM,
        ];
        return inProgressStatuses.includes(this.status);
    }
    isJobFailed() {
        return this.status === JobStatusConstant.FAILED;
    }
    isJobScheduled() {
        return this.status === JobStatusConstant.SCHEDULED;
    }
}
exports.JobModel = JobModel;
// Adding depth to createFakeJob to make sure we don't get an infinite loop
function createFakeJob(depth = 0) {
    const randomStatus = Math.floor(Math.random() * 6);
    const startDate = new Date('2000-01-01').getTime();
    const endDate = new Date('2023-12-31').getTime();
    const randomFinishedAt = randomStatus === JobStatusConstant.SUCCEEDED
        ? Number(new Date(startDate + Math.random() * (endDate - startDate)))
        : null;
    let childrenList = [];
    if (depth < 2) {
        childrenList =
            Math.random() > 0.5
                ? [createFakeJob(depth + 1), createFakeJob(depth + 1)]
                : [];
    }
    return new JobModel({
        createdAt: Number(new Date(startDate + Math.random() * (endDate - startDate))),
        finishedAt: randomFinishedAt,
        assetId: `vol-${Math.floor(Math.random() * 10000000000)}`,
        kind: Math.floor(Math.random() * 50),
        progressPercent: Math.floor(Math.random() * 100),
        status: Math.floor(Math.random() * 6),
        childrenList: childrenList,
    });
}
