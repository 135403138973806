import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import {
  EntropyTrendsFilters,
  EntropyTrendsTable,
  EntropyTrendsGraph,
} from './components'
import { useEntropyTrendsBreadcrumbs } from './use-entropy-trends-breadcrums'
import { useEntropyFiltersActions } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'
import { useSelector } from 'react-redux'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'

interface Props {
  getInitialTimeRange: () => {
    startAt: number
    endAt: number
  }
}

export default function EntropyTrendsPage({ getInitialTimeRange }: Props) {
  const { startAt, endAt } = getInitialTimeRange()
  useEntropyTrendsBreadcrumbs()

  const { fetchInitialEntropyTrendsHistory, resetState } =
    useEntropyFiltersActions()

  const liveAssetsCriteria = useSelector(getLiveAssetsCriteria)
  useEffect(() => {
    fetchInitialEntropyTrendsHistory(
      {
        startAt,
        endAt,
      },
      liveAssetsCriteria
    )

    return () => {
      resetState()
    }
  }, [])

  return (
    <Grid container padding="24px" gap="24px" height="100%">
      <Grid item container xs={12}>
        <EntropyTrendsGraph />
      </Grid>
      <Grid item container gap="24px" height="100%" minHeight="430px">
        <Grid item xs={3} minWidth="355px">
          <EntropyTrendsFilters />
        </Grid>
        <Grid item xs>
          <EntropyTrendsTable />
        </Grid>
      </Grid>
    </Grid>
  )
}
