import { NUMBER_OF_INTERVALS } from '@lib/constants/backup-timeline.constant'
import JobStatusConstant from '@lib/constants/jobs/job-status.constant'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { VIMatrix } from '@lib/engine-types'
import FilterFactory from '@lib/factories/filter.factory'
import TimeHelper from '@lib/helpers/time.helper'
import {
  BackupTimelineChartDataPoint,
  BackupTimelineDataPoint,
  CountsList,
} from '@lib/interfaces/backup-timeline.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import JobModel from '@lib/models/job.model'

export abstract class BackupTimelineFactory {
  static groupData(
    jobs: Array<JobModel>,
    periodInHours: number
  ): Array<BackupTimelineDataPoint> {
    const periodEnd = TimeHelper.momentNow()
    const periodStart = TimeHelper.currentMinusHours(periodInHours)
    const intervalDiff = Math.floor(
      periodEnd.diff(periodStart) / NUMBER_OF_INTERVALS
    )

    const result = []

    for (let i = 0; i < NUMBER_OF_INTERVALS; i++) {
      const from = periodStart.valueOf() + intervalDiff * i
      const to = periodStart.valueOf() + intervalDiff * (i + 1)
      const dataPoint: BackupTimelineDataPoint = {
        from,
        to,
        jobs: jobs.filter((job) => job.createdAt >= from && job.createdAt < to),
      }

      result.push(dataPoint)
    }

    return result
  }

  static toChartData(
    groupedData: Array<BackupTimelineDataPoint>,
    selectedPeriodInHours: number
  ): Array<BackupTimelineChartDataPoint> {
    const timestampFormatFunction =
      selectedPeriodInHours > 24
        ? (timestamp: number) => [
            TimeHelper.timestampFormat(
              timestamp ?? 0,
              TimeFormatConstants.TIME_FORMAT
            ),
            TimeHelper.timestampFormat(
              timestamp ?? 0,
              TimeFormatConstants.ONLY_DATE_FORMAT
            ),
          ]
        : (timestamp: number) =>
            TimeHelper.timestampFormat(
              timestamp ?? 0,
              TimeFormatConstants.TIME_FORMAT
            )

    return groupedData.map((dataPoint) => ({
      timestamp: timestampFormatFunction(dataPoint.from),
      success: dataPoint.jobs.filter(
        (job) => job.status === JobStatusConstant.SUCCEEDED
      ).length,
      warning: dataPoint.jobs.filter(
        (job) => job.status === JobStatusConstant.ABORTED
      ).length,
      failed: dataPoint.jobs.filter(
        (job) => job.status === JobStatusConstant.FAILED
      ).length,
      inProgress: dataPoint.jobs.filter(
        (job) => job.status === JobStatusConstant.IN_PROGRESS
      ).length,
    }))
  }

  static toStatsChartData(
    statsData: CountsList,
    selectedPeriodInHours: number
  ): Array<BackupTimelineChartDataPoint> {
    const timestampFormatFunction =
      selectedPeriodInHours > 24
        ? (timestamp: number) => [
            TimeHelper.timestampFormat(
              timestamp ?? 0,
              TimeFormatConstants.TIME_FORMAT
            ),
            TimeHelper.timestampFormat(
              timestamp ?? 0,
              TimeFormatConstants.ONLY_DATE_FORMAT
            ),
          ]
        : (timestamp: number) =>
            TimeHelper.timestampFormat(
              timestamp ?? 0,
              TimeFormatConstants.TIME_FORMAT
            )

    return statsData.map((dataPoint) => ({
      timestamp: timestampFormatFunction(
        dataPoint.intervalStart?.seconds
          ? dataPoint.intervalStart?.seconds * 1000
          : 0
      ),
      success: dataPoint.succeeded,
      failed: dataPoint.failed,
      inProgress: dataPoint.inProgress,
      warning: dataPoint.aborted,
    }))
  }

  static toTableData(
    groupedData: Array<BackupTimelineDataPoint>,
    jobKindFilter: ValueInterface,
    jobStatusFilter?: JobStatusConstant
  ): VIMatrix {
    return groupedData
      .filter(
        (dataPoint) =>
          dataPoint.jobs.filter(
            (job) => !jobStatusFilter || job.status === jobStatusFilter
          ).length
      )
      .map((dataPoint) => [
        {
          name: TimeHelper.timestampShortDatetimeFormat(dataPoint.from),
        },
        {
          name: String(
            dataPoint.jobs.filter(
              (job) => !jobStatusFilter || job.status === jobStatusFilter
            ).length
          ),
        },
        {
          name: `from=${dataPoint.from}&to=${
            dataPoint.to
          }&kinds=${jobKindFilter.options
            ?.filter((option) => Number(option.value) > 0)
            .map((option) => String(option.value))
            .join(',')}`,
        },
      ])
  }

  static getNoDataMessage(
    selectedPeriodLabel: string,
    selectedJobFilterLabel: string
  ): string {
    if (!selectedPeriodLabel) {
      return 'There are no backups to display.'
    } else {
      return `No ${
        selectedJobFilterLabel === 'All assets'
          ? 'Backups'
          : selectedJobFilterLabel
      } for ${selectedPeriodLabel.toLocaleLowerCase()}`
    }
  }

  static findDateRangeFilterOptionBasedOnLatestJob(job: JobModel) {
    const allDateFilters = FilterFactory.getBackupTimelineDateRangeFilters()
    return allDateFilters.options.find((option) => {
      const timestamp = TimeHelper.currentMinusHours(option.extraValue)
      return job.createdAt > timestamp.valueOf()
    })
  }
}
