import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import React, { useState, useEffect } from 'react'
import {
  useRuleDetailsActions,
  useRuleDetailsState,
} from './rule-details-store'
import { useDebouncedCallback } from 'use-debounce'
import { useRuleDrawerDataState } from '@features/alerts/create-rule-drawer/create-rule-drawer-data-store'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Label,
  MaxWidthFormControl,
  ControlledInput,
  RadioGroupController,
  CalendarController,
  Subtitle,
  Divider,
  transformEventKindsToSelectOptions,
} from '@features/alerts/create-rule-drawer/shared'
import {
  FORM_FIELDS,
  defaultValues,
  ALERT_ON_EVENTS,
  modeOptions,
} from './consts'
import { RuleFormValues } from './types'
import SuppressUntilTitle from './suppress-until-title'
import { useFormValidationActions } from '@features/alerts/create-rule-drawer'
import { FormSchema } from './form-schema'
import EventsMultiSelect from '@features/alerts/create-rule-drawer/shared/events-multiselect'

export const DEBOUNCE_DELAY_MS = 300

function validateMode(value: string) {
  return value === ALERT_ON_EVENTS
}

function RuleDetails() {
  const { setIsFormValid } = useFormValidationActions()
  const { setRuleDetailsData } = useRuleDetailsActions()

  const { listEventKinds } = useRuleDrawerDataState()
  const {
    reasonSuppression,
    suppressUntil,
    ruleName,
    mode,
    description,
    events,
  } = useRuleDetailsState().ruleDetailsData
  const methods = useForm<any>({
    defaultValues: {
      [FORM_FIELDS.RULE_NAME]: ruleName || defaultValues.ruleName,
      [FORM_FIELDS.DESCRIPTION]: description || defaultValues.description,
      [FORM_FIELDS.MODE]: mode || ALERT_ON_EVENTS,
      [FORM_FIELDS.EVENTS]: events || defaultValues.events,
      [FORM_FIELDS.SUPPRESS_UNTIL]:
        suppressUntil || defaultValues.suppressUntil,
      [FORM_FIELDS.REASON_SUPPRESSION]:
        reasonSuppression || defaultValues.reasonSuppression,
    },
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  })

  const debouncedSave = useDebouncedCallback((values: RuleFormValues) => {
    setRuleDetailsData(values)
  }, DEBOUNCE_DELAY_MS)

  const [isFieldDisabled, setIsFieldDisabled] = useState(validateMode(mode))

  useEffect(() => {
    const { unsubscribe } = methods.watch((value) => {
      debouncedSave(value)
      setIsFieldDisabled(validateMode(value[FORM_FIELDS.MODE] as string))
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    setIsFormValid(methods.formState.isValid)
  }, [methods.formState.isValid])

  return (
    <Box>
      <Subtitle>Define Name, Description & Rule Mode.</Subtitle>
      <Typography
        variant="body1"
        marginY="8px"
        fontSize="14px"
        fontWeight="400"
      >
        (Optionally, Pause the rule for a set period of time)
      </Typography>
      <FormProvider {...methods}>
        <Label>Rule Name</Label>
        <MaxWidthFormControl>
          <ControlledInput
            name={FORM_FIELDS.RULE_NAME}
            placeholder="Rule Name"
          />
        </MaxWidthFormControl>
        <Label sx={{ mt: '16px' }}>Description (Optional)</Label>
        <MaxWidthFormControl>
          <ControlledInput
            name={FORM_FIELDS.DESCRIPTION}
            placeholder="Rule Description"
          />
        </MaxWidthFormControl>
        <Divider />
        <Label>Rule Mode</Label>
        <RadioGroupController
          name={FORM_FIELDS.MODE}
          defaultValue={ALERT_ON_EVENTS}
          labelOptions={modeOptions}
        />
        <Label>Select Events</Label>
        <MaxWidthFormControl maxWidth="70%">
          <EventsMultiSelect
            name={FORM_FIELDS.EVENTS}
            placeholder="All"
            options={transformEventKindsToSelectOptions(listEventKinds)}
          />
        </MaxWidthFormControl>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            my: 2,
            alignItems: 'center',
          }}
        >
          <SuppressUntilTitle disabled={isFieldDisabled}>
            Suppress until
          </SuppressUntilTitle>
          <CalendarController
            name={FORM_FIELDS.SUPPRESS_UNTIL}
            disabled={isFieldDisabled}
          />
        </Stack>
        <MaxWidthFormControl>
          <ControlledInput
            disabled={isFieldDisabled}
            name={FORM_FIELDS.REASON_SUPPRESSION}
            placeholder="Reason for suppression"
          />
        </MaxWidthFormControl>
      </FormProvider>
    </Box>
  )
}

export default RuleDetails
