import Label from 'ui-v2/src/components/ui/data-display/label'
import { useRevokedTokenListQuery } from 'ui-v2/src/hooks/queries/settings'
import { RevokedTokenTableData } from 'ui-v2/src/lib/use-cases/settings/general/api-access/revoked-history-table-data'
import { Grid2 } from '@mui/material'
import { useMemo } from 'react'
import { getRevokedTokenListColumns } from './column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'

const RevokedHistory = () => {
  const { data: revokedTokenList } = useRevokedTokenListQuery()

  const data = useMemo(() => {
    if (!revokedTokenList) {
      return undefined
    }

    return new RevokedTokenTableData({
      tokenList: revokedTokenList,
    }).getRevokedTokenList()
  }, [revokedTokenList])
  const columns = useMemo(getRevokedTokenListColumns, [])

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Label>Revoked History</Label>
        <ClientTable checkable={false} data={data ?? []} columns={columns} />
      </Grid2>
    </Grid2>
  )
}

export default RevokedHistory
