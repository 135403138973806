import React from 'react'
import { EngineCallback, EngineCallbackPure } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import { Button } from '@mui/material'
import ValueInterface from '@lib/interfaces/value.interface'

interface Props {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<boolean>
  show: boolean
  data: ValueInterface
}

function SafetyLockEditModal({ onSubmit, onCancel, data, show }: Props) {
  return (
    <BaseModal
      className="jsSafetyLockEditModal"
      onBgClick={onCancel}
      show={show}
    >
      <div className="modalHeader">
        <div className="mhTitle">Edit Safety Lock</div>
      </div>
      {data && (
        <div className="modalContent">
          <div className="mcDescription">
            Safety Lock cannot be disabled once enabled. Are you sure you want
            to enable Safety Lock for
            <span className="fontBold ml5">{data.name}</span> vault?
          </div>

          <div className="modalControlBlock safetyLockModal">
            <Button
              className="jsSafetyLockEditModalYes"
              color="primary"
              variant="contained"
              onClick={() => onSubmit(Boolean(!data.value))}
              type="button"
            >
              Yes
            </Button>
            <Button
              className="jsSafetyLockEditModalNo"
              variant="text"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </BaseModal>
  )
}

export default SafetyLockEditModal
