import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const StyledSuppressionForm = styled(Box)({
  padding: '24px',
  backgroundColor: '#fff',
})

export const StyledLabelSuppressionForm = styled(Box)({
  margin: '8px 0',
  fontSize: '14px',
  fontWeight: '500',
})

export const StyledSuppressionFormItem = styled(Box)({
  marginBottom: '24px',
})

export const StyledInfoLabelSuppression = styled(Box)({
  fontWeight: '500',
})

export const StyledInfoItemSuppression = styled(Box)({
  display: 'flex',
  gap: '4px',
  marginBottom: '5px',
})
