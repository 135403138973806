/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPolicies } from '@store/selectors/policies.selector'
import {
  deletePolicy,
  executePolicy,
  pausePolicy,
  resumePolicy,
} from '@store/actions/policies.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ControlledVITable from '@tables/ControlledVITable'
import { BACKUP_POLICIES_HEAD } from '@tables/core/table-constants'
import TableFactory from '@lib/factories/table.factory'
import { OnMenuClickData } from '@tables/core/table-handlers'
import SystemHelper from '@lib/helpers/system.helper'
import DialogModal from '@components-composite/modals/DialogModal'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import clsx from 'clsx'
import MenuVIRow from '@tables/rows-vi/MenuVIRow'
import usePreloader from '@lib/hooks/usePreloader'
import { POLICIES_MAPPER } from '@tables/core/table-vi-draw-mappers'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { Menu, MenuItem } from '@mui/material'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import InfoHeaderButtonsRowElement from '@lib/interfaces/info-header-buttons-row-element.interface'
import InfoHeader from '@components-composite/info-header/InfoHeader'
import { getVaultList } from '@store/selectors/vault.selector'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { getAllRedStacks } from '@store/actions/rex.action'
import {
  createVault,
  requestVaultList,
  setVaultAsDefault,
} from '@store/actions/vault.action'
import AddVaultModal, {
  AddVaultModalInputData,
} from '@components-composite/modals/AddVaultModal'
import LangHelper from '@lib/helpers/lang.helper'
import SelectDefaultVaultModal from '@components-composite/modals/SelectDefaultVaultModal'
import VaultModel from '@lib/models/vault.model'
import ArrHelper from '@lib/helpers/arr.helper'
import FormAddVaultInterface from '@lib/interfaces/form/form-add-vault.interface'
import RedStackModel from '@lib/models/red-stack.model'
import { Sorting, SortOrder } from '@lib/interfaces/tables/sort.interface'
import TableSortableHeader from '@components-composite/table-sortable-header/TableSortableHeader'
import { PoliciesRoutes, useNavigation } from '@lib/router'

enum MENU {
  edit = 'Edit',
  delete = 'Delete',
  execute = 'Execute',
}

enum VaultModalType {
  NO_DEFAULT = 'No default',
  NO_VAULTS = 'No vaults',
}

enum SortVariant {
  POLICY_NAME = 'Name',
  STATUS = 'Status',
  LAST_RUN = 'Last Run',
  NEXT_RUN = 'Next Run',
  SOURCES = 'Sources',
  ASSETS = 'Assets',
}

function MenuVIRowWrapper(props: any) {
  function renderMenuType() {
    return [MENU.edit, MENU.delete, MENU.execute]
  }

  return <MenuVIRow {...props} menu={renderMenuType()} />
}

function PoliciesBackupComplex() {
  const dispatch = useDispatch()
  const router = useNavigation()
  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const vaults = useSelector(getVaultList)
  const policies = useSelector(getPolicies)

  const [selectedRSaccount, setSelectedRSaccount] = useState<{
    redStackId: string
    accountId: string
    region: string
  }>()

  const findProblematicRS = allActiveRedStacks.map((rs) => {
    return {
      reason: !vaults.filter((vault) => vault.redStackId === rs.redStackId)
        .length
        ? VaultModalType.NO_VAULTS
        : !vaults.some(
              (vault) => vault.redStackId === rs.redStackId && vault.isDefault
            )
          ? VaultModalType.NO_DEFAULT
          : '',
      ...rs,
    }
  })

  const isSomeVaultProblems = findProblematicRS.some((rs) => rs.reason)

  const loadingPoliciesList = usePreloader(PreloaderConstants.REQUEST_POLICIES)

  const isRSorVaultsLoading = usePreloaderAny([
    PreloaderConstants.GET_ALL_RED_STACKS,
    PreloaderConstants.REQUEST_VAULT_LIST,
  ])

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_POLICIES,
    PreloaderConstants.ADD_POLICIES,
    PreloaderConstants.DELETE_POLICY,
    PreloaderConstants.PAUSE_POLICIES,
    PreloaderConstants.RESUME_POLICIES,
    PreloaderConstants.EXECUTE_POLICY,
    PreloaderConstants.REQUEST_POSSIBLE_VPC_LIST,
    PreloaderConstants.GET_ALL_RED_STACKS,
    PreloaderConstants.REQUEST_VAULT_LIST,
    PreloaderConstants.SET_VAULT_AS_DEFAULT,
  ])
  useEffect(() => {
    dispatch(requestVaultList())
    dispatch(getAllRedStacks())
  }, [])

  const handleRedirectCallback = () => {
    router.push(PoliciesRoutes.root)
  }

  const { openModal, modalProps } = useModal<string>(
    Modal.deletePolicy,
    (policyId: string) => {
      dispatch(deletePolicy(policyId, handleRedirectCallback))
    }
  )

  const { openModal: openVaultDefModal, modalProps: vaultDefProps } = useModal<
    Array<VaultModel>,
    string
  >(Modal.defaultPolicy, (vaultId) => {
    const selectedVault = vaults.find((vault) => vault.innerId === vaultId)
    if (selectedVault) {
      dispatch(setVaultAsDefault(selectedVault))
    }
  })

  const { openModal: openAddVaultModal, modalProps: openAddVaultProps } =
    useModal<AddVaultModalInputData, FormAddVaultInterface>(
      Modal.addVault,
      (form) => {
        dispatch(
          createVault({
            vaultName: form.vaultName,
            vpcId: form.vpc,
            subnetIdsList: form.subnets ?? [],
            redStackId: selectedRSaccount?.redStackId ?? '',
            accountId: selectedRSaccount?.accountId ?? '',
            safetyLock: form.safetyLock,
          })
        )
      }
    )

  const possibleRegionsTranslation = ArrHelper.uniqueBy(
    allActiveRedStacks.map((v) => ({
      name: v.awsRegion,
      label: LangHelper.getAwsRegionSingleTranslation(v.awsRegion),
      value: v.version,
    })),
    'name'
  )

  const possibleRegionsByAccount = (awsAccount: string, awsRegion: string) => {
    const rsAccount = allActiveRedStacks.find(
      (rs) => rs.awsAccount === awsAccount && rs.awsRegion === awsRegion
    )

    if (!rsAccount) {
      return
    }
    return possibleRegionsTranslation.filter(
      (region) => region.name === rsAccount.awsRegion
    )
  }

  // Maybe should create menu component for that
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent) => {
    return anchorEl
      ? setAnchorEl(null)
      : setAnchorEl(event.currentTarget as HTMLButtonElement)
  }

  const openVaultModalChoose = (
    reason: string,
    awsAccount: string,
    awsRegion: string
  ) => {
    switch (reason) {
      case VaultModalType.NO_DEFAULT:
        openVaultDefModal(
          vaults.filter(
            (vault) =>
              vault.accountId === awsAccount && vault.region === awsRegion
          )
        )
        break
      case VaultModalType.NO_VAULTS:
        openAddVaultModal({
          possibleRegionsTranslation:
            possibleRegionsByAccount(awsAccount, awsRegion) || [],
          vaults: vaults.filter((vault) => vault.accountId === awsAccount),
          accountId: awsAccount,
        })
        break
    }
  }

  const openAddVaultModalByClick = () => {
    if (!selectedRSaccount || !selectedRSaccount.accountId) {
      return
    }
    return openAddVaultModal({
      possibleRegionsTranslation:
        possibleRegionsByAccount(
          selectedRSaccount.accountId,
          selectedRSaccount.region
        ) || [],
      accountId: selectedRSaccount.accountId,
      vaults: vaults.filter(
        (vault) => vault.accountId === selectedRSaccount.accountId
      ),
    })
  }

  const onHeaderMenuClick = (rs: RedStackModel & { reason: string }) => {
    setSelectedRSaccount({
      redStackId: rs.redStackId,
      accountId: rs.awsAccount,
      region: rs.awsRegion,
    })
    openVaultModalChoose(rs.reason, rs.awsAccount, rs.awsRegion)
  }

  const titleButtonRowData: Array<InfoHeaderButtonsRowElement> = [
    {
      onClick: (event) => handleClick(event),
      label: (
        <div>
          <span>Fix</span>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClick}
          >
            {findProblematicRS.map(
              (rs) =>
                rs.reason && (
                  <MenuItem
                    key={rs.id}
                    onClick={() => {
                      onHeaderMenuClick(rs)
                    }}
                  >{`${rs.awsAccount} (${rs.awsRegion})`}</MenuItem>
                )
            )}
          </Menu>
        </div>
      ),
      isDisabled: loading,
      type: 'blue',
    },
  ]

  const onMenuClick = (menuData: OnMenuClickData) => {
    const id = menuData.data[0].value
    switch (menuData.chosenMenu) {
      case MENU.edit:
        router.push(PoliciesRoutes.buildPathWithEncodedId(id))
        return

      case MENU.delete:
        openModal(id)
        return

      case MENU.execute:
        dispatch(executePolicy(id))
        return

      default:
        SystemHelper.throwErrorInLocalEnv('Unresolved policies menu')
    }
  }

  const onPausePolicyClick = (id: string) => {
    dispatch(pausePolicy(id))
  }

  const onResumePolicyClick = (id: string) => {
    dispatch(resumePolicy(id))
  }

  const [sorting, setSorting] = useState<Sorting>({})
  const [sortedPolicies, setSortedPolicies] = useState(policies)

  const onSortingChange = (columnId: string) => {
    const newSortOrder: SortOrder =
      sorting.columnId === columnId
        ? sorting.order === SortOrder.ASC
          ? SortOrder.DESC
          : SortOrder.ASC
        : SortOrder.ASC

    switch (columnId) {
      case SortVariant.POLICY_NAME:
        setSortedPolicies(
          ArrHelper.orderBy(policies, 'policyName', newSortOrder)
        )
        break
      case SortVariant.STATUS:
        setSortedPolicies(ArrHelper.orderBy(policies, 'status', newSortOrder))
        break
      case SortVariant.LAST_RUN:
        setSortedPolicies(
          ArrHelper.orderBy(policies, 'previousRun', newSortOrder)
        )
        break
      case SortVariant.NEXT_RUN:
        setSortedPolicies(ArrHelper.orderBy(policies, 'status', newSortOrder))
        break
    }

    setSorting({
      columnId,
      order: newSortOrder,
    })
  }

  useEffect(() => {
    setSortedPolicies(policies)
  }, [policies])

  return (
    <div className="wrap-1638783021601 policyComplex policiesBackupComplex">
      <div className="controlHeaderBlock">
        {!isRSorVaultsLoading && isSomeVaultProblems && (
          <InfoHeader
            className="policiesErrorHeader"
            buttons={titleButtonRowData}
            type="error"
            title="Vault is misconfigured"
            description="Your policies cannot be run because a vault is misconfigured in one or more AWS accounts."
          />
        )}
      </div>

      {loadingPoliciesList && !policies.length ? (
        <PreloaderBlock show />
      ) : (
        <>
          {!policies.length ? (
            <InfoHeader
              type="info"
              title="No policies created"
              description="start creating policies, and they’ll appear here."
            />
          ) : (
            <div className="widgetWrap">
              <ControlledVITable
                head={BACKUP_POLICIES_HEAD}
                headComponent={() => (
                  <TableSortableHeader
                    onHeadClick={onSortingChange}
                    sorting={sorting}
                    data={BACKUP_POLICIES_HEAD}
                  />
                )}
                rows={TableFactory.policiesTable(sortedPolicies)}
                onMenuClick={onMenuClick}
                menu={[MENU.edit, MENU.delete, MENU.execute]}
                rowComponent={MenuVIRowWrapper}
                className={clsx(
                  'tableVIUncontrolled policiesTable tableUpgrade striped shrinkTableColumn6',
                  {
                    controlsDisabled: loading,
                  }
                )}
                columnDrawMapper={POLICIES_MAPPER(
                  onPausePolicyClick,
                  onResumePolicyClick,
                  50
                )}
              />
            </div>
          )}
        </>
      )}

      <DialogModal
        description={
          <div>
            You are going to remove the Policy
            {<LongTextTooltip text={modalProps.data ?? ''} maxLength={35} />}
            Please confirm the deletion.
          </div>
        }
        {...modalProps}
      />
      {<AddVaultModal loading={loading} {...openAddVaultProps} />}
      <SelectDefaultVaultModal
        openAddModal={openAddVaultModalByClick}
        className="defaultVaultModal"
        loading={loading}
        {...vaultDefProps}
      />
    </div>
  )
}

export default PoliciesBackupComplex
