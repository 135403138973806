/* eslint-disable import/no-extraneous-dependencies */
import { CellWithDateTimeTooltip } from '@components/table'
import { ScansRun } from 'blues-corejs/dist/models'
import { Box, styled } from '@mui/material'
import React from 'react'
import { getCompletionTime } from '../helpers'

const StyledCompletionTimeCell = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: 'var(--font-weight-medium)',
  color: theme.palette.blue[500],
}))

interface Props {
  scans: ScansRun
  onScansClick: (scans: ScansRun) => void
}

export function CompletionTimeCell({ scans, onScansClick }: Props) {
  const computedCompletionTime = getCompletionTime(scans)

  function handleScansClick() {
    onScansClick(scans)
  }

  return (
    <StyledCompletionTimeCell onClick={handleScansClick}>
      <CellWithDateTimeTooltip direction="row" date={computedCompletionTime} />
    </StyledCompletionTimeCell>
  )
}
