/* eslint-disable import/no-extraneous-dependencies */
import { EfsInventoryOutput } from 'blues-corejs/dist/use_cases/list_efs_inventory/types'

/**
 * Sorts to last scan first, if no last scan, sort by name
 */
export function initialPrioritySortForEFSTable(
  filesystems: Array<EfsInventoryOutput>
) {
  return filesystems.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const efsAName = a.name || a.awsId
    const efsBName = b.name || b.awsId

    return efsAName.localeCompare(efsBName)
  })
}
