import React, { useEffect, useMemo, useState } from 'react'
import {
  EngineCallback,
  EngineCallbackPure,
  ValueObject,
  VIRow,
  VIRowReadonly,
} from '@lib/engine-types'
import FormAddVaultInterface from '@lib/interfaces/form/form-add-vault.interface'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormHelper from '@lib/helpers/form.helper'
import clsx from 'clsx'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import ValueInterface from '@lib/interfaces/value.interface'
import CustomTextField from '@forms/fields/custom-text-field'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import VpcModel, { VpcTagsType } from '@lib/models/vpc.model'
import ErrorValidationConstants from '@lib/constants/error-validation.constant'
import VaultModel from '@lib/models/vault.model'
import { MAX_VAULT_NUMBERS } from '@lib/constants/app.constant'
import buildDefaultFormData from './data'
import { Button, Switch } from '@mui/material'
import SystemHelper from '@lib/helpers/system.helper'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import VpcFactory from '@lib/factories/vpc.factory'
import DemoWrap from '@components-simple/demo-wrap/DemoWrap'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { VPC_SUBNET_NAME_TOOLTIP_LENGTH } from '@lib/constants/tooltip.constant'

interface Props {
  possibleRegions: VIRow
  possibleVPCList: Array<VpcModel>
  defaultValues?: ValueObject<FormAddVaultInterface>
  onSubmit: EngineCallback<FormAddVaultInterface>
  onCancel: EngineCallbackPure
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
  vaults: Array<VaultModel>
}

function VaultForm({
  possibleRegions,
  possibleVPCList,
  defaultValues,
  onSubmit,
  onCancel,
  loading,
  errorGroups,
  vaults,
}: Props) {
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )

  const {
    register,
    isFormDisabled,
    submit,
    formState,
    globalFormError,
    setValue,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading)

  const MIN_VERSION_FOR_SAFETY_LOCK = 24

  // possible region
  const [region, setRegion] = useState<ValueInterface>(
    possibleRegions[0] ?? { name: '' }
  )

  const [safetyLock, setSafetyLock] = useState<boolean>(false)
  const [disabledSafetyLock, setDisabledSafetyLock] = useState<boolean>(false)

  if (possibleRegions.length === 0) {
    SystemHelper.throwErrorInLocalEnv('Possible regions were not provided')
  }

  const regionVpcList = possibleVPCList
    .filter((el: VpcModel) => el.region.name === region.name)
    .map((v: VpcModel) => {
      const vpcLabel =
        v.params.tagsList.find(
          (tag: VpcTagsType) => tag.key.toLowerCase() === 'name'
        )?.value ?? v.params.innerId

      return {
        name: v.params.innerId,
        label: v.isDefault ? `${vpcLabel} (default)` : vpcLabel,
      }
    })

  // VPC list by region
  const [currentRegionVpcList, setCurrentRegionVpcList] =
    useState<VIRow>(regionVpcList)
  // vpc select first
  const [vpc, setVpc] = useState<ValueInterface>(
    regionVpcList[0] ?? { name: '' }
  )

  const [possibleSub, setPossibleSub] = useState<VIRowReadonly>([])
  const [selectedSub, setSelectedSub] = useState<VIRow>([])
  const [privateSubnet, setPrivateSubnet] = useState<boolean>(false)

  if (regionVpcList.length === 0) {
    SystemHelper.throwErrorInLocalEnv('Region VPC list was not provided')
  }

  // number of vaults in current region
  const numberOfVaultsByRegion = vaults.filter(
    (vault) => vault.region === region.name
  ).length

  useEffect(() => {
    setDisabledSafetyLock(
      vaults?.some(
        ({ version }) =>
          Number(version.split('.')[1]) < MIN_VERSION_FOR_SAFETY_LOCK
      )
    )
  }, [vaults])

  useEffect(() => {
    setValue('safetyLock', safetyLock)
  }, [safetyLock])

  useEffect(() => {
    // set VPC list by region
    setCurrentRegionVpcList(regionVpcList)

    setVpc(regionVpcList[0] ?? { name: '' })
  }, [region, possibleVPCList])

  useEffect(() => {
    setValue('region', region.name)

    const possibleSubsList =
      possibleVPCList.find(({ innerId }) => innerId == vpc?.name)
        ?.subnetsList ?? []

    const possibleSubs: VIRowReadonly = possibleSubsList.map(
      VpcFactory.fromSubnetModelToVi
    )

    setPossibleSub(possibleSubs)
    setSelectedSub([...possibleSubs]) // pre-select all

    setValue('vpc', vpc.name)
  }, [region, vpc])

  useEffect(() => {
    setValue(
      'subnets',
      selectedSub.map(({ name }) => name)
    )

    setPrivateSubnet(
      selectedSub.length > 0 &&
        selectedSub.some((sub) => sub.extraValue === false)
    )
  }, [selectedSub])

  FormHelper.fillErrors(formState.errors, currentForm)

  return (
    <form
      className={clsx('wrap-1617284626632', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      <input
        type="hidden"
        {...register(
          currentForm.region.name,
          currentForm.region.validationRules
        )}
      />
      <input
        type="hidden"
        {...register(currentForm.vpc.name, currentForm.vpc.validationRules)}
      />

      <div className="newFormSingleRow">
        <ViSelect
          label={currentForm.region.label}
          possible={possibleRegions}
          selected={region}
          onChange={setRegion}
          disabled={isFormDisabled}
          getLabel={(v) => (
            <>
              {v.label}
              <span className="smallSubOptionViSelect">{v.name}</span>
            </>
          )}
        />
      </div>
      <div className="newFormSingleRow">
        <ViSelect
          label={currentForm.vpc.label}
          possible={currentRegionVpcList}
          selected={vpc}
          onChange={setVpc}
          disabled={isFormDisabled}
          getLabel={(v) => {
            if (!v.label?.includes(v.name)) {
              return (
                <LongTextTooltip
                  text={v.label ?? v.name}
                  placement="top"
                  tooltipText={v.name}
                  maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                  show
                />
              )
            }
            return v.name
          }}
        />
      </div>
      <div className="newFormSingleRow">
        <ViMultipleSelect
          label={currentForm.subnets.label}
          disabled={isFormDisabled}
          possible={possibleSub}
          selected={selectedSub}
          onChange={(value) => setSelectedSub(value)}
          placeholder="Select Subnets"
          maxLengthInTooltip={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
          showNameInTooltip
          menuWidth="auto"
        />
      </div>
      {privateSubnet && (
        <div className="newFormSingleRow">
          <div className="blockWarningText">
            The Vault will be deployed to a private subnet and will need manual
            configuration. Please see documentation for more details. One of our
            cloud team members will reach out with assistance.
          </div>
        </div>
      )}
      <div className="newFormSingleRow">
        <div className="v2StaticTextInput">
          <CustomTextField
            disabled={isFormDisabled}
            field={currentForm.vaultName}
            {...register(
              currentForm.vaultName.name,
              currentForm.vaultName.validationRules
            )}
          />
        </div>
      </div>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}
      {numberOfVaultsByRegion < MAX_VAULT_NUMBERS || (
        <div className="formGlobalError">
          {ErrorValidationConstants.MAX_VAULTS_NUMBER}
        </div>
      )}
      <DemoWrap>
        <div className="newFormSingleRow">
          <div className="switcher">
            <Switch
              className="greenSwitch"
              color="primary"
              disabled={disabledSafetyLock}
              checked={safetyLock}
              onChange={(_e, value) => setSafetyLock(value)}
              name="switch"
            />
            {safetyLock ? 'Disable ' : 'Enable '}
            Safety Lock
            {disabledSafetyLock && (
              <QuestionHint
                text="The Cloud Connector the vault belongs to need to be updated to the latest version to use this feature."
                variant="inText"
              />
            )}
          </div>
        </div>
      </DemoWrap>
      <div className="modalFooterV2">
        <PreloaderSmall show={loading} top={76} right={22} />
        <Button
          disabled={loading}
          className="mfCancel"
          type="button"
          variant="text"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || numberOfVaultsByRegion >= MAX_VAULT_NUMBERS}
          className="mfSubmit"
          color="primary"
          variant="contained"
          type="submit"
        >
          Create
        </Button>
      </div>
    </form>
  )
}

export default VaultForm
