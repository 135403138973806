import React from 'react'

interface Props {
  fill?: string
}

function UpdateIcon({ fill = '#32B6F3' }: Props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9714 13.4582C16.8722 13.1528 16.5442 12.9859 16.2387 13.0849L15.4826 13.3306C15.9458 12.3727 16.1884 11.3193 16.1884 10.2436C16.1884 6.33184 13.006 3.14941 9.09419 3.14941C5.18243 3.14941 2 6.33184 2 10.2436C2 14.1554 5.18243 17.3378 9.09419 17.3378C9.41531 17.3378 9.67568 17.0774 9.67568 16.7563C9.67568 16.4352 9.41531 16.1748 9.09419 16.1748C5.8237 16.1748 3.16298 13.5141 3.16298 10.2436C3.16298 6.97311 5.8237 4.3124 9.09419 4.3124C12.3647 4.3124 15.0254 6.97311 15.0254 10.2436C15.0254 11.1448 14.8221 12.027 14.4335 12.8286L14.1752 12.0333C14.076 11.7279 13.7479 11.5608 13.4425 11.66C13.1371 11.7592 12.9699 12.0873 13.0691 12.3927L13.7548 14.5032C13.8346 14.749 14.0625 14.9052 14.3077 14.9052C14.3672 14.9052 14.4278 14.896 14.4875 14.8766L16.598 14.191C16.9034 14.0918 17.0706 13.7638 16.9714 13.4582Z"
        fill={fill}
      />
    </svg>
  )
}

export default UpdateIcon
