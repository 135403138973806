/* eslint-disable import/no-extraneous-dependencies */
import { AWSBRecoveryPoint } from 'blues-corejs/dist'
import { RecoveryPointKind as AWSRecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/awsb/types'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

export function computeAwsRecoveryPointKind(backup: AWSBRecoveryPoint) {
  if (backup.kind === AWSRecoveryPointKind.EC2) {
    return 'EC2'
  }

  if (backup.kind === AWSRecoveryPointKind.EBS) {
    return 'EBS'
  }

  if (backup.kind === AWSRecoveryPointKind.S3) {
    return 'S3'
  }

  sentryReThrowCatchHandler(
    `computeAwsRecoveryPointKind: Unknown AWS recovery point kind: ${backup.kind}`
  )
  throw new Error(
    `computeAwsRecoveryPointKind: Unknown AWS recovery point kind: ${backup.kind}`
  )
}
