import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import ContentBlock from '@components-composite/content-block/ContentBlock'
import DialogModal from '@components-composite/modals/DialogModal'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import Modal from '@lib/constants/modal.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import TableFactory from '@lib/factories/table.factory'
import { useModal } from '@lib/hooks/useModal'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { WEBHOOK_TABLE_HEAD } from '@tables/core/table-constants'
import { WEBHOOK_TABLE_MAPPER } from '@tables/core/table-vi-draw-mappers'
import SimpleVITable from '@tables/SimpleVITable'
import PageHelper from '@lib/helpers/page.helper'
import { getWebhooksList } from '@store/selectors/webhook.selector'
import {
  deleteWebhook,
  requestEventTypes,
  requestTestExistingWebhook,
  requestWebhooksList,
} from '@store/actions/webhook.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { SettingsRoutes } from '@lib/router'

function WebhookEventsComplex() {
  const router = useNavigation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestWebhooksList())
    dispatch(requestEventTypes())
  }, [])

  const webhooks = useSelector(getWebhooksList)

  const loading = usePreloaderAny([PreloaderConstants.TEST_EXISTING_WEBHOOK])

  const handleRedirectCallback = () => {
    router.push(SettingsRoutes.webhooksTab)
  }

  const { openModal, modalProps } = useModal<{ name: string; id: string }>(
    Modal.deletePolicyRetention,
    ({ id }: { name: string; id: string }) => {
      dispatch(deleteWebhook(id, handleRedirectCallback))
    }
  )

  const onTestWebHook = (id: string) => {
    const currentWebhook = webhooks.find((item) => item.getId() === id)
    if (currentWebhook) {
      dispatch(requestTestExistingWebhook(currentWebhook.getId()))
    }
  }

  const onEditWebHook = (id: string) => {
    const currentWebhook = webhooks.find((item) => item.getId() === id)
    if (currentWebhook) {
      router.push(
        PageHelper.buildUrl(
          PagePathConstant.WEBHOOK_EDIT_ID,
          currentWebhook.getId()
        )
      )
    }
  }

  const onDeleteWebHook = (id: string) => {
    const currentWebhook = webhooks.find((item) => item.getId() === id)
    if (currentWebhook) {
      openModal({
        name: currentWebhook.getName(),
        id: currentWebhook.getId(),
      })
    }
  }

  return (
    <div
      className="wrap-1658432471114 webhookEventsComplex"
      data-testid="webhookEventsComplex"
    >
      <ContentBlock
        data={webhooks}
        loading={loading}
        noDataMessage="There are no webhooks yet"
      >
        <div className="widgetWrap">
          <div className="totalWebhooks">
            {webhooks?.length} Webhook{webhooks?.length !== 1 ? 's' : ''}
          </div>
          <SimpleVITable
            head={WEBHOOK_TABLE_HEAD}
            rows={TableFactory.webhooksTable(webhooks)}
            className={clsx(
              'tableVIUncontrolled webhookEventsTable tableUpgrade striped',
              {
                controlsDisabled: loading,
              }
            )}
            columnDrawMapper={WEBHOOK_TABLE_MAPPER(
              onTestWebHook,
              onEditWebHook,
              onDeleteWebHook
            )}
          />
        </div>
      </ContentBlock>

      <DialogModal
        description={
          <div>
            You are going to remove Webhook
            {
              <LongTextTooltip
                text={modalProps.data?.name ?? ''}
                maxLength={35}
              />
            }
            Please confirm the deletion.
          </div>
        }
        {...modalProps}
      />
    </div>
  )
}

export default memo(WebhookEventsComplex)
