import React from 'react'
import { EngineCallback } from '@lib/engine-types'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'

interface Props {
  data: KeepDataCopyInterface
  onChange: EngineCallback<KeepDataCopyInterface>
}

function KeepDataCopy({ data, onChange }: Props) {
  const onChangeLastCleanInner = (keepLastClean: boolean) => {
    onChange({
      ...data,
      keepLastClean,
    })
  }

  const onChangeLatestInfectedInner = (keepLatestInfected: boolean) => {
    onChange({
      ...data,
      keepLatestInfected,
    })
  }

  return (
    <div className="keepDataCopyWrap" data-testid="keepDataCopy">
      <V2Checkbox
        checked={data.keepLastClean}
        onChange={onChangeLastCleanInner}
        label={
          <div className="checkboxLabel">
            <b>Always keep the last clean copy of data </b>
            <span>(applicable for EC2 and EBS)</span>
          </div>
        }
      />
      <V2Checkbox
        checked={data.keepLatestInfected}
        onChange={onChangeLatestInfectedInner}
        label={
          <div className="checkboxLabel">
            <b>Always keep the latest infected copy of data </b>
            <span>(applicable for EC2 and EBS)</span>
          </div>
        }
      />
    </div>
  )
}

export default KeepDataCopy
