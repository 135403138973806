import FormRoleNameInterface from '@lib/interfaces/form/form-role-name.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormRoleNameInterface,
  FormFieldInterface
> => ({
  roleName: {
    name: 'roleName',
    value: '',
    validationRules: {
      required: true,
    },
  },
})

export default buildDefaultFormData
