// eslint-disable-next-line import/no-extraneous-dependencies
import {
  GenerateOnDemandReportRequest,
  AwsExecutiveReportParams,
  OvaMspOperationalReportParams,
  AwsInfectedAssetsReportParams,
  AwsScannedAssetsSummaryReportParams,
  AwsAccountSummaryReportParams,
  AwsCompletedScansReportParams,
  AwsTenantSummaryReportParams,
  FailedScansReportParams,
  InfectedFilesReportParams,
  OvaMspExecutiveReportParams,
  OvaMspTenantSummaryReportParams,
  OvaMspAccountSummaryReportParams,
  OvaMspCompletedScansReportParams,
  OvaMspScannedAssetsSummaryReportParams,
  OvaMspInfectedAssetsReportParams,
  OvaMspInfectedFilesReportParams,
  OvaMspFailedScansReportParams,
  ReportKindWithParams,
} from '@lib/clients/grpc-imports'
import {
  AWSReports,
  BackupConnectorReports,
} from '@features/reports/schedules/shared'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { mapFormatsToEnum } from '../shared'

export interface GenerateOnDemandReportCriteria {
  start: number
  end: number
  formats: Array<string>
  backupProvider: Array<number>
  ovaAccounts: Array<string>
  reportType: string
  description: string
}

function createTimestamp(seconds: number) {
  const timestamp = new Timestamp()
  timestamp.setSeconds(seconds)
  return timestamp
}

export function buildGenerateOnDemandReportRequest(
  criteria: GenerateOnDemandReportCriteria
): GenerateOnDemandReportRequest {
  const request = new GenerateOnDemandReportRequest()
  const {
    reportType,
    backupProvider,
    ovaAccounts,
    start: startUI,
    end: endUI,
    formats: formatsUI,
    description = '',
  } = criteria

  const formats = mapFormatsToEnum(reportType, formatsUI)

  const start = createTimestamp(startUI)
  const end = createTimestamp(endUI)
  const kind = new ReportKindWithParams()

  switch (reportType) {
    case BackupConnectorReports.ovaMspOperationalReport:
      kind.setOvaMspOperational(
        new OvaMspOperationalReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspExecutiveReport:
      kind.setOvaMspExecutive(
        new OvaMspExecutiveReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspTenantSummaryReport:
      kind.setOvaMspTenantSummary(
        new OvaMspTenantSummaryReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspAccountSummaryReport:
      kind.setOvaMspAccountSummary(
        new OvaMspAccountSummaryReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspCompletedScansReport:
      kind.setOvaMspCompletedScans(
        new OvaMspCompletedScansReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspScannedAssetsSummaryReport:
      kind.setOvaMspScannedAssetsSummary(
        new OvaMspScannedAssetsSummaryReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspInfectedAssetsReport:
      kind.setOvaMspInfectedAssets(
        new OvaMspInfectedAssetsReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspInfectedFilesReport:
      kind.setOvaMspInfectedFiles(
        new OvaMspInfectedFilesReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case BackupConnectorReports.ovaMspFailedScansReport:
      kind.setOvaMspFailedScans(
        new OvaMspFailedScansReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
          .setOvaBackupProvidersList(backupProvider)
          .setOvaAccountIdsList(ovaAccounts)
      )
      break
    case AWSReports.awsExecutiveReport:
      kind.setAwsExecutive(
        new AwsExecutiveReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )

      break
    case AWSReports.awsTenantSummaryReport:
      kind.setAwsTenantSummary(
        new AwsTenantSummaryReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )
      break
    case AWSReports.awsAccountSummaryReport:
      kind.setAwsAccountSummary(
        new AwsAccountSummaryReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )
      break
    case AWSReports.awsCompletedScansReport:
      kind.setAwsCompletedScans(
        new AwsCompletedScansReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )
      break
    case AWSReports.awsScannedAssetsSummaryReport:
      kind.setAwsScannedAssetsSummary(
        new AwsScannedAssetsSummaryReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )
      break
    case AWSReports.awsInfectedAssetsReport:
      kind.setAwsInfectedAssets(
        new AwsInfectedAssetsReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )

      break
    case AWSReports.infectedFilesReport:
      kind.setInfectedFiles(
        new InfectedFilesReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )
      break

    case AWSReports.failedScansReport:
      kind.setFailedScans(
        new FailedScansReportParams()
          .setStartAt(start)
          .setEndAt(end)
          .setFormatsList(formats)
      )
      break
  }

  return request.setKind(kind).setDescription(description)
}
