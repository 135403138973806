/* eslint-disable import/no-extraneous-dependencies */
import { RiskLevel } from 'blues-corejs/dist'

export function getRiskNameFormQuery(
  risk?: string | Array<string>
): RiskLevel | undefined {
  switch (risk) {
    case '0':
      return RiskLevel.Low
    case '1':
      return RiskLevel.Medium
    case '2':
      return RiskLevel.High

    default:
      return undefined
  }
}
