/* eslint-disable import/no-extraneous-dependencies */
import { Asset } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { CellWithDateTimeTooltip } from '@components/table'
import { BackupType } from '@lib/clients/assets/types'

const NO_BACKUPS_LABEL = 'No Backups'

interface Props {
  lastBackups: Map<string, { backup: BackupType }>
  asset: Asset
}

export function AssetLastBackupCell({ lastBackups, asset }: Props) {
  const lastBackup = lastBackups.get(asset.id)

  if (!lastBackup) {
    return <>{NO_BACKUPS_LABEL}</>
  }

  return (
    <CellWithDateTimeTooltip
      date={lastBackup.backup.timestamp}
      direction="row"
    />
  )
}
