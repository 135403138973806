import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { JobsClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useCloudConnectorJobQuery(id: string | undefined) {
  const jobsClient = useGrpcClient(JobsClient)

  return useQuery({
    queryKey: ['jobs', 'cloud-connector', id],
    queryFn: () => {
      if (!jobsClient) {
        throw new Error('Jobs client not initialized')
      }
      if (!id) {
        throw new Error('Cloud connector job ID not found')
      }
      return jobsClient.getCloudConnectorJob(id)
    },
    enabled: !!jobsClient && !!id,
  })
}
