/* eslint-disable import/no-extraneous-dependencies */
import {
  AfterOccurrences,
  Custom,
  Daily,
  EveryDay,
  EveryMonth,
  EveryWeek,
  Monthly,
  Never,
  OnDate,
  Schedule,
  Weekly,
} from 'blues-corejs/dist/models/reports/report-schedule/schedule'
import { getDayNameAsString } from '@lib/clients/reports/schedules/shared/get-day-name'

function getDailyDescription(): string {
  return 'Daily'
}

function getWeeklyDescription(day: number): string {
  return `Weekly, on ${getDayNameAsString(day)}`
}

function getMonthlyDescription(monthly: Monthly): string {
  let computedDay
  switch (monthly.dayOfMonth) {
    case 0:
      computedDay = 'last'
      break

    case 1:
      computedDay = '1st'
      break

    case 2:
      computedDay = '2nd'
      break

    case 3:
      computedDay = '3rd'
      break

    default:
      computedDay = `${monthly?.dayOfMonth}th`
  }
  return `Monthly, on ${computedDay} day`
}

function getCustomModeDescription(schedule: any) {
  let computedStr = ''

  const { repeatMode, expirationMode } = schedule.mode

  let repeatText = ''
  let expirationText = ''

  if (repeatMode instanceof EveryDay) {
    repeatText =
      repeatMode.stride === 1
        ? 'Every 1 day'
        : `Every ${repeatMode.stride} days`
  } else if (repeatMode instanceof EveryMonth) {
    let computedDay
    switch (repeatMode.dayOfMonth) {
      case 0:
        computedDay = 'last'
        break
      case 1:
        computedDay = '1st'
        break
      case 2:
        computedDay = '2nd'
        break
      case 3:
        computedDay = '3rd'
        break
      default:
        computedDay = `${repeatMode.dayOfMonth}th`
    }
    repeatText =
      repeatMode.stride === 1
        ? `Every 1 month, on the ${computedDay} day`
        : `Every ${repeatMode.stride} months, on the ${computedDay} day`
  } else if (repeatMode instanceof EveryWeek) {
    const dayName = repeatMode.day
    repeatText =
      repeatMode.stride === 1
        ? `Every 1 week, on ${dayName}`
        : `Every ${repeatMode.stride} weeks, on ${dayName}`
  } else {
    repeatText = 'Unknown repeat mode'
  }

  if (expirationMode instanceof Never) {
    expirationText = ''
  } else if (expirationMode instanceof AfterOccurrences) {
    expirationText =
      expirationMode.afterOccurrences === 1
        ? 'one time'
        : `${expirationMode.afterOccurrences} times`
  } else if (expirationMode instanceof OnDate) {
    const { month, day, year } = expirationMode.date
    expirationText = `until ${month}/${day}, ${year}`
  } else {
    expirationText = 'No expiration'
  }

  if (expirationText) {
    computedStr = `${repeatText}, ${expirationText}`
  } else {
    computedStr = repeatText
  }

  return computedStr
}

export function getScheduleDescription(schedule: Schedule): string {
  if (schedule.mode instanceof Daily) {
    return getDailyDescription()
  }

  if (schedule.mode instanceof Weekly) {
    return getWeeklyDescription(schedule.mode.day)
  }

  if (schedule.mode instanceof Monthly) {
    return getMonthlyDescription(new Monthly(schedule.mode.dayOfMonth))
  }

  if (schedule.mode instanceof Custom) {
    return getCustomModeDescription(schedule)
  }

  return 'Unknown schedule type'
}
