import { UserModel } from 'ui-v2/src/lib/models/user'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  userProfile: UserModel | undefined
  userConfig: any
}

const initialState: UserState = {
  userProfile: undefined,
  userConfig: undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<UserModel>) => {
      state.userProfile = action.payload
    },
    setUserConfig: (state, action: PayloadAction<any>) => {
      state.userConfig = action.payload
    },
  },
})

export const { setUserProfile, setUserConfig } = userSlice.actions
export default userSlice.reducer
