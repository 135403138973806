/* eslint-disable import/no-extraneous-dependencies */
import {
  PolicyEfsTableDataAttrs,
  PolicyEfsTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

export class PolicyEfsTableData {
  readonly #efsAssets: AssetsForPolicyCoverage

  constructor(parameters: PolicyEfsTableDataAttrs) {
    this.#efsAssets = parameters.efsAssets
  }

  getEfsAssetsList = (): Array<PolicyEfsTableRow> => {
    return this.#efsAssets.map((row) => {
      const displayedValue = row.asset.name || row.asset.awsId
      const backupPolicies = row.asset.backupPolicies

      return new PolicyEfsTableRow({
        id: row.asset.id,
        efs: displayedValue,
        account: `${row.asset.awsAccountId} (${row.asset.awsRegion ? row.asset.awsRegion : ''})`,
        policy:
          backupPolicies.length === 0
            ? 'Not Assigned'
            : backupPolicies.join(', '),
      })
    })
  }
}
