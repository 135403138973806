import { TimeFormatConstants } from '@lib/constants'
import { Nullable } from '@lib/engine-types'
import LangHelper from '@lib/helpers/lang.helper'
import type { EfsInventoryOutput } from 'blues-corejs/dist/use_cases/list_efs_inventory/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function convertDateToString(date: Nullable<Date>) {
  if (!date) {
    return ''
  }

  return `${dayjs(date)
    .utc()
    .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
}

function prepareEfsDataForCsv(efsList: Array<EfsInventoryOutput>) {
  return efsList.map((filesystem) => {
    return {
      filesystemName: filesystem.name
        ? `${filesystem.name} (${filesystem.awsId})`
        : filesystem.awsId,
      // To prevent converting into number column
      accountId: `${filesystem.accountId}‎`,
      region: LangHelper.getAwsRegionSingleTranslation(filesystem.region),
      lastScan: convertDateToString(filesystem.lastScan),
      policyCoverage: filesystem.backupPolicies.join(', '),
    }
  })
}

const EFS_CSV_TABLE_HEADERS = [
  {
    label: 'File system name',
    key: 'filesystemName',
  },
  {
    label: 'Account',
    key: 'accountId',
  },
  {
    label: 'Region',
    key: 'region',
  },
  {
    label: 'Last Scan',
    key: 'lastScan',
  },

  {
    label: 'Policy Coverage',
    key: 'policyCoverage',
  },
]

export { prepareEfsDataForCsv, EFS_CSV_TABLE_HEADERS }
