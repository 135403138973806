import { Nullable } from '@lib/engine-types'
import { WebhooksInvocation } from '@lib/interfaces/webhooks.interface'
import { Chart, ChartType, registerables, Tooltip } from 'chart.js'

function drawWebhookEventsTimelineChart(
  data: {
    labels: Array<string>
    webhooks: Array<{ name: string; id: string }>
    invocations: Array<Array<WebhooksInvocation>>
  },
  chartRef: Nullable<HTMLCanvasElement>
): Chart | undefined {
  if (chartRef && data?.webhooks?.length && data?.invocations?.length) {
    Chart.register(...registerables)
    const context = chartRef.getContext('2d')

    const gradient = context?.createLinearGradient(0, 0, 0, 250)
    if (gradient) {
      gradient.addColorStop(1, 'rgba(167, 225, 255, 0)')
      gradient.addColorStop(0, 'rgba(167, 225, 255, 1)')
    }

    const chartType: ChartType = 'bar'

    const colors = [
      '#32b6f3',
      '#00c0ec',
      '#00c8da',
      '#00cdc0',
      '#30d0a0',
      '#70d180',
      '#8dce66',
      '#a9c94d',
      '#c6c138',
      '#e3b82b',
      '#ffab2d',
    ]
    // color length > total

    const getColor = (index: number, total: number) => {
      const [, ...pallet] = colors
      const step = pallet.length / (total - 1)
      return colors[Math.round(step * index) || 0]
    }

    const datasets = []

    const average: Array<Array<number>> = []
    const sumArrays = (arrays: Array<Array<number>>): Array<number> =>
      arrays.reduce(
        (acc, array) => acc.map((sum, i) => sum + array[i]),
        new Array(arrays[0]?.length).fill(0)
      )

    data?.webhooks.forEach(({ name, id }, i) => {
      const success: Array<number> = []
      const failed: Array<number> = []
      data.invocations.forEach((invocation, index) => {
        success[index] = 0
        failed[index] = 0
        invocation.forEach((item) => {
          if (item.webhookId === id) {
            success[index] = item.successfulInvocations
            failed[index] = -1 * item.unsuccessfulInvocations // failed marked with negative values
          }
        })
      })

      average.push(sumArrays([success, failed]))
      datasets.push({
        label: name,
        data: success,
        backgroundColor: getColor(i, data.webhooks.length),
        stack: `event ${i}`,
        borderRadius: 50,
        barThickness: 12,
        borderColor: 'rgba(0,0,0,0)',
        borderWidth: 2,
      })
      datasets.push({
        label: name,
        data: failed,
        backgroundColor: getColor(i, data.webhooks.length),
        stack: `event ${i}`,
        borderRadius: 50,
        barThickness: 12,
        borderColor: 'rgba(0,0,0,0)',
        borderWidth: 2,
      })
    })

    datasets.push({
      label: 'Average',
      data: sumArrays(average).map((val) => Math.round(val / average.length)),
      borderColor: '#727883',
      backgroundColor: 'rgba(255,255,0,0)',
      type: 'line',
      pointStyle: false,
      order: 0,
    })

    const getOrCreateLegendList = (id: string) => {
      const legendContainer = document.getElementById(id)
      if (legendContainer) {
        let listContainer = legendContainer.querySelector('ul')

        if (!listContainer) {
          listContainer = document.createElement('ul')
          listContainer.style.display = 'flex'
          listContainer.style.gap = '10px'
          listContainer.style.flexDirection = 'row'
          listContainer.style.justifyContent = 'center'
          listContainer.style.margin = '0'
          listContainer.style.padding = '0'

          legendContainer.appendChild(listContainer)
        }
        return listContainer
      }
    }

    const htmlLegendPlugin = {
      id: 'htmlLegend',
      // @ts-ignore
      afterUpdate(chart: any, args: any, options: any) {
        const ul = getOrCreateLegendList(options.containerID)
        // Remove old legend items
        while (ul?.firstChild) {
          ul.firstChild.remove()
        }

        // Reuse the built-in legendItems generator
        const items: Array<any> =
          chart.options.plugins.legend.labels.generateLabels(chart)

        items.forEach((item, i) => {
          if (i % 2 == 0 && i !== items.length - 1) {
            const li = document.createElement('li')
            li.style.alignItems = 'center'
            li.style.pointerEvents = 'none' // disabled
            li.style.display = 'flex'
            li.style.flexDirection = 'row'

            // Color box
            const boxSpan = document.createElement('span')
            boxSpan.style.background = item.fillStyle
            boxSpan.style.display = 'inline-block'
            boxSpan.style.height = '10px'
            boxSpan.style.marginRight = '8px'
            boxSpan.style.width = '10px'
            boxSpan.style.borderRadius = '5px'

            // Text
            const textContainer = document.createElement('p')
            textContainer.style.color = '#2F3745'
            textContainer.style.margin = '0'
            textContainer.style.padding = '0'
            textContainer.style.fontWeight = '500'
            textContainer.style.textDecoration = item.hidden
              ? 'line-through'
              : ''

            const text = document.createTextNode(item.text)
            textContainer.appendChild(text)

            li.appendChild(boxSpan)
            li.appendChild(textContainer)
            ul?.appendChild(li)
          }
        })
      },
    }

    // @ts-ignore
    Tooltip.positioners.zeroLine = function (items: Array<any>, e: any) {
      // A reference to the tooltip model
      // const tooltip = this;
      // @ts-ignore
      const pos = Tooltip.positioners.average(items, e)
      // @ts-ignore
      const chart = this.chart

      if (pos === false) {
        return false
      }

      const height = ((items.length - 1) / 2) * 24 + 12 + 26 // calculate real tooltip height

      const offset = chart.scales.y.getPixelForValue(0) > e.y ? height : 0

      return {
        x: pos.x,
        y: chart.scales.y.getPixelForValue(0) - offset,
        xAlign: 'center',
        yAlign: 'bottom',
      }
    }

    const getOrCreateTooltip = (chart: any) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div')

      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)'
        tooltipEl.style.borderRadius = '3px'
        tooltipEl.style.color = 'white'
        tooltipEl.style.opacity = 1
        tooltipEl.style.pointerEvents = 'none'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.transform = 'translate(-50%, 0)'
        tooltipEl.style.transition = 'all .1s ease'

        const table = document.createElement('table')
        table.style.margin = '0px'

        tooltipEl.appendChild(table)
        chart.canvas.parentNode.appendChild(tooltipEl)
      }

      return tooltipEl
    }

    const gradientBelow = {
      id: 'gradientBelow',
      // @ts-ignore
      beforeDraw(chart: any, args: any, options: any) {
        const {
          ctx,
          chartArea: { left, right, bottom },
          scales: { y },
        } = chart

        const midY = y.getPixelForValue(0)
        ctx.save()

        const canvas = chart.canvas

        const gradientBack = canvas
          .getContext('2d')
          .createLinearGradient(0, midY, 0, bottom)
        gradientBack.addColorStop(0, options.from)
        gradientBack.addColorStop(1, options.to)

        ctx.fillStyle = gradientBack
        ctx.fillRect(left, midY, right - left, bottom - midY)

        ctx.restore()
      },
    }

    const externalTooltipHandler = ({ chart, tooltip }: any) => {
      const tooltipEl = getOrCreateTooltip(chart)
      let gotItems = false
      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set Text
      if (tooltip.body) {
        //   const midX = x.getPixelForValue(0);
        const midY = chart.scales.y.getPixelForValue(0)

        // const titleLines = tooltip.title || []
        // const bodyLines: Array<any> = tooltip.body.map((b) => b.lines)
        const bodyItems: Array<{
          value: number
          color: string
          label: string
        }> = tooltip.dataPoints.map(({ dataset, raw, label }: any) => ({
          date: label,
          value: raw,
          color: dataset.backgroundColor,
          label: dataset.label,
        }))

        const above = tooltip.caretY < midY ? 0 : 1
        const tableHead = document.createElement('thead')

        // titleLines.forEach((title) => {
        const tr = document.createElement('tr')
        const dateTr = document.createElement('tr')
        tr.style.borderWidth = '0'

        const th = document.createElement('th')
        const dateTh = document.createElement('th')
        th.style.borderWidth = '0'
        th.colSpan = 2
        const text = document.createTextNode(above ? 'Failed' : 'Successful')
        const dateText = document.createTextNode(`${tooltip.title[0]} (UTC)`)

        th.appendChild(text)
        tr.appendChild(th)
        dateTh.appendChild(dateText)
        dateTr.appendChild(dateTh)
        tableHead.appendChild(tr)
        tableHead.appendChild(dateTr)
        // })

        const tableBody = document.createElement('tbody')

        // bodyLines.pop()
        bodyItems.pop()

        const items = bodyItems.filter((_, index) => index % 2 == above)

        const displayItems = items.filter(
          (item) => (above && item.value < 0) || (!above && item.value > 0)
        )

        gotItems = Boolean(displayItems.length)

        displayItems.forEach(({ label, value, color }) => {
          //   const colors = tooltip.labelColors[i]

          const span = document.createElement('span')
          span.style.background = color
          span.style.borderWidth = '2px'
          span.style.marginRight = '10px'
          span.style.height = '10px'
          span.style.width = '10px'
          span.style.display = 'inline-block'

          const lineTr = document.createElement('tr')
          lineTr.style.backgroundColor = 'inherit'
          lineTr.style.borderWidth = '0'

          //   const td1 = document.createElement('td')
          const td2 = document.createElement('td')
          const td3 = document.createElement('td')
          td2.style.borderWidth = '0'
          td3.style.borderWidth = '0'
          td3.style.textAlign = 'right'
          const labelText = document.createTextNode(`${label}:`)
          const valueText = document.createTextNode(
            `${above ? value * -1 : value}`
          )

          td2.appendChild(span)
          td2.appendChild(labelText)
          td3.appendChild(valueText)
          //   lineTr.appendChild(td1)
          lineTr.appendChild(td2)
          lineTr.appendChild(td3)
          tableBody.appendChild(lineTr)
        })

        const tableRoot = tooltipEl.querySelector('table')

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove()
        }

        // Add new children
        tableRoot.appendChild(tableHead)
        tableRoot.appendChild(tableBody)
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
      // Display, position, and set styles for font
      tooltipEl.style.opacity = gotItems ? 1 : 0
      tooltipEl.style.left = positionX + tooltip.caretX + 'px'
      tooltipEl.style.top = positionY + tooltip.caretY + 'px'
      tooltipEl.style.font = tooltip.options.bodyFont.string
      tooltipEl.style.padding =
        tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
    }

    const config = {
      type: chartType,
      data: {
        labels: data.labels,
        datasets,
      },
      options: {
        layout: {
          autoPadding: false,
          padding: 20,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
            position: 'zeroLine',
            external: externalTooltipHandler,
          },

          gradientBelow: {
            from: '#FFD2D2',
            to: 'rgba(255, 210, 210, 0)',
          },
          title: {
            display: false,
          },
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: 'index' as const,
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
      plugins: [gradientBelow, htmlLegendPlugin],
    }

    return new Chart(context as CanvasRenderingContext2D, config as any)
  }
}

export default drawWebhookEventsTimelineChart
