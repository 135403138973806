import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FilterDashboardV2 from '@components/components-complex/filter-dashboard-v2/FilterDashboardV2'
import { EmptyFunc } from '@lib/constants/app.constant'
import { getGenericHostsTableColumnDefinitions } from '@components/components-complex/dashboard-pages-v3/generic-host-inventory-table-complex/columns-definition'
import {
  getBackupsMapped,
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ListGenericHostsInventory } from 'blues-corejs/dist'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAssetItemsForAssetDataFetcher } from '@components/components-complex/dashboard-pages-v3/hooks'
import { getAssetItemsMapWithHostId } from '@components/components-complex/dashboard-pages-v3/generic-host-inventory-table-complex/utils'
import { initialPrioritySortForGenericHostTable } from './initial-priority-sort-for-generic-host-table'
import { ListLiveAssetsItemsRequestFilters } from '@lib/clients/assets-items/types'
import VirtualTable from '@components/table/table-infinite-scroll-memory/virtual-table'
import { ExportAsCsv } from '@features/common'
import {
  GENERIC_HOST_CSV_TABLE_HEADERS,
  prepareGenericHostDataForCsv,
} from './prepare-data-for-csv'
import { getDetectedThreats } from '@components-complex/dashboard-pages-v3/utils'
import { useLayoutContext } from '@features/contexts'

// FIXME: Corner cut
const DEFAULT_GRPC_ASSET_ITEMS_CONFIG: ListLiveAssetsItemsRequestFilters = {
  pageSize: 1000,
}

const HEADER_TITLE = 'Generic Hosts'

function GenericHostInventoryTableComplex() {
  const { setHeaderTitle } = useLayoutContext()
  const genericHostsData = useSelector(getLiveAssets).genericHosts
  const threats = useSelector(getThreats)
  const detectedThreat = getDetectedThreats(threats)
  const { malwareScans, ransomwareScans, fsCheckScans } = useSelector(getScans)
  const { lastBackups } = useSelector(getBackupsMapped)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)
  const [search, setSearch] = useState('')

  const genericHostsDataIds = genericHostsData.map((host) => host.id)

  // Asset Items
  const assetItemsData = useAssetItemsForAssetDataFetcher({
    ...DEFAULT_GRPC_ASSET_ITEMS_CONFIG,
    assetIdList: genericHostsData.length ? genericHostsDataIds : [],
  })

  useEffect(() => {
    if (genericHostsData.length) {
      assetItemsData.onFetchData()
    }
  }, [JSON.stringify(assetItemsData.data), genericHostsData.length])

  const assetItemsListWithHostIds = getAssetItemsMapWithHostId({
    assetItems: assetItemsData.data,
    genericHostsData,
  })

  const onSearchChange = (value: string) => {
    setSearch(value?.trim())
  }

  // Table data
  const { genericHosts, summarizedData } = new ListGenericHostsInventory({
    genericHosts: genericHostsData,
    threats: detectedThreat,
    lastBackups,
    assetItemsListWithHostIds,
    scans: [...malwareScans, ...ransomwareScans, ...fsCheckScans],
  }).execute({ idSearch: search.toLowerCase() })

  const sortedGenericHosts =
    initialPrioritySortForGenericHostTable(genericHosts)

  const isAllDataLoading =
    !isLiveAssetsLoading && assetItemsData.isLoadingData()

  useEffect(() => {
    setHeaderTitle(HEADER_TITLE)
    return () => {
      setHeaderTitle(null)
    }
  }, [])

  return (
    <div className="wrap-1698240379849">
      <FilterDashboardV2
        searchPlaceholder="Search by hostname"
        possibleFilters={[]}
        selectedFilters={[]}
        turnOnSearch
        onFiltersChange={EmptyFunc()}
        onSearchChange={onSearchChange}
        disabled={false}
        searchVal={search}
        showFilterFirst
        tableName=""
        turnOffButton
        turnOnAddPolicyBtn
        className="onlySearch"
      />
      <ExportAsCsv
        data={prepareGenericHostDataForCsv(sortedGenericHosts)}
        headers={GENERIC_HOST_CSV_TABLE_HEADERS}
        filename="generic-hosts-inventory.csv"
        shouldShow={sortedGenericHosts.length > 0}
      />
      <VirtualTable
        config={{
          data: sortedGenericHosts,
          columns: getGenericHostsTableColumnDefinitions({
            summarizedData,
          }),
          state: {
            isLoading: isAllDataLoading,
          },
        }}
        emptyPlaceholderText="No Generic Hosts found"
      />
    </div>
  )
}

export default GenericHostInventoryTableComplex
