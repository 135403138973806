import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import {
  AddAccessToken,
  AccountAndSettings,
  EditAccessTokenId,
  GeneratedAccessTokenId,
} from '@features/account-and-settings'

import { AccountAndSettingsRoutes } from './account-and-settings'

export const accountAndSettingsRoutes: Array<PathRouteProps> = [
  {
    path: AccountAndSettingsRoutes.root,
    element: (
      <MainTopBarLayout title="Account and Settings">
        <AccountAndSettings />
      </MainTopBarLayout>
    ),
  },

  {
    path: AccountAndSettingsRoutes.addAccessToken,
    element: (
      <MainTopBarLayout title="Add Access Token">
        <AddAccessToken />
      </MainTopBarLayout>
    ),
  },

  {
    path: AccountAndSettingsRoutes.editAccessTokenIdPlaceholder,
    element: (
      <MainTopBarLayout title="Edit Access Token">
        <EditAccessTokenId />
      </MainTopBarLayout>
    ),
  },

  {
    path: AccountAndSettingsRoutes.generatedAccessTokenIdPlaceholder,
    element: (
      <MainTopBarLayout title="Generated Access Token">
        <GeneratedAccessTokenId />
      </MainTopBarLayout>
    ),
  },
]
