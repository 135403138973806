import Typography from '@mui/material/Typography'
import React, { PropsWithChildren } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'

export interface ButtonPrimaryOwnProps extends Omit<ButtonProps, 'disabled'> {
  ariaControls?: string
  ariaExpanded?: boolean
  ariaHasPopup?: boolean
  fontSize?: string
  fontWeight?: string | number
  lineHeight?: string
  isDisabled?: boolean
}

function ButtonPrimary({
  children,
  isDisabled,
  onClick,
  ...restProps
}: PropsWithChildren<ButtonPrimaryOwnProps>) {
  const textColor = isDisabled ? 'var(--blue500)' : '#ffffff'
  return (
    <Button
      aria-controls={false ? 'basic-menu' : undefined}
      aria-expanded={false ? 'true' : undefined}
      aria-haspopup="true"
      onClick={onClick}
      variant="contained"
      size="small"
      disabled={isDisabled}
      {...restProps}
    >
      <Typography
        variant="caption"
        fontWeight="600"
        lineHeight="21px"
        fontSize="14px"
        color={textColor}
      >
        {children}
      </Typography>
    </Button>
  )
}

export default ButtonPrimary
