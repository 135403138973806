/* eslint-disable import/no-extraneous-dependencies */
import { Threat } from 'blues-corejs/dist/models/threats'
import React, { useState } from 'react'
import type { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { Table } from '@components/table'
import { ThreatsFilter } from '@features/asset-page/shared/threats-filter'
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
} from 'blues-corejs/dist'
import { OvaBackupSnapshot } from 'blues-corejs/dist/models/backups/ova/backup/ova-backup-snapshot'
import { ListDetailsOvaSnapshotsWithThreats } from 'blues-corejs/dist/use_cases/list_details_ova_snapshots_with_threats'
import { getOvaSnapshotsWithThreatsTableColumnDefinition } from './column-definitions'
import { DetailsOvaSnapshotOutput } from 'blues-corejs/dist/use_cases/list_details_ova_snapshots_with_threats/types'
import { Row } from '@tanstack/react-table'
import SubTableForOvaSnapshotWithThreats from './ova-snapshots-with-threats-sub-table'
import {
  prepareOvaThreatsDataForCsv,
  THREATS_OVA_CSV_TABLE_HEADERS,
} from './prepare-data-for-csv'
import { ExportAsCsv } from '@features/common'
import Box from '@mui/material/Box'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

const computeNoDataMessageForTable = (
  threatsSize: number,
  scansSize: number
): string => {
  if (threatsSize === 0 && scansSize > 0) {
    return 'No Threats found'
  }

  if (threatsSize === 0 && scansSize === 0) {
    return 'No scans performed'
  }

  return ''
}

interface Props {
  threats: Array<Threat>
  scans: Array<Scan>
  snapshots: Array<OvaBackupSnapshot>
}

function OvaSnaphotsWithThreats({ threats, scans, snapshots }: Props) {
  const [selectedFilter, setSelectedFilter] = useState({})
  const { ovaSnapshotsData, possibleFilters } =
    new ListDetailsOvaSnapshotsWithThreats(snapshots, threats).execute(
      selectedFilter
    )
  const isShowExportCsvBtn =
    ovaSnapshotsData.filter((data) => data.threats.length).length > 0

  const handleSelectFilter = (
    e: SelectChangeEvent<Array<string>>,
    key: string
  ) => {
    const value = e.target.value as Array<string>

    setSelectedFilter({
      ...selectedFilter,
      [key]: value.length === 0 ? undefined : value,
    })
  }

  return (
    <Box>
      <Stack marginY={2} direction="row" alignItems="center">
        <ThreatsFilter
          possibleFilter={possibleFilters}
          selectedFilters={selectedFilter}
          onSelectFilter={handleSelectFilter}
        />
        <ExportAsCsv
          data={prepareOvaThreatsDataForCsv(ovaSnapshotsData)}
          headers={THREATS_OVA_CSV_TABLE_HEADERS}
          filename="detected-threats.csv"
          shouldShow={isShowExportCsvBtn}
          marginBottom={0}
        />
      </Stack>
      <Table
        data={ovaSnapshotsData}
        columns={getOvaSnapshotsWithThreatsTableColumnDefinition()}
        generalProps={{
          isLoading: false,
          noDataMessage: computeNoDataMessageForTable(
            threats.length,
            scans.length
          ),
        }}
        advancedProps={{
          renderSubComponent: (props: {
            row: Row<DetailsOvaSnapshotOutput>
          }) => (
            <SubTableForOvaSnapshotWithThreats
              row={props.row}
              selectedFilter={selectedFilter}
            />
          ),
        }}
      />
    </Box>
  )
}

export default OvaSnaphotsWithThreats
