/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { EBS } from 'blues-corejs/dist'
import { AssetId, AccountId, AssetType, Region } from '../shared'
import Grid from '@mui/material/Grid'

import { AttachedState } from './attached-state'
import { AssetTags } from '@features/asset-page/more-details/more-details-components'

export function EbsSummary({ asset }: { asset: EBS }) {
  return (
    <Grid container rowSpacing={2}>
      <Grid container item xs={12}>
        <AssetId asset={asset} />
        <AssetType asset={asset} />
        <AttachedState asset={asset} />
      </Grid>
      <Grid container item xs={12}>
        <Region asset={asset} />
        <AccountId asset={asset} />
        <AssetTags asset={asset} />
      </Grid>
    </Grid>
  )
}
