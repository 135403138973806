import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
/* eslint-disable import/no-extraneous-dependencies */
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'
import { OvaAccount } from 'blues-corejs/dist'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from './consts'
import { useToolbarFiltersActions } from './toolbar-filters-store'

export function useDrilldownQuery(ovaAccountsList: Array<OvaAccount>) {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const statusFilterQuery = urlParams.get(STATUS_FILTER_DRILLDOWN_QUERY_KEY)
  const { setToolbarFiltersState } = useToolbarFiltersActions()

  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  useEffect(() => {
    const parsedStatusFilterQuery = Number(statusFilterQuery)

    if (!statusFilterQuery) {
      return
    }

    let statusesList: Array<OvaServerStatus> | undefined = undefined

    if (parsedStatusFilterQuery === OvaServerStatus.INFECTED) {
      statusesList = [OvaServerStatus.INFECTED]
    } else if (parsedStatusFilterQuery === OvaServerStatus.CLEAN) {
      statusesList = [OvaServerStatus.CLEAN]
    }

    const filteredAccountsList = sliceOvaAccountsList.length
      ? ovaAccountsList.filter((account) =>
          sliceOvaAccountsList.includes(account.id)
        )
      : []

    const filteredBackupProvidersList = sliceOvaBackupProvidersList.length
      ? sliceOvaBackupProvidersList
      : []

    if (!statusFilterQuery || isNaN(parsedStatusFilterQuery) || !statusesList) {
      return
    }

    setToolbarFiltersState({
      ovaAccountsList: filteredAccountsList,
      backupProvidersList: filteredBackupProvidersList,
      statusesList: {
        CLEAN: statusesList.includes(OvaServerStatus.CLEAN),
        INFECTED: statusesList.includes(OvaServerStatus.INFECTED),
      },
    })
  }, [
    ovaAccountsList.length,
    statusFilterQuery,
    sliceOvaAccountsList.length,
    sliceOvaBackupProvidersList.length,
  ])
}
