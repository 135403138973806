import { z } from 'zod'

import { createNameSchema } from './name'

export interface OptionType {
  value: string | number
  label: string
}

const optionSchema = z.object({
  value: z.union([z.string(), z.number()]),
  label: z.string(),
})

const optionArraySchema = z.array(optionSchema).optional()

export interface FormData {
  name: string
  backupVendors?: z.infer<typeof optionArraySchema>
  accounts?: z.infer<typeof optionArraySchema>
  isDefaultDashboard: boolean
}

function getFormSchema(isEdit = false): z.ZodSchema<FormData> {
  return z.object({
    name: createNameSchema(isEdit),
    backupVendors: optionArraySchema.optional(),
    accounts: optionArraySchema.optional(),
    isDefaultDashboard: z.boolean(),
  })
}

const FormSchema: z.ZodSchema<FormData> = getFormSchema()

export { getFormSchema, FormSchema }
