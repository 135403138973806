/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import AssetDetailHeader from '@features/asset-page/more-details/more-details-components/asset-detail-header'
import { AssetTags } from '@features/asset-page/more-details/more-details-components'
import { Asset, EBS, EC2, S3Bucket } from 'blues-corejs/dist'
import { ThreatSummaryDetails } from './threats-summary-details'
import { CustomTabs } from '@components-simple/custom-tab'
import Tab from '@mui/material/Tab'
import { MaterialTab } from '@components-simple/material-tab'
import { useTab } from '@lib/hooks'
import { EntropyTrendsDetails } from './entropy-trends-details'
import { EntropyFiltersProvider } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store.provider'
import { TimeHelper } from '@lib/helpers'

interface Props {
  asset: Asset
  onCloseModalClick: () => void
  isMoreDetailsModalOpen: boolean
}

enum Tabs {
  THREATS = 0,
  ENTROPY_TRENDS = 1,
}

function getInitialTimeRange() {
  const endAt = TimeHelper.getDateWithTimezone(Date.now()).endOf('day')
  const startAt = endAt.clone().startOf('day')

  return {
    startAt: startAt.valueOf(),
    endAt: endAt.valueOf(),
  }
}

function getAssetIdsList(asset: Asset) {
  if (asset instanceof EC2) {
    return [asset.id, ...asset.ebsIds]
  }

  if (asset instanceof EBS) {
    return [asset.id]
  }

  if (asset instanceof S3Bucket) {
    return [asset.id]
  }

  return []
}

export function EntropyForAsset({ asset }: { asset: Asset }) {
  const { startAt, endAt } = getInitialTimeRange()
  const assetIdsList = getAssetIdsList(asset)

  return (
    <EntropyFiltersProvider
      startAt={startAt}
      endAt={endAt}
      assetIdsList={assetIdsList}
    >
      <EntropyTrendsDetails asset={asset} />
    </EntropyFiltersProvider>
  )
}

export function MoreDetailsWithEntropyTrendsDialog({
  asset,
  isMoreDetailsModalOpen,
  onCloseModalClick,
}: Props) {
  const { activeTab, onTabChange } = useTab(Tabs.THREATS)

  return (
    <Dialog
      // FIXME: when we use it, the dialog is not fully rendered.
      // TransitionComponent={MoreDetailsDialogTransition}
      open={isMoreDetailsModalOpen}
      onClose={onCloseModalClick}
      sx={{
        padding: '24px',
      }}
    >
      <DialogContent>
        <AssetDetailHeader
          asset={asset}
          onCloseModalClick={onCloseModalClick}
        />
        <AssetTags asset={asset} />
        <CustomTabs
          sx={{ marginTop: '24px' }}
          value={activeTab}
          onChange={(_, tabIndex) => onTabChange(tabIndex)}
        >
          <Tab label="Threats" value={Tabs.THREATS} />
          <Tab label="Entropy Trends" value={Tabs.ENTROPY_TRENDS} />
        </CustomTabs>
        <MaterialTab value={activeTab} index={Tabs.THREATS}>
          <ThreatSummaryDetails asset={asset} />
        </MaterialTab>
        <MaterialTab value={activeTab} index={Tabs.ENTROPY_TRENDS}>
          <EntropyForAsset asset={asset} />
        </MaterialTab>
      </DialogContent>
    </Dialog>
  )
}
