import OnboardingComplex from '@components-complex/onboarding-complex/OnboardingComplex'

export function OnboardingPage() {
  return (
    <div className="innerContent">
      <div className="controlHeaderBlock">
        <div className="controlHeader">
          <div className="controlHeaderLabel">Elastio Onboarding</div>
        </div>
        <div className="controlDescSmall mb30">
          New to Elastio? Start here with our most common workflows
        </div>
        <OnboardingComplex />
      </div>
    </div>
  )
}
