/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Box, Divider } from '@mui/material'
import clsx from 'clsx'
import ThreatsWidget from '@features/DashboardV4/threats-widgets/threats-widget'
import { ThreatExposureWidget } from '@features/DashboardV4/threats-widgets'
import { useManagementView } from '../common'
import styles from './threats-wrapper.module.css'

function ThreatsWrapper() {
  const { isOvaBackupView } = useManagementView()

  return (
    <Box
      className={clsx(styles.wrapper, {
        [String(styles.backup)]: isOvaBackupView,
      })}
      data-testid="threats-wrapper"
    >
      <ThreatsWidget />
      {!isOvaBackupView && <Divider />}
      <ThreatExposureWidget />
    </Box>
  )
}

export default ThreatsWrapper
