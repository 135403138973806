import { useScheduleScansMutation } from 'ui-v2/src/hooks/queries/pechkin'
import { useToast } from 'ui-v2/src/hooks/toast'
import { ScheduleScanAsset } from 'ui-v2/src/lib/models/assets'
import { Button, CircularProgress } from '@mui/material'

interface AssetScanProps {
  asset: ScheduleScanAsset
}

const AssetScan: React.FC<AssetScanProps> = ({ asset }) => {
  const { mutate, isPending } = useScheduleScansMutation()
  const toast = useToast()

  const scheduleScan = () => {
    mutate([asset], {
      onError: (error) => {
        toast.error(error.message ?? 'Failed to schedule scan')
      },
      onSuccess: (data) => {
        if (data?.[0]?.errorMessage) {
          toast.error('Failed to schedule scan')
        } else {
          toast.success('Scan scheduled successfully')
        }
      },
    })
  }

  return (
    <Button
      variant="contained"
      onClick={scheduleScan}
      disabled={isPending}
      startIcon={
        isPending ? <CircularProgress size={20} color="inherit" /> : null
      }
      sx={{ textTransform: 'capitalize' }}
    >
      Scan
    </Button>
  )
}

export default AssetScan
