// eslint-disable-next-line import/no-extraneous-dependencies
import { OvaAccount } from 'blues-corejs/dist'

export function transformAccountsToSelectOptions(
  accounts: Array<OvaAccount>
): Array<{ value: string; label: string }> {
  return accounts.map((account) => ({
    value: account.id,
    label: account.name,
  }))
}
