import { useEffect, useState } from 'react'
import { DownloadGeneratedReportResponse } from '@lib/clients/reports/generated-reports/download-generated-report'
import { GeneratedReportsClient } from '@lib/clients/reports'

interface Props {
  id: string
}

export function useGetReportDownload({ id }: Props) {
  const generatedReportRequest = new GeneratedReportsClient()

  const [isLoading, setLoading] = useState(false)
  const [report, setReport] = useState<
    DownloadGeneratedReportResponse | undefined
  >(undefined)

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await generatedReportRequest.downloadGeneratedReport({
        id,
      })
      setReport(response)
    } catch (error) {
      if (error instanceof Error) {
        console.log(`Failed to download ${id} report: ${error.message}`)
        throw new Error(`Failed to download ${id} report: ${error.message}`)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  return {
    isLoading,
    report,
  }
}
