import CheckboxBlock from '@components-simple/checkbox-block/CheckboxBlock'
import { EngineCallback } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import React from 'react'

interface Props {
  assetVolumesList: Array<ValueInterface>
  handleChangeVolumesSelected: EngineCallback<Array<ValueInterface>>
  selectAllLabel?: string
}

function VolumesCheckBoxBlock({
  assetVolumesList,
  handleChangeVolumesSelected,
  selectAllLabel = '',
}: Props) {
  return (
    <div className="lightGrayBlock">
      <div className="lightGrayBlockHeader">Mount via CLI</div>
      <div className="mb16">
        Pick the volumes to mount and select the platform. The CLI command will
        be copied to the clipboard.
      </div>

      <CheckboxBlock
        selectAllLabel={selectAllLabel}
        onChange={handleChangeVolumesSelected}
        data={assetVolumesList}
        className="twoColumnsLabel"
      />
    </div>
  )
}

export default VolumesCheckBoxBlock
