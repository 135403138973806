import {
  EngineCallback,
  EngineCallbackPure,
  RowCallback,
  RowSimpleUnit,
} from '@lib/engine-types'
import React, { useCallback, useState } from 'react'

export interface OnMenuClickData<S extends string = string> {
  chosenMenu: S
  data: any
}
export function useRowMenu<T, S extends string>(
  data: T,
  onMenuClick: EngineCallback<OnMenuClickData<S>>
): [
  null | HTMLElement,
  boolean,
  EngineCallback<React.MouseEvent<HTMLElement>>,
  EngineCallbackPure,
  EngineCallback<S>,
] {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuClick = (chosenMenu: S) => {
    setAnchorEl(null)
    onMenuClick({
      chosenMenu,
      data,
    })
  }

  return [anchorEl, open, handleClick, handleClose, handleMenuClick]
}

export function useRawCallbackHandler(
  func: RowCallback,
  id: number,
  data: RowSimpleUnit
) {
  return useCallback(() => func(id, data), [])
}
