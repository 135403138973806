import {
  Box,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import DatepickerInput from 'ui-v2/src/components/ui/inputs/datepicker-input'
import TimepickerInput from 'ui-v2/src/components/ui/inputs/timepicker-input'
import { useSuppressThreatQuery } from 'ui-v2/src/hooks/queries/threat'
import { DEFAULT_TIME_ZONE } from 'ui-v2/src/lib/constants/time.constant'
import { ThreatListTableRow } from 'ui-v2/src/lib/models/assets/infected-assets'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useToast } from 'ui-v2/src/hooks/toast'
import { HourMinuteInterface } from 'ui-v2/src/lib/helpers/time.helper'
import { TimeFormatConstants } from '@lib/constants'
import { useQueryClient } from '@tanstack/react-query'
import Button from 'ui-v2/src/components/ui/inputs/button'

dayjs.extend(utc)
dayjs.extend(timezone)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

interface ThreatFormData {
  endDate: {
    day: Dayjs
    time: HourMinuteInterface
  }
  reason: string
}

const ThreatForm = ({ open, row, handleClose }: ThreatFormProps) => {
  const toast = useToast()

  const userTimezone = DEFAULT_TIME_ZONE().value

  const { control, handleSubmit } = useForm<ThreatFormData>({
    defaultValues: {
      endDate: {
        day: dayjs(),
        time: {
          timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
          hour: dayjs().add(1, 'hour').hour(),
          minute: dayjs().add(1, 'hour').minute(),
        },
      },
      reason: '',
    },
  })

  const { mutate, isPending } = useSuppressThreatQuery()

  const queryClient = useQueryClient()

  const getUtcTimeInSeconds = (
    selectedDate: Dayjs,
    timePickerValue: HourMinuteInterface
  ) => {
    const dateWithTime = selectedDate
      .hour(timePickerValue.hour)
      .minute(timePickerValue.minute)
      .second(0)

    return dateWithTime.utc().unix()
  }

  const handleSubmitSuppress = (data: ThreatFormData) => {
    const utcTimestamp = getUtcTimeInSeconds(
      data.endDate.day,
      data.endDate.time
    )

    mutate(
      {
        threatId: row.id,
        until: utcTimestamp,
        reason: data.reason,
      },
      {
        onSuccess: () => {
          toast.success('Threat Suppression successful')
          queryClient.invalidateQueries({
            queryKey: ['threat', 'list-asset'],
          })
          handleClose()
        },
        onError: (error) => {
          toast.error(error.message ?? 'Failed to download scan report')
        },
      }
    )
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} spacing={2}>
        <Typography variant="h5">Threat Suppression</Typography>
        <Typography whiteSpace={'normal'} sx={{ wordBreak: 'break-word' }}>
          Location: {row.infectedFiles}
        </Typography>
        <Typography>Threat Name: {row.threat}</Typography>
        <Typography>
          Detected: {dayjs(row.detected).format('hh:mm A, MM/DD/YYYY')}
        </Typography>
        <form onSubmit={handleSubmit(handleSubmitSuppress)}>
          <Stack spacing={2}>
            <Box display={'flex'} columnGap={2}>
              <FormControl fullWidth>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatepickerInput
                      value={dayjs(field.value.day)}
                      onChange={(value) => {
                        if (dayjs(value).isValid()) {
                          field.onChange({
                            ...field.value,
                            day: value,
                          })
                        }
                      }}
                      disablePast={true}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <TimepickerInput
                      {...field}
                      label=""
                      value={field.value.time}
                      onChange={(time: any) => {
                        field.onChange({
                          ...field.value,
                          time: {
                            ...time,
                            timezone: userTimezone,
                          },
                        })
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <FormControl fullWidth>
              <Controller
                control={control}
                rules={{
                  required: 'Reason is required',
                }}
                name="reason"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    placeholder="Reason"
                    size="small"
                    slotProps={{
                      htmlInput: { maxLength: 120 },
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </FormControl>
            <Box display={'flex'} justifyContent={'flex-end'} columnGap={2}>
              <Button variant="contained" type="submit" isLoading={isPending}>
                Suppress
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </Box>
          </Stack>
        </form>
      </Stack>
    </Modal>
  )
}
interface ThreatFormProps {
  open: boolean
  row: ThreatListTableRow
  handleClose: () => void
}
export default ThreatForm
