/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { RuleStatus } from 'blues-corejs/dist/models/notifications/rule/status'

interface ReviewEnableActions {
  setReviewEnableData: (data: any) => void
  resetStore: () => void
}

export interface ReviewEnableState {
  reviewEnableData: {
    ruleStatus: RuleStatus
  }
}

export type ReviewEnableStore = ReviewEnableActions & ReviewEnableState

const INITIAL_STATE: ReviewEnableState = {
  reviewEnableData: {
    ruleStatus: RuleStatus.enabled,
  },
}

const useReviewEnableStore = create<ReviewEnableStore>((set) => ({
  ...INITIAL_STATE,
  setReviewEnableData: (data) => set({ reviewEnableData: data }),
  resetStore: () => set({ ...INITIAL_STATE }),
}))
export const resetReviewEnableStore = () => {
  useReviewEnableStore.getState().resetStore()
}

export const useReviewEnableState = () =>
  useReviewEnableStore((state) => state.reviewEnableData)

export const useReviewEnableActions = () =>
  useReviewEnableStore((state) => ({
    setReviewEnableData: state.setReviewEnableData,
    resetStore: state.resetStore,
  }))
