import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  styled,
} from '@mui/material'

interface ButtonProps extends MuiButtonProps {
  isLoading?: boolean
}

const LoaderWrapper = styled('span')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '50%',
  marginTop: -10,
})

const StyledButton = styled(MuiButton)<ButtonProps>(() => ({
  position: 'relative',
  textTransform: 'capitalize',
}))

const Button: React.FC<ButtonProps> = ({
  children,
  isLoading = false,
  disabled,
  ...props
}) => {
  return (
    <StyledButton disabled={disabled || isLoading} {...props}>
      {isLoading && (
        <LoaderWrapper>
          <CircularProgress size={20} color="inherit" thickness={4} />
        </LoaderWrapper>
      )}
      {children}
    </StyledButton>
  )
}

export default Button
