import React from 'react'
import { EngineCallbackPure } from '@lib/engine-types'
import { Button } from '@mui/material'
import { EmptyFunc } from '@lib/constants/app.constant'

interface Props {
  colCount: number
  onFooterClick?: EngineCallbackPure
  isShowFooter?: boolean
}

function ShowMoreVIRow({
  colCount,
  onFooterClick = EmptyFunc,
  isShowFooter = false,
}: Props) {
  return isShowFooter ? (
    <tr className="showMoreRow">
      <td colSpan={colCount}>
        <Button
          onClick={onFooterClick}
          variant="contained"
          className="showMoreBtn"
        >
          Show More
        </Button>
      </td>
    </tr>
  ) : (
    <></>
  )
}

export default ShowMoreVIRow
