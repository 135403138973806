/* eslint-disable import/no-extraneous-dependencies */
import ElastioLogoBlueIcon from '@inline-img/general/elastio-blue-icon'
import AWS from '@inline-img/general/aws-logo-icon'
import {
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { CopyToClipboardIcon } from '@features/common'
import React from 'react'
import { OvaBackup } from 'blues-corejs/dist/models'
import {
  computeOvaBackupProvider,
  computeOvaBackupProviderIcon,
} from '@features/asset-page/modals/recovery-point-details/helpers/ova-rp-utils'
import dayjs, { Dayjs } from 'dayjs'
import { RecoveryPointCreationDate } from './rp-creation-date'

// Since `dayjs` does not have a built-in function to format dates as "X days old", "X hours old", etc.
// We should use this function to format the date like on the design
// FIXME: after date behaviour bug fixed, add here the correct implementation with timezones

function pluralize(value: number, unit: string) {
  return `${value} ${unit}${value === 1 ? '' : 's'} old`
}
export function customFormatDate(target: Dayjs) {
  const now = dayjs()

  const diffYears = now.diff(target, 'year')
  const diffMonths = now.diff(target, 'month')
  const diffDays = now.diff(target, 'day')
  const diffHours = now.diff(target, 'hour')
  const diffMinutes = now.diff(target, 'minute')

  if (diffYears >= 1) {
    return pluralize(diffYears, 'year')
  } else if (diffMonths >= 1) {
    return pluralize(diffMonths, 'month')
  } else if (diffDays >= 1) {
    return pluralize(diffDays, 'day')
  } else if (diffHours >= 1) {
    return '< 1 day old'
  } else if (diffMinutes >= 1) {
    return '< 1 hr old'
  } else {
    return '< 1 min old'
  }
}

function TextRow({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  return (
    <Box>
      <Box component="b" fontWeight="500" fontSize="14px" marginRight="5px">
        {title}:
      </Box>
      {children && children}
    </Box>
  )
}

type Props = {
  backup: Backup
}

const Icon = {
  elastio: <ElastioLogoBlueIcon />,
  aws: <AWS />,
}

function computeBackupIcon(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return Icon.elastio
  } else if (backup instanceof AWSBRecoveryPoint) {
    return Icon.aws
  } else if (backup instanceof EBSSnapshot) {
    return Icon.aws
  } else if (backup instanceof OvaBackup) {
    return computeOvaBackupProviderIcon(backup)
  }
}

function computedbackupName(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return 'Elastio RP'
  } else if (backup instanceof AWSBRecoveryPoint) {
    return 'AWS Backup RP'
  } else if (backup instanceof EBSSnapshot) {
    return 'EBS Snapshot'
  } else if (backup instanceof OvaBackup) {
    return computeOvaBackupProvider(backup)
  }
}

function computeBackupId(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return backup.ccRpId
  } else if (backup instanceof AWSBRecoveryPoint) {
    return backup.arn
  } else if (backup instanceof EBSSnapshot) {
    return backup.arn
  } else if (backup instanceof OvaBackup) {
    return backup.backupProviderBackupId
  }
}

function BackupProvider({ backup }: Props) {
  const computedIcon = computeBackupIcon(backup)
  const computedSourceName = computedbackupName(backup)
  const computedBackupId = computeBackupId(backup)

  return (
    <Box display="flex" alignItems="center" fontSize="14px" height="auto">
      <TextRow title="Provider" />
      <Box display="flex" alignItems="center" gap="5px">
        <Box
          display="flex"
          sx={{
            svg: {
              width: '24px',
              height: '24px',
            },
          }}
        >
          {computedIcon}
        </Box>
        <Typography variant="body2" component="span">
          {computedSourceName}{' '}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box>
            (
            <Box component="span" color="var(--blue500)" fontWeight={600}>
              {computedBackupId}
            </Box>
          </Box>
          <CopyToClipboardIcon
            fontSize={16}
            text={computedBackupId as string}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginX: '8px',
            }}
          />
          <RecoveryPointCreationDate backup={backup} />)
        </Box>
      </Box>
    </Box>
  )
}

export { BackupProvider }
