import { useMemo } from 'react'
import Button, { ButtonProps } from '@mui/material/Button'

type ExtendedVariant = ButtonProps['variant'] | 'el-error'

const restoreStateStrategy = {
  getText: () => 'Restore',
  getVariant: () => 'contained' as ExtendedVariant,
}

const sandboxStateStrategy = {
  getText: () => 'Sandbox',
  getVariant: () => 'el-error' as ExtendedVariant,
}

function useButtonStrategy(isClean: boolean) {
  return useMemo(() => {
    return isClean ? restoreStateStrategy : sandboxStateStrategy
  }, [isClean])
}

export function DynamicButton({
  isCleanRP,
  onClick,
}: {
  isCleanRP: boolean
  onClick: () => void
}) {
  const { getText, getVariant } = useButtonStrategy(isCleanRP)

  return (
    <Button onClick={onClick} variant={getVariant()}>
      {getText()}
    </Button>
  )
}
