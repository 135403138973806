import { DEFAULT_VIEWS, ViewKind } from '@features/DashboardV4/management-view'
import { getViewsFromConfig } from '@features/DashboardV4'
import { useMemo } from 'react'
import { StrHelper } from '@lib/helpers'
import { useSearchParams } from 'react-router-dom'

const DEFAULT_VIEW_INDEX = 0

function useCurrentViewKind() {
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get('t')
  const availableViews = useMemo(
    () => [...DEFAULT_VIEWS, ...getViewsFromConfig()],
    []
  )

  const currentViewIndex = useMemo(() => {
    const decodedIndex = Number(StrHelper.base64Decode(tabIndex))
    return isNaN(decodedIndex) ? DEFAULT_VIEW_INDEX : decodedIndex
  }, [tabIndex])

  return availableViews[currentViewIndex]?.kind ?? ViewKind.AWS
}

export { useCurrentViewKind }
