/* eslint-disable import/no-extraneous-dependencies */
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import React, { Fragment } from 'react'
import { TenantJobStatusChip } from '../../shared'
import {
  useSelectedSystemJobFilters,
  useSystemJobsFiltersActions,
} from '../stores'

export function SelectedJobStatusesChips() {
  const { statusesList } = useSelectedSystemJobFilters()

  const { removeFilter } = useSystemJobsFiltersActions()

  if (!statusesList.size) {
    return null
  }

  function handleRemoveJobStatus(status: TenantJobStatus) {
    removeFilter('statusesList', status)
  }

  return (
    <Fragment>
      {Array.from(statusesList).map((status) => (
        <TenantJobStatusChip
          key={status}
          status={status}
          onDelete={handleRemoveJobStatus}
        />
      ))}
    </Fragment>
  )
}
