import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import ProtectionOptionsCheckbox from '../protection-options-checkbox'
import { ProtectNewImmediately } from 'ui-v2/src/lib/constants/policies.constant'

const Ec2EbsScanTriggers = ({ reviewPolicy }: Ec2EbsScanTriggersProps) => {
  const { control } = useFormContext<PolicyFormData>()

  return (
    <>
      <Controller
        name="protectNewImmediately"
        control={control}
        render={({ field }) => (
          <ProtectionOptionsCheckbox
            label="Scheduled + On-Creation scan"
            description={
              reviewPolicy
                ? ''
                : 'Ensures scheduled scans are always performed, with an additional scan triggered immediately upon the creation of an EC2 or EBS asset.'
            }
            protectionOptionValue={
              field.value === ProtectNewImmediately.ENABLED
            }
            onProtectionOptionChange={(e) => {
              field.onChange(
                e.target.checked
                  ? ProtectNewImmediately.ENABLED
                  : ProtectNewImmediately.DISABLED
              )
            }}
            disabled={reviewPolicy}
          />
        )}
      />
    </>
  )
}
interface Ec2EbsScanTriggersProps {
  reviewPolicy?: boolean
}
export default Ec2EbsScanTriggers
