import { Box, styled, keyframes } from '@mui/material'
import { CIRCLE_STATUS_COLOR } from './consts'

const spinProgress = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
})

const circleAnimation = keyframes({
  '0%': {
    strokeDashoffset: '320',
    transform: 'rotate(0deg)',
  },
  '25%': {
    strokeDashoffset: '320',
    transform: 'rotate(0deg)',
  },
  '50%': {
    strokeDashoffset: '50',
    transform: 'rotate(45deg)',
  },
  '75%': {
    strokeDashoffset: '50',
    transform: 'rotate(45deg)',
  },
  '100%': {
    strokeDashoffset: '320',
    transform: 'rotate(360deg)',
  },
})

export const JobStatusIndicator = styled(Box)({
  width: '22px',
  height: '22px',
  marginRight: '8px',

  svg: {
    width: '22px',
    height: '22px',
  },

  '.successIcon': {
    display: 'none',
  },

  '.errorIcon': {
    display: 'none',
  },

  '&.statSuccessVariant': {
    '.scMainCircle': {
      stroke: CIRCLE_STATUS_COLOR.SUCCESS,
    },
    '.successIcon': {
      display: 'block',
    },
  },

  '&.statFailedVariant': {
    '.scMainCircle': {
      stroke: CIRCLE_STATUS_COLOR.ERROR,
    },
  },

  '&.statAbortedVariant': {
    '.scMainCircle': {
      stroke: CIRCLE_STATUS_COLOR.ABORTED,
    },
  },

  '&.statPendingVariant': {
    '.scMainCircle': {
      stroke: CIRCLE_STATUS_COLOR.WARNING,
    },
  },

  '&.statProgressVariant': {
    animationName: spinProgress,
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    animationDelay: '0s',

    '.scMainCircle': {
      stroke: CIRCLE_STATUS_COLOR.PROGRESS,
      strokeLinecap: 'round',
      strokeDasharray: '324',
      strokeDashoffset: '320',
      WebkitTransformOrigin: '3.8rem 3.8rem',
      transform: 'rotate(-90deg)',
      animationName: circleAnimation,
      animationDuration: '3s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      animationDelay: '0s',
    },
    '.successIcon': {
      display: 'none',
    },
  },
})
