import React from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'

export type Value = string | number

export interface OptionType {
  value: Value
  label: string
}

interface BasicSelectOwnProps {
  value: Value
  onChange: (event: SelectChangeEvent<Value>) => void
  options: Array<OptionType>
  placeholder?: string
  isLoading?: boolean
  error?: boolean
  disabled?: boolean
  inputSize?: 'small' | 'medium'
  onSelectedClick?: () => void
}

function BasicSelect({
  value,
  onChange,
  options,
  disabled,
  placeholder = '',
  isLoading = false,
  error = false,
  inputSize = 'small',
  onSelectedClick,
  ...props
}: BasicSelectOwnProps) {
  const handleOnSelectedClick = (optionValue: Value) => {
    if (!onSelectedClick || optionValue !== value) {
      return
    }

    onSelectedClick()
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      displayEmpty
      renderValue={(selectedValue) => {
        if (!selectedValue) {
          return (
            <Typography
              component="em"
              sx={(theme) => ({
                color: theme.palette.text.disabled,
                fontSize: theme.typography.body2.fontSize,
              })}
            >
              {placeholder}
            </Typography>
          )
        }
        const selectedOption = options.find(
          (option) => option.value === selectedValue
        )
        return selectedOption ? selectedOption.label : selectedValue
      }}
      disabled={isLoading || disabled}
      input={<OutlinedInput size={inputSize} margin="dense" />}
      error={error}
      {...props}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value.toString()}
          value={option.value}
          onClick={() => handleOnSelectedClick(option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default BasicSelect
