import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { Chip, IconButton, styled } from '@mui/material'
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'

const StyledChip = styled(Chip)({
  background: '#d6f0fd',
  borderRadius: '4px',
  '.MuiChip-label': {
    fontSize: '12px',
    fontWeight: 500,
    paddingRight: '0',
  },
})

const StyledDeleteIcon = styled(IconButton)({
  height: '18px',
  width: '18px',
  backgroundColor: 'transparent',
  fontWeight: 600,
  '&.MuiChip-deleteIcon': {
    color: '#32b6f3 !important',
    lineHeight: '18px',
    fontSize: '18px',
    margin: '0 5px 0 5px',
    width: 'auto',
    '&:hover': {
      color: '#32b6f3',
      backgroundColor: 'transparent',
    },
  },
})

interface Props {
  filterName: string
  filterValue: string
  onDelete?: () => void
}

export function BaseSelectedChip({ filterName, filterValue, onDelete }: Props) {
  const label = `${filterName}: ${filterValue}`

  return (
    <StyledChip
      label={<LongTextTooltip text={label} maxLength={40} />}
      deleteIcon={
        <StyledDeleteIcon>
          <ClearIcon fontSize="inherit" />
        </StyledDeleteIcon>
      }
      onDelete={onDelete}
      sx={{
        height: 'auto',
        paddingY: '10px',
        '& .MuiChip-label': {
          width: '100%',
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
    />
  )
}
