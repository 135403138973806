"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckStatus = exports.StatusDeletion = exports.ElastioRecoveryPointStatus = exports.ElastioRecoveryPointKind = void 0;
var ElastioRecoveryPointKind;
(function (ElastioRecoveryPointKind) {
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["EC2"] = 0] = "EC2";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["EBS"] = 1] = "EBS";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["S3"] = 2] = "S3";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["POSTGRESQL"] = 3] = "POSTGRESQL";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["FILE"] = 4] = "FILE";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["STREAM"] = 5] = "STREAM";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["GENERIC_VOLUME"] = 6] = "GENERIC_VOLUME";
    ElastioRecoveryPointKind[ElastioRecoveryPointKind["GENERIC_DISK"] = 7] = "GENERIC_DISK";
})(ElastioRecoveryPointKind || (exports.ElastioRecoveryPointKind = ElastioRecoveryPointKind = {}));
var ElastioRecoveryPointStatus;
(function (ElastioRecoveryPointStatus) {
    ElastioRecoveryPointStatus[ElastioRecoveryPointStatus["PENDING"] = 0] = "PENDING";
    ElastioRecoveryPointStatus[ElastioRecoveryPointStatus["COMMITTED"] = 1] = "COMMITTED";
})(ElastioRecoveryPointStatus || (exports.ElastioRecoveryPointStatus = ElastioRecoveryPointStatus = {}));
var StatusDeletion;
(function (StatusDeletion) {
    StatusDeletion[StatusDeletion["INSERVICE"] = 0] = "INSERVICE";
    StatusDeletion[StatusDeletion["SOFTDELETE"] = 1] = "SOFTDELETE";
    StatusDeletion[StatusDeletion["HARDDELETE"] = 2] = "HARDDELETE";
})(StatusDeletion || (exports.StatusDeletion = StatusDeletion = {}));
// TODO: move to scan-status.ts
var CheckStatus;
(function (CheckStatus) {
    CheckStatus[CheckStatus["NOT_APPLICABLE"] = 0] = "NOT_APPLICABLE";
    CheckStatus[CheckStatus["UNCHECKED"] = 1] = "UNCHECKED";
    CheckStatus[CheckStatus["GOOD"] = 2] = "GOOD";
    CheckStatus[CheckStatus["BAD"] = 3] = "BAD";
})(CheckStatus || (exports.CheckStatus = CheckStatus = {}));
