/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useEffect } from 'react'
import {
  requestAccountRedStacks,
  requestAccountVaultList,
  requestAllActiveRedStacks,
  requestVaultList,
  setAccountVaultList,
  setVaultAsDefault,
} from '@store/actions/vault.action'
import { useDispatch, useSelector } from 'react-redux'
import PagePathConstant from '@lib/constants/page-path.constant'
import StrHelper from '@lib/helpers/str.helper'
import PreloaderConstants from '@lib/constants/preloader.constant'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import {
  getAccountRedStackVersions,
  getAccountVaultList,
  getActiveAccountRedStacks,
  getInactiveAccountRedStacks,
} from '@store/selectors/vault.selector'
import LangHelper from '@lib/helpers/lang.helper'
import DataHelper from '@lib/helpers/data.helper'
import VaultModel from '@lib/models/vault.model'
import { useModal } from '@lib/hooks/useModal'
import AddVaultModal, {
  AddVaultModalInputData,
} from '@components-composite/modals/AddVaultModal'
import FormAddVaultInterface from '@lib/interfaces/form/form-add-vault.interface'
import Modal from '@lib/constants/modal.constant'
import RedStackModel from '@lib/models/red-stack.model'
import TextConstant from '@lib/constants/text.constant'
import clsx from 'clsx'
import { Button } from '@mui/material'
import DialogModal from '@components-composite/modals/DialogModal'
import SystemHelper from '@lib/helpers/system.helper'
import { VIRow } from '@lib/engine-types'
import InfoHeader from '@components-composite/info-header/InfoHeader'
import ReconnectModal from '@components-composite/modals/ReconnectModal'
import { activateSources } from '@store/actions/source-id.action'
import DetailInfoCard from '@components-composite/detail-info-card/DetailInfoCard'
import { DetailInfoCardEntity } from '@lib/constants/detail-info.constant'
import ContentBlock from '@components-composite/content-block/ContentBlock'
import usePreloader from '@lib/hooks/usePreloader'
import CloudConnectorBackupTimeline from '@components-complex/cloud-connector-backup-timeline/CloudConnectorBackupTimeline'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { useScheduleTenantJob } from '@features/scheduled-jobs-monitoring/hooks'
import GrpcVaultService from '@lib/services/grpc/grpc-vault.service'
import { useParams } from 'react-router-dom'
import { Link, VaultDetailedRoutes, DeploymentRoutes } from '@lib/router'

function SourceVaultsComplex() {
  const { id: dynamicId } = useParams()
  const { setBreadcrumbsPaths, setHeaderTitle } = useLayoutContext()

  const accountId = StrHelper.base64Decode(dynamicId)
  const dispatch = useDispatch()

  const { scheduleTenantJob } = useScheduleTenantJob()

  useEffect(() => {
    dispatch(requestVaultList())
    dispatch(requestAllActiveRedStacks())
    setBreadcrumbsPaths([
      {
        href: PagePathConstant.SOURCES,
        text: titlesForBreadcrumb(PagePathConstant.SOURCES) ?? '',
      },
    ])
  }, [])

  useEffect(() => {
    if (accountId) {
      dispatch(setAccountVaultList([]))
      dispatch(requestAccountVaultList(accountId))
      dispatch(requestAccountRedStacks(accountId))
      setHeaderTitle(accountId)
    }
    return () => {
      setHeaderTitle(null)
    }
  }, [accountId])

  const vaults = useSelector(getAccountVaultList)

  // grouping by a region and removing "softDeleted" vaults
  const groupedByRegion = DataHelper.groupByField<string, VaultModel>(
    vaults.filter((vault) => !vault.softDeleted),
    'region'
  )

  const inactiveAccountRedStacks = useSelector(getInactiveAccountRedStacks)
  const activeAccountRedStacks = useSelector(getActiveAccountRedStacks)

  const possibleRegionsTranslation: VIRow = activeAccountRedStacks.map((v) => ({
    name: v.awsRegion,
    label: `${LangHelper.getAwsRegionNameSingleTranslation(v.awsRegion)} (${
      v.awsRegion
    })`,
    value: v.version,
    extraValue: v.redStackId,
  }))

  const accountRedStackVersions: Array<string> = useSelector(
    getAccountRedStackVersions
  )
  const requireUpdate = activeAccountRedStacks.some(
    ({ cfnUpgradeRequired }) => cfnUpgradeRequired
  )

  // need warning in case we have multiple Account RedStack versions
  if (accountRedStackVersions.length > 1) {
    SystemHelper.throwErrorInLocalEnv(
      'Multiple Account RedStack versions on one account'
    )
  }

  const vaultListLoading = usePreloader(
    PreloaderConstants.REQUEST_ACCOUNT_VAULT_LIST
  )
  const newVaultModalLoading = usePreloaderAny([
    PreloaderConstants.REQUEST_ACCOUNT_RED_STACKS,
    PreloaderConstants.SET_VAULT_AS_DEFAULT,
    PreloaderConstants.CREATE_VAULT,
  ])

  const {
    openModal: openReconnectSourcesModal,
    modalProps: ReconnectSourcesModalProps,
  } = useModal<Array<RedStackModel>>(
    Modal.connectSources,
    (rsArr: Array<RedStackModel>) => {
      rsArr.forEach((r) => {
        dispatch(activateSources(r.innerId, r.awsRegion, r.awsAccount))
      })
    }
  )

  // add vault
  const { openModal: openAddVaultModal, modalProps: vaultAddModalProps } =
    useModal<AddVaultModalInputData, FormAddVaultInterface>(
      Modal.addVault,
      async (form: FormAddVaultInterface) => {
        const redStackByRegion = activeAccountRedStacks.find(
          (rsm: RedStackModel) => rsm.awsRegion === form.region
        )

        const jobId = await GrpcVaultService.createVault({
          vaultName: form.vaultName,
          vpcId: form.vpc,
          subnetIdsList: form.subnets ?? [],
          redStackId: redStackByRegion?.redStackId ?? '',
          accountId,
          safetyLock: form.safetyLock,
        })

        await scheduleTenantJob(jobId)
      }
    )

  // set up vault as default
  const {
    openModal: openSetVaultAsDefaultModal,
    modalProps: setVaultAsDefaultModalProps,
  } = useModal<VaultModel>(Modal.setVaultAsDefault, (vault) =>
    dispatch(setVaultAsDefault(vault))
  )

  const onAddVault = (regionName: string) => {
    openAddVaultModal({
      possibleRegionsTranslation: possibleRegionsTranslation.filter(
        ({ name }) => name == regionName
      ),
      accountId,
      vaults: vaults.filter((vault) => !vault.softDeleted),
    })
  }

  return (
    <div className="wrap-1649835996758">
      <div className="controlHeaderBlock">
        <div className="controlHeader">
          {accountRedStackVersions.length > 0 && (
            <span className="svValueVersion jsVersion">
              <b>Version:</b> {accountRedStackVersions[0]}
            </span>
          )}
        </div>
        {!newVaultModalLoading && requireUpdate && (
          <div className="updateNotification">
            <div className="updateNotificationBody">
              <Button
                variant="contained"
                color="primary"
                href={DeploymentRoutes.buildCloudConfigureUpgradeUrl(accountId)}
              >
                Upgrade now!
              </Button>
              <span>
                Please update to the latest version to with the latest security
                patches and features.
              </span>
            </div>
          </div>
        )}

        <div className="controlDescText">
          Vaults are used to store recovery points in the cloud. The vaults are
          compressed and deduplicated object storage that are automatically
          scaled based on demand and the space is managed automatically based on
          retention policies.
        </div>
      </div>

      {!newVaultModalLoading && inactiveAccountRedStacks.length !== 0 && (
        <InfoHeader
          buttons={[
            {
              onClick: () =>
                openReconnectSourcesModal(inactiveAccountRedStacks),
              label: 'Reconnect...',
              isDisabled: false,
              type: 'green',
            },
            {
              onClick: () =>
                SystemHelper.openInNewTab(
                  'https://docs.elastio.com/docs/tenant/sources#reconnect-elastio-source'
                ),
              label: 'Help',
              isDisabled: false,
              icon: 'launch_outlined',
              type: 'blue',
            },
          ]}
          type="error"
          title="Connection has been lost."
          description={TextConstant.NO_CLOUD_CONNECTORS_ARE_FOUND}
        />
      )}

      {/* Regions */}

      <ContentBlock
        data={possibleRegionsTranslation}
        loading={vaultListLoading}
        noDataMessage="There are no vaults yet"
      >
        <div
          className={clsx('jsSourceVaultTableWrap', {
            controlsDisabled: vaultListLoading,
          })}
        >
          {possibleRegionsTranslation.map((region) => (
            <div key={region.name} className="mscBlock">
              <div className="msIdRegionName">
                <div className="msIdRegionNameLabel">{region.label}</div>
                <div className="msIdRegionInfo">
                  <div className="msIdRegionVersion">
                    <b>Version:</b> {region.value}
                  </div>
                  <Button
                    className="jsCreateRegionVault createRegionVault"
                    disabled={
                      newVaultModalLoading ||
                      activeAccountRedStacks.length === 0
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => onAddVault(region.name)}
                  >
                    + New Vault
                  </Button>
                </div>
              </div>

              {/*  Chart  if data?*/}
              <div className="">
                <CloudConnectorBackupTimeline
                  cloudConnectorId={String(region.extraValue)}
                />
              </div>

              {groupedByRegion.get(region.name) ? (
                <>
                  {groupedByRegion
                    .get(region.name)
                    ?.map((vault: VaultModel) => (
                      <div className="vaultBlock" key={vault.name}>
                        <div className="titleWrap">
                          <div className="titleText">
                            {vault.name}
                            {vault.isDefault ? (
                              <div className="badge">Default</div>
                            ) : (
                              <Button
                                className="link"
                                disabled={newVaultModalLoading}
                                onClick={() =>
                                  openSetVaultAsDefaultModal(vault)
                                }
                                variant="text"
                              >
                                Make Default
                              </Button>
                            )}
                          </div>
                          <Link
                            to={VaultDetailedRoutes.buildPathWithEncodedId(
                              vault.innerId
                            )}
                          >
                            <span className="link">
                              <span>Details</span>
                            </span>
                          </Link>
                        </div>

                        {/* Card */}

                        <div className="cardArea">
                          <DetailInfoCard
                            title="Assets"
                            type={DetailInfoCardEntity.Success}
                          >
                            {vault.assetsCount}
                          </DetailInfoCard>

                          <DetailInfoCard
                            title="Allocated"
                            hint="Allocated size is the size of all successful backups. It might differ from the total size of the S3 bucket as Elastio stores data on unsuccessful operations as well."
                            type={DetailInfoCardEntity.Success}
                          >
                            {vault.allocatedSizeFormatted}
                          </DetailInfoCard>
                          <DetailInfoCard
                            title="Backup Size"
                            type={DetailInfoCardEntity.Success}
                          >
                            {vault.backUpSizeFormatted}
                          </DetailInfoCard>
                          <DetailInfoCard
                            title="Reduction"
                            type={DetailInfoCardEntity.Success}
                          >
                            {vault.reductionFactorFormatted}
                          </DetailInfoCard>
                        </div>
                      </div>
                    ))}
                </>
              ) : !vaultListLoading ? (
                <InfoHeader
                  buttons={[
                    {
                      onClick: () => onAddVault(region.name),
                      label: 'Create Vault',
                      isDisabled: false,
                      type: 'blue',
                    },
                  ]}
                  type="error"
                  title="No Vaults Found"
                  description="There is no vault in the specific AWS Account and region."
                />
              ) : null}
            </div>
          ))}
        </div>
      </ContentBlock>

      {/* Modals */}

      <ReconnectModal {...ReconnectSourcesModalProps} />

      <AddVaultModal {...vaultAddModalProps} loading={newVaultModalLoading} />
      <DialogModal
        description="Are you sure you want to set this vault as default?"
        {...setVaultAsDefaultModalProps}
      />
    </div>
  )
}

export default memo(SourceVaultsComplex)
