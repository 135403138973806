import { Typography, useTheme } from '@mui/material'

const PolicLabelValue = ({ label, value }: PolicLabelValueProps) => {
  const theme = useTheme()
  return (
    <Typography
      whiteSpace={'nowrap'}
      gap={1}
      mb={2}
      display={'flex'}
      alignItems={'flex-start'}
      variant="body2"
    >
      {label}
      <Typography
        fontStyle={'italic'}
        color={theme.palette.mode === 'dark' ? '#FFDD89' : '#c99002'}
        component={'span'}
        variant="body2"
        whiteSpace={'normal'}
      >
        {value}
      </Typography>
    </Typography>
  )
}
interface PolicLabelValueProps {
  label: string
  value: string
}
export default PolicLabelValue
