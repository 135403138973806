import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PaginationModel from '@lib/models/pagination.model'
import useLocalModelPagination from '@lib/hooks/pagination/useLocalModelPagination'
// eslint-disable-next-line import/no-extraneous-dependencies
import { InstanceModel, VolumeModel } from 'blues-corejs/dist'
import { requestDashboardData } from '@store/actions/dashboard.action'
import { getDashboardData } from '@store/selectors/dashboard.selector'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ListAssetsCoveredWithRetentionPolicy } from 'blues-corejs/dist/use_cases'

export interface PolicyRetentionAssetsProps {
  currentPolicy: string
  isInfoMode: boolean
}

function usePolicyRetentionAssetsData(currentPolicy: string) {
  const dispatch = useDispatch()

  const [assetsList, setAssetsList] = useState<
    Array<InstanceModel | VolumeModel>
  >([])
  const isLoading = usePreloaderAny([
    PreloaderConstants.REQUEST_ASSETS_POLICY_RETENTION,
    PreloaderConstants.REQUEST_DASHBOARD_DATA,
  ])

  const inventoryState = useSelector(getDashboardData)

  useEffect(() => {
    dispatch(requestDashboardData())
  }, [])

  useEffect(() => {
    if (!inventoryState) {
      return
    }
    const assets = new ListAssetsCoveredWithRetentionPolicy(
      inventoryState
    ).execute(currentPolicy)

    setAssetsList(assets)
  }, [JSON.stringify(inventoryState)])

  // pagination
  const { paginatedData, pagination, setPagination } = useLocalModelPagination<
    Array<InstanceModel | VolumeModel>
  >(assetsList, 0, 10)

  const onPaginationChange = (newPagination: PaginationModel) => {
    setPagination(newPagination)
  }

  const onSearchChange = (value: string) => {
    if (!inventoryState) {
      return
    }

    const filteredAssets = new ListAssetsCoveredWithRetentionPolicy(
      inventoryState
    ).execute(currentPolicy, value)

    setAssetsList(filteredAssets)
  }

  return {
    isLoading,
    data: {
      assetsList,
      pagination,
      paginatedData,
    },
    onPaginationChange,
    onSearchChange,
  }
}

export default usePolicyRetentionAssetsData
