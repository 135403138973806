/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { ThreatSummary } from '@features/asset-page/shared'
import {
  LatestCleanBackup,
  MostRecentBackup,
  MostRecentScan,
} from '@features/asset-page/more-details/more-details-components'
import {
  MoreDetailsTabs,
  MoreDetailsTabsContent,
} from './more-details-tabs-content'
import { useFetchLastBackupForAsset } from '../use-fetch-last-backup'
import { useFetchThreatsForAsset } from '../use-fetch-threats-for-asset'
import { useFetchLastScansForAsset } from '../use-fetch-last-scans-for-asset'
import { ThreatStatus } from 'blues-corejs/dist/models/threats/types'
import { useMoreDetailsTabsState } from '../use-more-details-tabs-state'
import { Asset } from 'blues-corejs/dist'
import Skeleton from '@mui/material/Skeleton'

interface Props {
  asset: Asset
}

export function ThreatSummaryDetails({ asset }: Props) {
  const { lastBackupForAsset, isBackupLoading } =
    useFetchLastBackupForAsset(asset)
  const { threats, isLoadingThreats, fetchDataWithoutLoader } =
    useFetchThreatsForAsset(asset, [ThreatStatus.DETECTED])
  const { lastScans, isLoadingLastScans } = useFetchLastScansForAsset(asset)

  const {
    getIsSliderTabSelected,
    getIsTableTabSelected,
    selectedTab,
    setSelectedTab,
  } = useMoreDetailsTabsState(asset, lastBackupForAsset)

  const isSliderTabSelected = getIsSliderTabSelected()

  const isTableTabSelected = getIsTableTabSelected()

  const shouldRenderLatestCleanBackup = threats.length > 0

  const shouldRenderMostRecentBackup = lastBackupForAsset !== undefined

  const shouldRenderMostRecentScan = lastScans.length > 0

  const isDataLoading =
    isLoadingThreats || isLoadingLastScans || isBackupLoading

  if (isDataLoading) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />
  }

  return (
    <>
      <ThreatSummary sx={{ marginTop: '24px' }} threats={threats} />
      {shouldRenderLatestCleanBackup && <LatestCleanBackup asset={asset} />}
      {shouldRenderMostRecentScan && (
        <MostRecentScan scans={lastScans} asset={asset} />
      )}
      {shouldRenderMostRecentBackup && (
        <MostRecentBackup backup={lastBackupForAsset} />
      )}

      <MoreDetailsTabs
        asset={asset}
        selectedTab={selectedTab}
        onChangeTab={setSelectedTab}
        backup={lastBackupForAsset}
      />
      <MoreDetailsTabsContent
        asset={asset}
        backup={lastBackupForAsset}
        dataThreats={threats}
        scansList={lastScans}
        isSliderTabSelected={isSliderTabSelected}
        isTableTabSelected={isTableTabSelected}
        fetchDataThreats={fetchDataWithoutLoader}
      />
    </>
  )
}
