/* eslint-disable import/no-cycle */
import { DatetimeDataInterface } from '../helpers/time.helper'

export enum PolicyStatus {
  POLICY_STATUS_DISABLED = 0,
  POLICY_STATUS_ENABLED = 1,
}

export enum DefaultRetentionPoliciesConstant {
  DEFAULT = 'Default',
}

export enum TableTabsConstant {
  EBS = 'EBS',
  EC2 = 'EC2',
  S3 = 'S3',
  OTHER = 'Other',
  UNREACHABLE = 'Unreachable',
  EFS = 'EFS',
}

export enum SnapshotImport {
  SKIP = 0,
  LATEST = 1,
  ALL = 2,
}

export enum PoliciesFilterLabel {
  PROTECTED = 'Protected',
  UNPROTECTED = 'Unprotected',
}

export enum ProtectNewImmediately {
  UNSPECIFIED = 0,
  DISABLED = 1,
  ENABLED = 2,
}

export enum SelectedCloudConnectorVariant {
  ALL = 0,
  CUSTOM = 1,
}

export enum IntegrityCheckOptions {
  // Do not run integrity checks
  INTEGRITY_CHECK_DISABLED = 0,
  // Run file system integrity checks
  INTEGRITY_CHECK_FILE_SYSTEM = 1,
}

export enum SelectedVariant {
  AllEC2Variant = 0,
  AllEBSVariant = 1,
  AllEC2EBSVariant = 2,
  AssetsVariant = 3,
  TagsVariant = 4,
  S3Variant = 5,
  EfsVariant = 6,
  AllS3Variant = 7,
}
export enum IntegrityScanOptions {
  SCAN_WITH_ELASTIO,
  SCAN_AND_BACKUP_WITH_ELASTIO,
  BACKUP_WITH_ELASTIO,
}
export interface KeepDataCopyInterface {
  keepLastClean: boolean
  keepLatestInfected: boolean
}
export interface ScanWithTimestampsInterface {
  isScanObjects: boolean
  dateTimeData: DatetimeDataInterface
}
export enum TabAssetType {
  Ec2andEbs = 0,
  S3 = 1,
  EFS = 2,
}
export const INVALID_POLICY_NAME =
  'Policy name can only contain alphanumeric characters, spaces and +-._:/@.The name can\'t begin with "+", "/" or whitespace, and can\'t end with whitespace.'
