/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import DownloadIcon from './download-icon'
import {
  ReportGenerated,
  ReportGeneratedPending,
} from 'blues-corejs/dist/models/reports/report-generated'
import { ActionMenu } from '@components/table'
import CircularProgress from '@mui/material/CircularProgress'
import { GeneratedReportsClient } from '@lib/clients/reports'
import { DownloadGeneratedReportResponse } from '@lib/clients/reports/generated-reports/download-generated-report'
import { useTabStore } from '@features/reports/schedules/use-data-store'

const PENDING = 'PENDING'

const GeneratedReportRequest = new GeneratedReportsClient()

const getOptions = (
  files: DownloadGeneratedReportResponse | undefined,
  handleDownloadFile: (url: string) => void
) => {
  if (!files) {
    return []
  }

  const result = []
  const { csvUrl, pdfUrl, xlsxUrl } = files

  if (csvUrl) {
    result.push({
      label: 'CSV',
      onClick: () => handleDownloadFile(csvUrl),
    })
  }
  if (pdfUrl) {
    result.push({
      label: 'PDF',
      onClick: () => handleDownloadFile(pdfUrl),
    })
  }

  if (xlsxUrl) {
    result.push({
      label: 'XLSX',
      onClick: () => handleDownloadFile(xlsxUrl),
    })
  }

  return result
}

function CellActionsMenuItems({ row }: { row: ReportGenerated }) {
  const [isLoading, setLoading] = useState(false)
  const { actions, generatedDownloadReports } = useTabStore()
  const loadedFiles = generatedDownloadReports[row.id]
  const [files, setFiles] = useState<
    DownloadGeneratedReportResponse | undefined
  >(loadedFiles)

  const { id, status } = row

  const handleDownloadFile = (url: string) => {
    window.open(url, '_blank')
  }

  const options = getOptions(files, handleDownloadFile)

  useEffect(() => {
    if (files && options?.length && options.length === 1 && !loadedFiles) {
      options[0]?.onClick()
    }
  }, [files, id])

  if (status instanceof ReportGeneratedPending) {
    return <Box sx={{ textAlign: 'right' }}>{PENDING}</Box>
  }

  if (files) {
    if (options.length === 1) {
      return (
        <Box
          sx={{
            textAlign: 'right',
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              padding: '5px 8px',
              cursor: 'pointer',
            }}
            onClick={options[0]?.onClick}
          >
            <DownloadIcon />
          </Box>
        </Box>
      )
    }

    return <ActionMenu options={options} />
  }

  const handleDownload = async () => {
    setLoading(true)
    try {
      const response = await GeneratedReportRequest.downloadGeneratedReport({
        id,
      })
      setFiles(response)
      actions.setGeneratedDownloadReports(response, id)
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to download ${id} report: ${error.message}`)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        textAlign: 'right',
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          padding: '5px 8px',
          cursor: 'pointer',
        }}
        onClick={handleDownload}
      >
        {isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
      </Box>
    </Box>
  )
}

export default CellActionsMenuItems
