import { SvgIcon, SvgIconProps } from '@mui/material'

const ReportsActive = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 0,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M12.9 9.3H17.85L12.9 4.35V9.3ZM6.59999 3H13.8L19.2 8.4V19.2C19.2 19.6774 19.0103 20.1352 18.6728 20.4728C18.3352 20.8104 17.8774 21 17.4 21H6.59999C5.60099 21 4.79999 20.19 4.79999 19.2V4.8C4.79999 3.801 5.60099 3 6.59999 3ZM7.49999 19.2H9.29999V13.8H7.49999V19.2ZM11.1 19.2H12.9V12H11.1V19.2ZM14.7 19.2H16.5V15.6H14.7V19.2Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ReportsActive
