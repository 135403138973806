import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { JobStatusClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

interface Options {
  enabled?: boolean
}

export function useJobsListQuery(options?: Options) {
  const jobStatusClient = useGrpcClient(JobStatusClient)

  return useQuery({
    queryKey: ['job-status', 'list-jobs'],
    queryFn: () => {
      if (!jobStatusClient) {
        throw new Error('Job status client not initialized')
      }
      return jobStatusClient.listJobs()
    },
    enabled:
      !!jobStatusClient &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
