/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProvider } from 'blues-corejs/dist'
import { OvaBackupProviderProto } from './grpc-imports'

export class OvaBackupProviderTransformer {
  static #ovaBackupProviderProtoMapping: Record<
    OvaBackupProvider,
    OvaBackupProviderProto
  > = {
    [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
    [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
    [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
    [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
    [OvaBackupProvider.AWS_BACKUP_VMWARE]:
      OvaBackupProviderProto.AWS_BACKUP_VMWARE,
    [OvaBackupProvider.VERITAS_NET_BACKUP]:
      OvaBackupProviderProto.VERITAS_NET_BACKUP,
  }

  static transformToProtoDefinition(
    provider: OvaBackupProvider
  ): OvaBackupProviderProto {
    return this.#ovaBackupProviderProtoMapping[provider]
  }
}
