/* eslint-disable import/no-extraneous-dependencies */
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'

type Scan = {
  filesystemCheckScan?: FilesystemScanCheck
  malwareScan?: MalwareScan
  ransomwareScan?: RansomwareScan
}

export function getLastScanningdDate(scans: Array<Scan>) {
  const lastScannedDate = scans.reduce((acc, scan) => {
    const scanDate =
      scan.ransomwareScan?.createdAt.getTime() ||
      scan.malwareScan?.createdAt.getTime() ||
      scan.filesystemCheckScan?.createdAt.getTime()
    return scanDate && scanDate > acc ? scanDate : acc
  }, 0)

  return lastScannedDate
}
