import { VIRow } from 'ui-v2/src/lib/engine-types'
import {
  PolicyTagsTableDataAttrs,
  PolicyTagsTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'

export class PolicyTagsTableData {
  readonly #policyTags: VIRow

  #reviewPolicy: boolean | undefined

  constructor(parameters: PolicyTagsTableDataAttrs) {
    this.#policyTags = parameters.policyTags
    this.#reviewPolicy = parameters.reviewPolicy
  }

  getPolicyTagList = (): Array<PolicyTagsTableRow> => {
    return this.#policyTags.map((policy, index) => {
      return new PolicyTagsTableRow({
        id: index,
        tagKey: policy.name,
        tagValue: policy.value as string,
        action: '',
        reviewPolicy: !!this.#reviewPolicy,
      })
    })
  }
}
