import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ListEfsFilters } from 'ui-v2/src/lib/models/assets'
import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'
import { AssetsFilters, PolicyCoverageState } from 'ui-v2/src/lib/models/assets'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import {
  ASSET_FILTERS_MAP,
  NAME_TAG_KEY,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { ListEfsRequest } from 'ui-v2/src/lib/grpc'

export const useAssetsEfsFilters = (): AssetsFilters<ListEfsFilters> => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setPageToken(undefined)
  }, [searchParams])

  return useMemo(() => {
    return {
      filters: {
        pageSize,
        pageToken,
        search: searchParams.get(SEARCH_PARAM) ?? undefined,
        assetIdList: searchParams
          .get(ASSET_FILTERS_MAP.VOLUME_ID)
          ?.split(',')
          .filter(Boolean),
        filesystemStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.FILESYSTEM_STATE)?.split(',') ??
            [],
          ListEfsRequest.Initial.Filter.FilesystemState
        ),
        tagsList: searchParams
          .get(ASSET_FILTERS_MAP.VOLUME_NAME)
          ?.split(',')
          .filter(Boolean)
          .map((name) => ({
            key: NAME_TAG_KEY,
            value: name,
          })),
        protectionPolicyCoverageStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.POLICY_COVERAGE)?.split(',') ?? [],
          PolicyCoverageState
        ),
      },
      setPageSize,
      setPageToken,
    }
  }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
}
