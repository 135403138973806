import {
  KrabsAWSPromiseClient,
  AwsAccountRegionStatisticsPb,
} from '@lib/clients/grpc-imports'
import { GrpcClient } from '@lib/clients/grpc-client'
import { Filter as AwsScanFilter } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/messages/get_aws_scan_statistics_pb'
import type { AwsScanStatisticsResponse } from '@lib/clients/krabs-aws-statistics/types'

interface KrabsAwsStatisticsRequestParams {
  accountIds?: Array<string>
  regions?: Array<string>
}

export class KrabsAwsStatisticsClient extends GrpcClient<KrabsAWSPromiseClient> {
  #krabsAwsStatisticsClient: KrabsAWSPromiseClient

  constructor(hostName = '') {
    super()
    this.#krabsAwsStatisticsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'KrabsAWSPromiseClient'
  }

  protected initClient(hostName = ''): KrabsAWSPromiseClient {
    return new KrabsAWSPromiseClient(hostName, null, null)
  }

  async getAwsScanStatistics(
    params: KrabsAwsStatisticsRequestParams
  ): Promise<AwsScanStatisticsResponse> {
    const request = new AwsAccountRegionStatisticsPb()
    const filter = new AwsScanFilter()
    if (params?.accountIds) {
      filter.setAccountIdsList(params.accountIds)
    }
    if (params.regions) {
      filter.setRegionsList(params.regions)
    }

    request.setFilter(filter)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#krabsAwsStatisticsClient.getAwsScanStatistics(
            request,
            this.metadata()
          ),
        {
          requestName: 'KrabsAWSPromiseClient/getAwsScanStatistics',
        }
      )
    ).toObject()

    return {
      accountRegionStatisticsList: response.accountRegionStatsList,
    }
  }
}
