import React from 'react'
import { Button, ButtonProps, styled, Box, Typography } from '@mui/material'
import FilterIcon from '@inline-img/general/filter-icon'

const DEFAULT_LABEL = 'Filter'

interface Props extends ButtonProps {
  onButtonClick: () => void
  label?: string
}

const StyledButton = styled(Button)({
  minWidth: '100px !important',
  maxWidth: '100px',
  '.btnIconWrap': {
    marginRight: '10px',
  },
})

export function FilterModalButton({
  onButtonClick,
  label = DEFAULT_LABEL,
  ...restProps
}: Props) {
  return (
    <StyledButton {...restProps} variant="contained" onClick={onButtonClick}>
      <Box className="btnIconWrap">
        <FilterIcon />
      </Box>
      <Typography fontSize="14px" fontWeight="600" variant="caption">
        {label}
      </Typography>
    </StyledButton>
  )
}
