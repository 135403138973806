/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import type { BoxProps } from '@mui/material/Box'
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { DateRangePickerWithCallAPI } from '@components-composite/date-range-picker-with-call-api'
import { TimeRange } from '@lib/clients/types'
import {
  useInProgressJobsList,
  useIsLoadingJobs,
  useJobsList,
  useAssetsMap,
  useAssetJobsStoreActions,
  useTimeRange,
} from './store'
import { getJobsTableColumnDefinitions } from './helpers'
import { useTrackCloudConnectorJobs } from './hooks'
import { Asset } from 'blues-corejs/dist'

import styles from './asset-jobs-table.module.css'

interface AssetJobsTableOwnProps extends BoxProps {
  asset: Asset
}

/**
 * Renders a tab component for displaying asset jobs in table view.
 */
export function AssetJobsTable({
  asset,
  ...restProps
}: AssetJobsTableOwnProps) {
  const isLoadingJobsList = useIsLoadingJobs()

  const jobsList = useJobsList()

  const inProgressJobsList = useInProgressJobsList()

  const assetsMap = useAssetsMap()

  const { timeRangeToDisplay } = useTimeRange()

  const {
    fetchInitialJobsList,
    fetchPaginatedJobsList,
    resetAssetJobsStore,
    setJobsList,
  } = useAssetJobsStoreActions()

  const observable = useInfiniteScrollDataFetch({
    initialItems: jobsList,
    fetchNextBatch: fetchPaginatedJobsList,
  })

  function handleDateRangeChange(newTimeRange: TimeRange) {
    fetchInitialJobsList({
      timeRange: newTimeRange,
    })
  }

  useEffect(() => {
    fetchInitialJobsList({
      assetIdsList: [asset.id],
    })

    return () => {
      resetAssetJobsStore()
    }
  }, [])

  useTrackCloudConnectorJobs({
    inProgressJobsList,
    onJobsChange: (changedJobs) => {
      const newJobsList = jobsList.map((job) => {
        const changedJob = changedJobs.find((j) => j.id === job.id)
        return changedJob || job
      })

      setJobsList(newJobsList)
    },
  })

  return (
    <Box {...restProps} display="flex" flexDirection="column" gap="16px">
      <DateRangePickerWithCallAPI
        defaultDateRange={timeRangeToDisplay}
        onDateRangeChange={handleDateRangeChange}
      />
      <Table
        className={styles['jobs-table']}
        data={jobsList}
        advancedProps={{
          expandableRowKey: 'childrenList',
          observableState: observable,
        }}
        columns={getJobsTableColumnDefinitions({
          jobAssetMap: assetsMap,
        })}
        generalProps={{
          isLoading: isLoadingJobsList,
        }}
      />
    </Box>
  )
}
