/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import { AwsFileScansStatsResponse } from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { useSelector } from 'react-redux'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

const INITIAL_FILE_SCANS_COUNT: AwsFileScansStatsResponse = {
  cleanFiles: 0,
  infectedFiles: 0,
}

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsFileScans() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )

  const [isLoading, setIsLoading] = useState(false)
  const [fileScansCount, setFileScansCount] =
    useState<AwsFileScansStatsResponse>(INITIAL_FILE_SCANS_COUNT)

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)

    const fetchFileScansStats = async () => {
      try {
        const response = await awsStatsClient.getFileScansStats({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (isMounted) {
          setFileScansCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getFileScansStats: failed to fetch file scans count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    const fetchInterval = setInterval(
      fetchFileScansStats,
      INTERVAL_REQUEST_TIMEOUT
    )
    fetchFileScansStats()

    return () => {
      clearInterval(fetchInterval)
      isMounted = false
      setFileScansCount(INITIAL_FILE_SCANS_COUNT)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    ...fileScansCount,
  }
}
