/* eslint-disable import/no-extraneous-dependencies */
import {
  ListScansForAssetClient,
  ListScansForAssetFilter,
} from '@lib/clients/scans/list-scans-for-asset'
import { ListThreatsForAssetClient } from '@lib/clients/threats/list-threats-for-asset'
import SystemHelper from '@lib/helpers/system.helper'
import {
  AssetItem,
  EFS,
  EfsItem,
  GenericHost,
  S3Bucket,
  S3Item,
  Threat,
} from 'blues-corejs/dist'
import { useEffect, useRef, useState } from 'react'
import {
  ListScansForAssetItemsClient,
  ListScansForAssetItemsFilter,
} from '@lib/clients/scans/list-scans-for-asset-items'
import { ScansRun } from 'blues-corejs/dist/models'
import { ObjHelper } from '@lib/helpers'

const listScansForAssetClient = new ListScansForAssetClient()
const listScansForAssetItemsClient = new ListScansForAssetItemsClient()
const threats = new ListThreatsForAssetClient()

function fetchScansForAsset(asset: Asset | AssetItem) {
  const isAsset =
    asset instanceof EFS ||
    asset instanceof S3Bucket ||
    asset instanceof GenericHost

  if (isAsset) {
    return listScansForAssetClient.listScansForAsset(
      new ListScansForAssetFilter({
        assetIdList: [asset.id],
        onlyForAsset: true,
      })
    )
  }
  return listScansForAssetItemsClient.listScansForAssetItems(
    new ListScansForAssetItemsFilter({
      assetItemId: asset.id,
      assetIdList: [asset.assetId],
    })
  )
}

async function processAsset(asset: Asset | AssetItem) {
  const scans = await fetchScansForAsset(asset)
  const firstScanRun = scans.scansRunList[0]

  if (!firstScanRun) {
    return {
      [asset.id]: {
        asset: asset,
        scans: {
          filesystemChecksList: [],
          malwareScansList: [],
          ransomwareScansList: [],
        } as ScansRun,
        threats: [] as Array<Threat>,
      },
    }
  }

  const threatsIds = [
    ...firstScanRun.filesystemChecksList,
    ...firstScanRun.malwareScansList,
    ...firstScanRun.ransomwareScansList,
  ].flatMap((scan) => scan.threatIds)

  if (threatsIds.length === 0) {
    return {
      [asset.id]: {
        asset: asset,
        scans: firstScanRun,
        threats: [] as Array<Threat>,
      },
    }
  }

  const { threatsList } = await threats.listThreats(null, threatsIds)

  return {
    [asset.id]: {
      asset: asset,
      scans: firstScanRun,
      threats: threatsList,
    },
  }
}

function sortAssets(a: Asset | Item, b: Asset | Item) {
  const aIsAsset =
    a instanceof EFS || a instanceof S3Bucket || a instanceof GenericHost

  const bIsAsset =
    b instanceof EFS || b instanceof S3Bucket || b instanceof GenericHost

  if (aIsAsset) {
    return -1
  } else if (bIsAsset) {
    return 1
  } else {
    return b.createdAt.getTime() - a.createdAt.getTime()
  }
}

type Asset = S3Bucket | EFS | GenericHost

type Item = AssetItem | S3Item | EfsItem

export interface TableDefinition {
  threats: Array<Threat>
  scans: ScansRun
  asset: Asset | Item
}

type TableDefinitionRecord = Record<string, TableDefinition>

export function useProcessDataForTable(assets?: Array<Asset | AssetItem>) {
  const [tableData, setTableData] = useState<TableDefinitionRecord>({})
  const [isLoading, setIsLoading] = useState(false)

  const previousAssets = useRef<Array<Asset | AssetItem>>([])

  useEffect(() => {
    async function getAllScans() {
      if (ObjHelper.isEqual(previousAssets.current, assets)) {
        return
      }

      if (!assets) {
        return
      }

      try {
        setIsLoading(true)
        const response = await Promise.all<TableDefinitionRecord>(
          assets.map(processAsset)
        )
        const responseRecord = Object.assign(
          {},
          ...response
        ) as TableDefinitionRecord

        setTableData(responseRecord)
        previousAssets.current = assets
      } catch (error) {
        SystemHelper.sendObjectToSentryIfProd({
          message: 'scan-items-table: error in useProcessDataForTable',
          error,
        })
      } finally {
        setIsLoading(false)
      }
    }

    getAllScans()
  }, [assets?.length])

  return {
    data: Object.values(tableData).sort((a, b) => sortAssets(a.asset, b.asset)),
    isLoading,
  }
}
