/* eslint-disable import/no-extraneous-dependencies */
import { VolumesWithThreatsTable } from '@features/asset-page/tables'
import {
  AssetSlider,
  ThreatsList,
  OvaBackupSlider,
} from '@features/asset-page/shared'
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
  ElastioRecoveryPoint,
  Backup,
  EBS,
  Threat,
  Asset,
} from 'blues-corejs/dist'
import {
  AssetItem,
  EBSSnapshot,
  OvaBackup,
  OvaServer,
} from 'blues-corejs/dist/models'
import { ElastioRecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/elastio/types'
import React, { useMemo, useState } from 'react'
import { useGetInstanceForSlider } from '../helpers'
import { OvaBackupSnapshot } from 'blues-corejs/dist/models/backups/ova/backup/ova-backup-snapshot'
import { OvaSnapshotsWithThreatsTable } from 'features/asset-page/tables/ova-snapshot-with-threats'
import { EngineCallback } from '@lib/engine-types'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

function getThreatsForSelectedVolume(threatList: Array<Threat>, volume?: EBS) {
  if (!volume) {
    return []
  }

  return threatList.filter(
    (threat) =>
      threat.source.asset.assetId === volume.id ||
      threat.source.assetItem?.assetId === volume.id
  )
}

interface Props {
  isTableTabSelected: boolean
  isSliderTabSelected: boolean
  asset: Asset
  backup: Backup
  dataThreats: Array<Threat>
  scansList: Array<Scan>
  assetItem?: AssetItem
  fetchDataThreats: EngineCallback<void>
}

function Ec2ElastioRecoveryPointStrategy({
  asset,
  backup,
  dataThreats,
  scansList,
  isTableTabSelected,
  fetchDataThreats,
}: Props) {
  const preselectedVolume = asset instanceof EBS ? asset : undefined

  const [selectedVolume, setSelectedVolume] = useState<EBS | undefined>(
    preselectedVolume
  )

  const threatsForSelectedVolume = useMemo(
    () => getThreatsForSelectedVolume(dataThreats, selectedVolume),
    [selectedVolume, dataThreats]
  )

  const instance = useGetInstanceForSlider({
    asset,
    backup,
  })

  if (!instance || !(backup instanceof ElastioRecoveryPoint)) {
    return null
  }

  if (isTableTabSelected) {
    return (
      <VolumesWithThreatsTable
        instance={instance}
        scans={scansList}
        threats={dataThreats}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  function handleSlideChange(volume: EBS) {
    setSelectedVolume(volume)
  }

  return (
    <>
      <AssetSlider
        instance={instance}
        backup={backup}
        onSlideChange={handleSlideChange}
        preselectedVolume={preselectedVolume}
        threats={dataThreats}
      />
      <VolumesWithThreatsTable
        instance={instance}
        scans={scansList}
        threats={threatsForSelectedVolume}
        preselectedVolume={selectedVolume}
        fetchDataThreats={fetchDataThreats}
      />
    </>
  )
}

const getThreatsForSelectedOvaSnapshot = (
  threatList: Array<Threat>,
  snapshot?: OvaBackupSnapshot
) => {
  if (!snapshot) {
    return []
  }

  return threatList.filter(
    (threat) =>
      threat.source.asset?.assetId === snapshot.ovaVolumeId ||
      threat.source.assetItem?.assetItemId === snapshot.ovaVolumeId
  )
}

const sortOvaSnapshotsByThreatsCount = (
  snapshots: Array<OvaBackupSnapshot>,
  threatsList: Array<Threat>
) => {
  return snapshots.sort((snapshotA, snapshotB) => {
    const threatsLengthForFirst = getThreatsForSelectedOvaSnapshot(
      threatsList,
      snapshotA
    ).length
    const threatsLengthForSecond = getThreatsForSelectedOvaSnapshot(
      threatsList,
      snapshotB
    ).length

    return threatsLengthForSecond - threatsLengthForFirst
  })
}

function OvaBackupStrategy({
  asset,
  backup,
  dataThreats,
  scansList,
  isTableTabSelected,
  fetchDataThreats,
}: {
  isTableTabSelected: boolean
  asset: OvaServer
  backup: OvaBackup
  dataThreats: Array<Threat>
  scansList: Array<Scan>
  fetchDataThreats: EngineCallback<void>
}) {
  const snapshots = sortOvaSnapshotsByThreatsCount(
    [...backup.snapshots],
    dataThreats
  )
  const preselectedOvaSnapshot = snapshots[0]
  const [selectedOvaSnapshot, setSelectedOvaSnapshot] = useState<
    OvaBackupSnapshot | undefined
  >(preselectedOvaSnapshot)

  const threatsForSelectedOvaSnapshot = useMemo(
    () => getThreatsForSelectedOvaSnapshot(dataThreats, selectedOvaSnapshot),
    [selectedOvaSnapshot, dataThreats]
  )

  if (isTableTabSelected) {
    return (
      <OvaSnapshotsWithThreatsTable
        scans={scansList}
        threats={dataThreats}
        snapshots={snapshots}
      />
    )
  }
  if (isTableTabSelected) {
    return (
      <ThreatsList
        scans={scansList}
        threats={dataThreats}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  function handleSnaspshotChange(snapshot: OvaBackupSnapshot) {
    setSelectedOvaSnapshot(snapshot)
  }

  return (
    <>
      <OvaBackupSlider
        server={asset}
        snapshots={snapshots}
        onSnapshotChange={handleSnaspshotChange}
        threats={dataThreats}
      />
      <ThreatsList
        scans={scansList}
        threats={threatsForSelectedOvaSnapshot}
        fetchDataThreats={fetchDataThreats}
      />
    </>
  )
}

export function RecoveryPointTabsContent({
  isTableTabSelected,
  isSliderTabSelected,
  assetItem,
  asset,
  backup,
  dataThreats,
  scansList,
  fetchDataThreats,
}: Props) {
  if (
    backup instanceof ElastioRecoveryPoint &&
    backup.kind === ElastioRecoveryPointKind.EC2
  ) {
    return (
      <Ec2ElastioRecoveryPointStrategy
        asset={asset}
        backup={backup}
        dataThreats={dataThreats}
        scansList={scansList}
        assetItem={assetItem}
        isTableTabSelected={isTableTabSelected}
        isSliderTabSelected={isSliderTabSelected}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  if (backup instanceof ElastioRecoveryPoint) {
    return (
      <ThreatsList
        scans={scansList}
        threats={dataThreats}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  if (backup instanceof EBSSnapshot) {
    return (
      <ThreatsList
        scans={scansList}
        threats={dataThreats}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  if (asset instanceof OvaServer && backup instanceof OvaBackup) {
    return (
      <OvaBackupStrategy
        asset={asset}
        backup={backup}
        dataThreats={dataThreats}
        scansList={scansList}
        isTableTabSelected={isTableTabSelected}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  return null
}
