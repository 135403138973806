import React, {
  createContext,
  useContext,
  ReactNode,
  PropsWithChildren,
} from 'react'
import { NavigationStrategy } from '../types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSpaRouter } from '@lib/router/navigation-strategies/hooks/use-spa-router'

interface NavigationContextValue {
  navigationStrategy: NavigationStrategy
}

const NavigationContext = createContext<NavigationContextValue | undefined>(
  undefined
)

interface NavigationProviderProps {
  children: ReactNode
}

export default function NavigationProvider({
  children,
}: PropsWithChildren<NavigationProviderProps>) {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const navigationStrategy = useSpaRouter({
    navigate,
    location,
    searchParams,
  })

  return (
    <NavigationContext.Provider
      value={{ navigationStrategy: navigationStrategy }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

/**
 * Custom hook to access the navigation strategy from the context.
 * @example
 * ```tsx
 * const navigation = useNavigation();
 * navigation.push('/');
 * ```
 */
export const useNavigation = (): NavigationStrategy => {
  const context = useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider')
  }
  return context.navigationStrategy
}
