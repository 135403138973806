import {
  Stack,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputAdornment,
} from '@mui/material'

import Grid from '@mui/material/Grid2'
import Select from '@mui/material/Select'

const Regions = () => {
  return (
    <Stack maxWidth={700}>
      <Typography
        mb={2.5}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        Configure Recovery Assurance, Recovery and Scan Settings.
      </Typography>
      <Grid container spacing={2} pb={2}>
        <Grid size={12}>
          <FormControl fullWidth size="small">
            <Typography variant="body2" mb={1}>
              Select
            </Typography>
            <Select
              // value={reports}
              // onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    color="text.secondary"
                  >
                    Us East(N. Virginia)
                  </Typography>
                </InputAdornment>
              }
            >
              <MenuItem value={0} disabled>
                us-east
              </MenuItem>
              <MenuItem value={1}>us-east-1</MenuItem>
              <MenuItem value={2}>us-east-2</MenuItem>
              <MenuItem value={3}>us-east-3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Button
            variant="contained"
            size="medium"
            disableElevation
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Deploy Stack
          </Button>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Regions
