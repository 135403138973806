import { useEffect, useState } from 'react'
import {
  Asset,
  ListAssetsByIdsClient,
} from '@lib/clients/assets/list-assets-by-ids'
import SystemHelper from '@lib/helpers/system.helper'

const assetsByIdsClient = new ListAssetsByIdsClient()

export interface ListAssetsByIdsOutput {
  assetsList: Array<Asset>
  isAssetsLoading: boolean
}

/**
 * Custom hook to get assets by `assetIds`.
 * @param assetIds - asset ids array
 * @returns list of assets and loading state
 */
export function useFetchAssetsByIds(
  assetIds: Array<string>
): ListAssetsByIdsOutput {
  const [assetsList, setAssetsList] = useState<Array<Asset>>([])
  const [isAssetsLoading, setIsAssetsLoading] = useState(false)

  useEffect(() => {
    async function fetchAssetsByIds() {
      if (!assetIds.length) {
        setAssetsList([])
        return
      }
      setIsAssetsLoading(true)

      try {
        const response = await assetsByIdsClient.listAssetsByIds(assetIds)
        setAssetsList(response.assetsList)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong in ${__filename}: ${error}`
        )
        console.error(
          `Error occurred in file ${__filename} while processing: ${error}`
        )
      } finally {
        setIsAssetsLoading(false)
      }
    }

    fetchAssetsByIds()
  }, [JSON.stringify(assetIds)])

  return {
    assetsList,
    isAssetsLoading,
  }
}
