import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import { Button, Box } from '@mui/material'
import { EngineCallbackPure } from '@lib/engine-types'

type RestoreControlPanelOwnProps = {
  isLoading: boolean
  isSubmitDisabled: boolean
  onCancel: EngineCallbackPure
  onSubmit: () => void
}
function RestoreControlPanel({
  isLoading,
  onCancel,
  onSubmit,
  isSubmitDisabled,
}: RestoreControlPanelOwnProps) {
  return (
    <BasePortalButtons alwaysInPortal>
      <div className="forms-button-panel">
        <Box display="flex" justifyContent="space-between">
          <Button
            disabled={isLoading}
            className="cancel-button"
            type="button"
            variant="text"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          {isLoading && <PreloaderSmall show top={18} right={170} />}
          <Button
            disabled={isSubmitDisabled}
            className="restore-button"
            color="primary"
            variant="contained"
            type="submit"
            onClick={onSubmit}
          >
            Restore
          </Button>
        </Box>
      </div>
    </BasePortalButtons>
  )
}

export default RestoreControlPanel
