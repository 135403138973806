import React from 'react'

interface Props {
  completeLinkLabel?: string
  statusIntegrityDI: number
  completeLinkIscan?: string
  completeLinkFsCheck?: string
  statusIscan: number
  statusFsCheck: number
  className?: string
}

// TODO: deprecate
function DataItemStatus({}: Props) {
  return <div />
}

export default DataItemStatus
