/* eslint-disable import/no-extraneous-dependencies */
import { JobTenant } from 'blues-corejs/dist'

interface UpdatedJobsInterface {
  updatedData: Array<JobTenant>
  updatedElements: Array<JobTenant>
}

function getIsJobChanged({
  existingJob,
  job,
}: {
  existingJob?: JobTenant
  job: JobTenant
}): boolean {
  if (!existingJob) {
    return false
  }

  const childrenListChanged =
    existingJob.childrenList.length !== job.childrenList.length
  const updatedAtChanged =
    existingJob.updatedAt.getTime() !== job.updatedAt.getTime()
  const statusChanged = existingJob.status !== job.status

  const someChildStateChanged = existingJob.childrenList.some((child) => {
    return job.childrenList.some((newChild) => {
      return (
        child.id === newChild.id &&
        (child.status !== newChild.status ||
          child.updatedAt.getTime() !== newChild.updatedAt.getTime())
      )
    })
  })

  return (
    childrenListChanged ||
    updatedAtChanged ||
    statusChanged ||
    someChildStateChanged
  )
}

export function getUpdatedJobs({
  jobsFromServer,
  jobsInState,
}: {
  jobsInState: Array<JobTenant>
  jobsFromServer: Array<JobTenant>
}): UpdatedJobsInterface {
  const jobsInStateCopied = [...jobsInState]
  const updatedJobs: Array<JobTenant> = []

  for (const job of jobsFromServer) {
    const existingJobIndex = jobsInStateCopied.findIndex(
      ({ id }) => id === job.id
    )

    if (existingJobIndex === -1) {
      jobsInStateCopied.push(job)
      updatedJobs.push(job)
      continue
    }

    const existingJob = jobsInStateCopied[existingJobIndex]

    const isJobChanged = getIsJobChanged({
      existingJob,
      job,
    })

    if (isJobChanged) {
      jobsInStateCopied[existingJobIndex] = job
      updatedJobs.push(job)
    }
  }

  return {
    updatedData: jobsInStateCopied,
    updatedElements: updatedJobs,
  }
}
