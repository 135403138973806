import ErrorBoundary from '@components/shared/error-boundary'
import { TimeRangeProvider } from '@components-context/time-range'
import { AssetPage as AssetPageComponent } from '@features/asset-page/asset-page'
import React from 'react'

function AssetPageWrapper() {
  return (
    <div className="innerContent innerContentBlueBackground jsInstance">
      <ErrorBoundary>
        <TimeRangeProvider>
          <AssetPageComponent />
        </TimeRangeProvider>
      </ErrorBoundary>
    </div>
  )
}
export default AssetPageWrapper
