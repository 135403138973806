/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { CloudConnectorJobsJobsStore } from '.'
import { getSelectedFilters } from '..'
import { getInitialState } from './consts'
import { fetchInitialJobsList, fetchJobsListViaPagination } from './actions'

export const useCloudConnectorJobsStore = create<CloudConnectorJobsJobsStore>(
  (set, get) => ({
    ...getInitialState(),
    actions: {
      resetCloudConnectorJobsState: () => set(getInitialState),
      setCloudConnectorJobsList: (cloudConnectorJobsList) =>
        set({ cloudConnectorJobsList }),
      fetchInitialCloudConnectorJobs: async () => {
        const { selectedFilters, timeRange } = getSelectedFilters()

        set({
          loadingState: 'loading',
          cloudConnectorJobsList: [],
        })

        const { jobsList, pageToken } = await fetchInitialJobsList(
          selectedFilters,
          timeRange
        )

        set({
          cloudConnectorJobsList: jobsList,
          pageToken,
          loadingState: 'success',
        })
      },
      fetchPaginatedCloudConnectorJobs: async () => {
        const { pageToken } = get()

        if (!pageToken) {
          return
        }

        set({ loadingState: 'loading' })

        const cloudConnectorJobs = await fetchJobsListViaPagination(pageToken)

        set((state) => ({
          cloudConnectorJobsList: [
            ...state.cloudConnectorJobsList,
            ...cloudConnectorJobs.jobsList,
          ],
          pageToken: cloudConnectorJobs.pageToken,
          loadingState: 'success',
        }))
      },
    },
  })
)
