/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import PlusToggleBtn from '@components-simple/plus-toggle-btn/PlusToggleBtn'
import RedStackModel from '@lib/models/red-stack.model'
import { createColumnHelper } from '@tanstack/react-table'
import { Asset, JobCloudConnector } from 'blues-corejs/dist'
import {
  AssetKindCell,
  JobStatusCell,
  ProgressCell,
  ResourceIdCell,
} from './cells'
import {
  getHumanReadableCloudConnectorJobKind,
  formatRedStackAccountAndRegion,
  getCloudConnectorJobStatusMessage,
} from './helpers'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

const columns = createColumnHelper<JobCloudConnector>()

const MAX_TEXT_LENGTH = 150

interface ColumnsDefinitionsProps {
  allRedStacksList: Array<RedStackModel>
  assetMap: Map<string, Asset>
}

export function getColumnsDefinitions({
  allRedStacksList,
  assetMap,
}: ColumnsDefinitionsProps) {
  return [
    columns.accessor('childrenList', {
      header: '',
      enableSorting: false,
      cell: ({ row, getValue }) => {
        const childrenList = getValue()

        if (childrenList.length === 0) {
          return null
        }

        return (
          <PlusToggleBtn
            expanded={row.getIsExpanded()}
            onChange={row.toggleExpanded}
          />
        )
      },
    }),
    columns.accessor('status', {
      header: '',
      enableSorting: false,

      cell: ({ getValue }) => <JobStatusCell status={getValue()} />,
    }),
    columns.accessor('createdAt', {
      header: 'Scheduled On',
      enableSorting: false,

      cell: ({ getValue }) => (
        <DateTimeTooltip
          className="deployment-jobs-no-wrap-cell"
          direction="row"
          date={getValue().getTime()}
        />
      ),
    }),
    columns.accessor('kind', {
      header: 'Job Type',
      enableSorting: false,
      cell: ({ getValue, row: { original } }) =>
        getHumanReadableCloudConnectorJobKind(getValue(), original),
    }),
    columns.accessor('ccId', {
      header: 'Cloud Connector',
      enableSorting: false,

      cell: ({ getValue }) =>
        formatRedStackAccountAndRegion({
          ccId: getValue(),
          allRedStacksList,
        }),
    }),
    columns.accessor('targetAssetId', {
      header: 'Asset Kind',
      enableSorting: false,

      cell: ({ getValue }) => {
        const asset = assetMap.get(getValue())

        return <AssetKindCell asset={asset} />
      },
    }),
    columns.accessor('targetAssetId', {
      id: 'resource-id',
      enableSorting: false,

      header: 'Resource ID',
      cell: ({ getValue }) => {
        const asset = assetMap.get(getValue())

        return <ResourceIdCell asset={asset} />
      },
    }),

    columns.accessor('statusMessage', {
      header: 'Message',
      enableSorting: false,
      cell: ({ row: { original } }) =>
        getCloudConnectorJobStatusMessage({ job: original }),
    }),
    columns.accessor('name', {
      header: 'Task',
      enableSorting: false,
      maxSize: 100,
      meta: {
        whiteSpace: 'pre-line',
      },
      cell: ({ getValue }) => (
        <LongTextTooltip
          customClass=" task-cell"
          text={getValue()}
          maxLength={MAX_TEXT_LENGTH}
        />
      ),
    }),
    columns.display({
      id: 'progress',
      header: 'Completed On',
      enableSorting: false,

      cell: ({ row: { original } }) => {
        return <ProgressCell job={original} />
      },
    }),
  ]
}
