import { useForm } from 'react-hook-form'

import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'
import { useMemo } from 'react'
import {
  getCurrentView,
  transformAccountData,
} from '@features/management-view/backup-connector/form/helpers'
import { zodResolver } from '@hookform/resolvers/zod'
import { getFormSchema } from '@features/management-view/backup-connector/form/validations'
import { OptionType } from '@features/management-view/backup-connector/form/validations'
import { useSearchParams } from 'react-router-dom'

function useSetupForm() {
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get('t')

  const { ovaAccountsList, isOvaAccountsLoading } = useFetchListOvaAccounts()

  const currentView = getCurrentView(tabIndex as string)
  const isEdit = Boolean(currentView)

  const formSchema = getFormSchema(isEdit)
  const { accountOptions, backupProviderOptions } = useMemo(
    () => transformAccountData(ovaAccountsList),
    [ovaAccountsList]
  )

  const methods = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      isDefaultDashboard: false,
      backupVendors: [] as Array<OptionType>,
      accounts: [] as Array<OptionType>,
    },
  })

  return {
    methods,
    accountOptions,
    backupProviderOptions,
    isOvaAccountsLoading,
    ovaAccountsList,
    currentTabInQuery: tabIndex,
    currentView,
    isEdit,
  }
}

export { useSetupForm }
