import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { OvaAccount } from 'blues-corejs/dist'
import {
  ControlledFormatCheckboxGroup,
  ControlledSelect,
  MaxWidthFormControl,
  ovaBackupProviderOptions,
  TimeRangeDropdown,
  Title,
} from '@features/reports/schedules/shared'
import { MultiSelectSearch } from '@components/shared'
import { FORM_FIELDS } from '@features/reports/schedules/report/drawer/dynamic-form/schedule/form-field-names'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Calendar from '@components-simple/calendar/Calendar'
// @ts-ignore
import { ITimezoneOption } from 'react-select'
import TimezoneSelect from 'react-timezone-select'
import { EngineCallback } from '@lib/engine-types'
import { useRecipients } from '@features/reports/schedules/use-users-list-store'
import {
  filterReportPeriodOptions,
  useUpdateFrequency,
  generateScheduleOptions,
  getFrequencyType,
} from '@features/reports/schedules/report/drawer/dynamic-form/shared'
import { useFormContext } from 'react-hook-form'
import { FrequencyType } from '@features/reports/schedules/report/drawer/dynamic-form/shared/frequency-type'
import moment from 'moment/moment'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import CustomScheduleModal from '@features/reports/schedules/custom-schedule/custom-schedule-modal'
import { SelectDisplayStyle } from '@features/reports/schedules/custom-schedule/styles'
import {
  CustomScheduleState,
  RepeatPeriod,
} from '@features/reports/schedules/custom-schedule/types'
import formatCustomSchedule from '@features/reports/schedules/report/drawer/dynamic-form/shared/formatCustomSchedule'
import Link from '@mui/material/Link'
import { Typography } from '@mui/material'

export default function BackupConnectorForm({
  ovaAccounts = [],
  formats,
  calendarValue,
  onCalendarChangeValue,
  timeZone,
  onTimezoneSelectChange,
}: {
  ovaAccounts?: Array<OvaAccount>
  calendarValue: number
  formats: Array<string>
  timeZone: { label: string; value: string }
  onCalendarChangeValue: EngineCallback<number>
  onTimezoneSelectChange: (timezoneOption: ITimezoneOption) => void
}) {
  const { setValue, watch } = useFormContext()
  const selectedValue = watch(FORM_FIELDS.FREQUENCY) || ''
  const userTimezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value
  const minDate = moment().tz(userTimezone)

  const initialCustomSchedule = watch(FORM_FIELDS.CUSTOM_SCHEDULE)
  const initialFormattedValue = formatCustomSchedule(initialCustomSchedule)

  const [isCustomScheduleModalOpen, setCustomScheduleModalOpen] =
    useState(false)
  const [formattedValue, setFormattedValue] = useState(
    initialFormattedValue || ''
  )
  const [customScheduleModalState, setCustomScheduleModalState] =
    useState<CustomScheduleState | null>(initialCustomSchedule || null)

  useUpdateFrequency({
    calendarValue,
    frequencyFieldName: FORM_FIELDS.FREQUENCY,
  })

  const scheduleOptions = generateScheduleOptions(calendarValue)

  const resetCustomSchedule = () => {
    if (formattedValue && customScheduleModalState) {
      setFormattedValue('')
      setCustomScheduleModalState(null)
    }
  }

  useEffect(() => {
    const optionExists = scheduleOptions.some(
      (option) => option.value === selectedValue
    )

    if (selectedValue.startsWith(`${FrequencyType.WEEKLY}_`)) {
      const newWeeklyValue = `${FrequencyType.WEEKLY}_${moment(
        calendarValue
      ).format('dddd')}`
      if (selectedValue !== newWeeklyValue) {
        setValue(FORM_FIELDS.FREQUENCY, newWeeklyValue)
      }
      resetCustomSchedule()
    } else if (selectedValue.startsWith(`${FrequencyType.MONTHLY}_`)) {
      const dayOfMonth = moment(calendarValue).date()
      const newMonthlyValue =
        dayOfMonth > 28
          ? `${FrequencyType.MONTHLY}_0`
          : `${FrequencyType.MONTHLY}_${dayOfMonth}`
      if (selectedValue !== newMonthlyValue) {
        setValue(FORM_FIELDS.FREQUENCY, newMonthlyValue)
      }
      resetCustomSchedule()
    } else if (selectedValue === FrequencyType.DAILY) {
      resetCustomSchedule()
    } else if (!optionExists && scheduleOptions.length > 0) {
      // @ts-ignore
      setValue(FORM_FIELDS.FREQUENCY, scheduleOptions[0].value)
    }
  }, [calendarValue, scheduleOptions, selectedValue, setValue])

  useEffect(() => {
    if (selectedValue === FrequencyType.CUSTOM && !customScheduleModalState) {
      setCustomScheduleModalOpen(true)
    }
  }, [selectedValue])

  const frequencyType = getFrequencyType(selectedValue)
  const filteredReportPeriodOptions = filterReportPeriodOptions(
    frequencyType,
    customScheduleModalState?.periodValue || RepeatPeriod.DAY
  )

  useEffect(() => {
    const selectedRangeOfData = watch(FORM_FIELDS.RANGE_OF_DATA)
    const isValidRangeOfData = filteredReportPeriodOptions.some(
      (option) => option.value === selectedRangeOfData
    )

    if (!isValidRangeOfData) {
      if (filteredReportPeriodOptions.length > 0) {
        setValue(
          FORM_FIELDS.RANGE_OF_DATA,
          // @ts-ignore
          filteredReportPeriodOptions[0].value
        )
      } else {
        setValue(FORM_FIELDS.RANGE_OF_DATA, '')
      }
    }
  }, [filteredReportPeriodOptions])

  const sortedRecipientsByLabel = useRecipients().sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  const handleCustomScheduleModalSave = (state: CustomScheduleState) => {
    setFormattedValue(formatCustomSchedule(state))
    setCustomScheduleModalState(state)
    setValue(FORM_FIELDS.CUSTOM_SCHEDULE, state)
    setCustomScheduleModalOpen(false)
  }

  const handleCustomScheduleModalClose = () => {
    if (!formattedValue && !customScheduleModalState) {
      // @ts-ignore
      setValue(FORM_FIELDS.FREQUENCY, scheduleOptions[0].value)
    }
    setCustomScheduleModalOpen(false)
  }

  const handleCustomScheduleLinkClick = () => {
    setCustomScheduleModalOpen(true)
  }

  return (
    <>
      <Title>Select OVA Accounts</Title>
      <MaxWidthFormControl>
        <MultiSelectSearch
          name={FORM_FIELDS.OVA_ACCOUNTS}
          options={ovaAccounts as any}
          placeholder="Select"
          disabled={false}
        />
      </MaxWidthFormControl>

      <Title>Select Backup Provider</Title>
      <MaxWidthFormControl>
        <MultiSelectSearch
          name={FORM_FIELDS.BACKUP_PROVIDER}
          options={ovaBackupProviderOptions}
          placeholder="Select"
          disabled={false}
        />
      </MaxWidthFormControl>

      <Stack direction="row" spacing={2}>
        <Box flex={1}>
          <Title>Frequency</Title>
          <ControlledSelect
            key={JSON.stringify(scheduleOptions)}
            name={FORM_FIELDS.FREQUENCY}
            options={scheduleOptions}
            placeholder="Select a Frequency"
          />
        </Box>
        <Box flex={1}>
          <Title>Date Range</Title>
          <Calendar
            value={calendarValue}
            onChange={onCalendarChangeValue}
            userTimezone={userTimezone}
            minDate={minDate}
          />
        </Box>
        <Box flex={1}>
          <Title>Range of Data</Title>
          <TimeRangeDropdown
            options={filteredReportPeriodOptions}
            name={FORM_FIELDS.RANGE_OF_DATA}
          />
        </Box>
      </Stack>
      {formattedValue && !isCustomScheduleModalOpen ? (
        <Link
          component="button"
          variant="body2"
          onClick={handleCustomScheduleLinkClick}
          underline="hover"
          sx={{
            textDecoration: 'none',
            color: '#6b6b6b',
            '&:hover': {
              textDecorationColor: '#6b6b6b',
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: '14px',
              color: '#6b6b6b',
              marginTop: '4px',
              textAlign: 'left',
            }}
          >
            {formattedValue}
          </Typography>
        </Link>
      ) : null}
      <Title>Time zone</Title>
      <MaxWidthFormControl>
        <TimezoneSelect
          className="timezone-select"
          value={timeZone.value}
          onChange={(tz) => {
            onTimezoneSelectChange(tz)
          }}
        />
      </MaxWidthFormControl>
      <Title>Select Recipients</Title>
      <MaxWidthFormControl>
        <MultiSelectSearch
          name={FORM_FIELDS.RECIPIENTS}
          options={sortedRecipientsByLabel}
          placeholder="Select"
          disabled={false}
        />
      </MaxWidthFormControl>
      <Title>Format:</Title>
      <ControlledFormatCheckboxGroup
        name={FORM_FIELDS.FORMATS}
        options={formats.map((format) => ({
          value: format,
          label: format,
        }))}
      />
      <CustomScheduleModal
        isOpen={isCustomScheduleModalOpen}
        handleSaveCallback={handleCustomScheduleModalSave}
        handleCloseCallback={handleCustomScheduleModalClose}
        customStyles={SelectDisplayStyle}
        initialValues={customScheduleModalState}
      />
    </>
  )
}
