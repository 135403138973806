/* eslint-disable import/no-extraneous-dependencies */
import {
  Asset,
  Backup,
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import useFetchScansByIds from '@components-complex/dashboard-pages-v3/hooks/use-fetch-scans-by-ids'
import Skeleton from '@mui/material/Skeleton'
import React from 'react'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import DetectedTreatsIcons from '@features/asset-page/shared/detected-threats-icons/detected-treats-icons'
import { BackupProvider } from '@features/asset-page/shared/backup-provider'
import { Nullable } from '@lib/engine-types'
import { ScansForBackup } from 'blues-corejs/dist/use_cases/list_recovery_points_for_asset_item_generic/types'
import RansomwareScanDetails from '@features/asset-page/shared/ransomware-scan-details'
import MalwareScanDetails from '@features/asset-page/shared/malware-scan-details'
import FsCheckScanDetails from '@features/asset-page/shared/fs-check-scan-details'
import { useFetchLastCleanRpForAsset } from '@components-complex/dashboard-pages-v3/hooks/use-fetch-last-clean-rp-for-asset'
import { StyledHeader } from './styles'
import Box from '@mui/material/Box'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type Props = {
  asset: Asset
}

function renderLoading() {
  return (
    <Skeleton
      animation="wave"
      height={10}
      width="80%"
      style={{ marginBottom: 6 }}
    />
  )
}

function renderBackupSummary(backup: Backup) {
  return (
    <Grid
      container
      marginTop="16px"
      columnGap="24px"
      fontSize="14px"
      paddingX="16px"
      flexWrap="nowrap"
      alignItems="center"
    >
      <Grid item xs="auto">
        <DetectedTreatsIcons backup={backup} />
      </Grid>
      <Divider flexItem orientation="vertical" />
      <Grid item xs="auto">
        <BackupProvider backup={backup} />
      </Grid>
    </Grid>
  )
}

function convertDataScanToScans(dataScans: Nullable<ScansForBackup>) {
  if (dataScans) {
    return [
      ...dataScans.malwareScansForBackup,
      ...dataScans.ransomWareScansForBackup,
      ...dataScans.fsCheckScansForBackup,
    ]
  } else {
    return []
  }
}

function renderRansomwareScanDetails(scans: Array<RansomwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <RansomwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderMalwareScanDetails(scans: Array<MalwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <MalwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderFsCheckScanDetails(scans: Array<FilesystemScanCheck>) {
  if (scans.length === 0) {
    return <Grid item xs={4} />
  }

  return (
    <Grid item xs={4}>
      <FsCheckScanDetails scans={scans} />
    </Grid>
  )
}

function renderScansDetails(scans: Array<Scan>) {
  if (scans.length === 0) {
    return null
  }

  const ransomwareScan = scans.filter(
    (scan) => scan instanceof RansomwareScan
  ) as Array<RansomwareScan>
  const malwareScan = scans.filter(
    (scan) => scan instanceof MalwareScan
  ) as Array<MalwareScan>
  const fsCheckScan = scans.filter(
    (scan) => scan instanceof FilesystemScanCheck
  ) as Array<FilesystemScanCheck>

  return (
    <>
      <Divider sx={{ marginY: '24px' }} />
      <Grid
        container
        marginTop="16px"
        columnGap="15px"
        fontSize="14px"
        paddingX="16px"
        flexWrap="nowrap"
      >
        {renderRansomwareScanDetails(ransomwareScan)}
        {renderMalwareScanDetails(malwareScan)}
        {renderFsCheckScanDetails(fsCheckScan)}
      </Grid>
    </>
  )
}

function BackupDetails({ backup }: { backup: Backup }) {
  const { dataScans, isScansLoading } = useFetchScansByIds([...backup.scanIds])
  const scans = convertDataScanToScans(dataScans)

  if (isScansLoading) {
    return renderLoading()
  }

  return (
    <Box marginBottom="10px" marginTop="24px">
      <StyledHeader>
        <Typography fontWeight="600">Latest clean recovery point</Typography>
      </StyledHeader>
      {renderBackupSummary(backup)}
      {renderScansDetails(scans)}
    </Box>
  )
}

function LatestCleanBackup({ asset }: Props) {
  const { isLastCleanRpLoading, latestCleanBackup } =
    useFetchLastCleanRpForAsset(asset.id)

  if (isLastCleanRpLoading) {
    return renderLoading()
  }

  if (!latestCleanBackup) {
    return null
  }

  return <BackupDetails backup={latestCleanBackup} />
}

export default LatestCleanBackup
