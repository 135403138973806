/* eslint-disable import/no-extraneous-dependencies */
import { ActionMenu } from '@components/table'
import React from 'react'
import {
  NotificationRule,
  RuleStatus,
} from 'blues-corejs/dist/models/notifications/rule'
import { useAlertsActions } from '@features/alerts/hooks/use-alerts-actions'
import { useConfirmDeleteStore } from '@features/alerts/store/alerts-confirm-delete-modal-store'

interface Props {
  rule: NotificationRule
}

function AlertsCellActions({ rule }: Props) {
  const { handleDisableRule, handleEnableRule, handleEditRule } =
    useAlertsActions()
  const { setOpen, setRulesIds } = useConfirmDeleteStore()

  const { id, status } = rule

  const options = [
    {
      label: 'Edit',
      onClick: () => {
        handleEditRule(rule)
      },
    },
    {
      label: 'Delete',
      onClick: () => {
        setRulesIds([id])
        setOpen(true)
      },
    },
  ]

  if (status === RuleStatus.disabled) {
    options.unshift({
      label: 'Enable',
      onClick: () => {
        handleEnableRule([id])
      },
    })
  } else {
    options.unshift({
      label: 'Disable',
      onClick: () => {
        handleDisableRule([id])
      },
    })
  }

  return <ActionMenu options={options} />
}

export default AlertsCellActions
