import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Drawer,
  FormControl,
  Grid2,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import Button from '../../ui/inputs/button'
import { RoiData } from 'ui-v2/src/views/dashboard/dashboard/return-investment-card'

type ReturnOfInvestmentDrawerProps = {
  isOpen: boolean
  closeDrawer: () => void
  dollarFormatter: Intl.NumberFormat
  storedRoiData: RoiData
}

const roiReductionFactor = 0.01

const industryOptions = [
  {
    label: 'IT',
    value: 'it',
    smb: 145000,
    enterprise: 217500,
  },
  {
    label: 'Automotive',
    value: 'automotive',
    smb: 250000,
    enterprise: 375000,
  },
  {
    label: 'Manufacturing',
    value: 'manufacturing',
    smb: 200000,
    enterprise: 300000,
  },
  {
    label: 'Retail',
    value: 'retail',
    smb: 300000,
    enterprise: 450000,
  },
  {
    label: 'Telecommunications',
    value: 'telecommunications',
    smb: 200000,
    enterprise: 300000,
  },
  {
    label: 'Healthcare',
    value: 'healthcare',
    smb: 250000,
    enterprise: 375000,
  },
  {
    label: 'Energy',
    value: 'energy',
    smb: 500000,
    enterprise: 750000,
  },
  {
    label: 'Media',
    value: 'media',
    smb: 100000,
    enterprise: 150000,
  },
  {
    label: 'Other',
    value: 'other',
    smb: 225000,
    enterprise: 337500,
  },
]

const getStoredRoiData = (): RoiData => {
  const storedData = localStorage.getItem('roiData')
  if (storedData) {
    return JSON.parse(storedData)
  }
  return {
    selectedIndustry: 'it',
    revenueInput: null,
    downtimeCost: null,
    calculatedRoi: '0',
  }
}

const ReturnOfInvestmentDrawer: React.FC<ReturnOfInvestmentDrawerProps> = ({
  isOpen,
  closeDrawer,
  dollarFormatter,
  storedRoiData,
}) => {
  const [localRoiData, setLocalRoiData] = useState<RoiData>(storedRoiData)
  const [isFormCleared, setIsFormCleared] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<RoiData>({
    defaultValues: localRoiData,
    mode: 'onChange',
  })

  const { selectedIndustry, revenueInput, downtimeCost } = watch()

  const handleClearClick = () => {
    setValue('selectedIndustry', 'it')
    setValue('revenueInput', null)
    setValue('downtimeCost', null)
    setIsFormCleared(true)
  }

  const handleDowntimeCost = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: Array<any>) => void
  ) => {
    const inputValue = e.target.value
    const numericValue = inputValue ? Number(inputValue) : 0
    if (numericValue >= 0) {
      onChange(numericValue)
    }
  }

  const onSubmit = (data: RoiData) => {
    const selectedIndustryData = industryOptions.find(
      (industry) => industry.value === data.selectedIndustry
    )

    const hourlyDowntimeCost =
      (data.revenueInput || 0) > 1000000
        ? selectedIndustryData?.enterprise
        : selectedIndustryData?.smb

    const downtimeSavings =
      Number(hourlyDowntimeCost) * (data.downtimeCost || 0)

    const roiData: RoiData = {
      selectedIndustry: data.selectedIndustry,
      revenueInput: data.revenueInput,
      downtimeCost: data.downtimeCost,
      calculatedRoi: downtimeSavings.toString(),
    }

    // Reduce ROI data with the reduction factor
    const calculatedRoi = Number(roiData.calculatedRoi) * roiReductionFactor
    roiData.calculatedRoi = calculatedRoi.toString()

    localStorage.setItem('roiData', JSON.stringify(roiData))
    closeDrawer()
  }

  useEffect(() => {
    if (isOpen) {
      const data = getStoredRoiData()
      setLocalRoiData(data)
      // Update form values when ROI data changes
      setValue('selectedIndustry', data.selectedIndustry)
      setValue('revenueInput', data.revenueInput)
      setValue('downtimeCost', data.downtimeCost)
      setIsFormCleared(false)
    }
  }, [storedRoiData, isOpen, setValue])

  useEffect(() => {
    if (isFormCleared && (selectedIndustry || revenueInput || downtimeCost)) {
      setIsFormCleared(false)
    }
  }, [selectedIndustry, revenueInput, downtimeCost, isFormCleared])

  const isSaveDisabled =
    isFormCleared ||
    !isValid ||
    !selectedIndustry ||
    !revenueInput ||
    !downtimeCost

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      sx={{ backdropFilter: 'blur(3px)' }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '400px',
        },
      }}
    >
      <List
        sx={{
          paddingX: 0,
          borderBottom: '2px solid rgba(51, 51, 51, 1)',
          backgroundColor: 'rgba(25, 35, 47, 0.2)',
        }}
      >
        <ListItem
          sx={{ paddingX: 2.5 }}
          secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={closeDrawer}>
              <Close />
            </IconButton>
          }
        >
          <Typography fontWeight={700} fontSize={22}>
            ROI Calculation
          </Typography>
        </ListItem>
      </List>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={2}
          sx={{
            paddingX: 2.5,
            paddingY: 2,
            minHeight: 'calc(100vh - 129px)',
          }}
        >
          <Typography variant="body2" fontWeight={400}>
            By calculate the ROI....
          </Typography>
          <Box
            sx={{
              backgroundColor: '#1AA8EA',
              borderRadius: '8px',
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            padding={2}
          >
            <Typography
              maxWidth={150}
              lineHeight={'20px'}
              fontSize={16}
              fontWeight={600}
              color="#fff"
            >
              Your ROI since the start of the service
            </Typography>
            <Typography fontSize={24} fontWeight={700} color="#fff">
              {dollarFormatter.format(parseInt(storedRoiData.calculatedRoi))}
            </Typography>
          </Box>

          <Typography>Input Your Organization's Data</Typography>
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <Typography variant="body2" mb={1}>
                  Your Industry
                </Typography>
                <Controller
                  name="selectedIndustry"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      size="small"
                      fullWidth
                      {...field}
                      sx={(theme) => ({
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
                        color:
                          theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor:
                            theme.palette.mode === 'dark'
                              ? '#1A1F28'
                              : 'rgba(228, 219, 233, 0.1',
                        },
                      })}
                    >
                      {industryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <Typography variant="body2" mb={1}>
                  Annual Revenue
                </Typography>
                {/* <Controller
                  name="revenueInput"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      {...field}
                      value={value ?? ''}
                      onChange={(e) => {
                        const val = e.target.value ? parseInt(e.target.value) : null
                        onChange(val)
                      }}
                      size="small"
                      type="number"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                      sx={(theme) => ({
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
                        color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
                      })}
                    />
                  )}
                /> */}

                <Controller
                  name="revenueInput"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      {...field}
                      // Format the value when displaying
                      value={value ? value.toLocaleString('en-US') : ''}
                      // Parse the input when changing
                      onChange={(e) => {
                        // Remove commas and non-numeric characters (except decimal)
                        const rawValue = e.target.value.replace(/[^0-9.]/g, '')
                        // Convert to number
                        const val = rawValue ? parseFloat(rawValue) : null
                        onChange(val)
                      }}
                      size="small"
                      // Change type to "text" instead of "number" to allow comma formatting
                      type="text"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                      sx={(theme) => ({
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
                        color:
                          theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
                      })}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <Typography variant="body2" mb={1}>
                  Total Data under Elastio Protection
                </Typography>
                <Controller
                  name="downtimeCost"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ...field } }) => (
                    <TextField
                      {...field}
                      value={value ?? ''}
                      onChange={(e) => {
                        handleDowntimeCost(e, onChange)
                      }}
                      size="small"
                      type="number"
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="start">TB</InputAdornment>
                          ),
                        },
                      }}
                      sx={(theme) => ({
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#1A1F28' : '#FFFFFF',
                        color:
                          theme.palette.mode === 'dark' ? '#D3D8DE' : '#1A1F28',
                      })}
                    />
                  )}
                />
              </FormControl>
            </Grid2>
          </Grid2>
        </Stack>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          columnGap={1}
          sx={{
            paddingX: 2.5,
            paddingY: 2,
            backgroundColor: 'rgba(21, 38, 53, 1)',
          }}
        >
          <Button
            size="small"
            variant="outlined"
            sx={{
              textTransform: 'capitalize',
              fontSize: 13,
            }}
            onClick={handleClearClick}
          >
            Clear Changes
          </Button>

          <Button
            type="submit"
            size="small"
            variant="contained"
            sx={{
              textTransform: 'capitalize',
              fontSize: 13,
              borderRadius: 10,
            }}
            disabled={isSaveDisabled}
          >
            Save
          </Button>
        </Box>
      </form>
    </Drawer>
  )
}

export default ReturnOfInvestmentDrawer
