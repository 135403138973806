"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VolumeModel = exports.VolumeGenericBackupKind = exports.VolumeStatusCheck = exports.VolumeStatus = void 0;
const threat_status_model_1 = require("./threat-status.model");
var VolumeStatus;
(function (VolumeStatus) {
    VolumeStatus[VolumeStatus["VOLUME_STATUS_UNKNOWN"] = 0] = "VOLUME_STATUS_UNKNOWN";
    VolumeStatus[VolumeStatus["AVAILABLE"] = 1] = "AVAILABLE";
    VolumeStatus[VolumeStatus["CREATING"] = 2] = "CREATING";
    VolumeStatus[VolumeStatus["DELETED"] = 3] = "DELETED";
    VolumeStatus[VolumeStatus["DELETING"] = 4] = "DELETING";
    VolumeStatus[VolumeStatus["ERROR"] = 5] = "ERROR";
    VolumeStatus[VolumeStatus["IN_USE"] = 6] = "IN_USE";
})(VolumeStatus || (exports.VolumeStatus = VolumeStatus = {}));
var VolumeStatusCheck;
(function (VolumeStatusCheck) {
    VolumeStatusCheck[VolumeStatusCheck["VOLUME_STATUS_CHECK_UNKNOWN"] = 0] = "VOLUME_STATUS_CHECK_UNKNOWN";
    VolumeStatusCheck[VolumeStatusCheck["IMPAIRED"] = 1] = "IMPAIRED";
    VolumeStatusCheck[VolumeStatusCheck["INSUFFICIENT_DATA"] = 2] = "INSUFFICIENT_DATA";
    VolumeStatusCheck[VolumeStatusCheck["OK"] = 3] = "OK";
})(VolumeStatusCheck || (exports.VolumeStatusCheck = VolumeStatusCheck = {}));
var VolumeGenericBackupKind;
(function (VolumeGenericBackupKind) {
    VolumeGenericBackupKind[VolumeGenericBackupKind["UNKNOWN"] = 0] = "UNKNOWN";
    VolumeGenericBackupKind[VolumeGenericBackupKind["ELASTIO"] = 1] = "ELASTIO";
    VolumeGenericBackupKind[VolumeGenericBackupKind["IMPORTED_AWS_BACKUP"] = 2] = "IMPORTED_AWS_BACKUP";
    VolumeGenericBackupKind[VolumeGenericBackupKind["AWS_SNAPSHOT"] = 3] = "AWS_SNAPSHOT";
})(VolumeGenericBackupKind || (exports.VolumeGenericBackupKind = VolumeGenericBackupKind = {}));
class VolumeModel extends threat_status_model_1.AssetWithThreat {
    id;
    name;
    accountId;
    region;
    cost;
    cloudConnectorId;
    cloudProviderVolumeId;
    createdAt;
    volumeType;
    inventorySize;
    encrypted;
    usesCustomerManagedKey;
    lastActivityAt;
    iops;
    throughput;
    multiAttachEnabled;
    availabilityZone;
    status;
    statusCheck;
    tags;
    coveredByPolicies;
    coveredByPausedPolicies;
    lastGenericBackupAt;
    lastGenericBackupKind;
    firstGenericBackupAt;
    coveringPoliciesSLAs;
    associatedRetentionPolicies;
    constructor(attrs) {
        super(attrs);
        this.id = attrs.id;
        this.name = attrs.name;
        this.accountId = attrs.accountId;
        this.region = attrs.region;
        this.cost = attrs.cost;
        this.cloudConnectorId = attrs.cloudConnectorId;
        this.createdAt = attrs.createdAt;
        this.inventorySize = attrs.inventorySize;
        this.encrypted = attrs.encrypted;
        this.usesCustomerManagedKey = attrs.usesCustomerManagedKey;
        this.lastActivityAt = attrs.lastActivityAt;
        this.volumeType = attrs.volumeType;
        this.cloudProviderVolumeId = attrs.cloudProviderVolumeId;
        this.iops = attrs.iops;
        this.throughput = attrs.throughput;
        this.multiAttachEnabled = attrs.multiAttachEnabled;
        this.availabilityZone = attrs.availabilityZone;
        this.status = attrs.status;
        this.statusCheck = attrs.statusCheck;
        this.tags = attrs.tags;
        this.coveredByPolicies = attrs.coveredByPolicies;
        this.coveredByPausedPolicies = attrs.coveredByPausedPolicies;
        this.firstGenericBackupAt = attrs.firstGenericBackupAt;
        this.lastGenericBackupAt = attrs.lastGenericBackupAt;
        this.coveringPoliciesSLAs = attrs.coveringPoliciesSLAs;
        this.lastGenericBackupKind = attrs.lastGenericBackupKind;
        this.associatedRetentionPolicies = attrs.associatedRetentionPolicies;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getAccountId() {
        return this.accountId;
    }
    getRegion() {
        return this.region;
    }
    getCost() {
        return this.cost;
    }
    getCloudConnectorId() {
        return this.cloudConnectorId;
    }
    getCloudProviderAssetId() {
        return this.cloudProviderVolumeId;
    }
    getCloudProviderVolumeId() {
        return this.cloudProviderVolumeId;
    }
    getCreatedAt() {
        return this.createdAt;
    }
    getInventorySize() {
        return this.inventorySize;
    }
    getVolumeType() {
        return this.volumeType;
    }
    getEncrypted() {
        return this.encrypted;
    }
    getUsesCustomerManagedKey() {
        return this.usesCustomerManagedKey;
    }
    getLastActivityAt() {
        return this.lastActivityAt;
    }
    getIops() {
        return this.iops;
    }
    getThroughput() {
        return this.throughput;
    }
    getMultiAttachEnabled() {
        return this.multiAttachEnabled;
    }
    getAvailabilityZone() {
        return this.availabilityZone;
    }
    getStatus() {
        return this.status;
    }
    getStatusCheck() {
        return this.statusCheck;
    }
    getTags() {
        return Array.from(this.tags);
    }
    getTagsMap() {
        return this.tags;
    }
    getCoveredByPolicies() {
        return this.coveredByPolicies;
    }
    isCoveredByPausedPolicies() {
        return this.coveredByPausedPolicies.length > 0;
    }
    getAssociatedRetentionPolicies() {
        return this.associatedRetentionPolicies;
    }
    getElastioPoliciesOrAwsBackupCoverage() {
        if (this.getCoveredByPolicies().length > 0) {
            return this.getCoveredByPolicies();
        }
        return this.lastGenericBackupKind;
    }
    getMaxBackupRetention() {
        if (!this.firstGenericBackupAt) {
            return null;
        }
        return Date.now() - (this.firstGenericBackupAt ?? 0);
    }
    getRpo() {
        if (!this.lastGenericBackupAt) {
            return null;
        }
        return Date.now() - this.lastGenericBackupAt;
    }
    getSla() {
        const slaDurations = Array.from(this.coveringPoliciesSLAs.values());
        if (slaDurations.length === 0) {
            return null;
        }
        return Math.min(...slaDurations);
    }
    hasAnyBackup() {
        return this.lastGenericBackupAt !== null;
    }
    isOutOfSchedule() {
        const slaDurations = Array.from(this.coveringPoliciesSLAs.values());
        const rpo = this.getRpo();
        if (rpo === null || rpo === undefined) {
            return false;
        }
        for (const slaDuration of slaDurations) {
            if (slaDuration === null || slaDuration === undefined) {
                continue;
            }
            if (rpo > slaDuration) {
                return true;
            }
        }
        return false;
    }
    isUnencrypted() {
        return !this.encrypted;
    }
    doesNotUseCustomerManagedKey() {
        return this.usesCustomerManagedKey === false;
    }
    isInactiveFor30Days() {
        if (!this.lastActivityAt) {
            return false;
        }
        const oneDay = 24 * 60 * 60 * 1000;
        const diffDays = Math.round(Math.abs((Date.now() - this.lastActivityAt) / oneDay));
        return diffDays >= 30;
    }
    getAgeInDays() {
        return Math.round(Math.abs((Date.now() - this.createdAt) / (1000 * 3600 * 24)));
    }
    getParsedCloudProviderVolumeId() {
        return (this.cloudProviderVolumeId?.split(':')?.pop() ??
            this.cloudProviderVolumeId ??
            '');
    }
    getParsedCloudProviderAssetId() {
        return this.getParsedCloudProviderVolumeId();
    }
    isCoveredByPolicy() {
        return this.getCoveredByPolicies().length > 0;
    }
    isCoveredByElastioPolicyOrAwsBackup() {
        return (this.getCoveredByPolicies().length > 0 ||
            this.lastGenericBackupKind ===
                VolumeGenericBackupKind.IMPORTED_AWS_BACKUP ||
            this.lastGenericBackupKind === VolumeGenericBackupKind.AWS_SNAPSHOT);
    }
    getVolumeFsCheckReport() {
        return this.getThreatStatus()?.volumeFsCheckReport;
    }
    isProtected() {
        return this.isLastRecoveryPointId() && this.isCoveredByPolicy();
    }
    getSize() {
        return '';
    }
    getVpcId() {
        return '';
    }
    isAssociatedWithAnyRetentionPolicy() {
        return this.getAssociatedRetentionPolicies().length > 0;
    }
}
exports.VolumeModel = VolumeModel;
