import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { GeneratedReportsClient } from 'ui-v2/src/lib/clients/reports/on-demand'
import { ListGeneratedReportsRequestParams } from 'ui-v2/src/lib/models/reports'
import { useQuery } from '@tanstack/react-query'

export function useGeneratedReportListQuery(
  filterParams: ListGeneratedReportsRequestParams
) {
  const generatedReportsClient = useGrpcClient(GeneratedReportsClient)

  return useQuery({
    queryKey: ['reports', 'generatedReportList', filterParams],
    queryFn: () => {
      if (!generatedReportsClient) {
        throw new Error('Generate report client not initialized')
      }
      return generatedReportsClient.listGeneratedReport(filterParams)
    },
    enabled: !!generatedReportsClient,
    refetchOnMount: true,
  })
}
