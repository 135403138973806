import { EngineCallbackPure } from '@lib/engine-types'
import { useEffect } from 'react'

function useIntervalIf(
  condition: boolean,
  callback: EngineCallbackPure,
  deps: Array<any>,
  intervalTime = 10000
) {
  useEffect(() => {
    const intervalRef = setInterval(() => {
      if (condition) {
        callback()
      }
    }, intervalTime)

    return () => {
      clearInterval(intervalRef)
    }
  }, [...deps, condition])
}

export default useIntervalIf
