import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormSearchInterface,
  FormFieldInterface
> => ({
  search: {
    name: 'search',
    value: '',
    validationRules: {},
  },
})

export default buildDefaultFormData
