import {
  AwsAccountSummaryReportFormat,
  AwsCompletedScansReportFormat,
  AwsExecutiveReportFormat,
  AwsInfectedAssetsReportFormat,
  AwsScannedAssetsSummaryReportFormat,
  AwsTenantSummaryReportFormat,
  FailedScansReportFormat,
  InfectedFilesReportFormat,
  OvaMspExecutiveReportFormat,
  OvaMspOperationalReportFormat,
  OvaMspTenantSummaryReportFormat,
  OvaMspAccountSummaryReportFormat,
  OvaMspCompletedScansReportFormat,
  OvaMspScannedAssetsSummaryReportFormat,
  OvaMspInfectedAssetsReportFormat,
  OvaMspInfectedFilesReportFormat,
  OvaMspFailedScansReportFormat,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/report_formats_pb'

const formatMapping: Record<string, Record<string, number>> = {
  ovaMspOperationalReport: {
    PDF: OvaMspOperationalReportFormat.OVA_MSP_OPERATIONAL_REPORT_FORMAT_PDF,
    XLSX: OvaMspOperationalReportFormat.OVA_MSP_OPERATIONAL_REPORT_FORMAT_XLSX,
  },
  ovaMspExecutiveReport: {
    PDF: OvaMspExecutiveReportFormat.OVA_MSP_EXECUTIVE_REPORT_FORMAT_PDF,
  },
  ovaMspTenantSummaryReport: {
    PDF: OvaMspTenantSummaryReportFormat.OVA_MSP_TENANT_SUMMARY_REPORT_FORMAT_PDF,
  },
  ovaMspAccountSummaryReport: {
    PDF: OvaMspAccountSummaryReportFormat.OVA_MSP_ACCOUNT_SUMMARY_REPORT_FORMAT_PDF,
  },
  ovaMspCompletedScansReport: {
    CSV: OvaMspCompletedScansReportFormat.OVA_MSP_COMPLETED_SCANS_REPORT_FORMAT_CSV,
  },
  ovaMspScannedAssetsSummaryReport: {
    CSV: OvaMspScannedAssetsSummaryReportFormat.OVA_MSP_SCANNED_ASSETS_SUMMARY_REPORT_FORMAT_CSV,
  },
  ovaMspInfectedAssetsReport: {
    CSV: OvaMspInfectedAssetsReportFormat.OVA_MSP_INFECTED_ASSETS_REPORT_FORMAT_CSV,
  },
  ovaMspInfectedFilesReport: {
    CSV: OvaMspInfectedFilesReportFormat.OVA_MSP_INFECTED_FILES_REPORT_FORMAT_CSV,
  },
  ovaMspFailedScansReport: {
    CSV: OvaMspFailedScansReportFormat.OVA_MSP_FAILED_SCANS_REPORT_FORMAT_CSV,
  },
  awsExecutiveReport: {
    PDF: AwsExecutiveReportFormat.AWS_EXECUTIVE_REPORT_FORMAT_PDF,
  },
  awsTenantSummaryReport: {
    PDF: AwsTenantSummaryReportFormat.AWS_TENANT_SUMMARY_REPORT_FORMAT_PDF,
  },
  awsAccountSummaryReport: {
    PDF: AwsAccountSummaryReportFormat.AWS_ACCOUNT_SUMMARY_REPORT_FORMAT_PDF,
  },
  awsCompletedScansReport: {
    CSV: AwsCompletedScansReportFormat.AWS_COMPLETED_SCANS_REPORT_FORMAT_CSV,
  },
  awsScannedAssetsSummaryReport: {
    CSV: AwsScannedAssetsSummaryReportFormat.AWS_SCANNED_ASSETS_SUMMARY_REPORT_FORMAT_CSV,
  },
  awsInfectedAssetsReport: {
    CSV: AwsInfectedAssetsReportFormat.AWS_INFECTED_ASSETS_REPORT_FORMAT_CSV,
  },
  infectedFilesReport: {
    CSV: InfectedFilesReportFormat.INFECTED_FILES_REPORT_FORMAT_CSV,
  },
  failedScansReport: {
    CSV: FailedScansReportFormat.FAILED_SCANS_REPORT_FORMAT_CSV,
  },
}

export function mapFormatsToEnum(
  reportType: string,
  formats: Array<string>
): Array<number> {
  const allowedFormats = formatMapping[reportType]
  if (!allowedFormats) {
    throw new Error(`Unsupported report type: ${reportType}`)
  }

  // @ts-ignore
  return formats
    .map((format) => allowedFormats[format])
    .filter((mappedFormat) => mappedFormat !== undefined)
}
