import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale'
import { TimeFormatConstants } from '@lib/constants'
import { Nullable } from '@lib/engine-types'
import { StrHelper, TimeHelper } from '@lib/helpers'
import LangHelper from '@lib/helpers/lang.helper'
import type { InventoryInstanceOutput } from 'blues-corejs/dist/use_cases/list_inventory_instances/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function convertDateToString(date: Nullable<Date>) {
  if (!date) {
    return ''
  }

  return `${dayjs(date)
    .utc()
    .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
}

function convertRetentionToString(milliseconds: Nullable<number>) {
  if (!milliseconds) {
    return ''
  }

  const formattedRetention = convertMillisecondsWithLabel(milliseconds)

  const { label, value } = formattedRetention

  return value ? `${value} ${label}` : ''
}

function prepareEc2DataForCsv(instanceList: Array<InventoryInstanceOutput>) {
  return instanceList.map((instance) => {
    return {
      instance: instance.instanceName
        ? `${instance.instanceName} (${instance.instanceId})`
        : instance.instanceId,
      // To prevent converting into number column
      account: `${instance.accountId}‎`,
      region: LangHelper.getAwsRegionSingleTranslation(instance.region),
      lastBackup: convertDateToString(instance.lastBackup?.timestamp),
      lastScan: convertDateToString(instance.lastScan),
      status: StrHelper.getEc2StateName(instance.instanceState),
      policyCoverage: instance.coveredByPolicesList.join(', '),
      rpo: instance.rpo ? TimeHelper.timeAgo(instance.rpo).toString() : '',
      rpoTarget: instance.sla
        ? TimeHelper.timeAgo(instance.sla * 1000).toString()
        : '',
      retention: convertRetentionToString(instance.maxBackupRetention),
    }
  })
}

const getEc2CvsTableHeaders = (showRPO: boolean, showRetention: boolean) => [
  {
    label: 'Instance',
    key: 'instance',
  },
  {
    label: 'Account',
    key: 'account',
  },
  {
    label: 'Region',
    key: 'region',
  },
  {
    label: 'Last Backup',
    key: 'lastBackup',
  },
  ...(showRPO
    ? [
        {
          label: 'RPO',
          key: 'rpo',
        },
        {
          label: 'RPO Target',
          key: 'rpoTarget',
        },
      ]
    : []),
  ...(showRetention
    ? [
        {
          label: 'Retention',
          key: 'retention',
        },
      ]
    : []),
  {
    label: 'Last Scan',
    key: 'lastScan',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Policy coverage',
    key: 'policyCoverage',
  },
]

export { prepareEc2DataForCsv, getEc2CvsTableHeaders }
