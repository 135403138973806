import { Button } from '@mui/material'
import React, { memo } from 'react'
import WebhookEventsTimeline from '@components-complex/webhooks-events-timeline-complex/WebhooksEventsTimeline'
import WebhookEventsComplex from '@components-complex/webhooks-events-complex/WebhooksEventsComplex'
import AddWeebhookModal from '@components-composite/modals/AddWebhookModal'
import Modal from '@lib/constants/modal.constant'
import { useModal } from '@lib/hooks/useModal'

const TenantWebhooks = () => {
  const { openModal: openAddWebhookModal, modalProps: webhookModalProps } =
    useModal<any>(Modal.addWebhook, () => {}, false)

  return (
    <div className="wrap-1659954175617">
      <Button
        data-testid="create-new-webhook"
        className="create-new-webhook"
        variant="contained"
        onClick={openAddWebhookModal}
      >
        Create New
      </Button>

      <div className="">
        <WebhookEventsComplex />
      </div>
      <div className="">
        <WebhookEventsTimeline />
      </div>

      <AddWeebhookModal loading={false} {...webhookModalProps} />
    </div>
  )
}

export default memo(TenantWebhooks)
