import {
  GetOvaDataScannedStatisticsRequestPb,
  KrabsOvaStatisticsPromiseClient,
} from '../grpc'
import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'

export class KrabsOvaStatistics extends GrpcClient<KrabsOvaStatisticsPromiseClient> {
  #krabsOvaStatisticsClient: KrabsOvaStatisticsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#krabsOvaStatisticsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'KrabsOvaStatistics'
  }

  protected initClient(hostName = ''): KrabsOvaStatisticsPromiseClient {
    return new KrabsOvaStatisticsPromiseClient(hostName, null, null)
  }

  async getDataScannedStatistics() {
    const request = new GetOvaDataScannedStatisticsRequestPb()

    const response = (
      await this.callGrpcService(
        () =>
          this.#krabsOvaStatisticsClient.getDataScannedStatistics(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName:
            'KrabsOvaStatisticsPromiseClient/getDataScannedStatistics',
        }
      )
    ).toObject()

    return response
  }
}
