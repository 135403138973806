import { Box, Typography } from '@mui/material'
import React, { CSSProperties, memo } from 'react'
import { ScaleInfoSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/skeleton'

function InfoBox({
  mainText,
  subText,
  sx,
  isLoading,
}: {
  mainText: string
  subText: string
  sx?: CSSProperties
  isLoading?: boolean
}) {
  if (isLoading) {
    return <ScaleInfoSkeletonLoader />
  }
  return (
    <Box
      sx={sx}
      display="flex"
      flexDirection="column"
      width="50%"
      textAlign="center"
    >
      <Typography variant="caption" fontSize="22px" fontWeight="600">
        {mainText}
      </Typography>
      <Typography variant="caption" fontSize="16px">
        {subText}
      </Typography>
    </Box>
  )
}

export default memo(InfoBox)
