import { ScheduledTenantJob } from 'ui-v2/src/features/scheduled-jobs-monitoring/models'
import { JobMessages } from 'ui-v2/src/features/scheduled-jobs-monitoring/use-cases'
import { ClientSideJobStatus } from 'ui-v2/src/features/scheduled-jobs-monitoring/use-cases/constants'

interface ScheduledTenantJobsClient {
  get(jobId: string): Promise<ScheduledTenantJob>
}

interface ScheduledTenantJobsRepository {
  insert(job: ScheduledTenantJob): Promise<void>
}

interface Notifier {
  info(text: string): void
}

export class ScheduleTenantJobUseCase {
  constructor(
    private repository: ScheduledTenantJobsRepository,
    private client: ScheduledTenantJobsClient,
    private notifier: Notifier
  ) {}

  async execute(jobId: string): Promise<void> {
    try {
      const job = await this.client.get(jobId)
      await this.repository.insert(job)
      this.notifier.info(
        JobMessages[job.kind][ClientSideJobStatus.INITIALIZING]
      )
    } catch (error) {
      const err = error as Error
      throw new Error(`Failed to execute job with ID ${jobId}: ${err}`)
    }
  }
}
