import { SEARCH_VARIANTS } from './consts'
import { SearchVariant } from './types'

import SearchIcon from '@inline-img/general/search-icon'
import SearchLongIcon from '@inline-img/general/search-long-icon'
import SearchBlackIcon from '@inline-img/general/search-black-icon'

export function getSearchIconByVariant(
  variant: SearchVariant
): () => JSX.Element {
  const variants: Partial<Record<SearchVariant, () => JSX.Element>> = {
    [SEARCH_VARIANTS.WHITE_FILTER_SEARCH]: SearchLongIcon,
    [SEARCH_VARIANTS.WHITE_FILTER_SEARCH_BLACK_ICON]: SearchBlackIcon,
  }

  return variants[variant] || SearchIcon
}
