/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  ElastioRecoveryPoint,
  Backup,
  EBSSnapshot,
  Asset,
} from 'blues-corejs/dist'
import {
  computeAwsRecoveryPointState,
  computeElastioRecoveryPointState,
  computeEbsSnapshotsRpState,
} from '../helpers'

export function BackupState({
  backup,
  asset,
}: {
  backup: Backup
  asset: Asset
}) {
  if (backup instanceof ElastioRecoveryPoint) {
    return computeElastioRecoveryPointState(backup)
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return computeAwsRecoveryPointState(backup, asset)
  }

  if (backup instanceof EBSSnapshot) {
    return computeEbsSnapshotsRpState(backup)
  }

  return null
}
