import React from 'react'
import { VIRow } from '@lib/engine-types'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import TableHelper from '@lib/helpers/table.helper'
import { drawVICell } from '@tables/core/table-draw'
import TableMenu from '@tables/core/table-menu'
import {
  AssetsEntityType,
  RowComponentData,
} from '@lib/interfaces/tables/assets-table.interface'
import CheckboxConstant from '@lib/constants/checkbox.constant'
import clsx from 'clsx'
import AssetState from '@components-composite/asset-state/AssetState'
import SubTableVIRowWithHeadersForEC2 from '@tables/rows-vi/SubTableVIRowWithHeadersForEC2'
import SubTableVIRowWithHeadersForEBS from '@tables/rows-vi/SubTableVIRowWithHeadersForEBS'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import TableCellTags from '@components-composite/table-cell-tags/TableCellTags'
import { Switch } from '@mui/material'
import PlusToggleBtn from '@components-simple/plus-toggle-btn/PlusToggleBtn'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import Link from '@lib/router/navigation-strategies/link'

function AssetsVIRow({
  data,
  columnState,
  forceTemplate,
  menu,
  onMenuClick,
  onChange,
  showMenu,
  columnDrawMapper = {},
  className = '',
}: RowComponentData) {
  const subRow = data.find((v) => v.type === AssetsEntityType.Expand)
  const shouldShowChildren = !!subRow && !!subRow.value && !!subRow.matrix
  const drawRow = (dataToShow: VIRow, isParent = true) => (
    <>
      {dataToShow
        .filter(
          TableHelper.buildStateTemplateFilterFunc(columnState, forceTemplate)
        )
        .map((v, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {!v.type && drawVICell(columnDrawMapper, v, index, columnState)}
            {/* expand */}
            {AssetsEntityType.Expand === v.type && (
              <>
                {isParent ? (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{
                      width: '1px',
                    }}
                  >
                    {!!subRow &&
                      !!subRow.matrix &&
                      subRow.matrix.length > 0 && (
                        <PlusToggleBtn
                          expanded={!!v.value}
                          onChange={(value) =>
                            onChange({
                              type: AssetsEntityType.Expand,
                              value: value
                                ? CheckboxConstant.Checked
                                : CheckboxConstant.Empty,
                              index,
                              row: dataToShow,
                            })
                          }
                        />
                      )}
                  </td>
                ) : (
                  <td className="assetsVIBorderWrap">
                    <div className="assetsVIRowBorder" />
                  </td>
                )}
              </>
            )}
            {/* checkbox */}
            {AssetsEntityType.Checkbox === v.type && (
              <td
                className={`assetKind${v.selectedOption?.type}`}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{
                  paddingRight: 0,
                  width: '1px',
                }}
              >
                <div className="flexRowJustifyStart">
                  <V2Checkbox
                    variant="table"
                    checked={!!v.value}
                    disabled={v.selectedOption?.disabled}
                    indeterminate={v.value === CheckboxConstant.Indeterminate}
                    onChange={(value) =>
                      onChange({
                        type: AssetsEntityType.Checkbox,
                        index,
                        value: value
                          ? CheckboxConstant.Checked
                          : CheckboxConstant.Empty,
                        row: dataToShow,
                      })
                    }
                  />
                  {/* do not show QuestionHint for disabled asset linked with selected asset*/}
                  {/*                   {v.selectedOption?.disabled && (
                    <QuestionHint
                      className="tableVariant mr12"
                      text="This account is disconnected"
                    />
                  )} */}
                </div>
              </td>
            )}
            {/* switch */}
            {AssetsEntityType.Switch === v.type && (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{
                  width: '1px',
                }}
              >
                <Switch
                  className="greenSwitch"
                  checked={!!v.value}
                  onChange={(e) =>
                    onChange({
                      type: AssetsEntityType.Switch,
                      value: e.target.checked
                        ? CheckboxConstant.Checked
                        : CheckboxConstant.Empty,
                      index,
                      row: dataToShow,
                    })
                  }
                  name="switch"
                />
              </td>
            )}
            {/* link */}
            {AssetsEntityType.Link === v.type && (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {v.name ? (
                  <Link to={v.name} className="blueLink">
                    <span>
                      <LongTextTooltip text={v.label || ''} maxLength={30} />
                    </span>
                  </Link>
                ) : (
                  <div>{v.label}</div>
                )}
                <div className="details">{v.value}</div>
              </td>
            )}
            {/* tags */}
            {AssetsEntityType.Tags === v.type && (
              <td key={index}>
                <TableCellTags
                  tagsMap={v?.tags?.map(([tagKey, tagValue]) => ({
                    name: tagValue ? `${tagKey}=${tagValue}` : tagKey,
                  }))}
                />
              </td>
            )}
            {/* icon */}
            {AssetsEntityType.Icon === v.type && (
              <td className="pr0" key={index}>
                <div className="tableStatus flexRowJustifyEnd">
                  <AssetState
                    assetKind={Number(v.value ?? -1)}
                    healthyStatusName={v.name}
                    fsCheckIntegrityStatusName={String(v.extraValue)}
                    iscanIntegrityStatusName={String(v.label)}
                  />
                </div>
              </td>
            )}
            {AssetsEntityType.Text === v.type && (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <div>{v.label}</div>
              </td>
            )}
            {AssetsEntityType.Date === v.type && (
              <td key={index}>
                {v.name ? (
                  <DateTimeTooltip
                    placement="top-start"
                    direction="row"
                    date={new Date(v.name)}
                  />
                ) : (
                  'No Backups'
                )}
              </td>
            )}
          </React.Fragment>
        ))}
      <td className="tableMenuWrap">
        <TableMenu
          data={data}
          menu={menu}
          showMenu={showMenu}
          onMenuClick={onMenuClick}
        />
      </td>
    </>
  )

  return (
    <>
      <tr
        className={clsx(`assetsVIRow assetsVIRowMain ${className}`, {
          assetsVIRowExpanded: shouldShowChildren,
        })}
        data-testid="jsAssetsVIRow"
      >
        {drawRow(data)}
      </tr>

      {shouldShowChildren &&
        subRow?.matrix?.map((sr: VIRow, index: number) => (
          <React.Fragment key={index}>
            {AssetKind.AWS_EC2 === subRow.extraValue && (
              <SubTableVIRowWithHeadersForEC2
                data={sr}
                key={`${index}${subRow.id}`}
                index={`${index}${subRow.id}`}
                isFirst={index === 0}
                isLast={index === (subRow.matrix?.length || 1) - 1}
                onChange={onChange}
              />
            )}
            {AssetKind.AWS_EBS === subRow.extraValue && (
              <SubTableVIRowWithHeadersForEBS
                data={sr}
                key={`${index}${subRow.id}`}
                index={`${index}${subRow.id}`}
                isFirst={index === 0}
                isLast={index === (subRow.matrix?.length || 1) - 1}
                onChange={onChange}
              />
            )}
          </React.Fragment>
        ))}
    </>
  )
}

export default AssetsVIRow
