import { KrabsGetAwsScannedStatistics, KrabsPromiseClient } from '../grpc'
import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'

export class KrabsClient extends GrpcClient<KrabsPromiseClient> {
  #krabsClient: KrabsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#krabsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'KrabsClient'
  }

  protected initClient(hostName = ''): KrabsPromiseClient {
    return new KrabsPromiseClient(hostName, null, null)
  }

  async getAwsScanStatistics() {
    const request = await this.callGrpcService(
      () =>
        this.#krabsClient.getAwsScanStatistics(
          new KrabsGetAwsScannedStatistics(),
          this.metadata(this.#token)
        ),
      {
        requestName: 'KrabsPromiseClient/getAwsScanStatistics',
      }
    )

    return request.toObject()
  }
}
