/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { JobTenant } from 'blues-corejs/dist'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'

// We can pass job (second argument) in the table definitions, but can't pass it in the filter modal (for example)
export function getHumanReadableTenantJobKind(
  kind: TenantJobKind,
  job?: JobTenant
): string {
  const mapping: Record<TenantJobKind, string> = {
    [TenantJobKind.INSTALL_CLOUD_CONNECTOR]: 'Install Cloud Connector',
    [TenantJobKind.CREATE_VAULT]: 'Create Vault',
    [TenantJobKind.DESTROY_VAULT]: 'Destroy Vault',
    [TenantJobKind.REFRESH_PLANS]: 'Refresh Policies',
    [TenantJobKind.SET_PLAN_STATUS]: 'Set Plan Status',
    [TenantJobKind.RESYNC]: 'Resync',
    [TenantJobKind.ACTIVATE_CLOUD_CONNECTOR]: 'Activate Cloud Connector',
    [TenantJobKind.ANALYZE_CLOUD_CONNECTOR_TARGET_REGION]:
      'Analyze Cloud Connector Target Region',
    [TenantJobKind.EVALUATE_PLANS]: 'Evaluate Policies',
    [TenantJobKind.SCAN_INVENTORY]: 'Scan Inventory',
    [TenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING]:
      'Enable Elastio Reports Gathering',
    [TenantJobKind.DISABLE_ELASTIO_REPORTS_GATHERING]:
      'Disable Elastio Reports Gathering',
    [TenantJobKind.UPGRADE_CLOUD_CONNECTOR]: 'Upgrade Cloud Connector',
    [TenantJobKind.UPGRADE_VAULT]: 'Upgrade Vault',
    [TenantJobKind.REPAIR_REPORT_GATHERING_INFRA]:
      'Repair Report Gathering Infra',
    [TenantJobKind.CLEANUP_RED_STACK_DATA]: 'Cleanup Red Stack Data',
    [TenantJobKind.REPAIR_CLOUD_CONNECTOR]: 'Repair Cloud Connector',
    [TenantJobKind.REPAIR_VAULT]: 'Repair Vault',
    [TenantJobKind.UPDATE_VAULT_SUBNETS]: 'Update Vault Subnets',
    [TenantJobKind.CLOUD_CONNECTOR_INTEGRITY_CHECK]:
      'Cloud Connector Integrity Check',
    [TenantJobKind.CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK]:
      'Cloud Connector Infrastructure Check',
    [TenantJobKind.VAULT_INFRASTRUCTURE_CHECK]: 'Vault Infrastructure Check',
    [TenantJobKind.VAULT_NETWORKING_CHECK]: 'Vault Networking Check',
    [TenantJobKind.EXECUTE_BACKUP_POLICY]: 'Execute Backup Policy',
    [TenantJobKind.CLEANUP_BACKUP_POLICY_COVERAGE]:
      'Cleanup Backup Policy Coverage',
    [TenantJobKind.REFRESH_CONNECTOR_RETENTION_POLICIES]:
      'Refresh Connector Retention Policies',
    [TenantJobKind.EVALUATE_RETENTION_POLICIES]: 'Evaluate Retention Policies',
    [TenantJobKind.UPDATE_VAULT]: 'Update Vault',
    [TenantJobKind.EVALUATE_ASSETS_INTEGRITY_STATUS]:
      'Evaluate Assets Integrity Status',
    [TenantJobKind.REFRESH_ASSET_THREAT_STATUSES]:
      'Refresh Asset Threat Statuses',
    [TenantJobKind.GENERATE_SCHEDULED_REPORT]: 'Generate Scheduled Report',
    [TenantJobKind.DELETE_CLOUD_CONNECTOR]: 'Delete Cloud Connector',
  }

  const humanReadableJobKind = mapping[kind]

  if (!humanReadableJobKind) {
    const errorMessage = job
      ? `Got unknown tenant job kind for job with id ${job.id}, probably UI models are outdated`
      : 'Got unknown tenant job kind for job, probably UI models are outdated'

    SystemHelper.sendSentryIfProd(errorMessage)
  }

  return humanReadableJobKind || 'Unknown job kind'
}
