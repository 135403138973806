import React from 'react'

function BlueEyeIcon() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.99996C1 5.99996 3.16667 1.66663 6.95833 1.66663C10.75 1.66663 12.9167 5.99996 12.9167 5.99996C12.9167 5.99996 10.75 10.3333 6.95833 10.3333C3.16667 10.3333 1 5.99996 1 5.99996Z"
        stroke="#32B6F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.95752 7.62476C7.85498 7.62476 8.58252 6.89722 8.58252 5.99976C8.58252 5.10229 7.85498 4.37476 6.95752 4.37476C6.06006 4.37476 5.33252 5.10229 5.33252 5.99976C5.33252 6.89722 6.06006 7.62476 6.95752 7.62476Z"
        stroke="#32B6F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BlueEyeIcon
