import React from 'react'

export default function SpeedometerMediumRiskIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#E4F7FF" />
      <path
        d="M15.2968 7.38052V5.64844C12.5864 5.80402 10.0447 6.85725 8.01587 8.66536L11.1535 11.803C11.887 11.1923 12.7183 10.7318 13.6092 10.4371C13.7447 9.80212 13.862 9.26836 13.9408 8.9138C14.1036 8.18133 14.6241 7.61536 15.2968 7.38052Z"
        fill="#32B6F3"
      />
      <path
        d="M10.1588 12.7938L7.02125 9.65625C5.2116 11.6845 4.15705 14.2264 4.00049 16.9375H8.43847C8.5791 15.4084 9.17441 13.9745 10.1588 12.7938Z"
        fill="#32B6F3"
      />
      <path
        d="M23.5615 16.9375H27.9995C27.843 14.2264 26.7885 11.6845 24.9788 9.65625L21.8412 12.7938C22.8256 13.9745 23.4209 15.4084 23.5615 16.9375Z"
        fill="#32B6F3"
      />
      <path
        d="M23.5604 18.3438C23.3279 20.8983 21.9255 22.457 21.3688 23.0137C21.0937 23.2887 21.0937 23.7347 21.3688 24.0098L23.5021 26.1433C23.6397 26.2808 23.82 26.3496 24.0002 26.3496C24.1805 26.3496 24.3607 26.2808 24.4982 26.1433C26.604 24.0377 27.8319 21.2901 27.9999 18.3438H23.5604Z"
        fill="#32B6F3"
      />
      <path
        d="M8.43953 18.3438H4C4.16805 21.2901 5.39594 24.0377 7.5017 26.1433C7.63923 26.2809 7.81947 26.3496 7.9997 26.3496C8.17994 26.3496 8.36022 26.2808 8.49775 26.1433L10.6311 24.0098C10.9061 23.7348 10.9061 23.2888 10.6311 23.0137C10.0744 22.457 8.67203 20.8983 8.43953 18.3438Z"
        fill="#32B6F3"
      />
      <path
        d="M16.7031 5.64844V7.38056C17.3758 7.61541 17.8964 8.18138 18.0591 8.91389C18.1379 9.26845 18.2553 9.80217 18.3907 10.4371C19.2817 10.7319 20.1129 11.1923 20.8465 11.803L23.9841 8.66541C21.9553 6.8572 19.4135 5.80402 16.7031 5.64844Z"
        fill="#32B6F3"
      />
      <path
        d="M16.0001 8.67188C15.6705 8.67188 15.3851 8.90077 15.3137 9.22247C15.1578 9.92395 13.7906 16.1204 13.7906 17.6594C13.7906 18.8776 14.7818 19.8687 16 19.8687C17.2182 19.8687 18.2094 18.8776 18.2094 17.6594C18.2094 16.1204 16.8423 9.92395 16.6864 9.22247C16.615 8.90077 16.3296 8.67188 16.0001 8.67188Z"
        fill="#32B6F3"
      />
    </svg>
  )
}
