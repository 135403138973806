import React from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  padding: '16px',
  display: 'flex',
  gap: '8px',
}))

const StyledHelpIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.blue[500],
}))

const POPOVER_TITLE = 'What is the Hard deleted?'
const POPOVER_CONTENT =
  'This recovery point is rolled up as per the retention policy settings.'

export function PopoverContent() {
  return (
    <StyledContainer>
      <StyledHelpIcon />
      <Box display="flex" flexDirection="column" maxWidth="240px">
        <Typography
          fontWeight="600"
          variant="caption"
          fontSize="14px"
          lineHeight="17px"
          marginBottom="5px"
        >
          {POPOVER_TITLE}
        </Typography>
        <Typography
          fontWeight="400"
          variant="caption"
          fontSize="14px"
          lineHeight="21px"
        >
          {POPOVER_CONTENT}
        </Typography>
      </Box>
    </StyledContainer>
  )
}
