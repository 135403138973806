/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { AssetItem } from 'blues-corejs/dist'
import { CellWithButtonLink } from '@components/table'
import { LongTextTooltip } from '@components-simple/long-text-tooltip'
import { computeAssetItemName } from './compute-asset-item-name'

const ITEM_NAME_MAX_LENGTH = 70

interface AssetItemNameCellProps {
  assetItem: AssetItem
  onAssetItemClick: () => void
}

function AssetItemNameCell({
  assetItem,
  onAssetItemClick,
}: AssetItemNameCellProps) {
  const name = computeAssetItemName(assetItem)

  return (
    <CellWithButtonLink onClick={onAssetItemClick}>
      <LongTextTooltip text={name} maxLength={ITEM_NAME_MAX_LENGTH} />
    </CellWithButtonLink>
  )
}

export { AssetItemNameCell }
