import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { NormalizedCoverageCardData } from 'ui-v2/src/lib/models/aws-stats'
import { IconButton, Stack, Typography } from '@mui/material'
import {
  COVERAGE_TITLE_MAP,
  getCoverageContent,
} from 'ui-v2/src/lib/constants/stats.constant'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { ArrowOutward } from '@mui/icons-material'

const CoverageCard: React.FC<NormalizedCoverageCardData> = ({
  slug,
  count,
  totalCount,
  link,
}) => {
  return (
    <Link to={link} sx={{ textDecoration: 'none' }}>
      <GradientCard
        sx={{
          p: 1.5,
          px: 2,
        }}
        gradientVariant="blue"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography fontWeight={700} color="">
            {COVERAGE_TITLE_MAP[slug]}:&nbsp;
            <Typography component="span" fontWeight={700} color="primary">
              {count}
            </Typography>
            <Typography component="span" fontWeight={400}>
              &nbsp;out of&nbsp;
            </Typography>
            <Typography component="span" fontWeight={700} color="primary">
              {totalCount}{' '}
            </Typography>
            <Typography component="span" fontWeight={400}>
              &nbsp;{getCoverageContent(slug)}
            </Typography>
          </Typography>

          <IconButton disabled>
            <ArrowOutward sx={{ color: '#fff' }} />
          </IconButton>
        </Stack>
      </GradientCard>
    </Link>
  )
}

export default CoverageCard
