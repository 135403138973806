import { LocalizationProvider as MUILocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { PropsWithChildren } from 'react'

const LocalizationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MUILocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </MUILocalizationProvider>
  )
}

export default LocalizationProvider
