import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getRiskName } from '../utils'

export interface FilterComponentProps {
  options: Array<string>
}

const RiskFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsRiskList =
    searchParams.get(ASSETS_VULNERABILITIES_FILTERS_MAP.RISK)?.split(',') || []

  const awsRiskOptions = useMemo(() => {
    return options.map((awsRiskOption) => ({
      value: awsRiskOption,
      label: getRiskName(awsRiskOption),
    }))
  }, [options])

  const handleAwsRiskChange = (
    newAwsRiskList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsRiskList.length === 0) {
      searchParams.delete(ASSETS_VULNERABILITIES_FILTERS_MAP.RISK)
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.RISK,
        newAwsRiskList.join(',')
      )
    }
    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsRiskOptions}
      value={awsRiskList}
      onChange={handleAwsRiskChange}
      label="Risk Type"
      width={200}
    />
  )
}

export default RiskFilter
