import RetentionPolicyModel from '@lib/models/retention-policy.model'

export const getPoliciesRetention = (state: any): Array<RetentionPolicyModel> =>
  state.policiesRetentionState.policiesRetention

export const getPoliciesRetentionCount = (state: any): number =>
  state.policiesRetentionState.policiesRetentionCount

export const getPoliciesRetentionNames = (state: any): Array<string> =>
  state.policiesRetentionState.policiesRetention.map(
    (p: RetentionPolicyModel) => p.policyName
  )

export const getEditPolicyRetentionDefaultUpdated = (state: any): boolean =>
  state.editPolicyRetentionState.retentionPolicyDefaultUpdated

export const getAddPolicyRetentionComplete = (state: any): boolean =>
  state.addRetentionPolicyState.addPolicyRetentionComplete
