/* eslint-disable import/no-extraneous-dependencies */
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export const SYSTEM_JOB_KINDS = [
  TenantJobKind.REFRESH_PLANS,
  TenantJobKind.SET_PLAN_STATUS,
  TenantJobKind.EVALUATE_PLANS,
  TenantJobKind.CLOUD_CONNECTOR_INTEGRITY_CHECK,
  TenantJobKind.EXECUTE_BACKUP_POLICY,
  TenantJobKind.EVALUATE_RETENTION_POLICIES,
  TenantJobKind.CLEANUP_BACKUP_POLICY_COVERAGE,
  TenantJobKind.REFRESH_CONNECTOR_RETENTION_POLICIES,
  TenantJobKind.EVALUATE_ASSETS_INTEGRITY_STATUS,
  TenantJobKind.REFRESH_ASSET_THREAT_STATUSES,
  TenantJobKind.GENERATE_SCHEDULED_REPORT,
  TenantJobKind.CLEANUP_RED_STACK_DATA,
]

export const NO_JOBS_MESSAGE = 'No Jobs yet!'
