"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationRule = void 0;
const criteria_1 = require("./criteria");
class NotificationRule {
    #id;
    #name;
    #description;
    #mode;
    #status;
    #criteria;
    #channels;
    #createdAt;
    #updatedAt;
    constructor(args) {
        this.#id = args.id;
        this.#name = args.name;
        this.#description = args.description;
        this.#mode = args.mode;
        this.#status = args.status;
        this.#criteria = args.criteria;
        this.#channels = args.channels;
        this.#createdAt = args.createdAt;
        this.#updatedAt = args.updatedAt;
    }
    get id() {
        return this.#id;
    }
    get name() {
        return this.#name;
    }
    get description() {
        return this.#description;
    }
    get mode() {
        return this.#mode;
    }
    get status() {
        return this.#status;
    }
    get criteria() {
        return this.#criteria;
    }
    get channels() {
        return this.#channels;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    get eventTriggers() {
        if (this.#criteria.eventKinds instanceof criteria_1.AllKinds) {
            return ['All Events'];
        }
        return this.#criteria.eventKinds.kinds.map((kind) => kind);
    }
}
exports.NotificationRule = NotificationRule;
