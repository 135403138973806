import IconButton from '@mui/material/IconButton'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import React, { useCallback } from 'react'
import { ManagementViewRoutes, useNavigation } from '@lib/router'

function AddTabAction() {
  const router = useNavigation()

  const handleAddTabClick = useCallback(
    () => router.push(ManagementViewRoutes.root),
    []
  )
  return (
    <IconButton
      onClick={handleAddTabClick}
      color="inherit"
      data-testid="add-dashboard-view-btn"
    >
      <AddRoundedIcon />
    </IconButton>
  )
}

export default AddTabAction
