// eslint-disable-next-line import/no-extraneous-dependencies
import { Disk, File, GenericHost, LocalVolume, Stream } from 'blues-corejs/dist'
import { LiveAssetsItems } from '@lib/clients/assets-items/types'

export function getAssetItemsMapWithHostId({
  assetItems,
  genericHostsData,
}: {
  assetItems: LiveAssetsItems
  genericHostsData: Array<GenericHost>
}) {
  const assetItemsWithGenericHostId = new Map<
    string,
    Array<File | Disk | LocalVolume | Stream>
  >()

  genericHostsData.forEach((host) => {
    const assetItemsGenericHost: Array<File | Disk | LocalVolume | Stream> = [
      ...assetItems.filesList,
      ...assetItems.disksList,
      ...assetItems.streamsList,
      ...assetItems.volumesList,
    ].filter((assetItem) => assetItem.assetId === host.id)

    if (!assetItemsWithGenericHostId.has(host.id)) {
      assetItemsWithGenericHostId.set(host.id, assetItemsGenericHost)
    }
  })

  return assetItemsWithGenericHostId
}
