/* eslint-disable import/no-extraneous-dependencies */
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import { EC2, EBS, EFS, S3Bucket, GenericHost, Asset } from 'blues-corejs/dist'
import { OvaServer } from 'blues-corejs/dist/models'

export function computeAssetTitle(asset: Asset) {
  if (asset instanceof EBS) {
    return 'Volume ID'
  }

  if (asset instanceof EC2) {
    return 'Instance ID'
  }

  if (asset instanceof S3Bucket) {
    return 'Bucket name'
  }

  if (asset instanceof EFS) {
    return 'Name'
  }

  if (asset instanceof GenericHost) {
    return 'Host name'
  }

  if (asset instanceof OvaServer) {
    return 'Server'
  }

  sentryReThrowCatchHandler('computeAssetIdTitle: Unknown asset type')
  throw new Error('computeAssetIdTitle: Unknown asset type')
}
