import { Severity } from '@lib/constants/notifications.constant'
import RegexConstants from '@lib/constants/regex.constant'
import { VIRow } from '@lib/engine-types'
import DataHelper from '@lib/helpers/data.helper'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import WebhookModel, { EventType } from '@lib/models/webhook.model'

export interface WebhookFormInterface {
  name: string
  endpoint: string
  authorization: string
  login: string
  password: string
  apiKey: string
  apiValue: string
  token: string
  description: string
  eventType: string
  version: string
  severity: string
}

const authorizationOptions: VIRow = [
  {
    value: 'none',
    label: 'None',
    name: 'none',
  },
  {
    value: 'basic_authorization',
    label: 'Basic Authorization',
    name: 'basic_authorization',
  },
  {
    value: 'bearer',
    label: 'Bearer',
    name: 'bearer',
  },
  {
    value: 'api_key',
    label: 'API Key',
    name: 'api_key',
  },
]

const versionOptions: VIRow = [
  {
    value: 'v0',
    label: 'V0',
    name: 'v0',
  },
]

const severityOptions: VIRow = [
  {
    value: Severity.ERROR,
    label: 'Error',
    name: 'Severity.ERROR',
  },
  {
    value: Severity.WARNING,
    label: 'Warning',
    name: 'Severity.WARNING',
  },
  {
    value: Severity.INFO,
    label: 'Info',
    name: 'Severity.INFO',
  },
  {
    value: Severity.TRACE,
    label: 'Trace',
    name: 'Severity.TRACE',
  },
]

const buildDefaultFormData = (
  events: Array<EventType>,
  webhooks: Array<WebhookModel>
): Record<keyof WebhookFormInterface, FormFieldInterface> => {
  const eventsOptions = events.map(({ name }) => ({
    value: name,
    label: DataHelper.getWebhooksEventType(name),
    name: name,
  }))

  return {
    name: {
      name: 'name',
      placeholder: 'Name',
      validationRules: {
        required: 'This field is required',
        maxLength: {
          value: 100,
          message: 'Maximal length is 100 characters',
        },
        validate: (value: string) => {
          const isWebhookNameExist = webhooks.some(
            (webhook) => webhook.getName() === value
          )

          if (isWebhookNameExist) {
            return 'Webhook with this name already exists'
          }

          return true
        },
      },
    },
    endpoint: {
      name: 'endpoint',
      placeholder: 'your-endpoint',
      validationRules: {
        required: 'This field is required',
        validate: (value: string) => {
          const url = `https://${value}`
          if (RegexConstants.SITE_WITH_HTTPS.test(url)) {
            return true
          }

          return 'Invalid URL'
        },
      },
    },
    authorization: {
      name: 'authorization',
      options: authorizationOptions,
      value: authorizationOptions[0]?.value,
    },
    login: {
      name: 'login',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    password: {
      name: 'password',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    token: {
      name: 'token',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    apiKey: {
      name: 'apiKey',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    apiValue: {
      name: 'apiValue',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },

    description: {
      name: 'description',
      validationRules: {
        maxLength: {
          value: 100,
          message: 'Maximal length is 100 characters',
        },
      },
    },
    eventType: {
      name: 'eventType',
      options: eventsOptions,
      placeholder: 'Select event type',
    },
    version: {
      name: 'version',
      value: versionOptions[0]?.value,
      options: versionOptions,
    },
    severity: {
      name: 'severity',
      options: severityOptions,
      label: 'Select severities',
    },
  }
}

export default buildDefaultFormData
