import { useQuery } from '@tanstack/react-query'
import { ListCloudConnnectorJobsFilterRequestParams } from 'ui-v2/src/lib/models/monitor'
import { JobsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useListCloudConnectorJobsQuery(
  filterParams?: ListCloudConnnectorJobsFilterRequestParams
) {
  const jobsClient = useGrpcClient(JobsClient)

  return useQuery({
    queryKey: ['jobs', 'cloud-connector', 'list', filterParams],
    queryFn: () => {
      if (!jobsClient) {
        throw new Error('Jobs client not initialized')
      }
      return jobsClient.listCloudConnectorJobs(filterParams ? filterParams : {})
    },
    enabled: !!jobsClient,
  })
}
