export enum ReportPeriodValue {
  CURRENT_DAY = 'currentDay',
  LAST_DAY = 'lastDay',
  CURRENT_WEEK = 'currentWeek',
  LAST_WEEK = 'lastWeek',
  CURRENT_MONTH = 'currentMonth',
  LAST_MONTH = 'lastMonth',
  CURRENT_QUARTER = 'currentQuarter',
  LAST_QUARTER = 'lastQuarter',
  CURRENT_YEAR = 'currentYear',
  LAST_YEAR = 'lastYear',
}

export const reportPeriodOptions = [
  {
    value: ReportPeriodValue.CURRENT_DAY,
    label: 'Today',
  },
  {
    value: ReportPeriodValue.LAST_DAY,
    label: 'Yesterday',
  },
  {
    value: ReportPeriodValue.CURRENT_WEEK,
    label: 'Current Week',
  },
  {
    value: ReportPeriodValue.LAST_WEEK,
    label: 'Last Week',
  },
  {
    value: ReportPeriodValue.CURRENT_MONTH,
    label: 'Current Month',
  },
  {
    value: ReportPeriodValue.LAST_MONTH,
    label: 'Last Month',
  },
  {
    value: ReportPeriodValue.CURRENT_QUARTER,
    label: 'Current Quarter',
  },
  {
    value: ReportPeriodValue.LAST_QUARTER,
    label: 'Last Quarter',
  },
  {
    value: ReportPeriodValue.CURRENT_YEAR,
    label: 'Current Year',
  },
  {
    value: ReportPeriodValue.LAST_YEAR,
    label: 'Last Year',
  },
]
