import AccessTokenModel from 'ui-v2/src/lib/models/settings/api-access/access-token.model'
import {
  TokenListTableDataAttrs,
  TokenListTableRow,
} from 'ui-v2/src/lib/models/settings/api-access/api-access'

export class RevokedTokenTableData {
  readonly #revokedTokenList: Array<AccessTokenModel>

  constructor(parameters: TokenListTableDataAttrs) {
    this.#revokedTokenList = parameters.tokenList
  }

  getRevokedTokenList = (): Array<TokenListTableRow> => {
    return this.#revokedTokenList.map((token) => {
      return new TokenListTableRow({
        id: token.innerId,
        token: token.name,
        status: 'Revoked',
        lastUsed: token.updatedAt,
        action: '',
      })
    })
  }
}
