import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextWithIconLink, { IconType } from './text-with-icon-link'
import { useTabsActions } from '@features/reports/schedules/use-data-store'
import { Tabs, ReportFormType } from '@features/reports/schedules/shared'
import {
  useDrawerActions,
  DrawerName,
} from '@features/reports/schedules/use-drawer-store'
import { NavigationStrategy } from '@lib/router/types'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface ReportCardOwnProps {
  title: string
  description: string
  kind: string
}

const redirectToReportTab = (router: NavigationStrategy, tab: number) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set('t', tab.toString())
  router.push(`${router.currentPath}?${searchParams.toString()}`)
}

function ReportCard({ title, description, kind }: ReportCardOwnProps) {
  const router = useNavigation()

  const { openDrawer } = useDrawerActions()
  const { setReportSourceType, setSelectedFormType } = useTabsActions()

  const handleReportAction = (formType: string) => {
    setReportSourceType(kind)

    openDrawer(DrawerName.GenerateReport, { kind })

    // TODO: Replace the mock value => formType
    setSelectedFormType(formType)

    // @ts-ignore
    redirectToReportTab(router, Tabs.REPORT)
  }

  const handleScheduleReportClick = () =>
    handleReportAction(ReportFormType.SCHEDULED)

  const handleOnDemandReportClick = () =>
    handleReportAction(ReportFormType.ON_DEMAND)

  return (
    <Card
      variant="outlined"
      sx={{
        minHeight: 150,
        borderRadius: '6px',
        borderColor: '#EAEFF3',
        boxShadow: '0px 4px 8px 0px #0000001A',
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            color: '#2F3745',
            fontSize: '16px',
            fontWeight: 700,
          }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#999CAA',
          }}
          gutterBottom
        >
          {description}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
          mt={2}
        >
          <TextWithIconLink
            text="Schedule report"
            iconType={IconType.calendar}
            onClick={handleScheduleReportClick}
          />
          <TextWithIconLink
            text="On-demand"
            iconType={IconType.arrow}
            onClick={handleOnDemandReportClick}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default ReportCard
