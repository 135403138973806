import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import { useStepperState } from '../stepper/stepper-store'
import { Step, StepWithoutInfrastructure } from '../stepper/constants'
import { useRuleDrawerDataState } from '../create-rule-drawer-data-store'

import {
  RuleDetails,
  InfrastructureScope,
  Notifications,
  ReviewEnable,
} from '../stepper'

type StepComponentMap<T extends number> = Record<T, React.ComponentType>

const stepComponents: StepComponentMap<Step> = {
  [Step.RULE_DETAILS]: RuleDetails,
  [Step.INFRASTRUCTURE_SCOPE]: InfrastructureScope,
  [Step.NOTIFICATIONS]: Notifications,
  [Step.REVIEW_ENABLE]: ReviewEnable,
}

const stepWithoutInfrastructure: StepComponentMap<StepWithoutInfrastructure> = {
  [StepWithoutInfrastructure.RULE_DETAILS]: RuleDetails,
  [StepWithoutInfrastructure.NOTIFICATIONS]: Notifications,
  [StepWithoutInfrastructure.REVIEW_ENABLE]: ReviewEnable,
}
function ComputedStep() {
  const { step: currentStep } = useStepperState()
  const { assetId } = useRuleDrawerDataState()

  const components = useMemo(() => {
    if (assetId) {
      return stepWithoutInfrastructure as StepComponentMap<Step>
    }
    return stepComponents
  }, [assetId])

  const StepComponent = useMemo(() => {
    return components[currentStep] || null
  }, [currentStep])

  return (
    <Box role="tabpanel" aria-labelledby={`step-${currentStep}`}>
      {StepComponent ? <StepComponent /> : <Box>Unknown Step</Box>}
    </Box>
  )
}

export default ComputedStep
