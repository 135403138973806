import React from 'react'
import { Skeleton, Box, Grid } from '@mui/material'

const NUM_SKELETONS = 2

export function ScansAndTestsSkeletonLoader() {
  const skeletonData = Array.from(Array(NUM_SKELETONS))
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        borderBottom="1px solid var(--grey300)"
        py="4px"
      >
        <Skeleton height={30} width="70%" />
      </Box>
      <Grid container py="4px">
        {skeletonData.map((_, index) => (
          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            key={index}
          >
            <Skeleton height={30} width={76} />
            <Skeleton height={28} width={100} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
