/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, GenericHost, OvaServer } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { SystemHelper } from '@lib/helpers'

interface Props {
  asset?: Asset
}
const EMPTY_ASSET_ID = '- -'

const StyledTargetAssetIdCell = styled(Typography)({
  fontSize: '14px',
})

function computeAssetId(asset?: Asset) {
  if (!asset) {
    return EMPTY_ASSET_ID
  }

  if (asset instanceof AWSAsset) {
    return asset.awsId
  }

  if (asset instanceof GenericHost) {
    return asset.hostname
  }

  if (asset instanceof OvaServer) {
    return asset.name
  }

  SystemHelper.sendSentryIfProd(
    'Got an unknown asset type for formatting targetAssetId in jobs table'
  )

  return EMPTY_ASSET_ID
}

export function TargetAssetIdCell({ asset }: Props) {
  const assetId = computeAssetId(asset)

  return <StyledTargetAssetIdCell>{assetId}</StyledTargetAssetIdCell>
}
