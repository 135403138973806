import { useQuery } from '@tanstack/react-query'
import { ListEfsFilters } from 'ui-v2/src/lib/models/assets'
import { AssetsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useAssetsEfsQuery(filters: ListEfsFilters) {
  const assetsClient = useGrpcClient(AssetsClient)

  return useQuery({
    queryKey: ['assets', 'efs', filters],
    queryFn: async () => {
      if (!assetsClient) {
        throw new Error('Assets client not initialized')
      }
      return assetsClient.listEfs(filters)
    },
    enabled: !!assetsClient,
  })
}
