import React, { memo, useRef, useState } from 'react'
import ValueInterface from '@lib/interfaces/value.interface'
import useOnClickOutside from '@lib/hooks/window/useOnClickOutside'
import useKeyUp from '@lib/hooks/window/useKeyUp'
import useMakeViewableByWidth from '@lib/hooks/window/useMakeViewableByWidth'
import BlueEyeBtn from '@components-composite/blue-eye-btn/BlueEyeBtn'
import clsx from 'clsx'
import LangHelper from '@lib/helpers/lang.helper'

interface Props {
  data: ValueInterface
  buttonLabel?: string
  popupLabel?: string
}

function ListPopup({ data, buttonLabel = '', popupLabel = '' }: Props) {
  const [show, setShow] = useState<boolean>(false)
  const componentRef = useRef(null)
  const popupRef = useRef(null)

  useOnClickOutside(componentRef, () => {
    setShow(false)
  })

  useKeyUp(['Escape'], () => {
    setShow(false)
  })

  useMakeViewableByWidth(
    popupRef,
    {
      top: 29,
      left: 0,
    },
    [show]
  )

  const optionsLength = data.options?.length

  let buttonLabelFinal
  if (buttonLabel) {
    buttonLabelFinal = buttonLabel
  } else {
    buttonLabelFinal = `${optionsLength} ${LangHelper.pluralizeEn(
      String(data.name),
      optionsLength
    )}`
  }

  let popupLabelFinal = buttonLabelFinal
  if (popupLabel) {
    popupLabelFinal = popupLabel
  }

  return (
    <div ref={componentRef} className="wrap-1619511624870">
      <BlueEyeBtn onClick={() => setShow(!show)} label={buttonLabelFinal} />

      <div ref={popupRef} className={clsx('lpPopup jsPopup', { dNone: !show })}>
        <div className="lpUnitLabel jsPopupLabel">{popupLabelFinal}</div>
        <ul className="lpContent">
          {data.options?.map((row: ValueInterface, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${row.name}${row.label}${index}`}>
              <div className="lpUserName">{row.name}</div>
              <div className="lpUserEmail">{row.label}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default memo(ListPopup)
