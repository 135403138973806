/* eslint-disable import/no-extraneous-dependencies */
import RedStackModel from '@lib/models/red-stack.model'
import { AliasNamesForS3Bucket } from '@lib/interfaces/inventory/s3-bucket.interface'
import { InventoryS3BucketsFilters, S3Bucket } from 'blues-corejs/dist'
import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'

export function getAliasNamesForS3(
  s3BucketList: Array<S3Bucket>,
  allActiveRedStacks: Array<RedStackModel>
) {
  if (allActiveRedStacks.length === 0 || !s3BucketList) {
    return {}
  }
  const accountIds: Set<string> = new Set()

  s3BucketList.forEach((s3Bucket) => {
    accountIds.add(s3Bucket.awsAccountId)
  })

  return allActiveRedStacks.reduce(
    (accIdWithAliasNames: AliasNamesForS3Bucket, redItem) => {
      if ([...accountIds].includes(redItem.awsAccount)) {
        return {
          ...accIdWithAliasNames,
          [redItem.awsAccount]: redItem.accountAlias,
        }
      }
      return accIdWithAliasNames
    },
    {}
  )
}

export function getDataForPossibleS3InventoryFilters(
  filterEfsInventory: InventoryS3BucketsFilters
) {
  const filters = [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: filterEfsInventory?.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: filterEfsInventory?.regions,
    },
    {
      id: FilterIdDashboard.COVERED,
      covered: filterEfsInventory?.covered,
    },
  ]

  return filters
}
