/* eslint-disable import/no-extraneous-dependencies */
import { DiskProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { Disk } from 'blues-corejs/dist'

type DiskPt = DiskProto.AsObject

export class DiskTransformer {
  #disk: DiskPt

  constructor(disk: DiskPt) {
    this.#disk = disk
  }

  transform(): Disk {
    return this.#transformDisk(this.#disk)
  }

  #transformDisk(disk: DiskPt): Disk {
    return new Disk({
      id: disk.id,
      ccAssetId: disk.ccAssetId,
      assetId: disk.assetId,
      deviceName: disk.deviceName,
      createdAt: secondsToDate(disk.createdAt?.seconds),
      updatedAt: secondsToDate(disk.updatedAt?.seconds),
    })
  }
}
