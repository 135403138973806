/* eslint-disable import/no-extraneous-dependencies */
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'
import { create } from 'zustand'

export interface Asset {
  id: string
  tagKey: string
  tagValue: string
  tagsOperator: TagsOperator
}

interface AssetTagSelectionActions {
  addAsset: (asset: Omit<Asset, 'id'>) => void
  removeAsset: (id: string) => void
  resetStore: () => void
}

export interface AssetTagSelectionState {
  assets: Array<Asset>
}

export type AssetTagSelectionStore = AssetTagSelectionActions &
  AssetTagSelectionState

const INITIAL_STATE: AssetTagSelectionState = {
  assets: [],
}

const useAssetTagSelectionStore = create<AssetTagSelectionStore>((set) => ({
  ...INITIAL_STATE,
  addAsset: (asset) =>
    set((state) => {
      const isDuplicate = state.assets.some(
        (existing) =>
          existing.tagKey === asset.tagKey &&
          existing.tagValue === asset.tagValue
      )
      if (isDuplicate) {
        return state
      }

      return {
        assets: [
          ...state.assets,
          {
            ...asset,
            // @ts-ignore
            id: crypto.randomUUID(),
          },
        ],
      }
    }),
  removeAsset: (id) =>
    set((state) => {
      const assetToRemove = state.assets.find((asset) => asset.id === id)
      if (!assetToRemove) {
        return state
      }

      return {
        assets: state.assets.filter((asset) => asset.id !== id),
      }
    }),
  resetStore: () => set({ ...INITIAL_STATE }),
}))

export const resetAssetTagSelectionStore = () => {
  useAssetTagSelectionStore.getState().resetStore()
}

export const useAssetTagSelectionState = () =>
  useAssetTagSelectionStore((state) => state.assets)

export const useAssetTagSelectionActions = () =>
  useAssetTagSelectionStore((state) => ({
    addAsset: state.addAsset,
    removeAsset: state.removeAsset,
  }))
