/* eslint-disable import/no-extraneous-dependencies */
import { OvaAccountProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { OvaAccount, OvaBackupProvider } from 'blues-corejs/dist'

type OvaAccountPt = OvaAccountProto.AsObject

export class OvaAccountTransformer {
  #ovaAccount: OvaAccountPt

  constructor(ovaAccount: OvaAccountPt) {
    this.#ovaAccount = ovaAccount
  }

  transform(): OvaAccount {
    return this.#transformOvaAccount(this.#ovaAccount)
  }

  #transformOvaAccount(ovaAccount: OvaAccountPt): OvaAccount {
    return new OvaAccount({
      id: ovaAccount.id,
      name: ovaAccount.name,
      backupProvidersList: this.#convertBackupProviders(
        ovaAccount.backupProvidersList
      ),
      createdAt: secondsToDate(ovaAccount.createdAt?.seconds),
      updatedAt: secondsToDate(ovaAccount.updatedAt?.seconds),
    })
  }

  #convertBackupProviders(
    backupProviders: Array<string>
  ): Array<OvaBackupProvider> {
    return backupProviders.reduce((acc, backupProvider: string) => {
      const convertedBackupProvider =
        this.#convertBackupProvider(backupProvider)

      if (convertedBackupProvider !== undefined) {
        acc.push(convertedBackupProvider)
      }
      return acc
    }, [] as Array<OvaBackupProvider>)
  }

  #convertBackupProvider(
    backupProvider: string
  ): OvaBackupProvider | undefined {
    switch (backupProvider) {
      case 'Veeam':
        return OvaBackupProvider.VEEAM
      case 'Commvault':
        return OvaBackupProvider.COMMVAULT
      case 'AwsBackupVmware':
        return OvaBackupProvider.AWS_BACKUP_VMWARE
      case 'VeritasNetBackup':
        return OvaBackupProvider.VERITAS_NET_BACKUP
      case 'Cohesity':
        return OvaBackupProvider.COHESITY
      case 'Rubrik':
        return OvaBackupProvider.RUBRIK
      default:
        break
    }
  }
}
