import React from 'react'
import Typography from '@mui/material/Typography'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

function FinishedJobCell({ finishedAt }: { finishedAt: Date }) {
  return (
    <React.Fragment>
      <Typography fontSize={14}>Completed</Typography>
      <DateTimeTooltip direction="row" date={finishedAt} />
    </React.Fragment>
  )
}

export default FinishedJobCell
