import React from 'react'

function ReductionIcon() {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 0L1 5.6575V24.49L16.5 31L32 24.49V5.5025L16.5 0Z"
        fill="#EFC75E"
      />
      <path d="M32 5.5025V24.49L16.5 31V11.005L32 5.5025Z" fill="#E7BF55" />
      <path
        d="M26.4975 7.5175V12.4775L22.4675 14.0275V8.99L22.3125 8.9125L26.42 7.44L26.4975 7.5175Z"
        fill="#F7E3AF"
      />
      <path
        d="M26.4201 7.44L22.3126 8.9125L7.51012 3.4875L7.43262 3.255L11.4626 1.86V2.015L26.4201 7.44Z"
        fill="#FFEDC5"
      />
      <path d="M16.5 11.005V31L1 24.49V5.6575L16.5 11.005Z" fill="#D3A644" />
      <path
        d="M17.2534 20.801L22.6784 18.476V21.576L17.2534 23.901V20.801Z"
        fill="white"
      />
    </svg>
  )
}

export default ReductionIcon
