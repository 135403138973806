/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import VaultModel from '@lib/models/vault.model'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useDispatch, useSelector } from 'react-redux'
import { getVaultList } from '@store/selectors/vault.selector'
import { DataHelper } from '@lib/helpers'
import { getAssetsSelectFilters } from '@store/selectors/assets-select.selector'
import { setAssetsSelectFilters } from '@store/actions/assets-select.action'
import { FilterNamesConstant } from '@lib/constants/filters/filter-names.constant'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'
import { getEditPolicySelectedCloudConnectors } from '@store/selectors/edit-policy.selector'

export function useFilteredVaultsList() {
  const dispatch = useDispatch()
  const vaultsList = useSelector(getVaultList)
  const selectedAssetsFilters = useSelector(getAssetsSelectFilters)
  const selectedCloudConnectors = useSelector(
    getEditPolicySelectedCloudConnectors
  )

  const [filteredVaultsList, setFilteredVaultsList] = useState<
    Array<VaultModel>
  >([])

  useDeepCompareEffect(() => {
    if (selectedCloudConnectors.length > 0) {
      let filteredVaults: Array<VaultModel> = []
      selectedCloudConnectors.forEach((item) => {
        const currentVaults = vaultsList.filter(
          (vault: VaultModel) =>
            vault.accountId === item.value && vault.region === item.extraValue
        )
        if (currentVaults.length) {
          filteredVaults = [...filteredVaults, ...currentVaults]
        }
      })

      setFilteredVaultsList(filteredVaults)

      // filter Assets list to show Assets from selected Cloud Connectors
      const filters = DataHelper.buildCloudConnectorsFilter(
        selectedCloudConnectors
      )
      const filtersWithoutCloudConnectors = selectedAssetsFilters.filter(
        (v) => v.name !== FilterNamesConstant.CLOUD_CONNECTORS
      )
      const newSelectedAssetsFilters = [
        ...filtersWithoutCloudConnectors,
        filters,
      ]

      dispatch(setAssetsSelectFilters(newSelectedAssetsFilters))
    } else {
      setFilteredVaultsList(vaultsList)
      const filtersWithoutCloudConnectors = selectedAssetsFilters.filter(
        (v) =>
          v.name !== FilterNamesConstant.CLOUD_CONNECTORS &&
          v.name !== FilterNamesConstant.CLOUD_CONNECTOR_STATUSES
      )

      const filtersWithDefault = [
        ...defaultSelectedFilters(),
        ...filtersWithoutCloudConnectors,
      ]

      dispatch(setAssetsSelectFilters(filtersWithDefault))
    }
  }, [selectedCloudConnectors, vaultsList])

  return filteredVaultsList
}
