import { Scan, ScanRun } from '../models/scans'

export function extractScanResults(scanRunList: Array<ScanRun>) {
  if (!scanRunList || !Array.isArray(scanRunList)) {
    return []
  }

  const allScans = scanRunList.reduce((accumulator, scanRun) => {
    const scans = []

    if (
      scanRun.filesystemChecksList &&
      Array.isArray(scanRun.filesystemChecksList)
    ) {
      scans.push(...scanRun.filesystemChecksList)
    }

    if (scanRun.malwareScansList && Array.isArray(scanRun.malwareScansList)) {
      scans.push(...scanRun.malwareScansList)
    }

    if (
      scanRun.ransomwareScansList &&
      Array.isArray(scanRun.ransomwareScansList)
    ) {
      scans.push(...scanRun.ransomwareScansList)
    }

    return [...accumulator, ...scans]
  }, [] as Array<Scan>)

  return allScans
}
