import React from 'react'

function VMWareReportsIcon() {
  return (
    <svg
      width="86"
      height="27"
      viewBox="0 0 86 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1795_9120)">
        <path
          d="M33.5128 24.3306V25.1741H33.1426V19.5784H33.5128V22.1379C33.846 21.6237 34.3358 21.173 35.0989 21.173C36.0273 21.173 36.9727 21.9328 36.9727 23.2039V23.2184C36.9727 24.4924 36.0273 25.2637 35.0989 25.2637C34.3272 25.2637 33.8289 24.8188 33.5128 24.3277V24.3306ZM36.574 23.2357V23.2213C36.574 22.1928 35.8707 21.5341 35.0619 21.5341C34.2532 21.5341 33.49 22.2159 33.49 23.2126V23.227C33.49 24.2324 34.2617 24.9055 35.0619 24.9055C35.8621 24.9055 36.574 24.2844 36.574 23.2357Z"
          fill="black"
        />
        <path
          d="M40.9542 21.2655H41.3614L39.6926 25.3128C39.3538 26.1333 38.9608 26.4251 38.4083 26.4251C38.1122 26.4251 37.9014 26.3702 37.6452 26.2575L37.7733 25.9282C37.9783 26.0264 38.1435 26.0726 38.4311 26.0726C38.8326 26.0726 39.0946 25.8357 39.3822 25.1539L37.5312 21.2684H37.9612L39.5616 24.7639L40.9513 21.2684L40.9542 21.2655Z"
          fill="black"
        />
        <path
          d="M44.625 19.8066H46.9971C47.6008 19.8066 48.0764 19.9742 48.3811 20.2804C48.6146 20.5173 48.737 20.809 48.737 21.1702V21.1846C48.737 21.8288 48.3754 22.1726 47.9824 22.3806C48.6032 22.5944 49.0332 22.9555 49.0332 23.6835V23.6979C49.0332 24.657 48.2558 25.1684 47.0769 25.1684H44.6278V19.8037L44.625 19.8066ZM47.8059 21.3233C47.8059 20.9015 47.4727 20.6415 46.8775 20.6415H45.5391V22.0686H46.8092C47.4072 22.0686 47.8059 21.8317 47.8059 21.3406V21.3262V21.3233ZM47.0114 22.863H45.5391V24.3335H47.0797C47.7147 24.3335 48.0992 24.0793 48.0992 23.5968V23.5824C48.0992 23.1288 47.7432 22.863 47.0114 22.863Z"
          fill="black"
        />
        <path
          d="M49.9755 21.1268H50.8896V22.0396C51.1402 21.433 51.5987 21.0199 52.3021 21.0516V22.0339H52.248C51.4478 22.0339 50.8868 22.5625 50.8868 23.6343V25.1741H49.9727V21.1268H49.9755Z"
          fill="black"
        />
        <path
          d="M52.7402 23.1722V23.1578C52.7402 21.9993 53.6458 21.0431 54.8703 21.0431C56.0948 21.0431 56.9947 21.9849 56.9947 23.1433V23.1578C56.9947 24.3075 56.0891 25.2666 54.8561 25.2666C53.623 25.2666 52.7402 24.3249 52.7402 23.1751V23.1722ZM56.0806 23.1722V23.1578C56.0806 22.4442 55.5737 21.8549 54.8561 21.8549C54.1384 21.8549 53.6543 22.4384 53.6543 23.1433V23.1578C53.6543 23.8626 54.1612 24.452 54.8703 24.452C55.5794 24.452 56.0806 23.8684 56.0806 23.1722Z"
          fill="black"
        />
        <path
          d="M57.6582 24.01V23.9955C57.6582 23.1202 58.3303 22.6926 59.307 22.6926C59.7541 22.6926 60.0702 22.762 60.3806 22.8602V22.762C60.3806 22.1871 60.0332 21.8809 59.3896 21.8809C58.9425 21.8809 58.6122 21.9791 58.2477 22.1351L58.0056 21.4071C58.4442 21.2078 58.8742 21.0778 59.5092 21.0778C60.1072 21.0778 60.5514 21.2395 60.8305 21.5313C61.1267 21.8231 61.2605 22.2506 61.2605 22.7822V25.1829H60.3692V24.6773C60.0958 25.0066 59.6801 25.2666 59.0707 25.2666C58.3217 25.2666 57.6582 24.8304 57.6582 24.0158V24.01ZM60.3948 23.7182V23.4409C60.1613 23.3484 59.8509 23.2791 59.4893 23.2791C58.8998 23.2791 58.5524 23.5333 58.5524 23.9522V23.9666C58.5524 24.3566 58.8913 24.5791 59.3298 24.5791C59.9335 24.5791 60.3948 24.2266 60.3948 23.7124V23.7182Z"
          fill="black"
        />
        <path
          d="M62.1523 23.1577V23.1433C62.1523 21.8028 63.0522 21.043 64.0033 21.043C64.6612 21.043 65.0769 21.3724 65.3645 21.7566V19.5784H66.2786V25.1741H65.3645V24.501C65.0712 24.9228 64.6555 25.2608 64.0033 25.2608C63.0664 25.2608 62.1523 24.501 62.1523 23.1606V23.1577ZM65.3788 23.1577V23.1433C65.3788 22.3604 64.8349 21.849 64.2226 21.849C63.6104 21.849 63.075 22.3401 63.075 23.1433V23.1577C63.075 23.9406 63.6047 24.4519 64.2226 24.4519C64.8406 24.4519 65.3788 23.9319 65.3788 23.1577Z"
          fill="black"
        />
        <path
          d="M67.207 23.1722V23.1578C67.207 22.008 68.0841 21.0431 69.2858 21.0431C70.0348 21.0431 70.5018 21.3262 70.8805 21.7422L70.3053 22.3633C70.0262 22.0629 69.7301 21.8578 69.2773 21.8578C68.6138 21.8578 68.1211 22.4413 68.1211 23.1462V23.1606C68.1211 23.88 68.6109 24.4549 69.3143 24.4549C69.7443 24.4549 70.0633 24.2555 70.3509 23.958L70.9033 24.5098C70.5103 24.9546 70.049 25.2695 69.2716 25.2695C68.0841 25.2695 67.2099 24.3278 67.2099 23.178L67.207 23.1722Z"
          fill="black"
        />
        <path
          d="M71.4062 23.1722V23.1578C71.4062 21.9993 72.3118 21.0431 73.5363 21.0431C74.7608 21.0431 75.6607 21.9849 75.6607 23.1433V23.1578C75.6607 24.3075 74.7551 25.2666 73.5221 25.2666C72.289 25.2666 71.4062 24.3249 71.4062 23.1751V23.1722ZM74.7466 23.1722V23.1578C74.7466 22.4442 74.2397 21.8549 73.5221 21.8549C72.8045 21.8549 72.3204 22.4384 72.3204 23.1433V23.1578C72.3204 23.8626 72.8272 24.452 73.5363 24.452C74.2454 24.452 74.7466 23.8684 74.7466 23.1722Z"
          fill="black"
        />
        <path
          d="M76.5888 21.1268H77.5029V21.7393C77.7592 21.3782 78.1066 21.0402 78.7188 21.0402C79.2941 21.0402 79.7013 21.3233 79.912 21.7537C80.2281 21.3233 80.6524 21.0402 81.2561 21.0402C82.1247 21.0402 82.6543 21.6006 82.6543 22.5886V25.1713H81.7402V22.8717C81.7402 22.2275 81.4469 21.8837 80.9315 21.8837C80.4161 21.8837 80.0772 22.2362 80.0772 22.8891V25.1742H79.1631V22.8659C79.1631 22.2362 78.8612 21.8837 78.3543 21.8837C77.8475 21.8837 77.5 22.2679 77.5 22.8891V25.1742H76.5859V21.1268H76.5888Z"
          fill="black"
        />
        <path
          d="M36.9707 12.9886L33.2231 2.40376C33.1747 2.25642 33.1035 2.06287 33.1035 1.89531C33.1035 1.43598 33.4851 0.999756 34.0347 0.999756C34.5131 0.999756 34.8235 1.31753 34.9659 1.77398L38.0699 10.8942L41.1966 1.74798C41.339 1.31464 41.6266 0.999756 42.1022 0.999756H42.199C42.7002 0.999756 43.0135 1.31753 43.1558 1.74798L46.257 10.8942L49.4122 1.72487C49.5318 1.33776 49.7938 0.999756 50.295 0.999756C50.7962 0.999756 51.2005 1.40998 51.2005 1.8722C51.2005 2.03976 51.1293 2.2362 51.0809 2.38353L47.3078 12.9915C47.1141 13.5462 46.7325 13.8351 46.3054 13.8351H46.2313C45.7558 13.8351 45.397 13.5462 45.2261 12.9915L42.1506 3.99553L39.0466 12.9915C38.8815 13.5462 38.5198 13.8351 38.0442 13.8351H37.9958C37.5431 13.8351 37.1615 13.5462 36.9707 12.9915"
          fill="#727175"
        />
        <path
          d="M64.2852 1.92143C64.2852 1.4101 64.6895 0.973877 65.1907 0.973877C65.7175 0.973877 66.1248 1.3841 66.1248 1.92143V4.07077C67.0332 1.98499 69.0465 0.999877 70.382 0.999877C70.9316 0.999877 71.2933 1.41299 71.2933 1.94454C71.2933 2.4761 70.9573 2.81699 70.4789 2.8921C68.0868 3.18388 66.1248 5.00099 66.1248 8.62077V12.8732C66.1248 13.3817 65.7432 13.8179 65.2163 13.8179C64.6895 13.8179 64.2852 13.4048 64.2852 12.8732V1.92143Z"
          fill="#727175"
        />
        <path
          d="M77.2038 13.9334C73.8749 13.9334 71.1582 11.316 71.1582 7.4738V7.42468C71.1582 3.85113 73.6186 0.968018 76.9703 0.968018C80.5527 0.968018 82.5916 3.96668 82.5916 7.26002C82.5916 7.76268 82.1901 8.1238 81.7487 8.1238H72.9864C73.2399 10.8134 75.0908 12.3242 77.2494 12.3242C78.7501 12.3242 79.8493 11.7262 80.7407 10.8827C80.8802 10.7642 81.0454 10.6689 81.2561 10.6689C81.6975 10.6689 82.0534 11.03 82.0534 11.4634C82.0534 11.6771 81.9623 11.8938 81.7687 12.0614C80.6211 13.188 79.2627 13.9334 77.201 13.9334M80.7862 6.75157C80.5983 4.49535 79.331 2.52802 76.9191 2.52802C74.8089 2.52802 73.2142 4.32779 72.9836 6.75157H80.7834H80.7862Z"
          fill="#727175"
        />
        <path
          d="M51.2314 10.0478V9.99869C51.2314 7.36402 53.3757 5.96002 56.4996 5.96002C58.0715 5.96002 59.1906 6.17957 60.2927 6.49157V6.05535C60.2927 3.80491 58.9286 2.64357 56.6192 2.64357C55.3776 2.64357 54.6571 2.80535 53.7487 3.21557C53.6291 3.26469 53.5095 3.2878 53.4155 3.2878C52.9855 3.2878 52.6011 2.9238 52.6011 2.49046C52.6011 2.10335 52.7691 1.86646 53.1051 1.71335C54.3183 1.17891 55.2324 1.00269 56.8071 1.00269C58.5471 1.00269 59.8826 1.45913 60.7882 2.38069C61.6226 3.22713 62.0526 4.43757 62.0526 6.03224V12.9338C62.0526 13.4654 61.671 13.8525 61.1669 13.8525C60.6629 13.8525 60.2898 13.4654 60.2898 12.98V11.7869C59.4298 12.9251 58.0003 13.942 55.8332 13.942C53.5437 13.942 51.2285 12.6102 51.2285 10.0478M60.3126 9.08002V7.87246C59.4071 7.60669 58.1883 7.33802 56.6875 7.33802C54.3781 7.33802 53.0881 8.3578 53.0881 9.92646V9.97557C53.0881 11.5471 54.5176 12.4658 56.1863 12.4658C58.4502 12.4658 60.3098 11.0618 60.3098 9.08002"
          fill="#727175"
        />
        <path
          d="M3.56752 1.78276C3.16315 0.887201 2.16646 0.476979 1.23527 0.901646C0.304075 1.32053 -0.0404943 2.3692 0.380963 3.27053L4.29368 11.8968C4.90878 13.2488 5.55805 13.9594 6.77401 13.9594C8.07255 13.9594 8.64209 13.1852 9.25434 11.8968C9.25434 11.8968 12.6687 4.35676 12.7 4.28165C12.7371 4.20076 12.8453 3.95809 13.1927 3.95809C13.486 3.96098 13.7337 4.19787 13.7337 4.51565V11.891C13.7337 13.0263 14.3545 13.9594 15.5506 13.9594C16.7466 13.9594 17.3902 13.0263 17.3902 11.891V5.85898C17.3902 4.69476 18.2131 3.94076 19.3323 3.94076C20.4514 3.94076 21.1975 4.72076 21.1975 5.85898V11.891C21.1975 13.0263 21.8212 13.9594 23.0143 13.9594C24.2075 13.9594 24.8568 13.0263 24.8568 11.891V5.85898C24.8568 4.69476 25.6769 3.94076 26.7961 3.94076C27.9152 3.94076 28.6613 4.72076 28.6613 5.85898V11.891C28.6613 13.0263 29.2821 13.9594 30.4781 13.9594C31.6741 13.9594 32.3206 13.0263 32.3206 11.891V5.02409C32.3206 2.4992 30.3215 0.731201 27.9152 0.731201C25.5089 0.731201 24.0053 2.4212 24.0053 2.4212C23.2051 1.36965 22.1031 0.73409 20.235 0.73409C18.2644 0.73409 16.5416 2.4212 16.5416 2.4212C15.7385 1.36965 14.3773 0.73409 13.2496 0.73409C11.504 0.73409 10.1172 1.5112 9.27427 3.47565L6.77401 9.44987L3.56752 1.78276Z"
          fill="#727175"
        />
        <path
          d="M82.7383 2.53087V2.51931C82.7383 1.67575 83.4132 0.965088 84.2618 0.965088C85.1104 0.965088 85.7882 1.66709 85.7882 2.51064V2.51931C85.7882 3.36575 85.1161 4.07642 84.2618 4.07642C83.4075 4.07642 82.7383 3.37731 82.7383 2.53087ZM85.4948 2.51931C85.4948 1.82309 84.968 1.25109 84.2618 1.25109C83.5556 1.25109 83.0259 1.82887 83.0259 2.51931V2.52798C83.0259 3.22131 83.5556 3.78753 84.2618 3.78753C84.968 3.78753 85.4948 3.20975 85.4948 2.51642"
          fill="#727175"
        />
        <path
          d="M83.6816 1.90999C83.6816 1.81754 83.7528 1.74243 83.844 1.74243H84.3537C84.5445 1.74243 84.6926 1.79732 84.7837 1.89843C84.8691 1.97932 84.9147 2.10065 84.9147 2.23932V2.2451C84.9147 2.48199 84.7951 2.62643 84.6185 2.70154L84.8435 2.97888C84.8748 3.01932 84.8948 3.05399 84.8948 3.1031C84.8948 3.19265 84.8207 3.25621 84.741 3.25621C84.6698 3.25621 84.6214 3.21865 84.5815 3.17243L84.2654 2.7651H84.0063V3.09154C84.0063 3.18399 83.9351 3.25621 83.844 3.25621C83.7528 3.25621 83.6816 3.18399 83.6816 3.09154V1.90999ZM84.3366 2.47043C84.4961 2.47043 84.5844 2.38954 84.5844 2.25954V2.25376C84.5844 2.1151 84.4904 2.03999 84.3338 2.03999H84.0091V2.47043H84.3366Z"
          fill="#727175"
        />
      </g>
      <defs>
        <clipPath id="clip0_1795_9120">
          <rect
            width="86"
            height="26"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VMWareReportsIcon
