import { realFileSize } from 'ui-v2/src/lib/helpers/string.helper'
import { BaseModel } from '../base-model'
import { roundTo } from 'ui-v2/src/lib/helpers/num.helper'

class VaultMetricsModel extends BaseModel {
  public readonly totalRecoveryPointSize: number

  public readonly reductionFactor: number

  public readonly assetsCountProtect: number

  public readonly assetsCountAcc: number

  // extra
  public readonly totalRecoveryPointSizeFormatted: string

  public readonly reductionFactorFormatted: string

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.totalRecoveryPointSize = params.totalRecoveryPointSize
    this.reductionFactor = params.reductionFactor
    this.assetsCountProtect = params.assetsCountProtect
    this.assetsCountAcc = params.assetsCountAcc

    // extra
    this.totalRecoveryPointSizeFormatted = realFileSize(
      this.totalRecoveryPointSize,
      '0'
    )
    const reductionFactorFormatted = String(roundTo(this.reductionFactor, 2))

    this.reductionFactorFormatted = reductionFactorFormatted
      ? `${reductionFactorFormatted}x`
      : '- - x'
  }
}

export default VaultMetricsModel
