import { Dayjs } from 'dayjs'
import AccessTokenModel from './access-token.model'
import { Nullable } from 'ui-v2/src/lib/engine-types'

export interface AccessTokenData {
  tokenId?: string
  tokenName: string
  scopes: Array<string>
}
export interface TokenIssueResultInterface {
  secretToken: string
  generatedToken: AccessTokenModel
}

export interface TokenListTableDataAttrs {
  tokenList: Array<AccessTokenModel>
}

export class TokenListTableRow {
  readonly #id: string

  readonly #token: string

  readonly #status: string

  readonly #lastUsed: Nullable<Dayjs>

  readonly #action: string

  constructor(parameters: TokenListTableRowAttr) {
    const { id, token, status, lastUsed, action } = parameters

    this.#id = id
    this.#token = token
    this.#status = status
    this.#lastUsed = lastUsed
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get token(): string {
    return this.#token
  }

  get status(): string {
    return this.#status
  }

  get lastUsed(): Nullable<Dayjs> {
    return this.#lastUsed
  }

  get action(): string {
    return this.#action
  }
}
export interface TokenListTableRowAttr {
  id: string
  token: string
  status: string
  lastUsed: Nullable<Dayjs>
  action: string
}
