import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { NotificationsClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function useDisconnectSlackMutation() {
  const notificationsClient = useGrpcClient(NotificationsClient)
  const toast = useToast()
  return useMutation({
    mutationFn: async () => {
      if (!notificationsClient) {
        throw new Error('Notifications client not initialized')
      }
      return notificationsClient.disconnectSlackFromProfile()
    },
    onError: (error) => {
      toast.error(
        error.message ?? 'Something went wrong while disconnecting slack'
      )
    },
    onSuccess: () => {
      toast.success('Slack has been disconnected')
    },
  })
}
