import { useTenantSettingsQuery } from './tenant/tenant-settings'
import { useUpdateTenantMutation } from './tenant/update-tenant'
import { useTokenListQuery } from './personal-access-token/token-list'
import { useRevokedTokenListQuery } from './personal-access-token/revoked-token-list'
import { useIssueMutation } from './personal-access-token/issue'
import { useUpdateIssueMutation } from './personal-access-token/update-issue'

export {
  useTenantSettingsQuery,
  useUpdateTenantMutation,
  useTokenListQuery,
  useRevokedTokenListQuery,
  useIssueMutation,
  useUpdateIssueMutation,
}
