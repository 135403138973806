import React, { ReactNode } from 'react'
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { useSPAAccessToken } from './spa/use-spa-access-token'
import { Auth0SpaKeys, AuthStrategy } from './types'
import PagePathConstant from '@lib/constants/page-path.constant'
import SystemHelper from '@lib/helpers/system.helper'

class Auth0EnvError extends Error {
  constructor() {
    super(
      'Missing Auth0 environment variables: publicAuth0SpaDomain, publicAuth0SpaClientId'
    )
    this.name = 'Auth0EnvError'
  }
}

let authStrategyInstance: AuthStrategy | undefined

interface Auth0SPAStrategyAttrs {
  domain: string
  clientId: string
  cookieDomain: string
}

export class Auth0SPAStrategy implements AuthStrategy {
  #domain: string | undefined

  #clientId: string | undefined

  #cookieDomain: string | undefined

  constructor(attrs: Auth0SPAStrategyAttrs) {
    this.#domain = attrs.domain
    this.#clientId = attrs.clientId
    this.#cookieDomain = attrs.cookieDomain

    if (!attrs.domain || !attrs.clientId || !attrs.cookieDomain) {
      throw new Auth0EnvError()
    }
  }

  #redirectCallback = (appState?: AppState): void => {
    const targetUrl = appState?.returnTo || PagePathConstant.DASHBOARD

    SystemHelper.pureNavigate(targetUrl)
  }

  wrapApp = (children: ReactNode): JSX.Element => {
    return (
      <Auth0Provider
        domain={this.#domain!}
        clientId={this.#clientId!}
        cookieDomain={this.#cookieDomain!}
        onRedirectCallback={this.#redirectCallback}
        authorizationParams={{
          redirect_uri:
            typeof window !== 'undefined' ? window.location.origin : undefined,
        }}
      >
        {children}
      </Auth0Provider>
    )
  }

  getAccessToken = () => {
    try {
      const { token } = useSPAAccessToken()
      return token
    } catch (error) {
      console.error('Error getting access token:', error)
    }
  }

  getUser = () => {
    try {
      const { user } = useAuth0()
      return user
    } catch (error) {
      console.error('Error getting user:', error)
    }
  }
}

export const createAuthStrategy = (spaEnvKeys: Auth0SpaKeys): AuthStrategy => {
  if (!authStrategyInstance) {
    authStrategyInstance = new Auth0SPAStrategy(spaEnvKeys)
  }
  return authStrategyInstance
}
