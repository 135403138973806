import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { StrHelper } from '@lib/helpers'

interface Props {
  dataProtectedSize: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    gap="14px"
    justifyContent="center"
    marginTop="10px"
  >
    {children}
  </Box>
)

function DataProtectedSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          140 GiB
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" fontSize="16px">
          Front-end data protected
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function DataProtected({ dataProtectedSize, isLoading }: Props) {
  if (isLoading) {
    return <DataProtectedSkeleton />
  }

  const formattedDataProtectedSize = StrHelper.toHumanBytes(
    dataProtectedSize,
    '0',
    0
  )

  return (
    <Container>
      <Typography fontWeight="600" fontSize="22px">
        {formattedDataProtectedSize}
      </Typography>
      <Typography fontWeight="400" fontSize="16px">
        Front-end data protected
      </Typography>
    </Container>
  )
}
