import { AbstractListRequest } from '@lib/clients/list-request'
import { ListGeneratedReportsRequest } from '@lib/clients/grpc-imports'

export class ListGeneratedReportsPagination extends AbstractListRequest<ListGeneratedReportsRequest> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): ListGeneratedReportsRequest {
    const grpcRequest = new ListGeneratedReportsRequest().setPageToken(
      this.#pageToken
    )

    return grpcRequest
  }
}
