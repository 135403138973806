interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const AssetsScanneIcon = ({ size = 18, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 10H2C0.9 10 0 10.9 0 12V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12C18 10.9 17.1 10 16 10ZM4 16C2.9 16 2 15.1 2 14C2 12.9 2.9 12 4 12C5.1 12 6 12.9 6 14C6 15.1 5.1 16 4 16ZM16 0H2C0.9 0 0 0.9 0 2V6C0 7.1 0.9 8 2 8H16C17.1 8 18 7.1 18 6V2C18 0.9 17.1 0 16 0ZM4 6C2.9 6 2 5.1 2 4C2 2.9 2.9 2 4 2C5.1 2 6 2.9 6 4C6 5.1 5.1 6 4 6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AssetsScanneIcon
