/* eslint-disable import/no-extraneous-dependencies */
import { AWSSliceCriteria } from 'blues-corejs/dist/models/inventory.model'
import { OVASliceCriteria } from 'blues-corejs/dist/models/ova-account'
import { TabViewInterface, ViewKind } from './types'

export function isAWSSliceCriteria(
  slice: AWSSliceCriteria | OVASliceCriteria,
  view: TabViewInterface
): slice is AWSSliceCriteria {
  return (
    'regions' in slice ||
    'include_tags' in slice ||
    'exclude_tags' in slice ||
    view.kind === ViewKind.AWS
  )
}

export function isOVASliceCriteria(
  slice: AWSSliceCriteria | OVASliceCriteria
): slice is OVASliceCriteria {
  return 'backupVendors' in slice || 'accountIds' in slice
}
