import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import ContentBlock from '@components-composite/content-block/ContentBlock'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import SimpleSelect from '@components-simple/simple-select/SimpleSelect'
import PreloaderConstants from '@lib/constants/preloader.constant'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { DefinedDateRangeTuple } from '@lib/engine-types'
import FilterFactory from '@lib/factories/filter.factory'
import TimeHelper from '@lib/helpers/time.helper'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import ValueInterface from '@lib/interfaces/value.interface'
import {
  requestWebhooksInvocations,
  requestWebhooksList,
  setWebhooksInvocations,
} from '@store/actions/webhook.action'
import {
  getWebhooksInvocations,
  getWebhooksList,
} from '@store/selectors/webhook.selector'
import { Chart } from 'chart.js'
import { useDispatch, useSelector } from 'react-redux'
import drawWebhookEventsTimelineChart from './drawWebhooksEventsTimelineChart'

function WebhookEventsTimeline() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestWebhooksList())
  }, [])

  const webhooks = useSelector(getWebhooksList)
  const webhooksInvocations = useSelector(getWebhooksInvocations)
  const MAX_SELECTED_WEBHOOKS = 10

  const loading: boolean = usePreloaderAny([
    PreloaderConstants.REQUEST_WEBHOOK_INVOCATIONS,
    PreloaderConstants.REQUEST_WEBHOOKS_LIST,
  ])

  const chartRef = useRef<HTMLCanvasElement>(null)
  const [chartInstance, setChartInstance] = useState<Chart>()

  const dateRangeFilters = FilterFactory.getWebhooksDateRangeFilters()

  const [noDataMessage, setNoDataMessage] = useState<string>('')
  const [labels, setChartLabels] = useState<Array<string>>([])
  const [hooksFilters, setHooksFilter] = useState<Array<ValueInterface>>([])
  const [selectedWebhooks, setSelectedWebhooks] = useState<
    Array<ValueInterface>
  >([])

  useEffect(() => {
    if (webhooks?.length) {
      const items = webhooks.map((webhook) => ({
        label: webhook.getName(),
        name: webhook.getName(),
        value: webhook.getId(),
      }))

      setHooksFilter(items)
      setSelectedWebhooks(items.slice(0, MAX_SELECTED_WEBHOOKS))
    }
  }, [webhooks])

  const [dateFilter, setSelectedDate] =
    useState<ValueInterface>(dateRangeFilters)

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    setSelectedWebhooks(newFilters.slice(0, MAX_SELECTED_WEBHOOKS))
  }

  const onDateFiltersChange = (newFilters: ValueInterface) => {
    setSelectedDate(newFilters)
  }

  const selectedDateFilterOption = useMemo<ValueInterface | undefined>(
    () =>
      dateFilter.options?.find((option) => option.value === dateFilter.value),
    [dateFilter]
  )

  useEffect(() => {
    dispatch(setWebhooksInvocations([]))
    if (!selectedWebhooks?.length || !selectedDateFilterOption) {
      return
    }
    const range: DefinedDateRangeTuple = [
      TimeHelper.momentToTimestamp(
        TimeHelper.currentMinusHours(
          Number(selectedDateFilterOption.extraValue)
        )
      ),
      TimeHelper.momentToTimestamp(TimeHelper.momentNow()),
    ]
    const periods: Array<DefinedDateRangeTuple> = TimeHelper.generatePeriods(
      range,
      Number(selectedDateFilterOption.supplementalValue)
    )
    const webHooksIds: Array<string> = selectedWebhooks.map(({ value }) =>
      String(value)
    )

    setChartLabels(
      periods.map(([start]) =>
        TimeHelper.timestampFormat(
          start,
          TimeFormatConstants.SHORT_DATETIME_FORMAT
        )
      )
    )
    dispatch(requestWebhooksInvocations(webHooksIds, periods))
  }, [selectedDateFilterOption, selectedWebhooks])

  useEffect(() => {
    if (selectedWebhooks?.length === 0) {
      setNoDataMessage('There are no webhooks to display.')
    }
    setHooksFilter(
      hooksFilters.map((hook) => {
        const selected = selectedWebhooks.map(({ value }) => value)
        hook.disabled =
          selectedWebhooks?.length >= MAX_SELECTED_WEBHOOKS
            ? !selected.includes(hook.value)
            : false

        return hook
      })
    )
  }, [selectedWebhooks])

  useEffect(() => {
    if (
      webhooksInvocations.every(
        (item) => item.webhookInvocationDataListList?.length === 0
      )
    ) {
      setNoDataMessage('There are no webhooks data to display.')
      return
    }

    setNoDataMessage('')

    if (chartInstance) {
      chartInstance.destroy()
    }

    const newChartInstance = drawWebhookEventsTimelineChart(
      {
        labels,
        webhooks: selectedWebhooks.map(({ name, value }) => ({
          name,
          id: String(value),
        })),
        invocations: webhooksInvocations.map(
          ({ webhookInvocationDataListList }) => webhookInvocationDataListList
        ),
      },
      chartRef.current
    )

    setChartInstance(newChartInstance)
  }, [webhooksInvocations, chartRef.current])

  return (
    <div className="widgetWrap wrap-1648744329123">
      <div className="widgetHeader timelineHeader">
        <div className="timelineTitle">Sync events</div>
        <div className="filters">
          <ViMultipleSelect
            className="webhooksFilter"
            disabled={loading}
            possible={hooksFilters}
            selected={selectedWebhooks}
            onChange={(value) => onFiltersChange(value)}
            placeholder="Select Webhooks"
          />

          <SimpleSelect
            data={dateFilter}
            onChange={(value) => onDateFiltersChange(value)}
            typeFunction={Number}
            disabled={loading}
          />
        </div>
      </div>
      <ContentBlock
        data={selectedWebhooks}
        loading={loading}
        noDataMessage={noDataMessage}
      >
        <div className="webhooksChartWrapper">
          <canvas ref={chartRef}></canvas>
        </div>
        <div id="legend-container" className="webhooksChartLegend"></div>
      </ContentBlock>
    </div>
  )
}

export default memo(WebhookEventsTimeline)
