import { Box, Typography } from '@mui/material'
import React from 'react'
import { useIsDeploymentFiltersNotEmpty } from '../stores'
import { SelectedAssetIdsChips } from './selected-asset-ids-chips'
import { SelectedJobKindsChips } from './selected-job-kinds-chips'
import { SelectedJobStatusesChips } from './selected-job-statuses-chips'

export function SelectedFiltersChipsBlock() {
  const isFiltersNotEmpty = useIsDeploymentFiltersNotEmpty()

  if (!isFiltersNotEmpty) {
    return null
  }

  return (
    <Box marginBottom="10px">
      <Typography fontSize="14px" fontWeight="600" marginBottom="10px">
        Selected Jobs via Filters:
      </Typography>
      <Box display="flex" gap="10px" flexWrap="wrap">
        <SelectedJobKindsChips />
        <SelectedJobStatusesChips />
        <SelectedAssetIdsChips />
      </Box>
    </Box>
  )
}
