import DialogModal from '@components-composite/modals/DialogModal'
import AccordionBlock from '@components-simple/accordion-block/AccordionBlock'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import DeleteButton from '@components-simple/delete-button/DeleteButton'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { SelectedVariant, TabAssetType } from '@lib/constants/app.constant'
import Modal from '@lib/constants/modal.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { VIRow } from '@lib/engine-types'
import StrHelper from '@lib/helpers/str.helper'
import { useModal } from '@lib/hooks/useModal'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { TextField } from '@mui/material'
import { setAllSelectedAssets } from '@store/actions/assets-select.action'
import {
  setEditSelectedCloudConnectors,
  updateEditPolicy,
} from '@store/actions/edit-policy.action'
import { deletePolicy } from '@store/actions/policies.action'
import {
  getAssetTypeTabSelectedNum,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import {
  getEditIntegrityCheck,
  getEditIntegrityScan,
  getEditPolicyName,
  getEditSelectedVariant,
  getIntegrityScanOption,
} from '@store/selectors/edit-policy.selector'
import { getPoliciesNames } from '@store/selectors/policies.selector'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import ValueInterface from '@lib/interfaces/value.interface'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  AssetsSelection,
  CloudConnectorsSelection,
  IntegrityScan,
  ProtectionPolicy,
  VaultsSelection,
} from './edit-policy-steps'
import { EditPolicyMenu } from './edit-policy-menu'
import { useInitializeInitialStateForEditPolicy } from './use-initialize-initial-state-for-edit-policy'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { PoliciesRoutes } from '@lib/router'

const EDIT_POLICY_ACCORDION_LABELS = [
  'Protection policy',
  'Integrity Scan',
  'Cloud Connectors',
  'Assets',
  'Vaults',
]

function EditPolicyComplex() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const basePolicyName = StrHelper.base64Decode(String(id))
  const policyName = useSelector(getEditPolicyName)
  const integrityScan = useSelector(getEditIntegrityScan)
  const integrityScanOption = useSelector(getIntegrityScanOption)
  const existingPoliciesNames = useSelector(getPoliciesNames)
  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const selectedVariant = useSelector(getEditSelectedVariant)
  const integrityCheck = useSelector(getEditIntegrityCheck)
  const router = useNavigation()

  const selectedTypeVariant = useSelector(getAssetTypeTabSelectedNum)

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_POLICIES,
    PreloaderConstants.REQUEST_EDIT_POLICY,
    PreloaderConstants.DELETE_POLICY,
    PreloaderConstants.UPDATE_EDIT_POLICY,
    PreloaderConstants.GET_ALL_RED_STACKS,
  ])

  const [isS3TableErrors, setIsS3TableErrors] = useState<boolean>(false)
  const [isEfsTableErrors, setIsEfsTableErrors] = useState<boolean>(false)

  const handleRedirectCallback = () => {
    router.push(PoliciesRoutes.root)
  }

  const { openModal, modalProps } = useModal<string>(
    Modal.deletePolicy,
    (policyId: string) => {
      dispatch(deletePolicy(policyId, handleRedirectCallback))
    }
  )

  const {
    openModal: openRemoveCloudConnector,
    modalProps: modalRemoveCloudConnectorProps,
  } = useModal<{ ccList: VIRow; rsId: string }>(
    Modal.removeCloudConnectorFromPolicy,
    (params) => {
      dispatch(setEditSelectedCloudConnectors(params.ccList))

      let assetsWithoutRemoved: Array<AssetWithRelatedAssets<Asset>> = []
      params.ccList.forEach((item: ValueInterface) => {
        const currentAssets = selectedAssets.filter(
          (assetWithRelated: AssetWithRelatedAssets<Asset>) =>
            assetWithRelated.asset.awsAccountId !== item.value &&
            assetWithRelated.asset.awsRegion !== item.extraValue
        )
        if (currentAssets.length) {
          assetsWithoutRemoved = [...assetsWithoutRemoved, ...currentAssets]
        }
      })
      dispatch(setAllSelectedAssets(assetsWithoutRemoved))
    }
  )

  const onDeletePolicy = () => {
    openModal(policyName)
  }

  const trimmedPolicyName = policyName.trim()

  // integrity check is not defined properly
  const isIntegrityScanOptionSelected =
    integrityScanOption === IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO ||
    integrityScanOption === IntegrityScanOptions.SCAN_WITH_ELASTIO

  const isInegrityScanWithoutVariants =
    integrityCheck === IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED &&
    !integrityScan.scanForMalware &&
    !integrityScan.scanForRansomware

  const isNotIntegrityCheckDefined =
    isIntegrityScanOptionSelected && isInegrityScanWithoutVariants

  // assets
  const isAssetsName =
    policyName !== basePolicyName &&
    existingPoliciesNames.includes(trimmedPolicyName)
  const isAssetsVariant = selectedVariant === SelectedVariant.AssetsVariant
  const isNoSelectedAssets = selectedAssets.length === 0
  const isS3VariantWithTableError =
    selectedTypeVariant === TabAssetType.S3 && isS3TableErrors
  const isEfsVariantWithTableError =
    selectedTypeVariant === TabAssetType.EFS && isEfsTableErrors

  const updatePolicyDisabled =
    loading ||
    isAssetsName ||
    (isNoSelectedAssets && isAssetsVariant) ||
    isS3VariantWithTableError ||
    isEfsVariantWithTableError ||
    isNotIntegrityCheckDefined

  const onUpdatePolicy = (executeImmediately: boolean) => {
    dispatch(updateEditPolicy(executeImmediately, handleRedirectCallback))
  }

  const handleAssetsSelectionProblems = (
    isS3Invalid: boolean,
    isEFSInvalid: boolean
  ) => {
    setIsS3TableErrors(isS3Invalid)
    setIsEfsTableErrors(isEFSInvalid)
  }

  useInitializeInitialStateForEditPolicy()

  return (
    <Box className="wrap-1642148455743">
      <Box className="controlHeaderBlock">
        <Box className="controlHeader">
          <Box className="controlDescSmall">
            Edit the schedule for the policy.
          </Box>
          <Box className="controlHeaderControls">
            <DeleteButton
              disabled={loading}
              onClick={onDeletePolicy}
              label="Delete Policy"
            />
          </Box>
        </Box>
      </Box>
      <Box className="newFormSingleRow v2StaticTextInput">
        <TextField
          value={policyName}
          label="Policy name"
          variant="outlined"
          className="jsPolicyNameField"
          disabled
        />
      </Box>

      <AccordionBlock labels={EDIT_POLICY_ACCORDION_LABELS} loading={loading}>
        <ProtectionPolicy />
        <IntegrityScan
          isNotEc2AndEbsAssetType={
            selectedTypeVariant !== TabAssetType.Ec2andEbs
          }
        />
        <CloudConnectorsSelection
          isAssetsVariant={selectedVariant === SelectedVariant.AssetsVariant}
          isLoading={loading}
          onRemoveCloudConnector={openRemoveCloudConnector}
        />
        <AssetsSelection
          isLoading={loading}
          isAssetsSelectionHasProblems={handleAssetsSelectionProblems}
        />
        <VaultsSelection isLoading={loading} />
      </AccordionBlock>

      <BasePortalButtons alwaysInPortal>
        <EditPolicyMenu
          updatePolicyDisabled={updatePolicyDisabled}
          onUpdatePolicy={onUpdatePolicy}
        />
      </BasePortalButtons>

      <DialogModal
        description={
          <Box>
            You are going to remove the Policy
            {<LongTextTooltip text={modalProps.data ?? ''} maxLength={35} />}
            Please confirm the deletion.
          </Box>
        }
        {...modalProps}
      />
      <DialogModal
        description={
          <Box>
            If you remove the Cloud Connector all assets associated with the
            Cloud Connector no longer be covered by a Protection Policy. Do you
            still like to proceed?
          </Box>
        }
        className="removeCloudConnectorFromPolicy"
        {...modalRemoveCloudConnectorProps}
      />
    </Box>
  )
}

export default memo(EditPolicyComplex)
