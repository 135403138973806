/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProviderProto, OvaServerProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { OvaBackupProvider, OvaServer } from 'blues-corejs/dist'

export class OvaServerTransformer {
  #server: OvaServerProto.AsObject

  constructor(server: OvaServerProto.AsObject) {
    this.#server = server
  }

  transform(): OvaServer {
    return this.#transformServer(this.#server)
  }

  #transformServer(server: OvaServerProto.AsObject): OvaServer {
    return new OvaServer({
      backupProvider: this.#convertBackupProvider(server.backupProvider),
      backupProviderAssetId: server.backupProviderAssetId,
      ccAssetId: server.ccAssetId,
      createdAt: secondsToDate(server.createdAt?.seconds),
      id: server.id,
      name: server.name,
      osName: server.osName,
      ovaAccountId: server.ovaAccountId,
      updatedAt: secondsToDate(server.updatedAt?.seconds),
    })
  }

  #convertBackupProvider(provider: OvaBackupProviderProto): OvaBackupProvider {
    const mapping: Record<OvaBackupProviderProto, OvaBackupProvider> = {
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProvider.VERITAS_NET_BACKUP,
      [OvaBackupProvider.VEEAM]: OvaBackupProvider.VEEAM,
      [OvaBackupProvider.COHESITY]: OvaBackupProvider.COHESITY,
      [OvaBackupProvider.RUBRIK]: OvaBackupProvider.RUBRIK,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProvider.COMMVAULT,
      [OvaBackupProvider.AWS_BACKUP_VMWARE]:
        OvaBackupProvider.AWS_BACKUP_VMWARE,
    }

    return mapping[provider]
  }
}
