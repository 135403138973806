import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const AccountIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const account =
    searchParams.get(ASSET_FILTERS_MAP.ACCOUNT_ID)?.split(',') || []

  const { data: redStackListData } = useRedStackListQuery()

  const awsAccountIdOptions = useMemo(() => {
    if (!redStackListData) {
      return []
    }

    const awsAccountIdSet = new Set<string>()

    redStackListData.forEach((redStack) =>
      awsAccountIdSet.add(redStack.awsAccountId)
    )

    return Array.from(awsAccountIdSet).map((awsAccountId) => ({
      value: awsAccountId,
      label: awsAccountId,
    }))
  }, [redStackListData])

  const handleAwsAccountIdChange = (
    newAccountIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newAccountIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.ACCOUNT_ID)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.ACCOUNT_ID, newAccountIdList.join(','))
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsAccountIdOptions}
      value={account}
      onChange={handleAwsAccountIdChange}
      label="Account"
      width={200}
    />
  )
}

export default AccountIdFilter
