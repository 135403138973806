import { Card } from '@mui/material'
import { styled } from '@mui/material/styles'

const SolidCard = styled(Card)(({ theme }) => ({
  paddingX: 2.5,
  paddingY: 2,
  borderRadius: 6,
  background: theme.palette.background.secondary,
  boxShadow: 'none',
}))

export default SolidCard
