import React, { memo } from 'react'
import TableMenu from '@tables/core/table-menu'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { useLogout } from '@features/authentication'

const MENU = ['Logout']

interface Props {
  userAgent: string
  dateStartSession: string
  device: string
  ip: string
  location: string
  activeStatus?: boolean
}

function SessionInfo({
  userAgent,
  dateStartSession,
  device,
  ip,
  location,
  activeStatus = false,
}: Props) {
  const { logout } = useLogout()
  return (
    <div className="wrap-1621677798441 jsSessionInfo">
      <div className="sessionWrapper">
        <div className="top-row">
          <div>
            <span className="fontMedium jsUa">{userAgent}</span>
            <span className="dash jsDs">
              <DateTimeTooltip
                placement="top"
                direction="row"
                date={new Date(dateStartSession)}
              />
            </span>
          </div>
          <div className="controlLocation">
            <span className="jsLo">{location}</span>
            <span className="siStatus inActive jsDe">{device}</span>
            <span className="siStatus inActive jsIp">{ip}</span>
            {activeStatus && (
              <span className="siStatus active jsActive">Active NOW</span>
            )}
          </div>
        </div>
        <div className="mt5">
          <TableMenu
            data={[]}
            menu={MENU}
            showMenu={activeStatus}
            onMenuClick={() => logout()}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(SessionInfo)
