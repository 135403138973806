import { BaseRoutes } from '../base'

export enum PolicyTab {
  BACKUP = 0,
  RETENTION = 1,
}

export enum RouteFragments {
  POLICY_CREATE = 'add',
  DYNAMIC_ID = ':id',
}

export class PoliciesRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_POLICIES
  }

  public static get policyBackupWithIdPlaceholder(): string {
    return `${this.root}/${RouteFragments.DYNAMIC_ID}`
  }

  public static get policiesRetention(): string {
    return this.#ROUTE_POLICY_RETENTION
  }

  public static get policyRetentionEdit(): string {
    return this.#ROUTE_POLICY_RETENTION_EDIT
  }

  public static get policyRetentionEditWithIdPlaceholder(): string {
    return `${this.policyRetentionEdit}/${RouteFragments.DYNAMIC_ID}`
  }

  public static get policyBackupCreate(): string {
    return `${this.root}/${RouteFragments.POLICY_CREATE}`
  }

  public static get policyRetentionCreate(): string {
    return `${this.policiesRetention}/${RouteFragments.POLICY_CREATE}`
  }

  public static get backupTable(): string {
    return this.policiesTableRoute(PolicyTab.BACKUP)
  }

  public static get retentionTable(): string {
    return this.policiesTableRoute(PolicyTab.RETENTION)
  }

  public static policiesTableRoute(tabId: PolicyTab): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
      },
    })
  }

  public static policyRetentionEditWithId(policyId: string): string {
    return `${this.policyRetentionEdit}/${this.base64Encode(policyId)}`
  }

  public static buildPathWithEncodedId(id: string | number): string {
    return `${this.root}/${this.base64Encode(String(id))}`
  }

  public static withIdPlaceholder(): string {
    return `${this.root}/${RouteFragments.DYNAMIC_ID}`
  }

  static #ROUTE_POLICIES = '/policies'

  static #ROUTE_POLICY_RETENTION = '/policies-retention'

  static #ROUTE_POLICY_RETENTION_EDIT = '/policy-retention-edit'

  static readonly #QUERY_KEYS = {
    TAB: 't',
  }
}
