import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RetentionPoliciesClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useRetentionPolicyListQuery() {
  const retentionPoliciesClient = useGrpcClient(RetentionPoliciesClient)

  return useQuery({
    queryKey: ['catalog', 'retention-policy-list'],
    queryFn: async () => {
      if (!retentionPoliciesClient) {
        throw new Error('Retention policy client not initialized')
      }
      return retentionPoliciesClient.listRetentionPolicies()
    },
    enabled: !!retentionPoliciesClient,
  })
}
