import {
  AwsScanCoverageResponse,
  AwsBackupCoverageResponse,
} from '@lib/clients/aws-statistics/types'

export function computeScanCoverageStats({
  ec2,
  ebs,
  s3,
  efs,
}: AwsScanCoverageResponse) {
  const totalInstances = ec2?.total ?? 0
  const coveredInstances = ec2?.covered ?? 0

  const totalVolumes = ebs?.total ?? 0
  const coveredVolumes = ebs?.covered ?? 0

  const totalS3Buckets = s3?.total ?? 0
  const coveredS3Buckets = s3?.covered ?? 0

  const totalFilesystems = efs?.total ?? 0
  const coveredFilesystems = efs?.covered ?? 0

  const numberOfAssets =
    totalInstances + totalVolumes + totalS3Buckets + totalFilesystems
  const numberOfAssetsWithScans =
    coveredInstances + coveredVolumes + coveredS3Buckets + coveredFilesystems
  const percentOfAssetsWithScans =
    (numberOfAssetsWithScans / numberOfAssets) * 100

  return {
    totalInstances,
    coveredInstances,
    totalVolumes,
    coveredVolumes,
    totalS3Buckets,
    coveredS3Buckets,
    totalFilesystems,
    coveredFilesystems,
    numberOfAssets,
    numberOfAssetsWithScans,
    percentOfAssetsWithScans,
  }
}

export function computeBackupCoverageStats({
  ec2,
  ebs,
}: AwsBackupCoverageResponse) {
  const totalInstances = ec2?.total ?? 0
  const coveredInstances = ec2?.covered ?? 0

  const totalVolumes = ebs?.total ?? 0
  const coveredVolumes = ebs?.covered ?? 0

  const numberOfAssets = totalInstances + totalVolumes
  const numberOfAssetsWithBackups = coveredInstances + coveredVolumes
  const percentOfAssetsWithBackups =
    (numberOfAssetsWithBackups / numberOfAssets) * 100

  return {
    totalInstances,
    coveredInstances,
    totalVolumes,
    coveredVolumes,
    numberOfAssets,
    numberOfAssetsWithBackups,
    percentOfAssetsWithBackups,
  }
}
