import { useEffect, useState } from 'react'

interface Props<T> {
  ref: React.RefObject<HTMLElement>
  slides: Array<T>
  selectedSlide: number
}

type Direction = 'next' | 'back'

const SHOW_SLIDES_PER_PAGE = 4

export default function useSlider<T>({ ref, slides, selectedSlide }: Props<T>) {
  const [currentSlide, setCurrentSlide] = useState<number>(selectedSlide)

  const handleSlideChange = (index: number) => {
    setCurrentSlide(index)
  }

  const [canScroll, setCanScroll] = useState({
    canScrollNext: slides.length > SHOW_SLIDES_PER_PAGE,
    canScrollBack: false,
  })

  const onSlideChange = () => {
    const canScrollOnInitial = slides.length > SHOW_SLIDES_PER_PAGE

    if (!ref.current) {
      return {
        canScrollNext: canScrollOnInitial,
        canScrollBack: false,
      }
    }

    const { scrollLeft, scrollWidth, clientWidth } = ref.current

    const canScrollNext = scrollLeft + clientWidth < scrollWidth

    const canScrollBack = scrollLeft > 0

    setCanScroll({
      canScrollNext,
      canScrollBack,
    })
  }

  const handleScroll = (direction: Direction) => {
    if (!ref.current) {
      return
    }

    const { width } = ref.current.getBoundingClientRect()
    const scrollLeft = ref.current.scrollLeft
    const newScrollLeft =
      direction === 'next' ? scrollLeft + width : scrollLeft - width

    ref.current.scrollTo({
      left: newScrollLeft,
      behavior: 'smooth',
    })
  }

  const onScrollNext = () => handleScroll('next')

  const onScrollBack = () => handleScroll('back')

  useEffect(() => {
    ref.current?.addEventListener('scrollend', onSlideChange)

    return () => {
      ref.current?.removeEventListener('scrollend', onSlideChange)
    }
  }, [])

  return {
    currentSlide,
    canScrollNext: canScroll.canScrollNext,
    canScrollBack: canScroll.canScrollBack,
    handleSlideChange,
    onScrollNext,
    onScrollBack,
  }
}
