import React from 'react'
import Typography from '@mui/material/Typography'

export const MIN_VERSION_FOR_ENTROPY_DETECTION = '0.31'

export const ENTROPY_DETECTION_CONSTANTS = {
  DETECTION_TITLE: 'Insider Threat Detection',
  DETECTION_INFO_LABEL: 'What is Insider Threat Detection?',
  DETECTION_INFO_MESSAGE:
    'High Entropy detection monitors file encryption changes over time and alerts you when a significant percentage of files in a directory become encrypted. This detection method can identify both deliberate encryption and malicious non-ransomware encryption activities.',
  DETECTION_CHECKBOX_LABEL: 'Insider Threat Detection',
  DETECTION_CHECKBOX_DESCRIPTION:
    // eslint-disable-next-line quotes
    "Detect potential internal threats by identifying newly encrypted or suspiciously modified files across your network. This behavioral model analyzes file changes between scans, flagging files that show signs of encryption, and includes file type analysis to help safeguard your company's data.",
  DETECTION_NOT_AVAILABLE_BY_VERSION: `This feature is available in Elastio versions ${MIN_VERSION_FOR_ENTROPY_DETECTION} and higher. Upgrade to enable it.`,
  DETECTION_NOT_AVAILABLE_BY_MALWARE: (
    <>
      This feature is not available when{' '}
      <Typography variant="caption" fontSize="11px" fontWeight="600">
        only
      </Typography>{' '}
      Malware Scan is enabled.
    </>
  ),
}
