import React, { memo } from 'react'
import BlueCheckIcon from '@inline-img/general/blue-check-icon'
import { ListSubheader, Typography, Button, Box, styled } from '@mui/material'

const BoxStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingInline: '10px',
})

interface Props {
  selectedCount: number
  handleAllSelected: () => void
}

function AllOptionsBlock({ selectedCount, handleAllSelected }: Props) {
  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <ListSubheader>
      <BoxStyled onClick={stopPropagation}>
        <Typography variant="body1">{selectedCount} selected</Typography>
        <Button role="button" variant="el-gray" onClick={handleAllSelected}>
          <BlueCheckIcon />
          <span>All</span>
        </Button>
      </BoxStyled>
    </ListSubheader>
  )
}

export default memo(AllOptionsBlock)
