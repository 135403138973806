import {
  Title,
  ControlledSelect,
  ControlledInput,
  TimeRangeDropdown,
  DateRangePicker,
  ControlledFormatCheckboxGroup,
  MaxWidthFormControl,
  ovaBackupProviderOptions,
  FormVariant,
  reportOptions,
  TimeRangeSelection,
} from '@features/reports/schedules/shared'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useForm,
  FormProvider,
  Controller,
  useFormContext,
} from 'react-hook-form'
import { styled } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { MultiSelectSearch } from '@components/shared'
import { FormData, FormSchema, isType2Schema } from './validations/schema'
import { FORM_FIELDS } from './form-field-names'
import { getDefaultValues } from './default-values'
import {
  useTabsActions,
  useReportSourceType,
} from '@features/reports/schedules/use-data-store'
import { DateRangeTuple } from '@lib/engine-types'
import { transformDateRangeForApi } from '@components-composite/date-range-picker-with-call-api/transform-date-range-for-api'
import { useOvaAccounts } from '@features/reports/schedules/use-ova-accounts-store'
import { transformAccountsToSelectOptions } from '@features/reports/schedules/shared/transform-accounts-to-select-options'
import ReportRangeSelector from '@components-composite/report-range-selector/ReportRangeSelector'
import { useReportPeriod } from './use-report-period'
import moment from 'moment-timezone'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'

const MarginSelectWrapper = styled(Box)`
  margin-bottom: 0;
  margin-top: 8px;

  & > * {
    margin-bottom: 0;
  }
  .rrReportPeriod {
    margin-bottom: 0; /* Override margin for rrReportPeriod */
  }
`

function AWSForm({
  formats,
  handleDateChange,
  dateRange,
}: {
  formats: Array<string>
  dateRange: DateRangeTuple
  handleDateChange: () => void
}) {
  return (
    <>
      <MarginSelectWrapper>
        <ReportRangeSelector
          disabled={false}
          reportPeriod={dateRange as any}
          onChange={handleDateChange}
          labelStyle={{ fontSize: '14px' }}
        />
      </MarginSelectWrapper>

      <Title>Format:</Title>
      <ControlledFormatCheckboxGroup
        name={FORM_FIELDS.FORMATS}
        options={formats.map((format) => ({
          value: format,
          label: format,
        }))}
      />
    </>
  )
}

const getDatePeriod = (value: string | number): DateRangeTuple => {
  const timezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value

  const today = moment().tz(timezone)

  switch (value) {
    case TimeRangeSelection.LAST_7_DAYS:
      return [today.clone().subtract(7, 'days'), today]
    case TimeRangeSelection.LAST_30_DAYS:
      return [today.clone().subtract(30, 'days'), today]
    default:
      return [today, today]
  }
}

function BackupConnectorForm({ formats }: { formats: Array<string> }) {
  const { setValue, control, getValues } = useFormContext()
  const ovaAccounts = transformAccountsToSelectOptions(useOvaAccounts())

  const handleDateChange = (newDateRange: DateRangeTuple) => {
    setValue(FORM_FIELDS.START_AT, newDateRange[0])
    setValue(FORM_FIELDS.END_AT, newDateRange[1])
  }

  const onReportPeriodChangeInner = (value: string | number) => {
    const datePeriod = getDatePeriod(value)
    handleDateChange(datePeriod)
  }

  useEffect(() => {
    onReportPeriodChangeInner(TimeRangeSelection.TODAY)
  }, [])

  return (
    <>
      <Title>Select OVA Accounts</Title>
      <MaxWidthFormControl>
        <MultiSelectSearch
          name={FORM_FIELDS.OVA_ACCOUNTS}
          options={ovaAccounts}
          placeholder="Select"
          disabled={false}
        />
      </MaxWidthFormControl>

      <Title>Select Backup Provider</Title>
      <MaxWidthFormControl>
        <MultiSelectSearch
          name={FORM_FIELDS.BACKUP_PROVIDER}
          options={ovaBackupProviderOptions}
          placeholder="Select"
          disabled={false}
        />
      </MaxWidthFormControl>

      <Stack direction="row" spacing={2}>
        <Box flex={1}>
          <Title>Range of Data</Title>
          <TimeRangeDropdown
            name={FORM_FIELDS.TIME_RANGE}
            onChange={onReportPeriodChangeInner}
          />
        </Box>
        <Box flex={1}>
          <Title>Date Range</Title>
          <Controller
            name={FORM_FIELDS.START_AT}
            control={control}
            render={({ field }) => {
              const endValues = getValues(FORM_FIELDS.END_AT)
              const startAt =
                field.value instanceof Date
                  ? field.value
                  : new Date(field.value)
              const endAt =
                endValues instanceof Date ? endValues : new Date(endValues)

              return (
                <DateRangePicker
                  dateRange={[startAt.getTime(), endAt.getTime()]}
                  onDateChange={handleDateChange as any}
                />
              )
            }}
          />
        </Box>
      </Stack>
      <Title>Format:</Title>
      <ControlledFormatCheckboxGroup
        name={FORM_FIELDS.FORMATS}
        options={formats.map((format) => ({
          value: format,
          label: format,
        }))}
      />
    </>
  )
}

const formViews = {
  [FormVariant.AWS]: AWSForm,
  [FormVariant.BackupConnector]: BackupConnectorForm,
}

function OnDemandForm() {
  const { reportPeriod, setReportPeriod } = useReportPeriod()
  const { setIsFormValid, setFormData, setReportSourceType } = useTabsActions()

  const reportSourceType = useReportSourceType()

  const defaultReportFormats = reportOptions.find(
    (option) => option.value === reportSourceType
  )?.formats

  const methods = useForm<FormData>({
    // @ts-ignore
    defaultValues: getDefaultValues({
      reportType: reportSourceType as string,
      formats: defaultReportFormats as Array<string>,
    }),
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  })

  const selectedReportValue = methods.watch(FORM_FIELDS.REPORT_TYPE)
  const descriptionValue = methods.watch(FORM_FIELDS.DESCRIPTION)
  const reportsFormat = methods.watch(FORM_FIELDS.FORMATS)
  const startAt = methods.watch(FORM_FIELDS.START_AT)
  const endAt = methods.watch(FORM_FIELDS.END_AT)
  const dateRange = [startAt, endAt]

  const selectedReportOption = useMemo(
    () => reportOptions.find((option) => option.value === selectedReportValue),
    [selectedReportValue]
  )

  const selectedFormVariant = selectedReportOption?.type
  const availableFormats = selectedReportOption?.formats || []

  const SelectedFormComponent = selectedFormVariant
    ? formViews[selectedFormVariant]
    : null

  const { isValid } = methods.formState

  useEffect(() => {
    setReportSourceType(selectedReportValue)
  }, [selectedReportValue])

  useEffect(() => {
    methods.setValue(FORM_FIELDS.START_AT, reportPeriod.dateRange[0])
    methods.setValue(FORM_FIELDS.END_AT, reportPeriod.dateRange[1])
  }, [reportPeriod])

  useEffect(() => {
    const values = methods.getValues()
    let backupProvider
    let ovaAccounts
    setIsFormValid(isValid)
    const dateRangeTransformed = transformDateRangeForApi(
      dateRange as DateRangeTuple
    )
    if (isType2Schema(values)) {
      backupProvider = (values?.backupProvider || []).map(
        (provider) => provider.value
      )
      ovaAccounts = values.ovaAccounts?.map((account) => account.value) || []
    }

    const {
      // @ts-ignore
      // eslint-disable-next-line no-unused-vars
      description,
      ...valuesRest
    } = values
    if (isValid) {
      const formData = {
        ...valuesRest,
        ...{ description: descriptionValue },
        ...(backupProvider && { backupProvider }),
        ...(ovaAccounts && { ovaAccounts }),
        ...dateRangeTransformed,
      }
      setFormData(formData)
    }
  }, [isValid, dateRange, descriptionValue, reportsFormat])

  return (
    <FormProvider {...methods}>
      <Box>
        <Title>Report Type</Title>
        <MaxWidthFormControl>
          <ControlledSelect
            name={FORM_FIELDS.REPORT_TYPE as any}
            isLoading={false}
            errors={{}}
            options={reportOptions.map(({ label, value }) => ({
              label,
              value,
            }))}
            placeholder="Select a Report"
          />
        </MaxWidthFormControl>
        <Title>Description (Optional)</Title>
        <ControlledInput
          name={FORM_FIELDS.DESCRIPTION}
          placeholder="Description"
        />
        {SelectedFormComponent && (
          <SelectedFormComponent
            formats={availableFormats}
            handleDateChange={setReportPeriod as any}
            dateRange={reportPeriod as any}
          />
        )}
      </Box>
    </FormProvider>
  )
}

export default OnDemandForm
