import { Table } from '@components/table'
import { getSchedulesColumnDefinitions } from './column-definitions'
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect } from 'react'
import { useListReportSchedules } from './use-list-report-schedules'
import {
  TabName,
  useTabsActions,
  useReportData,
} from '@features/reports/schedules/use-data-store'

function SchedulesTable() {
  const { data, loading: isLoading } = useListReportSchedules()
  const { setReportDataLength, setTabData } = useTabsActions()

  const reportData = useReportData()

  useEffect(() => {
    setTabData(TabName.report, data)
    setReportDataLength(reportData.length)
  }, [data, reportData])

  return (
    <Table
      data={reportData}
      columns={getSchedulesColumnDefinitions()}
      data-testid="schedulesTable"
      generalProps={{
        noDataMessage: 'No reports found',
        isLoading,
      }}
    />
  )
}

export default SchedulesTable
