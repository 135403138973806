import PageMenuInterface from '@lib/interfaces/page-menu.interface'
import PagePathInterface from '@lib/interfaces/page-path.interface'
import MyAccountIcon from '@inline-img/general/nav-bar/my-account-icon'
import ContactUsIcon from '@inline-img/general/nav-bar/contact-us-icon'
import SettingsIcon from '@inline-img/left-side-menu/settings-icon'
import NotificationNavIcon from '@inline-img/general/nav-bar/notification-nav-icon'
import MenuDocsIcon from '@inline-img/general/nav-bar/docs-icon'
import { SettingsRoutes } from '@lib/router/routes/settings'
import { AccountAndSettingsRoutes } from '@lib/router/routes/account-and-settings/account-and-settings'
import { OnboardingRoutes } from '@lib/router/routes/onboarding/onboarding'
import { DashboardRoutes } from '@lib/router/routes/dashboard/dashboard'
import { SourcesRoutes } from '@lib/router/routes/sources/sources'
import { PoliciesRoutes } from '@lib/router/routes/policies/policies'
import { JobsRoutes } from '@lib/router/routes/jobs/jobs'
import { ReportsRoutes } from '@lib/router/routes/reports/reports'
import PagePathConstant from '@lib/constants/page-path.constant'
import { AlertsRoutes } from '@lib/router/routes/alerts/alerts'

// This menu is deprecated, probably we should create the task to clean up it
export const RIGHT_SUBMENU = (): Array<PagePathInterface> => [
  {
    name: 'Account and Settings',
    path: AccountAndSettingsRoutes.root,
  },
  {
    name: 'Contact Us',
    path: PagePathConstant.CONTACT_US,
  },
]

export const DEMO_RIGHT_SUBMENU = (): Array<PagePathInterface> => [
  {
    name: 'Account and Settings',
    path: AccountAndSettingsRoutes.root,
  },
  {
    name: 'Contact Us',
    path: PagePathConstant.CONTACT_US,
  },
]

// ____________________________________________________________________________________
// TOP NAVIGATION BAR MENU

export const USER_MENU = (): Array<PagePathInterface> => [
  {
    name: 'My account',
    path: AccountAndSettingsRoutes.root,
    icon: MyAccountIcon(),
    class: 'jsAccountAndSettings',
  },
  {
    name: 'Contact us',
    path: PagePathConstant.CONTACT_US,
    icon: ContactUsIcon(),
    openInNewTab: true,
  },
  {
    name: 'Documentation',
    path: PagePathConstant.ELASTIO_DOCS,
    icon: MenuDocsIcon(),
    openInNewTab: true,
  },
]

export const USER_DEMO_MENU = (): Array<PagePathInterface> => [
  {
    name: 'My account',
    path: AccountAndSettingsRoutes.root,
    icon: MyAccountIcon(),
  },
  {
    name: 'Contact us',
    path: PagePathConstant.CONTACT_US,
    icon: ContactUsIcon(),
    openInNewTab: true,
  },
  {
    name: 'Documentation',
    path: PagePathConstant.ELASTIO_DOCS,
    icon: MenuDocsIcon(),
    openInNewTab: true,
  },
]

export const SETTING_MENU = (): Array<PagePathInterface> => [
  {
    name: 'Tenant Settings',
    path: SettingsRoutes.root,
    icon: SettingsIcon(),
  },
  {
    name: 'Alerts',
    path: AlertsRoutes.root,
    icon: NotificationNavIcon(),
  },
]

export const NO_CLOUD_CONNECTORS_MAIN_MENU = (): Array<PageMenuInterface> => [
  {
    page: {
      name: 'Onboarding',
      path: OnboardingRoutes.root,
    },
    subPages: [],
  },
]

export const MAIN_MENU = (): Array<PageMenuInterface> => [
  {
    page: {
      name: 'Onboarding',
      path: OnboardingRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Dashboard',
      path: DashboardRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Sources',
      path: SourcesRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Policies',
      path: PoliciesRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Assets',
      path: '',
    },
    subPages: [
      {
        name: 'EC2',
        path: DashboardRoutes.ec2Table,
      },
      {
        name: 'EBS',
        path: DashboardRoutes.ebsTable,
      },
      {
        name: 'S3',
        path: DashboardRoutes.s3Table,
      },
      {
        name: 'EFS',
        path: DashboardRoutes.efsTable,
      },
      {
        name: 'Servers',
        path: DashboardRoutes.ovaServerTable,
      },
      {
        name: 'Generic Host',
        path: DashboardRoutes.genericHostsTable,
      },
    ],
  },
  {
    page: {
      name: 'Jobs',
      path: '',
    },
    subPages: [
      {
        name: 'All',
        path: JobsRoutes.cloudConnector,
        hideInSubMenu: true,
      },
      {
        name: 'Cloud Connector',
        path: JobsRoutes.cloudConnector,
      },
      {
        name: 'Deployment',
        path: JobsRoutes.deployment,
      },
      {
        name: 'System',
        path: JobsRoutes.system,
      },
    ],
  },
  {
    page: {
      name: 'Reports',
      path: ReportsRoutes.root,
    },
    subPages: [],
  },
  // {
  //   page: {
  //     name: 'Alerts',
  //     path: AlertsRoutes.root,
  //   },
  //   subPages: [],
  // },
]

export const MAIN_DEMO_MENU = (): Array<PageMenuInterface> => [
  {
    page: {
      name: 'Onboarding',
      path: OnboardingRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Dashboard',
      path: DashboardRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Sources',
      path: SourcesRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Policies',
      path: PoliciesRoutes.root,
    },
    subPages: [],
  },
  {
    page: {
      name: 'Assets',
      path: '',
    },
    subPages: [
      {
        name: 'EC2',
        path: DashboardRoutes.ec2Table,
      },
      {
        name: 'EBS',
        path: DashboardRoutes.ebsTable,
      },
      {
        name: 'S3',
        path: DashboardRoutes.s3Table,
      },
      {
        name: 'EFS',
        path: DashboardRoutes.ebsTable,
      },
      {
        name: 'Servers',
        path: DashboardRoutes.ovaServerTable,
      },
      {
        name: 'Generic Host',
        path: DashboardRoutes.genericHostsTable,
      },
    ],
  },
  {
    page: {
      name: 'Jobs',
      path: '',
    },
    subPages: [
      {
        name: 'Cloud Connector',
        path: JobsRoutes.cloudConnector,
      },
      {
        name: 'Deployment',
        path: JobsRoutes.deployment,
      },
      {
        name: 'System',
        path: JobsRoutes.system,
      },
    ],
  },
  {
    page: {
      name: 'Reports',
      path: ReportsRoutes.root,
    },
    subPages: [],
  },
  // {
  //   page: {
  //     name: 'Alerts',
  //     path: AlertsRoutes.root,
  //   },
  //   subPages: [],
  // },
]
