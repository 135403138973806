import React, { ReactNode } from 'react'
import { Typography } from '@mui/material'

interface TitleOwnProps {
  children: ReactNode
  disabled?: boolean
}

function SuppressUntilTitle({ children, disabled = false }: TitleOwnProps) {
  return (
    <Typography
      variant="body1"
      marginY="8px"
      fontSize="14px"
      fontWeight="400"
      color={disabled ? 'text.disabled' : 'text.primary'}
      sx={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'inherit',
      }}
    >
      {children}
    </Typography>
  )
}

export default SuppressUntilTitle
