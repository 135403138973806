import React, { memo } from 'react'
import Box from '@mui/material/Box'
import { SvgIconTypeMap } from '@mui/material'
import { TabViewInterface } from '..'
import ContextMenu from './context-menu'
import UpdateIcon from '@inline-img/general/menu/update-icon'
import DeleteIcon from '@inline-img/general/menu/delete-icon'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { OverridableComponent } from '@mui/material/OverridableComponent'

interface Props {
  tab: TabViewInterface
  selected: boolean
  editable: boolean
  onViewUpdate: (id: string) => void
  onViewDelete: (id: string) => void
  TitleIcon?: OverridableComponent<SvgIconTypeMap>
}

const renderContextMenuOption = ({
  icon,
  label,
  onOptionClick,
}: {
  icon: React.ReactNode
  label: React.ReactNode
  onOptionClick?: () => void
}) => {
  const menuId = label?.toString().toLocaleLowerCase()
  return {
    text: (
      <Box
        display="flex"
        alignItems="center"
        gap="10px"
        data-testid={`menu-item-${menuId}`}
      >
        {icon} {label}
      </Box>
    ),
    onOptionClick,
  }
}

function TabLabel({
  tab,
  selected,
  editable,
  onViewUpdate,
  onViewDelete,
  TitleIcon,
}: Props) {
  return (
    <Box className="flexRowCenter">
      <ContextMenu
        TitleIcon={TitleIcon}
        shouldShow={selected && editable}
        title={<LongTextTooltip text={tab.name} maxLength={20} />}
        options={[
          renderContextMenuOption({
            icon: <UpdateIcon />,
            label: 'Update',
            onOptionClick: () => onViewUpdate(tab.id),
          }),
          renderContextMenuOption({
            icon: <DeleteIcon />,
            label: 'Remove',
            onOptionClick: () => onViewDelete(tab.id),
          }),
        ]}
      />
    </Box>
  )
}

export default memo(TabLabel)
