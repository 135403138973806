import React, { memo, useEffect, useMemo, useState } from 'react'
import FormHelper from '@lib/helpers/form.helper'
import buildDefaultFormData from './data'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import CustomTextFieldV2 from '@forms/fields/custom-text-field-v2'
import SelectField from '@forms/fields/select-field'
import PasswordField from '@forms/fields/password-field'
import TestWebhookButton from '@components-composite/test-webhook-button/TestWebhookButton'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import { Button, Select } from '@mui/material'
import ControlledVITable from '@tables/ControlledVITable'
import { WEBHOOK_SELECTED_EVENTS_HEAD } from '@tables/core/table-constants'
import TableFactory from '@lib/factories/table.factory'
import { WEBHOOK_EVENTS_MAPPER } from '@tables/core/table-vi-draw-mappers'
import { EngineCallback, EngineCallbackPure, VIRow } from '@lib/engine-types'
import { EmptyFunc } from '@lib/constants/app.constant'
import {
  AuthorizationOptions,
  Severity,
} from '@lib/constants/notifications.constant'
import { WebhookValueInterface } from '@lib/interfaces/notifications.interface'
import WebhookModel from '@lib/models/webhook.model'
import ObjHelper from '@lib/helpers/obj.helper'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { useSelector } from 'react-redux'
import {
  getEventTypes,
  getWebhooksList,
} from '@store/selectors/webhook.selector'
import MenuItem from '@mui/material/MenuItem'
import clsx from 'clsx'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'

enum FormSteps {
  INFORMATION,
  EVENTS,
}

interface Props {
  onSubmit: (form: any) => void
  loading: boolean
  tableData: Array<WebhookValueInterface>
  setTableData: React.Dispatch<
    React.SetStateAction<Array<WebhookValueInterface>>
  >
  onCancel: EngineCallbackPure
  selectedWebhook?: WebhookModel
  onTestWebhook: any
  testWebhookSuccess?: boolean
}

const errorGroups = [ErrorGroupConstants.WEBHOOK]

function AddWebhookForm({
  onSubmit,
  loading,
  setTableData,
  tableData,
  onCancel,
  selectedWebhook,
  onTestWebhook,
  testWebhookSuccess,
}: Props) {
  const eventTypes = useSelector(getEventTypes)
  const webhooks = useSelector(getWebhooksList)

  const isTestingWebhook = usePreloader(PreloaderConstants.TEST_WEBHOOK)
  const [currentStep, setCurrentStep] = useState(FormSteps.INFORMATION)
  const [selectedSeverities, setSelectedSeverities] = useState<VIRow>()
  const isOnlyForAddEvents = useMemo(
    () => ObjHelper.isNotEmpty(selectedWebhook),
    [selectedWebhook]
  )

  const currentForm = useMemo(
    () =>
      FormHelper.fillDefaultValues(buildDefaultFormData(eventTypes, webhooks)),
    [eventTypes.length, tableData.length]
  )

  const eventTypesOptions = useMemo(
    () =>
      currentForm.eventType.options?.filter(
        (event) => !tableData.some((i) => i.eventType === event.value)
      ),
    [tableData, currentForm.eventType.options]
  )

  const eventTypeOptionsFirstValue = useMemo(
    () => eventTypesOptions?.[0]?.value,
    [eventTypesOptions, tableData.length]
  )

  const [selectedEventType, setSelectedEventType] = useState(
    eventTypeOptionsFirstValue
  )

  const {
    isFormDisabled,
    submit,
    formState,
    register,
    control,
    watch,
    setValue,
    clearErrors,
    getValues,
    trigger,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading, false, 'all')

  FormHelper.fillErrors(formState.errors, currentForm)

  const selectedAuthorizationValue = watch(currentForm.authorization.name)

  const setNextStep = () => {
    if (currentStep === FormSteps.EVENTS) {
      submit(currentForm)
      onCancel()
    } else {
      setCurrentStep(FormSteps.EVENTS)
    }
  }

  const setPreviousStep = () => {
    if (isOnlyForAddEvents) {
      return
    }

    if (currentStep === FormSteps.EVENTS) {
      setCurrentStep(FormSteps.INFORMATION)
    } else {
      onCancel()
    }
  }

  const onAddEvent = (data: WebhookValueInterface) => {
    setTableData([...tableData, data])
  }

  const requiredFields = ['name', 'endpoint']

  const requiredValues = Object.values(currentForm)
    .filter((property) => requiredFields.includes(property.name as string))
    .map((property) => ({
      value: watch(property.name)?.trim() || '',
      errors: property.hasError,
    }))

  const isSubmitDisabled =
    isFormDisabled || (currentStep === FormSteps.EVENTS && !tableData.length)

  const isRequiredFieldsEmpty = !requiredValues.every(
    ({ value, errors }) => value !== '' && !errors
  )

  const isNextStepDisabled =
    isFormDisabled ||
    (currentStep === FormSteps.INFORMATION &&
      Object.values(currentForm).some(
        (property) => property.hasError !== false
      )) ||
    isRequiredFieldsEmpty

  const renderAuthBlock = () => {
    switch (selectedAuthorizationValue) {
      case AuthorizationOptions.BASIC:
        return (
          <div className="login-and-password">
            <div className="login reset-select-style">
              <p id="option-title">Login</p>
              <CustomTextFieldV2
                disabled={isFormDisabled}
                field={currentForm.login}
                {...register(
                  currentForm.login.name,
                  currentForm.login.validationRules
                )}
              />
            </div>
            <div className="password reset-select-style">
              <p id="option-title">Password</p>
              <PasswordField
                disabled={isFormDisabled}
                field={currentForm.password}
                {...register(
                  currentForm.password.name,
                  currentForm.password.validationRules
                )}
              />
            </div>
          </div>
        )
      case AuthorizationOptions.BEARER:
        return (
          <div className="login-and-password">
            <div className="reset-select-style formFullBasis">
              <p id="option-title">Token</p>
              <PasswordField
                disabled={isFormDisabled}
                field={currentForm.token}
                {...register(
                  currentForm.token.name,
                  currentForm.token.validationRules
                )}
              />
            </div>
          </div>
        )

      case AuthorizationOptions.API_KEY:
        return (
          <div className="login-and-password">
            <div className="login reset-select-style">
              <p id="option-title">Key</p>
              <CustomTextFieldV2
                disabled={isFormDisabled}
                field={currentForm.apiKey}
                {...register(
                  currentForm.apiKey.name,
                  currentForm.apiKey.validationRules
                )}
              />
            </div>
            <div className="password reset-select-style">
              <p id="option-title">Value</p>
              <PasswordField
                disabled={isFormDisabled}
                field={currentForm.apiValue}
                {...register(
                  currentForm.apiValue.name,
                  currentForm.apiValue.validationRules
                )}
              />
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const renderInformationBlock = () => {
    return (
      <>
        <p id="basic-details">Basic details</p>
        <div className="webhook-name reset-select-style">
          <p id="option-title">Webhook name</p>
          <CustomTextFieldV2
            disabled={isFormDisabled}
            field={currentForm.name}
            className="webhook-name-input"
            {...register(
              currentForm.name.name,
              currentForm.name.validationRules
            )}
          />
        </div>
        <div className="endpoint-and-auth">
          <div className="endpoint-url reset-select-style">
            <p id="option-title">Endpoint URL</p>
            <CustomTextFieldV2
              disabled={isFormDisabled}
              field={currentForm.endpoint}
              className="webhook-url-input"
              startAdorment={<span>https://</span>}
              {...register(
                currentForm.endpoint.name,
                currentForm.endpoint.validationRules
              )}
              onPaste={(e) => {
                e.preventDefault()
                const value = e.clipboardData.getData('text').trim()

                if (value.startsWith('https://')) {
                  const newValue = value.substring(8)
                  setValue(currentForm.endpoint.name, newValue)

                  trigger(currentForm.endpoint.name)
                  return
                }
                setValue(currentForm.endpoint.name, value)
                trigger(currentForm.endpoint.name)
              }}
            />
          </div>
          <div className="authentication-option reset-select-style">
            <p id="option-title">Authentication option</p>
            <SelectField
              field={currentForm.authorization}
              disabled={isFormDisabled}
              variant="standard"
              onChange={(e) => {
                clearErrors([
                  currentForm.apiKey.name,
                  currentForm.apiValue.name,
                  currentForm.login.name,
                  currentForm.password.name,
                  currentForm.token.name,
                ])

                setValue(currentForm.authorization.name, e.target.value)
              }}
              iconComponent={SelectArrowIcon}
            />
          </div>
        </div>
        {renderAuthBlock()}

        <div className="description reset-select-style">
          <p id="option-title">Description</p>
          <CustomTextFieldV2
            disabled={isFormDisabled}
            field={currentForm.description}
            className="webhook-description-input"
            {...register(
              currentForm.description.name,
              currentForm.description.validationRules
            )}
          />
        </div>
        <TestWebhookButton
          className="test-webhook-btn"
          text="Test webhook"
          disabled={isRequiredFieldsEmpty || isTestingWebhook}
          onClick={() =>
            onTestWebhook({
              name: watch(currentForm.name.name),
              endpoint: watch(currentForm.endpoint.name),
              authorization: watch(currentForm.authorization.name),
              login: watch(currentForm.login.name),
              password: watch(currentForm.password.name),
              token: watch(currentForm.token.name),
              apiKey: watch(currentForm.apiKey.name),
              apiValue: watch(currentForm.apiValue.name),
            })
          }
        />
        <p
          className={clsx('test-webhook-result', {
            success: testWebhookSuccess,
            failed: !testWebhookSuccess,
          })}
        >
          {typeof testWebhookSuccess === 'boolean' &&
            (testWebhookSuccess
              ? 'Webhook test is successful.'
              : 'Webhook test failed.')}
        </p>
      </>
    )
  }

  const onRemoveEventClick = (id: number) => {
    setTableData(tableData.filter((i) => i.id !== id))
  }

  const VersionOption = ({
    label,
    field,
  }: {
    label: string
    field: FormFieldInterface
  }) => (
    <div className="version-option reset-select-style">
      <p id="option-title">{label}</p>
      <SelectField
        field={field}
        disabled
        control={control}
        variant="standard"
        iconComponent={SelectArrowIcon}
      />
    </div>
  )

  const AddOptionButton = ({
    disabled,
    onClick,
  }: {
    disabled: boolean
    onClick: EngineCallback<any>
  }) => (
    <Button className="add-option" disabled={disabled} onClick={onClick}>
      Add
    </Button>
  )

  const SelectedEventsTable = ({
    data,
    onRemoveEventClickFunc,
  }: {
    data: Array<WebhookValueInterface>
    onRemoveEventClickFunc: EngineCallback<number>
  }) => (
    <div className="table">
      <ControlledVITable
        onMenuClick={EmptyFunc}
        head={WEBHOOK_SELECTED_EVENTS_HEAD}
        rows={TableFactory.webhooksEvents(data)}
        className={
          'tableVIUncontrolled tableUpgrade striped shrinkTableColumn8 add-webhook-table'
        }
        columnDrawMapper={WEBHOOK_EVENTS_MAPPER(onRemoveEventClickFunc)}
      />
    </div>
  )

  const renderEventsBlock = () => {
    const { eventType, version, severity } = currentForm

    return (
      <>
        <p id="basic-details">Select events</p>
        <div className="event-option reset-select-style">
          <p id="option-title">Event Type</p>
          <Select
            value={selectedEventType}
            fullWidth
            disabled={isFormDisabled || !eventTypesOptions?.length}
            // @ts-ignore: TODO - Fix TypeScript issue: "placeholder" is not assignable to type SelectProps<string[]>.
            placeholder="Select event type"
            className={clsx('webhook-event-type', {
              disabled: isFormDisabled || !eventTypesOptions?.length,
            })}
            onChange={(e) => {
              setValue(eventType.name, e.target.value)
              setSelectedEventType(e.target.value)
            }}
          >
            {!!eventTypesOptions?.length &&
              eventTypesOptions?.map((option) => (
                <MenuItem
                  key={String(option?.value)}
                  value={String(option?.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </div>

        <div className="version-and-severity">
          <VersionOption label="Version" field={version} />
          <div className="severity-option reset-select-style">
            <p id="option-title">Severities</p>
            <ViMultipleSelect
              className="severity-select"
              onChange={(v) => setSelectedSeverities(v)}
              possible={severity.options ?? []}
              selected={selectedSeverities}
              placeholder={severity.label}
              key={severity.label}
              withSearch={false}
              disabled={!eventTypesOptions?.length}
            />
          </div>
        </div>
        <AddOptionButton
          onClick={() => {
            const values = getValues()
            setSelectedEventType(eventTypeOptionsFirstValue ?? 'vault')
            onAddEvent({
              id: Date.now(),
              name: Date.now().toString(),
              eventType: values.eventType,
              version: values.version,
              severities: (selectedSeverities?.map((i) => i.value) ??
                []) as Array<Severity>,
            })
            setSelectedSeverities([])
          }}
          disabled={!selectedSeverities?.length}
        />
        {!!tableData.length && (
          <SelectedEventsTable
            data={tableData}
            onRemoveEventClickFunc={onRemoveEventClick}
          />
        )}
      </>
    )
  }

  const isTableDataItemsEqualToSelectedWebhookEvents = (): boolean => {
    if (!selectedWebhook) {
      return false
    }

    const selectedWebhookEvents = selectedWebhook.getEventSubscriptions()

    return (
      tableData.length === selectedWebhookEvents.length &&
      tableData.every((item) => {
        const selectedWebhookEvent = selectedWebhookEvents.find(
          (event) => event.getEventType() === item.eventType
        )

        return selectedWebhookEvent
      })
    )
  }

  useEffect(() => {
    if (selectedWebhook && ObjHelper.isNotEmpty(selectedWebhook)) {
      setCurrentStep(FormSteps.EVENTS)
      setValue(currentForm.name.name, selectedWebhook.getName())
      setValue(currentForm.endpoint.name, selectedWebhook.getEndpoint())
      setValue(
        currentForm.authorization.name,
        selectedWebhook.getAuthentication().getAuthTypeToString()
      )
      setValue(currentForm.description.name, selectedWebhook.getDescription())
      setTableData(
        // @ts-ignore TODO: fix types
        selectedWebhook.getEventSubscriptions().map((event) => ({
          severities: event.getSeverities(),
          eventType: event.getEventType(),
          version: event.getVersion(),
        }))
      )
    }
  }, [selectedWebhook])

  useEffect(() => {
    setSelectedEventType(eventTypeOptionsFirstValue)
    setValue(currentForm.eventType.name, eventTypeOptionsFirstValue)
  }, [tableData.length])

  const getFooterButtons = () => {
    const cancelButtonText =
      currentStep === FormSteps.INFORMATION ? 'Cancel' : 'Back'
    const nextButtonText =
      currentStep === FormSteps.INFORMATION
        ? 'Next: Select Events'
        : isOnlyForAddEvents
          ? 'Add Events'
          : 'Add Webhook'

    return (
      <>
        <Button
          className="showButton sizeS jsCancel"
          type="button"
          variant="text"
          color="primary"
          onClick={setPreviousStep}
          disabled={isOnlyForAddEvents}
        >
          {cancelButtonText}
        </Button>

        <Button
          className="sizeS webhook-next-button"
          type="button"
          variant="contained"
          onClick={setNextStep}
          disabled={
            isNextStepDisabled ||
            isSubmitDisabled ||
            isTestingWebhook ||
            isTableDataItemsEqualToSelectedWebhookEvents()
          }
        >
          {nextButtonText}
        </Button>
      </>
    )
  }

  return (
    <form className="wrap-1676290482617">
      {currentStep === FormSteps.INFORMATION && renderInformationBlock()}
      {currentStep === FormSteps.EVENTS && renderEventsBlock()}
      <div className="modalFooter">{getFooterButtons()}</div>
    </form>
  )
}

export default memo(AddWebhookForm)
