import { TextConstant } from '@lib/constants'
import InfoHeaderButtonsRowElement from '@lib/interfaces/info-header-buttons-row-element.interface'
import { getButtonsPopoverContent } from '../utilities'

const DOCS_LINK = 'https://docs.elastio.com/docs/cli/installation'

const onInstallCliClick = () => {
  window.open(DOCS_LINK, '_blank')
}

export const MOUNT_DESCRIPTION_PART_1 =
  'To maintain security of your data, the mount must be performed from compute within the AWS account.'

export const MOUNT_DESCRIPTION_PART_2 = 'on how to mount.'

export const INFO_HEADER_BUTTONS: Array<InfoHeaderButtonsRowElement> = [
  {
    onClick: onInstallCliClick,
    isDisabled: false,
    label: 'Install CLI',
    type: 'default',
  },
]

export const TEXT_POPOVER_PROPS = {
  closable: true,
  buttonText: 'Click for more details',
  buttonClassName: 'linkWithPopover',
  wrapperClassName: 'inlineButtonPopover',
  popoverClassName: 'inlineButtonPopoverContent',
  textForPopover: getButtonsPopoverContent({
    header: 'How to mount',
    message: TextConstant.MOUNT_DESCRIPTION,
  }),
} as const
