/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, EBS, EC2 } from 'blues-corejs/dist'
import {
  CellWithDateTimeTooltip,
  createColumn,
  createDisplayColumn,
} from '@components/table'
import { AssetNameCell } from './cells'
import { LongTextTooltip } from '@components/components-simple'

export interface DetectedAnomalyTable {
  path: string
  assetId: string
  detectedAt: Date
  numberOfFindings: number
}

function getAssetFromId(assetId: string, assetList: Array<Asset>) {
  return assetList.find(({ id }) => id === assetId)
}

function getAttachedAssets(asset: Asset | undefined, assetList: Array<Asset>) {
  if (asset instanceof EBS) {
    const attachedInstancesIds = asset.attachedInstanceIds
    return attachedInstancesIds.map((id) => {
      return getAssetFromId(id, assetList)
    })
  }

  if (asset instanceof EC2) {
    const attachedEBSIds = asset.ebsIds
    return attachedEBSIds.map((id) => {
      return getAssetFromId(id, assetList)
    })
  }

  return []
}

const columnsSize = {
  date: '86',
  numberOfFindings: '170',
}

export function getEntropyTrendsColumnsDefinition(assetList: Array<Asset>) {
  return [
    createColumn<DetectedAnomalyTable>('path', {
      header: 'Directory',
      enableSorting: false,
      meta: {
        whiteSpace: 'pre-line',
        workBreak: 'break-word',
      },
      cell: ({ getValue }) => (
        <LongTextTooltip
          sx={{
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}
          text={getValue()}
        />
      ),
    }),
    createColumn<DetectedAnomalyTable>('assetId', {
      header: 'Asset',
      enableSorting: false,
      cell: ({ getValue }) => {
        const assetId = getValue() as string

        const asset = getAssetFromId(assetId, assetList)

        if (!asset) {
          return null
        }

        return <AssetNameCell asset={asset} />
      },
    }),

    createDisplayColumn<DetectedAnomalyTable>({
      id: 'attachedAsset',
      header: 'Attached Asset',
      enableSorting: false,

      cell: ({ row: { original } }) => {
        const { assetId } = original
        const asset = getAssetFromId(assetId, assetList)
        const attachedAssets = getAttachedAssets(asset, assetList)
        return attachedAssets.map((attachedAsset) => {
          if (!attachedAsset) {
            return null
          }

          return <AssetNameCell asset={attachedAsset} />
        })
      },
    }),
    createColumn<DetectedAnomalyTable>('detectedAt', {
      header: 'Detected',
      enableSorting: false,
      meta: {
        width: columnsSize.date,
      },
      cell: ({ getValue }) => (
        <CellWithDateTimeTooltip date={getValue() as unknown as Date} />
      ),
    }),
    createColumn<DetectedAnomalyTable>('numberOfFindings', {
      header: 'Files with high entropy',
      enableSorting: false,
      meta: {
        textAlign: 'right',
        width: columnsSize.numberOfFindings,
      },
    }),
  ]
}
