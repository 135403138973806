import { Column } from 'ui-v2/src/components/ui/data-display/data-table'
import { RetentionPolicyListTableRow } from 'ui-v2/src/lib/models/settings/retention-policy/retention-policy.model'
import RetentionPolicyAction from './retention-policy-action'

export const getRetentionPolicyListColumns = (): Array<
  Column<RetentionPolicyListTableRow>
> => [
  {
    key: 'policyName',
    header: 'Name',
    render: (row) => row.policyName,
  },
  {
    key: 'status',
    header: 'Status',
    render: (row) => row.status,
  },
  {
    key: 'retention',
    header: 'Retention',
    render: (row) => row.retention,
  },
  {
    key: 'action',
    header: '',
    render: (row) => <RetentionPolicyAction row={row} />,
  },
]
