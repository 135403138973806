import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  PaperProps,
  styled,
} from '@mui/material'
import React from 'react'

const PAPER_STYLES = {
  position: 'relative',
  maxHeight: '615px',
  maxWidth: '850px',
} as const

export function DialogPaper(props: PaperProps) {
  return <Paper {...props} style={PAPER_STYLES} />
}

export const StyledDialogTitle = styled(DialogTitle)({
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '140%',
  color: '#171717',
})

export const StyledDialogContent = styled(DialogContent)({
  display: 'flex',
  padding: '0 !important',
  overflow: 'hidden',
})

export const StyledDialogActions = styled(DialogActions)({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '60px',
  padding: '10px 30px',
  boxShadow: '0 -4px 7px rgba(0,0,0,.08)',
})
