/* eslint-disable import/no-extraneous-dependencies */
import { AssetByThreat } from 'ui-v2/src/lib/models/assets/asset-items'
import {
  Asset,
  EBS,
  EC2,
  EFS,
  GenericHost,
  OvaServer,
  S3Bucket,
  Threat,
} from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

interface InitialSortParams {
  threats: Array<Threat>
  assetByThreat: AssetByThreat
}

export function initialPrioritySortForThreats({
  threats,
  assetByThreat,
}: InitialSortParams) {
  return threats.sort((threatA, threatB) => {
    const assetA = assetByThreat.get(threatA)
    const assetB = assetByThreat.get(threatB)
    const aThreatDetectedAt = threatA.firstDetectedAt.getTime()
    const bThreatDetectedAt = threatB.firstDetectedAt.getTime()

    if (aThreatDetectedAt !== bThreatDetectedAt) {
      return bThreatDetectedAt - aThreatDetectedAt
    }

    if (assetA instanceof AWSAsset && assetB instanceof AWSAsset) {
      return (assetA.name || assetA.awsId).localeCompare(
        assetB.name || assetB.awsId
      )
    }

    if (assetA instanceof GenericHost && assetB instanceof GenericHost) {
      return assetA.hostname.localeCompare(assetB.hostname)
    }

    return threatA.location.localeCompare(threatB.location)
  })
}

export const assetFilterCriteria = (asset: Asset) =>
  asset instanceof AWSAsset || asset instanceof GenericHost

export const getAssetName = (asset?: Asset) => {
  if (
    asset instanceof EC2 ||
    asset instanceof EBS ||
    asset instanceof S3Bucket ||
    asset instanceof EFS
  ) {
    return asset.awsId
  } else if (asset instanceof OvaServer) {
    return asset.ccAssetId
  } else if (asset instanceof GenericHost) {
    return asset.hostname
  } else {
    return ''
  }
}

export const getAssetType = (asset?: Asset) => {
  if (asset instanceof EC2) {
    return 'EC2 Instance'
  } else if (asset instanceof EBS) {
    return 'EBS Volume'
  } else if (asset instanceof S3Bucket) {
    return 'S3 Bucket'
  } else if (asset instanceof EFS) {
    return 'EFS Filesystem'
  } else if (asset instanceof OvaServer) {
    return 'Server'
  } else if (asset instanceof GenericHost) {
    return 'Generic Host'
  } else {
    return ''
  }
}
