import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Container } from '../styles'
import OverviewRightColumn from './overview-right-column'
import OverviewLeftColumn from './overview-left-column'
import { OverviewWithEntropyTrends } from './overview-with-entropy-trends'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'

function Overview() {
  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()

  if (isEntropyEnabled) {
    return <OverviewWithEntropyTrends />
  }

  return (
    <Container>
      <Box className="left-column">
        <OverviewLeftColumn />
      </Box>
      <Box className="right-column">
        <OverviewRightColumn />
      </Box>
    </Container>
  )
}

export default memo(Overview)
