import BaseModal from '@components-composite/modals/BaseModal'
import { useConfirmDeleteStore } from '@features/alerts/create-rule-drawer/confirm-delete-modal/use-confirm-delete-modal'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

function ConfirmDeleteModal() {
  const { isOpen, setOpen, modalText, confirmCallback } =
    useConfirmDeleteStore()

  const handleCloseModal = () => {
    setOpen({
      isOpen: false,
      confirmCallback: () => {},
      modalText: '',
    })
  }

  const handleDelete = async () => {
    confirmCallback()
    setOpen({
      isOpen: false,
      confirmCallback: () => {},
      modalText: '',
    })
  }

  return (
    <BaseModal
      onBgClick={handleCloseModal}
      show={isOpen}
      customStyles={{ zIndex: 1500 }}
    >
      <Box style={{ position: 'relative' }}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>{modalText}</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleDelete}
            type="button"
          >
            Yes, Proceed
          </Button>
          <Button variant="text" onClick={handleCloseModal} type="button">
            No, Cancel
          </Button>
        </DialogActions>
      </Box>
    </BaseModal>
  )
}

export default ConfirmDeleteModal
