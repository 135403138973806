/* eslint-disable import/no-extraneous-dependencies */
import { Asset, AWSBRecoveryPoint } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { RecoveryPointStatus } from 'blues-corejs/dist/models/backups/aws/awsb/types'
import React from 'react'
import { SoftDeletedState } from '@features/asset-page/modals/recovery-point-details/components'
import { HardDeletedState } from '@features/asset-page/modals/recovery-point-details/components/hard-deleted-state/hard-deleted-state'
import { ReplicatedState } from '@features/asset-page/modals/recovery-point-details/components/replicated-state'

function getIsRecoveryPointReplicated(backup: AWSBRecoveryPoint, asset: Asset) {
  if (!(asset instanceof AWSAsset)) {
    return false
  }

  return backup.accountId !== asset.awsAccountId
}

export function computeAwsRecoveryPointState(
  backup: AWSBRecoveryPoint,
  asset: Asset
) {
  const isRecoveryPointReplicated = getIsRecoveryPointReplicated(backup, asset)

  if (isRecoveryPointReplicated) {
    return <ReplicatedState />
  }

  //  RecoveryPointStatus.DELETING equals soft-delete
  if (backup.status === RecoveryPointStatus.DELETING) {
    return <SoftDeletedState text="Deleting" />
  }

  if (backup.status === RecoveryPointStatus.DELETED) {
    return <HardDeletedState />
  }

  return null
}
