import React, { FunctionComponent } from 'react'
import { VIMatrix, VINumberMap, VIRow } from '@lib/engine-types'
import SimpleVIHead from '@tables/head-vi/SimpleVIHead'
import SimpleVIRow from '@tables/rows-vi/SimpleVIRow'

interface HeadComponentData {
  data: VIRow
}

interface RowComponentData {
  data: VIRow
  columnDrawMapper?: VINumberMap
}

interface Props {
  head: VIRow
  rows: VIMatrix
  className?: string
  headComponent?: FunctionComponent<HeadComponentData>
  rowComponent?: FunctionComponent<RowComponentData>
  columnDrawMapper?: VINumberMap
}

function SimpleVITable({
  head,
  rows,
  className = '',
  headComponent = SimpleVIHead,
  rowComponent = SimpleVIRow,
  columnDrawMapper = {},
}: Props) {
  const HeadComponent = headComponent
  const RowComponent = rowComponent
  return (
    <table className={`wrap-1602747175897 ${className} jsSimpleVITable`}>
      {head.length > 0 && (
        <thead>
          <HeadComponent data={head} />
        </thead>
      )}
      <tbody>
        {rows.map((v: VIRow, index: number) => (
          <RowComponent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data={v}
            columnDrawMapper={columnDrawMapper}
          />
        ))}
      </tbody>
    </table>
  )
}

export default SimpleVITable
