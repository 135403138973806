import { create } from 'zustand'

type OpenType = {
  isOpen: boolean
  modalText: string
  confirmCallback: () => void
}

type ConfirmDeleteStore = {
  isOpen: boolean
  modalText: string
  confirmCallback: () => void
  setOpen: (props: OpenType) => void
}

const INITIAL_STATE = {
  isOpen: false,
  modalText: '',
  confirmCallback: () => {},
}

export const useConfirmDeleteStore = create<ConfirmDeleteStore>((set) => ({
  ...INITIAL_STATE,
  setOpen: ({ isOpen, confirmCallback, modalText }) =>
    set((state) => ({
      ...state,
      isOpen,
      modalText,
      confirmCallback,
    })),
}))
