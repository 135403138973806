interface ScanAttrs {
  ransomwareScan?: RansomwareScan
}

export class Scan {
  #ransomwareScan?: RansomwareScan

  constructor(attrs: ScanAttrs) {
    this.#ransomwareScan = attrs.ransomwareScan
  }

  get ransomwareScanId() {
    return this.#ransomwareScan?.id
  }
}

export class RansomwareScan {
  #id?: string

  constructor(id?: string) {
    this.#id = id
  }

  get id(): string | undefined {
    return this.#id
  }
}
