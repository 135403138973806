import { Box, Button, Card, Slider, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { ResilienceWeightData } from 'ui-v2/src/views/dashboard/dashboard/radar-chart-card'

const ScoreField = ({
  name,
  label,
  control,
}: {
  name: keyof ResilienceWeightData
  label: string
  control: any
}) => (
  <Card
    elevation={0}
    sx={(theme) => ({
      paddingX: 2.5,
      paddingY: 1.5,
      borderRadius: '8px',
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(21, 38, 53, 1)'
          : 'rgba(211, 216, 222, 0.26)',
    })}
  >
    <Box display={'flex'} alignItems={'center'} columnGap={3} marginBottom={2}>
      <Typography
        fontSize={14}
        fontWeight={600}
        sx={(theme) => ({
          flexGrow: 1,
          color:
            theme.palette.mode === 'dark'
              ? 'rgba(211, 216, 222, 1)'
              : 'rgba(21, 38, 53, 1)',
        })}
      >
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Button
            variant="contained"
            sx={{
              py: 0.5,
              px: 0.75,
              borderRadius: '8px',
            }}
          >
            {field.value}%
          </Button>
        )}
      />
    </Box>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Slider
          {...field}
          step={10}
          marks
          min={0}
          max={100}
          sx={{
            color: 'rgba(50, 182, 243, 1)',
          }}
        />
      )}
    />
  </Card>
)

export default ScoreField
