/* eslint-disable */
import { Stack, Typography } from '@mui/material'
import { useState } from 'react'
import StatsCards from './stats'
import CoverageCards from './coverage'
import LoadingActionCard from './loading-action-card'

export const CARD_TYPES = {
  STATS_CARDS: 'stats-cards',
  COVERAGE_CARDS: 'coverage-cards',
} as const

export type CardType = (typeof CARD_TYPES)[keyof typeof CARD_TYPES]

export interface CardLoadingState {
  [CARD_TYPES.STATS_CARDS]: boolean
  [CARD_TYPES.COVERAGE_CARDS]: boolean
}

const ActionCards = () => {
  const [cardLoadingState, setCardLoadingState] = useState<CardLoadingState>({
    [CARD_TYPES.STATS_CARDS]: true,
    [CARD_TYPES.COVERAGE_CARDS]: true,
  })

  const isLoading = Object.values(cardLoadingState).some((value) => value)

  return (
    <Stack
      gap={2}
      sx={{
        maxHeight: { xs: 800, md: 292, lg: 468 },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(128, 128, 128, 0.5)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(128, 128, 128, 0.7)',
        },
      }}
    >
      <Typography fontWeight={700}>Scan Results</Typography>
      <div style={{ position: 'relative' }}>
        {isLoading ? (
          <Stack gap={2}>
            {Array.from({ length: 6 }).map((_, index) => (
              <LoadingActionCard key={index} />
            ))}
          </Stack>
        ) : null}
        <Stack gap={2}>
          <StatsCards
            cardLoadingState={cardLoadingState}
            setCardLoadingState={setCardLoadingState}
          />
          <CoverageCards
            cardLoadingState={cardLoadingState}
            setCardLoadingState={setCardLoadingState}
          />
        </Stack>
      </div>
    </Stack>
  )
}

export default ActionCards
