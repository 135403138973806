export interface NestedOption {
  id: string
  name: string
  children?: Array<NestedOption>
}

export type NestedOptions = Map<string, Set<string>>

export const transformNestedOptions = (
  allRedstacks: Array<any> | null
): Array<NestedOption> => {
  if (!allRedstacks) {
    return []
  }

  const nestedOptions: NestedOptions = new Map<string, Set<string>>([])
  allRedstacks.forEach(({ awsAccountId, awsRegion }) => {
    const regions = nestedOptions.get(awsAccountId) || new Set<string>()
    regions.add(awsRegion)
    nestedOptions.set(awsAccountId, regions)
  })

  const sampleOptions: Array<NestedOption> = []

  nestedOptions.forEach((awsRegions, awsAccountId) => {
    const children = Array.from(awsRegions).map((awsRegion) => ({
      id: `${awsAccountId}||${awsRegion}`,
      name: awsRegion,
    }))

    sampleOptions.push({
      id: awsAccountId,
      name: awsAccountId,
      children,
    })
  })

  return sampleOptions
}
