import ListPopup from '@components-composite/popups/list-popup/ListPopup'
import React, { ReactNode } from 'react'
import { EngineCallback, VINumberMap, VIRow } from '@lib/engine-types'
import UserStatusBock from '@components-simple/user-status-bock/UserStatusBock'
import ListPopupToken from '@components-composite/popups/list-popup-token/ListPopupToken'
import ValueInterface from '@lib/interfaces/value.interface'
import RegionStatusProgress from '@components-composite/region-status-progress/RegionStatusProgress'
import AccountStatusConstant from '@lib/constants/account-status.constant'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import SourceIdOnChangeInterface from '@lib/interfaces/source-id-on-change.interface'
import CopyRow from '@components-simple/copy-row/CopyRow'
import { drawAWSStatus } from './table-draw'
import RecoveryPointCircleStatus from '@components-composite/recovery-point-circle-status/RecoveryPointCircleStatus'
import PageHelper from '@lib/helpers/page.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import ButtonsRow from '@components-composite/buttons-row/ButtonsRow'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import PauseResumePolicy from '@components-simple/pause-resume-policy/PauseResumePolicy'
import RepairVaultInterface from '@lib/interfaces/repair-vault-interface'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import TableCellTags from '@components-composite/table-cell-tags/TableCellTags'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import DataHelper from '@lib/helpers/data.helper'
import DataItemStatus from '@components-composite/data-item-status/DataItemStatus'
import QuizIcon from '@inline-img/general/quiz-icon'
import DeleteIcon from '@inline-img/general/menu/delete-icon'
import EditIcon from '@inline-img/general/menu/edit-icon'
import NumHelper from '@lib/helpers/num.helper'
import DownloadIcon from '@mui/icons-material/Download'
import TextConstant from '@lib/constants/text.constant'
import { SelectAllPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
// eslint-disable-next-line import/no-extraneous-dependencies
import CheckCircle from '@inline-img/general/check-circle'
import AlertErrorRoundedIcon from '@inline-img/general/alert-error-rounded-icon'

import {
  DateTimeTooltip,
  ParenthesizedDateTimeTooltip,
} from '@components-simple/date-time-tooltip'
import { VPC_SUBNET_NAME_TOOLTIP_LENGTH } from '@lib/constants/tooltip.constant'
import { Link, WebhookEditRoutes } from '@lib/router'

const extractDate = (value: string): string => {
  const [, date] = value.split(': ')
  return String(date)
}

export const MANAGE_GROUPS_TABLE_MAPPER = (): VINumberMap => ({
  0: ({ name }: ValueInterface) => <div className="fontBold">{name}</div>,
  1: (v) => <ListPopup data={v} />,
  2: ({ name }: ValueInterface) => (
    <div className="jobTimeColumn hintLabel">
      {name && (
        <DateTimeTooltip
          placement="top-start"
          direction="row"
          date={new Date(name)}
        />
      )}
    </div>
  ),
  /*TODO: hidden 09.02.2024 by ticket #9484*/
  // 1: ({ options }: ValueInterface) => (
  //   <div className="groupList">
  //     {options?.map((popupData) => (
  //       <GroupPopup
  //         key={`${popupData.name}${popupData.label}`}
  //         data={popupData}
  //       />
  //     ))}
  //   </div>
  // ),
})
export const MANAGE_USERS_TABLE_MAPPER = (): VINumberMap => ({
  0: ({ name, label }: ValueInterface) => (
    <div className="userTableBlock">
      <div className="userTBTitle jsName">{name}</div>
      <div className="jsEmail">{label}</div>
    </div>
  ),
  /*TODO: hidden 09.02.2024 by ticket #9484*/
  // 2: ({ options }: ValueInterface) => (
  //   <div className="groupList">
  //     {options?.map((popupData) => (
  //       <GroupPopup
  //         key={`${popupData.name}${popupData.label}`}
  //         data={popupData}
  //       />
  //     ))}
  //   </div>
  // ),
  1: ({ name }: ValueInterface) => (
    <div className="jobTimeColumn hintLabel">
      {name && (
        <DateTimeTooltip
          placement="top-start"
          direction="row"
          date={new Date(name)}
        />
      )}
    </div>
  ),
  2: ({ value }: ValueInterface) => <UserStatusBock status={Number(value)} />,
})

export const ACTIVE_SOURCES_TABLE_MAPPER = (): VINumberMap => ({
  0: ({ type, messages, label }: ValueInterface) => (
    <div className="tableStatus">
      {drawAWSStatus(
        label
          ? AccountStatusConstant.MIXED
          : (type ?? AccountStatusConstant.UNDEFINED),
        label ? [label] : messages
      )}
    </div>
  ),
  1: ({ name }: ValueInterface) => (
    <CopyRow
      href={PageHelper.buildUrl(PagePathConstant.SOURCE_VAULTS, name)}
      text={name}
      variant="unstyled"
    />
  ),
})

export const HISTORY_SOURCES_TABLE_MAPPER = (): VINumberMap => ({
  0: ({ name }: ValueInterface) => <CopyRow text={name} variant="unstyled" />,
})

export const ACCESS_TOKENS_TABLE_MAPPER = (): VINumberMap => ({
  1: (v) => <ListPopupToken data={v} />,
  3: ({ name }) => (
    <DateTimeTooltip
      placement="top-start"
      direction="row"
      date={new Date(name)}
    />
  ),
})

export const REVOKED_TOKENS_TABLE_MAPPER = (): VINumberMap => ({
  1: (v) => <ListPopupToken data={v} />,
  3: ({ name }) => (
    <DateTimeTooltip
      placement="top-start"
      direction="row"
      date={new Date(name)}
    />
  ),
})

export const REGION_STATUS_PROGRESS_MAPPER = (
  onRetry: EngineCallback<string>,
  onShowDetails: EngineCallback<string>,
  onCancel: EngineCallback<string>
): VINumberMap => ({
  0: ({ name }: ValueInterface) => <RegionStatusProgress status={name} />,
  1: ({ label }: ValueInterface) => (
    <div className="regionStatusLabelWrap">
      <div className="regionStatusLabel">{label}</div>
    </div>
  ),
  2: ({ name, type }: ValueInterface) => (
    <>{type === -1 ? <div>-</div> : <div>{name}</div>}</>
  ),
  3: ({ name, type, label }: ValueInterface) => (
    <div className="rowButtonsList">
      {type === 2 && (
        <>
          <Button
            onClick={() => onRetry(name)}
            color="primary"
            variant="contained"
          >
            Retry
          </Button>
          <Button
            onClick={() =>
              onShowDetails(label ?? 'Error message was not specified')
            }
            color="primary"
            variant="outlined"
          >
            Show Details
          </Button>
        </>
      )}
      {type === 3 && (
        <Button
          onClick={() => onCancel(name)}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
      )}
    </div>
  ),
})

export const SOURCE_ID_MAPPER = (
  onChange: EngineCallback<SourceIdOnChangeInterface>
): VINumberMap => ({
  0: ({ label, options }: ValueInterface) => (
    <>
      {!options || options.length < 1 ? (
        <>
          <span className="disabledTest">{label}</span>
          <QuestionHint text="This region does not have any VPCs or the region is disabled in AWS." />
        </>
      ) : (
        <div>{label}</div>
      )}
    </>
  ),
  1: ({
    name,
    value,
    options,
    selectedOption,
    label,
    extraValue,
    defaultValue,
    matrix,
  }: ValueInterface) => (
    <div className="flexRowJustifyStart">
      <FormControlLabel
        className="tableBigCheckbox"
        control={
          <Checkbox
            disabled={!options || options.length < 1}
            color="primary"
            checked={Boolean(value)}
            onChange={(ev) =>
              onChange({
                redStackId: name,
                checkboxValue: ev.target.checked,
                selectedOption,
                label,
                region: String(extraValue),
                defaultValueCheckbox: !!defaultValue,
                selectedSubOptions:
                  matrix?.[1]?.filter(
                    (sv) =>
                      sv.defaultValue === selectedOption?.name &&
                      sv.placeholder === String(extraValue)
                  ) ?? [],
              })
            }
          />
        }
        label={(() => {
          switch (label) {
            case 'Connected':
              return (
                <>
                  {label}
                  <QuestionHint
                    text="Connected means that communication between the tenant and elastio
          connector is established."
                  />
                </>
              )
            case 'Disconnected':
              return (
                <>
                  {label}
                  <QuestionHint
                    text="Disconnected means that the elastio connector is disconnected
        from the tenant.  In this state, backups and recoveries and all other functions are are not available.
        However, your recovery points are maintained."
                  />
                </>
              )
            case 'Connected (Upgrade Required)':
              return (
                <>
                  {label}
                  <QuestionHint
                    text="Upgrade required means that communication between the tenant and elastio
                    connector is established but cloud connected upgrade is required"
                  />
                </>
              )
            default:
              return (
                <>
                  <b>{label}</b>
                </>
              )
          }
        })()}
      />

      {value && value !== defaultValue && (
        <div className="flexRowJustifyStart dropdownsBlock">
          <ViSelect
            possible={options ?? []}
            selected={selectedOption}
            onChange={(el: ValueInterface) =>
              onChange({
                redStackId: name,
                checkboxValue: true,
                selectedOption: el,
                label,
                region: String(extraValue),
                defaultValueCheckbox: !!defaultValue,
                selectedSubOptions:
                  matrix?.[1]?.filter(
                    (sv) =>
                      sv.defaultValue === el?.name &&
                      sv.placeholder === String(extraValue)
                  ) ?? [],
              })
            }
            getLabel={(v) => {
              if (v.label) {
                return (
                  <LongTextTooltip
                    text={v.label ?? v.name}
                    placement="right"
                    tooltipText={v.name}
                    maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                    show
                  />
                )
              }
              return v.name
            }}
          />
          <div className="ml22">
            <ViMultipleSelect
              disabled={selectedOption === null}
              possible={
                matrix?.[0]?.filter(
                  (mv) =>
                    mv.defaultValue === selectedOption?.name &&
                    mv.placeholder === String(extraValue)
                ) ?? []
              }
              selected={
                matrix?.[1]?.filter(
                  (sv) =>
                    sv.defaultValue === selectedOption?.name &&
                    sv.placeholder === String(extraValue)
                ) ?? []
              }
              onChange={(el: VIRow) =>
                onChange({
                  redStackId: name,
                  checkboxValue: true,
                  selectedOption,
                  label,
                  region: String(extraValue),
                  defaultValueCheckbox: !!defaultValue,
                  selectedSubOptions: el,
                })
              }
              placeholder={'Select subnet'}
              countLabel={'subnets selected'}
              showNameInTooltip
              menuWidth="auto"
              maxLengthInTooltip={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
            />
          </div>
        </div>
      )}
    </div>
  ),
})

export const RECOVERY_POINT_LIST_MAPPER = (): VINumberMap => ({
  1: ({
    selectedOption,
    value,
    label,
    extraValue,
    extraOption,
  }: ValueInterface) => (
    <RecoveryPointCircleStatus
      statusIscan={selectedOption}
      statusFsCheck={extraOption}
      completeLink={String(value)}
      completeLinkLabel={String(label)}
      kind={extraValue}
    />
  ),
  2: ({ options }: ValueInterface) => <TableCellTags tagsMap={options} />,
})

export const DATA_ITEM_LIST_MAPPER = (): VINumberMap => ({
  1: ({
    type,
    value,
    label,
    extraValue,
    supplementalValue,
    defaultValue,
  }: ValueInterface) => {
    return (
      <DataItemStatus
        completeLinkLabel={String(label)}
        statusIntegrityDI={Number(type)}
        completeLinkIscan={String(value)}
        completeLinkFsCheck={String(defaultValue)}
        statusIscan={Number(extraValue)}
        statusFsCheck={Number(supplementalValue)}
      />
    )
  },
})

export const POLICIES_MAPPER = (
  onResumePolicy: EngineCallback<string>,
  onPausePolicy: EngineCallback<string>,
  tooltipMaxLength = 50
): VINumberMap => ({
  0: ({ name }) => <LongTextTooltip text={name} maxLength={tooltipMaxLength} />,
  // Last run
  2: ({ name }) =>
    name ? (
      <DateTimeTooltip
        placement="top-start"
        direction="row"
        date={new Date(Number(name))}
      />
    ) : (
      'Never'
    ),
  3: ({ name }) =>
    name === 'Paused' ? (
      'Never'
    ) : name.startsWith('Next run: ') ? (
      <>
        Next run:{' '}
        <DateTimeTooltip
          placement="top-start"
          direction="row"
          date={new Date(extractDate(name))}
        />
      </>
    ) : name.startsWith('Scheduled: ') ? (
      <>
        Scheduled:{' '}
        <DateTimeTooltip
          placement="top-start"
          direction="row"
          date={new Date(extractDate(name))}
        />
      </>
    ) : (
      name
    ),
  // columns are temporary hidden by MSKCC Support issues
  /* 4: ({ name, value }: ValueInterface) => (
    <Link href={String(value)}>
      <a className="blueBoldLink">{name}</a>
    </Link>
  ), */
  //5: ({ name /* extraValue */ }: ValueInterface) => {
  //  return name

  // TODO return link when backend is ready
  // Ticket #8321
  // return (
  //   <Link
  //     href={PageHelper.buildUrl(
  //       PagePathConstant.ASSETS_POLICY_ID,
  //       String(extraValue)
  //     )}
  //   >
  //     <a className="blueBoldLink">{name}</a>
  //   </Link>
  // )
  //},
  4: ({ value, extraValue }: ValueInterface) => (
    <div className="iconBtnWrap iconBtnNoHover jsPauseResumeWrap">
      <PauseResumePolicy
        isPaused={Boolean(extraValue)}
        onResume={() => onResumePolicy(String(value))}
        onPause={() => onPausePolicy(String(value))}
      />
    </div>
  ),
})

export const POLICIES_RETENTION_MAPPER = (
  onResumePolicy: EngineCallback<string>,
  onPausePolicy: EngineCallback<string>,
  tooltipMaxLength = 50,
  isEditing = false,
  isShowAssetsColumn: boolean
): VINumberMap => {
  if (isShowAssetsColumn) {
    return {
      0: ({ name }) => (
        <div className="iconBtnWrap draggable">
          {!DataHelper.isRetentionDefaultPolicy(name) && isEditing && (
            <DragIndicatorIcon />
          )}
          <LongTextTooltip text={name} maxLength={tooltipMaxLength} />
        </div>
      ),
      4: ({ value, extraValue }: ValueInterface) => (
        <div className="iconBtnWrap iconBtnNoHover jsPauseResumeWrap">
          <PauseResumePolicy
            isPaused={Boolean(extraValue)}
            onResume={() => onResumePolicy(String(value))}
            onPause={() => onPausePolicy(String(value))}
          />
        </div>
      ),
    }
  }
  return {
    0: ({ name }) => (
      <div className="iconBtnWrap draggable">
        {!DataHelper.isRetentionDefaultPolicy(name) && isEditing && (
          <DragIndicatorIcon />
        )}
        <LongTextTooltip text={name} maxLength={tooltipMaxLength} />
      </div>
    ),
    3: ({ value, extraValue }: ValueInterface) => (
      <div className="iconBtnWrap iconBtnNoHover jsPauseResumeWrap">
        <PauseResumePolicy
          isPaused={Boolean(extraValue)}
          onResume={() => onResumePolicy(String(value))}
          onPause={() => onPausePolicy(String(value))}
        />
      </div>
    ),
  }
}

export const ADD_POLICY_S3_ASSETS_MAPPER = (
  onSelectAllPaths: EngineCallback<SelectAllPathsInterface>,
  onSelectPathsClick: EngineCallback<string>
): VINumberMap => ({
  7: ({ disabled, label, id, value }) => (
    <div>
      <V2Checkbox
        variant="table"
        onChange={() =>
          onSelectAllPaths({
            assetId: String(id),
            cbValue: Boolean(value),
          })
        }
        label={label}
        checked={Boolean(value)}
        disabled={disabled}
        key={id}
      />
    </div>
  ),
  8: ({ id, label, disabled }) => (
    <Button
      className="whiteSelectButton"
      type="button"
      onClick={() => onSelectPathsClick(String(id))}
      variant="contained"
      disabled={disabled}
    >
      {label}
    </Button>
  ),
})

export const WEBHOOK_EVENTS_TABLE_MAPPER = (
  onDeleteWebhook: EngineCallback<string>,
  isOnlyOneEventType: boolean
): VINumberMap => ({
  3: ({ name }: ValueInterface) => {
    if (isOnlyOneEventType) {
      return (
        <Tooltip
          placement="top"
          title="At least one event subscription is required"
        >
          <span className="disabled">
            <DeleteIcon fill="#1A1F28" />
          </span>
        </Tooltip>
      )
    }

    return (
      <div className="iconBtnWrap iconBtnNoHover jsbtnWrap">
        <IconButton
          size="small"
          onClick={() => onDeleteWebhook(String(name))}
          aria-label="delete-event"
          className="jsQuizIcon"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    )
  },
})

export const WEBHOOK_TABLE_MAPPER = (
  onTestWebhook: EngineCallback<string>,
  onEditWebhook: EngineCallback<string>,
  onDeleteWebhook: EngineCallback<string>
): VINumberMap => ({
  0: ({ value, name }: ValueInterface) => (
    <Link to={WebhookEditRoutes.hookId(String(value))}>
      <span className="blueBoldLink" data-testid="webhook-link">
        {name}
      </span>
    </Link>
  ),

  3: ({ value }: ValueInterface) => (
    <div className="iconBtnWrap iconBtnNoHover jsbtnWrap">
      <IconButton
        size="small"
        onClick={() => onTestWebhook(String(value))}
        className="jsQuizIcon"
        aria-label="test-webhook"
      >
        <QuizIcon />
      </IconButton>
    </div>
  ),
  4: ({ value }: ValueInterface) => (
    <div className="iconBtnWrap iconBtnNoHover jsbtnWrap">
      <IconButton
        size="small"
        onClick={() => onEditWebhook(String(value))}
        className="jsEditIcon"
        aria-label="edit-webhook"
      >
        <EditIcon />
      </IconButton>
    </div>
  ),
  5: ({ value }: ValueInterface) => (
    <div className="iconBtnWrap iconBtnNoHover jsbtnWrap">
      <IconButton
        size="small"
        onClick={() => onDeleteWebhook(String(value))}
        className="jsQuizIcon"
        aria-label="delete-webhook"
        data-testid="delete-webhook"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  ),
})

export const BACKUP_POLICIES_DASHBOARD_MAPPER = (): VINumberMap => ({
  3: ({ name, value }: ValueInterface) => (
    <Link to={String(value)}>
      <span className="blueBoldLink">{name}</span>
    </Link>
  ),
})

export const SOURCE_VAULTS_MAPPER = (
  onPin: EngineCallback<string>,
  onUnpin: EngineCallback<string>,
  onVaultRepair: EngineCallback<RepairVaultInterface>
): VINumberMap => ({
  0: ({ name, label }: ValueInterface) => (
    <>
      {name ? (
        <Link
          to={PageHelper.buildUrl(PagePathConstant.VAULT_DETAILED, name)}
          className="blueLink jsAccLink"
        >
          <span>{label}</span>
        </Link>
      ) : (
        <div>{label}</div>
      )}
    </>
  ),
  // repair button
  6: ({ value, disabled, name }) => {
    return (
      <Button
        onClick={() =>
          onVaultRepair({
            cloudConnectorId: String(value),
            vaultName: name,
          })
        }
        disabled={disabled}
        color="primary"
        variant="text"
        className="buttonSmall"
      >
        Repair
      </Button>
    )
  },
  // pin inpin
  7: ({ type, name }: ValueInterface) => {
    // type 0 - empty
    // type 1 - pin button
    // type 2 - unpin button
    switch (type) {
      case 1:
        return (
          <ButtonsRow
            data={[
              {
                onClick: () => onPin(name),
                label: 'Pin',
                isDisabled: false,
                variant: 'showSquare',
              },
            ]}
          />
        )
      // Unpin
      case 2:
        return (
          <ButtonsRow
            data={[
              {
                onClick: () => onUnpin(name),
                label: 'Unpin',
                isDisabled: false,
                variant: 'showSquare',
              },
            ]}
          />
        )
      default:
        return null
    }
  },
})

export const DASHBOARD_VAULTS_MAPPER = (): VINumberMap => ({
  2: ({ name, label }: ValueInterface) => (
    <>
      {name ? (
        <Link
          to={PageHelper.buildUrl(PagePathConstant.VAULT_DETAILED, name)}
          className="blueLink jsAccLink"
        >
          <span>{label}</span>
        </Link>
      ) : (
        <div>{label}</div>
      )}
    </>
  ),
})

function dashboardV3FieldLinkWithName({
  name,
  value,
  label,
}: ValueInterface): ReactNode {
  return (
    <>
      {value ? (
        <Link
          to={String(value ?? '')}
          className="blueLink"
          rel="noopener noreferrer"
        >
          <span>{name}</span>
        </Link>
      ) : (
        <>{name}</>
      )}
      <LongTextTooltip customClass="idName" text={label ?? ''} maxLength={25} />
    </>
  )
}

export const ASSETS_POLICY_RETENTION_MAPPER = (): VINumberMap => ({
  0: dashboardV3FieldLinkWithName,
})

export const WEBHOOK_EVENTS_MAPPER = (
  onRemove: (id: number) => void
): VINumberMap => ({
  3: ({ value }) => {
    if (!value) {
      return null
    }

    return (
      <span onClick={() => onRemove(Number(value))}>
        <DeleteIcon fill="#1A1F28" />
      </span>
    )
  },
})

function commonValue({ name, value }: ValueInterface): ReactNode {
  return (
    <>
      <div className="totalValueTable">{value}</div>
      <div className="totalLabelTable">{name}</div>
    </>
  )
}

function costTotalValueWithTooltip({ name, value }: ValueInterface): ReactNode {
  return (
    <>
      <Tooltip
        title={TextConstant.ESTIMATED_PRICE_TOOLTIP_TEXT}
        placement={'bottom'}
      >
        <div className="totalValueTable nowrap">
          <span className="details">~ </span>$
          {NumHelper.getNumberUSFormatted(Number(value))}
        </div>
      </Tooltip>

      <div className="totalLabelTable">{name}</div>
    </>
  )
}

function sizeTotalValue({ name, value }: ValueInterface): ReactNode {
  return (
    <>
      <div className="totalValueTable">
        {value}
        <span className="unit">GiB</span>
      </div>
      <div className="totalLabelTable">{name}</div>
    </>
  )
}

export const TOTAL_ROW_SCROLL_TABLE_MAPPER = (): VINumberMap => ({
  0: commonValue,
  1: commonValue,
  2: commonValue,
  3: commonValue,
  4: commonValue,
  5: commonValue,
  6: costTotalValueWithTooltip,
  7: sizeTotalValue,
  8: commonValue,
})

export const TOTAL_ROW_EBS_INVENTORY_MAPPER = (): VINumberMap => ({
  0: commonValue,
  1: commonValue,
  2: commonValue,
  3: commonValue,
  4: commonValue,
  5: commonValue,
  6: commonValue,
  7: sizeTotalValue,
  8: commonValue,
})

function volumeFieldLink({ name, value }: ValueInterface): ReactNode {
  return (
    <>
      {value ? (
        <Link
          to={String(value ?? '')}
          className="blueLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{name}</span>
        </Link>
      ) : (
        <>{name}</>
      )}
    </>
  )
}

export const EBS_SNAPSHOT_DASHBOARD_MAPPER = (): VINumberMap => ({
  0: ({ name }) => (
    <div className="statusCircle">
      <div
        className="statusCircleInner"
        style={{ backgroundColor: name ? '#70d180' : '#E22626' }}
      />
    </div>
  ),
  1: volumeFieldLink,
  5: ({ name }) => <LongTextTooltip text={name} maxLength={10} />,
  6: ({ value }) => (
    <div>
      <span>{NumHelper.getNumberUSFormatted(Number(value))}</span>
    </div>
  ),
  7: ({ value }) => <div>{value}</div>,
  8: ({ value }) => <div>{value}</div>,
  9: ({ value }) => <div>{value}</div>,
})

export const SCHEDULE_REPORTS_TABLE_MAPPER = (
  onDownload: EngineCallback<string>
): VINumberMap => ({
  1: ({ name }: ValueInterface) => {
    return (
      <div className="iconBtnWrap iconBtnNoHover jsbtnWrap">
        <IconButton
          size="small"
          onClick={() => onDownload(String(name))}
          aria-label="delete-event"
          className="jsQuizIcon"
        >
          <DownloadIcon sx={{ color: '#32B6F3' }} />
        </IconButton>
      </div>
    )
  },
})

export const SNAPSHOT_INVENTORY_TABLE_DASHBOARD_MAPPER = (): VINumberMap => ({
  0: dashboardV3FieldLinkWithName,
  1: dashboardV3FieldLinkWithName,
  4: ({ value }) => <div>{value}</div>,
})

export const dateTimeHintDraw = ({ name, label }: ValueInterface) => {
  const isLaunchTimeOrCreated = name === 'Launch Time' || name === 'Created'
  const countDaysOrMonth = isLaunchTimeOrCreated ? label?.split('(').pop() : ''

  let content
  if (label === '-') {
    content = <>{label}</>
  } else {
    content = label && (
      <DateTimeTooltip
        placement="top-start"
        date={new Date(label)}
        direction="row"
      >
        <span className="ml3">{`(${countDaysOrMonth})`}</span>
      </DateTimeTooltip>
    )
  }

  return (
    <>
      <div className="mbLabel jsDashboardDateTimeBlockLabel">{name}</div>
      <div className="mbValue jsDashboardDateTimeBlockValue">{content}</div>
    </>
  )
}

function scanStatus({
  name,
  value, // Status of Scan
  supplementalValue, // Date of Scan
}: ValueInterface) {
  const currentScanStatusIcon: Record<number, JSX.Element> = {
    0: <CheckCircle />,
    1: <AlertErrorRoundedIcon />,
  }
  const scanRenderStatus: Record<number, string> = {
    0: 'Clean',
    1: 'Infected',
  }

  const displayedStatus = scanRenderStatus[value as number] ?? 'Unscanned'
  const displayedStatusIcon = currentScanStatusIcon[value as number] ?? (
    <AlertErrorRoundedIcon />
  )

  return (
    <>
      <div className="mbLabel jsBlockLabel">{name}</div>
      <div className="mbValue _flex jsBlockValue">
        {displayedStatusIcon}
        {displayedStatus}
        {supplementalValue && (
          <ParenthesizedDateTimeTooltip>
            <DateTimeTooltip
              placement="top-start"
              direction="row"
              date={new Date(supplementalValue as number)}
            />
          </ParenthesizedDateTimeTooltip>
        )}
      </div>
    </>
  )
}

function restoreRpStatusInnerDraw({
  name,
  value,
  supplementalValue,
}: ValueInterface) {
  const currentScanStatusIcon: Record<number, JSX.Element> = {
    0: <CheckCircle />,
    1: <AlertErrorRoundedIcon />,
  }
  const scanRenderStatus: Record<number, string> = {
    0: 'Not Found',
    1: 'Detected',
  }

  const displayedStatus = scanRenderStatus[value as number] ?? 'Unscanned'
  const displayedStatusIcon = currentScanStatusIcon[value as number] ?? (
    <AlertErrorRoundedIcon />
  )

  return (
    <>
      <div className="mbLabel jsBlockLabel">{name}</div>
      <div className="mbValue _flex jsBlockValue">
        {displayedStatusIcon}
        {displayedStatus}
        {supplementalValue && (
          <ParenthesizedDateTimeTooltip>
            <DateTimeTooltip
              placement="top-start"
              direction="row"
              date={new Date(supplementalValue as number)}
            />
          </ParenthesizedDateTimeTooltip>
        )}
      </div>
    </>
  )
}

export const dateTimeRpPageMore = ({ name, label }: ValueInterface) => {
  return (
    <>
      <div className="mbLabel jsDashboardDateTimeBlockLabel">{name}</div>
      <div className="mbValue jsDashboardDateTimeBlockValue">
        <DateTimeTooltip
          placement="top-start"
          date={new Date(Number(label))}
          direction="row"
        />
      </div>
    </>
  )
}

export const RESTORE_RP_INFO_DRAW_MAPPER = (): VINumberMap => ({
  0: scanStatus,
  1: scanStatus,
  2: restoreRpStatusInnerDraw,
  4: dateTimeRpPageMore,
})

export const MOUNT_RP_INFO_DRAW_MAPPER = (): VINumberMap => ({
  3: dateTimeRpPageMore,
})
