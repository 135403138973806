import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import BaseModal from '@components-composite/modals/BaseModal'
import { EngineCallbackPure } from '@lib/engine-types'
import { Button, TextField } from '@mui/material'
import { getPoliciesNames } from '@store/selectors/policies.selector'
import RegexConstants from '@lib/constants/regex.constant'
import { getAddPolicyName } from '@store/selectors/add-policy.selector'
import { setAddPolicyName } from '@store/actions/add-policy.action'
import PagePathConstant from '@lib/constants/page-path.constant'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface Props {
  onCancel: EngineCallbackPure
  show: boolean
}

function CreateProtectionPolicyModal({ show, onCancel }: Props) {
  const dispatch = useDispatch()
  const router = useNavigation()
  const policyName = useSelector(getAddPolicyName)
  const existingPoliciesNames = useSelector(getPoliciesNames)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    dispatch(setAddPolicyName(''))
  }, [])

  const onPolicyNameChange = (newPolicyName: string) => {
    dispatch(setAddPolicyName(newPolicyName))
  }

  const checkNameValidity = () => {
    // the policy name is required, and it must be unique among other
    // existing policies with only alphanumeric, spaces and +-=._:/@ are allowed Maximum 255 characters.

    const trimmedPolicyName: string = policyName.trim()
    const policyNameValid: boolean = RegexConstants.POLICY_NAME.test(policyName)
    const policyNameExists: boolean =
      existingPoliciesNames.includes(trimmedPolicyName)

    if (error) {
      setError('')
    }

    if (trimmedPolicyName && policyNameValid && !policyNameExists) {
      return
    }

    if (!trimmedPolicyName) {
      setError('The Name is required')
      return
    }

    if (policyNameExists) {
      setError(`The Name ${trimmedPolicyName} already exists`)
      return
    }

    if (!policyNameValid) {
      setError(
        `Policy name can only contain alphanumeric characters, spaces and +-._:/@.
        The name can't begin with "+", "/" or whitespace, and can't end with whitespace.`
      )
      return
    }
  }

  const onNext = () => {
    checkNameValidity()
    if (!error && policyName?.trim()) {
      router.push(PagePathConstant.POLICIES_ADD)
      onCancel()
    }
  }

  return (
    <BaseModal
      className="createProtectionPolicy"
      show={show}
      onBgClick={onCancel}
    >
      <div className="modalHeader">Create Protection Policy</div>
      <div className="modalContent">
        <div
          className={clsx('newFormSingleRow v2StaticTextInput', {
            statusErrorClass: !!error,
          })}
        >
          <TextField
            value={policyName}
            onBlur={checkNameValidity}
            onKeyUp={checkNameValidity}
            inputProps={{ maxLength: 255 }}
            onChange={(e) => onPolicyNameChange(e.target.value)}
            label="Policy name"
            variant="outlined"
            helperText={error}
            className="jsPolicyNameField"
            error={!!error}
            placeholder="Name..."
          />
        </div>
        <div className="mfControls">
          <Button
            className="mfCancel"
            type="button"
            variant="text"
            color="primary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="mfNext"
            color="primary"
            variant="contained"
            type="button"
            onClick={onNext}
            disabled={!!error || !policyName?.trim()}
          >
            Next
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default CreateProtectionPolicyModal
