import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSecretToken } from '@store/selectors/token.selector'
import StrHelper from '@lib/helpers/str.helper'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import { submitRevokeTokenList } from '@store/actions/token.action'
import GeneratedTokenSuccess from '@components-complex/generated-token-success/GeneratedTokenSuccess'
import DialogModal from '@components-composite/modals/DialogModal'
import { useSearchParams } from 'react-router-dom'

function GeneratedAccessTokenId() {
  const [searchParams] = useSearchParams()
  const queryId = searchParams.get('id')
  const dispatch = useDispatch()
  const secretToken = useSelector(getSecretToken)
  const [generatedTokenId, setGeneratedTokenId] = useState<string>('')

  useEffect(() => {
    if (queryId) {
      const tokenId = StrHelper.base64Decode(queryId)
      setGeneratedTokenId(tokenId)
    }
  }, [queryId])

  const { modalProps: RevokeTokenModalProps } = useModal<string>(
    Modal.revokeToken,
    () => {
      dispatch(submitRevokeTokenList([generatedTokenId]))
    }
  )

  return (
    <div className="innerContent">
      <GeneratedTokenSuccess secretToken={secretToken} />
      <DialogModal
        description="You are going to remove API access for the user, please confirm the action in order to proceed"
        {...RevokeTokenModalProps}
      />
    </div>
  )
}

export default GeneratedAccessTokenId
