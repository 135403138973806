import React, { memo } from 'react'
import { Box, Button, FormControl, Popover, useTheme } from '@mui/material'
import { EngineCallback } from 'ui-v2/src/lib/engine-types'
import { generateIncSequence } from 'ui-v2/src/lib/helpers/arr.helper'
import Grid from '@mui/material/Grid2'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Label from 'ui-v2/src/components/ui/data-display/label'
import { getDayLabel } from 'ui-v2/src/lib/helpers/policies.helper'

interface Props {
  onChange: EngineCallback<number>
  readonly value: number
  readonly disabled?: boolean
  readonly label?: string
  readonly renderLastDay?: boolean
}

function DayInMonthSelector({
  value,
  onChange,
  disabled = false,
  label = '',
  renderLastDay = true,
}: Props) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeInner = (dayIndex: number) => () => {
    onChange(dayIndex)
    handleClose()
  }

  const drawDayButton = (dayIndex: number) => (
    <Grid key={dayIndex}>
      <Button
        onClick={onChangeInner(dayIndex)}
        type="button"
        variant={dayIndex === value ? 'contained' : 'text'}
        sx={{
          paddingX: 1,
          minWidth: 30,
          height: 30,
          marginBottom: 2,
        }}
      >
        {getDayLabel(dayIndex)}
      </Button>
    </Grid>
  )
  const drawDay = (dayIndex: number) => (
    <Grid key={dayIndex}>
      <Button
        onClick={onChangeInner(dayIndex)}
        type="button"
        variant={dayIndex === value ? 'contained' : 'text'}
        sx={{
          paddingX: 1,
          minWidth: 30,
          height: 30,
          width: 30,
          textAlign: 'center',
          color: theme.palette.text.primary,
        }}
      >
        {getDayLabel(dayIndex)}
      </Button>
    </Grid>
  )
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <FormControl>
      {label && (
        <Label variant="body2" fontWeight={0}>
          {label}
        </Label>
      )}
      <Button
        aria-label="Date popup"
        disabled={disabled}
        className="currentDayLabel"
        onClick={handleClick}
        type="button"
        sx={{
          borderColor: theme.palette.mode === 'dark' ? '#2F3745' : '#f5f5f5',
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'transparent',
          borderRadius: '4px',
          boxShadow: 'none',
          color: theme.palette.text.primary,
          textTransform: 'capitalize',
          display: 'flex',
          justifyContent: 'space-between',
          paddingX: '14px',
          paddingY: '7.5px',
          fontWeight: 400,
        }}
        fullWidth
        endIcon={<ArrowDropDownIcon fontSize="large" />}
      >
        {getDayLabel(value, true)}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            p: 2,
            width: 320,
          }}
        >
          {renderLastDay && [0, -1].map((dayIndex) => drawDayButton(dayIndex))}
          <Grid container spacing={1.5} rowSpacing={2}>
            {generateIncSequence(1, 28).map((drawIndex: number) =>
              drawDay(drawIndex)
            )}
          </Grid>
        </Box>
      </Popover>
    </FormControl>
  )
}

export default memo(DayInMonthSelector)
