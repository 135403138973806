import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import {
  useEmailSelectionState,
  useNotificationsState,
} from '@features/alerts/create-rule-drawer/stepper/steps'

function EmailList() {
  const emails = useEmailSelectionState()

  const { existingConsoleUsers } = useNotificationsState()

  if (emails && emails.length === 0 && existingConsoleUsers.length === 0) {
    return <Box component="p">No recipients available.</Box>
  }

  return (
    <>
      {emails.map(({ email }, index) => (
        <Typography sx={{ fontSize: '14px' }} key={index}>
          {email}
        </Typography>
      ))}
      {existingConsoleUsers.map(({ label }, index) => (
        <Typography sx={{ fontSize: '14px' }} key={index}>
          {label}
        </Typography>
      ))}
    </>
  )
}

export default EmailList
