import { PagePathConstant } from '@lib/constants'
import { CrumbsType, titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  EBS,
  EC2,
  EFS,
  GenericHost,
  S3Bucket,
  Asset,
  OvaServer,
} from 'blues-corejs/dist'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

export function computeAWSAssetHeaderTitle(awsAsset: AWSAsset) {
  return awsAsset.name ? `${awsAsset.awsId} (${awsAsset.name})` : awsAsset.awsId
}

export function getScansGrpcConfig(asset: Asset) {
  if (asset instanceof EC2) {
    return {
      assetIdList: [...asset.ebsIds, asset.id],
    }
  }

  return {
    assetIdList: [asset.id],
  }
}

export function computeHeaderTitle(asset: Asset) {
  if (asset instanceof AWSAsset) {
    return computeAWSAssetHeaderTitle(asset)
  }

  if (asset instanceof GenericHost) {
    return asset.hostname
  }

  if (asset instanceof OvaServer) {
    return asset.name
  }

  return ''
}

export function computeCurrentCrumbTitle(asset: Asset) {
  if (asset instanceof GenericHost) {
    return asset.hostname
  }

  if (asset instanceof AWSAsset) {
    return asset.awsId
  }

  if (asset instanceof OvaServer) {
    return asset.name
  }

  return null
}
export function computeVolumeBreadcrumbs() {
  return [
    {
      href: PagePathConstant.DASHBOARD,
      text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
    },
    {
      href: PagePathConstant.EBS_INVENTORY_TABLE,
      text: titlesForBreadcrumb(PagePathConstant.EBS_INVENTORY_TABLE) ?? '',
    },
  ]
}

export function computeGenericHostBreadcrumbs() {
  return [
    {
      href: PagePathConstant.DASHBOARD,
      text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
    },
    {
      href: PagePathConstant.GENERIC_HOSTS_INVENTORY_TABLE,
      text:
        titlesForBreadcrumb(PagePathConstant.GENERIC_HOSTS_INVENTORY_TABLE) ??
        '',
    },
  ]
}

export function computeS3BucketBreadcrumbs() {
  return [
    {
      href: PagePathConstant.DASHBOARD,
      text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
    },
    {
      href: PagePathConstant.S3_BUCKET_INVENTORY_TABLE,
      text:
        titlesForBreadcrumb(PagePathConstant.S3_BUCKET_INVENTORY_TABLE) ?? '',
    },
  ]
}

export function computeEFSBreadcrumbs() {
  return [
    {
      href: PagePathConstant.DASHBOARD,
      text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
    },
    {
      href: PagePathConstant.EFS_INVENTORY_TABLE,
      text: titlesForBreadcrumb(PagePathConstant.EFS_INVENTORY_TABLE) ?? '',
    },
  ]
}

function computeEC2Breadcrumbs() {
  return [
    {
      href: PagePathConstant.DASHBOARD,
      text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
    },
    {
      href: PagePathConstant.EC2_INVENTORY_TABLE,
      text: titlesForBreadcrumb(PagePathConstant.EC2_INVENTORY_TABLE) ?? '',
    },
  ]
}

function computeOvaServerBreadcrumbs() {
  return [
    {
      href: PagePathConstant.DASHBOARD,
      text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
    },
    {
      href: PagePathConstant.OVA_SERVER_INVENTORY_TABLE, // OVA_SERVER_INVENTORY_TABLE
      text:
        titlesForBreadcrumb(PagePathConstant.OVA_SERVER_INVENTORY_TABLE) ?? '', // OVA_SERVER_INVENTORY_TABLE
    },
  ]
}

export function computeBreadcrumbs(asset: Asset): Array<CrumbsType> {
  if (asset instanceof EBS) {
    return computeVolumeBreadcrumbs()
  }

  if (asset instanceof GenericHost) {
    return computeGenericHostBreadcrumbs()
  }

  if (asset instanceof S3Bucket) {
    return computeS3BucketBreadcrumbs()
  }

  if (asset instanceof EFS) {
    return computeEFSBreadcrumbs()
  }

  if (asset instanceof EC2) {
    return computeEC2Breadcrumbs()
  }

  if (asset instanceof OvaServer) {
    return computeOvaServerBreadcrumbs()
  }

  return []
}
