export const MAX_QUANTILES_SIZE = 5

export const TIME_UNITS = {
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
  month: 1000 * 60 * 60 * 24 * 30,
  year: 1000 * 60 * 60 * 24 * 365,
}

export const MIN = 'min'
export const HR = 'hr'
export const DAY = 'day'

export const SCALE_HEIGHT = 138
