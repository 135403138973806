import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { OvaAccountsClient } from 'ui-v2/src/lib/clients'

export function useListOvaAccountsQuery() {
  const ovaAccountsClient = useGrpcClient(OvaAccountsClient)

  return useQuery({
    queryKey: ['ova-accounts', 'list'],
    queryFn: async () => {
      if (!ovaAccountsClient) {
        throw new Error('Ova accounts client not initialized')
      }
      return ovaAccountsClient.listOvaAccounts()
    },
    enabled: !!ovaAccountsClient,
  })
}
