import { Column } from 'ui-v2/src/components/ui/data-display/data-table'
import { SourcesListTableRow } from 'ui-v2/src/lib/models/settings/sources/sources'
import SourceAction from './source-action'
//import UpgradeAction from './upgrade-action'

export const getSourcesListColumns = (): Array<Column<SourcesListTableRow>> => [
  {
    key: 'accountId',
    header: 'Account ID',
    render: (row) => (
      <>
        {row.accountId}
        {/* <UpgradeAction row={row} /> */}
      </>
    ),
  },
  {
    key: 'aliasName',
    header: 'Alias Name',
    render: (row) => (row.aliasName ? row.aliasName : '-'),
  },
  {
    key: 'description',
    header: 'Description',
    render: (row) => (row.description ? row.description : '-'),
  },
  {
    key: 'regions',
    header: 'Regions',
    render: (row) => row.regions,
  },
  {
    key: 'action',
    header: '',
    render: (row) => <SourceAction row={row} />,
  },
]
