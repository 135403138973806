import React, { useEffect } from 'react'
import Box from '@mui/material/Box'

import { useQueryTab } from '@lib/hooks'
import { TabContentRenderer, TabsRenderer } from './tabs'
import { Tabs } from './shared'
import { GenerateReportDrawer, ReportDetailsDrawer } from './report/drawer'
import { useTabsActions, TabName } from './use-data-store'
import { useUsersList } from './use-users-list'
import { useOvaAccountsActions, useOvaAccounts } from './use-ova-accounts-store'
import { useUsersListActions } from './use-users-list-store'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'

function ReportsSchedule() {
  const { activeUsers, isLoading } = useUsersList()
  const { setTab, tab: currentTab } = useQueryTab(Tabs.OVERVIEW)
  const { fetchTabData } = useTabsActions()
  const { setUsers } = useUsersListActions()

  useEffect(() => {
    if (!isLoading) {
      setUsers(activeUsers)
    }
  }, [isLoading])

  const { ovaAccountsList, isOvaAccountsLoading } = useFetchListOvaAccounts()

  const { setOvaAccountsList } = useOvaAccountsActions()
  useEffect(() => {
    const f = async () => {
      await fetchTabData(TabName.report)
    }
    f()
  }, [])

  const isDataLoaded = useOvaAccounts().length > 0

  useEffect(() => {
    if (!isOvaAccountsLoading && !isDataLoaded && ovaAccountsList.length > 0) {
      setOvaAccountsList(ovaAccountsList)
    }
  }, [isOvaAccountsLoading, isDataLoaded])

  return (
    <>
      <Box mx={2}>
        <TabsRenderer currentTab={currentTab} onChangeTab={setTab} />
      </Box>
      <TabContentRenderer currentTab={currentTab} />
      <GenerateReportDrawer />
      <ReportDetailsDrawer />
    </>
  )
}

export default ReportsSchedule
