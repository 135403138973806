"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.date = date;
exports.randomValueFromEnum = randomValueFromEnum;
function date() {
    const maxDate = Date.now();
    const timestamp = Math.floor(Math.random() * maxDate);
    return new Date(timestamp);
}
function randomValueFromEnum(enumObj) {
    const enumValues = Object.values(enumObj).filter((value) => typeof value === 'number');
    const index = Math.floor(Math.random() * enumValues.length);
    return enumValues[index];
}
