/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import AwsIcon from '@inline-img/general/aws-logo-icon'

import ElastioLogoBlueIcon from '@inline-img/general/elastio-blue-icon'
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
  Asset,
  Backup,
  GenericHost,
  OvaBackup,
  OvaBackupProvider,
} from 'blues-corejs/dist'
import ParenthesizedDateTimeTooltip from '@components/components-simple/date-time-tooltip/parenthesized-date-time-tooltip'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { CopyToClipboardIcon } from '@features/common'
import {
  CohesityIcon,
  CommvaultIcon,
  NetBackupIcon,
  RubrikIcon,
  VeeamIcon,
} from '@inline-img/general/ova/backup'
import { AwsLogoIcon } from '@inline-img/general'
import { SystemHelper } from '@lib/helpers'
import dayjs, { Dayjs } from 'dayjs'
import { RecoveryPointCreationDate } from './rp-creation-date'

// Since `dayjs` does not have a built-in function to format dates as "X days old", "X hours old", etc.
// We should use this function to format the date like on the design
// FIXME: after date behaviour bug fixed, add here the correct implementation with timezones
function pluralize(value: number, unit: string) {
  return `${value} ${unit}${value === 1 ? '' : 's'} old`
}
export function customFormatDate(target: Dayjs) {
  const now = dayjs()

  const diffYears = now.diff(target, 'year')
  const diffMonths = now.diff(target, 'month')
  const diffDays = now.diff(target, 'day')
  const diffHours = now.diff(target, 'hour')
  const diffMinutes = now.diff(target, 'minute')

  if (diffYears >= 1) {
    return pluralize(diffYears, 'year')
  } else if (diffMonths >= 1) {
    return pluralize(diffMonths, 'month')
  } else if (diffDays >= 1) {
    return pluralize(diffDays, 'day')
  } else if (diffHours >= 1) {
    return '< 1 day old'
  } else if (diffMinutes >= 1) {
    return '< 1 hr old'
  } else {
    return '< 1 min old'
  }
}

function computeOvaBackupProvider(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return 'Cohesity'
    case OvaBackupProvider.VEEAM:
      return 'Veeam'
    case OvaBackupProvider.COMMVAULT:
      return 'Commvault'
    case OvaBackupProvider.VERITAS_NET_BACKUP:
      return 'VeritasNetBackup'
    case OvaBackupProvider.RUBRIK:
      return 'Rubrik'
    case OvaBackupProvider.AWS_BACKUP_VMWARE:
      return 'AwsBackupVmware'
    default:
      SystemHelper.sendSentryIfProd(
        `computeOvaBackupProvider: Unknown OvaBackup provider: ${backup.provider}`
      )
      return null
  }
}
function computeOvaBackupProviderIcon(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return <CohesityIcon />
    case OvaBackupProvider.VEEAM:
      return <VeeamIcon />
    case OvaBackupProvider.COMMVAULT:
      return <CommvaultIcon />
    case OvaBackupProvider.VERITAS_NET_BACKUP:
      return <NetBackupIcon />
    case OvaBackupProvider.RUBRIK:
      return <RubrikIcon />
    case OvaBackupProvider.AWS_BACKUP_VMWARE:
      return <AwsLogoIcon />
    default:
      SystemHelper.sendSentryIfProd(
        `computeOvaBackupProviderIcon: Unknown OvaBackup provider: ${backup.provider}`
      )
      return null
  }
}

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type Props = {
  asset?: Asset
  backup?: Backup
  scan?: Scan
}

export const StyledIconContainer = styled(Box)`
  height: 25px;

  svg {
    width: 32px;
    height: 25px;
  }
`

const Icon = {
  elastio: <ElastioLogoBlueIcon />,
  aws: (
    <StyledIconContainer>
      <AwsIcon />
    </StyledIconContainer>
  ),
}

function RowTitle({ title }: { title: string }) {
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      marginRight="5px"
      variant="caption"
    >
      {title}:
    </Typography>
  )
}

function computeAssetIcon(asset: Asset) {
  if (asset instanceof GenericHost) {
    return Icon.elastio
  }
  return Icon.aws
}

function computeBackupIcon(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return Icon.elastio
  } else if (backup instanceof AWSBRecoveryPoint) {
    return Icon.aws
  } else if (backup instanceof EBSSnapshot) {
    return Icon.aws
  } else if (backup instanceof OvaBackup) {
    return computeOvaBackupProviderIcon(backup)
  }

  return null
}

function computeSourceName(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return 'Elastio RP'
  } else if (backup instanceof AWSBRecoveryPoint) {
    return 'AWS Backup RP'
  } else if (backup instanceof EBSSnapshot) {
    return 'EBS Snapshot'
  } else if (backup instanceof OvaBackup) {
    return computeOvaBackupProvider(backup)
  }
}

function computeBackupId(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return backup.ccRpId
  } else if (backup instanceof AWSBRecoveryPoint) {
    return backup.arn
  } else if (backup instanceof EBSSnapshot) {
    return backup.arn
  } else if (backup instanceof OvaBackup) {
    return backup.backupProviderBackupId
  }
}

function renderBackupSource(backup: Backup) {
  const computedIcon = computeBackupIcon(backup)
  const computedSourceName = computeSourceName(backup)
  const computedBackupId = computeBackupId(backup)
  return (
    <Box display="flex" alignItems="center">
      <RowTitle title="Source" />
      <Box display="flex" alignItems="center" gap="5px">
        <Box
          sx={{
            svg: {
              width: '24px',
              height: '24px',
            },
          }}
        >
          {computedIcon}
        </Box>
        <Typography variant="body2" component="span">
          {computedSourceName}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box>
            (
            <Box component="span" color="var(--blue500)" fontWeight={600}>
              {computedBackupId}
            </Box>
          </Box>
          <CopyToClipboardIcon
            fontSize={16}
            text={computedBackupId as string}
          />
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginX: '8px',
            }}
          />
          <RecoveryPointCreationDate backup={backup} />
          <Typography marginX="5px" fontSize="14px">
            {customFormatDate(dayjs(backup.timestamp))})
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

function renderDirectScanSource(asset: Asset, scan: Scan) {
  if (!scan) {
    return null
  }

  const computedIcon = computeAssetIcon(asset)
  return (
    <Box display="flex" alignItems="center">
      <RowTitle title="Source" />
      <Box display="flex" alignItems="center" gap="5px">
        <Box
          display="flex"
          sx={{
            svg: {
              width: '24px',
              height: '24px',
            },
          }}
        >
          {computedIcon}
        </Box>
        <Typography variant="body2" component="span">
          Direct scan
        </Typography>
        <ParenthesizedDateTimeTooltip>
          <DateTimeTooltip
            date={scan?.completedAt?.getTime()}
            direction="row"
          />
        </ParenthesizedDateTimeTooltip>
      </Box>
    </Box>
  )
}

function ScanSource({ asset, backup, scan }: Props) {
  if (asset && scan) {
    return renderDirectScanSource(asset, scan)
  } else if (backup) {
    return renderBackupSource(backup)
  }

  return null
}

export { ScanSource }
