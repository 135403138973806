import { StyledInputBase } from '@components-simple/styled-form-components/styled-input-base'
import { Box, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { StyledInputAdornment } from '@features/reports/schedules/history/search-filter-bar/search-filter-bar'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { useAlertsFilterStore } from '@features/alerts/store/alerts-filter-store'
import useDebouncedFunction from '../../lib/hooks/useDebouncedFunction'

function AlertsFilter() {
  const { setRuleValue } = useAlertsFilterStore()
  const [searchValue, setSearchValue] = useState('')

  const handleRuleChange = (e: BaseSyntheticEvent) => {
    setSearchValue(e.target.value)
  }

  const submitDebounced = useDebouncedFunction(() => {
    setRuleValue(searchValue)
  }, 500)

  useEffect(() => {
    submitDebounced()
  }, [searchValue])

  return (
    <Box>
      <StyledInputBase
        value={searchValue}
        onChange={handleRuleChange}
        placeholder="Search Rules"
        endAdornment={
          <StyledInputAdornment position="end">
            <IconButton size="small">
              <SearchIcon />
            </IconButton>
          </StyledInputAdornment>
        }
        sx={{ width: '450px' }}
      />
    </Box>
  )
}

export default AlertsFilter
