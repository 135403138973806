import { Box, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CheckIcon from '@mui/icons-material/Check'
import { useAlertsListStore } from '@features/alerts/store/alerts-list-store'
import { useAlertsActions } from '@features/alerts/hooks/use-alerts-actions'
import { useCreateRuleDrawerActions } from '@features/alerts/create-rule-drawer'
import { useConfirmDeleteStore } from '@features/alerts/store/alerts-confirm-delete-modal-store'

function AlertsActions() {
  const { openDrawer } = useCreateRuleDrawerActions()
  const { selectedRows, tableData } = useAlertsListStore()
  const { setOpen, setRulesIds } = useConfirmDeleteStore()
  const { handleEnableRule, handleDisableRule, isLoading } = useAlertsActions()
  const tableIndexes = Object.keys(selectedRows)
  const isDisable = tableIndexes.length === 0 || isLoading
  const rulesIds = tableData
    .filter((_, index) => tableIndexes.includes(index.toString()))
    .map(({ id }) => id)

  const handleDelete = () => {
    setRulesIds(rulesIds)
    setOpen(true)
  }

  const handleEnable = () => {
    handleEnableRule(rulesIds)
  }

  const handleDisable = () => {
    handleDisableRule(rulesIds)
  }

  return (
    <Box display="flex" gap={1}>
      <Button variant="contained" color="primary" onClick={() => openDrawer()}>
        Create Rule
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CheckIcon />}
        disabled={isDisable}
        onClick={handleEnable}
      >
        Enable
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloseIcon />}
        disabled={isDisable}
        onClick={handleDisable}
      >
        Disable
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<DeleteOutlineIcon />}
        disabled={isDisable}
        onClick={handleDelete}
      >
        Delete
      </Button>
    </Box>
  )
}

export default AlertsActions
