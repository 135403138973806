import MultiSelectChip from 'ui-v2/src/components/ui/inputs/multi-select-chip'
import { useListUsersQuery } from 'ui-v2/src/hooks/queries/users'

const Recipients = ({
  value,
  helperText,
  error,
  onChange,
}: RecipientsProps) => {
  const { data: recipients } = useListUsersQuery()
  const options = recipients?.userProfilesList?.map((user) => ({
    label: user.email,
    value: user.id,
  }))

  const sortedRecipientsByLabel = options?.sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  return (
    <MultiSelectChip
      size="small"
      options={sortedRecipientsByLabel ?? []}
      selectedOptions={value}
      placeholder="Select"
      setSelectedOptions={onChange}
      helperText={helperText}
      error={!!error}
    />
  )
}
interface RecipientsProps {
  value: Array<string>
  helperText?: string
  error?: boolean
  onChange: (options: Array<string>) => void
}
export default Recipients
