import { ObjHelper } from '@lib/helpers'
import { useContext, useEffect } from 'react'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import {
  EntropyTrendsDetailsState,
  EntropyTrendsDetailsStore,
  FetchEntropyTrendsHistoryParams,
} from './types'
import {
  ListAnomaliesFilter,
  ListAnomaliesPagination,
  ListAnomaliesRequestParams,
} from '@lib/clients/entropy-detection/list-anomalies'
import { EntropyDetectionClient } from '@lib/clients/entropy-detection'
import {
  EntropyFiltersContext,
  useEntropyFiltersActions,
} from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store.context'
import {
  EntropyTrendsTimeSelection,
  incrementEntropyTrendsTimeSelection,
} from '@features/DashboardV4/entropy-trends/types'
import { EntropyTrendsHistory } from '@lib/models/anomalies'
import { buildTimeRangeForRequest } from '@features/DashboardV4/entropy-trends/utils'

const INITIAL_STATE: Omit<EntropyTrendsDetailsState, 'filters'> = {
  tableData: [],
  entropyTrendsHistory: new EntropyTrendsHistory([]),
  isLoadingData: false,
}

const client = new EntropyDetectionClient()

async function fetchInitialEntropyTrendsHistory(
  filters: FetchEntropyTrendsHistoryParams
): Promise<[EntropyTrendsHistory, FetchEntropyTrendsHistoryParams]> {
  const entropyTrendsHistoryResponse =
    await client.getEntropyTrendsHistory(filters)

  if (entropyTrendsHistoryResponse.totalAnomalyFindingsCount > 0) {
    return [entropyTrendsHistoryResponse, filters]
  }

  const incrementedTimeSelection = incrementEntropyTrendsTimeSelection(
    filters.timeSelection
  )

  if (incrementedTimeSelection === filters.timeSelection) {
    return [entropyTrendsHistoryResponse, filters]
  }

  const newTimeRange = buildTimeRangeForRequest(
    incrementedTimeSelection,
    filters.startAt,
    filters.endAt
  )

  return fetchInitialEntropyTrendsHistory({
    ...filters,
    timeSelection: incrementedTimeSelection,
    startAt: newTimeRange.startDate,
    endAt: newTimeRange.endDate,
    timeGranularity: newTimeRange.timeGranularity,
  })
}

export type EntropyFiltersZustandStore = ReturnType<
  typeof createEntropyTrendsDetailsStore
>

export const createEntropyTrendsDetailsStore = (
  initStartAt: number,
  initEndAt: number,
  assetIdsList: Array<string>
) => {
  const initialTimerange = buildTimeRangeForRequest(
    EntropyTrendsTimeSelection.TODAY,
    initStartAt,
    initEndAt
  )

  return create<EntropyTrendsDetailsStore>()(
    subscribeWithSelector((set, get) => {
      return {
        ...INITIAL_STATE,
        filters: {
          assetIdsList,
          startAt: initStartAt,
          endAt: initEndAt,
          timeGranularity: initialTimerange.timeGranularity,
          timeSelection: EntropyTrendsTimeSelection.TODAY,
        },
        actions: {
          setFilters: (filters) =>
            set({
              filters: {
                ...get().filters,
                ...filters,
              },
            }),
          setIsLoadingData: (isLoadingData) =>
            set({
              isLoadingData,
            }),
          fetchInitialData: async (
            filter?: Partial<ListAnomaliesRequestParams>
          ) => {
            try {
              set({
                isLoadingData: true,
              })

              const combinedFilters = {
                ...get().filters,
                ...filter,
              }

              const { anomaliesList, pageToken } = await client.listAnomalies(
                new ListAnomaliesFilter(combinedFilters).toGrpc()
              )

              set({
                tableData: anomaliesList,
                filters: combinedFilters,
                pageToken,
              })
            } catch (error) {
              console.log(error)
            } finally {
              set({
                isLoadingData: false,
              })
            }
          },
          fetchMoreData: async () => {
            const pageToken = get().pageToken

            if (!pageToken) {
              return
            }

            try {
              set({
                isLoadingData: true,
              })

              const { anomaliesList, pageToken: newPageToken } =
                await client.listAnomalies(
                  new ListAnomaliesPagination(pageToken).toGrpc()
                )

              set({
                tableData: [...get().tableData, ...anomaliesList],
                pageToken: newPageToken,
              })
            } catch (error) {
              console.log(error)
            } finally {
              set({
                isLoadingData: false,
              })
            }
          },
          fetchInitialEntropyTrendsHistory: async (
            filters: Partial<FetchEntropyTrendsHistoryParams>
          ) => {
            set({
              isLoadingData: true,
            })

            const newFilters = {
              startAt: filters.startAt ?? get().filters.startAt,
              endAt: filters.endAt ?? get().filters.endAt,
              assetIdsList: filters.assetIdsList
                ? filters.assetIdsList
                : get().filters.assetIdsList,
              timeGranularity:
                filters.timeGranularity ?? get().filters.timeGranularity,
              timeSelection:
                filters.timeSelection ?? get().filters.timeSelection,
            }

            const entropyTrendsHistoryResponse =
              await fetchInitialEntropyTrendsHistory(newFilters)

            const { anomaliesList, pageToken } = await client.listAnomalies(
              new ListAnomaliesFilter({
                assetIdsList: newFilters.assetIdsList,
                startAt: newFilters.startAt,
                endAt: newFilters.endAt,
              }).toGrpc()
            )

            set({
              isLoadingData: false,
              entropyTrendsHistory: entropyTrendsHistoryResponse[0],
              filters: entropyTrendsHistoryResponse[1],
              tableData: anomaliesList,
              pageToken,
            })
          },
          fetchEntropyTrendsHistory: async (
            filters: Partial<FetchEntropyTrendsHistoryParams>
          ) => {
            set({
              isLoadingData: true,
            })
            const newFilters = {
              startAt: filters.startAt ?? get().filters.startAt,
              endAt: filters.endAt ?? get().filters.endAt,
              assetIdsList: filters.assetIdsList
                ? filters.assetIdsList
                : get().filters.assetIdsList,
              timeGranularity:
                filters.timeGranularity ?? get().filters.timeGranularity,
              timeSelection:
                filters.timeSelection ?? get().filters.timeSelection,
            }

            const entropyTrendsHistoryResponse =
              await client.getEntropyTrendsHistory(newFilters)

            set({
              isLoadingData: false,
              entropyTrendsHistory: entropyTrendsHistoryResponse,
              filters: newFilters,
            })
          },
        },
      }
    })
  )
}

export const useEntropyTrendsDetailsFilterSubscription = () => {
  const store = useContext(EntropyFiltersContext)

  const { fetchInitialData } = useEntropyFiltersActions()

  useEffect(() => {
    if (!store) {
      return
    }

    const unsubscribeFn = store.subscribe(
      ({ filters }) => {
        return filters
      },
      (filters) => {
        fetchInitialData(filters)
      },
      {
        equalityFn: (a, b) => {
          return ObjHelper.isEqual(a, b)
        },
      }
    )

    return () => {
      unsubscribeFn()
    }
  }, [])
}
