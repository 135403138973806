import AppShell from 'ui-v2/src/components/layout/app-shell'
import { useAuth0 } from '@auth0/auth0-react'
import { Outlet, useLocation } from 'react-router-dom'
import SiteLoader from '../common/site-loader'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

const RootLayout = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const location = useLocation()

  if (isLoading) {
    return <SiteLoader />
  }

  if (!isAuthenticated && !window.location.search.includes('code=')) {
    loginWithRedirect({
      appState: {
        returnTo: location.pathname || `/${BASE_ROUTE_SEGMENTS.DASHBOARD}`,
      },
    })
    return <SiteLoader />
  }

  return (
    <AppShell>
      <Outlet />
    </AppShell>
  )
}

export default RootLayout
