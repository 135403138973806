import InfoIconFilled from '@inline-img/general/info-icon-filled'
import { VIRow } from '@lib/engine-types'
import CopyCommandButton from '@components-composite/copy-command-button/CopyCommandButton'
import { CopyButtonVariant } from '@lib/constants/copy-command-button.constant'
import React from 'react'

export function getButtonsPopoverContent({
  header,
  message,
}: {
  header: string
  message: string
}) {
  return (
    <div className="infoPopoverMessage">
      {InfoIconFilled({ fill: '#32B6F3' })}
      <div className="infoPopoverText">
        {<h3>{header}</h3>}
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></div>
      </div>
    </div>
  )
}

export function getCommandButtonsRow({
  commandToastText,
  commands,
  disabledAll,
}: {
  commands: VIRow
  disabledAll: boolean
  commandToastText: string
}) {
  return commands.map((command) => (
    <CopyCommandButton
      key={command.type}
      commandText={command.name}
      variant={command.type ?? CopyButtonVariant.DEFAULT}
      disabled={command.disabled || disabledAll}
      toastText={commandToastText}
    />
  ))
}
