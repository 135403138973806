import { StyledSuppressionForm } from '@features/threat-suppression/styles'
import ThreatSuppressionInfo from '@features/threat-suppression/threat-suppression-info'
import ThreatSuppressionForm from '@features/threat-suppression/threat-suppression-form'
import BaseModal from '@components-composite/modals/BaseModal'
import React from 'react'
import { useThreatSuppressionStore } from '@features/threat-suppression/use-threat-suppression-modal-store'
import { EngineCallback } from '@lib/engine-types'

interface Props {
  fetchDataThreats: EngineCallback<void>
}

function ThreatSuppressionModal({ fetchDataThreats }: Props) {
  const { showModal, closeModal, setThreatsLoading, threatInfo } =
    useThreatSuppressionStore()
  const { threatName, threatLocation, firstDetectedAt } = threatInfo

  const handleAfterSuccess = async () => {
    setThreatsLoading(true)
    await fetchDataThreats()
    setThreatsLoading(false)
    closeModal()
  }

  return (
    <BaseModal
      show={showModal}
      onBgClick={closeModal}
      customStyles={{
        zIndex: 1400,
      }}
    >
      <StyledSuppressionForm>
        <ThreatSuppressionInfo
          threatName={threatName}
          location={threatLocation}
          firstDetectedAt={firstDetectedAt}
        />
        <ThreatSuppressionForm callbackAfterSuccess={handleAfterSuccess} />
      </StyledSuppressionForm>
    </BaseModal>
  )
}

export default ThreatSuppressionModal
