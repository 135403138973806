import Checkbox from 'ui-v2/src/components/ui/inputs/checkbox'
import { EngineCallback, ValueInterface } from 'ui-v2/src/lib/engine-types'
import { updateValueByIndex } from 'ui-v2/src/lib/helpers/value.helper'
import { Box, Card, FormControlLabel, FormGroup } from '@mui/material'
import { ChangeEvent, useState } from 'react'

const Scopes = ({
  behaviour = 'default',
  behaviourParams = {
    mainLabel: /write/i,
    subLabel: /read/i,
  },
  data,
  onChange,
  selectAllLabel,
  disabled = false,
}: ScopesProps) => {
  const [isSubCheckboxDisabled, setIsSubCheckboxDisabled] = useState(false)

  // eslint-disable-next-line no-unused-vars
  let isSubCheckboxDisabledFunc = (_: ValueInterface): boolean =>
    isSubCheckboxDisabled

  let onDataChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newData = updateValueByIndex(index, e.target.checked, data)
    onChange(newData)
  }

  if (behaviour === 'roles') {
    // reassign handlers
    isSubCheckboxDisabledFunc = (option: ValueInterface): boolean => {
      const isItReadCheckbox = !!behaviourParams.subLabel?.test(
        option.label ?? ''
      )
      return isItReadCheckbox && isSubCheckboxDisabled
    }
    onDataChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newData = updateValueByIndex(index, e.target.checked, data)
      const isChecked = e.target.checked
      // when we select 'write' - it select 'read' automatically and disabled it
      const isWrite = !!behaviourParams.mainLabel?.test(
        newData[index]?.label ?? ''
      )
      if (isWrite) {
        setIsSubCheckboxDisabled(isChecked)
        if (isChecked) {
          // if we found 'write' then we try to find 'read'
          const readIndex = newData.findIndex((nd) =>
            behaviourParams.subLabel?.test(nd?.label ?? '')
          )
          if (readIndex !== -1 && newData[readIndex]) {
            const elem = newData[readIndex] as ValueInterface
            elem.value = true
          }
        }
      }
      onChange(newData)
    }
  }

  // HELP DATA CALCULATION
  const enabledCheckboxes = data.filter((v) => !isSubCheckboxDisabledFunc(v))
  const selectedTotalEnabled = enabledCheckboxes.filter((v) => v.value).length
  const selectAllChecked = selectedTotalEnabled === enabledCheckboxes.length
  const selectAllIndeterminate =
    selectedTotalEnabled > 0 && selectedTotalEnabled < enabledCheckboxes.length

  const onChangeAll = (e: ChangeEvent<HTMLInputElement>) => {
    const newData = [...data].map((v) => ({
      ...v,
      value: e.target.checked,
      disabled: v.disabled,
    }))
    if (behaviour === 'roles') {
      setIsSubCheckboxDisabled(e.target.checked)
    }
    onChange(newData)
  }

  return (
    <Card
      sx={{
        paddingX: 2,
        paddingY: 1,
        bgcolor: 'background.paper',
      }}
    >
      <FormGroup>
        {selectAllLabel && (
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                name="selectAll"
                indeterminate={selectAllIndeterminate}
                checked={selectAllChecked}
                onChange={onChangeAll}
              />
            }
            label={selectAllLabel}
          />
        )}

        <Box sx={{ pl: 4 }}>
          <FormGroup>
            {data.map((v, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    className="jsSubCheckbox"
                    disabled={isSubCheckboxDisabledFunc(v)}
                    value={v.value}
                    name={v.name}
                    checked={Boolean(v.value)}
                    onChange={onDataChange(index)}
                  />
                }
                label={v.label ?? v.name}
              />
            ))}
          </FormGroup>
        </Box>
      </FormGroup>
    </Card>
  )
}
interface ScopesProps {
  behaviour?: 'default' | 'roles'
  behaviourParams?: {
    mainLabel?: RegExp
    subLabel?: RegExp
  }
  data: Array<ValueInterface>
  onChange: EngineCallback<Array<ValueInterface>>
  selectAllLabel?: string
  disabled?: boolean
}
export default Scopes
