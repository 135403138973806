import React, { Dispatch, memo, SetStateAction, useEffect } from 'react'
import ValueInterface from '@lib/interfaces/value.interface'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import {
  requestDisconnectSlackFromProfile,
  requestUserNotificationsSettings,
} from '@store/actions/user-notifications.action'
import NotificationsCheckboxBlock from '@components-composite/notifications-checkbox-block/NotificationsCheckboxBlock'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
//TODO: @polyakh change to useCustomFlags
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@lib/router'

interface Props {
  errors: {
    state: Array<ValueInterface>
    setState: Dispatch<SetStateAction<Array<ValueInterface>>>
  }
  warnings: {
    state: Array<ValueInterface>
    setState: Dispatch<SetStateAction<Array<ValueInterface>>>
  }
  success: {
    state: Array<ValueInterface>
    setState: Dispatch<SetStateAction<Array<ValueInterface>>>
  }
  slackUrl?: string
}

const getTextDependsByFlag = (updatedNotificationsPage: boolean) => {
  const titleForCheckboxGroups = updatedNotificationsPage
    ? 'Send Critical Alerts'
    : 'Alerts'
  const labelForCriticalCheckboxGroups = updatedNotificationsPage
    ? ''
    : 'Critical'
  const integrationTitle = updatedNotificationsPage ? 'Send to' : 'Integrations'

  return {
    titleForCheckboxGroups,
    labelForCriticalCheckboxGroups,
    integrationTitle,
  }
}

const AccountNotificationsComplex = ({
  errors,
  warnings,
  success,
  slackUrl,
}: Props) => {
  const dispatch = useDispatch()
  const { updatedNotificationsPage } = useFlags()
  const {
    integrationTitle,
    titleForCheckboxGroups,
    labelForCriticalCheckboxGroups,
  } = getTextDependsByFlag(updatedNotificationsPage)

  useEffect(() => {
    dispatch(requestUserNotificationsSettings())
  }, [])

  const isSlackEnabled = !Boolean(slackUrl)

  const notificationsLoading = usePreloader(
    PreloaderConstants.REQUEST_USER_NOTIFICATIONS_SETTINGS
  )

  const onClickHandler = () => {
    if (isSlackEnabled) {
      dispatch(requestDisconnectSlackFromProfile())
    }
  }

  const SlackIntegrationBlock = () => {
    if (isSlackEnabled) {
      return (
        <Button
          variant="contained"
          className="slack-button"
          disabled={notificationsLoading}
          onClick={onClickHandler}
        >
          Disconnect from Slack workspace
        </Button>
      )
    }

    return (
      <Link to={slackUrl || ''} target="_blank" rel="noopener noreferrer">
        <span>
          <Button
            variant="contained"
            className="slack-button"
            disabled={notificationsLoading}
          >
            Connect to Slack workspace
          </Button>
        </span>
      </Link>
    )
  }

  return (
    <Box className="wrap-1659954175617 wrap-1659965944983">
      <Box className="frequencyBlock">
        <Typography className="headerText">{titleForCheckboxGroups}</Typography>
        <Box className="checkboxes">
          <NotificationsCheckboxBlock
            text={labelForCriticalCheckboxGroups}
            data={errors.state}
            onChange={errors.setState}
          />
          {!updatedNotificationsPage && (
            <>
              <NotificationsCheckboxBlock
                text="Medium"
                data={warnings.state}
                onChange={warnings.setState}
              />
              <NotificationsCheckboxBlock
                text="Low"
                data={success.state}
                onChange={success.setState}
                className="infoNotificationBlock"
              />
            </>
          )}
        </Box>
      </Box>
      <Box className="integrationsBlock">
        <Typography className="headerText">{integrationTitle}</Typography>
        <SlackIntegrationBlock />
        {!isSlackEnabled && (
          <Typography className="description">
            This will create a Slack channel in your workspace
          </Typography>
        )}
      </Box>
      {/* Wait for being implemented by Backend */}
      {/* <DemoWrap>
        <Box className="optInBlock">
          <Typography className="headerText">Opt-in</Typography>
          <Typography className="receiveType">
            Please indicate what communication you'd like to receive
          </Typography>
          <CheckboxBlock data={optIn.state} onChange={optIn.setState} />
        </Box>
      </DemoWrap> */}
    </Box>
  )
}

export default memo(AccountNotificationsComplex)
