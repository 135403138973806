import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ListGenericHostFilters } from 'ui-v2/src/lib/models/assets'
import { AssetsFilters } from 'ui-v2/src/lib/models/assets/common'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'

export const useAssetsGenericHostFilters =
  (): AssetsFilters<ListGenericHostFilters> => {
    const [pageSize, setPageSize] = useState<number>(
      TABLE_ROWS_PER_PAGE_OPTIONS[0]
    )
    const [pageToken, setPageToken] = useState<string | undefined>(undefined)
    const [searchParams] = useSearchParams()

    useEffect(() => {
      setPageToken(undefined)
    }, [searchParams])

    return useMemo(() => {
      return {
        filters: {
          pageSize,
          pageToken,
          hostnameList: searchParams
            .get(ASSET_FILTERS_MAP.HOST)
            ?.split(',')
            .filter(Boolean),
        },
        setPageSize,
        setPageToken,
      }
    }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
  }
