import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import Typography from '@mui/material/Typography'
import { ButtonPrimary } from '@components/shared'
import {
  DrawerName,
  useDrawerActions,
  useDrawerState,
} from '@features/reports/schedules/use-drawer-store'
import {
  useIsFormValid,
  useReportSourceType,
  useTabsActions,
} from '@features/reports/schedules/use-data-store'
import { useReportScheduleActions } from './use-report-schedule-actions'
import {
  reportLabelMapper,
  ReportType,
} from '@features/reports/schedules/shared'

function DrawerActionHeader() {
  const { isEditing, saveAndClose } = useReportScheduleActions()
  const { closeDrawer } = useDrawerActions()
  const { setEditingItemId, setSelectedFormType } = useTabsActions()

  const isFormValid = useIsFormValid()

  const reportSourceType = useReportSourceType()

  const { data } = useDrawerState(DrawerName.GenerateReport)
  const actionButtonText = isEditing ? 'Modify' : 'Create'

  const actionTitle = isEditing
    ? 'Modify Report Schedule'
    : `Generate ${
        reportLabelMapper[reportSourceType as ReportType] ?? data?.label
      }`

  const handleClose = async () => {
    closeDrawer(DrawerName.GenerateReport)
    setSelectedFormType(undefined)
    setEditingItemId(undefined)
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={9}>
        <Typography fontSize="22px" fontWeight="600">
          {actionTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3} container justifyContent="flex-end">
        <Box display="flex" gap="5px">
          <ButtonPrimary onClick={saveAndClose} isDisabled={!isFormValid}>
            {actionButtonText}
          </ButtonPrimary>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DrawerActionHeader
