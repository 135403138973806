/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { Backup, RansomwareScan } from 'blues-corejs/dist'
import { Scan } from 'blues-corejs/dist/models/scans/scan'
import RansomwareIcon from '@inline-img/general/ransomware-icon'
import {
  useComputeRansomwareScanStatusByBackup,
  useComputeRansomwareStatusByScans,
} from './use-compute-scans-statuses'
import React from 'react'

const StyledBox = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  marginRight: '5px',
})

function RansomwareIconByScans({ scans }: { scans: Array<Scan> }) {
  const ransomwareScans = scans.filter(
    (scan): scan is RansomwareScan => scan instanceof RansomwareScan
  )

  const computedScanStatus = useComputeRansomwareStatusByScans(ransomwareScans)

  if (!computedScanStatus) {
    return null
  }

  const { tooltipText, color, fill } = computedScanStatus

  return (
    <Tooltip title={tooltipText} placement="top">
      <StyledBox bgcolor={color} data-testid="threat-icon">
        <RansomwareIcon width="24px" height="24px" fill={fill} />
      </StyledBox>
    </Tooltip>
  )
}

function RansomwareIconByBackup({ backup }: { backup: Backup }) {
  const computedStatus = useComputeRansomwareScanStatusByBackup(backup)

  return (
    <Tooltip title={computedStatus.tooltipText} placement="top">
      <StyledBox bgcolor={computedStatus.color} data-testid="threat-icon">
        <RansomwareIcon width="24px" height="24px" fill={computedStatus.fill} />
      </StyledBox>
    </Tooltip>
  )
}

interface RansomwareBlockProps {
  backup?: Backup
  scans?: Array<Scan>
}

export function RansomwareBlock({ backup, scans }: RansomwareBlockProps) {
  if (backup) {
    return <RansomwareIconByBackup backup={backup} />
  }

  if (scans) {
    return <RansomwareIconByScans scans={scans as Array<RansomwareScan>} />
  }

  return null
}
