import { create } from 'zustand'
// eslint-disable-next-line import/no-extraneous-dependencies
import { reportFactory } from 'blues-corejs/dist/tests/factories'
import { useShallow } from 'zustand/react/shallow'
import { DownloadGeneratedReportResponse } from '@lib/clients/reports/generated-reports/download-generated-report'
import { UpdateReportScheduleCriteria } from '@lib/clients/reports/schedules/update-report-schedule'

export enum TabName {
  overview = 'overview',
  report = 'report',
  history = 'history',
}

export interface GeneratedDownloadReportsInterface {
  [key: string]: DownloadGeneratedReportResponse
}

export interface TabDataState<T = any> {
  data?: T
  isLoading: boolean
  filterValues?: T
}

interface TabActions {
  fetchTabData: (tabName: TabName) => void
  setTabData: <T>(tabName: TabName, data: T) => void
  setLoading: (tabName: TabName, isLoading: boolean) => void
  deleteTabItemById: (itemId: string) => void
  setCurrentTab: (tabName: TabName) => void
  getTabItemById: (itemId: string) => any | undefined
  setEditingItemId: (id: string | undefined) => void
  getEditingItemId: () => string | undefined
  setDeletingItemId: (id: string | undefined) => void
  toggleRevalidate: (value: boolean) => void
  getDeletingItemId: () => string | undefined
  setIsFormValid: (isValid: boolean) => void
  getReportDataLength: () => number
  setReportDataLength: (value: number) => void
  updateTabItemById: (
    itemId: string,
    updates: Partial<UpdateReportScheduleCriteria>
  ) => void
  setReportSourceType: (kind: string | undefined) => void
  setSelectedFormType: (type: string | undefined) => void
  setTabFilterValues: <T>(tabName: TabName, filterValues: T) => void
  setGeneratedDownloadReports: (
    files: DownloadGeneratedReportResponse,
    id: string
  ) => void
  setFormData: (data: any) => void
}

export interface TabStore {
  currentTab: TabName
  isFormValid: boolean
  editingItemId: string | undefined
  deletingItemId: string | undefined
  reportDataLength: number
  reportSourceType: string | undefined
  isRevalidate: boolean
  selectedFormType: string | undefined
  formData: any
  tabs: {
    [key in TabName]?: TabDataState
  }
  generatedDownloadReports: GeneratedDownloadReportsInterface
}

class MockClient {
  async getMockData(): Promise<any> {
    return reportFactory.buildList(10)
  }
}

export const mockClient = new MockClient()

export const createTabStore = (client: any = mockClient) => {
  return create<TabStore & { actions: TabActions }>()((set, get) => ({
    tabs: {},
    isFormValid: false,
    editingItemId: undefined,
    currentTab: TabName.report,
    reportDataLength: 0,
    generatedDownloadReports: {},
    actions: {
      updateTabItemById: (
        itemId: string,
        updates: Partial<UpdateReportScheduleCriteria>
      ) => {
        set((state) => {
          const reportTab = state.tabs[TabName.report]?.data
          if (!Array.isArray(reportTab)) {
            return state
          }

          const updatedData = reportTab.map((item) => {
            if (item.id === itemId) {
              item.description = updates.description
              item.name = updates.name
              return item
            } else {
              return item
            }
          })

          return {
            tabs: {
              ...state.tabs,
              [TabName.report]: {
                ...(state.tabs[TabName.report] || {}),
                data: updatedData,
                isLoading: false,
              },
            },
          }
        })
      },

      setFormData: (data: FormData) => set({ formData: data }),
      setIsFormValid: (isValid) => set({ isFormValid: isValid }),
      fetchTabData: async (tabName: TabName) => {
        set((state) => ({
          tabs: {
            ...state.tabs,
            [tabName]: {
              ...(state.tabs[tabName] || {}),
              isLoading: true,
            },
          },
        }))

        try {
          const data = await client.getMockData(tabName)

          set((state) => ({
            tabs: {
              ...state.tabs,
              [tabName]: {
                ...(state.tabs[tabName] || {}),
                data,
                isLoading: false,
              },
            },
          }))
        } catch (error) {
          console.error(`Error fetching data for ${tabName}:`, error)
          set((state) => ({
            tabs: {
              ...state.tabs,
              [tabName]: {
                ...(state.tabs[tabName] || {}),
                isLoading: false,
              },
            },
          }))
        }
      },

      setReportDataLength: (value: number) => {
        set({ reportDataLength: value })
      },

      setCurrentTab: (tabName: TabName) => {
        set({ currentTab: tabName })
        const initialValidity = false
        set({ isFormValid: initialValidity })
      },

      toggleRevalidate: (value: boolean) => {
        set({ isRevalidate: value })
      },
      getCurrentTab: () => {
        return get().currentTab
      },

      setTabData: (tabName, data) => {
        set((state) => ({
          tabs: {
            ...state.tabs,
            [tabName]: {
              ...(state.tabs[tabName] || {}),
              data,
              isLoading: false,
            },
          },
        }))
      },

      setLoading: (tabName, isLoading) => {
        set((state) => ({
          tabs: {
            ...state.tabs,
            [tabName]: {
              ...(state.tabs[tabName] || {}),
              isLoading,
            },
          },
        }))
      },

      deleteTabItemById: (itemId: string) => {
        set((state) => {
          const reportTab = state.tabs[TabName.report]

          if (!reportTab || !Array.isArray(reportTab.data)) {
            console.warn(
              'Report tab does not exist or has no valid data array:',
              reportTab
            )
            return state
          }

          const index = reportTab.data.findIndex(
            (item: any) => item?.id === itemId
          )
          if (index === -1) {
            console.warn(
              `Item with id "${itemId}" not found in the report tab.`
            )
            return state
          }

          const updatedData = [...reportTab.data]
          updatedData.splice(index, 1)

          return {
            tabs: {
              ...state.tabs,
              [TabName.report]: {
                ...reportTab,
                data: updatedData,
              },
            },
          }
        })
      },
      // @ts-ignore
      setEditingItemId: (id: string) => set({ editingItemId: id }),
      // @ts-ignore
      setSelectedFormType: (type: string) => set({ selectedFormType: type }),

      setReportSourceType: (kind: string | undefined) =>
        set({ reportSourceType: kind }),

      getEditingItemId: () => {
        return get().editingItemId
      },

      // @ts-ignore
      setDeletingItemId: (id: string) => set({ deletingItemId: id }),

      getDeletingItemId: () => {
        return get().deletingItemId
      },

      getReportDataLength: () => {
        const { tabs } = get()
        const reportTab = tabs[TabName.report]
        if (reportTab && Array.isArray(reportTab.data)) {
          return reportTab.data.length
        }

        return 0
      },

      getTabItemById: (itemId: string) => {
        const { currentTab, tabs } = get()
        const tab = tabs[currentTab]
        if (!tab || !Array.isArray(tab.data)) {
          console.warn(`Tab "${currentTab}" does not exist or has no data.`)
          return undefined
        }

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const item = tab.data.find((item: any) => item.id === itemId)
        if (!item) {
          console.warn(
            `Item with id "${itemId}" not found in tab "${currentTab}".`
          )
          return undefined
        }
        return item
      },

      setTabFilterValues: (tabName: TabName, filterValues) => {
        set((state) => ({
          tabs: {
            ...state.tabs,
            [tabName]: {
              ...(state.tabs[tabName] || {}),
              filterValues,
            },
          },
        }))
      },
      setGeneratedDownloadReports: (
        files: DownloadGeneratedReportResponse,
        id: string
      ) => {
        set((state) => ({
          generatedDownloadReports: {
            ...state.generatedDownloadReports,
            [id]: files,
          },
        }))
      },
    },
  }))
}

export const useTabStore = createTabStore()

export const useReportData = () =>
  useTabStore((state) => {
    const reportTab = state.tabs[TabName.report]
    return reportTab?.data || []
  })

export const useTabsActions = () =>
  useTabStore(useShallow((state) => state.actions))

export const useIsFormValid = () => useTabStore((state) => state.isFormValid)
export const useDeletingItemId = () =>
  useTabStore((state) => state.deletingItemId)

export const useReportSourceType = () =>
  useTabStore((state) => state.reportSourceType)

export const useReportDataLength = () =>
  useTabStore((state) => state.reportDataLength)

export const useEditingItemId = () =>
  useTabStore((state) => state.editingItemId)

export const useSelectedFormType = () =>
  useTabStore((state) => state.selectedFormType)

export const useEditingItem = () =>
  useTabStore((state) => {
    const { editingItemId } = state
    const currentTab = state.currentTab
    const tabData = state.tabs[currentTab]?.data || []

    if (!editingItemId) {
      return undefined
    }

    return Array.isArray(tabData)
      ? tabData.find((item: any) => item.id === editingItemId)
      : undefined
  })

// export function useTabs<K extends DrawerName>(drawerName: K) {
//     return tabStore(
//         useShallow((state) => state.drawers[drawerName] || { isOpen: false })
//     ) as DrawerState<any>
// }
