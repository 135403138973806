import React from 'react'

interface Props {
  fill?: string
}

export function MalwareIcon({ fill = 'FF7878' }: Props) {
  return (
    <svg
      data-testid="malware-icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19346_167966)">
        <path
          d="M23.5834 11.6666H21.6225C21.135 10.8216 20.4634 10.0958 19.6509 9.54328L20.6584 8.53578C21.0809 8.11328 21.0809 7.43078 20.6584 7.00828C20.2359 6.58578 19.5534 6.58578 19.1309 7.00828L17.5384 8.60078C17.04 8.48161 16.5309 8.41661 16 8.41661C15.4692 8.41661 14.96 8.48161 14.4725 8.60078L12.8692 7.00828C12.4467 6.58578 11.7642 6.58578 11.3417 7.00828C10.9192 7.43078 10.9192 8.11328 11.3417 8.53578L12.3384 9.54328C11.5367 10.0958 10.865 10.8216 10.3775 11.6666H8.41671C7.82087 11.6666 7.33337 12.1541 7.33337 12.7499C7.33337 13.3458 7.82087 13.8333 8.41671 13.8333H9.59754C9.54337 14.1908 9.50004 14.5483 9.50004 14.9166V15.9999H8.41671C7.82087 15.9999 7.33337 16.4874 7.33337 17.0833C7.33337 17.6791 7.82087 18.1666 8.41671 18.1666H9.50004V19.2499C9.50004 19.6183 9.54337 19.9758 9.59754 20.3333H8.41671C7.82087 20.3333 7.33337 20.8208 7.33337 21.4166C7.33337 22.0124 7.82087 22.4999 8.41671 22.4999H10.3775C11.5042 24.4391 13.595 25.7499 16 25.7499C18.405 25.7499 20.4959 24.4391 21.6225 22.4999H23.5834C24.1792 22.4999 24.6667 22.0124 24.6667 21.4166C24.6667 20.8208 24.1792 20.3333 23.5834 20.3333H22.4025C22.4567 19.9758 22.5 19.6183 22.5 19.2499V18.1666H23.5834C24.1792 18.1666 24.6667 17.6791 24.6667 17.0833C24.6667 16.4874 24.1792 15.9999 23.5834 15.9999H22.5V14.9166C22.5 14.5483 22.4567 14.1908 22.4025 13.8333H23.5834C24.1792 13.8333 24.6667 13.3458 24.6667 12.7499C24.6667 12.1541 24.1792 11.6666 23.5834 11.6666ZM17.0834 20.3333H14.9167C14.3209 20.3333 13.8334 19.8458 13.8334 19.2499C13.8334 18.6541 14.3209 18.1666 14.9167 18.1666H17.0834C17.6792 18.1666 18.1667 18.6541 18.1667 19.2499C18.1667 19.8458 17.6792 20.3333 17.0834 20.3333ZM17.0834 15.9999H14.9167C14.3209 15.9999 13.8334 15.5124 13.8334 14.9166C13.8334 14.3208 14.3209 13.8333 14.9167 13.8333H17.0834C17.6792 13.8333 18.1667 14.3208 18.1667 14.9166C18.1667 15.5124 17.6792 15.9999 17.0834 15.9999Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_19346_167966">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
