/* eslint-disable import/no-extraneous-dependencies */
import { Button } from '@mui/material'
import React from 'react'
import { Row } from '@tanstack/react-table'
import { S3Bucket, EFS } from 'blues-corejs/dist'

interface Props {
  row: Row<any>
  asset: S3Bucket | EFS
  handleSelectPathsClick: (id: string, rowIndex: number) => void
}

export function PolicySelectPath({
  row,
  asset,
  handleSelectPathsClick,
}: Props) {
  const { index } = row
  const handleSelectPath = () => {
    handleSelectPathsClick(asset.id, index)
  }

  return <Button onClick={handleSelectPath}>Select paths ...</Button>
}
