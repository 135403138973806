function CheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.83366 11.4993L7.02116 9.68685C6.86838 9.53407 6.68088 9.45768 6.45866 9.45768C6.23644 9.45768 6.04199 9.54102 5.87533 9.70768C5.72255 9.86046 5.64616 10.0549 5.64616 10.291C5.64616 10.5271 5.72255 10.7216 5.87533 10.8743L8.25033 13.2493C8.4031 13.4021 8.59755 13.4785 8.83366 13.4785C9.06977 13.4785 9.26421 13.4021 9.41699 13.2493L14.1462 8.52018C14.2989 8.3674 14.3753 8.1799 14.3753 7.95768C14.3753 7.73546 14.292 7.54102 14.1253 7.37435C13.9725 7.22157 13.7781 7.14518 13.542 7.14518C13.3059 7.14518 13.1114 7.22157 12.9587 7.37435L8.83366 11.4993ZM10.0003 18.3327C8.84755 18.3327 7.76421 18.1138 6.75033 17.676C5.73644 17.2388 4.85449 16.6452 4.10449 15.8952C3.35449 15.1452 2.76088 14.2632 2.32366 13.2493C1.88588 12.2355 1.66699 11.1521 1.66699 9.99935C1.66699 8.84657 1.88588 7.76324 2.32366 6.74935C2.76088 5.73546 3.35449 4.85352 4.10449 4.10352C4.85449 3.35352 5.73644 2.75963 6.75033 2.32185C7.76421 1.88463 8.84755 1.66602 10.0003 1.66602C11.1531 1.66602 12.2364 1.88463 13.2503 2.32185C14.2642 2.75963 15.1462 3.35352 15.8962 4.10352C16.6462 4.85352 17.2398 5.73546 17.677 6.74935C18.1148 7.76324 18.3337 8.84657 18.3337 9.99935C18.3337 11.1521 18.1148 12.2355 17.677 13.2493C17.2398 14.2632 16.6462 15.1452 15.8962 15.8952C15.1462 16.6452 14.2642 17.2388 13.2503 17.676C12.2364 18.1138 11.1531 18.3327 10.0003 18.3327Z"
        fill="#70D180"
      />
    </svg>
  )
}

export default CheckCircle
