import { VIMatrix } from 'ui-v2/src/lib/engine-types'

import { dataGroupedByAwsAccount } from 'ui-v2/src/lib/helpers/data.helper'
import { RedStackModel } from '../base-model'
import VaultModel from '../settings/policies/vault.model'
import RedStackStatusConstant from 'ui-v2/src/lib/constants/red-stack-status.constant'
import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
import AccountStatusConstant from 'ui-v2/src/lib/constants/account-status.constant'

export const enabledSourcesVI = (
  rsArr: Array<RedStackModel>,
  vaults: Array<VaultModel>
): VIMatrix => {
  // grouping by status "0", "1", "3"
  const connectAndDisconnectSources = rsArr.filter(
    (row: RedStackModel) =>
      row.isActive ||
      row.isInactive ||
      row.status === RedStackStatusConstant.UPGRADE_REQUIRED
  )
  const groupedByAwsAccount: Record<string, any> = dataGroupedByAwsAccount(
    connectAndDisconnectSources
  )
  // add count of assetsCount
  vaults.forEach((rowVault: VaultModel) => {
    if (groupedByAwsAccount[rowVault.accountId]) {
      groupedByAwsAccount[rowVault.accountId].assetsCount +=
        rowVault.assetsCount
    }
  })
  const uniqueEnabledSources = [...Object.values(groupedByAwsAccount)]
  return uniqueEnabledSources.map((row: any) => {
    // general account status and label
    let activeRSCount = 0
    let inactiveRSCount = 0
    row.statuses.forEach((value: number) => {
      if (value === RedStackStatusConstant.ACTIVE) {
        activeRSCount += 1
      }
      if (value === RedStackStatusConstant.INACTIVE) {
        inactiveRSCount += 1
      }
    })
    let accountStatusValue = AccountStatusConstant.MIXED

    if (activeRSCount === 0) {
      accountStatusValue = AccountStatusConstant.DISCONNECTED
    }
    if (inactiveRSCount === 0) {
      accountStatusValue = AccountStatusConstant.CONNECTED
    }
    if (row.cfnUpgradeRequired) {
      accountStatusValue = AccountStatusConstant.UPGRADE_REQUIRED
    }

    // fill message
    const messages: Array<string> = []
    if (
      accountStatusValue === AccountStatusConstant.DISCONNECTED ||
      accountStatusValue === AccountStatusConstant.MIXED
    ) {
      row.awsRegions.forEach((region: string, index: number) => {
        let messageValue = ''
        if (row.statuses[index] === RedStackStatusConstant.ACTIVE) {
          messageValue = 'Success'
        }
        if (row.statuses[index] === RedStackStatusConstant.INACTIVE) {
          messageValue = 'Failed'
        }
        messageValue = `${messageValue} - ${region}`
        if (row.lastErrorMessages[index]) {
          messageValue += `: "${row.lastErrorMessages[index]}"`
        }
        messages.push(messageValue)
      })
    }

    return [
      // unprotected
      {
        name: '',
        type: accountStatusValue,
        messages,
        label: row.lastWarningMessage,
      },
      // id
      {
        name: row.awsAccount,
      },
      // name
      {
        name: row.accountAlias,
      },
      // description
      {
        name: row.accountDescription,
      },
      // region
      {
        name: row.awsRegions
          .map((regionName: string) =>
            LangHelper.getAwsRegionSingleTranslation(regionName)
          )
          .join(', '),
      },
      {
        name: '',
        type: accountStatusValue,
      },
    ]
  })
}
