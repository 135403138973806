import React from 'react'
import { TextConstant } from '@lib/constants'
import { Button } from '@mui/material'
import { getCommandButtonsRow, getButtonsPopoverContent } from '../utilities'
import { POPOVER_CONTENT } from './consts'
import ValueInterface from '@lib/interfaces/value.interface'
import PopoverIcon from '@components-composite/popover-icon/PopoverIcon'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'

interface Props {
  onCancelClick: () => void
  commandsData: Array<ValueInterface>
  shouldShowPopover: boolean
}

function PortalMountButtons({
  onCancelClick,
  shouldShowPopover,
  commandsData,
}: Props) {
  return (
    <BasePortalButtons alwaysInPortal>
      <div className="flexRowBetween w100">
        <Button
          data-testid="jsCancelMountModal"
          className="showButton sizeS"
          type="button"
          variant="text"
          color="primary"
          onClick={onCancelClick}
        >
          Cancel
        </Button>

        <PopoverIcon
          showOnParentTop
          shouldShow={shouldShowPopover}
          parent={
            <div className="copyButtonsRow">
              {getCommandButtonsRow({
                commands: commandsData,
                disabledAll: shouldShowPopover,
                commandToastText: TextConstant.CLI_COMMAND_MOUNT_TOAST,
              })}
            </div>
          }
          children={getButtonsPopoverContent(POPOVER_CONTENT)}
        />
      </div>
    </BasePortalButtons>
  )
}

export { PortalMountButtons }
