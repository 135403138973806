export function getFirstNestedKey<T>(obj: T, targetKey: string) {
  if (!obj || typeof obj !== 'object') {
    return undefined
  }

  const queue: Array<{ currentObj: any }> = [{ currentObj: obj }]

  while (queue.length > 0) {
    const { currentObj } = queue.shift()!

    for (const key in currentObj) {
      if (
        key === targetKey &&
        typeof currentObj[key] === 'object' &&
        currentObj[key] !== null
      ) {
        const nestedKeys = Object.keys(currentObj[key])
        return nestedKeys.length > 0 ? nestedKeys[0] : undefined
      }

      if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
        queue.push({ currentObj: currentObj[key] })
      }
    }
  }

  return undefined
}
