/* eslint-disable import/no-extraneous-dependencies */
import {
  Backup,
  BackupPoliciesArray,
  EFS,
  Nullable,
  Tag,
  Threat,
} from 'blues-corejs/dist'
import { PolicyCoverageState } from '../common'
import { Scan } from '../../scans'
import { EFS_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { ListEfsRequest } from 'ui-v2/src/lib/grpc'

export interface ListEfsFilters {
  assetIdList?: Array<string>
  tagsList?: Array<Tag>
  protectionPolicyCoverageStatesList?: Array<PolicyCoverageState>
  filesystemStatesList: Array<ListEfsRequest.Initial.Filter.FilesystemState>
  search?: string
  pageSize?: number
  pageToken?: string
  accountIdsList?: Array<string>
  regionsList?: Array<string>
}

export interface AssetsEfsAttrs {
  filesystems: Array<EFS>
  scans: Array<Scan>
  lastBackups: Map<string, Backup>
  threats: Array<Threat>
}

export class EfsTableRow {
  readonly #id: string

  readonly #name: string

  readonly #awsId: string

  readonly #backupPolicies: BackupPoliciesArray

  readonly #lastBackupDate: Nullable<Date>

  readonly #lastScanDate: Nullable<Date>

  readonly #isUnhealthy: boolean

  constructor(parameters: EfsTableRowAttrs) {
    const {
      id,
      name,
      awsId,
      backupPolicies,
      lastBackupDate,
      lastScanDate,
      isUnhealthy,
    } = parameters

    this.#id = id
    this.#name = name
    this.#awsId = awsId
    this.#backupPolicies = backupPolicies
    this.#lastBackupDate = lastBackupDate
    this.#lastScanDate = lastScanDate
    this.#isUnhealthy = isUnhealthy
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get awsId(): string {
    return this.#awsId
  }

  get backupPolicies(): BackupPoliciesArray {
    return this.#backupPolicies
  }

  get lastBackupDate(): Nullable<Date> {
    return this.#lastBackupDate
  }

  get lastScanDate(): Nullable<Date> {
    return this.#lastScanDate
  }

  get isUnhealthy(): boolean {
    return this.#isUnhealthy
  }
}

interface EfsTableRowAttrs {
  id: string
  name: string
  awsId: string
  lastBackupDate: Nullable<Date>
  lastScanDate: Nullable<Date>
  backupPolicies: BackupPoliciesArray
  isUnhealthy: boolean
}

export class EfsDrawer {
  readonly #id: string

  readonly #name: string

  readonly #awsId: string

  readonly #awsAccountId: string

  readonly #awsRegion: string

  readonly #lastBackup: Nullable<Backup>

  readonly #lastScan: Nullable<Scan>

  readonly #isUnhealthy: boolean

  readonly #state: (typeof EFS_STATE_MAP)[keyof typeof EFS_STATE_MAP]

  readonly assetKind: string = 'EFS Filesystem'

  constructor(parameters: EfsDrawerAttrs) {
    const {
      id,
      name,
      awsId,
      awsAccountId,
      awsRegion,
      lastBackup,
      lastScan,
      isUnhealthy,
      state,
    } = parameters

    this.#id = id
    this.#name = name
    this.#awsId = awsId
    this.#awsAccountId = awsAccountId
    this.#awsRegion = awsRegion
    this.#lastBackup = lastBackup
    this.#lastScan = lastScan
    this.#isUnhealthy = isUnhealthy
    this.#state = state
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get awsId(): string {
    return this.#awsId
  }

  get awsAccountId(): string {
    return this.#awsAccountId
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }

  get isUnhealthy(): boolean {
    return this.#isUnhealthy
  }

  get state(): (typeof EFS_STATE_MAP)[keyof typeof EFS_STATE_MAP] {
    return this.#state
  }
}

interface EfsDrawerAttrs {
  id: string
  name: string
  awsId: string
  awsAccountId: string
  awsRegion: string
  lastBackup: Nullable<Backup>
  lastScan: Nullable<Scan>
  isUnhealthy: boolean
  state: (typeof EFS_STATE_MAP)[keyof typeof EFS_STATE_MAP]
}
