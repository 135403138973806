/* eslint-disable import/no-extraneous-dependencies */
import {
  HistoryTableDataAttrs,
  HistoryTableRow,
} from 'ui-v2/src/lib/models/reports'
import { OnDemandOrigin } from 'blues-corejs/dist/models/reports/report-generated/origin'
import { ReportGenerated } from 'blues-corejs/dist/models/reports/report-generated/report-generated'
import { ReportGeneratedCompleted } from 'blues-corejs/dist/models/reports/report-generated/status'

const ON_DEMAND = 'On Demand'

export class HistoryTableData {
  readonly #generatedReports: Array<ReportGenerated>

  constructor(parameters: HistoryTableDataAttrs) {
    this.#generatedReports = parameters.generatedReports
  }

  getGeneratedReportsData = (): Array<HistoryTableRow> => {
    return this.#generatedReports.map((report) => {
      let scheduleName
      if (report.origin instanceof OnDemandOrigin) {
        scheduleName = ON_DEMAND
      } else {
        scheduleName = report.origin.name
      }
      const readableReport = report.getReadableReportType()

      const { reportKindWithParams, status, createdAt } = report
      const startDate = reportKindWithParams?.startAt
      const endDate = reportKindWithParams?.endDate

      let date = createdAt
      if (status instanceof ReportGeneratedCompleted) {
        date = status.generatedAt
      }

      return new HistoryTableRow({
        id: report.id,
        scheduleName: scheduleName,
        description: report.origin.description,
        reportType: readableReport,
        startDate: startDate,
        endDate: endDate,
        generatedDate: date,
        status: status,
        rangeOfData: '',
        generated: '',
        action: '',
      })
    })
  }
}
