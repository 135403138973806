export enum EntropyTrendsTimeSelection {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_DATE = 'Custom date',
}

export function incrementEntropyTrendsTimeSelection(
  selection: EntropyTrendsTimeSelection
): EntropyTrendsTimeSelection {
  switch (selection) {
    case EntropyTrendsTimeSelection.TODAY:
      return EntropyTrendsTimeSelection.LAST_7_DAYS
    case EntropyTrendsTimeSelection.LAST_7_DAYS:
    case EntropyTrendsTimeSelection.LAST_30_DAYS:
      return EntropyTrendsTimeSelection.LAST_30_DAYS
    case EntropyTrendsTimeSelection.CUSTOM_DATE:
      return EntropyTrendsTimeSelection.CUSTOM_DATE
  }
}
