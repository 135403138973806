import { Chip, ChipOwnProps, styled } from '@mui/material'

interface StatusChipProps extends ChipOwnProps {
  type: 'error' | 'success' | 'warning' | 'disabled'
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: StatusChipProps['type'] }>(({ theme, type }) => {
  const getTypeStyles = () => {
    const styles = {
      success: {
        backgroundColor: theme.palette.success.dark,
        borderColor: theme.palette.success.main,
      },
      error: {
        backgroundColor: theme.palette.error.dark,
        borderColor: theme.palette.error.main,
      },
      warning: {
        backgroundColor: theme.palette.warning.dark,
        borderColor: theme.palette.warning.main,
      },
      disabled: {
        backgroundColor: theme.palette.grey[600],
        borderColor: theme.palette.grey[300],
      },
    }
    return styles[type] || styles.success
  }

  return {
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    border: '2px solid',
    height: '1.25rem',
    minWidth: '3.25rem',
    fontSize: '0.625rem',
    fontWeight: 700,
    '& .MuiChip-label': {
      padding: 0,
    },
    ...getTypeStyles(),
  }
})

const defaultLabels = {
  success: 'Clean',
  error: 'Infected',
  warning: 'Warning',
  disabled: 'Disabled',
}

const StatusChip: React.FC<StatusChipProps> = ({
  type = 'success',
  label,
  ...props
}) => {
  return (
    <StyledChip label={label || defaultLabels[type]} type={type} {...props} />
  )
}

export default StatusChip
