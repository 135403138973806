import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { AssetPageWrapper } from '@features/asset-page'
import { AssetRoutes } from './asset'

export const assetRoutes: Array<PathRouteProps> = [
  {
    path: AssetRoutes.assetIdPlaceholder,
    element: (
      <MainTopBarLayout title="Asset">
        <AssetPageWrapper />
      </MainTopBarLayout>
    ),
  },
]
