import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

interface ControlledInputOwnProps
  extends Omit<OutlinedInputProps, 'name' | 'placeholder'> {
  name: string
  placeholder: string
}

function ControlledInput({
  name,
  placeholder,
  ...restProps
}: ControlledInputOwnProps) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        return (
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              {...field}
              fullWidth
              name={name}
              size="small"
              margin="dense"
              placeholder={placeholder}
              {...restProps}
            />
            {error && (
              <Typography color="error" fontSize="12px">
                {error.message}
              </Typography>
            )}
          </FormControl>
        )
      }}
    />
  )
}

export default ControlledInput
