/* eslint-disable import/no-extraneous-dependencies */
import { S3BucketThreatsOutput } from 'blues-corejs/dist/use_cases/list_s3buckets_with_threats/types'

/**
 * Sorts to last scan first, if no last scan, sort by bucket name.
 */
export function initialPrioritySortForS3Table(
  buckets: Array<S3BucketThreatsOutput>
) {
  return buckets.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const bucketAName = a.name || a.s3AwsId
    const bucketBName = b.name || b.s3AwsId

    return bucketAName.localeCompare(bucketBName)
  })
}
