import React, { useEffect } from 'react'
import AccountIdForm from '@forms/account-id/account-id.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { useDispatch, useSelector } from 'react-redux'
import { getCFLink, getCfn } from '@store/selectors/rex.selector'
import clsx from 'clsx'
import DataHelper from '@lib/helpers/data.helper'
import ActivatedIcon from '@inline-img/general/region-status/activated-icon'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import {
  requestCFLink,
  requestCfnShadow,
  setCfn,
} from '@store/actions/rex.action'
import useIntervalIf from '@lib/hooks/useIntervalIf'
import PagePathConstant from '@lib/constants/page-path.constant'
import CfnFactory from '@lib/factories/cfn.factory'
import { CfnStatusConstant } from '@lib/constants/cfn-status.constant'
import CfnModel from '@lib/models/cfn.model'
import StrHelper from '@lib/helpers/str.helper'
import { EmptyFunc } from '@lib/constants/app.constant'
import CopyRow from '@components-simple/copy-row/CopyRow'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import AwsLogoIcon from '@inline-img/general/aws-logo-icon'
import { Button } from '@mui/material'
import DeployInformationBlock from '@components-simple/deployInformationBlock/DeployInformationBlock'
import SettingsService from '@lib/services/high/settings.service'
import { UserSettings } from '@lib/constants/settings.constant'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { useLayoutContext } from '@features/contexts'
import { useSearchParams } from 'react-router-dom'
import { SourcesRoutes, useNavigation } from '@lib/router'

function CloudConfigureUpgrade() {
  const dispatch = useDispatch()
  const router = useNavigation()
  const [searchParams] = useSearchParams()
  const { setBreadcrumbsPaths, setHeaderTitle } = useLayoutContext()

  const cfLink = useSelector(getCFLink)
  const cfnModel: CfnModel = useSelector(getCfn)

  const prefilledAccountId = StrHelper.base64Decode(searchParams.get('id'))
  const defaultAccountParams = {
    accountId: prefilledAccountId,
  }

  const lastHandledAccountId = prefilledAccountId

  useEffect(() => {
    // reset
    dispatch(setCfn(CfnFactory.buildEmpty()))
    // request
    dispatch(requestCFLink())
    setBreadcrumbsPaths([
      {
        href: PagePathConstant.SOURCES,
        text: titlesForBreadcrumb(PagePathConstant.SOURCES) ?? '',
      },
    ])
    setHeaderTitle('Upgrade Elastio')

    return () => {
      setHeaderTitle(null)
    }
  }, [])

  // hidden request in order to detect CFN
  useIntervalIf(
    CfnStatusConstant.INSTALLED !== cfnModel.status,
    () => {
      dispatch(requestCfnShadow(lastHandledAccountId))
    },
    [lastHandledAccountId],
    40000
  )

  const generalLoading = usePreloaderAny([PreloaderConstants.REQUEST_CF_LINK])

  const loadSourcesPage = () => {
    const alreadyKnownCfnUpgrade: Array<string> =
      SettingsService.getSetting(UserSettings.CfnUpgrade) ?? []
    const arrWithoutUpgradedAccount = alreadyKnownCfnUpgrade.filter(
      (account) => account !== lastHandledAccountId
    )

    SettingsService.updateSetting(
      UserSettings.CfnUpgrade,
      arrWithoutUpgradedAccount
    )
    router.push(SourcesRoutes.root)
  }

  return (
    <div className="ccdLayout">
      <div className="wrap-1626616534577 jsCloudConfigureUpgrade">
        <div className="depMainBlock">
          <div className="depPlate">
            <div className="depPlateBlock jsStep1">
              {/*STEP 1*/}
              <div className="depPlateBlockTitle">
                Step 1.{' '}
                <span className="depPlateBlockTitleBold jsCloudInstallerText">
                  Enter your AWS account ID
                </span>
                <PreloaderSmall
                  right="0"
                  top="0"
                  show={generalLoading || cfnModel.isEmpty}
                />
              </div>
              <div className="depPlateBlockDesc">
                Enter your AWS Account ID to complete the upgrade of Elastio.
              </div>

              <div className="depAccountInfoContainer">
                <div>
                  <div className="mb10">
                    <AwsLogoIcon />
                  </div>
                  <AccountIdForm
                    loading={generalLoading}
                    errorGroups={[ErrorGroupConstants.REX]}
                    onSubmit={EmptyFunc}
                    defaultValues={defaultAccountParams}
                    blockIamRoleShowing
                    isDisabled={true}
                  />
                </div>
                <DeployInformationBlock />
              </div>
            </div>

            {/*STEP 2*/}
            <div
              className={clsx('depPlateBlock jsStep2', {
                depPlateBlockDisabled: cfnModel.isEmpty,
                depPlateBlockSuccess:
                  CfnStatusConstant.INSTALLED === cfnModel.status,
              })}
            >
              <div className="depPlateBlockTitle">
                Step 2.{' '}
                {CfnStatusConstant.INSTALLED === cfnModel.status ? (
                  <span className="depPlateBlockTitleBold">
                    Your Elastio CloudFormation stack is up to date.
                  </span>
                ) : (
                  <span className="depPlateBlockTitleBold">
                    Upgrade Elastio CloudFormation stack in your AWS account.
                  </span>
                )}
                <div className="depPlateSuccessIcon">
                  <ActivatedIcon />
                </div>
              </div>

              {CfnStatusConstant.UPDATING === cfnModel.status && (
                <>
                  <div className="depPlateBlockDesc">
                    The CloudFormation template is updating and will take a few
                    minutes.
                    <div className="relative">
                      <PreloaderSmall right="0" top="-10" show />
                    </div>
                  </div>
                </>
              )}

              {CfnStatusConstant.INSTALLING === cfnModel.status && (
                <>
                  <div className="depPlateBlockDesc">
                    Now the CFN is in a "INSTALLING" status
                    <div className="relative">
                      <PreloaderSmall right="0" top="-10" show />
                    </div>
                  </div>
                </>
              )}

              {CfnStatusConstant.UPDATEREQUIRED === cfnModel.status && (
                <>
                  <div className="depPlateBlockDesc">
                    The CFN installed in the account is outdated. There is a new
                    CloudFormation stack available. Upgrade CFN by pressing the
                    button below.
                  </div>
                  <div className="depPlateContentWrap">
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      className={clsx({
                        contentDisabled: cfLink === '',
                      })}
                      href={DataHelper.buildCfnUpdatingLink(cfLink, cfnModel)}
                      rel="noreferrer"
                    >
                      Upgrade the CloudFormation stack
                    </Button>

                    {cfLink !== '' && cfnModel.region && (
                      <>
                        <div className="depPlateBlockDesc mt20">
                          Optionally you can copy the command below and run it
                          to upgrade CFN
                        </div>

                        <CopyRow
                          text={DataHelper.buildCfnUpdatingCommand(
                            cfLink,
                            cfnModel
                          )}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <BasePortalButtons alwaysInPortal>
          <div className="portalBetweenButtons">
            <Button
              onClick={loadSourcesPage}
              disabled={generalLoading}
              variant="text"
              color="primary"
              className="showButton jsCloseCloudInstallerPage"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                generalLoading ||
                CfnStatusConstant.INSTALLED !== cfnModel.status
              }
              onClick={loadSourcesPage}
              className="sizeL"
            >
              Done
            </Button>
          </div>
        </BasePortalButtons>
      </div>
    </div>
  )
}

export default CloudConfigureUpgrade
