/* eslint-disable import/no-extraneous-dependencies */
import { Pagination as PaginationRequest, ThreatsPromiseClient } from '../grpc'
import { Threat } from 'blues-corejs/dist'
import {
  Request as ThreatRequest,
  Response as ThreatResponse,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/threats/list_threats_for_asset_pb'

import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'
import { Nullable } from '../engine-types'
import { ThreatTransformer } from '../transformers/threats'
import { Pagination } from '../models/types'

import { ThreatSuppressionCriteria } from '@lib/clients/threats/threat-suppression'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { Request as RequestRemoveSuppressThreat } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/threats/remove_threat_suppression_pb'
import { Request as RequestSuppressThreat } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/threats/suppress_threat_pb'

export interface ListThreatsForAsset {
  threatsList: Array<Threat>
  pagination?: Pagination
}

export interface ThreatFilter {
  assetId: Array<string>
  includeAssetItems?: boolean
  statusList?: Array<number>
  pageSize: number
  pageToken?: string
}

function createTimestamp(seconds: number) {
  const timestamp = new Timestamp()
  timestamp.setSeconds(seconds)
  return timestamp
}

export interface RemoveThreatSuppressionCriteria {
  threatId: string
}

export class ThreatClient extends GrpcClient<ThreatsPromiseClient> {
  #threatsPromiseClient: ThreatsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#threatsPromiseClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'ThreatClient'
  }

  protected initClient(hostName = ''): ThreatsPromiseClient {
    return new ThreatsPromiseClient(hostName, null, null)
  }

  async listThreatsForAsset(
    threatFilter: ThreatFilter
  ): Promise<ListThreatsForAsset> {
    const request = new ThreatRequest()
    const initial = new ThreatRequest.Initial()
    const filter = new ThreatRequest.Initial.Filter()

    const {
      assetId,
      includeAssetItems = false,
      statusList,
      pageSize,
      pageToken,
    } = threatFilter

    filter.setIncludeAssetItems(includeAssetItems)

    if (statusList) {
      filter.setStatusList(statusList)
    }

    initial.setPageSize(pageSize)
    initial.setFilter(filter.setAssetIdList(assetId))

    request.setInitial(initial)

    if (pageToken) {
      request.setPagination(new PaginationRequest().setPageToken(pageToken))
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#threatsPromiseClient.listThreatsForAsset(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'ThreatsPromiseClient/listThreatsForAsset',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  async listThreats(
    assetId: Nullable<string>,
    threatIds: Array<string>
  ): Promise<ListThreatsForAsset> {
    const request = new ThreatRequest()
    const initial = new ThreatRequest.Initial()
    const filter = new ThreatRequest.Initial.Filter()

    filter.setThreatIdsList(threatIds)
    if (assetId) {
      filter.setAssetIdList([assetId])
    }
    initial.setPageSize(1000).setFilter(filter)
    request.setInitial(initial)

    const response = (
      await this.callGrpcService(
        () =>
          this.#threatsPromiseClient.listThreatsForAsset(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'ThreatsPromiseClient/listThreatsForAsset',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #transformResponse(response: ThreatResponse.AsObject): ListThreatsForAsset {
    return {
      threatsList: response.threatsList.map((threat) =>
        new ThreatTransformer(threat).transform()
      ),
      pagination: {
        pageToken: response.pagination?.nextPageToken,
        prevPageToken: response.pagination?.prevPageToken,
      },
    }
  }

  async threatSuppression({
    threatId,
    until,
    reason,
  }: ThreatSuppressionCriteria): Promise<void> {
    const request = new RequestSuppressThreat()
    request.setThreatId(threatId)
    request.setUntil(createTimestamp(until))
    request.setReason(reason)

    await this.callGrpcService(
      () =>
        this.#threatsPromiseClient.suppressThreat(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'ThreatsPromiseClient/suppressThreat',
      }
    )
  }

  async removeThreatSuppression({
    threatId,
  }: RemoveThreatSuppressionCriteria): Promise<void> {
    const request = new RequestRemoveSuppressThreat()
    request.setThreatId(threatId)

    await this.callGrpcService(
      () =>
        this.#threatsPromiseClient.removeThreatSuppression(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'ThreatsPromiseClient/suppressThreat',
      }
    )
  }
}
