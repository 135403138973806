/* eslint-disable import/no-extraneous-dependencies */
import { RuleOvaAccountProviders } from 'blues-corejs/dist/models/notifications/rule'
import { createColumn, Table } from '@components/table'
import { Box } from '@mui/material'
import React from 'react'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'
import { OvaBackupProvider } from 'blues-corejs/dist'

const getColumns = () => {
  return [
    createColumn<RuleOvaAccountProviders>('accountId', {
      header: 'Backups Account',
      meta: {
        width: '40%',
      },
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
    createColumn<RuleOvaAccountProviders>('provider', {
      header: 'Providers',
      meta: {
        width: '60%',
      },
      enableSorting: false,
      cell: ({ cell }) => {
        const value = cell.getValue() as unknown as OvaBackupProvider
        return HUMAN_READABLE_OVA_BACKUP_PROVIDER[value]
      },
    }),
  ]
}

export const computeOvaAccounts = (
  backupAccounts: Array<RuleOvaAccountProviders>
) => {
  if (backupAccounts.length === 0) {
    return null
  }

  return (
    <Box mb={2}>
      <Table
        data={backupAccounts}
        columns={getColumns()}
        generalProps={{
          noDataMessage: 'No accounts found',
          isLoading: false,
        }}
      />
    </Box>
  )
}
