import { create } from 'zustand'

import { getPreviousStep, getNextStep } from './stepper-logic'
import { Step, StepWithoutInfrastructure } from './constants'

interface StepperActions {
  goNext: () => void
  goPrev: () => void
  resetStore: () => void
  setHasStepWithoutNotifications: (value: boolean) => void
}

export interface StepperState {
  step: Step | StepWithoutInfrastructure
  hasStepWithoutNotifications: boolean
}

export type StepperStore = StepperActions & StepperState

const INITIAL_STATE: StepperState = {
  step: Step.RULE_DETAILS,
  hasStepWithoutNotifications: false,
}

const useStepperStore = create<StepperStore>((set, get) => ({
  ...INITIAL_STATE,
  goNext: () =>
    set((state) => {
      return {
        step: getNextStep(
          state.step,
          get().hasStepWithoutNotifications
            ? StepWithoutInfrastructure.REVIEW_ENABLE
            : Step.REVIEW_ENABLE
        ),
      }
    }),
  goPrev: () =>
    set((state) => ({
      step: getPreviousStep(state.step),
    })),
  resetStore: () => set({ ...INITIAL_STATE }),
  setHasStepWithoutNotifications: (value) =>
    set({
      hasStepWithoutNotifications: value,
    }),
}))

export const resetStepperStore = () => {
  useStepperStore.getState().resetStore()
}

export const setHasStepWithoutNotificationsStore = (value: boolean) => {
  useStepperStore.getState().setHasStepWithoutNotifications(value)
}

export const useStepperState = () =>
  useStepperStore((state) => ({
    step: state.step,
    hasStepWithoutNotifications: state.hasStepWithoutNotifications,
  }))

export const useStepperActions = () =>
  useStepperStore((state) => ({
    goNext: state.goNext,
    goPrev: state.goPrev,
    setHasStepWithoutNotifications: state.setHasStepWithoutNotifications,
  }))
