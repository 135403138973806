/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  NotificationsPromiseClient,
  ListEventKindsRequest,
} from '@lib/clients/grpc-imports'

const NOTIFICATIONS_CLIENT_NAME = 'NotificationsClient'

export class NotificationsClient extends GrpcClient<NotificationsPromiseClient> {
  #notificationsClient: NotificationsPromiseClient

  constructor(hostName = '') {
    super()
    this.#notificationsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return NOTIFICATIONS_CLIENT_NAME
  }

  protected initClient(hostName = ''): NotificationsPromiseClient {
    return new NotificationsPromiseClient(hostName, null, null)
  }

  async getListEventKinds(): Promise<Array<string>> {
    const request = new ListEventKindsRequest()

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#notificationsClient.listEventKinds(request, this.metadata()),
        {
          requestName: `${NOTIFICATIONS_CLIENT_NAME}/ListEventKinds`,
        }
      )
    ).getEventKindsList()

    return response
  }
}
