import { ListOvaServersClient } from '@lib/clients/assets/list-ova-servers'
import { GetFiltersForListOvaServersParams } from '@lib/clients/assets/list-ova-servers/types'
import { SystemHelper } from '@lib/helpers'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

const listOvaServersClient = new ListOvaServersClient()

interface OvaServerPossibleFiltersStore {
  malwareThreatNamesList: Set<string>
  ransomwareThreatNamesList: Set<string>
  isLoading: boolean
}

interface OvaServerPossibleFiltersStoreActions {
  fetchPossibleOvaServersFilters: (
    params?: GetFiltersForListOvaServersParams
  ) => void
  resetPossibleOvaServersFiltersStore: () => void
}

const INITIAL_STATE: OvaServerPossibleFiltersStore = {
  malwareThreatNamesList: new Set(),
  ransomwareThreatNamesList: new Set(),
  isLoading: false,
}

const useOvaServerPossibleFiltersStore = create<
  OvaServerPossibleFiltersStore & {
    actions: OvaServerPossibleFiltersStoreActions
  }
>((set) => ({
  ...INITIAL_STATE,
  actions: {
    fetchPossibleOvaServersFilters: async (
      params?: GetFiltersForListOvaServersParams
    ) => {
      try {
        set({ isLoading: true })
        const response = await listOvaServersClient.getFiltersForListOvaServers(
          {
            ovaAccountIdsList: params?.ovaAccountIdsList || [],
            backupProvidersList: params?.backupProvidersList || [],
          }
        )
        set({
          malwareThreatNamesList: response.malwareThreatNamesList,
          ransomwareThreatNamesList: response.ransomwareThreatNamesList,
        })
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `fetchPossibleOvaServersFilters: caught error: ${
            (error as unknown as Error)?.message
          }`
        )
      } finally {
        set({ isLoading: false })
      }
    },
    resetPossibleOvaServersFiltersStore: () => set({ ...INITIAL_STATE }),
  },
}))

const useGetOvaServerPossibleFilters = () =>
  useOvaServerPossibleFiltersStore(
    useShallow((state) => ({
      malwareThreatNamesList: state.malwareThreatNamesList,
      ransomwareThreatNamesList: state.ransomwareThreatNamesList,
    }))
  )

const useIsOvaServerPossibleFiltersLoading = () =>
  useOvaServerPossibleFiltersStore((state) => state.isLoading)

const useOvaServerPossibleFiltersStoreActions = () =>
  useOvaServerPossibleFiltersStore(useShallow((state) => state.actions))

export {
  useGetOvaServerPossibleFilters,
  useIsOvaServerPossibleFiltersLoading,
  useOvaServerPossibleFiltersStoreActions,
}
