import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'

import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

import { GeneratedScanListFilters } from 'ui-v2/src/lib/models/assets/common'
import { ListGeneratedReportsRequestParams } from 'ui-v2/src/lib/models/reports'

export const useScanListFilters = (
  id: Array<string>
): GeneratedScanListFilters<ListGeneratedReportsRequestParams> => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setPageToken(undefined)
  }, [searchParams])

  return useMemo(() => {
    return {
      filters: {
        assetIdList: id,
        pageSize,
        pageToken,
      },
      setPageSize,
      setPageToken,
    }
  }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
}
