/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useModalFiltersStore } from '@features/jobs/cloud-connector-jobs/filters-modal/cloud-connector-filters-store'
import { CloudConnectorJobKindCheckbox } from '@features/jobs/cloud-connector-jobs/shared'
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

import { getHumanReadableCloudConnectorJobKind } from '@features/jobs/cloud-connector-jobs/helpers'
import { usePossibleCloudConnectorJobFilters } from '@features/jobs/cloud-connector-jobs/stores'
import {
  useModalFilterActions,
  useSelectedModalFilters,
} from '../cloud-connector-filters-store'

export function JobKindsSection() {
  const { kindsList: initialKindList } = usePossibleCloudConnectorJobFilters()

  const filterSearchValue = useModalFiltersStore(
    (state) => state.filterSearchValue
  )

  const { setFilter } = useModalFilterActions()

  const kindsList = useMemo(
    () =>
      Array.from(initialKindList).filter((kind) =>
        getHumanReadableCloudConnectorJobKind(kind)
          .toLowerCase()
          .includes(filterSearchValue)
      ),
    [initialKindList, filterSearchValue]
  )

  const { kindsList: selectedKindsList } = useSelectedModalFilters()

  if (kindsList.length === 0) {
    return null
  }

  function handleCheckboxChange(checked: boolean, kind: JobCloudConnectorKind) {
    const newKindsList = new Set(selectedKindsList)

    if (checked) {
      newKindsList.add(kind)
    } else {
      newKindsList.delete(kind)
    }

    setFilter('kindsList', newKindsList)
  }

  return (
    <Fragment>
      <Typography
        fontSize="12px"
        lineHeight="140%"
        marginBottom="10px"
        marginTop="10px"
        color="#71768e"
        fontWeight="700"
      >
        Job Kind
      </Typography>
      <Box display="flex" gap="12px" flexDirection="column">
        {kindsList.map((kind) => {
          return (
            <CloudConnectorJobKindCheckbox
              key={kind}
              onChange={handleCheckboxChange}
              isChecked={selectedKindsList.has(kind)}
              kind={kind}
            />
          )
        })}
      </Box>
    </Fragment>
  )
}
