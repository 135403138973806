import { Box, IconButton } from '@mui/material'
import DeleteIcon from 'ui-v2/src/assets/icons/delete-icon'
import {
  PolicyFormData,
  PolicyTagsTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { useFormContext } from 'react-hook-form'

const TagAction = ({ row }: TagActionProps) => {
  const { getValues, setValue } = useFormContext<PolicyFormData>()

  const onRemove = () => {
    const policyTags = getValues('policyTags')

    const filteredArray = policyTags.filter((_, index) => index !== row.id)

    setValue('policyTags', filteredArray)
  }

  return (
    <Box textAlign={'right'}>
      {!row.reviewPolicy && (
        <IconButton onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  )
}
interface TagActionProps {
  row: PolicyTagsTableRow
}
export default TagAction
