import AccessTokenModel from 'ui-v2/src/lib/models/settings/api-access/access-token.model'
import {
  TokenListTableDataAttrs,
  TokenListTableRow,
} from 'ui-v2/src/lib/models/settings/api-access/api-access'

export class ActiveTokenTableData {
  readonly #tokenList: Array<AccessTokenModel>

  constructor(parameters: TokenListTableDataAttrs) {
    this.#tokenList = parameters.tokenList
  }

  getTokenList = (): Array<TokenListTableRow> => {
    return this.#tokenList.map((token) => {
      return new TokenListTableRow({
        id: token.innerId,
        token: token.name,
        status: 'Active',
        lastUsed: token.updatedAt,
        action: '',
      })
    })
  }
}
