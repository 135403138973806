export enum ReportStatus {
  CLEAN = 1,
  INFECTED = 2,
}

export type ReportType = 'Integrity scan' | 'File system scan'

export interface ReportBlock {
  reportType: ReportType
  reportStatus: ReportStatus
}
