/* eslint-disable import/no-extraneous-dependencies */
import { VIRow } from 'ui-v2/src/lib/engine-types'
/* eslint-disable import/no-cycle */
import PolicyModel from './policy.model'
import ScheduleInterface from './schedule'
import {
  IntegrityCheckOptions,
  IntegrityScanOptions,
  KeepDataCopyInterface,
  ProtectNewImmediately,
  ScanWithTimestampsInterface,
  SelectedCloudConnectorVariant,
  SelectedVariant,
  SnapshotImport,
} from 'ui-v2/src/lib/constants/policies.constant'
import {
  AssetsForPolicyCoverage,
  AssetWithRelatedAssets,
  Asset,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'

export interface IntegrityScanInterface {
  scanForRansomware: boolean
  scanForMalware: boolean
}

export interface PolicyListTableDataAttrs {
  policyList: Array<PolicyModel>
}

export class PolicyListTableRow {
  readonly #id: string

  readonly #policyName: string

  readonly #status: string

  readonly #lastRun: number

  readonly #nextRun: string

  readonly #isPaused: boolean

  readonly #action: string

  constructor(parameters: PolicyListTableRowAttr) {
    const { id, policyName, status, lastRun, nextRun, isPaused, action } =
      parameters

    this.#id = id
    this.#policyName = policyName
    this.#status = status
    this.#lastRun = lastRun
    this.#nextRun = nextRun
    this.#isPaused = isPaused
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get policyName(): string {
    return this.#policyName
  }

  get status(): string {
    return this.#status
  }

  get lastRun(): number {
    return this.#lastRun
  }

  get nextRun(): string {
    return this.#nextRun
  }

  get isPaused(): boolean {
    return this.#isPaused
  }

  get action(): string {
    return this.#action
  }
}
export interface PolicyListTableRowAttr {
  id: string
  policyName: string
  status: string
  lastRun: number
  nextRun: string
  isPaused: boolean
  action: string
}

export interface PolicyFormData {
  policyName: string
  description: string // new
  schedule: ScheduleInterface
  integrityScan: IntegrityScanInterface
  policyTags: VIRow
  selectedVariant: SelectedVariant
  integrityCheck: IntegrityCheckOptions
  snapshotImportVariant: SnapshotImport
  protectNewImmediately: ProtectNewImmediately
  selectedVaultsList: Array<any> // remove
  skipEbsBackup: boolean
  selectedCloudConnectorVariant: SelectedCloudConnectorVariant
  selectedCloudConnectors: VIRow
  integrityScanOption: IntegrityScanOptions
  keepDataCopy: KeepDataCopyInterface
  scanWithTimestamps: ScanWithTimestampsInterface
  // Feature: Entropy Detection
  isEntropyDetectionEnabled: boolean
  timezone: any
  tabAssetTypeSelectedNum: number
  assetVariant: AssetVariant
  selectedEc2EbsAssetsRows: Array<PolicyEc2EbsTableRow>
  selectedS3BucketAssetsRows: Array<PolicyS3BucketTableRow>
  selectedEfsAssetsRows: Array<PolicyEfsTableRow>
  selectedAssets: Array<AssetWithRelatedAssets<Asset>>
  selectedS3Assets: Array<AssetWithRelatedAssets<Asset>>
  selectedEfsAssets: Array<AssetWithRelatedAssets<Asset>>
}

export interface PolicyTagsTableDataAttrs {
  policyTags: VIRow
  reviewPolicy?: boolean
}

export class PolicyTagsTableRow {
  readonly #id: number

  readonly #tagKey: string

  readonly #tagValue: string

  readonly #action: string

  readonly #reviewPolicy: boolean

  constructor(parameters: PolicyTagsTableRowAttr) {
    const { id, tagKey, tagValue, action, reviewPolicy } = parameters

    this.#id = id
    this.#tagKey = tagKey
    this.#tagValue = tagValue
    this.#action = action
    this.#reviewPolicy = reviewPolicy
  }

  get id(): number {
    return this.#id
  }

  get tagKey(): string {
    return this.#tagKey
  }

  get tagValue(): string {
    return this.#tagValue
  }

  get action(): string {
    return this.#action
  }

  get reviewPolicy(): boolean {
    return this.#reviewPolicy
  }
}
export interface PolicyTagsTableRowAttr {
  id: number
  tagKey: string
  tagValue: string
  action: string
  reviewPolicy: boolean
}

export interface PolicyEc2EbsTableDataAttrs {
  ec2EbsAssets: AssetsForPolicyCoverage
  lastBackup: Map<
    string,
    {
      backup: EBSSnapshot | AWSBRecoveryPoint | ElastioRecoveryPoint
    }
  >
}

export class PolicyEc2EbsTableRow {
  readonly #id: string

  readonly #resourceId: string

  readonly #assetTags: string

  readonly #assetType: string

  readonly #accountId: string

  readonly #region: string

  readonly #lastBackup: string

  readonly #policy: string

  constructor(parameters: PolicyEc2EbsTableRowAttr) {
    const {
      id,
      resourceId,
      assetTags,
      assetType,
      accountId,
      region,
      lastBackup,
      policy,
    } = parameters

    this.#id = id
    this.#resourceId = resourceId
    this.#assetTags = assetTags
    this.#assetType = assetType
    this.#accountId = accountId
    this.#region = region
    this.#lastBackup = lastBackup
    this.#policy = policy
  }

  get id(): string {
    return this.#id
  }

  get resourceId(): string {
    return this.#resourceId
  }

  get assetTags(): string {
    return this.#assetTags
  }

  get assetType(): string {
    return this.#assetType
  }

  get accountId(): string {
    return this.#accountId
  }

  get region(): string {
    return this.#region
  }

  get lastBackup(): string {
    return this.#lastBackup
  }

  get policy(): string {
    return this.#policy
  }
}
export interface PolicyEc2EbsTableRowAttr {
  id: string
  resourceId: string
  assetTags: string
  assetType: string
  accountId: string
  region: string
  lastBackup: string
  policy: string
}

export interface PolicyS3BucketTableDataAttrs {
  s3Buckets: AssetsForPolicyCoverage
}

export class PolicyS3BucketTableRow {
  readonly #id: string

  readonly #s3Bucket: string

  readonly #account: string

  readonly #policy: string

  constructor(parameters: PolicyS3BucketTableRowAttr) {
    const { id, s3Bucket, account, policy } = parameters

    this.#id = id
    this.#s3Bucket = s3Bucket
    this.#account = account
    this.#policy = policy
  }

  get id(): string {
    return this.#id
  }

  get s3Bucket(): string {
    return this.#s3Bucket
  }

  get account(): string {
    return this.#account
  }

  get policy(): string {
    return this.#policy
  }
}
export interface PolicyS3BucketTableRowAttr {
  id: string
  s3Bucket: string
  account: string
  policy: string
}

export interface PolicyEfsTableDataAttrs {
  efsAssets: AssetsForPolicyCoverage
}

export class PolicyEfsTableRow {
  readonly #id: string

  readonly #efs: string

  readonly #account: string

  readonly #policy: string

  constructor(parameters: PolicyEfsTableRowAttr) {
    const { id, efs, account, policy } = parameters

    this.#id = id
    this.#efs = efs
    this.#account = account
    this.#policy = policy
  }

  get id(): string {
    return this.#id
  }

  get efs(): string {
    return this.#efs
  }

  get account(): string {
    return this.#account
  }

  get policy(): string {
    return this.#policy
  }
}
export interface PolicyEfsTableRowAttr {
  id: string
  efs: string
  account: string
  policy: string
}

export interface VaultForPolicy {
  redStackId: string
  vaultName: string
}

export enum AssetType {
  ASSET_TYPE_EC2 = 0,
  ASSET_TYPE_EBS = 1,
  ASSET_TYPE_S3 = 2,
  ASSET_TYPE_EC2_EBS = 3,
}
export enum AssetVariantType {
  EC2 = 'EC2',
  EBS = 'EBS',
}

export type AssetVariant = AssetVariantType

export enum AssetVariants {
  EBS = 0,
  S3 = 1,
  EFS = 2,
}
