import { WebhookFormInterface } from '@forms/add-webhook/data'
import CustomTextField from '@forms/fields/custom-text-field'
import PasswordField from '@forms/fields/password-field'
import SelectField from '@forms/fields/select-field'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { AuthorizationOptions } from '@lib/constants/notifications.constant'
import { EngineCallbackPure } from '@lib/engine-types'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import { Button } from '@mui/material'
import React, { memo, useMemo } from 'react'
import buildDefaultFormData from './data'
import { useSelector } from 'react-redux'
import { getSelectedWebhook } from '@store/selectors/webhook.selector'

interface Props {
  onSubmit: (form: any) => void
  loading: boolean
  onCancel: EngineCallbackPure
  defaultValues?: Partial<WebhookFormInterface>
}

const errorGroups = [ErrorGroupConstants.EMPTY]

function EditWebhookForm({ onSubmit, loading, defaultValues }: Props) {
  const selectedWebhook = useSelector(getSelectedWebhook)

  const currentForm = useMemo(
    () =>
      FormHelper.fillDefaultValues(
        buildDefaultFormData(selectedWebhook),
        defaultValues
      ),
    [defaultValues]
  )
  const {
    isFormDisabled,
    formState,
    globalFormError,
    register,
    control,
    setValue,
    submit,
    watch,
    clearErrors,
  } = useGeneralForm(
    currentForm,
    onSubmit,
    errorGroups,
    loading,
    false,
    'onSubmit'
  )

  FormHelper.fillErrors(formState.errors, currentForm)

  const isSubmitDisabled = isFormDisabled

  const selectedAuthorizationValue = watch(currentForm.authorization.name)

  const isSecretFieldNotChanged = (): boolean => {
    const auth = selectedAuthorizationValue

    if (auth === AuthorizationOptions.BASIC) {
      return watch(currentForm.password.name)?.includes('•')
    }

    if (auth === AuthorizationOptions.BEARER) {
      return watch(currentForm.token.name)?.includes('•')
    }

    if (auth === AuthorizationOptions.API_KEY) {
      return watch(currentForm.apiValue.name)?.includes('•')
    }

    return false
  }

  function clearPasswordIfMasked(
    passwordFieldName: string,
    lookFieldName?: string
  ) {
    if (lookFieldName && lookFieldName?.includes('•')) {
      setValue(passwordFieldName, '')
      return
    }

    const currentValue = watch(passwordFieldName)
    if (currentValue?.includes('•')) {
      setValue(passwordFieldName, '')
    }
  }

  React.useEffect(() => {
    const { login, password, token, apiKey, apiValue } = currentForm

    switch (selectedAuthorizationValue) {
      case AuthorizationOptions.BASIC:
        setValue(login.name, defaultValues?.login)
        setValue(password.name, defaultValues?.password)
        break

      case AuthorizationOptions.BEARER:
        setValue(token.name, defaultValues?.token)
        break

      case AuthorizationOptions.API_KEY:
        setValue(apiKey.name, defaultValues?.apiKey)
        setValue(apiValue.name, defaultValues?.apiValue)
        break
    }
  }, [selectedAuthorizationValue, defaultValues, currentForm])

  const renderAuthBlock = () => {
    const { login, password, token, apiKey, apiValue } = currentForm
    return (
      <div className="login-and-password">
        {selectedAuthorizationValue === AuthorizationOptions.BASIC && (
          <>
            <div className="login reset-select-style">
              <p id="option-title">Login</p>
              <CustomTextField
                {...register(login.name, login.validationRules)}
                disabled={isFormDisabled}
                field={login}
                onKeyDown={() => {
                  clearPasswordIfMasked(login.name)
                  clearPasswordIfMasked(password.name, watch(password.name))
                }}
                onChange={(e) => {
                  setValue(login.name, e.target.value)
                }}
              />
            </div>
            <div className="password reset-select-style">
              <p id="option-title">Password</p>

              <PasswordField
                {...register(password.name, password.validationRules)}
                disabled={isFormDisabled}
                field={password}
                isVisible={!isSecretFieldNotChanged()}
                onKeyDown={() => {
                  clearPasswordIfMasked(password.name)
                  clearPasswordIfMasked(login.name, watch(login.name))
                }}
              />
            </div>
          </>
        )}

        {selectedAuthorizationValue === AuthorizationOptions.BEARER && (
          <div className="reset-select-style formFullBasis">
            <p id="option-title">Token</p>
            <PasswordField
              {...register(token.name, token.validationRules)}
              disabled={isFormDisabled}
              field={token}
              isVisible={!isSecretFieldNotChanged()}
              onKeyDown={() => clearPasswordIfMasked(token.name)}
            />
          </div>
        )}

        {selectedAuthorizationValue === AuthorizationOptions.API_KEY && (
          <>
            <div className="login reset-select-style">
              <p id="option-title">Key</p>
              <CustomTextField
                {...register(apiKey.name, apiKey.validationRules)}
                disabled={isFormDisabled}
                field={apiKey}
                onKeyDown={() => {
                  clearPasswordIfMasked(apiKey.name)
                  clearPasswordIfMasked(apiValue.name, watch(apiValue.name))
                }}
              />
            </div>
            <div className="password reset-select-style">
              <p id="option-title">Value</p>
              <PasswordField
                disabled={isFormDisabled}
                field={apiValue}
                {...register(apiValue.name, apiValue.validationRules)}
                isVisible={!isSecretFieldNotChanged()}
                onKeyDown={() => {
                  clearPasswordIfMasked(apiValue.name)
                  clearPasswordIfMasked(apiKey.name, watch(apiKey.name))
                }}
              />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <form className="wrap-1676290493412" onSubmit={submit}>
      <>
        <p id="basic-details">Select events</p>
        <div className="main-form-line">
          <div className="webhook-name reset-select-style">
            <p id="option-title">Webhook name</p>
            <CustomTextField
              field={currentForm.name}
              {...register(
                currentForm.name.name,
                currentForm.name.validationRules
              )}
            />
          </div>
          <div className="endpoint-block">
            <div className="endpoint-url reset-select-style">
              <p id="option-title">Endpoint URL</p>
              <input
                type="hidden"
                value={String(currentForm.endpoint.value)}
                {...register(
                  currentForm.endpoint.name,
                  currentForm.endpoint.validationRules
                )}
              />
              <CustomTextField
                disabled={isFormDisabled}
                field={currentForm.endpoint}
                {...register(
                  currentForm.endpoint.name,
                  currentForm.endpoint.validationRules
                )}
              />
            </div>
          </div>
          <div className="authentication-block">
            <div className="authentication-option reset-select-style">
              <p id="option-title">Authentication option</p>

              <SelectField
                field={currentForm.authorization}
                disabled={isFormDisabled}
                control={control}
                variant="standard"
                onChange={(e) => {
                  clearErrors([
                    currentForm.apiKey.name,
                    currentForm.apiValue.name,
                    currentForm.login.name,
                    currentForm.password.name,
                    currentForm.token.name,
                  ])
                  setValue(currentForm.authorization.name, e.target.value)
                }}
                iconComponent={SelectArrowIcon}
              />
            </div>
          </div>
          {renderAuthBlock()}
        </div>
        <div className="description reset-select-style">
          <p id="option-title">Description</p>
          <div className="description-line">
            <CustomTextField
              disabled={isFormDisabled}
              field={currentForm.description}
              {...register(
                currentForm.description.name,
                currentForm.description.validationRules
              )}
            />
            <Button
              className="sizeS"
              variant="contained"
              type="submit"
              disabled={isSubmitDisabled}
            >
              Update
            </Button>
          </div>
        </div>
      </>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}
    </form>
  )
}

export default memo(EditWebhookForm)
