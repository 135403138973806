import moment from 'moment'

import { FrequencyType } from './frequency-type'

export const generateScheduleOptions = (date: string | number) => {
  const inputDate = moment(date).startOf('day')
  const dayOfMonth = inputDate.date()
  const MAX_DAY_OF_MONTH = 28

  return [
    {
      label: FrequencyType.DAILY,
      value: FrequencyType.DAILY,
    },
    {
      label: `${FrequencyType.WEEKLY} on ${inputDate.format('dddd')}`,
      value: `${FrequencyType.WEEKLY}_${inputDate.format('dddd')}`,
    },
    {
      label:
        dayOfMonth > MAX_DAY_OF_MONTH
          ? `${FrequencyType.MONTHLY} on the last day`
          : `${FrequencyType.MONTHLY} on the ${inputDate.format('Do')}`,
      value: `${FrequencyType.MONTHLY}_${
        dayOfMonth > MAX_DAY_OF_MONTH ? 0 : dayOfMonth
      }`,
    },
    {
      label: FrequencyType.CUSTOM,
      value: FrequencyType.CUSTOM,
    },
  ]
}
