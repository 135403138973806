import React from 'react'
import {
  TextField,
  InputAdornment,
  styled,
  TextFieldProps,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { useDebouncedCallback } from 'ui-v2/src/hooks/utils/debounce'
import { useSearchParams } from 'react-router-dom'

const StyledTextField = styled(TextField)(({ theme }) => ({
  maxWidth: '18.25rem',

  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.muted,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.paper,
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border.main,
  },
}))

const SearchBar = React.forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, 'variant'>
>(
  (
    { placeholder = 'Search', sx = {}, fullWidth = true, size = 'xs', ...rest },
    ref
  ) => {
    const [searchParams, setSearchParams] = useSearchParams()

    const debouncedOnChange = useDebouncedCallback((e) => {
      const search = e.target.value
      if (search) {
        searchParams.set('search', search)
      } else {
        searchParams.delete('search')
      }
      setSearchParams(searchParams)
    })

    return (
      <StyledTextField
        variant="outlined"
        placeholder={placeholder}
        fullWidth={fullWidth}
        size={size}
        inputRef={ref}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <Search color="icon" />
              </InputAdornment>
            ),
          },
        }}
        sx={sx}
        autoComplete="off"
        defaultValue={searchParams.get('search')}
        onChange={debouncedOnChange}
        {...rest}
      />
    )
  }
)

SearchBar.displayName = 'SearchBar'

export default SearchBar
