import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import { Description } from '../styles'
import { Controller } from 'react-hook-form'
import {
  radioButtonsForTagsOperations,
  radioButtonsForViewOptions,
} from '../consts'
import { useNavigation, DashboardRoutes } from '@lib/router'
import { useSearchParams } from 'react-router-dom'
import { TypographyStyled, FormWrap } from '../shared/form'
import styles from '../management-view.module.css'
import { StrHelper } from '@lib/helpers'
import { FieldNames } from '../types'
import { RadioGroupController, SelectWithChips } from '../components'
import { useEditView } from '@features/management-view/edit/useEditView'
import { TextFieldController } from '@features/management-view'

function EditManagementView() {
  const router = useNavigation()
  const [searchParams] = useSearchParams()
  const tabIndex = StrHelper.base64Decode(searchParams.get('t'))

  const {
    accounts,
    regions,
    includingTags,
    excludingTags,
    control,
    setValue,
    atLeastOneFieldIsNotEmpty,
    handleEditView,
    validateName,
  } = useEditView()

  const handleOnCancel = () => router.push(DashboardRoutes.rootTab(tabIndex))

  return (
    <React.Fragment>
      <FormWrap data-testid="edit-dashboard-view">
        <TypographyStyled>Edit dashboard view</TypographyStyled>

        <Box component="form" className={styles.form}>
          <Typography
            variant="body1"
            marginY="16px"
            fontSize="16px"
            fontWeight="500"
          >
            Dashboard name
          </Typography>

          <Controller
            name={FieldNames.NAME}
            control={control}
            rules={{
              required: 'This field is required',
              maxLength: {
                value: 255,
                message: 'The maximum length is 255 characters',
              },
              validate: validateName,
            }}
            render={({ field, fieldState: { error } }) => (
              //@ts-ignore
              <TextFieldController field={field} error={error} />
            )}
          />

          <Typography
            variant="body1"
            marginY="16px"
            fontSize="16px"
            fontWeight="500"
          >
            Dashboard view
          </Typography>

          <Description>Choose option for a new account view.</Description>

          <Controller
            name={FieldNames.DASHBOARD_VIEW}
            control={control}
            render={({ field }) => (
              <RadioGroupController
                //@ts-ignore
                field={field}
                options={radioButtonsForViewOptions}
              />
            )}
          />

          <SelectWithChips
            name={FieldNames.ACCOUNTS}
            title="Accounts"
            options={accounts.available}
            selected={accounts.selected}
            placeholder="Select accounts..."
            control={control}
            setValue={setValue}
            rules={{
              validate: atLeastOneFieldIsNotEmpty,
            }}
          />

          <SelectWithChips
            name={FieldNames.REGIONS}
            title="AWS Regions"
            options={regions.available}
            selected={regions.selected}
            placeholder="Select regions..."
            control={control}
            setValue={setValue}
            rules={{
              validate: atLeastOneFieldIsNotEmpty,
            }}
          />

          <SelectWithChips
            name={FieldNames.INCLUDING_TAGS}
            title="Filter assets with tags"
            options={includingTags.available}
            selected={includingTags.selected}
            placeholder="Choose tags..."
            showSearchInput
            control={control}
            setValue={setValue}
            isCreatable
            rules={{
              validate: atLeastOneFieldIsNotEmpty,
            }}
          >
            <>
              <Description>
                Choose a boolean operator and select tags to filter assets for
                the new dashboard view.
              </Description>
              <Controller
                name={FieldNames.INCLUDING_TAGS_OPERATION}
                control={control}
                render={({ field }) => (
                  <RadioGroupController
                    //@ts-ignore
                    field={field}
                    options={radioButtonsForTagsOperations}
                  />
                )}
              />
            </>
          </SelectWithChips>

          <SelectWithChips
            name={FieldNames.EXCLUDING_TAGS}
            title="Exclude assets with tags"
            options={excludingTags.available}
            selected={excludingTags.selected}
            placeholder="Choose tags..."
            control={control}
            setValue={setValue}
            isCreatable
            rules={{
              validate: atLeastOneFieldIsNotEmpty,
            }}
          >
            <>
              <Description variant="el-description" fontSize="14px" margin="0">
                Choose a boolean operator and select tags to exclude assets from
                the new dashboard view.
              </Description>
              <Controller
                name={FieldNames.EXCLUDING_TAGS_OPERATION}
                control={control}
                render={({ field }) => (
                  <RadioGroupController
                    //@ts-ignore
                    field={field}
                    options={radioButtonsForTagsOperations}
                  />
                )}
              />
            </>
          </SelectWithChips>
        </Box>
      </FormWrap>
      <BasePortalButtons alwaysInPortal>
        <div className="portalBetweenButtons">
          <Button
            variant="text"
            color="primary"
            className="showButton jsCloseCloudInstallerPage"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="sizeS ml16"
            onClick={handleEditView}
            data-testid="update-view-button"
          >
            Update view
          </Button>
        </div>
      </BasePortalButtons>
    </React.Fragment>
  )
}

export default EditManagementView
