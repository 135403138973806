import React, { useCallback } from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import RedStackModel from '@lib/models/red-stack.model'
import LangHelper from '@lib/helpers/lang.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import { Button } from '@mui/material'

export interface InstallRemoveSourceInterface {
  redStacksArrForDeletion: Array<RedStackModel>
  dataForInstallation: Array<ValueInterface>
}

interface InstallAndRemoveSourcesModalData {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<InstallRemoveSourceInterface>
  data: Nullable<InstallRemoveSourceInterface>
  show: boolean
}

function InstallAndRemoveSourcesModal({
  data,
  onSubmit,
  onCancel,
  show,
}: InstallAndRemoveSourcesModalData) {
  const onSubmitCallback = useCallback(() => {
    onSubmit(
      data ?? {
        redStacksArrForDeletion: [],
        dataForInstallation: [],
      }
    )
  }, [data])

  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  let regionsLabel = ''
  if (data && data.dataForInstallation) {
    regionsLabel = data.dataForInstallation
      .map((obj: any) =>
        LangHelper.getAwsRegionSingleTranslation(obj.extraValue)
      )
      .join(', ')
  }
  let regionsForDeletion = ''
  if (data && data.redStacksArrForDeletion) {
    regionsForDeletion = data.redStacksArrForDeletion
      .map((r: RedStackModel) => r.awsRegion)
      .map((regionName: string) =>
        LangHelper.getAwsRegionSingleTranslation(regionName)
      )
      .join(', ')
  }

  return (
    <BaseModal onBgClick={onCancelCallback} show={show}>
      <div className="modalHeader">Confirmation</div>

      {data && data.dataForInstallation?.length > 0 && (
        <div className="modalContent">
          You are going to <span className="greenColor fontBold">install</span>{' '}
          next Source(s):
          <div className="mb20 mt20 fontBold jsContentRegionsForInstallation">
            {regionsLabel}
          </div>
          Make sure that you removed all vaults before installing and confirm
          the action in order to proceed
        </div>
      )}

      {data && data.redStacksArrForDeletion?.length > 0 && (
        <div className="modalContent dsModalContent pt20">
          You are going to <span className="redColor fontBold">delete</span>{' '}
          next Source(s):
          <div className="mb20 mt20 fontBold jsContentRegionsForDeletion">
            {regionsForDeletion}
          </div>
          <div className="dsModalContentWarning">
            Deletion will NOT remove the vaults.
          </div>
        </div>
      )}

      <div className="modalControlBlock">
        <Button
          className="jsInstallAndRemoveModalYes"
          data-testid="jsInstallAndRemoveModalYes"
          color="primary"
          variant="contained"
          onClick={onSubmitCallback}
          type="button"
        >
          Yes, Proceed
        </Button>
        <Button
          className="jsInstallAndRemoveModalNo"
          data-testid="jsInstallAndRemoveModalNo"
          variant="text"
          onClick={onCancelCallback}
          type="button"
        >
          No, Сancel
        </Button>
      </div>
    </BaseModal>
  )
}

export default InstallAndRemoveSourcesModal
