import React from 'react'

function DeployElastioIcon() {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="10" fill="#FFF4E4" />
      <path
        d="M15.4272 43.5523C14.3269 44.6492 9.57373 45.4262 9.57373 45.4262C9.57373 45.4262 10.3507 40.673 11.4476 39.5727C12.5479 38.4758 14.3269 38.4758 15.4272 39.5727C16.5241 40.673 16.5241 42.452 15.4272 43.5523Z"
        fill="url(#paint0_linear_5277_535)"
      />
      <path
        d="M14.4885 42.5013C13.94 43.0497 11.5635 43.4364 11.5635 43.4364C11.5635 43.4364 11.9502 41.0599 12.4986 40.5114C13.0506 39.963 13.94 39.963 14.4885 40.5114C15.0369 41.0599 15.0369 41.9493 14.4885 42.5013Z"
        fill="url(#paint1_linear_5277_535)"
      />
      <path
        d="M16.3134 41.2881L13.7119 38.6865C13.3814 38.3596 13.3462 37.8357 13.624 37.4631L15.9513 34.3623L20.6376 39.0521L17.5369 41.376C17.1642 41.6537 16.6404 41.6186 16.3134 41.2881Z"
        fill="#199BE2"
      />
      <path
        d="M44.1395 9.73909C42.1216 9.88323 38.0118 10.4282 35.9376 12.4989L35.1606 13.2758L41.7243 19.8395L42.5013 19.0625C44.572 16.9883 45.1169 12.8785 45.261 10.8606C45.3067 10.2207 44.7794 9.69339 44.1395 9.73909Z"
        fill="#50E6FF"
      />
      <path
        d="M15.311 33.1252L35.9372 12.499L42.5009 19.0627L21.8747 39.6854L15.311 33.1252Z"
        fill="#35C1F1"
      />
      <path
        d="M15.3111 33.125L14.9244 33.5117C14.1932 34.2465 14.1932 35.4313 14.9244 36.166L18.8338 40.0754C19.5686 40.8066 20.7533 40.8066 21.4881 40.0754L21.8748 39.6887L15.3111 33.125Z"
        fill="#50E6FF"
      />
      <path
        d="M36.8762 21.4076C36.8762 23.2182 35.4066 24.6877 33.5926 24.6877C31.782 24.6877 30.3125 23.2182 30.3125 21.4076C30.3125 19.5936 31.782 18.124 33.5926 18.124C35.4066 18.124 36.8762 19.5936 36.8762 21.4076Z"
        fill="#50E6FF"
      />
      <path
        d="M35.9373 21.4074C35.9373 22.7012 34.8896 23.7488 33.5924 23.7488C32.2986 23.7488 31.251 22.7012 31.251 21.4074C31.251 20.1102 32.2986 19.0625 33.5924 19.0625C34.8896 19.0625 35.9373 20.1102 35.9373 21.4074Z"
        fill="#0078D4"
      />
      <path
        d="M30.3122 27.9676C30.3122 29.7816 28.8427 31.2512 27.0321 31.2512C25.2181 31.2512 23.7485 29.7816 23.7485 27.9676C23.7485 26.157 25.2181 24.6875 27.0321 24.6875C28.8427 24.6875 30.3122 26.157 30.3122 27.9676Z"
        fill="#50E6FF"
      />
      <path
        d="M29.3738 27.9674C29.3738 29.2646 28.3262 30.3123 27.0324 30.3123C25.7352 30.3123 24.6875 29.2646 24.6875 27.9674C24.6875 26.6736 25.7352 25.626 27.0324 25.626C28.3262 25.626 29.3738 26.6736 29.3738 27.9674Z"
        fill="#0078D4"
      />
      <path
        d="M27.5 34.0635V40.3986C27.5 41.1439 27.2047 41.8576 26.6773 42.385L23.7488 45.3135L21.875 39.6885L27.5 34.0635Z"
        fill="#199BE2"
      />
      <path
        d="M20.936 27.5H14.6009C13.8556 27.5 13.1419 27.7953 12.6146 28.3227L9.68604 31.2512L15.311 33.125L20.936 27.5Z"
        fill="#199BE2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5277_535"
          x1="13.8856"
          y1="38.9931"
          x2="12.382"
          y2="46.065"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F44F5A" />
          <stop offset="0.443" stopColor="#EE3D4A" />
          <stop offset="1" stopColor="#E52030" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5277_535"
          x1="11.6026"
          y1="43.3973"
          x2="14.1873"
          y2="40.8126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCF7B" />
          <stop offset="0.195" stopColor="#FECD76" />
          <stop offset="0.439" stopColor="#FAC666" />
          <stop offset="0.71" stopColor="#F3BC4D" />
          <stop offset="0.997" stopColor="#EAAD29" />
          <stop offset="1" stopColor="#EAAD29" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DeployElastioIcon
