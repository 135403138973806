import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import {
  ASSETS_POLICIES_VI_HEAD,
  MISSING_ASSETS_HEAD,
} from '@tables/core/table-constants'
import TableHelper from '@lib/helpers/table.helper'
import {
  addSelectedAsset,
  removeSelectedAsset,
  requestAllAssetsSelect,
  requestLiveAssetsSelect,
  selectAllEBSAsset,
  selectAllEC2Asset,
  selectAllOtherAsset,
  selectAllS3Asset,
  setAssetsSelectFilters,
  setAssetsSelectPagination,
  setAssetsSelectSearch,
  setMissingSelectedAssets,
} from '@store/actions/assets-select.action'
import {
  getAssetsMarker,
  getAssetsSelect,
  getAssetsSelectAllFilters,
  getAssetsSelectEnabledTabs,
  getAssetsSelectFilters,
  getAssetsSelectPagination,
  getAssetsSelectSearch,
  getAssetsSelectTabSelectedNum,
  getAssetsTableTabsData,
  getAssetsTableTabsDataInit,
  getIsUnprotectedAssets,
} from '@store/selectors/assets-select.selector'
import {
  AssetsEntityType,
  OnChangeData,
} from '@lib/interfaces/tables/assets-table.interface'
import SystemHelper from '@lib/helpers/system.helper'
import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'
import FilterV3 from '@components-complex/filter-v3/FilterV3'
import ValueInterface from '@lib/interfaces/value.interface'
import TableTabs from '@components-composite/table-tabs/TableTabs'
import PaginationModel from '@lib/models/pagination.model'
import { VIMatrix, VIRow } from '@lib/engine-types'
import CheckboxConstant from '@lib/constants/checkbox.constant'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'
import DataHelper from '@lib/helpers/data.helper'
import ObjHelper from '@lib/helpers/obj.helper'
import {
  PoliciesFilterLabel,
  TableTabsConstant,
} from '@lib/constants/policies.constant'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllRedStacksList } from '@store/selectors/rex.selector'
import { FilterNamesConstant } from '@lib/constants/filters/filter-names.constant'
import BaseDataConstant from '@lib/constants/data/base-data.constant'
import { useAccessToken } from '@features/authentication'

const ASSETS_VI_FORCE_TEMPLATE = () => [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1]

export interface AssetKindParsedData extends ValueInterface {
  pathSlug: string
  pathLabel: string
}

class AssetKindParsedConstantInner extends BaseDataConstant<AssetKindParsedData> {
  get KIND_UNDEFINED(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: '',
      label: 'Undefined',
      value: -1,
    }
  }

  get KIND_AWS_EC2(): AssetKindParsedData {
    return {
      pathSlug: 'instances',
      pathLabel: 'Instances',
      name: 'ec2',
      label: 'EC2',
      value: 0,
    }
  }

  get KIND_AZURE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'azure_vm',
      label: '',
      value: 1,
    }
  }

  get KIND_VM_WARE_VM(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'vm_ware_vm',
      label: '',
      value: 2,
    }
  }

  get KIND_AWS_EBS(): AssetKindParsedData {
    return {
      pathSlug: 'volumes',
      pathLabel: 'Volumes',
      name: 'aws_ebs',
      label: 'EBS',
      value: 3,
    }
  }

  get KIND_AWS_EFS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'aws_efs',
      label: 'EFS',
      value: 4,
    }
  }

  get KIND_GENERIC_HOST(): AssetKindParsedData {
    return {
      pathSlug: 'local-machine',
      pathLabel: 'Other Assets',
      name: 'generic_host',
      label: 'Generic Host',
      value: 5,
    }
  }

  get KIND_GENERIC_FS(): AssetKindParsedData {
    return {
      pathSlug: '',
      pathLabel: '',
      name: 'generic_fs',
      label: 'Generic FS',
      value: 6,
    }
  }

  get KIND_S3_BUCKET(): AssetKindParsedData {
    return {
      pathSlug: 's3buckets',
      pathLabel: 'S3 Buckets',
      name: 's3_bucket',
      label: 'S3',
      value: 7,
    }
  }

  get KIND_AWS_S3_OBJECTS(): AssetKindParsedData {
    return {
      pathSlug: 's3objects',
      pathLabel: 'S3 Objects',
      name: 's3_objects',
      label: 'S3 Objects',
      value: 8,
    }
  }

  get KIND_ALL(): AssetKindParsedData {
    return {
      pathSlug: 'all',
      pathLabel: 'All',
      name: 'all',
      label: 'All',
      value: 999,
    }
  }

  public getByPathSlug(value: string): AssetKindParsedData {
    return this.getByField('pathSlug', value)
  }
}

const AssetKindParsedConstant = AssetKindParsedConstantInner.build(
  AssetKindParsedConstantInner,
  'KIND_'
)

interface Props {
  supportedAssetKinds: Array<AssetKind>
  isLoading?: boolean
  initSelectedFilters?: VIRow
  additionalData?: VIMatrix
  storeInitialFilters?: boolean
  disableProtectionFilter?: boolean
  isLiveAssets?: boolean
}

function AssetsSelectComplex({
  supportedAssetKinds,
  isLoading = false,
  initSelectedFilters,
  additionalData = [],
  storeInitialFilters = false,
  disableProtectionFilter = true,
  isLiveAssets = true,
}: Props) {
  const token = useAccessToken()
  const dispatch = useDispatch()

  const possibleFilters = useSelector(getAssetsSelectAllFilters)
  const selectedFilters = useSelector(getAssetsSelectFilters)
  const pagination = useSelector(getAssetsSelectPagination)
  const search = useSelector(getAssetsSelectSearch)
  const assets = useSelector(getAssetsSelect)
  const tableTabsDataInit = useSelector(getAssetsTableTabsDataInit)
  const tableTabsData = useSelector(getAssetsTableTabsData)
  const tabsEnabled = useSelector(getAssetsSelectEnabledTabs)
  const tabSelected = useSelector(getAssetsSelectTabSelectedNum)
  const assetsMarker = useSelector(getAssetsMarker)
  const allRedStacksList = useSelector(getAllRedStacksList)
  const isUnprotectedAssets = useSelector(getIsUnprotectedAssets)

  const tableLoading = usePreloader(
    PreloaderConstants.REQUEST_ASSETS_SELECT_INVENTORY
  )
  const filtersLoading = usePreloader(
    PreloaderConstants.REQUEST_ASSETS_SELECT_ALL_FILTERS_V3
  )
  const finalLoading = isLoading || tableLoading

  const [disabledUnprotectedFilter, setDisabledUnprotectedFilter] =
    useState(false)
  const [forceTemplate, setForceTemplate] = useState(ASSETS_VI_FORCE_TEMPLATE())
  const [currentHeadEBS, setCurrentHeadEBS] = useState<VIRow>(
    ASSETS_POLICIES_VI_HEAD()
  )
  const [currentHeadEC2, setCurrentHeadEC2] = useState<VIRow>(
    ASSETS_POLICIES_VI_HEAD()
  )
  const [currentHeadOthersAssetsKind, setCurrentHeadOthersAssetsKind] =
    useState<VIRow>(ASSETS_POLICIES_VI_HEAD())
  const [currentHeadS3, setCurrentHeadS3] = useState<VIRow>(
    ASSETS_POLICIES_VI_HEAD()
  )

  useEffect(() => {
    if (isLiveAssets) {
      dispatch(requestLiveAssetsSelect())
    } else {
      dispatch(requestAllAssetsSelect(token as string))
    }
    dispatch(setAssetsSelectSearch(''))
    setDisabledUnprotectedFilter(false)
    dispatch(getAllRedStacks())
  }, [])

  useEffect(() => {
    const newSelectedFilters: VIRow =
      initSelectedFilters ?? defaultSelectedFilters()

    dispatch(setAssetsSelectFilters(newSelectedFilters))
  }, [JSON.stringify(initSelectedFilters)])

  const isSelectedUnprotectedFilter = selectedFilters?.some((filter) =>
    filter?.options?.some(
      (options) =>
        options.label === PoliciesFilterLabel.UNPROTECTED && options.value
    )
  )

  const selectedFiltersWithDisabledProtected: VIRow =
    ObjHelper.cloneDeep(selectedFilters)
  selectedFiltersWithDisabledProtected?.forEach((filter) => {
    if (filter.name === FilterNamesConstant.IS_PROTECTED) {
      filter.options?.splice(
        0,
        2,
        {
          type: 1,
          name: 'true',
          label: 'Protected',
          value: true,
          disabled: true,
        },
        {
          type: 1,
          name: 'false',
          label: 'Unprotected',
          value: false,
          disabled: true,
        }
      )
    }
    return filter
  })

  const selectedFiltersWithNotDisabledProtected: VIRow =
    ObjHelper.cloneDeep(selectedFilters)
  selectedFiltersWithNotDisabledProtected?.forEach((filter) => {
    if (filter.name === FilterNamesConstant.IS_PROTECTED) {
      filter.options?.splice(0, 2)
    }
    return filter
  })

  useEffect(() => {
    if (
      !assets.length &&
      isSelectedUnprotectedFilter &&
      assetsMarker === 1 &&
      !isUnprotectedAssets
    ) {
      setDisabledUnprotectedFilter(true)
    }
    if (assets.length && isUnprotectedAssets && assetsMarker === 1) {
      setDisabledUnprotectedFilter(false)
    }
  }, [assets])

  useEffect(() => {
    if (disableProtectionFilter) {
      if (
        !assets.length &&
        isSelectedUnprotectedFilter &&
        disabledUnprotectedFilter
      ) {
        dispatch(setAssetsSelectFilters(selectedFiltersWithDisabledProtected))
      }
      if (assets.length && isUnprotectedAssets && !disabledUnprotectedFilter) {
        dispatch(
          setAssetsSelectFilters(selectedFiltersWithNotDisabledProtected)
        )
      }
    }
  }, [disabledUnprotectedFilter])

  useEffect(() => {
    const ebsTotal = tableTabsDataInit?.EBS?.length
    const ec2Total = tableTabsDataInit?.EC2?.length
    const otherTotal = tableTabsDataInit?.othersAssetKind?.length
    const s3Total = tableTabsDataInit?.S3?.length

    const ebsSelected = tableTabsDataInit?.EBS?.filter(
      (row) => row[0]?.value === CheckboxConstant.Checked
    )
    const ec2Selected = tableTabsDataInit?.EC2?.filter(
      (row) => row[0]?.value === CheckboxConstant.Checked
    )
    const s3Selected = tableTabsDataInit?.S3?.filter(
      (row) => row[0]?.value === CheckboxConstant.Checked
    )
    const otherSelected = tableTabsDataInit?.othersAssetKind?.filter(
      (row) => row[0]?.value === CheckboxConstant.Checked
    )

    if (ebsTotal !== undefined && ebsSelected !== undefined) {
      setCurrentHeadEBS(
        ObjHelper.cloneDeep(
          ASSETS_POLICIES_VI_HEAD(
            DataHelper.headCheckboxStatus(ebsTotal, ebsSelected?.length)
          )
        )
      )
    }
    if (ec2Total !== undefined && ec2Selected !== undefined) {
      setCurrentHeadEC2(
        ObjHelper.cloneDeep(
          ASSETS_POLICIES_VI_HEAD(
            DataHelper.headCheckboxStatus(ec2Total, ec2Selected?.length)
          )
        )
      )
    }
    if (otherTotal !== undefined && otherSelected !== undefined) {
      setCurrentHeadOthersAssetsKind(
        ObjHelper.cloneDeep(
          ASSETS_POLICIES_VI_HEAD(
            DataHelper.headCheckboxStatus(otherTotal, otherSelected?.length)
          )
        )
      )
    }
    if (s3Total !== undefined && s3Selected !== undefined) {
      setCurrentHeadS3(
        ObjHelper.cloneDeep(
          ASSETS_POLICIES_VI_HEAD(
            DataHelper.headCheckboxStatus(s3Total, s3Selected?.length)
          )
        )
      )
    }
  }, [tableTabsDataInit])

  // bind filter
  useEffect(() => {
    if (selectedFilters !== undefined) {
      const newTemplate = TableHelper.calcSelectedTemplate(
        forceTemplate,
        selectedFilters
      )
      setForceTemplate(newTemplate)
    }
  }, [selectedFilters])

  const onRowChange = (data: OnChangeData) => {
    const { row } = data
    if (row[0]?.type === AssetsEntityType.Checkbox) {
      const value = Boolean(row[0].value)
      const foundAsset = assets.find((v) => v?.asset?.id === row[0]?.id)
      if (!foundAsset) {
        SystemHelper.throwErrorInLocalEnv(
          'Cannot find an asset in the assets selector'
        )
        return
      }
      let actionToRun = addSelectedAsset
      if (value) {
        actionToRun = removeSelectedAsset
      }
      dispatch(actionToRun(foundAsset))
      return
    }
  }

  const onHeadChangeAssets = (data: OnChangeData, label: string) => {
    const { row } = data
    if (
      row[0]?.type === AssetsEntityType.Checkbox &&
      row[0]?.value !== undefined
    ) {
      if (label === AssetKindParsedConstant.KIND_AWS_EBS.label) {
        dispatch(selectAllEBSAsset(Boolean(row[0].value)))
      }
      if (label === AssetKindParsedConstant.KIND_AWS_EC2.label) {
        dispatch(selectAllEC2Asset(Boolean(row[0].value)))
      }
      if (label === AssetKindParsedConstant.KIND_S3_BUCKET.label) {
        dispatch(selectAllS3Asset(Boolean(row[0].value)))
      }
      if (
        label !== AssetKindParsedConstant.KIND_AWS_EBS.label &&
        label !== AssetKindParsedConstant.KIND_AWS_EC2.label &&
        label !== AssetKindParsedConstant.KIND_S3_BUCKET.label
      ) {
        dispatch(selectAllOtherAsset(Boolean(row[0].value)))
      }
    }
    return
  }

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    dispatch(setAssetsSelectFilters(newFilters))
  }

  const onSearchChange = (value: string) => {
    dispatch(setAssetsSelectSearch(value))
  }

  const onPaginationChange = (newPagination: PaginationModel) => {
    dispatch(setAssetsSelectPagination(newPagination))
  }

  const ebsTotalCount = tableTabsDataInit?.EBS?.length ?? 0
  const ec2TotalCount = tableTabsDataInit?.EC2?.length ?? 0
  const otherAssetKindTotalCount =
    tableTabsDataInit?.othersAssetKind?.length ?? 0
  const s3TotalCount = tableTabsDataInit?.S3?.length ?? 0

  const showSupportedAssetKinds = supportedAssetKinds
    .map((kind) => `showAssetKind${kind}`)
    .join(' ')

  const allNotDeletedRedStack = allRedStacksList.filter(
    (rs) => rs.isActive || rs.isInactive
  )

  // red stack version for displaying s3 buckets
  const isNotSupportedVersionCCs = DataHelper.isNotSupportedVersionCCsFunc(
    allNotDeletedRedStack
  )

  const dataTableTabs: Array<ValueInterface> = []
  supportedAssetKinds.map((kind) => {
    if (kind === 0) {
      dataTableTabs.push({
        name: TableTabsConstant.EBS,
        value: ebsTotalCount,
        disabled:
          ebsTotalCount === 0 ||
          (tabsEnabled.length > 0 &&
            !tabsEnabled.includes(TableTabsConstant.EBS)),
        options: currentHeadEBS,
        matrix: tableTabsData.EBS,
      })
    }
    if (kind === 3) {
      dataTableTabs.push({
        name: TableTabsConstant.EC2,
        value: ec2TotalCount,
        disabled:
          ec2TotalCount === 0 ||
          (tabsEnabled.length > 0 &&
            !tabsEnabled.includes(TableTabsConstant.EC2)),
        options: currentHeadEC2,
        matrix: tableTabsData.EC2,
      })
    }
    if (kind === 5) {
      dataTableTabs.push({
        name: TableTabsConstant.OTHER,
        value: otherAssetKindTotalCount,
        disabled:
          otherAssetKindTotalCount === 0 ||
          (tabsEnabled.length > 0 &&
            !tabsEnabled.includes(TableTabsConstant.OTHER)),
        options: currentHeadOthersAssetsKind,
        matrix: tableTabsData.othersAssetKind,
      })
    }
    if (kind === 7) {
      dataTableTabs.push({
        name: TableTabsConstant.S3,
        value: s3TotalCount,
        disabled:
          s3TotalCount === 0 ||
          (tabsEnabled.length > 0 &&
            !tabsEnabled.includes(TableTabsConstant.S3)),
        options: currentHeadS3,
        matrix: tableTabsData.S3,
      })
    }
  })

  const additionalDataTableTabs: ValueInterface = {
    name: TableTabsConstant.UNREACHABLE,
    value: additionalData.length,
    disabled: additionalData.length === 0,
    options: MISSING_ASSETS_HEAD,
    matrix: additionalData,
  }

  const onAdditionalDataTableChange = (newAssets: VIMatrix) => {
    dispatch(setMissingSelectedAssets(newAssets))
  }

  return (
    <div
      className={`wrap-1642722206069 ${showSupportedAssetKinds} jsAssetsSelectComplex`}
    >
      {Object.values(tableTabsData).length > 0 && (
        <TableTabs
          data={dataTableTabs}
          finalLoading={finalLoading}
          loading={isLoading}
          onRowChange={onRowChange}
          onHeadChangeAssets={onHeadChangeAssets}
          forceTemplate={forceTemplate}
          onPaginationChange={onPaginationChange}
          additionalData={additionalDataTableTabs}
          onAdditionalDataChange={onAdditionalDataTableChange}
          tabSelected={tabSelected}
          isNotSupportedVersionCCs={isNotSupportedVersionCCs}
        >
          {!filtersLoading && (
            <FilterV3
              possibleFilters={possibleFilters}
              selectedFilters={selectedFilters}
              initSelectedFilters={
                storeInitialFilters
                  ? initSelectedFilters
                  : defaultSelectedFilters()
              }
              onFiltersChange={onFiltersChange}
              pagination={pagination}
              onPaginationChange={onPaginationChange}
              onSearchChange={onSearchChange}
              searchVal={search}
              turnOnSearch
              disabled={tableLoading}
              searchPlaceholder="Search by Asset ID"
              finalLoading={finalLoading}
            />
          )}
        </TableTabs>
      )}
    </div>
  )
}

export default memo(AssetsSelectComplex)
