/* eslint-disable import/no-extraneous-dependencies */
import { Nullable, RansomwareScan } from 'blues-corejs/dist'
import { RansomwareScanStatus } from 'blues-corejs/dist/models/scans/ransomware/types'

export function getRansomwareScanStatus(
  ransomwareScans: Array<RansomwareScan>
): Nullable<RansomwareScanStatus> {
  if (ransomwareScans.length === 0) {
    return null
  }

  return ransomwareScans.reduce((status: RansomwareScanStatus, scan) => {
    if (scan.status === RansomwareScanStatus.INFECTED) {
      return RansomwareScanStatus.INFECTED
    }
    if (scan.status === RansomwareScanStatus.CLEAN) {
      return RansomwareScanStatus.CLEAN
    }
    return status
  }, RansomwareScanStatus.CLEAN)
}
