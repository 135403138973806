import React, { memo } from 'react'
import ValueInterface from '@lib/interfaces/value.interface'

interface Props {
  data: ValueInterface
}

function ListPopupToken({ data }: Props) {
  return (
    <div className="wrap-1623752429770">
      {data.options &&
        data.options.map((v: ValueInterface, index: number) => (
          <div
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${v.label}${index}`}
            className="atScope"
          >
            {`${v.label};`}

            <div className="lpPopup">
              <div>
                {v.options?.map((row: ValueInterface, rowIndex: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ul className="lpContent" key={`${row.label}${rowIndex}`}>
                    <li>
                      <div className="lpUnitLabel">{row.label}</div>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default memo(ListPopupToken)
