/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import {
  AwsRetentionStats,
  AwsRpoStats,
} from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { useSelector } from 'react-redux'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

const INITIAL_RPO: AwsRpoStats = {
  rpoStatsQuantilesList: [],
  highestRpo: 0,
  backupsOutOfSchedule: 0,
  lowestRpo: 0,
}
const INITIAL_RETENTION: AwsRetentionStats = {
  averageRetention: 0,
  rpoStatsQuantilesList: [],
  bottomQuartile: 0,
  topQuartile: 0,
}

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsRpoAndRetention() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )
  const [isLoading, setIsLoading] = useState(false)
  const [retentionStats, setRetentionStats] =
    useState<AwsRetentionStats>(INITIAL_RETENTION)
  const [rpoStats, setRpoStats] = useState<AwsRpoStats>(INITIAL_RPO)

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)

    const fetchRpoAndRetentionStats = async () => {
      try {
        const response = await awsStatsClient.getRpoAndRetentionStats({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (!isMounted) {
          return
        }
        if (response?.rpoStats) {
          setRpoStats(response.rpoStats)
        }
        if (response?.retentionStats) {
          setRetentionStats(response.retentionStats)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getRpoAndRetentionStats: failed to fetch Rpo and retention count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    const fetchInterval = setInterval(
      fetchRpoAndRetentionStats,
      INTERVAL_REQUEST_TIMEOUT
    )
    fetchRpoAndRetentionStats()

    return () => {
      clearInterval(fetchInterval)
      isMounted = false
      setRetentionStats(INITIAL_RETENTION)
      setRpoStats(INITIAL_RPO)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    rpoStats,
    retentionStats,
  }
}
