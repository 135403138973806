import { useMemo } from 'react'

export function useDebouncedCallback<T extends (...args: Array<any>) => any>(
  callback: T,
  delay = 300
): T {
  return useMemo(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null

    return ((...args: Parameters<T>) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        callback(...args)
        timeoutId = null
      }, delay)
    }) as T
  }, [callback, delay])
}
