import React from 'react'
import { Autocomplete, TextField, Chip } from '@mui/material'
import { Option } from 'ui-v2/src/lib/models/reports'

const MultiSelectChip: React.FC<MultiSelectChipProps> = ({
  options,
  selectedOptions,
  size = 'small',
  placeholder,
  helperText,
  error = false,
  setSelectedOptions,
}) => {
  const handleChange = (_: React.SyntheticEvent, newValue: Array<Option>) => {
    const selectedValues = newValue.map((option) => option.value.toString())

    setSelectedOptions(selectedValues)
  }

  return (
    <Autocomplete
      size={size}
      multiple
      options={options.filter(
        (option) => !selectedOptions.includes(option.value.toString())
      )}
      getOptionLabel={(option: Option) => option.label}
      value={options.filter((option) =>
        selectedOptions.includes(option.value.toString())
      )}
      onChange={handleChange}
      renderTags={(value: Array<Option>, getTagProps) =>
        value.map((option: Option, index: number) => (
          <Chip
            {...getTagProps({ index })}
            key={option.value}
            label={option.label}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={selectedOptions.length ? '' : placeholder}
          error={!!error}
          helperText={helperText}
        />
      )}
    />
  )
}
interface MultiSelectChipProps {
  options: Array<Option>
  selectedOptions: Array<string>
  size?: 'small' | 'medium'
  placeholder?: string
  helperText?: string
  error?: boolean
  setSelectedOptions: (options: Array<string>) => void
}

export default MultiSelectChip
