import { FrequencyType } from './frequency-type'

export const getFrequencyType = (
  selectedValue: string
): FrequencyType | null => {
  if (!selectedValue) {
    return null
  }
  if (selectedValue.startsWith(FrequencyType.DAILY)) {
    return FrequencyType.DAILY
  }
  if (selectedValue.startsWith(FrequencyType.WEEKLY)) {
    return FrequencyType.WEEKLY
  }

  if (selectedValue.startsWith(FrequencyType.MONTHLY)) {
    return FrequencyType.MONTHLY
  }

  if (selectedValue.startsWith(FrequencyType.CUSTOM)) {
    return FrequencyType.CUSTOM
  }

  return null
}
