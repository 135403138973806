import {
  Focusable,
  FormRefType,
  Nullable,
  QuerySelector,
  ValueObject,
} from '@lib/engine-types'
import ObjHelper from '@lib/helpers/obj.helper'
import ErrorValidationConstants from '@lib/constants/error-validation.constant'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { FieldValues, FieldErrors } from 'react-hook-form'
import { PossibleRoles } from '@lib/constants/role.constant'
import {
  FormPeriodInterface,
  RowPeriodInterface,
} from '@lib/interfaces/form/form-period.interface'

abstract class FormHelper {
  public static refBuilder(register: any): (v?: any) => FormRefType {
    return (v?: any) => {
      if (!v) {
        return <FormRefType>register
      }
      return <FormRefType>register(v)
    }
  }

  public static focusFirst<T extends string>(
    form: Record<T, FormFieldInterface> | FormPeriodInterface
  ) {
    const arr: Array<FormFieldInterface> = Object.values(form)
    if (!arr || arr.length === 0) {
      return
    }
    const selector = `id_${arr[0]?.name}`
    const elem = document.getElementById(selector)
    if (elem) {
      elem.focus()
      elem.dispatchEvent(
        new Event('focus', {
          bubbles: true,
          cancelable: true,
        })
      )
    }
  }

  public static focusFirstPure(
    selector: string,
    node: Nullable<QuerySelector<Focusable>> = null
  ) {
    const finalNode = node ?? document
    const elem = finalNode.querySelector(selector)
    if (elem) {
      setTimeout(() => {
        // @ts-ignore
        elem.focus()
      }, 400)
    }
  }

  public static fillDefaultValues<T extends string>(
    form: Record<T, FormFieldInterface>,
    values?: ValueObject<T>
  ): Record<T, FormFieldInterface> {
    if (!values) {
      return form
    }
    const result = ObjHelper.cloneDeep(form)

    // fill with default values
    ObjHelper.iterate(values, (key: T, value) => {
      result[key].value = value
    })

    // fill unset values
    ObjHelper.iterate(result, (key: T, value: FormFieldInterface) => {
      result[key] = ObjHelper.assignIfEmpty(value, {
        value: '',
        validationRules: {},
        hasError: false,
        errorText: '',
      })
    })

    return result
  }

  public static fillDefaultValuesPeriodForm(
    form: FormPeriodInterface,
    values?: FormPeriodInterface
  ): FormPeriodInterface {
    if (!values) {
      return form
    }

    const result = form?.period?.map((obj: RowPeriodInterface) =>
      ObjHelper.cloneDeep(obj)
    )

    // fill with default values
    result?.map((obj: any) => {
      values?.period?.map((newObj: RowPeriodInterface) =>
        ObjHelper.iterate(newObj, (key: string, value) => {
          obj[key] = value
        })
      )
    })

    return { period: result }
  }

  public static getDefaultErrorByType(error: {
    type: string
    message: string
  }): string {
    switch (error.type) {
      case 'required':
        return ErrorValidationConstants.REQUIRED
      case 'minLength':
        return ErrorValidationConstants.MIN_LENGTH
      case 'maxLength':
        return ErrorValidationConstants.MAX_LENGTH
      case 'validate':
        return error.message
      default:
        return ''
    }
  }

  public static fillErrors<T extends FieldValues>(
    errors: FieldErrors<T>,
    form: Record<keyof T, FormFieldInterface>
  ): void {
    // reset errors
    const formKeys = Object.keys(form) as Array<keyof T>
    formKeys.forEach((formKey) => {
      form[formKey].errorText = ''
      form[formKey].hasError = false
    })

    // handle new errors
    const errorKeys = Object.keys(errors) as Array<keyof T>
    errorKeys.forEach((errorKey) => {
      if (!(errorKey in form)) {
        return
      }
      const errorElem = errors[errorKey] as {
        type: string
        message: string
      }
      const formElem = form[errorKey]
      formElem.hasError = true

      // predefined errors
      if (formElem.validationErrors) {
        const predefinedError = formElem.validationErrors[errorElem.type]
        if (predefinedError) {
          formElem.errorText = predefinedError
          return
        }
      }

      // default errors
      formElem.errorText = FormHelper.getDefaultErrorByType(errorElem)
    })
  }

  public static buildEmptyGroupFormUnit() {
    return {
      group: '',
      roles: PossibleRoles(),
      users: [],
    }
  }

  public static combineValidators(validators: Array<any>) {
    return (value: string) => {
      let errorMsg = ''
      validators.forEach((validator) => {
        const localResult = validator(value)
        // if we already found some error
        if (errorMsg !== '') {
          return
        }
        // if there is an error
        if (localResult !== true) {
          errorMsg = localResult
        }
      })
      return errorMsg === '' ? true : errorMsg
    }
  }
}

export default FormHelper
