import ValueInterface from '@lib/interfaces/value.interface'

export enum RoleConstant {
  GUEST = 1,
  CUSTOMER = 2,
}

export const PossibleRoles = (): Array<ValueInterface> => [
  {
    name: 'super_admin',
    label: 'SuperAdmin',
  },
  {
    name: 'observer',
    label: 'Observer',
  },
  {
    name: 'backup_admin',
    label: 'Backup Admin',
  },
  {
    name: 'security_admin',
    label: 'Security Admin',
  },
  {
    name: 'manager',
    label: 'Manager',
  },
]
