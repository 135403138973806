import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts'
import { ProtectAssetsRoutes } from '@lib/router/routes/protect-assets/protect-assets'
import ProtectAssetsPage from '@features/protect-assets'

export const protectAssetsRoutes: Array<PathRouteProps> = [
  {
    path: ProtectAssetsRoutes.root,
    element: (
      <MainTopBarLayout title="Policies">
        <ProtectAssetsPage />
      </MainTopBarLayout>
    ),
  },
]
