import React, { memo } from 'react'
import PagePathConstant from '@lib/constants/page-path.constant'
import PageHelper from '@lib/helpers/page.helper'
import { Button } from '@mui/material'
import { Link } from '@lib/router'

function ProtectAssetsComplex() {
  return (
    <div className="wrap-1648130105109">
      <div className="onboardingBlock">
        <div className="flexRowJustifyStart">
          <div className="ml15">
            <div className="obHeader">
              Protect all EC2 assets using a policy
            </div>
            <div className="controlDescSmall">
              Protect all EC2 assets using a custom schedule, integrity scan
              options and tags.
            </div>
          </div>
        </div>
        <div className="m7">
          <Link
            to={PageHelper.buildOnboardingParamUrl(
              PagePathConstant.POLICIES_ADD
            )}
            passHref
          >
            <Button color="primary" variant="contained" className="sizeS">
              Get Started
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(ProtectAssetsComplex)
