import { Util } from 'cloudinary-core'

abstract class CloudinaryHelper {
  public static openUploadWidget(callback: (error: string, photo: any) => any) {
    const options = {
      cloudName: 'politech',
      uploadPreset: 'my_preset',
      folder: 'user_photos',
      multiple: false,
      clientAllowedFormats: ['jpg', 'jpeg', 'png', 'gif', 'svg'],
      maxFileSize: 3145728,
      cropping: true,
      resourceType: 'image',
      theme: 'white',
      styles: {
        palette: {
          link: '#555A5F',
        },
      },
    }
    const scOptions = Util.withSnakeCaseKeys(options)
    // @ts-ignore
    window.cloudinary.openUploadWidget(scOptions, callback)
  }
}

export default CloudinaryHelper
