import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import {
  AddPolicyComplex,
  AddRetentionPolicyComplex,
  EditPolicyRetentionCustom,
  EditPolicyRetentionDefault,
  PoliciesComplex,
} from '@components/components-complex'
import { PoliciesRoutes } from './policies'
import EditPolicyComplex from '@components-complex/edit-policy-complex/EditPolicyComplex'

export const policiesRoutes: Array<PathRouteProps> = [
  {
    path: PoliciesRoutes.root,
    element: (
      <MainTopBarLayout title="Policies">
        <PoliciesComplex />
      </MainTopBarLayout>
    ),
  },
  {
    path: PoliciesRoutes.policyBackupWithIdPlaceholder,
    element: (
      <MainTopBarLayout title="Edit policy">
        <div className="innerContent innerContentBlueBackground jsEditPolicyPage">
          <EditPolicyComplex />
        </div>
      </MainTopBarLayout>
    ),
  },
  {
    path: PoliciesRoutes.policyBackupCreate,
    element: (
      <MainTopBarLayout title="Add New Policy">
        <AddPolicyComplex />
      </MainTopBarLayout>
    ),
  },

  {
    path: PoliciesRoutes.policyRetentionCreate,
    element: (
      <MainTopBarLayout title="Add Retention Policy">
        <AddRetentionPolicyComplex />
      </MainTopBarLayout>
    ),
  },
  {
    path: PoliciesRoutes.policyRetentionEdit,
    element: (
      <MainTopBarLayout title="Edit Default Retention Policy">
        <EditPolicyRetentionDefault />
      </MainTopBarLayout>
    ),
  },
  {
    path: PoliciesRoutes.policyRetentionEditWithIdPlaceholder,
    element: (
      <MainTopBarLayout title="Edit Retention Policy">
        <EditPolicyRetentionCustom />
      </MainTopBarLayout>
    ),
  },
]
