/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { getSelectedFilters } from '..'
import { fetchInitialJobsList, fetchJobsListViaPagination } from './actions'
import { getInitialState } from './consts'
import { DeploymentJobsStore } from '.'

export const useDeploymentJobsStore = create<DeploymentJobsStore>(
  (set, get) => ({
    ...getInitialState(),
    actions: {
      resetDeploymentJobsState: () => set(getInitialState()),
      setDeploymentJobsList: (deploymentJobsList) =>
        set({ deploymentJobsList }),
      fetchInitialDeploymentJobs: async () => {
        const { selectedFilters, timeRange } = getSelectedFilters()

        set({
          loadingState: 'loading',
          deploymentJobsList: [],
        })

        const { jobsList, pageToken } = await fetchInitialJobsList(
          selectedFilters,
          timeRange
        )

        set({
          deploymentJobsList: jobsList,
          pageToken,
          loadingState: 'idle',
        })
      },
      fetchPaginatedDeploymentJobs: async () => {
        const { pageToken } = get()

        if (!pageToken) {
          return
        }

        set({ loadingState: 'loading' })

        const deploymentJobs = await fetchJobsListViaPagination(pageToken)

        set((state) => ({
          deploymentJobsList: [
            ...state.deploymentJobsList,
            ...deploymentJobs.jobsList,
          ],
          pageToken: deploymentJobs.pageToken,
          loadingState: 'idle',
        }))
      },
    },
  })
)
