/* eslint-disable import/no-extraneous-dependencies */
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { Snapshot as SnapshotProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/backups/aws/ebs_snapshot/snapshot_pb'
import { EBSSnapshot } from 'blues-corejs/dist'
import {
  CheckStatus,
  CorrespondingBackup,
  EBSSnapshotStatus,
  StorageTier,
} from 'blues-corejs/dist/models/backups/aws/ebs-snapshot/types'

type SnapshotPt = SnapshotProto.AsObject

export class EbsSnapshotTransformer {
  #snapshot: SnapshotPt

  constructor(snapshot: SnapshotPt) {
    this.#snapshot = snapshot
  }

  transform(): EBSSnapshot {
    return this.#transformSnapshot(this.#snapshot)
  }

  #transformSnapshot(snapshot: SnapshotPt): EBSSnapshot {
    return new EBSSnapshot({
      id: snapshot.id,
      awsAccountId: snapshot.awsAccountId,
      awsId: snapshot.awsId,
      awsRegion: snapshot.awsRegion,
      arn: snapshot.arn,
      tags: snapshot.tagsList,
      encrypted: snapshot.encrypted,
      publiclyShared: snapshot.publiclyShared,
      size: snapshot.size?.value ?? 0,
      volumeId: snapshot.volumeId,
      jobId: snapshot.jobId,
      description: snapshot.description,
      backupPolicyName: snapshot.backupPolicyNameList,
      threatIds: snapshot.threatIdsList,
      scanIds: snapshot.scanIdsList,
      correspondingBackup: this.#convertCorrespondingBackup(
        snapshot.correspondingBackup as SnapshotProto.CorrespondingBackup.AsObject
      ),
      fsCheckStatus: this.#convertCheckStatus(snapshot.fsCheckStatus),
      malwareCheckStatus: this.#convertCheckStatus(snapshot.malwareCheckStatus),
      ransomwareCheckStatus: this.#convertCheckStatus(
        snapshot.ransomwareCheckStatus
      ),
      status: this.#convertStatus(snapshot.status),
      storageTier: this.#convertStorageTier(snapshot.storageTier),
      timestamp: secondsToDate(snapshot.timestamp?.seconds),
      createdAt: secondsToDate(snapshot.createdAt?.seconds),
      deletedAt: snapshot.deletedAt?.seconds
        ? secondsToDate(snapshot.deletedAt?.seconds)
        : undefined,
      updatedAt: secondsToDate(snapshot.updatedAt?.seconds),
    })
  }

  #convertStorageTier(tier: SnapshotProto.StorageTier): StorageTier {
    const mapping = {
      [SnapshotProto.StorageTier.STORAGE_TIER_ARCHIVE]: StorageTier.ARCHIVE,
      [SnapshotProto.StorageTier.STORAGE_TIER_STANDARD]: StorageTier.STANDARD,
    }

    return mapping[tier]
  }

  #convertStatus(status: SnapshotProto.Status): EBSSnapshotStatus {
    const mapping = {
      [SnapshotProto.Status.STATUS_COMPLETED]: EBSSnapshotStatus.COMPLETED,
      [SnapshotProto.Status.STATUS_PENDING]: EBSSnapshotStatus.PENDING,
      [SnapshotProto.Status.STATUS_ERROR]: EBSSnapshotStatus.ERROR,
    }

    return mapping[status]
  }

  #convertCheckStatus(status: SnapshotProto.CheckStatus): CheckStatus {
    const mapping = {
      [SnapshotProto.CheckStatus.CHECK_STATUS_BAD]: CheckStatus.BAD,
      [SnapshotProto.CheckStatus.CHECK_STATUS_GOOD]: CheckStatus.GOOD,
      [SnapshotProto.CheckStatus.CHECK_STATUS_UNCHECKED]: CheckStatus.UNCHECKED,
    }

    return mapping[status]
  }

  #convertCorrespondingBackup(
    backup?: SnapshotProto.CorrespondingBackup.AsObject
  ): CorrespondingBackup {
    const correspondingBackup: Partial<CorrespondingBackup> = {}

    if (!backup) {
      return correspondingBackup as CorrespondingBackup
    }

    const awsBackupId = backup.awsBackup?.id

    const elastioRpId = backup.elastioRecoveryPoint?.id

    if (awsBackupId) {
      correspondingBackup.awsBackup = {
        awsbRpId: awsBackupId,
      }
    }

    if (elastioRpId) {
      correspondingBackup.elastioRecoveryPoint = {
        erpId: elastioRpId,
      }
    }

    return correspondingBackup as CorrespondingBackup
  }
}
