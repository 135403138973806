/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { ElastioRecoveryPoint } from 'blues-corejs/dist'
import { Policy } from 'blues-corejs/dist/models/policy'
import { BackupProcessor } from '@lib/clients/assets/backup-processor'
import { InventoryTableParams } from '@lib/router/routes/dashboard/dashboard'

function getSla<T extends AWSAsset>(asset: T, policies: Array<Policy>) {
  const relatedPolicies = policies.filter(({ id }) =>
    asset.backupPolicies.includes(id)
  )

  const frequencies = relatedPolicies.map(({ sla }) => sla)

  const minFrequency = Math.min(...frequencies)

  return minFrequency
}

export function isAssetOutOfSchedule<T extends AWSAsset>(
  asset: T,
  lastBackups: BackupProcessor,
  policies: Array<Policy>
): boolean {
  const lastBackup = lastBackups.backups.get(asset.id)

  const isElastioLastBackup = lastBackup instanceof ElastioRecoveryPoint

  if (!isElastioLastBackup) {
    return false
  }

  if (!lastBackup) {
    return false
  }

  return (
    Date.now() - lastBackup.createdAt.getTime() > getSla(asset, policies) * 1.5 // We consider that asset is out of schedule if it's RPO is 1.5 times more than SLA
  )
}

export const rpoAscParam: InventoryTableParams = {
  rpo: 'asc',
}

export const rpoDescParam: InventoryTableParams = {
  rpo: 'desc',
}

export const showRpoNoBackupsParam: InventoryTableParams = {
  ...rpoAscParam,
  filter: 'noBackupsOnSchedule',
}

export const showRpoBackupsParam: InventoryTableParams = {
  ...rpoAscParam,
  filter: 'backupsOnSchedule',
}
