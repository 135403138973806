import DividerMUI from '@mui/material/Divider'
import React from 'react'

export default function Divider() {
  return (
    <DividerMUI
      orientation="horizontal"
      flexItem
      sx={{
        my: 2,
        alignSelf: 'stretch',
      }}
    />
  )
}
