export enum EntropyDetectionNotificationsStatus {
  ENABLED = 0,
  DISABLED = 1,
}

export interface TenantSettingsAttrs {
  entropyDetectionStatus: EntropyDetectionNotificationsStatus
}

export class TenantSettings {
  #entropyDetectionStatus: EntropyDetectionNotificationsStatus

  constructor(attrs: TenantSettingsAttrs) {
    this.#entropyDetectionStatus = attrs.entropyDetectionStatus
  }

  get isEntropyDetectionEnabled(): boolean {
    return (
      this.#entropyDetectionStatus ===
      EntropyDetectionNotificationsStatus.ENABLED
    )
  }

  get isEntropyDetectionDisabled(): boolean {
    return (
      this.#entropyDetectionStatus ===
      EntropyDetectionNotificationsStatus.DISABLED
    )
  }
}
export type UpdateTenantSettingsRequestAttrs = Partial<TenantSettingsAttrs>
