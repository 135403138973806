import { create } from 'zustand'
import {
  ListOvaServersClient,
  ListOvaServersFilter,
  ListOvaServersPagination,
  ListOvaServersParams,
  MaybeInfectedServer,
} from '@lib/clients/assets/list-ova-servers'
import { SystemHelper } from '@lib/helpers'
import { useShallow } from 'zustand/react/shallow'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'

const listOvaServersClient = new ListOvaServersClient()

interface OvaServersWithThreatsStoreActions {
  fetchInitial: (filters?: ListOvaServersParams) => Promise<void>
  fetchNextPage: () => Promise<void>
}

interface OvaServersWithThreatsStoreState {
  ovaServers: Array<MaybeInfectedServer>
  totalServers: number
  totalAccounts: number
  totalProviders: number
  isLoading: boolean
  pageToken?: string
}

const INITIAL_STATE: OvaServersWithThreatsStoreState = {
  ovaServers: [],
  totalServers: 0,
  totalAccounts: 0,
  totalProviders: 0,
  isLoading: false,
}

const OvaServersWithThreatsStore = create<
  OvaServersWithThreatsStoreState & {
    actions: OvaServersWithThreatsStoreActions
  }
>((set, get) => ({
  ...INITIAL_STATE,
  actions: {
    fetchInitial: async (filters?: ListOvaServersParams) => {
      set({
        ...INITIAL_STATE,
        isLoading: true,
      })

      try {
        const response = await listOvaServersClient.list(
          new ListOvaServersFilter({
            ...filters,
            statusesList: [OvaServerStatus.INFECTED],
          })
        )

        set({
          ovaServers: response.servers,
          totalServers: response.totalServers,
          totalAccounts: response.totalAccounts,
          totalProviders: response.totalProviders,
          pageToken: response.pageToken,
        })
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(error.message as string)
        }
      } finally {
        set({ isLoading: false })
      }
    },
    fetchNextPage: async () => {
      const { pageToken } = get()

      if (!pageToken) {
        return
      }

      set({ isLoading: true })

      try {
        const response = await listOvaServersClient.list(
          new ListOvaServersPagination(pageToken)
        )

        set((state) => ({
          ovaServers: [...state.ovaServers, ...response.servers],
          pageToken: response.pageToken,
        }))
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(error.message as string)
        }
      } finally {
        set({ isLoading: false })
      }
    },
  },
}))

export const useOvaServersWithThreatsStoreActions = () =>
  OvaServersWithThreatsStore(useShallow((state) => state.actions))

export const useOvaServersWithThreatsStoreState = () =>
  OvaServersWithThreatsStore(
    useShallow((state) => ({
      ovaServers: state.ovaServers,
      totalServers: state.totalServers,
      totalAccounts: state.totalAccounts,
      totalProviders: state.totalProviders,
    }))
  )

export const useIsLoadingOvaServersWithThreats = () =>
  OvaServersWithThreatsStore((state) => state.isLoading)
