import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { Option } from 'ui-v2/src/lib/models/reports'

const RangeData = ({
  value,
  reportPeriodOptions,
  onChange,
}: RangeDataProps) => {
  return (
    <BasicSelect
      value={value}
      onChange={(val) => {
        onChange(val as string)
      }}
      options={reportPeriodOptions}
      size="small"
    />
  )
}
interface RangeDataProps {
  value: string | number
  reportPeriodOptions: Array<Option>
  onChange: (value: string) => void
}
export default RangeData
