/* eslint-disable import/no-extraneous-dependencies */
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import { Pagination, TimeRange } from '../../types'

export type ListScansForAsset = {
  runsList: Array<{
    filesystemChecks: FilesystemScanCheck
    malwares: MalwareScan
    ransomwares: RansomwareScan
  }>
}
export type ListScans = {
  filesystemChecks: Array<FilesystemScanCheck>
  malwares: Array<MalwareScan>
  ransomwares: Array<RansomwareScan>
}

interface ListScansForAssetFilters {
  assetIdList?: Array<string>
  assetItemId?: string
  typesList?: any // Array<Request.Initial.Filter.Type> -> implement if we need it
  kindsList?: any // Array<Request.Initial.Filter.Kind> -> implement if we need it
  statusesList?: any // Array<Request.Initial.Filter.Status> -> implement if we need it
  onlyForAsset?: boolean // if true get only scansForAssets
  providersList?: Array<any> // Request.Initial.Filter.Provider -> implement if we need it
  timeRange?: TimeRange // Filter.TimeRange -> implement if we need it
  pageSize?: number
}

export type ListScansForAssetRequest = ListScansForAssetFilters & Pagination

export interface ListScansForAssetPagination {
  pageToken?: string
}

export type ListScansForAssetRequestFilters = ListScansForAssetFilters &
  ListScansForAssetPagination

export type AssetId = {
  genericHost: string
  s3Bucket: string
  ec2Instance: string
  ebsVolume: string
  efsFilesystem: string
}

export enum ScanTargetKind {
  BACKUP = 0,
  DIRECT_SCAN = 1,
}

export enum Kind {
  RANSOMWARE = 0,
  MALWARE = 1,
  FILE_SYSTEM_CHECK = 2,
}

export enum Status {
  GOOD = 0,
  BAD = 1,
}

export enum ScanKind {
  RANSOMWARE = 0,
  MALWARE = 1,
  FILE_SYSTEM_CHECK = 2,
}

export interface GetScanFiltersRequest {
  timeRange?: TimeRange // Filter.TimeRange -> implement if we need it
  scanKinds: Array<ScanKind> // required field
}

export interface ListScansByFiltersReqFilterParams {
  accountIdsList?: Array<string>
  regionsList?: Array<string>
  scanTargetKindsList?: Array<ScanTargetKind>
  humanReadableRecoveryPointIdsList?: Array<string>
  assetIdList?: Array<string>
  statusesList?: Array<Status>
  kindsList?: Array<Kind>
  timeRange?: TimeRange
  pageSize?: number
}

export type ListScansByFiltersRequest = ListScansByFiltersReqFilterParams &
  Pagination

export enum CloudConnectorStatus {
  UNDEFINED = -1,
  DISCONNECTED = 0,
  CONNECTED = 1,
}
