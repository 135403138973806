import { getOvaDashboardState } from '@features/ova-dashboard/ova-dashboard-store'
import { KrabsOvaStatisticsClient, OvaStatisticsClient } from '@lib/clients'
import { AccountSummaryResponse as KrabsAccountSummaryResponse } from '@lib/clients/krabs-ova-statistics/types'
import { AccountSummaryResponse as OvaAccountSummaryResponse } from '@lib/clients/ova-statistics/types'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

export type AccountStatistics = OvaAccountSummaryResponse &
  KrabsAccountSummaryResponse

const ovaStatisticsClient = new OvaStatisticsClient()
const krabsOvaStatisticsClient = new KrabsOvaStatisticsClient()

const EMPTY_DATA_FOR_KRABS_RESPONSE: Omit<
  KrabsAccountSummaryResponse,
  'ovaAccountId' | 'ovaAccountName'
> = {
  dataScanned: 0,
  frontEndDataProtected: 0,
}

function mergeAccountStatisticsResponses(
  responseFromOvaStatistic: Array<OvaAccountSummaryResponse>,
  responseFromKrabs: Array<KrabsAccountSummaryResponse>
): Array<AccountStatistics> {
  const accountStatisticsList: Array<AccountStatistics> = []

  for (const ovaAccount of responseFromOvaStatistic) {
    const krabsAccount = responseFromKrabs.find(
      (account) => account.ovaAccountId === ovaAccount.ovaAccountId
    )

    accountStatisticsList.push({
      ...ovaAccount,
      dataScanned:
        krabsAccount?.dataScanned || EMPTY_DATA_FOR_KRABS_RESPONSE.dataScanned,
      frontEndDataProtected:
        krabsAccount?.frontEndDataProtected ||
        EMPTY_DATA_FOR_KRABS_RESPONSE.frontEndDataProtected,
    })
  }

  return accountStatisticsList
}

interface AccountSummaryStoreState {
  accountStatisticsList: Array<AccountStatistics>
  isLoading: boolean
}

interface AccountSummaryStoreActions {
  fetchAccountsSummary: () => Promise<void>
  resetStore: () => void
}

const getInitialState = (): AccountSummaryStoreState => ({
  accountStatisticsList: [],
  isLoading: false,
})

const useAccountSummaryStore = create<
  AccountSummaryStoreState & {
    actions: AccountSummaryStoreActions
  }
>((set) => ({
  accountStatisticsList: [],
  isLoading: false,
  actions: {
    fetchAccountsSummary: async () => {
      set({ isLoading: true })

      const { ovaBackupProvidersList, ovaAccountsList } = getOvaDashboardState()

      const ovaStatistics = await ovaStatisticsClient.listAccountSummaries({
        backupProviders: ovaBackupProvidersList,
        ovaAccountIds: ovaAccountsList,
      })

      const krabsOvaStatistics =
        await krabsOvaStatisticsClient.listAccountSummaries({
          backupProviders: ovaBackupProvidersList,
          ovaAccountIds: ovaAccountsList,
        })

      const accountStatisticsList = mergeAccountStatisticsResponses(
        ovaStatistics,
        krabsOvaStatistics
      )

      set({
        accountStatisticsList,
        isLoading: false,
      })
    },
    resetStore: () => {
      set(getInitialState())
    },
  },
}))

export const useAccountSummaryStoreActions = () =>
  useAccountSummaryStore((state) => state.actions)

export const useAccountSummaryStoreData = () =>
  useAccountSummaryStore(useShallow((state) => state.accountStatisticsList))

export const useIsAccountsDataLoading = () =>
  useAccountSummaryStore(useShallow((state) => state.isLoading))
