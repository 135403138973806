export interface DashboardViewForm {
  name: string
  dashboardView: DashboardViewOptions
  accounts: Array<string>
  regions: Array<string>
  includingTags: Array<string>
  includingTagsOperation: TagsOperations
  excludingTags: Array<string>
  excludingTagsOperation: TagsOperations
}

export enum DashboardViewOptions {
  BACKUPS = 0, // UI: Recovery Points
  SECURITY = 1,
  ALL = 2, // UI: Backups & Scans
  OVA = 3,
}

export enum TagsOperations {
  AND = 0,
  OR = 1,
}

export enum FieldNames {
  NAME = 'name',
  DASHBOARD_VIEW = 'dashboardView',
  ACCOUNTS = 'accounts',
  REGIONS = 'regions',
  INCLUDING_TAGS = 'includingTags',
  INCLUDING_TAGS_OPERATION = 'includingTagsOperation',
  EXCLUDING_TAGS = 'excludingTags',
  EXCLUDING_TAGS_OPERATION = 'excludingTagsOperation',
}
