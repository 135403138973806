/* eslint-disable import/no-extraneous-dependencies */
import { PolicyS3BucketTableData } from 'ui-v2/src/lib/use-cases/settings/policies/s3-bucket-table-data'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { useEffect, useMemo } from 'react'
import { getS3BucketColumns } from './column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import {
  PolicyFormData,
  PolicyS3BucketTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

const AssetsS3 = ({
  s3Buckets,
  isLoading,
  handleAssetsSelect,
}: AssetsS3Props) => {
  const { id } = useParams()
  const { watch, getValues, setValue, trigger } =
    useFormContext<PolicyFormData>()
  const selectedS3BucketAssetsRows = watch('selectedS3BucketAssetsRows')

  const data = useMemo(() => {
    if (!s3Buckets) {
      return
    }

    return new PolicyS3BucketTableData({
      s3Buckets,
    }).getS3BucketAssetsList()
  }, [s3Buckets])

  useEffect(() => {
    if (id && data) {
      const selectedS3Assets = getValues('selectedS3Assets')
      const selectedS3AssetsIds = selectedS3Assets.map(
        (asset) => asset.asset.id
      )
      const selectedS3BucketRows = data.filter((row) =>
        selectedS3AssetsIds.includes(row.id)
      )

      setValue('selectedS3BucketAssetsRows', selectedS3BucketRows)
    }
  }, [id, data])

  const columns = useMemo(getS3BucketColumns, [])

  return (
    <ClientTable
      checkable
      data={data ?? []}
      columns={columns}
      isLoading={isLoading}
      selectedRows={selectedS3BucketAssetsRows}
      onSelectRows={(rows) => {
        handleAssetsSelect(rows)
        trigger('tabAssetTypeSelectedNum')
      }}
    />
  )
}
interface AssetsS3Props {
  s3Buckets: AssetsForPolicyCoverage | undefined
  isLoading: boolean
  handleAssetsSelect: (rows: Array<PolicyS3BucketTableRow>) => void
}
export default AssetsS3
