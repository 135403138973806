import React from 'react'
import { VINumberMap, VIRow } from '@lib/engine-types'
import { drawVICell } from '@tables/core/table-draw'

interface Props {
  data: VIRow
  columnDrawMapper?: VINumberMap
}

function SimpleVIRow({ data, columnDrawMapper = {} }: Props) {
  const columnState = data.map(() => 1)
  return (
    <tr>
      {data.map((v, index) =>
        drawVICell(columnDrawMapper, v, index, columnState)
      )}
    </tr>
  )
}

export default SimpleVIRow
