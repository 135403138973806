import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { PechkinClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function usePausePlanMutation() {
  const pechkinClient = useGrpcClient(PechkinClient)

  return useMutation({
    mutationFn: async (id: string) => {
      if (!pechkinClient) {
        throw new Error('Pechkin client not initialized')
      }
      return pechkinClient.pausePlan(id)
    },
  })
}
