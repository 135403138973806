/* eslint-disable import/no-extraneous-dependencies */
import {
  EC2,
  EBS,
  S3Bucket,
  EFS,
  GenericHost,
  Asset,
  OvaServer,
} from 'blues-corejs/dist'
import SystemHelper from '@lib/helpers/system.helper'

export function computeAssetKind(asset: Asset): string {
  switch (asset.constructor) {
    case EC2:
      return 'EC2'
    case EBS:
      return 'EBS'
    case S3Bucket:
      return 'S3'
    case EFS:
      return 'EFS'
    case GenericHost:
      return 'Generic Host'
    case OvaServer:
      return 'Server'
    default:
      SystemHelper.sendSentryIfProd(
        `feature/active-threats -> computeAssetKind: Unknown asset kind: ${asset.id}`
      )
      throw new Error(
        `feature/active-threats -> computeAssetKind: Unknown asset kind: ${asset.id}`
      )
  }
}
