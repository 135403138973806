import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { UsersClient } from 'ui-v2/src/lib/clients'
import { UserSettings } from 'ui-v2/src/lib/constants/settings.constant'
import { useMutation } from '@tanstack/react-query'

export function useUpdateSettingMutation() {
  const usersClient = useGrpcClient(UsersClient)

  return useMutation({
    mutationFn: async ({ name, value }: { name: UserSettings; value: any }) => {
      if (!usersClient) {
        throw new Error('Users client not initialized')
      }
      return usersClient.updateSetting(name, value)
    },
  })
}
