/* eslint-disable import/no-extraneous-dependencies */
import { GetScanFiltersRequest } from '@lib/clients/scans/types'
import SystemHelper from '@lib/helpers/system.helper'
import { useEffect, useState } from 'react'
import {
  GetScanFiltersClient,
  GetScanFiltersResponse,
} from '@lib/clients/scans/get-scan-filters'

const scanFiltersClient = new GetScanFiltersClient()

const INITIAL_STATE: GetScanFiltersResponse = {
  accountIdsList: [],
  regionsList: [],
  scanTargetKindsList: [],
  humanReadableRecoveryPointIdsList: [],
  assetIdList: [],
  statusesList: [],
  kindsList: [],
}

type FetchScanFiltersOutput = {
  possibleScanFilters: GetScanFiltersResponse
  isFiltersLoading: boolean
}

function useFetchPossibleScanFilters(
  filters: GetScanFiltersRequest
): FetchScanFiltersOutput {
  const [isFiltersLoading, setIsFiltersLoading] = useState(false)
  const [possibleScanFilters, setPossibleScanFilters] =
    useState<GetScanFiltersResponse>(INITIAL_STATE)

  useEffect(() => {
    async function fetchPossibleScanFilters() {
      setIsFiltersLoading(true)
      try {
        const scanFiltersResponse =
          await scanFiltersClient.getScanFilters(filters)

        setPossibleScanFilters(scanFiltersResponse)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong in ${__filename}: ${error}`
        )
        console.error(
          `Error occurred in file ${__filename} while processing: ${error}`
        )
      } finally {
        setIsFiltersLoading(false)
      }
    }

    fetchPossibleScanFilters()

    return () => {
      setPossibleScanFilters(INITIAL_STATE)
    }
  }, [JSON.stringify(filters)])

  return {
    possibleScanFilters,
    isFiltersLoading,
  }
}

export default useFetchPossibleScanFilters
