/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import { Backup, AssetItem, Asset } from 'blues-corejs/dist'
import React from 'react'
import {
  AccountId,
  Region,
  BackupKind,
  AssetId,
  MostRecentScan,
  RecoveryPointDetailsHeader,
  RecoveryPointDetailsTabs,
  RecoveryPointTabsContent,
} from './components/'
import {
  DetectedTreatsIcons,
  BackupProvider,
  ThreatSummary,
} from '@features/asset-page/shared'
import { useFetchScansByScanIds } from './helpers/use-get-scans-by-scan-ids'
import { useThreatsByIds } from '@components-complex/dashboard-pages-v3'
import { useRecoveryPointTabsState } from './helpers/use-rp-tabs-state'
import { OvaBackup, OvaServer } from 'blues-corejs/dist/models'
import { ReplicatedDetails } from './components/replicated-details'

interface Props {
  asset: Asset
  assetItem?: AssetItem
  backup: Backup
  isRecoveryPointDetailsOpen: boolean
  onCloseRecoveryPointDetails: () => void
}

function AwsAssetCredentials({
  asset,
  backup,
  assetItem,
}: {
  asset: Asset
  backup: Backup
  assetItem?: AssetItem
}) {
  if (backup instanceof OvaBackup || asset instanceof OvaServer) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" gap="24px">
      <AssetId assetItem={assetItem} asset={asset} />
      <AccountId asset={asset} />
      <Region asset={asset} />
    </Box>
  )
}

const DIVIDER_PROPS = ['marginX', 'marginY']

const StyledDivider = styled(Divider, {
  shouldForwardProp: (prop) => {
    return typeof prop === 'string' && !DIVIDER_PROPS.includes(prop)
  },
})<{
  marginY?: string
  marginX?: string
}>(({ marginX, marginY }) => ({
  marginBlock: marginY,
  marginInline: marginX,
}))

export function RecoveryPointDetails({
  asset,
  assetItem,
  backup,
  isRecoveryPointDetailsOpen,
  onCloseRecoveryPointDetails,
}: Props) {
  const { dataThreats, isLoadingThreats, fetchThreatsWithoutLoader } =
    useThreatsByIds([...backup.threatIds])

  const { scans, isScansLoading } = useFetchScansByScanIds([...backup.scanIds])

  const {
    getIsSliderTabSelected,
    getIsTableTabSelected,
    selectedTab,
    setSelectedTab,
  } = useRecoveryPointTabsState(backup)

  if (isLoadingThreats || isScansLoading) {
    return null
  }

  const scansList = [
    ...scans.filesystemChecks,
    ...scans.malwares,
    ...scans.ransomwares,
  ]

  const isTableTabSelected = getIsTableTabSelected()

  const isSliderTabSelected = getIsSliderTabSelected()

  return (
    <Dialog
      open={isRecoveryPointDetailsOpen}
      onClose={onCloseRecoveryPointDetails}
    >
      <DialogContent>
        <RecoveryPointDetailsHeader
          backup={backup}
          asset={asset}
          onCloseRecoveryPointDetails={onCloseRecoveryPointDetails}
        />
        <Box display="flex" alignItems="center">
          <BackupProvider backup={backup} />
          <StyledDivider orientation="vertical" flexItem marginX="5px" />
          <BackupKind backup={backup} />
        </Box>
        <ReplicatedDetails asset={asset} backup={backup} />

        <StyledDivider marginY="15px" flexItem />
        <AwsAssetCredentials
          asset={asset}
          backup={backup}
          assetItem={assetItem}
        />
        <Box display="flex" alignItems="flex-start" gap="32px" marginTop="24px">
          <DetectedTreatsIcons backup={backup} />
          <ThreatSummary threats={dataThreats} />
        </Box>
        <MostRecentScan scans={scansList} />
        <StyledDivider marginY="24px" orientation="horizontal" />
        <RecoveryPointDetailsTabs
          backup={backup}
          selectedTab={selectedTab}
          onChangeTab={setSelectedTab}
        />
        <RecoveryPointTabsContent
          assetItem={assetItem}
          backup={backup}
          dataThreats={dataThreats}
          asset={asset}
          scansList={scansList}
          isSliderTabSelected={isSliderTabSelected}
          isTableTabSelected={isTableTabSelected}
          fetchDataThreats={fetchThreatsWithoutLoader}
        />
      </DialogContent>
    </Dialog>
  )
}
