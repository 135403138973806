import ActionInterface from '@lib/interfaces/action.interface'
import AddRetentionPolicyState from '@store/states/add-retention-policy.state'
import {
  SET_ADD_POLICY_RETENTION_ACCOUNTS,
  SET_ADD_POLICY_RETENTION_ASSET_SOURCES,
  SET_ADD_POLICY_RETENTION_BACKUP_TYPES,
  SET_ADD_POLICY_RETENTION_REGIONS,
  SET_ADD_POLICY_RETENTION_SELECTED_FILTERS,
  SET_ADD_POLICY_RETENTION_TAGS,
  SET_ADD_POLICY_RETENTION_VAULTS,
  SET_ADD_RETENTION_POLICY_NAME,
  SET_RECOVERY_PERIOD,
  SET_ADD_POLICY_RETENTION_COMPLETE,
} from '@store/actions/add-retention-policy.action'

const initialState: AddRetentionPolicyState = {
  retentionPolicyName: '',
  recoveryPeriod: {
    period: [
      {
        periodValue: 'All',
        countDuration: '',
        duration: '',
        removable: false,
      },
    ],
  },
  policyRetentionAccountIds: [],
  policyRetentionRegions: [],
  policyRetentionVaults: [],
  policyRetentionBackupTypes: [],
  policyRetentionAssetSources: [],
  policyRetentionTags: [],
  assetsSelectedFilters: [],
  addPolicyRetentionComplete: false,
}

function addRetentionPolicyReducer(
  prevState = initialState,
  action: ActionInterface
): AddRetentionPolicyState {
  switch (action.type) {
    case SET_ADD_RETENTION_POLICY_NAME:
      return {
        ...prevState,
        retentionPolicyName: action.payload,
      }

    case SET_RECOVERY_PERIOD:
      return {
        ...prevState,
        recoveryPeriod: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_ACCOUNTS:
      return {
        ...prevState,
        policyRetentionAccountIds: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_REGIONS:
      return {
        ...prevState,
        policyRetentionRegions: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_VAULTS:
      return {
        ...prevState,
        policyRetentionVaults: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_BACKUP_TYPES:
      return {
        ...prevState,
        policyRetentionBackupTypes: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_ASSET_SOURCES:
      return {
        ...prevState,
        policyRetentionAssetSources: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_TAGS:
      return {
        ...prevState,
        policyRetentionTags: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_SELECTED_FILTERS:
      return {
        ...prevState,
        assetsSelectedFilters: action.payload,
      }

    case SET_ADD_POLICY_RETENTION_COMPLETE:
      return {
        ...prevState,
        addPolicyRetentionComplete: action.payload,
      }

    default:
      return prevState
  }
}

export default addRetentionPolicyReducer
