import React from 'react'

export default function JobSuccessIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7946_116082"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7946_116082)">
        <path
          d="M8.83317 11.5003L7.02067 9.68783C6.86789 9.53505 6.68039 9.45866 6.45817 9.45866C6.23595 9.45866 6.0415 9.54199 5.87484 9.70866C5.72206 9.86144 5.64567 10.0559 5.64567 10.292C5.64567 10.5281 5.72206 10.7225 5.87484 10.8753L8.24984 13.2503C8.40262 13.4031 8.59706 13.4795 8.83317 13.4795C9.06928 13.4795 9.26373 13.4031 9.4165 13.2503L14.1457 8.52116C14.2984 8.36838 14.3748 8.18088 14.3748 7.95866C14.3748 7.73644 14.2915 7.54199 14.1248 7.37533C13.9721 7.22255 13.7776 7.14616 13.5415 7.14616C13.3054 7.14616 13.1109 7.22255 12.9582 7.37533L8.83317 11.5003ZM9.99984 18.3337C8.84706 18.3337 7.76373 18.1148 6.74984 17.677C5.73595 17.2398 4.854 16.6462 4.104 15.8962C3.354 15.1462 2.76039 14.2642 2.32317 13.2503C1.88539 12.2364 1.6665 11.1531 1.6665 10.0003C1.6665 8.84755 1.88539 7.76421 2.32317 6.75033C2.76039 5.73644 3.354 4.85449 4.104 4.10449C4.854 3.35449 5.73595 2.7606 6.74984 2.32283C7.76373 1.8856 8.84706 1.66699 9.99984 1.66699C11.1526 1.66699 12.2359 1.8856 13.2498 2.32283C14.2637 2.7606 15.1457 3.35449 15.8957 4.10449C16.6457 4.85449 17.2393 5.73644 17.6765 6.75033C18.1143 7.76421 18.3332 8.84755 18.3332 10.0003C18.3332 11.1531 18.1143 12.2364 17.6765 13.2503C17.2393 14.2642 16.6457 15.1462 15.8957 15.8962C15.1457 16.6462 14.2637 17.2398 13.2498 17.677C12.2359 18.1148 11.1526 18.3337 9.99984 18.3337Z"
          fill="#70D180"
        />
      </g>
    </svg>
  )
}
