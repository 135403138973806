import React from 'react'
import ValueInterface from '@lib/interfaces/value.interface'
import { Button } from '@mui/material'
import {
  requestDisconnectSlackFromTenant,
  requestTenantNotificationsSettings,
} from '@store/actions/tenant-notifications.action'
import { Dispatch, memo, SetStateAction, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import NotificationsCheckboxBlock from '@components-composite/notifications-checkbox-block/NotificationsCheckboxBlock'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import SpecificEmailsBlock from '@components-composite/specific-emails-block/SpecificEmailsBlock'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@lib/router'

interface Props {
  errors: {
    state: Array<ValueInterface>
    setState: Dispatch<SetStateAction<Array<ValueInterface>>>
  }
  warnings: {
    state: Array<ValueInterface>
    setState: Dispatch<SetStateAction<Array<ValueInterface>>>
  }
  success: {
    state: Array<ValueInterface>
    setState: Dispatch<SetStateAction<Array<ValueInterface>>>
  }
  slackUrl?: string
  emailsConfig: {
    emails: Array<string>
    setEmails: React.Dispatch<React.SetStateAction<Array<string>>>
    disabled?: boolean
  }
}

const getTextDependsByFlag = (updatedNotificationsPage: boolean) => {
  const titleForCheckboxGroups = updatedNotificationsPage
    ? 'Send Critical Alerts'
    : 'Alerts'
  const labelForCriticalCheckboxGroups = updatedNotificationsPage
    ? ''
    : 'Critical'
  const integrationTitle = updatedNotificationsPage ? 'Send to' : 'Integrations'
  const integrationDescription = updatedNotificationsPage
    ? 'This is where alerts will be sent if you select "specific emails"'
    : undefined

  return {
    titleForCheckboxGroups,
    labelForCriticalCheckboxGroups,
    integrationTitle,
    integrationDescription,
  }
}

const TenantNotificationsComplex = ({
  errors,
  warnings,
  success,
  slackUrl,
  emailsConfig,
}: Props) => {
  const dispatch = useDispatch()
  const { updatedNotificationsPage } = useFlags()

  const { emails, setEmails, disabled = false } = emailsConfig
  const {
    titleForCheckboxGroups,
    labelForCriticalCheckboxGroups,
    integrationTitle,
    integrationDescription,
  } = getTextDependsByFlag(updatedNotificationsPage)

  useEffect(() => {
    dispatch(requestTenantNotificationsSettings())
  }, [])

  const isSlackEnabled = !Boolean(slackUrl)

  const onClickHandler = () => {
    if (isSlackEnabled) {
      dispatch(requestDisconnectSlackFromTenant())
    }
  }

  const notificationsLoading = usePreloader(
    PreloaderConstants.REQUEST_TENANT_NOTIFICATIONS_SETTINGS
  )

  const SlackIntegrationBlock = () => {
    if (isSlackEnabled) {
      return (
        <Button
          variant="contained"
          className="slack-button"
          disabled={notificationsLoading}
          onClick={onClickHandler}
        >
          Disconnect from Slack workspace
        </Button>
      )
    }

    return (
      <Link to={slackUrl || ''} target="_blank" rel="noopener noreferrer">
        <span>
          <Button
            variant="contained"
            className="slack-button"
            disabled={notificationsLoading}
          >
            Connect to Slack workspace
          </Button>
        </span>
      </Link>
    )
  }

  return (
    <Box className="wrap-1659954175617">
      <Box className="frequencyBlock">
        <Typography className="headerText">{titleForCheckboxGroups}</Typography>
        <Box className="checkboxes">
          <NotificationsCheckboxBlock
            text={labelForCriticalCheckboxGroups}
            data={errors.state}
            onChange={errors.setState}
          />
          {!updatedNotificationsPage && (
            <>
              <NotificationsCheckboxBlock
                text="Medium"
                data={warnings.state}
                onChange={warnings.setState}
              />
              <NotificationsCheckboxBlock
                text="Low"
                data={success.state}
                onChange={success.setState}
                className="infoNotificationBlock"
              />
            </>
          )}
        </Box>
      </Box>
      <Box className="integrationsBlock">
        <Typography className="headerText">{integrationTitle}</Typography>
        <Box className="integrationsApps">
          <SpecificEmailsBlock
            emails={emails}
            setEmails={setEmails}
            disabled={disabled}
            description={integrationDescription}
          />
          <Box className="slackBlock">
            <SlackIntegrationBlock />
            {!isSlackEnabled && (
              <Typography className="description">
                This will create a Slack channel in your workspace
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default memo(TenantNotificationsComplex)
