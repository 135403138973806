import { Request as GprcListTenantJobsRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/jobs/list_tenant_jobs_pb'

export interface ListTenantJobsRequest {
  toGrpc(): GprcListTenantJobsRequest
}

export { ListTenantJobsFilter } from './list-tenant-jobs-filter'
export type { ListTenantJobsFilterRequestParams } from './list-tenant-jobs-filter'

export { ListTenantJobsPagination } from './list-tenant-jobs-pagination'
