import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import Stack from '@mui/material/Stack'
import { Toolbar } from './toolbar'
import {
  useIsLoadingOvaServersWithThreats,
  useOvaServersWithThreatsStoreActions,
  useOvaServersWithThreatsStoreState,
} from './ova-servers-with-threats-store'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'
import { columnDefinitions } from './columns-definition'
import { useToolbarFiltersState } from './toolbar/toolbar-filters-store'
import { useOvaServersDrilldownQuery } from './use-ova-servers-drilldown-query'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'

export default function OvaServersWithThreats() {
  const { fetchNextPage, fetchInitial } = useOvaServersWithThreatsStoreActions()

  const { ovaAccountsList: ovaAccountsListState } = useToolbarFiltersState()

  const { ovaServers, totalAccounts, totalProviders, totalServers } =
    useOvaServersWithThreatsStoreState()

  const isLoadingOvaServers = useIsLoadingOvaServersWithThreats()

  const observable = useInfiniteScrollDataFetch({
    initialItems: ovaServers,
    fetchNextBatch: fetchNextPage,
  })

  const { ovaAccountsList, isOvaAccountsLoading } = useFetchListOvaAccounts()

  useOvaServersDrilldownQuery(ovaAccountsList)

  const ovaAccountsListWithInfectedServers = useMemo(() => {
    const ovaAccountsListFromInfectedServers = ovaServers.map(
      ({ server }) => server.ovaAccountId
    )

    const ovaAccountsFromState = ovaAccountsListState.map(({ id }) => id)

    const uniqueOvaAccountsList = new Set([
      ...ovaAccountsListFromInfectedServers,
      ...ovaAccountsFromState,
    ])

    return ovaAccountsList.filter(({ id }) => uniqueOvaAccountsList.has(id))
  }, [ovaAccountsList.length, ovaServers.length, ovaAccountsListState.length])

  const isTableDataLoading = isLoadingOvaServers || isOvaAccountsLoading

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const malwareQuery = urlParams.get('malware')
  const ransomwareQuery = urlParams.get('ransomware')

  const isDrilldownQueryActive = malwareQuery || ransomwareQuery

  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  useEffect(() => {
    if (isDrilldownQueryActive) {
      return
    }

    fetchInitial({
      accountIdsList: sliceOvaAccountsList,
      backupProvidersList: sliceOvaBackupProvidersList,
    })
  }, [isDrilldownQueryActive])

  return (
    <Stack gap={1}>
      <Toolbar ovaAccountsList={ovaAccountsListWithInfectedServers} />
      <Table
        data={ovaServers}
        columns={columnDefinitions({
          ovaAccountsList: ovaAccountsListWithInfectedServers,
          totalServers,
          totalAccounts,
          totalProviders,
        })}
        generalProps={{
          isLoading: isTableDataLoading,
          noDataMessage: 'No infected servers found',
        }}
        advancedProps={{
          observableState: observable,
        }}
      />
    </Stack>
  )
}
