import { create } from 'zustand'

import { RuleFormValues } from './types'
import { defaultValues, FORM_FIELDS } from './consts'

interface NotificationsActions {
  setNotificationsData: (data: RuleFormValues) => void
  resetStore: () => void
}

export interface NotificationsState {
  notificationsData: RuleFormValues
}

export type NotificationsStore = NotificationsActions & NotificationsState

const INITIAL_STATE: NotificationsState = {
  notificationsData: {
    ...defaultValues,
  },
}

const useNotificationsStore = create<NotificationsStore>((set) => ({
  ...INITIAL_STATE,
  setNotificationsData: (data) => set({ notificationsData: data }),
  resetStore: () => set({ ...INITIAL_STATE }),
}))

export const resetNotificationsStore = () => {
  useNotificationsStore.getState().resetStore()
}

export const useNotificationsState = () =>
  useNotificationsStore((state) => state.notificationsData)

export const useNotificationsActions = () =>
  useNotificationsStore((state) => ({
    setNotificationsData: state.setNotificationsData,
    resetStore: state.resetStore,
  }))

export const useWebhooks = () =>
  useNotificationsStore(
    (state) => state.notificationsData[FORM_FIELDS.WEBHOOKS]
  )
