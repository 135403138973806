import { useQuery } from '@tanstack/react-query'
import { WebhooksClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useListEventTypesQuery() {
  const webhooksClient = useGrpcClient(WebhooksClient)

  return useQuery({
    queryKey: ['webhooks', 'list-event-types'],
    queryFn: () => {
      if (!webhooksClient) {
        throw new Error('Webhooks client not initialized')
      }
      return webhooksClient.listEventTypes()
    },
    enabled: !!webhooksClient,
  })
}
