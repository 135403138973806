import { BaseModel } from '../base-model'

class AwsAccountInfoModel extends BaseModel {
  public readonly awsAccountId: string

  public readonly upgradeRequired: boolean

  public readonly version: string

  public readonly reportGatheringEnabled: boolean

  constructor(readonly params: any = {}) {
    super(params)

    this.awsAccountId = params.awsAccountId
    this.reportGatheringEnabled = params.reportGatheringEnabled
    this.version = params.version
    this.upgradeRequired = params.upgradeRequired
  }
}

export default AwsAccountInfoModel
