import React from 'react'

function AlertErrorRoundedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99935 1.66602C5.39935 1.66602 1.66602 5.39935 1.66602 9.99935C1.66602 14.5993 5.39935 18.3327 9.99935 18.3327C14.5993 18.3327 18.3327 14.5993 18.3327 9.99935C18.3327 5.39935 14.5993 1.66602 9.99935 1.66602ZM9.99935 10.8327C9.54102 10.8327 9.16602 10.4577 9.16602 9.99935V6.66602C9.16602 6.20768 9.54102 5.83268 9.99935 5.83268C10.4577 5.83268 10.8327 6.20768 10.8327 6.66602V9.99935C10.8327 10.4577 10.4577 10.8327 9.99935 10.8327ZM10.8327 14.166H9.16602V12.4993H10.8327V14.166Z"
        fill="#ED3F3F"
      />
    </svg>
  )
}

export default AlertErrorRoundedIcon
