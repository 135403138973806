import { useShallow } from 'zustand/react/shallow'
import { useSystemJobsStore } from '@features/jobs/tenant-jobs/system-jobs/stores/system-jobs-store/system-jobs-store'

export const useSystemJobsStoreActions = () =>
  useSystemJobsStore(useShallow((state) => state.actions))

export const useSystemJobsList = () =>
  useSystemJobsStore(useShallow((state) => state.systemJobsList))

export const useSystemJobsIsLoading = () =>
  useSystemJobsStore((state) => state.loadingState === 'loading')
