/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models/scans/index'
import { Threat } from 'blues-corejs/dist'
import { ListBackupsForAssetItem } from 'ui-v2/src/lib/clients/backups'

export interface ScanListTableDataAttrs {
  scanLists: Array<ScansRun>
}
export class ScanListTableRow {
  readonly #id: string

  readonly #scanTime: Date

  readonly #scanType: string

  readonly #source: string

  constructor(parameters: ScanListTableRowAttr) {
    const { id, scanTime, scanType, source } = parameters

    this.#id = id
    this.#scanTime = scanTime
    this.#scanType = scanType
    this.#source = source
  }

  get id(): string {
    return this.#id
  }

  get scanTime(): Date {
    return this.#scanTime
  }

  get scanType(): string {
    return this.#scanType
  }

  get source(): string {
    return this.#source
  }
}
export interface ScanListTableRowAttr {
  id: string
  scanTime: Date
  scanType: string
  source: string
}

export interface ThreatListTableDataAttrs {
  threatsList: Array<Threat>
  assetAwsIdMap: Map<string, string>
}
export class ThreatListTableRow {
  readonly #id: string

  readonly #threat: string

  readonly #infectedFiles: string

  readonly #volume: string

  readonly #detected: Date

  readonly #action: string

  constructor(parameters: ThreatListTableRowAttr) {
    const { id, threat, infectedFiles, volume, detected, action } = parameters

    this.#id = id
    this.#threat = threat
    this.#infectedFiles = infectedFiles
    this.#volume = volume
    this.#detected = detected
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get threat(): string {
    return this.#threat
  }

  get infectedFiles(): string {
    return this.#infectedFiles
  }

  get volume(): string {
    return this.#volume
  }

  get detected(): Date {
    return this.#detected
  }

  get action(): string {
    return this.#action
  }
}
export interface ThreatListTableRowAttr {
  id: string
  threat: string
  infectedFiles: string
  volume: string
  detected: Date
  action: string
}

export interface RecoveryListTableDataAttrs {
  recoveryList: ListBackupsForAssetItem
}
export class RecoveryListTableRow {
  readonly #id: string

  readonly #recoveryPointTime: Date

  readonly #backupSource: string

  readonly #backupSize: string

  readonly #action: string

  constructor(parameters: RecoveryListTableRowAttr) {
    const { id, recoveryPointTime, backupSource, backupSize, action } =
      parameters

    this.#id = id
    this.#recoveryPointTime = recoveryPointTime
    this.#backupSource = backupSource
    this.#backupSize = backupSize
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get recoveryPointTime(): Date {
    return this.#recoveryPointTime
  }

  get backupSource(): string {
    return this.#backupSource
  }

  get backupSize(): string {
    return this.#backupSize
  }

  get action(): string {
    return this.#action
  }
}
export interface RecoveryListTableRowAttr {
  id: string
  recoveryPointTime: Date
  backupSource: string
  backupSize: string
  action: string
}
