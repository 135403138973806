import React, { ElementType, FocusEventHandler } from 'react'
import { Controller } from 'react-hook-form'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { EngineCallback } from '@lib/engine-types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface Props {
  canBeEmpty?: boolean
  field: FormFieldInterface
  control?: any
  disabled?: boolean
  className?: any
  onChange?: EngineCallback<any>
  // eslint-disable-next-line react/require-default-props
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  // eslint-disable-next-line react/require-default-props
  variant?: 'standard' | 'outlined' | 'filled'
  iconComponent?: ElementType
}

function SelectField({
  canBeEmpty = false,
  field,
  control = null,
  disabled,
  className,
  onChange,
  onBlur,
  variant = 'outlined',
  iconComponent = ExpandMoreIcon,
}: Props) {
  const minWidth = field.label ? Math.max(100, field.label.length * 14) : 0
  // const selectFieldWidth = field.label ? field.label.length * 9 : 0;

  const drawSelect = () => (
    <Select
      IconComponent={iconComponent}
      // @ts-ignore: TODO - Fix TypeScript issue: "placeholder" is not assignable to type SelectProps<string[]>.
      placeholder={field.placeholder}
      defaultValue={field.value}
      className={className}
      onBlur={onBlur}
      onChange={onChange}
      variant={variant}
      labelId={`id_label_${field.name}`}
      id={`id_${field.name}`}
      data-testid="jsSelectField"
    >
      {canBeEmpty && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}
      {field.options &&
        field.options.map((option) => (
          <MenuItem key={option.name} value={String(option.name)}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  )

  return (
    <FormControl
      disabled={disabled}
      style={{ minWidth }}
      error={field.hasError}
      variant="outlined"
    >
      <InputLabel id={`id_label_${field.name}`}>{field.label}</InputLabel>
      {control ? (
        <Controller
          name={field.name}
          control={control}
          defaultValue={field.value}
          rules={field.validationRules}
          render={() => drawSelect()}
        />
      ) : (
        drawSelect()
      )}
      <FormHelperText>{field.errorText}</FormHelperText>
    </FormControl>
  )
}

export default SelectField
