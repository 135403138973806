/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import { BaseSelectedChip } from './base-selected-chip'
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import { getHumanReadableCloudConnectorJobKind } from '@features/jobs/cloud-connector-jobs/helpers'

export function CloudConnectorJobKindChip({
  kind,
  onDelete,
}: {
  kind: JobCloudConnectorKind
  onDelete: (kind: JobCloudConnectorKind) => void
}) {
  const handleRemoveFilter = useCallback(() => {
    onDelete(kind)
  }, [])

  return (
    <BaseSelectedChip
      filterName="Job Kind"
      filterValue={getHumanReadableCloudConnectorJobKind(kind)}
      onDelete={handleRemoveFilter}
    />
  )
}
