import { Box, styled } from '@mui/material'
import { PressAndHoldButton } from '@components/components-simple'

export const TabsBlockWrapper = styled(Box)({
  padding: '24px',
  background: 'var(--white)',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '6px',
  marginBottom: '22px',
})

export const StyledDemandButton = styled(PressAndHoldButton)({
  maxHeight: '40px',
  marginTop: '12px',
})
