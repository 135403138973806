import {
  StyledContainer,
  StyledThreatRow,
} from '@features/DashboardV4/threats-widgets/styles'
import { WidgetHeader } from '@features/DashboardV4/common'
import { Box, Skeleton } from '@mui/material'
import React from 'react'

const NUM_SKELETONS = 3

interface Props {
  title: string
  showDateLabel?: boolean
}

export function ThreatSkeletonLoader({ title, showDateLabel = false }: Props) {
  const skeletonData = Array.from(Array(NUM_SKELETONS))
  return (
    <StyledContainer>
      <WidgetHeader title={title} />
      <Box display="grid" height="100%" gap="8px" paddingX="16px">
        {showDateLabel && <Skeleton height={23} />}
        {skeletonData.map((_, index) => {
          return (
            <StyledThreatRow key={index}>
              <Skeleton height={54} width={32} />
              <Skeleton height={40} width={24} />
              <Skeleton height={32} width={'100%'} />
            </StyledThreatRow>
          )
        })}
      </Box>
    </StyledContainer>
  )
}
