import React, {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  memo,
  useCallback,
  useState,
} from 'react'
import { EngineCallback, FormRefType } from '@lib/engine-types'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { FormControl, IconButton, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

interface Props {
  field: FormFieldInterface
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
  // eslint-disable-next-line react/require-default-props
  className?: any
  // eslint-disable-next-line react/require-default-props
  onChange?: EngineCallback<ChangeEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  // eslint-disable-next-line react/require-default-props
  onKeyDown?: EngineCallback<React.KeyboardEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  onFocus?: EngineCallback<React.FocusEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  isVisible?: boolean
}

const PasswordField = forwardRef(
  (
    {
      field,
      disabled,
      className,
      onChange,
      onBlur,
      onKeyDown = () => {},
      onFocus = () => {},
      isVisible = true,
    }: Props,
    ref: FormRefType
  ) => {
    const [visible, setVisible] = useState(false)
    const togglePasswordVisibility = useCallback(
      () => setVisible(!visible),
      [visible]
    )

    const isPasswordVisible = !disabled && visible
    return (
      <FormControl className="passwordField jsPasswordField">
        <TextField
          className={className}
          error={field.hasError}
          label={field.label}
          variant="outlined"
          helperText={field.errorText}
          disabled={disabled}
          id={`id_${field.name}`}
          type={isPasswordVisible ? 'text' : 'password'}
          inputRef={ref}
          name={field.name}
          autoComplete="off"
          defaultValue={field.value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        {isVisible && (
          <IconButton
            disabled={disabled}
            aria-label="Toggle password visibility"
            onClick={togglePasswordVisibility}
            edge="end"
            tabIndex={-1}
          >
            {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        )}
      </FormControl>
    )
  }
)

export default memo(PasswordField)
