/* eslint-disable import/no-extraneous-dependencies */
import {
  CellWithDateTimeTooltip,
  createColumn,
  createDisplayColumn,
} from '@components/table'
import React from 'react'
import {
  CellActionsMenuItems,
  COLUMN_SIZES,
  COLUMN_HEADERS,
} from './columns/cells'
import {
  ReportGenerated,
  OnDemandOrigin,
  ReportGeneratedCompleted,
} from 'blues-corejs/dist/models/reports/report-generated'
import Typography from '@mui/material/Typography'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

const ON_DEMAND = 'On Demand'

export function getHistoryColumnDefinitions() {
  return [
    createDisplayColumn<ReportGenerated>({
      id: 'Name',
      header: COLUMN_HEADERS.SCHEDULE_NAME,
      enableSorting: false,
      cell: ({ row }) => {
        const { origin } = row.original

        if (origin instanceof OnDemandOrigin) {
          return ON_DEMAND
        }

        return origin.name
      },
      size: COLUMN_SIZES.SCHEDULE_NAME,
    }),
    createDisplayColumn<ReportGenerated>({
      id: 'description',
      header: COLUMN_HEADERS.DESCRIPTION,
      meta: {
        width: '20%',
      },
      enableSorting: false,
      cell: ({ row }) => {
        const { origin } = row.original

        return <LongTextTooltip text={origin.description} maxLength={50} />
      },
    }),
    createColumn<ReportGenerated>('reportKindWithParams', {
      header: COLUMN_HEADERS.REPORT_TYPE,
      enableSorting: false,
      size: COLUMN_SIZES.REPORT_TYPE,
      cell: ({ row }) => {
        const { original } = row

        const readableReport = original.getReadableReportType()

        return (
          <Typography variant="caption" fontSize="14px">
            {readableReport}
          </Typography>
        )
      },
    }),
    createDisplayColumn<ReportGenerated>({
      id: 'rangeOfData',
      header: COLUMN_HEADERS.RANGE_OF_DATA,
      size: COLUMN_SIZES.RANGE_OF_DATA,
      enableSorting: false,
      cell: ({ row }) => {
        const { reportKindWithParams } = row.original
        const startDate = reportKindWithParams?.startAt
        const endDate = reportKindWithParams?.endDate

        return (
          <>
            <CellWithDateTimeTooltip date={startDate} />
            {' - '}
            <CellWithDateTimeTooltip date={endDate} />
          </>
        )
      },
    }),
    createColumn<ReportGenerated>('createdAt', {
      header: COLUMN_HEADERS.GENERATED,
      size: COLUMN_SIZES.GENERATED,
      enableSorting: false,
      cell: ({ row }) => {
        const { status, createdAt } = row.original
        let date = createdAt
        if (status instanceof ReportGeneratedCompleted) {
          date = status.generatedAt
        }

        return <CellWithDateTimeTooltip date={date?.getTime()} />
      },
    }),

    createColumn<ReportGenerated>('status', {
      enableSorting: false,
      size: COLUMN_SIZES.HISTORY_ACTIONS,
      cell: ({ row }) => {
        return <CellActionsMenuItems row={row.original} />
      },
    }),
  ]
}
