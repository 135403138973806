import Box from '@mui/material/Box'
import React, { useEffect } from 'react'
import {
  useNotificationsActions,
  useNotificationsState,
} from './notifications-store'
import { useDebouncedCallback } from 'use-debounce'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { MultiSelectSearch } from '@components/shared'
import {
  Label,
  MaxWidthFormControl,
  Subtitle,
  ControlledCheckbox,
} from '@features/alerts/create-rule-drawer/shared'
import {
  useFormValidationActions,
  useRuleDrawerDataState,
} from '@features/alerts/create-rule-drawer'
import { FORM_FIELDS, defaultValues } from './consts'
import { RuleFormValues } from './types'
import { EmailUsers } from './email-selection'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserProfile } from 'blues-corejs/dist/models'
import { ChannelsSchema, ChannelsForm } from './channels-schema'
import { useEmailSelectionState } from '@features/alerts/create-rule-drawer/stepper/steps'
import { EmailUser } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/email-selection/email-store'

export const DEBOUNCE_DELAY_MS = 300

const useFormValidation = (
  methods: ReturnType<typeof useForm<ChannelsForm>>,
  emails: Array<EmailUser>
) => {
  const { setIsFormValid } = useFormValidationActions()
  const { control, getValues } = methods
  const [hasInteracted, setHasInteracted] = React.useState(false)

  const checkFieldsValidity = (values: ChannelsForm): boolean => {
    const hasSlack = Boolean(values[FORM_FIELDS.SLACK_INTEGRATION])
    const hasWebhooks = Boolean(values[FORM_FIELDS.WEBHOOKS]?.length)
    const hasConsoleUsers = Boolean(
      values[FORM_FIELDS.EXISTING_CONSOLE_USERS]?.length
    )
    const hasEmails = Boolean(emails.length)

    return hasSlack || hasWebhooks || hasConsoleUsers || hasEmails
  }

  useEffect(() => {
    const currentValues = getValues()
    const hasAnyValue = checkFieldsValidity(currentValues)

    if (hasAnyValue) {
      setHasInteracted(true)
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [getValues, setIsFormValid, emails])

  useEffect(() => {
    const subscription = methods.watch((formValues) => {
      if (!hasInteracted) {
        setHasInteracted(true)
      }

      const isValid = checkFieldsValidity(formValues as ChannelsForm)
      setIsFormValid(isValid)
    })

    return () => subscription.unsubscribe()
  }, [control, setIsFormValid, hasInteracted, emails])
}

const transformAndSortUsers = (
  users: Array<UserProfile>
): Array<{ value: string; label: string }> => {
  if (!Array.isArray(users)) {
    return []
  }
  return users
    .map((user) => ({
      value: user.id,
      label: user.email,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}
function Notifications() {
  const { setNotificationsData } = useNotificationsActions()
  const { webhooks, slackIntegration, existingConsoleUsers } =
    useNotificationsState()
  const { webhooksList, listUsers } = useRuleDrawerDataState()
  const emails = useEmailSelectionState()
  const methods = useForm<ChannelsForm>({
    defaultValues: {
      [FORM_FIELDS.EXISTING_CONSOLE_USERS]:
        existingConsoleUsers ||
        defaultValues[FORM_FIELDS.EXISTING_CONSOLE_USERS],
      [FORM_FIELDS.WEBHOOKS]: webhooks || defaultValues[FORM_FIELDS.WEBHOOKS],
      [FORM_FIELDS.SLACK_INTEGRATION]:
        slackIntegration || defaultValues.slackIntegration,
    },
    resolver: zodResolver(ChannelsSchema),
    mode: 'onChange',
    shouldUnregister: false,
  })

  const debouncedSave = useDebouncedCallback((values: RuleFormValues) => {
    setNotificationsData(values)
  }, DEBOUNCE_DELAY_MS)

  const webhooksOptions = webhooksList?.map((webhook) => ({
    label: webhook?.name,
    value: webhook?.id,
  }))
  useFormValidation(methods, emails)

  useEffect(() => {
    const subscription = methods.watch((values) => {
      debouncedSave(values as RuleFormValues)
    })
    return () => subscription.unsubscribe()
  }, [debouncedSave, methods])

  return (
    <Box>
      <Subtitle>
        Select delivery channels. This can be a combination of Email, Webhook
        and Slack.
      </Subtitle>
      <FormProvider {...methods}>
        <MaxWidthFormControl maxWidth="70%">
          <Label>Email Users</Label>
          <MultiSelectSearch
            name={FORM_FIELDS.EXISTING_CONSOLE_USERS}
            placeholder="Select Existing Console Users"
            options={transformAndSortUsers(listUsers)}
          />
        </MaxWidthFormControl>
        <EmailUsers />
        <Label>Webhooks</Label>
        <MaxWidthFormControl maxWidth="70%">
          <MultiSelectSearch
            name={FORM_FIELDS.WEBHOOKS}
            placeholder="Select"
            options={webhooksOptions}
          />
        </MaxWidthFormControl>
        <Label gutterBottom={false} sx={{ pt: '16px' }}>
          Slack Integration
        </Label>
        <ControlledCheckbox name={FORM_FIELDS.SLACK_INTEGRATION} />
      </FormProvider>
    </Box>
  )
}

export default Notifications
