import { Typography, TypographyOwnProps } from '@mui/material'
import { Link as LinkReactRouter, useLocation } from 'react-router-dom'

interface LinkProps extends TypographyOwnProps {
  to: string
  preservePath?: boolean
  preserveSearchParams?: boolean
  children: React.ReactNode
  variant?: TypographyOwnProps['variant']
  color?: TypographyOwnProps['color']
}
const Link: React.FC<LinkProps> = ({
  to,
  preservePath = false,
  preserveSearchParams = false,
  children,
  variant = 'body2',
  color = 'primary',
  ...props
}) => {
  const location = useLocation()

  const getTargetPath = (): string => {
    let path = to

    if (preservePath) {
      const currentPath = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname

      const targetPath = to.startsWith('/') ? to.slice(1) : to

      path = `${currentPath}/${targetPath}`
    }

    if (preserveSearchParams && location.search) {
      const currentSearchParams = new URLSearchParams(location.search)
      const targetSearchParams = new URLSearchParams(
        path.includes('?') ? path.split('?')[1] : ''
      )

      currentSearchParams.forEach((value, key) => {
        if (!targetSearchParams.has(key)) {
          targetSearchParams.append(key, value)
        }
      })

      const searchString = targetSearchParams.toString()
      const basePath = path.split('?')[0] ?? ''

      return searchString ? `${basePath}?${searchString}` : basePath
    }

    return path
  }

  return (
    <Typography
      component={LinkReactRouter}
      to={getTargetPath()}
      sx={{
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' },
        ...props.sx,
      }}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default Link
