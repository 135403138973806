import React from 'react'

function CheckboxPlusIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1.00012"
        width="17"
        height="17"
        rx="2"
        stroke="#32B6F3"
        strokeWidth="2"
      />
      <path
        d="M9.45825 5.91675V13.0001"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.91675 9.4585H13.0001"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckboxPlusIcon
