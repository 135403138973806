import React, { useCallback } from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import LangHelper from '@lib/helpers/lang.helper'
import RedStackModel from '@lib/models/red-stack.model'
import DocsLink from '@components-simple/docs-link/DocsLink'
import { Button } from '@mui/material'
import WarningIcon from '@inline-img/general/warning-icon'
import ModalCloseIcon from '@inline-img/general/modal-close-icon'

interface Props {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<Array<RedStackModel>>
  data: Nullable<Array<RedStackModel>>
  show: boolean
}

function DeleteSourceModal({ data, onSubmit, onCancel, show }: Props) {
  const onSubmitCallback = useCallback(() => {
    onSubmit(data ?? [])
  }, [data])
  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])
  return (
    <BaseModal onBgClick={onCancelCallback} show={show}>
      <div className="disableServiceContent">
        <div className="modalHeader dsModalHeader">
          <WarningIcon /> Please Note!{' '}
          <span className="dsModalClose" onClick={onCancelCallback}>
            <ModalCloseIcon />
          </span>
        </div>
        <div className="modalContent dsModalContent">
          <div className="dsExplanationText">
            Unlink will remove the following regions of this account from
            Elastio.
          </div>
          <div className="dsExplanationText">
            Unlink <strong>will NOT</strong> remove any Vaults or Cloud
            Connectors from your AWS account. To know more, see the&nbsp;
            <DocsLink href="https://docs.elastio.com/docs/uninstall/cleanup-script" />{' '}
          </div>
          <div className="mb20 dsModalContentGrey">
            {data && data.map((r: RedStackModel) => r.awsRegion).length > 1 ? (
              <span>The regions to be removed are:</span>
            ) : (
              <span>The region to be removed is:</span>
            )}
          </div>
          <table>
            <tbody>
              <tr className="llContent" data-testid="jsSourceModalItemsRow">
                {data &&
                  data
                    .map((r: RedStackModel) => r.awsRegion)
                    .map((regionName: string) => (
                      <td key={regionName}>
                        {LangHelper.getAwsRegionSingleTranslation(regionName)}
                      </td>
                    ))}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt20 modalControlBlock jsDeleteSourceControlBlock deleteSourceControlBlock">
          <Button
            className="blueColor lightGrayBgColor jsDeletedSourceModalNo"
            data-testid="jsDeletedSourceModalNo"
            variant="text"
            onClick={onCancelCallback}
            type="button"
          >
            No, cancel
          </Button>
          <Button
            className="redBGColor whiteColor jsDeletedSourceModalYes"
            data-testid="jsDeletedSourceModalYes"
            variant="contained"
            onClick={onSubmitCallback}
            type="button"
          >
            Yes, unlink
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default DeleteSourceModal
