import React, { useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  listEbsInventoryPossibleFilters,
  ListInventoryVolumes,
  Policy,
} from 'blues-corejs/dist'
import { useDispatch, useSelector } from 'react-redux'

import { useListEbs } from './use-list-ebs'

import ValueInterface from '@lib/interfaces/value.interface'
import FilterDashboardV2 from '@components-complex/filter-dashboard-v2/FilterDashboardV2'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import DataHelper from '@lib/helpers/data.helper'
import {
  getVolumesTableColumnDefinitions,
  RETENTION_COLUMN_NAME,
  RPO_COLUMN_NAME,
  RPO_TARGET_COLUMN_NAME,
} from './columns-definition'
import { getColumnsSorting } from '@components-complex/dashboard-pages-v3/inventory-tables-complex/shared/columns-sorting'
import { NO_BACKUPS_ON_SCHEDULE } from '@components-complex/dashboard-pages-v3/inventory-tables-complex/filter-configs'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  getAliasNamesForEBS,
  getDataForPossibleEbsInventoryFilters,
} from '@components-complex/dashboard-pages-v3/ebs-inventory-table-complex/utils'
import FilterFactory from '@lib/factories/filter.factory'
import { pechkinClient as listPlans } from '@lib/clients/pechkin'
import {
  getBackups,
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { Box, Typography } from '@mui/material'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'

import { VirtualTable } from '@components/table/table-infinite-scroll-memory'
import { useQueryParams } from './use-query-params'
import { useList } from './use-list'
import { updateDisabledStateForCoveredOptions } from '@components-complex/dashboard-pages-v3/shared'
import { initialPrioritySortForEBSTable } from './initial-priority-sort-for-ebs-table'
import { ExportAsCsv } from '@features/common'
import {
  getEbsCvsTableHeaders,
  prepareEbsDataForCsv,
} from './prepare-data-for-csv'
import { getDetectedThreats } from '@components-complex/dashboard-pages-v3/utils'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'
import { useOnDemandScan } from '@components-complex/dashboard-pages-v3/useOnDemandScan'
import { StyledDemandButton } from '@components-complex/dashboard-pages-v3/styles'
import {
  ON_DEMAND_BUTTON_GRADIENT_COLORS,
  ON_DEMAND_SCAN_BUTTON_TEXT,
} from '@components-complex/dashboard-pages-v3/const'
import { useLayoutContext } from '@features/contexts'

const filterBackups = 'backupsOnSchedule'
const HEADER_TITLE = 'EBS'

function EbsInventoryTable() {
  const dispatch = useDispatch()
  const { setHeaderTitle } = useLayoutContext()

  const {
    isRPOVisible,
    isRetentionVisible,
    filterParameter,
    rpoQueryParameter,
    retentionQueryValue,
    shouldHideBackupsOnSchedule,
  } = useQueryParams()

  const {
    ebsVolumes: volumesList,
    ec2Instances: instancesList,
    ebsSnapshots,
  } = useSelector(getLiveAssets)

  const [isListEbsActive, setIsListEbsActive] = useState(false)
  const [list, actions] = useList(volumesList)

  const listEbs = useListEbs()
  const handleToggleList = (value: boolean) => {
    setIsListEbsActive(value)
    if (value) {
      actions.clear()
      actions.set(listEbs.data)
    } else {
      actions.reset()
    }
  }

  const [isPoliciesLoading, setIsPoliciesLoading] = useState<boolean>(false)

  const [policiesList, setPoliciesList] = useState<Array<Policy>>([])

  const [searchEbs, setSearchEbs] = useState('')
  const [selectedFiltersEbs, setSelectedFiltersEbs] = useState<
    Array<ValueInterface>
  >([])

  const threats = useSelector(getThreats)
  const detectedThreat = getDetectedThreats(threats)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)
  const { firstBackups, lastBackups } = useSelector(getBackups)

  const { fsCheckScans, malwareScans, ransomwareScans } = useSelector(getScans)

  const { possibleEbsFilters } = useMemo(() => {
    if (!volumesList.length) {
      return {
        possibleEbsFilters: {},
      }
    }

    return new listEbsInventoryPossibleFilters({
      EbsList: volumesList,
    }).execute()
  }, [volumesList.length])

  useEffect(() => {
    if (filterParameter && shouldHideBackupsOnSchedule()) {
      setSelectedFiltersEbs(NO_BACKUPS_ON_SCHEDULE)
    }
  }, [volumesList.length, filterParameter])

  const { volumes, summarizedData } = useMemo(() => {
    if (!volumesList.length) {
      return {
        volumes: [],
        summarizedData: {
          inventorySize: 0,
          accountsCount: 0,
          regionsCount: 0,
          volumesCount: 0,
        },
      }
    }
    return new ListInventoryVolumes({
      instances: instancesList,
      volumes: list,
      threats: detectedThreat,
      policies: policiesList,
      firstBackups: firstBackups.backups, //
      lastBackups: lastBackups.backups,
      lastEbsSnapshots: ebsSnapshots,
      lastScans: [...fsCheckScans, ...malwareScans, ...ransomwareScans],
    }).execute(
      DataHelper.getDataForDashboardFilter(selectedFiltersEbs, searchEbs)
    )
  }, [
    isLiveAssetsLoading,
    JSON.stringify(selectedFiltersEbs),
    searchEbs,
    listEbs.data.length,
    list.length,
  ])

  const dataForPossibleEbsInventoryFilters =
    getDataForPossibleEbsInventoryFilters(possibleEbsFilters)

  const possibleFiltersViRow = updateDisabledStateForCoveredOptions(
    dataForPossibleEbsInventoryFilters.map(
      FilterFactory.buildVolumeInventoryFilter
    ),
    isListEbsActive
  )

  const possibleEbsInventoryFilters = !shouldHideBackupsOnSchedule()
    ? possibleFiltersViRow.filter((filter) => filter.name !== filterBackups)
    : possibleFiltersViRow

  const handleFiltersChange = (newFilters: Array<ValueInterface>) => {
    setSelectedFiltersEbs(newFilters)
  }

  const handleSearchChange = (value: string) => {
    setSearchEbs(value.trim())
  }

  const fetchPoliciesList = async () => {
    const policiesResponse = await listPlans.listPolicies()

    setPoliciesList(policiesResponse.plansList)
    setIsPoliciesLoading(false)
  }

  useEffect(() => {
    setHeaderTitle(HEADER_TITLE)
    dispatch(getAllRedStacks())
    setIsPoliciesLoading(true)
    fetchPoliciesList()
    const fetchInterval = setInterval(
      fetchPoliciesList,
      INTERVAL_REQUEST_TIMEOUT
    )

    return () => {
      setHeaderTitle(null)
      clearInterval(fetchInterval)
    }
  }, [])

  const { handleDemandButtonClick, isScheduleScanLoading, rowsSelection } =
    useOnDemandScan({
      assetsCoreModel: volumesList,
      assets: volumes,
    })

  const isDemandButtonDisabled =
    isScheduleScanLoading || !Object.keys(rowsSelection.selectedRows).length

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const aliasNamesWithId: AliasName = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !volumesList.length) {
      return {}
    }
    return getAliasNamesForEBS(volumesList, allActiveRedStacks)
  }, [volumesList.length, allActiveRedStacks.length])

  const sortedVolumes = initialPrioritySortForEBSTable(volumes)

  return (
    <Box className="wrap-1686745554069">
      <Box className="wrapEbsSnapshotTable" data-testid="ebs-table">
        {isLiveAssetsLoading ? (
          <PreloaderBlock show />
        ) : (
          <Box display="flex">
            <FilterDashboardV2
              searchPlaceholder="Search Instances, Volumes, Snapshots"
              possibleFilters={possibleEbsInventoryFilters}
              selectedFilters={selectedFiltersEbs}
              turnOnSearch
              onFiltersChange={handleFiltersChange}
              onSearchChange={handleSearchChange}
              disabled={isPoliciesLoading}
              searchVal={searchEbs}
              showFilterFirst
              tableName=""
              turnOffButton
              turnOnAddPolicyBtn
              className="fiveItemsWithLabel"
            />

            <Box
              display="flex"
              alignItems="center"
              height="62px"
              sx={{
                color: 'var(--grey800)',
                '.MuiCheckbox-root svg': {
                  width: '18px',
                  height: '18px',
                },
                '.MuiFormControlLabel-label': {
                  fontSize: '14px',
                  fontWeight: '400',
                },
              }}
            >
              <Typography fontWeight="500" fontSize="14px" marginRight="12px">
                Status:
              </Typography>
              <V2Checkbox
                onChange={handleToggleList}
                label="Existing"
                checked
                disabled
              />
              <V2Checkbox
                onChange={handleToggleList}
                label="Deleted"
                checked={isListEbsActive}
                disabled={listEbs.isLoadingData()}
              />
            </Box>
            <StyledDemandButton
              variant="contained"
              disabled={isDemandButtonDisabled}
              onHoldComplete={handleDemandButtonClick}
              gradientColors={ON_DEMAND_BUTTON_GRADIENT_COLORS}
            >
              {ON_DEMAND_SCAN_BUTTON_TEXT}
            </StyledDemandButton>
          </Box>
        )}
        <ExportAsCsv
          data={prepareEbsDataForCsv(sortedVolumes)}
          headers={getEbsCvsTableHeaders(isRPOVisible, isRetentionVisible)}
          filename="ebs-inventory.csv"
          shouldShow={!isListEbsActive && sortedVolumes.length > 0}
        />
        <VirtualTable
          config={{
            data: sortedVolumes,
            columns: getVolumesTableColumnDefinitions({
              summarizedData,
              aliasNamesWithId,
            }),
            state: {
              rowSelection: rowsSelection.selectedRows,
              isLoading: isPoliciesLoading,
            },
            onRowSelectionChange: rowsSelection.setSelectedRows,
            initialState: {
              columnVisibility: {
                [RPO_COLUMN_NAME]: isRPOVisible,
                [RPO_TARGET_COLUMN_NAME]: isRPOVisible,
                [RETENTION_COLUMN_NAME]: isRetentionVisible,
              },
              sorting: getColumnsSorting({
                queryRpo: rpoQueryParameter as string,
                retentionValue: retentionQueryValue as string,
              }),
            },
          }}
          emptyPlaceholderText="No EBS Volumes found"
        />
      </Box>
    </Box>
  )
}

export default EbsInventoryTable
