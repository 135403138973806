/* eslint-disable import/no-extraneous-dependencies */
import { RuleStatus } from 'blues-corejs/dist/models/notifications/rule/status'

export const ENABLED = RuleStatus.enabled
export const DISABLED = RuleStatus.disabled

export const ENABLED_LABEL = 'Enable Rule'
export const DISABLED_LABEL = 'Disabled Rule'

export const statusOptionsForRules = [
  {
    value: ENABLED,
    label: ENABLED_LABEL,
  },
  {
    value: DISABLED,
    label: DISABLED_LABEL,
  },
]
