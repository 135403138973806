/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { EBS, EC2, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist/models'
import { computeAccountName } from './compute-account-name'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { CopyToClipboardIcon } from '@features/common'
import { RowTitle } from '../../row-title'
import { useGetCloudConnectorByAsset } from '@lib/hooks/api-hooks'

const CLIPBOARD_ICON_SIZE = 16

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function AccountId({ asset }: { asset: Asset }) {
  const { cloudConnector, isCloudConnectorLoading } =
    useGetCloudConnectorByAsset(asset)

  if (isCloudConnectorLoading) {
    return <Skeleton />
  }

  if (!cloudConnector) {
    return null
  }

  const accountName = computeAccountName(cloudConnector)

  return (
    <Grid container item xs={4}>
      <Grid item>
        <RowTitle title="Account ID:" />
      </Grid>
      <Grid item>
        <Typography fontSize="14px" fontWeight="400" variant="caption">
          {accountName}
        </Typography>
        <CopyToClipboardIcon
          text={cloudConnector.awsAccountId}
          fontSize={CLIPBOARD_ICON_SIZE}
        />
      </Grid>
    </Grid>
  )
}
