import { useParams, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AssetsEc2 from './ec2'
import AssetsEbs from './ebs'
import AssetsS3 from './s3'
import AssetsEfs from './efs'
import AssetsOvaServer from './ova-server'
import AssetsGenericHost from './generic-host'
import AssetsDrawer from '../../components/drawers/asset-details'
import AssetRestoreCleanBackup from '../../components/drawers/restore-clean-backup'
import { getDefaultQueryParams } from './filters/config'
import { SyntheticEvent, useEffect } from 'react'
import { ASSETS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const TAB_MAP = {
  ec2: {
    label: 'EC2',
    component: <AssetsEc2 />,
    slug: ASSETS_ROUTE_SEGMENTS.EC2,
  },
  ebs: {
    label: 'EBS',
    component: <AssetsEbs />,
    slug: ASSETS_ROUTE_SEGMENTS.EBS,
  },
  s3: {
    label: 'S3 Bucket',
    component: <AssetsS3 />,
    slug: ASSETS_ROUTE_SEGMENTS.S3,
  },
  efs: {
    label: 'EFS',
    component: <AssetsEfs />,
    slug: ASSETS_ROUTE_SEGMENTS.EFS,
  },
  servers: {
    label: 'Servers',
    component: <AssetsOvaServer />,
    slug: ASSETS_ROUTE_SEGMENTS.SERVER,
  },
  'generic-host': {
    label: 'Generic Host',
    component: <AssetsGenericHost />,
    slug: ASSETS_ROUTE_SEGMENTS.GENERIC_HOST,
  },
}

export default function Assets() {
  const navigate = useNavigate()
  const { tab } = useParams<{
    tab: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  }>()
  const defaultPath = `/assets/${ASSETS_ROUTE_SEGMENTS.EC2}${getDefaultQueryParams(ASSETS_ROUTE_SEGMENTS.EC2)}`

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(TAB_MAP).some((t) => t.slug === tab)) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!location.search) {
      const defaultQueryParams = getDefaultQueryParams(tab)
      if (defaultQueryParams) {
        navigate(`/assets/${tab}${defaultQueryParams}`, { replace: true })
      }
    }
  }, [tab])

  if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
  ) => {
    const defaultQueryParams = getDefaultQueryParams(newSlug)
    navigate(`/assets/${newSlug}${defaultQueryParams}`)
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="asset tabs"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              paddingLeft: 3,
              paddingRight: 3,

              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },
            })}
          >
            {Object.values(TAB_MAP).map((tabItem) => (
              <Tab
                key={tabItem.slug}
                value={tabItem.slug}
                label={tabItem.label}
                sx={{ py: 0 }}
                {...a11yProps(tabItem.slug)}
              />
            ))}
          </Tabs>
        </Box>
        {Object.entries(TAB_MAP).map(([key, value]) => (
          <CustomTabPanel key={key} value={tab} slug={value.slug}>
            {value.component}
          </CustomTabPanel>
        ))}
      </Box>

      <AssetsDrawer />
      <AssetRestoreCleanBackup />
    </>
  )
}
