import { create } from 'zustand'

interface FormValidationState {
  isFormValid: boolean
}
interface FormValidationActions {
  setIsFormValid: (isValid: boolean) => void
}

export type FormValidationStore = FormValidationActions & FormValidationState

const INITIAL_STATE: FormValidationState = {
  isFormValid: false,
}

const useFormValidationStore = create<FormValidationStore>((set) => ({
  ...INITIAL_STATE,
  setIsFormValid: (isValid) => set({ isFormValid: isValid }),
}))

export const useFormValidationState = () =>
  useFormValidationStore((state) => state.isFormValid)

export const useFormValidationActions = () =>
  useFormValidationStore((state) => ({
    setIsFormValid: state.setIsFormValid,
  }))
