/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, GenericHost, OvaServer } from 'blues-corejs/dist'
import { CellWithLink } from '@components/table'
import { AssetRoutes } from '@lib/router'

function extractAssetNameData(asset: Asset) {
  if (asset instanceof OvaServer || asset instanceof GenericHost) {
    return
  }

  const displayedValue = asset.name || asset.awsId

  const displayedLabel = asset.name ? asset.awsId : asset.name

  return {
    displayedValue,
    displayedLabel,
  }
}

export function AssetNameCell({ asset }: { asset: Asset }) {
  const assetNameData = extractAssetNameData(asset)

  if (!assetNameData) {
    return null
  }

  const assetPageLink = AssetRoutes.assetId(asset.id)

  return (
    <CellWithLink
      href={assetPageLink}
      value={assetNameData.displayedValue}
      label={assetNameData.displayedLabel}
      textToCopy={assetNameData.displayedLabel}
    />
  )
}
