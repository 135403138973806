import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { TenantSettingsClient } from 'ui-v2/src/lib/clients/settings/tenant'
import { UpdateTenantSettingsRequestAttrs } from 'ui-v2/src/lib/models/settings/tenant'
import { useMutation } from '@tanstack/react-query'

export function useUpdateTenantMutation() {
  const tenantClient = useGrpcClient(TenantSettingsClient)

  return useMutation({
    mutationFn: async ({
      entropyDetectionStatus,
    }: UpdateTenantSettingsRequestAttrs) => {
      if (!tenantClient) {
        throw new Error('Tenant client not initialized')
      }
      return tenantClient.updateTenantSettings({ entropyDetectionStatus })
    },
  })
}
