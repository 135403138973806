/* eslint-disable import/no-extraneous-dependencies */
import { EbsProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { EBS } from 'blues-corejs/dist'
import {
  EBSState,
  EBSType,
  EncryptionDetails,
  PerformanceCharacteristics,
} from 'blues-corejs/dist/models/assets/aws/ebs/types'

type EbsPt = EbsProto.AsObject

export class EBSTransformer {
  #volume: EbsPt

  constructor(volume: EbsPt) {
    this.#volume = volume
  }

  transform(): EBS {
    return this.#transformVolume(this.#volume)
  }

  #transformVolume(volume: EbsPt): EBS {
    return new EBS({
      id: volume.id,
      awsId: volume.awsId,
      arn: volume.arn,
      awsAccountId: volume.awsAccountId,
      awsRegion: volume.awsRegion,
      backupPolicies: volume.backupPoliciesList,
      tags: volume.tagsList,
      size: volume.size,
      awsCreatedAt: secondsToDate(volume.createdByAwsAt?.seconds),
      attachedInstanceIds: volume.attachedInstanceIdsList,
      ccAssetId: volume.ccAssetId,
      nonRecognizedVolumeType: volume.nonRecognizedVolumeType,
      awsAz: volume.awsAz,
      type: this.#convertEbsType(volume.knownType),
      state: this.#convertEbsState(volume.state),
      encryptionDetails: this.#convertEncryptionDetails(
        volume.encryptionDetails
      ),
      performanceCharacteristics: this.#convertPerformanceCharacteristics(
        volume.performanceCharacteristics
      ),
      retentionPolicy: volume.retentionPoliciesList,
      createdAt: secondsToDate(volume.createdAt?.seconds),
      updatedAt: secondsToDate(volume.updatedAt?.seconds),
      hasReplicatedBackups: volume.hasReplicatedBackups,
    })
  }

  #convertPerformanceCharacteristics(
    characteristics?: EbsProto.PerformanceCharacteristics.AsObject
  ): PerformanceCharacteristics | undefined {
    if (!characteristics) {
      return
    }

    return {
      iops: characteristics.iops,
      throughput: characteristics.throughput,
    }
  }

  #convertEncryptionDetails(
    details?: EbsProto.EncryptionDetails.AsObject
  ): EncryptionDetails | undefined {
    if (!details) {
      return
    }

    return {
      encrypted: details.encrypted,
      encryptedWithCmk: details.encryptedWithCmk?.value,
    }
  }

  #convertEbsState(state: EbsProto.EbsState): EBSState {
    const mapping = {
      [EbsProto.EbsState.STATE_CREATING]: EBSState.CREATING,
      [EbsProto.EbsState.STATE_AVAILABLE]: EBSState.AVAILABLE,
      [EbsProto.EbsState.STATE_INUSE]: EBSState.INUSE,
      [EbsProto.EbsState.STATE_DELETING]: EBSState.DELETING,
      [EbsProto.EbsState.STATE_DELETED]: EBSState.DELETED,
      [EbsProto.EbsState.STATE_ERROR]: EBSState.ERROR,
      [EbsProto.EbsState.STATE_UNKNOWN]: EBSState.UNKNOWN,
    }

    return mapping[state]
  }

  #convertEbsType(type: EbsProto.EbsType): EBSType {
    const mapping = {
      [EbsProto.EbsType.TYPE_STANDARD]: EBSType.STANDARD,
      [EbsProto.EbsType.TYPE_GP2]: EBSType.GP2,
      [EbsProto.EbsType.TYPE_GP3]: EBSType.GP3,
      [EbsProto.EbsType.TYPE_IO1]: EBSType.IO1,
      [EbsProto.EbsType.TYPE_IO2]: EBSType.IO2,
      [EbsProto.EbsType.TYPE_SC1]: EBSType.SC1,
      [EbsProto.EbsType.TYPE_ST1]: EBSType.ST1,
    }

    return mapping[type]
  }
}
