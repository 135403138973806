import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'
/* eslint-disable import/no-extraneous-dependencies */
import RedStackModel from '@lib/models/red-stack.model'
import { AliasNamesForS3Bucket } from '@lib/interfaces/inventory/s3-bucket.interface'
import { EFS } from 'blues-corejs/dist/models/assets/aws/efs'
import { EfsInventoryFilters } from 'blues-corejs/dist/use_cases/list_efs_inventory/types'

export function getDataForPossibleEfsInventoryFilters(
  filterEfsInventory: EfsInventoryFilters
) {
  const filters = [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: filterEfsInventory?.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: filterEfsInventory?.regions,
    },
    {
      id: FilterIdDashboard.COVERED,
      covered: filterEfsInventory?.covered,
    },
  ]

  return filters
}

export function getAliasNamesForEfs(
  efsList: Array<EFS>,
  allActiveRedStacks: Array<RedStackModel>
) {
  if (allActiveRedStacks.length === 0 || !efsList) {
    return {}
  }
  const accountIds: Set<string> = new Set()

  efsList.forEach((efs) => {
    accountIds.add(efs.awsAccountId)
  })

  return allActiveRedStacks.reduce((accountsAliases, redStack) => {
    if (accountIds.has(redStack.awsAccount)) {
      return {
        ...accountsAliases,
        [redStack.awsAccount]: redStack.accountAlias,
      }
    }
    return accountsAliases
  }, {} as AliasNamesForS3Bucket)
}
