/* eslint-disable import/no-extraneous-dependencies */
import { Asset } from 'blues-corejs/dist'

export interface AssetState {
  assetMap: Map<string, Asset>
}

interface AssetStoreActions {
  setAssetMap: (assetMap: Map<string, Asset>) => void
  resetAssetStore: () => void
  fetchAssetsByIds: (assetIds: Array<string>) => void
}

export type AssetStore = AssetState & {
  actions: AssetStoreActions
}

export { fetchAssetsByIds, fetchAssetByIdsAction } from './actions'
export { useAssetMap, useAssetStoreActions } from './selectors'
