import { useQuery } from '@tanstack/react-query'
import { ReportSchedulesClient } from 'ui-v2/src/lib/clients/reports/report-schedules-client'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useReportScheduleListQuery() {
  const reportScheduleClient = useGrpcClient(ReportSchedulesClient)

  return useQuery({
    queryKey: ['reports', 'reportScheduleList'],
    queryFn: () => {
      if (!reportScheduleClient) {
        throw new Error('Report schedule not initialized')
      }
      return reportScheduleClient.listReportSchedules()
    },
    enabled: !!reportScheduleClient,
    refetchOnMount: true,
  })
}
