import React from 'react'
import ThreatRow from './threat-row'
import { WidgetHeader } from '@features/DashboardV4/common'
import { StyledContainer } from '@features/DashboardV4/threats-widgets/styles'
import { Box } from '@mui/material'
import { defineThreatRows } from './define-threat-rows'
import {
  ThreatSkeletonLoader,
  useFetchAwsThreatExposure,
} from '@features/DashboardV4/threats-widgets'
import { DashboardRoutes, DashboardTab, useNavigation } from '@lib/router'

function ThreatExposureWidget() {
  const router = useNavigation()
  const {
    isLoading,
    regionsWithThreatsCount,
    accountsWithThreatsCount,
    assetsWithThreatsCount,
    totalAssetsCount,
  } = useFetchAwsThreatExposure()

  if (isLoading) {
    return <ThreatSkeletonLoader title="Threats Exposure" />
  }

  const threatsDrillDownLink = DashboardRoutes.assetsThreatsTableRoute(
    DashboardTab.EBS
  )

  const handleWidgetLink = () => {
    router.push(threatsDrillDownLink)
  }

  const threatsRows = defineThreatRows({
    totalAssets: totalAssetsCount,
    assetsWithThreats: assetsWithThreatsCount,
    accountsWithThreats: accountsWithThreatsCount,
    regionsWithThreats: regionsWithThreatsCount,
  })

  return (
    <StyledContainer data-testid="threat-exposure-widget">
      <WidgetHeader title="Threat Exposure" onClick={handleWidgetLink} />
      <Box display="grid" height="100%" gap="8px" paddingX="16px">
        {threatsRows.map((row) => (
          <ThreatRow
            key={row.description}
            icon={row.icon}
            amount={row.amount}
            totalAmount={row.totalAmount}
            description={row.description}
          />
        ))}
      </Box>
    </StyledContainer>
  )
}

export default ThreatExposureWidget
