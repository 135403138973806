import { useMemo } from 'react'
import { splitBeforeFirstEqual } from '../utils'
import type { Option } from '@components/select'

export function useTags(tags: Array<string>): Array<Option> {
  return useMemo(
    () =>
      tags.map((tag) => {
        const { value, key } = splitBeforeFirstEqual(tag)
        return {
          value: `${key}=${value === 'null' ? '*' : value}`,
          label: `${key}=${value === 'null' ? '*' : value}`,
        }
      }),
    [tags.length]
  )
}
