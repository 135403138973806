/* eslint-disable import/no-extraneous-dependencies */
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Asset, Threat } from 'blues-corejs/dist'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { getAssetName, getAssetType } from './util'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'

export const getActiveAssetThreatsColumns = (
  assetByThreat: Map<Threat, Asset>
): Array<Column<Threat>> => [
  {
    key: 'assetId',
    header: 'Asset Name',
    render: (row) => (
      <Link to={`/${row.assetId}`} preservePath preserveSearchParams>
        {getAssetName(assetByThreat.get(row))}
      </Link>
    ),
  },
  {
    key: 'source',
    header: 'Asset Type',
    render: (row) => getAssetType(assetByThreat.get(row)),
  },
  {
    key: 'firstDetectedAt',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.firstDetectedAt),
  },
  {
    key: 'location',
    header: 'Path',
    wrap: true,
  },
  {
    key: 'name',
    header: 'Threat Name',
  },
]
