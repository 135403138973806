import React, { ReactNode, useCallback } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import {
  normalizeDataForScale,
  GradientBox,
} from '@features/DashboardV4/widgets-with-scale/shared'
import { ScaleElement } from './element'
import { useScale } from './use-scale'
import styles from './scale.module.css'
import { AWSStatsQuantile } from '@lib/clients/aws-statistics/types'

export type QuantileUI = {
  top: number
} & Pick<AWSStatsQuantile, 'percentile'>

function computedClsActiveQuantile(label: number, percentage: number) {
  return clsx({
    [styles.underline as string]: label == percentage,
  })
}

const Container = ({ children }: { children?: ReactNode }) => {
  return (
    <Box className={styles.root}>
      <Box className={styles.scale}>
        <GradientBox />
      </Box>
      {children}
    </Box>
  )
}

interface Props {
  quantiles: Array<AWSStatsQuantile>
  isLoading: boolean
}

function Scale({ quantiles, isLoading }: Props) {
  const initDivisionPosition = quantiles[quantiles.length - 1]?.percentile || 0

  const { currentQuantile, setCurrentQuantile, offsets, setOffsets } =
    useScale(initDivisionPosition)

  const handleQuantileOffsetTopClick = useCallback(
    ({ top, percentile }: Partial<QuantileUI>) =>
      () => {
        setCurrentQuantile({
          top,
          percentile,
        })
      },
    []
  )

  const handleElementMount = useCallback((index: number, offsetTop: number) => {
    setOffsets((prevOffsets) => {
      const newOffsets = [...prevOffsets]
      newOffsets[index] = { top: offsetTop }
      return newOffsets
    })
  }, [])

  if (isLoading) {
    return <Container />
  }

  return (
    <Container>
      <Box className={styles.values}>
        {normalizeDataForScale(quantiles)
          .reverse()
          .map((quantile, idx) => {
            return (
              <Box
                key={`${idx}-${quantile.value}`}
                onClick={handleQuantileOffsetTopClick({
                  top: offsets[idx]?.top,
                  percentile: quantile.label,
                })}
                className={computedClsActiveQuantile(
                  quantile.label,
                  currentQuantile.percentile || 0
                )}
              >
                <ScaleElement
                  index={idx}
                  content={quantile?.value as string}
                  onMount={handleElementMount}
                />
              </Box>
            )
          })}
      </Box>
      <Box
        style={{ top: `${currentQuantile.top}px` }}
        className={styles.wrapDivision}
      >
        <Box className={styles.divisionLabel}>
          <Box>{currentQuantile.percentile}%</Box>
          <Box>Assets</Box>
        </Box>
        <Box className={styles.divider}></Box>
      </Box>
    </Container>
  )
}

export default Scale
