import {
  RemoveThreatSuppressionCriteria,
  ThreatSuppression,
  ThreatSuppressionCriteria,
} from '@lib/clients/threats/threat-suppression'
import { useState } from 'react'
import { SystemHelper } from '@lib/helpers'
import { EngineCallback } from '@lib/engine-types'
import ToastHelper from '@lib/helpers/toast.helper'

const threatSuppression = new ThreatSuppression()

interface Props {
  callbackAfterSuppression?: EngineCallback<void>
}

export const useThreatSuppression = ({ callbackAfterSuppression }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleThreatSuppression = async ({
    threatId,
    until,
    reason,
  }: ThreatSuppressionCriteria) => {
    setIsLoading(true)
    try {
      await threatSuppression.threatSuppression({
        threatId,
        until,
        reason,
      })
      ToastHelper.success('Threat Suppression successful')
      if (callbackAfterSuppression) {
        callbackAfterSuppression()
      }
    } catch (e) {
      SystemHelper.sendSentryIfProd(
        'threatSuppression.suppressThreat: failed to threat suppression'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleRemoveThreatSuppression = async ({
    threatId,
  }: RemoveThreatSuppressionCriteria) => {
    setIsLoading(true)
    try {
      await threatSuppression.removeThreatSuppression({
        threatId,
      })
    } catch (e) {
      SystemHelper.sendSentryIfProd(
        'threatSuppression.removeThreatSuppression: failed to remove threat suppression'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    handleThreatSuppression,
    handleRemoveThreatSuppression,
  }
}
