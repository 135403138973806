/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { useEffect } from 'react'
import { subscribeWithSelector } from 'zustand/middleware'
import { ObjHelper } from '@lib/helpers'
import { useOvaServersWithThreatsStoreActions } from '../ova-servers-with-threats-store'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'
import { OvaAccount, OvaBackupProvider } from 'blues-corejs/dist'

interface ToolbarFiltersStoreState {
  ovaAccountsList: Array<OvaAccount>
  backupProvidersList: Array<OvaBackupProvider>
  malwareThreatNames: Set<string>
  ransomwareThreatNames: Set<string>
  textSearch: string
}

const INITIAL_STATE: ToolbarFiltersStoreState = {
  ovaAccountsList: [],
  backupProvidersList: [],
  malwareThreatNames: new Set(),
  ransomwareThreatNames: new Set(),
  textSearch: '',
}

interface ToolbarFiltersStoreActions {
  setTextSearch: (textSearch: string) => void
  setAccountsList: (accountsIdsList: Array<OvaAccount>) => void
  setBackupProvidersList: (
    backupProvidersList: Array<OvaBackupProvider>
  ) => void
  setToolbarFiltersState: (state: Partial<ToolbarFiltersStoreState>) => void
  setMalwareThreatNames: (threatsNames: Array<string>) => void
  setRansomwareThreatNames: (threatsNames: Array<string>) => void
  resetToolbarFilters: () => void
}

function getPossibleOvaProviders(ovaAccountsList: Array<OvaAccount>) {
  const backupProvidersMap = new Set<OvaBackupProvider>()
  ovaAccountsList.forEach((ovaAccount) => {
    ovaAccount.backupProvidersList.forEach((backupProvider) => {
      if (!backupProvidersMap.has(backupProvider)) {
        backupProvidersMap.add(backupProvider)
      }
    })
  })

  return Array.from(backupProvidersMap)
}

const useToolbarFiltersStore = create<
  ToolbarFiltersStoreState & { actions: ToolbarFiltersStoreActions }
>()(
  subscribeWithSelector((set, get) => ({
    ...INITIAL_STATE,
    actions: {
      setToolbarFiltersState: (state: Partial<ToolbarFiltersStoreState>) =>
        set(state),

      setTextSearch: (textSearch: string) => set({ textSearch }),
      setAccountsList: (ovaAccountsList: Array<OvaAccount>) => {
        const { backupProvidersList } = get()

        if (ovaAccountsList.length) {
          const possibleOvaProviders = getPossibleOvaProviders(ovaAccountsList)

          set({
            backupProvidersList: backupProvidersList.filter((provider) =>
              possibleOvaProviders.includes(provider)
            ),
          })
        }

        set({
          ovaAccountsList,
        })
      },
      setBackupProvidersList: (backupProvidersList: Array<OvaBackupProvider>) =>
        set({ backupProvidersList }),
      setMalwareThreatNames: (threatsNames: Array<string>) =>
        set({ malwareThreatNames: new Set(threatsNames) }),
      setRansomwareThreatNames: (threatsNames: Array<string>) =>
        set({ ransomwareThreatNames: new Set(threatsNames) }),
      resetToolbarFilters: () => set({ ...INITIAL_STATE }),
    },
  }))
)

export const useToolbarFiltersActions = () =>
  useToolbarFiltersStore(useShallow((state) => state.actions))

export const useToolbarFiltersState = () =>
  useToolbarFiltersStore(
    useShallow((state) => ({
      textSearch: state.textSearch,
      backupProvidersList: state.backupProvidersList,
      ovaAccountsList: state.ovaAccountsList,
      malwareThreatNames: state.malwareThreatNames,
      ransomwareThreatNames: state.ransomwareThreatNames,
    }))
  )

export const useToolbarFiltersSubscription = () => {
  const { fetchInitial } = useOvaServersWithThreatsStoreActions()

  useEffect(() => {
    const unsubscribeFn = useToolbarFiltersStore.subscribe(
      (state) => ({
        textSearch: state.textSearch,
        accountIdsList: state.ovaAccountsList.map((account) => account.id),
        backupProvidersList: state.backupProvidersList,
        malwareThreatNames: state.malwareThreatNames,
        ransomwareThreatNames: state.ransomwareThreatNames,
      }),
      ({
        textSearch,
        accountIdsList,
        backupProvidersList,
        malwareThreatNames,
        ransomwareThreatNames,
      }) => {
        fetchInitial({
          textSearch,
          statusesList: [OvaServerStatus.INFECTED],
          accountIdsList: accountIdsList,
          malwareThreatNames: malwareThreatNames,
          ransomwareThreatNames: ransomwareThreatNames,
          backupProvidersList,
        })
      },
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])
}
