interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const DeleteIcon = ({ size = 20, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.3 17.5C2.8 17.5 2.375 17.325 2.025 16.975C1.675 16.625 1.5 16.2 1.5 15.7V3H0.5V1.5H5V0.625H11V1.5H15.5V3H14.5V15.7C14.5 16.2 14.325 16.625 13.975 16.975C13.625 17.325 13.2 17.5 12.7 17.5H3.3ZM13 3H3V15.7C3 15.7833 3.02933 15.8543 3.088 15.913C3.146 15.971 3.21667 16 3.3 16H12.7C12.7667 16 12.8333 15.9667 12.9 15.9C12.9667 15.8333 13 15.7667 13 15.7V3ZM5.4 14H6.9V5H5.4V14ZM9.1 14H10.6V5H9.1V14ZM3 3V16V15.7V3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DeleteIcon
