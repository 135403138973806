import { useEffect, useState } from 'react'

const SPA_KEYS_API_ROUTE = '/api/auth0-spa/fetch-spa-env-keys'

interface Auth0SpaKeys {
  clientId: string
  domain: string
  cookieDomain: string
}

const INITIAL_SPA_AUTH_KEYS: Auth0SpaKeys = {
  clientId: '',
  domain: '',
  cookieDomain: '',
}

/**
 * Fetches the Auth0 SPA env keys from the Next.JS server.
 * Because of server-side rendering, we can not get .env variables on the client side directly, because they are undefined.
 */
export function useFetchAuth0SpaKeys() {
  const [spaAuthKeys, setSpaAuthKeys] = useState<Auth0SpaKeys>(
    INITIAL_SPA_AUTH_KEYS
  )

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchEnvs() {
      const apiKey = await fetch(SPA_KEYS_API_ROUTE)
      const response = await apiKey.json()

      setSpaAuthKeys(response)

      setIsLoading(false)
    }

    fetchEnvs()
  }, [])

  return {
    spaAuthKeys,
    isLoading,
  }
}
