/* eslint-disable import/no-extraneous-dependencies */
import {
  PolicyS3BucketTableDataAttrs,
  PolicyS3BucketTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

export class PolicyS3BucketTableData {
  readonly #s3Buckets: AssetsForPolicyCoverage

  constructor(parameters: PolicyS3BucketTableDataAttrs) {
    this.#s3Buckets = parameters.s3Buckets
  }

  getS3BucketAssetsList = (): Array<PolicyS3BucketTableRow> => {
    return this.#s3Buckets.map((row) => {
      const displayedValue = row.asset.name || row.asset.awsId

      const backupPolicies = row.asset.backupPolicies
      return new PolicyS3BucketTableRow({
        id: row.asset.id,
        s3Bucket: displayedValue,
        account: `${row.asset.awsAccountId} (${row.asset.awsRegion ? row.asset.awsRegion : ''})`,
        policy:
          backupPolicies.length === 0
            ? 'Not Assigned'
            : backupPolicies.join(', '),
      })
    })
  }
}
