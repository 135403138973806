"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagsOperator = exports.ExactEvents = exports.AllKinds = exports.RuleOvaBackupProvider = void 0;
var RuleOvaBackupProvider;
(function (RuleOvaBackupProvider) {
    RuleOvaBackupProvider[RuleOvaBackupProvider["OVA_BACKUP_PROVIDER_VEEAM"] = 0] = "OVA_BACKUP_PROVIDER_VEEAM";
    RuleOvaBackupProvider[RuleOvaBackupProvider["OVA_BACKUP_PROVIDER_COMMVAULT"] = 1] = "OVA_BACKUP_PROVIDER_COMMVAULT";
    RuleOvaBackupProvider[RuleOvaBackupProvider["OVA_BACKUP_PROVIDER_RUBRIK"] = 2] = "OVA_BACKUP_PROVIDER_RUBRIK";
    RuleOvaBackupProvider[RuleOvaBackupProvider["OVA_BACKUP_PROVIDER_COHESITY"] = 3] = "OVA_BACKUP_PROVIDER_COHESITY";
    RuleOvaBackupProvider[RuleOvaBackupProvider["OVA_BACKUP_PROVIDER_VERITAS_NET_BACKUP"] = 4] = "OVA_BACKUP_PROVIDER_VERITAS_NET_BACKUP";
    RuleOvaBackupProvider[RuleOvaBackupProvider["OVA_BACKUP_PROVIDER_AWS_BACKUP_VMWARE"] = 5] = "OVA_BACKUP_PROVIDER_AWS_BACKUP_VMWARE";
})(RuleOvaBackupProvider || (exports.RuleOvaBackupProvider = RuleOvaBackupProvider = {}));
class AllKinds {
}
exports.AllKinds = AllKinds;
class ExactEvents {
    kinds;
    constructor(kinds) {
        this.kinds = kinds;
    }
}
exports.ExactEvents = ExactEvents;
var TagsOperator;
(function (TagsOperator) {
    TagsOperator[TagsOperator["AND"] = 0] = "AND";
    TagsOperator[TagsOperator["OR"] = 1] = "OR";
})(TagsOperator || (exports.TagsOperator = TagsOperator = {}));
