import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { EntropyDetectionClient } from 'ui-v2/src/lib/clients'
import { QUERY_REFETCH_INTERVAL } from 'ui-v2/src/lib/constants/query.constant'
import { GetFiltersForListAwsAssetSummariesFilter } from 'ui-v2/src/lib/models/anomalies/anomaly'

export function useGetFiltersForListAwsAssetSummariesQuery(
  filters: GetFiltersForListAwsAssetSummariesFilter
) {
  const entropyDetectionClient = useGrpcClient(EntropyDetectionClient)

  return useQuery({
    queryKey: [
      'entropy-detection',
      'fileters-for-aws-asset-summaries',
      filters,
    ],
    queryFn: async () => {
      if (!entropyDetectionClient) {
        throw new Error('Entropy detection client not initialized')
      }
      return entropyDetectionClient.getFiltersForListAwsAssetSummaries(filters)
    },
    enabled: !!entropyDetectionClient,
    refetchInterval: QUERY_REFETCH_INTERVAL,
  })
}
