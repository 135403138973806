import React, { useEffect } from 'react'
import TokenForm from '@forms/token/token.form'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEditToken,
  getUpdatedSuccess,
} from '@store/selectors/token.selector'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import {
  requestToken,
  setEditToken,
  setUpdatedSuccess,
  submitEditToken,
} from '@store/actions/token.action'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import StrHelper from '@lib/helpers/str.helper'
import { getUserRoleScopes } from '@store/selectors/user.selector'
import FormTokenInterface from '@lib/interfaces/form/form-token.interface'
import { useParams } from 'react-router-dom'
import { AccountAndSettingsRoutes, useNavigation } from '@lib/router'
import { AccountAndSettingsTab } from '@lib/router/routes/account-and-settings/account-and-settings'

const ERROR_GROUPS = [ErrorGroupConstants.ACCESS]

function EditAccessTokenId() {
  const navigation = useNavigation()
  const { id: queryId } = useParams()
  const dispatch = useDispatch()
  const updatedSuccess = useSelector(getUpdatedSuccess)

  const editToken = useSelector(getEditToken)
  const possibleScopes = useSelector(getUserRoleScopes)

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_TOKEN,
    PreloaderConstants.UPDATE_TOKEN,
    PreloaderConstants.REQUEST_POSSIBLE_SCOPES,
  ])

  useEffect(() => {
    dispatch(setEditToken(null))
  }, [])

  useEffect(() => {
    if (!updatedSuccess) {
      return
    }

    navigation.push(
      AccountAndSettingsRoutes.accountAndSettingsWithParams(
        AccountAndSettingsTab.API_ACCESS
      )
    )
    return () => {
      dispatch(setUpdatedSuccess(false))
    }
  }, [updatedSuccess])

  useEffect(() => {
    if (queryId) {
      const decodedId = StrHelper.base64Decode(queryId)
      dispatch(requestToken(decodedId))
    }
  }, [queryId])

  const onSubmit = (data: FormTokenInterface) => {
    dispatch(submitEditToken(data))
  }

  return (
    <div className="innerContent">
      {loading && possibleScopes.length === 0 ? (
        <PreloaderBlock show />
      ) : (
        <>
          {editToken && (
            <TokenForm
              submitButtonLabel="Update Token"
              possibleScopes={possibleScopes}
              token={editToken}
              onSubmit={onSubmit}
              loading={loading}
              errorGroups={ERROR_GROUPS}
            />
          )}
        </>
      )}
    </div>
  )
}

export default EditAccessTokenId
