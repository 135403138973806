import React from 'react'

function ProtectionIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.2439V18.6537C3 26.9268 16 32 16 32C16 32 29 26.9268 29 18.6537V5.46341C24.7941 5.46341 18.6 3.0439 16 0C12.9412 3.12195 7.20588 6.2439 3 6.2439Z"
        fill="#32B6F3"
      />
      <path
        d="M16 0V32C16 32 29 26.9268 29 18.6537V5.46341C24.7941 5.46341 18.6 3.0439 16 0Z"
        fill="#6BCDFF"
      />
      <path
        d="M13.7824 22.7902L8.04712 17.0146L10.1883 14.7512L13.6295 18.3415L22.5001 8.58537L24.7942 10.6927L13.7824 22.7902Z"
        fill="white"
      />
    </svg>
  )
}

export default ProtectionIcon
