import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const StyledDeleteBox = styled(Box)({
  cursor: 'pointer',
})

export const StyledWarningBox = styled(Box)({
  padding: '10px 16px',
  fontSize: 'var(--font-size-small)',
  color: 'var(--yellow900)',
  backgroundColor: 'var(--yellow50)',
  fontWeight: 400,
})
