// Grpc codes: https://grpc.github.io/grpc/core/md_doc_statuscodes.html

export const enum GrpcCodes {
  OK,
  CANCELLED,
  UNKNOWN,
  INVALID_ARGUMENT,
  DEADLINE_EXCEEDED,
  NOT_FOUND,
  ALREADY_EXISTS,
  PERMISSION_DENIED,
  RESOURCE_EXHAUSTED,
  FAILED_PRECONDITION,
  ABORTED,
  OUT_OF_RANGE,
  UNIMPLEMENTED,
  INTERNAL,
  UNAVAILABLE,
  DATA_LOSS,
  UNAUTHENTICATED,
}

export const retryableErrors = [
  GrpcCodes.UNKNOWN,
  GrpcCodes.DEADLINE_EXCEEDED,
  GrpcCodes.RESOURCE_EXHAUSTED,
  GrpcCodes.ABORTED,
  GrpcCodes.INTERNAL,
  GrpcCodes.UNAVAILABLE,
]

export const notRetryableErrors = [
  GrpcCodes.OK,
  GrpcCodes.NOT_FOUND,
  GrpcCodes.INVALID_ARGUMENT,
  GrpcCodes.ALREADY_EXISTS,
  GrpcCodes.PERMISSION_DENIED,
  GrpcCodes.RESOURCE_EXHAUSTED,
  GrpcCodes.FAILED_PRECONDITION,
  GrpcCodes.OUT_OF_RANGE,
  GrpcCodes.UNIMPLEMENTED,
  GrpcCodes.DATA_LOSS,
  GrpcCodes.UNAUTHENTICATED,
]
