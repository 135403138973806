import { Request as Pagination } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { ListAnomaliesRequest } from '@lib/clients/entropy-detection/list-anomalies/index'
import { ListAnomaliesRequest as ListAnomaliesRequestGrpc } from '@lib/clients/grpc-imports'

export class ListAnomaliesPagination implements ListAnomaliesRequest {
  #pageToken: string

  constructor(pageToken: string) {
    this.#pageToken = pageToken
  }

  toGrpc(): ListAnomaliesRequestGrpc {
    return new ListAnomaliesRequestGrpc().setPagination(
      new Pagination().setPageToken(this.#pageToken)
    )
  }
}
