import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RetentionPoliciesClient } from 'ui-v2/src/lib/clients'
import { RetentionPolicyModel } from 'ui-v2/src/lib/models/settings/retention-policy/retention-policy.model'
import { useMutation } from '@tanstack/react-query'

export function useUpdateStatusRetentionPolicyMutation() {
  const retentionPoliciesClient = useGrpcClient(RetentionPoliciesClient)

  return useMutation({
    mutationFn: async (policy: RetentionPolicyModel) => {
      if (!retentionPoliciesClient) {
        throw new Error('Pechkin client not initialized')
      }
      return retentionPoliciesClient.updateStatusRetentionPolicy(policy)
    },
  })
}
