import React, { memo, ReactElement } from 'react'
import Popover from '@mui/material/Popover'
import { Button } from '@mui/material'
import InfoIcon from '@inline-img/general/info-icon'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import ModalCloseIcon from '@inline-img/general/modal-close-icon'

interface Props {
  textForPopover: string | ReactElement<any, any>
  buttonText: string
  buttonClassName: 'linkWithPopover' | 'buttonWithPopover' | 'numberWithPopover'
  isShowInfoIcon?: boolean
  isShowArrowIcon?: boolean
  wrapperClassName?: string
  popoverClassName?: string
  closable?: boolean
}

function TextPopover({
  textForPopover = '',
  buttonText = '',
  buttonClassName = 'linkWithPopover',
  isShowInfoIcon,
  isShowArrowIcon,
  wrapperClassName = '',
  popoverClassName = '',
  closable = false,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className={`wrap-1652710067625 ${wrapperClassName} jsTextPopover`}>
      {
        <>
          <Button
            aria-describedby={id}
            disableRipple
            color="primary"
            variant="text"
            className={
              isShowArrowIcon
                ? `${buttonClassName} buttonWithArrow`
                : buttonClassName
            }
            data-testid="buttonPopover"
            onClick={handleClick}
          >
            <div className="popoverButtonText">{buttonText}</div>
            {isShowInfoIcon && (
              <span className="iconInfo">
                <InfoIcon />
              </span>
            )}
            {isShowArrowIcon && (
              <span className="iconArrow">
                <SelectArrowIcon className="MuiSvgIcon-root MuiSelect-icon" />
              </span>
            )}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <div
              data-testid="textPopover"
              className={`textPopover ${popoverClassName}`}
            >
              {closable && (
                <div
                  className="closableIcon"
                  title="Close"
                  onClick={handleClose}
                >
                  <ModalCloseIcon />
                </div>
              )}
              {textForPopover}
            </div>
          </Popover>
        </>
      }
    </div>
  )
}

export default memo(TextPopover)
