import React from 'react'

export default function JobScheduledIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9935 3.33398C6.3135 3.33398 3.3335 6.32065 3.3335 10.0007C3.3335 13.6807 6.3135 16.6673 9.9935 16.6673C13.6802 16.6673 16.6668 13.6807 16.6668 10.0007C16.6668 6.32065 13.6802 3.33398 9.9935 3.33398ZM10.0002 15.334C7.0535 15.334 4.66683 12.9473 4.66683 10.0007C4.66683 7.05398 7.0535 4.66732 10.0002 4.66732C12.9468 4.66732 15.3335 7.05398 15.3335 10.0007C15.3335 12.9473 12.9468 15.334 10.0002 15.334ZM9.8535 6.66732H9.8135C9.54683 6.66732 9.3335 6.88065 9.3335 7.14732V10.294C9.3335 10.5273 9.4535 10.7473 9.66016 10.8673L12.4268 12.5273C12.6535 12.6607 12.9468 12.594 13.0802 12.3673C13.2202 12.1407 13.1468 11.8407 12.9135 11.7073L10.3335 10.174V7.14732C10.3335 6.88065 10.1202 6.66732 9.8535 6.66732Z"
        fill="#999CAA"
      />
    </svg>
  )
}
