import React, { memo } from 'react'
import DatetimeDataInterface from '@lib/interfaces/datetime-data.interface'
import { EngineCallback } from '@lib/engine-types'
import Calendar from '@components-simple/calendar/Calendar'
import DefaultTimePicker from '@components-simple/default-time-picker/DefaultTimePicker'
import Typography from '@mui/material/Typography'
import { Moment } from 'moment/moment'

interface Props {
  data: DatetimeDataInterface
  onChange: EngineCallback<DatetimeDataInterface>
  disablePast?: boolean
  dayLabel?: string
  timeLabel?: string
  dateTimeMinuteStep?: number
  minDate?: Moment
  showTimezoneLabel?: boolean
  zIndex?: number
}

function Datetime({
  data,
  onChange,
  disablePast = false,
  dayLabel = '',
  timeLabel = '',
  dateTimeMinuteStep,
  minDate,
  showTimezoneLabel = true,
  zIndex,
}: Props) {
  return (
    <div className="wrap-1612270734988">
      <div className="dateTimeWrap">
        <div>
          {(dayLabel || timeLabel) && (
            <div className="dateTimeLabel jsDayLabel">{dayLabel}</div>
          )}
          <Calendar
            disablePast={disablePast}
            value={data.day}
            minDate={minDate}
            onChange={(day) =>
              onChange({
                ...data,
                day,
              })
            }
            zIndex={zIndex}
          />
        </div>
        <div className="containerFlex alignCenter">
          {(dayLabel || timeLabel) && (
            <div className="dateTimeLabel jsTimeLabel">{timeLabel}</div>
          )}
          <DefaultTimePicker
            minuteStep={dateTimeMinuteStep}
            data={data.time}
            onChange={(time) =>
              onChange({
                ...data,
                time,
              })
            }
          />
          {showTimezoneLabel && (
            <Typography
              variant="caption"
              fontSize="14px"
              fontWeight={500}
              marginLeft={1}
            >
              (GMT+0:00) UTC
            </Typography>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Datetime)
