import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { CloudConnectorJobs, DeploymentJobs, SystemJobs } from '@features'
import { JobsRoutes } from './jobs'

export const jobsRoutes: Array<PathRouteProps> = [
  {
    path: JobsRoutes.cloudConnector,
    element: (
      <MainTopBarLayout title="Cloud Connector">
        <CloudConnectorJobs />
      </MainTopBarLayout>
    ),
  },
  {
    path: JobsRoutes.deployment,
    element: (
      <MainTopBarLayout title="Deployment">
        <DeploymentJobs />
      </MainTopBarLayout>
    ),
  },
  {
    path: JobsRoutes.system,
    element: (
      <MainTopBarLayout title="System">
        <SystemJobs />
      </MainTopBarLayout>
    ),
  },
]
