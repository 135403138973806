/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { S3Bucket } from 'blues-corejs/dist'
import { computeS3BucketState } from './compute-bucket-state'
import { RowTitle } from '../row-title'

export function BucketState({ asset }: { asset: S3Bucket }) {
  const computedState = computeS3BucketState(asset)

  return (
    <Grid container item xs={4}>
      <Grid item>
        <RowTitle title="State" />
      </Grid>
      <Grid item>
        <Typography fontSize="14px" fontWeight="400" variant="caption">
          {computedState}
        </Typography>
      </Grid>
    </Grid>
  )
}
