/* eslint-disable css-modules/no-undef-class */
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { WidgetHeader } from '../shared'
import { getInfectedServersColumnsDefinition } from './infected-servers-columns-definition'
import {
  useInfectedServersStoreActions,
  useInfectedServersStoreData,
  useIsInfectedServersLoading,
} from './infected-servers-store'
import { Container } from './styles'
import styles from './infected-servers.module.css'

/**
 * Infected Servers widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19346-170977&mode=design&t=HxsA4pMle9UFt4wO-4)
 */

interface InfectedServersProps {
  ovaAccountId: string
}

export default function InfectedServers({
  ovaAccountId,
}: InfectedServersProps) {
  const { fetchPagination, fetchInitial } = useInfectedServersStoreActions()

  const infectedServersTableData = useInfectedServersStoreData()

  const isLoading = useIsInfectedServersLoading()

  const observableState = useInfiniteScrollDataFetch({
    fetchNextBatch: fetchPagination,
    initialItems: [],
  })

  useEffect(() => {
    fetchInitial(ovaAccountId)
  }, [ovaAccountId])

  return (
    <Container className={styles.root}>
      <Stack spacing={1}>
        <WidgetHeader widgetTitle="Infected Servers" />
        <Table
          className={styles['infected-servers-table']}
          data={infectedServersTableData}
          columns={getInfectedServersColumnsDefinition()}
          generalProps={{
            isLoading,
            noDataMessage: 'No infected servers found',
          }}
          advancedProps={{
            observableState,
          }}
        />
      </Stack>
    </Container>
  )
}
