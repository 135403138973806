import FormEditUserInterface from '@lib/interfaces/form/form-edit-user.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormEditUserInterface,
  FormFieldInterface
> => ({
  // we proxy email and userId
  email: {
    name: 'email',
    label: 'Email',
    value: '',
    validationRules: {},
  },
  userId: {
    name: 'userId',
    label: '',
    value: '',
    validationRules: {},
  },

  roleId: {
    name: 'roleId',
    label: 'Role(s)',
    value: '',
    validationRules: {},
  },
})

export default buildDefaultFormData
