import React from 'react'
import { ButtonPrimary, ButtonPrimaryOwnProps } from '@components/shared/button'
import { useStepperState, useStepperActions } from '../../stepper/stepper-store'
import { Step, StepWithoutInfrastructure } from '../../stepper/constants'

const isFirstStep = (step: Step | StepWithoutInfrastructure): boolean =>
  step === Step.RULE_DETAILS

function PreviousButton(props: ButtonPrimaryOwnProps) {
  const { step: currentStep } = useStepperState()
  const { goPrev } = useStepperActions()

  if (isFirstStep(currentStep)) {
    return null
  }

  return (
    <ButtonPrimary onClick={goPrev} isDisabled={false} {...props}>
      Back
    </ButtonPrimary>
  )
}

export default PreviousButton
