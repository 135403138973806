/* eslint-disable import/no-extraneous-dependencies */
import {
  File,
  AssetItem,
  Disk,
  EfsItem,
  LocalVolume,
  S3Item,
  Stream,
} from 'blues-corejs/dist'
import SystemHelper from '@lib/helpers/system.helper'

export function computeAssetItemName(assetItem: AssetItem) {
  if (assetItem instanceof File) {
    return assetItem.path
  }

  if (assetItem instanceof Stream) {
    return assetItem.name
  }

  if (assetItem instanceof LocalVolume) {
    return assetItem.deviceName
  }

  if (assetItem instanceof EfsItem) {
    return assetItem.name
  }

  if (assetItem instanceof S3Item) {
    return assetItem.selectors.map((selector) => selector.value).join(', ')
  }

  if (assetItem instanceof Disk) {
    return assetItem.deviceName
  }

  SystemHelper.sendSentryIfProd(
    `computeAssetItemName: Unknown asset item type: ${assetItem.id}`
  )
  return ''
}
