import React from 'react'
import moment from 'moment/moment'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import {
  StyledLabelSuppressionForm,
  StyledSuppressionFormItem,
} from '@features/threat-suppression/styles'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import { Backdrop, Box, Button, Typography } from '@mui/material'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useThreatSuppression } from '@features/threat-suppression/use-suppression'
import { convertMillisecondsToSeconds } from '@components-composite/date-range-picker-with-call-api/transform-date-range-for-api'
import CircularProgress from '@mui/material/CircularProgress'
import Datetime from '@components-composite/datetime/Datetime'
import DatetimeDataInterface from '@lib/interfaces/datetime-data.interface'
import { EngineCallback } from '@lib/engine-types'
import { useThreatSuppressionStore } from '@features/threat-suppression/use-threat-suppression-modal-store'

interface Props {
  callbackAfterSuccess: EngineCallback<void>
}

function ErrorText({ text }: { text: string }) {
  if (text === '') {
    return null
  }

  return (
    <Typography
      width="100%"
      marginY="5px"
      variant="caption"
      color="error"
      fontSize="12px"
    >
      {text}
    </Typography>
  )
}

const suppressionFormSchema = z.object({
  endDate: z.any().optional(),
  reason: z.string().min(1, 'The reason is required'),
})

type SuppressionFormData = z.infer<typeof suppressionFormSchema>

function ThreatSuppressionForm({ callbackAfterSuccess }: Props) {
  const { closeModal, isThreatsLoading, threatInfo } =
    useThreatSuppressionStore()
  const { threatId } = threatInfo
  const { handleThreatSuppression, isLoading } = useThreatSuppression({
    callbackAfterSuppression: callbackAfterSuccess,
  })
  const showLoader = isThreatsLoading || isLoading

  const userTimezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value
  const {
    control,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<z.infer<typeof suppressionFormSchema>>({
    resolver: zodResolver(suppressionFormSchema),
    mode: 'all',
    defaultValues: {
      endDate: moment().tz(userTimezone).add('5', 'minute'),
      reason: '',
    },
  })
  const isSaveButtonDisabled = Object.keys(errors).length > 0

  const minDate = moment().tz(userTimezone)

  const validateEndDate = (date: moment.Moment) => {
    const currentDate = moment().tz(userTimezone)
    if (date.isBefore(currentDate)) {
      setError('endDate', {
        type: 'manual',
        message: 'The end date is invalid or in the past!',
      })
    } else {
      clearErrors('endDate')
    }
  }

  const handleChangeDateTime = (v: DatetimeDataInterface) => {
    const date = moment(v.day).tz(userTimezone)
    date.hours(v.time.hour)
    date.minutes(v.time.minute)
    setValue('endDate', date)

    validateEndDate(date)
  }

  const onSubmit = (data: SuppressionFormData) => {
    const date = moment(data.endDate).tz(userTimezone)
    validateEndDate(date)

    if (Object.keys(errors).length > 0) {
      return
    }

    const dateInSeconds =
      convertMillisecondsToSeconds(data.endDate.valueOf()) ?? 0
    handleThreatSuppression({
      threatId,
      until: dateInSeconds,
      reason: data.reason,
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSuppressionFormItem>
          <StyledLabelSuppressionForm>End Date</StyledLabelSuppressionForm>
          <Controller
            name="endDate"
            control={control}
            render={({ field, fieldState }) => {
              const day = field.value?.valueOf()
              const hour = moment(day).tz(userTimezone).hours()
              const minute = moment(day).tz(userTimezone).minutes()

              return (
                <>
                  <Datetime
                    {...field}
                    data={{
                      day: day,
                      time: {
                        hour: hour,
                        minute: minute,
                        timezone: userTimezone,
                      },
                    }}
                    onChange={handleChangeDateTime}
                    minDate={minDate}
                    showTimezoneLabel={false}
                    zIndex={1500}
                  />
                  <ErrorText text={fieldState.error?.message || ''} />
                </>
              )
            }}
          />
        </StyledSuppressionFormItem>

        <StyledSuppressionFormItem>
          <StyledLabelSuppressionForm>Reason</StyledLabelSuppressionForm>
          <Controller
            name="reason"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  {...field}
                  required
                  variant="outlined"
                  error={!!fieldState.error?.message}
                  helperText={
                    <ErrorText text={fieldState.error?.message || ''} />
                  }
                  placeholder="Reason..."
                  size="small"
                  fullWidth
                />
              )
            }}
          />
        </StyledSuppressionFormItem>
        <StyledSuppressionFormItem>
          <Box display="flex" justifyContent="space-between">
            <Button
              type="submit"
              variant="contained"
              disabled={isSaveButtonDisabled}
            >
              Suppress
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </StyledSuppressionFormItem>
      </form>
      <Backdrop
        sx={(theme) => ({
          color: '#fff',
          zIndex: theme.zIndex.drawer + 2,
          position: 'absolute',
        })}
        open={showLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default ThreatSuppressionForm
