/* eslint-disable import/no-extraneous-dependencies */
import { ListAssetsByIdsClient } from '@lib/clients'
import { ListAssetsByIds } from '@lib/clients/assets/list-assets-by-ids'
import { Asset } from 'blues-corejs/dist'
import { useAssetStore } from '@features/jobs/cloud-connector-jobs/stores/asset-store/asset-store'

const assetsByIdsClient = new ListAssetsByIdsClient()

function getNotEmptyAsset(assetsList: ListAssetsByIds['assetsList'][number]) {
  return [
    assetsList?.ebsVolume,
    assetsList?.ec2Instance,
    assetsList?.efsFilesystem,
    assetsList?.genericHost,
    assetsList?.s3Bucket,
    assetsList.ovaServer,
  ].find((asset) => asset !== undefined)
}

async function fetchAssetsByIds(assetIds: Array<string>) {
  const response = await assetsByIdsClient.listAssetsByIds(assetIds)

  const assetsMap = new Map<string, Asset>()

  response.assetsList.forEach((assetList) => {
    const asset = getNotEmptyAsset(assetList)

    if (!asset) {
      return
    }

    assetsMap.set(asset.id, asset)
  })

  return assetsMap
}

function fetchAssetByIdsAction(assetIds: Array<string>) {
  return useAssetStore.getState().actions.fetchAssetsByIds(assetIds)
}

export { fetchAssetsByIds, fetchAssetByIdsAction }
