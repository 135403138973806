import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { FrequencyType } from 'ui-v2/src/lib/models/reports'
import dayjs from 'dayjs'

export const generateScheduleOptions = (date: string | number) => {
  const inputDate = dayjs(date)
  const dayOfMonth = inputDate.date()
  const MAX_DAY_OF_MONTH = 28

  return [
    {
      label: FrequencyType.DAILY,
      value: FrequencyType.DAILY,
    },

    {
      label: `${FrequencyType.WEEKLY} on ${inputDate.format('dddd')}`,
      value: `${FrequencyType.WEEKLY}_${inputDate.format('dddd')}`,
    },
    {
      label:
        dayOfMonth > MAX_DAY_OF_MONTH
          ? `${FrequencyType.MONTHLY} on the last day`
          : `${FrequencyType.MONTHLY} on the ${inputDate.format('Do')}`,
      value: `${FrequencyType.MONTHLY}_${
        dayOfMonth > MAX_DAY_OF_MONTH ? 0 : dayOfMonth
      }`,
    },
    {
      label: FrequencyType.CUSTOM,
      value: FrequencyType.CUSTOM,
    },
  ]
}
const Frequency = ({ value, scheduleStartDate, onChange }: FrequencyProps) => {
  return (
    <BasicSelect
      value={value}
      onChange={(newValue) => {
        onChange(newValue as string)
      }}
      options={generateScheduleOptions(
        scheduleStartDate?.toISOString() ?? dayjs().toISOString()
      )}
      size="small"
    />
  )
}
interface FrequencyProps {
  value: string | number
  scheduleStartDate: Date | null
  onChange: (value: string) => void
}
export default Frequency
