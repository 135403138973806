import { useEffect, useState } from 'react'
import ErrorInterface from '@lib/interfaces/error.interface'
import ErrorService from '@lib/services/error.service'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { EngineCallback } from '@lib/engine-types'

function useError(
  errorGroupArr: Array<ErrorGroupConstants>
): [string, EngineCallback<string>] {
  const [error, setError] = useState('')
  useEffect(() => {
    const s = ErrorService.filter(errorGroupArr).subscribe(
      (e: ErrorInterface) => {
        setError(e.message)
      }
    )
    return () => s.unsubscribe()
  }, [errorGroupArr])
  return [error, setError]
}

export default useError
