import React from 'react'

function PausedIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 23.8333C15.5833 23.8333 16.3333 23.0833 16.3333 22.1667V13.8333C16.3333 12.9167 15.5833 12.1667 14.6667 12.1667C13.75 12.1667 13 12.9167 13 13.8333V22.1667C13 23.0833 13.75 23.8333 14.6667 23.8333ZM19.6667 13.8333V22.1667C19.6667 23.0833 20.4167 23.8333 21.3333 23.8333C22.25 23.8333 23 23.0833 23 22.1667V13.8333C23 12.9167 22.25 12.1667 21.3333 12.1667C20.4167 12.1667 19.6667 12.9167 19.6667 13.8333Z"
        fill="#4E5665"
      />
    </svg>
  )
}

export default PausedIcon
