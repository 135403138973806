/* eslint-disable import/no-extraneous-dependencies */
import {
  ListReportSchedulesRequest,
  ReportSchedulesPromiseClient,
} from 'ui-v2/src/lib/grpc'
import { GrpcClient } from '../grpc'
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule/report-schedule'
import { convertReportSchedulesList } from './list-report-schedules'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import {
  CreateReportScheduleCriteria,
  buildCreateReportScheduleRequest,
} from './schedule/create-report-schedule'
import GrpcError from 'ui-v2/src/lib/helpers/grpc-error.helper'
import { GrpcCodes } from 'ui-v2/src/lib/constants/api-errors.constant'
import {
  buildDeleteReportScheduleRequest,
  DeleteReportScheduleCriteria,
} from './schedule/delete-report-schedule'
import {
  buildUpdateReportScheduleRequest,
  UpdateReportScheduleCriteria,
} from './schedule/update-report-schedule'

export class ReportSchedulesClient extends GrpcClient<ReportSchedulesPromiseClient> {
  #reportSchedulesClient: ReportSchedulesPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#reportSchedulesClient = this.getClient(hostName)
    this.#token = token
  }

  protected initClient(hostName: string): ReportSchedulesPromiseClient {
    return new ReportSchedulesPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ReportSchedulesClient'
  }

  async listReportSchedules(): Promise<{
    listReportSchedules: Array<ReportSchedule>
  }> {
    const request = new ListReportSchedulesRequest()

    const response = await this.callGrpcService(
      () =>
        this.#reportSchedulesClient.listReportSchedules(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'ReportSchedulesClient/listReportSchedules',
      }
    )

    return {
      listReportSchedules: convertReportSchedulesList(response),
    }
  }

  async createReportSchedule(
    data: CreateReportScheduleCriteria
  ): Promise<void> {
    const request = buildCreateReportScheduleRequest(data)

    try {
      await this.callGrpcService(
        () =>
          this.#reportSchedulesClient.createReportSchedule(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'ReportSchedulesClient/createReportSchedule',
        }
      )
    } catch (error) {
      if (error instanceof GrpcError) {
        const grpcError = new GrpcError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: 'ReportSchedulesClient/createReportSchedule',
        })
        throw grpcError
      }
    }
  }

  async updateReportSchedule(
    data: UpdateReportScheduleCriteria
  ): Promise<void> {
    const request = buildUpdateReportScheduleRequest(data)

    try {
      await this.callGrpcService(
        () =>
          this.#reportSchedulesClient.updateReportSchedule(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'ReportSchedulesClient/updateReportSchedule',
        }
      )
    } catch (error) {
      if (error instanceof GrpcError) {
        const grpcError = new GrpcError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: 'ReportSchedulesClient/createReportSchedule',
        })
        throw grpcError
      }
    }
  }

  async deleteReportSchedule({
    id,
  }: DeleteReportScheduleCriteria): Promise<void> {
    const request = buildDeleteReportScheduleRequest({ id })

    try {
      await this.callGrpcService(
        () =>
          this.#reportSchedulesClient.deleteReportSchedule(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'ReportSchedulesClient/deleteReportSchedule',
        }
      )
    } catch (error) {
      if (error instanceof GrpcError) {
        const grpcError = new GrpcError({
          code: error.code ?? GrpcCodes.NOT_FOUND,
          message: '',
          metadata: {},
          requestName: 'ReportSchedulesClient/deleteReportSchedule',
        })
        throw grpcError
      }
    }
  }
}
