import React from 'react'
import {
  drawCheckboxColumn,
  drawCircleColumn,
  drawCommaSeparatedColumn,
} from '@tables/core/table-draw'
import {
  EngineCallback,
  EngineCallbackDouble,
  EngineCallbackPure,
  RowSimpleUnit,
} from '@lib/engine-types'
import ManageRolesOnChangeInterface from '@lib/interfaces/manage-roles-on-change.interface'
import RoleNameForm from '@forms/role-name/role-name.form'
import FormRoleNameInterface from '@lib/interfaces/form/form-role-name.interface'
import clsx from 'clsx'
import { Button } from '@mui/material'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

interface Props {
  rows: Array<RowSimpleUnit>
  rolesNames: Array<string>
  scopesCategories: Array<string>
  shouldShowRemoveButton?: boolean
  shouldDisableSaveButton?: boolean
  loading?: boolean
  canEdit?: boolean
  editColumnIndex?: number
  onAddRole?: EngineCallbackPure
  onCancelAddRole?: EngineCallbackPure
  onRemoveRole?: EngineCallbackPure
  onSetRoleForChanging?: EngineCallbackDouble<string, number>
  onPermissionChange?: EngineCallback<ManageRolesOnChangeInterface>
  onSubmit?: EngineCallback<string>
}

function ManageRolesTable({
  rows,
  rolesNames,
  scopesCategories,
  shouldShowRemoveButton = false,
  shouldDisableSaveButton = false,
  loading = false,
  canEdit = false,
  editColumnIndex = -1,
  onAddRole = () => null,
  onCancelAddRole = () => null,
  onRemoveRole = () => null,
  onSetRoleForChanging = () => null,
  onPermissionChange = () => null,
  onSubmit = () => null,
}: Props) {
  const onSetEditMode = (index: number) => {
    if (index !== -1) {
      onSetRoleForChanging(rolesNames[index] ?? '', index)
    }
  }

  const onInnerPermissionChange = (
    value: boolean,
    subIndex: number,
    rowIndex: number
  ) => {
    onPermissionChange({
      roleName: rolesNames[editColumnIndex] ?? '',
      categoryName: scopesCategories[rowIndex] ?? '',
      permissionName:
        String(rows[rowIndex]?.[1] ?? '').split(', ')[subIndex] ?? '',
      value,
    })
  }

  const onInnerSubmit = (data: FormRoleNameInterface) => {
    onSubmit(data.roleName)
  }

  return (
    <table className={clsx('manageRolesTable', { mrtDisabled: loading })}>
      <thead>
        <tr>
          <th className="addRoleHeadColumn">
            {canEdit && editColumnIndex === -1 && (
              <div className="addRoleBtnWrap">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onAddRole}
                  disabled={loading}
                >
                  + New Role
                </Button>
              </div>
            )}
          </th>
          {rolesNames.map((roleName, roleIndex) => (
            <th key={roleName}>
              {canEdit ? (
                <>
                  {roleIndex === editColumnIndex ? (
                    <div className="mrtNameForm">
                      <RoleNameForm
                        loading={loading}
                        defaultValues={{
                          roleName,
                        }}
                        shouldShowRemoveButton={shouldShowRemoveButton}
                        shouldDisableSaveButton={shouldDisableSaveButton}
                        onRemove={onRemoveRole}
                        onCancel={onCancelAddRole}
                        onSubmit={onInnerSubmit}
                      />
                    </div>
                  ) : (
                    <div className="mrEditHead">
                      <LongTextTooltip text={roleName ?? ''} maxLength={50} />
                      <button
                        disabled={loading || editColumnIndex !== -1}
                        type="button"
                        className="mrEditBtn"
                        onClick={() => onSetEditMode(roleIndex)}
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="mrHeadWrapper">
                    <div className="mrHead">{roleName}</div>
                  </div>
                </>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((r, rowIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={rowIndex}>
            <td>
              <div className="groupLabel">{scopesCategories[rowIndex]}</div>
              <div className="groupContent">
                {drawCommaSeparatedColumn(r[1] ?? '')}
              </div>
            </td>
            {rolesNames.map((roleName, columnIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={roleName}>
                {canEdit && columnIndex === editColumnIndex ? (
                  <td className="editInner">
                    <div className="mrContent">
                      {drawCheckboxColumn(
                        r[columnIndex + 2] ?? '',
                        (value, subIndex) =>
                          onInnerPermissionChange(value, subIndex, rowIndex)
                      )}
                    </div>
                  </td>
                ) : (
                  <td className="simpleInner">
                    <div className="mrContent">
                      {drawCircleColumn(r[columnIndex + 2] ?? '')}
                    </div>
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ManageRolesTable
