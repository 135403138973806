import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import {
  ReLogin,
  InitError,
  AccessDenied,
} from '@components/components-complex'
import { ErrorLayout } from '@components/components-complex/layouts'
import PageTitle from './page-title'
import { PublicRoutes } from './routes/public'
import { Link } from '@lib/router/navigation-strategies'
import VerificationStatusIdPage from '@features/verification-status/verification-status'
import VerificationLoadingPage from '@features/verification-loading/verification-loading'

export const publicRoutes: Array<PathRouteProps> = [
  {
    path: PublicRoutes.relogin,
    element: (
      <>
        <PageTitle title="Re-login" />
        <ErrorLayout>
          <ReLogin />
        </ErrorLayout>
      </>
    ),
  },
  {
    path: PublicRoutes.initError,
    element: (
      <>
        <PageTitle title="Initialization error" />
        <ErrorLayout>
          <InitError />
        </ErrorLayout>
      </>
    ),
  },
  {
    path: PublicRoutes.accessDenied,
    element: (
      <>
        <PageTitle title="Access denied" />
        <ErrorLayout>
          <AccessDenied />
        </ErrorLayout>
      </>
    ),
  },
  {
    path: PublicRoutes.e2eHost,
    element: (
      <div className="innerContent">
        <div>
          <a className="jsLogoutLink" href={PublicRoutes.logout}>
            Logout
          </a>
        </div>
      </div>
    ),
  },
  {
    path: PublicRoutes.verification,
    element: (
      <div className="innerContent jsVerificationPage">
        <div className="verificationContainer">
          <div className="verificationTitle">
            Email verification is required
          </div>
          <div className="verificationDescription">
            An email with a verification link has been sent to your email
            address.
            <br />
            Please check your inbox and follow the link.
          </div>
          <Link to={PublicRoutes.logout} passHref>
            <button className="verificationPageButton">
              Back to login page
            </button>
          </Link>
        </div>
      </div>
    ),
  },
  {
    path: PublicRoutes.verificationStatusIdPlaceholder,
    element: <VerificationStatusIdPage />,
  },
  {
    path: PublicRoutes.verificationLoading,
    element: <VerificationLoadingPage />,
  },
]
