import QuizIcon from '@inline-img/general/quiz-icon'
import { EmptyFunc } from '@lib/constants/app.constant'
import { Button } from '@mui/material'
import React, { memo } from 'react'

interface Props {
  className?: string
  onClick?: () => void
  disabled?: boolean
  text?: string
}

function TestWebhookButton({
  className = '',
  onClick = EmptyFunc,
  disabled = false,
  text = '',
}: Props) {
  return (
    <Button
      className={`${className} showButton sizeS jsCancel`}
      startIcon={<QuizIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{text}</span>
    </Button>
  )
}

export default memo(TestWebhookButton)
