import { TIME_UNITS, MIN, HR, DAY } from './consts'

function getLabel(value: number, unit: string): string {
  return value === 1 ? unit : `${unit}s`
}

export function convertMillisecondsToMinutes(milliseconds: number): number {
  return Math.round((milliseconds / (1000 * 60)) * 100) / 100
}

export function convertMillisecondsToHours(milliseconds: number): number {
  return Math.round((milliseconds / TIME_UNITS.hour) * 100) / 100
}

export function convertMillisecondsToDays(milliseconds: number): number {
  return Math.round((milliseconds / TIME_UNITS.day) * 100) / 100
}

export function convertMillisecondsToMonths(milliseconds: number): number {
  return Math.round((milliseconds / TIME_UNITS.month) * 100) / 100
}

function isLessThanHour(milliseconds: number) {
  return milliseconds < TIME_UNITS.hour
}

function isLessThanDay(milliseconds: number) {
  return milliseconds < TIME_UNITS.day
}

export function convertMillisecondsWithLabel(milliseconds: number) {
  let computedValue: number

  if (isLessThanHour(milliseconds)) {
    computedValue = convertMillisecondsToMinutes(milliseconds)
    return {
      value: computedValue,
      label: getLabel(computedValue, MIN),
      defaultFormatDate: `${computedValue} ${getLabel(computedValue, MIN)}`,
    }
  }

  if (isLessThanDay(milliseconds)) {
    computedValue = convertMillisecondsToHours(milliseconds)
    return {
      value: computedValue,
      label: getLabel(computedValue, HR),
      defaultFormatDate: `${computedValue} ${getLabel(computedValue, HR)}`,
    }
  }

  computedValue = convertMillisecondsToDays(milliseconds)

  return {
    value: computedValue,
    label: getLabel(computedValue, DAY),
    defaultFormatDate: `${computedValue} ${getLabel(computedValue, DAY)}`,
  }
}
