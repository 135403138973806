import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Popover from '@mui/material/Popover'
import InputAdornment from '@mui/material/InputAdornment'
import Search from '@mui/icons-material/Search'
import ChevronRight from '@mui/icons-material/ChevronRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { SelectTrigger, StyledMenuItem, StyledPopover } from './styled'
import { useFormContext } from 'react-hook-form'
import { Button } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

export interface NestedOption {
  id: string
  name: string
  children?: Array<NestedOption>
}

interface FlexibleNestedSelectProps {
  options: Array<NestedOption>
  searchPlaceholder?: string
  allOptionText?: string
  name: string
}

const getAllIds = (nestedOptions: Array<NestedOption>): Array<string> => {
  return nestedOptions.flatMap((option) => [
    option.id,
    ...(option.children ? getAllIds(option.children) : []),
  ])
}

export default function FlexibleNestedSelect({
  options,
  searchPlaceholder = 'Search options',
  allOptionText = 'All',
  name,
}: FlexibleNestedSelectProps) {
  const { setValue, watch } = useFormContext()
  const selectedIds = watch(name) || []

  const [isOpenPopover, setIsOpenPopover] = React.useState(false) // Rename to avoid shadowing
  const [searchTerm, setSearchTerm] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openOptionId, setOpenOptionId] = React.useState<string | null>(null)
  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const toggleOption = (id: string) => {
    setValue(
      name,
      selectedIds.includes(id)
        ? selectedIds.filter((existingId: string) => existingId !== id)
        : [...selectedIds, id]
    )
  }

  const handleParentSelect = (option: NestedOption) => {
    const childIds = option.children?.map((child) => child.id) || []
    const allSelected = childIds.every((id) => selectedIds.includes(id))

    setValue(
      name,
      allSelected
        ? selectedIds.filter((id: string) => !childIds.includes(id))
        : [...new Set([...selectedIds, ...childIds])]
    )
  }

  const handleSelectAll = () => {
    const allIds = getAllIds(options)
    setValue(name, selectedIds.length === allIds.length ? [] : allIds)
  }

  const filterOptions = (
    nestedOptions: Array<NestedOption>,
    term: string
  ): Array<NestedOption> => {
    return nestedOptions
      .filter(
        (option) =>
          option.name.toLowerCase().includes(term.toLowerCase()) ||
          (option.children && filterOptions(option.children, term).length > 0)
      )
      .map((option) => ({
        ...option,
        children: option.children
          ? filterOptions(option.children, term)
          : undefined,
      }))
  }

  const filteredOptions = filterOptions(options, searchTerm)
  const allIds = getAllIds(options)
  const isAllSelected = allIds.every((id) => selectedIds.includes(id))

  const handleTriggerClick = () => {
    setIsOpenPopover(true)
  }

  const handleClose = () => {
    setIsOpenPopover(false)
    setAnchorEl(null)
    setOpenOptionId(null)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleOptionClick = (
    event: React.MouseEvent<HTMLElement>,
    optionId: string
  ) => {
    if (openOptionId === optionId) {
      setOpenOptionId(null)
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
      setOpenOptionId(optionId)
    }
  }

  return (
    <Box component="div" className="w-[300px]">
      <SelectTrigger ref={triggerRef} onClick={handleTriggerClick}>
        <Box component="span">
          {selectedIds.length === 0
            ? allOptionText
            : `${selectedIds.length} selected`}
        </Box>
        <ArrowDropDownIcon className="h-4 w-4" />
      </SelectTrigger>
      <Popover
        open={isOpenPopover}
        anchorEl={triggerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: 300,
            mt: 1,
            borderRadius: 2,
          },
        }}
      >
        <Box p={2}>
          <TextField
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
            placeholder={searchPlaceholder}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className="h-5 w-5 text-gray-400" />
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <Box
              sx={{
                padding: 1,
              }}
            >
              <Button
                onClick={handleSelectAll}
                variant={isAllSelected ? 'contained' : 'outlined'}
                size="small"
              >
                {allOptionText}
              </Button>
            </Box>
            {filteredOptions.map((option) => {
              const isOptionSelected = option.children
                ? option.children.some((child) =>
                    selectedIds.includes(child.id)
                  )
                : selectedIds.includes(option.id)
              const isOpenChild = openOptionId === option.id

              return (
                <React.Fragment key={option.id}>
                  <StyledMenuItem
                    onClick={(event) => handleOptionClick(event, option.id)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    selected={isOptionSelected}
                  >
                    <Box component="span">{option.name}</Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {option.children &&
                        (isOpenChild ? (
                          <ExpandMore className="h-4 w-4" />
                        ) : (
                          <ChevronRight className="h-4 w-4" />
                        ))}
                    </Box>
                  </StyledMenuItem>
                  {option.children && (
                    <StyledPopover
                      open={isOpenChild}
                      anchorEl={anchorEl}
                      onClose={() => {
                        setAnchorEl(null)
                        setOpenOptionId(null)
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            padding: 1,
                          }}
                        >
                          <Button
                            onClick={() => handleParentSelect(option)}
                            variant={isAllSelected ? 'contained' : 'outlined'}
                            size="small"
                          >
                            {isOptionSelected ? 'Deselect All' : 'Select All'}
                          </Button>
                        </Box>

                        {option.children.map((child) => (
                          <StyledMenuItem
                            key={child.id}
                            selected={selectedIds.includes(child.id)}
                            onClick={() => toggleOption(child.id)}
                          >
                            {child.name}
                          </StyledMenuItem>
                        ))}
                      </Box>
                    </StyledPopover>
                  )}
                </React.Fragment>
              )
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
