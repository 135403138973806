export const FORM_FIELDS = {
  REPORT_TYPE: 'reportType',
  OVA_ACCOUNTS: 'ovaAccounts',
  RECIPIENTS: 'recipients',
  BACKUP_PROVIDER: 'backupProvider',
  DESCRIPTION: 'description',
  TIME_RANGE: 'timeRange',
  FORMATS: 'formats',
  START_TIME_OF_DATA_RANGE: 'startTimeOfDataRange',
  START_AT: 'startAt',
  END_AT: 'endAt',
} as const
