import React from 'react'

export default function SpeedometerLowRiskIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#E4F7FF" />
      <path
        d="M15.2968 10.0862V5.64844C12.5864 5.80402 10.0447 6.85725 8.01587 8.66536L11.1535 11.803C12.3343 10.82 13.768 10.2259 15.2968 10.0862Z"
        fill="#32B6F3"
      />
      <path
        d="M10.1588 12.7938L7.02125 9.65625C5.2116 11.6845 4.15705 14.2264 4.00049 16.9375H8.43847C8.5791 15.4084 9.17441 13.9745 10.1588 12.7938Z"
        fill="#32B6F3"
      />
      <path
        d="M23.5615 16.9375H27.9995C27.843 14.2264 26.7885 11.6845 24.9788 9.65625L21.8412 12.7938C22.8256 13.9745 23.4209 15.4084 23.5615 16.9375Z"
        fill="#32B6F3"
      />
      <path
        d="M23.5604 18.3438C23.3279 20.8983 21.9255 22.457 21.3688 23.0137C21.0937 23.2887 21.0937 23.7347 21.3688 24.0098L23.5021 26.1433C23.6397 26.2808 23.82 26.3496 24.0002 26.3496C24.1805 26.3496 24.3607 26.2808 24.4982 26.1433C26.604 24.0377 27.8319 21.2901 27.9999 18.3438H23.5604Z"
        fill="#32B6F3"
      />
      <path
        d="M8.36252 22.3781C8.55892 22.0695 8.8562 21.6047 9.21283 21.0548C8.84725 20.3302 8.53811 19.4268 8.43953 18.3438H4C4.16805 21.2901 5.39594 24.0377 7.5017 26.1433C7.63923 26.2809 7.81947 26.3496 7.9997 26.3496C8.17994 26.3496 8.36022 26.2808 8.49775 26.1433L9.22825 25.4128C9.01666 25.3109 8.82142 25.1732 8.65047 25.0023C7.94603 24.2978 7.82758 23.2187 8.36252 22.3781Z"
        fill="#32B6F3"
      />
      <path
        d="M16.7031 5.64844V10.0862C18.2319 10.2259 19.6657 10.82 20.8465 11.803L23.9841 8.66536C21.9553 6.8572 19.4135 5.80402 16.7031 5.64844Z"
        fill="#32B6F3"
      />
      <path
        d="M14.4378 16.0914C13.3495 17.1796 9.9347 22.5278 9.54892 23.1341C9.37196 23.4121 9.4119 23.7757 9.64492 24.0088C9.87793 24.2418 10.2415 24.2817 10.5196 24.1048C11.1258 23.719 16.4741 20.3042 17.5623 19.2159C18.4238 18.3545 18.4238 16.9528 17.5623 16.0914C16.7009 15.23 15.2992 15.2299 14.4378 16.0914Z"
        fill="#32B6F3"
      />
    </svg>
  )
}
