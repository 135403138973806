/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import {
  Asset,
  AWSBRecoveryPoint,
  Backup,
  CloudConnector,
} from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import Stack from '@mui/material/Stack'
import { RowTitle } from './row-title'
import { RexClient } from '@lib/clients'
import Typography from '@mui/material/Typography'
import { CopyToClipboardIcon } from '@features/common'
import RegionIcon from '@mui/icons-material/Place'
import LangHelper from '@lib/helpers/lang.helper'

interface Props {
  backup: Backup
  asset: Asset
}

function getIsRecoveryPointReplicated(
  backup: AWSBRecoveryPoint,
  asset: AWSAsset
) {
  return backup.accountId !== asset.awsAccountId
}

const rexClient = new RexClient()

const computeTargetAccount = (cloudConnector: CloudConnector) =>
  cloudConnector.accountAlias
    ? `${cloudConnector.accountAlias} (${cloudConnector.awsAccountId})`
    : cloudConnector.awsAccountId

function TargetAccount({ backup }: { backup: AWSBRecoveryPoint }) {
  const [cloudConnector, setCloudConnector] = useState<
    CloudConnector | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchCloudConnector() {
      try {
        setIsLoading(true)
        const cloudConnectorList = await rexClient.listGetAllRedstacks()

        const fetchedCloudConnector = cloudConnectorList.find(
          ({ awsAccountId }) => backup.accountId === awsAccountId
        )

        if (!fetchedCloudConnector) {
          return
        }

        setCloudConnector(fetchedCloudConnector)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchCloudConnector()

    return () => {
      setIsLoading(false)
      setCloudConnector(undefined)
    }
  }, [])

  if (isLoading || !cloudConnector) {
    return null
  }

  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Target account" />
      <Typography fontSize="14px">
        {computeTargetAccount(cloudConnector)}
      </Typography>
      <CopyToClipboardIcon text={cloudConnector.awsAccountId} fontSize={16} />
    </Stack>
  )
}

function TargetRegion({ backup }: { backup: AWSBRecoveryPoint }) {
  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Target region" />
      <RegionIcon color="disabled" fontSize="inherit" />
      <Typography
        fontSize="14px"
        fontWeight="400"
        variant="caption"
        marginLeft="5px"
      >
        {LangHelper.getAwsRegionSingleTranslation(backup.region)}
      </Typography>
    </Stack>
  )
}

export function ReplicatedDetails({ asset, backup }: Props) {
  if (!(backup instanceof AWSBRecoveryPoint) || !(asset instanceof AWSAsset)) {
    return null
  }

  const isRecoveryPointReplicated = getIsRecoveryPointReplicated(backup, asset)

  if (!isRecoveryPointReplicated) {
    return null
  }

  return (
    <Stack marginTop="16px" direction="row" alignContent="center" gap="24px">
      <TargetAccount backup={backup} />
      <TargetRegion backup={backup} />
    </Stack>
  )
}
