/* eslint-disable import/no-extraneous-dependencies */
import { CellWithDateTimeTooltip, createColumn } from '@components/table'
import { Threat } from 'blues-corejs/dist'
import React from 'react'
import { AssetLinkCell, ThreatPathCell } from './cells'
import { computeAssetKind } from './helpers'
import { AssetByThreat } from './types'

interface ColumnDefinitionParams {
  assetByThreat: AssetByThreat
}

export function getActiveThreatsColumnDefinitions({
  assetByThreat,
}: ColumnDefinitionParams) {
  return [
    createColumn<Threat>('source', {
      id: 'asset-name',
      header: 'Asset name',
      cell: ({ row }) => {
        const asset = assetByThreat.get(row.original)

        if (!asset) {
          return null
        }

        return <AssetLinkCell asset={asset} />
      },
      size: 80,
    }),
    createColumn<Threat>('source', {
      id: 'asset-type',
      header: 'Asset type',
      cell: ({ row }) => {
        const asset = assetByThreat.get(row.original)

        if (!asset) {
          return null
        }

        return computeAssetKind(asset)
      },
      size: 40,
    }),
    createColumn<Threat>('firstDetectedAt', {
      header: 'First Detected',
      cell: ({ getValue }) => {
        const firstDetectedAt = getValue() as unknown as Date
        return <CellWithDateTimeTooltip date={firstDetectedAt} />
      },
      size: 60,
    }),
    createColumn<Threat>('location', {
      header: 'Path',
      cell: ({ getValue }) => {
        const threatPath = getValue() as unknown as string

        return <ThreatPathCell path={threatPath} />
      },
    }),
    createColumn<Threat>('name', {
      header: 'Threat name',
    }),
  ]
}
