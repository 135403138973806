import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { EntropyTrendsRoutes } from './entropy-trends'
import { SystemHelper, TimeHelper } from '@lib/helpers'
import { EntropyTrendsPage as EntropyTrends } from '@features/entropy-trends'
import { EntropyFiltersProvider } from '@features/entropy-trends/components/entropy-trends-filters'

function getInitialTimeRange() {
  const endAt = TimeHelper.getDateWithTimezone(Date.now()).endOf('day')
  const startAt = endAt.clone().startOf('day')

  return {
    startAt: startAt.valueOf(),
    endAt: endAt.valueOf(),
  }
}
const pageTitle = SystemHelper.getCurrentPageName('Entropy Trends')

export const entropyTrendsRoutes: Array<PathRouteProps> = [
  {
    path: EntropyTrendsRoutes.root,
    element: (
      <MainTopBarLayout title={pageTitle}>
        <EntropyFiltersProvider getInitialTimeRange={getInitialTimeRange}>
          <EntropyTrends getInitialTimeRange={getInitialTimeRange} />
        </EntropyFiltersProvider>
      </MainTopBarLayout>
    ),
  },
]
