import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  TableCellProps,
} from '@mui/material'
import { Plagiarism, MoreVert } from '@mui/icons-material'

export interface Column<T> {
  key: keyof T
  header: string
  render?: (row: T) => React.ReactNode
  width?: number | string
  wrap?: boolean
  align?: 'left' | 'right' | 'center'
}

export interface RowAction<T> {
  label: string
  icon?: React.ReactNode
  onClick: (row: T) => void
}

interface EmptyStateProps {
  title?: string
  description?: string
  action?: React.ReactNode
}

export interface BaseTableProps<T extends Record<string, any>> {
  data: Array<T> | undefined
  columns: Array<Column<T>>
  idField?: keyof T
  onRowClick?: (row: T) => void
  checkable?: boolean
  selectedRows?: Array<T>
  onSelectRows?: (rows: Array<T>) => void
  isLoading?: boolean
  loadingRowCount?: number
  emptyState?: EmptyStateProps
  footer?: React.ReactNode
  rowActions?: Array<RowAction<T>> | ((row: T) => Array<RowAction<T>>)
}

const LoadingTable = ({
  columnCount,
  rowCount,
}: {
  columnCount: number
  rowCount: number
}) => {
  return (
    <TableBody
      sx={(theme) => ({
        background: theme.palette.background.tertiary,
      })}
    >
      {Array.from({ length: rowCount }, (_, index) => (
        <TableRow key={index}>
          {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
          {Array.from({ length: columnCount }, (_, cellIndex) => (
            <TableCell key={cellIndex} sx={{ minHeight: '43px' }} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}

function BaseTable<T extends Record<string, any>>({
  data,
  columns,
  idField = 'id' as keyof T,
  onRowClick,
  checkable = false,
  selectedRows = [],
  onSelectRows,
  isLoading = false,
  loadingRowCount = 10,
  emptyState,
  footer,
  rowActions,
}: BaseTableProps<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [actionRow, setActionRow] = useState<T | null>(null)
  const open = Boolean(anchorEl)

  const getRowId = (row: T): string => {
    const id = row[idField]
    return id !== undefined ? String(id) : JSON.stringify(row)
  }

  const getRowActions = (row: T) => {
    if (!rowActions) {
      return []
    }
    return Array.isArray(rowActions) ? rowActions : rowActions(row)
  }

  const handleActionClick = (event: React.MouseEvent<HTMLElement>, row: T) => {
    event.stopPropagation()
    setActionRow(row)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setActionRow(null)
  }

  const handleMenuItemClick = (action: RowAction<T>) => {
    if (actionRow) {
      action.onClick(actionRow)
    }
    handleClose()
  }

  const handleSelectAllClick = () => {
    if (selectedRows.length === 0 && data) {
      onSelectRows?.(data)
    } else {
      onSelectRows?.([])
    }
  }

  const handleRowSelect = (row: T) => {
    const selectedIndex = selectedRows.findIndex(
      (selectedRow) => getRowId(selectedRow) === getRowId(row)
    )
    let newSelectedRows: Array<T> = []

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row]
    } else if (selectedIndex === 0) {
      newSelectedRows = selectedRows.slice(1)
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelectedRows = selectedRows.slice(0, -1)
    } else if (selectedIndex > 0) {
      newSelectedRows = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ]
    }

    onSelectRows?.(newSelectedRows)
  }

  const isSelected = (row: T) =>
    selectedRows.findIndex(
      (selectedRow) => getRowId(selectedRow) === getRowId(row)
    ) !== -1

  if (!isLoading && (!data || data.length === 0)) {
    return (
      <Paper
        sx={{
          width: '100%',
          height: '400px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          p: 3,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Plagiarism
            sx={{
              fontSize: 80,
              color: 'text.secondary',
            }}
          />
        </Box>
        <Typography variant="h6" color="text.secondary" sx={{ mb: 1 }}>
          {emptyState?.title ?? 'No data available'}
        </Typography>
        <Typography variant="body2" color="text.disabled" sx={{ mb: 2 }}>
          {emptyState?.description ?? 'There are no items to display.'}
        </Typography>
        {emptyState?.action && <Box>{emptyState.action}</Box>}
      </Paper>
    )
  }

  const getCellStyle = (column: Column<T>): TableCellProps['sx'] => ({
    width: column.width,
    ...(!column.wrap && {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
  })

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead
            sx={(theme) => ({
              '& .MuiTableCell-head': {
                backgroundColor: theme.palette.background.paper,
                fontWeight: '700 !important',
              },
            })}
          >
            <TableRow>
              {checkable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < (data?.length ?? 0)
                    }
                    checked={
                      data?.length === selectedRows.length && data.length > 0
                    }
                    onChange={handleSelectAllClick}
                    sx={(theme) => ({
                      color: theme.palette.grey[700],
                    })}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={String(column.key)}
                  sx={getCellStyle(column)}
                  align={column.align ?? 'left'}
                >
                  {column.header}
                </TableCell>
              ))}
              {rowActions && <TableCell padding="checkbox" />}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <LoadingTable
              columnCount={columns.length + (rowActions ? 1 : 0)}
              rowCount={loadingRowCount}
            />
          ) : (
            <TableBody
              sx={(theme) => ({
                '& .MuiTableRow-root:hover': {
                  backgroundColor: theme.palette.background.hover,
                },
                '& .MuiTableRow-root:hover .MuiTableCell-body': {
                  borderColor: theme.palette.background.hover,
                },
              })}
            >
              {data?.map((row) => (
                <TableRow
                  key={getRowId(row)}
                  hover
                  onClick={() => onRowClick?.(row)}
                  sx={(theme) => ({
                    background: theme.palette.background.tertiary,
                    cursor: onRowClick ? 'pointer' : 'default',
                  })}
                >
                  {checkable && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(row)}
                        onChange={(e) => {
                          e.stopPropagation()
                          handleRowSelect(row)
                        }}
                        sx={(theme) => ({
                          color: theme.palette.grey[700],
                        })}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={String(column.key)}
                      align={column.align ?? 'left'}
                      sx={getCellStyle(column)}
                    >
                      {column.render
                        ? column.render(row)
                        : (row[column.key] as React.ReactNode)}
                    </TableCell>
                  ))}
                  {rowActions && (
                    <TableCell padding="checkbox">
                      <IconButton
                        size="small"
                        onClick={(e) => handleActionClick(e, row)}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {footer}
      {isLoading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={10}
          sx={{ backgroundColor: 'transparent' }}
        >
          <CircularProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.10))',
            },
          },
        }}
      >
        {actionRow &&
          getRowActions(actionRow).map((action, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(action)}>
              {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
              {action.label}
            </MenuItem>
          ))}
      </Menu>
    </Paper>
  )
}

export default BaseTable
