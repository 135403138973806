enum ErrorValidationConstants {
  REQUIRED = 'The field is required',
  EMAIL = 'The email is incorrect',
  PASSWORD_MISMATCH = 'The password is not match',
  MIN_LENGTH = 'The min length error',
  MAX_LENGTH = 'The max length error',
  ORGANIZATION_FIRST_LETTER = 'The name should start from the letter',
  ORGANIZATION_SPACE = 'The name must not contain spaces',
  TAG_TEMPLATE = 'The field should be in a format "[key]:[value]"',
  HAS_LOWER_CASE = 'At least 1 lower case letter is required',
  HAS_UPPER_CASE = 'At least 1 upper case letter is required',
  HAS_NUMBER = 'At least 1 number is required',
  ACCOUNT_ID = 'Account id must contain 12 numbers',
  VAULT_NAME = 'The vault name should start only from the letter or number. ' +
    'Also must contain min of 3 characters: [lower case letters], [numbers] or [dash]. ' +
    'Don`t contain space and special character: ~`!@#$%^&*()-_+={}[]|\\;:"<>,./?. ' +
    '',
  MAX_VAULTS_NUMBER = 'You have reached the vaults limit for the current region. ' +
    'No more than 7 vaults allowed for one region',
}

export default ErrorValidationConstants
