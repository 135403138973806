import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { OvaStatisticsClient } from '@lib/clients'
import { GetAssetsScannedStatisticsResponse } from '@lib/clients/ova-statistics/types'
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'

type AssetsScanned = {
  totalServers: number
  infectedServers: number
  cleanServers: number
}

const INITIAL_ASSETS_SCANNED_STATE: AssetsScanned = {
  totalServers: 0,
  infectedServers: 0,
  cleanServers: 0,
}

const ovaStatisticsClient = new OvaStatisticsClient()

function transformGetAssetsScannedStatisticsResponse(
  response: GetAssetsScannedStatisticsResponse
): AssetsScanned {
  return {
    totalServers: response.totalAssets,
    infectedServers: response.infectedAssets,
    cleanServers: response.cleanAssets,
  }
}

export function useFetchAssetsScanned() {
  const ovaAccountsList = useGetOvaAccountList()
  const ovaBackupProvidersList = useGetOvaBackupProvidersList()
  const [isLoading, setIsLoading] = useState(true)
  const [assetsScanned, setAssetsScanned] = useState<AssetsScanned>(
    INITIAL_ASSETS_SCANNED_STATE
  )

  useEffect(() => {
    let isMounted = true

    const fetchAssetsScanned = async () => {
      try {
        setIsLoading(true)

        const response = await ovaStatisticsClient.getAssetsScannedStatistics({
          ovaAccountIds: ovaAccountsList,
          backupProviders: ovaBackupProvidersList,
        })

        if (isMounted) {
          setAssetsScanned(
            transformGetAssetsScannedStatisticsResponse(response)
          )
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'ovaStatisticsClient.getThreatsStatistics: failed to fetch threats count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchAssetsScanned()

    return () => {
      isMounted = false
      setAssetsScanned(INITIAL_ASSETS_SCANNED_STATE)
      setIsLoading(false)
    }
  }, [ovaAccountsList, ovaBackupProvidersList])

  return {
    isLoading,
    totalServers: assetsScanned.totalServers,
    infectedServers: assetsScanned.infectedServers,
    cleanServers: assetsScanned.cleanServers,
  }
}
