/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetsFilter,
  AwsBackupCoverageRequest,
  AwsFileScansStatsRequest,
  AwsScanCoverageRequest,
  AwsStatisticsPromiseClient,
  AwsStorageMisconfigurationRequest,
  AwsStorageMisconfigurationResponse,
  AwsThreatExposureRequest,
  AwsThreatStatsRequest,
  AwsThreatStatsResponse,
  TagsFilter,
} from '../grpc'
import {
  AwsBackupCoverageResponse,
  AwsFileScansStatsResponse,
  AwsRetentionStats,
  AwsRpoAndRetentionStatsResponse,
  AwsRpoStats,
  AwsScanCoverageResponse,
  AwsStatsRequestParams,
  AwsStorageMisconfigurationsStatsResponse,
  AwsThreatExposureStatsResponse,
  AwsThreatStats,
  Percentile,
  ResponsePage,
  SliceCriteriaTag,
  SliceCriteriaTagsCombinationOperator,
} from '../models/aws-stats'
import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'
import GetAwsRpoAndRetentionStatsPb from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/aws_statistics/get_rpo_and_retention_stats_pb'
import * as google_protobuf_duration_pb from 'google-protobuf/google/protobuf/duration_pb'
import { SnapshotVulnerabilityKind as SnapshotVulnerabilityKindPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/aws_statistics/snapshot_vulnerability_pb'
import { VolumeVulnerabilityKind as VolumeVulnerabilityKindPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/aws_statistics/volume_vulnerability_pb'
import {
  SnapshotVulnerabilityKind,
  VolumeVulnerabilityKind,
} from 'blues-corejs/dist/models/vulnerability'

export class AwsStatsClient extends GrpcClient<AwsStatisticsPromiseClient> {
  #statsClient: AwsStatisticsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#statsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'AwsStatistics'
  }

  protected initClient(hostName = ''): AwsStatisticsPromiseClient {
    return new AwsStatisticsPromiseClient(hostName, null, null)
  }

  #convertTagsToGrpc(tagsList: Array<SliceCriteriaTag>) {
    const filtersTagsList = new TagsFilter.TagsList()
    const filtersTags = tagsList.map((tag) => {
      const tagFilter = new TagsFilter.Tag()
      tagFilter.setKey(tag.key)
      if (tag?.value) {
        tagFilter.setValue(tag.value)
      }
      return tagFilter
    })

    filtersTagsList.setTagsList(filtersTags)

    return filtersTagsList
  }

  #buildFilter(filters: AwsStatsRequestParams) {
    const filter = new AssetsFilter()
    const { accountIds, regions, includeTags, excludeTags } = filters

    if (accountIds) {
      filter.setAccountsList(accountIds)
    }
    if (regions) {
      filter.setRegionsList(regions)
    }

    if (includeTags) {
      const tagsFilter = new TagsFilter()
      const tagsList = this.#convertTagsToGrpc(includeTags.tags)
      if (includeTags.operator === SliceCriteriaTagsCombinationOperator.AND) {
        tagsFilter.setAnd(tagsList)
      }
      if (includeTags.operator === SliceCriteriaTagsCombinationOperator.OR) {
        tagsFilter.setOr(tagsList)
      }

      filter.setIncludeTags(tagsFilter)
    }

    if (excludeTags) {
      const tagsFilter = new TagsFilter()
      const tagsList = this.#convertTagsToGrpc(excludeTags.tags)
      if (excludeTags.operator === SliceCriteriaTagsCombinationOperator.AND) {
        tagsFilter.setAnd(tagsList)
      }
      if (excludeTags.operator === SliceCriteriaTagsCombinationOperator.OR) {
        tagsFilter.setOr(tagsList)
      }
      filter.setIncludeTags(tagsFilter)
    }

    return filter
  }

  #convertPageToGo(page: AwsThreatStatsResponse.Page): ResponsePage {
    const pages = {
      [AwsThreatStatsResponse.Page.EC2INSTANCES]: ResponsePage.EC2INSTANCES,
      [AwsThreatStatsResponse.Page.EBSVOLUMES]: ResponsePage.EBSVOLUMES,
      [AwsThreatStatsResponse.Page.S3BUCKETS]: ResponsePage.S3BUCKETS,
      [AwsThreatStatsResponse.Page.EFS]: ResponsePage.EFS,
    }

    return pages[page]
  }

  async getThreatStats(
    filters: AwsStatsRequestParams
  ): Promise<AwsThreatStats> {
    const request = new AwsThreatStatsRequest()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getThreatsStats(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getThreatsStats',
        }
      )
    ).toObject()

    return {
      malwareThreatsCount: response.malwareThreatsCount,
      ransomwareThreatsCount: response.ransomwareThreatsCount,
      corruptedFilesystemsCount: response.corruptedFilesystemsCount,
      malwarePageToGo: this.#convertPageToGo(response.malwarePageToGo),
      ransomwarePageToGo: this.#convertPageToGo(response.ransomwarePageToGo),
      corruptedFilesystemsPageToGo: this.#convertPageToGo(
        response.corruptedFilesystemsPageToGo
      ),
    }
  }

  async getThreatExposureStats(
    filters: AwsStatsRequestParams
  ): Promise<AwsThreatExposureStatsResponse> {
    const request = new AwsThreatExposureRequest()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getThreatExposure(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getThreatExposureStats',
        }
      )
    ).toObject()

    return {
      totalAssetsCount: response.totalAssetsCount,
      assetsWithThreatsCount: response.assetsWithThreatsCount,
      regionsWithThreatsCount: response.regionsWithThreatsCount,
      accountsWithThreatsCount: response.accountsWithThreatsCount,
    }
  }

  async getFileScansStats(
    filters: AwsStatsRequestParams
  ): Promise<AwsFileScansStatsResponse> {
    const request = new AwsFileScansStatsRequest()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getFileScansStats(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getFileScansStats',
        }
      )
    ).toObject()

    return {
      cleanFiles: response.cleanFiles,
      infectedFiles: response.infectedFiles,
    }
  }

  async getScanCoverage(
    filters: AwsStatsRequestParams
  ): Promise<AwsScanCoverageResponse> {
    const request = new AwsScanCoverageRequest()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getScanCoverage(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getScanCoverage',
        }
      )
    ).toObject()

    return {
      ec2: response.ec2,
      ebs: response.ebs,
      s3: response.s3,
      efs: response.efs,
    }
  }

  async getBackupCoverage(
    filters: AwsStatsRequestParams
  ): Promise<AwsBackupCoverageResponse> {
    const request = new AwsBackupCoverageRequest()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getBackupCoverage(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getBackupCoverage',
        }
      )
    ).toObject()

    return {
      ec2: response.ec2,
      ebs: response.ebs,
    }
  }

  async getRpoAndRetentionStats(
    filters: AwsStatsRequestParams
  ): Promise<AwsRpoAndRetentionStatsResponse> {
    const request = new GetAwsRpoAndRetentionStatsPb.Request()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getRpoAndRetentionStats(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getRpoAndRetentionStats',
        }
      )
    ).toObject()

    return {
      retentionStats: this.#transformRetentionStatsResponse(
        response?.retentionStats
      ),
      rpoStats: this.#transformRpoStatsResponse(response?.rpoStats),
    }
  }

  #transformRetentionStatsResponse(
    retentionStats?: GetAwsRpoAndRetentionStatsPb.RetentionStats.AsObject
  ): AwsRetentionStats | undefined {
    if (!retentionStats) {
      return undefined
    }

    return {
      averageRetention: this.#transformDurationToMilliseconds(
        retentionStats.averageRetention
      ),
      bottomQuartile: this.#transformDurationToMilliseconds(
        retentionStats.bottomQuartile
      ),
      topQuartile: this.#transformDurationToMilliseconds(
        retentionStats.topQuartile
      ),
      rpoStatsQuantilesList: retentionStats.rpoStatsQuantilesList.map(
        (quantile) => ({
          age: this.#transformDurationToMilliseconds(quantile.age),
          percentile: this.#convertRetentionPercentile(quantile.percentile),
        })
      ),
    }
  }

  #transformDurationToMilliseconds(
    duration?: google_protobuf_duration_pb.Duration.AsObject
  ): number | undefined {
    return duration ? duration.seconds * 1000 : undefined
  }

  #convertRetentionPercentile(
    percentile: GetAwsRpoAndRetentionStatsPb.RetentionStats.RetentionStatsQuantile.Percentile
  ): Percentile {
    const percentiles = {
      [GetAwsRpoAndRetentionStatsPb.RetentionStats.RetentionStatsQuantile
        .Percentile.PERCENT_20]: Percentile.PERCENT_20,
      [GetAwsRpoAndRetentionStatsPb.RetentionStats.RetentionStatsQuantile
        .Percentile.PERCENT_40]: Percentile.PERCENT_40,
      [GetAwsRpoAndRetentionStatsPb.RetentionStats.RetentionStatsQuantile
        .Percentile.PERCENT_60]: Percentile.PERCENT_60,
      [GetAwsRpoAndRetentionStatsPb.RetentionStats.RetentionStatsQuantile
        .Percentile.PERCENT_80]: Percentile.PERCENT_80,
      [GetAwsRpoAndRetentionStatsPb.RetentionStats.RetentionStatsQuantile
        .Percentile.PERCENT_100]: Percentile.PERCENT_100,
    }

    return percentiles[percentile]
  }

  #transformRpoStatsResponse(
    rpoStats?: GetAwsRpoAndRetentionStatsPb.RpoStats.AsObject
  ): AwsRpoStats | undefined {
    if (!rpoStats) {
      return undefined
    }

    return {
      highestRpo: this.#transformDurationToMilliseconds(rpoStats?.highestRpo),
      lowestRpo: this.#transformDurationToMilliseconds(rpoStats?.lowestRpo),
      backupsOutOfSchedule: rpoStats.backupsOutOfSchedule,
      rpoStatsQuantilesList: rpoStats.rpoStatsQuantilesList.map((quantile) => ({
        age: this.#transformDurationToMilliseconds(quantile.age),
        percentile: this.#convertRpoPercentile(quantile.percentile),
      })),
    }
  }

  #convertRpoPercentile(
    percentile: GetAwsRpoAndRetentionStatsPb.RpoStats.RpoStatsQuantile.Percentile
  ): Percentile {
    const percentiles = {
      [GetAwsRpoAndRetentionStatsPb.RpoStats.RpoStatsQuantile.Percentile
        .PERCENT_20]: Percentile.PERCENT_20,
      [GetAwsRpoAndRetentionStatsPb.RpoStats.RpoStatsQuantile.Percentile
        .PERCENT_40]: Percentile.PERCENT_40,
      [GetAwsRpoAndRetentionStatsPb.RpoStats.RpoStatsQuantile.Percentile
        .PERCENT_60]: Percentile.PERCENT_60,
      [GetAwsRpoAndRetentionStatsPb.RpoStats.RpoStatsQuantile.Percentile
        .PERCENT_80]: Percentile.PERCENT_80,
      [GetAwsRpoAndRetentionStatsPb.RpoStats.RpoStatsQuantile.Percentile
        .PERCENT_100]: Percentile.PERCENT_100,
    }

    return percentiles[percentile]
  }

  async getStorageMisconfigurations(
    filters: AwsStatsRequestParams
  ): Promise<AwsStorageMisconfigurationsStatsResponse> {
    const request = new AwsStorageMisconfigurationRequest()
    const filter = this.#buildFilter(filters)
    request.setFilter(filter)

    const response = (
      await this.callGrpcService(
        () =>
          this.#statsClient.getStorageMisconfiguration(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'AwsStatisticsPromiseClient/getStorageMisconfiguration',
        }
      )
    ).toObject()

    return this.#transformStorageMisconfigurationsResponse(response)
  }

  #transformStorageMisconfigurationsResponse(
    response: AwsStorageMisconfigurationResponse.AsObject
  ): AwsStorageMisconfigurationsStatsResponse {
    const snapshotVulnerabilitiesList =
      response.snapshotVulnerabilitiesList.map((vulnerability) => ({
        kind: this.#convertSnapshotVulnerabilityKind(vulnerability.kind),
        total: vulnerability.total,
      }))

    const volumeVulnerabilitiesList = response.volumeVulnerabilitiesList.map(
      (vulnerability) => ({
        kind: this.#convertVolumeVulnerabilityKind(vulnerability.kind),
        total: vulnerability.total,
      })
    )

    return {
      snapshotVulnerabilitiesList: snapshotVulnerabilitiesList,
      volumeVulnerabilitiesList: volumeVulnerabilitiesList,
    }
  }

  #convertVolumeVulnerabilityKind(
    kind: VolumeVulnerabilityKindPb
  ): VolumeVulnerabilityKind {
    const kinds = {
      [VolumeVulnerabilityKindPb.UNATTACHED]:
        VolumeVulnerabilityKind.UNATTACHED,
      [VolumeVulnerabilityKindPb.UNENCRYPTED]:
        VolumeVulnerabilityKind.UNENCRYPTED,
      [VolumeVulnerabilityKindPb.NOT_USING_CMK]:
        VolumeVulnerabilityKind.NOT_USING_CMK,
      [VolumeVulnerabilityKindPb.NO_BACKUPS]:
        VolumeVulnerabilityKind.NO_BACKUPS,
      [VolumeVulnerabilityKindPb.SNAPSHOTS_OLDER_7DAYS]:
        VolumeVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS,
      [VolumeVulnerabilityKindPb.NOT_CYBER_SCANNED]:
        VolumeVulnerabilityKind.NOT_CYBER_SCANNED,
    }

    return kinds[kind]
  }

  #convertSnapshotVulnerabilityKind(
    kind: SnapshotVulnerabilityKindPb
  ): SnapshotVulnerabilityKind {
    const kinds = {
      [SnapshotVulnerabilityKindPb.UNENCRYPTED]:
        SnapshotVulnerabilityKind.UNENCRYPTED,
      [SnapshotVulnerabilityKindPb.PUBLIC_PERMISSIONS]:
        SnapshotVulnerabilityKind.PUBLIC_PERMISSIONS,
    }

    return kinds[kind]
  }
}
