/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import {
  EBS,
  EC2,
  GenericHost,
  Backup,
  Asset,
  OvaServer,
} from 'blues-corejs/dist'
import { ListBackupsForAssetClient } from '@lib/clients'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import { ListBackupsForAssetFilter } from '@lib/clients/backups'

const ERROR_MESSAGE =
  'useFetchLastBackupForAsset: Failed to fetch last backup for asset with id:'

const backupsClient = new ListBackupsForAssetClient()

function getLastBackupByTimestamp(backups: Array<Backup>) {
  return backups.reduce((previousBackup, currentBackup) =>
    previousBackup.timestamp > currentBackup.timestamp
      ? previousBackup
      : currentBackup
  )
}

async function fetchLastBackup(asset: Asset) {
  if (
    !(asset instanceof GenericHost) &&
    !(asset instanceof EBS) &&
    !(asset instanceof EC2) &&
    !(asset instanceof OvaServer)
  ) {
    return
  }

  try {
    const backupsListForAsset = await backupsClient.listBackupsForAsset(
      new ListBackupsForAssetFilter({
        assetIdList: [asset.id],
      })
    )

    const backupsList = [
      ...backupsListForAsset.awsbRpsList,
      ...backupsListForAsset.elastioRpsList,
      ...backupsListForAsset.ebsSnapshotsList,
      ...backupsListForAsset.ovaBackupList,
    ]

    if (backupsList.length === 0) {
      return
    }

    const lastBackupForAsset = getLastBackupByTimestamp(backupsList)

    return lastBackupForAsset
  } catch (error) {
    console.error(error)
    sentryReThrowCatchHandler(`${ERROR_MESSAGE} ${asset.id}`)
    throw new Error(`${ERROR_MESSAGE} ${asset.id}`)
  }
}

export function useFetchLastBackupForAsset(asset: Asset) {
  const [lastBackup, setLastBackup] = useState<Backup | undefined>(undefined)
  const [isBackupLoading, setIsBackupLoading] = useState(false)

  useEffect(() => {
    try {
      setIsBackupLoading(true)
      fetchLastBackup(asset).then((backup) => {
        setLastBackup(backup)
      })
    } catch (error) {
      console.error(
        `useFetchLastBackupForAsset: Cannot fetch last backup for asset: ${asset.id}`
      )
    } finally {
      setIsBackupLoading(false)
    }
  }, [asset.id])

  return {
    lastBackupForAsset: lastBackup,
    isBackupLoading,
  }
}
