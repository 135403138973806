/* eslint-disable import/no-extraneous-dependencies */
import { pechkinClient, ScheduleAsset } from '@lib/clients/pechkin'
import { RowSelectionState } from '@tanstack/table-core'
import { InventoryInstanceOutput } from 'blues-corejs/dist/use_cases/list_inventory_instances/types'
import { useMemo, useState } from 'react'
import { ScheduleCcJobsUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledCcJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import { ScheduledCcJobsClient } from '@features/scheduled-jobs-monitoring/clients'
import ToastHelper from '@lib/helpers/toast.helper'
import { InventoryVolumeOutput } from 'blues-corejs/dist/use_cases/list_inventory_volumes/types'
import { InventoryS3BucketOutput } from 'blues-corejs/dist/use_cases/list_inventory_s3_buckets/types'
import { EfsInventoryOutput } from 'blues-corejs/dist/use_cases/list_efs_inventory/types'

const scheduleCcJobsUseCase = new ScheduleCcJobsUseCase(
  new ScheduledCcJobsRepository(createLogger()),
  new ScheduledCcJobsClient(),
  ToastHelper
)

interface Props {
  assetsCoreModel: Array<ScheduleAsset>
  assets: Array<
    | InventoryInstanceOutput
    | InventoryVolumeOutput
    | InventoryS3BucketOutput
    | EfsInventoryOutput
  >
}

/**
 @param assetsCoreModel - List of assets from the API
 @param assets - List of assets from the use-case
 @description This file is used to handle on-demand scan logic in EC2, EBS, S3 and EFS inventory table. This hook is used to call the API to start a scan on the selected EC2, EBS, S3 or EFS.
 @description hook takes two parameters, just to ensure that the data is consistent between the API and the use-case
 */
export function useOnDemandScan({ assetsCoreModel, assets }: Props) {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})

  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)

  const coreAssetsModels = useMemo(() => {
    const keys = Object.keys(selectedRows).map(Number)

    return keys.reduce((acc: Array<ScheduleAsset>, key) => {
      const selectedAsset = assets[key]

      const assetCoreModel = assetsCoreModel.find(
        (asset) => asset.awsId === selectedAsset?.awsId
      )

      if (!assetCoreModel) {
        return acc
      }

      acc.push(assetCoreModel)
      return acc
    }, [])
  }, [Object.keys(selectedRows).length])

  async function handleDemandButtonClick() {
    try {
      setIsRequestLoading(true)
      const jobIds = await pechkinClient.scheduleAssetScans(coreAssetsModels)
      await scheduleCcJobsUseCase.execute(jobIds)
      setSelectedRows({})
    } catch (error) {
      ToastHelper.error('Failed to schedule scans')
      console.error('Error starting on-demand scan:', error)
    } finally {
      setIsRequestLoading(false)
    }
  }

  return {
    handleDemandButtonClick,
    isScheduleScanLoading: isRequestLoading,
    rowsSelection: {
      selectedRows,
      setSelectedRows,
    },
  }
}
