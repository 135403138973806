/* eslint-disable import/no-extraneous-dependencies */
import { BaseRoutes } from '../base'
import { RiskLevel } from 'blues-corejs/dist'
import { SnapshotVulnerabilityKind } from 'blues-corejs/dist/models/vulnerability'
import { EbsVulnerabilityKind } from 'blues-corejs/dist/models'
import {
  ASSET_ID_QUERY_KEY,
  CLOUD_PROVIDER_ASSET_ID_QUERY_KEY,
  RP_ID_QUERY,
  T_QUERY,
} from '@lib/constants'

type AssetsThreatsParams = Readonly<
  Partial<
    Record<
      'ransomware' | 'malware' | 'filesystem',
      typeof DashboardRoutes.Params.ALL
    >
  >
>
type AbcSortType = 'asc' | 'desc'
type RPOFilterParam = 'backupsOnSchedule' | 'noBackupsOnSchedule'

export type InventoryTableParams = {
  rpo?: AbcSortType
  filter?: RPOFilterParam
  retention?: AbcSortType
}

type AssetsVulnerabilitiesParams = {
  risk?: RiskLevel
  vulnerability?: SnapshotVulnerabilityKind | EbsVulnerabilityKind
}

enum RouteFragments {
  INVENTORY_TABLES = 'inventory-tables',
  ASSETS_THREATS = 'assets-threats',
  RESTORE_INSTANCE = 'restore-instance',
  RESTORE_VOLUME = 'restore-volume',
  ASSETS_VULNERABILITIES = 'assets-vulnerabilities',
  INSTANCE = 'instance',
  VOLUME = 'volume',
  DYNAMIC_ID = ':id',
}

export enum DashboardTab {
  EC2 = 0,
  EBS = 1,
  S3 = 2,
  EFS = 3,
  OVA_SERVER = 4,
  GENERIC_HOSTS = 5,
}

export enum VulnerabilitiesTabs {
  INSTANCES = 0,
  VOLUMES = 1,
  SNAPSHOTS = 2,
}

export class DashboardRoutes extends BaseRoutes {
  public static readonly Params = {
    ALL: 'all' as const,
  }

  public static get root(): string {
    return this.#ROUTE_DASHBOARD
  }

  public static get defaultRoot(): string {
    return this.#ROUTE_DASHBOARD_DEFAULT
  }

  public static get ovaDashboard(): string {
    return this.#ROUTE_OVA_DASHBOARD
  }

  public static get mountRecoveryPoint(): string {
    return this.buildRoute(this.#ROUTE_MOUNT_RECOVERY_POINT)
  }

  public static get inventoryTables(): string {
    return `${this.#ROUTE_DASHBOARD}/${RouteFragments.INVENTORY_TABLES}`
  }

  public static inventoryTablesWithParams(
    tabId: string,
    params: InventoryTableParams
  ): string {
    const basePath = this.inventoryTables
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
        ...params,
      },
    })
  }

  public static get ec2Table(): string {
    return this.inventoryTableRoute(DashboardTab.EC2)
  }

  public static get ebsTable(): string {
    return this.inventoryTableRoute(DashboardTab.EBS)
  }

  public static get s3Table(): string {
    return this.inventoryTableRoute(DashboardTab.S3)
  }

  public static get efsTable(): string {
    return this.inventoryTableRoute(DashboardTab.EFS)
  }

  public static get ovaServerTable(): string {
    return this.inventoryTableRoute(DashboardTab.OVA_SERVER)
  }

  public static get genericHostsTable(): string {
    return this.inventoryTableRoute(DashboardTab.GENERIC_HOSTS)
  }

  public static get assetsThreats(): string {
    return `${this.#ROUTE_DASHBOARD}/${RouteFragments.ASSETS_THREATS}`
  }

  public static get restoreInstance() {
    return this.buildRoute(
      RouteFragments.RESTORE_INSTANCE,
      RouteFragments.DYNAMIC_ID
    )
  }

  public static get restoreVolume() {
    return this.buildRoute(
      RouteFragments.RESTORE_VOLUME,
      RouteFragments.DYNAMIC_ID
    )
  }

  public static get instanceMountRecoveryPoint() {
    return this.buildRoute(
      this.#ROUTE_MOUNT_RECOVERY_POINT,
      RouteFragments.INSTANCE
    )
  }

  public static get volumeMountRecoveryPoint() {
    return this.buildRoute(
      this.#ROUTE_MOUNT_RECOVERY_POINT,
      RouteFragments.VOLUME
    )
  }

  public static get assetsVulnerabilities() {
    return this.buildRoute(RouteFragments.ASSETS_VULNERABILITIES)
  }

  public static assetsVulnerabilitiesTableRoute(tabId: string): string {
    const basePath = this.assetsVulnerabilities
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
      },
    })
  }

  public static assetsVulnerabilitiesWithParams(
    tabId: VulnerabilitiesTabs,
    params: AssetsVulnerabilitiesParams
  ): string {
    const basePath = this.assetsVulnerabilities
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
        ...params,
      },
    })
  }

  public static assetsThreatsTableRoute(tabId: DashboardTab): string {
    const basePath = this.assetsThreats
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
      },
    })
  }

  public static assetsThreatsWithParams(
    tabId: string,
    params: AssetsThreatsParams
  ): string {
    const basePath = this.assetsThreats
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
        ...params,
      },
    })
  }

  private static inventoryTableRoute(tabId: DashboardTab): string {
    const basePath = this.inventoryTables
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
      },
    })
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_DASHBOARD}/${fragments.join('/')}`
  }

  public static rootTab(tabId: string): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
      },
    })
  }

  public static restoreInstanceWithParams(
    rpId: string,
    assetId: string
  ): string {
    const basePath = this.buildRoute(
      RouteFragments.RESTORE_INSTANCE,
      this.base64Encode(rpId)
    )
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.ASSET_ID]: this.base64Encode(assetId),
      },
    })
  }

  public static restoreVolumeWithParams(rpId: string, assetId: string): string {
    const basePath = this.buildRoute(
      RouteFragments.RESTORE_VOLUME,
      this.base64Encode(rpId)
    )
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.ASSET_ID]: this.base64Encode(assetId),
      },
    })
  }

  public static mountInstanceWithParams(rpId: string, assetId: string): string {
    const basePath = this.instanceMountRecoveryPoint
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.CLOUD_PROVIDER_ASSET_ID]: this.base64Encode(assetId),
        [this.#QUERY_KEYS.RP_ID]: rpId,
      },
    })
  }

  public static mountVolumeWithParams(rpId: string, assetId: string): string {
    const basePath = this.volumeMountRecoveryPoint
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.CLOUD_PROVIDER_ASSET_ID]: this.base64Encode(assetId),
        [this.#QUERY_KEYS.RP_ID]: rpId,
      },
    })
  }

  static readonly #ROUTE_DASHBOARD_DEFAULT = '/'

  static readonly #ROUTE_DASHBOARD = '/dashboard'

  static readonly #ROUTE_OVA_DASHBOARD = '/ova-dashboard'

  static readonly #ROUTE_MOUNT_RECOVERY_POINT = '/mount-recovery-point'

  static readonly #QUERY_KEYS = {
    TAB: T_QUERY,
    ASSET_ID: ASSET_ID_QUERY_KEY,
    RP_ID: RP_ID_QUERY,
    CLOUD_PROVIDER_ASSET_ID: CLOUD_PROVIDER_ASSET_ID_QUERY_KEY,
  }
}
