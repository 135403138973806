import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts'
import { SettingsRoutes } from './settings'
import { SettingsComplex } from '@components/components-complex'

export const settingsRoutes: Array<PathRouteProps> = [
  {
    path: SettingsRoutes.root,
    element: (
      <MainTopBarLayout title="Tenant Settings">
        <SettingsComplex />
      </MainTopBarLayout>
    ),
  },
]
