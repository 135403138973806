/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import { VirtualTable } from '@components/table'
import { getThreats } from '@store/selectors/list-live-assets.selector'
import { initialPrioritySortForThreats } from './helpers'
import { useBreadcrumbForActiveThreatsPage } from './use-set-breadcrumbs-for-active-threats-page'
import { useThreatsMappedByAsset } from './use-threats-mapped-by-asset'
import { getActiveThreatsColumnDefinitions } from './active-threats-column-definitions'
import styles from './active-threats.module.css'
import { Asset } from 'blues-corejs/dist'
import { ExportAsCsv } from '@features/common'
import {
  prepareActiveThreatDataToCsvFormat,
  ACTIVE_THREATS_CSV_TABLE_HEADERS,
} from './prepare-active-threats-to-csv'

// We need to display 2 different tables: for AWS and OVA assets
// So, basically, the tables are the same, but the assets are different
// To prevent duplication, this props will be used to filter the assets
interface Props {
  assetFilterCriteria: (asset: Asset) => boolean
}

export function ActiveThreats({ assetFilterCriteria }: Props) {
  useBreadcrumbForActiveThreatsPage()

  const threatList = useSelector(getThreats)

  const { assetByThreat, isAssetLoading } = useThreatsMappedByAsset(threatList)

  const sortedThreatList = initialPrioritySortForThreats({
    threats: threatList,
    assetByThreat,
  })

  const filteredThreatsListByAssetsCriteria = useMemo(() => {
    return sortedThreatList.filter((threat) => {
      const asset = assetByThreat.get(threat)

      if (!asset) {
        return false
      }

      return assetFilterCriteria(asset)
    })
  }, [assetByThreat])

  return (
    <Stack padding="16px" gap={2}>
      <ExportAsCsv
        shouldShow={filteredThreatsListByAssetsCriteria.length > 0}
        headers={ACTIVE_THREATS_CSV_TABLE_HEADERS}
        filename="active-threats.csv"
        data={prepareActiveThreatDataToCsvFormat(
          filteredThreatsListByAssetsCriteria,
          assetByThreat
        )}
      />
      <VirtualTable
        className={styles['active-threats-table']}
        config={{
          data: filteredThreatsListByAssetsCriteria,
          columns: getActiveThreatsColumnDefinitions({
            assetByThreat,
          }),
          state: {
            isLoading: isAssetLoading,
          },
        }}
        emptyPlaceholderText="No active threats found"
      />
    </Stack>
  )
}
