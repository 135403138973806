import { GenerateReportForm } from './form'
import { SchedulesTable } from '../table'
import Box from '@mui/material/Box'
import React from 'react'
import { Title } from '@features/reports/schedules/shared'
import { useReportDataLength } from '@features/reports/schedules/use-data-store'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'

import ConfirmDeleteScheduledItemModal from './confirm-delete-scheduled-item-modal'

function GenerateReport() {
  const reportDataLength = useReportDataLength()
  const { maxAmountOfReportSchedules } = useCustomFlags()
  return (
    <Box>
      <Title>Create a Report</Title>
      <GenerateReportForm />
      <Title>
        Report Schedules ({reportDataLength} of {maxAmountOfReportSchedules}{' '}
        reports)
      </Title>
      <SchedulesTable />
      <ConfirmDeleteScheduledItemModal />
    </Box>
  )
}

export default GenerateReport
