import React, { PropsWithChildren } from 'react'
import SiteLoader from '../components/common/site-loader'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { useFetchAuth0SpaKeys } from '../hooks/auth/fetch-auth0-spa-keys'

const Auth0ProviderV2: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, spaAuthKeys } = useFetchAuth0SpaKeys()
  const navigate = useNavigate()

  if (isLoading) {
    return <SiteLoader />
  }

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo ?? window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={spaAuthKeys.domain}
      clientId={spaAuthKeys.clientId}
      cookieDomain={spaAuthKeys.cookieDomain}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderV2
