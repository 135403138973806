import React, { memo, useMemo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

export type ClosableTag = {
  name: string
  closable: boolean
}
interface Props {
  title: string
  tags: Array<string> | Array<ClosableTag>
  onRemoveTag: (tag: string) => void
  limit?: number
}

const MAX_LENGTH = 25

function isClosableTag(tag: any): tag is ClosableTag {
  return typeof tag === 'object' && 'name' in tag && 'closable' in tag
}

// TODO: make tags only with ClosableTag type
const renderTags = (
  tags: Array<string> | Array<ClosableTag>,
  onRemoveTag: (tag: string) => void
) => {
  if ((tags as Array<ClosableTag>).every((tag) => tag.closable === undefined)) {
    return (tags as Array<string>).map((tag, index) => (
      <div className="content" key={tag + index}>
        <LongTextTooltip text={tag} maxLength={MAX_LENGTH} placement="top" />
        <CloseIcon
          data-testid={`close-icon-${tag}`}
          htmlColor="#ED3F3F"
          fontSize="small"
          onClick={() => onRemoveTag(tag)}
        />
      </div>
    ))
  }

  return (tags as Array<ClosableTag>).map((tag, index) => {
    if (isClosableTag(tag)) {
      return (
        <div className="content" key={tag.name + index}>
          <LongTextTooltip
            text={tag.name}
            maxLength={MAX_LENGTH}
            placement="top"
          />
          {tag.closable && (
            <CloseIcon
              data-testid={`close-icon-${tag.name}`}
              htmlColor="#ED3F3F"
              fontSize="small"
              onClick={() => onRemoveTag(tag.name)}
            />
          )}
        </div>
      )
    }
  })
}

/** 
  @note Please note, that component gonna replace RansomwareMalwareBlock, because this component more flexible and can be used in other places. 
  @description  This component is used to display tags in a list.
  If the number of tags is more than the limit, then the number of tags that are not displayed is displayed.
  If the limit is less than or equal to zero, then all tags are displayed.
**/

function EditableTags({ title, tags, onRemoveTag, limit = 5 }: Props) {
  const isItemsMoreThanLimit = useMemo(() => {
    return tags.length > limit
  }, [tags, limit])

  const differenceBetweenLimitAndTagsLength = useMemo(() => {
    return tags.length - limit
  }, [limit, tags])

  const displayedTags = limit <= 0 ? tags : tags.slice(0, limit)

  if (tags.length === 0) {
    return null
  }

  return (
    <div className="wrap-1687945223320">
      <div className="title">{title}</div>
      {renderTags(displayedTags, onRemoveTag)}
      {isItemsMoreThanLimit && limit > 0 && (
        <div className="content limitBlock">
          <div className="more">+{differenceBetweenLimitAndTagsLength}</div>
        </div>
      )}
    </div>
  )
}

export default memo(EditableTags)
