import { AssetItemKind } from '@features/DashboardV4/common'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import React from 'react'
import SearchForm from '@forms/search/search.form'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import type { SelectChangeEvent } from '@mui/material/Select'

const assetItemKinds = [
  {
    label: 'File',
    value: AssetItemKind.KIND_FILE,
  },
  {
    label: 'Stream',
    value: AssetItemKind.KIND_STREAM,
  },
  {
    label: 'Volume',
    value: AssetItemKind.KIND_VOLUME,
  },
  {
    label: 'Disk',
    value: AssetItemKind.KIND_DISK,
  },
]

interface AssetItemFiltersProps {
  searchValue: string
  selectedAssetItemKinds: Array<AssetItemKind>
  onSearchChange: (searchValue: string) => void
  onKindChange: (kinds: Array<AssetItemKind>) => void
}

export function AssetItemFilters({
  searchValue,
  selectedAssetItemKinds,
  onSearchChange,
  onKindChange,
}: AssetItemFiltersProps) {
  function handleAssetItemKindsChange(
    event: SelectChangeEvent<Array<AssetItemKind>>
  ) {
    const { value } = event.target

    const kinds = (
      typeof value === 'string' ? value.split(',') : value
    ) as Array<AssetItemKind>

    onKindChange(kinds)
  }

  return (
    <Box display="flex" alignItems="center" gap="24px" marginY="16px">
      <Box width="30%">
        <SearchForm
          placeholder="Search by name"
          errorGroups={[ErrorGroupConstants.EMPTY]}
          defaultValues={{
            search: searchValue,
          }}
          variant="whiteFilterSearchBlackIcon"
          onSubmit={(formData: FormSearchInterface) => {
            onSearchChange(formData.search)
          }}
        />
      </Box>
      <Select
        multiple
        defaultValue={selectedAssetItemKinds}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return 'Type'
          }

          return `${selected.length} Type`
        }}
        onChange={handleAssetItemKindsChange}
        sx={{
          width: '150px',
          height: '40px',
          fontSize: '14px',

          border: '1px solid var(--grey200)',
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        displayEmpty
      >
        {assetItemKinds.map((kind) => (
          <MenuItem key={kind.value} value={kind.value}>
            {kind.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
