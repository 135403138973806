import PagePathConstant from '@lib/constants/page-path.constant'
import LayoutConstant from '@lib/constants/layout.constant'
import SystemHelper from '@lib/helpers/system.helper'

function HomePage() {
  return <></>
}

HomePage.pageMeta = {
  metaTitle: SystemHelper.getCurrentPageName('Dashboard'),
  metaDescription: 'The page for dashboard',
  metaKeywords: '',
  path: PagePathConstant.HOME,
  layout: LayoutConstant.Empty,
}

export default HomePage
