import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { getPossibleRoles, getUsersList } from '@store/selectors/user.selector'
import {
  disableUser,
  enableUser,
  inviteUser,
  removeUser,
  requestPossibleRoles,
  requestUsersList,
  resendInviteUser,
  updateUser,
} from '@store/actions/user.action'
import TableFactory from '@lib/factories/table.factory'
import { MANAGE_USERS_TABLE_HEAD } from '@tables/core/table-constants'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { OnMenuClickData } from '@tables/core/table-handlers'
import UserModel from '@lib/models/user.model'
import { Nullable, VIMatrix } from '@lib/engine-types'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { useModal } from '@lib/hooks/useModal'
import ControlledVITable from '@tables/ControlledVITable'
import useLocalVIMatrixSearch from '@lib/hooks/search/useLocalVIMatrixSearch'
import PaginationBlock from '@components-simple/pagination-block/PaginationBlock'
import useLocalModelPagination from '@lib/hooks/pagination/useLocalModelPagination'
import SearchForm from '@forms/search/search.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import FormAddUserInterface from '@lib/interfaces/form/form-add-user.interface'
import Modal from '@lib/constants/modal.constant'
import { MANAGE_USERS_TABLE_MAPPER } from '@tables/core/table-vi-draw-mappers'
import AddUserModal, {
  AddUserModalInputData,
} from '@components-composite/modals/AddUserModal'
import DialogModal from '@components-composite/modals/DialogModal'
import UserRoleModel from '@lib/models/user-role.model'
import EditUserModal, {
  EditUserModalInputData,
} from '@components-composite/modals/EditUserModal'
import FormEditUserInterface from '@lib/interfaces/form/form-edit-user.interface'
import SystemHelper from '@lib/helpers/system.helper'
import { Button } from '@mui/material'
import {
  ManageUsersMenu,
  ManageUsersMenuViRow,
} from '@components-complex/management/management-users/manage-users-menu-vi-row'
import { selectModalBasedOnUserMenuOption } from '@components-complex/management/management-users/utils'

function ManagementUsers() {
  const dispatch = useDispatch()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const isModalOpen = urlParams.get('modal') ?? ''

  const blockLoading = usePreloader(PreloaderConstants.REQUEST_USERS_LIST)
  const controlsLoading = usePreloaderAny([
    PreloaderConstants.REMOVE_USER,
    PreloaderConstants.DISABLE_USER,
    PreloaderConstants.REQUEST_USERS_LIST,
    PreloaderConstants.RESEND_INVITE_USER,
  ])
  const userInviteLoading = usePreloader(PreloaderConstants.INVITE_USER)
  const userUpdateLoading = usePreloader(PreloaderConstants.UPDATE_USER)

  const possibleRoles: Array<UserRoleModel> = useSelector(getPossibleRoles)

  useEffect(() => {
    dispatch(requestUsersList())
    dispatch(requestPossibleRoles())
  }, [])

  const usersList = useSelector(getUsersList)
  // transforming into a table format
  const usersListTableData: VIMatrix = TableFactory.managementVIUsers(usersList)

  // searching
  const { filteredData, setSearch } = useLocalVIMatrixSearch(
    usersListTableData,
    ''
  )

  // pagination
  const { paginatedData, pagination, setPagination } =
    useLocalModelPagination<VIMatrix>(filteredData, 0, 10)

  // remove user modal
  const { openModal: openUserRemoveModal, modalProps: userRemoveModalProps } =
    useModal<string>(Modal.manageUsersRemoveUser, (id) =>
      dispatch(removeUser(id))
    )

  // disable user modal
  const { openModal: openUserDisableModal, modalProps: userDisableModalProps } =
    useModal<string>(Modal.manageUsersDisableUser, (id) =>
      dispatch(disableUser(id))
    )

  // enable user modal
  const { openModal: openUserEnableModal, modalProps: userEnableModalProps } =
    useModal<string>(Modal.manageUsersEnableUser, (id) =>
      dispatch(enableUser(id))
    )

  // add user
  const { openModal: openUserAddModal, modalProps: userAddModalProps } =
    useModal<AddUserModalInputData, FormAddUserInterface>(
      Modal.manageUsersAddUser,
      (form) => {
        dispatch(inviteUser(form))
      },
      false
    )

  useEffect(() => {
    if (isModalOpen && possibleRoles && possibleRoles.length > 0) {
      openUserAddModal({
        possibleRoles,
      })
    }
  }, [isModalOpen, possibleRoles])

  // edit user
  const { openModal: openUserEditModal, modalProps: userEditModalProps } =
    useModal<EditUserModalInputData, FormEditUserInterface>(
      Modal.manageUsersEditUser,
      (form) => {
        dispatch(updateUser(form))
      },
      false
    )

  const handleMenuClick = ({ chosenMenu, data }: OnMenuClickData) => {
    const email = String(data[0].label)

    const findUserWithErrorAnd = (
      err: string,
      fn: (user: UserModel) => any
    ) => {
      const user: Nullable<UserModel> = usersList.find((v) => v.email === email)
      return user ? fn(user) : SystemHelper.throwErrorInLocalEnv(err)
    }

    // Add extra variable, cause current switch have strict comparison, and we need a space in the word (we cannot do that in enums)
    const isResend = chosenMenu === ManageUsersMenu.ResendInvite

    if (isResend) {
      findUserWithErrorAnd('User for editing was not found', (user) =>
        dispatch(resendInviteUser(user.email, user.role.name))
      )
      return
    }

    const menuAction = selectModalBasedOnUserMenuOption({
      chosenMenu,
      possibleRoles,
      openUserRemoveModal,
      openUserEditModal,
      openUserEnableModal,
      openUserDisableModal,
    })

    if (menuAction) {
      const { errorText, openModal } = menuAction
      findUserWithErrorAnd(errorText, (user) => openModal(user))
    }
  }

  return (
    <div className="wrap-1617010233124">
      <div className="searchTopWrap">
        <SearchForm
          placeholder="Search Members"
          errorGroups={[ErrorGroupConstants.EMPTY]}
          loading={controlsLoading}
          variant="whiteTopSearch"
          onSubmit={(formData: FormSearchInterface) => {
            setSearch(formData.search)
          }}
        />
        <Button
          aria-label="Add User"
          disabled={possibleRoles.length === 0}
          onClick={() =>
            openUserAddModal({
              possibleRoles,
            })
          }
          className="buttonV2"
          color="primary"
          variant="contained"
          size="large"
        >
          + Add User
        </Button>
      </div>

      {blockLoading && paginatedData.length === 0 ? (
        <PreloaderBlock show />
      ) : (
        <>
          {paginatedData.length > 0 ? (
            <>
              <ControlledVITable
                head={MANAGE_USERS_TABLE_HEAD}
                columnDrawMapper={MANAGE_USERS_TABLE_MAPPER()}
                rows={paginatedData}
                className={clsx('jsUsersTable', {
                  controlsDisabled: controlsLoading,
                })}
                onMenuClick={handleMenuClick}
                menu={[ManageUsersMenu.ResendInvite, ManageUsersMenu.Disable]}
                rowComponent={ManageUsersMenuViRow}
              />
              <PaginationBlock
                pagination={pagination}
                onChange={setPagination}
              />
            </>
          ) : (
            <div className="emptyResultBlock jsEmpty">
              There are no users yet
            </div>
          )}
        </>
      )}

      <AddUserModal {...userAddModalProps} loading={userInviteLoading} />
      <EditUserModal {...userEditModalProps} loading={userUpdateLoading} />
      <DialogModal
        description="You are going to remove the user, please confirm the action in order to proceed"
        {...userRemoveModalProps}
      />
      <DialogModal
        description="You are going to disable the user, please confirm the action in order to proceed"
        {...userDisableModalProps}
      />
      <DialogModal
        description="You are going to enable the user, please confirm the action in order to proceed"
        {...userEnableModalProps}
      />
    </div>
  )
}

export default memo(ManagementUsers)
