/* eslint-disable import/no-extraneous-dependencies */
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  BackupVariant,
  InstancesOutput,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import { EfsThreatsOutput } from 'blues-corejs/dist/use_cases/list_efs_with_threats/types'
import { S3BucketThreatsOutput } from 'blues-corejs/dist/use_cases/list_s3buckets_with_threats/types'
import {
  ListVolumesWithThreatsOutput,
  VolumeThreatsOutput,
} from 'blues-corejs/dist/use_cases/list_volumes_with_threats/types'
import { useMemo } from 'react'

export function getUniqueInstances(
  instancesList: Array<ListVolumesWithThreatsOutput>
): Array<InstancesOutput> {
  return Array.from(
    instancesList
      .flatMap(({ instances }) => instances)
      .reduce(
        (acc, instance) => acc.set(instance.instanceId, instance),
        new Map()
      )
      .values()
  )
}

export function initialPrioritySortForEC2Table(
  instances: Array<InstancesOutput>
) {
  return instances.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const aLastBackupTime = a.lastBackup?.backup.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.backup.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const instanceAName = a.instanceName || a.instanceAwsId
    const instanceBName = b.instanceName || b.instanceAwsId

    return instanceAName.localeCompare(instanceBName)
  })
}

export function initialPrioritySortForEBSTable(
  volumes: Array<VolumeThreatsOutput>
) {
  return volumes.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const aLastBackupTime = a.lastBackup?.backup.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.backup.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const volumeAName = a.volumeName || a.volumeAwsId
    const volumeBName = b.volumeName || b.volumeAwsId

    return volumeAName.localeCompare(volumeBName)
  })
}

export function initialPrioritySortForS3Table(
  buckets: Array<S3BucketThreatsOutput>
) {
  return buckets.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const bucketAName = a.name || a.s3AwsId
    const bucketBName = b.name || b.s3AwsId

    return bucketAName.localeCompare(bucketBName)
  })
}

export function initialPrioritySortForEFSTable(
  filesystems: Array<EfsThreatsOutput>
) {
  return filesystems.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const efsAName = a.name || a.efsAwsId
    const efsBName = b.name || b.efsAwsId

    return efsAName.localeCompare(efsBName)
  })
}

export const useLiveAssetsDataForAssetsThreat = () => {
  const { data: liveAssetsData, isLoading } = useAssetsLiveQuery()
  //TODO: need to delay turning off loading, theres a flicker of no data dute to client side logic
  return useMemo(() => {
    if (!liveAssetsData) {
      return {
        data: undefined,
        isLoading,
      }
    }

    const {
      ec2Instances,
      ebsVolumes,
      s3Buckets,
      efs,
      threats,
      scans,
      lastBackups,
    } = liveAssetsData

    const lastBackupsMap = new Map<string, BackupVariant>()
    for (const [key, backup] of lastBackups.backups) {
      lastBackupsMap.set(key, { backup })
    }

    const lastScans = scans.flatMap(
      (scan) =>
        scan.ransomwareScan ||
        scan.malwareScan ||
        scan.filesystemCheckScan ||
        []
    )

    const lastMalwareScans = scans
      .filter(
        (scan): scan is { malwareScan: MalwareScan } => !!scan.malwareScan
      )
      .map((scan) => scan.malwareScan)

    const lastRansomwareScans = scans
      .filter(
        (scan): scan is { ransomwareScan: RansomwareScan } =>
          !!scan.ransomwareScan
      )
      .map((scan) => scan.ransomwareScan)

    return {
      data: {
        instances: ec2Instances,
        volumes: ebsVolumes,
        s3buckets: s3Buckets,
        filesystems: efs,
        lastBackups: lastBackupsMap,
        threats,
        lastScans,
        lastMalwareScans,
        lastRansomwareScans,
      },
      isLoading,
    }
  }, [liveAssetsData, isLoading])
}
