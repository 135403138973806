import { useState, useCallback } from 'react'
import { DialogProps } from '@mui/material/Dialog'

import { useToggle } from '@lib/hooks'

type DialogScrollType = DialogProps['scroll']

const DIALOG_PAPER_SCROLL_IS_TYPE = 'paper'

type ModalState = {
  isOpenModal: boolean
  openModal: () => void
  closeModal: () => void
  updateModalScrollType: (scrollType: DialogScrollType) => void
  scroll: DialogScrollType
}

export function useModalWithScroll(): ModalState {
  const { isActive: isOpenModal, activate, deactivate } = useToggle()

  const [scroll, setScroll] = useState<DialogScrollType>(
    DIALOG_PAPER_SCROLL_IS_TYPE
  )

  const openModal = useCallback(() => {
    activate()
  }, [])

  const closeModal = useCallback(() => {
    deactivate()
  }, [])

  const updateModalScrollType = useCallback((scrollType: DialogScrollType) => {
    setScroll(scrollType)
  }, [])

  return {
    isOpenModal,
    openModal,
    closeModal,
    updateModalScrollType,
    scroll,
  }
}
