import React, { useState } from 'react'
import { Button } from '@mui/material'
import ThreatForm from './threat-form'
import { ThreatListTableRow } from 'ui-v2/src/lib/models/assets/infected-assets'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

const ThreatAction = ({ row }: ThreatActionProps) => {
  const [open, setOpen] = useState<boolean>()

  const handleSuppress = () => {
    setOpen(true)
  }
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleSuppress}
        startIcon={<NotificationsActiveIcon />}
        sx={{ backgroundColor: '#4E5665' }}
      >
        Suppress
      </Button>
      <ThreatForm open={!!open} row={row} handleClose={() => setOpen(false)} />
    </>
  )
}
interface ThreatActionProps {
  row: ThreatListTableRow
}
export default ThreatAction
