import { useState, useEffect } from 'react'
import {
  DataFetchStatus,
  UseDataFetcher,
} from '@components-complex/dashboard-pages-v3/hooks/types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EFS } from 'blues-corejs/dist'

import { ListEfsFilter, ListEfsClient } from '@lib/clients/assets/list-efs'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

const listEfsClient = new ListEfsClient()

export function useListEfs(
  request: any = {}
): Omit<UseDataFetcher<Array<EFS>>, 'resetData'> {
  const [dataFetchStatus, setDataFetchStatus] = useState<DataFetchStatus>(
    DataFetchStatus.IDLE
  )
  const [listEbs, setListEbs] = useState<Array<EFS>>([])

  const updateListEbsData = (listEbsCResponse: Array<EFS>) => {
    setListEbs((prevListEbs) => [...prevListEbs, ...listEbsCResponse])
  }

  const handleFetchSuccess = (response: Array<EFS>) => {
    updateListEbsData(response)
  }

  const isLoadingData = () => dataFetchStatus === DataFetchStatus.IS_LOADING

  const resetFetchStatus = () => {
    setDataFetchStatus(DataFetchStatus.IDLE)
  }

  const fetchData = async (): Promise<void> => {
    setDataFetchStatus(DataFetchStatus.IS_LOADING)
    try {
      if (dataFetchStatus === DataFetchStatus.IDLE) {
        const response = await listEfsClient.list(new ListEfsFilter(request))

        setDataFetchStatus(DataFetchStatus.SUCCESS)
        handleFetchSuccess(response)
      }
    } catch (error) {
      console.error(
        `Something went wrong with fetch listEbs request - ${error}. Error occurred in: ${__dirname}`
      )
      setDataFetchStatus(DataFetchStatus.FAILURE)
    } finally {
      resetFetchStatus()
    }
  }

  useEffect(() => {
    setDataFetchStatus(DataFetchStatus.IS_LOADING)
    fetchData()
    const fetchInterval = setInterval(fetchData, INTERVAL_REQUEST_TIMEOUT)

    return () => {
      clearInterval(fetchInterval)
    }
  }, [])

  return {
    onFetchData: fetchData,
    data: listEbs,
    dataFetchStatus,
    isLoadingData,
  }
}
