import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { setListAvailabilityZones } from '@store/actions/rex.action'
import { useLayoutContext } from '@features/contexts'
import { RESTORE_RP_INFO_DRAW_MAPPER } from '@tables/core/table-vi-draw-mappers'
import MoreBlock from '@components-composite/more-block/MoreBlock'
import {
  useURLDataDecoder,
  useBackupsForAssetListAndScansData,
  ScanStatusLabel,
} from '@components-complex/dashboard-pages-v3/restore/shared'
// eslint-disable-next-line import/no-extraneous-dependencies
import DataHelper from '@lib/helpers/data.helper'
import RestorePageForm from '@forms/restore/restore-page.form'
import { useFetchNetworkResourcesOnInstanceChange } from './use-fetch-network-resources-on-Instance-change'
import { useInitializeAwsEc2Environment } from './use-initialize-aws-ec2-environment'
import {
  getListAwsSecurityGroupsList,
  getPossibleVPCList,
} from '@store/selectors/rex.selector'
import { PageHelper } from '@lib/helpers'
import { PagePathConstant } from '@lib/constants'
import FormRestoreInterface from '@lib/interfaces/form/form-restore.interface'
import { useEC2Instance } from './use-ec2-instance'
import { useRestoreSchedule } from './use-schedule-restore'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { isAnyScanInfected } from './should-be-infected'
import { useNavigation } from '@lib/router/contexts/navigation-context'

const topText =
  'The EC2 restore will create a new instance and new EBS volumes identical to the recovery point and it will not overwrite the existing one. The instance configuration will match exactly as in the recovery point.'

function RestoreInstance() {
  const dispatch = useDispatch()
  const router = useNavigation()
  const { assetId, backupId } = useURLDataDecoder()
  const { asset, isAssetLoading } = useEC2Instance(assetId)
  const { scans, backup, isLoadingBackupAndScans } =
    useBackupsForAssetListAndScansData({
      assetId,
      backupId,
    })

  const { isRestoreInProgress, restoreSchedule } = useRestoreSchedule(backup)

  const isLoading = isAssetLoading && isLoadingBackupAndScans

  useEffect(() => {
    dispatch(setListAvailabilityZones([]))
  }, [])

  useFetchNetworkResourcesOnInstanceChange({
    accountId: asset?.awsAccountId,
    region: asset?.awsRegion,
  })

  const { setBreadcrumbsPaths, setHeaderTitle, setCurrentCrumbTitle } =
    useLayoutContext()

  useInitializeAwsEc2Environment()

  const possibleVPCList = useSelector(getPossibleVPCList)
  const possibleSecurityGroupsList = useSelector(getListAwsSecurityGroupsList)

  const handleRestoreScheduleSubmit = async (form: FormRestoreInterface) => {
    restoreSchedule(form)
  }

  const handleGoBack = () => {
    if (!asset) {
      return
    }

    router.replace(PageHelper.buildAssetPageUrl(asset.id))
  }

  useEffect(() => {
    if (!asset) {
      return
    }

    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
      },
      {
        href: PagePathConstant.EC2_INVENTORY_TABLE,
        text: titlesForBreadcrumb(PagePathConstant.EC2_INVENTORY_TABLE) ?? '',
      },
      {
        href: PageHelper.buildAssetPageUrl(asset.id),
        text: asset.awsId,
      },
    ])
  }, [asset?.awsId])

  useEffect(() => {
    if (!backup) {
      return
    }
    setHeaderTitle(`Restore ${backup.ccRpId}`)
    setCurrentCrumbTitle(backup.ccRpId)

    return () => {
      setHeaderTitle(null)
      setCurrentCrumbTitle(null)
    }
  }, [backup])

  if (isLoading) {
    return <PreloaderBlock show />
  }

  const isInfected = isAnyScanInfected(scans)

  if (!(backup && asset)) {
    return null
  }

  return (
    <div className="innerContent innerContentBlueBackground jsRestore">
      <div className="wrap-1687845026448">
        <div className="icNameWithBackWrap">
          <ScanStatusLabel isInfected={isInfected} />
        </div>
        <div className="helpText">{topText}</div>
        <MoreBlock
          data={DataHelper.restoreRpInstanceInfo({
            backup,
            ec2: asset,
            scans,
          })}
          columns={3}
          isButtonShow={false}
          drawMapper={RESTORE_RP_INFO_DRAW_MAPPER()}
        />
        <RestorePageForm
          backedup={backup}
          asset={asset}
          isLoading={isRestoreInProgress}
          onCancel={handleGoBack}
          onSubmit={handleRestoreScheduleSubmit}
          kindLabel="ec2"
          isShowVpcList
          isShowAvailabilityZonesList={false}
          possibleVPCList={possibleVPCList}
          possibleSecurityGroupsList={possibleSecurityGroupsList}
        />
      </div>
    </div>
  )
}

export default RestoreInstance
