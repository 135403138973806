/* eslint-disable import/no-extraneous-dependencies */
import { Asset, GenericHost, OvaServer } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

export function computeAssetName(asset?: Asset) {
  if (asset instanceof AWSAsset) {
    return asset.name ? `${asset.name} (${asset.awsId})` : asset.awsId
  }

  if (asset instanceof GenericHost) {
    return asset.hostname
  }

  if (asset instanceof OvaServer) {
    return asset.name
  }

  return ''
}
