import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RbacClient } from 'ui-v2/src/lib/clients'

export function useListRolesQuery() {
  const rbacClient = useGrpcClient(RbacClient)

  return useQuery({
    queryKey: ['rbac', 'list-roles'],
    queryFn: async () => {
      if (!rbacClient) {
        throw new Error('Rbac client not initialized')
      }
      return rbacClient.listRoles()
    },
    enabled: !!rbacClient,
  })
}
