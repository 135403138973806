import { Navigate, useSearchParams } from 'react-router-dom'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

type Tab = '0' | '1' | '2' | '3' | '4' | '5'

const TAB_SEARCH_PARAM_MAP: Record<
  Tab,
  (typeof ASSETS_ROUTE_SEGMENTS)[keyof typeof ASSETS_ROUTE_SEGMENTS]
> = {
  '0': ASSETS_ROUTE_SEGMENTS.EC2,
  '1': ASSETS_ROUTE_SEGMENTS.EBS,
  '2': ASSETS_ROUTE_SEGMENTS.S3,
  '3': ASSETS_ROUTE_SEGMENTS.EFS,
  '4': ASSETS_ROUTE_SEGMENTS.SERVER,
  '5': ASSETS_ROUTE_SEGMENTS.GENERIC_HOST,
}

const RedirectToUpdatedAssetPath = () => {
  const [searchParams] = useSearchParams()

  const tab = (searchParams.get('t') ?? '0') as Tab

  const redirectTab = TAB_SEARCH_PARAM_MAP[tab] ?? ASSETS_ROUTE_SEGMENTS.EC2

  const newPath = `/${BASE_ROUTE_SEGMENTS.ASSETS}/${redirectTab}`

  return <Navigate to={newPath} replace />
}

export default RedirectToUpdatedAssetPath
