/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, memo } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CopyToClipboardIcon } from '@features/common'
import { StyledContainer } from './styles'
import { Ec2Icon } from '@features/DashboardV4/common'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { ElastioRecoveryPoint } from 'blues-corejs/dist'

import { PageHelper, StrHelper } from '@lib/helpers'
import { ASSET_ID_QUERY_KEY, PagePathConstant } from '@lib/constants'
import { DynamicButton } from './dynamic-button'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface Props {
  asset: AWSAsset
  backup?: ElastioRecoveryPoint
}

function buildRestoreLink(assetId: string, recoveryPointId: string) {
  return `${PageHelper.buildUrl(
    PagePathConstant.RESTORE_INSTANCE,
    recoveryPointId
  )}?${ASSET_ID_QUERY_KEY}=${StrHelper.base64Encode(assetId)}`
}

function Header({ asset, backup }: Props) {
  const router = useNavigation()

  const handleSandboxClick = (): void => {
    if (!backup) {
      return
    }

    router.push(buildRestoreLink(asset.id, backup.id))
  }

  const isCleanRP = backup?.hasGoodSummaryCheckStatus || false

  return (
    <Fragment>
      <Ec2Icon />
      <StyledContainer>
        <Box className="asset-name">
          <Typography component={'span'} fontWeight="500" fontSize="16px">
            <LongTextTooltip text={asset.name} maxLength={20} />
          </Typography>
          <Typography
            variant="caption"
            fontSize={asset.name ? '11px' : '14px'}
            display="flex"
            alignItems="center"
          >
            {asset.awsId}
            <CopyToClipboardIcon text={asset.awsId} />
          </Typography>
        </Box>
        {backup && (
          <DynamicButton isCleanRP={isCleanRP} onClick={handleSandboxClick} />
        )}
      </StyledContainer>
    </Fragment>
  )
}

export default memo(Header)
