export function copyToClipboard(str: string): Promise<void> {
  return navigator.clipboard.writeText(str)
}

export function getCurrentTenantName(): string {
  return globalThis?.location?.host ?? ''
}

export function pureNavigate(url: string) {
  if (!globalThis || !globalThis.location) {
    return
  }

  const urlValue = url.replace(/^\//, '')
  let origin = ''

  if (!url.match(/^http/)) {
    origin = `${globalThis.location.origin}/`
  }

  if (globalThis.location.pathname === url) {
    globalThis.history.pushState({}, '', urlValue)
    return
  }

  globalThis.location.href = `${origin}${urlValue}`
}

export function navigateTo(url: string) {
  window.history.pushState({}, '', url)

  const navEvent = new PopStateEvent('popstate')
  window.dispatchEvent(navEvent)
}
