/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import { ScansPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { Pagination } from '@lib/clients/types'
import { ListScansByFiltersRequest } from '@lib/clients/scans/list-scans-by-filters'
import { Response as ScansByFiltersResponse } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/list_scans_by_filters_pb'
import {
  FilesystemScanTransformer,
  MalwareScanTransformer,
  RansomwareScanTransformer,
} from '@lib/clients/assets/transformers'
import { ScansRun } from 'blues-corejs/dist/models'

export type ListScansByFiltersResponse = {
  scansRunList: Array<ScansRun>
}

export class ListScansByFiltersClient extends GrpcClient<ScansPromiseClient> {
  #scansClient: ScansPromiseClient

  constructor(hostName = '') {
    super()
    this.#scansClient = new ScansPromiseClient(hostName, null, null)
  }

  protected initClient(hostName: string): ScansPromiseClient {
    return new ScansPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListScansByFiltersClient'
  }

  async listScansByFilters(
    request: ListScansByFiltersRequest
  ): Promise<ListScansByFiltersResponse & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#scansClient.listScansByFilters(
            request.toGrpc(),
            this.metadata()
          ),
        {
          requestName: 'ScansPromiseClient/listScansByFilters',
        }
      )
    ).toObject()

    return {
      scansRunList: this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformResponse(
    response: ScansByFiltersResponse.AsObject
  ): Array<ScansRun> {
    return response.scanRunsList.map(
      ({ filesystemChecksList, malwareScansList, ransomwareScansList }) => ({
        filesystemChecksList: filesystemChecksList.map((filesystemChecks) =>
          new FilesystemScanTransformer(filesystemChecks).transform()
        ),
        malwareScansList: malwareScansList.map((malwares) =>
          new MalwareScanTransformer(malwares).transform()
        ),
        ransomwareScansList: ransomwareScansList.map((ransomwares) =>
          new RansomwareScanTransformer(ransomwares).transform()
        ),
      })
    )
  }
}
