import { styled } from '@mui/material'
import Drawer from '@mui/material/Drawer'

export const StyledDrawer = styled(Drawer)``

StyledDrawer.defaultProps = {
  anchor: 'right',
  PaperProps: {
    sx: {
      width: '40%',
      minWidth: '640px',
    },
  },
}
