import React, { ReactNode } from 'react'
import { Box, Typography } from '@mui/material'

function NameDisplay({
  label,
  value,
}: {
  label: string
  value: string | ReactNode
}) {
  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Typography variant="body1" fontWeight="bold" mr={1}>
        {label}:
      </Typography>
      <Typography variant="body1" fontStyle="italic">
        {value}
      </Typography>
    </Box>
  )
}

export default NameDisplay
