import { Divider, Paper, styled } from '@mui/material'

export const Container = styled(Paper)({
  padding: '24px',
  borderRadius: '8px',
  height: '100%',
})

export const StyledDivider = styled(Divider)({
  marginBlock: '10px',
})
