import { Box, IconButton, Typography, useTheme } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'

const CopyRow = ({ text }: CopyRowProps) => {
  const theme = useTheme()
  return (
    <Box
      display={'inline-flex'}
      alignItems={'center'}
      sx={{
        background: theme.palette.mode === 'dark' ? '#1a1f28' : '#ddfde3',
      }}
    >
      <Typography variant="body2" m={2}>
        {text}
      </Typography>
      <IconButton
        size="small"
        onClick={() => {
          copyToClipboard(text)
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  )
}
interface CopyRowProps {
  text: string
}
export default CopyRow
