import { useState, ChangeEvent } from 'react'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  useTabsActions,
  useEditingItemId,
} from '@features/reports/schedules/use-data-store'
import { formOptions, FormOptionValue } from './form-options'
import { DrawerActionHeader } from '@features/reports/schedules/shared/drawer-action-header'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { ReportFormType } from '@features/reports/schedules/shared'

const shouldDisableFormControl = (
  isExceedingMaxLength: boolean,
  optionValue: FormOptionValue
): boolean => {
  return isExceedingMaxLength && optionValue === FormOptionValue.SCHEDULE
}

function getInitTab(kind: string) {
  const reportTypeMapping = {
    [ReportFormType.ON_DEMAND]: FormOptionValue.ON_DEMAND,
    [ReportFormType.SCHEDULED]: FormOptionValue.SCHEDULE,
  } as const
  return reportTypeMapping[kind as keyof typeof reportTypeMapping]
}

function DynamicForm({ forms = formOptions, initialFormValue = '' }: any) {
  const editingItemId = useEditingItemId()
  const [selectedForm, setSelectedForm] = useState<FormOptionValue | undefined>(
    getInitTab(initialFormValue)
  )

  const { maxAmountOfReportSchedules } = useCustomFlags()
  const { getReportDataLength } = useTabsActions()

  const shouldDisableScheduleForm = shouldDisableFormControl(
    getReportDataLength() > maxAmountOfReportSchedules,
    FormOptionValue.SCHEDULE
  )

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as FormOptionValue
    setSelectedForm(newValue)
  }

  const selectedFormComponent = forms.find(
    (form: any) => form.value === selectedForm
  )?.component

  const enhancedForms = forms.map((form: any) => ({
    ...form,
    isDisabled:
      form.value === FormOptionValue.SCHEDULE && shouldDisableScheduleForm,
  }))

  return (
    <>
      <DrawerActionHeader />
      {Boolean(editingItemId) ? null : (
        <RadioGroup row value={selectedForm} onChange={handleRadioChange}>
          {enhancedForms.map((form: any) => (
            <FormControlLabel
              key={form.value}
              value={form.value}
              control={<Radio />}
              label={form.label}
              disabled={form.isDisabled}
            />
          ))}
        </RadioGroup>
      )}
      <Box mt={2}>{selectedFormComponent}</Box>
    </>
  )
}

export default DynamicForm
