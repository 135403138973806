import { TenantStatisticsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { KrabsPromiseClient } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_grpc_web_pb'
import {
  GetStorageReductionMetricsRequest,
  GetStorageReductionMetricsResponse,
} from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'
import { GrpcClient } from '../grpc-client/grpc-client'

export class TenantStatisticsClient extends GrpcClient<TenantStatisticsPromiseClient> {
  #krabsClient: KrabsPromiseClient

  constructor(hostName = '') {
    super()
    this.#krabsClient = new KrabsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'TenantStatisticsClient'
  }

  protected initClient(hostName = ''): TenantStatisticsPromiseClient {
    return new TenantStatisticsPromiseClient(hostName, null, null)
  }

  async getStorageReductionMetrics(): Promise<GetStorageReductionMetricsResponse.AsObject> {
    const request = await this.retryGrpcCall(
      () =>
        this.#krabsClient.getStorageReductionMetrics(
          new GetStorageReductionMetricsRequest(),
          this.metadata()
        ),
      {
        requestName: 'KrabsPromiseClient/getStorageReductionMetrics',
      }
    )

    return request.toObject()
  }
}
