export default function OvaDiskIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
    >
      <g filter="url(#filter0_d_20895_82091)">
        <g clipPath="url(#clip0_20895_82091)">
          <circle
            cx="23"
            cy="23"
            r="23"
            fill="url(#paint0_linear_20895_82091)"
          />
          <path
            d="M19.1294 28.2695H17.8794C17.6205 28.2695 17.4106 28.4794 17.4106 28.7383C17.4106 28.9972 17.6205 29.207 17.8794 29.207H19.1294C19.3883 29.207 19.5981 28.9972 19.5981 28.7383C19.5981 28.4794 19.3883 28.2695 19.1294 28.2695Z"
            fill="white"
          />
          <path
            d="M31.1922 28.3588L29.5753 17.1535C29.4948 16.5958 29.2159 16.0855 28.79 15.7166C28.364 15.3477 27.8191 15.1445 27.2556 15.1445H19.1907C18.6272 15.1445 18.0823 15.3477 17.6564 15.7166C17.2304 16.0855 16.9515 16.5958 16.871 17.1535L15.2541 28.3588C15.2339 28.4824 15.2231 28.6091 15.2231 28.7383C15.2231 30.0306 16.2746 31.082 17.5669 31.082H28.8794C30.1717 31.082 31.2231 30.0306 31.2231 28.7383C31.2231 28.6091 31.2124 28.4824 31.1922 28.3588ZM17.7989 17.2874C17.8981 16.6003 18.4964 16.082 19.1907 16.082H27.2556C27.9499 16.082 28.5482 16.6003 28.6474 17.2874L30.0033 26.6822C29.6694 26.499 29.2864 26.3945 28.8794 26.3945H17.5669C17.1599 26.3945 16.7769 26.499 16.4431 26.6822L17.7989 17.2874ZM28.8794 30.1445H17.5669C16.7915 30.1445 16.1606 29.5137 16.1606 28.7383C16.1606 27.9629 16.7915 27.332 17.5669 27.332H28.8794C29.6548 27.332 30.2856 27.9629 30.2856 28.7383C30.2856 29.5137 29.6548 30.1445 28.8794 30.1445Z"
            fill="white"
          />
          <path
            d="M28.5669 28.2695H22.5669C22.308 28.2695 22.0981 28.4794 22.0981 28.7383C22.0981 28.9972 22.308 29.207 22.5669 29.207H28.5669C28.8258 29.207 29.0356 28.9972 29.0356 28.7383C29.0356 28.4794 28.8258 28.2695 28.5669 28.2695Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_20895_82091"
          x="0"
          y="0"
          width="46"
          height="46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_20895_82091"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20895_82091"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_20895_82091"
          x1="0"
          y1="0"
          x2="46"
          y2="46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B660F" />
          <stop offset="1" stopColor="#6CAE3E" />
        </linearGradient>
        <clipPath id="clip0_20895_82091">
          <rect width="46" height="46" rx="22.999" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
