import { useQuery } from '@tanstack/react-query'
import { ListUsersFilters } from 'ui-v2/src/lib/models/users'
import { UsersClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useListUsersQuery(filters?: ListUsersFilters) {
  const usersClient = useGrpcClient(UsersClient)

  return useQuery({
    queryKey: ['users', 'users-list', filters],
    queryFn: () => {
      if (!usersClient) {
        throw new Error('Users client not initialized')
      }
      return usersClient.listUsers(filters)
    },
    enabled: !!usersClient,
  })
}
