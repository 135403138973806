import { useParams, Navigate } from 'react-router-dom'
import {
  ASSETS_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { base64Decode } from 'ui-v2/src/lib/helpers/string.helper'

const RedirectToUpdatedAssetDetailsPath = () => {
  const { assetId } = useParams()

  const newPath = `/${BASE_ROUTE_SEGMENTS.ASSETS}/${ASSETS_ROUTE_SEGMENTS.EC2}/${base64Decode(assetId)}`

  return <Navigate to={newPath} replace />
}

export default RedirectToUpdatedAssetDetailsPath
