import React, { memo } from 'react'
import RansomwareIcon from '@inline-img/general/ransomware-icon'
import MalwareIcon from '@inline-img/general/malware-icon'
import FsCheckIcon from '@inline-img/general/fs-check-icon'
import ThreatIconBlock from './threat-icon-block'
import { Risks } from '../types'
import Box from '@mui/material/Box'

function ThreatIconsBlock({
  ransomwareStatus,
  malwareStatus,
  fsCheckStatus,
}: Risks) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      {ransomwareStatus && (
        <ThreatIconBlock scanStatus={ransomwareStatus} Icon={RansomwareIcon} />
      )}
      {malwareStatus && (
        <ThreatIconBlock scanStatus={malwareStatus} Icon={MalwareIcon} />
      )}
      {fsCheckStatus && (
        <ThreatIconBlock scanStatus={fsCheckStatus} Icon={FsCheckIcon} />
      )}
    </Box>
  )
}

export default memo(ThreatIconsBlock)
