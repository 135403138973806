/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Tab } from '@mui/material'
import FilterDashboardV2 from '@components-complex/filter-dashboard-v2/FilterDashboardV2'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import EditableTags from '@components-composite/editable-tags/EditableTags'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import SearchFormV2 from '@forms/search-2/search.form'
import PagePathConstant from '@lib/constants/page-path.constant'
import { useAssetsWithVulnerabilitiesData } from './useAssetsWithVulnerabilitiesData'
import { useSelector } from 'react-redux'
import {
  getEbsVulnerabilitiesSelectedFilters,
  getSnapshotsVulnerabilitiesSelectedFilters,
} from '@store/selectors/dashboard.selector'
import {
  ListSnapshotsVulnerabilities,
  ListVolumesVulnerabilities,
  SnapshotsVulnerabitiesFilters,
  VolumesWithVulnerabilitiesFilters,
} from 'blues-corejs/dist'
import { VulnerabilitiesDetectionStrategy } from 'blues-corejs/dist/use_cases'
import { VirtualTable } from '@components/table'
import DataHelper from '@lib/helpers/data.helper'
import { instancesColumns, snapshotsColumns, volumesColumns } from './tables'
import {
  getBackupsMapped,
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
} from '@store/selectors/list-live-assets.selector'
import { PreloaderBlock } from '@components-simple/preloaders'
import { ExportAsCsv } from '@features/common'
import {
  EC2_CSV_TABLE_HEADERS,
  prepareEc2DataForCsv,
  EBS_CSV_TABLE_HEADERS,
  prepareEbsDataForCsv,
  SNAPSHOT_CSV_TABLE_HEADERS,
  prepareSnapshotDataForCsv,
} from './csv-helpers'

function AssetsWithVulnerabilities() {
  const { filters, tags, tabs, isLoading } = useAssetsWithVulnerabilitiesData()

  const {
    onFiltersChange,
    possibleFiltersByTab,
    selectedFiltersByTab,
    setFilterSearch,
    filterSearch,
  } = filters

  const {
    onRisksFilterChange,
    onVulnerabilityFilterChange,
    vulnerabilitiesTagsData,
    risksTagsData,
  } = tags

  const { tab, onTabChange, Tabs } = tabs

  const selectedFilters = useSelector(getEbsVulnerabilitiesSelectedFilters)

  const selectedSnapshotsFilters = useSelector(
    getSnapshotsVulnerabilitiesSelectedFilters
  )

  const { ec2Instances, ebsVolumes, ebsSnapshots } = useSelector(getLiveAssets)
  const { fsCheckScans, malwareScans, ransomwareScans } = useSelector(getScans)
  const { lastBackups } = useSelector(getBackupsMapped)

  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const snapshotsList = new ListSnapshotsVulnerabilities({
    snapshots: ebsSnapshots,
    volumes: ebsVolumes,
  }).execute(
    DataHelper.getDataForDashboardFilter(
      selectedSnapshotsFilters,
      filterSearch.toLowerCase()
    ) as SnapshotsVulnerabitiesFilters,
    new VulnerabilitiesDetectionStrategy()
  )

  const { volumesList, instancesList } = new ListVolumesVulnerabilities({
    instances: ec2Instances,
    volumes: ebsVolumes,
    snapshots: ebsSnapshots,
    scans: [...fsCheckScans, ...malwareScans, ...ransomwareScans],
    lastBackups,
  }).execute(
    DataHelper.getDataForDashboardFilter(
      selectedFilters,
      filterSearch.toLowerCase()
    ) as VolumesWithVulnerabilitiesFilters,
    new VulnerabilitiesDetectionStrategy()
  )

  if (isLiveAssetsLoading) {
    return <PreloaderBlock show />
  }
  return (
    <div className="innerContent innerContentBlueBackground">
      <div className="wrap-1689072311273">
        <div className="wrapAssetsVulnerabilitiesTable newTablesPreview">
          <FilterDashboardV2
            possibleFilters={possibleFiltersByTab}
            selectedFilters={selectedFiltersByTab}
            onFiltersChange={onFiltersChange}
            disabled={false}
            showFilterFirst
            backButtonHref={PagePathConstant.DASHBOARD}
            tableName="Assets with Misconfigurations"
            turnOffButton
            customSearch={
              <SearchFormV2
                placeholder="Search..."
                variant="whiteFilterSearchBlackIcon"
                searchValue={filterSearch}
                onChange={setFilterSearch}
                debounceTime={0}
              />
            }
          />

          <div className="tags">
            <EditableTags
              title="Misconfiguration"
              tags={vulnerabilitiesTagsData ?? []}
              limit={3}
              onRemoveTag={onVulnerabilityFilterChange}
            />

            <EditableTags
              title="Risk Type"
              tags={risksTagsData ?? []}
              limit={3}
              onRemoveTag={onRisksFilterChange}
            />
          </div>

          <CustomTabs value={tab} onChangeTab={onTabChange}>
            <Tab label="Instances" />
            <Tab label="Volumes" />
            <Tab label="Snapshots" />
          </CustomTabs>
          <MaterialTab value={tab} index={Tabs.INSTANCES}>
            <ExportAsCsv
              headers={EC2_CSV_TABLE_HEADERS}
              data={prepareEc2DataForCsv(instancesList)}
              filename="ec2-miscofigurations.csv"
              shouldShow={instancesList.length > 0}
            />
            <VirtualTable
              config={{
                data: instancesList,
                columns: instancesColumns,
                state: {
                  isLoading,
                },
              }}
              emptyPlaceholderText="No EC2 Instances found"
            />
          </MaterialTab>
          <MaterialTab value={tab} index={Tabs.VOLUMES}>
            <ExportAsCsv
              headers={EBS_CSV_TABLE_HEADERS}
              data={prepareEbsDataForCsv(volumesList)}
              filename="ebs-miscofigurations.csv"
              shouldShow={volumesList.length > 0}
            />
            <VirtualTable
              config={{
                data: volumesList,
                columns: volumesColumns,
                state: {
                  isLoading,
                },
              }}
              emptyPlaceholderText="No EBS Volumes found"
            />
          </MaterialTab>
          <MaterialTab value={tab} index={Tabs.SNAPSHOTS}>
            <ExportAsCsv
              headers={SNAPSHOT_CSV_TABLE_HEADERS}
              data={prepareSnapshotDataForCsv(snapshotsList)}
              filename="snapshots-miscofigurations.csv"
              shouldShow={snapshotsList.length > 0}
            />
            <VirtualTable
              config={{
                data: snapshotsList,
                columns: snapshotsColumns,
                state: {
                  isLoading,
                },
              }}
              emptyPlaceholderText="No EBS Snapshots found"
            />
          </MaterialTab>
        </div>
      </div>
    </div>
  )
}

export default AssetsWithVulnerabilities
