import React from 'react'
import { TabVariant } from './types'
import { VARIANTS_ICONS } from './consts'

export function renderIconByVariant({
  variant,
  selected,
}: {
  variant: TabVariant
  selected: boolean
}) {
  return React.createElement(VARIANTS_ICONS[variant], {
    selected,
  })
}
