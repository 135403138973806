/* eslint-disable import/no-extraneous-dependencies */
import { ListThreatsForAssetClient } from '@lib/clients/threats/list-threats-for-asset'
import { EC2, Threat, AssetItem, Asset, OvaServer } from 'blues-corejs/dist'
import { useState, useEffect } from 'react'
import { ThreatStatus } from 'blues-corejs/dist/models/threats/types'

const threatsClient = new ListThreatsForAssetClient()

async function getThreatForInstance(
  instance: EC2,
  statusList?: Array<ThreatStatus>
): Promise<Array<Threat>> {
  const attachedVolumesThreats = await Promise.all(
    instance.ebsIds.map((ebsId) =>
      threatsClient.listThreatsForAsset({
        assetId: ebsId,
        statusList: statusList,
      })
    )
  )

  return attachedVolumesThreats.flatMap(({ threatsList }) => threatsList)
}

async function getThreatsForOvaServer(
  server: OvaServer,
  statusList?: Array<ThreatStatus>
): Promise<Array<Threat>> {
  const response = await threatsClient.listThreatsForAsset({
    assetId: server.id,
    includeAssetItems: true,
    statusList: statusList,
  })

  return response.threatsList
}

async function getThreatsForAsset(
  asset: Asset | AssetItem,
  statusList?: Array<ThreatStatus>
): Promise<Array<Threat>> {
  if (asset instanceof EC2) {
    return getThreatForInstance(asset, statusList)
  }

  if (asset instanceof OvaServer) {
    return getThreatsForOvaServer(asset, statusList)
  }

  const response = await threatsClient.listThreatsForAsset({
    assetId: asset.id,
    statusList: statusList,
  })

  return response.threatsList
}

export function useFetchThreatsForAsset(
  asset?: Asset | AssetItem,
  statusList?: Array<ThreatStatus>
) {
  const [threats, setThreats] = useState<Array<Threat>>([])

  const [isLoadingThreats, setIsLoadingThreats] = useState(false)

  const fetchDataWithoutLoader = async () => {
    if (!asset) {
      return
    }
    try {
      const threatsResponse = await getThreatsForAsset(asset, statusList)
      setThreats(threatsResponse)
    } catch (error) {
      console.error(
        `useFetchThreatsForAsset: Cannot fetch threats for asset: ${asset.id}`
      )
    }
  }

  useEffect(() => {
    if (!asset) {
      return
    }
    const fetchDataThreats = async () => {
      setIsLoadingThreats(true)
      try {
        const threatsResponse = await getThreatsForAsset(asset, statusList)
        setThreats(threatsResponse)
      } catch (error) {
        console.error(
          `useFetchThreatsForAsset: Cannot fetch threats for asset: ${asset.id}`
        )
      } finally {
        setIsLoadingThreats(false)
      }
    }

    fetchDataThreats()
  }, [asset?.id])

  return {
    isLoadingThreats,
    threats,
    fetchDataWithoutLoader,
  }
}
