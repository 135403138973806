/* eslint-disable import/no-extraneous-dependencies */
import { EC2_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { EBS, EC2, EC2State, Threat } from 'blues-corejs/dist'

export abstract class AssetsEc2Base {
  protected abstract readonly instances: Array<EC2>

  protected abstract readonly volumes: Map<string, EBS>

  protected abstract readonly threats: Array<Threat>

  protected convertEc2State(
    state: EC2State
  ): (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP] {
    return EC2_STATE_MAP[state]
  }

  protected isUnhealthyInstance(instance: EC2): boolean {
    const attachedVolumes = this.getAttachedVolumes(instance)

    if (instance.state === EC2State.TERMINATED) {
      return false
    }

    return attachedVolumes.some((volume) =>
      this.threats.some(
        (threat) =>
          threat.source.asset?.assetId === volume.id ||
          threat.source.assetItem?.assetId === volume.id
      )
    )
  }

  protected getAttachedVolumes(instance: EC2): Array<EBS> {
    return instance.ebsIds
      .map((id) => this.volumes.get(id))
      .filter(Boolean) as Array<EBS>
  }
}
