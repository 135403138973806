import { AssetsOvaServerTableAttrs, Server } from 'ui-v2/src/lib/models/assets'
import { OvaServerTableRow } from 'ui-v2/src/lib/models/assets'
import { AssetsOvaServerBase } from './ova-server-base'

export class AssetsOvaServerTableData extends AssetsOvaServerBase {
  readonly #servers: Array<Server>

  readonly ovaAccountsMap: Map<string, string>

  constructor(parameters: AssetsOvaServerTableAttrs) {
    super()
    this.#servers = parameters.servers
    this.ovaAccountsMap = parameters.ovaAccounts.reduce((map, ovaAccount) => {
      map.set(ovaAccount.id, ovaAccount.name)
      return map
    }, new Map<string, string>())
  }

  getOvaServerTableData() {
    return this.#servers.map(
      (server) =>
        new OvaServerTableRow({
          id: server.server.id,
          name: server.server.name,
          ovaAccountId:
            this.ovaAccountsMap.get(server.server.ovaAccountId) ?? '',
          backupProvider: this.convertBackupProvider(
            server.server.backupProvider
          ),
          lastCleanBackupDate: server.lastCleanBackup?.createdAt,
          isUnhealthy: server.hasMalware || server.hasRansomware,
        })
    )
  }
}
