/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EFS } from 'blues-corejs/dist'
import { computeEfsState } from './compute-efs-state'
import { RowTitle } from '../row-title'

export function FilesystemState({ asset }: { asset: EFS }) {
  const computedState = computeEfsState(asset)

  return (
    <Grid container item xs={4}>
      <Grid item>
        <RowTitle title="State" />
      </Grid>
      <Grid item>
        <Typography fontSize="14px" fontWeight="400" variant="caption">
          {computedState}
        </Typography>
      </Grid>
    </Grid>
  )
}
