import { useMutation } from '@tanstack/react-query'
import { CreateReportScheduleCriteria } from 'ui-v2/src/lib/clients/reports/schedule/create-report-schedule'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { ReportSchedulesClient } from 'ui-v2/src/lib/clients/reports/report-schedules-client'

export function useCreateReportSchedule() {
  const reportScheduleClient = useGrpcClient(ReportSchedulesClient)

  return useMutation({
    mutationFn: async (data: CreateReportScheduleCriteria) => {
      if (!reportScheduleClient) {
        throw new Error('Report schedule client not initialized')
      }

      return reportScheduleClient.createReportSchedule(data)
    },
  })
}
