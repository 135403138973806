/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { OvaBackup } from 'blues-corejs/dist/models'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'

export default function computeOvaBackupProvider(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return 'Cohesity'
    case OvaBackupProvider.VEEAM:
      return 'Veeam'
    case OvaBackupProvider.COMMVAULT:
      return 'Commvault'
    case OvaBackupProvider.VERITAS_NET_BACKUP:
      return 'VeritasNetBackup'
    case OvaBackupProvider.RUBRIK:
      return 'Rubrik'
    case OvaBackupProvider.AWS_BACKUP_VMWARE:
      return 'AwsBackupVmware'
    default:
      SystemHelper.sendSentryIfProd(
        `computeOvaBackupProvider: Unknown OvaBackup provider: ${backup.provider}`
      )
      return null
  }
}
