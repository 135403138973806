import React, { memo } from 'react'
import Popover from '@mui/material/Popover'
import { IconButton } from '@mui/material'
import InfoIconFilled from '@inline-img/general/info-icon-filled'
import SystemHelper from '@lib/helpers/system.helper'

export type IconVariant = 'info'

function getIcon(variant: IconVariant, color: string | undefined) {
  switch (variant) {
    case 'info':
      return InfoIconFilled({ fill: color ?? '#32B6F3' })
    default:
      SystemHelper.throwErrorInLocalEnv('Wrong icon variant')
      return null
  }
}

interface Props {
  variant: IconVariant
  header?: string
  message: string
  color?: string
  buttonClassName?: string
  popoverClassName?: string
}

function IconPopover({
  variant,
  header,
  message,
  color,
  buttonClassName = 'iconPopoverButton',
  popoverClassName = 'iconPopoverBody',
}: Props) {
  const IconComponent = getIcon(variant, color)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'icon-popover' : undefined

  return (
    <div
      data-testid="jsIconPopover"
      className="wrap-1652710069925 jsIconPopover"
    >
      {
        <>
          <IconButton
            aria-describedby={id}
            disableRipple
            className={buttonClassName}
            onClick={handleClick}
            data-testid="jsIconPopoverButton"
          >
            {IconComponent}
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            className="iconPopover"
          >
            <div className={popoverClassName}>
              {IconComponent}
              <div className="iconPopoverText">
                {header && <h3>{header}</h3>}
                <p>{message}</p>
              </div>
            </div>
          </Popover>
        </>
      }
    </div>
  )
}

export default memo(IconPopover)
