/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment } from 'react'
import { CloudConnectorJobStatusChip } from '@features/jobs/cloud-connector-jobs/shared'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import {
  useModalFilterActions,
  useSelectedModalFilters,
} from '../cloud-connector-filters-store'

export function SelectedJobStatusesFilters() {
  const { statusesList } = useSelectedModalFilters()

  const { setFilter } = useModalFilterActions()

  if (statusesList.size === 0) {
    return null
  }

  function handleRemoveFilter(kind: CloudConnectorJobStatus) {
    const newStatusesList = new Set(statusesList)

    newStatusesList.delete(kind)

    setFilter('statusesList', newStatusesList)
  }

  return (
    <Fragment>
      {Array.from(statusesList).map((status) => (
        <CloudConnectorJobStatusChip
          onDelete={handleRemoveFilter}
          key={status}
          status={status}
        />
      ))}
    </Fragment>
  )
}
