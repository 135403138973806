/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, GenericHost, OvaServer } from 'blues-corejs/dist'
import { CellWithLink } from '@components/table'
import { AssetRoutes } from '@lib/router'

function extractAssetNameData(asset: Asset) {
  if (asset instanceof OvaServer || asset instanceof GenericHost) {
    return
  }

  const value = asset.name || asset.awsId

  const label = asset.name ? asset.awsId : asset.name

  return {
    value,
    label,
  }
}

export function AssetNameCell({ asset }: { asset: Asset }) {
  const assetNameData = extractAssetNameData(asset)

  if (!assetNameData) {
    return null
  }

  const assetPageLink = AssetRoutes.assetId(asset.id)

  return (
    <CellWithLink
      href={assetPageLink}
      value={assetNameData.value}
      label={assetNameData.label}
      textToCopy={assetNameData.label}
    />
  )
}
