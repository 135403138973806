import React, { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import RoutesRenderer from './routes-renderer'
import NavigationProvider from './contexts/navigation-context'
import theme from '@lib/theme/theme'
import { ThemeProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'

/**
 * This component computes the current routing strategy based on feature flags
 * for SPA or SSR.
 * We ensure a unified interface for working with imperative routing, regardless
 * of whether the app is in SPA or SSR mode.
 */

interface RoutingStrategyProviderProps {
  children: ReactNode
}

const RoutingStrategyProvider: React.FC<RoutingStrategyProviderProps> = ({
  children,
}) => {
  return (
    <BrowserRouter>
      <NavigationProvider>
        <ThemeProvider theme={theme}>
          <RoutesRenderer />
          <ToastContainer />
          {children}
        </ThemeProvider>
      </NavigationProvider>
    </BrowserRouter>
  )
}

export default RoutingStrategyProvider
