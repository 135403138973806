import { useListUsersQuery } from 'ui-v2/src/hooks/queries/users'
import { useMemo, useState } from 'react'
import { getRowActions, getUserColumns, UserAction } from './column-config'
import OffsetTable from 'ui-v2/src/components/ui/data-table/offset-pagination-table'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import AddUserDialog from './add-user'
import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  SETTINGS_ROUTE_SEGMENTS,
  USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import UserActionConfirmation from './action-confirmation'

const Users = () => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [offset, setOffset] = useState(0)
  const [action, setAction] = useState<UserAction | undefined>(undefined)

  const navigate = useNavigate()

  const { data, isLoading } = useListUsersQuery({
    limit: pageSize,
    offset: offset,
  })

  const columns = useMemo(getUserColumns, [])

  const openAddUserDialog = () => {
    navigate(
      `/settings/${SETTINGS_ROUTE_SEGMENTS.USERS_AND_ACCESS}/${USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS.USERS}/add-user`
    )
  }

  return (
    <>
      <Stack direction={'row'} justifyContent={'end'} mb={1}>
        <Button
          onClick={openAddUserDialog}
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'capitalize',
            width: '6rem',
          }}
        >
          Add User
        </Button>
      </Stack>
      <OffsetTable
        data={data?.userProfilesList}
        columns={columns}
        isLoading={isLoading}
        pageSize={pageSize}
        offset={offset}
        setPageSize={setPageSize}
        setOffset={setOffset}
        totalCount={data?.totalCount ?? -1}
        rowActions={(row) => getRowActions(row, setAction)}
      />
      <AddUserDialog />
      <UserActionConfirmation action={action} setAction={setAction} />
    </>
  )
}

export default Users
