import React, { memo } from 'react'
import InfoIcon from '@inline-img/general/info-icon'

function DeployInformationBlock() {
  return (
    <div>
      <div className="depInfoBlock">
        <div className="depPlateBlockTitle">
          <span>
            <InfoIcon />
          </span>
          <span className="depPlateBlockTitleBold">
            Where can I find my AWS account ID?
          </span>
        </div>
        <ul>
          <li>
            <span>
              If you are already logged into your AWS console, you can view your
              AWS account ID in the profile dropdown on the top right of the
              screen.{' '}
            </span>
            <a
              href="https://aws.amazon.com/console/"
              rel="noreferrer"
              target="_blank"
              className="buttonLikeLink"
            >
              Visit AWS Console
            </a>
          </li>
        </ul>
      </div>
      <div className="depInfoBlock">
        <div className="depPlateBlockTitle">
          <span>
            <InfoIcon />
          </span>
          <span className="depPlateBlockTitleBold">
            Are there any requirements for the target environment?
          </span>
        </div>
        <ul>
          <li>
            <span>
              Elastio deploy consists of several parts, including the
              account-level CloudFormation stack and regional Cloud Connectors.
              For a smooth deploy setup your environment as explained
            </span>
            <a
              href="https://support.elastio.com/hc/en-us/articles/22608750175506-Deployment-Requirements"
              rel="noreferrer"
              target="_blank"
              className="buttonLikeLink"
            >
              {' '}
              here.
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default memo(DeployInformationBlock)
