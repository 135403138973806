import { useState, useEffect } from 'react'
import { useAccessToken, useUser } from '@features/authentication'
import { useAuth0 } from '@auth0/auth0-react'

// TODO We need this file. We are waiting for our backend to add an endpoint for retrieving the user sessions.
interface UserSession {
  id: string
  description: string
  [key: string]: any
}

interface UseUserSessionsResult {
  sessions: any
  isLoading: boolean
  error: string | undefined
}

function useUserSessions(): UseUserSessionsResult {
  const accessToken = useAccessToken()
  const user = useUser()
  const { getIdTokenClaims } = useAuth0()
  const [sessions, setSessions] = useState<Array<UserSession>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setIsLoading(true)
        setError(undefined)

        const claims = await getIdTokenClaims()
        if (!claims || !accessToken) {
          return
        }

        const response = await fetch(
          `${claims.iss}api/v2/users/gmKBcLLJ7iNLj1VXBe1IOiwktpBaOAbu/sessions`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        // zod here
        // @ts-ignore
        setSessions(response)
      } catch (err: any) {
        setError(
          err.response?.data?.message ||
            'Failed to fetch user sessions. Please try again later.'
        )
      } finally {
        setIsLoading(false)
      }
    }

    if (user) {
      fetchSessions()
    }
  }, [accessToken])

  return {
    sessions,
    isLoading,
    error,
  }
}

export { useUserSessions }
