/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import CircleStatusIcon from '@inline-img/general/circle-status-icon'
import { STATUS_TO_STYLE_VARIANT_MAPPING } from './consts'
import { JobStatusIndicator } from './job-status-indicator'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

function mapCloudConnectorJobStatusToStyleVariant(
  status: CloudConnectorJobStatus
) {
  return STATUS_TO_STYLE_VARIANT_MAPPING[status]
}

export function JobStatusCell({ status }: { status: CloudConnectorJobStatus }) {
  return (
    <JobStatusIndicator
      className={mapCloudConnectorJobStatusToStyleVariant(status)}
    >
      <CircleStatusIcon />
    </JobStatusIndicator>
  )
}
