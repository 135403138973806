"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetKind = exports.AssetItemKind = exports.ScanTargetKind = exports.IscanReportStatus = exports.IscanStatus = void 0;
var IscanStatus;
(function (IscanStatus) {
    IscanStatus[IscanStatus["NONE"] = 0] = "NONE";
    IscanStatus[IscanStatus["OK"] = 1] = "OK";
    IscanStatus[IscanStatus["MALWARE"] = 2] = "MALWARE";
    IscanStatus[IscanStatus["RANSOMWARE"] = 3] = "RANSOMWARE";
    IscanStatus[IscanStatus["MALWARE_AND_RANSOMWARE"] = 4] = "MALWARE_AND_RANSOMWARE";
})(IscanStatus || (exports.IscanStatus = IscanStatus = {}));
var IscanReportStatus;
(function (IscanReportStatus) {
    IscanReportStatus[IscanReportStatus["CLEAN"] = 0] = "CLEAN";
    IscanReportStatus[IscanReportStatus["INFECTED"] = 1] = "INFECTED";
})(IscanReportStatus || (exports.IscanReportStatus = IscanReportStatus = {}));
var ScanTargetKind;
(function (ScanTargetKind) {
    ScanTargetKind[ScanTargetKind["BACKUP"] = 0] = "BACKUP";
    ScanTargetKind[ScanTargetKind["DIRECT_SCAN"] = 1] = "DIRECT_SCAN";
})(ScanTargetKind || (exports.ScanTargetKind = ScanTargetKind = {}));
var AssetItemKind;
(function (AssetItemKind) {
    AssetItemKind[AssetItemKind["FILE"] = 0] = "FILE";
    AssetItemKind[AssetItemKind["STREAM"] = 1] = "STREAM";
    AssetItemKind[AssetItemKind["LOCAL_VOLUME"] = 2] = "LOCAL_VOLUME";
    AssetItemKind[AssetItemKind["DISK"] = 3] = "DISK";
})(AssetItemKind || (exports.AssetItemKind = AssetItemKind = {}));
var AssetKind;
(function (AssetKind) {
    AssetKind[AssetKind["UNDEFINED"] = -1] = "UNDEFINED";
    AssetKind[AssetKind["AWS_EC2"] = 0] = "AWS_EC2";
    AssetKind[AssetKind["AWS_EBS"] = 1] = "AWS_EBS";
    AssetKind[AssetKind["AWS_EFS"] = 2] = "AWS_EFS";
    AssetKind[AssetKind["GENERIC_HOST"] = 3] = "GENERIC_HOST";
    AssetKind[AssetKind["AWS_S3"] = 4] = "AWS_S3";
})(AssetKind || (exports.AssetKind = AssetKind = {}));
