/* eslint-disable import/no-extraneous-dependencies */
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_s3_items_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { S3ItemTransformer } from '@lib/clients/assets/transformers'
import { ListS3ItemsRequestFilters } from '@lib/clients/assets-items/types'
import { Request as PaginationPB } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { Nullable } from '@lib/engine-types'
import { Pagination } from '../types'
import { S3Item } from 'blues-corejs/dist'

export interface ListS3ItemsResponse {
  items: Array<S3Item>
}

export class ListS3ItemsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListS3ItemsClient'
  }

  #buildFilter(filters: ListS3ItemsRequestFilters) {
    const filter = new Request.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length > 0) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.itemIdList) {
      filter.setItemIdList(filters.itemIdList)
    }

    if (filters.itemNameList) {
      filter.setItemNameList(filters.itemNameList)
    }

    if (filters.retentionPolicyNameList) {
      filter.setRetentionPolicyNameList(filters.retentionPolicyNameList)
    }

    return filter
  }

  #setupPagination(request: Request, pageToken: Nullable<string>) {
    return (
      pageToken &&
      request.setPagination(new PaginationPB().setPageToken(pageToken))
    )
  }

  async listS3Items(
    filters?: ListS3ItemsRequestFilters & Pagination
  ): Promise<ListS3ItemsResponse & Pagination> {
    const filter = this.#buildFilter(filters as ListS3ItemsRequestFilters)

    const initial = new Request.Initial()
      .setPageSize(this.pageSize)
      .setFilter(filter)

    const requestInstance = new Request().setInitial(initial)

    this.#setupPagination(requestInstance, filters?.pageToken)

    const response = (
      await this.retryGrpcCall(
        () => this.#assetsClient.listS3Items(requestInstance, this.metadata()),
        {
          requestName: 'AssetsPromiseClient/listS3Items',
        }
      )
    ).toObject()

    return {
      items: this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformResponse(response: Response.AsObject): Array<S3Item> {
    return response.itemsList.map((item) =>
      new S3ItemTransformer(item).transform()
    )
  }
}
