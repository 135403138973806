import React, { memo } from 'react'
import LinkMUI from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import { CellWithLinkOwnProps } from './types'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { CopyToClipboardIcon } from '@features/common'
import Stack from '@mui/material/Stack'
import Link from '@lib/router/navigation-strategies/link'

const LinkMUIStyled = styled(LinkMUI)`
  color: var(--blue500);
  font-weight: var(--font-weight-semi-bold);
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;
`

function CellWithLink({
  href,
  label,
  className = 'idName',
  value,
  children,
  textToCopy,
}: CellWithLinkOwnProps) {
  if (!label) {
    return (
      <Link to={href}>
        <LinkMUIStyled>{value || children}</LinkMUIStyled>
      </Link>
    )
  }

  return (
    <>
      <Link to={href}>
        <LinkMUIStyled>{value || children}</LinkMUIStyled>
      </Link>
      <Stack direction="row" alignItems="center">
        <LongTextTooltip customClass={className} text={label} maxLength={25} />
        {!!textToCopy && (
          <CopyToClipboardIcon text={textToCopy} fontSize={12} />
        )}
      </Stack>
    </>
  )
}

export default memo(CellWithLink)
