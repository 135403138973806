import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'
import AvailabilityZoneModel from './availability-zone.model'
import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'

abstract class AvailabilityZoneFactory {
  public static buildFromGrpc(params: any): AvailabilityZoneModel {
    return new AvailabilityZoneModel({
      id: numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      azId: TypeHelper.stringValDef(params.id, ''),
      azName: TypeHelper.stringValDef(params.name, ''),
    })
  }

  public static buildEmpty() {
    return new AvailabilityZoneModel({
      id: '',
      innerId: '',
      azId: '',
      azName: '',
    })
  }
}

export default AvailabilityZoneFactory
