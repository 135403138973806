/* eslint-disable import/no-extraneous-dependencies */
import {
  COVERAGE_CARD_SLUG,
  STAT_CARD_SLUG,
} from 'ui-v2/src/lib/constants/stats.constant'
import { Nullable } from 'blues-corejs/dist'
import {
  SnapshotVulnerabilityKind,
  VolumeVulnerabilityKind,
} from 'blues-corejs/dist/models/vulnerability'

export interface AwsStatsRequestParams {
  regions?: Array<string>
  accountIds?: Array<string>
  includeTags?: SliceCriteriaTags
  excludeTags?: SliceCriteriaTags
}

export interface SliceCriteriaTags {
  tags: Array<SliceCriteriaTag>
  operator: SliceCriteriaTagsCombinationOperator
}

export interface SliceCriteriaTag {
  key: string
  value: Nullable<string>
}

export enum SliceCriteriaTagsCombinationOperator {
  AND = 0,
  OR = 1,
}

export enum ResponsePage {
  EC2INSTANCES = 0,
  EBSVOLUMES = 1,
  S3BUCKETS = 2,
  EFS = 3,
}

export interface AwsThreatStats {
  malwareThreatsCount: number
  ransomwareThreatsCount: number
  corruptedFilesystemsCount: number
  malwarePageToGo: ResponsePage
  ransomwarePageToGo: ResponsePage
  corruptedFilesystemsPageToGo: ResponsePage
}

export interface AwsThreatExposureStatsResponse {
  totalAssetsCount: number
  assetsWithThreatsCount: number
  regionsWithThreatsCount: number
  accountsWithThreatsCount: number
}

export type StatCardSlugValues =
  (typeof STAT_CARD_SLUG)[keyof typeof STAT_CARD_SLUG]

export type CoverageCardSlugValues =
  (typeof COVERAGE_CARD_SLUG)[keyof typeof COVERAGE_CARD_SLUG]

export interface NormalizedStatCardData {
  slug: StatCardSlugValues
  count: number
  link: string
}
export interface NormalizedCoverageCardData {
  slug: CoverageCardSlugValues
  count: number
  totalCount: number
  link: string
}

export interface AwsFileScansStatsResponse {
  infectedFiles: number
  cleanFiles: number
}
interface ScanCoverage {
  total: number
  covered: number
}
export interface AwsScanCoverageResponse {
  ec2?: ScanCoverage
  ebs?: ScanCoverage
  s3?: ScanCoverage
  efs?: ScanCoverage
}

interface BackupCoverage {
  total: number
  covered: number
}

export interface AwsBackupCoverageResponse {
  ec2?: BackupCoverage
  ebs?: BackupCoverage
}

export enum Percentile {
  PERCENT_20 = 20,
  PERCENT_40 = 40,
  PERCENT_60 = 60,
  PERCENT_80 = 80,
  PERCENT_100 = 100,
}

export interface AWSStatsQuantile {
  age?: number
  percentile: Percentile
}

export interface AwsRpoStats {
  backupsOutOfSchedule: number
  rpoStatsQuantilesList: Array<AWSStatsQuantile>
  lowestRpo?: number
  highestRpo?: number
}

export interface AwsRetentionStats {
  rpoStatsQuantilesList: Array<AWSStatsQuantile>
  averageRetention?: number
  bottomQuartile?: number
  topQuartile?: number
}

export interface AwsRpoAndRetentionStatsResponse {
  rpoStats?: AwsRpoStats
  retentionStats?: AwsRetentionStats
}

export interface AwsSnapshotVulnerability {
  kind: SnapshotVulnerabilityKind
  total: number
}

export interface AwsVolumeVulnerability {
  kind: VolumeVulnerabilityKind
  total: number
}

export interface AwsStorageMisconfigurationsStatsResponse {
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
}
