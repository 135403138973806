import React from 'react'
import Stack from '@mui/material/Stack'
import EditableTags from '@components-composite/editable-tags/EditableTags'
import {
  useToolbarFiltersActions,
  useToolbarFiltersState,
} from '../toolbar-filters-store'

function handleThreatRemove(threatName: string, threatNames: Set<string>) {
  const newThreatNames = new Set(threatNames)

  newThreatNames.delete(threatName)

  return Array.from(newThreatNames)
}

export function ThreatsFiltersChips() {
  const { ransomwareThreatNames, malwareThreatNames } = useToolbarFiltersState()

  const { setRansomwareThreatNames, setMalwareThreatNames } =
    useToolbarFiltersActions()

  const handleMalwareThreatRemove = (threatName: string) => {
    setMalwareThreatNames(handleThreatRemove(threatName, malwareThreatNames))
  }

  const handleRansomwareThreatRemove = (threatName: string) => {
    setRansomwareThreatNames(
      handleThreatRemove(threatName, ransomwareThreatNames)
    )
  }

  if (ransomwareThreatNames.size === 0 && malwareThreatNames.size === 0) {
    return null
  }

  return (
    <Stack direction="row" py={1} gap="16px">
      <EditableTags
        title="Ransomware"
        tags={Array.from(ransomwareThreatNames)}
        onRemoveTag={handleRansomwareThreatRemove}
      />
      <EditableTags
        title="Malware"
        tags={Array.from(malwareThreatNames)}
        onRemoveTag={handleMalwareThreatRemove}
      />
    </Stack>
  )
}
