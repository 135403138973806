/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  EntropyTrendSelectionFilter,
  EntropyTrendsFilter,
} from '@features/entropy-trends/components/entropy-trends-filters/generic'
import {
  useGetEntropyFiltersLoading,
  useGetPossibleFilters,
  useGetSelectedFilters,
  useEntropyFiltersActions,
} from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'
import type { EntropySelectionFilter } from '../../types'
import { CloudConnectorAccountFilterLabel } from './cc-account-label'
import { CloudConnector } from 'blues-corejs/dist'
import { useGetCloudConnectorList } from '@lib/hooks/api-hooks/rex'
import {
  FilterName,
  FILTER_NAMES,
} from '@features/entropy-trends/components/entropy-trends-filters/composable/consts'

function buildCloudConnectorAccountFilters(
  cloudConnectorList: Array<CloudConnector>
): Array<EntropySelectionFilter<string>> {
  const uniqueAccounts = cloudConnectorList.reduce(
    (acc: Array<CloudConnector>, cc) => {
      if (!acc.find((a) => a.awsAccountId === cc.awsAccountId)) {
        acc.push(cc)
      }
      return acc
    },
    []
  )

  return uniqueAccounts.map((cc) => ({
    value: cc.awsAccountId,
    label: <CloudConnectorAccountFilterLabel cloudConnector={cc} />,
    searchFilterCriteria: (searchValue: string) =>
      cc.accountAlias.toLowerCase().includes(searchValue.toLowerCase()) ||
      cc.awsAccountId.toLowerCase().includes(searchValue.toLowerCase()),
  }))
}

interface Props {
  isExpanded: boolean
  onFilterClick: (filterName: FilterName) => void
}

export function CloudConnectorAccountSelectionFilter({
  isExpanded,
  onFilterClick,
}: Props) {
  const { accountsList } = useGetPossibleFilters()
  const { selectedAccounts } = useGetSelectedFilters()
  const { setFilters } = useEntropyFiltersActions()
  const { cloudConnectorList, isLoading: isCloudConnectorsListLoading } =
    useGetCloudConnectorList()

  const isFiltersLoading = useGetEntropyFiltersLoading()
  const options = buildCloudConnectorAccountFilters(
    cloudConnectorList.filter((cc) => accountsList.includes(cc.awsAccountId))
  )

  const isLoading = isCloudConnectorsListLoading || isFiltersLoading

  const handleFilterChange = (selectedOptions: Set<string>) => {
    setFilters({ selectedAccounts: selectedOptions })
  }

  return (
    <EntropyTrendsFilter
      filterName={FILTER_NAMES.ACCOUNT}
      isLoading={isLoading}
      isExpanded={isExpanded}
      onAccordionClick={() => onFilterClick(FILTER_NAMES.ACCOUNT)}
    >
      <EntropyTrendSelectionFilter
        options={options}
        selectedOptions={selectedAccounts}
        onSelectionChange={handleFilterChange}
      />
    </EntropyTrendsFilter>
  )
}
