"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scanFilesystemCheckFactory = void 0;
exports.scanTarget = scanTarget;
const fishery_1 = require("fishery");
const filesystem_check_1 = require("../../models/scans/filesystem-check");
const factories_1 = require("../../use_cases/tests/factories");
const types_1 = require("../../models/scans/filesystem-check/types");
const common_1 = require("./common");
function scanTarget() {
    return new filesystem_check_1.FilesystemScanTarget({ assetId: (0, factories_1.uuid)() });
}
class ScanFilesystemCheckFactory extends fishery_1.Factory {
    withScanTarget(scanTarget) {
        return this.transient({
            scanTarget,
        });
    }
}
const status = () => (0, common_1.randomValueFromEnum)(types_1.StatusScanFilesystemCheck);
const partition = () => ({
    name: (0, factories_1.uuid)(),
    errorMessage: `Error message: ${Math.random() * 100 + 1}`,
    filesystem: (0, factories_1.uuid)(),
    checkStatus: (0, common_1.randomValueFromEnum)(types_1.CheckStatus),
});
const partitions = new Array(10).fill(partition);
const threatsIds = new Array(10).fill(factories_1.uuid);
exports.scanFilesystemCheckFactory = ScanFilesystemCheckFactory.define(({ transientParams }) => {
    return new filesystem_check_1.FilesystemScanCheck({
        id: transientParams.id ?? (0, factories_1.uuid)(),
        scanTarget: transientParams.scanTarget ?? scanTarget(),
        status: transientParams.status ?? status(),
        jobId: transientParams.jobId ?? (0, factories_1.uuid)(),
        finishedAt: transientParams.finishedAt ?? (0, common_1.date)(),
        createdAt: transientParams.createdAt ?? (0, common_1.date)(),
        updatedAt: transientParams.updatedAt ?? (0, common_1.date)(),
        partitions: transientParams.partitions ?? partitions,
        threatIds: transientParams.threatIds ?? threatsIds,
    });
});
