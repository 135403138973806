import React from 'react'
import { Button, Dialog } from '@mui/material'
import {
  DialogPaper,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from './styles'
import {
  useIsModalFilterDialogOpen,
  useModalFilterActions,
  useSelectedModalFilters,
  useTotalSelectedFilters,
} from './cloud-connector-filters-store'
import { SelectedFilters } from './selected-filters'
import { FiltersSelection } from './filters-selection'
import { useCloudConnectorJobsFiltersActions } from '../stores'

export function FiltersModal() {
  const selectedFilters = useSelectedModalFilters()

  const isFilterDialogOpen = useIsModalFilterDialogOpen()

  const totalSelectedFilters = useTotalSelectedFilters()

  const { setFilters } = useCloudConnectorJobsFiltersActions()

  const { resetModalFiltersStore, closeFilterDialog } = useModalFilterActions()

  function handleSaveFilters() {
    setFilters(selectedFilters)

    resetModalFiltersStore()
  }

  return (
    <Dialog
      open={isFilterDialogOpen}
      onClose={closeFilterDialog}
      PaperComponent={DialogPaper}
    >
      <StyledDialogTitle>Select Jobs with Filters</StyledDialogTitle>
      <StyledDialogContent>
        <SelectedFilters />
        <FiltersSelection />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={resetModalFiltersStore}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveFilters}>
          Save filters {totalSelectedFilters}
        </Button>
      </StyledDialogActions>
    </Dialog>
  )
}
