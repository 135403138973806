/* eslint-disable import/no-extraneous-dependencies */
import { Threat } from 'blues-corejs/dist'
import { ThreatsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/threats/list_threats_for_asset_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { ThreatTransformer } from '../assets/transformers'
import { Nullable } from '@lib/engine-types'

export interface ListThreatsForAsset {
  threatsList: Array<Threat>
}

export class ListThreatsForAssetClient extends GrpcClient<ThreatsPromiseClient> {
  #threatsClient: ThreatsPromiseClient

  constructor(hostName = '') {
    super()
    this.#threatsClient = this.getClient(hostName)
  }

  get client(): ThreatsPromiseClient {
    return this.#threatsClient
  }

  async listThreatsForAsset({
    assetId,
    includeAssetItems = false,
    statusList,
  }: {
    assetId: string
    includeAssetItems?: boolean
    statusList?: Array<number>
  }): Promise<ListThreatsForAsset> {
    const filter = new Request.Initial.Filter()

    filter.setIncludeAssetItems(includeAssetItems)

    if (statusList) {
      filter.setStatusList(statusList)
    }

    const initial = new Request.Initial()
      .setPageSize(this.pageSize)
      .setFilter(filter.setAssetIdList([assetId]))
    const request = new Request().setInitial(initial)

    const response = (
      await this.retryGrpcCall(
        () => this.#threatsClient.listThreatsForAsset(request, this.metadata()),
        {
          requestName: 'ThreatsPromiseClient/listThreatsForAsset',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  async listThreats(
    assetId: Nullable<string>,
    threatIds: Array<string>
  ): Promise<ListThreatsForAsset> {
    const filter = new Request.Initial.Filter()
    filter.setThreatIdsList(threatIds)
    if (assetId) {
      filter.setAssetIdList([assetId])
    }
    const initial = new Request.Initial().setPageSize(1000).setFilter(filter)
    const request = new Request().setInitial(initial)

    const response = (
      await this.retryGrpcCall(
        () => this.#threatsClient.listThreatsForAsset(request, this.metadata()),
        {
          requestName: 'ThreatsPromiseClient/listThreatsForAsset',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #transformResponse(response: Response.AsObject): ListThreatsForAsset {
    return {
      threatsList: response.threatsList.map((threat) =>
        new ThreatTransformer(threat).transform()
      ),
    }
  }

  protected innerClientTypeId(): string {
    return 'ListThreatsForAssetClient'
  }

  initClient(hostName = ''): ThreatsPromiseClient {
    return new ThreatsPromiseClient(hostName, null, null)
  }
}
