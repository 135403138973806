/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  ElastioRecoveryPoint,
  Backup,
} from 'blues-corejs/dist'
import { computeAwsRecoveryPointKind } from './aws-rp-utils/compute-aws-rp-kind'
import { computeElastioRecoveryPointKind } from './elastio-rp-utils/compute-elastio-rp-kind'

export function computeBackupKind(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return computeElastioRecoveryPointKind(backup)
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return computeAwsRecoveryPointKind(backup)
  }

  return null
}
