import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { Sources, SourceId } from '@features'
import { SourcesRoutes } from './sources'
import SourceVaultsComplex from '@components-complex/source-vaults-complex/SourceVaultsComplex'

export const sourcesRoutes: Array<PathRouteProps> = [
  {
    path: SourcesRoutes.root,
    element: (
      <MainTopBarLayout title="Sources">
        <Sources />
      </MainTopBarLayout>
    ),
  },
  {
    path: SourcesRoutes.withIdPlaceholder,
    element: (
      <MainTopBarLayout title="">
        <SourceId />
      </MainTopBarLayout>
    ),
  },
  {
    path: SourcesRoutes.sourceVaultWithIdPlaceholder,
    element: (
      <MainTopBarLayout title="">
        <div className="innerContent innerContentBlueBackground">
          <SourceVaultsComplex />
        </div>
      </MainTopBarLayout>
    ),
  },
]
