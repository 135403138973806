import { S3SelectorsConstant } from '@lib/constants/s3-selectors.constant'
import { VIRow } from '@lib/engine-types'
import FormAddSpecificPathsInterface from '@lib/interfaces/form/form-add-specific-paths.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const pathSelectors: VIRow = [
  {
    value: S3SelectorsConstant.PATH,
    label: 'Path',
    name: 'path',
  },
  {
    value: S3SelectorsConstant.PREFIX,
    label: 'Prefix',
    name: 'prefix',
  },
  {
    value: S3SelectorsConstant.GLOB,
    label: 'Glob',
    name: 'glob',
  },
]

const pathSelectorsForEfs: VIRow = [
  {
    value: S3SelectorsConstant.PATH,
    label: 'Path',
    name: 'path',
  },
  {
    value: S3SelectorsConstant.GLOB,
    label: 'Glob',
    name: 'glob',
  },
]

const buildDefaultFormData = (
  isShowPrefix: boolean
): Record<keyof FormAddSpecificPathsInterface, FormFieldInterface> => ({
  pathSelector: {
    name: 'pathSelector',
    options: isShowPrefix ? pathSelectors : pathSelectorsForEfs,
    value: isShowPrefix
      ? pathSelectors[0]?.value
      : pathSelectorsForEfs[0]?.value,
  },
  pathsList: {
    name: 'pathsList',
    value: '',
    validationRules: {},
  },
})

export default buildDefaultFormData
