import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import { styled, keyframes } from '@mui/material/styles'
import { ARROW_DOWN_ICON } from '../icons/consts'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const MIN_WIDTH = '275px'

export const StyledCard = styled(Card)<{
  selected: boolean
}>(({ theme, selected }) => {
  const borderColor = theme.palette.blue[500]

  return {
    height: 'auto',
    marginTop: 'auto',
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
    minWidth: MIN_WIDTH,
    overflow: 'inherit',
    borderRadius: '8px',
    animation: selected ? `${fadeIn} 0.5s  ease` : 'none',
    '.card-content': {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      border: selected && `2px solid ${borderColor}`,
      '&.infected': {
        // We do not have a name for this color
        borderColor: '#D94F41',
      },
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  }
})

export const StyledBadge = styled(Badge)({
  position: 'absolute',
  top: '-10px',
  left: '20px',
  '.MuiBadge-badge': {
    top: '5px',
  },
})

export const StyledChip = styled(Chip)({
  maxWidth: '50',
  marginLeft: 'auto',
})

export const StyledDownArrow = styled(Box)({
  width: '100%',
  height: 'auto',
  minHeight: '40px',
  position: 'relative',
  '::before': {
    display: 'flex',
    justifyContent: 'center',
    content: ARROW_DOWN_ICON,
    position: 'absolute',
    top: 0,
    left: '128px',
    height: '25px',
  },
})

export const StyledCardWrapper = styled(Box)({
  position: 'relative',
  '&:nth-child(1)': {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: '50%',
      width: '50vw',
      height: '2px',
      background: '#fff',
    },
  },
  '&:nth-last-child(1)': {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      width: '50vw',
      height: '2px',
      background: '#fff',
    },
  },
})
