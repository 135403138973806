import { BaseRoutes } from '../base'

export enum RouteFragments {
  ADD_ACCESS_TOKEN = 'add-access-token',
  EDIT_ACCESS_TOKEN = 'edit-access-token',
  GENERATED_ACCESS_TOKEN = 'generated-access-token',
  DYNAMIC_ID = ':id',
}

export enum AccountAndSettingsTab {
  ACCOUNT_PROFILE = 0,
  ACCOUNT_SECURITY = 1,
  API_ACCESS = 2,
  ALERTS = 3,
}

export class AccountAndSettingsRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_ACCOUNT_AND_SETTINGS
  }

  public static get addAccessToken(): string {
    return this.buildRoute(RouteFragments.ADD_ACCESS_TOKEN)
  }

  public static get editAccessTokenIdPlaceholder(): string {
    return this.buildRoute(
      RouteFragments.EDIT_ACCESS_TOKEN,
      RouteFragments.DYNAMIC_ID
    )
  }

  public static get generatedAccessTokenIdPlaceholder(): string {
    return this.buildRoute(
      RouteFragments.GENERATED_ACCESS_TOKEN,
      RouteFragments.DYNAMIC_ID
    )
  }

  public static get alertsPage(): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: {
        t: AccountAndSettingsTab.ALERTS,
      },
    })
  }

  public static accountAndSettingsWithParams(
    tabId: number,
    params: any = {}
  ): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: {
        t: tabId,
        ...params,
      },
    })
  }

  public static successTokenId(tokenId: string): string {
    return this.buildRoute(
      RouteFragments.GENERATED_ACCESS_TOKEN,
      this.base64Encode(tokenId)
    )
  }

  public static editTokenId(tokenId: string): string {
    return this.buildRoute(
      RouteFragments.EDIT_ACCESS_TOKEN,
      this.base64Encode(tokenId)
    )
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_ACCOUNT_AND_SETTINGS}/${fragments.join('/')}`
  }

  static readonly #ROUTE_ACCOUNT_AND_SETTINGS = '/account-and-settings'
}
