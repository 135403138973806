/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { ActiveThreatsRoutes } from './active-threats'
import { Asset, GenericHost } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { ActiveThreats } from '@features/active-threats'
import { SystemHelper } from '@lib/helpers'
import ActiveThreatsPage from '@features/active-threats/ova'

const getAwsassetFilterCriteria = (asset: Asset) =>
  asset instanceof AWSAsset || asset instanceof GenericHost

const META_TITLE = 'Active Threats'

const pageTitle = SystemHelper.getCurrentPageName(META_TITLE)

export const activeThreatsRoutes: Array<PathRouteProps> = [
  {
    path: ActiveThreatsRoutes.awsAssetsActiveThreats,
    element: (
      <MainTopBarLayout title={pageTitle}>
        <ActiveThreats assetFilterCriteria={getAwsassetFilterCriteria} />
      </MainTopBarLayout>
    ),
  },

  {
    path: ActiveThreatsRoutes.ovaAssetsActiveThreats,
    element: (
      <MainTopBarLayout title={pageTitle}>
        <ActiveThreatsPage />
      </MainTopBarLayout>
    ),
  },
]
