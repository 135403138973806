import React from 'react'
import PagePathConstant from '@lib/constants/page-path.constant'

function AccessDenied() {
  return (
    <div className="innerContent">
      <div className="innerContent ilMain">
        <div className="llContent">
          <div className="logoBlockWrap">
            <div className="logoBlock">
              <div className="">
                <img
                  width={140}
                  height={50}
                  alt="Elastio"
                  src="/images/elastio-logo.svg"
                />
              </div>
            </div>
          </div>
          <p className="textHeaderBlock">
            <b>Access denied</b>
          </p>
          <div className="textBlock">
            <p>
              The account specified does not have access to this tenant.
              <br />
              Please request authorization from the tenant owner.
            </p>
          </div>

          <a
            className="jsLogoutLink iButton"
            href={PagePathConstant.AUTH0_LOGOUT}
          >
            Back to login page
          </a>
        </div>
        <div className="image">
          <img
            width={468}
            height={468}
            alt="Elastio"
            src="/images/error-reloading.svg"
          />
        </div>
      </div>
    </div>
  )
}

export default AccessDenied
