import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { AwsStatsClient } from 'ui-v2/src/lib/clients'
import { AwsStatsRequestParams } from 'ui-v2/src/lib/models/aws-stats'
import { QUERY_REFETCH_INTERVAL } from 'ui-v2/src/lib/constants/query.constant'

export function useAwsBackupCoverageQuery(filters: AwsStatsRequestParams) {
  const awsStatsClient = useGrpcClient(AwsStatsClient)

  return useQuery({
    queryKey: ['aws-statistics', 'backup-coverage', filters],
    queryFn: async () => {
      if (!awsStatsClient) {
        throw new Error('Aws stats client not initialized')
      }
      return awsStatsClient.getBackupCoverage(filters)
    },
    enabled: !!awsStatsClient,
    refetchInterval: QUERY_REFETCH_INTERVAL,
  })
}
