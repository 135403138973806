/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { getHumanReadableTenantJobStatus } from '@features/jobs/tenant-jobs/helpers'
import { BaseSelectedChip } from './base-selected-chip'

export function TenantJobStatusChip({
  status,
  onDelete,
}: {
  status: TenantJobStatus
  onDelete: (status: TenantJobStatus) => void
}) {
  const handleRemoveFilter = useCallback(() => {
    onDelete(status)
  }, [])

  return (
    <BaseSelectedChip
      filterName="Job Status"
      filterValue={getHumanReadableTenantJobStatus(status)}
      onDelete={handleRemoveFilter}
    />
  )
}
