/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupSlider, ThreatsList } from '@features/asset-page/shared'
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
  EC2,
  Threat,
  Asset,
  Backup,
  OvaServer,
  OvaBackup,
} from 'blues-corejs/dist'
import React from 'react'
import { MoreDetailsEc2TabsContent } from './more-details-ec2-tabs-content'
import { EngineCallback } from '@lib/engine-types'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

function OvaBackupStrategy({
  asset,
  backup,
  dataThreats,
  scansList,
  isTableTabSelected,
  fetchDataThreats,
}: {
  isTableTabSelected: boolean
  asset: OvaServer
  backup: OvaBackup
  dataThreats: Array<Threat>
  scansList: Array<Scan>
  fetchDataThreats: EngineCallback<void>
}) {
  const snapshots = [...backup.snapshots]
  if (isTableTabSelected) {
    return (
      <ThreatsList
        scans={scansList}
        threats={dataThreats}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  // TODO: implement
  function handleSnaspshotChange() {}

  return (
    <>
      <OvaBackupSlider
        server={asset}
        snapshots={snapshots}
        onSnapshotChange={handleSnaspshotChange}
        threats={dataThreats}
      />
      <ThreatsList
        scans={scansList}
        threats={dataThreats}
        fetchDataThreats={fetchDataThreats}
      />
    </>
  )
}

interface Props {
  isTableTabSelected: boolean
  isSliderTabSelected: boolean
  asset: Asset
  backup?: Backup
  dataThreats: Array<Threat>
  scansList: Array<Scan>
  fetchDataThreats: EngineCallback<void>
}

export function MoreDetailsTabsContent({
  isTableTabSelected,
  isSliderTabSelected,
  asset,
  backup,
  dataThreats,
  scansList,
  fetchDataThreats,
}: Props) {
  if (asset instanceof EC2) {
    return (
      <MoreDetailsEc2TabsContent
        asset={asset}
        backup={backup}
        dataThreats={dataThreats}
        scansList={scansList}
        isTableTabSelected={isTableTabSelected}
        isSliderTabSelected={isSliderTabSelected}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  if (asset instanceof OvaServer && backup instanceof OvaBackup) {
    return (
      <OvaBackupStrategy
        asset={asset}
        backup={backup}
        dataThreats={dataThreats}
        scansList={scansList}
        isTableTabSelected={isTableTabSelected}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  return (
    <ThreatsList
      threats={dataThreats}
      scans={scansList}
      fetchDataThreats={fetchDataThreats}
    />
  )
}
