/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment } from 'react'
import {
  useSelectedModalFilters,
  useModalFilterActions,
} from '@features/jobs/tenant-jobs/deployment-jobs/filters-modal/deployment-filters-store'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { TenantJobKindChip } from '@features/jobs/tenant-jobs'

export function SelectedJobKindsFilters() {
  const { kindsList } = useSelectedModalFilters()

  const { setFilter } = useModalFilterActions()

  if (kindsList.size === 0) {
    return null
  }

  function handleRemoveFilter(kind: TenantJobKind) {
    const newKindsList = new Set(kindsList)

    newKindsList.delete(kind)

    setFilter('kindsList', newKindsList)
  }

  return (
    <Fragment>
      {Array.from(kindsList).map((kind) => (
        <TenantJobKindChip
          onDelete={handleRemoveFilter}
          key={kind}
          kind={kind}
        />
      ))}
    </Fragment>
  )
}
