import { useEffect } from 'react'
import { PagePathConstant } from '@lib/constants'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'

const HEADER_TITLE = 'Active Threats'

export function useBreadcrumbForActiveThreatsPage() {
  const { setBreadcrumbsPaths, setHeaderTitle } = useLayoutContext()

  useEffect(() => {
    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
      },
    ])

    setHeaderTitle(HEADER_TITLE)

    return () => {
      setHeaderTitle(null)
    }
  }, [])
}
