/* eslint-disable import/no-extraneous-dependencies */
import { ParenthesizedDateTimeTooltip } from '@components-simple/date-time-tooltip'
import { CellWithDateTimeTooltip } from '@components/table'
import { ScansRun } from 'blues-corejs/dist/models'
import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import {
  computeScansTarget,
  Provider,
  ScanTarget,
} from '../helpers/compute-scan-target'
import {
  RubrikIcon,
  VeeamIcon,
  CohesityIcon,
  CommvaultIcon,
  NetBackupIcon,
} from '@inline-img/general/ova/backup'
import { AwsLogoIcon, ElastioLogoBlueIcon } from '@inline-img/general'

interface Props {
  scans: ScansRun
}

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  margin: 0,
})

export const StyledIconContainer = styled(Box)`
  height: 25px;

  svg {
    width: 32px;
    height: 25px;
  }
`

function AssetItemElastioBackupSource() {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <ElastioLogoBlueIcon />
      <StyledTypography>Asset Item Elastio Backup</StyledTypography>
    </Box>
  )
}

function DirectScanSource() {
  return <StyledTypography>Direct scan</StyledTypography>
}

function ElastioBackupSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box display="flex" alignItems="center" gap="4px">
      <ElastioLogoBlueIcon />
      Elastio Backup
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function AwsBackupSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="4px"
      sx={{
        svg: {
          width: '24px',
          height: '24px',
        },
      }}
    >
      <AwsLogoIcon />
      AWS Backup
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function VeeamBackupSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <VeeamIcon />
      <StyledTypography>Veeam</StyledTypography>
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function RubrikBackupSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <RubrikIcon />
      <StyledTypography>Rubrik</StyledTypography>
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function CohesityBackupSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <CohesityIcon />
      <StyledTypography>Cohesity</StyledTypography>
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function AwsBackupVmwareSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <StyledIconContainer>
        <AwsLogoIcon />
      </StyledIconContainer>
      <StyledTypography>Vmware</StyledTypography>
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function VeritasNetBackupSource({
  backupTimestamp,
}: {
  backupTimestamp: Date
}) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <NetBackupIcon />
      <StyledTypography>VeritasNetBackup</StyledTypography>
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function CommvaultBackupSource({ backupTimestamp }: { backupTimestamp: Date }) {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <CommvaultIcon />
      <StyledTypography>Commvault</StyledTypography>
      <ParenthesizedDateTimeTooltip>
        <CellWithDateTimeTooltip direction="row" date={backupTimestamp} />
      </ParenthesizedDateTimeTooltip>
    </Box>
  )
}

function getDataSource(scansTarget: Array<ScanTarget>) {
  for (const scanTarget of scansTarget) {
    if ('assetId' in scanTarget) {
      return <DirectScanSource />
    }

    if ('backupId' in scanTarget) {
      const isElastioAssetItemScan =
        scanTarget.backupProvider === Provider.ELASTIO &&
        scanTarget.backupAssetItemId

      if (isElastioAssetItemScan) {
        return <AssetItemElastioBackupSource />
      }

      if (scanTarget.backupProvider === Provider.ELASTIO) {
        return (
          <ElastioBackupSource backupTimestamp={scanTarget.backupTimestamp} />
        )
      }

      if (scanTarget.backupProvider === Provider.AWS) {
        return <AwsBackupSource backupTimestamp={scanTarget.backupTimestamp} />
      }

      if (scanTarget.backupProvider === Provider.OVA_VEEAM) {
        return (
          <VeeamBackupSource backupTimestamp={scanTarget.backupTimestamp} />
        )
      }
      if (scanTarget.backupProvider === Provider.OVA_RUBRIK) {
        return (
          <RubrikBackupSource backupTimestamp={scanTarget.backupTimestamp} />
        )
      }
      if (scanTarget.backupProvider === Provider.OVA_COHESITY) {
        return (
          <CohesityBackupSource backupTimestamp={scanTarget.backupTimestamp} />
        )
      }
      if (scanTarget.backupProvider === Provider.OVA_VERITAS_NET_BACKUP) {
        return (
          <VeritasNetBackupSource
            backupTimestamp={scanTarget.backupTimestamp}
          />
        )
      }
      if (scanTarget.backupProvider === Provider.OVA_COMMVAULT) {
        return (
          <CommvaultBackupSource backupTimestamp={scanTarget.backupTimestamp} />
        )
      }

      if (scanTarget.backupProvider === Provider.OVA_AWS_BACKUP_VMWARE) {
        return (
          <AwsBackupVmwareSource backupTimestamp={scanTarget.backupTimestamp} />
        )
      }
    }
  }

  return null
}

export function DataSourceCell({ scans }: Props) {
  const computedScanTarget = computeScansTarget([
    ...scans.filesystemChecksList,
    ...scans.malwareScansList,
    ...scans.ransomwareScansList,
  ])

  const dataSource = getDataSource(computedScanTarget)

  return dataSource
}
