import { BaseRoutes } from '../base'

export class ManagementViewRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_MANAGEMENT_VIEW
  }

  public static get editManagementView(): string {
    return this.#ROUTE_EDIT_MANAGEMENT_VIEW
  }

  public static editManagementViewTab(tabId: string): string {
    return this.buildRouteWithQuery({
      basePath: this.#ROUTE_EDIT_MANAGEMENT_VIEW,
      params: {
        [this.#QUERY_KEYS.TAB]: tabId,
      },
    })
  }

  static #ROUTE_MANAGEMENT_VIEW = '/management-view'

  static #ROUTE_EDIT_MANAGEMENT_VIEW = '/edit-management-view'

  static readonly #QUERY_KEYS = {
    TAB: 't',
  }
}
