import { useOvaServerPossibleFiltersStoreActions } from '@lib/zustand'
import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import {
  ThreatExposure,
  ThreatsWidget,
  AssetsScanned,
  DataScanned,
  FilesScanned,
  AccountSummary,
  InfectedServers,
} from './widgets'
import { useCurrentView } from '@features/DashboardV4/common'
import { TEMPORARY_VIEW_ID } from '@features/DashboardV4/management-view/consts'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
  useOvaDashboardStoreSubscription,
} from './ova-dashboard-store/ova-dashboard-store'

function AccountTables() {
  const view = useCurrentView()

  const isTemporaryTab = view.id.includes(TEMPORARY_VIEW_ID)

  if (isTemporaryTab) {
    return (
      <InfectedServers ovaAccountId={view.slice.accountIds?.[0] as string} />
    )
  }

  return <AccountSummary />
}

export default function OvaDashboard() {
  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  const {
    fetchPossibleOvaServersFilters,
    resetPossibleOvaServersFiltersStore,
  } = useOvaServerPossibleFiltersStoreActions()

  useOvaDashboardStoreSubscription()

  useEffect(() => {
    fetchPossibleOvaServersFilters({
      backupProvidersList: sliceOvaBackupProvidersList,
      ovaAccountIdsList: sliceOvaAccountsList,
    })

    return resetPossibleOvaServersFiltersStore
  }, [sliceOvaAccountsList.length, sliceOvaBackupProvidersList.length])

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <ThreatsWidget />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <ThreatExposure />
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <AssetsScanned />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DataScanned />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <FilesScanned />
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs>
          <AccountTables />
        </Grid>
      </Grid>
    </Grid>
  )
}
