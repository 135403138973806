import { useQuery } from '@tanstack/react-query'
import { PersonalAccessTokenClient } from 'ui-v2/src/lib/clients/settings/personal-access-token'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useTokenDetailsQuery(id: string | undefined) {
  const personalAccessTokenClient = useGrpcClient(PersonalAccessTokenClient)

  return useQuery({
    queryKey: ['settings', 'tokenDetails', id],
    queryFn: () => {
      if (!personalAccessTokenClient) {
        throw new Error('Personal access token client not initialized')
      }
      if (!id) {
        throw new Error('Personal access token ID not found')
      }
      return personalAccessTokenClient.getTokenDetailsById(id)
    },
    enabled: !!personalAccessTokenClient,
  })
}
