/* eslint-disable import/no-extraneous-dependencies */
import { Nullable } from '@lib/engine-types'
import { ObjHelper } from '@lib/helpers'
import { UseFormSetValue } from 'react-hook-form'
import { DashboardViewForm, TagsOperations } from './types'
import type { Option } from '@components/select'
import { SliceCriteriaTag } from 'blues-corejs/dist/models/inventory.model'
import { TagsArray } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { TabViewInterface } from '@features/DashboardV4/management-view'
import { isAWSSliceCriteria } from '@features/DashboardV4/management-view/slice-criteria-guards'

export function removeEmptyValuesFromObject(
  obj: TabViewInterface
): TabViewInterface {
  const result = ObjHelper.cloneDeep(obj) as TabViewInterface

  Object.entries(result.slice).forEach(
    ([key, value]: [string, Array<string> | object]) => {
      if (Array.isArray(value) && value.length === 0) {
        // TODO: type-safe this
        ;(result.slice as any)[key] = undefined
      }
    }
  )
  const { slice } = result
  const isAWS = isAWSSliceCriteria(slice, result)

  if (isAWS) {
    const includeTags = slice.include_tags?.tags ?? {}
    const excludeTags = slice.exclude_tags?.tags ?? {}

    if (Object.keys(includeTags).length === 0) {
      slice.include_tags = undefined
    }

    if (Object.keys(excludeTags).length === 0) {
      slice.exclude_tags = undefined
    }
  }

  return result
}

export function splitBeforeFirstEqual(str: string): SliceCriteriaTag {
  const index = str.indexOf('=')

  if (index === -1) {
    return {
      key: str,
      value: null,
    }
  }

  if (str.substring(index + 1) === '*') {
    return {
      key: str.substring(0, index),
      value: null,
    }
  }

  return {
    key: str.substring(0, index),
    value: str.substring(index + 1),
  }
}

export function convertTagsToTuples(
  tags: Array<string>
): Array<SliceCriteriaTag> {
  return tags.map(splitBeforeFirstEqual)
}

export const setFormValuesFromView = (
  view: TabViewInterface,
  setValue: UseFormSetValue<DashboardViewForm>
) => {
  const { slice } = view
  const isAWS = isAWSSliceCriteria(slice, view)

  if (isAWS) {
    const includeTag = slice.include_tags?.tags ?? []
    const excludeTag = slice.exclude_tags?.tags ?? []

    setValue('name', view.name)
    setValue('accounts', view.slice.accountIds ?? [])
    setValue('regions', slice.regions ?? [])
    setValue(
      'includingTagsOperation',
      (slice.include_tags?.operator ?? TagsOperations.AND) as TagsOperations
    )
    setValue(
      'includingTags',
      includeTag
        ? includeTag.map(({ key, value }) => `${key}=${value ?? '*'}`)
        : []
    )

    setValue(
      'excludingTagsOperation',
      (slice.exclude_tags?.operator ?? TagsOperations.AND) as TagsOperations
    )

    setValue(
      'excludingTags',
      excludeTag
        ? excludeTag.map(({ key, value }) => `${key}=${value ?? '*'}`)
        : []
    )
  } else {
    setValue('regions', [])
    setValue('includingTagsOperation', TagsOperations.AND)
    setValue('includingTags', [])
    setValue('excludingTagsOperation', TagsOperations.AND)
    setValue('excludingTags', [])
  }
}

export const getUniqueOptions = (
  data: Array<string>,
  tagsOptions: Array<Option>
): Array<Option> => {
  return tagsOptions.filter((item) => !data.includes(item.value))
}

export function getRegionsWithRelatedAccounts<T extends AWSAsset>(
  assets: Array<T>
): Map<string, Array<string>> {
  const result = new Map<string, Array<string>>()

  for (const asset of assets) {
    const accountId = asset.awsAccountId
    const region = asset.awsRegion

    const accounts = result.get(region)

    if (accounts && !accounts.includes(accountId)) {
      accounts.push(accountId)
      continue
    }

    if (result.has(region)) {
      continue
    }

    result.set(region, [accountId])
  }

  return result
}

function getTagsFromAssets<T extends AWSAsset>(
  assets: Array<T>
): Array<string> {
  return assets.flatMap((asset) =>
    (asset.tags as TagsArray).map(({ key, value }) => `${key}=${value ?? '*'}`)
  )
}

function getTagsFromCurrentView(
  view: Nullable<TabViewInterface>
): Array<string> {
  if (!view) {
    return []
  }

  const { slice } = view
  if (isAWSSliceCriteria(slice, view)) {
    return [
      ...(slice.include_tags?.tags ?? []),
      ...(slice.exclude_tags?.tags ?? []),
    ].map(({ value, key }) => `${key}=${value}`)
  }

  return []
}

export function getAvailableTags<T extends AWSAsset>({
  assets,
  currentView,
}: {
  assets: Array<T>
  currentView: Nullable<TabViewInterface>
}): Array<string> {
  const assetTags = getTagsFromAssets(assets)
  const currentViewTags = getTagsFromCurrentView(currentView)

  return Array.from(new Set([...assetTags, ...currentViewTags]))
}
