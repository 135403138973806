import { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, FormControl, Stack, Typography } from '@mui/material'

import GenerateReportDrawer from './generate-report-drawer'

import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'

import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'

import { FormOptionValueSlug } from 'ui-v2/src/lib/models/reports'
import { useReportScheduleListQuery } from 'ui-v2/src/hooks/queries/reports/report-schedule-list'
import { ScheduleTableData } from 'ui-v2/src/lib/use-cases/reports'
import { getScheduleColumns } from './column-config'

import RreportDetailsDrawer from './report-details-drawer'
import { useCustomFlags } from 'ui-v2/src/lib/helpers/reports/use-custom-flags'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'

export default function GenerateReport() {
  const navigate = useNavigate()
  const { kindSlug } = useParams()

  const [selectReportType, setSelectReportType] = useState<string>('')

  const { data: scheduleList } = useReportScheduleListQuery()

  const handleOpenReportDrawer = () => {
    if (selectReportType) {
      const reportOption = reportOptions.find(
        (option) => option.value === selectReportType
      )
      if (reportOption) {
        navigate(
          `/reports/schedules/form/${FormOptionValueSlug.ON_DEMAND}/${reportOption.slug}`
        )
      }
    }
  }

  useEffect(() => {
    if (!selectReportType && kindSlug) {
      const option = reportOptions.find(
        (reportOption) => reportOption.slug === kindSlug
      )
      if (option) {
        setSelectReportType(option.value)
      }
    }
  }, [selectReportType, kindSlug])

  const data = useMemo(() => {
    if (!scheduleList) {
      return undefined
    }

    return new ScheduleTableData({
      scheduleReports: scheduleList.listReportSchedules,
    }).getScheduleReportsData()
  }, [scheduleList])

  const columns = useMemo(getScheduleColumns, [])

  const scheduleDataLength = data?.length ?? 0
  const { maxAmountOfReportSchedules } = useCustomFlags()

  return (
    <Box>
      <Stack spacing={2} direction="row" alignItems={'flex-end'} mt={2} mb={2}>
        <FormControl fullWidth sx={{ maxWidth: 350 }} size="small">
          <Typography variant="body1" mb={0.5}>
            Create a Report
          </Typography>
          <BasicSelect
            value={selectReportType ?? ''}
            onChange={(value) => {
              setSelectReportType(value as string)
            }}
            options={reportOptions.map(({ label, value }) => ({
              label,
              value,
            }))}
            size="small"
            placeholder="Select a Report"
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenReportDrawer}
          sx={{ textTransform: 'capitalize' }}
          disabled={!selectReportType}
        >
          Generate
        </Button>
      </Stack>
      <Typography variant="body1" mb={2}>
        Report Schedules ({scheduleDataLength} of {maxAmountOfReportSchedules}{' '}
        report)
      </Typography>

      <ClientTable checkable={false} data={data ?? []} columns={columns} />

      <GenerateReportDrawer scheduleDataLength={scheduleDataLength} />
      <RreportDetailsDrawer />
    </Box>
  )
}
