/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnector } from 'blues-corejs/dist/models'

/** If we don't have an account name or account alias, then show only account id without brackets */
export function computeAccountName(cloudConnector: CloudConnector): string {
  if (cloudConnector.accountAlias) {
    return `${cloudConnector.accountAlias} (${cloudConnector.awsAccountId})`
  }

  return cloudConnector.awsAccountId
}
