import React, { memo } from 'react'
import PlayIcon from '@inline-img/general/play-icon'
import PausedIcon from '@inline-img/general/paused-icon'
import { EngineCallbackPure } from '@lib/engine-types'
import { IconButton } from '@mui/material'

interface Props {
  onResume: EngineCallbackPure
  onPause: EngineCallbackPure
  isPaused: boolean
}

function PauseResumePolicy({ onResume, onPause, isPaused }: Props) {
  return (
    <div className="wrap-1653480494317 jsPauseResumeIcon">
      {isPaused ? (
        <IconButton
          size="small"
          onClick={onResume}
          data-testid="jsPausedIcon"
          className="jsPausedIcon"
        >
          <PausedIcon />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          onClick={onPause}
          data-testid="jsPlayIcon"
          className="jsPlayIcon"
        >
          <PlayIcon />
        </IconButton>
      )}
    </div>
  )
}

export default memo(PauseResumePolicy)
