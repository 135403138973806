import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import NumHelper from '@lib/helpers/num.helper'

interface Props {
  cleanFilesCount: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    flexBasis="50%"
  >
    {children}
  </Box>
)

function CleanFilesScannedSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          13.4 M
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" textAlign="center" fontSize="16px">
          Clean files (Prior 30 days)
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function CleanFiles({ cleanFilesCount, isLoading }: Props) {
  if (isLoading) {
    return <CleanFilesScannedSkeleton />
  }

  const cleanFilesCountFormatted = NumHelper.formatLargeNumber(cleanFilesCount)

  return (
    <Container>
      <Typography fontWeight="600" fontSize="22px">
        {cleanFilesCountFormatted}
      </Typography>
      <Typography fontWeight="400" textAlign="center" fontSize="16px">
        Clean files (Prior 30 days)
      </Typography>
    </Container>
  )
}
