export const NEWS_FEED_DATA = [
  {
    id: 1,
    name: 'STOP',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/stop/',
  },
  {
    id: 2,
    name: 'SpartCrypt',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/spartcrypt/',
  },
  {
    id: 3,
    name: 'Vipasana',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/vipasana/',
  },
  {
    id: 4,
    name: 'Woddy',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/woddy/',
  },
  {
    id: 5,
    name: 'AnonWorld',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/anonworld/',
  },
  {
    id: 6,
    name: 'Blind',
    updatedAt: 'Dec 5, 2024',
    link: 'https://elastio.us/blind/',
  },
  {
    id: 7,
    name: 'Arachna',
    updatedAt: 'Dec 5, 2024',
    link: 'https://elastio.us/arachna/',
  },
  {
    id: 8,
    name: 'Imploder',
    updatedAt: 'Dec 12, 2024',
    link: 'https://elastio.us/imploder/',
  },
  {
    id: 9,
    name: 'Dharma',
    updatedAt: 'Dec 12, 2024',
    link: 'https://elastio.us/dharma/',
  },
  {
    id: 10,
    name: 'CryptFIle2',
    updatedAt: 'Dec 5, 2024',
    link: 'https://elastio.us/cryptfile2/',
  },
  {
    id: 11,
    name: 'Kasper',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/kasper/',
  },
  {
    id: 12,
    name: 'Jigsaw-2016',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/jigsaw-2016/',
  },
  {
    id: 13,
    name: 'IziCrypt',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/izicrypt/',
  },
  {
    id: 14,
    name: 'Clop',
    updatedAt: 'Dec 2, 2024',
    link: 'https://elastio.us/clop/',
  },
  {
    id: 15,
    name: 'Termite-2024',
    updatedAt: 'Dec 12, 2024',
    link: 'https://elastio.us/termite-2024/',
  },
  {
    id: 16,
    name: 'Shifr RaaS',
    updatedAt: 'Dec 12, 2024',
    link: 'https://elastio.us/shifr-raas/',
  },
  {
    id: 17,
    name: 'Scarab',
    updatedAt: 'Dec 12, 2024',
    link: 'https://elastio.us/scarab/',
  },
  {
    id: 18,
    name: 'Rapid',
    updatedAt: 'Dec 12, 2024',
    link: 'https://elastio.us/rapid/',
  },
]
