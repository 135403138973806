import { create } from 'zustand'

export enum TabsIds {
  DETAILS = 0,
  ASSET_ITEMS = 1,
  RECOVERY_POINTS = 2,
  REPLICATED_RECOVERY_POINTS = 3,
  SCANS = 4,
  ENTROPY = 5,
  JOBS = 6,
}

type Tabs = {
  activeTab: TabsIds
}

export const useTabsStore = create<Tabs>(() => ({
  activeTab: TabsIds.DETAILS,
}))

export const getActiveTab = (state: Tabs) => state.activeTab

export const setActiveTab = (tab: TabsIds) =>
  useTabsStore.setState({ activeTab: tab })
