import { EngineCallbackPure } from '@lib/engine-types'
import { useCallback, useState } from 'react'

function useStepper(
  initialStep = 0
): [number, EngineCallbackPure, EngineCallbackPure] {
  const [currentStep, setCurrentStep] = useState(initialStep)
  const moveForward = useCallback(
    () => setCurrentStep(currentStep + 1),
    [currentStep]
  )
  const moveBackward = useCallback(
    () => setCurrentStep(currentStep - 1),
    [currentStep]
  )
  return [currentStep, moveForward, moveBackward]
}

export default useStepper
