/* eslint-disable import/no-extraneous-dependencies */
import {
  CellLongTextWithTooltip,
  CellWithDateTimeTooltip,
  CellWithLink,
  CopyCell,
  createColumn,
  createDisplayColumn,
  HeaderWithLabel,
  StatusCircle,
} from '@components/table'
import {
  InventoryS3BucketOutput,
  SummarizedS3BucketDataOutput,
} from 'blues-corejs/dist'
import React from 'react'
import LangHelper from '@lib/helpers/lang.helper'
import { AliasNamesForS3Bucket } from '@lib/interfaces/inventory/s3-bucket.interface'
import { Nullable } from '@lib/engine-types'
import styles from './s3BucketInventoryTableComplex.module.css'
import { INVENTORY_TABLE_MIN_HEIGHT } from '../inventory-tables-complex'
import { customColorResolver } from '@components-complex/dashboard-pages-v3/utils'
import { StyledCheckbox } from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/styles'
import { AssetRoutes } from '@lib/router'

type InputData = {
  summarizedData: SummarizedS3BucketDataOutput
  aliasNamesWithId: AliasNamesForS3Bucket
}

export function getS3BucketsTableColumnDefinitions({
  summarizedData,
  aliasNamesWithId,
}: InputData) {
  return [
    createDisplayColumn<InventoryS3BucketOutput>({
      header: ({ table }) => (
        <StyledCheckbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      size: 60,
      id: 'row-selection',
      cell: ({ row }) => {
        return (
          <StyledCheckbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      },
    }),
    createColumn<InventoryS3BucketOutput>('isInfected', {
      enableSorting: false,
      cell: ({ getValue, row }) => {
        const isHealthy = !getValue() as unknown as boolean
        return (
          <StatusCircle
            colorResolver={customColorResolver(row.original.lastScan)}
            isHealthy={isHealthy}
          />
        )
      },
      size: 45,
    }),
    createColumn<InventoryS3BucketOutput>('awsId', {
      header: () => (
        <HeaderWithLabel
          title="Bucket name"
          label={String(summarizedData.awsIdCount)}
        />
      ),
      meta: {
        height: INVENTORY_TABLE_MIN_HEIGHT,
      },
      sortingFn: (a, b) => {
        const bucketA = a.original
        const bucketB = b.original

        const bucketAName = bucketA.name || bucketA.awsId
        const bucketBName = bucketB.name || bucketB.awsId

        return bucketAName.localeCompare(bucketBName)
      },
      cell: ({ getValue, row }) => {
        const awsId = getValue() as unknown as string
        const bucketName = row.original.name
        const href = AssetRoutes.assetId(row.original.uuid)
        const displayedValue = bucketName || awsId
        const displayedLabel = bucketName ? awsId : bucketName
        const className = styles.idName

        return (
          <CellWithLink
            href={href}
            value={displayedValue}
            label={displayedLabel}
            className={className}
          />
        )
      },
    }),

    createColumn<InventoryS3BucketOutput>('accountId', {
      enableSorting: false,
      header: () => (
        <HeaderWithLabel
          title="Account"
          label={String(summarizedData.accountCount)}
        />
      ),
      cell: ({ getValue }) => {
        const className = aliasNamesWithId[getValue()] ? styles.copyRow : ''

        return (
          <CopyCell
            additionalText={aliasNamesWithId[getValue()]}
            copyText={getValue()}
            className={className}
          />
        )
      },
    }),
    createColumn<InventoryS3BucketOutput>('region', {
      header: () => (
        <HeaderWithLabel
          title="Region"
          label={String(summarizedData.regionCount)}
        />
      ),
      cell: ({ getValue }) =>
        LangHelper.getAwsRegionSingleTranslation(getValue()),
    }),
    createColumn<InventoryS3BucketOutput>('lastScan', {
      header: 'Last scan',
      cell: ({ getValue }) => {
        const lastScan = getValue() as unknown as Nullable<Date>

        return <CellWithDateTimeTooltip date={lastScan} />
      },
    }),

    createColumn<InventoryS3BucketOutput>('coveredByPolicesList', {
      header: 'Policy Coverage',
      cell: ({ getValue }) => {
        const policiesList = getValue() as unknown as Array<string>

        return <CellLongTextWithTooltip title={policiesList.join(', ')} />
      },
    }),
  ]
}
