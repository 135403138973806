import { useState } from 'react'

/* eslint-disable import/no-extraneous-dependencies */
import ObjHelper from '@lib/helpers/obj.helper'
import { Pagination } from '@lib/clients/types'
import { usePagination } from '@lib/hooks'
import { DataFetchStatus, UseS3ItemsDataFetcher } from './types'
import { ListS3ItemsRequestFilters } from '@lib/clients/assets-items/types'
import { ListS3ItemsClient } from '@lib/clients/assets-items'
import { S3Item } from 'blues-corejs/dist'
import { ListS3ItemsResponse } from '@lib/clients/assets-items/list-s3-items'

const S3ItemsClient = new ListS3ItemsClient()

interface ExtendedUseS3ItemsDataFetcher extends UseS3ItemsDataFetcher {
  fetchOnRefreshInterval: (filter?: ListS3ItemsRequestFilters) => Promise<void>
}

export function useS3ItemsForAssetDataFetcher(
  request?: ListS3ItemsRequestFilters
): ExtendedUseS3ItemsDataFetcher {
  const [dataFetchStatus, setDataFetchStatus] = useState<DataFetchStatus>(
    DataFetchStatus.IDLE
  )
  const [assetItemsData, setAssetItemsData] = useState<Array<S3Item>>([])

  const {
    updatePageTokenAndFlagFetched,
    pageToken,
    isNextPageAvailable,
    resetPagination,
  } = usePagination()

  const updateAssetItemsData = (assetItemsResponse: Array<S3Item>) => {
    setAssetItemsData((prevAssetItems) => [
      ...prevAssetItems,
      ...assetItemsResponse,
    ])
  }

  const updateAssetItemsDataOnRefreshInterval = (
    assetItemsResponse: Array<S3Item>
  ) => {
    setAssetItemsData((prevState) => {
      const updatedItems = [...prevState]
      assetItemsResponse.forEach((item) => {
        const findIndex = updatedItems.findIndex(
          (prevItem) => prevItem.id === item.id
        )
        if (findIndex > -1) {
          updatedItems[findIndex] = item
        } else {
          updatedItems.unshift(item)
        }
      })
      return updatedItems
    })
  }

  const handleFetchSuccess = (response: ListS3ItemsResponse & Pagination) => {
    updateAssetItemsData(response.items)
    updatePageTokenAndFlagFetched(response.pageToken || undefined)
  }

  const resetData = () => {
    setAssetItemsData([])
    resetPagination()
  }

  const isLoadingData = () => dataFetchStatus === DataFetchStatus.IS_LOADING

  const isFetchAllowed = () => {
    return request && ObjHelper.hasValues(request) && !isLoadingData()
  }

  const resetFetchStatus = () => {
    setDataFetchStatus(DataFetchStatus.IDLE)
  }

  const fetchData = async (filter?: ListS3ItemsRequestFilters) => {
    if (!isFetchAllowed() || !isNextPageAvailable()) {
      return
    }

    setDataFetchStatus(DataFetchStatus.IS_LOADING)
    try {
      if (dataFetchStatus === DataFetchStatus.IDLE) {
        const response = await S3ItemsClient.listS3Items({
          ...request,
          ...filter,
          pageToken,
        })

        setDataFetchStatus(DataFetchStatus.SUCCESS)
        handleFetchSuccess(response)
      }
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset requestin ${__filename}: ${error}`
      )
      setDataFetchStatus(DataFetchStatus.FAILURE)
    } finally {
      resetFetchStatus()
    }
  }

  const fetchOnRefreshInterval = async (filter?: ListS3ItemsRequestFilters) => {
    try {
      const response = await S3ItemsClient.listS3Items({
        ...request,
        ...filter,
        pageToken,
      })

      updateAssetItemsDataOnRefreshInterval(response.items)
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset requestin ${__filename}: ${error}`
      )
    }
  }

  return {
    onFetchData: fetchData,
    data: assetItemsData,
    dataFetchStatus,
    resetData,
    isLoadingData,
    fetchOnRefreshInterval,
  }
}
