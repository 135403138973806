import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { QuestionHint } from '@components-simple/question-hint'
import { ENTROPY_DETECTION_CONSTANTS } from './consts'
import Typography from '@mui/material/Typography'

interface Props {
  isEntropyFeatureAvailable: boolean
  hintText: React.ReactNode
}

export function EntropyDetectionCheckboxLabel({
  isEntropyFeatureAvailable,
  hintText,
}: Props) {
  return (
    <>
      <Stack direction="row" gap="10px">
        <Box className="checkboxLabel">
          {ENTROPY_DETECTION_CONSTANTS.DETECTION_CHECKBOX_LABEL}
        </Box>
        {!isEntropyFeatureAvailable && (
          <QuestionHint
            text={
              <Typography variant="caption" fontSize="11px" fontWeight="400">
                {hintText}
              </Typography>
            }
          />
        )}
      </Stack>
      <Box className="policiesBlockLabel">
        {ENTROPY_DETECTION_CONSTANTS.DETECTION_CHECKBOX_DESCRIPTION}
      </Box>
    </>
  )
}
