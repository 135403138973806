/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'

type InfoModalStore = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  rule?: NotificationRule
  setRule: (rule?: NotificationRule) => void
}

export const useInfoModalStore = create<InfoModalStore>((set) => ({
  rule: undefined,
  isOpen: false,
  setOpen: (isOpen: boolean) =>
    set((state) => ({
      ...state,
      isOpen,
    })),
  setRule: (rule?: NotificationRule) =>
    set((state) => ({
      ...state,
      rule,
    })),
}))
