import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  marginLeft: '24px',
  marginRight: '24px',

  '.slider-container': {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '16px',
    minHeight: '280px',
    paddingInline: '8px',
  },
})
