/* eslint-disable import/no-extraneous-dependencies */
import { Threat } from 'blues-corejs/dist/models/threats'
import React, { useState } from 'react'
import { ThreatKind } from 'blues-corejs/dist/models/threats/types'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Nullable } from '@lib/engine-types'
import { SxProps, Theme } from '@mui/system'

type Props = {
  threats: Array<Threat>
  sx?: SxProps<Theme>
}

enum CollapsebleFields {
  RANSOMWARE = 'RANSOMWARE',
  MALWARE = 'MALWARE',
}

interface UseCollapsedFieldsOutput {
  handleChipClick: (updatedCollapsedState: Nullable<CollapsebleFields>) => void
  getIsFieldCollapsed: (field: CollapsebleFields) => boolean
}

export function useCollapsedFields(): UseCollapsedFieldsOutput {
  const [collapsedState, setCollapsedState] =
    useState<Nullable<CollapsebleFields>>(null)

  function getIsFieldCollapsed(field: CollapsebleFields) {
    return collapsedState === field
  }

  function handleChipClick(updatedCollapsedState: Nullable<CollapsebleFields>) {
    setCollapsedState(updatedCollapsedState)
  }

  return {
    handleChipClick,
    getIsFieldCollapsed,
  }
}

const StyledHiddenChip = styled(Chip)(() => ({
  '&.MuiChip-root': {
    backgroundColor: '#E4F7FF',
    fontSize: '14px',
    fontWeight: '600',
    marginLeft: '5px',
  },
}))

interface HiddenChipCountProps {
  hiddenCount: number
  onClick: () => void
}

export function HiddenChipCount({
  hiddenCount,
  onClick,
}: HiddenChipCountProps) {
  if (hiddenCount === 0) {
    return null
  }

  const label = `+${hiddenCount}`

  return <StyledHiddenChip label={label} onClick={onClick} />
}

const DEFAULT_THREATS_TO_DISPLAY = 2

const StyledThreatChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  color: theme.palette.red[500],
  border: `1px solid ${theme.palette.red[500]}`,
  '.MuiChip-label': {
    fontWeight: 'var(--font-weight-semi-bold)',
  },
}))

function getFirstThreatsNames(threats: Array<string>) {
  return threats.slice(0, DEFAULT_THREATS_TO_DISPLAY)
}

function getThreatNamesWithoutFirst(threats: Array<string>) {
  return threats.slice(DEFAULT_THREATS_TO_DISPLAY)
}

function ThreatChip({
  threatName,
  onClick,
}: {
  threatName: string
  onClick: () => void
}) {
  return (
    <StyledThreatChip
      key={threatName}
      label={threatName}
      onClick={onClick}
      clickable={false}
    />
  )
}

interface RansomwaresProps {
  threats: Array<Threat>
  isRansomwareCollapsed: boolean
  onRansomwareChipClick: (
    collapsedField: Nullable<CollapsebleFields.RANSOMWARE>
  ) => void
}

function Ransomwares({
  threats,
  isRansomwareCollapsed,
  onRansomwareChipClick,
}: RansomwaresProps) {
  const ransomwareThreats = threats.filter(
    (threat) => threat.kind === ThreatKind.RANSOMWARE
  )

  if (ransomwareThreats.length === 0) {
    return null
  }

  const ransomwareNames = Array.from(
    new Set(ransomwareThreats.map((threat) => threat.name))
  )

  const firstRansomwareThreats = getFirstThreatsNames(ransomwareNames)

  const ransomwareThreatWithoutFirst =
    getThreatNamesWithoutFirst(ransomwareNames)

  const collapsedRansomwareThreatsNames = isRansomwareCollapsed
    ? ransomwareNames
    : firstRansomwareThreats

  function handleHiddenChipClick() {
    onRansomwareChipClick(CollapsebleFields.RANSOMWARE)
  }

  function handleCollapseClick() {
    onRansomwareChipClick(null)
  }

  const hiddenThreatsCount =
    ransomwareNames.length - collapsedRansomwareThreatsNames.length

  return (
    <React.Fragment>
      <Box
        display="flex"
        rowGap="15px"
        columnGap="8px"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography fontSize="14px" fontWeight="600" variant="caption">
          Ransomware
        </Typography>
        {firstRansomwareThreats.map((ransomwareName: string) => (
          <ThreatChip
            onClick={handleCollapseClick}
            key={ransomwareName}
            threatName={ransomwareName}
          />
        ))}
        {!isRansomwareCollapsed && (
          <HiddenChipCount
            hiddenCount={hiddenThreatsCount}
            onClick={handleHiddenChipClick}
          />
        )}
        {isRansomwareCollapsed && (
          <>
            {ransomwareThreatWithoutFirst.map((ransomwareName: string) => (
              <ThreatChip
                onClick={handleCollapseClick}
                key={ransomwareName}
                threatName={ransomwareName}
              />
            ))}
          </>
        )}
      </Box>
    </React.Fragment>
  )
}

interface MalwaresProps {
  threats: Array<Threat>
  isMalwareCollapsed: boolean
  onMalwareChipClick: (
    collapsedField: Nullable<CollapsebleFields.MALWARE>
  ) => void
}

function Malwares({
  threats,
  onMalwareChipClick,
  isMalwareCollapsed,
}: MalwaresProps) {
  const malwareThreats = threats.filter(
    (threat) => threat.kind === ThreatKind.MALWARE
  )

  if (malwareThreats.length === 0) {
    return null
  }

  const malwareNames = Array.from(
    new Set(malwareThreats.map((threat) => threat.name))
  )

  const firstMalwareThreats = getFirstThreatsNames(malwareNames)

  const malwareThreatWithoutFirst = getThreatNamesWithoutFirst(malwareNames)

  const collapsedMalwareThreatsNames = isMalwareCollapsed
    ? malwareNames
    : firstMalwareThreats

  function handleHiddenChipClick() {
    onMalwareChipClick(CollapsebleFields.MALWARE)
  }

  function handleCollapseClick() {
    onMalwareChipClick(null)
  }

  const hiddenThreatsCount =
    malwareNames.length - collapsedMalwareThreatsNames.length

  return (
    <React.Fragment>
      <Box
        display="flex"
        rowGap="15px"
        columnGap="8px"
        alignItems="center"
        flexWrap="wrap"
      >
        <Typography fontSize="14px" fontWeight="600" variant="caption">
          Malware
        </Typography>
        {firstMalwareThreats.map((ransomwareName: string) => (
          <ThreatChip
            onClick={handleCollapseClick}
            key={ransomwareName}
            threatName={ransomwareName}
          />
        ))}
        {!isMalwareCollapsed && (
          <HiddenChipCount
            hiddenCount={hiddenThreatsCount}
            onClick={handleHiddenChipClick}
          />
        )}
      </Box>
      {isMalwareCollapsed && (
        <Box display="flex" gap="5px" marginY="5px">
          {malwareThreatWithoutFirst.map((malwareName: string) => (
            <ThreatChip
              onClick={handleCollapseClick}
              key={malwareName}
              threatName={malwareName}
            />
          ))}
        </Box>
      )}
    </React.Fragment>
  )
}

function ThreatSummary({ threats, sx }: Props) {
  const { handleChipClick, getIsFieldCollapsed } = useCollapsedFields()

  const isRansomwareCollapsed = getIsFieldCollapsed(
    CollapsebleFields.RANSOMWARE
  )

  const isMalwareCollapsed = getIsFieldCollapsed(CollapsebleFields.MALWARE)

  if (threats.length === 0) {
    return null
  }

  return (
    <Grid sx={sx} container flexWrap="nowrap" columnSpacing={2}>
      <Grid item>
        <Malwares
          isMalwareCollapsed={isMalwareCollapsed}
          onMalwareChipClick={handleChipClick}
          threats={threats}
        />
      </Grid>
      <Grid item>
        <Ransomwares
          isRansomwareCollapsed={isRansomwareCollapsed}
          onRansomwareChipClick={handleChipClick}
          threats={threats}
        />
      </Grid>
    </Grid>
  )
}

export { ThreatSummary }
