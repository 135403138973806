import React from 'react'

function InfoIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3594 11.9C12.2542 11.8366 12.1312 11.8 12 11.8C11.8688 11.8 11.7458 11.8366 11.6406 11.9C11.4369 12.0228 11.3 12.2462 11.3 12.5V15.3C11.3 15.685 11.615 16 12 16C12.385 16 12.7 15.685 12.7 15.3V12.5C12.7 12.2462 12.5631 12.0228 12.3594 11.9ZM12.7 10.4V9H11.3V10.4H12.7ZM3.5 12.5C3.5 7.80757 7.30757 4 12 4C16.6924 4 20.5 7.80757 20.5 12.5C20.5 17.1924 16.6924 21 12 21C7.30757 21 3.5 17.1924 3.5 12.5ZM5 12.5C5 8.636 8.136 5.5 12 5.5C15.864 5.5 19 8.636 19 12.5C19 16.364 15.864 19.5 12 19.5C8.136 19.5 5 16.364 5 12.5Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default InfoIcon
