import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { RexClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useRedStackPolicyQuery() {
  const rexClient = useGrpcClient(RexClient)

  return useQuery({
    queryKey: ['red-stack-policy', 'list'],
    queryFn: () => {
      if (!rexClient) {
        throw new Error('Rex client not initialized')
      }
      return rexClient.getAllRedstacks()
    },
    enabled: !!rexClient,
  })
}
