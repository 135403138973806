import { ListScansClient } from '@lib/clients/scans/list-scans'
import { useEffect, useState } from 'react'
import { Nullable } from '@lib/engine-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ElastioRecoveryPoint } from 'blues-corejs/dist'
import { ListScans } from '../instance/types'
import { GetBackupsByIdsClient } from '@lib/clients/backups/list-backups-by-ids'
import { SystemHelper } from '@lib/helpers'

const listScansClient = new ListScansClient()
const listBackupsForAssetClient = new GetBackupsByIdsClient()

const defaultScansList: ListScans = {
  filesystemChecks: [],
  malwares: [],
  ransomwares: [],
}

type UseBackupsForAssetListAndScansDataOutput = {
  backup: Nullable<ElastioRecoveryPoint>
  scans: ListScans
  isLoadingBackupAndScans: boolean
  error: Nullable<Error>
}

function useBackupsForAssetListAndScansData({
  backupId,
  assetId,
}: {
  backupId: string
  assetId: string
}): UseBackupsForAssetListAndScansDataOutput {
  const [data, setData] = useState<{
    backup: Nullable<ElastioRecoveryPoint>
    scans: ListScans
  }>({
    backup: null,
    scans: defaultScansList,
  })
  const [isLoadingBackupAndScans, setIsLoadingBackupAndScans] = useState(false)
  const [error, setError] = useState<Nullable<Error>>(null)

  useEffect(() => {
    async function fetchData() {
      if (!assetId || !backupId) {
        return
      }
      setIsLoadingBackupAndScans(true)
      try {
        const backupsByIdsResponse =
          await listBackupsForAssetClient.getBackupsByIds([backupId])

        const targetBackup = backupsByIdsResponse.backupsList[0]

        if (!targetBackup || !targetBackup.elastioRp) {
          throw new Error(
            `useBackupsForAssetListAndScansData: Elastio backup not found with id: ${backupId}`
          )
        }

        const backup = targetBackup.elastioRp
        let scansResult: ListScans = defaultScansList

        if (backup.scanIds.length) {
          scansResult = await listScansClient.listScans([...backup.scanIds])
        }

        setData({
          backup,
          scans: scansResult,
        })
        setIsLoadingBackupAndScans(false)
      } catch (err) {
        SystemHelper.sendObjectToSentryIfProd(err)
        setError(err as Error)
      } finally {
        setIsLoadingBackupAndScans(false)
      }
    }

    fetchData()

    return () => {
      setData({
        backup: null,
        scans: defaultScansList,
      })
    }
  }, [assetId, backupId])

  return {
    ...data,
    isLoadingBackupAndScans,
    error,
  }
}

export { useBackupsForAssetListAndScansData }
