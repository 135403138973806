import React, { useState, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  useTheme,
  CircularProgress,
  circularProgressClasses,
  Typography,
  TablePagination,
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { useListCloudConnectorJobsQuery } from 'ui-v2/src/hooks/queries/jobs'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets/list-by-id'
import { MonitorTableData } from 'ui-v2/src/lib/use-cases/monitor'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import RedStackModel from 'ui-v2/src/lib/models/base-model/red-stack.model'
import Filters from './filters'
import { useMonitorFilters } from 'ui-v2/src/hooks/filters/monitor'
import ProgressCell from 'ui-v2/src/components/ui/data-display/progress-cell'
import { formatTimeDifference } from 'ui-v2/src/lib/helpers/time.helper'
import dayjs from 'dayjs'
import { STATUS_TO_STYLE_VARIANT_MAPPING } from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import CollapsibleDetailsDrawer from './details-drawer/collapsible-details'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import RestartScan from './details-drawer/restart-scan'

const LoadingTable = ({
  columnCount,
  rowCount,
}: {
  columnCount: number
  rowCount: number
}) => {
  return (
    <TableBody
      sx={(theme) => ({
        background: theme.palette.background.tertiary,
      })}
    >
      {Array.from({ length: rowCount }, (_, index) => (
        <TableRow key={index}>
          {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
          {Array.from({ length: columnCount }, (_, cellIndex) => (
            <TableCell key={cellIndex} sx={{ minHeight: '43px' }} />
          ))}
        </TableRow>
      ))}
    </TableBody>
  )
}

const MonitorComponent = () => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  )
  const [previousPageTokens, setPreviousPageTokens] = useState<
    Array<string | undefined>
  >([])

  const { filters, startDate, endDate, timeRange, setPageSize, setPageToken } =
    useMonitorFilters()

  const {
    data: cloudConnectorJobsList,
    isLoading: isCloudConnectorJobsLoading,
  } = useListCloudConnectorJobsQuery({
    pageSize: filters.pageSize,
    pageToken: filters.pageToken,
    assetIdsList: filters.assetIdsList,
    statusesList: filters.statusesList,
    kindsList: filters.kindsList,
    timeRange: filters.timeRange,
  })

  const { data: assetIdsList, isLoading: isAssetsIdListLoading } =
    useAssetsListByIdQuery(
      (cloudConnectorJobsList &&
        cloudConnectorJobsList.jobsList
          .filter((jobList) => jobList.targetAssetId)
          .map((jobList) => jobList.targetAssetId)) ??
        []
    )

  const { data: redStackList, isLoading: isRedStackListLoading } =
    useRedStackListQuery()

  const redStackListData = useMemo(() => {
    return redStackList?.map(
      (data) =>
        new RedStackModel({
          redStackId: data.redStackId,
          accountAlias: data.accountAlias,
          awsRegion: data.awsRegion,
          awsAccount: data.awsAccountId,
          status: data.status,
          iamRole: data.iamRole,
          accountDescription: data.accountDescription,
          lastErrorMessage: data.lastErrorMessage,
          version: data.version,
          lastWarningMessage: data.lastWarningMessage,
          awsAccountCfnVersion: data.awsAccountCfnVersion,
          lastIntegrityCheckErrorsList: data.lastIntegrityCheckErrors,
          cfnUpgradeRequired: data.cfnUpgradeRequired,
        })
    )
  }, [redStackList])

  const data = useMemo(() => {
    if (!cloudConnectorJobsList || !redStackListData) {
      return undefined
    }
    return new MonitorTableData({
      monitors: cloudConnectorJobsList.jobsList,
      assetsList: assetIdsList?.assetsList ?? [],
      redStackListData: redStackListData,
    }).getMonitorData()
  }, [cloudConnectorJobsList, assetIdsList, redStackListData])

  const isLoading =
    isCloudConnectorJobsLoading ||
    isAssetsIdListLoading ||
    isRedStackListLoading

  const handleNextPage = () => {
    if (cloudConnectorJobsList?.pagination?.nextPageToken) {
      setPreviousPageTokens((prev) => [...prev, filters.pageToken])
      setPageToken?.(cloudConnectorJobsList?.pagination.nextPageToken)
    }
  }

  const handlePreviousPage = () => {
    if (previousPageTokens.length > 0) {
      const lastPreviousToken =
        previousPageTokens[previousPageTokens.length - 1]
      setPreviousPageTokens((prev) => prev.slice(0, -1))
      setPageToken?.(lastPreviousToken)
    }
  }

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      handleNextPage()
    } else {
      handlePreviousPage()
    }
    setPage(newPage)
    // baseProps.onSelectRows?.([])
  }

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0)
    setPreviousPageTokens([])
    setPageToken?.(undefined)
    setPageSize?.(parseInt(e.target.value, 10))
    //   baseProps.onSelectRows?.([])
  }

  const handleExpandClick = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Filters startDate={startDate} endDate={endDate} timeRange={timeRange} />
      <TableContainer>
        <Table stickyHeader>
          <TableHead
            sx={{
              '& .MuiTableCell-head': {
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#1A1F28' : '#ffffff',
              },
            }}
          >
            <TableRow>
              {/* <TableCell padding="checkbox" sx={{ paddingRight: 0 }}>
                                <Checkbox
                                    indeterminate={
                                        selectedRows.length > 0 &&
                                        selectedRows.length <
                                        paginatedData.map((row) => row.id).length
                                    }
                                    checked={
                                        paginatedData.every((row) =>
                                            selectedRows.includes(row.id)
                                        ) && paginatedData.length > 0
                                    }
                                    onChange={handleSelectAll}
                                    sx={{
                                        color:
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(204, 204, 220, 0.2)'
                                                : '#85888c',
                                        '&.Mui-checked': {
                                            color: 'rgba(61, 113, 217, 1)',
                                        },
                                    }}
                                />
                            </TableCell> */}
              <TableCell sx={{ width: '10px' }}>&nbsp;</TableCell>
              <TableCell sx={{ width: '150px' }}>Scheduled On</TableCell>
              <TableCell sx={{ width: '100px' }}>Duration</TableCell>
              <TableCell sx={{ width: '150px' }}>Status</TableCell>
              <TableCell sx={{ width: '150px' }}>Job Type</TableCell>
              <TableCell sx={{ width: '150px' }}>Asset ID</TableCell>
              <TableCell sx={{ width: '180px' }}>Message</TableCell>
              <TableCell sx={{ width: '180px' }}>Task</TableCell>
              <TableCell align="right" sx={{ width: '50px' }}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <>
              <LoadingTable columnCount={9} rowCount={filters.pageSize ?? 1} />
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                zIndex={10}
                sx={{ backgroundColor: 'transparent' }}
              >
                <CircularProgress />
              </Box>
            </>
          ) : (
            <TableBody
              sx={{
                '& .MuiTableCell-body': {
                  backgroundColor:
                    theme.palette.mode === 'dark' ? '#181B1F' : '#f8f8f8',
                },
              }}
            >
              {data &&
                data.map((row) => (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      <TableCell
                        sx={{
                          paddingRight: 0,
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {/* TODO Later */}
                          {/* <Checkbox
                                                checked={selectedRows.includes(row.id)}
                                                onChange={() => handleCheckboxChange(row.id)}
                                                sx={{
                                                    color:
                                                        theme.palette.mode === 'dark'
                                                            ? 'rgba(204, 204, 220, 0.2)'
                                                            : '#85888c',
                                                    '&.Mui-checked': {
                                                        color: 'rgba(61, 113, 217, 1)',
                                                    },
                                                }}
                                            /> */}
                          <IconButton
                            sx={{
                              padding: 0,
                              width: 30,
                              height: 30,
                            }}
                            onClick={() => handleExpandClick(row.id)}
                            size="small"
                          >
                            {expandedRows[row.id] ? (
                              <KeyboardArrowDown />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {dayjs(row.createdAt).format('MM/DD/YY HH[h]:mm[m]')}
                      </TableCell>
                      <TableCell>
                        {!!row.scanEndTime &&
                          !!row.isFinished &&
                          formatTimeDifference(
                            row.scanStartTime,
                            row.scanEndTime
                          )}
                      </TableCell>
                      <TableCell>
                        <Box
                          display={'flex'}
                          sx={{
                            position: 'relative',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {STATUS_TO_STYLE_VARIANT_MAPPING[row.status] ===
                          'Failed' ? (
                            <>
                              <CircularProgress
                                variant="determinate"
                                sx={{ color: '#4E5665' }}
                                size={20}
                                thickness={8}
                                value={0}
                              />
                              <CircularProgress
                                variant="determinate"
                                sx={{
                                  color: '#eb5757',
                                  position: 'absolute',
                                  left: 0,
                                  [`& .${circularProgressClasses.circle}`]: {
                                    strokeLinecap: 'round',
                                  },
                                }}
                                size={20}
                                thickness={8}
                                value={100}
                              />
                              <Typography
                                marginLeft={1}
                                variant="body2"
                                whiteSpace={'nowrap'}
                              >
                                Job Failed
                              </Typography>
                            </>
                          ) : (
                            <ProgressCell
                              progress={Number(row.progress.toFixed(2)) ?? 0}
                              status={row.status}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {row.jobType}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {row.assetId}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {row.message}
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {row.name}
                      </TableCell>

                      <TableCell align="right">
                        {/* <IconButton sx={{ color: theme.palette.mode === 'dark' ? 'rgba(204, 204, 220, 1)' : "#1A1F28", padding: 1 }}>
                                            <StopRounded />
                                        </IconButton> */}
                        {STATUS_TO_STYLE_VARIANT_MAPPING[row.status] ===
                          'Failed' && <RestartScan monitorId={row.id} />}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          height: 'auto',
                          borderBottom: 0,
                          padding: 0,
                        }}
                        colSpan={9}
                      >
                        <Collapse
                          in={expandedRows[row.id] || false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <CollapsibleDetailsDrawer monitorId={row.id} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={-1}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={filters.pageSize ?? -1}
        onRowsPerPageChange={onRowsPerPageChange}
        slotProps={{
          actions: {
            nextButton: {
              disabled: !cloudConnectorJobsList?.pagination?.nextPageToken,
            },
            previousButton: {
              disabled: previousPageTokens.length === 0,
            },
          },
        }}
        sx={{
          '& .MuiTablePagination-toolbar': {
            minHeight: '43px !important',
            height: '43px',
            backgroundColor: theme.palette.background.paper,
          },
        }}
      />
    </Box>
  )
}

export default MonitorComponent
