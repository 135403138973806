import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import ReportCard from './report-card'
import GroupTitle from './group-title'
import HorizontalIconWithText from './horizontal-icon-with-text'
import { AwsLogoIcon, VMWareReportsIcon } from '@inline-img/general'
import { AWSReports, BackupConnectorReports } from '../shared'

const summaryReports = [
  {
    title: 'Ransomware Readiness',
    description:
      'Executive Summary on your business’ ransomware readiness posture and risk of data loss (PDF)',
    kind: AWSReports.awsExecutiveReport,
  },
  {
    title: 'Account Summary',
    description: 'Summary of scanned assets by account (PDF)',
    kind: AWSReports.awsAccountSummaryReport,
  },

  {
    title: 'Operational Summary',
    description:
      'A high-level overview of scanning outcomes, active threats, and protected data over the reporting period, helping you quickly assess security posture and operational effectiveness (PDF)',
    kind: AWSReports.awsTenantSummaryReport,
  },
]

const detailedReports = [
  {
    title: 'All Scans',
    description:
      'Detailed information about all scans to monitor Elastio`s protection  (CSV)',
    kind: AWSReports.awsCompletedScansReport,
  },
  {
    title: 'Failed scans',
    description:
      'Detailed information about any failed scans to support troubleshooting (CSV)',
    kind: AWSReports.failedScansReport,
  },
  {
    title: 'Infected files',
    description:
      'Comprehensive list of compromised files for targeted remediation efforts (CSV)',
    kind: AWSReports.infectedFilesReport,
  },
  {
    title: 'Assets Scanned',
    description: 'Detailed information about protected assets (CSV)',
    kind: AWSReports.awsScannedAssetsSummaryReport,
  },
  {
    title: 'Infected Assets',
    description:
      'Comprehensive list of compromised assets for targeted remediation efforts (CSV)',
    kind: AWSReports.awsInfectedAssetsReport,
  },
]

const backupSummaryReportsData = [
  {
    title: 'Ransomware Readiness',
    description:
      'Executive Summary on your business’ ransomware readiness posture and risk of data loss (PDF)',
    kind: BackupConnectorReports.ovaMspExecutiveReport,
  },
  {
    title: 'Account Summary',
    description:
      'Consolidated overview of account details for resource management and usage evaluation (PDF)',
    kind: BackupConnectorReports.ovaMspAccountSummaryReport,
  },
  {
    title: 'Operational Summary',
    description:
      'A high-level overview of scanning outcomes, active threats, and protected data over the reporting period, helping you quickly assess security posture and operational effectiveness (PDF)',
    kind: BackupConnectorReports.ovaMspTenantSummaryReport,
  },
]

const BackupDetailedReportsData = [
  {
    title: 'All Scans',
    description:
      'Detailed information about all scans to monitor Elastio`s protection (CSV)',
    kind: BackupConnectorReports.ovaMspCompletedScansReport,
  },
  {
    title: 'Infected files',
    description:
      'Comprehensive list of compromised files for targeted remediation efforts (CSV)',
    kind: BackupConnectorReports.ovaMspInfectedFilesReport,
  },
  {
    title: 'Assets Scanned',
    description: 'Detailed information about protected assets (CSV)',
    kind: BackupConnectorReports.ovaMspScannedAssetsSummaryReport,
  },
  {
    title: 'Infected Assets',
    description:
      'Comprehensive list of compromised assets for targeted remediation efforts (CSV)',
    kind: BackupConnectorReports.ovaMspInfectedAssetsReport,
  },
]

function Overview() {
  return (
    <Box component="section" px={4} aria-labelledby="report-selection">
      {/*<ReportSelectionHeader />*/}
      <Grid
        container
        spacing={4}
        alignItems="stretch"
        sx={{ minHeight: '100vh' }}
      >
        {/* Left Column */}
        <Grid item xs={12} md={5}>
          <HorizontalIconWithText
            title="AWS Reports"
            icon={AwsLogoIcon}
            width="34.96px"
          />
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <GroupTitle title="Summary Reports" />
              {summaryReports.map((report, index) => (
                <Grid item xs={12} sx={{ mb: 2 }} key={index}>
                  <ReportCard
                    title={report.title}
                    description={report.description}
                    kind={report.kind}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} md={6}>
              <GroupTitle title="Detailed Reports" />
              {detailedReports.map((report, index) => (
                <Grid item xs={12} sx={{ mb: 2 }} key={index}>
                  <ReportCard
                    title={report.title}
                    description={report.description}
                    kind={report.kind as string}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Divider */}
        <Grid item sx={{ display: 'flex' }}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              mx: 2,
              height: 'auto',
              alignSelf: 'stretch',
            }}
          />
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={5}>
          <HorizontalIconWithText
            title="VMware Reports"
            icon={VMWareReportsIcon}
            width="86px"
            height="27px"
          />
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <GroupTitle title="Summary Reports" />
              {backupSummaryReportsData.map((report, index) => (
                <Grid item xs={12} sx={{ mb: 2 }} key={index}>
                  <ReportCard
                    title={report.title}
                    description={report.description}
                    kind={report.kind}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} md={6}>
              <GroupTitle title="Detailed Reports" />
              {BackupDetailedReportsData.map((report, index) => (
                <Grid item xs={12} sx={{ mb: 2 }} key={index}>
                  <ReportCard
                    title={report.title}
                    description={report.description}
                    kind={report.kind}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Overview
