/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import type { TableElementEvent } from '@features/DashboardV4/widgets-with-scale/shared'
import styles from './rpo.module.css'
import { isAssetOutOfSchedule, rpoAscParam } from './utilities'
import { RetentionCommonStatistic } from './common-statistic'
import { Scale } from '@features/DashboardV4/widgets-with-scale/shared'
import { useSelector } from 'react-redux'
import {
  getBackups,
  getIsLiveAssetsLoading,
  getLiveAssets,
} from '@store/selectors/list-live-assets.selector'
import { usePoliciesList } from './use-policies-list'
import { AwsRpoStats } from '@lib/clients/aws-statistics/types'
import { DashboardRoutes, DashboardTab, useNavigation } from '@lib/router'
import { WidgetHeader } from '@features/DashboardV4/common'

interface Props {
  isLoading: boolean
  rpoStats: AwsRpoStats
}

function RpoStatsWgt({ isLoading, rpoStats }: Props) {
  const { lowestRpo, highestRpo, backupsOutOfSchedule, rpoStatsQuantilesList } =
    rpoStats
  const { ec2Instances, ebsVolumes } = useSelector(getLiveAssets)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const { policiesList, isPoliciesListLoading } = usePoliciesList()

  const { lastBackups } = useSelector(getBackups)

  const router = useNavigation()
  const inventoryInstancesShowRpoAscPath =
    DashboardRoutes.inventoryTablesWithParams(
      String(DashboardTab.EC2),
      rpoAscParam
    )

  const instancesNumber = ec2Instances.length
  const volumesNumber = ebsVolumes.length
  const instancesOutOfScheduleNumber = ec2Instances.filter((instance) =>
    isAssetOutOfSchedule(instance, lastBackups, policiesList)
  ).length

  const volumesOutOfScheduleNumber = ebsVolumes.filter((volume) =>
    isAssetOutOfSchedule(volume, lastBackups, policiesList)
  ).length

  const handlerNavigateToDrillDownLink = (drillDownLink: string) => () => {
    router.push(drillDownLink)
  }

  const handleNavigateToDrillDownLink = (link: string) => {
    return (event: TableElementEvent) => {
      event.stopPropagation()
      router.push(link)
    }
  }

  const isDataLoading =
    isLoading || isLiveAssetsLoading || isPoliciesListLoading
  const shouldRenderScaleWidget = rpoStatsQuantilesList.length > 0

  return (
    <Box data-testid="rpo-widget">
      <WidgetHeader
        title="Recovery Point Objective (RPO)"
        onClick={handlerNavigateToDrillDownLink(
          inventoryInstancesShowRpoAscPath
        )}
      />

      <Box className={styles.container}>
        <RetentionCommonStatistic
          isLoading={isDataLoading}
          backupsOutOfSchedule={backupsOutOfSchedule}
          lowestRpo={lowestRpo}
          highestRpo={highestRpo}
          rpoStatsQuantilesList={rpoStatsQuantilesList}
          onClick={handleNavigateToDrillDownLink}
          instancesNumber={instancesNumber}
          volumesNumber={volumesNumber}
          instancesOutOfScheduleNumber={instancesOutOfScheduleNumber}
          volumesOutOfScheduleNumber={volumesOutOfScheduleNumber}
        />
        <Box className={styles.wrapScale}>
          {shouldRenderScaleWidget && (
            <Scale isLoading={isLoading} quantiles={rpoStatsQuantilesList} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default RpoStatsWgt
