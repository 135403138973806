import React, { memo } from 'react'
import PaginationModel from '@lib/models/pagination.model'
import { EngineCallback } from '@lib/engine-types'
import PaginationFactory from '@lib/factories/pagination.factory'
import { rowsPerPageOptions } from '@lib/constants/pangination.constant'
import { TablePagination } from '@mui/material'

interface Props {
  pagination: PaginationModel
  onChange: EngineCallback<PaginationModel>
  disabled?: boolean
  withCount?: boolean
  isNextDisabled?: boolean
}

function PaginationBlock({
  pagination,
  onChange,
  disabled = false,
  withCount = true,
  isNextDisabled = false,
}: Props) {
  const onChangePage = (
    _e: React.MouseEvent<HTMLButtonElement> | null,
    p: number
  ) => {
    const newModel = PaginationFactory.clone(pagination, {
      offset: +p * pagination.limit,
      changed: true,
    })

    onChange(newModel)
  }
  const onChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newModel = PaginationFactory.clone(pagination, {
      offset: 0,
      limit: +e.target.value,
      changed: true,
    })

    onChange(newModel)
  }

  if (
    withCount &&
    (!pagination.total || !pagination.limit || !pagination.currentPage)
  ) {
    return null
  }

  const buttonsProps: any = {}
  if (disabled) {
    buttonsProps.disabled = true
  }

  const nextButtonProps: any = {}

  if (isNextDisabled || disabled) {
    nextButtonProps.disabled = true
  }

  // hide pagination if there are no pages
  if (withCount && !pagination.hasPages && !pagination.changed) {
    return null
  }

  return (
    <div
      className={`wrap-1610449736947 newMaterialPagination ${
        !withCount && 'disableCount'
      }`}
    >
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        backIconButtonProps={buttonsProps}
        nextIconButtonProps={nextButtonProps}
        SelectProps={buttonsProps}
        component="div"
        count={withCount ? pagination.total : -1}
        rowsPerPage={pagination.limit}
        page={pagination.currentPage - 1}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </div>
  )
}

export default memo(PaginationBlock)
