/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetItemsData,
  AssetList,
  LiveAssets,
} from 'ui-v2/src/lib/models/assets'
import { AssetsEc2DrawerData } from 'ui-v2/src/lib/use-cases/assets'
import { Asset, Backup, EBS, EC2, EFS, S3Bucket } from 'blues-corejs/dist'
import { AssetsEbsDrawerData } from '../use-cases/assets/ebs/ebs-drawer-data'
import { AssetsS3DrawerData } from '../use-cases/assets/s3/s3-drawer-data'
import { AssetsEfsDrawerData } from '../use-cases/assets/efs/efs-drawer-data'
import { AssetsGenericHostDrawerData } from '../use-cases/assets/generic-host/generic-host-drawer-data'
import { AssetsOvaServerDrawerData } from '../use-cases/assets/ova-server/ova-server.drawer-data'
import { Scan } from '../models/scans'
import { ScansRun } from 'blues-corejs/dist/models/scans/index'

interface GetAssetDetailsParams {
  asset: AssetList
  assetsLiveData: LiveAssets
  assetItemsData?: AssetItemsData
  scans: Array<Scan>
  backups: Map<string, Backup>
}

const combineBackupMaps = (
  map1: Map<string, Backup>,
  map2: Map<string, Backup>
) => {
  const combined = new Map(map1)
  map2.forEach((value, key) => combined.set(key, value))
  return combined
}

export function getAssetDetails({
  asset,
  assetsLiveData,
  assetItemsData,
  scans,
  backups,
}: GetAssetDetailsParams) {
  const liveAssetScans = assetsLiveData.scans.flatMap((scan) => {
    if (scan.ransomwareScan) {
      return scan.ransomwareScan
    }
    if (scan.malwareScan) {
      return scan.malwareScan
    }
    if (scan.filesystemCheckScan) {
      return scan.filesystemCheckScan
    }
    return []
  })

  if (asset.ec2Instance) {
    return new AssetsEc2DrawerData({
      instances: [asset.ec2Instance],
      volumes: assetsLiveData.ebsVolumes,
      lastBackups: combineBackupMaps(
        backups,
        assetsLiveData.lastBackups.backups
      ),
      threats: assetsLiveData.threats,
      scans: [...liveAssetScans, ...scans],
    }).getEc2DrawerData()
  }
  if (asset.ebsVolume) {
    return new AssetsEbsDrawerData({
      volumes: [asset.ebsVolume],
      lastBackups: combineBackupMaps(
        backups,
        assetsLiveData.lastBackups.backups
      ),
      threats: assetsLiveData.threats,
      scans: [...liveAssetScans, ...scans],
    }).getEbsDrawerData()
  }
  if (asset.s3Bucket) {
    return new AssetsS3DrawerData({
      s3Buckets: [asset.s3Bucket],
      lastBackups: combineBackupMaps(
        assetsLiveData.lastBackups.backups,
        backups
      ),
      threats: assetsLiveData.threats,
      scans: [...liveAssetScans, ...scans],
    }).getS3DrawerData()
  }
  if (asset.efsFilesystem) {
    return new AssetsEfsDrawerData({
      filesystems: [asset.efsFilesystem],
      lastBackups: combineBackupMaps(
        assetsLiveData.lastBackups.backups,
        backups
      ),
      threats: assetsLiveData.threats,
      scans: [...liveAssetScans, ...scans],
    }).getEfsDrawerData()
  }
  if (asset.ovaServer) {
    return new AssetsOvaServerDrawerData({
      servers: [asset.ovaServer],
      lastBackups: backups,
      ovaAccounts: [],
      scans: [...liveAssetScans, ...scans],
    }).getOvaServerDrawerData()
  }
  if (asset.genericHost) {
    if (!assetItemsData) {
      return undefined
    }
    return new AssetsGenericHostDrawerData({
      genericHosts: [asset.genericHost],
      threats: assetsLiveData.threats,
      lastBackups: backups,
      assetItemsData,
      scans: [...liveAssetScans, ...scans],
    }).getGenericHostDrawerData()
  }
}

export function getAsset(asset: AssetList) {
  if (asset.ec2Instance) {
    return asset.ec2Instance
  }
  if (asset.ebsVolume) {
    return asset.ebsVolume
  }
  if (asset.s3Bucket) {
    return asset.s3Bucket
  }
  if (asset.efsFilesystem) {
    return asset.efsFilesystem
  }
  if (asset.ovaServer) {
    return asset.ovaServer
  }
  if (asset.genericHost) {
    return asset.genericHost
  }
  return undefined
}

export function isAwsAsset(asset: Asset) {
  return (
    asset instanceof EC2 ||
    asset instanceof EBS ||
    asset instanceof S3Bucket ||
    asset instanceof EFS
  )
}

export const getCompletionTime = (scanRun: ScansRun): Date => {
  const malwareScansCompleteTime = scanRun.malwareScansList.reduce(
    (lastCompleteTime, scan) => {
      if (scan.createdAt > lastCompleteTime) {
        return scan.createdAt
      }
      return lastCompleteTime
    },
    new Date(0)
  )

  const ransomwareScansCompleteTime = scanRun.ransomwareScansList.reduce(
    (lastCompleteTime, scan) => {
      if (scan.createdAt > lastCompleteTime) {
        return scan.createdAt
      }
      return lastCompleteTime
    },
    new Date(0)
  )

  const fsCheckCompleteTime = scanRun.filesystemChecksList.reduce(
    (lastCompleteTime, scan) => {
      if (scan.createdAt > lastCompleteTime) {
        return scan.createdAt
      }
      return lastCompleteTime
    },
    new Date(0)
  )

  return new Date(
    Math.max(
      malwareScansCompleteTime.getTime(),
      ransomwareScansCompleteTime.getTime(),
      fsCheckCompleteTime.getTime()
    )
  )
}
export const computeScanType = (scanRun: ScansRun): string => {
  const accScanType: Array<string> = []

  if (scanRun.ransomwareScansList.length > 0) {
    accScanType.push('Ransomware')
  }
  if (scanRun.malwareScansList.length > 0) {
    accScanType.push('Malware')
  }

  if (scanRun.filesystemChecksList.length > 0) {
    accScanType.push('Filesystem')
  }

  return accScanType.join(', ')
}
