import React, { memo, ReactNode, useMemo, useState } from 'react'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import { EngineCallback, VIRow, VIRowReadonly } from '@lib/engine-types'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import BlueCheckIcon from '@inline-img/general/blue-check-icon'
import SearchIcon from '@inline-img/general/search-icon'
import RemoveIcon from '@inline-img/general/remove-icon'
import {
  Button,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import StrHelper from '@lib/helpers/str.helper'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

const LABEL_FOR_PLURAL = ['Account', 'Region', 'Misconfiguration Type']

interface Props {
  possible: VIRowReadonly
  selected?: VIRow
  onChange: EngineCallback<VIRow>
  label?: string
  disabled?: boolean
  placeholder?: string
  hint?: string
  countLabel?: string
  menuWidth?: string
  onClose?: EngineCallback<VIRow>
  className?: string
  title?: string
  withSearch?: boolean
  isOpen?: boolean
  showNameInTooltip?: boolean
  getLabel?: (v: ValueInterface) => ReactNode
  maxLengthInTooltip?: number
}

function ViMultipleSelect({
  label,
  possible,
  selected = [],
  onChange,
  disabled = false,
  placeholder = '',
  hint = '',
  countLabel = 'items selected',
  menuWidth = '350px',
  onClose,
  className,
  title,
  withSearch = true,
  isOpen = false,
  showNameInTooltip = false,
  getLabel = undefined,
  maxLengthInTooltip = 38,
}: Props) {
  const isAllSelected = possible.length === selected.length
  const [selectedCount, setSelectedCount] = useState<number>(0)

  const onChangeInner = (e: any, child: any) => {
    const selectedValue = e.target.value as Array<string>
    const currentTargetId = child ? child.props.id : ''

    const result: VIRow = []

    if (currentTargetId === 'selectAllItems') {
      if (!isAllSelected) {
        possible.forEach((el) => {
          if (el !== undefined) {
            result.push(el)
          }
        })
      }
    } else {
      const allResult = selectedValue.map((val) =>
        possible.find((v) => v.name === val)
      )
      allResult.forEach((el) => {
        if (el !== undefined) {
          result.push(el)
        }
      })
    }

    setSelectedCount(result.length)

    if (result.length > 0) {
      onChange(ObjHelper.cloneDeep(result))
    } else {
      onChange([])
    }
  }

  const [searchText, setSearchText] = useState<string>('')
  const getOptionLabel = (option: ValueInterface) => option.label ?? option.name

  const displayedOptions = useMemo(() => {
    if (searchText.trim().length > 0) {
      return possible.filter((option) =>
        StrHelper.isContainText(getOptionLabel(option), searchText)
      )
    }
    return possible
  }, [searchText])

  //button All
  const handleAll = () => {
    setSearchText('')
  }

  // button Done
  const [open, setOpen] = useState<boolean>(isOpen)
  const handleOpen = () => {
    setOpen(true)
    setSearchText(' ')
    setSelectedCount(selected.length)
  }
  const handleClose = () => {
    setSearchText('')
    setOpen(false)
    onClose?.()
  }

  const getCountLabel = (items: Array<string>) => {
    if (items.length <= 1) {
      return countLabel
    }
    return LABEL_FOR_PLURAL.includes(countLabel) ? countLabel + 's' : countLabel
  }

  return (
    <div className={`wrap-1653546666509 newMaterialSelects ${className}`}>
      {label && (
        <div className="viSelectLabel jsLabel">
          {label}
          {hint && <QuestionHint variant="inText" text={hint} />}
        </div>
      )}
      <Select
        title={title}
        id="select"
        // @ts-ignore: TODO - Fix TypeScript issue: "placeholder" is not assignable to type SelectProps<string[]>.
        placeholder={placeholder}
        disabled={disabled}
        multiple
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoFocus: false,
          PaperProps: {
            style: {
              width: `${menuWidth}`,
              maxHeight: '410px',
              minWidth: '350px',
            },
          },
        }}
        IconComponent={() => (
          <SelectArrowIcon className="MuiSvgIcon-root MuiSelect-icon" />
        )}
        value={selected.map((item) => item.name)}
        onChange={onChangeInner}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        renderValue={(values) => (
          <div>
            {
              <>
                <span>{(values as Array<string>).length}</span>
                <span>&nbsp;{getCountLabel(values)}</span>
              </>
            }
          </div>
        )}
      >
        <div className="multipleListInfo">{selectedCount} selected</div>

        <Button
          onClick={handleAll}
          variant="contained"
          className="sizeS multilpleListTopBtn"
          id="selectAllItems"
        >
          {isAllSelected ? (
            <>
              <RemoveIcon />
              <span>Clear</span>
            </>
          ) : (
            <>
              <BlueCheckIcon />
              <span>All</span>
            </>
          )}
        </Button>

        {withSearch && (
          <ListSubheader className="multipleListTopInfo" value={'texttexttext'}>
            <div className="v2StaticTextInput multipleListSearch">
              <TextField
                // Autofocus on textfield
                autoFocus
                placeholder="Search"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation()
                  }
                }}
              />
            </div>
          </ListSubheader>
        )}

        {displayedOptions.map((row) => (
          <MenuItem
            disabled={row.disabled}
            key={`${row.name}${row.value}${row.label}`}
            value={row.name}
            className="multipleListOption"
            data-testid="menuItem"
          >
            <div className="multipleListOptionText">
              {getLabel ? (
                getLabel(row)
              ) : (
                <LongTextTooltip
                  text={row.label ?? row.name}
                  maxLength={maxLengthInTooltip}
                  placement="top"
                  tooltipText={showNameInTooltip ? row.name : undefined}
                  show={!row.label?.toString().includes(row.name)}
                />
              )}
            </div>
          </MenuItem>
        ))}

        {displayedOptions.length < 1 && (
          <MenuItem className="multipleListEmptyPlaceholder">
            <div></div>
          </MenuItem>
        )}

        <div className="wrapBtnDone">
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className="sizeS btnDone"
          >
            Done
          </Button>
        </div>
      </Select>
    </div>
  )
}

export default memo(ViMultipleSelect)
