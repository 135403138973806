import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { TextFieldController } from '@features/management-view'
import { MultiSelectSearch } from '@components/shared'
import styles from '../../management-view.module.css'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import { UserSettings } from '@lib/constants'
import { getViewsFromConfig } from '@features/DashboardV4'
import SettingsService from '@lib/services/high/settings.service'
import { useNavigateToDashboard } from './use-navigate-to-dashboard'
import { FormData } from '@features/management-view/backup-connector/form/validations/schema'
import {
  createFormData,
  getUpdatedViewsList,
  OptionType,
  handleSliceCriteria,
} from './helpers'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { DashboardRoutes } from '@lib/router/routes/dashboard/dashboard'
import { useSetupForm } from './use-setup-form'
import { useLayoutContext } from '@features/contexts'
import { TypographyStyled } from '@features/management-view/shared/form'
import { TabViewInterface } from '@features/DashboardV4/management-view'
import { isOVASliceCriteria } from '@features/DashboardV4/management-view/slice-criteria-guards'
import { OvaBackupProvider } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'
import { isEqual } from 'lodash'

function BackupConnectorForm() {
  const navigateToDashboard = useNavigateToDashboard()
  const router = useNavigation()

  const {
    accountOptions,
    backupProviderOptions,
    methods,
    isOvaAccountsLoading,
    currentTabInQuery,
    ovaAccountsList,
    currentView,
    isEdit,
  } = useSetupForm()

  const { setHeaderTitle } = useLayoutContext()

  useEffect(() => {
    setHeaderTitle(isEdit ? 'Edit dashboard view' : 'Add dashboard view')
    return () => setHeaderTitle(null)
  }, [])
  const [filteredAccountOptions, setFilteredAccountOptions] =
    useState<Array<OptionType>>(accountOptions)
  const [filteredVendorOptions, setFilteredVendorOptions] = useState<
    Array<OptionType>
  >(backupProviderOptions)

  const prevAccountOptions = useRef(accountOptions)
  const prevBackupOptions = useRef(backupProviderOptions)

  useEffect(() => {
    if (!isEqual(accountOptions, prevAccountOptions.current)) {
      setFilteredAccountOptions(accountOptions)
    }

    if (!isEqual(backupProviderOptions, prevBackupOptions.current)) {
      setFilteredVendorOptions(backupProviderOptions)
    }

    prevAccountOptions.current = accountOptions
    prevBackupOptions.current = backupProviderOptions
  }, [accountOptions, backupProviderOptions, currentView])

  const handleAccountChange = (selectedAccounts: Array<OptionType>) => {
    if (selectedAccounts.length === 0) {
      setFilteredVendorOptions(backupProviderOptions)
      return
    }

    const selectedAccountIds = selectedAccounts.map((account) => account.value)
    const newVendorOptions = backupProviderOptions.filter((provider) =>
      ovaAccountsList.some(
        (account) =>
          selectedAccountIds.includes(account.id) &&
          account.backupProvidersList.includes(provider.value as number)
      )
    )
    setFilteredVendorOptions(newVendorOptions)
  }

  const handleBackupVendorChange = (selectedVendors: Array<OptionType>) => {
    if (selectedVendors.length === 0) {
      setFilteredAccountOptions(accountOptions)
      return
    }

    const selectedVendorValues = selectedVendors.map((vendor) => vendor.value)

    const newAccountOptions = accountOptions.filter((account) =>
      ovaAccountsList.some(
        (acc) =>
          acc.id === account.value &&
          acc.backupProvidersList.some((provider) =>
            selectedVendorValues.includes(provider)
          )
      )
    )
    setFilteredAccountOptions(newAccountOptions)
  }

  const areAllFieldsDisabled =
    methods.formState.isSubmitting || isOvaAccountsLoading

  useEffect(() => {
    if (!currentTabInQuery) {
      return
    }

    if (!currentView) {
      router.push(DashboardRoutes.root)
      return
    }

    if (isOVASliceCriteria(currentView.slice)) {
      methods.reset({
        name: currentView.name,
        isDefaultDashboard: currentView.isDefaultDashboard,
        backupVendors: currentView.slice.backupVendors?.map(
          (vendor: OvaBackupProvider) =>
            backupProviderOptions.find((item) => item.value === vendor)
        ),
        accounts: currentView.slice.accountIds?.map((id) =>
          accountOptions.find((item) => item.value === id)
        ),
      })
    }
  }, [currentTabInQuery, accountOptions, backupProviderOptions, methods])

  const handleUpdateView = async (data: TabViewInterface) => {
    const currentViews = getViewsFromConfig()
    const updatedViews = currentViews.map((view) =>
      view.id === data.id ? data : view
    )

    handleSliceCriteria(data.slice)
    await SettingsService.updateSetting(UserSettings.TabViews, updatedViews)

    navigateToDashboard(data.id, updatedViews)
  }

  const handleCreateView = async (data: TabViewInterface) => {
    const updatedViews = getUpdatedViewsList(
      getViewsFromConfig(),
      data,
      data.isDefaultDashboard
    )

    handleSliceCriteria(data.slice)
    await SettingsService.updateSetting(UserSettings.TabViews, updatedViews)

    navigateToDashboard(data.id, updatedViews)
  }

  const handleOnCancel = () => {
    router.push(DashboardRoutes.root)
  }

  const handleSubmit = (data: FormData) => {
    const formData = createFormData(data, currentView?.id)

    if (isEdit) {
      handleUpdateView(formData)
      return
    } else {
      handleCreateView(formData)
    }
  }

  return (
    <FormProvider {...methods}>
      {isEdit ? (
        <TypographyStyled sx={{ marginBottom: 0 }}>
          Edit dashboard view
        </TypographyStyled>
      ) : null}

      <Box component="form" id="backup-connector-form" className={styles.form}>
        <Typography
          variant="body1"
          marginY="16px"
          fontSize="16px"
          fontWeight="500"
        >
          Dashboard name
        </Typography>

        <Controller
          name="name"
          control={methods.control}
          render={({ field, fieldState: { error } }) => (
            <TextFieldController
              // @ts-ignore
              field={field}
              error={error}
              disabled={areAllFieldsDisabled}
            />
          )}
        />
        <Typography
          variant="body1"
          marginTop="16px"
          marginBottom="16px"
          lineHeight="180%"
          fontSize="16px"
          fontWeight="500"
        >
          Backup Vendor
        </Typography>
        <Box>
          <MultiSelectSearch
            name="backupVendors"
            options={filteredVendorOptions}
            placeholder="Select backup vendors"
            disabled={areAllFieldsDisabled}
            onSelectionChange={handleBackupVendorChange}
          />
        </Box>

        <Typography
          variant="body1"
          marginTop="16px"
          marginBottom="16px"
          lineHeight="180%"
          fontSize="16px"
          fontWeight="500"
        >
          Accounts
        </Typography>

        <Box>
          <MultiSelectSearch
            name={'accounts'}
            options={filteredAccountOptions}
            placeholder="Select accounts..."
            disabled={areAllFieldsDisabled}
            onSelectionChange={handleAccountChange}
          />
        </Box>

        <Box mt={2}>
          <Controller
            name={'isDefaultDashboard'}
            control={methods.control}
            render={({ field }) => (
              <FormControlLabel
                disabled={areAllFieldsDisabled}
                control={<Checkbox checked={field.value} {...field} />}
                label="Default Dashboard"
              />
            )}
          />
        </Box>

        <BasePortalButtons alwaysInPortal>
          <Box component="div" className="portalBetweenButtons">
            <Button
              variant="text"
              color="primary"
              className="showButton jsCloseCloudInstallerPage"
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
            <Button
              form="backup-connector-form"
              type="button"
              variant="contained"
              color="primary"
              disabled={methods.formState.isSubmitting}
              className="sizeS ml16"
              onClick={methods.handleSubmit(handleSubmit)}
            >
              {isEdit ? 'Update view' : 'Create new view'}
            </Button>
          </Box>
        </BasePortalButtons>
      </Box>
    </FormProvider>
  )
}

export default BackupConnectorForm
