import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { Option, TimeRangeSelection } from 'ui-v2/src/lib/models/reports'

export const TIME_RANGE_MENU_ITEMS = [
  {
    label: 'Today',
    value: TimeRangeSelection.TODAY,
  },
  {
    label: 'Last 7 days',
    value: TimeRangeSelection.LAST_7_DAYS,
  },
  {
    label: 'Last 30 days',
    value: TimeRangeSelection.LAST_30_DAYS,
  },
  {
    label: 'Custom date',
    value: TimeRangeSelection.CUSTOM_DATE,
  },
]

const TimeRange = ({
  value,
  error,
  isSelectedObj,
  onChange,
}: ReportPeriodProps) => {
  return (
    <BasicSelect
      value={value}
      options={TIME_RANGE_MENU_ITEMS}
      onChange={onChange}
      error={error}
      isSelectedObj={isSelectedObj}
    />
  )
}
interface ReportPeriodProps {
  value: string | number
  error?: boolean
  isSelectedObj?: boolean
  onChange: (value: Option | string) => void
}
export default TimeRange
