import LangHelper from '@lib/helpers/lang.helper'
import { useMemo } from 'react'

interface Props {
  accounts: Array<string>
  regionsWithRelatedAccounts: Map<string, Array<string>>
}

export function useUniqueRegions({
  accounts,
  regionsWithRelatedAccounts,
}: Props) {
  return useMemo(() => {
    const filteredRegions = new Set<string>()

    for (const [region, accountIds] of regionsWithRelatedAccounts) {
      if (
        accounts.length > 0 &&
        !accountIds.some((item) => accounts.includes(item))
      ) {
        continue
      }

      filteredRegions.add(region)
    }

    return [...filteredRegions].map((item) => ({
      value: item,
      label: LangHelper.getAwsRegionSingleTranslation(item),
    }))
  }, [accounts.length, regionsWithRelatedAccounts.size])
}
