//import AccountStatusConstant from 'ui-v2/src/lib/constants/account-status.constant'
//import RedStackStatusConstant from 'ui-v2/src/lib/constants/red-stack-status.constant'
import { VIMatrix } from 'ui-v2/src/lib/engine-types'
//import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
//import { CCwithProblems } from 'ui-v2/src/lib/models/red-stack/integrity-check.interface'
//import VaultModel from 'ui-v2/src/lib/models/settings/policies/vault.model'
import {
  //ProblematicCC,
  SourcesListTableDataAttrs,
  SourcesListTableRow,
} from 'ui-v2/src/lib/models/settings/sources/sources'

//const REPAIR_PREFIX = 'Repair - '

export class SourcesTableData {
  readonly #sourcesList: VIMatrix
  //readonly #redStackListData: Array<RedStackModel>
  //readonly #allActiveRedStacksData: Array<RedStackModel>
  //readonly #vaultListData: Array<VaultModel>
  //readonly #problematicCC: ProblematicCC
  //readonly #cloudConnectorsInAction: Array<string>

  constructor(parameters: SourcesListTableDataAttrs) {
    this.#sourcesList = parameters.sourcesList
    //this.#redStackListData = parameters.redStackListData
    //this.#allActiveRedStacksData = parameters.allActiveRedStacksData
    //this.#vaultListData = parameters.vaultListData
    //this.#problematicCC = parameters.problematicCC
    //this.#cloudConnectorsInAction = parameters.cloudConnectorsInAction
  }

  getSourcesList = (): Array<SourcesListTableRow> => {
    return this.#sourcesList.map((source) => {
      const accountId = source?.[1]?.name
      const accountAlias = source?.[2]?.name
      const accountDescription = source?.[3]?.name
      const regions = source?.[4]?.name
      // TODO: Menu

      // const getMenuByStatus = (status: AccountStatusConstant): Array<MENU> => {
      //   switch (status) {
      //     case AccountStatusConstant.CONNECTED:
      //       return [MENU.Edit, MENU.Unlink]
      //     case AccountStatusConstant.MIXED:
      //       return [MENU.Reconnect, MENU.Edit, MENU.Unlink]
      //     case AccountStatusConstant.DISCONNECTED:
      //       return [MENU.Reconnect, MENU.Unlink]
      //     case AccountStatusConstant.UPGRADE_REQUIRED:
      //       return [MENU.Upgrade, MENU.Edit, MENU.Unlink]
      //     default:
      //       return []
      //   }
      // }

      // const menuIsAvailable = (menu: string): boolean => {
      //   if (menu.includes(REPAIR_PREFIX)) {
      //     const region = menu.replace(REPAIR_PREFIX, '')
      //     const selectedCloudConnectorId = this.#allActiveRedStacksData.find(
      //       (ar) =>
      //         ar.awsAccount === accountId && ar.awsRegionFormatted === region
      //     )?.redStackId
      //     if (selectedCloudConnectorId) {
      //       return !this.#cloudConnectorsInAction.find(
      //         (cc) => cc === selectedCloudConnectorId
      //       )
      //     }
      //   }
      //   return true
      // }

      // const accountNotDeletedRedStacks = this.#redStackListData.filter(
      //   (r) =>
      //     r.awsAccount === accountId &&
      //     r.status !== RedStackStatusConstant.DELETED
      // )

      // const account = this.#problematicCC.problemsArray.find(
      //   (r: CCwithProblems) => r.model.awsAccount === accountId
      // )

      // const redStackAccounts = this.#redStackListData.filter(
      //   (rs) => rs.awsAccount === account?.model.awsAccount
      // )

      // const problematicCCByAcc = this.#problematicCC.problemsArray.filter(
      //   (r: CCwithProblems) => r.model.awsAccount === accountId
      // )

      // const brokenRedStacksByAcc = problematicCCByAcc
      //   ?.filter((brokenRS: CCwithProblems) => {
      //     return this.#allActiveRedStacksData.map(
      //       (rs) => rs.awsAccount === brokenRS?.model.awsAccount
      //     )
      //   })
      //   .map((brokenActiveRS) => brokenActiveRS.model)

      // const accountsVaults = this.#vaultListData.filter((vault) =>
      //   redStackAccounts.some((rs) => rs.redStackId === vault.redStackId)
      // )

      //const regionsWithDefault: Array<string> = []
      //const regionsWithoutDefault: Array<string> = []

      // const vaultsWithoutDefault = accountsVaults
      //   .reduce((result, vault) => {
      //     if (vault.isDefault) {
      //       regionsWithDefault.push(vault.region)
      //       return result
      //     }

      //     if (!regionsWithoutDefault.includes(vault.region)) {
      //       regionsWithoutDefault.push(vault.region)
      //       result.push(vault)
      //     }

      //     return result
      //   }, [] as Array<VaultModel>)
      //   .filter((vault) =>
      //     regionsWithDefault.every((val) => val !== vault.region)
      //   )

      /*const menu = [
        ...[], // TODO: ...getMenuByStatus(props.data[5].type),
        ...source[4].name
          .split(', ')
          .filter((r: string) => !!r.trim())
          .map((r: string) => `${REPAIR_PREFIX}${r}`),
      ]*/

      return new SourcesListTableRow({
        id: accountId ?? '',
        accountId: accountId ?? '',
        aliasName: accountAlias ?? '',
        description: accountDescription ?? '',
        regions: regions ?? '',
        action: '',
      })
    })
  }
}
