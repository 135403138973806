/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { EC2, EBS, EFS, S3Bucket, GenericHost } from 'blues-corejs/dist'
// import { RowTitle } from '../../row-title'
import { computeAssetCopiableId } from './compute-asset-copiable-id'
import { computeAssetId } from './compute-asset-id'
import { computeAssetTitle } from './compute-asset-title'
import { CopyToClipboardIcon } from '@features/common'

function RowTitle({ title }: { title: string }) {
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      marginRight="5px"
      variant="caption"
    >
      {title}:
    </Typography>
  )
}

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

interface Props {
  asset: Asset
}

const CLIPBOARD_ICON_SIZE = 16

export function AssetId({ asset }: Props) {
  const title = computeAssetTitle(asset)

  const assetId = computeAssetId(asset)

  const assetCopiableId = computeAssetCopiableId(asset)

  return (
    <Box>
      <RowTitle title={title} />
      <Typography variant="caption" fontSize="14px">
        {assetId}
      </Typography>
      <CopyToClipboardIcon
        text={assetCopiableId}
        fontSize={CLIPBOARD_ICON_SIZE}
      />
    </Box>
  )
}
