"use strict";
// took from
// ./blue-stack-libs/jobstatus-grpc-libs/js/jobstatus/messages/job_kind_pb.d.ts
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobKindConstant = void 0;
var JobKindConstant;
(function (JobKindConstant) {
    JobKindConstant[JobKindConstant["UNDEFINED"] = -1] = "UNDEFINED";
    JobKindConstant[JobKindConstant["UNKNOWN"] = 0] = "UNKNOWN";
    JobKindConstant[JobKindConstant["INSTALL_CLOUD_CONNECTOR"] = 1] = "INSTALL_CLOUD_CONNECTOR";
    JobKindConstant[JobKindConstant["CREATE_VAULT"] = 2] = "CREATE_VAULT";
    JobKindConstant[JobKindConstant["EBS_BACKUP"] = 3] = "EBS_BACKUP";
    JobKindConstant[JobKindConstant["EBS_RESTORE"] = 4] = "EBS_RESTORE";
    JobKindConstant[JobKindConstant["EC2_BACKUP"] = 5] = "EC2_BACKUP";
    JobKindConstant[JobKindConstant["EC2_RESTORE"] = 6] = "EC2_RESTORE";
    JobKindConstant[JobKindConstant["EFS_BACKUP"] = 7] = "EFS_BACKUP";
    JobKindConstant[JobKindConstant["ROLLUP"] = 8] = "ROLLUP";
    JobKindConstant[JobKindConstant["DESTROY_VAULT"] = 9] = "DESTROY_VAULT";
    JobKindConstant[JobKindConstant["REFRESH_PLANS"] = 10] = "REFRESH_PLANS";
    JobKindConstant[JobKindConstant["SET_PLAN_STATUS"] = 11] = "SET_PLAN_STATUS";
    JobKindConstant[JobKindConstant["RESYNC"] = 12] = "RESYNC";
    JobKindConstant[JobKindConstant["ACTIVATE_CLOUD_CONNECTOR"] = 13] = "ACTIVATE_CLOUD_CONNECTOR";
    JobKindConstant[JobKindConstant["ANALYZE_CLOUD_CONNECTOR_TARGET_REGION"] = 14] = "ANALYZE_CLOUD_CONNECTOR_TARGET_REGION";
    JobKindConstant[JobKindConstant["EVALUATE_PLANS"] = 15] = "EVALUATE_PLANS";
    JobKindConstant[JobKindConstant["BLOCK_BACKUP"] = 16] = "BLOCK_BACKUP";
    JobKindConstant[JobKindConstant["BLOCK_RESTORE"] = 17] = "BLOCK_RESTORE";
    JobKindConstant[JobKindConstant["FILE_BACKUP"] = 18] = "FILE_BACKUP";
    JobKindConstant[JobKindConstant["FILE_RESTORE"] = 19] = "FILE_RESTORE";
    JobKindConstant[JobKindConstant["STREAM_BACKUP"] = 20] = "STREAM_BACKUP";
    JobKindConstant[JobKindConstant["STREAM_RESTORE"] = 21] = "STREAM_RESTORE";
    JobKindConstant[JobKindConstant["SCAN_INVENTORY"] = 22] = "SCAN_INVENTORY";
    JobKindConstant[JobKindConstant["ISCAN"] = 23] = "ISCAN";
    JobKindConstant[JobKindConstant["ENABLE_ELASTIO_REPORTS_GATHERING"] = 24] = "ENABLE_ELASTIO_REPORTS_GATHERING";
    JobKindConstant[JobKindConstant["DISABLE_ELASTIO_REPORTS_GATHERING"] = 25] = "DISABLE_ELASTIO_REPORTS_GATHERING";
    JobKindConstant[JobKindConstant["UPGRADE_CLOUD_CONNECTOR"] = 26] = "UPGRADE_CLOUD_CONNECTOR";
    JobKindConstant[JobKindConstant["UPGRADE_VAULT"] = 27] = "UPGRADE_VAULT";
    JobKindConstant[JobKindConstant["REPAIR_REPORT_GATHERING_INFRA"] = 28] = "REPAIR_REPORT_GATHERING_INFRA";
    JobKindConstant[JobKindConstant["CLEANUP_RED_STACK_DATA"] = 29] = "CLEANUP_RED_STACK_DATA";
    JobKindConstant[JobKindConstant["REPAIR_CLOUD_CONNECTOR"] = 30] = "REPAIR_CLOUD_CONNECTOR";
    JobKindConstant[JobKindConstant["REPAIR_VAULT"] = 31] = "REPAIR_VAULT";
    JobKindConstant[JobKindConstant["UPDATE_VAULT_SUBNETS"] = 32] = "UPDATE_VAULT_SUBNETS";
    JobKindConstant[JobKindConstant["CLOUD_CONNECTOR_INTEGRITY_CHECK"] = 33] = "CLOUD_CONNECTOR_INTEGRITY_CHECK";
    JobKindConstant[JobKindConstant["CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK"] = 34] = "CLOUD_CONNECTOR_INFRASTRUCTURE_CHECK";
    JobKindConstant[JobKindConstant["VAULT_INFRASTRUCTURE_CHECK"] = 35] = "VAULT_INFRASTRUCTURE_CHECK";
    JobKindConstant[JobKindConstant["VAULT_NETWORKING_CHECK"] = 36] = "VAULT_NETWORKING_CHECK";
    JobKindConstant[JobKindConstant["EXECUTE_BACKUP_POLICY"] = 37] = "EXECUTE_BACKUP_POLICY";
    JobKindConstant[JobKindConstant["CLEANUP_BACKUP_POLICY_COVERAGE"] = 38] = "CLEANUP_BACKUP_POLICY_COVERAGE";
    JobKindConstant[JobKindConstant["REFRESH_CONNECTOR_RETENTION_POLICIES"] = 39] = "REFRESH_CONNECTOR_RETENTION_POLICIES";
    JobKindConstant[JobKindConstant["EVALUATE_RETENTION_POLICIES"] = 40] = "EVALUATE_RETENTION_POLICIES";
    JobKindConstant[JobKindConstant["IMPORT_SNAPSHOTS"] = 41] = "IMPORT_SNAPSHOTS";
    JobKindConstant[JobKindConstant["BACKUP_INTEGRITY_CHECK"] = 42] = "BACKUP_INTEGRITY_CHECK";
    JobKindConstant[JobKindConstant["UPDATE_VAULT"] = 43] = "UPDATE_VAULT";
    JobKindConstant[JobKindConstant["APPLY_RETENTION_POLICY"] = 44] = "APPLY_RETENTION_POLICY";
    JobKindConstant[JobKindConstant["FS_CHECK"] = 45] = "FS_CHECK";
    JobKindConstant[JobKindConstant["POSTGRES_BACKUP"] = 46] = "POSTGRES_BACKUP";
    JobKindConstant[JobKindConstant["POSTGRES_RESTORE"] = 47] = "POSTGRES_RESTORE";
    JobKindConstant[JobKindConstant["AWS_BACKUP_IMPORT"] = 48] = "AWS_BACKUP_IMPORT";
    JobKindConstant[JobKindConstant["VAULT_CATALOG_RESTORE"] = 49] = "VAULT_CATALOG_RESTORE";
    JobKindConstant[JobKindConstant["EVALUATE_ASSETS_INTEGRITY_STATUS"] = 50] = "EVALUATE_ASSETS_INTEGRITY_STATUS";
    JobKindConstant[JobKindConstant["DATABASE_MAINTENANCE"] = 51] = "DATABASE_MAINTENANCE";
    JobKindConstant[JobKindConstant["POSTGRES_RECOVERY_TEST"] = 52] = "POSTGRES_RECOVERY_TEST";
    JobKindConstant[JobKindConstant["S3_BACKUP"] = 53] = "S3_BACKUP";
    JobKindConstant[JobKindConstant["REFRESH_ASSET_THREAT_STATUSES"] = 54] = "REFRESH_ASSET_THREAT_STATUSES";
    JobKindConstant[JobKindConstant["S3_RESTORE"] = 55] = "S3_RESTORE";
    JobKindConstant[JobKindConstant["GENERATE_SCHEDULED_REPORT"] = 56] = "GENERATE_SCHEDULED_REPORT";
    JobKindConstant[JobKindConstant["DELETE_CLOUD_CONNECTOR"] = 57] = "DELETE_CLOUD_CONNECTOR";
})(JobKindConstant || (exports.JobKindConstant = JobKindConstant = {}));
