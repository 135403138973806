import { tabConfigurations } from './tab-configurations'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import Tab from '@mui/material/Tab'
import { useTabsActions } from '@features/reports/schedules/use-data-store'

interface TabsRendererOwnProps {
  currentTab: number
  onChangeTab: (tab: number) => void
}
const tabKeys = Object.keys(tabConfigurations).map(Number)
const additionalTabsStyles = {
  marginTop: '16px',
}

function TabsRenderer({ currentTab, onChangeTab }: TabsRendererOwnProps) {
  const { setCurrentTab } = useTabsActions()
  return (
    <CustomTabs
      value={currentTab}
      onChangeTab={onChangeTab}
      sx={additionalTabsStyles}
      className={'_no-border'}
    >
      {tabKeys.map((tabKey) => (
        <Tab
          // @ts-ignore
          onClick={() => setCurrentTab(tabConfigurations[tabKey].tabName)}
          key={tabKey}
          // @ts-ignore
          label={tabConfigurations[tabKey].label}
          value={tabKey}
          // @ts-ignore
          data-testid={`${tabConfigurations[tabKey].label
            .toLowerCase()
            .replace(' ', '-')}-tab`}
        />
      ))}
    </CustomTabs>
  )
}

export default TabsRenderer
