import Typography from '@mui/material/Typography'

function InfectedServerCell({ isInfected }: { isInfected: boolean }) {
  const computedLabel = isInfected ? 'Infected' : 'Healthy'
  const computedColor = isInfected ? 'var(--red500)' : 'var(--green600)'
  return (
    <Typography
      fontSize="14px"
      variant="caption"
      fontWeight="500"
      color={computedColor}
    >
      {computedLabel}
    </Typography>
  )
}

export default InfectedServerCell
