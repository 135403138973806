import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const StyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  marginLeft: '24px',
  marginRight: '24px',

  '.slider-container': {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '16px',
    minHeight: '280px',
    paddingInline: '8px',
  },
})

export const StyledSliderLine = styled(Box)({
  width: '100%',
  transform: 'translateY(1px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
})
