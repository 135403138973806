import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import SearchIcon from '@mui/icons-material/Search'
import { FormProvider, useForm } from 'react-hook-form'
import {
  DateRangePicker,
  reportOptions,
  Title,
} from '@features/reports/schedules/shared'
import { IconButton, InputAdornment } from '@mui/material'
import useDebouncedFunction from '@lib/hooks/useDebouncedFunction'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { DateRangeTuple } from '@lib/engine-types'
import {
  TabName,
  useTabsActions,
} from '@features/reports/schedules/use-data-store'
import { transformDateRangeForApi } from '@components-composite/date-range-picker-with-call-api/transform-date-range-for-api'
import {
  ListGeneratedReportsRequestParams,
  OptionalReportKind,
} from '@lib/clients/reports/generated-reports/list-generated-reports-filter'
import moment from 'moment'
import FormControl from '@mui/material/FormControl'
import { BasicSelect, Value } from '@components/shared'
import { SelectChangeEvent } from '@mui/material/Select'
import { StyledInputBase } from '@components-simple/styled-form-components/styled-input-base'

const SEARCH_NAME = 'search'
const REPORT_TYPE_NAME = 'reportType'

type FormData = {
  search: string
  reportType: string
}

export const StyledInputAdornment = styled(InputAdornment)({
  position: 'absolute',
  right: 0,
})

function SearchFilterBar() {
  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      search: '',
      reportType: '',
    },
  })

  const { setTabFilterValues } = useTabsActions()

  const searchValue = methods.watch(SEARCH_NAME)
  const reportType = methods.watch(REPORT_TYPE_NAME)
  const [dateRange, setDateRange] = useState<DateRangeTuple>([
    moment().startOf('month'),
    moment(),
  ])

  const handleChangeFieldValue = (e: BaseSyntheticEvent) => {
    methods.setValue(SEARCH_NAME, e.target.value)
  }

  const handleDateChange = (newDateRange: DateRangeTuple) => {
    setDateRange(newDateRange)
  }

  const handleReportTypeChange = (e: SelectChangeEvent<Value>) => {
    methods.setValue(REPORT_TYPE_NAME, String(e.target.value))
  }

  const handleReportTypeSelectedClick = () => {
    methods.setValue(REPORT_TYPE_NAME, '')
  }

  const submitDebounced = useDebouncedFunction(() => {
    const dateForApi = transformDateRangeForApi(dateRange)
    const filterValue: ListGeneratedReportsRequestParams = {
      textSearch: searchValue || undefined,
      reportKind: (reportType as unknown as OptionalReportKind) || undefined,
      rangeStartAt: dateForApi.start,
      rangeEndAt: dateForApi.end,
    }
    setTabFilterValues<ListGeneratedReportsRequestParams>(
      TabName.history,
      filterValue
    )
  }, 450)

  useEffect(() => {
    submitDebounced()
  }, [searchValue, reportType, dateRange])

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2} alignItems="end">
        <Grid item xs={4}>
          <StyledInputBase
            name={SEARCH_NAME}
            onChange={handleChangeFieldValue}
            placeholder="Search Schedule Name and Description"
            endAdornment={
              <StyledInputAdornment position="end">
                <IconButton size="small">
                  <SearchIcon />
                </IconButton>
              </StyledInputAdornment>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <BasicSelect
              value={reportType}
              onChange={handleReportTypeChange}
              options={reportOptions}
              placeholder={'Report Type'}
              inputSize="small"
              onSelectedClick={handleReportTypeSelectedClick}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Box flex={1}>
            <Title>Date Range</Title>
            <DateRangePicker
              dateRange={dateRange}
              onDateChange={handleDateChange}
            />
          </Box>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default SearchFilterBar
