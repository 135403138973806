import { BaseRoutes } from '../base'

export enum RouteFragments {
  CLOUD_CONNECTOR = 'cloud-connector',
  DEPLOYMENT = 'deployment',
  SYSTEM = 'system',
}

export class JobsRoutes extends BaseRoutes {
  public static get cloudConnector(): string {
    return this.buildRoute(RouteFragments.CLOUD_CONNECTOR)
  }

  public static get deployment(): string {
    return this.buildRoute(RouteFragments.DEPLOYMENT)
  }

  public static get system(): string {
    return this.buildRoute(RouteFragments.SYSTEM)
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_JOBS}/${fragments.join('/')}`
  }

  static #ROUTE_JOBS = '/jobs'
}
