import { z } from 'zod'
import { FORM_FIELDS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts'
import { SUPPRESS_EVENTS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts/mode'

export const FormSchema = z
  .object({
    [FORM_FIELDS.RULE_NAME]: z
      .string()
      .min(1, { message: 'Rule name is required' }),
    [FORM_FIELDS.DESCRIPTION]: z.string().optional(),
    [FORM_FIELDS.MODE]: z.string().optional(),
    [FORM_FIELDS.SUPPRESS_UNTIL]: z.any().optional(),
    [FORM_FIELDS.REASON_SUPPRESSION]: z.string().optional(),
  })
  .refine(
    (data) =>
      data[FORM_FIELDS.MODE] !== SUPPRESS_EVENTS ||
      (data[FORM_FIELDS.SUPPRESS_UNTIL] &&
        data[FORM_FIELDS.REASON_SUPPRESSION]),
    {
      message: 'Fields suppress until and reason for suppression are required',
      path: [FORM_FIELDS.REASON_SUPPRESSION],
    }
  )

export type FormData = z.infer<typeof FormSchema>
