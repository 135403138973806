import { useState, useEffect, useCallback } from 'react'
import {
  getIsLiveAssetsLoading,
  getLiveAssets,
} from '@store/selectors/list-live-assets.selector'
import { useSelector } from 'react-redux'
import { EC2 } from '@lib/models/assets'
import { Nullable } from '@lib/engine-types'

type UseEC2InstanceOutput = {
  asset: Nullable<EC2>
  isAssetLoading: boolean
}

const processAsset = (ec2Instances: Array<EC2>, assetId: string) => {
  const asset = ec2Instances.find(({ id }) => id === assetId)

  if (!asset) {
    throw new Error('assetId not found')
  }
  return asset
}

function useEC2Instance(assetId: string): UseEC2InstanceOutput {
  const [asset, setAsset] = useState<Nullable<EC2>>(null)
  const { ec2Instances } = useSelector(getLiveAssets)
  const isAssetLoading = useSelector(getIsLiveAssetsLoading)

  const findAsset = useCallback(
    (): Nullable<EC2> => processAsset(ec2Instances, assetId),
    [ec2Instances.length, assetId]
  )

  useEffect(() => {
    if (ec2Instances.length) {
      setAsset(findAsset())
    }

    return () => {
      setAsset(null)
    }
  }, [isAssetLoading, findAsset])

  return {
    asset,
    isAssetLoading,
  }
}

export { useEC2Instance }
