import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'

export const SCAN_WITH_ELASTIO_NAME = 'Live Scan'
export const SCAN_AND_BACKUP_WITH_ELASTIO_NAME = 'Backup and Scan with Elastio'
export const BACKUP_WITH_ELASTIO_NAME = 'Backup with Elastio'

export const IntegrityScanOptionToHumanReadable = {
  [IntegrityScanOptions.SCAN_WITH_ELASTIO]: SCAN_WITH_ELASTIO_NAME,
  [IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO]:
    SCAN_AND_BACKUP_WITH_ELASTIO_NAME,
  [IntegrityScanOptions.BACKUP_WITH_ELASTIO]: BACKUP_WITH_ELASTIO_NAME,
}
