/* eslint-disable import/no-cycle */
import React, { SetStateAction, useEffect, Dispatch } from 'react'
import useAwsFileScanCoverageData from 'ui-v2/src/hooks/dashboard-kpi/aws-scan-coverage-stats'
import CoverageCard from './coverage-card'
import useAwsBackupCoverageData from 'ui-v2/src/hooks/dashboard-kpi/aws-backup-coverage'
import { CARD_TYPES, CardLoadingState } from '..'
import { Stack } from '@mui/material'

type CoverageCardsProps = {
  cardLoadingState: CardLoadingState
  setCardLoadingState: Dispatch<SetStateAction<CardLoadingState>>
}

const CoverageCards: React.FC<CoverageCardsProps> = ({
  cardLoadingState,
  setCardLoadingState,
}) => {
  const { data: scanCoverageData, isLoading: isScanCoverageDataLoading } =
    useAwsFileScanCoverageData()

  const { data: backupCoverageData, isLoading: isBackupCoverageDataLoading } =
    useAwsBackupCoverageData()

  const isLoading = isScanCoverageDataLoading || isBackupCoverageDataLoading

  useEffect(() => {
    setCardLoadingState((prevState) => ({
      ...prevState,
      [CARD_TYPES.COVERAGE_CARDS]: isLoading,
    }))
  }, [isLoading])

  const cardsLoading = Object.values(cardLoadingState).some((value) => value)

  if (cardsLoading) {
    return null
  }

  return (
    <Stack gap={2}>
      {[...scanCoverageData, ...backupCoverageData].map((coverage) => (
        <CoverageCard
          key={coverage.slug}
          slug={coverage.slug}
          count={coverage.count}
          totalCount={coverage.totalCount}
          link={coverage.link}
        />
      ))}
    </Stack>
  )
}

export default CoverageCards
