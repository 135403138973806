import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { ButtonPrimary } from '@components/shared/button'
import { Label } from '@features/alerts/create-rule-drawer/shared'
import { emailSchema, type EmailFormData } from './email-schema'
import {
  useEmailSelectionState,
  useEmailSelectionActions,
  EmailUser,
} from './email-store'
import { useConfirmDeleteStore } from '@features/alerts/create-rule-drawer/confirm-delete-modal/use-confirm-delete-modal'

function EmailUsers() {
  const emails = useEmailSelectionState()
  const { addEmail, removeEmail } = useEmailSelectionActions()
  const { setOpen } = useConfirmDeleteStore()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EmailFormData>({
    resolver: zodResolver(emailSchema),
  })

  const onSubmit = (data: EmailFormData) => {
    addEmail(data.email)
    reset()
  }

  const handleRemoveEmail = (email: EmailUser) => {
    setOpen({
      isOpen: true,
      modalText: `Are you sure you want to remove ${email.email}?`,
      confirmCallback: () => {
        removeEmail(email.id)
      },
    })
  }

  const hasEmails = emails.length > 0

  return (
    <Box
      sx={{
        maxWidth: '70%',
        mt: '1rem',
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                placeholder="Add non-Console email addresses"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          <ButtonPrimary type="submit" variant="contained">
            Add
          </ButtonPrimary>
        </Box>
      </Box>

      {hasEmails && (
        <Box sx={{ mb: 2 }}>
          <Label gutterBottom={false}>Recipients</Label>
          <Box
            sx={{
              bgcolor: 'background.paper',
              borderRadius: 1,
              px: 2,
            }}
          >
            {emails.map((email) => (
              <Box
                key={email.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  py: '4px',
                  borderBottom: '1px solid #d3d8de',
                }}
              >
                <Typography sx={{ fontSize: '14px' }}>{email.email}</Typography>
                <IconButton
                  onClick={() => handleRemoveEmail(email)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default EmailUsers
