import ActionInterface from '@lib/interfaces/action.interface'
import {
  SET_EDIT_TOKEN,
  SET_TOKENS_LIST,
  SET_SECRET_TOKEN,
  SET_REVOKED_TOKENS_LIST,
  SET_NEW_TOKEN,
  SET_UPDATED_SUCCESS,
} from '@store/actions/token.action'
import { TokenState } from '@store/states/token.state'

const initialState: TokenState = {
  list: [],
  revokedList: [],
  editToken: null,
  secretToken: '',
  newToken: null,
  updatedSuccess: false,
}

function tokenReducer(
  prevState: TokenState = initialState,
  action: ActionInterface
): TokenState {
  switch (action.type) {
    case SET_TOKENS_LIST:
      return {
        ...prevState,
        list: action.payload,
      }

    case SET_REVOKED_TOKENS_LIST:
      return {
        ...prevState,
        revokedList: action.payload,
      }

    case SET_EDIT_TOKEN:
      return {
        ...prevState,
        editToken: action.payload,
      }

    case SET_SECRET_TOKEN:
      return {
        ...prevState,
        secretToken: action.payload,
      }

    case SET_NEW_TOKEN:
      return {
        ...prevState,
        newToken: action.payload,
      }

    case SET_UPDATED_SUCCESS:
      return {
        ...prevState,
        updatedSuccess: action.payload,
      }

    default:
      return prevState
  }
}

export default tokenReducer
