import { ManageUsersMenu } from '@components-complex/management/management-users/manage-users-menu-vi-row'
import SystemHelper from '@lib/helpers/system.helper'
import UserModel from '@lib/models/user.model'
import UserRoleModel from '@lib/models/user-role.model'
import { EditUserModalInputData } from '@components-composite/modals/EditUserModal'
import { EngineCallback, Nullable } from '@lib/engine-types'

type ManageUsersMenuActionResult = {
  errorText: string
  openModal: (user: UserModel) => void
}

export function selectModalBasedOnUserMenuOption({
  chosenMenu,
  possibleRoles,
  openUserRemoveModal,
  openUserEditModal,
  openUserEnableModal,
  openUserDisableModal,
}: {
  chosenMenu: string
  possibleRoles: Array<UserRoleModel>
  openUserRemoveModal: EngineCallback<string>
  openUserEditModal: EngineCallback<EditUserModalInputData>
  openUserEnableModal: EngineCallback<string>
  openUserDisableModal: EngineCallback<string>
}): Nullable<ManageUsersMenuActionResult> {
  switch (ManageUsersMenu[chosenMenu as keyof typeof ManageUsersMenu]) {
    case ManageUsersMenu.Disable:
      return {
        errorText: 'User for removing was not found',
        openModal: (user) => openUserDisableModal(user.innerId),
      }

    case ManageUsersMenu.Delete:
      return {
        errorText: 'User for deleting was not found',
        openModal: (user) => openUserRemoveModal(user.innerId),
      }

    case ManageUsersMenu.Edit:
      return {
        errorText: 'User for editing was not found',
        openModal: (user) =>
          openUserEditModal({
            possibleRoles,
            selectedRole: user.role,
            email: user.email,
            userId: user.innerId,
          }),
      }

    case ManageUsersMenu.Enable:
      return {
        errorText: 'User for enabling was not found',
        openModal: (user) => openUserEnableModal(user.innerId),
      }
    default:
      SystemHelper.throwErrorInLocalEnv('Wrong management user ManageUsersMenu')
      return null
  }
}
