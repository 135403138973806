import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import { List, ListItem, Typography, useTheme } from '@mui/material'
import AwsLogoLightTheme from 'ui-v2/src/assets/images/aws-logo-light-theme'
import AwsLogoDarkTheme from '../../../assets/images/aws-logo-dark-theme'
import ReportCard from './report-card'
import {
  summaryReports,
  detailedReports,
  backupSummaryReportsData,
  backupDetailedReportsData,
} from 'ui-v2/src/lib/constants/reports.constant'
import VMWareReportsIcon from '../../../assets/images/vmware-reports'

const Overview = () => {
  const theme = useTheme()
  return (
    <Grid container spacing={0} maxWidth={1500}>
      <Grid
        size={{
          md: 6,
          lg: 6,
        }}
        minHeight={'calc(100vh - 200px)'}
      >
        <Box
          borderRight={{
            sm: 0,
            md: 1,
          }}
          paddingRight={{
            sm: 0,
            md: 2,
          }}
          sx={{
            borderColor: '#3d3e40 !important',
          }}
        >
          <List
            sx={{
              paddingX: 0,
              paddingY: 2,
            }}
          >
            <ListItem alignItems="center" disablePadding>
              {theme.palette.mode === 'light' ? (
                <AwsLogoLightTheme />
              ) : (
                <AwsLogoDarkTheme />
              )}
              <Typography variant="h5" mt={-1} ml={1} fontWeight={700}>
                AWS Reports
              </Typography>
            </ListItem>
          </List>
          <Grid container spacing={2.5}>
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 12,
                lg: 6,
              }}
            >
              <Typography variant="body1" mb={2} fontWeight={700}>
                Summary Reports
              </Typography>
              <Grid container spacing={2.5}>
                {summaryReports.map((report) => (
                  <Grid size={12} key={report.kindSlug}>
                    <ReportCard
                      title={report.title}
                      description={report.description}
                      kind={report.kind}
                      kindSlug={report.kindSlug}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 12,
                lg: 6,
              }}
            >
              <Typography variant="body1" mb={2} fontWeight={700}>
                Detailed Reports
              </Typography>
              <Grid container spacing={2.5}>
                {detailedReports.map((report, index) => (
                  <Grid size={12} key={report.kindSlug}>
                    <ReportCard
                      key={index}
                      title={report.title}
                      description={report.description}
                      kind={report.kind}
                      kindSlug={report.kindSlug}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        size={{
          md: 6,
          lg: 6,
        }}
        minHeight={'calc(100vh - 200px)'}
      >
        <Box
          paddingLeft={{
            sm: 0,
            md: 2,
          }}
        >
          <List
            sx={{
              paddingX: 0,
              paddingY: 2,
            }}
          >
            <ListItem alignItems="center" disablePadding>
              <VMWareReportsIcon />
              <Typography variant="h5" mt={-1} ml={1} fontWeight={700}>
                VMware Reports
              </Typography>
            </ListItem>
          </List>
          <Grid container spacing={2.5}>
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 12,
                lg: 6,
              }}
            >
              <Typography variant="body1" mb={2} fontWeight={700}>
                Summary Reports
              </Typography>
              <Grid container spacing={2.5}>
                {backupSummaryReportsData.map((report, index) => (
                  <Grid size={12} key={report.kindSlug}>
                    <ReportCard
                      key={index}
                      title={report.title}
                      description={report.description}
                      kind={report.kind}
                      kindSlug={report.kindSlug}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
                md: 12,
                lg: 6,
              }}
            >
              <Typography variant="body1" mb={2} fontWeight={700}>
                Detailed Reports
              </Typography>
              <Grid container spacing={2.5}>
                {backupDetailedReportsData.map((report, index) => (
                  <Grid size={12} key={report.kindSlug}>
                    <ReportCard
                      key={index}
                      title={report.title}
                      description={report.description}
                      kind={report.kind}
                      kindSlug={report.kindSlug}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Overview
