import React from 'react'
import Typography from '@mui/material/Typography'

export function ZeroOptionsAvailableText() {
  return (
    <Typography variant="body2" marginLeft="12px">
      No options available
    </Typography>
  )
}
