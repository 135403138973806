import { useListWebhooksQuery } from './list-webhooks'
import { useListEventTypesQuery } from './list-events'
import { useDeleteWebhookMutation } from './delete-webhook'
import { useTestWebhookMutation } from './test-webhook'
import { useGetWebhookQuery } from './get-webhook'

export {
  useListEventTypesQuery,
  useListWebhooksQuery,
  useDeleteWebhookMutation,
  useTestWebhookMutation,
  useGetWebhookQuery,
}
