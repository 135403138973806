import React from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { OverviewLeftColumnWithEntropyTrends } from './left-column'
import { OverviewRightColumnWithEntropyTrends } from './right-column'

export function OverviewWithEntropyTrends() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const queryTab = urlParams.get('t') as string

  return (
    <Grid container height="100%" key={queryTab} gap={3} alignItems="stretch">
      <OverviewLeftColumnWithEntropyTrends />
      <OverviewRightColumnWithEntropyTrends />
    </Grid>
  )
}
