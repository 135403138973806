import { Box, Skeleton } from '@mui/material'

const NUM_SKELETONS = 5

export function TopConfigurationSkeletonLoader() {
  const skeletonData = Array.from(Array(NUM_SKELETONS))
  return (
    <>
      {skeletonData.map((_, index) => (
        <Box display="flex" gap="5px" key={index}>
          <Skeleton height={30} width="40%" />
          <Skeleton height={30} width="20%" />
          <Skeleton height={30} width="20%" />
          <Skeleton height={30} width="20%" />
        </Box>
      ))}
    </>
  )
}
