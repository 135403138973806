/* eslint-disable import/no-extraneous-dependencies */
import Tab from '@mui/material/Tab'
import { TabsIds } from '@features/asset-page/store'
import { Asset } from 'blues-corejs/dist'

export function DetailsTab({
  onTabChange,
  asset,
}: {
  onTabChange: (tabIndex: TabsIds) => void
  asset: Asset
}) {
  // @ts-ignore
  const tabTitle = `${asset.getHumanReadableType()} Details`
  function handleTabChange() {
    onTabChange(TabsIds.DETAILS)
  }

  return (
    <Tab
      onClick={handleTabChange}
      tabIndex={TabsIds.DETAILS}
      label={tabTitle}
      value={TabsIds.DETAILS}
    />
  )
}

export default DetailsTab
