import React from 'react'
import LayoutConstant from '@lib/constants/layout.constant'
import ProtectAssetsComplex from '@components-complex/protect-assets-complex/ProtectAssetsComplex'
import AllWorkflowsLink from '@components-simple/all-workflows-link/AllWorkflowsLink'
import PageHelper from '@lib/helpers/page.helper'
import SystemHelper from '@lib/helpers/system.helper'
import { OnboardingRoutes, useNavigation } from '@lib/router'
import { ProtectAssetsRoutes } from '@lib/router/routes/protect-assets/protect-assets'

function ProtectAssetsPage() {
  const { query } = useNavigation()
  const isPrevOnboarding = PageHelper.isOnboardingPrevious(query)
  return (
    <>
      {isPrevOnboarding && <AllWorkflowsLink backUrl={OnboardingRoutes.root} />}
      <div className="innerContent">
        <div className="controlHeaderBlock">
          <div className="controlHeader">
            <div className="controlHeaderLabel">Protect Assets</div>
          </div>
          <div className="controlDescSmall mb30">
            Protect EC2 assets. For advanced capabilities, use the Assets tab.
          </div>
          <ProtectAssetsComplex />
        </div>
      </div>
    </>
  )
}

ProtectAssetsPage.pageMeta = {
  metaTitle: SystemHelper.getCurrentPageName('Protect assets'),
  metaDescription: '',
  metaKeywords: '',
  path: ProtectAssetsRoutes.root,
  layout: LayoutConstant.MainTopBar,
  title: 'Protect Assets',
}

export default ProtectAssetsPage
