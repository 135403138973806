import { EfsForPolicy } from '@features/policies'
import Box from '@mui/material/Box'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'
import React from 'react'

interface Props {
  onEfsTableErrorChange: (isInvalid: boolean) => void
}

export function EfsProtectionOptions({ onEfsTableErrorChange }: Props) {
  return (
    <>
      <Box
        className="policiesBlockSubheader mt25"
        data-testid="jsEditEfsTitleTable"
      >
        Select assets
      </Box>
      <Box
        className="policiesBlockLabel fontMedium mb20"
        data-testid="jsEditEfsDescriptionTable"
      >
        Select which assets to protect from the table below to add to the
        policy.
      </Box>
      <EfsForPolicy
        // @ts-ignore | Property 'onEfsTableErrorChange' very strict to the React types, but we need more generic way
        onEfsTableErrorChange={onEfsTableErrorChange}
        initSelectedFilters={defaultSelectedFilters()}
      />
    </>
  )
}
