import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { TenantSettingsClient } from 'ui-v2/src/lib/clients/settings/tenant'
import { useQuery } from '@tanstack/react-query'

export function useTenantSettingsQuery() {
  const tenantClient = useGrpcClient(TenantSettingsClient)

  return useQuery({
    queryKey: ['settings', 'tenant'],
    queryFn: () => {
      if (!tenantClient) {
        throw new Error('Tenant client not initialized')
      }

      return tenantClient.getTenantSettings()
    },
    enabled: !!tenantClient,
  })
}
