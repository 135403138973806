/* eslint-disable import/no-extraneous-dependencies */
import { useListThreatAssetQuery } from 'ui-v2/src/hooks/queries/threat'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { useState, useMemo } from 'react'
import {
  Asset,
  AWSBRecoveryPoint,
  EBSSnapshot,
  EC2,
  ElastioRecoveryPoint,
  FilesystemScanCheck,
  MalwareScan,
  OvaBackup,
  RansomwareScan,
} from 'blues-corejs/dist'
import { ThreatStatus } from 'blues-corejs/dist/models/threats/types'
import { getThreatColumns } from './column-config'
import { ThreatTableData } from 'ui-v2/src/lib/use-cases/assets/infected-assets/threat-table-data'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'

const Threats = ({ asset }: ThreatsProps) => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)

  let threatAsset = asset ? [asset.id] : []
  if (asset instanceof EC2) {
    threatAsset = asset.ebsIds as Array<string>
  }

  const { data: listThreatAssetData, isLoading } = useListThreatAssetQuery({
    assetId: threatAsset,
    pageSize: pageSize,
    pageToken: pageToken,
    statusList: [ThreatStatus.DETECTED],
  })

  const { data: assetListIdData } = useAssetsListByIdQuery(threatAsset, {
    enabled: asset instanceof EC2,
  })

  const assetAwsIdMap = useMemo(() => {
    if (!assetListIdData) {
      return new Map<string, string>()
    }
    const map = new Map<string, string>()
    assetListIdData.assetsList.forEach((assetItem) => {
      if (assetItem.ebsVolume) {
        map.set(assetItem.ebsVolume.id, assetItem.ebsVolume.awsId)
      }
    })

    return map
  }, [assetListIdData])

  const data = useMemo(() => {
    return new ThreatTableData({
      threatsList: listThreatAssetData?.threatsList ?? [],
      assetAwsIdMap: assetAwsIdMap,
    }).getThreatList()
  }, [listThreatAssetData, assetAwsIdMap])

  const columns = useMemo(() => {
    return getThreatColumns(asset)
  }, [])

  return (
    <CursorTable
      checkable={false}
      data={data}
      columns={columns}
      isLoading={isLoading}
      pageSize={pageSize}
      pageToken={pageToken}
      paginationCursors={{
        nextPageToken: listThreatAssetData?.pagination?.pageToken,
        prevPageToken: listThreatAssetData?.pagination?.prevPageToken,
      }}
      setPageSize={setPageSize}
      setPageToken={setPageToken}
    />
  )
}

interface ThreatsProps {
  asset: Asset
  lastBackupData:
    | AWSBRecoveryPoint
    | EBSSnapshot
    | ElastioRecoveryPoint
    | OvaBackup
    | Array<never>
  lastScanData: Array<FilesystemScanCheck | MalwareScan | RansomwareScan>
}
export default Threats
