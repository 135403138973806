import { styled } from '@mui/material/styles'

import Chip from '@mui/material/Chip'

const StyledHiddenChip = styled(Chip)(() => ({
  '&.MuiChip-root': {
    backgroundColor: '#E4F7FF',
    fontSize: '14px',
    fontWeight: '600',
  },
}))

interface Props {
  hiddenCount: number
  onClick: () => void
}

export function HiddenChipCount({ hiddenCount, onClick }: Props) {
  if (hiddenCount === 0) {
    return null
  }

  const label = `+${hiddenCount}`

  return <StyledHiddenChip label={label} onClick={onClick} />
}
