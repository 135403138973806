import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@mui/material'
import React from 'react'
import {
  useTabsActions,
  useDeletingItemId,
} from '@features/reports/schedules/use-data-store'
import { ReportSchedulesClient } from '@lib/clients/reports'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
}

const reportSchedulesClient = new ReportSchedulesClient()

function ConfirmDeleteScheduledItemModal() {
  const {
    setDeletingItemId,
    getTabItemById,
    deleteTabItemById,
    toggleRevalidate,
  } = useTabsActions()

  const itemId = useDeletingItemId() as string

  // @ts-ignore
  const { name } = getTabItemById(itemId) ?? { name: '' }

  const handleDeleteReportSchedule = async () => {
    await reportSchedulesClient.deleteReportSchedule({ id: itemId })

    deleteTabItemById(itemId)
    setDeletingItemId(undefined)
    toggleRevalidate(true)
  }

  const handleCloseModal = () => {
    setDeletingItemId(undefined)
  }
  return (
    <Modal
      open={Boolean(itemId)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box
              component="p"
              sx={{
                fontWeight: 600,
                margin: 0,
              }}
            >
              {name}
            </Box>
            Are you sure you want to delete this scheduled item?
            <Box component="div">This action cannot be undone.</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="large jsDialogYes"
            data-testid="jsDialogYes"
            color="primary"
            variant="contained"
            onClick={handleDeleteReportSchedule}
            type="button"
          >
            Yes, Proceed
          </Button>
          <Button
            className="jsDialogNo"
            data-testid="jsDialogNo"
            variant="text"
            onClick={handleCloseModal}
            type="button"
          >
            No, Cancel
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  )
}

export default ConfirmDeleteScheduledItemModal
