import { ovaBackupProviderOptions } from 'ui-v2/src/lib/constants/reports.constant'
import MultiSelectChip from 'ui-v2/src/components/ui/inputs/multi-select-chip'

const BackupProvider = ({
  value,
  helperText,
  error,
  onChange,
}: BackupProviderProps) => {
  const options = ovaBackupProviderOptions.map((option) => ({
    label: option.label,
    value: option.value.toString(),
  }))

  return (
    <MultiSelectChip
      size="small"
      options={options}
      selectedOptions={value}
      placeholder="Select"
      setSelectedOptions={onChange}
      helperText={helperText}
      error={error}
    />
  )
}
interface BackupProviderProps {
  value: Array<string>
  helperText?: string
  error?: boolean
  onChange: (options: Array<string>) => void
}
export default BackupProvider
