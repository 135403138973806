import React, { ChangeEvent, memo, useState } from 'react'
import clsx from 'clsx'
import { EngineCallback } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import ValueHelper from '@lib/helpers/value.helper'
import { Checkbox, FormControlLabel } from '@mui/material'

// how to extend:
// 1. add new "behaviour"
// 2. add if needed "behaviourParams" as optional
// 3. reassign handlers if needed, like - "if (behaviour === 'roles') ..."

interface Props {
  behaviour?: 'default' | 'roles'
  behaviourParams?: {
    mainLabel?: RegExp
    subLabel?: RegExp
  }
  data: Array<ValueInterface>
  onChange: EngineCallback<Array<ValueInterface>>
  selectAllLabel?: string
  disabled?: boolean
}

function CheckboxBlockCustom({
  behaviour = 'default',
  behaviourParams = {
    mainLabel: /write/i,
    subLabel: /read/i,
  },
  data,
  onChange,
  selectAllLabel,
  disabled = false,
}: Props) {
  const [isSubCheckboxDisabled, setIsSubCheckboxDisabled] = useState(false)

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  let isSubCheckboxDisabledFunc = (_option: ValueInterface): boolean =>
    isSubCheckboxDisabled

  // default on change handler
  let onDataChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newData = ValueHelper.updateValueByIndex(
      index,
      e.target.checked,
      data
    )
    onChange(newData)
  }

  if (behaviour === 'roles') {
    // reassign handlers
    isSubCheckboxDisabledFunc = (option: ValueInterface): boolean => {
      const isItReadCheckbox = !!behaviourParams.subLabel?.test(
        option.label ?? ''
      )
      return isItReadCheckbox && isSubCheckboxDisabled
    }
    onDataChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newData = ValueHelper.updateValueByIndex(
        index,
        e.target.checked,
        data
      )
      const isChecked = e.target.checked
      // when we select 'write' - it select 'read' automatically and disabled it
      const isWrite = !!behaviourParams.mainLabel?.test(
        newData[index]?.label ?? ''
      )
      if (isWrite) {
        setIsSubCheckboxDisabled(isChecked)
        if (isChecked) {
          // if we found 'write' then we try to find 'read'
          const readIndex = newData.findIndex((nd) =>
            behaviourParams.subLabel?.test(nd?.label ?? '')
          )
          if (readIndex !== -1 && newData[readIndex]) {
            const elem = newData[readIndex] as ValueInterface
            elem.value = true
          }
        }
      }
      onChange(newData)
    }
  }

  // HELP DATA CALCULATION
  const enabledCheckboxes = data.filter((v) => !isSubCheckboxDisabledFunc(v))
  const selectedTotalEnabled = enabledCheckboxes.filter((v) => v.value).length
  const selectAllChecked = selectedTotalEnabled === enabledCheckboxes.length
  const selectAllIndeterminate =
    selectedTotalEnabled > 0 && selectedTotalEnabled < enabledCheckboxes.length

  const onChangeAll = (e: ChangeEvent<HTMLInputElement>) => {
    const newData = [...data].map((v) => ({
      ...v,
      value: e.target.checked,
      disabled: v.disabled,
    }))
    if (behaviour === 'roles') {
      setIsSubCheckboxDisabled(e.target.checked)
    }
    onChange(newData)
  }

  return (
    <div
      className={clsx('wrap-1642608296683', {
        selectAllLabel: !!selectAllLabel,
      })}
    >
      <div className="flexRowBetween">
        {selectAllLabel && (
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                name="selectAll"
                indeterminate={selectAllIndeterminate}
                checked={selectAllChecked}
                onChange={onChangeAll}
              />
            }
            label={selectAllLabel}
          />
        )}
      </div>

      <div className="checkboxesBlock">
        {data.map((v, index) => (
          <div key={v.name}>
            <FormControlLabel
              control={
                <Checkbox
                  className="jsSubCheckbox"
                  disabled={isSubCheckboxDisabledFunc(v)}
                  value={v.value}
                  name={v.name}
                  checked={Boolean(v.value)}
                  onChange={onDataChange(index)}
                />
              }
              label={v.label ?? v.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(CheckboxBlockCustom)
