"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModeCase = exports.IntegrityCheckOptions = exports.SnapshotImport = exports.ProtectNewImmediately = exports.IscanOptions = exports.ScheduleStatus = exports.DayOfWeek = void 0;
var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek[DayOfWeek["SUNDAY"] = 0] = "SUNDAY";
    DayOfWeek[DayOfWeek["MONDAY"] = 1] = "MONDAY";
    DayOfWeek[DayOfWeek["TUESDAY"] = 2] = "TUESDAY";
    DayOfWeek[DayOfWeek["WEDNESDAY"] = 3] = "WEDNESDAY";
    DayOfWeek[DayOfWeek["THURSDAY"] = 4] = "THURSDAY";
    DayOfWeek[DayOfWeek["FRIDAY"] = 5] = "FRIDAY";
    DayOfWeek[DayOfWeek["SATURDAY"] = 6] = "SATURDAY";
})(DayOfWeek || (exports.DayOfWeek = DayOfWeek = {}));
var ScheduleStatus;
(function (ScheduleStatus) {
    ScheduleStatus[ScheduleStatus["ENABLED"] = 0] = "ENABLED";
    ScheduleStatus[ScheduleStatus["PAUSED"] = 1] = "PAUSED";
    ScheduleStatus[ScheduleStatus["DISABLED"] = 2] = "DISABLED";
})(ScheduleStatus || (exports.ScheduleStatus = ScheduleStatus = {}));
var AssetType;
(function (AssetType) {
    AssetType[AssetType["ASSET_TYPE_EC2"] = 0] = "ASSET_TYPE_EC2";
    AssetType[AssetType["ASSET_TYPE_EBS"] = 1] = "ASSET_TYPE_EBS";
    AssetType[AssetType["ASSET_TYPE_S3"] = 2] = "ASSET_TYPE_S3";
    AssetType[AssetType["ASSET_TYPE_EFS"] = 3] = "ASSET_TYPE_EFS";
})(AssetType || (AssetType = {}));
var IscanOptions;
(function (IscanOptions) {
    IscanOptions[IscanOptions["Disabled"] = 0] = "Disabled";
    IscanOptions[IscanOptions["Malware"] = 1] = "Malware";
    IscanOptions[IscanOptions["Ransomware"] = 2] = "Ransomware";
    IscanOptions[IscanOptions["Full"] = 3] = "Full";
    IscanOptions[IscanOptions["RansomwareAndEntropyDetection"] = 4] = "RansomwareAndEntropyDetection";
    IscanOptions[IscanOptions["FullAndEntropyDetection"] = 5] = "FullAndEntropyDetection";
})(IscanOptions || (exports.IscanOptions = IscanOptions = {}));
var ProtectNewImmediately;
(function (ProtectNewImmediately) {
    ProtectNewImmediately[ProtectNewImmediately["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    ProtectNewImmediately[ProtectNewImmediately["DISABLED"] = 1] = "DISABLED";
    ProtectNewImmediately[ProtectNewImmediately["ENABLED"] = 2] = "ENABLED";
})(ProtectNewImmediately || (exports.ProtectNewImmediately = ProtectNewImmediately = {}));
var SnapshotImport;
(function (SnapshotImport) {
    SnapshotImport[SnapshotImport["Skip"] = 0] = "Skip";
    SnapshotImport[SnapshotImport["Latest"] = 1] = "Latest";
    SnapshotImport[SnapshotImport["All"] = 2] = "All";
})(SnapshotImport || (exports.SnapshotImport = SnapshotImport = {}));
var IntegrityCheckOptions;
(function (IntegrityCheckOptions) {
    IntegrityCheckOptions[IntegrityCheckOptions["INTEGRITY_CHECK_DISABLED"] = 0] = "INTEGRITY_CHECK_DISABLED";
    IntegrityCheckOptions[IntegrityCheckOptions["INTEGRITY_CHECK_FILE_SYSTEM"] = 1] = "INTEGRITY_CHECK_FILE_SYSTEM";
})(IntegrityCheckOptions || (exports.IntegrityCheckOptions = IntegrityCheckOptions = {}));
var ModeCase;
(function (ModeCase) {
    ModeCase[ModeCase["MODE_NOT_SET"] = 0] = "MODE_NOT_SET";
    ModeCase[ModeCase["BACKUP"] = 1] = "BACKUP";
    ModeCase[ModeCase["SCAN_ONLY"] = 2] = "SCAN_ONLY";
})(ModeCase || (exports.ModeCase = ModeCase = {}));
