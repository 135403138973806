import { Column } from 'ui-v2/src/components/ui/data-display/data-table'

import { RecoveryListTableRow } from 'ui-v2/src/lib/models/assets/infected-assets'
import dayjs from 'dayjs'

export const getRecoveryPointsColumns = (): Array<
  Column<RecoveryListTableRow>
> => [
  {
    key: 'recoveryPointTime',
    header: 'Recovery Point Time',
    render: (row) => (
      <>{dayjs(row.recoveryPointTime).format('hh:mm A, MM/DD/YYYY')}</>
    ),
  },
  {
    key: 'backupSource',
    header: 'Backup Source',
    render: (row) => row.backupSource,
  },
  {
    key: 'backupSize',
    header: 'Backup Size(GiB)',
    render: (row) => row.backupSize,
  },
]
