/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetsGenericHostDrawerAttrs,
  GenericHostDrawer,
} from 'ui-v2/src/lib/models/assets'
import { Backup, GenericHost, Nullable, Threat } from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { AssetsGenericHostBase } from './generic-host-base'
import { AssetItemGenericHost } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'

export class AssetsGenericHostDrawerData extends AssetsGenericHostBase {
  protected readonly genericHosts: Array<GenericHost>

  protected readonly threats: Array<Threat>

  protected readonly assetItemsListWithHostIds: Nullable<
    Map<string, Array<AssetItemGenericHost>>
  >

  protected readonly scans: Array<Scan>

  protected readonly genericHostsWithScans: Array<{
    genericHost: GenericHost
    scans: Array<Scan>
  }>

  #lastBackups: Map<string, Backup>

  constructor({
    genericHosts,
    threats,
    assetItemsData,
    scans,
    lastBackups,
  }: AssetsGenericHostDrawerAttrs) {
    super()
    this.genericHosts = genericHosts
    this.threats = threats
    this.assetItemsListWithHostIds = genericHosts.reduce((map, host) => {
      const assetItemsGenericHost: Array<AssetItemGenericHost> = [
        ...assetItemsData.filesList,
        ...assetItemsData.disksList,
        ...assetItemsData.streamsList,
        ...assetItemsData.volumesList,
      ].filter((assetItem) => assetItem.assetId === host.id)

      if (!map.has(host.id)) {
        map.set(host.id, assetItemsGenericHost)
      }
      return map
    }, new Map<string, Array<AssetItemGenericHost>>())
    this.scans = scans
    this.#lastBackups = lastBackups
    this.genericHostsWithScans = this.getGenericHostsWithScans()
  }

  getGenericHostDrawerData() {
    const genericHost = this.genericHosts[0]
    if (!genericHost) {
      return undefined
    }
    return new GenericHostDrawer({
      id: genericHost.id,
      name: genericHost.hostname,
      threats: this.calculateThreatsCount(
        this.assetItemsListWithHostIds?.get(genericHost.id) ?? []
      ),
      lastBackup: this.#getLastBackup(genericHost.id),
      lastScan: this.getLastScan(genericHost),
      isUnhealthy: this.isUnhealthyHost(
        this.assetItemsListWithHostIds?.get(genericHost.id) ?? []
      ),
    })
  }

  #getLastBackup(genericHostId: string) {
    const lastBackup = this.#lastBackups.get(genericHostId)
    if (lastBackup) {
      return lastBackup
    }
    return null
  }
}
