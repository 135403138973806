/* eslint-disable import/no-extraneous-dependencies */
import {
  ListTenantJobsFilter,
  TenantJobsClient,
  ListTenantJobsFilterRequestParams,
} from '@lib/clients/jobs'
import { TenantJobFiltersUI } from '@lib/interfaces/jobs'
import { JobTenant } from 'blues-corejs/dist'
import { SYSTEM_JOB_KINDS } from '../consts'

const jobsClient = new TenantJobsClient()

const HOUR_IN_MS = 1000 * 60 * 60

function processFiltersToRequestParams(
  filters: TenantJobFiltersUI
): ListTenantJobsFilterRequestParams {
  const kindsList = filters.kindsList.size
    ? Array.from(filters.kindsList)
    : SYSTEM_JOB_KINDS
  const statusesList = Array.from(filters.statusesList)

  return {
    kindsList,
    statusesList,
  }
}

export async function fetchJobsFromLastHour(
  filters: TenantJobFiltersUI
): Promise<Array<JobTenant>> {
  const processedFilters = processFiltersToRequestParams(filters)

  const response = await jobsClient.listTenantJobs(
    new ListTenantJobsFilter({
      ...processedFilters,
      timeRange: {
        start: Math.round((Date.now() - HOUR_IN_MS) / 1000),
        end: Math.round(Date.now() / 1000),
      },
    })
  )
  return response.jobsList
}
