import { BaseRoutes } from '../base'

export enum RouteFragments {
  CLOUD_CONFIGURE_DEPLOYMENT = 'cloud-configure-deployment',
  CLOUD_CONFIGURE_UPGRADE = 'cloud-configure-upgrade',
}

export class DeploymentRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_DEPLOYMENT
  }

  public static get cloudConfigureDeployment(): string {
    return this.buildRoute(RouteFragments.CLOUD_CONFIGURE_DEPLOYMENT)
  }

  public static get cloudConfigureUpgrade(): string {
    return this.buildRoute(RouteFragments.CLOUD_CONFIGURE_UPGRADE)
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_DEPLOYMENT}/${fragments.join('/')}`
  }

  public static buildCloudConfigureDeploymentUpgradeUrl(id: number): string {
    const basePath = this.cloudConfigureDeployment
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.ID]: id,
      },
    })
  }

  public static buildCloudConfigureUpgradeUrl(id: string): string {
    const basePath = this.cloudConfigureUpgrade
    return this.buildRouteWithQuery({
      basePath,
      params: {
        [this.#QUERY_KEYS.ID]: this.base64Encode(id),
      },
    })
  }

  static readonly #QUERY_KEYS = {
    ID: 'id',
  }

  static readonly #ROUTE_DEPLOYMENT = '/deployment'
}
