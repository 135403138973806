/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import CloseIcon from '@mui/icons-material/Close'
import { Asset, Backup } from 'blues-corejs/dist'
import { useState, MouseEvent } from 'react'
import { BackupState } from '../backup-state'
import ArrowForward from '@mui/icons-material/ArrowForward'

import { StyledActionButton } from './styles'

import { Option } from './types'
import { isActionButtonHidden, retrieveRpCapabilities } from './backup-actions'

interface Props {
  backup: Backup
  asset: Asset
  onCloseRecoveryPointDetails: () => void
}

export function ActionsMenu({ options }: { options: Array<Option> }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  if (!options.length) {
    return null
  }
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledActionButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={
          <ArrowForward
            sx={{
              fontSize: '15px',
            }}
          />
        }
      >
        <Typography
          variant="caption"
          fontWeight="600"
          lineHeight="21px"
          fontSize="14px"
        >
          Action
        </Typography>
      </StyledActionButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.label}
            onClick={() => {
              option.onClick()
              handleClose()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export function RecoveryPointDetailsHeader({
  backup,
  asset,
  onCloseRecoveryPointDetails,
}: Props) {
  const isHideActionsMenu = isActionButtonHidden(backup, asset)

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h5" fontWeight="600" marginBottom="5px">
        Recovery Point Details
      </Typography>
      <Box display="flex" alignItems="center" gap="5px">
        <BackupState backup={backup} asset={asset} />
        {!isHideActionsMenu && (
          <ActionsMenu
            options={retrieveRpCapabilities({
              asset,
              backup,
              includeScanOnly: false,
            })}
          />
        )}

        <IconButton onClick={onCloseRecoveryPointDetails}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
