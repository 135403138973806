import { useEffect, useState } from 'react'
import { EngineCallback } from '@lib/engine-types'
import { useSearchParams } from 'react-router-dom'
import { useNavigation } from '@lib/router/contexts/navigation-context'

const TAB_RENDER_TIME = 1000

interface UseRouteTabsData {
  // access for tests
  setInit: EngineCallback<boolean>
  currentTab: number
  onTabChange: (newValue: number) => void
}

interface UseRouteTabsProps {
  additionalQueries?: any
}

function useRouteTabs(args: UseRouteTabsProps = {}): UseRouteTabsData {
  const router = useNavigation()
  const pathName = window.location.pathname
  const [searchParams, setSearchParams] = useSearchParams()
  const tabId = searchParams.get('t')

  const [currentTab, setCurrentTab] = useState<number>(0)
  // for switching between initialization and main
  // handling effects
  const [init, setInit] = useState<boolean>(false)

  // initialization
  useEffect(() => {
    if (!init) {
      setTimeout(() => setInit(true), TAB_RENDER_TIME)
      if (tabId !== null) {
        setCurrentTab(+tabId)
      }
    }
  }, [tabId])

  // main handling
  useEffect(() => {
    if (init) {
      const isOnbordingParam = searchParams.get('onboarding') !== null

      if (currentTab === 0) {
        router.push(`${pathName}?t=0`)
      } else {
        if (isOnbordingParam) {
          router.push(`${pathName}?t=${currentTab}&onboarding=1`)
        } else {
          router.push(`${pathName}?t=${currentTab}`)
          setSearchParams(args.additionalQueries)
        }
      }
    }
  }, [currentTab, init])

  return {
    setInit,
    currentTab,
    onTabChange: setCurrentTab,
  }
}

export default useRouteTabs
