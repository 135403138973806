import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { OnboardingRoutes } from '@lib/router/routes/onboarding/onboarding'
import { OnboardingPage } from '@features/onboarding-page/onboarding-page'

export const onboardingRoutes: Array<PathRouteProps> = [
  {
    path: OnboardingRoutes.root,
    element: (
      <MainTopBarLayout title="Onboarding">
        <OnboardingPage />
      </MainTopBarLayout>
    ),
  },
]
