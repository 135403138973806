import { GrpcClient } from '@lib/clients/grpc-client'
import {
  DownloadEntropyDetectionReportRequest,
  EntropyDetectionPromiseClient,
  GetEntropyTrendsHistoryRequest,
  GetFiltersForListAwsSummariesRequest,
  ListAnomaliesRequest,
} from '../grpc-imports'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { ObjHelper, TimeHelper } from '@lib/helpers'
import { GetEntropyTrendsHistoryRequestAttrs } from '@lib/clients/entropy-detection/types'
import { AnomalyTransformer } from '@lib/clients/entropy-detection/list-anomalies/anomaly-transformer'
import { Anomaly } from '@lib/models/anomalies/anomaly.model'
import { buildAssetsFilter } from './common'
import { Pagination } from '@lib/clients'
import {
  EBSTransformer,
  EC2Transformer,
  EFSTransformer,
  S3BucketTransformer,
} from '@lib/clients/assets/transformers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { EntropyTrendsHistory } from '@lib/models/anomalies'

export type { GetEntropyTrendsHistoryRequestAttrs } from './types'

export class EntropyDetectionClient extends GrpcClient<EntropyDetectionPromiseClient> {
  #entropyDetectionClient: EntropyDetectionPromiseClient

  constructor(hostName = '') {
    super()
    this.#entropyDetectionClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): EntropyDetectionPromiseClient {
    return new EntropyDetectionPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'EntropyDetectionClient'
  }

  async getEntropyTrendsHistory(
    params: GetEntropyTrendsHistoryRequestAttrs
  ): Promise<EntropyTrendsHistory> {
    const request = this.#buildEntropyTrendsHistoryFilter(params)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#entropyDetectionClient.getEntropyTrendsHistory(
            request,
            this.metadata()
          ),
        {
          requestName: 'EntropyDetectionPromiseClient/getEntropyTrendsHistory',
        }
      )
    ).toObject()

    return new EntropyTrendsHistory(
      response.dataPointsList.map((dataPoint) => ({
        anomalyFindingCount: dataPoint.anomalyFindingCount,
        timestamp: TimeHelper.getDateWithTimezone(
          new Date(dataPoint.timestamp!.seconds * 1000).getTime()
        ).toDate(),
        anomalyIdsList: dataPoint.anomalyIdsList,
      }))
    )
  }

  async listAnomalies(request: ListAnomaliesRequest): Promise<
    {
      anomaliesList: Array<Anomaly>
    } & Pagination
  > {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#entropyDetectionClient.listAnomalies(request, this.metadata()),
        {
          requestName: 'EntropyDetectionPromiseClient/listAnomalies',
        }
      )
    ).toObject()

    return {
      anomaliesList: response.anomaliesList.map((anomaly) =>
        new AnomalyTransformer(anomaly).transform()
      ),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  async downloadEntropyDetectionReport(anomalyId: string): Promise<string> {
    const request = new DownloadEntropyDetectionReportRequest()

    request.setAnomalyId(anomalyId)

    const response = await this.retryGrpcCall(
      () =>
        this.#entropyDetectionClient.downloadEntropyDetectionReport(
          request,
          this.metadata()
        ),
      {
        requestName:
          'EntropyDetectionPromiseClient/downloadEntropyDetectionReport',
      }
    )

    return response.getDownloadUrl()
  }

  async getFiltersForListAwsAssetSummaries(
    startAt: number,
    endAt: number
  ): Promise<Array<AWSAsset>> {
    const request = new GetFiltersForListAwsSummariesRequest()

    request.setStartAt(this.#setTimestamp(startAt))
    request.setEndAt(this.#setTimestamp(endAt))

    const response = await this.retryGrpcCall(
      () =>
        this.#entropyDetectionClient.getFiltersForListAwsAssetSummaries(
          request,
          this.metadata()
        ),
      {
        requestName:
          'EntropyDetectionPromiseClient/getFiltersForListAwsAssetSummaries',
      }
    )

    return response
      .getAssetsList()
      .reduce((assetsList: Array<AWSAsset>, currentAsset) => {
        const ebsVolume = currentAsset.getEbsVolume()
        if (ebsVolume) {
          const ebs = new EBSTransformer(ebsVolume.toObject()).transform()

          assetsList.push(ebs)
        }

        const ec2Instance = currentAsset.getEc2Instance()

        if (ec2Instance) {
          const ec2 = new EC2Transformer(ec2Instance.toObject()).transform()

          assetsList.push(ec2)
        }

        const s3Bucket = currentAsset.getS3Bucket()

        if (s3Bucket) {
          const s3 = new S3BucketTransformer(s3Bucket.toObject()).transform

          assetsList.push(s3)
        }

        const efsFilesystem = currentAsset.getEfsFilesystem()

        if (efsFilesystem) {
          const efs = new EFSTransformer(efsFilesystem.toObject()).transform()

          assetsList.push(efs)
        }

        return assetsList
      }, [] as Array<AWSAsset>)
  }

  #buildEntropyTrendsHistoryFilter(
    params: GetEntropyTrendsHistoryRequestAttrs
  ) {
    const request = new GetEntropyTrendsHistoryRequest()

    request.setStartAt(this.#setTimestamp(params.startAt))

    request.setEndAt(this.#setTimestamp(params.endAt))

    request.setTimeGranularity(params.timeGranularity)

    if (params.assetsFilter && ObjHelper.isNotEmpty(params.assetsFilter)) {
      request.setAssetsFilter(buildAssetsFilter(params.assetsFilter))
    }

    if (params.assetIdsList?.length) {
      request.setAssetIdsList(params.assetIdsList)
    }

    return request
  }

  #setTimestamp(date: number): Timestamp {
    const timestamp = new Timestamp()
    timestamp.setSeconds(Math.floor(date / 1000))
    return timestamp
  }
}
