import React, { ReactEventHandler, useState } from 'react'
import styles from './RenderMenuWithSubMenu.module.scss'
import PagePathInterface from '@lib/interfaces/page-path.interface'
import PageMenuInterface from '@lib/interfaces/page-menu.interface'
import clsx from 'clsx'
import { Collapse, MenuItem } from '@mui/material'
import ArrowDropUpIcon from '@inline-img/general/nav-bar/arrow-drop-up-icon'
import ArrowDropDownIcon from '@inline-img/general/nav-bar/arrow-drop-down-icon'
import Divider from '@mui/material/Divider'
import {
  checkSelectedAmongChildren,
  dividerStyle,
  drawMenuIcon,
} from '@features/MainMenu/utilsMenu'
import Link from '@lib/router/navigation-strategies/link'
import { useLocation } from 'react-router-dom'

interface Props {
  menuElem: PageMenuInterface
  pagePath: string
  handleMenuMainClose: ReactEventHandler
}

function RenderMenuWithSubMenu({
  menuElem,
  pagePath,
  handleMenuMainClose,
}: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const location = useLocation()
  const asPath = location.pathname ?? ''

  const handleClick = () => {
    setOpen(!open)
  }
  const isSelected = checkSelectedAmongChildren(menuElem.subPages, pagePath)
  if (menuElem.subPages.length === 0) {
    return null
  }

  const renderSubElem = (subMenuElem: PagePathInterface) => {
    return (
      <Link key={subMenuElem.path} to={subMenuElem.path}>
        <div
          className={clsx(`${styles.link}`, {
            [String(styles.selectedMenu)]: asPath === subMenuElem.path,
          })}
        >
          <MenuItem
            key={menuElem.page.path}
            className={`${styles.menuItemIcon}`}
            onClick={handleMenuMainClose}
          >
            <div>{drawMenuIcon(subMenuElem)}</div>
            <div>{subMenuElem.name}</div>
          </MenuItem>
        </div>
      </Link>
    )
  }

  return (
    <>
      <div
        onClick={handleClick}
        className={clsx(`${styles.flexBetween} ${styles.link}`, {
          [String(styles.selectedMenu)]: isSelected,
        })}
        data-testid="complex-row-testid"
      >
        <div className={styles.menuItemIcon}>
          <div>{drawMenuIcon(menuElem.page)}</div>
          <div>{menuElem.page.name}</div>
        </div>
        {menuElem.subPages.some((page) => !page.hideInSubMenu) && (
          <div
            className={clsx({
              [String(styles.baseArrowSelected)]: isSelected,
            })}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        )}
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider sx={dividerStyle} />
        <div className={styles.subMenu}>
          {menuElem.subPages
            .filter((page) => !page.hideInSubMenu)
            .map(renderSubElem)}
        </div>
        <Divider sx={dividerStyle} />
      </Collapse>
    </>
  )
}

export default RenderMenuWithSubMenu
