import { ListLiveAssetsResponse } from 'ui-v2/src/lib/grpc'
import { GenericHostTransformer } from './generic-host'
import { EC2Transformer } from './ec2'
import { EBSTransformer } from './ebs'
import { S3BucketTransformer } from './s3'
import { EFSTransformer } from './efs'
import {
  BackupsTransformer,
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
} from '../recovery'
import {
  FilesystemScanTransformer,
  MalwareScanTransformer,
  RansomwareScanTransformer,
} from '../scans'
import { ThreatTransformer } from '../threats'
import { LiveAssets } from 'ui-v2/src/lib/models/assets'

export class ListLiveAssetsTransformer {
  transformResponse(response: ListLiveAssetsResponse): LiveAssets {
    const {
      ec2InstancesList,
      ebsVolumesList,
      s3BucketsList,
      efsList,
      threatsList,
      genericHostsList,
      scansList,
      lastEbsSnapshotsList,
      lastElastioBackupsMap,
    } = response.toObject()

    const lastBackupsMap = response.getLastBackupsMap()

    const firstBackupsMap = response.getFirstBackupsMap()

    return {
      genericHosts: genericHostsList.map((host) =>
        new GenericHostTransformer(host).transform()
      ),
      ec2Instances: ec2InstancesList.map((instance) =>
        new EC2Transformer(instance).transform()
      ),
      ebsVolumes: ebsVolumesList.map((volume) =>
        new EBSTransformer(volume).transform()
      ),
      s3Buckets: s3BucketsList.map((bucket) =>
        new S3BucketTransformer(bucket).transform()
      ),
      efs: efsList.map((filesystem) =>
        new EFSTransformer(filesystem).transform()
      ),
      threats: threatsList.map((threat) =>
        new ThreatTransformer(threat).transform()
      ),
      lastEbsSnapshots: lastEbsSnapshotsList.map((snapshot) =>
        new EbsSnapshotTransformer(snapshot).transform()
      ),
      scans: scansList.map(
        ({ filesystemCheckScan, ransomwareScan, malwareScan }) => {
          return {
            filesystemCheckScan:
              filesystemCheckScan &&
              new FilesystemScanTransformer(filesystemCheckScan).transform(),
            malwareScan:
              malwareScan &&
              new MalwareScanTransformer(malwareScan).transform(),
            ransomwareScan:
              ransomwareScan &&
              new RansomwareScanTransformer(ransomwareScan).transform(),
          }
        }
      ),
      lastBackups: new BackupsTransformer(lastBackupsMap),
      firstBackups: new BackupsTransformer(firstBackupsMap),
      lastElastioBackupsMap: this.#transformLastElastioBackupsMap(
        lastElastioBackupsMap
      ),
    }
  }

  #transformLastElastioBackupsMap(
    lastElastioBackupsMap: ListLiveAssetsResponse.AsObject['lastElastioBackupsMap']
  ) {
    const map = new Map()
    for (const [, [assetId, backup]] of lastElastioBackupsMap.entries()) {
      map.set(assetId, new ElastioRecoveryPointTransformer(backup).transform())
    }
    return map
  }
}
