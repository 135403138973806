import { useEffect, useState } from 'react'
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  ValidationMode,
} from 'react-hook-form'
import useError from '@lib/hooks/useError'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { EngineCallback, EngineFunction } from '@lib/engine-types'
import FormHelper from '@lib/helpers/form.helper'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'

interface UseGeneralFormHookResult {
  isFormDisabled: boolean
  register: UseFormRegister<FieldValues>
  submit: any
  globalFormError: string
  setGlobalFormError: EngineCallback<string>
  watch: EngineFunction<string, string>
  control: Control
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  trigger: UseFormTrigger<FieldValues>
}

function useGeneralForm<T extends FieldValues>(
  currentForm: Record<keyof T, FormFieldInterface> | FormPeriodInterface,
  onSubmit: EngineCallback<T>,
  errorGroups: Array<ErrorGroupConstants>,
  loading: boolean,
  blockFocus = false,
  validationMode = 'onSubmit' as keyof ValidationMode
): UseGeneralFormHookResult {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState,
    clearErrors,
    getValues,
    trigger,
  } = useForm({
    mode: validationMode,
  })
  const [globalFormError, setGlobalFormError] = useError(errorGroups)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const focusInterval = setTimeout(() => {
      setDisabled(false)
      if (!blockFocus) {
        FormHelper.focusFirst(currentForm)
      }
    }, 300)
    return () => clearInterval(focusInterval)
  }, [])

  const isFormDisabled: boolean = disabled || loading

  const submit = handleSubmit((data) => {
    setGlobalFormError('')
    onSubmit(data as T)
  })

  return {
    isFormDisabled,
    register,
    submit,
    globalFormError,
    setGlobalFormError,
    watch,
    control,
    setValue,
    formState,
    clearErrors,
    getValues,
    trigger,
  }
}

export default useGeneralForm
