import React, { useEffect, useMemo, useState } from 'react'
import {
  EngineCallback,
  ValueObject,
  VIRow,
  VIRowReadonly,
} from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormHelper from '@lib/helpers/form.helper'
import clsx from 'clsx'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import { Button, Switch, TextField } from '@mui/material'
import buildDefaultFormData from './data'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import FormEditVaultInterface from '@lib/interfaces/form/form-edit-vault.interface'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import ValueInterface from '@lib/interfaces/value.interface'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import EditWhiteIcon from '@inline-img/general/edit-white-icon'
import CheckMarkIcon from '@inline-img/general/check-mark-icon'
import PreloaderConstants from '@lib/constants/preloader.constant'
import usePreloader from '@lib/hooks/usePreloader'
import { useDispatch, useSelector } from 'react-redux'
import { getPossibleVPCList } from '@store/selectors/rex.selector'
import { requestPossibleVpcList } from '@store/actions/rex.action'
import VpcModel from '@lib/models/vpc.model'
import VpcFactory from '@lib/factories/vpc.factory'
import DemoWrap from '@components-simple/demo-wrap/DemoWrap'
import SafetyLockEditModal from '@components-composite/modals/SafetyLockEditModal'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import ObjHelper from '@lib/helpers/obj.helper'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { VPC_SUBNET_NAME_TOOLTIP_LENGTH } from '@lib/constants/tooltip.constant'

interface Props {
  defaultValues?: ValueObject<FormEditVaultInterface>
  onSubmit: EngineCallback<FormEditVaultInterface>
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
  data: FormEditVaultInterface
}

function EditVaultForm({
  defaultValues,
  onSubmit,
  loading,
  errorGroups,
  data,
}: Props) {
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )
  const {
    register,
    isFormDisabled,
    submit,
    formState,
    globalFormError,
    setValue,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading)
  const dispatch = useDispatch()
  const MIN_VERSION_FOR_SAFETY_LOCK = 24

  const loadingPossibleVPCList = usePreloader(
    PreloaderConstants.REQUEST_POSSIBLE_VPC_LIST
  )

  const possibleVPCList: Array<VpcModel> = useSelector(getPossibleVPCList)

  const possibleVPCByRegion: Array<VpcModel> = possibleVPCList.filter(
    (vpc) => vpc.regionName === data.region
  )

  const possibleVPCArray: VIRowReadonly = possibleVPCByRegion.map(
    ({ innerId, vpcName }) => ({
      name: innerId,
      label: vpcName,
    })
  )

  const possibleVPC: VIRowReadonly = possibleVPCArray.length
    ? possibleVPCArray
    : [{ name: data.vpcId }]

  const [descriptionValue, setDescriptionValue] = useState<string>(
    data.description
  )
  const [safetyLock, setSafetyLock] = useState<boolean>(data.safetyLock)
  const [disabledSafetyLock, setDisabledSafetyLock] = useState<boolean>(false)

  const [vpc, setVpc] = useState<ValueInterface>()
  const [possibleSub, setPossibleSub] = useState<VIRowReadonly>([])
  const [selectedSub, setSelectedSub] = useState<VIRow>([])
  const [formEditable, setFormEditable] = useState<boolean>(false)
  const [privateSubnet, setPrivateSubnet] = useState<boolean>(false)

  const [formHasCHanges, setFormHasCHanges] = useState<boolean>(false)

  useEffect(() => {
    const subnetList = data.subnetIdsList.map((id) => ({ name: id }))
    const version = data?.version ?? ''

    setPossibleSub(subnetList)
    setSelectedSub(subnetList)
    setVpc({ name: data.vpcId })

    setDisabledSafetyLock(
      Number(version.split('.')[1]) < MIN_VERSION_FOR_SAFETY_LOCK
    )
  }, [])

  useEffect(() => {
    const shouldRequestVpcList =
      data.accountId && possibleVPCByRegion.length === 0 && formEditable
    if (shouldRequestVpcList) {
      dispatch(requestPossibleVpcList(data.accountId))
    }
  }, [data.accountId, possibleSub.length, formEditable])

  useEffect(() => {
    setVpc(possibleVPC.find(({ name }) => name == data.vpcId))
  }, [possibleVPCList])

  useEffect(() => {
    setValue('description', descriptionValue)
  }, [descriptionValue])

  useEffect(() => {
    setValue('safetyLock', safetyLock)
  }, [safetyLock])

  useEffect(() => {
    if (formEditable) {
      const possibleSubsList =
        possibleVPCList.find(({ innerId }) => innerId == vpc?.name)
          ?.subnetsList ?? []

      const possibleSubs: VIRowReadonly = possibleSubsList.map(
        VpcFactory.fromSubnetModelToVi
      )

      let selectedSubs: Array<ValueInterface> = []

      if (vpc?.name == data.vpcId) {
        data.subnetIdsList?.forEach((name) => {
          const sub = possibleSubsList.find((item) => item.id == name)
          if (sub) {
            selectedSubs.push(VpcFactory.fromSubnetModelToVi(sub))
          }
        })
      } else {
        selectedSubs = [...possibleSubs]
      }

      setPossibleSub(possibleSubs)
      setSelectedSub(selectedSubs)
      setValue('vpcId', vpc?.name)
    }
  }, [vpc])

  useEffect(() => {
    setValue(
      'subnetIdsList',
      selectedSub.map(({ name }) => name)
    )

    setPrivateSubnet(
      selectedSub.length > 0 &&
        selectedSub.some((sub) => sub.extraValue === false)
    )
  }, [selectedSub])

  const { openModal: openSafetyLockEditModal, modalProps: Props } =
    useModal<boolean>(Modal.safetyLockEdit, (value: boolean) => {
      setSafetyLock(value)
    })

  FormHelper.fillErrors(formState.errors, currentForm)

  useEffect(() => {
    const formValues: FormEditVaultInterface = {
      ...data,
      safetyLock,
      vpcId: String(vpc?.name),
      description: descriptionValue,
      subnetIdsList: selectedSub.map(({ name }) => name),
    }
    setFormHasCHanges(ObjHelper.isNotEqual(data, formValues))
  }, [formState])

  return (
    <>
      <PreloaderSmall show={loadingPossibleVPCList} top={27} right={220} />
      <form
        className={clsx('wrap-1650643184609 jsEditVaultForm', {
          formDisabled: isFormDisabled || !formEditable,
        })}
        onSubmit={(formData) => {
          setFormEditable(false)
          return submit(formData)
        }}
      >
        <input
          type="hidden"
          value={String(currentForm.description.value)}
          {...register(currentForm.description.name)}
        />
        <input type="hidden" {...register(currentForm.vpc.name)} />
        <input type="hidden" {...register(currentForm.selectedSub.name)} />

        <div className="vdNameBlockSubTitle">
          <b>VPC</b>
        </div>
        <div className="vdControlBlock">
          <ViSelect
            disabled={isFormDisabled || !formEditable || loadingPossibleVPCList}
            possible={possibleVPC}
            selected={vpc}
            onChange={(value) => setVpc(value)}
            placeholder="Select VPC"
            getLabel={(v) => {
              if (v.label) {
                return (
                  <LongTextTooltip
                    text={v.label}
                    placement="top"
                    tooltipText={v.name}
                    maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                    show
                  />
                )
              }
              return v.name
            }}
          />
        </div>
        <div className="vdNameBlockSubTitle">
          <b>Subnet</b>
        </div>
        <div className="vdControlBlock">
          <ViMultipleSelect
            disabled={isFormDisabled || !formEditable || loadingPossibleVPCList}
            possible={possibleSub}
            selected={selectedSub}
            onChange={(value) => setSelectedSub(value)}
            placeholder="Select Subnets"
            showNameInTooltip
            getLabel={(v) => {
              return (
                <LongTextTooltip
                  text={v.label ?? v.name}
                  placement="top"
                  tooltipText={v.name}
                  maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                  show={!v.label?.includes(v.name)}
                />
              )
            }}
            menuWidth="auto"
          />
        </div>
        {privateSubnet && (
          <div className="blockWarningText">
            The Vault will be deployed to a private subnet and will need manual
            configuration. Please see documentation for more details. One of our
            cloud team members will reach out with assistance.
          </div>
        )}
        <DemoWrap>
          <div className="vdNameBlockSubTitle">
            <div className="switcher jsSwitcherEditVaultForm">
              <Switch
                className="greenSwitch"
                color="primary"
                disabled={
                  isFormDisabled ||
                  !formEditable ||
                  loadingPossibleVPCList ||
                  disabledSafetyLock ||
                  data.safetyLock
                }
                checked={safetyLock}
                onChange={(_e, value) =>
                  value ? openSafetyLockEditModal(value) : setSafetyLock(value)
                }
                name="switch"
              />
              {safetyLock ? 'Safety Lock Enabled ' : 'Enable Safety Lock'}
              {disabledSafetyLock && (
                <QuestionHint
                  text="The Cloud Connector the vault belongs to need to be updated to the latest version to use this feature."
                  variant="inText"
                />
              )}
            </div>
          </div>
        </DemoWrap>
        <div className="vdNameBlockSubTitle">
          <b>Description</b>
        </div>
        <TextField
          className="vdControlBlock staticLabel jsDescVault descVault"
          name="description"
          value={descriptionValue}
          disabled={isFormDisabled || !formEditable || loadingPossibleVPCList}
          onChange={(e) => setDescriptionValue(e.target.value)}
          variant="outlined"
          rows={4}
          multiline
        />

        {globalFormError && (
          <div className="formGlobalError">{globalFormError}</div>
        )}
        <div className="positionRelative">
          <PreloaderSmall
            show={loading || loadingPossibleVPCList}
            top={20}
            left={100}
          />

          {(formEditable || loading) && (
            <Button
              disabled={loading || loadingPossibleVPCList || !formHasCHanges}
              className="mfSubmit buttonSaveEditVault jsButtonSaveEditVault"
              variant="contained"
              color="primary"
              type="submit"
            >
              <span className="mr10">
                <CheckMarkIcon />
              </span>
              <span>Save</span>
            </Button>
          )}
          {!formEditable && !loading && (
            <Button
              className="mfSubmit buttonSaveEditVault jsButtonSaveEditVault"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => setFormEditable(true)}
            >
              <span className="mr10">
                <EditWhiteIcon />
              </span>
              <span>Edit</span>
            </Button>
          )}
        </div>
        <SafetyLockEditModal
          {...Props}
          data={{
            name: data.vaultName,
            value: safetyLock,
          }}
        />
      </form>
    </>
  )
}

export default EditVaultForm
