import React from 'react'
import clsx from 'clsx'
import { useManagementView } from '../common'
import { FileScans } from './file-scans'
import { RecoveryTests } from './recovery-tests'
import { StyledDivider } from './styles'

import styles from './index.module.scss'
import { useFetchAwsScanStatistics } from '@features/DashboardV4/ScansAndTests/use-fetch-aws-scan-statistics'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'
import Box from '@mui/material/Box'

function ScansAndTests() {
  const { isSecurityView } = useManagementView()
  const { isLoading, accountRegionStatisticsList } = useFetchAwsScanStatistics()

  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()

  // TODO: implement
  const shouldRenderWidgetWithRowDirection = isSecurityView || isEntropyEnabled

  return (
    <Box
      className={clsx(styles.cardBlock, {
        [String(styles.security)]: shouldRenderWidgetWithRowDirection,
      })}
      data-testid="jsScansAndTests"
    >
      <FileScans
        accountRegionStatisticsList={accountRegionStatisticsList}
        isLoadingRegionStatisticsList={isLoading}
      />
      {shouldRenderWidgetWithRowDirection && <StyledDivider flexItem />}
      <RecoveryTests
        accountRegionStatisticsList={accountRegionStatisticsList}
        isLoadingRegionStatisticsList={isLoading}
      />
    </Box>
  )
}

export default ScansAndTests
