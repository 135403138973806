import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import { DateRangeTuple } from '@lib/engine-types'
import moment from 'moment/moment'
import ReportPeriodInterface from '@lib/interfaces/report-period.interface'
import ReportDateRangeConstant, {
  getReportDateRangeName,
} from '@lib/constants/report-date-range.constant'
import { PeriodTimeRange } from '@features/reports/time-utils'
import { useState } from 'react'

export function useReportPeriod() {
  const timezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value
  // 3 months - max report generatinon period
  const reportDatesRange: DateRangeTuple = [
    moment().tz(timezone).subtract(3, 'months'),
    moment().tz(timezone),
  ]

  const initialReportPeriod: ReportPeriodInterface = {
    period: {
      name: getReportDateRangeName(ReportDateRangeConstant.ThisMonth),
      value: ReportDateRangeConstant.ThisMonth,
    },
    dateRange: PeriodTimeRange.currentMonthDateRange(timezone),
  }

  const [reportPeriod, setReportPeriod] =
    useState<ReportPeriodInterface>(initialReportPeriod)

  const handleReportPeriodChange = (newPeriod: ReportPeriodInterface) => {
    setReportPeriod(newPeriod)
  }
  return {
    timezone,
    reportDatesRange,
    reportPeriod,
    setReportPeriod: handleReportPeriodChange,
  }
}
