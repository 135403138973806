"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetWithThreat = exports.IntegrityStatus = exports.IscanStatus = exports.FsCheckStatus = void 0;
var FsCheckStatus;
(function (FsCheckStatus) {
    FsCheckStatus[FsCheckStatus["NONE"] = 0] = "NONE";
    FsCheckStatus[FsCheckStatus["SUCCEEDED"] = 1] = "SUCCEEDED";
    FsCheckStatus[FsCheckStatus["FAILED"] = 2] = "FAILED";
})(FsCheckStatus || (exports.FsCheckStatus = FsCheckStatus = {}));
var IscanStatus;
(function (IscanStatus) {
    IscanStatus[IscanStatus["NONE"] = 0] = "NONE";
    IscanStatus[IscanStatus["SUCCEEDED"] = 1] = "SUCCEEDED";
    IscanStatus[IscanStatus["FAILED"] = 2] = "FAILED";
})(IscanStatus || (exports.IscanStatus = IscanStatus = {}));
var IntegrityStatus;
(function (IntegrityStatus) {
    IntegrityStatus[IntegrityStatus["NONE"] = 0] = "NONE";
    IntegrityStatus[IntegrityStatus["SUCCEEDED"] = 1] = "SUCCEEDED";
    IntegrityStatus[IntegrityStatus["FAILED"] = 2] = "FAILED";
})(IntegrityStatus || (exports.IntegrityStatus = IntegrityStatus = {}));
class AssetWithThreat {
    threatStatus;
    constructor(attrs) {
        this.threatStatus = attrs.threatStatus;
    }
    getMaxBackupRetention() {
        throw new Error('Not implemented');
    }
    getRpo() {
        throw new Error('Not implemented');
    }
    getSla() {
        throw new Error('Not implemented');
    }
    isOutOfSchedule() {
        throw new Error('Not implemented');
    }
    getFsCheckStatus() {
        return this.threatStatus?.fsCheckStatus ?? null;
    }
    getLastRpFsCheckStatus() {
        if (this.getLastRecoveryPointId() !==
            this.threatStatus?.fsCheckedRecoveryPointId) {
            return FsCheckStatus.NONE;
        }
        return this.threatStatus?.fsCheckStatus;
    }
    getBackupAgeInDays() {
        if (!this.threatStatus?.recoveryPointCreatedAt) {
            return null;
        }
        return Math.round(Math.abs((Date.now() - this.threatStatus.recoveryPointCreatedAt) /
            (1000 * 3600 * 24)));
    }
    getThreatStatus() {
        return this.threatStatus;
    }
    getThreatScanReportsByVolumeIdMap() {
        if (!this.threatStatus) {
            return new Map([]);
        }
        return new Map(this.threatStatus.threatScanReportsByVolumeIdMap);
    }
    getInstanceFsCheckReports() {
        if (!this.threatStatus) {
            return new Map([]);
        }
        return new Map(this.threatStatus.instanceFsCheckReports?.fsCheckReportsByVolumeIdMap);
    }
    getUniqueThreatNames() {
        if (!this.threatStatus) {
            return new Set([]);
        }
        return new Set([
            ...(this.threatStatus?.malwareList || []),
            ...(this.threatStatus?.ransomwareList || []),
        ]);
    }
    getThreatScanReportId() {
        return this.threatStatus?.threatScanReportId ?? '';
    }
    getUniqueRansomwareNames() {
        return new Set(this.threatStatus?.ransomwareList);
    }
    getUniqueMalwareNames() {
        return new Set(this.threatStatus?.malwareList);
    }
    getMalwareThreatsNumber() {
        return this.threatStatus?.malwareList?.length || 0;
    }
    getRansomwareThreatsNumber() {
        return this.threatStatus?.ransomwareList?.length || 0;
    }
    getRansomwareThreatsNameList() {
        return this.threatStatus?.ransomwareList || [];
    }
    getMalwareThreatsNameList() {
        return this.threatStatus?.malwareList || [];
    }
    getTreatScanReportCreatedAt() {
        return this.threatStatus?.threatScanReportCreatedAt;
    }
    getFirstRansomwareDetectedAt() {
        return this.threatStatus?.firstRansomwareDetectedAt;
    }
    getFirstMalwareDetectedAt() {
        return this.threatStatus?.firstMalwareDetectedAt;
    }
    getLastRansomwareDetectedAt() {
        return this.threatStatus?.lastRansomwareDetectedAt;
    }
    getLastMalwareDetectedAt() {
        return this.threatStatus?.lastMalwareDetectedAt;
    }
    getRecoveryPointVaultName() {
        return this.threatStatus?.recoveryPointVault ?? '';
    }
    getLastScannedRecoveryPointVaultName() {
        return this.threatStatus?.scannedRecoveryPointVault;
    }
    isInfected() {
        if (!this.threatStatus) {
            return false;
        }
        return ((this.threatStatus?.ransomwareList?.length || 0) +
            (this.threatStatus?.malwareList?.length || 0) >
            0);
    }
    isNotCyberScanned() {
        return !this.threatStatus || !this.threatStatus?.scannedRecoveryPointId;
    }
    isNotRecoveryTested() {
        if (!this.threatStatus) {
            return true;
        }
        return !this.threatStatus?.fsCheckedRecoveryPointId;
    }
    containsExactMalware(name) {
        return Boolean(this.threatStatus?.malwareList?.includes(name));
    }
    containsExactRansomware(name) {
        return Boolean(this.threatStatus?.ransomwareList?.includes(name));
    }
    containsMalwares() {
        return Boolean(this.threatStatus?.malwareList?.length);
    }
    containsRansomwares() {
        return Boolean(this.threatStatus?.ransomwareList?.length);
    }
    isLastRecoveryPointId() {
        return !!this.threatStatus?.recoveryPointId;
    }
    isFsCheckFailed() {
        return Boolean(this.threatStatus?.fsCheckStatus === FsCheckStatus.FAILED);
    }
    isFsCheckSucceeded() {
        return Boolean(this.threatStatus?.fsCheckStatus === FsCheckStatus.SUCCEEDED);
    }
    hasThreat() {
        return (this.containsMalwares() ||
            this.containsRansomwares() ||
            this.isFsCheckFailed());
    }
    getLastRecoveryPointId() {
        return this.threatStatus?.recoveryPointId ?? '';
    }
    getHealthyRecoveryPointId() {
        return this.threatStatus?.healthyRecoveryPointId ?? null;
    }
    getInfectedRecoveryPointId() {
        return (this.threatStatus?.healthyRecoveryPointId !==
            this.threatStatus?.recoveryPointId ||
            this.threatStatus?.healthyRecoveryPointId === '') &&
            this.getIntegrityStatus() === IntegrityStatus.FAILED
            ? this.threatStatus?.recoveryPointId
            : '';
    }
    getUnscannedRecoveryPointId() {
        return (this.threatStatus?.healthyRecoveryPointId !==
            this.threatStatus?.recoveryPointId ||
            this.threatStatus?.healthyRecoveryPointId === '') &&
            this.getIntegrityStatus() === IntegrityStatus.NONE
            ? this.threatStatus?.recoveryPointId
            : '';
    }
    getLastRpCreatedAt() {
        return this.threatStatus?.recoveryPointCreatedAt ?? null;
    }
    getHealthyRecoveryPointCreatedAt() {
        return this.threatStatus?.healthyRecoveryPointCreatedAt ?? null;
    }
    getHealthyTenantRecoveryPointId() {
        return this.threatStatus?.healthyTenantRecoveryPointId ?? null;
    }
    getIscanStatus() {
        if (this.getLastRecoveryPointId() !==
            this.threatStatus?.scannedRecoveryPointId) {
            return IscanStatus.NONE;
        }
        if (!this.threatStatus?.threatScanReportId) {
            return IscanStatus.NONE;
        }
        if (this.threatStatus?.threatScanReportId && this.isInfected()) {
            return IscanStatus.FAILED;
        }
        else {
            return IscanStatus.SUCCEEDED;
        }
    }
    getIntegrityStatus() {
        if (this.getIscanStatus() === IscanStatus.NONE &&
            this.getFsCheckStatus() === FsCheckStatus.NONE) {
            return IntegrityStatus.NONE;
        }
        if ((this.getIscanStatus() === IscanStatus.SUCCEEDED &&
            this.getFsCheckStatus() === FsCheckStatus.SUCCEEDED) ||
            (this.getIscanStatus() === IscanStatus.NONE &&
                this.getFsCheckStatus() === FsCheckStatus.SUCCEEDED) ||
            (this.getIscanStatus() === IscanStatus.SUCCEEDED &&
                this.getFsCheckStatus() === FsCheckStatus.NONE)) {
            return IntegrityStatus.SUCCEEDED;
        }
        if (this.getIscanStatus() === IscanStatus.FAILED ||
            this.getFsCheckStatus() === FsCheckStatus.FAILED) {
            return IntegrityStatus.FAILED;
        }
        return IntegrityStatus.NONE;
    }
    getScannedRecoveryPointCreatedAt() {
        return this.threatStatus?.scannedRecoveryPointCreatedAt ?? null;
    }
    getFsCheckedRecoveryPointId() {
        return this?.threatStatus?.fsCheckedRecoveryPointId ?? null;
    }
    getTenantRecoveryPointId() {
        return this.threatStatus?.tenantRecoveryPointId ?? '';
    }
    getRecoveryPointInstanceId() {
        return this.threatStatus?.recoveryPointInstanceId ?? '';
    }
    getRecoveryPointTotalBytesIngested() {
        return this.threatStatus?.recoveryPointTotalBytesIngested ?? 0;
    }
    getRecoveryPointNewBytesIngested() {
        return this.threatStatus?.recoveryPointNewBytesIngested ?? 0;
    }
    getRecoveryPointBytesIncluded() {
        return this.threatStatus?.recoveryPointBytesIncluded ?? 0;
    }
    getRecoveryPointBytesUploaded() {
        return this.threatStatus?.recoveryPointBytesUploaded ?? 0;
    }
    getScannedTenantRecoveryPointId() {
        return this.threatStatus?.scannedTenantRecoveryPointId ?? '';
    }
    getScannedRecoveryPointInstanceId() {
        return this.threatStatus?.scannedRecoveryPointInstanceId ?? '';
    }
    getScannedRecoveryPointTotalBytesIngested() {
        return this.threatStatus?.scannedRecoveryPointTotalBytesIngested ?? 0;
    }
    getScannedRecoveryPointNewBytesIngested() {
        return this.threatStatus?.scannedRecoveryPointNewBytesIngested ?? 0;
    }
    getScannedRecoveryPointBytesIncluded() {
        return this.threatStatus?.scannedRecoveryPointBytesIncluded ?? 0;
    }
    getScannedRecoveryPointBytesUploaded() {
        return this.threatStatus?.scannedRecoveryPointBytesUploaded ?? 0;
    }
    getHealthyRecoveryPointVault() {
        return this.threatStatus?.healthyRecoveryPointVault ?? '';
    }
    getHealthyRecoveryPointInstanceId() {
        return this.threatStatus?.healthyRecoveryPointInstanceId ?? '';
    }
    getHealthyRecoveryPointTotalBytesIngested() {
        return this.threatStatus?.healthyRecoveryPointTotalBytesIngested ?? 0;
    }
    getHealthyRecoveryPointNewBytesIngested() {
        return this.threatStatus?.healthyRecoveryPointNewBytesIngested ?? 0;
    }
    getHealthyRecoveryPointBytesIncluded() {
        return this.threatStatus?.healthyRecoveryPointBytesIncluded ?? 0;
    }
    getHealthyRecoveryPointBytesUploaded() {
        return this.threatStatus?.healthyRecoveryPointBytesUploaded ?? 0;
    }
    getFsCheckedTenantRecoveryPointId() {
        return this.threatStatus?.fsCheckedTenantRecoveryPointId ?? '';
    }
    getFsCheckedRecoveryPointVault() {
        return this.threatStatus?.fsCheckedRecoveryPointVault ?? '';
    }
    getFsCheckedRecoveryPointInstanceId() {
        return this.threatStatus?.fsCheckedRecoveryPointInstanceId ?? '';
    }
    getFsCheckedRecoveryPointTotalBytesIngested() {
        return this.threatStatus?.fsCheckedRecoveryPointTotalBytesIngested ?? 0;
    }
    getFsCheckedRecoveryPointNewBytesIngested() {
        return this.threatStatus?.fsCheckedRecoveryPointNewBytesIngested ?? 0;
    }
    getFsCheckedRecoveryPointBytesIncluded() {
        return this.threatStatus?.fsCheckedRecoveryPointBytesIncluded ?? 0;
    }
    getFsCheckedRecoveryPointBytesUploaded() {
        return this.threatStatus?.fsCheckedRecoveryPointBytesUploaded ?? 0;
    }
    getHealthyMalwareScanTimestamp() {
        return this.threatStatus?.healthyMalwareScanTimestamp ?? null;
    }
    getHealthyRansomwareScanTimestamp() {
        return this.threatStatus?.healthyRansomwareScanTimestamp ?? null;
    }
    getHealthyFsCheckTimestamp() {
        return this.threatStatus?.healthyFsCheckTimestamp ?? null;
    }
    getFsCheckedRecoveryPointCreatedAt() {
        return this.threatStatus?.fsCheckedRecoveryPointCreatedAt ?? null;
    }
}
exports.AssetWithThreat = AssetWithThreat;
