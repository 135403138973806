import { useState } from 'react'

import { MAX_QUANTILES_SIZE } from '@features/DashboardV4/widgets-with-scale/shared'

import type { QuantileUI } from './scale'

export function useScale(
  initDivisionPosition: number,
  maxQuantilesSize = MAX_QUANTILES_SIZE
) {
  const [offsets, setOffsets] = useState<Array<Pick<QuantileUI, 'top'>>>(
    Array(maxQuantilesSize).fill({ top: 0 })
  )
  const [currentQuantile, setCurrentQuantile] = useState<Partial<QuantileUI>>({
    top: 0,
    percentile: initDivisionPosition,
  })

  return {
    offsets,
    setOffsets,
    currentQuantile,
    setCurrentQuantile,
  } as const
}
