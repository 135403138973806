import VaultDetailedComplex from '@components-complex/vault-detailed-complex/VaultDetailedComplex'

function VaultDetailedPage() {
  return (
    <div className="innerContent innerContentBlueBackground innerContentStripe">
      <VaultDetailedComplex />
    </div>
  )
}

export default VaultDetailedPage
