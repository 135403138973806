import React from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import { EntropyFilterHeader } from './filter-header'
import { StyledAccordionContainer } from './styles'
import { Divider } from '@mui/material'

interface EntropyTrendsFilterProps {
  filterName: string
  isLoading: boolean
  isExpanded: boolean
  onAccordionClick?: () => void
}

export function EntropyTrendsFilter({
  filterName,
  isLoading,
  isExpanded,
  onAccordionClick,
  children,
}: React.PropsWithChildren<EntropyTrendsFilterProps>) {
  return (
    <StyledAccordionContainer disabled={isLoading} expanded={isExpanded}>
      <EntropyFilterHeader
        filterName={filterName}
        onHeaderClick={onAccordionClick}
      />
      <Divider sx={{ mb: 1 }} />
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordionContainer>
  )
}
