import { useEffect } from 'react'
import {
  useDeploymentJobsList,
  useDeploymentJobsStoreActions,
  useSelectedDeploymentJobFilters,
} from '../stores'
import { fetchJobsFromLastHour } from './fetch-jobs-from-last-hour'
import { getUpdatedJobs } from './get-updated-jobs'

const JOBS_INTERVAL_MILLISECONDS = 10000

export function useTrackChangedJobs() {
  const selectedFilters = useSelectedDeploymentJobFilters()

  const deploymentJobsList = useDeploymentJobsList()

  const { setDeploymentJobsList } = useDeploymentJobsStoreActions()

  useEffect(() => {
    let isComponentMounted = true

    const interval = setInterval(
      async () => {
        const jobsFromLastHour = await fetchJobsFromLastHour(selectedFilters)

        const { updatedElements } = getUpdatedJobs({
          jobsFromServer: jobsFromLastHour,
          jobsInState: deploymentJobsList,
        })

        if (updatedElements.length === 0) {
          return
        }

        const newJobsList = deploymentJobsList.map((job) => {
          const changedJob = updatedElements.find((j) => j.id === job.id)

          return changedJob ?? job
        })

        const newJobsIds = new Set(newJobsList.map((job) => job.id))

        const jobsNotInList = updatedElements.filter(
          (job) => !newJobsIds.has(job.id)
        )

        if (isComponentMounted) {
          setDeploymentJobsList([...jobsNotInList, ...newJobsList])
        }
      },

      JOBS_INTERVAL_MILLISECONDS
    )

    return () => {
      clearInterval(interval)
      isComponentMounted = false
    }
  }, [deploymentJobsList, selectedFilters])
}
