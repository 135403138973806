const SKIP_RP_INDEX = 1
const SKIP_RP_HINT =
  'Warning: EC2 recovery points will still be generated even if you opt to skip creating new recovery points. When importing pre-existing EBS snapshots, no new backups will be created.'

export const TABS_HINT_FOR_CREATE_RP = [
  {
    tabIndex: SKIP_RP_INDEX,
    text: SKIP_RP_HINT,
  },
]

export const INVALID_POLICY_NAME =
  'Policy name can only contain alphanumeric characters, spaces and +-._:/@.The name can\'t begin with "+", "/" or whitespace, and can\'t end with whitespace.'
