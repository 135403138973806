import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

export enum DrawerName {
  GenerateReport = 'generateReport',
  ModifyReportSchedule = 'modifyReportSchedule',
  ReportDetails = 'reportDetails',
}

export interface DrawerState<T = any> {
  isOpen: boolean
  data?: T
}

interface DrawerActions {
  openDrawer: <T>(drawerName: DrawerName, data?: T) => void
  closeDrawer: (drawerName: DrawerName) => void
  closeAllDrawers: () => void
  // TODO add types
  setDrawerData: <K extends DrawerName>(drawerName: K, data: any) => void
}

export interface DrawerStore {
  drawers: {
    [key in DrawerName]?: DrawerState
  }
}

const INITIAL_STATE: DrawerStore = {
  drawers: {},
}

const useDrawerStore = create<DrawerStore & { actions: DrawerActions }>(
  (set) => ({
    ...INITIAL_STATE,
    actions: {
      openDrawer: <T>(drawerName: DrawerName, data?: T) =>
        set((state) => ({
          drawers: {
            ...state.drawers,
            [drawerName]: {
              isOpen: true,
              data,
            },
          },
        })),
      setDrawerData: <T>(drawerName: DrawerName, data?: T) =>
        set((state) => ({
          drawers: {
            ...state.drawers,
            [drawerName]: {
              ...state.drawers[drawerName],
              data,
            },
          },
        })),
      closeDrawer: (drawerName: DrawerName) =>
        set((state) => ({
          drawers: {
            ...state.drawers,
            [drawerName]: {
              ...state.drawers[drawerName],
              isOpen: false,
            },
          },
        })),

      closeAllDrawers: () =>
        set(() => ({
          drawers: {},
        })),
    },
  })
)

const useDrawers = () => useDrawerStore(useShallow((state) => state.drawers))

const useDrawerActions = () =>
  useDrawerStore(useShallow((state) => state.actions))

// interface DrawerDataMap {
//     [DrawerName.GenerateReport]: GenerateReportData;
//     [DrawerName.ModifyReportSchedule]: ModifyReportScheduleData;
// }

export function useDrawerState<K extends DrawerName>(drawerName: K) {
  return useDrawerStore(
    useShallow((state) => state.drawers[drawerName] || { isOpen: false })
  ) as DrawerState<any>
}

export { useDrawerStore, useDrawerActions, useDrawers }
