import React, { Component, ReactNode } from 'react'
import SystemHelper from '@lib/helpers/system.helper'
import { Box, Button, Container, Typography } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useNavigation } from '@lib/router/contexts/navigation-context'

const BackButton: React.FC = () => {
  const router = useNavigation()

  function goBack() {
    router.back()
  }

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<ArrowBack />}
      onClick={goBack}
    >
      Go Back
    </Button>
  )
}

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error: Error): void {
    SystemHelper.sendObjectToSentryIfProd(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="sm">
          <Box
            sx={{
              mt: 3,
              textAlign: 'center',
              p: 2,
            }}
          >
            <Typography variant="h5" gutterBottom>
              <b>Something went wrong</b>
            </Typography>
            <Typography variant="body1" paragraph>
              The application encountered an issue. Our team has been notified
              and is working on a fix. In the meantime, you might find what you
              need by going back to the previous page.
            </Typography>
            <BackButton />
          </Box>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
