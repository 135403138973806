import { useState } from 'react'
import FormRestoreInterface from '@lib/interfaces/form/form-restore.interface'
import { pechkinClient } from '@lib/clients/pechkin'
import { Nullable } from '@lib/engine-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ElastioRecoveryPoint } from 'blues-corejs/dist'

type UseRestoreScheduleOutput = {
  restoreSchedule: (formValues: FormRestoreInterface) => void
  isRestoreInProgress: boolean
}
function useRestoreSchedule(
  backup: Nullable<ElastioRecoveryPoint>,
  ccAssetId?: string
): UseRestoreScheduleOutput {
  const [isRestoreInProgress, setIsRestoreInProgress] = useState(false)

  const restoreSchedule = async (formValues: FormRestoreInterface) => {
    if (!backup || !ccAssetId) {
      return
    }
    setIsRestoreInProgress(true)
    try {
      await pechkinClient.scheduleRestoreEBS({
        ccRpId: backup.ccRpId,
        ccId: backup.ccId,
        ccAssetId,
        ccVaultId: backup.vaultName,
        tags: formValues.tags,
        availabilityZone: formValues.zone,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsRestoreInProgress(false)
    }
  }

  return {
    isRestoreInProgress,
    restoreSchedule,
  }
}

export { useRestoreSchedule }
