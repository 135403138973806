/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { SearchBar } from './search-bar'
import Stack from '@mui/material/Stack'
import {
  useToolbarFiltersActions,
  useToolbarFiltersSubscription,
} from './toolbar-filters-store'
import { OvaAccount } from 'blues-corejs/dist'
import { OvaAccountsSelect } from './ova-accounts-select'
import { OvaBackupProvidersSelect } from './ova-backup-providers-select'
import { ServersStatusCheckboxGroup } from './servers-status-checkbox'
import { useDrilldownQuery } from './use-drilldown-query'

interface Props {
  ovaAccountsList: Array<OvaAccount>
}

function Toolbar({ ovaAccountsList }: Props) {
  const { resetToolbarFilters } = useToolbarFiltersActions()

  useToolbarFiltersSubscription()

  useDrilldownQuery(ovaAccountsList)

  useEffect(() => {
    return () => resetToolbarFilters()
  }, [])

  return (
    <Box gap={1} display="grid">
      <SearchBar />
      <Stack direction="row" columnGap={2}>
        <Box gap={1} display="flex" flexDirection="row">
          <OvaAccountsSelect ovaAccountsList={ovaAccountsList} />
          <OvaBackupProvidersSelect ovaAccountsList={ovaAccountsList} />
        </Box>
        <ServersStatusCheckboxGroup />
      </Stack>
    </Box>
  )
}

export default Toolbar
