import Box from '@mui/material/Box'

import { useGlobalLoginCheck } from './use-global-login-check'

function LoadingScreen() {
  const isGlobalLogin = useGlobalLoginCheck()

  return (
    <Box
      className="innerContent llContent llFullHeight"
      display="flex"
      alignItems="center"
    >
      {!isGlobalLogin && (
        <div className="llTitle">Launching your tenant...</div>
      )}
      <img
        width={280}
        height={220}
        alt=""
        src="/images/tenant-creation-loader.svg"
      />
    </Box>
  )
}

export default LoadingScreen
