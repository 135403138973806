/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import IconMUI from '@mui/material/Icon'
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'
import React from 'react'
import JobUnscannedIcon from '@inline-img/general/job-dashboard/JobUnscannedIcon'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReportIcon from '@mui/icons-material/Report'

type Backup = ElastioRecoveryPoint | AWSBRecoveryPoint | EBSSnapshot

interface Props {
  backup: Backup
}

function computeBackupStatusColor(backup: Backup) {
  if (backup.summaryCheckStatus === SummaryCheckStatus.UNCHECKED) {
    return 'el-grey'
  }
  if (backup.summaryCheckStatus === SummaryCheckStatus.GOOD) {
    return 'el-success'
  }

  return 'el-error'
}

function computeBackupStatusIcon(backup: Backup) {
  if (backup.summaryCheckStatus === SummaryCheckStatus.UNCHECKED) {
    return JobUnscannedIcon
  }
  if (backup.summaryCheckStatus === SummaryCheckStatus.GOOD) {
    return CheckCircleIcon
  }

  return ReportIcon
}

function AssetItemRecoveryPointStatusCell({ backup }: Props) {
  const color = computeBackupStatusColor(backup)
  const Icon = computeBackupStatusIcon(backup)

  return (
    <Box display="flex" alignItems="center" gap="5px">
      <IconMUI color={color} fontSize="small" component={Icon} />
    </Box>
  )
}

export { AssetItemRecoveryPointStatusCell }
