import { VIRowReadonly } from '../engine-types'

export enum AssetSource {
  ASSET_SOURCE_EC2 = 0,
  ASSET_SOURCE_EBS = 1,
  ASSET_SOURCE_GENERIC_HOST = 2,
  ASSET_SOURCE_NAS = 3,
  ASSET_SOURCE_S3 = 4,
}

export function getAssetSourceName(source: AssetSource): string {
  switch (source) {
    case AssetSource.ASSET_SOURCE_EC2:
      return 'EC2'

    case AssetSource.ASSET_SOURCE_EBS:
      return 'EBS'

    case AssetSource.ASSET_SOURCE_GENERIC_HOST:
      return 'Generic host'

    case AssetSource.ASSET_SOURCE_NAS:
      return 'NAS'

    case AssetSource.ASSET_SOURCE_S3:
      return 'S3'

    default:
      return ''
  }
}

export function getAssetSourceList(): VIRowReadonly {
  {
    /*TODO: hidden S3 and Generic 08.02.2024 by task 9480 and 9538*/
  }
  const result = [
    {
      name: getAssetSourceName(AssetSource.ASSET_SOURCE_EC2),
      value: AssetSource.ASSET_SOURCE_EC2,
    },
    {
      name: getAssetSourceName(AssetSource.ASSET_SOURCE_EBS),
      value: AssetSource.ASSET_SOURCE_EBS,
    },
    // {
    //   name: getAssetSourceName(AssetSource.ASSET_SOURCE_GENERIC_HOST),
    //   value: AssetSource.ASSET_SOURCE_GENERIC_HOST,
    // },
    // {
    //   name: getAssetSourceName(AssetSource.ASSET_SOURCE_S3),
    //   value: AssetSource.ASSET_SOURCE_S3,
    // },
  ]

  return result
}
