import { useMutation } from '@tanstack/react-query'
import { DownloadGeneratedReportCriteria } from 'ui-v2/src/lib/clients/reports/on-demand/download-generated-report'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { GeneratedReportsClient } from 'ui-v2/src/lib/clients/reports/on-demand'

export function useDownloadGeneratedReport() {
  const generatedReportsClient = useGrpcClient(GeneratedReportsClient)

  return useMutation({
    mutationFn: async ({ id }: DownloadGeneratedReportCriteria) => {
      if (!generatedReportsClient) {
        throw new Error('Generated report client not initialized')
      }

      return generatedReportsClient.downloadGeneratedReport({ id })
    },
  })
}
