import { EntropyGraph } from '@features/common/entropy-graph'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import React, { useMemo } from 'react'
import { columns, MENU_ITEMS } from './consts'
import { EntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'
import {
  buildTimeRangeForRequest,
  getDateFormatBasedByRange,
} from '@features/DashboardV4/entropy-trends/utils'
import { StyledTimeSelect } from './styles'
import {
  useEntropyFiltersActions,
  useGetEntropyTrendsDetailsStoreFilters,
  useGetEntropyTrendsHistory,
  useGetIsDataLoading,
} from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store.context'
import { GoogleDataTableRow } from 'react-google-charts'
import { Typography } from '@mui/material'

const CHART_OPTIONS = {
  chartArea: {
    width: '90%',
    height: '85%',
  },
  height: 300,
  bar: { groupWidth: '11' },
}

function EntropyTrendsDetailsGraph() {
  const entropyTrendsHistory = useGetEntropyTrendsHistory()
  const { timeSelection } = useGetEntropyTrendsDetailsStoreFilters()
  const { setFilters, fetchEntropyTrendsHistory } = useEntropyFiltersActions()
  const { startAt, endAt } = useGetEntropyTrendsDetailsStoreFilters()
  const isLoading = useGetIsDataLoading()

  const shouldUseDynamicTicks =
    timeSelection !== EntropyTrendsTimeSelection.TODAY

  const rows = entropyTrendsHistory.anomalyDataPoints.map((dataPoint) => [
    dataPoint.timestamp,
    dataPoint.anomalyFindingCount,
  ])

  const chartOptions = useMemo(() => {
    return {
      ...CHART_OPTIONS,
      hAxis: {
        type: 'date',
        format: getDateFormatBasedByRange(timeSelection),
      },
    }
  }, [timeSelection])

  const shouldShowDateLabel =
    rows.length !== 0 && !isLoading && rows.some((row) => row[1] !== 0)

  // We set unknown because when we set custom styled Select types inheritance are broken
  const handleSelectDate = (event: SelectChangeEvent<unknown>) => {
    const newTimeRangeForRequest = buildTimeRangeForRequest(
      event.target.value as EntropyTrendsTimeSelection,
      startAt,
      endAt
    )

    fetchEntropyTrendsHistory({
      timeSelection: event.target.value as EntropyTrendsTimeSelection,
      startAt: newTimeRangeForRequest.startDate,
      endAt: newTimeRangeForRequest.endDate,
      timeGranularity: newTimeRangeForRequest.timeGranularity,
    })
  }

  const handleSelectPoint = (point: GoogleDataTableRow) => {
    const anomaly = entropyTrendsHistory.anomalyDataPoints.find(
      ({ timestamp }) => timestamp === point[0]
    )

    if (!anomaly) {
      setFilters({
        anomaliesIdsList: [],
      })
      return
    }

    setFilters({
      anomaliesIdsList: anomaly.anomalyIdsList,
    })
  }

  return (
    <Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        paddingX="16px"
        marginTop="8px"
        gap="8px"
      >
        <StyledTimeSelect
          size="small"
          value={timeSelection}
          onChange={handleSelectDate}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledTimeSelect>
      </Stack>
      <EntropyGraph
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        options={chartOptions}
        onSelectPoint={handleSelectPoint}
        shouldUseDynamicTicks={shouldUseDynamicTicks}
        chartType="ColumnChart"
        noNeedToRenderEmptyRows
      />
      {shouldShowDateLabel && (
        <Typography fontWeight="600" textAlign="center" marginBottom="32px">
          Scanning Date
        </Typography>
      )}
    </Stack>
  )
}

export { EntropyTrendsDetailsGraph }
