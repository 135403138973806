import React, { useEffect, useMemo, useState } from 'react'
import { EngineCallbackPure } from '@lib/engine-types'
import { AuthorizationOptions } from '@lib/constants/notifications.constant'
import { WebhookValueInterface } from '@lib/interfaces/notifications.interface'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  Secret,
  WebhookAuthentication,
  WebhookEventSubscription,
} from '@lib/models/webhook.model'
import {
  addWebhook,
  requestTestWebhook,
  requestWebhooksList,
  setTestWebhookResult,
  updateWebhook,
} from '@store/actions/webhook.action'
import AddWebhookForm from '@forms/add-webhook/add-webhook-form'
import ModalCloseIcon from '@inline-img/general/modal-close-icon'
import BaseModal from './BaseModal'
import { WebhookFormInterface } from '@forms/add-webhook/data'
import { useDispatch, useSelector } from 'react-redux'
import ObjHelper from '@lib/helpers/obj.helper'
import { getTestWebhookResult } from '@store/selectors/webhook.selector'

interface Props {
  onCancel: EngineCallbackPure
  loading: boolean
  show: boolean
  selectedWebhook?: WebhookModel
}

const AddWeebhookModal: React.FC<Props> = ({
  show,
  onCancel,
  loading,
  selectedWebhook,
}) => {
  const dispatch = useDispatch()

  const testWebhookResult = useSelector(getTestWebhookResult)

  const isOnlyForAddEvents = useMemo(
    () => ObjHelper.isNotEmpty(selectedWebhook),
    [selectedWebhook]
  )

  const [tableData, setTableData] = useState<Array<WebhookValueInterface>>([])

  const getAuthType = (data: WebhookFormInterface) => {
    switch (data.authorization) {
      case AuthorizationOptions.BASIC:
        return new BasicAuth(
          new Secret({
            secret: data.login,
            encrypted: false,
          }),
          new Secret({
            secret: data.password,
            encrypted: false,
          })
        )
      case AuthorizationOptions.BEARER:
        return new BearerToken(
          new Secret({
            secret: data.token,
            encrypted: false,
          })
        )
      case AuthorizationOptions.API_KEY:
        return new ApiKey(
          new Secret({
            secret: data.apiKey,
            encrypted: false,
          }),
          new Secret({
            secret: data.apiValue,
            encrypted: false,
          })
        )
      case AuthorizationOptions.NONE:
        return
    }
  }

  const onSubmit = (data: WebhookFormInterface) => {
    const events: Array<WebhookEventSubscription> = tableData.map((item) => {
      return new WebhookEventSubscription(
        item.eventType,
        item.version,
        item.severities
      )
    })

    const payload = new WebhookModel({
      name: data.name,
      endpoint: data.endpoint,
      authentication: new WebhookAuthentication(getAuthType(data)),
      description: data.description,
      id: isOnlyForAddEvents
        ? String(selectedWebhook?.getId())
        : String(Date.now()),
      event_subscriptions: events,
    })

    if (isOnlyForAddEvents && selectedWebhook) {
      const updatedWebhook = new WebhookModel({
        name: selectedWebhook.getName(),
        endpoint: selectedWebhook.getEndpoint(),
        authentication: selectedWebhook.getAuthentication(),
        description: selectedWebhook.getDescription(),
        id: selectedWebhook.getId(),
        event_subscriptions: events,
      })
      dispatch(updateWebhook(updatedWebhook))
      return
    }
    dispatch(addWebhook(payload))
  }

  const onTestWebhook = (data: WebhookFormInterface) => {
    dispatch(
      requestTestWebhook({
        name: data.name,
        endpoint: data.endpoint,
        authentication: new WebhookAuthentication(getAuthType(data)),
      })
    )
  }

  useEffect(() => {
    if (testWebhookResult === undefined) {
      return
    }

    setTimeout(() => {
      dispatch(setTestWebhookResult(undefined))
    }, 1000 * 10)
  }, [testWebhookResult])

  useEffect(() => {
    dispatch(requestWebhooksList())
  }, [])

  return (
    <BaseModal
      className="modalDefaultV2Small addWebhookModal"
      show={show}
      onBgClick={onCancel}
    >
      <div className="modalHeaderWrapV2">
        <div className="mhTitle">
          {isOnlyForAddEvents ? 'Add Events' : 'Add Webhook'}
        </div>
        <div
          className="closableIcon"
          title="Close"
          onClick={onCancel}
          role="button"
        >
          <ModalCloseIcon />
        </div>
      </div>
      <div className="modalFormContentV2">
        <AddWebhookForm
          onCancel={onCancel}
          tableData={tableData}
          setTableData={setTableData}
          onSubmit={onSubmit}
          loading={loading}
          selectedWebhook={selectedWebhook}
          onTestWebhook={onTestWebhook}
          testWebhookSuccess={testWebhookResult}
        />
      </div>
    </BaseModal>
  )
}

export default AddWeebhookModal
