/* eslint-disable import/no-extraneous-dependencies */
import { RuleAwsAccountRegion } from 'blues-corejs/dist/models/notifications/rule'
import { createColumn, Table } from '@components/table'
import { Box } from '@mui/material'
import React from 'react'

const getColumns = () => {
  return [
    createColumn<RuleAwsAccountRegion>('accountId', {
      header: 'AWS Accounts',
      meta: {
        width: '40%',
      },
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
    createColumn<RuleAwsAccountRegion>('region', {
      header: 'Regions',
      meta: {
        width: '60%',
      },
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
  ]
}

export const computeAwsAccounts = (
  awsAccounts: Array<RuleAwsAccountRegion>
) => {
  if (awsAccounts.length === 0) {
    return null
  }

  return (
    <Box mb={2}>
      <Table
        data={awsAccounts}
        columns={getColumns()}
        generalProps={{
          noDataMessage: 'No accounts found',
          isLoading: false,
        }}
      />
    </Box>
  )
}
