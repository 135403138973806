import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import NoDataDisplay from 'ui-v2/src/components/common/no-data'
import { CircularProgress, Stack } from '@mui/material'

interface DrawerContentProps {
  isLoading?: boolean
  isError?: boolean
  isEmpty?: boolean
  errorMessage?: string
  emptyMessage?: string
  children: React.ReactNode
}

const DrawerContent: React.FC<DrawerContentProps> = ({
  isLoading = false,
  isError = false,
  isEmpty = false,
  errorMessage,
  emptyMessage,
  children,
}) => {
  if (isLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: '100%',
          py: 4,
        }}
      >
        <CircularProgress />
      </Stack>
    )
  }

  if (isError) {
    return <ErrorDisplay message={errorMessage} />
  }

  if (isEmpty) {
    return <NoDataDisplay message={emptyMessage} />
  }

  return <>{children}</>
}

export default DrawerContent
