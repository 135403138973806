/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'
import { AwsStorageMisconfigurationsStatsResponse } from '@lib/clients/aws-statistics/types'
import { AwsStatsClient } from '@lib/clients/aws-statistics'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { useSelector } from 'react-redux'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

const INITIAL_STORAGE_MISCONFIGURATIONS_COUNT: AwsStorageMisconfigurationsStatsResponse =
  {
    snapshotVulnerabilitiesList: [],
    volumeVulnerabilitiesList: [],
  }

const awsStatsClient = new AwsStatsClient()

export function useFetchAwsStorageMisconfigurations() {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )
  const [isLoading, setIsLoading] = useState(false)
  const [storageMisconfigurationsCount, setStorageMisconfigurationsCount] =
    useState<AwsStorageMisconfigurationsStatsResponse>(
      INITIAL_STORAGE_MISCONFIGURATIONS_COUNT
    )

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)
    const fetchStorageMisconfigurationsStats = async () => {
      try {
        const response = await awsStatsClient.getStorageMisconfigurations({
          regions,
          accountIds,
          includeTags,
          excludeTags,
        })

        if (isMounted) {
          setStorageMisconfigurationsCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getStorageMisconfigurations: failed to fetch storageMisconfigurations count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    const fetchInterval = setInterval(
      fetchStorageMisconfigurationsStats,
      INTERVAL_REQUEST_TIMEOUT
    )
    fetchStorageMisconfigurationsStats()

    return () => {
      clearInterval(fetchInterval)
      isMounted = false
      setStorageMisconfigurationsCount(INITIAL_STORAGE_MISCONFIGURATIONS_COUNT)
      setIsLoading(false)
    }
  }, [accountIds, regions, includeTags?.tags, excludeTags?.tags])

  return {
    isLoading,
    snapshotVulnerabilitiesList:
      storageMisconfigurationsCount.snapshotVulnerabilitiesList,
    volumeVulnerabilitiesList:
      storageMisconfigurationsCount.volumeVulnerabilitiesList,
  }
}
