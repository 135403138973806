import { VIRow } from '../engine-types'
import ObjHelper from './obj.helper'

export const updateValueByIndex = (
  index: number,
  newValue: number | string | boolean,
  arr: VIRow
): VIRow => {
  const result: VIRow = ObjHelper.cloneDeep(arr)
  const elem = result[index]
  if (elem) {
    elem.value = newValue
  }
  return result
}
