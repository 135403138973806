import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

interface ControlledCheckboxOwnProps {
  name: string
  label?: string
  isDisabled?: boolean
}

function ControlledCheckbox({
  label = '',
  isDisabled = false,
  name,
}: ControlledCheckboxOwnProps) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          disabled={isDisabled}
          control={
            <Checkbox
              {...field}
              checked={field.value || false}
              onChange={(event) => field.onChange(event.target.checked)}
              size="small"
            />
          }
          label={label}
        />
      )}
    />
  )
}

export default ControlledCheckbox
