import { Request as ListScansRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/list_scans_by_filters_pb'
import { Request as Pagination } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { ListScansByFiltersRequest } from '@lib/clients/scans/list-scans-by-filters'

export class ListScansByFiltersPagination implements ListScansByFiltersRequest {
  #pageToken: string

  constructor(pageToken: string) {
    this.#pageToken = pageToken
  }

  toGrpc(): ListScansRequest {
    const grpcRequest = new ListScansRequest()
    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
