import { STEP_CHANGE } from './constants'

export const getNextStep = (currentStep: number, maxStep: number): number =>
  currentStep < maxStep ? currentStep + STEP_CHANGE : currentStep

export const getPreviousStep = (currentStep: number, minStep = 0): number =>
  currentStep > minStep ? currentStep - STEP_CHANGE : currentStep

export const getDisplayStep = (step: number): number => step + STEP_CHANGE

export const getEnumStep = (displayStep: number, maxStep: number): number => {
  const step = displayStep - STEP_CHANGE
  if (step < 0 || step > maxStep) {
    throw new Error('Invalid display step number')
  }
  return step
}

export const isLastStep = (currentStep: number, maxStep: number): boolean =>
  currentStep === maxStep
