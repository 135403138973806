/* eslint-disable import/no-extraneous-dependencies */
import { BACKUP_VENDOR_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import {
  AssetsOvaServerDrawerAttrs,
  OvaServerDrawer,
} from 'ui-v2/src/lib/models/assets'
import { Scan } from 'ui-v2/src/lib/models/scans'
import {
  Backup,
  Nullable,
  OvaBackupProvider,
  OvaServer,
} from 'blues-corejs/dist'
import {
  MalwareScanBackup,
  MalwareScanTarget,
} from 'blues-corejs/dist/models/scans/malware/index'
import { ScanTarget } from 'blues-corejs/dist/models/scans/malware/types'
import {
  RansomwareScanBackup,
  RansomwareScanTarget,
} from 'blues-corejs/dist/models/scans/ransomware/index'
import { AssetsOvaServerBase } from './ova-server-base'

export class AssetsOvaServerDrawerData extends AssetsOvaServerBase {
  protected readonly servers: Array<OvaServer>

  protected readonly ovaAccountsMap: Map<string, string>

  readonly #scans: Array<Scan>

  readonly #lastBackups: Map<string, Backup>

  readonly #serversWithScans: Array<{
    server: OvaServer
    scans: Array<Scan>
  }>

  constructor(parameters: AssetsOvaServerDrawerAttrs) {
    super()
    this.servers = parameters.servers
    this.ovaAccountsMap = parameters.ovaAccounts.reduce((map, ovaAccount) => {
      map.set(ovaAccount.id, ovaAccount.name)
      return map
    }, new Map<string, string>())
    this.#scans = parameters.scans
    this.#serversWithScans = this.#getServersWithScans()
    this.#lastBackups = parameters.lastBackups
  }

  getOvaServerDrawerData() {
    const server = this.servers[0]
    if (!server) {
      return undefined
    }
    return new OvaServerDrawer({
      id: server.id,
      name: server.name,
      ovaAccountId: this.ovaAccountsMap.get(server.ovaAccountId) ?? '',
      backupProvider: this.#convertBackupProvider(server.backupProvider),
      lastScan: this.#getLastScanForServer(server),
      lastBackup: this.#getLastBackup(server),
      isUnhealthy: false,
    })
  }

  #getLastBackup(server: OvaServer): Nullable<Backup> {
    return this.#lastBackups.get(server.id)
  }

  #convertBackupProvider(
    backupProvider: OvaBackupProvider
  ): (typeof BACKUP_VENDOR_MAP)[keyof typeof BACKUP_VENDOR_MAP] {
    return BACKUP_VENDOR_MAP[backupProvider]
  }

  #getLastScanForServer(server: OvaServer) {
    const matchingScan = this.#serversWithScans.find(
      (serverScan) => serverScan.server.id === server.id
    )
    if (!matchingScan) {
      return null
    }

    return matchingScan.scans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      matchingScan.scans[0]
    )
  }

  #isRansomwareScanTarget(target: ScanTarget, server: OvaServer) {
    if (target instanceof RansomwareScanTarget) {
      if ('assetId' in target.target) {
        return target.target.assetId === server.id
      }

      if (target instanceof RansomwareScanBackup && target.source.asset) {
        return target.source.asset.backupAssetId === server.id
      }
    }
  }

  #isMalwareScanTarget(target: ScanTarget, server: OvaServer) {
    if (target instanceof MalwareScanTarget) {
      if ('assetId' in target.target) {
        return target.target.assetId === server.id
      }

      if (target instanceof MalwareScanBackup && target.source.asset) {
        return target.source.asset.backupAssetId === server.id
      }
    }
  }

  #filterScansForServer(server: OvaServer, scans: Array<Scan>): Array<Scan> {
    return scans.filter((scan) => {
      const ransomwareScanMatches = this.#isRansomwareScanTarget(
        scan.scanTarget,
        server
      )

      const malwareScanMatches = this.#isMalwareScanTarget(
        scan.scanTarget,
        server
      )

      return ransomwareScanMatches ?? malwareScanMatches
    })
  }

  #mapServerToScans(server: OvaServer): {
    server: OvaServer
    scans: Array<Scan>
  } {
    const scans = this.#filterScansForServer(server, this.#scans)
    return {
      server,
      scans,
    }
  }

  #getServersWithScans(): Array<{
    server: OvaServer
    scans: Array<Scan>
  }> {
    return this.servers.map(this.#mapServerToScans.bind(this))
  }
}
