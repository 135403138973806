import React from 'react'

function ProtectionIcon() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1085 2.01163L10.1085 0.0116284C10.037 -0.00387613 9.963 -0.00387613 9.8915 0.0116284L0.8915 2.01163C0.780385 2.03633 0.681024 2.0982 0.609838 2.18703C0.538653 2.27585 0.499906 2.3863 0.5 2.50013V12.5001C0.5 15.0197 1.50089 17.436 3.28249 19.2176C5.06408 20.9992 7.48044 22.0001 10 22.0001C12.5196 22.0001 14.9359 20.9992 16.7175 19.2176C18.4991 17.436 19.5 15.0197 19.5 12.5001V2.50013C19.5001 2.3863 19.4613 2.27585 19.3902 2.18703C19.319 2.0982 19.2196 2.03633 19.1085 2.01163Z"
        fill="#258AFF"
      />
      <path
        d="M19.082 2.00266L10.082 0.0026604C10.0547 0.000415284 10.0273 0.000415284 10 0.0026604V21.9897C12.5141 21.98 14.9222 20.9752 16.6975 19.1949C18.4728 17.4147 19.4709 15.0038 19.4735 12.4897V2.48966C19.4733 2.37609 19.4344 2.26599 19.3632 2.17747C19.292 2.08896 19.1929 2.02731 19.082 2.00266Z"
        fill="#258AFF"
      />
      <path
        d="M14 10H12.5V7.5C12.5 6.83696 12.2366 6.20107 11.7678 5.73223C11.2989 5.26339 10.663 5 10 5C9.33696 5 8.70107 5.26339 8.23223 5.73223C7.76339 6.20107 7.5 6.83696 7.5 7.5V10H6C5.86739 10 5.74021 10.0527 5.64645 10.1464C5.55268 10.2402 5.5 10.3674 5.5 10.5V15.5C5.5 15.6326 5.55268 15.7598 5.64645 15.8536C5.74021 15.9473 5.86739 16 6 16H14C14.1326 16 14.2598 15.9473 14.3536 15.8536C14.4473 15.7598 14.5 15.6326 14.5 15.5V10.5C14.5 10.3674 14.4473 10.2402 14.3536 10.1464C14.2598 10.0527 14.1326 10 14 10ZM11.5 10H8.5V7.5C8.5 7.10218 8.65804 6.72064 8.93934 6.43934C9.22064 6.15804 9.60218 6 10 6C10.3978 6 10.7794 6.15804 11.0607 6.43934C11.342 6.72064 11.5 7.10218 11.5 7.5V10Z"
        fill="#EBEEF0"
      />
    </svg>
  )
}

export default ProtectionIcon
