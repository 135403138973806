import React, { memo } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import { WidgetHeader } from '@features/DashboardV4/common'
import { StyledCommonStatistic } from './styles'
import { CommonStatistic } from '@features/DashboardV4/widgets-with-scale/coverage-stats/common-statistic'
import { InfoBox } from '@features/DashboardV4/widgets-with-scale'
import { ScaleStatic } from '@features/DashboardV4/widgets-with-scale/coverage-stats/scale-static'
import { RpoStatsWgt } from '@features/DashboardV4/widgets-with-scale/rpo'
import { RetentionStatsWgt } from '@features/DashboardV4/widgets-with-scale/retention'
import { useBackupCoverageStats } from '../useBackupCoverageStats'
import { useFetchAwsRpoAndRetention } from '@features/DashboardV4/views'
import { useFetchStorageReductionMetrics } from '@features/DashboardV4/views/use-fetch-storage-reduction-metrics'

function BackupCoverageSkeleton() {
  const storageReductionInfo = {
    value: '74.9%',
    label: 'Storage reduction',
  }

  const optimizedSizeInfo = {
    value: '107.64 GiB',
    label: 'Optimized size',
  }

  const rows = [
    {
      assets: 2,
      assetsWithBackups: 2,
      label: 'EC2 Instances',
    },
    {
      assets: 21,
      assetsWithBackups: 21,
      label: 'EBS Volumes',
    },
  ]

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexDirection="column" flexBasis="100%">
        <StyledCommonStatistic>
          <Skeleton width="100%">
            <CommonStatistic rows={rows} />
          </Skeleton>
          <Divider flexItem sx={{ my: '24px' }} />
        </StyledCommonStatistic>
        <Box display="flex" justifyContent="space-between">
          <Skeleton width="50%">
            <InfoBox
              mainText={storageReductionInfo.value}
              subText={storageReductionInfo.label}
            />
          </Skeleton>
          <Divider orientation="vertical" flexItem sx={{ mx: '8px' }} />
          <Skeleton width="50%">
            <InfoBox
              mainText={optimizedSizeInfo.value}
              subText={optimizedSizeInfo.label}
            />
          </Skeleton>
        </Box>
      </Box>

      <Box flexBasis="160px">
        <ScaleStatic
          numberOfAssetsWithBackups={0}
          numberOfAssets={0}
          percentOfAssetsWithBackups={0}
        />
      </Box>
    </Box>
  )
}

function RecoveryRightColumn() {
  const {
    isLoading: isLoadingRpoAndRetention,
    retentionStats,
    rpoStats,
  } = useFetchAwsRpoAndRetention()
  const {
    isLoading: isLoadingBackupStats,
    backupCoverageStats,
    numberOfAssets,
    numberOfAssetsWithBackups,
    percentOfAssetsWithBackups,
  } = useBackupCoverageStats()

  const {
    isLoading: isLoadingStorageReduction,
    storageReductionInfo,
    optimizedSizeInfo,
  } = useFetchStorageReductionMetrics()

  return (
    <React.Fragment>
      <WidgetHeader title="Backup Coverage" />
      {isLoadingBackupStats ? (
        <BackupCoverageSkeleton />
      ) : (
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="calc(100% - 160px)"
          >
            <StyledCommonStatistic>
              <CommonStatistic rows={backupCoverageStats} />
              <Divider flexItem sx={{ my: '24px' }} />
            </StyledCommonStatistic>
            <Box display="flex">
              <InfoBox
                mainText={storageReductionInfo.value}
                subText={storageReductionInfo.label}
                isLoading={isLoadingStorageReduction}
              />
              <Divider orientation="vertical" flexItem sx={{ mx: '8px' }} />
              <InfoBox
                mainText={optimizedSizeInfo.value}
                subText={optimizedSizeInfo.label}
                isLoading={isLoadingStorageReduction}
              />
            </Box>
          </Box>

          <Box flexBasis="160px">
            <ScaleStatic
              numberOfAssetsWithBackups={numberOfAssetsWithBackups}
              numberOfAssets={numberOfAssets}
              percentOfAssetsWithBackups={Math.round(
                percentOfAssetsWithBackups
              )}
            />
          </Box>
        </Box>
      )}

      <Divider sx={{ my: 3 }} />
      <RpoStatsWgt isLoading={isLoadingRpoAndRetention} rpoStats={rpoStats} />
      <Divider sx={{ my: 3 }} />
      <RetentionStatsWgt
        isLoading={isLoadingRpoAndRetention}
        retentionStats={retentionStats}
      />
    </React.Fragment>
  )
}

export default memo(RecoveryRightColumn)
