import React, { useEffect } from 'react'
import { useNavigation } from '@lib/router'
import {
  createTenantDomainAndPath,
  loginElastioDomainAndPath,
} from '@features/authentication/use-appInitialization'
import PagePathConstant from '@lib/constants/page-path.constant'
import GlobalLoginComplex from '@components-complex/global-login-complex/GlobalLoginComplex'
import Head from 'next/head'
import TenantCreationGlobalComplex from '@components-complex/tenant-creation-global/TenantCreationComplex'

function HomePage() {
  const router = useNavigation()
  const domain = window.location.hostname.toLowerCase()
  const shouldRenderLoginPage = loginElastioDomainAndPath.domain.test(domain)
  const shouldRenderCreateTenantPage =
    createTenantDomainAndPath.domain.test(domain)

  const shouldRedirectToDashboard =
    !shouldRenderLoginPage && !shouldRenderCreateTenantPage

  const currentPath = router.currentPath

  useEffect(() => {
    if (!shouldRedirectToDashboard) {
      return
    }

    const isRootPath = !currentPath || currentPath === '/'

    if (isRootPath) {
      router.push(PagePathConstant.DASHBOARD)
    }
  }, [shouldRedirectToDashboard])

  if (shouldRenderLoginPage) {
    return (
      <>
        <Head>
          <link rel="shortcut icon" href="/favicon-global.ico" />
        </Head>
        <GlobalLoginComplex />
      </>
    )
  }

  if (shouldRenderCreateTenantPage) {
    return (
      <>
        <Head>
          <link rel="shortcut icon" href="/favicon-global.ico" />
        </Head>
        <TenantCreationGlobalComplex />
      </>
    )
  }

  return <></>
}

export default HomePage
