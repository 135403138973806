/* eslint-disable import/no-extraneous-dependencies */
import { CellWithLink } from '@components/table'
import { PagePathConstant } from '@lib/constants'
import React from 'react'
import { Asset } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { Box } from '@mui/material'

interface Props {
  asset: Asset
}

export function PoliciesCell({ asset }: Props) {
  const policies = asset.backupPolicies
  const joinedPolicies = policies.join(', ')
  if (policies.length === 0) {
    return <Box>Not assigned</Box>
  }

  return (
    <CellWithLink href={PagePathConstant.POLICIES} value={joinedPolicies} />
  )
}
