import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setPossibleVpcList } from '@store/actions/rex.action'

function useInitializeAwsEc2Environment() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPossibleVpcList([]))
  }, [])
}

export { useInitializeAwsEc2Environment }
