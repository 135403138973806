import React, { memo } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import { Box, SvgIconTypeMap } from '@mui/material'
import { EmptyFunc } from '@lib/constants/app.constant'
import { OverridableComponent } from '@mui/material/OverridableComponent'

interface Props {
  title: React.ReactNode
  options: Array<{
    text: React.ReactNode
    onOptionClick?: () => void
  }>
  TitleIcon?: OverridableComponent<SvgIconTypeMap>
  shouldShow?: boolean
}

function ContextMenu({
  title,
  options,
  TitleIcon = CreateOutlinedIcon,
  shouldShow = true,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleOptionClick = (
    e: React.MouseEvent,
    onOptionClick?: () => void
  ) => {
    e.stopPropagation()
    handleMenuClose()

    if (!onOptionClick) {
      return
    }

    onOptionClick()
  }

  if (!options.length) {
    return null
  }

  return (
    <>
      <Box
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="tabMenuButton"
        data-testid="tab-menu-button"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          gap: '8px',
        }}
      >
        {title}
        {shouldShow && (
          <Box
            sx={{
              padding: 0,
              height: 'auto',
              display: 'flex',
            }}
            onClick={handleMenuClick}
            color="inherit"
          >
            <TitleIcon
              sx={{
                fontSize: '16px',
              }}
            />
          </Box>
        )}
      </Box>
      {shouldShow && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
          className="tabMenu"
          data-testid="tab-menu"
        >
          {options.map(({ text, onOptionClick = EmptyFunc }, index) => (
            <MenuItem
              key={index}
              onClick={(event) => handleOptionClick(event, onOptionClick)}
            >
              {text}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default memo(ContextMenu)
