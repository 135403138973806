import PaginationModel from '@lib/models/pagination.model'
import PaginationFactory from '@lib/factories/pagination.factory'
import { useEffect, useState } from 'react'
import { EngineCallback } from '@lib/engine-types'

interface PaginationDataType<T> {
  length: number
  slice: (start: number, end: number) => T
}

interface UseLocalModelPaginationResult<T> {
  paginatedData: T
  pagination: PaginationModel
  setPagination: EngineCallback<PaginationModel>
}

function useLocalModelPagination<T extends PaginationDataType<T>>(
  data: T,
  offset: number,
  limit: number
): UseLocalModelPaginationResult<T> {
  const [pagination, setPagination] = useState<PaginationModel>(
    PaginationFactory.buildEmpty()
  )
  const [dataLength, setDataLength] = useState<number>(0)

  useEffect(() => {
    if (data.length === dataLength) {
      return
    }
    setDataLength(data.length)
    const newPagination = PaginationFactory.build({
      limit,
      offset,
      total: data.length,
    })
    setPagination(newPagination)
  }, [data, offset, limit])

  const paginatedData = data.slice(
    pagination.offset,
    pagination.offset + pagination.limit
  )

  return {
    paginatedData,
    pagination,
    setPagination,
  }
}

export default useLocalModelPagination
