import { SvgIcon, SvgIconProps } from '@mui/material'

const Reports = ({ size = 24, ...props }: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 0,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M13.8 3H6.59999C5.60999 3 4.79999 3.81 4.79999 4.8V19.2C4.79999 20.19 5.60999 21 6.59999 21H17.4C18.39 21 19.2 20.19 19.2 19.2V8.4L13.8 3ZM17.4 19.2H6.59999V4.8H12.9V9.3H17.4V19.2ZM9.29999 12.9V18.3H7.49999V12.9H9.29999ZM14.7 14.7V18.3H16.5V14.7H14.7ZM11.1 11.1V18.3H12.9V11.1H11.1Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default Reports
