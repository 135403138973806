"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listEfsInventoryPossibleFilters = void 0;
class listEfsInventoryPossibleFilters {
    #filesystems;
    constructor(parameters) {
        this.#filesystems = parameters.filesystems;
    }
    execute() {
        const accountIds = new Set();
        const regions = new Set();
        this.#filesystems.forEach((efsItem) => {
            accountIds.add(efsItem.awsAccountId);
            regions.add(efsItem.awsRegion);
        });
        return {
            possibleEfsFilters: {
                accountIds: [...accountIds],
                regions: [...regions],
                covered: [true, false],
                state: [true, false],
            },
        };
    }
}
exports.listEfsInventoryPossibleFilters = listEfsInventoryPossibleFilters;
