/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useState } from 'react'

import {
  DataFetchStatus,
  UseScansByFiltersDataFetcher,
} from '@components-complex/dashboard-pages-v3/hooks/types'
import { usePagination } from '@lib/hooks'
import { SystemHelper } from '@lib/helpers'
import { ListScansByFiltersReqFilterParams } from '@lib/clients/scans/types'
import {
  ListScansByFiltersClient,
  ListScansByFiltersPagination,
  ListScansByFiltersReqFilter,
} from '@lib/clients/scans/list-scans-by-filters'
import { ListScansByFiltersResponse } from '@lib/clients/scans/list-scans-by-filters/list-scans-by-filters-client'
import { ScansRun } from 'blues-corejs/dist/models'

const client = new ListScansByFiltersClient()

export const INITIAL_STATE: ListScansByFiltersResponse = {
  scansRunList: [],
}

interface UseScansByFiltersDataFetcherExtended
  extends UseScansByFiltersDataFetcher {
  fetchPagination: () => Promise<void>
}

export function useScansByFiltersDataFetcher(
  filters?: ListScansByFiltersReqFilterParams
): UseScansByFiltersDataFetcherExtended {
  const [fetchStatus, setFetchStatus] = useState<DataFetchStatus>(
    DataFetchStatus.IDLE
  )

  const [scansData, setScansData] = useState<Array<ScansRun>>([])

  const previousFilters = useRef<
    ListScansByFiltersReqFilterParams | undefined
  >()

  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()

  function updateScansByFiltersList(
    scansByFiltersListResponse: ListScansByFiltersResponse
  ) {
    if (!scansByFiltersListResponse.scansRunList.length) {
      return
    }

    setScansData((prevData) => [
      ...prevData,
      ...scansByFiltersListResponse.scansRunList,
    ])
  }

  function handleFetchSuccess(
    response: ListScansByFiltersResponse,
    nextPageToken?: string
  ) {
    updateScansByFiltersList(response)
    updatePageTokenAndFlagFetched(nextPageToken)
  }

  function isLoadingData() {
    return fetchStatus === DataFetchStatus.IS_LOADING
  }

  function resetFetchStatus() {
    setFetchStatus(DataFetchStatus.IDLE)
  }

  function resetScansByFiltersList() {
    setScansData([])
    resetPagination()
  }

  async function fetchInitialScansByFiltersList(
    combinedFilters: ListScansByFiltersReqFilterParams = {}
  ) {
    return client.listScansByFilters(
      new ListScansByFiltersReqFilter({
        ...combinedFilters,
      })
    )
  }

  async function fetchScansByFiltersListViaPagination(nextPageToken: string) {
    return client.listScansByFilters(
      new ListScansByFiltersPagination(nextPageToken)
    )
  }

  async function fetchScansByFiltersData(
    newFilters?: ListScansByFiltersReqFilterParams
  ) {
    const combinedFilters = {
      ...filters,
      ...newFilters,
    }

    resetScansByFiltersList()

    try {
      if (fetchStatus === DataFetchStatus.IDLE) {
        setFetchStatus(DataFetchStatus.IS_LOADING)

        const response = await fetchInitialScansByFiltersList(combinedFilters)

        previousFilters.current = combinedFilters

        setFetchStatus(DataFetchStatus.SUCCESS)
        handleFetchSuccess(response, response.pageToken)
      }
    } catch (error: any) {
      if ('message' in error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong with fetch scans by filters request - ${error.message}`
        )
      }
    } finally {
      resetFetchStatus()
    }
  }

  async function fetchPagination() {
    if (!pageToken) {
      return
    }

    try {
      setFetchStatus(DataFetchStatus.IS_LOADING)

      const response = await fetchScansByFiltersListViaPagination(pageToken)

      setFetchStatus(DataFetchStatus.SUCCESS)
      handleFetchSuccess(response, response.pageToken)
    } catch (error: any) {
      if ('message' in error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong with fetch scans by filters request - ${error.message}`
        )
      }
    } finally {
      resetFetchStatus()
    }
  }

  return {
    onFetchData: fetchScansByFiltersData,
    data: scansData,
    dataFetchStatus: fetchStatus,
    resetData: resetScansByFiltersList,
    isLoadingData,
    fetchPagination,
  }
}
