import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { ThreatClient, ThreatFilter } from 'ui-v2/src/lib/clients/threat'

export function useListThreatAssetQuery(threatFilter: ThreatFilter) {
  const threatClient = useGrpcClient(ThreatClient)

  return useQuery({
    queryKey: ['threat', 'list-asset', threatFilter],
    queryFn: async () => {
      if (!threatClient) {
        throw new Error('Threat client not initialized')
      }
      return threatClient.listThreatsForAsset(threatFilter)
    },
    enabled: !!threatClient,
  })
}
