import React from 'react'
import { Box } from '@mui/material'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

export function FinishedJobCell({ finishedAt }: { finishedAt: Date }) {
  return (
    <Box minWidth="150px">
      <DateTimeTooltip direction="row" date={finishedAt} />
    </Box>
  )
}
