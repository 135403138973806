import {
  getLastBackup,
  TabsIds,
  useAssetStore,
} from '@features/asset-page/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Backup, EBS, EC2, OvaServer } from 'blues-corejs/dist'
import Tab from '@mui/material/Tab'
import React from 'react'
import { BadgeStatusColor, StyledCircleIcon } from './shared'

function getBadgeStatusColorForBackupsTab(
  latestBackup?: Backup
): BadgeStatusColor {
  if (!latestBackup) {
    return BadgeStatusColor.UNCHECKED
  }

  if (latestBackup.hasBadSummaryCheckStatus) {
    return BadgeStatusColor.BAD
  }

  if (latestBackup.hasGoodSummaryCheckStatus) {
    return BadgeStatusColor.GOOD
  }

  return BadgeStatusColor.UNCHECKED
}

function useBackupTabVisibility() {
  const { lastBackup, asset } = useAssetStore((state) => ({
    lastBackup: state.lastBackup,
    asset: state.asset,
  }))

  const isEligibleForBackup =
    asset instanceof EC2 || asset instanceof EBS || asset instanceof OvaServer

  return isEligibleForBackup && Boolean(lastBackup)
}
export default function BackupsTab({
  onTabChange,
}: {
  onTabChange: (tabIndex: TabsIds) => void
}) {
  const lastBackup = useAssetStore(getLastBackup)

  const color = getBadgeStatusColorForBackupsTab(lastBackup)

  const shouldHideBackupTab = !useBackupTabVisibility()
  function handleTabChange() {
    onTabChange(TabsIds.RECOVERY_POINTS)
  }

  return (
    <Tab
      label="Recovery points"
      hidden={shouldHideBackupTab}
      value={TabsIds.RECOVERY_POINTS}
      onClick={handleTabChange}
      iconPosition="end"
      icon={
        <StyledCircleIcon
          sx={{
            color,
          }}
        />
      }
    />
  )
}
