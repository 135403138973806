/* eslint-disable import/no-extraneous-dependencies */
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { S3 as S3Proto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/assets/aws/s3_pb'
import { S3Bucket } from 'blues-corejs/dist'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'

type S3Pt = S3Proto.AsObject

export class S3BucketTransformer {
  #bucket: S3Pt

  constructor(bucket: S3Pt) {
    this.#bucket = bucket
  }

  transform(): S3Bucket {
    return this.#transformBucket(this.#bucket)
  }

  #transformBucket(bucket: S3Pt): S3Bucket {
    return new S3Bucket({
      arn: bucket.arn,
      awsAccountId: bucket.awsAccountId,
      awsId: bucket.awsId,
      awsRegion: bucket.awsRegion,
      backupPolicies: bucket.backupPoliciesList,
      createdAt: secondsToDate(bucket.createdAt?.seconds),
      id: bucket.id,
      objectCount: bucket.objectCount,
      retentionPolicy: bucket.retentionPoliciesList,
      size: bucket.size,
      state: this.#convertS3BucketState(bucket.state),
      tags: bucket.tagsList,
      updatedAt: secondsToDate(bucket.updatedAt?.seconds),
    })
  }

  #convertS3BucketState(state: S3Proto.State): S3BucketState {
    const mapping = {
      [S3Proto.State.STATE_DELETED]: S3BucketState.DELETED,
      [S3Proto.State.STATE_IN_USE]: S3BucketState.IN_USE,
      [S3Proto.State.STATE_UNKNOWN]: S3BucketState.UNKNOWN,
    }

    return mapping[state]
  }
}
