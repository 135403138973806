/* eslint-disable import/no-extraneous-dependencies */
import { JobTenant } from 'blues-corejs/dist/models'
import { SystemFiltersState } from '../filters-store'

export interface SystemJobsState {
  systemJobsList: Array<JobTenant>
  pageToken?: string
  loadingState: 'idle' | 'loading' | 'success'
}

export interface SystemJobsActions {
  resetSystemJobsState: () => void
  setSystemJobsList: (systemJobsList: Array<JobTenant>) => void
  fetchInitialSystemJobs: (filters?: Partial<SystemFiltersState>) => void
  fetchPaginatedSystemJobs: () => Promise<void>
}

export type SystemJobsStore = SystemJobsState & {
  actions: SystemJobsActions
}

export { fetchInitialJobsList, fetchJobsListViaPagination } from './actions'
export {
  useSystemJobsIsLoading,
  useSystemJobsList,
  useSystemJobsStoreActions,
} from './selectors'
