/* eslint-disable import/no-extraneous-dependencies */
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'

export const AND = TagsOperator.AND
export const OR = TagsOperator.OR

export const AND_LABEL = 'AND'
export const OR_LABEL = 'OR'

export const tagsOperatorOptions = [
  {
    value: AND,
    label: AND_LABEL,
  },
  {
    value: OR,
    label: OR_LABEL,
  },
]
