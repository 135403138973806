import React, { useState, useMemo, useCallback } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FilesystemScanCheck,
  ListDetailsDetectedThreats,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import type { SelectChangeEvent } from '@mui/material/Select'
import { styled } from '@mui/material/styles'
import { ThreatsFilter } from '../threats-filter'
import { Table } from '@components/table'
import { getDetectedThreatsTableColumnDefinition } from './columns-definition'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Threat } from 'blues-corejs/dist/models/threats'
import { ExportAsCsv } from '@features/common'
import {
  prepareThreatsDataForCsv,
  THREATS_CSV_TABLE_HEADERS,
} from './prepare-data-for-csv'
import Stack from '@mui/material/Stack'
import { EngineCallback } from '@lib/engine-types'
import ThreatSuppressionModal from '@features/threat-suppression/threat-suppression-modal'

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  background: theme.palette.grey[50],
  borderRadius: '12px',
}))

function Header({ title }: { title: string }) {
  return (
    <StyledHeader>
      <Typography fontWeight="600">{title}</Typography>
    </StyledHeader>
  )
}

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type Props = {
  threats: Array<Threat>
  scans: Array<Scan>
  fetchDataThreats: EngineCallback<void>
}

const computeNoDataMessageForTable = (
  threatsSize: number,
  scansSize: number
): string => {
  if (threatsSize === 0 && scansSize > 0) {
    return 'No Threats found'
  }

  if (threatsSize === 0 && scansSize === 0) {
    return 'No scans performed'
  }

  return ''
}
type FilterState = Record<string, Array<string> | undefined>

const ThreatsList = ({ threats, scans, fetchDataThreats }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterState>({})

  const { threatsData, possibleFilters } = useMemo(
    () => new ListDetailsDetectedThreats(threats).execute(selectedFilter),
    [threats, selectedFilter]
  )

  const handleSelectFilter = useCallback(
    (event: SelectChangeEvent<Array<string>>, key: string) => {
      const value: Array<string> = event.target.value as Array<string>

      setSelectedFilter((prevFilters) => ({
        ...prevFilters,
        [key]: value.length > 0 ? value : undefined,
      }))
    },
    []
  )

  return (
    <Box marginTop="24px">
      <Header title="Detected Threats" />

      <Stack marginY={2} direction="row" alignItems="center">
        <ThreatsFilter
          possibleFilter={possibleFilters}
          selectedFilters={selectedFilter}
          onSelectFilter={handleSelectFilter}
        />
        <ExportAsCsv
          shouldShow={threatsData.length > 0}
          filename="detected-threats.csv"
          headers={THREATS_CSV_TABLE_HEADERS}
          data={prepareThreatsDataForCsv(threatsData)}
          marginBottom={0}
        />
      </Stack>
      <Table
        data={threatsData}
        columns={getDetectedThreatsTableColumnDefinition()}
        generalProps={{
          isLoading: false,
          noDataMessage: computeNoDataMessageForTable(
            threats.length,
            scans.length
          ),
        }}
      />
      <ThreatSuppressionModal fetchDataThreats={fetchDataThreats} />
    </Box>
  )
}

export default ThreatsList
