import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import MultiSelectChip from 'ui-v2/src/components/ui/inputs/multi-select-chip'

const OvaAccount = ({
  value,
  error,
  helperText,
  onChange,
}: OvaAccountProps) => {
  const { data } = useListOvaAccountsQuery()
  const options = data?.map((account) => ({
    label: account.name,
    value: account.id,
  }))

  return (
    <MultiSelectChip
      options={options ?? []}
      selectedOptions={value}
      size="small"
      placeholder="Select"
      setSelectedOptions={onChange}
      error={error}
      helperText={helperText}
    />
  )
}
interface OvaAccountProps {
  value: Array<string>
  error?: boolean
  helperText?: string
  onChange: (options: Array<string>) => void
}
export default OvaAccount
