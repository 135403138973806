import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { WebhooksClient } from 'ui-v2/src/lib/clients'
import { TestWebhookFilters } from 'ui-v2/src/lib/models/webhooks'
import { useMutation } from '@tanstack/react-query'

export function useTestWebhookMutation() {
  const webhooksClient = useGrpcClient(WebhooksClient)
  const toast = useToast()

  return useMutation({
    mutationFn: async (filters: TestWebhookFilters) => {
      if (!webhooksClient) {
        throw new Error('Webhooks client not initialized')
      }
      return webhooksClient.testWebhook(filters)
    },
    onError: (error) => {
      toast.error(error.message ?? 'Something went wrong while testing webhook')
    },
    onSuccess: (data) => {
      if (data.success) {
        toast.success('Webhook test is successful')
      } else {
        toast.error('Webhook test failed')
      }
    },
  })
}
