/* eslint-disable import/no-extraneous-dependencies */
import { EC2_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import {
  Backup,
  BackupPoliciesArray,
  EBS,
  EC2,
  Nullable,
  Threat,
} from 'blues-corejs/dist'
import { PolicyCoverageState } from '../common'
import { Scan } from '../../scans'
import { ListEc2Request } from 'ui-v2/src/lib/grpc'
import { BackupForAsset } from '../../backups'

export interface ListEc2Filters {
  assetIdList?: Array<string>
  accountIdsList?: Array<string>
  regionsList?: Array<string>
  instanceStatesList?: Array<ListEc2Request.Initial.Filter.InstanceState>
  protectionPolicyCoverageStatesList?: Array<PolicyCoverageState>
  search?: string
  pageSize?: number
  pageToken?: string
}

export interface AssetsEc2TableDataAttrs {
  instances: Array<EC2>
  volumes: Array<EBS>
  threats: Array<Threat>
  lastCleanBackups: Record<string, BackupForAsset>
}

export interface AssetsEc2DrawerDataAttrs {
  instances: Array<EC2>
  volumes: Array<EBS>
  threats: Array<Threat>
  lastBackups: Map<string, Backup>
  scans: Array<Scan>
}

interface BaseEc2Attrs {
  id: string
  name: string
  awsId: string
  state: (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP]
  isUnhealthy: boolean
}

export abstract class BaseEc2 {
  readonly #id: string

  readonly #name: string

  readonly #awsId: string

  readonly #state: (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP]

  readonly #isUnhealthy: boolean

  constructor(parameters: BaseEc2Attrs) {
    const { id, name, awsId, state, isUnhealthy } = parameters

    this.#id = id
    this.#name = name
    this.#awsId = awsId
    this.#state = state
    this.#isUnhealthy = isUnhealthy
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get awsId(): string {
    return this.#awsId
  }

  get state(): (typeof EC2_STATE_MAP)[keyof typeof EC2_STATE_MAP] {
    return this.#state
  }

  get isUnhealthy(): boolean {
    return this.#isUnhealthy
  }
}

export class Ec2TableRow extends BaseEc2 {
  readonly #backupPolicies: BackupPoliciesArray

  readonly #lastKnownCleanBackup: Date | undefined

  readonly #createdAt: Date

  constructor(parameters: Ec2TableRowAttrs) {
    const { backupPolicies, lastKnownCleanBackup, createdAt, ...baseParams } =
      parameters
    super(baseParams)

    this.#backupPolicies = backupPolicies
    this.#lastKnownCleanBackup = lastKnownCleanBackup
    this.#createdAt = createdAt
  }

  get backupPolicies(): BackupPoliciesArray {
    return this.#backupPolicies
  }

  get lastKnownCleanBackup(): Date | undefined {
    return this.#lastKnownCleanBackup
  }

  get createdAt(): Date {
    return this.#createdAt
  }
}

export class Ec2Drawer extends BaseEc2 {
  readonly #awsAccountId: string

  readonly #awsRegion: string

  readonly #lastScan: Nullable<Scan>

  readonly #lastBackup: Nullable<Backup>

  readonly assetKind: string = 'EC2 Instance'

  constructor(parameters: Ec2DrawerAttrs) {
    const { awsAccountId, awsRegion, lastScan, lastBackup, ...baseParams } =
      parameters
    super(baseParams)

    this.#awsAccountId = awsAccountId
    this.#awsRegion = awsRegion
    this.#lastScan = lastScan
    this.#lastBackup = lastBackup
  }

  get awsAccountId(): string {
    return this.#awsAccountId
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get lastScan(): Nullable<Scan> {
    return this.#lastScan
  }

  get lastBackup(): Nullable<Backup> {
    return this.#lastBackup
  }
}

interface Ec2TableRowAttrs extends BaseEc2Attrs {
  backupPolicies: BackupPoliciesArray
  lastKnownCleanBackup?: Date | undefined
  createdAt: Date
}

interface Ec2DrawerAttrs extends BaseEc2Attrs {
  awsAccountId: string
  awsRegion: string
  lastScan: Nullable<Scan>
  lastBackup: Nullable<Backup>
}
