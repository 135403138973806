import { create } from 'zustand'
import { SystemHelper } from '@lib/helpers'
import {
  fetchAssetsByIds,
  fetchListCloudConnectorJobsFilter,
  fetchListCloudConnectorJobsPagination,
} from './actions'
import { getInitialState } from './consts'
import type { AssetJobsStoreState } from './types'
import { useShallow } from 'zustand/react/shallow'
import { getAssetIdsFromJobs } from './utils'
import { DateRangeTuple } from '@lib/engine-types'
import { ListCloudConnnectorJobsFilterRequestParams } from '@lib/clients/jobs'

const useAssetJobsStore = create<AssetJobsStoreState>((set, get) => ({
  ...getInitialState(),
  actions: {
    resetAssetJobsStore: () => {
      set(getInitialState())
    },
    setJobsList: (jobsList) => {
      set({
        jobsList,
      })
    },
    fetchInitialJobsList: async (
      filters?: ListCloudConnnectorJobsFilterRequestParams
    ) => {
      const { filters: filtersState, actions } = get()

      set({
        loadingState: 'loading',
      })

      try {
        const filtersForRequest = {
          ...filtersState,
          ...filters,
        }

        const { jobsList, pageToken } = await fetchListCloudConnectorJobsFilter(
          {
            filters: filtersForRequest,
          }
        )

        const assetIdsFromJobs = getAssetIdsFromJobs(jobsList)

        await actions.fetchAssetsByIds(assetIdsFromJobs)

        set({
          jobsList,
          pageToken,
          filters: filtersForRequest,
        })
      } catch (error) {
        console.error(error)
        SystemHelper.sendObjectToSentryIfProd(error)
      } finally {
        set({
          loadingState: 'idle',
        })
      }
    },
    fetchAssetsByIds: async (assetIds: Array<string>) => {
      const { assetsMap } = get()

      if (!assetIds.length) {
        return
      }

      const assetIdsThatAreNotInMap = assetIds.filter(
        (assetId) => !assetsMap.has(assetId) && assetId
      )

      const newAssetsMap = await fetchAssetsByIds(assetIdsThatAreNotInMap)

      set((state) => ({
        assetsMap: new Map([...state.assetsMap, ...newAssetsMap]),
      }))
    },
    fetchPaginatedJobsList: async () => {
      const { pageToken, actions } = get()
      if (!pageToken) {
        return
      }

      set({
        loadingState: 'loading',
      })

      try {
        const { jobsList: newJobsList, pageToken: newPageToken } =
          await fetchListCloudConnectorJobsPagination(pageToken)

        const assetIdsFromJobs = getAssetIdsFromJobs(newJobsList)

        actions.fetchAssetsByIds(assetIdsFromJobs)

        set((state) => ({
          jobsList: [...state.jobsList, ...newJobsList],
          pageToken: newPageToken,
        }))
      } catch (error) {
        console.error(error)
        SystemHelper.sendObjectToSentryIfProd(error)
      } finally {
        set({
          loadingState: 'idle',
        })
      }
    },
  },
}))

export const useAssetsMap = () =>
  useAssetJobsStore(useShallow((state) => state.assetsMap))

export const useJobsList = () =>
  useAssetJobsStore(useShallow((state) => state.jobsList))

export const useInProgressJobsList = () =>
  useJobsList().filter((job) => job.isInProgress)

export const useIsLoadingJobs = () =>
  useAssetJobsStore((state) => state.loadingState === 'loading')

export const useAssetJobsStoreActions = () =>
  useAssetJobsStore((state) => state.actions)

export const useTimeRange = () =>
  useAssetJobsStore(
    useShallow((state) => {
      const timeRange = state.filters.timeRange

      const timeRangeToDisplay: DateRangeTuple = [
        (timeRange?.start || 0) * 1000,
        (timeRange?.end || 0) * 1000,
      ]

      return {
        timeRange,
        timeRangeToDisplay,
      }
    })
  )
