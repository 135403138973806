/* eslint-disable import/no-extraneous-dependencies */
// @ts-nocheck
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import { Scan } from '../models/scans'

export function computeCleanFilesCount(scan: Scan) {
  switch (true) {
    case scan instanceof MalwareScan:
      return {
        label: 'Clean Files',
        value: scan.cleanFilesCount.toString(),
      }
    case scan instanceof RansomwareScan:
      return {
        label: 'Scanned Files',
        value: scan.scannedFilesCount.toString(),
      }
    case scan instanceof FilesystemScanCheck:
      return {
        label: 'Healthy Partition',
        value: scan.healthyPartitionsCount,
      }
    default:
      return {
        label: 'Clean Files',
        value: '',
      }
  }
}

export function computeInfectedFilesCount(scan: Scan) {
  switch (true) {
    case scan instanceof MalwareScan:
      return {
        label: 'Infected Files',
        value: scan.infectedFilesCount,
      }
    case scan instanceof RansomwareScan:
      return {
        label: 'Infected Files',
        value: scan.infectedFilesCount,
      }
    case scan instanceof FilesystemScanCheck:
      return {
        label: 'Corrupted Partition',
        value: scan.corruptedPartitionsCount,
      }
    default:
      return {
        label: 'Clean Files',
        value: '',
      }
  }
}
