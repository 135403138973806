import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserProfile } from 'blues-corejs/dist/models'

interface UsersListActions {
  setActiveUserIds: (data: Array<string>) => void
  setUsers: (data: Array<UserProfile>) => void
}

export interface UsersListStore {
  activeUserIds: Array<string>
  users: Array<UserProfile>
  recipients: Array<{ value: string; label: string }>
}

const INITIAL_STATE: UsersListStore = {
  activeUserIds: [],
  recipients: [],
  users: [],
}

const useUsersListStore = create<
  UsersListStore & { actions: UsersListActions }
>((set) => ({
  ...INITIAL_STATE,
  actions: {
    setActiveUserIds: (data: Array<string>) =>
      set(() => ({
        activeUserIds: data,
      })),
    setUsers: (data: Array<UserProfile>) =>
      set(() => ({
        users: data,
        recipients: data.map((user) => ({
          value: user.id,
          label: user.email,
        })),
      })),
  },
}))

export const useRecipients = () =>
  useUsersListStore(useShallow((state) => state.recipients))

export const useRecipientsByIds = (ids: string | Array<string>) => {
  const recipients = useRecipients()

  const idArray = Array.isArray(ids) ? ids : [ids]

  return recipients.filter((recipient) => idArray.includes(recipient.value))
}

export const useActiveUserIds = () =>
  useUsersListStore(useShallow((state) => state.activeUserIds))

export const useUsersListActions = () =>
  useUsersListStore((state) => state.actions)
