import { useMutation } from '@tanstack/react-query'
import { UpdateReportScheduleCriteria } from 'ui-v2/src/lib/clients/reports/schedule/update-report-schedule'
import { ReportSchedulesClient } from 'ui-v2/src/lib/clients/reports/report-schedules-client'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useUpdateReportSchedule() {
  const reportScheduleClient = useGrpcClient(ReportSchedulesClient)

  return useMutation({
    mutationFn: async (data: UpdateReportScheduleCriteria) => {
      if (!reportScheduleClient) {
        throw new Error('Report schedule client not initialized')
      }
      return reportScheduleClient.updateReportSchedule(data)
    },
  })
}
