/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { WidgetHeader } from '@features/ova-dashboard/widgets/shared'
import { Container } from './styles'
import { Box } from '@mui/material'
import { RansomwareFound, MalwareFound } from './components'
import { useFetchOvaThreats } from './use-fetch-ova-threats'

/**
 * Threats widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19346-167892&mode=design&t=tQScFrA9cvNE4u4P-4)
 */
function ThreatsWidget() {
  const { isLoading, malwareCount, ransomwareCount } = useFetchOvaThreats()

  return (
    <Container>
      <WidgetHeader widgetTitle="Threats" />
      <Box
        display="flex"
        flexDirection="column"
        marginTop="10px"
        gap="18px"
        paddingX="16px"
      >
        <RansomwareFound
          isLoading={isLoading}
          ransomwareCount={ransomwareCount}
        />
        <MalwareFound isLoading={isLoading} malwareCount={malwareCount} />
      </Box>
    </Container>
  )
}

export default ThreatsWidget
