import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid2,
  Stack,
} from '@mui/material'
import Label from '../../../../components/ui/data-display/label'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useUpdateMfaMutation } from 'ui-v2/src/hooks/queries/users/update-mfa'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'

const AccountSecurity = () => {
  const { getIdTokenClaims, logout } = useAuth0()
  const { mutate } = useUpdateMfaMutation()

  const [isEnabled, setIsEnabled] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const handleClick = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleConfirmDialog = async () => {
    setIsEnabled(false)
    mutate(false)
    await logout({
      logoutParams: { returnTo: window.location.origin },
    })
  }

  const handleMFAChange = async () => {
    setIsEnabled(!isEnabled)
    mutate(!isEnabled)
    await logout({
      logoutParams: { returnTo: window.location.origin },
    })
  }

  useEffect(() => {
    const initializeClient = async () => {
      try {
        const claims = await getIdTokenClaims()
        if (claims && claims.amr && Array.isArray(claims.amr)) {
          if (claims.amr.includes('mfa')) {
            setIsEnabled(true)
          } else {
            setIsEnabled(false)
          }
        } else {
          setIsEnabled(false)
        }
      } catch (error) {
        console.error('Failed to initialize assets client', error)
      }
    }
    initializeClient().catch(console.error)
  }, [])

  return (
    <Stack spacing={2.5} sx={{ paddingBottom: 2.5 }} maxWidth={824}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl>
            <Label>Multi-Factor Authentication:</Label>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={isEnabled} onChange={handleMFAChange} />
                }
                label="MFA Enabled"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgba(211, 216, 222, 1)'
                      : '#1A1F28',
                })}
              />
            </FormGroup>
          </FormControl>
        </Grid2>
        <Grid2>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleClick}
          >
            Reset MFA
          </Button>
          <ConfirmationDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDialog}
            title="Confirmation"
            confirmText="Yes,Proceed"
            cancelText="No,Cancel"
          >
            You are going to reset MFA, please confirm the action in order to
            proceed
          </ConfirmationDialog>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default AccountSecurity
