import React, { memo, useState } from 'react'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import clsx from 'clsx'
import { VINumberMap, VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import CopyRow from '@components-simple/copy-row/CopyRow'

const recoveryPointInnerDraw = ({ name, label }: ValueInterface) => (
  <>
    <div className="mbLabel jsBlockLabel">{name}</div>
    <div className="mbValue jsBlockValue">
      {label === '-' ? (
        <>{label}</>
      ) : (
        <CopyRow text={String(label)} variant="unstyled" />
      )}
    </div>
  </>
)

// this draw mapper function is here because it fully related only for
// this component and can not be used in any other contexts
export const recoveryPointDrawMapper = (): VINumberMap => ({
  0: recoveryPointInnerDraw,
  2: recoveryPointInnerDraw,
})

interface Props {
  data: VIRow
  rows?: number
  columns?: number
  opened?: boolean
  drawMapper?: VINumberMap
  isButtonShow?: boolean
  className?: string
}

function MoreBlock({
  data,
  rows = 2,
  columns = 3,
  opened = true,
  drawMapper = {},
  isButtonShow = true,
  className = '',
}: Props) {
  const [innerOpened, setInnerOpened] = useState<boolean>(opened)

  const MAX_INDEX = rows * columns
  const toggleOpened = () => setInnerOpened(!innerOpened)

  return (
    <div className={`wrap-1620748129686 ${className}`}>
      <div
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
        className="mbContent"
      >
        {data.map((elem, index) => (
          <div
            key={elem.name}
            className={clsx('mbBlock jsBlock', {
              dNone: !innerOpened && index >= MAX_INDEX,
            })}
          >
            {drawMapper[index] ? (
              drawMapper[index]?.(elem)
            ) : (
              <>
                <div className="mbLabel jsBlockLabel">{elem.name}</div>
                <div className="mbValue jsBlockValue">{elem.label}</div>
              </>
            )}
          </div>
        ))}
      </div>

      {isButtonShow && (
        <div className="mbBtnWrap">
          <button
            className={clsx('blueLabelBtn jsLoadBtn', { dNone: innerOpened })}
            type="button"
            onClick={toggleOpened}
          >
            Load more
            <SelectArrowIcon />
          </button>
          <button
            className={clsx('blueLabelBtn blbSvgRotated jsHideBtn', {
              dNone: !innerOpened,
            })}
            type="button"
            onClick={toggleOpened}
          >
            Hide
            <SelectArrowIcon />
          </button>
        </div>
      )}
    </div>
  )
}

export default memo(MoreBlock)
