import { Typography } from '@mui/material'
import DoughnutChart from '../dashboard-charts/doughnut-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useListAnomaliesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'

export const EncryptionIntegrity = () => {
  const { data: anomaliesData } = useListAnomaliesQuery({
    startAt: Math.floor(
      dayjs().startOf('day').subtract(30, 'day').valueOf() / 1000
    ),
    endAt: Math.floor(dayjs().endOf('day').valueOf() / 1000),
    pageSize: 100,
  })

  const { data: assetsLiveData } = useAssetsLiveQuery()

  const chartData = useMemo(() => {
    if (!assetsLiveData || !anomaliesData) {
      return {
        totalCount: 0,
        data: [
          {
            name: 'EBS Volumes 0',
            value: 0,
            itemStyle: { color: '#FF1654' },
          },
          {
            name: 'S3 Buckets 0',
            value: 0,
            itemStyle: { color: '#E28800' },
          },
        ],
      }
    }

    const assetsWithAnomalies = new Set<string>()
    anomaliesData.anomaliesList.forEach((anomaly) => {
      const assetId =
        anomaly.source.getAsset()?.getId() ??
        anomaly.source.getAssetItem()?.getAssetId()

      if (assetId) {
        assetsWithAnomalies.add(assetId)
      }
    })

    const volumes = assetsLiveData.ebsVolumes
    const buckets = assetsLiveData.s3Buckets

    const affectedVolumesCount = volumes.filter((volume) =>
      assetsWithAnomalies.has(volume.id)
    ).length

    const affectedBucketsCount = buckets.filter((bucket) =>
      assetsWithAnomalies.has(bucket.id)
    ).length

    const totalCount = affectedVolumesCount + affectedBucketsCount
    const ebsPercentage =
      Math.round((affectedVolumesCount / totalCount) * 100) || 0
    const s3Percentage =
      Math.round((affectedBucketsCount / totalCount) * 100) || 0

    return {
      totalCount,
      data: [
        {
          name: `EBS Volumes  {bold|${affectedVolumesCount}/${ebsPercentage}%}`,
          value: ebsPercentage,
          itemStyle: { color: '#FF1654' },
        },
        {
          name: `S3 Buckets  {bold|${affectedBucketsCount}/${s3Percentage}%}`,
          value: s3Percentage,
          itemStyle: { color: '#E28800' },
        },
      ],
    }
  }, [anomaliesData, assetsLiveData])

  const onChartClick = (componentIndex: number) => {
    switch (componentIndex) {
      case 0:
        //  TODO: link to encryption activity page if any
        break
      case 1:
        //  TODO: link to encryption activity page if any
        break
      default:
    }
  }

  return (
    <GradientCard>
      <Typography fontSize={16} fontWeight={700} mb={1} color="text.secondary">
        Encryption Activity
      </Typography>
      <DoughnutChart
        title={'Assets with\nEncryption\nIntegrity'}
        count={chartData.totalCount ?? 0}
        data={chartData.data}
        onClick={onChartClick}
        graphicPosition={{
          top: '45%',
          left: '20%',
        }}
      />
    </GradientCard>
  )
}

export default EncryptionIntegrity
