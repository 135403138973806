import React from 'react'

import {
  CellWithDateTimeTooltip,
  CellWithLink,
  createColumn,
  StatusCircle,
  CellLongTextWithTooltip,
  CopyCell,
} from '@components/table'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  EC2,
  ElastioRecoveryPoint,
  Threat,
  VolumeThreatsOutput,
} from 'blues-corejs/dist'
import LangHelper from '@lib/helpers/lang.helper'

import { ColumnWidth, createDisplayColumn } from '@components/table'
// eslint-disable-next-line import/no-extraneous-dependencies
import { BackupVariant } from 'blues-corejs/dist/use_cases/list_volumes_with_threats/types'
import { Box } from '@mui/material'
import ElastioLogoBlueIcon from '@inline-img/general/elastio-blue-icon'
import ChartAwsIcon from '@inline-img/general/chart/chart-aws-icon'
import { Nullable } from '@lib/engine-types'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { ASSETS_THREATS_TABLE_MIN_HEIGHT } from './consts'
import { AssetRoutes } from '@lib/router'

const EMPTY_INSTANCE_VALUE = '- -'

type InputData = {
  aliasNamesWithId: AliasName
}

function getStatusDummyColumn() {
  return createDisplayColumn<VolumeThreatsOutput>({
    id: 'ebsStatus',
    cell: () => <StatusCircle isHealthy={false} />,
  })
}

function getVolumeIdColumn() {
  return createColumn<VolumeThreatsOutput>('volumeId', {
    header: 'Volume',
    meta: {
      height: ASSETS_THREATS_TABLE_MIN_HEIGHT,
    },
    cell: ({ getValue, row }) => {
      const volumeId = getValue() as unknown as string
      const assetPageUrl = AssetRoutes.assetId(volumeId)
      const { volumeName, volumeAwsId } = row.original

      const displayedValue = volumeName || volumeAwsId
      const displayedLabel = volumeName ? volumeAwsId : volumeName
      return (
        <CellWithLink
          href={assetPageUrl}
          value={displayedValue}
          label={displayedLabel}
        />
      )
    },
  })
}

function getVolumeThreatsColumn() {
  return createColumn<VolumeThreatsOutput>('volumeThreats', {
    header: 'Threats',
    cell: ({ getValue }) => {
      const threats = getValue() as unknown as Array<Threat>
      const threatNames: Set<string> = new Set()
      threats.map((threat) => {
        threatNames.add(threat.name)
      })

      return (
        <CellLongTextWithTooltip
          title={[...threatNames].join(', ')}
          maxLength={60}
        />
      )
    },
    meta: {
      size: ColumnWidth.LARGE,
    },
  })
}

function getAccountIdColumn(aliasNamesWithId: AliasName) {
  return createColumn<VolumeThreatsOutput>('accountId', {
    header: 'Account',
    cell: ({ getValue }) => {
      const className = aliasNamesWithId[getValue()] ? 'copyRow' : ''

      return (
        <CopyCell
          additionalText={aliasNamesWithId[getValue()]}
          copyText={getValue()}
          className={className}
        />
      )
    },
  })
}

function getRegionColumn() {
  return createColumn<VolumeThreatsOutput>('region', {
    header: 'Region',
    cell: ({ getValue }) => {
      return <>{LangHelper.getAwsRegionSingleTranslation(getValue())}</>
    },
  })
}

function getAttachedInstancesListColumn() {
  return createColumn<VolumeThreatsOutput>('attachedInstancesList', {
    header: 'Instance attached',
    cell: ({ getValue }) => {
      const instances = getValue() as unknown as Array<EC2>

      let instancesIds = instances.map((instance) => instance.awsId)

      if (instancesIds.length === 0) {
        instancesIds = [EMPTY_INSTANCE_VALUE]
      }

      return (
        <>
          {instancesIds.map((id: string) => {
            return (
              <CellLongTextWithTooltip key={id} title={id} maxLength={40} />
            )
          })}
        </>
      )
    },
  })
}

function getIsAttachedColumn() {
  return createColumn<VolumeThreatsOutput>('isAttached', {
    header: 'Status',
    cell: ({ getValue }) => {
      const isAttached = getValue() as unknown as boolean

      return <>{isAttached ? 'Attached' : 'Unattached'}</>
    },
  })
}

function getLastScanColumn() {
  return createColumn<VolumeThreatsOutput>('lastScan', {
    header: 'Last scan',
    cell: ({ getValue }) => {
      const lastScan = getValue() as unknown as Nullable<Date>

      return <CellWithDateTimeTooltip date={lastScan} />
    },
  })
}

function getLastBackupColumn() {
  return createColumn<VolumeThreatsOutput>('lastBackup', {
    header: 'Last RP',
    cell: ({ getValue }) => {
      const lastBackup = getValue() as unknown as BackupVariant

      if (!lastBackup) {
        return null
      }

      const lastBackupElastio = lastBackup instanceof ElastioRecoveryPoint
      const date = lastBackup.backup.timestamp

      return (
        <Box display="flex" gap="8px" alignItems="center">
          <CellWithDateTimeTooltip date={date} />
          {lastBackupElastio ? <ElastioLogoBlueIcon /> : <ChartAwsIcon />}
        </Box>
      )
    },
  })
}

export class VolumeColumns {
  static getColumns({ aliasNamesWithId }: InputData) {
    return [
      getStatusDummyColumn(),
      getVolumeIdColumn(),
      getVolumeThreatsColumn(),
      getAccountIdColumn(aliasNamesWithId),
      getRegionColumn(),
      getAttachedInstancesListColumn(),
      getIsAttachedColumn(),
      getLastScanColumn(),
      getLastBackupColumn(),
    ]
  }
}
