import { useQuery } from '@tanstack/react-query'
import { UsersClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useUserConfigQuery() {
  const usersClient = useGrpcClient(UsersClient)

  return useQuery({
    queryKey: ['users', 'userConfig'],
    queryFn: () => {
      if (!usersClient) {
        throw new Error('Users client not initialized')
      }
      return usersClient.getUserConfig()
    },
    enabled: !!usersClient,
  })
}
