import { useEffect, SyntheticEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Overview from './overview'
import Schedules from './schedules'
import History from './history'
import { REPORTS_TAB_SLUG_MAP } from 'ui-v2/src/lib/constants/reports.constant'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}
const TAB_MAP = {
  overview: {
    label: 'Overview',
    component: <Overview />,
    slug: REPORTS_TAB_SLUG_MAP.OVERVIEW,
  },
  schedules: {
    label: 'Schedules',
    component: <Schedules />,
    slug: REPORTS_TAB_SLUG_MAP.SCHEDULES,
  },
  history: {
    label: 'History',
    component: <History />,
    slug: REPORTS_TAB_SLUG_MAP.HISTORY,
  },
}
export default function ReportsTabs() {
  const navigate = useNavigate()
  const { tab } = useParams<{
    tab: (typeof REPORTS_TAB_SLUG_MAP)[keyof typeof REPORTS_TAB_SLUG_MAP]
  }>()

  const defaultPath = `/reports/${REPORTS_TAB_SLUG_MAP.OVERVIEW}`

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(TAB_MAP).some((t) => t.slug === tab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [tab])

  if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof REPORTS_TAB_SLUG_MAP)[keyof typeof REPORTS_TAB_SLUG_MAP]
  ) => {
    navigate(`/reports/${newSlug}`)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="asset tabs"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            paddingLeft: 3,
            paddingRight: 3,

            '& .MuiTab-root.Mui-selected': {
              color: theme.palette.text.primary,
            },
          })}
        >
          {Object.values(TAB_MAP).map((tabItem) => (
            <Tab
              key={tabItem.slug}
              value={tabItem.slug}
              label={tabItem.label}
              sx={{ py: 0 }}
              {...a11yProps(tabItem.slug)}
            />
          ))}
        </Tabs>
      </Box>
      {Object.entries(TAB_MAP).map(([key, value]) => (
        <CustomTabPanel key={key} value={tab} slug={value.slug}>
          {value.component}
        </CustomTabPanel>
      ))}
    </Box>
  )
}
