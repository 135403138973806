/* eslint-disable import/no-extraneous-dependencies */
import { GenericHost, Threat } from 'blues-corejs/dist'
import { AssetByThreat } from './../types'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

interface InitialSortParams {
  threats: Array<Threat>
  assetByThreat: AssetByThreat
}

/**
 * @description Sort the rows by First detected in descending order second order sort by asset, third order by path
 */
export function initialPrioritySortForThreats({
  threats,
  assetByThreat,
}: InitialSortParams) {
  return threats.sort((threatA, threatB) => {
    const assetA = assetByThreat.get(threatA)
    const assetB = assetByThreat.get(threatB)
    const aThreatDetectedAt = threatA.firstDetectedAt.getTime()
    const bThreatDetectedAt = threatB.firstDetectedAt.getTime()

    if (aThreatDetectedAt !== bThreatDetectedAt) {
      return bThreatDetectedAt - aThreatDetectedAt
    }

    if (assetA instanceof AWSAsset && assetB instanceof AWSAsset) {
      return (assetA.name || assetA.awsId).localeCompare(
        assetB.name || assetB.awsId
      )
    }

    if (assetA instanceof GenericHost && assetB instanceof GenericHost) {
      return assetA.hostname.localeCompare(assetB.hostname)
    }

    return threatA.location.localeCompare(threatB.location)
  })
}
