/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { HistoryTableRow } from 'ui-v2/src/lib/models/reports'
import CircularProgress from '@mui/material/CircularProgress'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import { Box } from '@mui/material'
import { ReportGeneratedPending } from 'blues-corejs/dist/models/reports/report-generated/status'
import { useDownloadGeneratedReport } from 'ui-v2/src/hooks/queries/reports/download-generated-report'
import { DownloadGeneratedReportResponse } from 'ui-v2/src/lib/clients/reports/on-demand/download-generated-report'

const PENDING = 'PENDING'

const getOptions = (
  files: DownloadGeneratedReportResponse | undefined,
  handleDownloadFile: (url: string) => void
) => {
  if (!files) {
    return []
  }

  const result = []
  const { csvUrl, pdfUrl, xlsxUrl } = files

  if (csvUrl) {
    result.push({
      label: 'CSV',
      onClick: () => handleDownloadFile(csvUrl),
    })
  }
  if (pdfUrl) {
    result.push({
      label: 'PDF',
      onClick: () => handleDownloadFile(pdfUrl),
    })
  }

  if (xlsxUrl) {
    result.push({
      label: 'XLSX',
      onClick: () => handleDownloadFile(xlsxUrl),
    })
  }

  return result
}

const HistoryAction = ({ row }: HistoryActionProps) => {
  const [isLoading, setLoading] = useState(false)
  const [files, setFiles] = useState<
    DownloadGeneratedReportResponse | undefined
  >()
  const { mutate } = useDownloadGeneratedReport()

  const handleDownloadFile = (url: string) => {
    window.open(url, '_blank')
  }

  const options = getOptions(files, handleDownloadFile)

  useEffect(() => {
    if (files && options?.length && options.length === 1) {
      options[0]?.onClick()
    }
  }, [files])

  const handleDownload = async () => {
    setLoading(true)

    mutate(
      { id: row.id },
      {
        onSuccess: (data) => {
          setLoading(false)
          setFiles(data)
        },
        onError: (error) => {
          setLoading(false)
          console.log(`Failed to download ${row.id} report: ${error.message}`)
        },
      }
    )
  }

  if (row.status instanceof ReportGeneratedPending) {
    return <Box>{PENDING}</Box>
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        padding: '5px 8px',
        cursor: 'pointer',
      }}
      onClick={handleDownload}
    >
      {isLoading ? <CircularProgress size={20} /> : <SaveAltIcon />}
    </Box>
  )
}
interface HistoryActionProps {
  row: HistoryTableRow
}
export default HistoryAction
