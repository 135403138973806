/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { StyledCheckbox, StyledFormControlLabel } from './styles'
import { getHumanReadableTenantJobKind } from '@features/jobs/tenant-jobs/helpers'

interface Props {
  kind: TenantJobKind
  onChange: (checked: boolean, kind: TenantJobKind) => void
  isChecked: boolean
}

export function TenantJobKindCheckbox({ kind, onChange, isChecked }: Props) {
  const handleCheckboxChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked, kind)
    },
    [kind, onChange]
  )

  return (
    <StyledFormControlLabel
      label={getHumanReadableTenantJobKind(kind)}
      control={
        <StyledCheckbox checked={isChecked} onChange={handleCheckboxChange} />
      }
    />
  )
}
