import { Metadata } from 'grpc-web'
import {
  GrpcCodes,
  retryableErrors,
} from 'ui-v2/src/lib/constants/api-errors.constant'
import { RequestOptions } from '../models/client'
import GrpcError from '../helpers/grpc-error.helper'
import { navigateTo } from '../helpers/system.helper'
import {
  BASE_ROUTE_SEGMENTS,
  TENANT_ROUTE_SEGMENTS,
} from '../constants/route-segments.constant'

/**
 * To extend `GrpcClient` for a new service, you must:
 *
 * 1. Implement the `initClient(hostName: string): C` method to initialize the specific gRPC service client.
 *    This method should create and return an instance of the gRPC service client using the provided `hostName`.
 *
 * 2. Implement the `innerClientTypeId(): string` method to return a unique identifier for the client type.
 *    This identifier is used for client registration and retrieval from the internal `clients` map.
 *
 * 3. Implement additional methods that utilize `this.getClient(hostName)` to obtain the gRPC service client
 *    and perform service-specific operations.
 *
 * Example usage:
 * ```ts
 * class ExampleClient extends GrpcClient<C> {
 *   constructor(hostName) {
 *     super();
 *     // Initialize and register the client in the constructor
 *     this.exampleServiceClient = this.getClient(hostName);
 *   }
 *
 *   // Implement the required abstract methods
 *   protected getClient(hostName: string): C {
 *     // Initialize your specific gRPC client here
 *   }
 *
 *   // After minification, the class name Client might be changed to something like a to reduce the file size,
 *   // and therefore Client.name and Client.constructor.name would return "a" instead of "Client"
 *   protected innerClientTypeId(): string {
 *     return 'ExampleClient';
 *   }
 * }
 * ```
 */

class GrpcDevToolsIntegration {
  register(client: unknown): void {
    const enableDevTools =
      typeof (window as any).__GRPCWEB_DEVTOOLS__ === 'function'
        ? (window as any).__GRPCWEB_DEVTOOLS__
        : () => void 0

    enableDevTools([client])
  }
}

export abstract class GrpcClient<C> {
  #devToolsIntegration: GrpcDevToolsIntegration

  constructor() {
    this.#devToolsIntegration = new GrpcDevToolsIntegration()
  }

  readonly #MAX_REQUEST_TIME_MS = 5 * 60 * 1000 // 5 minutes

  readonly #INITIAL_RETRY_DELAY_MS = 100

  readonly #INITIAL_RETRY_COUNT = 0

  readonly #MAX_RETRIES = 3

  readonly #MAX_RETRY_DELAY_MS = 10000

  protected static clients = new Map<string, unknown>()

  #delay(interval: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, interval))
  }

  #calculateBackoff({
    retryCount,
    initialRetryDelayMs,
  }: {
    retryCount: number
    initialRetryDelayMs: number
  }): number {
    const backoff =
      initialRetryDelayMs * Math.exp(retryCount) * (1.1 - Math.random() * 0.2)
    return Math.min(backoff, this.#MAX_RETRY_DELAY_MS)
  }

  #isGrpcError(error: unknown): error is {
    code: number
    message: string
    metadata: Metadata
  } {
    return (
      'code' in (error as object) &&
      'message' in (error as object) &&
      'metadata' in (error as object)
    )
  }

  #getGrpcErrorCode(error: unknown): number {
    if (this.#isGrpcError(error)) {
      return error.code
    }
    return GrpcCodes.UNKNOWN
  }

  protected metadata(token: string): Metadata {
    return {
      Authorization: token,
      deadline: String(Date.now() + this.#MAX_REQUEST_TIME_MS),
    }
  }

  #shouldRetryError(grpcErrorCode: GrpcCodes): boolean {
    return retryableErrors.includes(grpcErrorCode)
  }

  protected async callGrpcService<T>(
    call: () => Promise<T>,
    options: RequestOptions
  ): Promise<T> {
    const {
      currentRetryCount = this.#INITIAL_RETRY_COUNT,
      maxRetries = this.#MAX_RETRIES,
      initialRetryDelayMs = this.#INITIAL_RETRY_DELAY_MS,
    } = options

    try {
      return await call()
    } catch (error: unknown) {
      const grpcErrorCode = this.#getGrpcErrorCode(error)

      switch (grpcErrorCode as GrpcCodes) {
        case GrpcCodes.UNAUTHENTICATED:
          navigateTo(
            `/${BASE_ROUTE_SEGMENTS.TENANT}/${TENANT_ROUTE_SEGMENTS.UNAUTHENTICATED}`
          )
          break

        case GrpcCodes.PERMISSION_DENIED:
          navigateTo(
            `/${BASE_ROUTE_SEGMENTS.TENANT}/${TENANT_ROUTE_SEGMENTS.ACCESS_DENIED}`
          )
          break
      }

      const shouldRetry =
        this.#shouldRetryError(grpcErrorCode) && currentRetryCount < maxRetries

      if (!shouldRetry) {
        if (this.#isGrpcError(error)) {
          throw new GrpcError({
            code: error.code as GrpcCodes,
            message: error.message,
            metadata: error.metadata,
            requestName: options.requestName,
          })
        }
        throw error
      }

      const backoffDuration = this.#calculateBackoff({
        retryCount: currentRetryCount,
        initialRetryDelayMs,
      })

      await this.#delay(backoffDuration)

      return this.callGrpcService(call, {
        ...options,
        currentRetryCount: currentRetryCount + 1,
      })
    }
  }

  protected getClient(hostName: string): C {
    const key = `typeId:${this.innerClientTypeId()}-hostname:${hostName.trim()}}`

    if (!GrpcClient.clients.has(key)) {
      const newClient = this.initClient(hostName)
      this.#devToolsIntegration.register(newClient)
      GrpcClient.clients.set(key, newClient)
    }

    return GrpcClient.clients.get(key) as C
  }

  // this method must return the inner GRPC client
  protected abstract initClient(hostName: string): C

  // this method must return a class name or another id of the inner client type
  protected abstract innerClientTypeId(): string
}
