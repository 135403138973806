import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { PechkinClient } from 'ui-v2/src/lib/clients'
import { PolicyRequestParams } from 'ui-v2/src/lib/models/pechkin'
import { useMutation } from '@tanstack/react-query'

export function useUpdatePlanMutation() {
  const pechkinClient = useGrpcClient(PechkinClient)

  return useMutation({
    mutationFn: async (params: PolicyRequestParams) => {
      if (!pechkinClient) {
        throw new Error('Pechkin client not initialized')
      }
      return pechkinClient.updatePlan(params)
    },
  })
}
