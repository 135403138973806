import React from 'react'
import Box from '@mui/material/Box'
import { QuestionHint } from '@components-simple/question-hint'

function AllEc2Instances() {
  return (
    <Box className="protectEC2">
      All EC2 instances
      <QuestionHint text="All EC2 instances available in all accounts connected to the Tenant will be protected." />
    </Box>
  )
}

function AllEbsVolumes() {
  return (
    <Box className="protectEC2">
      All EBS volumes
      <QuestionHint text="All EBS volumes available in the Sources connected to the Tenant will be protected." />
    </Box>
  )
}

function AllEc2AndEbs() {
  return (
    <Box className="protectEC2">
      All EC2 and EBS
      <QuestionHint text="All EC2 and EBS available in all accounts connected to the Tenant will be protected." />
    </Box>
  )
}

function SpecificAssets() {
  return <>Specific assets</>
}

export const RADIO_TABS_LABELS = [
  <AllEc2Instances />,
  <AllEbsVolumes />,
  <AllEc2AndEbs />,
  <SpecificAssets />,
]
