/* eslint-disable import/no-extraneous-dependencies */
import { useAlertsListStore } from '@features/alerts/store/alerts-list-store'
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { useFetchAlertsListData } from '@features/alerts/hooks/use-fetch-alerts-list-data'
import { getAlertsColumnDefinitions } from '@features/alerts/alerts-table/column-definitions'
import { useCreateRuleDrawerActions } from '@features/alerts/create-rule-drawer'
import { Box, Button } from '@mui/material'

function AlertsTable() {
  const { openDrawer } = useCreateRuleDrawerActions()
  const { isLoading, tableData, selectedRows, setSelectedRows } =
    useAlertsListStore()
  const { paginationRequest } = useFetchAlertsListData()
  const observable = useInfiniteScrollDataFetch({
    initialItems: [],
    fetchNextBatch: () => paginationRequest(),
  })

  const noDataMessage = (
    <Box>
      <Box mb={2}>No rules found</Box>
      <Button variant="contained" onClick={() => openDrawer()}>
        Create Rule
      </Button>
    </Box>
  )

  return (
    <Table
      data={tableData}
      columns={getAlertsColumnDefinitions()}
      data-testid="RulesTable"
      generalProps={{
        noDataMessage: noDataMessage,
        isLoading,
      }}
      handlers={{
        rowsSelection: {
          setSelectedRows,
          selectedRows,
        },
      }}
      advancedProps={{
        observableState: observable,
      }}
    />
  )
}

export default AlertsTable
