/* eslint-disable import/no-extraneous-dependencies */
import { Scan } from 'ui-v2/src/lib/models/scans'
import { GenericHost, Nullable, Threat } from 'blues-corejs/dist'
import {
  FilesystemScanBackup,
  FilesystemScanTarget,
} from 'blues-corejs/dist/models/index'
import {
  MalwareScanBackup,
  MalwareScanTarget,
} from 'blues-corejs/dist/models/scans/malware/index'
import { ScanTarget } from 'blues-corejs/dist/models/scans/malware/types'
import {
  RansomwareScanBackup,
  RansomwareScanTarget,
} from 'blues-corejs/dist/models/scans/ransomware/index'
import { AssetItemGenericHost } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'

export abstract class AssetsGenericHostBase {
  protected abstract readonly genericHosts: Array<GenericHost>

  protected abstract readonly assetItemsListWithHostIds: Nullable<
    Map<string, Array<AssetItemGenericHost>>
  >

  protected abstract readonly threats: Array<Threat>

  protected abstract readonly scans: Array<Scan>

  protected abstract readonly genericHostsWithScans: Array<{
    genericHost: GenericHost
    scans: Array<Scan>
  }>

  protected getLastScan(host: GenericHost): Nullable<Scan> {
    const scansForHost = this.genericHostsWithScans.find(
      (scan) => scan.genericHost.id === host.id
    )

    if (!scansForHost) {
      return null
    }

    return scansForHost.scans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      scansForHost.scans[0]
    )
  }

  protected calculateThreatsCount(
    assetItems: Array<AssetItemGenericHost>
  ): number {
    const threatsCount = this.threats.reduce((acc, threat) => {
      assetItems.forEach((item) => {
        if (threat.source.assetItem?.assetItemId === item.id) {
          acc += 1
        }
      })
      return acc
    }, 0)

    return threatsCount
  }

  protected isRansomwareScanTarget(
    scanTarget: ScanTarget,
    host: GenericHost
  ): boolean {
    if (!this.assetItemsListWithHostIds) {
      return false
    }
    const assetItems = this.assetItemsListWithHostIds.get(host.id)
    if (!assetItems) {
      return false
    }

    const assetItemsHaveScans: Array<AssetItemGenericHost> = []
    assetItems.forEach((assetItem) => {
      if (scanTarget instanceof RansomwareScanTarget) {
        if (
          'assetItemId' in scanTarget.target &&
          scanTarget.target.assetItemId === assetItem.id
        ) {
          assetItemsHaveScans.push(assetItem)
        }
        if (
          scanTarget.target instanceof RansomwareScanBackup &&
          scanTarget.target.source?.assetItem &&
          scanTarget.target.source.assetItem?.backupAssetItemId === assetItem.id
        ) {
          assetItemsHaveScans.push(assetItem)
        }
      }
    })
    return assetItemsHaveScans.length > 0
  }

  protected isMalwareScanTarget(
    scanTarget: ScanTarget,
    host: GenericHost
  ): boolean {
    if (!this.assetItemsListWithHostIds) {
      return false
    }
    const assetItems = this.assetItemsListWithHostIds?.get(host.id)
    if (!assetItems) {
      return false
    }

    const assetItemsHaveScans: Array<AssetItemGenericHost> = []
    assetItems.forEach((assetItem) => {
      if (scanTarget instanceof MalwareScanTarget) {
        if (
          'assetItemId' in scanTarget.target &&
          scanTarget.target.assetItemId === assetItem.id
        ) {
          assetItemsHaveScans.push(assetItem)
        }

        if (
          scanTarget.target instanceof MalwareScanBackup &&
          scanTarget.target.source.assetItem &&
          scanTarget.target.source.assetItem.backupAssetItemId === assetItem.id
        ) {
          assetItemsHaveScans.push(assetItem)
        }
      }
    })
    return assetItemsHaveScans.length > 0
  }

  protected isFilesystemScanTarget(
    scanTarget: ScanTarget,
    host: GenericHost
  ): boolean {
    if (!this.assetItemsListWithHostIds) {
      return false
    }
    const assetItems = this.assetItemsListWithHostIds.get(host.id)
    if (!assetItems) {
      return false
    }
    const assetItemsHaveScans: Array<AssetItemGenericHost> = []
    assetItems.forEach((assetItem) => {
      if (
        scanTarget instanceof FilesystemScanTarget &&
        scanTarget.target instanceof FilesystemScanBackup &&
        scanTarget.target.source.assetItem &&
        scanTarget.target.source.assetItem?.backupAssetItemId === assetItem.id
      ) {
        return assetItemsHaveScans.push(assetItem)
      }
    })
    return assetItemsHaveScans.length > 0
  }

  protected filterScansForGenericHostsItems(
    host: GenericHost,
    scans: Array<Scan>
  ): Array<Scan> {
    return scans.filter((scan) => {
      const isRansomwareScanMatches = this.isRansomwareScanTarget(
        scan.scanTarget,
        host
      )

      const isMalwareScanMatches = this.isMalwareScanTarget(
        scan.scanTarget,
        host
      )

      const isFilesystemScanMatches = this.isFilesystemScanTarget(
        scan.scanTarget,
        host
      )

      return (
        isRansomwareScanMatches ||
        isMalwareScanMatches ||
        isFilesystemScanMatches
      )
    })
  }

  protected mapGenericHostItemsToScans(genericHost: GenericHost): {
    genericHost: GenericHost
    scans: Array<Scan>
  } {
    const scans = this.filterScansForGenericHostsItems(genericHost, this.scans)
    return {
      genericHost,
      scans,
    }
  }

  protected getGenericHostsWithScans(): Array<{
    genericHost: GenericHost
    scans: Array<Scan>
  }> {
    return this.genericHosts.map(this.mapGenericHostItemsToScans.bind(this))
  }

  protected isUnhealthyHost(assetItems: Array<AssetItemGenericHost>): boolean {
    return this.threats.some((threat) =>
      assetItems.some(
        (item) => threat.source.assetItem?.assetItemId === item.id
      )
    )
  }
}
