import { useEffect, useState } from 'react'

export function useLoadingDebounce(isLoading: boolean, delay = 300): boolean {
  const [debouncedLoading, setDebouncedLoading] = useState<boolean>(isLoading)

  useEffect(() => {
    if (isLoading) {
      // Immediately update when switching to loading state
      setDebouncedLoading(true)
      return
    }

    // Debounce only when switching from loading to not loading
    const handler = setTimeout(() => {
      setDebouncedLoading(false)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [isLoading, delay])

  return debouncedLoading
}
