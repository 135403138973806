import React from 'react'

function ProtectedAssetsIcon() {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="10" fill="#E4FFFC" />
      <path
        d="M27.5002 46.2488C26.9834 46.2488 26.5615 45.8305 26.5615 45.3137C26.5615 44.7934 26.9834 44.375 27.5002 44.375V46.2488Z"
        fill="#107C42"
      />
      <path
        d="M28.4387 45.3137C28.4387 45.8305 28.0168 46.2488 27.5 46.2488V44.375C28.0168 44.375 28.4387 44.7934 28.4387 45.3137Z"
        fill="#185C37"
      />
      <path
        d="M45.3139 15.3111C45.3139 15.8314 44.892 16.2498 44.3752 16.2498C43.8584 16.2498 43.4365 15.8314 43.4365 15.3111C43.4365 14.7943 43.8584 14.376 44.3752 14.376C44.892 14.376 45.3139 14.7943 45.3139 15.3111Z"
        fill="#21A366"
      />
      <path
        d="M11.5639 15.3111C11.5639 15.8314 11.142 16.2498 10.6252 16.2498C10.1084 16.2498 9.68652 15.8314 9.68652 15.3111C9.68652 14.7943 10.1084 14.376 10.6252 14.376C11.142 14.376 11.5639 14.7943 11.5639 15.3111Z"
        fill="#33C481"
      />
      <path
        d="M27.5 45.3137L27.9008 46.1645C27.9008 46.1645 42.0969 38.757 44.8496 27.5H27.5V45.3137Z"
        fill="#185C37"
      />
      <path
        d="M45.3137 15.3111L44.375 14.376C33.9477 14.376 33.8035 8.75098 27.5 8.75098V27.4998H44.8496C45.1449 26.2939 45.3137 25.0424 45.3137 23.7486C45.3137 20.8025 45.3137 15.3111 45.3137 15.3111Z"
        fill="#21A366"
      />
      <path
        d="M27.5 45.3137L27.0992 46.1645C27.0992 46.1645 12.9031 38.757 10.1504 27.5H27.5V45.3137Z"
        fill="#107C42"
      />
      <path
        d="M9.68652 15.3111L10.6252 14.376C21.0525 14.376 21.1967 8.75098 27.5002 8.75098V27.4998H10.1506C9.85527 26.2939 9.68652 25.0424 9.68652 23.7486C9.68652 20.8025 9.68652 15.3111 9.68652 15.3111Z"
        fill="#33C481"
      />
      <path
        d="M24.962 35.663L17.4632 28.1642C17.0976 27.795 17.0976 27.2009 17.4632 26.8353L18.7886 25.5099C19.1542 25.1442 19.7483 25.1442 20.114 25.5099L25.6265 31.0224L35.8253 20.8235C36.1909 20.4579 36.7851 20.4579 37.1507 20.8235L38.4761 22.1489C38.8417 22.5146 38.8417 23.1087 38.4761 23.4743L26.2874 35.663C25.9218 36.0286 25.3276 36.0286 24.962 35.663Z"
        fill="white"
      />
    </svg>
  )
}

export default ProtectedAssetsIcon
