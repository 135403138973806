import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'
import ObjHelper from 'ui-v2/src/lib/helpers/obj.helper'

abstract class BaseModel {
  public readonly id: number
  public readonly innerId: string

  public readonly baseParams: any

  protected constructor(readonly params: any) {
    this.baseParams = params
    this.id = TypeHelper.numberValDef(params.id, 0)
    this.innerId = TypeHelper.stringValDef(params.innerId, '')
  }

  // this method is static in order to prevent accidental cloning
  // it should be done only in factories
  public static clone<T extends BaseModel>(model: T, params: any = {}): T {
    const baseParams = ObjHelper.cloneDeep(model.baseParams)
    const overrideParams = ObjHelper.cloneDeep(params)
    const finalParams = Object.assign(baseParams, overrideParams)

    // @ts-ignore
    const result = new model.constructor(finalParams)

    result['baseParams'] = finalParams

    return result
  }
}

export default BaseModel
