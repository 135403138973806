import React, { useMemo, ReactNode } from 'react'
import { PagePathConstant } from '@lib/constants'
import { LoadingScreen } from '@components-simple/loading-screen'
import { PageMeta } from '@lib/engine-types'
import { UsersClient } from '@lib/clients'
import {
  useAppInitialization,
  useAuthentication,
} from '@features/authentication'

const usersClient = new UsersClient()

// TODO: refactor this when we'll migrate to SPA routing (react-router)
const PAGES_THAT_DO_NOT_REQUIRE_AUTH = [
  PagePathConstant.RELOGIN,
  PagePathConstant.NOT_FOUND,
  PagePathConstant.TENANT_ACCESS_DENIED,
]

function AuthenticationSPARequired({
  children,
}: {
  children: ReactNode
  pageMeta?: PageMeta
}) {
  const pathName = window.location.pathname

  const shouldHandleAuth = useMemo(
    () => !PAGES_THAT_DO_NOT_REQUIRE_AUTH.includes(pathName),
    [window.location.pathname]
  )

  const { isAuthenticated, isLoading } = useAuthentication(shouldHandleAuth)
  const { isInitialized } = useAppInitialization(isAuthenticated, {
    usersClient,
  })

  const isAppLoading = shouldHandleAuth ? !isInitialized : false

  if (isLoading || isAppLoading) {
    return <LoadingScreen />
  }

  return <>{children}</>
}

export default AuthenticationSPARequired
