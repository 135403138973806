/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/material'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'
import clsx from 'clsx'
import React, { useMemo } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  scanStatus: SummaryCheckStatus
}

const DEFAULT_STATUSES = {
  isInfected: false,
  isUnscanned: false,
}

function BackupScanStatus({ scanStatus, ...restProps }: Props) {
  const { label, isInfected, isUnscanned } = useMemo(() => {
    switch (scanStatus) {
      case SummaryCheckStatus.BAD: {
        return {
          ...DEFAULT_STATUSES,
          label: 'Infected',
          isInfected: true,
        }
      }
      case SummaryCheckStatus.UNCHECKED: {
        return {
          ...DEFAULT_STATUSES,
          label: 'Unscanned',
          isUnscanned: true,
        }
      }

      default:
        return {
          label: '',
          ...DEFAULT_STATUSES,
        }
    }
  }, [scanStatus])

  return (
    <Box className="icNameWithBackWrap" {...restProps}>
      <Box
        marginRight="100%"
        component="span"
        className={clsx('icLabel', {
          infected: isInfected,
          unscanned: isUnscanned,
        })}
      >
        {label}
      </Box>
    </Box>
  )
}

export { BackupScanStatus }
