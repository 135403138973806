import React, { memo, ReactNode } from 'react'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'

interface Props {
  loading: boolean
  data: Array<any>
  children: ReactNode
  noDataMessage?: string
  noDataComponent?: ReactNode
  EmptyBlock?: ReactNode
}

function ContentBlock({
  loading,
  data,
  children,
  noDataMessage = 'No data found',
  noDataComponent,
  EmptyBlock = <div className="emptyResultBlock jsEmpty">{noDataMessage}</div>,
}: Props) {
  return (
    <>
      {loading && data.length === 0 ? (
        <PreloaderBlock show />
      ) : (
        <>
          {data.length > 0 ? (
            <>{children}</>
          ) : (
            <>{noDataComponent ? <>{noDataComponent}</> : <>{EmptyBlock}</>}</>
          )}
        </>
      )}
    </>
  )
}

export default memo(ContentBlock)
