import { Column } from 'ui-v2/src/components/ui/data-display/data-table'
import { TokenListTableRow } from 'ui-v2/src/lib/models/settings/api-access/api-access'
import dayjs from 'dayjs'

export const getRevokedTokenListColumns = (): Array<
  Column<TokenListTableRow>
> => [
  {
    key: 'token',
    header: 'Token',
    render: (row) => <>{row.token}</>,
  },
  {
    key: 'status',
    header: 'Status',
    render: (row) => row.status,
  },
  {
    key: 'lastUsed',
    header: 'Last Used',
    render: (row) => (
      <>
        {row.lastUsed
          ? dayjs(row.lastUsed).format('MM/DD/YYYY hh:mm A')
          : 'Never used'}
      </>
    ),
  },
]
