import { styled } from '@mui/material/styles'
import CircleIcon from '@mui/icons-material/Circle'

export enum BadgeStatusColor {
  GOOD = 'var(--green500)',
  BAD = 'var(--red400)',
  UNCHECKED = 'var(--grey500)',
}

export const StyledCircleIcon = styled(CircleIcon)({
  fontSize: '14px',
})
