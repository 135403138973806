import { ValueInterface, VIMatrix } from 'ui-v2/src/lib/engine-types'
import { RedStackModel } from '../../base-model'
import VaultModel from '../policies/vault.model'

export interface SourcesListTableDataAttrs {
  sourcesList: VIMatrix
  redStackListData: Array<RedStackModel>
  allActiveRedStacksData: Array<RedStackModel>
  vaultListData: Array<VaultModel>
  problematicCC: ProblematicCC
  cloudConnectorsInAction: Array<string>
}

export class SourcesListTableRow {
  readonly #id: string

  readonly #accountId: string

  readonly #aliasName: string

  readonly #description: string

  readonly #regions: string

  readonly #action: string

  constructor(parameters: SourcesListTableRowAttr) {
    const { id, accountId, aliasName, description, regions, action } =
      parameters

    this.#id = id
    this.#accountId = accountId
    this.#aliasName = aliasName
    this.#description = description
    this.#regions = regions
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get accountId(): string {
    return this.#accountId
  }

  get aliasName(): string {
    return this.#aliasName
  }

  get description(): string {
    return this.#description
  }

  get regions(): string {
    return this.#regions
  }

  get action(): string {
    return this.#action
  }
}
export interface SourcesListTableRowAttr {
  id: string
  accountId: string
  aliasName: string
  description: string
  regions: string
  action: string
}
export interface ProblematicCC {
  isProblem: boolean
  problemsArray: Array<{
    reason: string
    model: RedStackModel
  }>
}
export interface SourceFormData {
  awsAccountId: string
  region: ValueInterface
}

export enum CopyButtonVariant {
  WINDOWS,
  MACOS,
  LINUX,
  DEFAULT,
}
