import React, { memo } from 'react'
import { ListSubheader, MenuItem, Select } from '@mui/material'
import { SelectSearch, Placeholder, AllOptionsBlock } from './components'
import { menuProps, selectDisplayStyle } from './styles'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import AddIcon from '@mui/icons-material/Add'
import { ControllerRenderProps } from 'react-hook-form'
import { EmptyFunc } from '@lib/constants/app.constant'

export type Option = {
  value: string
  label: string
}

interface Props {
  options: Array<Option>
  selected: Array<string> // TODO: get rid off this props, use field.value instead
  onChange?: any
  defaultValues?: Array<string>
  placeholder?: string
  showSearchInput?: boolean
  // When true, select provided any value to be selected
  isCreatable?: boolean
  onCreateNewValue?: (value: string) => boolean
  field?: ControllerRenderProps
  inputError?: string
  onClose?: () => void
}

function MultipleSelect({
  options,
  selected,
  defaultValues = [],
  showSearchInput = true,
  placeholder,
  onChange,
  field,
  onCreateNewValue,
  isCreatable = false,
  inputError,
  onClose = EmptyFunc,
}: Props) {
  const [searchValue, setSearchValue] = React.useState('')

  const filteredOptions = React.useMemo(() => {
    return options.filter((item) =>
      Object.values(item).some((f) =>
        f.toString().toLowerCase().includes(searchValue.toLowerCase())
      )
    )
  }, [options.length, searchValue])

  const isNoSelectedOptions = React.useMemo(
    () => Boolean(selected.length === 0),
    [selected]
  )

  const isNoExactMatchBySearch =
    isCreatable &&
    searchValue &&
    filteredOptions.every((item) => item.value !== searchValue)

  const handleOnSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!showSearchInput) {
      return
    }

    const { value } = event.target

    setSearchValue(value)
  }

  const handleAllSelected = () => {
    if (selected.length === options.length) {
      onChange([])
      return
    }

    onChange(options.map((item) => item.value))
  }

  const handleClose = () => {
    if (!showSearchInput) {
      return
    }

    onClose()

    setSearchValue('')
  }

  const renderSelectValue = React.useCallback(() => {
    if (isNoSelectedOptions) {
      return <Placeholder>{placeholder}</Placeholder>
    }
    return options
      .filter((item) => {
        return selected.some((option) => option === item.value)
      })
      .map((item) => item.label)
      .join(', ')
  }, [selected, isNoSelectedOptions, options.length])

  const handleAddNewValue = () => {
    if (!onCreateNewValue || !showSearchInput) {
      return
    }

    const isCreated = onCreateNewValue(searchValue)

    if (!isCreated) {
      return
    }

    setSearchValue('')
  }

  return (
    <Select
      {...field}
      variant="outlined"
      multiple
      fullWidth
      autoFocus={false}
      defaultValue={defaultValues}
      displayEmpty={isNoSelectedOptions}
      renderValue={renderSelectValue}
      // @ts-ignore: TODO - Fix TypeScript issue: "placeholder" is not assignable to type SelectProps<string[]>.
      placeholder={placeholder}
      onClose={handleClose}
      MenuProps={{
        ...menuProps,
      }}
      SelectDisplayProps={{
        style: selectDisplayStyle,
      }}
    >
      <AllOptionsBlock
        selectedCount={selected.length}
        handleAllSelected={handleAllSelected}
      />
      {showSearchInput && (
        <SelectSearch
          searchValue={searchValue}
          error={inputError}
          onSearch={handleOnSearch}
        />
      )}

      {isNoExactMatchBySearch && (
        <ListSubheader
          component="div"
          sx={{
            marginBottom: '8px',
            color: 'black',
          }}
        >
          <MenuItem
            sx={{
              paddingLeft: '6px',
            }}
            onClick={handleAddNewValue}
          >
            <AddIcon /> Add
            <LongTextTooltip text={searchValue} maxLength={50} />"
          </MenuItem>
        </ListSubheader>
      )}
      {filteredOptions.map((item: Option) => (
        <MenuItem key={item.value} value={item.value}>
          <LongTextTooltip text={item.label} maxLength={60} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default memo(MultipleSelect)
