import { DefinedDateRangeTuple } from '@lib/engine-types'
import { CloudConnectorJobFiltersUI } from '@lib/interfaces/jobs'

type SetMember<T> = T extends Set<infer E> ? E : never

export interface CloudConnectorFiltersState {
  timeRange: DefinedDateRangeTuple
  selectedFilters: CloudConnectorJobFiltersUI
  possibleFilters: CloudConnectorJobFiltersUI
}

export interface CloudConnectorFiltersActions {
  resetCloudConnectorJobsFilters: () => void
  setFilters: (selectedFilters: CloudConnectorJobFiltersUI) => void
  setTimeRange: (timeRange: DefinedDateRangeTuple) => void
  fetchPossibleCloudConnectorJobFilters: () => void
  removeFilter: <
    K extends keyof CloudConnectorJobFiltersUI,
    V extends SetMember<
      CloudConnectorJobFiltersUI[keyof CloudConnectorJobFiltersUI]
    >,
  >(
    key: K,
    value: V
  ) => void
}

export type CloudConnectorFiltersStore = CloudConnectorFiltersState & {
  actions: CloudConnectorFiltersActions
}

export {
  useIsCloudConnectorFiltersNotEmpty as useIsDeploymentFiltersNotEmpty,
  useCloudConnectorJobsTimerange,
  useSelectedCloudConnectorJobFilters,
  usePossibleCloudConnectorJobFilters,
  useShouldDisableFilterButton,
  useCloudConnectorJobsFiltersActions,
  getSelectedFilters,
} from './selectors'

export { useFiltersSubscription } from './subscriptions'
