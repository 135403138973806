import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataHelper } from '@lib/helpers'
import VaultModel from '@lib/models/vault.model'
import { setAddPolicyVaults } from '@store/actions/add-policy.action'
import { getSelectedVaultsList } from '@store/selectors/add-policy.selector'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { useFilteredVaultsList } from './use-filtered-vaults-list'
import ChooseVaultStep from '@components-complex/add-policy-complex/ChooseVaultStep'

interface Props {
  isLoading: boolean
}

export function VaultsSelection({ isLoading }: Props) {
  const dispatch = useDispatch()
  const filteredVaultsList = useFilteredVaultsList()
  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const selectedVaults = useSelector(getSelectedVaultsList)

  const [accountIdFilterName, setAccountIdFilterName] = useState<string>('')

  const accountsGroupWithVaults =
    DataHelper.groupVaultsByAccountIdAndRegion(filteredVaultsList)

  const filteredAccountsWithVaults = useMemo(() => {
    return accountsGroupWithVaults.filter((account) =>
      account.accountId.includes(accountIdFilterName)
    )
  }, [accountsGroupWithVaults.length, accountIdFilterName, filteredVaultsList])

  const hasActiveCloudConnectors = allActiveRedStacks.length > 0

  const handleVaultSelect = (vault: VaultModel) => {
    const currentVault = {
      redStackId: vault.redStackId,
      vaultName: vault.name,
      isDefault: vault.isDefault,
    }

    if (currentVault.isDefault) {
      return
    }

    const index = selectedVaults.findIndex(
      (v) => v.redStackId === vault.redStackId
    )

    if (index === -1) {
      dispatch(setAddPolicyVaults([...selectedVaults, currentVault]))
      return
    }

    const newVaults = [...selectedVaults]
    newVaults[index] = currentVault
    dispatch(setAddPolicyVaults(newVaults))
  }

  return (
    <ChooseVaultStep
      accounts={filteredAccountsWithVaults}
      onSearchChange={setAccountIdFilterName}
      onVaultSelect={handleVaultSelect}
      loading={isLoading}
      hasActiveCloudConnectors={hasActiveCloudConnectors}
    />
  )
}
