import {
  MAX_PERCENT_OF_ASSETS_WITH_BACKUPS,
  TOP_BOT_PCT_CHANGE_HIDE_LABEL,
} from './consts'

export function shouldBeHidePercentOfAssetsWithBackups(
  percentOfAssetsWithBackups: number
) {
  return (
    Math.abs(MAX_PERCENT_OF_ASSETS_WITH_BACKUPS - percentOfAssetsWithBackups) <
    TOP_BOT_PCT_CHANGE_HIDE_LABEL
  )
}
