import { AssetsEc2TableData } from './ec2/ec2-table-data'
import { AssetsEc2DrawerData } from './ec2/ec2-drawer-data'
import { AssetsEbsTableData } from './ebs/ebs-table-data'
import { AssetsS3TableData } from './s3/s3-table-data'
import { AssetsEfsTableData } from './efs/efs-table-data'
import { AssetsGenericHostTableData } from './generic-host/generic-host-table-data'
import { AssetsOvaServerTableData } from './ova-server/ova-server-table-data'

export {
  AssetsEc2TableData,
  AssetsEc2DrawerData,
  AssetsEbsTableData,
  AssetsS3TableData,
  AssetsEfsTableData,
  AssetsGenericHostTableData,
  AssetsOvaServerTableData,
}
