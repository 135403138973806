import { useMemo } from 'react'
import { useAwsBackupCoverageQuery } from '../queries/aws-stats'
import { NormalizedCoverageCardData } from 'ui-v2/src/lib/models/aws-stats'
import { COVERAGE_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

const useAwsBackupCoverageData = () => {
  const { data: awsBackupCoverageData, isLoading } = useAwsBackupCoverageQuery(
    {}
  )

  const normalizedData: Array<NormalizedCoverageCardData> = useMemo(() => {
    if (!awsBackupCoverageData) {
      return []
    }

    const services = ['ebs', 'ec2'] as const

    const coveredCount = services.reduce(
      (sum, service) => sum + (awsBackupCoverageData[service]?.covered ?? 0),
      0
    )

    const totalCount = services.reduce(
      (sum, service) => sum + (awsBackupCoverageData[service]?.total ?? 0),
      0
    )

    return [
      {
        slug: COVERAGE_CARD_SLUG.BACKUP_COVERAGE,
        count: coveredCount,
        totalCount,
        link: `/${BASE_ROUTE_SEGMENTS.ASSETS}`,
      },
    ]
  }, [awsBackupCoverageData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsBackupCoverageData
