import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useInfoModalStore } from '@features/alerts/store/alerts-info-modal-store'

function RuleDetailsHeader() {
  const { setOpen } = useInfoModalStore()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid container spacing={2} alignItems="center" mb={3}>
      <Grid item xs={12} sm={9}>
        <Typography fontSize="22px" fontWeight="600">
          Details
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3} container justifyContent="flex-end">
        <Box display="flex" gap="5px">
          {/*<ButtonPrimary>*/}
          {/*    Modify*/}
          {/*</ButtonPrimary>*/}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default RuleDetailsHeader
