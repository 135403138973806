/* eslint-disable import/no-extraneous-dependencies */
import { DownloadGeneratedReportRequest } from 'ui-v2/src/lib/grpc'
import { ReportGenerated } from 'blues-corejs/dist/models/reports/report-generated/report-generated'

export interface DownloadGeneratedReportCriteria {
  id: string
}

export interface DownloadGeneratedReportResponse {
  pdfUrl?: string
  csvUrl?: string
  xlsxUrl?: string
  generatedReport?: ReportGenerated
}

export function buildDownloadGeneratedReportRequest({
  id,
}: DownloadGeneratedReportCriteria): DownloadGeneratedReportRequest {
  const request = new DownloadGeneratedReportRequest().setId(id)

  return request
}
