/* eslint-disable import/no-extraneous-dependencies */
import { StreamProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { Stream } from 'blues-corejs/dist'

type StreamPt = StreamProto.AsObject

export class StreamTransformer {
  #stream: StreamPt

  constructor(stream: StreamPt) {
    this.#stream = stream
  }

  transform(): Stream {
    return this.#transformStream(this.#stream)
  }

  #transformStream(stream: StreamPt): Stream {
    return new Stream({
      id: stream.id,
      name: stream.name,
      assetId: stream.assetId,
      ccAssetId: stream.ccAssetId,
      createdAt: secondsToDate(stream.createdAt?.seconds),
      updatedAt: secondsToDate(stream.updatedAt?.seconds),
    })
  }
}
