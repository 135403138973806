export function removeParamsSuffix(input: string): string {
  if (!input) {
    return ''
  }
  if (typeof input !== 'string') {
    throw new TypeError(`Expected a string, but received a ${typeof input}`)
  }

  return input.replace(/Params$/, '')
}
