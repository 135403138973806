import { styled } from '@mui/material/styles'
import Chip from '@mui/material/Chip'

export const StyledAssetDetailsChip = styled(Chip)(() => ({
  '&.MuiChip-root': {
    backgroundColor: '#E4F7FF',
    fontSize: '14px',
    fontWeight: '600',
    marginY: '5px',
    marginX: '5px',
  },
}))
