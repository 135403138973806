import PagePathConstant from '@lib/constants/page-path.constant'
import React from 'react'
import SystemHelper from '@lib/helpers/system.helper'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { useAuth0 } from '@auth0/auth0-react'
import { useCountdown } from '@lib/hooks'

export default function ReLogin() {
  const { loginWithRedirect } = useAuth0()
  const { auth0Spa } = useCustomFlags()
  const handleLogout = async () => {
    if (auth0Spa) {
      await loginWithRedirect()
    } else {
      SystemHelper.pureNavigate(PagePathConstant.AUTH0_LOGOUT)
    }
  }
  const timeLeft = useCountdown(9999999, () => handleLogout())

  return (
    <div className="innerContent ilMain">
      <div className="llContent">
        <div className="logoBlockWrap">
          <div className="logoBlock">
            <div className="">
              <img
                width={140}
                height={50}
                alt="Elastio"
                src="/images/elastio-logo.svg"
              />
            </div>
          </div>
        </div>
        <p className="textHeaderBlock">
          <b>Your Session Has Expired</b>
        </p>
        <div className="textBlock">
          <p>
            You will be automatically redirected to the <br />
            Login page in {timeLeft} second(s).
          </p>
        </div>
        <button
          onClick={handleLogout}
          className="jsLogoutLink iButton"
          type="button"
        >
          Back to login page
        </button>
      </div>
      <div className="image">
        <img
          width={468}
          height={468}
          alt="Elastio"
          src="/images/error-reloading.svg"
        />
      </div>
    </div>
  )
}
