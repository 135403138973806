import ValueInterface from '@lib/interfaces/value.interface'
import NumHelper from '@lib/helpers/num.helper'
import TypeHelper from '@lib/helpers/type.helper'
import TenantNotificationsModel from '@lib/models/tenant-notifications-model'
import TenantNotificationsState from '@store/states/tenant-notifications.state'

abstract class TenantNotificationsFactory {
  public static build(params: any): TenantNotificationsModel {
    return new TenantNotificationsModel({
      id: NumHelper.numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      slackConfig: params.slackConfig,
      installSlackUrl: TypeHelper.stringValDef(params.installSlackUrl, ''),
    })
  }

  public static buildEmpty() {
    return new TenantNotificationsModel({
      id: '',
      innerId: '',
      slackConfig: {},
    })
  }

  public static buildDataBySettings(
    data: TenantNotificationsState,
    isSlackInstalled: boolean,
    isLoading: boolean
  ): Record<string, Array<ValueInterface>> {
    let slack, email

    if (data) {
      slack = data.slackNotificationSettings
      email = data.emailNotificationSettings
    }

    return {
      errors: [
        {
          name: 'email',
          value: !!email?.sendErrors,
          label: 'Send to specific emails',
          disabled: isLoading || !data,
        },
        {
          name: 'slack',
          value: !!slack?.sendErrors,
          label: 'Send to Slack',
          disabled: isLoading || !data || !isSlackInstalled,
        },
      ],
      warnings: [
        {
          name: 'email',
          value: !!email?.sendWarnings,
          label: 'Send to specific emails',
          disabled: isLoading || !data,
        },
        {
          name: 'slack',
          value: !!slack?.sendWarnings,
          label: 'Send to Slack',
          disabled: isLoading || !data || !isSlackInstalled,
        },
      ],
      info: [
        // {
        //   name: 'email',
        //   value: !!email?.sendInfo,
        //   label: 'Send to email',
        //   disabled: isLoading || !data,
        // },
        {
          name: 'slack',
          value: !!slack?.sendInfo,
          label: 'Send to Slack',
          disabled: isLoading || !data || !isSlackInstalled,
        },
      ],
    }
  }
}

export default TenantNotificationsFactory
