import Divider from '@mui/material/Divider'

import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale'
import type { TableElementEvent } from '@features/DashboardV4/widgets-with-scale/shared'
import styles from './common-statistic.module.css'
import {
  rpoAscParam,
  rpoDescParam,
  showRpoBackupsParam,
  showRpoNoBackupsParam,
} from '@features/DashboardV4/widgets-with-scale/rpo/utilities'
import { RpoRetentionSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/skeleton'
import { AwsRpoStats } from '@lib/clients/aws-statistics/types'
import { DashboardRoutes, DashboardTab } from '@lib/router'
import Box from '@mui/material/Box'
import React from 'react'
import clsx from 'clsx'

type RetentionCommonStatisticOwnProps = {
  onClick: (link: string) => (event: TableElementEvent) => void
  instancesNumber: number
  volumesNumber: number
  instancesOutOfScheduleNumber: number
  volumesOutOfScheduleNumber: number
  isLoading: boolean
} & Omit<AwsRpoStats, 'quantiles'>

function computedStyleOutOfSchedulePercent(percentage: number) {
  if (percentage > 0) {
    return {
      color: '#ED3F3F',
    }
  }
  return {}
}

function RetentionCommonStatistic({
  backupsOutOfSchedule,
  lowestRpo,
  highestRpo,
  onClick,
  instancesNumber,
  volumesNumber,
  instancesOutOfScheduleNumber,
  volumesOutOfScheduleNumber,
  isLoading,
}: RetentionCommonStatisticOwnProps) {
  const lowestRpoMilliseconds = lowestRpo || 0
  const highestRpoMilliseconds = highestRpo || 0
  const { label: lowestRpoLabel, value: lowestRpoValue } =
    convertMillisecondsWithLabel(lowestRpoMilliseconds)
  const { label: highestRpoLabel, value: highestRpoValue } =
    convertMillisecondsWithLabel(highestRpoMilliseconds)

  const ec2Tab = String(DashboardTab.EC2)
  const ebsTab = String(DashboardTab.EBS)

  const inventoryInstancesShowRpoAsc =
    DashboardRoutes.inventoryTablesWithParams(ec2Tab, rpoAscParam)
  const inventoryInstancesShowRpoDesc =
    DashboardRoutes.inventoryTablesWithParams(ec2Tab, rpoDescParam)

  const inventoryVolumesShowRpoAscPath =
    DashboardRoutes.inventoryTablesWithParams(ebsTab, rpoAscParam)
  const inventoryVolumesShowRpoDesc = DashboardRoutes.inventoryTablesWithParams(
    ebsTab,
    rpoDescParam
  )

  const inventoryInstancesShowRpoNoBackups =
    DashboardRoutes.inventoryTablesWithParams(ec2Tab, showRpoNoBackupsParam)
  const inventoryVolumesShowRpoNoBackups =
    DashboardRoutes.inventoryTablesWithParams(ebsTab, showRpoNoBackupsParam)
  const inventoryInstancesShowRpoBackups =
    DashboardRoutes.inventoryTablesWithParams(ec2Tab, showRpoBackupsParam)

  const rpoOutOfScheduleDrillDown =
    instancesOutOfScheduleNumber > 0
      ? inventoryInstancesShowRpoNoBackups
      : volumesOutOfScheduleNumber > 0
        ? inventoryVolumesShowRpoNoBackups
        : inventoryInstancesShowRpoBackups

  const rpoAscDrillDown =
    instancesNumber > 0
      ? inventoryInstancesShowRpoAsc
      : volumesNumber > 0
        ? inventoryVolumesShowRpoAscPath
        : inventoryInstancesShowRpoAsc

  const rpoDescDrillDown =
    instancesNumber > 0
      ? inventoryInstancesShowRpoDesc
      : volumesNumber > 0
        ? inventoryVolumesShowRpoDesc
        : inventoryInstancesShowRpoDesc

  if (isLoading) {
    return <RpoRetentionSkeletonLoader />
  }

  return (
    <table className={styles.root}>
      <tbody>
        <tr
          onClick={onClick(rpoOutOfScheduleDrillDown)}
          className={styles.link}
        >
          <td colSpan={2} className={styles.firstTd}>
            <Box component="span" className={styles.wrapDate}>
              <Box
                component="span"
                style={computedStyleOutOfSchedulePercent(backupsOutOfSchedule)}
                className={styles.number}
              >
                {backupsOutOfSchedule}%
              </Box>
            </Box>{' '}
            <Box
              component="span"
              className={styles.text}
              display="inline-flex"
              justifyContent="center"
              gap="8px"
            >
              <Box>Backups out of schedule</Box>
            </Box>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tr>
          <td
            className={clsx([styles.columnBorder, styles.link])}
            onClick={onClick(rpoAscDrillDown)}
          >
            <Box className={styles.wrapDate}>
              <Box component="span" className={styles.number}>
                {lowestRpoValue}
              </Box>{' '}
              <Box component="span" className={styles.date}>
                {lowestRpoLabel}
              </Box>
            </Box>
            <Box
              className={styles.text}
              display="inline-flex"
              justifyContent="center"
              gap="8px"
            >
              <Box>Lowest RPO</Box>
            </Box>
          </td>
          <td onClick={onClick(rpoDescDrillDown)} className={styles.link}>
            <Box className={styles.wrapDate}>
              <Box component="span" className={styles.number}>
                {highestRpoValue}
              </Box>{' '}
              <Box component="span" className={styles.date}>
                {highestRpoLabel}
              </Box>
            </Box>
            <Box
              className={styles.text}
              display="inline-flex"
              justifyContent="center"
              gap="8px"
            >
              <Box>Highest RPO</Box>
            </Box>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default RetentionCommonStatistic
