import { Box, styled } from '@mui/material'

const PROPS_TO_NOT_FORWARD = ['clickable']

export const RiskBlockContainer = styled(Box, {
  shouldForwardProp: (prop) => !PROPS_TO_NOT_FORWARD.includes(prop as string),
})<{ clickable: boolean }>(({ clickable }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: clickable ? 'pointer' : 'default',
  gap: '16px',
  marginBottom: '16px',
  paddingInline: '20px',
  '&:hover': {
    color: clickable ? 'var(--blue500)' : 'var(--grey900)',
  },
}))

export const StyledRiskBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})
