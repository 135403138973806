import AddTagsBlock from '@components-complex/add-policy-complex/add-tags-block/AddTagsBlock'
import TagsSelect from '@components-composite/tags-select/TagsSelect'
import { WarningMessage } from '@components-simple/messages/warning-message'
import { QuestionHint } from '@components-simple/question-hint'
import { RadioTabs } from '@components-simple/radio-tabs'
import { SpecificAssetsForPolicy } from '@features/policies'
import { SelectedVariant } from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import { TABS_HINT_FOR_CREATE_RP } from '@lib/constants/policies/policies-constants'
import { VIRow } from '@lib/engine-types'
import Box from '@mui/material/Box'
import {
  setEditPolicyTags,
  setEditProtectNewImmediately,
  setEditSelectedVariant,
  setEditSkipEbsBackup,
  setEditSnapshotImportVariant,
} from '@store/actions/edit-policy.action'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'

import { getSelectedAssetsSelect } from '@store/selectors/assets-select.selector'
import {
  getEditPolicyTags,
  getEditProtectNewImmediately,
  getEditSelectedVariant,
  getEditSkipEbsBackup,
  getEditSnapshotImportVariant,
} from '@store/selectors/edit-policy.selector'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RADIO_TABS_LABELS } from './radio-tabs-labels'

interface Props {
  isLoading: boolean
}

const useGetDisabledRadioList = (): Array<SelectedVariant> => {
  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const policyTags = useSelector(getEditPolicyTags)

  const isSelectedAssets = selectedAssets.length > 0
  const isPolicyTag = policyTags.length > 0

  if (isSelectedAssets) {
    return [
      SelectedVariant.AllEC2EBSVariant,
      SelectedVariant.AllEBSVariant,
      SelectedVariant.AllEC2Variant,
      SelectedVariant.AssetsVariant,
    ]
  }
  if (isPolicyTag) {
    return [SelectedVariant.AssetsVariant]
  }

  return []
}

export function Ec2EbsProtectionOptions({ isLoading }: Props) {
  const disabledVariants = useGetDisabledRadioList()
  const dispatch = useDispatch()
  const selectedVariant = useSelector(getEditSelectedVariant)
  const snapshotImportVariant = useSelector(getEditSnapshotImportVariant)
  const editImmediatelyVariant = useSelector(getEditProtectNewImmediately)
  const skipEbsBackup = useSelector(getEditSkipEbsBackup)
  const policyTags = useSelector(getEditPolicyTags)

  const [isSkipEbsBackupAdd, setIsSkipEbsBackup] =
    useState<boolean>(skipEbsBackup)

  const handleTabChange = (newTabIndex: number) => {
    dispatch(setEditSelectedVariant(newTabIndex as SelectedVariant))
  }

  const handleSnapshotImportTabChange = (newTabIndex: number) => {
    dispatch(setEditSnapshotImportVariant(newTabIndex as SnapshotImport))
  }

  const handleNewImmediatelyChange = (newTabIndex: number) => {
    dispatch(
      setEditProtectNewImmediately((newTabIndex + 1) as ProtectNewImmediately)
    )
  }

  const handleSkipEbsBackup = (newTabIndex: number) => {
    setIsSkipEbsBackup(Boolean(newTabIndex))
    dispatch(setEditSkipEbsBackup(Boolean(newTabIndex)))
  }

  const handleTagsChange = (newTags: VIRow) => {
    dispatch(setEditPolicyTags(newTags))
  }

  useEffect(() => {
    setIsSkipEbsBackup(skipEbsBackup)
    dispatch(setEditSkipEbsBackup(skipEbsBackup))
  }, [skipEbsBackup])

  return (
    <>
      <Box className="addPolicyTitle mt25 jsAddPolicyProtectionOptionsSubTitle">
        Protection options
      </Box>
      <RadioTabs
        className="flexColumnJustifyStart assetsSelectBlock jsSelectAssetsRadio"
        tabsLabel="Protect:"
        labels={RADIO_TABS_LABELS}
        currentTabIndex={selectedVariant}
        onCurrentTabChange={handleTabChange}
        disabledRadioList={disabledVariants}
        mode="default"
        commonChild={
          <>
            <Box className="relative importSnapshotSelect">
              <p className="fontMedium">Scan pre-existing EBS snapshot:</p>
              <RadioTabs
                className="importRadioGroup radioTabEnabled jsSnapshotImportRadio"
                labels={['Skip', 'Latest snapshot', 'All snapshots']}
                currentTabIndex={snapshotImportVariant}
                onCurrentTabChange={handleSnapshotImportTabChange}
              />
            </Box>
            <Box className="relative importSnapshotSelect">
              <p className="fontMedium">
                Scan immediately upon asset creation:
                <QuestionHint text="The default behaviour is to protect the assets based on the backup schedule. If enabled, the backup of a newly created asset is performed right after it appears in the account with only a five minute delay to ensure that EC2 and/or EBS is fully loaded and all volumes properly mounted." />
              </p>
              <RadioTabs
                className="importRadioGroup radioTabEnabled jsProtectImmediatelyRadio"
                labels={['Disabled', 'Enabled']}
                currentTabIndex={editImmediatelyVariant - 1}
                onCurrentTabChange={handleNewImmediatelyChange}
              />
            </Box>
            {snapshotImportVariant !== SnapshotImport.SKIP && (
              <>
                <Box className="relative importSnapshotSelect">
                  <p className="fontMedium">Create recovery point:</p>
                  <RadioTabs
                    className="importRadioGroup radioTabEnabled jsSkipEbsBackupRadio"
                    labels={['Create', 'Skip']}
                    currentTabIndex={Number(isSkipEbsBackupAdd)}
                    onCurrentTabChange={handleSkipEbsBackup}
                    tabsQuestionHint={TABS_HINT_FOR_CREATE_RP}
                  />
                </Box>
                {skipEbsBackup && (
                  <WarningMessage
                    message="Warning! Skipping creating recovery points in
            combination with enabling snapshot import will only
            work in cases, where there is a third party tool
            enabled in the account that creates snapshots of your
            assets. In other cases this setup will leave assets
            unprotected."
                    className="align-justify jsWarningSkipRP"
                  />
                )}
              </>
            )}
          </>
        }
      >
        <AddTagsBlock
          policyTags={policyTags}
          onTagsChange={handleTagsChange}
          loading={isLoading}
        />
        <AddTagsBlock
          policyTags={policyTags}
          onTagsChange={handleTagsChange}
          loading={isLoading}
        />
        <AddTagsBlock
          policyTags={policyTags}
          onTagsChange={handleTagsChange}
          loading={isLoading}
        />
        {selectedVariant === SelectedVariant.AssetsVariant && (
          <>
            <Box className="policiesBlockSubheader mt25">Select assets</Box>
            <Box className="assetsDescrBlockLabel fontMedium mb20 jsSelectSpecificAssetsDescr">
              Select the specific assets from the table below to add to the
              policy. Note that only assets belonging to the Cloud Connectors
              with “Connected” status are displayed below.
            </Box>
            <SpecificAssetsForPolicy
              initialSelectedFilters={defaultSelectedFilters()}
            />
          </>
        )}
        <>
          <Box className="policiesBlockSubheader mt25">
            Select assets by tags
          </Box>
          <Box className="policiesBlockLabel fontMedium mb20">
            Enter the tags to identify which assets to protect when the policy
            is executed. Each time the policy is executed, the assets are
            identified by tag dynamically meaning that the assets and tags don’t
            need to exist when this policy is created.
          </Box>
          <Box
            className={clsx('mb40 w400', {
              controlsDisabled: isLoading,
            })}
          >
            <TagsSelect onChange={handleTagsChange} tags={policyTags} />
          </Box>
        </>
      </RadioTabs>
    </>
  )
}
