import {
  ScheduledTenantJob,
  ScheduledTenantJobKind,
} from 'ui-v2/src/features/scheduled-jobs-monitoring/models'

interface ScheduledTenantJobsRepository {
  listByKinds(
    kinds: Array<ScheduledTenantJobKind>
  ): Promise<Array<ScheduledTenantJob>>
}

export class GetTenantIdsForRunningScheduledJobs {
  constructor(private repository: ScheduledTenantJobsRepository) {}

  async execute(kinds: Array<ScheduledTenantJobKind>): Promise<Set<string>> {
    const jobs = await this.repository.listByKinds(kinds)

    return new Set<string>(jobs.map((job) => job.ccId))
  }
}
