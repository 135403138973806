/* eslint-disable import/no-extraneous-dependencies */
import { EC2, EBS, EFS, S3Bucket, GenericHost } from 'blues-corejs/dist'

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function computeAssetCopiableId(asset: Asset): string {
  if (asset instanceof GenericHost) {
    return asset.hostname
  } else {
    return asset.arn
  }
}
