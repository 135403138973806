import { BaseRoutes } from '../base'

export enum RouteFragments {
  DYNAMIC_ID = ':id',
}

export class VaultDetailedRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_VAULT_DETAILED
  }

  public static buildPathWithEncodedId(id: string | number): string {
    return `${this.root}/${this.base64Encode(String(id))}`
  }

  public static get withIdPlaceholder(): string {
    return `${this.root}/${RouteFragments.DYNAMIC_ID}`
  }

  static #ROUTE_VAULT_DETAILED = '/vault-detailed'
}
