"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitsProgress = exports.MeasurementUnit = void 0;
var MeasurementUnit;
(function (MeasurementUnit) {
    MeasurementUnit[MeasurementUnit["BYTES"] = 0] = "BYTES";
})(MeasurementUnit || (exports.MeasurementUnit = MeasurementUnit = {}));
class UnitsProgress {
    #measurement;
    #expected;
    #current;
    #rate;
    constructor(parameters) {
        this.#measurement = parameters.measurement;
        this.#expected = parameters.expected;
        this.#current = parameters.current;
        this.#rate = parameters.rate;
    }
    get rate() {
        return this.#rate;
    }
    get measurement() {
        return this.#measurement;
    }
    get progress() {
        if (!this.#current) {
            return 0;
        }
        if (!this.#expected || this.#expected === 0) {
            if (!this.#rate) {
                return 0;
            }
            return this.#rate > 0 ? 50 : 0;
        }
        return (this.#current / this.#expected) * 100;
    }
}
exports.UnitsProgress = UnitsProgress;
