import Divider from '@mui/material/Divider'

import styles from './common-statistic.module.css'
import {
  TableElementEvent,
  convertMillisecondsWithLabel,
} from '@features/DashboardV4/widgets-with-scale'
import {
  INVENTORY_INSTANCES_TABLE,
  INVENTORY_INSTANCES_TABLE_DESC,
  INVENTORY_VOLUMES_TABLE,
  INVENTORY_VOLUMES_TABLE_DESC,
} from '../consts'
import { RpoRetentionSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/skeleton'
import { AwsRetentionStats } from '@lib/clients/aws-statistics/types'
import Box from '@mui/material/Box'
import React from 'react'
import clsx from 'clsx'

type RetentionCommonStatisticOwnProps = {
  onClick: (link: string) => (event: TableElementEvent) => void
  isShowInstancesTable: boolean
  isLoading: boolean
} & Omit<AwsRetentionStats, 'quantiles'>

function RetentionCommonStatistic({
  averageRetention,
  topQuartile,
  bottomQuartile,
  onClick,
  isShowInstancesTable,
  isLoading,
}: RetentionCommonStatisticOwnProps) {
  const averageRetentionMilliseconds = averageRetention || 0
  const bottomQuartileMilliseconds = bottomQuartile || 0
  const topQuartileMilliseconds = topQuartile || 0

  const { label: averageRetentionLabel, value: averageRetentionValue } =
    convertMillisecondsWithLabel(averageRetentionMilliseconds)
  const {
    label: retentionBottomQuartileLabel,
    value: retentionBottomQuartileValue,
  } = convertMillisecondsWithLabel(bottomQuartileMilliseconds)

  const { label: retentionTopQuartileLabel, value: retentionTopQuartileValue } =
    convertMillisecondsWithLabel(topQuartileMilliseconds)

  const bottomQuartileDrillDown = isShowInstancesTable
    ? INVENTORY_INSTANCES_TABLE
    : INVENTORY_VOLUMES_TABLE

  const topQuartileDrillDown = isShowInstancesTable
    ? INVENTORY_INSTANCES_TABLE_DESC
    : INVENTORY_VOLUMES_TABLE_DESC

  if (isLoading) {
    return <RpoRetentionSkeletonLoader />
  }

  return (
    <table className={styles.root}>
      <tbody>
        <tr className={styles.link}>
          <td colSpan={2} className={styles.firstTd}>
            <Box component="span" className={styles.wrapDate}>
              <Box component="span" className={styles.number}>
                {averageRetentionValue}
              </Box>{' '}
              <Box component="span" className={styles.date}>
                {averageRetentionLabel}
              </Box>
            </Box>{' '}
            <Box
              component="span"
              className={styles.text}
              display="inline-flex"
              justifyContent="center"
              gap="8px"
            >
              <Box>Average retention</Box>
            </Box>
            <Divider sx={{ my: 1 }} />
          </td>
        </tr>
        <tr>
          <td
            className={clsx([styles.columnBorder, styles.link])}
            onClick={onClick(bottomQuartileDrillDown)}
          >
            <Box className={styles.wrapDate}>
              <Box component="span" className={styles.number}>
                {retentionBottomQuartileValue}
              </Box>{' '}
              <Box component="span" className={styles.date}>
                {retentionBottomQuartileLabel}
              </Box>
            </Box>
            <Box
              component="span"
              className={styles.text}
              display="inline-flex"
              justifyContent="center"
              gap="8px"
            >
              <Box>Bottom quartile</Box>
            </Box>
          </td>
          <td onClick={onClick(topQuartileDrillDown)} className={styles.link}>
            <Box className={styles.wrapDate}>
              <Box component="span" className={styles.number}>
                {retentionTopQuartileValue}
              </Box>{' '}
              <Box component="span" className={styles.date}>
                {retentionTopQuartileLabel}
              </Box>
            </Box>
            <Box
              component="span"
              className={styles.text}
              display="inline-flex"
              justifyContent="center"
              gap="8px"
            >
              <Box>Top quartile</Box>
            </Box>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default RetentionCommonStatistic
