/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import SystemHelper from '@lib/helpers/system.helper'
import { ListBackupsForAssetClient } from '@lib/clients/backups/list-backups-for-asset'
import { LastCleanBackupForAsset } from '@lib/clients/backups/types'
import { Backup } from 'blues-corejs/dist'

const listBackupsForAssetClient = new ListBackupsForAssetClient()

export interface LastCleanRpForAsset {
  /**
  @returns latest clean backup model from the response or undefined if there is no backup
  */
  latestCleanBackup: Backup | undefined
  lastCleanRp: Partial<LastCleanBackupForAsset>
  isLastCleanRpLoading: boolean
}

function getLatestBackupFromResponse(
  response: Partial<LastCleanBackupForAsset>
): Backup | undefined {
  const backups = [
    response.awsbRps,
    response.ebsSnapshots,
    response.elastioRps,
    response.ovaBackup,
  ]

  const notEmptyBackup = backups.find(Boolean)

  return notEmptyBackup
}

/**
 * Custom hook to get last clean recovery point by `assetId`.
 * @param assetId - asset id
 * @returns last clean recovery point and loading state
 */
export function useFetchLastCleanRpForAsset(
  assetId: string
): LastCleanRpForAsset {
  const [lastCleanRp, setLastCleanRp] = useState<
    Partial<LastCleanBackupForAsset>
  >({})
  const [isLastCleanRpLoading, setLastCleanRpLoading] = useState(false)

  useEffect(() => {
    async function fetchLastCleanBackupForAsset() {
      if (!assetId) {
        return
      }
      setLastCleanRpLoading(true)

      try {
        const response =
          await listBackupsForAssetClient.lastCleanBackupForAsset(assetId)
        setLastCleanRp(response)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong  in ${__filename}: ${error}`
        )
        console.error(
          `Error occurred with fetch last clean rp for asset request in file ${__filename} while processing: ${error}`
        )
      } finally {
        setLastCleanRpLoading(false)
      }
    }

    fetchLastCleanBackupForAsset()
  }, [])

  return {
    lastCleanRp,
    latestCleanBackup: getLatestBackupFromResponse(lastCleanRp),
    isLastCleanRpLoading,
  }
}
