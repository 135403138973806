/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReportIcon from '@mui/icons-material/Report'
import React from 'react'
import Box from '@mui/material/Box'
import IconMUI from '@mui/material/Icon'

function getIsSomeScansInfected(scans: ScansRun) {
  const isMalwareInfected = scans.malwareScansList.some(
    (scan) => scan.isInfected
  )
  const isRansomwareInfected = scans.ransomwareScansList.some(
    (scan) => scan.isInfected
  )
  const fsCheckInfected = scans.filesystemChecksList.some(
    (scan) => scan.isInfected
  )

  return isMalwareInfected || isRansomwareInfected || fsCheckInfected
}
function computeScanStatusColor(scans: ScansRun) {
  const isScansRunInfected = getIsSomeScansInfected(scans)

  if (isScansRunInfected) {
    return 'el-error'
  }

  return 'el-success'
}

function computeScanStatusIcon(scans: ScansRun) {
  const isScansRunInfected = getIsSomeScansInfected(scans)

  if (isScansRunInfected) {
    return ReportIcon
  }

  return CheckCircleIcon
}

function AssetItemRecoveryPointStatusCell({ scans }: { scans: ScansRun }) {
  const color = computeScanStatusColor(scans)
  const Icon = computeScanStatusIcon(scans)

  return (
    <Box display="flex" alignItems="center" gap="5px">
      <IconMUI color={color} fontSize="small" component={Icon} />
    </Box>
  )
}

export default AssetItemRecoveryPointStatusCell
