import { useEffect } from 'react'
import { FormControl, Grid2 } from '@mui/material'

import { Controller, useForm } from 'react-hook-form'

import { useDispatch } from 'react-redux'
import { AppDispatch } from 'ui-v2/src/store'
import {
  useUpdateSettingMutation,
  useUserConfigQuery,
} from 'ui-v2/src/hooks/queries/users'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'ui-v2/src/hooks/toast'
import { UserSettings } from 'ui-v2/src/lib/constants/settings.constant'
import { base64DecodeObj } from 'ui-v2/src/lib/helpers/string.helper'
import { setUserConfig } from 'ui-v2/src/store/features/user/user-slice'
import Label from 'ui-v2/src/components/ui/data-display/label'
import TimeZoneSelector from 'ui-v2/src/components/ui/inputs/time-zone-select'
import Button from 'ui-v2/src/components/ui/inputs/button'

interface AccountProfileFormData {
  timezone: any
}

const Timezone = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { data } = useUserConfigQuery()
  const { mutate, isPending } = useUpdateSettingMutation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<AccountProfileFormData>({
    defaultValues: { timezone: '' },
  })

  const onSubmit = ({ timezone }: AccountProfileFormData) => {
    mutate(
      {
        name: UserSettings.TimeZone,
        value: timezone,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['users', 'userConfig'],
          })

          toast.success('Timezone has been updated')
          reset(getValues())
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Timezone update failed')
        },
      }
    )
  }

  useEffect(() => {
    if (data) {
      if (data?.TimeZone?.value) {
        reset({
          timezone:
            (base64DecodeObj(data?.TimeZone?.value)?.value as string) ?? '',
        })
      }
      dispatch(setUserConfig(data))
    }
  }, [data])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              maxWidth: {
                sm: '100%',
                md: 302,
              },
            }}
          >
            <Label>Timezone</Label>
            <Controller
              control={control}
              rules={{
                required: 'Timezone is required',
              }}
              name="timezone"
              render={({ field, fieldState }) => (
                <TimeZoneSelector
                  value={field.value}
                  onChange={(tz) => {
                    field.onChange(tz)
                  }}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2>
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            disabled={!isDirty || isPending}
            isLoading={isPending}
          >
            Save
          </Button>
        </Grid2>
      </Grid2>
    </form>
  )
}

export default Timezone
