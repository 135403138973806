import { Box, styled, Tooltip, useTheme } from '@mui/material'
import React, { memo, useMemo } from 'react'

import RansomwareIcon from '@inline-img/general/ransomware-icon'
import MalwareIcon from '@inline-img/general/malware-icon'
import FsCheckIcon from '@inline-img/general/fs-check-icon'
import { ScanStatus } from '../types'

const StyledBox = styled(Box)(() => ({
  width: '22px',
  height: '22px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
}))

type ThreatIcons =
  | typeof RansomwareIcon
  | typeof MalwareIcon
  | typeof FsCheckIcon

interface Props {
  scanStatus: ScanStatus
  Icon: ThreatIcons
}

function getIconTooltipText(
  scanStatus: ScanStatus,
  iconType: ThreatIcons
): string {
  const threatName =
    iconType === RansomwareIcon
      ? 'Ransomware'
      : iconType === MalwareIcon
        ? 'Malware'
        : 'File Corruption'

  switch (scanStatus) {
    case ScanStatus.SUCCEEDED:
      return `No ${threatName} found`
    case ScanStatus.FAILED:
      return `${threatName} found`
    default:
      return `Not scanned for ${threatName}`
  }
}

function ThreatIconBlock({ scanStatus, Icon }: Props) {
  const isScanStatusClean = scanStatus === ScanStatus.SUCCEEDED
  const isScanStatusInfected = scanStatus === ScanStatus.FAILED

  const { palette } = useTheme()

  const { red, green, grey } = palette

  const color = useMemo(
    () => ({
      backgroundColor: isScanStatusClean
        ? green[50]
        : isScanStatusInfected
          ? red[50]
          : grey[200],
      fill: isScanStatusClean
        ? green[500]
        : isScanStatusInfected
          ? red[500]
          : grey[500],
    }),
    [scanStatus]
  )

  return (
    <Tooltip title={getIconTooltipText(scanStatus, Icon)} placement="top">
      <StyledBox bgcolor={color.backgroundColor} data-testid="threat-icon">
        <Icon width="16px" height="16px" fill={color.fill} />
      </StyledBox>
    </Tooltip>
  )
}

export default memo(ThreatIconBlock)
