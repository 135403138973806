/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Box } from '@mui/material'
import { Header } from './header'
import { Navigation } from './navigation'
import { SliderItem } from './slider-item'
import { SliderLine } from './icons'
import useSlider from './useSlider'
import { StyledContainer } from './styles'
import { Threat } from 'blues-corejs/dist'

import { OvaServer } from 'blues-corejs/dist/models'
import { OvaBackupSnapshot } from 'blues-corejs/dist/models/backups/ova/backup/ova-backup-snapshot'

interface Props {
  server: OvaServer
  snapshots: Array<OvaBackupSnapshot>
  onSnapshotChange: (asset: OvaBackupSnapshot) => void
  threats: Array<Threat>
  preselectedSnapshot?: OvaBackupSnapshot
}

const DEFAULT_SELECTED_SLIDE = 0

function OvaBackupSlider({
  server,
  snapshots,
  onSnapshotChange,
  threats,
  preselectedSnapshot,
}: Props) {
  const sliderContainerRef = React.useRef<HTMLDivElement>(null)

  const findPreselectedOrInfectedSnapshot = () => {
    if (preselectedSnapshot) {
      return snapshots.findIndex(
        (snapshot) => snapshot.id === preselectedSnapshot.id
      )
    }

    const firstInfectedSnapshot = snapshots.findIndex((snapshot) =>
      threats.some(
        (threat) =>
          threat.source.asset?.assetId === snapshot.ovaVolumeId ||
          threat.source.assetItem?.assetItemId === snapshot.ovaVolumeId
      )
    )

    if (firstInfectedSnapshot !== -1) {
      return firstInfectedSnapshot
    }

    return DEFAULT_SELECTED_SLIDE
  }

  const {
    currentSlide,
    canScrollBack,
    canScrollNext,
    handleSlideChange,
    onScrollBack,
    onScrollNext,
  } = useSlider({
    ref: sliderContainerRef,
    slides: snapshots,
    selectedSlide: findPreselectedOrInfectedSnapshot(),
  })

  function changeSlideWithCallback(
    index: number,
    selectedSnapshot: OvaBackupSnapshot
  ) {
    handleSlideChange(index)

    onSnapshotChange(selectedSnapshot)
  }

  return (
    <StyledContainer>
      <Header server={server} />
      <SliderLine />
      <Navigation
        canScrollBack={canScrollBack}
        canScrollNext={canScrollNext}
        onScrollBack={onScrollBack}
        onScrollNext={onScrollNext}
      />
      <Box className="slider-container" ref={sliderContainerRef}>
        {snapshots.map((snapshot, index) => (
          <SliderItem
            threats={threats}
            snapshot={snapshot}
            key={snapshot.id}
            index={index}
            selected={currentSlide === index}
            onSlideChange={(selectedIndex) =>
              changeSlideWithCallback(selectedIndex, snapshot)
            }
          />
        ))}
      </Box>
    </StyledContainer>
  )
}

export default OvaBackupSlider
