import { FormControl, FormHelperText } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'

const DatepickerInput = ({
  value,
  maxDate,
  disabled,
  format = 'll',
  disablePast,
  helperText,
  error,
  onChange,
}: DatepickerInputProps) => {
  return (
    <FormControl error={error}>
      <DatePicker
        defaultValue={value}
        value={value}
        format={format}
        slotProps={{
          inputAdornment: {
            position: 'start',
          },
          textField: { size: 'small' },
        }}
        onChange={onChange}
        maxDate={maxDate}
        disabled={!!disabled}
        disablePast={!!disablePast}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
interface DatepickerInputProps {
  value: Dayjs | null
  maxDate?: Dayjs
  disabled?: boolean
  format?: string
  disablePast?: boolean
  helperText?: string
  error?: boolean
  onChange: (value: any) => void
}
export default DatepickerInput
