/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EBS, EC2, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import LangHelper from '@lib/helpers/lang.helper'
import { RowTitle } from '../../row-title'

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function Region({ asset }: { asset: Asset }) {
  if (!(asset instanceof AWSAsset)) {
    return null
  }

  return (
    <Grid container item xs={4}>
      <Grid item>
        <RowTitle title="Region:" />
      </Grid>
      <Grid item>
        <Typography fontSize="14px" fontWeight="400" variant="caption">
          {LangHelper.getAwsRegionSingleTranslation(asset.awsRegion)}
        </Typography>
      </Grid>
    </Grid>
  )
}
