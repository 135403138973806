"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uuid = uuid;
exports.name = name;
exports.volumeStatus = volumeStatus;
exports.volumeStatusCheck = volumeStatusCheck;
exports.osKind = osKind;
exports.instanceState = instanceState;
exports.awsAccountId = awsAccountId;
exports.awsRegion = awsRegion;
exports.awsAvailabilityZone = awsAvailabilityZone;
exports.awsVolumeId = awsVolumeId;
exports.awsInstanceId = awsInstanceId;
exports.awsAmiId = awsAmiId;
exports.awsSnapshotId = awsSnapshotId;
exports.awsCCAssetId = awsCCAssetId;
exports.awsAmiName = awsAmiName;
exports.awsAmiLocation = awsAmiLocation;
exports.awsCost = awsCost;
exports.awsResourceId = awsResourceId;
exports.tenant = tenant;
exports.bool = bool;
exports.randomString = randomString;
const models_1 = require("../../../models");
function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0, v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
    // return crypto.randomUUID()
}
function name(modelName, n) {
    if (bool()) {
        return '';
    }
    return `${modelName}-${n}`;
}
function volumeStatus() {
    const statuses = [
        models_1.VolumeStatus.VOLUME_STATUS_UNKNOWN,
        models_1.VolumeStatus.AVAILABLE,
        models_1.VolumeStatus.IN_USE,
        models_1.VolumeStatus.CREATING,
        models_1.VolumeStatus.DELETING,
        models_1.VolumeStatus.ERROR,
    ];
    return statuses[Math.floor(Math.random() * statuses.length)];
}
function volumeStatusCheck() {
    const statuses = [
        models_1.VolumeStatusCheck.VOLUME_STATUS_CHECK_UNKNOWN,
        models_1.VolumeStatusCheck.OK,
        models_1.VolumeStatusCheck.INSUFFICIENT_DATA,
        models_1.VolumeStatusCheck.IMPAIRED,
    ];
    return statuses[Math.floor(Math.random() * statuses.length)];
}
function osKind() {
    const kinds = [models_1.OsKind.LINUX, models_1.OsKind.WINDOWS, models_1.OsKind.OTHER];
    return kinds[Math.floor(Math.random() * kinds.length)];
}
function instanceState() {
    const states = [
        models_1.InstanceState.INSTANCE_STATE_UNKNOWN,
        models_1.InstanceState.INSTANCE_STATE_STOPPED,
        models_1.InstanceState.INSTANCE_STATE_RUNNING,
    ];
    return states[Math.floor(Math.random() * states.length)];
}
function awsAccountId() {
    return randomString('0123456789', 12);
}
function awsRegion() {
    const regions = ['us-east-1', 'us-east-2', 'us-west-1', 'us-west-2'];
    return regions[Math.floor(Math.random() * regions.length)];
}
function awsAvailabilityZone(region) {
    const zones = ['a', 'b', 'c'];
    return `${region}${zones[Math.floor(Math.random() * zones.length)]}`;
}
function awsVolumeId() {
    return `vol-${awsResourceId()}`;
}
function awsInstanceId() {
    return `i-${awsResourceId()}`;
}
function awsAmiId() {
    return `ami-${awsResourceId()}`;
}
function awsSnapshotId() {
    return `snap-${awsResourceId()}`;
}
function awsCCAssetId(account, region, assetName, type) {
    return `elastio:asset:${type}:s:${account}:${region}:${assetName}`;
}
function awsAmiName() {
    return `amazon/amzn2-ami-kernel-5.10-hvm-2.${Math.random()}.1-x86_64-gp2`;
}
function awsAmiLocation(name) {
    return `amazon/${name}`;
}
function awsCost() {
    return Math.floor(Math.random() * 1000000);
}
function awsResourceId() {
    return randomString('0123456789abcdef', 17);
}
function tenant() {
    return `dev.${randomString('abcdefghij', 7)}.elastio.us`;
}
function bool() {
    return Math.random() > 0.5;
}
function randomString(alphabet, length) {
    let randomValue = '';
    const alphabetLength = alphabet.length;
    let i = 0;
    while (i < length) {
        randomValue += alphabet.charAt(Math.floor(Math.random() * alphabetLength));
        i += 1;
    }
    return randomValue;
}
