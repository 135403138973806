import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import Button from 'ui-v2/src/components/ui/inputs/button'
import {
  BASE_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { Close } from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NameAndSchedule from './policy-form/name-and-schedule'

import ProtectionOptions from './policy-form/protection-options'
import ReviewPolicy from './policy-form/review-policy'
import Assets from './policy-form/assets'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AssetType,
  AssetVariantType,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import {
  DEFAULT_TIME_ZONE,
  EMPTY_SCAN_WITH_TIMESTAMPS,
  EMPTY_SCHEDULE,
} from 'ui-v2/src/lib/constants/time.constant'
import {
  IntegrityCheckOptions,
  IntegrityScanOptions,
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SelectedVariant,
  SnapshotImport,
  TabAssetType,
} from 'ui-v2/src/lib/constants/policies.constant'
import { useCreatePlanMutation } from 'ui-v2/src/hooks/queries/pechkin/create-plan'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'ui-v2/src/hooks/toast'
import { base64Decode } from 'ui-v2/src/lib/helpers/string.helper'
import { usePlanInfoSelectedAssetsQuery } from 'ui-v2/src/hooks/queries/pechkin/plan-info-selected-assets'
import { PolicyInfoWithSelectedAssets } from 'ui-v2/src/lib/models/pechkin/pechkin'
import { useUpdatePlanMutation } from 'ui-v2/src/hooks/queries/pechkin/update-plan'

const steps = 4

const PolicyDrawer = ({ existingPoliciesNames }: PolicyDrawerProps) => {
  const { action, id } = useParams()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState<number>(0)

  const method = useForm<PolicyFormData>({
    defaultValues: {
      policyName: '',
      description: '',
      schedule: EMPTY_SCHEDULE(),
      integrityScan: {
        scanForMalware: true,
        scanForRansomware: true,
      },
      policyTags: [],
      selectedVariant: SelectedVariant.AllEC2EBSVariant,
      integrityCheck: IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM,
      snapshotImportVariant: SnapshotImport.SKIP,
      protectNewImmediately: ProtectNewImmediately.DISABLED,
      selectedVaultsList: [],
      skipEbsBackup: false,
      selectedCloudConnectorVariant: SelectedCloudConnectorVariant.ALL,
      selectedCloudConnectors: [],
      integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO,
      keepDataCopy: {
        keepLastClean: false,
        keepLatestInfected: false,
      },
      scanWithTimestamps: EMPTY_SCAN_WITH_TIMESTAMPS(),
      isEntropyDetectionEnabled: false,
      timezone: DEFAULT_TIME_ZONE(),
      tabAssetTypeSelectedNum: 0,
      assetVariant: AssetVariantType.EC2,
      selectedEc2EbsAssetsRows: [],
      selectedS3BucketAssetsRows: [],
      selectedEfsAssetsRows: [],
      selectedAssets: [],
      selectedS3Assets: [],
      selectedEfsAssets: [],
    },
  })

  const { handleSubmit, reset, setValue } = method

  const { mutate: createPlanMutate, isPending: createPlanIsPending } =
    useCreatePlanMutation()

  const { mutate: updatePlanMutate, isPending: updatePlanIsPending } =
    useUpdatePlanMutation()

  const {
    data: planInfoSelectedAssetsData,
    isLoading: isPlanSelectedAssetsDataLoading,
  } = usePlanInfoSelectedAssetsQuery(id ? base64Decode(id) : '')

  const queryClient = useQueryClient()

  const toast = useToast()

  const handleCloseDrawer = () => {
    setActiveStep(0)
    reset()
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}`
    )
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const handleCreatePlan = (policyFormData: PolicyFormData) => {
    const policyName = policyFormData.policyName
    const schedule = policyFormData.schedule
    const selectedCloudConnectors = policyFormData.selectedCloudConnectors

    const selectedVariant = policyFormData.selectedVariant
    const selectedAssets = policyFormData.selectedAssets
    const selectedS3Assets = policyFormData.selectedS3Assets
    const selectedS3AllPath = policyFormData.selectedS3Assets.map(
      (s3Asset) => s3Asset.asset.id
    )
    const selectedEfsAssets = policyFormData.selectedEfsAssets
    const selectedEfsAllPath = policyFormData.selectedEfsAssets.map(
      (efsAsset) => efsAsset.asset.id
    )
    const integrityScan = policyFormData.integrityScan // Recovery Assurance settings
    const isEntropyDetectionEnabled = policyFormData.isEntropyDetectionEnabled
    const integrityCheck = policyFormData.integrityCheck

    const keepDataCopy = policyFormData.keepDataCopy // EC2 & EBS Settings
    const protectNewImmediately = policyFormData.protectNewImmediately

    const scanWithTimestamps = policyFormData.scanWithTimestamps // S3 Settings & EFS settings

    const policyTags = policyFormData.policyTags

    const snapshotImportVariant = policyFormData.snapshotImportVariant

    const skipEbsBackup = policyFormData.skipEbsBackup

    const executeImmediately = false

    createPlanMutate(
      {
        policyName,
        schedule,
        selectedCloudConnectors,
        selectedVariant,
        selectedAssets,
        selectedS3Assets,
        selectedS3AllPath,
        selectedEfsAssets,
        selectedEfsAllPath,
        iscan: integrityScan,
        isEntropyDetectionEnabled,
        integrityCheck,
        keepDataCopy,
        protectNewImmediately,
        scanWithTimestamps,
        policyTags,
        executeImmediately,
        skipEbsBackup: skipEbsBackup,
        snapshotImport: snapshotImportVariant,
        integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO, // 0
      },
      {
        onSuccess: () => {
          toast.success('The Policy has been created')
          queryClient.invalidateQueries({
            queryKey: ['pechkin', 'list-plan'],
          })
          handleCloseDrawer()
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Error during the policy creation')
        },
      }
    )
  }

  const handleUpdatePlan = (policyFormData: PolicyFormData) => {
    const policyName = policyFormData.policyName
    const schedule = policyFormData.schedule
    const selectedCloudConnectors = policyFormData.selectedCloudConnectors

    const selectedVariant = policyFormData.selectedVariant
    const selectedAssets = policyFormData.selectedAssets
    const selectedS3Assets = policyFormData.selectedS3Assets
    const selectedS3AllPath = policyFormData.selectedS3Assets.map(
      (s3Asset) => s3Asset.asset.id
    )
    const selectedEfsAssets = policyFormData.selectedEfsAssets
    const selectedEfsAllPath = policyFormData.selectedEfsAssets.map(
      (efsAsset) => efsAsset.asset.id
    )

    const integrityScan = policyFormData.integrityScan // Recovery Assurance settings
    const isEntropyDetectionEnabled = policyFormData.isEntropyDetectionEnabled
    const integrityCheck = policyFormData.integrityCheck

    const keepDataCopy = policyFormData.keepDataCopy // EC2 & EBS Settings
    const protectNewImmediately = policyFormData.protectNewImmediately

    const scanWithTimestamps = policyFormData.scanWithTimestamps // S3 Settings & EFS settings

    const policyTags = policyFormData.policyTags

    const snapshotImportVariant = policyFormData.snapshotImportVariant

    const skipEbsBackup = policyFormData.skipEbsBackup

    const executeImmediately = false

    updatePlanMutate(
      {
        policyName,
        schedule,
        selectedCloudConnectors,
        selectedVariant,
        selectedAssets,
        selectedS3Assets,
        selectedS3AllPath,
        selectedEfsAssets,
        selectedEfsAllPath,
        iscan: integrityScan,
        isEntropyDetectionEnabled,
        integrityCheck,
        keepDataCopy,
        protectNewImmediately,
        scanWithTimestamps,
        policyTags,
        executeImmediately,
        skipEbsBackup: skipEbsBackup,
        snapshotImport: snapshotImportVariant,
        integrityScanOption: IntegrityScanOptions.SCAN_WITH_ELASTIO, // 0
      },
      {
        onSuccess: () => {
          toast.success('The Policy has been updated')
          queryClient.invalidateQueries({
            queryKey: ['pechkin', 'list-plan'],
          })
          handleCloseDrawer()
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Error during the policy update')
        },
      }
    )
  }

  const submit = (policyFormData: PolicyFormData) => {
    if (activeStep === steps - 1) {
      if (id) {
        handleUpdatePlan(policyFormData)
      } else {
        handleCreatePlan(policyFormData)
      }
    } else {
      handleNext()
    }
  }

  const handlePolicyDrawerForm = (
    planSelectedAssetsData: PolicyInfoWithSelectedAssets
  ) => {
    const {
      policyName,
      schedule,
      iscan,
      isEntropyDetectionEnabled,
      integrityCheck,
      selectedAssets,
      selectedS3Assets,
      policyTags,
      isType,
      accountRegionSelectorsList,
      selectedEfsAssets,
      keepDataCopy,
      protectNewImmediately,
      scanWithTimestamps,
      snapshotImportVariant,
      skipEbsBackup,
    } = planSelectedAssetsData

    setValue('policyName', policyName)
    setValue('schedule', schedule)
    setValue('selectedCloudConnectors', accountRegionSelectorsList)

    // set up the selected tab
    let selectedVariant = SelectedVariant.AllEC2Variant
    let selectedTab = TabAssetType.Ec2andEbs

    if (policyTags.length > 0 && isType === undefined) {
      selectedVariant = SelectedVariant.AllEC2EBSVariant
    } else if (selectedAssets?.length > 0) {
      selectedVariant = SelectedVariant.AssetsVariant
    } else if (selectedS3Assets?.length !== 0) {
      selectedTab = TabAssetType.S3
      selectedVariant = SelectedVariant.S3Variant
    } else if (selectedEfsAssets?.length !== 0) {
      selectedTab = TabAssetType.EFS
      selectedVariant = SelectedVariant.EfsVariant
    } else if (selectedAssets.length === 0 && policyTags?.length > 0) {
      selectedVariant = SelectedVariant.TagsVariant
    } else if (isType !== undefined) {
      if (isType == AssetType.ASSET_TYPE_EC2) {
        selectedTab = TabAssetType.Ec2andEbs
        selectedVariant = SelectedVariant.AllEC2Variant
      }
      if (isType == AssetType.ASSET_TYPE_EBS) {
        selectedTab = TabAssetType.Ec2andEbs
        selectedVariant = SelectedVariant.AllEBSVariant
      }
      if (isType == AssetType.ASSET_TYPE_EC2_EBS) {
        selectedTab = TabAssetType.Ec2andEbs
        selectedVariant = SelectedVariant.AllEC2EBSVariant
      }
      if (isType == AssetType.ASSET_TYPE_S3) {
        selectedTab = TabAssetType.S3
        selectedVariant = SelectedVariant.AllS3Variant
      }
    }
    setValue('selectedVariant', selectedVariant)
    setValue('tabAssetTypeSelectedNum', selectedTab)
    setValue('selectedAssets', selectedAssets)
    setValue('selectedS3Assets', selectedS3Assets)
    setValue('selectedEfsAssets', selectedEfsAssets)
    setValue('integrityScan', iscan)
    setValue('isEntropyDetectionEnabled', isEntropyDetectionEnabled)
    setValue('integrityCheck', integrityCheck as IntegrityCheckOptions)
    setValue('keepDataCopy', keepDataCopy)
    setValue('protectNewImmediately', protectNewImmediately)
    setValue('scanWithTimestamps', scanWithTimestamps)
    setValue('policyTags', policyTags)
    setValue('snapshotImportVariant', snapshotImportVariant)
    setValue('skipEbsBackup', !!skipEbsBackup)
  }

  useEffect(() => {
    if (planInfoSelectedAssetsData) {
      handlePolicyDrawerForm(planInfoSelectedAssetsData)
    }
  }, [planInfoSelectedAssetsData])

  return (
    <Drawer
      open={!!action}
      onClose={handleCloseDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: activeStep === 0 ? 640 : 1120,
        },
      }}
    >
      <DrawerContent
        isLoading={isPlanSelectedAssetsDataLoading}
        isEmpty={false}
      >
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(submit)}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              p={2.5}
            >
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography variant="h5">
                  {id ? 'Modify' : 'Create'} Policy ({activeStep + 1}/{steps})
                </Typography>
              </Box>
              <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                spacing={2}
              >
                {activeStep > 0 && (
                  <Button type="button" color="inherit" onClick={handleBack}>
                    Back
                  </Button>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  isLoading={createPlanIsPending || updatePlanIsPending}
                >
                  {activeStep !== steps - 1 ? 'Next' : id ? 'Modify' : 'Create'}
                </Button>

                <IconButton
                  edge="end"
                  aria-label="close"
                  onClick={handleCloseDrawer}
                >
                  <Close />
                </IconButton>
              </Stack>
            </Box>

            <Stack
              spacing={2}
              sx={{
                paddingX: 2.5,
                paddingBottom: 2.5,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  padding: 0,
                }}
              >
                {activeStep === 0 && (
                  <NameAndSchedule
                    existingPoliciesNames={existingPoliciesNames}
                  />
                )}
                {activeStep === 1 && <Assets />}
                {activeStep === 2 && <ProtectionOptions />}
                {activeStep === 3 && <ReviewPolicy />}

                {/* <NameAndSchedule
                  existingPoliciesNames={existingPoliciesNames}
                />

                <Assets />
                <ProtectionOptions />
                <ReviewPolicy /> */}
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}
interface PolicyDrawerProps {
  existingPoliciesNames: Array<string>
}
export default PolicyDrawer
