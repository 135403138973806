/* eslint-disable import/no-extraneous-dependencies */
import {
  Typography,
  Box,
  Card,
  IconButton,
  List,
  ListItem,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { base64Encode } from 'ui-v2/src/lib/helpers/string.helper'

const InfoHeader = ({ title, description, tags }: InfoHeaderProps) => {
  const navigate = useNavigate()

  const handleAccountLink = (tag: string) => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE}/${tag}`
    )
  }

  return (
    <Card
      elevation={0}
      sx={{
        padding: '20px 24px',
        marginBottom: 2,
      }}
    >
      <Box display={'flex'} columnGap={1} alignItems={'flex-start'}>
        <IconButton color="primary" sx={{ padding: 0 }}>
          <InfoIcon />
        </IconButton>
        <Box position={'relative'} width={'100%'}>
          <IconButton
            color="inherit"
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography mb={0.5} variant="body1" fontWeight={600}>
            {title}
          </Typography>
          <Typography fontSize={13}>{description}</Typography>
          <List
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              gap: 1,
            }}
          >
            {tags?.map((tag) => (
              <ListItem disablePadding sx={{ width: 'auto' }}>
                <Button
                  onClick={() => handleAccountLink(base64Encode(tag))}
                  variant="contained"
                >
                  {tag}
                </Button>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Card>
  )
}
interface InfoHeaderProps {
  title: string
  description: string
  tags: Array<string>
}
export default InfoHeader
