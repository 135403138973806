/* eslint-disable import/no-extraneous-dependencies */
import { createDisplayColumn } from '@components/table'
import React from 'react'
import LangHelper from '@lib/helpers/lang.helper'
import { EngineCallback } from '@lib/engine-types'
import {
  SelectAllPathsInterface,
  SelectSpecificPathsInterface,
} from '@lib/interfaces/assets-s3-select.interface'
import { AssetWithRelatedAssets } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { S3Bucket } from 'blues-corejs/dist'
import {
  PoliciesCell,
  PolicyLinkCell,
  PolicySelectAllPathsCell,
  PolicySelectPath,
  PolicySelectRowCell,
} from '@features/policies/policy/policy-tables/cells'
import { StyledCheckbox } from '@features/policies/policy/policy-tables/styles'

type InputData = {
  handleS3Select: EngineCallback<{
    assetId: string
    checked: boolean
  }>
  onSelectAllPaths: EngineCallback<SelectAllPathsInterface>
  handleSelectPathsClick: (id: string, rowIndex: number) => void
  selectedAllPaths: Array<string>
  selectedSpecificPaths: Array<SelectSpecificPathsInterface>
  handleSelectAllS3Rows: (isSelected: boolean) => void
}

export function getS3PolicyTableColumnDefinitions({
  handleS3Select,
  onSelectAllPaths,
  handleSelectPathsClick,
  selectedAllPaths,
  selectedSpecificPaths,
  handleSelectAllS3Rows,
}: InputData) {
  return [
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      header: ({ table }) => {
        const isSelected = table.getIsAllRowsSelected()

        const onClick = () => {
          handleSelectAllS3Rows(!isSelected)
        }

        return (
          <StyledCheckbox
            indeterminate={table.getIsSomeRowsSelected()}
            checked={isSelected}
            onChange={table.getToggleAllRowsSelectedHandler()}
            onClick={onClick}
          />
        )
      },
      meta: {
        width: '32',
      },
      id: 'row-selection',
      cell: ({ row }) => (
        <PolicySelectRowCell
          row={row}
          asset={row.original.asset}
          selectedSpecificPaths={selectedSpecificPaths}
          selectedAllPaths={selectedAllPaths}
          handleSelect={handleS3Select}
        />
      ),
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      enableSorting: false,
      header: 'Bucket name',
      id: 'link',
      cell: ({ row }) => <PolicyLinkCell asset={row.original.asset} />,
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      enableSorting: false,
      header: 'Type',
      id: 'assetType',
      cell: 'S3 Bucket',
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      enableSorting: false,
      header: 'Account ID',
      id: 'accountId',
      cell: ({ row }) => row.original.asset.awsAccountId,
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      enableSorting: false,
      header: 'Region',
      id: 'region',
      cell: ({ row }) =>
        LangHelper.getAwsRegionSingleTranslation(row.original.asset.awsRegion),
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      enableSorting: false,
      header: 'Policy',
      id: 'policy',
      cell: ({ row }) => <PoliciesCell asset={row.original.asset} />,
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      header: 'Paths',
      id: 'all-path',
      cell: ({ row }) => (
        <PolicySelectAllPathsCell
          row={row}
          asset={row.original.asset}
          selectedAllPaths={selectedAllPaths}
          onSelectAllPaths={onSelectAllPaths}
        />
      ),
    }),
    createDisplayColumn<AssetWithRelatedAssets<S3Bucket>>({
      id: 'select-path',
      cell: ({ row }) => (
        <PolicySelectPath
          row={row}
          asset={row.original.asset}
          handleSelectPathsClick={handleSelectPathsClick}
        />
      ),
    }),
  ]
}
