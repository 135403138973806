import { GetBackupsByIdsClient } from '@lib/clients/backups/list-backups-by-ids'
import type { BackupById } from '@lib/interfaces/backup'
import SystemHelper from '@lib/helpers/system.helper'
import { useEffect, useState } from 'react'

const backupsByIdsClient = new GetBackupsByIdsClient()

export interface ListBackupsByIdsOutput {
  backupsList: Array<BackupById>
  isBackupsLoading: boolean
}

/**
 * Custom hook to get backups by `backupIds`.
 * @param backupIds - backup ids array
 * @returns list of backups and loading state
 */
export function useFetchBackupsByIds(
  backupIds: Array<string>
): ListBackupsByIdsOutput {
  const [backupsList, setBackupsList] = useState<Array<BackupById>>([])
  const [isBackupsLoading, setIsBackupsLoading] = useState(false)

  useEffect(() => {
    async function fetchBackupsByIds() {
      if (!backupIds.length) {
        return
      }
      setIsBackupsLoading(true)

      try {
        const response = await backupsByIdsClient.getBackupsByIds(backupIds)
        setBackupsList(response.backupsList)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong in ${__filename}: ${error}`
        )
        console.error(
          `Error occurred in file ${__filename} while processing: ${error}`
        )
      } finally {
        setIsBackupsLoading(false)
      }
    }

    fetchBackupsByIds()
  }, [JSON.stringify(backupIds)])

  return {
    backupsList,
    isBackupsLoading,
  }
}
