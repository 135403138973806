import { useEffect, useState } from 'react'
import { useAssetsLiveQuery } from './queries/assets'
import { useUserConfigQuery } from './queries/users'
import { base64DecodeObj } from '../lib/helpers/string.helper'
import { useNavigate } from 'react-router-dom'
import { BASE_ROUTE_SEGMENTS } from '../lib/constants/route-segments.constant'
import { UserSettings } from '../lib/constants/settings.constant'

const useAppInitializer = () => {
  const { status: liveAssetsQueryStatus } = useAssetsLiveQuery()
  const { data: userConfig, status: userConfigQueryStatus } =
    useUserConfigQuery()
  const navigate = useNavigate()

  const [isLoadingTimeout, setIsLoadingTimeout] = useState(false)

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoadingTimeout(true)
    }, 5000)

    const onboardingSetting = !!userConfig
      ? userConfig[UserSettings.OnBoardingCongratulationsShown]
      : {}

    const isOnboardingPageShown = onboardingSetting?.value
      ? base64DecodeObj(onboardingSetting?.value)
      : 0

    if (!isOnboardingPageShown && userConfigQueryStatus === 'success') {
      navigate(`/${BASE_ROUTE_SEGMENTS.ONBOARDING}`, { replace: true })
    }

    return () => clearTimeout(loadingTimer)
  }, [userConfig, userConfigQueryStatus, navigate])

  const isLoading =
    (liveAssetsQueryStatus === 'pending' ||
      userConfigQueryStatus === 'pending') &&
    !isLoadingTimeout

  return {
    isLoading,
  }
}

export default useAppInitializer
