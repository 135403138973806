import React, { memo, ReactNode } from 'react'
import { EngineCallback } from '@lib/engine-types'
import clsx from 'clsx'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Tooltip from '@mui/material/Tooltip'
import RadioTwoCircleSelectedIcon from '@inline-img/inputs/radio-two-circle-selected-icon'
import RadioTwoCircleNotSelectedIcon from '@inline-img/inputs/radio-two-circle-not-selected-icon'
import { TabWithTooltip } from '@lib/interfaces/tab-with-tooltip'
import QuestionHint from '../question-hint/QuestionHint'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

interface TabQuestionHint {
  tabIndex: number
  text: string
}

interface Props {
  currentTabIndex?: number
  onCurrentTabChange?: EngineCallback<number>
  labels: Array<string | React.ReactElement>
  children?: Array<ReactNode>
  mode?: 'default' | 'disableInactive'
  className?: string
  disabled?: boolean
  commonChild?: ReactNode
  tabsLabel?: string
  disabledRadioList?: Array<number>
  tabsTooltips?: Array<TabWithTooltip>
  tabsQuestionHint?: Array<TabQuestionHint>
}

function renderTabLabel({
  lbl,
  tabsQuestionHint,
  tabIndex,
}: {
  lbl: string | React.ReactElement
  tabsQuestionHint: Array<TabQuestionHint>
  tabIndex: number
}) {
  const questionHint = tabsQuestionHint.find(
    (hint) => hint.tabIndex === tabIndex
  )

  if (!questionHint) {
    return lbl
  }

  return (
    <Stack direction="row" spacing={5} component="span">
      {lbl}
      <QuestionHint text={questionHint.text} />
    </Stack>
  )
}

function RadioTabs({
  currentTabIndex = 0,
  onCurrentTabChange,
  labels,
  children = [],
  mode = 'default',
  className = '',
  commonChild = null,
  tabsLabel = '',
  disabledRadioList = [],
  tabsTooltips = [],
  tabsQuestionHint = [],
}: Props) {
  return (
    <Box className={`wrap-1605021859581 ${className}`}>
      <Box
        className={clsx({
          tabsLabelWrap: tabsLabel.length > 0,
        })}
      >
        {tabsLabel.length > 0 && <p className="tabsLabel">{tabsLabel}</p>}

        <RadioGroup value={currentTabIndex}>
          {labels.map((lbl, index) => {
            const tabWithTooltip = tabsTooltips.find(
              (item) => item.tabIndex === index
            )
            const label = renderTabLabel({
              lbl,
              tabsQuestionHint,
              tabIndex: index,
            })

            const formLabel = (
              <FormControlLabel
                key={typeof lbl == 'string' ? lbl : `label${index}`}
                onChange={() => onCurrentTabChange?.(index)}
                checked={index === currentTabIndex}
                value={lbl}
                control={
                  <Radio
                    color="primary"
                    icon={<RadioTwoCircleNotSelectedIcon />}
                    checkedIcon={<RadioTwoCircleSelectedIcon />}
                  />
                }
                label={label}
                className={
                  disabledRadioList.includes(index) ? 'radioTabDisabled' : ''
                }
                data-testid="jsRadioTab"
              />
            )

            return (
              <React.Fragment
                key={typeof lbl == 'string' ? lbl : `fragment${index}`}
              >
                {tabWithTooltip ? (
                  <Tooltip title={tabWithTooltip.tooltip} placement="top">
                    <Box>{formLabel}</Box>
                  </Tooltip>
                ) : (
                  <>{formLabel}</>
                )}
              </React.Fragment>
            )
          })}
        </RadioGroup>
      </Box>

      {commonChild}
      <Box className="radioTabsContent">
        {mode === 'default' && (
          <>
            {React.Children.map(children, (child, index) => (
              <Box
                className="rContent"
                hidden={index !== currentTabIndex}
                key={`rContent${index}`}
              >
                {child}
              </Box>
            ))}
          </>
        )}

        {mode === 'disableInactive' && (
          <>
            {React.Children.map(children, (child, index) => (
              <Box
                className={clsx('rContent', {
                  radioTabDisabled: index !== currentTabIndex,
                })}
                key={`child${index}`}
              >
                {child}
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

export default memo(RadioTabs)
