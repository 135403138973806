/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { VIRow } from '@lib/engine-types'
import { StrHelper } from '@lib/helpers'
import { EBS, EC2, Threat } from 'blues-corejs/dist'

interface Props {
  instance?: EC2
  ebsVolumes: Array<EBS>
  threats: Array<Threat>
}

export function useVolumesData({ instance, ebsVolumes, threats }: Props) {
  const [volumesVIRowList, setVolumesVIRowList] = useState<VIRow>([])

  useEffect(() => {
    const volumes: VIRow = []

    if (!instance || volumes.length > 0) {
      return
    }

    const instanceVolumes = ebsVolumes.filter((volume) =>
      instance.ebsIds.includes(volume.id)
    )

    for (const volume of instanceVolumes) {
      const assetName = volume.name ? `(${instance.name})` : ''
      const isInfectedVolume = threats.some(
        ({ source }) =>
          source.asset?.assetId === volume.id ||
          source.assetItem?.assetId === volume.id
      )

      volumes.push({
        name: volume.awsId,
        value: true,
        label: `${volume.awsId} ${assetName}`,
        extraValue: StrHelper.realFileSize(volume.size),
        supplementalValue: isInfectedVolume ? 'error' : '',
      })
    }

    setVolumesVIRowList(volumes)

    return () => setVolumesVIRowList([])
  }, [JSON.stringify(instance)])

  const someVolumeSelected = volumesVIRowList.some(
    (element) => element.value // element.value means checkbox is checked
  )

  return {
    volumesVIRowList,
    setVolumesVIRowList,
    someVolumeSelected,
  }
}
