export const UNCOVERED_FILTERS = [
  {
    extraValue: 'Policy:',
    name: 'covered',
    label: 'Covered',
    type: 1,
    value: '',
    options: [
      {
        type: 1,
        name: 'false',
        label: 'Uncovered',
        value: true,
      },
    ],
  },
]

export const NO_BACKUPS_ON_SCHEDULE = [
  {
    extraValue: 'Backups on schedule:',
    name: 'backupsOnSchedule',
    label: 'Backups on schedule"',
    placeholder: 'second row',
    type: 1,
    value: '',
    options: [
      {
        type: 1,
        name: 'true',
        label: 'No',
        value: true,
      },
    ],
  },
]

export const BACKUPS_ON_SCHEDULE = [
  {
    extraValue: 'Backups on schedule:',
    name: 'backupsOnSchedule',
    label: 'Backups on schedule"',
    placeholder: 'second row',
    type: 1,
    value: '',
    options: [
      {
        type: 1,
        name: 'false',
        label: 'Yes',
        value: true,
      },
    ],
  },
]
