import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { HeaderGroupsTableOwnProps } from '@components/table/core/header/types'
import HeaderCell from './header-cell'

function HeaderGroupsTable<T>({
  headerGroups = [],
}: HeaderGroupsTableOwnProps<T>) {
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => {
        return (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              if (header.column.columnDef.meta?.hidden) {
                return null
              }

              return <HeaderCell key={header.id + index} header={header} />
            })}
          </TableRow>
        )
      })}
    </TableHead>
  )
}

export default HeaderGroupsTable
