/* eslint-disable import/no-extraneous-dependencies */
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import React, { Fragment } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import {
  RansomwareScanDetails,
  MalwareScanDetails,
  FsCheckScanDetails,
} from '@features/asset-page/shared'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type Props = {
  scans: Array<Scan>
}

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  background: theme.palette.grey[50],
  borderRadius: '12px',
}))

function renderRansomwareScanDetails(scans: Array<RansomwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <RansomwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderMalwareScanDetails(scans: Array<MalwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <MalwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderFsCheckScanDetails(scans: Array<FilesystemScanCheck>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <FsCheckScanDetails scans={scans} />
    </Grid>
  )
}

function renderScansDetails(scans: Array<Scan>) {
  const ransomwareScan = scans.filter(
    (scan) => scan instanceof RansomwareScan
  ) as Array<RansomwareScan>
  const malwareScan = scans.filter(
    (scan) => scan instanceof MalwareScan
  ) as Array<MalwareScan>
  const fsCheckScan = scans.filter(
    (scan) => scan instanceof FilesystemScanCheck
  ) as Array<FilesystemScanCheck>

  return (
    <Grid
      container
      marginTop="16px"
      columnGap="15px"
      fontSize="14px"
      paddingX="16px"
      flexWrap="nowrap"
    >
      {renderRansomwareScanDetails(ransomwareScan)}
      {renderMalwareScanDetails(malwareScan)}
      {renderFsCheckScanDetails(fsCheckScan)}
    </Grid>
  )
}

function MostRecentScan({ scans }: Props) {
  if (scans.length === 0) {
    sentryReThrowCatchHandler(
      'Scans array is empty for MostRecentScan component'
    )
    return null
  }

  return (
    <Fragment>
      <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
      <StyledHeader>
        <Typography fontWeight="600">Most recent scan</Typography>
      </StyledHeader>
      {renderScansDetails(scans)}
    </Fragment>
  )
}

export { MostRecentScan }
