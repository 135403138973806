import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { CloudConfigureUpgrade, CloudConfigureDeployment } from '@features'
import { DeploymentRoutes } from './deployment'

export const deploymentRoutes: Array<PathRouteProps> = [
  {
    path: DeploymentRoutes.cloudConfigureDeployment,
    element: (
      <MainTopBarLayout title="Cloud Configure Deployment">
        <CloudConfigureDeployment />
      </MainTopBarLayout>
    ),
  },
  {
    path: DeploymentRoutes.cloudConfigureUpgrade,
    element: (
      <MainTopBarLayout title="Cloud Configure Upgrade">
        <CloudConfigureUpgrade />
      </MainTopBarLayout>
    ),
  },
]
