import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import PaginationBlock from '@components-simple/pagination-block/PaginationBlock'
import React from 'react'
import PaginationModel from '@lib/models/pagination.model'
import { EngineCallback } from '@lib/engine-types'

interface Props {
  pagination?: PaginationModel
  onPaginationChange?: EngineCallback<PaginationModel>
  isFinalLoading: boolean
  disabled: boolean
}

function FilterPagination({
  pagination,
  onPaginationChange,
  isFinalLoading,
  disabled,
}: Props) {
  if (!pagination || !onPaginationChange) {
    return null
  }

  if (isFinalLoading) {
    return (
      <div className="pr50">
        <PreloaderSmall top={7} right={7} show={isFinalLoading} />
      </div>
    )
  }

  return (
    <div className="paginationWrap">
      <PaginationBlock
        disabled={disabled}
        pagination={pagination}
        onChange={onPaginationChange}
      />
    </div>
  )
}

export default FilterPagination
