/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { getSelectedFilters } from '..'
import { fetchInitialJobsList, fetchJobsListViaPagination } from './actions'
import { getInitialState } from './consts'
import { SystemJobsStore } from '.'

export const useSystemJobsStore = create<SystemJobsStore>((set, get) => ({
  ...getInitialState(),
  actions: {
    resetSystemJobsState: () => set(getInitialState()),
    setSystemJobsList: (systemJobsList) => set({ systemJobsList }),
    fetchInitialSystemJobs: async () => {
      const { selectedFilters, timeRange } = getSelectedFilters()

      set({
        loadingState: 'loading',
        systemJobsList: [],
      })

      const { jobsList, pageToken } = await fetchInitialJobsList(
        selectedFilters,
        timeRange
      )

      set({
        systemJobsList: jobsList,
        pageToken,
        loadingState: 'idle',
      })
    },
    fetchPaginatedSystemJobs: async () => {
      const { pageToken } = get()

      if (!pageToken) {
        return
      }

      set({ loadingState: 'loading' })

      const systemJobsResponse = await fetchJobsListViaPagination(pageToken)

      set((state) => ({
        systemJobsList: [
          ...state.systemJobsList,
          ...systemJobsResponse.jobsList,
        ],
        pageToken: systemJobsResponse.pageToken,
        loadingState: 'idle',
      }))
    },
  },
}))
