import React, { memo } from 'react'
import InfoHeader from '@components-composite/info-header/InfoHeader'
import TextPopover from '@components-simple/text-popover/TextPopover'
import { TextConstant } from '@lib/constants'
import {
  INFO_HEADER_BUTTONS,
  MOUNT_DESCRIPTION_PART_1,
  MOUNT_DESCRIPTION_PART_2,
  TEXT_POPOVER_PROPS,
} from './consts'

function CliPopoverBlock() {
  return (
    <>
      <InfoHeader
        className="infoHeaderV2"
        type="warning"
        description={TextConstant.MOUNT_WARNING}
        buttons={INFO_HEADER_BUTTONS}
      />
      <div className="mcDescription jsDesc" aria-label="jsDesc">
        {MOUNT_DESCRIPTION_PART_1} <TextPopover {...TEXT_POPOVER_PROPS} />{' '}
        {MOUNT_DESCRIPTION_PART_2}
      </div>
    </>
  )
}

export default memo(CliPopoverBlock)
