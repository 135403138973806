import React, { memo } from 'react'
import RadioTabs from '@components-simple/radio-tabs/RadioTabs'
import { SelectedCloudConnectorVariant } from '@lib/constants/policies.constant'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import { EngineCallback, VIRow } from '@lib/engine-types'
import clsx from 'clsx'
import WarningMessage from '@components-simple/messages/warning-message/WarningMessage'
import IconPopover from '@components-simple/icon-popover/IconPopover'
import Box from '@mui/material/Box'

interface Props {
  selectedVariant: SelectedCloudConnectorVariant
  selectedCloudConnectors: VIRow
  possibleCloudConnectors: VIRow
  onCloudConnectorVariantChange: EngineCallback<SelectedCloudConnectorVariant>
  onSelectCloudConnectors: EngineCallback<VIRow>
  loading: boolean
}

const POLICY_SCOPE_TITLE = 'Select Cloud Connectors'
const POLICY_SCOPE_INFO_HEADER = 'What is the Cloud Connector?'
const POLICY_SCOPE_INFO_MESSAGE =
  'The Cloud Connector is a lightweight command-and-control channel between Elastio tenants and customer AWS accounts, built on secured AWS SQS queues and Lambda functions. It enables Elastio to provide incident response, monitor activity, and present information in dashboards and reports.'
const POLICY_SCOPE_LABEL =
  'Choose the appropriate Elastio Cloud Connectors to specify the accounts and regions where the policy will be applied.'

function ChooseAccountRegionStep({
  selectedVariant,
  selectedCloudConnectors,
  possibleCloudConnectors,
  onCloudConnectorVariantChange,
  onSelectCloudConnectors,
  loading,
}: Props) {
  const onTabChange = (newTabIndex: number) => {
    onCloudConnectorVariantChange(newTabIndex)
  }

  const onSelectInnerChange = (value: VIRow) => {
    onSelectCloudConnectors(value)
  }

  const isDisabled = loading || !possibleCloudConnectors.length

  return (
    <Box className="wrap-1689667589975 jsSelectAccountRegion">
      <Box className="addPolicyTitle">
        {POLICY_SCOPE_TITLE}
        <IconPopover
          variant="info"
          header={POLICY_SCOPE_INFO_HEADER}
          message={POLICY_SCOPE_INFO_MESSAGE}
        />
      </Box>
      <Box className="policiesBlockLabel">{POLICY_SCOPE_LABEL}</Box>
      <RadioTabs
        currentTabIndex={selectedVariant}
        onCurrentTabChange={onTabChange}
        labels={['Apply to All Cloud Connectors', 'Choose Cloud Connectors']}
        mode="disableInactive"
        className={clsx(
          'jsSelectAccountRegionRadioTabs selectAccountRegionRadioTabs',
          {
            radioTabDisabled: isDisabled,
          }
        )}
      >
        <Box className="emptyFirstTab" />
        <Box>
          <ViMultipleSelect
            className="selectedValue placeholderAll jsSelectAccountRegionSelect"
            disabled={loading}
            possible={possibleCloudConnectors}
            selected={selectedCloudConnectors}
            onChange={(value) => onSelectInnerChange(value)}
            placeholder="All Cloud Connectors"
            countLabel="Cloud Connectors selected"
            key="selectCloudConnectors"
          />
        </Box>
      </RadioTabs>
      {!possibleCloudConnectors.length && (
        <WarningMessage message="You don't have any active Cloud Connectors" />
      )}
    </Box>
  )
}

export default memo(ChooseAccountRegionStep)
