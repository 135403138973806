import { StoreReturnType } from '@store/store'

export const getCloudConnectorBackupTimelineStats =
  (redStackId: string) => (state: StoreReturnType) => {
    const currentStats = state.sourceVaultsState.backupTimelineStats[redStackId]
    const allValues =
      currentStats?.reduce(
        (accum, currentPoint) =>
          accum +
          currentPoint.aborted +
          currentPoint.succeeded +
          currentPoint.failed +
          currentPoint.inProgress,
        0
      ) || 0

    if (allValues === 0) {
      return []
    }
    return currentStats ?? []
  }
