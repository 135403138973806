/* eslint-disable import/no-extraneous-dependencies */
import {
  EbsVulnerabilityKind,
  SnapshotVulnerabilitiesTypeName,
} from 'blues-corejs/dist/models'
import { DashboardRoutes, VulnerabilitiesTabs } from '@lib/router'

export function renderVulnerabilityName(id: string): {
  name: string
  drillDownLink: string
} {
  switch (id) {
    case SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS:
      return {
        name: SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.SNAPSHOTS,
          {
            vulnerability: SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS,
          }
        ),
      }
    case SnapshotVulnerabilitiesTypeName.UNENCRYPTED:
      return {
        name: SnapshotVulnerabilitiesTypeName.UNENCRYPTED,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.SNAPSHOTS,
          {
            vulnerability: SnapshotVulnerabilitiesTypeName.UNENCRYPTED,
          }
        ),
      }
    case EbsVulnerabilityKind.NOT_USING_CMK:
      return {
        name: EbsVulnerabilityKind.NOT_USING_CMK,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.VOLUMES,
          {
            vulnerability: EbsVulnerabilityKind.NOT_USING_CMK,
          }
        ),
      }
    case EbsVulnerabilityKind.UNENCRYPTED:
      return {
        name: EbsVulnerabilityKind.UNENCRYPTED,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.VOLUMES,
          {
            vulnerability: EbsVulnerabilityKind.UNENCRYPTED,
          }
        ),
      }
    case EbsVulnerabilityKind.UNATTACHED:
      return {
        name: EbsVulnerabilityKind.UNATTACHED,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.VOLUMES,
          {
            vulnerability: EbsVulnerabilityKind.UNATTACHED,
          }
        ),
      }
    case EbsVulnerabilityKind.NOT_CYBER_SCANNED:
      return {
        name: EbsVulnerabilityKind.NOT_CYBER_SCANNED,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.VOLUMES,
          {
            vulnerability: EbsVulnerabilityKind.NOT_CYBER_SCANNED,
          }
        ),
      }

    case EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS:
      return {
        name: EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.VOLUMES,
          {
            vulnerability: EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS,
          }
        ),
      }

    case EbsVulnerabilityKind.NO_BACKUPS:
      return {
        name: EbsVulnerabilityKind.NO_BACKUPS,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesWithParams(
          VulnerabilitiesTabs.VOLUMES,
          {
            vulnerability: EbsVulnerabilityKind.NO_BACKUPS,
          }
        ),
      }

    default:
      return {
        name: id,
        drillDownLink: DashboardRoutes.assetsVulnerabilitiesTableRoute(
          String(VulnerabilitiesTabs.VOLUMES)
        ),
      }
  }
}
