import React, { FC } from 'react'
import { useCurrentViewKind } from '@features/management-view'
import { EditManagementView } from '@features/management-view/edit'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import { BackupConnectorForm } from '@features/management-view/backup-connector/form'
import { ViewKind } from '@features/DashboardV4/management-view'

const views = new Map<ViewKind, FC>([
  [ViewKind.AWS, EditManagementView],
  [ViewKind.OVA, BackupConnectorForm],
])

// TODO: Breadcrumbs is broken for pages like /path/[id].tsx, create task for fix it, cause its bad practice to use it like this
function EditView() {
  const currentViewKind = useCurrentViewKind()

  const Component = views.get(currentViewKind)

  if (!Component) {
    return (
      <Box mt={2}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          The view kind{' '}
          <Typography component="span" variant="subtitle2" fontWeight="bold">
            {currentViewKind}
          </Typography>{' '}
          is invalid. Please try a different view.
        </Alert>
      </Box>
    )
  }

  return (
    <Box className="innerContent innerContentBlueBackground">
      <Component />
    </Box>
  )
}

export default EditView
