import PolicyModel from '@lib/models/policy.model'

export const getPolicies = (state: any): Array<PolicyModel> =>
  state.policiesState.policies

export const getPoliciesNames = (state: any): Array<string> =>
  state.policiesState.policies.map((p: PolicyModel) => p.policyName)

export const getPoliciesCount = (state: any): number =>
  state.policiesState.policiesCount
