"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsOvaSnapshotsWithThreats = void 0;
const types_1 = require("../../models/threats/types");
class ListDetailsOvaSnapshotsWithThreats {
    #snapshots;
    #threats;
    constructor(snapshots, threats) {
        this.#snapshots = snapshots;
        this.#threats = threats;
    }
    execute(filters) {
        return this.#prepareOutput(this.#findSnapshotMatchingFilters(filters));
    }
    #prepareOutput(snapshots) {
        const snapshotsOutput = snapshots.map((snapshot) => ({
            backupProviderVolumeId: snapshot.backupProviderVolumeId,
            ovaVolumeId: snapshot.ovaVolumeId,
            threats: this.#getThreatsForSnapshot(snapshot.ovaVolumeId),
            firstDetectedAt: this.#getFirstDetectedAtForSnapshot(snapshot.ovaVolumeId),
            id: snapshot.id,
        }));
        const possibleFilters = this.#getSnapshotPossibleFilters();
        return {
            ovaSnapshotsData: snapshotsOutput,
            possibleFilters,
        };
    }
    #getThreatsForSnapshot(ovaVolumeId) {
        return this.#threats.filter((threat) => {
            return threat.source.assetItem?.assetItemId === ovaVolumeId;
        });
    }
    #getFirstDetectedAtForSnapshot(ovaVolumeId) {
        const threatsForVolume = this.#getThreatsForSnapshot(ovaVolumeId);
        if (threatsForVolume.length === 0) {
            return null;
        }
        const firstDetectedAt = threatsForVolume.reduce((minDetected, threat) => Math.min(minDetected, threat.firstDetectedAt.getTime()), 0);
        return new Date(firstDetectedAt);
    }
    #getSnapshotPossibleFilters() {
        const ransomware = new Set();
        const malware = new Set();
        this.#snapshots.forEach((snapshot) => {
            const threatsForVolume = this.#getThreatsForSnapshot(snapshot.ovaVolumeId);
            threatsForVolume.forEach((threat) => {
                if (threat.kind === types_1.ThreatKind.RANSOMWARE) {
                    ransomware.add(threat.name);
                }
                if (threat.kind === types_1.ThreatKind.MALWARE) {
                    malware.add(threat.name);
                }
            });
        });
        return {
            ransomware: [...ransomware],
            malware: [...malware],
        };
    }
    #findSnapshotMatchingFilters(filters) {
        return this.#snapshots.filter((snapshot) => {
            const threatsForSnapshot = this.#getThreatsForSnapshot(snapshot.ovaVolumeId);
            if (filters?.ransomware &&
                !threatsForSnapshot.some((threat) => filters.ransomware?.includes(threat.name))) {
                return false;
            }
            if (filters?.malware &&
                !threatsForSnapshot.some((threat) => filters.malware?.includes(threat.name))) {
                return false;
            }
            return true;
        });
    }
}
exports.ListDetailsOvaSnapshotsWithThreats = ListDetailsOvaSnapshotsWithThreats;
