import { useState, useEffect, useCallback } from 'react'
import { UsersClient } from '@lib/clients'
// eslint-disable-next-line import/no-extraneous-dependencies
import { UserProfile } from 'blues-corejs/dist/models'

interface UseUsersListResult {
  users: Array<UserProfile>
  isLoading: boolean
  error: string | null
  fetchUsers: () => Promise<void>
  getActiveUserIds: () => Array<string>
  activeUsers: Array<UserProfile>
}

const usersClient = new UsersClient()

export function useUsersList(): UseUsersListResult {
  const [users, setUsers] = useState<Array<UserProfile>>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchUsers = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      const fetchedUsers = await usersClient.getList()
      setUsers(fetchedUsers)
    } catch (err) {
      setError('Failed to fetch users')
      console.error('Error fetching users:', err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [])

  const getActiveUserIds = useCallback((): Array<string> => {
    return users
      .filter((user) => user.status === 'active')
      .map((user) => user.id)
  }, [users])

  const activeUsers = users.filter((user) => user.status === 'active')
  return {
    users,
    isLoading,
    error,
    fetchUsers,
    getActiveUserIds,
    activeUsers,
  }
}
