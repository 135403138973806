import React from 'react'

function CheckMarkIcon() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99991 10.6701L1.82991 6.50009L0.409912 7.91009L5.99991 13.5001L17.9999 1.50009L16.5899 0.0900879L5.99991 10.6701Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CheckMarkIcon
