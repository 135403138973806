/* eslint-disable import/no-extraneous-dependencies */
import {
  getLastScanRun,
  TabsIds,
  useAssetStore,
} from '@features/asset-page/store'
import { styled } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import React from 'react'

import { BadgeStatusColor, StyledCircleIcon } from './shared'
import { ScansRun } from 'blues-corejs/dist/models'

const TabLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
})

function getBadgeStatusColorForScansTab(scans: ScansRun): BadgeStatusColor {
  const allScans = [
    ...scans.malwareScansList,
    ...scans.ransomwareScansList,
    ...scans.filesystemChecksList,
  ]

  if (allScans.some((scan) => scan.isInfected)) {
    return BadgeStatusColor.BAD
  }

  if (allScans.some((scan) => scan.isClean)) {
    return BadgeStatusColor.GOOD
  }
  return BadgeStatusColor.UNCHECKED
}

function ScansTab({
  onTabChange,
}: {
  onTabChange: (tabIndex: TabsIds) => void
}) {
  const lastScan = useAssetStore(getLastScanRun)

  const color = getBadgeStatusColorForScansTab(lastScan)

  function handleTabChange() {
    onTabChange(TabsIds.SCANS)
  }

  return (
    <Tab
      label={<TabLabel variant="caption">Scans</TabLabel>}
      value={TabsIds.SCANS}
      iconPosition="end"
      onClick={handleTabChange}
      icon={
        <StyledCircleIcon
          sx={{
            color,
          }}
        />
      }
    />
  )
}

export default ScansTab
