/* eslint-disable import/no-extraneous-dependencies */
import {
  createColumn,
  StatusCircle,
  CellWithDateTimeTooltip,
  CellWithLink,
  HeaderWithLabel,
  createDisplayColumn,
  CopyCell,
  sortDate,
  CellLongTextWithTooltip,
} from '@components/table'
import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale/shared'
import { StrHelper } from '@lib/helpers'
import LangHelper from '@lib/helpers/lang.helper'
import TimeHelper from '@lib/helpers/time.helper'

import React from 'react'
import {
  InventoryInstanceOutput,
  SummarizedInstancesDataOutput,
  BackupType,
} from 'blues-corejs/dist/use_cases/list_inventory_instances/types'
import { StyledCheckbox } from './styles'
import { EC2State } from 'blues-corejs/dist'
import { Nullable } from '@lib/engine-types'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { customColorResolver } from '@components-complex/dashboard-pages-v3/utils'
import { AssetRoutes } from '@lib/router'

export const RPO_COLUMN_NAME = 'rpo'
export const RPO_TARGET_COLUMN_NAME = 'sla'
export const RETENTION_COLUMN_NAME = 'maxBackupRetention'

type InputData = {
  summarizedData: SummarizedInstancesDataOutput
  aliasNamesWithId: AliasName
}

// TODO Inline Functions in Render
export function getInstancesTableColumnDefinitions({
  summarizedData,
  aliasNamesWithId,
}: InputData) {
  return [
    createDisplayColumn<InventoryInstanceOutput>({
      header: ({ table }) => (
        <StyledCheckbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      size: 60,
      id: 'row-selection',
      cell: ({ row }) => {
        return (
          <StyledCheckbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      },
    }),
    createColumn<InventoryInstanceOutput>('isInfected', {
      enableSorting: false,
      cell: ({ row, getValue }) => (
        <StatusCircle
          colorResolver={customColorResolver(row.original.lastScan)}
          isHealthy={!getValue() as unknown as boolean}
        />
      ),
      size: 45,
    }),
    createColumn<InventoryInstanceOutput>('instanceId', {
      header: () => (
        <HeaderWithLabel
          title="Instance"
          label={String(summarizedData.instancesCount)}
        />
      ),
      sortingFn: (a, b) => {
        const instanceA = a.original
        const instanceB = b.original

        const instanceAName = instanceA.instanceName || instanceA.instanceId
        const instanceBName = instanceB.instanceName || instanceB.instanceId

        return instanceAName.localeCompare(instanceBName)
      },
      cell: ({ getValue, row }) => {
        const instanceName = row.original.instanceName
        const instanceId = getValue() as unknown as string

        const href = AssetRoutes.assetId(row.original.uuid)
        const displayedValue = instanceName || instanceId
        const displayedLabel = instanceName ? instanceId : instanceName

        return (
          <CellWithLink
            href={href}
            value={displayedValue}
            label={displayedLabel}
          />
        )
      },
    }),
    createColumn<InventoryInstanceOutput>('accountId', {
      enableSorting: false,
      header: () => (
        <HeaderWithLabel
          title="Account"
          label={String(summarizedData.accountsCount)}
        />
      ),
      cell: ({ getValue }) => {
        const className = aliasNamesWithId[getValue()] ? 'copyRow' : ''

        return (
          <CopyCell
            additionalText={aliasNamesWithId[getValue()]}
            copyText={getValue()}
            className={className}
          />
        )
      },
    }),
    createColumn<InventoryInstanceOutput>('region', {
      header: () => (
        <HeaderWithLabel
          title="Region"
          label={String(summarizedData.regionsCount)}
        />
      ),
      cell: ({ getValue }) => {
        return LangHelper.getAwsRegionSingleTranslation(getValue())
      },
    }),
    createColumn<InventoryInstanceOutput>('maxBackupRetention', {
      header: 'Retention',
      cell: ({ getValue }) => {
        const formattedRetention = convertMillisecondsWithLabel(
          getValue() as unknown as number
        )

        const { label, value } = formattedRetention

        return value ? `${value} ${label}` : ''
      },
    }),
    createColumn<InventoryInstanceOutput>('lastBackup', {
      header: 'Last Backup',
      sortingFn: (a, b) =>
        sortDate(
          a.original.lastBackup?.timestamp,
          b.original.lastBackup?.timestamp
        ),
      cell: ({ getValue }) => {
        const lastBackup = getValue() as unknown as Nullable<BackupType>
        return <CellWithDateTimeTooltip date={lastBackup?.timestamp} />
      },
    }),

    createColumn<InventoryInstanceOutput>('rpo', {
      header: 'RPO',
      cell: ({ getValue }) => {
        const rpo = getValue() as unknown as Nullable<number>

        if (!rpo) {
          return null
        }

        return TimeHelper.timeAgo(rpo as unknown as number)
      },
    }),

    createColumn<InventoryInstanceOutput>('sla', {
      header: 'RPO Target',
      cell: ({ getValue }) => {
        const sla = getValue() as unknown as Nullable<number>

        if (!sla) {
          return null
        }

        return TimeHelper.timeAgo(sla * 1000)
      },
    }),
    createColumn<InventoryInstanceOutput>('lastScan', {
      header: 'Last scan',
      cell: ({ getValue }) => {
        const lastScan = getValue() as unknown as Nullable<Date>

        return <CellWithDateTimeTooltip date={lastScan} />
      },
    }),

    createColumn<InventoryInstanceOutput>('instanceState', {
      header: () => (
        <HeaderWithLabel
          title="Status"
          label={String(summarizedData.statusesCount)}
        />
      ),
      cell: ({ getValue }) => {
        const state = getValue() as unknown as EC2State

        return StrHelper.getEc2StateName(state)
      },
    }),

    createColumn<InventoryInstanceOutput>('coveredByPolicesList', {
      header: 'Policy Coverage',
      cell: ({ getValue }) => {
        const policiesList = getValue() as unknown as Array<string>

        return <CellLongTextWithTooltip title={policiesList.join(', ')} />
      },
    }),
  ]
}
