import { Box } from '@mui/material'
import React from 'react'
import { WidgetHeader } from '../shared'
import { Container, StyledDivider } from './styles'
import { CleanServers, InfectedServers, TotalServers } from './components'
import { useFetchAssetsScanned } from './use-fetch-assets-scanned'

/**
 * Assets Scanned widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19346-168406&mode=design&t=tTaLokhszBTO63v3-4)
 */
export default function AssetsScanned() {
  const { isLoading, cleanServers, infectedServers, totalServers } =
    useFetchAssetsScanned()

  return (
    <Container>
      <WidgetHeader widgetTitle="Assets Scanned" />
      <TotalServers isLoading={isLoading} totalServersCount={totalServers} />
      <StyledDivider />
      <Box display="flex">
        <InfectedServers
          isLoading={isLoading}
          infectedServersCount={infectedServers}
        />
        <CleanServers isLoading={isLoading} cleanServersCount={cleanServers} />
      </Box>
    </Container>
  )
}
