import { useQuery } from '@tanstack/react-query'
import { ListBackupsForAssetFilters } from 'ui-v2/src/lib/models/backups'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { BackupsClient } from 'ui-v2/src/lib/clients'

interface Options {
  enabled?: boolean
}

export function useAssetsListBackupsQuery(
  filters: ListBackupsForAssetFilters,
  options?: Options
) {
  const backupsClient = useGrpcClient(BackupsClient)

  return useQuery({
    queryKey: ['backups', 'list', filters],
    queryFn: async () => {
      if (!backupsClient) {
        throw new Error('Backups client not initialized')
      }
      return backupsClient.listBackupsForAsset(filters)
    },
    enabled:
      !!backupsClient &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
