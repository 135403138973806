/* eslint-disable import/no-extraneous-dependencies */
import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'
import { EfsWithActiveThreatsPossibleFilters } from 'blues-corejs/dist/use_cases/list_efs_with_threats_possible_filters/types'
import { S3BucketsWithActiveThreatsPossibleFilters } from 'blues-corejs/dist/use_cases/list_s3buckets_with_threats_possible_filters/types'
import {
  BackupVariant,
  InstancesOutput,
  ListVolumesWithThreatsOutput,
} from 'blues-corejs/dist/use_cases/list_volumes_with_threats/types'
import { VolumesWithActiveThreatsPossibleFilters } from 'blues-corejs/dist/use_cases/list_volumes_with_threats_possible_filters/types'

export const emptyDataEbsEc2Attrs = {
  instances: [],
  volumes: [],
  lastBackups: new Map<string, BackupVariant>(),
  threats: [],
  lastScans: [],
}

export const emptyDataEfsAttrs = {
  filesystems: [],
  threats: [],
  lastScans: [],
}

export const emptyDataS3Attrs = {
  s3buckets: [],
  threats: [],
  lastBackups: new Map<string, BackupVariant>(),
  lastScans: [],
}

export function getDataForPossibleS3BucketsWithActiveThreatsFilters(
  possibleS3BucketsWithActiveThreatsFilters: S3BucketsWithActiveThreatsPossibleFilters
) {
  return [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: possibleS3BucketsWithActiveThreatsFilters.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: possibleS3BucketsWithActiveThreatsFilters.regions,
    },
    {
      id: FilterIdDashboard.MALWARES,
      malwares: possibleS3BucketsWithActiveThreatsFilters.malwaresList,
    },
  ]
}

export function getDataForPossibleEfsWithActiveThreatsFilters(
  possibleEfsWithActiveThreatsFilters: EfsWithActiveThreatsPossibleFilters
) {
  return [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: possibleEfsWithActiveThreatsFilters.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: possibleEfsWithActiveThreatsFilters.regions,
    },
    {
      id: FilterIdDashboard.MALWARES,
      malwares: possibleEfsWithActiveThreatsFilters.malwaresList,
    },
    {
      id: FilterIdDashboard.RANSOMWARES,
      ransomwares: possibleEfsWithActiveThreatsFilters.ransomwaresList,
    },
  ]
}

export function getDataForPossibleVolumesWithActiveThreatsFilters(
  possibleVolumesWithActiveThreatsFilters: VolumesWithActiveThreatsPossibleFilters
) {
  return [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: possibleVolumesWithActiveThreatsFilters.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: possibleVolumesWithActiveThreatsFilters.regions,
    },
    {
      id: FilterIdDashboard.MALWARES,
      malwares: possibleVolumesWithActiveThreatsFilters.malwaresList,
    },
    {
      id: FilterIdDashboard.RANSOMWARES,
      ransomwares: possibleVolumesWithActiveThreatsFilters.ransomwaresList,
    },
    {
      id: FilterIdDashboard.FS_CHECK,
      fsCheck: possibleVolumesWithActiveThreatsFilters.fsCheck,
    },
  ]
}

export function getUniqueInstances(
  instancesList: Array<ListVolumesWithThreatsOutput>
): Array<InstancesOutput> {
  return Array.from(
    instancesList
      .flatMap(({ instances }) => instances)
      .reduce(
        (acc, instance) => acc.set(instance.instanceId, instance),
        new Map()
      )
      .values()
  )
}
