import { Button } from '@mui/material'
import React, { memo, ReactNode } from 'react'

interface Props {
  href?: string
  text?: string
  variant?: 'default' | 'button' | 'buttonIcon'
  icon?: ReactNode
}

function DocsLink({
  href = 'https://docs.elastio.com/',
  text = 'Docs',
  variant = 'default',
  icon,
}: Props) {
  return (
    <>
      {'default' === variant && (
        <a
          target="_blank"
          rel="noreferrer"
          href={href}
          className="wrap-1640345585469 blueLink"
        >
          {text}
        </a>
      )}

      {'button' === variant && (
        <Button
          type="button"
          target="_blank"
          rel="noreferrer"
          href={href}
          variant="contained"
          color="primary"
          className="wrap-1640345585469 sizeS jsButtonDocsLink"
        >
          {text}
        </Button>
      )}

      {'buttonIcon' === variant && (
        <Button
          type="button"
          target="_blank"
          rel="noreferrer"
          href={href}
          variant="contained"
          color="primary"
          className="wrap-1640345585469 sizeS jsButtonIconDocLink"
        >
          <div className="mr6 mt5">{icon}</div>
          {text}
        </Button>
      )}
    </>
  )
}

export default memo(DocsLink)
