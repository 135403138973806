import { useThreatsByIds } from '@components-complex/dashboard-pages-v3'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

export function RecoveryPointThreatsCell({
  threatsIds,
}: {
  threatsIds: Array<string>
}) {
  const { dataThreats, isLoadingThreats } = useThreatsByIds(threatsIds)

  const threatNames = useMemo(
    () => [...new Set(dataThreats.map((threat) => threat.name))],
    [dataThreats.length]
  )

  if (isLoadingThreats) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />
  }

  return (
    <Typography variant="caption" fontSize="14px">
      {threatNames.join(', ')}
    </Typography>
  )
}
