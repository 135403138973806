/* eslint-disable import/no-extraneous-dependencies */
import {
  CellWithDateTimeTooltip,
  createColumn,
  createDisplayColumn,
  StatusCircle,
} from '@components/table'
import React from 'react'
import { StyledCheckbox } from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/styles'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import AlertsCellActions from '@features/alerts/alerts-table/cells/alerts-cell-actions'
import {
  NotificationRule,
  RuleStatus,
} from 'blues-corejs/dist/models/notifications/rule'
import AlertNameCell from '@features/alerts/alerts-table/cells/alert-open-details-cell'

export function getAlertsColumnDefinitions() {
  return [
    createDisplayColumn<NotificationRule>({
      id: 'row-selection',
      meta: {
        width: '50px',
      },
      header: ({ table }) => (
        <StyledCheckbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => {
        return (
          <StyledCheckbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      },
    }),
    createColumn<NotificationRule>('status', {
      enableSorting: false,
      meta: {
        width: '40px',
      },
      cell: ({ row }) => {
        const { status } = row.original
        const value = status === RuleStatus.enabled
        return <StatusCircle isHealthy={value} />
      },
      size: 20,
    }),
    createColumn<NotificationRule>('name', {
      header: 'Rule',
      enableSorting: false,
      meta: {
        width: '10%',
      },
      cell: ({ row }) => {
        const rule = row.original
        return <AlertNameCell rule={rule} />
      },
      size: 120,
    }),
    createColumn<NotificationRule>('description', {
      header: 'Description',
      meta: {
        width: '30%',
      },
      enableSorting: false,
      cell: ({ getValue }) => {
        const value = getValue() as string
        return <LongTextTooltip text={value} maxLength={50} />
      },
      size: 120,
    }),
    createColumn<NotificationRule>('criteria', {
      header: 'Event Triggers',
      meta: {
        width: '30%',
      },
      enableSorting: false,
      cell: ({ row }) => {
        const rule = row.original
        const events = rule.eventTriggers || []
        const eventsText = events.join(', ')

        return <LongTextTooltip text={eventsText} maxLength={50} />
      },
      size: 120,
    }),
    createColumn<NotificationRule>('createdAt', {
      header: 'Created',
      enableSorting: false,
      meta: {
        width: '130px',
      },
      cell: ({ row }) => {
        const { createdAt } = row.original
        return <CellWithDateTimeTooltip date={createdAt} />
      },
    }),

    createColumn<NotificationRule>('status', {
      header: 'Actions',
      enableSorting: false,
      meta: {
        width: '60px',
      },
      cell: ({ row }) => {
        return <AlertsCellActions rule={row.original} />
      },
    }),
  ]
}
