import React, { memo } from 'react'
import { Box, Button, Typography } from '@mui/material'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import { Description } from '../styles'
import { useCreateView } from './useCreateView'
import { Controller } from 'react-hook-form'
import { PagePathConstant } from '@lib/constants'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { FieldNames } from '../types'
import {
  radioButtonsForTagsOperations,
  radioButtonsForViewOptions,
} from '../consts'
import { RadioGroupController, SelectWithChips, TextFieldController } from '..'
import styles from '../management-view.module.css'

function CreateManagementView() {
  const router = useNavigation()

  const {
    accounts,
    regions,
    includingTags,
    excludingTags,
    control,
    setValue,
    atLeastOneFieldIsNotEmpty,
    handleCreateView,
    validateName,
  } = useCreateView()

  const handleOnCancel = () => router.push(PagePathConstant.DASHBOARD)

  return (
    <>
      <Box
        component="form"
        className={styles.form}
        data-testid="add-new-dashboard-view"
      >
        <Typography
          variant="body1"
          marginY="16px"
          fontSize="16px"
          fontWeight="500"
        >
          Dashboard name
        </Typography>

        <Controller
          name={FieldNames.NAME}
          control={control}
          rules={{
            required: 'This field is required',
            maxLength: {
              value: 255,
              message: 'The maximum length is 255 characters',
            },
            validate: validateName,
          }}
          render={({ field, fieldState: { error } }) => (
            // @ts-ignore
            <TextFieldController field={field} error={error} />
          )}
        />

        <Typography
          variant="body1"
          marginY="16px"
          fontSize="16px"
          fontWeight="500"
        >
          Dashboard view
        </Typography>

        <Description>Choose option for a new account view.</Description>

        <Controller
          name={FieldNames.DASHBOARD_VIEW}
          control={control}
          render={({ field }) => (
            <RadioGroupController
              // @ts-ignore
              field={field}
              options={radioButtonsForViewOptions}
            />
          )}
        />

        <SelectWithChips
          name={FieldNames.ACCOUNTS}
          title="Accounts"
          options={accounts.available}
          selected={accounts.selected}
          placeholder="Select accounts..."
          control={control}
          setValue={setValue}
          rules={{
            validate: atLeastOneFieldIsNotEmpty,
          }}
        />

        <SelectWithChips
          name={FieldNames.REGIONS}
          title="AWS Regions"
          options={regions.available}
          selected={regions.selected}
          placeholder="Select regions..."
          control={control}
          setValue={setValue}
          rules={{
            validate: atLeastOneFieldIsNotEmpty,
          }}
        />

        <SelectWithChips
          name={FieldNames.INCLUDING_TAGS}
          title="Filter assets with tags"
          options={includingTags.available}
          selected={includingTags.selected}
          placeholder="Choose tags..."
          showSearchInput
          control={control}
          setValue={setValue}
          isCreatable
          rules={{
            validate: atLeastOneFieldIsNotEmpty,
          }}
        >
          <>
            <Description>
              Choose a boolean operator and select tags to filter assets for the
              new dashboard view.
            </Description>
            <Controller
              name={FieldNames.INCLUDING_TAGS_OPERATION}
              control={control}
              render={({ field }) => (
                <RadioGroupController
                  // @ts-ignore
                  field={field}
                  options={radioButtonsForTagsOperations}
                />
              )}
            />
          </>
        </SelectWithChips>

        <SelectWithChips
          name={FieldNames.EXCLUDING_TAGS}
          title="Exclude assets with tags"
          options={excludingTags.available}
          selected={excludingTags.selected}
          placeholder="Choose tags..."
          control={control}
          setValue={setValue}
          isCreatable
          rules={{
            validate: atLeastOneFieldIsNotEmpty,
          }}
        >
          <>
            <Description variant="el-description" fontSize="14px" margin="0">
              Choose a boolean operator and select tags to exclude assets from
              the new dashboard view.
            </Description>
            <Controller
              name={FieldNames.EXCLUDING_TAGS_OPERATION}
              control={control}
              render={({ field }) => (
                <RadioGroupController
                  // @ts-ignore
                  field={field}
                  options={radioButtonsForTagsOperations}
                />
              )}
            />
          </>
        </SelectWithChips>
      </Box>
      <BasePortalButtons alwaysInPortal>
        <div className="portalBetweenButtons">
          <Button
            variant="text"
            color="primary"
            className="showButton jsCloseCloudInstallerPage"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="sizeS ml16"
            onClick={handleCreateView}
            data-testid="create-new-view-button"
          >
            Create new view
          </Button>
        </div>
      </BasePortalButtons>
    </>
  )
}

export default memo(CreateManagementView)
