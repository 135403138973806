/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { AWSBRecoveryPoint, Backup, CloudConnector } from 'blues-corejs/dist'
import Typography from '@mui/material/Typography'

interface Props {
  backup: Backup
  cloudConnectorList: Array<CloudConnector>
}

const computeTargetAccountId = (cloudConnector: CloudConnector) =>
  cloudConnector.accountAlias
    ? `${cloudConnector.accountAlias} (${cloudConnector.awsAccountId})`
    : cloudConnector.awsAccountId

export default function BackupTargetAccountId({
  backup,
  cloudConnectorList,
}: Props) {
  if (!(backup instanceof AWSBRecoveryPoint)) {
    return null
  }

  const cloudConnector = cloudConnectorList.find(
    (cc) => cc.awsAccountId === backup.accountId
  )

  if (!cloudConnector) {
    return null
  }

  const targetAccountId = computeTargetAccountId(cloudConnector)

  return <Typography fontSize="14px">{targetAccountId}</Typography>
}
