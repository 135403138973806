"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InventoryModel = exports.SliceCriteriaTagsCombinationOperator = exports.EfsModel = exports.GenericHostModel = exports.S3BucketState = exports.S3BucketModel = exports.EbsVulnerabilityKind = exports.FsCheckStatus = exports.AssetWithThreat = exports.TenantModel = exports.VolumeStatusCheck = exports.VolumeStatus = exports.InstanceState = exports.VolumeModel = exports.SnapshotModel = exports.OsKind = exports.InstanceModel = void 0;
const instance_model_1 = require("./instance.model");
Object.defineProperty(exports, "InstanceModel", { enumerable: true, get: function () { return instance_model_1.InstanceModel; } });
Object.defineProperty(exports, "InstanceState", { enumerable: true, get: function () { return instance_model_1.InstanceState; } });
Object.defineProperty(exports, "OsKind", { enumerable: true, get: function () { return instance_model_1.OsKind; } });
const snapshot_model_1 = require("./snapshot.model");
Object.defineProperty(exports, "SnapshotModel", { enumerable: true, get: function () { return snapshot_model_1.SnapshotModel; } });
const volume_model_1 = require("./volume.model");
Object.defineProperty(exports, "VolumeModel", { enumerable: true, get: function () { return volume_model_1.VolumeModel; } });
Object.defineProperty(exports, "VolumeStatus", { enumerable: true, get: function () { return volume_model_1.VolumeStatus; } });
Object.defineProperty(exports, "VolumeStatusCheck", { enumerable: true, get: function () { return volume_model_1.VolumeStatusCheck; } });
const threat_status_model_1 = require("./threat-status.model");
Object.defineProperty(exports, "AssetWithThreat", { enumerable: true, get: function () { return threat_status_model_1.AssetWithThreat; } });
Object.defineProperty(exports, "FsCheckStatus", { enumerable: true, get: function () { return threat_status_model_1.FsCheckStatus; } });
const index_1 = require("../index");
Object.defineProperty(exports, "EbsVulnerabilityKind", { enumerable: true, get: function () { return index_1.EbsVulnerabilityKind; } });
const tenant_model_1 = require("./tenant.model");
Object.defineProperty(exports, "TenantModel", { enumerable: true, get: function () { return tenant_model_1.TenantModel; } });
const s3bucket_model_1 = require("./s3bucket.model");
Object.defineProperty(exports, "S3BucketModel", { enumerable: true, get: function () { return s3bucket_model_1.S3BucketModel; } });
Object.defineProperty(exports, "S3BucketState", { enumerable: true, get: function () { return s3bucket_model_1.S3BucketState; } });
const generic_host_model_1 = require("./generic-host.model");
Object.defineProperty(exports, "GenericHostModel", { enumerable: true, get: function () { return generic_host_model_1.GenericHostModel; } });
const efs_model_1 = require("./efs.model");
Object.defineProperty(exports, "EfsModel", { enumerable: true, get: function () { return efs_model_1.EfsModel; } });
var SliceCriteriaTagsCombinationOperator;
(function (SliceCriteriaTagsCombinationOperator) {
    SliceCriteriaTagsCombinationOperator[SliceCriteriaTagsCombinationOperator["AND"] = 0] = "AND";
    SliceCriteriaTagsCombinationOperator[SliceCriteriaTagsCombinationOperator["OR"] = 1] = "OR";
})(SliceCriteriaTagsCombinationOperator || (exports.SliceCriteriaTagsCombinationOperator = SliceCriteriaTagsCombinationOperator = {}));
class InventoryModel {
    instanceList;
    volumeList;
    snapshotList;
    s3BucketList;
    genericHostList;
    efsList;
    allInstanceList;
    allVolumeList;
    allSnapshotList;
    allS3BucketList;
    allGenericHostList;
    allEfsList;
    snapshotsByVolume;
    tenant;
    instancesByVolumes;
    volumesByInstances;
    accountIds;
    regions;
    tags;
    constructor(attrs) {
        this.instanceList = attrs.instanceList;
        this.volumeList = attrs.volumeList;
        this.snapshotList = attrs.snapshotList;
        this.s3BucketList = attrs.s3BucketList;
        this.genericHostList = attrs.genericHostList;
        this.efsList = attrs.efsList;
        this.allInstanceList = attrs.allInstanceList ?? attrs.instanceList;
        this.allVolumeList = attrs.allVolumeList ?? attrs.volumeList;
        this.allSnapshotList = attrs.allSnapshotList ?? attrs.snapshotList;
        this.allS3BucketList = attrs.allS3BucketList ?? attrs.s3BucketList;
        this.allGenericHostList = attrs.allGenericHostList ?? attrs.genericHostList;
        this.allEfsList = attrs.allEfsList ?? attrs.efsList;
        this.tenant = attrs.tenant;
        this.snapshotsByVolume = new Map();
        this.instancesByVolumes = new Map();
        this.volumesByInstances = new Map();
        this.allSnapshotList?.forEach((snapshot) => {
            const volumeSnapshots = this.snapshotsByVolume.get(snapshot.getVolumeId());
            if (!volumeSnapshots) {
                this.snapshotsByVolume.set(snapshot.getVolumeId(), [snapshot]);
            }
            else {
                volumeSnapshots.push(snapshot);
            }
        });
        this.allInstanceList?.forEach((instance) => {
            const instanceVolumes = instance.getVolumeIds();
            instanceVolumes.forEach((volumeId) => {
                const volumesInstances = this.instancesByVolumes.get(volumeId);
                if (!volumesInstances) {
                    this.instancesByVolumes.set(volumeId, [instance]);
                }
                else {
                    volumesInstances.push(instance);
                }
            });
        });
        const volumes = new Map(this.allVolumeList.map((volume) => [volume.getId(), volume]));
        this.allInstanceList?.forEach((instance) => {
            const instanceVolumes = instance
                .getVolumeIds()
                .map((volumeId) => volumes.get(volumeId))
                .filter((volume) => !!volume);
            this.volumesByInstances.set(instance.getId(), instanceVolumes);
        });
        this.accountIds = new Set();
        this.regions = new Set();
        this.tags = [];
        this.initializeAssetsData();
    }
    // TODO: move to ManagementViewClass
    slice(criteria) {
        const matchingInstanceList = slice(this.instanceList, criteria);
        const matchingVolumeList = slice(this.volumeList, criteria);
        const matchingSnapshotList = slice(this.snapshotList, criteria);
        const matchingS3BucketList = slice(this.s3BucketList, criteria);
        const matchingGenericHostList = slice(this.genericHostList, criteria);
        const matchingEfsList = slice(this.efsList, criteria);
        const volumeSet = new Set(matchingVolumeList);
        const snapshotSet = new Set(matchingSnapshotList);
        for (const instance of matchingInstanceList) {
            const volumes = this.volumesByInstances.get(instance.getId()) ?? [];
            for (const volume of volumes) {
                volumeSet.add(volume);
            }
        }
        for (const volume of volumeSet) {
            const snapshots = this.snapshotsByVolume.get(volume.getId()) ?? [];
            for (const snapshot of snapshots) {
                snapshotSet.add(snapshot);
            }
        }
        return new InventoryModel({
            instanceList: matchingInstanceList,
            volumeList: Array.from(volumeSet),
            snapshotList: Array.from(snapshotSet),
            s3BucketList: matchingS3BucketList,
            genericHostList: matchingGenericHostList,
            efsList: matchingEfsList,
            tenant: this.tenant,
            allVolumeList: this.allVolumeList,
            allInstanceList: this.allInstanceList,
            allSnapshotList: this.allSnapshotList,
            allS3BucketList: this.allS3BucketList,
            allGenericHostList: this.allGenericHostList,
            allEfsList: this.allEfsList,
        });
    }
    getAllAssetsList() {
        return [
            ...this.allInstanceList,
            ...this.allVolumeList,
            ...this.allSnapshotList,
            ...this.allS3BucketList,
            ...this.allGenericHostList,
            ...this.allEfsList,
        ];
    }
    initializeAssetsData() {
        const assetsList = this.getAllAssetsList();
        assetsList.forEach((asset) => {
            this.accountIds.add(asset.getAccountId());
            this.regions.add(asset.getRegion());
            for (const [key, value] of asset.getTagsMap()) {
                if (!this.tags.some((tag) => tag.key === key && tag.value === value)) {
                    this.tags.push({ key, value });
                }
            }
        });
    }
    getAccountIds() {
        return Array.from(this.accountIds);
    }
    getRegions() {
        return Array.from(this.regions);
    }
    getTags() {
        return this.tags;
    }
    getInstances() {
        return this.instanceList;
    }
    getAllInstances() {
        return this.allInstanceList;
    }
    getVolumes() {
        return this.volumeList;
    }
    getAllVolumes() {
        return this.allVolumeList;
    }
    getSnapshots() {
        return this.snapshotList;
    }
    getAllSnapshots() {
        return this.allSnapshotList;
    }
    // returns mock data
    // TODO remove mock data when backend is ready
    getS3Buckets() {
        //return this.s3BucketList
        const s3BucketsMockList = [];
        for (let i = 0; i < 5; i++) {
            const s3Bucket = new s3bucket_model_1.S3BucketModel((0, s3bucket_model_1.createMockS3BucketAttributes)(i + 1));
            s3BucketsMockList.push(s3Bucket);
        }
        return this.s3BucketList.length ? this.s3BucketList : s3BucketsMockList;
    }
    getAllS3Buckets() {
        return this.allS3BucketList;
    }
    getS3BucketInventory(filters) {
        return this.getS3Buckets()
            ?.filter((s3Bucket) => {
            if (filters?.idSearch?.length &&
                !s3Bucket.getName().includes(filters?.idSearch)) {
                return false;
            }
            if (filters.accountIds &&
                !filters.accountIds?.includes(s3Bucket.getAccountId())) {
                return false;
            }
            if (filters.regions &&
                !filters.regions?.includes(s3Bucket.getRegion())) {
                return false;
            }
            if (filters.covered?.includes(true) &&
                s3Bucket.isCoveredByPolicy() === false) {
                return false;
            }
            if (filters.covered?.includes(false) &&
                s3Bucket.isCoveredByPolicy() === true) {
                return false;
            }
            if (filters.state?.includes(true) &&
                s3Bucket.getState() !== s3bucket_model_1.S3BucketState.STATE_IN_USE) {
                return false;
            }
            if (filters.state?.includes(false) &&
                s3Bucket.getState() !== s3bucket_model_1.S3BucketState.STATE_DELETED) {
                return false;
            }
            return true;
        })
            ?.map((s3Bucket) => s3Bucket);
    }
    getS3BucketInventoryPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        this.getS3Buckets()?.forEach((s3Bucket) => {
            accountIds.add(s3Bucket.getAccountId());
            regions.add(s3Bucket.getRegion());
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            covered: [true, false],
            state: [true, false],
        };
    }
    // returns mock data
    // TODO remove mock data when backend is ready
    getGenericHosts() {
        //return this.genericHostList
        const genericHostMockList = [];
        for (let i = 0; i < 5; i++) {
            const genericHost = new generic_host_model_1.GenericHostModel((0, generic_host_model_1.createMockGenericHostAttributes)(i + 1));
            genericHostMockList.push(genericHost);
        }
        return this.genericHostList.length
            ? this.genericHostList
            : genericHostMockList;
    }
    // returns mock data
    // TODO remove mock data when backend is ready
    getEfs() {
        //return this.efsList
        const efsMockList = [];
        for (let i = 0; i < 5; i++) {
            const efs = new efs_model_1.EfsModel((0, efs_model_1.createMockEfsAttributes)(i + 1));
            efsMockList.push(efs);
        }
        return this.efsList.length ? this.efsList : efsMockList;
    }
    getQuarantinedVolumes(filters) {
        return this.volumeList.filter((volume) => {
            if (!volume.isInfected()) {
                return false;
            }
            if (filters?.idSearch?.length &&
                !volume.getParsedCloudProviderVolumeId().includes(filters?.idSearch)) {
                return false;
            }
            if (filters.malwaresList?.length &&
                !filters.malwaresList?.some((item) => volume.containsExactMalware(item))) {
                return false;
            }
            if (filters.ransomwaresList?.length &&
                !filters.ransomwaresList?.some((item) => volume.containsExactRansomware(item))) {
                return false;
            }
            return true;
        });
    }
    getInstancesNumber() {
        return this.instanceList?.length;
    }
    getVolumesNumber() {
        return this.volumeList?.length;
    }
    getVolumesUnattachedList() {
        const volumeIds = this.getInstancesVolumeIds();
        return this.volumeList?.filter((v) => !volumeIds.has(v.getId()));
    }
    getVolumesAttachedToRunningInstances() {
        const volumeIds = this.getRunningInstancesVolumeIds();
        return this.volumeList?.filter((v) => volumeIds.has(v.getId()));
    }
    getVolumesWithVulnerabilities() {
        return this.volumeList.filter((volume) => {
            const snapshotList = this.snapshotsByVolume.get(volume.getId()) ?? [];
            return this.volumeVulnerabilitiesList(volume, snapshotList).length > 0;
        });
    }
    getInstanceList() {
        return this.instanceList;
    }
    getVolumeList() {
        return this.volumeList;
    }
    getSnapshotList() {
        return this.snapshotList;
    }
    getSnapshotsByVolume() {
        return this.snapshotsByVolume;
    }
    firstRansomwareDetectedAt() {
        const ransomwareDetectedAt = this.volumeList
            .map((volume) => volume.getFirstRansomwareDetectedAt() ?? 0)
            .reduce((acc, curr) => {
            if (acc === 0) {
                return curr;
            }
            if (curr === 0) {
                return acc;
            }
            return Math.min(acc, curr);
        }, 0);
        return ransomwareDetectedAt === 0 ? null : ransomwareDetectedAt;
    }
    lastRansomwareDetectedAt() {
        const ransomwareDetectedAt = this.volumeList
            .map((volume) => volume.getLastRansomwareDetectedAt() ?? 0)
            .reduce((acc, curr) => {
            if (acc === 0) {
                return curr;
            }
            if (curr === 0) {
                return acc;
            }
            return Math.max(acc, curr);
        }, 0);
        return ransomwareDetectedAt === 0 ? null : ransomwareDetectedAt;
    }
    firstMalwareDetectedAt() {
        const malwareDetectedAt = this.volumeList
            .map((volume) => volume.getFirstMalwareDetectedAt() ?? 0)
            .reduce((acc, curr) => {
            if (acc === 0) {
                return curr;
            }
            if (curr === 0) {
                return acc;
            }
            return Math.min(acc, curr);
        }, 0);
        return malwareDetectedAt === 0 ? null : malwareDetectedAt;
    }
    lastMalwareDetectedAt() {
        const malwareDetectedAt = this.volumeList
            .map((volume) => volume.getLastMalwareDetectedAt() ?? 0)
            .reduce((acc, curr) => {
            if (acc === 0) {
                return curr;
            }
            if (curr === 0) {
                return acc;
            }
            return Math.max(acc, curr);
        }, 0);
        return malwareDetectedAt === 0 ? null : malwareDetectedAt;
    }
    getAllSnapshotVulnerabilitiesTypes() {
        if (!this.tenant.getVulnerabilitiesEnabled()) {
            return [];
        }
        const snapshotVulnerabilitiesType = new Set();
        this.snapshotList?.map((snapshot) => {
            if (snapshot.isPublic()) {
                snapshotVulnerabilitiesType.add(index_1.SnapshotVulnerabilitiesTypeName.PUBLIC_PERMISSIONS);
            }
            if (snapshot.isUnencrypted()) {
                snapshotVulnerabilitiesType.add(index_1.SnapshotVulnerabilitiesTypeName.UNENCRYPTED);
            }
        });
        return [...snapshotVulnerabilitiesType].sort();
    }
    // TODO: memoise
    getSnapshotsInventoryPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        this.snapshotList?.forEach((snapshot) => {
            accountIds.add(snapshot.getAccountId());
            regions.add(snapshot.getRegion());
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
        };
    }
    getSnapshotsInventory(filters) {
        return this.snapshotList
            .filter((snapshot) => {
            if (filters.accountIds &&
                !filters.accountIds.includes(snapshot.getAccountId())) {
                return false;
            }
            if (filters.regions &&
                !filters.regions.includes(snapshot.getRegion())) {
                return false;
            }
            if (filters.idSearch &&
                (filters.idSearch ?? '') !== '' &&
                !this.snapshotSearchMatches(filters.idSearch ?? '', snapshot)) {
                return false;
            }
            return true;
        })
            .map((snapshot) => {
            const volumeName = this.volumeList
                ?.find((volume) => volume.getId() === snapshot.getVolumeId())
                ?.getName() ?? '';
            return {
                snapshot,
                volumeName,
            };
        });
    }
    // TODO: memoise
    getSnapshotVulnerabilitiesPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        this.snapshotList?.forEach((snapshot) => {
            accountIds.add(snapshot.getAccountId());
            regions.add(snapshot.getRegion());
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            snapshotVulnerabilityTypes: this.getAllSnapshotVulnerabilitiesTypes(),
        };
    }
    getSnapshotVulnerabilities(filters) {
        return this.snapshotList
            .filter((snapshot) => {
            if (filters.accountIds &&
                !filters.accountIds.includes(snapshot.getAccountId())) {
                return false;
            }
            if (filters.regions &&
                !filters.regions.includes(snapshot.getRegion())) {
                return false;
            }
            if (filters.idSearch &&
                (filters.idSearch ?? '') !== '' &&
                !this.volumeOrSnapshotIdsMatchesForSnapshots(filters.idSearch ?? '', snapshot)) {
                return false;
            }
            if (filters.snapshotVulnerabilityTypes &&
                !filters.snapshotVulnerabilityTypes.includes(snapshot.getSnapshotVulnerabilitiesTypes()[0] ?? '') &&
                !filters.snapshotVulnerabilityTypes.includes(snapshot.getSnapshotVulnerabilitiesTypes()[1] ?? '')) {
                return false;
            }
            return true;
        })
            .map((snapshot) => ({
            snapshot,
            snapshotVulnerabilityTypes: this.tenant?.getVulnerabilitiesEnabled()
                ? snapshot.getSnapshotVulnerabilitiesTypes()
                : [],
        }));
    }
    // TODO: memoise
    getVolumeSnapshotPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        this.volumeList?.forEach((volume) => {
            accountIds.add(volume.getAccountId());
            regions.add(volume.getRegion());
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            covered: [true, false],
        };
    }
    getVolumesSnapshotCoverage(filters) {
        return this.volumeList
            .filter((volume) => {
            const volumeSnapshots = this.snapshotsByVolume.get(volume.getId());
            if (filters.accountIds &&
                !filters.accountIds?.includes(volume.getAccountId())) {
                return false;
            }
            if (filters.regions && !filters.regions?.includes(volume.getRegion())) {
                return false;
            }
            if ((filters.idSearch ?? '') !== '' &&
                !this.volumeOrSnapshotIdsMatches(filters.idSearch ?? '', volume, volumeSnapshots)) {
                return false;
            }
            if (filters.covered?.includes(true) &&
                (volumeSnapshots?.length ?? 0) === 0) {
                return false;
            }
            if (filters.covered?.includes(false) &&
                (volumeSnapshots?.length ?? 0) > 0) {
                return false;
            }
            return true;
        })
            ?.map((volume) => ({
            volume,
            snapshotList: this.snapshotsByVolume.get(volume.getId()) ?? [],
        }));
    }
    // TODO: memoise
    getVolumeInventoryPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        this.volumeList?.forEach((volume) => {
            accountIds.add(volume.getAccountId());
            regions.add(volume.getRegion());
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            protected: [true, false],
            covered: [true, false],
            backupsOnSchedule: [false, true],
        };
    }
    getVolumesInventory(filters) {
        return this.volumeList
            ?.filter((volume) => {
            if (filters.accountIds &&
                !filters.accountIds?.includes(volume.getAccountId())) {
                return false;
            }
            if (filters.regions && !filters.regions?.includes(volume.getRegion())) {
                return false;
            }
            if ((filters.idSearch ?? '') !== '' &&
                !this.volumeSearchMatches(filters.idSearch ?? '', volume)) {
                return false;
            }
            if (filters.protected?.includes(true) &&
                volume.isLastRecoveryPointId() === false) {
                return false;
            }
            if (filters.protected?.includes(false) &&
                volume.isLastRecoveryPointId() === true) {
                return false;
            }
            if (filters.covered?.includes(true) &&
                volume.isCoveredByElastioPolicyOrAwsBackup() === false) {
                return false;
            }
            if (filters.covered?.includes(false) &&
                volume.isCoveredByElastioPolicyOrAwsBackup() === true) {
                return false;
            }
            if (filters.backupsOnSchedule?.includes(true) &&
                volume.isOutOfSchedule() === false) {
                return false;
            }
            if (filters.backupsOnSchedule?.includes(false) &&
                volume.isOutOfSchedule() === true) {
                return false;
            }
            return true;
        })
            ?.map((volume) => ({
            volume,
            instances: this.instanceList?.filter((instanceModel) => instanceModel.getVolumeIds().includes(volume.getId())),
        }));
    }
    getVolumesForReport() {
        return this.volumeList.map((volume) => ({
            volume,
            instances: this.instanceList?.filter((instanceModel) => instanceModel.getVolumeIds().includes(volume.getId())),
        }));
    }
    // TODO: memoise
    // used for EBS Vulnerabilities table filtering
    getEBSVulnerabilitiesPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        const ebsVulnerabilities = new Set();
        this.volumeList?.forEach((volume) => {
            accountIds.add(volume.getAccountId());
            regions.add(volume.getRegion());
            const snapshotList = this.snapshotsByVolume.get(volume.getId()) ?? [];
            const vulnerabilities = this.volumeVulnerabilitiesList(volume, snapshotList);
            vulnerabilities.forEach((item) => ebsVulnerabilities.add(item));
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            ebsVulnerabilities: [...ebsVulnerabilities].sort(),
        };
    }
    // used for EBS Vulnerabilities table
    getEBSVulnerabilities(filters) {
        return this.volumeList
            .filter((volume) => {
            if (filters.accountIds &&
                !filters.accountIds?.includes(volume.getAccountId())) {
                return false;
            }
            if (filters.regions && !filters.regions?.includes(volume.getRegion())) {
                return false;
            }
            if (filters.ebsVulnerabilities) {
                const curVolumeSnapshotList = this.snapshotsByVolume.get(volume.getId()) ?? [];
                const curVolumeVulnerabilities = this.volumeVulnerabilitiesList(volume, curVolumeSnapshotList);
                if (!filters.ebsVulnerabilities.some((el) => curVolumeVulnerabilities.includes(el))) {
                    return false;
                }
            }
            if ((filters.idSearch ?? '') !== '' &&
                !this.volumeOrAccountIdsMatches(filters.idSearch ?? '', volume)) {
                return false;
            }
            return true;
        })
            ?.map((volume) => {
            const snapshotList = this.snapshotsByVolume.get(volume.getId()) ?? [];
            const vulnerabilities = this.volumeVulnerabilitiesList(volume, snapshotList);
            return {
                volume,
                vulnerabilities,
            };
        });
    }
    doesVolumeHaveVulnerabilities(volume) {
        if (!this.tenant.getVulnerabilitiesEnabled()) {
            return false;
        }
        const snapshotList = this.snapshotsByVolume.get(volume.getId()) ?? [];
        const vulnerabilities = this.volumeVulnerabilitiesList(volume, snapshotList);
        return vulnerabilities.length > 0;
    }
    // TODO: memoise
    getEc2InventoryPossibleFilters() {
        const accountIds = new Set();
        const regions = new Set();
        const osKinds = new Set();
        this.instanceList?.forEach((instance) => {
            accountIds.add(instance.getAccountId());
            regions.add(instance.getRegion());
            osKinds.add(instance.getOsKind());
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            osKinds: [...osKinds],
            covered: [true, false],
            protected: [true, false],
            backupsOnSchedule: [false, true],
        };
    }
    getEc2Inventory(filters) {
        return this.instanceList
            .filter((instance) => {
            if (filters.accountIds &&
                !filters.accountIds?.includes(instance.getAccountId())) {
                return false;
            }
            if (filters.regions &&
                !filters.regions?.includes(instance.getRegion())) {
                return false;
            }
            if (filters.osKinds &&
                !filters.osKinds?.includes(instance.getOsKind())) {
                return false;
            }
            if ((filters.idSearch ?? '') !== '' &&
                !this.instanceSearchMatches(filters.idSearch ?? '', instance)) {
                return false;
            }
            if (filters.protected?.includes(true) &&
                instance.isProtected() === false) {
                return false;
            }
            if (filters.protected?.includes(false) &&
                instance.isProtected() === true) {
                return false;
            }
            if (filters.covered?.includes(true) &&
                instance.isCoveredByElastioPolicyOrAwsBackup() === false) {
                return false;
            }
            if (filters.covered?.includes(false) &&
                instance.isCoveredByElastioPolicyOrAwsBackup() === true) {
                return false;
            }
            if (filters.backupsOnSchedule?.includes(true) &&
                instance.isOutOfSchedule() === false) {
                return false;
            }
            if (filters.backupsOnSchedule?.includes(false) &&
                instance.isOutOfSchedule() === true) {
                return false;
            }
            return true;
        })
            ?.map((instance) => {
            const volumesList = this.getInstanceAttachedVolumes(instance) ?? [];
            return {
                instance,
                volumesList,
            };
        });
    }
    volumeOrSnapshotIdsMatches(id, volume, snapshots) {
        if (volume.getParsedCloudProviderVolumeId().includes(id)) {
            return true;
        }
        return Boolean(snapshots?.find((snapshot) => snapshot.getCloudProviderSnapshotId().includes(id)));
    }
    volumeOrSnapshotIdsMatchesForSnapshots(id, snapshot) {
        if (snapshot.getCloudProviderSnapshotId().includes(id)) {
            return true;
        }
        return Boolean(snapshot.getParsedCloudProviderVolumeId().includes(id));
    }
    volumeOrAccountIdsMatches(id, volume) {
        if (volume.getParsedCloudProviderVolumeId().includes(id)) {
            return true;
        }
        if (volume.getAccountId().includes(id)) {
            return true;
        }
        return false;
    }
    volumeSnapshotsNameOrIdMatches(id, volume) {
        const volumeSnapshots = this.snapshotsByVolume.get(volume.getId()) ?? [];
        // snapshot ID and snapshot name
        if (volumeSnapshots.filter((snap) => snap.getName().includes(id) ||
            snap.getCloudProviderSnapshotId().includes(id)).length) {
            return true;
        }
        return false;
    }
    volumeSnapshotsTagsMatches(searchValue, volume) {
        const volumeSnapshots = this.snapshotsByVolume.get(volume.getId()) ?? [];
        if (volumeSnapshots.some((snapshot) => {
            return this.snapshotTagsMatches(searchValue, snapshot);
        })) {
            return true;
        }
        return false;
    }
    volumeTagsMatches(searchValue, volume) {
        const volumeTags = volume.getTags() ?? [];
        if (!volumeTags.length) {
            return false;
        }
        if (volumeTags.some(([tagKey, tagValue]) => {
            return this.tagsKeyValueMatches(searchValue, tagKey, tagValue);
        })) {
            return true;
        }
        return false;
    }
    instanceTagsMatches(searchValue, instance) {
        const instanceTags = instance.getTags() ?? [];
        if (!instanceTags.length) {
            return false;
        }
        if (instanceTags.some(([tagKey, tagValue]) => {
            return this.tagsKeyValueMatches(searchValue, tagKey, tagValue);
        })) {
            return true;
        }
        return false;
    }
    snapshotTagsMatches(searchValue, snapshot) {
        const snapshotTags = snapshot.getTags() ?? [];
        if (!snapshotTags.length) {
            return false;
        }
        if (snapshotTags.some(([tagKey, tagValue]) => {
            return this.tagsKeyValueMatches(searchValue, tagKey, tagValue);
        })) {
            return true;
        }
        return false;
    }
    tagsKeyValueMatches(searchValue, tagKey, tagValue) {
        if (tagKey.includes(searchValue) || tagValue.includes(searchValue)) {
            return true;
        }
        return false;
    }
    // instances search by:
    //instance name, instance ID, volume name, volume ID,
    //snapshot ID and snapshot name, tags
    instanceSearchMatches(searchValue, instance) {
        //instance ID
        if (instance.getParsedCloudProviderInstanceId().includes(searchValue)) {
            return true;
        }
        //instance name
        if (instance.getName().includes(searchValue)) {
            return true;
        }
        // instance tags
        if (this.instanceTagsMatches(searchValue, instance)) {
            return true;
        }
        const attachedVolumes = this.getInstanceAttachedVolumes(instance) ?? [];
        if (attachedVolumes.length) {
            //volume ID and volume name
            if (attachedVolumes.filter((vol) => vol.getParsedCloudProviderVolumeId().includes(searchValue) ||
                vol.getName().includes(searchValue)).length) {
                return true;
            }
            // attached volumes tags
            if (attachedVolumes.some((volume) => {
                return this.volumeTagsMatches(searchValue, volume);
            })) {
                return true;
            }
            // snapshot ID and snapshot name
            if (attachedVolumes.some((volume) => {
                return this.volumeSnapshotsNameOrIdMatches(searchValue, volume);
            })) {
                return true;
            }
            // snapshot tags
            if (attachedVolumes.some((volume) => {
                return this.volumeSnapshotsTagsMatches(searchValue, volume);
            })) {
                return true;
            }
        }
        return false;
    }
    // volumes search by:
    //instance name, instance ID, volume name, volume ID,
    //snapshot ID and snapshot name, tags
    volumeSearchMatches(searchValue, volume) {
        //volume ID
        if (volume.getParsedCloudProviderVolumeId().includes(searchValue)) {
            return true;
        }
        //volume name
        if (volume.getName().includes(searchValue)) {
            return true;
        }
        // volume tags
        if (this.volumeTagsMatches(searchValue, volume)) {
            return true;
        }
        const volumeInstances = this.instancesByVolumes.get(volume.getId()) ?? [];
        if (volumeInstances.length) {
            //instance ID and instance name
            if (volumeInstances.filter((inst) => inst.getParsedCloudProviderInstanceId().includes(searchValue) ||
                inst.getName().includes(searchValue)).length) {
                return true;
            }
            // volume instances tags
            if (volumeInstances.some((instance) => {
                return this.instanceTagsMatches(searchValue, instance);
            })) {
                return true;
            }
        }
        // snapshot ID and snapshot name
        if (this.volumeSnapshotsNameOrIdMatches(searchValue, volume)) {
            return true;
        }
        // snapshot tags
        if (this.volumeSnapshotsTagsMatches(searchValue, volume)) {
            return true;
        }
        return false;
    }
    // snapshots search by:
    //instance name, instance ID, volume name, volume ID,
    //snapshot ID and snapshot name, tags
    snapshotSearchMatches(searchValue, snapshot) {
        //snapshot ID
        if (snapshot.getParsedCloudProviderSnapshotId().includes(searchValue)) {
            return true;
        }
        //snapshot name
        if (snapshot.getName().includes(searchValue)) {
            return true;
        }
        // snapshot tags
        if (this.snapshotTagsMatches(searchValue, snapshot)) {
            return true;
        }
        const volumeOfSnapshotInstances = this.instancesByVolumes.get(snapshot.getVolumeId()) ?? [];
        if (volumeOfSnapshotInstances.length) {
            //instance ID and instance name
            if (volumeOfSnapshotInstances.filter((inst) => inst.getParsedCloudProviderInstanceId().includes(searchValue) ||
                inst.getName().includes(searchValue)).length) {
                return true;
            }
            // volume instances tags
            if (volumeOfSnapshotInstances.some((instance) => {
                return this.instanceTagsMatches(searchValue, instance);
            })) {
                return true;
            }
        }
        const snapshotVolume = this.getVolumeModel(snapshot.getParsedCloudProviderVolumeId());
        if (snapshotVolume) {
            //volume ID
            if (snapshotVolume.getParsedCloudProviderVolumeId().includes(searchValue)) {
                return true;
            }
            //volume name
            if (snapshotVolume.getName().includes(searchValue)) {
                return true;
            }
            // volume tags
            if (this.volumeTagsMatches(searchValue, snapshotVolume)) {
                return true;
            }
        }
        return false;
    }
    // TODO: add test
    getInstancesVolumeIds() {
        const volumeIds = new Set();
        this.instanceList?.forEach((instance) => {
            instance.getVolumeIds().forEach((id) => volumeIds.add(id));
        });
        return volumeIds;
    }
    getAllInstancesVolumeIds() {
        const volumeIds = new Set();
        this.allInstanceList?.forEach((instance) => {
            instance.getVolumeIds().forEach((id) => volumeIds.add(id));
        });
        return volumeIds;
    }
    getRunningInstancesVolumeIds() {
        const volumeIds = new Set();
        this.instanceList
            ?.filter((instance) => instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED)
            .forEach((instance) => {
            instance.getVolumeIds().forEach((id) => volumeIds.add(id));
        });
        return volumeIds;
    }
    getInstanceAttachedVolumes(instance) {
        const volumeIds = instance.getVolumeIds();
        if (!volumeIds.length) {
            return [];
        }
        const volumesList = this.allVolumeList?.filter((v) => volumeIds.includes(v.getId()));
        return volumesList;
    }
    // TODO: memoise
    getQuarantinedVolumesPossibleFilters() {
        const malwaresList = new Set();
        const ransomwaresList = new Set();
        this.volumeList?.forEach((volume) => {
            volume
                .getUniqueMalwareNames()
                .forEach((malware) => malwaresList.add(malware));
            volume
                .getUniqueRansomwareNames()
                .forEach((ransomware) => ransomwaresList.add(ransomware));
        });
        return {
            malwaresList: [...malwaresList],
            ransomwaresList: [...ransomwaresList],
        };
    }
    getVolumesUnattachedWithThreats() {
        return this.getVolumesUnattachedList().filter((volume) => {
            return (volume.containsMalwares() ||
                volume.containsRansomwares() ||
                volume.isFsCheckFailed());
        });
    }
    getMaybeRunningInstances() {
        return this.getInstanceList().filter((instance) => instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED);
    }
    getInstancesWithActiveThreatsPossibleFilters() {
        const instances = this.getMaybeRunningInstances().filter((instance) => instance.hasThreat());
        const accountIds = new Set();
        const malwaresList = new Set();
        const ransomwaresList = new Set();
        const regions = new Set();
        const fsChecks = new Set();
        instances.forEach((instance) => {
            accountIds.add(instance.getAccountId());
            instance
                .getUniqueMalwareNames()
                .forEach((malware) => malwaresList.add(malware));
            instance
                .getUniqueRansomwareNames()
                .forEach((ransomware) => ransomwaresList.add(ransomware));
            regions.add(instance.getRegion());
            if (instance.getFsCheckStatus()) {
                fsChecks.add(instance.getFsCheckStatus());
            }
        });
        return {
            accountIds: [...accountIds],
            malwaresList: [...malwaresList],
            ransomwaresList: [...ransomwaresList],
            fsCheck: [...fsChecks],
            regions: [...regions],
        };
    }
    getInstancesWithActiveThreatsData(filters) {
        const instances = this.getMaybeRunningInstances().filter((instance) => instance.hasThreat());
        return instances
            .filter((instance) => {
            if (!matchesIncludeFilter(filters.accountIds, instance.getAccountId())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.regions, instance.getRegion())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.fsCheck, instance.getFsCheckStatus())) {
                return false;
            }
            if (filters.malwaresList &&
                !filters.malwaresList?.some((malware) => instance.containsExactMalware(malware))) {
                return false;
            }
            if (filters.ransomwaresList &&
                !filters.ransomwaresList?.some((ransomware) => instance.containsExactRansomware(ransomware))) {
                return false;
            }
            return true;
        })
            ?.map((instance) => ({
            instance,
            volumesList: this.getInstanceAttachedVolumes(instance),
        }));
    }
    getAllVolumesWithActiveThreats() {
        const volumesWithActiveThreats = this.getVolumesUnattachedWithThreats();
        // This is attached volumes with threats which are new and not present in any of the instances
        const attachedActiveThreatsVolumes = this.getVolumesAttachedToRunningInstances().filter((volume) => {
            const volumeScanTime = volume.getScannedRecoveryPointCreatedAt();
            if (!volumeScanTime) {
                return false;
            }
            const instances = this.instancesByVolumes.get(volume.getId());
            const earlyScannedInstances = instances.filter((instance) => {
                const instanceScanTime = instance.getScannedRecoveryPointCreatedAt();
                if (!instanceScanTime) {
                    return true; // This means instance was never scanned
                }
                return instanceScanTime < volumeScanTime;
            });
            // This means there are instances were scanned after than volume itself
            if (earlyScannedInstances.length !== instances.length) {
                return false;
            }
            // If we are here, it means all instances were scanned before volume itself
            // Then we need to determine that the volume has threats that are not present in any of the instances
            const volumeRansomwares = [...volume.getUniqueRansomwareNames()];
            const volumeMalwares = [...volume.getUniqueMalwareNames()];
            return earlyScannedInstances.every((instance) => {
                const instanceRansomwares = [...instance.getUniqueRansomwareNames()];
                const instanceMalwares = [...instance.getUniqueMalwareNames()];
                if (volumeRansomwares.some((rw) => !instanceRansomwares.includes(rw))) {
                    return true;
                }
                if (volumeMalwares.some((mw) => !instanceMalwares.includes(mw))) {
                    return true;
                }
                return instance.isFsCheckFailed() && volume.isFsCheckSucceeded();
            });
        });
        return volumesWithActiveThreats.concat(attachedActiveThreatsVolumes);
    }
    getVolumesWithActiveThreatsPossibleFilters() {
        const volumesWithActiveThreats = this.getAllVolumesWithActiveThreats();
        const accountIds = new Set();
        const malwaresList = new Set();
        const ransomwaresList = new Set();
        const regions = new Set();
        const fsChecks = new Set();
        volumesWithActiveThreats.forEach((volume) => {
            accountIds.add(volume.getAccountId());
            volume
                .getUniqueMalwareNames()
                .forEach((malware) => malwaresList.add(malware));
            volume
                .getUniqueRansomwareNames()
                .forEach((ransomware) => ransomwaresList.add(ransomware));
            regions.add(volume.getRegion());
            if (volume.getFsCheckStatus()) {
                fsChecks.add(volume.getFsCheckStatus());
            }
        });
        return {
            accountIds: [...accountIds],
            malwaresList: [...malwaresList],
            ransomwaresList: [...ransomwaresList],
            fsCheck: [...fsChecks],
            regions: [...regions],
        };
    }
    getVolumesWithActiveThreatsData(filters) {
        const volumesWithActiveThreats = this.getAllVolumesWithActiveThreats();
        return volumesWithActiveThreats
            .filter((volume) => {
            if (!matchesIncludeFilter(filters.accountIds, volume.getAccountId())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.regions, volume.getRegion())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.fsCheck, volume.getFsCheckStatus())) {
                return false;
            }
            if (filters.malwaresList &&
                !filters.malwaresList?.some((malware) => volume.containsExactMalware(malware))) {
                return false;
            }
            if (filters.ransomwaresList &&
                !filters.ransomwaresList?.some((ransomware) => volume.containsExactRansomware(ransomware))) {
                return false;
            }
            return true;
        })
            ?.map((volume) => ({
            volume,
            instances: this.instanceList?.filter((instanceModel) => instanceModel.getVolumeIds().includes(volume.getId())),
        }));
    }
    getActiveThreatsPossibleFilters() {
        const instances = this.getMaybeRunningInstances().filter((instance) => instance.hasThreat());
        const volumesWithActiveThreats = this.getAllVolumesWithActiveThreats();
        const accountIds = new Set();
        const malwaresList = new Set();
        const ransomwaresList = new Set();
        const regions = new Set();
        const fsChecks = new Set();
        instances.forEach((instance) => {
            accountIds.add(instance.getAccountId());
            instance
                .getUniqueMalwareNames()
                .forEach((malware) => malwaresList.add(malware));
            instance
                .getUniqueRansomwareNames()
                .forEach((ransomware) => ransomwaresList.add(ransomware));
            regions.add(instance.getRegion());
            if (instance.getFsCheckStatus()) {
                fsChecks.add(instance.getFsCheckStatus());
            }
        });
        volumesWithActiveThreats.forEach((volume) => {
            accountIds.add(volume.getAccountId());
            volume
                .getUniqueMalwareNames()
                .forEach((malware) => malwaresList.add(malware));
            volume
                .getUniqueRansomwareNames()
                .forEach((ransomware) => ransomwaresList.add(ransomware));
            regions.add(volume.getRegion());
            if (volume.getFsCheckStatus()) {
                fsChecks.add(volume.getFsCheckStatus());
            }
        });
        return {
            accountIds: [...accountIds],
            malwaresList: [...malwaresList],
            ransomwaresList: [...ransomwaresList],
            fsCheck: [...fsChecks],
            regions: [...regions],
        };
    }
    getVolumesWithVulnerabilitiesData(filters, riskIdentificationStrategy) {
        return this.getVolumesWithVulnerabilities()
            ?.filter((volume) => {
            const vulnerabilities = this.volumeVulnerabilitiesList(volume, this.snapshotsByVolume.get(volume.getId()) ?? []);
            const risks = [
                ...new Set(vulnerabilities.map(riskIdentificationStrategy.identifyRiskLevel)),
            ];
            if (!matchesIncludeFilter(filters.accountIds, volume.getAccountId())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.regions, volume.getRegion())) {
                return false;
            }
            if (filters.ebsVulnerabilities) {
                if (!filters.ebsVulnerabilities.some((vulnerability) => vulnerabilities.includes(vulnerability))) {
                    return false;
                }
            }
            if (filters.riskType?.length &&
                !filters.riskType?.some((risk) => risks.includes(risk))) {
                return false;
            }
            return true;
        })
            ?.map((volume) => {
            const attachedInstances = this.instanceList?.filter((instanceModel) => instanceModel.getVolumeIds().includes(volume.getId()));
            return {
                volume,
                instances: attachedInstances,
            };
        });
    }
    getVolumesWithVulnerabilitiesPossibleFilters(riskIdentificationStrategy) {
        const accountIds = new Set();
        const regions = new Set();
        const ebsVulnerabilities = new Set();
        const riskLevel = new Set();
        this.getVolumesWithVulnerabilities().forEach((volume) => {
            accountIds.add(volume.getAccountId());
            regions.add(volume.getRegion());
            const vulnerabilities = this.volumeVulnerabilitiesList(volume, this.snapshotsByVolume.get(volume.getId()) ?? []);
            vulnerabilities.forEach((vulnerability) => ebsVulnerabilities.add(vulnerability));
            const risks = vulnerabilities.map(riskIdentificationStrategy.identifyRiskLevel);
            risks.forEach((risk) => riskLevel.add(risk));
            vulnerabilities.forEach((vulnerability) => {
                ebsVulnerabilities.add(vulnerability);
            });
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            ebsVulnerabilities: [...ebsVulnerabilities].sort(),
            riskType: [...riskLevel].sort(),
        };
    }
    // used for EBS Vulnerabilities table
    volumeVulnerabilitiesList(volume, snapshots) {
        const result = [];
        const instancesVolumeIds = this.getInstancesVolumeIds();
        if (!instancesVolumeIds.has(volume.getId())) {
            result.push(index_1.EbsVulnerabilityKind.UNATTACHED);
        }
        if (volume.isUnencrypted()) {
            result.push(index_1.EbsVulnerabilityKind.UNENCRYPTED);
        }
        if (volume.doesNotUseCustomerManagedKey()) {
            result.push(index_1.EbsVulnerabilityKind.NOT_USING_CMK);
        }
        if (volume.isNotCyberScanned()) {
            result.push(index_1.EbsVulnerabilityKind.NOT_CYBER_SCANNED);
        }
        if (snapshots.length === 0) {
            result.push(index_1.EbsVulnerabilityKind.NO_BACKUPS);
        }
        const older7DaysNumber = snapshots.filter((s) => s.isOlderThen7Days()).length;
        if (snapshots.length > 0 && older7DaysNumber === snapshots.length) {
            result.push(index_1.EbsVulnerabilityKind.SNAPSHOTS_OLDER_7DAYS);
        }
        return result;
    }
    doesVolumeContainVulnerabilitiesV2(volume, snapshots) {
        const instancesVolumeIds = this.getInstancesVolumeIds();
        if (!instancesVolumeIds.has(volume.getId())) {
            return true;
        }
        if (volume.isUnencrypted()) {
            return true;
        }
        if (volume.doesNotUseCustomerManagedKey()) {
            return true;
        }
        if (volume.isNotCyberScanned()) {
            return true;
        }
        if (volume.isNotRecoveryTested()) {
            return true;
        }
        if (snapshots.length === 0) {
            return true;
        }
        if (snapshots.length > 0) {
            const older7DaysNumber = snapshots.filter((s) => Boolean(s.isOlderThen7Days()))?.length;
            if (older7DaysNumber === snapshots.length) {
                return true;
            }
        }
        return false;
    }
    doesVolumeContainVulnerabilities(volume, snapshots) {
        const instancesVolumeIds = this.getInstancesVolumeIds();
        if (!instancesVolumeIds.has(volume.getId())) {
            return true;
        }
        if (volume.isUnencrypted()) {
            return true;
        }
        if (volume.doesNotUseCustomerManagedKey()) {
            return true;
        }
        if (volume.isNotCyberScanned()) {
            return true;
        }
        if (snapshots.length === 0) {
            return true;
        }
        if (snapshots.length > 0) {
            const older7DaysNumber = snapshots.filter((s) => Boolean(s.isOlderThen7Days()))?.length;
            if (older7DaysNumber === snapshots.length) {
                return true;
            }
        }
        return false;
    }
    doesSnapshotContainVulnerabilitiesV2(snapshot) {
        if (snapshot.isOlderThen30Days()) {
            return true;
        }
        if (snapshot.isPublic()) {
            return true;
        }
        if (snapshot.isUnencrypted()) {
            return true;
        }
        return false;
    }
    doesSnapshotContainVulnerabilities(snapshot) {
        if (snapshot.isPublic()) {
            return true;
        }
        if (snapshot.isUnencrypted()) {
            return true;
        }
        return false;
    }
    // TODO: consider use memo
    getVolumesWithVulnerabilitiesNumber() {
        if (!this.tenant.getVulnerabilitiesEnabled()) {
            return 0;
        }
        let volumesWithVulnerabilities = 0;
        this.volumeList?.forEach((volume) => {
            const currentVolumeSnapshots = this.snapshotsByVolume.get(volume.getId()) ?? [];
            if (this.doesVolumeContainVulnerabilities(volume, currentVolumeSnapshots)) {
                volumesWithVulnerabilities++;
            }
        });
        return volumesWithVulnerabilities;
    }
    // TODO: consider use memo
    getSnapshotsWithVulnerabilitiesNumber() {
        if (!this.tenant.getVulnerabilitiesEnabled()) {
            return 0;
        }
        let snapshotsWithVulnerabilities = 0;
        this.snapshotList?.forEach((snapshot) => {
            if (this.doesSnapshotContainVulnerabilities(snapshot)) {
                snapshotsWithVulnerabilities++;
            }
        });
        return snapshotsWithVulnerabilities;
    }
    // TODO: consider use memo
    // total number of snapshots vulnerabilities
    // SnapshotsOlderThen30Days, SnapshotsWithPublicPermissions, SnapshotsUnencrypted
    getSnapshotsVulnerabilitiesTotalNumberV2() {
        let snapshotsOlderThen30Days = 0;
        let snapshotsWithPublicPermissions = 0;
        let snapshotsUnencrypted = 0;
        this.snapshotList?.forEach((snapshot) => {
            if (this.doesSnapshotContainVulnerabilities(snapshot)) {
                if (snapshot.isOlderThen30Days()) {
                    snapshotsOlderThen30Days++;
                }
                if (snapshot.isPublic()) {
                    snapshotsWithPublicPermissions++;
                }
                if (snapshot.isUnencrypted()) {
                    snapshotsUnencrypted++;
                }
            }
        });
        return (snapshotsOlderThen30Days +
            snapshotsWithPublicPermissions +
            snapshotsUnencrypted);
    }
    getSnapshotsVulnerabilitiesTotalNumber() {
        let snapshotsWithPublicPermissions = 0;
        let snapshotsUnencrypted = 0;
        this.snapshotList?.forEach((snapshot) => {
            if (this.doesSnapshotContainVulnerabilities(snapshot)) {
                if (snapshot.isPublic()) {
                    snapshotsWithPublicPermissions++;
                }
                if (snapshot.isUnencrypted()) {
                    snapshotsUnencrypted++;
                }
            }
        });
        return snapshotsWithPublicPermissions + snapshotsUnencrypted;
    }
    // TODO: consider use memo
    // total number of volumes vulnerabilities
    // VolumesUnencrypted, VolumesUnattached, SnapshotsNotCovered, VolumesDontUseCustomerManagedKey,
    // VolumesWithSnapshotsOlderThan7Days, VolumesNotCyberScanned, VolumesNotRecoveryTested
    getVolumesVulnerabilitiesTotalNumberV2() {
        let volumesUnencrypted = 0;
        let volumesUnattached = 0;
        let volumesNotCoveredWithSnapshots = 0;
        let volumesDontUseCustomerManagedKey = 0;
        let volumesWithSnapshotsOlderThan7Days = 0;
        let volumesNotCyberScanned = 0;
        let volumesNotRecoveryTested = 0;
        const instancesVolumeIds = this.getInstancesVolumeIds();
        this.volumeList?.forEach((volume) => {
            const currentVolumeSnapshots = this.snapshotsByVolume.get(volume.getId()) ?? [];
            if (!instancesVolumeIds.has(volume.getId())) {
                volumesUnattached++;
            }
            if (volume.isUnencrypted()) {
                volumesUnencrypted++;
            }
            if (volume.doesNotUseCustomerManagedKey()) {
                volumesDontUseCustomerManagedKey++;
            }
            if (volume.isNotCyberScanned()) {
                volumesNotCyberScanned++;
            }
            if (volume.isNotRecoveryTested()) {
                volumesNotRecoveryTested++;
            }
            if (currentVolumeSnapshots.length === 0) {
                volumesNotCoveredWithSnapshots++;
            }
            if (currentVolumeSnapshots.length > 0) {
                const older7DaysNumber = currentVolumeSnapshots.filter((s) => Boolean(s.isOlderThen7Days()))?.length;
                if (older7DaysNumber === currentVolumeSnapshots.length) {
                    volumesWithSnapshotsOlderThan7Days++;
                }
            }
        });
        return (volumesUnencrypted +
            volumesUnattached +
            volumesNotCoveredWithSnapshots +
            volumesDontUseCustomerManagedKey +
            volumesWithSnapshotsOlderThan7Days +
            volumesNotCyberScanned +
            volumesNotRecoveryTested);
    }
    getVolumesVulnerabilitiesTotalNumber() {
        if (!this.tenant?.getVulnerabilitiesEnabled) {
            return 0;
        }
        let volumesUnencrypted = 0;
        let volumesUnattached = 0;
        let volumesNotCoveredWithSnapshots = 0;
        let volumesDontUseCustomerManagedKey = 0;
        let volumesWithSnapshotsOlderThan7Days = 0;
        let volumesNotCyberScanned = 0;
        const instancesVolumeIds = this.getInstancesVolumeIds();
        this.volumeList?.forEach((volume) => {
            const currentVolumeSnapshots = this.snapshotsByVolume.get(volume.getId()) ?? [];
            if (!instancesVolumeIds.has(volume.getId())) {
                volumesUnattached++;
            }
            if (volume.isUnencrypted()) {
                volumesUnencrypted++;
            }
            if (volume.doesNotUseCustomerManagedKey()) {
                volumesDontUseCustomerManagedKey++;
            }
            if (volume.isNotCyberScanned()) {
                volumesNotCyberScanned++;
            }
            if (currentVolumeSnapshots.length === 0) {
                volumesNotCoveredWithSnapshots++;
            }
            if (currentVolumeSnapshots.length > 0) {
                const older7DaysNumber = currentVolumeSnapshots.filter((s) => Boolean(s.isOlderThen7Days()))?.length;
                if (older7DaysNumber === currentVolumeSnapshots.length) {
                    volumesWithSnapshotsOlderThan7Days++;
                }
            }
        });
        return (volumesUnencrypted +
            volumesUnattached +
            volumesNotCoveredWithSnapshots +
            volumesDontUseCustomerManagedKey +
            volumesWithSnapshotsOlderThan7Days +
            volumesNotCyberScanned);
    }
    // TODO: consider use memo
    // used for EBS Protection Level widget
    getVolumesProtected() {
        return this.volumeList?.filter((volume) => volume.isLastRecoveryPointId())
            .length;
    }
    // used for EBS Protection Level widget
    getVolumesUnprotected() {
        return this.getVolumesNumber() - this.getVolumesProtected();
    }
    // used for EC2 Protection Level widget
    getInstancesProtected() {
        return this.instanceList?.filter((instance) => instance.isProtected())
            .length;
    }
    // used for EC2 Protection Level widget
    getInstancesUnprotected() {
        return this.getInstancesNumber() - this.getInstancesProtected();
    }
    getInstanceAttachedVolumesWithFailedFsCheck(instance) {
        const volumeIds = instance.getVolumeIds();
        if (!volumeIds.length) {
            return [];
        }
        const volumesList = this.allVolumeList?.filter((v) => volumeIds.includes(v.getId()) && v.isFsCheckFailed());
        return volumesList;
    }
    getInstancesNumberWithFailedFsCheck() {
        const instancesWithFailedFsCheck = this.instanceList?.filter((instance) => this.getInstanceAttachedVolumesWithFailedFsCheck(instance).length > 0);
        return instancesWithFailedFsCheck.length;
    }
    getRunningInstancesNumberWithFailedFsCheck() {
        const instancesWithFailedFsCheck = this.instanceList?.filter((instance) => instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED &&
            instance.isFsCheckFailed());
        return instancesWithFailedFsCheck.length;
    }
    getVolumesUnattachedNumberWithFailedFsCheck() {
        const volumeIds = this.getInstancesVolumeIds();
        return this.volumeList?.filter((v) => v.isFsCheckFailed() && !volumeIds.has(v.getId())).length;
    }
    getInfectedInstancesNumberByMalware(isOnlyRunning = false) {
        const instancesByMalware = new Map();
        const instances = this.instanceList.filter((instance) => isOnlyRunning
            ? instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED
            : instance);
        instances
            .filter((instance) => instance.getMalwareThreatsNumber() > 0 ? instance : null)
            .map((instance) => {
            instance.getUniqueMalwareNames()?.forEach((name) => {
                if (instancesByMalware.has(name)) {
                    instancesByMalware.set(name, instancesByMalware.get(name) + 1);
                }
                else {
                    instancesByMalware.set(name, 1);
                }
            });
        });
        return instancesByMalware;
    }
    getInfectedRunningInstancesNumberByMalware() {
        // filter only running instances
        return this.getInfectedInstancesNumberByMalware(true);
    }
    getInfectedInstancesNumberByRansomware(isOnlyRunning = false) {
        const instancesByRansomvare = new Map();
        const instances = this.instanceList
            .filter((instance) => isOnlyRunning
            ? instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED
            : instance)
            .filter((instance) => instance.hasThreat());
        instances
            .filter((instance) => instance.getRansomwareThreatsNumber() > 0 ? instance : null)
            .map((instance) => {
            instance.getUniqueRansomwareNames()?.forEach((name) => {
                if (instancesByRansomvare.has(name)) {
                    instancesByRansomvare.set(name, instancesByRansomvare.get(name) + 1);
                }
                else {
                    instancesByRansomvare.set(name, 1);
                }
            });
        });
        return instancesByRansomvare;
    }
    getInfectedRunningInstancesNumberByRansomware() {
        // filter only running instances
        return this.getInfectedInstancesNumberByRansomware(true);
    }
    getInfectedUnattachedVolumesNumber() {
        const attachedVolumeIds = this.getInstancesVolumeIds();
        const unattachedVolumesWithThreats = this.volumeList?.filter((volume) => volume.isInfected() && !attachedVolumeIds.has(volume.getId()));
        return unattachedVolumesWithThreats.length;
    }
    getThreatsNumberForRunningInstancesAndUnattachedVolumes() {
        const attachedVolumeIds = this.getInstancesVolumeIds();
        const runningInstances = this.instanceList.filter((instance) => instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED);
        const unattachedVolumes = this.volumeList.filter((volume) => !attachedVolumeIds.has(volume.getId()));
        const threatsPerVolume = unattachedVolumes?.map((volume) => volume.getUniqueThreatNames());
        const threatsPerInstance = runningInstances?.map((instance) => instance.getUniqueThreatNames());
        const allThreats = new Set();
        threatsPerVolume.forEach((threat) => {
            threat.forEach((value) => allThreats.add(value));
        });
        threatsPerInstance.forEach((threat) => {
            threat.forEach((value) => allThreats.add(value));
        });
        return allThreats.size;
    }
    getVolumesModelsForInstance(volumeIdsForInstance) {
        const volumesModelList = [];
        this.allVolumeList.filter((volumeModel) => {
            volumeIdsForInstance.includes(volumeModel.getId())
                ? volumesModelList.push(volumeModel)
                : null;
        });
        return volumesModelList;
    }
    getInstanceModel(instanceId) {
        return this.allInstanceList.find((instance) => instance.getParsedCloudProviderInstanceId() === instanceId
            ? instance
            : null);
    }
    getVolumeModel(volumeId) {
        return this.allVolumeList.find((volume) => volume.getParsedCloudProviderVolumeId() === volumeId ? volume : null);
    }
    getS3BucketModel(s3BucketId) {
        return this.allS3BucketList.find((s3Bucket) => s3Bucket.getParsedCloudProviderAssetId() === s3BucketId ? s3Bucket : null);
    }
    getGenericHostModel(genericHostId) {
        return this.allGenericHostList.find((genericHost) => genericHost.getParsedCloudProviderAssetId() === genericHostId
            ? genericHost
            : null);
    }
    getVolumeModelByCloudProviderId(volumeId) {
        return this.allVolumeList.find((volume) => volume.getCloudProviderVolumeId() === volumeId ? volume : null);
    }
    getSnapshotModel(snapshotId) {
        return this.allSnapshotList.find((snapshot) => snapshot.getParsedCloudProviderSnapshotId() === snapshotId
            ? snapshot
            : null);
    }
    getInstanceIdsListForAttachedVolume(volumeModel) {
        const instancesForVolume = [];
        this.allInstanceList.filter((instance) => instance.getVolumeIds().includes(volumeModel.getId())
            ? instancesForVolume.push(instance?.getParsedCloudProviderInstanceId())
            : null);
        return instancesForVolume;
    }
    getTenant() {
        return this.tenant;
    }
    //  Fs Check succeeded
    getRunningInstancesNumberWithSucceededFsCheck() {
        const instancesWithSucceededFsCheck = this.instanceList?.filter((instance) => instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED &&
            instance.isFsCheckSucceeded());
        return instancesWithSucceededFsCheck.length;
    }
    getVolumesUnattachedNumberWithSucceededFsCheck() {
        const volumeIds = this.getInstancesVolumeIds();
        return this.volumeList?.filter((v) => v.isFsCheckSucceeded() && !volumeIds.has(v.getId())).length;
    }
    // iscan succeeded
    getRunningInstancesNumberWithSucceededIscan() {
        const instancesWithSucceededIscan = this.instanceList?.filter((instance) => instance.getState() !== instance_model_1.InstanceState.INSTANCE_STATE_STOPPED &&
            instance.getIscanStatus() === threat_status_model_1.IscanStatus.SUCCEEDED);
        return instancesWithSucceededIscan.length;
    }
    getVolumesUnattachedNumberWithSucceededIscan() {
        const volumeIds = this.getAllInstancesVolumeIds();
        return this.volumeList?.filter((v) => v.getIscanStatus() === threat_status_model_1.IscanStatus.SUCCEEDED &&
            !volumeIds.has(v.getId())).length;
    }
    getSnapshotsWithVulnerabilitiesPossibleFilters(riskIdentificationStrategy) {
        const accountIds = new Set();
        const regions = new Set();
        const snapshotsVulnerabilitiesTypes = new Set();
        const riskLevel = new Set();
        this.snapshotList
            ?.filter((s) => s.getSnapshotVulnerabilitiesTypes().length)
            .forEach((snapshot) => {
            accountIds.add(snapshot.getAccountId());
            regions.add(snapshot.getRegion());
            const vulnerabilitiesTypes = snapshot.getSnapshotVulnerabilitiesTypes();
            vulnerabilitiesTypes.forEach((vulnerability) => snapshotsVulnerabilitiesTypes.add(vulnerability));
            const risks = [
                ...new Set(vulnerabilitiesTypes.map(riskIdentificationStrategy.identifyRiskLevel)),
            ];
            risks.forEach((risk) => riskLevel.add(risk));
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            snapshotVulnerabilityTypes: [...snapshotsVulnerabilitiesTypes],
            riskType: [...riskLevel].sort(),
        };
    }
    getSnapshotsWithVulnerabilitiesData(filters, riskIdentificationStrategy) {
        return this.snapshotList
            ?.filter((s) => s.getSnapshotVulnerabilitiesTypes().length)
            ?.filter((snapshot) => {
            const vulnerabilitiesTypes = snapshot.getSnapshotVulnerabilitiesTypes();
            const risks = [
                ...new Set(vulnerabilitiesTypes.map(riskIdentificationStrategy.identifyRiskLevel)),
            ];
            if (!matchesIncludeFilter(filters.accountIds, snapshot.getAccountId())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.regions, snapshot.getRegion())) {
                return false;
            }
            if (filters.snapshotVulnerabilityTypes) {
                if (!filters.snapshotVulnerabilityTypes.some((vulnerability) => vulnerabilitiesTypes.includes(vulnerability))) {
                    return false;
                }
            }
            if (filters.riskType?.length &&
                !filters.riskType?.some((el) => risks.includes(el))) {
                return false;
            }
            return true;
        })
            .map((snapshot) => ({
            snapshot,
            snapshotVulnerabilityTypes: snapshot.getSnapshotVulnerabilitiesTypes(),
        }));
    }
    getUnscannedVolumes() {
        return this.volumeList.filter((volume) => volume.isNotCyberScanned());
    }
    getAssetsWithRecoveryExposurePossibleFilters() {
        const volumesWithThreats = this.volumeList.filter((volume) => volume.isInfected() || volume.isFsCheckFailed());
        const assetsWithRecoveryExposure = [
            ...new Set(this.getUnscannedVolumes().concat(volumesWithThreats)),
        ];
        const accountIds = new Set();
        const regions = new Set();
        const malwaresList = new Set();
        const ransomwaresList = new Set();
        const fsChecks = new Set();
        const ebsVulnerabilities = new Set();
        assetsWithRecoveryExposure.forEach((volume) => {
            accountIds.add(volume.getAccountId());
            regions.add(volume.getRegion());
            const vulnerabilities = this.volumeVulnerabilitiesList(volume, this.snapshotsByVolume.get(volume.getId()) ?? []);
            vulnerabilities.forEach((vulnerability) => {
                if (vulnerability !== index_1.EbsVulnerabilityKind.NOT_CYBER_SCANNED) {
                    ebsVulnerabilities.add(vulnerability);
                }
            });
        });
        assetsWithRecoveryExposure.forEach((asset) => {
            accountIds.add(asset.getAccountId());
            regions.add(asset.getRegion());
            asset
                .getUniqueMalwareNames()
                .forEach((malware) => malwaresList.add(malware));
            asset
                .getUniqueRansomwareNames()
                .forEach((ransomware) => ransomwaresList.add(ransomware));
            if (asset.getFsCheckStatus()) {
                fsChecks.add(asset.getFsCheckStatus());
            }
        });
        return {
            accountIds: [...accountIds],
            regions: [...regions],
            malwaresList: [...malwaresList],
            ransomwaresList: [...ransomwaresList],
            fsChecks: [...fsChecks],
            ebsVulnerabilities: [...ebsVulnerabilities],
        };
    }
    getVolumesWithRecoveryExposureData(filters) {
        const volumesWithThreats = this.volumeList.filter((volume) => volume.isInfected() || volume.isFsCheckFailed());
        const assetsWithRecoveryExposure = [
            ...new Set(this.getUnscannedVolumes().concat(volumesWithThreats)),
        ];
        return assetsWithRecoveryExposure
            .filter((asset) => {
            if (!matchesIncludeFilter(filters.accountIds, asset.getAccountId())) {
                return false;
            }
            if (!matchesIncludeFilter(filters.regions, asset.getRegion())) {
                return false;
            }
            if (filters.malwaresList &&
                !filters.malwaresList?.some((malware) => asset.containsExactMalware(malware))) {
                return false;
            }
            if (filters.ransomwaresList?.length &&
                !filters.ransomwaresList?.some((item) => asset.containsExactRansomware(item))) {
                return false;
            }
            if (!matchesIncludeFilter(filters.fsCheck, asset.getFsCheckStatus())) {
                return false;
            }
            if (filters.ebsVulnerabilities) {
                const curVolumeSnapshotList = this.snapshotsByVolume.get(asset.getId()) ?? [];
                const curVolumeVulnerabilities = this.volumeVulnerabilitiesList(asset, curVolumeSnapshotList);
                if (!filters.ebsVulnerabilities.some((el) => curVolumeVulnerabilities.includes(el))) {
                    return false;
                }
            }
            return true;
        })
            .map((volume) => ({
            volume,
            instances: this.instanceList?.filter((instanceModel) => instanceModel.getVolumeIds().includes(volume.getId())),
        }));
    }
    getInstancesCoveredByPolicyNumber() {
        return this.instanceList.filter((instance) => instance.isCoveredByElastioPolicyOrAwsBackup()).length;
    }
    getInstancesOutOfScheduleNumber() {
        return this.instanceList.filter((instance) => instance.isOutOfSchedule())
            .length;
    }
    getVolumesCoveredByPolicyNumber() {
        return this.volumeList.filter((volume) => volume.isCoveredByElastioPolicyOrAwsBackup()).length;
    }
    getVolumesOutOfScheduleNumber() {
        return this.volumeList.filter((volume) => volume.isOutOfSchedule()).length;
    }
}
exports.InventoryModel = InventoryModel;
function matchesIncludeFilter(filters, value) {
    if (!filters) {
        return true;
    }
    if (!value) {
        return false;
    }
    return filters.includes(value);
}
function slice(list, { accountIds, regions, include_tags, exclude_tags }) {
    if (!accountIds && !regions && !include_tags && !exclude_tags) {
        return list;
    }
    return list.filter((item) => {
        if (accountIds && !accountIds.includes(item.getAccountId())) {
            return false;
        }
        if (regions && !regions.includes(item.getRegion())) {
            return false;
        }
        if (include_tags && !matchTags(item, include_tags)) {
            return false;
        }
        if (exclude_tags && matchTags(item, exclude_tags)) {
            return false;
        }
        return true;
    });
}
function matchTags(item, { tags, operator }) {
    switch (operator) {
        case SliceCriteriaTagsCombinationOperator.AND:
            return matchAllTags(item, tags);
        case SliceCriteriaTagsCombinationOperator.OR:
            return matchAnyTag(item, tags);
        default:
            return false;
    }
}
function matchAllTags(item, tags) {
    const itemTags = item.getTagsMap();
    for (const { key, value } of tags) {
        const itemValue = itemTags.get(key);
        if (itemValue !== undefined && (value === null || itemValue === value)) {
            continue;
        }
        else {
            return false;
        }
    }
    return true;
}
function matchAnyTag(item, tags) {
    const itemTags = item.getTagsMap();
    for (const { key, value } of tags) {
        const itemValue = itemTags.get(key);
        if (itemValue !== undefined && (value === null || itemValue === value)) {
            return true;
        }
    }
    return false;
}
