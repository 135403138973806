/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Box from '@mui/material/Box'
import { AssetItem } from 'blues-corejs/dist'
import { computeAssetItemIcon } from './compute-asset-item-icon'

interface Props {
  assetItem: AssetItem
}

function AssetItemIconCell({ assetItem }: Props) {
  const icon = computeAssetItemIcon(assetItem)

  return (
    <Box display="flex" justifyContent="center" maxWidth="40px">
      {icon}
    </Box>
  )
}

export { AssetItemIconCell }
