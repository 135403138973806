/* eslint-disable import/no-extraneous-dependencies */
import { FilesystemScanCheck } from '@lib/models/scans'
import { StatusScanFilesystemCheck } from 'blues-corejs/dist/models/scans/filesystem-check/types'
import React from 'react'
import {
  FilesystemScanBackup,
  GenericHost,
  OvaServer,
} from 'blues-corejs/dist/models'
import { DownloadScan, ListLiveAssetsClient } from '@lib/clients'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import IconMUI from '@mui/material/Icon'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

const downloadScanClient = new DownloadScan()
const assetsClient = new ListLiveAssetsClient()

function TextRow({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  return (
    <Box>
      <Box component="b" fontWeight="500" fontSize="14px" marginRight="5px">
        {title}:
      </Box>
      {children && children}
    </Box>
  )
}

interface ReportMenuProps {
  options: Array<{
    label: string
    onClick: () => void
  }>
}

export function ReportMenu({ options }: ReportMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          justifyContent: 'flex-start',
          paddingLeft: '0',
          width: 'min-content',
        }}
      >
        Download reports
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.label}
            onClick={() => {
              option.onClick()
              handleClose()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

type Props = {
  scans: Array<FilesystemScanCheck>
}

function renderLoading() {
  return (
    <Skeleton
      animation="wave"
      height={10}
      width="80%"
      style={{ marginBottom: 6 }}
    />
  )
}

function computeDetectedThreatIcon(scans: Array<FilesystemScanCheck>) {
  if (scans.some((scan) => scan.status === StatusScanFilesystemCheck.BAD)) {
    return {
      icon: ErrorIcon,
      label: 'Corrupted',
      color: 'el-error-bright',
    } as const
  }

  return {
    icon: CheckCircle,
    label: 'Healthy',
    color: 'el-success',
  } as const
}

function computePartitionsCount(scans: Array<FilesystemScanCheck>) {
  if (scans.some((scan) => scan.status === StatusScanFilesystemCheck.BAD)) {
    return scans.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.corruptedPartitionsCount,
      0
    )
  }

  return scans.reduce(
    (accumulator, currentValue) => accumulator + currentValue.partitions.length,
    0
  )
}

function RowTitle({ title }: { title: string }) {
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      marginRight="5px"
      variant="caption"
    >
      {title}:
    </Typography>
  )
}

function computeColor(value: number, scans: Array<FilesystemScanCheck>) {
  if (scans.some((scan) => scan.status === StatusScanFilesystemCheck.BAD)) {
    return value > 0 ? 'var(--red500)' : 'var(--grey900)'
  }

  return 'var(--grey900)'
}

function computeFontWeight(value: number) {
  return value > 0 ? 'var(--font-weight-medium)' : 'var(--font-weight-normal)'
}

function DetectedThreat({ scans }: { scans: Array<FilesystemScanCheck> }) {
  const computedIcon = computeDetectedThreatIcon(scans)

  const firstScan = scans[0]

  if (!computedIcon || !firstScan) {
    return null
  }

  return (
    <Box display="flex" alignItems="center">
      <RowTitle title="Filesystem" />
      <Box display="flex" alignItems="center">
        <IconMUI
          color={computedIcon.color}
          fontSize="small"
          component={computedIcon.icon}
        />
        <Typography marginX="5px" variant="body2" component="span">
          {computedIcon.label}
        </Typography>
        (
        <DateTimeTooltip direction="row" date={firstScan.createdAt} />)
      </Box>
    </Box>
  )
}

function mapReportsMenu({
  reportLink,
  assetId,
}: {
  assetId: string
  reportLink: string
}) {
  return {
    label: assetId,
    onClick: () => window.open(reportLink, '_blank'),
  }
}

function renderFsCheckDetails(
  scans: Array<FilesystemScanCheck>,
  reportsLinksState: Array<{
    assetId: string
    reportLink: string
  }>
) {
  const partitionsCount = computePartitionsCount(scans)

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <DetectedThreat scans={scans} />
      <TextRow title="Partitions">
        <Typography
          variant="caption"
          fontSize="14px"
          fontWeight={computeFontWeight(partitionsCount)}
          sx={{ color: computeColor(partitionsCount, scans) }}
        >
          {partitionsCount}
        </Typography>
      </TextRow>
      <ReportMenu options={reportsLinksState.map(mapReportsMenu)} />
    </Box>
  )
}

function getAssetIdFromScanTarget(scan: FilesystemScanCheck): string {
  if (scan.scanTarget.target instanceof FilesystemScanBackup) {
    return (scan.scanTarget?.target?.source?.asset?.backupAssetId ||
      scan.scanTarget?.target?.source?.assetItem?.backupAssetId) as string
  }
  // Direct scan
  if ('assetId' in scan.scanTarget.target) {
    return scan.scanTarget.target.assetId
  }

  throw new Error('Invalid scan target')
}

function useGetAssetList(assetIds: Array<string>) {
  const [assetList, setAssetList] = React.useState<
    Array<{
      displayName: string
      id: string
    }>
  >([])

  const [isAssetListLoading, setIsAssetListLoading] = React.useState(false)

  React.useEffect(() => {
    async function getAssetList() {
      try {
        setIsAssetListLoading(true)
        const response = await assetsClient.listAssetsByIds(assetIds)

        setAssetList(
          response.map((asset) => {
            if (asset instanceof AWSAsset) {
              return {
                displayName: asset.awsId,
                id: asset.id,
              }
            }
            if (asset instanceof GenericHost) {
              return {
                displayName: asset.hostname,
                id: asset.id,
              }
            }
            if (asset instanceof OvaServer) {
              return {
                displayName: asset.name,
                id: asset.id,
              }
            }

            throw new Error('Invalid asset type')
          })
        )
      } catch (e) {
        console.error(e)
      } finally {
        setIsAssetListLoading(false)
      }
    }

    getAssetList()

    return () => setAssetList([])
  }, [assetIds.length])

  return {
    assetList,
    isAssetListLoading,
  }
}

function getAssetWithRelatedScanIds(scans: Array<FilesystemScanCheck>) {
  const assetWithRelatedScanIds = new Map<string, FilesystemScanCheck>()

  for (const scan of scans) {
    const scanTargetAssetId = getAssetIdFromScanTarget(scan)
    const currentScan = assetWithRelatedScanIds.get(scanTargetAssetId)

    if (!currentScan) {
      assetWithRelatedScanIds.set(scanTargetAssetId, scan)
    }
  }

  return assetWithRelatedScanIds
}

function fetchReportLinksWithRelatedAssetId({
  assetWithRelatedScanIds,
  assetList,
}: {
  assetWithRelatedScanIds: Map<string, FilesystemScanCheck>
  assetList: Array<{
    displayName: string
    id: string
  }>
}) {
  return Array.from(assetWithRelatedScanIds, async ([assetId, scan]) => {
    const reportLink = await downloadScanClient.downloadScan(scan.id)
    const displayedAssetName =
      assetList.find((asset) => asset.id === assetId)?.displayName || ''

    return {
      assetId: displayedAssetName,
      reportLink,
    }
  })
}

function useGetReportLinksWithRelatedAssetId({
  scans,
}: {
  scans: Array<FilesystemScanCheck>
}) {
  const [isReportLinksLoading, setIsReportLinksLoading] = React.useState(false)

  const assetWithRelatedScanIds = getAssetWithRelatedScanIds(scans)

  const { assetList, isAssetListLoading } = useGetAssetList(
    scans.map(getAssetIdFromScanTarget)
  )

  const [reportLinksState, setReportLinksState] = React.useState<
    Array<{
      assetId: string
      reportLink: string
    }>
  >([])

  React.useEffect(() => {
    async function getReportLinksWithRelatedAssetId() {
      if (scans.length === 0 || assetList.length === 0) {
        return
      }
      try {
        setIsReportLinksLoading(true)

        const reportLinks = fetchReportLinksWithRelatedAssetId({
          assetWithRelatedScanIds,
          assetList,
        })

        setReportLinksState(await Promise.all(reportLinks))
      } catch (e) {
        console.error(e)
      } finally {
        setIsReportLinksLoading(false)
      }
    }

    getReportLinksWithRelatedAssetId()

    return () => setReportLinksState([])
  }, [scans.length, assetList.length])

  return {
    isReportLinksLoading: isReportLinksLoading || isAssetListLoading,
    reportLinksState,
  }
}

function FsCheckScanDetails({ scans }: Props) {
  const { isReportLinksLoading, reportLinksState } =
    useGetReportLinksWithRelatedAssetId({
      scans,
    })

  if (isReportLinksLoading) {
    return renderLoading()
  }

  return renderFsCheckDetails(scans, reportLinksState)
}

export default FsCheckScanDetails
