import React, { memo } from 'react'
import RadioTabs from '@components-simple/radio-tabs/RadioTabs'
import FirstRunDataInterface from '@lib/interfaces/first-run-data.interface'
import { EngineCallback } from '@lib/engine-types'
import Datetime from '@components-composite/datetime/Datetime'
import DatetimeDataInterface from '@lib/interfaces/datetime-data.interface'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface Props {
  data: FirstRunDataInterface
  onChange: EngineCallback<FirstRunDataInterface>
}

const FIRST_RUN_TITLE = 'First run'
const RADIO_TABS_LABELS = ['Activate now', 'Pause until...']

function FirstRun({ data, onChange }: Props) {
  const currentTabIndex = data.runNow ? 0 : 1

  const onTabChange = (newTabIndex: number) => {
    onChange({
      ...data,
      runNow: newTabIndex === 0,
    })
  }

  const onDatetimeChange = (newDatetime: DatetimeDataInterface) => {
    onChange({
      ...data,
      runOn: newDatetime,
    })
  }

  return (
    <Box className="wrap-1642064951684">
      <Box className="firstRunLbl">{FIRST_RUN_TITLE}</Box>
      <Box className="policiesBlockLabel">
        This is when the first backup / scan will run{' '}
        <Typography variant="caption" fontSize="14px" fontWeight={500}>
          (GMT+0:00) UTC
        </Typography>
      </Box>
      <RadioTabs
        currentTabIndex={currentTabIndex}
        onCurrentTabChange={onTabChange}
        labels={RADIO_TABS_LABELS}
        mode="disableInactive"
        className="jsFirstRunRadioTabs firstRunRadioTabs"
      >
        <Box className="emptyFirstTab" />
        <Box>
          <Datetime disablePast data={data.runOn} onChange={onDatetimeChange} />
        </Box>
      </RadioTabs>
    </Box>
  )
}

export default memo(FirstRun)
