import ValueInterface from '@lib/interfaces/value.interface'
import {
  setEfsAssetsSelectFilters,
  setEfsAssetsSelectSearch,
} from '@store/actions/assets-select.action'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEfsAssetsSelectAllFilters,
  getEfsAssetsSelectFilters,
  getEfsAssetsSelectSearch,
} from '@store/selectors/assets-select.selector'

export function useEfsFilter() {
  const dispatch = useDispatch()
  const possibleFilters = useSelector(getEfsAssetsSelectAllFilters)
  const selectedFilters = useSelector(getEfsAssetsSelectFilters)
  const searchVal = useSelector(getEfsAssetsSelectSearch)

  const handleEfsFiltersChange = (newFilters: Array<ValueInterface>) => {
    dispatch(setEfsAssetsSelectFilters(newFilters))
  }

  const handleSearchChange = (value: string) => {
    dispatch(setEfsAssetsSelectSearch(value))
  }
  return {
    possibleFilters,
    selectedFilters,
    searchVal,
    handleEfsFiltersChange,
    handleSearchChange,
  }
}
