/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { styled } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { OvaBackup, OvaServer } from 'blues-corejs/dist'
import { RowTitle } from '../row-title'
import { CopyToClipboardIcon } from '@features/common'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'
import { useFetchLastCleanRpForAsset } from '@components-complex/dashboard-pages-v3/hooks/use-fetch-last-clean-rp-for-asset'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { RecoveryPointDetails } from '@features/asset-page/modals/recovery-point-details'
import { useToggle } from '@lib/hooks'
import Box from '@mui/system/Box'

export const LatestCleanRpDateContainer = styled(Box)({
  '.date-time-tooltip-stack': {
    fontWeight: 500,
    fontSize: 14,
    color: 'var(--blue500)',
    cursor: 'pointer',
  },
})

interface Props {
  asset: OvaServer
}

// Since `dayjs` does not have a built-in function to format dates as "X days old", "X hours old", etc.
// We should use this function to format the date like on the design
// FIXME: after date behaviour bug fixed, add here the correct implementation with timezones
function pluralize(value: number, unit: string) {
  return `${value} ${unit}${value === 1 ? '' : 's'} old`
}
export function customFormatDate(target: Dayjs) {
  const now = dayjs()

  const diffYears = now.diff(target, 'year')
  const diffMonths = now.diff(target, 'month')
  const diffDays = now.diff(target, 'day')
  const diffHours = now.diff(target, 'hour')
  const diffMinutes = now.diff(target, 'minute')

  if (diffYears >= 1) {
    return pluralize(diffYears, 'year')
  } else if (diffMonths >= 1) {
    return pluralize(diffMonths, 'month')
  } else if (diffDays >= 1) {
    return pluralize(diffDays, 'day')
  } else if (diffHours >= 1) {
    return '< 1 day old'
  } else if (diffMinutes >= 1) {
    return '< 1 hr old'
  } else {
    return '< 1 min old'
  }
}

function OvaServerName({ asset }: Props) {
  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Server:" />
      <Typography fontSize="14px">{asset.name}</Typography>
      <CopyToClipboardIcon text={asset.name} fontSize={16} />
    </Stack>
  )
}

function AssetType() {
  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Asset Type:" />
      <Typography fontSize="14px">Server</Typography>
    </Stack>
  )
}

function OvaAccountSkeleton() {
  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Account:" />
      <Skeleton>
        <Typography fontSize="14px">ABC Inc.</Typography>
      </Skeleton>
    </Stack>
  )
}

function OvaAccount({ asset }: Props) {
  const { isOvaAccountsLoading, ovaAccountsList } = useFetchListOvaAccounts()

  if (isOvaAccountsLoading) {
    return <OvaAccountSkeleton />
  }

  const ovaAccountName =
    ovaAccountsList.find((account) => account.id === asset.ovaAccountId)
      ?.name || ''

  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Account:" />
      <Typography fontSize="14px">{ovaAccountName}</Typography>
    </Stack>
  )
}

function CleanRecoveryPointTimestamp({
  backup,
  asset,
}: {
  backup: OvaBackup
  asset: OvaServer
}) {
  const {
    activate: openModal,
    deactivate: closeModal,
    isActive: isModalOpen,
  } = useToggle(false)

  return (
    <>
      <LatestCleanRpDateContainer alignItems="center" onClick={openModal}>
        <DateTimeTooltip direction="row" date={backup.timestamp} />
      </LatestCleanRpDateContainer>
      <Typography fontSize="14px" marginLeft="5px">
        ({customFormatDate(dayjs(backup.timestamp))})
      </Typography>
      <CopyToClipboardIcon text={backup.backupProviderBackupId} fontSize={16} />
      {isModalOpen && (
        <RecoveryPointDetails
          asset={asset}
          backup={backup}
          isRecoveryPointDetailsOpen={isModalOpen}
          onCloseRecoveryPointDetails={closeModal}
        />
      )}
    </>
  )
}

function LatestCleanRecoveryPoint({ asset }: Props) {
  const { isLastCleanRpLoading, latestCleanBackup } =
    useFetchLastCleanRpForAsset(asset.id)

  if (isLastCleanRpLoading) {
    return <Skeleton />
  }

  const isBackupExists = latestCleanBackup instanceof OvaBackup

  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Latest Clean Recovery Point:" />
      {isBackupExists ? (
        <CleanRecoveryPointTimestamp asset={asset} backup={latestCleanBackup} />
      ) : (
        <Typography fontSize="14px">None</Typography>
      )}
    </Stack>
  )
}

export default function OvaServerSummary({ asset }: Props) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      rowGap={2}
    >
      <OvaServerName asset={asset} />
      <AssetType />
      <OvaAccount asset={asset} />
      <LatestCleanRecoveryPoint asset={asset} />
    </Stack>
  )
}
