import type { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

import styles from './gradient-box.module.css'
import { SCALE_HEIGHT } from '@features/DashboardV4/widgets-with-scale/shared'

export type GradientBoxOwnProps = {
  height?: number
}

function GradientBox({
  height = SCALE_HEIGHT,
  children,
}: PropsWithChildren<GradientBoxOwnProps>) {
  return (
    <Box className={styles.root} style={{ height: `${height}px` }}>
      {children}
      <Box className={styles.gradient} />
    </Box>
  )
}

export default GradientBox
