import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { FormOptionValueSlug } from 'ui-v2/src/lib/models/reports'
import SolidCard from 'ui-v2/src/components/ui/cards/solid-card'

const ReportCard = ({ title, description, kindSlug }: ReportCardProps) => {
  const navigate = useNavigate()

  const handleNavigateSchedules = (
    reportForm: FormOptionValueSlug,
    kind: string
  ) => {
    navigate(`/reports/schedules/form/${reportForm}/${kind}`)
  }
  return (
    <SolidCard
      sx={{
        minHeight: 180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Typography
          mb={2}
          fontWeight={700}
          fontSize={16}
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          padding: 2.5,
          paddingTop: 0.5,
        }}
      >
        <Box display={'flex'} columnGap={3}>
          <Button
            endIcon={<CalendarTodayOutlinedIcon />}
            variant="text"
            color="primary"
            sx={{
              textTransform: 'capitalize',
              padding: 0,
              fontWeight: 700,
            }}
            onClick={() =>
              handleNavigateSchedules(FormOptionValueSlug.SCHEDULE, kindSlug)
            }
          >
            Schedule reports
          </Button>
          <Button
            endIcon={<PlayArrowIcon />}
            variant="text"
            color="primary"
            sx={{
              textTransform: 'capitalize',
              padding: 0,
              fontWeight: 700,
            }}
            onClick={() =>
              handleNavigateSchedules(FormOptionValueSlug.ON_DEMAND, kindSlug)
            }
          >
            On-demand
          </Button>
        </Box>
      </CardActions>
    </SolidCard>
  )
}
interface ReportCardProps {
  title: string
  description: string
  kind: string
  kindSlug: string
}
export default ReportCard
