/* eslint-disable import/no-extraneous-dependencies */
import { FileProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { File } from 'blues-corejs/dist'

type FilePt = FileProto.AsObject

export class FileTransformer {
  #file: FilePt

  constructor(file: FilePt) {
    this.#file = file
  }

  transform(): File {
    return this.#transformFile(this.#file)
  }

  #transformFile(file: FilePt): File {
    return new File({
      id: file.id,
      assetId: file.assetId,
      ccAssetId: file.ccAssetId,
      path: file.path,
      createdAt: secondsToDate(file.createdAt?.seconds),
      updatedAt: secondsToDate(file.updatedAt?.seconds),
    })
  }
}
