import React, { memo, useEffect } from 'react'
import { requestVault, setVault } from '@store/actions/vault.action'
import { useDispatch, useSelector } from 'react-redux'
import { getVaultModel } from '@store/selectors/vault.selector'
import VaultMetrics from '@components-complex/vault-metrics/VaultMetrics'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { VAULT_REPLICATION } from '@tables/core/table-constants'
import TableFactory from '@lib/factories/table.factory'
import StrHelper from '@lib/helpers/str.helper'
import ControlledVITable from '@tables/ControlledVITable'
import usePreloader from '@lib/hooks/usePreloader'
import FormEditVaultInterface from '@lib/interfaces/form/form-edit-vault.interface'
import DetailInfoCard from '@components-composite/detail-info-card/DetailInfoCard'
import { DetailInfoCardEntity } from '@lib/constants/detail-info.constant'
import EditVaultForm from '@forms/edit-vault/edit-vault.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { Button } from '@mui/material'
import VaultFactory from '@lib/factories/vault.factory'
import { useLayoutContext } from '@features/contexts'
import PagePathConstant from '@lib/constants/page-path.constant'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import PageHelper from '@lib/helpers/page.helper'
import { useScheduleTenantJob } from '@features/scheduled-jobs-monitoring/hooks'
import GrpcVaultService from '@lib/services/grpc/grpc-vault.service'
import { useParams } from 'react-router-dom'

function VaultDetailedComplex() {
  const { scheduleTenantJob } = useScheduleTenantJob()
  const dispatch = useDispatch()
  const { id: encodeVaultId } = useParams()
  const { setBreadcrumbsPaths, setHeaderTitle, setCurrentCrumbTitle } =
    useLayoutContext()
  const vaultId = StrHelper.base64Decode(encodeVaultId)
  const loading = usePreloader(PreloaderConstants.REQUEST_VAULT)
  const loadingUpdateVaultModal = usePreloader(PreloaderConstants.UPDATE_VAULT)

  useEffect(() => {
    if (vaultId) {
      dispatch(requestVault(vaultId))
      dispatch(setVault(VaultFactory.buildEmptyVault()))
      setBreadcrumbsPaths([])
    }

    return () => {
      setHeaderTitle(null)
      setCurrentCrumbTitle(null)
    }
  }, [vaultId])

  const vault = useSelector(getVaultModel)
  const accountId = vault.accountId

  useEffect(() => {
    if (accountId) {
      setBreadcrumbsPaths([
        {
          href: PagePathConstant.SOURCES,
          text: titlesForBreadcrumb(PagePathConstant.SOURCES) ?? '',
        },
        {
          href: PageHelper.buildUrl(PagePathConstant.SOURCE_VAULTS, accountId),
          text: `Vaults (${accountId})`,
        },
      ])
    }
  }, [accountId])

  useEffect(() => {
    if (vault.name) {
      setHeaderTitle(vault.name)
      setCurrentCrumbTitle(vault.name)
    }
  }, [vault.name])

  const onSubmit = async ({
    description,
    vpcId,
    subnetIdsList,
    safetyLock,
  }: FormEditVaultInterface) => {
    const { jobId } = await GrpcVaultService.updateVault(
      vaultId,
      description,
      vpcId,
      subnetIdsList,
      safetyLock
    )
    return jobId && (await scheduleTenantJob(jobId))
  }

  return (
    <div className="wrap-1649845425911 jsVaultDetailedPage">
      {loading || vault.accountId === '' ? (
        <PreloaderBlock show />
      ) : (
        <>
          <div className="controlHeaderBlock">
            <div className="controlHeader">
              <div className="vdHeaders jsVaultName">
                <div className="vdName">
                  {vault.isDefault && <div className="badge">Default</div>}
                </div>
                <span className="vdValueVersion jsVersion">
                  <b>Version:</b> {vault.version}
                </span>
              </div>
            </div>
          </div>
          {/*  */}

          {/*  Content Block */}
          <div className="vdContent">
            <div className="vdWrapper">
              <div className="vdWrapperStatusBlock">
                <DetailInfoCard
                  type={DetailInfoCardEntity.Error}
                  title="Quarantined Recovery Points"
                >
                  <div className="vdQuarantinedRP jsDetailedInfoCard">
                    {vault.contaminatedRecoveryPointsCount}
                  </div>
                </DetailInfoCard>
              </div>
              {/* Main Information Block */}
              <div className="vdWrapperNameBlock jsMainInformationBlock">
                <div className="vdNameBlockTitle">Main Information</div>
                {loading ? (
                  <PreloaderBlock show />
                ) : (
                  <>
                    <div className="vdNameBlockSubTitle jsMainInformationVaultName">
                      <b>Vault name:</b> {vault.name}
                    </div>
                    <div className="vdNameBlockSubTitle jsMainInformationVaultRegion">
                      <b>Vault region:</b> {vault.region}
                    </div>

                    <EditVaultForm
                      onSubmit={onSubmit}
                      loading={loadingUpdateVaultModal}
                      errorGroups={[ErrorGroupConstants.VAULT]}
                      data={{
                        accountId: vault.accountId,
                        region: vault.region,
                        description: vault.description,
                        vpcId: vault.vpcId,
                        subnetIdsList: vault.subnetIdsList,
                        safetyLock: vault.safetyLock,
                        vaultName: vault.name,
                        version: vault.version,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="vaultMetricsWrap">
              <div className="vaultMetricsWrapInner">
                {loading ? (
                  <PreloaderBlock show />
                ) : (
                  <VaultMetrics vaultId={vaultId} />
                )}

                <div className="replicationTable hide">
                  <div className="vdHeaders mb25 mt40">
                    Replication ( Coming Soon )
                  </div>
                  <ControlledVITable
                    head={VAULT_REPLICATION}
                    rows={TableFactory.vaultReplication()}
                    onMenuClick={() => null}
                  />
                  <div className="mt20">
                    <Button
                      disabled={true}
                      className="sizeS "
                      type="button"
                      color="primary"
                      variant="contained"
                    >
                      <span>+ New Replication</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default memo(VaultDetailedComplex)
