import { Column } from 'ui-v2/src/components/ui/data-display/data-table'
import { PolicyEfsTableRow } from 'ui-v2/src/lib/models/settings/policies/policies'

export const getEfsColumns = (): Array<Column<PolicyEfsTableRow>> => [
  {
    key: 'efs',
    header: 'EFS',
    render: (row) => row.efs,
  },
  {
    key: 'account',
    header: 'Account',
    render: (row) => row.account,
  },
  {
    key: 'policy',
    header: 'Policy',
    render: (row) => row.policy,
  },
]
