/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { Policy } from 'blues-corejs/dist'
import { pechkinClient } from '@lib/clients/pechkin'
import { SystemHelper } from '@lib/helpers'

export function usePoliciesList() {
  const [policiesList, setPoliciesList] = useState<Array<Policy>>([])
  const [isPoliciesListLoading, setIsPoliciesListLoading] = useState(false)

  useEffect(() => {
    async function fetchPoliciesList() {
      try {
        setIsPoliciesListLoading(true)
        const { plansList } = await pechkinClient.listPolicies()
        setPoliciesList(plansList)
      } catch (error) {
        SystemHelper.sendObjectToSentryIfProd({
          error,
          message: 'Failed to fetch policies list.',
        })
      } finally {
        setIsPoliciesListLoading(false)
      }
    }

    fetchPoliciesList()

    return () => {
      setPoliciesList([])
    }
  }, [])

  return {
    policiesList,
    isPoliciesListLoading,
  }
}
