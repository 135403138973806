import { SortOrder } from '@lib/interfaces/tables/sort.interface'
import { SortingState } from '@tanstack/react-table'

export const RPO_COLUMN_NAME = 'rpo'
export const RETENTION_COLUMN_NAME = 'maxBackupRetention'

/**
 * Used for drill-down queries in EC2/EBS Inventory tables
 */

export function getColumnsSorting({
  queryRpo,
  retentionValue,
}: {
  queryRpo: string
  retentionValue: string
}) {
  const showRPO = Boolean(queryRpo)
  const showRetention = Boolean(retentionValue)

  const columnsSorting: SortingState = []

  if (showRPO) {
    columnsSorting.push({
      id: RPO_COLUMN_NAME,
      desc: queryRpo === SortOrder.DESC,
    })
  }

  if (showRetention) {
    columnsSorting.push({
      id: RETENTION_COLUMN_NAME,
      desc: retentionValue === SortOrder.DESC,
    })
  }

  return columnsSorting
}
