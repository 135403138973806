/* eslint-disable import/no-extraneous-dependencies */
import { Backup, EBS, Threat } from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { AssetsEbsAttrs, EbsTableRow } from 'ui-v2/src/lib/models/assets'
import { AssetsEbsBase } from './ebs-base'

export class AssetsEbsTableData extends AssetsEbsBase {
  protected readonly volumes: Array<EBS>

  protected readonly scans: Array<Scan>

  protected readonly lastBackups: Map<string, Backup>

  protected readonly threats: Array<Threat>

  protected readonly volumesWithScans: Map<string, Array<Scan>>

  constructor(parameters: AssetsEbsAttrs) {
    super()
    this.volumes = parameters.volumes
    this.scans = parameters.scans
    this.lastBackups = parameters.lastBackups
    this.threats = parameters.threats
    this.volumesWithScans = this.getVolumeWithScans()
  }

  getEbsTableData() {
    return this.volumes.map(
      (volume) =>
        new EbsTableRow({
          id: volume.id,
          name: volume.name,
          awsId: volume.awsId,
          lastBackupDate: this.getLastBackup(volume)?.timestamp,
          lastScanDate: this.getLastScanForVolume(volume)?.createdAt,
          isUnhealthy: this.isUnhealthyVolume(volume),
          backupPolicies: volume.backupPolicies,
        })
    )
  }
}
