import React from 'react'
import { TablePagination } from '@mui/material'
import BaseTable, { BaseTableProps } from './base-table'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

interface OffsetTableProps<T extends Record<string, any>>
  extends Omit<BaseTableProps<T>, 'footer'> {
  pageSize: number
  setPageSize?: React.Dispatch<React.SetStateAction<number>>
  offset: number
  setOffset?: React.Dispatch<React.SetStateAction<number>>
  totalCount: number
}

function OffsetTable<T extends { id?: string | number }>({
  pageSize = TABLE_ROWS_PER_PAGE_OPTIONS[0],
  offset = 0,
  totalCount,
  setOffset,
  setPageSize,
  ...baseProps
}: OffsetTableProps<T>) {
  const page = Math.floor(offset / pageSize)

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setOffset?.(newPage * pageSize)
    baseProps.onSelectRows?.([])
  }

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageSize = parseInt(e.target.value, 10)
    setOffset?.(0)
    setPageSize?.(newPageSize)
    baseProps.onSelectRows?.([])
  }

  const paginationComponent = (
    <TablePagination
      rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={totalCount}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={onRowsPerPageChange}
      sx={(theme) => ({
        '& .MuiTablePagination-toolbar': {
          minHeight: '43px !important',
          height: '43px',
          backgroundColor: theme.palette.background.paper,
        },
      })}
    />
  )

  return <BaseTable {...baseProps} footer={paginationComponent} />
}

export default OffsetTable
