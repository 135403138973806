/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  EntropyTrendSelectionFilter,
  EntropyTrendsFilter,
} from '@features/entropy-trends/components/entropy-trends-filters/generic'

import type { EntropySelectionFilter } from '../../types'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { EntropyTrendAssetFilterLabel } from './asset-filter-label'
import {
  useGetEntropyFiltersLoading,
  useGetPossibleFilters,
  useGetSelectedFilters,
  useEntropyFiltersActions,
} from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'
import {
  FILTER_NAMES,
  FilterName,
} from '@features/entropy-trends/components/entropy-trends-filters/composable/consts'

function buildAssetFilters(
  assetsList: Array<AWSAsset>
): Array<EntropySelectionFilter<string>> {
  return assetsList.map((asset) => ({
    value: asset.id,
    label: <EntropyTrendAssetFilterLabel asset={asset as AWSAsset} />,
    searchFilterCriteria: (searchValue: string) =>
      asset.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      asset.awsId.toLowerCase().includes(searchValue.toLowerCase()),
  }))
}

interface Props {
  isExpanded: boolean
  onFilterClick: (filterName: FilterName) => void
}

export function AssetSelectionFilter({ isExpanded, onFilterClick }: Props) {
  const { assetsList } = useGetPossibleFilters()
  const { selectedAssets } = useGetSelectedFilters()
  const { fetchEntropyTrendsHistory } = useEntropyFiltersActions()

  const isFiltersLoading = useGetEntropyFiltersLoading()

  const options = buildAssetFilters(assetsList)

  const handleFilterChange = (selectedOptions: Set<string>) => {
    const newSelectedAssets = assetsList
      .filter((asset) => selectedOptions.has(asset.id))
      .map((asset) => asset.id)

    fetchEntropyTrendsHistory({
      assetIdsList: newSelectedAssets,
    })
  }

  return (
    <EntropyTrendsFilter
      filterName={FILTER_NAMES.ASSET}
      isLoading={isFiltersLoading}
      isExpanded={isExpanded}
      onAccordionClick={() => onFilterClick(FILTER_NAMES.ASSET)}
    >
      <EntropyTrendSelectionFilter
        options={options}
        selectedOptions={selectedAssets}
        onSelectionChange={handleFilterChange}
      />
    </EntropyTrendsFilter>
  )
}
