/* eslint-disable import/no-extraneous-dependencies */
import {
  createColumn,
  StatusCircle,
  CellWithDateTimeTooltip,
  HeaderWithLabel,
  CellWithLink,
  CopyCell,
  sortDate,
  ColumnWidth,
  CellLongTextWithTooltip,
  createDisplayColumn,
} from '@components/table'
import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale'
import LangHelper from '@lib/helpers/lang.helper'
import TimeHelper from '@lib/helpers/time.helper'
import {
  InventoryVolumeOutput,
  SummarizedVolumesDataOutput,
  BackupType,
} from 'blues-corejs/dist/use_cases/list_inventory_volumes/types'
import React from 'react'
import { Nullable } from '@lib/engine-types'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { VolumeGenericBackupKind } from 'blues-corejs/dist/models/inventory.model/volume.model'
import { INVENTORY_TABLE_MIN_HEIGHT } from '../inventory-tables-complex'
import { customColorResolver } from '@components-complex/dashboard-pages-v3/utils'
import { StyledCheckbox } from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/styles'
import { AssetRoutes } from '@lib/router'

export const RPO_COLUMN_NAME = 'rpo'
export const RPO_TARGET_COLUMN_NAME = 'sla'
export const RETENTION_COLUMN_NAME = 'maxBackupRetention'

type InputData = {
  summarizedData: SummarizedVolumesDataOutput
  aliasNamesWithId: AliasName
}

export function getVolumesTableColumnDefinitions({
  summarizedData,
  aliasNamesWithId,
}: InputData) {
  return [
    createDisplayColumn<InventoryVolumeOutput>({
      header: ({ table }) => (
        <StyledCheckbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      size: 60,
      id: 'row-selection',
      cell: ({ row }) => {
        return (
          <StyledCheckbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      },
    }),
    createColumn<InventoryVolumeOutput>('isUnhealthy', {
      enableSorting: false,
      cell: ({ row, getValue }) => (
        <StatusCircle
          colorResolver={customColorResolver(row.original.lastScan)}
          isHealthy={!getValue() as unknown as boolean}
        />
      ),
      size: 35,
    }),
    createColumn<InventoryVolumeOutput>('volumeId', {
      header: () => (
        <HeaderWithLabel
          title="Volume"
          label={String(summarizedData.volumesCount)}
        />
      ),
      meta: {
        height: INVENTORY_TABLE_MIN_HEIGHT,
      },
      sortingFn: (a, b) => {
        const volumeA = a.original
        const volumeB = b.original

        const volumeAName = volumeA.volumeName || volumeA.volumeId
        const volumeBName = volumeB.volumeName || volumeB.volumeId

        return volumeAName.localeCompare(volumeBName)
      },
      cell: ({ getValue, row }) => {
        const volumeName = row.original.volumeName
        const volumeId = getValue() as unknown as string
        const href = AssetRoutes.assetId(row.original.uuid)
        const displayedValue = volumeName || volumeId
        const displayedLabel = volumeName ? volumeId : volumeName

        return (
          <CellWithLink
            href={href}
            value={displayedValue}
            label={displayedLabel}
          />
        )
      },
    }),
    createColumn<InventoryVolumeOutput>('accountId', {
      enableSorting: false,
      header: () => (
        <HeaderWithLabel
          title="Account"
          label={String(summarizedData.accountsCount)}
        />
      ),
      cell: ({ getValue }) => {
        const className = aliasNamesWithId[getValue()] ? 'copyRow' : ''

        return (
          <CopyCell
            additionalText={aliasNamesWithId[getValue()]}
            copyText={getValue()}
            className={className}
          />
        )
      },
    }),
    createColumn<InventoryVolumeOutput>('region', {
      header: () => (
        <HeaderWithLabel
          title="Region"
          label={String(summarizedData.regionsCount)}
        />
      ),
      cell: ({ getValue }) => {
        return LangHelper.getAwsRegionSingleTranslation(getValue())
      },
    }),
    createColumn<InventoryVolumeOutput>('maxBackupRetention', {
      header: 'Retention',
      cell: ({ getValue }) => {
        const formattedRetention = convertMillisecondsWithLabel(
          getValue() as unknown as number
        )

        const { label, value } = formattedRetention

        const displayedValue = value ? `${value} ${label}` : ''

        return displayedValue
      },
    }),
    createColumn<InventoryVolumeOutput>('lastBackup', {
      header: 'Last Backup',
      sortingFn: (a, b) =>
        sortDate(
          a.original.lastBackup?.timestamp,
          b.original.lastBackup?.timestamp
        ),
      cell: ({ getValue }) => {
        const lastBackup = getValue() as unknown as Nullable<BackupType>
        return <CellWithDateTimeTooltip date={lastBackup?.timestamp} />
      },
    }),
    createColumn<InventoryVolumeOutput>('rpo', {
      header: 'RPO',
      cell: ({ getValue }) => {
        const rpo = getValue() as unknown as Nullable<number>

        if (!rpo) {
          return null
        }

        const formattedRpo = TimeHelper.timeAgo(rpo)

        return formattedRpo
      },
    }),

    createColumn<InventoryVolumeOutput>('sla', {
      header: 'RPO Target',
      cell: ({ getValue }) => {
        const sla = getValue() as unknown as Nullable<number>

        if (!sla) {
          return null
        }

        const formattedSla = TimeHelper.timeAgo(sla * 1000)

        return formattedSla
      },
    }),
    createColumn<InventoryVolumeOutput>('lastScan', {
      header: 'Last scan',
      cell: ({ getValue }) => {
        const lastScan = getValue() as unknown as Nullable<Date>

        return <CellWithDateTimeTooltip date={lastScan} />
      },
    }),

    createColumn<InventoryVolumeOutput>('policies', {
      meta: {
        size: ColumnWidth.SMALL,
      },
      header: 'Policy Coverage',
      cell: ({ getValue }) => {
        const policyCoverage = getValue() as unknown as Array<string>
        let policyCoverageLabel = ''

        if (Array.isArray(policyCoverage)) {
          policyCoverageLabel = policyCoverage.join(', ')
        } else if (
          policyCoverage === VolumeGenericBackupKind.IMPORTED_AWS_BACKUP
        ) {
          policyCoverageLabel = 'AWS Backup'
        } else if (policyCoverage === VolumeGenericBackupKind.AWS_SNAPSHOT) {
          policyCoverageLabel = 'EBS Snapshot'
        }
        return <CellLongTextWithTooltip title={policyCoverageLabel} />
      },
    }),
  ]
}
