import { AssetsFilter } from '@lib/clients/entropy-detection/common'

export interface GetEntropyTrendsHistoryRequestAttrs {
  // Start of the time range to count anomaly findings. Required.
  startAt: number
  // End of the time range to count anomaly findings. Required.
  endAt: number
  // The time granularity of the data points in the response. Required.
  timeGranularity: TimeGranularity
  // Filters for assets for which to count anomaly findings. Optional.
  assetsFilter?: AssetsFilter
  // Exact asset IDs for which to count anomaly findings. Optional.
  assetIdsList?: Array<string>
}

export enum TimeGranularity {
  TIME_GRANULARITY_HOUR = 0,
  TIME_GRANULARITY_DAY = 1,
}
