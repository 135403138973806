import { styled } from '@mui/material'
import Paper from '@mui/material/Paper'

const HEIGHT = '150px'

export const PaperStyled = styled(Paper)`
  height: calc(100vh - ${HEIGHT});
  padding: 24px;
  border-radius: 8px;
  margin: 24px;
`
