import React, { useEffect } from 'react'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { setNewToken, submitAddToken } from '@store/actions/token.action'
import TokenForm from '@forms/token/token.form'
import { useDispatch, useSelector } from 'react-redux'
import usePreloader from '@lib/hooks/usePreloader'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import AccessFactory from '@lib/factories/access.factory'
import { getUserRoleScopes } from '@store/selectors/user.selector'
import FormTokenInterface from '@lib/interfaces/form/form-token.interface'
import { AccountAndSettingsRoutes, useNavigation } from '@lib/router'
import { getNewToken } from '@store/selectors/token.selector'

const ERROR_GROUPS = [ErrorGroupConstants.ACCESS]

function AddAccessToken() {
  const router = useNavigation()
  const dispatch = useDispatch()
  const createdToken = useSelector(getNewToken)

  const possibleScopesLoading = usePreloader(
    PreloaderConstants.REQUEST_POSSIBLE_SCOPES
  )
  const issueTokenLoading = usePreloader(PreloaderConstants.ISSUE_TOKEN)

  const possibleScopes = useSelector(getUserRoleScopes)

  useEffect(() => {
    if (!createdToken) {
      return
    }

    router.push(AccountAndSettingsRoutes.successTokenId(createdToken))

    return () => {
      dispatch(setNewToken(null))
    }
  }, [createdToken])

  const onSubmit = (data: FormTokenInterface) => dispatch(submitAddToken(data))

  return (
    <div className="innerContent">
      {possibleScopesLoading || possibleScopes.length === 0 ? (
        <PreloaderBlock show />
      ) : (
        <TokenForm
          possibleScopes={possibleScopes}
          token={AccessFactory.buildEmpty()}
          onSubmit={onSubmit}
          loading={issueTokenLoading}
          errorGroups={ERROR_GROUPS}
        />
      )}
    </div>
  )
}

export default AddAccessToken
