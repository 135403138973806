import { useSelector } from 'react-redux'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import {
  SliceCriteriaTags,
  SliceCriteriaTagsCombinationOperator,
} from '@lib/models/management-view/types'
import { AssetsFilter, TagsFilter } from '@lib/clients/entropy-detection/common'
import { EntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'
import { TimeGranularity } from '@lib/clients/entropy-detection/types'

function convertSliceCriteriaToRequestAttrs(
  sliceTags?: SliceCriteriaTags
): TagsFilter {
  if (!sliceTags) {
    return {
      and: [],
      or: [],
    }
  }

  const isAndOperator =
    sliceTags.operator === SliceCriteriaTagsCombinationOperator.AND

  if (isAndOperator) {
    return {
      and: sliceTags.tags.map((tag) => ({
        key: tag.key,
        value: tag.value || '',
      })),
      or: [],
    }
  } else {
    return {
      and: [],
      or: sliceTags.tags.map((tag) => ({
        key: tag.key,
        value: tag.value || '',
      })),
    }
  }
}

function setNotEmptyArray<T>(array: Array<T> | undefined): Array<T> {
  return array ? array : []
}

interface UseFetchEntropyTrendsHistory {
  timeSelection: EntropyTrendsTimeSelection
  // Start of the time range to count anomaly findings. Required.
  startAt: number
  // End of the time range to count anomaly findings. Required.
  endAt: number
  // The time granularity of the data points in the response. Required.
  timeGranularity: TimeGranularity
  // Filters for assets for which to count anomaly findings. Optional.
  assetsFilter?: AssetsFilter
  // Exact asset IDs for which to count anomaly findings. Optional.
  assetIdsList?: Array<string>
}

export function usePrepareFetchEntropyTrendsHistoryParams(
  filters: UseFetchEntropyTrendsHistory
): UseFetchEntropyTrendsHistory {
  const { accountIds, regions, includeTags, excludeTags } = useSelector(
    getLiveAssetsCriteria
  )
  const includeTagsFromSlice = convertSliceCriteriaToRequestAttrs(includeTags)
  const excludeTagsFromSlice = convertSliceCriteriaToRequestAttrs(excludeTags)

  const combinedAccounts = [
    ...setNotEmptyArray(accountIds),
    ...setNotEmptyArray(filters.assetsFilter?.accounts),
  ]

  const combinedRegions = [
    ...setNotEmptyArray(regions),
    ...setNotEmptyArray(filters.assetsFilter?.regions),
  ]

  return {
    timeSelection: filters.timeSelection,
    assetIdsList: filters.assetIdsList,
    assetsFilter: {
      accounts: combinedAccounts,
      regions: combinedRegions,
      includeTags: includeTagsFromSlice,
      excludeTags: excludeTagsFromSlice,
      hasReplicatedBackups: [],
    },
    endAt: filters.endAt,
    startAt: filters.startAt,
    timeGranularity: filters.timeGranularity,
  }
}
