import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'
import { Option } from 'ui-v2/src/lib/models/reports'
import WebhookModel, {
  EventType,
  EventTypeConstant,
  FormFieldInterface,
  Severity,
  WebhookFormInterface,
} from 'ui-v2/src/lib/models/webhooks'

export const authorizationOptions: Array<Option> = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'basic_authorization',
    label: 'Basic Authorization',
  },
  {
    value: 'bearer',
    label: 'Bearer',
  },
  {
    value: 'api_key',
    label: 'API Key',
  },
]

export const versionOptions: Array<Option> = [
  {
    value: 'v0',
    label: 'V0',
  },
]

export const severityOptions: Array<Option> = [
  {
    value: Severity.ERROR,
    label: 'Error',
  },
  {
    value: Severity.WARNING,
    label: 'Warning',
  },
  {
    value: Severity.INFO,
    label: 'Info',
  },
  {
    value: Severity.TRACE,
    label: 'Trace',
  },
]

export const getWebhooksEventType = (name: string) => {
  switch (name) {
    case EventTypeConstant.ACCOUNT_LEVEL_STACK:
      return 'Account Level Stack'
    case EventTypeConstant.ASSET:
      return 'Asset'
    case EventTypeConstant.BACKUP_POLICY:
      return 'Backup Policy'
    case EventTypeConstant.CLOUD_CONNECTOR:
      return 'Cloud Connector'
    case EventTypeConstant.LOCAL_PATH:
      return 'Local Path'
    case EventTypeConstant.SNAPSHOTS:
      return 'Snapshots'
    case EventTypeConstant.RECOVERY_POINT:
      return 'Recovery Point'
    case EventTypeConstant.VAULT:
      return 'Vault'
    case EventTypeConstant.ROLE:
      return 'Role'
    case EventTypeConstant.USER:
      return 'User'
    default:
      return ''
  }
}

export const WEBHOOK_MAP = {
  AUTHORIZATION: 'authorization',
  VERSION: 'version',
  SEVERITY: 'severity',
  WEBHOOKS: 'webhooks',
}

const buildDefaultFormData = (
  events: Array<EventType>,
  webhooks: Array<WebhookModel>
): Record<keyof WebhookFormInterface, FormFieldInterface> => {
  const eventsOptions = events.map(({ name }) => ({
    value: name,
    label: getWebhooksEventType(name),
    name: name,
  }))

  return {
    name: {
      name: 'name',
      placeholder: 'Name',
      validationRules: {
        required: 'This field is required',
        maxLength: {
          value: 100,
          message: 'Maximal length is 100 characters',
        },
        validate: (value: string) => {
          const isWebhookNameExist = webhooks.some(
            (webhook) => webhook.getName() === value
          )

          if (isWebhookNameExist) {
            return 'Webhook with this name already exists'
          }

          return true
        },
      },
    },
    endpoint: {
      name: 'endpoint',
      placeholder: 'your-endpoint',
      validationRules: {
        required: 'This field is required',
        validate: (value: string) => {
          const url = `https://${value}`
          if (RegexConstants.SITE_WITH_HTTPS.test(url)) {
            return true
          }

          return 'Invalid URL'
        },
      },
    },
    authorization: {
      name: 'authorization',
      options: authorizationOptions,
      value: authorizationOptions[0]?.value,
    },
    login: {
      name: 'login',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    password: {
      name: 'password',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    token: {
      name: 'token',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    apiKey: {
      name: 'apiKey',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },
    apiValue: {
      name: 'apiValue',
      validationRules: {
        required: true,
        validate: (value: string) =>
          (RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
            !RegexConstants.WITH_WHITESPACE.test(value)) ||
          'Only ASCII characters are allowed',
      },
    },

    description: {
      name: 'description',
      validationRules: {
        maxLength: {
          value: 100,
          message: 'Maximal length is 100 characters',
        },
      },
    },
    eventType: {
      name: 'eventType',
      options: eventsOptions,
      placeholder: 'Select event type',
    },
    version: {
      name: 'version',
      value: versionOptions[0]?.value,
      options: versionOptions,
    },
    severity: {
      name: 'severity',
      options: severityOptions,
      label: 'Select severities',
    },
  }
}

export const getWebhooksDateRangeFilters = () => ({
  name: 'daterange',
  options: [
    {
      name: 'Past 3 hours',
      label: 'Past 3 hours',
      value: 0,
      extraValue: 3,
      supplementalValue: 6,
    },
    {
      name: 'Past 6 hours',
      label: 'Past 6 hours',
      value: 1,
      extraValue: 6,
      supplementalValue: 6,
    },
    {
      name: 'Past day',
      label: 'Past day',
      value: 2,
      extraValue: 24,
      supplementalValue: 6,
    },
    {
      name: 'Past week',
      label: 'Past week',
      value: 3,
      extraValue: 7 * 24,
      supplementalValue: 7,
    },
    {
      name: 'Past 2 weeks',
      label: 'Past 2 weeks',
      value: 4,
      extraValue: 14 * 24,
      supplementalValue: 7,
    },
    {
      name: 'Past month',
      label: 'Past month',
      value: 5,
      extraValue: 30 * 24,
      supplementalValue: 6,
    },
  ],
  value: 0,
})

export default buildDefaultFormData
