import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'

interface Option {
  label: string
  value: string
}
const Format = ({
  options,
  selectedOptions,
  error,
  helperText,
  onChange,
}: FormatProps) => {
  const handleChange = (id: string) => {
    const updatedOptions = selectedOptions.includes(id)
      ? selectedOptions.filter((option) => option !== id)
      : [...selectedOptions, id]
    onChange(updatedOptions)
  }

  return (
    <FormControl error={error}>
      <FormGroup row>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={selectedOptions.includes(option.value)}
                onChange={() => {
                  handleChange(option.value)
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
interface FormatProps {
  options: Array<Option>
  selectedOptions: Array<string>
  helperText?: string
  error?: boolean
  onChange: (selected: Array<string>) => void
}
export default Format
