import React, { memo } from 'react'

interface Props {
  labelText: string
  badgeCount?: number
  className?: string
}

function TabBadgeLabel({ labelText, badgeCount, className = '' }: Props) {
  return (
    <div className="tabBadgeContainer">
      <span>{labelText}</span>
      <div data-testid="tabBadge" className={`tabBadge ${className}`}>
        {badgeCount}
      </div>
    </div>
  )
}

export default memo(TabBadgeLabel)
