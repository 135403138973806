import { Typography } from '@mui/material'

interface SubtitleProps {
  children: React.ReactNode
}

function Subtitle({ children }: SubtitleProps) {
  return (
    <Typography variant="body1" marginY="8px" fontSize="16px" fontWeight="500">
      {children}
    </Typography>
  )
}

export default Subtitle
