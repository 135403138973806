/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import { Table } from '@components/table'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { AssetItem } from 'blues-corejs/dist/'
import { getAssetItemsTableColumnDefinitions } from './columns-definition'
import { AssetItemKind } from '@features/DashboardV4/common'
import { AssetItemFilters } from './asset-items-filter'
import { useDebouncedFunction } from '@lib/hooks'
import { UseLiveAssetsItemsDataFetcher } from '@components-complex/dashboard-pages-v3/hooks/types'
import { LiveAssetsItemsFilters } from '@lib/clients/assets-items/types'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

interface Props {
  assetItems: Array<AssetItem>
  isLoading: boolean
  onScrollEnd: UseLiveAssetsItemsDataFetcher['onFetchData']
  filterChangeHandler: (
    filter?: LiveAssetsItemsFilters | undefined
  ) => Promise<void>
  onAssetItemClick: (item: AssetItem) => void
  fetchOnRefreshInterval: (filter?: LiveAssetsItemsFilters) => Promise<void>
}

const DEBOUNCE_INPUT_TIME_MS = 500

/**
 * Renders a table of asset items (for now - only for EC2 asset)
 */
function AssetItemsTable({
  assetItems,
  isLoading,
  onScrollEnd,
  filterChangeHandler,
  onAssetItemClick,
  fetchOnRefreshInterval,
}: Props) {
  const [searchValue, setSearchValue] = useState('')
  const [selectedAssetItemKinds, setSelectedAssetItemKinds] = useState<
    Array<AssetItemKind>
  >([])

  const observable = useInfiniteScrollDataFetch({
    initialItems: assetItems,
    fetchNextBatch: onScrollEnd,
  })

  useEffect(() => {
    const fetchInterval = setInterval(
      fetchOnRefreshInterval,
      INTERVAL_REQUEST_TIMEOUT
    )

    return () => {
      clearInterval(fetchInterval)
    }
  }, [])

  function handleSearchChange(newSearchValue: string) {
    setSearchValue(newSearchValue)
  }

  function handleKindChange(kinds: Array<AssetItemKind>) {
    setSelectedAssetItemKinds(kinds)
  }

  const debouncedSearchValue = useDebouncedFunction(
    (newSearchValue: string) => {
      const itemNameList = newSearchValue ? [newSearchValue] : undefined

      handleSearchChange(newSearchValue)
      filterChangeHandler({
        itemNameList,
      })
    },
    DEBOUNCE_INPUT_TIME_MS
  )

  const debouncedKindChange = useDebouncedFunction(
    (kinds: Array<AssetItemKind>) => {
      filterChangeHandler({
        kindList: kinds,
      })
      handleKindChange(kinds)
    },
    DEBOUNCE_INPUT_TIME_MS
  )

  return (
    <>
      <AssetItemFilters
        searchValue={searchValue}
        selectedAssetItemKinds={selectedAssetItemKinds}
        onKindChange={debouncedKindChange}
        onSearchChange={debouncedSearchValue}
      />
      <Table
        data={assetItems}
        columns={getAssetItemsTableColumnDefinitions({
          onItemClick: onAssetItemClick,
        })}
        data-testid="assetItemsTable"
        generalProps={{
          isShowHeader: false,
          noDataMessage: 'No Asset items found',
          isLoading,
        }}
        advancedProps={{
          observableState: observable,
        }}
      />
    </>
  )
}

export { AssetItemsTable }
