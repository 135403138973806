import React, { useCallback } from 'react'
import { EngineCallback, EngineCallbackPure } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import { Button } from '@mui/material'

interface Props {
  description: React.ReactNode
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<any>
  data: any
  show: boolean
  className?: string
}

function DialogModal({
  description,
  data,
  onSubmit,
  onCancel,
  show,
  className,
}: Props) {
  const onSubmitCallback = useCallback(() => {
    onSubmit(data)
  }, [data])

  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  return (
    <BaseModal onBgClick={onCancelCallback} show={show} className={className}>
      <div className="modalHeader">Confirmation</div>
      <div className="modalContent">{description}</div>
      <div className="modalControlBlock">
        <Button
          className="large jsDialogYes"
          data-testid="jsDialogYes"
          color="primary"
          variant="contained"
          onClick={onSubmitCallback}
          type="button"
        >
          Yes, Proceed
        </Button>
        <Button
          className="jsDialogNo"
          data-testid="jsDialogNo"
          variant="text"
          onClick={onCancelCallback}
          type="button"
        >
          No, Cancel
        </Button>
      </div>
    </BaseModal>
  )
}

export default DialogModal
