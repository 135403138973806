import { useRef, useState } from 'react'
import { ListScansForAssetRequest } from '@lib/clients/scans/types'
import { Scans, UseAssetScansDataFetcher } from './types'
import { usePagination } from '@lib/hooks'
import ObjHelper from '@lib/helpers/obj.helper'
import { ListBackupsForAssetFilters } from '@lib/clients/backups/types'
import {
  ListScansForAssetItemsClient,
  ListScansForAssetItemsFilter,
  ListScansForAssetItemsPagination,
} from '@lib/clients/scans/list-scans-for-asset-items'
import { useFetchStatus } from '@lib/hooks/api-hooks'
import { ListScansForAssetFiltersParams } from '@lib/clients/scans/list-scans-for-asset/types'
import { ListBackupsForAssetItemsFiltersParams } from '@lib/clients/backups/list-backups-for-asset-items/types'
import { ListScansForAssetItemsFiltersParams } from '@lib/clients/scans/list-scans-for-asset-items/types'
import { ListScansForAssetResponse } from '@lib/clients/scans/list-scans-for-asset/list-scans-for-asset-client'

const listScansForAssetItemsClient = new ListScansForAssetItemsClient()

const INITIAL_STATE: Scans = {
  latestScans: [],
  allScans: [],
}

interface ExtendedAssetScansDataFetcher extends UseAssetScansDataFetcher {
  fetchInitial: (filter?: ListBackupsForAssetFilters) => Promise<void>
}

async function fetchInitialScansList(
  combinedFilters: ListScansForAssetItemsFiltersParams
) {
  return listScansForAssetItemsClient.listScansForAssetItems(
    new ListScansForAssetItemsFilter(combinedFilters)
  )
}

async function fetchScansForAssetViaPagination(nextPageToken: string) {
  return listScansForAssetItemsClient.listScansForAssetItems(
    new ListScansForAssetItemsPagination(nextPageToken)
  )
}

export function useFetchScanForAssetItems(
  initialFilters?: ListScansForAssetRequest
): ExtendedAssetScansDataFetcher {
  const fetchStatus = useFetchStatus()
  const [scansData, setScansData] = useState<Scans>(INITIAL_STATE)

  const previousFilters = useRef<ListBackupsForAssetItemsFiltersParams | null>(
    null
  )

  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()

  const updateScansData = (response: ListScansForAssetResponse) => {
    setScansData((prevScans) => ({
      latestScans: [...prevScans.latestScans, ...(response.runsList || [])],
      allScans: [...prevScans.allScans, ...(response.scansRunList || [])],
    }))
  }

  const handleFetchSuccess = ({
    scansList,
    nextPageToken,
  }: {
    scansList: ListScansForAssetResponse
    nextPageToken?: string
  }) => {
    updateScansData(scansList)
    updatePageTokenAndFlagFetched(nextPageToken || undefined)
  }

  const resetData = () => {
    setScansData(INITIAL_STATE)
    resetPagination()
  }

  const setPreviousFilters = (newFilters: ListScansForAssetFiltersParams) => {
    previousFilters.current = newFilters
  }

  const fetchPagination = async () => {
    if (!pageToken) {
      return
    }

    fetchStatus.setFetching()
    try {
      if (fetchStatus.isIdle()) {
        const response = await fetchScansForAssetViaPagination(
          pageToken as string
        )

        fetchStatus.setSuccess()
        handleFetchSuccess({
          scansList: response,
          nextPageToken: response.pageToken,
        })
      }
    } catch (error) {
      console.error(`Error fetching scans for asset items request - ${error}`)
    } finally {
      fetchStatus.reset()
    }
  }

  const fetchInitial = async (filters?: ListBackupsForAssetFilters) => {
    const combinedFilters = {
      ...initialFilters,
      ...filters,
    }

    const areFiltersEqual = ObjHelper.isEqual(
      combinedFilters,
      previousFilters.current
    )

    if (areFiltersEqual) {
      return
    }

    resetData()
    fetchStatus.setFetching()
    try {
      if (fetchStatus.isIdle()) {
        const response = await fetchInitialScansList(combinedFilters)
        setPreviousFilters(combinedFilters)
        fetchStatus.setSuccess()
        handleFetchSuccess({
          scansList: response,
          nextPageToken: response.pageToken,
        })
      }
    } catch (error) {
      console.error(
        `Something went wrong with fetch scans for asset request in ${__filename}: ${error}`
      )
    } finally {
      fetchStatus.reset()
    }
  }

  return {
    fetchInitial,
    onFetchData: fetchPagination,
    data: scansData,
    dataFetchStatus: fetchStatus.status,
    resetData,
    isLoadingData: fetchStatus.isLoading,
  }
}
