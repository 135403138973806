"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EBSState = exports.EBSType = void 0;
var EBSType;
(function (EBSType) {
    EBSType[EBSType["IO1"] = 0] = "IO1";
    EBSType[EBSType["IO2"] = 1] = "IO2";
    EBSType[EBSType["GP2"] = 2] = "GP2";
    EBSType[EBSType["GP3"] = 3] = "GP3";
    EBSType[EBSType["SC1"] = 4] = "SC1";
    EBSType[EBSType["ST1"] = 5] = "ST1";
    EBSType[EBSType["STANDARD"] = 6] = "STANDARD";
})(EBSType || (exports.EBSType = EBSType = {}));
var EBSState;
(function (EBSState) {
    EBSState[EBSState["CREATING"] = 0] = "CREATING";
    EBSState[EBSState["AVAILABLE"] = 1] = "AVAILABLE";
    EBSState[EBSState["INUSE"] = 2] = "INUSE";
    EBSState[EBSState["DELETING"] = 3] = "DELETING";
    EBSState[EBSState["DELETED"] = 4] = "DELETED";
    EBSState[EBSState["ERROR"] = 5] = "ERROR";
    EBSState[EBSState["UNKNOWN"] = 6] = "UNKNOWN";
})(EBSState || (exports.EBSState = EBSState = {}));
