import {
  DialogActions as MuiDialogActions,
  DialogActionsProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledDialogActions = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingTop: 0,
  paddingBottom: theme.spacing(2.5),

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
  },
}))

const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  ...props
}) => {
  return <StyledDialogActions {...props}>{children}</StyledDialogActions>
}

export default DialogActions
