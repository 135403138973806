/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import PlusToggleBtn from '@components-simple/plus-toggle-btn/PlusToggleBtn'
import RedStackModel from '@lib/models/red-stack.model'
import { createColumnHelper } from '@tanstack/react-table'
import { JobTenant } from 'blues-corejs/dist'
import { JobStatusCell } from '../cells'
import { getHumanReadableTenantJobKind } from '../helpers'
import { formatRedStackAccountAndRegion } from '../helpers/format-redstack-account-and-region'
import { getTenantJobStatusMessage } from '../helpers/get-tenant-job-status-message'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

const columns = createColumnHelper<JobTenant>()

const MAX_TEXT_LENGTH = 150

export function getColumnsDefinitions({
  allRedStacksList,
}: {
  allRedStacksList: Array<RedStackModel>
}) {
  return [
    columns.accessor('childrenList', {
      header: '',
      enableSorting: false,
      cell: ({ row, getValue }) => {
        const childrenList = getValue()

        if (childrenList.length === 0) {
          return null
        }

        return (
          <PlusToggleBtn
            expanded={row.getIsExpanded()}
            onChange={row.toggleExpanded}
          />
        )
      },
    }),
    columns.accessor('status', {
      header: '',
      enableSorting: false,
      cell: ({ getValue }) => <JobStatusCell status={getValue()} />,
    }),
    columns.accessor('createdAt', {
      header: 'Scheduled On',
      enableSorting: false,
      cell: ({ getValue }) => (
        <DateTimeTooltip
          className="system-jobs-no-wrap-cell"
          direction="row"
          date={getValue().getTime()}
        />
      ),
    }),
    columns.accessor('kind', {
      header: 'Job Type',
      enableSorting: false,
      cell: ({ getValue, row: { original } }) =>
        getHumanReadableTenantJobKind(getValue(), original),
    }),
    columns.accessor('ccId', {
      header: 'Cloud Connector',
      enableSorting: false,
      cell: ({ getValue }) =>
        formatRedStackAccountAndRegion({
          ccId: getValue(),
          allRedStacksList,
        }),
    }),

    columns.accessor('statusMessage', {
      header: 'Message',
      enableSorting: false,
      cell: ({ row: { original } }) =>
        getTenantJobStatusMessage({ job: original }),
    }),
    columns.accessor('name', {
      header: 'Task',
      enableSorting: false,
      cell: ({ getValue }) => (
        <LongTextTooltip
          customClass="task-cell"
          text={getValue()}
          maxLength={MAX_TEXT_LENGTH}
        />
      ),
    }),
    columns.accessor('finishedAt', {
      header: 'Completed On',
      enableSorting: false,
      cell: ({ getValue }) => {
        const finishedAt = getValue()

        if (!finishedAt) {
          return null
        }

        return (
          <DateTimeTooltip
            className="system-jobs-no-wrap-cell"
            direction="row"
            date={finishedAt.getTime()}
          />
        )
      },
    }),
  ]
}
