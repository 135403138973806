import { SelectSpecificPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import { Table } from '@components/table'
import { EngineCallback } from '@lib/engine-types'
import { DeletePathInterface } from '@tables/assets-with-paths-table/AssetsWithPathsTable'
import {
  getPathsSubTableColumnDefinitions,
  StyledWarningBox,
} from '@features/policies/policy/policy-tables/paths-sub-row'
import StrHelper from '@lib/helpers/str.helper'
import { isSelectedAllPathsForAsset } from '@features/policies/policy/policy-tables'

type Props = {
  assetId: string
  selectedSpecificPaths: Array<SelectSpecificPathsInterface>
  selectedAllPaths: Array<string>
  onDeletePath: EngineCallback<DeletePathInterface>
}

function PathsSubRow({
  assetId,
  selectedSpecificPaths,
  onDeletePath,
  selectedAllPaths,
}: Props) {
  const specificPathsForAsset = selectedSpecificPaths.filter(
    (path) => path.asset.asset.id === assetId
  )
  const allPaths = specificPathsForAsset[0]?.selectedPaths ?? {}
  const shouldRenderTables = !!Object.keys(allPaths).length
  const isSelectedAllPaths = isSelectedAllPathsForAsset(
    selectedAllPaths,
    assetId
  )
  const handleDeletePath = (deletedPath: string, selector: string) => {
    onDeletePath({
      assetId,
      deletedPath,
      selector,
    })
  }

  if (isSelectedAllPaths) {
    return null
  }

  if (shouldRenderTables && allPaths) {
    return (
      <>
        {Object.keys(allPaths).map((key) => {
          const paths = allPaths[key]
          const pathTitle = StrHelper.capitalize(key)

          if (!paths || !paths.length) {
            return null
          }
          return (
            <Table
              data={paths}
              key={key}
              columns={getPathsSubTableColumnDefinitions({
                pathTitle: pathTitle,
                handleDeletePath,
              })}
              generalProps={{
                noDataMessage: 'No Paths found',
                isLoading: false,
              }}
            />
          )
        })}
      </>
    )
  }
  return <StyledWarningBox>Please select paths</StyledWarningBox>
}

export default PathsSubRow
