import React from 'react'
import { Box, Typography } from '@mui/material'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import ElastioLogo from '../../assets/logo/elastio-logo'

const ballScaleMultiple = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 0.75;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`

const LoaderWrapper = styled(Box)`
  position: relative;
  width: 100px;
  height: 100px;
`

const Ball = styled.div`
  background-color: #ff1654;
  border-radius: 100%;
  position: absolute;
  inset: 0;
  margin: auto;
  opacity: 0;
  width: 100%;
  height: 100%;
  animation: ${ballScaleMultiple} 1s linear infinite;

  &:nth-of-type(2) {
    animation-delay: -0.4s;
  }

  &:nth-of-type(3) {
    animation-delay: -0.2s;
  }
`

const LogoContainer = styled(Box)`
  position: absolute;
  inset: 0;
  display: flex;
  margin-top: 5px;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Significantly higher z-index */
`

const SiteLoader = ({ text = 'Loading...' }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 999,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 1,
        textAlign: 'center',
      }}
    >
      <LoaderWrapper>
        <LogoContainer>
          <ElastioLogo />
        </LogoContainer>
        <Ball />
        <Ball />
        <Ball />
      </LoaderWrapper>
      <Typography>{text}</Typography>
    </Box>
  )
}

export default SiteLoader
