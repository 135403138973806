import React, { memo } from 'react'

interface Props {
  title: string
}

function VirusCard({ title }: Props) {
  return (
    <div className="wrap-1652704361125 jsVirusCard">
      {title}
      <img
        src="/images/notifications/icon-info.svg"
        alt="completed"
        width={13}
        height={13}
      />
    </div>
  )
}

export default memo(VirusCard)
