import React, { FunctionComponent, ReactNode } from 'react'
import clsx from 'clsx'
import {
  EngineCallbackDouble,
  RowCallback,
  RowChildrenUnit,
  RowSimpleUnit,
  VINumberMap,
} from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import GreenCheckedIcon from '@inline-img/general/green-checked-icon'
import RedCrossIcon from '@inline-img/general/red-cross-icon'
import YellowTriangle from '@inline-img/general/warning-icon'
import DataHelper from '@lib/helpers/data.helper'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import AccountStatusConstant from '@lib/constants/account-status.constant'
import ArrHelper from '@lib/helpers/arr.helper'
import UpgradeRequiredIcon from '@inline-img/general/upgrade-required-icon'
import { Button, Tooltip } from '@mui/material'

interface RowData {
  data: any
}
export function drawRow<T extends RowData>(
  row: RowSimpleUnit | RowChildrenUnit,
  rowComponent: FunctionComponent<T>,
  extra: any = {}
) {
  const [id, ...data] = row
  const RowComponent = rowComponent
  return <RowComponent id={id} key={id} data={data} {...extra} />
}

export function drawAWSValidationStatus(validationStatus: string) {
  const [success, haveFailure] = validationStatus.split(' ')
  return (
    <div className="awsValidationWrap jsAWSValidation">
      {success && <div className="statusCircle ehStatusReady">{success}</div>}
      {haveFailure && (
        <div className="sublabelText">{haveFailure} Have A Failure</div>
      )}
    </div>
  )
}

export function drawAWSStatus(
  status: AccountStatusConstant,
  messages: Array<string> = []
): ReactNode {
  let IconComponent: React.FC
  switch (status) {
    case AccountStatusConstant.CONNECTED:
      IconComponent = GreenCheckedIcon
      break
    case AccountStatusConstant.DISCONNECTED:
      IconComponent = RedCrossIcon
      break
    case AccountStatusConstant.MIXED:
      IconComponent = YellowTriangle
      break
    case AccountStatusConstant.UPGRADE_REQUIRED:
      IconComponent = UpgradeRequiredIcon
      break
    default:
      IconComponent = () => null
  }

  if (ArrHelper.arrEmpty(messages)) {
    return <IconComponent />
  }
  return (
    <Tooltip
      placement="right"
      title={messages.map((m) => (
        <div key={m}>{m}</div>
      ))}
    >
      <div>
        <IconComponent />
      </div>
    </Tooltip>
  )
}

interface DrawRetrySkipCancelCallbacks {
  id: number
  onEnableService: RowCallback
  onShowDetails: RowCallback
  onRetry: RowCallback
  onSkip: RowCallback
  onCancel: RowCallback
}
export function drawRetrySkipCancelControls(
  serviceState: number,
  row: RowSimpleUnit,
  {
    id,
    onEnableService,
    onShowDetails,
    onRetry,
    onSkip,
    onCancel,
  }: DrawRetrySkipCancelCallbacks
) {
  switch (serviceState) {
    // failed
    case 0:
      return (
        <div className="tableButtonsGroup">
          <Button
            className="jsRtShow"
            onClick={() => onShowDetails(id, row)}
            size="small"
            variant="contained"
          >
            Show Details
          </Button>
          <Button
            className="jsRtRetry"
            onClick={() => onRetry(id, row)}
            size="small"
            variant="contained"
          >
            Retry
          </Button>
          <Button
            className="jsRtSkip"
            onClick={() => onSkip(id, row)}
            size="small"
            variant="contained"
          >
            Skip
          </Button>
        </div>
      )

    // service enabled
    case 1:
      return <div className="jsRtEnabledLabel">Service enabled</div>

    // in progress
    case 2:
      return (
        <div>
          <Button
            className="jsRtCancel"
            onClick={() => onCancel(id, row)}
            size="small"
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </div>
      )

    // not started
    case 3:
      return (
        <div>
          <Button
            className="jsRtEnable"
            onClick={() => onEnableService(id, row)}
            size="small"
            variant="contained"
          >
            Enable Service
          </Button>
        </div>
      )

    default:
      return null
  }
}

export function drawCommaSeparatedColumn(str: string | number) {
  const arr = str
    .toString()
    .split(',')
    .map((v) => v.trim())
  return (
    <div>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {arr.map((r, index) => (
        <div className="separatedElem jsSeparatedElem" key={index}>
          {r}
        </div>
      ))}
    </div>
  )
}

export function drawCircleColumn(value: string | number) {
  const arr = value.toString().split('').map(Number)
  return (
    <div className="circleTableWrap">
      {arr.map((v, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div
          key={index}
          className={clsx('tableCircle', { tableCircleEnabled: !!v })}
        />
      ))}
    </div>
  )
}

export function drawCheckboxColumn(
  value: string | number,
  onChange: EngineCallbackDouble<boolean, number>
) {
  const arr = value.toString().split('').map(Number)
  return (
    <div className="circleTableWrap">
      {arr.map((v, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="tableRoleCheckbox">
          <V2Checkbox
            variant="table"
            onChange={(checkboxValue) => onChange(checkboxValue, index)}
            checked={!!v}
          />
        </div>
      ))}
    </div>
  )
}

export function drawVICell(
  columnDrawMapper: VINumberMap,
  value: ValueInterface,
  relativeIndex: number,
  columnState: Array<number>
): ReactNode {
  const absoluteIndex = DataHelper.calcAbsoluteIndexByTemplate(
    relativeIndex,
    columnState
  )
  if (absoluteIndex === -1) {
    return null
  }
  const drawFunc = columnDrawMapper[absoluteIndex]
    ? columnDrawMapper[absoluteIndex]
    : () => value.name
  return <td key={absoluteIndex}>{drawFunc?.(value)}</td>
}

export function drawTotalVICell(
  columnTotalDrawMapper: VINumberMap,
  value: ValueInterface,
  relativeIndex: number,
  columnState: Array<number>
): ReactNode {
  const absoluteIndex = DataHelper.calcAbsoluteIndexByTemplate(
    relativeIndex,
    columnState
  )
  if (absoluteIndex === -1) {
    return null
  }
  const drawFunc = columnTotalDrawMapper[absoluteIndex]
    ? columnTotalDrawMapper[absoluteIndex]
    : () => value.name
  return (
    <div className="td" key={absoluteIndex}>
      {drawFunc?.(value)}
    </div>
  )
}

export function drawScrollVICell(
  columnDrawMapper: VINumberMap,
  value: ValueInterface,
  relativeIndex: number,
  columnState: Array<number>
): ReactNode {
  const absoluteIndex = DataHelper.calcAbsoluteIndexByTemplate(
    relativeIndex,
    columnState
  )
  if (absoluteIndex === -1) {
    return null
  }
  const drawFunc = columnDrawMapper[absoluteIndex]
    ? columnDrawMapper[absoluteIndex]
    : () => value.name
  return (
    <div className="td" key={absoluteIndex}>
      {drawFunc?.(value)}
    </div>
  )
}
