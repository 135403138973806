import { useMutation } from '@tanstack/react-query'
import { DeleteReportScheduleCriteria } from 'ui-v2/src/lib/clients/reports/schedule/delete-report-schedule'
import { ReportSchedulesClient } from 'ui-v2/src/lib/clients/reports/report-schedules-client'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useDeleteReportSchedule() {
  const reportScheduleClient = useGrpcClient(ReportSchedulesClient)

  return useMutation({
    mutationFn: async ({ id }: DeleteReportScheduleCriteria) => {
      if (!reportScheduleClient) {
        throw new Error('Report schedule client not initialized')
      }

      return reportScheduleClient.deleteReportSchedule({ id })
    },
  })
}
