import React from 'react'
import BaseModal from '@components-composite/modals/BaseModal'
import { EngineCallback, EngineCallbackPure } from '@lib/engine-types'
import { Button } from '@mui/material'
import RedStackModel from '@lib/models/red-stack.model'

interface Props {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<any>
  onFixClick: EngineCallback<string>
  data: any
  show: boolean
}

function FixCCProblemsModal({ show, onCancel, data, onFixClick }: Props) {
  const onClick = (redStackId: string) => {
    onFixClick(redStackId)
    onCancel()
  }

  return (
    <BaseModal
      className="cloudConnectorProblems"
      show={show}
      onBgClick={onCancel}
    >
      <div className="modalHeader">Cloud Connector problems</div>
      <div className="modalContent">
        {data &&
          data.accounts &&
          data.accounts.map(
            ({ model }: { reason: string; model: RedStackModel }) => (
              <div className="container" key={model.redStackId}>
                {model.awsAccount}
                <span className="colorTextGrey">({model.awsRegion})</span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onClick(model.redStackId)}
                >
                  Fix it
                </Button>
              </div>
            )
          )}
      </div>
    </BaseModal>
  )
}

export default FixCCProblemsModal
