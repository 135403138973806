import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const StyledBackupSourceContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '8px',
})

export const StyledIconContainer = styled(Box)`
  height: 25px;

  svg {
    width: 32px;
    height: 25px;
  }
`
