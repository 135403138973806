import {
  ScheduledTenantJobKind,
  ScheduledCcJobKind,
} from 'ui-v2/src/features/scheduled-jobs-monitoring/models'

export enum JobStatus {
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
  ABORTED = 'aborted',
}

export enum ClientSideJobStatus {
  INITIALIZING = 'initializing',
}

export interface Messages {
  [ClientSideJobStatus.INITIALIZING]: string
  [JobStatus.SUCCEEDED]: string
  [JobStatus.FAILED]: string
  [JobStatus.ABORTED]: string
}

export type JobMessageCollection = Record<
  Partial<ScheduledTenantJobKind> | Partial<ScheduledCcJobKind>,
  Messages
>

export const JobMessages: JobMessageCollection = {
  [ScheduledTenantJobKind.REPAIR_CLOUD_CONNECTOR]: {
    [ClientSideJobStatus.INITIALIZING]: 'The repair process has been started.',
    [JobStatus.SUCCEEDED]: 'The repairing has been finished successfully.',
    [JobStatus.FAILED]: 'The repairing has finished with errors.',
    [JobStatus.ABORTED]: 'The repairing has been aborted.',
  },
  [ScheduledTenantJobKind.ACTIVATE_CLOUD_CONNECTOR]: {
    // The connection for sources in ${regionName} has been started
    [ClientSideJobStatus.INITIALIZING]: 'The connection has been started.',
    // The sources in ${regionName} region in ${action.payload.accountId} account has been connected
    [JobStatus.SUCCEEDED]: 'The sources has been connected.',
    // This sources in ${regionName} region in ${action.payload.accountId} account is not connected
    [JobStatus.FAILED]: 'This sources is not connected.',
    [JobStatus.ABORTED]: 'This sources is not connected.',
  },

  [ScheduledTenantJobKind.UPDATE_VAULT_SUBNETS]: {
    [ClientSideJobStatus.INITIALIZING]: 'The Vault update has been started.',
    [JobStatus.SUCCEEDED]: 'The Vault updated.',
    [JobStatus.FAILED]: 'Vault failed to update.',
    // Vault failed to update
    [JobStatus.ABORTED]: 'Vault failed to update.',
  },

  [ScheduledTenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING]: {
    // The connection for sources in ${regionName} has been started
    [ClientSideJobStatus.INITIALIZING]: 'The connection has been started.',
    // The sources in ${regionName} region in ${action.payload.accountId} account has been connected
    [JobStatus.SUCCEEDED]: 'The sources has been connected.',
    // This sources in ${regionName} region in ${action.payload.accountId} account is not connected
    [JobStatus.FAILED]: 'This sources is not connected.',
    [JobStatus.ABORTED]: 'This sources is not connected.',
  },

  [ScheduledTenantJobKind.CREATE_VAULT]: {
    [ClientSideJobStatus.INITIALIZING]: 'The vault creation has been started.',
    [JobStatus.SUCCEEDED]: 'The vault has been created.',
    [JobStatus.FAILED]: 'The vault creation has been finished with errors.',
    [JobStatus.ABORTED]: 'The vault creation has been aborted.',
  },

  [ScheduledTenantJobKind.REPAIR_VAULT]: {
    [ClientSideJobStatus.INITIALIZING]: 'The repairing has been started.',
    [JobStatus.SUCCEEDED]: 'The vault repairing has been finished.',
    [JobStatus.FAILED]: 'The vault repairing has been finished with errors.',
    [JobStatus.ABORTED]: 'The vault repairing has been aborted.',
  },

  [ScheduledTenantJobKind.INSTALL_CLOUD_CONNECTOR]: {
    [ClientSideJobStatus.INITIALIZING]:
      'The source installation has been started.',
    [JobStatus.SUCCEEDED]: 'Sources have been installed.',
    [JobStatus.FAILED]:
      'The source installation has been finished with errors.',
    [JobStatus.ABORTED]: 'The source installation has been aborted.',
  },

  [ScheduledCcJobKind.ISCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.APPLY_RETENTION_POLICY]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },

  [ScheduledCcJobKind.DATABASE_MAINTENANCE]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.AWS_EBS_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.AWS_EC2_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },

  [ScheduledCcJobKind.AWS_EFS_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.AWS_S3_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.AWS_EC2_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },

  [ScheduledCcJobKind.AWS_EC2_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },

  [ScheduledCcJobKind.AWS_BACKUP_RP_EC2_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },

  [ScheduledCcJobKind.UNKNOWN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.AWS_BACKUP_RP_EBS_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
  [ScheduledCcJobKind.AWS_BACKUP_RP_EFS_SCAN]: {
    [ClientSideJobStatus.INITIALIZING]: 'Scan(s) scheduled successfully',
    [JobStatus.SUCCEEDED]: 'Scan(s) finished successfully.',
    [JobStatus.FAILED]: 'Scan(s) have failed',
    [JobStatus.ABORTED]: 'Scan(s) were aborted',
  },
}
