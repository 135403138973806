import React, { useEffect, useMemo, useState } from 'react'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ValueInterface from '@lib/interfaces/value.interface'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ListVolumesWithThreats,
  ListVolumesWithThreatsAttrs,
  ListEfsWithThreats,
  ListEfsWithThreatsAttrs,
  ListS3BucketsWithThreatsAttrs,
  ListS3BucketsWithThreats,
  listEfsWithActiveThreatsPossibleFilters,
  listS3BucketsWithActiveThreatsPossibleFilters,
  listVolumesWithActiveThreatsPossibleFilters,
} from 'blues-corejs/dist'
import { VIRow } from '@lib/engine-types'
import { Table } from '@components/table'
import DataHelper from '@lib/helpers/data.helper'
import {
  emptyDataEbsEc2Attrs,
  emptyDataEfsAttrs,
  emptyDataS3Attrs,
  getDataForPossibleEfsWithActiveThreatsFilters,
  getDataForPossibleS3BucketsWithActiveThreatsFilters,
  getDataForPossibleVolumesWithActiveThreatsFilters,
  getUniqueInstances,
} from './utils'
import {
  EfsColumns,
  initialPrioritySortForEBSTable,
  initialPrioritySortForEC2Table,
  initialPrioritySortForEFSTable,
  initialPrioritySortForS3Table,
  InstanceColumns,
  S3Columns,
  VolumeColumns,
} from './column-definitions'
// eslint-disable-next-line import/no-extraneous-dependencies
import { BackupVariant } from 'blues-corejs/dist/use_cases/list_instances_with_threats/types'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  InstancesOutput,
  VolumeThreatsOutput,
} from 'blues-corejs/dist/use_cases/list_volumes_with_threats/types'
import FilterFactory from '@lib/factories/filter.factory'
import { ObjHelper } from '@lib/helpers'
import { useLiveAssetsData } from './useLiveAssetsData'
import { OvaServersWithThreats } from './ova-servers-with-threats'
import { useGetActiveTab, useTabsStoreActions, Tabs } from './tabs-store'
import { AssetsWithThreatsContainer } from './assets-with-threats-container'
import { DashboardRoutes, useNavigation } from '@lib/router'
import { useSearchParams } from 'react-router-dom'

type CustomQuery = {
  t: string | null
  ransomware?: typeof DashboardRoutes.Params.ALL
  malware?: typeof DashboardRoutes.Params.ALL
  filesystem?: typeof DashboardRoutes.Params.ALL
}

enum ThreatFilter {
  MALWARE = 'malwares',
  RANSOMWARE = 'ransomwares',
  FS_CHECK = 'fsCheck',
}

const SELECTED_ALL_THREATS = [
  {
    name: 'No assets with threats',
    label: 'No assets with threats',
    value: true,
  },
]

function AssetsWithThreats() {
  const tab = useGetActiveTab()

  const { setActiveTab } = useTabsStoreActions()
  const [searchParams] = useSearchParams()
  const router = useNavigation()
  const customQuery: CustomQuery = {
    t: searchParams.get('t'),
    filesystem: searchParams.get('filesystem') ? 'all' : undefined,
    malware: searchParams.get('malware') ? 'all' : undefined,
    ransomware: searchParams.get('ransomware') ? 'all' : undefined,
  }
  const [filterSearch, setFilterSearch] = useState<string>('')

  // Data for the new Assets tables
  const [isTablesLoading, setTablesLoading] = useState<boolean>(true)
  const [dataForEc2EbsTables, setDataForEc2EbsTables] =
    useState<ListVolumesWithThreatsAttrs>(emptyDataEbsEc2Attrs)
  const [dataForEfsTable, setDataForEfsTable] =
    useState<ListEfsWithThreatsAttrs>(emptyDataEfsAttrs)
  const [dataForS3Table, setDataForS3Table] =
    useState<ListS3BucketsWithThreatsAttrs>(emptyDataS3Attrs)

  const [selectedEBSFilters, setSelectedEBSFilters] = useState<VIRow>([])
  const [selectedEC2Filters, setSelectedEC2Filters] = useState<VIRow>([])
  const [selectedEFSFilters, setSelectedEFSFilters] = useState<VIRow>([])
  const [selectedS3Filters, setSelectedS3Filters] = useState<VIRow>([])

  const [selectedMalwares, setSelectedMalwares] = useState<Array<string>>([])
  const [selectedRansomwares, setSelectedRansomwares] = useState<Array<string>>(
    []
  )
  const [selectedFsChecks, setSelectedFsChecks] = useState<Array<string>>([])

  const {
    liveAssets,
    isLiveAssetsLoading,
    detectedThreats,
    scans,
    lastBackups,
    aliasNames,
  } = useLiveAssetsData()

  const isLoading = isLiveAssetsLoading || isTablesLoading

  const fetchDataForInventoryTables = () => {
    setTablesLoading(true)

    const lastBackupsMap = new Map<string, BackupVariant>()
    for (const [key, backup] of lastBackups.backups) {
      lastBackupsMap.set(key, { backup })
    }

    const lastMalwareScans = scans.malwareScans
    const lastRansomwareScans = scans.ransomwareScans
    const lastFSCheckScans = scans.fsCheckScans

    if (liveAssets.ec2Instances.length || liveAssets.ebsVolumes.length) {
      setDataForEc2EbsTables({
        instances: liveAssets.ec2Instances,
        volumes: liveAssets.ebsVolumes,
        lastBackups: lastBackupsMap,
        threats: detectedThreats,
        lastScans: [
          ...lastMalwareScans,
          ...lastRansomwareScans,
          ...lastFSCheckScans,
        ],
      })
    }

    if (liveAssets.filesystems.length) {
      setDataForEfsTable({
        filesystems: liveAssets.filesystems,
        threats: detectedThreats,
        lastScans: [...lastMalwareScans, ...lastRansomwareScans],
      })
    }

    if (liveAssets.s3Buckets.length) {
      setDataForS3Table({
        s3buckets: liveAssets.s3Buckets,
        threats: detectedThreats,
        lastBackups: lastBackupsMap,
        lastScans: lastMalwareScans,
      })
    }

    setTablesLoading(false)
  }

  useEffect(() => {
    fetchDataForInventoryTables()
  }, [JSON.stringify(liveAssets)])

  const { possibleEfsWithActiveThreatsFilters } =
    new listEfsWithActiveThreatsPossibleFilters({
      efsList: dataForEfsTable.filesystems,
      threats: dataForEfsTable.threats,
    }).execute()

  const { possibleS3BucketsWithActiveThreatsFilters } =
    new listS3BucketsWithActiveThreatsPossibleFilters({
      s3BucketsList: dataForS3Table.s3buckets,
      threats: dataForS3Table.threats,
    }).execute()

  const { possibleVolumesWithActiveThreatsFilters } =
    new listVolumesWithActiveThreatsPossibleFilters({
      volumes: dataForEc2EbsTables.volumes,
      threats: dataForEc2EbsTables.threats,
    }).execute()

  // get possible filters for EBS and EC2 tabs
  const dataForPossibleVolumesFilters =
    getDataForPossibleVolumesWithActiveThreatsFilters(
      possibleVolumesWithActiveThreatsFilters
    )
  const possibleVolumeFilters = dataForPossibleVolumesFilters.map(
    FilterFactory.buildActiveVolumesThreatsFilter
  )

  // get possible filters for S3 tab
  const dataForPossibleS3Filters =
    getDataForPossibleS3BucketsWithActiveThreatsFilters(
      possibleS3BucketsWithActiveThreatsFilters
    )
  const possibleS3Filters = dataForPossibleS3Filters.map(
    FilterFactory.buildActiveS3ThreatsFilter
  )

  // get possible filters for EFS tab
  const dataForPossibleEfsFilters =
    getDataForPossibleEfsWithActiveThreatsFilters(
      possibleEfsWithActiveThreatsFilters
    )
  const possibleEfsFilters = dataForPossibleEfsFilters.map(
    FilterFactory.buildActiveEfsThreatsFilter
  )

  const possibleFiltersByTab = useMemo(() => {
    const filterByTab = {
      [Tabs.INSTANCES]: possibleVolumeFilters.filter(
        (filter) => filter.options?.length
      ),
      [Tabs.VOLUMES]: possibleVolumeFilters.filter(
        (filter) => filter.options?.length
      ),
      [Tabs.S3]: possibleS3Filters.filter((filter) => filter.options?.length),
      [Tabs.EFS]: possibleEfsFilters.filter((filter) => filter.options?.length),
      [Tabs.OVA_SERVERS]: [],
    }

    return filterByTab[tab as Tabs] ?? []
  }, [tab, possibleVolumeFilters, possibleS3Filters, possibleEfsFilters])

  const selectedFiltersByTab = useMemo(() => {
    const selectedByTab = {
      [Tabs.INSTANCES]: selectedEC2Filters,
      [Tabs.VOLUMES]: selectedEBSFilters,
      [Tabs.S3]: selectedS3Filters,
      [Tabs.EFS]: selectedEFSFilters,
      [Tabs.OVA_SERVERS]: [],
    }
    return selectedByTab[tab as Tabs]
  }, [
    tab,
    selectedEBSFilters,
    selectedEC2Filters,
    selectedS3Filters,
    selectedEFSFilters,
  ])

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    if (tab === Tabs.VOLUMES || tab === Tabs.INSTANCES) {
      setSelectedEBSFilters(newFilters)
      setSelectedEC2Filters(newFilters)
    }
    if (tab === Tabs.EFS) {
      setSelectedEFSFilters(newFilters)
    }
    if (tab === Tabs.S3) {
      setSelectedS3Filters(newFilters)
    }

    const malwareFilter = newFilters.find(
      (filter) => filter.name === ThreatFilter.MALWARE
    )

    const ransomwareFilter = newFilters.find(
      (filter) => filter.name === ThreatFilter.RANSOMWARE
    )

    const fsCheckFilter = newFilters.find(
      (filter) => filter.name === ThreatFilter.FS_CHECK
    )

    if (malwareFilter) {
      setSelectedMalwares(
        malwareFilter.options?.map((option) => option.name) || []
      )
    }

    if (ransomwareFilter) {
      setSelectedRansomwares(
        ransomwareFilter.options?.map((option) => option.name) || []
      )
    }

    if (fsCheckFilter) {
      setSelectedFsChecks(
        fsCheckFilter.options?.map((option) => option?.label ?? '') || []
      )
    }
  }

  //change threats tags
  const onMalwareFilterChange = (malware: string) => {
    const malwareFilter = selectedFiltersByTab.find(
      (filter) => filter.name === ThreatFilter.MALWARE
    )
    if (!malwareFilter) {
      return
    }

    const malwaresFilterOptions = malwareFilter.options

    const filteredMalwareOptions = malwaresFilterOptions?.filter(
      (option) => option.name !== malware
    )

    if (customQuery.malware) {
      router.push(DashboardRoutes.assetsThreatsWithParams(String(tab), {}))
    }

    setSelectedMalwares(
      filteredMalwareOptions
        ?.filter((option) => option.value)
        ?.map((option) => option.name) || []
    )

    if (tab === Tabs.INSTANCES || tab === Tabs.VOLUMES) {
      const selectedEC2FiltersCurrent = ObjHelper.cloneDeep(selectedEC2Filters)
      setSelectedEC2Filters(
        selectedEC2FiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.MALWARE) {
            return {
              ...filter,
              options: filteredMalwareOptions,
            }
          }
          return filter
        })
      )

      const selectedEBSFiltersCurrent = ObjHelper.cloneDeep(selectedEBSFilters)
      setSelectedEBSFilters(
        selectedEBSFiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.MALWARE) {
            return {
              ...filter,
              options: filteredMalwareOptions,
            }
          }
          return filter
        })
      )
    }

    if (tab === Tabs.EFS) {
      const selectedEFSFiltersCurrent = ObjHelper.cloneDeep(selectedEFSFilters)
      setSelectedEFSFilters(
        selectedEFSFiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.MALWARE) {
            return {
              ...filter,
              options: filteredMalwareOptions,
            }
          }
          return filter
        })
      )
    }

    if (tab === Tabs.S3) {
      const selectedS3FiltersCurrent = ObjHelper.cloneDeep(selectedS3Filters)
      setSelectedS3Filters(
        selectedS3FiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.MALWARE) {
            return {
              ...filter,
              options: filteredMalwareOptions,
            }
          }
          return filter
        })
      )
    }
  }

  const onRansomwareFilterChange = (ransomware: string) => {
    const ransomwareFilter = selectedFiltersByTab.find(
      (filter) => filter.name === ThreatFilter.RANSOMWARE
    )
    if (!ransomwareFilter) {
      return
    }

    const ransomwareFilterOptions = ransomwareFilter.options

    const filteredRansomwareOptions = ransomwareFilterOptions?.filter(
      (option) => option.name !== ransomware
    )

    if (customQuery.ransomware) {
      router.push(DashboardRoutes.assetsThreatsWithParams(String(tab), {}))
    }

    setSelectedRansomwares(
      filteredRansomwareOptions?.map((option) => option.name) || []
    )

    if (tab === Tabs.INSTANCES || tab === Tabs.VOLUMES) {
      const selectedEC2FiltersCurrent = ObjHelper.cloneDeep(selectedEC2Filters)
      setSelectedEC2Filters(
        selectedEC2FiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.RANSOMWARE) {
            return {
              ...filter,
              options: filteredRansomwareOptions,
            }
          }
          return filter
        })
      )

      const selectedEBSFiltersCurrent = ObjHelper.cloneDeep(selectedEBSFilters)
      setSelectedEBSFilters(
        selectedEBSFiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.RANSOMWARE) {
            return {
              ...filter,
              options: filteredRansomwareOptions,
            }
          }
          return filter
        })
      )
    }

    if (tab === Tabs.EFS) {
      const selectedEFSFiltersCurrent = ObjHelper.cloneDeep(selectedEFSFilters)
      setSelectedEFSFilters(
        selectedEFSFiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.RANSOMWARE) {
            return {
              ...filter,
              options: filteredRansomwareOptions,
            }
          }
          return filter
        })
      )
    }

    if (tab === Tabs.S3) {
      const selectedS3FiltersCurrent = ObjHelper.cloneDeep(selectedS3Filters)
      setSelectedS3Filters(
        selectedS3FiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.RANSOMWARE) {
            return {
              ...filter,
              options: filteredRansomwareOptions,
            }
          }
          return filter
        })
      )
    }
  }

  const onFsCheckFilterChange = (fsCheck: string) => {
    const fsCheckFilter = selectedFiltersByTab.find(
      (filter) => filter.name === ThreatFilter.FS_CHECK //'fsCheck'
    )

    if (!fsCheckFilter) {
      return
    }

    const fsCheckFilterOptions = fsCheckFilter.options

    const filteredFsCheckOptions = fsCheckFilterOptions?.filter(
      (option) => option.label !== fsCheck
    )

    if (customQuery.filesystem) {
      router.push(DashboardRoutes.assetsThreatsWithParams(String(tab), {}))
    }

    setSelectedFsChecks(
      filteredFsCheckOptions?.map((option) => option.label ?? '') || []
    )

    if (tab === Tabs.INSTANCES || tab === Tabs.VOLUMES) {
      const selectedEC2FiltersCurrent = ObjHelper.cloneDeep(selectedEC2Filters)
      setSelectedEC2Filters(
        selectedEC2FiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.FS_CHECK) {
            return {
              ...filter,
              options: filteredFsCheckOptions,
            }
          }
          return filter
        })
      )

      const selectedEBSFiltersCurrent = ObjHelper.cloneDeep(selectedEBSFilters)
      setSelectedEBSFilters(
        selectedEBSFiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.FS_CHECK) {
            return {
              ...filter,
              options: filteredFsCheckOptions,
            }
          }
          return filter
        })
      )
    }

    if (tab === Tabs.EFS) {
      const selectedEFSFiltersCurrent = ObjHelper.cloneDeep(selectedEFSFilters)
      setSelectedEFSFilters(
        selectedEFSFiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.FS_CHECK) {
            return {
              ...filter,
              options: filteredFsCheckOptions,
            }
          }
          return filter
        })
      )
    }

    if (tab === Tabs.S3) {
      const selectedS3FiltersCurrent = ObjHelper.cloneDeep(selectedS3Filters)
      setSelectedS3Filters(
        selectedS3FiltersCurrent.map((filter) => {
          if (filter.name === ThreatFilter.FS_CHECK) {
            return {
              ...filter,
              options: filteredFsCheckOptions,
            }
          }
          return filter
        })
      )
    }
  }

  const changeFilterByTab = ({
    selectedFilters,
    possibleFilters,
    filterName,
    isQueryExists,
    tagsCallback,
    selectedCallback,
  }: {
    selectedFilters: VIRow
    possibleFilters: VIRow
    filterName: string
    isQueryExists: boolean
    tagsCallback: (value: React.SetStateAction<Array<string>>) => void
    selectedCallback: (
      value: React.SetStateAction<Array<ValueInterface>>
    ) => void
  }) => {
    const selectedFilter = selectedFilters.find(
      (filter) => filter.name === filterName
    )

    const tagsCallbackExecute = (data: VIRow) => {
      if (filterName === ThreatFilter.FS_CHECK) {
        tagsCallback(data?.map((option) => option?.label ?? ''))
      } else {
        tagsCallback(data?.map((option) => option.name))
      }
    }

    if (isQueryExists) {
      const allPossibleFiltersOptions = possibleFilters.find(
        (filter) => filter.name === filterName
      ) as ValueInterface

      const filteredOptions: ValueInterface = {
        ...allPossibleFiltersOptions,
        options: allPossibleFiltersOptions?.options?.map((option) => {
          return {
            ...option,
            value: true,
          }
        }),
      }

      if (!filteredOptions.options?.length && selectedFilters[0]?.options) {
        filteredOptions.options = [...selectedFilters[0].options]
      }
      tagsCallbackExecute(filteredOptions.options ?? [])
      const updatedSelectedFilters = selectedFilters.filter(
        (filter) => filter.name !== filteredOptions.name
      )

      selectedCallback([...updatedSelectedFilters, filteredOptions])
    } else {
      if (selectedFilter) {
        tagsCallbackExecute(selectedFilter.options ?? [])
      } else {
        tagsCallback([])
      }
    }
  }

  const handleTabChange = (newTab: number) => {
    setActiveTab(newTab)

    setFilterSearch('')

    const isRansomwareQuery = Boolean(customQuery.ransomware === 'all')

    const isMalwareQuery = Boolean(customQuery.malware === 'all')

    const isFsCheckQuery = Boolean(customQuery.filesystem === 'all')

    if (newTab === Tabs.INSTANCES) {
      // Ransomware filter

      changeFilterByTab({
        selectedFilters: selectedEC2Filters,
        possibleFilters: possibleVolumeFilters,
        selectedCallback: setSelectedEC2Filters,
        filterName: ThreatFilter.RANSOMWARE,
        isQueryExists: isRansomwareQuery,
        tagsCallback: setSelectedRansomwares,
      })

      // Malware filter

      changeFilterByTab({
        selectedFilters: selectedEC2Filters,
        possibleFilters: possibleVolumeFilters,
        selectedCallback: setSelectedEC2Filters,
        filterName: ThreatFilter.MALWARE,
        isQueryExists: isMalwareQuery,
        tagsCallback: setSelectedMalwares,
      })

      // FS check filter

      changeFilterByTab({
        selectedFilters: selectedEC2Filters,
        possibleFilters: possibleVolumeFilters,
        selectedCallback: setSelectedEC2Filters,
        filterName: ThreatFilter.FS_CHECK,
        isQueryExists: isFsCheckQuery,
        tagsCallback: setSelectedFsChecks,
      })
    }

    if (newTab === Tabs.VOLUMES) {
      // Ransomware filter

      changeFilterByTab({
        selectedFilters: selectedEBSFilters,
        possibleFilters: possibleVolumeFilters,
        selectedCallback: setSelectedEBSFilters,
        filterName: ThreatFilter.RANSOMWARE,
        isQueryExists: isRansomwareQuery,
        tagsCallback: setSelectedRansomwares,
      })

      // Malware filter

      changeFilterByTab({
        selectedFilters: selectedEBSFilters,
        possibleFilters: possibleVolumeFilters,
        selectedCallback: setSelectedEBSFilters,
        filterName: ThreatFilter.MALWARE,
        isQueryExists: isMalwareQuery,
        tagsCallback: setSelectedMalwares,
      })

      // FS check filter

      changeFilterByTab({
        selectedFilters: selectedEBSFilters,
        possibleFilters: possibleVolumeFilters,
        selectedCallback: setSelectedEBSFilters,
        filterName: ThreatFilter.FS_CHECK,
        isQueryExists: isFsCheckQuery,
        tagsCallback: setSelectedFsChecks,
      })
    }

    if (newTab === Tabs.EFS) {
      // Ransomware filter

      changeFilterByTab({
        selectedFilters: selectedEFSFilters,
        possibleFilters: possibleEfsFilters,
        selectedCallback: setSelectedEFSFilters,
        filterName: ThreatFilter.RANSOMWARE,
        isQueryExists: isRansomwareQuery,
        tagsCallback: setSelectedRansomwares,
      })

      // Malware filter

      changeFilterByTab({
        selectedFilters: selectedEFSFilters,
        possibleFilters: possibleEfsFilters,
        selectedCallback: setSelectedEFSFilters,
        filterName: ThreatFilter.MALWARE,
        isQueryExists: isMalwareQuery,
        tagsCallback: setSelectedMalwares,
      })
      if (!isFsCheckQuery) {
        setSelectedFsChecks([])
      }
    }

    if (newTab === Tabs.S3) {
      // Malware filter

      changeFilterByTab({
        selectedFilters: selectedS3Filters,
        possibleFilters: possibleS3Filters,
        selectedCallback: setSelectedS3Filters,
        filterName: ThreatFilter.MALWARE,
        isQueryExists: isMalwareQuery,
        tagsCallback: setSelectedMalwares,
      })

      if (!isFsCheckQuery) {
        setSelectedFsChecks([])
      }

      if (!isRansomwareQuery) {
        setSelectedRansomwares([])
      }
    }
  }

  useEffect(() => {
    const queryTab = customQuery.t

    if (queryTab) {
      if (
        !isLoading &&
        Number(queryTab) === Tabs.VOLUMES &&
        !dataForEc2EbsTables.volumes.length
      ) {
        setActiveTab(Tabs.INSTANCES)
        return
      }
      setActiveTab(Number(queryTab))
      handleTabChange(Number(queryTab))
    }
  }, [JSON.stringify(dataForEc2EbsTables)])

  function getFilterByQuery(
    queryThreatValue: string,
    queryTab: number,
    threatType: ThreatFilter
  ) {
    if (queryThreatValue === 'all') {
      let allSelectedThreats: ValueInterface | undefined = undefined
      if (queryTab === Tabs.INSTANCES || queryTab === Tabs.VOLUMES) {
        allSelectedThreats = possibleVolumeFilters.find(
          (filter) => filter.name === threatType
        )
      }
      if (queryTab === Tabs.EFS) {
        allSelectedThreats = possibleEfsFilters.find(
          (filter) => filter.name === threatType
        )
      }
      if (queryTab === Tabs.S3) {
        allSelectedThreats = possibleS3Filters.find(
          (filter) => filter.name === threatType
        )
      }

      if (allSelectedThreats && !allSelectedThreats.options?.length) {
        allSelectedThreats.options = SELECTED_ALL_THREATS
      }

      if (!allSelectedThreats) {
        return
      }

      const filteredThreat: ValueInterface = {
        ...allSelectedThreats,
        options: allSelectedThreats?.options?.map((option) => {
          return {
            ...option,
            value: true,
          }
        }),
      }

      if (threatType === ThreatFilter.MALWARE) {
        setSelectedMalwares(
          filteredThreat.options?.map((option) => option.name) ?? []
        )
      }
      if (threatType === ThreatFilter.RANSOMWARE) {
        setSelectedRansomwares(
          filteredThreat.options?.map((option) => option.name) ?? []
        )
      }
      if (threatType === ThreatFilter.FS_CHECK) {
        setSelectedFsChecks(
          filteredThreat.options?.map((option) => option.label as string) ?? []
        )
      }

      setSelectedEBSFilters([filteredThreat])
      setSelectedEC2Filters([filteredThreat])
      setSelectedEFSFilters([filteredThreat])
      setSelectedS3Filters([filteredThreat])

      return
    }

    if (
      possibleFiltersByTab.some(
        (filter) =>
          filter.name === threatType &&
          filter.options?.find((option) => option.name === queryThreatValue)
      )
    ) {
      const currentThreatFilter = possibleFiltersByTab.find(
        (filter) =>
          filter.name === threatType &&
          filter?.options?.find((option) => option.name === queryThreatValue)
      )

      if (!currentThreatFilter) {
        return
      }

      const filteredThreat: ValueInterface = {
        ...currentThreatFilter,
        options: currentThreatFilter?.options
          ?.filter((option) => option.name === queryThreatValue)
          ?.map((option) => {
            return {
              ...option,
              value: true,
            }
          }),
      }

      if (threatType === ThreatFilter.MALWARE) {
        setSelectedMalwares(
          filteredThreat.options?.map((option) => option.name) ?? []
        )
      }
      if (threatType === ThreatFilter.RANSOMWARE) {
        setSelectedRansomwares(
          filteredThreat.options?.map((option) => option.name) ?? []
        )
      }
      if (threatType === ThreatFilter.FS_CHECK) {
        setSelectedFsChecks(
          filteredThreat.options
            ?.filter((option) => option.value)
            .map((option) => option.label as string) ?? []
        )
      }

      if (queryTab === Tabs.INSTANCES || queryTab === Tabs.VOLUMES) {
        setSelectedEBSFilters([filteredThreat])
        setSelectedEC2Filters([filteredThreat])
      }
      if (queryTab === Tabs.EFS) {
        setSelectedEFSFilters([filteredThreat])
      }
      if (queryTab === Tabs.S3) {
        setSelectedS3Filters([filteredThreat])
      }
    }
  }

  useEffect(() => {
    if (customQuery.t && tab !== Number(customQuery.t)) {
      return
    }

    const queryTab = Number(customQuery.t)

    if (customQuery.malware) {
      const value = customQuery.malware
      getFilterByQuery(value, queryTab, ThreatFilter.MALWARE)
    }

    if (customQuery.ransomware) {
      const value = customQuery.ransomware
      getFilterByQuery(value, queryTab, ThreatFilter.RANSOMWARE)
    }

    if (customQuery.filesystem) {
      const value = customQuery.filesystem
      getFilterByQuery(value, queryTab, ThreatFilter.FS_CHECK)
    }
  }, [JSON.stringify(possibleFiltersByTab)])

  const parseFilters = (filters: Array<ValueInterface>) => {
    return DataHelper.getDataForDashboardFilter(filters, filterSearch)
  }

  if (!(dataForEc2EbsTables && dataForEfsTable && dataForS3Table)) {
    return null
  }

  const newVolumesAndInstancesList = new ListVolumesWithThreats(
    dataForEc2EbsTables
  ).execute(parseFilters(selectedEBSFilters))

  const efsFilteredData = initialPrioritySortForEFSTable(
    new ListEfsWithThreats(dataForEfsTable).execute(
      parseFilters(selectedEFSFilters)
    )
  )

  const s3FilteredData = initialPrioritySortForS3Table(
    new ListS3BucketsWithThreats(dataForS3Table).execute(
      parseFilters(selectedS3Filters)
    )
  )

  const volumesFilteredData: Array<VolumeThreatsOutput> =
    initialPrioritySortForEBSTable(
      newVolumesAndInstancesList.map(({ volume }) => volume)
    )

  const instancesFilteredData: Array<InstancesOutput> =
    initialPrioritySortForEC2Table(
      getUniqueInstances(newVolumesAndInstancesList)
    )

  return (
    <div className="innerContent innerContentBlueBackground">
      <Box className="wrap-1687865552776">
        <div className="wrapAssetsThreatsTable newTablePreview">
          <CustomTabs value={tab} onChangeTab={handleTabChange}>
            <Tab label="Instances" />
            <Tab label="Volumes" />
            <Tab label="S3 Buckets" />
            <Tab label="EFS" />
            <Tab label="Servers" />
          </CustomTabs>

          <MaterialTab value={tab} index={Tabs.INSTANCES}>
            <AssetsWithThreatsContainer
              possibleFilters={possibleFiltersByTab}
              selectedFilters={selectedFiltersByTab}
              onFiltersChange={onFiltersChange}
              isFiltersLoading={isLoading}
              filterSearch={filterSearch}
              setFilterSearch={setFilterSearch}
              selectedFsChecks={selectedFsChecks}
              selectedMalwares={selectedMalwares}
              selectedRansomwares={selectedRansomwares}
              onMalwareFilterChange={onMalwareFilterChange}
              onRansomwareFilterChange={onRansomwareFilterChange}
              onFsCheckFilterChange={onFsCheckFilterChange}
            >
              <Table
                data={instancesFilteredData}
                columns={InstanceColumns.getColumns({
                  aliasNamesWithId: aliasNames.aliasNamesEc2WithId,
                })}
                generalProps={{
                  isLoading: isLoading,
                  noDataMessage: 'No EC2 Instances found',
                }}
              />
            </AssetsWithThreatsContainer>
          </MaterialTab>
          <MaterialTab value={tab} index={Tabs.VOLUMES}>
            <AssetsWithThreatsContainer
              possibleFilters={possibleFiltersByTab}
              selectedFilters={selectedFiltersByTab}
              onFiltersChange={onFiltersChange}
              isFiltersLoading={isLoading}
              filterSearch={filterSearch}
              setFilterSearch={setFilterSearch}
              selectedFsChecks={selectedFsChecks}
              selectedMalwares={selectedMalwares}
              selectedRansomwares={selectedRansomwares}
              onMalwareFilterChange={onMalwareFilterChange}
              onRansomwareFilterChange={onRansomwareFilterChange}
              onFsCheckFilterChange={onFsCheckFilterChange}
            >
              <Table
                data={volumesFilteredData}
                columns={VolumeColumns.getColumns({
                  aliasNamesWithId: aliasNames.aliasNamesEbsWithId,
                })}
                generalProps={{
                  isLoading: isLoading,
                  noDataMessage: 'No EBS Volumes found',
                }}
              />
            </AssetsWithThreatsContainer>
          </MaterialTab>
          <MaterialTab value={tab} index={Tabs.S3}>
            <AssetsWithThreatsContainer
              possibleFilters={possibleFiltersByTab}
              selectedFilters={selectedFiltersByTab}
              onFiltersChange={onFiltersChange}
              isFiltersLoading={isLoading}
              filterSearch={filterSearch}
              setFilterSearch={setFilterSearch}
              selectedFsChecks={selectedFsChecks}
              selectedMalwares={selectedMalwares}
              selectedRansomwares={selectedRansomwares}
              onMalwareFilterChange={onMalwareFilterChange}
              onRansomwareFilterChange={onRansomwareFilterChange}
              onFsCheckFilterChange={onFsCheckFilterChange}
            >
              <Table
                data={s3FilteredData}
                columns={S3Columns.getColumns({
                  aliasNamesWithId: aliasNames.aliasNamesS3WithId,
                })}
                generalProps={{
                  isLoading: isLoading,
                  noDataMessage: 'No S3 buckets found',
                }}
              />
            </AssetsWithThreatsContainer>
          </MaterialTab>
          <MaterialTab value={tab} index={Tabs.EFS}>
            <AssetsWithThreatsContainer
              possibleFilters={possibleFiltersByTab}
              selectedFilters={selectedFiltersByTab}
              onFiltersChange={onFiltersChange}
              isFiltersLoading={isLoading}
              filterSearch={filterSearch}
              setFilterSearch={setFilterSearch}
              selectedFsChecks={selectedFsChecks}
              selectedMalwares={selectedMalwares}
              selectedRansomwares={selectedRansomwares}
              onMalwareFilterChange={onMalwareFilterChange}
              onRansomwareFilterChange={onRansomwareFilterChange}
              onFsCheckFilterChange={onFsCheckFilterChange}
            >
              <Table
                data={efsFilteredData}
                columns={EfsColumns.getColumns({
                  aliasNamesWithId: aliasNames.aliasNamesEfsWithId,
                })}
                generalProps={{
                  isLoading: isLoading,
                  noDataMessage: 'No EFS found',
                }}
              />
            </AssetsWithThreatsContainer>
          </MaterialTab>
          <MaterialTab value={tab} index={Tabs.OVA_SERVERS}>
            <OvaServersWithThreats />
          </MaterialTab>
        </div>
      </Box>
    </div>
  )
}

export default AssetsWithThreats
