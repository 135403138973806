// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'
import { Response } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/list_backups_for_asset_pb'
import { Response as LastCleanBackupForAssetResponse } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/get_last_clean_backup_for_asset_pb'
import { TimeRange, Pagination } from '@lib/clients/types'

export interface ListBackupsForAsset {
  awsbRpsList: Array<AWSBRecoveryPoint>
  ebsSnapshotsList: Array<EBSSnapshot>
  elastioRpsList: Array<ElastioRecoveryPoint>
  ovaBackupList: Array<OvaBackup>
}

export interface ListBackupsForAssetItem {
  awsbRpsList: Array<AWSBRecoveryPoint>
  ebsSnapshotsList: Array<EBSSnapshot>
  elastioRpsList: Array<ElastioRecoveryPoint>
}

export interface ListByAssetItemsRequest extends Pagination {
  assetItems: Array<AssetItemAsObject>
  timeRange?: TimeRange
}

export interface LastCleanBackupForAsset {
  awsbRps: AWSBRecoveryPoint
  ebsSnapshots: EBSSnapshot
  elastioRps: ElastioRecoveryPoint
  ovaBackup: OvaBackup
}

export type ListBackupsForAssetAsObject = Response.AsObject
export type LastCleanBackupForAssetResponseAsObject = Partial<
  ReturnType<typeof LastCleanBackupForAssetResponse.toObject>
>

export interface ListBackupsForAssetFilters {
  assetIdList?: Array<string>
  providersList?: Array<any> // Request.Initial.Filter.Provider -> implement if we need it
  timeRange?: TimeRange // Filter.TimeRange -> implement if we need it
  pageSize?: number
}

export type ListBackupsForAssetRequest = ListBackupsForAssetFilters & Pagination
export interface AssetItemAsObject {
  id: string
  kind: number
}

export enum KindAssetItem {
  KIND_FILE = 0,
  KIND_STREAM = 1,
  KIND_VOLUME = 2,
  KIND_DISK = 3,
  KIND_S3_ITEM = 4,
  KIND_FS_ITEM = 5,
}

export interface ListBackupsForAssetItem {
  awsbRpsList: Array<AWSBRecoveryPoint>
  ebsSnapshotsList: Array<EBSSnapshot>
  elastioRpsList: Array<ElastioRecoveryPoint>
}

export interface ListBackupsForAssetItemFilters {
  assetItemsList: Array<AssetItemAsObject>
  timeRange?: Date
}

export interface ListBackupsForAssetItemPagination {
  pageToken?: string
}

export type ListBackupsForAssetItemRequestFilters =
  ListBackupsForAssetItemFilters & ListBackupsForAssetItemPagination
