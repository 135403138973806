import React from 'react'
import ContentBlock from '@components-composite/content-block/ContentBlock'
import DialogModal from '@components-composite/modals/DialogModal'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import Modal from '@lib/constants/modal.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import TableFactory from '@lib/factories/table.factory'
import DataHelper from '@lib/helpers/data.helper'
import SystemHelper from '@lib/helpers/system.helper'
import { useDnDforArray } from '@lib/hooks/useDnD'
import { useModal } from '@lib/hooks/useModal'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { RetentionPolicyPriority } from '@lib/interfaces/retention-policies.interface'
import { Button } from '@mui/material'
import {
  deletePolicyRetention,
  setCustomRetentionPolicyPriorities,
  updateStatusPolicyRetention,
} from '@store/actions/policies-retention.action'
import { getPoliciesRetention } from '@store/selectors/policies-retention.selector'
import ControlledVITable from '@tables/ControlledVITable'
import {
  POLICIES_RETENTION_HEAD,
  POLICIES_RETENTION_HEAD_HIDDEN_ASSETS,
} from '@tables/core/table-constants'
import { OnMenuClickData } from '@tables/core/table-handlers'
import { POLICIES_RETENTION_MAPPER } from '@tables/core/table-vi-draw-mappers'
import MenuVIRow from '@tables/rows-vi/MenuVIRow'
import clsx from 'clsx'
import { memo, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultRetentionPoliciesConstant } from '@lib/constants/policies.constant'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { PoliciesRoutes, useNavigation } from '@lib/router'

enum MENU {
  edit = 'Edit',
  delete = 'Delete',
}

function PoliciesRetentionComplex() {
  const dispatch = useDispatch()
  const router = useNavigation()
  const { scanOnlyReleaseJanuary } = useCustomFlags()

  const [isOrderEditing, setIsOrderEditing] = useState(false)

  const policies = useSelector(getPoliciesRetention)

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_POLICIES_RETENTION,
    PreloaderConstants.UPDATE_STATUS_POLICY_RETENTION,
    PreloaderConstants.DELETE_POLICY_RETENTION,
    PreloaderConstants.SET_CUSTOM_RETENTION_POLICY_PRIORITIES,
  ])

  const { openModal, modalProps } = useModal<string>(
    Modal.deletePolicyRetention,
    (policyId: string) => {
      dispatch(deletePolicyRetention(policyId))
    }
  )

  const {
    onDragStart,
    onDragEnd,
    onDragOver,
    setDraggedArray,
    setNativeArray,
    draggedArray,
    nativeArray,
  } = useDnDforArray(TableFactory.policiesRetentionTable(policies))

  function MenuVIRowWrapper(props: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const policyName: string = props.data[0].value

    function renderMenuType() {
      const isDefaultPolicy =
        policyName === DefaultRetentionPoliciesConstant.DEFAULT

      if (isDefaultPolicy) {
        return [MENU.edit]
      }

      return [MENU.edit, MENU.delete]
    }

    const onStartHandler = () => {
      if (DataHelper.isRetentionDefaultPolicy(policyName)) {
        return
      }
      onDragStart(draggedArray.findIndex((row) => row[0]?.name === policyName))
    }

    const onEndHandler = () => {
      if (DataHelper.isRetentionDefaultPolicy(policyName)) {
        return
      }

      onDragEnd(draggedArray.findIndex((row) => row[0]?.name === policyName))
    }

    const onMouseDownHandler = (e: React.MouseEvent) => {
      if (!isOrderEditing) {
        return
      }

      const DRAGGABLE_TARGET = 'draggable'
      const tableRow = e.target as HTMLElement
      // It should be: <td className=""><div>data...</div></td>, in this case we find draggable className
      const tableData = tableRow.closest('div')
      if (!tableData?.className.includes(DRAGGABLE_TARGET)) {
        return e.preventDefault()
      }

      // Start dragging
      onStartHandler()
    }

    const isMenuDraggable = useMemo(
      () => isOrderEditing && !DataHelper.isRetentionDefaultPolicy(policyName),
      [isOrderEditing]
    )

    return (
      <MenuVIRow
        {...props}
        menu={renderMenuType()}
        draggable={isMenuDraggable}
        onDragEnd={onEndHandler}
        onDragStart={onStartHandler}
        onDragOver={onDragOver}
        onMouseDown={onMouseDownHandler}
      />
    )
  }

  const onSaveOrder = () => {
    // Save previous order before editing due to cancel case
    setNativeArray(draggedArray)

    if (!isOrderEditing) {
      setIsOrderEditing(true)
      return
    }

    const dataToServer: Array<RetentionPolicyPriority> = draggedArray.map(
      (item, index) => ({
        // @ts-ignore
        policyId: item[0].name,
        priority: index,
      })
    )

    dispatch(setCustomRetentionPolicyPriorities(dataToServer))

    setIsOrderEditing(false)
  }

  const onCancelOrder = () => {
    setDraggedArray(nativeArray)
    setIsOrderEditing(false)
  }

  const isValuesChanged = useMemo(
    () =>
      !!draggedArray.filter(
        (data, index) =>
          !nativeArray.some(
            (old, ind) => old[0]?.name === data[0]?.name && index === ind
          )
      ).length,
    [draggedArray, nativeArray]
  )

  const onMenuClick = (menuData: OnMenuClickData) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const id = menuData.data[0].value
    switch (menuData.chosenMenu) {
      case MENU.edit:
        if (id === 'Default') {
          router.push(PoliciesRoutes.policyRetentionEdit)
          return
        }
        router.push(PoliciesRoutes.policyRetentionEditWithId(id))
        return

      case MENU.delete:
        openModal(id)
        return

      default:
        SystemHelper.throwErrorInLocalEnv('Unresolved policies menu')
    }
  }

  const onPauseResumePolicyClick = (name: string) => {
    const currentPolicy = policies.find((item) => item.policyName === name)
    if (currentPolicy) {
      dispatch(updateStatusPolicyRetention(currentPolicy))
    }
  }

  const tableHeader = scanOnlyReleaseJanuary
    ? POLICIES_RETENTION_HEAD
    : POLICIES_RETENTION_HEAD_HIDDEN_ASSETS

  return (
    <div className="wrap-1658432471114 policyComplex policiesRetentionComplex">
      <ContentBlock
        data={policies}
        loading={loading}
        noDataMessage="There are no retention policies yet"
      >
        <div className="optionButtons">
          {isOrderEditing && (
            <Button
              className="showButton"
              variant="text"
              color="primary"
              type="button"
              onClick={onCancelOrder}
            >
              Cancel
            </Button>
          )}
          <Button
            className="editOrderBtn"
            variant="contained"
            color="primary"
            type="button"
            disabled={(!isValuesChanged && isOrderEditing) || loading}
            onClick={onSaveOrder}
          >
            {isOrderEditing ? 'Save' : 'Edit order'}
          </Button>
        </div>
        <div className="widgetWrap">
          <ControlledVITable
            head={tableHeader}
            rows={draggedArray}
            onMenuClick={onMenuClick}
            menu={[MENU.edit, MENU.delete]}
            rowComponent={MenuVIRowWrapper}
            className={clsx(
              'tableVIUncontrolled policiesRetentionTable tableUpgrade striped',
              {
                controlsDisabled: loading,
                allColumns: scanOnlyReleaseJanuary,
              }
            )}
            columnDrawMapper={POLICIES_RETENTION_MAPPER(
              onPauseResumePolicyClick,
              onPauseResumePolicyClick,
              50,
              isOrderEditing,
              scanOnlyReleaseJanuary
            )}
          />
        </div>
      </ContentBlock>

      <DialogModal
        description={
          <div>
            You are going to remove the Retention Policy
            {<LongTextTooltip text={modalProps.data ?? ''} maxLength={35} />}
            Please confirm the deletion.
          </div>
        }
        {...modalProps}
      />
    </div>
  )
}

export default memo(PoliciesRetentionComplex)
