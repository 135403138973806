import React from 'react'
import Box from '@mui/material/Box'
import { IntegrityScanBlock } from '@components-complex/add-policy-complex/integrity-scan-block'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAddIntegrityScan,
  getIntegrityCheck,
  getIntegrityScanOption,
  getIsEntropyDetectionEnabled,
  getKeepDataCopy,
} from '@store/selectors/add-policy.selector'
import {
  setAddIntegrityScan,
  setAddSelectedVariant,
  setEntropyDetectionConfiguration,
  setIntegrityCheck,
  setIntegrityScanOption,
  setKeepDataCopy,
} from '@store/actions/add-policy.action'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import { useRecoveryTestingOption } from './use-recovery-testing-option'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import {
  resetEfsSelectedData,
  resetS3SelectedData,
  setEditMainTabAssets,
} from '@store/actions/assets-select.action'
import { SelectedVariant, TabAssetType } from '@lib/constants/app.constant'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'

interface Props {
  isNotEc2AndEbsAssetType: boolean
}

export function ProtectionOptions({ isNotEc2AndEbsAssetType }: Props) {
  const dispatch = useDispatch()
  const integrityCheck = useSelector(getIntegrityCheck)
  const integrityScan = useSelector(getAddIntegrityScan)
  const integrityScanOption = useSelector(getIntegrityScanOption)
  const keepDataCopy = useSelector(getKeepDataCopy)
  const isEntropyDetectionEnabled = useSelector(getIsEntropyDetectionEnabled)

  const { isRecoveryTestingDisabled } = useRecoveryTestingOption()

  const handleAddIntegrityScan = (newIntegrityScan: IntegrityScanInterface) => {
    const malwareScanEnabled = newIntegrityScan.scanForMalware
    const ransomwareScanEnabled = newIntegrityScan.scanForRansomware
    const bothScansDisabled = !malwareScanEnabled && !ransomwareScanEnabled

    const shouldDeselectEntropyDetection =
      (malwareScanEnabled && !ransomwareScanEnabled) || bothScansDisabled

    if (shouldDeselectEntropyDetection) {
      dispatch(setEntropyDetectionConfiguration(false))
    }

    dispatch(setAddIntegrityScan(newIntegrityScan))
  }

  const handleSetIntegrityCheck = (newIntegrityCheck: boolean) => {
    const integrityCheckEnabled = newIntegrityCheck
      ? IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM
      : IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED

    dispatch(setIntegrityCheck(integrityCheckEnabled))
  }

  const handleSetIntegrityScanOption = (
    selectedOption: IntegrityScanOptions
  ) => {
    dispatch(setIntegrityScanOption(selectedOption))
    if (
      selectedOption !== IntegrityScanOptions.SCAN_WITH_ELASTIO &&
      isNotEc2AndEbsAssetType
    ) {
      dispatch(resetEfsSelectedData())
      dispatch(resetS3SelectedData())
      dispatch(setEditMainTabAssets(TabAssetType.Ec2andEbs))
      dispatch(setAddSelectedVariant(SelectedVariant.AllEC2Variant))
    }

    if (selectedOption === IntegrityScanOptions.BACKUP_WITH_ELASTIO) {
      dispatch(
        setIntegrityCheck(IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED)
      )
      dispatch(
        setAddIntegrityScan({
          scanForMalware: false,
          scanForRansomware: false,
        })
      )
    } else {
      dispatch(
        setIntegrityCheck(IntegrityCheckOptions.INTEGRITY_CHECK_FILE_SYSTEM)
      )
      dispatch(
        setAddIntegrityScan({
          scanForMalware: true,
          scanForRansomware: true,
        })
      )
    }
  }

  const handleSetKeepDataCopy = (newKeepDataCopy: KeepDataCopyInterface) => {
    dispatch(setKeepDataCopy(newKeepDataCopy))
  }

  const handleEntropyDetectionSelection = (value: boolean) => {
    dispatch(setEntropyDetectionConfiguration(value))
  }

  return (
    <Box>
      <IntegrityScanBlock
        integrityScanData={integrityScan}
        onIntegrityScanChange={handleAddIntegrityScan}
        integrityCheckEnabled={!!integrityCheck}
        onIntegrityCheckClick={handleSetIntegrityCheck}
        isRecoveryTestingDisabled={isRecoveryTestingDisabled}
        onIntegrityScanOptionsChange={handleSetIntegrityScanOption}
        integrityScanOptionSelected={integrityScanOption}
        keepDataCopyData={keepDataCopy}
        onKeepDataCopyChange={handleSetKeepDataCopy}
        entropyDetectionsOptions={{
          isEntropyEnabled: isEntropyDetectionEnabled,
          onEntropyEnabledChange: handleEntropyDetectionSelection,
        }}
      />
    </Box>
  )
}
