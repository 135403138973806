import { styled } from '@mui/material'
import Chip from '@mui/material/Chip'

type Props = {
  isHighlighted?: boolean
}

export const RoundCountChips = styled(Chip)<Props>(({ isHighlighted }) => ({
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 600,
  height: '25px',
  width: '25px',
  background: isHighlighted ? 'var(--red400)' : 'var(--grey100)',
  color: isHighlighted ? 'var(--white)' : 'var(--grey900)',
}))
