import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useAssetsOvaServerQuery } from 'ui-v2/src/hooks/queries/assets'
import { getOvaServerColumns } from './column-config'
import { AssetsOvaServerTableData } from 'ui-v2/src/lib/use-cases/assets'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'
import { useAssetsOvaServerFilters } from 'ui-v2/src/hooks/filters/assets'
import { useListOvaAccountsQuery } from 'ui-v2/src/hooks/queries/ova-accounts'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'

const AssetsOvaServer = () => {
  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsOvaServerFilters()

  const { data: assetsOvaServerData, isLoading: isAssetsOvaServerDataLoading } =
    useAssetsOvaServerQuery(filters)

  const { data: ovaAccountsData, isLoading: isOvaAccountsLoading } =
    useListOvaAccountsQuery()

  const data = useMemo(() => {
    if (!assetsOvaServerData || !ovaAccountsData) {
      return undefined
    }

    return new AssetsOvaServerTableData({
      servers: assetsOvaServerData.servers,
      ovaAccounts: ovaAccountsData,
    }).getOvaServerTableData()
  }, [assetsOvaServerData, ovaAccountsData])

  const columns = useMemo(getOvaServerColumns, [])

  const isLoading = isAssetsOvaServerDataLoading || isOvaAccountsLoading

  return (
    <Stack spacing={1}>
      <AssetFilters
        filters={[
          ASSET_FILTERS_MAP.OVA_ACCOUNT_ID,
          ASSET_FILTERS_MAP.BACKUP_VENDOR,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsOvaServerData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsOvaServer
