export const FORM_FIELDS = {
  RULE_NAME: 'ruleName',
  DESCRIPTION: 'description',
  MODE: 'mode',
  EVENTS: 'events',
  SUPPRESS_UNTIL: 'suppressUntil',
  REASON_SUPPRESSION: 'reasonSuppression',
} as const

export const MODE = FORM_FIELDS.MODE
