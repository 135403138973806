/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, OvaServer } from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import { AccountName } from '../account-name'
import { Region } from './region'
import { AssetId } from './asset-id'

interface Props {
  asset: Asset
}

export function AssetDetails({ asset }: Props) {
  if (asset instanceof OvaServer) {
    return null
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" columnGap="24px">
        <AssetId asset={asset} />
        <AccountName asset={asset} />
        <Region asset={asset} />
      </Box>
    </Box>
  )
}
