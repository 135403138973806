import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import type { CSSProperties } from 'react'

const DEFAULT_ARROW_STYLES: CSSProperties = {
  margin: 'auto 0',
  width: '40px',
  position: 'absolute',
}
export const StyledArrowBackIcon = styled(IconButton)({
  ...DEFAULT_ARROW_STYLES,
  right: '100%',
  top: '240px',
})

export const StyledArrowNextIcon = styled(IconButton)({
  ...DEFAULT_ARROW_STYLES,

  right: 0,
  top: '240px',
  left: '100%',
})
