import FilterDashboardV2 from '@components-complex/filter-dashboard-v2/FilterDashboardV2'
import { PagePathConstant } from '@lib/constants'
import ValueInterface from '@lib/interfaces/value.interface'
import SearchForm from '@forms/search/search.form'

import React, { PropsWithChildren } from 'react'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import Box from '@mui/material/Box'
import EditableTags from '@components-composite/editable-tags/EditableTags'

interface Props {
  possibleFilters: Array<ValueInterface>
  selectedFilters: Array<ValueInterface>
  onFiltersChange: (newFilters: Array<ValueInterface>) => void
  isFiltersLoading: boolean
  filterSearch: string
  setFilterSearch: (search: string) => void
  selectedRansomwares: Array<string>
  selectedMalwares: Array<string>
  selectedFsChecks: Array<string>
  onRansomwareFilterChange: (tag: string) => void
  onMalwareFilterChange: (tag: string) => void
  onFsCheckFilterChange: (tag: string) => void
}

// Temporary component, will be removed after refactor (I hope)
// The problem is, with the new figma design, we have: tabs, filters, tags and table
// But the old one has: filters, tags, tabs and table
export function AssetsWithThreatsContainer(props: PropsWithChildren<Props>) {
  return (
    <>
      <FilterDashboardV2
        possibleFilters={props.possibleFilters}
        selectedFilters={props.selectedFilters}
        onFiltersChange={props.onFiltersChange}
        disabled={props.isFiltersLoading}
        showFilterFirst
        backButtonHref={PagePathConstant.DASHBOARD}
        tableName=""
        turnOffButton
        customSearch={
          <SearchForm
            placeholder="Search..."
            errorGroups={[ErrorGroupConstants.EMPTY]}
            defaultValues={{
              search: props.filterSearch,
            }}
            variant="whiteFilterSearchBlackIcon"
            onSubmit={(formData: FormSearchInterface) => {
              props.setFilterSearch?.(formData.search.toLowerCase())
            }}
          />
        }
      />
      <Box marginLeft="10px" className="tags">
        <EditableTags
          title="Ransomware"
          tags={props.selectedRansomwares ?? []}
          limit={3}
          onRemoveTag={props.onRansomwareFilterChange}
        />
        <EditableTags
          title="Malware"
          tags={props.selectedMalwares ?? []}
          limit={3}
          onRemoveTag={props.onMalwareFilterChange}
        />

        <EditableTags
          title="File System Integrity Scan"
          tags={props.selectedFsChecks ?? []}
          limit={3}
          onRemoveTag={props.onFsCheckFilterChange}
        />
      </Box>
      {props.children}
    </>
  )
}
