import { getAliasNamesForAccounts } from '@components-complex/dashboard-pages-v3/utils'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import RedStackModel from '@lib/models/red-stack.model'
import { useMemo } from 'react'

interface Props {
  regions: Array<string>
  regionsWithRelatedAccounts: Map<string, Array<string>>
  allActiveRedStacks: Array<RedStackModel>
}

export function useUniqueAccounts({
  regions,
  regionsWithRelatedAccounts,
  allActiveRedStacks,
}: Props) {
  return useMemo(() => {
    const filteredAccounts = new Set<string>()

    for (const [region, accountIds] of regionsWithRelatedAccounts) {
      if (regions.length > 0 && !regions.includes(region)) {
        continue
      }

      accountIds.forEach(filteredAccounts.add.bind(filteredAccounts))
    }

    let aliasNamesWithId: AliasName = {}
    if (allActiveRedStacks.length) {
      aliasNamesWithId = getAliasNamesForAccounts(
        allActiveRedStacks,
        filteredAccounts
      )
    }

    return [...filteredAccounts].map((item) => ({
      value: item,
      label: aliasNamesWithId[item]
        ? `${aliasNamesWithId[item]} (${item})`
        : item,
    }))
  }, [regions.length, regionsWithRelatedAccounts.size])
}
