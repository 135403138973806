/* eslint-disable import/no-extraneous-dependencies */
import { OvaAccount } from 'blues-corejs/dist'
import { ListOvaAccountsRequest, OvaAccountsPromiseClient } from '../grpc'
import { GrpcClient } from './grpc'
import { ClientConfig } from '../models/client'
import { OvaAccountTransformer } from '../transformers/ova-accounts'

export class OvaAccountsClient extends GrpcClient<OvaAccountsPromiseClient> {
  #ovaAccountsClient: OvaAccountsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#ovaAccountsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'OvaAccountsClient'
  }

  protected initClient(hostName = ''): OvaAccountsPromiseClient {
    return new OvaAccountsPromiseClient(hostName, null, null)
  }

  async listOvaAccounts(): Promise<Array<OvaAccount>> {
    const request = new ListOvaAccountsRequest()

    const response = (
      await this.callGrpcService(
        () =>
          this.#ovaAccountsClient.listOvaAccounts(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'OvaAccountsPromiseClient/listOvaAccounts',
        }
      )
    ).toObject()

    return response.ovaAccountsList.map((ovaAccount) =>
      new OvaAccountTransformer(ovaAccount).transform()
    )
  }
}
