import type { ChartWrapperOptions } from 'react-google-charts'

export const TOOLTIP_COLUMN = {
  role: 'tooltip',
  p: { html: true },
}

export const STYLE_COLUMN = {
  role: 'style',
}

export const CHART_COLORS = {
  DEFAULT_STATE: '#32B6F3',
  SELECTED_STATE: '#1C75A1',
}

export const DEFAULT_OPTIONS: ChartWrapperOptions['options'] = {
  legend: 'none',
  backgroundColor: 'white',
  colors: [CHART_COLORS.DEFAULT_STATE],
  tooltip: {
    isHtml: true,
  },
  pointSize: 0,
  crosshair: {
    color: '#EAEFF3',
    orientation: 'vertical',
    trigger: 'focus',
  },
  interpolateNulls: true,
  chartArea: {
    left: 80,
    right: 40,
    bottom: 60,
    top: 40,
  },
  vAxis: {
    format: 'short',
    title: 'Files',
    titleTextStyle: { italic: false },
    gridlines: {
      color: '#EAEFF3;opacity:1',
    },
    // Just to make graph look better
    // If we set 0 - and we get 0 data - we got lower label as '-1'
    // If we set 1 - and we get 0 data - we got lower label as '0' and then step as '0.5'
    // So I guess it's better to set 2
    minValue: 2,
  },
  hAxis: {
    padding: 50,
    gridlines: {
      color: 'transparent',
    },
  },
}
