import type { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'
import { MultiplySelect } from '@features/elements/multiply-select'
import { StrHelper } from '@lib/helpers'
import styles from './threats-filter-item.module.css'

interface Props {
  possibleValues: Array<string>
  selectedValues: Array<string>
  onSelect: (evt: SelectChangeEvent<Array<string>>, key: string) => void
  field: string
}

function ThreatsFilterItem({
  possibleValues,
  selectedValues,
  field,
  onSelect,
}: Props) {
  const labelText = StrHelper.capitalize(field)
  const handleSelectFilter = (evt: SelectChangeEvent<Array<string>>) => {
    onSelect(evt, field)
  }

  if (!possibleValues.length) {
    return null
  }
  return (
    <MultiplySelect
      possible={possibleValues}
      selected={selectedValues}
      onChange={handleSelectFilter}
      placeholder={labelText}
      countLabel={labelText}
      title={labelText}
      fieldName={field}
      className={styles.Select}
    />
  )
}

export default ThreatsFilterItem
