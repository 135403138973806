import DatepickerInput from 'ui-v2/src/components/ui/inputs/datepicker-input'
import RadioGroup from 'ui-v2/src/components/ui/inputs/radio-group'
import TimepickerInput from 'ui-v2/src/components/ui/inputs/timepicker-input'
import TimeFormatConstants from 'ui-v2/src/lib/constants/time-format.constant'

import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import { FormControl, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import dayjs from 'dayjs'
import { Controller, useFormContext } from 'react-hook-form'

const FirstRun = () => {
  const { control, watch } = useFormContext<PolicyFormData>()
  const scheduleData = watch('schedule')
  const firstRunData = scheduleData.firstRun

  const currentActivation = firstRunData.runNow ? 0 : 1
  return (
    <Grid container rowSpacing={2} columnSpacing={4}>
      <Typography variant="body1">Activation Settings</Typography>
      <FormControl fullWidth>
        <Controller
          name="schedule"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              value={currentActivation?.toString()}
              options={[
                {
                  label: 'Activate now',
                  value: 0,
                },
                {
                  label: 'Pause until...',
                  value: 1,
                },
              ]}
              onChange={(e) => {
                field.onChange({
                  ...field.value,
                  firstRun: {
                    ...field.value.firstRun,
                    runNow: +e.target.value === 0,
                  },
                })
              }}
            />
          )}
        />
        <Controller
          name="schedule"
          control={control}
          render={({ field }) => (
            <DatepickerInput
              value={dayjs(field.value.firstRun.runOn.day)}
              onChange={(value) => {
                if (dayjs(value).isValid()) {
                  field.onChange({
                    ...field.value,
                    firstRun: {
                      ...field.value.firstRun,
                      runOn: {
                        ...field.value.firstRun.runOn,
                        day: dayjs(value).valueOf(),
                      },
                    },
                  })
                }
              }}
              disablePast={true}
              disabled={!currentActivation}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <Controller
          control={control}
          name="schedule"
          render={({ field }) => (
            <TimepickerInput
              {...field}
              label="Protection window start"
              value={field.value.firstRun.runOn.time}
              onChange={(time: any) => {
                field.onChange({
                  ...field.value,
                  firstRun: {
                    ...field.value.firstRun,
                    runOn: {
                      ...field.value.firstRun.runOn,
                      time: {
                        ...time,
                        timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
                      },
                    },
                  },
                })
              }}
              disabled={!currentActivation}
            />
          )}
        />
      </FormControl>
    </Grid>
  )
}

export default FirstRun
