import React, { PropsWithChildren, MouseEvent } from 'react'
import { Button } from '@mui/material'
import { ButtonProps } from '@mui/material/Button'
import { ObjHelper } from '@lib/helpers'
import { useLongPress } from './use-long-press'
import { GradientColorsType } from '@lib/interfaces/press-hold-button.interface'

interface PressAndHoldButtonProps extends ButtonProps {
  onHoldComplete: () => void
  gradientColors: GradientColorsType
  holdDuration?: number
}

/**
 * A button that detects a press-and-hold action, triggering a callback upon completion.
 * @param {PressAndHoldButtonProps} props - Component props.
 * @param {Function} props.onHoldComplete - Callback to execute when hold completes.
 * @param {[string, string]} [props.gradientColors] - Gradient colors for progress.
 * @param {number} [props.holdDuration=1000] - Duration (in ms) to detect a hold.
 * @param {React.ReactNode} props.children - Button label or content.
 *
 * @example
 * <PressAndHoldButton
 *   onHoldComplete={handleComplete}
 *   gradientColors={['#ff0000', '#00ff00']}
 * >
 *   Hold Me
 * </PressAndHoldButton>
 */
function PressAndHoldButton({
  onHoldComplete,
  holdDuration,
  gradientColors,
  children,
  ...restProps
}: PropsWithChildren<PressAndHoldButtonProps>) {
  const { onMouseDown, onMouseLeave, onMouseUp, progress, isHolding } =
    useLongPress({
      onHoldComplete,
      holdDuration,
    })

  const buildProgressGradient = (colors: GradientColorsType) => {
    return `linear-gradient(to right, ${colors[0]} ${progress}%, ${colors[1]} 0%)`
  }

  const sx = ObjHelper.mergeObjects(restProps.sx || {}, {
    transition: 'background 0.2s ease',
    background: buildProgressGradient(gradientColors),
  })

  const handleClick = (mouseEvent: MouseEvent) => {
    mouseEvent.preventDefault()
  }

  return (
    <Button
      {...restProps}
      disableRipple
      onPointerDown={onMouseDown}
      onPointerUp={onMouseUp}
      onPointerLeave={onMouseLeave}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      onClick={handleClick}
      sx={sx}
      aria-pressed={isHolding}
    >
      {children}
    </Button>
  )
}

export default PressAndHoldButton
