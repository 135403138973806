import { RetentionPolicyListTableRow } from 'ui-v2/src/lib/models/settings/retention-policy/retention-policy.model'
import { Box, IconButton, Tooltip } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlined from '@mui/icons-material/PauseCircleOutlined'
import { useUpdateStatusRetentionPolicyMutation } from 'ui-v2/src/hooks/queries/catalog/update-status-retention-policy'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from 'ui-v2/src/hooks/toast'

const RetentionPolicyAction = ({ row }: RetentionPolicyActionProps) => {
  const { mutate, isPending } = useUpdateStatusRetentionPolicyMutation()

  const queryClient = useQueryClient()

  const toast = useToast()

  const handleUpdateStatusRetentionPolicy = () => {
    const retentionPolicyList = row.retentionPolicyList
    const retentionPolicy = retentionPolicyList.find(
      (policy) => policy.policyName === row.policyName
    )
    if (!retentionPolicy) {
      return
    }
    mutate(retentionPolicy, {
      onSuccess: () => {
        toast.success('The retention policy status has been updated')
        queryClient.invalidateQueries({
          queryKey: ['catalog', 'retention-policy-list'],
        })
      },
      onError: (error) => {
        toast.error(
          error?.message ?? 'Error during retention policy status updating'
        )
      },
    })
  }
  return (
    <Box textAlign={'right'}>
      {row.isPaused ? (
        <Tooltip title="Pause">
          <IconButton
            onClick={handleUpdateStatusRetentionPolicy}
            disabled={isPending}
          >
            <PauseCircleOutlined />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Resume">
          <IconButton
            onClick={handleUpdateStatusRetentionPolicy}
            disabled={isPending}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
interface RetentionPolicyActionProps {
  row: RetentionPolicyListTableRow
}
export default RetentionPolicyAction
