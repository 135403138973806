import React, { useState } from 'react'
import ValueInterface from '@lib/interfaces/value.interface'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import CheckboxConstant from '@lib/constants/checkbox.constant'
import {
  AssetsEntityType,
  HeadComponentData,
} from '@lib/interfaces/tables/assets-table.interface'
import {
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'

function AssetsVIHead({
  data,
  onSettingsClick,
  onChange,
  columnState,
  forceTemplate,
  skipTemplate,
}: HeadComponentData) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // handlers
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const onSettingChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    onSettingsClick({
      index,
      value: e.target.checked,
      row: data,
    })
  }

  // helping calculations
  const shouldPreventDisabling = columnState.reduce((a, b) => a + b) < 2

  function isMenuDisabled(index: number): boolean {
    return (
      (!!forceTemplate && !!forceTemplate[index]) ||
      (shouldPreventDisabling && !!columnState[index])
    )
  }

  function isMenuChecked(index: number): boolean {
    return (!!forceTemplate && !!forceTemplate[index]) || !!columnState[index]
  }

  return (
    <>
      <tr>
        {data
          .filter(
            (_v, index) =>
              (forceTemplate && forceTemplate[index]) || !!columnState[index]
          )
          .map((h: ValueInterface, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <th key={index}>
              {!h.type && (
                <>
                  {h.name}
                  {!!h.label && <QuestionHint text={h.label} variant="table" />}
                </>
              )}
              {h.type === AssetsEntityType.Checkbox && (
                <V2Checkbox
                  variant="table"
                  indeterminate={h.value === CheckboxConstant.Indeterminate}
                  checked={Boolean(h.value)}
                  onChange={(value) =>
                    onChange({
                      type: AssetsEntityType.Checkbox,
                      index,
                      value: value
                        ? CheckboxConstant.Checked
                        : CheckboxConstant.Empty,
                      row: data,
                    })
                  }
                />
              )}
            </th>
          ))}
        <th className="tableSettingsWrap">
          <div className="iconBtnWrap">
            <IconButton
              size="small"
              className="jsSettingsBtn"
              onClick={handleClick}
            >
              <Icon className="material-icons-outlined">settings_outlined</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {data.map(
                (m, index) =>
                  !(!!skipTemplate && skipTemplate[index]) && (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem
                      className="settingsMenu jsMenuElem"
                      key={`${m.name}${index}`}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={isMenuDisabled(index)}
                            onChange={(e) => onSettingChange(e, index)}
                            color="primary"
                            checked={isMenuChecked(index)}
                          />
                        }
                        label={m.name}
                      />
                    </MenuItem>
                  )
              )}
            </Menu>
          </div>
        </th>
      </tr>
    </>
  )
}

export default AssetsVIHead
