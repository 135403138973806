/* eslint-disable import/no-extraneous-dependencies */
import {
  SnapshotVulnerabilityKind,
  VolumeVulnerabilityKind,
} from 'blues-corejs/dist/models/vulnerability'

export enum ResponsePage {
  EC2INSTANCES = 0,
  EBSVOLUMES = 1,
  S3BUCKETS = 2,
  EFS = 3,
}

export interface AwsThreatStatsResponse {
  malwareThreatsCount: number
  ransomwareThreatsCount: number
  corruptedFilesystemsCount: number
  malwarePageToGo: ResponsePage
  ransomwarePageToGo: ResponsePage
  corruptedFilesystemsPageToGo: ResponsePage
}

export interface AwsThreatExposureStatsResponse {
  totalAssetsCount: number
  assetsWithThreatsCount: number
  regionsWithThreatsCount: number
  accountsWithThreatsCount: number
}

interface ScanCoverage {
  total: number
  covered: number
}

export interface AwsScanCoverageResponse {
  ec2?: ScanCoverage
  ebs?: ScanCoverage
  s3?: ScanCoverage
  efs?: ScanCoverage
}

export interface AwsFileScansStatsResponse {
  infectedFiles: number
  cleanFiles: number
}

interface BackupCoverage {
  total: number
  covered: number
}

export interface AwsBackupCoverageResponse {
  ec2?: BackupCoverage
  ebs?: BackupCoverage
}

export interface AwsSnapshotVulnerability {
  kind: SnapshotVulnerabilityKind
  total: number
}

export interface AwsVolumeVulnerability {
  kind: VolumeVulnerabilityKind
  total: number
}

export interface AwsStorageMisconfigurationsStatsResponse {
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
}

export interface AwsFsChecksStatsResponse {
  corruptedFilesystems: number
  healthyFilesystems: number
}

export enum Percentile {
  PERCENT_20 = 20,
  PERCENT_40 = 40,
  PERCENT_60 = 60,
  PERCENT_80 = 80,
  PERCENT_100 = 100,
}

export interface AWSStatsQuantile {
  age?: number
  percentile: Percentile
}

export interface AwsRpoStats {
  backupsOutOfSchedule: number
  rpoStatsQuantilesList: Array<AWSStatsQuantile>
  lowestRpo?: number
  highestRpo?: number
}

export interface AwsRetentionStats {
  rpoStatsQuantilesList: Array<AWSStatsQuantile>
  averageRetention?: number
  bottomQuartile?: number
  topQuartile?: number
}

export interface AwsRpoAndRetentionStatsResponse {
  rpoStats?: AwsRpoStats
  retentionStats?: AwsRetentionStats
}
