/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackup, OvaServer } from 'blues-corejs/dist'

export { ListOvaServersPagination } from './list-ova-servers-pagination'
export { ListOvaServersFilter } from './list-ova-servers-filter'
export type { ListOvaServersParams } from './list-ova-servers-filter'

export { ListOvaServersClient } from './list-ova-servers'

export interface MaybeInfectedServer {
  hasMalware: boolean
  hasRansomware: boolean
  lastCleanBackup?: OvaBackup
  server: OvaServer
}

export interface ListOvaServersResponse {
  totalServers: number
  totalAccounts: number
  totalProviders: number
  servers: Array<MaybeInfectedServer>
}

export interface GetFiltersForListOvaServersResponse {
  malwareThreatNamesList: Set<string>
  ransomwareThreatNamesList: Set<string>
}
