import { EngineCallback, VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import React from 'react'
import {
  AssetsEntityType,
  OnChangeData,
} from '@lib/interfaces/tables/assets-table.interface'
import { ASSETS_SUB_TABLE_VI_HEAD_FOR_EBS } from '@tables/core/table-constants'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import CheckboxConstant from '@lib/constants/checkbox.constant'
import TableCellTags from '@components-composite/table-cell-tags/TableCellTags'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { Link } from '@lib/router'

interface Props {
  data: VIRow
  index: string
  isFirst: boolean
  isLast: boolean
  onChange?: EngineCallback<OnChangeData>
}

function SubTableVIRowWithHeadersForEBS({
  data,
  index,
  isFirst,
  isLast,
  onChange,
}: Props) {
  return (
    <>
      {isFirst && (
        <>
          <tr>
            <td className="subVIEmptyRowNoBorder" colSpan={11} />
          </tr>
          <tr className="subVIHeader" key={index}>
            {ASSETS_SUB_TABLE_VI_HEAD_FOR_EBS().map(
              (headElem, headerIndex: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <td key={headerIndex}>{headElem.name}</td>
              )
            )}
          </tr>
        </>
      )}

      <tr
        className="rpRow rpSubRow assetSubRow jsAssetSubRowEBS"
        key={Date.now()}
      >
        <td>
          <div className="subBorderBlock" />
        </td>

        {data.map((val: ValueInterface, rowIndex: number) => (
          <React.Fragment key={rowIndex}>
            {/* default - text */}
            {!val.type && <td key={rowIndex}>{val.name}</td>}

            {/* checkbox */}
            {AssetsEntityType.Checkbox === val.type && (
              <td
                className={`assetKind${val.selectedOption?.type}`}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{
                  paddingRight: 0,
                  width: '1px',
                }}
              >
                <V2Checkbox
                  variant="table"
                  checked={!!val.value}
                  disabled={val.selectedOption?.disabled}
                  indeterminate={val.value === CheckboxConstant.Indeterminate}
                  onChange={(value) =>
                    onChange?.({
                      type: AssetsEntityType.Checkbox,
                      index: rowIndex,
                      value: value
                        ? CheckboxConstant.Checked
                        : CheckboxConstant.Empty,
                      row: data,
                    })
                  }
                />
              </td>
            )}

            {/* link */}
            {AssetsEntityType.Link === val.type && (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={rowIndex}
              >
                {val.name ? (
                  <Link to={val.name}>
                    <span className="blueLink">{val.label}</span>
                  </Link>
                ) : (
                  <div>{val.label}</div>
                )}
                <div className="details">{val.value}</div>
              </td>
            )}

            {/* tags */}
            {AssetsEntityType.Tags === val.type && (
              <td key={rowIndex}>
                <TableCellTags
                  tagsMap={val?.tags?.map(([tagKey, tagValue]) => ({
                    name: tagValue ? `${tagKey}=${tagValue}` : tagKey,
                  }))}
                />
              </td>
            )}

            {/* Date */}
            {AssetsEntityType.Date === val.type && (
              <td key={rowIndex}>
                {val.name ? (
                  <DateTimeTooltip
                    placement="top-start"
                    direction="row"
                    date={new Date(val.name)}
                  />
                ) : (
                  'No Backups'
                )}
              </td>
            )}
          </React.Fragment>
        ))}
      </tr>

      {isLast && (
        <tr>
          <td className="subVIEmptyRow" colSpan={11} />
        </tr>
      )}
    </>
  )
}

export default SubTableVIRowWithHeadersForEBS
