/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EBS, EC2, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist'
import { RowTitle } from '../../row-title'

import { computeAssetType } from './compute-asset-type'

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function AssetType({ asset }: { asset: Asset }) {
  const assetType = computeAssetType(asset)

  return (
    <Grid container item xs={4}>
      <Grid item>
        <RowTitle title="Asset Type:" />
      </Grid>
      <Grid item>
        <Typography fontSize="14px" fontWeight="400" variant="caption">
          {assetType}
        </Typography>
      </Grid>
    </Grid>
  )
}
