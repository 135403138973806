import { FormHelperText, MenuProps, styled } from '@mui/material'
import { CSSProperties } from 'react'

export const menuProps: Partial<MenuProps> = {
  autoFocus: false,
  disableScrollLock: true,
  sx: {
    '& li': {
      maxWidth: '600px',
    },
  },
  style: {
    maxHeight: '300px',
    scrollbarWidth: 'thin',
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

export const selectDisplayStyle: CSSProperties = {
  padding: '8px 12px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  border: '1px solid #E3E8ED',
  fontSize: '14px',
}

export const StyledFormHelperText = styled(FormHelperText)({
  fontSize: '14px',
  marginTop: '10px',
  color: 'red',
})
