import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { ErrorLayout } from '@components/components-complex/layouts'
import { NotFound } from '@components/components-complex'
import { publicRoutes } from './public-routes'
import { protectedRoutes } from './protected-routes'
import { PublicRoutes } from './routes/public'
import HomePage from '@features/home-page/home-page'

export default function RoutesRenderer() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      {publicRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
      {protectedRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}
      <Route
        path={PublicRoutes.pageNotFound}
        element={
          <ErrorLayout>
            <NotFound />
          </ErrorLayout>
        }
      />
    </Routes>
  )
}
