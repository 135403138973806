import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { Nullable, VIRow } from '@lib/engine-types'
import React, { memo } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

interface Props {
  tagsMap: Nullable<VIRow>
  variant?: 'default' | 'wrap'
  textLength?: number
}

function TableCellTags({
  tagsMap,
  variant = 'default',
  textLength = 40,
}: Props) {
  const isBigScreen = useMediaQuery('(min-width: 1657px)')

  if (variant === 'wrap') {
    const allTagsName = tagsMap?.map((tag) => tag.name + '\n').join(' ') || ''
    const maxLength = isBigScreen ? 50 : 30
    return (
      <LongTextTooltip
        text={allTagsName}
        maxLength={maxLength}
        customClass="cursor-default"
        customPopperClass="pre-line cursor-default"
      />
    )
  }

  return (
    <div className="wrap-1652943420076">
      {tagsMap?.map((tagElem, index) => (
        <div key={`${tagElem.name}${index}`}>
          <LongTextTooltip
            customClass="cursor-default"
            text={tagElem.name}
            maxLength={textLength}
          />
        </div>
      ))}
    </div>
  )
}

export default memo(TableCellTags)
