import { Stack, Typography } from '@mui/material'

import { useFormContext } from 'react-hook-form'
import {
  AssetVariants,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'

import ProtectionEc2Ebs from './ec2-ebs'
import ProtectionS3 from './s3'
import ProtectionEfs from './efs'

const ProtectionOptions = () => {
  const { watch } = useFormContext<PolicyFormData>()
  const tabAssetTypeSelectedNum = watch('tabAssetTypeSelectedNum')

  const shouldRenderEc2EbsProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.EBS

  const shouldRenderS3BucketProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.S3

  const shouldRenderEfsProtectionOptions =
    tabAssetTypeSelectedNum === AssetVariants.EFS

  return (
    <Stack maxWidth={1120}>
      <Typography
        mb={2.5}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        Configure Recovery Assurance, Recovery and Scan Settings.
      </Typography>

      {shouldRenderEc2EbsProtectionOptions && <ProtectionEc2Ebs />}
      {shouldRenderS3BucketProtectionOptions && <ProtectionS3 />}
      {shouldRenderEfsProtectionOptions && <ProtectionEfs />}
    </Stack>
  )
}

export default ProtectionOptions
