import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import SignedAsHeader from '@components-simple/global-site-components/header/SignedAsHeader'
import { PreloaderBlock } from '@components-simple/preloaders'
import { useFetchTenantsAndCustomerProfile } from '@lib/hooks/api-hooks'
import { useNavigation, Link } from '@lib/router'

const TenantBlock = ({ tenantName }: { tenantName: string }) => {
  return (
    <Link to={`https://${tenantName}/`} passHref key={tenantName}>
      <Box className="tenant-block">
        <Typography component="p" title={tenantName}>
          {tenantName}
        </Typography>
        <Typography component="span" className="open-arrow">
          Open <ArrowRightAltIcon />
        </Typography>
      </Box>
    </Link>
  )
}

function GlobalLoginComplex() {
  const [tenantCreationUrl, setTenantCreateUrl] = useState<string>('')
  const router = useNavigation()

  const {
    tenants,
    customerProfile: customer,
    isLoading,
  } = useFetchTenantsAndCustomerProfile()

  const handleCreateTenant = () => {
    if (!tenantCreationUrl) {
      return
    }
    router.push(tenantCreationUrl)
  }

  async function fetchEnvs() {
    const result = await fetch('api/global/get-settings')

    const {
      createTenantUrl,
    }: {
      createTenantUrl: string
    } = await result.json()

    setTenantCreateUrl(createTenantUrl)
  }

  useEffect(() => {
    fetchEnvs()
  }, [])

  const createTenantBlock = () => {
    if (!tenants.length) {
      return (
        <Box
          className={clsx('create-tenant global-page', {
            disabled: !tenantCreationUrl,
          })}
          role="button"
          onClick={handleCreateTenant}
        >
          Create a tenant
        </Box>
      )
    }
    return (
      <Box className="open-tenant">
        <Typography variant="h1" component="h1">
          Open tenant
        </Typography>

        <Box className="tenants-list">
          {tenants?.map(({ name }) => (
            <TenantBlock tenantName={name} key={name} />
          ))}
        </Box>
      </Box>
    )
  }

  return (
    <Box className="container">
      <Box className="form">
        {customer?.email && <SignedAsHeader email={customer.email} />}
        <Box className="get-started">
          <Typography variant="h1" component="h1">
            Get started with Elastio
          </Typography>
          <Typography component="p">
            Elastio's cyber recovery platform assures safe recovery from risks
            posed by ransomware, malware and application failures.
          </Typography>
        </Box>
        {isLoading ? <PreloaderBlock show /> : createTenantBlock()}
      </Box>
    </Box>
  )
}

export default GlobalLoginComplex
