import {
  BASE_DATE_FORMAT,
  DateRangeSelect,
} from '@components-composite/date-range-select'
import { DateRangeTuple } from '@lib/engine-types'
import { TimeHelper } from '@lib/helpers'
import React from 'react'
import {
  useEntropyFiltersActions,
  useGetEntropyTrendsDetailsStoreFilters,
} from '../entropy-trends-details-store/entropy-trends-details-store.context'
import moment from 'moment'
import styles from './date-range-selection.module.css'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import Stack from '@mui/material/Stack'
import { QuestionHint } from '@components-simple/question-hint'

function convertTimeRangeToTuple(
  startAt: number,
  endAt: number
): DateRangeTuple {
  return [
    TimeHelper.timestampToMoment(startAt),
    TimeHelper.timestampToMoment(endAt),
  ]
}

interface Props {
  disabled: boolean
  showQuestionHint?: boolean
}

export function DateRangeSelection({
  disabled,
  showQuestionHint = true,
}: Props) {
  const { fetchEntropyTrendsHistory } = useEntropyFiltersActions()
  const { startAt, endAt } = useGetEntropyTrendsDetailsStoreFilters()
  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()

  const minMaxRange: DateRangeTuple = [
    moment().tz(timeZone.value).subtract(1, 'month').startOf('month'),
    moment().endOf('day').tz(timeZone.value),
  ]

  const handleDateRangeChange = (newDateRange: DateRangeTuple) => {
    const start = newDateRange[0]
    const end = newDateRange[1]

    if (moment.isMoment(start) && moment.isMoment(end)) {
      fetchEntropyTrendsHistory({
        startAt: start.valueOf(),
        endAt: end.valueOf(),
      })
    }
  }

  const tooltipMessage = disabled
    ? 'You can apply custom date range only if you selected custom date'
    : ''

  return (
    <Stack direction="row" alignItems="flex-start">
      <DateRangeSelect
        className={styles.root}
        value={convertTimeRangeToTuple(startAt, endAt)}
        onChange={handleDateRangeChange}
        startText={BASE_DATE_FORMAT}
        endText={BASE_DATE_FORMAT}
        disabled={disabled}
        label=""
        endLabel=""
        minMaxRange={minMaxRange}
      />
      {showQuestionHint && <QuestionHint text={tooltipMessage} />}
    </Stack>
  )
}
