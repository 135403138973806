import Box from '@mui/material/Box'
import clsx from 'clsx'

import styles from './scale-gradient.module.css'
import { GradientBox } from '@features/DashboardV4/widgets-with-scale'

const BORDER_HIDE_THRESHOLD_PERCENTAGE = 95

function ScaleGradient({ percent }: { percent: number }) {
  const computedStyle = clsx({
    [styles.hideBorder as string]: percent > BORDER_HIDE_THRESHOLD_PERCENTAGE,
  })

  return (
    <GradientBox>
      <Box
        className={`${styles.inner} ${computedStyle}`}
        style={{ bottom: `${percent}%` }}
      />
    </GradientBox>
  )
}

export default ScaleGradient
