/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { CloudConnector } from 'blues-corejs/dist'

interface Props {
  cloudConnector: CloudConnector
}

export function CloudConnectorAccountFilterLabel({ cloudConnector }: Props) {
  if (!cloudConnector.accountAlias) {
    return (
      <Typography variant="caption" fontSize="14px" fontWeight="500">
        {cloudConnector.awsAccountId}
      </Typography>
    )
  }

  return (
    <Stack>
      <Typography
        fontSize="14px"
        variant="caption"
        fontWeight="500"
        lineHeight="20px"
      >
        {cloudConnector.accountAlias}
      </Typography>
      <Typography variant="caption" fontSize="11px">
        {cloudConnector.awsAccountId}
      </Typography>
    </Stack>
  )
}
