import { Option } from 'ui-v2/src/lib/models/reports'
import { MenuItem, Select, Typography } from '@mui/material'

const BasicSelect = ({
  value,
  options,
  size = 'small',
  error,
  placeholder,
  disabled,
  isSelectedObj,
  onChange,
  endAdornment,
}: BasicSelectProps) => {
  return (
    <Select
      size={size}
      value={value}
      onChange={(e) => {
        const selectedValue = e.target.value
        const selectedObj = options.find(
          (option) => option.value === selectedValue
        )
        if (!selectedObj) {
          return
        }
        if (isSelectedObj) {
          onChange(selectedObj)
        } else {
          onChange(selectedObj.value.toString())
        }
      }}
      error={!!error}
      displayEmpty
      renderValue={(selectedValue) => {
        if (!selectedValue) {
          return (
            <Typography
              component="em"
              sx={(theme) => ({
                color: theme.palette.text.disabled,
                fontSize: theme.typography.body2.fontSize,
              })}
            >
              {placeholder}
            </Typography>
          )
        }
        const selectedOption = options.find(
          (option) => option.value === selectedValue
        )
        return selectedOption ? selectedOption.label : selectedValue
      }}
      disabled={!!disabled}
      endAdornment={endAdornment}
      IconComponent={value && endAdornment ? () => null : undefined}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}
interface BasicSelectProps {
  value: string | number
  options: Array<Option>
  size?: 'medium' | 'small'
  error?: boolean
  placeholder?: string
  isSelectedObj?: boolean
  onChange: (value: Option | string) => void
  disabled?: boolean
  endAdornment?: React.ReactElement | null
}
export default BasicSelect
