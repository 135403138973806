/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Stack, Typography } from '@mui/material'
import { OvaBackup } from 'blues-corejs/dist'
import { CopyToClipboardIcon } from '@features/common'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'

// Since `dayjs` does not have a built-in function to format dates as "X days old", "X hours old", etc.
// We should use this function to format the date like on the design
// FIXME: after date behaviour bug fixed, add here the correct implementation with timezones
function pluralize(value: number, unit: string) {
  return `${value} ${unit}${value === 1 ? '' : 's'} old`
}
export function customFormatDate(target: Dayjs) {
  const now = dayjs()

  const diffYears = now.diff(target, 'year')
  const diffMonths = now.diff(target, 'month')
  const diffDays = now.diff(target, 'day')
  const diffHours = now.diff(target, 'hour')
  const diffMinutes = now.diff(target, 'minute')

  if (diffYears >= 1) {
    return pluralize(diffYears, 'year')
  } else if (diffMonths >= 1) {
    return pluralize(diffMonths, 'month')
  } else if (diffDays >= 1) {
    return pluralize(diffDays, 'day')
  } else if (diffHours >= 1) {
    return '< 1 day old'
  } else if (diffMinutes >= 1) {
    return '< 1 hr old'
  } else {
    return '< 1 min old'
  }
}

interface Props {
  backup?: OvaBackup
}

const EMPTY_CELL_PLACEHOLDER = '-'

const EmptyDataCell = () => (
  <Typography fontSize="12px" fontWeight="500">
    {EMPTY_CELL_PLACEHOLDER}
  </Typography>
)

export function LastCleanBackupCell({ backup }: Props) {
  if (!backup) {
    return <EmptyDataCell />
  }

  return (
    <Stack direction="row" justifyContent="right" gap="8px">
      <Typography fontSize="14px" fontWeight="400">
        {customFormatDate(dayjs(backup.timestamp))}
      </Typography>
      <CopyToClipboardIcon fontSize={16} text={backup.backupProviderBackupId} />
    </Stack>
  )
}
