/* eslint-disable import/no-extraneous-dependencies */
import RedStackStatusConstant from 'ui-v2/src/lib/constants/red-stack-status.constant'
import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'
import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'
import { BaseModel, RedStackModel } from 'ui-v2/src/lib/models/base-model'
import {
  RedStack,
  Status as RedStackStatusProto,
} from 'blue-stack-libs/rex-grpc-libs/js/rex/messages/list_redstacks_pb'
import {
  CloudConnector,
  CloudConnectorStatus,
} from 'blues-corejs/dist/models/cloud-connector/index'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export class CloudConnectorTransformer {
  #cloudConnectorProto: RedStack.AsObject

  constructor(cloudConnectorProto: RedStack.AsObject) {
    this.#cloudConnectorProto = cloudConnectorProto
  }

  transform(): CloudConnector {
    return this.#transformCloudConnector(this.#cloudConnectorProto)
  }

  #transformCloudConnector(
    cloudConnectorProto: RedStack.AsObject
  ): CloudConnector {
    return new CloudConnector({
      redStackId: cloudConnectorProto.redStackId,
      awsAccountId: cloudConnectorProto.awsAccount,
      awsRegion: cloudConnectorProto.awsRegion,
      accountAlias: cloudConnectorProto.accountAlias,
      status: this.#transformCloudConnectorStatus(cloudConnectorProto.status),
      lastErrorMessage: cloudConnectorProto.lastErrorMessage,
      iamRole: cloudConnectorProto.iamRole,
      accountDescription: cloudConnectorProto.accountDescription,
      lastWarningMessage: cloudConnectorProto.lastWarningMessage,
      awsAccountCfnVersion: cloudConnectorProto.awsAccountCfnVersion,
      version: cloudConnectorProto.version,
      createdAt: this.#transformTimestamp(cloudConnectorProto.createdAt),
      updatedAt: this.#transformTimestamp(cloudConnectorProto.updatedAt),
      lastIntegrityCheckErrors:
        cloudConnectorProto.lastIntegrityCheckErrorsList,
      tenantName: cloudConnectorProto.tenantName,
      cfnUpgradeRequired: cloudConnectorProto.cfnUpgradeRequired,
    })
  }

  #transformCloudConnectorStatus(
    status: RedStackStatusProto
  ): CloudConnectorStatus {
    const mapping = {
      [RedStackStatusProto.ACTIVE]: CloudConnectorStatus.ACTIVE,
      [RedStackStatusProto.INACTIVE]: CloudConnectorStatus.INACTIVE,
      [RedStackStatusProto.DELETED]: CloudConnectorStatus.DELETED,
      [RedStackStatusProto.UPGRADE_REQUIRED]:
        CloudConnectorStatus.UPGRADE_REQUIRED,
    }

    return mapping[status]
  }

  #transformTimestamp(timestamp?: Timestamp.AsObject): Date | undefined {
    if (!timestamp) {
      return undefined
    }

    return new Date(timestamp.seconds * 1000)
  }
}

export abstract class RedStackFactory {
  public static buildFromScanRedStack(params: any): RedStackModel {
    return new RedStackModel({
      id: numberHash(params.redStackId),
      innerId: params.redStackId,
      redStackId: TypeHelper.stringValDef(params.redStackId, ''),
      accountAlias: TypeHelper.stringValDef(params.accountAlias, ''),
      awsRegion: TypeHelper.stringValDef(params.awsRegion, ''),
      awsAccount: TypeHelper.stringValDef(params.awsAccount, ''),
      iamRole: TypeHelper.stringValDef(params.iamRole, ''),
      accountDescription: TypeHelper.stringValDef(
        params.accountDescription,
        ''
      ),
      lastErrorMessage: TypeHelper.stringValDef(params.lastErrorMessage, ''),
      lastWarningMessage: TypeHelper.stringValDef(
        params.lastWarningMessage,
        ''
      ),
      status: TypeHelper.enumValDef(
        params.status,
        RedStackStatusConstant.UNDEFINED,
        RedStackStatusConstant
      ),
      version: TypeHelper.stringValDef(params.version, ''),
      awsAccountCfnVersion: TypeHelper.stringValDef(
        params.awsAccountCfnVersion,
        ''
      ),
      lastIntegrityCheckErrorsList: params.lastIntegrityCheckErrorsList,
      cfnUpgradeRequired: params.cfnUpgradeRequired,
    })
  }

  public static clone(source: RedStackModel, params: any = {}): RedStackModel {
    return BaseModel.clone(source, params)
  }
}
