/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import {
  AssetItem,
  EFS,
  GenericHost,
  S3Bucket,
  S3Item,
  EfsItem,
} from 'blues-corejs/dist'
import { Table } from '@components/table'
import { getAssetItemScansColumnDefinitions } from './get-asset-item-scans-column-definition'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { AssetItemScansModal } from '@features/asset-page/modals/asset-item-scans-modal'
import { useProcessDataForTable } from './use-process-data-for-table'
import { TimeRangeProvider } from '@components-context/time-range'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

type Asset = S3Bucket | EFS | GenericHost

type Item = AssetItem | S3Item | EfsItem

interface Props {
  asset: Asset
  items: Array<Item>
  isLoading: boolean
  onScrollEnd: () => Promise<void>
  fetchOnRefreshInterval: () => void
}

export function ScanItemsTable({
  asset,
  items,
  onScrollEnd,
  fetchOnRefreshInterval,
  isLoading,
}: Props) {
  const observable = useInfiniteScrollDataFetch({
    initialItems: items,
    fetchNextBatch: onScrollEnd,
  })

  const [selectedAssetItem, setSelectedAssetItem] = React.useState<
    Asset | Item | null
  >(null)

  useEffect(() => {
    const fetchInterval = setInterval(
      fetchOnRefreshInterval,
      INTERVAL_REQUEST_TIMEOUT
    )

    return () => {
      clearInterval(fetchInterval)
    }
  }, [])

  function handleAssetClick(handledAsset: Asset | Item) {
    setSelectedAssetItem(handledAsset)
  }

  const processedData = () => {
    if (isLoading) {
      return
    }

    return asset instanceof GenericHost ? items : [asset, ...items]
  }

  const { data: tableData, isLoading: isProcessingData } =
    useProcessDataForTable(processedData())

  return (
    <>
      {selectedAssetItem && (
        <TimeRangeProvider>
          <AssetItemScansModal
            asset={asset}
            assetItem={selectedAssetItem}
            isOpen={Boolean(selectedAssetItem)}
            onClose={() => setSelectedAssetItem(null)}
          />
        </TimeRangeProvider>
      )}
      <Table
        data={tableData}
        columns={getAssetItemScansColumnDefinitions({
          asset,
          onRowClick: handleAssetClick,
        })}
        generalProps={{
          isLoading: isLoading || isProcessingData,
        }}
        advancedProps={{
          observableState: observable,
        }}
      />
    </>
  )
}
