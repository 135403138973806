import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { OvaStatisticsClient } from '@lib/clients'
import { GetThreatExposureStatisticsResponse } from '@lib/clients/ova-statistics/types'
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'

type TotalThreatsCount = {
  serversWithThreats: number
  serversWithLatestCleanRpRatio: number
}

function transformThreatExposureStatisticsResponse(
  response: GetThreatExposureStatisticsResponse
): TotalThreatsCount {
  return {
    serversWithThreats: response.assetsWithThreatsCount,
    serversWithLatestCleanRpRatio: response.assetsWithCleanRpRatio,
  }
}

const INITIAL_THREATS_COUNT: TotalThreatsCount = {
  serversWithThreats: 0,
  serversWithLatestCleanRpRatio: 0,
}

const ovaStatisticsClient = new OvaStatisticsClient()

export function useFetchOvaThreatExposure() {
  const ovaAccountsList = useGetOvaAccountList()
  const ovaBackupProvidersList = useGetOvaBackupProvidersList()
  const [isLoading, setIsLoading] = useState(false)
  const [threatExposureData, setThreatExposureData] =
    useState<TotalThreatsCount>(INITIAL_THREATS_COUNT)

  useEffect(() => {
    let isMounted = true

    const fetchThreatsExposure = async () => {
      try {
        setIsLoading(true)

        const response = await ovaStatisticsClient.getThreatExposureStatistics({
          ovaAccountIds: ovaAccountsList,
          backupProviders: ovaBackupProvidersList,
        })

        if (isMounted) {
          setThreatExposureData(
            transformThreatExposureStatisticsResponse(response)
          )
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'ovaStatisticsClient.getThreatExposureStatistics: failed to fetch threats exposure'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchThreatsExposure()

    return () => {
      isMounted = false
      setThreatExposureData(INITIAL_THREATS_COUNT)
      setIsLoading(false)
    }
  }, [ovaAccountsList, ovaBackupProvidersList])

  return {
    isLoading,
    serversWithThreats: threatExposureData.serversWithThreats,
    serversWithLatestCleanRpRatio:
      threatExposureData.serversWithLatestCleanRpRatio,
  }
}
