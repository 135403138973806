import { Box, Tab, Tabs } from '@mui/material'
import GeneralTab from './general'
import React, { SyntheticEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SETTINGS_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import Webhooks from './webhooks'
import Sources from './sources'
import Policies from './policies'
import Users from './user-access/users'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const TAB_MAP = {
  general: {
    label: 'General',
    component: <GeneralTab />,
    slug: SETTINGS_ROUTE_SEGMENTS.GENERAL,
  },
  users: {
    label: 'Users',
    component: <Users />,
    slug: SETTINGS_ROUTE_SEGMENTS.USERS_AND_ACCESS,
  },
  webhooks: {
    label: 'Webhooks',
    component: <Webhooks />,
    slug: SETTINGS_ROUTE_SEGMENTS.WEBHOOKS,
  },
  sources: {
    label: 'Deployments',
    component: <Sources />,
    slug: SETTINGS_ROUTE_SEGMENTS.SOURCES,
  },
  policies: {
    label: 'Policies',
    component: <Policies />,
    slug: SETTINGS_ROUTE_SEGMENTS.POLICIES,
  },
}

type SettingsProps = {
  tab: (typeof SETTINGS_ROUTE_SEGMENTS)[keyof typeof SETTINGS_ROUTE_SEGMENTS]
}

function Settings({ tab }: SettingsProps) {
  const navigate = useNavigate()

  const defaultPath = `/settings/${SETTINGS_ROUTE_SEGMENTS.GENERAL}`

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(TAB_MAP).some((t) => t.slug === tab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [tab])

  if (!tab || !Object.values(TAB_MAP).some((t) => t.slug === tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: (typeof SETTINGS_ROUTE_SEGMENTS)[keyof typeof SETTINGS_ROUTE_SEGMENTS]
  ) => {
    navigate(`/settings/${newSlug}`)
  }
  return (
    <Box sx={{ width: '100%' }} px={1.5}>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: 'divider',
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          sx={(theme) => ({
            '& .MuiTab-root.Mui-selected': {
              color: theme.palette.text.primary,
            },
          })}
        >
          {Object.values(TAB_MAP).map((tabItem) => (
            <Tab
              key={tabItem.slug}
              value={tabItem.slug}
              label={tabItem.label}
              sx={{ textTransform: 'capitalize' }}
              {...a11yProps(tabItem.slug)}
            />
          ))}
        </Tabs>
      </Box>
      {Object.entries(TAB_MAP).map(([key, value]) => (
        <TabPanel key={key} value={tab} slug={value.slug}>
          {value.component}
        </TabPanel>
      ))}
    </Box>
  )
}

export default Settings
