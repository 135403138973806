import React from 'react'

function DeleteOutlineIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00004 13.1667C4.00004 13.9 4.60004 14.5 5.33337 14.5H10.6667C11.4 14.5 12 13.9 12 13.1667V6.5C12 5.76667 11.4 5.16667 10.6667 5.16667H5.33337C4.60004 5.16667 4.00004 5.76667 4.00004 6.5V13.1667ZM6.00004 6.5H10C10.3667 6.5 10.6667 6.8 10.6667 7.16667V12.5C10.6667 12.8667 10.3667 13.1667 10 13.1667H6.00004C5.63337 13.1667 5.33337 12.8667 5.33337 12.5V7.16667C5.33337 6.8 5.63337 6.5 6.00004 6.5ZM10.3334 3.16667L9.86004 2.69333C9.74004 2.57333 9.56671 2.5 9.39337 2.5H6.60671C6.43337 2.5 6.26004 2.57333 6.14004 2.69333L5.66671 3.16667H4.00004C3.63337 3.16667 3.33337 3.46667 3.33337 3.83333C3.33337 4.2 3.63337 4.5 4.00004 4.5H12C12.3667 4.5 12.6667 4.2 12.6667 3.83333C12.6667 3.46667 12.3667 3.16667 12 3.16667H10.3334Z"
        fill="#ED3F3F"
      />
    </svg>
  )
}

export default DeleteOutlineIcon
