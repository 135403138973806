import { Moment } from 'moment/moment'

export enum RepeatPeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum ReportEnd {
  NEVER = 'never',
  ON = 'on',
  AFTER = 'after',
}

export enum Days {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export interface CustomScheduleState {
  everyValue: number
  periodValue: RepeatPeriod
  daysValue: string | null
  monthsValue: string
  endValue: ReportEnd
  dateValue: Moment | null
  occurrencesValue: number | null
}
