import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { REPORT_DEFAULT_POSSIBLE_PERIODS } from 'ui-v2/src/lib/constants/report-date-range.constant'
import { Option } from 'ui-v2/src/lib/models/reports'

const ReportPeriod = ({
  value,
  error,
  isSelectedObj,
  onChange,
}: ReportPeriodProps) => {
  const possiblePeriod = REPORT_DEFAULT_POSSIBLE_PERIODS.map(
    ({ name, value: val }) => ({
      label: name,
      value: val.toString(),
    })
  )

  return (
    <BasicSelect
      value={value}
      options={possiblePeriod}
      onChange={onChange}
      error={error}
      isSelectedObj={isSelectedObj}
    />
  )
}
interface ReportPeriodProps {
  value: string | number
  error?: boolean
  isSelectedObj?: boolean
  onChange: (value: Option | string) => void
}
export default ReportPeriod
