import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface FilterComponentProps {
  options: Array<string>
}

const InstanceIdFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsInstanceList =
    searchParams
      .get(ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_ID)
      ?.split(',') || []

  const awsInstanceOptions = useMemo(() => {
    return options.map((awsInstanceOption) => ({
      value: awsInstanceOption,
      label: awsInstanceOption,
    }))
  }, [options])

  const handleAwsInstanceChange = (
    newAwsInstanceList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsInstanceList.length === 0) {
      searchParams.delete(ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_ID)
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_ID,
        newAwsInstanceList.join(',')
      )
    }
    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsInstanceOptions}
      value={awsInstanceList}
      onChange={handleAwsInstanceChange}
      label="Instance ID"
      width={200}
    />
  )
}

export default InstanceIdFilter
