import { VIRowReadonly } from '../engine-types'

export enum BackupType {
  BACKUP_TYPE_EC2 = 0,
  BACKUP_TYPE_EBS = 1,
  BACKUP_TYPE_BLOCK = 2,
  BACKUP_TYPE_FILE = 3,
  BACKUP_TYPE_STREAM = 4,
  BACKUP_TYPE_NAS = 5,
  BACKUP_TYPE_S3 = 6,
}

export function getBackupTypeName(source: BackupType): string {
  switch (source) {
    case BackupType.BACKUP_TYPE_EC2:
      return 'EC2'

    case BackupType.BACKUP_TYPE_EBS:
      return 'EBS'

    case BackupType.BACKUP_TYPE_BLOCK:
      return 'Block'

    case BackupType.BACKUP_TYPE_FILE:
      return 'File'

    case BackupType.BACKUP_TYPE_STREAM:
      return 'Stream'

    case BackupType.BACKUP_TYPE_NAS:
      return 'NAS'

    case BackupType.BACKUP_TYPE_S3:
      return 'S3'

    default:
      return ''
  }
}

export function getBackupTypeList(): VIRowReadonly {
  const result = [
    {
      name: getBackupTypeName(BackupType.BACKUP_TYPE_EC2),
      value: BackupType.BACKUP_TYPE_EC2,
    },
    {
      name: getBackupTypeName(BackupType.BACKUP_TYPE_EBS),
      value: BackupType.BACKUP_TYPE_EBS,
    },
    {
      name: getBackupTypeName(BackupType.BACKUP_TYPE_BLOCK),
      value: BackupType.BACKUP_TYPE_BLOCK,
    },
    {
      name: getBackupTypeName(BackupType.BACKUP_TYPE_FILE),
      value: BackupType.BACKUP_TYPE_FILE,
    },
    {
      name: getBackupTypeName(BackupType.BACKUP_TYPE_STREAM),
      value: BackupType.BACKUP_TYPE_STREAM,
    },
    {
      name: getBackupTypeName(BackupType.BACKUP_TYPE_S3),
      value: BackupType.BACKUP_TYPE_S3,
    },
  ]

  return result
}
