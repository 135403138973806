import { ListOvaServersRequestPb, Pagination } from '@lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListOvaServersPagination extends AbstractListRequest<ListOvaServersRequestPb> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): ListOvaServersRequestPb {
    const grpcRequest = new ListOvaServersRequestPb()

    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
