/* eslint-disable import/no-extraneous-dependencies */
import {
  ColumnWidth,
  createColumn,
  createDisplayColumn,
  RowHeight,
} from '@components/table'
import { ScanStatus } from '@features/DashboardV4/common'
import { ThreatIconsBlock } from '@features/DashboardV4/common/threat-icon-group'
import {
  AssetItem,
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  Stream,
} from 'blues-corejs/dist'
import React from 'react'
import { RecoveryPointCompletionTimeCell } from './completion-time-cell'
import { AssetItemRecoveryPointStatusCell } from './recovery-point-status-cell'
import { RecoveryPointThreatsCell } from './recovery-point-threats-cell'

type Backup = ElastioRecoveryPoint | AWSBRecoveryPoint | EBSSnapshot

const EMPTY_THREATS_VALUE = '-'
const TIME_COLUMN_WIDTH = '120'

export function getAssetItemRecoveryPointColumnDefinitions({
  onCompletionTimeClick,
  assetItem,
}: {
  onCompletionTimeClick: (backup: Backup) => void
  assetItem: AssetItem
}) {
  const isStreamAssetItem = assetItem instanceof Stream

  const shouldHideRisksColumn = isStreamAssetItem

  const completionTimeColumnWidth = shouldHideRisksColumn
    ? 'auto'
    : TIME_COLUMN_WIDTH

  return [
    createDisplayColumn<Backup>({
      id: 'status',
      enableSorting: false,
      meta: {
        width: '40',
        height: RowHeight.SMALL,
      },
      cell: ({ row }) => (
        <AssetItemRecoveryPointStatusCell backup={row.original} />
      ),
    }),
    createDisplayColumn<Backup>({
      enableSorting: false,
      header: 'Risks',
      meta: {
        width: '100',
        height: RowHeight.SMALL,
        hidden: shouldHideRisksColumn,
      },
      cell: ({ row }) => {
        const shouldRenderFsCheckStatus = row.original.fsCheckStatus

        if (shouldRenderFsCheckStatus) {
          return (
            <ThreatIconsBlock
              fsCheckStatus={
                row.original.fsCheckStatus as unknown as ScanStatus
              }
            />
          )
        }

        return (
          <ThreatIconsBlock
            ransomwareStatus={
              row.original.ransomwareCheckStatus as unknown as ScanStatus
            }
            malwareStatus={
              row.original.malwareCheckStatus as unknown as ScanStatus
            }
          />
        )
      },
    }),
    createColumn<Backup>('timestamp', {
      header: 'Completion Time',
      enableSorting: false,
      meta: {
        width: completionTimeColumnWidth,
      },
      cell: ({ getValue, row }) => {
        const onTimestampClick = () => {
          onCompletionTimeClick(row.original)
        }

        const timestamp = getValue() as unknown as Date

        return (
          <RecoveryPointCompletionTimeCell
            timestamp={timestamp}
            onTimestampClick={onTimestampClick}
          />
        )
      },
    }),
    createColumn<Backup>('threatIds', {
      header: 'Threats',
      enableSorting: false,
      meta: {
        size: ColumnWidth.MEDIUM,
        hidden: shouldHideRisksColumn,
      },
      cell: ({ getValue }) => {
        const threatsIds = getValue() as unknown as Array<string>

        if (!threatsIds.length) {
          return EMPTY_THREATS_VALUE
        }

        return <RecoveryPointThreatsCell threatsIds={threatsIds} />
      },
    }),
  ]
}
