import React from 'react'
import {
  NavigateFunction,
  Location,
  Link as RouterLink,
} from 'react-router-dom'
import { ParsedUrlQuery } from 'querystring'
import { NavigationStrategy } from '../types'
import type { NavigateOptions } from 'react-router/dist/lib/context'

interface SpaNavigationStrategyAttrs {
  navigate: NavigateFunction
  location: Location
  searchParams: URLSearchParams
}

export class SpaNavigationStrategy implements NavigationStrategy {
  #navigate: NavigateFunction

  #location: Location

  #searchParams: URLSearchParams

  constructor(attrs: SpaNavigationStrategyAttrs) {
    const { navigate, location, searchParams } = attrs

    if (!navigate || !location) {
      throw new Error(
        'NavigateFunction, Location are required for SPA navigation.'
      )
    }

    this.#navigate = navigate
    this.#location = location
    this.#searchParams = searchParams
  }

  push(path: string, navigateOptions?: NavigateOptions): void {
    // TODO: ask author about this
    if (this.#location.pathname !== path) {
      this.#navigate(path, navigateOptions)
    }
  }

  updatePath({ location, searchParams, navigate }: any) {
    this.#navigate = navigate
    this.#location = location
    this.#searchParams = searchParams
  }

  public get query(): ParsedUrlQuery {
    const searchParams = new URLSearchParams(this.#location.search)
    const queryObject: Record<string, string> = {}
    for (const [key, value] of searchParams.entries()) {
      queryObject[key] = value
    }
    return queryObject
  }

  get currentPath(): string {
    return this.#location.pathname
  }

  createLinkComponent(): React.ComponentType<any> {
    return ({ to, children, ...restProps }) => (
      <RouterLink to={to} {...restProps}>
        {children}
      </RouterLink>
    )
  }

  back(): void {
    return this.#navigate(-1)
  }

  getQueryParam(key: string): string | undefined | null {
    return this.#searchParams.get(key)
  }

  replace(url: string, options?: any): void {
    return this.#navigate(url, {
      ...options,
      replace: true,
    })
  }
}
