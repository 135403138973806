import React from 'react'

function EditWhiteIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4417_426)">
        <path
          d="M7.375 2.5H3C2.66848 2.5 2.35054 2.6317 2.11612 2.86612C1.8817 3.10054 1.75 3.41848 1.75 3.75V12.5C1.75 12.8315 1.8817 13.1495 2.11612 13.3839C2.35054 13.6183 2.66848 13.75 3 13.75H11.75C12.0815 13.75 12.3995 13.6183 12.6339 13.3839C12.8683 13.1495 13 12.8315 13 12.5V8.125"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.0625 1.56252C12.3111 1.31388 12.6484 1.17419 13 1.17419C13.3516 1.17419 13.6889 1.31388 13.9375 1.56252C14.1861 1.81116 14.3258 2.14839 14.3258 2.50002C14.3258 2.85165 14.1861 3.18888 13.9375 3.43752L8 9.37502L5.5 10L6.125 7.50002L12.0625 1.56252Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4417_426">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditWhiteIcon
