import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

export enum Tabs {
  INSTANCES = 0,
  VOLUMES = 1,
  S3 = 2,
  EFS = 3,
  OVA_SERVERS = 4,
}

interface TabsStoreState {
  activeTab: Tabs
}

interface TabsStoreActions {
  setActiveTab: (tab: Tabs) => void
  setServersTabActive: () => void
}

const INITIAL_STATE: TabsStoreState = {
  activeTab: Tabs.INSTANCES,
}

const useTabsStore = create<TabsStoreState & { actions: TabsStoreActions }>(
  (set) => ({
    ...INITIAL_STATE,
    actions: {
      setActiveTab: (activeTab: Tabs) => set({ activeTab }),
      setServersTabActive: () => set({ activeTab: Tabs.OVA_SERVERS }),
    },
  })
)

const useGetActiveTab = () => useTabsStore((state) => state.activeTab)

const useTabsStoreActions = () =>
  useTabsStore(useShallow((state) => state.actions))

export { useGetActiveTab, useTabsStoreActions }
