import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const StyledThreatRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '32px',
  gap: '10px',
  '.MuiTypography-root': {
    lineHeight: '180%',
  },
})
