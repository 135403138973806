import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  EngineCallback,
  EngineCallbackDouble,
  ValueObject,
} from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormAccountIdInterface from '@lib/interfaces/form/form-account-id.interface'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import CustomTextField from '@forms/fields/custom-text-field'
import CustomTextFieldV2 from '@forms/fields/custom-text-field-v2'
import accountIdValidator from '@forms/validators/account-id.validator'
import buildDefaultFormData from './data'
import ProtectionIcon from '@inline-img/general/protection-icon'
import DataHelper from '@lib/helpers/data.helper'

interface Props {
  defaultValues?: ValueObject<FormAccountIdInterface>
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
  onSubmit: EngineCallback<FormAccountIdInterface>
  onAccountIdChange?: EngineCallbackDouble<string, boolean>
  blockIamRoleShowing?: boolean
  isDisabled?: boolean
  // We need that props in case prefilled AWS ID for deployment
  isPrefilled?: boolean
}

function AccountIdForm({
  defaultValues,
  loading,
  errorGroups,
  onSubmit,
  onAccountIdChange,
  blockIamRoleShowing = false,
  isDisabled,
  isPrefilled = false,
}: Props) {
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )

  const {
    isFormDisabled,
    register,
    submit,
    globalFormError,
    setGlobalFormError,
    formState,
    watch,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading)

  const [isShowRoleField, setIsShowRoleField] = useState(false)
  useEffect(() => {
    if (globalFormError && !blockIamRoleShowing) {
      setIsShowRoleField(true)
    }
  }, [globalFormError])

  const [previousEmittedAccountId, setPreviousEmittedAccountId] =
    useState<string>('')

  const accountIdCurrentValue = watch('accountId')

  useEffect(() => {
    // We only allow the user to enter digitals and a character "-"
    // and the rest of the characters in the form are not displayed
    if (!isFormDisabled) {
      currentForm.accountId.value = DataHelper.accountIdFilter(
        accountIdCurrentValue
      )
    }
    if (
      !onAccountIdChange ||
      accountIdCurrentValue === undefined ||
      accountIdCurrentValue === previousEmittedAccountId
    ) {
      return
    }
    // rules for forms applies after submitting, so we can not use just
    //     currentForm.accountId.hasError
    // we should check it directly in order to emit asap

    // Only digits should be sent to the backend.
    // for this reason, we format the account ID.
    const accountIdServerFormat = DataHelper.accountIdToServerFormat(
      accountIdCurrentValue
    )
    // We need to validate the value that is sent to the server
    const errorMessages = accountIdValidator(accountIdServerFormat)
    onAccountIdChange(accountIdServerFormat, errorMessages === true)
    setPreviousEmittedAccountId(accountIdCurrentValue)
    // by default all errors are reset after submitting
    // but in this case we need to reset them asap
    setGlobalFormError(errorMessages === true ? '' : errorMessages)
  }, [accountIdCurrentValue])

  FormHelper.fillErrors(formState.errors, currentForm)
  return (
    <form
      id="accountIdFormId"
      className={clsx('wrap-1615467320315 jsAccountIdForm', {
        formDisabled: isFormDisabled,
      })}
      onSubmit={submit}
    >
      <input
        type="hidden"
        {...register(
          currentForm.accountId.name,
          currentForm.accountId.validationRules
        )}
        name={isPrefilled ? currentForm.accountId.name : ''}
        value={String(currentForm.accountId.value)}
      />
      <div className="v2StaticTextInput flexRowJustifyStart">
        {isPrefilled ? (
          <CustomTextFieldV2
            field={currentForm.accountId}
            disabled={isDisabled || isFormDisabled}
          />
        ) : (
          <CustomTextFieldV2
            field={currentForm.accountId}
            disabled={isDisabled || isFormDisabled}
            {...register(
              currentForm.accountId.name,
              currentForm.accountId.validationRules
            )}
          />
        )}
        <span className="ml16">
          <ProtectionIcon />
        </span>
      </div>
      <div className="mt16">
        <a
          href="https://support.elastio.com/hc/en-us/articles/22834016672914-Elastio-Architecture"
          rel="noreferrer"
          target="_blank"
          className="buttonLikeLink"
        >
          Why are we asking for your account number?
        </a>
      </div>

      <div
        className={clsx('newFormRow v2StaticTextInput', {
          dNone: !isShowRoleField,
        })}
      >
        <CustomTextField
          field={currentForm.iamRole}
          disabled={isFormDisabled}
          {...register(
            currentForm.iamRole.name,
            currentForm.iamRole.validationRules
          )}
        />
      </div>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}
    </form>
  )
}

export default memo(AccountIdForm)
