import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { WebhookEditRoutes } from './webhook-edit'
import WebhookEditPage from '@features/webhook-edit-page/webhook-edit-page'

export const webhookEditRoutes: Array<PathRouteProps> = [
  {
    path: WebhookEditRoutes.hookIdPlaceholder,
    element: (
      <MainTopBarLayout title="Asset">
        <WebhookEditPage />
      </MainTopBarLayout>
    ),
  },
]
