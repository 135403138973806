import { useState, useEffect } from 'react'
import {
  DateRangeTuple,
  EngineCallback,
  Nullable,
} from 'ui-v2/src/lib/engine-types'
import dayjs, { Dayjs } from 'dayjs'
import { FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import DatepickerInput from './datepicker-input'

const DateRangeSelect = ({
  value,
  label,
  helperText,
  error,
  onChange,
}: DateRangeSelectProps) => {
  const [selectedStartDate, setSelectedStartDate] = useState<
    Nullable<number | dayjs.Dayjs>
  >(value[0])
  const [selectedEndDate, setSelectedEndDate] = useState<
    Nullable<number | dayjs.Dayjs>
  >(value[1])

  useEffect(() => {
    setSelectedStartDate(value[0])
    setSelectedEndDate(value[1])
  }, [value])

  const handleOnChange = (
    newStartDate: Nullable<number | Dayjs>,
    newEndDate: Nullable<number | Dayjs>
  ) => {
    const startDate = newStartDate ? dayjs(newStartDate).startOf('day') : null
    const endDate = newEndDate ? dayjs(newEndDate).endOf('day') : null

    onChange([startDate, endDate])
  }

  const handleStartDateChange = (newDate: Nullable<Dayjs>) => {
    setSelectedStartDate(newDate)
    handleOnChange(newDate, selectedEndDate)
  }

  const handleEndDateChange = (newDate: Nullable<Dayjs>) => {
    if (dayjs(newDate).isBefore(selectedStartDate)) {
      setSelectedStartDate(newDate)
      setSelectedEndDate(selectedEndDate)
      handleOnChange(newDate, selectedEndDate)
    } else {
      setSelectedEndDate(newDate)
      handleOnChange(selectedStartDate, newDate)
    }
  }

  return (
    <FormControl error={error}>
      {!!label && (
        <Typography variant="body1" mb={1}>
          {label}
        </Typography>
      )}
      <Stack direction={'row'} spacing={2}>
        <FormControl fullWidth>
          <DatepickerInput
            value={dayjs(selectedStartDate)}
            onChange={handleStartDateChange}
            maxDate={selectedEndDate ? dayjs(selectedEndDate) : dayjs()}
          />
        </FormControl>
        <FormControl fullWidth>
          <DatepickerInput
            value={dayjs(selectedEndDate)}
            onChange={handleEndDateChange}
            maxDate={dayjs()}
          />
        </FormControl>
      </Stack>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
interface DateRangeSelectProps {
  value: DateRangeTuple
  label?: string
  helperText?: string
  error?: boolean
  onChange: EngineCallback<DateRangeTuple>
}
export default DateRangeSelect
