/* eslint-disable import/no-extraneous-dependencies */
import { ElastioRecoveryPoint } from 'blues-corejs/dist'
import { StatusDeletion } from 'blues-corejs/dist/models/backups/aws/elastio/types'
import React from 'react'
import { SoftDeletedState } from '@features/asset-page/modals/recovery-point-details/components'
import { HardDeletedState } from '../../components/hard-deleted-state/hard-deleted-state'

export function computeElastioRecoveryPointState(backup: ElastioRecoveryPoint) {
  if (backup.deletionStatus === StatusDeletion.SOFTDELETE) {
    return <SoftDeletedState />
  }

  if (backup.deletionStatus === StatusDeletion.HARDDELETE) {
    return <HardDeletedState />
  }

  return null
}
