"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsVolumesWithThreats = void 0;
const types_1 = require("../../models/threats/types");
__exportStar(require("./types"), exports);
class ListDetailsVolumesWithThreats {
    volumes;
    threats;
    constructor(volumes, threats) {
        this.volumes = volumes;
        this.threats = threats;
    }
    execute(filters) {
        return this.prepareOutput(this.findVolumeMatchingFilters(filters));
    }
    prepareOutput(volumes) {
        const volumesOutput = volumes.map((volume) => {
            const threatsForVolume = this.getThreatsForVolume(volume.id);
            return {
                volumeId: volume.id,
                volumeAwsId: volume.awsId,
                volumeName: volume.name,
                firstDetectedAt: this.getFirstDetectedAtForVolume(volume.id),
                threats: threatsForVolume,
                state: volume.state,
            };
        });
        const possibleFilters = this.getVolumePossibleFilters();
        return {
            volumesData: volumesOutput,
            possibleFilters: possibleFilters,
        };
    }
    getFirstDetectedAtForVolume(volumeId) {
        const threatsForVolume = this.getThreatsForVolume(volumeId);
        if (threatsForVolume.length === 0) {
            return null;
        }
        const firstDetectedAt = Math.min(...threatsForVolume.map(({ firstDetectedAt }) => firstDetectedAt.getTime()));
        return new Date(firstDetectedAt);
    }
    getThreatsForVolume(volumeId) {
        return this.threats.filter((threat) => {
            return (threat.source.asset?.assetId === volumeId ||
                threat.source.assetItem?.assetItemId === volumeId);
        });
    }
    getVolumePossibleFilters() {
        const ransomware = new Set();
        const malware = new Set();
        this.volumes.forEach((volume) => {
            const threatsForVolume = this.getThreatsForVolume(volume.id);
            threatsForVolume.forEach((threat) => {
                if (threat.kind === types_1.ThreatKind.RANSOMWARE) {
                    ransomware.add(threat.name);
                }
                if (threat.kind === types_1.ThreatKind.MALWARE) {
                    malware.add(threat.name);
                }
            });
        });
        return {
            ransomware: [...ransomware],
            malware: [...malware],
        };
    }
    findVolumeMatchingFilters(filters) {
        return this.volumes.filter((volume) => {
            const threatsForVolume = this.getThreatsForVolume(volume.id);
            if (filters?.ransomware &&
                !threatsForVolume.some((threat) => filters.ransomware?.includes(threat.name))) {
                return false;
            }
            if (filters?.malware &&
                !threatsForVolume.some((threat) => filters.malware?.includes(threat.name))) {
                return false;
            }
            return true;
        });
    }
}
exports.ListDetailsVolumesWithThreats = ListDetailsVolumesWithThreats;
