/* eslint-disable import/no-extraneous-dependencies */
import { TimeFormatConstants } from '@lib/constants'
import { StrHelper } from '@lib/helpers'
import LangHelper from '@lib/helpers/lang.helper'
import { RiskLevel } from 'blues-corejs/dist'
import type { InstanceOutput } from 'blues-corejs/dist/use_cases/list_volumes_vulnerabilities'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function buildInstanceName(instance: InstanceOutput) {
  return instance.instanceName
    ? `${instance.instanceName} (${instance.instanceAwsId})`
    : instance.instanceAwsId
}

function prepareEc2DataForCsv(instanceList: Array<InstanceOutput>) {
  return instanceList.map((instance) => ({
    instance: buildInstanceName(instance),
    misconfiguration: instance.volumeVulnerabilities.join(', '),
    risk: RiskLevel[Number(instance.riskVulnerabilities)],
    // To prevent converting into number column
    accountId: `${instance.accountId}‎`,
    region: LangHelper.getAwsRegionSingleTranslation(instance.region),
    osType: instance.osKind,
    attachedVolumes: instance.attachedVolumesCount.toString(),
    status: StrHelper.getEc2StateName(instance.instanceState),
    lastBackup: instance.lastBackup?.backup
      ? `${dayjs
          .utc(instance.lastBackup.backup.timestamp)
          .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
      : '',
  }))
}

const EC2_CSV_TABLE_HEADERS = [
  {
    label: 'Instance',
    key: 'instance',
  },
  {
    label: 'Misconfiguration',
    key: 'misconfiguration',
  },
  {
    label: 'Risk',
    key: 'risk',
  },
  {
    label: 'Account ID',
    key: 'accountId',
  },
  {
    label: 'Region',
    key: 'region',
  },
  {
    label: 'OS Type',
    key: 'osType',
  },
  {
    label: 'Attached Volumes',
    key: 'attachedVolumes',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Last Backup',
    key: 'lastBackup',
  },
]

export { prepareEc2DataForCsv, EC2_CSV_TABLE_HEADERS }
