import { BaseValidator } from './base.validator'

export class TagKeyValidator extends BaseValidator {
  /**
   * Validates the tag key.
   * Tag keys can have a maximum character length of 128 characters.
   * @returns {boolean}
   */
  validate(): boolean {
    return this.value.length <= 128
  }

  get errorMessage(): string {
    return 'Tag key can have a maximum character length of 128 characters.'
  }
}

export class TagValueValidator extends BaseValidator {
  /**
   * Validates the tag value.
   * Tag values can have a maximum character length of 256 characters.
   * @returns {boolean}
   */
  validate(): boolean {
    return this.value.length <= 256
  }

  get errorMessage(): string {
    return 'Tag value can have a maximum character length of 256 characters.'
  }
}
