import React, { memo, ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
}

function ComingSoonWrap({ children, className = '' }: Props) {
  return (
    <span className={`wrap-1642698221683 ${className}`}>
      <div className="overlay">Coming Soon</div>
      {children}
    </span>
  )
}

export default memo(ComingSoonWrap)
