import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'

interface ControlledInputOwnProps {
  name: string
  placeholder: string
}

function ControlledInput({ name, placeholder }: ControlledInputOwnProps) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl variant="outlined" fullWidth>
          <OutlinedInput
            {...field}
            fullWidth
            name={name}
            size="small"
            margin="dense"
            placeholder={placeholder}
          />
        </FormControl>
      )}
    />
  )
}

export default ControlledInput
