import React from 'react'
import Typography from '@mui/material/Typography'

function GroupTitle({ title }: { title: string }) {
  return (
    <Typography
      variant="h6"
      gutterBottom
      sx={{
        fontWeight: 700,
        fontSize: '16px',
        color: '#727883',
      }}
    >
      {title}
    </Typography>
  )
}

export default GroupTitle
