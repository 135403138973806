import React from 'react'
import Checkbox from '@mui/material/Checkbox'

import { CheckboxTableOwnProps } from './types'

function CheckboxTable({
  indeterminate = false,
  className = '',
  ...restProps
}: CheckboxTableOwnProps) {
  return (
    <Checkbox
      size="small"
      indeterminate={indeterminate}
      className={className + ' cursor-pointer'}
      {...restProps}
    />
  )
}

export default CheckboxTable
