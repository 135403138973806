import { useRef, useState } from 'react'
import {
  ListBackupsForAssetItem as ListBackupsForAssetItemType,
  ListBackupsForAssetItemRequest,
  ListBackupsForAssetItemsFiltersParams,
} from '@lib/clients/backups/list-backups-for-asset-items/types'
import { usePagination } from '@lib/hooks'
import {
  ListBackupsForAssetItemsClient,
  ListBackupsForAssetItemsFilter,
} from '@lib/clients/backups/list-backups-for-asset-items'
import { ListBackupsForAssetItemsPagination } from '@lib/clients/backups/list-backups-for-asset-items/list-backups-for-asset-items-pagination'
import { useFetchStatus } from '@lib/hooks/api-hooks'
import { UseAssetItemsBackupsDataFetcher } from 'components/components-complex/dashboard-pages-v3/hooks/types'
import { ListByAssetItemsRequest } from '@lib/clients/backups/types'
import { ObjHelper } from '@lib/helpers'

const listBackupsForAssetItemsClient = new ListBackupsForAssetItemsClient()

interface ExtendedUseAssetItemsBackupsDataFetcher
  extends UseAssetItemsBackupsDataFetcher {
  fetchInitial: (filter?: Partial<ListByAssetItemsRequest>) => Promise<void>
}

export const INITIAL_STATE: ListBackupsForAssetItemType = {
  awsbRpsList: [],
  elastioRpsList: [],
  ebsSnapshotsList: [],
}

async function fetchInitialBackupsList(
  combinedFilters: ListBackupsForAssetItemsFiltersParams
) {
  return listBackupsForAssetItemsClient.list(
    new ListBackupsForAssetItemsFilter(combinedFilters)
  )
}

async function fetchBackupsListViaPagination(nextPageToken: string) {
  return listBackupsForAssetItemsClient.list(
    new ListBackupsForAssetItemsPagination(nextPageToken)
  )
}

export function useListBackupsForAssetItemDataFetcher(
  initialFilters?: ListBackupsForAssetItemRequest
): ExtendedUseAssetItemsBackupsDataFetcher {
  const fetchStatus = useFetchStatus()
  const [backupsData, setBackupsData] =
    useState<ListBackupsForAssetItemType>(INITIAL_STATE)

  const previousFilters = useRef<ListBackupsForAssetItemsFiltersParams | null>(
    null
  )

  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()

  const updateBackupsData = (backupsResponse: ListBackupsForAssetItemType) => {
    setBackupsData((prevBackups) => ({
      awsbRpsList: [
        ...prevBackups.awsbRpsList,
        ...(backupsResponse.awsbRpsList || []),
      ],
      ebsSnapshotsList: [
        ...prevBackups.ebsSnapshotsList,
        ...(backupsResponse.ebsSnapshotsList || []),
      ],
      elastioRpsList: [
        ...prevBackups.elastioRpsList,
        ...(backupsResponse.elastioRpsList || []),
      ],
    }))
  }

  const handleFetchSuccess = ({
    backupsList,
    nextPageToken,
  }: {
    backupsList: ListBackupsForAssetItemType
    nextPageToken?: string
  }) => {
    updateBackupsData(backupsList)
    updatePageTokenAndFlagFetched(nextPageToken || undefined)
  }

  const resetData = () => {
    setBackupsData(INITIAL_STATE)
    resetPagination()
  }

  const setPreviousFilters = (
    newFilters: ListBackupsForAssetItemsFiltersParams
  ) => {
    previousFilters.current = newFilters
  }

  const fetchPagination = async () => {
    if (!pageToken) {
      return
    }

    fetchStatus.setFetching()
    try {
      const response = await fetchBackupsListViaPagination(pageToken as string)

      fetchStatus.setSuccess()
      handleFetchSuccess({
        backupsList: response,
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      console.error(`Error fetching backups request - ${error}`)
      fetchStatus.setFailure()
    } finally {
      fetchStatus.reset()
    }
  }

  const fetchInitial = async (
    filters?: ListBackupsForAssetItemsFiltersParams
  ) => {
    const combinedFilters = {
      ...initialFilters,
      ...filters,
    }

    const areFiltersEqual = ObjHelper.isEqual(
      combinedFilters,
      previousFilters.current
    )

    if (areFiltersEqual) {
      return
    }

    resetData()
    fetchStatus.setFetching()
    try {
      if (fetchStatus.isIdle()) {
        const response = await fetchInitialBackupsList(combinedFilters)
        setPreviousFilters(combinedFilters)
        fetchStatus.setSuccess()
        handleFetchSuccess({
          backupsList: response,
          nextPageToken: response.pageToken,
        })
      }
    } catch (error) {
      console.log(error)

      console.error(
        `Something went wrong with fetch asset items for asset request in ${__filename}: ${error}`
      )
      fetchStatus.setFailure()
    } finally {
      fetchStatus.reset()
    }
  }

  return {
    fetchInitial,
    onFetchData: fetchPagination,
    data: backupsData,
    dataFetchStatus: fetchStatus.status,
    resetData,
    isLoadingData: fetchStatus.isLoading,
  }
}
