/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import {
  ColumnWidth,
  createColumn,
  createDisplayColumn,
  StatusCircle,
} from '@components/table'
import {
  CellWithLink,
  CellWithDateTimeTooltip,
} from '@components/table/core/cell'
import LangHelper from '@lib/helpers/lang.helper'
import {
  RiskLevel,
  VolumeOutput,
  Nullable,
  EC2,
  Backup,
} from 'blues-corejs/dist'
import { TABLE_MISCONFIGURATIONS_ROW_MIN_HEIGHT } from './consts'
import { AssetRoutes } from '@lib/router'

const EMPTY_INSTANCE_VALUE = '- -'

export const volumesColumns = [
  createDisplayColumn<VolumeOutput>({
    id: 'status',
    size: 40,
    cell: () => <StatusCircle isHealthy={false} />,
  }),
  createColumn<VolumeOutput>('volumeId', {
    header: 'Volume',
    meta: {
      height: TABLE_MISCONFIGURATIONS_ROW_MIN_HEIGHT,
    },
    cell: (props) => {
      const volumeId = props.getValue() as unknown as string
      const assetPageUrl = AssetRoutes.assetId(volumeId)
      const { volumeName, volumeAwsId } = props.row.original

      const displayedValue = volumeName || volumeAwsId
      const displayedLabel = volumeName ? volumeAwsId : volumeName

      return (
        <CellWithLink
          href={assetPageUrl}
          value={displayedValue}
          label={displayedLabel}
        />
      )
    },
  }),
  createColumn<VolumeOutput>('vulnerabilitiesList', {
    header: 'Misconfiguration',
    meta: {
      size: ColumnWidth.LARGE,
    },
    cell: (props) => {
      const vulnerabilities = props.getValue() as unknown as Array<string>

      return (
        <LongTextTooltip text={vulnerabilities.join(', ')} maxLength={60} />
      )
    },
  }),
  createColumn<VolumeOutput>('riskVulnerabilities', {
    header: 'Risk',
    cell: (props) => RiskLevel[Number(props.getValue())],
  }),
  createColumn<VolumeOutput>('accountId', {
    header: 'Account ID',
  }),
  createColumn<VolumeOutput>('region', {
    header: 'Region',
    cell: (props) => LangHelper.getAwsRegionSingleTranslation(props.getValue()),
  }),

  createColumn<VolumeOutput>('instancesList', {
    header: 'Instance attached',
    cell: (props) => {
      const instances = props.getValue() as unknown as Array<EC2>

      let instancesIds = instances.map((instance) => instance.awsId)

      if (instancesIds.length === 0) {
        instancesIds = [EMPTY_INSTANCE_VALUE]
      }

      return instancesIds.map((id: string) => {
        return <LongTextTooltip key={id} text={id} maxLength={40} />
      })
    },
  }),

  createColumn<VolumeOutput>('isAttached', {
    header: 'Status',
    meta: {
      textAlign: 'right',
    },
    cell: (props) => {
      const isAttached = props.getValue() as unknown as boolean

      return isAttached ? 'Attached' : 'Unattached'
    },
  }),

  createColumn<VolumeOutput>('lastBackup', {
    header: 'Last Backup',
    meta: {
      textAlign: 'right',
    },
    cell: ({ getValue }) => {
      const lastBackup = getValue() as unknown as Nullable<{ backup: Backup }>

      if (!lastBackup?.backup) {
        return null
      }

      return <CellWithDateTimeTooltip date={lastBackup.backup.timestamp} />
    },
  }),
]
