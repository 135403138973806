import React from 'react'
import { Card, CardProps } from '@mui/material'
import { styled } from '@mui/material/styles'

export type GradientVariant = 'blue' | 'red'

type GradientColors = Record<
  GradientVariant,
  {
    border: string
    background: string
  }
>

interface GradientCardProps extends Omit<CardProps, 'variant'> {
  gradientVariant?: GradientVariant
}

const gradientColors: GradientColors = {
  blue: {
    border: 'rgba(0, 100, 193, 0.6)',
    background: 'rgba(0, 100, 193, 0.6)',
  },
  red: {
    border: 'rgba(255, 22, 84, 0.6)',
    background: 'rgba(255, 22, 84, 0.6)',
  },
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'gradientVariant',
})<{ gradientVariant: GradientVariant }>(({ theme, gradientVariant }) => {
  const colors = gradientColors[gradientVariant]

  return {
    padding: theme.spacing(2.5, 2.5),
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    background:
      theme.palette.mode === 'dark'
        ? `radial-gradient(100% 100% at 54.49% 100%, ${colors.background} 0%, ${colors.background.replace(', 0.6)', ', 0)')} 100%)`
        : '#ffffff',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: theme.spacing(1.5),
      padding: '1px',
      background:
        theme.palette.mode === 'dark'
          ? `radial-gradient(92.89% 92.89% at 50% -3.16%, ${colors.border} 0%, ${colors.border.replace(', 0.6)', ', 0)')} 100%)`
          : '#ffffff',
      mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      maskComposite: 'exclude',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      pointerEvents: 'none',
    },
    boxShadow:
      theme.palette.mode === 'dark'
        ? 'none'
        : '0px 16px 20px 0px rgba(167, 172, 211, 0.17)',
  }
})

const GradientCard = React.forwardRef<HTMLDivElement, GradientCardProps>(
  ({ gradientVariant = 'blue', children, ...props }, ref) => {
    return (
      <StyledCard
        ref={ref}
        gradientVariant={gradientVariant}
        elevation={0}
        {...props}
      >
        {children}
      </StyledCard>
    )
  }
)

GradientCard.displayName = 'GradientCard'

export default GradientCard
