import { useAssetsEc2Query } from './ec2'
import { useAssetsEbsQuery } from './ebs'
import { useAssetsS3Query } from './s3'
import { useAssetsEfsQuery } from './efs'
import { useAssetsGenericHostsQuery } from './generic-host'
import { useAssetsLiveQuery } from './live'
import { useAssetsOvaServerQuery } from './ova-server'
import { useAssetsListByIdQuery } from './list-by-id'
import { useAssetItemsQuery } from './asset-items'

export {
  useAssetsEc2Query,
  useAssetsEbsQuery,
  useAssetsS3Query,
  useAssetsEfsQuery,
  useAssetsGenericHostsQuery,
  useAssetsLiveQuery,
  useAssetsOvaServerQuery,
  useAssetsListByIdQuery,
  useAssetItemsQuery,
}
