/* eslint-disable import/no-extraneous-dependencies */
import InfoHeader from '@components-composite/info-header/InfoHeader'
import AddVaultModal, {
  AddVaultModalInputData,
} from '@components-composite/modals/AddVaultModal'
import DeleteSourceModal from '@components-composite/modals/DeleteSourceModal'
import DialogModal from '@components-composite/modals/DialogModal'
import FixCCProblemsModal from '@components-composite/modals/FixCCProblemsModal'
import ReconnectModal from '@components-composite/modals/ReconnectModal'
import SelectDefaultVaultModal from '@components-composite/modals/SelectDefaultVaultModal'
import { SourcesExtraInfoRow } from '@components-composite/sources-extra-info-row/SourcesExtraInfoRow'
import InstallCliLink from '@components-simple/install-cli-link/InstallCliLink'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import AccountStatusConstant from '@lib/constants/account-status.constant'
import Modal from '@lib/constants/modal.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'
import { UserSettings } from '@lib/constants/settings.constant'
import TableFactory from '@lib/factories/table.factory'
import ArrHelper from '@lib/helpers/arr.helper'
import DataHelper, { CloudConnectorProblems } from '@lib/helpers/data.helper'
import LangHelper from '@lib/helpers/lang.helper'
import SystemHelper from '@lib/helpers/system.helper'
import { useTenantIdsFromScheduledJobs } from '@lib/hooks/use-tenant-ids-from-scheduled-jobs'
import { useModal } from '@lib/hooks/useModal'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import FormAddVaultInterface from '@lib/interfaces/form/form-add-vault.interface'
import { CCwithProblems } from '@lib/interfaces/integrity-check.interface'
import RedStackModel from '@lib/models/red-stack.model'
import VaultModel from '@lib/models/vault.model'
import SettingsService from '@lib/services/high/settings.service'
import { Button } from '@mui/material'
import {
  getAllRedStacks,
  requestListAwsAccounts,
} from '@store/actions/rex.action'
import { activateSources, deleteSources } from '@store/actions/source-id.action'
import {
  createVault,
  requestSourcesVaultList,
  setVaultAsDefault,
} from '@store/actions/vault.action'
import {
  getAllActiveRedStacksList,
  getAllRedStacksList,
  getListAwsAccountsList,
} from '@store/selectors/rex.selector'
import { getSourcesVaultList } from '@store/selectors/vault.selector'
import ControlledVITable from '@tables/ControlledVITable'
import { ACTIVE_SOURCES_TABLE_HEAD } from '@tables/core/table-constants'
import { ACTIVE_SOURCES_TABLE_MAPPER } from '@tables/core/table-vi-draw-mappers'
import MenuVIRow from '@tables/rows-vi/MenuVIRow'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import { useScheduleTenantJob } from '@features/scheduled-jobs-monitoring/hooks'
import GrpcRexService from '@lib/services/grpc/grpc-rex.service'
import { ScheduledTenantJobKind } from '@features/scheduled-jobs-monitoring/models'
import { SourcesRoutes } from '@lib/router/routes/sources/sources'
import { PageHelper } from '@lib/helpers'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { DeploymentRoutes } from '@lib/router/routes/deployment'
import Link from '@lib/router/navigation-strategies/link'

const REPAIR_PREFIX = 'Repair - '

// also in the menu we add extra 'Repair' options for regions
enum MENU {
  Edit = 'Edit',
  Reconnect = 'Reconnect',
  Unlink = 'Unlink',
  Upgrade = 'Upgrade',
}

type SelectedAccount = {
  redStacks: Array<RedStackModel>
  accountId: string
  region: string
}

function Sources() {
  const { scheduleTenantJob } = useScheduleTenantJob()

  const dispatch = useDispatch()
  const router = useNavigation()

  const isLoading = usePreloaderAny([
    PreloaderConstants.GET_ALL_RED_STACKS,
    PreloaderConstants.ACTIVATE_SOURCES,
    PreloaderConstants.DEACTIVATE_SOURCES,
    PreloaderConstants.DELETE_SOURCES,
    PreloaderConstants.REQUEST_LIST_AWS_ACCOUNTS,
  ])
  const loadingVault = usePreloader(
    PreloaderConstants.REQUEST_SOURCES_VAULT_LIST
  )

  useEffect(() => {
    dispatch(getAllRedStacks())
    dispatch(requestSourcesVaultList())
    dispatch(requestListAwsAccounts())
  }, [])

  const allRedStacksList = useSelector(getAllRedStacksList)
  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const vaultList = useSelector(getSourcesVaultList)
  const listAwsAccountsList = useSelector(getListAwsAccountsList)

  // Get already known RS
  const alreadyKnownRsUpgrade: Array<string> =
    SettingsService.getSetting(UserSettings.RsUpgrade) ?? []

  const RSwhichNeedUpgrade = allActiveRedStacks
    .filter(
      (rs) =>
        rs.status === RedStackStatusConstant.UPGRADE_REQUIRED &&
        !rs.cfnUpgradeRequired
    )
    .map((rs) => `${rs.awsAccount} (${rs.awsRegion})`)
    .filter((awsAccount) => !alreadyKnownRsUpgrade?.includes(awsAccount))
  // Get already known accounts without RS
  const alreadyKnownCfnUpgrade: Array<string> =
    SettingsService.getSetting(UserSettings.CfnUpgrade) ?? []

  // Make array with new upgrades
  const accountsWithoutRS = !allActiveRedStacks.length
    ? []
    : listAwsAccountsList
        ?.filter(
          (acc) =>
            !allActiveRedStacks.some(
              ({ awsAccount }) => awsAccount === acc.awsAccountId
            )
        )
        .map(({ awsAccountId }) => awsAccountId)
        .filter((awsAccount) => !alreadyKnownCfnUpgrade?.includes(awsAccount))

  const cloudConnectorsInAction = useTenantIdsFromScheduledJobs([
    ScheduledTenantJobKind.REPAIR_CLOUD_CONNECTOR,
    ScheduledTenantJobKind.REPAIR_VAULT,
  ])

  const enabledSourcesData = TableFactory.enabledSourcesVI(
    allRedStacksList,
    vaultList
  )

  const possibleRegionsTranslation = ArrHelper.uniqueBy(
    allActiveRedStacks.map((v) => ({
      name: v.awsRegion,
      label: LangHelper.getAwsRegionSingleTranslation(v.awsRegion),
      value: v.version,
    })),
    'name'
  )

  const possibleRegionsByAccount = (awsAccount: string) => {
    const availableRegions = allActiveRedStacks
      .filter((rs) => rs.awsAccount === awsAccount)
      .map(({ awsRegion }) => awsRegion)

    return possibleRegionsTranslation.filter(({ name }) =>
      availableRegions.includes(name)
    )
  }

  const {
    openModal: openReconnectSourcesModal,
    modalProps: ReconnectSourcesModalProps,
  } = useModal<Array<RedStackModel>>(
    Modal.connectSources,
    (rsArr: Array<RedStackModel>) => {
      rsArr.forEach((r) => {
        dispatch(activateSources(r.innerId, r.awsRegion, r.awsAccount))
      })
    }
  )

  const problematicCC = DataHelper.findProblematicCC(
    allActiveRedStacks,
    vaultList
  )
  const { modalProps: RepairCCModalProps, openModal: openRepairCCModal } =
    useModal<string>(Modal.repairCloudConnector, async (cloudConnectorId) => {
      const jobId = await GrpcRexService.repairCloudConnector(cloudConnectorId)
      await scheduleTenantJob(jobId)
    })

  const { modalProps: FixCCModalProps, openModal: openFixCCModal } = useModal<{
    accounts: Array<CCwithProblems>
  }>(Modal.fixCC, () => {})

  const {
    openModal: openRemoveSourcesModal,
    modalProps: RemoveSourcesModalProps,
  } = useModal<Array<RedStackModel>>(
    Modal.removeSources,
    (rsArr: Array<RedStackModel>) => {
      dispatch(deleteSources(rsArr))
    }
  )

  const [selectedRsAccount, setSelectedRsAccount] = useState<SelectedAccount>()

  const { openModal: openAddVaultModal, modalProps: openAddVaultProps } =
    useModal<AddVaultModalInputData, FormAddVaultInterface>(
      Modal.addVault,
      (form) => {
        const redStackId = selectedRsAccount?.redStacks?.find(
          (rs: RedStackModel) => rs.awsRegion === form.region
        )?.redStackId

        dispatch(
          createVault({
            vaultName: form.vaultName,
            vpcId: form.vpc,
            subnetIdsList: form.subnets ?? [],
            redStackId: redStackId ?? '',
            accountId: selectedRsAccount?.accountId ?? '',
            safetyLock: form.safetyLock,
          })
        )
      }
    )

  const { openModal: openVaultDefModal, modalProps: vaultDefProps } = useModal<
    Array<VaultModel>,
    string
  >(Modal.defaultPolicy, (vaultId) => {
    const selectedVault = vaultList.find((vault) => vault.innerId === vaultId)
    if (selectedVault) {
      dispatch(setVaultAsDefault(selectedVault))
    }
  })

  const openAddVaultModalByClick = () => {
    if (!selectedRsAccount || !selectedRsAccount.accountId) {
      return
    }
    return openAddVaultModal({
      possibleRegionsTranslation:
        possibleRegionsByAccount(selectedRsAccount.accountId).filter(
          ({ name }) => selectedRsAccount.region === name
        ) || [],
      accountId: selectedRsAccount.accountId,
      vaults: vaultList.filter(
        (vault) => vault.accountId === selectedRsAccount.accountId
      ),
    })
  }

  const onEnableTableMenu = (selectedMenu: any) => {
    const accountId = selectedMenu.data[1].name
    const accountRedStack = allRedStacksList.filter(
      (r) =>
        r.awsAccount === accountId &&
        r.status !== RedStackStatusConstant.DELETED
    )

    if (selectedMenu.chosenMenu.includes(REPAIR_PREFIX)) {
      const region = selectedMenu.chosenMenu.replace(REPAIR_PREFIX, '')
      const redStackForRepairing = allActiveRedStacks.find(
        (ar) => ar.awsRegionFormatted === region && ar.awsAccount === accountId
      )
      if (redStackForRepairing) {
        openRepairCCModal(redStackForRepairing.redStackId)
      }
      return
    }

    switch (selectedMenu.chosenMenu) {
      case MENU.Edit:
        router.push(SourcesRoutes.buildPathWithEncodedId(accountId))
        break
      case MENU.Reconnect:
        const inactiveRedStacks = accountRedStack.filter((r) => r.isInactive)
        openReconnectSourcesModal(inactiveRedStacks)
        break
      case MENU.Unlink:
        openRemoveSourcesModal(accountRedStack)
        break
      case MENU.Upgrade:
        window.open(
          PageHelper.buildUrl(
            PagePathConstant.CLOUD_CONFIGURE_UPGRADE_WITH_ACCOUNT,
            accountId
          ),
          '_blank'
        )
        break

      default:
        SystemHelper.throwErrorInLocalEnv('Sources page - wrong menu name')
    }
  }

  const openModalByProblem = (
    acc?: CCwithProblems,
    vaultRegion?: string,
    brokenRedStacksByAcc?: Array<RedStackModel>
  ) => {
    if (!acc) {
      return
    }
    setSelectedRsAccount({
      redStacks: brokenRedStacksByAcc ?? [],
      accountId: acc.model.awsAccount,
      region: vaultRegion ?? acc.model.awsRegion,
    })

    if (acc.reason === CloudConnectorProblems.BS_ERROR) {
      openFixCCModal({
        accounts: problematicCC.problemsArray.filter(
          (cc) => cc.reason && cc.model.awsAccount === acc.model.awsAccount
        ),
      })
      return
    }

    if (acc.reason === CloudConnectorProblems.NO_DEFAULT) {
      openVaultDefModal(
        vaultList.filter(
          (vault) =>
            vault.accountId === acc.model.awsAccount &&
            vault.region === vaultRegion
        )
      )
      return
    }

    if (acc.reason === CloudConnectorProblems.NO_VAULTS) {
      openAddVaultModal({
        possibleRegionsTranslation:
          possibleRegionsByAccount(acc.model.awsAccount) ?? [],
        vaults: vaultList.filter(
          (vault) => vault.accountId === acc.model.awsAccount
        ),
        accountId: acc.model.awsAccount,
      })
      return
    }
  }

  // Check if there is something new RS, if so - show alert again
  const isNewRsUpgrades =
    !isLoading &&
    !!RSwhichNeedUpgrade?.filter(
      (item) => !alreadyKnownRsUpgrade?.includes(item)
    ).length

  // Remember RS
  const onCloseRSUpgrade = () =>
    SettingsService.updateSetting(
      UserSettings.RsUpgrade,
      alreadyKnownRsUpgrade.concat(RSwhichNeedUpgrade)
    )

  // Check if there is something new CFN upgrades, if so - show alert again
  const isNewCfnUpgrades =
    !isLoading &&
    !!accountsWithoutRS?.filter(
      (item) => !alreadyKnownCfnUpgrade?.includes(item)
    ).length

  // Remember accounts
  const onCloseCfnUpgrade = () =>
    SettingsService.updateSetting(
      UserSettings.CfnUpgrade,
      alreadyKnownCfnUpgrade.concat(accountsWithoutRS)
    )

  return (
    <div className="innerContent jsSourcesPage">
      <div className="wrap-1622553673917 jsSourcesComplex">
        <div className="controlHeaderBlock">
          <div className="controlHeader">
            <div className="controlDescSmall jsSourcesComplexDescription">
              Sources are AWS accounts that host the Elastio service and
              optionally contain AWS assets that you would like to protect.
            </div>
            <div className="controlHeaderControls">
              <InstallCliLink />
              <Link to={DeploymentRoutes.cloudConfigureDeployment}>
                <Button
                  className="jsLinkSource sizeXS"
                  color="primary"
                  variant="contained"
                >
                  Link Source
                </Button>
              </Link>
            </div>
          </div>

          {isNewRsUpgrades && (
            <InfoHeader
              closable
              tags={RSwhichNeedUpgrade}
              type="info"
              title="The upgrade will be completed in a few minutes."
              description="Elastio handles Cloud Connector upgrades automatically. Upgrade operation takes several minutes to complete. Once the upgraded, the source will be displayed in the list as active."
              onClose={onCloseRSUpgrade}
            />
          )}
          {isNewCfnUpgrades && (
            <InfoHeader
              className="cfnAlert"
              closable
              tags={accountsWithoutRS}
              type="info"
              title="Cloud connector ready to be deployed"
              description="These accounts are ready to be deployed with Elastio. Protect your assets from security threats in your applications."
              onClose={onCloseCfnUpgrade}
            />
          )}
        </div>
        {isLoading && enabledSourcesData?.length === 0 ? (
          <PreloaderBlock show />
        ) : (
          <>
            {enabledSourcesData?.length > 0 ? (
              <ControlledVITable
                head={ACTIVE_SOURCES_TABLE_HEAD}
                columnDrawMapper={ACTIVE_SOURCES_TABLE_MAPPER()}
                rows={enabledSourcesData}
                onMenuClick={onEnableTableMenu}
                className={clsx(
                  'shrinkTableColumn1 shrinkTableColumn6  tableVIUncontrolled jsActiveSourcesTable borderless',
                  {
                    // tableVIHideMenu: modeNotActive,
                    controlsDisabled: isLoading,
                  }
                )}
                menu={[MENU.Upgrade, MENU.Edit, MENU.Reconnect, MENU.Unlink]}
                rowComponent={(props: any) => {
                  const accountId = props.data[1].name

                  const getMenuByStatus = (
                    status: AccountStatusConstant
                  ): Array<MENU> => {
                    switch (status) {
                      case AccountStatusConstant.CONNECTED:
                        return [MENU.Edit, MENU.Unlink]
                      case AccountStatusConstant.MIXED:
                        return [MENU.Reconnect, MENU.Edit, MENU.Unlink]
                      case AccountStatusConstant.DISCONNECTED:
                        return [MENU.Reconnect, MENU.Unlink]
                      case AccountStatusConstant.UPGRADE_REQUIRED:
                        return [MENU.Upgrade, MENU.Edit, MENU.Unlink]
                      default:
                        SystemHelper.throwErrorInLocalEnv(
                          'Wrong accounts status on the sources page'
                        )
                        return []
                    }
                  }

                  const menuIsAvailable = (menu: string): boolean => {
                    if (menu.includes(REPAIR_PREFIX)) {
                      const region = menu.replace(REPAIR_PREFIX, '')
                      const selectedCloudConnectorId = allActiveRedStacks.find(
                        (ar) =>
                          ar.awsAccount === accountId &&
                          ar.awsRegionFormatted === region
                      )?.redStackId
                      if (selectedCloudConnectorId) {
                        return !cloudConnectorsInAction.find(
                          (cc) => cc === selectedCloudConnectorId
                        )
                      }
                    }
                    return true
                  }

                  const accountNotDeletedRedStacks = allRedStacksList.filter(
                    (r) =>
                      r.awsAccount === accountId &&
                      r.status !== RedStackStatusConstant.DELETED
                  )

                  const account = problematicCC.problemsArray.find(
                    (r: CCwithProblems) => r.model.awsAccount === accountId
                  )

                  const redStackAccounts = allActiveRedStacks.filter(
                    (rs) => rs.awsAccount === account?.model.awsAccount
                  )

                  const problematicCCByAcc = problematicCC.problemsArray.filter(
                    (r: CCwithProblems) => r.model.awsAccount === accountId
                  )

                  const brokenRedStacksByAcc = problematicCCByAcc
                    ?.filter((brokenRS: CCwithProblems) => {
                      return allActiveRedStacks.map(
                        (rs) => rs.awsAccount === brokenRS?.model.awsAccount
                      )
                    })
                    .map((brokenActiveRS) => brokenActiveRS.model)

                  const accountsVaults = vaultList.filter((vault) =>
                    redStackAccounts.some(
                      (rs) => rs.redStackId === vault.redStackId
                    )
                  )

                  const regionsWithDefault: Array<string> = []
                  const regionsWithoutDefault: Array<string> = []

                  const vaultsWithoutDefault = accountsVaults
                    .reduce((result, vault) => {
                      if (vault.isDefault) {
                        regionsWithDefault.push(vault.region)
                        return result
                      }

                      if (!regionsWithoutDefault.includes(vault.region)) {
                        regionsWithoutDefault.push(vault.region)
                        result.push(vault)
                      }

                      return result
                    }, [] as Array<VaultModel>)
                    .filter((vault) =>
                      regionsWithDefault.every((val) => val !== vault.region)
                    )

                  // currently this info is taken from the standard column of regions
                  // in future better to place all regions in a separate field of the column
                  const menu = [
                    ...getMenuByStatus(props.data[5].type),
                    ...props.data[4].name
                      .split(', ')
                      .filter((r: string) => !!r.trim())
                      .map((r: string) => `${REPAIR_PREFIX}${r}`),
                  ]

                  const isOddRow = props.index % 2 === 0
                  return (
                    <>
                      {loadingVault ? (
                        <PreloaderSmall show />
                      ) : (
                        <>
                          <MenuVIRow
                            {...props}
                            menu={menu}
                            menuIsAvailable={menuIsAvailable}
                            className={clsx({
                              highlighted: isOddRow,
                            })}
                          />
                          <SourcesExtraInfoRow
                            data={{
                              vaults: vaultsWithoutDefault,
                            }}
                            accountId={accountId}
                            accountRedStacks={accountNotDeletedRedStacks}
                            status={account?.reason || props.data[5].type}
                            onReconnect={openReconnectSourcesModal}
                            onClick={(vaultRegion) =>
                              openModalByProblem(
                                account,
                                vaultRegion,
                                brokenRedStacksByAcc
                              )
                            }
                            className={clsx({
                              highlighted: isOddRow,
                            })}
                          />
                        </>
                      )}
                    </>
                  )
                }}
              />
            ) : (
              <div className="emptyResultBlock jsEmptyActiveSourcesTable">
                Click “Link Source” to add a source account
              </div>
            )}
          </>
        )}
        <ReconnectModal {...ReconnectSourcesModalProps} />
        <FixCCProblemsModal
          onFixClick={(id) => openRepairCCModal(id)}
          {...FixCCModalProps}
        />

        <DeleteSourceModal {...RemoveSourcesModalProps} />
        <SelectDefaultVaultModal
          openAddModal={openAddVaultModalByClick}
          className="defaultVaultModal"
          loading={false}
          {...vaultDefProps}
        />
        <AddVaultModal loading={false} {...openAddVaultProps} />
        <DialogModal
          description="You are going to repair the cloud connector, please confirm the action in order to proceed"
          {...RepairCCModalProps}
        />
      </div>
    </div>
  )
}

export default Sources
