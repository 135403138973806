import React, { useCallback } from 'react'
import { StyledViewAllButton } from '@features/DashboardV4/entropy-trends/styles'
import { useNavigation, EntropyTrendsRoutes } from '@lib/router'

function ViewAllButton() {
  const router = useNavigation()

  const handleClick = useCallback(
    () => router.push(EntropyTrendsRoutes.root),
    []
  )

  return (
    <StyledViewAllButton onClick={handleClick}>View All</StyledViewAllButton>
  )
}

export { ViewAllButton }
