import React from 'react'

function ReviewResultsIcon() {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="10" fill="#E8E4FF" />
      <path
        d="M5 10.0016C5 7.25 7.25 5 10.0016 5H44.9984C47.75 5 50 7.25 50 10.0016V44.9984C50 47.75 47.75 50 44.9984 50H10.0016C7.25 50 5 47.75 5 44.9984V10.0016Z"
        fill="#455A64"
      />
      <path
        d="M29.75 48.2516L24.7484 20.8766L19.8734 40.4984L17.2484 28.3766L17.1266 28.7516H5V26.2484H15.3734L17.75 19.1234L20.1266 29.5016L25.25 9.125L30.2516 36.7484L35 19.0016L37.8734 29.1266L39.2516 26.2484H50V28.7516H40.7516L37.1234 35.8766L35 28.4984L29.75 48.2516Z"
        fill="#AEEA00"
      />
    </svg>
  )
}

export default ReviewResultsIcon
