export enum FormVariant {
  AWS = 'AWS',
  BackupConnector = 'BackupConnector',
}

export enum AWSReports {
  awsExecutiveReport = 'awsExecutiveReport',
  awsAccountSummaryReport = 'awsAccountSummaryReport',
  awsTenantSummaryReport = 'awsTenantSummaryReport',
  awsCompletedScansReport = 'awsCompletedScansReport',
  awsScannedAssetsSummaryReport = 'awsScannedAssetsSummaryReport',
  infectedFilesReport = 'infectedFilesReport',
  failedScansReport = 'failedScansReport',
  awsInfectedAssetsReport = 'awsInfectedAssetsReport',
}

export enum BackupConnectorReports {
  ovaMspOperationalReport = 'ovaMspOperationalReport',
  ovaMspExecutiveReport = 'ovaMspExecutiveReport',
  ovaMspTenantSummaryReport = 'ovaMspTenantSummaryReport',
  ovaMspAccountSummaryReport = 'ovaMspAccountSummaryReport',
  ovaMspCompletedScansReport = 'ovaMspCompletedScansReport',
  ovaMspScannedAssetsSummaryReport = 'ovaMspScannedAssetsSummaryReport',
  ovaMspInfectedAssetsReport = 'ovaMspInfectedAssetsReport',
  ovaMspInfectedFilesReport = 'ovaMspInfectedFilesReport',
  ovaMspFailedScansReport = 'ovaMspFailedScansReport',
}

export const Reports = {
  aws: Object.values(AWSReports),
  backup: Object.values(BackupConnectorReports),
}

const AWSPrefix = 'AWS'
const OVAPrefix = 'OVA'

export const reportOptions = [
  // AWS Reports
  {
    label: `${AWSPrefix} Ransomware Readiness`,
    value: 'awsExecutiveReport',
    type: FormVariant.AWS,
    formats: ['PDF'],
  },
  {
    label: `${AWSPrefix} Account Summary`,
    value: 'awsAccountSummaryReport',
    type: FormVariant.AWS,
    formats: ['PDF'],
  },
  {
    label: `${AWSPrefix} Operational Summary`,
    value: 'awsTenantSummaryReport',
    type: FormVariant.AWS,
    formats: ['PDF'],
  },
  {
    label: `${AWSPrefix} All Scans`,
    value: 'awsCompletedScansReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
  },
  {
    label: `${AWSPrefix} Failed Scans`,
    value: 'failedScansReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
  },
  {
    label: `${AWSPrefix} Infected files`,
    value: 'infectedFilesReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
  },
  {
    label: `${AWSPrefix} Assets Scanned`,
    value: 'awsScannedAssetsSummaryReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
  },
  {
    label: `${AWSPrefix} Infected Assets`,
    value: 'awsInfectedAssetsReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
  },

  // Backup Connector Reports
  {
    label: `${OVAPrefix} Ransomware Readiness`,
    value: 'ovaMspExecutiveReport',
    type: FormVariant.BackupConnector,
    formats: ['PDF'],
  },
  {
    label: `${OVAPrefix} Account Summary`,
    value: 'ovaMspAccountSummaryReport',
    type: FormVariant.BackupConnector,
    formats: ['PDF'],
  },
  {
    label: `${OVAPrefix} Operational Summary`,
    value: 'ovaMspTenantSummaryReport',
    type: FormVariant.BackupConnector,
    formats: ['PDF'],
  },

  {
    label: `${OVAPrefix} All Scans`,
    value: 'ovaMspCompletedScansReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
  },
  {
    label: `${OVAPrefix} Infected files`,
    value: 'ovaMspInfectedFilesReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
  },
  {
    label: `${OVAPrefix} Assets Scanned`,
    value: 'ovaMspScannedAssetsSummaryReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
  },
  {
    label: `${OVAPrefix} Infected Assets`,
    value: 'ovaMspInfectedAssetsReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
  },

  //for future use
  // {
  //     label: `${OVAPrefix} Failed Scans`,
  //     value: 'ovaMspFailedScansReport',
  //     type: FormVariant.BackupConnector,
  //     formats: ['CSV'],
  // }
]
