// eslint-disable-next-line import/no-extraneous-dependencies
import { OvaBackupProvider } from 'blues-corejs/dist/models/reports/report-schedule/kind'

export const ovaBackupProviderOptions = [
  {
    label: 'Veeam',
    value: OvaBackupProvider.VEEAM,
  },
  {
    label: 'Commvault',
    value: OvaBackupProvider.COMMVAULT,
  },
  {
    label: 'Rubrik',
    value: OvaBackupProvider.RUBRIK,
  },
  {
    label: 'Cohesity',
    value: OvaBackupProvider.COHESITY,
  },
  {
    label: 'Veritas NetBackup',
    value: OvaBackupProvider.VERITAS_NET_BACKUP,
  },
  {
    label: 'AWS Backup VMware',
    value: OvaBackupProvider.AWS_BACKUP_VMWARE,
  },
]
