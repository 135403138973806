/* eslint-disable import/no-extraneous-dependencies */
import { ListBackupsForAssetItem } from 'ui-v2/src/lib/clients/backups'
import { getSizeFormattedInGib } from 'ui-v2/src/lib/helpers/num.helper'
import {
  RecoveryListTableDataAttrs,
  RecoveryListTableRow,
} from 'ui-v2/src/lib/models/assets/infected-assets'
import {
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
  OvaBackupProvider,
} from 'blues-corejs/dist'

const BACKUP_SOURCE_ELASTIO_NAME = 'Elastio'
const BACKUP_SOURCE_AWS_NAME = 'AWS Backup'
const BACKUP_SOURCE_EBS_SNAPSHOT_NAME = 'EBS Snapshot'

const DEFAULT_BACKUP_SIZE = 0

function computeBackupSize(backup: Backup): number {
  if (backup instanceof ElastioRecoveryPoint) {
    return backup.optimizedSize
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return backup.size
  }

  if (backup instanceof EBSSnapshot) {
    return backup.size
  }

  if (backup instanceof OvaBackup) {
    return backup.totalSize
  }

  return DEFAULT_BACKUP_SIZE
}

function computeOvaBackupSource(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return 'Cohesity'
    case OvaBackupProvider.VEEAM:
      return 'Veeam'
    case OvaBackupProvider.COMMVAULT:
      return 'Commvault'
    case OvaBackupProvider.VERITAS_NET_BACKUP: {
      return 'VeritasNetBackup'
    }
    case OvaBackupProvider.RUBRIK: {
      return 'Rubrik'
    }
    case OvaBackupProvider.AWS_BACKUP_VMWARE: {
      return 'AwsBackupVmware'
    }
    default:
      return null
  }
}

function computeBackupSource(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return BACKUP_SOURCE_ELASTIO_NAME
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return BACKUP_SOURCE_AWS_NAME
  }

  if (backup instanceof EBSSnapshot) {
    return BACKUP_SOURCE_EBS_SNAPSHOT_NAME
  }

  if (backup instanceof OvaBackup) {
    return computeOvaBackupSource(backup)
  }

  return null
}

export class RecoveryTableData {
  readonly #recoveryList: ListBackupsForAssetItem

  constructor(parameters: RecoveryListTableDataAttrs) {
    this.#recoveryList = parameters.recoveryList
  }

  getRecoveryList = (): Array<RecoveryListTableRow> => {
    return this.#recoveryList.map((recovery) => {
      const backupFromMap = computeBackupSource(recovery)
      const formattedSize = getSizeFormattedInGib(computeBackupSize(recovery))

      return new RecoveryListTableRow({
        id: recovery.id,
        recoveryPointTime: recovery.timestamp,
        backupSource: backupFromMap ?? '-',
        backupSize: formattedSize,
        action: '',
      })
    })
  }
}
