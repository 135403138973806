import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { compareVersions } from 'compare-versions'
import { MIN_VERSION_FOR_RECOVERY_TESTING } from '@lib/constants/integrity-check-constant'
import { useSelector } from 'react-redux'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'

export function useRecoveryTestingOption() {
  const [isRecoveryTestingDisabled, setIsRecoveryTestingDisabled] =
    useState<boolean>(false)

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)

  useDeepCompareEffect(() => {
    setIsRecoveryTestingDisabled(
      allActiveRedStacks?.some(
        ({ version }) =>
          compareVersions(version, MIN_VERSION_FOR_RECOVERY_TESTING) < 0
      )
    )
  }, [allActiveRedStacks])

  return {
    isRecoveryTestingDisabled,
  }
}
