import { styled, Tabs } from '@mui/material'

interface MUITabsProps {
  minheight?: number
}

export const MUITabs = styled(Tabs)<MUITabsProps>(
  ({ theme, minheight = 40 }) => ({
    height: `${minheight}px`,
    minHeight: `${minheight}px`,
    fontSize: '14px',
    '.MuiTab-root': {
      textTransform: 'none',
      height: `${minheight}px`,
      minHeight: `${minheight}px`,
      borderRadius: '8px 8px 0px 0px',
      border: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.common.white,
      gap: '8px',
      '&.Mui-selected': {
        background: theme.palette.blue[500],
        color: theme.palette.common.white,
      },
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
  })
)
