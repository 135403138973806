import BaseModal from '@components-composite/modals/BaseModal'
import { useConfirmDeleteStore } from '@features/alerts/store/alerts-confirm-delete-modal-store'
import Box from '@mui/material/Box'
import { Backdrop, Button } from '@mui/material'
import { useAlertsActions } from '@features/alerts/hooks/use-alerts-actions'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

function RuleConfirmDeleteModal() {
  const { isOpen, setOpen, rulesIds, setRulesIds } = useConfirmDeleteStore()
  const { handleDeleteRule, isLoading } = useAlertsActions()

  const handleCloseModal = () => {
    setRulesIds([])
    setOpen(false)
  }

  const handleDelete = async () => {
    await handleDeleteRule(rulesIds)
    handleCloseModal()
  }
  return (
    <BaseModal onBgClick={handleCloseModal} show={isOpen}>
      <Box style={{ position: 'relative' }}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this scheduled item?
          <Box>This action cannot be undone.</Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleDelete}
            type="button"
          >
            Yes, Proceed
          </Button>
          <Button variant="text" onClick={handleCloseModal} type="button">
            No, Cancel
          </Button>
        </DialogActions>
        <Backdrop
          sx={(theme) => ({
            color: '#fff',
            zIndex: theme.zIndex.drawer + 2,
            position: 'absolute',
          })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </BaseModal>
  )
}

export default RuleConfirmDeleteModal
