import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import CustomTextField from '@forms/fields/custom-text-field'
import { EngineCallback, ValueObject } from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormAccountProfileInterface from '@lib/interfaces/form/form-account-profile.interface'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import PhoneNumber from '@components-simple/phone-number/PhoneNumber'
import buildDefaultFormData from './data'
import { Button } from '@mui/material'
import { useUser } from '@features/authentication'
import { OAuthProvider } from '@features/authentication'

interface Props {
  defaultValues?: ValueObject<FormAccountProfileInterface>
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
  onSubmit: EngineCallback<FormAccountProfileInterface>
}

function AccountProfileForm({
  defaultValues,
  errorGroups,
  onSubmit,
  loading,
}: Props) {
  const user = useUser()
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )

  const {
    isFormDisabled,
    register,
    setValue,
    submit,
    formState,
    globalFormError,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading)
  const provider = new OAuthProvider(user?.sub as string)

  const isSSO = provider.isSSO

  const [phone, setPhone] = useState<string>(
    String(currentForm.phoneNumber.value ?? '')
  )
  // for testing - pretending that we are a new user
  // const [phone, setPhone] = useState<string>('');
  useEffect(() => {
    setValue('phoneNumber', phone)
  }, [phone])
  FormHelper.fillErrors(formState.errors, currentForm)
  return (
    <form
      className={clsx('wrap-1603976359902 jsProfileForm', {
        formDisabled: isFormDisabled,
      })}
      onSubmit={submit}
    >
      <input
        type="hidden"
        id="id_formPhoneNumber"
        {...register(
          currentForm.phoneNumber.name,
          currentForm.phoneNumber.validationRules
        )}
      />
      {isSSO && (
        <input
          type="hidden"
          id="id_formAccountName"
          {...register(
            currentForm.userName.name,
            currentForm.userName.validationRules
          )}
          name={currentForm.userName.name}
          value={String(currentForm.userName.value)}
        />
      )}

      <span className="subtitle">Basic Information</span>
      <PreloaderSmall top="0" left="140" show={loading} />

      <div className="basicInformationContainer newFormThreeRow">
        <div className="v2StaticTextInput">
          {isSSO ? (
            <CustomTextField
              className="textInput jsAccountName"
              field={currentForm.userName}
              disabled={isSSO || isFormDisabled}
            />
          ) : (
            <CustomTextField
              className="textInput jsAccountName"
              field={currentForm.userName}
              disabled={isFormDisabled}
              {...register(
                currentForm.userName.name,
                currentForm.userName.validationRules
              )}
            />
          )}
        </div>

        <PhoneNumber
          phoneNumber={phone}
          label={currentForm.phoneNumber.label ?? ''}
          onChange={setPhone}
          disabled={isFormDisabled}
        />
      </div>

      {globalFormError ? (
        <div className="formGlobalError">{globalFormError}</div>
      ) : null}

      <Button
        disabled={isFormDisabled}
        className="saveInfo"
        variant="contained"
        type="submit"
        color="primary"
      >
        Save Information
      </Button>
    </form>
  )
}

export default AccountProfileForm
