import React, { FunctionComponent, useState } from 'react'
import { EngineCallback, VIMatrix, VINumberMap, VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import CheckboxControlledVIHead from '@tables/head-vi/CheckboxControlledVIHead'
import CheckboxMenuVIRow from '@tables/rows-vi/CheckboxMenuVIRow'
import CheckboxConstant from '@lib/constants/checkbox.constant'

interface SettingsClickData {
  index: number
  value: boolean
}

interface HeadComponentData {
  data: VIRow
  onSettingsClick: EngineCallback<SettingsClickData>
  columnState: Array<number>
  forceTemplate?: Array<number>
  skipTemplate?: Array<number>
  globalCheckboxState: CheckboxConstant
  onCheckboxGlobalChange: EngineCallback<boolean>
}

interface RowComponentData {
  data: VIRow
  columnState: Array<number>
  forceTemplate?: Array<number>
  menu: Array<string>
  onMenuClick: EngineCallback<OnMenuClickData>
  showMenu: boolean
  onCheckboxRowChange: EngineCallback<VIRow>
  columnDrawMapper?: VINumberMap
}

interface OnMenuClickData {
  chosenMenu: string
  data: ValueInterface
}

interface Props {
  head: VIRow
  rows: VIMatrix
  className?: string
  menu?: Array<string>
  forceTemplate?: Array<number>
  skipTemplate?: Array<number>
  onMenuClick: EngineCallback<OnMenuClickData>
  headComponent?: FunctionComponent<HeadComponentData>
  rowComponent?: FunctionComponent<RowComponentData>
  globalCheckboxState: CheckboxConstant
  onCheckboxGlobalChange: EngineCallback<boolean>
  onCheckboxRowChange: EngineCallback<VIRow>
  columnDrawMapper?: VINumberMap
}

function CheckboxControlledVITable({
  head,
  rows,
  className = '',
  menu = [],
  forceTemplate,
  skipTemplate,
  onMenuClick,
  headComponent = CheckboxControlledVIHead,
  rowComponent = CheckboxMenuVIRow,
  globalCheckboxState,
  onCheckboxGlobalChange,
  onCheckboxRowChange,
  columnDrawMapper = {},
}: Props) {
  const [columnState, setColumnState] = useState(head.map(() => 1))
  const onSettingsClick = (data: SettingsClickData) => {
    const result = [...columnState]
    result[data.index] = +data.value
    setColumnState(result)
  }
  const HeadComponent = headComponent
  const RowComponent = rowComponent
  return (
    <table
      className={`wrap-1602747175897 shrinkLastColumn ${className}`}
      data-testid="jsCheckboxControlledVITable"
    >
      <thead>
        <HeadComponent
          columnState={columnState}
          onSettingsClick={onSettingsClick}
          data={head}
          forceTemplate={forceTemplate}
          skipTemplate={skipTemplate}
          globalCheckboxState={globalCheckboxState}
          onCheckboxGlobalChange={onCheckboxGlobalChange}
        />
      </thead>
      <tbody>
        {rows.map((v: VIRow, index: number) => (
          <RowComponent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data={v}
            menu={menu}
            onMenuClick={onMenuClick}
            columnState={columnState}
            forceTemplate={forceTemplate}
            onCheckboxRowChange={onCheckboxRowChange}
            columnDrawMapper={columnDrawMapper}
            showMenu
          />
        ))}
      </tbody>
    </table>
  )
}

export default CheckboxControlledVITable
