import { Button } from '@mui/material'

const InstallCliLink = () => {
  return (
    <Button
      href="https://docs.elastio.com/docs/cli/installation"
      target="_blank"
      variant="text"
      color="primary"
      sx={{ textTransform: 'capitalize' }}
    >
      Install CLI
    </Button>
  )
}

export default InstallCliLink
