import { RETENTION_POLICY_LIST } from 'ui-v2/src/lib/constants/retention-policy.constant'
import RetentionPolicyFactory from 'ui-v2/src/lib/models/settings/retention-policy/retention-policy.factory'
import {
  PaginatedInterface,
  RetentionPolicyListTableDataAttrs,
  RetentionPolicyListTableRow,
  RetentionPolicyModel,
} from 'ui-v2/src/lib/models/settings/retention-policy/retention-policy.model'

export class RetentionPolicyTableData {
  readonly #retentionPolicy: PaginatedInterface<RetentionPolicyModel>
  // readonly #scanOnlyReleaseJanuary: boolean // TODO: not needed

  constructor(parameters: RetentionPolicyListTableDataAttrs) {
    this.#retentionPolicy = parameters.retentionPolicy
    // this.#scanOnlyReleaseJanuary = parameters.scanOnlyReleaseJanuary
  }

  getRetentionPolicyList = (): Array<RetentionPolicyListTableRow> => {
    const defaultRetentionPolicy = RETENTION_POLICY_LIST.defaultRetentionPolicy

    return this.#retentionPolicy.list.map((retentionPolicy) => {
      const retentionRefactorPolicy =
        RetentionPolicyFactory.buildRetentionPolicyFromGrpcForList({
          ...defaultRetentionPolicy,
          ...retentionPolicy,
        })

      return new RetentionPolicyListTableRow({
        id: retentionPolicy.id,
        policyName: retentionPolicy.policyName,
        status: retentionRefactorPolicy.status,
        retention: retentionRefactorPolicy.retention,
        isPaused: !!retentionPolicy.policyStatus,
        action: '',
        retentionPolicyList: this.#retentionPolicy.list,
      })
    })
  }
}
