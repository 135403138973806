import React, { memo } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import ReportIcon from '@mui/icons-material/Report'
import { ReportBlock, ReportStatus } from './types'

function ReportsBlock({ reportType, reportStatus }: ReportBlock) {
  const { palette } = useTheme()

  const isInfected = reportStatus === ReportStatus.INFECTED

  const color = {
    color: isInfected ? palette.red[400] : palette.green[400],
  }
  const Icon = isInfected ? <ReportIcon sx={color} /> : <CheckIcon sx={color} />

  const label = isInfected ? 'Infected' : 'Passed'

  return (
    <Box
      data-testid="reports-block"
      marginY="5px"
      display="flex"
      alignItems="center"
      gap="5px"
      fontSize="14px"
      fontWeight="400"
      lineHeight="150%"
    >
      {Icon}
      {reportType}: {label}
    </Box>
  )
}

export default memo(ReportsBlock)
