import Typography from '@mui/material/Typography'
import {
  DateOrTimestamp,
  DateTimeTooltip,
} from '@components-simple/date-time-tooltip'
import React from 'react'
import { Box } from '@mui/material'
import {
  StyledInfoItemSuppression,
  StyledInfoLabelSuppression,
} from '@features/threat-suppression/styles'

interface Props {
  threatName: string
  location: string
  firstDetectedAt: DateOrTimestamp
}

function ThreatSuppressionInfo({
  threatName,
  location,
  firstDetectedAt,
}: Props) {
  return (
    <>
      <Typography variant="h6" marginBottom={2}>
        Threat Suppression
      </Typography>
      <Typography
        variant="body1"
        marginBottom={2}
        sx={{
          fontSize: '14px',
        }}
      >
        <StyledInfoItemSuppression>
          <StyledInfoLabelSuppression>Location:</StyledInfoLabelSuppression>
          <Box
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {location}
          </Box>
        </StyledInfoItemSuppression>
        <StyledInfoItemSuppression>
          <StyledInfoLabelSuppression>Threat Name:</StyledInfoLabelSuppression>
          <Box>{threatName}</Box>
        </StyledInfoItemSuppression>
        <StyledInfoItemSuppression>
          <StyledInfoLabelSuppression>Detected:</StyledInfoLabelSuppression>
          <Box>
            <DateTimeTooltip
              placement="top-start"
              direction="row"
              showTimeFirst
              date={firstDetectedAt}
            />
          </Box>
        </StyledInfoItemSuppression>
      </Typography>
    </>
  )
}

export default ThreatSuppressionInfo
