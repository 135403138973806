/* eslint-disable import/no-extraneous-dependencies */
import { CalculateBackupCoverageStats } from 'blues-corejs/dist'
import { TenantStatisticsClient } from '@lib/clients/tenant-statistics'
import { useEffect, useState } from 'react'
import { StrHelper, SystemHelper } from '@lib/helpers'
import { VaultStatistics } from 'blues-corejs/dist/models/tenant_statistics/types'
import NumHelper from '@lib/helpers/num.helper'
import { GetStorageReductionMetricsResponse } from 'blue-stack-libs/krabs-grpc-libs/js/krabs/krabs_pb'

const tenantStatisticsClient = new TenantStatisticsClient()
const INITIAL_STORAGE_REDUCTION: Array<VaultStatistics> = []

const transformData = (
  data: GetStorageReductionMetricsResponse.AsObject
): Array<VaultStatistics> => {
  return data.vaultMetricsList.map((vaultMetric) => {
    const {
      awsAccountId,
      region,
      vaultName,
      bytesUploaded,
      bytesIncluded,
      totalBytesIngested,
      newBytesIngested,
      bytesCompressedAndEncrypted,
    } = vaultMetric
    return {
      accountId: awsAccountId,
      region,
      vaultName,
      bytesUploaded,
      bytesIncluded,
      totalBytesIngested,
      bytesCompressedAndEncrypted,
      newBytesIngested,
    }
  })
}

export const useFetchStorageReductionMetrics = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [storageReductionMetrics, setStorageReductionMetrics] = useState<
    Array<VaultStatistics>
  >(INITIAL_STORAGE_REDUCTION)

  const { storageReduction, storageSize } = new CalculateBackupCoverageStats({
    vaultStatistics: storageReductionMetrics,
  }).execute()

  useEffect(() => {
    let isMounted = true
    const fetchStorageReductionMetrics = async () => {
      try {
        setIsLoading(true)

        const response =
          await tenantStatisticsClient.getStorageReductionMetrics()

        if (isMounted) {
          setStorageReductionMetrics(transformData(response))
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'awsStatsClient.getBackupCoverage: failed to fetch Backup coverage count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchStorageReductionMetrics()

    return () => {
      isMounted = false
      setStorageReductionMetrics(INITIAL_STORAGE_REDUCTION)
      setIsLoading(false)
    }
  }, [])

  const [optimizedBackupSize, optimizedBackupSizeLabel] =
    StrHelper.realFileSize(storageSize, '0').split(' ')

  const optimizedSizeLabel = optimizedBackupSizeLabel ?? ''

  const optimizedSizeInfo = {
    value: `${String(optimizedBackupSize)} ${optimizedSizeLabel}`,
    label: 'Optimized size',
  }

  const storageReductionInfo = {
    value: `${NumHelper.roundTo(storageReduction, 2)}%`,
    label: 'Storage reduction',
  }

  return {
    isLoading,
    optimizedSizeInfo,
    storageReductionInfo,
  }
}
