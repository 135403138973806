import React, { memo } from 'react'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import PaginationBlock from '@components-simple/pagination-block/PaginationBlock'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import ControlledVITable from '@tables/ControlledVITable'
import { ASSETS_POLICY_RETENTION_HEAD } from '@tables/core/table-constants'
import TableFactory from '@lib/factories/table.factory'
import { ASSETS_POLICY_RETENTION_MAPPER } from '@tables/core/table-vi-draw-mappers'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import SearchForm from '@forms/search/search.form'
import InfoHeader from '@components-composite/info-header/InfoHeader'
import usePolicyRetentionAssetsData, {
  PolicyRetentionAssetsProps,
} from './usePolicyRetentionAssetsData'

function PolicyRetentionAssets({
  currentPolicy,
  isInfoMode,
}: PolicyRetentionAssetsProps) {
  const { isLoading, data, onPaginationChange, onSearchChange } =
    usePolicyRetentionAssetsData(currentPolicy)

  const isAssetsListNotEmpty = !isLoading && data.assetsList.length > 0

  return (
    <div className="wrap-1660210186779">
      {isInfoMode && (
        <InfoHeader
          type="info"
          description="The list of Assets is being updated after Retention policy changes. This might take a couple of minutes."
          className="mb20"
        />
      )}

      {isLoading ? (
        <>
          <div className="emptyResultBlock">Gathering information.</div>
          <PreloaderBlock show />
        </>
      ) : (
        <div className="assetsContainer">
          {isInfoMode && <div className="overlayOpacity"></div>}

          <div className="searchTopContainer">
            <div className="searchFormWrap">
              <SearchForm
                placeholder="Search..."
                errorGroups={[ErrorGroupConstants.EMPTY]}
                loading={isLoading}
                variant="whiteFilterSearchBlackIcon"
                defaultValues={{
                  search: '',
                }}
                onSubmit={(formData: FormSearchInterface) => {
                  onSearchChange(formData.search)
                }}
              />
            </div>
            {isAssetsListNotEmpty && (
              <div className="paginationWrap">
                <PaginationBlock
                  disabled={isLoading}
                  pagination={data.pagination}
                  onChange={onPaginationChange}
                />
                <PreloaderSmall top={14} left={0} show={isLoading} />
              </div>
            )}
          </div>

          {isAssetsListNotEmpty ? (
            <ControlledVITable
              className="tableVIHideSettings tableUpgrade striped"
              head={ASSETS_POLICY_RETENTION_HEAD}
              rows={TableFactory.assetsPolicyRetentionTable(data.paginatedData)}
              columnDrawMapper={ASSETS_POLICY_RETENTION_MAPPER()}
              onMenuClick={() => null}
            />
          ) : (
            <div className="emptyResultBlock">No Assets Yet!</div>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(PolicyRetentionAssets)
