import { MultiplySelectProps } from '@features/elements/multiply-select/types'
import React, { useMemo, useState } from 'react'
import StrHelper from '../../../lib/helpers/str.helper'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import {
  Button,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import RemoveIcon from '@inline-img/general/remove-icon'
import BlueCheckIcon from '@inline-img/general/blue-check-icon'
import SearchIcon from '@inline-img/general/search-icon'

const LABEL_FOR_PLURAL = [
  'Account',
  'Region',
  'Misconfiguration Type',
  'OVA Account',
  'OVA Backup Provider',
]

function MultiplySelect({
  label,
  possible,
  selected = [],
  onChange,
  disabled = false,
  placeholder = '',
  hint = '',
  countLabel = 'items selected',
  menuWidth = '350px',
  title,
  withSearch = true,
  isOpen = false,
  fieldName,
  className = '',
}: MultiplySelectProps) {
  const isAllSelected = possible.length === selected.length
  const [selectedCount, setSelectedCount] = useState<number>(0)

  const onChangeInner = (e: SelectChangeEvent<Array<string>>, child: any) => {
    const selectedValue = e.target.value as Array<string>
    const currentTargetId = child ? child.props.id : ''

    const result: Array<string> = []

    if (currentTargetId === 'selectAllItems') {
      if (!isAllSelected) {
        possible.forEach((el) => {
          if (el !== undefined) {
            result.push(el)
          }
        })
      }
    } else {
      const allResult = selectedValue.map((val) =>
        possible.find((v) => v === val)
      )
      allResult.forEach((el) => {
        if (el !== undefined) {
          result.push(el)
        }
      })
    }

    setSelectedCount(result.length)

    e.target.value = result

    onChange(e, fieldName)
  }

  const [searchText, setSearchText] = useState<string>('')

  const displayedOptions = useMemo(() => {
    if (searchText.trim().length > 0) {
      return possible.filter((option) =>
        StrHelper.isContainText(option, searchText)
      )
    }

    return possible
  }, [searchText])

  //button All
  const handleAll = () => {
    setSearchText('')
  }

  // button Done
  const [open, setOpen] = useState<boolean>(isOpen)
  const handleOpen = () => {
    setOpen(true)
    setSearchText(' ')
    setSelectedCount(selected.length)
  }
  const handleClose = () => {
    setSearchText('')
    setOpen(false)
  }

  const getCountLabel = (items: Array<string>) => {
    if (items.length <= 1) {
      return countLabel
    }
    return LABEL_FOR_PLURAL.includes(countLabel) ? countLabel + 's' : countLabel
  }

  return (
    <div className="wrap-1653546666509 newMaterialSelects">
      {label && (
        <div className="viSelectLabel jsLabel">
          {label}
          {hint && <QuestionHint variant="inText" text={hint} />}
        </div>
      )}
      <Select
        className={className}
        title={title}
        id="select"
        // @ts-ignore: TODO - Fix TypeScript issue: "placeholder" is not assignable to type SelectProps<string[]>.
        placeholder={placeholder}
        disabled={disabled}
        multiple
        variant="outlined"
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoFocus: false,
          PaperProps: {
            style: {
              width: `${menuWidth}`,
              maxHeight: '410px',
            },
          },
        }}
        value={selected}
        onChange={onChangeInner}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        renderValue={(values) => (
          <div>
            {
              <>
                <span>{(values as Array<string>).length}</span>
                <span>&nbsp;{getCountLabel(values)}</span>
              </>
            }
          </div>
        )}
      >
        <div className="multipleListInfo">{selectedCount} selected</div>

        <Button
          onClick={handleAll}
          variant="contained"
          className="sizeS multilpleListTopBtn"
          id="selectAllItems"
        >
          {isAllSelected ? (
            <>
              <RemoveIcon />
              <span>Clear</span>
            </>
          ) : (
            <>
              <BlueCheckIcon />
              <span>All</span>
            </>
          )}
        </Button>

        {withSearch && (
          <ListSubheader className="multipleListTopInfo" value={'texttexttext'}>
            <div className="v2StaticTextInput multipleListSearch">
              <TextField
                // Autofocus on textfield
                autoFocus
                placeholder={placeholder}
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={({ target }) => setSearchText(target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation()
                  }
                }}
              />
            </div>
          </ListSubheader>
        )}

        {displayedOptions.map((option) => (
          <MenuItem
            key={option}
            value={option}
            className="multipleListOption"
            data-testid="menuItem"
          >
            <div className="multipleListOptionText">{option}</div>
          </MenuItem>
        ))}

        {displayedOptions.length < 1 && (
          <MenuItem className="multipleListEmptyPlaceholder">
            <div></div>
          </MenuItem>
        )}

        <div className="wrapBtnDone">
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            className="sizeS btnDone"
          >
            Done
          </Button>
        </div>
      </Select>
    </div>
  )
}

export default MultiplySelect
