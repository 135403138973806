import {
  BASE_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
  USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { ActionCardProps } from './action-card'

export const getOnboardingActionCardsData = (
  isButtonDisabled: boolean
): Array<ActionCardProps> => {
  return [
    {
      title: 'Deploy Elastio',
      description: 'Deploy the Elastio service into an AWS account.',
      buttonText: 'Deploy',
      to: `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE}`,
    },
    {
      title: 'Invite Team Members',
      description: 'Invite your team members to the platform. Get Started',
      buttonText: 'Invite',
      to: `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.USERS_AND_ACCESS}/${USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS.USERS}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_USER}`,
    },
    {
      title: 'Protect Assets',
      description:
        'Protect your EC2 and EBS assets from ransomware with a few clicks.',
      buttonText: 'Protect',
      to: `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.CREATE_POLICY}`,
      disabled: isButtonDisabled,
    },
    {
      title: 'Review the results',
      description:
        'Review the job status of the protection and ransomware analysis.',
      buttonText: 'Review',
      to: `/${BASE_ROUTE_SEGMENTS.MONITOR}`,
      disabled: isButtonDisabled,
    },
  ]
}
