import { ValueInterface, VIRow } from '../engine-types'
import { UserModel } from '../models/user'
import { buildHumanLabel } from './string.helper'

export const getScopeValues = (
  possibleScopes: Array<string>
): Array<ValueInterface> => {
  const result: Array<ValueInterface> = []
  possibleScopes.forEach((value: string) => {
    const [label, action] = value.split(':').map((v) => v.trim())
    const index = result.findIndex((v) => v.name === label)
    // add new
    if (index === -1) {
      result.push({
        name: label ?? '',
        label: buildHumanLabel(label ?? ''),
        options: [
          {
            name: value,
            label: buildHumanLabel(action ?? ''),
            value: false,
          },
        ],
      })
      return
    }
    // add into existing
    result[index]?.options?.push({
      name: value,
      label: buildHumanLabel(action ?? ''),
      value: false,
    })
  })
  return result
}

export const getSelectedScopeValues = (
  selectedScopes: Array<string>,
  possibleScopes: Array<string>
): Array<ValueInterface> => {
  const result: Array<ValueInterface> = getScopeValues(possibleScopes)
  result.forEach((value: ValueInterface) => {
    value.options?.forEach((option: ValueInterface) => {
      if (selectedScopes.includes(option.name)) {
        option.value = true
      }
    })
  })
  return result
}

export const selectedValuesToStrings = (
  scopes: Array<ValueInterface>
): Array<string> => {
  return scopes
    .map(
      (scope) => scope.options?.filter((v) => v.value).map((s) => s.name) ?? []
    )
    .reduce((acc, curVal) => acc.concat(curVal), [])
}

export const isAnySelected = (scopes: VIRow): boolean => {
  return scopes.some((scope) => scope.options?.some((opt) => opt.value))
}

export const isUserHasAnyScopes = (user: UserModel, scopes: VIRow): boolean => {
  return scopes.some((scope) => user.role.scopesList.includes(scope.name))
}

export const isUserHasAllScopes = (user: UserModel, scopes: VIRow): boolean => {
  return scopes.every((scope) => user.role.scopesList.includes(scope.name))
}
