import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { UsersClient } from 'ui-v2/src/lib/clients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useActivateUserMutation() {
  const usersClient = useGrpcClient(UsersClient)
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async (userId: string) => {
      if (!usersClient) {
        throw new Error('Users client not initialized')
      }
      return usersClient.activateUser(userId)
    },
    onError: (error) => {
      toast.error(error.message ?? 'Something went wrong while activating user')
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      toast.success('User has been activated')
    },
  })
}
