/* eslint-disable import/no-extraneous-dependencies */
import { usePagination } from '@lib/hooks'
import { useAlertsFilterStore } from '@features/alerts/store/alerts-filter-store'
import { useAlertsListStore } from '@features/alerts/store/alerts-list-store'
import SystemHelper from '@lib/helpers/system.helper'
import { useEffect } from 'react'
import { NotificationsRulesClient } from '@lib/clients/notifications'
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'

const notificationsRulesClient = new NotificationsRulesClient()

export function useFetchAlertsListData() {
  const { ruleSearch } = useAlertsFilterStore()
  const { setLoading, setTableData, tableData } = useAlertsListStore()
  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()

  const handleFetchSuccess = ({
    rulesList,
    nextPageToken,
  }: {
    rulesList: Array<NotificationRule>
    nextPageToken?: string
  }) => {
    setTableData(rulesList)
    updatePageTokenAndFlagFetched(nextPageToken)
  }

  const resetData = () => {
    resetPagination()
  }

  const updateReportsOnRefreshInterval = (
    rulesList: Array<NotificationRule>
  ) => {
    if (rulesList.length === 0) {
      return
    }

    const rulesMap = new Map(tableData.map((rule) => [rule.id, rule]))
    const updatedRulesList: Array<NotificationRule> = []

    rulesList.forEach((rule) => {
      if (rulesMap.has(rule.id)) {
        rulesMap.set(rule.id, rule)
      } else {
        updatedRulesList.push(rule)
      }
    })

    const result = [...updatedRulesList, ...Array.from(rulesMap.values())]

    setTableData(result)
  }

  const initialRequest = async () => {
    setLoading(true)
    try {
      const response = await notificationsRulesClient.getNotificationRulesList({
        search: ruleSearch,
      })
      resetData()

      handleFetchSuccess({
        rulesList: response.rulesList,
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        'useFetchAlertsListData: Error fetching initial alerts list'
      )
    } finally {
      setLoading(false)
    }
  }

  const paginationRequest = async () => {
    if (!pageToken) {
      return
    }

    setLoading(true)
    try {
      const response =
        await notificationsRulesClient.getNotificationRulesListPagination({
          pageToken,
        })

      handleFetchSuccess({
        rulesList: [...tableData, ...response.rulesList],
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        'useFetchAlertsListData: Error fetching alerts list with pagination'
      )
    } finally {
      setLoading(false)
    }
  }

  const fetchOnRefreshInterval = async () => {
    try {
      const response = await notificationsRulesClient.getNotificationRulesList({
        search: ruleSearch,
      })

      updateReportsOnRefreshInterval(response.rulesList)
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        'useFetchAlertsListData: Error fetching interval alerts list with filter'
      )
    }
  }

  useEffect(() => {
    initialRequest()
  }, [ruleSearch])

  return {
    paginationRequest,
    fetchOnRefreshInterval,
  }
}
