import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { DetailsVolumeOutput } from 'blues-corejs/dist/use_cases/list_detailes_volumes_with_threats'
import { TimeFormatConstants } from '@lib/constants'

dayjs.extend(utc)

// TODO: optimize this function?
function prepareThreatsDataForCsv(threatsList: Array<DetailsVolumeOutput>) {
  return threatsList.flatMap(({ threats, volumeAwsId }) =>
    threats.map(({ name, location, firstDetectedAt }) => ({
      volumeId: volumeAwsId,
      threat: name,
      path: location,
      firstDetected: `${dayjs
        .utc(firstDetectedAt)
        .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`,
    }))
  )
}

const THREATS_CSV_TABLE_HEADERS = [
  {
    label: 'Volume ID',
    key: 'volumeId',
  },
  {
    label: 'Threat',
    key: 'threat',
  },
  {
    label: 'Path',
    key: 'path',
  },
  {
    label: 'First Detected',
    key: 'firstDetected',
  },
]

export { prepareThreatsDataForCsv, THREATS_CSV_TABLE_HEADERS }
