import { Close } from '@mui/icons-material'
import { Box, BoxProps, IconButton, Typography } from '@mui/material'

interface DialogHeaderProps extends BoxProps {
  title: string
  handleCloseDialog?: () => void
  iconStart?: string | undefined
}

const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  handleCloseDialog,
  iconStart = undefined,
  ...rest
}) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={2.5}
      pb={0}
      {...rest}
    >
      <Box display={'flex'} alignItems={'center'} gap={2}>
        {iconStart ? <img src={iconStart} alt="drawer header icon" /> : null}
        <Typography variant="h5">{title}</Typography>
      </Box>
      <IconButton edge="end" aria-label="close" onClick={handleCloseDialog}>
        <Close />
      </IconButton>
    </Box>
  )
}

export default DialogHeader
