import React from 'react'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { EngineCallback } from '@lib/engine-types'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import { MIN_VERSION_FOR_RECOVERY_TESTING } from '@lib/constants/integrity-check-constant'
import IconPopover from '@components-simple/icon-popover/IconPopover'
import Box from '@mui/material/Box'
import { EntropyDetectionCheck } from './entropy-detection-check'

interface Props {
  data: IntegrityScanInterface
  onChange: EngineCallback<IntegrityScanInterface>
  integrityCheckEnabled: boolean
  onIntegrityCheckClick: EngineCallback<boolean>
  isRecoveryTestingDisabled: boolean
  isAllOptionsDisabled?: boolean
  entropyDetectionsOptions: {
    isEntropyEnabled: boolean
    onEntropyEnabledChange: (value: boolean) => void
  }
}

const ANTI_RANSOMWARE_TITLE = 'Anti-Ransomware'
const ANTI_RANSOMWARE_INFO_HEADER = 'What is Anti-Ransomware?'
const ANTI_RANSOMWARE_INFO_MESSAGE =
  'Our Anti-Ransomware technology continuously inspects live data and backups for ransomware encryption and malware payloads, ensuring your data is clean, uncompromised, and recoverable.'

const RANSOMWARE_DETECTION_LABEL = 'Ransomware Detection'
const RANSOMWARE_DETECTION_DESCRIPTION =
  'Detects ransomware encryption using and ensemble of ML models. The ransomware models are updated automatically with the latest ransomware by our ransomware specialists.'

const MALWARE_LABEL = 'Malware Scan'
const MALWARE_DESCRIPTION =
  'Scans for malware including Trojans, spyware, adware, crypto-miners, ransomware payloads.  The signatures are updated automatically and on a daily basis.'

const RECOVERABILITY_CHECK_TITLE = 'Recoverability Check'
const RECOVERABILITY_CHECK_INFO_LABEL = 'What is Recoverability Check?'
const RECOVERABILITY_CHECK_INFO_MESSAGE =
  'The recoverability check verifies that your data is application-consistent and recoverable. It inspects the health of the file system to ensure it is not corrupt and identifies snapshots that may fail to restore usable application data. This check is available for EC2 and EBS assets.'

const RECOVERABILITY_CHECK_LABEL = 'Recoverability Check'
const RECOVERABILITY_CHECK_DESCRIPTION =
  'Verifies that the data is application-consistent and recoverable by inspecting the health of the file system. Available for EC2 and EBS assets, it identifies snapshots that may fail to restore usable application data.'

function IntegrityScan({
  data,
  onChange,
  integrityCheckEnabled,
  onIntegrityCheckClick,
  isRecoveryTestingDisabled,
  isAllOptionsDisabled = false,
  entropyDetectionsOptions,
}: Props) {
  return (
    <Box className="wrap-1642159277169 jsIntegrityScan">
      <Box>
        <Box className="addPolicyTitle">
          {ANTI_RANSOMWARE_TITLE}
          <IconPopover
            variant="info"
            header={ANTI_RANSOMWARE_INFO_HEADER}
            message={ANTI_RANSOMWARE_INFO_MESSAGE}
          />
        </Box>

        <V2Checkbox
          disabled={isAllOptionsDisabled}
          checked={data.scanForRansomware}
          onChange={(scanForRansomware) =>
            onChange({
              ...data,
              scanForRansomware,
            })
          }
          label={
            <>
              <Box className="checkboxLabel">{RANSOMWARE_DETECTION_LABEL}</Box>
              <Box className="policiesBlockLabel">
                {RANSOMWARE_DETECTION_DESCRIPTION}
              </Box>
            </>
          }
        />
        <V2Checkbox
          disabled={isAllOptionsDisabled}
          checked={data.scanForMalware}
          onChange={(scanForMalware) =>
            onChange({
              ...data,
              scanForMalware,
            })
          }
          label={
            <>
              <Box className="checkboxLabel">{MALWARE_LABEL}</Box>
              <Box className="policiesBlockLabel">{MALWARE_DESCRIPTION}</Box>
            </>
          }
        />
      </Box>
      <Box>
        <Box className="addPolicyTitle">
          {RECOVERABILITY_CHECK_TITLE}
          <IconPopover
            variant="info"
            header={RECOVERABILITY_CHECK_INFO_LABEL}
            message={RECOVERABILITY_CHECK_INFO_MESSAGE}
          />
        </Box>
        <Box className="questionBlock">
          <V2Checkbox
            checked={integrityCheckEnabled}
            onChange={(value) => onIntegrityCheckClick(value)}
            disabled={isRecoveryTestingDisabled || isAllOptionsDisabled}
            label={
              <>
                <Box className="checkboxLabel">
                  {RECOVERABILITY_CHECK_LABEL}
                </Box>
                <Box className="policiesBlockLabel">
                  {RECOVERABILITY_CHECK_DESCRIPTION}
                </Box>
              </>
            }
          />
          {isRecoveryTestingDisabled && (
            <QuestionHint
              text={`This feature is available in Elastio versions ${MIN_VERSION_FOR_RECOVERY_TESTING} and higher. Upgrade to enable it.`}
            />
          )}
        </Box>
        <EntropyDetectionCheck
          integrityScanOptions={data}
          {...entropyDetectionsOptions}
        />
      </Box>
    </Box>
  )
}

export default IntegrityScan
