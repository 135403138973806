import React from 'react'
import { Box, styled, Typography } from '@mui/material'

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  wordBreak: 'break-word',
})

export function ThreatPathCell({ path }: { path: string }) {
  return (
    <Box whiteSpace="pre-line" minWidth="400px">
      <StyledTypography>{path}</StyledTypography>
    </Box>
  )
}
