import { useMemo } from 'react'
import { useGeneratedReportListQuery } from 'ui-v2/src/hooks/queries/reports/generated-report-list'

import { HistoryTableData } from 'ui-v2/src/lib/use-cases/reports'
import { getHistoryColumns } from './column-config'
import Filters from './filters'
import { Stack } from '@mui/material'

import { useGeneratedReportListFilters } from 'ui-v2/src/hooks/filters/reports/generated-report-list'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'

const History = () => {
  const { startDate, endDate, filters, setPageSize, setPageToken } =
    useGeneratedReportListFilters()

  const { data: historyList, isLoading } = useGeneratedReportListQuery(filters)

  const data = useMemo(() => {
    if (!historyList) {
      return undefined
    }

    return new HistoryTableData({
      generatedReports: historyList.generatedReportList,
    }).getGeneratedReportsData()
  }, [historyList])

  const columns = useMemo(getHistoryColumns, [])

  return (
    <Stack spacing={1.5}>
      <Filters startDate={startDate} endDate={endDate} />
      <CursorTable
        checkable={false}
        data={data}
        columns={columns}
        isLoading={isLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={{
          nextPageToken: historyList?.pageToken,
          prevPageToken: '',
        }}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default History
