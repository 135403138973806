/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, createContext, useMemo, useContext } from 'react'
import { Nullable } from '@lib/engine-types'
import { CrumbsType, titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'

type LayoutContextType = {
  breadcrumbsPaths: Nullable<Array<CrumbsType>>
  setBreadcrumbsPaths: React.Dispatch<
    React.SetStateAction<Nullable<Array<CrumbsType>>>
  >
  headerTitle: Nullable<React.ReactNode>
  setHeaderTitle: React.Dispatch<
    React.SetStateAction<Nullable<React.ReactNode>>
  >
  currentCrumbTitle: Nullable<string>
  setCurrentCrumbTitle: React.Dispatch<React.SetStateAction<Nullable<string>>>
  isDataLoading: boolean
  setDataLoading: React.Dispatch<React.SetStateAction<boolean>>
  resetBreadCrumbs: boolean
  setResetBreadCrumbs: React.Dispatch<React.SetStateAction<boolean>>
}

export const Layout = createContext<LayoutContextType>({} as LayoutContextType)

const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [breadcrumbsPaths, setBreadcrumbsPaths] =
    useState<Nullable<Array<CrumbsType>>>(null)
  const [headerTitle, setHeaderTitle] =
    useState<Nullable<React.ReactNode>>(null)
  const [currentCrumbTitle, setCurrentCrumbTitle] =
    useState<Nullable<string>>(null)
  const [isDataLoading, setDataLoading] = useState(false)
  const [resetBreadCrumbs, setResetBreadCrumbs] = useState(false)

  const layoutContextValue: LayoutContextType = useMemo(
    () => ({
      breadcrumbsPaths,
      setBreadcrumbsPaths,
      headerTitle,
      setHeaderTitle,
      currentCrumbTitle,
      setCurrentCrumbTitle,
      isDataLoading,
      setDataLoading,
      resetBreadCrumbs,
      setResetBreadCrumbs,
    }),
    [
      breadcrumbsPaths,
      headerTitle,
      currentCrumbTitle,
      isDataLoading,
      resetBreadCrumbs,
    ]
  )

  return (
    <Layout.Provider
      value={{
        breadcrumbsPaths: layoutContextValue.breadcrumbsPaths,
        setBreadcrumbsPaths: layoutContextValue.setBreadcrumbsPaths,
        headerTitle: layoutContextValue.headerTitle,
        setHeaderTitle: layoutContextValue.setHeaderTitle,
        currentCrumbTitle: layoutContextValue.currentCrumbTitle,
        setCurrentCrumbTitle: layoutContextValue.setCurrentCrumbTitle,
        isDataLoading: layoutContextValue.isDataLoading,
        setDataLoading: layoutContextValue.setDataLoading,
        resetBreadCrumbs: layoutContextValue.resetBreadCrumbs,
        setResetBreadCrumbs: layoutContextValue.setResetBreadCrumbs,
      }}
    >
      {children}
    </Layout.Provider>
  )
}
export function useLayoutContext() {
  const context = useContext(Layout)
  if (!context) {
    throw new Error('useLayoutContext must be used within an LayoutProvider')
  }
  return context
}

export default LayoutProvider

export function createBreadcrumbs(paths: Array<string>) {
  return paths.map((path) => ({
    href: path,
    text: titlesForBreadcrumb(path) ?? '',
  }))
}
