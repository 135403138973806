import { useManagementView } from '@features/DashboardV4/common/use-management-view'
import { EmptyFunc } from '@lib/constants/app.constant'
import { Box, Divider } from '@mui/material'
import React from 'react'
import type { StatisticRowType } from './statistic-row'
import StatisticRow from './statistic-row'

interface CommonStatisticOwnProps {
  rows: Array<StatisticRowType>
}

function CommonStatistic({ rows }: CommonStatisticOwnProps) {
  const { isRecoveryView } = useManagementView()

  return (
    <Box className="common-statistic" display="flex" flexDirection="column">
      {rows.map((row, index) => {
        const shouldRenderDivider = isRecoveryView && index !== rows.length - 1

        return (
          <React.Fragment key={index}>
            <StatisticRow
              key={row.label}
              assets={row.assets}
              assetsWithBackups={row.assetsWithBackups}
              label={row.label}
              onRowClick={row.onRowClick || EmptyFunc}
            />
            {shouldRenderDivider && <Divider orientation="vertical" flexItem />}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default CommonStatistic
