/* eslint-disable import/no-extraneous-dependencies */
import {
  AfterOccurrences,
  Custom,
  Daily,
  Monthly,
  Never,
  OnDate,
  Weekly,
  EveryDay,
  EveryWeek,
  EveryMonth,
} from 'blues-corejs/dist/models/reports/report-schedule/schedule'

import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule/report-schedule'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
import {
  CustomScheduleState,
  RepeatPeriod,
  ReportEnd,
} from 'ui-v2/src/lib/models/reports'
import { getDayNameAsString } from 'ui-v2/src/lib/helpers/reports/get-day-name'
import { getMonthOptions } from 'ui-v2/src/lib/constants/reports.constant'

import { mapBackendFormatCodesToUI } from './map-backend-format-codes-to-ui'
import { DEFAULT_TIME_ZONE } from 'ui-v2/src/lib/constants/time.constant'
import {
  extractAllReportTypesAndParams,
  getFirstKey,
} from './extract-all-report-types-and-params'

export function getFirstNestedKey<T>(obj: T, targetKey: string) {
  if (!obj || typeof obj !== 'object') {
    return undefined
  }

  const queue: Array<{ currentObj: any }> = [{ currentObj: obj }]

  while (queue.length > 0) {
    const { currentObj } = queue.shift()!

    for (const key in currentObj) {
      if (
        key === targetKey &&
        typeof currentObj[key] === 'object' &&
        currentObj[key] !== null
      ) {
        const nestedKeys = Object.keys(currentObj[key])
        return nestedKeys.length > 0 ? nestedKeys[0] : undefined
      }

      if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
        queue.push({ currentObj: currentObj[key] })
      }
    }
  }

  return undefined
}

export const getMomentWithTimezone = (startsOn: {
  year: number
  month: number
  day: number
}): Dayjs => {
  const tz = DEFAULT_TIME_ZONE().value
  const date = dayjs(
    `${startsOn.year}-${startsOn.month}-${startsOn.day}`,
    'YYYY-M-D'
  )

  return date.tz(tz).startOf('day')
}

export function getDefaultValues(data: ReportSchedule) {
  let frequency = 'Daily'

  const scheduleMode = data?.schedule.mode
  const startsOn = data?.schedule.startsOn

  const rangeOfData = getFirstNestedKey(data?.reportKindWithParams, 'period')

  const initialCalendarValue = getMomentWithTimezone(startsOn)

  const inputDate = dayjs(initialCalendarValue)
  const dayOfMonth = inputDate.date()

  const customSchedule: CustomScheduleState = {
    dateValue: null,
    daysValue: null,
    endValue: ReportEnd.NEVER,
    everyValue: 1,
    monthsValue: '',
    occurrencesValue: null,
    periodValue: RepeatPeriod.DAY,
  }
  let valiCustomSchedule = false
  if (scheduleMode instanceof Daily) {
    frequency = 'Daily'
  } else if (scheduleMode instanceof Weekly) {
    frequency = `Weekly_${inputDate.format('dddd')}`
  } else if (scheduleMode instanceof Monthly) {
    frequency = `Monthly_${dayOfMonth > 28 ? 0 : dayOfMonth}`
  } else if (scheduleMode instanceof Custom) {
    valiCustomSchedule = true
    if (scheduleMode?.expirationMode) {
      if (scheduleMode?.expirationMode instanceof Never) {
        customSchedule.endValue = ReportEnd.NEVER
      } else if (scheduleMode?.expirationMode instanceof OnDate) {
        customSchedule.endValue = ReportEnd.ON
        customSchedule.dateValue = getMomentWithTimezone(
          scheduleMode?.expirationMode.date
        )
      } else if (scheduleMode?.expirationMode instanceof AfterOccurrences) {
        customSchedule.endValue = ReportEnd.AFTER
        customSchedule.occurrencesValue =
          scheduleMode?.expirationMode.afterOccurrences
      }
    }

    if (scheduleMode?.repeatMode) {
      customSchedule.everyValue = scheduleMode?.repeatMode?.stride || 1
      if (scheduleMode?.repeatMode instanceof EveryDay) {
        customSchedule.periodValue = RepeatPeriod.DAY
      } else if (scheduleMode?.repeatMode instanceof EveryWeek) {
        customSchedule.periodValue = RepeatPeriod.WEEK
        customSchedule.daysValue =
          getDayNameAsString(scheduleMode?.repeatMode?.day) || ''
      } else if (scheduleMode?.repeatMode instanceof EveryMonth) {
        customSchedule.periodValue = RepeatPeriod.MONTH
        const scheduleDayOfMonth = scheduleMode?.repeatMode?.dayOfMonth
        const monthOptions = getMonthOptions()
        const monthsValue =
          scheduleDayOfMonth > 1 ? monthOptions[1] : monthOptions[0]
        customSchedule.monthsValue = monthsValue?.value || ''
      }
    }

    frequency = 'Custom'
  }

  const key = getFirstKey(data?.reportKindWithParams)

  // @ts-ignore
  const { formatsList = [] } = data?.reportKindWithParams?.[key as string] || {}

  const formatsMappedToUI = mapBackendFormatCodesToUI(
    key as string,
    formatsList
  )

  const schedule = data?.schedule

  const scheduleStartDate = dayjs()
    .set('year', schedule?.startsOn?.year)
    .set('month', schedule?.startsOn?.month - 1)
    .set('date', schedule?.startsOn?.day)

  const { reportParams } = extractAllReportTypesAndParams(
    data?.reportKindWithParams as any
  )

  return {
    name: data?.name,
    description: data?.description,
    frequency: frequency,
    rangeOfData: rangeOfData,
    scheduleStartDate: scheduleStartDate.toDate(),
    ovaAccounts: reportParams?.ovaAccountIdsList ?? [],
    backupProvider:
      reportParams?.ovaBackupProvidersList?.map((provider: any) =>
        provider?.toString()
      ) ?? [],
    recipients: data?.recipientIds,
    timezone: data?.timezone,
    customSchedule: valiCustomSchedule ? customSchedule : null,
    format: formatsMappedToUI,
  }
}
