import { AssetsFilter as GrpcAssetsFilter } from '@lib/clients/grpc-imports'
import { buildTagsFilter } from '@lib/clients/entropy-detection/common/tags-filter'
import type { AssetsFilter } from './types'

export function buildAssetsFilter(params: AssetsFilter): GrpcAssetsFilter {
  const filter = new GrpcAssetsFilter()

  if (params.hasReplicatedBackups?.length) {
    filter.setHasReplicatedBackupsList(params.hasReplicatedBackups)
  }

  if (params.accounts?.length) {
    filter.setAccountsList(params.accounts)
  }

  if (params.regions?.length) {
    filter.setRegionsList(params.regions)
  }

  if (params.includeTags?.and.length || params.includeTags?.or.length) {
    filter.setIncludeTags(buildTagsFilter(params.includeTags))
  }

  if (params.excludeTags?.and.length || params.excludeTags?.or.length) {
    filter.setExcludeTags(buildTagsFilter(params.excludeTags))
  }

  return filter
}
