import React, { CSSProperties, memo, ReactNode } from 'react'
import { EngineCallback, VIRowReadonly } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import ObjHelper from '@lib/helpers/obj.helper'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import { MenuItem, Select } from '@mui/material'

interface Props {
  possible: VIRowReadonly
  selected?: ValueInterface
  onChange: EngineCallback<ValueInterface>
  label?: string
  disabled?: boolean
  placeholder?: string
  getLabel?: (v: ValueInterface) => ReactNode
  hint?: string
  disabledItems?: Array<string>
  labelStyle?: CSSProperties
}

function ViSelect({
  label,
  possible,
  selected = { name: '' },
  onChange,
  disabled = false,
  placeholder = '',
  getLabel = (v) => <>{v.label ?? v.name}</>,
  hint = '',
  disabledItems = [],
  labelStyle = {},
}: Props) {
  const onChangeInner = (e: any) => {
    const selectedValue = e.target.value
    const result = possible.find((v) => v.name === selectedValue)
    if (result) {
      onChange(ObjHelper.cloneDeep(result))
    }
  }

  return (
    <div className="wrap-1619615447654 newMaterialSelects jsViSelect">
      {label && (
        <div className="viSelectLabel jsLabel" style={labelStyle}>
          {label}
          {hint && <QuestionHint variant="inText" text={hint} />}
        </div>
      )}
      <Select
        // @ts-ignore: TODO - Fix TypeScript issue: "placeholder" is not assignable to type SelectProps<string[]>.
        placeholder={placeholder}
        disabled={disabled}
        variant="outlined"
        IconComponent={() => (
          <SelectArrowIcon className="MuiSvgIcon-root MuiSelect-icon" />
        )}
        defaultValue={selected.name}
        value={selected.name}
        onChange={onChangeInner}
      >
        {possible.map((row) => (
          <MenuItem
            key={`${row.name}${row.value}${row.label}`}
            value={row.name}
            data-testid="menuItem"
            disabled={Boolean(disabledItems?.includes(row.name))}
          >
            {getLabel(row)}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default memo(ViSelect)
