import {
  ReportPeriodSchedule,
  CurrentMonth,
  CurrentDay,
  CurrentWeek,
  CurrentYear,
  LastDay,
  CurrentQuarter,
  LastMonth,
  LastWeek,
  LastYear,
  LastQuarter,
} from '@lib/clients/grpc-imports'

export function createReportPeriodSchedule(
  rangeOfData: string
): ReportPeriodSchedule {
  const reportPeriodSchedule = new ReportPeriodSchedule()

  switch (rangeOfData) {
    case 'currentDay':
      reportPeriodSchedule.setCurrentDay(new CurrentDay())
      break
    case 'lastDay':
      reportPeriodSchedule.setLastDay(new LastDay())
      break
    case 'currentWeek':
      reportPeriodSchedule.setCurrentWeek(new CurrentWeek())
      break
    case 'lastWeek':
      reportPeriodSchedule.setLastWeek(new LastWeek())
      break
    case 'currentMonth':
      reportPeriodSchedule.setCurrentMonth(new CurrentMonth())
      break
    case 'lastMonth':
      reportPeriodSchedule.setLastMonth(new LastMonth())
      break
    case 'currentQuarter':
      reportPeriodSchedule.setCurrentQuarter(new CurrentQuarter())
      break
    case 'lastQuarter':
      reportPeriodSchedule.setLastQuarter(new LastQuarter())
      break
    case 'currentYear':
      reportPeriodSchedule.setCurrentYear(new CurrentYear())
      break
    case 'lastYear':
      reportPeriodSchedule.setLastYear(new LastYear())
      break
    default:
      throw new Error(`Invalid range of data: ${rangeOfData}`)
  }

  return reportPeriodSchedule
}
