import { createColumn } from '@components/table'

export const getProvidersColumns = () => {
  return [
    createColumn<any>('account', {
      header: 'Backups Account',
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
    createColumn<any>('regions', {
      header: 'Provider',
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
  ]
}
