/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { MultiSelectSearch } from '@components/components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/multi-select-search'
import type { OptionType } from '@components/components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/multi-select-search'

import {
  useToolbarFiltersState,
  useToolbarFiltersActions,
} from '../toolbar-filters-store'

interface Props {
  threatsNames: Set<string>
}

function buildThreatsOptions(threatsNames: Set<string>): Array<OptionType> {
  return Array.from(threatsNames).map((threatName) => ({
    label: threatName,
    value: threatName,
  }))
}

export function MalwareThreatsSelect({ threatsNames }: Props) {
  const { malwareThreatNames } = useToolbarFiltersState()

  const { setMalwareThreatNames } = useToolbarFiltersActions()

  const threatsOptions = buildThreatsOptions(threatsNames)

  const selectedThreatsOptions = buildThreatsOptions(malwareThreatNames)

  const handleMalwareThreatsChange = (threatsList: Array<OptionType>) => {
    setMalwareThreatNames(threatsList.map((threat) => threat.value as string))
  }

  return (
    <MultiSelectSearch
      key={malwareThreatNames.size}
      tagsTitle="Malware"
      placeholder="Malware"
      options={threatsOptions}
      selectedOptions={selectedThreatsOptions}
      onDone={handleMalwareThreatsChange}
    />
  )
}
