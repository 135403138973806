export enum FindingConfidence {
  ENCRYPTED,
  SUSPICIOUS,
}

interface FindingAttrs {
  confidence: FindingConfidence
  path: string
  numberOfFiles: number
  totalSize: number
}

export class Finding {
  // The level of confidence of Elastio in the directory content's encryption.
  #confidence: FindingConfidence

  // The path to the directory with files with high entropy.
  #path: string

  // The number of files in the directory with high entropy levels.
  #numberOfFiles: number

  // Total size of affected data in bytes.
  #totalSize: number

  constructor(attrs: FindingAttrs) {
    this.#confidence = attrs.confidence
    this.#path = attrs.path
    this.#numberOfFiles = attrs.numberOfFiles
    this.#totalSize = attrs.totalSize
  }

  get confidence() {
    return this.#confidence
  }

  get path() {
    return this.#path
  }

  get numberOfFiles() {
    return this.#numberOfFiles
  }

  get totalSize() {
    return this.#totalSize
  }
}
