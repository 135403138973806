import React, { useState, ChangeEvent, useEffect } from 'react'
import { Box, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { BackupConnectorForm } from './backup-connector/form'
import { TypographyStyled, FormWrap } from './shared/form'
import { useLayoutContext } from '@features/contexts'
import { CreateManagementView } from '@features/management-view/create'

export const FORM_CREATE_MANAGEMENT = 0
export const FORM_BACKUP_CONNECTOR = 1

function ManagementView() {
  const { setHeaderTitle } = useLayoutContext()

  const [selectedForm, setSelectedForm] = useState(FORM_CREATE_MANAGEMENT)

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedForm(parseInt(event.target.value, 10))
  }

  useEffect(() => {
    setHeaderTitle('Add dashboard view')

    return () => setHeaderTitle(null)
  }, [setHeaderTitle])

  return (
    <Box className="innerContent innerContentBlueBackground">
      <FormWrap>
        <TypographyStyled>Add new dashboard view</TypographyStyled>
        <RadioGroup
          row
          value={selectedForm.toString()}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="0" control={<Radio />} label="AWS" />
          <FormControlLabel value="1" control={<Radio />} label="VMware" />
        </RadioGroup>
        <Box>
          {selectedForm === FORM_CREATE_MANAGEMENT && <CreateManagementView />}
          {selectedForm === FORM_BACKUP_CONNECTOR && <BackupConnectorForm />}
        </Box>
      </FormWrap>
    </Box>
  )
}

export default ManagementView
