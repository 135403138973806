import { ListAnomaliesRequest } from '@lib/clients/entropy-detection/list-anomalies/index'
import { ListAnomaliesRequest as ListAnomaliesRequestGrpc } from '@lib/clients/grpc-imports'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { AssetsFilter, buildAssetsFilter } from '../common'

export interface ListAnomaliesRequestParams {
  anomaliesIdsList?: Array<string>
  assetIdsList?: Array<string>
  assetsFilter?: AssetsFilter
  endAt: number
  startAt: number
}

const PAGE_SIZE = 25

export class ListAnomaliesFilter implements ListAnomaliesRequest {
  #requestParams?: ListAnomaliesRequestParams

  constructor(params?: ListAnomaliesRequestParams) {
    this.#requestParams = params
  }

  toGrpc(): ListAnomaliesRequestGrpc {
    const request = new ListAnomaliesRequestGrpc()

    const initial = new ListAnomaliesRequestGrpc.Initial()

    initial.setFilters(this.#setupRequestParams())

    initial.setPageSize(PAGE_SIZE)

    request.setInitial(initial)

    return request
  }

  #setupRequestParams(): ListAnomaliesRequestGrpc.Initial.Filters {
    const filters = new ListAnomaliesRequestGrpc.Initial.Filters()

    if (!this.#requestParams) {
      return filters
    }

    const { startAt, endAt, anomaliesIdsList, assetIdsList, assetsFilter } =
      this.#requestParams

    filters.setStartAt(this.#setTimestamp(startAt))

    filters.setEndAt(this.#setTimestamp(endAt))

    if (assetIdsList && assetIdsList.length) {
      filters.setAssetIdsList(assetIdsList)
    }

    if (anomaliesIdsList && anomaliesIdsList.length) {
      filters.setIdsList(anomaliesIdsList)
    }

    if (assetsFilter) {
      filters.setAssetsFilter(buildAssetsFilter(assetsFilter))
    }

    return filters
  }

  #setTimestamp(date: number): Timestamp {
    const timestamp = new Timestamp()
    timestamp.setSeconds(Math.floor(date / 1000))
    return timestamp
  }
}
