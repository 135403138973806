/* eslint-disable import/no-extraneous-dependencies */
import { CopyToClipboardIcon } from '@features/common'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  EC2,
  EBS,
  EFS,
  S3Bucket,
  GenericHost,
  AssetItem,
} from 'blues-corejs/dist'
import React from 'react'
import {
  computeAssetCopiableId,
  computeAssetId,
  computeAssetItemId,
  computeAssetTitle,
} from '../helpers'
import { RowTitle } from './row-title'

const CLIPBOARD_ICON_SIZE = 16
type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function AssetId({
  asset,
  assetItem,
}: {
  asset: Asset
  assetItem?: AssetItem
}) {
  if (assetItem) {
    const assetItemId = computeAssetItemId(assetItem)

    return (
      <Box display="flex" alignItems="center">
        <RowTitle title="Name" />
        <Box display="flex" alignItems="center">
          <Typography variant="caption" fontSize="14px">
            {assetItemId}
          </Typography>
          <CopyToClipboardIcon
            text={assetItem.ccAssetId}
            fontSize={CLIPBOARD_ICON_SIZE}
          />
        </Box>
      </Box>
    )
  }

  const title = computeAssetTitle(asset)

  const assetId = computeAssetId(asset)

  const assetCopiableId = computeAssetCopiableId(asset)

  return (
    <Box display="flex" alignItems="center">
      <RowTitle title={title} />
      <Box display="flex" alignItems="center">
        <Typography variant="caption" fontSize="14px">
          {assetId}
        </Typography>
        <CopyToClipboardIcon
          text={assetCopiableId}
          fontSize={CLIPBOARD_ICON_SIZE}
        />
      </Box>
    </Box>
  )
}
