import React from 'react'
import ValueInterface from '@lib/interfaces/value.interface'
import { Nullable } from '@lib/engine-types'

interface Props {
  status?: Nullable<ValueInterface>
  statusIscan: Nullable<ValueInterface>
  statusFsCheck: Nullable<ValueInterface>
  completeLink?: string
  completeLinkLabel?: string
  kind?: number | string | boolean
}

// TODO: deprecate
function RecoveryPointCircleStatus({}: Props) {
  return <div />
}

export default RecoveryPointCircleStatus
