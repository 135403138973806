import { ScheduledTenantJobKind } from 'ui-v2/src/features/scheduled-jobs-monitoring/models'
import { ScheduledTenantJobsRepository } from 'ui-v2/src/features/scheduled-jobs-monitoring/repositories'
import { GetTenantIdsForRunningScheduledJobs } from 'ui-v2/src/features/scheduled-jobs-monitoring/use-cases'
import { useLiveQuery } from 'dexie-react-hooks'

const retrieveTenantIdsByScheduledJobKindUseCase =
  new GetTenantIdsForRunningScheduledJobs(
    new ScheduledTenantJobsRepository(console)
  )
export function useTenantIdsFromScheduledJobs(
  jobKinds: Array<ScheduledTenantJobKind>
): Array<string> {
  const ccIdsList = useLiveQuery(async () => {
    const result = await Promise.resolve(
      retrieveTenantIdsByScheduledJobKindUseCase.execute(jobKinds)
    )

    return result
  }, [jobKinds.length])

  return ccIdsList ? [...ccIdsList] : []
}
