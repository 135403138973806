import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface FilterComponentProps {
  options: Array<string>
}

const AccountIdFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsAccountIdList =
    searchParams.get(ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID)?.split(',') || []

  const awsAccountIdOptions = useMemo(() => {
    return options.map((awsAccountId) => ({
      value: awsAccountId,
      label: awsAccountId,
    }))
  }, [options])

  const handleAwsAccountIdChange = (
    newAwsAccountIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsAccountIdList.length === 0) {
      searchParams.delete(ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID)
    } else {
      searchParams.set(
        ASSETS_THREATS_FILTERS_MAP.ACCOUNT_ID,
        newAwsAccountIdList.join(',')
      )
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsAccountIdOptions}
      value={awsAccountIdList}
      onChange={handleAwsAccountIdChange}
      label="Account ID"
      width={200}
    />
  )
}

export default AccountIdFilter
