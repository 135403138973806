/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { SearchBar } from './search-bar'
import Stack from '@mui/material/Stack'
import {
  useToolbarFiltersActions,
  useToolbarFiltersSubscription,
} from './toolbar-filters-store'
import { OvaAccount } from 'blues-corejs/dist'
import { OvaAccountsSelect } from './ova-accounts-select'
import { OvaBackupProvidersSelect } from './ova-backup-providers-select'
import { MalwareThreatsSelect } from './malware-threats-select'
import { RansomwareThreatsSelect } from './ransomware-threats-select'
import { ThreatsFiltersChips } from './threats-filters-chips'
import {
  useGetOvaServerPossibleFilters,
  useOvaServerPossibleFiltersStoreActions,
} from '@lib/zustand'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'

interface Props {
  ovaAccountsList: Array<OvaAccount>
}

function Toolbar({ ovaAccountsList }: Props) {
  const { resetToolbarFilters } = useToolbarFiltersActions()

  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  const {
    fetchPossibleOvaServersFilters,
    resetPossibleOvaServersFiltersStore,
  } = useOvaServerPossibleFiltersStoreActions()

  const { malwareThreatNamesList, ransomwareThreatNamesList } =
    useGetOvaServerPossibleFilters()

  useToolbarFiltersSubscription()

  useEffect(() => {
    fetchPossibleOvaServersFilters({
      backupProvidersList: sliceOvaBackupProvidersList,
      ovaAccountIdsList: sliceOvaAccountsList,
    })
    return () => {
      resetToolbarFilters()
      resetPossibleOvaServersFiltersStore()
    }
  }, [sliceOvaAccountsList.length, sliceOvaBackupProvidersList.length])

  return (
    <Box gap={1} display="grid">
      <SearchBar />
      <Stack direction="row" columnGap={2} justifyContent="space-between">
        <OvaAccountsSelect ovaAccountsList={ovaAccountsList} />
        <OvaBackupProvidersSelect ovaAccountsList={ovaAccountsList} />
        <MalwareThreatsSelect threatsNames={malwareThreatNamesList} />
        <RansomwareThreatsSelect threatsNames={ransomwareThreatNamesList} />
      </Stack>

      <ThreatsFiltersChips />
    </Box>
  )
}

export default Toolbar
