import { z } from 'zod'
import { FORM_FIELDS } from '../form-field-names'

export interface OptionType {
  value: string | number
  label: string
}

const ReportTypeEnum = z.enum([
  'awsExecutiveReport',
  'awsAccountSummaryReport',
  'awsTenantSummaryReport',
  'awsCompletedScansReport',
  'awsScannedAssetsSummaryReport',
  'infectedFilesReport',
  'failedScansReport',
  'awsInfectedAssetsReport',
])

export const optionSchema = z.object({
  value: z.union([
    z.string().min(1, { message: 'Value cannot be empty' }),
    z.number(),
  ]),
  label: z.string().min(1, { message: 'Label cannot be empty' }),
})

const Type1Schema = z.object({
  reportType: ReportTypeEnum,
  [FORM_FIELDS.FORMATS]: z
    .array(z.string())
    .min(1, { message: 'Please select at least one format' }),
  [FORM_FIELDS.DESCRIPTION]: z.string().optional(),
  [FORM_FIELDS.START_AT]: z.any().optional(),
  [FORM_FIELDS.END_AT]: z.any().optional(),
})

const Type2Schema = z.object({
  reportType: z.enum([
    'ovaMspOperationalReport',
    'ovaMspExecutiveReport',
    'ovaMspTenantSummaryReport',
    'ovaMspAccountSummaryReport',
    'ovaMspCompletedScansReport',
    'ovaMspScannedAssetsSummaryReport',
    'ovaMspInfectedAssetsReport',
    'ovaMspInfectedFilesReport',
    'ovaMspFailedScansReport',
  ]),
  [FORM_FIELDS.OVA_ACCOUNTS]: z.array(optionSchema).optional(),
  [FORM_FIELDS.BACKUP_PROVIDER]: z.array(optionSchema).optional(),
  [FORM_FIELDS.DESCRIPTION]: z.string().optional(),
  [FORM_FIELDS.START_AT]: z.any().optional(),
  [FORM_FIELDS.END_AT]: z.any().optional(),
  [FORM_FIELDS.FORMATS]: z
    .array(z.string())
    .min(1, { message: 'Please select at least one format' }),
})

export const FormSchema = z.discriminatedUnion('reportType', [
  Type1Schema,
  Type2Schema,
])

export function isType2Schema(
  values: FormData
): values is z.infer<typeof Type2Schema> {
  return (
    values.reportType === 'ovaMspOperationalReport' ||
    values.reportType === 'ovaMspExecutiveReport' ||
    values.reportType === 'ovaMspTenantSummaryReport' ||
    values.reportType === 'ovaMspAccountSummaryReport' ||
    values.reportType === 'ovaMspCompletedScansReport' ||
    values.reportType === 'ovaMspScannedAssetsSummaryReport' ||
    values.reportType === 'ovaMspInfectedAssetsReport' ||
    values.reportType === 'ovaMspInfectedFilesReport' ||
    values.reportType === 'ovaMspFailedScansReport'
  )
}

export type FormData = z.infer<typeof FormSchema>
