import SearchForm from '@forms/search/search.form'
import LangHelper from '@lib/helpers/lang.helper'
import VaultModel from '@lib/models/vault.model'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import React, { useMemo } from 'react'
import { VaultForPolicy } from '@lib/interfaces/vault/vaults-policy'
import WarningMessage from '@components-simple/messages/warning-message/WarningMessage'
import Box from '@mui/material/Box'

interface Props {
  accounts: Array<{
    accountId: string
    regionToVaults: {
      [region: string]: Array<VaultModel>
    }
  }>
  onVaultSelect: (vault: VaultModel) => void
  onSearchChange: React.Dispatch<React.SetStateAction<string>>
  selectedVaults?: Array<VaultForPolicy>
  loading?: boolean
  hasActiveCloudConnectors?: boolean
}

const CHOOSE_VAULT_TITLE = 'Choose Vault'
const CHOOSE_VAULT_LABEL =
  'Vaults store scan metadata and, optionally, backups. They use globally deduplicated S3 storage and scale-to-zero compute resources to perform these operations.'

/* This component completely depends of parent,
   so it is created just for covering whole render
   logic for 4th step of Policy creation */

function ChooseVaultStep({
  accounts,
  onSearchChange,
  onVaultSelect,
  selectedVaults,
  loading,
  hasActiveCloudConnectors = true,
}: Props) {
  const isEditVaults = useMemo(() => {
    return !!selectedVaults?.length
  }, [selectedVaults])

  const defaultSelectValue = (vaults: Array<VaultModel>) => {
    const defaultVaultName = vaults?.find((v) => v.isDefault)?.name

    if (!isEditVaults) {
      return defaultVaultName
    }

    const currentSelectedVaultName = vaults?.find((v) =>
      selectedVaults?.find(
        (sv) => sv.redStackId === v.redStackId && sv.vaultName === v.name
      )
    )

    if (currentSelectedVaultName) {
      return currentSelectedVaultName.name
    }

    return defaultVaultName
  }

  return (
    <Box className="vaultsBlock jsVaultsBlock">
      <Box className="addPolicyTitle">{CHOOSE_VAULT_TITLE}</Box>
      <Box className="policiesBlockLabel">{CHOOSE_VAULT_LABEL}</Box>
      {hasActiveCloudConnectors && (
        <SearchForm
          onSubmit={({ search }) => {
            onSearchChange(search)
          }}
          variant="whiteFilterSearchBlackIcon"
          placeholder="Search by Account ID"
          loading={loading}
        />
      )}
      <Box className="accounts">
        <Box className="accountBlock">
          {!!accounts.length &&
            accounts.map(({ accountId, regionToVaults }) => (
              <Box key={accountId}>
                <Box className="account-block__header">
                  Account ID: {accountId}
                </Box>
                {Object.entries(regionToVaults).map(([region, vaults], ind) => (
                  <Box className="account-block__body" key={region + ind}>
                    <p>{LangHelper.getAwsRegionSingleTranslation(region)}</p>
                    <Select
                      aria-label="Select Vault"
                      key={
                        selectedVaults?.length
                          ? selectedVaults?.length
                          : undefined
                      }
                      defaultValue={
                        defaultSelectValue(vaults)
                          ? defaultSelectValue(vaults)
                          : ''
                      }
                      inputProps={{ 'data-testid': 'my-wrapper' }}
                      disabled={loading}
                    >
                      {vaults.map((vault) => (
                        <MenuItem
                          key={vault.innerId}
                          value={vault.name || ''}
                          onClick={() => onVaultSelect(vault)}
                          children={
                            vault.isDefault ? (
                              <Box>
                                {vault.name}{' '}
                                <span className="defaultVault">(Default)</span>
                              </Box>
                            ) : (
                              vault.name
                            )
                          }
                        />
                      ))}
                    </Select>
                  </Box>
                ))}
              </Box>
            ))}
        </Box>
      </Box>
      {!hasActiveCloudConnectors && (
        <WarningMessage message="You don't have any active Cloud Connectors" />
      )}
    </Box>
  )
}

export default ChooseVaultStep
