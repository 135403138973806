/* eslint-disable import/no-extraneous-dependencies */
import FrequencyConstant from '../constants/frequency.constant'
import { SelectedVariant, TabAssetType } from '../constants/policies.constant'
import { ValueInterface } from '../engine-types'
import { BackupProcessor } from '../models/assets/backup-processor'
import { buildWithOrdinalSuffix } from './string.helper'

import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'

export function determineShowWindowInputs(frequency: ValueInterface): boolean {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_EVERY_15_MIN.value:
    case FrequencyConstant.FREQUENCY_EVERY_30_MIN.value:
    case FrequencyConstant.FREQUENCY_EVERY_HOUR.value:
      return true

    default:
      return false
  }
}

export function determineShowOnlyStart(frequency: ValueInterface): boolean {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_EVERY_12_HOURS.value:
    case FrequencyConstant.FREQUENCY_DAILY.value:
      return true

    default:
      return false
  }
}

export function determineShowDayOfWeekInput(frequency: ValueInterface) {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_WEEKLY.value:
      return true

    default:
      return false
  }
}

export function determineShowDayOfMonthInput(frequency: ValueInterface) {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_MONTHLY.value:
      return true

    default:
      return false
  }
}
export function getDayLabel(day: number, ordinal = false): string {
  if (day === 0) {
    return 'The last day'
  }
  if (day === -1) {
    return 'Before the last day'
  }
  if (ordinal) {
    return buildWithOrdinalSuffix(day)
  }
  return String(day)
}

export function convertBackupsToMap({ backups }: BackupProcessor) {
  const backupsMapped = new Map<
    string,
    { backup: EBSSnapshot | AWSBRecoveryPoint | ElastioRecoveryPoint }
  >()

  for (const [key, value] of backups) {
    backupsMapped.set(key, { backup: value })
  }

  return backupsMapped
}

export const assetTypeVariant = (newTabIndex: number) => {
  switch (newTabIndex) {
    case TabAssetType.S3:
      return SelectedVariant.S3Variant
    case TabAssetType.EFS:
      return SelectedVariant.EfsVariant
    default:
      return SelectedVariant.AllEC2EBSVariant
  }
}
