import { BaseRoutes } from '../base'

export enum RouteFragments {
  DYNAMIC_ID = ':id',
}

export class AssetRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_ASSET
  }

  public static get assetIdPlaceholder(): string {
    return this.buildRoute(RouteFragments.DYNAMIC_ID)
  }

  public static assetId(assetId: string): string {
    return this.buildRoute(this.base64Encode(assetId))
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_ASSET}/${fragments.join('/')}`
  }

  static readonly #ROUTE_ASSET = '/asset'
}
