/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EC2 } from 'blues-corejs/dist'
import { computeInstanceState } from './compute-instance-state'
import { RowTitle } from '../row-title'
import Box from '@mui/system/Box'

export function InstanceState({ asset }: { asset: EC2 }) {
  const { label, Icon } = computeInstanceState(asset)

  return (
    <Grid container item xs={4}>
      <Grid item>
        <RowTitle title="State:" />
      </Grid>
      <Grid item>
        <Box display="flex" alignItems="center">
          {Icon}
          <Typography fontSize="14px" fontWeight="400" variant="caption">
            {label}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
