import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  ApiKey as ApiKeyGrpc,
  BasicAuth as BasicAuthGrpc,
  BearerToken as BearerTokenGrpc,
  WebhookAuthentication,
  CreateWebhookRequest,
  SecretString,
  UpdateWebhookRequest,
  Webhook,
  WebhookEventSubscription,
  ListWebhookInvocationsRequest,
  WebhookInvocationPeriod,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import {
  DeleteWebhookRequest,
  Empty,
  GetWebhookRequest,
  ListEventTypesRequest,
  ListWebhooksRequest,
  TestNewWebhook,
  TestWebhookRequest,
  WebhooksPromiseClient,
} from '../grpc'
import { ClientConfig } from '../models/client'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  Secret,
  TestWebhookFilters,
  WebhookAuthentication as WebhookAuthenticationModel,
  WebhookEventSubscription as WebhookEventSubscriptionModel,
} from '../models/webhooks'
import { GrpcClient } from './grpc'
import { DefinedDateRangeTuple } from '../engine-types'

export class WebhooksClient extends GrpcClient<WebhooksPromiseClient> {
  #webhooksPromiseClient: WebhooksPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#webhooksPromiseClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'WebhooksClient'
  }

  protected initClient(hostName = ''): WebhooksPromiseClient {
    return new WebhooksPromiseClient(hostName, null, null)
  }

  async listWebhooks() {
    const request = new ListWebhooksRequest()

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.listWebhooks(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/listWebhooks',
        }
      )
    ).toObject()

    return response.webhooksList
  }

  async getWebhook(webhookId: string) {
    const request = new GetWebhookRequest()
    request.setId(webhookId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.getWebhook(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/getWebhook',
        }
      )
    ).toObject().webhook

    if (!response) {
      return undefined
    }

    let authentication = undefined

    if (response.authentication?.apiKey) {
      authentication = new ApiKey(
        new Secret({
          secret: response.authentication.apiKey.key?.value ?? '',
          encrypted: true,
        }),
        new Secret({
          secret: response.authentication.apiKey.value?.value ?? '',
          encrypted: true,
        })
      )
    } else if (response.authentication?.basicAuth) {
      authentication = new BasicAuth(
        new Secret({
          secret: response.authentication.basicAuth.login?.value ?? '',
          encrypted: true,
        }),
        new Secret({
          secret: response.authentication.basicAuth.password?.value ?? '',
          encrypted: true,
        })
      )
    } else if (response.authentication?.bearerToken) {
      authentication = new BearerToken(
        new Secret({
          secret: response.authentication.bearerToken.token?.value ?? '',
          encrypted: true,
        })
      )
    }

    return new WebhookModel({
      id: response.id,
      name: response.name,
      endpoint: response.endpoint,
      description: response.description,
      authentication: new WebhookAuthenticationModel(authentication),
      event_subscriptions: response.eventSubscriptionsList.map(
        (eventSubscription) =>
          new WebhookEventSubscriptionModel(
            eventSubscription.eventType,
            eventSubscription.version,
            eventSubscription.severitiesList
          )
      ),
    })
  }

  async listEventTypes() {
    const request = new ListEventTypesRequest()

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.listEventTypes(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/listEventTypes',
        }
      )
    ).toObject()

    return response
  }

  async deleteWebhook(webhookId: string) {
    const request = new DeleteWebhookRequest()
    request.setId(webhookId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.deleteWebhook(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/deleteWebhook',
        }
      )
    ).toObject()

    return response
  }

  async testWebhook(filters: TestWebhookFilters) {
    const request = new TestWebhookRequest()
    const webhook = new TestNewWebhook()

    if ('name' in filters && 'endpoint' in filters) {
      webhook.setName(filters.name)
      webhook.setEndpoint(filters.endpoint)
      request.setWebhook(webhook)
    } else {
      request.setId(filters.id)
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.testWebhook(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/testWebhook',
        }
      )
    ).toObject()

    return response
  }

  async buildWebhookFromModel(webhook: WebhookModel): Promise<Webhook> {
    const newWebhook = new Webhook()
    const subscriptions: Array<WebhookEventSubscription> = []
    const authRequest = new WebhookAuthentication()

    const getAuthType = () => {
      const auth = webhook.getAuthentication().intoInner()
      if (auth instanceof BearerToken) {
        const token = new BearerTokenGrpc()
        const secret = new SecretString()
        return authRequest.setBearerToken(
          token.setToken(
            auth.isTokenEncrypted()
              ? secret.setOldValue(new Empty())
              : secret.setValue(auth.getToken())
          )
        )
      }
      if (auth instanceof BasicAuth) {
        const basic = new BasicAuthGrpc()
        const secretPassword = new SecretString()
        const secretLogin = new SecretString()
        basic.setPassword(
          auth.isLoginEncrypted()
            ? secretPassword.setOldValue(new Empty())
            : secretPassword.setValue(auth.getPassword())
        )
        basic.setLogin(
          auth.isPasswordEncrypted()
            ? secretLogin.setOldValue(new Empty())
            : secretLogin.setValue(auth.getLogin())
        )
        return authRequest.setBasicAuth(basic)
      }

      if (auth instanceof ApiKey) {
        const api = new ApiKeyGrpc()
        const secretKey = new SecretString()
        const secretValue = new SecretString()

        api.setKey(
          auth.isKeyEncrypted()
            ? secretKey.setOldValue(new Empty())
            : secretKey.setValue(auth.getKey())
        )
        api.setValue(
          auth.isValueEncrypted()
            ? secretValue.setOldValue(new Empty())
            : secretValue.setValue(auth.getValue())
        )
        return authRequest.setApiKey(api)
      }
    }

    webhook.getEventSubscriptions().forEach((event) => {
      const subscription = new WebhookEventSubscription()
      subscription.setEventType(event.getEventType().toLowerCase())
      subscription.setVersion(event.getVersion())
      subscription.setSeveritiesList(event.getSeverities())

      subscriptions.push(subscription)
    })

    newWebhook.setId(webhook.getId())
    newWebhook.setName(webhook.getName())
    newWebhook.setDescription(webhook.getDescription())
    newWebhook.setEndpoint(webhook.getEndpoint())
    newWebhook.setAuthentication(getAuthType())
    newWebhook.setEventSubscriptionsList(subscriptions)

    return newWebhook
  }

  async createWebhook(webhook: WebhookModel) {
    const request = new CreateWebhookRequest()

    const newWebhook = await this.buildWebhookFromModel(webhook)

    request.setName(webhook.getName())
    request.setEndpoint(`https://${newWebhook.getEndpoint()}`)
    request.setDescription(newWebhook.getDescription())
    request.setAuthentication(newWebhook.getAuthentication())
    request.setEventSubscriptionsList(newWebhook.getEventSubscriptionsList())

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.createWebhook(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/createWebhook',
        }
      )
    ).toObject()

    return response
  }

  async updateWebhook(webhook: WebhookModel) {
    const request = new UpdateWebhookRequest()

    const updatedWebhook = await this.buildWebhookFromModel(webhook)
    request.setName(updatedWebhook.getName())
    request.setId(updatedWebhook.getId())
    request.setEndpoint(`https://${updatedWebhook.getEndpoint()}`)
    request.setDescription(updatedWebhook.getDescription())
    request.setAuthentication(updatedWebhook.getAuthentication())
    request.setEventSubscriptionsList(
      updatedWebhook.getEventSubscriptionsList()
    )

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.updateWebhook(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/updateWebhook',
        }
      )
    ).toObject()

    return response
  }

  async getWebhookInvocations(
    webhooks: Array<string> = [],
    periods: Array<DefinedDateRangeTuple> = []
  ) {
    const request = new ListWebhookInvocationsRequest()
    const periodsList = periods.map(([startAt, endAt]) => {
      const period = new WebhookInvocationPeriod()
      period.setStartAt(Timestamp.fromDate(new Date(startAt)))
      period.setEndAt(Timestamp.fromDate(new Date(endAt)))
      return period
    })

    request.setWebhookIdsList(webhooks)
    request.setPeriodsList(periodsList)

    const response = (
      await this.callGrpcService(
        () =>
          this.#webhooksPromiseClient.listWebhookInvocations(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'WebhooksPromiseClient/listWebhookInvocations',
        }
      )
    ).toObject()

    return response
  }
}
