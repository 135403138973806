import React, { memo } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { EngineCallback } from '@lib/engine-types'
import clsx from 'clsx'
import { getCountries } from 'react-phone-number-input'
import ArrHelper from '@lib/helpers/arr.helper'
import { CountryCode } from 'libphonenumber-js/types'

interface Props {
  onChange: EngineCallback<string>
  phoneNumber?: string
  label?: string
  disabled?: boolean
  placeholder?: string
}

function PhoneNumber({
  onChange,
  phoneNumber = '',
  label = '',
  disabled = false,
  placeholder = 'Enter phone number',
}: Props) {
  // exclude russia, North Korea and Iran from the countries list
  const excludedeCountries: Array<CountryCode> = ['RU', 'KP', 'IR']
  const allCountriesList = getCountries()
  const countriesList = ArrHelper.arrRemoveValues(
    allCountriesList,
    excludedeCountries
  )

  return (
    <div className="wrap-1630923470503">
      <div className="v2StaticTextInput phoneInput flexRowJustifyStart">
        <label className={clsx('phoneLabel', { phoneLabelDisabled: disabled })}>
          {label}
        </label>

        <PhoneInput
          international
          countries={countriesList}
          defaultCountry="US"
          placeholder={placeholder}
          value={phoneNumber}
          data-testid="phoneNumber"
          onChange={(value) => onChange(String(value ?? ''))}
          id="id_phoneNumber"
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default memo(PhoneNumber)
