/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'
import JobSuccessIcon from '@inline-img/general/job-dashboard/JobSuccessIcon'
import JobFailedIcon from '@inline-img/general/job-dashboard/JobFailedIcon'
import Stack from '@mui/material/Stack'

interface Props {
  scans: ScansRun
}

function getAreAllScansHealthy(scans: ScansRun): boolean {
  const allMalwareScansIsClean = scans.malwareScansList.every(
    (scan) => scan.isClean
  )
  const allRansomwareScansIsClean = scans.ransomwareScansList.every(
    (scan) => scan.isClean
  )
  const allFilesystemChecksIsClean = scans.filesystemChecksList.every(
    (scan) => scan.isClean
  )

  return (
    allMalwareScansIsClean &&
    allRansomwareScansIsClean &&
    allFilesystemChecksIsClean
  )
}

function getScanStatusIcon(isHealthy: boolean) {
  if (isHealthy) {
    return <JobSuccessIcon />
  }

  return <JobFailedIcon />
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <Stack alignItems="center" direction="row">
    {children}
  </Stack>
)

export function ScanStatusCell({ scans }: Props) {
  const allScansHealthy = getAreAllScansHealthy(scans)

  return <Container>{getScanStatusIcon(allScansHealthy)}</Container>
}
