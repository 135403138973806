import { Anomaly } from '@lib/models/anomalies'
import { DetectedAnomalyTable } from './column-definitions'

export function mapAnomaliesListToTableDefinitions(
  anomaliesList: Array<Anomaly>
): Array<DetectedAnomalyTable> {
  return anomaliesList.reduce(
    (acc: Array<DetectedAnomalyTable>, anomaly: Anomaly) => {
      if (!anomaly.assetId) {
        return acc
      }

      const findings = anomaly.findings.map((finding) => ({
        path: finding.path,
        assetId: anomaly.assetId,
        detectedAt: anomaly.createdAt,
        numberOfFindings: anomaly.totalNumberOfFiles,
        numberOfFiles: finding.numberOfFiles,
      })) as Array<DetectedAnomalyTable>

      return [...acc, ...findings]
    },
    [] as Array<DetectedAnomalyTable>
  )
}

export function getAssetIdFromAnomalies(
  anomalies: Array<Anomaly>
): Array<string> {
  return anomalies
    .map((anomaly) => anomaly.assetId)
    .filter(Boolean) as Array<string>
}
