import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ListOvaServerFilters } from 'ui-v2/src/lib/models/assets'
import { AssetsFilters } from 'ui-v2/src/lib/models/assets/common'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import {
  ASSET_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'
import { OvaBackupProviderProto } from 'ui-v2/src/lib/grpc'

export const useAssetsOvaServerFilters =
  (): AssetsFilters<ListOvaServerFilters> => {
    const [pageSize, setPageSize] = useState<number>(
      TABLE_ROWS_PER_PAGE_OPTIONS[0]
    )
    const [pageToken, setPageToken] = useState<string | undefined>(undefined)
    const [searchParams] = useSearchParams()

    useEffect(() => {
      setPageToken(undefined)
    }, [searchParams])

    return useMemo(() => {
      return {
        filters: {
          pageSize,
          pageToken,
          search: searchParams.get(SEARCH_PARAM) ?? undefined,
          accountIdsList: searchParams
            .get(ASSET_FILTERS_MAP.OVA_ACCOUNT_ID)
            ?.split(',')
            .filter(Boolean),
          backupProvidersList: EnumHelper.filterToEnumValues(
            searchParams.get(ASSET_FILTERS_MAP.BACKUP_VENDOR)?.split(',') ?? [],
            OvaBackupProviderProto
          ),
        },
        setPageSize,
        setPageToken,
      }
    }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
  }
