import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AccountProfileForm from '@forms/account-profile/account-profile.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import UserModel from '@lib/models/user.model'
import { getUser } from '@store/selectors/user.selector'
import { requestUpdateUser } from '@store/actions/user.action'
import useLoadScript from '@lib/hooks/useLoadScript'
import Stack from '@mui/material/Stack'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import EditIcon from '@inline-img/general/edit-icon'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import FormAccountProfileInterface from '@lib/interfaces/form/form-account-profile.interface'
import CloudinaryHelper from '@lib/helpers/cloudinary.helper'
import useDebouncedFunction from '@lib/hooks/useDebouncedFunction'
import { Timezone } from './timezone'
import Typography from '@mui/material/Typography'
import { Box, Button, Dialog, Switch } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { DashboardRoutes } from '@lib/router'

type PhotoInfo = {
  event: string
  info: {
    url: string
  }
}

const ERROR_GROUPS = [ErrorGroupConstants.USER]
const CLOUDINARY_SCRIPT =
  'https://upload-widget.cloudinary.com/1.45.44/global/all.js'

const BEGIN_IMAGE_UPLOAD_TIME = 100

function AccountProfile() {
  const dispatch = useDispatch()
  useLoadScript(CLOUDINARY_SCRIPT)

  const user: UserModel = useSelector(getUser)
  const [pictureUrl, setPictureUrl] = useState('')
  const [isNewUIEnabled, setIsNewUIEnabled] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    const savedValue = localStorage?.getItem('useNewUI') === 'true' || false
    setIsNewUIEnabled(savedValue)
  }, [])

  const isLoading = usePreloader(PreloaderConstants.REQUEST_UPDATE_USER)

  const setAccountProfileCallback = (data: FormAccountProfileInterface) => {
    const newUser = UserModel.clone(user, {
      name: data.userName,
      phoneNumber: data.phoneNumber,
      picture: pictureUrl,
    })

    dispatch(requestUpdateUser(newUser))
  }
  const isPictureToDisplay = pictureUrl || user.picture
  const beginUpload = () => {
    CloudinaryHelper.openUploadWidget((error: string, photo: PhotoInfo) => {
      if (!error) {
        if (photo.event === 'success') {
          setPictureUrl(photo.info.url)
        }
      }
    })
  }
  const onBeginUpload = useDebouncedFunction(
    beginUpload,
    BEGIN_IMAGE_UPLOAD_TIME
  )

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    setIsNewUIEnabled(newValue)

    if (newValue) {
      setIsDialogOpen(true)
    } else {
      setIsNewUIEnabled(newValue)
      localStorage.setItem('useNewUI', newValue.toString())
    }
  }

  const handleDialogClose = (confirmReload: boolean) => {
    setIsDialogOpen(false)

    if (confirmReload) {
      setIsNewUIEnabled(true)
      localStorage.setItem('useNewUI', 'true')
      window.location.href = DashboardRoutes.root
    } else {
      setIsNewUIEnabled(false)
    }
  }

  return (
    <>
      <div className="wrap-1603973938498">
        <span className="subtitle">Profile</span>
        <div className="profileWrapper">
          <div
            role="presentation"
            className="photoWrapper jsPicUpload"
            onClick={onBeginUpload}
          >
            {isPictureToDisplay ? (
              <>
                <img
                  className="jsUserPic"
                  src={pictureUrl || user.picture}
                  alt="user"
                  width={100}
                  height={100}
                />
                <div className="editIcon">
                  <EditIcon />
                </div>
              </>
            ) : (
              <PreloaderSmall show />
            )}
            <div className="blueWrapper" />
          </div>

          <div className="profileInfo">
            <div className="fullName">{user.name}</div>
            <div className="email">{user.email}</div>
          </div>
        </div>

        <div className="line" />

        <Stack spacing={4}>
          {user.isNotEmpty ? (
            <AccountProfileForm
              loading={isLoading}
              defaultValues={{
                userName: user.name,
                phoneNumber: user.phoneNumber,
              }}
              onSubmit={setAccountProfileCallback}
              errorGroups={ERROR_GROUPS}
            />
          ) : null}

          <div className="wrapTimezone">
            <Timezone />
          </div>
        </Stack>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            padding: 2,
            border: '1px solid #ddd',
            borderRadius: 4,
            maxWidth: 400,
            marginTop: '20px',
          }}
        >
          <Box sx={{ marginRight: 2 }}>
            <Typography variant="body1" fontWeight="bold">
              Enable New UI
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Toggle to switch to the new version of the UI.
            </Typography>
          </Box>
          <Switch
            checked={isNewUIEnabled}
            onChange={handleSwitchChange}
            color="primary"
          />
        </Box>
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleDialogClose(false)}
        sx={{
          '& .MuiDialog-paper': {
            width: '420px',
            padding: '24px',
            borderRadius: '12px',
            textAlign: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '235px',
          },
        }}
      >
        <DialogTitle>Switch to New UI</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enabling the new UI requires a page reload. Do you want to reload
            the page now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            Reload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default memo(AccountProfile)
