import { useEffect } from 'react'
import { EngineCallback } from '@lib/engine-types'

function useKeyUp(
  keys: Array<string>,
  callback: EngineCallback<KeyboardEvent>
): void {
  useEffect(() => {
    const keyUpListener = (event: KeyboardEvent) => {
      if (keys.includes(event.key)) {
        callback(event)
      }
    }
    window.addEventListener('keyup', keyUpListener)
    return () => {
      window.removeEventListener('keyup', keyUpListener)
    }
  }, [])
}

export default useKeyUp
