/* eslint-disable import/no-extraneous-dependencies */
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { getHistoryColumnDefinitions } from './column-definitions'
import { useTabStore } from '@features/reports/schedules/use-data-store'
import { useFetchHistoryReport } from '@features/reports/schedules/history/use-fetch-history-report'
import { ReportGeneratedPending } from 'blues-corejs/dist/models/reports/report-generated'
import { useEffect } from 'react'
import { useAlertsActions } from '@features/alerts/hooks/use-alerts-actions'

const REPORTS_INTERVAL_MILLISECONDS = 10000

function HistoryTable() {
  const { paginationRequest, fetchOnRefreshInterval } = useFetchHistoryReport()
  const { isLoading: isActionsLoading } = useAlertsActions()
  const observable = useInfiniteScrollDataFetch({
    initialItems: [],
    fetchNextBatch: () => paginationRequest(),
  })

  const { tabs } = useTabStore()
  const tableData = tabs.history?.data ?? []
  const isLoading = tabs.history?.isLoading || isActionsLoading

  useEffect(() => {
    const fetchInterval = setInterval(
      fetchOnRefreshInterval,
      REPORTS_INTERVAL_MILLISECONDS
    )

    return () => {
      clearInterval(fetchInterval)
    }
  }, [tableData])

  return (
    <Table
      data={tableData}
      columns={getHistoryColumnDefinitions()}
      data-testid="HistoryTable"
      generalProps={{
        noDataMessage: 'No reports found',
        isLoading,
      }}
      advancedProps={{
        observableState: observable,
        warningState: ({ row }) => {
          return row.original.status instanceof ReportGeneratedPending
        },
      }}
    />
  )
}

export default HistoryTable
