/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch } from 'react-redux'
import {
  findAssetById,
  getDataForSelectedAllAssetsWithPaths,
} from '@features/policies/policy/policy-tables'
import SystemHelper from '@lib/helpers/system.helper'
import {
  addS3SelectedAsset,
  removeS3SelectedAsset,
  setAllS3SelectedAssets,
} from '@store/actions/assets-select.action'
import { AssetWithRelatedAssets } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { S3Bucket } from '@lib/models/assets'
import { SelectAllPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import { EmptyAllS3SelectedAssets } from '@lib/constants/app.constant'

interface Props {
  s3Assets: Array<AssetWithRelatedAssets<S3Bucket>>
  onSelectAllPaths: (params: SelectAllPathsInterface) => void
}

export function useS3SelectRows({ s3Assets, onSelectAllPaths }: Props) {
  const dispatch = useDispatch()

  const handleS3Select = ({
    assetId,
    checked,
  }: {
    assetId: string
    checked: boolean
  }) => {
    const foundAsset = findAssetById({
      assets: s3Assets,
      assetId: assetId,
    })
    if (!foundAsset) {
      SystemHelper.throwErrorInLocalEnv(
        'Cannot find an asset in the assets selector'
      )
      return
    }
    let actionToRun = addS3SelectedAsset
    if (!checked) {
      onSelectAllPaths({
        assetId: assetId,
        cbValue: true,
      })
      actionToRun = removeS3SelectedAsset
    } else {
      onSelectAllPaths({
        assetId: assetId,
        cbValue: false,
      })
    }
    dispatch(actionToRun(foundAsset))
  }

  const handleSelectAllS3Rows = (isSelected: boolean) => {
    if (isSelected) {
      dispatch(
        setAllS3SelectedAssets(
          getDataForSelectedAllAssetsWithPaths({ assets: s3Assets })
        )
      )
      return
    }

    dispatch(setAllS3SelectedAssets(EmptyAllS3SelectedAssets()))
  }

  return {
    handleS3Select,
    handleSelectAllS3Rows,
  }
}
