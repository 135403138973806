import React, { useCallback, useMemo, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import SearchBlackIcon from '@inline-img/general/search-black-icon'
import type { EntropySelectionFilter } from '@features/entropy-trends/components/entropy-trends-filters/types'
import { EntropyTrendsSelectionActionButtons } from './selection-action-buttons'
import { ZeroOptionsAvailableText } from './zero-options-text'

interface EntropyTrendSelectionFilterProps<T extends string> {
  options: Array<EntropySelectionFilter<T>>
  selectedOptions: Set<T>
  onSelectionChange: (selectedOptions: Set<T>) => void
}

export function EntropyTrendSelectionFilter({
  options,
  selectedOptions,
  onSelectionChange,
}: EntropyTrendSelectionFilterProps<string>) {
  const [searchValue, setSearchValue] = useState<string>('')

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchValue(event.target.value)
    },
    []
  )

  const filteredOptions = useMemo(
    () => options.filter((option) => option.searchFilterCriteria(searchValue)),
    [options, searchValue]
  )

  const handleSelectOption = (value: string) => {
    const newSelectedOptions = new Set(selectedOptions)

    if (newSelectedOptions.has(value)) {
      newSelectedOptions.delete(value)
    } else {
      newSelectedOptions.add(value)
    }

    onSelectionChange(newSelectedOptions)
  }

  const handleSelectAll = () => {
    const newSelectedOptions = new Set(options.map((option) => option.value))
    onSelectionChange(newSelectedOptions)
  }

  const handleClear = () => {
    onSelectionChange(new Set())
  }

  const shouldRenderCheckboxes = filteredOptions.length > 0

  return (
    <>
      <TextField
        size="small"
        fullWidth
        placeholder="Search..."
        variant="outlined"
        value={searchValue}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: <SearchBlackIcon />,
        }}
      />
      <EntropyTrendsSelectionActionButtons
        handleSelectAll={handleSelectAll}
        handleClearAll={handleClear}
      />
      {shouldRenderCheckboxes ? (
        <FormControl
          component="fieldset"
          variant="standard"
          sx={{ mx: '16px' }}
        >
          <FormGroup>
            {filteredOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox checked={selectedOptions.has(option.value)} />
                }
                onChange={() => handleSelectOption(option.value)}
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      ) : (
        <ZeroOptionsAvailableText />
      )}
    </>
  )
}
