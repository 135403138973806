import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestRevokedTokenList,
  requestTokenList,
  submitRevokeTokenList,
} from '@store/actions/token.action'
import {
  getRevokedTokenList,
  getTokenList,
} from '@store/selectors/token.selector'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import PreloaderConstants from '@lib/constants/preloader.constant'
import CheckboxControlledVITable from '@tables/CheckboxControlledVITable'
import { REVOKE_TOKENS_HEAD } from '@tables/core/table-constants'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import useLocalCheckboxTable from '@lib/hooks/tables/useLocalCheckboxTable'
import TableFactory from '@lib/factories/table.factory'
import useLocalModelPagination from '@lib/hooks/pagination/useLocalModelPagination'
import { VIMatrix } from '@lib/engine-types'
import PaginationBlock from '@components-simple/pagination-block/PaginationBlock'
import clsx from 'clsx'
import SimpleVITable from '@tables/SimpleVITable'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import usePreloader from '@lib/hooks/usePreloader'
import {
  ACCESS_TOKENS_TABLE_MAPPER,
  REVOKED_TOKENS_TABLE_MAPPER,
} from '@tables/core/table-vi-draw-mappers'
import useDemo from '@lib/hooks/useDemo'
import DialogModal from '@components-composite/modals/DialogModal'
import { Button } from '@mui/material'
import { AccountAndSettingsRoutes, Link, useNavigation } from '@lib/router'

enum Menu {
  Edit = 'Edit',
  Revoke = 'Revoke',
}

function ApiAccess() {
  const dispatch = useDispatch()
  const router = useNavigation()

  // loaders
  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_TOKEN_LIST,
    PreloaderConstants.REVOKE_TOKEN,
  ])
  const revokedLoading = usePreloader(
    PreloaderConstants.REQUEST_REVOKED_TOKEN_LIST
  )

  // selectors
  const allTokens = useSelector(getTokenList)
  const allRevokedTokens = useSelector(getRevokedTokenList)

  useEffect(() => {
    dispatch(requestTokenList())
    dispatch(requestRevokedTokenList())
  }, [])

  // tokens list data with pagination
  const tokensData = TableFactory.apiAccessTable(allTokens)
  const {
    currentData,
    currentSelectedData,
    globalCheckboxState,
    setGlobalCheckbox,
    setCheckbox,
  } = useLocalCheckboxTable(tokensData)
  // pagination
  const { paginatedData, pagination, setPagination } =
    useLocalModelPagination<VIMatrix>(currentData, 0, 10)

  // revoke list data with pagination
  const revokedData = TableFactory.revokedTokensHistory(allRevokedTokens)
  // pagination
  const {
    paginatedData: paginatedRevokedData,
    pagination: revokedPagination,
    setPagination: setRevokedPagination,
  } = useLocalModelPagination<VIMatrix>(revokedData, 0, 10)

  // revoke single modal
  const { openModal: openRevokeTokenModal, modalProps: RevokeTokenModalProps } =
    useModal<string>(Modal.revokeToken, (tokenId) => {
      dispatch(submitRevokeTokenList([tokenId]))
    })

  // revoke selected modal
  const {
    openModal: openRevokeSelectedTokensModal,
    modalProps: RevokeSelectedTokensModalProps,
  } = useModal<string>(Modal.revokeMultipleTokens, () => {
    const tokenIdList = currentSelectedData
      .map((row) => row[0]?.name ?? '')
      .filter((v) => !!v)
    dispatch(submitRevokeTokenList(tokenIdList))
  })

  const onRevokeMenu = (selectedMenu: any) => {
    const id = selectedMenu.data[0].name

    // go to the edit token page
    if (Menu.Edit === selectedMenu.chosenMenu) {
      router.push(AccountAndSettingsRoutes.editTokenId(id))
      return
    }

    // single revoke from the menu
    if (Menu.Revoke === selectedMenu.chosenMenu) {
      openRevokeTokenModal(id)
    }
  }

  const { modeNotActive } = useDemo({})

  return (
    <div className="wrap-1603974278684">
      <div className="aatTopBlock">
        <div className="aatTopContent">
          <div className="aatTopLabel jsTopLabelAccessTokens">
            Access Tokens
          </div>
          <div className="aatDesc">
            Access tokens that have been generated to access the Elastio API.{' '}
          </div>
        </div>
        <div className="aatBtnBlock">
          <div>
            <Link to={AccountAndSettingsRoutes.addAccessToken}>
              <Button
                className="jsAddNewToken"
                color="primary"
                variant="contained"
              >
                Add New Access Token
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="aatRegularBlock">
        {allTokens.length > 0 && (
          <div className="aatTopContent">
            <div className="aatTopLabel jsActiveTokens">Active Tokens</div>
            <div className="aatDesc">
              For those that you don&apos;t recognize or that are out-of-date,
              click, then click Revoke. To revoke all tokens, click Revoke all.
            </div>
          </div>
        )}

        {loading && allTokens.length === 0 ? (
          <PreloaderBlock show />
        ) : (
          <>
            {allTokens.length > 0 ? (
              <>
                <div className="aatTopContent">
                  <CheckboxControlledVITable
                    className={clsx('tableVIUncontrolled jsAccessTokenTable', {
                      controlsDisabled: loading,
                      tableVIHideThirdThead: modeNotActive,
                    })}
                    globalCheckboxState={globalCheckboxState}
                    onCheckboxGlobalChange={setGlobalCheckbox}
                    onCheckboxRowChange={setCheckbox}
                    head={REVOKE_TOKENS_HEAD}
                    rows={paginatedData}
                    columnDrawMapper={ACCESS_TOKENS_TABLE_MAPPER()}
                    menu={[Menu.Edit, Menu.Revoke]}
                    onMenuClick={onRevokeMenu}
                  />
                  <PaginationBlock
                    disabled={loading}
                    pagination={pagination}
                    onChange={setPagination}
                  />
                </div>
                <div className="aatBtnBlock aatBtnBlockError">
                  <div>
                    <Button
                      className="jsRevokeMultiple"
                      variant="contained"
                      disabled={currentSelectedData.length === 0}
                      onClick={() => openRevokeSelectedTokensModal()}
                    >
                      Revoke Selected Tokens
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="emptyResultBlock jsEmpty">
                There are no tokens yet
              </div>
            )}
          </>
        )}
      </div>

      <div className="aatRegularBlock">
        <div className="aatTopContent">
          <div className="aatTopLabel jsRevokeHistory">Revoked History</div>
        </div>

        {revokedLoading ? (
          <PreloaderBlock show />
        ) : (
          <>
            {allRevokedTokens.length > 0 ? (
              <>
                <SimpleVITable
                  className={clsx('jsRevokedTokenTable', {
                    controlsDisabled: loading,
                    tableVIHideSecondThead: modeNotActive,
                  })}
                  head={REVOKE_TOKENS_HEAD}
                  rows={paginatedRevokedData}
                  columnDrawMapper={REVOKED_TOKENS_TABLE_MAPPER()}
                />
                <PaginationBlock
                  disabled={revokedLoading}
                  pagination={revokedPagination}
                  onChange={setRevokedPagination}
                />
              </>
            ) : (
              <div className="emptyResultBlock jsEmpty">
                There are no revoked tokens yet
              </div>
            )}
          </>
        )}
      </div>

      <DialogModal
        description="You are going to remove API access for the user, please confirm the action in order to proceed"
        {...RevokeTokenModalProps}
      />
      <DialogModal
        description="You are going to remove selected API access tokens, please confirm the action in order to proceed"
        {...RevokeSelectedTokensModalProps}
      />
    </div>
  )
}

export default memo(ApiAccess)
