import React, { PropsWithChildren } from 'react'
import { EngineCallback, VINumberMap, VIRow } from '@lib/engine-types'
import { OnMenuClickData } from '@tables/core/table-handlers'
import TableHelper from '@lib/helpers/table.helper'
import TableMenu, { MenuAvailability } from '@tables/core/table-menu'
import { drawVICell } from '@tables/core/table-draw'

interface Props<S extends string> {
  data: VIRow
  columnState: Array<number>
  forceTemplate?: Array<number>
  menu: Array<S>
  onMenuClick: EngineCallback<OnMenuClickData<S>>
  showMenu: boolean
  columnDrawMapper?: VINumberMap
  menuIsAvailable?: MenuAvailability<S>
  className?: string
  draggable?: boolean
  onDragEnd?: React.DragEventHandler
  onDragOver?: React.DragEventHandler
  onMouseDown?: React.MouseEventHandler
  ariaLabel?: string
}

function MenuVIRow<S extends string>({
  data,
  columnState,
  forceTemplate,
  menu,
  onMenuClick,
  showMenu,
  columnDrawMapper = {},
  menuIsAvailable = () => true,
  className = '',
  onDragEnd,
  onDragOver,
  onMouseDown,
  ariaLabel,
  // This prop allows to drag elements
  draggable = false,
}: PropsWithChildren<Props<S>>) {
  return (
    <tr
      className={className}
      data-testid="jsMenuVIRow"
      draggable={draggable}
      onDragOver={onDragOver}
      onDrop={onDragEnd}
      onMouseDown={onMouseDown}
      aria-label={ariaLabel}
    >
      {data
        .filter(
          TableHelper.buildStateTemplateFilterFunc(columnState, forceTemplate)
        )
        .map((v, index) => drawVICell(columnDrawMapper, v, index, columnState))}
      <td className="tableMenuWrap">
        <TableMenu<S>
          data={data}
          menu={menu}
          showMenu={showMenu}
          onMenuClick={onMenuClick}
          menuIsAvailable={menuIsAvailable}
        />
      </td>
    </tr>
  )
}

export default MenuVIRow
