import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { StrHelper } from '@lib/helpers'

interface Props {
  cumulativeDataScanned: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    flexBasis="50%"
  >
    {children}
  </Box>
)

function CumulativeDataScannedSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          2 TiB
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" textAlign="center" fontSize="16px">
          Cumulative data scanned
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function CumulativeDataScanned({
  cumulativeDataScanned,
  isLoading,
}: Props) {
  if (isLoading) {
    return <CumulativeDataScannedSkeleton />
  }

  const cumulativeDataScannedFormatted = StrHelper.toHumanBytes(
    cumulativeDataScanned,
    '0',
    0
  )

  return (
    <Container>
      <Typography fontWeight="600" fontSize="22px">
        {cumulativeDataScannedFormatted}
      </Typography>
      <Typography fontWeight="400" textAlign="center" fontSize="16px">
        Cumulative data scanned
      </Typography>
    </Container>
  )
}
