/* eslint-disable import/no-extraneous-dependencies */
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import React, { useCallback } from 'react'
import { getHumanReadableCloudConnectorJobKind } from '@features/jobs/cloud-connector-jobs/helpers'
import { StyledCheckbox, StyledFormControlLabel } from './styles'

interface Props {
  kind: JobCloudConnectorKind
  onChange: (checked: boolean, kind: JobCloudConnectorKind) => void
  isChecked: boolean
}

export function CloudConnectorJobKindCheckbox({
  kind,
  onChange,
  isChecked,
}: Props) {
  const handleCheckboxChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked, kind)
    },
    [kind, onChange]
  )

  return (
    <StyledFormControlLabel
      label={getHumanReadableCloudConnectorJobKind(kind)}
      control={
        <StyledCheckbox checked={isChecked} onChange={handleCheckboxChange} />
      }
    />
  )
}
