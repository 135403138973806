import React from 'react'

import SpeedometerHighRiskIcon from './speedometer-high'
import SpeedometerMediumRiskIcon from './speedometer-medium'
import SpeedometerLowRiskIcon from './speedometer-low'
import { Risk } from '../types'

export default function getIconByRisk(risk: Risk[keyof Risk]) {
  switch (risk) {
    case Risk.HIGH:
      return <SpeedometerHighRiskIcon />
    case Risk.MEDIUM:
      return <SpeedometerMediumRiskIcon />
    case Risk.LOW:
      return <SpeedometerLowRiskIcon />
    default:
      return null
  }
}
