import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

interface Props {
  text?: string
}

const StyledButton = styled(Box)({
  boxShadow: 'none !important',
  height: '33px !important',
  width: '110px !important',
  minWidth: '110px !important',
  backgroundColor: '#33B6F2',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px, 12px, 4px, 12px',
  borderRadius: '4px',
  fontWeight: '600',
  fontSize: '14px',
  userSelect: 'none',
})

const DEFAULT_DELETED_STATE_TEXT = 'Replicated'

export function ReplicatedState({ text = DEFAULT_DELETED_STATE_TEXT }: Props) {
  return <StyledButton>{text}</StyledButton>
}
