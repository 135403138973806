import FormRestoreInterface from '@lib/interfaces/form/form-restore.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormRestoreInterface,
  FormFieldInterface
> => ({
  tags: {
    name: 'tags',
    label: 'Tag(s)',
    options: [],
    validationRules: {},
  },
  recoveryPointId: {
    name: 'recoveryPointId',
  },
  vpc: {
    name: 'vpc',
    label: 'VPC',
    value: '',
    validationRules: {},
  },
  subnet: {
    name: 'subnet',
    label: 'Subnet',
    value: '',
    validationRules: {},
  },
  zone: {
    name: 'zone',
    label: 'Availability zone',
    value: '',
    validationRules: {},
  },
  securityGroups: {
    name: 'securityGroups',
    label: 'Security Groups',
    value: '',
    validationRules: {},
  },
  securityGroupIdsList: {
    name: 'securityGroupIdsList',
  },
})

export default buildDefaultFormData
