/* eslint-disable import/no-extraneous-dependencies */
import { EFS } from 'blues-corejs/dist'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

export function computeEfsState(filesystem: EFS): string {
  if (filesystem.state === EfsState.IN_USE) {
    return 'In Use'
  }

  if (filesystem.state === EfsState.DELETED) {
    return 'Deleted'
  }

  if (filesystem.state === EfsState.UNKNOWN) {
    return 'Unknown'
  }

  sentryReThrowCatchHandler(
    `computeEfsState: Unknown state: ${filesystem.state}`
  )
  throw new Error(`computeEfsState: Unknown state: ${filesystem.state}`)
}
