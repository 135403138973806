import { StoreReturnType } from '@store/store'

export const getDashboardData = (state: StoreReturnType) =>
  state.dashboardState.dashboardData

export const getEbsVulnerabilitiesSelectedFilters = (state: StoreReturnType) =>
  state.dashboardState.selectedVolumesVulnerabilitiesFilters

export const getSnapshotsVulnerabilitiesSelectedFilters = (
  state: StoreReturnType
) => state.dashboardState.selectedSnapshotsVulnerabilitiesFilters
