import React, { Fragment } from 'react'
import RedStackModel from '@lib/models/red-stack.model'
import { Box } from '@mui/material'

interface RedStackByCcIdParams {
  ccId: string
  allRedStacksList: Array<RedStackModel>
}

function findRedStackByCcId({
  ccId,
  allRedStacksList,
}: RedStackByCcIdParams): RedStackModel | undefined {
  const redstack = allRedStacksList.find((r) => String(r.redStackId) === ccId)

  if (!redstack) {
    return
  }

  return redstack
}

export function formatRedStackAccountAndRegion({
  ccId,
  allRedStacksList,
}: RedStackByCcIdParams): JSX.Element | null {
  const redstack = findRedStackByCcId({
    ccId,
    allRedStacksList,
  })

  if (!redstack) {
    return null
  }

  return (
    <Fragment>
      <Box>{redstack.awsAccount}</Box>
      <Box>({redstack.awsRegion})</Box>
    </Fragment>
  )
}
