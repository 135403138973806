import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'

import { Controller, useFormContext } from 'react-hook-form'
import ProtectionOptionsCheckbox from '../protection-options-checkbox'

const Ec2EbsRecoverySettings = ({
  reviewPolicy,
}: Ec2EbsRecoverySettingsProps) => {
  const { control } = useFormContext<PolicyFormData>()
  return (
    <>
      <Controller
        name="keepDataCopy"
        control={control}
        render={({ field }) => (
          <ProtectionOptionsCheckbox
            label="Always keep the latest clean copy of data"
            protectionOptionValue={field.value.keepLastClean}
            onProtectionOptionChange={(e) => {
              field.onChange({
                ...field.value,
                keepLastClean: e.target.checked,
              })
            }}
            disabled={reviewPolicy}
          />
        )}
      />
      <Controller
        name="keepDataCopy"
        control={control}
        render={({ field }) => (
          <ProtectionOptionsCheckbox
            label="Always keep the latest infected copy of data"
            protectionOptionValue={field.value.keepLatestInfected}
            onProtectionOptionChange={(e) => {
              field.onChange({
                ...field.value,
                keepLatestInfected: e.target.checked,
              })
            }}
            disabled={reviewPolicy}
          />
        )}
      />
    </>
  )
}
interface Ec2EbsRecoverySettingsProps {
  reviewPolicy?: boolean
}
export default Ec2EbsRecoverySettings
