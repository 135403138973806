import { useState } from 'react'
import Button from 'ui-v2/src/components/ui/inputs/button'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { Stack, FormControl, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import TagLists from './tag-lists'
import { VIRow } from 'ui-v2/src/lib/engine-types'
import { useFormContext } from 'react-hook-form'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'

const Tags = () => {
  const { getValues, setValue, trigger } = useFormContext<PolicyFormData>()

  const disabled = false

  const [tagKey, setTagKey] = useState<string>('')
  const [tagValue, setTagValue] = useState<string>('')

  const onAddClick = () => {
    const valueToAdd = {
      name: tagKey,
      value: tagValue,
    }

    const result: VIRow = getValues('policyTags').filter(
      (elem) => elem.name !== valueToAdd.name
    )
    result.push(valueToAdd)

    setValue('policyTags', result)
    setTagKey('')
    setTagValue('')
    trigger('selectedVariant')
  }

  const addDisabled =
    disabled ||
    !RegexConstants.TAG_NAME.test(tagKey) ||
    !RegexConstants.TAG_VALUE.test(tagValue)

  return (
    <Grid container spacing={3}>
      <Grid size={10}>
        <Typography variant="body2" fontWeight={400} color="text.secondary">
          Define tags to specify which assets to protect when the policy runs.
          The policy dynamically identifies assets based on these tags during
          execution, allowing you to include assets and tags that may not exist
          at the time of policy creation.
        </Typography>
      </Grid>
      <Grid size={10}>
        <Stack spacing={1} direction="row" alignItems={'center'}>
          <FormControl fullWidth sx={{ maxWidth: 315 }}>
            <TextField
              placeholder="Tag Key"
              value={tagKey}
              onChange={(e) => setTagKey(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ maxWidth: 315 }}>
            <TextField
              placeholder="Tag Value"
              value={tagValue}
              onChange={(e) => setTagValue(e.target.value)}
            />
          </FormControl>
          <Button
            disabled={addDisabled}
            variant="contained"
            onClick={onAddClick}
          >
            Add
          </Button>
        </Stack>
      </Grid>
      <Grid size={10}>
        <TagLists />
      </Grid>
    </Grid>
  )
}

export default Tags
