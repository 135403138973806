import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { VaultClient, RexClient } from 'ui-v2/src/lib/clients'

import { useQuery } from '@tanstack/react-query'

export function useListVaultAllRedstacksQuery() {
  const vaultClient = useGrpcClient(VaultClient)
  const rexClient = useGrpcClient(RexClient)

  return useQuery({
    queryKey: ['vault', 'list-vault-all-redstacks'],
    queryFn: async () => {
      if (!vaultClient) {
        throw new Error('Retention policy client not initialized')
      }
      if (!rexClient) {
        throw new Error('Rex client not initialized')
      }
      return vaultClient.listVaultsForAllRedStacks()
    },
    enabled: !!vaultClient && !!rexClient,
  })
}
