import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import Button from 'ui-v2/src/components/ui/inputs/button'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { FileCopyOutlined } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'

const GeneratedAccessToken = ({
  open,
  token,
  onClose,
}: GeneratedAccessTokenProps) => {
  return (
    <Dialog open={open}>
      <Box p={2.5} pb={0} textAlign={'center'}>
        <Typography variant="h5" mb={2}>
          Congratulations New Token Created!
        </Typography>

        <Typography>
          A token has been generated that can be used to access elastio API.
          Make sure to copy your new token now. You won’t be able to see it
          again.
        </Typography>
      </Box>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => copyToClipboard(token)}
                    >
                      <FileCopyOutlined fontSize={'small'} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            value={token}
            disabled
          />
          <Button variant="contained" fullWidth onClick={onClose} type="button">
            Back
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
interface GeneratedAccessTokenProps {
  open: boolean
  token: string
  onClose: () => void
}
export default GeneratedAccessToken
