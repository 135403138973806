/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  FilesystemScanCheck,
  MalwareScan,
  OvaBackup,
  RansomwareScan,
} from 'blues-corejs/dist/models'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Backup } from 'blues-corejs/dist'
import { RansomwareBlock } from './ransomware-block'
import { FsCheckBlock } from './filesystem-block'
import { MalwareBlock } from './malware-block'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type Props = {
  backup?: Backup
  scans?: Array<Scan>
}

function RowTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      marginRight="5px"
      variant="caption"
    >
      {children}:{' '}
    </Typography>
  )
}

function DetectedTreatsIcons({ backup, scans }: Props) {
  if (!backup && !scans) {
    sentryReThrowCatchHandler(
      'No backup or scans provided, failed to render detected treats icons'
    )
    return null
  } else if (backup && scans) {
    sentryReThrowCatchHandler(
      'Both backup and scans provided, failed to render detected treats icons'
    )
    return null
  } else if (backup instanceof OvaBackup) {
    return (
      <Box display="flex" alignItems="center">
        <RowTitle>Threat</RowTitle>
        <RansomwareBlock backup={backup} scans={scans} />
        <MalwareBlock backup={backup} scans={scans} />
      </Box>
    )
  } else {
    return (
      <Box display="flex" alignItems="center">
        <RowTitle>Threat</RowTitle>
        <RansomwareBlock backup={backup} scans={scans} />
        <MalwareBlock backup={backup} scans={scans} />
        <FsCheckBlock backup={backup} scans={scans} />
      </Box>
    )
  }
}

export default DetectedTreatsIcons
