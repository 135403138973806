/* eslint-disable import/no-extraneous-dependencies */
import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'
import { VIRow } from '@lib/engine-types'
import FilterFactory from '@lib/factories/filter.factory'
import { SnapshotVulnerabilitiesFilters } from 'blues-corejs/dist/use_cases/get_snapshots_vulnerabilities_filters/types'
import { VolumesVulnerabilitiesFilters } from 'blues-corejs/dist/use_cases/get_volumes_vulnerabilities_filters/types'
import DataHelper from '@lib/helpers/data.helper'
import ValueInterface from '@lib/interfaces/value.interface'
import {
  VolumesWithVulnerabilitiesFilters,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import { VolumeVulnerabilityKind } from 'blues-corejs/dist/models/vulnerability'

function getPossibleRisksList({
  vulnerabilitiesDetectionStrategy,
  riskTypes,
  selectedFilters,
}: {
  vulnerabilitiesDetectionStrategy: VulnerabilitiesDetectionStrategy
  selectedFilters: VolumesWithVulnerabilitiesFilters
  riskTypes?: Array<number>
}) {
  if (!riskTypes) {
    return
  }
  const selectedEbsVulnerabilities = selectedFilters.ebsVulnerabilities
  const selectedRiskTypes = selectedFilters?.riskType ?? []

  if (!selectedEbsVulnerabilities) {
    return riskTypes
  }

  return riskTypes.filter(
    (riskType) =>
      selectedEbsVulnerabilities.some(
        (vulnerability) =>
          vulnerabilitiesDetectionStrategy.identifyRiskLevel(vulnerability) ===
          riskType
      ) || selectedRiskTypes.includes(riskType)
  )
}

function getPossibleEbsVulnerabilities({
  ebsVulnerabilities,
  vulnerabilitiesDetectionStrategy,
  selectedFilters,
}: {
  vulnerabilitiesDetectionStrategy: VulnerabilitiesDetectionStrategy
  selectedFilters: VolumesWithVulnerabilitiesFilters
  ebsVulnerabilities?: Array<VolumeVulnerabilityKind>
}) {
  if (!ebsVulnerabilities) {
    return
  }

  const selectedRiskTypes = selectedFilters?.riskType
  const selectedVulnerabilities = selectedFilters?.ebsVulnerabilities ?? []

  if (!selectedRiskTypes) {
    return ebsVulnerabilities
  }

  return ebsVulnerabilities.filter(
    (vulnerability) =>
      selectedRiskTypes.includes(
        vulnerabilitiesDetectionStrategy.identifyRiskLevel(vulnerability)
      ) || selectedVulnerabilities.includes(vulnerability)
  )
}

export function getVolumeVulnerabilitiesPossibleFilters(
  filtersVolumesVulnerabilities: VolumesVulnerabilitiesFilters,
  selectedFilters: Array<ValueInterface>,
  vulnerabilitiesDetectionStrategy: VulnerabilitiesDetectionStrategy
): VIRow {
  const selectedFiltersForDashboard = DataHelper.getDataForDashboardFilter(
    selectedFilters
  ) as VolumesWithVulnerabilitiesFilters

  const possibleFilters = [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: filtersVolumesVulnerabilities.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: filtersVolumesVulnerabilities.regions,
    },
    {
      id: FilterIdDashboard.VOLUME_AWS_ID,
      volumeAwsIds: filtersVolumesVulnerabilities.volumeAwsIds,
    },
    {
      id: FilterIdDashboard.ATTACHED_INSTANCE_AWS_ID,
      attachedInstancesAwsIds:
        filtersVolumesVulnerabilities.attachedInstancesAwsIds,
    },
    {
      id: FilterIdDashboard.EBS_VULNERABILITIES,
      ebsVulnerabilities: getPossibleEbsVulnerabilities({
        ebsVulnerabilities: filtersVolumesVulnerabilities.ebsVulnerabilities,
        selectedFilters: selectedFiltersForDashboard,
        vulnerabilitiesDetectionStrategy,
      }),
    },
    {
      id: FilterIdDashboard.RISK_TYPE,
      riskType: getPossibleRisksList({
        riskTypes: filtersVolumesVulnerabilities.riskType,
        vulnerabilitiesDetectionStrategy,
        selectedFilters: selectedFiltersForDashboard,
      }),
    },
  ]

  return possibleFilters.map(FilterFactory.buildAssetsVulnerabilityFilter)
}

export function getSnapshotVulnerabilitiesPossibleFilters(
  filtersSnapshotsVulnerabilities: SnapshotVulnerabilitiesFilters
) {
  const possibleFilters = [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: filtersSnapshotsVulnerabilities.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: filtersSnapshotsVulnerabilities.regions,
    },
    {
      id: FilterIdDashboard.SNAPSHOT_VULNERABILITY_TYPES,
      snapshotVulnerabilityTypes:
        filtersSnapshotsVulnerabilities.snapshotVulnerabilityTypes,
    },
    {
      id: FilterIdDashboard.RISK_TYPE,
      riskType: filtersSnapshotsVulnerabilities.riskType,
    },
  ]

  return possibleFilters.map(FilterFactory.buildSnapshotsVulnerabilityFilter)
}
