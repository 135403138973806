import {
  FilesystemScanTransformer,
  MalwareScanTransformer,
  RansomwareScanTransformer,
} from '@lib/clients/assets/transformers'
/* eslint-disable import/no-extraneous-dependencies */
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/list_scans_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { ScansPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import { ListScans } from './types'

export class ListScansClient extends GrpcClient<ScansPromiseClient> {
  #scansClient: ScansPromiseClient

  constructor(hostName = '') {
    super()
    this.#scansClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListScansClient'
  }

  protected initClient(hostName = ''): ScansPromiseClient {
    return new ScansPromiseClient(hostName, null, null)
  }

  async listScans(ids: Array<string>): Promise<ListScans> {
    const request = new Request().setScanIdsList(ids)
    const response = (
      await this.retryGrpcCall(
        () => this.#scansClient.listScans(request, this.metadata()),
        {
          requestName: 'ScansPromiseClient/listScans',
        }
      )
    ).toObject()

    return this.#transformListScansResponse(response)
  }

  #transformListScansResponse({
    filesystemChecksList,
    ransomwaresList,
    malwaresList,
  }: Response.AsObject): ListScans {
    return {
      filesystemChecks: filesystemChecksList.map((filesystemCheck) => {
        return new FilesystemScanTransformer(filesystemCheck).transform()
      }),
      malwares: malwaresList.map((malware) =>
        new MalwareScanTransformer(malware).transform()
      ),

      ransomwares: ransomwaresList.map((ransomware) =>
        new RansomwareScanTransformer(ransomware).transform()
      ),
    }
  }
}
