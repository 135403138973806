import React, { memo, useEffect, useState } from 'react'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { EngineCallback } from '@lib/engine-types'
import IntegrityScan from '@components-composite/integrity-scan/IntegrityScan'
import SimpleSelect from '@components-simple/simple-select/SimpleSelect'
import { getIntegrityScanOptionsList } from './utils'
import ValueInterface from '@lib/interfaces/value.interface'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'
import KeepDataCopy from './KeepDataCopy'
import Box from '@mui/material/Box'
import { useFlags } from 'launchdarkly-react-client-sdk'

export interface IntegrityScanBlockProps {
  integrityScanData: IntegrityScanInterface
  onIntegrityScanChange: EngineCallback<IntegrityScanInterface>
  integrityCheckEnabled: boolean
  onIntegrityCheckClick: EngineCallback<boolean>
  isRecoveryTestingDisabled: boolean
  onIntegrityScanOptionsChange: EngineCallback<IntegrityScanOptions>
  integrityScanOptionSelected: IntegrityScanOptions
  keepDataCopyData: KeepDataCopyInterface
  onKeepDataCopyChange: EngineCallback<KeepDataCopyInterface>
  entropyDetectionsOptions: {
    isEntropyEnabled: boolean
    onEntropyEnabledChange: (value: boolean) => void
  }
}

function IntegrityScanBlock({
  integrityScanData,
  onIntegrityScanChange,
  integrityCheckEnabled,
  onIntegrityCheckClick,
  isRecoveryTestingDisabled,
  onIntegrityScanOptionsChange,
  integrityScanOptionSelected,
  keepDataCopyData,
  onKeepDataCopyChange,
  entropyDetectionsOptions,
}: IntegrityScanBlockProps) {
  const { backupOptionsVisibilityInPolicy } = useFlags()
  const options = getIntegrityScanOptionsList(
    integrityScanOptionSelected,
    backupOptionsVisibilityInPolicy
  )
  const [isAllOptionsDisabled, setAllOptionsDisabled] = useState<boolean>(false)
  const [isShowKeepDataCopy, setShowKeepDataCopy] = useState<boolean>(false)

  const onIntegrityScanOptionChangeInner = (
    newIntegrityScanOption: ValueInterface
  ) => {
    const selectedOption = Number(newIntegrityScanOption.value)
    onIntegrityScanOptionsChange(selectedOption)
  }

  useEffect(() => {
    switch (integrityScanOptionSelected) {
      case IntegrityScanOptions.BACKUP_WITH_ELASTIO:
        setAllOptionsDisabled(true)
        setShowKeepDataCopy(false)
        return
      case IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO:
        setAllOptionsDisabled(false)
        setShowKeepDataCopy(false)
        return
      case IntegrityScanOptions.SCAN_WITH_ELASTIO:
        setAllOptionsDisabled(false)
        setShowKeepDataCopy(true)
        return
    }
  }, [JSON.stringify(integrityScanOptionSelected)])

  return (
    <Box className="wrap-1698248386885" data-testid="integrity-scan-block">
      <Box className="integrityScanOptionsWrapper">
        <Box className="addPolicyTitle">Protection Options</Box>
        <SimpleSelect
          data={options}
          onChange={onIntegrityScanOptionChangeInner}
          className="whiteFilledSelect"
        />
        {isShowKeepDataCopy && (
          <KeepDataCopy
            data={keepDataCopyData}
            onChange={onKeepDataCopyChange}
          />
        )}
      </Box>
      <IntegrityScan
        data={integrityScanData}
        onChange={onIntegrityScanChange}
        integrityCheckEnabled={integrityCheckEnabled}
        onIntegrityCheckClick={onIntegrityCheckClick}
        isRecoveryTestingDisabled={isRecoveryTestingDisabled}
        isAllOptionsDisabled={isAllOptionsDisabled}
        entropyDetectionsOptions={entropyDetectionsOptions}
      />
    </Box>
  )
}

export default memo(IntegrityScanBlock)
