import { Box, styled } from '@mui/material'
import { ASSET_LINES } from '../icons/consts'

export const StyledContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  position: 'relative',
  marginBottom: '40px',
  '::before': {
    display: 'flex',
    justifyContent: 'center',
    content: ASSET_LINES,
    position: 'absolute',
    rotate: '180deg',
    top: '100%',
    width: '100%',
    height: '25px',
  },

  '.MuiButton-root': {
    maxWidth: '80px',
    minWidth: 'auto',
  },
})
