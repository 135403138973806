/* eslint-disable import/no-extraneous-dependencies */
import {
  CellLongTextWithTooltip,
  CellWithDateTimeTooltip,
  CellWithLink,
  CopyCell,
  createColumn,
  createDisplayColumn,
  HeaderWithLabel,
  StatusCircle,
} from '@components/table'
import React from 'react'
import styles from './EfsInventoryTableComplex.module.css'
import LangHelper from '@lib/helpers/lang.helper'
import { AliasNamesForS3Bucket } from '@lib/interfaces/inventory/s3-bucket.interface'
import {
  EfsInventoryOutput,
  SummarizedEfsDataOutput,
} from 'blues-corejs/dist/use_cases/list_efs_inventory/types'
import { Nullable } from '@lib/engine-types'
import { INVENTORY_TABLE_MIN_HEIGHT } from '../inventory-tables-complex/shared/consts'
import { customColorResolver } from '@components-complex/dashboard-pages-v3/utils'
import { StyledCheckbox } from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/styles'
import { AssetRoutes } from '@lib/router'

type InputData = {
  summarizedData: SummarizedEfsDataOutput
  aliasNamesWithId: AliasNamesForS3Bucket
}

export function getEfsTableColumnDefinitions({
  summarizedData,
  aliasNamesWithId,
}: InputData) {
  return [
    createDisplayColumn<EfsInventoryOutput>({
      header: ({ table }) => (
        <StyledCheckbox
          indeterminate={table.getIsSomeRowsSelected()}
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      size: 60,
      id: 'row-selection',
      cell: ({ row }) => {
        return (
          <StyledCheckbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      },
    }),
    createColumn<EfsInventoryOutput>('isInfected', {
      enableSorting: false,
      size: 45,
      cell: ({ row, getValue }) => (
        <StatusCircle
          colorResolver={customColorResolver(row.original.lastScan)}
          isHealthy={!getValue() as unknown as boolean}
        />
      ),
    }),
    createColumn<EfsInventoryOutput>('name', {
      header: () => (
        <HeaderWithLabel
          title="File system name"
          label={String(summarizedData.nameCount)}
        />
      ),
      meta: {
        height: INVENTORY_TABLE_MIN_HEIGHT,
      },
      sortingFn: (a, b) => {
        const efsA = a.original
        const efsB = b.original

        const efsAName = efsA.name || efsA.awsId
        const efsBName = efsB.name || efsB.awsId

        return efsAName.localeCompare(efsBName)
      },
      cell: ({ getValue, row }) => {
        const efsName = getValue() as unknown as string
        const awsId = row.original.awsId
        const href = AssetRoutes.assetId(row.original.uuid)
        const displayedValue = efsName || awsId
        const displayedLabel = efsName ? awsId : efsName
        const className = styles.idName

        return (
          <CellWithLink
            href={href}
            value={displayedValue}
            label={displayedLabel}
            className={className}
          />
        )
      },
    }),

    createColumn<EfsInventoryOutput>('accountId', {
      enableSorting: false,
      header: () => (
        <HeaderWithLabel
          title="Account"
          label={String(summarizedData.accountCount)}
        />
      ),
      cell: ({ getValue }) => {
        const className = aliasNamesWithId[getValue()] ? styles.copyRow : ''

        return (
          <CopyCell
            additionalText={aliasNamesWithId[getValue()]}
            copyText={getValue()}
            className={className}
          />
        )
      },
    }),
    createColumn<EfsInventoryOutput>('region', {
      enableSorting: false,
      header: () => (
        <HeaderWithLabel
          title="Region"
          label={String(summarizedData.regionCount)}
        />
      ),
      cell: ({ getValue }) =>
        LangHelper.getAwsRegionSingleTranslation(getValue()),
    }),

    createColumn<EfsInventoryOutput>('lastScan', {
      header: 'Last scan',
      cell: ({ getValue }) => {
        const lastScan = getValue() as unknown as Nullable<Date>

        return <CellWithDateTimeTooltip date={lastScan} />
      },
    }),
    // Temporary hide
    // createColumn<EfsInventoryOutput>('state', {
    //   enableSorting: false,
    //   header: 'State',
    //   cell: ({ getValue }) => {
    //     const state = getValue() as unknown as number | string
    //     switch (state) {
    //       case EfsState.IN_USE:
    //         return 'In use'
    //       case EfsState.DELETED:
    //         return 'Deleted'
    //
    //       // TODO: remove this when the backend is fixed
    //       default:
    //         return state
    //     }
    //   },
    // }),
    //

    createColumn<EfsInventoryOutput>('backupPolicies', {
      header: 'Policy Coverage',
      cell: ({ getValue }) => {
        const policiesList = getValue() as unknown as Array<string>

        return <CellLongTextWithTooltip title={policiesList.join(', ')} />
      },
    }),
  ]
}
