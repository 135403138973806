import type { GenericHostInventoryOutput } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'
import { Nullable } from '@lib/engine-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TimeFormatConstants } from '@lib/constants'

dayjs.extend(utc)

function convertDateToString(date: Nullable<Date>) {
  if (!date) {
    return ''
  }

  return `${dayjs(date)
    .utc()
    .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
}

function prepareGenericHostDataForCsv(
  genericHostList: Array<GenericHostInventoryOutput>
) {
  return genericHostList.map((host) => {
    return {
      hostName: host.genericHostName,
      threats: host.threats.toString(),
      lastScan: convertDateToString(host.lastScan),
    }
  })
}

const GENERIC_HOST_CSV_TABLE_HEADERS = [
  {
    label: 'Host name',
    key: 'hostName',
  },
  {
    label: 'Threats',
    key: 'threats',
  },
  {
    label: 'Last Scan',
    key: 'lastScan',
  },
]

export { prepareGenericHostDataForCsv, GENERIC_HOST_CSV_TABLE_HEADERS }
