import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.yellow[600],
  boxShadow: 'none !important',
  height: '33px !important',
  width: '110px !important',
  minWidth: '110px !important',
  ':hover': {
    backgroundColor: theme.palette.yellow[600],
    boxShadow: 'none',
  },
}))

interface Props {
  text?: string
}

const DEFAULT_DELETED_STATE_TEXT = 'Soft deleted'

export function SoftDeletedState({ text = DEFAULT_DELETED_STATE_TEXT }: Props) {
  return <StyledButton variant="contained">{text}</StyledButton>
}
