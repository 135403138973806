import VaultModel from '@lib/models/vault.model'
import RedStackModel from '@lib/models/red-stack.model'
import ValueInterface from '@lib/interfaces/value.interface'
import VaultMetricsModel from '@lib/models/vault-metrics.model'
import ArrHelper from '@lib/helpers/arr.helper'

export const getAccountVaultList = (state: any): Array<VaultModel> =>
  state.vaultState.accountVaultList

export const getSourcesVaultList = (state: any): Array<VaultModel> =>
  state.vaultState.sourcesVaultList

export const getVaultList = (state: any): Array<VaultModel> =>
  state.vaultState.vaultList

export function getVaultsNamesByRedStackId(redStackId: string) {
  return (state: any): Array<ValueInterface> =>
    state.vaultState.vaultList
      .filter((vaultModel: VaultModel) => vaultModel.redStackId === redStackId)
      .map((v: VaultModel) => ({
        name: v.name,
        value: v.redStackId,
        label: v.innerId,
      }))
}
export const getAccountRedStacks = (state: any): Array<RedStackModel> =>
  state.vaultState.accountRedStacks

export const getActiveAccountRedStacks = (state: any): Array<RedStackModel> =>
  state.vaultState.accountRedStacks.filter(
    (item: RedStackModel) => item.isActive
  )

export const getInactiveAccountRedStacks = (state: any): Array<RedStackModel> =>
  state.vaultState.accountRedStacks.filter(
    (item: RedStackModel) => item.isInactive
  )

export const getAllActiveRedStacks = (state: any): Array<RedStackModel> =>
  state.vaultState.allActiveRedStacks

export const getAccountRedStackVersions = (state: any): Array<string> => {
  const allRsVersions: Array<string> = getActiveAccountRedStacks(state).map(
    (rs: RedStackModel) => rs.awsAccountCfnVersion
  )
  return ArrHelper.unique(allRsVersions).sort().reverse()
}

export const getVaultModel = (state: any): VaultModel =>
  state.vaultState.vaultModel

export const getVaultMetrics = (state: any): VaultMetricsModel =>
  state.vaultState.vaultMetricsModel
