/* eslint-disable import/no-extraneous-dependencies */
import { Row } from '@tanstack/react-table'
import { S3Bucket, EFS } from 'blues-corejs/dist'
import React, { ChangeEvent } from 'react'
import { FormControlLabel } from '@mui/material'
import { isSelectedAllPathsForAsset } from '@features/policies/policy/policy-tables'
import { EngineCallback } from '@lib/engine-types'
import { SelectAllPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import { StyledCheckbox } from '@features/policies/policy/policy-tables/styles'

interface Props {
  row: Row<any>
  asset: S3Bucket | EFS
  selectedAllPaths: Array<string>
  onSelectAllPaths: EngineCallback<SelectAllPathsInterface>
}

export function PolicySelectAllPathsCell({
  row,
  asset,
  selectedAllPaths,
  onSelectAllPaths,
}: Props) {
  const isSelectedAllPath = isSelectedAllPathsForAsset(
    selectedAllPaths,
    asset.id
  )

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { checked } = evt.target
    if (checked) {
      row.toggleSelected(true)
      row.toggleExpanded(false)
    } else {
      row.toggleExpanded(true)
    }

    onSelectAllPaths({
      assetId: asset.id,
      cbValue: !checked,
    })
  }
  return (
    <FormControlLabel
      label="All paths"
      control={
        <StyledCheckbox checked={isSelectedAllPath} onChange={handleChange} />
      }
    />
  )
}
