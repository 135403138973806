import { Typography } from '@mui/material'

const AwsUpdating = () => {
  return (
    <Typography variant="body1" fontWeight={400} color="text.secondary">
      To enable your AWS account with Elastio, you will need to deploy Elastio
      CloudFormation stack.
    </Typography>
  )
}

export default AwsUpdating
