import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'

interface LabelOption {
  value: string | number
  label: string
}

interface RadioGroupControllerOwnProps {
  name: string
  defaultValue?: string | number
  labelOptions: Array<LabelOption>
}

function RadioGroupController({
  name,
  defaultValue,
  labelOptions = [],
}: RadioGroupControllerOwnProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup row {...field}>
          {labelOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}

export default RadioGroupController
