export const reportLabelMapper = {
  awsExecutiveReport: 'AWS Ransomware Readiness Report',
  awsAccountSummaryReport: 'AWS Account Summary Report',
  awsTenantSummaryReport: 'AWS Operational Summary Report',

  // Detailed Reports
  awsCompletedScansReport: 'AWS All Scans',
  awsScannedAssetsSummaryReport: 'AWS Assets Scanned',
  awsInfectedAssetsReport: 'AWS Infected Assets',
  infectedFilesReport: 'AWS Infected Files',
  failedScansReport: 'AWS Failed Scans',

  // Backup Connector Summary Reports
  ovaMspOperationalReport: 'OVA Operational Report',
  ovaMspExecutiveReport: 'OVA Ransomware Readiness Report',
  ovaMspTenantSummaryReport: 'OVA Operational Summary Report',
  ovaMspAccountSummaryReport: 'OVA Account Summary Report',
  ovaMspCompletedScansReport: 'OVA All Scans',
  ovaMspScannedAssetsSummaryReport: 'OVA Assets Scanned',
  ovaMspInfectedAssetsReport: 'OVA Infected Assets',
  ovaMspInfectedFilesReport: 'OVA Infected Files',
  ovaMspFailedScansReport: 'OVA Failed Scans',
} as const

export type ReportType = keyof typeof reportLabelMapper
