/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

export function EntropyTrendAssetFilterLabel({ asset }: { asset: AWSAsset }) {
  if (!asset.name) {
    return (
      <Typography variant="caption" fontSize="14px" fontWeight="500">
        {asset.awsId}
      </Typography>
    )
  }

  return (
    <Stack>
      <Typography
        fontSize="14px"
        variant="caption"
        fontWeight="500"
        lineHeight="20px"
      >
        {asset.name}
      </Typography>
      <Typography variant="caption" fontSize="11px">
        {asset.awsId}
      </Typography>
    </Stack>
  )
}
