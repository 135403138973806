import { CloudConnectorJobFiltersUI } from '@lib/interfaces/jobs'
import { CloudConnectorFiltersStore } from '.'

// Type to get one value from a Set
// For example: SetMember<JobKind> will return one JobKind
type SetMember<T> = T extends Set<infer E> ? E : never

function removeFilterFromSet<
  V extends SetMember<
    CloudConnectorJobFiltersUI[keyof CloudConnectorJobFiltersUI]
  >,
>(
  value: V,
  filters: CloudConnectorJobFiltersUI[keyof CloudConnectorJobFiltersUI]
) {
  return new Set([...filters].filter((filter) => filter !== value))
}

function removeFilter(
  key: keyof CloudConnectorJobFiltersUI,
  value: SetMember<
    CloudConnectorJobFiltersUI[keyof CloudConnectorJobFiltersUI]
  >,
  state: CloudConnectorFiltersStore
) {
  return {
    selectedFilters: {
      ...state.selectedFilters,
      [key]: removeFilterFromSet(value, state.selectedFilters[key]),
    },
  }
}

export { removeFilter }
