/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import {
  Asset,
  AWSBRecoveryPoint,
  Backup,
  CloudConnector,
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import Stack from '@mui/material/Stack'
import { RexClient } from '@lib/clients'
import Typography from '@mui/material/Typography'
import { CopyToClipboardIcon } from '@features/common'
import RegionIcon from '@mui/icons-material/Place'
import LangHelper from '@lib/helpers/lang.helper'
import { useFetchBackupsByIds } from '@lib/hooks/backups/use-fetch-backups-by-ids'
import { BackupById } from '@lib/interfaces/backup'
import Skeleton from '@mui/material/Skeleton'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

interface Props {
  asset: Asset
  scan: Scan
}

function RowTitle({ title }: { title: string }) {
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      marginRight="5px"
      variant="caption"
    >
      {title}:
    </Typography>
  )
}

function getIsRecoveryPointReplicated(
  backup: AWSBRecoveryPoint,
  asset: AWSAsset
) {
  return backup.accountId !== asset.awsAccountId
}

const rexClient = new RexClient()

const computeTargetAccount = (cloudConnector: CloudConnector) =>
  cloudConnector.accountAlias
    ? `${cloudConnector.accountAlias} (${cloudConnector.awsAccountId})`
    : cloudConnector.awsAccountId

function TargetAccount({ backup }: { backup: AWSBRecoveryPoint }) {
  const [cloudConnector, setCloudConnector] = useState<
    CloudConnector | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchCloudConnector() {
      try {
        setIsLoading(true)
        const cloudConnectorList = await rexClient.listGetAllRedstacks()

        const fetchedCloudConnector = cloudConnectorList.find(
          ({ awsAccountId }) => backup.accountId === awsAccountId
        )

        if (!fetchedCloudConnector) {
          return
        }

        setCloudConnector(fetchedCloudConnector)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchCloudConnector()

    return () => {
      setIsLoading(false)
      setCloudConnector(undefined)
    }
  }, [])

  if (isLoading || !cloudConnector) {
    return null
  }

  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Target account" />
      <Typography fontSize="14px">
        {computeTargetAccount(cloudConnector)}
      </Typography>
      <CopyToClipboardIcon text={cloudConnector.awsAccountId} fontSize={16} />
    </Stack>
  )
}

function TargetRegion({ backup }: { backup: AWSBRecoveryPoint }) {
  return (
    <Stack direction="row" alignItems="center">
      <RowTitle title="Target region" />
      <RegionIcon color="disabled" fontSize="inherit" />
      <Typography
        fontSize="14px"
        fontWeight="400"
        variant="caption"
        marginLeft="5px"
      >
        {LangHelper.getAwsRegionSingleTranslation(backup.region)}
      </Typography>
    </Stack>
  )
}

function getScanBackup(backups: Array<BackupById>): Array<Backup> {
  const result: Array<Backup> = []
  backups.forEach((backup) => {
    if (backup.awsbRp) {
      result.push(backup.awsbRp)
    }
    if (backup.ebsSnapshot) {
      result.push(backup.ebsSnapshot)
    }

    if (backup.elastioRp) {
      result.push(backup.elastioRp)
    }

    if (backup.ovaBackup) {
      result.push(backup.ovaBackup)
    }
  })

  return result
}

function ReplicatedDetails({
  asset,
  backupId,
}: {
  asset: Asset
  backupId: string
}) {
  const { isBackupsLoading, backupsList } = useFetchBackupsByIds([backupId])

  const backupsListFromScans = getScanBackup(backupsList)

  const backup = backupsListFromScans[0]

  if (isBackupsLoading) {
    return <Skeleton width="100%" />
  }

  if (!backup) {
    return null
  }

  if (!(backup instanceof AWSBRecoveryPoint) || !(asset instanceof AWSAsset)) {
    return null
  }

  const isRecoveryPointReplicated = getIsRecoveryPointReplicated(backup, asset)

  if (!isRecoveryPointReplicated) {
    return null
  }

  return (
    <Stack marginTop="16px" direction="row" alignContent="center" gap="24px">
      <TargetAccount backup={backup} />
      <TargetRegion backup={backup} />
    </Stack>
  )
}

export function ScanReplicatedDetails({ asset, scan }: Props) {
  const isBackupScan =
    scan.targetIsBackup && 'backupId' in scan.scanTarget.target

  if (!isBackupScan) {
    return null
  }

  return (
    <ReplicatedDetails
      asset={asset}
      backupId={scan.scanTarget.target.backupId}
    />
  )
}
