import DialogModal from '@components-composite/modals/DialogModal'
import PolicyRetentionAssets from '@components-complex/policy-retention-assets/PolicyRetentionAssets'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import EditPenIcon from '@inline-img/general/edit-pen-icon'
import DeleteOutlineIcon from '@inline-img/inputs/delete-outline-icon'
import Modal from '@lib/constants/modal.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import StrHelper from '@lib/helpers/str.helper'
import { useModal } from '@lib/hooks/useModal'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { Button, TextField } from '@mui/material'
import {
  requestEditPolicyRetention,
  setEditPolicyCurrentAssetsSelected,
  setEditPolicyMissingAssetsSelected,
  setEditPolicyRecoveryPeriod,
  setEditPolicyRetention,
  setEditPolicyRetentionAccountIDs,
  setEditPolicyRetentionAssetSources,
  setEditPolicyRetentionBackupTypes,
  setEditPolicyRetentionRegions,
  setEditPolicyRetentionSelectedFilters,
  setEditPolicyRetentionTags,
  setEditPolicyRetentionVaults,
  updateEditPolicyRetention,
} from '@store/actions/edit-policy-retention.action'
import { deletePolicyRetention } from '@store/actions/policies-retention.action'
import {
  getEditPolicyAssetsSelectedInitial,
  getEditPolicyCurrentAssetsSelected,
  getEditPolicyMissingAssetsSelected,
  getEditPolicyMissingAssetsSelectedInitial,
  getEditPolicyRecoveryPeriod,
  getEditPolicyRecoveryPeriodInitial,
  getEditPolicyRetention,
  getEditPolicyRetentionAccountIDs,
  getEditPolicyRetentionAccountIDsInitial,
  getEditPolicyRetentionAssetSources,
  getEditPolicyRetentionAssetSourcesInitial,
  getEditPolicyRetentionAssetsSelectedFilters,
  getEditPolicyRetentionBackupTypes,
  getEditPolicyRetentionBackupTypesInitial,
  getEditPolicyRetentionRegions,
  getEditPolicyRetentionRegionsInitial,
  getEditPolicyRetentionTags,
  getEditPolicyRetentionTagsInitial,
  getEditPolicyRetentionVaults,
  getEditPolicyRetentionVaultsInitial,
} from '@store/selectors/edit-policy-retention.selector'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VIRow } from '@lib/engine-types'
import RecoveryPointTypes from '@components-complex/recovery-point-types/RecoveryPointTypes'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PeriodForm from '@forms/period/period.form'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import {
  setAllSelectedAssets,
  setAssetIdsMissingInLiveAssets,
  setAssetsSelectEnabledTabs,
  setAssetsSelectFilters,
  setAssetsSelectTabSelectedNum,
} from '@store/actions/assets-select.action'
import {
  getMissingSelectedAssets,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import clsx from 'clsx'
import AssetsSelectComplex from '@components-complex/assets-select-complex/AssetsSelectComplex'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import { emptyAction } from '@store/actions/default/empty.action'
import ArrHelper from '@lib/helpers/arr.helper'
import TableFactory from '@lib/factories/table.factory'
import DataHelper from '@lib/helpers/data.helper'
import { EmptyFunc } from '@lib/constants/app.constant'
import ObjHelper from '@lib/helpers/obj.helper'
import { useLayoutContext } from '@features/contexts'
import { PagePathConstant } from '@lib/constants'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import useGetSupportedAssetKinds from '@components-complex/add-policy-retention-complex/useGetSupportedAssetKinds'
import { useParams } from 'react-router-dom'

function EditPolicyRetentionCustom() {
  const { setHeaderTitle, setBreadcrumbsPaths } = useLayoutContext()
  const dispatch = useDispatch()
  const { id } = useParams()
  const { scanOnlyReleaseJanuary } = useCustomFlags()

  const customPolicyName = StrHelper.base64Decode(String(id))

  useEffect(() => {
    return () => {
      setHeaderTitle(null)
      dispatch(setAssetsSelectFilters([]))
      dispatch(setAssetIdsMissingInLiveAssets([]))
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(setEditPolicyRetention(null))
      dispatch(setEditPolicyRetentionSelectedFilters([]))
      dispatch(setAssetsSelectEnabledTabs([]))
      dispatch(setAllSelectedAssets([]))
      dispatch(setAssetsSelectTabSelectedNum(0))
      dispatch(requestEditPolicyRetention(customPolicyName))

      setHeaderTitle(
        `Edit Retention Policy ${id ? `(${customPolicyName})` : ''}`
      )

      setBreadcrumbsPaths([
        {
          href: PagePathConstant.POLICIES_RETENTION,
          text: titlesForBreadcrumb(PagePathConstant.POLICIES) ?? '',
        },
      ])
    }
  }, [id])

  const [formEditable, setFormEditable] = useState<boolean>(false)
  const [assetsInUpdate, setAssetsInUpdate] = useState<boolean>(false)
  const [isCoverageOutdated, setCoverageOutdated] = useState<boolean>(false)
  const [showAssetsTable, setShowAssetsTable] = useState<boolean>(false)
  const [formHasError, setFormHasError] = useState<boolean>(false)

  const periodFormRef = useRef({ submit: EmptyFunc })

  const editPolicy = useSelector(getEditPolicyRetention)
  const dataForPeriodForm = useSelector(getEditPolicyRecoveryPeriod)
  const dataForPeriodFormInitial = useSelector(
    getEditPolicyRecoveryPeriodInitial
  )

  // Assets models for Assets Select table
  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const selectedAssetsMissing = useSelector(getMissingSelectedAssets)
  // Assets for this page
  const currentAssetsSelected = useSelector(getEditPolicyCurrentAssetsSelected)
  const missingAssetsSelected = useSelector(getEditPolicyMissingAssetsSelected)
  const missingAssetsSelectedInitial = useSelector(
    getEditPolicyMissingAssetsSelectedInitial
  )
  const initialAssetsSelected = useSelector(getEditPolicyAssetsSelectedInitial)
  const selectedAssetsFilters = useSelector(
    getEditPolicyRetentionAssetsSelectedFilters
  )

  const baseSelectedFilterValues = useMemo(
    () => ObjHelper.cloneDeep(selectedAssetsFilters),
    []
  )

  const missingAssetsSelectedData = TableFactory.missingAssetsTable(
    missingAssetsSelected
  )

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_EDIT_POLICY_RETENTION,
    PreloaderConstants.UPDATE_EDIT_POLICY_RETENTION,
  ])

  // bind assets message
  useEffect(() => {
    if (editPolicy?.coverageInvalidatedAt && editPolicy?.coverageUpdatedAt) {
      const isShowMessage =
        editPolicy.coverageUpdatedAt <= editPolicy.coverageInvalidatedAt
      setCoverageOutdated(isShowMessage)
    }
  }, [editPolicy])

  const assetsFromPolicy = editPolicy?.dataForSelectedAssets || []
  const dataForRecoveryPointTypes = editPolicy?.dataForRecoveryPointTypes || []

  const hasRecoveryPointTypes = dataForRecoveryPointTypes.some(
    (item) => item.name !== SelectCategory.ASSETS
  )

  const policyRetentionAccountIds = useSelector(
    getEditPolicyRetentionAccountIDs
  )
  const policyRetentionRegions = useSelector(getEditPolicyRetentionRegions)
  const policyRetentionVaults = useSelector(getEditPolicyRetentionVaults)
  const policyRetentionBackupTypes = useSelector(
    getEditPolicyRetentionBackupTypes
  )
  const policyRetentionAssetSources = useSelector(
    getEditPolicyRetentionAssetSources
  )
  const policyRetentionTags = useSelector(getEditPolicyRetentionTags)

  const basePolicyRetentionAccountIds = useSelector(
    getEditPolicyRetentionAccountIDsInitial
  )
  const basePolicyRetentionRegions = useSelector(
    getEditPolicyRetentionRegionsInitial
  )
  const basePolicyRetentionVaults = useSelector(
    getEditPolicyRetentionVaultsInitial
  )
  const basePolicyRetentionBackupTypes = useSelector(
    getEditPolicyRetentionBackupTypesInitial
  )
  const basePolicyRetentionAssetSources = useSelector(
    getEditPolicyRetentionAssetSourcesInitial
  )
  const basePolicyRetentionTags = useSelector(getEditPolicyRetentionTagsInitial)

  const { openModal, modalProps } = useModal<string>(
    Modal.deletePolicyRetention,
    (policyId: string) => {
      dispatch(deletePolicyRetention(policyId))
    }
  )

  const onDeletePolicy = () => {
    openModal(customPolicyName)
  }

  const onUpdatePolicy = () => {
    // started submit PeriodForm
    periodFormRef.current.submit()
    if (!formHasError) {
      setFormEditable(false)
      if (editPolicy) {
        setTimeout(() => {
          dispatch(updateEditPolicyRetention(editPolicy.policyName))
        }, 5)
      }
    }
    setAssetsInUpdate(true)
  }

  const onCancel = () => {
    dispatch(setEditPolicyRetentionAccountIDs(basePolicyRetentionAccountIds))
    dispatch(setEditPolicyRetentionRegions(basePolicyRetentionRegions))
    dispatch(setEditPolicyRetentionVaults(basePolicyRetentionVaults))
    dispatch(
      setEditPolicyRetentionAssetSources(basePolicyRetentionAssetSources)
    )
    dispatch(setEditPolicyRetentionBackupTypes(basePolicyRetentionBackupTypes))
    dispatch(setEditPolicyRetentionTags(basePolicyRetentionTags))

    dispatch(setEditPolicyCurrentAssetsSelected(assetsFromPolicy))
    dispatch(setEditPolicyRecoveryPeriod(dataForPeriodFormInitial))
    dispatch(setAllSelectedAssets(initialAssetsSelected))

    dispatch(setEditPolicyMissingAssetsSelected(missingAssetsSelectedInitial))
    dispatch(setEditPolicyRetentionSelectedFilters(baseSelectedFilterValues))

    setFormEditable(false)
  }

  const onAssetsCancel = () => {
    dispatch(setAllSelectedAssets(initialAssetsSelected))
    dispatch(setEditPolicyMissingAssetsSelected(missingAssetsSelectedInitial))
    setShowAssetsTable(false)
  }

  const onAssetsSelected = () => {
    const assets: VIRow = []
    const newMissingSelectedAssets: VIRow = []

    if (selectedAssets.length > 0) {
      selectedAssets.map((item: AssetWithRelatedAssets<Asset>) => {
        const asset = item.asset
        assets.push({
          name: asset.awsId,
          value: asset.awsAccountId,
          label: asset.awsRegion,
          type: item.type,
        })
      })
    }

    if (selectedAssetsMissing.length > 0) {
      selectedAssetsMissing.map((item: VIRow) => {
        newMissingSelectedAssets.push({
          name: String(item[1]?.name),
          type: Number(item[2]?.value),
          value: String(item[3]?.name),
          label: String(item[4]?.name),
        })
      })
    }

    const newAssets = ArrHelper.unionArray(
      newMissingSelectedAssets,
      assets,
      'name'
    )

    dispatch(setEditPolicyMissingAssetsSelected(newMissingSelectedAssets))
    dispatch(setEditPolicyCurrentAssetsSelected(newAssets))
    setShowAssetsTable(false)
  }

  const onSelectedFilters = (category: SelectCategory, value: VIRow) => {
    let allAssetsFilters: VIRow = []
    switch (category) {
      case SelectCategory.ACCOUNT_IDS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.ACCOUNT_IDS,
          value,
          selectedAssetsFilters
        )

        dispatch(setEditPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setEditPolicyRetentionAccountIDs(value))
        return
      case SelectCategory.REGIONS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.REGIONS,
          value,
          selectedAssetsFilters
        )

        dispatch(setEditPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setEditPolicyRetentionRegions(value))
        return
      case SelectCategory.VAULTS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.VAULTS,
          value,
          selectedAssetsFilters
        )

        dispatch(setEditPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setEditPolicyRetentionAccountIDs([]))
        dispatch(setEditPolicyRetentionRegions([]))
        dispatch(setEditPolicyRetentionVaults(value))
        return
      case SelectCategory.ASSET_SOURCES:
        const tabsEnabled = DataHelper.getEnabledAssetSelectTabs(value)
        dispatch(setAssetsSelectEnabledTabs(tabsEnabled))
        dispatch(setEditPolicyRetentionAssetSources(value))
        return
      case SelectCategory.BACKUP_TYPES:
        dispatch(setEditPolicyRetentionBackupTypes(value))
        return
      case SelectCategory.TAGS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.TAGS,
          value,
          selectedAssetsFilters
        )

        dispatch(setEditPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setEditPolicyRetentionTags(value))
        return
      case SelectCategory.ALL:
        dispatch(setEditPolicyRetentionAccountIDs([]))
        dispatch(setEditPolicyRetentionRegions([]))
        dispatch(setEditPolicyRetentionVaults([]))
        dispatch(setEditPolicyRetentionAssetSources([]))
        dispatch(setEditPolicyRetentionBackupTypes([]))
        dispatch(setEditPolicyRetentionTags([]))
        dispatch(
          setEditPolicyRetentionSelectedFilters(defaultSelectedFilters())
        )
        dispatch(setAssetsSelectEnabledTabs([]))
        return
    }
  }

  const onRecoveryPeriodChange = (newRecoveryPeriod: FormPeriodInterface) => {
    dispatch(setEditPolicyRecoveryPeriod(newRecoveryPeriod))
  }

  //check feture flags
  const supportedAssetKinds = useGetSupportedAssetKinds()

  return (
    <div className="innerContent innerContentBlueBackground jsEditPolicyRetentionCustomPage">
      <div className="wrap-1660053775064">
        <div className={clsx({ dNone: showAssetsTable })}>
          <div className="controlHeaderBlock">
            <div className="controlHeader">
              <div className="controlHeaderLabel mb0 jsControlHeaderLabel"></div>
              <div>
                <Button
                  disabled={loading || !editPolicy}
                  className="jsDeletePolicyRetention deleteWhiteButton sizeXS"
                  variant="contained"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={onDeletePolicy}
                >
                  <span>Delete</span>
                </Button>
              </div>
            </div>
          </div>
          <div>
            {!loading && editPolicy ? (
              <>
                <div className="editPolicyContainer">
                  <div className="editPolicyMainContainer">
                    <div className="editPolicyMainHeader">
                      <div className="editPolicyMainHeaderLabel jsPolicyMainHeaderLabel">
                        Main Information
                      </div>
                      <div>
                        {(formEditable || loading) && (
                          <div className="editPolicyButtonsBlock">
                            <Button
                              disabled={loading}
                              className="showButton sizeXS"
                              variant="text"
                              color="primary"
                              type="button"
                              onClick={onCancel}
                            >
                              Cancel
                            </Button>
                            <Button
                              disabled={loading}
                              className="mfSubmit jsDonePolicyRetention sizeXS"
                              variant="contained"
                              color="primary"
                              onClick={onUpdatePolicy}
                            >
                              <span>Done</span>
                            </Button>
                          </div>
                        )}
                        {!formEditable && !loading && (
                          <Button
                            className="jsEditPolicyRetention sizeXS"
                            variant="contained"
                            color="primary"
                            onClick={() => setFormEditable(true)}
                            startIcon={<EditPenIcon />}
                          >
                            <span>Edit</span>
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="editPolicyControlsBlock">
                      <div className="newFormSingleRow v2StaticTextInput inputReadOnly mb0">
                        <TextField
                          disabled
                          value={editPolicy.policyName}
                          label="Retention Policy name"
                          variant="outlined"
                          className="jsPolicyNameField"
                        />
                      </div>
                    </div>

                    <div className="editPolicyControlsBlock">
                      <div className="editPolicySubHeader">Recovery period</div>
                      {formEditable && dataForPeriodForm && (
                        <PeriodForm
                          onSubmit={onRecoveryPeriodChange}
                          loading={loading}
                          errorGroups={[ErrorGroupConstants.POLICIES_RETENTION]}
                          defaultValues={dataForPeriodForm}
                          onFormValid={(e) => setFormHasError(!e)}
                          onTriggerForm={periodFormRef}
                        />
                      )}
                      {!formEditable && dataForPeriodForm && (
                        <PeriodForm
                          onSubmit={emptyAction}
                          loading={true}
                          errorGroups={[ErrorGroupConstants.POLICIES_RETENTION]}
                          defaultValues={dataForPeriodForm}
                          onFormValid={(e) => setFormHasError(!e)}
                          onTriggerForm={periodFormRef}
                        />
                      )}
                    </div>

                    <div className="editPolicyControlsScrollable">
                      <div className="editPolicyControlsBlock">
                        <div className="editPolicySubHeader">
                          Recovery point types
                        </div>

                        {formEditable && (
                          <RecoveryPointTypes
                            loading={loading}
                            onFiltersChange={onSelectedFilters}
                            selectedFilters={[
                              {
                                name: SelectCategory.ACCOUNT_IDS,
                                options: policyRetentionAccountIds,
                              },
                              {
                                name: SelectCategory.REGIONS,
                                options: policyRetentionRegions,
                              },
                              {
                                name: SelectCategory.VAULTS,
                                options: policyRetentionVaults,
                              },
                              {
                                name: SelectCategory.BACKUP_TYPES,
                                options: policyRetentionBackupTypes,
                              },
                              {
                                name: SelectCategory.ASSET_SOURCES,
                                options: policyRetentionAssetSources,
                              },
                              {
                                name: SelectCategory.TAGS,
                                options: policyRetentionTags,
                              },
                            ]}
                            emptyFilters={DataHelper.buildRetentionPolicyEmptyFilters()}
                            allSpecificAssets={currentAssetsSelected}
                          />
                        )}
                        {!formEditable &&
                          (hasRecoveryPointTypes ? (
                            <div className="editPolicyInfo" key={'rpTypes'}>
                              {dataForRecoveryPointTypes?.map((rpType, i) => {
                                switch (rpType.name) {
                                  case SelectCategory.ACCOUNT_IDS:
                                  case SelectCategory.ASSET_SOURCES:
                                  case SelectCategory.BACKUP_TYPES:
                                    return (
                                      <React.Fragment key={i}>
                                        <div
                                          className="editPolicyInfoHeader"
                                          key={rpType.name}
                                        >
                                          {rpType.name}
                                        </div>
                                        {rpType.options?.map((v) => (
                                          <div key={v.name}>{v.name}</div>
                                        ))}
                                      </React.Fragment>
                                    )
                                  case SelectCategory.REGIONS:
                                  case SelectCategory.TAGS:
                                    return (
                                      <React.Fragment key={i}>
                                        <div
                                          className="editPolicyInfoHeader"
                                          key={rpType.name}
                                        >
                                          {rpType.name}
                                        </div>
                                        {rpType.options?.map((v) => (
                                          <div key={v.label}>{v.label}</div>
                                        ))}
                                      </React.Fragment>
                                    )
                                  case SelectCategory.VAULTS:
                                    return (
                                      <React.Fragment key={i}>
                                        <div
                                          className="editPolicyInfoHeader"
                                          key={rpType.name}
                                        >
                                          {rpType.name}
                                        </div>
                                        {rpType.options?.map((v) => (
                                          <div key={v.label}>{v.value}</div>
                                        ))}
                                      </React.Fragment>
                                    )
                                }
                              })}
                            </div>
                          ) : (
                            <div className="editPolicyInfo">
                              No Recovery point types selected
                            </div>
                          ))}
                      </div>

                      <div className="editPolicyControlsBlock">
                        <div className="editPolicySubHeader">
                          Specific assets
                        </div>
                        <div className="editPolicyInfo" key={'specificAssets'}>
                          {currentAssetsSelected.length > 0 ? (
                            currentAssetsSelected.map((v, i) => (
                              <div key={`${v.name}-${i}`}>{v.name}</div>
                            ))
                          ) : (
                            <div className="editPolicyInfo">
                              No Specific assets selected
                            </div>
                          )}
                          <div>
                            {formEditable && (
                              <Button
                                className="showButton sizeS"
                                disabled={loading}
                                type="button"
                                color="primary"
                                variant="text"
                                onClick={() => setShowAssetsTable(true)}
                              >
                                {currentAssetsSelected.length > 0 ? (
                                  <span>Edit Asset IDs</span>
                                ) : (
                                  <span>Add Asset IDs</span>
                                )}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {scanOnlyReleaseJanuary && (
                    <div className="editPolicyAssetsContainer">
                      <div className="editPolicyMainHeader">
                        <div className="editPolicyMainHeaderLabel jsPolicyMainHeaderLabel">
                          Assets
                        </div>
                      </div>
                      <PolicyRetentionAssets
                        currentPolicy={customPolicyName}
                        isInfoMode={assetsInUpdate || isCoverageOutdated}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <PreloaderBlock show />
            )}
          </div>
        </div>

        {showAssetsTable && (
          <>
            <div className="controlHeaderBlock">
              <div className="controlHeader">
                <div className="controlHeaderLabelUnderlined">
                  Find asset IDs
                </div>
              </div>
            </div>
            <div className="policiesBlockSubheader mt25">
              Select specific assets
            </div>
            <div className="policiesBlockLabel fontMedium mb20">
              Select the specific assets from the table below to add to the
              policy.
            </div>
            <AssetsSelectComplex
              isLoading={loading}
              supportedAssetKinds={supportedAssetKinds}
              additionalData={missingAssetsSelectedData}
              initSelectedFilters={selectedAssetsFilters}
              storeInitialFilters
              disableProtectionFilter={false}
            />
            <BasePortalButtons alwaysInPortal>
              <div className="portalBetweenButtons">
                <Button
                  className="showButton sizeXS"
                  variant="text"
                  color="primary"
                  type="button"
                  onClick={onAssetsCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="jsAddRetentionPolicySubmit"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={onAssetsSelected}
                >
                  Done
                </Button>
              </div>
            </BasePortalButtons>
          </>
        )}

        <DialogModal
          description={
            <div>
              You are going to remove the Retention Policy
              {<LongTextTooltip text={modalProps.data ?? ''} maxLength={35} />}
              Please confirm the deletion.
            </div>
          }
          {...modalProps}
        />
      </div>
    </div>
  )
}

export default EditPolicyRetentionCustom
