"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportGeneratedPending = exports.ReportGeneratedCompleted = void 0;
class ReportGeneratedCompleted {
    #generatedAt;
    constructor(generatedAt) {
        this.#generatedAt = generatedAt;
    }
    get generatedAt() {
        return this.#generatedAt;
    }
}
exports.ReportGeneratedCompleted = ReportGeneratedCompleted;
class ReportGeneratedPending {
}
exports.ReportGeneratedPending = ReportGeneratedPending;
