import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import { BaseModel } from '../base-model'

export interface VpcTagsType {
  key: string
  value: string
}

export class Subnet {
  public readonly id: string

  public readonly name: string

  public readonly isPublic: boolean

  constructor(readonly params: any) {
    this.id = params.id
    this.name = params.name
    this.isPublic = params.isPublic
  }

  public get labelName(): string {
    return this.name ? this.name : this.id
  }
}

class VpcModel extends BaseModel {
  public readonly isDefault: boolean

  public readonly subnetsList: Array<Subnet>

  public readonly hasPublicSubnets: boolean

  public readonly tagsList: Array<VpcTagsType>

  // we assign these parameters from another requests to the server

  public accountId: string

  public innerId: string

  public region: ValueInterface

  public regionName: string

  // using for groupByField - it simplifies the algorithm
  public get awsRegion(): string {
    return this.region.name || ''
  }

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.isDefault = params.isDefault
    this.subnetsList = params.subnetsList
    this.hasPublicSubnets = params.hasPublicSubnets
    this.tagsList = params.tagsList

    this.region = params.region ?? {
      name: '',
      label: '',
    }
    this.accountId = params.accountId ?? ''
    this.innerId = params.innerId

    // extra
    this.regionName = this.region.name
  }

  public static clone<T extends BaseModel>(model: T, params: any = {}): T {
    const result = BaseModel.clone(model, params)
    // @ts-ignore
    result.accountId = model.accountId
    // @ts-ignore
    result.region = ObjHelper.cloneDeep(model.region)
    return result
  }

  public get vpcName(): string | undefined {
    if (!this.tagsList) {
      return undefined
    }

    return (
      this.tagsList.find((tag) => tag.key.toLowerCase() === 'name')?.value ?? ''
    )
  }
}

export default VpcModel
