import { useEffect, useState } from 'react'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'

import { Box, FormControl, Grid2, Stack, Typography } from '@mui/material'
import Scopes from './scopes'

import DrawerHeader from 'ui-v2/src/components/ui/drawer/header'

import { useUserProfileQuery } from 'ui-v2/src/hooks/queries/users'
import {
  getScopeValues,
  getSelectedScopeValues,
  isAnySelected,
  selectedValuesToStrings,
} from 'ui-v2/src/lib/helpers/scope.helper'
import { ValueInterface, VIRow } from 'ui-v2/src/lib/engine-types'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import Label from 'ui-v2/src/components/ui/data-display/label'
import { Controller, useForm } from 'react-hook-form'
import { AccessTokenData } from 'ui-v2/src/lib/models/settings/api-access'
import {
  useIssueMutation,
  useUpdateIssueMutation,
} from 'ui-v2/src/hooks/queries/settings'
import { useNavigate, useParams } from 'react-router-dom'
import { useTokenDetailsQuery } from 'ui-v2/src/hooks/queries/settings/personal-access-token/token-details'
import GeneratedAccessToken from './generated-access-token'
import { useQueryClient } from '@tanstack/react-query'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useToast } from 'ui-v2/src/hooks/toast'

const AddAccessTokenDrawer = () => {
  const { action, id } = useParams()
  const navigate = useNavigate()

  const { data: userProfileData, isLoading: userProfileIsLoading } =
    useUserProfileQuery()
  const { data: tokenDetailsData } = useTokenDetailsQuery(id)

  const { mutate: issueMutate, isPending: issueIsPending } = useIssueMutation()
  const { mutate: issueUpdateMutate, isPending: issueUpdateIsPending } =
    useUpdateIssueMutation()
  const queryClient = useQueryClient()

  const [innerScopes, setInnerScopes] = useState<VIRow>([])

  const [token, setToken] = useState<string>()

  const toast = useToast()

  const { control, handleSubmit, setValue, reset } = useForm<AccessTokenData>({
    defaultValues: {
      tokenId: undefined,
      tokenName: '',
      scopes: [],
    },
  })

  const actionTitle = id ? 'Update Token' : 'Generate Token'

  const handleCloseDrawer = () => {
    setValue('tokenId', undefined)
    setValue('tokenName', '')
    setValue('scopes', [])
    if (userProfileData) {
      setInnerScopes(getScopeValues(userProfileData?.role?.scopesList))
    }
    navigate('/settings/general/api-access')
  }

  const onCheckboxBlockChange = (index: number) => (values: VIRow) => {
    const newInnerScopes = [...innerScopes]
    const elem = newInnerScopes[index] as ValueInterface
    elem.options = values
    setValue('scopes', selectedValuesToStrings(newInnerScopes))
    setInnerScopes(newInnerScopes)
  }
  const canBeSubmitted = isAnySelected(innerScopes)

  const onSubmit = ({ tokenId, tokenName, scopes }: AccessTokenData) => {
    if (tokenId) {
      issueUpdateMutate(
        {
          tokenId,
          tokenName,
          scopes,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['settings', 'tokenList'],
            })
            handleCloseDrawer()

            toast.success('Token updated')
          },
          onError: (error) => {
            toast.error(error?.message ?? 'Token update failed')
          },
        }
      )
    } else {
      issueMutate(
        {
          tokenName,
          scopes,
        },
        {
          onSuccess: (data) => {
            setToken(data.secretToken)
            queryClient.invalidateQueries({
              queryKey: ['settings', 'tokenList'],
            })
            handleCloseDrawer()

            toast.success('Token created')
          },
          onError: (error) => {
            toast.error(error?.message ?? 'Token create failed')
          },
        }
      )
    }
  }

  useEffect(() => {
    if (userProfileData) {
      let selectedScopes: Array<string> = []

      if (tokenDetailsData) {
        selectedScopes = tokenDetailsData?.scopesList ?? []
        reset({
          tokenId: tokenDetailsData?.innerId,
          tokenName: tokenDetailsData?.name,
          scopes: selectedScopes,
        })
      }
      const selectedScopesValues = getSelectedScopeValues(
        selectedScopes,
        userProfileData?.role?.scopesList
      )
      setInnerScopes(selectedScopesValues)
    }
  }, [userProfileData, tokenDetailsData])

  return (
    <>
      <Drawer open={!!action} onClose={handleCloseDrawer}>
        <DrawerHeader
          title={id ? 'Modify New Access Token' : 'Add New Access Token'}
          handleCloseDrawer={handleCloseDrawer}
        />
        <DrawerContent isLoading={userProfileIsLoading} isEmpty={false}>
          <Stack
            spacing={2}
            sx={{
              paddingX: 2.5,
              paddingBottom: 2.5,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid2 container spacing={2}>
                <Grid2 size={12}>
                  <FormControl fullWidth>
                    <Label variant="body2" mb={1}>
                      Name
                    </Label>
                    <Controller
                      name="tokenName"
                      control={control}
                      rules={{
                        required: 'Name is required',
                        minLength: 2,
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Access Token Name"
                          size="small"
                          error={!!fieldState.error?.message}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2
                  size={12}
                  sx={{
                    height: 'calc(100vh - 250px)',
                    overflow: 'auto',
                  }}
                >
                  <Stack spacing={2.5}>
                    <Box>
                      <Typography fontSize={20} mb={0.5}>
                        Select Scopes
                      </Typography>
                      <Typography variant="body2" color="text.secondary" mb={1}>
                        Scopes limit access to the tokens
                      </Typography>
                    </Box>
                    {innerScopes.map((scope, index) => (
                      <Scopes
                        key={scope.name}
                        behaviour="roles"
                        disabled={false}
                        selectAllLabel={scope.label}
                        data={scope.options ?? []}
                        onChange={onCheckboxBlockChange(index)}
                      />
                    ))}
                  </Stack>
                </Grid2>
                <Grid2 size={12}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Label>
                      {selectedValuesToStrings(innerScopes).length} Access
                      Selected
                    </Label>
                    <Stack display={'flex'} flexDirection={'row'} gap={2}>
                      <Button
                        color="inherit"
                        onClick={() => {
                          handleCloseDrawer()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={
                          issueIsPending ||
                          issueUpdateIsPending ||
                          !canBeSubmitted
                        }
                        isLoading={issueIsPending || issueUpdateIsPending}
                      >
                        {actionTitle}
                      </Button>
                    </Stack>
                  </Box>
                </Grid2>
              </Grid2>
            </form>
          </Stack>
        </DrawerContent>
      </Drawer>
      <GeneratedAccessToken
        open={!!token}
        token={token as string}
        onClose={() => setToken(undefined)}
      />
    </>
  )
}

export default AddAccessTokenDrawer
