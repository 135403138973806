import { useNavigate } from 'react-router-dom'
import { useCreateReportSchedule } from 'ui-v2/src/hooks/queries/reports/create-report-schedule'
import { useGenerateOnDemandReport } from 'ui-v2/src/hooks/queries/reports/generate-on-demand-report'
import { useUpdateReportSchedule } from 'ui-v2/src/hooks/queries/reports/update-report-schedule'
import { GrpcCodes } from 'ui-v2/src/lib/constants/api-errors.constant'
import GrpcError from 'ui-v2/src/lib/helpers/grpc-error.helper'
import { FormOptionValue, ReportForm } from 'ui-v2/src/lib/models/reports'
import { transformDateRangeForApi } from 'ui-v2/src/lib/transformers/reports/transform-date-range-for-api'
import Close from '@mui/icons-material/Close'
import { IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useFormContext } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useToast } from 'ui-v2/src/hooks/toast'

const ReportHeader = ({
  selectedReportForm,
  reportTypeLabel,
  reportId,
  handleCloseDrawer,
}: ReportHeaderProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const action = reportId ? 'Modify' : 'Create'

  const {
    getValues,
    trigger,
    formState: { isValid },
  } = useFormContext<ReportForm>()

  const { mutate: generateReportMutate, isPending: generateReportIsPending } =
    useGenerateOnDemandReport()
  const {
    mutate: createReportScheduleMutate,
    isPending: createReportScheduleIsPending,
  } = useCreateReportSchedule()
  const {
    mutate: updateReportScheduleMutate,
    isPending: updateReportScheduleIsPending,
  } = useUpdateReportSchedule()
  const toast = useToast()

  const isPending =
    generateReportIsPending ||
    createReportScheduleIsPending ||
    updateReportScheduleIsPending

  const actionTitle = `${reportId ? 'Modify' : 'Generate'} ${reportTypeLabel}`

  const handleOnDemandFormData = (data: ReportForm) => {
    const dateRangeTransformed = transformDateRangeForApi(data.dateRange)

    generateReportMutate(
      {
        reportType: data.reportType,
        description: data.description,
        backupProvider: data.backupProvider.map((provider) => +provider),
        ovaAccounts: data.ovaAccounts,
        formats: data.format,
        start: dateRangeTransformed.start,
        end: dateRangeTransformed.end,
      },
      {
        onSuccess: () => {
          toast.success('Generated report')
          handleCloseDrawer()
          navigate('/reports/history', { replace: true })
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Generated report failed')
        },
      }
    )
  }

  const handleScheduleFormData = (data: ReportForm) => {
    const scheduleStartDate = dayjs(data.scheduleStartDate)

    createReportScheduleMutate(
      {
        reportType: data.reportType,
        name: data.name,
        description: data.description,
        ovaAccountIds: data.ovaAccounts,
        ovaBackupProviders: data.backupProvider.map((provider) => +provider),
        frequency: data.frequency?.toString() ?? '',
        startOn: {
          day: scheduleStartDate.date(),
          month: scheduleStartDate.month() + 1,
          year: scheduleStartDate.year(),
        },
        rangeOfData: data.rangeOfData?.toString() ?? '',
        timezone: data.timezone,
        recipientsIds: data.recipients,
        formats: data.format,
        customSchedule: data.customSchedule,
      },
      {
        onSuccess: () => {
          toast.success('Report scheduled created')

          queryClient.invalidateQueries({
            queryKey: ['reports', 'reportScheduleList'],
          })
          handleCloseDrawer()
          navigate('/reports/schedules', { replace: true })
        },
        onError: (error) => {
          if (error instanceof GrpcError) {
            if (error.code === GrpcCodes.ALREADY_EXISTS) {
              toast.error('Report name already exists.')
            } else {
              toast.error(
                error?.message ??
                  'Something went wrong while creating report schedule'
              )
            }
          } else {
            toast.error(
              error?.message ??
                'Something went wrong while creating report schedule'
            )
          }
        },
      }
    )
  }

  const handleUpdateFormData = (data: ReportForm) => {
    const scheduleStartDate = dayjs(data.scheduleStartDate)

    updateReportScheduleMutate(
      {
        id: reportId ?? '',
        reportType: data.reportType,
        name: data.name,
        description: data.description,
        ovaAccountIds: data.ovaAccounts,
        ovaBackupProviders: data.backupProvider.map(
          (backupProvider) => +backupProvider
        ),
        frequency: data.frequency?.toString() ?? '',
        startOn: {
          day: scheduleStartDate.date(),
          month: scheduleStartDate.month() + 1,
          year: scheduleStartDate.year(),
        },
        rangeOfData: data.rangeOfData?.toString() ?? '',
        timezone: data.timezone,
        recipientsIds: data.recipients,
        recipientIds: data.recipients,
        formats: data.format,
        customSchedule: data.customSchedule,
      },
      {
        onSuccess: () => {
          toast.success('Report scheduled updated')
          queryClient.invalidateQueries({
            queryKey: ['reports', 'reportScheduleList'],
          })
          handleCloseDrawer()
          navigate('/reports/schedules', { replace: true })
        },
        onError: (error) => {
          toast.error(error?.message ?? 'Report scheduled failed')
        },
      }
    )
  }

  const handleSubmit = () => {
    if (!isValid) {
      trigger()
      return
    }
    const data = getValues()

    if (reportId) {
      handleUpdateFormData(data)
      return
    }

    if (selectedReportForm === FormOptionValue.ON_DEMAND) {
      handleOnDemandFormData(data)
      return
    }

    if (selectedReportForm === FormOptionValue.SCHEDULE) {
      handleScheduleFormData(data)
      return
    }
  }
  return (
    <List sx={{ paddingX: 0 }}>
      <ListItem
        sx={{ paddingX: 2.5 }}
        secondaryAction={
          <Stack direction={'row'} spacing={1}>
            <Button
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              onClick={handleSubmit}
              disabled={isPending}
              isLoading={isPending}
            >
              {action}
            </Button>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                handleCloseDrawer()
              }}
            >
              <Close />
            </IconButton>
          </Stack>
        }
      >
        <Typography fontWeight={700} variant="h5">
          {actionTitle}
        </Typography>
      </ListItem>
    </List>
  )
}
interface ReportHeaderProps {
  selectedReportForm: FormOptionValue | string
  reportTypeLabel: string
  reportId?: string
  handleCloseDrawer: () => void
}
export default ReportHeader
