function cloneDeep<T>(value: T): T {
  // Handle primitive types and null/undefined directly
  if (value === null || value === undefined) {
    return value
  }

  // Handle basic immutable types
  if (
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol'
  ) {
    return value
  }

  // Handle Date objects
  if (value instanceof Date) {
    return new Date(value.getTime()) as T
  }

  // Handle RegExp objects
  if (value instanceof RegExp) {
    return new RegExp(value) as T
  }

  // Handle Arrays
  if (Array.isArray(value)) {
    return value.map((item) => cloneDeep(item)) as T
  }

  // Handle Maps
  if (value instanceof Map) {
    const newMap = new Map()
    for (const [key, val] of value.entries()) {
      newMap.set(cloneDeep(key), cloneDeep(val))
    }
    return newMap as T
  }

  // Handle Sets
  if (value instanceof Set) {
    const newSet = new Set()
    for (const item of value) {
      newSet.add(cloneDeep(item))
    }
    return newSet as T
  }

  // Handle plain objects
  if (typeof value === 'object') {
    // Create a new object with the same prototype
    const newObj = Object.create(Object.getPrototypeOf(value))

    // Copy all enumerable and own properties
    const keys = [
      ...Object.getOwnPropertyNames(value),
      ...Object.getOwnPropertySymbols(value),
    ]

    keys.forEach((key) => {
      // Get property descriptor to preserve all property characteristics
      const descriptor = Object.getOwnPropertyDescriptor(value, key)

      if (descriptor) {
        // Deep clone the value while preserving property descriptor
        descriptor.value = cloneDeep(descriptor.value)
        Object.defineProperty(newObj, key, descriptor)
      }
    })

    return newObj
  }

  // Handle functions by returning them as-is (deep cloning functions is complex)
  if (typeof value === 'function') {
    return value
  }

  // Fallback for any unexpected types
  return value
}

abstract class ObjHelper {
  public static cloneDeep<T>(obj: T): T {
    return cloneDeep(obj)
  }
}

export default ObjHelper
