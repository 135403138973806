import React, { memo } from 'react'
import { VIRow } from '@lib/engine-types'
import CopyCommandButton from '@components-composite/copy-command-button/CopyCommandButton'
import { CopyButtonVariant } from '@lib/constants/copy-command-button.constant'

interface Props {
  blockHeader?: string
  blockText?: string
  commands: VIRow
  blockToastText: string
  className?: string
  disabled?: boolean
}

function CopyButtonsBlock({
  blockHeader,
  blockText,
  commands,
  blockToastText,
  className = '',
  disabled = false,
}: Props) {
  return (
    <div className={`wrap-1655795312447 copyButtonsBlock ${className}`}>
      {blockHeader && (
        <div className="copyButtonsBlockHeader">{blockHeader}</div>
      )}
      {blockText && (
        <div
          className="copyButtonsBlockText"
          dangerouslySetInnerHTML={{ __html: blockText }}
        ></div>
      )}
      <div className="copyButtonsRow">
        {commands.map((command) => (
          <CopyCommandButton
            key={command.type}
            commandText={command.name}
            variant={command.type ?? CopyButtonVariant.DEFAULT}
            disabled={!!command.disabled || disabled}
            toastText={blockToastText}
          />
        ))}
      </div>
    </div>
  )
}

export default memo(CopyButtonsBlock)
