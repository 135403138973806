import React, { ReactNode } from 'react'

import { Tabs } from './consts'
import { GenerateReport } from '../generate-report'
import { Overview } from '../overview'
import { History } from '../history'
import { TabName } from '@features/reports/schedules/use-data-store'
import { PaperStyled } from '@features/reports/schedules/shared'

interface TabConfiguration {
  label: string
  component: ReactNode
  tabName: TabName
}

export const tabConfigurations: Record<number, TabConfiguration> = {
  [Tabs.OVERVIEW]: {
    label: 'Overview',
    tabName: TabName.report,
    component: <Overview />,
  },
  [Tabs.REPORT]: {
    label: 'Schedules',
    tabName: TabName.report,
    component: (
      <PaperStyled>
        <GenerateReport />
      </PaperStyled>
    ),
  },
  [Tabs.HISTORY]: {
    label: 'History',
    tabName: TabName.history,
    component: (
      <PaperStyled>
        <History />
      </PaperStyled>
    ),
  },
}
