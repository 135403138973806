import React, { memo } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'

import type { Column, MUIComponents } from './types'
import styles from './Table.module.css'
import { useTable } from './useTable'

interface Props<T> {
  data: Array<T>
  columns: Array<Column<T>>
  components?: MUIComponents
  onRowClick?: (row: T) => void
}

const DEFAULT_ALIGN = 'left'

function ReusableTable<T>({ data, columns, components, onRowClick }: Props<T>) {
  const {
    Table: CustomTable = Table,
    TableHead: CustomTableHead = TableHead,
    TableBody: CustomTableBody = TableBody,
    TableRow: CustomTableRow = TableRow,
    TableCell: CustomTableCell = TableCell,
    TableContainer: CustomTableContainer = TableContainer,
  } = components || {}

  const { expandedRows, renderCellContent, renderExpandCollapseIcon } =
    useTable({
      data,
      onRowClick,
    })

  return (
    <CustomTableContainer className={styles.root}>
      <CustomTable>
        <CustomTableHead>
          <CustomTableRow>
            {columns.map((column, index) => (
              <CustomTableCell
                align={column.align}
                sx={{
                  maxWidth: column.width,
                }}
                key={index}
              >
                {column.name}
              </CustomTableCell>
            ))}
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody data-testid="table-body">
          {data.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <CustomTableRow
                key={rowIndex}
                style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                sx={{
                  '&:hover .MuiTableCell-root': {
                    color: onRowClick ? 'var(--blue500)' : 'inherit',
                  },
                }}
              >
                {columns.map((column, columnIndex) => (
                  <CustomTableCell
                    sx={{
                      width: column.width,
                      cursor:
                        column.expand?.shouldBeExpanded(row) || onRowClick
                          ? 'pointer'
                          : 'default',
                    }}
                    onClick={() => {
                      // TODO: add logic to hook
                      if (onRowClick) {
                        onRowClick(row)
                      }
                    }}
                    align={column.align ?? DEFAULT_ALIGN}
                    key={columnIndex}
                  >
                    {column.expand && column.expand.shouldBeExpanded(row) ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {renderExpandCollapseIcon(column, row, rowIndex)}
                      </div>
                    ) : (
                      (renderCellContent(column, row) as React.ReactNode)
                    )}
                  </CustomTableCell>
                ))}
              </CustomTableRow>
              {expandedRows.includes(rowIndex) &&
                columns.map(
                  (column) =>
                    column.expand?.shouldBeExpanded(row) && (
                      <CustomTableRow key={`${rowIndex}-expand`}>
                        <CustomTableCell
                          sx={{
                            padding: '0 !important',
                          }}
                          colSpan={columns.length}
                        >
                          {column.expand.content(row)}
                        </CustomTableCell>
                      </CustomTableRow>
                    )
                )}
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  )
}

export default memo(ReusableTable)
