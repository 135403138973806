import { BaseRoutes } from '../base'

export enum RouteFragments {
  AWS = 'aws',
  OVA = 'ova',
}

export class ActiveThreatsRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_ACTIVE_THREATS
  }

  public static get awsAssetsActiveThreats(): string {
    return this.buildRoute(RouteFragments.AWS)
  }

  public static get ovaAssetsActiveThreats(): string {
    return this.buildRoute(RouteFragments.OVA)
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_ACTIVE_THREATS}/${fragments.join('/')}`
  }

  static #ROUTE_ACTIVE_THREATS = '/active-threats'
}
