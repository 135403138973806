import Schedule from '@components-composite/schedule/Schedule'
import { zodResolver } from '@hookform/resolvers/zod'
import ScheduleInterface from '@lib/interfaces/schedule.interface'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import {
  setAddPolicyName,
  setAddPolicySchedule,
} from '@store/actions/add-policy.action'
import {
  getAddPolicyName,
  getAddPolicySchedule,
} from '@store/selectors/add-policy.selector'
import { getPoliciesNames } from '@store/selectors/policies.selector'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { z } from 'zod'

import { getPolicyNameSchema } from './policy-name-schema'
import { useTimezoneState } from '@components-complex/account-profile/timezone/use-timezone-state'
import SettingsService from '@lib/services/high/settings.service'
import {
  DEFAULT_TIME_ZONE,
  TimeFormatConstants,
  UserSettings,
} from '@lib/constants'

interface Props {
  isLoading: boolean
  couldGoNextStep: (nameIsValid: boolean) => void
}

interface UserTimeZone {
  value?: string | null
}

function ErrorText({ text }: { text: string }) {
  if (text === '') {
    return null
  }

  return (
    <Typography
      width="100%"
      marginY="5px"
      variant="caption"
      color="error"
      fontSize="12px"
    >
      {text}
    </Typography>
  )
}

export function PolicySchedule({ isLoading, couldGoNextStep }: Props) {
  const dispatch = useDispatch()
  const policyName = useSelector(getAddPolicyName)
  const userTimeZone: UserTimeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()
  const timeZone = userTimeZone?.value ?? TimeFormatConstants.UTC_TIMEZONE_VALUE
  const { selectedTimezone, handleTimezoneSelectChange } = useTimezoneState({
    value: timeZone,
    label: timeZone,
  })
  const existingPoliciesNames = useSelector(getPoliciesNames)
  const schedule = useSelector(getAddPolicySchedule)
  const policyNameSchema = getPolicyNameSchema(existingPoliciesNames)

  const { control, formState, watch } = useForm<
    z.infer<typeof policyNameSchema>
  >({
    resolver: zodResolver(policyNameSchema),
    mode: 'all',
    defaultValues: {
      policyName,
    },
  })

  const handleScheduleChange = (newSchedule: ScheduleInterface) => {
    dispatch(setAddPolicySchedule(newSchedule))
  }

  const newPolicyName = watch('policyName')

  // Use effect to synchronize RHF with the parent component
  useEffect(() => {
    if (!newPolicyName) {
      couldGoNextStep(false)
      return
    }

    couldGoNextStep(!formState.errors.policyName)
  }, [formState.errors.policyName, newPolicyName])

  useEffect(() => {
    dispatch(setAddPolicyName(newPolicyName))
  }, [newPolicyName])

  return (
    <Box>
      <Box className="addPolicyTitle">Enter Policy Name</Box>
      <Box
        className={clsx('newFormSingleRow v2StaticTextInput', {
          statusErrorClass: !!formState.errors.policyName,
        })}
      >
        <Controller
          name="policyName"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                required
                disabled={isLoading}
                label="Enter the name of the policy."
                variant="outlined"
                className="jsPolicyNameField"
                error={!!fieldState.error?.message}
                helperText={
                  <ErrorText text={fieldState.error?.message || ''} />
                }
                placeholder="Name..."
              />
            )
          }}
        />
      </Box>

      <Schedule
        data={schedule}
        onChange={handleScheduleChange}
        timeZone={selectedTimezone.value}
        handleTimezoneSelectChange={handleTimezoneSelectChange}
      />
    </Box>
  )
}
