import React, { useEffect } from 'react'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { PagePathConstant } from '@lib/constants'
import IconPopover from '@components-simple/icon-popover/IconPopover'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const ENTROPY_MESSAGE =
  // eslint-disable-next-line quotes
  "Detect potential internal threats by identifying newly encrypted or suspiciously modified files across your network. This behavioral model analyzes file changes between scans, flagging files that show signs of encryption and includes file type analysis to help safeguard your company's data."
export function useEntropyTrendsBreadcrumbs() {
  const { setBreadcrumbsPaths, setHeaderTitle } = useLayoutContext()

  useEffect(() => {
    setHeaderTitle(
      <Stack direction="row" alignItems="center" maxHeight={20}>
        <Typography variant="caption" fontSize="16px" fontWeight={700}>
          Entropy Trends
        </Typography>
        <IconPopover variant="info" message={ENTROPY_MESSAGE} />
      </Stack>
    )
    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) as string,
      },
    ])

    return () => {
      setHeaderTitle(null)
    }
  }, [])
}
