import * as actions from '@store/actions/action-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RecoveryPointModel } from 'blues-corejs/dist'

export function requestAssetListRecoveryPoints({
  dateRange,
  redStackAssetId,
}: actions.RequestAssetListRecoveryPointsAction['payload']): actions.RequestAssetListRecoveryPointsAction {
  return {
    type: actions.REQUEST_ASSET_LIST_RECOVERY_POINTS,
    payload: {
      dateRange,
      redStackAssetId,
    },
  }
}
export function resetCoreListRecoveryPointsState(): actions.ResetCoreListRecoveryPointsStateAction {
  return {
    type: actions.RESET_CORE_LIST_RECOVERY_POINTS_STATE,
  }
}

export function setAssetListRecoveryPoints(
  listRecoveryPoints: Array<RecoveryPointModel>
): actions.SetAssetListRecoveryPointsAction {
  return {
    type: actions.SET_ASSET_LIST_RECOVERY_POINTS,
    payload: listRecoveryPoints,
  }
}
