/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { GenericHost } from 'blues-corejs/dist'
import { AssetId, AssetType } from '../shared'
import Grid from '@mui/material/Grid'
import { AssetTags } from '@features/asset-page/more-details/more-details-components'

export function GenericHostSummary({ asset }: { asset: GenericHost }) {
  return (
    <Grid container item xs={12}>
      <AssetId asset={asset} />
      <AssetType asset={asset} />
      <AssetTags asset={asset} />
    </Grid>
  )
}
