/* eslint-disable import/no-extraneous-dependencies */
import { ListOvaAccountsClient } from '@lib/clients/ova-accounts'
import SystemHelper from '@lib/helpers/system.helper'
import { OvaAccount } from 'blues-corejs/dist'
import { useEffect, useState, useRef } from 'react'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

const listOvaAccountsClient = new ListOvaAccountsClient()

export interface ListOvaAccountsOutput {
  ovaAccountsList: Array<OvaAccount>
  isOvaAccountsLoading: boolean
}

/**
 * Custom hook to get Ova Accounts.
 * @returns list of OvaAccounts and loading state
 */
export function useFetchListOvaAccounts(): ListOvaAccountsOutput {
  const [ovaAccountsList, setOvaAccountsList] = useState<Array<OvaAccount>>([])
  const [isOvaAccountsLoading, setIsOvaAccountsLoading] = useState(false)

  const prevAccountOptions = useRef<Array<OvaAccount>>([])

  useEffect(() => {
    let isMounted = true
    setIsOvaAccountsLoading(true)

    async function fetchOvaAccountsList() {
      try {
        const response = await listOvaAccountsClient.getListOvaAccounts()
        const responseString = JSON.stringify(response)
        const ovaAccountsListString = JSON.stringify(prevAccountOptions.current)

        if (!isMounted) {
          return
        }

        if (responseString !== ovaAccountsListString) {
          prevAccountOptions.current = response
          setOvaAccountsList(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `Something went wrong in ${__filename}: ${error}`
        )
        console.error(
          `Error occurred in file ${__filename} while processing: ${error}`
        )
      } finally {
        setIsOvaAccountsLoading(false)
      }
    }

    fetchOvaAccountsList()
    const fetchInterval = setInterval(
      fetchOvaAccountsList,
      INTERVAL_REQUEST_TIMEOUT
    )

    return () => {
      clearInterval(fetchInterval)
      isMounted = false
      setIsOvaAccountsLoading(false)
      setOvaAccountsList([])
    }
  }, [])

  return {
    ovaAccountsList,
    isOvaAccountsLoading,
  }
}
