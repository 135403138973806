/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { JobCloudConnector } from 'blues-corejs/dist'
import JobFailedIcon from '@inline-img/general/job-dashboard/JobFailedIcon'
import JobInProgressIcon from '@inline-img/general/job-dashboard/JobInProgressIcon'
import JobScheduledIcon from '@inline-img/general/job-dashboard/JobScheduledIcon'
import JobSuccessIcon from '@inline-img/general/job-dashboard/JobSuccessIcon'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function getIcon(job: JobCloudConnector): JSX.Element {
  if (job.isScheduled) {
    return <JobScheduledIcon />
  }

  if (job.isInProgress) {
    return <JobInProgressIcon />
  }

  if (job.isFailed || job.isAborted) {
    return <JobFailedIcon />
  }

  if (job.isFinished) {
    return <JobSuccessIcon />
  }

  return <Typography variant="caption" fontSize="12px" />
}

export function getJobStatusIcon(job: JobCloudConnector): JSX.Element {
  const icon = getIcon(job)

  return (
    <Box display="flex" justifyContent="center">
      {icon}
    </Box>
  )
}
