import DeploymentFilesInterface from '@lib/interfaces/deployment-files.interface'
import RedStackModel from '@lib/models/red-stack.model'
import VpcModel from '@lib/models/vpc.model'
import ValueInterface from '@lib/interfaces/value.interface'
import CfnModel from '@lib/models/cfn.model'
import AwsAccountInfoModel from '@lib/models/aws-account-info.model'
import CloudDeploymentTaskInterface from '@lib/interfaces/deployment/cloud-deployment-task.interface'
import AvailabilityZoneModel from '@lib/models/availability-zone.model'
import AwsSecurityGroupsModel from '@lib/models/aws-security-groups.model'

export const REQUEST_DEPLOYMENT_FILES = 'REQUEST_DEPLOYMENT_FILES'
export const SET_DEPLOYMENT_FILES = 'SET_DEPLOYMENT_FILES'
export const SET_RED_STACKS_LIST = 'SET_RED_STACKS_LIST'
export const GET_ALL_RED_STACKS = 'GET_ALL_RED_STACKS'
export const SET_ALL_RED_STACKS = 'SET_ALL_RED_STACKS'
export const REQUEST_POSSIBLE_VPC_LIST = 'REQUEST_POSSIBLE_VPC_LIST'
export const SET_POSSIBLE_VPC_LIST = 'SET_POSSIBLE_VPC_LIST'
export const REQUEST_CF_LINK = 'REQUEST_CF_LINK'
export const SET_CF_LINK = 'SET_CF_LINK'
export const REQUEST_CFN = 'REQUEST_CFN'
export const REQUEST_CFN_SHADOW = 'REQUEST_CFN_SHADOW'
export const REQUEST_POSSIBLE_REGIONS_LIST = 'REQUEST_POSSIBLE_REGIONS_LIST'
export const SET_POSSIBLE_REGIONS_LIST = 'SET_POSSIBLE_REGIONS_LIST'
export const SET_CFN = 'SET_CFN'
export const REQUEST_AWS_ACCOUNT_INFO_LIST = 'REQUEST_AWS_ACCOUNT_INFO_LIST'
export const SET_AWS_ACCOUNT_INFO_LIST = 'SET_AWS_ACCOUNT_INFO_LIST'
export const INSTALL_RED_STACKS = 'INSTALL_RED_STACKS'
export const REPAIR_CLOUD_CONNECTOR = 'REPAIR_CLOUD_CONNECTOR'
export const REPAIR_VAULT = 'REPAIR_VAULT'
export const REQUEST_LIST_AWS_ACCOUNTS = 'REQUEST_LIST_AWS_ACCOUNTS'
export const SET_LIST_AWS_ACCOUNTS = 'SET_LIST_AWS_ACCOUNTS'
export const REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT =
  'REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT'
export const REQUEST_LIST_AVAILABILITY_ZONES = 'REQUEST_LIST_AVAILABILITY_ZONES'
export const SET_LIST_AVAILABILITY_ZONES = 'SET_LIST_AVAILABILITY_ZONES'
export const REQUEST_LIST_AWS_SECURITY_GROUPS =
  'REQUEST_LIST_AWS_SECURITY_GROUPS'
export const SET_LIST_AWS_SECURITY_GROUPS = 'SET_LIST_AWS_SECURITY_GROUPS'
export const REQUEST_IF_RED_STACK_EXIST = 'REQUEST_IF_RED_STACK_EXIST'
export const SET_IF_RED_STACK_EXIST = 'SET_IF_RED_STACK_EXIST'

export const requestDeploymentFiles = () => ({
  type: REQUEST_DEPLOYMENT_FILES,
  payload: null,
})

export const setDeploymentFiles = (payload: DeploymentFilesInterface) => ({
  type: SET_DEPLOYMENT_FILES,
  payload,
})

export const setRedStacksList = (payload: Array<RedStackModel>) => ({
  type: SET_RED_STACKS_LIST,
  payload,
})

export const getAllRedStacks = () => ({
  type: GET_ALL_RED_STACKS,
  payload: null,
})

export const setAllRedStacks = (payload: Array<RedStackModel>) => ({
  type: SET_ALL_RED_STACKS,
  payload,
})

export const requestPossibleVpcList = (accountId: string) => ({
  type: REQUEST_POSSIBLE_VPC_LIST,
  payload: accountId,
})

export const setPossibleVpcList = (vpcList: Array<VpcModel>) => ({
  type: SET_POSSIBLE_VPC_LIST,
  payload: vpcList,
})

export const requestPossibleVpcListByRegionAndAccount = (
  accountId: string,
  region: string
) => ({
  type: REQUEST_POSSIBLE_VPC_LIST_BY_REGION_AND_ACCOUNT,
  payload: {
    accountId,
    region,
  },
})

export const requestCFLink = () => ({
  type: REQUEST_CF_LINK,
  payload: null,
})

export const setCFLink = (link: string) => ({
  type: SET_CF_LINK,
  payload: link,
})

export const setCfn = (cfnModel: CfnModel) => ({
  type: SET_CFN,
  payload: cfnModel,
})

export const requestCfn = (accountId: string) => ({
  type: REQUEST_CFN,
  payload: accountId,
})

export const requestCfnShadow = (accountId: string) => ({
  type: REQUEST_CFN_SHADOW,
  payload: accountId,
})

export const requestPossibleRegionsList = () => ({
  type: REQUEST_POSSIBLE_REGIONS_LIST,
  payload: null,
})

export const setPossibleRegionsList = (regionsList: Array<ValueInterface>) => ({
  type: SET_POSSIBLE_REGIONS_LIST,
  payload: regionsList,
})

export const requestAwsAccountInfoList = (accountIds: Array<string>) => ({
  type: REQUEST_AWS_ACCOUNT_INFO_LIST,
  payload: accountIds,
})

export const setAwsAccountInfoList = (
  awsAccountInfoList: Array<AwsAccountInfoModel>
) => ({
  type: SET_AWS_ACCOUNT_INFO_LIST,
  payload: awsAccountInfoList,
})

export const installRedStacks = (
  accountId: string,
  isEnableSafetyLock: boolean,
  tasks: Array<CloudDeploymentTaskInterface>,
  callback: () => void
) => ({
  type: INSTALL_RED_STACKS,
  payload: {
    accountId,
    isEnableSafetyLock,
    tasks,
    callback,
  },
})

export const requestListAwsAccounts = () => ({
  type: REQUEST_LIST_AWS_ACCOUNTS,
  payload: null,
})

export const setListAwsAccounts = (accounts: Array<AwsAccountInfoModel>) => ({
  type: SET_LIST_AWS_ACCOUNTS,
  payload: accounts,
})

export const requestListAvailabilityZones = (redStackId: string) => ({
  type: REQUEST_LIST_AVAILABILITY_ZONES,
  payload: redStackId,
})

export const setListAvailabilityZones = (
  zones: Array<AvailabilityZoneModel>
) => ({
  type: SET_LIST_AVAILABILITY_ZONES,
  payload: zones,
})

export const requestListAwsSecurityGroups = (
  awsAccountId: string,
  awsRegion: string
) => ({
  type: REQUEST_LIST_AWS_SECURITY_GROUPS,
  payload: {
    awsAccountId,
    awsRegion,
  },
})

export const setListAwsSecurityGroups = (
  groups: Array<AwsSecurityGroupsModel>
) => ({
  type: SET_LIST_AWS_SECURITY_GROUPS,
  payload: groups,
})

export const requestIfRedStackExist = () => ({
  type: REQUEST_IF_RED_STACK_EXIST,
  payload: null,
})

export const setIfRedStackExist = (payload: boolean) => ({
  type: SET_IF_RED_STACK_EXIST,
  payload,
})
