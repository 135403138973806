import React from 'react'

type ScanStatusLabelOwnProps = {
  isInfected: boolean
}
function ScanStatusLabel({ isInfected }: ScanStatusLabelOwnProps) {
  if (!isInfected) {
    return null
  }

  return <span className="icLabel infected">Infected</span>
}

export default ScanStatusLabel
