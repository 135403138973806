/* eslint-disable import/no-extraneous-dependencies */
import { TabsBlockWrapper } from '@components-complex/dashboard-pages-v3/styles'
import FilterV3 from '@components-complex/filter-v3/FilterV3'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEfsAssetsSelect,
  getEfsSelectedAllPaths,
  getEfsSelectedSpecificPaths,
} from '@store/selectors/assets-select.selector'
import {
  requestLiveAssetsSelect,
  resetEfsSelectedData,
  setAssetsSelectSearch,
  setEfsAssetsSelectFilters,
} from '@store/actions/assets-select.action'
import {
  useEfsFilter,
  useEfsSelectPaths,
  useEfsSelectRows,
  getEfsPolicyTableColumnDefinitions,
} from '@features/policies/policy/policy-tables/efs-for-policy'
import { Table } from '@components/table'
import { Row } from '@tanstack/react-table'
import { PathsSubRow } from '@features/policies/policy/policy-tables/paths-sub-row'
import AddSpecificPathsModal from '@components-composite/modals/AddSpecificPathsModal'
import {
  isSelectedAllPathsForAsset,
  isSelectedSpecificPathForAsset,
} from '@features/policies/policy/policy-tables'
import { AssetWithRelatedAssets } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { EFS } from 'blues-corejs/dist'
import { VIRow } from '@lib/engine-types'
import { getAllRedStacks } from '@store/actions/rex.action'
import { PreloaderConstants } from '@lib/constants'
import usePreloader from '@lib/hooks/usePreloader'
import { getIsLiveAssetsLoading } from '@store/selectors/list-live-assets.selector'

interface Props {
  onEfsTableErrorChange: React.Dispatch<React.SetStateAction<boolean>>
  initSelectedFilters: VIRow
}

function EfsForPolicy({ onEfsTableErrorChange, initSelectedFilters }: Props) {
  const dispatch = useDispatch()
  const efsAssets = useSelector(getEfsAssetsSelect)
  const selectedSpecificPaths = useSelector(getEfsSelectedSpecificPaths)
  const selectedAllPaths = useSelector(getEfsSelectedAllPaths)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const isTableLoading =
    usePreloader(PreloaderConstants.REQUEST_ASSETS_SELECT_INVENTORY) ||
    isLiveAssetsLoading

  const {
    handleSearchChange,
    handleEfsFiltersChange,
    searchVal,
    possibleFilters,
    selectedFilters,
  } = useEfsFilter()

  const {
    selectedRows,
    setSelectedRows,
    expandedRows,
    setExpandedRow,
    pathsAddModalProps,
    handleSelectPathsClick,
    onSelectAllPaths,
    handleDeletePath,
  } = useEfsSelectPaths({ efsAssets })

  const { handleEfsSelect, handleSelectAllEfsRows } = useEfsSelectRows({
    efsAssets,
    onSelectAllPaths,
  })

  useEffect(() => {
    dispatch(resetEfsSelectedData())
    dispatch(setEfsAssetsSelectFilters(initSelectedFilters))
    dispatch(requestLiveAssetsSelect())
    dispatch(setAssetsSelectSearch(''))
    dispatch(getAllRedStacks())
  }, [])

  useEffect(() => {
    if (!efsAssets.length) {
      return
    }

    let errors = efsAssets.reduce((errCount, item, index) => {
      const isSelected = selectedRows[index]

      const asset = item.asset
      const isSelectedAllPath = isSelectedAllPathsForAsset(
        selectedAllPaths,
        asset.id
      )
      const isSelectedSpecificPath = isSelectedSpecificPathForAsset(
        selectedSpecificPaths,
        asset.id
      )
      const isAnyPathSelected = isSelectedAllPath || isSelectedSpecificPath

      if (isSelected && !isAnyPathSelected) {
        return errCount + 1
      }
      return errCount
    }, 0)
    const isNotAnyS3AssetsSelected = !Object.keys(selectedRows).length

    if (isNotAnyS3AssetsSelected) {
      errors++
    }

    onEfsTableErrorChange(errors > 0)
  }, [efsAssets, selectedSpecificPaths, selectedAllPaths, selectedRows])

  return (
    <TabsBlockWrapper>
      {!isTableLoading && (
        <FilterV3
          possibleFilters={possibleFilters}
          selectedFilters={selectedFilters}
          onFiltersChange={handleEfsFiltersChange}
          onSearchChange={handleSearchChange}
          searchVal={searchVal}
          turnOnSearch
          searchPlaceholder="Search by Asset ID"
          hideClearAllBtn={true}
          disabled={false}
          finalLoading
        />
      )}
      <Table
        columns={getEfsPolicyTableColumnDefinitions({
          handleEfsSelect,
          onSelectAllPaths,
          handleSelectPathsClick,
          selectedSpecificPaths,
          selectedAllPaths,
          handleSelectAllEfsRows,
        })}
        data={efsAssets}
        generalProps={{
          noDataMessage: 'No EFS found',
          isLoading: isTableLoading,
        }}
        handlers={{
          rowsSelection: {
            selectedRows: selectedRows,
            setSelectedRows: setSelectedRows,
          },
          rowsExpanded: {
            expandedRows: expandedRows,
            setExpandedRows: setExpandedRow,
          },
        }}
        advancedProps={{
          renderSubComponent: ({
            row,
          }: {
            row: Row<AssetWithRelatedAssets<EFS>>
          }) => {
            const { asset } = row.original
            return (
              <PathsSubRow
                assetId={asset.id}
                selectedSpecificPaths={selectedSpecificPaths}
                onDeletePath={handleDeletePath}
                selectedAllPaths={selectedAllPaths}
              />
            )
          },
          warningState: ({
            row,
          }: {
            row: Row<AssetWithRelatedAssets<EFS>>
          }) => {
            const { asset } = row.original
            const isSelected = row.getIsSelected()
            const isSelectedAllPath = isSelectedAllPathsForAsset(
              selectedAllPaths,
              asset.id
            )
            const isSelectedSpecificPath = isSelectedSpecificPathForAsset(
              selectedSpecificPaths,
              asset.id
            )
            return isSelected && !isSelectedAllPath && !isSelectedSpecificPath
          },
        }}
      />

      <AddSpecificPathsModal
        {...pathsAddModalProps}
        isShowPrefix={false}
        loading={false}
      />
    </TabsBlockWrapper>
  )
}

export default EfsForPolicy
