import { FormControl, FormHelperText } from '@mui/material'
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import Label from '../data-display/label'
import TimeFormatConstants from 'ui-v2/src/lib/constants/time-format.constant'
import { EngineCallback } from 'ui-v2/src/lib/engine-types'
import { HourMinuteInterface } from 'ui-v2/src/lib/helpers/time.helper'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const DEFAULT_MINUTE_STEP = 5

const TimepickerInput = ({
  label = 'Select Time',
  value,
  onChange,
  minTime,
  maxTime,
  disabled = false,
  error = false,
  helperText = '',
  minuteStep = DEFAULT_MINUTE_STEP,
  ...timePickerProps
}: TimepickerInputProps) => {
  const onChangeHandler = (v: Dayjs | null) => {
    if (!v) {
      return
    }

    onChange({
      minute: v.minute(),
      hour: v.hour(),
      timezone: v.tz()?.toString() ?? TimeFormatConstants.UTC_TIMEZONE_VALUE,
    })
  }

  const timePickerValue = dayjs()
    .tz(value.timezone)
    .set('minute', value.minute)
    .set('hour', value.hour)

  return (
    <>
      <FormControl error={error}>
        {!!label && (
          <Label variant="body1" fontWeight={0}>
            {label}
          </Label>
        )}
        <TimePicker
          value={timePickerValue}
          onChange={onChangeHandler}
          minTime={minTime}
          maxTime={maxTime}
          disabled={disabled}
          minutesStep={minuteStep}
          slotProps={{
            inputAdornment: {
              position: 'start',
            },
            textField: {
              size: 'small',
              error: false,
            },
          }}
          {...timePickerProps}
        />
      </FormControl>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}
interface TimepickerInputProps
  extends Omit<TimePickerProps<Dayjs>, 'onChange' | 'value'> {
  label?: string
  value: HourMinuteInterface
  onChange: EngineCallback<HourMinuteInterface>
  minTime?: Dayjs
  maxTime?: Dayjs
  disabled?: boolean
  error?: boolean
  helperText?: string
  minuteStep?: number
}
export default TimepickerInput
