import { useRef, useState } from 'react'
/* eslint-disable import/no-extraneous-dependencies */
import {
  ListScansForAssetClient,
  ListScansForAssetFilter,
  ListScansForAssetPagination,
} from '@lib/clients/scans/list-scans-for-asset'
import { ListScansForAssetRequest } from '@lib/clients/scans/types'
import { usePagination } from '@lib/hooks'
import { Scans, UseAssetScansDataFetcher } from './types'
import ObjHelper from '@lib/helpers/obj.helper'
import { useFetchStatus } from '@lib/hooks/api-hooks'
import {
  DetailsScans,
  ListScansForAssetResponse,
} from '@lib/clients/scans/list-scans-for-asset/list-scans-for-asset-client'
import { ListBackupsForAssetFilters } from '@lib/clients/backups/types'
import { ListBackupsForAssetFiltersParams } from '@lib/clients/backups/list-backups-for-asset/types'
import { ListScansForAssetFiltersParams } from '@lib/clients/scans/list-scans-for-asset/types'
import { ScansRun } from 'blues-corejs/dist/models'

export const INITIAL_STATE: Scans = {
  latestScans: [],
  allScans: [],
}

const listScansForAssetClient = new ListScansForAssetClient()

interface ExtendedAssetScansDataFetcher extends UseAssetScansDataFetcher {
  fetchInitial: (filter?: ListScansForAssetFiltersParams) => Promise<void>
  fetchOnRefreshInterval: (
    filter?: ListScansForAssetFiltersParams
  ) => Promise<void>
}

async function fetchInitialScansList(
  combinedFilters: ListScansForAssetFiltersParams
) {
  return listScansForAssetClient.listScansForAsset(
    new ListScansForAssetFilter(combinedFilters)
  )
}

async function fetchScansForAssetViaPagination(nextPageToken: string) {
  return listScansForAssetClient.listScansForAsset(
    new ListScansForAssetPagination(nextPageToken)
  )
}

const unionLatestScans = (
  prevScans: Array<DetailsScans>,
  newScans: Array<DetailsScans>
) => {
  const updatedScans = [...prevScans]
  newScans.forEach((scan) => {
    const findIndex = updatedScans.findIndex(
      (prevScan) =>
        prevScan.filesystemChecks?.id === scan.filesystemChecks?.id &&
        prevScan.malwares?.id === scan.malwares?.id &&
        prevScan.ransomwares?.id === scan.ransomwares?.id
    )
    if (findIndex > -1) {
      updatedScans[findIndex] = scan
    } else {
      updatedScans.unshift(scan)
    }
  })
  return updatedScans
}

const unionAllScans = (
  prevScans: Array<ScansRun>,
  newScans: Array<ScansRun>
) => {
  const updatedScans = [...prevScans]
  newScans.forEach((scan) => {
    const findIndex = updatedScans.findIndex(
      (prevScan) =>
        prevScan.ransomwareScansList.every(
          (val, index) => val.id === scan.ransomwareScansList[index]?.id
        ) &&
        prevScan.malwareScansList.every(
          (val, index) => val.id === scan.malwareScansList[index]?.id
        ) &&
        prevScan.filesystemChecksList.every(
          (val, index) => val.id === scan.filesystemChecksList[index]?.id
        )
    )
    if (findIndex > -1) {
      updatedScans[findIndex] = scan
    } else {
      updatedScans.unshift(scan)
    }
  })

  return updatedScans
}

export function useAssetScansDataFetcher(
  initialFilters?: ListScansForAssetRequest
): ExtendedAssetScansDataFetcher {
  const fetchStatus = useFetchStatus()
  const [scansData, setScansData] = useState<Scans>(INITIAL_STATE)

  const previousFilters = useRef<ListBackupsForAssetFiltersParams | null>(null)

  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()

  const updateScansData = (response: ListScansForAssetResponse) => {
    setScansData((prevScans) => ({
      latestScans: [...prevScans.latestScans, ...(response.runsList || [])],
      allScans: [...prevScans.allScans, ...(response.scansRunList || [])],
    }))
  }

  const updateScansDataOnRefreshInterval = (
    response: ListScansForAssetResponse
  ) => {
    setScansData((prevScans) => {
      return {
        latestScans: unionLatestScans(
          prevScans.latestScans,
          response.runsList || []
        ),
        allScans: unionAllScans(
          prevScans.allScans,
          response.scansRunList || []
        ),
      }
    })
  }

  const handleFetchSuccess = ({
    scansList,
    nextPageToken,
  }: {
    scansList: ListScansForAssetResponse
    nextPageToken?: string
  }) => {
    updateScansData(scansList)
    updatePageTokenAndFlagFetched(nextPageToken || undefined)
  }

  const resetData = () => {
    setScansData(INITIAL_STATE)
    resetPagination()
  }

  const setPreviousFilters = (newFilters: ListScansForAssetFiltersParams) => {
    previousFilters.current = newFilters
  }

  const fetchPagination = async () => {
    if (!pageToken) {
      return
    }

    fetchStatus.setFetching()
    try {
      const response = await fetchScansForAssetViaPagination(
        pageToken as string
      )

      fetchStatus.setSuccess()
      handleFetchSuccess({
        scansList: response,
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      console.error(`Error fetching scans request - ${error}`)
    } finally {
      fetchStatus.reset()
    }
  }

  const fetchInitial = async (filters?: ListBackupsForAssetFilters) => {
    const combinedFilters = {
      ...initialFilters,
      ...filters,
    }

    const areFiltersEqual = ObjHelper.isEqual(
      combinedFilters,
      previousFilters.current
    )

    if (areFiltersEqual) {
      return
    }

    fetchStatus.setFetching()

    try {
      const response = await fetchInitialScansList(combinedFilters)
      setPreviousFilters(combinedFilters)
      fetchStatus.setSuccess()
      resetData()
      handleFetchSuccess({
        scansList: response,
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      console.error(
        `Something went wrong with fetch scans for asset request in ${__filename}: ${error}`
      )
    } finally {
      fetchStatus.reset()
    }
  }

  const fetchOnRefreshInterval = async (
    filters?: ListBackupsForAssetFilters
  ) => {
    const combinedFilters = {
      ...initialFilters,
      ...filters,
    }

    try {
      const response = await fetchInitialScansList(combinedFilters)
      setPreviousFilters(combinedFilters)
      updateScansDataOnRefreshInterval(response)
    } catch (error) {
      console.error(
        `Something went wrong with fetch scans for asset request in ${__filename}: ${error}`
      )
    }
  }

  return {
    fetchInitial,
    onFetchData: fetchPagination,
    data: scansData,
    dataFetchStatus: fetchStatus.status,
    resetData,
    isLoadingData: fetchStatus.isLoading,
    fetchOnRefreshInterval,
  }
}
