import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ListEc2Filters } from 'ui-v2/src/lib/models/assets'
import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'
import { AssetsFilters, PolicyCoverageState } from 'ui-v2/src/lib/models/assets'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import {
  ASSET_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { ListEc2Request } from 'ui-v2/src/lib/grpc'

export const useAssetsEc2Filters = (): AssetsFilters<ListEc2Filters> => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setPageToken(undefined)
  }, [searchParams])

  return useMemo(() => {
    return {
      filters: {
        pageSize,
        pageToken,
        search: searchParams.get(SEARCH_PARAM) ?? undefined,
        assetIdList: searchParams
          .get(ASSET_FILTERS_MAP.INSTANCE_ID)
          ?.split(',')
          .filter(Boolean),
        regionsList: searchParams
          .get(ASSET_FILTERS_MAP.REGION)
          ?.split(',')
          .filter(Boolean),
        accountIdsList: searchParams
          .get(ASSET_FILTERS_MAP.ACCOUNT_ID)
          ?.split(',')
          .filter(Boolean),
        instanceStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.INSTANCE_STATE)?.split(',') ?? [],
          ListEc2Request.Initial.Filter.InstanceState
        ),
        protectionPolicyCoverageStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.POLICY_COVERAGE)?.split(',') ?? [],
          PolicyCoverageState
        ),
      },
      setPageSize,
      setPageToken,
    }
  }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
}
