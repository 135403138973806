import { PolicyStatus } from 'ui-v2/src/lib/constants/policies.constant'
import { BackupType } from '../../assets/live'
import { Nullable, StringTupleArray, VIRow } from 'ui-v2/src/lib/engine-types'
import { BaseModel } from '../../base-model'
import { AssetSource } from 'ui-v2/src/lib/constants/retention-policy-asset-source.constant'

export interface PaginatedInterface<T> {
  list: Array<T>
  total: number
}

export interface Tags {
  tagsMap: StringTupleArray
}
export interface AwsAsset {
  accountId: string
  region: string
  ec2: string
  ebs: string
  s3: string
}
export interface GenericHostAsset {
  hostname: string
}
export interface NasAsset {
  hostname: string
}

export interface Assets {
  awsList: Array<AwsAsset>
  genericList: Array<GenericHostAsset>
  nasList: Array<NasAsset>
}
export interface Vault {
  vault: string
  awsAccountId: string
  region: string
}

export interface RetentionDurationInterface {
  hours: number
  days: number
  weeks: number
  months: number
  years: number
  infinite?: boolean
}

export class Criterion {
  assetSource: AssetSource

  backupType: BackupType

  tags?: Tags

  assets?: Assets

  vault?: Vault

  awsAccountId: string

  awsRegion: string

  constructor(readonly params: any) {
    this.assetSource = params.assetSource
    this.backupType = params.backupType
    this.tags = params.tags
    this.assets = params.assets
    this.vault = params.vault
    this.awsAccountId = params.awsAccountId
    this.awsRegion = params.awsRegion
  }
}

export class Selector {
  criteriaList: Array<Criterion>

  constructor(readonly params: any) {
    this.criteriaList = params.criteriaList
  }
}

export class RetentionPolicy {
  keepAll?: RetentionDurationInterface

  keepHourly?: RetentionDurationInterface

  keepDaily?: RetentionDurationInterface

  keepWeekly?: RetentionDurationInterface

  keepMonthly?: RetentionDurationInterface

  keepYearly?: RetentionDurationInterface

  status: PolicyStatus

  constructor(readonly params: any) {
    this.keepAll = params.keepAll
    this.keepHourly = params.keepHourly
    this.keepDaily = params.keepDaily
    this.keepWeekly = params.keepWeekly
    this.keepMonthly = params.keepMonthly
    this.keepYearly = params.keepYearly
    this.status = params.status
  }
}

export interface DefaultRetentionPolicy {
  id: string
  policy?: RetentionPolicy
}

export interface CustomRetentionPolicy {
  id: string
  policy?: RetentionPolicy
  selectorsList?: Array<Selector>
}

export interface RowPeriodInterface {
  countDuration: string
  duration: string
  periodValue: string
  removable: boolean
}

export interface FormPeriodInterface {
  period: Array<RowPeriodInterface>
}

export enum SelectCategory {
  ACCOUNT_IDS = 'Account IDs',
  REGIONS = 'Regions',
  VAULTS = 'Vaults',
  ASSET_SOURCES = 'Asset Sources',
  BACKUP_TYPES = 'Backup Types',
  TAGS = 'Tags',
  ASSETS = 'Assets',
  ALL = 'All',
}

export class RetentionPolicyModel extends BaseModel {
  public readonly defaultPolicy?: DefaultRetentionPolicy

  public readonly customPolicy?: CustomRetentionPolicy

  public readonly coveredAssetCount: number

  public readonly coverageUpdatedAt?: Nullable<number>

  public readonly coverageInvalidatedAt?: Nullable<number>

  // extra
  public readonly policyName: string

  public readonly retention: string

  public readonly status: string

  public readonly policyStatus: PolicyStatus

  public readonly isDefault: boolean

  public readonly started: boolean

  public readonly dataForSelectedAssets?: VIRow

  public readonly dataForRecoveryPointTypes?: VIRow

  public readonly dataForPeriodForm: FormPeriodInterface

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.defaultPolicy = params.defaultPolicy
    this.customPolicy = params.customPolicy
    this.coveredAssetCount = params.coveredAssetCount
    this.coverageUpdatedAt = params.coverageUpdatedAt
    this.coverageInvalidatedAt = params.coverageInvalidatedAt

    // extra
    this.policyName = params.policyName
    this.isDefault = params.isDefault
    this.retention = params.retention
    this.policyStatus = params.policyStatus
    this.dataForPeriodForm = params.dataForPeriodForm
    this.dataForRecoveryPointTypes = params.dataForRecoveryPointTypes

    this.status = 'Paused'
    this.started = false

    if (this.defaultPolicy) {
      this.status =
        this.defaultPolicy.policy?.status ===
        PolicyStatus.POLICY_STATUS_DISABLED
          ? 'Paused'
          : 'Active'
      this.started =
        this.defaultPolicy.policy?.status === PolicyStatus.POLICY_STATUS_ENABLED
    }
    if (this.customPolicy) {
      this.status =
        this.customPolicy?.policy?.status ===
        PolicyStatus.POLICY_STATUS_DISABLED
          ? 'Paused'
          : 'Active'
      this.started =
        this.customPolicy?.policy?.status === PolicyStatus.POLICY_STATUS_ENABLED
    }

    const assetsRow: VIRow = []
    if (this.dataForRecoveryPointTypes) {
      this.dataForRecoveryPointTypes
        .filter((rpType) => rpType.name === SelectCategory.ASSETS)
        .map((item) => {
          item.options?.map((v) => {
            assetsRow.push(v)
          })
        })
    }

    this.dataForSelectedAssets = assetsRow
  }
}

export interface RetentionPolicyPriority {
  policyId: string
  priority: number
}

export interface RetentionPolicyListTableDataAttrs {
  retentionPolicy: PaginatedInterface<RetentionPolicyModel>
  scanOnlyReleaseJanuary: boolean
}

export class RetentionPolicyListTableRow {
  readonly #id: number

  readonly #policyName: string

  readonly #status: string

  readonly #retention: string

  readonly #isPaused: boolean

  readonly #action: string

  readonly #retentionPolicyList: Array<RetentionPolicyModel>

  constructor(parameters: RetentionPolicyListTableRowAttr) {
    const {
      id,
      policyName,
      status,
      retention,
      isPaused,
      action,
      retentionPolicyList,
    } = parameters

    this.#id = id
    this.#policyName = policyName
    this.#status = status
    this.#retention = retention
    this.#isPaused = isPaused
    this.#action = action
    this.#retentionPolicyList = retentionPolicyList
  }

  get id(): number {
    return this.#id
  }

  get policyName(): string {
    return this.#policyName
  }

  get status(): string {
    return this.#status
  }

  get retention(): string {
    return this.#retention
  }

  get isPaused(): boolean {
    return this.#isPaused
  }

  get action(): string {
    return this.#action
  }

  get retentionPolicyList(): Array<RetentionPolicyModel> {
    return this.#retentionPolicyList
  }
}
export interface RetentionPolicyListTableRowAttr {
  id: number
  policyName: string
  status: string
  retention: string
  isPaused: boolean
  action: string
  retentionPolicyList: Array<RetentionPolicyModel>
}
