import React, { memo } from 'react'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { Button } from '@mui/material'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import TagsMultipleSelect from '@components-composite/tags-multiple-select/TagsMultipleSelect'
import useRecoveryPointTypesData, {
  RecoveryPointTypesProps,
} from './useRecoveryPointTypesData'

function RecoveryPointTypes({
  onFiltersChange,
  selectedFilters = [],
  emptyFilters = [],
  loading,
  allSpecificAssets = [],
}: RecoveryPointTypesProps) {
  const {
    isLoading,
    isAllSelectsDisabled,
    hasAssetsSelected,
    canBeReset,
    vaults,
    accounts,
    regions,
    assetSources,
    backupTypes,
    tags,
    onInnerChange,
    onClearAll,
  } = useRecoveryPointTypesData({
    onFiltersChange,
    selectedFilters,
    emptyFilters,
    loading,
    allSpecificAssets,
  })

  const isRegionsSelectDisabled =
    isAllSelectsDisabled ||
    hasAssetsSelected ||
    vaults.selectedVaults.length > 0

  const isAccountsSelectDisabled =
    isAllSelectsDisabled ||
    hasAssetsSelected ||
    vaults.selectedVaults.length > 0

  const isAssetSourcesSelectDisabled = isAllSelectsDisabled || hasAssetsSelected

  return (
    <div className="wrap-1660656589835">
      {isLoading ? (
        <PreloaderBlock show />
      ) : (
        <>
          <div className="categoryWrapper">
            <ViMultipleSelect
              className="selectedValue placeholderAll"
              disabled={isAccountsSelectDisabled}
              possible={accounts.allPossibleAccountIds}
              selected={accounts.selectedAccounts}
              onChange={(value) =>
                onInnerChange(SelectCategory.ACCOUNT_IDS, value)
              }
              placeholder={`All ${SelectCategory.ACCOUNT_IDS}`}
              countLabel={`${SelectCategory.ACCOUNT_IDS} selected`}
              key={SelectCategory.ACCOUNT_IDS}
            />
          </div>
          <div className="categoryWrapper">
            <ViMultipleSelect
              className="selectedValue placeholderAll"
              disabled={isRegionsSelectDisabled}
              possible={regions.allPossibleRegions}
              selected={regions.selectedRegions}
              onChange={(value) => onInnerChange(SelectCategory.REGIONS, value)}
              placeholder={`All ${SelectCategory.REGIONS}`}
              countLabel={`${SelectCategory.REGIONS} selected`}
              key={SelectCategory.REGIONS}
            />
          </div>
          <div className="categoryWrapper">
            <ViMultipleSelect
              className="selectedValue placeholderAll"
              disabled={isAllSelectsDisabled}
              possible={vaults.allPossibleVaults}
              selected={vaults.selectedVaults}
              onChange={(value) => onInnerChange(SelectCategory.VAULTS, value)}
              placeholder={`All ${SelectCategory.VAULTS}`}
              countLabel={`${SelectCategory.VAULTS} selected`}
              key={SelectCategory.VAULTS}
            />
          </div>
          <div className="categoryWrapper">
            <ViMultipleSelect
              className="selectedValue placeholderAll"
              disabled={isAssetSourcesSelectDisabled}
              possible={assetSources.possibleAssetSources}
              selected={assetSources.selectedAssetSources}
              onChange={(value) =>
                onInnerChange(SelectCategory.ASSET_SOURCES, value)
              }
              placeholder={`All ${SelectCategory.ASSET_SOURCES}`}
              countLabel={`${SelectCategory.ASSET_SOURCES} selected`}
              key={SelectCategory.ASSET_SOURCES}
            />
          </div>
          <div className="categoryWrapper">
            <ViMultipleSelect
              className="selectedValue placeholderAll"
              disabled={isAllSelectsDisabled}
              possible={backupTypes.possibleBackupTypes}
              selected={backupTypes.selectedBackupTypes}
              onChange={(value) =>
                onInnerChange(SelectCategory.BACKUP_TYPES, value)
              }
              placeholder={`All ${SelectCategory.BACKUP_TYPES}`}
              countLabel={`${SelectCategory.BACKUP_TYPES} selected`}
              key={SelectCategory.BACKUP_TYPES}
            />
          </div>
          <div className="categoryWrapper">
            <TagsMultipleSelect
              className="selectedValue placeholderAll"
              disabled={isAllSelectsDisabled}
              possible={tags.allPossibleTags}
              selected={tags.selectedTags}
              onChange={(value) => onInnerChange(SelectCategory.TAGS, value)}
              placeholder={`All Recovery Points ${SelectCategory.TAGS}`}
              countLabel={`${SelectCategory.TAGS} selected`}
              key={SelectCategory.TAGS}
              title="All Recovery Points Tags drop down contains both recovery point tags and assets tags at the moment of backup."
            />
          </div>
          {canBeReset && (
            <div>
              <Button
                className="sizeS"
                disabled={isAllSelectsDisabled}
                type="button"
                color="primary"
                variant="text"
                onClick={onClearAll}
              >
                Clear all
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default memo(RecoveryPointTypes)
