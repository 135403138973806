import Stack from '@mui/material/Stack'

import { SearchFilterBar } from './search-filter-bar'
import { HistoryTable } from './table'

function History() {
  return (
    <Stack gap={2}>
      <SearchFilterBar />
      <HistoryTable />
    </Stack>
  )
}

export default History
