import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CalendarIcon from '@inline-img/inputs/calendar-icon'
import { DateOrTimestamp } from '@components-simple/date-time-tooltip'
import { useThreatSuppressionStore } from '@features/threat-suppression/use-threat-suppression-modal-store'

interface Props {
  threatId: string
  threatName: string
  threatLocation: string
  firstDetectedAt: DateOrTimestamp
}

function ThreatSuppression({
  threatId,
  threatName,
  threatLocation,
  firstDetectedAt,
}: Props) {
  const { openModal, setThreatInfo } = useThreatSuppressionStore()

  const handleOpenButtonClick = () => {
    setThreatInfo({
      threatLocation,
      threatId,
      threatName,
      firstDetectedAt,
    })
    openModal()
  }

  return (
    <Box display="flex" justifyContent="end">
      <Button
        variant="outlined"
        startIcon={<CalendarIcon />}
        size="small"
        onClick={handleOpenButtonClick}
      >
        Suppress until
      </Button>
    </Box>
  )
}

export default ThreatSuppression
