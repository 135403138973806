/* eslint-disable import/no-extraneous-dependencies */
import { AssetsEfsAttrs, EfsTableRow } from 'ui-v2/src/lib/models/assets'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { Backup, EFS, Threat } from 'blues-corejs/dist'
import { AssetsEfsBase } from './efs-base'

export class AssetsEfsTableData extends AssetsEfsBase {
  protected readonly filesystems: Array<EFS>

  protected readonly scans: Array<Scan>

  protected readonly lastBackups: Map<string, Backup>

  protected readonly threats: Array<Threat>

  protected readonly filesystemsWithScans: Array<{
    filesystem: EFS
    scans: Array<Scan>
  }>

  constructor(parameters: AssetsEfsAttrs) {
    super()
    this.filesystems = parameters.filesystems
    this.scans = parameters.scans
    this.lastBackups = parameters.lastBackups
    this.threats = parameters.threats
    this.filesystemsWithScans = this.getFilesystemsWithScans()
  }

  getEfsTableData(): Array<EfsTableRow> {
    return this.filesystems.map(
      (filesystem) =>
        new EfsTableRow({
          id: filesystem.id,
          name: filesystem.name,
          awsId: filesystem.awsId,
          lastBackupDate: this.getLastBackup(filesystem)?.timestamp,
          lastScanDate: this.getLastScanForFilesystem(filesystem)?.completedAt,
          backupPolicies: filesystem.backupPolicies,
          isUnhealthy: this.isUnhealthy(filesystem),
        })
    )
  }
}
