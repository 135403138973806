/* eslint-disable import/no-extraneous-dependencies */
import { Typography } from '@mui/material'
import AreaChart from '../dashboard-charts/area-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useMemo } from 'react'
import { CheckStatus } from 'blues-corejs/dist/models/backups/aws/awsb/types'
import { ElastioRecoveryPoint } from 'blues-corejs/dist'

export const RecoveryAssurance = () => {
  const { data: assetsLiveData } = useAssetsLiveQuery()

  const cleanBackups = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }
    const lastBackupsArray = Array.from(assetsLiveData.lastElastioBackupsMap)

    const cleanRPBackups = lastBackupsArray.reduce<Array<ElastioRecoveryPoint>>(
      (cleanBackupsAcc, [, backup]) => {
        if (
          (backup.ransomwareCheckStatus === CheckStatus.GOOD ||
            backup.ransomwareCheckStatus === CheckStatus.NOT_APPLICABLE) &&
          (backup.malwareCheckStatus === CheckStatus.GOOD ||
            backup.malwareCheckStatus === CheckStatus.NOT_APPLICABLE) &&
          (backup.fsCheckStatus === CheckStatus.GOOD ||
            backup.fsCheckStatus === CheckStatus.NOT_APPLICABLE)
        ) {
          return [...cleanBackupsAcc, backup]
        }
        return cleanBackupsAcc
      },
      []
    )

    return cleanRPBackups
  }, [assetsLiveData])

  return (
    <GradientCard>
      <Typography fontSize={16} fontWeight={700} mb={1} color="text.secondary">
        Recovery Assurance
      </Typography>
      <AreaChart cleanBackups={cleanBackups} />
    </GradientCard>
  )
}

export default RecoveryAssurance
