/* eslint-disable import/no-extraneous-dependencies */
import { useListCloudConnectorJobsQuery } from 'ui-v2/src/hooks/queries/jobs'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import { useState } from 'react'
import { useMemo } from 'react'
import { Asset } from 'blues-corejs/dist'
import { useAssetsListByIdQuery } from 'ui-v2/src/hooks/queries/assets'
import { useRedStackListQuery } from 'ui-v2/src/hooks/queries/red-stack'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import { MonitorTableData } from 'ui-v2/src/lib/use-cases/monitor'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { getJobColumns } from './column-config'

const Jobs = ({ asset }: JobsProps) => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)

  const {
    data: cloudConnectorJobsList,
    isLoading: isCloudConnectorJobsLoading,
  } = useListCloudConnectorJobsQuery({
    pageSize,
    pageToken,
    assetIdsList: asset ? [asset.id] : [],
  })

  const { data: assetIdsList, isLoading: isAssetsIdListLoading } =
    useAssetsListByIdQuery(
      (cloudConnectorJobsList &&
        cloudConnectorJobsList.jobsList
          .filter((jobList) => jobList.targetAssetId)
          .map((jobList) => jobList.targetAssetId)) ??
        []
    )

  const { data: redStackList, isLoading: isRedStackListLoading } =
    useRedStackListQuery()

  const redStackListData = useMemo(() => {
    return redStackList?.map(
      (data) =>
        new RedStackModel({
          redStackId: data.redStackId,
          accountAlias: data.accountAlias,
          awsRegion: data.awsRegion,
          awsAccount: data.awsAccountId,
          status: data.status,
          iamRole: data.iamRole,
          accountDescription: data.accountDescription,
          lastErrorMessage: data.lastErrorMessage,
          version: data.version,
          lastWarningMessage: data.lastWarningMessage,
          awsAccountCfnVersion: data.awsAccountCfnVersion,
          lastIntegrityCheckErrorsList: data.lastIntegrityCheckErrors,
          cfnUpgradeRequired: data.cfnUpgradeRequired,
        })
    )
  }, [redStackList])

  const data = useMemo(() => {
    if (!cloudConnectorJobsList || !redStackListData) {
      return undefined
    }

    return new MonitorTableData({
      monitors: cloudConnectorJobsList.jobsList,
      assetsList: assetIdsList?.assetsList ?? [],
      redStackListData: redStackListData,
    }).getMonitorData()
  }, [cloudConnectorJobsList, assetIdsList, redStackListData])

  const columns = useMemo(getJobColumns, [])

  const isLoading =
    isCloudConnectorJobsLoading ||
    isAssetsIdListLoading ||
    isRedStackListLoading

  return (
    <CursorTable
      checkable={false}
      data={data}
      columns={columns}
      isLoading={isLoading}
      pageSize={pageSize}
      pageToken={pageToken}
      paginationCursors={cloudConnectorJobsList?.pagination}
      setPageSize={setPageSize}
      setPageToken={setPageToken}
    />
  )
}
interface JobsProps {
  asset: Asset
}
export default Jobs
