import React, { useMemo } from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { EntropyGraph } from '@features/common/entropy-graph'
import { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { EntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'
import { StyledTimeSelect } from './styles'
import { MENU_ITEMS, columns, CHART_OPTIONS } from './consts'
import { getDateFormatBasedByRange } from './utils'
import { DateRangeFilter } from '@features/entropy-trends/components/entropy-trends-filters/composable'
import { GoogleDataTableRow } from 'react-google-charts'
import {
  useEntropyFiltersActions,
  useGetEntropyFiltersTimeSelection,
  useGetEntropyHistory,
  useGetEntropyFiltersLoading,
} from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'

export function EntropyTrendsGraph() {
  const { timeSelection } = useGetEntropyFiltersTimeSelection()
  const entropyTrendsHistory = useGetEntropyHistory()
  const { setFilters, fetchFilters } = useEntropyFiltersActions()
  const isLoading = useGetEntropyFiltersLoading()

  const shouldUseDynamicTicks =
    timeSelection !== EntropyTrendsTimeSelection.TODAY

  const rows = entropyTrendsHistory.anomalyDataPoints.map((dataPoint) => [
    dataPoint.timestamp,
    dataPoint.anomalyFindingCount,
  ])

  const chartOptions = useMemo(() => {
    return {
      ...CHART_OPTIONS,
      chartArea: {
        width: '80%',
        height: '100%',
      },
      hAxis: {
        type: 'date',
        format: getDateFormatBasedByRange(timeSelection),
      },
      bar: { groupWidth: '11' },
    }
  }, [timeSelection])

  // We set unknown because when we set custom styled Select types inheritance are broken
  const handleSelectDate = (event: SelectChangeEvent<unknown>) => {
    fetchFilters(event.target.value as EntropyTrendsTimeSelection)
  }

  const shouldDisableDateRangeSelector =
    timeSelection !== EntropyTrendsTimeSelection.CUSTOM_DATE

  const handleSelectPoint = (point: GoogleDataTableRow) => {
    const anomaly = entropyTrendsHistory.anomalyDataPoints.find(
      ({ timestamp }) => timestamp === point[0]
    )

    if (!anomaly) {
      setFilters({
        anomaliesIdsList: new Set(),
      })
      return
    }

    setFilters({
      anomaliesIdsList: new Set(anomaly.anomalyIdsList),
    })
  }

  return (
    <Paper component={Stack} padding="24px" width="100%" maxHeight="315px">
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent="flex-end"
      >
        <StyledTimeSelect
          size="small"
          value={timeSelection}
          onChange={handleSelectDate}
        >
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </StyledTimeSelect>
        <DateRangeFilter disabled={shouldDisableDateRangeSelector} />
      </Stack>

      <EntropyGraph
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        options={chartOptions}
        onSelectPoint={handleSelectPoint}
        shouldUseDynamicTicks={shouldUseDynamicTicks}
        chartType="ColumnChart"
        noNeedToRenderEmptyRows
      />
    </Paper>
  )
}
