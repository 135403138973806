import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const OvaAccountIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const backupVendorList =
    searchParams.get(ASSET_FILTERS_MAP.OVA_ACCOUNT_ID)?.split(',') || []

  const ovaAccountIdOptions = useMemo(() => {
    //TODO: call list ova accounts endpoint to get these options
    return []
  }, [])

  const handleOvaAccountIdChange = (
    newOvaAccountIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newOvaAccountIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.OVA_ACCOUNT_ID)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.OVA_ACCOUNT_ID,
        newOvaAccountIdList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={ovaAccountIdOptions}
      value={backupVendorList}
      onChange={handleOvaAccountIdChange}
      label="Account"
      width={200}
    />
  )
}

export default OvaAccountIdFilter
