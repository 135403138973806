import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CustomSchedule from '@features/reports/schedules/custom-schedule/custom-schedule'
import { CustomScheduleModalBackgroundStyle } from '@features/reports/schedules/custom-schedule/styles'
import { CustomScheduleState } from '@features/reports/schedules/custom-schedule/types'

interface CustomScheduleModalProps {
  isOpen: boolean
  customStyles: React.CSSProperties
  handleSaveCallback: (state: CustomScheduleState) => void
  handleCloseCallback: () => void
  initialValues: CustomScheduleState | null
}

function CustomScheduleModal({
  isOpen,
  handleSaveCallback,
  customStyles,
  handleCloseCallback,
  initialValues,
}: CustomScheduleModalProps) {
  const [open, setOpen] = useState(isOpen)
  const [getState, setGetState] = useState<(() => CustomScheduleState) | null>(
    null
  )

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const handleClose = () => {
    handleCloseCallback()
    setOpen(false)
  }

  const handleGetStateCallback = (stateGetter: () => CustomScheduleState) => {
    setGetState(() => stateGetter)
  }

  const handleSave = () => {
    if (getState) {
      const state = getState()
      handleSaveCallback(state)
    } else {
      handleCloseCallback()
    }
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <div className="wrap-1603900370692 jsBaseModal">
      <div
        onClick={handleClose}
        style={CustomScheduleModalBackgroundStyle}
        aria-label="baseModalBg"
      />
      <div className="baseModalContent" style={customStyles}>
        <Box padding="18px 32px">
          <CustomSchedule
            getStateCallback={handleGetStateCallback}
            initialValues={initialValues}
          />
          <Box display="flex" gap={2} mt={2} justifyContent="flex-end">
            <Button
              onClick={handleClose}
              sx={{
                background: 'var(--grey50)',
                minWidth: '75px !important',
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{
                background: 'var(--blue500)',
                minWidth: '75px !important',
                '&:hover': {
                  backgroundColor: '#24A1DA',
                },
              }}
              onClick={handleSave}
            >
              <Typography color="#fff" fontWeight={600} fontSize="14px">
                Create
              </Typography>
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default CustomScheduleModal
