/* eslint-disable import/no-extraneous-dependencies */
import { getVolumeVulnerabilitiesPossibleFilters } from '../get-possible-filters'
import { useSearchParams } from 'react-router-dom'
import { useAssetsVulnerabilitiesFilters } from 'ui-v2/src/hooks/filters/assets-vulnerabilities'
import {
  ASSETS_VULNERABILITIES_FILTERS_MAP,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import {
  FilterOptions,
  getRiskNameFormQuery,
  initialPrioritySortForEBSVolumeTable,
  useLiveAssetsDataForAssetsVulnerabilities,
} from '../utils'
import { useEffect, useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import { getVolumesVulnerabilitiesColumns } from './column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import {
  GetVolumesVulnerabilitiesFilters,
  ListVolumesVulnerabilities,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import AssetsVulnerabilitiesFilters from '../filters'

const AssetsVulnerabilitiesVolumes = () => {
  const { data: liveAssetsData, isLoading } =
    useLiveAssetsDataForAssetsVulnerabilities()
  const filters = useAssetsVulnerabilitiesFilters()
  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)

  const [queryParams, setQueryParams] = useState<{
    t?: string
    vulnerability?: string
    risk?: string
  }>({})
  const riskFromQuery = getRiskNameFormQuery(queryParams.risk)
  const vulnerabilitiesDetectionStrategy = useMemo(
    () => new VulnerabilitiesDetectionStrategy(),
    []
  )

  const data = useMemo(() => {
    if (!liveAssetsData) {
      return undefined
    }

    const { instances, volumes, snapshots, scans, lastBackups } = liveAssetsData

    if (instances.length || volumes.length) {
      return {
        instances,
        volumes,
        lastBackups,
        snapshots,
        scans,
      }
    }
  }, [liveAssetsData])

  const volumesFilteredData = useMemo(() => {
    if (!data) {
      return undefined
    }

    const parseFilters = (selectedFilters: Array<ValueInterface>) => {
      return DataHelper.getDataForDashboardFilter(selectedFilters, search ?? '')
    }

    return initialPrioritySortForEBSVolumeTable(
      new ListVolumesVulnerabilities(data).execute(
        parseFilters(filters),
        vulnerabilitiesDetectionStrategy
      ).volumesList
    )
  }, [data, filters])

  const volumeVulnerabilitiesPossibleFilters = useMemo(() => {
    if (!data) {
      return undefined
    }

    const vulnerabilitiesVolumeFilters = new GetVolumesVulnerabilitiesFilters({
      volumes: data.volumes,
      instances: data.instances,
      snapshots: data.snapshots,
      lastBackups: data.lastBackups,
      riskFromQuery: riskFromQuery,
    }).execute(vulnerabilitiesDetectionStrategy)

    const possibleVolumeFilters = getVolumeVulnerabilitiesPossibleFilters(
      vulnerabilitiesVolumeFilters,
      filters,
      vulnerabilitiesDetectionStrategy
    )

    const filterOptions: FilterOptions = possibleVolumeFilters.reduce(
      (acc, item) => {
        if (item.options) {
          acc[item.name] = item.options
            .map((option) => option.name)
            .filter((value): value is string => typeof value === 'string')
        } else {
          acc[item.name] = []
        }
        return acc
      },
      {} as FilterOptions
    )

    return filterOptions
  }, [data])

  const columns = useMemo(getVolumesVulnerabilitiesColumns, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    setQueryParams({
      t: urlParams.get('t') ?? undefined,
      vulnerability: urlParams.get('vulnerability') ?? undefined,
      risk: urlParams.get('risk') ?? undefined,
    })
  }, [window.location.search])

  return (
    <Stack gap={1}>
      <AssetsVulnerabilitiesFilters
        showSearch
        filters={[
          ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID,
          ASSETS_VULNERABILITIES_FILTERS_MAP.REGION,
          ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_ID,
          ASSETS_VULNERABILITIES_FILTERS_MAP.VOLUME_ID,
          ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_MISCONFIGURATION,
          ASSETS_VULNERABILITIES_FILTERS_MAP.RISK,
        ]}
        filterValues={{
          [ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID]:
            volumeVulnerabilitiesPossibleFilters?.accountId,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.REGION]:
            volumeVulnerabilitiesPossibleFilters?.regions,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_ID]:
            volumeVulnerabilitiesPossibleFilters?.attachedInstancesAwsIds,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.VOLUME_ID]:
            volumeVulnerabilitiesPossibleFilters?.volumeAwsIds,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_MISCONFIGURATION]:
            volumeVulnerabilitiesPossibleFilters?.ebsVulnerabilities,
          [ASSETS_VULNERABILITIES_FILTERS_MAP.RISK]:
            volumeVulnerabilitiesPossibleFilters?.riskType,
        }}
      />
      <ClientTable
        idField="volumeId"
        data={volumesFilteredData ?? []}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default AssetsVulnerabilitiesVolumes
