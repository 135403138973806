import { Tag, TagsFilter } from './types'
import { TagsFilter as GrpcTagsFilter } from '@lib/clients/grpc-imports'

function setSingleTag(tag: Tag): GrpcTagsFilter.Tag {
  const grpcTag = new GrpcTagsFilter.Tag()

  grpcTag.setKey(tag.key)
  grpcTag.setValue(tag.value)

  return grpcTag
}

export function buildTagsFilter(params: TagsFilter) {
  const tagsFilter = new GrpcTagsFilter()

  if (params.or.length) {
    tagsFilter.setOr(
      new GrpcTagsFilter.TagsList().setTagsList(
        params.or.map((tag) => setSingleTag(tag))
      )
    )
  }

  if (params.and.length) {
    tagsFilter.setAnd(
      new GrpcTagsFilter.TagsList().setTagsList(
        params.and.map((tag) => setSingleTag(tag))
      )
    )
  }

  return tagsFilter
}
