import { create } from 'zustand'

export interface EmailUser {
  id: string
  email: string
}

interface EmailSelectionActions {
  addEmail: (email: string) => void
  removeEmail: (id: string) => void
  setEmailsList: (emails: Array<EmailUser>) => void
  resetStore: () => void
}

export interface EmailSelectionState {
  emails: Array<EmailUser>
}

export type EmailStore = EmailSelectionActions & EmailSelectionState

const INITIAL_STATE: EmailSelectionState = {
  emails: [],
}

const useEmailStore = create<EmailStore>((set) => ({
  ...INITIAL_STATE,
  addEmail: (email) =>
    set((state) => {
      const isDuplicate = state.emails.some(
        (existing) => existing.email === email
      )
      if (isDuplicate) {
        return state
      }

      return {
        emails: [
          ...state.emails,
          {
            email,
            id: crypto.randomUUID(),
          },
        ],
      }
    }),
  removeEmail: (id) =>
    set((state) => {
      const emailToRemove = state.emails.find((email) => email.id === id)
      if (!emailToRemove) {
        return state
      }

      return {
        emails: state.emails.filter((email) => email.id !== id),
      }
    }),
  setEmailsList: (email) =>
    set((state) => {
      return {
        emails: [...state.emails, ...email],
      }
    }),
  resetStore: () => set({ ...INITIAL_STATE }),
}))

export const resetUseEmailStore = () => {
  useEmailStore.getState().resetStore()
}

export const useEmailSelectionState = () =>
  useEmailStore((state) => state.emails)

export const useEmailSelectionActions = () =>
  useEmailStore((state) => ({
    addEmail: state.addEmail,
    removeEmail: state.removeEmail,
    setEmails: state.setEmailsList,
  }))
