export enum Step {
  RULE_DETAILS = 0,
  INFRASTRUCTURE_SCOPE = 1,
  NOTIFICATIONS = 2,
  REVIEW_ENABLE = 3,
}

export enum StepWithoutInfrastructure {
  RULE_DETAILS = 0,
  NOTIFICATIONS = 1,
  REVIEW_ENABLE = 2,
}

export const STEP_CHANGE = 1
