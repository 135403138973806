import { useQuery } from '@tanstack/react-query'
import { ListEc2Filters } from 'ui-v2/src/lib/models/assets'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { AssetsClient } from 'ui-v2/src/lib/clients'

export function useAssetsEc2Query(filters: ListEc2Filters) {
  const assetsClient = useGrpcClient(AssetsClient)

  return useQuery({
    queryKey: ['assets', 'ec2', filters],
    queryFn: async () => {
      if (!assetsClient) {
        throw new Error('Assets client not initialized')
      }
      return assetsClient.listEc2(filters)
    },
    enabled: !!assetsClient,
  })
}
