import React, {
  ChangeEvent,
  ClipboardEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'
import { EngineCallback, FormRefType } from '@lib/engine-types'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { FormControl, InputAdornment, TextField } from '@mui/material'

// now it is obligatory to register onBlur event
// react hook forms change their implementation

interface Props {
  field: FormFieldInterface
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
  // eslint-disable-next-line react/require-default-props
  className?: any
  // eslint-disable-next-line react/require-default-props
  multiline?: boolean
  // eslint-disable-next-line react/require-default-props
  rows?: number
  // eslint-disable-next-line react/require-default-props
  onChange?: EngineCallback<ChangeEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  // eslint-disable-next-line react/require-default-props
  onPaste?: ClipboardEventHandler<HTMLDivElement>
  // eslint-disable-next-line react/require-default-props
  variant?: 'standard' | 'outlined' | 'filled'
  // eslint-disable-next-line react/require-default-props
  startAdorment?: JSX.Element
}

const CustomTextFieldV2 = forwardRef<HTMLInputElement, Props>(
  (
    {
      field,
      disabled,
      className,
      multiline,
      rows,
      onChange,
      onBlur,
      onPaste,
      variant = 'outlined',
      startAdorment,
    }: Props,
    ref: FormRefType
  ) => {
    const id = `id_${field.name}`

    return (
      <FormControl>
        <TextField
          className={className}
          id={id}
          error={field.hasError}
          helperText={field.errorText}
          label={field.label}
          name={field.name}
          variant={variant}
          value={field.value}
          placeholder={field.placeholder}
          inputRef={ref}
          inputProps={{
            'aria-label': field.label,
          }}
          autoComplete="off"
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          onPaste={onPaste}
          InputProps={{
            startAdornment: startAdorment && (
              <InputAdornment position="start">{startAdorment}</InputAdornment>
            ),
          }}
        />
      </FormControl>
    )
  }
)

export default memo(CustomTextFieldV2)
