/* eslint-disable import/no-extraneous-dependencies */
import { mapCloudConnectorJobStatusColorVariant } from 'ui-v2/src/lib/transformers/cloud-connector-job/cloud-connector-job'
import {
  CircularProgress,
  circularProgressClasses,
  Typography,
} from '@mui/material'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

const ProgressCell = ({ progress, status }: ProgressCellProps) => {
  return (
    <>
      <CircularProgress
        variant="determinate"
        sx={{ color: '#4E5665' }}
        size={20}
        thickness={8}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: mapCloudConnectorJobStatusColorVariant(status),
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={20}
        thickness={8}
        value={progress}
      />
      <Typography marginLeft={1} variant="body2" whiteSpace={'nowrap'}>
        {progress}% Completed
      </Typography>
    </>
  )
}
interface ProgressCellProps {
  progress: number
  status: CloudConnectorJobStatus
}
export default ProgressCell
