import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useMemo } from 'react'
import { useThreatsMappedByAsset } from './use-threats-mapped-by-asset'
import { assetFilterCriteria, initialPrioritySortForThreats } from './util'
import { Stack } from '@mui/material'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { useLoadingDebounce } from 'ui-v2/src/hooks/utils/debounce'
import { getActiveAssetThreatsColumns } from './column-config'
import {
  ACTIVE_THREATS_CSV_TABLE_HEADERS,
  prepareActiveThreatDataToCsvFormat,
} from './prepare-active-threats-to-csv'
import { ExportAsCsv } from 'ui-v2/src/components/common/export-csv'
import AssetsDetailsDrawer from 'ui-v2/src/components/drawers/asset-details'

const ActiveThreats = () => {
  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const { assetByThreat, isLoading: isAssetLoading } = useThreatsMappedByAsset(
    assetsLiveData?.threats || []
  )

  const sortedThreatList = useMemo(() => {
    if (!assetsLiveData?.threats) {
      return undefined
    }

    return initialPrioritySortForThreats({
      threats: assetsLiveData.threats,
      assetByThreat,
    })
  }, [assetsLiveData, assetByThreat])

  const filteredThreatsListByAssetsCriteria = useMemo(() => {
    if (!sortedThreatList) {
      return undefined
    }

    return sortedThreatList.filter((threat) => {
      const asset = assetByThreat.get(threat)

      if (!asset) {
        return false
      }

      return assetFilterCriteria(asset)
    })
  }, [sortedThreatList, assetByThreat])

  const isLoading = useLoadingDebounce(
    isAssetsLiveDataLoading || isAssetLoading
  )

  const columns = useMemo(() => {
    return getActiveAssetThreatsColumns(assetByThreat)
  }, [assetByThreat])

  return (
    <>
      <Stack gap={1}>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <ExportAsCsv
            disabled={!filteredThreatsListByAssetsCriteria?.length}
            headers={ACTIVE_THREATS_CSV_TABLE_HEADERS}
            filename="active-threats.csv"
            data={prepareActiveThreatDataToCsvFormat(
              filteredThreatsListByAssetsCriteria ?? [],
              assetByThreat
            )}
          />
        </Stack>
        <ClientTable
          data={filteredThreatsListByAssetsCriteria ?? []}
          columns={columns}
          isLoading={isLoading}
        />
      </Stack>
      <AssetsDetailsDrawer />
    </>
  )
}

export default ActiveThreats
