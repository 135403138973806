import type { ITimezoneOption, ITimezone } from 'react-timezone-select'
import { useState } from 'react'

import { usePrevious } from '@lib/hooks'

export function useTimezoneState(initialValue: ITimezoneOption) {
  const [selectedTimezone, setSelectedTimezone] =
    useState<ITimezoneOption>(initialValue)
  const prevTimezone = usePrevious(selectedTimezone)
  const isTimezoneSelected = selectedTimezone !== null
  const isTimezoneChanged =
    !isTimezoneSelected ||
    (prevTimezone && selectedTimezone?.value !== prevTimezone.value)

  const handleTimezoneSelectChange = (timezone: ITimezone) => {
    if (typeof timezone === 'string') {
      setSelectedTimezone({
        value: timezone,
        label: timezone,
      })
    } else {
      setSelectedTimezone({
        value: timezone.value,
        label: timezone.label,
      })
    }
  }

  return {
    selectedTimezone,
    handleTimezoneSelectChange,
    isTimezoneChanged,
  } as const
}
