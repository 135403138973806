import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import SettingsService from '@lib/services/high/settings.service'
import { Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'

dayjs.extend(utc)
dayjs.extend(timezone)

// Since `dayjs` does not have a built-in function to format dates as "X days ago", "X hours ago", etc.
// We should use this function to format the date like on the design
// FIXME: after date behaviour bug fixed, add here the correct implementation with timezones
function pluralize(value: number, unit: string) {
  return `${value} ${unit}${value === 1 ? '' : 's'} old`
}
export function customFormatDate(target: Dayjs) {
  const now = dayjs()

  const diffYears = now.diff(target, 'year')
  const diffMonths = now.diff(target, 'month')
  const diffDays = now.diff(target, 'day')
  const diffHours = now.diff(target, 'hour')
  const diffMinutes = now.diff(target, 'minute')

  if (diffYears >= 1) {
    return pluralize(diffYears, 'year')
  } else if (diffMonths >= 1) {
    return pluralize(diffMonths, 'month')
  } else if (diffDays >= 1) {
    return pluralize(diffDays, 'day')
  } else if (diffHours >= 1) {
    return '< 1 day old'
  } else if (diffMinutes >= 1) {
    return '< 1 hr old'
  } else {
    return '< 1 min old'
  }
}
interface Props {
  firstDetected: Date
}

export default function FirstDetectedCell({ firstDetected }: Props) {
  const timezoneConfig =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value

  const firstDetectedFormatted = customFormatDate(
    dayjs(firstDetected).tz(timezoneConfig)
  )

  return <Typography fontSize="14px">{firstDetectedFormatted}</Typography>
}
