/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { JobCloudConnector } from 'blues-corejs/dist'
import { getHumanReadableCloudConnectorJobKind } from '@features/asset-page/tables/asset-jobs-table/helpers/get-human-readable-cloud-connector-job-kind'

interface Props {
  job: JobCloudConnector
}

function TooltipTitle({ title }: { title: string }) {
  return (
    <Typography fontSize="14px" variant="caption">
      {title}
    </Typography>
  )
}

function JobKindCell({ job }: Props) {
  const tooltipTitle = getHumanReadableCloudConnectorJobKind(job)

  return (
    <Tooltip title={<TooltipTitle title={tooltipTitle} />}>
      <Box
        maxWidth="300px"
        whiteSpace="pre-line"
        sx={{
          cursor: 'default',
        }}
      >
        {job.name}
      </Box>
    </Tooltip>
  )
}

export default memo(JobKindCell)
