/* eslint-disable import/no-extraneous-dependencies */
import { Threat } from 'blues-corejs/dist'
import React from 'react'
import Typography from '@mui/material/Typography'

interface Props {
  threats: Array<Threat>
}

const EMPTY_THREATS_VALUE = '-'

const THREAT_NAMES_SEPARATOR = ', '

function computeThreatNames(threats: Array<Threat>) {
  const threatNames = [...new Set(threats.map((threat) => threat.name))]

  if (threatNames.length === 0) {
    return EMPTY_THREATS_VALUE
  }

  return threatNames.join(THREAT_NAMES_SEPARATOR)
}

function AssetItemScanThreatsCell({ threats }: Props) {
  const threatNames = computeThreatNames(threats)

  return (
    <Typography variant="caption" fontSize="14px">
      {threatNames}
    </Typography>
  )
}

export default AssetItemScanThreatsCell
