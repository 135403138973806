/* eslint-disable import/no-extraneous-dependencies */
import Link from 'ui-v2/src/components/ui/data-display/link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { InstancesOutput } from 'blues-corejs/dist'
import { IconButton, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { EC2_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'

export const getEc2ThreatColumns = (): Array<Column<InstancesOutput>> => [
  {
    key: 'instanceId',
    header: 'Health',
    render: (row) => (
      <StatusChip type={row.threats.length > 0 ? 'error' : 'success'} />
    ),
    width: 80,
    align: 'center',
  },
  {
    key: 'instanceAwsId',
    header: 'Instance',
    render: (row) => (
      <Link to={`/${row.instanceId}`} preservePath preserveSearchParams>
        {row.instanceAwsId}
      </Link>
    ),
  },
  {
    key: 'threats',
    header: 'Threats',
    render: (row) =>
      [...new Set(row.threats.map((threat) => threat.name))].join(', '),
    wrap: true,
  },
  {
    key: 'accountId',
    header: 'Account',
    render: (row) => (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {row.accountId}
        <IconButton
          onClick={() => copyToClipboard(row.accountId)}
          size="small"
          sx={{
            padding: '6px',
            '& svg': { fontSize: 16 },
          }}
        >
          <ContentCopy />
        </IconButton>
      </Stack>
    ),
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'lastBackup',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.lastBackup?.backup.createdAt),
  },
  {
    key: 'attachedVolumes',
    header: 'Attached Volumes',
  },
  {
    key: 'status',
    header: 'Status',
    render: (row) => EC2_STATE_MAP[row.status],
  },
]
