import React, { memo } from 'react'
import { EngineCallback } from '@lib/engine-types'
import clsx from 'clsx'
import { Checkbox, FormControlLabel } from '@mui/material'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

function IconClear() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1.00146"
        width="13"
        height="13"
        rx="2"
        fill="white"
        stroke="#DFEAF5"
        strokeWidth="2"
      />
    </svg>
  )
}

function IconChecked() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.00219727" width="13" height="13" rx="2" fill="#32B6F3" />
      <path
        transform="translate(2.5, 3)"
        d="M7.01936 1.00146L3.02075 5.00008L1.50069 3.48003L1 3.98072L3.02075 6.00146L7.52006 1.50216L7.01936 1.00146Z"
        fill="white"
        stroke="white"
      />
    </svg>
  )
}

interface Props {
  onChange: EngineCallback<boolean>
  label?: string | number | React.ReactElement
  checked?: boolean
  indeterminate?: boolean
  disabled?: boolean
  variant?: 'default' | 'table' | 'bordered'
  className?: string
}

function V2Checkbox({
  onChange,
  label = '',
  checked = false,
  indeterminate = false,
  disabled = false,
  variant = 'default',
  className = '',
}: Props) {
  return (
    <div
      className={clsx(
        `wrap-1618144255100 newMaterialCheckbox ${variant}Variant ${className}`,
        { noLabel: !label }
      )}
    >
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            indeterminate={indeterminate}
            icon={<IconClear />}
            checkedIcon={<IconChecked />}
            checked={checked}
            onChange={(e) => onChange(e.target.checked)}
            color="primary"
            id="checkbox"
          />
        }
        label={
          typeof label === 'string' ? (
            <LongTextTooltip text={label} maxLength={60} />
          ) : (
            label
          )
        }
      />
    </div>
  )
}

export default memo(V2Checkbox)
