import { Risk } from './types'

export function renderStatusRisk(variant: Risk[keyof Risk]): string {
  switch (variant) {
    case Risk.HIGH:
      return 'High risk'
    case Risk.MEDIUM:
      return 'Medium risk'
    case Risk.LOW:
      return 'Low risk'
    default:
      return ''
  }
}
