import React from 'react'

export default function SpeedometerHighRiskIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#E4F7FF" />
      <path
        d="M14.8262 5.57812C12.2287 5.72722 9.7929 6.73657 7.84863 8.46934L10.8555 11.4762C11.9871 10.5342 13.3611 9.96488 14.8262 9.83101V5.57812Z"
        fill="#32B6F3"
      />
      <path
        d="M9.90223 12.4287L6.89539 9.42188C5.16113 11.3656 4.15053 13.8016 4.00049 16.3997H8.25356C8.38832 14.9343 8.95883 13.5602 9.90223 12.4287Z"
        fill="#32B6F3"
      />
      <path
        d="M22.7463 16.3997H26.9994C26.8494 13.8016 25.8388 11.3656 24.1045 9.42188L21.0977 12.4287C22.0411 13.5602 22.6116 14.9343 22.7463 16.3997Z"
        fill="#32B6F3"
      />
      <path
        d="M22.7454 17.75C22.651 18.7878 22.3548 19.6535 22.0045 20.3478C22.3462 20.8748 22.6311 21.3202 22.8193 21.6159C23.3319 22.4215 23.2184 23.4555 22.5433 24.1306C22.3794 24.2945 22.1922 24.4266 21.9893 24.5242L22.6895 25.2246C22.8213 25.3564 22.9941 25.4223 23.1668 25.4223C23.3396 25.4223 23.5123 25.3564 23.6441 25.2246C25.6622 23.2067 26.8389 20.5736 26.9999 17.75H22.7454Z"
        fill="#32B6F3"
      />
      <path
        d="M8.25455 17.75H4C4.16104 20.5736 5.33777 23.2067 7.3558 25.2246C7.4876 25.3564 7.66032 25.4223 7.83305 25.4223C8.00577 25.4223 8.17854 25.3564 8.31034 25.2246L10.3548 23.18C10.6184 22.9164 10.6184 22.489 10.3548 22.2254C9.82129 21.6918 8.47736 20.1981 8.25455 17.75Z"
        fill="#32B6F3"
      />
      <path
        d="M23.1514 8.46934C21.2071 6.73653 18.7713 5.72722 16.1738 5.57812V9.83101C17.6389 9.96488 19.0129 10.5342 20.1445 11.4762L23.1514 8.46934Z"
        fill="#32B6F3"
      />
      <path
        d="M16.9972 15.5879C16.1717 14.7624 14.8285 14.7624 14.0029 15.5879C13.1774 16.4134 13.1774 17.7567 14.0029 18.5822C15.0458 19.6251 20.1712 22.8977 20.7522 23.2674C21.0187 23.437 21.3671 23.3987 21.5905 23.1754C21.8138 22.9521 21.852 22.6036 21.6825 22.3371C21.3127 21.7562 18.0402 16.6308 16.9972 15.5879Z"
        fill="#32B6F3"
      />
    </svg>
  )
}
