import { useQuery } from '@tanstack/react-query'
import { WebhooksClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useGetWebhookQuery(webhookId: string) {
  const webhooksClient = useGrpcClient(WebhooksClient)

  return useQuery({
    queryKey: ['webhooks', 'get', webhookId],
    queryFn: () => {
      if (!webhooksClient) {
        throw new Error('Webhooks client not initialized')
      }
      return webhooksClient.getWebhook(webhookId)
    },
    enabled: !!webhooksClient && !!webhookId,
  })
}
