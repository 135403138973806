/* eslint-disable import/no-extraneous-dependencies */
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { RecoveryPoint as AWSBRecoveryPointProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/backups/aws/awsb/recovery_point_pb'
import { AWSBRecoveryPoint } from 'blues-corejs/dist'
import {
  CheckStatus,
  CorrespondingBackup,
  RecoveryPointKind,
  RecoveryPointStatus,
  StorageClass,
} from 'blues-corejs/dist/models/backups/aws/awsb/types'

type AWSBRecoveryPointPt = AWSBRecoveryPointProto.AsObject

export class AWSBRecoveryPointTransformer {
  #awsbRecoveryPoint: AWSBRecoveryPointPt

  constructor(awsbRecoveryPoint: AWSBRecoveryPointPt) {
    this.#awsbRecoveryPoint = awsbRecoveryPoint
  }

  transform(): AWSBRecoveryPoint {
    return this.#transformInstance(this.#awsbRecoveryPoint)
  }

  #transformInstance(
    awsbRecoveryPoint: AWSBRecoveryPointPt
  ): AWSBRecoveryPoint {
    return new AWSBRecoveryPoint({
      accountId: awsbRecoveryPoint.accountId,
      arn: awsbRecoveryPoint.arn,
      calculatedLifecycle: {
        moveToColdStorageAt: secondsToDate(
          awsbRecoveryPoint.calculatedLifecycle?.moveToColdStorageAt?.seconds
        ),
        deleteAt: secondsToDate(
          awsbRecoveryPoint.calculatedLifecycle?.deleteAt?.seconds
        ),
      },
      correspondingBackup: this.#convertCorrespondingBackup(
        awsbRecoveryPoint.correspondingBackup as AWSBRecoveryPointProto.CorrespondingBackup.AsObject
      ),
      createdAt: secondsToDate(awsbRecoveryPoint.createdAt?.seconds),
      createdBy: {
        backupPlanArn: awsbRecoveryPoint.createdBy?.backupPlanArn as string,
        backupPlanId: awsbRecoveryPoint.createdBy?.backupPlanId as string,
        backupPlanVersion: awsbRecoveryPoint.createdBy
          ?.backupPlanVersion as string,
        backupRuleId: awsbRecoveryPoint.createdBy?.backupRuleId as string,
      },
      encrypted: awsbRecoveryPoint.encrypted,
      encryptionKeyArn: awsbRecoveryPoint.encryptionKeyArn,
      fsCheckStatus: this.#convertCheckStatus(awsbRecoveryPoint.fsCheckStatus),
      id: awsbRecoveryPoint.id,
      importJobId: awsbRecoveryPoint.importJobId,
      kind: this.#convertKind(awsbRecoveryPoint.kind),
      lifecycle: {
        moveToColdStorageAfter: secondsToDate(
          awsbRecoveryPoint.lifecycle?.moveToColdStorageAfter?.seconds
        ),
        deleteAfter: secondsToDate(
          awsbRecoveryPoint.lifecycle?.deleteAfter?.seconds
        ),
      },
      malwareCheckStatus: this.#convertCheckStatus(
        awsbRecoveryPoint.malwareCheckStatus
      ),
      parentRecoveryPointArn: awsbRecoveryPoint.parentRecoveryPointArn,
      ransomwareCheckStatus: this.#convertCheckStatus(
        awsbRecoveryPoint.ransomwareCheckStatus
      ),
      region: awsbRecoveryPoint.region,
      scanIds: awsbRecoveryPoint.scanIdsList,
      size: awsbRecoveryPoint.size,
      sourceAssetId: awsbRecoveryPoint.sourceAssetId,
      sourceBackupVaultArn: awsbRecoveryPoint.sourceBackupVaultArn,
      status: this.#convertStatus(awsbRecoveryPoint.status),
      storageClass: this.#convertStorageClass(awsbRecoveryPoint.storageClass),
      tags: awsbRecoveryPoint.tagsList,
      threatIds: awsbRecoveryPoint.threatIdsList,
      timestamp: secondsToDate(awsbRecoveryPoint.timestamp?.seconds),
      updatedAt: secondsToDate(awsbRecoveryPoint.updatedAt?.seconds),
      vault: {
        name: awsbRecoveryPoint.vault?.name as string,
        arn: awsbRecoveryPoint.vault?.arn as string,
        size: awsbRecoveryPoint.vault?.size?.value || 0,
        sizeLastCalculatedAt: secondsToDate(
          awsbRecoveryPoint.vault?.sizeLastCalculatedAt?.seconds
        ),
        createdAt: secondsToDate(awsbRecoveryPoint.vault?.createdAt?.seconds),
      },
    })
  }

  #convertCorrespondingBackup(
    backup: AWSBRecoveryPointProto.CorrespondingBackup.AsObject
  ): CorrespondingBackup {
    const correspondingBackup: Partial<CorrespondingBackup> = {}

    if (!backup) {
      return correspondingBackup as CorrespondingBackup
    }
    const elastioRecoveryPointId = backup.elastioRecoveryPoint?.id

    const ebsSnapshotId = backup.ebsSnapshot?.id

    if (elastioRecoveryPointId) {
      correspondingBackup.elastioRecoveryPoint = {
        erpId: elastioRecoveryPointId,
      }
    }

    if (ebsSnapshotId) {
      correspondingBackup.ebsSnapshot = {
        snapId: ebsSnapshotId,
      }
    }

    return correspondingBackup as CorrespondingBackup
  }

  #convertStorageClass(
    storageClass: AWSBRecoveryPointProto.StorageClass
  ): StorageClass {
    const mapping = {
      [AWSBRecoveryPointProto.StorageClass.STORAGE_CLASS_WARM]:
        StorageClass.CLASS_WARM,
      [AWSBRecoveryPointProto.StorageClass.STORAGE_CLASS_COLD]:
        StorageClass.CLASS_COLD,
      [AWSBRecoveryPointProto.StorageClass.STORAGE_CLASS_DELETED]:
        StorageClass.CLASS_DELETED,
    }

    return mapping[storageClass]
  }

  #convertCheckStatus(
    checkStatus: AWSBRecoveryPointProto.CheckStatus
  ): CheckStatus {
    const mapping = {
      [AWSBRecoveryPointProto.CheckStatus.CHECK_STATUS_UNCHECKED]:
        CheckStatus.UNCHECKED,
      [AWSBRecoveryPointProto.CheckStatus.CHECK_STATUS_GOOD]: CheckStatus.GOOD,
      [AWSBRecoveryPointProto.CheckStatus.CHECK_STATUS_BAD]: CheckStatus.BAD,
      [AWSBRecoveryPointProto.CheckStatus.CHECK_STATUS_NOT_APPLICABLE]:
        CheckStatus.NOT_APPLICABLE,
    }

    return mapping[checkStatus]
  }

  #convertKind(kind: AWSBRecoveryPointProto.Kind): RecoveryPointKind {
    const mapping: Record<AWSBRecoveryPointProto.Kind, RecoveryPointKind> = {
      [AWSBRecoveryPointProto.Kind.KIND_EBS]: RecoveryPointKind.EBS,
      [AWSBRecoveryPointProto.Kind.KIND_EC2]: RecoveryPointKind.EC2,
      [AWSBRecoveryPointProto.Kind.KIND_S3]: RecoveryPointKind.S3,
      [AWSBRecoveryPointProto.Kind.KIND_EFS]: RecoveryPointKind.EFS,
    }

    return mapping[kind]
  }

  #convertStatus(status: AWSBRecoveryPointProto.Status): RecoveryPointStatus {
    const mapping = {
      [AWSBRecoveryPointProto.Status.STATUS_DELETING]:
        RecoveryPointStatus.DELETING,
      [AWSBRecoveryPointProto.Status.STATUS_COMPLETED]:
        RecoveryPointStatus.COMPLETED,
      [AWSBRecoveryPointProto.Status.STATUS_EXPIRED]:
        RecoveryPointStatus.EXPIRED,
      [AWSBRecoveryPointProto.Status.STATUS_PARTIAL]:
        RecoveryPointStatus.PARTIAL,
      [AWSBRecoveryPointProto.Status.STATUS_STOPPED]:
        RecoveryPointStatus.STOPPED,
      [AWSBRecoveryPointProto.Status.STATUS_DELETED]:
        RecoveryPointStatus.DELETED,
    }
    return mapping[status]
  }
}
