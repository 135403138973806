/* eslint-disable import/no-extraneous-dependencies */
import { ScanStatus } from '@features/DashboardV4/common'
import { Nullable, RansomwareScan } from 'blues-corejs/dist'

export function getRansomwareScanStatus(
  ransomwareScans: Array<RansomwareScan>
): Nullable<ScanStatus> {
  if (ransomwareScans.length === 0) {
    return null
  }

  if (ransomwareScans.some((scan) => scan.isInfected)) {
    return ScanStatus.FAILED
  }

  if (ransomwareScans.every((scan) => scan.isClean)) {
    return ScanStatus.SUCCEEDED
  }

  return ScanStatus.UNSCANNED
}
