/* eslint-disable import/no-extraneous-dependencies */
import LangHelper from '@lib/helpers/lang.helper'
import { RiskLevel } from 'blues-corejs/dist'
import type { SnapshotOutput } from 'blues-corejs/dist/use_cases/list_snapshots_vulnerabilities'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function prepareSnapshotDataForCsv(snapshotsList: Array<SnapshotOutput>) {
  return snapshotsList.map((snapshot) => ({
    snapshotId: snapshot.snapshotId,
    misconfiguration: snapshot.vulnerabilitiesList.join(', '),
    risk: RiskLevel[Number(snapshot.riskVulnerabilities)],
    volumeAwsId: snapshot.volumeAwsId,
    // To prevent converting into number column
    accountId: `${snapshot.accountId}‎`,
    region: LangHelper.getAwsRegionSingleTranslation(snapshot.region),
  }))
}

const SNAPSHOT_CSV_TABLE_HEADERS = [
  {
    label: 'Snapshot ID',
    key: 'snapshotId',
  },
  {
    label: 'Misconfiguration',
    key: 'misconfiguration',
  },
  {
    label: 'Risk',
    key: 'risk',
  },
  {
    label: 'Volume ID',
    key: 'volumeAwsId',
  },
  {
    label: 'Account ID',
    key: 'accountId',
  },
  {
    label: 'Region',
    key: 'region',
  },
]

export { prepareSnapshotDataForCsv, SNAPSHOT_CSV_TABLE_HEADERS }
