import { NavigationStrategy } from '@lib/router/types'
import { useSpaRouter } from './use-spa-router'
import { useLocation, useNavigate } from 'react-router-dom'

export const useSelectNavigationStrategy = (): NavigationStrategy => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  return useSpaRouter({
    navigate,
    location,
    searchParams,
  })
}
