import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { SystemJobsFiltersStore } from '.'
import { getInitialFiltersState } from './consts'
import {
  fetchPossibleTenantJobFilters,
  removeFilter,
  setFilters,
} from './actions'

export const useSystemJobsFiltersStore = create<SystemJobsFiltersStore>()(
  subscribeWithSelector((set, get) => ({
    ...getInitialFiltersState(),
    actions: {
      resetSystemJobsFiltersState: () => set(getInitialFiltersState()),
      setTimeRange: (timeRange) => set({ timeRange }),
      setFilters: (selectedFilters) =>
        set((state) => setFilters(selectedFilters, state)),
      removeFilter: (key, value) =>
        set((state) => removeFilter(key, value, state)),
      fetchPossibleTenantJobFilters: async () => {
        const possibleFilters = await fetchPossibleTenantJobFilters(
          get().timeRange
        )

        set({
          possibleFilters,
        })
      },
    },
  }))
)
