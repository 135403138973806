/* eslint-disable import/no-extraneous-dependencies */
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { SelectSpecificPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import { emptySelectedSpecificPaths } from '@lib/constants/app.constant'

export function findAssetById({
  assets,
  assetId,
}: {
  assets: Array<AssetWithRelatedAssets<Asset>>
  assetId: string
}): AssetWithRelatedAssets<Asset> | undefined {
  return assets?.find((item) => item.asset?.id === assetId)
}

export function isSelectedAllPathsForAsset(
  selectedAllPaths: Array<string>,
  assetId: string
): boolean {
  return selectedAllPaths.includes(assetId)
}

export function isSelectedSpecificPathForAsset(
  selectedSpecificPaths: Array<SelectSpecificPathsInterface>,
  assetId: string
): boolean {
  return selectedSpecificPaths.some((path) => path.asset.asset.id === assetId)
}

export function findSelectedAssetIndex({
  assets,
  selectedAsset,
}: {
  assets: Array<AssetWithRelatedAssets<Asset>>
  selectedAsset: AssetWithRelatedAssets<Asset>
}) {
  return assets.findIndex(({ asset }) => asset.id === selectedAsset.asset.id)
}

export function getDataForSelectedAllAssetsWithPaths({
  assets,
}: {
  assets: Array<AssetWithRelatedAssets<Asset>>
}) {
  return {
    allSelected: assets,
    allSelectedComplex: assets.map((assetWithRelatedAssets) => ({
      selectedAsset: assetWithRelatedAssets.asset.id,
      assetModel: assetWithRelatedAssets,
      selectedAllPath: true,
      selectedSpecificPath: emptySelectedSpecificPaths(),
    })),
  }
}
