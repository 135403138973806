/* eslint-disable brace-style */
/* eslint-disable import/no-extraneous-dependencies */
import { Request as ListScansRequest } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/list_scans_by_filters_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { ListScansByFiltersRequest } from '@lib/clients/scans/list-scans-by-filters'
import {
  Kind,
  ListScansByFiltersReqFilterParams,
  ScanTargetKind,
  Status,
} from '../types'

const DEFAULT_PAGE_SIZE = 25

export class ListScansByFiltersReqFilter implements ListScansByFiltersRequest {
  #requestParams: ListScansByFiltersReqFilterParams

  constructor(requestParams: ListScansByFiltersReqFilterParams) {
    this.#requestParams = requestParams
  }

  toGrpc(): ListScansRequest {
    const grpcRequest = new ListScansRequest()

    const filter = this.#buildFilter()

    return grpcRequest.setInitial(
      new ListScansRequest.Initial()
        .setFilter(filter)
        .setPageSize(this.#requestParams.pageSize || DEFAULT_PAGE_SIZE)
    )
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #setupTimeRange({ start, end }: { start: number; end: number }) {
    const timeRange = new ListScansRequest.Initial.Filter.TimeRange()
    if (start) {
      timeRange.setStart(this.#createTimestamp(start))
    }

    if (end) {
      timeRange.setEnd(this.#createTimestamp(end))
    }
    return timeRange
  }

  #convertKind(kind: Kind): ListScansRequest.Initial.Filter.Kind {
    const mapping = {
      [Kind.FILE_SYSTEM_CHECK]:
        ListScansRequest.Initial.Filter.Kind.KIND_FILE_SYSTEM_CHECK,
      [Kind.MALWARE]: ListScansRequest.Initial.Filter.Kind.KIND_MALWARE,
      [Kind.RANSOMWARE]: ListScansRequest.Initial.Filter.Kind.KIND_RANSOMWARE,
    }

    return mapping[kind]
  }

  #convertScanTargetKind(
    scanTargetKind: ScanTargetKind
  ): ListScansRequest.Initial.Filter.ScanTargetKind {
    const mapping = {
      [ScanTargetKind.BACKUP]:
        ListScansRequest.Initial.Filter.ScanTargetKind.BACKUP,
      [ScanTargetKind.DIRECT_SCAN]:
        ListScansRequest.Initial.Filter.ScanTargetKind.DIRECT_SCAN,
    }

    return mapping[scanTargetKind]
  }

  #convertStatus(status: Status): ListScansRequest.Initial.Filter.Status {
    const mapping = {
      [Status.BAD]: ListScansRequest.Initial.Filter.Status.STATUS_BAD,
      [Status.GOOD]: ListScansRequest.Initial.Filter.Status.STATUS_GOOD,
    }

    return mapping[status]
  }

  #setFilterIfNotEmpty<T>(
    list: Array<T> | undefined,
    setter: (list: Array<T>) => void
  ): void {
    if (!list || !list.length) {
      return
    }

    setter(list)
  }

  #buildFilter() {
    const filter = new ListScansRequest.Initial.Filter()

    this.#setFilterIfNotEmpty(
      this.#requestParams.accountIdsList,
      filter.setAccountIdsList.bind(filter)
    )

    this.#setFilterIfNotEmpty(
      this.#requestParams.regionsList,
      filter.setRegionsList.bind(filter)
    )

    this.#setFilterIfNotEmpty(
      this.#requestParams.humanReadableRecoveryPointIdsList,
      filter.setHumanReadableRecoveryPointIdsList.bind(filter)
    )

    this.#setFilterIfNotEmpty(this.#requestParams.kindsList, (list) =>
      filter.setKindsList(list.map(this.#convertKind.bind(this)))
    )

    this.#setFilterIfNotEmpty(this.#requestParams.statusesList, (list) =>
      filter.setStatusesList(list.map(this.#convertStatus.bind(this)))
    )

    this.#setFilterIfNotEmpty(this.#requestParams.scanTargetKindsList, (list) =>
      filter.setScanTargetKindsList(
        list.map(this.#convertScanTargetKind.bind(this))
      )
    )

    this.#setFilterIfNotEmpty(
      this.#requestParams.assetIdList,
      filter.setAssetIdsList.bind(filter)
    )

    if (this.#requestParams.timeRange) {
      const timeRange = this.#setupTimeRange({
        start: this.#requestParams.timeRange.start as number,
        end: this.#requestParams.timeRange.end as number,
      })

      filter.setTimeRange(timeRange)
    }

    return filter
  }
}
