/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

export const CIRCLE_STATUS_COLOR = {
  SUCCESS: '#27ae60',
  PROGRESS: '#fec53d',
  WARNING: '#f2994a',
  ERROR: '#eb5757',
  ABORTED: '#dfeaf5',
  NONE: '#dfeaf5',
}

export const STATUS_TO_STYLE_VARIANT_MAPPING = {
  [CloudConnectorJobStatus.SCHEDULED]: 'statPendingVariant',
  [CloudConnectorJobStatus.STARTING]: 'statPendingVariant',
  [CloudConnectorJobStatus.IN_PROGRESS]: 'statProgressVariant',
  [CloudConnectorJobStatus.FAILED]: 'statFailedVariant',
  [CloudConnectorJobStatus.SUCCEEDED]: 'statSuccessVariant',
  [CloudConnectorJobStatus.ABORTED]: 'statAbortedVariant',
}
