/* eslint-disable import/no-extraneous-dependencies */
import { InventoryVolumesFilters } from 'blues-corejs/dist/use_cases/list_inventory_volumes/types'
import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'
import { EBS } from 'blues-corejs/dist'
/* eslint-disable import/no-extraneous-dependencies */
import RedStackModel from '@lib/models/red-stack.model'
import { getAliasNamesForAccounts } from '../utils'

export function getDataForPossibleEbsInventoryFilters(
  filterEbsInventory: InventoryVolumesFilters
) {
  return [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: filterEbsInventory?.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: filterEbsInventory?.regions,
    },
    {
      id: FilterIdDashboard.COVERED,
      covered: filterEbsInventory?.covered,
    },
    {
      id: FilterIdDashboard.BACKUPS_ON_SCHEDULE,
      backupsOnSchedule: filterEbsInventory?.backupsOnSchedule,
    },
  ]
}

export function getAliasNamesForEBS(
  ebsList: Array<EBS>,
  allActiveRedStacks: Array<RedStackModel>
) {
  if (allActiveRedStacks.length === 0 || !ebsList) {
    return {}
  }
  const accountIds: Set<string> = new Set()

  ebsList.forEach((ebs) => {
    accountIds.add(ebs.awsAccountId)
  })

  return getAliasNamesForAccounts(allActiveRedStacks, accountIds)
}
