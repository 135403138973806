/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { OvaAccount, OvaBackupProvider } from 'blues-corejs/dist'
import { MultiSelectSearch } from '@components/components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/multi-select-search'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'

import type { OptionType } from '@components/components-complex/dashboard-pages-v3/ova-server-table/multi-select-search/'
import {
  useToolbarFiltersActions,
  useToolbarFiltersState,
} from '../toolbar-filters-store'
import { getPossibleOvaProviders } from '../helpers'

interface Props {
  ovaAccountsList: Array<OvaAccount>
}

function buildBackupProvidersOptions(
  ovaBackupProviders: Array<OvaBackupProvider>
): Array<OptionType> {
  return ovaBackupProviders.map((provider) => ({
    label: HUMAN_READABLE_OVA_BACKUP_PROVIDER[provider],
    value: provider,
  }))
}

export function OvaBackupProvidersSelect({ ovaAccountsList }: Props) {
  const {
    backupProvidersList: backupProvidersListState,
    ovaAccountsList: ovaAccountsListState,
  } = useToolbarFiltersState()

  const { setBackupProvidersList } = useToolbarFiltersActions()

  const backupProvidersOptions = buildBackupProvidersOptions(
    getPossibleOvaProviders(
      ovaAccountsListState.length ? ovaAccountsListState : ovaAccountsList
    )
  )

  const selectedBackupProvidersOptions = buildBackupProvidersOptions(
    backupProvidersListState
  )

  const handleBackupProvidersChange = (
    backupProvidersList: Array<OptionType>
  ) => {
    setBackupProvidersList(
      backupProvidersList.map((bp) => bp.value) as Array<OvaBackupProvider>
    )
  }

  return (
    <MultiSelectSearch
      key={backupProvidersListState.length}
      tagsTitle="Backup Vendor"
      placeholder="Backup Vendor"
      options={backupProvidersOptions}
      selectedOptions={selectedBackupProvidersOptions}
      onDone={handleBackupProvidersChange}
    />
  )
}
