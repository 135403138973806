import { MUITabs } from '@lib/theme'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import DialogModal from '@components-composite/modals/DialogModal'
import { useManagementViewConfig } from './useManagementViewConfig'
import { renderIconByVariant } from './icon-renderer'
import { MIN_HEIGHT_FOR_TAB } from './consts'
import AddTabAction from './add-tab-action'

import ActionsRenderer from './actions-renderer'

function ManagementViews() {
  const {
    activeView,
    viewsToDisplay,
    modalProps,
    handleSwitchView,
    handleViewDelete,
    handleViewUpdate,
    isSelectedViewEditable,
  } = useManagementViewConfig()

  return (
    <Box display="flex" alignItems="center" marginY="8px">
      <MUITabs
        minheight={MIN_HEIGHT_FOR_TAB}
        value={activeView}
        onChange={handleSwitchView}
      >
        {viewsToDisplay.map((tab, index) => {
          const selected = activeView === index
          const editable = isSelectedViewEditable(tab.id)

          const tabIcon = renderIconByVariant({
            variant: tab.variant,
            selected,
          })

          return (
            <Tab
              key={index}
              icon={tabIcon}
              iconPosition="start"
              label={
                <ActionsRenderer
                  kind={tab.kind}
                  tab={tab}
                  selected={selected}
                  editable={editable}
                  onViewUpdate={handleViewUpdate}
                  onViewDelete={handleViewDelete}
                />
              }
            />
          )
        })}
      </MUITabs>
      <AddTabAction />
      <DialogModal
        description={
          <Box className="word-break-all">
            You are going to remove the {modalProps.data?.name} view tab <br />
            Please confirm the deletion.
          </Box>
        }
        {...modalProps}
      />
    </Box>
  )
}

export default ManagementViews
