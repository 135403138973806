/* eslint-disable import/no-extraneous-dependencies */
import { S3Bucket } from 'blues-corejs/dist'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

export function computeS3BucketState(bucket: S3Bucket): string {
  if (bucket.state === S3BucketState.IN_USE) {
    return 'In Use'
  }

  if (bucket.state === S3BucketState.DELETED) {
    return 'Deleted'
  }

  if (bucket.state === S3BucketState.UNKNOWN) {
    return 'Unknown'
  }

  sentryReThrowCatchHandler(
    `computeS3BucketState: Unknown state: ${bucket.state}`
  )
  throw new Error(`computeS3BucketState: Unknown state: ${bucket.state}`)
}
