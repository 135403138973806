/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllRedStacksList } from '@store/selectors/rex.selector'
import { useSelector, useDispatch } from 'react-redux'
import { NO_JOBS_MESSAGE } from './consts'
import { Box } from '@mui/material'
import { Table } from '@components/table'
import { getColumnsDefinitions } from './get-system-jobs-columns-definitions'
import DateRangeSelect from '@components-composite/date-range-select/DateRangeSelect'
import { DateRangeTuple, DefinedDateRangeTuple } from '@lib/engine-types'
import { FilterModalButton } from '../shared'
import { FiltersModal, useModalFilterActions } from './filters-modal'
import { SelectedFiltersChipsBlock } from './selected-filters-chips-block'
import { useTrackChangedJobs } from './use-track-changed-jobs'
import {
  useFiltersSubscription,
  useSelectedSystemJobFilters,
  useShouldDisableFilterButton,
  useSystemJobsFiltersActions,
  useSystemJobsIsLoading,
  useSystemJobsList,
  useSystemJobsStoreActions,
  useSystemJobsTimeRange,
} from './stores'

import styles from './system-jobs.module.css'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import moment from 'moment-timezone'

function Filters() {
  const timeRangeState = useSystemJobsTimeRange()
  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()
  const timeRangeWithTimeZone: DateRangeTuple = [
    moment(timeRangeState[0]).tz(timeZone.value),
    moment(timeRangeState[1]).tz(timeZone.value),
  ]
  const minMaxRange: DateRangeTuple = [
    moment().tz(timeZone.value).subtract(1, 'month').startOf('month'),
    moment().endOf('day').tz(timeZone.value),
  ]

  const shouldDisableFilterButton = useShouldDisableFilterButton()

  const { setTimeRange } = useSystemJobsFiltersActions()

  const { openFilterDialog } = useModalFilterActions()

  function handleTimeRangeChange(timeRange: DateRangeTuple) {
    const convertTimeRange = [timeRange[0]?.valueOf(), timeRange[1]?.valueOf()]
    setTimeRange(convertTimeRange as DefinedDateRangeTuple)
  }

  const selectedFilters = useSelectedSystemJobFilters()

  function handleFilterButtonClick() {
    openFilterDialog(selectedFilters)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="25px"
    >
      <FilterModalButton
        disabled={shouldDisableFilterButton}
        onButtonClick={handleFilterButtonClick}
      />
      <DateRangeSelect
        value={timeRangeWithTimeZone}
        onChange={handleTimeRangeChange}
        minMaxRange={minMaxRange}
      />
    </Box>
  )
}

export function SystemJobs() {
  const allRedStacksList = useSelector(getAllRedStacksList)

  const { fetchPossibleTenantJobFilters, resetSystemJobsFiltersState } =
    useSystemJobsFiltersActions()

  const {
    fetchInitialSystemJobs,
    fetchPaginatedSystemJobs,
    resetSystemJobsState,
  } = useSystemJobsStoreActions()

  const dispatch = useDispatch()

  const systemJobsList = useSystemJobsList()

  const isSystemJobsLoading = useSystemJobsIsLoading()

  useEffect(() => {
    dispatch(getAllRedStacks())
    fetchInitialSystemJobs()
    fetchPossibleTenantJobFilters()

    return () => {
      resetSystemJobsState()
      resetSystemJobsFiltersState()
    }
  }, [])

  const observableState = useInfiniteScrollDataFetch({
    initialItems: [],
    fetchNextBatch: fetchPaginatedSystemJobs,
  })

  useTrackChangedJobs()

  useFiltersSubscription()

  return (
    <Box className="innerContent">
      <Box>
        <FiltersModal />
        <Filters />
        <SelectedFiltersChipsBlock />
        <Table
          className={styles['tenant-jobs-table']}
          data={systemJobsList}
          // @ts-ignore Please help me fix that!
          columns={getColumnsDefinitions({ allRedStacksList })}
          generalProps={{
            isLoading: isSystemJobsLoading,
            noDataMessage: NO_JOBS_MESSAGE,
          }}
          advancedProps={{
            observableState,
            expandableRowKey: 'childrenList',
          }}
        />
      </Box>
    </Box>
  )
}
