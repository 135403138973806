import { ScheduledCcJob } from 'ui-v2/src/features/scheduled-jobs-monitoring/models'

export interface ScheduledCcJobsListParams {
  jobIds: Array<string>
  start: Date
  end: Date
}

interface ScheduledCcJobsClient {
  list(params: ScheduledCcJobsListParams): Promise<Array<ScheduledCcJob>>
  get(jobId: string): Promise<ScheduledCcJob>
}

interface ScheduledCcJobRepository {
  insert(job: ScheduledCcJob): Promise<void>
  get(jobId: string): Promise<ScheduledCcJob | undefined>
  list(): Promise<Array<ScheduledCcJob>>
}

interface Notifier {
  info(text: string): void
  warning(text: string): void
}

export class ScheduleCcJobsUseCase {
  constructor(
    private repository: ScheduledCcJobRepository,
    private client: ScheduledCcJobsClient,
    private notifier: Notifier
  ) {}

  async execute(jobIds: Array<string>): Promise<void> {
    try {
      const existingJobs = await this.#getExistingJobs()

      const { duplicateJobIds, newJobIds } = this.#categorizeJobIds(
        jobIds,
        existingJobs
      )
      if (duplicateJobIds.length > 0) {
        this.#notifyDuplicates(duplicateJobIds)
        return
      }

      const validNewJobs = await this.#fetchNewJobs(newJobIds)
      await this.#insertNewJobs(validNewJobs)

      this.notifier.info('Scans scheduled successfully.')
    } catch (error) {
      const err = error as Error
      throw new Error(`Failed to execute job with ID: ${err.message}`)
    }
  }

  async #getExistingJobs(): Promise<Array<ScheduledCcJob>> {
    return this.repository.list()
  }

  #categorizeJobIds(
    jobIds: Array<string>,
    existingJobs: Array<ScheduledCcJob>
  ): { duplicateJobIds: Array<string>; newJobIds: Array<string> } {
    const existingJobIdsSet = new Set(existingJobs.map((job) => job.id))
    const duplicateJobIds: Array<string> = []
    const newJobIds: Array<string> = []

    for (const jobId of jobIds) {
      if (existingJobIdsSet.has(jobId)) {
        duplicateJobIds.push(jobId)
      } else {
        newJobIds.push(jobId)
      }
    }

    return {
      duplicateJobIds,
      newJobIds,
    }
  }

  #notifyDuplicates(duplicateJobIds: Array<string>): void {
    if (duplicateJobIds.length > 0) {
      this.notifier.warning('This job will not be scheduled.')
    }
  }

  async #fetchNewJobs(
    newJobIds: Array<string>
  ): Promise<Array<ScheduledCcJob>> {
    const newJobPromises = newJobIds.map((jobId: string) =>
      this.client.get(jobId)
    )
    const newJobs = await Promise.all(newJobPromises)
    return newJobs
  }

  async #insertNewJobs(newJobs: Array<ScheduledCcJob>): Promise<void> {
    for (const job of newJobs) {
      await this.repository.insert(job)
    }
  }
}
