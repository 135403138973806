import { Dayjs } from 'dayjs'
import { momentShortDatetimeFormat } from 'ui-v2/src/lib/helpers/time.helper'
import { Nullable } from 'ui-v2/src/lib/engine-types'
import { BaseModel } from '../base-model'
import UserRoleModel from './user-role'
import UserStatusConstant, {
  UserStatusData,
} from 'ui-v2/src/lib/constants/user-status.constant'

class UserModel extends BaseModel {
  public readonly email: string

  public readonly locale: string

  public readonly name: string

  public readonly nickname: string

  public readonly picture: string

  public readonly lastSeen: Nullable<Dayjs>

  public readonly status: string

  public readonly role: UserRoleModel

  public readonly phoneNumber: string

  // extra

  public readonly isEmpty: boolean

  public readonly isNotEmpty: boolean

  public readonly lastSeenFormatted: string

  public readonly statusParsed: UserStatusData

  public congratulationsCanBeShown: boolean

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.email = params.email
    this.locale = params.locale
    this.name = params.name
    this.nickname = params.nickname
    this.picture = params.picture
    this.lastSeen = params.lastSeen
    this.status = params.status
    this.phoneNumber = params.phoneNumber
    this.role = params.role

    // extra
    this.isEmpty = !this.email
    this.isNotEmpty = !this.isEmpty
    this.lastSeenFormatted = momentShortDatetimeFormat(this.lastSeen)
    this.statusParsed = UserStatusConstant.getFromTheUsersString(this.status)
    // hardcode - Andrii Horodnytskyi - 30.07.21
    // instead of the owner's creator flag
    this.congratulationsCanBeShown =
      this.role.scopesList.includes('sources:write')
  }
}

export default UserModel
