import { TimeFormatConstants } from '@lib/constants'
import type { DetailsDetectedThreatOutput } from 'blues-corejs/dist/use_cases/list_details_detected_threats'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function prepareThreatsDataForCsv(
  threatsList: Array<DetailsDetectedThreatOutput>
) {
  return threatsList.map((threat) => ({
    path: threat.location,
    threat: threat.threatName,
    firstDetected: `${dayjs
      .utc(threat.firstDetectedAt)
      .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`,
  }))
}

const THREATS_CSV_TABLE_HEADERS = [
  {
    label: 'Path',
    key: 'path',
  },
  {
    label: 'Threat',
    key: 'threat',
  },
  {
    label: 'First Detected',
    key: 'firstDetected',
  },
]

export { prepareThreatsDataForCsv, THREATS_CSV_TABLE_HEADERS }
