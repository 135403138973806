import { useShallow } from 'zustand/react/shallow'
import { SYSTEM_JOB_KINDS } from '@features/jobs/tenant-jobs/system-jobs/consts'
import { useSystemJobsFiltersStore } from '@features/jobs/tenant-jobs/system-jobs/stores/filters-store/system-jobs-filters-store'

export const getSelectedFilters = () => ({
  selectedFilters: useSystemJobsFiltersStore.getState().selectedFilters,
  timeRange: useSystemJobsFiltersStore.getState().timeRange,
})

export const usePossibleTenantJobFilters = () =>
  useSystemJobsFiltersStore(
    useShallow((state) => {
      const { possibleFilters } = state

      const deploymentJobKinds = [...possibleFilters.kindsList].filter((kind) =>
        SYSTEM_JOB_KINDS.includes(kind)
      )

      return {
        kindsList: deploymentJobKinds,
        statusesList: possibleFilters.statusesList,
      }
    })
  )

export const useSelectedSystemJobFilters = () =>
  useSystemJobsFiltersStore(useShallow((state) => state.selectedFilters))

export const useIsSystemFiltersNotEmpty = () => {
  const selectedFilters = useSystemJobsFiltersStore(
    (state) => state.selectedFilters
  )

  return Object.values(selectedFilters).some((filter) => filter.size > 0)
}

export const useShouldDisableFilterButton = () => {
  const possibleFilters = useSystemJobsFiltersStore(
    (state) => state.possibleFilters
  )

  return Object.values(possibleFilters).every((filter) => filter.size === 0)
}

export const useSystemJobsTimeRange = () =>
  useSystemJobsFiltersStore((state) => state.timeRange)

export const useSystemJobsFiltersActions = () =>
  useSystemJobsFiltersStore((state) => state.actions)
