/* eslint-disable import/no-extraneous-dependencies */
import { EC2, EC2State } from 'blues-corejs/dist'
import ArrowRunningIcon from '@inline-img/general/arrow-running-icon'
import { StoppedIcon } from '@inline-img/general/stopped-icon'

export function computeInstanceState(instance: EC2) {
  if (instance.state === EC2State.RUNNING) {
    return {
      label: 'Running',
      Icon: <ArrowRunningIcon />,
    }
  }

  if (instance.state === EC2State.STOPPED) {
    return {
      label: 'Stopped',
      Icon: <StoppedIcon />,
    }
  }

  if (instance.state === EC2State.TERMINATED) {
    return {
      label: 'Terminated',
      Icon: null,
    }
  }

  return {
    label: 'Unknown',
    Icon: null,
  }
}
