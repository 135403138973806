import { useCallback } from 'react'

import { ScheduleTenantJobUseCase } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledTenantJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { ScheduledTenantJobsClient } from '@features/scheduled-jobs-monitoring/clients'
import { createLogger } from '@features/scheduled-jobs-monitoring/infrastructure/logging'
import ToastHelper from '@lib/helpers/toast.helper'

const scheduleTenantJobUseCase = new ScheduleTenantJobUseCase(
  new ScheduledTenantJobsRepository(createLogger()),
  new ScheduledTenantJobsClient(),
  ToastHelper
)

export const useScheduleTenantJob = () => {
  const scheduleTenantJob = useCallback(async (jobId: string) => {
    if (!jobId) {
      return
    }

    try {
      await scheduleTenantJobUseCase.execute(jobId)
    } catch (error) {
      console.error('Failed to execute scheduled tenant job:', error)
    }
  }, [])

  return {
    scheduleTenantJob,
  }
}
