/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Typography from '@mui/material/Typography'
import LangHelper from '@lib/helpers/lang.helper'
import Stack from '@mui/material/Stack'

interface Props {
  region: string
}

export function CloudConnectorRegionFilterLabel({ region }: Props) {
  return (
    <Stack>
      <Typography
        fontSize="14px"
        variant="caption"
        fontWeight="500"
        lineHeight="20px"
      >
        {LangHelper.getAwsRegionSingleTranslation(region)}
      </Typography>
      <Typography variant="caption" fontSize="11px">
        {region}
      </Typography>
    </Stack>
  )
}
