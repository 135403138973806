import React from 'react'

function ArrowForwardIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.475 14.6C7.325 14.45 7.25 14.2707 7.25 14.062C7.25 13.854 7.31667 13.675 7.45 13.525L12.75 8.25H1.125C0.925 8.25 0.75 8.179 0.6 8.037C0.45 7.89566 0.375 7.71666 0.375 7.5C0.375 7.28333 0.45 7.104 0.6 6.962C0.75 6.82066 0.925 6.75 1.125 6.75H12.75L7.45 1.475C7.31667 1.325 7.25 1.146 7.25 0.937997C7.25 0.729331 7.325 0.549997 7.475 0.399997C7.625 0.266664 7.8 0.199997 8 0.199997C8.2 0.199997 8.375 0.266664 8.525 0.399997L14.975 6.875C15.075 6.95833 15.146 7.054 15.188 7.162C15.2293 7.27066 15.25 7.38333 15.25 7.5C15.25 7.61666 15.2293 7.729 15.188 7.837C15.146 7.94566 15.075 8.04166 14.975 8.125L8.525 14.6C8.375 14.7333 8.2 14.8 8 14.8C7.8 14.8 7.625 14.7333 7.475 14.6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowForwardIcon
