/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'
import {
  BackupsPromiseClient,
  LastCleanBackupRequest,
  ListBackupsForAssetRequest,
  Pagination as PaginationRequest,
} from '../grpc'
import { BackupForAsset, ListBackupsForAssetFilters } from '../models/backups'
import { ClientConfig } from '../models/client'
import {
  AWSBRecoveryPointTransformer,
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
  OvaBackupTransformer,
} from '../transformers/recovery'
import { GrpcClient } from './grpc'

export type ListBackupsForAssetItem = Array<
  AWSBRecoveryPoint | ElastioRecoveryPoint | EBSSnapshot | OvaBackup
>

export class BackupsClient extends GrpcClient<BackupsPromiseClient> {
  #backupsClient: BackupsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#backupsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'BackupsClient'
  }

  protected initClient(hostName = ''): BackupsPromiseClient {
    return new BackupsPromiseClient(hostName, null, null)
  }

  async lastCleanBackupForAsset(
    assetId: string
  ): Promise<Partial<BackupForAsset>> {
    const request = new LastCleanBackupRequest()

    request.setAssetId(assetId)

    const response = (
      await this.callGrpcService(
        () =>
          this.#backupsClient.getLastCleanBackupForAsset(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'BackupsPromiseClient/getLastCleanBackupForAsset',
        }
      )
    ).toObject()

    return {
      awsbRps:
        response.awsbRps &&
        new AWSBRecoveryPointTransformer(response.awsbRps).transform(),
      ebsSnapshots:
        response.ebsSnapshots &&
        new EbsSnapshotTransformer(response.ebsSnapshots).transform(),
      elastioRps:
        response.elastioRps &&
        new ElastioRecoveryPointTransformer(response.elastioRps).transform(),
      ovaBackup:
        response.ovaBackups &&
        new OvaBackupTransformer(response.ovaBackups).transform(),
    }
  }

  async listBackupsForAsset(filters: ListBackupsForAssetFilters) {
    const request = new ListBackupsForAssetRequest()
    const initial = new ListBackupsForAssetRequest.Initial()
    const filter = new ListBackupsForAssetRequest.Initial.Filter()

    filter.setAssetIdList(filters.assetIdList)
    initial.setPageSize(filters.pageSize ?? 100)
    initial.setFilter(filter)
    request.setInitial(initial)

    if (filters?.pageToken) {
      request.setPagination(
        new PaginationRequest().setPageToken(filters.pageToken)
      )
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#backupsClient.listBackupsForAsset(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'BackupsPromiseClient/listBackupsForAsset',
        }
      )
    ).toObject()

    return {
      awsbRpsList: response.awsbRpsList.map((rp) =>
        new AWSBRecoveryPointTransformer(rp).transform()
      ),
      ebsSnapshotsList: response.ebsSnapshotsList.map((snapshot) =>
        new EbsSnapshotTransformer(snapshot).transform()
      ),
      elastioRpsList: response.elastioRpsList.map((rp) =>
        new ElastioRecoveryPointTransformer(rp).transform()
      ),
      ovaBackupList: response.ovaBackupsList.map((backup) =>
        new OvaBackupTransformer(backup).transform()
      ),
      pagination: {
        pageToken: response.pagination?.nextPageToken,
        prevPageToken: response.pagination?.prevPageToken,
      },
    }
  }
}
