import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownBtnIcon from '@inline-img/general/arrow-drop-down-btn-icon'

interface Props {
  onAddPolicySubmit: () => void
  onAddPolicyExecute: () => void
  addPolicyDisabled: boolean
}

export function AddPolicyMenu({
  onAddPolicySubmit,
  onAddPolicyExecute,
  addPolicyDisabled,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen)

  useEffect(() => {
    if (prevOpen.current === true && !isOpen) {
      anchorRef.current?.focus()
    }

    prevOpen.current = isOpen
  }, [isOpen])

  const handleToggle = useCallback(() => {
    setIsOpen((previousState) => !previousState)
  }, [])

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setIsOpen(false)
  }

  const handleAddPolicySubmit = () => {
    setIsOpen(false)
    onAddPolicySubmit()
  }

  return (
    <Stack direction="row" className="buttonDropdown">
      <Button
        className="mainBtn"
        id="mainsave"
        disabled={addPolicyDisabled}
        onClick={onAddPolicyExecute}
        variant="contained"
        color="primary"
      >
        Save & Run
      </Button>
      <Button
        type="button"
        color="primary"
        variant="contained"
        className="dropdownBtn"
        disabled={addPolicyDisabled}
        ref={anchorRef}
        id="secondary-save"
        aria-controls={isOpen ? 'save-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ArrowDropDownBtnIcon />
      </Button>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={isOpen}
                  id="save-menu"
                  aria-labelledby="secondary-save"
                >
                  <MenuItem onClick={handleAddPolicySubmit}>Save</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}
