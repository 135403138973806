/* eslint-disable import/no-extraneous-dependencies */
import { GenericHostInventoryOutput } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'

/**
 * Sorts to last scan first, if no last scan, sort by hostname
 */
export function initialPrioritySortForGenericHostTable(
  hosts: Array<GenericHostInventoryOutput>
) {
  return hosts.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    return a.genericHostName.localeCompare(b.genericHostName)
  })
}
