/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react'
import type { Column } from '@features/DashboardV4/common'
import { ReusableTable } from '@features/DashboardV4/common'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { renderVulnerabilityName } from './render-vulnerability-name'
import {
  CustomizedTableCell,
  CustomizedTableContainer,
  CustomizedTableRow,
} from './styles'
import { TopConfigurationSkeletonLoader } from '@features/DashboardV4/ConfigurationVulnerabilities/components'
import {
  AwsSnapshotVulnerability,
  AwsVolumeVulnerability,
} from '@lib/clients/aws-statistics/types'
import {
  prepareVulnerabilityDataForTable,
  renderStatusRisk,
  VulnerabilityTableDataTypes,
} from '@features/DashboardV4/ConfigurationVulnerabilities/utils'

interface Props {
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
  isLoading: boolean
}

function TopConfigurationVulnerabilities({
  volumeVulnerabilitiesList,
  snapshotVulnerabilitiesList,
  isLoading,
}: Props) {
  const tableData = prepareVulnerabilityDataForTable({
    volumeVulnerabilitiesList,
    snapshotVulnerabilitiesList,
  })

  const router = useNavigation()

  function onRowClick(
    row: AwsSnapshotVulnerability | AwsVolumeVulnerability
  ): void {
    const { drillDownLink } = renderVulnerabilityName(row.kind)

    const hasAssets = row.total > 0

    if (!hasAssets) {
      return
    }

    router.push(drillDownLink)
  }

  const columns: Array<Column<VulnerabilityTableDataTypes>> = [
    {
      name: 'Misconfiguration',
      accessor: 'kind',
      render: (item) => {
        const { name } = renderVulnerabilityName(item.kind)

        return name
      },
    },
    {
      name: 'Total',
      accessor: 'total',
      width: 80,
      align: 'right',
    },
    {
      name: 'Risk',
      accessor: 'riskLevel',
      width: 80,
      render: (item) => renderStatusRisk(item.riskLevel),
    },
  ]

  if (isLoading) {
    return <TopConfigurationSkeletonLoader />
  }

  // TODO: fix types
  return (
    <ReusableTable
      data={tableData}
      columns={columns as Array<Column<unknown>>}
      components={{
        TableContainer: CustomizedTableContainer,
        TableCell: CustomizedTableCell,
        TableRow: CustomizedTableRow,
      }}
      onRowClick={onRowClick as (row: unknown) => void}
    />
  )
}

export default memo(TopConfigurationVulnerabilities)
