import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  requestListAwsSecurityGroups,
  requestPossibleVpcListByRegionAndAccount,
} from '@store/actions/rex.action'

function useFetchNetworkResourcesOnInstanceChange({
  accountId,
  region,
}: {
  accountId?: string
  region?: string
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (accountId && region) {
      dispatch(requestPossibleVpcListByRegionAndAccount(accountId, region))
      dispatch(requestListAwsSecurityGroups(accountId, region))
    }
  }, [accountId, region])
}

export { useFetchNetworkResourcesOnInstanceChange }
