import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  ResiliencePercentage,
  ResilienceWeightData,
} from 'ui-v2/src/views/dashboard/dashboard/radar-chart-card'
import ScoreField from './score-field'

type ResilienceScoreDrawerProps = {
  openScoreCalculationDrawer: boolean
  closeScoreCalculationDrawer: () => void
  setResilienceScore: Dispatch<SetStateAction<number>>
  resilienceWeight: ResilienceWeightData
  setResilienceWeight: Dispatch<SetStateAction<ResilienceWeightData>>
  resiliencePercentage: ResiliencePercentage | undefined
}

const calculateTotalScore = (
  resilienceWeightData: ResilienceWeightData,
  resiliencePercentageData: ResiliencePercentage | undefined
): number => {
  if (!resiliencePercentageData) {
    const score =
      (resilienceWeightData.assetCoverageWeight +
        resilienceWeightData.encryptionIntegrityWeight +
        resilienceWeightData.ransomwareSafetyWeight +
        resilienceWeightData.recoveryAssuranceWeight +
        resilienceWeightData.storageHealthWeight) /
      5
    return score
  }

  const score =
    (resiliencePercentageData.assetCoveragePercentage *
      (resilienceWeightData.assetCoverageWeight / 100) +
      resiliencePercentageData.encryptionIntegrityPercentage *
        (resilienceWeightData.encryptionIntegrityWeight / 100) +
      resiliencePercentageData.ransomwareSafetyPercentage *
        (resilienceWeightData.ransomwareSafetyWeight / 100) +
      resiliencePercentageData.recoveryAssurancePercentage *
        (resilienceWeightData.recoveryAssuranceWeight / 100) +
      resiliencePercentageData.storageHealthPercentage *
        (resilienceWeightData.storageHealthWeight / 100)) /
    5

  return score
}

const ResilienceScoreDrawer: React.FC<ResilienceScoreDrawerProps> = ({
  openScoreCalculationDrawer,
  closeScoreCalculationDrawer,
  setResilienceScore,
  resilienceWeight,
  setResilienceWeight,
  resiliencePercentage,
}) => {
  const { control, handleSubmit, reset, watch } = useForm<ResilienceWeightData>(
    {
      defaultValues: resilienceWeight,
    }
  )

  const formValues = watch()
  const totalScore = calculateTotalScore(formValues, resiliencePercentage)

  const onSubmit = (data: ResilienceWeightData) => {
    localStorage.setItem('resilienceData', JSON.stringify(data))
    setResilienceWeight(data)
    setResilienceScore(totalScore)
    closeScoreCalculationDrawer()
  }

  const handleClear = () => {
    reset()
  }

  const [isDrawerReady, setIsDrawerReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsDrawerReady(true)
    }, 0)
  }, [])

  return (
    <Drawer
      anchor="right"
      open={openScoreCalculationDrawer}
      onClose={() => {
        reset()
        closeScoreCalculationDrawer()
      }}
      sx={{ backdropFilter: 'blur(2px)' }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '360px',
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <List
          sx={(theme) => ({
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.background.default,
            backgroundColor: theme.palette.background.hover,
            paddingX: 0,
          })}
        >
          <ListItem
            sx={{ paddingX: 2.5 }}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  reset()
                  closeScoreCalculationDrawer()
                }}
              >
                <Close />
              </IconButton>
            }
          >
            <Typography fontWeight={700} fontSize={16}>
              360 Resilience Score Calculation
            </Typography>
          </ListItem>
        </List>
        <Stack
          spacing={2}
          sx={{
            paddingX: 2.5,
            paddingY: 2,
            minHeight: 'calc(100vh - 120px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(128, 128, 128, 0.5)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(128, 128, 128, 0.7)',
            },
          }}
        >
          <Box>
            <Typography variant="body2" fontWeight={400}>
              The Elastio 360 Resilience dashboard represents five critical
              pillars of cyber resilience, each measured and reported with a
              weighted score.
            </Typography>
            {/* TODO:  Add this later */}
            {/* <Link
              to={'/'}
              style={{
                color: 'rgba(50, 182, 243, 1)',
                textDecoration: 'none',
                fontWeight: 600,
              }}
            >
              View Details.
            </Link> */}
          </Box>
          <Box
            sx={{
              backgroundColor: '#1AA8EA',
              borderRadius: '8px',
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            columnGap={2}
            padding={1}
          >
            <Typography
              maxWidth={170}
              lineHeight={'20px'}
              fontSize={16}
              fontWeight={700}
              color="#fff"
            >
              Your Current Score
            </Typography>
            <Typography fontSize={32} fontWeight={700} color="#fff">
              {Math.round(totalScore)}%
            </Typography>
          </Box>
          <Typography>Custom Total Resilience Score Weighting</Typography>
          {isDrawerReady && (
            <Stack spacing={1.5} marginBottom={2}>
              <ScoreField
                name="recoveryAssuranceWeight"
                label="Recovery Assurance"
                control={control}
              />
              <ScoreField
                name="ransomwareSafetyWeight"
                label="Ransomware Safety"
                control={control}
              />
              <ScoreField
                name="assetCoverageWeight"
                label="Asset Coverage"
                control={control}
              />
              <ScoreField
                name="encryptionIntegrityWeight"
                label="Encryption Integrity"
                control={control}
              />
              <ScoreField
                name="storageHealthWeight"
                label="Storage Health"
                control={control}
              />
            </Stack>
          )}
        </Stack>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          columnGap={1}
          sx={(theme) => ({
            paddingX: 2.5,
            paddingY: 2,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(21, 38, 53, 1)'
                : 'rgba(211, 216, 222, 0.26)',
          })}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={handleClear}
            sx={{
              textTransform: 'capitalize',
              fontSize: 13,
            }}
          >
            Clear Changes
          </Button>
          <Button
            type="submit"
            size="small"
            variant="contained"
            sx={{
              textTransform: 'capitalize',
              fontSize: 13,
              borderRadius: 10,
            }}
          >
            Save
          </Button>
        </Box>
      </form>
    </Drawer>
  )
}

export default ResilienceScoreDrawer
