import React, { memo, ReactNode } from 'react'
import { EngineCallbackPure } from '@lib/engine-types'
import clsx from 'clsx'

interface Props {
  disabled?: boolean
  text: ReactNode
  onClose: EngineCallbackPure
  variant?: 'default' | 'blue' | 'emailList' | 'red'
  readonly?: boolean
}

function CloseBlock({
  disabled,
  text,
  onClose,
  variant = 'default',
  readonly = false,
}: Props) {
  return (
    <div className={`wrap-1618135691040 ${variant}Variant`}>
      <div className={clsx('closeWrap', { readOnly: readonly })}>
        <span className="closeLabel">{text}</span>
        <button
          disabled={disabled}
          type="button"
          className="closeBtn jsClose"
          onClick={() => onClose()}
        >
          &times;
        </button>
      </div>
    </div>
  )
}

export default memo(CloseBlock)
