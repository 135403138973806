import { useMemo } from 'react'
import { useAwsFileScansQuery } from '../queries/aws-stats'
import { NormalizedStatCardData } from 'ui-v2/src/lib/models/aws-stats'
import { STAT_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import {
  ACTIVE_THREATS_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

const useAwsFileScansStatsData = () => {
  const { data: awsFileScansData, isLoading } = useAwsFileScansQuery({})

  const normalizedData: Array<NormalizedStatCardData> = useMemo(() => {
    if (!awsFileScansData) {
      return []
    }

    return [
      {
        slug: STAT_CARD_SLUG.FILE_SCANS,
        count: awsFileScansData.infectedFiles,
        link: `/${DASHBOARD_ROUTE_SEGMENTS.ACTIVE_THREATS}/${ACTIVE_THREATS_ROUTE_SEGMENTS.AWS}`,
      },
    ]
  }, [awsFileScansData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsFileScansStatsData
