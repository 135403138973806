/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/quotes */
import React, { useEffect } from 'react'
import { DateRangePickerWithCallAPI } from '@components-composite/date-range-picker-with-call-api'
import { Table } from '@components/table'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { KindAssetItem } from '@lib/clients/backups/types'
import { TimeRange } from '@lib/clients/types'
import SystemHelper from '@lib/helpers/system.helper'
import {
  AssetItem,
  AWSBRecoveryPoint,
  Disk,
  EBSSnapshot,
  EfsItem,
  ElastioRecoveryPoint,
  File,
  LocalVolume,
  S3Item,
  Stream,
} from 'blues-corejs/dist'
import { getAssetItemRecoveryPointColumnDefinitions } from './get-asset-item-rp-column-definition'
import { useListBackupsForAssetItemDataFetcher } from '@components-complex/dashboard-pages-v3/hooks/use-list-backups-for-asset-items-fetcher'

type Backup = ElastioRecoveryPoint | AWSBRecoveryPoint | EBSSnapshot

interface Props {
  assetItem: AssetItem
  onCompletionTimeClick: (backup: Backup) => void
}

function computeAssetItemKind(assetItem: AssetItem) {
  switch (assetItem.constructor) {
    case File:
      return KindAssetItem.KIND_FILE
    case Stream:
      return KindAssetItem.KIND_STREAM
    case LocalVolume:
      return KindAssetItem.KIND_VOLUME
    case EfsItem:
      return KindAssetItem.KIND_FS_ITEM
    case S3Item:
      return KindAssetItem.KIND_S3_ITEM
    case Disk:
      return KindAssetItem.KIND_DISK
    default:
      SystemHelper.sendSentryIfProd(
        `computeAssetItemKind: Unknown asset item type: ${assetItem.id}`
      )
      throw Error(
        `computeAssetItemKind: Unknown asset item type: ${assetItem.id}`
      )
  }
}

export function AssetItemRecoveryPointsTable({
  assetItem,
  onCompletionTimeClick,
}: Props) {
  const assetItemBackupsData = useListBackupsForAssetItemDataFetcher(
    assetItem
      ? {
          assetItems: [
            {
              id: assetItem.id,
              kind: computeAssetItemKind(assetItem),
            },
          ],
        }
      : { assetItems: [] }
  )

  const observable = useInfiniteScrollDataFetch({
    initialItems: [],
    fetchNextBatch: assetItemBackupsData.onFetchData,
  })

  const handleTimeRangeChange = (newTimeRange: TimeRange) => {
    assetItemBackupsData.fetchInitial({ timeRange: newTimeRange })
  }

  useEffect(() => {
    assetItemBackupsData.fetchInitial()
  }, [])

  return (
    <>
      <DateRangePickerWithCallAPI onDateRangeChange={handleTimeRangeChange} />
      <Table
        data={[
          ...assetItemBackupsData.data.elastioRpsList,
          ...assetItemBackupsData.data.awsbRpsList,
          ...assetItemBackupsData.data.ebsSnapshotsList,
        ]}
        columns={getAssetItemRecoveryPointColumnDefinitions({
          onCompletionTimeClick,
          assetItem,
        })}
        generalProps={{
          noDataMessage: 'No Recovery points found',
          isLoading: assetItemBackupsData.isLoadingData(),
        }}
        advancedProps={{
          observableState: observable,
        }}
      />
    </>
  )
}
