import { DeleteReportScheduleRequest } from 'ui-v2/src/lib/grpc'

export interface DeleteReportScheduleCriteria {
  id: string
}

export function buildDeleteReportScheduleRequest({
  id,
}: DeleteReportScheduleCriteria): DeleteReportScheduleRequest {
  return new DeleteReportScheduleRequest().setId(id)
}
