import { TemporaryTabMenu, TabLabelOva } from './components'
import {
  TabViewInterface,
  ViewKind,
} from '@features/DashboardV4/management-view/types'
import React, { FC } from 'react'
import { SvgIconTypeMap } from '@mui/material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { OverridableComponent } from '@mui/material/OverridableComponent'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useGetTemporaryTab } from '@features/DashboardV4/management-view/tabs-store'

type Props = {
  tab: TabViewInterface
  selected: boolean
  editable: boolean
  onViewUpdate: (id: string) => void
  onViewDelete: (id: string) => void
  kind: ViewKind
  TitleIcon?: OverridableComponent<SvgIconTypeMap>
}

const labelsView = new Map<boolean, FC<Props>>([
  [false, TemporaryTabMenu],
  [true, TabLabelOva],
])

const getAdditionalProps = (isTemporaryTab: boolean) => {
  return isTemporaryTab ? { TitleIcon: MoreVertIcon } : {}
}

function ActionsRenderer({ kind, tab, ...restProps }: Props): JSX.Element {
  const temporaryTab = useGetTemporaryTab()

  const isTemporaryTab = tab.id === temporaryTab?.id

  const Component = labelsView.get(isTemporaryTab)

  if (!Component) {
    return (
      <Box mt={2}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          The view kind{' '}
          <Typography component="span" variant="subtitle2" fontWeight="bold">
            {kind}
          </Typography>{' '}
          is invalid. Please try a different view.
        </Alert>
      </Box>
    )
  }

  return (
    <Component
      kind={kind}
      tab={tab}
      {...restProps}
      {...getAdditionalProps(isTemporaryTab)}
    />
  )
}

export default ActionsRenderer
