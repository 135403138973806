import { BaseRoutes } from '../base'

export enum RouteFragments {
  DYNAMIC_ID = ':id',
}

export class SourcesRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_SOURCES
  }

  public static buildPathWithEncodedId(id: string | number): string {
    return `${this.root}/${this.base64Encode(String(id))}`
  }

  public static get withIdPlaceholder(): string {
    return `${this.root}/${RouteFragments.DYNAMIC_ID}`
  }

  public static get sourceVaultWithIdPlaceholder(): string {
    return `${this.#ROUTE_SOURCE_VAULT}/${RouteFragments.DYNAMIC_ID}`
  }

  public static sourceVaultWithEncodedId(id: string | number): string {
    return `${this.#ROUTE_SOURCE_VAULT}/${this.base64Encode(String(id))}`
  }

  static #ROUTE_SOURCES = '/sources'

  static #ROUTE_SOURCE_VAULT = 'source-vaults'
}
