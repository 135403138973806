/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { CellProps } from './types'
import Typography from '@mui/material/Typography'

function SchedulesTableCellWithText<TData, TValue>({
  cell,
}: CellProps<TData, TValue>) {
  return (
    <Typography variant="caption" fontSize="14px">
      {cell.getValue() as string}
    </Typography>
  )
}

export default SchedulesTableCellWithText
