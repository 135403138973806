/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { RowHeight, createDisplayColumn } from '@components/table'
import { EngineCallback } from '@lib/engine-types'
import { AssetItem } from 'blues-corejs/dist/'
import { AssetItemIconCell } from './asset-item-icon-cell'
import { AssetItemNameCell } from './asset-item-name-cell'

export function getAssetItemsTableColumnDefinitions({
  onItemClick,
}: {
  onItemClick: EngineCallback<AssetItem>
}) {
  return [
    createDisplayColumn<AssetItem>({
      id: 'asset-item-type',
      enableSorting: false,
      meta: {
        width: '50',
        height: RowHeight.SMALL,
      },
      cell: ({ row }) => {
        const assetItem = row.original as AssetItem
        return <AssetItemIconCell assetItem={assetItem} />
      },
    }),
    createDisplayColumn<AssetItem>({
      id: 'asset-item-name',
      cell: ({ row }) => {
        const assetItem = row.original as AssetItem

        function handleAssetItemClick() {
          onItemClick(assetItem)
        }

        return (
          <AssetItemNameCell
            assetItem={assetItem}
            onAssetItemClick={handleAssetItemClick}
          />
        )
      },
    }),
  ]
}
