export const COLUMN_SIZES = {
  NAME: 80,
  DESCRIPTION: 60,
  REPORT_TYPE: 60,
  FREQUENCY: 60,
  LAST_GENERATED: 60,
  NEXT_REPORT_DUE_ON: 60,
  SCHEDULES_ACTIONS: 40,
} as const

export const COLUMN_IDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  REPORT_TYPE: 'report-type',
  FREQUENCY: 'frequency',
  LAST_GENERATED: 'last-generated',
  NEXT_REPORT_DUE_ON: 'next-report-due-on',
  SCHEDULES_ACTIONS: 'schedules-actions',
} as const

export const COLUMN_HEADERS = {
  NAME: 'Name',
  DESCRIPTION: 'Description',
  REPORT_TYPE: 'Report Type',
  FREQUENCY: 'Frequency',
  LAST_GENERATED: 'Last Generated',
  NEXT_REPORT_DUE_ON: 'Next report due on',
} as const

export const COLUMN_ACCESSOR = {
  NAME: 'name',
  DESCRIPTION: 'description',
  REPORT_TYPE: 'reportType',
  FREQUENCY: 'frequency',
  REPORT_KIND_WITH_PARAMS: 'reportKindWithParams',
  NEXT_REPORT_GENERATION_AT: 'nextReportGenerationAt',
  LAST_REPORT_GENERATION_COMPLETED_AT: 'lastReportGenerationCompletedAt',
} as const
