import Box from '@mui/material/Box'
import { ScaleGradient } from '@features/DashboardV4/widgets-with-scale/coverage-stats/scale-static/scale-gradient'
import { INITIAL_VALUE_FOR_CONSISTENT_RENDERING } from '../consts'
import { shouldBeHidePercentOfAssetsWithBackups } from '../utilities'

import styles from './scale-static.module.css'

function ScaleStatic({
  percentOfAssetsWithBackups,
  numberOfAssets,
  numberOfAssetsWithBackups,
}: {
  percentOfAssetsWithBackups: number
  numberOfAssetsWithBackups: number
  numberOfAssets: number
}) {
  return (
    <Box className={styles.wrapScale}>
      <Box className={styles.root}>
        <Box className={styles.scale}>
          <ScaleGradient percent={percentOfAssetsWithBackups} />
        </Box>
        <Box className={styles.values}>
          <Box className={styles.value}>{numberOfAssets}</Box>
          <Box className={styles.value}>
            {INITIAL_VALUE_FOR_CONSISTENT_RENDERING}
          </Box>
        </Box>
        <Box
          style={{ bottom: `${percentOfAssetsWithBackups}%` }}
          className={styles.wrapDivision}
        >
          <Box className={styles.divisionLabel}>
            <Box>{percentOfAssetsWithBackups}%</Box>
            <Box>Coverage</Box>
          </Box>
          <Box className={styles.divider}></Box>
          {!shouldBeHidePercentOfAssetsWithBackups(
            percentOfAssetsWithBackups
          ) && (
            <Box className={`${styles.dynamicValue} ${styles.value}`}>
              {numberOfAssetsWithBackups}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ScaleStatic
