import React, { ReactNode } from 'react'
import Typography from '@mui/material/Typography'

function Title({ children }: { children: ReactNode }) {
  return (
    <Typography variant="body1" marginY="8px" fontSize="14px" fontWeight="500">
      {children}
    </Typography>
  )
}

export default Title
