/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import { S3Bucket, EFS, GenericHost, AssetItem } from 'blues-corejs/dist'
import React from 'react'
import { computeAssetIcon } from './compute-asset-icon'

type Asset = S3Bucket | EFS | GenericHost

interface Props {
  asset: Asset | AssetItem
}

export function AssetItemCell({ asset }: Props) {
  return <Box maxWidth="50px">{computeAssetIcon(asset)}</Box>
}
