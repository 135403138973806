import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { ServersWithLatestCleanRpIcon } from '../icons'
import { useServersDrilldownLink } from '@components-complex/dashboard-pages-v3/ova-server-table'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from '@components-complex/dashboard-pages-v3/ova-server-table/toolbar/consts'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'

interface Props {
  isLoading: boolean
  serversWithLatestCleanRpRatio: number
}

function ServersWithLatestCleanRpSkeleton() {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      width="100%"
      data-testid="servers-latest-clean-rp-skeleton"
    >
      <Skeleton>
        <ServersWithLatestCleanRpIcon />
      </Skeleton>
      <Skeleton>
        <Typography fontSize="22px" fontWeight="600" minWidth="30px">
          22%
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontSize="16px">
          Servers with a clean latest recovery point
        </Typography>
      </Skeleton>
    </Box>
  )
}

function formatAsPercentage(ratio: number): string {
  const clampedRatio = Math.max(0, Math.min(ratio, 1))
  const percentage = Math.ceil(clampedRatio * 100)
  return `${percentage}%`
}

function computeServersWithLatestCleanRpIconColor(
  serversWithLatestCleanRpRatio: number
) {
  return serversWithLatestCleanRpRatio > 0 ? 'var(--green500)' : 'var(--red400)'
}

export function ServersWithLatestCleanRp({
  isLoading,
  serversWithLatestCleanRpRatio,
}: Props) {
  const { handleRedirectWithDrilldown } = useServersDrilldownLink({
    queryKey: STATUS_FILTER_DRILLDOWN_QUERY_KEY,
    queryValue: OvaServerStatus.CLEAN.toString(),
  })

  if (isLoading) {
    return <ServersWithLatestCleanRpSkeleton />
  }

  const iconColor = computeServersWithLatestCleanRpIconColor(
    serversWithLatestCleanRpRatio
  )

  const formattedRatio = formatAsPercentage(serversWithLatestCleanRpRatio)

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      sx={{ cursor: 'pointer' }}
      onClick={handleRedirectWithDrilldown}
    >
      <ServersWithLatestCleanRpIcon fill={iconColor} />
      <Typography fontSize="22px" fontWeight="600" minWidth="30px">
        {formattedRatio}
      </Typography>
      <Typography fontSize="16px">
        Servers with a clean latest recovery point
      </Typography>
    </Box>
  )
}
