/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { EngineCallback } from '@lib/engine-types'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Table } from '@components/table'
import { getScansTableColumnDefinitions } from './columns-definition'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { DateRangePickerWithCallAPI } from '@components-composite/date-range-picker-with-call-api'
import { ScansRun } from 'blues-corejs/dist/models'
import { TimeRange } from '@lib/clients/types'
import { ListScansForAssetFiltersParams } from '@lib/clients/scans/list-scans-for-asset/types'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

interface Props {
  scans: Array<ScansRun>
  onDateRangeSelect: (timeRange: TimeRange) => void
  onScansClick: EngineCallback<ScansRun>
  onScrollEnd: () => Promise<void>
  onFetchInitial: (filter?: ListScansForAssetFiltersParams) => Promise<void>
  fetchOnRefreshInterval: (
    filter?: ListScansForAssetFiltersParams
  ) => Promise<void>
  isLoading: boolean
}

const StyledBox = styled(Box)`
  display: inline-flex;
  margin-bottom: 16px;
`

function ScansTable({
  scans,
  onDateRangeSelect,
  onScansClick,
  onScrollEnd,
  onFetchInitial,
  fetchOnRefreshInterval,
  isLoading = false,
}: Props) {
  const observable = useInfiniteScrollDataFetch({
    initialItems: scans,
    fetchNextBatch: onScrollEnd,
  })

  useEffect(() => {
    const fetchInterval = setInterval(
      fetchOnRefreshInterval,
      INTERVAL_REQUEST_TIMEOUT
    )
    onFetchInitial()
    return () => {
      clearInterval(fetchInterval)
    }
  }, [])

  return (
    <Box>
      <StyledBox>
        <DateRangePickerWithCallAPI onDateRangeChange={onDateRangeSelect} />
      </StyledBox>

      <Table
        data={scans}
        columns={getScansTableColumnDefinitions({
          onScansClick,
        })}
        generalProps={{
          isLoading,
          noDataMessage: 'No Scans found',
        }}
        advancedProps={{
          observableState: observable,
        }}
      />
    </Box>
  )
}

export default ScansTable
