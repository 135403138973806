import React from 'react'
import { TabVariant } from './management-view'
import ManagementViews from './management-view/management-views'
import { Overview, Security, Recovery } from './views'
import { Container } from './styles'
import { useCurrentView } from './common'
import { OvaDashboard } from '@features/ova-dashboard'

const views: Record<TabVariant, JSX.Element> = {
  overview: <Overview />,
  security: <Security />,
  recovery: <Recovery />,
  ova: <OvaDashboard />,
}

function DashboardV4() {
  const view = useCurrentView()

  return (
    <Container data-testid="dashboard-v4">
      <ManagementViews />
      {views[view.variant]}
    </Container>
  )
}

export default DashboardV4
