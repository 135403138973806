import Button from 'ui-v2/src/components/ui/inputs/button'
import { CopyButtonVariant } from 'ui-v2/src/lib/models/settings/sources/sources'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import AppleIcon from '@mui/icons-material/Apple'
import LinuxIcon from 'ui-v2/src/assets/icons/linux-icon'
import { useToast } from 'ui-v2/src/hooks/toast'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'

const CopyCommandButton = ({
  commandText,
  variant,
  disabled,
  toastText,
}: CopyCommandButtonProps) => {
  const toast = useToast()

  const getButtonIcon = () => {
    switch (variant) {
      case CopyButtonVariant.WINDOWS:
        return <MicrosoftIcon />
      case CopyButtonVariant.MACOS:
        return <AppleIcon />
      case CopyButtonVariant.LINUX:
        return <LinuxIcon />
      case CopyButtonVariant.DEFAULT:
        return <></>
      default:
        return <></>
    }
  }
  const getButtonText = () => {
    switch (variant) {
      case CopyButtonVariant.WINDOWS:
        return 'Microsoft'
      case CopyButtonVariant.MACOS:
        return 'Mac OS'
      case CopyButtonVariant.LINUX:
        return 'Linux'
      case CopyButtonVariant.DEFAULT:
        return <></>
      default:
        return <></>
    }
  }

  return (
    <Button
      disableElevation
      endIcon={getButtonIcon()}
      variant="contained"
      size="medium"
      sx={{
        textTransform: 'capitalize',
      }}
      disabled={disabled}
      onClick={() => {
        copyToClipboard(commandText)
        toast.success(toastText)
      }}
    >
      {getButtonText()}
    </Button>
  )
}
interface CopyCommandButtonProps {
  commandText: string
  variant: CopyButtonVariant
  disabled?: boolean
  toastText: string
}
export default CopyCommandButton
