/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnectorJobsClient } from '@lib/clients'
import { ObjHelper } from '@lib/helpers'
import { JobCloudConnector } from 'blues-corejs/dist'
import { useEffect, useRef } from 'react'

const INTERVAL_TIME = 10 * 1000

export interface JobsClient {
  getCloudConnectorJob(jobId: string): Promise<JobCloudConnector>
}

export interface UseTrackCloudConnectorJobsParams {
  onJobsChange: (jobs: Array<JobCloudConnector>) => void
  inProgressJobsList: Array<JobCloudConnector>
  client?: JobsClient
  intervalTime?: number
}

/**
 * This hook is used to track the cloud connector jobs
 * @returns changed jobs
 */
export function useTrackCloudConnectorJobs({
  onJobsChange,
  inProgressJobsList,
  client = new CloudConnectorJobsClient(),
  intervalTime = INTERVAL_TIME,
}: UseTrackCloudConnectorJobsParams) {
  const previousJobList = useRef<null | Array<JobCloudConnector>>(null)

  useEffect(() => {
    const isJobListChanged = ObjHelper.isNotEqual(
      previousJobList.current,
      inProgressJobsList
    )

    if (!isJobListChanged) {
      return
    }

    const interval = setInterval(async () => {
      previousJobList.current = inProgressJobsList

      const jobs = await Promise.all(
        inProgressJobsList.map((job) => client.getCloudConnectorJob(job.id))
      )

      for (const job of jobs) {
        const jobInProgress = inProgressJobsList.find(({ id }) => id === job.id)

        if (!jobInProgress) {
          continue
        }

        if (
          job.status !== jobInProgress.status ||
          job.progress !== jobInProgress.progress
        ) {
          onJobsChange(jobs)
          return
        }
      }
    }, intervalTime)

    return () => {
      previousJobList.current = null
      clearInterval(interval)
    }
  }, [inProgressJobsList])
}
