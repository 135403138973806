import { SvgIcon, SvgIconProps } from '@mui/material'

const SettingsActive = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 0,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M12.002 15.15C11.1665 15.15 10.3653 14.8181 9.77459 14.2274C9.18385 13.6366 8.85197 12.8354 8.85197 12C8.85197 11.1646 9.18385 10.3634 9.77459 9.77261C10.3653 9.18187 11.1665 8.85 12.002 8.85C12.8374 8.85 13.6386 9.18187 14.2294 9.77261C14.8201 10.3634 15.152 11.1646 15.152 12C15.152 12.8354 14.8201 13.6366 14.2294 14.2274C13.6386 14.8181 12.8374 15.15 12.002 15.15ZM18.689 12.873C18.725 12.585 18.752 12.297 18.752 12C18.752 11.703 18.725 11.406 18.689 11.1L20.588 9.633C20.759 9.498 20.804 9.255 20.696 9.057L18.896 5.943C18.788 5.745 18.545 5.664 18.347 5.745L16.106 6.645C15.638 6.294 15.152 5.988 14.585 5.763L14.252 3.378C14.216 3.162 14.027 3 13.802 3H10.202C9.97697 3 9.78797 3.162 9.75197 3.378L9.41897 5.763C8.85197 5.988 8.36597 6.294 7.89797 6.645L5.65697 5.745C5.45897 5.664 5.21597 5.745 5.10797 5.943L3.30797 9.057C3.19097 9.255 3.24497 9.498 3.41597 9.633L5.31497 11.1C5.27897 11.406 5.25197 11.703 5.25197 12C5.25197 12.297 5.27897 12.585 5.31497 12.873L3.41597 14.367C3.24497 14.502 3.19097 14.745 3.30797 14.943L5.10797 18.057C5.21597 18.255 5.45897 18.327 5.65697 18.255L7.89797 17.346C8.36597 17.706 8.85197 18.012 9.41897 18.237L9.75197 20.622C9.78797 20.838 9.97697 21 10.202 21H13.802C14.027 21 14.216 20.838 14.252 20.622L14.585 18.237C15.152 18.003 15.638 17.706 16.106 17.346L18.347 18.255C18.545 18.327 18.788 18.255 18.896 18.057L20.696 14.943C20.804 14.745 20.759 14.502 20.588 14.367L18.689 12.873Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default SettingsActive
