/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import {
  StyledBadge,
  StyledCard,
  StyledCardWrapper,
  StyledChip,
  StyledDownArrow,
} from './styles'
import { CopyToClipboardIcon } from '@features/common'
import clsx from 'clsx'
import { EbsIcon } from '@features/DashboardV4/common'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { EBS, ElastioRecoveryPoint, Threat } from 'blues-corejs/dist'
import { ListBackupsForAssetClient } from '@lib/clients'
import { PageHelper, StrHelper } from '@lib/helpers'
import pagePathConstant from '@lib/constants/page-path.constant'
import { ASSET_ID_QUERY_KEY } from '@lib/constants'
import { ReportSliderItemGenerator, ReportsBlock } from '../reports-block'
import { useNavigation } from '@lib/router/contexts/navigation-context'

const backupsClient = new ListBackupsForAssetClient()

function getRestoreLink(assetId: string, rpId: string) {
  return `${PageHelper.buildUrl(
    pagePathConstant.RESTORE_VOLUME,
    rpId
  )}?${ASSET_ID_QUERY_KEY}=${StrHelper.base64Encode(assetId)}`
}

function useGetLastCleanBackupByAssetId({
  volume,
  lastCleanBackupMap,
}: {
  volume: EBS
  lastCleanBackupMap: Map<string, ElastioRecoveryPoint>
}) {
  const [lastCleanBackup, setLastCleanBackup] = React.useState<
    ElastioRecoveryPoint | undefined
  >()

  React.useEffect(() => {
    async function getLastCleanBackup() {
      try {
        if (lastCleanBackupMap.has(volume.id)) {
          setLastCleanBackup(lastCleanBackupMap.get(volume.id))
          return
        }

        const response = await backupsClient.lastCleanBackupForAsset(volume.id)

        if (!response.elastioRps) {
          return
        }

        setLastCleanBackup(response.elastioRps)
        lastCleanBackupMap.set(volume.id, response.elastioRps)
      } catch (error) {
        console.error('Error while fetching last clean backup:', error)
      }
    }

    getLastCleanBackup()
  }, [])

  return lastCleanBackup
}

const SCROLL_VIEW_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'center',
}

interface Props {
  volume: EBS
  threats: Array<Threat>
  index: number
  selected: boolean
  onSlideChange: (index: number) => void
}

function computeLabelChip(threats: Array<Threat>) {
  if (threats.length > 0) {
    return {
      labelChip: 'Infected',
      labelVariant: 'el-error',
    } as const
  }

  return {
    labelChip: 'Clean',
    labelVariant: 'el-success',
  } as const
}

function SliderItem({
  volume,
  threats,
  index,
  selected,
  onSlideChange,
}: Props) {
  const lastCleanBackupMap = new Map<string, ElastioRecoveryPoint>()

  const router = useNavigation()

  const sliderRef = useRef<HTMLDivElement>(null)

  const lastCleanBackup = useGetLastCleanBackupByAssetId({
    volume,
    lastCleanBackupMap,
  })

  const volumeThreats: Array<Threat> = threats.filter(
    (threat) =>
      threat.source.asset?.assetId === volume.id ||
      threat.source.assetItem?.assetId === volume.id
  )

  const isAssetContainThreats = volumeThreats.length > 0

  const { labelVariant, labelChip } = computeLabelChip(volumeThreats)

  const handleSlideChange = () => onSlideChange(index)

  const handleRestoreToLastClean = () => {
    if (!lastCleanBackup) {
      return
    }

    router.push(getRestoreLink(volume.id, lastCleanBackup.id))
  }

  useEffect(() => {
    if (selected) {
      sliderRef.current?.scrollIntoView(SCROLL_VIEW_OPTIONS)
    }
  }, [selected])

  useEffect(() => {
    return () => {
      lastCleanBackupMap.clear()
    }
  }, [])

  const isShowRestoreToLastCleanButton =
    isAssetContainThreats && selected && lastCleanBackup

  const { reportBlocks } = new ReportSliderItemGenerator({
    volume,
    threats,
  })

  return (
    <StyledCardWrapper
      display="flex"
      data-testid="slider-item"
      flexDirection="column"
      ref={sliderRef}
    >
      <StyledDownArrow />

      <StyledCard selected={selected} onClick={handleSlideChange}>
        <StyledBadge
          badgeContent={volumeThreats.length}
          variant="el-error"
          children={<EbsIcon />}
        />

        <CardContent
          className={clsx('card-content', { infected: isAssetContainThreats })}
        >
          <StyledChip label={labelChip} variant={labelVariant} size="small" />

          <Link variant="el-link">
            <LongTextTooltip text={volume.name} maxLength={20} />
          </Link>

          <Typography fontSize="11px">
            {volume.awsId}
            <CopyToClipboardIcon text={volume.awsId} />
          </Typography>

          {reportBlocks.map((report, idx) => (
            <ReportsBlock
              data-testid={`reports-block-${idx}`}
              key={idx}
              {...report}
            />
          ))}

          {isShowRestoreToLastCleanButton && (
            <Button variant="contained" onClick={handleRestoreToLastClean}>
              Restore to last known clean recovery point
            </Button>
          )}
        </CardContent>
      </StyledCard>
    </StyledCardWrapper>
  )
}

export default memo(SliderItem)
