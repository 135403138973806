/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import CircleStatusIcon from '@inline-img/general/circle-status-icon'
import { STATUS_TO_STYLE_VARIANT_MAPPING } from './consts'
import { JobStatusIndicator } from './job-status-indicator'

function mapTenantJobStatusToStyleVariant(status: TenantJobStatus) {
  return STATUS_TO_STYLE_VARIANT_MAPPING[status]
}

export function JobStatusCell({ status }: { status: TenantJobStatus }) {
  return (
    <JobStatusIndicator className={mapTenantJobStatusToStyleVariant(status)}>
      <CircleStatusIcon />
    </JobStatusIndicator>
  )
}
