import { EntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'
import { TimeGranularity } from '@lib/clients/entropy-detection/types'
import { TimeHelper } from '@lib/helpers'

export const getDateFormatBasedByRange = (
  timeRange: EntropyTrendsTimeSelection
): string => {
  return timeRange === EntropyTrendsTimeSelection.TODAY ? 'hh:mm a' : 'MM/dd'
}

export const buildTimeRangeForRequest = (
  selectedDate: EntropyTrendsTimeSelection,
  startAt?: number,
  endAt?: number
) => {
  {
    switch (selectedDate) {
      case EntropyTrendsTimeSelection.TODAY: {
        const endDate = TimeHelper.getDateWithTimezone(Date.now()).endOf('day')

        const startDate = endDate.clone().startOf('day')

        return {
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
          timeGranularity: TimeGranularity.TIME_GRANULARITY_HOUR,
        }
      }
      case EntropyTrendsTimeSelection.LAST_7_DAYS: {
        const endDate = TimeHelper.getDateWithTimezone(Date.now()).endOf('day')

        const startDate = endDate.clone().subtract(6, 'day').startOf('day')

        return {
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
          timeGranularity: TimeGranularity.TIME_GRANULARITY_DAY,
        }
      }
      case EntropyTrendsTimeSelection.LAST_30_DAYS: {
        const endDate = TimeHelper.getDateWithTimezone(Date.now()).endOf('day')

        const startDate = endDate.clone().subtract(30, 'day').startOf('day')

        return {
          startDate: startDate.valueOf(),
          endDate: endDate.valueOf(),
          timeGranularity: TimeGranularity.TIME_GRANULARITY_DAY,
        }
      }
      case EntropyTrendsTimeSelection.CUSTOM_DATE: {
        if (!startAt || !endAt) {
          throw new Error(
            'Start and end date are required for custom date range'
          )
        }

        return {
          startDate: startAt,
          endDate: endAt,
          timeGranularity: TimeGranularity.TIME_GRANULARITY_DAY,
        }
      }
    }
  }
}
