import { Nullable } from '@lib/engine-types'
import { WebhooksInvocation } from '@lib/interfaces/webhooks.interface'
import WebhookModel from '@lib/models/webhook.model'
import { Chart, ChartType, registerables } from 'chart.js'

let chartInstance: Chart | undefined = undefined

function drawWebhookEventsTimelineChart(
  data: {
    labels: Array<string>
    webhook: WebhookModel
    invocations: Array<WebhooksInvocation>
  },
  chartRef: Nullable<HTMLCanvasElement>
): Chart | undefined {
  if (chartRef && data?.webhook && data?.invocations?.length) {
    Chart.register(...registerables)
    const context = chartRef.getContext('2d')

    const gradient = context?.createLinearGradient(0, 0, 0, 250)
    if (gradient) {
      gradient.addColorStop(1, 'rgba(167, 225, 255, 0)')
      gradient.addColorStop(0, 'rgba(167, 225, 255, 1)')
    }

    const gradientRed = context?.createLinearGradient(0, 0, 0, 300)
    if (gradientRed) {
      gradientRed.addColorStop(1, 'rgba(255, 120, 120, 0)')
      gradientRed.addColorStop(0, 'rgba(255, 120, 120, 1)')
    }

    const chartType: ChartType = 'line'

    const all: Array<number> = []
    const failed: Array<number> = []

    data.invocations.forEach(
      ({ successfulInvocations, unsuccessfulInvocations }) => {
        all.push(successfulInvocations + unsuccessfulInvocations)
        failed.push(unsuccessfulInvocations)
      }
    )

    const tooltipTitle = (tooltipItems: Array<{ label: string }>) =>
      `${tooltipItems[0]?.label} (UTC)`

    const datasets = [
      {
        label: 'All',
        fill: 'start',
        borderColor: '#32B6F3',
        backgroundColor: gradient,
        stack: 'Stack 0',
        data: all,
        order: 1,
      },
      {
        label: 'Failed',
        fill: 'start',
        borderColor: '#FF7878',
        backgroundColor: gradientRed,
        stack: 'Stack 1',
        data: failed,
        order: 0,
      },
    ]

    const config = {
      type: chartType,
      data: {
        labels: data.labels,
        datasets,
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: tooltipTitle,
            },
          },
        },
        layout: {
          autoPadding: false,
          padding: 20,
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            stacked: true,
            ticks: {
              precision: 0,
            },
            grid: {
              display: false,
            },
          },
        },
      },
    }

    if (chartInstance) {
      chartInstance.destroy()
    }

    chartInstance = new Chart(context as CanvasRenderingContext2D, config)

    return chartInstance
  }
}

export default drawWebhookEventsTimelineChart
