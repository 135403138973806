import React, {
  CSSProperties,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Popover from '@mui/material/Popover'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import clsx from 'clsx'
import CalendarIcon from '@inline-img/inputs/calendar-icon'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import moment, { Moment } from 'moment-timezone'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import { TimeHelper } from '@lib/helpers'
import SettingsService from '@lib/services/high/settings.service'
import { EngineCallback } from '@lib/engine-types'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { DateRangeTuple } from '@lib/engine-types'

export const BASE_DATE_FORMAT = 'DD / MM / YYYY'

export interface DateRangeSelectProps {
  value: DateRangeTuple
  label?: string
  endLabel?: string
  onChange: EngineCallback<DateRangeTuple>
  inputFormat?: TimeFormatConstants
  minMaxRange?: DateRangeTuple
  startText?: string
  endText?: string
  hint?: string
  disabled?: boolean
  labelStyle?: CSSProperties
  className?: string

  [key: string]: any
}

export default function DateRangeSelect(
  props: PropsWithChildren<DateRangeSelectProps>
): JSX.Element {
  const {
    value,
    label = '',
    endLabel = '',
    onChange,
    minMaxRange = [0, moment().valueOf()],
    className = '',
    inputFormat = TimeFormatConstants.SHORT_DATE_FORMAT,
    startText = 'From',
    endText = 'To',
    hint = '',
    disabled,
    labelStyle = {},
    ...nativeProps
  } = props

  const timezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value

  const [startRaw, endRaw] = value

  const [calendarOpen, setCalendarOpen] = useState(false)
  const [tempStart, setTempStart] = useState<number | null>(null)
  const [tempEnd, setTempEnd] = useState<number | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [calendarViewDate, setCalendarViewDate] = useState<Date>(new Date())

  function toNumberOrNull(val: number | Moment | null | undefined) {
    if (val == null) {
      return null
    }
    if (moment.isMoment(val)) {
      return val.valueOf()
    }
    return val
  }

  function formatDate(ts: number | null): string {
    if (ts == null) {
      return ''
    }
    return moment(ts).tz(timezone).format(inputFormat)
  }

  const startTimestamp = toNumberOrNull(startRaw)
  const endTimestamp = toNumberOrNull(endRaw)

  const minDate = useMemo(() => {
    const raw = toNumberOrNull(minMaxRange[0])
    return raw != null ? moment(raw).tz(timezone, false).toDate() : undefined
  }, [minMaxRange, timezone])

  const maxDate = useMemo(() => {
    const raw = toNumberOrNull(minMaxRange[1])
    return raw != null ? moment(raw).tz(timezone, false).toDate() : undefined
  }, [minMaxRange, timezone])

  const displayStart = formatDate(startTimestamp)
  const displayEnd = formatDate(endTimestamp)

  const handleDayClick = (date: Date) => {
    const clickedTs = moment(date).tz(timezone).valueOf()
    if (!tempStart) {
      setTempStart(clickedTs)
      setTempEnd(null)
      return
    }
    if (tempStart && !tempEnd) {
      if (clickedTs < tempStart) {
        setTempEnd(tempStart)
        setTempStart(clickedTs)
      } else {
        setTempEnd(clickedTs)
      }
      setTimeout(() => {
        const finalStart = Math.min(tempStart, clickedTs)
        const finalEnd = Math.max(tempStart, clickedTs)
        const startM = moment(finalStart).tz(timezone).startOf('day')
        const endM = TimeHelper.convertToEndOfDayInTimezone(finalEnd, timezone)
        onChange([startM.valueOf(), endM.valueOf()])
        setCalendarOpen(false)
        setAnchorEl(null)
      }, 100)
      return
    }
    setTempStart(clickedTs)
    setTempEnd(null)
  }

  const tileClassName = ({ date }: { date: Date }) => {
    if (!tempStart) {
      return ''
    }
    const dayTs = moment(date).tz(timezone).startOf('day').valueOf()
    const startValue = moment(tempStart).tz(timezone).startOf('day').valueOf()
    if (startValue && !tempEnd && dayTs === startValue) {
      return 'startEdge'
    }
    if (tempStart && tempEnd) {
      const endValue = moment(tempEnd).tz(timezone).startOf('day').valueOf()
      const start = Math.min(startValue, endValue)
      const end = Math.max(startValue, endValue)
      if (dayTs === start) {
        return 'startEdge'
      }
      if (dayTs === end) {
        return 'endEdge'
      }
      if (dayTs > start && dayTs < end) {
        return 'inRange'
      }
    }
    return ''
  }

  const handleOpenCalendar = (
    e: React.MouseEvent<HTMLInputElement | HTMLDivElement>
  ) => {
    if (disabled) {
      return
    }
    setCalendarOpen(true)
    setAnchorEl(e.currentTarget)
    setTempStart(startTimestamp)
    setTempEnd(endTimestamp)
    if (endTimestamp) {
      setCalendarViewDate(moment(endTimestamp).tz(timezone).toDate())
    } else if (startTimestamp) {
      setCalendarViewDate(moment(startTimestamp).tz(timezone).toDate())
    } else {
      setCalendarViewDate(new Date())
    }
  }

  const handleClickAway = () => {
    setTimeout(() => {
      setCalendarOpen(false)
      setAnchorEl(null)
    }, 50)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="wrap-1637118383413 pickerWrap">
        {label && (
          <Box className="dataRangeSelectLabel" style={labelStyle}>
            {label}
            {hint ? <span style={{ marginLeft: 4 }}>{hint}</span> : null}
          </Box>
        )}
        <TextField
          onClick={handleOpenCalendar}
          className={clsx('pickerFrom')}
          InputLabelProps={{ shrink: false }}
          label={disabled ? '' : displayStart ? '' : startText}
          value={displayStart}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SelectArrowIcon />
              </InputAdornment>
            ),
            readOnly: true,
            style: { cursor: disabled ? 'not-allowed' : 'pointer' },
          }}
          disabled={disabled}
          size="small"
          variant="outlined"
          sx={{ width: 160 }}
        />
        {endLabel && (
          <Box className="dataRangeSelectLabel" style={{ marginTop: 16 }}>
            {endLabel}
          </Box>
        )}
        <TextField
          onClick={handleOpenCalendar}
          className={clsx('pickerTo')}
          InputLabelProps={{ shrink: false }}
          label={disabled ? '' : displayEnd ? '' : endText}
          value={displayEnd}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SelectArrowIcon />
              </InputAdornment>
            ),
            readOnly: true,
            style: { cursor: disabled ? 'not-allowed' : 'pointer' },
          }}
          disabled={disabled}
          size="small"
          variant="outlined"
          sx={{ width: 160 }}
        />
        <Popover
          open={calendarOpen}
          anchorEl={anchorEl}
          onClose={() => setCalendarOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box p={2} className={className}>
            <Calendar
              onClickDay={handleDayClick}
              tileClassName={tileClassName}
              minDate={minDate}
              maxDate={maxDate}
              prev2Label={null}
              next2Label={null}
              formatShortWeekday={(_, date) => {
                const dayIndex = date.getDay()
                const shortNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                return shortNames[dayIndex] || ''
              }}
              maxDetail="month"
              minDetail="month"
              activeStartDate={calendarViewDate}
              onActiveStartDateChange={({ activeStartDate }) => {
                setCalendarViewDate(activeStartDate || new Date())
              }}
              {...nativeProps}
            />
          </Box>
        </Popover>
      </Box>
    </ClickAwayListener>
  )
}
