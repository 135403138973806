import React, { memo, useState } from 'react'
import { EngineCallback, VIRow } from '@lib/engine-types'
import CloseBlock from '@components-simple/close-block/CloseBlock'
import { Button, TextField } from '@mui/material'
import RegexConstant from '@lib/constants/regex.constant'
import ValueInterface from '@lib/interfaces/value.interface'
import ArrHelper from '@lib/helpers/arr.helper'

// for old tags we are supporting the case where
// we may not have value
const buildTagLabel = (elem: ValueInterface) => {
  let result = elem.name
  if (elem.value) {
    result += `=${elem.value}`
  }
  return result
}

interface Props {
  readonly tags: VIRow
  onChange: EngineCallback<VIRow>
  disabled?: boolean
}

function TagsSelect({ tags, onChange, disabled = false }: Props) {
  const [tagKey, setTagKey] = useState<string>('')
  const [tagValue, setTagValue] = useState<string>('')

  const onRemove = (valueToRemove: ValueInterface) => () => {
    onChange(ArrHelper.remove(valueToRemove, tags))
  }

  const onAddClick = () => {
    const valueToAdd = {
      name: tagKey,
      value: tagValue,
    }

    const result: VIRow = tags.filter((elem) => elem.name !== valueToAdd.name)
    result.push(valueToAdd)

    onChange(result)
    setTagKey('')
    setTagValue('')
  }

  const addDisabled =
    disabled ||
    !RegexConstant.TAG_NAME.test(tagKey) ||
    !RegexConstant.TAG_VALUE.test(tagValue)

  return (
    <div className="wrap-1646232354797">
      <div className="v2StaticTextInput tsControls">
        <TextField
          disabled={disabled}
          value={tagKey}
          onChange={(e) => setTagKey(e.target.value)}
          className="bsInputWrap"
          variant="outlined"
          placeholder="Tag key"
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
        <TextField
          disabled={disabled}
          value={tagValue}
          onChange={(e) => setTagValue(e.target.value)}
          className="bsInputWrap"
          variant="outlined"
          placeholder="Tag value"
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
        <Button
          disabled={addDisabled}
          className="tsAddBtn jsAdd"
          color="primary"
          variant="contained"
          onClick={onAddClick}
        >
          Add
        </Button>
      </div>
      <ul className="tsTagsList">
        {tags.map((elem) => (
          <li key={elem.name}>
            <CloseBlock
              disabled={disabled}
              text={buildTagLabel(elem)}
              onClose={onRemove(elem)}
              variant="blue"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default memo(TagsSelect)
