// import VaultModel from '@lib/models/vault.model'
// import NumHelper from '@lib/helpers/num.helper'
// import TypeHelper from '@lib/helpers/type.helper'
// import VaultMetricsModel from '@lib/models/vault-metrics.model'

import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'
import VaultModel from '../settings/policies/vault.model'
import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'
import VaultMetricsModel from './vault-metrics.model'

abstract class VaultFactory {
  public static buildFromGrpc(params: any): VaultModel {
    return new VaultModel({
      id: numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      name: TypeHelper.stringValDef(params.name, ''),
      accountId: TypeHelper.stringValDef(params.accountId, ''),
      region: TypeHelper.stringValDef(params.region, ''),
      redStackId: TypeHelper.stringValDef(params.redStackId, ''),
      assetsCount: TypeHelper.numberValDef(params.assetsCount, 0),
      recoveryPointsCount: TypeHelper.numberValDef(
        params.recoveryPointsCount,
        0
      ),
      vpcId: TypeHelper.stringValDef(params.vpcId, ''),
      subnetIdsList: TypeHelper.arrayStringValDef(params.subnetIdsList, []),
      isDefault: TypeHelper.booleanValDef(params.isDefault, false),
      version: TypeHelper.stringValDef(params.version, ''),
      totalOptimizedSize: TypeHelper.numberValDef(params.totalOptimizedSize, 0),
      totalRecoveryPointSize: TypeHelper.numberValDef(
        params.totalRecoveryPointSize,
        0
      ),
      reductionFactor: TypeHelper.numberValDef(params.reductionFactor, 0),
      contaminatedRecoveryPointsCount: TypeHelper.numberValDef(
        params.contaminatedRecoveryPointsCount,
        0
      ),
      cleanRecoveryPointsCount: TypeHelper.numberValDef(
        params.cleanRecoveryPointsCount,
        0
      ),
      description: TypeHelper.stringValDef(params.description, ''),
      lastIntegrityCheckErrors: TypeHelper.arrayStringValDef(
        params.lastIntegrityCheckErrors,
        []
      ),
      safetyLock: params.safetyLock,
      softDeleted: params.softDeleted,
      // extra - can be provided from the different source
      redStack: params.redStack,
      lastIntegrityCheckErrorsList: params.lastIntegrityCheckErrorsList,
    })
  }

  public static buildEmptyVault(): VaultModel {
    return new VaultModel({
      id: 0,
      innerId: '',
      name: '',
      accountId: '',
      region: '',
      redStackId: '',
      assetsCount: 0,
      recoveryPointsCount: 0,
      vpcId: '',
      subnetIdsList: [],
      isDefault: false,
      redStack: {},
      version: '',
      description: '',
      cleanRecoveryPointsCount: 0,
      contaminatedRecoveryPointsCount: 0,
      totalRecoveryPointSize: 0,
      reductionFactor: 0,
      safetyLock: true,
    })
  }

  public static buildVaultMetrics(params: any): VaultMetricsModel {
    return new VaultMetricsModel({
      totalRecoveryPointSize: TypeHelper.numberValDef(
        params.totalRecoveryPointSize,
        0
      ),
      reductionFactor: TypeHelper.numberValDef(params.reductionFactor, 0),
      assetsCountProtect: TypeHelper.numberValDef(params.assetsCount, 0),
      assetsCountAcc: TypeHelper.numberValDef(params.assetsCountOfAccount, 0),
    })
  }

  public static buildEmptyVaultMetrics(): VaultMetricsModel {
    return new VaultMetricsModel({
      totalRecoveryPointSize: 0,
      reductionFactor: 0,
      assetsCountProtect: 0,
      assetsCountAcc: 0,
    })
  }
}

export default VaultFactory
