import { EfsForPolicy } from '@features/policies'
import Box from '@mui/material/Box'
import { selectedFiltersForAddPolicies } from '@store/reducers/assets-select.reducer'
import React from 'react'
import { SELECT_ASSETS_DESCRIPTION } from '../consts'

interface Props {
  onEfsTableErrorChange: (isInvalid: boolean) => void
}

export function EfsProtectionOptions({ onEfsTableErrorChange }: Props) {
  return (
    <>
      <Box
        className="policiesBlockSubheader mt25"
        data-testid="jsAddEfsTitleTable"
      >
        Select assets
      </Box>
      <Box
        className="policiesBlockLabel fontMedium mb20"
        data-testid="jsAddEfsDescriptionTable"
      >
        {SELECT_ASSETS_DESCRIPTION}
      </Box>
      <EfsForPolicy
        // @ts-ignore | Property 'onEfsTableErrorChange' very strict to the React types, but we need more generic way
        onEfsTableErrorChange={onEfsTableErrorChange}
        initSelectedFilters={selectedFiltersForAddPolicies()}
      />
    </>
  )
}
