/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import React, { Fragment } from 'react'
import {
  useSelectedCloudConnectorJobFilters,
  useCloudConnectorJobsFiltersActions,
} from '../stores'
import { CloudConnectorJobStatusChip } from '../shared'

export function SelectedJobStatusesChips() {
  const { statusesList } = useSelectedCloudConnectorJobFilters()

  const { removeFilter } = useCloudConnectorJobsFiltersActions()

  if (!statusesList.size) {
    return null
  }

  function handleRemoveJobStatus(status: CloudConnectorJobStatus) {
    removeFilter('statusesList', status)
  }

  return (
    <Fragment>
      {Array.from(statusesList).map((status) => (
        <CloudConnectorJobStatusChip
          key={status}
          status={status}
          onDelete={handleRemoveJobStatus}
        />
      ))}
    </Fragment>
  )
}
