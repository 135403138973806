import React, { memo } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
}

function PreloaderBlock({ show, ...restProps }: Props) {
  if (!show) {
    return null
  }

  return (
    <div
      {...restProps}
      data-testid="preloaderBlock"
      className="wrap-1602572065670"
    >
      <div className="preloaderBlockImgWrap"></div>
      <img
        src="/images/preloaders/preloader-big.svg"
        alt="preloader"
        width={100}
        height={100}
      />
    </div>
  )
}

export default memo(PreloaderBlock)
