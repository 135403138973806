/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { AssetItem, EC2, Asset, Backup } from 'blues-corejs/dist'
import { ListAssetsByIdsClient } from '@lib/clients/assets/list-assets-by-ids'
import {
  ListBackupsForAssetClient,
  ListScansForAssetClient,
} from '@lib/clients'
import { ListAssetItemsClient } from '@lib/clients/assets-items/list-asset-items'
import { StrHelper } from '@lib/helpers'
import { ListScansForAssetFilter } from '@lib/clients/scans/list-scans-for-asset'
import { ListBackupsForAssetFilter } from '@lib/clients/backups'
import { ScansRun } from 'blues-corejs/dist/models'

const listAssetsByIds = new ListAssetsByIdsClient()
const listBackupsForAsset = new ListBackupsForAssetClient()
const listScansForAsset = new ListScansForAssetClient()

const listAssetItemsClient = new ListAssetItemsClient()

function getScansGrpcConfig(asset: Asset) {
  if (asset instanceof EC2) {
    return {
      assetIdList: [...asset.ebsIds, asset.id],
    }
  }

  return {
    assetIdList: [asset.id],
  }
}

const INITIAL_PAGE_SIZE = 1

async function fetchAssetItems(asset: Asset) {
  const response = await listAssetItemsClient.listByAssetId({
    assetIdList: [asset.id],
    pageSize: INITIAL_PAGE_SIZE,
  })

  return [
    ...response.filesList,
    ...response.streamsList,
    ...response.volumesList,
    ...response.disksList,
  ]
}

async function fetchLatestScan(asset: Asset) {
  const response = await listScansForAsset.listScansForAsset(
    new ListScansForAssetFilter({
      ...getScansGrpcConfig(asset),
      pageSize: INITIAL_PAGE_SIZE,
    })
  )

  const lastScan = response.scansRunList[0]

  if (!lastScan) {
    return {
      filesystemChecksList: [],
      malwareScansList: [],
      ransomwareScansList: [],
    }
  }

  return lastScan
}

async function fetchLatestBackup(asset: Asset) {
  const response = await listBackupsForAsset.listBackupsForAsset(
    new ListBackupsForAssetFilter({
      assetIdList: [asset.id],
      pageSize: INITIAL_PAGE_SIZE,
    })
  )

  const allBackups = [
    ...response.awsbRpsList,
    ...response.ebsSnapshotsList,
    ...response.elastioRpsList,
    ...response.ovaBackupList,
  ]

  const latestBackup = allBackups.sort(
    (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
  )[0]

  if (!latestBackup) {
    return
  }

  return latestBackup
}

async function fetchAssetFromQuery(assetId: string) {
  const { assetsList } = await listAssetsByIds.listAssetsByIds([assetId])

  const assetFromQuery = assetsList[0]

  if (!assetFromQuery) {
    throw new Error(`Asset with asset ID ${assetId} not found.`)
  }

  const asset = [
    assetFromQuery.ebsVolume,
    assetFromQuery.ec2Instance,
    assetFromQuery.s3Bucket,
    assetFromQuery.efsFilesystem,
    assetFromQuery.genericHost,
    assetFromQuery.ovaServer,
  ].find(
    (assetFromList): assetFromList is Asset => assetFromList?.id === assetId
  )

  if (!asset) {
    throw new Error(`Asset with asset ID ${assetId} not found`)
  }

  return asset
}

type LoadingState = 'idle' | 'loading' | 'finished' | 'failure'

interface State {
  asset?: Asset
  lastBackup?: Backup
  lastReplicatedBackup?: Backup
  lastScanRun: ScansRun
  assetItems: Array<AssetItem>
  loadingState: LoadingState
}

const getInitialState = (): State => ({
  lastScanRun: {
    filesystemChecksList: [],
    malwareScansList: [],
    ransomwareScansList: [],
  },
  assetItems: [],
  loadingState: 'idle',
  asset: undefined,
})

export const useAssetStore = create<State>(getInitialState)

export const fetchAssetStore = async (assetId: string, showLoader = true) => {
  useAssetStore.setState({ loadingState: showLoader ? 'loading' : 'finished' })

  const asset = await fetchAssetFromQuery(StrHelper.base64Decode(assetId))
  const lastScanRun = await fetchLatestScan(asset)
  const lastBackup = await fetchLatestBackup(asset)
  const assetItems = await fetchAssetItems(asset)

  useAssetStore.setState({
    asset,
    lastScanRun,
    lastBackup,
    assetItems,
    loadingState: 'finished',
  })
}

export const resetAssetStore = () => useAssetStore.setState(getInitialState)

export const getAsset = (state: State) => state.asset
export const getLastBackup = (state: State) => state.lastBackup
export const getLastScanRun = (state: State) => state.lastScanRun
export const getAssetItems = (state: State) => state.assetItems

export const getIsAssetStoreLoading = (state: State) =>
  state.loadingState === 'loading'

export const getShouldRenderAssetItemsTab = () => {
  const assetItems = getAssetItems(useAssetStore.getState())
  const asset = getAsset(useAssetStore.getState())

  if (!(asset instanceof EC2)) {
    return false
  }

  return assetItems.length > 0
}
