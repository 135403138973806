/* eslint-disable import/no-extraneous-dependencies */
import {
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
  OvaBackupProvider,
} from 'blues-corejs/dist'
import { CheckStatus as AwsbCheckStatus } from 'blues-corejs/dist/models/backups/aws/awsb/types'
import { CheckStatus as EbsSnapshotCheckStatus } from 'blues-corejs/dist/models/backups/aws/ebs-snapshot/types'
import { CheckStatus as ElastioRecoveryCheckStatus } from 'blues-corejs/dist/models/backups/aws/elastio/types'
import { CheckStatus as OvaBackupCheckStatus } from 'blues-corejs/dist/models/backups/ova/backup/types'

function computeOvaBackupProvider(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return 'Cohesity'
    case OvaBackupProvider.VEEAM:
      return 'Veeam'
    case OvaBackupProvider.COMMVAULT:
      return 'Commvault'
    case OvaBackupProvider.VERITAS_NET_BACKUP:
      return 'VeritasNetBackup'
    case OvaBackupProvider.RUBRIK:
      return 'Rubrik'
    case OvaBackupProvider.AWS_BACKUP_VMWARE:
      return 'AwsBackupVmware'
    default:
      return 'Unknown Backup Type'
  }
}

export function computeBackupName(backup: Backup): string {
  if (backup instanceof ElastioRecoveryPoint) {
    return 'Elastio RP'
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return 'AWS Backup RP'
  }

  if (backup instanceof EBSSnapshot) {
    return 'EBS Snapshot'
  }

  if (backup instanceof OvaBackup) {
    return computeOvaBackupProvider(backup)
  }

  return 'Unknown Backup Type'
}

export function computeThreatStatus(
  backup: Backup,
  status:
    | ElastioRecoveryCheckStatus
    | EbsSnapshotCheckStatus
    | AwsbCheckStatus
    | OvaBackupCheckStatus
): string {
  if (backup instanceof ElastioRecoveryPoint) {
    return (
      {
        [ElastioRecoveryCheckStatus.NOT_APPLICABLE]: 'Not Applicable',
        [ElastioRecoveryCheckStatus.UNCHECKED]: 'Not Checked',
        [ElastioRecoveryCheckStatus.GOOD]: 'Not Detected',
        [ElastioRecoveryCheckStatus.BAD]: 'Detected',
      }[status as ElastioRecoveryCheckStatus] ?? ''
    )
  }

  if (backup instanceof AWSBRecoveryPoint) {
    return (
      {
        [AwsbCheckStatus.NOT_APPLICABLE]: 'Not Applicable',
        [AwsbCheckStatus.UNCHECKED]: 'Not Checked',
        [AwsbCheckStatus.GOOD]: 'Not Detected',
        [AwsbCheckStatus.BAD]: 'Detected',
      }[status as AwsbCheckStatus] ?? ''
    )
  }

  if (backup instanceof EBSSnapshot) {
    return (
      {
        [EbsSnapshotCheckStatus.UNCHECKED]: 'Not Checked',
        [EbsSnapshotCheckStatus.GOOD]: 'Not Detected',
        [EbsSnapshotCheckStatus.BAD]: 'Detected',
      }[status as EbsSnapshotCheckStatus] ?? ''
    )
  }

  if (backup instanceof OvaBackup) {
    return (
      {
        [OvaBackupCheckStatus.NOT_APPLICABLE]: 'Not Applicable',
        [OvaBackupCheckStatus.UNCHECKED]: 'Not Checked',
        [OvaBackupCheckStatus.GOOD]: 'Not Detected',
        [OvaBackupCheckStatus.BAD]: 'Detected',
      }[status as OvaBackupCheckStatus] ?? ''
    )
  }

  return ''
}
