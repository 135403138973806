import React from 'react'
import { FormControlLabelProps } from '@mui/material'
import { StyledCheckbox, StyledFormControlLabel } from './styles'

export function FilterCheckbox({
  label,
  checked,
  onChange,
}: FormControlLabelProps) {
  return (
    <StyledFormControlLabel
      label={label}
      control={<StyledCheckbox checked={checked} onChange={onChange} />}
    />
  )
}
