/* eslint-disable import/no-extraneous-dependencies */
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import React from 'react'
import Grid from '@mui/material/Grid'
import RansomwareScanDetails from '@features/asset-page/shared/ransomware-scan-details'
import MalwareScanDetails from '@features/asset-page/shared/malware-scan-details'
import FsCheckScanDetails from '@features/asset-page/shared/fs-check-scan-details'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type Props = {
  scans: Array<Scan>
}

function renderRansomwareScanDetails(scans: Array<RansomwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <RansomwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderMalwareScanDetails(scans: Array<MalwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <MalwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderFsCheckScanDetails(scans: Array<FilesystemScanCheck>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <FsCheckScanDetails scans={scans} />
    </Grid>
  )
}

function renderScansDetails(scans: Array<Scan>) {
  const ransomwareScan = scans.filter(
    (scan) => scan instanceof RansomwareScan
  ) as Array<RansomwareScan>
  const malwareScan = scans.filter(
    (scan) => scan instanceof MalwareScan
  ) as Array<MalwareScan>
  const fsCheckScan = scans.filter(
    (scan) => scan instanceof FilesystemScanCheck
  ) as Array<FilesystemScanCheck>

  return (
    <Grid
      container
      marginTop="16px"
      columnGap="15px"
      fontSize="14px"
      paddingX="16px"
      flexWrap="nowrap"
    >
      {renderRansomwareScanDetails(ransomwareScan)}
      {renderMalwareScanDetails(malwareScan)}
      {renderFsCheckScanDetails(fsCheckScan)}
    </Grid>
  )
}

function ScanDetails({ scans }: Props) {
  if (scans.length === 0) {
    sentryReThrowCatchHandler(
      'Scans array is empty for MostRecentScan component'
    )
    return null
  }

  return renderScansDetails(scans)
}

export { ScanDetails }
