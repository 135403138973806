/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'

export function computeScanType(scanRun: ScansRun): string {
  const accScanType: Array<string> = []

  if (scanRun.ransomwareScansList.length > 0) {
    accScanType.push('Ransomware')
  }
  if (scanRun.malwareScansList.length > 0) {
    accScanType.push('Malware')
  }

  if (scanRun.filesystemChecksList.length > 0) {
    accScanType.push('Filesystem')
  }

  return accScanType.join(', ')
}
