import React, { useState, MouseEvent } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Button, Typography } from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import {
  useRuleDetailsActions,
  useRuleDetailsState,
} from '@features/alerts/create-rule-drawer/stepper/steps/rule-details'

import {
  StyledPopper,
  StyledInput,
  StyledAutocompletePopperWrapper,
  Root,
} from '@components/shared/multi-select-search/styles'
import { SelectedItemsChips } from '@components/shared/multi-select-search/selected-items-chips'

interface OptionType {
  value: string | number
  label: string
}

interface Props {
  options: Array<OptionType>
  placeholder?: string
  name: string
  disabled?: boolean
  onSelectionChange?: (selectedItems: Array<OptionType>) => void
}

function EventsMultiSelect({
  options = [],
  placeholder = 'No options',
  name,
  disabled,
  onSelectionChange,
}: Props) {
  const { setAllKinds } = useRuleDetailsActions()
  const { allKinds } = useRuleDetailsState()
  const { control } = useFormContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const validOptions = options.filter(
    (option) => option.label && option.label.trim() !== ''
  )

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAllClick = () => {
    setAllKinds(true)
  }
  const handleAddClick = () => {
    setAllKinds(false)
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'select-option' : undefined

  return (
    <Root>
      <Controller
        name={name}
        control={control}
        defaultValue={[]}
        render={({ field }) => {
          const concatenatedValue = field.value
            .map((option: OptionType) => option?.label)
            .join(', ')

          const handleChange = (
            _: React.SyntheticEvent,
            newValue: Array<OptionType>
          ) => {
            field.onChange(newValue)
            onSelectionChange?.(newValue)
          }

          return (
            <>
              <Button
                fullWidth
                sx={{
                  padding: '0 0',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                disableRipple
                aria-describedby={id}
                onClick={handleClick}
              >
                <Box display="flex" flexDirection="column" width="100%">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    sx={{
                      backgroundColor: 'var(--white)',
                    }}
                  >
                    <OutlinedInput
                      fullWidth
                      readOnly
                      disabled={disabled}
                      size="small"
                      value={concatenatedValue}
                      type="text"
                      endAdornment={<ArrowDropDownIcon />}
                      placeholder={placeholder}
                    />
                  </FormControl>
                </Box>
              </Button>

              {/* Render selected items with delete functionality */}
              {field.value.length > 0 && (
                <Box marginTop="16px" display="flex" gap="8px" flexWrap="wrap">
                  <SelectedItemsChips
                    items={field.value}
                    onDelete={(item) => {
                      const newValue = field.value.filter(
                        (selectedItem: OptionType) =>
                          selectedItem.value !== item.value
                      )
                      field.onChange(newValue)
                      onSelectionChange?.(newValue)
                    }}
                  />
                </Box>
              )}

              {/* Autocomplete setup to manage options */}
              <StyledPopper
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                placement="bottom-start"
                sx={{
                  minWidth: anchorEl ? anchorEl.clientWidth : undefined,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    <Stack direction="row" alignItems="center" p={1} gap={1}>
                      <Typography variant="body2">
                        Selected: {field.value.length}
                      </Typography>
                      <Button
                        onClick={() => {
                          handleAllClick()
                          field.onChange([])
                          onSelectionChange?.([])
                        }}
                        variant={allKinds ? 'contained' : 'outlined'}
                        sx={{ minWidth: '80px !important' }}
                      >
                        All
                      </Button>
                      <Button
                        onClick={handleAddClick}
                        variant={!allKinds ? 'contained' : 'outlined'}
                        sx={{ minWidth: '80px !important' }}
                      >
                        Add
                      </Button>
                    </Stack>
                    <Autocomplete
                      open
                      multiple
                      value={field.value}
                      onChange={handleChange}
                      disableCloseOnSelect
                      slots={{
                        popper: StyledAutocompletePopperWrapper,
                      }}
                      renderTags={() => null}
                      noOptionsText={'No options'}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) =>
                        option.label ? (
                          <Box component="li" {...props}>
                            {option.label}
                          </Box>
                        ) : null
                      }
                      options={validOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <StyledInput
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          autoFocus
                          placeholder="Search"
                        />
                      )}
                      getOptionDisabled={() => allKinds}
                    />
                  </div>
                </ClickAwayListener>
              </StyledPopper>
            </>
          )
        }}
      />
    </Root>
  )
}

export default EventsMultiSelect
