/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  createColumn,
  StatusCircle,
  CellWithLink,
  HeaderWithLabel,
} from '@components/table'
import InfectedServerCell from './infected-server-cell'
import { OvaServer, OvaAccount } from 'blues-corejs/dist/models'
import { MaybeInfectedServer } from '@lib/clients/assets/list-ova-servers'
import { OvaBackupProviderHelper } from '@lib/helpers/ova-backup'
import { AssetRoutes } from '@lib/router'

type InputData = {
  ovaAccountsList: Array<OvaAccount>
  totalServers: number
  totalAccounts: number
  totalProviders: number
}

const EMPTY_CELL_PLACEHOLDER = '-'

export function columnDefinitions({
  ovaAccountsList,
  totalServers,
  totalProviders,
  totalAccounts,
}: InputData) {
  return [
    createColumn<MaybeInfectedServer>('hasMalware', {
      enableSorting: false,
      id: 'status-circle',
      cell: () => <StatusCircle isHealthy={false} />,
      size: 45,
    }),
    createColumn<MaybeInfectedServer>('server', {
      header: () => (
        <HeaderWithLabel title="Server" label={String(totalServers)} />
      ),
      enableSorting: false,
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        const assetPageLink = AssetRoutes.assetId(server.id)

        return <CellWithLink href={assetPageLink} value={server.name} />
      },
    }),
    createColumn<MaybeInfectedServer>('server', {
      enableSorting: false,
      id: 'account-name',
      header: () => (
        <HeaderWithLabel title="Account" label={String(totalAccounts)} />
      ),
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        const serverAccount = ovaAccountsList.find(
          (account) => account.id === server.ovaAccountId
        )

        return serverAccount?.name || EMPTY_CELL_PLACEHOLDER
      },
    }),
    createColumn<MaybeInfectedServer>('server', {
      id: 'backup-vendor',
      enableSorting: false,
      header: () => (
        <HeaderWithLabel title="Backup Vendor" label={String(totalProviders)} />
      ),
      cell: ({ getValue }) => {
        const server = getValue() as unknown as OvaServer

        return OvaBackupProviderHelper.getProviderName(server.backupProvider)
      },
    }),
    createColumn<MaybeInfectedServer>('hasMalware', {
      id: 'status',
      header: 'Status',
      enableSorting: false,
      cell: () => <InfectedServerCell isInfected />,
    }),
    createColumn<MaybeInfectedServer>('hasMalware', {
      header: 'Threats',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { hasMalware, hasRansomware } = original

        const threats: Array<string> = []

        if (!hasMalware && !hasRansomware) {
          return EMPTY_CELL_PLACEHOLDER
        }

        if (hasRansomware) {
          threats.push('Ransomware')
        }

        if (hasMalware) {
          threats.push('Malware')
        }

        return `${threats.join(' & ')} Found`
      },
    }),
  ]
}
