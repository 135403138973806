import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

export const StyledContainer = styled(Box)({})

export const StyledDivider = styled(Divider)({
  marginBlock: '16px',
})

export const StyledHeader = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  background: theme.palette.grey[50],
  borderRadius: '12px',
}))
