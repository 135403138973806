export enum ExecutiveReportPeriod {
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This Week',
  LAST_WEEK = 'Last Week',
  THIS_MONTH = 'This Month',
  LAST_MONTH = 'Last Month',
  THIS_QUARTER = 'This Quarter',
  LAST_QUARTER = 'Last Quarter',
  THIS_YEAR = 'This Year',
  LAST_YEAR = 'Last Year',
  CUSTOM_DATE = 'Custom Date',
}
