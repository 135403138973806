import { CfnStatusConstant } from 'ui-v2/src/lib/constants/cfn-status.constant'
import { BaseModel } from '../base-model'

class CfnModel extends BaseModel {
  public readonly arn: string

  public readonly region: string

  public readonly status: CfnStatusConstant

  public readonly isEmpty: boolean

  constructor(readonly params: any = {}) {
    super(params)

    this.arn = params.arn
    this.region = params.region
    this.status = params.status
    this.isEmpty = params.isEmpty
  }
}

export default CfnModel
