import React from 'react'

function RedCrossIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7667 2.5H7.23333C7.01667 2.5 6.8 2.59167 6.65 2.74167L2.74167 6.65C2.59167 6.8 2.5 7.01667 2.5 7.23333V12.7583C2.5 12.9833 2.59167 13.1917 2.74167 13.35L6.64167 17.25C6.8 17.4083 7.01667 17.5 7.23333 17.5H12.7583C12.9833 17.5 13.1917 17.4083 13.35 17.2583L17.25 13.3583C17.4083 13.2 17.4917 12.9917 17.4917 12.7667V7.23333C17.4917 7.00833 17.4 6.8 17.25 6.64167L13.35 2.74167C13.2 2.59167 12.9833 2.5 12.7667 2.5ZM10 14.4167C9.4 14.4167 8.91667 13.9333 8.91667 13.3333C8.91667 12.7333 9.4 12.25 10 12.25C10.6 12.25 11.0833 12.7333 11.0833 13.3333C11.0833 13.9333 10.6 14.4167 10 14.4167ZM10 10.8333C9.54167 10.8333 9.16667 10.4583 9.16667 10V6.66667C9.16667 6.20833 9.54167 5.83333 10 5.83333C10.4583 5.83333 10.8333 6.20833 10.8333 6.66667V10C10.8333 10.4583 10.4583 10.8333 10 10.8333Z"
        fill="#FF7878"
      />
    </svg>
  )
}

export default RedCrossIcon
