import React, { useEffect, useMemo, useState } from 'react'
import FormHelper from '@lib/helpers/form.helper'
import buildDefaultFormData from '@forms/restore/data'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import {
  EngineCallback,
  EngineCallbackPure,
  Nullable,
  ValueObject,
  VIRow,
  VIRowReadonly,
} from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormRestoreInterface from '@lib/interfaces/form/form-restore.interface'
import TagsSelect from '@components-composite/tags-select/TagsSelect'
import StrHelper from '@lib/helpers/str.helper'
import CopyButtonsBlock from '@components-composite/copy-buttons-block/CopyButtonsBlock'
import TextConstant from '@lib/constants/text.constant'
import { CopyButtonVariant } from '@lib/constants/copy-command-button.constant'
import VpcModel, { VpcTagsType } from '@lib/models/vpc.model'
import VpcFactory from '@lib/factories/vpc.factory'
import ValueInterface from '@lib/interfaces/value.interface'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import AvailabilityZoneModel from '@lib/models/availability-zone.model'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import AwsSecurityGroupsModel from '@lib/models/aws-security-groups.model'
import AwsSecurityGroupsFactory from '@lib/factories/aws-security-groups.factory'
// eslint-disable-next-line import/no-extraneous-dependencies
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { useSelector } from 'react-redux'
import { getListAvailabilityZonesList } from '@store/selectors/rex.selector'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EC2, ElastioRecoveryPoint } from 'blues-corejs/dist'
import RestoreControlPanel from './restore-control-panel'
import { VPC_SUBNET_NAME_TOOLTIP_LENGTH } from '@lib/constants/tooltip.constant'

interface Props {
  backedup?: Nullable<ElastioRecoveryPoint>
  kindLabel: string
  asset?: Nullable<EC2>
  possibleVPCList?: Array<VpcModel>
  possibleSecurityGroupsList: Array<AwsSecurityGroupsModel>
  defaultValues?: ValueObject<FormRestoreInterface>
  onSubmit: EngineCallback<FormRestoreInterface>
  onCancel: EngineCallbackPure
  isLoading: boolean
  errorGroups?: Array<ErrorGroupConstants>
  isShowVpcList: boolean
  isShowAvailabilityZonesList: boolean
}

function RestorePageForm({
  backedup = null,
  kindLabel,
  asset = null,
  possibleVPCList = [],
  possibleSecurityGroupsList,
  defaultValues,
  onSubmit,
  onCancel,
  isLoading,
  errorGroups = [ErrorGroupConstants.RESTORE],
  isShowVpcList = false,
  isShowAvailabilityZonesList = false,
}: Props) {
  const possibleAZList: Array<AvailabilityZoneModel> = useSelector(
    getListAvailabilityZonesList
  )

  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )
  const {
    register,
    isFormDisabled,
    submit,
    formState,
    globalFormError,
    setValue,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, isLoading)

  const [currentTags, setCurrentTags] = useState<VIRow>(
    currentForm.tags?.options ?? []
  )

  useEffect(() => {
    setValue(currentForm.tags.name, currentTags)
  }, [currentTags])

  const regionVpcList: VIRow = possibleVPCList.map((v: VpcModel) => {
    const vpcLabel =
      v.params.tagsList.find(
        (tag: VpcTagsType) => tag.key.toLowerCase() === 'name'
      )?.value ?? v.params.innerId
    return {
      name: v.params.innerId,
      label: v.isDefault ? `${vpcLabel} (default)` : vpcLabel,
    }
  })

  // vpc select first
  const [vpc, setVpc] = useState<ValueInterface>(
    regionVpcList[0] ?? { name: '' }
  )

  // subnets
  const [possibleSub, setPossibleSub] = useState<VIRowReadonly>([])
  const [selectedSub, setSelectedSub] = useState<ValueInterface>({ name: '' })

  // security groups
  const [possibleSecurityGroups, setPossibleSecurityGroups] =
    useState<VIRowReadonly>([])
  const [selectedSecurityGroups, setSelectedSecurityGroups] = useState<VIRow>(
    []
  )

  useEffect(() => {
    // pre-select recovery point asset VPC
    const vpcId = asset?.awsVpcId
    if (vpcId) {
      const currentVpc = regionVpcList.find((el) => el.name === vpcId) ?? {
        name: '',
      }

      setVpc((prevVpc) => {
        return prevVpc.name !== currentVpc.name ? currentVpc : prevVpc
      })
    }
  }, [possibleVPCList, backedup])

  useEffect(() => {
    const possibleSubsList =
      possibleVPCList.find(({ innerId }) => innerId == vpc?.name)
        ?.subnetsList ?? []

    const possibleSubs: VIRowReadonly = possibleSubsList.map(
      VpcFactory.fromSubnetModelToVi
    )

    setPossibleSub(possibleSubs)

    // pre-select recovery point asset subnet
    if (vpc.name) {
      const currentPossibleSub = possibleSubs[0]

      if (currentPossibleSub) {
        setSelectedSub(currentPossibleSub)
      }
    }

    const securityGroupsList = possibleSecurityGroupsList
      ? (possibleSecurityGroupsList.find(({ vpcId }) => vpcId == vpc?.name)
          ?.securityGroupsList ?? [])
      : []

    const possibleGroups: VIRowReadonly = securityGroupsList.map(
      AwsSecurityGroupsFactory.fromGroupModelToVi
    )

    setPossibleSecurityGroups(possibleGroups)

    // TODO Implement it when we have it
    // pre-select recovery point asset security groups
    // if (vpc.name) {
    //   const currentSecurityGroups: VIRow = []
    //   if (asset?.securityGroupIdsList?.length)) {
    //     asset.securityGroupIdsList.forEach((el: string) => {
    //       const currentGroup = possibleGroups.find((g) => g.name === el)
    //       if (currentGroup) {
    //         currentSecurityGroups.push(currentGroup)
    //       }
    //     })
    //   }
    //   setSelectedSecurityGroups(currentSecurityGroups)
    // }

    setValue(currentForm.vpc.name, vpc.name)
  }, [vpc, possibleSecurityGroupsList])

  useEffect(() => {
    setValue('subnet', selectedSub?.name ?? '')
  }, [selectedSub])

  useEffect(() => {
    setValue('securityGroups', selectedSecurityGroups ?? [])
    setValue(
      'securityGroupIdsList',
      selectedSecurityGroups.map(({ name }) => name)
    )
  }, [selectedSecurityGroups])

  // availability zones
  let availabilityZonesList: VIRow = []
  if (possibleAZList) {
    availabilityZonesList = possibleAZList.map((v: AvailabilityZoneModel) => ({
      name: v.azName,
      label: v.azName,
    }))
  }

  const [azone, setAZone] = useState<ValueInterface>({ name: '' })

  // TODO Implement it when we have it
  // useEffect(() => {
  //   // pre-select recovery point asset AZ
  //   let currentAz = { name: '' }
  //   if (backupVolume?.getAvailabilityZone()) {
  //     currentAz = availabilityZonesList.find(
  //       (el) => el.name === backupVolume?.getAvailabilityZone()
  //     ) ?? { name: '' }
  //   }
  //   setAZone(currentAz)
  // }, [possibleAZList, backupVolume])

  useEffect(() => {
    setValue('zone', azone.name ?? '')
  }, [azone])

  FormHelper.fillErrors(formState.errors, currentForm)

  // check if subnets selected
  const subnetsNotSelected = isShowVpcList && !selectedSub?.name

  // check if security groups selected
  const groupsNotSelected = isShowVpcList && !selectedSecurityGroups?.length

  // check if Availability Zones selected
  const azonesNotSelected = isShowAvailabilityZonesList && azone.name === ''

  const commandButtonText = StrHelper.buildRestoreCLICommandRestore(
    kindLabel,
    asset,
    backedup?.ccRpId as string,
    currentTags,
    isShowVpcList ? selectedSub.name : '',
    isShowAvailabilityZonesList ? azone.name : '',
    isShowVpcList ? selectedSecurityGroups.map(({ name }) => name) : []
  )

  const commandButtonsData: VIRow = [
    {
      type: CopyButtonVariant.WINDOWS,
      name: commandButtonText,
      disabled: false,
    },
    {
      type: CopyButtonVariant.MACOS,
      name: commandButtonText,
      disabled: false,
    },
    {
      type: CopyButtonVariant.LINUX,
      name: commandButtonText,
      disabled: false,
    },
  ]

  const handleSubmitForm = () => {
    submit()
  }

  const isSubmitDisabled =
    isLoading ||
    isFormDisabled ||
    subnetsNotSelected ||
    azonesNotSelected ||
    groupsNotSelected

  return (
    <form
      className={clsx('wrap-1688055335930', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      <input
        type="hidden"
        {...register(currentForm.tags.name, currentForm.tags.validationRules)}
      />

      {isShowVpcList && (
        <>
          <input
            type="hidden"
            {...register(currentForm.vpc.name, currentForm.vpc.validationRules)}
          />
          <input
            type="hidden"
            {...register(
              currentForm.subnet.name,
              currentForm.subnet.validationRules
            )}
          />
          <input
            type="hidden"
            {...register(
              currentForm.securityGroupIdsList.name,
              currentForm.securityGroupIdsList.validationRules
            )}
          />
        </>
      )}

      {isShowAvailabilityZonesList && (
        <input
          type="hidden"
          {...register(currentForm.zone.name, currentForm.zone.validationRules)}
        />
      )}

      <div
        className={clsx('form-row', {
          thirdGrid: isShowAvailabilityZonesList,
        })}
      >
        {/* Select subnet for EC2 asset*/}
        {isShowVpcList && (
          <div className="dropdownsColBlock">
            <div className="subTitle">Select Network</div>
            <div className="dropdownsRowFlex dropdownsBlock mb20">
              <ViSelect
                placeholder={'Select VPC'}
                possible={regionVpcList}
                selected={vpc}
                onChange={setVpc}
                disabled={isFormDisabled || isLoading}
                getLabel={(v) => {
                  if (!v.label?.includes(v.name)) {
                    return (
                      <LongTextTooltip
                        text={v.label ?? v.name}
                        placement="top"
                        tooltipText={v.name}
                        maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                        show
                      />
                    )
                  }
                  return v.name
                }}
              />
              <div className="dropdownsColFlex">
                <ViSelect
                  placeholder="Select Subnet"
                  possible={possibleSub}
                  selected={selectedSub}
                  onChange={setSelectedSub}
                  disabled={isFormDisabled || isLoading}
                  getLabel={(v) => {
                    if (!v.label?.includes(v.name)) {
                      return (
                        <LongTextTooltip
                          text={v.label ?? v.name}
                          placement="top"
                          tooltipText={v.name}
                          maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                          show
                        />
                      )
                    }
                    return v.label
                  }}
                />
                <ViMultipleSelect
                  disabled={isFormDisabled || isLoading}
                  possible={possibleSecurityGroups}
                  selected={selectedSecurityGroups}
                  onChange={(value) => setSelectedSecurityGroups(value)}
                  placeholder="Select Security Groups"
                  countLabel={`security group${
                    selectedSecurityGroups.length > 1 ? 's' : ''
                  } selected`}
                />
              </div>
            </div>
          </div>
        )}

        {/* Select Availability zone for EBS asset*/}
        {isShowAvailabilityZonesList && (
          <div>
            <div className="subTitle">Select availability zone (required)</div>
            <div className="dropdownsRowFlex dropdownsBlock mb20">
              <ViSelect
                possible={availabilityZonesList}
                placeholder="Select Availability Zone"
                selected={azone}
                onChange={setAZone}
                disabled={isFormDisabled || isLoading}
                getLabel={(v) => v.label}
              />
            </div>
          </div>
        )}
        <div>
          <div className="subTitle">
            Add recovery tags to restored assets (optional)
          </div>
          <div className={clsx({ controlsDisabled: isLoading })}>
            <TagsSelect onChange={setCurrentTags} tags={currentTags} />
          </div>
        </div>
      </div>
      <CopyButtonsBlock
        blockHeader="Restore via CLI"
        blockText={
          kindLabel === 'ebs'
            ? TextConstant.CLI_RESTORE_BLOCK_TEXT_EBS
            : TextConstant.CLI_RESTORE_BLOCK_TEXT_EC2
        }
        blockToastText={TextConstant.CLI_COMMAND_RESTORE_TOAST}
        commands={commandButtonsData}
        disabled={subnetsNotSelected || azonesNotSelected || groupsNotSelected}
      />

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}
      <RestoreControlPanel
        onSubmit={handleSubmitForm}
        onCancel={onCancel}
        isSubmitDisabled={isSubmitDisabled}
        isLoading={isLoading}
      />
    </form>
  )
}

export default RestorePageForm
