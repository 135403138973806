/* eslint-disable import/no-extraneous-dependencies */
import CliCommandModalData from '@lib/interfaces/cli-command-modal-data.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'
import React from 'react'
import { VolumesCheckBoxBlock } from '../components'
import MountRecoveryPoint from '../MountRecoveryPoint'
import { buildMountDetails } from '../utils'
import { PortalMountButtons } from './portal-buttons'
import { useBreadcrumbsForMount } from './use-breadcrumbs-for-mount'
import { useCommandsData } from './use-commands-data'
import { useMountInstanceData } from './use-mount-instance-data'
import { useVolumesData } from './use-volumes-data'

function MountInstance() {
  const { backup, ebsVolumes, instance, isLoading, threats, handleGoBack } =
    useMountInstanceData()

  useBreadcrumbsForMount({
    instance,
    backup,
  })

  const mountDetails = buildMountDetails({
    asset: instance,
    backup,
  })

  const { commands } = mountDetails

  const { commandsData, setCommandsData, updateCommandText } =
    useCommandsData(commands)

  const { setVolumesVIRowList, volumesVIRowList, someVolumeSelected } =
    useVolumesData({
      instance,
      ebsVolumes,
      threats,
    })

  const updatedMountDetails: CliCommandModalData = {
    ...mountDetails,
    details: [
      ...mountDetails.details,
      {
        name: 'Instance ID',
        label: instance?.awsId || '',
      },
    ],
  }

  const CHECKBOX_SELECT_ALL_LABEL =
    volumesVIRowList.length === 1 ? '' : 'All volumes'

  const handleChangeVolumesSelected = (elements: Array<ValueInterface>) => {
    const selected = elements.filter((element) => element.value)

    if (!selected.length || selected.length === elements.length) {
      setCommandsData(commands)
    } else {
      updateCommandText(selected)
    }

    setVolumesVIRowList(elements)
  }

  return (
    <div className="innerContent innerContentBlueBackground jsMountRecoveryPoint">
      <MountRecoveryPoint
        detailsBlockData={updatedMountDetails}
        scansStatus={backup?.summaryCheckStatus || SummaryCheckStatus.UNCHECKED}
        isLoading={isLoading}
      >
        <VolumesCheckBoxBlock
          assetVolumesList={volumesVIRowList}
          selectAllLabel={CHECKBOX_SELECT_ALL_LABEL}
          handleChangeVolumesSelected={handleChangeVolumesSelected}
        />
        <PortalMountButtons
          commandsData={commandsData}
          onCancelClick={handleGoBack}
          shouldShowPopover={!someVolumeSelected}
        />
      </MountRecoveryPoint>
    </div>
  )
}

export default MountInstance
