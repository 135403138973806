import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  SelectedVariant,
  EmptyAllS3SelectedAssets,
  EmptyAllEfsSelectedAssets,
  TabAssetType,
} from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
} from '@lib/constants/policies.constant'
import {
  setAddTags,
  setProtectNewImmediately,
  setAddSelectedCloudConnectorsVariant,
  setAddSelectedCloudConnectors,
  resetIntegrityScan,
  resetScanWithTimestamps,
  setAddPolicyName,
  setAddSelectedVariant,
  setEntropyDetectionConfiguration,
} from '@store/actions/add-policy.action'
import {
  setAssetsSelectFilters,
  setAssetsSelectEnabledTabs,
  setAssetsLoadingMarker,
  setAllSelectedAssets,
  setAllS3SelectedAssets,
  setAllEfsSelectedAssets,
  setAssetsSelectTabSelectedNum,
  setEditMainTabAssets,
} from '@store/actions/assets-select.action'
import { requestPolicies } from '@store/actions/policies.action'
import { getAllRedStacks } from '@store/actions/rex.action'
import { requestVaultList } from '@store/actions/vault.action'
import {
  selectedFiltersForAddPolicies,
  AssetLoadingMarker,
} from '@store/reducers/assets-select.reducer'

export function useInitializeInitialStateForAddPolicy() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const filterParam = urlParams.get('protectfiltered') ?? ''
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestPolicies())
    dispatch(setAssetsSelectFilters(selectedFiltersForAddPolicies()))
    dispatch(getAllRedStacks())
    dispatch(setAssetsSelectEnabledTabs([]))
    dispatch(requestVaultList())
    dispatch(setAddTags([]))
    dispatch(setProtectNewImmediately(ProtectNewImmediately.DISABLED))
    dispatch(
      setAddSelectedCloudConnectorsVariant(SelectedCloudConnectorVariant.ALL)
    )
    dispatch(setAddSelectedCloudConnectors([]))
    dispatch(resetIntegrityScan())
    dispatch(resetScanWithTimestamps())

    return () => {
      dispatch(setAddPolicyName(''))
      dispatch(setAssetsSelectFilters([]))
      dispatch(setAssetsLoadingMarker(AssetLoadingMarker.INITIAL))
      dispatch(setEntropyDetectionConfiguration(false))
    }
  }, [])

  useEffect(() => {
    if (filterParam) {
      dispatch(setAddSelectedVariant(SelectedVariant.AssetsVariant))
      dispatch(setAssetsSelectFilters(selectedFiltersForAddPolicies()))
    } else {
      dispatch(setAddSelectedVariant(SelectedVariant.AllEC2Variant))
      dispatch(setAllSelectedAssets([]))
      dispatch(setAllS3SelectedAssets(EmptyAllS3SelectedAssets()))
      dispatch(setAllEfsSelectedAssets(EmptyAllEfsSelectedAssets()))
      dispatch(setAssetsSelectTabSelectedNum(TabAssetType.Ec2andEbs))
      dispatch(setEditMainTabAssets(TabAssetType.Ec2andEbs))
    }
  }, [])
}
