/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  OvaBackupTransformer,
  OvaServerTransformer,
} from '@lib/clients/assets/transformers'
import { AbstractListRequest } from '@lib/clients/list-request'
import {
  ListOvaServersRequestPb,
  GetFiltersForListOvaServersPb,
} from '@lib/clients/grpc-imports'
import type {
  GetFiltersForListOvaServersResponse,
  ListOvaServersResponse,
} from '.'
import { Pagination } from '@lib/clients'
import { OvaBackupProviderTransformer } from '@lib/clients/ova-backup-provider-transformer'
import { GetFiltersForListOvaServersParams } from './types'

export class ListOvaServersClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListOvaServersClient'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  async list(
    request: AbstractListRequest<ListOvaServersRequestPb>
  ): Promise<ListOvaServersResponse & Pagination> {
    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listOvaServers(
            request.createGrpcRequest(),
            this.metadata()
          ),
        {
          requestName: 'AssetsPromiseClient/listOvaServers',
        }
      )
    ).toObject()

    return {
      pageToken: response.pagination?.nextPageToken,
      totalAccounts: response.totalAccounts,
      totalServers: response.totalServers,
      totalProviders: response.totalProviders,
      servers: response.serversList.map(
        ({ hasMalware, hasRansomware, lastCleanBackup, server }) => ({
          hasMalware,
          hasRansomware: hasRansomware,
          lastCleanBackup:
            lastCleanBackup &&
            new OvaBackupTransformer(lastCleanBackup).transform(),
          server: new OvaServerTransformer(server!).transform(),
        })
      ),
    }
  }

  async getFiltersForListOvaServers(
    params: GetFiltersForListOvaServersParams
  ): Promise<GetFiltersForListOvaServersResponse> {
    const request = new GetFiltersForListOvaServersPb.Request()

    request.setOvaAccountIdsList(params.ovaAccountIdsList)

    request.setBackupProvidersList(
      params.backupProvidersList.map((provider) =>
        OvaBackupProviderTransformer.transformToProtoDefinition(provider)
      )
    )

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.getFiltersForListOvaServers(
            request,
            this.metadata()
          ),
        {
          requestName: 'AssetsPromiseClient/getFiltersForListOvaServers',
        }
      )
    ).toObject()

    return {
      malwareThreatNamesList: new Set(response.malwareThreatNamesList),
      ransomwareThreatNamesList: new Set(response.ransomwareThreatNamesList),
    }
  }
}
