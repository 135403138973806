import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import DataHelper from '@lib/helpers/data.helper'
import MoreBlock from '@components-composite/more-block/MoreBlock'
import RestorePageForm from '@forms/restore/restore-page.form'
import FormRestoreInterface from '@lib/interfaces/form/form-restore.interface'
import { requestListAvailabilityZones } from '@store/actions/rex.action'
import PageHelper from '@lib/helpers/page.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import { RESTORE_RP_INFO_DRAW_MAPPER } from '@tables/core/table-vi-draw-mappers'
import { useLayoutContext } from '@features/contexts'

import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { useEBSVolume } from './use-ebs-volume'
import { useRestoreSchedule } from './use-schedule-restore'
import {
  useURLDataDecoder,
  useBackupsForAssetListAndScansData,
  ScanStatusLabel,
} from '@components-complex/dashboard-pages-v3/restore/shared'
import { isAnyScanInfected } from '@components-complex/dashboard-pages-v3/restore/instance/should-be-infected'
import { AssetRoutes, useNavigation } from '@lib/router'
// eslint-disable-next-line import/no-extraneous-dependencies

const topText =
  'The EBS restore will create a new volume, identical to that in the recovery point, in the same availability zone if not specified otherwise. The metadata will match the recovery point exactly. You can customize the availability zone and tags using this dialog as of now, more option are on the way.'

function RestoreVolume() {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const { assetId, backupId } = useURLDataDecoder()
  const { scans, backup, isLoadingBackupAndScans } =
    useBackupsForAssetListAndScansData({
      assetId,
      backupId,
    })

  const { ebsVolume, isAssetLoading } = useEBSVolume(assetId)
  const { isRestoreInProgress, restoreSchedule } = useRestoreSchedule(
    backup,
    ebsVolume?.ccAssetId
  )

  const { setBreadcrumbsPaths, setCurrentCrumbTitle, setHeaderTitle } =
    useLayoutContext()

  useEffect(() => {
    if (!backup) {
      return
    }
    dispatch(requestListAvailabilityZones(backup.ccId))
  }, [backup])

  const handleRestoreScheduleSubmit = async (form: FormRestoreInterface) => {
    restoreSchedule(form)
  }

  const handleGoBack = () => {
    if (!ebsVolume) {
      return
    }

    navigation.push(AssetRoutes.assetId(ebsVolume.id))
  }

  useEffect(() => {
    if (!ebsVolume?.awsId) {
      return
    }

    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
      },
      {
        href: PagePathConstant.EBS_INVENTORY_TABLE,
        text: titlesForBreadcrumb(PagePathConstant.EBS_INVENTORY_TABLE) ?? '',
      },
      {
        href: PageHelper.buildAssetPageUrl(ebsVolume.id),
        text: ebsVolume.awsId,
      },
    ])
  }, [ebsVolume?.awsId])

  useEffect(() => {
    if (!backup) {
      return
    }
    setHeaderTitle(`Restore ${backup.ccRpId}`)
    setCurrentCrumbTitle(backup.ccRpId)

    return () => {
      setHeaderTitle(null)
      setCurrentCrumbTitle(null)
    }
  }, [backup])

  const isLoading = isAssetLoading || isLoadingBackupAndScans

  if (isLoading) {
    return <PreloaderBlock show />
  }

  if (!(backup && ebsVolume)) {
    return null
  }

  const isInfected = isAnyScanInfected(scans)

  return (
    <div className="innerContent innerContentBlueBackground jsRestore">
      <div className="wrap-1688403755464">
        <>
          <div className="icNameWithBackWrap">
            <ScanStatusLabel isInfected={isInfected} />
          </div>
          <div className="helpText">{topText}</div>
          <MoreBlock
            data={DataHelper.restoreRpVolumeInfo({
              ebs: ebsVolume,
              backup: backup,
              scans,
            })}
            columns={3}
            isButtonShow={false}
            drawMapper={RESTORE_RP_INFO_DRAW_MAPPER()}
          />
          <RestorePageForm
            backedup={backup}
            kindLabel="ebs"
            isShowAvailabilityZonesList={true}
            isShowVpcList={false}
            possibleSecurityGroupsList={[]}
            isLoading={isRestoreInProgress}
            onCancel={handleGoBack}
            onSubmit={handleRestoreScheduleSubmit}
          />
        </>
      </div>
    </div>
  )
}

export default RestoreVolume
