/* eslint-disable import/no-extraneous-dependencies */
import { EBS } from 'blues-corejs/dist/models/assets/aws/ebs'
import { Threat } from 'blues-corejs/dist/models/threats'
import React, { useState } from 'react'
import {
  DetailsVolumeOutput,
  ListDetailsVolumesWithThreats,
} from 'blues-corejs/dist/use_cases/list_detailes_volumes_with_threats'
import type { SelectChangeEvent } from '@mui/material/Select'
import { Table } from '@components/table'
import { getVolumesWithThreatsTableColumnDefinition } from './columns-definition'
import { Row } from '@tanstack/react-table'
import { SubTableForVolumeWithThreats } from './volumes-with-threats-sub-table'
import { ThreatsFilter } from '@features/asset-page/shared/threats-filter'
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
  EC2,
} from 'blues-corejs/dist'
import { useGetAttachedVolumes } from '@lib/hooks/api-hooks'
import { ExportAsCsv } from '@features/common'
import {
  prepareThreatsDataForCsv,
  THREATS_CSV_TABLE_HEADERS,
} from './prepare-data-for-csv'
import Stack from '@mui/material/Stack'
import { EngineCallback } from '@lib/engine-types'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

const computeNoDataMessageForTable = (
  threatsSize: number,
  scansSize: number
): string => {
  if (threatsSize === 0 && scansSize > 0) {
    return 'No Threats found'
  }

  if (threatsSize === 0 && scansSize === 0) {
    return 'No scans performed'
  }

  return ''
}

interface Props {
  threats: Array<Threat>
  scans: Array<Scan>
  instance: EC2
  preselectedVolume?: EBS
  fetchDataThreats: EngineCallback<void>
}

function VolumesWithThreats({
  threats,
  scans,
  instance,
  preselectedVolume,
  fetchDataThreats,
}: Props) {
  const volumes = useGetAttachedVolumes([...instance.ebsIds])

  const [selectedFilter, setSelectedFilter] = useState({})
  const { volumesData, possibleFilters } = new ListDetailsVolumesWithThreats(
    preselectedVolume ? [preselectedVolume] : volumes,
    threats
  ).execute(selectedFilter)

  const handleSelectFilter = (
    e: SelectChangeEvent<Array<string>>,
    key: string
  ) => {
    const value = e.target.value as Array<string>

    setSelectedFilter({
      ...selectedFilter,
      [key]: value.length === 0 ? undefined : value,
    })
  }

  return (
    <div>
      <Stack marginY={2} direction="row" alignItems="center">
        <ThreatsFilter
          possibleFilter={possibleFilters}
          selectedFilters={selectedFilter}
          onSelectFilter={handleSelectFilter}
        />
        <ExportAsCsv
          data={prepareThreatsDataForCsv(volumesData)}
          headers={THREATS_CSV_TABLE_HEADERS}
          filename="detected-threats.csv"
          shouldShow={
            volumesData.filter((data) => data.threats.length).length > 0
          }
          marginBottom={0}
        />
      </Stack>
      <Table
        data={volumesData}
        columns={getVolumesWithThreatsTableColumnDefinition()}
        generalProps={{
          isLoading: false,
          noDataMessage: computeNoDataMessageForTable(
            threats.length,
            scans.length
          ),
        }}
        advancedProps={{
          renderSubComponent: (props: { row: Row<DetailsVolumeOutput> }) => (
            <SubTableForVolumeWithThreats
              row={props.row}
              selectedFilter={selectedFilter}
              fetchDataThreats={fetchDataThreats}
            />
          ),
        }}
      />
    </div>
  )
}

export default VolumesWithThreats
