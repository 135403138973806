import { DateRangeTuple, ValueInterface } from '../engine-types'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ScheduleInterface from '../models/settings/policies/schedule'
/* eslint-disable import/no-cycle */
import {
  currentPlusHours,
  momentNow,
  momentToTimestamp,
} from '../helpers/time.helper'
import FrequencyConstant from './frequency.constant'
import DayOfWeekConstant from './day-of-week.constant'
import TimeFormatConstants from './time-format.constant'
import { ScanWithTimestampsInterface } from './policies.constant'

dayjs.extend(utc)
dayjs.extend(timezone)

export const EMPTY_DATE_RANGE = (): DateRangeTuple => [null, null]
export const EMPTY_REPORT_PERIOD = (): ValueInterface => ({ name: '' })

export const DEFAULT_TIME_ZONE = () => {
  const value = dayjs.tz.guess() // Guess the user's timezone
  return {
    value,
  }
}
export const EMPTY_SCHEDULE = (): ScheduleInterface => {
  const dayTodayPlusHour = currentPlusHours(1)

  return {
    frequency: FrequencyConstant.FREQUENCY_DAILY,
    dayOfMonth: -1,
    dayOfWeek: DayOfWeekConstant.DAY_SUNDAY,
    windowStart: {
      timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
      hour: momentNow().startOf('day').hour(),
      minute: 0,
    },
    windowEnd: {
      timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
      hour: momentNow().endOf('day').hour(),
      minute: 55,
    },
    daysList: {
      monday: true,
      thursday: true,
      wednesday: true,
      sunday: true,
      friday: true,
      saturday: true,
      tuesday: true,
    },
    firstRun: {
      runNow: true,
      runOn: {
        day: momentToTimestamp(dayTodayPlusHour),
        time: {
          timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
          hour: dayTodayPlusHour.hour(),
          minute: dayTodayPlusHour.minute(),
        },
      },
    },
  }
}
export const EMPTY_SCAN_WITH_TIMESTAMPS = (): ScanWithTimestampsInterface => {
  const dayTodayPlusHour = currentPlusHours(1)

  return {
    isScanObjects: false,
    dateTimeData: {
      day: momentToTimestamp(dayTodayPlusHour),
      time: {
        timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
        hour: dayTodayPlusHour.hour(),
        minute: dayTodayPlusHour.minute(),
      },
    },
  }
}
