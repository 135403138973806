/* eslint-disable import/no-extraneous-dependencies */
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import { EC2, EBS, EFS, S3Bucket, GenericHost } from 'blues-corejs/dist'

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function computeAssetTitle(asset: Asset) {
  if (asset instanceof EBS) {
    return 'Volume ID'
  }

  if (asset instanceof EC2) {
    return 'Instance ID'
  }

  if (asset instanceof S3Bucket) {
    return 'Bucket name'
  }

  if (asset instanceof EFS) {
    return 'Name'
  }

  if (asset instanceof GenericHost) {
    return 'Host name'
  }

  sentryReThrowCatchHandler('computeAssetIdTitle: Unknown asset type')
  throw new Error('computeAssetIdTitle: Unknown asset type')
}
