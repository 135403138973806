/* eslint-disable import/no-extraneous-dependencies */
import { DateRangeTuple, ValueInterface } from 'ui-v2/src/lib/engine-types'
import { ReportGenerated } from 'blues-corejs/dist/models/reports/report-generated/report-generated'
import { ReportGeneratedStatus } from 'blues-corejs/dist/models/reports/report-generated/status'
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule/report-schedule'
import { Dayjs } from 'dayjs'
import { ReactNode } from 'react'

export enum TabName {
  overview = 'overview',
  schedules = 'schedules',
  history = 'history',
}

export interface TabConfiguration {
  label: string
  component: ReactNode
  tabName: TabName
}

export enum AWSReports {
  awsExecutiveReport = 'awsExecutiveReport',
  awsAccountSummaryReport = 'awsAccountSummaryReport',
  awsTenantSummaryReport = 'awsTenantSummaryReport',
  awsCompletedScansReport = 'awsCompletedScansReport',
  awsScannedAssetsSummaryReport = 'awsScannedAssetsSummaryReport',
  infectedFilesReport = 'infectedFilesReport',
  failedScansReport = 'failedScansReport',
  awsInfectedAssetsReport = 'awsInfectedAssetsReport',
}
export enum BackupConnectorReports {
  ovaMspOperationalReport = 'ovaMspOperationalReport',
  ovaMspExecutiveReport = 'ovaMspExecutiveReport',
  ovaMspTenantSummaryReport = 'ovaMspTenantSummaryReport',
  ovaMspAccountSummaryReport = 'ovaMspAccountSummaryReport',
  ovaMspCompletedScansReport = 'ovaMspCompletedScansReport',
  ovaMspScannedAssetsSummaryReport = 'ovaMspScannedAssetsSummaryReport',
  ovaMspInfectedAssetsReport = 'ovaMspInfectedAssetsReport',
  ovaMspInfectedFilesReport = 'ovaMspInfectedFilesReport',
  ovaMspFailedScansReport = 'ovaMspFailedScansReport',
}

export enum AWSReportsSlug {
  awsExecutiveReport = 'aws-executive-report',
  awsAccountSummaryReport = 'aws-account-summary-report',
  awsTenantSummaryReport = 'aws-tenant-summary-report',
  awsCompletedScansReport = 'aws-completed-scans-report',
  awsScannedAssetsSummaryReport = 'aws-scanned-assets-summary-report',
  infectedFilesReport = 'infected-files-report',
  failedScansReport = 'failed-scans-report',
  awsInfectedAssetsReport = 'aws-infected-assets-report',
}

export enum BackupConnectorReportsSlug {
  ovaMspOperationalReport = 'ova-msp-operational-report',
  ovaMspExecutiveReport = 'ova-msp-executive-report',
  ovaMspTenantSummaryReport = 'ova-msp-tenant-summary-report',
  ovaMspAccountSummaryReport = 'ova-msp-account-summary-report',
  ovaMspCompletedScansReport = 'ova-msp-completed-scans-report',
  ovaMspScannedAssetsSummaryReport = 'ova-msp-scanned-assets-summary-report',
  ovaMspInfectedAssetsReport = 'ova-msp-infected-assets-report',
  ovaMspInfectedFilesReport = 'ova-msp-infected-files-report',
  ovaMspFailedScansReport = 'ova-msp-failed-scans-report',
}

export enum FormOptionValue {
  ON_DEMAND = 'OnDemand',
  SCHEDULE = 'Schedule',
}
export enum FormOptionValueSlug {
  ON_DEMAND = 'on-demand',
  SCHEDULE = 'schedule',
}
export interface FormOption {
  value: FormOptionValue
  label: string
}
export enum FormVariant {
  AWS = 'AWS',
  BackupConnector = 'BackupConnector',
}
export interface ReportPeriodInterface {
  period: ValueInterface
  dateRange: DateRangeTuple
}
export interface ReportOption {
  label: string
  value: string
  type: FormVariant
  formats: Array<string>
}

export interface ReportForm {
  reportType: string
  description: string
  reportPeriod: Option
  timeRange: Option
  dateRange: DateRangeTuple
  format: Array<string>
  ovaAccounts: Array<string>
  backupProvider: Array<string>
  name: string
  frequency: string
  scheduleStartDate: Date | null
  rangeOfData: string
  timezone: any
  recipients: Array<string>
  customSchedule: CustomScheduleState | null
}
export interface Option {
  label: string
  value: string | number
  name?: string
  extraValue?: string | number | boolean
}
export enum TimeRangeSelection {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_DATE = 'Custom date',
}
export enum FrequencyType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  CUSTOM = 'Custom',
}
export enum ReportPeriodValue {
  CURRENT_DAY = 'currentDay',
  LAST_DAY = 'lastDay',
  CURRENT_WEEK = 'currentWeek',
  LAST_WEEK = 'lastWeek',
  CURRENT_MONTH = 'currentMonth',
  LAST_MONTH = 'lastMonth',
  CURRENT_QUARTER = 'currentQuarter',
  LAST_QUARTER = 'lastQuarter',
  CURRENT_YEAR = 'currentYear',
  LAST_YEAR = 'lastYear',
}
export interface GenerateOnDemandReportCriteria {
  start: number
  end: number
  formats: Array<string>
  backupProvider: Array<number>
  ovaAccounts: Array<string>
  reportType: string
  description: string
}
export enum RepeatPeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
export enum Days {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}
export enum ReportEnd {
  NEVER = 'never',
  ON = 'on',
  AFTER = 'after',
}
export interface CustomScheduleState {
  everyValue: number
  periodValue: RepeatPeriod
  daysValue: string | null
  monthsValue: string
  endValue: ReportEnd
  dateValue: Dayjs | null
  occurrencesValue: number | null
}
export type OptionalReportKind = AWSReports | BackupConnectorReports
export interface ListGeneratedReportsRequestParams {
  textSearch?: string
  reportKind?: OptionalReportKind
  rangeStartAt?: number
  rangeEndAt?: number
  pageSize?: number
  pageToken?: string
  assetIdList?: Array<string>
}
export interface HistoryTableDataAttrs {
  generatedReports: Array<ReportGenerated>
}

export class HistoryTableRow {
  readonly #id: string

  readonly #scheduleName: string

  readonly #description: string

  readonly #reportType: string

  readonly #rangeOfData: string

  readonly #generated: string

  readonly #startDate: Date | undefined

  readonly #endDate: Date | undefined

  readonly #generatedDate: Date | undefined

  readonly #status: ReportGeneratedStatus

  readonly #action: string

  constructor(parameters: HistoryTableRowAttr) {
    const {
      id,
      scheduleName,
      description,
      reportType,
      startDate,
      endDate,
      generatedDate,
      status,
      rangeOfData,
      generated,
      action,
    } = parameters

    this.#id = id
    this.#scheduleName = scheduleName
    this.#description = description
    this.#reportType = reportType
    this.#rangeOfData = rangeOfData
    this.#generated = generated
    this.#startDate = startDate
    this.#endDate = endDate
    this.#generatedDate = generatedDate
    this.#status = status
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get scheduleName(): string {
    return this.#scheduleName
  }

  get description(): string {
    return this.#description
  }

  get reportType(): string {
    return this.#reportType
  }

  get startDate(): Date | undefined {
    return this.#startDate
  }

  get endDate(): Date | undefined {
    return this.#endDate
  }

  get generatedDate(): Date | undefined {
    return this.#generatedDate
  }

  get status(): ReportGeneratedStatus {
    return this.#status
  }

  get rangeOfData(): string {
    return this.#rangeOfData
  }

  get generated(): string {
    return this.#generated
  }

  get action(): string {
    return this.#action
  }
}
export interface HistoryTableRowAttr {
  id: string
  scheduleName: string
  description: string
  reportType: string
  startDate: Date | undefined
  endDate: Date | undefined
  generatedDate: Date | undefined
  status: ReportGeneratedStatus
  rangeOfData: string
  generated: string
  action: string
}

export interface ScheduleTableDataAttrs {
  scheduleReports: Array<ReportSchedule>
}

export class ScheduleTableRow {
  readonly #id: string

  readonly #name: string

  readonly #description: string

  readonly #reportTypeKey: string

  readonly #reportType: string

  readonly #frequency: string

  readonly #lastGeneratedDate: Date | undefined

  readonly #nextReportDueOnDate: Date | undefined

  readonly #action: string

  constructor(parameters: ScheduleTableRowAttr) {
    const {
      id,
      name,
      description,
      reportTypeKey,
      reportType,
      frequency,
      lastGeneratedDate,
      nextReportDueOnDate,
      action,
    } = parameters

    this.#id = id
    this.#name = name
    this.#description = description
    this.#reportTypeKey = reportTypeKey
    this.#reportType = reportType
    this.#frequency = frequency
    this.#lastGeneratedDate = lastGeneratedDate
    this.#nextReportDueOnDate = nextReportDueOnDate
    this.#action = action
  }

  get id(): string {
    return this.#id
  }

  get name(): string {
    return this.#name
  }

  get description(): string {
    return this.#description
  }

  get reportTypeKey(): string {
    return this.#reportTypeKey
  }

  get reportType(): string {
    return this.#reportType
  }

  get frequency(): string {
    return this.#frequency
  }

  get lastGeneratedDate(): Date | undefined {
    return this.#lastGeneratedDate
  }

  get nextReportDueOnDate(): Date | undefined {
    return this.#nextReportDueOnDate
  }

  get action(): string {
    return this.#action
  }
}
export interface ScheduleTableRowAttr {
  id: string
  name: string
  description: string
  reportTypeKey: string
  reportType: string
  frequency: string
  lastGeneratedDate: Date | undefined
  nextReportDueOnDate: Date | undefined
  action: string
}
