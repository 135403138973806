"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfile = exports.RoleType = void 0;
var RoleType;
(function (RoleType) {
    RoleType[RoleType["DEFAULT"] = 0] = "DEFAULT";
    RoleType[RoleType["CUSTOM"] = 1] = "CUSTOM";
})(RoleType || (exports.RoleType = RoleType = {}));
class UserProfile {
    constructor(args) {
        this.#id = args.id;
        this.#email = args.email;
        this.#locale = args.locale;
        this.#name = args.name;
        this.#nickname = args.nickname;
        this.#picture = args.picture;
        this.#status = args.status;
        this.#lastSeen = args.lastSeen;
        this.#role = args.role;
        this.#phoneNumber = args.phoneNumber;
    }
    get id() {
        return this.#id;
    }
    get email() {
        return this.#email;
    }
    get locale() {
        return this.#locale;
    }
    get name() {
        return this.#name;
    }
    get nickname() {
        return this.#nickname;
    }
    get picture() {
        return this.#picture;
    }
    get status() {
        return this.#status;
    }
    get lastSeen() {
        return this.#lastSeen;
    }
    get role() {
        return this.#role;
    }
    get phoneNumber() {
        return this.#phoneNumber;
    }
    #id;
    #email;
    #locale;
    #name;
    #nickname;
    #picture;
    #status;
    #lastSeen;
    #role;
    #phoneNumber;
}
exports.UserProfile = UserProfile;
