/* eslint-disable import/no-extraneous-dependencies */
import { JobKindConstant } from 'blues-corejs/dist/models/job.model/job-kind'
import {
  JobKind,
  JobStatusPagination,
  JobStatusPromiseClient,
  ListJobsFilters,
  ListJobsRequest,
} from '../grpc'
import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'

export class JobStatusClient extends GrpcClient<JobStatusPromiseClient> {
  #jobStatusClient: JobStatusPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#jobStatusClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'JobStatusClient'
  }

  protected initClient(hostName = ''): JobStatusPromiseClient {
    return new JobStatusPromiseClient(hostName, null, null)
  }

  async listJobs() {
    const request = new ListJobsRequest()

    const pagination = new JobStatusPagination()
    pagination.setOffset(0)
    pagination.setLimit(1)
    request.setPagination(pagination)

    // build filters
    const requestFilters = new ListJobsFilters()

    requestFilters.setKindsList([
      JobKindConstant.INSTALL_CLOUD_CONNECTOR as unknown as JobKind,
    ])

    request.setFilters(requestFilters)

    const response = await this.callGrpcService(
      () => this.#jobStatusClient.listJobs(request, this.metadata(this.#token)),
      {
        requestName: 'JobStatusClient/listJobs',
      }
    )

    return response.toObject().jobsList
  }
}
