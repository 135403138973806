import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'

import {
  CellLongTextWithTooltip,
  CellWithDateTimeTooltip,
  CellWithLink,
  ColumnWidth,
  CopyCell,
  createColumn,
  StatusCircle,
} from '@components/table'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Backup,
  EC2State,
  InstancesOutput,
  Nullable,
  Threat,
} from 'blues-corejs/dist'
import LangHelper from '@lib/helpers/lang.helper'
import StrHelper from '@lib/helpers/str.helper'

import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { ASSETS_THREATS_TABLE_MIN_HEIGHT } from './consts'
import { AssetRoutes } from '@lib/router'

const columnHelper = createColumnHelper<InstancesOutput>()

type InputData = {
  aliasNamesWithId: AliasName
}

function getStatusDummyColumn() {
  return columnHelper.display({
    id: 'ec2Status',
    cell: () => <StatusCircle isHealthy={false} />,
  })
}

function getInstanceIDColumn() {
  return createColumn<InstancesOutput>('instanceId', {
    header: 'Instance',
    meta: {
      height: ASSETS_THREATS_TABLE_MIN_HEIGHT,
    },
    sortingFn: (a, b) => {
      return (
        a.original.instanceName || a.original.instanceAwsId
      ).localeCompare(b.original.instanceName || b.original.instanceAwsId)
    },
    cell: ({ getValue, row }) => {
      const { instanceName, instanceAwsId } = row.original
      const instanceId = getValue() as unknown as string
      const assetPageUrl = AssetRoutes.assetId(instanceId)

      const displayedValue = instanceName || instanceAwsId
      const displayedLabel = instanceName ? instanceAwsId : instanceName
      return (
        <CellWithLink
          href={assetPageUrl}
          value={displayedValue}
          label={displayedLabel}
        />
      )
    },
  })
}

function getThreatsColumn() {
  return createColumn<InstancesOutput>('threats', {
    header: 'Threats',
    cell: ({ getValue }) => {
      const threats = getValue() as unknown as Array<Threat>
      const threatNames: Set<string> = new Set()
      threats.map((threat) => {
        threatNames.add(threat.name)
      })

      return (
        <CellLongTextWithTooltip
          title={[...threatNames].join(', ')}
          maxLength={60}
        />
      )
    },
    meta: {
      size: ColumnWidth.LARGE,
    },
  })
}

function getAccountIdColumn(aliasNamesWithId: AliasName) {
  return createColumn<InstancesOutput>('accountId', {
    header: 'Account',
    cell: ({ getValue }) => {
      const className = aliasNamesWithId[getValue()] ? 'copyRow' : ''

      return (
        <CopyCell
          additionalText={aliasNamesWithId[getValue()]}
          copyText={getValue()}
          className={className}
        />
      )
    },
  })
}

function getRegionColumn() {
  return createColumn<InstancesOutput>('region', {
    header: 'Region',
    cell: ({ getValue }) => {
      return <>{LangHelper.getAwsRegionSingleTranslation(getValue())}</>
    },
  })
}

function getOSTypeColumn() {
  return createColumn<InstancesOutput>('osKind', {
    header: 'OS Type',
  })
}

function getAttachedVolumesColumn() {
  return createColumn<InstancesOutput>('attachedVolumes', {
    header: 'Attached volumes',
  })
}

function getStatusColumn() {
  return createColumn<InstancesOutput>('status', {
    header: 'Status',
    cell: ({ getValue }) => {
      return StrHelper.getEc2StateName(getValue() as unknown as EC2State)
    },
  })
}

function getLastScanColumn() {
  return createColumn<InstancesOutput>('lastScan', {
    header: 'Last scan',
    cell: ({ getValue }) => {
      const lastScan = getValue() as unknown as Nullable<Date>

      return <CellWithDateTimeTooltip date={lastScan} />
    },
  })
}

function getLastBackupColumn() {
  return createColumn<InstancesOutput>('lastBackup', {
    header: 'Last Backup',
    cell: ({ getValue }) => {
      const lastBackup = getValue() as unknown as Nullable<{ backup: Backup }>

      if (!lastBackup?.backup) {
        return null
      }

      return <CellWithDateTimeTooltip date={lastBackup.backup.timestamp} />
    },
  })
}

export class InstanceColumns {
  static getColumns({ aliasNamesWithId }: InputData) {
    return [
      getStatusDummyColumn(),
      getInstanceIDColumn(),
      getThreatsColumn(),
      getAccountIdColumn(aliasNamesWithId),
      getRegionColumn(),
      getLastScanColumn(),
      getLastBackupColumn(),
      getAttachedVolumesColumn(),
      getStatusColumn(),
      getOSTypeColumn(),
    ]
  }
}
