import Checkbox from 'ui-v2/src/components/ui/inputs/checkbox'
import { Stack, Typography, FormControlLabel } from '@mui/material'
import { ChangeEvent } from 'react'

const ProtectionOptionsCheckbox = ({
  label,
  description,
  protectionOptionValue,
  disabled,
  onProtectionOptionChange,
}: ProtectionOptionsCheckboxProps) => {
  return (
    <FormControlLabel
      sx={{
        alignItems: 'flex-start',
        marginLeft: 0,
        marginRight: 0,
        '& .MuiButtonBase-root': {
          padding: '0 10px',
        },
      }}
      control={
        <Checkbox
          checked={protectionOptionValue}
          disabled={disabled}
          onChange={onProtectionOptionChange}
        />
      }
      label={
        <Stack spacing={1}>
          <Typography color="text.secondary" variant="body2" fontWeight={600}>
            {label}
          </Typography>
          {!!description && (
            <Typography color="text.secondary" variant="body2" fontWeight={400}>
              {description}
            </Typography>
          )}
        </Stack>
      }
    />
  )
}
interface ProtectionOptionsCheckboxProps {
  label: string
  description?: string
  protectionOptionValue: boolean
  disabled?: boolean
  onProtectionOptionChange: (e: ChangeEvent<HTMLInputElement>) => void
}
export default ProtectionOptionsCheckbox
