/* eslint-disable import/no-extraneous-dependencies */
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'
import { useInfoModalStore } from '@features/alerts/store/alerts-info-modal-store'
import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  rule: NotificationRule
}

function AlertNameCell({ rule }: Props) {
  const { setRule, setOpen } = useInfoModalStore()
  const { name } = rule

  const handleOpen = () => {
    setRule(rule)
    setOpen(true)
  }
  return (
    <Typography
      onClick={handleOpen}
      variant="caption"
      fontSize="14px"
      sx={{
        color: '#0B97D8',
        cursor: 'pointer',
      }}
    >
      {name}
    </Typography>
  )
}

export default AlertNameCell
