/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Asset, GenericHost, OvaServer } from 'blues-corejs/dist'
import { TimeFormatConstants } from '@lib/constants'
import { DetectedAnomalyTable } from '@features/entropy-trends/components/entropy-trends-table/entropy-trends-columns-definition'

dayjs.extend(utc)

function getAssetName(asset?: Asset) {
  if (!asset || asset instanceof GenericHost || asset instanceof OvaServer) {
    return ''
  }

  return asset.name
}

function getAssetId(asset?: Asset) {
  if (!asset || asset instanceof GenericHost || asset instanceof OvaServer) {
    return ''
  }

  return asset.awsId
}

function getFirstDetectedAt(firstDetectedAt: Date) {
  return `${dayjs
    .utc(firstDetectedAt)
    .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
}

export function prepareDataForCsv({
  data,
  assetsList,
}: {
  data: Array<DetectedAnomalyTable>
  assetsList: Array<Asset>
}) {
  return data.map((row) => ({
    assetName: getAssetName(
      assetsList.find((asset) => asset.id === row.assetId)
    ),
    assetId: getAssetId(assetsList.find((asset) => asset.id === row.assetId)),
    firstDetectedAt: getFirstDetectedAt(row.detectedAt),
    filesWithEntropy: row.numberOfFindings,
  }))
}
export const ENTROPY_TRENDS_CSV_TABLE_HEADERS = [
  {
    label: 'Asset Name',
    key: 'assetName',
  },
  {
    label: 'Asset ID',
    key: 'assetId',
  },
  {
    label: 'First Detected',
    key: 'firstDetectedAt',
  },
  {
    label: 'Files with high entropy',
    key: 'filesWithEntropy',
  },
]
