import { EngineCallback, VIMatrix, VIRow } from '@lib/engine-types'
import { useEffect, useState } from 'react'
import CheckboxConstant from '@lib/constants/checkbox.constant'
import ValueHelper from '@lib/helpers/value.helper'
import ObjHelper from '@lib/helpers/obj.helper'

function determineGlobalCheckbox(data: VIMatrix): CheckboxConstant {
  let empty = 0
  let checked = 0

  data.forEach((row: VIRow) => {
    if (row[0]?.value) {
      checked += 1
    } else {
      empty += 1
    }
  })

  if (checked > 0 && empty > 0) {
    return CheckboxConstant.Indeterminate
  }

  if (checked > 0) {
    return CheckboxConstant.Checked
  }

  return CheckboxConstant.Empty
}

interface UseLocalCheckboxTableResult {
  currentData: VIMatrix
  currentSelectedData: VIMatrix
  globalCheckboxState: CheckboxConstant
  setGlobalCheckbox: EngineCallback<boolean>
  setCheckbox: EngineCallback<VIRow>
}

function useLocalCheckboxTable(data: VIMatrix): UseLocalCheckboxTableResult {
  const [baseData, setBaseData] = useState<VIMatrix>([])
  const [currentData, setCurrentData] = useState<VIMatrix>([])
  const [globalCheckboxState, setGlobalCheckboxState] =
    useState<CheckboxConstant>(CheckboxConstant.Empty)

  useEffect(() => {
    // if do not check - it provokes re-rendering and recalculating the data on the
    // top component, they will have different references with the same data and
    // it leads to the infinite re-rendering
    if (ObjHelper.isNotEqual(data, baseData)) {
      setBaseData(data)
      setCurrentData(data)
    }
  }, [data])

  useEffect(() => {
    const newGlobalState = determineGlobalCheckbox(currentData)
    setGlobalCheckboxState(newGlobalState)
  }, [currentData])

  const setGlobalCheckboxStateFunc = (value: boolean) => {
    const newCurrentData = ValueHelper.cloneVIMatrix(currentData)
    newCurrentData.forEach((row) => {
      const firstElem = row[0]
      if (firstElem) {
        firstElem.value = value
      }
    })
    setCurrentData(newCurrentData)
  }

  const setCheckbox = (value: VIRow) => {
    const foundIndex = currentData.findIndex(
      (v) => v[0]?.name === value[0]?.name
    )
    if (foundIndex === -1) {
      return
    }
    const newCurrentData = ValueHelper.cloneVIMatrix(currentData)
    newCurrentData[foundIndex] = value
    setCurrentData(newCurrentData)
  }

  return {
    currentData,
    currentSelectedData: currentData.filter((v) => v[0]?.value),
    globalCheckboxState,
    setGlobalCheckbox: setGlobalCheckboxStateFunc,
    setCheckbox,
  }
}

export default useLocalCheckboxTable
