/* eslint-disable import/no-extraneous-dependencies */
import { useAssetsListBackupsQuery } from 'ui-v2/src/hooks/queries/backups'
import {
  Asset,
  AWSBRecoveryPoint,
  Backup,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'
import { useMemo } from 'react'
import BackupProvider from './infected-assets/backup-provider'

function computeBackupId(backup: Backup) {
  if (backup instanceof ElastioRecoveryPoint) {
    return backup.ccRpId
  } else if (backup instanceof AWSBRecoveryPoint) {
    return backup.arn
  } else if (backup instanceof EBSSnapshot) {
    return backup.arn
  } else if (backup instanceof OvaBackup) {
    return backup.backupProviderBackupId
  }
}

const InfectedBackupProvider = ({ asset }: InfectedBackupProviderProps) => {
  const { data: backupData } = useAssetsListBackupsQuery({
    assetIdList: asset ? [asset.id] : [],
  })

  const lastBackupData = useMemo(() => {
    if (!backupData) {
      return []
    }

    const backupsList = [
      ...backupData.awsbRpsList,
      ...backupData.elastioRpsList,
      ...backupData.ebsSnapshotsList,
      ...backupData.ovaBackupList,
    ]

    if (backupsList.length === 0) {
      return []
    }

    return backupsList.reduce((previousBackup, currentBackup) =>
      previousBackup.timestamp > currentBackup.timestamp
        ? previousBackup
        : currentBackup
    )
  }, [backupData])

  const computedBackupId = useMemo(() => {
    if (!lastBackupData) {
      return
    }
    return computeBackupId(lastBackupData as Backup)
  }, [lastBackupData])

  return (
    <>
      {!!computedBackupId && (
        <BackupProvider computedBackupId={computedBackupId} />
      )}
    </>
  )
}
interface InfectedBackupProviderProps {
  asset: Asset
}
export default InfectedBackupProvider
