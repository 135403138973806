import React from 'react'
import { ConfigurationVulnerabilities } from '@features/DashboardV4/ConfigurationVulnerabilities'
import { ThreatsWrapper } from '@features/DashboardV4/threats-widgets'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import { ScansAndTests } from '@features/DashboardV4/ScansAndTests'
import { EntropyTrends } from '@features/DashboardV4/entropy-trends'

export function OverviewLeftColumnWithEntropyTrends() {
  return (
    <Grid
      container
      item
      spacing={1}
      padding={2}
      bgcolor="white"
      md={12}
      lg={7.7}
      marginRight="auto"
      gap={3}
    >
      <Grid item container xs={12}>
        <Grid item xs={5.5}>
          <ThreatsWrapper />
        </Grid>
        <Grid item container xs={1} justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.5}>
          <ScansAndTests />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <EntropyTrends />
      </Grid>

      <Grid item xs={12} md={12}>
        <ConfigurationVulnerabilities />
      </Grid>
    </Grid>
  )
}
