import { BaseRoutes } from '../base'

export enum SettingsTab {
  GENERAL = 0,
  MEMBERS = 1,
  ALERTS = 2,
  WEBHOOKS = 3,
}

export class SettingsRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_SETTINGS
  }

  public static get generalTab(): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: { t: SettingsTab.GENERAL },
    })
  }

  public static get membersTab(): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: { t: SettingsTab.MEMBERS },
    })
  }

  public static get alertsTab(): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: { t: SettingsTab.ALERTS },
    })
  }

  public static get webhooksTab(): string {
    const basePath = this.root
    return this.buildRouteWithQuery({
      basePath,
      params: { t: SettingsTab.WEBHOOKS },
    })
  }

  static readonly #ROUTE_SETTINGS = '/settings'
}
