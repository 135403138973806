import React from 'react'

function FilterIcon() {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5.17442H10V3.82558H2V5.17442ZM0 0.453491V1.80233H12V0.453491H0ZM4.66667 8.54651H7.33333V7.19768H4.66667V8.54651Z"
        fill="white"
      />
    </svg>
  )
}

export default FilterIcon
