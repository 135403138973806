import { ObjHelper } from '@lib/helpers'
import { useEffect } from 'react'
import { useCloudConnectorJobsFiltersActions } from '@features/jobs/cloud-connector-jobs/stores/filters-store/selectors'
import { useCloudConnectorJobsActions } from '@features/jobs/cloud-connector-jobs/stores/cloud-connector-jobs-store/selectors'
import { useCloudConnectorJobsFiltersStore } from '@features/jobs/cloud-connector-jobs/stores/filters-store/cloud-connector-jobs-filters-store'

export const useFiltersSubscription = () => {
  const { fetchInitialCloudConnectorJobs } = useCloudConnectorJobsActions()
  const { fetchPossibleCloudConnectorJobFilters } =
    useCloudConnectorJobsFiltersActions()

  useEffect(() => {
    const unsubscribeFn = useCloudConnectorJobsFiltersStore.subscribe(
      (state) => ({
        selectedFilters: state.selectedFilters,
        timeRange: state.timeRange,
      }),
      ({ selectedFilters, timeRange }) => {
        fetchInitialCloudConnectorJobs({
          selectedFilters,
          timeRange,
        })
      },
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])

  useEffect(() => {
    const unsubscribeFn = useCloudConnectorJobsFiltersStore.subscribe(
      (state) => state.timeRange,
      () => fetchPossibleCloudConnectorJobFilters(),
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])
}
