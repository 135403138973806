import { useState, useEffect } from 'react'
import { ReportSchedulesClient } from '@lib/clients/reports'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule'
import { useTabStore } from '../use-data-store'

const reportSchedulesClient = new ReportSchedulesClient()

function useListReportSchedules() {
  const [data, setData] = useState<Array<ReportSchedule>>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  const { isRevalidate, toggleRevalidate } = useTabStore((state) => ({
    isRevalidate: state.isRevalidate,
    toggleRevalidate: state.actions.toggleRevalidate,
  }))

  useEffect(() => {
    let isMounted = true

    async function fetchReportSchedules() {
      setLoading(true)
      try {
        const { listReportSchedules } =
          await reportSchedulesClient.listReportSchedules()
        if (isMounted) {
          setData(listReportSchedules)
          setLoading(false)
          toggleRevalidate(false)
        }
      } catch (err) {
        if (isMounted) {
          setError(err as Error)
          setLoading(false)
        }
      }
    }

    fetchReportSchedules()

    return () => {
      isMounted = false
    }
  }, [isRevalidate]) // Trigger re-fetch when isRevalidate changes

  return {
    data,
    loading,
    error,
  }
}

export { useListReportSchedules }
