import React from 'react'
import DateRangeSelect from '@components-composite/date-range-select/DateRangeSelect'
import styles from './index.module.css'
import { DateRangeTuple, EngineCallback } from '@lib/engine-types'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import moment from 'moment/moment'
import { QuestionHint } from '@components-simple/question-hint'
import Stack from '@mui/material/Stack'

interface Props {
  onDateChange: EngineCallback<DateRangeTuple>
  dateRange: DateRangeTuple
  disabled: boolean
}

export function EntropyTrendDateFilter({
  onDateChange,
  dateRange,
  disabled,
}: Props) {
  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()

  const minMaxRange: DateRangeTuple = [
    moment().tz(timeZone.value).subtract(1, 'month').startOf('month'),
    moment().endOf('day').tz(timeZone.value),
  ]

  const tooltipMessage = disabled
    ? 'You can apply custom date range only if you selected custom date'
    : ''

  return (
    <Stack direction="row" alignItems="flex-start">
      <DateRangeSelect
        className={styles.root}
        endLabel=""
        label=""
        onChange={onDateChange}
        value={dateRange}
        disabled={disabled}
        minMaxRange={minMaxRange}
      />
      <QuestionHint text={tooltipMessage} />
    </Stack>
  )
}
