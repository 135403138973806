import { decode as base64DecodeLib, encode as base64EncodeLib } from 'js-base64'

type ParamValue = string | number | boolean | null | undefined
type Params = Record<string, ParamValue>

interface BuildRouteWithQuery<K extends keyof Params> {
  basePath: string
  params: Params
  encodeParams?: Array<K>
}

export class BaseRoutesError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'BaseRoutesError'
  }
}

export abstract class BaseRoutes {
  protected static buildRouteWithQuery<K extends keyof Params>({
    basePath,
    params,
    encodeParams = [],
  }: BuildRouteWithQuery<K>): string {
    const queryParams: Record<string, string> = {}

    for (const [key, value] of Object.entries(params)) {
      if (!this.isNullOrUndefined(value)) {
        const stringValue = String(value)
        queryParams[key] = encodeParams.includes(key as K)
          ? this.base64Encode(stringValue)
          : stringValue
      }
    }

    const queryString = new URLSearchParams(queryParams).toString()
    return queryString ? `${basePath}?${queryString}` : basePath
  }

  protected static base64Encode(str: string): string {
    if (this.isNullOrUndefined(str)) {
      throw new BaseRoutesError(
        'Input to base64Encode cannot be null or undefined.'
      )
    }
    return base64EncodeLib(str)
  }

  protected static base64Decode(str: string): string {
    if (this.isNullOrUndefined(str)) {
      throw new BaseRoutesError(
        'Input to base64Decode cannot be null or undefined.'
      )
    }
    return base64DecodeLib(str)
  }

  protected static decodeQueryParams(
    queryString: string,
    decodeParams: Array<string> = []
  ): Record<string, string> {
    const params: Record<string, string> = {}
    const urlSearchParams = new URLSearchParams(queryString)

    for (const [key, value] of urlSearchParams.entries()) {
      params[key] = decodeParams.includes(key)
        ? this.base64Decode(value)
        : value
    }

    return params
  }

  private static isNullOrUndefined(value: unknown): value is null | undefined {
    return value == null
  }
}
