import { useEffect } from 'react'

function useLoadScript(src: string) {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

export default useLoadScript
