import { PolicyListTableRow } from 'ui-v2/src/lib/models/settings/policies/policies'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckIcon from '@mui/icons-material/Check'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import PauseCircleOutlined from '@mui/icons-material/PauseCircleOutlined'
import DeleteIcon from 'ui-v2/src/assets/icons/delete-icon'
import {
  useDeletePlanMutation,
  useExecutePlanMutation,
  usePausePlanMutation,
  useResumePlanMutation,
} from 'ui-v2/src/hooks/queries/pechkin'
import { useToast } from 'ui-v2/src/hooks/toast'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { base64Encode } from 'ui-v2/src/lib/helpers/string.helper'

const PolicyAction = ({ row }: PolicyActionProps) => {
  const navigate = useNavigate()
  const [policyId, setPolicyId] = useState<string>()
  const { mutate: resumePlanMutate, isPending: resumePlanIsPending } =
    useResumePlanMutation()
  const { mutate: pausePlanMutate, isPending: pausePlanIsPending } =
    usePausePlanMutation()
  const { mutate: deletePlanMutate, isPending: deletePlanIsPending } =
    useDeletePlanMutation()
  const { mutate: executePlanMutate, isPending: executePlanIsPending } =
    useExecutePlanMutation()

  const queryClient = useQueryClient()

  const toast = useToast()

  const handleEditPolicy = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.EDIT_POLICY}/${base64Encode(row.id)}`
    )
  }

  const handleResume = () => {
    resumePlanMutate(row.id, {
      onSuccess: () => {
        toast.success('The policy has been resumed')
        queryClient.invalidateQueries({
          queryKey: ['pechkin', 'list-plan'],
        })
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during policy resuming')
      },
    })
  }
  const handlePause = () => {
    pausePlanMutate(row.id, {
      onSuccess: () => {
        toast.success('The policy has been paused')
        queryClient.invalidateQueries({
          queryKey: ['pechkin', 'list-plan'],
        })
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during policy pausing')
      },
    })
  }
  const handleDelete = () => {
    if (!policyId) {
      return
    }
    setPolicyId(undefined)
    deletePlanMutate(policyId, {
      onSuccess: () => {
        toast.success('The policy has been removed')
        queryClient.invalidateQueries({
          queryKey: ['pechkin', 'list-plan'],
        })
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during policy removing')
      },
    })
  }
  const handleExecute = () => {
    executePlanMutate(row.id, {
      onSuccess: () => {
        toast.success('The policy has been executed')
        queryClient.invalidateQueries({
          queryKey: ['pechkin', 'list-plan'],
        })
      },
      onError: (error) => {
        toast.error(error?.message ?? 'Error during policy executing')
      },
    })
  }

  return (
    <Box textAlign={'right'}>
      {row.isPaused ? (
        <Tooltip title="Pause">
          <IconButton onClick={handlePause} disabled={pausePlanIsPending}>
            <PauseCircleOutlined />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Resume">
          <IconButton onClick={handleResume} disabled={resumePlanIsPending}>
            <PlayCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Edit" onClick={handleEditPolicy}>
        <IconButton>
          <EditOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => setPolicyId(row.id)}
          disabled={deletePlanIsPending}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Execute">
        <IconButton onClick={handleExecute} disabled={executePlanIsPending}>
          <CheckIcon />
        </IconButton>
      </Tooltip>
      <ConfirmationDialog
        open={!!policyId}
        title="Confirmation"
        onClose={() => setPolicyId(undefined)}
        onConfirm={handleDelete}
        confirmText="Yes, Proceed"
        cancelText="No, Cancel"
      >
        <Typography>
          You are going to remove the Policy new policy Please confirm the
          deletion.
        </Typography>
      </ConfirmationDialog>
    </Box>
  )
}
interface PolicyActionProps {
  row: PolicyListTableRow
}
export default PolicyAction
