import React, { memo, ReactNode } from 'react'
import clsx from 'clsx'

interface Props {
  labels: Array<string>
  children: Array<ReactNode>
  currentStep: number
}

function Stepper({ labels, currentStep, children }: Props) {
  return (
    <div className="wrap-1602858980181">
      <div className="labelsWrap">
        {labels.map((lbl, index) => (
          <div
            key={lbl}
            data-testid="stepLabel"
            className={clsx('stepLabel', { active: index === currentStep })}
          >
            {lbl}
          </div>
        ))}
      </div>
      {React.Children.map(children, (child, index) => (
        <div
          data-testid={`stepContent-${index}`}
          className="stepContent"
          hidden={index !== currentStep}
        >
          {index === currentStep && child}
        </div>
      ))}
    </div>
  )
}
export default memo(Stepper)
