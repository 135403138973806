import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import CloseIcon from '@mui/icons-material/Close'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { ScanSource } from '@features/asset-page/shared/scan-source'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
  Asset,
  Backup,
} from 'blues-corejs/dist'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import type { BackupById } from '@lib/interfaces/backup'
import { useFetchBackupsByIds } from '@lib/hooks/backups/use-fetch-backups-by-ids'
import { ScanReplicatedDetails } from './replicated-details'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck
type Props = {
  onModalClose: () => void
  readonly asset: Asset
  readonly scans: Array<Scan>
}

function getScanBackup(backups: Array<BackupById>): Array<Backup> {
  const result: Array<Backup> = []
  backups.forEach((backup) => {
    if (backup.awsbRp) {
      result.push(backup.awsbRp)
    }
    if (backup.ebsSnapshot) {
      result.push(backup.ebsSnapshot)
    }

    if (backup.elastioRp) {
      result.push(backup.elastioRp)
    }

    if (backup.ovaBackup) {
      result.push(backup.ovaBackup)
    }
  })

  return result
}

function RenderBackupScanSource({ backupId }: { backupId: string }) {
  const { isBackupsLoading, backupsList } = useFetchBackupsByIds([backupId])
  if (isBackupsLoading) {
    return <Skeleton width="100%" />
  }
  const backup = getScanBackup(backupsList)

  return <ScanSource backup={backup[0]} />
}

function RenderScanSource({ scan, asset }: { scan: Scan; asset: Asset }) {
  if (scan.targetIsBackup && 'backupId' in scan.scanTarget.target) {
    return <RenderBackupScanSource backupId={scan.scanTarget.target.backupId} />
  }

  return <ScanSource asset={asset} scan={scan} />
}

function renderAssetCredentials(
  asset: Asset,
  scan: MalwareScan | RansomwareScan | FilesystemScanCheck
) {
  return (
    <Box fontSize="14px" display="flex" alignItems="center">
      <DateTimeTooltip direction="row" date={scan.createdAt} />
      <Divider orientation="vertical" flexItem sx={{ marginX: '8px' }} />
      <RenderScanSource scan={scan} asset={asset} />
    </Box>
  )
}

function ScanDetailsHeader({ onModalClose, asset, scans }: Props) {
  const firstScan = scans[0]

  if (!firstScan) {
    sentryReThrowCatchHandler(
      'ScanDetailsHeader: scans[0] is not defined for asset'
    )
    throw new Error('ScanSourceRenderer: scans[0] is not defined')
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight="600" marginBottom="5px">
              Scan Details
            </Typography>
            <Box display="flex" alignItems="center" gap="5px">
              <IconButton onClick={onModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {renderAssetCredentials(asset, firstScan)}
        </Grid>
      </Grid>
      <ScanReplicatedDetails asset={asset} scan={firstScan} />
      <Divider
        orientation="horizontal"
        sx={{
          marginBlock: '15px',
        }}
      />
    </>
  )
}

export default ScanDetailsHeader
