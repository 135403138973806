import { PathRouteProps } from 'react-router-dom'
import { policiesRoutes } from './routes/policies/routes'
import { dashboardRoutes } from './routes/dashboard/routes'
import { deploymentRoutes } from './routes/deployment/routes'
import { accountAndSettingsRoutes } from './routes/account-and-settings'
import { assetRoutes } from './routes/asset'
import { jobsRoutes } from './routes/jobs'
import { sourcesRoutes } from './routes/sources'
import { managementViewRoutes } from './routes/management-view'
import { activeThreatsRoutes } from './routes/active-threats'
import { entropyTrendsRoutes } from './routes/entropy-trends'
import { onboardingRoutes } from './routes/onboarding'
import { vaultDetailedRoutes } from './routes/vault-detailed'
import { reportsRoutes } from '@lib/router/routes/reports/routes'
import { protectAssetsRoutes } from './routes/protect-assets'
import { alertsRoutes } from './routes/alerts'
import { webhookEditRoutes } from './routes/webhook-edit'
import { settingsRoutes } from '@lib/router/routes/settings/routes'

export const protectedRoutes: Array<PathRouteProps> = [
  ...dashboardRoutes,
  ...policiesRoutes,
  ...accountAndSettingsRoutes,
  ...assetRoutes,
  ...jobsRoutes,
  ...deploymentRoutes,
  ...sourcesRoutes,
  ...managementViewRoutes,
  ...activeThreatsRoutes,
  ...entropyTrendsRoutes,
  ...onboardingRoutes,
  ...vaultDetailedRoutes,
  ...reportsRoutes,
  ...protectAssetsRoutes,
  ...alertsRoutes,
  ...webhookEditRoutes,
  ...settingsRoutes,
]
