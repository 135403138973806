import { ReactNode, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { compareVersions } from 'compare-versions'
import { useSelector } from 'react-redux'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import {
  ENTROPY_DETECTION_CONSTANTS,
  MIN_VERSION_FOR_ENTROPY_DETECTION,
} from './consts'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'

function getHintText(
  isAvailableByVersion: boolean,
  isMalwareSelected: boolean
): ReactNode {
  if (!isAvailableByVersion) {
    return ENTROPY_DETECTION_CONSTANTS.DETECTION_NOT_AVAILABLE_BY_VERSION
  }

  if (isMalwareSelected) {
    return ENTROPY_DETECTION_CONSTANTS.DETECTION_NOT_AVAILABLE_BY_MALWARE
  }

  return ''
}

export function useEntropyDetectionOption(
  integrityScanOptions: IntegrityScanInterface
) {
  const [shouldUseEntropyDetection, setIssEntropyFeatureAvailable] =
    useState<boolean>(true)

  // We cannot use entropy detection if only malware detection is enabled
  const isOnlyMalwareSelected =
    integrityScanOptions.scanForMalware &&
    !integrityScanOptions.scanForRansomware

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)

  useDeepCompareEffect(() => {
    setIssEntropyFeatureAvailable(
      allActiveRedStacks.some(
        ({ version }) =>
          compareVersions(version, MIN_VERSION_FOR_ENTROPY_DETECTION) >= 0
      )
    )
  }, [allActiveRedStacks])

  return {
    isEntropyFeatureAvailable:
      shouldUseEntropyDetection && !isOnlyMalwareSelected,
    hintText: getHintText(shouldUseEntropyDetection, isOnlyMalwareSelected),
  }
}
