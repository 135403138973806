import React, { memo } from 'react'
import { EngineCallback } from '@lib/engine-types'
import { FormControl, TextField } from '@mui/material'

interface Props {
  aliasName: string
  description: string
  onAliasNameChange?: EngineCallback<string>
  onDescriptionChange?: EngineCallback<string>
  disabled?: boolean
}

function AliasDescriptionBlock({
  aliasName = '',
  description = '',
  onAliasNameChange = () => null,
  onDescriptionChange = () => null,
  disabled = false,
}: Props) {
  return (
    <div className="wrap-1603291045141">
      <div className="limitedForm formFullWidth oldInputs">
        <FormControl>
          <TextField
            id="aliasName"
            data-testid="jsAliasName"
            className="staticLabel jsAlias"
            label="Alias Name"
            name="aliasName"
            value={aliasName}
            disabled={disabled}
            onChange={(e) => onAliasNameChange(e.target.value)}
            variant="outlined"
          />
        </FormControl>
        <FormControl>
          <TextField
            id="aliasDescription"
            data-testid="jsAliasDesc"
            className="staticLabel jsDesc"
            label="Description"
            name="description"
            value={description}
            disabled={disabled}
            onChange={(e) => onDescriptionChange(e.target.value)}
            variant="outlined"
            rows={4}
            multiline
          />
        </FormControl>
      </div>
    </div>
  )
}

export default memo(AliasDescriptionBlock)
