import React, { useEffect } from 'react'
import { useNavigation } from '@lib/router'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { verifyUser } from '@store/actions/user.action'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'

export default function VerificationLoadingPage() {
  const router = useNavigation()
  const { url } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (url) {
      dispatch(verifyUser(String(url)))
    }
  }, [router])

  return (
    <div className="innerContent llContent">
      <div className="llTitle">Verification ...</div>
      <PreloaderBlock show />
    </div>
  )
}
