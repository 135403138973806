import { Controller, useFormContext } from 'react-hook-form'
import Calendar, {
  Props as ControllerOwnProps,
} from '@components-simple/calendar/Calendar'
import { minDate, userTimezone } from './timezone-helper'
import TimeFormatConstants from '@lib/constants/time-format.constant'

interface CalendarOwnProps extends Partial<ControllerOwnProps> {
  name: string
}

function CalendarController({ name, ...restProps }: CalendarOwnProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Calendar
            value={field.value}
            onChange={field.onChange}
            userTimezone={userTimezone}
            minDate={minDate}
            format={TimeFormatConstants.MONTH_DATE_YEAR}
            {...restProps}
          />
        )
      }}
    />
  )
}

export default CalendarController
