/* eslint-disable import/no-extraneous-dependencies */
import { MalwareScan } from 'blues-corejs/dist'
import { MalwareScanStatus } from 'blues-corejs/dist/models/scans/malware/types'
import { Nullable } from '@lib/engine-types'

export function getMalwareScanStatus(
  malwareScans: Array<MalwareScan>
): Nullable<MalwareScanStatus> {
  if (malwareScans.length === 0) {
    return null
  }

  return malwareScans.reduce((status: MalwareScanStatus, scan) => {
    if (scan.status === MalwareScanStatus.INFECTED) {
      return MalwareScanStatus.INFECTED
    }
    if (scan.status === MalwareScanStatus.CLEAN) {
      return MalwareScanStatus.CLEAN
    }
    return status
  }, MalwareScanStatus.CLEAN)
}
