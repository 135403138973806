/* eslint-disable import/no-extraneous-dependencies */
import { DetailsScans } from '@lib/clients/scans/list-scans-for-asset/list-scans-for-asset-client'
import { TimeRange } from '@lib/clients/types'
import {
  ListBackupsForAsset as ListBackupsForAssetType,
  ListBackupsForAssetItem,
} from '@lib/clients/backups/types'
import {
  ListLiveAssetsItemsRequestFilters,
  LiveAssetsItems as LiveAssetsItemsType,
} from '@lib/clients/assets-items/types'
import { Kind, ScanTargetKind, Status } from '@lib/clients/scans/types'
import {
  EfsItem,
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
  S3Item,
} from 'blues-corejs/dist'
import { ScansRun } from 'blues-corejs/dist/models'
import { JobCloudConnector } from 'blues-corejs/dist'
import { ListCloudConnnectorJobsFilterRequestParams } from '@lib/clients/jobs'

//FIXME: @polyakh tell this
export enum DataFetchStatus {
  IDLE = 'idle',
  IS_LOADING = 'isLoading',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

// TODO Remove this
export const PAGINATION_STATUS = {
  INITIAL: 'initial' as const,
  ACTIVE: 'active' as const,
  FINISHED: 'finished' as const,
}

export type PaginationStatus =
  (typeof PAGINATION_STATUS)[keyof typeof PAGINATION_STATUS]

export type Scans = {
  latestScans: Array<DetailsScans>
  allScans: Array<ScansRun>
}

export interface ScansForBackup {
  malwareScansForBackup: Array<MalwareScan>
  ransomWareScansForBackup: Array<RansomwareScan>
  fsCheckScansForBackup: Array<FilesystemScanCheck>
}

export const DEFAULT_SCANS_FOR_BACKUP: ScansForBackup = {
  malwareScansForBackup: [],
  ransomWareScansForBackup: [],
  fsCheckScansForBackup: [],
}

type RequestFilters = {
  assetIdList?: Array<string>
  providersList?: Array<any> // Request.Initial.Filter.Provider -> implement if we need it
  accountIdsList?: Array<string>
  regionsList?: Array<string>
  scanTargetKindsList?: Array<ScanTargetKind>
  humanReadableRecoveryPointIdsList?: Array<string>
  statusesList?: Array<Status>
  kindsList?: Array<Kind>
  timeRange?: TimeRange // Filter.TimeRange -> implement if we need it
}

export interface UseDataFetcher<Data, Filter = RequestFilters> {
  onFetchData: (filter?: Filter) => Promise<void>
  data: Data
  dataFetchStatus: DataFetchStatus
  resetData: () => void
  isLoadingData: () => boolean
  dataNew?: Data
}

export type UseAssetScansDataFetcher = UseDataFetcher<Scans>
export type UseAssetItemsScansDataFetcher = UseDataFetcher<Scans>
export type UseAssetBackupsDataFetcher = UseDataFetcher<ListBackupsForAssetType>
export type UseAssetItemsBackupsDataFetcher =
  UseDataFetcher<ListBackupsForAssetItem>
export type UseLiveAssetsItemsDataFetcher = UseDataFetcher<
  LiveAssetsItemsType,
  ListLiveAssetsItemsRequestFilters
>
export type UseS3ItemsDataFetcher = UseDataFetcher<Array<S3Item>>
export type UseEfsItemsDataFetcher = UseDataFetcher<Array<EfsItem>>

export type UseScansByFiltersDataFetcher = UseDataFetcher<Array<ScansRun>>
export type UseCloudConnectorJobsDataFetcher = UseDataFetcher<
  Array<JobCloudConnector>,
  ListCloudConnnectorJobsFilterRequestParams
>
