import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const InstanceIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const instanceIdList =
    searchParams.get(ASSET_FILTERS_MAP.INSTANCE_ID)?.split(',') || []

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const instanceIdOptions = useMemo(() => {
    if (!liveAssetsData) {
      return []
    }

    return liveAssetsData.ec2Instances.map((instance) => ({
      label: instance.awsId,
      value: instance.id,
    }))
  }, [liveAssetsData])

  const handleInstanceIdChange = (
    newInstanceIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newInstanceIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.INSTANCE_ID)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.INSTANCE_ID,
        newInstanceIdList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={instanceIdOptions}
      value={instanceIdList}
      onChange={handleInstanceIdChange}
      label="Instance ID"
      width={200}
    />
  )
}

export default InstanceIdFilter
