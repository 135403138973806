import React from 'react'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { TimeHelper } from '@lib/helpers'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props {
  time: Date
  files: number
  filesDifference: number | null
}

function formatTime(time: Date) {
  return TimeHelper.getDateWithTimezone(time.getTime()).format(
    TimeFormatConstants.SHORT_DATETIME_FORMAT
  )
}

function FilesDifference({
  filesDifference,
}: {
  filesDifference: number | null
}) {
  if (filesDifference === null || filesDifference === 0) {
    return null
  }

  const isIncreased = filesDifference > 0
  const pluralizedFiles = Math.abs(filesDifference) === 1 ? 'file' : 'files'

  return (
    <div className={styles.filesDifference}>
      <div
        className={clsx(styles.differenceCount, {
          [String(styles.increased)]: isIncreased,
        })}
      >
        {isIncreased && '+'} {filesDifference} {pluralizedFiles}
      </div>
      <div className={styles.differenceText}>since previous scan</div>
    </div>
  )
}

export function CustomTooltip({ time, files, filesDifference }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.date}>{formatTime(time)}</div>
      <div className={styles.filesWrapper}>
        <div className={styles.filesCount}>{files}</div>
        <div className={styles.filesText}>High entropy files</div>
      </div>
      <FilesDifference filesDifference={filesDifference} />
    </div>
  )
}
