/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import {
  AssetItem,
  AWSBRecoveryPoint,
  EBS,
  EBSSnapshot,
  EC2,
  EFS,
  ElastioRecoveryPoint,
  GenericHost,
  Nullable,
  S3Bucket,
  Asset,
} from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { computeAssetItemIcon } from './compute-asset-item-icon'
import { computeAssetItemName } from './compute-asset-item-name'
import SystemHelper from '@lib/helpers/system.helper'
import { RowTitle } from './row-title'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import RegionIcon from '@mui/icons-material/Place'
import LangHelper from '@lib/helpers/lang.helper'
import { AssetItemRecoveryPointsTable } from '@features/asset-page/tables'
import { RecoveryPointDetails } from '@features/asset-page/modals/recovery-point-details'
import { TimeRangeProvider } from '@components-context/time-range'

interface Props {
  asset: Asset
  assetItem: AssetItem
  isOpen: boolean
  onClose: () => void
}

function AssetItemDetailHeader({
  assetItem,
  onClose,
}: {
  assetItem: AssetItem
  onClose: () => void
}) {
  const icon = computeAssetItemIcon(assetItem)
  const name = computeAssetItemName(assetItem)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="16px"
      marginLeft="10px"
    >
      <Box display="flex" alignItems="center" gap="10px">
        {icon}
        <Typography fontSize="22px" fontWeight="600">
          {name}
        </Typography>
      </Box>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export function computeAssetTitle(asset: Asset) {
  if (asset instanceof EBS) {
    return 'Volume ID'
  }

  if (asset instanceof EC2) {
    return 'Instance ID'
  }

  if (asset instanceof S3Bucket) {
    return 'Bucket name'
  }

  if (asset instanceof EFS) {
    return 'Name'
  }

  if (asset instanceof GenericHost) {
    return 'Host name'
  }

  SystemHelper.sendSentryIfProd('computeAssetIdTitle: Unknown asset type')
  throw new Error('computeAssetIdTitle: Unknown asset type')
}

export function computeAssetId(asset: Asset) {
  if (asset instanceof GenericHost) {
    return asset.hostname
  } else if (asset instanceof AWSAsset) {
    return asset.name ? `${asset.name} (${asset.awsId})` : asset.awsId
  }
}

export function Region({ asset }: { asset: Asset }) {
  if (!(asset instanceof AWSAsset)) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" marginRight="60px">
      <RowTitle title="Region" />
      <RegionIcon color="disabled" fontSize="inherit" />
      <Typography
        fontSize="14px"
        fontWeight="400"
        variant="caption"
        marginLeft="5px"
      >
        {LangHelper.getAwsRegionSingleTranslation(asset.awsRegion)}
      </Typography>
    </Box>
  )
}

function AssetCredentials({ asset }: { asset: Asset }) {
  const title = computeAssetTitle(asset)
  const id = computeAssetId(asset)

  return (
    <Box display="flex" gap="24px">
      <Box display="flex">
        <RowTitle title={title} />
        <Typography
          fontSize="14px"
          fontWeight="400"
          variant="caption"
          marginLeft="5px"
        >
          {id}
        </Typography>
      </Box>
      <Region asset={asset} />
    </Box>
  )
}

export function AssetItemBackupsModal({
  assetItem,
  asset,
  isOpen,
  onClose,
}: Props) {
  const [selectedAssetItemBackup, setSelectedAssetItemBackup] =
    useState<Nullable<ElastioRecoveryPoint | AWSBRecoveryPoint | EBSSnapshot>>(
      null
    )

  const handleAssetItemBackupClick = (
    backup: ElastioRecoveryPoint | AWSBRecoveryPoint | EBSSnapshot
  ) => {
    setSelectedAssetItemBackup(backup)
  }

  return (
    <>
      {selectedAssetItemBackup && (
        <RecoveryPointDetails
          asset={asset}
          assetItem={assetItem}
          backup={selectedAssetItemBackup}
          isRecoveryPointDetailsOpen={Boolean(selectedAssetItemBackup)}
          onCloseRecoveryPointDetails={() => setSelectedAssetItemBackup(null)}
        />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        <DialogContent>
          <AssetItemDetailHeader assetItem={assetItem} onClose={onClose} />
          <AssetCredentials asset={asset} />
          <Divider
            sx={{
              marginY: '16px',
            }}
          />
          <TimeRangeProvider>
            <AssetItemRecoveryPointsTable
              assetItem={assetItem}
              onCompletionTimeClick={handleAssetItemBackupClick}
            />
          </TimeRangeProvider>
        </DialogContent>
      </Dialog>
    </>
  )
}
