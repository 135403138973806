/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { OvaBackup } from 'blues-corejs/dist'
import { Box } from '@mui/material'
import { CopyToClipboardIcon } from '@features/common'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

interface Props {
  backup: OvaBackup
}

export default function LatestCleanRecoveryPoint({ backup }: Props) {
  return (
    <Box display="flex" gap="8px" justifyContent="flex-end">
      <DateTimeTooltip date={backup.timestamp} direction="row" />
      <CopyToClipboardIcon text={backup.backupProviderBackupId} fontSize={16} />
    </Box>
  )
}
