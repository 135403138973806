import { VIRow } from 'ui-v2/src/lib/engine-types'
import { RetentionPolicyModel } from './retention-policy.model'
import {
  buildSelectedPeriodFormData,
  buildSelectedRecoveryPointTypes,
  getRetentionString,
} from 'ui-v2/src/lib/helpers/data.helper'
import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'
import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'

abstract class RetentionPolicyFactory {
  public static buildRetentionPolicyFromGrpc(
    params: any,
    assetSources: VIRow,
    backupTypes: VIRow
  ): RetentionPolicyModel {
    const id = params.defaultPolicy
      ? params.defaultPolicy.id
      : params.customPolicy.id
    const isDefault = params.defaultPolicy !== undefined
    const policy = params.defaultPolicy
      ? params.defaultPolicy.policy
      : params.customPolicy.policy
    let dataSelectors: VIRow = []

    if (!isDefault) {
      dataSelectors = buildSelectedRecoveryPointTypes(
        params.customPolicy.selectorsList,
        assetSources,
        backupTypes
      )
    }

    return new RetentionPolicyModel({
      id: numberHash(id),
      innerId: TypeHelper.stringValDef(id, ''),
      defaultPolicy: params.defaultPolicy,
      customPolicy: params.customPolicy,
      coveredAssetCount: TypeHelper.numberValDef(params.coveredAssetCount, 0),
      coverageUpdatedAt: params.coverageUpdatedAt
        ? TypeHelper.numberVal(params.coverageUpdatedAt.seconds)
        : null,
      coverageInvalidatedAt: params.coverageInvalidatedAt
        ? TypeHelper.numberVal(params.coverageInvalidatedAt.seconds)
        : null,
      policyName: TypeHelper.stringValDef(id, ''),
      isDefault: isDefault,
      policyStatus: policy.status,
      retention: getRetentionString(policy),
      dataForPeriodForm: buildSelectedPeriodFormData(policy),
      dataForRecoveryPointTypes: dataSelectors,
    })
  }

  public static buildRetentionPolicyFromGrpcForList(
    params: any
  ): RetentionPolicyModel {
    const id = params.defaultPolicy
      ? params.defaultPolicy.id
      : params.customPolicy.id
    const isDefault = params.defaultPolicy !== undefined
    const policy = params.defaultPolicy
      ? params.defaultPolicy.policy
      : params.customPolicy.policy

    return new RetentionPolicyModel({
      id: numberHash(id),
      innerId: TypeHelper.stringValDef(id, ''),
      defaultPolicy: params.defaultPolicy,
      customPolicy: params.customPolicy,
      coveredAssetCount: TypeHelper.numberValDef(params.coveredAssetCount, 0),
      coverageUpdatedAt: params.coverageUpdatedAt
        ? TypeHelper.numberVal(params.coverageUpdatedAt.seconds)
        : null,
      coverageInvalidatedAt: params.coverageInvalidatedAt
        ? TypeHelper.numberVal(params.coverageInvalidatedAt.seconds)
        : null,
      policyName: TypeHelper.stringValDef(id, ''),
      isDefault: isDefault,
      policyStatus: policy.status,
      retention: getRetentionString(policy),
      dataForPeriodForm: [],
      dataForRecoveryPointTypes: [],
    })
  }
}

export default RetentionPolicyFactory
