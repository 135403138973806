import { Typography, TypographyOwnProps } from '@mui/material'

const Label: React.FC<TypographyOwnProps> = ({ children, ...props }) => {
  return (
    <Typography variant="body1" mb={1} fontWeight={600} {...props}>
      {children}
    </Typography>
  )
}

export default Label
