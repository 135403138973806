import React, { useState } from 'react'
import SearchForm from '@forms/search-2/search.form'
import { useDebouncedFunction } from '@lib/hooks'
import { useToolbarFiltersActions } from '../toolbar-filters-store'

const DEBOUNCE_TIME = 500

export function SearchBar() {
  const [localSearchTerm, setLocalSearchTerm] = useState('')

  const { setTextSearch } = useToolbarFiltersActions()

  const searchDebounced = useDebouncedFunction(setTextSearch, DEBOUNCE_TIME)

  const handleSearchChange = (newSearchTerm: string) => {
    setLocalSearchTerm(newSearchTerm)
    searchDebounced(newSearchTerm)
  }

  return (
    <SearchForm
      placeholder="Search by Server"
      variant="whiteFilterSearchBlackIcon"
      searchValue={localSearchTerm}
      onChange={handleSearchChange}
      debounceTime={0} // We cannot set here debounce cause on the UI it looks like it is not working
    />
  )
}
