import { useState } from 'react'
import { EditOutlined, DeleteOutline } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Typography } from '@mui/material'
import {
  FormOptionValueSlug,
  ScheduleTableRow,
} from 'ui-v2/src/lib/models/reports'
import { useDeleteReportSchedule } from 'ui-v2/src/hooks/queries/reports/delete-report-schedule'
import { useNavigate } from 'react-router-dom'

import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'
import { useQueryClient } from '@tanstack/react-query'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import { useToast } from 'ui-v2/src/hooks/toast'

const ScheduleAction = ({ row }: ScheduleActionProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [scheduleId, setScheduleId] = useState<string>()
  const { mutate, isPending } = useDeleteReportSchedule()
  const toast = useToast()
  const handleEdit = () => {
    const reportOption = reportOptions.find(
      (option) => option.value === row.reportTypeKey
    )
    if (reportOption?.slug) {
      navigate(
        `/reports/schedules/form/${FormOptionValueSlug.SCHEDULE}/${reportOption.slug}/${row.id}`
      )
    }
  }

  const handleScheduleDelete = () => {
    setScheduleId(undefined)

    mutate(
      { id: row.id },
      {
        onSuccess: () => {
          toast.success('Report deleted')
          queryClient.invalidateQueries({
            queryKey: ['reports', 'reportScheduleList'],
          })
        },
        onError: (error) => {
          toast.error(
            error?.message ??
              `Failed to download ${row.id} report: ${error.message}`
          )
        },
      }
    )
  }

  return (
    <Box textAlign={'right'}>
      <IconButton aria-label="delete" size="small" onClick={handleEdit}>
        <EditOutlined fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => setScheduleId(row.id)}
      >
        {isPending ? (
          <CircularProgress size={20} />
        ) : (
          <DeleteOutline fontSize="small" />
        )}
      </IconButton>

      <ConfirmationDialog
        open={!!scheduleId}
        title="Confirm Delete"
        onClose={() => setScheduleId(undefined)}
        onConfirm={handleScheduleDelete}
        confirmText="Yes, Proceed"
        cancelText="No, Cancel"
      >
        <Typography>{row.name}</Typography>
        <Typography>
          {' '}
          Are you sure you want to delete this scheduled item? This action
          cannot be undone.
        </Typography>
      </ConfirmationDialog>
    </Box>
  )
}
interface ScheduleActionProps {
  row: ScheduleTableRow
}
export default ScheduleAction
