/* eslint-disable import/no-extraneous-dependencies */
import {
  RuleMode,
  RuleNotify,
} from 'blues-corejs/dist/models/notifications/rule'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

export const computeMode = (mode: RuleMode) => {
  if (mode instanceof RuleNotify) {
    return 'Alert On These Events'
  }

  return (
    <>
      Suppress These Events Until:{' '}
      <DateTimeTooltip placement="top-start" date={mode.date} direction="row" />
    </>
  )
}
