/* eslint-disable import/no-extraneous-dependencies */
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Nullable } from '@lib/engine-types'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { useModal } from '@lib/hooks/useModal'
import { AddSpecificPathsModalInputData } from '@components-composite/modals/AddSpecificPathsModal'
import FormAddSpecificPathsInterface from '@lib/interfaces/form/form-add-specific-paths.interface'
import Modal from '@lib/constants/modal.constant'
import {
  addS3SelectedAllPaths,
  addS3SelectedSpecificPaths,
  removeS3SelectedAllPaths,
} from '@store/actions/assets-select.action'
import { SelectAllPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import SystemHelper from '@lib/helpers/system.helper'
import { DeletePathInterface } from '@tables/assets-with-paths-table/AssetsWithPathsTable'
import { ExpandedState } from '@tanstack/react-table'
import {
  getS3SelectedAssetsSelect,
  getS3SelectedSpecificPaths,
} from '@store/selectors/assets-select.selector'
import { RowSelectionState } from '@tanstack/table-core'
import {
  findAssetById,
  findSelectedAssetIndex,
} from '@features/policies/policy/policy-tables'
import { S3Bucket } from '@lib/models/assets'

interface Props {
  s3Assets: Array<AssetWithRelatedAssets<S3Bucket>>
}

export function useS3SelectPath({ s3Assets }: Props) {
  const dispatch = useDispatch()
  const s3SelectedAsset = useSelector(getS3SelectedAssetsSelect)
  const selectedSpecificPaths = useSelector(getS3SelectedSpecificPaths)

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const [expandedRows, setExpandedRow] = useState<ExpandedState>({})

  const [currentS3, setCurrentS3] =
    useState<Nullable<AssetWithRelatedAssets<Asset>>>(null)
  const [currentRowIndex, setCurrentRowIndex] = useState<Nullable<number>>(null)

  useEffect(() => {
    if (!s3SelectedAsset.length) {
      return
    }
    const selectedS3Rows: RowSelectionState = {}
    s3SelectedAsset.forEach((s3Selected) => {
      const selectedIndex = findSelectedAssetIndex({
        assets: s3Assets,
        selectedAsset: s3Selected,
      })

      if (selectedIndex > -1) {
        selectedS3Rows[selectedIndex] = true
      }
    })
    setSelectedRows(selectedS3Rows)
  }, [s3SelectedAsset, s3Assets])

  useEffect(() => {
    if (!selectedSpecificPaths.length) {
      return
    }
    const expandedS3Rows: ExpandedState = {}
    selectedSpecificPaths.forEach((selectedPath) => {
      const expandedIndex = findSelectedAssetIndex({
        assets: s3Assets,
        selectedAsset: selectedPath.asset,
      })

      if (expandedIndex > -1) {
        expandedS3Rows[expandedIndex] = true
      }
    })
    setExpandedRow(expandedS3Rows)
  }, [selectedSpecificPaths, s3Assets])

  const onSelectAllPaths = (params: SelectAllPathsInterface) => {
    const foundAsset = findAssetById({
      assets: s3Assets,
      assetId: params.assetId,
    })
    if (!foundAsset) {
      SystemHelper.throwErrorInLocalEnv(
        'Cannot find an asset in the assets selector'
      )
      return
    }
    let actionToRun = addS3SelectedAllPaths
    if (params.cbValue) {
      actionToRun = removeS3SelectedAllPaths
    }
    dispatch(actionToRun(foundAsset))
  }

  const { openModal: openPathsAddModal, modalProps: pathsAddModalProps } =
    useModal<AddSpecificPathsModalInputData, FormAddSpecificPathsInterface>(
      Modal.addSpecificPaths,
      (form) => {
        if (currentS3) {
          setSelectedRows((prevState) => ({
            ...prevState,
            [String(currentRowIndex)]: true,
          }))
          setExpandedRow((prevState) => {
            if (typeof prevState === 'boolean') {
              return true
            }

            return {
              ...prevState,
              [String(currentRowIndex)]: true,
            }
          })
          onSelectAllPaths({
            assetId: currentS3.asset.id,
            cbValue: true,
          })
          dispatch(
            addS3SelectedSpecificPaths({
              asset: currentS3,
              selectedPaths: form.pathsList,
            })
          )
        }
      }
    )

  const onSelectPathsClick = (assetId: string, rowIndex: number) => {
    const foundAsset = findAssetById({
      assets: s3Assets,
      assetId,
    })
    setCurrentS3(foundAsset)
    setCurrentRowIndex(rowIndex)

    const specificPathsForCurrentAsset = selectedSpecificPaths?.find(
      (item) => item.asset.asset?.id === assetId
    )

    const modalData: AddSpecificPathsModalInputData = {
      pathSelector: 'path',
      pathsList: specificPathsForCurrentAsset
        ? specificPathsForCurrentAsset.selectedPaths
        : {},
    }

    openPathsAddModal(modalData)
  }

  const handleDeletePath = (deleteProps: DeletePathInterface) => {
    const foundAsset = findAssetById({
      assets: s3Assets,
      assetId: deleteProps.assetId,
    })

    const selectedSpecificPathsForAsset = selectedSpecificPaths.find(
      (item) => item.asset.asset?.id === deleteProps.assetId
    )
    if (selectedSpecificPathsForAsset !== undefined) {
      const result = selectedSpecificPathsForAsset.selectedPaths
      Object.entries(selectedSpecificPathsForAsset.selectedPaths).forEach(
        ([key, value]: [string, Array<string>]) => {
          if (key === deleteProps.selector) {
            const values = value.filter((v) => v !== deleteProps.deletedPath)
            if (!values || values.length === 0) {
              delete result[key]
            } else {
              result[key] = values
            }
          }
        }
      )

      if (foundAsset) {
        dispatch(
          addS3SelectedSpecificPaths({
            asset: foundAsset,
            selectedPaths: result,
          })
        )
      }
    }
  }
  return {
    selectedRows,
    setSelectedRows,
    expandedRows,
    setExpandedRow,
    pathsAddModalProps,
    handleDeletePath,
    onSelectPathsClick,
    onSelectAllPaths,
  }
}
