import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { OvaServerTableRow } from 'ui-v2/src/lib/models/assets/ova-server'
import StatusChip from '../../../components/ui/data-display/chip'

export const getOvaServerColumns = (): Array<Column<OvaServerTableRow>> => [
  {
    key: 'isUnhealthy',
    header: 'Health',
    render: (row) => (
      <StatusChip type={row.isUnhealthy ? 'error' : 'success'} />
    ),
    width: 80,
    align: 'center',
  },
  {
    key: 'name',
    header: 'Servers',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.name}
      </Link>
    ),
  },
  {
    key: 'ovaAccountId',
    header: 'Account',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.ovaAccountId}
      </Link>
    ),
  },
  {
    key: 'backupProvider',
    header: 'Backup Vendor',
  },
  {
    key: 'lastCleanBackupDate',
    header: 'Last Clean Recovery Point',
    render: (row) => formatDateToUtc(row.lastCleanBackupDate),
  },
]
