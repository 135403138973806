/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { EngineCallback } from '@lib/engine-types'
import Tab from '@mui/material/Tab'
import type { TabsProps } from '@mui/material/Tabs'
import { styled } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { TabSliderIcon, TabTableIcon } from '@features/DashboardV4/common'
import { EC2, Asset, Backup, OvaBackup } from 'blues-corejs/dist'
import Divider from '@mui/material/Divider'

const color = {
  selected: 'var(--blue500)',
  unselected: 'var(--grey800)',
}

const StyledTabs = styled(Tabs)`
  min-height: 30px;

  .MuiTabs-flexContainer {
    justify-content: flex-end;
  }

  .MuiButtonBase-root.MuiTab-root {
    height: 30px;
    min-height: 30px;
    min-width: 42px;
    padding: 0;
  }

  .MuiTabs-indicator {
    display: none;
  }
`

export enum MoreDetailsTab {
  TABLE,
  SLIDER,
}

interface Props extends TabsProps {
  asset: Asset
  onChangeTab: EngineCallback<MoreDetailsTab>
  selectedTab: number
  backup?: Backup
}

const getTabIconColor = (isTabSelected: boolean) => {
  return isTabSelected ? color.selected : color.unselected
}

function shouldRenderTabIcons(asset: Asset, backup?: Backup) {
  const isEc2Asset = asset instanceof EC2

  const isOvaBackup = backup instanceof OvaBackup

  return isEc2Asset || isOvaBackup
}

export function MoreDetailsTabs({
  asset,
  backup,
  onChangeTab,
  selectedTab,
  ...restProps
}: Props) {
  if (!shouldRenderTabIcons(asset, backup)) {
    return null
  }

  function handleTabChange(
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) {
    onChangeTab(newValue)
  }

  const tabTableColor = getTabIconColor(MoreDetailsTab.TABLE === selectedTab)

  const tabSliderColor = getTabIconColor(MoreDetailsTab.SLIDER === selectedTab)

  return (
    <>
      <Divider sx={{ marginY: '24px' }} />
      <StyledTabs {...restProps} value={selectedTab} onChange={handleTabChange}>
        <Tab
          icon={<TabTableIcon fill={tabTableColor} />}
          data-testid="table-tab"
        />
        <Tab
          icon={<TabSliderIcon fill={tabSliderColor} />}
          data-testid="slider-tab"
        />
      </StyledTabs>
    </>
  )
}
