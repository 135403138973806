import { ObjHelper } from '@lib/helpers'
import { useEffect } from 'react'
import { useSystemJobsFiltersActions } from '@features/jobs/tenant-jobs/system-jobs/stores/filters-store/selectors'
import { useSystemJobsStoreActions } from '@features/jobs/tenant-jobs/system-jobs/stores/system-jobs-store/selectors'
import { useSystemJobsFiltersStore } from '@features/jobs/tenant-jobs/system-jobs/stores/filters-store/system-jobs-filters-store'

export const useFiltersSubscription = () => {
  const { fetchInitialSystemJobs } = useSystemJobsStoreActions()
  const { fetchPossibleTenantJobFilters } = useSystemJobsFiltersActions()
  useEffect(() => {
    const unsubscribeFn = useSystemJobsFiltersStore.subscribe(
      (state) => ({
        selectedFilters: state.selectedFilters,
        timeRange: state.timeRange,
      }),
      ({ timeRange, selectedFilters }) => {
        fetchInitialSystemJobs({
          selectedFilters,
          timeRange,
        })
      },
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])

  // Subscribe to timeRange changes to fetch possible filters
  useEffect(() => {
    const unsubscribeFn = useSystemJobsFiltersStore.subscribe(
      (state) => ({
        timeRange: state.timeRange,
      }),
      fetchPossibleTenantJobFilters,
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])
}
