"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Backup = exports.SummaryCheckStatus = void 0;
var SummaryCheckStatus;
(function (SummaryCheckStatus) {
    SummaryCheckStatus[SummaryCheckStatus["UNCHECKED"] = 0] = "UNCHECKED";
    SummaryCheckStatus[SummaryCheckStatus["GOOD"] = 1] = "GOOD";
    SummaryCheckStatus[SummaryCheckStatus["BAD"] = 2] = "BAD";
})(SummaryCheckStatus || (exports.SummaryCheckStatus = SummaryCheckStatus = {}));
class Backup {
    #id;
    #createdAt;
    #updatedAt;
    #timestamp;
    constructor({ id, createdAt, updatedAt, timestamp }) {
        this.#id = id;
        this.#createdAt = createdAt;
        this.#updatedAt = updatedAt;
        this.#timestamp = timestamp;
    }
    get id() {
        return this.#id;
    }
    get createdAt() {
        return this.#createdAt;
    }
    get updatedAt() {
        return this.#updatedAt;
    }
    get timestamp() {
        return this.#timestamp;
    }
    get hasBadSummaryCheckStatus() {
        return this.summaryCheckStatus === SummaryCheckStatus.BAD;
    }
    get hasUncheckedSummaryCheckStatus() {
        return this.summaryCheckStatus === SummaryCheckStatus.UNCHECKED;
    }
    get hasGoodSummaryCheckStatus() {
        return this.summaryCheckStatus === SummaryCheckStatus.GOOD;
    }
}
exports.Backup = Backup;
