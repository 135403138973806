/* eslint-disable import/no-extraneous-dependencies */
import {
  ScheduleTableDataAttrs,
  ScheduleTableRow,
} from 'ui-v2/src/lib/models/reports'
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule/report-schedule'

import { getScheduleDescription } from 'ui-v2/src/lib/helpers/reports/get-schedule-description'
import { extractAllReportTypesAndParams } from 'ui-v2/src/lib/helpers/reports/extract-all-report-types-and-params'
import { removeParamsSuffix } from 'ui-v2/src/lib/helpers/reports/remove-params-suffix'

const reportLabelMapper = {
  awsExecutive: 'AWS Ransomware Readiness Report',
  awsAccountSummary: 'AWS Account Summary Report',
  awsTenantSummary: 'AWS Operational Summary Report',

  // Detailed Reports
  awsCompletedScans: 'AWS All Scans',
  awsScannedAssetsSummary: 'AWS Assets Scanned',
  awsInfectedAssets: 'AWS Infected Assets',
  infectedFiles: 'AWS Infected Files',
  failedScans: 'AWS Failed Scans',

  // Backup Connector Summary Reports
  ovaMspOperational: 'VMware Operational Report',
  ovaMspExecutive: 'VMware Ransomware Readiness Report',
  ovaMspTenantSummary: 'VMware Operational Summary Report',
  ovaMspAccountSummary: 'VMware Account Summary Report',
  ovaMspCompletedScans: 'VMware All Scans',
  ovaMspScannedAssetsSummary: 'VMware Assets Scanned',
  ovaMspInfectedAssets: 'VMware Infected Assets',
  ovaMspInfectedFiles: 'VMware Infected Files',
  ovaMspFailedScans: 'VMware Failed Scans',
}
type ReportType = keyof typeof reportLabelMapper
export class ScheduleTableData {
  readonly #scheduleReports: Array<ReportSchedule>

  constructor(parameters: ScheduleTableDataAttrs) {
    this.#scheduleReports = parameters.scheduleReports
  }

  getScheduleReportsData = (): Array<ScheduleTableRow> => {
    return this.#scheduleReports.map((report) => {
      const { reportType } = extractAllReportTypesAndParams(
        report.reportKindWithParams
      )

      const reportTypeKey = removeParamsSuffix(reportType as string)

      return new ScheduleTableRow({
        id: report.id,
        name: report.name,
        description: report.description,
        reportTypeKey: reportTypeKey,
        reportType:
          reportLabelMapper[reportTypeKey.replace(/Report$/, '') as ReportType],
        frequency: getScheduleDescription(report.schedule),
        lastGeneratedDate: report.lastReportGenerationStartedAt,
        nextReportDueOnDate: report.nextReportGenerationAt,
        action: '',
      })
    })
  }
}
