/* eslint-disable import/no-extraneous-dependencies */
import { TextConstant, CopyButtonVariant } from '@lib/constants'
import { CliCommandType } from '@lib/constants/cli-command-type.constant'
import { MountModalDetailsType } from '@lib/constants/mount-modal-details-type.constant'
import { StrHelper } from '@lib/helpers'
import LangHelper from '@lib/helpers/lang.helper'
import { CliCommandModalData } from '@lib/interfaces/cli-command-modal-data.interface'
import { ElastioRecoveryPoint, Nullable } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'

export function buildMountDetails<T extends AWSAsset>({
  asset,
  backup,
}: {
  asset?: T
  backup: Nullable<ElastioRecoveryPoint>
}): CliCommandModalData {
  if (!asset || !backup) {
    return {
      header: '',
      description: '',
      details: [],
      commands: [],
      commandType: 0,
      volumes: [],
      isQuarantined: false,
    }
  }

  const commandLinux = `sudo -E elastio mount rp --rp ${backup.ccRpId}`
  const commandWindows = `elastio mount rp --rp ${backup.ccRpId}`

  return {
    header: 'Mount the Recovery Point',
    description: TextConstant.MOUNT_DESCRIPTION,
    commandType: CliCommandType.MOUNT,
    details: [
      {
        name: 'AWS Account ID',
        label: asset.awsAccountId,
      },
      {
        name: 'Vault',
        label: backup.vaultName,
      },
      {
        name: 'Vault region',
        label: LangHelper.getAwsRegionSingleTranslation(asset.awsRegion || ''),
      },
      {
        name: 'RP completion time',
        label: String(backup.timestamp.getTime()),
        type: MountModalDetailsType.DATE,
      },
      {
        name: 'Recovery point ID',
        label: backup.ccRpId,
      },
      {
        name: 'Size',
        label: StrHelper.realFileSize(backup.sizeInfo.optimizedSize),
      },
    ],
    commands: [
      {
        type: CopyButtonVariant.WINDOWS,
        name: commandWindows,
        disabled: false,
      },
      {
        type: CopyButtonVariant.LINUX,
        name: commandLinux,
        disabled: false,
      },
    ],
  }
}
