import RegexConstant from '@lib/constants/regex.constant'
import { z } from 'zod'
import { INVALID_POLICY_NAME } from '@lib/constants/policies/policies-constants'

export const getPolicyNameSchema = (existingPoliciesNames: Array<string>) =>
  z.object({
    policyName: z
      .string()
      .min(1, 'The name is required')
      .max(255, 'The Name must be at most 255 characters')
      .regex(RegexConstant.POLICY_NAME, INVALID_POLICY_NAME)
      .refine(
        (name) => !existingPoliciesNames.includes(name),
        (name) => ({ message: `The Name ${name} already exists` })
      ),
  })
