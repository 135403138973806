import { BaseRoutes } from '../base'

export enum RouteFragments {
  DYNAMIC_ID = ':id',
}

export class WebhookEditRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_HOOK
  }

  public static get hookIdPlaceholder(): string {
    return this.buildRoute(RouteFragments.DYNAMIC_ID)
  }

  public static hookId(id: string): string {
    return this.buildRoute(this.base64Encode(id))
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_HOOK}/${fragments.join('/')}`
  }

  static readonly #ROUTE_HOOK = '/webhook-edit'
}
