/* eslint-disable import/no-extraneous-dependencies */
import { BUCKET_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { Backup, Nullable, S3Bucket, Threat } from 'blues-corejs/dist'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { MalwareScanBackup } from 'blues-corejs/dist/models/scans/malware/index'
import { ScanTarget } from 'blues-corejs/dist/models/scans/malware/types'

export abstract class AssetsS3Base {
  protected abstract readonly s3Buckets: Array<S3Bucket>

  protected abstract readonly scans: Array<Scan>

  protected abstract readonly lastBackups: Map<string, Backup>

  protected abstract readonly threats: Array<Threat>

  protected abstract readonly bucketsWithScans: Array<{
    bucket: S3Bucket
    scans: Array<Scan>
  }>

  protected convertBucketState(
    state: S3BucketState
  ): (typeof BUCKET_STATE_MAP)[keyof typeof BUCKET_STATE_MAP] {
    return BUCKET_STATE_MAP[state]
  }

  protected isUnhealthyBucket(bucket: S3Bucket): boolean {
    if (bucket.state === S3BucketState.DELETED) {
      return false
    }

    return this.threats.some(
      (threat) =>
        threat.source.asset?.assetId === bucket.id ||
        threat.source.assetItem?.assetId === bucket.id
    )
  }

  protected getLastBackup(bucket: S3Bucket): Nullable<Backup> {
    return this.lastBackups.get(bucket.id)
  }

  protected getLastScanForBucket(bucket: S3Bucket) {
    const matchingScan = this.bucketsWithScans.find(
      (bucketScan) => bucketScan.bucket.id === bucket.id
    )
    if (!matchingScan) {
      return null
    }

    return matchingScan.scans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      matchingScan.scans[0]
    )
  }

  protected isMalwareScanTarget(target: ScanTarget, bucket: S3Bucket) {
    if (target instanceof MalwareScanBackup && target.source.asset) {
      return target.source.asset.backupAssetId === bucket.id
    }

    if ('assetId' in target) {
      return target.assetId === bucket.id
    }

    return false
  }

  protected filterScansForS3Bucket(
    bucket: S3Bucket,
    scans: Array<Scan>
  ): Array<Scan> {
    return scans.filter((scan) => {
      const malwareScanMatches = this.isMalwareScanTarget(
        scan.scanTarget.target,
        bucket
      )

      return malwareScanMatches
    })
  }

  #mapBucketToScans(bucket: S3Bucket): {
    bucket: S3Bucket
    scans: Array<Scan>
  } {
    const scans = this.filterScansForS3Bucket(bucket, this.scans)
    return {
      bucket: bucket,
      scans,
    }
  }

  protected getBucketsWithScans(): Array<{
    bucket: S3Bucket
    scans: Array<Scan>
  }> {
    return this.s3Buckets.map(this.#mapBucketToScans.bind(this))
  }
}
