import React, { memo, ReactNode } from 'react'
import { Typography } from '@mui/material'

function Placeholder({ children }: { children: ReactNode }) {
  return (
    <Typography
      sx={{
        color: '#aaa',
        fontSize: '14px',
      }}
    >
      {children}
    </Typography>
  )
}

export default memo(Placeholder)
