import React, { memo } from 'react'
import { ListSubheader, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@inline-img/general/search-icon'
import { StyledFormHelperText } from '../styles'

interface Props {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
  searchValue: string
  error?: string
}

function SelectSearch({ onSearch, searchValue, error }: Props) {
  return (
    <ListSubheader
      sx={{
        padding: '0 10px',
      }}
    >
      {!!error && <StyledFormHelperText>{error}</StyledFormHelperText>}
      <TextField
        fullWidth
        placeholder="Search"
        variant="outlined"
        value={searchValue}
        autoFocus
        style={{
          backgroundColor: '#fff',
          fontSize: '14px',
          margin: '8px 0',
          padding: '8px 0px !important',
        }}
        InputProps={{
          autoFocus: false,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            padding: '8px 0',
          },
        }}
        onChange={onSearch}
        onKeyDown={(e) => {
          if (e.key !== 'Escape') {
            e.stopPropagation()
          }
        }}
      />
    </ListSubheader>
  )
}

export default memo(SelectSearch)
