export const numberHash = (value: any): number => {
  if (value === undefined || value === null) {
    return -1
  }
  return value
    .toString()
    .split('')
    .reduce((a: any, b: any) => {
      const temp = (a << 5) - a + b.charCodeAt(0)

      return Math.abs(temp & temp)
    }, 0)
}
export const roundTo = (value: number, maxSigns = 0): number => {
  const factor = Math.pow(10, maxSigns)
  return Math.round(value * factor) / factor
}

export const getNumberUSFormatted = (value: number, maxSigns = 2): string => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: maxSigns,
    maximumFractionDigits: maxSigns,
  }).format(value)
}

export const getSizeFormattedInGib = (sizeInByte: number): string => {
  return getNumberUSFormatted(
    Number(Math.ceil(sizeInByte / 1024 / 1024 / 1024)),
    0
  )
}
