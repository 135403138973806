import React from 'react'
import { useSelector } from 'react-redux'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import { Tab } from '@mui/material'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import Ec2InventoryTableComplex from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/Ec2InventoryTableComplex'
import EbsInventoryTableComplex from '@components-complex/dashboard-pages-v3/ebs-inventory-table-complex/ebs-inventory-table'
import S3BucketInventoryTableComplex from '@components-complex/dashboard-pages-v3/s3-bucket-Inventory-table-complex/S3BucketInventoryTableComplex'
import EfsInventoryTableComplex from '@components-complex/dashboard-pages-v3/efs-inventory-table-complex/EfsInventoryTableComplex'
import { OvaServerTable } from '@components-complex/dashboard-pages-v3/ova-server-table'
import GenericHostInventoryTableComplex from '@components/components-complex/dashboard-pages-v3/generic-host-inventory-table-complex/generic-host-inventory-table-complex'
import { getIsLiveAssetsLoading } from '@store/selectors/list-live-assets.selector'
import { DashboardTab } from '@lib/router'
import { useQueryTabs } from './use-query-tabs'

function InventoryTables() {
  const { setTab, tab } = useQueryTabs(DashboardTab.EC2)
  const isLoading = useSelector(getIsLiveAssetsLoading)
  const { genericHostsEnabled } = useCustomFlags()

  return (
    <div className="innerContent innerContentBlueBackground jsInventoryTablesPage">
      <div className="wrap-1688376167667">
        <div
          className="wrapInventoryTables newTablesPreview"
          data-testid="inventory-tables"
        >
          {isLoading ? (
            <PreloaderBlock show />
          ) : (
            <>
              <CustomTabs value={tab} onChangeTab={setTab}>
                <Tab label="EC2" value={DashboardTab.EC2} />
                <Tab
                  label="EBS"
                  value={DashboardTab.EBS}
                  data-testid="ebs-tab"
                />
                <Tab
                  label="S3 Bucket"
                  value={DashboardTab.S3}
                  data-testid="s3-bucket-tab"
                />
                <Tab
                  label="EFS"
                  value={DashboardTab.EFS}
                  data-testid="efs-tab"
                />
                <Tab
                  label="Servers"
                  value={DashboardTab.OVA_SERVER}
                  data-testid="ova-tab"
                />
                <Tab
                  label="Generic Host"
                  value={DashboardTab.GENERIC_HOSTS}
                  hidden={!genericHostsEnabled}
                />
              </CustomTabs>

              <MaterialTab value={tab} index={DashboardTab.EC2}>
                <Ec2InventoryTableComplex />
              </MaterialTab>

              <MaterialTab value={tab} index={DashboardTab.EBS}>
                <EbsInventoryTableComplex />
              </MaterialTab>

              <MaterialTab value={tab} index={DashboardTab.S3}>
                <S3BucketInventoryTableComplex />
              </MaterialTab>

              <MaterialTab value={tab} index={DashboardTab.EFS}>
                <EfsInventoryTableComplex />
              </MaterialTab>

              <MaterialTab value={tab} index={DashboardTab.OVA_SERVER}>
                <OvaServerTable />
              </MaterialTab>

              {genericHostsEnabled && (
                <MaterialTab value={tab} index={DashboardTab.GENERIC_HOSTS}>
                  <GenericHostInventoryTableComplex />
                </MaterialTab>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default InventoryTables
