/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment } from 'react'
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import { CloudConnectorJobKindChip } from '@features/jobs/cloud-connector-jobs/shared'
import {
  useModalFilterActions,
  useSelectedModalFilters,
} from '../cloud-connector-filters-store'

export function SelectedJobKindsFilters() {
  const { kindsList: selectedKindsList } = useSelectedModalFilters()

  const { setFilter } = useModalFilterActions()

  if (selectedKindsList.size === 0) {
    return null
  }

  function handleRemoveFilter(kind: JobCloudConnectorKind) {
    const newKindsList = new Set(selectedKindsList)

    newKindsList.delete(kind)

    setFilter('kindsList', newKindsList)
  }

  return (
    <Fragment>
      {Array.from(selectedKindsList).map((kind) => (
        <CloudConnectorJobKindChip
          onDelete={handleRemoveFilter}
          key={kind}
          kind={kind}
        />
      ))}
    </Fragment>
  )
}
