import { VIMatrix } from 'ui-v2/src/lib/engine-types'
import { useMemo } from 'react'
import { getSourcesListColumns } from './column-config'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { SourcesTableData } from 'ui-v2/src/lib/use-cases/settings/sources'
import { RedStackModel } from 'ui-v2/src/lib/models/base-model'
import { ProblematicCC } from 'ui-v2/src/lib/models/settings/sources/sources'
import VaultModel from 'ui-v2/src/lib/models/settings/policies/vault.model'

const SourcesTable = ({
  redStackListData,
  allActiveRedStacksData,
  vaultListData,
  problematicCC,
  enabledSourcesData,
  cloudConnectorsInAction,
}: SourcesTableProps) => {
  const data = useMemo(() => {
    if (!enabledSourcesData) {
      return undefined
    }

    return new SourcesTableData({
      sourcesList: enabledSourcesData,
      redStackListData,
      allActiveRedStacksData,
      vaultListData,
      problematicCC,
      cloudConnectorsInAction,
    }).getSourcesList()
  }, [
    enabledSourcesData,
    redStackListData,
    allActiveRedStacksData,
    vaultListData,
    problematicCC,
    cloudConnectorsInAction,
  ])

  const columns = useMemo(getSourcesListColumns, [])

  return <ClientTable checkable={false} data={data ?? []} columns={columns} />
}
interface SourcesTableProps {
  enabledSourcesData: VIMatrix
  redStackListData: Array<RedStackModel>
  allActiveRedStacksData: Array<RedStackModel>
  vaultListData: Array<VaultModel>
  problematicCC: ProblematicCC
  cloudConnectorsInAction: Array<string>
}
export default SourcesTable
