import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
import GrpcRetentionPoliciesService from '@lib/services/grpc/grpc-retention-policies.service'
import PaginatedInterface from '@lib/interfaces/paginated.interface'
import {
  DELETE_POLICY_RETENTION,
  requestPoliciesRetention,
  REQUEST_POLICIES_RETENTION,
  setPoliciesRetention,
  SET_CUSTOM_RETENTION_POLICY_PRIORITIES,
  SET_POLICIES_RETENTION_PAGINATION,
  UPDATE_STATUS_POLICY_RETENTION,
} from '@store/actions/policies-retention.action'

export const requestPoliciesRetentionEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_POLICIES_RETENTION, SET_POLICIES_RETENTION_PAGINATION],
      PreloaderConstants.REQUEST_POLICIES_RETENTION,
      () => {
        return GrpcRetentionPoliciesService.listRetentionPolicies().catch(
          sentryReThrowCatchHandler('Cannot get a list of retention policies')
        )
      },
      (data: PaginatedInterface<RetentionPolicyModel>) =>
        setPoliciesRetention(data),
      ErrorGroupConstants.POLICIES_RETENTION
    )
  )

export const deletePolicyRetentionEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [DELETE_POLICY_RETENTION],
      PreloaderConstants.DELETE_POLICY_RETENTION,
      (action: ActionInterface) =>
        GrpcRetentionPoliciesService.deleteRetentionPolicy(
          action.payload
        ).catch(
          sentryReThrowCatchHandler('Cannot delete the retention policy')
        ),
      () => {
        // navigation here because we can handle policy deletion from the edit page
        history.back()
        return requestPoliciesRetention()
      },
      () => 'The retention policy has been removed',
      () => 'Error during retention policy removing'
    )
  )

export const updateStatusPolicyRetentionEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [UPDATE_STATUS_POLICY_RETENTION],
      PreloaderConstants.UPDATE_STATUS_POLICY_RETENTION,
      (action: ActionInterface) =>
        GrpcRetentionPoliciesService.updateStatusRetentionPolicy(
          action.payload
        ).catch(
          sentryReThrowCatchHandler('Cannot update the retention policy status')
        ),
      () => {
        history.back()
        return requestPoliciesRetention()
      },
      () => 'The retention policy status has been updated',
      () => 'Error during retention policy status updating'
    )
  )

export const setCustomRetentionPolicyPrioritiesEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [SET_CUSTOM_RETENTION_POLICY_PRIORITIES],
      PreloaderConstants.SET_CUSTOM_RETENTION_POLICY_PRIORITIES,
      (action: ActionInterface) =>
        GrpcRetentionPoliciesService.setCustomRetentionPolicyPriorities(
          action.payload
        ).catch(
          sentryReThrowCatchHandler(
            'An error occurred. The retention policy ordering has not been changed'
          )
        ),
      () => {
        history.back()
        return requestPoliciesRetention()
      },
      () => 'The retention policy ordering has been changed',
      () =>
        'An error occurred. The retention policy ordering has not been changed. Try again'
    )
  )
