/* eslint-disable import/no-extraneous-dependencies */
import {
  computeScanType,
  getCompletionTime,
} from 'ui-v2/src/lib/helpers/assets.helper'
import {
  computeScansTarget,
  getDataSource,
} from 'ui-v2/src/lib/helpers/compute-scan-target'
import {
  ScanListTableDataAttrs,
  ScanListTableRow,
} from 'ui-v2/src/lib/models/assets/infected-assets'
import { ScansRun } from 'blues-corejs/dist/models/scans/index'

export class ScanTableData {
  readonly #scanlist: Array<ScansRun>

  constructor(parameters: ScanListTableDataAttrs) {
    this.#scanlist = parameters.scanLists
  }

  getScanList = (): Array<ScanListTableRow> => {
    return this.#scanlist.map((scan, index) => {
      const computedCompletionTime = getCompletionTime(scan)
      const computedScanType = computeScanType(scan)

      const computedScanTarget = computeScansTarget([
        ...scan.filesystemChecksList,
        ...scan.malwareScansList,
        ...scan.ransomwareScansList,
      ])

      const source = getDataSource(computedScanTarget)

      return new ScanListTableRow({
        id: index.toString(),
        scanTime: computedCompletionTime,
        scanType: computedScanType,
        source: source ? source : '-',
      })
    })
  }
}
