import { DialogContentText } from '@mui/material'
import { Dialog, DialogContent, DialogHeader } from '../ui/dialog'
import DialogActions from '../ui/dialog/actions'
import Button from '../ui/inputs/button'
import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useToast } from 'ui-v2/src/hooks/toast'

interface SignoutDialogProps {
  open: boolean
  onClose: () => void
}

const SignoutDrawer: React.FC<SignoutDialogProps> = ({
  open,
  onClose,
  ...props
}) => {
  const [isSigningout, setIsSigningout] = useState(false)
  const { logout } = useAuth0()
  const toast = useToast()

  const logoutUser = async () => {
    try {
      setIsSigningout(true)

      await logout({
        logoutParams: { returnTo: window.location.origin },
      })
    } catch {
      toast.error('Could not sign out, please try again later!')
    } finally {
      setIsSigningout(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogHeader title="Sign Out" handleCloseDialog={onClose} />
      <DialogContent>
        <DialogContentText>
          Are you sure you want to sign out?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={logoutUser}
          autoFocus
          isLoading={isSigningout}
        >
          Sign out
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SignoutDrawer
