import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DoneIcon from '@mui/icons-material/Done'
import Typography from '@mui/material/Typography'
import React from 'react'

function renderIconByState({
  pendingLength,
  optionsLength,
}: {
  pendingLength: number
  optionsLength: number
}) {
  return pendingLength === optionsLength ? (
    <>
      <DeleteOutlineIcon />
      <Typography variant="body2">Clear</Typography>
    </>
  ) : (
    <>
      <DoneIcon />
      <Typography variant="body2">All</Typography>
    </>
  )
}

export { renderIconByState }
