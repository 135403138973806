import React from 'react'

function WindowsIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.7771 5.51021V11.2781H11.1298V4.56155L3.7771 5.51021ZM3.7771 17.8428L11.1298 18.7915V12.1508H3.7771V17.8428ZM11.9333 18.9053L21.7771 20.1575V12.1508H11.9333V18.9053ZM11.9333 4.44771V11.2781H21.7771V3.15753L11.9333 4.44771Z"
        fill="white"
      />
    </svg>
  )
}

export default WindowsIcon
