/* eslint-disable import/no-extraneous-dependencies */
import { ListAssetsByIdsClient, CloudConnectorJobsClient } from '@lib/clients'
import {
  ListCloudConnectorJobsPagination,
  ListCloudConnnectorJobsFilter,
  ListCloudConnnectorJobsFilterRequestParams,
} from '@lib/clients/jobs'

import { Asset } from 'blues-corejs/dist'

const assetsByIdsClient = new ListAssetsByIdsClient()
const cloudConnectorJobsClient = new CloudConnectorJobsClient()

export async function fetchListCloudConnectorJobsFilter({
  filters,
}: {
  filters: ListCloudConnnectorJobsFilterRequestParams
}) {
  const response = await cloudConnectorJobsClient.listCloudConnectorJobs(
    new ListCloudConnnectorJobsFilter(filters)
  )

  return {
    jobsList: response.jobsList,
    pageToken: response.pageToken,
  }
}

export async function fetchListCloudConnectorJobsPagination(pageToken: string) {
  const response = await cloudConnectorJobsClient.listCloudConnectorJobs(
    new ListCloudConnectorJobsPagination(pageToken)
  )

  return {
    jobsList: response.jobsList,
    pageToken: response.pageToken,
  }
}

export async function fetchAssetsByIds(assetIds: Array<string>) {
  if (!assetIds.length) {
    return new Map<string, Asset>()
  }

  const response = await assetsByIdsClient.listAssetsByIds([
    ...new Set(assetIds),
  ])

  const assetsMap = new Map<string, Asset>()

  response.assetsList.forEach((assetList) => {
    const asset = [
      assetList?.ebsVolume,
      assetList?.ec2Instance,
      assetList?.efsFilesystem,
      assetList?.genericHost,
      assetList?.s3Bucket,
      assetList?.ovaServer,
    ].find((a) => a !== undefined)

    if (asset) {
      assetsMap.set(asset.id, asset)
    }
  })

  return assetsMap
}
