import ControlledSelect from '../controlled-select/controlled-select'
import { OptionType } from '@components/shared'

export enum TimeRangeSelection {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_DATE = 'Custom date',
}

export const TIME_RANGE_MENU_ITEMS = [
  {
    label: 'Today',
    value: TimeRangeSelection.TODAY,
  },
  {
    label: 'Last 7 days',
    value: TimeRangeSelection.LAST_7_DAYS,
  },
  {
    label: 'Last 30 days',
    value: TimeRangeSelection.LAST_30_DAYS,
  },
  {
    label: 'Custom date',
    value: TimeRangeSelection.CUSTOM_DATE,
  },
]

function TimeRangeDropdown({
  options = TIME_RANGE_MENU_ITEMS,
  name,
  onChange,
}: {
  options?: Array<OptionType>
  name: string
  onChange?: (value: string | number) => void
}) {
  return (
    <ControlledSelect<any>
      options={options}
      name={name}
      errors={{}}
      onChangeCallback={onChange}
    />
  )
}

export default TimeRangeDropdown
