/* eslint-disable import/no-extraneous-dependencies */
import { ScanStatus } from '@features/DashboardV4/common'
import { ThreatIconsBlock } from '@features/DashboardV4/common/threat-icon-group'
import { ScansRun } from 'blues-corejs/dist/models'
import {
  AssetItem,
  EFS,
  EfsItem,
  GenericHost,
  Nullable,
  S3Bucket,
  S3Item,
} from 'blues-corejs/dist'
import { StatusScanFilesystemCheck } from 'blues-corejs/dist/models/scans/filesystem-check/types'
import { MalwareScanStatus } from 'blues-corejs/dist/models/scans/malware/types'
import { RansomwareScanStatus } from 'blues-corejs/dist/models/scans/ransomware/types'
import React from 'react'
import { getFilesystemCheckStatus } from './get-fs-check-scan-status'
import { getMalwareScanStatus } from './get-malware-scan-status'
import { getRansomwareScanStatus } from './get-ransomware-scan-status'

function getCheckStatusParsedForScans(
  status:
    | Nullable<RansomwareScanStatus>
    | Nullable<MalwareScanStatus>
    | Nullable<StatusScanFilesystemCheck>
): Nullable<ScanStatus> {
  switch (status) {
    case RansomwareScanStatus.INFECTED:
      return ScanStatus.FAILED
    case MalwareScanStatus.INFECTED:
      return ScanStatus.FAILED
    case StatusScanFilesystemCheck.BAD:
      return ScanStatus.FAILED
    case RansomwareScanStatus.CLEAN:
      return ScanStatus.SUCCEEDED
    case MalwareScanStatus.CLEAN:
      return ScanStatus.SUCCEEDED
    case StatusScanFilesystemCheck.GOOD:
      return ScanStatus.SUCCEEDED
    default:
      return ScanStatus.UNSCANNED
  }
}

type Asset = EFS | S3Bucket | GenericHost

interface Props {
  asset: Asset | AssetItem
  scans: ScansRun
}

export function ThreatsCell({ scans, asset }: Props) {
  if (asset instanceof GenericHost) {
    return null
  }

  const ransomwareScanStatus = getRansomwareScanStatus(
    scans.ransomwareScansList
  )

  const malwareScanStatus = getMalwareScanStatus(scans.malwareScansList)

  const fsCheckScanStatus = getFilesystemCheckStatus(scans.filesystemChecksList)

  const shouldShowMalwareAndRansomware = fsCheckScanStatus === null

  const shouldShowFsCheck =
    ransomwareScanStatus === null && malwareScanStatus === null

  switch (asset.constructor) {
    case S3Bucket:
    case S3Item:
    case EFS:
    case EfsItem:
      return (
        <ThreatIconsBlock
          ransomwareStatus={getCheckStatusParsedForScans(ransomwareScanStatus)}
          malwareStatus={getCheckStatusParsedForScans(malwareScanStatus)}
        />
      )
    default:
      if (shouldShowFsCheck) {
        return (
          <ThreatIconsBlock
            fsCheckStatus={getCheckStatusParsedForScans(fsCheckScanStatus)}
          />
        )
      }

      if (shouldShowMalwareAndRansomware) {
        return (
          <ThreatIconsBlock
            ransomwareStatus={getCheckStatusParsedForScans(
              ransomwareScanStatus
            )}
            malwareStatus={getCheckStatusParsedForScans(malwareScanStatus)}
          />
        )
      }
  }

  return null
}
