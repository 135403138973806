import React, { createContext, useState, useEffect, useCallback } from 'react'
import { Snackbar, Alert, Slide, AlertColor } from '@mui/material'
import type { SnackbarOrigin, SlideProps, SxProps, Theme } from '@mui/material'

type ToastPosition = SnackbarOrigin

export interface ToastOptions {
  severity?: AlertColor
  duration?: number
  position?: ToastPosition
  style?: SxProps<Theme>
  animation?: 'slide' | 'fade'
}

interface Toast extends Required<Omit<ToastOptions, 'style'>> {
  id: string
  message: string
  expired: boolean
  style: SxProps<Theme>
}

interface ToastContextValue {
  addToast: (message: string, options?: ToastOptions) => void
  removeToast: (id: string) => void
}

interface ToastProviderProps {
  children: React.ReactNode
  maxSnacks?: number
}

const defaultStyles: Record<AlertColor, SxProps<Theme>> = {
  success: { backgroundColor: '#4caf50' },
  error: { backgroundColor: '#f44336' },
  warning: { backgroundColor: '#ff9800' },
  info: { backgroundColor: '#2196f3' },
}

export const ToastContext = createContext<ToastContextValue>({
  addToast: () => {
    throw new Error('ToastContext not initialized')
  },
  removeToast: () => {
    throw new Error('ToastContext not initialized')
  },
})

const SlideTransition = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide {...props} ref={ref} direction="up" />
})
SlideTransition.displayName = 'SlideTransition'

export const ToastProvider: React.FC<ToastProviderProps> = React.memo(
  ({ children, maxSnacks = 6 }) => {
    const [toasts, setToasts] = useState<Array<Toast>>([])

    const addToast = useCallback(
      (message: string, options: ToastOptions = {}) => {
        const id = `${Date.now()}-${Math.random().toString(36).slice(2, 9)}`
        const newToast: Toast = {
          id,
          message,
          severity: options.severity || 'success',
          duration: options.duration || 6000,
          position: options.position || {
            vertical: 'bottom',
            horizontal: 'right',
          },
          style: options.style || {},
          animation: options.animation || 'slide',
          expired: false,
        }

        setToasts((currentToasts) => {
          if (currentToasts.length >= maxSnacks) {
            return [...currentToasts.slice(1), newToast]
          }
          return [...currentToasts, newToast]
        })

        setTimeout(() => {
          setToasts((currentToasts) =>
            currentToasts.map((toast) =>
              toast.id === id
                ? {
                    ...toast,
                    expired: true,
                  }
                : toast
            )
          )
        }, newToast.duration)
      },
      [maxSnacks]
    )

    const removeToast = useCallback((id: string) => {
      setToasts((currentToasts) =>
        currentToasts.map((toast) =>
          toast.id === id
            ? {
                ...toast,
                expired: true,
              }
            : toast
        )
      )
    }, [])

    useEffect(() => {
      const interval = setInterval(() => {
        setToasts((currentToasts) =>
          currentToasts.filter((toast) => !toast.expired)
        )
      }, 100)
      return () => clearInterval(interval)
    }, [])

    const contextValue = React.useMemo(
      () => ({
        addToast,
        removeToast,
      }),
      [addToast, removeToast]
    )

    return (
      <ToastContext.Provider value={contextValue}>
        {children}
        {toasts.map((toast) => (
          <Snackbar
            key={toast.id}
            open={!toast.expired}
            autoHideDuration={toast.duration}
            onClose={() => removeToast(toast.id)}
            anchorOrigin={toast.position}
            TransitionComponent={
              toast.animation === 'slide' ? SlideTransition : undefined
            }
            sx={{ '& + &': { marginTop: '10px' } }}
          >
            <Alert
              onClose={() => removeToast(toast.id)}
              severity={toast.severity}
              variant="filled"
              sx={Object.assign({}, defaultStyles[toast.severity], toast.style)}
            >
              {toast.message}
            </Alert>
          </Snackbar>
        ))}
      </ToastContext.Provider>
    )
  }
)
