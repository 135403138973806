import { useLayoutContext } from '@features/contexts'
import {
  EmptyAllS3SelectedAssets,
  TabAssetType,
} from '@lib/constants/app.constant'
import { SelectedCloudConnectorVariant } from '@lib/constants/policies.constant'
import { StrHelper } from '@lib/helpers'
import {
  setAssetsSelectFilters,
  setAssetsSelectEnabledTabs,
  setAllSelectedAssets,
  setAllS3SelectedAssets,
  setAssetsSelectTabSelectedNum,
} from '@store/actions/assets-select.action'
import {
  requestEditPolicy,
  resetEditScanWithTimestamps,
  resetIntegrityScan,
  setEditPolicyName,
  setEditPolicyTags,
  setEditPolicyVaultList,
  setEditSelectedCloudConnectors,
  setEditSelectedCloudConnectorsVariant,
  setEntropyDetectionConfiguration,
} from '@store/actions/edit-policy.action'
import { requestPolicies } from '@store/actions/policies.action'
import { getAllRedStacks } from '@store/actions/rex.action'
import { requestVaultList } from '@store/actions/vault.action'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

export function useInitializeInitialStateForEditPolicy() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { setHeaderTitle } = useLayoutContext()

  const basePolicyName = StrHelper.base64Decode(String(id))

  useEffect(() => {
    return () => {
      setHeaderTitle(null)
      dispatch(setAssetsSelectFilters([]))
    }
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(requestEditPolicy(basePolicyName))
      dispatch(requestPolicies())
      dispatch(setEditPolicyName(''))
      dispatch(setAssetsSelectEnabledTabs([]))
      dispatch(setAllSelectedAssets([]))
      dispatch(setAssetsSelectFilters(defaultSelectedFilters()))
      dispatch(setAssetsSelectTabSelectedNum(TabAssetType.Ec2andEbs))
      dispatch(setAllS3SelectedAssets(EmptyAllS3SelectedAssets()))
      dispatch(resetIntegrityScan())
      dispatch(setEntropyDetectionConfiguration(false))
      dispatch(resetEditScanWithTimestamps())
      dispatch(setEditPolicyTags([]))
      dispatch(setEditPolicyVaultList([]))
      dispatch(getAllRedStacks())
      dispatch(requestVaultList())
      dispatch(
        setEditSelectedCloudConnectorsVariant(SelectedCloudConnectorVariant.ALL)
      )
      dispatch(setEditSelectedCloudConnectors([]))

      setHeaderTitle(`Edit Policy ${id ? `(${basePolicyName})` : ''}`)
    }
  }, [id])
}
