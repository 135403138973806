import React from 'react'

function InviteTeamMembersIcon() {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55.0001" height="55" rx="10" fill="#EDFFE4" />
      <path
        d="M47 35.5731H8V21.5992C8 20.8558 8.36175 20.1652 8.95826 19.7671L25.8567 8.5027C26.8612 7.83243 28.1388 7.83243 29.1433 8.5027L46.0418 19.7671C46.6383 20.1652 47 20.8558 47 21.5992V35.5731Z"
        fill="url(#paint0_linear_5277_594)"
      />
      <path
        d="M40.8427 38.3315H14.1577V18.833C14.1577 18.2358 14.6195 17.7524 15.1852 17.7524H39.8152C40.3809 17.7524 40.8427 18.2358 40.8427 18.833V38.3315Z"
        fill="url(#paint1_linear_5277_594)"
      />
      <path
        d="M47 22.0825V45.111C47 46.151 46.1841 47 45.1797 47H12.6797L17.6095 40.143L47 22.0825Z"
        fill="url(#paint2_linear_5277_594)"
      />
      <path
        d="M8 22.0825V45.111C8 46.151 8.81587 47 9.82031 47H45.1797C45.7531 47 46.2611 46.7156 46.5921 46.285L8 22.0825Z"
        fill="url(#paint3_linear_5277_594)"
      />
      <path
        d="M32.1182 25.3322H28.5276V21.5422C28.5276 21.2416 28.2967 21.002 28.0119 21.002H26.9882C26.7035 21.002 26.4725 21.2416 26.4725 21.5422V25.3322H22.882C22.5972 25.3322 22.3701 25.576 22.3701 25.8766V26.9571C22.3701 27.2577 22.5972 27.5015 22.882 27.5015H26.4725V31.2915C26.4725 31.5921 26.7035 31.8317 26.9882 31.8317H28.0119C28.2967 31.8317 28.5276 31.5921 28.5276 31.2915V27.5015H32.1182C32.403 27.5015 32.63 27.2577 32.63 26.9571V25.8766C32.63 25.576 32.403 25.3322 32.1182 25.3322Z"
        fill="url(#paint4_linear_5277_594)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5277_594"
          x1="22.2877"
          y1="9.85947"
          x2="29.3371"
          y2="32.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0078D3" />
          <stop offset="1" stopColor="#0858A1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5277_594"
          x1="27.5002"
          y1="18.2017"
          x2="27.5002"
          y2="33.169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.24" stopColor="#F8F8F7" />
          <stop offset="1" stopColor="#E3E3E1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5277_594"
          x1="29.4355"
          y1="33.9303"
          x2="42.7835"
          y2="52.0284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#1FA0E5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5277_594"
          x1="6.02473"
          y1="33.172"
          x2="44.6343"
          y2="50.9107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#28AFEA" />
          <stop offset="1" stopColor="#0B88DA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5277_594"
          x1="27.5001"
          y1="20.8381"
          x2="27.5001"
          y2="31.936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#21AD64" />
          <stop offset="1" stopColor="#088242" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default InviteTeamMembersIcon
