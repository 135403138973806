/* eslint-disable import/no-extraneous-dependencies */
import { ListScansClient } from '@lib/clients/scans/list-scans'
import { ListScans } from '@lib/clients/scans/types'
import SystemHelper from '@lib/helpers/system.helper'
import { useEffect, useRef, useState } from 'react'
import { ObjHelper } from '@lib/helpers'

const scanClient = new ListScansClient()

const INITIAL_STATE: ListScans = {
  filesystemChecks: [],
  malwares: [],
  ransomwares: [],
}

export function useFetchScansByScanIds(scanIds: Array<string>) {
  const [isScansLoading, setIsScansLoading] = useState(false)
  const [scans, setScans] = useState<ListScans>(INITIAL_STATE)

  const previousScanIds = useRef<Array<string>>([])

  const isScansIdsEqual = ObjHelper.isEqual(scanIds, previousScanIds.current)

  useEffect(() => {
    async function fetchDataScans() {
      if (!scanIds.length || isScansIdsEqual) {
        return
      }
      setIsScansLoading(true)
      try {
        const scansResponse = await scanClient.listScans(scanIds)

        previousScanIds.current = scanIds

        setScans(scansResponse)
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          `useFetchScansByScanIds: Error fetching scans by scan ids: ${scanIds.join(
            ', '
          )}`
        )
      } finally {
        setIsScansLoading(false)
      }
    }

    fetchDataScans()
  }, [isScansIdsEqual])

  function getIsScansExist() {
    const allScans = [
      ...scans.filesystemChecks,
      ...scans.malwares,
      ...scans.ransomwares,
    ]

    return allScans.length > 0
  }

  return {
    scans,
    isScansLoading,
    getIsScansExist,
  }
}
