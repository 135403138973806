import React from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '@store/selectors/user.selector'
import UserStatusConstant from '@lib/constants/data/user/user-status.constant'
import MenuVIRow from '@tables/rows-vi/MenuVIRow'

export enum ManageUsersMenu {
  Edit = 'Edit',
  Disable = 'Disable',
  Enable = 'Enable',
  ResendInvite = 'Resend invite',
  Delete = 'Delete',
}

enum Columns {
  Email = 0,
  LastLogged = 1,
  Status = 2,
}

export function ManageUsersMenuViRow(props: any) {
  const currentUser = useSelector(getUser)

  const renderMenuType = () => {
    const { data } = props

    const userEmail = String(data[Columns.Email].label)

    if (userEmail === currentUser.email) {
      return []
    }

    switch (data[Columns.Status].value) {
      case UserStatusConstant.STATUS_EXPIRED.value:
        return [ManageUsersMenu.ResendInvite, ManageUsersMenu.Disable]
      case UserStatusConstant.STATUS_DISABLED.value:
        return [ManageUsersMenu.Enable, ManageUsersMenu.Delete]
      default:
        return [ManageUsersMenu.Disable]
    }
  }

  return (
    <MenuVIRow
      {...props}
      menu={renderMenuType()}
      ariaLabel="managaments-users-row"
    />
  )
}
