import React from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'
import { TextField } from '@mui/material'
import { inputPropsStyle } from './styles'

interface Props {
  field: ControllerRenderProps
  error?: FieldError
  disabled?: boolean
}

export default function TextFieldController({ field, error, disabled }: Props) {
  return (
    <TextField
      {...field}
      disabled={disabled}
      placeholder="Enter a name of view"
      error={!!error}
      helperText={error?.message || ''}
      inputProps={{
        style: inputPropsStyle,
      }}
      data-testid="dashboard-view-name-input"
    />
  )
}
