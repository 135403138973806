import { EngineCallback, VIRow } from '@lib/engine-types'
import { Sorting } from '@lib/interfaces/tables/sort.interface'
import { TableSortLabel } from '@mui/material'
import React, { memo } from 'react'

export interface Props {
  sorting: Sorting
  onHeadClick: EngineCallback<string>
  data: VIRow
}

export function TableSortableHeader({ sorting, onHeadClick, data }: Props) {
  return (
    <tr>
      {data.map((headCell, index) =>
        headCell.name ? (
          <th key={index} onClick={() => onHeadClick(headCell.name)}>
            <TableSortLabel
              active={sorting.columnId === headCell.name}
              direction={
                sorting.columnId === headCell.name ? sorting.order : undefined
              }
            >
              {headCell.name}
            </TableSortLabel>
          </th>
        ) : (
          <th key={index} />
        )
      )}
    </tr>
  )
}

export default memo(TableSortableHeader)
