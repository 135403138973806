import { Select, styled, Typography } from '@mui/material'

export const StyledViewAllButton = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.blue[500],
  fontWeight: 500,
  cursor: 'pointer',
}))

export const StyledTimeSelect = styled(Select)({
  width: '160px',
})
