"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListDetailsDetectedThreats = void 0;
const share_1 = require("../share");
const types_1 = require("../../models/threats/types");
__exportStar(require("./types"), exports);
class ListDetailsDetectedThreats {
    threats;
    constructor(threats) {
        this.threats = threats;
    }
    execute(filters) {
        return this.prepareOutput(this.findThreatsMatchingFilters(filters));
    }
    prepareOutput(threats) {
        const threatsOutput = threats.map(({ kind, location, name, firstDetectedAt, status, id }) => {
            return {
                kind,
                location,
                firstDetectedAt,
                status,
                threatName: name,
                id,
            };
        });
        const possibleFilters = this.getThreatsPossibleFilters();
        return {
            threatsData: threatsOutput,
            possibleFilters: possibleFilters,
        };
    }
    getThreatsPossibleFilters() {
        const ransomware = new Set();
        const malware = new Set();
        this.threats.forEach((threat) => {
            if (threat.kind === types_1.ThreatKind.RANSOMWARE) {
                ransomware.add(threat.name);
            }
            if (threat.kind === types_1.ThreatKind.MALWARE) {
                malware.add(threat.name);
            }
        });
        return {
            ransomware: [...ransomware],
            malware: [...malware],
        };
    }
    findThreatsMatchingFilters(filters) {
        return this.threats.filter((threat) => {
            if (!(0, share_1.matchesIncludeFilter)(filters?.ransomware, threat.name)) {
                return false;
            }
            if (!(0, share_1.matchesIncludeFilter)(filters?.malware, threat.name)) {
                return false;
            }
            return true;
        });
    }
}
exports.ListDetailsDetectedThreats = ListDetailsDetectedThreats;
