import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface Props {
  filterName: string
  onHeaderClick?: () => void
}

export function EntropyFilterHeader({ filterName, onHeaderClick }: Props) {
  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={onHeaderClick}>
      <Typography fontSize="14px" variant="caption" fontWeight="500">
        {filterName}
      </Typography>
    </AccordionSummary>
  )
}
