import React, { ReactNode } from 'react'
import styles from './threats.module.scss'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import RansomwareIcon from '@inline-img/general/ransomware-icon'
import MalwareIcon from '@inline-img/general/malware-icon'
import FsCheckIcon from '@inline-img/general/fs-check-icon'
import { Nullable } from '@lib/engine-types'
import LangHelper from '@lib/helpers/lang.helper'
import { useNavigation, DashboardRoutes, DashboardTab } from '@lib/router'
import {
  getLastScanningdDate,
  ThreatSkeletonLoader,
  useFetchAwsThreat,
} from '@features/DashboardV4/threats-widgets'
import { WidgetHeader } from '@features/DashboardV4/common'
import { useSelector } from 'react-redux'
import {
  getIsLiveAssetsLoading,
  getScans,
} from '@store/selectors/list-live-assets.selector'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

function getRansomwareIcon(value: Nullable<number>): ReactNode {
  switch (value) {
    case null:
      return (
        <Tooltip title="Not Scanned for Ransomware" placement="top">
          <div className={`${styles.iconItem} ${styles.unscanned}`}>
            {RansomwareIcon({
              fill: '#999caa',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    case 0:
      return (
        <Tooltip title="No Ransomware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.clean}`}>
            {RansomwareIcon({
              fill: '#45aa5b',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Ransomware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.infected}`}>
            {RansomwareIcon({
              fill: '#ff7878',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
  }
}

function getMalwareIcon(value: Nullable<number>): ReactNode {
  switch (value) {
    case null:
      return (
        <Tooltip title="Not Scanned for Malware" placement="top">
          <div className={`${styles.iconItem} ${styles.unscanned}`}>
            {MalwareIcon({
              fill: '#999caa',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    case 0:
      return (
        <Tooltip title="No Malware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.clean}`}>
            {MalwareIcon({
              fill: '#45aa5b',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Malware Found" placement="top">
          <div className={`${styles.iconItem} ${styles.infected}`}>
            {MalwareIcon({
              fill: '#ff7878',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
  }
}

function getFsCheckIcon(value: Nullable<number>): ReactNode {
  switch (value) {
    case null:
      return (
        <Tooltip title="Not scanned for File Corruption" placement="top">
          <div className={`${styles.iconItem} ${styles.unscanned}`}>
            {FsCheckIcon({
              fill: '#999caa',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    case 0:
      return (
        <Tooltip title="No File Corruption Found" placement="top">
          <div className={`${styles.iconItem} ${styles.clean}`}>
            {FsCheckIcon({
              fill: '#45aa5b',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="File Corruption Found" placement="top">
          <div className={`${styles.iconItem} ${styles.infected}`}>
            {FsCheckIcon({
              fill: '#ff7878',
              width: '24',
              height: '24',
            })}
          </div>
        </Tooltip>
      )
  }
}

function getColorForThreatsAmount(value: Nullable<number>) {
  switch (value) {
    case null:
      return `${styles.black}`
    case 0:
      return `${styles.green}`
    default:
      return `${styles.red}`
  }
}

function ThreatsWidget() {
  const router = useNavigation()
  const scans = useSelector(getScans)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)
  const lastScanningDate = getLastScanningdDate(scans.allScans)

  const {
    isLoading,
    ransomwareThreatsCount,
    malwareThreatsCount,
    corruptedFilesystemsCount,
    malwarePageToGo,
    corruptedFilesystemsPageToGo,
    ransomwarePageToGo,
  } = useFetchAwsThreat()

  if (isLoading || isLiveAssetsLoading) {
    return <ThreatSkeletonLoader title="Threats" showDateLabel />
  }

  const handleAllThreatsWidget = () => {
    const threatsDrillDownLink = DashboardRoutes.assetsThreatsTableRoute(
      DashboardTab.EBS
    )
    router.push(threatsDrillDownLink)
  }

  const handleActiveRansomware = () => {
    const ransomwareDrillDownLink = DashboardRoutes.assetsThreatsWithParams(
      String(ransomwarePageToGo),
      {
        ransomware: DashboardRoutes.Params.ALL,
      }
    )
    router.push(ransomwareDrillDownLink)
  }

  const handleActiveMalware = () => {
    const malwareDrillDownLink = DashboardRoutes.assetsThreatsWithParams(
      String(malwarePageToGo),
      {
        malware: DashboardRoutes.Params.ALL,
      }
    )
    router.push(malwareDrillDownLink)
  }

  const handleFileSystemCorruption = () => {
    const fsCheckDrillDownLink = DashboardRoutes.assetsThreatsWithParams(
      String(corruptedFilesystemsPageToGo),
      {
        filesystem: DashboardRoutes.Params.ALL,
      }
    )
    router.push(fsCheckDrillDownLink)
  }

  return (
    <Box className={styles.threatsComponent} data-testid="threats-widget">
      <WidgetHeader title="Threats" onClick={handleAllThreatsWidget} />

      <Box className={styles.threatsTable} paddingBottom="2px">
        {lastScanningDate ? (
          <Box display="flex" fontSize="14px" alignItems="center" gap="4px">
            <Typography fontWeight="600" fontSize="14px">
              Last scan date:
            </Typography>
            <DateTimeTooltip direction="row" date={lastScanningDate} />
          </Box>
        ) : (
          <Typography fontWeight="600" fontSize="14px">
            No scans were found in tenant
          </Typography>
        )}
        <Box
          className={styles.threatsRow}
          data-testid="threats-widget-ransomware"
          onClick={handleActiveRansomware}
        >
          {getRansomwareIcon(ransomwareThreatsCount)}
          <Box
            className={`${styles.threatsAmount}
          ${getColorForThreatsAmount(ransomwareThreatsCount)}
          `}
            data-testid="ransomware-count"
          >
            {ransomwareThreatsCount}
          </Box>
          <Box className={`${styles.threatsDescription}`}>
            Active ransomware
          </Box>
        </Box>
        <Box
          className={styles.threatsRow}
          data-testid="threats-widget-malware"
          onClick={handleActiveMalware}
        >
          {getMalwareIcon(malwareThreatsCount)}
          <Box
            className={`${styles.threatsAmount}
          ${getColorForThreatsAmount(malwareThreatsCount)}
          `}
            data-testid="malware-count"
          >
            {malwareThreatsCount}
          </Box>
          <Box className={styles.threatsDescription}>Active malware</Box>
        </Box>
        <Box
          className={styles.threatsRow}
          data-testid="threats-widget-fs-check"
          onClick={handleFileSystemCorruption}
        >
          {getFsCheckIcon(corruptedFilesystemsCount)}
          <Box
            className={`${styles.threatsAmount}
        ${getColorForThreatsAmount(corruptedFilesystemsCount)}
        `}
          >
            {corruptedFilesystemsCount}
          </Box>
          <Box className={styles.threatsDescription}>
            {LangHelper.pluralizeEn(
              'File System corruption',
              corruptedFilesystemsCount
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ThreatsWidget
