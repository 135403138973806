import { RefObject, useEffect } from 'react'
import { EngineMouseCallback } from '@lib/engine-types'

function useOnClickOutside(
  ref: RefObject<any>,
  handler: EngineMouseCallback
): void {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export default useOnClickOutside
