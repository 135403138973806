/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react'
import {
  TabName,
  useTabsActions,
  useTabStore,
} from '@features/reports/schedules/use-data-store'
import { GeneratedReportsClient } from '@lib/clients/reports/generated-reports'
import SystemHelper from '../../../../lib/helpers/system.helper'
import { usePagination } from '@lib/hooks'
import { ReportGenerated } from 'blues-corejs/dist/models/reports/report-generated'
import { transformDateRangeForApi } from '@components-composite/date-range-picker-with-call-api/transform-date-range-for-api'

const GeneratedReportRequest = new GeneratedReportsClient()

export const useFetchHistoryReport = () => {
  const { setTabData, setLoading } = useTabsActions()
  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()
  const { tabs } = useTabStore()
  const storeReportList = tabs.history?.data ?? []
  const filterValues = tabs.history?.filterValues || null

  const handleFetchSuccess = ({
    reportList,
    nextPageToken,
  }: {
    reportList: Array<ReportGenerated>
    nextPageToken?: string
  }) => {
    setTabData(TabName.history, reportList)
    updatePageTokenAndFlagFetched(nextPageToken)
  }

  const resetData = () => {
    setTabData(TabName.history, [])
    resetPagination()
  }

  const updateReportsOnRefreshInterval = (
    reportsList: Array<ReportGenerated>
  ) => {
    if (reportsList.length === 0) {
      return
    }

    const reportsMap = new Map(
      storeReportList.map((report: ReportGenerated) => [report.id, report])
    )
    const newReportItem: Array<ReportGenerated> = []
    reportsList.forEach((report) => {
      if (reportsMap.has(report.id)) {
        reportsMap.set(report.id, report)
      } else {
        newReportItem.push(report)
      }
    })

    const result = [...newReportItem, ...Array.from(reportsMap.values())]

    setTabData(TabName.history, result)
  }

  const prepareDataForRequest = () => {
    const { start, end } = transformDateRangeForApi([
      new Date().getTime(),
      new Date().getTime(),
    ])
    const filter = {
      rangeStartAt: filterValues?.rangeStartAt || start,
      rangeEndAt: filterValues?.rangeEndAt || end,
      textSearch: filterValues?.textSearch,
      reportKind: filterValues?.reportKind,
    }

    return filter
  }

  const initialRequest = async () => {
    setLoading(TabName.history, true)

    const filter = prepareDataForRequest()

    try {
      const response = await GeneratedReportRequest.listGeneratedReport(filter)
      resetData()
      handleFetchSuccess({
        reportList: response.generatedReportList,
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        'useFetchHistoryReport: Error fetching generated reports with filter'
      )
    } finally {
      setLoading(TabName.history, false)
    }
  }

  const paginationRequest = async () => {
    if (!pageToken) {
      return
    }

    setLoading(TabName.history, true)

    try {
      const response =
        await GeneratedReportRequest.listGeneratedReportPagination(pageToken)
      handleFetchSuccess({
        reportList: [...storeReportList, ...response.generatedReportList],
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        'useFetchHistoryReport: Error fetching generated reports with pagination'
      )
    } finally {
      setLoading(TabName.history, false)
    }
  }

  const fetchOnRefreshInterval = async () => {
    const filter = prepareDataForRequest()

    try {
      const response = await GeneratedReportRequest.listGeneratedReport(filter)
      updateReportsOnRefreshInterval(response.generatedReportList)
    } catch (error) {
      SystemHelper.sendSentryIfProd(
        'useFetchHistoryReport: Error fetching interval generated reports with filter'
      )
    }
  }

  useEffect(() => {
    initialRequest()
  }, [filterValues])

  return {
    paginationRequest,
    fetchOnRefreshInterval,
    initialRequest,
  }
}
