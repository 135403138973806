import React, { memo } from 'react'
import ButtonsRowElement from '@lib/interfaces/buttons-row-element.interface'
import { Button } from '@mui/material'
import Link from '@lib/router/navigation-strategies/link'

interface Props {
  data?: ReadonlyArray<ButtonsRowElement>
}

function ButtonsRow({ data }: Props) {
  return (
    <div className="wrap-1648749621449">
      {data?.map((dataItem) =>
        dataItem.onClick ? (
          <Button
            key={dataItem.label}
            className={`roundedButton ${dataItem.variant}Button js${dataItem.variant}Btn`}
            type="button"
            onClick={dataItem.onClick}
            disabled={dataItem.isDisabled}
          >
            {dataItem.label}
          </Button>
        ) : (
          <Link key={dataItem.label} to={dataItem.link ?? '#'} passHref>
            <Button
              className={`roundedButton ${dataItem.variant}Button js${dataItem.variant}Link`}
              type="button"
              disabled={dataItem.isDisabled}
            >
              {dataItem.label}
            </Button>
          </Link>
        )
      )}
    </div>
  )
}

export default memo(ButtonsRow)
