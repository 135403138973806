import React from 'react'

function BlueCheckIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67677 13.975L4.20177 10.5L3.01843 11.675L7.67677 16.3333L17.6768 6.33333L16.5018 5.15833L7.67677 13.975Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default BlueCheckIcon
