/* eslint-disable import/no-extraneous-dependencies */
import { PreloaderConstants } from '@lib/constants'
import usePreloader from '@lib/hooks/usePreloader'
import ValueInterface from '@lib/interfaces/value.interface'
import {
  setAssetsEbsVulnerabilitiesFilters,
  setSnapshotsVulnerabilitiesFilters,
} from '@store/actions/dashboard.action'
import {
  getEbsVulnerabilitiesSelectedFilters,
  getSnapshotsVulnerabilitiesSelectedFilters,
} from '@store/selectors/dashboard.selector'
import {
  getBackupsMapped,
  getLiveAssets,
} from '@store/selectors/list-live-assets.selector'
import {
  GetSnapshotsVulnerabilitiesFilters,
  GetVolumesVulnerabilitiesFilters,
  RiskLevel,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSnapshotVulnerabilitiesPossibleFilters,
  getVolumeVulnerabilitiesPossibleFilters,
} from './get-possible-filters'
import { getRiskNameFormQuery } from '@components-complex/dashboard-pages-v3/assets-with-vulnerabilities/utils'
import {
  DashboardRoutes,
  VulnerabilitiesTabs,
} from '@lib/router/routes/dashboard/dashboard'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { useSearchParams } from 'react-router-dom'

enum Filters {
  EBS_VULNERABILITIES = 'ebsVulnerabilities',
  SNAPSHOTS_VULNERABILITIES = 'snapshotVulnerabilityTypes',
  RISK_TYPE = 'riskType',
}

type CustomQuery = {
  t?: string
  vulnerability?: string
  risk?: string
}

function useAssetsWithVulnerabilitiesData() {
  const [tab, setTab] = useState<number>(0)
  const router = useNavigation()
  const [searchParams] = useSearchParams()
  const queryRisk = searchParams.get('risk') || undefined
  const riskFromQuery = getRiskNameFormQuery(queryRisk)
  const isLoading = usePreloader(PreloaderConstants.REQUEST_DASHBOARD_DATA)

  const customQuery: CustomQuery = {
    t: searchParams.get('t') || undefined,
    risk: queryRisk,
    vulnerability: searchParams.get('vulnerability') || undefined,
  }

  const prevQuery = useRef<CustomQuery>(customQuery)

  const [filterSearch, setFilterSearch] = useState<string>('')

  const dispatch = useDispatch()

  const vulnerabilitiesDetectionStrategy = useMemo(
    () => new VulnerabilitiesDetectionStrategy(),
    []
  )

  const { ebsVolumes, ebsSnapshots, ec2Instances } = useSelector(getLiveAssets)
  const { lastBackups } = useSelector(getBackupsMapped)

  const vulnerabilitiesVolumeFilters = new GetVolumesVulnerabilitiesFilters({
    volumes: ebsVolumes,
    instances: ec2Instances,
    snapshots: ebsSnapshots,
    lastBackups,
    riskFromQuery: riskFromQuery,
  }).execute(vulnerabilitiesDetectionStrategy)

  const vulnerabilitiesSnapshotFilters = new GetSnapshotsVulnerabilitiesFilters(
    {
      snapshots: ebsSnapshots,
      riskFromQuery: riskFromQuery,
    }
  ).execute(vulnerabilitiesDetectionStrategy)
  const selectedFilters = useSelector(getEbsVulnerabilitiesSelectedFilters)

  const possibleFilters = getVolumeVulnerabilitiesPossibleFilters(
    vulnerabilitiesVolumeFilters,
    selectedFilters,
    vulnerabilitiesDetectionStrategy
  )

  const possibleSnapshotsFilters = getSnapshotVulnerabilitiesPossibleFilters(
    vulnerabilitiesSnapshotFilters
  )

  const selectedSnapshotsFilters = useSelector(
    getSnapshotsVulnerabilitiesSelectedFilters
  )

  const selectedVulnerabilitiesFiltersForVolumes = selectedFilters.find(
    (filters) => filters.name === Filters.EBS_VULNERABILITIES
  ) as ValueInterface

  const selectedRisksFiltersForVolumes = selectedFilters.find(
    (filters) => filters.name === Filters.RISK_TYPE
  ) as ValueInterface

  const defaultSelectedVulnerabilities = useMemo<Array<string>>(() => {
    if (tab === VulnerabilitiesTabs.SNAPSHOTS) {
      return []
    }

    return selectedVulnerabilitiesFiltersForVolumes &&
      selectedVulnerabilitiesFiltersForVolumes?.options
      ? selectedVulnerabilitiesFiltersForVolumes.options.map(
          (option) => option.name
        )
      : []
  }, [])

  const defaultSelectedRisks = useMemo<Array<string>>(() => {
    if (tab === VulnerabilitiesTabs.SNAPSHOTS) {
      return []
    }

    return selectedRisksFiltersForVolumes &&
      selectedRisksFiltersForVolumes?.options
      ? selectedRisksFiltersForVolumes.options.map(
          (option) => option.label as string
        )
      : []
  }, [])

  const possibleFiltersByTab = useMemo(() => {
    const filterByTab =
      tab === VulnerabilitiesTabs.SNAPSHOTS
        ? possibleSnapshotsFilters
        : possibleFilters

    const notEmptyFilters = filterByTab.filter(
      (filter) => filter.options?.length
    )

    return notEmptyFilters
  }, [tab, possibleFilters, possibleSnapshotsFilters])

  const selectedFiltersByTab = useMemo(
    () =>
      tab === VulnerabilitiesTabs.SNAPSHOTS
        ? selectedSnapshotsFilters
        : selectedFilters,
    [tab, selectedFilters, selectedSnapshotsFilters]
  )

  const [selectedVulnerabilities, setSelectedVulnerabilities] = useState<
    Array<string>
  >(defaultSelectedVulnerabilities)

  const [
    selectedSnapshotsVulnerabilities,
    setSelectedSnapshotsVulnerabilities,
  ] = useState<Array<string>>(defaultSelectedVulnerabilities)

  const [selectedRisks, setSelectedRisks] =
    useState<Array<string>>(defaultSelectedRisks)

  const [selectedSnapshotsRisk, setSelectedSnapshotsRisk] =
    useState<Array<string>>(defaultSelectedRisks)

  const vulnerabilitiesTagsData =
    tab === VulnerabilitiesTabs.SNAPSHOTS
      ? selectedSnapshotsVulnerabilities
      : selectedVulnerabilities

  const risksTagsData =
    tab === VulnerabilitiesTabs.SNAPSHOTS
      ? selectedSnapshotsRisk
      : selectedRisks

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    const riskFilter = newFilters.find(
      (filter) => filter.name === Filters.RISK_TYPE
    )

    if (tab === VulnerabilitiesTabs.SNAPSHOTS) {
      const vulnerabilityFilter = newFilters.find(
        (filter) => filter.name === Filters.SNAPSHOTS_VULNERABILITIES
      )

      dispatch(setSnapshotsVulnerabilitiesFilters(newFilters))

      if (vulnerabilityFilter) {
        setSelectedSnapshotsVulnerabilities(
          vulnerabilityFilter.options?.map((option) => option.name) || []
        )
      }

      if (riskFilter) {
        setSelectedSnapshotsRisk(
          riskFilter.options?.map((option) => option.label as string) || []
        )
      }

      return
    }

    dispatch(setAssetsEbsVulnerabilitiesFilters(newFilters))

    const vulnerabilityFilter = newFilters.find(
      (filter) => filter.name === Filters.EBS_VULNERABILITIES
    )

    if (vulnerabilityFilter) {
      setSelectedVulnerabilities(
        vulnerabilityFilter.options?.map((option) => option.name) || []
      )
    }

    if (riskFilter) {
      setSelectedRisks(
        riskFilter.options?.map((option) => option.label as string) || []
      )
    }
  }

  const onVulnerabilityFilterChange = (vulnerability: string) => {
    if (tab === VulnerabilitiesTabs.SNAPSHOTS) {
      const vulnerabilityFilter = selectedFiltersByTab.find(
        (filter) => filter.name === Filters.SNAPSHOTS_VULNERABILITIES
      )

      if (!vulnerabilityFilter) {
        return
      }

      const vulnerabilityFilterOptions = vulnerabilityFilter.options?.filter(
        (option) => option.value
      )

      const filteredVulnerabilityOptions = vulnerabilityFilterOptions?.filter(
        (option) => option.name !== vulnerability
      )

      if (
        customQuery.vulnerability &&
        customQuery.vulnerability === vulnerability
      ) {
        router.push(
          DashboardRoutes.assetsVulnerabilitiesTableRoute(String(tab))
        )
      }

      setSelectedSnapshotsVulnerabilities(
        filteredVulnerabilityOptions?.map((option) => option.name) || []
      )

      dispatch(
        setSnapshotsVulnerabilitiesFilters(
          selectedFiltersByTab.map((filter) => {
            if (filter.name === Filters.SNAPSHOTS_VULNERABILITIES) {
              return {
                ...filter,
                options: filteredVulnerabilityOptions,
              }
            }
            return filter
          })
        )
      )
      return
    }

    const vulnerabilityFilter = selectedFiltersByTab.find(
      (filter) => filter.name === 'ebsVulnerabilities'
    )
    if (!vulnerabilityFilter) {
      return
    }
    const vulnerabilityFilterOptions = vulnerabilityFilter.options?.filter(
      (option) => option.value
    )

    const filteredVulnerabilityOptions = vulnerabilityFilterOptions?.filter(
      (option) => option.name !== vulnerability
    )

    if (
      customQuery.vulnerability &&
      customQuery.vulnerability === vulnerability
    ) {
      router.push(DashboardRoutes.assetsVulnerabilitiesTableRoute(String(tab)))
    }

    setSelectedVulnerabilities(
      filteredVulnerabilityOptions?.map((option) => option.name) || []
    )

    dispatch(
      setAssetsEbsVulnerabilitiesFilters(
        selectedFiltersByTab.map((filter) => {
          if (filter.name === 'ebsVulnerabilities') {
            return {
              ...filter,
              options: filteredVulnerabilityOptions,
            }
          }
          return filter
        })
      )
    )
  }

  const onRisksFilterChange = (risk: string) => {
    const isRiskEqualQuery =
      customQuery.risk &&
      Number(customQuery.risk) === RiskLevel[risk as keyof typeof RiskLevel]

    if (tab === VulnerabilitiesTabs.SNAPSHOTS) {
      const riskFilter = selectedFiltersByTab.find(
        (filter) => filter.name === Filters.RISK_TYPE
      )

      if (!riskFilter) {
        return
      }

      const riskFilterOptions = riskFilter.options?.filter(
        (option) => option.value
      )

      const filteredRiskOptions = riskFilterOptions?.filter(
        (option) => option.label !== risk
      )

      if (isRiskEqualQuery) {
        router.push(
          DashboardRoutes.assetsVulnerabilitiesTableRoute(String(tab))
        )
      }

      setSelectedSnapshotsRisk(
        filteredRiskOptions?.map((option) => option.label as string) || []
      )

      dispatch(
        setSnapshotsVulnerabilitiesFilters(
          selectedFiltersByTab.map((filter) => {
            if (filter.name === Filters.RISK_TYPE) {
              return {
                ...filter,
                options: filteredRiskOptions,
              }
            }
            return filter
          })
        )
      )
      return
    }

    const vulnerabilityFilter = selectedFiltersByTab.find(
      (filter) => filter.name === Filters.RISK_TYPE
    )
    if (!vulnerabilityFilter) {
      return
    }
    const vulnerabilityFilterOptions = vulnerabilityFilter.options?.filter(
      (option) => option.value
    )

    const filteredVulnerabilityOptions = vulnerabilityFilterOptions?.filter(
      (option) => option.label !== risk
    )

    if (isRiskEqualQuery) {
      router.push(DashboardRoutes.assetsVulnerabilitiesTableRoute(String(tab)))
    }

    setSelectedRisks(
      filteredVulnerabilityOptions?.map((option) => option.label as string) ||
        []
    )

    dispatch(
      setAssetsEbsVulnerabilitiesFilters(
        selectedFiltersByTab.map((filter) => {
          if (filter.name === Filters.RISK_TYPE) {
            return {
              ...filter,
              options: filteredVulnerabilityOptions,
            }
          }
          return filter
        })
      )
    )
  }

  const onTabChange = (newTab: number) => {
    setTab(newTab)

    setFilterSearch('')
  }

  useEffect(() => {
    if (customQuery.t) {
      setTab(Number(customQuery.t))
    }
  }, [])

  useEffect(() => {
    dispatch(setAssetsEbsVulnerabilitiesFilters([]))
    dispatch(setSnapshotsVulnerabilitiesFilters([]))

    return () => {
      dispatch(setAssetsEbsVulnerabilitiesFilters([]))
      dispatch(setSnapshotsVulnerabilitiesFilters([]))
    }
  }, [])

  useEffect(() => {
    if (
      customQuery.t &&
      tab !== Number(customQuery.t) &&
      prevQuery.current.t !== customQuery.t
    ) {
      return
    }

    if (customQuery.vulnerability) {
      const value = customQuery.vulnerability
      if (
        customQuery.t &&
        customQuery.t === String(VulnerabilitiesTabs.SNAPSHOTS)
      ) {
        if (
          possibleSnapshotsFilters.some(
            (filter) =>
              filter.name === Filters.SNAPSHOTS_VULNERABILITIES &&
              filter.options?.find((option) => option.name === value)
          )
        ) {
          const vulnerabilityFilter = possibleSnapshotsFilters.find(
            (filter) =>
              filter.name === Filters.SNAPSHOTS_VULNERABILITIES &&
              filter?.options?.find((option) => option.name === value)
          )

          if (!vulnerabilityFilter) {
            return
          }

          const filteredVulnerabilities: ValueInterface = {
            ...vulnerabilityFilter,
            options: vulnerabilityFilter?.options
              ?.map((option) => {
                if (option.name === value) {
                  return {
                    ...option,
                    value: true,
                  }
                }

                return option
              })
              .filter((option) => option.value),
          }

          setSelectedSnapshotsVulnerabilities(
            filteredVulnerabilities.options?.map((option) => option.name) ?? []
          )

          const otherFilters = selectedFilters.filter(
            (filter) => filter.name !== Filters.SNAPSHOTS_VULNERABILITIES
          ) as Array<ValueInterface>

          dispatch(
            setSnapshotsVulnerabilitiesFilters([
              ...otherFilters,
              filteredVulnerabilities,
            ])
          )

          return
        }
      }

      if (
        possibleFiltersByTab.some(
          (filter) =>
            filter.name === 'ebsVulnerabilities' &&
            filter.options?.find((option) => option.name === value)
        )
      ) {
        const vulnerabilityFilter = possibleFiltersByTab.find(
          (filter) =>
            filter.name === 'ebsVulnerabilities' &&
            filter?.options?.find((option) => option.name === value)
        )

        if (!vulnerabilityFilter) {
          return
        }

        const filteredVulnerabilities: ValueInterface = {
          ...vulnerabilityFilter,
          options: vulnerabilityFilter?.options
            ?.map((option) => {
              if (option.name === value) {
                return {
                  ...option,
                  value: true,
                }
              }

              return option
            })
            .filter((option) => option.value),
        }

        setSelectedVulnerabilities(
          filteredVulnerabilities.options?.map((option) => option.name) ?? []
        )

        const otherFilters = selectedFilters.filter(
          (filter) => filter.name !== 'ebsVulnerabilities'
        ) as Array<ValueInterface>

        dispatch(
          setAssetsEbsVulnerabilitiesFilters([
            ...otherFilters,
            filteredVulnerabilities,
          ])
        )

        return
      }
    }

    if (customQuery.risk) {
      const value = customQuery.risk
      if (
        customQuery.t &&
        customQuery.t === String(VulnerabilitiesTabs.SNAPSHOTS)
      ) {
        if (
          possibleSnapshotsFilters.some(
            (filter) =>
              filter.name === Filters.RISK_TYPE &&
              filter.options?.find((option) => option.name === value)
          )
        ) {
          const vulnerabilityFilter = possibleSnapshotsFilters.find(
            (filter) =>
              filter.name === Filters.RISK_TYPE &&
              filter?.options?.find((option) => option.name === value)
          )

          if (!vulnerabilityFilter) {
            return
          }

          const filteredVulnerabilities: ValueInterface = {
            ...vulnerabilityFilter,
            options: vulnerabilityFilter?.options
              ?.map((option) => {
                if (option.name === value) {
                  return {
                    ...option,
                    value: true,
                  }
                }

                return option
              })
              .filter((option) => option.value),
          }

          setSelectedSnapshotsRisk(
            filteredVulnerabilities.options?.map(
              (option) => option.label as string
            ) ?? []
          )

          const otherFilters = selectedFilters.filter(
            (filter) => filter.name !== Filters.RISK_TYPE
          ) as Array<ValueInterface>

          dispatch(
            setSnapshotsVulnerabilitiesFilters([
              ...otherFilters,
              filteredVulnerabilities,
            ])
          )

          return
        }
      }

      if (
        possibleFiltersByTab.some(
          (filter) =>
            filter.name === Filters.RISK_TYPE &&
            filter.options?.find((option) => option.name === value)
        )
      ) {
        const vulnerabilityFilter = possibleFiltersByTab.find(
          (filter) =>
            filter.name === Filters.RISK_TYPE &&
            filter?.options?.find((option) => option.name === value)
        )

        if (!vulnerabilityFilter) {
          return
        }

        const filteredVulnerabilities: ValueInterface = {
          ...vulnerabilityFilter,
          options: vulnerabilityFilter?.options
            ?.map((option) => {
              if (option.name === value) {
                return {
                  ...option,
                  value: true,
                }
              }

              return option
            })
            .filter((option) => option.value),
        }

        setSelectedRisks(
          filteredVulnerabilities.options?.map(
            (option) => option.label as string
          ) ?? []
        )

        const otherFilters = selectedFilters.filter(
          (filter) => filter.name !== Filters.RISK_TYPE
        ) as Array<ValueInterface>

        dispatch(
          setAssetsEbsVulnerabilitiesFilters([
            ...otherFilters,
            filteredVulnerabilities,
          ])
        )

        return
      }
    }
  }, [
    tab,
    customQuery.risk,
    customQuery.vulnerability,
    possibleFiltersByTab.length,
    possibleSnapshotsFilters.length,
  ])

  return {
    isLoading,
    filters: {
      possibleFiltersByTab,
      selectedFiltersByTab,
      onFiltersChange,
      filterSearch,
      setFilterSearch,
    },

    tags: {
      vulnerabilitiesTagsData,
      risksTagsData,
      onVulnerabilityFilterChange,
      onRisksFilterChange,
    },
    tabs: {
      tab,
      onTabChange,
      Tabs: VulnerabilitiesTabs,
    },
  }
}

export { useAssetsWithVulnerabilitiesData }
