import ContentBlock from '@components-composite/content-block/ContentBlock'
import SimpleSelect from '@components-simple/simple-select/SimpleSelect'
import PreloaderConstants from '@lib/constants/preloader.constant'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import { DefinedDateRangeTuple } from '@lib/engine-types'
import FilterFactory from '@lib/factories/filter.factory'
import TimeHelper from '@lib/helpers/time.helper'
import usePreloader from '@lib/hooks/usePreloader'
import ValueInterface from '@lib/interfaces/value.interface'
import { WebhooksInvocation } from '@lib/interfaces/webhooks.interface'
import WebhookModel from '@lib/models/webhook.model'
import {
  requestWebhooksInvocations,
  setWebhooksInvocations,
} from '@store/actions/webhook.action'
import { getWebhooksInvocations } from '@store/selectors/webhook.selector'
import { Chart } from 'chart.js'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import drawWebhookEventsTimelineChart from './drawWebhookEventsTimelineChart'

interface Props {
  webhook: WebhookModel
}

function WebhookEventsTimeline({ webhook }: Props) {
  const dispatch = useDispatch()
  const chartRef = useRef<HTMLCanvasElement>(null)
  const loading: boolean = usePreloader(
    PreloaderConstants.REQUEST_WEBHOOK_INVOCATIONS
  )
  const dateRangeFilters = FilterFactory.getWebhooksDateRangeFilters()
  const [noDataMessage, setNoDataMessage] = useState<string>('')
  const [chartInstance, setChartInstance] = useState<Chart>()
  const [labels, setChartLabels] = useState<Array<string>>([])
  const webhooksInvocations = useSelector(getWebhooksInvocations)
  const [dateFilters, setSelectedDate] =
    useState<ValueInterface>(dateRangeFilters)

  const onDateFiltersChange = (newFilters: ValueInterface) => {
    setSelectedDate(newFilters)
  }

  const selectedDateFilterOption = useMemo<ValueInterface | undefined>(
    () =>
      dateFilters.options?.find((option) => option.value === dateFilters.value),
    [dateFilters]
  )

  useEffect(() => {
    dispatch(setWebhooksInvocations([]))
    if (!webhook || !selectedDateFilterOption) {
      return
    }
    const range: DefinedDateRangeTuple = [
      TimeHelper.momentToTimestamp(
        TimeHelper.currentMinusHours(
          Number(selectedDateFilterOption.extraValue)
        )
      ),
      TimeHelper.momentToTimestamp(TimeHelper.momentNow()),
    ]
    const periods: Array<DefinedDateRangeTuple> = TimeHelper.generatePeriods(
      range,
      Number(selectedDateFilterOption.supplementalValue)
    )

    setChartLabels(
      periods.map(([start]) =>
        TimeHelper.timestampFormat(
          start,
          TimeFormatConstants.SHORT_DATETIME_FORMAT
        )
      )
    )
    dispatch(requestWebhooksInvocations([webhook.getId()], periods))
  }, [webhook, selectedDateFilterOption])

  useEffect(() => {
    if (
      webhooksInvocations.every(
        (item) => item.webhookInvocationDataListList?.length === 0
      )
    ) {
      setNoDataMessage('There are no webhooks data to display.')
      return
    }

    setNoDataMessage('')

    if (chartInstance) {
      chartInstance.destroy()
    }

    const emptyRecord: WebhooksInvocation = {
      webhookId: webhook.getId(),
      successfulInvocations: 0,
      unsuccessfulInvocations: 0,
    }
    const newChartInstance = drawWebhookEventsTimelineChart(
      {
        labels,
        webhook,
        invocations: webhooksInvocations.map(
          ({ webhookInvocationDataListList }) =>
            webhookInvocationDataListList?.[0] || emptyRecord
        ),
      },
      chartRef.current
    )

    setChartInstance(newChartInstance)
  }, [webhooksInvocations, chartRef.current])

  return (
    <div className="widgetWrap wrap-1648744344112">
      <div className="widgetHeader timelineHeader">
        <div className="filters">
          <SimpleSelect
            data={dateFilters}
            onChange={(value) => onDateFiltersChange(value)}
            typeFunction={Number}
            disabled={loading}
          />
        </div>
      </div>
      <ContentBlock
        data={webhooksInvocations}
        loading={loading}
        noDataMessage={noDataMessage}
      >
        <div className="webhooksChartWrapper">
          <canvas ref={chartRef}></canvas>
        </div>
        <div id="legend-container" className="webhooksChartLegend"></div>
      </ContentBlock>
    </div>
  )
}

export default memo(WebhookEventsTimeline)
