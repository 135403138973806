import React from 'react'

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M13.75 5.00066H5C4.33696 5.00066 3.70107 5.26405 3.23223 5.73289C2.76339 6.20173 2.5 6.83761 2.5 7.50066V25.0007C2.5 25.6637 2.76339 26.2996 3.23223 26.7684C3.70107 27.2373 4.33696 27.5007 5 27.5007H22.5C23.163 27.5007 23.7989 27.2373 24.2678 26.7684C24.7366 26.2996 25 25.6637 25 25.0007V16.2507"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.125 3.12571C23.6223 2.62843 24.2967 2.34906 25 2.34906C25.7033 2.34906 26.3777 2.62843 26.875 3.12571C27.3723 3.62299 27.6516 4.29745 27.6516 5.00071C27.6516 5.70397 27.3723 6.37843 26.875 6.87571L15 18.7507L10 20.0007L11.25 15.0007L23.125 3.12571Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
