/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { EBS, EC2, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist/models'
import { computeAccountName } from './compute-account-name'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { CopyToClipboardIcon } from '@features/common'
import { useGetCloudConnectorByAsset } from '@lib/hooks/api-hooks'

function RowTitle({ title }: { title: string }) {
  return (
    <Typography
      fontWeight="500"
      fontSize="14px"
      marginRight="5px"
      variant="caption"
    >
      {title}:
    </Typography>
  )
}

const CLIPBOARD_ICON_SIZE = 16

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function AccountName({ asset }: { asset: Asset }) {
  const { cloudConnector, isCloudConnectorLoading } =
    useGetCloudConnectorByAsset(asset)

  if (isCloudConnectorLoading) {
    return <Skeleton />
  }

  if (!cloudConnector) {
    return null
  }

  const accountName = computeAccountName(cloudConnector)

  return (
    <Box>
      <RowTitle title="Account ID" />
      <Typography fontSize="14px" fontWeight="400" variant="caption">
        {accountName}
      </Typography>
      <CopyToClipboardIcon
        text={cloudConnector.awsAccountId}
        fontSize={CLIPBOARD_ICON_SIZE}
      />
    </Box>
  )
}
