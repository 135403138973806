import React, { memo } from 'react'
import { Typography } from '@mui/material'
import { getIconByRisk } from './icons'
import { RiskBlockContainer, StyledRiskBlock } from './styles'
import { renderStatusRisk } from './define-status-risk'
import { Risk } from './types'
import Stack from '@mui/material/Stack'

interface Props {
  variant: Risk[keyof Risk]
  count: number
  onClick?: () => void
}

function RiskBlock({ variant, count, onClick }: Props) {
  const isCLickable = Boolean(onClick)
  return (
    <RiskBlockContainer clickable={isCLickable} onClick={onClick}>
      {getIconByRisk(variant)}
      <StyledRiskBlock>
        <Typography
          data-testid="risk-count"
          lineHeight="22px"
          variant="caption"
          fontSize="22px"
          fontWeight="600"
        >
          {count}
        </Typography>
        <Typography
          variant="caption"
          data-testid="risk-variant"
          fontSize="14px"
          fontWeight="400"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {renderStatusRisk(variant)}
          </Stack>
        </Typography>
      </StyledRiskBlock>
    </RiskBlockContainer>
  )
}

export default memo(RiskBlock)
