/* eslint-disable import/no-extraneous-dependencies */
import {
  CellWithDateTimeTooltip,
  CellWithLink,
  createColumn,
  HeaderWithLabel,
  StatusCircle,
} from '@components/table'
import React from 'react'
import {
  GenericHostInventoryOutput,
  SummarizedGenericHostsDataOutput,
} from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'
import NumHelper from '@lib/helpers/num.helper'
import { Nullable } from '@lib/engine-types'
import { INVENTORY_TABLE_MIN_HEIGHT } from '../inventory-tables-complex'
import { customColorResolver } from '@components-complex/dashboard-pages-v3/utils'
import { AssetRoutes } from '@lib/router'

type InputData = {
  summarizedData: SummarizedGenericHostsDataOutput
}

export function getGenericHostsTableColumnDefinitions({
  summarizedData,
}: InputData) {
  return [
    createColumn<GenericHostInventoryOutput>('isInfected', {
      enableSorting: false,
      size: 20,
      cell: ({ row, getValue }) => (
        <StatusCircle
          colorResolver={customColorResolver(row.original.lastScan)}
          isHealthy={!getValue() as unknown as boolean}
        />
      ),
    }),
    createColumn<GenericHostInventoryOutput>('genericHostName', {
      enableSorting: false,
      header: () => (
        <HeaderWithLabel
          title="Host Name"
          label={String(summarizedData.genericHostsCount)}
        />
      ),
      meta: {
        height: INVENTORY_TABLE_MIN_HEIGHT,
      },
      cell: ({ getValue, row }) => {
        const genericHostName = row.original.genericHostName
        const href = AssetRoutes.assetId(row.original.uuid)

        return (
          <CellWithLink
            href={href}
            value={String(getValue())}
            label={genericHostName}
          />
        )
      },
    }),
    createColumn<GenericHostInventoryOutput>('threats', {
      meta: {
        textAlign: 'right',
      },
      header: () => {
        const threatsCount = NumHelper.getNumberUSFormatted(
          Number(summarizedData.threatsCount),
          0
        )
        return <HeaderWithLabel title="Threats" label={String(threatsCount)} />
      },
    }),
    // hidden 26.01.2024 by Eswar
    // createColumn<GenericHostInventoryOutput>('cpuCores', {
    //   meta: {
    //     textAlign: 'right',
    //   },
    //   header: 'CPU cores',
    // }),
    // createColumn<GenericHostInventoryOutput>('osName', {
    //   header: 'OS type',
    //   cell: ({ getValue }) => {
    //     const osKind = getValue() as unknown as OsKindString
    //     return StrHelper.getOsName(osKind)
    //   },
    // }),
    createColumn<GenericHostInventoryOutput>('lastScan', {
      header: 'Last scan',
      cell: ({ getValue }) => {
        const lastScan = getValue() as unknown as Nullable<Date>

        return <CellWithDateTimeTooltip date={lastScan} />
      },
    }),
    // hidden 26.01.2024 by Eswar
    // createColumn<GenericHostInventoryOutput>('lastBackup', {
    //   enableSorting: false,
    //   header: 'Last Backup',
    //   cell: ({ getValue }) => {
    //     const lastBackup = getValue() as unknown as Nullable<Date>
    //
    //     return <CellWithDateTimeTooltip date={lastBackup} />
    //   },
    // }),
  ]
}
