/* eslint-disable import/no-extraneous-dependencies */
import { Ec2InventoryPossibleFilters } from 'blues-corejs/dist/use_cases/list_inventory_instances_possible_filters/types'
import { FilterIdDashboard } from '@lib/constants/grpc/filter.constant'
import { EC2 } from 'blues-corejs/dist'
/* eslint-disable import/no-extraneous-dependencies */
import RedStackModel from '@lib/models/red-stack.model'
import { getAliasNamesForAccounts } from '../utils'

export function getDataForPossibleEc2InventoryFilters(
  filterEc2Inventory: Ec2InventoryPossibleFilters
) {
  return [
    {
      id: FilterIdDashboard.ACCOUNT_IDS,
      accountIds: filterEc2Inventory?.accountIds,
    },
    {
      id: FilterIdDashboard.REGIONS,
      regions: filterEc2Inventory?.regions,
    },
    {
      id: FilterIdDashboard.COVERED,
      covered: filterEc2Inventory?.covered,
    },
    {
      id: FilterIdDashboard.BACKUPS_ON_SCHEDULE,
      backupsOnSchedule: filterEc2Inventory?.backupsOnSchedule,
    },
  ]
}

export function getAliasNamesForEC2(
  ec2List: Array<EC2>,
  allActiveRedStacks: Array<RedStackModel>
) {
  if (allActiveRedStacks.length === 0 || !ec2List) {
    return {}
  }
  const accountIds: Set<string> = new Set()

  ec2List.forEach((ec2) => {
    accountIds.add(ec2.awsAccountId)
  })

  return getAliasNamesForAccounts(allActiveRedStacks, accountIds)
}
