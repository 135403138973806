import { SvgIcon, SvgIconProps } from '@mui/material'

const Assets = ({ size = 24, ...props }: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        fill: 'none',
        strokeWidth: 1.5,
        ...props.sx,
      }}
      {...props}
    >
      <g clipPath="url(#clip0_40000070_10325)">
        <path
          d="M5 13.5H19C19.8239 13.5 20.5 14.1761 20.5 15V19C20.5 19.8239 19.8239 20.5 19 20.5H5C4.17614 20.5 3.5 19.8239 3.5 19V15C3.5 14.1761 4.17614 13.5 5 13.5ZM4.5 17C4.5 18.3761 5.62386 19.5 7 19.5C8.37614 19.5 9.5 18.3761 9.5 17C9.5 15.6239 8.37614 14.5 7 14.5C5.62386 14.5 4.5 15.6239 4.5 17ZM5 3.5H19C19.8239 3.5 20.5 4.17614 20.5 5V9C20.5 9.82386 19.8239 10.5 19 10.5H5C4.17614 10.5 3.5 9.82386 3.5 9V5C3.5 4.17614 4.17614 3.5 5 3.5ZM4.5 7C4.5 8.37614 5.62386 9.5 7 9.5C8.37614 9.5 9.5 8.37614 9.5 7C9.5 5.62386 8.37614 4.5 7 4.5C5.62386 4.5 4.5 5.62386 4.5 7Z"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_40000070_10325">
          <rect width={size} height={size} />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default Assets
