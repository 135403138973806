import Label from 'ui-v2/src/components/ui/data-display/label'
import {
  useNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from 'ui-v2/src/hooks/queries/notifications'
import { useDisconnectSlackMutation } from 'ui-v2/src/hooks/queries/notifications/disconnect-slack'
import { NotificationsConfig } from 'ui-v2/src/lib/clients/notifications'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid2,
  Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'

const Alerts = () => {
  const { data: notificationsSettingsData } = useNotificationSettingsQuery()
  const { mutate: disconnectSlackMutate, isPending: disconnectSlackIsPending } =
    useDisconnectSlackMutation()
  const { mutate: updateSettingsMutate } =
    useUpdateNotificationSettingsMutation()

  const [sendToEmail, setSendToEmail] = useState(false)
  const [sendToSlack, setSendToSlack] = useState(false)
  const [enableSlackCheckbox, setEnableSlackCheckbox] = useState(true)
  const [prevState, setPrevState] = useState({
    sendToEmail: false,
    sendToSlack: false,
  })
  const [saveDisabled, setSaveDisabled] = useState(true)

  const handleEmailCheckboxChange = () => {
    setSendToEmail(!sendToEmail)
  }

  const handleSlackCheckboxChange = () => {
    setSendToSlack(!sendToSlack)
  }

  const handleSlackButtonClick = () => {
    if (enableSlackCheckbox) {
      if (notificationsSettingsData) {
        window.open(notificationsSettingsData.slackSetupUrl, '_blank')
      }
    } else {
      disconnectSlackMutate()
      setSendToSlack(false)
      setEnableSlackCheckbox(true)
      setPrevState((prev) => ({
        ...prev,
        sendToSlack: false,
      }))
    }
  }

  const handleSaveButtonClick = () => {
    let updatedSettings: NotificationsConfig

    setPrevState({
      sendToEmail,
      sendToSlack,
    })

    if (
      sendToEmail !== prevState.sendToEmail &&
      sendToSlack !== prevState.sendToSlack
    ) {
      updatedSettings = {
        slack: {
          sendErrors: sendToSlack,
          sendWarnings: false,
          sendInfo: false,
        },
        email: {
          sendErrors: sendToEmail,
          sendWarnings: false,
          sendInfo: false,
        },
      }
      updateSettingsMutate(updatedSettings)
    } else if (sendToEmail !== prevState.sendToEmail) {
      updatedSettings = {
        slack: {
          sendErrors: sendToSlack,
          sendWarnings: false,
          sendInfo: false,
          isDisconnected: true,
        },
        email: {
          sendErrors: true,
          sendWarnings: false,
          sendInfo: false,
        },
      }
      updateSettingsMutate(updatedSettings)
    } else if (sendToSlack !== prevState.sendToSlack) {
      updatedSettings = {
        slack: {
          sendErrors: sendToSlack,
          sendWarnings: false,
          sendInfo: false,
        },
        email: {
          sendErrors: sendToEmail,
          sendWarnings: false,
          sendInfo: false,
        },
      }
      updateSettingsMutate(updatedSettings)
    }
  }

  useEffect(() => {
    const hasChanged =
      sendToEmail !== prevState.sendToEmail ||
      sendToSlack !== prevState.sendToSlack
    setSaveDisabled(!hasChanged)
  }, [sendToEmail, sendToSlack, prevState])

  useEffect(() => {
    if (notificationsSettingsData) {
      if (
        notificationsSettingsData.emailNotificationSettings &&
        notificationsSettingsData.emailNotificationSettings.sendErrors
      ) {
        setSendToEmail(true)
        setPrevState((prev) => ({
          ...prev,
          sendToEmail: true,
        }))
      }

      if (
        notificationsSettingsData.slackNotificationSettings &&
        notificationsSettingsData.slackNotificationSettings.sendErrors
      ) {
        setSendToSlack(true)
        setPrevState((prev) => ({
          ...prev,
          sendToSlack: true,
        }))
      }

      if (notificationsSettingsData.slackNotificationSettings) {
        setEnableSlackCheckbox(false)
        setPrevState((prev) => ({
          ...prev,
          sendToSlack: true,
        }))
      } else {
        setEnableSlackCheckbox(true)
        setPrevState((prev) => ({
          ...prev,
          sendToSlack: false,
        }))
      }
    }
  }, [notificationsSettingsData])

  return (
    <Stack spacing={2.5} sx={{ paddingBottom: 2.5 }} maxWidth={824}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl>
            <Label>Send Critical Alerts</Label>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendToEmail}
                    onChange={handleEmailCheckboxChange}
                  />
                }
                label="Send to email"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgba(211, 216, 222, 1)'
                      : '#1A1F28',
                })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendToSlack}
                    disabled={enableSlackCheckbox}
                    onChange={handleSlackCheckboxChange}
                  />
                }
                label="Send to Slack"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === 'dark'
                      ? 'rgba(211, 216, 222, 1)'
                      : '#1A1F28',
                })}
              />
            </FormGroup>
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <Label>Send to:</Label>
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleSlackButtonClick}
            disabled={disconnectSlackIsPending}
          >
            {disconnectSlackIsPending ? <CircularProgress /> : <></>}
            {enableSlackCheckbox
              ? 'Connect to Slack workspace'
              : 'Disconnect from Slack workspace'}
          </Button>
        </Grid2>
        <Grid2>
          <Button
            size="large"
            variant="contained"
            color="inherit"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleSaveButtonClick}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default Alerts
