/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import VaultModel from '@lib/models/vault.model'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedCloudConnectors,
  getSelectedVariant,
} from '@store/selectors/add-policy.selector'
import { getVaultList } from '@store/selectors/vault.selector'
import { DataHelper } from '@lib/helpers'
import {
  getAssetsSelectFilters,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import {
  setAllSelectedAssets,
  setAssetsSelectFilters,
} from '@store/actions/assets-select.action'
import { FilterNamesConstant } from '@lib/constants/filters/filter-names.constant'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { selectedFiltersForAddPolicies } from '@store/reducers/assets-select.reducer'
import { SelectedVariant } from '@lib/constants/app.constant'
import { useLocation } from 'react-router-dom'

export function useFilteredVaultsList() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const filterParam = urlParams.get('protectfiltered') ?? ''

  const dispatch = useDispatch()
  const vaultsList = useSelector(getVaultList)
  const selectedCloudConnectors = useSelector(getSelectedCloudConnectors)
  const selectedAssetsFilters = useSelector(getAssetsSelectFilters)
  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const selectedVariant = useSelector(getSelectedVariant)

  const [filteredVaultsList, setFilteredVaultsList] = useState<
    Array<VaultModel>
  >([])

  const isAssetsVariant = selectedVariant === SelectedVariant.AssetsVariant

  useDeepCompareEffect(() => {
    if (selectedCloudConnectors.length > 0) {
      let filteredVaults: Array<VaultModel> = []
      selectedCloudConnectors.forEach((item) => {
        const currentVaults = vaultsList.filter(
          (vault: VaultModel) =>
            vault.accountId === item.value && vault.region === item.extraValue
        )
        if (currentVaults.length) {
          filteredVaults = [...filteredVaults, ...currentVaults]
        }
      })

      setFilteredVaultsList(filteredVaults)

      // filter Assets list to show Assets from selected Clour Connectors
      const filters = DataHelper.buildCloudConnectorsFilter(
        selectedCloudConnectors
      )

      const filtersWithoutCloudConnectors = selectedAssetsFilters.filter(
        (v) => v.name !== FilterNamesConstant.CLOUD_CONNECTORS
      )
      const newSelectedAssetsFilters = [
        ...filtersWithoutCloudConnectors,
        filters,
      ]

      dispatch(setAssetsSelectFilters(newSelectedAssetsFilters))

      // if assets are pre-selected on Assets pages
      if (filterParam) {
        if (isAssetsVariant && selectedAssets.length > 0) {
          let assetsShouldStaySelected: Array<AssetWithRelatedAssets<Asset>> =
            []
          selectedCloudConnectors.forEach((item) => {
            const currentAssets = selectedAssets.filter(
              (assetWithRelated: AssetWithRelatedAssets<Asset>) =>
                assetWithRelated.asset.awsAccountId === item.value &&
                assetWithRelated.asset.awsRegion === item.extraValue
            )
            if (currentAssets.length) {
              assetsShouldStaySelected = [
                ...assetsShouldStaySelected,
                ...currentAssets,
              ]
            }
          })

          dispatch(setAllSelectedAssets(assetsShouldStaySelected))
        }
      }
    } else {
      setFilteredVaultsList(vaultsList)
      const filtersWithoutCloudConnectors = selectedAssetsFilters.filter(
        (v) =>
          v.name !== FilterNamesConstant.CLOUD_CONNECTORS &&
          v.name !== FilterNamesConstant.CLOUD_CONNECTOR_STATUSES &&
          v.name !== FilterNamesConstant.IS_PROTECTED
      )

      const filtersWithDefault = [
        ...selectedFiltersForAddPolicies(),
        ...filtersWithoutCloudConnectors,
      ]

      dispatch(setAssetsSelectFilters(filtersWithDefault))
    }
  }, [selectedCloudConnectors, vaultsList])

  return filteredVaultsList
}
