import Datetime from '@components-composite/datetime/Datetime'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { EngineCallback } from '@lib/engine-types'
import DatetimeDataInterface from '@lib/interfaces/datetime-data.interface'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'
import clsx from 'clsx'
import React, { memo } from 'react'

interface Props {
  scanWithTimestamps: ScanWithTimestampsInterface
  onChange: EngineCallback<ScanWithTimestampsInterface>
}

function ScanSpecificTimestamps({ scanWithTimestamps, onChange }: Props) {
  const onCheckScanObjects = (value: boolean) => {
    onChange({
      ...scanWithTimestamps,
      isScanObjects: value,
    })
  }

  const onDatetimeChange = (newDatetime: DatetimeDataInterface) => {
    onChange({
      ...scanWithTimestamps,
      dateTimeData: newDatetime,
    })
  }

  return (
    <div className="scanSpecificTimestampsWrap">
      <V2Checkbox
        checked={scanWithTimestamps.isScanObjects}
        onChange={onCheckScanObjects}
        label="Scan objects with specific time stamps"
      />
      <div
        className={clsx('scanSpecificTimestampContainer', {
          disabled: !scanWithTimestamps.isScanObjects,
        })}
      >
        <div className="dateTimeBlockLabel">Scan objects uploaded after:</div>
        <Datetime
          data={scanWithTimestamps.dateTimeData}
          onChange={onDatetimeChange}
        />
      </div>
    </div>
  )
}

export default memo(ScanSpecificTimestamps)
