import { BaseRoutes } from '../base'

export enum RouteFragments {
  INTEGRITY_SCAN = 'integrity-scan',
  ISCAN = 'iscan',
  DYNAMIC_ID = ':id',
}

export class ReportsRoutes extends BaseRoutes {
  public static get root(): string {
    return this.#ROUTE_REPORTS
  }

  public static get integrityScan(): string {
    return this.buildRoute(RouteFragments.INTEGRITY_SCAN)
  }

  private static buildRoute(...fragments: Array<string>): string {
    return `${this.#ROUTE_REPORTS}/${fragments.join('/')}`
  }

  static readonly #ROUTE_REPORTS = '/reports'

  public static get reportIdPlaceholder(): string {
    return this.buildRoute('download', RouteFragments.DYNAMIC_ID)
  }
}
