/* eslint-disable import/no-extraneous-dependencies */
import { AssetsEbsAttrs, EbsDrawer } from 'ui-v2/src/lib/models/assets'
import { AssetsEbsBase } from './ebs-base'
import { Backup, EBS, Threat } from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'

export class AssetsEbsDrawerData extends AssetsEbsBase {
  protected readonly volumes: Array<EBS>

  protected readonly scans: Array<Scan>

  protected readonly lastBackups: Map<string, Backup>

  protected readonly threats: Array<Threat>

  protected readonly volumesWithScans: Map<string, Array<Scan>>

  constructor(parameters: AssetsEbsAttrs) {
    super()
    this.volumes = parameters.volumes
    this.scans = parameters.scans
    this.lastBackups = parameters.lastBackups
    this.threats = parameters.threats
    this.volumesWithScans = this.getVolumeWithScans()
  }

  getEbsDrawerData() {
    const volume = this.volumes[0]
    if (!volume) {
      return undefined
    }
    return new EbsDrawer({
      id: volume.id,
      name: volume.name,
      awsId: volume.awsId,
      awsAccountId: volume.awsAccountId,
      awsRegion: volume.awsRegion,
      state: this.convertEbsState(volume.state),
      lastBackup: this.getLastBackup(volume),
      lastScan: this.getLastScanForVolume(volume),
      isUnhealthy: this.isUnhealthyVolume(volume),
    })
  }
}
