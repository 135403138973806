import React, { useEffect } from 'react'
import { Schedule } from '@components-composite/schedule'
import { useDispatch, useSelector } from 'react-redux'
import { getEditPolicySchedule } from '@store/selectors/edit-policy.selector'
import { DataHelper } from '@lib/helpers'
import ScheduleInterface from '@lib/interfaces/schedule.interface'
import { setEditPolicySchedule } from '@store/actions/edit-policy.action'
import Box from '@mui/material/Box'
import { useTimezoneState } from '@components-complex/account-profile/timezone/use-timezone-state'

export function ProtectionPolicy() {
  const dispatch = useDispatch()
  const schedule = useSelector(getEditPolicySchedule)
  const timeZoneOptions = {
    value: schedule.windowStart.timezone,
    label: schedule.windowStart.timezone,
  }
  const { selectedTimezone, handleTimezoneSelectChange } =
    useTimezoneState(timeZoneOptions)

  useEffect(() => {
    handleTimezoneSelectChange(timeZoneOptions)
  }, [schedule.windowStart.timezone])

  const totalBackupsPerMonth = DataHelper.calcMonthBackups(schedule)

  const handleScheduleChange = (newSchedule: ScheduleInterface) => {
    dispatch(setEditPolicySchedule(newSchedule))
  }

  return (
    <Box>
      <Box className="totalPerMonth">
        Total jobs per month: {totalBackupsPerMonth}
      </Box>
      <Schedule
        data={schedule}
        onChange={handleScheduleChange}
        timeZone={selectedTimezone.value}
        handleTimezoneSelectChange={handleTimezoneSelectChange}
      />
    </Box>
  )
}
