import { useQuery } from '@tanstack/react-query'
import { ListAssetItemsFilters } from 'ui-v2/src/lib/models/assets'
import { AssetsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

interface Options {
  enabled?: boolean
}

export function useAssetItemsQuery(
  filters: ListAssetItemsFilters,
  options?: Options
) {
  const assetsClient = useGrpcClient(AssetsClient)

  return useQuery({
    queryKey: ['assets', 'asset-items', filters],
    queryFn: async () => {
      if (!assetsClient) {
        throw new Error('Assets client not initialized')
      }
      return assetsClient.listAssetItems(filters)
    },
    enabled:
      !!assetsClient &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
