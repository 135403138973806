/* eslint-disable import/no-extraneous-dependencies */
import { FilesystemScanCheck } from 'blues-corejs/dist'
import { StatusScanFilesystemCheck } from 'blues-corejs/dist/models/scans/filesystem-check/types'
import { Nullable } from '@lib/engine-types'

export function getFilesystemCheckStatus(
  filesystemChecks: Array<FilesystemScanCheck>
): Nullable<StatusScanFilesystemCheck> {
  if (filesystemChecks.length === 0) {
    return null
  }

  return filesystemChecks.reduce((status: StatusScanFilesystemCheck, scan) => {
    if (scan.status === StatusScanFilesystemCheck.BAD) {
      return StatusScanFilesystemCheck.BAD
    }
    if (scan.status === StatusScanFilesystemCheck.GOOD) {
      return StatusScanFilesystemCheck.GOOD
    }
    return status
  }, StatusScanFilesystemCheck.GOOD)
}
