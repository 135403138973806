/* eslint-disable import/no-extraneous-dependencies */
import { TimeFormatConstants } from '@lib/constants'
import { EbsInventoryStatus } from '@lib/constants/dashboard.constant'
import LangHelper from '@lib/helpers/lang.helper'
import { RiskLevel } from 'blues-corejs/dist'
import type { VolumeOutput } from 'blues-corejs/dist/use_cases/list_volumes_vulnerabilities'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function buildVolumeName(volume: VolumeOutput) {
  return volume.volumeName
    ? `${volume.volumeName} (${volume.volumeAwsId})`
    : volume.volumeAwsId
}

function prepareEbsDataForCsv(volumeList: Array<VolumeOutput>) {
  return volumeList.map((volume) => ({
    volume: buildVolumeName(volume),
    misconfiguration: volume.vulnerabilitiesList.join(', '),
    risk: RiskLevel[Number(volume.riskVulnerabilities)],
    // To prevent converting into number column
    accountId: `${volume.accountId}‎`,
    region: LangHelper.getAwsRegionSingleTranslation(volume.region),
    attachedVolumes: volume.instancesList
      .map((instance) => instance.awsId)
      .join(', '),
    status: volume.isAttached
      ? EbsInventoryStatus.ATTACHED
      : EbsInventoryStatus.UNATTACHED,
    lastBackup: volume.lastBackup?.backup
      ? `${dayjs
          .utc(volume.lastBackup.backup.timestamp)
          .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
      : '',
  }))
}

const EBS_CSV_TABLE_HEADERS = [
  {
    label: 'Volume',
    key: 'volume',
  },
  {
    label: 'Misconfiguration',
    key: 'misconfiguration',
  },
  {
    label: 'Risk',
    key: 'risk',
  },
  {
    label: 'Account ID',
    key: 'accountId',
  },
  {
    label: 'Region',
    key: 'region',
  },
  {
    label: 'Instance attached',
    key: 'attachedVolumes',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Last Backup',
    key: 'lastBackup',
  },
]

export { prepareEbsDataForCsv, EBS_CSV_TABLE_HEADERS }
