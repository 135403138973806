import React from 'react'

interface Props {
  fill?: string
  width?: string
  height?: string
}

export function ServersWithLatestCleanRpIcon({
  fill = 'none',
  height = '32',
  width = '32',
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
    >
      <rect width="32" height="32" rx="8" fill="white" />
      <path
        d="M18 7C15.6131 7 13.3239 7.94821 11.636 9.63604C9.94821 11.3239 9 13.6131 9 16H6L10 20L14 16H11C11 14.1435 11.7375 12.363 13.0503 11.0503C14.363 9.7375 16.1435 9 18 9C19.8565 9 21.637 9.7375 22.9497 11.0503C24.2625 12.363 25 14.1435 25 16C25 17.8565 24.2625 19.637 22.9497 20.9497C21.637 22.2625 19.8565 23 18 23C16.5 23 15.09 22.5 13.94 21.7L12.5 23.14C14.04 24.3 15.94 25 18 25C20.3869 25 22.6761 24.0518 24.364 22.364C26.0518 20.6761 27 18.3869 27 16C27 13.6131 26.0518 11.3239 24.364 9.63604C22.6761 7.94821 20.3869 7 18 7ZM20 16C20 15.4696 19.7893 14.9609 19.4142 14.5858C19.0391 14.2107 18.5304 14 18 14C17.4696 14 16.9609 14.2107 16.5858 14.5858C16.2107 14.9609 16 15.4696 16 16C16 16.5304 16.2107 17.0391 16.5858 17.4142C16.9609 17.7893 17.4696 18 18 18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16Z"
        fill={fill}
      />
    </svg>
  )
}
