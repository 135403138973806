import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import moment from 'moment-timezone'

export const getUserTimezone = (): string => {
  return (
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value
  )
}

export const userTimezone = getUserTimezone()

export const getMinDate = (timezone: string): moment.Moment => {
  return moment().tz(timezone)
}

export const minDate = getMinDate(getUserTimezone())
