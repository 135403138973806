import type { GoogleChartWrapper } from 'react-google-charts'

export const adjustLabelPositions = (chartWrapper: GoogleChartWrapper) => {
  const labels = chartWrapper
    .getChart()
    // @ts-ignore
    .getContainer()
    .getElementsByTagName('text')

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i]
    if (label.innerHTML === 'Files') {
      const fontSize = 14
      const yCoord = parseFloat(label.getAttribute('y'))
      label.setAttribute('y', yCoord - fontSize)
      continue
    }

    if (label.getAttribute('text-anchor') === 'middle') {
      const fontSize = 10
      const yCoord = parseFloat(label.getAttribute('y'))
      label.setAttribute('y', yCoord + fontSize)
    }

    if (label.getAttribute('text-anchor') === 'end') {
      const fontSize = 10
      const xCoord = parseFloat(label.getAttribute('x'))
      label.setAttribute('x', xCoord - fontSize)
    }
  }
}
