"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratedOvaMspScannedAssetsSummaryReportParams = exports.GeneratedOvaMspInfectedFilesReportParams = exports.GeneratedOvaMspInfectedAssetsReportParams = exports.GeneratedOvaMspFailedScansReportParams = exports.GeneratedOvaMspCompletedScansReportParams = exports.GeneratedOvaMspAccountSummaryReportParams = exports.GeneratedOvaMspTenantSummaryReportParams = exports.GeneratedOvaMspOperationalReportParams = exports.GeneratedOvaMspExecutiveReportParams = exports.GeneratedAwsAccountSummaryReportParams = exports.GeneratedInfectedFilesReportParams = exports.GeneratedFailedScansReportParams = exports.GeneratedAwsTenantSummaryReportParams = exports.GeneratedAwsScannedAssetsSummaryReportParams = exports.GeneratedAwsInfectedAssetsReportParams = exports.GeneratedAwsExecutiveReportParams = exports.GeneratedAwsCompletedScansReportParams = exports.ReportKindCase = exports.ReportGenerated = exports.ScheduledOrigin = exports.OnDemandOrigin = exports.GeneratedReportFormat = exports.ReportGeneratedPending = exports.ReportGeneratedCompleted = void 0;
var status_1 = require("./status");
Object.defineProperty(exports, "ReportGeneratedCompleted", { enumerable: true, get: function () { return status_1.ReportGeneratedCompleted; } });
Object.defineProperty(exports, "ReportGeneratedPending", { enumerable: true, get: function () { return status_1.ReportGeneratedPending; } });
var format_list_1 = require("./format-list");
Object.defineProperty(exports, "GeneratedReportFormat", { enumerable: true, get: function () { return format_list_1.GeneratedReportFormat; } });
var origin_1 = require("./origin");
Object.defineProperty(exports, "OnDemandOrigin", { enumerable: true, get: function () { return origin_1.OnDemandOrigin; } });
Object.defineProperty(exports, "ScheduledOrigin", { enumerable: true, get: function () { return origin_1.ScheduledOrigin; } });
var report_generated_1 = require("./report-generated");
Object.defineProperty(exports, "ReportGenerated", { enumerable: true, get: function () { return report_generated_1.ReportGenerated; } });
var generated_kind_1 = require("./generated-kind");
Object.defineProperty(exports, "ReportKindCase", { enumerable: true, get: function () { return generated_kind_1.ReportKindCase; } });
Object.defineProperty(exports, "GeneratedAwsCompletedScansReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedAwsCompletedScansReportParams; } });
Object.defineProperty(exports, "GeneratedAwsExecutiveReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedAwsExecutiveReportParams; } });
Object.defineProperty(exports, "GeneratedAwsInfectedAssetsReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedAwsInfectedAssetsReportParams; } });
Object.defineProperty(exports, "GeneratedAwsScannedAssetsSummaryReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedAwsScannedAssetsSummaryReportParams; } });
Object.defineProperty(exports, "GeneratedAwsTenantSummaryReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedAwsTenantSummaryReportParams; } });
Object.defineProperty(exports, "GeneratedFailedScansReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedFailedScansReportParams; } });
Object.defineProperty(exports, "GeneratedInfectedFilesReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedInfectedFilesReportParams; } });
Object.defineProperty(exports, "GeneratedAwsAccountSummaryReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedAwsAccountSummaryReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspExecutiveReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspExecutiveReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspOperationalReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspOperationalReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspTenantSummaryReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspTenantSummaryReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspAccountSummaryReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspAccountSummaryReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspCompletedScansReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspCompletedScansReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspFailedScansReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspFailedScansReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspInfectedAssetsReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspInfectedAssetsReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspInfectedFilesReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspInfectedFilesReportParams; } });
Object.defineProperty(exports, "GeneratedOvaMspScannedAssetsSummaryReportParams", { enumerable: true, get: function () { return generated_kind_1.GeneratedOvaMspScannedAssetsSummaryReportParams; } });
