import { z } from 'zod'

import { getViewsFromConfig } from '@features/DashboardV4'

function createNameSchema(isEdit: boolean) {
  const nameValidation = z
    .string()
    .min(1, { message: 'Name must be at least 1 character long (e.g., "J")' })
    .max(255, {
      message: 'Name must be at most 255 characters long (e.g., "John Doe")',
    })
    .regex(/^(?!\s)(?!.*\s$)[A-Za-z0-9\-=\._:@][A-Za-z0-9\+\-=\._:@\s]*$/, {
      message:
        'Name must only contain alphanumeric characters, spaces, and +-._:/@.',
    })

  if (!isEdit) {
    return nameValidation.refine(
      (name) => {
        const existingNames = new Set(
          getViewsFromConfig().map((view) => view.name.toLowerCase().trim())
        )
        return !existingNames.has(name.trim().toLowerCase())
      },
      {
        message: 'This name is already taken, please use a different name.',
      }
    )
  }

  return nameValidation
}

export { createNameSchema }
