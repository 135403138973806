import { Box, styled } from '@mui/material'

export const StyledBackupCoverage = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 160px',
  gridTemplateAreas: `
  'title title'
  'coverage graph'
  'stats graph'
  `,
})

export const StyledCommonStatistic = styled(Box)({
  '.common-statistic': {
    gridArea: 'coverage',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: '16px',
  },
  '.MuiGrid-container': {
    justifyContent: 'center',
  },
  '.MuiGrid-item': {
    flexBasis: 'auto',
  },
  '.backup-coverage-title': {
    gridArea: 'title',
  },
})
