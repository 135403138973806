import { VIRow } from '@lib/engine-types'

const COVERED_OPTION_NAME = 'covered'
export function updateDisabledStateForCoveredOptions(
  array: VIRow,
  condition: boolean
) {
  return array.map((item) => {
    if (item.name === COVERED_OPTION_NAME) {
      return {
        ...item,
        options: item.options?.map((option) => ({
          ...option,
          disabled: condition,
        })),
      }
    }

    return item
  })
}
