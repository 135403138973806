import { Box, Button, Card, Typography } from '@mui/material'
import { useState } from 'react'
import ReturnOfInvestmentDrawer from 'ui-v2/src/components/drawers/return-of-investment'

export type RoiData = {
  selectedIndustry: string
  revenueInput: number | null
  downtimeCost: number | null
  calculatedRoi: string
}

const getStoredRoiData = (): RoiData => {
  const storedData = localStorage.getItem('roiData')
  if (storedData) {
    return JSON.parse(storedData)
  }
  return {
    selectedIndustry: 'it',
    revenueInput: null,
    downtimeCost: null,
    calculatedRoi: '0',
  }
}

function ReturnInvestmentCard() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return (
    <>
      <Card
        elevation={0}
        sx={(theme) => ({
          paddingX: 2.5,
          paddingY: 2,
          borderRadius: 2.5,
          background: theme.palette.background.secondary,
          height: '100%',
        })}
      >
        <Typography
          fontSize={16}
          fontWeight={700}
          mb={1.5}
          color="text.secondary"
        >
          Return of Investment
        </Typography>
        <Box textAlign={'center'} maxWidth={300} margin={'0 auto'}>
          <Typography
            fontSize={32}
            fontWeight={700}
            mb={0.5}
            sx={{
              background:
                'linear-gradient(167.32deg, #DD9427 22.59%, #FFC42D 44.81%, #99671B 64.83%, #C78523 90.82%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
            }}
          >
            {dollarFormatter.format(parseInt(getStoredRoiData().calculatedRoi))}
          </Typography>
          <Typography mb={1}>Since the Start of Service</Typography>
          <Button
            sx={{
              fontSize: '12px',
              textTransform: 'capitalize',
            }}
            variant="outlined"
            size="small"
            onClick={() => {
              setIsDrawerOpen(true)
            }}
          >
            How was this Calculated?
          </Button>
        </Box>
      </Card>

      <ReturnOfInvestmentDrawer
        isOpen={isDrawerOpen}
        closeDrawer={() => {
          setIsDrawerOpen(false)
          getStoredRoiData()
        }}
        storedRoiData={getStoredRoiData()}
        dollarFormatter={dollarFormatter}
      />
    </>
  )
}

export default ReturnInvestmentCard
