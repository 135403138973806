import {
  RiskBlockContainer,
  StyledRiskBlock,
} from '@features/DashboardV4/ConfigurationVulnerabilities/components/risk-block/styles'
import { Skeleton } from '@mui/material'
import React from 'react'

const NUM_SKELETONS = 3

export function RiskSkeletonLoader() {
  const skeletonData = Array.from(Array(NUM_SKELETONS))
  return (
    <>
      {skeletonData.map((_, index) => {
        return (
          <RiskBlockContainer key={index} clickable={false}>
            <Skeleton height={54} width={32} />
            <StyledRiskBlock>
              <Skeleton height={22} width={12} />
              <Skeleton height={24} width={76} />
            </StyledRiskBlock>
          </RiskBlockContainer>
        )
      })}
    </>
  )
}
