import type { GoogleDataTableColumn } from 'react-google-charts'
import { EntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'

export const columns: Array<GoogleDataTableColumn> = [
  {
    type: 'date',
    label: 'First Detected',
  },
  {
    type: 'number',
    label: 'Files',
  },
]

export const MENU_ITEMS = [
  {
    label: 'Today',
    value: EntropyTrendsTimeSelection.TODAY,
  },
  {
    label: 'Last 7 days',
    value: EntropyTrendsTimeSelection.LAST_7_DAYS,
  },
  {
    label: 'Last 30 days',
    value: EntropyTrendsTimeSelection.LAST_30_DAYS,
  },
  {
    label: 'Custom date',
    value: EntropyTrendsTimeSelection.CUSTOM_DATE,
  },
]
