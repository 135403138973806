import { ListInfectedAssetsPb, Pagination } from '@lib/clients/grpc-imports'
import { AbstractListRequest } from '@lib/clients/list-request'

export class ListInfectedAssetsPagination extends AbstractListRequest<ListInfectedAssetsPb.Request> {
  #pageToken: string

  constructor(pageToken: string) {
    super()
    this.#pageToken = pageToken
  }

  createGrpcRequest(): ListInfectedAssetsPb.Request {
    const grpcRequest = new ListInfectedAssetsPb.Request()
    grpcRequest.setPagination(new Pagination().setPageToken(this.#pageToken))

    return grpcRequest
  }
}
