import React, { memo } from 'react'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

function InProgressJobCell({ progress = 0 }: { progress?: number }) {
  const roundedProgress = Math.round(progress)

  if (progress === 0) {
    return null
  }

  return (
    <>
      <Typography fontSize={14}>In progress ({roundedProgress}%) </Typography>
      <LinearProgress value={progress} variant="determinate" />
    </>
  )
}

export default memo(InProgressJobCell)
