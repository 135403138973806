/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, JobCloudConnector } from 'blues-corejs/dist'
import { getJobStatusIcon } from './get-job-status-icon'
import { JobKindCell, ProgressCell, TargetAssetIdCell } from '../cells'
import {
  CellWithDateTimeTooltip,
  createColumn,
  createDisplayColumn,
} from '@components/table'
import PlusToggleBtn from '@components-simple/plus-toggle-btn/PlusToggleBtn'

interface ColumnDefinition {
  jobAssetMap: Map<string, Asset>
}

export function getJobsTableColumnDefinitions({
  jobAssetMap,
}: ColumnDefinition) {
  return [
    createColumn<JobCloudConnector>('childrenList', {
      header: '',
      enableSorting: false,
      meta: {
        width: '40',
      },
      cell: ({ row, getValue }) => {
        const childrenList = getValue() as unknown as Array<JobCloudConnector>

        if (childrenList.length === 0) {
          return null
        }

        return (
          <PlusToggleBtn
            expanded={row.getIsExpanded()}
            onChange={row.getToggleExpandedHandler()}
          />
        )
      },
    }),

    createDisplayColumn<JobCloudConnector>({
      id: 'job-icon-status',
      enableSorting: false,
      cell: ({ row }) => {
        const job = row.original as JobCloudConnector

        return getJobStatusIcon(job)
      },
    }),
    createColumn<JobCloudConnector>('startedAt', {
      header: 'Scheduled On (UTC)',
      enableSorting: false,
      cell: ({ getValue }) => {
        const jobStartedAt = getValue() as unknown as Date

        return <CellWithDateTimeTooltip direction="row" date={jobStartedAt} />
      },
    }),
    createColumn<JobCloudConnector>('kind', {
      header: 'Job type',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const job = original as JobCloudConnector

        return <JobKindCell job={job} />
      },
    }),
    createColumn<JobCloudConnector>('targetAssetId', {
      header: 'Asset',
      enableSorting: false,
      cell: ({ getValue }) => {
        const asset = jobAssetMap.get(getValue())

        return <TargetAssetIdCell asset={asset} />
      },
    }),
    createColumn<JobCloudConnector>('progress', {
      header: 'Status',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const job = original as JobCloudConnector

        return <ProgressCell job={job} />
      },
    }),
  ]
}
