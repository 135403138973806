export const transformAccountToTableData = (inputArray: Array<string>) => {
  const accountsMap: Record<string, Set<string>> = {}

  inputArray.forEach((item) => {
    const [account, region] = item.split('||')

    if (account === undefined) {
      return
    }

    if (!accountsMap[account]) {
      accountsMap[account] = new Set()
    }

    if (region) {
      accountsMap[account]?.add(region)
    }
  })

  return Object.entries(accountsMap).map(([account, regionsSet]) => ({
    account,
    regions: Array.from(regionsSet).join(', '),
  }))
}
