import {
  DrawerName,
  useDrawerActions,
} from '@features/reports/schedules/use-drawer-store'
import {
  useTabsActions,
  useEditingItemId,
  useTabStore,
} from '@features/reports/schedules/use-data-store'
import {
  ReportSchedulesClient,
  GeneratedReportsClient,
} from '@lib/clients/reports'
import GrpcRequestError from '@lib/errors/grpc-error'
import ToastHelper from '@lib/helpers/toast.helper'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import { PagePathConstant } from '@lib/constants'
import { useNavigation } from '@lib/router/contexts/navigation-context'

export function useReportScheduleActions() {
  const reportSchedulesClient = new ReportSchedulesClient()
  const router = useNavigation()
  const generatedReportsClient = new GeneratedReportsClient()

  const { closeDrawer } = useDrawerActions()
  const {
    setEditingItemId,
    toggleRevalidate,
    getEditingItemId,
    updateTabItemById,
  } = useTabsActions()
  const { formData, reportSourceType: reportType } = useTabStore()
  const editingItemId = useEditingItemId()
  const isEditing = Boolean(editingItemId)

  const finalizeAction = () => {
    closeDrawer(DrawerName.GenerateReport)
    setEditingItemId(undefined)
    toggleRevalidate(true)
  }

  const saveAndClose = async () => {
    updateTabItemById(getEditingItemId() as string, {
      description: formData.description,
      name: formData.name,
    })
    try {
      if (isEditing) {
        await reportSchedulesClient.updateReportSchedule({
          ...formData,
          reportType,
          editingItemId,
        })
        finalizeAction()

        return
      }

      if ('name' in formData) {
        await reportSchedulesClient.createReportSchedule({
          ...formData,
          reportType,
        })
        finalizeAction()
        return
      }

      await generatedReportsClient.generateOnDemandReport({
        ...formData,
        reportType,
      })

      router.push(PagePathConstant.REPORTS_HISTORY)

      finalizeAction()
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        if (error.code === GrpcCodes.ALREADY_EXISTS) {
          ToastHelper.warning('Report name already exists.')
        }
      }
    }
  }

  return {
    isEditing,
    saveAndClose,
  }
}
