/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { JobCloudConnector } from 'blues-corejs/dist'
import FinishedJobCell from './finished-job-cell'
import InProgressJobCell from './in-progress-job-cell'
import Typography from '@mui/material/Typography'

interface Props {
  job: JobCloudConnector
}

export function ProgressCell({ job }: Props) {
  if (job.isInProgress) {
    return <InProgressJobCell progress={job.progress} />
  }

  if (job.isAborted) {
    return <Typography fontSize="14px">Aborted</Typography>
  }

  if (job.isFailed) {
    return (
      <Typography color="var(--red500)" fontSize="14px">
        Failed
      </Typography>
    )
  }

  if (job.isScheduled) {
    return <Typography fontSize="14px">Scheduled</Typography>
  }

  if (job.isFinished && job.finishedAt) {
    return <FinishedJobCell finishedAt={job.finishedAt} />
  }

  return null
}
