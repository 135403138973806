import { Schedule, Daily, Weekly, Monthly } from '@lib/clients/grpc-imports'
import { getDayNameAsNumber } from '@lib/clients/reports/schedules/shared/get-day-name'
import { MODES } from './consts'
import {
  Custom,
  CustomScheduleRepeatMode,
  Date,
  EveryDay,
  EveryMonth,
  EveryWeek,
  ExpirationMode,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/report_schedule/schedule_pb'
import {
  RepeatPeriod,
  ReportEnd,
} from '@features/reports/schedules/custom-schedule/types'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'

export function createScheduleInstance(
  frequency: string,
  customSchedule: any
): Schedule {
  const scheduleInstance = new Schedule()
  const [mode, param] = frequency.split('_', 2)

  switch (mode) {
    case MODES.DAILY:
      scheduleInstance.setDaily(new Daily())
      break
    case MODES.WEEKLY:
      const dayOfWeek = getDayNameAsNumber(param as string)
      if (typeof dayOfWeek == 'undefined') {
        throw new Error(`Invalid day of the week: ${param}`)
      }
      scheduleInstance.setWeekly(new Weekly().setDay(dayOfWeek as any))
      break
    case MODES.MONTHLY:
      const dayOfMonth = Number.parseInt(param as string, 10)
      if (isNaN(dayOfMonth) || dayOfMonth < 0 || dayOfMonth > 31) {
        throw new Error(`Invalid day of the month: ${param}`)
      }
      scheduleInstance.setMonthly(new Monthly().setDayOfMonth(dayOfMonth))
      break
    case MODES.CUSTOM:
      const customInstance = new Custom()

      if (customSchedule.periodValue) {
        const repeatModeInstance = new CustomScheduleRepeatMode()

        if (customSchedule.periodValue === RepeatPeriod.DAY) {
          const everyDayInstance = new EveryDay()
          everyDayInstance.setStride(customSchedule.everyValue)
          repeatModeInstance.setEveryDay(everyDayInstance)
        } else if (customSchedule.periodValue === RepeatPeriod.WEEK) {
          const everyWeekInstance = new EveryWeek()
          everyWeekInstance.setStride(customSchedule.everyValue)
          everyWeekInstance.setDay(
            getDayNameAsNumber(customSchedule.daysValue) as any
          )
          repeatModeInstance.setEveryWeek(everyWeekInstance)
        } else if (customSchedule.periodValue === RepeatPeriod.MONTH) {
          const everyMonthInstance = new EveryMonth()
          const monthDay = customSchedule?.monthsValue?.slice(-2)?.trim()
          everyMonthInstance.setStride(customSchedule.everyValue)
          everyMonthInstance.setDayOfMonth(monthDay)
          repeatModeInstance.setEveryMonth(everyMonthInstance)
        }

        customInstance.setRepeatMode(repeatModeInstance)
      }

      if (customSchedule.endValue) {
        const expirationModeInstance = new ExpirationMode()
        if (customSchedule.endValue === ReportEnd.NEVER) {
          const emptyInstance = new Empty()
          expirationModeInstance.setNever(emptyInstance)
        } else if (customSchedule.endValue === ReportEnd.ON) {
          const dateInstance = new Date()
          dateInstance.setYear(customSchedule.dateValue.year())
          dateInstance.setMonth(customSchedule.dateValue.month() + 1)
          dateInstance.setDay(customSchedule.dateValue.date())
          expirationModeInstance.setOnDate(dateInstance)
        } else if (customSchedule.endValue === ReportEnd.AFTER) {
          expirationModeInstance.setAfterOccurrences(
            customSchedule.occurrencesValue
          )
        }
        customInstance.setExpirationMode(expirationModeInstance)
      }

      scheduleInstance.setCustom(customInstance)
      break
    default:
      throw new Error(`Invalid frequency mode: ${mode}`)
  }
  return scheduleInstance
}
