/* eslint-disable import/no-extraneous-dependencies */
import {
  ThreatListTableDataAttrs,
  ThreatListTableRow,
} from 'ui-v2/src/lib/models/assets/infected-assets'
import { Threat } from 'blues-corejs/dist'

export class ThreatTableData {
  readonly #threatsList: Array<Threat>

  readonly #assetAwsIdMap: Map<string, string>

  constructor(parameters: ThreatListTableDataAttrs) {
    this.#threatsList = parameters.threatsList
    this.#assetAwsIdMap = parameters.assetAwsIdMap
  }

  getThreatList = (): Array<ThreatListTableRow> => {
    return this.#threatsList.map((threat) => {
      return new ThreatListTableRow({
        id: threat.id,
        threat: threat.name,
        infectedFiles: threat.location,
        detected: threat.firstDetectedAt,
        action: '',
        volume: this.#assetAwsIdMap.get(threat.source.asset.assetId) ?? '',
      })
    })
  }
}
