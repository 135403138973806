import TagsSelect from '@components-composite/tags-select/TagsSelect'
import { EngineCallback, VIRow } from '@lib/engine-types'
import clsx from 'clsx'
import React, { memo } from 'react'
import Box from '@mui/material/Box'

interface Props {
  policyTags: VIRow
  onTagsChange: EngineCallback<VIRow>
  loading: boolean
}

const ADD_TAGS_BLOCK_TITLE = 'Select Assets By Tags'
const ADD_TAGS_BLOCK_LABEL =
  'Enter the tags to identify which assets to protect when the policy is executed. Each time the policy runs, it dynamically identifies the assets by their tags, meaning the assets and tags do not need to exist when the policy is created.'

function AddTagsBlock({ policyTags, onTagsChange, loading }: Props) {
  return (
    <Box className="wrap-1690281439608">
      <Box className="addPolicyTitle mt25">{ADD_TAGS_BLOCK_TITLE}</Box>
      <Box className="policiesBlockLabel fontMedium mb20">
        {ADD_TAGS_BLOCK_LABEL}
      </Box>
      <Box className={clsx('jsAddTagsBlock', { controlsDisabled: loading })}>
        <TagsSelect onChange={onTagsChange} tags={policyTags} />
      </Box>
    </Box>
  )
}

export default memo(AddTagsBlock)
