/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'

export const VEEAM_HUMANREADABLE = 'Veeam'
export const COMMVAULT_HUMANREADABLE = 'Commvault'
export const RUBRIK_HUMANREADABLE = 'Rubrik'
export const COHESITY_HUMANREADABLE = 'Cohesity'
export const VERITAS_NET_BACKUP_HUMANREADABLE = 'VeritasNetBackup'
export const AWS_BACKUP_VMWARE_HUMANREADABLE = 'AwsBackupVmware'

export const HUMAN_READABLE_OVA_BACKUP_PROVIDER = {
  [OvaBackupProvider.VEEAM]: VEEAM_HUMANREADABLE,
  [OvaBackupProvider.COMMVAULT]: COMMVAULT_HUMANREADABLE,
  [OvaBackupProvider.RUBRIK]: RUBRIK_HUMANREADABLE,
  [OvaBackupProvider.COHESITY]: COHESITY_HUMANREADABLE,
  [OvaBackupProvider.VERITAS_NET_BACKUP]: VERITAS_NET_BACKUP_HUMANREADABLE,
  [OvaBackupProvider.AWS_BACKUP_VMWARE]: AWS_BACKUP_VMWARE_HUMANREADABLE,
}
