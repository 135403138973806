/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  AssetItemEfsIcon,
  AssetItemFileIcon,
  AssetItemS3Icon,
  AssetItemStreamIcon,
  AssetItemVolumeIcon,
  AssetItemDiskIcon,
} from '@features/DashboardV4/common'
import {
  AssetItem,
  Disk,
  EfsItem,
  File,
  LocalVolume,
  S3Item,
  Stream,
} from 'blues-corejs/dist'
import SystemHelper from '@lib/helpers/system.helper'

export function computeAssetItemIcon(assetItem: AssetItem) {
  switch (assetItem.constructor) {
    case File:
      return <AssetItemFileIcon />
    case Stream:
      return <AssetItemStreamIcon />
    case LocalVolume:
      return <AssetItemVolumeIcon />
    case EfsItem:
      return <AssetItemEfsIcon />
    case S3Item:
      return <AssetItemS3Icon />
    case Disk:
      return <AssetItemDiskIcon />
    default:
      SystemHelper.sendSentryIfProd(
        `computeAssetItemIcon: Unknown asset item type: ${assetItem.id}`
      )
      return null
  }
}
