import AlertsFilter from '@features/alerts/alerts-filter'
import { AlertsTable } from 'features/alerts/alerts-table'
import { Box } from '@mui/material'
import RuleInfoModal from '@features/alerts/modals/rule-info-modal'
import AlertsActions from '@features/alerts/alerts-actions'
import RuleConfirmDeleteModal from '@features/alerts/modals/rule-confirm-delete-modal'
import {
  CreateRuleDrawer,
  useCreateRuleDrawerState,
} from '@features/alerts/create-rule-drawer'

function Alerts() {
  const { isOpen } = useCreateRuleDrawerState()
  return (
    <Box className="innerContent innerContentBlueBackground">
      <Box display="flex" justifyContent="space-between" gap={1} mb={2}>
        <AlertsFilter />
        <AlertsActions />
      </Box>
      <AlertsTable />
      <RuleInfoModal />
      <RuleConfirmDeleteModal />
      {isOpen && <CreateRuleDrawer />}
    </Box>
  )
}

export default Alerts
