/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react'
import { Box } from '@mui/material'
import { Container } from '../styles'
import RecoveryLeftColumn from './recovery-left-column'
import RecoveryRightColumn from './recovery-right-column'

function Recovery() {
  return (
    <Container>
      <Box className="left-column" maxWidth="400px">
        <RecoveryLeftColumn />
      </Box>
      <Box className="right-column">
        <RecoveryRightColumn />
      </Box>
    </Container>
  )
}

export default memo(Recovery)
