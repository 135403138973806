import {
  PolicyListTableDataAttrs,
  PolicyListTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import PolicyModel from 'ui-v2/src/lib/models/settings/policies/policy.model'

export class PolicyTableData {
  readonly #policyList: Array<PolicyModel>

  constructor(parameters: PolicyListTableDataAttrs) {
    this.#policyList = parameters.policyList
  }

  getPolicyList = (): Array<PolicyListTableRow> => {
    return this.#policyList.map((policy) => {
      return new PolicyListTableRow({
        id: policy.innerId,
        policyName: policy.policyName,
        status:
          policy.status === 'Paused'
            ? policy.schedule.pausedUntil
              ? 'Paused'
              : 'Disabled'
            : 'Active',
        lastRun: policy.previousRun,
        nextRun: policy.status,
        isPaused: policy.started,
        action: '',
      })
    })
  }
}
