/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import {
  EC2,
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
  Asset,
} from 'blues-corejs/dist'
import { ListScansForAssetClient } from '@lib/clients'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import { ListScansForAssetFilter } from '@lib/clients/scans/list-scans-for-asset'

const ERROR_MESSAGE =
  'useFetchLastScansForAsset: Failed to fetch last scans for asset with id:'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

const scansClient = new ListScansForAssetClient()

async function getScansForInstance(instance: EC2): Promise<Array<Scan>> {
  const attachedVolumesScans = await scansClient.listScansForAsset(
    new ListScansForAssetFilter({
      assetIdList: [...instance.ebsIds],
    })
  )

  const lastScansRun = attachedVolumesScans.scansRunList[0]

  if (!lastScansRun) {
    return []
  }

  return [
    ...lastScansRun.filesystemChecksList,
    ...lastScansRun.malwareScansList,
    ...lastScansRun.ransomwareScansList,
  ]
}

async function getScansForAsset(asset: Asset): Promise<Array<Scan>> {
  if (asset instanceof EC2) {
    return getScansForInstance(asset)
  }

  const response = await scansClient.listScansForAsset(
    new ListScansForAssetFilter({
      assetIdList: [asset.id],
    })
  )

  const lastScansRun = response.scansRunList[0]

  if (!lastScansRun) {
    return []
  }

  return [
    ...lastScansRun.filesystemChecksList,
    ...lastScansRun.malwareScansList,
    ...lastScansRun.ransomwareScansList,
  ]
}

async function fetchLastScans(asset: Asset): Promise<Array<Scan>> {
  try {
    return await getScansForAsset(asset)
  } catch (error) {
    sentryReThrowCatchHandler(`${ERROR_MESSAGE} ${asset.id}`)
    throw new Error(`${ERROR_MESSAGE} ${asset.id}`)
  }
}

export function useFetchLastScansForAsset(asset: Asset) {
  const [lastScans, setLastScans] = useState<Array<Scan>>([])
  const [isLoadingLastScans, setIsLoadingLastScans] = useState(false)

  useEffect(() => {
    try {
      setIsLoadingLastScans(true)
      fetchLastScans(asset).then((scans) => {
        setLastScans(scans)
      })
    } catch (error) {
      console.error(
        `useFetchLastScansForAsset: Cannot fetch last scans for asset: ${asset.id}`
      )
    } finally {
      setIsLoadingLastScans(false)
    }
  }, [asset.id])

  return {
    lastScans,
    isLoadingLastScans,
  }
}
