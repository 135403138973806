/* eslint-disable import/no-extraneous-dependencies */
import { BackupsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  Request as BackupByIdRequest,
  Response as BackupByIdResponseProto,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/backups/get_backup_by_id_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
  AWSBRecoveryPointTransformer,
  OvaBackupTransformer,
} from '../assets/transformers'

import type { BackupById } from '@lib/interfaces/backup'

export interface ListBackupsByIdsResponse {
  backupsList: Array<BackupById>
}

export class GetBackupsByIdsClient extends GrpcClient<BackupsPromiseClient> {
  #backupsClient: BackupsPromiseClient

  constructor(hostName = '') {
    super()
    this.#backupsClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): BackupsPromiseClient {
    return new BackupsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'GetBackupsByIdsClient'
  }

  async getBackupsByIds(
    backupIds: Array<string>
  ): Promise<ListBackupsByIdsResponse> {
    const request = new BackupByIdRequest().setIdsList(backupIds)

    const response = (
      await this.retryGrpcCall(
        () => this.#backupsClient.getBackupById(request, this.metadata()),
        {
          requestName: 'BackupsPromiseClient/getBackupById',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #transformResponse(
    response: BackupByIdResponseProto.AsObject
  ): ListBackupsByIdsResponse {
    return {
      backupsList: response.backupsList.map(
        // @ts-ignore TODO: fix it when we will have OvaBackup in response
        ({ ebsSnapshot, elastioRp, awsbRp, ovaBackup }) => ({
          ebsSnapshot:
            ebsSnapshot && new EbsSnapshotTransformer(ebsSnapshot).transform(),
          elastioRp:
            elastioRp &&
            new ElastioRecoveryPointTransformer(elastioRp).transform(),
          awsbRp:
            awsbRp && new AWSBRecoveryPointTransformer(awsbRp).transform(),
          ovaBackup:
            ovaBackup && new OvaBackupTransformer(ovaBackup).transform(),
        })
      ),
    }
  }
}
