import { GrpcClient } from '@lib/clients/grpc-client'
import { ReportSchedulesPromiseClient } from '@lib/clients/grpc-imports'
import {
  buildDeleteReportScheduleRequest,
  DeleteReportScheduleCriteria,
} from './delete-report-schedule'
import {
  CreateReportScheduleCriteria,
  buildCreateReportScheduleRequest,
} from '@lib/clients/reports/schedules/create-report-schedule'
import {
  UpdateReportScheduleCriteria,
  buildUpdateReportScheduleRequest,
} from '@lib/clients/reports/schedules/update-report-schedule'
import {
  buildListReportSchedulesRequest,
  convertReportSchedulesList,
} from '@lib/clients/reports/schedules/list-report-schedules'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule'
import GrpcRequestError from '@lib/errors/grpc-error'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import ToastHelper from '@lib/helpers/toast.helper'

export class ReportSchedulesClient extends GrpcClient<ReportSchedulesPromiseClient> {
  #reportSchedulesClient: ReportSchedulesPromiseClient

  // @ts-ignore
  readonly #CLIENT_TYPE_ID: 'ReportSchedulesClient'

  constructor(hostName = '') {
    super()
    this.#reportSchedulesClient = this.getClient(hostName)
  }

  protected initClient(hostName: string): ReportSchedulesPromiseClient {
    return new ReportSchedulesPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return this.#CLIENT_TYPE_ID
  }

  async listReportSchedules(): Promise<{
    listReportSchedules: Array<ReportSchedule>
  }> {
    const request = buildListReportSchedulesRequest()

    const response = await this.retryGrpcCall(
      () =>
        this.#reportSchedulesClient.listReportSchedules(
          request,
          this.metadata()
        ),
      {
        requestName: `${this.#CLIENT_TYPE_ID}/listReportSchedules`,
      }
    )

    return {
      listReportSchedules: convertReportSchedulesList(response),
    }
  }

  async deleteReportSchedule({
    id,
  }: DeleteReportScheduleCriteria): Promise<void> {
    const request = buildDeleteReportScheduleRequest({ id })

    try {
      await this.retryGrpcCall(
        () =>
          this.#reportSchedulesClient.deleteReportSchedule(
            request,
            this.metadata()
          ),
        {
          requestName: `${this.#CLIENT_TYPE_ID}/deleteReportSchedule`,
        }
      )
      ToastHelper.success('Report Deleted')
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.NOT_FOUND,
          message: '',
          metadata: {},
          requestName: `${this.#CLIENT_TYPE_ID}/deleteReportSchedule`,
        })
        throw grpcError
      }
    }
  }

  async updateReportSchedule(
    data: UpdateReportScheduleCriteria
  ): Promise<void> {
    const request = buildUpdateReportScheduleRequest(data)

    try {
      await this.retryGrpcCall(
        () =>
          this.#reportSchedulesClient.updateReportSchedule(
            request,
            this.metadata()
          ),
        {
          requestName: `${this.#CLIENT_TYPE_ID}/updateReportSchedule`,
        }
      )
      ToastHelper.success('Report Schedule Modified')
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: `${this.#CLIENT_TYPE_ID}/createReportSchedule`,
        })
        throw grpcError
      }
    }
  }

  async createReportSchedule(
    data: CreateReportScheduleCriteria
  ): Promise<void> {
    const request = buildCreateReportScheduleRequest(data)

    try {
      await this.retryGrpcCall(
        () =>
          this.#reportSchedulesClient.createReportSchedule(
            request,
            this.metadata()
          ),
        {
          requestName: `${this.#CLIENT_TYPE_ID}/createReportSchedule`,
        }
      )
      ToastHelper.success('Report Scheduled')
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: `${this.#CLIENT_TYPE_ID}/createReportSchedule`,
        })
        throw grpcError
      }
    }
  }
}
