interface IconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

const ElastioShieldIcon = ({ size = 20, ...props }: IconProps) => {
  return (
    <svg
      width={size}
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.02 2.66191L9.66676 0L0.313552 2.66191C0.284225 2.67051 0.26392 2.69629 0.261665 2.72529L0.00223155 12.4471C-0.0327344 13.6717 0.344007 14.8759 1.07607 15.8846C2.21982 17.4078 4.63936 19.7013 9.66667 22C14.694 19.7012 17.1135 17.4078 18.2573 15.8846C18.9893 14.8759 19.366 13.6717 19.3311 12.4471L19.0717 2.72529C19.0694 2.69629 19.0493 2.67051 19.02 2.66191Z"
        fill="#0076AC"
      />
      <path
        d="M9.92279 17C10.1082 17.0014 10.2908 16.9552 10.4521 16.8661L14.8815 14.3988C15.0435 14.3083 15.1782 14.178 15.2722 14.0208C15.3662 13.8637 15.4162 13.6852 15.4171 13.5033V10.6978H14.2334V13.4355L9.92279 15.8333L5.18009 13.166V7.83324L9.92279 5.16598L14.0665 7.49679L9.70498 9.90497L10.2879 10.9048L14.8315 8.39572C14.993 8.3058 15.1274 8.17607 15.2212 8.01956C15.315 7.86305 15.3648 7.68528 15.3657 7.50408C15.3666 7.32288 15.3185 7.14463 15.2263 6.98724C15.1342 6.82985 15.0011 6.69886 14.8404 6.60741L10.4521 4.1375C10.2914 4.04741 10.1091 4 9.92368 4C9.73821 4 9.55598 4.04741 9.39522 4.1375L4.53024 6.86822C4.36926 6.95895 4.23556 7.08924 4.1425 7.24608C4.04945 7.40292 4.00031 7.58081 4 7.76195V13.2303C4.00031 13.4115 4.04945 13.5894 4.1425 13.7462C4.23556 13.903 4.36926 14.0333 4.53024 14.1241L9.39522 16.8661C9.55603 16.9549 9.73794 17.001 9.92279 17Z"
        fill="#E3E8ED"
      />
      <path
        d="M9.99509 11.555C9.34334 11.555 8.815 11.0405 8.815 10.4057C8.815 9.77098 9.34334 9.25641 9.99509 9.25641C10.6468 9.25641 11.1752 9.77098 11.1752 10.4057C11.1752 11.0405 10.6468 11.555 9.99509 11.555Z"
        fill="#E3E8ED"
      />
      <path
        d="M13.5907 10.7491C13.5907 11.3838 14.1683 11.8506 14.8199 11.8506C15.1329 11.8506 15.433 11.7295 15.6544 11.514C15.8757 11.2985 16 11.0061 16 10.7013C16 10.3965 15.8757 10.1042 15.6544 9.88863C15.433 9.67309 15.1329 9.552 14.8199 9.552C14.4968 9.56097 14.1895 9.68998 13.9609 9.91257C13.7324 10.1352 13.5999 10.4345 13.5907 10.7491Z"
        fill="#E3E8ED"
      />
    </svg>
  )
}

export default ElastioShieldIcon
