import React from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'

interface Props<T> {
  field: ControllerRenderProps
  options: Array<{ value: T; label: string }>
}

export default function RadioGroupController<T>({ field, options }: Props<T>) {
  return (
    <RadioGroup {...field}>
      {options.map((option) => (
        <FormControlLabel
          key={String(option.value)}
          value={option.value}
          control={<Radio />}
          label={
            <Typography fontWeight="400" variant="body1" fontSize="15px">
              {option.label}
            </Typography>
          }
        />
      ))}
    </RadioGroup>
  )
}
