import React, { PropsWithChildren } from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

interface LabelOwnProps extends TypographyProps {}

function Label({
  children,
  gutterBottom = true,
  ...restProps
}: PropsWithChildren<LabelOwnProps>) {
  return (
    <Typography
      variant="body1"
      fontSize="14px"
      fontWeight="500"
      gutterBottom={gutterBottom}
      {...restProps}
    >
      {children}
    </Typography>
  )
}

export default Label
