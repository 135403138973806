/* eslint-disable import/no-extraneous-dependencies */
import { Threat } from 'blues-corejs/dist'
import React from 'react'
import { RoundCountChips } from '@features/elements/chips/RoundCountChips'

interface Props {
  threats: Array<Threat>
}

const CHIP_STYLE = {
  '.MuiChip-label': {
    padding: '0',
    textOverflow: 'unset',
  },
} as const

const MAX_DISPLAYABLE_THREATS = 99

const EXCEEDED_THREATS_DISPLAY_TEXT = '99+'

export function ThreatCountCell({ threats }: Props) {
  const displayedThreatCount =
    threats.length > MAX_DISPLAYABLE_THREATS
      ? EXCEEDED_THREATS_DISPLAY_TEXT
      : threats.length

  return (
    <RoundCountChips
      label={displayedThreatCount}
      size="small"
      sx={CHIP_STYLE}
      isHighlighted={threats.length > 0}
    />
  )
}
