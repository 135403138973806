import React, { memo, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Button, Dialog, Switch } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

function AccountSettings() {
  const [isNewUIEnabled, setIsNewUIEnabled] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    const savedValue = localStorage?.getItem('useNewUI') === 'true' || false
    setIsNewUIEnabled(savedValue)
  }, [])

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    setIsNewUIEnabled(newValue)

    if (!newValue) {
      setIsDialogOpen(true)
    }
  }

  const handleDialogClose = (confirmReload: boolean) => {
    setIsDialogOpen(false)

    if (confirmReload) {
      setIsNewUIEnabled(false)
      localStorage.setItem('useNewUI', 'false')
      window.location.href = '/dashboard'
    } else {
      setIsNewUIEnabled(true)
    }
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          padding: 2,
          border: '1px solid #ddd',
          borderRadius: 4,
          maxWidth: 400,
          marginTop: '20px',
        }}
      >
        <Box sx={{ marginRight: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            Disable New UI
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Toggle to switch to the old version of the UI.
          </Typography>
        </Box>
        <Switch
          checked={isNewUIEnabled}
          onChange={handleSwitchChange}
          color="primary"
        />
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={() => handleDialogClose(false)}
        sx={{
          '& .MuiDialog-paper': {
            width: '420px',
            padding: '24px',
            borderRadius: '12px',
            textAlign: 'center',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '235px',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle>Switch to Old UI</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Disabling the new UI requires a page reload. Do you want to reload
            the page now?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            Reload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default memo(AccountSettings)
