/* eslint-disable import/no-extraneous-dependencies */
import { BACKUP_VENDOR_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { OvaBackupProvider } from 'blues-corejs/dist'

export abstract class AssetsOvaServerBase {
  protected abstract readonly ovaAccountsMap: Map<string, string>

  protected convertBackupProvider(
    backupProvider: OvaBackupProvider
  ): (typeof BACKUP_VENDOR_MAP)[keyof typeof BACKUP_VENDOR_MAP] {
    return BACKUP_VENDOR_MAP[backupProvider]
  }
}
