/* eslint-disable import/no-extraneous-dependencies */
import { ActiveThreats } from '@features/active-threats'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'

import { Asset, OvaBackupProvider, OvaServer } from 'blues-corejs/dist'
import React from 'react'

const getAssetFilterCriteria = (
  ovaAccountsIdsList: Array<string>,
  ovaBackupProvidersList: Array<OvaBackupProvider>
) => {
  return (asset: Asset) => {
    if (!(asset instanceof OvaServer)) {
      return false
    }

    const isAssetInSliceOvaAccountsList = ovaAccountsIdsList.length
      ? ovaAccountsIdsList.includes(asset.ovaAccountId)
      : true

    const isBackupVendorInSliceOvaBackupProvidersList =
      ovaBackupProvidersList.length
        ? ovaBackupProvidersList.some(
            (backupProvider) => asset.backupProvider === backupProvider
          )
        : true

    return (
      isAssetInSliceOvaAccountsList &&
      isBackupVendorInSliceOvaBackupProvidersList
    )
  }
}

function ActiveThreatsPage() {
  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  const assetFilterCriteria = getAssetFilterCriteria(
    sliceOvaAccountsList,
    sliceOvaBackupProvidersList
  )

  return <ActiveThreats assetFilterCriteria={assetFilterCriteria} />
}
export default ActiveThreatsPage
