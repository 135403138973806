import React, { memo } from 'react'
import ComingSoonWrap from '@components-simple/coming-soon-wrap/ComingSoonWrap'
import AccountStatusConstant from '@lib/constants/account-status.constant'
import { EngineCallback } from '@lib/engine-types'
import RedStackModel from '@lib/models/red-stack.model'
import { Button, Menu, MenuItem } from '@mui/material'
import { CloudConnectorProblems } from '@lib/helpers/data.helper'
import VaultModel from '@lib/models/vault.model'
import { DeploymentRoutes } from '@lib/router/routes/deployment'

interface Props {
  data?: any
  status: AccountStatusConstant | CloudConnectorProblems
  accountId: string
  accountRedStacks: Array<RedStackModel>
  onReconnect: EngineCallback<Array<RedStackModel>>
  onClick?: EngineCallback<any>
  className?: string
}

export function SourcesExtraInfoRow({
  data,
  status,
  accountId,
  accountRedStacks,
  onReconnect,
  onClick,
  className = '',
}: Props) {
  const navigateToUpgradeHref =
    DeploymentRoutes.buildCloudConfigureUpgradeUrl(accountId)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent) => {
    return anchorEl
      ? setAnchorEl(null)
      : setAnchorEl(event.currentTarget as HTMLButtonElement)
  }
  const inactiveRedStacks = accountRedStacks.filter((r) => r.isInactive)

  const vaults: Array<VaultModel> = data?.vaults || []

  switch (status) {
    case AccountStatusConstant.DISCONNECTED:
      return (
        <tr className={`wrap-1621867992137 connectionLost ${className}`}>
          <td className="jsExtraSourceCell" colSpan={7}>
            <div className="infoWrapper">
              Connection has been lost. Try
              <a
                onClick={() => onReconnect(inactiveRedStacks)}
                className="blueLink"
              >
                Reconnect
              </a>{' '}
            </div>
          </td>
        </tr>
      )
    case AccountStatusConstant.UPGRADE_REQUIRED:
      return (
        <tr className={`wrap-1621867992137 upgradeRequired ${className}`}>
          <td className="jsExtraSourceCell" colSpan={7}>
            <div className="infoWrapper">
              <Button
                type="button"
                target="_blank"
                rel="noreferrer"
                href={navigateToUpgradeHref}
                variant="contained"
                color="primary"
                className="sizeXS"
              >
                Upgrade Now!
              </Button>
              Please update to the latest version to guarantee future support!
            </div>
          </td>
        </tr>
      )
    case AccountStatusConstant.MIXED:
      return (
        <tr className={`wrap-1621867992137 warning ${className}`}>
          <td className="jsExtraSourceCell" colSpan={7}>
            <div className="infoWrapper">
              A problem has occurred. Try{' '}
              <ComingSoonWrap className="small">
                <a rel="noreferrer" className="blueLink">
                  Fix it
                </a>
              </ComingSoonWrap>
            </div>
          </td>
        </tr>
      )
    case CloudConnectorProblems.NO_VAULTS:
      return (
        <tr className={`wrap-1621867992137 warning ${className}`}>
          <td className="jsExtraSourceCell" colSpan={7}>
            <div className="infoWrapper">
              There aren't any vaults in the region.
              <a rel="noreferrer" className="blueLink" onClick={onClick}>
                Fix it
              </a>
            </div>
          </td>
        </tr>
      )
    case CloudConnectorProblems.NO_DEFAULT:
      return (
        <tr className={`wrap-1621867992137 warning ${className}`}>
          <td className="jsExtraSourceCell" colSpan={7}>
            <div className="infoWrapper">
              There isn't a default vault in the region.
              <a
                rel="noreferrer"
                className="blueLink"
                onClick={data?.vaults && handleClick}
              >
                Fix it
              </a>
              <Menu
                anchorEl={anchorEl}
                id="basic-menu"
                open={open}
                onClose={handleClick}
              >
                {vaults.map((vault) => (
                  <MenuItem
                    key={vault.innerId}
                    onClick={() => onClick && onClick(vault.region)}
                  >
                    {vault.regionLabel}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </td>
        </tr>
      )
    case CloudConnectorProblems.BS_ERROR:
      return (
        <tr className={`wrap-1621867992137 warning ${className}`}>
          <td className="jsExtraSourceCell" colSpan={7}>
            <div className="infoWrapper">
              Cloud Connector is misconfigured.
              <a rel="noreferrer" className="blueLink" onClick={onClick}>
                Fix it
              </a>
            </div>
          </td>
        </tr>
      )
    // TODO: not implemented in backend
    // case CloudConnectorProblems.UNKNOWN:
    //   return (
    //     <tr className={`wrap-1621867992137 warning ${className}`}>
    //       <td className="jsExtraSourceCell" colSpan={7}>
    //         <div className="infoWrapper">
    //           Something went wrong. Contact Elastio support.
    //         </div>
    //       </td>
    //     </tr>
    //   )
    default:
      return <></>
  }
}

export default memo(SourcesExtraInfoRow)
