export class Source {
  // The asset source
  #asset?: Asset

  // The asset item source
  #assetItem?: AssetItem

  // The backup source
  #backup?: Backup

  constructor(asset?: Asset, assetItem?: AssetItem, backup?: Backup) {
    this.#asset = asset
    this.#assetItem = assetItem
    this.#backup = backup
  }

  getAsset(): Asset | undefined {
    return this.#asset
  }

  getAssetItem(): AssetItem | undefined {
    return this.#assetItem
  }

  getBackup(): Backup | undefined {
    return this.#backup
  }
}

export class Asset {
  // UUID of the asset
  #id?: string

  constructor(id?: string) {
    this.#id = id
  }

  getId(): string | undefined {
    return this.#id
  }
}

export class AssetItem {
  // UUID of the asset item
  #id?: string

  // UUID of the asset
  #assetId?: string

  constructor(id?: string, assetId?: string) {
    this.#id = id
    this.#assetId = assetId
  }

  getId() {
    return this.#id
  }

  getAssetId() {
    return this.#assetId
  }
}

export class Backup {
  // UUID of the backup
  #id?: string

  #resource?: Asset | AssetItem

  constructor(id?: string, resource?: Asset | AssetItem) {
    this.#id = id
    this.#resource = resource
  }

  getId() {
    return this.#id
  }

  getResource() {
    return this.#resource
  }

  getAssetId() {
    if (this.#resource instanceof Asset) {
      return this.#resource?.getId()
    }

    if (this.#resource instanceof AssetItem) {
      return this.#resource?.getAssetId()
    }
  }
}
