/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { MultiSelectSearch } from '@components/components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/multi-select-search'
import type { OptionType } from '@components/components-complex/dashboard-pages-v3/assets-with-threats/ova-servers-with-threats/multi-select-search'
import {
  useToolbarFiltersState,
  useToolbarFiltersActions,
} from '../toolbar-filters-store'

function buildThreatsOptions(threatsNames: Set<string>): Array<OptionType> {
  return Array.from(threatsNames).map((threatName) => ({
    label: threatName,
    value: threatName,
  }))
}

interface Props {
  threatsNames: Set<string>
}

export function RansomwareThreatsSelect({ threatsNames }: Props) {
  const { ransomwareThreatNames } = useToolbarFiltersState()

  const { setRansomwareThreatNames } = useToolbarFiltersActions()

  const threatsOptions = buildThreatsOptions(threatsNames)

  const selectedThreatsOptions = buildThreatsOptions(ransomwareThreatNames)

  const handleRansomwareThreatsChange = (threatsList: Array<OptionType>) => {
    setRansomwareThreatNames(
      threatsList.map((threat) => threat.value as string)
    )
  }

  return (
    <MultiSelectSearch
      key={ransomwareThreatNames.size}
      tagsTitle="Ransomware"
      placeholder="Ransomware"
      options={threatsOptions}
      selectedOptions={selectedThreatsOptions}
      onDone={handleRansomwareThreatsChange}
    />
  )
}
