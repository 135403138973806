import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'
import AwsAccountInfoModel from './aws-account-info.model'

abstract class AwsAccountInfoFactory {
  public static buildFromGrpc(params: any): AwsAccountInfoModel {
    return new AwsAccountInfoModel({
      awsAccountId: TypeHelper.stringValDef(params.awsAccountId, ''),
      reportGatheringEnabled: TypeHelper.booleanValDef(
        params.reportGatheringEnabled,
        false
      ),
    })
  }
}

export default AwsAccountInfoFactory
