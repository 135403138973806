import React, { memo, ReactNode, useEffect, useState } from 'react'
import { EngineCallback } from '@lib/engine-types'
import clsx from 'clsx'

interface Props {
  currentTabIndex?: number
  onCurrentTabChange?: EngineCallback<number>
  labels: Array<string>
  children: Array<ReactNode>
}

function ButtonGroupTabs({
  currentTabIndex = 0,
  onCurrentTabChange,
  labels,
  children,
}: Props) {
  const [tabIndex, setTabIndex] = useState(currentTabIndex)

  useEffect(() => {
    if (onCurrentTabChange) {
      onCurrentTabChange(tabIndex)
    }
  }, [tabIndex])

  return (
    <div className="wrap-1629286377388">
      <div className="bgtButtonsGroup">
        {labels.map((lbl, index) => (
          <button
            key={lbl}
            className={clsx('jsGbtBtn', { gbtActive: index === tabIndex })}
            onClick={() => setTabIndex(index)}
            type="button"
            data-testid={`jsGbtBtn-${lbl}`}
          >
            {lbl}
          </button>
        ))}
      </div>
      {React.Children.map(children, (child, index) => (
        <div className="jsBgtContent" hidden={index !== tabIndex}>
          {child}
        </div>
      ))}
    </div>
  )
}

export default memo(ButtonGroupTabs)
