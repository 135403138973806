import { useQuery } from '@tanstack/react-query'
import { PersonalAccessTokenClient } from 'ui-v2/src/lib/clients/settings/personal-access-token'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useTokenListQuery() {
  const personalAccessTokenClient = useGrpcClient(PersonalAccessTokenClient)

  return useQuery({
    queryKey: ['settings', 'tokenList'],
    queryFn: () => {
      if (!personalAccessTokenClient) {
        throw new Error('Personal access token client not initialized')
      }

      return personalAccessTokenClient.list()
    },
    enabled: !!personalAccessTokenClient,
  })
}
