import { useState, useEffect, useCallback } from 'react'
import {
  getIsLiveAssetsLoading,
  getLiveAssets,
} from '@store/selectors/list-live-assets.selector'
import { useSelector } from 'react-redux'
import { EBS } from '@lib/models/assets'
import { Nullable } from '@lib/engine-types'

type UseEBSVolumeOutput = {
  ebsVolume: Nullable<EBS>
  isAssetLoading: boolean
}

const processEBSVolume = (ebsVolumes: Array<EBS>, assetId: string) => {
  const ebsVolume = ebsVolumes.find((elastioRp) => elastioRp.id === assetId)

  if (!ebsVolume) {
    throw new Error(
      `Error occurred in file ${__filename} while processing: EBS Volume not found.`
    )
  }
  return ebsVolume
}
function useEBSVolume(assetId: string): UseEBSVolumeOutput {
  const [ebsVolume, setEbsVolume] = useState<Nullable<EBS>>(null)
  const { ebsVolumes } = useSelector(getLiveAssets)
  const isAssetLoading = useSelector(getIsLiveAssetsLoading)

  const findEbsVolume = useCallback(
    (): Nullable<EBS> => processEBSVolume(ebsVolumes, assetId),
    [ebsVolumes.length, assetId]
  )

  useEffect(() => {
    if (ebsVolumes.length) {
      setEbsVolume(findEbsVolume())
    }

    return () => {
      setEbsVolume(null)
    }
  }, [isAssetLoading, findEbsVolume])

  return {
    ebsVolume,
    isAssetLoading,
  }
}

export { useEBSVolume }
