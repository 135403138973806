import { removeParamsSuffix } from './remove-params-suffix'

export function getFirstKey(
  object: Record<string, any> = {}
): string | undefined {
  const keys = Object.keys(object)

  if (keys.length === 0) {
    return undefined
  }

  return keys[0]
}

export function extractAllReportTypesAndParams(
  reportKindWithParams: Record<string, any>
) {
  const reportParamKey = getFirstKey(reportKindWithParams)
  if (!reportParamKey) {
    return {
      reportType: undefined,
      reportParams: undefined,
    }
  }

  const reportParams = reportKindWithParams[reportParamKey]
  const reportParamKeyWithoutSuffix = removeParamsSuffix(reportParamKey)

  return {
    reportType: reportParamKeyWithoutSuffix,
    reportParams,
  }
}
