import { VIRow } from 'ui-v2/src/lib/engine-types'
import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'

abstract class GeneralFactory {
  public static buildPossibleRegions(data: Array<string>): VIRow {
    return data.map((regionName) => ({
      id: numberHash(regionName),
      name: regionName,
      label: LangHelper.getAwsRegionSingleTranslation(regionName),
    }))
  }
}

export default GeneralFactory
