// eslint-disable-next-line import/no-extraneous-dependencies
import { OvaAccount } from 'blues-corejs/dist'
import {
  TabViewInterface,
  ViewKind,
} from '@features/DashboardV4/management-view'
import {
  CURRENT_VIEW_VERSION,
  TEMPORARY_VIEW_ID,
} from '@features/DashboardV4/management-view/consts'
import { StrHelper } from '@lib/helpers'
import { OvaBackupProviderHelper } from '@lib/helpers/ova-backup'

export const createTemporaryTab = (
  ovaAccounts: Array<OvaAccount>,
  ovaAccountId: string
): TabViewInterface => {
  const currentAccount = ovaAccounts.find((a) => a.id === ovaAccountId)

  const backupProviders = currentAccount?.backupProvidersList.map(
    OvaBackupProviderHelper.getProviderName
  )

  const tabName = `${backupProviders?.join(', ')} - ${currentAccount?.name}`
  return {
    id: `${TEMPORARY_VIEW_ID}-${StrHelper.generateUUID()}`,
    name: tabName,
    variant: 'ova',
    kind: ViewKind.OVA,
    isDefaultDashboard: false,
    version: CURRENT_VIEW_VERSION,
    slice: {
      accountIds: [currentAccount?.id as string],
      backupVendors: currentAccount?.backupProvidersList,
    },
  }
}
