import React, { useCallback } from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import EditUserForm from '@forms/edit-user/edit-user.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormEditUserInterface from '@lib/interfaces/form/form-edit-user.interface'
import UserRoleModel from '@lib/models/user-role.model'

export interface EditUserModalInputData {
  selectedRole: Nullable<UserRoleModel>
  possibleRoles: Nullable<Array<UserRoleModel>>
  email: string
  userId: string
}

interface EditUserModalData {
  loading: boolean
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<FormEditUserInterface>
  data: Nullable<EditUserModalInputData>
  show: boolean
}

function EditUserModal({
  loading,
  onCancel,
  onSubmit,
  data,
  show,
}: EditUserModalData) {
  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  return (
    <BaseModal
      className="modalDefaultV2Small"
      onBgClick={onCancelCallback}
      show={show}
    >
      <div className="modalHeaderWrapV2">
        <div className="mhTitle jsFullTitle">Edit User</div>
      </div>

      <div className="modalFormContentV2 mt20">
        {data?.possibleRoles && data?.selectedRole && (
          <EditUserForm
            errorGroups={[ErrorGroupConstants.USER]}
            selectedRole={data.selectedRole}
            possibleRoles={data.possibleRoles}
            defaultValues={{
              email: data.email,
              userId: data.userId,
            }}
            onSubmit={onSubmit}
            onCancel={onCancel}
            loading={loading}
          />
        )}
      </div>
    </BaseModal>
  )
}

export default EditUserModal
