import React, { memo } from 'react'
import UserStatusConstant from '@lib/constants/data/user/user-status.constant'

interface Props {
  status: number
}

function UserStatusBock({ status }: Props) {
  const statusData = UserStatusConstant.getByValue(status)
  return (
    <div
      data-testid="userStatusBlock"
      className={`wrap-1619113611397 ustBefore ${statusData.clName}`}
    >
      {statusData.label}
    </div>
  )
}

export default memo(UserStatusBock)
