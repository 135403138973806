import React, { ChangeEvent, useState, useEffect, FC } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import BasicSelect, {
  Value,
} from '@components/shared/basic-select/basic-select'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Calendar from '@components-simple/calendar/Calendar'
import { SelectChangeEvent } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import { StyledModalItemTitle, StyledModalTitle } from './styles'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import Fab from '@mui/material/Fab'
import { Days, RepeatPeriod, ReportEnd, CustomScheduleState } from './types'
import {
  DaysOptions,
  EndOptions,
  getMonthOptions,
  repeatPeriodOptions,
} from './constants'
import { Moment } from 'moment'
import moment from 'moment-timezone'

interface RepeatsProps {
  periodValue: RepeatPeriod
  daysValue: string | null
  handleDaysChange: (v: Days) => void
  monthsValue: string
  handleMonthsChange: (v: string) => void
}

interface CustomScheduleProps {
  getStateCallback: (getState: () => CustomScheduleState) => void
  initialValues?: CustomScheduleState | null
}

const monthOptions = getMonthOptions()

const computeRepeats = ({
  periodValue,
  daysValue,
  handleDaysChange,
  handleMonthsChange,
  monthsValue,
}: RepeatsProps) => {
  if (periodValue === RepeatPeriod.WEEK) {
    return (
      <Box>
        <StyledModalItemTitle>Repeat On</StyledModalItemTitle>
        <Box display="flex" gap={1}>
          {DaysOptions.map((option) => (
            <Fab
              size="small"
              onClick={() => handleDaysChange(option.value)}
              sx={{
                background:
                  option.value === daysValue ? 'var(--blue500)' : '#f1f3f4',
                color: option.value === daysValue ? '#fff' : '#70757a',
                boxShadow: 'none',
                fontSize: '12px',
                width: '24px',
                height: '24px',
                minHeight: '24px',
                lineHeight: '24px',
                '&:hover': {
                  background: 'var(--blue500)',
                  color: '#fff',
                },
              }}
            >
              {option.label}
            </Fab>
          ))}
        </Box>
      </Box>
    )
  }

  if (periodValue === RepeatPeriod.MONTH) {
    return (
      <Box>
        <StyledModalItemTitle>Repeats On</StyledModalItemTitle>
        <FormControl fullWidth>
          <BasicSelect
            options={monthOptions}
            value={monthsValue}
            onChange={(e) => {
              handleMonthsChange(String(e.target.value))
            }}
            placeholder="Please select month"
          />
        </FormControl>
      </Box>
    )
  }

  return null
}

const CustomSchedule: FC<CustomScheduleProps> = ({
  getStateCallback,
  initialValues,
}) => {
  const [everyValue, setEveryValue] = useState<number>(
    initialValues?.everyValue || 1
  )
  const [periodValue, setPeriodValue] = useState<RepeatPeriod>(
    initialValues?.periodValue || RepeatPeriod.DAY
  )
  const [daysValue, setDaysValue] = useState<string | null>(
    initialValues?.daysValue || null
  )
  const [monthsValue, setMonthsValue] = useState<string>(
    initialValues?.monthsValue || ''
  )
  const [endValue, setEndValue] = useState<ReportEnd>(
    initialValues?.endValue || ReportEnd.NEVER
  )
  const [dateValue, setDateValue] = useState<Moment | null>(
    initialValues?.dateValue || null
  )
  const [occurrencesValue, setOccurrencesValue] = useState<number | null>(
    initialValues?.occurrencesValue || null
  )

  const isDateDisable = !(endValue === ReportEnd.ON)
  const isOccurrencesDisabled = !(endValue === ReportEnd.AFTER)

  const getState = (): CustomScheduleState => ({
    everyValue,
    periodValue,
    daysValue,
    monthsValue,
    endValue,
    dateValue,
    occurrencesValue,
  })

  useEffect(() => {
    getStateCallback(getState)
  }, [
    everyValue,
    periodValue,
    daysValue,
    monthsValue,
    endValue,
    dateValue,
    occurrencesValue,
  ])

  const handleEveryChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(event.target.value)

    setEveryValue(value)
  }

  const handlePeriodChange = (event: SelectChangeEvent<Value>) => {
    const value = event.target.value as RepeatPeriod
    setPeriodValue(value)
  }

  const handleEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as ReportEnd
    setEndValue(value)
  }

  const handleDateChange = (v: number) => {
    setDateValue(moment(v))
  }

  const handleOccurrencesChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = Number(event.target.value)
    setOccurrencesValue(value)
  }

  const handleDaysChange = (v: Days) => {
    setDaysValue(v)
  }

  const handleMonthsChange = (v: string) => {
    setMonthsValue(v)
  }

  const repeatsBlock = computeRepeats({
    periodValue,
    daysValue,
    handleDaysChange,
    monthsValue,
    handleMonthsChange,
  })

  return (
    <>
      <StyledModalTitle>Custom schedule</StyledModalTitle>
      <Box display="flex" gap={2} mb={1}>
        <Box width="60px">
          <OutlinedInput
            value={everyValue}
            onChange={handleEveryChange}
            type="number"
            size="small"
          />
        </Box>
        <Box width="100px">
          <FormControl fullWidth>
            <BasicSelect
              options={repeatPeriodOptions}
              value={periodValue}
              onChange={handlePeriodChange}
            />
          </FormControl>
        </Box>
      </Box>
      {repeatsBlock}
      <StyledModalItemTitle mt={2}>Ends</StyledModalItemTitle>
      <Box display="flex" gap="26px">
        <Box>
          <RadioGroup value={endValue} onChange={handleEndChange}>
            {EndOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box>
          <Box mt="46px">
            <Calendar
              value={dateValue?.valueOf()}
              onChange={handleDateChange}
              disabled={isDateDisable}
              format={TimeFormatConstants.ONLY_DATE_FORMAT}
            />
          </Box>
          <Box>
            <Box width="170px">
              <OutlinedInput
                value={occurrencesValue}
                onChange={handleOccurrencesChange}
                type="number"
                size="small"
                disabled={isOccurrencesDisabled}
                placeholder="1 occurrences"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CustomSchedule
