import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import WebhookEventsTimeline from '@components-complex/webhook-events-timeline/WebhookEventsTimeline'
import DialogModal from '@components-composite/modals/DialogModal'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import Modal from '@lib/constants/modal.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import TableFactory from '@lib/factories/table.factory'
import { useModal } from '@lib/hooks/useModal'
import { Button } from '@mui/material'
import { WEBHOOK_EVENTS_TABLE_HEAD } from '@tables/core/table-constants'
import { WEBHOOK_EVENTS_TABLE_MAPPER } from '@tables/core/table-vi-draw-mappers'
import EditWebhookForm from '@forms/edit-webhook/edit-webhook-form'
import SimpleVITable from '@tables/SimpleVITable'
import QuizIcon from '@inline-img/general/quiz-icon'
import {
  deleteWebhook,
  removeSelectedWebhook,
  requestGetWebhook,
  updateWebhook,
  requestEventTypes,
  requestTestExistingWebhook,
} from '@store/actions/webhook.action'
import { getSelectedWebhook } from '@store/selectors/webhook.selector'
import { WebhookValueInterface } from '@lib/interfaces/notifications.interface'
import ObjHelper from '@lib/helpers/obj.helper'
import { WebhookFormInterface } from '@forms/add-webhook/data'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  Secret,
  WebhookEventSubscription,
} from '@lib/models/webhook.model'
import { AuthorizationOptions } from '@lib/constants/notifications.constant'
import { EngineCallback } from '@lib/engine-types'
import StrHelper from '@lib/helpers/str.helper'
import DataHelper from '@lib/helpers/data.helper'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { useParams } from 'react-router-dom'
import { SettingsRoutes } from '@lib/router'

interface Props {
  showModal: EngineCallback<any>
}

function EditWebhookPage({ showModal }: Props) {
  const { setHeaderTitle, setBreadcrumbsPaths } = useLayoutContext()
  const router = useNavigation()
  const { id } = useParams()

  const dispatch = useDispatch()
  const webhook = useSelector(getSelectedWebhook)

  const isTestingWebhook = usePreloader(
    PreloaderConstants.TEST_EXISTING_WEBHOOK
  )

  useEffect(() => {
    if (typeof id !== 'string') {
      return router.back()
    }
    dispatch(requestGetWebhook(StrHelper.base64Decode(id)))
    setBreadcrumbsPaths([
      {
        href: PagePathConstant.SETTINGS_WEBHOOKS,
        text: titlesForBreadcrumb('Tenant Settings') ?? '',
      },
    ])
  }, [id])

  useEffect(() => {
    setHeaderTitle(`Edit Webhook ${webhook ? `(${webhook.getName()})` : ''}`)
  }, [webhook])

  useEffect(() => {
    dispatch(requestEventTypes())
    return () => {
      dispatch(removeSelectedWebhook())
      setHeaderTitle(null)
    }
  }, [])

  const loading = false

  const handleRedirectCallback = () => {
    router.push(SettingsRoutes.webhooksTab)
  }

  const {
    openModal: openDeleteWebhookModal,
    modalProps: webhookDeleteModalProps,
  } = useModal<{ webhookId: string; name: string }>(
    Modal.deleteWebhook,
    ({ webhookId }: { name: string; webhookId: string }) => {
      dispatch(deleteWebhook(webhookId, handleRedirectCallback))
    }
  )

  const {
    openModal: openDeleteWebhookEventModal,
    modalProps: webhookEventDeleteModalProps,
  } = useModal<string>(Modal.deleteWebhookEvent, (eventName: string) => {
    const updatedWebhook = new WebhookModel({
      id: webhook.getId(),
      name: webhook.getName(),
      description: webhook.getDescription(),
      endpoint: webhook.getEndpoint(),
      authentication: webhook.getAuthentication(),
      event_subscriptions: webhook
        .getEventSubscriptions()
        .filter((event) => event.getEventType() !== eventName),
    })

    dispatch(updateWebhook(updatedWebhook))
  })

  const onDeleteWebHook = () => {
    openDeleteWebhookModal({
      webhookId: webhook.getId(),
      name: webhook.getName(),
    })
  }

  const onDeleteWebHookEvent = (eventId: string) => {
    openDeleteWebhookEventModal(eventId)
  }

  const onCancel = () => {}

  const onTestWebhook = () => {
    dispatch(requestTestExistingWebhook(webhook.getId()))
  }

  if (ObjHelper.isEmpty(webhook)) {
    return (
      <div className="llFullHeight" aria-label="no-webhook">
        <PreloaderBlock show />
      </div>
    )
  }

  const webhookEvents: Array<WebhookValueInterface> = webhook
    .getEventSubscriptions()
    .map((event) => ({
      id: Date.now(),
      name: Date.now().toString(),
      eventType: event.getEventType(),
      version: event.getVersion(),
      severities: event.getSeverities(),
    }))

  const webhookAuthentication = webhook.getAuthentication()
  const fillDefaultAuthDataForm = (): Partial<WebhookFormInterface> => {
    const authType = webhookAuthentication.intoInner()
    if (authType instanceof BasicAuth) {
      const basic = new BasicAuth(
        new Secret({
          secret: authType.getLogin(),
          encrypted: true,
        }),
        new Secret({
          secret: authType.getPassword(),
          encrypted: true,
        })
      )

      return {
        login: basic.getLogin(),
        password: basic.getPassword(),
      }
    }
    if (authType instanceof ApiKey) {
      const apiKey = new ApiKey(
        new Secret({
          secret: authType.getKey(),
          encrypted: true,
        }),
        new Secret({
          secret: authType.getValue(),
          encrypted: true,
        })
      )
      return {
        apiKey: apiKey.getKey(),
        apiValue: apiKey.getValue(),
      }
    }
    if (authType instanceof BearerToken) {
      const bearer = new BearerToken(
        new Secret({
          secret: authType.getToken(),
          encrypted: true,
        })
      )
      return {
        token: bearer.getToken(),
      }
    }
    return {}
  }
  const defaultWebhookValues: Partial<WebhookFormInterface> = {
    name: webhook.getName(),
    endpoint: webhook.getEndpoint(),
    description: webhook.getDescription(),
    authorization: webhookAuthentication.getAuthTypeToString(),
    ...fillDefaultAuthDataForm(),
  }

  const onSubmit = (data: WebhookFormInterface) => {
    switch (data.authorization) {
      case AuthorizationOptions.BASIC:
        webhook.changeAuthTypeToBasicAuth()
        const isLoginChanged =
          (webhook.getAuthentication().intoInner() as BasicAuth).getLogin() !==
          data.login

        const isPasswordChanged =
          (
            webhook.getAuthentication().intoInner() as BasicAuth
          ).getPassword() !== data.password

        if (isLoginChanged) {
          webhook.updateLogin(data.login)
        }

        if (isPasswordChanged) {
          webhook.updatePassword(data.password)
        }
        break
      case AuthorizationOptions.API_KEY:
        webhook.changeAuthTypeToApiKey()

        const isApiKeyChanged =
          (webhook.getAuthentication().intoInner() as ApiKey).getKey() !==
          data.apiKey

        const isApiValueChanged =
          (webhook.getAuthentication().intoInner() as ApiKey).getValue() !==
          data.apiValue

        if (isApiKeyChanged) {
          webhook.updateApiKey(data.apiKey)
        }

        if (isApiValueChanged) {
          webhook.updateApiValue(data.apiValue)
        }
        break
      case AuthorizationOptions.BEARER:
        webhook.changeAuthTypeToBearerToken()

        const isTokenChanged =
          (
            webhook.getAuthentication().intoInner() as BearerToken
          ).getToken() !== data.token

        if (isTokenChanged) {
          webhook.updateToken(data.token)
        }
        break
    }

    const events: Array<WebhookEventSubscription> = webhookEvents.map(
      (event) => {
        return new WebhookEventSubscription(
          event.eventType,
          event.version,
          event.severities
        )
      }
    )

    webhook.setName(data.name)

    webhook.setEndpoint(data.endpoint)

    webhook.setDescription(data.description)

    webhook.setEventsSubscription(events)

    dispatch(updateWebhook(webhook))
  }

  return (
    <div
      className="wrap-1660053775064 edit-webhook-page"
      aria-label="edit-webhook-page"
    >
      <div>
        <div className="controlHeaderBlock">
          <div className="controlHeader">
            <div className="controlHeaderLabel mb0"></div>
            <div className="controlHeaderButtons">
              <Button
                className="jsTestWebhook"
                variant="outlined"
                startIcon={<QuizIcon />}
                onClick={onTestWebhook}
                disabled={isTestingWebhook}
              >
                <span>Test Webhook</span>
              </Button>
              <Button
                disabled={loading}
                className="jsDeleteWebhook"
                variant="contained"
                aria-label="delete-edit-webhook"
                onClick={onDeleteWebHook}
              >
                <span>Delete Webhook</span>
              </Button>
            </div>
          </div>
        </div>
        <div>
          {!loading ? (
            <>
              <div className="editWebhookContainer">
                <div className="editWebhookMainContainer">
                  <div className="editWebhookMainHeader">
                    <EditWebhookForm
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      loading={loading}
                      defaultValues={defaultWebhookValues}
                    />
                  </div>
                </div>

                <div className="editWebhookMainContainer editWebhookTableContainer">
                  <div className="editWebhookMainHeader">
                    <div className="editWebhookMainHeaderLabel">
                      {!webhookEvents?.length
                        ? 'No Events'
                        : `${webhookEvents.length} Event${
                            webhookEvents?.length !== 1 ? 's' : ''
                          }`}
                    </div>
                    <Button
                      className="showButton sizeXS"
                      variant="text"
                      color="primary"
                      type="button"
                      onClick={() => showModal(webhook)}
                    >
                      Add events
                    </Button>
                  </div>
                  {ObjHelper.isNotEmpty(webhook) && (
                    <SimpleVITable
                      head={WEBHOOK_EVENTS_TABLE_HEAD}
                      rows={TableFactory.webhookEventsTable(webhookEvents)}
                      className={clsx(
                        'tableVIUncontrolled webhookEventsTable tableUpgrade striped'
                      )}
                      columnDrawMapper={WEBHOOK_EVENTS_TABLE_MAPPER(
                        onDeleteWebHookEvent,
                        webhook.getEventSubscriptions().length === 1
                      )}
                    />
                  )}
                </div>
                <div className="editWebhookChartContainer">
                  <WebhookEventsTimeline webhook={webhook} />
                </div>
              </div>
            </>
          ) : (
            <PreloaderBlock show />
          )}
        </div>
      </div>

      <DialogModal
        description={
          <div>
            You are going to remove the Webhook
            {
              <LongTextTooltip
                text={webhookDeleteModalProps.data?.name ?? ''}
                maxLength={35}
              />
            }
            Please confirm the deletion.
          </div>
        }
        {...webhookDeleteModalProps}
      />
      <DialogModal
        description={
          <LongTextTooltip
            text={
              `You are going to remove the Event - ${DataHelper.getWebhooksEventType(
                webhookEventDeleteModalProps.data as string
              )}. Please confirm the deletion.` ?? ''
            }
            maxLength={100}
          />
        }
        {...webhookEventDeleteModalProps}
      />
    </div>
  )
}

export default memo(EditWebhookPage)
