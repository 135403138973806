import { useState } from 'react'

/* eslint-disable import/no-extraneous-dependencies */
import ObjHelper from '@lib/helpers/obj.helper'
import { Pagination } from '@lib/clients/types'
import { usePagination } from '@lib/hooks'
import { DataFetchStatus, UseEfsItemsDataFetcher } from './types'
import { ListEfsItemsRequestFilters } from '@lib/clients/assets-items/types'
import { ListEfsItemsClient } from '@lib/clients/assets-items'
import { EfsItem } from 'blues-corejs/dist'
import { ListEfsItemsResponse } from '@lib/clients/assets-items/list-efs-items'

const EfsItemsClient = new ListEfsItemsClient()

interface ExtendedUseEfsItemsDataFetcher extends UseEfsItemsDataFetcher {
  fetchOnRefreshInterval: (filter?: ListEfsItemsRequestFilters) => Promise<void>
}

export function useEfsItemsForAssetDataFetcher(
  request?: ListEfsItemsRequestFilters
): ExtendedUseEfsItemsDataFetcher {
  const [dataFetchStatus, setDataFetchStatus] = useState<DataFetchStatus>(
    DataFetchStatus.IDLE
  )
  const [assetItemsData, setAssetItemsData] = useState<Array<EfsItem>>([])

  const {
    updatePageTokenAndFlagFetched,
    pageToken,
    isNextPageAvailable,
    resetPagination,
  } = usePagination()

  const updateAssetItemsData = (assetItemsResponse: Array<EfsItem>) => {
    setAssetItemsData((prevAssetItems) => [
      ...prevAssetItems,
      ...assetItemsResponse,
    ])
  }

  const updateAssetItemsDataOnRefreshInterval = (
    assetItemsResponse: Array<EfsItem>
  ) => {
    setAssetItemsData((prevState) => {
      const assetItemsMap = new Map(prevState.map((item) => [item.id, item]))

      assetItemsResponse.forEach((item) => {
        assetItemsMap.set(item.id, item)
      })

      return Array.from(assetItemsMap.values())
    })
  }

  const handleFetchSuccess = (response: ListEfsItemsResponse & Pagination) => {
    updateAssetItemsData(response.objects)
    updatePageTokenAndFlagFetched(response.pageToken || undefined)
  }

  const resetData = () => {
    setAssetItemsData([])
    resetPagination()
  }

  const isLoadingData = () => dataFetchStatus === DataFetchStatus.IS_LOADING

  const isFetchAllowed = () => {
    return request && ObjHelper.hasValues(request) && !isLoadingData()
  }

  const resetFetchStatus = () => {
    setDataFetchStatus(DataFetchStatus.IDLE)
  }

  const fetchData = async (filter?: ListEfsItemsRequestFilters) => {
    if (!isFetchAllowed() || !isNextPageAvailable()) {
      return
    }

    setDataFetchStatus(DataFetchStatus.IS_LOADING)
    try {
      if (dataFetchStatus === DataFetchStatus.IDLE) {
        const response = await EfsItemsClient.listEfsItems({
          ...request,
          ...filter,
          pageToken,
        })

        setDataFetchStatus(DataFetchStatus.SUCCESS)
        handleFetchSuccess(response)
      }
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset requestin ${__filename}: ${error}`
      )
      setDataFetchStatus(DataFetchStatus.FAILURE)
    } finally {
      resetFetchStatus()
    }
  }

  const fetchOnRefreshInterval = async (
    filter?: ListEfsItemsRequestFilters
  ) => {
    try {
      const response = await EfsItemsClient.listEfsItems({
        ...request,
        ...filter,
        pageToken,
      })

      updateAssetItemsDataOnRefreshInterval(response.objects)
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset requestin ${__filename}: ${error}`
      )
    }
  }

  return {
    onFetchData: fetchData,
    data: assetItemsData,
    dataFetchStatus,
    resetData,
    isLoadingData,
    fetchOnRefreshInterval,
  }
}
