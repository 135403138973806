import { WebhookFormInterface } from '@forms/add-webhook/data'
import RegexConstants from '@lib/constants/regex.constant'
import { VIRow } from '@lib/engine-types'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
} from '@lib/models/webhook.model'

const authorizationOptions: VIRow = [
  {
    value: 'none',
    label: 'None',
    name: 'none',
  },
  {
    value: 'basic_authorization',
    label: 'Basic Authorization',
    name: 'basic_authorization',
  },
  {
    value: 'bearer',
    label: 'Bearer',
    name: 'bearer',
  },
  {
    value: 'api_key',
    label: 'API Key',
    name: 'api_key',
  },
]

const eventsOptions: VIRow = [
  {
    value: 'vault',
    label: 'Vault',
    name: 'vault',
  },
  {
    value: 'recovery_point',
    label: 'Recovery Point',
    name: 'recovery_point',
  },
]

const versionOptions: VIRow = [
  {
    value: 'v0',
    label: 'V0',
    name: 'v0',
  },
]

const severityOptions: VIRow = [
  {
    value: 0,
    label: 'Error',
    name: 'Severity.ERROR',
  },
  {
    value: 0,
    label: 'Warning',
    name: 'Severity.WARNING',
  },
  {
    value: 0,
    label: 'Info',
    name: 'Severity.INFO',
  },
  {
    value: 0,
    label: 'Trace',
    name: 'Severity.TRACE',
  },
]

const validateAuthorizationFields = (
  value: string,
  fieldName: string,
  webhook?: WebhookModel
) => {
  const auth = webhook?.getAuthentication().intoInner()

  if (auth instanceof BasicAuth) {
    if (fieldName === 'login' || auth.isLoginEncrypted()) {
      return true
    }

    if (fieldName === 'password' || auth.isPasswordEncrypted()) {
      return true
    }
  }

  if (auth instanceof ApiKey) {
    if (fieldName === 'apiKey' || auth.isKeyEncrypted()) {
      return true
    }

    if (fieldName === 'apiValue' || auth.isValueEncrypted()) {
      return true
    }
  }

  if (auth instanceof BearerToken) {
    if (fieldName === 'token' || auth.isTokenEncrypted()) {
      return true
    }
  }

  if (!value?.length) {
    return 'This field is required'
  }

  if (
    RegexConstants.ONLY_ASCII_CHARACTERS.test(value) &&
    !RegexConstants.WITH_WHITESPACE.test(value)
  ) {
    return true
  }

  return 'Only ASCII characters are allowed'
}

const buildDefaultFormData = (
  webhook: WebhookModel
): Record<keyof WebhookFormInterface, FormFieldInterface> => ({
  name: {
    name: 'name',
    placeholder: 'Name',
    validationRules: {
      required: 'This field is required',
      maxLength: {
        value: 100,
        message: 'Maximal length is 100 characters',
      },
    },
  },
  endpoint: {
    name: 'endpoint',
    placeholder: 'your-endpoint',
    validationRules: {
      required: true,
      validate: (value: string) =>
        /^(https:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
          value
        ) || 'Invalid URL',
    },
  },
  authorization: {
    name: 'authorization',
    options: authorizationOptions,
    value: authorizationOptions[0]?.value,
  },
  login: {
    name: 'login',
    validationRules: {
      validate: (value: string) =>
        validateAuthorizationFields(value, 'login', webhook),
    },
  },
  password: {
    name: 'password',
    validationRules: {
      validate: (value: string) =>
        validateAuthorizationFields(value, 'password', webhook),
    },
  },
  token: {
    name: 'token',
    validationRules: {
      validate: (value: string) =>
        validateAuthorizationFields(value, 'token', webhook),
    },
  },
  apiKey: {
    name: 'apiKey',
    validationRules: {
      validate: (value: string) =>
        validateAuthorizationFields(value, 'apiKey', webhook),
    },
  },
  apiValue: {
    name: 'apiValue',
    validationRules: {
      validate: (value: string) =>
        validateAuthorizationFields(value, 'apiValue', webhook),
    },
  },

  description: {
    name: 'description',
    validationRules: {
      maxLength: {
        value: 100,
        message: 'Maximal length is 100 characters',
      },
    },
  },
  eventType: {
    name: 'eventType',
    value: eventsOptions[0]?.value,
    options: eventsOptions,
  },
  version: {
    name: 'version',
    value: versionOptions[0]?.value,
    options: versionOptions,
  },
  severity: {
    name: 'severity',
    options: severityOptions,
    label: 'Select severities',
  },
})

export default buildDefaultFormData
