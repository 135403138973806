import React from 'react'
import Box from '@mui/material/Box'
import { IconPopover } from '@components-simple/icon-popover'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'
import { useEntropyDetectionOption } from './use-entropy-detection-option'
import { EntropyDetectionCheckboxLabel } from './entropy-detection-checkbox-label'
import { ENTROPY_DETECTION_CONSTANTS } from './consts'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'

interface Props {
  isEntropyEnabled: boolean
  onEntropyEnabledChange: (value: boolean) => void
  integrityScanOptions: IntegrityScanInterface
}

export function EntropyDetectionCheck({
  isEntropyEnabled,
  onEntropyEnabledChange,
  integrityScanOptions,
}: Props) {
  const { isEntropyEnabled: isEntropyFeatureEnabled } =
    useIsEntropyTrendsEnabled()

  const { isEntropyFeatureAvailable, hintText } =
    useEntropyDetectionOption(integrityScanOptions)

  if (!isEntropyFeatureEnabled) {
    return null
  }

  return (
    <Box>
      <Box className="addPolicyTitle">
        {ENTROPY_DETECTION_CONSTANTS.DETECTION_TITLE}
        <IconPopover
          variant="info"
          header={ENTROPY_DETECTION_CONSTANTS.DETECTION_INFO_LABEL}
          message={ENTROPY_DETECTION_CONSTANTS.DETECTION_INFO_MESSAGE}
        />
      </Box>
      <Box className="questionBlock">
        <V2Checkbox
          disabled={!isEntropyFeatureAvailable}
          onChange={onEntropyEnabledChange}
          checked={isEntropyEnabled}
          label={
            <EntropyDetectionCheckboxLabel
              hintText={hintText}
              isEntropyFeatureAvailable={isEntropyFeatureAvailable}
            />
          }
        />
      </Box>
    </Box>
  )
}
