/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'
import RedStackModel from '@lib/models/red-stack.model'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { EBS, EC2, EFS, S3Bucket } from '@lib/models/assets'
import {
  RansomwareScan,
  RansomwareScanBackup,
} from 'blues-corejs/dist/models/scans/ransomware'
import {
  MalwareScan,
  MalwareScanBackup,
} from 'blues-corejs/dist/models/scans/malware'
import {
  FilesystemScanBackup,
  FilesystemScanCheck,
} from 'blues-corejs/dist/models/scans/filesystem-check'
import { ListBackupsForAsset } from '@lib/clients/backups/types'
import { Nullable } from '@lib/engine-types'
import { BackupSource } from 'blues-corejs/dist/models/scans/ransomware/types'
import { BadgesStatusesType } from '@components-simple/tab-badge-label/types'
import { BackupType } from '@lib/clients/assets/types'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'
import { ThreatStatus } from 'blues-corejs/dist/models/threats/types'
import { Threat } from 'blues-corejs/dist'

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

export function getAliasNameForAsset(
  assetsModel: EC2 | EBS | S3Bucket | EFS,
  allActiveRedStacks: Array<RedStackModel>
) {
  if (allActiveRedStacks.length === 0 || !assetsModel) {
    return {}
  }
  const accountId: string = assetsModel.awsAccountId

  return allActiveRedStacks.reduce((accIdWithAliasName: AliasName, redItem) => {
    if (accountId === redItem.awsAccount) {
      return {
        ...accIdWithAliasName,
        [redItem.awsAccount]: redItem.accountAlias,
      }
    }
    return accIdWithAliasName
  }, {})
}

export function instanceOfBackupSource(object: any): object is BackupSource {
  return 'backupSource' in object
}

export function instanceOfRansomwareScanBackup(
  object: any
): object is RansomwareScanBackup {
  return 'ransomwareScanBackup' in object
}

export function instanceOfMalwareScanBackup(
  object: any
): object is MalwareScanBackup {
  return 'malwareScanBackup' in object
}

export function instanceOfFileSystemBackup(
  object: any
): object is FilesystemScanBackup {
  return 'filesystemScanBackup' in object
}

export const emptyBackupsList: ListBackupsForAsset = {
  awsbRpsList: [],
  ebsSnapshotsList: [],
  elastioRpsList: [],
  ovaBackupList: [],
}

export function getLastScanByDateAnyModel(
  lastScans: Array<MalwareScan | RansomwareScan | FilesystemScanCheck>
): Nullable<MalwareScan | RansomwareScan | FilesystemScanCheck> {
  if (!lastScans.length) {
    return null
  }
  const lastScanAt = Math.max(
    ...lastScans.map((scan) => scan?.createdAt.getTime())
  )

  const lastScanByDate = lastScans?.find(
    (scan) => scan?.createdAt.getTime() >= lastScanAt
  )
  return lastScanByDate as MalwareScan | RansomwareScan | FilesystemScanCheck
}

export function getLastScanByDateForLastBackup<
  T extends MalwareScan | RansomwareScan | FilesystemScanCheck,
>(
  listScans: Array<T>,
  scanIdsFromLastRecoveryPoint: ReadonlyArray<string>,
  assetId: string
): T {
  const scans = listScans?.filter(
    (scan: MalwareScan | RansomwareScan | FilesystemScanCheck) =>
      scanIdsFromLastRecoveryPoint.includes(scan.id)
  )
  // used only the scans of the current asset
  const scanModels = scans.filter((scan: T) => {
    if (
      (scan.scanTarget.target instanceof MalwareScanBackup ||
        scan.scanTarget.target instanceof RansomwareScanBackup ||
        scan.scanTarget.target instanceof FilesystemScanBackup) &&
      scan.scanTarget.target.source.asset?.backupAssetId === assetId
    ) {
      return scan
    }
  })

  const lastScanAt = Math.max(
    ...scanModels.map((scan) => scan.createdAt.getTime())
  )
  const lastScanByDate = scanModels.find(
    (scan) => scan.createdAt.getTime() >= lastScanAt
  )

  return lastScanByDate as T
}

export function getStatusForRecoveryPoint(
  recoveryPoint: Nullable<BackupType>
): Nullable<BadgesStatusesType> {
  if (!recoveryPoint) {
    return null
  }

  switch (recoveryPoint.summaryCheckStatus) {
    case SummaryCheckStatus.GOOD:
      return 'clean'
    case SummaryCheckStatus.BAD:
      return 'infected'
    case SummaryCheckStatus.UNCHECKED:
      return 'unscanned'
  }
}

export function getAliasNamesForAccounts(
  allActiveRedStacks: Array<RedStackModel>,
  accountIds: Set<string>
) {
  return allActiveRedStacks.reduce((accountsAliases, redStack) => {
    if (accountIds.has(redStack.awsAccount)) {
      return {
        ...accountsAliases,
        [redStack.awsAccount]: redStack.accountAlias,
      }
    }
    return accountsAliases
  }, {} as AliasName)
}

export const customColorResolver =
  (lastScan: Nullable<Date>) => (isHealthy: boolean) => {
    if (!lastScan) {
      return 'var(--grey500)'
    }
    return isHealthy ? 'var(--green500)' : 'var(--red400)'
  }

export const getDetectedThreats = (threats: Array<Threat>) => {
  return threats.filter((threat) => threat.status === ThreatStatus.DETECTED)
}
