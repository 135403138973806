/* eslint-disable import/no-extraneous-dependencies */
import { InventoryInstanceOutput } from 'blues-corejs/dist/use_cases/list_inventory_instances/types'

/**
 * Sorts to last scan first, last backup second key, prioritize last scan.
 * If both have no value, sort  by Instance name A-Z (use instance name tag followed by ID)
 */
export function initialPrioritySortForEC2Table(
  instances: Array<InventoryInstanceOutput>
) {
  return instances.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const aLastBackupTime = a.lastBackup?.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const instanceAName = a.instanceName || a.instanceId
    const instanceBName = b.instanceName || b.instanceId

    return instanceAName.localeCompare(instanceBName)
  })
}
