import React from 'react'

export default function SliderLine() {
  return (
    <svg width="100%" height="1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="100%"
        y1="0.5"
        x2="-5.24537e-08"
        y2="0.499916"
        stroke="#1A1F28"
        strokeDasharray="8 8"
      />
    </svg>
  )
}
