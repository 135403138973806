/* eslint-disable import/no-extraneous-dependencies */
import {
  ActivateRequest,
  DeactivateRequest,
  DeleteRequest,
  DiscoverInstalledCfnRequest,
  Empty,
  GetAwsAccountInfoRequest,
  InstallRedstackRequest,
  ListAvailabilityZonesRequest,
  ListAwsAccountsRequest,
  ListAwsSecurityGroupsRequest,
  ListAwsVpcsRequest,
  RedStackInstallParameters,
  RepairCloudConnectorRequest,
  RexPromiseClient,
  UpdateAwsAccountRequest,
} from '../grpc'

import { ListRequest } from 'blue-stack-libs/users-grpc-libs/js/users/messages/pat_pb'

import { GrpcClient } from './grpc'
import { CloudConnectorTransformer } from '../transformers/rex'
import { CloudConnector } from 'blues-corejs/dist/models/cloud-connector/index'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import { RedStackModel } from '../models/base-model'
import { RedStackFactory } from '../transformers/rex/cloud-connector-transformer'
import { ValueInterface, VIRow } from '../engine-types'
import GeneralFactory from '../models/red-stack/general.factory'
import VpcModel from '../models/red-stack/vpc.model'
import VpcFactory from '../models/red-stack/vpc.factory'
import { flatDeep } from '../helpers/arr.helper'
import AwsAccountInfoModel from '../models/red-stack/aws-account-info.model'
import AwsAccountInfoFactory from '../models/red-stack/aws-account-info.factory'
import CfnModel from '../models/red-stack/cfn.model'
import CfnFactory from '../models/red-stack/cfn.factory'
import AvailabilityZoneModel from '../models/red-stack/availability-zone.model'
import AvailabilityZoneFactory from '../models/red-stack/availability-zone.factory'
import AwsSecurityGroupsModel from '../models/red-stack/aws-security-groups.model'
import AwsSecurityGroupsFactory from '../models/red-stack/aws-security-groups.factory'
import DeploymentFilesInterface from '../models/red-stack/deployment-files.interface'

export class RexClient extends GrpcClient<RexPromiseClient> {
  #rexClient: RexPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#rexClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'RexClient'
  }

  protected initClient(hostName: string): RexPromiseClient {
    return new RexPromiseClient(hostName, null, null)
  }

  async getDeploymentFiles(): Promise<DeploymentFilesInterface> {
    const request = new Empty()
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.getDeploymentFiles(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/getDeploymentFiles',
      }
    )

    const objResult = result.toObject()
    return {
      cfTemplate: objResult.cfTemplateJson,
      tfArchiveUrl: objResult.tfArchiveUrl,
    }
  }

  async activate(id: string): Promise<string> {
    const request = new ActivateRequest()
    request.setRedStackId(id)
    const result = await this.callGrpcService(
      () => this.#rexClient.activate(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/activate',
      }
    )

    const resultObject = result.toObject()
    return resultObject.jobId
  }

  async deactivate(id: string): Promise<any> {
    const request = new DeactivateRequest()
    request.setRedStackId(id)
    const result = await this.callGrpcService(
      () => this.#rexClient.deactivate(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/deactivate',
      }
    )

    return result.toObject()
  }

  async delete(id: string): Promise<any> {
    const request = new DeleteRequest()
    request.setRedStackId(id)
    const result = await this.callGrpcService(
      () => this.#rexClient.delete(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/delete',
      }
    )

    return result.toObject()
  }

  async updateAwsAccount(
    accountId: string,
    accountAliasName: string,
    accountDescription: string
  ): Promise<any> {
    const request = new UpdateAwsAccountRequest()
    request.setAwsAccountId(accountId)
    request.setAccountAlias(accountAliasName)
    request.setDescription(accountDescription)
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.updateAwsAccount(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/updateAwsAccount',
      }
    )

    return result.toObject()
  }

  async listGetAllRedstacks(): Promise<Array<CloudConnector>> {
    const request = new ListRequest()

    const response = (
      await this.callGrpcService(
        () =>
          this.#rexClient.getAllRedstacks(request, this.metadata(this.#token)),
        {
          requestName: 'RexPromiseClient/getAllRedstacks',
        }
      )
    ).toObject()

    return response.redStacksList.map((cloudConnector) =>
      new CloudConnectorTransformer(cloudConnector).transform()
    )
  }

  async getAllRedstacks(): Promise<Array<RedStackModel>> {
    const request = new ListRequest()

    const result = await this.callGrpcService(
      () =>
        this.#rexClient.getAllRedstacks(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/getAllRedstacks',
      }
    )

    return result
      .toObject()
      .redStacksList.map(RedStackFactory.buildFromScanRedStack)
  }

  async getAllRedStacksByAccountId(id: string): Promise<Array<RedStackModel>> {
    return this.getAllRedstacks().then((arr) =>
      arr.filter((v) => v.awsAccount === id)
    )
  }

  async getAllActiveRedStacks(): Promise<Array<RedStackModel>> {
    return this.getAllRedstacks().then((arr) => arr.filter((v) => v.isActive))
  }

  async listSupportedAwsRegions(): Promise<VIRow> {
    const request = new Empty()

    const result = await this.callGrpcService(
      () =>
        this.#rexClient.listSupportedAwsRegions(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'RexPromiseClient/listSupportedAwsRegions',
      }
    )

    return GeneralFactory.buildPossibleRegions(
      result.toObject().regionNamesList
    )
  }

  async listAwsVpcs(
    accountId: string,
    regionName: string
  ): Promise<Array<VpcModel>> {
    const request = new ListAwsVpcsRequest()
    request.setAccountId(accountId)
    request.setRegionName(regionName)

    const result = await this.callGrpcService(
      () => this.#rexClient.listAwsVpcs(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/listAwsVpcs',
      }
    )

    return result
      .toObject()
      .vpcsList.map((params) =>
        VpcFactory.buildFromGrpc({
          ...params,
          accountId,
          regionName,
        })
      )
      .sort((a, b) => (a.isDefault < b.isDefault ? 1 : -1))
  }

  async listAwsVpcArr(accountId: string): Promise<Array<VpcModel>> {
    const supportedRegions = await this.listSupportedAwsRegions()
    const requests: Array<Promise<Array<VpcModel>>> = []
    supportedRegions.forEach((region: ValueInterface) => {
      requests.push(this.listAwsVpcs(accountId, region.name))
    })
    const allVpcTree: Array<Array<VpcModel>> = await Promise.all(requests)
    return flatDeep<VpcModel>(allVpcTree)
  }

  async getAwsAccountInfo(
    accountIds: Array<string>
  ): Promise<Array<AwsAccountInfoModel>> {
    const request = new GetAwsAccountInfoRequest()
    request.setAwsAccountIdsList(accountIds)
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.getAwsAccountInfo(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/getAwsAccountInfo',
      }
    )

    const resultObj = result.toObject()
    return resultObj.awsAccountsInfoList.map(
      AwsAccountInfoFactory.buildFromGrpc
    )
  }

  async repairCloudConnector(cloudConnectorId: string): Promise<string> {
    const request = new RepairCloudConnectorRequest()
    request.setRedStackId(cloudConnectorId)
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.repairCloudConnector(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'RexPromiseClient/repairCloudConnector',
      }
    )

    return result.toObject().jobId
  }

  async getCloudFormationLink(): Promise<string> {
    const request = new Empty()
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.getCloudFormationLink(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'RexPromiseClient/getCloudFormationLink',
      }
    )

    return result.toObject().link
  }

  async installRedstack(
    accountId: string,
    vpcId: string,
    regionName: string,
    safetyLock: boolean,
    subnetIdsList: Array<string>
  ): Promise<string> {
    const request = new InstallRedstackRequest()
    request.setAccountId(accountId)
    const region = new RedStackInstallParameters()
    region.setVpcId(vpcId)
    region.setSubnetIdsList(subnetIdsList)
    region.setRegion(regionName)
    request.setRegionsList([region])
    region.setSafetyLock(safetyLock)

    const result = await this.callGrpcService(
      () =>
        this.#rexClient.installRedstack(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/installRedstack',
      }
    )

    return result.toObject().jobsIdsList[0] ?? ''
  }

  async discoverInstalledCfn(accountId: string): Promise<CfnModel> {
    const request = new DiscoverInstalledCfnRequest()
    request.setAwsAccountId(accountId)
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.discoverInstalledCfn(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'RexPromiseClient/discoverInstalledCfn',
      }
    )

    return CfnFactory.buildFromGrpc(result.toObject())
  }

  async listAwsAccounts(): Promise<any> {
    const request = new ListAwsAccountsRequest()

    const result = await this.callGrpcService(
      () =>
        this.#rexClient.listAwsAccounts(request, this.metadata(this.#token)),
      {
        requestName: 'RexPromiseClient/listAwsAccounts',
      }
    )

    return result.toObject().awsAccountsList
  }

  async listAvailabilityZones(
    redStackId: string
  ): Promise<Array<AvailabilityZoneModel>> {
    const request = new ListAvailabilityZonesRequest()
    request.setRedStackId(redStackId)
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.listAvailabilityZones(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'RexPromiseClient/listAvailabilityZones',
      }
    )

    return result
      .toObject()
      .zonesList.map(AvailabilityZoneFactory.buildFromGrpc)
  }

  async listAwsSecurityGroups(
    awsAccountId: string,
    awsRegion: string
  ): Promise<Array<AwsSecurityGroupsModel>> {
    const request = new ListAwsSecurityGroupsRequest()
    request.setAwsAccountId(awsAccountId)
    request.setAwsRegion(awsRegion)
    const result = await this.callGrpcService(
      () =>
        this.#rexClient.listAwsSecurityGroups(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName: 'RexPromiseClient/listAwsSecurityGroups',
      }
    )

    return result
      .toObject()
      .securityGroupsList.map(AwsSecurityGroupsFactory.buildFromGrpc)
  }
}
