import React from 'react'
import { Table, Row } from '@tanstack/react-table'
import { CheckboxTable } from '@components/table/core/row/checkbox'

const DEFAULT_HEADER_ID_WITH_SELECTION = 'select'

export function getRowSelection<T>() {
  return {
    id: DEFAULT_HEADER_ID_WITH_SELECTION,
    header: ({ table }: { table: Table<Table<T>> }) => (
      <CheckboxTable
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: { row: Row<T> }) => (
      <CheckboxTable
        {...{
          checked: row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: row.getToggleSelectedHandler(),
        }}
      />
    ),
  }
}
