import BaseDataConstant from './base-data.constant'

export interface UserStatusData {
  value: number
  label: string
  color: string
  clName: string
}

class UserStatusConstantInner extends BaseDataConstant<UserStatusData> {
  get STATUS_UNDEFINED(): UserStatusData {
    return {
      value: -1,
      label: 'Undefined',
      color: '',
      clName: 'ustUndefined',
    }
  }

  get STATUS_ACTIVE(): UserStatusData {
    return {
      value: 1,
      label: 'Active',
      color: '',
      clName: 'ustActive',
    }
  }

  get STATUS_INVITED(): UserStatusData {
    return {
      value: 2,
      label: 'Invited',
      color: '',
      clName: 'ustInvited',
    }
  }

  get STATUS_EXPIRED(): UserStatusData {
    return {
      value: 3,
      label: 'Expired',
      color: '',
      clName: 'ustExpired',
    }
  }

  get STATUS_DISABLED(): UserStatusData {
    return {
      value: 4,
      label: 'Disabled',
      color: '',
      clName: 'ustDisabled',
    }
  }

  getFromTheUsersString(value: string) {
    switch (value) {
      case 'active':
        return this.STATUS_ACTIVE
      case 'invited':
        return this.STATUS_INVITED
      case 'expired':
        return this.STATUS_EXPIRED
      case 'disabled':
        return this.STATUS_DISABLED

      default:
        return this.STATUS_UNDEFINED
    }
  }
}

const UserStatusConstant = UserStatusConstantInner.build(
  UserStatusConstantInner,
  'STATUS_'
)

export default UserStatusConstant
