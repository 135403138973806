/* eslint-disable import/no-extraneous-dependencies */
import {
  PolicyEc2EbsTableDataAttrs,
  PolicyEc2EbsTableRow,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import dayjs from 'dayjs'

export class PolicyEc2EbsTableData {
  readonly #ec2EbsAssets: AssetsForPolicyCoverage

  readonly #lastBackup: Map<
    string,
    {
      backup: EBSSnapshot | AWSBRecoveryPoint | ElastioRecoveryPoint
    }
  >

  constructor(parameters: PolicyEc2EbsTableDataAttrs) {
    this.#ec2EbsAssets = parameters.ec2EbsAssets
    this.#lastBackup = parameters.lastBackup
  }

  getEc2AssetsList = (): Array<PolicyEc2EbsTableRow> => {
    return this.#ec2EbsAssets.map((row) => {
      const resourceDisplayedValue = row.asset.name || row.asset.awsId

      const resourceId = row.asset.name ? row.asset.awsId : row.asset.name

      const tags = row.asset.tags
        .map((tag) => `${tag.key}:${tag.value}`)
        .join(', ')

      const lastBackup = this.#lastBackup.get(row.asset.id)

      const backupPolicies = row.asset.backupPolicies

      return new PolicyEc2EbsTableRow({
        id: row.asset.id,
        resourceId: resourceDisplayedValue + ' ' + resourceId,
        assetTags: tags,
        assetType: 'EC2',
        accountId: row.asset.awsAccountId,
        region: row.asset.awsRegion,
        lastBackup: lastBackup
          ? (dayjs(lastBackup.backup.timestamp).format(
              'hh:mm A, MM/DD/YYYY'
            ) as any)
          : 'No Backups',
        policy:
          backupPolicies.length === 0
            ? 'Not Assigned'
            : backupPolicies.join(', '),
      })
    })
  }
}
