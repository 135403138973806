import { create } from 'zustand'

type ConfirmDeleteStore = {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  rulesIds: Array<string>
  setRulesIds: (rulesIds: Array<string>) => void
}

export const useConfirmDeleteStore = create<ConfirmDeleteStore>((set) => ({
  isOpen: false,
  rulesIds: [],
  setOpen: (isOpen: boolean) =>
    set((state) => ({
      ...state,
      isOpen,
    })),
  setRulesIds: (rulesIds: Array<string>) =>
    set((state) => ({
      ...state,
      rulesIds,
    })),
}))
