import React, { Fragment } from 'react'
import {
  useAssetMap,
  useSelectedCloudConnectorJobFilters,
  useCloudConnectorJobsFiltersActions,
} from '../stores'
import { computeAssetName } from '../helpers'
import { BaseSelectedChip } from '../shared'

export function SelectedAssetIdsChips() {
  const { assetIdsList } = useSelectedCloudConnectorJobFilters()
  const { removeFilter } = useCloudConnectorJobsFiltersActions()
  const assetsMap = useAssetMap()

  if (!assetIdsList.size) {
    return null
  }

  function handleRemoveAssetId(assetId: string) {
    removeFilter('assetIdsList', assetId)
  }

  return (
    <Fragment>
      {Array.from(assetIdsList).map((assetId) => {
        const assetName = computeAssetName(assetsMap.get(assetId))

        return (
          <BaseSelectedChip
            key={assetId}
            filterName="Asset ID"
            filterValue={assetName}
            onDelete={() => handleRemoveAssetId(assetId)}
          />
        )
      })}
    </Fragment>
  )
}
