import RegexConstants from '@lib/constants/regex.constant'
import ErrorValidationConstants from '@lib/constants/error-validation.constant'

function accountIdValidator(accountId: string) {
  if (RegexConstants.ACCOUNT_ID.test(accountId)) {
    return true
  }
  return ErrorValidationConstants.ACCOUNT_ID
}

export default accountIdValidator
