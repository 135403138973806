import React from 'react'
import { ActionMenu } from '@components/table'
import EditIcon from '@inline-img/general/menu/edit-icon'
import DeleteIcon from '@inline-img/general/menu/delete-icon'
import { ReportFormType } from '@features/reports/schedules/shared'

import { CellProps } from './types'
import { useTabsActions } from '@features/reports/schedules/use-data-store'
import {
  DrawerName,
  useDrawerActions,
} from '@features/reports/schedules/use-drawer-store'
import { extractAllReportTypesAndParams } from '@features/reports/schedules/shared'

function CellActionsMenuItems<TData, TValue>({
  cell,
}: CellProps<TData, TValue>) {
  const rowData = cell.row.original

  // @ts-ignore
  const id = rowData?.id ?? ''
  // @ts-ignore
  const reportKindWithParams = rowData?.reportKindWithParams

  const {
    setEditingItemId,
    setDeletingItemId,
    setSelectedFormType,
    setReportSourceType,
  } = useTabsActions()

  const { openDrawer } = useDrawerActions()

  const { reportType } = extractAllReportTypesAndParams(reportKindWithParams)
  const handleModify = () => {
    setReportSourceType(reportType as string)
    openDrawer(DrawerName.GenerateReport, {})

    setSelectedFormType(ReportFormType.SCHEDULED)

    setEditingItemId(id)
  }

  const handleDelete = () => {
    setDeletingItemId(id)
  }

  const options = [
    {
      label: 'Modify',
      onClick: handleModify,
      icon: EditIcon,
    },
    {
      label: 'Delete',
      onClick: handleDelete,
      icon: DeleteIcon,
    },
  ]
  return <ActionMenu options={options} />
}

export default CellActionsMenuItems
