/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  EC2,
  EBS,
  EFS,
  S3Bucket,
  GenericHost,
  Asset,
  OvaServer,
} from 'blues-corejs/dist'
import { EbsSummary } from './ebs-summary'
import { Ec2Summary } from './ec2-summary'
import { BucketSummary } from './bucket-summary'
import { EfsSummary } from './efs-summary'
import { GenericHostSummary } from './generic-host-summary'
import { OvaServerSummary } from './ova-server-summary'
import Typography from '@mui/material/Typography'
import { StyledHeader } from '@features/asset-page/more-details/more-details-components/styles'
import Box from '@mui/system/Box'

interface Props {
  asset: Asset
}

function computeAssetSummary(asset: Asset) {
  if (asset instanceof EBS) {
    return <EbsSummary asset={asset} />
  }

  if (asset instanceof EC2) {
    return <Ec2Summary asset={asset} />
  }

  if (asset instanceof S3Bucket) {
    return <BucketSummary asset={asset} />
  }

  if (asset instanceof EFS) {
    return <EfsSummary asset={asset} />
  }

  if (asset instanceof GenericHost) {
    return <GenericHostSummary asset={asset} />
  }

  if (asset instanceof OvaServer) {
    return <OvaServerSummary asset={asset} />
  }

  return null
}

export function AssetSummary({ asset }: Props) {
  const computedAssetSummary = computeAssetSummary(asset)

  return (
    <>
      <StyledHeader>
        <Typography fontWeight="600">General Information</Typography>
      </StyledHeader>
      <Box marginTop="16px" paddingX="16px">
        {computedAssetSummary}
      </Box>
    </>
  )
}
