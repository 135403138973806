import FormControl from '@mui/material/FormControl'
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import { styled } from '@mui/material/styles'

export const FormControlStyled = styled(FormControl)`
  display: flex;
  align-items: center;
  column-gap: 24px;
  flex-direction: row;
`

interface FormLabelStyledProps extends FormLabelProps {
  component?: React.ElementType
}

export const FormLabelStyled = styled(FormLabel)<FormLabelStyledProps>`
  color: var(--grey700);
  font-size: 14px;
  font-weight: 600;
`
FormLabelStyled.defaultProps = {
  component: 'legend',
}
