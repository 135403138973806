import { Stack, Box, Typography, FormGroup } from '@mui/material'
import Grid from '@mui/material/Grid2'

import Ec2EbsRecoverAssuranceSettings from './ec2-ebs-recover-assurance-settings'
import Ec2EbsRecoverySettings from './ec2-ebs-recovery-settings'

import Ec2EbsScanTriggers from './ec2-ebs-scan-triggers'

const ProtectionEc2Ebs = () => {
  return (
    <Grid container spacing={0}>
      <Grid
        size={{
          md: 6,
          lg: 6,
        }}
      >
        <Box
          borderRight={{
            sm: 0,
            md: 1,
          }}
          paddingRight={{
            sm: 0,
            md: 2,
          }}
          minHeight={'calc(100vh - 200px)'}
        >
          <Typography
            variant="body1"
            mb={2.5}
            fontWeight={700}
            color="text.secondary"
          >
            Recovery Assurance Settings
          </Typography>
          <FormGroup>
            <Stack spacing={3}>
              <Ec2EbsRecoverAssuranceSettings />
            </Stack>
          </FormGroup>
        </Box>
      </Grid>
      <Grid
        size={{
          md: 6,
          lg: 6,
        }}
      >
        <Stack
          spacing={2.5}
          paddingLeft={{
            sm: 0,
            md: 2,
          }}
          minHeight={'calc(100vh - 200px)'}
        >
          <Typography variant="body1" fontWeight={700} color="text.secondary">
            EC2 & EBS Settings
          </Typography>
          <FormGroup>
            <Stack spacing={2} paddingLeft={2}>
              <Typography
                variant="body2"
                mb={2.5}
                fontWeight={700}
                color="text.secondary"
              >
                Recovery Settings
              </Typography>
              <Ec2EbsRecoverySettings />
            </Stack>
          </FormGroup>
          <FormGroup>
            <Stack spacing={2} paddingLeft={2}>
              <Typography
                variant="body2"
                mb={2.5}
                fontWeight={700}
                color="text.secondary"
              >
                Scan Triggers
              </Typography>
              <Ec2EbsScanTriggers />
            </Stack>
          </FormGroup>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ProtectionEc2Ebs
