import React, { memo } from 'react'
import { EngineCallback } from '@lib/engine-types'
import ExpandedIcon from '@inline-img/general/toggle-plus-btn/expanded-icon'
import NormalIcon from '@inline-img/general/toggle-plus-btn/normal-icon'
import clsx from 'clsx'

interface Props {
  disabled?: boolean
  expanded: boolean
  onChange: EngineCallback<boolean>
}

function PlusToggleBtn({ disabled = false, expanded, onChange }: Props) {
  return (
    <div className={clsx('wrap-1618489602659', { ptDisabled: disabled })}>
      <button
        className="ptBtn jsExpBtn"
        data-testid="jsExpBtn"
        type="button"
        onClick={() => onChange(!expanded)}
      >
        <span
          data-testid={`jsNormal ${expanded ? 'hide' : 'show'}`}
          className={clsx('jsNormal', { dNone: expanded })}
        >
          <NormalIcon />
        </span>
        <span
          data-testid={`jsExpanded ${!expanded ? 'hide' : 'show'}`}
          className={clsx('jsExpanded', { dNone: !expanded })}
        >
          <ExpandedIcon />
        </span>
      </button>
    </div>
  )
}

export default memo(PlusToggleBtn)
