/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import React, { useCallback } from 'react'
import { getHumanReadableCloudConnectorJobStatus } from '@features/jobs/cloud-connector-jobs/helpers'
import { BaseSelectedChip } from './base-selected-chip'

export function CloudConnectorJobStatusChip({
  status,
  onDelete,
}: {
  status: CloudConnectorJobStatus
  onDelete: (status: CloudConnectorJobStatus) => void
}) {
  const handleRemoveFilter = useCallback(() => {
    onDelete(status)
  }, [])

  return (
    <BaseSelectedChip
      filterName="Job Status"
      filterValue={getHumanReadableCloudConnectorJobStatus(status)}
      onDelete={handleRemoveFilter}
    />
  )
}
