import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { Alerts } from '@features/alerts'
import { AlertsRoutes } from '@lib/router/routes/alerts/alerts'

export const alertsRoutes: Array<PathRouteProps> = [
  {
    path: AlertsRoutes.root,
    element: (
      <MainTopBarLayout title="Alerts">
        <Alerts />
      </MainTopBarLayout>
    ),
  },
]
