import React, { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { BaseModalProps } from '@lib/interfaces/modal/base-modal-props.interface'

const id = 'base-modal-root'

function BaseModal({
  children,
  show = false,
  onBgClick,
  className = '',
  customStyles = {},
}: BaseModalProps) {
  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'unset'
  }, [show])

  useEffect(() => {
    if (!document.getElementById(id)) {
      const modalRoot = document.createElement('div')
      modalRoot.id = id
      document.body.insertBefore(modalRoot, document.body.firstChild)
    }
  }, [])

  const onBgClickHandler = useCallback((e: any) => {
    if (onBgClick) {
      onBgClick(e)
    }
  }, [])

  if (!show) {
    return null
  }

  const modalWrapperElement = document.getElementById(id)
  if (!modalWrapperElement) {
    return null
  }

  return createPortal(
    <div
      className={`wrap-1603900370692 ${className} jsBaseModal`}
      style={customStyles}
    >
      <div
        onClick={onBgClickHandler}
        className="baseModalBg"
        aria-label="baseModalBg"
      />
      <div className="baseModalContent">{children}</div>
    </div>,
    modalWrapperElement
  )
}

export default BaseModal
