import React from 'react'

function DeleteIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1875 2.4375H9.75V0.8125C9.75 0.597012 9.6644 0.390349 9.51202 0.237976C9.35965 0.0856024 9.15299 0 8.9375 0L4.0625 0C3.84701 0 3.64035 0.0856024 3.48798 0.237976C3.3356 0.390349 3.25 0.597012 3.25 0.8125V2.4375H0.8125C0.597012 2.4375 0.390349 2.5231 0.237976 2.67548C0.0856024 2.82785 0 3.03451 0 3.25C0 3.46549 0.0856024 3.67215 0.237976 3.82452C0.390349 3.9769 0.597012 4.0625 0.8125 4.0625H12.1875C12.403 4.0625 12.6097 3.9769 12.762 3.82452C12.9144 3.67215 13 3.46549 13 3.25C13 3.03451 12.9144 2.82785 12.762 2.67548C12.6097 2.5231 12.403 2.4375 12.1875 2.4375ZM4.875 2.4375V1.625H8.125V2.4375H4.875Z"
        fill="white"
      />
      <path
        d="M1.66969 5.6875L2.03532 12.2688C2.05553 12.4699 2.14997 12.6562 2.30021 12.7914C2.45045 12.9267 2.64569 13.001 2.84782 13H10.1603C10.3617 13 10.556 12.9252 10.7054 12.7901C10.8547 12.655 10.9486 12.4692 10.9688 12.2688L11.3344 5.6875H1.66969Z"
        fill="white"
      />
    </svg>
  )
}

export default DeleteIcon
