import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useNavigation } from '@lib/router/contexts/navigation-context'

export function ErrorFallback() {
  const router = useNavigation()

  function goBack() {
    router.back()
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 3,
          textAlign: 'center',
          p: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          <b>Something went wrong</b>
        </Typography>
        <Typography variant="body1" paragraph>
          The application encountered an issue. Our team has been notified and
          is working on a fix. In the meantime, you might find what you need by
          going back to the previous page.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBack />}
          onClick={goBack}
        >
          Go Back
        </Button>
      </Box>
    </Container>
  )
}
