import { DateRangeTuple } from '@lib/engine-types'
import { ExecutiveReportPeriod } from '@lib/constants/reports/types'
import moment from 'moment-timezone'
import ValueInterface from '@lib/interfaces/value.interface'
import { EMPTY_DATE_RANGE } from '@lib/constants'
import ReportDateRangeConstant from '@lib/constants/report-date-range.constant'

type TimeRangeStringsFormatted = {
  start: string
  end: string
}

const NUMBER_OF_AMOUNT_MONTHS = 3

export function transformDateRangeForDownloadLink(
  dateRange: DateRangeTuple,
  format = 'yyyy-MM-DD HH:mm:ss'
): TimeRangeStringsFormatted {
  return {
    start: moment(dateRange[0]).startOf('day').format(format),
    end: moment(dateRange[1]).endOf('day').format(format),
  }
}

export class PeriodTimeRange {
  static currentMonthDateRange(timezone: string): DateRangeTuple {
    const to = moment().tz(timezone).startOf('day')
    const from = to.clone().startOf('month')

    return [from, to]
  }

  static lastMonthDateRange(timezone: string): DateRangeTuple {
    const fromCurrent = moment().tz(timezone).subtract(1, 'months')
    const from = fromCurrent.clone().startOf('month')
    const to = fromCurrent.clone().endOf('month')

    return [from, to]
  }

  static currentWeekDateRange(timezone: string): DateRangeTuple {
    const to = moment().tz(timezone).startOf('day')
    const from = to.clone().startOf('week')

    return [from, to]
  }

  static lastWeekDateRange(timezone: string): DateRangeTuple {
    const fromCurrent = moment().tz(timezone).subtract(1, 'weeks')
    const from = fromCurrent.clone().startOf('week')
    const to = fromCurrent.clone().endOf('week')

    return [from, to]
  }

  static dayBeforeDateRange(timezone: string): DateRangeTuple {
    const yesterday = moment().tz(timezone).subtract(1, 'days')
    const from = yesterday.clone().startOf('day')
    const to = yesterday.clone().endOf('day')

    return [from, to]
  }

  static lastYearDateRange(timezone: string): DateRangeTuple {
    const fromCurrent = moment().tz(timezone).subtract(1, 'years')
    const from = fromCurrent.clone().startOf('year')
    const to = fromCurrent.clone().endOf('year')

    return [from, to]
  }

  static currentYearDateRange(timezone: string): DateRangeTuple {
    const to = moment().tz(timezone).startOf('day')
    const from = to.clone().startOf('year')

    return [from, to]
  }

  static lastQuarterDateRange(timezone: string): DateRangeTuple {
    const lastQuarter = moment().tz(timezone).subtract(3, 'months').quarter()
    const from = moment().tz(timezone).quarter(lastQuarter).startOf('quarter')
    const to = moment().tz(timezone).quarter(lastQuarter).endOf('quarter')

    return [from, to]
  }

  static currentQuarterDateRange(timezone: string): DateRangeTuple {
    const currentQuarter = moment().tz(timezone).quarter()
    const from = moment()
      .tz(timezone)
      .quarter(currentQuarter)
      .startOf('quarter')
    const to = moment().tz(timezone).startOf('day')

    return [from, to]
  }

  static currentDay(timezone: string): DateRangeTuple {
    const from = moment().tz(timezone).startOf('day')
    const to = moment().tz(timezone).endOf('day')
    return [from, to]
  }

  static lastAmountMonthsDateRange(
    amount: number,
    timezone: string
  ): DateRangeTuple {
    const fromCurrent = moment().tz(timezone).subtract(amount, 'months')
    const from = fromCurrent.clone().startOf('day')
    const to = moment().tz(timezone).endOf('day')

    return [from, to]
  }
}

export function getExecutiveReportDateRangeByPeriod(
  period: ExecutiveReportPeriod,
  timezone: string
): DateRangeTuple {
  let dateRange: DateRangeTuple = EMPTY_DATE_RANGE()
  switch (period) {
    case ExecutiveReportPeriod.THIS_MONTH:
      dateRange = PeriodTimeRange.currentMonthDateRange(timezone)
      break
    case ExecutiveReportPeriod.LAST_MONTH:
      dateRange = PeriodTimeRange.lastMonthDateRange(timezone)
      break
    case ExecutiveReportPeriod.THIS_WEEK:
      dateRange = PeriodTimeRange.currentWeekDateRange(timezone)
      break
    case ExecutiveReportPeriod.LAST_WEEK:
      dateRange = PeriodTimeRange.lastWeekDateRange(timezone)
      break
    case ExecutiveReportPeriod.YESTERDAY:
      dateRange = PeriodTimeRange.dayBeforeDateRange(timezone)
      break
    case ExecutiveReportPeriod.LAST_YEAR:
      dateRange = PeriodTimeRange.lastYearDateRange(timezone)
      break
    case ExecutiveReportPeriod.THIS_YEAR:
      dateRange = PeriodTimeRange.currentYearDateRange(timezone)
      break
    case ExecutiveReportPeriod.LAST_QUARTER:
      dateRange = PeriodTimeRange.lastQuarterDateRange(timezone)
      break
    case ExecutiveReportPeriod.THIS_QUARTER:
      dateRange = PeriodTimeRange.currentQuarterDateRange(timezone)
      break
  }

  return dateRange
}

export function getReportDateRangeByPeriod(
  value: ValueInterface,
  timezone: string
): DateRangeTuple {
  let dateRange = EMPTY_DATE_RANGE()
  switch (value.value) {
    case ReportDateRangeConstant.Today:
      dateRange = PeriodTimeRange.currentDay(timezone)
      break
    case ReportDateRangeConstant.Yesterday:
      dateRange = PeriodTimeRange.dayBeforeDateRange(timezone)
      break
    case ReportDateRangeConstant.LastWeek:
      dateRange = PeriodTimeRange.lastWeekDateRange(timezone)
      break
    case ReportDateRangeConstant.LastMonth:
      dateRange = PeriodTimeRange.lastMonthDateRange(timezone)
      break
    case ReportDateRangeConstant.ThreeMonths:
      dateRange = PeriodTimeRange.lastAmountMonthsDateRange(
        NUMBER_OF_AMOUNT_MONTHS,
        timezone
      )
      break
    case ReportDateRangeConstant.LastYear:
      dateRange = PeriodTimeRange.lastYearDateRange(timezone)
      break
    case ReportDateRangeConstant.ThisWeek:
      dateRange = PeriodTimeRange.currentWeekDateRange(timezone)
      break
    case ReportDateRangeConstant.ThisMonth:
      dateRange = PeriodTimeRange.currentMonthDateRange(timezone)
      break
    case ReportDateRangeConstant.ThisYear:
      dateRange = PeriodTimeRange.currentYearDateRange(timezone)
      break
  }

  return dateRange
}
