/* eslint-disable import/no-extraneous-dependencies */
import { NotificationsRulesClient } from '@lib/clients/notifications'
import { useState } from 'react'
import { useAlertsListStore } from '@features/alerts/store/alerts-list-store'
import {
  RuleStatus,
  NotificationRule,
} from 'blues-corejs/dist/models/notifications/rule'
import { useInfrastructureScopeActions } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/infrastructure-scope-store'
import { useRuleDetailsActions } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/rule-details-store'

import {
  useCreateRuleDrawerActions,
  useRuleDrawerDataState,
} from '@features/alerts/create-rule-drawer'

import { useNotificationsActions } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/notifications-store'

import {
  EmailUser,
  useEmailSelectionActions,
} from '@features/alerts/create-rule-drawer/stepper/steps/notifications/email-selection/email-store'
import {
  changeStatusForRules,
  prepareDetailsData,
  prepareInfrastructureScopeData,
  prepareNotificationData,
} from './utils'
import { useReviewEnableActions } from '@features/alerts/create-rule-drawer/stepper/steps'

const notificationsRulesClient = new NotificationsRulesClient()

export function useAlertsActions() {
  const {
    setSelectedRows,
    tableData,
    setTableData,
    setLoading: setLoadingStore,
  } = useAlertsListStore()
  const [isLoading, setLoading] = useState(false)
  const { setRuleDetailsData, setAllKinds, setRuleId } = useRuleDetailsActions()
  const { setInfrastructureScopeData } = useInfrastructureScopeActions()
  const { setNotificationsData } = useNotificationsActions()
  const { setEmails } = useEmailSelectionActions()
  const { setReviewEnableData } = useReviewEnableActions()
  const { openDrawer } = useCreateRuleDrawerActions()
  const { listUsers, webhooksList } = useRuleDrawerDataState()

  const handleEnableRule = async (ids: Array<string>) => {
    setLoading(true)
    setLoadingStore(true)
    try {
      await notificationsRulesClient.enableNotificationRule({ ids })
      setSelectedRows({})

      setTableData(
        changeStatusForRules({
          rulesList: tableData,
          changedIds: ids,
          newStatus: RuleStatus.enabled,
        })
      )
    } finally {
      setLoading(false)
      setLoadingStore(false)
    }
  }

  const handleDisableRule = async (ids: Array<string>) => {
    setLoading(true)
    setLoadingStore(true)
    try {
      await notificationsRulesClient.disableNotificationRule({ ids })
      setSelectedRows({})
      setTableData(
        changeStatusForRules({
          rulesList: tableData,
          changedIds: ids,
          newStatus: RuleStatus.disabled,
        })
      )
    } finally {
      setLoading(false)
      setLoadingStore(false)
    }
  }

  const handleDeleteRule = async (ids: Array<string>) => {
    setLoading(true)
    setLoadingStore(true)
    try {
      await notificationsRulesClient.deleteNotificationRule({ ids })
      setSelectedRows({})
      setTableData(tableData.filter((rule) => !ids.includes(rule.id)))
    } finally {
      setLoading(false)
      setLoadingStore(false)
    }
  }

  const handleEditRule = (rule: NotificationRule) => {
    const detailsData = prepareDetailsData(rule)
    const infrastructureScopeData = prepareInfrastructureScopeData(rule)
    const notificationData = prepareNotificationData(
      rule,
      listUsers,
      webhooksList
    )
    const emailsData: Array<EmailUser> = rule.channels.emails.map((email) => ({
      email: email.email,
      id: email.email,
    }))
    const reviewEnableData = { ruleStatus: rule.status }

    setRuleDetailsData(detailsData.ruleDetailsData)
    setAllKinds(detailsData.allKinds)
    setRuleId(detailsData.ruleId)

    setInfrastructureScopeData(infrastructureScopeData.infraData)

    setNotificationsData(notificationData.notificationsData)
    setEmails(emailsData)

    setReviewEnableData(reviewEnableData)

    openDrawer(true)
  }

  return {
    handleEnableRule,
    handleDisableRule,
    handleDeleteRule,
    handleEditRule,
    isLoading,
  }
}
