/* eslint-disable import/no-extraneous-dependencies */
import { OvaAccount } from 'blues-corejs/dist'

const EMPTY_CELL_PLACEHOLDER = '-'

export const getOvaServerAccountName = (
  ovaAccountsList: Array<OvaAccount>,
  ovaAccountId: string
) => {
  const serverAccount = ovaAccountsList.find(
    (account) => account.id === ovaAccountId
  )

  return serverAccount?.name || EMPTY_CELL_PLACEHOLDER
}

export const getOvaServerThreats = (
  hasMalware: boolean,
  hasRansomware: boolean
) => {
  const threats: Array<string> = []

  if (!hasMalware && !hasRansomware) {
    return EMPTY_CELL_PLACEHOLDER
  }

  if (hasRansomware) {
    threats.push('Ransomware')
  }

  if (hasMalware) {
    threats.push('Malware')
  }

  return `${threats.join(' & ')} Found`
}
