/* eslint-disable import/no-extraneous-dependencies */
import { ClientConfig } from '../models/client'
import {
  GetOvaAssetsScannedStatisticsRequest,
  GetOvaFilesScannedStatisticsRequest,
  OvaBackupProviderProto,
  OvaStatisticsPromiseClient,
} from '../grpc'
import {
  GetAssetsScannedStatisticsResponse,
  OvaGetFilesScannedStatisticsResponse,
  OvaStatisticsRequestParams,
} from '../models/ova-stats'
import { OvaBackupProvider } from 'blues-corejs/dist'
import { GrpcClient } from './grpc'

export class OvaStatistics extends GrpcClient<OvaStatisticsPromiseClient> {
  #ovaStatisticsClient: OvaStatisticsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#ovaStatisticsClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'OvaStatistics'
  }

  protected initClient(hostName = ''): OvaStatisticsPromiseClient {
    return new OvaStatisticsPromiseClient(hostName, null, null)
  }

  async getAssetsScannedStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<GetAssetsScannedStatisticsResponse> {
    const request = new GetOvaAssetsScannedStatisticsRequest()

    if (params.ovaAccountIds) {
      request.setOvaAccountIdsList(params.ovaAccountIds)
    }

    if (params.backupProviders) {
      request.setBackupProvidersList(
        params.backupProviders.map((provider) =>
          this.#convertBackupProviderEnumToProto(provider)
        )
      )
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#ovaStatisticsClient.getAssetsScannedStatistics(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/getAssetsScannedStatistics',
        }
      )
    ).toObject()

    return {
      cleanAssets: response.statistics?.servers?.clean || 0,
      infectedAssets: response.statistics?.servers?.infected || 0,
      totalAssets: response.statistics?.servers?.total || 0,
    }
  }

  async getFilesScannedStatistics(
    params: OvaStatisticsRequestParams
  ): Promise<OvaGetFilesScannedStatisticsResponse> {
    const request = new GetOvaFilesScannedStatisticsRequest()

    if (params.ovaAccountIds) {
      request.setOvaAccountIdsList(params.ovaAccountIds)
    }

    if (params.backupProviders) {
      request.setBackupProvidersList(
        params.backupProviders.map((provider) =>
          this.#convertBackupProviderEnumToProto(provider)
        )
      )
    }

    const response = (
      await this.callGrpcService(
        () =>
          this.#ovaStatisticsClient.getFilesScannedStatistics(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'OvaStatisticsPromiseClient/getFilesScannedStatistics',
        }
      )
    ).toObject()

    return {
      cleanFilesCount: response.statistics?.clean || 0,
      infectedFilesCount: response.statistics?.infected || 0,
    }
  }

  #convertBackupProviderEnumToProto(
    backupProvider: OvaBackupProvider
  ): OvaBackupProviderProto {
    const mapping: Record<OvaBackupProvider, OvaBackupProviderProto> = {
      [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
      [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
      [OvaBackupProvider.AWS_BACKUP_VMWARE]:
        OvaBackupProviderProto.AWS_BACKUP_VMWARE,
      [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProviderProto.VERITAS_NET_BACKUP,
    }

    return mapping[backupProvider]
  }
}
