import React, { useState } from 'react'
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import { ButtonPrimary } from '@components/shared'
import { ControlledSelect } from '@features/reports/schedules/shared'
import { Root } from './styles'

import {
  useDrawerActions,
  DrawerName,
  useDrawerState,
} from '../../use-drawer-store'
import { reportOptions } from '../../shared'
import { useReportSourceType, useTabsActions } from '../../use-data-store'

const CONTROL_NAME = 'reportType'

type FormData = {
  reportType: string
}

function GenerateReportForm() {
  const { openDrawer } = useDrawerActions()

  const { setReportSourceType } = useTabsActions()
  const reportSourceTyp = useReportSourceType() ?? ''

  useDrawerState(DrawerName.GenerateReport)
  const methods = useForm<FormData>({
    defaultValues: {
      reportType: reportSourceTyp,
    },
  })

  const [options] =
    useState<Array<{ value: string; label: string }>>(reportOptions)

  const selectedReportType = methods.watch(CONTROL_NAME)

  const selectedReportOption = options.find(
    (option) => option.value === selectedReportType
  )

  const onSubmit: SubmitHandler<any> = (data) => {
    console.log(data)
  }

  const isGenerateReportDisabled =
    !selectedReportType || selectedReportType === ''

  const handleOpenReportDrawer = () => {
    setReportSourceType(selectedReportOption?.value)
    openDrawer(DrawerName.GenerateReport, { ...selectedReportOption })
  }
  return (
    <FormProvider {...methods}>
      <Root onSubmit={methods.handleSubmit(onSubmit)}>
        <ControlledSelect<FormData>
          name={CONTROL_NAME}
          isLoading={false}
          options={options}
          placeholder="Select a Report"
        />
        <ButtonPrimary
          isDisabled={isGenerateReportDisabled}
          onClick={handleOpenReportDrawer}
        >
          Generate
        </ButtonPrimary>
      </Root>
    </FormProvider>
  )
}

export default GenerateReportForm
