/* eslint-disable import/no-extraneous-dependencies */
import { CellWithButtonLink } from '@components/table'
import {
  S3Bucket,
  EFS,
  GenericHost,
  S3Item,
  AssetItem,
  EfsItem,
  Stream,
  File,
  LocalVolume,
  Disk,
} from 'blues-corejs/dist'
import React from 'react'

type Asset = S3Bucket | EFS | GenericHost

interface Props {
  asset: Asset | AssetItem
  onAssetPathClick: () => void
}

function computeAssetItemPath(asset: Asset | AssetItem) {
  if (asset instanceof S3Bucket) {
    return asset.awsId
  }

  if (asset instanceof EFS) {
    return asset.name || asset.awsId
  }

  if (asset instanceof S3Item) {
    const selector = asset.selectors[0]

    if (!selector) {
      return ''
    }

    return selector.value
  }

  if (asset instanceof EfsItem) {
    const selector = asset.selectors[0]

    if (!selector) {
      return ''
    }

    return selector.value
  }

  if (asset instanceof LocalVolume || asset instanceof Disk) {
    return asset.deviceName
  }

  if (asset instanceof File) {
    return asset.path
  }

  if (asset instanceof Stream) {
    return asset.name
  }

  return ''
}

export function AssetItemPath({ asset, onAssetPathClick }: Props) {
  const assetItemPath = computeAssetItemPath(asset)

  return (
    <CellWithButtonLink onClick={onAssetPathClick}>
      {assetItemPath}
    </CellWithButtonLink>
  )
}
