/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Box, Typography } from '@mui/material'
import { CopyToClipboardIcon } from '@features/common'
import { StyledContainer } from './styles'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { OvaServer } from 'blues-corejs/dist/models'
import { OvaServerIcon } from '@inline-img/general/ova'

interface Props {
  server: OvaServer
}

function Header({ server }: Props) {
  return (
    <>
      <OvaServerIcon />
      <StyledContainer>
        <Box display="flex" alignItems="center" gap="5px">
          <Typography component="span" fontWeight="500" fontSize="16px">
            <LongTextTooltip text={server.name} maxLength={20} />
          </Typography>
          <CopyToClipboardIcon text={server.name} fontSize={16} />
        </Box>
      </StyledContainer>
    </>
  )
}

export default Header
