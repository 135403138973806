/* eslint-disable import/no-extraneous-dependencies */
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_efs_items_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { EfsItemTransformer } from '@lib/clients/assets/transformers'
import { ListEfsItemsRequestFilters } from '@lib/clients/assets-items/types'
import { Request as PaginationPB } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { Nullable } from '@lib/engine-types'
import { Pagination } from '../types'
import { EfsItem } from 'blues-corejs/dist'

export interface ListEfsItemsResponse {
  objects: Array<EfsItem>
}

export class ListEfsItemsClient extends GrpcClient<AssetsPromiseClient> {
  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return 'ListEfsItemsClient'
  }

  protected initClient(hostName = ''): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  #buildFilter(filters: ListEfsItemsRequestFilters) {
    const filter = new Request.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length > 0) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.objectNameList) {
      filter.setObjectnameList(filters.objectNameList)
    }

    if (filters.objectIdList) {
      filter.setObjectIdList(filters.objectIdList)
    }

    if (filters.backupPolicyNameList) {
      filter.setBackupPolicyNameList(filters.backupPolicyNameList)
    }

    return filter
  }

  #setupPagination(request: Request, pageToken: Nullable<string>) {
    return (
      pageToken &&
      request.setPagination(new PaginationPB().setPageToken(pageToken))
    )
  }

  async listEfsItems(
    filters?: ListEfsItemsRequestFilters & Pagination
  ): Promise<ListEfsItemsResponse & Pagination> {
    const filter = this.#buildFilter(filters as ListEfsItemsRequestFilters)

    const initial = new Request.Initial()
      .setPageSize(this.pageSize)
      .setFilter(filter)

    const requestInstance = new Request().setInitial(initial)

    this.#setupPagination(requestInstance, filters?.pageToken)

    const response = (
      await this.retryGrpcCall(
        () => this.#assetsClient.listEFSItems(requestInstance, this.metadata()),
        {
          requestName: 'AssetsPromiseClient/listEFSItems',
        }
      )
    ).toObject()

    return {
      objects: this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformResponse(response: Response.AsObject): Array<EfsItem> {
    return response.objectsList.map((item) =>
      new EfsItemTransformer(item).transform()
    )
  }
}
