/* eslint-disable import/no-extraneous-dependencies */
import {
  useAssetScansDataFetcher,
  useFetchScanForAssetItems,
} from '@components-complex/dashboard-pages-v3'
import { Table } from '@components/table'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { ScanDetailsModal } from '@features/asset-page/modals/scan-details-modal'
import { ScansRun } from 'blues-corejs/dist/models'
import { Nullable } from '@lib/engine-types'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import {
  AssetItem,
  Disk,
  EFS,
  EfsItem,
  File,
  GenericHost,
  LocalVolume,
  S3Bucket,
  S3Item,
  Stream,
} from 'blues-corejs/dist'
import React, { useEffect } from 'react'
import { computeAssetIcon } from './compute-asset-item-icon'
import { computeAssetItemName } from './compute-asset-item-name'
import { getAssetItemScansColumnDefinitions } from './get-asset-item-scans-column-definition'
import CloseIcon from '@mui/icons-material/Close'
import { RowTitle } from './row-title'
import RegionIcon from '@mui/icons-material/Place'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import LangHelper from '@lib/helpers/lang.helper'
import { DateRangePickerWithCallAPI } from '@components-composite/date-range-picker-with-call-api'
import { useProcessDataForTable } from './use-process-data-for-table'
import { TimeRange } from '@lib/clients/types'

type Asset = S3Bucket | EFS | GenericHost

type Item = AssetItem | S3Item | EfsItem

interface Props {
  onClose: () => void
  isOpen: boolean
  asset: Asset
  assetItem: Item | Asset
}

function AssetItemDetailHeader({
  item,
  onClose,
}: {
  item: AssetItem | Asset
  onClose: () => void
}) {
  const icon = computeAssetIcon(item)
  const name = computeAssetItemName(item)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="16px"
      marginLeft="10px"
    >
      <Box display="flex" alignItems="center" gap="10px">
        {icon}
        <Typography fontSize="22px" fontWeight="600">
          {name}
        </Typography>
      </Box>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

function computeAssetItemKind(assetItem: Item | Asset) {
  switch (assetItem.constructor) {
    case S3Bucket:
      return 'S3 Bucket'
    case S3Item:
      return 'S3 Item'
    case EfsItem:
      return 'EFS Item'
    case File:
      return 'File'
    case Disk:
      return 'Disk'
    case Stream:
      return 'Stream'
    case LocalVolume:
      return 'Local Volume'
    case EFS:
      return 'EFS'
    default:
      return ''
  }
}

export function computeAssetTitle(asset: Asset) {
  switch (asset.constructor) {
    case S3Bucket:
      return 'Bucket name'
    case EFS:
      return 'Name'
    case GenericHost:
      return 'Host name'
    default:
      return 'Name'
  }
}

export function computeAssetId(asset: Asset) {
  if (asset instanceof GenericHost) {
    return asset.hostname
  } else {
    return asset.name ? `${asset.name} (${asset.awsId})` : asset.awsId
  }
}

export function Region({ asset }: { asset: Asset }) {
  if (!(asset instanceof AWSAsset)) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" marginRight="60px">
      <RowTitle title="Region" />
      <RegionIcon color="disabled" fontSize="inherit" />
      <Typography
        fontSize="14px"
        fontWeight="400"
        variant="caption"
        marginLeft="5px"
      >
        {LangHelper.getAwsRegionSingleTranslation(asset.awsRegion)}
      </Typography>
    </Box>
  )
}

function AssetCredentials({
  asset,
  assetItem,
}: {
  asset: Asset
  assetItem: Item | Asset
}) {
  const title = computeAssetTitle(asset)
  const id = computeAssetId(asset)
  const kind = computeAssetItemKind(assetItem)

  return (
    <Box display="flex" gap="24px">
      <Box display="flex">
        <RowTitle title={title} />
        <Typography
          fontSize="14px"
          fontWeight="400"
          variant="caption"
          marginLeft="5px"
        >
          {id}
        </Typography>
      </Box>
      <Box>
        <RowTitle title="Kind" />
        <Typography
          fontSize="14px"
          fontWeight="400"
          variant="caption"
          marginLeft="5px"
        >
          {kind}
        </Typography>
      </Box>
      <Region asset={asset} />
    </Box>
  )
}

export function AssetItemScansModal({
  onClose,
  isOpen,
  asset,
  assetItem,
}: Props) {
  const scansAssetData = useAssetScansDataFetcher({
    assetIdList: [asset.id],
    onlyForAsset: true,
  })

  const scansAssetItemData = useFetchScanForAssetItems(
    assetItem
      ? {
          assetIdList: [asset.id],
          assetItemId: assetItem.id,
        }
      : {}
  )

  const [selectedAssetItemScan, setSelectedAssetItemScan] =
    React.useState<Nullable<ScansRun>>(null)

  function handleAssetItemScanClick(scan: ScansRun) {
    setSelectedAssetItemScan(scan)
  }

  const scansTableData =
    assetItem instanceof EFS || assetItem instanceof S3Bucket
      ? scansAssetData
      : scansAssetItemData

  const observable = useInfiniteScrollDataFetch({
    initialItems: scansTableData.data.allScans,
    fetchNextBatch: scansTableData.onFetchData,
  })

  const { data: tableData, isLoading: isTableDataLoading } =
    useProcessDataForTable(scansTableData.data.allScans)

  const handleTimeRangeChange = (newTimeRange: TimeRange) => {
    scansTableData.fetchInitial({ timeRange: newTimeRange })
  }

  useEffect(() => {
    scansAssetData.fetchInitial()
    scansAssetItemData.fetchInitial()
  }, [])

  return (
    <>
      {selectedAssetItemScan && (
        <ScanDetailsModal
          asset={asset}
          isOpen={selectedAssetItemScan !== null}
          onClose={() => setSelectedAssetItemScan(null)}
          scans={[
            ...selectedAssetItemScan.filesystemChecksList,
            ...selectedAssetItemScan.malwareScansList,
            ...selectedAssetItemScan.ransomwareScansList,
          ]}
        />
      )}
      <Dialog open={isOpen} onClose={onClose}>
        <DialogContent>
          <AssetItemDetailHeader item={assetItem} onClose={onClose} />
          <AssetCredentials asset={asset} assetItem={assetItem} />
          <Divider
            sx={{
              marginY: '16px',
            }}
          />
          <DateRangePickerWithCallAPI
            onDateRangeChange={handleTimeRangeChange}
          />
          <Table
            data={tableData}
            columns={getAssetItemScansColumnDefinitions({
              onCompletionTimeClick: handleAssetItemScanClick,
              assetItem,
            })}
            generalProps={{
              noDataMessage: 'No Scans found',
              isLoading: scansTableData.isLoadingData() || isTableDataLoading,
            }}
            advancedProps={{
              observableState: observable,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
