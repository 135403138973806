import React from 'react'
import { VIRow } from '@lib/engine-types'

interface Props {
  data: VIRow
}

function SimpleVIHead({ data }: Props) {
  return (
    <tr>
      {data.map((elem, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <th key={index}>{elem.name}</th>
      ))}
    </tr>
  )
}

export default SimpleVIHead
