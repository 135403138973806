import { Stack, Button, Typography, Box } from '@mui/material'

import AwsDrawer from './aws-drawer'
import InfoHeader from './info-header'

import { useRedStackPolicyQuery } from 'ui-v2/src/hooks/queries/red-stack/red-stack-policy'
import { useListVaultAllRedstacksQuery } from 'ui-v2/src/hooks/queries/vault/list-vault-all-redstacks'
import {
  useAllActiveRedStacksQuery,
  useListAwsAccountQuery,
} from 'ui-v2/src/hooks/queries/red-stack'

import SourcesTable from './source-table'

//import RedStackStatusConstant from 'ui-v2/src/lib/constants/red-stack-status.constant'
import { ScheduledTenantJobKind } from 'ui-v2/src/features/scheduled-jobs-monitoring/models'
import { useTenantIdsFromScheduledJobs } from 'ui-v2/src/hooks/schedule-jobs/use-tenant-ids-from-scheduled-jobs'
import { enabledSourcesVI } from 'ui-v2/src/lib/models/red-stack/table.factory'
//import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
//import { uniqueBy } from 'ui-v2/src/lib/helpers/arr.helper'
import { findProblematicCC } from 'ui-v2/src/lib/helpers/data.helper'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import InstallCliLink from './install-cli-link'

const Sources = () => {
  const navigate = useNavigate()
  const { data: redStackListData } = useRedStackPolicyQuery()
  const { data: allActiveRedStacksData } = useAllActiveRedStacksQuery()
  const { data: vaultListData } = useListVaultAllRedstacksQuery()
  const { data: awsAccountData } = useListAwsAccountQuery()

  // Get already known RS
  // TODO:  SettingsService.getSetting(UserSettings.RsUpgrade)
  // const alreadyKnownRsUpgrade: Array<string> =
  //   SettingsService.getSetting(UserSettings.RsUpgrade) ?? []

  // TODO:
  // const alreadyKnownRsUpgrade: Array<string> = []

  // TODO:
  // const RSwhichNeedUpgrade = allActiveRedStacksData
  //   ?.filter(
  //     (rs) =>
  //       rs.status === RedStackStatusConstant.UPGRADE_REQUIRED &&
  //       !rs.cfnUpgradeRequired
  //   )
  //   .map((rs) => `${rs.awsAccount} (${rs.awsRegion})`)
  //   .filter((awsAccount) => !alreadyKnownRsUpgrade?.includes(awsAccount))

  // Get already known accounts without RS
  // TODO: SettingsService.getSetting(UserSettings.CfnUpgrade)
  // const alreadyKnownCfnUpgrade: Array<string> =
  // SettingsService.getSetting(UserSettings.CfnUpgrade) ?? []

  const alreadyKnownCfnUpgrade: Array<string> = []

  // Make array with new upgrades
  const accountsWithoutRS = !allActiveRedStacksData?.length
    ? []
    : awsAccountData
        ?.filter(
          (acc: any) =>
            !allActiveRedStacksData.some(
              ({ awsAccount }) => awsAccount === acc.awsAccountId
            )
        )
        .map(({ awsAccountId }: any) => awsAccountId)
        .filter(
          (awsAccount: any) => !alreadyKnownCfnUpgrade?.includes(awsAccount)
        )
  const cloudConnectorsInAction = useTenantIdsFromScheduledJobs([
    ScheduledTenantJobKind.REPAIR_CLOUD_CONNECTOR,
    ScheduledTenantJobKind.REPAIR_VAULT,
  ])

  const enabledSourcesData = enabledSourcesVI(
    redStackListData ?? [],
    vaultListData ?? []
  )

  // TODO:
  // const possibleRegionsTranslation = uniqueBy(
  //   allActiveRedStacksData?.map((v) => ({
  //     name: v.awsRegion,
  //     label: LangHelper.getAwsRegionSingleTranslation(v.awsRegion),
  //     value: v.version,
  //   })) ?? [],
  //   'name'
  // )

  // TODO:
  // const possibleRegionsByAccount = (awsAccount: string) => {
  //   const availableRegions = allActiveRedStacksData
  //     ?.filter((rs) => rs.awsAccount === awsAccount)
  //     .map(({ awsRegion }) => awsRegion)

  //   return possibleRegionsTranslation.filter(({ name }) =>
  //     availableRegions?.includes(name)
  //   )
  // }

  const problematicCC = findProblematicCC(
    allActiveRedStacksData ?? [],
    vaultListData ?? []
  )

  // TODO:
  // Check if there is something new RS, if so - show alert again
  // const isNewRsUpgrades = !!RSwhichNeedUpgrade?.filter(
  //   (item) => !alreadyKnownRsUpgrade?.includes(item)
  // ).length

  // TODO:
  // Check if there is something new CFN upgrades, if so - show alert again
  // const isNewCfnUpgrades = !!accountsWithoutRS?.filter(
  //   (item: any) => !alreadyKnownCfnUpgrade?.includes(item)
  // ).length

  // TODO: Modal configuration

  const handleLinkSource = () => {
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}/${SETTINGS_ACTIONS_ROUTE_SEGMENTS.LINK_SOURCE}`
    )
  }

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={2}
      >
        <Typography fontWeight={500} variant="body2">
          Sources are AWS accounts that host the Elastio service and optionally
          contain AWS assets that you would like to protect
        </Typography>
        <Box display={'flex'} columnGap={2}>
          <InstallCliLink />
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'capitalize' }}
            onClick={handleLinkSource}
          >
            Link Source
          </Button>
        </Box>
      </Stack>

      {/* <InfoHeader
        title="The upgrade will be completed in a few minutes."
        description="Elastio handles Cloud Connector upgrades automatically. Upgrade operation takes several minutes to complete. Once the upgraded, the source will be displayed in the list as active."
        tags={RSwhichNeedUpgrade ?? []}
      /> */}
      <InfoHeader
        title="Cloud connector ready to be deployed"
        description="These accounts are ready to be deployed with Elastio. Protect your
            assets from security threats in your applications."
        tags={accountsWithoutRS}
      />
      <AwsDrawer />
      <SourcesTable
        enabledSourcesData={enabledSourcesData}
        redStackListData={redStackListData ?? []}
        allActiveRedStacksData={allActiveRedStacksData ?? []}
        vaultListData={vaultListData ?? []}
        problematicCC={problematicCC}
        cloudConnectorsInAction={cloudConnectorsInAction}
      />
    </>
  )
}

export default Sources
