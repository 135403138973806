import {
  UpdateReportScheduleRequest,
  ReportKindWithParamsSchedule,
  AwsExecutiveReportParamsSchedule,
  OvaMspOperationalReportParamsSchedule,
  AwsInfectedAssetsReportParamsSchedule,
  AwsScannedAssetsSummaryReportParamsSchedule,
  AwsAccountSummaryReportParamsSchedule,
  AwsCompletedScansReportParamsSchedule,
  AwsTenantSummaryReportParamsSchedule,
  FailedScansReportParamsSchedule,
  InfectedFilesReportParamsSchedule,
  OvaMspExecutiveReportParamsSchedule,
  NaiveDate,
  OvaMspTenantSummaryReportParamsSchedule,
  OvaMspAccountSummaryReportParamsSchedule,
  OvaMspCompletedScansReportParamsSchedule,
  OvaMspScannedAssetsSummaryReportParamsSchedule,
  OvaMspInfectedAssetsReportParamsSchedule,
  OvaMspInfectedFilesReportParamsSchedule,
  OvaMspFailedScansReportParamsSchedule,
} from '@lib/clients/grpc-imports'
import {
  AWSReports,
  BackupConnectorReports,
} from '@features/reports/schedules/shared'
import { mapFormatsToEnum } from '@lib/clients/reports/shared'
import { createScheduleInstance, createReportPeriodSchedule } from './shared'
import { CustomScheduleState } from '@features/reports/schedules/custom-schedule/types'

export interface UpdateReportScheduleCriteria {
  id: string
  timezone: string
  name: string
  description?: string
  recipientIds: Array<string>
  recipientsIds: Array<string>
  frequency: string
  reportType: string
  rangeOfData: string
  ovaAccountIds: Array<string>
  ovaBackupProviders: Array<number>
  formats: Array<string>
  startOn: {
    year: number
    month: number
    day: number
  }
  customSchedule: CustomScheduleState
}

export function buildUpdateReportScheduleRequest({
  timezone,
  recipientsIds = [],
  description = '',
  name,
  frequency,
  reportType,
  formats: formatsUI,
  startOn,
  ovaBackupProviders,
  ovaAccountIds,
  rangeOfData,
  id,
  customSchedule,
}: UpdateReportScheduleCriteria): UpdateReportScheduleRequest {
  const startDate = new NaiveDate()
    .setDay(startOn.day)
    .setMonth(startOn.month)
    .setYear(startOn.year)

  const scheduleInstance = createScheduleInstance(frequency, customSchedule)
  const formats = mapFormatsToEnum(reportType, formatsUI)

  scheduleInstance.setStartsOn(startDate)

  const reportPeriodSchedule = createReportPeriodSchedule(rangeOfData)

  const kind = new ReportKindWithParamsSchedule()
  switch (reportType) {
    case BackupConnectorReports.ovaMspOperationalReport:
      kind.setOvaMspOperational(
        new OvaMspOperationalReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspExecutiveReport:
      kind.setOvaMspExecutive(
        new OvaMspExecutiveReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspTenantSummaryReport:
      kind.setOvaMspTenantSummary(
        new OvaMspTenantSummaryReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspAccountSummaryReport:
      kind.setOvaMspAccountSummary(
        new OvaMspAccountSummaryReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspCompletedScansReport:
      kind.setOvaMspCompletedScans(
        new OvaMspCompletedScansReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspScannedAssetsSummaryReport:
      kind.setOvaMspScannedAssetsSummary(
        new OvaMspScannedAssetsSummaryReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspInfectedAssetsReport:
      kind.setOvaMspInfectedAssets(
        new OvaMspInfectedAssetsReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspInfectedFilesReport:
      kind.setOvaMspInfectedFiles(
        new OvaMspInfectedFilesReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case BackupConnectorReports.ovaMspFailedScansReport:
      kind.setOvaMspFailedScans(
        new OvaMspFailedScansReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
          .setOvaBackupProvidersList(ovaBackupProviders)
          .setOvaAccountIdsList(ovaAccountIds)
      )
      break
    case AWSReports.awsExecutiveReport:
      kind.setAwsExecutive(
        new AwsExecutiveReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )

      break
    case AWSReports.awsTenantSummaryReport:
      kind.setAwsTenantSummary(
        new AwsTenantSummaryReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )
      break
    case AWSReports.awsAccountSummaryReport:
      kind.setAwsAccountSummary(
        new AwsAccountSummaryReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )
      break
    case AWSReports.awsCompletedScansReport:
      kind.setAwsCompletedScans(
        new AwsCompletedScansReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )
      break
    case AWSReports.awsScannedAssetsSummaryReport:
      kind.setAwsScannedAssetsSummary(
        new AwsScannedAssetsSummaryReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )
      break
    case AWSReports.awsInfectedAssetsReport:
      kind.setAwsInfectedAssets(
        new AwsInfectedAssetsReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )

      break
    case AWSReports.infectedFilesReport:
      kind.setInfectedFiles(
        new InfectedFilesReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )
      break

    case AWSReports.failedScansReport:
      kind.setFailedScans(
        new FailedScansReportParamsSchedule()
          .setFormatsList(formats)
          .setPeriod(reportPeriodSchedule)
      )
      break
  }
  const request = new UpdateReportScheduleRequest()
    .setName(name)
    .setTimezone(timezone)
    .setRecipientIdsList(recipientsIds)
    .setDescription(description)
    .setSchedule(scheduleInstance)
    .setReportKindWithParams(kind)
    .setId(id)

  return request
}
