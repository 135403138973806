import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
// eslint-disable-next-line import/no-extraneous-dependencies
import { OvaAccount } from 'blues-corejs/dist'

interface OvaAccountsActions {
  setOvaAccountsList: (data: Array<OvaAccount>) => void
}

export interface OvaAccountsStore {
  ovaAccountsList: Array<OvaAccount>
}

const INITIAL_STATE: OvaAccountsStore = {
  ovaAccountsList: [],
}

const useOvaAccountsStore = create<
  OvaAccountsStore & { actions: OvaAccountsActions }
>((set) => ({
  ...INITIAL_STATE,
  actions: {
    setOvaAccountsList: (data: Array<OvaAccount>) =>
      set((state) => ({
        ovaAccountsList: [...state.ovaAccountsList, ...data],
      })),
  },
}))

export const useOvaAccounts = () =>
  useOvaAccountsStore(useShallow((state) => state.ovaAccountsList))

export const useOvaAccountsActions = () =>
  useOvaAccountsStore(useShallow((state) => state.actions))
