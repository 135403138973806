import { CSVLink } from 'react-csv'
import type { CommonPropTypes } from 'react-csv/components/CommonPropTypes'
import Button from '../ui/inputs/button'

interface Props extends CommonPropTypes {
  buttonText?: string
  shouldShow?: boolean
  disabled?: boolean
}

const DEFAULT_SEPARATOR = ','
const DEFAULT_BUTTON_TEXT = 'Export as CSV'

export function ExportAsCsv(props: Props) {
  const {
    buttonText,
    shouldShow = true,
    disabled = false,
    ...csvLinkProps
  } = props

  if (!shouldShow) {
    return null
  }

  return (
    <CSVLink {...csvLinkProps} separator={DEFAULT_SEPARATOR}>
      <Button variant="contained" color="primary" disabled={disabled}>
        {buttonText || DEFAULT_BUTTON_TEXT}
      </Button>
    </CSVLink>
  )
}
