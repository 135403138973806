import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StyledSelectedFilterAction } from './styles'

interface Props {
  handleSelectAll: () => void
  handleClearAll: () => void
}

function EntropyTrendsSelectionActionButtons({
  handleSelectAll,
  handleClearAll,
}: Props) {
  return (
    <Stack direction="row" gap="8px" marginY="8px" marginX="12px">
      <StyledSelectedFilterAction onClick={handleSelectAll}>
        Select All
      </StyledSelectedFilterAction>
      <Typography fontSize="14px">|</Typography>
      <StyledSelectedFilterAction onClick={handleClearAll}>
        Clear
      </StyledSelectedFilterAction>
    </Stack>
  )
}

export { EntropyTrendsSelectionActionButtons }
