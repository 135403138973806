/* eslint-disable import/no-extraneous-dependencies */
import { useGetEC2List } from '@lib/hooks/api-hooks'
import { EC2, Asset, ElastioRecoveryPoint, Backup } from 'blues-corejs/dist'

export function useGetInstanceForSlider({
  asset,
  backup,
}: {
  asset: Asset
  backup: Backup
}) {
  const isElastioRecoveryPoint = backup instanceof ElastioRecoveryPoint
  const isAssetEC2 = asset instanceof EC2

  const getFiltersForEC2List = () => {
    if (isAssetEC2 || !isElastioRecoveryPoint) {
      return
    }

    return {
      assetIdList: [backup.sourceAssetId],
    }
  }

  const { ec2List } = useGetEC2List(getFiltersForEC2List())

  if (isAssetEC2) {
    return asset
  }

  if (isElastioRecoveryPoint) {
    return ec2List.find((ec2) => ec2.id === backup.sourceAssetId)
  }
}
