/* eslint-disable import/no-extraneous-dependencies */
import { createDisplayColumn } from '@components/table'
import React from 'react'
import LangHelper from '@lib/helpers/lang.helper'
import { AssetWithRelatedAssets } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  AssetLastBackupCell,
  AssetSelectCell,
  AssetTagsCell,
} from '@features/policies/policy/policy-tables/specific-assets-for-policy/cells'
import {
  PoliciesCell,
  PolicyLinkCell,
} from '@features/policies/policy/policy-tables/cells'
import { Asset } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { BackupType } from '@lib/clients/assets/types'
import { StyledCheckbox } from '@features/policies/policy/policy-tables/styles'

type InputData = {
  handleRowChange: (assetId: string, isSelected: boolean) => void
  onSelectAllRows: (isSelected: boolean) => void
  selectedAssetsIds: Set<string>
  isDisableHeadersCheckbox: boolean
  assetType: string
  lastBackups: Map<string, { backup: BackupType }>
}

export function getSpecificAssetsTableColumnDefinitions({
  handleRowChange,
  onSelectAllRows,
  selectedAssetsIds,
  isDisableHeadersCheckbox,
  assetType,
  lastBackups,
}: InputData) {
  return [
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      header: ({ table }) => {
        const isSelected = table.getIsAllRowsSelected()

        const onClick = () => {
          onSelectAllRows(isSelected)
        }

        return (
          <StyledCheckbox
            indeterminate={table.getIsSomeRowsSelected()}
            checked={isSelected}
            onChange={table.getToggleAllRowsSelectedHandler()}
            onClick={onClick}
            disabled={isDisableHeadersCheckbox}
          />
        )
      },
      meta: {
        width: '32',
      },
      size: 32,
      id: 'row-selection',
      cell: ({ row }) => (
        <AssetSelectCell
          row={row}
          asset={row.original.asset}
          selectedAssetsIds={selectedAssetsIds}
          handleRowChange={handleRowChange}
        />
      ),
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Resource ID',
      id: 'link',
      size: 200,
      cell: ({ row }) => <PolicyLinkCell asset={row.original.asset} />,
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Asset Tag(s)',
      id: 'tags',
      size: 300,
      cell: ({ row }) => <AssetTagsCell asset={row.original.asset} />,
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Asset Type',
      id: 'assetType',
      cell: assetType,
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Account ID',
      id: 'accountId',
      cell: ({ row }) => row.original.asset.awsAccountId,
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Region',
      id: 'region',
      cell: ({ row }) =>
        LangHelper.getAwsRegionSingleTranslation(row.original.asset.awsRegion),
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Last backup',
      id: 'lastBackup',
      cell: ({ row }) => {
        return (
          <AssetLastBackupCell
            lastBackups={lastBackups}
            asset={row.original.asset}
          />
        )
      },
    }),
    createDisplayColumn<AssetWithRelatedAssets<Asset>>({
      enableSorting: false,
      header: 'Policy',
      cell: ({ row }) => <PoliciesCell asset={row.original.asset} />,
    }),
  ]
}
