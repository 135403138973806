/* eslint-disable import/no-extraneous-dependencies */
import { VolumesWithThreatsTable } from '@features/asset-page/tables'
import { AssetSlider } from '@features/asset-page/shared'
import {
  EC2,
  Backup,
  Threat,
  EBS,
  ElastioRecoveryPoint,
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import React, { useMemo, useState } from 'react'
import { computeScanTarget } from './compute-scan-targets'
import { EngineCallback } from '@lib/engine-types'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

function getThreatsForSelectedVolume(threatsList: Array<Threat>, volume?: EBS) {
  if (!volume) {
    return []
  }

  return threatsList.filter(
    (threat) =>
      threat.source.asset.assetId === volume.id ||
      threat.source.assetItem?.assetId === volume.id
  )
}

function getScansForSelectedVolume(scansList: Array<Scan>, volume?: EBS) {
  if (!volume) {
    return []
  }

  return scansList.filter(
    (scan) => computeScanTarget(scan).targetId === volume.id
  )
}

interface Props {
  asset: EC2
  backup?: Backup
  dataThreats: Array<Threat>
  scansList: Array<Scan>
  isTableTabSelected: boolean
  isSliderTabSelected: boolean
  fetchDataThreats: EngineCallback<void>
}

export function MoreDetailsEc2TabsContent({
  asset,
  dataThreats,
  isSliderTabSelected,
  isTableTabSelected,
  scansList,
  backup,
  fetchDataThreats,
}: Props) {
  const [selectedVolume, setSelectedVolume] = useState<EBS | undefined>()

  const threatsForSelectedVolume = useMemo(
    () => getThreatsForSelectedVolume(dataThreats, selectedVolume),
    [selectedVolume, dataThreats]
  )

  const scansForSelectedVolume = useMemo(
    () => getScansForSelectedVolume(scansList, selectedVolume),
    [selectedVolume, scansList]
  )

  if (isTableTabSelected) {
    return (
      <VolumesWithThreatsTable
        instance={asset}
        threats={dataThreats}
        scans={scansList}
        fetchDataThreats={fetchDataThreats}
      />
    )
  }

  function handleSlideChange(volume: EBS) {
    setSelectedVolume(volume)
  }

  const backupForSlider =
    backup instanceof ElastioRecoveryPoint ? backup : undefined

  if (isSliderTabSelected) {
    return (
      <>
        <AssetSlider
          instance={asset}
          backup={backupForSlider}
          threats={dataThreats}
          onSlideChange={handleSlideChange}
        />
        <VolumesWithThreatsTable
          instance={asset}
          threats={threatsForSelectedVolume}
          scans={scansForSelectedVolume}
          preselectedVolume={selectedVolume}
          fetchDataThreats={fetchDataThreats}
        />
      </>
    )
  }

  return null
}
