/* eslint-disable import/no-extraneous-dependencies */
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { Nullable } from '@lib/engine-types'
import { Asset } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import React from 'react'
import { RowTitle } from '../row-title'

import { HiddenChipCount } from '../state/hidden-chip-count'
import { StyledAssetDetailsChip } from '../state/styles'
import { CollapsebleFields } from '../use-collapsed-fields'

const TAG_CHIP_MAX_LENGTH = 25

interface Props {
  asset: Asset
  isTagsFieldCollapsed: boolean
  onTagsChipClick: (collapsedField: Nullable<CollapsebleFields.TAGS>) => void
}

export function Tags({ asset, isTagsFieldCollapsed, onTagsChipClick }: Props) {
  if (!(asset instanceof AWSAsset)) {
    return null
  }

  if (asset.tags.length === 0) {
    return null
  }

  const assetTags = [...asset.tags]

  const collapsedTagsList = isTagsFieldCollapsed
    ? assetTags
    : assetTags.slice(0, 1)

  function handleHiddenChipClick() {
    onTagsChipClick(CollapsebleFields.TAGS)
  }

  function handleCollapseClick() {
    onTagsChipClick(null)
  }

  const hiddenTagsCount = assetTags.length - collapsedTagsList.length

  return (
    <React.Fragment>
      <RowTitle title="Tags" />
      {collapsedTagsList.map(({ key, value }) => {
        const label = `${key}=${value}`

        return (
          <StyledAssetDetailsChip
            key={label}
            label={
              <LongTextTooltip text={label} maxLength={TAG_CHIP_MAX_LENGTH} />
            }
            onClick={handleCollapseClick}
          />
        )
      })}
      <HiddenChipCount
        hiddenCount={hiddenTagsCount}
        onClick={handleHiddenChipClick}
      />
    </React.Fragment>
  )
}
