import { useQuery } from '@tanstack/react-query'
import { ListAnomaliesFilter } from 'ui-v2/src/lib/models/anomalies'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { EntropyDetectionClient } from 'ui-v2/src/lib/clients'

interface Options {
  enabled?: boolean
}

export function useListAnomaliesQuery(
  filters: ListAnomaliesFilter,
  options?: Options
) {
  const entropyDetectionClient = useGrpcClient(EntropyDetectionClient)

  return useQuery({
    queryKey: ['entropy-detection', 'anomalies', 'list', filters],
    queryFn: async () => {
      if (!entropyDetectionClient) {
        throw new Error('Entropy detection client not initialized')
      }
      return entropyDetectionClient.listAnomalies(filters)
    },
    enabled:
      !!entropyDetectionClient &&
      (options?.enabled === undefined ? true : options.enabled),
    refetchOnWindowFocus: false,
  })
}
