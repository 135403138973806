/* eslint-disable import/no-cycle */
import {
  useAwsFileScansStatsData,
  useAwsThreatExposureStatsData,
  useAwsThreatStatsData,
} from 'ui-v2/src/hooks/dashboard-kpi'
import StatCard from './stats-card'
import { SetStateAction, useEffect, useMemo, Dispatch } from 'react'
import { Stack } from '@mui/material'
import { CARD_TYPES, CardLoadingState } from '..'
import useAwsStorageMisconfigurationStatsData from 'ui-v2/src/hooks/dashboard-kpi/aws-storage-misconfigurations-stats'

type StatsCardsProps = {
  cardLoadingState: CardLoadingState
  setCardLoadingState: Dispatch<SetStateAction<CardLoadingState>>
}

const StatsCards: React.FC<StatsCardsProps> = ({
  cardLoadingState,
  setCardLoadingState,
}) => {
  const { data: threatStats, isLoading: isThreatStatsLoading } =
    useAwsThreatStatsData()
  const { data: threatExposureStats, isLoading: isThreatsExposureLoading } =
    useAwsThreatExposureStatsData()
  const { data: fileScansStats, isLoading: isFileScansStatsLoading } =
    useAwsFileScansStatsData()
  const {
    data: storageMisconfigurationsStats,
    isLoading: isStorageMisconfigurationsLoading,
  } = useAwsStorageMisconfigurationStatsData()

  const stats = useMemo(() => {
    return [
      ...threatStats,
      ...threatExposureStats,
      ...fileScansStats,
      ...storageMisconfigurationsStats,
    ].sort((a, b) => b.count - a.count)
  }, [
    threatStats,
    threatExposureStats,
    fileScansStats,
    storageMisconfigurationsStats,
  ])

  const isLoading =
    isThreatStatsLoading ||
    isThreatsExposureLoading ||
    isFileScansStatsLoading ||
    isStorageMisconfigurationsLoading

  useEffect(() => {
    setCardLoadingState((prevState) => ({
      ...prevState,
      [CARD_TYPES.STATS_CARDS]: isLoading,
    }))
  }, [isLoading])

  const cardsLoading = Object.values(cardLoadingState).some((value) => value)

  if (cardsLoading) {
    return null
  }

  return (
    <Stack gap={2}>
      {stats.map((stat) => (
        <StatCard
          key={stat.slug}
          slug={stat.slug}
          count={stat.count}
          link={stat.link}
        />
      ))}
    </Stack>
  )
}

export default StatsCards
