import React from 'react'

export default function NotFound() {
  return (
    <div className="innerContent ilMain">
      <div className="llContent">
        <div className="logoBlockWrap">
          <div className="logoBlock">
            <div className="">
              <img
                width={140}
                height={50}
                alt="Elastio"
                src="/images/elastio-logo.svg"
              />
            </div>
          </div>
        </div>

        <p className="textHeaderBlock">
          <b>Something went wrong</b>
        </p>
        <div className="textBlock">
          <p className="textAlignLeft">
            Please reload the page or try again.
            <br />
            If the problem persists, please get in touch with us at
            <br />
            <a className="blueLink underline" href="mailto:support@elastio.com">
              support@elastio.com
            </a>
          </p>
        </div>
      </div>
      <div className="image">
        <img
          width={575}
          height={441}
          alt="Elastio"
          src="/images/error-404.svg"
        />
      </div>
    </div>
  )
}
