/* eslint-disable import/no-extraneous-dependencies */
import { EBS, Threat } from 'blues-corejs/dist'
import { ThreatKind } from 'blues-corejs/dist/models/threats/types'
import { ReportBlock, ReportStatus } from './types'

interface ReportSliderItemGeneratorAttrs {
  threats: Array<Threat>
  volume: EBS
}

export class ReportSliderItemGenerator {
  #threats: Array<Threat>

  #volume: EBS

  constructor({ threats, volume }: ReportSliderItemGeneratorAttrs) {
    this.#threats = threats
    this.#volume = volume
  }

  #getThreatsForAsset(
    volumeId: string,
    kinds: Array<ThreatKind>
  ): Array<Threat> {
    return this.#threats.filter(
      (threat) =>
        (threat.source.asset?.assetId === volumeId ||
          threat.source.assetItem?.assetId === volumeId) &&
        kinds.includes(threat.kind)
    )
  }

  #determineReportStatus(threats: Array<Threat>): ReportStatus {
    return threats.length > 0 ? ReportStatus.INFECTED : ReportStatus.CLEAN
  }

  get reportBlocks(): Array<ReportBlock> {
    const iScanThreats = this.#getThreatsForAsset(this.#volume.id, [
      ThreatKind.MALWARE,
      ThreatKind.RANSOMWARE,
    ])
    const filesystemThreats = this.#getThreatsForAsset(this.#volume.id, [
      ThreatKind.FILESYSTEM_ERROR,
    ])

    return [
      {
        reportType: 'Integrity scan',
        reportStatus: this.#determineReportStatus(iScanThreats),
      },
      {
        reportType: 'File system scan',
        reportStatus: this.#determineReportStatus(filesystemThreats),
      },
    ]
  }
}
