/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Stack from '@mui/material/Stack'
import { Asset, EC2 } from 'blues-corejs/dist'
import { VolumeSelection } from './volume-selection'
import { EntropyDetectionClient } from '@lib/clients/entropy-detection'
import { SystemHelper } from '@lib/helpers'
import { StyledDownloadReportButton } from './styles'
import { Nullable } from '@lib/engine-types'
import { Anomaly } from '@lib/models/anomalies'
import { DateRangeSelection } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-filters/date-range-selection'
import { EntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'
import { useGetEntropyTrendsDetailsStoreFilters } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store.context'

interface Props {
  asset: Asset
  selectedAnomaly: Nullable<Anomaly>
}

const client = new EntropyDetectionClient()

function DownloadReportButton({
  selectedAnomaly,
}: {
  selectedAnomaly: Nullable<Anomaly>
}) {
  if (!selectedAnomaly) {
    return null
  }

  const handleDownloadClick = async () => {
    try {
      const reportUrl = await client.downloadEntropyDetectionReport(
        selectedAnomaly.id
      )

      SystemHelper.openInNewTab(reportUrl)
    } catch (error) {
      console.error('Error downloading report', error)
    }
  }

  return (
    <StyledDownloadReportButton
      variant="contained"
      color="primary"
      onClick={handleDownloadClick}
    >
      Download report
    </StyledDownloadReportButton>
  )
}

export function EntropyTrendsDetailsFilters({ asset, selectedAnomaly }: Props) {
  const isEC2 = asset instanceof EC2
  const { timeSelection } = useGetEntropyTrendsDetailsStoreFilters()
  const shouldDisableDateRangeSelector =
    timeSelection !== EntropyTrendsTimeSelection.CUSTOM_DATE

  return (
    <Stack direction="row" alignItems="center" alignContent="center">
      <Stack
        direction="row"
        alignItems="center"
        alignContent="center"
        gap="20px"
        width="100%"
      >
        <DateRangeSelection
          disabled={shouldDisableDateRangeSelector}
          showQuestionHint={false}
        />
        {isEC2 && <VolumeSelection asset={asset} />}
      </Stack>
      <DownloadReportButton selectedAnomaly={selectedAnomaly} />
    </Stack>
  )
}
