import { useShallow } from 'zustand/react/shallow'
import { useCloudConnectorJobsStore } from '@features/jobs/cloud-connector-jobs/stores/cloud-connector-jobs-store/cloud-connector-jobs-store'

const useCloudConnectorJobsList = () =>
  useCloudConnectorJobsStore(
    useShallow((state) => state.cloudConnectorJobsList)
  )

const useCloudConnectorJobsIsLoading = () =>
  useCloudConnectorJobsStore((state) => state.loadingState === 'loading')

const useCloudConnectorJobsActions = () =>
  useCloudConnectorJobsStore(useShallow((state) => state.actions))

export {
  useCloudConnectorJobsList,
  useCloudConnectorJobsIsLoading,
  useCloudConnectorJobsActions,
}
