import React from 'react'
import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'

function MaxWidthFormControl({
  children,
  maxWidth = '100%',
}: PropsWithChildren<{
  maxWidth?: string
}>) {
  return (
    <Box
      sx={{
        maxWidth,
      }}
    >
      {children}
    </Box>
  )
}

export default MaxWidthFormControl
