/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Stack } from '@mui/material'
import {
  useAssetsEc2Query,
  useAssetsLiveQuery,
} from 'ui-v2/src/hooks/queries/assets'
import { AssetsEc2TableData } from 'ui-v2/src/lib/use-cases/assets'
import { getEc2Columns } from './column-config'
import { useSearchParams } from 'react-router-dom'
import { useAssetsEc2Filters } from 'ui-v2/src/hooks/filters/assets'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useLastCleanBackupsQuery } from 'ui-v2/src/hooks/queries/backups'
import { BackupForAsset } from 'ui-v2/src/lib/models/backups'
import { EC2State } from 'blues-corejs/dist'

const AssetsEc2 = () => {
  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsEc2Filters()

  const { data: assetsEc2Data, isLoading: isAssetsEc2DataLoading } =
    useAssetsEc2Query(filters)

  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const assetIds = useMemo(() => {
    if (!assetsEc2Data?.instancesList) {
      return []
    }

    return assetsEc2Data.instancesList
      .filter(
        (instance) =>
          instance.state === EC2State.RUNNING ||
          instance.state === EC2State.STOPPED
      )
      .map((instance) => instance.id)
  }, [assetsEc2Data])

  const backupQueries = useLastCleanBackupsQuery(assetIds)
  const isBackupsLoading = backupQueries.some((query) => query.isLoading)

  const data = useMemo(() => {
    if (!assetsEc2Data || !assetsLiveData) {
      return undefined
    }

    const lastCleanBackups: Record<string, BackupForAsset> = Object.fromEntries(
      backupQueries.map((query, index) => [assetIds[index], query.data])
    )

    return new AssetsEc2TableData({
      instances: assetsEc2Data.instancesList,
      volumes: assetsLiveData.ebsVolumes,
      threats: assetsLiveData.threats,
      lastCleanBackups,
    }).getEc2TableData()
  }, [assetsEc2Data, assetsLiveData, backupQueries, assetIds])

  const columns = useMemo(getEc2Columns, [])

  return (
    <Stack spacing={1}>
      <AssetFilters
        filters={[
          ASSET_FILTERS_MAP.ACCOUNT_ID,
          ASSET_FILTERS_MAP.REGION,
          ASSET_FILTERS_MAP.INSTANCE_ID,
          ASSET_FILTERS_MAP.INSTANCE_STATE,
          ASSET_FILTERS_MAP.POLICY_COVERAGE,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={
          isAssetsEc2DataLoading || isAssetsLiveDataLoading || isBackupsLoading
        }
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsEc2Data?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsEc2
