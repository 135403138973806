/* eslint-disable import/no-extraneous-dependencies */
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'
import type { Asset, Backup } from 'blues-corejs/dist'
import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import DetectedTreatsIcons from '@features/asset-page/shared/detected-threats-icons/detected-treats-icons'
import { ScanSource } from '@features/asset-page/shared'
import RansomwareScanDetails from '@features/asset-page/shared/ransomware-scan-details'
import MalwareScanDetails from '@features/asset-page/shared/malware-scan-details'
import FsCheckScanDetails from '@features/asset-page/shared/fs-check-scan-details'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import { MalwareScanBackup } from 'blues-corejs/dist/models/scans/malware'
import { RansomwareScanBackup } from 'blues-corejs/dist/models/scans/ransomware'
import { FilesystemScanBackup } from 'blues-corejs/dist/models'
import { GetBackupsByIdsClient } from '@lib/clients/backups/list-backups-by-ids'

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

type ScanTarget = {
  kind: ScanTargetKind
  target_id: string
}

enum ScanTargetKind {
  Asset = 'asset',
  Backup = 'backup',
}

type Props = {
  asset: Asset
  scans: Array<Scan>
}

function computeMalwareScanTarget(scan: MalwareScan): ScanTarget {
  if (scan.scanTarget.target instanceof MalwareScanBackup) {
    return {
      kind: ScanTargetKind.Backup,
      target_id: scan.scanTarget.target.backupId,
    }
  }

  return {
    kind: ScanTargetKind.Asset,
    target_id: scan.scanTarget.target.assetId,
  }
}

function computeRasterwareScanTarget(scan: RansomwareScan): ScanTarget {
  if (scan.scanTarget.target instanceof RansomwareScanBackup) {
    return {
      kind: ScanTargetKind.Backup,
      target_id: scan.scanTarget.target.backupId,
    }
  }

  return {
    kind: ScanTargetKind.Asset,
    target_id: scan.scanTarget.target.assetId,
  }
}

function computeFsCheckScanTarget(scan: FilesystemScanCheck): ScanTarget {
  if (scan.scanTarget.target instanceof FilesystemScanBackup) {
    return {
      kind: ScanTargetKind.Backup,
      target_id: scan.scanTarget.target.backupId,
    }
  }
  return {
    kind: ScanTargetKind.Asset,
    target_id: scan.scanTarget.target.assetId,
  }
}

function computeScanTarget(scan: Scan): ScanTarget {
  if (scan instanceof MalwareScan) {
    return computeMalwareScanTarget(scan)
  } else if (scan instanceof RansomwareScan) {
    return computeRasterwareScanTarget(scan)
  } else if (scan instanceof FilesystemScanCheck) {
    return computeFsCheckScanTarget(scan)
  } else {
    /// Not reachable code
    sentryReThrowCatchHandler(
      `[MostRecentScan] Scan type not supported: ${scan}`
    )
    throw new Error(
      `[MostRecentScan] Scan type not supported: ${JSON.stringify(scan)}`
    )
  }
}

const backupsClient = new GetBackupsByIdsClient()

function useFetchBackup(scanTarget: ScanTarget) {
  const [backup, setBackup] = React.useState<Backup | null>(null)
  const [isBackupLoading, setIsBackupLoading] = React.useState(false)

  React.useEffect(() => {
    async function fetchBackup() {
      try {
        setIsBackupLoading(true)
        const response = await backupsClient.getBackupsByIds([
          scanTarget.target_id,
        ])

        const backups = response.backupsList
          .flatMap(({ awsbRp, ebsSnapshot, elastioRp, ovaBackup }) => [
            awsbRp,
            ebsSnapshot,
            elastioRp,
            ovaBackup,
          ])
          .filter(Boolean)

        const backupRelatedToScanTarget = backups.find(
          (b) => b?.id === scanTarget.target_id
        )

        if (!backupRelatedToScanTarget) {
          sentryReThrowCatchHandler(
            `[MostRecentScan] Backup not found for scan target: ${scanTarget}`
          )
          throw new Error(
            `[MostRecentScan] Backup not found for scan target: ${scanTarget}`
          )
        }

        setBackup(backupRelatedToScanTarget)
      } catch (e) {
        console.error(e)
      } finally {
        setIsBackupLoading(false)
      }
    }

    if (scanTarget.kind !== ScanTargetKind.Backup) {
      return
    }

    fetchBackup()
  }, [scanTarget.target_id, scanTarget.kind])

  return {
    backup,
    isBackupLoading,
  }
}

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  background: theme.palette.grey[50],
  borderRadius: '12px',
}))

function ScanSourceRenderer({
  asset,
  scans,
}: {
  asset: Asset
  scans: Array<Scan>
}) {
  const firstScan = scans[0]

  if (!firstScan) {
    sentryReThrowCatchHandler(
      'ScanSourceRenderer: scans[0] is not defined for asset'
    )
    throw new Error('ScanSourceRenderer: scans[0] is not defined')
  }

  const computedScanTarget = computeScanTarget(firstScan)

  const { backup } = useFetchBackup(computedScanTarget)

  if (computedScanTarget.kind === ScanTargetKind.Asset) {
    return <ScanSource asset={asset} scan={scans[0]} />
  } else if (backup) {
    return <ScanSource backup={backup} />
  }

  return null
}

function renderScanSummary(asset: Asset, scans: Array<Scan>) {
  return (
    <Grid
      container
      marginTop="16px"
      columnGap="24px"
      fontSize="14px"
      paddingX="16px"
      flexWrap="nowrap"
      alignItems="center"
    >
      <Grid item xs="auto">
        <DetectedTreatsIcons scans={scans} />
      </Grid>
      <Divider flexItem orientation="vertical" />
      <Grid item xs="auto">
        <ScanSourceRenderer asset={asset} scans={scans} />
      </Grid>
    </Grid>
  )
}

function renderRansomwareScanDetails(scans: Array<RansomwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <RansomwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderMalwareScanDetails(scans: Array<MalwareScan>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <MalwareScanDetails scans={scans} />
    </Grid>
  )
}

function renderFsCheckScanDetails(scans: Array<FilesystemScanCheck>) {
  if (scans.length === 0) {
    return null
  }

  return (
    <Grid item xs={4}>
      <FsCheckScanDetails scans={scans} />
    </Grid>
  )
}

function renderScansDetails(scans: Array<Scan>) {
  const ransomwareScan = scans.filter(
    (scan) => scan instanceof RansomwareScan
  ) as Array<RansomwareScan>
  const malwareScan = scans.filter(
    (scan) => scan instanceof MalwareScan
  ) as Array<MalwareScan>
  const fsCheckScan = scans.filter(
    (scan) => scan instanceof FilesystemScanCheck
  ) as Array<FilesystemScanCheck>

  return (
    <Grid
      container
      marginTop="16px"
      columnGap="15px"
      fontSize="14px"
      paddingX="16px"
      flexWrap="nowrap"
    >
      {renderRansomwareScanDetails(ransomwareScan)}
      {renderMalwareScanDetails(malwareScan)}
      {renderFsCheckScanDetails(fsCheckScan)}
    </Grid>
  )
}

function MostRecentScan({ asset, scans }: Props) {
  if (scans.length === 0) {
    sentryReThrowCatchHandler(
      'Scans array is empty for MostRecentScan component'
    )
    return null
  }

  return (
    <Box marginBottom="10px" marginTop="24px">
      <StyledHeader>
        <Typography fontWeight="600">Most recent scan</Typography>
      </StyledHeader>
      {renderScanSummary(asset, scans)}
      <Divider sx={{ marginY: '24px' }} />
      {renderScansDetails(scans)}
    </Box>
  )
}

export { MostRecentScan }
