import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ActionInterface from '@lib/interfaces/action.interface'
import PolicyModel from '@lib/models/policy.model'
import GrpcPechkinService from '@lib/services/grpc/grpc-pechkin.service'
import {
  DELETE_POLICY,
  EXECUTE_POLICY,
  PAUSE_POLICY,
  requestPolicies,
  REQUEST_POLICIES,
  RESUME_POLICY,
  setPolicies,
} from '@store/actions/policies.action'
import {
  baseRequestScenario,
  sentryReThrowCatchHandler,
  toastRequestScenario,
} from '@store/epics/epic-func'

export const requestPoliciesEpic = (action$: any) =>
  action$.pipe(
    baseRequestScenario(
      [REQUEST_POLICIES],
      PreloaderConstants.REQUEST_POLICIES,
      () =>
        GrpcPechkinService.listPlans().catch(
          sentryReThrowCatchHandler('Cannot get a list of policies')
        ),
      (policies: Array<PolicyModel>) => {
        return setPolicies(policies)
      },
      ErrorGroupConstants.POLICIES
    )
  )

export const deletePolicyEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [DELETE_POLICY],
      PreloaderConstants.DELETE_POLICY,
      (action: ActionInterface) =>
        GrpcPechkinService.deletePlan(action.payload.policyId)
          .then(() => {
            action.payload?.callback()
          })
          .catch(sentryReThrowCatchHandler('Cannot delete the policy')),
      () => {
        return requestPolicies()
      },
      () => 'The policy has been removed',
      () => 'Error during policy removing'
    )
  )

export const pausePolicyEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [PAUSE_POLICY],
      PreloaderConstants.PAUSE_POLICIES,
      (action: ActionInterface) =>
        GrpcPechkinService.pausePlan(action.payload).catch(
          sentryReThrowCatchHandler('Cannot pause the policy')
        ),
      () => requestPolicies(),
      () => 'The policy has been paused',
      () => 'Error during policy pausing'
    )
  )

export const resumePolicyEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [RESUME_POLICY],
      PreloaderConstants.RESUME_POLICIES,
      (action: ActionInterface) =>
        GrpcPechkinService.resumePlan(action.payload).catch(
          sentryReThrowCatchHandler('Cannot resume the policy')
        ),
      () => requestPolicies(),
      () => 'The policy has been resumed',
      () => 'Error during policy resuming'
    )
  )

export const executePolicyEpic = (action$: any) =>
  action$.pipe(
    toastRequestScenario(
      [EXECUTE_POLICY],
      PreloaderConstants.EXECUTE_POLICY,
      (action: ActionInterface) =>
        GrpcPechkinService.executePlan(action.payload).catch(
          sentryReThrowCatchHandler('Cannot execute the policy')
        ),
      () => requestPolicies(),
      () => 'The policy has been executed',
      () => 'Error during policy executing'
    )
  )
