import PagePathConstant from '@lib/constants/page-path.constant'
import React from 'react'
import SystemHelper from '@lib/helpers/system.helper'

interface Props {
  email: string
}

const SignedAsHeader = ({ email }: Props) => {
  const clearCustomerStoredData = () => {
    localStorage.removeItem('customer_info')
    SystemHelper.pureNavigate(PagePathConstant.AUTH0_GLOBAL_LOGOUT)
  }

  return (
    <>
      <div className="logo-block">
        <img width={40} height={40} src="/images/logo-red.svg" alt="Logo-red" />
        <h1>elastio</h1>
      </div>
      <div className="signed-as">
        Signed in as <b>{email}</b>
        <span onClick={clearCustomerStoredData}>Change</span>
      </div>
    </>
  )
}

export default SignedAsHeader
