/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Asset, OvaServer } from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import { CollapsebleFields, useCollapsedFields } from './use-collapsed-fields'
import { Tags } from './tags'

interface Props {
  asset: Asset
}

export function AssetTags({ asset }: Props) {
  const { getIsFieldCollapsed, handleChipClick } = useCollapsedFields()
  const isTagsCollapsed = getIsFieldCollapsed(CollapsebleFields.TAGS)

  if (asset instanceof OvaServer) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Tags
        isTagsFieldCollapsed={isTagsCollapsed}
        onTagsChipClick={handleChipClick}
        asset={asset}
      />
    </Box>
  )
}
