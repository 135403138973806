import React from 'react'

function LeftBlueArrowIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0416 7H1.95825"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49992 12.5413L1.95825 6.99968L7.49992 1.45801"
        stroke="#32B6F3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LeftBlueArrowIcon
