import { createContext, useContext } from 'react'
import { useStore } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { EntropyFiltersZustandStore } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store'
import { EntropyTrendsDetailsState } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/types'

export const EntropyFiltersContext =
  createContext<EntropyFiltersZustandStore | null>(null)

export function useEntropyFiltersActions() {
  const store = useContext(EntropyFiltersContext)
  if (!store) {
    throw new Error('Missing EntropyFiltersContext.Provider in the tree')
  }
  return store.getState().actions
}

function useEntropyFiltersContextWithSelector<T>(
  selector: (state: EntropyTrendsDetailsState) => T
): T {
  const store = useContext(EntropyFiltersContext)
  if (!store) {
    throw new Error('Missing EntropyFiltersContext.Provider in the tree')
  }
  return useStore(store, selector)
}

export const useGetEntropyTrendsDetailsStoreTableData = () =>
  useEntropyFiltersContextWithSelector(useShallow((state) => state.tableData))

export const useGetEntropyTrendsHistory = () =>
  useEntropyFiltersContextWithSelector((state) => state.entropyTrendsHistory)

export const useGetEntropyTrendsDetailsStoreFilters = () =>
  useEntropyFiltersContextWithSelector(useShallow((state) => state.filters))

export const useGetIsDataLoading = () =>
  useEntropyFiltersContextWithSelector((state) => state.isLoadingData)
