import React from 'react'
import LangHelper from '@lib/helpers/lang.helper'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'

export const INITIAL_TOTAL_AMOUNT = 0

export const defineThreatRows = ({
  totalAssets,
  assetsWithThreats,
  accountsWithThreats,
  regionsWithThreats,
}: {
  totalAssets: number
  assetsWithThreats: number
  accountsWithThreats: number
  regionsWithThreats: number
}) => [
  {
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="8" fill="#E4F7FF" />
        <path
          d="M16 7.10938C13.9636 7.10938 12.0541 8.12085 10.8959 9.77951C7.80876 9.89329 5.33337 12.44 5.33337 15.5538C5.33337 18.7396 7.92537 21.3316 11.1112 21.3316H17.7778V20.4427C17.7778 20.1254 17.8416 19.8312 17.9341 19.5538H11.1112C8.90582 19.5538 7.11115 17.7592 7.11115 15.5538C7.11115 13.3485 8.90582 11.5538 11.1112 11.5538L11.875 11.5955L12.1493 11.1215C12.9422 9.74286 14.4178 8.88715 16 8.88715C18.1974 8.88715 20.0397 10.463 20.3837 12.6337L20.5174 13.4878L21.4948 13.3594C21.5882 13.3469 21.6809 13.3316 21.7778 13.3316C22.6703 13.3316 23.4702 13.7142 24.0382 14.3177C25.0898 14.5328 25.9862 15.1139 26.6146 15.9201C26.356 13.5317 24.3948 11.6471 21.9601 11.5573C21.1903 8.93685 18.7992 7.10938 16 7.10938ZM23.1112 15.9983C21.6 15.9983 20.4445 17.1538 20.4445 18.6649V19.5538C19.9112 19.5538 19.5556 19.9094 19.5556 20.4427V23.9983C19.5556 24.5316 19.9112 24.8872 20.4445 24.8872H25.7778C26.3112 24.8872 26.6667 24.5316 26.6667 23.9983V20.4427C26.6667 19.9094 26.3112 19.5538 25.7778 19.5538V18.6649C25.7778 17.1538 24.6223 15.9983 23.1112 15.9983ZM23.1112 17.3316C23.8223 17.3316 24.4445 17.9538 24.4445 18.6649V19.5538H21.7778V18.6649C21.7778 17.9538 22.4 17.3316 23.1112 17.3316Z"
          fill="#32B6F3"
        />
      </svg>
    ),
    amount: assetsWithThreats,
    totalAmount: totalAssets,
    description: 'Assets with cyber threats',
  },
  {
    icon: (
      <PinDropOutlinedIcon
        sx={{
          background: '#E4F7FF',
          color: '#32B6F3',
          borderRadius: '8px',
          padding: '2px',
          fontSize: '32px',
        }}
      />
    ),
    amount: regionsWithThreats,
    totalAmount: INITIAL_TOTAL_AMOUNT,
    description: `${LangHelper.pluralizeEn(
      'Region',
      regionsWithThreats
    )} with threats`,
  },
  {
    icon: (
      <AccountCircleOutlinedIcon
        sx={{
          background: '#E4F7FF',
          color: '#32B6F3',
          borderRadius: '8px',
          padding: '2px',
          fontSize: '32px',
        }}
      />
    ),
    amount: accountsWithThreats,
    totalAmount: INITIAL_TOTAL_AMOUNT,
    description: `${LangHelper.pluralizeEn('Account', accountsWithThreats)} with
      threats`,
  },
]
