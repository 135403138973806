import React, { memo, ReactNode } from 'react'
import DemoModeConstant from '@lib/constants/data/demo/demo-mode.constant'
import useDemo from '@lib/hooks/useDemo'
import { VIRow } from '@lib/engine-types'

interface Props {
  children: ReactNode
  forMods?: VIRow
}

function DemoWrap({ children, forMods = [DemoModeConstant.DEMO_DEV] }: Props) {
  const { modeNotActive } = useDemo({ forMods })
  if (modeNotActive) {
    return null
  }
  return <>{children}</>
}

export default memo(DemoWrap)
