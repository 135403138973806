import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const BucketIdFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const bucketIdList =
    searchParams.get(ASSET_FILTERS_MAP.BUCKET_ID)?.split(',') || []

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const bucketIdOptions = useMemo(() => {
    if (!liveAssetsData) {
      return []
    }

    return liveAssetsData.s3Buckets.map((bucket) => ({
      label: bucket.awsId,
      value: bucket.id,
    }))
  }, [liveAssetsData])

  const handleBucketIdChange = (
    newBucketIdList: Array<MultiSelectOption['value']>
  ) => {
    if (newBucketIdList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.BUCKET_ID)
    } else {
      searchParams.set(ASSET_FILTERS_MAP.BUCKET_ID, newBucketIdList.join(','))
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={bucketIdOptions}
      value={bucketIdList}
      onChange={handleBucketIdChange}
      label="Bucket ID"
      width={200}
    />
  )
}

export default BucketIdFilter
