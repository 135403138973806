/* eslint-disable import/no-extraneous-dependencies */
import { Column } from 'ui-v2/src/components/ui/data-display/data-table'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { UserProfile } from 'blues-corejs/dist/models/index'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import {
  CancelOutlined,
  DeleteForeverOutlined,
  InsertInvitationOutlined,
  TaskAltOutlined,
} from '@mui/icons-material'
import { RowAction } from 'ui-v2/src/components/ui/data-table/base-table'

export type ActionType = 'enable' | 'disable' | 'delete' | 'invite'

export type UserAction = {
  type: ActionType
  row: UserProfile
}

export const getUserColumns = (): Array<Column<UserProfile>> => [
  {
    key: 'email',
    header: 'User',
  },
  {
    key: 'lastSeen',
    header: 'Last Logged In',
    render: (row) => formatDateToUtc(row.lastSeen),
  },
  {
    key: 'status',
    header: 'Status',
    render: (row) => {
      switch (row.status as string) {
        case 'active':
          return <StatusChip type="success" label="Active" />
        case 'disabled':
          return <StatusChip type="error" label="Disabled" />
        case 'expired':
          return <StatusChip type="disabled" label="Expired" />
        case 'invited':
          return <StatusChip type="warning" label="Invited" />
      }
    },
    width: 80,
    align: 'center',
  },
]

export const getRowActions = (
  row: UserProfile,
  setAction: React.Dispatch<React.SetStateAction<UserAction | undefined>>
): Array<RowAction<UserProfile>> => {
  const actions: Array<RowAction<UserProfile>> = []

  const menuItem: Record<ActionType, RowAction<UserProfile>> = {
    enable: {
      label: 'Enable',
      icon: <TaskAltOutlined fontSize="small" />,
      onClick: (selectedRow) => {
        setAction({
          type: 'enable',
          row: selectedRow,
        })
      },
    },
    disable: {
      label: 'Disable',
      icon: <CancelOutlined fontSize="small" />,
      onClick: (selectedRow) => {
        setAction({
          type: 'disable',
          row: selectedRow,
        })
      },
    },
    delete: {
      label: 'Delete',
      icon: <DeleteForeverOutlined fontSize="small" />,
      onClick: (selectedRow) => {
        setAction({
          type: 'delete',
          row: selectedRow,
        })
      },
    },
    invite: {
      label: 'Invite',
      icon: <InsertInvitationOutlined fontSize="small" />,
      onClick: (selectedRow) => {
        setAction({
          type: 'invite',
          row: selectedRow,
        })
      },
    },
  } as const

  switch (row.status as string) {
    case 'active':
    case 'invited':
      actions.push(menuItem.disable)
      break
    case 'disabled':
      actions.push(menuItem.enable, menuItem.delete)
      break
    case 'expired':
      actions.push(menuItem.invite)
      break
  }

  return actions
}
