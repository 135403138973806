/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { CellWithLink } from '@components/table'
import { Asset, GenericHost } from 'blues-corejs/dist'
import { OvaServer } from 'blues-corejs/dist/models'
import { AssetRoutes } from '@lib/router'

export function AssetLinkCell({ asset }: { asset: Asset }) {
  const assetPageLink = AssetRoutes.assetId(asset.id)

  if (asset instanceof OvaServer) {
    return <CellWithLink href={assetPageLink} value={asset.name} />
  }

  if (asset instanceof GenericHost) {
    return <CellWithLink href={assetPageLink} value={asset.hostname} />
  }

  const displayedValue = asset.name || asset.awsId
  const displayedLabel = asset.name ? asset.awsId : asset.name

  return (
    <CellWithLink
      href={assetPageLink}
      value={displayedValue}
      label={displayedLabel}
    />
  )
}
