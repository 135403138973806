import React, { memo, useEffect } from 'react'
import DeployElastioIcon from '@inline-img/general/onboarding/deploy-elastio-icon'
import InviteTeamMembersIcon from '@inline-img/general/onboarding/invite-team-members'
import ProtectedAssetsIcon from '@inline-img/general/onboarding/protect-assets'
import ReviewResultsIcon from '@inline-img/general/onboarding/review-results'
import PagePathConstant from '@lib/constants/page-path.constant'
import { UserSettings } from '@lib/constants/settings.constant'
import SettingsService from '@lib/services/high/settings.service'
import PageHelper from '@lib/helpers/page.helper'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { getIsRedStackExist } from '@store/selectors/rex.selector'
import { useNavigation } from '@lib/router/contexts/navigation-context'

function OnboardingComplex() {
  const router = useNavigation()

  const isRedStackExists = useSelector(getIsRedStackExist)

  useEffect(() => {
    SettingsService.updateSetting(
      UserSettings.OnBoardingCongratulationsShown,
      1
    )
  }, [])

  const onReviewResultOnClick = () => {
    router.push(
      PageHelper.buildOnboardingParamUrl(PagePathConstant.CLOUD_CONNECTOR)
    )
  }
  const onDeployElastioOnClick = () => {
    router.push(
      PageHelper.buildOnboardingParamUrl(
        PagePathConstant.CLOUD_CONFIGURE_DEPLOYMENT
      )
    )
  }
  const onProtectedAssetsOnClick = () => {
    router.push(
      PageHelper.buildOnboardingParamUrl(PagePathConstant.PROTECT_ASSETS)
    )
  }
  const onInviteTeamMembersOnClick = () => {
    router.push(
      PageHelper.buildOnboardingParamUrl(
        PagePathConstant.SETTINGS_MEMBERS_ADD_USER_MODAL
      )
    )
  }

  return (
    <div className="wrap-1648054942645">
      <div className="onboardingBlock">
        <div className="flexRowJustifyStart">
          <DeployElastioIcon />
          <div className="ml15">
            <div className="obHeader">Deploy Elastio</div>
            <div className="controlDescXs">
              Deploy the Elastio service into an AWS account.
            </div>
          </div>
        </div>
        <div className="m7">
          <Button
            color="primary"
            variant="contained"
            className="sizeS jsDeployElastio"
            data-testid="jsDeployElastio"
            onClick={onDeployElastioOnClick}
          >
            Get Started
          </Button>
        </div>
      </div>
      <div className="onboardingBlock">
        <div className="flexRowJustifyStart">
          <InviteTeamMembersIcon />
          <div className="ml15">
            <div className="obHeader">Invite Team Members</div>
            <div className="controlDescXs">
              Invite your team members to the platform.
            </div>
          </div>
        </div>
        <div className="m7">
          <Button
            color="primary"
            variant="contained"
            className="sizeS jsInviteTeamMembers"
            data-testid="jsInviteTeamMembers"
            onClick={onInviteTeamMembersOnClick}
          >
            Get Started
          </Button>
        </div>
      </div>
      <div className="onboardingBlock">
        <div className="flexRowJustifyStart">
          <ProtectedAssetsIcon />
          <div className="ml15">
            <div className="obHeader">Protect Assets</div>
            <div className="controlDescXs">
              Protect your EC2 and EBS assets from ransomware with a few clicks.
            </div>
          </div>
        </div>
        <div className="m7">
          <Button
            disabled={!isRedStackExists}
            color="primary"
            variant="contained"
            className="sizeS jsProtectedAssets"
            data-testid="jsProtectedAssets"
            onClick={onProtectedAssetsOnClick}
          >
            Get Started
          </Button>
        </div>
      </div>
      <div className="onboardingBlock">
        <div className="flexRowJustifyStart">
          <ReviewResultsIcon />
          <div className="ml15">
            <div className="obHeader">Review the results</div>
            <div className="controlDescXs">
              Review the job status of the protection and ransomware analysis.
            </div>
          </div>
        </div>
        <div className="m7">
          <Button
            disabled={!isRedStackExists}
            color="primary"
            variant="contained"
            className="sizeS jsReviewResult"
            data-testid="jsReviewResult"
            onClick={onReviewResultOnClick}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  )
}

export default memo(OnboardingComplex)
