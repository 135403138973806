/* eslint-disable import/no-extraneous-dependencies */
import {
  CloudConnectorJobsClient,
  ListCloudConnnectorJobsFilter,
} from '@lib/clients/jobs'
import { CloudConnectorJobFiltersUI } from '@lib/interfaces/jobs/cloud-connector-job-filters'
import { JobCloudConnector } from 'blues-corejs/dist'

const jobsClient = new CloudConnectorJobsClient()

const ONE_HOUR_IN_MS = 1000 * 60 * 60

export async function fetchJobsFromLastHour(
  filters: CloudConnectorJobFiltersUI
): Promise<Array<JobCloudConnector>> {
  const response = await jobsClient.listCloudConnectorJobs(
    new ListCloudConnnectorJobsFilter({
      assetIdsList: Array.from(filters.assetIdsList),
      statusesList: Array.from(filters.statusesList),
      kindsList: Array.from(filters.kindsList),
      timeRange: {
        start: Math.round((Date.now() - ONE_HOUR_IN_MS) / 1000),
        end: Math.round(Date.now() / 1000),
      },
    })
  )
  return response.jobsList
}
