export const ALERT_ON_EVENTS = 'alertOnEvents'
export const SUPPRESS_EVENTS = 'suppressEvents'

export const ALERT_ON_EVENTS_LABEL = 'Alert on events'
export const SUPPRESS_EVENTS_LABEL = 'Suppress events'

export const modeOptions = [
  {
    value: ALERT_ON_EVENTS,
    label: ALERT_ON_EVENTS_LABEL,
  },
  {
    value: SUPPRESS_EVENTS,
    label: SUPPRESS_EVENTS_LABEL,
  },
]
