import React from 'react'
import Stack from '@mui/material/Stack'
import { WidgetHeader } from '@features/DashboardV4/common'
import { StyledScanDate } from './styles'
import { Anomaly } from '@lib/models/anomalies'
import { Nullable } from '@lib/engine-types'
import { TimeHelper } from '@lib/helpers'
import {
  createColumn,
  Table,
  useInfiniteScrollDataFetch,
} from '@components/table'
import { useEntropyFiltersActions } from '../entropy-trends-details-store/entropy-trends-details-store.context'
import styles from './entropy-trends-scan-result-selection.module.css'

interface Props {
  anomaliesList: Array<Anomaly>
  selectedAnomaly: Nullable<Anomaly>
  onAnomalyClick: (anomaly: Nullable<Anomaly>) => void
  isLoading: boolean
}

interface ScanDateCellProps {
  anomaly: Anomaly
  isSelected: boolean
  onAnomalyClick: (anomaly: Anomaly) => void
}

function ScanDateCell({
  anomaly,
  onAnomalyClick,
  isSelected,
}: ScanDateCellProps) {
  const handleAnomalyClick = () => {
    onAnomalyClick(anomaly)
  }

  return (
    <StyledScanDate
      selected={isSelected}
      key={anomaly.id}
      onClick={handleAnomalyClick}
    >
      {TimeHelper.getDateWithTimezone(anomaly.createdAt.getTime()).format(
        'MM/DD/YY hh:mm a'
      )}
    </StyledScanDate>
  )
}

function getColumns({
  selectedAnomalyId,
  onClickAnomaly,
}: {
  selectedAnomalyId: Nullable<string>
  onClickAnomaly: (anomaly: Anomaly) => void
}) {
  return [
    createColumn<Anomaly>('id', {
      header: '',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const anomaly = original

        const isSelected = selectedAnomalyId === anomaly.id

        return (
          <ScanDateCell
            anomaly={anomaly}
            isSelected={isSelected}
            onAnomalyClick={onClickAnomaly}
          />
        )
      },
    }),
  ]
}

export function EntropyTrendsScanResultSelection({
  anomaliesList,
  onAnomalyClick,
  isLoading,
  selectedAnomaly,
}: Props) {
  const { fetchMoreData } = useEntropyFiltersActions()
  const observable = useInfiniteScrollDataFetch({
    initialItems: anomaliesList,
    fetchNextBatch: fetchMoreData,
  })

  return (
    <Stack>
      <WidgetHeader title="Scans" />
      <Table
        className={styles.table}
        data={anomaliesList}
        columns={getColumns({
          selectedAnomalyId: selectedAnomaly?.id,
          onClickAnomaly: onAnomalyClick,
        })}
        generalProps={{
          isLoading,
          isShowHeader: false,
        }}
        advancedProps={{
          observableState: observable,
        }}
      />
    </Stack>
  )
}
