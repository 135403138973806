import React, { memo, useEffect, useMemo } from 'react'
import {
  EngineCallback,
  EngineCallbackPure,
  ValueObject,
} from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormEditUserInterface from '@lib/interfaces/form/form-edit-user.interface'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import UserRoleModel from '@lib/models/user-role.model'
import ValueHelper from '@lib/helpers/value.helper'
import buildDefaultFormData from './data'
import { Button, TextField } from '@mui/material'

interface Props {
  selectedRole: UserRoleModel
  possibleRoles: Array<UserRoleModel>
  defaultValues?: ValueObject<FormEditUserInterface>
  onSubmit: EngineCallback<FormEditUserInterface>
  onCancel: EngineCallbackPure
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
}

function EditUserForm({
  selectedRole,
  defaultValues,
  onSubmit,
  onCancel,
  loading,
  errorGroups,
}: Props) {
  // const possibleVIRoles = possibleRoles.map(ValueHelper.fromRole)
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )

  const {
    setValue,
    isFormDisabled,
    register,
    submit,
    formState,
    globalFormError,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading)

  const role = ValueHelper.fromRole(selectedRole)
  useEffect(() => {
    setValue('roleId', role.value)
  }, [role])

  FormHelper.fillErrors(formState.errors, currentForm)

  return (
    <form
      className={clsx('wrap-1617284626632', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      <input
        type="hidden"
        value={String(currentForm.email.value)}
        {...register(currentForm.email.name, currentForm.email.validationRules)}
      />
      <input
        type="hidden"
        value={String(currentForm.userId.value)}
        {...register(
          currentForm.userId.name,
          currentForm.userId.validationRules
        )}
      />
      <input
        type="hidden"
        {...register(
          currentForm.roleId.name,
          currentForm.roleId.validationRules
        )}
      />

      <div className="newFormSingleRow v2StaticTextInput v2StaticTextInputModal">
        <TextField
          disabled
          label={currentForm.email.label}
          value={currentForm.email.value}
          variant="outlined"
        />
      </div>
      {/*TODO: hidden 09.02.2024 by ticket #9484*/}
      {/*<div className="newFormSingleRow jsRole">*/}
      {/*  <RolesSelect*/}
      {/*    possible={possibleVIRoles}*/}
      {/*    selected={role}*/}
      {/*    onChangeSelected={setRole}*/}
      {/*    label={currentForm.roleId.label}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="blueRadioSelected brsLast">
        Send user an email notification of changes
      </div>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}

      <div className="modalFooterV2Row">
        <Button
          disabled={loading}
          className="mfCancel"
          data-testid="jsEditUserCancel"
          type="button"
          variant="text"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          className="mfSubmit"
          data-testid="jsEditUserSubmit"
          color="primary"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default memo(EditUserForm)
