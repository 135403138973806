import { TimeRange } from '@lib/clients'
import { TimeHelper } from '@lib/helpers'
import type { AssetJobsStoreState } from './types'

export const getInitialState = (): Omit<AssetJobsStoreState, 'actions'> => {
  const [start, end] = TimeHelper.transformTupleDateRangeForApi(
    TimeHelper.currentMonthDateRange()
  )

  const initialTimeRange: TimeRange = {
    start,
    end,
  }

  return {
    jobsList: [],
    assetsMap: new Map(),
    loadingState: 'idle',
    filters: {
      timeRange: initialTimeRange,
    },
  }
}
