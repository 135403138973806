/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { getHumanReadableTenantJobKind } from '@features/jobs/tenant-jobs/helpers'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { TenantJobKindCheckbox } from '@features/jobs/tenant-jobs/shared'
import { usePossibleTenantJobFilters } from '../../stores'
import {
  useModalFilterActions,
  useModalFilterSearchValue,
  useSelectedModalFilters,
} from '../deployment-filters-store'

export function JobKindsSection() {
  const { kindsList: initialKindList } = usePossibleTenantJobFilters()

  const filterSearchValue = useModalFilterSearchValue()

  const { setFilter } = useModalFilterActions()

  const kindsList = useMemo(
    () =>
      Array.from(initialKindList).filter((kind) =>
        getHumanReadableTenantJobKind(kind)
          .toLowerCase()
          .includes(filterSearchValue)
      ),
    [initialKindList, filterSearchValue]
  )
  const { kindsList: selectedKindsList } = useSelectedModalFilters()

  if (kindsList === undefined) {
    return null
  }

  function handleCheckboxChange(checked: boolean, kind: TenantJobKind) {
    const newKindsList = new Set(selectedKindsList)

    if (checked) {
      newKindsList.add(kind)
    } else {
      newKindsList.delete(kind)
    }

    setFilter('kindsList', newKindsList)
  }

  return (
    <Fragment>
      <Typography
        fontSize="12px"
        lineHeight="140%"
        marginBottom="10px"
        marginTop="10px"
        color="#71768e"
        fontWeight="700"
      >
        Job Kind
      </Typography>
      <Box display="flex" gap="12px" flexDirection="column">
        {kindsList.map((kind) => (
          <TenantJobKindCheckbox
            key={kind}
            onChange={handleCheckboxChange}
            isChecked={selectedKindsList.has(kind)}
            kind={kind}
          />
        ))}
      </Box>
    </Fragment>
  )
}
