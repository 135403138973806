import React, { useState, useMemo } from 'react'
import { TablePagination } from '@mui/material'
import BaseTable, { BaseTableProps } from './base-table'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

interface ClientTableProps<T extends Record<string, any>>
  extends Omit<BaseTableProps<T>, 'footer' | 'data'> {
  data: Array<T>
  initialPageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
}

function ClientTable<T extends Record<string, any>>({
  data,
  initialPageSize = TABLE_ROWS_PER_PAGE_OPTIONS[0],
  onPageChange,
  onPageSizeChange,
  ...baseProps
}: ClientTableProps<T>) {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize)

  const paginatedData = useMemo(() => {
    const startIndex = page * pageSize
    return data.slice(startIndex, startIndex + pageSize)
  }, [data, page, pageSize])

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
    onPageChange?.(newPage)
    baseProps.onSelectRows?.([])
  }

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newPageSize = parseInt(e.target.value, 10)
    setPage(0)
    setPageSize(newPageSize)
    onPageSizeChange?.(newPageSize)
    baseProps.onSelectRows?.([])
  }

  const paginationComponent = (
    <TablePagination
      rowsPerPageOptions={TABLE_ROWS_PER_PAGE_OPTIONS}
      component="div"
      count={data.length}
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleRowsPerPageChange}
      sx={(theme) => ({
        '& .MuiTablePagination-toolbar': {
          minHeight: '43px !important',
          height: '43px',
          backgroundColor: theme.palette.background.paper,
        },
      })}
    />
  )

  return (
    <BaseTable
      {...baseProps}
      data={paginatedData}
      footer={paginationComponent}
    />
  )
}

export default ClientTable
