import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface FilterComponentProps {
  options: Array<string>
}

const AccountIdFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsAccountList =
    searchParams
      .get(ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID)
      ?.split(',') || []

  const awsAccountOptions = useMemo(() => {
    return options.map((awsAccountOption) => ({
      value: awsAccountOption,
      label: awsAccountOption,
    }))
  }, [options])

  const handleAwsAccountChange = (
    newAwsAccountList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsAccountList.length === 0) {
      searchParams.delete(ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID)
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID,
        newAwsAccountList.join(',')
      )
    }
    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={awsAccountOptions}
      value={awsAccountList}
      onChange={handleAwsAccountChange}
      label="Account ID"
      width={200}
    />
  )
}

export default AccountIdFilter
