import React, { memo, useEffect, useMemo, useState } from 'react'
import { EngineCallback, VIRow } from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import ScopeHelper from '@lib/helpers/scope.helper'
import CustomTextField from '@forms/fields/custom-text-field'
import AccessTokenModel from '@lib/models/access-token.model'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import buildDefaultFormData from './data'
import CheckboxBlockCustom from '@components-simple/checkbox-block-custom/CheckboxBlockCustom'
import { Button } from '@mui/material'
import FormTokenInterface from '@lib/interfaces/form/form-token.interface'
import ValueInterface from '@lib/interfaces/value.interface'
import { AccountAndSettingsRoutes, Link } from '@lib/router'
import { AccountAndSettingsTab } from '@lib/router/routes/account-and-settings/account-and-settings'

interface Props {
  possibleScopes: Array<string>
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
  onSubmit: EngineCallback<FormTokenInterface>
  token: AccessTokenModel
  submitButtonLabel?: string
}

function TokenForm({
  possibleScopes,
  token,
  loading,
  errorGroups,
  onSubmit,
  submitButtonLabel = 'Generate Token',
}: Props) {
  const selectedScopesValues = ScopeHelper.getSelectedScopeValues(
    token.scopesList,
    possibleScopes
  )

  // checkboxes
  const [innerScopes, setInnerScopes] = useState<VIRow>(selectedScopesValues)

  useEffect(() => () => setInnerScopes([]), [])

  // form initialization - for validation purposes
  const currentForm = useMemo(
    () =>
      FormHelper.fillDefaultValues(buildDefaultFormData(), {
        tokenName: token.name,
      }),
    []
  )

  const { isFormDisabled, register, submit, formState, globalFormError } =
    useGeneralForm(
      currentForm,
      (submitData: any) => {
        onSubmit({
          ...submitData,
          tokenId: token.innerId,
          scopes: ScopeHelper.selectedValuesToStrings(innerScopes),
        })
      },
      errorGroups,
      loading
    )

  const onCheckboxBlockChange = (index: number) => (values: VIRow) => {
    const newInnerScopes = [...innerScopes]
    const elem = newInnerScopes[index] as ValueInterface
    elem.options = values
    setInnerScopes(newInnerScopes)
  }

  const canBeSubmitted = ScopeHelper.isAnySelected(innerScopes)

  FormHelper.fillErrors(formState.errors, currentForm)

  return (
    <form
      className={clsx('wrap-1604049267547', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      <PreloaderSmall top="8" right="8" show={loading} />

      <div className="newFormRow v2StaticTextInput">
        <CustomTextField
          field={currentForm.tokenName}
          disabled={isFormDisabled}
          {...register(
            currentForm.tokenName.name,
            currentForm.tokenName.validationRules
          )}
        />
      </div>

      <div className="tokenTitle">Select Scopes</div>
      <div className="tokenDesc mb20">Scopes limit access to the tokens.</div>
      <div className="selectScopeBlock">
        {innerScopes.map((s, index) => (
          <CheckboxBlockCustom
            behaviour="roles"
            disabled={isFormDisabled}
            key={s.name}
            selectAllLabel={s.label}
            data={s.options ?? []}
            onChange={onCheckboxBlockChange(index)}
          />
        ))}
      </div>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}

      <BasePortalButtons>
        <div className="flexRowBetween">
          <p className="colorTextGrey">
            {ScopeHelper.selectedValuesToStrings(innerScopes).length} Access
            selected
          </p>
          <div>
            <Link
              to={AccountAndSettingsRoutes.accountAndSettingsWithParams(
                AccountAndSettingsTab.API_ACCESS
              )}
            >
              <Button
                variant="outlined"
                color="primary"
                className="blueButton sizeM mr14"
              >
                Cancel
              </Button>
            </Link>
            <Button
              onClick={submit}
              disabled={isFormDisabled || !canBeSubmitted}
              variant="contained"
              color="primary"
              className="sizeM jsPortalSubmit"
              type="button"
            >
              {submitButtonLabel}
            </Button>
          </div>
        </div>
      </BasePortalButtons>
    </form>
  )
}

export default memo(TokenForm)
