import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { NotificationsClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'
import { NotificationsConfig } from 'ui-v2/src/lib/clients/notifications'

export function useUpdateNotificationSettingsMutation() {
  const notificationsClient = useGrpcClient(NotificationsClient)
  const toast = useToast()
  return useMutation({
    mutationFn: async (notificationConfig: NotificationsConfig) => {
      if (!notificationsClient) {
        throw new Error('Notifications client not initialized')
      }
      return notificationsClient.updateCustomerNotificationSettings(
        notificationConfig
      )
    },
    onError: (error) => {
      toast.error(error.message ?? 'Cannot update a user notification settings')
    },
    onSuccess: () => {
      toast.success('The new settings have been saved')
    },
  })
}
