/* eslint-disable import/no-extraneous-dependencies */
import { useSelector, useDispatch } from 'react-redux'
import { getAssetsSelect } from '@store/selectors/assets-select.selector'
import React, { useEffect, useMemo } from 'react'
import { VirtualTable } from '@components/table'

import { TabsBlockWrapper } from '@components-complex/dashboard-pages-v3/styles'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import Tab from '@mui/material/Tab'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import TabBadgeLabel from '@components-simple/tab-badge-label/TabBadgeLabel'
import {
  requestLiveAssetsSelect,
  setAssetsSelectFilters,
  setAssetsSelectSearch,
} from '@store/actions/assets-select.action'
import FilterV3 from '@components-complex/filter-v3/FilterV3'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '../../../../../lib/constants/preloader.constant'
import { getAllRedStacks } from '@store/actions/rex.action'
import {
  getSpecificAssetsTableColumnDefinitions,
  useSpecificAssetsFilter,
  useSpecificAssetsSelectRows,
} from '@features/policies/policy/policy-tables/specific-assets-for-policy'
import { useTab } from '@lib/hooks'
import { AssetKind } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import {
  getBackupsMapped,
  getIsLiveAssetsLoading,
} from '@store/selectors/list-live-assets.selector'
import { VIRow } from '@lib/engine-types'

export enum SpecificAssetsTabs {
  EC2 = 0,
  EBS = 1,
}

interface Props {
  initialSelectedFilters: VIRow
}

function SpecificAssetsForPolicy({ initialSelectedFilters }: Props) {
  const dispatch = useDispatch()
  const assets = useSelector(getAssetsSelect)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)
  const { lastBackups } = useSelector(getBackupsMapped)
  const ec2Assets = useMemo(
    () => assets.filter((item) => item.type === AssetKind.AWS_EC2),
    [assets]
  )
  const ebsAssets = useMemo(
    () => assets.filter((item) => item.type === AssetKind.AWS_EBS),
    [assets]
  )
  const isTableLoading =
    usePreloader(PreloaderConstants.REQUEST_ASSETS_SELECT_INVENTORY) ||
    isLiveAssetsLoading
  const isFiltersLoading = usePreloader(
    PreloaderConstants.REQUEST_ASSETS_SELECT_ALL_FILTERS_V3
  )
  const { onTabChange, activeTab } = useTab()

  const {
    possibleFilters,
    selectedFilters,
    searchVal,
    handleSearchChange,
    handleFiltersChange,
    selectedAssetsFilters,
  } = useSpecificAssetsFilter()

  const {
    selectedAssetsIds,
    selectedEbsRows,
    isEbsTabDisabled,
    isEc2TabDisabled,
    isDisabledHeadersCheckboxForEbs,
    isDisabledHeadersCheckboxForEc2,
    setSelectedEbsRows,
    onSelectAllRows,
    setSelectedEc2Rows,
    selectedEc2Rows,
    handleRowChange,
  } = useSpecificAssetsSelectRows({
    assets,
    tab: activeTab,
    ec2Assets,
    ebsAssets,
  })

  useEffect(() => {
    dispatch(requestLiveAssetsSelect())
    dispatch(setAssetsSelectSearch(''))
    dispatch(getAllRedStacks())
    dispatch(setAssetsSelectFilters(initialSelectedFilters))
  }, [])

  return (
    <TabsBlockWrapper>
      <CustomTabs
        value={activeTab}
        onChangeTab={onTabChange}
        style={{ marginBottom: 16 }}
      >
        <Tab
          label={
            <TabBadgeLabel labelText="EC2" badgeCount={ec2Assets.length} />
          }
          value={SpecificAssetsTabs.EC2}
          disabled={isEc2TabDisabled}
        />
        <Tab
          label={
            <TabBadgeLabel labelText="EBS" badgeCount={ebsAssets.length} />
          }
          value={SpecificAssetsTabs.EBS}
          disabled={isEbsTabDisabled}
        />
      </CustomTabs>
      {!isFiltersLoading && (
        <FilterV3
          possibleFilters={possibleFilters}
          selectedFilters={selectedFilters}
          initSelectedFilters={selectedAssetsFilters}
          onFiltersChange={handleFiltersChange}
          onSearchChange={handleSearchChange}
          searchVal={searchVal}
          turnOnSearch
          disabled={isTableLoading}
          searchPlaceholder="Search by Asset ID"
          finalLoading={isTableLoading}
        />
      )}
      <MaterialTab value={activeTab} index={SpecificAssetsTabs.EC2}>
        <VirtualTable
          config={{
            columns: getSpecificAssetsTableColumnDefinitions({
              handleRowChange,
              selectedAssetsIds,
              onSelectAllRows,
              isDisableHeadersCheckbox: isDisabledHeadersCheckboxForEc2,
              assetType: 'EC2',
              lastBackups,
            }),
            data: ec2Assets,
            onRowSelectionChange: setSelectedEc2Rows,
            state: {
              rowSelection: selectedEc2Rows,
              isLoading: isTableLoading,
            },
          }}
          emptyPlaceholderText="No EC2 found"
        />
        {/* <Table
          columns={getSpecificAssetsTableColumnDefinitions({
            handleRowChange,
            selectedAssetsIds,
            onSelectAllRows,
            isDisableHeadersCheckbox: isDisabledHeadersCheckboxForEc2,
            assetType: 'EC2',
            lastBackups,
          })}
          data={ec2Assets}
          generalProps={{
            noDataMessage: 'No EC2 found',
            isLoading: false,
          }}
          handlers={{
            rowsSelection: {
              selectedRows: selectedEc2Rows,
              setSelectedRows: setSelectedEc2Rows,
            },
          }}
        /> */}
      </MaterialTab>
      <MaterialTab value={activeTab} index={SpecificAssetsTabs.EBS}>
        <VirtualTable
          config={{
            columns: getSpecificAssetsTableColumnDefinitions({
              handleRowChange,
              selectedAssetsIds,
              onSelectAllRows,
              isDisableHeadersCheckbox: isDisabledHeadersCheckboxForEbs,
              assetType: 'EBS',
              lastBackups,
            }),
            data: ebsAssets,
            onRowSelectionChange: setSelectedEbsRows,

            state: {
              rowSelection: selectedEbsRows,
              isLoading: isTableLoading,
            },
          }}
          emptyPlaceholderText="No EBS found"
        />
      </MaterialTab>
    </TabsBlockWrapper>
  )
}

export default SpecificAssetsForPolicy
