import ScheduleInterface from '@lib/interfaces/schedule.interface'
import IntegrityScanInterface from '@lib/interfaces/integrity-scan.interface'
import { VIRow } from '@lib/engine-types'
import { SelectedVariant } from '@lib/constants/app.constant'
import {
  ProtectNewImmediately,
  SelectedCloudConnectorVariant,
  SnapshotImport,
} from '@lib/constants/policies.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import { VaultForPolicy } from '@lib/interfaces/vault/vaults-policy'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { KeepDataCopyInterface } from '@lib/interfaces/keep-data-copy.interface'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'

export const REQUEST_EDIT_POLICY = 'REQUEST_EDIT_PLAN'
export const SET_EDIT_POLICY_NAME = 'SET_EDIT_PLAN_NAME'
export const SET_EDIT_POLICY_SCHEDULE = 'SET_EDIT_PLAN_SCHEDULE'
export const SET_EDIT_INTEGRITY_SCAN = 'SET_EDIT_INTEGRITY_SCAN'
export const SET_INTEGRITY_SCAN_OPTION = 'SET_INTEGRITY_SCAN_OPTION'
export const SET_KEEP_DATA_COPY = 'SET_KEEP_DATA_COPY'
export const RESET_INTEGRITY_SCAN = 'RESET_INTEGRITY_SCAN'
export const UPDATE_EDIT_POLICY = 'UPDATE_EDIT_PLAN'
export const SET_EDIT_POLICY_TAGS = 'SET_EDIT_PLAN_TAGS'
export const SET_EDIT_INTEGRITY_CHECK = 'SET_EDIT_INTEGRITY_CHECK'
export const SET_EDIT_SELECTED_VARIANT = 'SET_EDIT_SELECTED_VARIANT'
export const SET_EDIT_SNAPSHOT_IMPORT_VARIANT =
  'SET_EDIT_SNAPSHOT_IMPORT_VARIANT'
export const SET_EDIT_PROTECT_IMMEDIATELY = 'SET_EDIT_PROTECT_IMMEDIATELY'
export const SET_EDIT_POLICY_VAULT_LIST = 'SET_EDIT_POLICY_VAULT_LIST'
export const SET_EDIT_SKIP_EBS_BACKUP = 'SET_EDIT_SKIP_EBS_BACKUP'

export const SET_EDIT_SELECTED_CLOUD_CONNECTORS_VARIANT =
  'SET_EDIT_SELECTED_CLOUD_CONNECTORS_VARIANT'
export const SET_EDIT_SELECTED_CLOUD_CONNECTORS =
  'SET_EDIT_SELECTED_CLOUD_CONNECTORS'

export const SET_EDIT_SCAN_WITH_TIMESTAMPS = 'SET_EDIT_SCAN_WITH_TIMESTAMPS'
export const RESET_EDIT_SCAN_WITH_TIMESTAMPS = 'RESET_EDIT_SCAN_WITH_TIMESTAMPS'

export const SET_ENTROPY_DETECTION_CONFIGURATION =
  'SET_ENTROPY_DETECTION_CONFIGURATION'

export const requestEditPolicy = (policyName: string) => ({
  type: REQUEST_EDIT_POLICY,
  payload: policyName,
})

export const setEditPolicyName = (policyName: string) => ({
  type: SET_EDIT_POLICY_NAME,
  payload: policyName,
})

export const setEditPolicySchedule = (schedule: ScheduleInterface) => ({
  type: SET_EDIT_POLICY_SCHEDULE,
  payload: schedule,
})

export const setEditPolicyIntegrityScan = (
  payload: IntegrityScanInterface
) => ({
  type: SET_EDIT_INTEGRITY_SCAN,
  payload,
})

export const setIntegrityScanOption = (payload: IntegrityScanOptions) => ({
  type: SET_INTEGRITY_SCAN_OPTION,
  payload,
})

export const setKeepDataCopy = (payload: KeepDataCopyInterface) => ({
  type: SET_KEEP_DATA_COPY,
  payload,
})

export const resetIntegrityScan = () => ({
  type: RESET_INTEGRITY_SCAN,
  payload: null,
})

export const setEditSelectedVariant = (payload: SelectedVariant) => ({
  type: SET_EDIT_SELECTED_VARIANT,
  payload,
})

export const setEditSelectedCloudConnectorsVariant = (
  payload: SelectedCloudConnectorVariant
) => ({
  type: SET_EDIT_SELECTED_CLOUD_CONNECTORS_VARIANT,
  payload,
})

export const setEditSelectedCloudConnectors = (payload: VIRow) => ({
  type: SET_EDIT_SELECTED_CLOUD_CONNECTORS,
  payload,
})

export const setEditPolicyTags = (payload: VIRow) => ({
  type: SET_EDIT_POLICY_TAGS,
  payload,
})

export const setEditSnapshotImportVariant = (payload: SnapshotImport) => ({
  type: SET_EDIT_SNAPSHOT_IMPORT_VARIANT,
  payload,
})

export const setEditIntegrityCheck = (payload: IntegrityCheckOptions) => ({
  type: SET_EDIT_INTEGRITY_CHECK,
  payload,
})

export const setEditProtectNewImmediately = (
  payload: ProtectNewImmediately
) => ({
  type: SET_EDIT_PROTECT_IMMEDIATELY,
  payload,
})

export const setEditSkipEbsBackup = (payload: boolean) => ({
  type: SET_EDIT_SKIP_EBS_BACKUP,
  payload,
})

export const updateEditPolicy = (
  executeImmediately: boolean,
  callback: () => void
) => ({
  type: UPDATE_EDIT_POLICY,
  payload: {
    executeImmediately,
    callback,
  },
})

export const setEditPolicyVaultList = (vaultList: Array<VaultForPolicy>) => ({
  type: SET_EDIT_POLICY_VAULT_LIST,
  payload: vaultList,
})

export const setEditScanWithTimestamps = (
  payload: ScanWithTimestampsInterface
) => ({
  type: SET_EDIT_SCAN_WITH_TIMESTAMPS,
  payload,
})

export const resetEditScanWithTimestamps = () => ({
  type: RESET_EDIT_SCAN_WITH_TIMESTAMPS,
  payload: null,
})

export const setEntropyDetectionConfiguration = (payload: boolean) => ({
  type: SET_ENTROPY_DETECTION_CONFIGURATION,
  payload,
})
