/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { AssetStore, fetchAssetsByIds } from '.'
import { getInitialState } from './consts'

export const useAssetStore = create<AssetStore>((set, get) => ({
  ...getInitialState(),
  actions: {
    resetAssetStore: () => set(getInitialState()),
    setAssetMap: (assetMap) => set({ assetMap }),
    fetchAssetsByIds: async (assetIds) => {
      const previousMap = get().assetMap

      if (!assetIds.length) {
        return previousMap
      }

      const response = await fetchAssetsByIds(assetIds)

      useAssetStore.setState({
        assetMap: new Map([...previousMap, ...response]),
      })
    },
  },
}))
