/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProvider } from 'blues-corejs/dist/models/reports/report-schedule/kind'
import {
  AWSReports,
  BackupConnectorReports,
  AWSReportsSlug,
  BackupConnectorReportsSlug,
  Days,
  FormOption,
  FormOptionValue,
  FormVariant,
  RepeatPeriod,
  ReportEnd,
  ReportPeriodValue,
  TimeRangeSelection,
  FrequencyType,
} from '../models/reports'

export const Tabs = {
  OVERVIEW: 'overview',
  SCHEDULES: 'schedules',
  HISTORY: 'history',
}
export const summaryReports = [
  {
    title: 'Ransomware Readiness',
    description:
      'Executive Summary on your business’ ransomware readiness posture and risk of data loss (PDF)',
    kind: AWSReports.awsExecutiveReport,
    kindSlug: AWSReportsSlug.awsExecutiveReport,
  },
  {
    title: 'Account Summary',
    description: 'Summary of scanned assets by account (PDF)',
    kind: AWSReports.awsAccountSummaryReport,
    kindSlug: AWSReportsSlug.awsAccountSummaryReport,
  },

  {
    title: 'Operational Summary',
    description:
      'A high-level overview of scanning outcomes, active threats, and protected data over the reporting period, helping you quickly assess security posture and operational effectiveness (PDF)',
    kind: AWSReports.awsTenantSummaryReport,
    kindSlug: AWSReportsSlug.awsTenantSummaryReport,
  },
]

export const detailedReports = [
  {
    title: 'All Scans',
    description:
      'Detailed information about all scans to monitor Elastio`s protection  (CSV)',
    kind: AWSReports.awsCompletedScansReport,
    kindSlug: AWSReportsSlug.awsCompletedScansReport,
  },
  {
    title: 'Failed scans',
    description:
      'Detailed information about any failed scans to support troubleshooting (CSV)',
    kind: AWSReports.failedScansReport,
    kindSlug: AWSReportsSlug.failedScansReport,
  },
  {
    title: 'Infected files',
    description:
      'Comprehensive list of compromised files for targeted remediation efforts (CSV)',
    kind: AWSReports.infectedFilesReport,
    kindSlug: AWSReportsSlug.infectedFilesReport,
  },
  {
    title: 'Assets Scanned',
    description: 'Detailed information about protected assets (CSV)',
    kind: AWSReports.awsScannedAssetsSummaryReport,
    kindSlug: AWSReportsSlug.awsScannedAssetsSummaryReport,
  },
  {
    title: 'Infected Assets',
    description:
      'Comprehensive list of compromised assets for targeted remediation efforts (CSV)',
    kind: AWSReports.awsInfectedAssetsReport,
    kindSlug: AWSReportsSlug.awsInfectedAssetsReport,
  },
]

export const backupSummaryReportsData = [
  {
    title: 'Ransomware Readiness',
    description:
      'Executive Summary on your business’ ransomware readiness posture and risk of data loss (PDF)',
    kind: BackupConnectorReports.ovaMspExecutiveReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspExecutiveReport,
  },
  {
    title: 'Account Summary',
    description:
      'Consolidated overview of account details for resource management and usage evaluation (PDF)',
    kind: BackupConnectorReports.ovaMspAccountSummaryReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspAccountSummaryReport,
  },
  {
    title: 'Operational Summary',
    description:
      'A high-level overview of scanning outcomes, active threats, and protected data over the reporting period, helping you quickly assess security posture and operational effectiveness (PDF)',
    kind: BackupConnectorReports.ovaMspTenantSummaryReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspTenantSummaryReport,
  },
]

export const backupDetailedReportsData = [
  {
    title: 'All Scans',
    description:
      'Detailed information about all scans to monitor Elastio`s protection (CSV)',
    kind: BackupConnectorReports.ovaMspCompletedScansReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspCompletedScansReport,
  },
  {
    title: 'Infected files',
    description:
      'Comprehensive list of compromised files for targeted remediation efforts (CSV)',
    kind: BackupConnectorReports.ovaMspInfectedFilesReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspInfectedFilesReport,
  },
  {
    title: 'Assets Scanned',
    description: 'Detailed information about protected assets (CSV)',
    kind: BackupConnectorReports.ovaMspScannedAssetsSummaryReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspScannedAssetsSummaryReport,
  },
  {
    title: 'Infected Assets',
    description:
      'Comprehensive list of compromised assets for targeted remediation efforts (CSV)',
    kind: BackupConnectorReports.ovaMspInfectedAssetsReport,
    kindSlug: BackupConnectorReportsSlug.ovaMspInfectedAssetsReport,
  },
]
export const formOptions: Array<FormOption> = [
  {
    value: FormOptionValue.ON_DEMAND,
    label: 'On-Demand',
  },
  {
    value: FormOptionValue.SCHEDULE,
    label: 'Schedule',
  },
]
const AWSPrefix = 'AWS'
const OVAPrefix = 'VMware'

export const reportOptions = [
  // AWS Reports
  {
    label: `${AWSPrefix} Ransomware Readiness`,
    value: 'awsExecutiveReport',
    type: FormVariant.AWS,
    formats: ['PDF'],
    slug: 'aws-executive-report',
  },
  {
    label: `${AWSPrefix} Account Summary`,
    value: 'awsAccountSummaryReport',
    type: FormVariant.AWS,
    formats: ['PDF'],
    slug: 'aws-account-summary-report',
  },
  {
    label: `${AWSPrefix} Operational Summary`,
    value: 'awsTenantSummaryReport',
    type: FormVariant.AWS,
    formats: ['PDF'],
    slug: 'aws-tenant-summary-report',
  },
  {
    label: `${AWSPrefix} All Scans`,
    value: 'awsCompletedScansReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
    slug: 'aws-completed-scans-report',
  },
  {
    label: `${AWSPrefix} Failed Scans`,
    value: 'failedScansReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
    slug: 'failed-scans-report',
  },
  {
    label: `${AWSPrefix} Infected files`,
    value: 'infectedFilesReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
    slug: 'infected-files-report',
  },
  {
    label: `${AWSPrefix} Assets Scanned`,
    value: 'awsScannedAssetsSummaryReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
    slug: 'aws-completed-assets-summary-report',
  },
  {
    label: `${AWSPrefix} Infected Assets`,
    value: 'awsInfectedAssetsReport',
    type: FormVariant.AWS,
    formats: ['CSV'],
    slug: 'aws-infected-assets-report',
  },

  // Backup Connector Reports
  {
    label: `${OVAPrefix} Ransomware Readiness`,
    value: 'ovaMspExecutiveReport',
    type: FormVariant.BackupConnector,
    formats: ['PDF'],
    slug: 'vmware-msp-executive-report',
  },
  {
    label: `${OVAPrefix} Account Summary`,
    value: 'ovaMspAccountSummaryReport',
    type: FormVariant.BackupConnector,
    formats: ['PDF'],
    slug: 'vmware-msp-account-summary-report',
  },
  {
    label: `${OVAPrefix} Operational Summary`,
    value: 'ovaMspTenantSummaryReport',
    type: FormVariant.BackupConnector,
    formats: ['PDF'],
    slug: 'vmware-msp-tenant-summary-report',
  },

  {
    label: `${OVAPrefix} All Scans`,
    value: 'ovaMspCompletedScansReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
    slug: 'vmware-msp-completed-scans-report',
  },
  {
    label: `${OVAPrefix} Infected files`,
    value: 'ovaMspInfectedFilesReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
    slug: 'vmware-msp-infected-files-report',
  },
  {
    label: `${OVAPrefix} Assets Scanned`,
    value: 'ovaMspScannedAssetsSummaryReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
    slug: 'vmware-msp-scanned-assets-summary-report',
  },
  {
    label: `${OVAPrefix} Infected Assets`,
    value: 'ovaMspInfectedAssetsReport',
    type: FormVariant.BackupConnector,
    formats: ['CSV'],
    slug: 'vmware-msp-infected-assets-report',
  },

  //for future use
  // {
  //     label: `${OVAPrefix} Failed Scans`,
  //     value: 'ovaMspFailedScansReport',
  //     type: FormVariant.BackupConnector,
  //     formats: ['CSV'],
  // }
]

export const FORM_FIELDS = {
  REPORT_TYPE: 'reportType',
  NAME: 'name',
  DESCRIPTION: 'description',
  OVA_ACCOUNTS: 'ovaAccounts',
  BACKUP_PROVIDER: 'backupProvider',
  RECIPIENTS: 'recipients',
  FREQUENCY: 'frequency',
  START_TIME_OF_DATA_RANGE: 'startTimeOfDataRange',
  RANGE_OF_DATA: 'rangeOfData',
  FORMATS: 'formats',
  TIME_RANGE: 'timeRange',
} as const

export const reportLabelMapper = {
  awsExecutiveReport: 'AWS Ransomware Readiness Report',
  awsAccountSummaryReport: 'AWS Account Summary Report',
  awsTenantSummaryReport: 'AWS Operational Summary Report',

  // Detailed Reports
  awsCompletedScansReport: 'AWS All Scans',
  awsScannedAssetsSummaryReport: 'AWS Assets Scanned',
  awsInfectedAssetsReport: 'AWS Infected Assets',
  infectedFilesReport: 'AWS Infected Files',
  failedScansReport: 'AWS Failed Scans',

  // Backup Connector Summary Reports
  ovaMspOperationalReport: 'VMware Operational Report',
  ovaMspExecutiveReport: 'VMware Ransomware Readiness Report',
  ovaMspTenantSummaryReport: 'VMware Operational Summary Report',
  ovaMspAccountSummaryReport: 'VMware Account Summary Report',
  ovaMspCompletedScansReport: 'VMware All Scans',
  ovaMspScannedAssetsSummaryReport: 'VMware Assets Scanned',
  ovaMspInfectedAssetsReport: 'VMware Infected Assets',
  ovaMspInfectedFilesReport: 'VMware Infected Files',
  ovaMspFailedScansReport: 'VMware Failed Scans',
}
export const ovaBackupProviderOptions = [
  {
    label: 'Veeam',
    value: OvaBackupProvider.VEEAM,
  },
  {
    label: 'Commvault',
    value: OvaBackupProvider.COMMVAULT,
  },
  {
    label: 'Rubrik',
    value: OvaBackupProvider.RUBRIK,
  },
  {
    label: 'Cohesity',
    value: OvaBackupProvider.COHESITY,
  },
  {
    label: 'Veritas NetBackup',
    value: OvaBackupProvider.VERITAS_NET_BACKUP,
  },
  {
    label: 'AWS Backup VMware',
    value: OvaBackupProvider.AWS_BACKUP_VMWARE,
  },
]
export const TIME_RANGE_MENU_ITEMS = [
  {
    label: 'Today',
    value: TimeRangeSelection.TODAY,
  },
  {
    label: 'Last 7 days',
    value: TimeRangeSelection.LAST_7_DAYS,
  },
  {
    label: 'Last 30 days',
    value: TimeRangeSelection.LAST_30_DAYS,
  },
  {
    label: 'Custom date',
    value: TimeRangeSelection.CUSTOM_DATE,
  },
]

export const reportPeriodOptions = [
  {
    value: ReportPeriodValue.CURRENT_DAY,
    label: 'Today',
  },
  {
    value: ReportPeriodValue.LAST_DAY,
    label: 'Yesterday',
  },
  {
    value: ReportPeriodValue.CURRENT_WEEK,
    label: 'Current Week',
  },
  {
    value: ReportPeriodValue.LAST_WEEK,
    label: 'Last Week',
  },
  {
    value: ReportPeriodValue.CURRENT_MONTH,
    label: 'Current Month',
  },
  {
    value: ReportPeriodValue.LAST_MONTH,
    label: 'Last Month',
  },
  {
    value: ReportPeriodValue.CURRENT_QUARTER,
    label: 'Current Quarter',
  },
  {
    value: ReportPeriodValue.LAST_QUARTER,
    label: 'Last Quarter',
  },
  {
    value: ReportPeriodValue.CURRENT_YEAR,
    label: 'Current Year',
  },
  {
    value: ReportPeriodValue.LAST_YEAR,
    label: 'Last Year',
  },
]
export const getMonthOptions = () => {
  const currentMonthDay = new Date().getDate()
  const monthlyDay = currentMonthDay > 25 ? 15 : currentMonthDay
  return [
    {
      value: 'Monthly on day 1',
      label: 'Monthly on day 1',
    },
    {
      value: 'Monthly on day ' + monthlyDay,
      label: 'Monthly on day ' + monthlyDay,
    },
  ]
}
export const DaysOptions = [
  {
    label: 'S',
    value: Days.SUNDAY,
  },
  {
    label: 'M',
    value: Days.MONDAY,
  },
  {
    label: 'T',
    value: Days.TUESDAY,
  },
  {
    label: 'W',
    value: Days.WEDNESDAY,
  },
  {
    label: 'T',
    value: Days.THURSDAY,
  },
  {
    label: 'F',
    value: Days.FRIDAY,
  },
  {
    label: 'S',
    value: Days.SATURDAY,
  },
]

export const EndOptions = [
  {
    label: ReportEnd.NEVER,
    value: ReportEnd.NEVER,
  },
  {
    label: ReportEnd.ON,
    value: ReportEnd.ON,
  },
  {
    label: ReportEnd.AFTER,
    value: ReportEnd.AFTER,
  },
]
export const repeatPeriodOptions = [
  {
    label: RepeatPeriod.DAY,
    value: RepeatPeriod.DAY,
  },
  {
    label: RepeatPeriod.WEEK,
    value: RepeatPeriod.WEEK,
  },
  {
    label: RepeatPeriod.MONTH,
    value: RepeatPeriod.MONTH,
  },
  // {
  //   label: RepeatPeriod.YEAR,
  //   value: RepeatPeriod.YEAR,
  // },
]
export const filterRepeatPeriodOptionsForCustom = (
  repeatPeriod: RepeatPeriod | null
) => {
  const optionsMap: Record<RepeatPeriod, Array<ReportPeriodValue>> = {
    [RepeatPeriod.DAY]: [
      ReportPeriodValue.CURRENT_DAY,
      ReportPeriodValue.LAST_DAY,
    ],
    [RepeatPeriod.WEEK]: [
      ReportPeriodValue.CURRENT_WEEK,
      ReportPeriodValue.LAST_WEEK,
    ],
    [RepeatPeriod.MONTH]: [
      ReportPeriodValue.CURRENT_MONTH,
      ReportPeriodValue.LAST_MONTH,
      ReportPeriodValue.CURRENT_QUARTER,
      ReportPeriodValue.LAST_QUARTER,
      ReportPeriodValue.CURRENT_YEAR,
      ReportPeriodValue.LAST_YEAR,
    ],
  }

  return repeatPeriod ? optionsMap[repeatPeriod] : []
}

export const filterReportPeriodOptions = (
  frequencyType: FrequencyType | null,
  repeatPeriodOption: RepeatPeriod | null = null
) => {
  const optionsMap: Record<FrequencyType, Array<ReportPeriodValue>> = {
    [FrequencyType.DAILY]: [
      ReportPeriodValue.CURRENT_DAY,
      ReportPeriodValue.LAST_DAY,
    ],
    [FrequencyType.WEEKLY]: [
      ReportPeriodValue.CURRENT_WEEK,
      ReportPeriodValue.LAST_WEEK,
    ],
    [FrequencyType.MONTHLY]: [
      ReportPeriodValue.CURRENT_MONTH,
      ReportPeriodValue.LAST_MONTH,
      ReportPeriodValue.CURRENT_QUARTER,
      ReportPeriodValue.LAST_QUARTER,
      ReportPeriodValue.CURRENT_YEAR,
      ReportPeriodValue.LAST_YEAR,
    ],
    [FrequencyType.CUSTOM]:
      filterRepeatPeriodOptionsForCustom(repeatPeriodOption),
  }

  const allowedValues = frequencyType ? optionsMap[frequencyType] : []

  return reportPeriodOptions.filter((option) =>
    allowedValues.includes(option.value)
  )
}
export const REPORTS_TAB_SLUG_MAP = {
  OVERVIEW: 'overview',
  SCHEDULES: 'schedules',
  HISTORY: 'history',
}
