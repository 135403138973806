import React from 'react'
import { Box, Typography } from '@mui/material'
import { SelectedJobKindsFilters } from './selected-job-kinds-filters'
import { SelectedJobStatusesFilters } from './selected-job-statuses-filters'

function Header() {
  return (
    <Typography
      variant="caption"
      fontSize="12px"
      fontWeight="700"
      marginBottom="10px"
      sx={{
        color: 'var(--blue-gray)',
      }}
    >
      Selected Filters
    </Typography>
  )
}

export function SelectedFilters() {
  return (
    <Box
      width="240px"
      display="flex"
      height="100%"
      flexDirection="column"
      padding="0 20px 20px 20px"
    >
      <Header />
      <Box display="flex" flexDirection="column" gap="10px" overflow="auto">
        <SelectedJobKindsFilters />
        <SelectedJobStatusesFilters />
      </Box>
    </Box>
  )
}
