import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import SessionInfo from '@components-composite/session-info/SessionInfo'
import PaginationBlock from '@components-simple/pagination-block/PaginationBlock'
import React from 'react'
import useLocalModelPagination from '@lib/hooks/pagination/useLocalModelPagination'
import SessionModel from '@lib/models/session.model'
import { useSelector } from 'react-redux'
import { getSessions } from '@store/selectors/user.selector'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { useCustomFlags } from '@components-context/feature-flags/use-custom-flags'

function TitleBlock() {
  return (
    <h4 className="subtitle">
      Sessions
      <span className="subtitleDesc">Last 2 days</span>
    </h4>
  )
}

function SessionsList({
  sessions,
  firstSessionId,
}: {
  sessions: Array<SessionModel>
  firstSessionId: number
}) {
  return (
    <ul className="lpContent jsSessionsList">
      {sessions.map((session) => (
        <li key={session.id}>
          <SessionInfo
            userAgent={session.agent}
            dateStartSession={session.date.toISOString()}
            location={session.location}
            activeStatus={session.id === firstSessionId}
            device={session.device}
            ip={session.ip}
          />
        </li>
      ))}
    </ul>
  )
}

const getFirstSessionId = (sessions: Array<SessionModel>): number =>
  sessions.length > 0 ? Number(sessions[0]?.id ?? 0) : -1

function Sessions() {
  const { auth0Spa } = useCustomFlags()
  const requestSessionsLoading = usePreloader(
    PreloaderConstants.REQUEST_SESSIONS
  )
  const sessions: Array<SessionModel> = useSelector(getSessions)

  const firstSessionId = getFirstSessionId(sessions)

  const { paginatedData, pagination, setPagination } = useLocalModelPagination<
    Array<SessionModel>
  >(sessions, 0, 10)

  if (auth0Spa) {
    return null
  }

  if (requestSessionsLoading && sessions.length === 0) {
    return (
      <>
        <TitleBlock />
        <div className="relative">
          <PreloaderBlock show />
        </div>
      </>
    )
  }

  return (
    <>
      <TitleBlock />
      <div className="relative">
        <SessionsList
          sessions={paginatedData}
          firstSessionId={firstSessionId}
        />
        <PaginationBlock pagination={pagination} onChange={setPagination} />
      </div>
    </>
  )
}

export default Sessions
