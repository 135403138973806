/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import { getHumanReadableTenantJobKind } from '@features/jobs/tenant-jobs/helpers'
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { BaseSelectedChip } from './base-selected-chip'

export function TenantJobKindChip({
  kind,
  onDelete,
}: {
  kind: TenantJobKind
  onDelete: (kind: TenantJobKind) => void
}) {
  const handleRemoveFilter = useCallback(() => {
    onDelete(kind)
  }, [])

  return (
    <BaseSelectedChip
      filterName="Job Kind"
      filterValue={getHumanReadableTenantJobKind(kind)}
      onDelete={handleRemoveFilter}
    />
  )
}
