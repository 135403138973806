/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react'
import { CoverageStats } from '@features/DashboardV4/widgets-with-scale/coverage-stats'
import { WidgetHeader } from '@features/DashboardV4/common'
import { RpoStatsWgt } from '@features/DashboardV4/widgets-with-scale/rpo'
import { RetentionStatsWgt } from '@features/DashboardV4/widgets-with-scale/retention'
import { Box, Divider } from '@mui/material'
import { useBackupCoverageStats } from '../useBackupCoverageStats'
import { useScanCoverageStats } from '../useScanCoverageStats'
import { InfoBox } from '@features/DashboardV4/widgets-with-scale'
import { useFetchAwsRpoAndRetention } from '../use-fetch-aws-rpo-and-retention'
import { useFetchStorageReductionMetrics } from '@features/DashboardV4/views/use-fetch-storage-reduction-metrics'

function OverviewRightColumn() {
  const { isLoading, retentionStats, rpoStats } = useFetchAwsRpoAndRetention()
  const { isLoading: isLoadingBackupCoverage, backupCoverageStats } =
    useBackupCoverageStats()
  const {
    isLoading: isLoadingStorageReduction,
    storageReductionInfo,
    optimizedSizeInfo,
  } = useFetchStorageReductionMetrics()

  const { scanCoverageStats, isLoading: isLoadingScanCoverage } =
    useScanCoverageStats()

  return (
    <Box>
      <Box display="flex">
        <Box width="50%">
          <WidgetHeader title="Backup Coverage" />
          <CoverageStats
            statisticsRows={backupCoverageStats}
            isLoading={isLoadingBackupCoverage}
          />
        </Box>
        <Divider orientation="vertical" flexItem sx={{ mx: '8px' }} />
        <Box width="50%" data-testid="scan-coverage-block">
          <WidgetHeader title="Scan Coverage" />
          <CoverageStats
            statisticsRows={scanCoverageStats}
            isLoading={isLoadingScanCoverage}
          />
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box display="flex">
        <InfoBox
          mainText={storageReductionInfo.value}
          subText={storageReductionInfo.label}
          isLoading={isLoadingStorageReduction}
        />
        <Divider orientation="vertical" flexItem sx={{ mx: '8px' }} />
        <InfoBox
          mainText={optimizedSizeInfo.value}
          subText={optimizedSizeInfo.label}
          isLoading={isLoadingStorageReduction}
        />
      </Box>
      <Divider sx={{ my: 3 }} />
      <RpoStatsWgt rpoStats={rpoStats} isLoading={isLoading} />
      <Divider sx={{ my: 3 }} />
      <RetentionStatsWgt
        retentionStats={retentionStats}
        isLoading={isLoading}
      />
    </Box>
  )
}

export default memo(OverviewRightColumn)
