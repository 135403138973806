import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
  widgetTitle: string
}

const BG_COLOR = 'var(--grey50)'

function WidgetHeader({ widgetTitle }: Props) {
  return (
    <Box bgcolor={BG_COLOR} paddingX="16px" paddingY="8px" borderRadius="12px">
      <Typography fontWeight="600" fontSize="16px">
        {widgetTitle}
      </Typography>
    </Box>
  )
}

export default WidgetHeader
