import { useState } from 'react'
import FormRestoreInterface from '@lib/interfaces/form/form-restore.interface'
import { pechkinClient } from '@lib/clients/pechkin'
import { Nullable } from '@lib/engine-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ElastioRecoveryPoint } from 'blues-corejs/dist'

type UseRestoreScheduleOutput = {
  restoreSchedule: (formValues: FormRestoreInterface) => void
  isRestoreInProgress: boolean
}
function useRestoreSchedule(
  backup: Nullable<ElastioRecoveryPoint>
): UseRestoreScheduleOutput {
  const [isRestoreInProgress, setIsRestoreInProgress] = useState(false)

  const restoreSchedule = async (formValues: FormRestoreInterface) => {
    if (!backup) {
      return
    }
    setIsRestoreInProgress(true)
    try {
      await pechkinClient.scheduleRestoreEC2({
        securityGroupIdsList: formValues.securityGroupIdsList,
        ccRpId: backup.ccRpId,
        ccId: backup.ccId,
        ccVaultId: backup.vaultName,
        subnetId: formValues.subnet,
        tags: formValues.tags,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setIsRestoreInProgress(false)
    }
  }

  return {
    isRestoreInProgress,
    restoreSchedule,
  }
}

export { useRestoreSchedule }
