// @ts-nocheck
import React, { memo } from 'react'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import FrequencyConstant from '@lib/constants/data/time/frequency.constant'
import { EngineCallback } from '@lib/engine-types'
import ScheduleInterface from '@lib/interfaces/schedule.interface'
import DefaultTimePicker from '@components-simple/default-time-picker/DefaultTimePicker'
import DayOfWeekConstant from '@lib/constants/data/time/day-of-week.constant'
import DayInMonthSelector from '@components-simple/day-in-month-selector/DayInMonthSelector'
import ValueInterface from '@lib/interfaces/value.interface'
import FirstRun from '@components-composite/first-run/FirstRun'
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select'
import Box from '@mui/material/Box'

function determineShowWindowInputs(frequency: ValueInterface): boolean {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_EVERY_15_MIN.value:
    case FrequencyConstant.FREQUENCY_EVERY_30_MIN.value:
    case FrequencyConstant.FREQUENCY_EVERY_HOUR.value:
      return true

    default:
      return false
  }
}

function determineShowOnlyStart(frequency: ValueInterface): boolean {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_EVERY_12_HOURS.value:
    case FrequencyConstant.FREQUENCY_DAILY.value:
      return true

    default:
      return false
  }
}

function determineShowDayOfWeekInput(frequency: ValueInterface) {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_WEEKLY.value:
      return true

    default:
      return false
  }
}

function determineShowDayOfMonthInput(frequency: ValueInterface) {
  switch (frequency.value) {
    case FrequencyConstant.FREQUENCY_MONTHLY.value:
      return true

    default:
      return false
  }
}

const minuteStep = 15

interface Props {
  data: ScheduleInterface
  onChange: EngineCallback<ScheduleInterface>
  timeZone: string
  handleTimezoneSelectChange: (v: ITimezoneOption) => void
}

const TIME_ZONE_LABEL = 'Select time zone'

function Schedule({
  data,
  onChange,
  timeZone,
  handleTimezoneSelectChange,
}: Props) {
  const showWindowInputs = determineShowWindowInputs(data.frequency)
  const showDayOfWeekInput = determineShowDayOfWeekInput(data.frequency)
  const showDayOfMonthInput = determineShowDayOfMonthInput(data.frequency)
  const showOnlyStart = determineShowOnlyStart(data.frequency)

  const optionalWrapClass = showWindowInputs
    ? 'showWindowInputs'
    : showDayOfWeekInput
      ? 'showDayOfWeekInput'
      : showDayOfMonthInput
        ? 'showDayOfMonthInput'
        : ''

  return (
    <div className="wrap-1641476731313 jsSchedule">
      <div className="addPolicyTitle">Schedule details</div>
      <div className="policiesBlockLabel">
        Define the schedule for the policy.
      </div>

      <div className={`scheduleOptionalWrap ${optionalWrapClass}`}>
        <ViSelect
          possible={FrequencyConstant.getAllValid()}
          selected={data.frequency}
          onChange={(frequency) =>
            onChange({
              ...data,
              frequency,
              windowStart: {
                ...data.windowStart,
                timezone: timeZone,
              },
              windowEnd: {
                ...data.windowEnd,
                timezone: timeZone,
              },
            })
          }
          label="Every"
        />

        {showWindowInputs && (
          <>
            <DefaultTimePicker
              minuteStep={minuteStep}
              data={data.windowStart}
              onChange={(windowStart) =>
                onChange({
                  ...data,
                  windowStart: {
                    ...windowStart,
                    timezone: timeZone,
                  },
                  windowEnd: {
                    ...data.windowEnd,
                    timezone: timeZone,
                  },
                })
              }
              label="Protection window start"
            />
            <DefaultTimePicker
              data={data.windowEnd}
              minuteStep={minuteStep}
              onChange={(windowEnd) =>
                onChange({
                  ...data,
                  windowStart: {
                    ...data.windowStart,
                    timezone: timeZone,
                  },
                  windowEnd: {
                    ...windowEnd,
                    timezone: timeZone,
                  },
                })
              }
              label="Protection window end"
            />
          </>
        )}

        {showOnlyStart && (
          <DefaultTimePicker
            minuteStep={minuteStep}
            data={data.windowStart}
            onChange={(windowStart) =>
              onChange({
                ...data,
                windowStart: {
                  ...windowStart,
                  timezone: timeZone,
                },
              })
            }
            label="Protection window start"
          />
        )}

        {showDayOfWeekInput && (
          <>
            <ViSelect
              possible={DayOfWeekConstant.getAllValid()}
              selected={data.dayOfWeek}
              onChange={(dayOfWeek) =>
                onChange({
                  ...data,
                  dayOfWeek,
                })
              }
              label="Select day to backup"
            />
            <DefaultTimePicker
              minuteStep={minuteStep}
              data={data.windowStart}
              onChange={(windowStart) =>
                onChange({
                  ...data,
                  windowStart: {
                    ...windowStart,
                    timezone: timeZone,
                  },
                })
              }
              label="Protection window start"
            />
          </>
        )}

        {showDayOfMonthInput && (
          <>
            <DayInMonthSelector
              value={data.dayOfMonth}
              onChange={(dayOfMonth) =>
                onChange({
                  ...data,
                  dayOfMonth,
                })
              }
              label="On"
            />
            <DefaultTimePicker
              minuteStep={minuteStep}
              data={data.windowStart}
              onChange={(windowStart) =>
                onChange({
                  ...data,
                  windowStart: {
                    ...windowStart,
                    timezone: timeZone,
                  },
                })
              }
              label="Protection window start"
            />
          </>
        )}
        <Box className="wrap-1605102427226" flexGrow={1} maxWidth={500}>
          <Box className="timePickerLabel">{TIME_ZONE_LABEL}</Box>
          <TimezoneSelect
            className="timezone-select"
            value={timeZone}
            onChange={(tz) => {
              handleTimezoneSelectChange(tz)
              onChange({
                ...data,
                windowStart: {
                  ...data.windowStart,
                  timezone: tz.value,
                },
                windowEnd: {
                  ...data.windowEnd,
                  timezone: tz.value,
                },
              })
            }}
          />
        </Box>
      </div>

      <FirstRun
        data={data.firstRun}
        onChange={(firstRun) =>
          onChange({
            ...data,
            firstRun,
          })
        }
      />
    </div>
  )
}

export default memo(Schedule)
