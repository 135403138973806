import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { TOOLBAR_HEIGHT } from 'ui-v2/src/lib/constants/ui.constant'

// Color systems
import { lightColorSystem } from 'ui-v2/src/theme/light-theme'
import { darkColorSystem } from 'ui-v2/src/theme/dark-theme'

// // Roboto Font
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  let theme = createTheme({
    colorSchemes: {
      dark: darkColorSystem,
      light: lightColorSystem,
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
            variants: [
              {
                props: { variant: 'outlined' },
                style: {
                  borderRadius: '50px',
                },
              },
            ],
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: TOOLBAR_HEIGHT,
            height: TOOLBAR_HEIGHT,
            '@media (min-width:600px)': {
              minHeight: TOOLBAR_HEIGHT,
              height: TOOLBAR_HEIGHT,
            },
          },
        },
      },
    },
  })

  theme = createTheme(theme, {
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: '2.25rem',
            '& .MuiTab-root': {
              minHeight: '2.25rem',
              padding: 0,
            },
          },
          indicator: {
            backgroundColor: theme.palette.accent.main,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            '&.MuiButtonBase-root': {
              padding: 0,
              minWidth: 'auto',
              marginRight: '40px',
              fontSize: '14px',
              fontWeight: 600,
              color: theme.palette.text.muted,
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            maxHeight: '70vh',
            overflowY: 'auto',

            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(128, 128, 128, 0.5)',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(128, 128, 128, 0.7)',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            height: '40px',
            padding: '0rem 0.75rem',
            fontSize: '14px',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-body': {
              borderColor: 'rgba(204, 204, 220, 0.12)',
            },
          },
        },
      },
      MuiInputBase: {
        variants: [
          {
            props: { size: 'xs' },
            style: {
              fontSize: '0.875rem',
              padding: '0.375rem 0rem',
              height: '2.25rem',
            },
          },
        ],
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
          },
          select: {
            fontSize: '14px',
            fontWeight: 400,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
          },
          select: {
            backgroundColor: theme.palette.background,
            borderColor: theme.palette.background,
            color: theme.palette.background,
            fontSize: '14px',
            fontWeight: 400,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: '4px',
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: theme.palette.background.secondary,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.MuiInputLabel-sizeXs': {
              transform: 'translate(14px, 8px) scale(1)',
            },
          },
          shrink: {
            '&.MuiInputLabel-sizeXs': {
              transform: 'translate(13px, -7.5px) scale(0.85)',
            },
          },
        },
        variants: [
          {
            props: { size: 'xs' },
            style: {
              fontSize: '0.875rem',
              transformOrigin: 'top left',
            },
          },
        ],
      },
    },
  })

  return (
    <MUIThemeProvider theme={theme} disableTransitionOnChange noSsr>
      <CssBaseline /> {/* Apply global CSS baseline styles */}
      {children}
    </MUIThemeProvider>
  )
}

export default ThemeProvider

interface ThemeProviderProps {
  children: React.ReactNode
}
