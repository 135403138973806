import React from 'react'
import { Container, Typography, Button } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useNavigate } from 'react-router-dom'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '80vh',
      }}
    >
      <ErrorOutlineIcon
        sx={{
          fontSize: 100,
          color: 'error.main',
          marginBottom: 2,
        }}
      />
      <Typography variant="h3" component="h1" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="h6" color="text.secondary">
        Oops! The page you're looking for doesn't exist or has been moved.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => navigate(`/${BASE_ROUTE_SEGMENTS.DASHBOARD}`)}
        sx={{
          marginTop: 2,
          textTransform: 'capitalize',
        }}
      >
        Go to Dashboard
      </Button>
    </Container>
  )
}

export default NotFoundPage
