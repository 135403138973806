import { BaseValidator } from './base.validator'

export class ForbiddenCharactersValidator extends BaseValidator {
  private validationField: string

  constructor(value: string, validationField: string) {
    super(value)

    this.validationField = validationField
  }

  validate() {
    return new RegExp(
      /^(?!\s)(?!.*\s$)[A-Za-z0-9\-=\._:@][A-Za-z0-9\+\-=\._:@\s]*$/
    ).test(this.value)
  }

  get errorMessage(): string {
    return `${this.validationField} can only contain alphanumeric characters, spaces and +-._:/@.`
  }
}
