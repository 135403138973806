import { create } from 'zustand'

type FilterStore = {
  ruleSearch: string
  setRuleValue: (rule: string) => void
}

export const useAlertsFilterStore = create<FilterStore>((set) => ({
  ruleSearch: '',
  setRuleValue: (ruleSearch: string) =>
    set((state) => ({
      ...state,
      ruleSearch,
    })),
}))
