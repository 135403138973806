/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { ChooseAccountRegionStep } from '@components-complex/add-policy-complex/choose-account-region-step'
import DataHelper from '@lib/helpers/data.helper'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { SelectedCloudConnectorVariant } from '@lib/constants/policies.constant'
import { VIRow } from '@lib/engine-types'
import { ObjHelper } from '@lib/helpers'
import { getSelectedAssetsSelect } from '@store/selectors/assets-select.selector'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { setAssetsSelectFilters } from '@store/actions/assets-select.action'
import {
  getEditPolicySelectedCloudConnectors,
  getEditPolicySelectedCloudConnectorVariant,
  getEditPolicyVaultsList,
} from '@store/selectors/edit-policy.selector'
import {
  setEditPolicyVaultList,
  setEditSelectedCloudConnectors,
  setEditSelectedCloudConnectorsVariant,
} from '@store/actions/edit-policy.action'
import { defaultSelectedFilters } from '@store/reducers/assets-select.reducer'

interface Props {
  isLoading: boolean
  isAssetsVariant: boolean
  onRemoveCloudConnector: ({
    ccList,
    rsId,
  }: {
    ccList: VIRow
    rsId: string
  }) => void
}

export function CloudConnectorsSelection({
  isLoading,
  isAssetsVariant,
  onRemoveCloudConnector,
}: Props) {
  const dispatch = useDispatch()
  const selectedCloudConnectorVariant = useSelector(
    getEditPolicySelectedCloudConnectorVariant
  )
  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const selectedCloudConnectors = useSelector(
    getEditPolicySelectedCloudConnectors
  )
  const selectedAssets = useSelector(getSelectedAssetsSelect)

  const selectedVaultsName = useSelector(getEditPolicyVaultsList)

  const accountRegionPairsList =
    DataHelper.accountRegionPairsList(allActiveRedStacks)

  const handleCloudConnectorVariantChange = (newTabIndex: number) => {
    dispatch(setEditSelectedCloudConnectors([]))
    dispatch(setAssetsSelectFilters(defaultSelectedFilters()))
    dispatch(
      setEditSelectedCloudConnectorsVariant(
        newTabIndex as SelectedCloudConnectorVariant
      )
    )
  }

  const handleSelectCloudConnectors = (value: VIRow) => {
    const currentSelectedCloudConnectors = ObjHelper.cloneDeep(
      selectedCloudConnectors
    )
    // remove Cloud Connectors from list
    if (currentSelectedCloudConnectors.length > value.length) {
      currentSelectedCloudConnectors.map((item) => {
        if (!value.find((v) => v.name === item.name) || value.length === 0) {
          const deletedRs = allActiveRedStacks.find(
            (rs) =>
              rs.awsAccount === item.value && rs.awsRegion === item.extraValue
          )
          if (deletedRs) {
            const index = selectedVaultsName.findIndex(
              (v) => v.redStackId === deletedRs.redStackId
            )

            // remove vaults from deleted Cloud Connector
            if (index !== -1) {
              selectedVaultsName.splice(index, 1)
              dispatch(setEditPolicyVaultList([...selectedVaultsName]))
            }

            // remove selected assets from deleted Cloud Connector
            if (isAssetsVariant && selectedAssets.length > 0) {
              const assetsShouldBeRemoved = selectedAssets.filter(
                (v: AssetWithRelatedAssets<Asset>) =>
                  v.asset?.awsRegion === item.extraValue &&
                  v.asset?.awsAccountId === item.value
              )
              if (assetsShouldBeRemoved.length > 0) {
                onRemoveCloudConnector({
                  ccList: value,
                  rsId: deletedRs.redStackId,
                })
              } else {
                dispatch(setEditSelectedCloudConnectors(value))
              }
            } else {
              dispatch(setEditSelectedCloudConnectors(value))
            }
          }
        }
      })
    } else {
      dispatch(setEditSelectedCloudConnectors(value))
    }
  }

  return (
    <Box>
      <ChooseAccountRegionStep
        selectedVariant={selectedCloudConnectorVariant}
        selectedCloudConnectors={selectedCloudConnectors}
        possibleCloudConnectors={accountRegionPairsList}
        onCloudConnectorVariantChange={handleCloudConnectorVariantChange}
        onSelectCloudConnectors={handleSelectCloudConnectors}
        loading={isLoading}
      />
    </Box>
  )
}
