import React from 'react'
import { Box } from '@mui/material'
import SearchForm from '@forms/search/search.form'
import { useModalFilterActions } from '@features/jobs/tenant-jobs/deployment-jobs/filters-modal/deployment-filters-store'
import { JobKindsSection } from './job-kinds-section'
import { JobStatusesSection } from './job-statuses-section'

export function FiltersSelection() {
  const { setFilterSearchValue } = useModalFilterActions()

  function handleSearchChange(searchValue: string) {
    setFilterSearchValue(searchValue)
  }

  return (
    <Box bgcolor="#f2f6fc" padding="20px 12px 50px 12px" width="100%">
      <SearchForm
        placeholder="Search filters..."
        variant="whiteFilterSearch"
        key="search"
        defaultValues={{ search: '' }}
        onSubmit={({ search }) => {
          handleSearchChange(search.toLowerCase())
        }}
      />
      <Box marginTop="15px" overflow="auto" height="100%" paddingBottom="20px">
        <JobKindsSection />
        <JobStatusesSection />
      </Box>
    </Box>
  )
}
