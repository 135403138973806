export default class EnumHelper {
  static getNumericEnumValues(enumObject: object): Array<number> {
    return Object.values(enumObject).filter(Number.isInteger)
  }

  static createEnumTypeGuard<T extends number>(enumObject: object) {
    const validValues = this.getNumericEnumValues(enumObject)

    return function isValidEnumValue(value: unknown): value is T {
      return validValues.includes(value as T)
    }
  }

  static filterToEnumValues(values: Array<unknown>, enumObject: object) {
    const isValidValue = this.createEnumTypeGuard(enumObject)
    return values.map(Number).filter(isValidValue)
  }
}
