/* eslint-disable import/no-extraneous-dependencies */
import { ThreatScanProto } from 'ui-v2/src/lib/grpc'
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { Threat } from 'blues-corejs/dist'
import {
  Source,
  ThreatKind,
  ThreatStatus,
} from 'blues-corejs/dist/models/threats/types'

type ThreatPt = ThreatScanProto.AsObject

export class ThreatTransformer {
  #threat: ThreatPt

  constructor(threat: ThreatPt) {
    this.#threat = threat
  }

  transform(): Threat {
    return this.#transformThreat(this.#threat)
  }

  #transformThreat(threat: ThreatPt): Threat {
    return new Threat({
      id: threat.id,
      name: threat.name,
      location: threat.location,
      scanIds: threat.scanIdsList,
      kind: this.#convertKind(threat.kind),
      status: this.#convertStatus(threat.status),
      source: this.#convertSource(
        threat.source as ThreatScanProto.Source.AsObject
      ),
      createdAt: secondsToDate(threat.createdAt?.seconds),
      firstDetectedAt: secondsToDate(threat.firstDetectedAt?.seconds),
      updatedAt: secondsToDate(threat.updatedAt?.seconds),
      resolvedAt: secondsToDate(threat.resolvedAt?.seconds),
      ignoredAt: secondsToDate(threat.ignoredAt?.seconds),
    })
  }

  #convertSource(protobufSource: ThreatScanProto.Source.AsObject): Source {
    const { asset, assetItem } = protobufSource

    const source: Partial<Source> = {}

    const assetId = asset?.id

    const assetItemId = assetItem?.id

    if (assetItemId) {
      source.assetItem = {
        assetId: assetItem.assetId,
        assetItemId,
      }
    }

    if (assetId) {
      source.asset = {
        assetId,
      }
    }

    return source as Source
  }

  #convertStatus(status: ThreatScanProto.Status): ThreatStatus {
    const mapping = {
      [ThreatScanProto.Status.STATUS_DETECTED]: ThreatStatus.DETECTED,
      [ThreatScanProto.Status.STATUS_RESOLVED]: ThreatStatus.RESOLVED,
      [ThreatScanProto.Status.STATUS_IGNORED]: ThreatStatus.IGNORED,
    }

    return mapping[status]
  }

  #convertKind(kind: ThreatScanProto.Kind): ThreatKind {
    const mapping = {
      [ThreatScanProto.Kind.KIND_RANSOMWARE]: ThreatKind.RANSOMWARE,
      [ThreatScanProto.Kind.KIND_MALWARE]: ThreatKind.MALWARE,
      [ThreatScanProto.Kind.KIND_FILESYSTEM_ERROR]: ThreatKind.FILESYSTEM_ERROR,
    }

    return mapping[kind]
  }
}
