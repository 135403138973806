import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { UsersClient } from 'ui-v2/src/lib/clients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useInviteUserMutation() {
  const usersClient = useGrpcClient(UsersClient)
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async ({
      userEmail,
      roleId,
    }: {
      userEmail: string
      roleId?: string
    }) => {
      if (!usersClient) {
        throw new Error('Users client not initialized')
      }
      return usersClient.inviteUser(userEmail, roleId)
    },
    onError: (error) => {
      toast.error(error.message ?? 'Something went wrong while inviting user')
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
      toast.success(data.message ?? 'User has been activated')
    },
  })
}
