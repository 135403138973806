/* eslint-disable import/no-extraneous-dependencies */
import { RiskLevel, VulnerabilitiesDetectionStrategy } from 'blues-corejs/dist'
import {
  AwsSnapshotVulnerability,
  AwsVolumeVulnerability,
} from '@lib/clients/aws-statistics/types'

export interface VulnerabilityTableDataTypes {
  kind: string
  total: number
  riskLevel: RiskLevel
}

export function prepareVulnerabilityDataForTable({
  volumeVulnerabilitiesList,
  snapshotVulnerabilitiesList,
}: {
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
}): Array<VulnerabilityTableDataTypes> {
  const summaryVulnerabilities = [
    ...volumeVulnerabilitiesList,
    ...snapshotVulnerabilitiesList,
  ]

  return summaryVulnerabilities
    .map((vulnerability) => {
      const riskLevel =
        new VulnerabilitiesDetectionStrategy().identifyRiskLevel(
          vulnerability.kind
        )
      return {
        ...vulnerability,
        riskLevel,
      }
    })
    .sort((a, b) => b.riskLevel - a.riskLevel)
}

export function calculateTotalVulnerabilitiesByRisk({
  volumeVulnerabilitiesList,
  snapshotVulnerabilitiesList,
}: {
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
}) {
  const summaryVulnerabilities = [
    ...volumeVulnerabilitiesList,
    ...snapshotVulnerabilitiesList,
  ]

  return summaryVulnerabilities.reduce(
    (total, vulnerability) => {
      const riskLevel =
        new VulnerabilitiesDetectionStrategy().identifyRiskLevel(
          vulnerability.kind
        )

      switch (riskLevel) {
        case RiskLevel.High:
          return {
            ...total,
            high: total.high + vulnerability.total,
          }
        case RiskLevel.Medium:
          return {
            ...total,
            medium: total.medium + vulnerability.total,
          }
        case RiskLevel.Low:
          return {
            ...total,
            low: total.low + vulnerability.total,
          }
        default:
          return total
      }
    },
    {
      high: 0,
      medium: 0,
      low: 0,
    }
  )
}

export function renderStatusRisk(risk: RiskLevel) {
  switch (risk) {
    case RiskLevel.High:
      return 'High'
    case RiskLevel.Medium:
      return 'Medium'
    case RiskLevel.Low:
      return 'Low'
  }
}
