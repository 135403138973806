export interface SelectOption {
  label: string
  value: string
}

export const transformEventKindsToSelectOptions = (
  eventKinds: Array<string> | null
): Array<SelectOption> => {
  if (!eventKinds) {
    return []
  }

  return eventKinds.map((eventKind) => ({
    label: eventKind,
    value: eventKind,
  }))
}
