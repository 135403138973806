/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'
import { Typography } from '@mui/material'
import { computeScanType } from '../helpers'

interface Props {
  scans: ScansRun
}

export function ScanTypeCell({ scans }: Props) {
  const computedScanType = computeScanType(scans)

  return (
    <Typography variant="caption" fontSize="14px">
      {computedScanType}
    </Typography>
  )
}
