import React, { useMemo, useState } from 'react'
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import ElastioLogo from 'ui-v2/src/assets/logo/elastio-logo'
import ThemeSwitcher from '../common/theme-switcher'
import SettingsActive from 'ui-v2/src/assets/icons/navbar/settings-active'

//Nav icons
import Dashboard from 'ui-v2/src/assets/icons/navbar/dashboard'
import DashboardActive from 'ui-v2/src/assets/icons/navbar/dashboard-active'
import Assets from '../../assets/icons/navbar/assets'
import AssetsActive from 'ui-v2/src/assets/icons/navbar/assets-active'
import Monitor from 'ui-v2/src/assets/icons/navbar/monitor'
import MonitorActive from 'ui-v2/src/assets/icons/navbar/monitor-active'
import Reports from 'ui-v2/src/assets/icons/navbar/reports'
import ReportsActive from 'ui-v2/src/assets/icons/navbar/reports-active'
import Settings from 'ui-v2/src/assets/icons/navbar/settings'
import Navbar from './navbar'
import {
  DRAWER_WIDTH,
  MOBILE_DRAWER_WIDTH,
  TOOLBAR_HEIGHT,
} from 'ui-v2/src/lib/constants/ui.constant'
import { BASE_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'
import useAppInitializer from 'ui-v2/src/hooks/app-initializer'
import SiteLoader from '../common/site-loader'

const AppShell = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)
  const { isLoading: isInitializingApp } = useAppInitializer()

  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen((prevState) => !prevState)
  }

  const menuItems: Array<{
    text: string
    icon: React.ReactElement
    activeIcon: React.ReactElement
    path: string
  }> = useMemo(
    () => [
      {
        text: 'Dashboard',
        icon: <Dashboard color="icon" />,
        activeIcon: <DashboardActive color="accent" />,
        path: BASE_ROUTE_SEGMENTS.DASHBOARD,
      },
      {
        text: 'Assets',
        icon: <Assets color="icon" />,
        activeIcon: <AssetsActive color="accent" />,
        path: BASE_ROUTE_SEGMENTS.ASSETS,
      },
      {
        text: 'Monitor',
        icon: <Monitor color="icon" />,
        activeIcon: <MonitorActive color="accent" />,
        path: BASE_ROUTE_SEGMENTS.MONITOR,
      },
      {
        text: 'Reports',
        icon: <Reports color="icon" />,
        activeIcon: <ReportsActive color="accent" />,
        path: BASE_ROUTE_SEGMENTS.REPORTS,
      },
      {
        text: 'Settings',
        icon: <Settings color="icon" />,
        activeIcon: <SettingsActive color="accent" />,
        path: BASE_ROUTE_SEGMENTS.SETTINGS,
      },
    ],
    []
  )

  const drawerContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: '1rem',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          pb: '1rem',
        }}
      >
        <ElastioLogo />
      </Box>
      <List
        sx={{
          flex: 1,
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.75rem',
        }}
      >
        {menuItems.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '0.25rem',
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&:hover .MuiListItemIcon-root': {
                  backgroundColor: theme.palette.background.hover,
                },
              })}
              role="menuitem"
              aria-label={`Navigate to ${item.text}`}
            >
              <ListItemIcon
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '50px',
                  py: '0.25rem',
                  transition: 'background 150ms',
                }}
              >
                {pathname.split('/')[1] === item.path
                  ? item.activeIcon
                  : item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                slotProps={{
                  primary: {
                    variant: 'caption',
                    color: 'text.secondary',
                  },
                }}
                sx={{ my: 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ThemeSwitcher />
    </Box>
  )

  const mobileDrawerContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: '1rem',
        height: '100%',
      }}
    >
      <List sx={{ flex: 1 }}>
        {menuItems.map((item) => (
          <Link key={item.path} to={item.path}>
            <ListItem key={item.text}>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <ThemeSwitcher />
    </Box>
  )

  if (isInitializingApp) {
    return <SiteLoader />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Navbar */}
      <Navbar toggleMobileDrawer={toggleMobileDrawer} />

      {/* Sidebar */}
      <Box
        component="nav"
        sx={{
          width: { sm: DRAWER_WIDTH },
          flexShrink: { sm: 0 },
        }}
      >
        {/* Mobile drawer */}
        <Drawer
          variant="temporary"
          open={isMobileDrawerOpen}
          onClose={toggleMobileDrawer}
          ModalProps={{
            keepMounted: true, // Better mobile performance
          }}
          sx={(theme) => ({
            display: {
              xs: 'block',
              sm: 'none',
            },

            '& .MuiDrawer-paper': {
              background: theme.palette.background.secondary,
              width: MOBILE_DRAWER_WIDTH,
            },
          })}
        >
          {mobileDrawerContent}
        </Drawer>

        {/* Desktop drawer */}
        <Drawer
          variant="permanent"
          sx={(theme) => ({
            display: {
              xs: 'none',
              sm: 'block',
            },

            '& .MuiDrawer-paper': {
              background: theme.palette.background.secondary,
              width: DRAWER_WIDTH,
              border: 0,
            },
          })}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: {
            xs: 2,
            sm: 1.5,
          },
          width: { sm: `calc(100% - ${DRAWER_WIDTH})` },
          marginTop: TOOLBAR_HEIGHT,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default AppShell
