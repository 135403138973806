import { createContext, useContext } from 'react'
import { useStore } from 'zustand'
import type { EntropyFiltersZustandStore } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store'
import { EntropyFiltersState } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store'
import { useShallow } from 'zustand/react/shallow'

export const EntropyFiltersContext =
  createContext<EntropyFiltersZustandStore | null>(null)

export function useEntropyFiltersActions() {
  const store = useContext(EntropyFiltersContext)
  if (!store) {
    throw new Error('Missing EntropyFiltersContext.Provider in the tree')
  }
  return store.getState().actions
}

function useEntropyFiltersContextWithSelector<T>(
  selector: (state: EntropyFiltersState) => T
): T {
  const store = useContext(EntropyFiltersContext)
  if (!store) {
    throw new Error('Missing EntropyFiltersContext.Provider in the tree')
  }
  return useStore(store, selector)
}

export const useGetPossibleFilters = () =>
  useEntropyFiltersContextWithSelector(
    useShallow((state) => state.possibleFilters)
  )

export const useGetSelectedFilters = () =>
  useEntropyFiltersContextWithSelector(
    useShallow((state) => state.selectedFilters)
  )

export const useGetEntropyHistory = () =>
  useEntropyFiltersContextWithSelector((state) => state.entropyTrendsHistory)

export const useGetEntropyFiltersLoading = () =>
  useEntropyFiltersContextWithSelector((state) => state.isLoading)

export const useGetEntropyFiltersTimeSelection = () =>
  useEntropyFiltersContextWithSelector((state) => {
    return {
      timeSelection: state.timeSelection,
      timeGranularity: state.timeGranularity,
    }
  })
