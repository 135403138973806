import React, { useState, MouseEvent } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Autocomplete, {
  AutocompleteCloseReason,
} from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {
  StyledPopper,
  StyledAutocompletePopper,
  StyledButton,
  StyledInput,
  Root,
} from './styles'
import { renderIconByState } from './render-icon-by-state'
import { useToggle } from '@lib/hooks'

interface PopperComponentProps {
  anchorEl?: any
  disablePortal?: boolean
  open: boolean
}

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

/**
 * Sorts and displays the selected options first in the MUI Autocomplete component.
 * We can use the `options` prop to provide the sorted data.
 */

export interface OptionType {
  value: string | number
  label: string
}

interface MultiSelectSearchOwnProps {
  options: Array<OptionType>
  selectedOptions?: Array<OptionType>
  placeholder?: string
  getDisplayText?: (valueLength: number, tagsTitle: string) => string
  onDone?: (value: Array<OptionType>) => void
  tagsTitle: string
}

const getCustomDisplayText = (valueLength: number, tagsTitle: string) => {
  if (valueLength > 1) {
    return `${valueLength} ${tagsTitle}s`
  } else if (valueLength === 1) {
    return `${valueLength} ${tagsTitle}`
  } else {
    return ''
  }
}

function MultiSelectSearch({
  options = [],
  placeholder = 'No options',
  getDisplayText = getCustomDisplayText,
  tagsTitle,
  onDone,
  selectedOptions = [],
}: MultiSelectSearchOwnProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [value, setValue] = useState<Array<OptionType>>(selectedOptions)

  const {
    activate: toggleMouseInListBox,
    deactivate: toggleMouseNotInListBox,
    isActive: isMouseInListBox,
  } = useToggle()

  const handleToggleAll = () => {
    if (value.length === options.length) {
      setValue([])
    } else {
      setValue(options)
    }
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const handleDone = () => {
    onDone?.(value)
    handleClose()
  }

  const handleClickAway = () => {
    handleClose()

    setValue(selectedOptions)
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? 'select-option' : undefined

  return (
    <>
      <Root>
        <StyledButton
          sx={{
            padding: '0 0',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disableRipple
          aria-describedby={id}
          onClick={handleClick}
        >
          <FormControl variant="outlined">
            <OutlinedInput
              readOnly
              size="small"
              value={getDisplayText(value.length, tagsTitle)}
              type="text"
              endAdornment={<ArrowDropDownIcon />}
              placeholder={placeholder}
            />
          </FormControl>
        </StyledButton>
      </Root>
      <StyledPopper
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box component="div">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              p={1}
            >
              <Box fontWeight="400" fontSize="14px">
                Selected: {value.length}
              </Box>
              <Box>
                <StyledButton
                  onClick={handleToggleAll}
                  variant="contained"
                  className="sizeS multilpleListTopBtn"
                  id="toggleAll"
                >
                  {renderIconByState({
                    pendingLength: value.length,
                    optionsLength: options.length,
                  })}
                </StyledButton>
              </Box>
            </Stack>
            <Autocomplete
              open
              multiple
              onClose={(_, reason: AutocompleteCloseReason) =>
                reason === 'escape' && handleClose()
              }
              openOnFocus={false}
              value={value}
              onChange={(_, newValue) => setValue(newValue)}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={`No ${tagsTitle}s`}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                  sx={{
                    '.MuiAutocomplete-option': {
                      bgcolor: !isMouseInListBox ? 'white' : undefined,
                    },
                  }}
                >
                  <Box fontSize="16px">{option.label}</Box>
                </Box>
              )}
              ListboxProps={{
                onMouseEnter: toggleMouseInListBox,
                onMouseLeave: toggleMouseNotInListBox,
              }}
              isOptionEqualToValue={(option, selectedValue) =>
                option.value === selectedValue.value
              }
              options={options}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder="Search"
                />
              )}
            />
            <Box py={2} px={1}>
              <Button
                size="medium"
                variant="contained"
                fullWidth={false}
                onClick={handleDone}
              >
                Done
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export default MultiSelectSearch
