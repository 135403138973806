import { Stack } from '@mui/material'

import {
  useGetCloudInformationLinkQuery,
  useListSupportedAwsRegionsQuery,
} from 'ui-v2/src/hooks/queries/red-stack'

import CfnModel from 'ui-v2/src/lib/models/red-stack/cfn.model'
import AwsNotInstalled from './aws-not-installed'
import { CfnStatusConstant } from 'ui-v2/src/lib/constants/cfn-status.constant'
import AwsUpdating from './aws-updating'
import AwsInstalling from './aws-installing'
import AwsUpdateRequired from './aws-update-required'

const AwsAccountElastio = ({ cfn }: AwsAccountElastioProps) => {
  const { data: cloudInformationLinkData } = useGetCloudInformationLinkQuery()
  const { data: listSupportedAwsRegionsData } =
    useListSupportedAwsRegionsQuery()

  return (
    <Stack spacing={1} maxWidth={700}>
      {CfnStatusConstant.NOTINSTALLED === cfn.status && (
        <AwsNotInstalled
          cloudInformationLinkData={cloudInformationLinkData ?? ''}
          listSupportedAwsRegionsData={listSupportedAwsRegionsData ?? []}
        />
      )}
      {CfnStatusConstant.UPDATING === cfn.status && <AwsUpdating />}
      {CfnStatusConstant.INSTALLING === cfn.status && <AwsInstalling />}
      {CfnStatusConstant.UPDATEREQUIRED === cfn.status && (
        <AwsUpdateRequired
          cloudInformationLinkData={cloudInformationLinkData ?? ''}
          cfn={cfn}
        />
      )}
    </Stack>
  )
}
interface AwsAccountElastioProps {
  cfn: CfnModel
}
export default AwsAccountElastio
