import React, { memo } from 'react'
import { EngineCallback } from '@lib/engine-types'
import clsx from 'clsx'
import ArrHelper from '@lib/helpers/arr.helper'
import { Popover } from '@mui/material'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import StrHelper from '@lib/helpers/str.helper'

function getDayLabel(day: number, ordinal = false): string {
  if (day === 0) {
    return 'The last day'
  }
  if (day === -1) {
    return 'Before the last day'
  }
  if (ordinal) {
    return StrHelper.buildWithOrdinalSuffix(day)
  }
  return String(day)
}

interface Props {
  onChange: EngineCallback<number>
  readonly value: number
  readonly disabled?: boolean
  readonly label?: string
  readonly renderLastDay?: boolean
}

function DayInMonthSelector({
  value,
  onChange,
  disabled = false,
  label = '',
  renderLastDay = true,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeInner = (dayIndex: number) => () => {
    onChange(dayIndex)
    handleClose()
  }

  const drawDay = (dayIndex: number) => (
    <div key={dayIndex}>
      <button
        onClick={onChangeInner(dayIndex)}
        className={clsx('dayBtn', { daySelected: dayIndex === value })}
        type="button"
      >
        {getDayLabel(dayIndex)}
      </button>
    </div>
  )

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className="wrap-1641418161794 jsDayInMonthSelector">
      {label && <div className="dayInMonthLabel">{label}</div>}
      <button
        aria-label="Date popup"
        disabled={disabled}
        className="currentDayLabel"
        onClick={handleClick}
        type="button"
      >
        {getDayLabel(value, true)}
        <SelectArrowIcon />
      </button>
      <Popover
        className="dayInMonthPopover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {renderLastDay && [0, -1].map((dayIndex) => drawDay(dayIndex))}
        <div className="monthDaysWrap">
          {ArrHelper.generateIncSequence(1, 28).map((drawIndex: number) =>
            drawDay(drawIndex)
          )}
        </div>
      </Popover>
    </div>
  )
}

export default memo(DayInMonthSelector)
