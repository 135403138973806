/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { getHumanReadableTenantJobStatus } from '@features/jobs/tenant-jobs/helpers'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { TenantJobStatusCheckbox } from '@features/jobs/tenant-jobs/shared'
import { usePossibleTenantJobFilters } from '../../stores'
import {
  useModalFilterActions,
  useModalFilterSearchValue,
  useSelectedModalFilters,
} from '../deployment-filters-store'

export function JobStatusesSection() {
  const { statusesList: initialStatusesList } = usePossibleTenantJobFilters()

  const filterSearchValue = useModalFilterSearchValue()

  const { setFilter } = useModalFilterActions()

  const statusesList = useMemo(
    () =>
      Array.from(initialStatusesList).filter((kind) =>
        getHumanReadableTenantJobStatus(kind)
          .toLowerCase()
          .includes(filterSearchValue)
      ),
    [initialStatusesList, filterSearchValue]
  )

  const { statusesList: selectedStatusesList } = useSelectedModalFilters()

  if (statusesList.length === 0) {
    return null
  }

  function handleCheckboxChange(checked: boolean, status: TenantJobStatus) {
    const newStatusesList = new Set(selectedStatusesList)

    if (checked) {
      newStatusesList.add(status)
    } else {
      newStatusesList.delete(status)
    }

    setFilter('statusesList', newStatusesList)
  }

  return (
    <Fragment>
      <Typography
        fontSize="12px"
        lineHeight="140%"
        marginBottom="10px"
        marginTop="10px"
        color="#71768e"
        fontWeight="700"
      >
        Job Status
      </Typography>
      <Box display="flex" gap="12px" flexDirection="column">
        {statusesList.map((status) => {
          return (
            <TenantJobStatusCheckbox
              key={status}
              onChange={handleCheckboxChange}
              isChecked={selectedStatusesList.has(status)}
              status={status}
            />
          )
        })}
      </Box>
    </Fragment>
  )
}
