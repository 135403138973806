import React from 'react'
import { Chart } from 'chart.js'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterFactory from '@lib/factories/filter.factory'
import { getCloudConnectorBackupTimelineStats } from '@store/selectors/source-vaults.selector'
import {
  BackupTimelineChartDataPoint,
  BackupTimelineStatsRequest,
} from '@lib/interfaces/backup-timeline.interface'
import TimeHelper from '@lib/helpers/time.helper'
import { requestBackupTimelineStats } from '@store/actions/source-vaults.action'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import ValueInterface from '@lib/interfaces/value.interface'
import SimpleSelect from '@components-simple/simple-select/SimpleSelect'
import ContentBlock from '@components-composite/content-block/ContentBlock'
import { BackupTimelineFactory } from '@lib/factories/backup-timeline.factory'
import drawCloudConnectorBackupTimelinelineChart from './drawCloudConnectorBackupTimelineChart'
import JobKindConstant from '@lib/constants/jobs/job-kind.constant'

interface Props {
  cloudConnectorId: string
}

function CloudConnectorBackupTimeline({ cloudConnectorId }: Props) {
  const dispatch = useDispatch()

  const chartRef = useRef<HTMLCanvasElement>(null)
  const [chartInstance, setChartInstance] = useState<Chart>()

  const selectFilters = FilterFactory.getBackupTimelineJobFilters()
  const dateRangeFilters = FilterFactory.getBackupTimelineDateRangeFilters()

  const backupTimelineStats = useSelector(
    getCloudConnectorBackupTimelineStats(cloudConnectorId)
  )

  const [jobTypeFilter, setJobTypeFilters] =
    useState<ValueInterface>(selectFilters)
  const [dateFilters, setDateFilters] =
    useState<ValueInterface>(dateRangeFilters)

  const selectedDateFilterOption = useMemo<ValueInterface | undefined>(
    () =>
      dateFilters.options?.find((option) => option.value === dateFilters.value),
    [dateFilters]
  )

  const backupTimelineInitialParams: BackupTimelineStatsRequest = {
    intervalMinutes: Number(selectedDateFilterOption?.supplementalValue),
    kindsList: [],
    redStackIdsList: [cloudConnectorId],
    start: TimeHelper.currentMinusHours(
      Number(selectedDateFilterOption?.extraValue)
    ).valueOf(),
    end: TimeHelper.momentNow().valueOf(),
  }

  useEffect(() => {
    dispatch(requestBackupTimelineStats(backupTimelineInitialParams))
  }, [])

  const loading: boolean = usePreloader(
    PreloaderConstants.REQUEST_BACKUP_TIMELINE_STATS
  )

  const onFiltersChange = (newFilters: ValueInterface) => {
    let kindFilters: Array<JobKindConstant> = []
    if (newFilters.value !== -1) {
      kindFilters = [Number(newFilters.value) as JobKindConstant]
    }

    const params: BackupTimelineStatsRequest = {
      intervalMinutes: Number(selectedDateFilterOption?.supplementalValue),
      kindsList: kindFilters,
      redStackIdsList: [cloudConnectorId],
      start: TimeHelper.currentMinusHours(
        Number(selectedDateFilterOption?.extraValue)
      ).valueOf(),
      end: TimeHelper.momentNow().valueOf(),
    }

    dispatch(requestBackupTimelineStats(params))

    setJobTypeFilters(newFilters)
  }

  const onDateFiltersChange = (newDateFilters: ValueInterface) => {
    const selectedOption = newDateFilters.options?.find(
      (option) => option.value === newDateFilters.value
    )
    let kindFilters: Array<JobKindConstant> = []
    if (jobTypeFilter.value !== -1) {
      kindFilters = [Number(jobTypeFilter.value) as JobKindConstant]
    }

    const params: BackupTimelineStatsRequest = {
      intervalMinutes: Number(selectedOption?.supplementalValue),
      kindsList: kindFilters,
      redStackIdsList: [cloudConnectorId],
      start: TimeHelper.currentMinusHours(
        Number(selectedOption?.extraValue)
      ).valueOf(),
      end: TimeHelper.momentNow().valueOf(),
    }

    dispatch(requestBackupTimelineStats(params))

    setDateFilters(newDateFilters)
  }

  const chartData: Array<BackupTimelineChartDataPoint> = useMemo<
    Array<BackupTimelineChartDataPoint>
  >(
    () =>
      BackupTimelineFactory.toStatsChartData(
        backupTimelineStats,
        selectedDateFilterOption?.extraValue as number
      ),
    [backupTimelineStats]
  )

  const noDataMessage = useMemo<string>(() => {
    const selectedJobFilterOption = jobTypeFilter.options?.find(
      (option) => option.value === jobTypeFilter.value
    )
    return BackupTimelineFactory.getNoDataMessage(
      selectedDateFilterOption?.label as string,
      selectedJobFilterOption?.label as string
    )
  }, [selectedDateFilterOption, jobTypeFilter])

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy()
    }

    const newChartInstance = drawCloudConnectorBackupTimelinelineChart(
      chartData,
      chartRef.current
    )
    setChartInstance(newChartInstance)
  }, [chartData, chartRef.current])

  return (
    <div className="widgetWrap wrap-1681211249932 jsCloudConnectorBackupTimeline">
      <div className="widgetHeader">
        Backup Timeline
        <div className="filters">
          <SimpleSelect
            key={1}
            data={jobTypeFilter}
            onChange={onFiltersChange}
            typeFunction={Number}
            disabled={loading}
          />
          <SimpleSelect
            key={2}
            data={dateFilters}
            onChange={onDateFiltersChange}
            typeFunction={Number}
            disabled={loading}
          />
        </div>
      </div>
      <ContentBlock
        data={backupTimelineStats}
        loading={loading}
        noDataMessage={noDataMessage}
      >
        <div className="chartWrapper">
          <canvas ref={chartRef}></canvas>
        </div>
      </ContentBlock>
    </div>
  )
}

export default memo(CloudConnectorBackupTimeline)
