import { useMutation } from '@tanstack/react-query'
import { TokenIssueResultInterface } from 'ui-v2/src/lib/models/settings/api-access'
import { PersonalAccessTokenClient } from 'ui-v2/src/lib/clients/settings/personal-access-token'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useRevokeMutation() {
  const personalAccessTokenClient = useGrpcClient(PersonalAccessTokenClient)

  return useMutation({
    mutationFn: async ({
      tokens,
    }: {
      tokens: Array<string>
    }): Promise<TokenIssueResultInterface> => {
      if (!personalAccessTokenClient) {
        throw new Error('Personal access token client not initialized')
      }
      return personalAccessTokenClient.revoke(tokens)
    },
  })
}
