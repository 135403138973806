import { NavigateFunction, Location } from 'react-router-dom'
import { SpaNavigationStrategy } from '../spa-navigation'
import { NavigationStrategy } from '@lib/router/types'

let instance: NavigationStrategy | null = null

interface SpaNavigationStrategyAttrs {
  navigate: NavigateFunction
  location: Location
  searchParams: URLSearchParams
}

export const useSpaRouter = ({
  location,
  navigate,
  searchParams,
}: SpaNavigationStrategyAttrs): NavigationStrategy => {
  if (!instance) {
    instance = new SpaNavigationStrategy({
      location,
      navigate,
      searchParams,
    })
  } else {
    instance.updatePath({
      location,
      searchParams,
      navigate,
    })
  }

  return instance
}
