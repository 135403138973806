import React, { useEffect, useState } from 'react'
import AccountIdForm from '@forms/account-id/account-id.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import PreloaderConstants from '@lib/constants/preloader.constant'
import FormAccountIdInterface from '@lib/interfaces/form/form-account-id.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllActiveRedStackRegionsForAccount,
  getCFLink,
  getCfn,
  getPossibleRegionsList,
  getPossibleVPCList,
} from '@store/selectors/rex.selector'
import VpcModel, { Subnet } from '@lib/models/vpc.model'
import ViCheckboxSelect from '@components-composite/vi-inputs/vi-checkbox-select/ViCheckboxSelect'
import { Nullable, VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import VpcFactory from '@lib/factories/vpc.factory'
import clsx from 'clsx'
import DataHelper from '@lib/helpers/data.helper'
import LangHelper from '@lib/helpers/lang.helper'
import ActivatedIcon from '@inline-img/general/region-status/activated-icon'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import {
  getAllRedStacks,
  installRedStacks,
  requestCFLink,
  requestCfn,
  requestCfnShadow,
  requestPossibleRegionsList,
  setAllRedStacks,
  setCfn,
  setPossibleVpcList,
} from '@store/actions/rex.action'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import useIntervalIf from '@lib/hooks/useIntervalIf'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import CfnFactory from '@lib/factories/cfn.factory'
import { CfnStatusConstant } from '@lib/constants/cfn-status.constant'
import CfnModel from '@lib/models/cfn.model'
import CopyRow from '@components-simple/copy-row/CopyRow'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import AwsLogoIcon from '@inline-img/general/aws-logo-icon'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { Button } from '@mui/material'
import { CopyButtonVariant } from '@lib/constants/copy-command-button.constant'
import CopyButtonsBlock from '@components-composite/copy-buttons-block/CopyButtonsBlock'
import DeployInformationBlock from '@components-simple/deployInformationBlock/DeployInformationBlock'
import StrHelper from '@lib/helpers/str.helper'
import DemoWrap from '@components-simple/demo-wrap/DemoWrap'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import AllWorkflowsLink from '@components-simple/all-workflows-link/AllWorkflowsLink'
import {
  JobsRoutes,
  OnboardingRoutes,
  SourcesRoutes,
  useNavigation,
} from '@lib/router'
import { useSearchParams } from 'react-router-dom'

type GroupedVPC = Map<string, Array<VpcModel>>
type SelectedVPC = Map<string, VpcModel>
type SelectedSubnet = Map<string, Array<Subnet>>

function CloudConfigureDeployment() {
  const dispatch = useDispatch()
  const { setBreadcrumbsPaths, setHeaderTitle } = useLayoutContext()

  useEffect(() => {
    // reset
    dispatch(setCfn(CfnFactory.buildEmpty()))
    dispatch(setPossibleVpcList([]))
    dispatch(setAllRedStacks([]))
    // request
    dispatch(requestCFLink())
    dispatch(getAllRedStacks())
    dispatch(requestPossibleRegionsList())
    setBreadcrumbsPaths([
      {
        href: SourcesRoutes.root,
        text: titlesForBreadcrumb(SourcesRoutes.root) ?? '',
      },
    ])
    setHeaderTitle('Deploy the Elastio Cloud Connector')

    return () => {
      setHeaderTitle(null)
    }
  }, [])

  const router = useNavigation()
  const [searchParams] = useSearchParams()

  const isPrevOnboarding = searchParams.get('onboarding') !== null
  const [currentSelectedVPC, setCurrentSelectedVPC] = useState<SelectedVPC>(
    new Map()
  )
  const [currentSelectedSubnet, setCurrentSelectedSubnet] =
    useState<SelectedSubnet>(new Map())

  const prefilledAccountId = StrHelper.base64Decode(searchParams.get('id'))
  const defaultAccountParams = {
    accountId: prefilledAccountId,
  }

  const [lastHandledAccountId, setLastHandledAccountId] = useState<string>(
    prefilledAccountId || ''
  )

  const possibleRegions = useSelector(getPossibleRegionsList)
  const possibleVPCList = useSelector(getPossibleVPCList)
  const groupedPossibleVPC: GroupedVPC = DataHelper.groupByField(
    possibleVPCList,
    'awsRegion'
  )

  const cfLink = useSelector(getCFLink)
  const allActiveRedStackRegions: Array<string> = useSelector(
    getAllActiveRedStackRegionsForAccount(lastHandledAccountId)
  )
  const cfnModel: CfnModel = useSelector(getCfn)
  const [region, setRegion] = useState<ValueInterface>(
    possibleRegions[0] ?? { name: '' }
  )

  useEffect(() => {
    setRegion(possibleRegions[0] ?? { name: '' })
  }, [JSON.stringify(possibleRegions)])

  const [hasPrivateSubnetsSelected, setHasPrivateSubnetsSelected] =
    useState<boolean>(false)

  const [isEnableSafetyLock, setEnableSafetyLock] = useState<boolean>(false)

  // hidden request in order to detect CFN
  useIntervalIf(
    CfnStatusConstant.INSTALLED !== cfnModel.status,
    () => {
      dispatch(requestCfnShadow(lastHandledAccountId))
    },
    [lastHandledAccountId]
  )

  const generalLoading = usePreloaderAny([
    PreloaderConstants.REQUEST_POSSIBLE_VPC_LIST,
    PreloaderConstants.INSTALL_RED_STACKS,
    PreloaderConstants.REQUEST_CFN,
    PreloaderConstants.REQUEST_CF_LINK,
  ])

  const onAccountIdChange = (accountId: string, isValid: boolean) => {
    setLastHandledAccountId(accountId)
    if (isValid) {
      dispatch(requestCfn(accountId))
      return
    }
    // if "account id" is wrong - we reset "Step 3"
    setCurrentSelectedVPC(new Map())
    setCurrentSelectedSubnet(new Map())

    dispatch(setPossibleVpcList([]))
    dispatch(setCfn(CfnFactory.buildEmpty()))
    setRegion(possibleRegions[0] ?? { name: '' })
  }

  const redirectToDeploymentPage = () => {
    router.push(JobsRoutes.deployment)
  }

  const onSubmit = (data: FormAccountIdInterface) => {
    let vpcFlatArr: Array<VpcModel> = []
    currentSelectedVPC.forEach((arr) => {
      vpcFlatArr = vpcFlatArr.concat(arr)
    })
    dispatch(
      installRedStacks(
        DataHelper.accountIdToServerFormat(data.accountId),
        isEnableSafetyLock,
        vpcFlatArr.map((v) => ({
          accountId: DataHelper.accountIdToServerFormat(data.accountId),
          regionName: v.region.name,
          regionLabel: String(v.region.label),
          status: -1,
          errorMessage: '',
          vpcId: v.innerId,
          jobId: '',
          childMessage: '',
          subnetIdsList:
            currentSelectedSubnet.get(v.region.name)?.map((s) => s.id) ?? [],
        })),
        redirectToDeploymentPage
      )
    )
  }

  const onCancel = () => {
    setRegion(possibleRegions[0] ?? { name: '' })
    router.push(SourcesRoutes.root)
  }

  const checkForPrivateSubnetsSelected = (selectedSub: SelectedSubnet) => {
    // see if private subnets are selected to show warning message
    const allSubnets = Array.from(selectedSub.values())
    const privateSubnetsCount =
      allSubnets.find((el) => el.find((s) => !s.isPublic))?.length ?? 0
    setHasPrivateSubnetsSelected(privateSubnetsCount > 0)
  }

  const onVPCSelect =
    (regionName: string) => (value: Nullable<ValueInterface>) => {
      const newCurrentSelectedVPC = new Map(currentSelectedVPC)
      const newCurrentSelectedSubnet = new Map(currentSelectedSubnet)
      newCurrentSelectedSubnet.delete(regionName)

      if (!value) {
        newCurrentSelectedVPC.delete(regionName)
      } else {
        const modelToAdd = possibleVPCList.find(
          (v) => v.innerId === value.name && v.region.name === regionName
        )
        if (modelToAdd) {
          newCurrentSelectedVPC.set(regionName, modelToAdd)

          //pre-select public subnets for current VPC
          const publicModelSubnets = modelToAdd.subnetsList.filter(
            (s) => s.isPublic
          )
          if (publicModelSubnets.length > 0) {
            newCurrentSelectedSubnet.set(regionName, publicModelSubnets)
          }
        }
      }
      setCurrentSelectedVPC(newCurrentSelectedVPC)
      setCurrentSelectedSubnet(newCurrentSelectedSubnet)
      checkForPrivateSubnetsSelected(newCurrentSelectedSubnet)
    }

  const onSubnetSelect = (regionName: string) => (value: Nullable<VIRow>) => {
    const newCurrentSelectedSubnet = new Map(currentSelectedSubnet)

    if (!value?.length) {
      newCurrentSelectedSubnet.delete(regionName)
    } else {
      const selectedVPC = currentSelectedVPC.get(regionName)
      const modelToAdd = selectedVPC?.subnetsList.filter(
        (s) => s.id === value.find((v) => v.name === s.id)?.name
      )

      if (modelToAdd) {
        newCurrentSelectedSubnet.set(regionName, modelToAdd)
      }
    }

    setCurrentSelectedSubnet(newCurrentSelectedSubnet)
    checkForPrivateSubnetsSelected(newCurrentSelectedSubnet)
  }

  const drawSelect = ([regionName, vpcArr]: [string, Array<VpcModel>]) => {
    const selected = currentSelectedVPC.get(regionName)
    const regionLabel = LangHelper.getAwsRegionSingleTranslation(regionName)
    const selectedSubnet = currentSelectedSubnet.get(regionName)

    return (
      <ViCheckboxSelect
        key={regionName}
        disabled={generalLoading || vpcArr.length === 0}
        selectPlaceholder="Select VPC"
        checkboxLabel={regionLabel}
        checkboxSubLabel={regionName}
        possible={vpcArr.map(VpcFactory.fromModelToVi)}
        selected={selected ? VpcFactory.fromModelToVi(selected) : null}
        onChange={onVPCSelect(regionName)}
        possibleSub={
          selected?.subnetsList?.map(VpcFactory.fromSubnetModelToVi) ?? []
        }
        selectedSub={
          selectedSubnet
            ? selectedSubnet.map(VpcFactory.fromSubnetModelToVi)
            : null
        }
        onChangeSub={onSubnetSelect(regionName)}
        selectPlaceholderSub="Select Subnet"
        countLabelSub="subnets selected"
        menuWidth="auto"
      />
    )
  }

  const onEnableSafetyLockChange = (newEnableeSafetyLock: boolean) => {
    setEnableSafetyLock(newEnableeSafetyLock)
  }

  // we are showing only regions, where we did not find an activated red stack
  const possibleVPCFilteredByCurrentRedStacks = [
    ...groupedPossibleVPC.entries(),
  ].filter(([regionName]) => !allActiveRedStackRegions.includes(regionName))
  // but we should show all possible regions even in case we can not install there
  // so for this we add empty arrays for all remain regions
  possibleRegions.forEach((regionData) => {
    if (
      !possibleVPCFilteredByCurrentRedStacks.find(
        ([regionName]) => regionName === regionData.name
      )
    ) {
      possibleVPCFilteredByCurrentRedStacks.push([String(regionData.name), []])
    }
  })

  const commandToastText = 'Run it on the target client machine to deploy CFN.'
  const commandButtonText = DataHelper.buildCfnDeployCommand(
    cfLink,
    region?.name
  )
  const commandButtonsData: VIRow = [
    {
      type: CopyButtonVariant.WINDOWS,
      name: commandButtonText,
      disabled: false,
    },
    {
      type: CopyButtonVariant.MACOS,
      name: commandButtonText,
      disabled: false,
    },
    {
      type: CopyButtonVariant.LINUX,
      name: commandButtonText,
      disabled: false,
    },
  ]

  return (
    <div className={clsx('ccdLayout', { pt30: !isPrevOnboarding })}>
      {isPrevOnboarding && <AllWorkflowsLink backUrl={OnboardingRoutes.root} />}
      <div className="wrap-1626616534577 jsCloudConfigureDeployment">
        <div className="depMainBlock">
          <div className="depPlate">
            <div className="depPlateBlock jsStep1">
              {/*STEP 1*/}
              <div className="depPlateBlockTitle">
                Step 1.{' '}
                <span className="depPlateBlockTitleBold jsCloudInstallerText">
                  Enter your AWS account ID
                </span>
                <PreloaderSmall
                  right="0"
                  top="0"
                  show={
                    generalLoading ||
                    (!!defaultAccountParams.accountId && cfnModel.isEmpty)
                  }
                />
              </div>
              <div className="depPlateBlockDesc">
                Enter your Amazon Web Services (AWS) account ID to begin the
                deployment.
              </div>
              <div className="depAccountInfoContainer">
                <div>
                  <div className="mb10">
                    <AwsLogoIcon />
                  </div>
                  <AccountIdForm
                    loading={generalLoading}
                    errorGroups={[ErrorGroupConstants.REX]}
                    onSubmit={onSubmit}
                    defaultValues={defaultAccountParams}
                    onAccountIdChange={onAccountIdChange}
                    blockIamRoleShowing
                    isDisabled={!!defaultAccountParams.accountId ?? false}
                    isPrefilled={!!defaultAccountParams.accountId ?? false}
                  />
                </div>
                <DeployInformationBlock />
              </div>
            </div>

            {/*STEP 2*/}
            <div
              className={clsx('depPlateBlock jsStep2', {
                depPlateBlockDisabled: cfnModel.isEmpty,
                depPlateBlockSuccess:
                  CfnStatusConstant.INSTALLED === cfnModel.status,
              })}
            >
              <div className="depPlateBlockTitle">
                Step 2.{' '}
                <span className="depPlateBlockTitleBold">
                  Enable your AWS account with Elastio
                </span>
                <div className="depPlateSuccessIcon">
                  <ActivatedIcon />
                </div>
              </div>

              {CfnStatusConstant.NOTINSTALLED === cfnModel.status && (
                <>
                  <div className="depPlateContentWrap">
                    <div className="depPlateList">
                      <div className="fontNormal">
                        <span>
                          To enable your AWS account with Elastio, you will need
                          to deploy Elastio CloudFormation stack.{' '}
                        </span>
                        {cfLink !== '' && (
                          <>
                            <span>You can review it </span>
                            <a
                              href={cfLink}
                              rel="noreferrer"
                              className="buttonLikeLink"
                            >
                              <span className="fontBold">here</span>
                            </a>
                            .
                          </>
                        )}
                      </div>
                      <div className="fontNormal">
                        You will be redirected to AWS deploy CloudFormation
                        page.
                      </div>
                      <div className="fontNormal">
                        1. Login to your AWS account.
                      </div>
                      <div className="fontNormal">
                        2. Select a region from the dropdown below and press
                        Deploy CloudFormation button.
                      </div>
                      <div className="fontNormal">
                        3. Click the Create Stack button in the AWS Console.
                      </div>
                      <div className="fontNormal">
                        4. Once the CloudFormation is deployed successfully,
                        come back to this page and proceed to Step 3.
                      </div>
                    </div>

                    {possibleRegions.length > 0 ? (
                      <>
                        <div className="newFormRow jsCFRegions">
                          <ViSelect
                            possible={possibleRegions}
                            selected={region}
                            onChange={setRegion}
                            getLabel={(v) => (
                              <>
                                {v.label}
                                <span className="smallSubOptionViSelect">
                                  {v.name}
                                </span>
                              </>
                            )}
                          />
                        </div>

                        <Button
                          variant="contained"
                          color="primary"
                          target="_blank"
                          className={clsx('sizeS jsCFLink', {
                            contentDisabled: cfLink === '',
                          })}
                          href={DataHelper.buildCloudFormationLink(
                            cfLink,
                            region?.name
                          )}
                          rel="noreferrer"
                          disabled={!region || !region.name}
                        >
                          Deploy the CloudFormation Stack
                        </Button>
                        {cfLink !== '' && (
                          <>
                            <CopyButtonsBlock
                              blockHeader="Deploy CFN via CLI"
                              blockText="Optionally you can copy the command below and run it
                          to deploy CFN"
                              blockToastText={commandToastText}
                              commands={commandButtonsData}
                              className="mt20"
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <div className="textError textAlignLeft">
                        Regions are not found
                      </div>
                    )}
                  </div>
                </>
              )}

              {CfnStatusConstant.UPDATING === cfnModel.status && (
                <>
                  <div className="depPlateBlockDesc">
                    The CloudFormation template is updating and will take a few
                    minutes.
                    <div className="relative">
                      <PreloaderSmall right="0" top="-10" show />
                    </div>
                  </div>
                </>
              )}

              {CfnStatusConstant.INSTALLING === cfnModel.status && (
                <>
                  <div className="depPlateBlockDesc">
                    Now the CFN is in a "INSTALLING" status
                    <div className="relative">
                      <PreloaderSmall right="0" top="-10" show />
                    </div>
                  </div>
                </>
              )}

              {CfnStatusConstant.UPDATEREQUIRED === cfnModel.status && (
                <>
                  <div className="depPlateBlockDesc">
                    The CFN installed in the account is outdated. There is a new
                    CloudFormation stack available. Upgrade CFN by pressing the
                    button below.
                  </div>
                  <div className="depPlateContentWrap">
                    <Button
                      variant="contained"
                      color="primary"
                      target="_blank"
                      className={clsx({
                        contentDisabled: cfLink === '',
                      })}
                      href={DataHelper.buildCfnUpdatingLink(cfLink, cfnModel)}
                      rel="noreferrer"
                    >
                      Update the CloudFormation Stack
                    </Button>
                    {cfLink !== '' && cfnModel.region && (
                      <>
                        <div className="depPlateBlockDesc mt20">
                          Optionally you can copy the command below and run it
                          to upgrade CFN
                        </div>

                        <CopyRow
                          text={DataHelper.buildCfnUpdatingCommand(
                            cfLink,
                            cfnModel
                          )}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            {/*STEP 3*/}
            <div
              className={clsx('depPlateBlock jsStep3', {
                depPlateBlockDisabled:
                  CfnStatusConstant.INSTALLED !== cfnModel.status,
              })}
            >
              <div className="depPlateBlockTitle">
                Step 3.{' '}
                <span className="depPlateBlockTitleBold">Select Regions</span>
              </div>

              <div className="depPlateContentWrap">
                <div className="depPlateBlockDesc">
                  Below are the regions that Elastio currently supports. More
                  will be added in the near future.
                </div>
                {hasPrivateSubnetsSelected && (
                  <div className="depPlateBlockWarningText">
                    The Cloud Connector will be deployed into a private subnet
                    and will need manual configuration. Please see documentation
                    for more details. One of our cloud team members will reach
                    out with assistance.
                  </div>
                )}
                {generalLoading ? (
                  <>
                    <PreloaderBlock show />
                    <div className="depPlateBlockDesc textAlignCenter">
                      Analyzing your AWS VPC(s)
                    </div>
                  </>
                ) : (
                  <>
                    {possibleVPCFilteredByCurrentRedStacks.length === 0 ? (
                      <div className="emptyResultBlock jsEmpty">
                        The are no available regions
                      </div>
                    ) : (
                      <div className="depCheckboxList jsCloudCDRegionsList">
                        {possibleVPCFilteredByCurrentRedStacks.map(drawSelect)}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <DemoWrap>
              {/*STEP 5*/}
              <div
                className={clsx('depPlateBlock mb30 jsStep5', {
                  depPlateBlockDisabled:
                    CfnStatusConstant.INSTALLED !== cfnModel.status,
                })}
              >
                <div className="depPlateBlockTitle">
                  Step 5.{' '}
                  <span className="depPlateBlockTitleBold">Safety Lock</span>
                </div>
                <div className="depPlateContentWrap depCheckboxBlock">
                  <V2Checkbox
                    checked={isEnableSafetyLock}
                    onChange={onEnableSafetyLockChange}
                    label={
                      <>
                        <div className="depCheckboxLabel">
                          Enable Safety Lock
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            </DemoWrap>
          </div>
        </div>

        <BasePortalButtons alwaysInPortal>
          <div className="portalBetweenButtons">
            <Button
              onClick={onCancel}
              disabled={generalLoading}
              variant="text"
              color="primary"
              className="showButton jsCloseCloudInstallerPage"
            >
              Cancel
            </Button>
            <Button
              disabled={
                generalLoading ||
                currentSelectedVPC.size === 0 ||
                currentSelectedSubnet.size !== currentSelectedVPC.size
              }
              variant="contained"
              color="primary"
              form="accountIdFormId"
              type="submit"
              className="sizeL"
            >
              Deploy
            </Button>
          </div>
        </BasePortalButtons>
      </div>
    </div>
  )
}

export default CloudConfigureDeployment
