import React, { ChangeEvent, FocusEventHandler, forwardRef, memo } from 'react'
import { EngineCallback, FormRefType } from '@lib/engine-types'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'
import { FormControl, TextField } from '@mui/material'
import { EmptyFunc } from '@lib/constants/app.constant'

// now it is obligatory to register onBlur event
// react hook forms change their implementation

interface Props {
  field: FormFieldInterface
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
  // eslint-disable-next-line react/require-default-props
  className?: any
  // eslint-disable-next-line react/require-default-props
  multiline?: boolean
  // eslint-disable-next-line react/require-default-props
  rows?: number
  // eslint-disable-next-line react/require-default-props
  onChange?: EngineCallback<ChangeEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  // eslint-disable-next-line react/require-default-props
  onKeyDown?: EngineCallback<React.KeyboardEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  onFocus?: EngineCallback<React.FocusEvent<HTMLInputElement>>
  // eslint-disable-next-line react/require-default-props
  variant?: 'standard' | 'outlined' | 'filled'
}
// TODO  There is something wrong with this component. Refactoring is needed.
const CustomTextField = forwardRef<HTMLInputElement, Props>(
  (
    {
      field,
      disabled,
      className,
      multiline,
      rows,
      onChange,
      onBlur,
      onKeyDown = EmptyFunc,
      onFocus = EmptyFunc,
      variant = 'outlined',
    }: Props,
    ref: FormRefType
  ) => {
    const id = `id_${field.name}`
    return (
      <FormControl>
        <TextField
          className={className}
          id={id}
          error={field.hasError}
          helperText={field.errorText}
          label={field.label}
          name={field.name}
          variant={variant}
          defaultValue={field.value}
          placeholder={field.placeholder}
          inputRef={ref}
          inputProps={{
            'aria-label': field.label,
          }}
          autoComplete="off"
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
        />
      </FormControl>
    )
  }
)

export default memo(CustomTextField)
