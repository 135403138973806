import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { Box, IconButton, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const BackupProvider = ({ computedBackupId }: BackupProviderProps) => {
  return (
    <Box display={'inline-flex'} alignItems={'center'}>
      <Typography variant="body2" m={2}>
        {computedBackupId}
      </Typography>
      <IconButton
        size="small"
        onClick={() => {
          copyToClipboard(computedBackupId)
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  )
}
interface BackupProviderProps {
  computedBackupId: string
}
export default BackupProvider
