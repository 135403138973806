/* eslint-disable import/no-extraneous-dependencies */
import { FilesystemScanCheck } from 'blues-corejs/dist'
import { Nullable } from '@lib/engine-types'
import { ScanStatus } from '@features/DashboardV4/common'

export function getFilesystemCheckStatus(
  filesystemChecks: Array<FilesystemScanCheck>
): Nullable<ScanStatus> {
  if (filesystemChecks.length === 0) {
    return null
  }

  if (filesystemChecks.some((scan) => scan.isInfected)) {
    return ScanStatus.FAILED
  }

  if (filesystemChecks.every((scan) => scan.isClean)) {
    return ScanStatus.SUCCEEDED
  }

  return ScanStatus.UNSCANNED
}
