import React from 'react'

function InstallCliLink() {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href="https://docs.elastio.com/docs/cli/installation"
      className="wrap-1634819108438 blueLink jsInstallCliLink"
    >
      Install CLI
    </a>
  )
}

export default InstallCliLink
