/* eslint-disable import/no-extraneous-dependencies */
import { DefinedDateRangeTuple } from '@lib/engine-types'
import { TenantJobFiltersUI } from '@lib/interfaces/jobs'

export interface DeploymentFiltersState {
  timeRange: DefinedDateRangeTuple
  selectedFilters: TenantJobFiltersUI
  possibleFilters: TenantJobFiltersUI
}

type SetMember<T> = T extends Set<infer E> ? E : never

export interface DeploymentJobsFiltersStoreActions {
  resetDeploymentJobsFiltersState: () => void
  setFilters: (selectedFilters: TenantJobFiltersUI) => void
  removeFilter: <
    K extends keyof TenantJobFiltersUI,
    V extends SetMember<TenantJobFiltersUI[keyof TenantJobFiltersUI]>,
  >(
    key: K,
    value: V
  ) => void
  setTimeRange: (timeRange: DefinedDateRangeTuple) => void
  fetchPossibleTenantJobFilters: () => void
}

export type DeploymentJobsFiltersStore = DeploymentFiltersState & {
  actions: DeploymentJobsFiltersStoreActions
}

export {
  fetchPossibleTenantJobFilters,
  removeFilter,
  setFilters,
} from './actions'

export {
  getSelectedFilters,
  useDeploymentJobsFiltersActions,
  useDeploymentJobsTimeRange,
  useIsDeploymentFiltersNotEmpty,
  usePossibleTenantJobFilters,
  useSelectedDeploymentJobFilters,
  useShouldDisableFilterButton,
} from './selectors'

export { useFiltersSubscription } from './subscriptions'
