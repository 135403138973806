import React, { memo, MouseEventHandler } from 'react'
import DeleteIcon from '@inline-img/inputs/delete-icon'
import { Button } from '@mui/material'

interface Props {
  onClick: MouseEventHandler | undefined
  label: string
  disabled?: boolean
}

function DeleteButton({ onClick, label, disabled = false }: Props) {
  return (
    <div className="wrap-1642174987222">
      <Button disabled={disabled} onClick={onClick}>
        <DeleteIcon />
        {label}
      </Button>
    </div>
  )
}

export default memo(DeleteButton)
