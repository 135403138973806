import React from 'react'

interface Props {
  fill?: string
}

export function RansomwareIcon({ fill = '#FF7878' }: Props) {
  return (
    <svg
      data-testid="ransomware-icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8438 20.2421H18.1562C18.4617 20.2421 18.7178 20.1397 18.9244 19.9351C19.1311 19.7305 19.2344 19.477 19.2344 19.1745V15.972C19.2344 15.6695 19.1311 15.416 18.9244 15.2114C18.7178 15.0068 18.4617 14.9045 18.1562 14.9045V13.837C18.1562 13.2499 17.9451 12.7472 17.5229 12.3291C17.1006 11.911 16.593 11.702 16 11.702C15.407 11.702 14.8994 11.911 14.4771 12.3291C14.0549 12.7472 13.8438 13.2499 13.8438 13.837V14.9045C13.5383 14.9045 13.2822 15.0068 13.0756 15.2114C12.8689 15.416 12.7656 15.6695 12.7656 15.972V19.1745C12.7656 19.477 12.8689 19.7305 13.0756 19.9351C13.2822 20.1397 13.5383 20.2421 13.8438 20.2421ZM14.9219 14.9045V13.837C14.9219 13.5345 15.0252 13.281 15.2318 13.0764C15.4385 12.8718 15.6945 12.7695 16 12.7695C16.3055 12.7695 16.5615 12.8718 16.7682 13.0764C16.9748 13.281 17.0781 13.5345 17.0781 13.837V14.9045H14.9219ZM16 26.5404C15.8742 26.5404 15.7574 26.5315 15.6496 26.5137C15.5418 26.4959 15.434 26.4692 15.3262 26.4336C12.9004 25.633 10.9688 24.1518 9.53125 21.9901C8.09375 19.8284 7.375 17.5021 7.375 15.0112V9.96726C7.375 9.52247 7.50527 9.12215 7.76582 8.76631C8.02637 8.41048 8.36328 8.1525 8.77656 7.99237L15.2453 5.59047C15.4969 5.50151 15.7484 5.45703 16 5.45703C16.2516 5.45703 16.5031 5.50151 16.7547 5.59047L23.2234 7.99237C23.6367 8.1525 23.9736 8.41048 24.2342 8.76631C24.4947 9.12215 24.625 9.52247 24.625 9.96726V15.0112C24.625 17.5021 23.9063 19.8284 22.4688 21.9901C21.0312 24.1518 19.0996 25.633 16.6738 26.4336C16.566 26.4692 16.4582 26.4959 16.3504 26.5137C16.2426 26.5315 16.1258 26.5404 16 26.5404ZM16 24.4053C17.8688 23.8182 19.4141 22.6439 20.6359 20.8826C21.8578 19.1212 22.4688 17.1641 22.4688 15.0112V9.96726L16 7.56536L9.53125 9.96726V15.0112C9.53125 17.1641 10.1422 19.1212 11.3641 20.8826C12.5859 22.6439 14.1312 23.8182 16 24.4053Z"
        fill={fill}
      />
    </svg>
  )
}
