/* eslint-disable import/no-extraneous-dependencies */
import { createColumn, createDisplayColumn } from '@components/table'
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule'
import React from 'react'
import {
  SchedulesTableCellWithAction,
  CellActionsMenuItems,
  SchedulesTableCellWithDate,
  COLUMN_SIZES,
  COLUMN_IDS,
  COLUMN_HEADERS,
  COLUMN_ACCESSOR,
} from './columns/cells'
import Typography from '@mui/material/Typography'
import {
  extractAllReportTypesAndParams,
  getScheduleDescription,
  removeParamsSuffix,
} from '@features/reports/schedules/shared'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

const reportLabelMapper = {
  awsExecutive: 'AWS Ransomware Readiness Report',
  awsAccountSummary: 'AWS Account Summary Report',
  awsTenantSummary: 'AWS Operational Summary Report',

  // Detailed Reports
  awsCompletedScans: 'AWS All Scans',
  awsScannedAssetsSummary: 'AWS Assets Scanned',
  awsInfectedAssets: 'AWS Infected Assets',
  infectedFiles: 'AWS Infected Files',
  failedScans: 'AWS Failed Scans',

  // Backup Connector Summary Reports
  ovaMspOperational: 'OVA Operational Report',
  ovaMspExecutive: 'OVA Ransomware Readiness Report',
  ovaMspTenantSummary: 'OVA Operational Summary Report',
  ovaMspAccountSummary: 'OVA Account Summary Report',
  ovaMspCompletedScans: 'OVA All Scans',
  ovaMspScannedAssetsSummary: 'OVA Assets Scanned',
  ovaMspInfectedAssets: 'OVA Infected Assets',
  ovaMspInfectedFiles: 'OVA Infected Files',
  ovaMspFailedScans: 'OVA Failed Scans',
}

export function getSchedulesColumnDefinitions() {
  return [
    createColumn<ReportSchedule>(COLUMN_ACCESSOR.NAME, {
      header: COLUMN_HEADERS.NAME,
      enableSorting: false,
      cell: ({ cell, row }) => (
        <SchedulesTableCellWithAction cell={cell as any} row={row} />
      ),
      size: COLUMN_SIZES.NAME,
    }),
    createColumn<ReportSchedule>(COLUMN_ACCESSOR.DESCRIPTION, {
      header: COLUMN_HEADERS.DESCRIPTION,
      meta: {
        width: '20%',
      },
      enableSorting: false,
      cell: ({ cell }) => (
        <LongTextTooltip text={cell.getValue()} maxLength={50} />
      ),
    }),
    createColumn<ReportSchedule>('reportKindWithParams', {
      header: COLUMN_HEADERS.REPORT_TYPE,
      enableSorting: false,
      cell: ({ cell }) => {
        const { reportType } = extractAllReportTypesAndParams(
          cell.getValue() as any
        )
        const reportTypeKey = removeParamsSuffix(reportType as string)
        return (
          <Typography variant="caption" fontSize="14px">
            {/*
// @ts-ignore */}
            {reportLabelMapper[reportTypeKey.replace(/Report$/, '')]}
          </Typography>
        )
      },
    }),
    createColumn<ReportSchedule>('reportKindWithParams', {
      header: COLUMN_HEADERS.FREQUENCY,
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Typography variant="caption" fontSize="14px">
            {getScheduleDescription(row.original.schedule)}
          </Typography>
        )
      },
    }),
    createColumn<ReportSchedule>(
      COLUMN_ACCESSOR.LAST_REPORT_GENERATION_COMPLETED_AT,
      {
        header: COLUMN_HEADERS.LAST_GENERATED,
        enableSorting: false,
        cell: ({ cell }) => {
          return <SchedulesTableCellWithDate cell={cell as any} />
        },
      }
    ),
    createColumn<ReportSchedule>(COLUMN_ACCESSOR.NEXT_REPORT_GENERATION_AT, {
      header: COLUMN_HEADERS.NEXT_REPORT_DUE_ON,
      enableSorting: false,
      cell: ({ cell }) => {
        return <SchedulesTableCellWithDate cell={cell as any} />
      },
    }),
    createDisplayColumn<ReportSchedule>({
      id: COLUMN_IDS.SCHEDULES_ACTIONS,
      enableSorting: false,
      size: COLUMN_SIZES.SCHEDULES_ACTIONS,
      cell: ({ cell }) => {
        return <CellActionsMenuItems cell={cell as any} />
      },
    }),
  ]
}
