import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { PechkinClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function usePlanInfoSelectedAssetsQuery(id: string) {
  const pechkinClient = useGrpcClient(PechkinClient)

  return useQuery({
    queryKey: ['pechkin', 'plan-info-selected-assets', id],
    queryFn: async () => {
      if (!pechkinClient) {
        throw new Error('Pechkin client not initialized')
      }
      return pechkinClient.getPlanInfoWithSelectedAssets(id)
    },
    enabled: !!pechkinClient && !!id,
  })
}
