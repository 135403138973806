import { TabVariant } from '@features/DashboardV4/management-view'
import { DashboardViewOptions, TagsOperations } from './types'

export const dashboardViewTypes: Record<DashboardViewOptions, TabVariant> = {
  [DashboardViewOptions.BACKUPS]: 'recovery',
  [DashboardViewOptions.SECURITY]: 'security',
  [DashboardViewOptions.ALL]: 'overview',
  [DashboardViewOptions.OVA]: 'ova',
}

export const radioButtonsForViewOptions = [
  {
    value: DashboardViewOptions.BACKUPS,
    label: 'Recovery points',
  },
  {
    value: DashboardViewOptions.SECURITY,
    label: 'Security',
  },
  {
    value: DashboardViewOptions.ALL,
    label: 'Backups & Scans',
  },
]

export const radioButtonsForTagsOperations = [
  {
    value: TagsOperations.AND,
    label: 'And',
  },
  {
    value: TagsOperations.OR,
    label: 'Or',
  },
]
