/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Backup, OvaBackup } from 'blues-corejs/dist'
import RansomwareIcon from '@inline-img/general/ransomware-icon'
import MalwareIcon from '@inline-img/general/malware-icon'
import FsCheckIcon from '@inline-img/general/fs-check-icon'
import {
  useComputeFilesystemScanStatusByBackup,
  useComputeMalwareScanStatusByBackup,
  useComputeRansomwareScanStatusByBackup,
} from './use-compute-scans-statuses'

interface Props {
  backup: Backup
}

const ThreatContainer = styled(Box)({
  width: '22px',
  height: '22px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
})

function isFilesystemCheckIconNeeded(backup: Backup) {
  return !(backup instanceof OvaBackup)
}

export function ThreatIconsCell({ backup }: Props) {
  const ransomwareStatus = useComputeRansomwareScanStatusByBackup(backup)
  const malwareStatus = useComputeMalwareScanStatusByBackup(backup)
  const fsCheckStatus = useComputeFilesystemScanStatusByBackup(backup)

  const shouldRenderFilesystemCheckIcon = isFilesystemCheckIconNeeded(backup)

  return (
    <Box display="flex" alignItems="center" gap="5px">
      <Tooltip title={ransomwareStatus.tooltipText} placement="top">
        <ThreatContainer bgcolor={ransomwareStatus.color}>
          <RansomwareIcon
            width="16px"
            height="16px"
            fill={ransomwareStatus.fill}
          />
        </ThreatContainer>
      </Tooltip>
      <Tooltip title={malwareStatus.tooltipText} placement="top">
        <ThreatContainer bgcolor={malwareStatus.color}>
          <MalwareIcon width="16px" height="16px" fill={malwareStatus.fill} />
        </ThreatContainer>
      </Tooltip>
      {shouldRenderFilesystemCheckIcon && (
        <Tooltip title={fsCheckStatus.tooltipText} placement="top">
          <ThreatContainer bgcolor={fsCheckStatus.color}>
            <FsCheckIcon width="16px" height="16px" fill={fsCheckStatus.fill} />
          </ThreatContainer>
        </Tooltip>
      )}
    </Box>
  )
}
