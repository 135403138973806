import type { RegionStatistics } from '@lib/clients/krabs-aws-statistics/types'

export function computeFileScansLast30Days(
  accountRegionStatisticsList: Array<RegionStatistics>
): number {
  return accountRegionStatisticsList.reduce((acc, stat) => {
    return (
      acc + stat.totalMalwareScannedFiles + stat.totalRansomwareScannedFiles
    )
  }, 0)
}

export function computeFsChecksLast30Days(
  accountRegionStatisticsList: Array<RegionStatistics>
): number {
  return accountRegionStatisticsList.reduce((acc, stat) => {
    return acc + stat.totalFsChecks
  }, 0)
}
