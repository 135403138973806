/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useEffect, useRef } from 'react'
import { Box, CardContent, Typography } from '@mui/material'
import { StyledBadge, StyledCard, StyledChip, StyledDownArrow } from './styles'
import { CopyToClipboardIcon } from '@features/common'
import clsx from 'clsx'

import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { Threat } from 'blues-corejs/dist'
import { ReportSliderItemGenerator, ReportsBlock } from '../reports-block'
import { OvaBackupSnapshot } from 'blues-corejs/dist/models/backups/ova/backup/ova-backup-snapshot'
import { OvaDiskIcon } from '@inline-img/general/ova'

const SCROLL_VIEW_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'center',
}

interface Props {
  snapshot: OvaBackupSnapshot
  threats: Array<Threat>
  index: number
  selected: boolean
  onSlideChange: (index: number) => void
}

function computeLabelChip(threats: Array<Threat>) {
  if (threats.length > 0) {
    return {
      labelChip: 'Infected',
      labelVariant: 'el-error',
    } as const
  }

  return {
    labelChip: 'Clean',
    labelVariant: 'el-success',
  } as const
}

function SliderItem({
  snapshot: snapshot,
  threats,
  index,
  selected,
  onSlideChange,
}: Props) {
  const sliderRef = useRef<HTMLDivElement>(null)

  const snapshotThreatsList: Array<Threat> = threats.filter(
    (threat) =>
      threat.source.asset?.assetId === snapshot.ovaVolumeId ||
      threat.source.assetItem?.assetItemId === snapshot.ovaVolumeId
  )

  const isSnapshotContainThreats = snapshotThreatsList.length > 0

  const { labelVariant, labelChip } = computeLabelChip(snapshotThreatsList)

  const handleSlideChange = () => onSlideChange(index)

  useEffect(() => {
    if (selected) {
      sliderRef.current?.scrollIntoView(SCROLL_VIEW_OPTIONS)
    }
  }, [selected])

  const { reportBlocks } = new ReportSliderItemGenerator({
    snapshot,
    threats,
  })

  return (
    <Box
      display="flex"
      data-testid="slider-item"
      flexDirection="column"
      ref={sliderRef}
    >
      <StyledDownArrow />

      <StyledCard selected={selected} onClick={handleSlideChange}>
        <StyledBadge
          badgeContent={snapshotThreatsList.length}
          variant="el-error"
          children={<OvaDiskIcon />}
        />

        <CardContent
          className={clsx('card-content', {
            infected: isSnapshotContainThreats,
          })}
        >
          <StyledChip label={labelChip} variant={labelVariant} size="small" />

          <Typography marginTop="6px" fontSize="12px">
            <LongTextTooltip component="span" text={snapshot.id} />
            <CopyToClipboardIcon text={snapshot.ccVolumeId} fontSize={11} />
          </Typography>

          {reportBlocks.map((report, idx) => (
            <ReportsBlock
              data-testid={`reports-block-${idx}`}
              key={idx}
              {...report}
            />
          ))}
        </CardContent>
      </StyledCard>
    </Box>
  )
}

export default memo(SliderItem)
