export interface Validator {
  validate(value: string): boolean
  errorMessage?: string
}

export abstract class BaseValidator implements Validator {
  protected value: string

  constructor(value: string) {
    this.value = value
  }

  abstract validate(value: string): boolean
  abstract get errorMessage(): string | undefined
}
