// eslint-disable-next-line import/no-extraneous-dependencies
import { OvaAccount } from 'blues-corejs/dist'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'
import { FormData } from '@features/management-view/backup-connector/form/validations/schema'
import { StrHelper } from '@lib/helpers'
import {
  DashboardViewOptions,
  dashboardViewTypes,
} from '@features/management-view'
import {
  CURRENT_VIEW_VERSION,
  DEFAULT_VIEWS,
} from '@features/DashboardV4/management-view/consts'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  TabViewInterface,
  ViewKind,
} from '@features/DashboardV4/management-view'
import { getViewsFromConfig } from '@features/DashboardV4'
import { isOVASliceCriteria } from '@features/DashboardV4/management-view/slice-criteria-guards'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AWSSliceCriteria } from 'blues-corejs/dist/models/inventory.model'
// eslint-disable-next-line import/no-extraneous-dependencies
import { OVASliceCriteria } from 'blues-corejs/dist/models/ova-account'
import { getOvaDashboardState } from '@features/ova-dashboard/ova-dashboard-store'

export type OptionType = {
  value: string | number
  label: string
}

export const transformAccountData = (
  accounts: Array<OvaAccount>
): {
  accountOptions: Array<OptionType>
  backupProviderOptions: Array<OptionType>
} => {
  const accountOptions: Array<OptionType> = []
  const providerMap = new Map<string, OptionType>()

  for (const account of accounts) {
    accountOptions.push({
      value: account.id,
      label: account.name,
    })

    for (const provider of account.backupProvidersList) {
      const providerKey = provider.toString()
      if (!providerMap.has(providerKey)) {
        const providerOption: OptionType = {
          value: provider,
          label: HUMAN_READABLE_OVA_BACKUP_PROVIDER[provider],
        }
        providerMap.set(providerKey, providerOption)
      }
    }
  }

  return {
    accountOptions,
    backupProviderOptions: Array.from(providerMap.values()),
  }
}

export const createFormData = (data: FormData, id?: string) => {
  return {
    name: data.name,
    id: id ? id : StrHelper.generateUUID(),
    variant: dashboardViewTypes[DashboardViewOptions.OVA],
    slice: {
      accountIds: data.accounts?.map((vendor) => vendor.value as string) || [],
      backupVendors:
        data.backupVendors?.map((vendor) => vendor.value as number) || [],
    },
    version: CURRENT_VIEW_VERSION,
    isDefaultDashboard: data.isDefaultDashboard,
    kind: ViewKind.OVA,
  }
}

export const getUpdatedViewsList = (
  views: Array<TabViewInterface>,
  newView: TabViewInterface,
  newViewIsDefault: boolean
) => {
  if (!newViewIsDefault) {
    return [...views, newView]
  }

  const previousViewsWithoutDefaultOne = [...views].map((view) => ({
    ...view,
    isDefaultDashboard: false,
  }))

  return [...previousViewsWithoutDefaultOne, newView]
}

export function getCurrentView(t: string | Array<string>) {
  if (!t) {
    return null
  }

  const queryViewIndex = Number(StrHelper.base64Decode(String(t)))

  if (Number.isNaN(queryViewIndex)) {
    return null
  }

  const availableViews = [...DEFAULT_VIEWS, ...getViewsFromConfig()]
  const view = availableViews[queryViewIndex]

  if (!view) {
    console.error(`No view found at index ${queryViewIndex}`)
    return null
  }

  return view
}

function setBackupProvidersIfAvailable(slice: OVASliceCriteria): void {
  if (slice.backupVendors) {
    const { actions } = getOvaDashboardState()
    actions.setBackupProviders(slice.backupVendors)
  }
}

function setAccountIdsIfAvailable(slice: OVASliceCriteria): void {
  if (slice.accountIds) {
    const { actions } = getOvaDashboardState()
    actions.setOvaAccountIds(slice.accountIds)
  }
}

export function handleSliceCriteria(
  slice: AWSSliceCriteria | OVASliceCriteria
): void {
  if (!isOVASliceCriteria(slice)) {
    return
  }

  setBackupProvidersIfAvailable(slice)
  setAccountIdsIfAvailable(slice)
}
