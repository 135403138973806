import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

// BasePortalButtons - the main component which decides where to draw
//    - in portal - with a fixed position
//    - in component

// BPSubComponent - subcomponent - we need it because the main component should
// have wrap in order to get information about the position even if we draw in portal

// After the first rendering we calculate the height for the wrap, we need it because
// if all children are rendered into portal - we can not determine if the block in the
// view port, also we will have jumps in the layout after rendering in the main layout

const LEFT_SIDE_MENU_WIDTH = 0

function BPSubComponent({
  shouldShowInPortal,
  children,
  className = '',
}: {
  shouldShowInPortal: boolean
  children: ReactNode
  className?: string
}) {
  const [left, setLeft] = useState<number>(LEFT_SIDE_MENU_WIDTH)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  function updateLeft(animationTime = 0) {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      const currentMenuWidth =
        document.querySelector('.wrap-1602571315353')?.getBoundingClientRect()
          .width ?? LEFT_SIDE_MENU_WIDTH
      setLeft(Math.round(currentMenuWidth))
    }, animationTime)
  }

  useEffect(() => {
    updateLeft()

    function onMouseDown(e: any) {
      if (e.target.classList.contains('shrinkArrow')) {
        updateLeft(180)
      }
    }

    document.addEventListener('mousedown', onMouseDown)
    return () => {
      document.removeEventListener('mousedown', onMouseDown)
    }
  }, [])

  if (shouldShowInPortal) {
    const portal = document.querySelector('#base-portal-root')
    if (portal) {
      return createPortal(
        <div
          className={`wrap-1623056937441 ${className}`}
          style={{
            left,
          }}
        >
          <div className="basePortalButtonsContent">{children}</div>
        </div>,
        portal
      )
    }
  }
  return <>{children}</>
}

interface Props {
  children?: ReactNode
  className?: string
  alwaysInPortal?: boolean
}

function BasePortalButtons({
  children,
  className = '',
  alwaysInPortal = false,
}: Props) {
  const [shouldShowInPortal, setShouldShowInPortal] = useState<boolean>(false)
  const wrapRef = useRef<HTMLDivElement>(null)
  const [wrapHeight, setWrapHeight] = useState<number>(0)

  // calculate the element height - only on the first rendering
  useEffect(() => {
    if (wrapRef.current && !wrapHeight) {
      setWrapHeight(wrapRef.current.getBoundingClientRect().height)
    }
  }, [])

  useEffect(() => {
    // TODO: add throttle/debounce in order to prevent running very often
    const updateShouldShowPortal = () => {
      if (alwaysInPortal) {
        setShouldShowInPortal(true)
        return
      }
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight
      const rect = wrapRef.current?.getBoundingClientRect()
      if (rect) {
        setShouldShowInPortal(rect.top + wrapHeight > windowHeight)
      }
    }

    // base initiation
    updateShouldShowPortal()

    // assign events
    window.addEventListener('scroll', updateShouldShowPortal)
    return () => {
      window.removeEventListener('scroll', updateShouldShowPortal)
    }
  }, [wrapHeight])

  // add the min height in order to detect "in view" correctly
  // and prevent jumps in the layout
  const styles: any = {}
  if (wrapHeight > 0) {
    styles.minHeight = wrapHeight
  }

  return (
    <div ref={wrapRef} style={styles} className="jsBasePortalButtons">
      <BPSubComponent
        shouldShowInPortal={shouldShowInPortal}
        className={className}
      >
        {children}
      </BPSubComponent>
    </div>
  )
}

export default BasePortalButtons
