import React, { useEffect } from 'react'
// import ChangePasswordForm from '@forms/change-password/change-password.form'
// import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
// import ErrorGroupConstants from '@lib/constants/error-group.constant'
// import FormChangePassword from '@lib/interfaces/form/form-change-password.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestIsMfaEnabled,
  requestSessions,
  resetMfa,
  updateMfaEnabled,
  // updatePassword,
} from '@store/actions/user.action'
import { getIsMfaEnabled } from '@store/selectors/user.selector'
import Sessions from './sessions'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import DialogModal from '@components-composite/modals/DialogModal'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import { Button } from '@mui/material'
import { useUser } from '@features/authentication'
import { OAuthProvider } from '@features/authentication'
import { PublicRoutes } from '@lib/router/routes/public'
import { useNavigation } from '@lib/router'

function AccountSecurity() {
  const router = useNavigation()
  const dispatch = useDispatch()
  const user = useUser()

  // const updatePasswordLoading = usePreloader(PreloaderConstants.UPDATE_PASSWORD)

  const mfaLoading = usePreloaderAny([
    PreloaderConstants.REQUEST_IS_MFA_ENABLED,
    PreloaderConstants.UPDATE_MFA_ENABLED,
    PreloaderConstants.RESET_MFA,
  ])

  useEffect(() => {
    dispatch(requestSessions())
    dispatch(requestIsMfaEnabled())
  }, [])

  const isMfaEnabled = useSelector(getIsMfaEnabled)

  const oauthProvider = new OAuthProvider(user?.sub as string)

  const handleRedirectCallback = () => {
    router.push(PublicRoutes.logout)
  }

  const { openModal: openResetMfaModal, modalProps: resetMfaModalProps } =
    useModal<string>(Modal.resetMfa, () => {
      dispatch(resetMfa(handleRedirectCallback))
    })

  // const onSubmit = (data: FormChangePassword) => {
  //   dispatch(updatePassword(data, handleRedirectCallback))
  // }

  const onUpdateMfaEnabled = (value: boolean) => {
    dispatch(updateMfaEnabled(value, handleRedirectCallback))
  }

  const onResetMfa = () => {
    openResetMfaModal()
  }

  return (
    <div className="wrap-1603974259805">
      {oauthProvider.isGoogleOrMicrosoft && (
        <div className="asProviderBlock">
          <img
            className="jsLogo"
            src={oauthProvider.logoPath}
            alt="logo"
            width={64}
            height={64}
          />
          <div className="jsLabel">{oauthProvider.label}</div>
        </div>
      )}

      <>
        <h4 className="jsMFAuthentication">Multi-Factor Authentication</h4>
        <div className="mb20 relative">
          <V2Checkbox
            checked={isMfaEnabled}
            onChange={onUpdateMfaEnabled}
            disabled={mfaLoading}
            label="MFA Enabled"
          />
          <PreloaderSmall show={mfaLoading} top={4} left={160} />
        </div>
        <div className="asBtnError">
          <Button
            disabled={mfaLoading}
            onClick={onResetMfa}
            variant="contained"
          >
            Reset MFA
          </Button>
        </div>
      </>

      {/*{oauthProvider.isUsernamePassword && (*/}
      {/*  <>*/}
      {/*    <h4 className="subtitle jsChangePasswordTitle">Change password</h4>*/}
      {/*    <ChangePasswordForm*/}
      {/*      loading={updatePasswordLoading}*/}
      {/*      onSubmit={onSubmit}*/}
      {/*      errorGroups={[ErrorGroupConstants.USER]}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
      <Sessions />
      <DialogModal
        description="You are going to reset MFA, please confirm the action in order to proceed"
        {...resetMfaModalProps}
      />
    </div>
  )
}

export default AccountSecurity
