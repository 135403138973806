/* eslint-disable import/no-extraneous-dependencies */
import { OvaAccount, OvaBackupProvider } from 'blues-corejs/dist'

export function getPossibleOvaProviders(ovaAccountsList: Array<OvaAccount>) {
  const backupProvidersMap = new Set<OvaBackupProvider>()
  ovaAccountsList.forEach((ovaAccount) => {
    ovaAccount.backupProvidersList.forEach((backupProvider) => {
      if (!backupProvidersMap.has(backupProvider)) {
        backupProvidersMap.add(backupProvider)
      }
    })
  })

  return Array.from(backupProvidersMap)
}
