import { SystemHelper } from '@lib/helpers'
import ToastHelper from '@lib/helpers/toast.helper'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'
import { EntropyDetectionNotificationsStatus } from '@lib/models/tenant-settings/types'
import {
  useGetTenantSettings,
  useIsTenantSettingsLoading,
  useUpdateTenantSettings,
} from '@lib/zustand/tenant-settings'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StyledSaveSettingsButton } from './styles'

enum EntropyDetectionNotificationsTextStatus {
  ENABLE = 'Enable',
  DISABLE = 'Disable',
}

function getEntropyDetectionNotificationsTextStatus(
  isEntropyDetectionEnabled: boolean
): EntropyDetectionNotificationsTextStatus {
  return isEntropyDetectionEnabled
    ? EntropyDetectionNotificationsTextStatus.ENABLE
    : EntropyDetectionNotificationsTextStatus.DISABLE
}

interface FormValues {
  trendsEnabled: EntropyDetectionNotificationsTextStatus
}

export function EntropyDetectionNotificationRadio() {
  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()

  const tenantSettings = useGetTenantSettings()

  const isTenantSettingsLoading = useIsTenantSettingsLoading()

  const { updateTenantSettings } = useUpdateTenantSettings()

  const { control, formState, handleSubmit, reset, setValue } =
    useForm<FormValues>({
      defaultValues: {
        trendsEnabled: getEntropyDetectionNotificationsTextStatus(
          !!tenantSettings?.isEntropyDetectionEnabled
        ),
      },
    })

  const handleSubmitForm = async ({ trendsEnabled }: FormValues) => {
    const newEntropyDetectionStatus =
      trendsEnabled === EntropyDetectionNotificationsTextStatus.ENABLE
        ? EntropyDetectionNotificationsStatus.ENABLED
        : EntropyDetectionNotificationsStatus.DISABLED

    if (!tenantSettings) {
      return
    }

    try {
      await updateTenantSettings({
        entropyDetectionStatus: newEntropyDetectionStatus,
      })

      const newEntropyDetectionValue =
        getEntropyDetectionNotificationsTextStatus(
          tenantSettings.isEntropyDetectionEnabled
        )

      ToastHelper.success('Entropy Analysis settings updated successfully')

      setValue('trendsEnabled', newEntropyDetectionValue)
      reset({
        trendsEnabled: newEntropyDetectionValue,
      })
    } catch (error: any) {
      ToastHelper.error('Failed to update Entropy Analysis settings')

      if ('message' in error) {
        SystemHelper.sendSentryIfProd(
          `Update Tenant Settings Error: ${error.message}`
        )
      }
    }
  }

  if (!isEntropyEnabled) {
    return null
  }

  return (
    <Stack marginTop="16px">
      <FormControl component="form" onSubmit={handleSubmit(handleSubmitForm)}>
        <FormLabel>
          <Typography fontSize="12px" color="#171717" fontWeight="500">
            Entropy Analysis
          </Typography>
        </FormLabel>
        <Controller
          control={control}
          name="trendsEnabled"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                disabled={isTenantSettingsLoading}
                control={<Radio />}
                value={EntropyDetectionNotificationsTextStatus.ENABLE}
                label={<Typography fontSize="12px">Enable</Typography>}
              />
              <FormControlLabel
                disabled={isTenantSettingsLoading}
                control={<Radio />}
                value={EntropyDetectionNotificationsTextStatus.DISABLE}
                label={<Typography fontSize="12px">Disable</Typography>}
              />
            </RadioGroup>
          )}
        />
        <StyledSaveSettingsButton
          type="submit"
          variant="contained"
          disabled={!formState.isDirty || isTenantSettingsLoading}
        >
          Save
        </StyledSaveSettingsButton>
      </FormControl>
    </Stack>
  )
}
