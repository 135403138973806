/* eslint-disable import/no-extraneous-dependencies */
import { useState, ChangeEvent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'

import Grid from '@mui/material/Grid2'

import {
  AWSReports,
  FormOptionValue,
  FormOptionValueSlug,
  ReportForm,
  ReportPeriodValue,
} from 'ui-v2/src/lib/models/reports'

import ReportHeader from './report-header'
import OnDemandForm from './dynamic-form/on-demad-form'
import ScheduleForm from './dynamic-form/schedule-form'
import {
  formOptions,
  reportOptions,
  TIME_RANGE_MENU_ITEMS,
} from 'ui-v2/src/lib/constants/reports.constant'

import { useForm, FormProvider } from 'react-hook-form'
import ReportDateRangeConstant, {
  getReportDateRangeName,
} from 'ui-v2/src/lib/constants/report-date-range.constant'
import TimeFormatConstants from 'ui-v2/src/lib/constants/time-format.constant'

import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule/report-schedule'

import { getDefaultValues } from '../../../lib/helpers/reports/get-default-values'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import { useCustomFlags } from '../../../lib/helpers/reports/use-custom-flags'
import { useReportScheduleListQuery } from 'ui-v2/src/hooks/queries/reports/report-schedule-list'

const shouldDisableFormControl = (
  isExceedingMaxLength: boolean,
  optionValue: FormOptionValue
): boolean => {
  return isExceedingMaxLength && optionValue === FormOptionValue.SCHEDULE
}

const GenerateReportDrawer = ({
  scheduleDataLength,
}: GenerateReportDrawerProps) => {
  const navigate = useNavigate()
  const { maxAmountOfReportSchedules } = useCustomFlags()
  const {
    drawer,
    reportFormSlug = FormOptionValueSlug.ON_DEMAND,
    kindSlug,
    reportId,
  } = useParams()

  const reportForm =
    reportFormSlug === FormOptionValueSlug.ON_DEMAND
      ? FormOptionValue.ON_DEMAND
      : reportFormSlug === FormOptionValueSlug.SCHEDULE
        ? FormOptionValue.SCHEDULE
        : ''

  const [selectedReportForm, setSelectedReportForm] = useState<
    FormOptionValue | string
  >(reportForm as FormOptionValue)

  const selectReportType = reportOptions.find(
    (option) => option.slug === kindSlug
  )
  const [reportTypeLabel, setReportTypeLabel] = useState<string>(
    selectReportType?.label ?? ''
  )

  const { data: scheduleList, isLoading: isScheduleListLoading } =
    useReportScheduleListQuery()

  const method = useForm<ReportForm>({
    defaultValues: {
      reportType:
        selectReportType?.value?.toString() ?? AWSReports.awsExecutiveReport,
      description: '',
      reportPeriod: {
        label: getReportDateRangeName(ReportDateRangeConstant.ThisMonth),
        value: ReportDateRangeConstant.ThisMonth,
      },
      timeRange: TIME_RANGE_MENU_ITEMS[0],
      dateRange: [],
      format: [],
      ovaAccounts: [],
      backupProvider: [],
      name: '',
      frequency: '',
      scheduleStartDate: new Date(),
      rangeOfData: ReportPeriodValue.CURRENT_DAY,
      timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
      recipients: [],
      customSchedule: null,
    },
  })

  const { reset, setValue } = method

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as FormOptionValue
    setSelectedReportForm(newValue)
    const formSlug =
      newValue === FormOptionValue.ON_DEMAND
        ? FormOptionValueSlug.ON_DEMAND
        : newValue === FormOptionValue.SCHEDULE
          ? FormOptionValueSlug.SCHEDULE
          : ''
    reset()
    navigate(`/reports/schedules/form/${formSlug}/${kindSlug}`)
  }

  const handleScheduleFormData = (scheduleReport: ReportSchedule) => {
    const {
      name,
      description,
      frequency,
      customSchedule,
      timezone,
      recipients,
      scheduleStartDate,
      rangeOfData,
      ovaAccounts,
      backupProvider,
    } = getDefaultValues(scheduleReport)

    setValue('name', name)
    setValue('description', description)

    setValue('frequency', frequency as any)
    setValue('scheduleStartDate', scheduleStartDate)
    setValue('rangeOfData', rangeOfData as any)

    setValue('customSchedule', customSchedule)
    setValue('timezone', timezone as TimeFormatConstants)
    setValue('recipients', recipients as any)

    setValue('ovaAccounts', ovaAccounts)
    setValue('backupProvider', backupProvider)
  }

  const handleCloseDrawer = () => {
    setSelectedReportForm(FormOptionValue.ON_DEMAND)
    reset()
    navigate('/reports/schedules')
  }

  const shouldDisableScheduleForm = shouldDisableFormControl(
    scheduleDataLength >= maxAmountOfReportSchedules,
    FormOptionValue.SCHEDULE
  )

  const enhancedFormOptions = formOptions.map((form) => ({
    ...form,
    isDisabled:
      form.value === FormOptionValue.SCHEDULE && shouldDisableScheduleForm,
  }))

  useEffect(() => {
    if (reportForm) {
      setSelectedReportForm(reportForm)
    }
  }, [reportForm])

  useEffect(() => {
    if (reportId && scheduleList) {
      const scheduleReport = scheduleList?.listReportSchedules?.find(
        (schedule) => schedule.id === reportId
      )

      if (scheduleReport && FormOptionValueSlug.SCHEDULE === reportFormSlug) {
        handleScheduleFormData(scheduleReport)
      }
    }
  }, [reportId, scheduleList])

  return (
    <Drawer open={drawer === 'form' && !!kindSlug} onClose={handleCloseDrawer}>
      {/**Report Header */}
      <DrawerContent isLoading={isScheduleListLoading} isEmpty={false}>
        <FormProvider {...method}>
          <ReportHeader
            selectedReportForm={selectedReportForm}
            reportTypeLabel={reportTypeLabel}
            reportId={reportId}
            handleCloseDrawer={handleCloseDrawer}
          />
          <Stack
            spacing={2}
            sx={{
              paddingX: 2.5,
              paddingBottom: 2.5,
            }}
          >
            {!reportId && (
              <Grid container spacing={2}>
                <Grid size={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedReportForm}
                      onChange={handleRadioChange}
                    >
                      {enhancedFormOptions.map((form) => (
                        <FormControlLabel
                          key={form.value}
                          value={form.value}
                          control={<Radio />}
                          label={form.label}
                          disabled={form.isDisabled}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {selectedReportForm === FormOptionValue.ON_DEMAND && (
              <OnDemandForm
                selectReportType={selectReportType}
                reportId={reportId}
                setReportTypeLabel={setReportTypeLabel}
              />
            )}
            {selectedReportForm === FormOptionValue.SCHEDULE && (
              <ScheduleForm
                selectReportType={selectReportType}
                reportId={reportId}
                setReportTypeLabel={setReportTypeLabel}
              />
            )}
          </Stack>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}
interface GenerateReportDrawerProps {
  scheduleDataLength: number
}
export default GenerateReportDrawer
