import React, { memo, ReactNode } from 'react'
import { Popover, Typography } from '@mui/material'

export interface Props {
  children: ReactNode
  parent: ReactNode
  showOnParentTop?: boolean
  shouldShow?: boolean
}

function PopoverIcon({
  children,
  parent,
  showOnParentTop = false,
  shouldShow = true,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div className="wrap-1674124966250 jsPopoverIconComponent">
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        component={'div'}
        className="jsPopoverIcon"
      >
        {parent}
      </Typography>
      <Popover
        className="jsTooltipName"
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open && shouldShow}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: showOnParentTop ? 'top' : 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: showOnParentTop ? 'bottom' : 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          component={'div'}
          sx={{ p: 1 }}
          className="jsPopoverChildren"
        >
          <div className="textPopoverIcon">{children}</div>
        </Typography>
      </Popover>
    </div>
  )
}

export default memo(PopoverIcon)
