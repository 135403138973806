import { Box, Modal, Button, Stack } from '@mui/material'
import CustomSchedule from './custom-schedule'
import { CustomScheduleState } from 'ui-v2/src/lib/models/reports'
import { useState } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const CustomScheduleModal = ({
  open,
  initialValues,
  handleSaveCustomSchedule,
  handleCloseCustomScheduleModal,
}: CustomScheduleModalProps) => {
  const [customScheduleState, setCustomScheduleState] =
    useState<CustomScheduleState | null>(null)

  const handleCreate = () => {
    if (customScheduleState) {
      handleSaveCustomSchedule(customScheduleState)
    }
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CustomSchedule
          initialValues={initialValues}
          getStateCallback={(state) => setCustomScheduleState(state)}
        />
        <Stack
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          columnGap={2}
          mt={3}
        >
          <Button
            onClick={handleCloseCustomScheduleModal}
            variant="text"
            sx={{ textTransform: 'capitalize' }}
            size="large"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreate}
            sx={{ textTransform: 'capitalize' }}
            size="large"
          >
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
interface CustomScheduleModalProps {
  open: boolean
  initialValues: CustomScheduleState | null
  handleSaveCustomSchedule: (customScheduleState: CustomScheduleState) => void
  handleCloseCustomScheduleModal: () => void
}
export default CustomScheduleModal
