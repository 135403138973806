/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllRedStacksList } from '@store/selectors/rex.selector'
import { useSelector, useDispatch } from 'react-redux'
import { NO_JOBS_MESSAGE } from './consts'
import { Box } from '@mui/material'
import { Table } from '@components/table'
import { getColumnsDefinitions } from './get-deployment-jobs-columns-definitions'
import DateRangeSelect from '@components-composite/date-range-select/DateRangeSelect'
import { DateRangeTuple, DefinedDateRangeTuple } from '@lib/engine-types'
import { FilterModalButton } from '../shared'
import { FiltersModal } from './filters-modal'
import { SelectedFiltersChipsBlock } from './selected-filters-chips-block'
import styles from './deployment-jobs.module.css'
import { useTrackChangedJobs } from './use-track-changed-jobs'
import { useModalFilterActions } from './filters-modal/deployment-filters-store'
import {
  useDeploymentJobsFiltersActions,
  useDeploymentJobsIsLoading,
  useDeploymentJobsList,
  useDeploymentJobsStoreActions,
  useDeploymentJobsTimeRange,
  useFiltersSubscription,
  useSelectedDeploymentJobFilters,
  useShouldDisableFilterButton,
} from './stores'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import moment from 'moment-timezone'

function Filters() {
  const timeRangeState = useDeploymentJobsTimeRange()
  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()
  const timeRangeWithTimeZone: DateRangeTuple = [
    moment(timeRangeState[0]).tz(timeZone.value),
    moment(timeRangeState[1]).tz(timeZone.value),
  ]
  const minMaxRange: DateRangeTuple = [
    moment().tz(timeZone.value).subtract(1, 'month').startOf('month'),
    moment().endOf('day').tz(timeZone.value),
  ]

  const shouldDisableFilterButton = useShouldDisableFilterButton()

  const { setTimeRange } = useDeploymentJobsFiltersActions()

  const { openFilterDialog } = useModalFilterActions()

  function handleTimeRangeChange(timeRange: DateRangeTuple) {
    const convertTimeRange = [timeRange[0]?.valueOf(), timeRange[1]?.valueOf()]
    setTimeRange(convertTimeRange as DefinedDateRangeTuple)
  }

  const selectedFilters = useSelectedDeploymentJobFilters()

  function handleFilterButtonClick() {
    openFilterDialog(selectedFilters)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="25px"
    >
      <FilterModalButton
        disabled={shouldDisableFilterButton}
        onButtonClick={handleFilterButtonClick}
      />
      <DateRangeSelect
        value={timeRangeWithTimeZone}
        onChange={handleTimeRangeChange}
        minMaxRange={minMaxRange}
      />
    </Box>
  )
}

export function DeploymentJobs() {
  const allRedStacksList = useSelector(getAllRedStacksList)

  const { fetchPossibleTenantJobFilters, resetDeploymentJobsFiltersState } =
    useDeploymentJobsFiltersActions()

  const {
    resetDeploymentJobsState,
    fetchInitialDeploymentJobs,
    fetchPaginatedDeploymentJobs,
  } = useDeploymentJobsStoreActions()

  const dispatch = useDispatch()

  const deploymentJobsList = useDeploymentJobsList()

  const isDeploymentJobsLoading = useDeploymentJobsIsLoading()

  useEffect(() => {
    dispatch(getAllRedStacks())
    fetchInitialDeploymentJobs()
    fetchPossibleTenantJobFilters()

    return () => {
      resetDeploymentJobsState()
      resetDeploymentJobsFiltersState()
    }
  }, [])

  const observableState = useInfiniteScrollDataFetch({
    initialItems: [],
    fetchNextBatch: fetchPaginatedDeploymentJobs,
  })

  useTrackChangedJobs()

  useFiltersSubscription()

  return (
    <Box className="innerContent">
      <Box>
        <FiltersModal />
        <Filters />
        <SelectedFiltersChipsBlock />
        <Table
          className={styles['tenant-jobs-table']}
          data={deploymentJobsList}
          // @ts-ignore Please help me fix that!
          columns={getColumnsDefinitions({ allRedStacksList })}
          generalProps={{
            isLoading: isDeploymentJobsLoading,
            noDataMessage: NO_JOBS_MESSAGE,
          }}
          advancedProps={{
            observableState,
            expandableRowKey: 'childrenList',
          }}
        />
      </Box>
    </Box>
  )
}
