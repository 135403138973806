import { create } from 'zustand'
import { EntropyDetectionClient } from '@lib/clients/entropy-detection'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import type { LiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { subscribeWithSelector } from 'zustand/middleware'
import { useContext, useEffect } from 'react'
import { ObjHelper } from '@lib/helpers'
import {
  getEntropyTrendsTableActions,
  useEntropyTrendsTableActions,
} from '@features/entropy-trends/components/entropy-trends-table/entropy-trends-table-store'
import { EntropyFiltersContext } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'
import { buildTimeRangeForRequest } from '@features/entropy-trends/components/entropy-trends-graph/utils'
import { TimeGranularity } from '@lib/clients/entropy-detection/types'
import { FetchEntropyTrendsHistoryParams } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/types'
import { EntropyTrendsHistory } from '@lib/models/anomalies'
import { incrementEntropyTrendsTimeSelection } from '@features/DashboardV4/entropy-trends/types'

export enum EntropyTrendsTimeSelection {
  TODAY = 'Today',
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_DATE = 'Custom date',
}

export interface EntropyFiltersState {
  possibleFilters: {
    assetsList: Array<AWSAsset>
    accountsList: Array<string>
    regionsList: Array<string>
  }
  selectedFilters: {
    selectedAssets: Set<string>
    selectedAccounts: Set<string>
    selectedRegions: Set<string>
    anomaliesIdsList: Set<string>
    startAt: number
    endAt: number
  }
  entropyTrendsHistory: EntropyTrendsHistory
  timeGranularity: TimeGranularity
  timeSelection: EntropyTrendsTimeSelection
  isLoading: boolean
}

const client = new EntropyDetectionClient()

async function fetchInitialEntropyTrendsHistory(
  filters: FetchEntropyTrendsHistoryParams
): Promise<[EntropyTrendsHistory, FetchEntropyTrendsHistoryParams]> {
  const entropyTrendsHistoryResponse =
    await client.getEntropyTrendsHistory(filters)

  if (entropyTrendsHistoryResponse.totalAnomalyFindingsCount > 0) {
    return [entropyTrendsHistoryResponse, filters]
  }

  const incrementedTimeSelection = incrementEntropyTrendsTimeSelection(
    filters.timeSelection
  )

  if (incrementedTimeSelection === filters.timeSelection) {
    return [entropyTrendsHistoryResponse, filters]
  }

  const newTimeRange = buildTimeRangeForRequest(
    incrementedTimeSelection,
    filters.startAt,
    filters.endAt
  )

  return fetchInitialEntropyTrendsHistory({
    ...filters,
    timeSelection: incrementedTimeSelection,
    startAt: newTimeRange.startDate,
    endAt: newTimeRange.endDate,
    timeGranularity: newTimeRange.timeGranularity,
  })
}

interface EntropyFiltersActions {
  fetchFilters: (
    timeSelection: EntropyTrendsTimeSelection,
    startAt?: number,
    endAt?: number
  ) => void
  fetchInitialFilters: (
    liveAssetsCriteria: LiveAssetsCriteria,
    startAt: number,
    endAt: number
  ) => void
  setFilters: (filters: Partial<EntropyFiltersState['selectedFilters']>) => void
  setTimeSelection: (timeSelection: EntropyTrendsTimeSelection) => void
  fetchInitialEntropyTrendsHistory: (
    filters: Partial<FetchEntropyTrendsHistoryParams>,
    liveAssetsCriteria: LiveAssetsCriteria
  ) => Promise<void>
  fetchEntropyTrendsHistory: (
    filters: Partial<FetchEntropyTrendsHistoryParams>
  ) => Promise<void>
  resetState: () => void
}

type EntropyFiltersStore = EntropyFiltersState & {
  actions: EntropyFiltersActions
}

export type EntropyFiltersZustandStore = ReturnType<
  typeof createEntropyFiltersStore
>

const getInitialState = (): EntropyFiltersState => {
  const initialTimeSelection = EntropyTrendsTimeSelection.TODAY
  const timerange = buildTimeRangeForRequest(initialTimeSelection)
  return {
    entropyTrendsHistory: new EntropyTrendsHistory([]),
    isLoading: false,
    timeGranularity: timerange.timeGranularity,
    timeSelection: initialTimeSelection,
    possibleFilters: {
      accountsList: [],
      assetsList: [],
      regionsList: [],
    },
    selectedFilters: {
      selectedAccounts: new Set(),
      selectedAssets: new Set(),
      selectedRegions: new Set(),
      anomaliesIdsList: new Set(),
      startAt: timerange.startDate,
      endAt: timerange.endDate,
    },
  }
}

export const createEntropyFiltersStore = () => {
  return create<EntropyFiltersStore>()(
    subscribeWithSelector((set, get) => {
      return {
        ...getInitialState(),
        actions: {
          resetState: () => {
            set(getInitialState())
          },
          setTimeSelection: (timeSelection) => {
            set({
              timeSelection,
            })
          },
          fetchFilters: async (
            timeSelection: EntropyTrendsTimeSelection,
            initialStartAt?: number,
            initialEndAt?: number
          ) => {
            const { startAt: selectedStartAt, endAt: selectedEndAt } =
              get().selectedFilters

            const startAt = initialStartAt || selectedStartAt
            const endAt = initialEndAt || selectedEndAt

            set({
              isLoading: true,
              selectedFilters: {
                ...get().selectedFilters,
                selectedAssets: new Set(),
                selectedAccounts: new Set(),
                selectedRegions: new Set(),
              },
            })

            const timeRange = buildTimeRangeForRequest(
              timeSelection,
              startAt,
              endAt
            )

            try {
              const assetsList =
                await client.getFiltersForListAwsAssetSummaries(
                  timeRange.startDate,
                  timeRange.endDate
                )

              const accountsList = assetsList.map((asset) => asset.awsAccountId)

              const regionsList = assetsList.map((asset) => asset.awsRegion)

              set({
                possibleFilters: {
                  accountsList,
                  assetsList,
                  regionsList,
                },
              })

              await get().actions.fetchEntropyTrendsHistory({
                startAt: timeRange.startDate,
                endAt: timeRange.endDate,
                assetIdsList: [],
                timeSelection,
                timeGranularity: timeRange.timeGranularity,
                assetsFilter: {},
              })
            } catch (error) {}
          },
          fetchInitialFilters: async (
            liveAssetsCriteria: LiveAssetsCriteria,
            startAt: number,
            endAt: number
          ) => {
            try {
              set({
                isLoading: true,
              })

              const assetsList =
                await client.getFiltersForListAwsAssetSummaries(startAt, endAt)

              const accountsList = assetsList.map((asset) => asset.awsAccountId)

              const regionsList = assetsList.map((asset) => asset.awsRegion)

              const regionsListInCriteria = liveAssetsCriteria.regions
                ? regionsList.filter((region) =>
                    liveAssetsCriteria.regions?.includes(region)
                  )
                : []

              const accountsListInCriteria = liveAssetsCriteria.accountIds
                ? accountsList.filter((account) =>
                    liveAssetsCriteria.accountIds?.includes(account)
                  )
                : []

              set({
                possibleFilters: {
                  accountsList,
                  assetsList,
                  regionsList,
                },
                selectedFilters: {
                  ...get().selectedFilters,
                  selectedAccounts: new Set(accountsListInCriteria),
                  selectedAssets: new Set(),
                  selectedRegions: new Set(regionsListInCriteria),
                  anomaliesIdsList: new Set(),
                },
              })
            } catch (error) {}
          },
          setFilters: (filters) => {
            set((state) => ({
              selectedFilters: {
                ...state.selectedFilters,
                ...filters,
              },
            }))
          },
          fetchInitialEntropyTrendsHistory: async (
            filters: Partial<FetchEntropyTrendsHistoryParams>,
            liveAssetsCriteria: LiveAssetsCriteria
          ) => {
            const { selectedFilters, timeGranularity, timeSelection } = get()
            const { fetchInitialData, setLoading: setTableDataIsLoading } =
              getEntropyTrendsTableActions()
            setTableDataIsLoading(true)

            const mergedAssetsFilter = {
              accounts: [
                ...(filters.assetsFilter?.accounts ?? []),
                ...selectedFilters.selectedAccounts,
              ],
              regions: [
                ...(filters.assetsFilter?.regions ?? []),
                ...selectedFilters.selectedRegions,
              ],
              hasReplicatedBackups: filters.assetsFilter?.hasReplicatedBackups,
              excludeTags: filters.assetsFilter?.excludeTags,
              includeTags: filters.assetsFilter?.includeTags,
            }

            const assetIdsList = filters.assetIdsList
              ? [...filters.assetIdsList, ...selectedFilters.selectedAssets]
              : [...selectedFilters.selectedAssets]

            const newFilters = {
              startAt: filters.startAt ?? selectedFilters.startAt,
              endAt: filters.endAt ?? selectedFilters.endAt,
              timeGranularity: filters.timeGranularity ?? timeGranularity,
              timeSelection: filters.timeSelection ?? timeSelection,
              assetIdsList,
              assetsFilter: mergedAssetsFilter,
            }

            const entropyTrendsHistoryResponse =
              await fetchInitialEntropyTrendsHistory(newFilters)

            await get().actions.fetchInitialFilters(
              liveAssetsCriteria,
              entropyTrendsHistoryResponse[1].startAt,
              entropyTrendsHistoryResponse[1].endAt
            )

            await fetchInitialData({
              startAt: newFilters.startAt,
              endAt: newFilters.endAt,
              assetIdsList: Array.from(newFilters.assetIdsList),
              anomaliesIdsList: [],
              assetsFilter: {
                accounts: Array.from(newFilters.assetsFilter.accounts),
                regions: Array.from(newFilters.assetsFilter.regions),
              },
            })

            set({
              isLoading: false,
              entropyTrendsHistory: entropyTrendsHistoryResponse[0],
              timeSelection: entropyTrendsHistoryResponse[1].timeSelection,
              timeGranularity: entropyTrendsHistoryResponse[1].timeGranularity,
              selectedFilters: {
                endAt: entropyTrendsHistoryResponse[1].endAt,
                startAt: entropyTrendsHistoryResponse[1].startAt,
                selectedAccounts: new Set(
                  entropyTrendsHistoryResponse[1].assetsFilter?.accounts || []
                ),
                selectedRegions: new Set(
                  entropyTrendsHistoryResponse[1].assetsFilter?.regions || []
                ),
                anomaliesIdsList: new Set(),
                selectedAssets: new Set(
                  entropyTrendsHistoryResponse[1].assetIdsList || []
                ),
              },
            })
          },
          fetchEntropyTrendsHistory: async (
            filters: Partial<FetchEntropyTrendsHistoryParams>
          ) => {
            const { selectedFilters, timeGranularity, timeSelection } = get()

            const assetIdsList = filters.assetIdsList
              ? filters.assetIdsList
              : [...selectedFilters.selectedAssets]

            const mergedAssetsFilter = {
              accounts: [
                ...(filters.assetsFilter?.accounts ?? []),
                ...selectedFilters.selectedAccounts,
              ],
              regions: [
                ...(filters.assetsFilter?.regions ?? []),
                ...selectedFilters.selectedRegions,
              ],
              hasReplicatedBackups: filters.assetsFilter?.hasReplicatedBackups,
              excludeTags: filters.assetsFilter?.excludeTags,
              includeTags: filters.assetsFilter?.includeTags,
            }

            const newFilters = {
              startAt: filters.startAt ?? selectedFilters.startAt,
              endAt: filters.endAt ?? selectedFilters.endAt,
              timeGranularity: filters.timeGranularity ?? timeGranularity,
              assetIdsList,
              assetsFilter: mergedAssetsFilter,
            }

            const entropyTrendsHistoryResponse =
              await client.getEntropyTrendsHistory(newFilters)

            set({
              isLoading: false,
              entropyTrendsHistory: entropyTrendsHistoryResponse,
              timeGranularity: newFilters.timeGranularity,
              timeSelection: filters.timeSelection ?? timeSelection,
              selectedFilters: {
                ...newFilters,
                selectedAssets: new Set(newFilters.assetIdsList),
                selectedAccounts: new Set(newFilters.assetsFilter.accounts),
                selectedRegions: new Set(newFilters.assetsFilter.regions),
                anomaliesIdsList: new Set(),
              },
            })
          },
        },
      }
    })
  )
}

export const useFilterSubscription = () => {
  const store = useContext(EntropyFiltersContext)
  const { fetchInitialData } = useEntropyTrendsTableActions()

  useEffect(() => {
    if (!store) {
      return
    }

    const unsubscribeTableFiltersFn = store.subscribe(
      ({
        selectedFilters: {
          anomaliesIdsList,
          selectedAccounts,
          selectedAssets,
          selectedRegions,
          startAt,
          endAt,
        },
      }) => {
        return {
          startAt,
          endAt,
          anomaliesIdsList: Array.from(anomaliesIdsList),
          selectedAccounts: Array.from(selectedAccounts),
          selectedAssets: Array.from(selectedAssets),
          selectedRegions: Array.from(selectedRegions),
        }
      },
      (filters) => {
        fetchInitialData({
          ...store.getState().selectedFilters,
          startAt: filters.startAt,
          endAt: filters.endAt,
          assetIdsList: Array.from(filters.selectedAssets),
          assetsFilter: {
            accounts: Array.from(filters.selectedAccounts),
            regions: Array.from(filters.selectedRegions),
          },
          anomaliesIdsList: Array.from(filters.anomaliesIdsList),
        })
      },
      {
        equalityFn: (a, b) => {
          return ObjHelper.isEqual(a, b)
        },
      }
    )

    return () => {
      unsubscribeTableFiltersFn()
    }
  }, [])
}
