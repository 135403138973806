import { Box } from '@mui/material'
import React from 'react'
import { WidgetHeader } from '../shared'
import { ServersWithThreats } from './components'
import { ServersWithLatestCleanRp } from './components/servers-with-latest-clean-rp'
import { Container } from './styles'
import { useFetchOvaThreatExposure } from './use-fetch-ova-threat-exposure'

/**
 * Threat Exposure widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19346-168063&mode=design&t=tQScFrA9cvNE4u4P-4)
 */
export default function ThreatExposure() {
  const { isLoading, serversWithLatestCleanRpRatio, serversWithThreats } =
    useFetchOvaThreatExposure()

  return (
    <Container>
      <WidgetHeader widgetTitle="Threat Exposure" />
      <Box
        display="flex"
        flexDirection="column"
        marginTop="10px"
        gap="18px"
        paddingX="16px"
      >
        <ServersWithThreats
          isLoading={isLoading}
          serversWithThreats={serversWithThreats}
        />
        <ServersWithLatestCleanRp
          isLoading={isLoading}
          serversWithLatestCleanRpRatio={serversWithLatestCleanRpRatio}
        />
      </Box>
    </Container>
  )
}
