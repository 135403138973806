/* eslint-disable import/no-extraneous-dependencies */
import { useRef, useState } from 'react'
import {
  ListBackupsForAssetClient,
  ListBackupsForAssetFilter,
  ListBackupsForAssetPagination,
} from '@lib/clients/backups/list-backups-for-asset'
import {
  ListBackupsForAsset as ListBackupsForAssetType,
  ListBackupsForAssetFiltersParams,
  ListBackupsForAssetRequest,
} from '@lib/clients/backups/list-backups-for-asset/types'
import { usePagination } from '@lib/hooks'
import { UseAssetBackupsDataFetcher } from './types'
import { useFetchStatus } from '@lib/hooks/api-hooks/use-fetch-status'
import { ListByAssetItemsRequest } from '@lib/clients/backups/types'
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
  OvaBackup,
} from 'blues-corejs/dist'

const listBackupsForAssetClient = new ListBackupsForAssetClient()

interface ExtendedUseAssetBackupsDataFetcher
  extends UseAssetBackupsDataFetcher {
  fetchInitial: (filter?: Partial<ListByAssetItemsRequest>) => Promise<void>
  fetchOnRefreshInterval: (
    filter?: Partial<ListByAssetItemsRequest>
  ) => Promise<void>
}

export const INITIAL_STATE: ListBackupsForAssetType = {
  awsbRpsList: [],
  ebsSnapshotsList: [],
  elastioRpsList: [],
  ovaBackupList: [],
}

async function fetchInitialBackupsList(
  combinedFilters: ListBackupsForAssetFiltersParams
) {
  return listBackupsForAssetClient.listBackupsForAsset(
    new ListBackupsForAssetFilter(combinedFilters)
  )
}

async function fetchBackupsListViaPagination(nextPageToken: string) {
  return listBackupsForAssetClient.listBackupsForAsset(
    new ListBackupsForAssetPagination(nextPageToken)
  )
}
type UnionBackupsTypes =
  | Array<AWSBRecoveryPoint>
  | Array<EBSSnapshot>
  | Array<ElastioRecoveryPoint>
  | Array<OvaBackup>

const unionBackups = (
  prevBackups: UnionBackupsTypes,
  newBackups: UnionBackupsTypes
) => {
  const backupMap = new Map(prevBackups.map((backup) => [backup.id, backup]))

  newBackups.forEach((newBackup) => {
    backupMap.set(newBackup.id, newBackup)
  })

  return Array.from(backupMap.values())
}

// TODO I am going to improve the hook with useCallback and useMemo.
export function useAssetBackupsDataFetcher(
  initialFilters?: ListBackupsForAssetRequest
): ExtendedUseAssetBackupsDataFetcher {
  const fetchStatus = useFetchStatus()
  const [backupsData, setBackupsData] =
    useState<ListBackupsForAssetType>(INITIAL_STATE)

  const previousFilters = useRef<ListBackupsForAssetFiltersParams | null>(null)

  const { updatePageTokenAndFlagFetched, pageToken, resetPagination } =
    usePagination()

  const updateBackupsData = (backupsResponse: ListBackupsForAssetType) => {
    setBackupsData((prevBackups) => ({
      awsbRpsList: [
        ...prevBackups.awsbRpsList,
        ...(backupsResponse.awsbRpsList || []),
      ],
      ebsSnapshotsList: [
        ...prevBackups.ebsSnapshotsList,
        ...(backupsResponse.ebsSnapshotsList || []),
      ],
      elastioRpsList: [
        ...prevBackups.elastioRpsList,
        ...(backupsResponse.elastioRpsList || []),
      ],
      ovaBackupList: [
        ...prevBackups.ovaBackupList,
        ...(backupsResponse.ovaBackupList || []),
      ],
    }))
  }

  const updateBackupsDataOnRefreshInterval = (
    backupsResponse: ListBackupsForAssetType
  ) => {
    setBackupsData((prevBackups) => {
      return {
        awsbRpsList: unionBackups(
          prevBackups.awsbRpsList,
          backupsResponse.awsbRpsList || []
        ) as Array<AWSBRecoveryPoint>,
        ebsSnapshotsList: unionBackups(
          prevBackups.ebsSnapshotsList,
          backupsResponse.ebsSnapshotsList || []
        ) as Array<EBSSnapshot>,
        elastioRpsList: unionBackups(
          prevBackups.elastioRpsList,
          backupsResponse.elastioRpsList || []
        ) as Array<ElastioRecoveryPoint>,
        ovaBackupList: unionBackups(
          prevBackups.ovaBackupList,
          backupsResponse.ovaBackupList || []
        ) as Array<OvaBackup>,
      }
    })
  }

  const handleFetchSuccess = ({
    backupsList,
    nextPageToken,
  }: {
    backupsList: ListBackupsForAssetType
    nextPageToken?: string
  }) => {
    updateBackupsData(backupsList)
    updatePageTokenAndFlagFetched(nextPageToken || undefined)
  }

  const resetData = () => {
    setBackupsData(INITIAL_STATE)
    resetPagination()
  }

  const setPreviousFilters = (newFilters: ListBackupsForAssetFiltersParams) => {
    previousFilters.current = newFilters
  }

  const fetchPagination = async () => {
    if (!pageToken) {
      return
    }

    fetchStatus.setFetching()
    try {
      const response = await fetchBackupsListViaPagination(pageToken as string)

      fetchStatus.setSuccess()
      handleFetchSuccess({
        backupsList: response,
        nextPageToken: response.pageToken,
      })
    } catch (error) {
      console.error(`Error fetching backups request - ${error}`)
    } finally {
      fetchStatus.reset()
    }
  }

  const fetchInitial = async (filters?: ListBackupsForAssetFiltersParams) => {
    const combinedFilters = {
      ...initialFilters,
      ...filters,
    }

    resetData()
    fetchStatus.setFetching()
    try {
      if (fetchStatus.isIdle()) {
        const response = await fetchInitialBackupsList(combinedFilters)
        setPreviousFilters(combinedFilters)
        fetchStatus.setSuccess()
        handleFetchSuccess({
          backupsList: response,
          nextPageToken: response.pageToken,
        })
      }
    } catch (error) {
      console.log(error)

      console.error(
        `Something went wrong with fetch asset request in ${__filename}: ${error}`
      )
      fetchStatus.setFailure()
    } finally {
      fetchStatus.reset()
    }
  }

  const fetchOnRefreshInterval = async (
    filters?: ListBackupsForAssetFiltersParams
  ) => {
    const combinedFilters = {
      ...initialFilters,
      ...filters,
    }

    try {
      const response = await fetchInitialBackupsList(combinedFilters)
      setPreviousFilters(combinedFilters)

      updateBackupsDataOnRefreshInterval(response)
    } catch (error) {
      console.log(error)

      console.error(
        `Something went wrong with fetch asset request in ${__filename}: ${error}`
      )
    }
  }

  return {
    fetchInitial,
    onFetchData: fetchPagination,
    data: backupsData,
    dataFetchStatus: fetchStatus.status,
    resetData,
    isLoadingData: fetchStatus.isLoading,
    fetchOnRefreshInterval,
  }
}
