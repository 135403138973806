import { SvgIcon, SvgIconProps } from '@mui/material'

const MonitorActive = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 0,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M21 13.6364C21 13.0555 20.8691 12.5237 20.6318 12.0327C20.0918 10.6009 19.0036 9.44729 17.6127 8.82548C17.4082 7.71275 16.9091 6.73093 16.0582 5.89639C14.9455 4.78366 13.5955 4.22729 12 4.22729C10.7073 4.22729 9.54545 4.61184 8.52273 5.39729C7.5 6.18275 6.82091 7.18911 6.47727 8.44093C5.45455 8.67002 4.60364 9.20184 3.95727 10.0364C3.31091 10.8709 3 11.82 3 12.8837C3 14.1191 3.44182 15.1746 4.31727 16.0337C5.20091 16.9091 6.27273 17.3182 7.5 17.3182H10.5764C11.6155 18.7991 13.3255 19.7727 15.2727 19.7727C18.4391 19.7727 21 17.2118 21 14.0455C21 13.9555 21 13.8737 21 13.7837C21 13.7346 21 13.6855 21 13.6364ZM15.2727 18.1364C13.0145 18.1364 11.1818 16.3037 11.1818 14.0455C11.1818 11.7873 13.0145 9.95457 15.2727 9.95457C17.5309 9.95457 19.3636 11.7873 19.3636 14.0455C19.3636 16.3037 17.5309 18.1364 15.2727 18.1364ZM15.6818 14.25L18.0218 15.6327L17.4082 16.6309L14.4545 14.8637V10.7727H15.6818V14.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default MonitorActive
