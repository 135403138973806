import Box from '@mui/material/Box'
import { ComponentProps } from 'react'
import { styled } from '@mui/material'

const Root = styled(Box)`
  max-width: 480px;
  display: flex;
  column-gap: 16px;
`
Root.defaultProps = {
  component: 'form',
} as Partial<ComponentProps<typeof Box>>

export { Root }
