import React from 'react'
import { isAWSSliceCriteria } from '@features/DashboardV4/management-view/slice-criteria-guards'
import { useCurrentView } from '.'

function useManagementTags() {
  const view = useCurrentView()

  const isTagsEnabled = React.useCallback(() => {
    if (!view) {
      return false
    }
    let includeTags
    let excludeTags
    if (isAWSSliceCriteria(view.slice, view)) {
      includeTags = view.slice.include_tags
      excludeTags = view.slice.exclude_tags
    }

    return (
      (includeTags && includeTags.tags?.length > 0) ||
      (excludeTags && excludeTags.tags?.length > 0)
    )
  }, [])

  return {
    isTagsEnabled,
  }
}

export default useManagementTags
