/* eslint-disable import/no-extraneous-dependencies */
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { OvaStatisticsClient } from '@lib/clients'
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'

type TotalThreatsCount = {
  ransomwareCount: number
  malwareCount: number
}

const INITIAL_THREATS_COUNT: TotalThreatsCount = {
  ransomwareCount: 0,
  malwareCount: 0,
}

const ovaStatisticsClient = new OvaStatisticsClient()

export function useFetchOvaThreats() {
  const ovaAccountsList = useGetOvaAccountList()
  const ovaBackupProvidersList = useGetOvaBackupProvidersList()
  const [isLoading, setIsLoading] = useState(false)
  const [threatsCount, setThreatsCount] = useState<TotalThreatsCount>(
    INITIAL_THREATS_COUNT
  )

  useEffect(() => {
    let isMounted = true

    const fetchThreatsCount = async () => {
      try {
        setIsLoading(true)

        const response = await ovaStatisticsClient.getThreatsStatistics({
          ovaAccountIds: ovaAccountsList,
          backupProviders: ovaBackupProvidersList,
        })

        if (isMounted) {
          setThreatsCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'ovaStatisticsClient.getThreatsStatistics: failed to fetch threats count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchThreatsCount()

    return () => {
      isMounted = false
      setThreatsCount(INITIAL_THREATS_COUNT)
      setIsLoading(false)
    }
  }, [ovaAccountsList, ovaBackupProvidersList])

  return {
    isLoading,
    ransomwareCount: threatsCount.ransomwareCount,
    malwareCount: threatsCount.malwareCount,
  }
}
