import TimeHelper from '@lib/helpers/time.helper'
import { DeploymentFiltersState } from '.'

export const getInitialFiltersState = (): DeploymentFiltersState => ({
  timeRange: TimeHelper.currentMonthDateRangeWithTimezone(),
  possibleFilters: {
    kindsList: new Set(),
    statusesList: new Set(),
  },
  selectedFilters: {
    kindsList: new Set(),
    statusesList: new Set(),
  },
})
