import PolicyModel from '@lib/models/policy.model'

export const SET_POLICIES = 'SET_PLANS'
export const REQUEST_POLICIES = 'REQUEST_PLANS'
export const DELETE_POLICY = 'DELETE_PLAN'
export const PAUSE_POLICY = 'PAUSE_PLAN'
export const RESUME_POLICY = 'RESUME_PLAN'
export const EXECUTE_POLICY = 'EXECUTE_POLICY'

export const setPolicies = (policies: Array<PolicyModel>) => ({
  type: SET_POLICIES,
  payload: policies,
})

export const requestPolicies = () => ({
  type: REQUEST_POLICIES,
  payload: null,
})

export const deletePolicy = (policyId: string, callback: () => void) => ({
  type: DELETE_POLICY,
  payload: {
    policyId,
    callback,
  },
})

export const pausePolicy = (policyId: string) => ({
  type: PAUSE_POLICY,
  payload: policyId,
})

export const resumePolicy = (policyId: string) => ({
  type: RESUME_POLICY,
  payload: policyId,
})

export const executePolicy = (policyId: string) => ({
  type: EXECUTE_POLICY,
  payload: policyId,
})
