import React, { memo } from 'react'
import Box from '@mui/material/Box'
import { Header } from './header'
import { Navigation } from './navigation'
import { SliderItem } from './slider-item'
import SliderLine from './icons/slider-line'
import useSlider from './useSlider'
import { StyledContainer, StyledSliderLine } from './styles'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EBS, ElastioRecoveryPoint, Threat } from 'blues-corejs/dist'
import { EC2 } from '@lib/models/assets'
import { useGetAttachedVolumes } from '@lib/hooks/api-hooks'

interface Props {
  instance: EC2
  onSlideChange: (asset: EBS) => void
  backup?: ElastioRecoveryPoint
  threats: Array<Threat>
  preselectedVolume?: EBS
}

const DEFAULT_SELECTED_SLIDE = 0

/**
 * AssetSlider component displays a slider of attached volumes for a given instance.
 *
 * We show it in the following places:
 * - Recovery Point Details - when RP is Elastio and it's kind is EC2
 * - Asset Details - when asset is EC2
 * @prop instance - EC2 instance
 * @prop onChange - callback to handle volume change
 * @prop backup - Elastio recovery point
 * @prop preselectedVolume - we pass this prop, in case if component renders in Recovery Point Details, if not provided, the first infected volume will be shown
 */
function AssetSlider({
  instance,
  onSlideChange,
  backup,
  threats,
  preselectedVolume,
}: Props) {
  const sliderContainerRef = React.useRef<HTMLDivElement>(null)

  const attachedVolumes = useGetAttachedVolumes([...instance.ebsIds])

  const findPreselectedOrInfectedVolumeIndex = () => {
    if (preselectedVolume) {
      return attachedVolumes.findIndex(
        (volume) => volume.id === preselectedVolume.id
      )
    }

    const firstInfectedVolume = attachedVolumes.findIndex((volume) =>
      threats.some(
        (threat) =>
          threat.source.asset.assetId === volume.id ||
          threat.source.assetItem?.assetId === volume.id
      )
    )

    if (firstInfectedVolume !== -1) {
      return firstInfectedVolume
    }

    return DEFAULT_SELECTED_SLIDE
  }

  const {
    currentSlide,
    canScrollBack,
    canScrollNext,
    handleSlideChange,
    onScrollBack,
    onScrollNext,
  } = useSlider({
    ref: sliderContainerRef,
    slides: attachedVolumes,
    selectedSlide: findPreselectedOrInfectedVolumeIndex(),
  })

  function changeSlideWithCallback(index: number, selectedVolume: EBS) {
    handleSlideChange(index)

    onSlideChange(selectedVolume)
  }

  React.useEffect(() => {
    const selectedVolume = attachedVolumes[currentSlide]

    if (selectedVolume) {
      onSlideChange(selectedVolume)
    }
  }, [attachedVolumes.length, currentSlide])

  return (
    <StyledContainer>
      <Header asset={instance} backup={backup} />
      <StyledSliderLine>
        <SliderLine />
      </StyledSliderLine>
      <Navigation
        canScrollBack={canScrollBack}
        canScrollNext={canScrollNext}
        onScrollBack={onScrollBack}
        onScrollNext={onScrollNext}
      />
      <Box className="slider-container" ref={sliderContainerRef}>
        {attachedVolumes.map((volume, index) => (
          <SliderItem
            threats={threats}
            volume={volume}
            key={volume.id}
            index={index}
            selected={currentSlide === index}
            onSlideChange={(selectedIndex) =>
              changeSlideWithCallback(selectedIndex, volume)
            }
          />
        ))}
      </Box>
    </StyledContainer>
  )
}

export default memo(AssetSlider)
