/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { Anomaly } from '@lib/models/anomalies'
import {
  ListAnomaliesFilter,
  ListAnomaliesPagination,
  ListAnomaliesRequestParams,
} from '@lib/clients/entropy-detection/list-anomalies'
import { EntropyDetectionClient } from '@lib/clients/entropy-detection'

interface EntropyTrendsTableState {
  anomaliesList: Array<Anomaly>
  pageToken?: string
  isLoading: boolean
  previousFilters?: ListAnomaliesRequestParams
}

interface EntropyTrendsTableActions {
  fetchInitialData: (filters: ListAnomaliesRequestParams) => Promise<void>
  fetchMoreData: () => Promise<void>
  setLoading: (isLoading: boolean) => void
}

type EntropyTrendsTableStore = EntropyTrendsTableState & {
  actions: EntropyTrendsTableActions
}

const client = new EntropyDetectionClient()

const useEntropyTrendsTableStore = create<EntropyTrendsTableStore>(
  (set, get) => ({
    anomaliesList: [],
    isLoading: false,
    actions: {
      fetchInitialData: async (filters) => {
        try {
          set({ isLoading: true })

          const combinedFilters = {
            ...get().previousFilters,
            ...filters,
          }

          const { anomaliesList, pageToken } = await client.listAnomalies(
            new ListAnomaliesFilter(combinedFilters).toGrpc()
          )

          set({
            anomaliesList,
            pageToken,
            previousFilters: combinedFilters,
          })
        } catch (error) {
          console.error('Error fetching initial data', error)
        } finally {
          set({ isLoading: false })
        }
      },
      fetchMoreData: async () => {
        const { pageToken } = get()

        if (!pageToken) {
          return
        }

        try {
          const { anomaliesList, pageToken: newPageToken } =
            await client.listAnomalies(
              new ListAnomaliesPagination(pageToken).toGrpc()
            )

          set({
            anomaliesList: [...get().anomaliesList, ...anomaliesList],
            pageToken: newPageToken,
          })
        } catch (error) {
          console.error('Error fetching more data', error)
        } finally {
          set({ isLoading: false })
        }
      },
      setLoading: (isLoading) => {
        set({ isLoading })
      },
    },
  })
)

export const useGetAnomaliesList = () =>
  useEntropyTrendsTableStore(useShallow((state) => state.anomaliesList))

export const useEntropyTrendsTableLoading = () =>
  useEntropyTrendsTableStore((state) => state.isLoading)

export const useEntropyTrendsTableActions = () =>
  useEntropyTrendsTableStore((state) => state.actions)

export const getEntropyTrendsTableActions = () =>
  useEntropyTrendsTableStore.getState().actions
