/* eslint-disable import/no-extraneous-dependencies */
import {
  CohesityIcon,
  VeeamIcon,
  CommvaultIcon,
  NetBackupIcon,
  RubrikIcon,
} from '@inline-img/general/ova/backup'
import { AwsLogoIcon } from '@inline-img/general'
import { SystemHelper } from '@lib/helpers'
import { OvaBackup } from 'blues-corejs/dist/models'
import { OvaBackupProvider } from 'blues-corejs/dist/models/ova-backup-provider'

export default function computeOvaBackupProviderIcon(backup: OvaBackup) {
  switch (backup.provider) {
    case OvaBackupProvider.COHESITY:
      return <CohesityIcon />
    case OvaBackupProvider.VEEAM:
      return <VeeamIcon />
    case OvaBackupProvider.COMMVAULT:
      return <CommvaultIcon />
    case OvaBackupProvider.VERITAS_NET_BACKUP:
      return <NetBackupIcon />
    case OvaBackupProvider.AWS_BACKUP_VMWARE:
      return <AwsLogoIcon />
    case OvaBackupProvider.RUBRIK:
      return <RubrikIcon />
    default:
      SystemHelper.sendSentryIfProd(
        `computeOvaBackupProviderIcon: Unknown OvaBackup provider: ${backup.provider}`
      )
      return null
  }
}
