import React from 'react'

interface Props {
  assetKind: number
  healthyStatusName: string
  iscanIntegrityStatusName: string
  fsCheckIntegrityStatusName: string
}

function AssetState({}: Props) {
  return (
    <div className="jsAssetsState">
      <div></div>
    </div>
  )
}

export default AssetState
