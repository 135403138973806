/* eslint-disable import/no-extraneous-dependencies */
import {
  StatisticRowType,
  useFetchAwsScanCoverage,
} from '../widgets-with-scale/coverage-stats'
import { computeScanCoverageStats } from '@features/DashboardV4/views/utils'
import { useNavigation, DashboardRoutes } from '@lib/router'

export function useScanCoverageStats() {
  const router = useNavigation()
  const { isLoading, ec2, efs, s3, ebs } = useFetchAwsScanCoverage()
  const {
    totalInstances,
    coveredInstances,
    totalVolumes,
    coveredVolumes,
    totalS3Buckets,
    coveredS3Buckets,
    totalFilesystems,
    coveredFilesystems,
    numberOfAssets,
    numberOfAssetsWithScans,
    percentOfAssetsWithScans,
  } = computeScanCoverageStats({
    ec2,
    efs,
    s3,
    ebs,
  })

  const handleDrilldown = (url: string) => {
    router.push(url)
  }

  const scanCoverageStats: Array<StatisticRowType> = [
    {
      assets: totalInstances,
      assetsWithBackups: coveredInstances,
      label: 'EC2 Instances',
      onRowClick: () => handleDrilldown(DashboardRoutes.ec2Table),
    },
    {
      assets: totalVolumes,
      assetsWithBackups: coveredVolumes,
      label: 'EBS Volumes',
      onRowClick: () => handleDrilldown(DashboardRoutes.ebsTable),
    },
    {
      assets: totalS3Buckets,
      assetsWithBackups: coveredS3Buckets,
      label: 'S3 Buckets',
      onRowClick: () => handleDrilldown(DashboardRoutes.s3Table),
    },
    {
      assets: totalFilesystems,
      assetsWithBackups: coveredFilesystems,
      label: 'EFS',
      onRowClick: () => handleDrilldown(DashboardRoutes.efsTable),
    },
  ]

  return {
    isLoading,
    scanCoverageStats,
    numberOfAssetsWithScans,
    percentOfAssetsWithScans,
    numberOfAssets,
  }
}
