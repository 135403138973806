/* eslint-disable import/no-extraneous-dependencies */
import Box from '@mui/material/Box'
import {
  Scale,
  TableElementEvent,
} from '@features/DashboardV4/widgets-with-scale/shared'
import styles from './retention.module.css'
import { INVENTORY_INSTANCES_TABLE, INVENTORY_VOLUMES_TABLE } from './consts'
import { RetentionCommonStatistic } from './common-statistic'
import { useSelector } from 'react-redux'
import {
  getIsLiveAssetsLoading,
  getLiveAssets,
} from '@store/selectors/list-live-assets.selector'
import { AwsRetentionStats } from '@lib/clients/aws-statistics/types'
import { WidgetHeader } from '@features/DashboardV4/common'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface Props {
  isLoading: boolean
  retentionStats: AwsRetentionStats
}

function RetentionStatsWgt({ isLoading, retentionStats }: Props) {
  const {
    averageRetention,
    rpoStatsQuantilesList,
    bottomQuartile,
    topQuartile,
  } = retentionStats
  const { ec2Instances, ebsVolumes } = useSelector(getLiveAssets)

  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const router = useNavigation()

  const instancesNumber = ec2Instances.length
  const volumesNumber = ebsVolumes.length
  const isShowInstancesTable =
    instancesNumber > 0 || (instancesNumber === 0 && volumesNumber === 0)

  const drillDownLink =
    instancesNumber > 0
      ? INVENTORY_INSTANCES_TABLE
      : volumesNumber > 0
        ? INVENTORY_VOLUMES_TABLE
        : INVENTORY_INSTANCES_TABLE

  const handlerNavigateDrillDownLink = () => {
    router.push(drillDownLink)
  }

  const handleNavigateToDrillDownLink = (link: string) => {
    return (event: TableElementEvent) => {
      event.stopPropagation()
      router.push(link)
    }
  }

  const dataLoading = isLoading || isLiveAssetsLoading
  const shouldRenderScaleWidget = rpoStatsQuantilesList.length > 0

  return (
    <Box data-testid="retention-widget">
      <WidgetHeader title="Retention" onClick={handlerNavigateDrillDownLink} />

      <Box className={styles.container}>
        <Box
          onClick={handlerNavigateDrillDownLink}
          className={styles.tableWrapper}
        >
          <RetentionCommonStatistic
            rpoStatsQuantilesList={rpoStatsQuantilesList}
            isLoading={dataLoading}
            bottomQuartile={bottomQuartile}
            topQuartile={topQuartile}
            averageRetention={averageRetention}
            isShowInstancesTable={isShowInstancesTable}
            onClick={handleNavigateToDrillDownLink}
          />
        </Box>
        <Box className={styles.wrapScale}>
          {shouldRenderScaleWidget && (
            <Scale
              isLoading={isLiveAssetsLoading}
              quantiles={rpoStatsQuantilesList}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default RetentionStatsWgt
