/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { getEntropyTrendsColumnsDefinition } from './entropy-trends-columns-definition'

import styles from './entropy-trends-table.module.css'
import {
  useEntropyTrendsTableActions,
  useGetAnomaliesList,
  useEntropyTrendsTableLoading,
} from './entropy-trends-table-store'
import {
  mapAnomaliesListToTableDefinitions,
  getAssetIdFromAnomalies,
} from './utils'
import { useGetEntropyFiltersLoading } from '../entropy-trends-filters/entropy-trends-filters-store.context'
import { useAssetsMap } from '@lib/hooks/api-hooks/assets'
import { ExportAsCsv } from '@features/common'
import {
  ENTROPY_TRENDS_CSV_TABLE_HEADERS,
  prepareDataForCsv,
} from './prepare-data-for-csv'
import { useSelector } from 'react-redux'
import { getLiveAssets } from '@store/selectors/list-live-assets.selector'

export function EntropyTrendsTable() {
  const anomaliesList = useGetAnomaliesList()
  const { ec2Instances } = useSelector(getLiveAssets)
  const mappedAnomaliesList = mapAnomaliesListToTableDefinitions(anomaliesList)
  const { fetchMoreData } = useEntropyTrendsTableActions()
  const isTableDataLoading = useEntropyTrendsTableLoading()
  const isFiltersLoading = useGetEntropyFiltersLoading()

  const observable = useInfiniteScrollDataFetch({
    initialItems: anomaliesList,
    fetchNextBatch: fetchMoreData,
  })

  const { assetsList, isAssetsLoading } = useAssetsMap(
    getAssetIdFromAnomalies(anomaliesList)
  )

  const assetsListForTable = [...assetsList, ...ec2Instances]

  const isLoading = isTableDataLoading || isFiltersLoading || isAssetsLoading

  return (
    <Paper component={Stack} padding="24px" height="100%">
      <ExportAsCsv
        disabled={isLoading}
        filename="entropy-trends.csv"
        data={prepareDataForCsv({
          data: mappedAnomaliesList,
          assetsList,
        })}
        headers={ENTROPY_TRENDS_CSV_TABLE_HEADERS}
        buttonText="Download CSV"
      />
      <Table
        className={styles.table_root}
        data={mappedAnomaliesList}
        columns={getEntropyTrendsColumnsDefinition(assetsListForTable)}
        advancedProps={{
          observableState: observable,
        }}
        generalProps={{
          isLoading,
          noDataMessage: 'No data to display',
        }}
      />
    </Paper>
  )
}
