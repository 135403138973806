import {
  Notification,
  NotificationKind,
} from 'ui-v2/src/features/scheduled-jobs-monitoring/models'

interface NotificationsRepository {
  list(): Promise<Array<Notification>>
  delete(id: string): void
}

interface Notifier {
  success(text: string): void
  error(text: string): void
  warning(text: string): void
}

export class ShowPendingNotificationsUseCase {
  constructor(
    private repository: NotificationsRepository,
    private notifier: Notifier
  ) {}

  async execute(): Promise<void> {
    const notifications = await this.repository.list()

    for (const notification of notifications) {
      switch (notification.kind) {
        case NotificationKind.SUCCESS:
          this.notifier.success(notification.text)
          break
        case NotificationKind.WARNING:
          this.notifier.warning(notification.text)
          break
        case NotificationKind.ERROR:
          this.notifier.error(notification.text)
      }
      this.repository.delete(notification.id)
    }
  }
}
