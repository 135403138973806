/* eslint-disable import/no-extraneous-dependencies */
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

export function getHumanReadableCloudConnectorJobStatus(
  status: CloudConnectorJobStatus
): string {
  const mapping: Record<CloudConnectorJobStatus, string> = {
    [CloudConnectorJobStatus.ABORTED]: 'Aborted',
    [CloudConnectorJobStatus.FAILED]: 'Failed',
    [CloudConnectorJobStatus.IN_PROGRESS]: 'In progress',
    [CloudConnectorJobStatus.SCHEDULED]: 'Scheduled',
    [CloudConnectorJobStatus.STARTING]: 'Starting',
    [CloudConnectorJobStatus.SUCCEEDED]: 'Succeeded',
  }

  return mapping[status]
}
