import { useEffect } from 'react'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'

import { FrequencyType } from './frequency-type'

interface UseUpdateFrequencyProps {
  calendarValue: number
  frequencyFieldName: string
  frequencyTypeEnum?: typeof FrequencyType
}
const MAX_DAY_OF_MONTH = 28

export function useUpdateFrequency({
  calendarValue,
  frequencyFieldName,
  frequencyTypeEnum = FrequencyType,
}: UseUpdateFrequencyProps) {
  const { setValue, watch } = useFormContext()
  const selectedValue = watch(frequencyFieldName) || ''

  useEffect(() => {
    if (!selectedValue) {
      return
    }

    let newValue = selectedValue

    if (selectedValue.startsWith(`${frequencyTypeEnum.WEEKLY}_`)) {
      const newDay = moment(calendarValue).format('dddd')
      newValue = `${frequencyTypeEnum.WEEKLY}_${newDay}`
      setValue(frequencyFieldName, newValue)
    } else if (selectedValue.startsWith(`${frequencyTypeEnum.MONTHLY}_`)) {
      const dayOfMonth = moment(calendarValue).date()

      newValue =
        dayOfMonth > MAX_DAY_OF_MONTH
          ? `${frequencyTypeEnum.MONTHLY}_0`
          : `${frequencyTypeEnum.MONTHLY}_${dayOfMonth}`
      setValue(frequencyFieldName, newValue)
    }
  }, [
    calendarValue,
    selectedValue,
    setValue,
    frequencyFieldName,
    frequencyTypeEnum,
  ])
}
