import React, { memo } from 'react'
import LeftBlueArrowIcon from '@inline-img/general/left-blue-arrow-icon'
import { EngineCallbackPure } from '@lib/engine-types'
import { EmptyFunc } from '@lib/constants/app.constant'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface Props {
  backUrl?: string
  text?: string
  onClick?: EngineCallbackPure
}

function AllWorkflowsLink({
  backUrl = '',
  text = 'Back to onboarding steps',
  onClick = EmptyFunc,
}: Props) {
  const router = useNavigation()
  const handleClick = () => {
    onClick()
    if (backUrl !== '') {
      router.push(backUrl)
    } else {
      router.back()
    }
  }

  return (
    <div
      className="wrap-1649069029751 jsAllWorkflowLink"
      data-testid="all-workflow-link"
    >
      <LeftBlueArrowIcon />
      <a onClick={handleClick} className="blueLink">
        {text}
      </a>
    </div>
  )
}

export default memo(AllWorkflowsLink)
