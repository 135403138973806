"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInventoryS3Buckets = void 0;
const types_1 = require("../../models/assets/aws/s3-bucket/types");
const malware_1 = require("../../models/scans/malware");
const share_1 = require("../share");
__exportStar(require("./types"), exports);
class ListInventoryS3Buckets {
    #S3Buckets;
    #threats;
    #lastScans;
    #bucketsWithScans;
    constructor({ S3Buckets, threats, lastScans }) {
        this.#S3Buckets = S3Buckets;
        this.#threats = threats;
        this.#lastScans = lastScans;
        this.#bucketsWithScans ||= this.#getBucketsWithScans();
    }
    execute(filters) {
        return this.#preparedOutput(this.#findS3BucketsMatchingFilters(filters));
    }
    #preparedOutput(buckets) {
        const S3BucketsOutput = buckets.map((bucket) => ({
            uuid: bucket.id,
            isInfected: this.#isUnhealthyBucket(bucket),
            accountId: bucket.awsAccountId,
            accountName: bucket.awsAccountId,
            region: bucket.awsRegion,
            name: bucket.name,
            size: bucket.size,
            state: bucket.state,
            objectCount: bucket.objectCount,
            coveredByPolicesList: bucket.backupPolicies,
            arn: bucket.arn,
            awsId: bucket.awsId,
            lastScan: this.#getLastScanForBucket(bucket),
        }));
        return {
            s3Buckets: S3BucketsOutput,
            summarizedData: this.#summarizeS3BucketData(buckets),
        };
    }
    #isUnhealthyBucket(bucket) {
        if (bucket.state === types_1.S3BucketState.DELETED) {
            return false;
        }
        return this.#threats.some((threat) => threat.source.asset?.assetId === bucket.id ||
            threat.source.assetItem?.assetId === bucket.id);
    }
    #getLastScanForBucket(bucket) {
        const scan = this.#bucketsWithScans.find((scan) => scan.bucket.id === bucket.id);
        if (!scan) {
            return null;
        }
        const scansTime = scan.scans.map(({ createdAt }) => createdAt.getTime());
        if (!scansTime.length) {
            return null;
        }
        return new Date(Math.max(...scansTime));
    }
    #mapBucketToScans(bucket) {
        const scans = this.#filterScansForS3Bucket(bucket, this.#lastScans);
        return { bucket: bucket, scans };
    }
    #getBucketsWithScans() {
        return this.#S3Buckets.map(this.#mapBucketToScans.bind(this));
    }
    #summarizeS3BucketData(buckets) {
        const awsIdCount = new Set([...buckets.map(({ awsId }) => awsId)]).size;
        const objectCount = buckets.reduce((accum, bucket) => accum + bucket.objectCount, 0);
        const sizeCount = buckets.reduce((accum, bucket) => accum + bucket.size, 0);
        const accountCount = new Set([
            ...buckets.map(({ awsAccountId }) => awsAccountId),
        ]).size;
        const regionCount = new Set([...buckets.map(({ awsRegion }) => awsRegion)])
            .size;
        return {
            awsIdCount,
            objectCount,
            sizeCount,
            accountCount,
            regionCount,
        };
    }
    #getS3BucketCoveredByPolicies(bucket) {
        return bucket.backupPolicies.length > 0;
    }
    #findS3BucketsMatchingFilters(filters) {
        const { accountIds, regions, idSearch, covered, state } = filters;
        return this.#S3Buckets.filter((bucket) => {
            const nameMatches = idSearch &&
                (bucket.name.includes(idSearch) || bucket.awsId.includes(idSearch));
            const accountIdsMatches = (0, share_1.matchesIncludeFilter)(accountIds, bucket.awsAccountId);
            const regionsMatches = (0, share_1.matchesIncludeFilter)(regions, bucket.awsRegion);
            if (idSearch && !nameMatches) {
                return false;
            }
            if (accountIds && !accountIdsMatches) {
                return false;
            }
            if (regions && !regionsMatches) {
                return false;
            }
            const coveredMatches = (covered?.includes(true) &&
                this.#getS3BucketCoveredByPolicies(bucket)) ||
                (covered?.includes(false) &&
                    !this.#getS3BucketCoveredByPolicies(bucket));
            if (covered && !coveredMatches) {
                return false;
            }
            const stateMatches = (state?.includes(true) && bucket.state === types_1.S3BucketState.IN_USE) ||
                (state?.includes(false) && bucket.state === types_1.S3BucketState.DELETED);
            if (state && !stateMatches) {
                return false;
            }
            return true;
        });
    }
    #isMalwareScanTarget(target, bucket) {
        if (target instanceof malware_1.MalwareScanBackup && target.source.asset) {
            return target.source.asset.backupAssetId === bucket.id;
        }
        if ('assetId' in target) {
            return target.assetId === bucket.id;
        }
        return false;
    }
    #filterScansForS3Bucket(bucket, scans) {
        return scans.filter((scan) => {
            return this.#isMalwareScanTarget(scan.scanTarget.target, bucket);
        });
    }
}
exports.ListInventoryS3Buckets = ListInventoryS3Buckets;
