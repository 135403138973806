import Tab from '@mui/material/Tab'
import { TabsIds } from '@features/asset-page/store'

export function EntropyTabs({
  onTabChange,
}: {
  onTabChange: (tabIndex: TabsIds) => void
}) {
  function handleTabChange() {
    onTabChange(TabsIds.ENTROPY)
  }

  return (
    <Tab
      onClick={handleTabChange}
      tabIndex={TabsIds.ENTROPY}
      label="Entropy Trends"
      value={TabsIds.ENTROPY}
    />
  )
}

export default EntropyTabs
