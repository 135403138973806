/* eslint-disable import/no-extraneous-dependencies */
import { EfsThreatsOutput } from 'blues-corejs/dist/use_cases/list_efs_with_threats/types'

/**
 * Sorts to last scan first, if no last scan, sort by name
 */
export function initialPrioritySortForEFSTable(
  filesystems: Array<EfsThreatsOutput>
) {
  return filesystems.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const efsAName = a.name || a.efsAwsId
    const efsBName = b.name || b.efsAwsId

    return efsAName.localeCompare(efsBName)
  })
}
