import React from 'react'
import { StyledCheckbox, StyledFormControlLabel } from './styles'

interface Props {
  label: string
  checked: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

export function FilterCheckbox({ label, checked, onChange }: Props) {
  return (
    <StyledFormControlLabel
      label={label}
      control={<StyledCheckbox checked={checked} onChange={onChange} />}
    />
  )
}
