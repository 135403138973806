import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { NormalizedStatCardData } from 'ui-v2/src/lib/models/aws-stats'
import { ArrowOutward } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import {
  getStatContent,
  STAT_TITLE_MAP,
} from 'ui-v2/src/lib/constants/stats.constant'

const ActionCard: React.FC<NormalizedStatCardData> = ({
  slug,
  count,
  link,
}) => {
  return (
    <Link to={link} preservePath sx={{ textDecoration: 'none' }}>
      <GradientCard
        sx={{
          p: 1.5,
          px: 2,
        }}
        gradientVariant={count > 0 ? 'red' : 'blue'}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Typography fontWeight={700} sx={{ textDecoration: 'none' }}>
            {STAT_TITLE_MAP[slug]}:&nbsp;
            <Typography component="span" fontWeight={700} color="primary">
              {count}&nbsp;
            </Typography>
            <Typography component="span" fontWeight={400}>
              {getStatContent(slug, count)}
            </Typography>
          </Typography>

          <IconButton disabled>
            <ArrowOutward sx={{ color: '#fff' }} />
          </IconButton>
        </Stack>
      </GradientCard>
    </Link>
  )
}

export default ActionCard
