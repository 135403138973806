/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  EntropyTrendSelectionFilter,
  EntropyTrendsFilter,
} from '@features/entropy-trends/components/entropy-trends-filters/generic'
import {
  useGetEntropyFiltersLoading,
  useGetPossibleFilters,
  useGetSelectedFilters,
  useEntropyFiltersActions,
} from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'
import type { EntropySelectionFilter } from '../../types'
import { CloudConnectorRegionFilterLabel } from './cc-region-label'
import { useGetCloudConnectorList } from '@lib/hooks/api-hooks/rex'
import LangHelper from '@lib/helpers/lang.helper'
import {
  FilterName,
  FILTER_NAMES,
} from '@features/entropy-trends/components/entropy-trends-filters/composable/consts'

function buildCloudConnectorAccountFilters(
  awsRegionList: Array<string>
): Array<EntropySelectionFilter<string>> {
  return awsRegionList.map((awsRegion) => ({
    value: awsRegion,
    label: <CloudConnectorRegionFilterLabel region={awsRegion} />,
    searchFilterCriteria: (searchValue: string) => {
      const formattedRegion =
        LangHelper.getAwsRegionSingleTranslation(awsRegion)

      return (
        awsRegion.toLowerCase().includes(searchValue.toLowerCase()) ||
        formattedRegion.toLowerCase().includes(searchValue.toLowerCase())
      )
    },
  }))
}

interface Props {
  isExpanded: boolean
  onFilterClick: (filterName: FilterName) => void
}

export function CloudConnectorRegionSelectionFilter({
  isExpanded,
  onFilterClick,
}: Props) {
  const { regionsList } = useGetPossibleFilters()
  const { selectedRegions } = useGetSelectedFilters()
  const { setFilters } = useEntropyFiltersActions()
  const { cloudConnectorList, isLoading: isCloudConnectorsListLoading } =
    useGetCloudConnectorList()
  const isFiltersLoading = useGetEntropyFiltersLoading()

  const uniqueRegions = new Set(
    cloudConnectorList
      .filter((cc) => regionsList.includes(cc.awsRegion))
      .map((cc) => cc.awsRegion)
  )

  const options = buildCloudConnectorAccountFilters([...uniqueRegions])

  const isLoading = isCloudConnectorsListLoading || isFiltersLoading

  const handleFilterChange = (selectedOptions: Set<string>) => {
    setFilters({ selectedRegions: selectedOptions })
  }

  return (
    <EntropyTrendsFilter
      filterName={FILTER_NAMES.REGION}
      isLoading={isLoading}
      isExpanded={isExpanded}
      onAccordionClick={() => onFilterClick(FILTER_NAMES.REGION)}
    >
      <EntropyTrendSelectionFilter
        options={options}
        selectedOptions={selectedRegions}
        onSelectionChange={handleFilterChange}
      />
    </EntropyTrendsFilter>
  )
}
