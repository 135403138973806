import { create } from 'zustand'
import { DateOrTimestamp } from '@components-simple/date-time-tooltip'

type ThreatInfo = {
  threatId: string
  threatName: string
  threatLocation: string
  firstDetectedAt: DateOrTimestamp
}

type ThreatSuppressionStore = {
  showModal: boolean
  openModal: () => void
  closeModal: () => void
  threatInfo: ThreatInfo
  setThreatInfo: (threatInfo: ThreatInfo) => void
  isThreatsLoading: boolean
  setThreatsLoading: (v: boolean) => void
}

export const useThreatSuppressionStore = create<ThreatSuppressionStore>(
  (set) => ({
    showModal: false,
    threatInfo: {
      threatId: '',
      threatName: '',
      threatLocation: '',
      firstDetectedAt: 0,
    },
    isThreatsLoading: false,
    setThreatInfo: (threatInfo: ThreatInfo) =>
      set((state) => ({
        ...state,
        threatInfo,
      })),
    setThreatsLoading: (isThreatsLoading: boolean) =>
      set((state) => ({
        ...state,
        isThreatsLoading,
      })),
    openModal: () =>
      set((state) => ({
        ...state,
        showModal: true,
      })),
    closeModal: () =>
      set((state) => ({
        ...state,
        showModal: false,
      })),
  })
)
