import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  getAvailableTags,
  getRegionsWithRelatedAccounts,
  getUniqueOptions,
} from './utils'
import { PagePathConstant } from '@lib/constants'
import { getViewsFromConfig } from '@features/DashboardV4'
import {
  DashboardViewForm,
  DashboardViewOptions,
  TagsOperations,
} from './types'
import { useTags, useUniqueAccounts, useUniqueRegions } from './composables'
import { getLiveAssets } from '@store/selectors/list-live-assets.selector'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { setSlice } from '@store/actions/list-live-assets.action'
import { useCurrentView } from '@features/DashboardV4/common/use-management-view'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'

function setDefaultFormValues(): DashboardViewForm {
  return {
    name: '',
    dashboardView: DashboardViewOptions.ALL,
    accounts: [],
    regions: [],
    includingTags: [],
    includingTagsOperation: TagsOperations.AND,
    excludingTags: [],
    excludingTagsOperation: TagsOperations.AND,
  }
}

function useViewData() {
  const { setBreadcrumbsPaths } = useLayoutContext()

  const dispatch = useDispatch()

  const { ebsVolumes, ec2Instances, filesystems, s3Buckets } =
    useSelector(getLiveAssets)

  const assets = [...ec2Instances, ...ebsVolumes, ...s3Buckets, ...filesystems]

  const views = getViewsFromConfig()

  const currentView = useCurrentView()

  const { control, watch, setValue, handleSubmit } = useForm<DashboardViewForm>(
    {
      defaultValues: setDefaultFormValues(),
    }
  )

  const tagsOptions = useTags(
    getAvailableTags({
      assets,
      currentView,
    })
  )

  const regionsWithAccounts = useMemo(
    () => getRegionsWithRelatedAccounts(assets),
    [assets.length]
  )

  const accounts = watch('accounts')
  const regions = watch('regions')
  const includingTags = watch('includingTags')
  const excludingTags = watch('excludingTags')
  const includingTagsOptions = getUniqueOptions(excludingTags, tagsOptions)
  const excludingTagsOptions = getUniqueOptions(includingTags, tagsOptions)

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)

  const uniqueAccounts = useUniqueAccounts({
    regions,
    regionsWithRelatedAccounts: regionsWithAccounts,
    allActiveRedStacks,
  })

  const uniqueRegions = useUniqueRegions({
    accounts,
    regionsWithRelatedAccounts: regionsWithAccounts,
  })

  const atLeastOneFieldIsNotEmpty = (): string | boolean => {
    const ERROR_MESSAGE = 'At least one field should be selected'

    const allEmpty =
      includingTags.length === 0 &&
      excludingTags.length === 0 &&
      accounts.length === 0 &&
      regions.length === 0

    return !allEmpty ? true : ERROR_MESSAGE
  }

  useEffect(() => {
    dispatch(getAllRedStacks())
    dispatch(setSlice({}))

    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
      },
    ])
  }, [])

  return {
    accounts: {
      available: uniqueAccounts,
      selected: accounts,
    },
    regions: {
      available: uniqueRegions,
      selected: regions,
    },
    includingTags: {
      available: includingTagsOptions,
      selected: includingTags,
    },
    excludingTags: {
      available: excludingTagsOptions,
      selected: excludingTags,
    },
    control,
    setValue,
    atLeastOneFieldIsNotEmpty,
    handleSubmit,
    views,
  }
}

export default useViewData
