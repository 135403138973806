/* eslint-disable import/no-extraneous-dependencies */
import { Asset, EC2 } from 'blues-corejs/dist'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Stack } from '@mui/material'
import { useMemo } from 'react'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'

const ThreatSummary = ({ asset }: ThreatSummaryProps) => {
  const { data: assetsLiveData } = useAssetsLiveQuery()

  const threatName = useMemo(() => {
    if (!assetsLiveData) {
      return undefined
    }

    let assetId = [asset.id]

    if (asset instanceof EC2) {
      assetId = asset.ebsIds as Array<string>
    }
    const threatNamesSet = new Set<string>()

    assetsLiveData.threats.forEach((threat) => {
      if (assetId.includes(threat?.assetId ?? '')) {
        threatNamesSet.add(threat.name)
      }
    })

    return Array.from(threatNamesSet)
  }, [assetsLiveData])

  return (
    <Stack direction={'row'} mb={1} flexWrap={'wrap'} columnGap={1} rowGap={1}>
      {threatName?.map((threat: string) => (
        <StatusChip
          key={threat}
          label={threat}
          type="error"
          sx={{ paddingX: 0.5 }}
        />
      ))}
    </Stack>
  )
}

interface ThreatSummaryProps {
  asset: Asset
}

export default ThreatSummary
