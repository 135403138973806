import { Column } from 'ui-v2/src/components/ui/data-display/data-table'

import { ScanListTableRow } from 'ui-v2/src/lib/models/assets/infected-assets'
import dayjs from 'dayjs'

export const getScanColumns = (): Array<Column<ScanListTableRow>> => [
  {
    key: 'scanTime',
    header: 'Scan Time',
    render: (row) => <>{dayjs(row.scanTime).format('hh:mm A, MM/DD/YYYY')}</>,
  },
  {
    key: 'scanType',
    header: 'Scan Type',
    render: (row) => row.scanType,
  },
  {
    key: 'source',
    header: 'Source',
    render: (row) => row.source,
  },
]
