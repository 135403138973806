import {
  DrawerName,
  useDrawerActions,
  useDrawerState,
} from '../../use-drawer-store'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  reportLabelMapper,
  ReportType,
} from '@features/reports/schedules/shared'
import React from 'react'
import { useRecipients } from '@features/reports/schedules/use-users-list-store'
import {
  extractAllReportTypesAndParams,
  getScheduleDescription,
  formatToMMDDYYYY,
  mapBackendFormatCodesToUI,
} from '@features/reports/schedules/shared'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { reportPeriodOptions } from '@features/reports/schedules/report/drawer/dynamic-form/schedule'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReportPeriod } from 'blues-corejs/dist/models/reports/report-schedule/period'
// @ts-nocheck

function ListWithBackground<T>({
  title,
  items,
  renderItem,
}: {
  title: string
  items: Array<T>
  renderItem: (item: T, index: number) => React.ReactNode
}) {
  if (items.length === 0) {
    return null
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F6F9FC',
        padding: '4px 16px 4px 16px',
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <List dense={false}>
        {items.map((item, index) => (
          <ListItem key={index} disableGutters sx={{ padding: 0 }}>
            {renderItem(item, index)}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

const SummaryTitle = ({ reportType }: { reportType: string }) => {
  return (
    <Typography
      variant="h6"
      sx={{
        fontSize: '16px',
        color: '#4E5665',
        fontWeight: 500,
      }}
    >
      {reportLabelMapper[reportType as ReportType]}
    </Typography>
  )
}

const formatListToString = (formats: Array<any> = []): string =>
  formats.map((format: string) => format).join(' / ')

interface KeyValueDisplayProps {
  title: string
  value: string | JSX.Element
}

const KeyValueDisplay = ({ title, value }: KeyValueDisplayProps) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="body1" fontWeight="bold" mr={1}>
        {title}:
      </Typography>
      <Typography variant="body1" fontStyle="italic">
        {value}
      </Typography>
    </Box>
  )
}

const getReportPeriod = (period: ReportPeriod): string | undefined => {
  const periodKey = Object.keys(period)[0]

  return reportPeriodOptions.find((pItem: any) => pItem.value === periodKey)
    ?.label
}

const CURRENT_DRAWER_NAME = DrawerName.ReportDetails

function DrawerActionHeader() {
  const { closeDrawer } = useDrawerActions()
  const { data } = useDrawerState(CURRENT_DRAWER_NAME)
  const handleClose = () => {
    closeDrawer(CURRENT_DRAWER_NAME)
  }

  return (
    <Grid container fontSize="14px" rowSpacing={2}>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" gap="8px">
          <Typography fontSize="22px" fontWeight="600">
            {data.name}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="5px">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs>
        {/*{renderAssetCredentials(asset)}*/}
      </Grid>
    </Grid>
  )
}

function ReportDetailsDrawer() {
  const { isOpen, data } = useDrawerState(DrawerName.ReportDetails)
  const { closeDrawer } = useDrawerActions()
  const recipients = useRecipients()
  if (!data) {
    return null
  }

  const matchedRecipients = recipients.filter((user) =>
    data.recipientIds.includes(user.value)
  )

  const { reportType, reportParams } = extractAllReportTypesAndParams(
    data?.reportKindWithParams
  )

  const formatsList = mapBackendFormatCodesToUI(
    reportType as string,
    reportParams?.formatsList
  )

  const accounts = (
    (data?.accounts || []) as Array<{ accountName: string }>
  ).filter((item) => !!item.accountName)

  const {
    lastReportGenerationCompletedAt,
    nextReportGenerationAt,
    timezone,
    description,
  } = data

  const reportPeriod = getReportPeriod(reportParams?.period)

  const lastReportGeneration = (
    <DateTimeTooltip
      placement="top-start"
      direction="row"
      showTimeFirst
      date={lastReportGenerationCompletedAt}
    />
  )

  const nextReportGeneration = (
    <DateTimeTooltip
      placement="top-start"
      direction="row"
      showTimeFirst
      date={nextReportGenerationAt}
    />
  )

  return (
    <Drawer
      onClose={() => closeDrawer(CURRENT_DRAWER_NAME)}
      anchor="right"
      open={isOpen}
      PaperProps={{
        sx: { width: '35%' },
      }}
    >
      <DrawerActionHeader />
      <Stack dir="column" spacing={2}>
        <SummaryTitle reportType={reportType as string} />
        {description && (
          <KeyValueDisplay title="Description" value={description} />
        )}

        <KeyValueDisplay
          title={'Frequency'}
          value={getScheduleDescription(data.schedule)}
        />
        <KeyValueDisplay
          title="Started"
          value={formatToMMDDYYYY(data.schedule.startsOn)}
        />

        <ListWithBackground
          title="Accounts"
          items={accounts}
          renderItem={(account) => (
            <ListItemText primary={account.accountName as any} />
          )}
        />
        <ListWithBackground
          title="Recipients"
          items={matchedRecipients}
          renderItem={(recipient) => (
            <ListItemText primary={recipient.label as string} />
          )}
        />

        <KeyValueDisplay
          title="Format"
          value={formatListToString(formatsList ?? [])}
        />

        <KeyValueDisplay title={'Timezone'} value={timezone} />

        {lastReportGenerationCompletedAt && (
          <KeyValueDisplay
            title={'Last Generated'}
            value={lastReportGeneration}
          />
        )}
        {nextReportGenerationAt && (
          <KeyValueDisplay
            title={'Next report due on'}
            value={nextReportGeneration}
          />
        )}
        {reportPeriod && (
          <KeyValueDisplay title={'Report Period'} value={reportPeriod} />
        )}
      </Stack>
    </Drawer>
  )
}

export default ReportDetailsDrawer
