/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useModalFiltersStore } from '@features/jobs/cloud-connector-jobs/filters-modal/cloud-connector-filters-store'
import { getHumanReadableCloudConnectorJobStatus } from '@features/jobs/cloud-connector-jobs/helpers'
import { usePossibleCloudConnectorJobFilters } from '@features/jobs/cloud-connector-jobs/stores'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import { CloudConnectorJobStatusCheckbox } from '@features/jobs/cloud-connector-jobs/shared'
import {
  useModalFilterActions,
  useSelectedModalFilters,
} from '../cloud-connector-filters-store'

export function JobStatusesSection() {
  const { statusesList: initialStatusesList } =
    usePossibleCloudConnectorJobFilters()

  const filterSearchValue = useModalFiltersStore(
    (state) => state.filterSearchValue
  )

  const { setFilter } = useModalFilterActions()

  const statusesList = useMemo(
    () =>
      Array.from(initialStatusesList).filter((status) =>
        getHumanReadableCloudConnectorJobStatus(status)
          .toLowerCase()
          .includes(filterSearchValue)
      ),
    [initialStatusesList, filterSearchValue]
  )

  const { statusesList: selectedStatusesList } = useSelectedModalFilters()

  if (statusesList.length === 0) {
    return null
  }

  function handleCheckboxChange(
    checked: boolean,
    status: CloudConnectorJobStatus
  ) {
    const newStatusesList = new Set(selectedStatusesList)

    if (checked) {
      newStatusesList.add(status)
    } else {
      newStatusesList.delete(status)
    }

    setFilter('statusesList', newStatusesList)
  }

  return (
    <Fragment>
      <Typography
        fontSize="12px"
        lineHeight="140%"
        marginBottom="10px"
        marginTop="10px"
        color="#71768e"
        fontWeight="700"
      >
        Job Status
      </Typography>
      <Box display="flex" gap="12px" flexDirection="column">
        {statusesList.map((status) => {
          return (
            <CloudConnectorJobStatusCheckbox
              key={status}
              onChange={handleCheckboxChange}
              isChecked={selectedStatusesList.has(status)}
              status={status}
            />
          )
        })}
      </Box>
    </Fragment>
  )
}
