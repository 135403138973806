import { ColorSystemOptions } from '@mui/material'

export const darkColorSystem: ColorSystemOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#1AA8EA',
      contrastText: '#fff',
    },
    accent: {
      main: '#FF1654',
    },
    background: {
      default: '#141218',
      secondary: '#152635', // Used for navbar and sidebar
      tertiary: '#181B1F', //Used for table row body
      paper: '#1A1F28',
      drawer: '#1A1A1A',
      dialog: '#2A2C38',
      hover: '#2F3745',
    },
    text: {
      primary: '#D3D8DE',
      secondary: '#B2B5C1',
      muted: '#9ca0ad',
    },
    icon: {
      main: '#E3E8ED',
    },
    border: {
      main: '#e4dbe940',
    },
    grey: {
      700: '#ccccdc33',
      800: '#f9f9fb',
    },
    success: {
      main: '#50C56A',
      dark: '#2FAD0999',
    },
    error: {
      main: '#ed093f',
      dark: '#94193b',
    },
    warning: {
      main: '#d9ad14',
      dark: '#947919',
    },
  },
}
