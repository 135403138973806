import React, { memo, useEffect, useState } from 'react'
import { EngineCallback, Nullable, VIRow } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import clsx from 'clsx'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { VPC_SUBNET_NAME_TOOLTIP_LENGTH } from '@lib/constants/tooltip.constant'
import QuestionHint from '@components-simple/question-hint/QuestionHint'

const TOOLTIP_MESSAGE =
  'No VPC was found in the selected region or the region is disabled in AWS'

interface Props {
  possible: VIRow
  selected: Nullable<ValueInterface>
  onChange: EngineCallback<Nullable<ValueInterface>>
  selectPlaceholder?: string
  checkboxLabel?: string
  checkboxSubLabel?: string
  disabled?: boolean
  possibleSub: VIRow
  selectedSub: Nullable<VIRow>
  onChangeSub: EngineCallback<Nullable<VIRow>>
  selectPlaceholderSub?: string
  countLabelSub?: string
  menuWidth?: string
}

function ViCheckboxSelect({
  possible,
  selected,
  onChange,
  checkboxLabel = '',
  checkboxSubLabel = '',
  selectPlaceholder = '',
  disabled = false,
  possibleSub,
  selectedSub,
  onChangeSub,
  selectPlaceholderSub = '',
  countLabelSub = '',
  menuWidth = '350px',
}: Props) {
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(!!selected)

  // trace changes for checkbox
  useEffect(() => {
    const newValue = !!selected
    if (checkboxChecked === newValue) {
      return
    }
    setCheckboxChecked(newValue)
  }, [selected])

  const onCheckbox = (value: boolean) => {
    setCheckboxChecked(value)
    if (!value && selected) {
      onChange(null)
      onChangeSub(null)
    } else if (possible.length > 0) {
      onChange(possible[0])
    }
  }

  return (
    <div
      className={clsx('wrap-1626773739381', {
        viCsActive: !!selected,
      })}
    >
      <div className="viCsCheckboxRow">
        <V2Checkbox
          disabled={disabled}
          checked={checkboxChecked}
          onChange={onCheckbox}
          label={checkboxLabel}
          variant="table"
        />
        {checkboxSubLabel && (
          <div className="vsCsSubName">{checkboxSubLabel}</div>
        )}
        {/* if vpc is empty*/}
        {!possible.length && <QuestionHint text={TOOLTIP_MESSAGE} />}
      </div>
      <div className="viCsSelectsRow">
        <ViSelect
          disabled={disabled}
          possible={possible}
          selected={selected === null ? undefined : selected}
          onChange={(value) => onChange(value === undefined ? null : value)}
          placeholder={selectPlaceholder}
          getLabel={(v) => {
            if (v.label) {
              return (
                <LongTextTooltip
                  text={v.label}
                  placement="right"
                  tooltipText={v.name}
                  maxLength={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
                  show
                />
              )
            }
            return v.name
          }}
        />
        <ViMultipleSelect
          disabled={selected === null || disabled}
          possible={possibleSub}
          selected={selectedSub === null ? undefined : selectedSub}
          onChange={(value) => onChangeSub(value === undefined ? null : value)}
          placeholder={selectPlaceholderSub}
          countLabel={countLabelSub}
          showNameInTooltip
          menuWidth={menuWidth}
          maxLengthInTooltip={VPC_SUBNET_NAME_TOOLTIP_LENGTH}
        />
      </div>
    </div>
  )
}

export default memo(ViCheckboxSelect)
