import PagePathInterface from '@lib/interfaces/page-path.interface'
import React, { ReactNode } from 'react'
import AssetsIcon from '@inline-img/left-side-menu/assets-icon'
import JobsIcon from '@inline-img/left-side-menu/sub/jobs-icon'
import PagePathConstant from '@lib/constants/page-path.constant'
import OnboardingIcon from '@inline-img/left-side-menu/onboarding-icon'
import DashboardIcon from '@inline-img/left-side-menu/dashboard-icon'
import PoliciesIcon from '@inline-img/left-side-menu/policies-icon'
import SourcesIcon from '@inline-img/left-side-menu/sources-icon'
import ReportsIcon from '@inline-img/left-side-menu/reports-icon'
import AllJobsIcon from '@inline-img/general/nav-bar/all-jobs-icon'
import CloudConnectorIcon from '@inline-img/general/nav-bar/cloud-connector-icon'
import DeploymentIcon from '@inline-img/general/nav-bar/deployment-icon'
import SystemIcon from '@inline-img/general/nav-bar/system-icon'
import AuditLogIcon from '@inline-img/general/nav-bar/audit-log-icon'
import NotificationNavIcon from '@inline-img/general/nav-bar/notification-nav-icon'

export const dividerStyle = {
  borderColor: '#999caa',
  opacity: 0.2,
  marginBottom: '4px',
}

export function drawMenuIcon(pageData: PagePathInterface): ReactNode {
  // parent pages don't have paths - they open the first child
  // so for them so far we do check by name
  switch (pageData.name) {
    case 'Assets':
      return <AssetsIcon />
    case 'Jobs':
      return <JobsIcon />
  }

  switch (pageData.path) {
    case PagePathConstant.ONBOARDING:
      return <OnboardingIcon />
    case PagePathConstant.DASHBOARD:
      return <DashboardIcon />
    case PagePathConstant.POLICIES:
      return <PoliciesIcon />
    case PagePathConstant.SOURCES:
      return <SourcesIcon />
    case PagePathConstant.REPORTS:
      return <ReportsIcon />
    case PagePathConstant.JOBS:
      return <AllJobsIcon />
    case PagePathConstant.CLOUD_CONNECTOR:
      return <CloudConnectorIcon />
    case PagePathConstant.DEPLOYMENT:
      return <DeploymentIcon />
    case PagePathConstant.SYSTEM:
      return <SystemIcon />
    case PagePathConstant.AUDIT_LOG:
      return <AuditLogIcon />
    case PagePathConstant.ALERTS:
      return <NotificationNavIcon />
    default:
      return null
  }
}

export function checkSelectedAmongChildren(
  pages: Array<PagePathInterface>,
  toFind: string
): boolean {
  return pages.map((p) => p.path).includes(toFind)
}

export function isMenuSelected(subPath: string, fullPath: string) {
  const normalize = (v: string): string => v.replace(/\/$/, '')
  // slash normalizing
  const finalSubPath = normalize(subPath)
  const finalFullPath = normalize(fullPath)
  // check the beginning of the path
  const re = new RegExp(`^${finalSubPath}`)

  return re.test(finalFullPath)
}
