import { ButtonPrimary } from '@components/shared'
import Box from '@mui/material/Box'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { StrHelper } from '@lib/helpers'
import { useGetReportDownload } from '@features/reports/schedules/report-download/use-get-report-download'
import { useLayoutContext } from '@features/contexts'
import { useEffect } from 'react'
import { PagePathConstant } from '@lib/constants'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { useParams } from 'react-router-dom'

const HeaderAndBreadcrumbHeight = 65 + 45

function ReportDownload() {
  const { id: reportId } = useParams()

  const { report, isLoading } = useGetReportDownload({
    id: StrHelper.base64Decode(String(reportId || '')),
  })
  const generatedReport = report?.generatedReport

  const reportName = generatedReport?.getReadableReportType()
  // @ts-ignore
  const reportDescription = generatedReport?.getReadableReportDescription()

  const { setBreadcrumbsPaths, setHeaderTitle, setCurrentCrumbTitle } =
    useLayoutContext()

  useEffect(() => {
    if (!reportName) {
      setBreadcrumbsPaths([])
      setHeaderTitle('')
      return
    }

    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
      },
      {
        href: PagePathConstant.REPORTS,
        text: titlesForBreadcrumb(PagePathConstant.REPORTS) ?? '',
      },
    ])
    setCurrentCrumbTitle(reportName)

    setHeaderTitle(reportName)

    return () => {
      setHeaderTitle(null)
      setCurrentCrumbTitle(null)
    }
  }, [reportName])

  if (isLoading || !report) {
    return null
  }

  const { pdfUrl, xlsxUrl, csvUrl } = report

  const handleDownloadFile = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: `calc(100vh - ${HeaderAndBreadcrumbHeight}px)`,
      }}
    >
      <Box
        sx={{
          width: 420,
          boxShadow: '0px 4px 8px 0px #0000001A',
          background: '#fff',
          padding: '68px 22px 36px 22px',
        }}
      >
        <Card
          variant="outlined"
          sx={{
            borderRadius: '6px',
            borderColor: '#EAEFF3',
            boxShadow: '0px 4px 8px 0px #0000001A',
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              sx={{
                color: '#2F3745',
                fontSize: '16px',
                fontWeight: 700,
              }}
              gutterBottom
            >
              {reportName}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {reportDescription}
            </Typography>
          </CardContent>
        </Card>
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop="38px"
          gap={2}
          height={40}
        >
          {pdfUrl && (
            <ButtonPrimary
              size="large"
              onClick={() => handleDownloadFile(pdfUrl)}
            >
              Download PDF
            </ButtonPrimary>
          )}
          {xlsxUrl && (
            <ButtonPrimary
              size="large"
              onClick={() => handleDownloadFile(xlsxUrl)}
            >
              Download XLSX
            </ButtonPrimary>
          )}
          {csvUrl && (
            <ButtonPrimary
              size="small"
              onClick={() => handleDownloadFile(csvUrl)}
            >
              Download CSV
            </ButtonPrimary>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ReportDownload
