import RegexConstants from '@lib/constants/regex.constant'
import ErrorValidationConstants from '@lib/constants/error-validation.constant'

function vaultNameValidator(vaultName: string) {
  if (RegexConstants.VAULT_NAME.test(vaultName)) {
    return true
  }
  return ErrorValidationConstants.VAULT_NAME
}

export default vaultNameValidator
