// eslint-disable-next-line import/no-extraneous-dependencies
import { TenantModel } from 'blues-corejs/dist'

export const ADD_TENANT = 'ADD_TENANT'
export const SET_CREATED_TENANT_NAME = 'SET_CREATED_TENANT_NAME'
export const MARK_AS_TENANT_CREATOR = 'MARK_AS_TENANT_CREATOR'
export const CANCEL_TENANT_CREATION = 'CANCEL_TENANT_CREATION'

export const REQUEST_GET_TENANT = 'REQUEST_GET_TENANT'
export const REQUEST_UPDATE_TENANT = 'REQUEST_UPDATE_TENANT'
export const SET_TENANT = 'SET_TENANT'

export const addTenant = (tenantName: string, callback: () => void) => ({
  type: ADD_TENANT,
  payload: {
    tenantName,
    callback,
  },
})

export const setCreatedTenantName = (tenantName: string) => ({
  type: SET_CREATED_TENANT_NAME,
  payload: tenantName,
})

export const markAsTenantCreator = () => ({
  type: MARK_AS_TENANT_CREATOR,
  payload: null,
})

export const cancelTenantCreation = () => ({
  type: CANCEL_TENANT_CREATION,
  payload: null,
})

// Actions related to the Tenants Service

export const requestGetTenant = () => ({
  type: REQUEST_GET_TENANT,
})

export const setTenant = (tenant: TenantModel) => ({
  type: SET_TENANT,
  payload: tenant,
})

export const requestUpdateTenant = (tenant: TenantModel) => ({
  type: REQUEST_UPDATE_TENANT,
  payload: tenant,
})
