/* eslint-disable import/no-extraneous-dependencies */
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
} from 'ui-v2/src/components/ui/drawer'
import { Box, Button, Stack } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AssetDrawerDetails from './details'
import AssetsDrawerEntropyTrend from './entropy-trend'
import AssetsDrawerActions from './actions'
import {
  useAssetsListByIdQuery,
  useAssetsLiveQuery,
} from 'ui-v2/src/hooks/queries/assets'
import { useMemo } from 'react'
import {
  getAsset,
  getAssetDetails,
  isAwsAsset,
} from 'ui-v2/src/lib/helpers/assets.helper'
import { useLastCleanBackupQuery } from 'ui-v2/src/hooks/queries/backups/last-clean-backup'
import { useAssetItemsQuery } from 'ui-v2/src/hooks/queries/assets/asset-items'
import { GenericHost } from 'blues-corejs/dist'
import { useListAnomaliesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import { getDateRange } from 'ui-v2/src/lib/helpers/time.helper'
import { useAssetsListScansQuery } from 'ui-v2/src/hooks/queries/scans'
import { useAssetsListBackupsQuery } from 'ui-v2/src/hooks/queries/backups'
import { extractScanResults } from 'ui-v2/src/lib/helpers/scans.helper'
import { getLatestBackupMapFromBackups } from 'ui-v2/src/lib/helpers/backups.helper'
import InfectedAssets from './infected-assets'
import { Grid2 } from '@mui/material'
import InfectedBackupProvider from './infected-backup-provider'
import Link from 'ui-v2/src/components/ui/data-display/link'

const AssetsDetailsDrawer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { assetId } = useParams()

  const {
    data: assetsData,
    isLoading: isAssetsDataLoading,
    isError: isAssetsDataError,
  } = useAssetsListByIdQuery(assetId ? [assetId] : [])

  const {
    data: assetsLiveData,
    isLoading: isAssetsLiveDataLoading,
    isError: isAssetsLiveDataError,
  } = useAssetsLiveQuery()

  const asset = useMemo(() => {
    if (!assetsData?.assetsList?.[0] || !assetsData.assetsList.length) {
      return undefined
    }
    return getAsset(assetsData?.assetsList?.[0])
  }, [assetsData])

  const { data: assetsListScans, isLoading: isLoadingAssetsListScans } =
    useAssetsListScansQuery(
      {
        assetIdList: asset ? [asset.id] : [],
        pageSize: 1,
      },
      {
        enabled: !!asset,
      }
    )

  const { data: assetsListBackups, isLoading: isLoadingAssetsListBackups } =
    useAssetsListBackupsQuery(
      {
        assetIdList: asset ? [asset.id] : [],
        pageSize: 1,
      },
      {
        enabled: !!asset,
      }
    )

  const {
    data: assetLastCleanBackup,
    isLoading: isAssetLastCleanBackupLoading,
  } = useLastCleanBackupQuery(asset?.id)

  const { data: assetItemsData, isLoading: isAssetItemsDataLoading } =
    useAssetItemsQuery(
      {
        assetIdList: asset?.id ? [asset.id] : undefined,
        pageSize: 1000,
      },
      { enabled: !!asset && asset instanceof GenericHost }
    )

  const { data: assetAnomaliesData, isLoading: isAssetAnomaliesDataLoading } =
    useListAnomaliesQuery(
      {
        assetIdsList: asset?.id ? [asset.id] : [],
        ...getDateRange(365),
      },
      {
        enabled: !!asset && isAwsAsset(asset),
      }
    )

  const assetDetails = useMemo(() => {
    if (
      !asset ||
      !assetsData ||
      !assetsData.assetsList.length ||
      !assetsLiveData ||
      !assetsListScans ||
      !assetsListBackups ||
      !assetsData?.assetsList?.[0]
    ) {
      return undefined
    }

    const scans = extractScanResults(assetsListScans.scansRunList)
    const backups = getLatestBackupMapFromBackups(asset.id, assetsListBackups)

    return getAssetDetails({
      asset: assetsData?.assetsList?.[0],
      assetsLiveData,
      assetItemsData,
      scans,
      backups,
    })
  }, [
    asset,
    assetsData,
    assetsLiveData,
    assetItemsData,
    assetsListScans,
    assetsListBackups,
  ])

  const isLoading =
    isAssetsDataLoading ||
    isAssetsLiveDataLoading ||
    isAssetLastCleanBackupLoading ||
    isAssetItemsDataLoading ||
    isAssetAnomaliesDataLoading ||
    isLoadingAssetsListScans ||
    isLoadingAssetsListBackups

  const isError = isAssetsDataError || isAssetsLiveDataError

  const isEmpty =
    !isLoading && !isError && !!assetsData && !assetsData.assetsList.length

  const closeDrawer = () => {
    const currentPath = location.pathname
    const newPath = currentPath.split('/').slice(0, -1).join('/')
    const searchParams = location.search
    navigate(newPath + searchParams)
  }

  return (
    <Drawer
      open={!!assetId}
      onClose={closeDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: 1300,
        },
      }}
    >
      <DrawerHeader title="Asset Details" handleCloseDrawer={closeDrawer} />
      <DrawerContent isLoading={isLoading} isError={isError} isEmpty={isEmpty}>
        <Stack
          spacing={3}
          direction="column"
          flexWrap={'wrap'}
          paddingX={2.5}
          paddingBottom={2.5}
        >
          <Grid2 container columnSpacing={4}>
            {assetDetails ? (
              <Grid2
                size={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                }}
              >
                <AssetDrawerDetails
                  assetDetails={assetDetails}
                  asset={asset!}
                />
              </Grid2>
            ) : null}
            {assetAnomaliesData ? (
              <Grid2
                size={{
                  xs: 12,
                  sm: 12,
                  md: 6,
                }}
              >
                <AssetsDrawerEntropyTrend
                  data={assetAnomaliesData.anomaliesList}
                />
              </Grid2>
            ) : null}
          </Grid2>
          <Box
            display={'flex'}
            columnGap={2}
            alignItems={'center'}
            flexWrap={'wrap'}
          >
            <Link
              to="restore-last-clean-backup"
              preservePath
              preserveSearchParams
            >
              <Button
                variant="contained"
                color="success"
                sx={{
                  color: 'white',
                  textTransform: 'capitalize',
                }}
              >
                Restore Last Clean Recovery Point
              </Button>
            </Link>

            <InfectedBackupProvider asset={asset!} />
            <AssetsDrawerActions
              asset={asset!}
              scan={assetDetails?.lastScan}
              elastioRecoveryPoint={assetLastCleanBackup?.elastioRps}
            />
          </Box>
          <InfectedAssets asset={asset!} />
        </Stack>
      </DrawerContent>
    </Drawer>
  )
}

export default AssetsDetailsDrawer
