/* eslint-disable import/no-extraneous-dependencies */
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
} from 'blues-corejs/dist'
import { FilesystemScanBackup } from 'blues-corejs/dist/models'
import { MalwareScanBackup } from 'blues-corejs/dist/models/scans/malware'
import { RansomwareScanBackup } from 'blues-corejs/dist/models/scans/ransomware'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

export enum Provider {
  ELASTIO = 0,
  AWS = 1,
  OVA_VEEAM = 2,
  OVA_COMMVAULT = 3,
  OVA_RUBRIK = 4,
  OVA_COHESITY = 5,
  OVA_VERITAS_NET_BACKUP = 6,
  OVA_AWS_BACKUP_VMWARE = 7,
}

type BackupScanTarget = {
  backupId: string
  backupTimestamp: Date
  backupProvider: Provider
  backupAssetItemId?: string
}

type DirectScanTarget = {
  assetId: string
  assetItemId?: string
}

export type ScanTarget = BackupScanTarget | DirectScanTarget

type Scan = MalwareScan | RansomwareScan | FilesystemScanCheck

function computeMalwareScanTarget(scan: MalwareScan): ScanTarget {
  if (scan.scanTarget.target instanceof MalwareScanBackup) {
    return {
      backupId: scan.scanTarget.target.backupId,
      backupTimestamp: scan.scanTarget.target.timestamp,
      backupProvider: scan.scanTarget.target.provider,
      backupAssetItemId:
        scan.scanTarget.target.source.assetItem?.backupAssetItemId,
    }
  } else if ('assetItemId' in scan.scanTarget.target) {
    return {
      assetId: scan.scanTarget.target.assetId,
      assetItemId: scan.scanTarget.target.assetItemId,
    }
  } else if ('assetId' in scan.scanTarget.target) {
    return {
      assetId: scan.scanTarget.target.assetId,
    }
  } else {
    sentryReThrowCatchHandler(
      `[MostRecentScan] MalwareScan target not supported: ${scan.id}`
    )
    throw new Error(
      `[MostRecentScan] MalwareScan target not supported: ${scan.id}`
    )
  }
}

function computeRasterwareScanTarget(scan: RansomwareScan): ScanTarget {
  if (scan.scanTarget.target instanceof RansomwareScanBackup) {
    return {
      backupId: scan.scanTarget.target.backupId,
      backupTimestamp: scan.scanTarget.target.timestamp,
      backupProvider: scan.scanTarget.target.provider,
    }
  } else if ('assetItemId' in scan.scanTarget.target) {
    return {
      assetId: scan.scanTarget.target.assetId,
      assetItemId: scan.scanTarget.target.assetItemId,
    }
  } else if ('assetId' in scan.scanTarget.target) {
    return {
      assetId: scan.scanTarget.target.assetId,
    }
  } else {
    sentryReThrowCatchHandler(
      `[MostRecentScan] RansomwareScan target not supported: ${scan}`
    )

    throw new Error(
      `[MostRecentScan] RansomwareScan target not supported: ${JSON.stringify(
        scan
      )}`
    )
  }
}

function computeFsCheckScanTarget(scan: FilesystemScanCheck): ScanTarget {
  if (scan.scanTarget.target instanceof FilesystemScanBackup) {
    return {
      backupId: scan.scanTarget.target.backupId,
      backupTimestamp: scan.scanTarget.target.timestamp,
      backupProvider: scan.scanTarget.target.provider,
    }
  } else if ('assetId' in scan.scanTarget.target) {
    return {
      assetId: scan.scanTarget.target.assetId,
    }
  } else {
    sentryReThrowCatchHandler(
      `[MostRecentScan] FilesystemScanCheck target not supported: ${scan}`
    )

    throw new Error(
      `[MostRecentScan] FilesystemScanCheck target not supported: ${JSON.stringify(
        scan
      )}`
    )
  }
}

export function computeScanTarget(scan: Scan): ScanTarget {
  if (scan instanceof MalwareScan) {
    return computeMalwareScanTarget(scan)
  } else if (scan instanceof RansomwareScan) {
    return computeRasterwareScanTarget(scan)
  } else if (scan instanceof FilesystemScanCheck) {
    return computeFsCheckScanTarget(scan)
  } else {
    /// Not reachable code
    sentryReThrowCatchHandler(
      `[MostRecentScan] Scan type not supported: ${scan}`
    )
    throw new Error(
      `[MostRecentScan] Scan type not supported: ${JSON.stringify(scan)}`
    )
  }
}

export function computeScansTarget(scans: Array<Scan>): Array<ScanTarget> {
  return scans.map((scan) => computeScanTarget(scan))
}
