/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { TenantJobFiltersUI } from '@lib/interfaces/jobs'

interface ModalFiltersState {
  isFilterDialogOpen: boolean
  filterSearchValue: string
  selectedFilters: TenantJobFiltersUI
}

const getInitialFiltersState = (): ModalFiltersState => ({
  isFilterDialogOpen: false,
  filterSearchValue: '',
  selectedFilters: {
    kindsList: new Set(),
    statusesList: new Set(),
  },
})

interface ModalFiltersStoreActions {
  resetModalFiltersStore: () => void
  closeFilterDialog: () => void
  openFilterDialog: (selectedFilters: TenantJobFiltersUI) => void
  setFilter: <K extends keyof TenantJobFiltersUI>(
    key: K,
    value: TenantJobFiltersUI[K]
  ) => void
  setFilterSearchValue: (value: string) => void
}

const useModalFiltersStore = create<
  ModalFiltersState & {
    actions: ModalFiltersStoreActions
  }
>((set) => ({
  ...getInitialFiltersState(),
  actions: {
    closeFilterDialog: () => set({ isFilterDialogOpen: false }),
    resetModalFiltersStore: () => set(getInitialFiltersState()),
    openFilterDialog: (selectedFilters: TenantJobFiltersUI) =>
      set({
        selectedFilters,
        isFilterDialogOpen: true,
      }),
    setFilter: (key, value) => {
      set((state) => ({
        selectedFilters: {
          ...state.selectedFilters,
          [key]: value,
        },
      }))
    },
    setFilterSearchValue: (value) => set({ filterSearchValue: value }),
  },
}))

export const useIsModalFilterDialogOpen = () =>
  useModalFiltersStore((state) => state.isFilterDialogOpen)

export const useModalFilterSearchValue = () =>
  useModalFiltersStore((state) => state.filterSearchValue)

export const useModalFilterActions = () =>
  useModalFiltersStore((state) => state.actions)

export const useSelectedModalFilters = () =>
  useModalFiltersStore((state) => state.selectedFilters)

export const useTotalSelectedFilters = () => {
  const selectedFilters = useSelectedModalFilters()

  const totalSelectedFilters = Object.values(selectedFilters).reduce(
    (acc, filterSet) => acc + filterSet.size,
    0
  ) as number

  return totalSelectedFilters > 0 ? `(${totalSelectedFilters})` : ''
}
