import React from 'react'
import TableHead from '@mui/material/TableHead'
import { TableVirtuoso } from 'react-virtuoso'
import { Row } from '@tanstack/react-table'
import { styled, TableProps as MUITableProps } from '@mui/material'

import { Root } from './styles'
import { Table, TableBody, Scroller, FixedHeaderContent } from './components'
import TableRow from './table-row'
import { VirtualTableConfig } from './types'
import { useTableConfig } from './useTableConfig'
import EmptyPlaceholder from '@components/table/shared/no-data/no-data'
import { PreloaderBlock } from '@components-simple/preloaders'

const TableHeadStyled = styled(TableHead)`
  background-color: var(--white);
`

const TableHeadWrapper: React.FC<
  React.HTMLAttributes<HTMLTableSectionElement>
> = (props) => {
  return <TableHeadStyled {...props} />
}

const RootStyled = styled(Root)`
  height: calc(100vh - 325px);
`

type VirtualTableWithRowSelectionOwnProps<T> = {
  config: VirtualTableConfig<T>
  emptyPlaceholderText: string
  className?: string
  componentsProps?: {
    // To customize the table components
    tableProps?: MUITableProps
  }
}

function VirtualTable<T>({
  config,
  emptyPlaceholderText,
  componentsProps,
  className,
}: VirtualTableWithRowSelectionOwnProps<T>) {
  const [table, { rows }] = useTableConfig(config)

  if (config.state.isLoading) {
    return <PreloaderBlock show />
  }

  if (!rows.length) {
    return <EmptyPlaceholder text={emptyPlaceholderText} />
  }

  return (
    <RootStyled className={className}>
      <TableVirtuoso
        data={rows}
        components={{
          Scroller,
          Table: (props) => {
            return (
              <Table
                virtuosoProps={props}
                muiProps={componentsProps?.tableProps}
              />
            )
          },
          TableHead: TableHeadWrapper,
          TableRow: (props) => {
            const index = props['data-index']
            // eslint-disable-next-line no-unused-vars
            const { item, ...restProps } = props
            const row = rows[index]
            return <TableRow row={row as Row<T>} key={row?.id} {...restProps} />
          },
          TableBody,
        }}
        fixedHeaderContent={() => (
          <FixedHeaderContent headerGroups={table.getHeaderGroups()} />
        )}
        totalCount={rows.length}
      />
    </RootStyled>
  )
}

export default VirtualTable
