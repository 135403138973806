import React, { memo, useEffect, useState } from 'react'
import { InputAdornment, OutlinedInput } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@inline-img/general/menu/delete-icon'
import { TextConstant } from '@lib/constants'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

interface Props {
  disabled: boolean
  setPaths: React.Dispatch<React.SetStateAction<Array<string>>>
  paths: Array<string>
  showTitle?: boolean
  description?: string
}

function SpecificPathsBlock({
  paths,
  disabled,
  setPaths,
  showTitle = true,
  description = TextConstant.SPECIFIC_PATHS_DESCRIPTION,
}: Props) {
  const [pathInput, setPathInput] = useState('')

  const [inputError, setInputError] = useState('')

  const isPathCorrect = (value: string): boolean => {
    if (paths.includes(value)) {
      setInputError('This path is already in the list')
      return false
    }

    if (value.length > 1024) {
      setInputError(
        'Path can have a maximum character length of 1024 characters.'
      )
      return false
    }

    return true
  }

  const checkPathOnError = (value: string) => {
    if (value && !isPathCorrect(value)) {
      return
    }
    if (!value && !disabled && !paths.length) {
      setInputError('The list of paths is empty')
      return
    }
    setInputError('')
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEvent = event.nativeEvent as InputEvent

    if (inputEvent.inputType === 'insertFromPaste') {
      return
    }

    const value = event.target.value
    setPathInput(value)

    checkPathOnError(value)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputError) {
      return
    }
    if (event.key === 'Enter') {
      setPaths([...paths, pathInput])
      setPathInput('')
    }
  }
  const onPaste = (event: React.ClipboardEvent) => {
    const text = event.clipboardData.getData('text')
    isPathCorrect(pathInput + text)
    setPathInput(pathInput + text)
    checkPathOnError(pathInput + text)
  }

  const onSave = () => {
    if (inputError) {
      return
    }

    if (!pathInput) {
      setInputError('Enter the path')
      return
    }

    setPaths([...paths, pathInput])
    setPathInput('')
  }

  const onDelete = (deletedPath: string) => {
    setPaths(paths.filter((item) => item !== deletedPath))
  }

  useEffect(() => {
    if (!inputError && !disabled && !paths.length) {
      setInputError('The list of paths is empty')
      return
    }
    setInputError('')
  }, [disabled, JSON.stringify(paths)])

  return (
    <div className="wrap-1687356309608">
      <div className="addPathsBlock">
        {showTitle && <h3>Type path or glob</h3>}
        <OutlinedInput
          type="text"
          placeholder="Type path or glob"
          value={pathInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          disabled={disabled}
          size="small"
          inputProps={{ 'aria-label': 'path-input' }}
          endAdornment={
            <InputAdornment position="end">
              <div aria-label="icon-wrapper" id="icon-wrapper" onClick={onSave}>
                <AddIcon />
              </div>
            </InputAdornment>
          }
        />
        {!!inputError && <div className="formGlobalError">{inputError}</div>}
      </div>
      {!!description && (
        <p data-testid="description" className="description">
          {description}
        </p>
      )}
      <div className={`pathsList ${disabled && 'disabled'}`}>
        {!!paths.length &&
          paths.map((path) => (
            <div
              aria-label={`paths-block-${path}`}
              className="pathBlock"
              key={path}
            >
              <LongTextTooltip text={path} maxLength={60} customClass="path" />

              {!disabled && (
                <span
                  aria-label="delete-path-icon"
                  onClick={() => onDelete(path)}
                >
                  <DeleteIcon fill="#1A1F28" />
                </span>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default memo(SpecificPathsBlock)
