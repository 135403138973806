import React from 'react'
import { useSelectNavigationStrategy } from './hooks/use-select-navigation-strategy'

function Link({ to, children, ...restProps }: any) {
  const navigationStrategy = useSelectNavigationStrategy()

  const LinkComponent = navigationStrategy.createLinkComponent()

  return (
    <LinkComponent to={to} {...restProps}>
      {children}
    </LinkComponent>
  )
}

export default Link
