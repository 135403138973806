import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'
import AssetCoverage from './asset-coverage'
import EncryptionIntegrity from './encryption-integrity'
import RansomwareSafety from './ransomware-safety'
import RecoveryAssurance from './recovery-assurance'
import StorageHealth from './storage-health'

type RadarSectionsProps = {
  selectedSection: RadarChartAxes
}

export const RadarSections = ({ selectedSection }: RadarSectionsProps) => {
  if (selectedSection === RadarChartAxes.ASSET_COVERAGE) {
    return <AssetCoverage />
  }

  if (selectedSection === RadarChartAxes.ENCRYPTION_INTEGRITY) {
    return <EncryptionIntegrity />
  }

  if (selectedSection === RadarChartAxes.RANSOMWARE_SAFETY) {
    return <RansomwareSafety />
  }

  if (selectedSection === RadarChartAxes.RECOVERY_ASSURANCE) {
    return <RecoveryAssurance />
  }

  if (selectedSection === RadarChartAxes.STORAGE_HEALTH) {
    return <StorageHealth />
  }

  return null
}

export default RadarSections
