import React, { useRef } from 'react'
import type { EntropyFiltersZustandStore } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store'
import { createEntropyFiltersStore } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store'
import { EntropyFiltersContext } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'

type BearProviderProps = React.PropsWithChildren<{
  getInitialTimeRange: () => {
    startAt: number
    endAt: number
  }
}>

export function EntropyFiltersProvider({ children }: BearProviderProps) {
  const storeRef = useRef<EntropyFiltersZustandStore>()
  if (!storeRef.current) {
    storeRef.current = createEntropyFiltersStore()
  }

  return (
    <EntropyFiltersContext.Provider value={storeRef.current}>
      {children}
    </EntropyFiltersContext.Provider>
  )
}
