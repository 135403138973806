import {
  CellWithButtonLink,
  createColumn,
  StatusCircle,
} from '@components/table'
import { StrHelper } from '@lib/helpers'
import React from 'react'
import { AccountStatistics } from './account-summary-store'

export function getAccountSummaryColumnsDefinition(
  onAccountClick: (accountId: string) => void
) {
  return [
    createColumn<AccountStatistics>('infectedServersCount', {
      header: '',
      id: 'status',
      enableSorting: false,
      size: 20,
      meta: {
        width: '40',
      },
      cell: ({ getValue }) => {
        const infectedServersCount = getValue() as unknown as number

        const isHealthy = infectedServersCount === 0

        return <StatusCircle isHealthy={isHealthy} />
      },
    }),
    createColumn<AccountStatistics>('ovaAccountName', {
      header: 'Account',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const handleAccountClick = () => onAccountClick(original.ovaAccountId)

        return (
          <CellWithButtonLink onClick={handleAccountClick}>
            {original.ovaAccountName}
          </CellWithButtonLink>
        )
      },
    }),
    createColumn<AccountStatistics>('infectedServersCount', {
      header: 'Infected Servers',
      id: 'infected-servers',
      enableSorting: false,
      meta: {
        textAlign: 'right',
      },
      cell: ({ getValue }) => {
        const infectedServers = getValue() as unknown as number

        return infectedServers
      },
    }),
    createColumn<AccountStatistics>('protectedServersCount', {
      header: 'Protected Servers',
      enableSorting: false,
      meta: {
        textAlign: 'right',
      },
      cell: ({ getValue }) => {
        const protectedServers = getValue() as unknown as number

        return protectedServers
      },
    }),
    createColumn<AccountStatistics>('frontEndDataProtected', {
      header: 'Front-end Data Protected - MTD (GiB)',
      enableSorting: false,
      size: 260,
      meta: {
        textAlign: 'right',
      },
      cell: ({ getValue }) => {
        const dataProtectedSize = getValue() as unknown as number

        return StrHelper.bytesToGiB(dataProtectedSize)
      },
    }),
    createColumn<AccountStatistics>('dataScanned', {
      header: 'Data Scanned - MTD (GiB)',
      enableSorting: false,
      size: 260,
      meta: {
        textAlign: 'right',
      },
      cell: ({ getValue }) => {
        const dataScannedSize = getValue() as unknown as number

        return StrHelper.bytesToGiB(dataScannedSize)
      },
    }),
  ]
}
