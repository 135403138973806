/* eslint-disable import/no-extraneous-dependencies */
import { Backup } from 'blues-corejs/dist'
import { BackupsForAsset } from '../models/backups'

export function getLatestBackupMapFromBackups(
  assetId: string,
  response: BackupsForAsset
): Map<string, Backup> {
  const backupMap = new Map<string, Backup>()

  ;[
    ...response.awsbRpsList,
    ...response.ebsSnapshotsList,
    ...response.elastioRpsList,
    ...response.ovaBackupList,
  ].forEach((backup) => {
    const existingBackup = backupMap.get(assetId)

    if (
      !existingBackup ||
      new Date(backup.createdAt) > new Date(existingBackup.createdAt)
    ) {
      backupMap.set(assetId, backup)
    }
  })

  return backupMap
}
