/* eslint-disable import/no-extraneous-dependencies */
import { Box } from '@mui/material'
import React, { memo } from 'react'
import { Container } from '../styles'
import SecurityLeftColumn from './security-left-column'
import SecurityRightColumn from './security-right-column'

function Security() {
  return (
    <Container>
      <Box className="left-column">
        <SecurityLeftColumn />
      </Box>
      <Box className="right-column">
        <SecurityRightColumn />
      </Box>
    </Container>
  )
}

export default memo(Security)
