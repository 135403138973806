import { Drawer as MuiDrawer, DrawerProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface CustomDrawerProps extends DrawerProps {
  blur?: number
}

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'blur',
})<CustomDrawerProps>(({ theme, blur = 3 }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    maxWidth: '700px',
    background: theme.palette.background.drawer,
  },
  '& .MuiBackdrop-root': {
    backdropFilter: `blur(${blur}px)`,
  },
}))

const Drawer = ({ children, blur, ...props }: CustomDrawerProps) => {
  return (
    <StyledDrawer anchor="right" {...props} blur={blur}>
      {children}
    </StyledDrawer>
  )
}

export default Drawer
