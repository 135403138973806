import React from 'react'

function NormalIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#32B6F3" fillOpacity="0.2" />
      <path
        d="M5.882 8.71898V10.681H9.284V14.209H11.3V10.681H14.72V8.71898H11.3V5.20898H9.284V8.71898H5.882Z"
        fill="#32B6F3"
      />
    </svg>
  )
}

export default NormalIcon
