import { getOvaDashboardState } from '@features/ova-dashboard/ova-dashboard-store'
import { OvaStatisticsClient } from '@lib/clients'
import {
  ListInfectedAssetsFilter,
  ListInfectedAssetsFilterParams,
  ListInfectedAssetsPagination,
} from '@lib/clients/ova-statistics/list-infected-assets'
import { OvaInfectedServerResponse } from '@lib/clients/ova-statistics/types'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

function fetchInitialFilter(params: ListInfectedAssetsFilterParams) {
  return new ListInfectedAssetsFilter(params)
}

function fetchPaginated(pageToken: string) {
  return new ListInfectedAssetsPagination(pageToken)
}

const ovaStatisticsClient = new OvaStatisticsClient()

interface InfectedServerStoreState {
  infectedServers: Array<OvaInfectedServerResponse>
  isLoading: boolean
  pageToken: string
  actions: {
    fetchInitial: (ovaAccountId: string) => Promise<void>
    fetchPagination: () => Promise<void>
  }
}

const useInfectedServersStore = create<InfectedServerStoreState>(
  (set, get) => ({
    infectedServers: [],
    isLoading: false,
    pageToken: '',
    actions: {
      fetchInitial: async (ovaAccountId: string) => {
        set({ isLoading: true })

        const { ovaBackupProvidersList } = getOvaDashboardState()

        try {
          const response = await ovaStatisticsClient.listInfectedAssets(
            fetchInitialFilter({
              ovaAccountId,
              backupProvidersList: ovaBackupProvidersList,
            })
          )

          set({
            infectedServers: response.infectedServers,
            pageToken: response.pageToken,
          })
        } finally {
          set({ isLoading: false })
        }
      },
      fetchPagination: async () => {
        const { pageToken } = get()

        if (!pageToken) {
          return
        }

        try {
          set({ isLoading: true })

          const response = await ovaStatisticsClient.listInfectedAssets(
            fetchPaginated(pageToken)
          )

          set((state) => ({
            infectedServers: [
              ...state.infectedServers,
              ...response.infectedServers,
            ],
            pageToken: response.pageToken,
          }))
        } finally {
          set({ isLoading: false })
        }
      },
    },
  })
)

export const useInfectedServersStoreActions = () =>
  useInfectedServersStore((state) => state.actions)

export const useInfectedServersStoreData = () =>
  useInfectedServersStore(useShallow((state) => state.infectedServers))

export const useIsInfectedServersLoading = () =>
  useInfectedServersStore(useShallow((state) => state.isLoading))
