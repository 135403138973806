import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

interface HoverablePopoverOwnProps {
  content: React.ReactNode
  children: React.ReactNode
  title: string
  placement?: {
    vertical: 'top' | 'bottom' | 'center'
    horizontal: 'left' | 'right' | 'center'
  }
}

function HoverablePopover({
  content,
  children,
  title = '',
  placement = {
    vertical: 'bottom',
    horizontal: 'center',
  },
}: HoverablePopoverOwnProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        display: 'inline-block',
        cursor: 'pointer',
      }}
    >
      {children}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={placement}
        transformOrigin={{
          vertical: placement.vertical === 'bottom' ? 'top' : 'bottom',
          horizontal: placement.horizontal,
        }}
      >
        <Box sx={{ p: 1 }}>
          {title && (
            <Typography
              sx={{
                fontWeight: 'bold',
                mb: 1,
                fontSize: '14px',
              }}
            >
              {title}
            </Typography>
          )}
          {content}
        </Box>
      </Popover>
    </Box>
  )
}

export default HoverablePopover
