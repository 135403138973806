import { useShallow } from 'zustand/react/shallow'
import { DEPLOYMENT_JOB_KINDS } from '@features/jobs/tenant-jobs/deployment-jobs/consts'
import { useDeploymentJobsFiltersStore } from '@features/jobs/tenant-jobs/deployment-jobs/stores/filters-store/deployment-jobs-filters-store'

export const getSelectedFilters = () => ({
  selectedFilters: useDeploymentJobsFiltersStore.getState().selectedFilters,
  timeRange: useDeploymentJobsFiltersStore.getState().timeRange,
})

export const usePossibleTenantJobFilters = () =>
  useDeploymentJobsFiltersStore(
    useShallow((state) => {
      const { possibleFilters } = state

      const deploymentJobKinds = [...possibleFilters.kindsList].filter((kind) =>
        DEPLOYMENT_JOB_KINDS.includes(kind)
      )

      return {
        kindsList: deploymentJobKinds,
        statusesList: possibleFilters.statusesList,
      }
    })
  )

export const useSelectedDeploymentJobFilters = () =>
  useDeploymentJobsFiltersStore(useShallow((state) => state.selectedFilters))

export const useIsDeploymentFiltersNotEmpty = () => {
  const selectedFilters = useDeploymentJobsFiltersStore(
    (state) => state.selectedFilters
  )

  return Object.values(selectedFilters).some((filter) => filter.size > 0)
}

export const useShouldDisableFilterButton = () => {
  const possibleFilters = useDeploymentJobsFiltersStore(
    (state) => state.possibleFilters
  )

  return Object.values(possibleFilters).every((filter) => filter.size === 0)
}

export const useDeploymentJobsTimeRange = () =>
  useDeploymentJobsFiltersStore((state) => state.timeRange)

export const useDeploymentJobsFiltersActions = () =>
  useDeploymentJobsFiltersStore((state) => state.actions)
