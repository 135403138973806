// eslint-disable-next-line import/no-extraneous-dependencies

import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale/shared'
import { AWSStatsQuantile } from '@lib/clients/aws-statistics/types'

export function normalizeDataForScale(quantiles: Array<AWSStatsQuantile>) {
  return quantiles.map((quantile) => {
    const currentValue = quantile.age || 0

    return {
      label: quantile.percentile,
      value: convertMillisecondsWithLabel(currentValue).defaultFormatDate,
    } as const
  })
}
