import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIntegrityScanOption,
  getScanWithTimestamps,
  getSelectedVariant,
} from '@store/selectors/add-policy.selector'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { ScanSpecificTimestamps } from '@components-composite/scan-specific-timestamps'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'
import {
  setAddSelectedVariant,
  setScanWithTimestamps,
} from '@store/actions/add-policy.action'
import { S3ForPolicy } from '@features/policies'
import { SELECT_ASSETS_DESCRIPTION } from '../consts'
import { selectedFiltersForAddPolicies } from '@store/reducers/assets-select.reducer'
import RadioTabs from '@components-simple/radio-tabs/RadioTabs'
import {
  S3SelectedProtectVariant,
  SelectedVariant,
} from '@lib/constants/app.constant'
import {
  s3ProtectTabsLabels,
  s3ProtectTabsQuestionHint,
} from '@components-complex/add-policy-complex/policy-creation-steps/4-assets-selection/s3-protection-options/consts'
import { getS3SelectedAssetsSelect } from '@store/selectors/assets-select.selector'

interface Props {
  onS3TableErrorChange: (isInvalid: boolean) => void
}

const useGetDisabledRadioList = (): Array<S3SelectedProtectVariant> => {
  const selectedS3Assets = useSelector(getS3SelectedAssetsSelect)

  if (selectedS3Assets.length > 0) {
    return [
      S3SelectedProtectVariant.SpecificAssets,
      S3SelectedProtectVariant.AllS3Buckets,
    ]
  }

  return []
}

export function S3ProtectionOptions({ onS3TableErrorChange }: Props) {
  const dispatch = useDispatch()
  const disabledVariants = useGetDisabledRadioList()
  const selectedVariant = useSelector(getSelectedVariant)
  const variantValue =
    selectedVariant === SelectedVariant.S3Variant
      ? S3SelectedProtectVariant.SpecificAssets
      : S3SelectedProtectVariant.AllS3Buckets
  const integrityScanOption = useSelector(getIntegrityScanOption)
  const scanWithTimestamps = useSelector(getScanWithTimestamps)

  const isShowScanSpecificTimestamps =
    integrityScanOption === IntegrityScanOptions.SCAN_WITH_ELASTIO
  const isShowSpecificS3Assets = selectedVariant === SelectedVariant.S3Variant

  const handleScanSpecificTimestampsChange = (
    scan: ScanWithTimestampsInterface
  ) => {
    dispatch(setScanWithTimestamps(scan))
  }

  const handleTabChange = (newTabIndex: number) => {
    switch (newTabIndex) {
      case S3SelectedProtectVariant.SpecificAssets:
        dispatch(setAddSelectedVariant(SelectedVariant.S3Variant))
        break
      case S3SelectedProtectVariant.AllS3Buckets:
        dispatch(setAddSelectedVariant(SelectedVariant.AllS3Variant))
    }
  }

  useEffect(() => {
    if (selectedVariant === SelectedVariant.AllS3Variant) {
      onS3TableErrorChange(false)
    }
  }, [selectedVariant])

  return (
    <>
      <RadioTabs
        className="flexColumnJustifyStart assetsSelectBlock jsSelectAssetsRadio"
        tabsLabel="Protect:"
        labels={s3ProtectTabsLabels}
        currentTabIndex={variantValue}
        onCurrentTabChange={handleTabChange}
        tabsQuestionHint={s3ProtectTabsQuestionHint}
        disabledRadioList={disabledVariants}
        mode="default"
      />
      {isShowScanSpecificTimestamps && (
        <Box className="mt16" data-testid="jsAddSpecificTimestamps">
          <ScanSpecificTimestamps
            scanWithTimestamps={scanWithTimestamps}
            onChange={handleScanSpecificTimestampsChange}
          />
        </Box>
      )}

      {isShowSpecificS3Assets && (
        <>
          <Box
            className="policiesBlockSubheader mt25"
            data-testid="jsAddS3TitleTable"
          >
            Select assets
          </Box>
          <Box
            className="policiesBlockLabel fontMedium mb20"
            data-testid="jsAddS3DescriptionTable"
          >
            {SELECT_ASSETS_DESCRIPTION}
          </Box>
          <S3ForPolicy
            // @ts-ignore | Property 'onS3TableErrorChange' very strict to the React types, but we need more generic way
            onS3TableErrorChange={onS3TableErrorChange}
            initialSelectedFilters={selectedFiltersForAddPolicies()}
          />
        </>
      )}
    </>
  )
}
