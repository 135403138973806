import { Stack, FormControl, Typography, Divider } from '@mui/material'

import Grid from '@mui/material/Grid2'

import { Controller, useFormContext } from 'react-hook-form'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import Schedule from './schedule'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'
import { INVALID_POLICY_NAME } from 'ui-v2/src/lib/constants/policies.constant'
import { useParams } from 'react-router-dom'

const NameAndSchedule = ({ existingPoliciesNames }: NameAndScheduleProps) => {
  const { id } = useParams()
  const { control, trigger } = useFormContext<PolicyFormData>()
  return (
    <Stack maxWidth={640}>
      <Typography
        mb={2.5}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        Define Name, Description, Schedule Frequency and Activation Settings.
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid size={12}>
          <FormControl fullWidth>
            <Typography variant="body1" mb={1}>
              Policy Name
            </Typography>
            <Controller
              control={control}
              rules={{
                required: 'Policy Name is required',
                pattern: {
                  value: RegexConstants.POLICY_NAME,
                  message: INVALID_POLICY_NAME,
                },
                validate: (value) =>
                  !!id ||
                  !existingPoliciesNames.includes(value) ||
                  'Policy name is already exist',
              }}
              name="policyName"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    trigger('policyName')
                  }}
                  placeholder="Policy Name"
                  size="small"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  disabled={!!id}
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* TODO: NOT AVAILABLE */}
        {/* <Grid size={12}>
          <FormControl fullWidth>
            <Typography variant="body1" mb={1}>
              Description (Optional)
            </Typography>
            <Controller
              control={control}
              rules={{
                required: 'Policy Name is required',
              }}
              name="description"
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Policy Description"
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid> */}
        <Grid size={12}>
          <Divider />
        </Grid>
        <Schedule />
      </Grid>
    </Stack>
  )
}
interface NameAndScheduleProps {
  existingPoliciesNames: Array<string>
}
export default NameAndSchedule
