/* eslint-disable import/no-extraneous-dependencies */
import { styled, Typography } from '@mui/material'
import { Asset, GenericHost } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import PageHelper from '@lib/helpers/page.helper'
import { CellWithLink } from '@components/table'

const StyledResourceTypography = styled(Typography)({
  fontSize: '12px',
})

const EMPTY_RESOURCE_ID = '-'

interface Props {
  asset?: Asset
}

function computeResourceId(asset?: Asset): string {
  if (asset instanceof AWSAsset) {
    return asset.awsId
  }

  if (asset instanceof GenericHost) {
    return asset.hostname
  }

  return EMPTY_RESOURCE_ID
}

export function ResourceIdCell({ asset }: Props) {
  const resourceId = computeResourceId(asset)
  if (!asset) {
    return <StyledResourceTypography>{resourceId}</StyledResourceTypography>
  }

  const href = PageHelper.buildAssetPageUrl(asset.id)

  return (
    <StyledResourceTypography>
      <CellWithLink href={href} value={resourceId} />
    </StyledResourceTypography>
  )
}
