/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Backup, MalwareScan } from 'blues-corejs/dist'
import { Scan } from 'blues-corejs/dist/models/scans/scan'
import MalwareIcon from '@inline-img/general/malware-icon'

import {
  useComputeMalwareScanStatusByBackup,
  useComputeMalwareStatusByScan,
} from './use-compute-scans-statuses'

const StyledBox = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  marginRight: '5px',
})

function MalwareIconByScans({ scans }: { scans: Array<Scan> }) {
  const malwareScans = scans.filter(
    (scan): scan is MalwareScan => scan instanceof MalwareScan
  )

  const computedScanStatus = useComputeMalwareStatusByScan(malwareScans)

  if (!computedScanStatus) {
    return null
  }

  const { tooltipText, color, fill } = computedScanStatus

  return (
    <Tooltip title={tooltipText} placement="top">
      <StyledBox bgcolor={color} data-testid="threat-icon">
        <MalwareIcon width="24px" height="24px" fill={fill} />
      </StyledBox>
    </Tooltip>
  )
}

function MalwareIconByBackup({ backup }: { backup: Backup }) {
  const { color, fill, tooltipText } =
    useComputeMalwareScanStatusByBackup(backup)

  return (
    <Tooltip title={tooltipText} placement="top">
      <StyledBox bgcolor={color} data-testid="threat-icon">
        <MalwareIcon width="24px" height="24px" fill={fill} />
      </StyledBox>
    </Tooltip>
  )
}

interface MalwareBlockProps {
  scans?: Array<Scan>
  backup?: Backup
}

export function MalwareBlock({ scans, backup }: MalwareBlockProps) {
  if (backup) {
    return <MalwareIconByBackup backup={backup} />
  }

  if (scans) {
    return <MalwareIconByScans scans={scans} />
  }

  return null
}
