import { Box, Typography, Button } from '@mui/material'
import BlockIcon from '@mui/icons-material/Block'
import { useAuth0 } from '@auth0/auth0-react'

const AccessDenied = () => {
  const { loginWithRedirect } = useAuth0()

  const goToLoginPage = async () => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          prompt: 'login',
        },
      })
    } catch (error) {
      console.error('Login failed:', error)
    }
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        padding: 3,
      }}
    >
      <BlockIcon
        sx={{
          fontSize: 80,
          color: 'error.main',
          mb: 2,
        }}
      />

      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Access Denied
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          mb: 4,
          maxWidth: 'sm',
          textAlign: 'center',
        }}
      >
        Sorry, you don't have permission to access this page. Please contact
        your administrator if you think this is a mistake.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Button variant="contained" onClick={goToLoginPage}>
          Back to login page
        </Button>
      </Box>
    </Box>
  )
}

export default AccessDenied
