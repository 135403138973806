/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getVaultMetrics, getVaultModel } from '@store/selectors/vault.selector'
import { requestVaultMetrics } from '@store/actions/vault.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import DetailInfoCard from '@components-composite/detail-info-card/DetailInfoCard'
import { DetailInfoCardEntity } from '@lib/constants/detail-info.constant'
import ReductionIcon from '@inline-img/general/vault/reduction-icon'
import ProtectionIcon from '@inline-img/general/vault/protection-icon'
import QuestionHint from '@components-simple/question-hint/QuestionHint'
import usePreloader from '@lib/hooks/usePreloader'

interface Props {
  vaultId: string
}

// Note: Changes in PR #9292 have removed certain components from this file, and if you need them, you can restore them by looking at the PR history.

function VaultMetrics({ vaultId }: Props) {
  const dispatch = useDispatch()
  const loading = usePreloader(PreloaderConstants.REQUEST_VAULT_METRICS)

  const vault = useSelector(getVaultModel)
  const vaultMetrics = useSelector(getVaultMetrics)

  useEffect(() => {
    if (vaultId) {
      dispatch(requestVaultMetrics(vaultId))
    }
  }, [vaultId])

  return (
    <>
      {loading ? (
        <PreloaderBlock show />
      ) : (
        <div className="wrap-1649419378973 jsMetricsVaultContainer">
          <div className="vmHeader jsVaultMetrics">Metrics</div>
          <div className="vmContent">
            <div className="vmRow">
              <DetailInfoCard
                className="metricCard"
                icon={<ProtectionIcon />}
                title="Protection"
                type={DetailInfoCardEntity.Default}
              >
                <div className="vmItemRow">
                  <DetailInfoCard
                    className="metricCard vaultMetric jsAssetCountProtect"
                    title={`${vaultMetrics?.assetsCountProtect}`}
                    hint="Is updated once in 15 minutes"
                  >
                    {vaultMetrics?.assetsCountProtect !== 0
                      ? vaultMetrics?.assetsCountProtect !== 1
                        ? 'Assets'
                        : 'Asset'
                      : // TODO return link when backend is ready
                        // Ticket #8321
                        // <Link
                        //   href={PageHelper.buildUrl(
                        //     PagePathConstant.ASSETS_VAULT_ID,
                        //     vaultId
                        //   )}
                        // >
                        //   <a className="blueLink">
                        //     {vaultMetrics?.assetsCountProtect !== 1
                        //       ? 'Assets'
                        //       : 'Asset'}
                        //   </a>
                        // </Link>

                        'Asset'}
                  </DetailInfoCard>
                  <DetailInfoCard
                    className="metricCard vaultMetric"
                    title={`${vault.recoveryPointsCount}`}
                    hint="Is updated once in 15 minutes"
                  >
                    {vault.recoveryPointsCount !== 1
                      ? 'Recovery Points'
                      : 'Recovery Point'}
                  </DetailInfoCard>
                  <DetailInfoCard
                    className="metricCard jsTotalRecoveryPointSize"
                    title={vaultMetrics?.totalRecoveryPointSizeFormatted}
                  >
                    Total
                  </DetailInfoCard>
                </div>
              </DetailInfoCard>
              <DetailInfoCard
                className="metricCard"
                icon={<ReductionIcon />}
                title="Reduction"
                type={DetailInfoCardEntity.Default}
              >
                <DetailInfoCard
                  className="metricCard jsReductionFactor"
                  title={vaultMetrics?.reductionFactorFormatted}
                >
                  <div className="vmItemRow">
                    Reduction Factor
                    <QuestionHint
                      className="infoHint"
                      text={
                        <span>
                          Data protected = the size of the data that was backed
                          up.
                          <br />
                          Amount stored = The resulting size of the protected
                          data after deduplication and compression and
                          encryption.
                          <br /> Reduction factor = Data protected / Amount
                          stored.
                        </span>
                      }
                    />
                  </div>
                </DetailInfoCard>
              </DetailInfoCard>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(VaultMetrics)
