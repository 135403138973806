import StrHelper from '../../lib/helpers/str.helper'
import PagePathConstant from '../../lib/constants/page-path.constant'

export type CrumbsType = {
  href: string
  text: string
}

export const titlesForBreadcrumb = function (
  pathname: string,
  dynamicId?: string,
  view?: string
): string | undefined {
  const decodeQueryId = StrHelper.base64Decode(dynamicId)
  const path = pathname
  const lastPath = pathname
    ? pathname
        ?.split('/')
        ?.filter((v) => v.length > 0)
        ?.pop()
        ?.split('?')[0]
    : ''

  let titleFromPath = lastPath?.split('-')?.join(' ')
  if (titleFromPath && titleFromPath.length > 1) {
    titleFromPath =
      titleFromPath?.charAt(0).toUpperCase() + titleFromPath?.slice(1)
  }

  const paths = {
    [PagePathConstant.DASHBOARD]: () => {
      return 'Data Resilience Control Center'
    },
    [PagePathConstant.INVENTORY_TABLES]: () => {
      return 'Inventory'
    },
    [PagePathConstant.EC2_INVENTORY_TABLE]: () => {
      return 'EC2'
    },
    [PagePathConstant.EBS_INVENTORY_TABLE]: () => {
      return 'EBS'
    },
    [PagePathConstant.S3_BUCKET_INVENTORY_TABLE]: () => {
      return 'S3'
    },
    [PagePathConstant.EFS_INVENTORY_TABLE]: () => {
      return 'EFS'
    },
    [PagePathConstant.OVA_SERVER_INVENTORY_TABLE]: () => {
      return 'Server'
    },
    [PagePathConstant.GENERIC_HOSTS_INVENTORY_TABLE]: () => {
      return 'Generic Hosts'
    },

    [PagePathConstant.ASSETS_WITH_THREATS]: () => {
      return 'Assets with Active Threats'
    },
    [`${PagePathConstant.ASSETS_WITH_THREATS}?t=0`]: () => {
      return 'Assets with Active Threats'
    },
    [`${PagePathConstant.ASSETS_WITH_THREATS}?t=1`]: () => {
      return 'Assets with Active Threats'
    },
    [`${PagePathConstant.ASSETS_WITH_THREATS}?t=2`]: () => {
      return 'Assets with Active Threats'
    },
    [PagePathConstant.ASSETS_WITH_VULNERABILITIES]: () => {
      return 'Assets with Misconfigurations'
    },
    [`${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=0`]: () => {
      return 'Assets with Misconfigurations'
    },
    [`${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=1`]: () => {
      return 'Assets with Misconfigurations'
    },
    [`${PagePathConstant.ASSETS_WITH_VULNERABILITIES}?t=2`]: () => {
      return 'Assets with Misconfigurations'
    },
    [PagePathConstant.MOUNT_RECOVERY_POINT]: () => {
      return 'Mount'
    },
    [PagePathConstant.SOURCES]: () => {
      return 'Sources'
    },
    [PagePathConstant.CLOUD_CONFIGURE_DEPLOYMENT]: () => {
      return 'Cloud Configure Deployment'
    },
    [PagePathConstant.CLOUD_CONFIGURE_UPGRADE]: () => {
      return 'Upgrade Elastio'
    },
    [PagePathConstant.SOURCE_VAULTS]: () => {
      return `Vaults (${decodeQueryId})`
    },
    [PagePathConstant.SOURCES_ID]: () => {
      return `Edit source (${decodeQueryId})`
    },
    [PagePathConstant.POLICIES_RETENTION_ADD]: () => {
      return 'Add retention policy'
    },
    [PagePathConstant.POLICIES_RETENTION_EDIT_ID]: () => {
      return `Edit retention policy (${decodeQueryId})`
    },
    [PagePathConstant.POLICIES_RETENTION_EDIT_DEFAULT]: () => {
      return 'Edit default retention policy'
    },
    [PagePathConstant.POLICIES_ADD]: () => {
      return 'Add new policy'
    },
    [PagePathConstant.POLICIES_ID]: () => {
      return `Edit policy (${decodeQueryId})`
    },
    [PagePathConstant.WEBHOOK_EDIT_ID]: () => {
      return 'Edit webhook'
    },
    [PagePathConstant.SETTINGS_WEBHOOKS]: () => {
      return 'Tenant Settings'
    },
    [PagePathConstant.MANAGEMENT_VIEW]: () => {
      return view ? 'Edit dashboard view' : 'Add new dashboard view'
    },
    [PagePathConstant.VAULT_DETAILED]: () => {
      return ''
    },
    [PagePathConstant.OVA_SERVER_INVENTORY_TABLE]: () => {
      return 'Servers'
    },
    [PagePathConstant.AWS_ASSETS_ACTIVE_THREATS]: () => {
      return 'Active Threats'
    },
    [PagePathConstant.OVA_ASSETS_ACTIVE_THREATS]: () => {
      return 'Active Threats'
    },
    [PagePathConstant.ASSET]: () => {
      return ''
    },
    [PagePathConstant.ENTROPY_TRENDS]: () => {
      return 'Entropy Trends'
    },
    [PagePathConstant.REPORTS_SINGLE_PAGE]: () => {
      return ''
    },
  }
  if (
    pathname.split('?')[0] === PagePathConstant.DASHBOARD &&
    paths[PagePathConstant.DASHBOARD]
  ) {
    return paths[PagePathConstant.DASHBOARD]?.()
  }

  if (paths[path]) {
    return paths[path]?.()
  }

  if (pathname && paths[pathname]) {
    return paths[pathname]?.()
  }

  return decodeQueryId.length === 0 ? titleFromPath : decodeQueryId
}

export const startedPaths = [
  PagePathConstant.DASHBOARD,
  PagePathConstant.SOURCES,
  PagePathConstant.ONBOARDING,
  PagePathConstant.POLICIES,
  PagePathConstant.JOBS,
  PagePathConstant.REPORTS,
  PagePathConstant.SETTINGS,
  PagePathConstant.DEPLOYMENT,
  PagePathConstant.SYSTEM,
  PagePathConstant.ACCOUNT_AND_SETTINGS,
]
