/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  CellWithDateTimeTooltip,
  createColumn,
  createDisplayColumn,
} from '@components/table'
import { AssetNameCell } from './cells'
import { Asset, EBS, S3Bucket } from 'blues-corejs/dist'
import { LongTextTooltip } from '@components/components-simple'

export interface DetectedAnomalyTable {
  path: string
  assetId: string
  detectedAt: Date
  numberOfFiles: number
}

const columnsSize = {
  date: 100,
  numberOfFindings: 100,
}

function getAssetFromId(assetId: string, assetList: Array<Asset>) {
  return assetList.find(({ id }) => id === assetId)
}

function getAttachedInstances(
  asset: Asset | undefined,
  assetList: Array<Asset>
) {
  if (asset instanceof EBS) {
    const attachedInstancesIds = asset.attachedInstanceIds
    return attachedInstancesIds.map((id) => {
      return getAssetFromId(id, assetList)
    })
  }

  return []
}

export const getColumns = (assetsList: Array<Asset>, assetInstance: Asset) => {
  const columns = [
    createColumn<DetectedAnomalyTable>('path', {
      header: 'Directory',
      enableSorting: false,
      meta: {
        whiteSpace: 'pre-line',
        workBreak: 'break-word',
      },
      cell: ({ getValue }) => (
        <LongTextTooltip
          sx={{
            wordBreak: 'break-all',
            whiteSpace: 'normal',
          }}
          text={getValue()}
        />
      ),
    }),
    createColumn<DetectedAnomalyTable>('assetId', {
      header: 'Volume name',
      enableSorting: false,
      id: 'assetName',
      cell: ({ getValue }) => {
        const assetId = getValue() as string
        const asset = getAssetFromId(assetId, assetsList)

        if (!asset) {
          return null
        }

        return <AssetNameCell asset={asset} />
      },
    }),
    createDisplayColumn<DetectedAnomalyTable>({
      id: 'attachedInstance',
      header: 'Attached Instances',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const { assetId } = original
        const asset = getAssetFromId(assetId, assetsList)
        const attachedInstances = getAttachedInstances(asset, assetsList)
        return attachedInstances.map((instance) => {
          if (!instance) {
            return null
          }

          return <AssetNameCell asset={instance} />
        })
      },
    }),
    createColumn<DetectedAnomalyTable>('detectedAt', {
      header: 'Detected',
      enableSorting: false,
      size: columnsSize.date,
      cell: (row) => (
        <CellWithDateTimeTooltip date={row.getValue() as unknown as Date} />
      ),
    }),
    createColumn<DetectedAnomalyTable>('numberOfFiles', {
      header: 'High entropy files',
      size: columnsSize.numberOfFindings,
      meta: {
        textAlign: 'right',
      },
      enableSorting: false,
    }),
  ]

  return columns.filter((col) => {
    if (assetInstance instanceof S3Bucket) {
      if (col.id && col.id === 'assetName') {
        col.header = 'Bucket name'
        return col
      }
      if (col.id && col.id === 'attachedInstance') {
        return false
      }
    }
    return col
  })
}
