/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { useInfiniteScrollDataFetch } from '@components/table/use-infinite-scroll-data-fetch'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllRedStacksList } from '@store/selectors/rex.selector'
import { NO_JOBS_MESSAGE } from '@features/jobs/tenant-jobs/deployment-jobs/consts'
import { Box } from '@mui/material'
import { Table } from '@components/table'
import { getColumnsDefinitions } from './get-cloud-connector-jobs-columns-definitions'
import DateRangeSelect from '@components-composite/date-range-select/DateRangeSelect'
import { DateRangeTuple, DefinedDateRangeTuple } from '@lib/engine-types'
import { FilterModalButton } from './shared'
import { FiltersModal } from './filters-modal'
import { SelectedFiltersChipsBlock } from './selected-filters-chips-block'
import { useTrackChangedJobs } from './use-track-changed-jobs'
import {
  useAssetMap,
  useAssetStoreActions,
  useCloudConnectorJobsActions,
  useCloudConnectorJobsFiltersActions,
  useCloudConnectorJobsIsLoading,
  useCloudConnectorJobsList,
  useCloudConnectorJobsTimerange,
  useFiltersSubscription,
  useSelectedCloudConnectorJobFilters,
  useShouldDisableFilterButton,
} from './stores'
import styles from './cloud-connector-jobs.module.css'
import { useModalFilterActions } from './filters-modal/cloud-connector-filters-store'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import AllWorkflowsLink from '@components-simple/all-workflows-link/AllWorkflowsLink'
import PagePathConstant from '@lib/constants/page-path.constant'

function Filters() {
  const timeRangeState = useCloudConnectorJobsTimerange()
  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()
  const timeRangeWithTimeZone: DateRangeTuple = [
    moment(timeRangeState[0]).tz(timeZone.value),
    moment(timeRangeState[1]).tz(timeZone.value),
  ]
  const minMaxRange: DateRangeTuple = [
    moment().tz(timeZone.value).subtract(1, 'month').startOf('month'),
    moment().endOf('day').tz(timeZone.value),
  ]
  const shouldDisableFilterButton = useShouldDisableFilterButton()

  const { setTimeRange } = useCloudConnectorJobsFiltersActions()

  function handleTimeRangeChange(timeRange: DateRangeTuple) {
    const convertTimeRange = [timeRange[0]?.valueOf(), timeRange[1]?.valueOf()]
    setTimeRange(convertTimeRange as DefinedDateRangeTuple)
  }

  const { openFilterDialog } = useModalFilterActions()

  const selectedFilters = useSelectedCloudConnectorJobFilters()

  function handleFilterButtonClick() {
    openFilterDialog(selectedFilters)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      marginBottom="25px"
    >
      <FilterModalButton
        disabled={shouldDisableFilterButton}
        onButtonClick={handleFilterButtonClick}
      />
      <DateRangeSelect
        value={timeRangeWithTimeZone}
        onChange={handleTimeRangeChange}
        minMaxRange={minMaxRange}
      />
    </Box>
  )
}

export function CloudConnectorJobs() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const isPrevOnboarding = urlParams.get('onboarding') !== null
  const allRedStacksList = useSelector(getAllRedStacksList)

  const dispatch = useDispatch()

  const {
    fetchPossibleCloudConnectorJobFilters,
    resetCloudConnectorJobsFilters,
  } = useCloudConnectorJobsFiltersActions()

  const {
    fetchInitialCloudConnectorJobs,
    fetchPaginatedCloudConnectorJobs,
    resetCloudConnectorJobsState,
  } = useCloudConnectorJobsActions()

  const { resetAssetStore } = useAssetStoreActions()

  const cloudConnectorJobsList = useCloudConnectorJobsList()

  const isCloudConnectorJobsLoading = useCloudConnectorJobsIsLoading()
  const assetMap = useAssetMap()

  useEffect(() => {
    dispatch(getAllRedStacks())
    fetchInitialCloudConnectorJobs()
    fetchPossibleCloudConnectorJobFilters()

    return () => {
      resetCloudConnectorJobsFilters()
      resetAssetStore()
      resetCloudConnectorJobsState()
    }
  }, [])

  const observableState = useInfiniteScrollDataFetch({
    initialItems: [],
    fetchNextBatch: fetchPaginatedCloudConnectorJobs,
  })

  useTrackChangedJobs()

  useFiltersSubscription()

  return (
    <Box>
      {isPrevOnboarding && (
        <AllWorkflowsLink backUrl={PagePathConstant.ONBOARDING} />
      )}
      <div className="innerContent">
        <FiltersModal />
        <Filters />
        <SelectedFiltersChipsBlock />
        <Table
          className={styles['cloud-connectors-jobs-table']}
          data={cloudConnectorJobsList}
          // @ts-ignore Please help me fix that!
          columns={getColumnsDefinitions({
            allRedStacksList,
            assetMap,
          })}
          generalProps={{
            isLoading: isCloudConnectorJobsLoading,
            noDataMessage: NO_JOBS_MESSAGE,
          }}
          advancedProps={{
            observableState,
            expandableRowKey: 'childrenList',
          }}
        />
      </div>
    </Box>
  )
}
