import React, { memo, ReactNode, useState } from 'react'
import IconPlus from '@inline-img/inputs/checkbox-plus-icon'
import IconMinus from '@inline-img/inputs/checkbox-minus-icon'
import clsx from 'clsx'

interface Props {
  labels: Array<string>
  children: ReactNode
  loading?: boolean
}

function AccordionBlock({ labels, children, loading = false }: Props) {
  const [expanded, setExpanded] = useState<Array<boolean>>(
    labels.map(() => false)
  )

  const onSetExpanded = (index: number, value: boolean) => () => {
    const newValue = [...expanded]
    newValue[index] = value
    setExpanded(newValue)
  }

  return (
    <div className="wrap-1642152159934">
      {React.Children.map(children, (child, index) => (
        <div
          data-testid="blockRow"
          className={clsx('blockRow', { blockExpanded: expanded[index] })}
        >
          <div className="accordionTopRow">
            <div>
              <button
                className="accordionShrinkBtn"
                type="button"
                disabled={loading}
                onClick={onSetExpanded(index, false)}
              >
                <IconMinus />
              </button>
              <button
                className="accordionExpandBtn"
                data-testid="accordionExpandBtn"
                type="button"
                disabled={loading}
                onClick={onSetExpanded(index, true)}
              >
                <IconPlus />
              </button>
            </div>

            <div data-testid="accordionLabel" className="accordionLabel">
              {labels[index]}
            </div>
          </div>

          <div data-testid="accordionContent" className="accordionContent">
            {child}
          </div>
        </div>
      ))}
    </div>
  )
}

export default memo(AccordionBlock)
