export default function OvaServerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="95"
      height="94"
      viewBox="0 0 95 94"
      fill="none"
    >
      <g filter="url(#filter0_d_19625_169470)">
        <circle
          cx="47.5"
          cy="36"
          r="30"
          fill="url(#paint0_linear_19625_169470)"
        />
        <g clipPath="url(#clip0_19625_169470)">
          <path
            d="M59.5 31.7344C59.5 31.4537 59.4456 31.1809 59.3383 30.9234C59.3382 30.9232 59.3381 30.923 59.338 30.9228L56.994 25.298C56.6654 24.5095 55.9011 24 55.0469 24H39.9531C39.0989 24 38.3346 24.5095 38.006 25.298L35.6617 30.9233C35.5544 31.1809 35.5 31.4537 35.5 31.7344V34.6406C35.5 35.1805 35.7041 35.6734 36.0388 36.0469C35.7041 36.4204 35.5 36.9133 35.5 37.4531V40.2656C35.5 40.8055 35.7041 41.2984 36.0388 41.6719C35.7041 42.0454 35.5 42.5383 35.5 43.0781V45.8906C35.5 47.0537 36.4463 48 37.6094 48H57.3906C58.5537 48 59.5 47.0537 59.5 45.8906V43.0781C59.5 42.5383 59.2959 42.0454 58.9612 41.6719C59.296 41.2984 59.5 40.8055 59.5 40.2656V37.4531C59.5 36.9133 59.2959 36.4204 58.9612 36.0469C59.296 35.6734 59.5 35.1805 59.5 34.6406V31.7344ZM39.304 25.8389C39.4136 25.5761 39.6684 25.4062 39.9531 25.4062H55.0469C55.3316 25.4062 55.5864 25.5761 55.696 25.8389L57.2738 29.625H37.7262L39.304 25.8389ZM58.0938 45.8906C58.0938 46.2783 57.7783 46.5938 57.3906 46.5938H37.6094C37.2217 46.5938 36.9062 46.2783 36.9062 45.8906V43.0781C36.9062 42.6904 37.2217 42.375 37.6094 42.375H57.3906C57.7783 42.375 58.0938 42.6904 58.0938 43.0781V45.8906ZM58.0938 40.2656C58.0938 40.6533 57.7783 40.9688 57.3906 40.9688H37.6094C37.2217 40.9688 36.9062 40.6533 36.9062 40.2656V37.4531C36.9062 37.0654 37.2217 36.75 37.6094 36.75H57.3906C57.7783 36.75 58.0938 37.0654 58.0938 37.4531V40.2656ZM58.0938 34.6406C58.0938 35.0283 57.7783 35.3438 57.3906 35.3438H37.6094C37.2217 35.3438 36.9062 35.0283 36.9062 34.6406V31.7344C36.9062 31.3433 37.222 31.0312 37.6094 31.0312H57.3906C57.7792 31.0312 58.0938 31.3446 58.0938 31.7344V34.6406Z"
            fill="white"
          />
          <path
            d="M55.9844 43.7812H43.2344C42.8461 43.7812 42.5312 44.0961 42.5312 44.4844C42.5312 44.8727 42.8461 45.1875 43.2344 45.1875H55.9844C56.3727 45.1875 56.6875 44.8727 56.6875 44.4844C56.6875 44.0961 56.3727 43.7812 55.9844 43.7812Z"
            fill="white"
          />
          <path
            d="M55.9844 32.4375H43.2344C42.8461 32.4375 42.5312 32.7523 42.5312 33.1406C42.5312 33.5289 42.8461 33.8438 43.2344 33.8438H55.9844C56.3727 33.8438 56.6875 33.5289 56.6875 33.1406C56.6875 32.7523 56.3727 32.4375 55.9844 32.4375Z"
            fill="white"
          />
          <path
            d="M39.0156 32.4375C38.6273 32.4375 38.3125 32.7523 38.3125 33.1406C38.3125 33.5289 38.6273 33.9375 39.0156 33.9375C39.4039 33.9375 39.7188 33.5289 39.7188 33.1406C39.7188 32.7523 39.4039 32.4375 39.0156 32.4375Z"
            fill="white"
          />
          <path
            d="M55.9844 38.1562H43.2344C42.8461 38.1562 42.5312 38.4711 42.5312 38.8594C42.5312 39.2477 42.8461 39.5625 43.2344 39.5625H55.9844C56.3727 39.5625 56.6875 39.2477 56.6875 38.8594C56.6875 38.4711 56.3727 38.1562 55.9844 38.1562Z"
            fill="white"
          />
          <path
            d="M39.0156 39.5625C39.404 39.5625 39.7188 39.2477 39.7188 38.8594C39.7188 38.471 39.404 38.1562 39.0156 38.1562C38.6273 38.1562 38.3125 38.471 38.3125 38.8594C38.3125 39.2477 38.6273 39.5625 39.0156 39.5625Z"
            fill="white"
          />
          <path
            d="M39.0156 45.1875C39.404 45.1875 39.7188 44.8727 39.7188 44.4844C39.7188 44.096 39.404 43.7812 39.0156 43.7812C38.6273 43.7812 38.3125 44.096 38.3125 44.4844C38.3125 44.8727 38.6273 45.1875 39.0156 45.1875Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_19625_169470"
          x="0.5"
          y="0"
          width="94"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="8.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_19625_169470"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_19625_169470"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_19625_169470"
          x1="17.5"
          y1="6006"
          x2="6017.5"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C8511B" />
          <stop offset="1" stopColor="#FF9900" />
        </linearGradient>
        <clipPath id="clip0_19625_169470">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(35.5 24)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
