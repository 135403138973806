/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'
import { ObjHelper } from '@lib/helpers'
import { Threat } from 'blues-corejs/dist'
import { useEffect, useRef, useState } from 'react'
import { ListThreatsForAssetClient } from '@lib/clients/threats/list-threats-for-asset'

const threats = new ListThreatsForAssetClient()

type ScansWithThreats = {
  scans: ScansRun
  threats: Array<Threat>
}

// Helper function to get threats for a single scan
async function getThreatsForScan(scan: ScansRun): Promise<ScansWithThreats> {
  const threatIdsFromScan = [
    ...scan.filesystemChecksList,
    ...scan.malwareScansList,
    ...scan.ransomwareScansList,
  ].reduce((acc: Array<string>, scanRun) => {
    return [...acc, ...scanRun.threatIds]
  }, [])

  if (threatIdsFromScan.length === 0) {
    return {
      scans: scan,
      threats: [],
    }
  }

  const { threatsList } = await threats.listThreats(null, threatIdsFromScan)

  return {
    scans: scan,
    threats: threatsList,
  }
}

export function useProcessDataForTable(scans: Array<ScansRun>) {
  const [tableData, setTableData] = useState<Array<ScansWithThreats>>([])

  const [isLoading, setIsLoading] = useState(false)

  const previousScans = useRef<Array<ScansRun> | null>(null)

  useEffect(() => {
    async function getThreatsForScans() {
      try {
        setIsLoading(true)
        const data = await Promise.all(scans.map(getThreatsForScan))
        setTableData(data)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    }

    if (ObjHelper.isEqual(previousScans.current, scans)) {
      return
    }

    getThreatsForScans()

    previousScans.current = scans
  }, [scans.length])

  return {
    data: tableData,
    isLoading,
  }
}
