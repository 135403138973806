import { PolicyStatus } from '@lib/constants/policies.constant'
import { Nullable, VIRow } from '@lib/engine-types'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import RetentionPolicyModel from '@lib/models/retention-policy.model'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

export const REQUEST_EDIT_POLICY_RETENTION = 'REQUEST_EDIT_POLICY_RETENTION'
export const REQUEST_EDIT_POLICY_RETENTION_DEFAULT =
  'REQUEST_EDIT_POLICY_RETENTION_DEFAULT'
export const SET_EDIT_POLICY_RETENTION_NAME = 'SET_EDIT_POLICY_RETENTION_NAME'
export const SET_EDIT_POLICY_RETENTION = 'SET_EDIT_POLICY_RETENTION'
export const SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS =
  'SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS'
export const SET_EDIT_POLICY_RETENTION_REGIONS =
  'SET_EDIT_POLICY_RETENTION_REGIONS'
export const SET_EDIT_POLICY_RETENTION_BACKUP_TYPES =
  'SET_EDIT_POLICY_RETENTION_BACKUP_TYPES'
export const SET_EDIT_POLICY_RETENTION_ASSET_SOURCES =
  'SET_EDIT_POLICY_RETENTION_ASSET_SOURCES'
export const SET_EDIT_POLICY_RETENTION_TAGS = 'SET_EDIT_POLICY_RETENTION_TAGS'
export const SET_EDIT_POLICY_RETENTION_VAULTS =
  'SET_EDIT_POLICY_RETENTION_VAULTS'

export const SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS_INITIAL =
  'SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS_INITIAL'
export const SET_EDIT_POLICY_RETENTION_REGIONS_INITIAL =
  'SET_EDIT_POLICY_RETENTION_REGIONS_INITIAL'
export const SET_EDIT_POLICY_RETENTION_BACKUP_TYPES_INITIAL =
  'SET_EDIT_POLICY_RETENTION_BACKUP_TYPES_INITIAL'
export const SET_EDIT_POLICY_RETENTION_ASSET_SOURCES_INITIAL =
  'SET_EDIT_POLICY_RETENTION_ASSET_SOURCES_INITIAL'
export const SET_EDIT_POLICY_RETENTION_TAGS_INITIAL =
  'SET_EDIT_POLICY_RETENTION_TAGS_INITIAL'
export const SET_EDIT_POLICY_RETENTION_VAULTS_INITIAL =
  'SET_EDIT_POLICY_RETENTION_VAULTS_INITIAL'

export const UPDATE_EDIT_POLICY_RETENTION = 'UPDATE_EDIT_POLICY_RETENTION'
export const UPDATE_EDIT_POLICY_RETENTION_DEFAULT =
  'UPDATE_EDIT_POLICY_RETENTION_DEFAULT'
export const SET_EDIT_POLICY_RETENTION_STATUS =
  'SET_EDIT_POLICY_RETENTION_STATUS'
export const SET_EDIT_POLICY_RECOVERY_PERIOD = 'SET_EDIT_POLICY_RECOVERY_PERIOD'
export const SET_EDIT_POLICY_RECOVERY_PERIOD_INITIAL =
  'SET_EDIT_POLICY_RECOVERY_PERIOD_INITIAL'
export const SET_EDIT_POLICY_CURRENT_ASSETS_SELECTED =
  'SET_EDIT_POLICY_CURRENT_ASSETS_SELECTED'
export const SET_EDIT_POLICY_SELECTED_ASSETS_INITIAL =
  'SET_EDIT_POLICY_SELECTED_ASSETS_INITIAL'
export const SET_EDIT_POLICY_MISSING_ASSETS_SELECTED =
  'SET_EDIT_POLICY_MISSING_ASSETS_SELECTED'
export const SET_EDIT_POLICY_MISSING_ASSETS_SELECTED_INITIAL =
  'SET_EDIT_POLICY_MISSING_ASSETS_SELECTED_INITIAL'
export const SET_EDIT_POLICY_RETENTION_SELECTED_FILTERS =
  'SET_EDIT_POLICY_RETENTION_SELECTED_FILTERS'
export const SET_EDIT_POLICY_RETENTION_DEFAULT_UPDATED =
  'SET_EDIT_POLICY_RETENTION_DEFAULT_UPDATED'

export const requestEditPolicyRetention = (policyName: string) => ({
  type: REQUEST_EDIT_POLICY_RETENTION,
  payload: policyName,
})

export const requestEditPolicyRetentionDefault = (policyName: string) => ({
  type: REQUEST_EDIT_POLICY_RETENTION_DEFAULT,
  payload: policyName,
})

export const setEditPolicyRetentionName = (policyName: string) => ({
  type: SET_EDIT_POLICY_RETENTION_NAME,
  payload: policyName,
})

export const setEditPolicyRetentionStatus = (policyStatus: PolicyStatus) => ({
  type: SET_EDIT_POLICY_RETENTION_STATUS,
  payload: policyStatus,
})

export const setEditPolicyRetentionAccountIDs = (accounts: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS,
  payload: accounts,
})

export const setEditPolicyRetentionRegions = (regions: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_REGIONS,
  payload: regions,
})

export const setEditPolicyRetentionBackupTypes = (backup: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_BACKUP_TYPES,
  payload: backup,
})

export const setEditPolicyRetentionAssetSources = (source: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_ASSET_SOURCES,
  payload: source,
})

export const setEditPolicyRetentionTags = (tags: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_TAGS,
  payload: tags,
})

export const setEditPolicyRetentionVaults = (vaults: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_VAULTS,
  payload: vaults,
})

export const setEditPolicyRetentionAccountIDsInitial = (accounts: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_ACCOUNT_IDS_INITIAL,
  payload: accounts,
})

export const setEditPolicyRetentionRegionsInitial = (regions: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_REGIONS_INITIAL,
  payload: regions,
})

export const setEditPolicyRetentionBackupTypesInitial = (backup: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_BACKUP_TYPES_INITIAL,
  payload: backup,
})

export const setEditPolicyRetentionAssetSourcesInitial = (source: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_ASSET_SOURCES_INITIAL,
  payload: source,
})

export const setEditPolicyRetentionTagsInitial = (tags: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_TAGS_INITIAL,
  payload: tags,
})

export const setEditPolicyRetentionVaultsInitial = (vaults: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_VAULTS_INITIAL,
  payload: vaults,
})

export const setEditPolicyRecoveryPeriod = (
  recoveryPeriod: FormPeriodInterface
) => ({
  type: SET_EDIT_POLICY_RECOVERY_PERIOD,
  payload: recoveryPeriod,
})

export const setEditPolicyRecoveryPeriodInitial = (
  recoveryPeriod: FormPeriodInterface
) => ({
  type: SET_EDIT_POLICY_RECOVERY_PERIOD_INITIAL,
  payload: recoveryPeriod,
})

export const setEditPolicyCurrentAssetsSelected = (assets: VIRow) => ({
  type: SET_EDIT_POLICY_CURRENT_ASSETS_SELECTED,
  payload: assets,
})

export const setEditPolicyMissingAssetsSelected = (assets: VIRow) => ({
  type: SET_EDIT_POLICY_MISSING_ASSETS_SELECTED,
  payload: assets,
})

export const setEditPolicySelectedAssetsInitial = (
  assets: Array<AssetWithRelatedAssets<Asset>>
) => ({
  type: SET_EDIT_POLICY_SELECTED_ASSETS_INITIAL,
  payload: assets,
})

export const setEditPolicyMissingAssetsSelectedInitial = (assets: VIRow) => ({
  type: SET_EDIT_POLICY_MISSING_ASSETS_SELECTED_INITIAL,
  payload: assets,
})

export const setEditPolicyRetentionSelectedFilters = (filters: VIRow) => ({
  type: SET_EDIT_POLICY_RETENTION_SELECTED_FILTERS,
  payload: filters,
})

export const setEditPolicyRetention = (
  policy: Nullable<RetentionPolicyModel>
) => ({
  type: SET_EDIT_POLICY_RETENTION,
  payload: policy,
})

export const setEditPolicyRetentionDefaultUpdated = (updated: boolean) => ({
  type: SET_EDIT_POLICY_RETENTION_DEFAULT_UPDATED,
  payload: updated,
})

export const updateEditPolicyRetention = (policy: string) => ({
  type: UPDATE_EDIT_POLICY_RETENTION,
  payload: policy,
})

export const updateEditPolicyRetentionDefault = () => ({
  type: UPDATE_EDIT_POLICY_RETENTION_DEFAULT,
  payload: null,
})
