/* eslint-disable import/no-extraneous-dependencies */
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { JobCloudConnector } from 'blues-corejs/dist'
import { CloudConnectorJobStatus } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import clsx from 'clsx'
import React from 'react'

const MAX_TEXT_LENGTH = 100

export function getCloudConnectorJobStatusMessage({
  job,
}: {
  job: JobCloudConnector
}): JSX.Element {
  const mapping = {
    [TenantJobStatus.SCHEDULED]: 'Scheduled',
    [TenantJobStatus.STARTING]: 'Starting',
    [TenantJobStatus.INPROGRESS]: 'In Progress',
    [TenantJobStatus.FAILED]: job.statusMessage,
    [TenantJobStatus.SUCCEEDED]: 'Completed successfully',
    [TenantJobStatus.ABORTED]: 'Aborted',
  }

  return (
    <LongTextTooltip
      text={mapping[job.status]}
      maxLength={MAX_TEXT_LENGTH}
      customClass={clsx('system-jobs-no-wrap-cell', {
        'job-failed-status-message':
          job.status === CloudConnectorJobStatus.FAILED,
      })}
      customPopperClass="tooltipHeight400"
    />
  )
}
