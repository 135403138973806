/* eslint-disable import/no-extraneous-dependencies */
import AliasDescriptionBlock from '@components-composite/alias-description-block/AliasDescriptionBlock'
import InstallAndRemoveSourcesModal, {
  InstallRemoveSourceInterface,
} from '@components-composite/modals/InstallAndRemoveSourcesModal'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import Modal from '@lib/constants/modal.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import RedStackStatusConstant from '@lib/constants/red-stack-status.constant'
import ArrHelper from '@lib/helpers/arr.helper'
import StrHelper from '@lib/helpers/str.helper'
import { useModal } from '@lib/hooks/useModal'
import usePreloader from '@lib/hooks/usePreloader'
import SourceIdOnChangeInterface from '@lib/interfaces/source-id-on-change.interface'
import RedStackModel from '@lib/models/red-stack.model'
import { Button } from '@mui/material'
import { getAllRedStacks } from '@store/actions/rex.action'
import {
  cleanSourceIdForm,
  initSourceId,
  markRegionsForInstallation,
  markRegionsForRemoved,
  setAccountAliasName,
  setAccountDescription,
  submitSourceId,
} from '@store/actions/source-id.action'
import { getAllRedStacksList } from '@store/selectors/rex.selector'
import {
  getAccountAliasName,
  getAccountDescription,
  getDataForInstallation,
  getRsIdForRemoved,
  getSourceIdTableData,
  sourceIdCanSubmit,
  sourceIdHasSelectedPrivateSubnets,
} from '@store/selectors/source-id.selector'
import { SOURCE_ID_HEADERS } from '@tables/core/table-constants'
import { SOURCE_ID_MAPPER } from '@tables/core/table-vi-draw-mappers'
import SimpleVITable from '@tables/SimpleVITable'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLayoutContext } from '@features/contexts'
import { useParams } from 'react-router-dom'
import { SourcesRoutes, useNavigation } from '@lib/router'

function SourceId() {
  const router = useNavigation()
  const { id: encodeAccountId } = useParams()
  const { setHeaderTitle } = useLayoutContext()
  const accountId = StrHelper.base64Decode(encodeAccountId)

  const dispatch = useDispatch()
  const isInitLoading = usePreloader(PreloaderConstants.INIT_SOURCE_ID)
  const isSubmitLoading = usePreloader(PreloaderConstants.SUBMIT_SOURCE_ID)

  const sourceIdTableData = useSelector(getSourceIdTableData)
  const accountAliasName = useSelector(getAccountAliasName)
  const accountDescription = useSelector(getAccountDescription)
  const isSubmitActive = useSelector(sourceIdCanSubmit)
  const rsIdsForRemoved = useSelector(getRsIdForRemoved)
  const dataForInstallation = useSelector(getDataForInstallation)
  const allRedStacksList = useSelector(getAllRedStacksList)
  const hasSelectedPrivateSubnets = useSelector(
    sourceIdHasSelectedPrivateSubnets
  )

  useEffect(() => {
    if (accountId) {
      dispatch(cleanSourceIdForm())
      dispatch(initSourceId(accountId))
      dispatch(getAllRedStacks())
      setHeaderTitle(`Editing source "${accountId}"`)
    }
    return () => {
      setHeaderTitle(null)
    }
  }, [accountId])

  const {
    openModal: openInstallAndRemoveSourcesModal,
    modalProps: installAndRemoveSourcesProps,
  } = useModal<InstallRemoveSourceInterface>(
    Modal.installAndRemoveSources,
    () => {
      dispatch(submitSourceId())
    }
  )

  const onSubmit = () => {
    const accountRedStack = allRedStacksList.filter(
      (r) =>
        r.awsAccount === accountId &&
        r.status !== RedStackStatusConstant.DELETED
    )
    const redStacksArrForDeletion: Array<RedStackModel> = ArrHelper.flatDeep(
      rsIdsForRemoved.map((rsIdForRemoved) =>
        accountRedStack.filter((r) => r.redStackId === rsIdForRemoved)
      )
    )

    openInstallAndRemoveSourcesModal({
      redStacksArrForDeletion,
      dataForInstallation,
    })
  }

  const onRedStackInRegionChange = ({
    redStackId,
    checkboxValue,
    selectedOption,
    label,
    region,
    defaultValueCheckbox,
    selectedSubOptions,
  }: SourceIdOnChangeInterface) => {
    if (checkboxValue) {
      dispatch(
        markRegionsForInstallation(
          redStackId,
          accountId,
          checkboxValue,
          selectedOption,
          region,
          label,
          defaultValueCheckbox,
          allRedStacksList.find(
            (redStack) =>
              redStack.awsAccount === accountId &&
              redStack.redStackId === redStackId
          )?.status ?? RedStackStatusConstant.UNDEFINED,
          selectedSubOptions
        )
      )
    } else {
      dispatch(
        markRegionsForRemoved(
          redStackId,
          label,
          region,
          checkboxValue,
          defaultValueCheckbox,
          allRedStacksList.find(
            (redStack) =>
              redStack.awsAccount === accountId &&
              redStack.redStackId === redStackId
          )?.status ?? RedStackStatusConstant.UNDEFINED
        )
      )
    }
  }

  const outerDispatch = (action: any) => (value: any) => {
    dispatch(action(value))
  }

  return (
    <>
      <div className="innerContent wrap-1627019413056 jsSourceIdComplex">
        <div className="mainContent">
          <AliasDescriptionBlock
            disabled={isInitLoading || isSubmitLoading}
            aliasName={accountAliasName}
            description={accountDescription}
            onAliasNameChange={outerDispatch(setAccountAliasName)}
            onDescriptionChange={outerDispatch(setAccountDescription)}
          />
          {isInitLoading ? (
            <PreloaderBlock show />
          ) : (
            <>
              {hasSelectedPrivateSubnets && (
                <div className="warningBlock">
                  The Cloud Connector will be deployed into a private subnet and
                  will need manual configuration. Please see documentation for
                  more details. One of our cloud team members will reach out
                  with assistance.
                </div>
              )}
              <SimpleVITable
                className={clsx('width300TableColumn1', {
                  controlsDisabled: isSubmitLoading,
                })}
                head={SOURCE_ID_HEADERS}
                rows={sourceIdTableData}
                columnDrawMapper={SOURCE_ID_MAPPER(onRedStackInRegionChange)}
              />
            </>
          )}
        </div>
        <BasePortalButtons alwaysInPortal>
          <div className="portalBetweenButtons">
            <Button
              className="showButton jsSourceIdCancel"
              type="button"
              variant="text"
              color="primary"
              onClick={() => router.push(SourcesRoutes.root)}
            >
              Cancel
            </Button>
            <Button
              id="jsSourceIdSubmit"
              className="jsSourceIdSubmit"
              disabled={!isSubmitActive || isInitLoading || isSubmitLoading}
              type="button"
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              Save Changes
            </Button>
          </div>
        </BasePortalButtons>

        <InstallAndRemoveSourcesModal {...installAndRemoveSourcesProps} />
      </div>
    </>
  )
}

export default SourceId
