import { useState, useCallback } from 'react'

type ListActions<T> = {
  set: (newList: Array<T> | ((currentList: Array<T>) => Array<T>)) => void
  clear: () => void
  reset: () => void
}

function useList<T>(initialList: Array<T> = []): [Array<T>, ListActions<T>] {
  const [list, setList] = useState<Array<T>>(initialList)

  const actions: ListActions<T> = {
    /**
     * Sets the list to a new list.
     * Can take a direct new list or a function that receives the current list and returns the new list.
     *
     * @param {T[] | ((currentList: T[]) => T[])} newList - The new list or a function to produce it.
     */
    set: useCallback((newList) => {
      setList((currList) =>
        typeof newList === 'function' ? newList(currList) : newList
      )
    }, []),

    /**
     * Clears the list, setting it to an empty array.
     */
    clear: useCallback(() => {
      setList([])
    }, []),

    /**
     * Resets the list to its IS.
     */
    reset: useCallback(() => {
      setList(initialList)
    }, [initialList]),
  }

  return [list, actions]
}

export { useList }
