import React from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { WidgetHeader } from '@features/DashboardV4/common'
import {
  AssetSelectionFilter,
  CloudConnectorAccountSelectionFilter,
  CloudConnectorRegionSelectionFilter,
} from './composable'
import { useFilterSubscription } from './entropy-trends-filters-store'
import {
  FILTER_NAMES,
  FilterName,
} from '@features/entropy-trends/components/entropy-trends-filters/composable/consts'

export function EntropyTrendsFilters() {
  const [isFilterOpen, setIsFilterOpen] = React.useState<
    Record<(typeof FILTER_NAMES)[keyof typeof FILTER_NAMES], boolean>
  >({
    Asset: false,
    'AWS Account': false,
    'AWS Region': false,
  })

  const handleFilterClick = (filterName: FilterName) => {
    setIsFilterOpen((prevState) => {
      const newState = Object.keys(prevState).reduce(
        (acc, key) => {
          acc[key as FilterName] =
            key === filterName ? !prevState[key as FilterName] : false
          return acc
        },
        {} as Record<FilterName, boolean>
      )
      return newState
    })
  }

  useFilterSubscription()

  return (
    <Paper
      component={Stack}
      padding="24px"
      gap="8px"
      height="100%"
      paddingBottom={3}
      overflow="auto"
    >
      <WidgetHeader title="Filters" />
      <Stack gap="8px" maxHeight="330px" height="100%">
        <AssetSelectionFilter
          isExpanded={isFilterOpen.Asset}
          onFilterClick={handleFilterClick}
        />
        <CloudConnectorAccountSelectionFilter
          isExpanded={isFilterOpen['AWS Account']}
          onFilterClick={handleFilterClick}
        />
        <CloudConnectorRegionSelectionFilter
          isExpanded={isFilterOpen['AWS Region']}
          onFilterClick={handleFilterClick}
        />
      </Stack>
    </Paper>
  )
}
