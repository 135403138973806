import { DayOfWeek } from '@lib/clients/grpc-imports'

const MONDAY = 'Monday'
const TUESDAY = 'Tuesday'
const WEDNESDAY = 'Wednesday'
const THURSDAY = 'Thursday'
const FRIDAY = 'Friday'
const SATURDAY = 'Saturday'
const SUNDAY = 'Sunday'

export function getDayNameAsString(day: number): string | undefined {
  const mapping: Record<number, string> = {
    [DayOfWeek.DAY_OF_WEEK_MONDAY]: MONDAY,
    [DayOfWeek.DAY_OF_WEEK_TUESDAY]: TUESDAY,
    [DayOfWeek.DAY_OF_WEEK_WEDNESDAY]: WEDNESDAY,
    [DayOfWeek.DAY_OF_WEEK_THURSDAY]: THURSDAY,
    [DayOfWeek.DAY_OF_WEEK_FRIDAY]: FRIDAY,
    [DayOfWeek.DAY_OF_WEEK_SATURDAY]: SATURDAY,
    [DayOfWeek.DAY_OF_WEEK_SUNDAY]: SUNDAY,
  }

  return mapping[day]
}

export function getDayNameAsNumber(day: string): number | undefined {
  const mapping: Record<string, number> = {
    [MONDAY]: DayOfWeek.DAY_OF_WEEK_MONDAY,
    [TUESDAY]: DayOfWeek.DAY_OF_WEEK_TUESDAY,
    [WEDNESDAY]: DayOfWeek.DAY_OF_WEEK_WEDNESDAY,
    [THURSDAY]: DayOfWeek.DAY_OF_WEEK_THURSDAY,
    [FRIDAY]: DayOfWeek.DAY_OF_WEEK_FRIDAY,
    [SATURDAY]: DayOfWeek.DAY_OF_WEEK_SATURDAY,
    [SUNDAY]: DayOfWeek.DAY_OF_WEEK_SUNDAY,
  }

  return mapping[day]
}

export function getWeekDayAsLetter(day: number): string | undefined {
  const mapping: Record<number, string> = {
    [DayOfWeek.DAY_OF_WEEK_MONDAY]: 'M',
    [DayOfWeek.DAY_OF_WEEK_TUESDAY]: 'T',
    [DayOfWeek.DAY_OF_WEEK_WEDNESDAY]: 'W',
    [DayOfWeek.DAY_OF_WEEK_THURSDAY]: 'T',
    [DayOfWeek.DAY_OF_WEEK_FRIDAY]: 'F',
    [DayOfWeek.DAY_OF_WEEK_SATURDAY]: 'S',
    [DayOfWeek.DAY_OF_WEEK_SUNDAY]: 'S',
  }

  return mapping[day]
}
