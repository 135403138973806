import React, { memo, useMemo } from 'react'
import { CoverageStats } from '@features/DashboardV4/widgets-with-scale/coverage-stats'
import { Box, Divider } from '@mui/material'
import { WidgetHeader } from '@features/DashboardV4/common'
import { ScaleStatic } from '@features/DashboardV4/widgets-with-scale/coverage-stats/scale-static'
import { ScansAndTests } from '@features/DashboardV4/ScansAndTests'
import { useScanCoverageStats } from '@features/DashboardV4/views/useScanCoverageStats'

function SecurityRightColumn() {
  const {
    scanCoverageStats,
    numberOfAssets,
    numberOfAssetsWithScans,
    percentOfAssetsWithScans,
    isLoading,
  } = useScanCoverageStats()

  const roundedPercentOfAssetsWithScans = useMemo(
    () => Math.round(percentOfAssetsWithScans),
    [percentOfAssetsWithScans]
  )

  return (
    <>
      <WidgetHeader title="Scan coverage" />
      <Box
        display="flex"
        alignItems="center"
        data-testid="security-scan-coverage"
      >
        <Box
          display="flex"
          flexBasis="80%"
          flexDirection="column"
          alignItems="center"
        >
          <CoverageStats
            statisticsRows={scanCoverageStats}
            isLoading={isLoading}
          />
        </Box>
        <ScaleStatic
          numberOfAssetsWithBackups={numberOfAssetsWithScans}
          numberOfAssets={numberOfAssets}
          percentOfAssetsWithBackups={roundedPercentOfAssetsWithScans}
        />
      </Box>
      <Divider
        sx={{
          my: '24px',
        }}
      />
      <ScansAndTests />
    </>
  )
}

export default memo(SecurityRightColumn)
