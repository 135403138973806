import { EngineCallback, VIMatrix, VIRow } from '@lib/engine-types'
import { useState } from 'react'
import ValueHelper from '@lib/helpers/value.helper'
import ValueInterface from '@lib/interfaces/value.interface'

const SEARCH_FIELDS = ['label', 'name', 'value']

interface UseLocalVIMatrixSearchResult {
  filteredData: VIMatrix
  search: string
  setSearch: EngineCallback<string>
}
function useLocalVIMatrixSearch(
  data: VIMatrix,
  baseSearch: string
): UseLocalVIMatrixSearchResult {
  const [search, setSearch] = useState<string>(baseSearch)
  let filteredData: VIMatrix = []

  if (!search) {
    filteredData = data
  } else {
    data.forEach((row: VIRow) => {
      let found = false
      row.forEach((elem: ValueInterface) => {
        if (!found) {
          found =
            ValueHelper.textSearch(
              elem,
              search,
              SEARCH_FIELDS,
              SEARCH_FIELDS
            ) !== null
        }
      })
      if (found) {
        filteredData.push(row)
      }
    })
  }

  return {
    filteredData,
    search,
    setSearch,
  }
}

export default useLocalVIMatrixSearch
