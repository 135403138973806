import React from 'react'

function WarningTriangleIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72494 14.5001H14.2749C15.5583 14.5001 16.3583 13.1084 15.7166 12.0001L9.4416 1.1584C8.79994 0.0500651 7.19994 0.0500651 6.55827 1.1584L0.283272 12.0001C-0.358395 13.1084 0.441605 14.5001 1.72494 14.5001V14.5001ZM7.99994 8.66673C7.54161 8.66673 7.16661 8.29173 7.16661 7.8334V6.16673C7.16661 5.7084 7.54161 5.3334 7.99994 5.3334C8.45827 5.3334 8.83327 5.7084 8.83327 6.16673V7.8334C8.83327 8.29173 8.45827 8.66673 7.99994 8.66673ZM8.83327 12.0001H7.16661V10.3334H8.83327V12.0001Z"
        fill="#FFD56D"
      />
    </svg>
  )
}

export default WarningTriangleIcon
