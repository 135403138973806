/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'
import {
  getFilesystemCheckStatus,
  getMalwareScanStatus,
  getRansomwareScanStatus,
} from '../helpers'
import { ThreatIconsBlock } from '@features/DashboardV4/common/threat-icon-group'

interface Props {
  scans: ScansRun
}

export function ThreatsCell({ scans }: Props) {
  const ransomwareScanStatus = getRansomwareScanStatus(
    scans.ransomwareScansList
  )

  const malwareScanStatus = getMalwareScanStatus(scans.malwareScansList)

  const fsCheckScanStatus = getFilesystemCheckStatus(scans.filesystemChecksList)

  const shouldShowMalwareAndRansomware = fsCheckScanStatus === null

  const shouldShowFsCheck =
    ransomwareScanStatus === null && malwareScanStatus === null

  if (shouldShowFsCheck) {
    return <ThreatIconsBlock fsCheckStatus={fsCheckScanStatus} />
  }

  if (shouldShowMalwareAndRansomware) {
    return (
      <ThreatIconsBlock
        ransomwareStatus={ransomwareScanStatus}
        malwareStatus={malwareScanStatus}
      />
    )
  }

  return (
    <ThreatIconsBlock
      ransomwareStatus={ransomwareScanStatus}
      malwareStatus={malwareScanStatus}
      fsCheckStatus={fsCheckScanStatus}
    />
  )
}
