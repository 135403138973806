import { Days, RepeatPeriod, ReportEnd } from './types'

export const repeatPeriodOptions = [
  {
    label: RepeatPeriod.DAY,
    value: RepeatPeriod.DAY,
  },
  {
    label: RepeatPeriod.WEEK,
    value: RepeatPeriod.WEEK,
  },
  {
    label: RepeatPeriod.MONTH,
    value: RepeatPeriod.MONTH,
  },
  // {
  //   label: RepeatPeriod.YEAR,
  //   value: RepeatPeriod.YEAR,
  // },
]

export const DaysOptions = [
  {
    label: 'S',
    value: Days.SUNDAY,
  },
  {
    label: 'M',
    value: Days.MONDAY,
  },
  {
    label: 'T',
    value: Days.TUESDAY,
  },
  {
    label: 'W',
    value: Days.WEDNESDAY,
  },
  {
    label: 'T',
    value: Days.THURSDAY,
  },
  {
    label: 'F',
    value: Days.FRIDAY,
  },
  {
    label: 'S',
    value: Days.SATURDAY,
  },
]

export const EndOptions = [
  {
    label: ReportEnd.NEVER,
    value: ReportEnd.NEVER,
  },
  {
    label: ReportEnd.ON,
    value: ReportEnd.ON,
  },
  {
    label: ReportEnd.AFTER,
    value: ReportEnd.AFTER,
  },
]

export const getMonthOptions = () => {
  const currentMonthDay = new Date().getDate()
  const monthlyDay = currentMonthDay > 25 ? 15 : currentMonthDay
  return [
    {
      value: 'Monthly on day 1',
      label: 'Monthly on day 1',
    },
    {
      value: 'Monthly on day ' + monthlyDay,
      label: 'Monthly on day ' + monthlyDay,
    },
  ]
}
