import { useSelector } from 'react-redux'
import { getLiveAssetsCriteria } from '@store/selectors/list-live-assets.selector'
import { useEffect, useState } from 'react'
import { SystemHelper } from '@lib/helpers'
import { KrabsAwsStatisticsClient } from '@lib/clients/krabs-aws-statistics'
import type { AwsScanStatisticsResponse } from '@lib/clients/krabs-aws-statistics/types'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'

const INITIAL_SCAN_STATISTICS_COUNT: AwsScanStatisticsResponse = {
  accountRegionStatisticsList: [],
}

const krabsAwsStatsClient = new KrabsAwsStatisticsClient()

export function useFetchAwsScanStatistics() {
  const { accountIds, regions } = useSelector(getLiveAssetsCriteria)

  const [isLoading, setIsLoading] = useState(false)
  const [scanStatisticsCount, setScanStatisticsCount] =
    useState<AwsScanStatisticsResponse>(INITIAL_SCAN_STATISTICS_COUNT)

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)

    const fetchFileScansLast30Days = async () => {
      try {
        const response = await krabsAwsStatsClient.getAwsScanStatistics({
          regions,
          accountIds,
        })

        if (isMounted) {
          setScanStatisticsCount(response)
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'KrabsAwsStatisticsClient.getAwsScanStatistics: failed to fetch file scans last 30 days count'
        )
      } finally {
        setIsLoading(false)
      }
    }

    const fetchInterval = setInterval(
      fetchFileScansLast30Days,
      INTERVAL_REQUEST_TIMEOUT
    )
    fetchFileScansLast30Days()

    return () => {
      clearInterval(fetchInterval)
      isMounted = false
      setScanStatisticsCount(INITIAL_SCAN_STATISTICS_COUNT)
      setIsLoading(false)
    }
  }, [accountIds, regions])

  return {
    isLoading,
    ...scanStatisticsCount,
  }
}
