import { RepeatPeriod } from '@features/reports/schedules/custom-schedule/types'
import {
  reportPeriodOptions,
  ReportPeriodValue,
} from '@features/reports/schedules/report/drawer/dynamic-form/schedule'
import { FrequencyType } from './frequency-type'

export const filterRepeatPeriodOptionsForCustom = (
  repeatPeriod: RepeatPeriod | null
) => {
  const optionsMap: { [key in RepeatPeriod]: Array<ReportPeriodValue> } = {
    [RepeatPeriod.DAY]: [
      ReportPeriodValue.CURRENT_DAY,
      ReportPeriodValue.LAST_DAY,
    ],
    [RepeatPeriod.WEEK]: [
      ReportPeriodValue.CURRENT_WEEK,
      ReportPeriodValue.LAST_WEEK,
    ],
    [RepeatPeriod.MONTH]: [
      ReportPeriodValue.CURRENT_MONTH,
      ReportPeriodValue.LAST_MONTH,
      ReportPeriodValue.CURRENT_QUARTER,
      ReportPeriodValue.LAST_QUARTER,
      ReportPeriodValue.CURRENT_YEAR,
      ReportPeriodValue.LAST_YEAR,
    ],
  }

  return repeatPeriod ? optionsMap[repeatPeriod] : []
}

export const filterReportPeriodOptions = (
  frequencyType: FrequencyType | null,
  repeatPeriodOption: RepeatPeriod | null = null
) => {
  const optionsMap: { [key in FrequencyType]: Array<ReportPeriodValue> } = {
    [FrequencyType.DAILY]: [
      ReportPeriodValue.CURRENT_DAY,
      ReportPeriodValue.LAST_DAY,
    ],
    [FrequencyType.WEEKLY]: [
      ReportPeriodValue.CURRENT_WEEK,
      ReportPeriodValue.LAST_WEEK,
    ],
    [FrequencyType.MONTHLY]: [
      ReportPeriodValue.CURRENT_MONTH,
      ReportPeriodValue.LAST_MONTH,
      ReportPeriodValue.CURRENT_QUARTER,
      ReportPeriodValue.LAST_QUARTER,
      ReportPeriodValue.CURRENT_YEAR,
      ReportPeriodValue.LAST_YEAR,
    ],
    [FrequencyType.CUSTOM]:
      filterRepeatPeriodOptionsForCustom(repeatPeriodOption),
  }

  const allowedValues = frequencyType ? optionsMap[frequencyType] : []

  return reportPeriodOptions.filter((option) =>
    allowedValues.includes(option.value)
  )
}
