import { useMemo } from 'react'
import { useAwsThreatExposureQuery } from '../queries/aws-stats'
import { NormalizedStatCardData } from 'ui-v2/src/lib/models/aws-stats'
import { STAT_CARD_SLUG } from 'ui-v2/src/lib/constants/stats.constant'
import { DASHBOARD_ROUTE_SEGMENTS } from 'ui-v2/src/lib/constants/route-segments.constant'

const useAwsThreatExposureStatsData = () => {
  const { data: awsThreatExposureStatsData, isLoading } =
    useAwsThreatExposureQuery({})

  const normalizedData: Array<NormalizedStatCardData> = useMemo(() => {
    if (!awsThreatExposureStatsData) {
      return []
    }

    return [
      {
        slug: STAT_CARD_SLUG.THREAT_EXPOSURE,
        count: awsThreatExposureStatsData.assetsWithThreatsCount,
        link: `/${DASHBOARD_ROUTE_SEGMENTS.ASSET_THREATS}?t=1`,
      },
    ]
  }, [awsThreatExposureStatsData])

  return {
    data: normalizedData,
    isLoading,
  }
}

export default useAwsThreatExposureStatsData
