import { useAwsThreatStatsQuery } from './threats'
import { useAwsThreatExposureQuery } from './threat-exposure'
import { useAwsFileScansQuery } from './file-scans'
import { useAwsScanCoverageQuery } from './scan-coverage'
import { useAwsBackupCoverageQuery } from './backup-coverage'
import { useRpoAndRetentionStatsQuery } from './rpo-and-retention-stats'
import { useAwsStorageMisconfigurationQuery } from './storage-misconfiguration'

export {
  useAwsThreatStatsQuery,
  useAwsThreatExposureQuery,
  useAwsFileScansQuery,
  useAwsScanCoverageQuery,
  useAwsBackupCoverageQuery,
  useRpoAndRetentionStatsQuery,
  useAwsStorageMisconfigurationQuery,
}
