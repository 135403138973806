import React, { useState } from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import VaultModel from '@lib/models/vault.model'

interface Props {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<string>
  data: Nullable<Array<VaultModel>>
  show: boolean
  loading: boolean
  className?: string
  openAddModal: EngineCallbackPure
}

function SelectDefaultVaultModal({
  onSubmit,
  onCancel,
  data,
  show,
  className,
  openAddModal,
}: Props) {
  const [selectedVault, setSelectedVault] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVault((event.target as HTMLInputElement).value)
  }

  const handleSubmit = () => {
    if (!selectedVault) {
      return
    }
    onSubmit(selectedVault)
  }

  const onOpenAddVaultModal = () => {
    openAddModal()
    onCancel()
  }

  const onCancelButton = () => {
    setSelectedVault('')
    onCancel()
  }

  return (
    <BaseModal
      className={`modalBig ${className}`}
      onBgClick={onCancel}
      show={show}
    >
      <div className="modalHeaderWrap">
        <div className="mhTitle fontBold">Select Default Vault</div>
        <div className="mhDesc fontNormal">
          Please select from one of the available vaults below, or
          <span onClick={onOpenAddVaultModal}> create new</span>
        </div>
      </div>
      <div className="modalFormContentV2">
        {data?.length && (
          <FormControl>
            <RadioGroup
              name="radio-buttons-group"
              value={selectedVault}
              onChange={handleChange}
            >
              {data.map((vault) => (
                <FormControlLabel
                  key={vault.innerId}
                  value={vault.innerId}
                  control={<Radio />}
                  label={vault.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </div>
      <div className="controlButtons">
        <Button
          className="showButton fontSemiBold sizeS"
          variant="contained"
          type="button"
          onClick={onCancelButton}
        >
          Cancel
        </Button>
        <Button
          className="fontSemiBold sizeS"
          variant="contained"
          color="primary"
          type="button"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </BaseModal>
  )
}

export default SelectDefaultVaultModal
