import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Table, useInfiniteScrollDataFetch } from '@components/table'
import { DisabledStateWrapper } from '@components/table/table-infinite-scroll-memory'
import { Toolbar } from './toolbar'
import { columnDefinitions } from './columns-definition/columns-definition'
import Box from '@mui/material/Box'
import {
  useOvaServersStoreActions,
  useOvaServersStoreState,
  useIsLoadingOvaServers,
} from './ova-servers-store'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from './toolbar/consts'
import { ExportAsCsv } from '@features/common'
import {
  OVA_CSV_TABLE_HEADERS,
  prepareOVADataForCsv,
} from '@components-complex/dashboard-pages-v3/ova-server-table/prepare-data-for-ova-csv'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'
import { useLayoutContext } from '@features/contexts'
import {
  convertStatusesStateToEnum,
  useToolbarFiltersState,
} from '@components-complex/dashboard-pages-v3/ova-server-table/toolbar/toolbar-filters-store'
import { ListOvaServersParams } from '@lib/clients/assets/list-ova-servers'

const HEADER_TITLE = 'Servers'

function OvaServerTable() {
  const { setHeaderTitle } = useLayoutContext()
  const { fetchNextPage, fetchInitial } = useOvaServersStoreActions()
  const {
    statusesList,
    ovaAccountsList: accountIdsList,
    backupProvidersList,
    textSearch,
  } = useToolbarFiltersState()

  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const isDrilldownQueryActive = urlParams.get(
    STATUS_FILTER_DRILLDOWN_QUERY_KEY
  )

  const { ovaServers, totalAccounts, totalProviders, totalServers } =
    useOvaServersStoreState()

  const isLoadingOvaServers = useIsLoadingOvaServers()

  const observable = useInfiniteScrollDataFetch({
    initialItems: ovaServers,
    fetchNextBatch: fetchNextPage,
  })

  const { ovaAccountsList, isOvaAccountsLoading } = useFetchListOvaAccounts()

  const isTableDataLoading = isLoadingOvaServers || isOvaAccountsLoading

  const fetchData = (filters: ListOvaServersParams, hideLoader?: boolean) => {
    fetchInitial(filters, hideLoader)
  }

  useEffect(() => {
    if (isDrilldownQueryActive) {
      return
    }

    fetchData({
      accountIdsList: sliceOvaAccountsList,
      backupProvidersList: sliceOvaBackupProvidersList,
    })
  }, [isDrilldownQueryActive])

  useEffect(() => {
    if (isDrilldownQueryActive) {
      return
    }

    const fetchInterval = setInterval(() => {
      fetchData(
        {
          textSearch,
          accountIdsList: accountIdsList.map((account) => account.id),
          statusesList: convertStatusesStateToEnum(statusesList),
          backupProvidersList,
        },
        true
      )
    }, INTERVAL_REQUEST_TIMEOUT)

    return () => {
      clearInterval(fetchInterval)
    }
  }, [
    isDrilldownQueryActive,
    statusesList,
    accountIdsList,
    backupProvidersList,
    textSearch,
  ])

  useEffect(() => {
    setHeaderTitle(HEADER_TITLE)
    return () => {
      setHeaderTitle(null)
    }
  }, [])

  return (
    <DisabledStateWrapper isDisabled={false}>
      <Box display="grid" rowGap={2}>
        <Toolbar ovaAccountsList={ovaAccountsList} />
        <ExportAsCsv
          data={prepareOVADataForCsv(ovaServers, ovaAccountsList)}
          headers={OVA_CSV_TABLE_HEADERS}
          filename="servers-inventory.csv"
          shouldShow={ovaServers.length > 0}
        />
        <Table
          data={ovaServers}
          columns={columnDefinitions({
            ovaAccountsList,
            totalServers,
            totalAccounts,
            totalProviders,
          })}
          advancedProps={{
            observableState: observable,
          }}
          data-testid="recoveryPointsTable"
          generalProps={{
            noDataMessage: 'No Servers found',
            isLoading: isTableDataLoading,
          }}
        />
      </Box>
    </DisabledStateWrapper>
  )
}

export default OvaServerTable
