/* eslint-disable import/no-extraneous-dependencies */
import {
  IconButton,
  Stack,
  Typography,
  Box,
  Card,
  ListItem,
  List,
  FormControl,
  ListItemIcon,
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'

import Grid from '@mui/material/Grid2'

import { Link } from 'react-router-dom'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import { Controller, useFormContext } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import { useEffect } from 'react'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'

// import AwsLogo from '../../assets/images/aws-logo.svg'

const AwsAccount = ({
  previousEmittedAccountId,
  setPreviousEmittedAccountId,
  onAccountIdChange,
}: AwsAccountProps) => {
  const { control, setValue, watch, trigger } = useFormContext<SourceFormData>()

  const awsAccountId = watch('awsAccountId')
  const handleAwsAccountId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value
    const filteredValue = inputValue.replace(/[^0-9]/g, '')
    setValue('awsAccountId', filteredValue, { shouldValidate: true })
  }

  useEffect(() => {
    if (awsAccountId && awsAccountId !== previousEmittedAccountId) {
      trigger('awsAccountId').then((isValid) => {
        if (isValid) {
          onAccountIdChange(awsAccountId)
          setPreviousEmittedAccountId(awsAccountId)
        }
      })
    }
  }, [awsAccountId, previousEmittedAccountId])

  return (
    <Stack maxWidth={700}>
      <Typography
        mb={2.5}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        Enter your Amazon Web Services (AWS) account ID to begin the deployment.
      </Typography>
      <Box mb={2}>{/* <img src={AwsLogo} alt="" /> */}</Box>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid size={12}>
          <FormControl fullWidth>
            <Typography variant="body2" mb={1}>
              AWS Account ID
            </Typography>
            <Controller
              control={control}
              rules={{
                required: 'AWS account ID is required',
                pattern: {
                  value: RegexConstants.ACCOUNT_ID,
                  message: 'Account id must contain 12 numbers',
                },
              }}
              name="awsAccountId"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                  onChange={handleAwsAccountId}
                  slotProps={{ htmlInput: { maxLength: 12 } }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid size={12}>
          <Link
            style={{
              color: '#44acea',
              textDecoration: 'none',
            }}
            target="_blank"
            to={
              'https://docs.elastio.com/docs/get-started/deployment-specifications/cloud-connector-security'
            }
          >
            Why are we asking for your account number?
          </Link>
        </Grid>
        <Grid size={12}>
          <Typography variant="h6" mb={0.5}>
            Entropy Trend
          </Typography>
          <Card sx={{ padding: 2 }}>
            <Stack>
              <Box display={'flex'} columnGap={1} alignItems={'flex-start'}>
                <IconButton color="primary" sx={{ padding: 0 }}>
                  <InfoIcon />
                </IconButton>
                <Typography mb={0.5} variant="body1" fontWeight={600}>
                  Where can I find my AWS account ID?
                </Typography>
              </Box>
              <List>
                <ListItem sx={{ alignItems: 'flex-start' }}>
                  <ListItemIcon sx={{ minWidth: 20 }}>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <Typography fontSize={13}>
                    If you are already logged into your AWS console, you can
                    view your AWS account ID in the profile dropdown on the top
                    right of the screen.{' '}
                    <Link
                      style={{
                        color: '#44acea',
                        textDecoration: 'none',
                      }}
                      target="_blank"
                      to={'https://aws.amazon.com/console/'}
                    >
                      Visit AWS Console
                    </Link>
                  </Typography>
                </ListItem>
              </List>
            </Stack>
            <Stack>
              <Box display={'flex'} columnGap={1} alignItems={'flex-start'}>
                <IconButton color="primary" sx={{ padding: 0 }}>
                  <InfoIcon />
                </IconButton>
                <Typography mb={0.5} variant="body1" fontWeight={600}>
                  Are there any requirements for the target environment?
                </Typography>
              </Box>
              <List>
                <ListItem sx={{ alignItems: 'flex-start' }}>
                  <ListItemIcon sx={{ minWidth: 20 }}>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <Typography fontSize={13}>
                    Elastio deploy consists of several parts, including the
                    account-level CloudFormation stack and regional Cloud
                    Connectors. For a smooth deploy setup your environment as
                    explained{' '}
                    <Link
                      style={{
                        color: '#44acea',
                        textDecoration: 'none',
                      }}
                      target="_blank"
                      to={
                        'https://docs.elastio.com/docs/get-started/deployment-specifications'
                      }
                    >
                      here
                    </Link>
                    .
                  </Typography>
                </ListItem>
              </List>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  )
}

interface AwsAccountProps {
  previousEmittedAccountId: string
  setPreviousEmittedAccountId: React.Dispatch<React.SetStateAction<string>>
  onAccountIdChange: (awsAccountId: string) => void
}

export default AwsAccount
