/* eslint-disable import/no-extraneous-dependencies */
import { DefinedDateRangeTuple } from '@lib/engine-types'
import { TenantJobFiltersUI } from '@lib/interfaces/jobs'

export interface SystemFiltersState {
  timeRange: DefinedDateRangeTuple
  selectedFilters: TenantJobFiltersUI
  possibleFilters: TenantJobFiltersUI
}

type SetMember<T> = T extends Set<infer E> ? E : never

export interface SystemJobsFiltersStoreActions {
  resetSystemJobsFiltersState: () => void
  setFilters: (selectedFilters: TenantJobFiltersUI) => void
  removeFilter: <
    K extends keyof TenantJobFiltersUI,
    V extends SetMember<TenantJobFiltersUI[keyof TenantJobFiltersUI]>,
  >(
    key: K,
    value: V
  ) => void
  setTimeRange: (timeRange: DefinedDateRangeTuple) => void
  fetchPossibleTenantJobFilters: () => void
}

export type SystemJobsFiltersStore = SystemFiltersState & {
  actions: SystemJobsFiltersStoreActions
}

export {
  fetchPossibleTenantJobFilters,
  removeFilter,
  setFilters,
} from './actions'

export {
  getSelectedFilters,
  useSystemJobsFiltersActions,
  useSystemJobsTimeRange,
  useIsSystemFiltersNotEmpty,
  usePossibleTenantJobFilters,
  useSelectedSystemJobFilters,
  useShouldDisableFilterButton,
} from './selectors'

export { useFiltersSubscription } from './subscriptions'
