import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import { useSelector } from 'react-redux'
import { getSelectedWebhook } from '@store/selectors/webhook.selector'
import EditWebhook from '@components-complex/edit-webhook/EditWebhook'
import AddWeebhookModal from '@components-composite/modals/AddWebhookModal'
import React from 'react'

export default function WebhookEditPage() {
  const { openModal: openAddWebhookModal, modalProps: webhookModalProps } =
    useModal<any>(Modal.addWebhook, () => {})
  const webhook = useSelector(getSelectedWebhook)

  return (
    <div className="innerContent innerContentBlueBackground jsEditWebhookPage">
      <EditWebhook showModal={openAddWebhookModal} />

      <AddWeebhookModal
        selectedWebhook={webhook}
        loading={false}
        {...webhookModalProps}
      />
    </div>
  )
}
