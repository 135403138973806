// eslint-disable-next-line import/no-extraneous-dependencies
import { ReportGenerated } from 'blues-corejs/dist/models/reports/report-generated'
import { DownloadGeneratedReportRequest } from '@lib/clients/grpc-imports'

export interface DownloadGeneratedReportCriteria {
  id: string
}

export interface DownloadGeneratedReportResponse {
  pdfUrl?: string
  csvUrl?: string
  xlsxUrl?: string
  generatedReport?: ReportGenerated
}

export function buildDownloadGeneratedReportRequest({
  id,
}: DownloadGeneratedReportCriteria): DownloadGeneratedReportRequest {
  const request = new DownloadGeneratedReportRequest().setId(id)

  return request
}
