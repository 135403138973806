import { useSearchParams } from 'react-router-dom'

type QueryParameterValue = string | Array<string> | undefined
type QueryParamsState = {
  isRPOVisible: boolean
  isRetentionVisible: boolean
  filterParameter: QueryParameterValue
  rpoQueryParameter: QueryParameterValue
  retentionQueryValue: QueryParameterValue
  shouldHideBackupsOnSchedule: () => boolean
}
function useQueryParams(): QueryParamsState {
  const [searchParams] = useSearchParams()

  const isRPOVisible = !!searchParams.get('rpo')
  const isRetentionVisible = !!searchParams.get('retention')
  const filterParameter = searchParams.get('filter') || undefined
  const rpoQueryParameter = searchParams.get('rpo') || undefined
  const retentionQueryValue = searchParams.get('retention') || undefined

  function shouldHideBackupsOnSchedule(): boolean {
    return filterParameter === 'noBackupsOnSchedule'
  }

  return {
    isRPOVisible,
    isRetentionVisible,
    filterParameter,
    rpoQueryParameter,
    retentionQueryValue,
    shouldHideBackupsOnSchedule,
  }
}

export { useQueryParams }
