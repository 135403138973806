/* eslint-disable import/no-extraneous-dependencies */
import { Asset, EBS, EC2, S3Bucket } from 'blues-corejs/dist'

export function getShouldRenderEntropyTrendsDialog({
  asset,
  isEntropyEnabled,
}: {
  isEntropyEnabled: boolean
  asset: Asset | undefined
}) {
  const assetSupportEntropyTrends =
    asset instanceof EC2 || asset instanceof EBS || asset instanceof S3Bucket

  return isEntropyEnabled && assetSupportEntropyTrends
}
