import { useMutation } from '@tanstack/react-query'
import { TokenIssueResultInterface } from 'ui-v2/src/lib/models/settings/api-access'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { PersonalAccessTokenClient } from 'ui-v2/src/lib/clients/settings/personal-access-token'

export function useIssueMutation() {
  const personalAccessTokenClient = useGrpcClient(PersonalAccessTokenClient)

  return useMutation({
    mutationFn: async ({
      tokenName,
      scopes,
    }: {
      tokenName: string
      scopes: Array<string>
    }): Promise<TokenIssueResultInterface> => {
      if (!personalAccessTokenClient) {
        throw new Error('Personal access token client not initialized')
      }
      return personalAccessTokenClient.issue(tokenName, scopes)
    },
  })
}
