import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { KrabsOvaStatisticsClient, OvaStatisticsClient } from '@lib/clients'
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'

type FilesScanned = {
  cleanFilesCount: number
  infectedFilesCount: number
  totalFilesScannedCount: number
}

const INITIAL_FILES_SCANNED_STATE: FilesScanned = {
  cleanFilesCount: 0,
  infectedFilesCount: 0,
  totalFilesScannedCount: 0,
}

const ovaStatisticsClient = new OvaStatisticsClient()
const krabsOvaStatisticsClient = new KrabsOvaStatisticsClient()

export function useFetchFilesScannedScanned() {
  const ovaAccountsList = useGetOvaAccountList()
  const ovaBackupProvidersList = useGetOvaBackupProvidersList()
  const [isLoading, setIsLoading] = useState(false)
  const [filesScanned, setFilesScanned] = useState<FilesScanned>(
    INITIAL_FILES_SCANNED_STATE
  )

  useEffect(() => {
    let isMounted = true
    const fetchFilesScanned = async () => {
      try {
        setIsLoading(true)

        const { cleanFilesCount, infectedFilesCount } =
          await ovaStatisticsClient.getFilesScannedStatistics({
            ovaAccountIds: ovaAccountsList,
            backupProviders: ovaBackupProvidersList,
          })

        const totalFilesScanned =
          await krabsOvaStatisticsClient.getFilesScannedStatistics({
            ovaAccountIds: ovaAccountsList,
            backupProviders: ovaBackupProvidersList,
          })

        if (isMounted) {
          setFilesScanned({
            cleanFilesCount,
            infectedFilesCount,
            totalFilesScannedCount: totalFilesScanned,
          })
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'getFilesScannedStatistics: failed to fetch files scanned statistics'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchFilesScanned()

    return () => {
      isMounted = false
      setFilesScanned(INITIAL_FILES_SCANNED_STATE)
      setIsLoading(false)
    }
  }, [ovaAccountsList, ovaBackupProvidersList])

  return {
    isLoading,
    cleanFilesCount: filesScanned.cleanFilesCount,
    infectedFilesCount: filesScanned.infectedFilesCount,
    totalFilesScannedCount: filesScanned.totalFilesScannedCount,
  }
}
