import { create } from 'zustand'

import { InfrastructureScopeValues } from './types'
import { defaultValues } from './consts'

interface InfrastructureScopeActions {
  setInfraData: (data: InfrastructureScopeValues) => void
  resetStore: () => void
}

export interface InfrastructureScopeState {
  infraData: InfrastructureScopeValues
}

export type InfrastructureScopeStore = InfrastructureScopeActions &
  InfrastructureScopeState

const INITIAL_STATE: InfrastructureScopeState = {
  infraData: { ...defaultValues },
}

const useInfrastructureScopeStore = create<InfrastructureScopeStore>((set) => ({
  ...INITIAL_STATE,
  setInfraData: (data: InfrastructureScopeValues) =>
    set((state) => ({
      ...state,
      infraData: data,
    })),
  resetStore: () => set({ ...INITIAL_STATE }),
}))

export const resetInfrastructureScopeStore = () => {
  useInfrastructureScopeStore.getState().resetStore()
}

export const useInfrastructureScopeState = () =>
  useInfrastructureScopeStore((state) => state.infraData)

export const useInfrastructureScopeActions = () =>
  useInfrastructureScopeStore((state) => ({
    setInfrastructureScopeData: state.setInfraData,
    resetStore: state.resetStore,
  }))
