import { useRetentionPolicyListQuery } from 'ui-v2/src/hooks/queries/catalog/retention-policy-list'
import { useMemo } from 'react'
import { getRetentionPolicyListColumns } from './column-config'
import { RetentionPolicyTableData } from 'ui-v2/src/lib/use-cases/settings/retention-policies'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { useFlags } from 'launchdarkly-react-client-sdk'

const RetentionPolicies = () => {
  const { data: retentionPolicy, isLoading } = useRetentionPolicyListQuery()

  const columns = useMemo(getRetentionPolicyListColumns, [])

  const { scanOnlyReleaseJanuary } = useFlags()

  const data = useMemo(() => {
    if (!retentionPolicy) {
      return undefined
    }

    return new RetentionPolicyTableData({
      retentionPolicy,
      scanOnlyReleaseJanuary,
    }).getRetentionPolicyList()
  }, [retentionPolicy])

  return (
    <ClientTable
      checkable={false}
      isLoading={isLoading}
      data={data ?? []}
      columns={columns}
    />
  )
}

export default RetentionPolicies
