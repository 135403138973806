import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { AssetsClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useAssetsLiveQuery() {
  const assetsClient = useGrpcClient(AssetsClient)

  return useQuery({
    queryKey: ['assets', 'live'],
    queryFn: async () => {
      if (!assetsClient) {
        throw new Error('Assets client not initialized')
      }
      return assetsClient.listLiveAssets()
    },
    enabled: !!assetsClient,
  })
}
