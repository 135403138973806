/* eslint-disable import/no-extraneous-dependencies */
import { StatisticRowType } from '../widgets-with-scale/coverage-stats'
import { useFetchAwsBackupCoverage } from '@features/DashboardV4/widgets-with-scale/coverage-stats/use-fetch-aws-backup-coverage'
import { computeBackupCoverageStats } from '@features/DashboardV4/views/utils'
import { useNavigation, DashboardRoutes } from '@lib/router'

export function useBackupCoverageStats() {
  const router = useNavigation()
  const { isLoading, ec2, ebs } = useFetchAwsBackupCoverage()

  const {
    totalInstances,
    coveredInstances,
    totalVolumes,
    coveredVolumes,
    numberOfAssets,
    numberOfAssetsWithBackups,
    percentOfAssetsWithBackups,
  } = computeBackupCoverageStats({
    ec2,
    ebs,
  })

  const handleDrilldown = (url: string) => {
    router.push(url)
  }

  //TODO uncommemt and check this driildowns
  //after re-invent
  // const instancesNotCoveredByPolicy = ec2Instances.filter(
  //   ({ backupPolicies }) => backupPolicies.length === 0
  // ).length

  // const volumesNotCoveredByPolicy = ebsVolumes.filter(
  //   ({ backupPolicies }) => backupPolicies.length === 0
  // ).length

  // const numberOfInstancesNotCoveredByPolicy =
  //   numberOfInstances - instancesNotCoveredByPolicy
  // const numberOfVolumesNotCoveredByPolicy =
  //   numberOfVolumes - volumesNotCoveredByPolicy

  // const instancesDrillDown =
  //   numberOfInstancesNotCoveredByPolicy > 0
  //     ? INVENTORY_INSTANCES_TABLE
  //     : numberOfVolumesNotCoveredByPolicy > 0
  //     ? INVENTORY_VOLUMES_TABLE
  //     : INVENTORY_INSTANCES_TABLE

  // const volumesDrillDown =
  //   numberOfVolumesNotCoveredByPolicy > 0
  //     ? INVENTORY_VOLUMES_TABLE
  //     : INVENTORY_INSTANCES_TABLE

  const backupCoverageStats: Array<StatisticRowType> = [
    {
      assets: totalInstances,
      assetsWithBackups: coveredInstances,
      label: 'EC2 Instances',
      onRowClick: () => handleDrilldown(DashboardRoutes.ec2Table),
    },
    {
      assets: totalVolumes,
      assetsWithBackups: coveredVolumes,
      label: 'EBS Volumes',
      onRowClick: () => handleDrilldown(DashboardRoutes.ebsTable),
    },
  ]

  return {
    isLoading,
    backupCoverageStats,
    numberOfAssets,
    numberOfAssetsWithBackups,
    percentOfAssetsWithBackups,
  }
}
