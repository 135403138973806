import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

interface Props {
  handlePopoverOpen: (event: React.MouseEvent<HTMLElement>) => void
  handlePopoverClose: () => void
}

const StyledButton = styled(Button)({
  height: '33px !important',
  width: '110px !important',
  minWidth: '110px !important',
  boxShadow: 'none !important',
  ':hover': {
    boxShadow: 'none',
  },
})

const BUTTON_LABEL = 'Hard deleted'

export function ButtonComponent({
  handlePopoverOpen,
  handlePopoverClose,
}: Props) {
  return (
    <StyledButton
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      variant="el-error"
    >
      {BUTTON_LABEL}
    </StyledButton>
  )
}
