import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { useServersDrilldownLink } from '@components-complex/dashboard-pages-v3/ova-server-table'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from '@components-complex/dashboard-pages-v3/ova-server-table/toolbar/consts'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'

interface Props {
  cleanServersCount: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => {
  const { handleRedirectWithDrilldown } = useServersDrilldownLink({
    queryKey: STATUS_FILTER_DRILLDOWN_QUERY_KEY,
    queryValue: OvaServerStatus.CLEAN.toString(),
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      flexBasis="50%"
      sx={{ cursor: 'pointer' }}
      onClick={handleRedirectWithDrilldown}
    >
      {children}
    </Box>
  )
}

function CleanServersSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          128
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" fontSize="16px">
          Clean
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function CleanServers({ cleanServersCount, isLoading }: Props) {
  if (isLoading) {
    return <CleanServersSkeleton />
  }

  return (
    <Container>
      <Typography fontWeight="600" fontSize="22px">
        {cleanServersCount}
      </Typography>
      <Typography fontWeight="400" fontSize="16px">
        Clean
      </Typography>
    </Container>
  )
}
