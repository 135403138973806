import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import { Stack, Box, Typography, FormGroup, FormControl } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Controller, useFormContext } from 'react-hook-form'
import ProtectionOptionsCheckbox from '../protection-options-checkbox'
import DatepickerInput from 'ui-v2/src/components/ui/inputs/datepicker-input'
import dayjs from 'dayjs'
import TimepickerInput from 'ui-v2/src/components/ui/inputs/timepicker-input'
import TimeFormatConstants from 'ui-v2/src/lib/constants/time-format.constant'
import S3RecoverAssuranceSettings from './s3-recover-assurance-settings'

const ProtectionS3 = () => {
  const { control, watch } = useFormContext<PolicyFormData>()
  const scanWithTimestamps = watch('scanWithTimestamps')

  return (
    <Grid container spacing={0}>
      <Grid
        size={{
          md: 6,
          lg: 6,
        }}
      >
        <Box
          borderRight={{
            sm: 0,
            md: 1,
          }}
          paddingRight={{
            sm: 0,
            md: 2,
          }}
          minHeight={'calc(100vh - 200px)'}
        >
          <Typography
            variant="body1"
            mb={2.5}
            fontWeight={700}
            color="text.secondary"
          >
            Recovery Assurance Settings
          </Typography>
          <FormGroup>
            <Stack spacing={3}>
              <S3RecoverAssuranceSettings />
            </Stack>
          </FormGroup>
        </Box>
      </Grid>
      <Grid
        size={{
          md: 6,
          lg: 6,
        }}
      >
        <Stack
          spacing={2.5}
          paddingLeft={{
            sm: 0,
            md: 2,
          }}
          minHeight={'calc(100vh - 200px)'}
        >
          <Typography variant="body1" fontWeight={700} color="text.secondary">
            S3 Settings
          </Typography>
          <FormGroup>
            <Stack spacing={2} paddingLeft={2}>
              <Controller
                name="scanWithTimestamps"
                control={control}
                render={({ field }) => (
                  <ProtectionOptionsCheckbox
                    label="Scan objects uploaded after a specific time:"
                    protectionOptionValue={field.value.isScanObjects}
                    onProtectionOptionChange={(e) => {
                      field.onChange({
                        ...field.value,
                        isScanObjects: e.target.checked,
                      })
                    }}
                  />
                )}
              />
            </Stack>
          </FormGroup>
          <Box display={'flex'} paddingLeft={7.5} columnGap={1.5}>
            <FormControl fullWidth>
              <Controller
                name="scanWithTimestamps"
                control={control}
                render={({ field }) => (
                  <DatepickerInput
                    value={dayjs(field.value.dateTimeData.day)}
                    onChange={(value) => {
                      if (dayjs(value).isValid()) {
                        field.onChange({
                          ...field.value,
                          dateTimeData: {
                            ...field.value.dateTimeData,
                            day: dayjs(value).valueOf(),
                          },
                        })
                      }
                    }}
                    disablePast={true}
                    disabled={!scanWithTimestamps.isScanObjects}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="scanWithTimestamps"
                render={({ field }) => (
                  <TimepickerInput
                    {...field}
                    label=""
                    value={field.value.dateTimeData.time}
                    onChange={(time: any) => {
                      field.onChange({
                        ...field.value,
                        dateTimeData: {
                          ...field.value.dateTimeData,
                          time: {
                            ...time,
                            timezone: TimeFormatConstants.UTC_TIMEZONE_VALUE,
                          },
                        },
                      })
                    }}
                    disabled={!scanWithTimestamps.isScanObjects}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ProtectionS3
