/* eslint-disable import/no-extraneous-dependencies */
import { ScansRun } from 'blues-corejs/dist/models'

export function getCompletionTime(scanRun: ScansRun): Date {
  const malwareScansCompleteTime = scanRun.malwareScansList.reduce(
    (lastCompleteTime, scan) => {
      if (scan.createdAt > lastCompleteTime) {
        return scan.createdAt
      }
      return lastCompleteTime
    },
    new Date(0)
  )

  const ransomwareScansCompleteTime = scanRun.ransomwareScansList.reduce(
    (lastCompleteTime, scan) => {
      if (scan.createdAt > lastCompleteTime) {
        return scan.createdAt
      }
      return lastCompleteTime
    },
    new Date(0)
  )

  const fsCheckCompleteTime = scanRun.filesystemChecksList.reduce(
    (lastCompleteTime, scan) => {
      if (scan.createdAt > lastCompleteTime) {
        return scan.createdAt
      }
      return lastCompleteTime
    },
    new Date(0)
  )

  return new Date(
    Math.max(
      malwareScansCompleteTime.getTime(),
      ransomwareScansCompleteTime.getTime(),
      fsCheckCompleteTime.getTime()
    )
  )
}
