/* eslint-disable import/no-extraneous-dependencies */
import { Scan } from 'ui-v2/src/lib/models/scans'
import { GenericHost } from 'blues-corejs/dist'
import { Threat } from 'blues-corejs/dist/models/index'
import { AssetItemGenericHost } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'
import { AssetsGenericHostBase } from './generic-host-base'
import {
  AssetsGenericHostAttrs,
  GenericHostTableRow,
} from 'ui-v2/src/lib/models/assets'

export class AssetsGenericHostTableData extends AssetsGenericHostBase {
  protected readonly genericHosts: Array<GenericHost>

  protected readonly threats: Array<Threat>

  protected readonly assetItemsListWithHostIds: Map<
    string,
    Array<AssetItemGenericHost>
  >

  protected readonly scans: Array<Scan>

  protected readonly genericHostsWithScans: Array<{
    genericHost: GenericHost
    scans: Array<Scan>
  }>

  constructor({
    genericHosts,
    threats,
    assetItemsData,
    scans,
  }: AssetsGenericHostAttrs) {
    super()
    this.genericHosts = genericHosts
    this.threats = threats
    this.assetItemsListWithHostIds = genericHosts.reduce((map, host) => {
      const assetItemsGenericHost: Array<AssetItemGenericHost> = [
        ...assetItemsData.filesList,
        ...assetItemsData.disksList,
        ...assetItemsData.streamsList,
        ...assetItemsData.volumesList,
      ].filter((assetItem) => assetItem.assetId === host.id)

      if (!map.has(host.id)) {
        map.set(host.id, assetItemsGenericHost)
      }
      return map
    }, new Map<string, Array<AssetItemGenericHost>>())
    this.scans = scans
    this.genericHostsWithScans = this.getGenericHostsWithScans()
  }

  getGenericHostTableData(): Array<GenericHostTableRow> {
    return this.genericHosts.map(
      (genericHost) =>
        new GenericHostTableRow({
          id: genericHost.id,
          name: genericHost.hostname,
          threats: this.calculateThreatsCount(
            this.assetItemsListWithHostIds?.get(genericHost.id) ?? []
          ),
          lastScanDate: this.getLastScan(genericHost)?.completedAt,
          isUnhealthy: this.isUnhealthyHost(
            this.assetItemsListWithHostIds?.get(genericHost.id) ?? []
          ),
        })
    )
  }
}
