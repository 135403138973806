/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { MaybeInfectedServer } from '@lib/clients/assets/list-ova-servers'
import { OvaAccount } from 'blues-corejs/dist'
import { OvaBackupProviderHelper } from '@lib/helpers/ova-backup'
import { getOvaServerAccountName, getOvaServerThreats } from './utils'
import { customFormatDate } from '@components-complex/dashboard-pages-v3/ova-server-table/columns-definition/last-clean-backup-cell'

dayjs.extend(utc)

const EMPTY_CELL_PLACEHOLDER = '-'

function prepareOVADataForCsv(
  serverList: Array<MaybeInfectedServer>,
  ovaAccountsList: Array<OvaAccount>
) {
  return serverList.map(
    ({ server, lastCleanBackup, hasRansomware, hasMalware }) => {
      const account = getOvaServerAccountName(
        ovaAccountsList,
        server.ovaAccountId
      )
      const threats = getOvaServerThreats(hasMalware, hasRansomware)
      const dateOfLastCleanBackup = lastCleanBackup
        ? customFormatDate(dayjs(lastCleanBackup.timestamp))
        : EMPTY_CELL_PLACEHOLDER

      const isInfected = hasMalware || hasRansomware
      const status = isInfected ? 'Infected' : 'Healthy'

      return {
        server: server.name,
        account,
        backupVendor: OvaBackupProviderHelper.getProviderName(
          server.backupProvider
        ),
        lastCleanBackup: dateOfLastCleanBackup,
        status: status,
        threats,
      }
    }
  )
}

const OVA_CSV_TABLE_HEADERS = [
  {
    label: 'Server',
    key: 'server',
  },
  {
    label: 'Account',
    key: 'account',
  },
  {
    label: 'Backup Vendor',
    key: 'backupVendor',
  },
  {
    label: 'Last Clean Recovery Point',
    key: 'lastCleanBackup',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Threats',
    key: 'threats',
  },
]

export { prepareOVADataForCsv, OVA_CSV_TABLE_HEADERS }
