import React, { memo } from 'react'
import LinuxIcon from '@inline-img/general/linux-icon'
import MacosIcon from '@inline-img/general/macos-icon'
import WindowsIcon from '@inline-img/general/windows-icon'
import SystemHelper from '@lib/helpers/system.helper'
import ToastHelper from '@lib/helpers/toast.helper'
import EditIcon from '@inline-img/general/edit-icon'
import { CopyButtonVariant } from '@lib/constants/copy-command-button.constant'
import { Button } from '@mui/material'

interface Props {
  commandText: string
  variant: CopyButtonVariant
  className?: string
  toastText: string
  disabled?: boolean
}

function CopyCommandButton({
  commandText = '',
  variant = CopyButtonVariant.WINDOWS,
  className,
  toastText,
  disabled = false,
}: Props) {
  const toastHeader = 'Command copied to clipboard!'

  const getButtonText = () => {
    switch (variant) {
      case CopyButtonVariant.WINDOWS:
        return (
          <>
            <span className="mr6 jsCopyButtonText">Microsoft</span>
            <WindowsIcon />
          </>
        )
      case CopyButtonVariant.MACOS:
        return (
          <>
            <span className="mr6 jsCopyButtonText">Mac OS</span>
            <MacosIcon />
          </>
        )
      case CopyButtonVariant.LINUX:
        return (
          <>
            <span className="mr6 jsCopyButtonText">Linux</span>
            <LinuxIcon />
          </>
        )
      case CopyButtonVariant.DEFAULT:
        return (
          <>
            <span>Copy</span>
            <EditIcon />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <div className={`wrap-1655390369855 copyBtnWrap ${className}`}>
      <Button
        type="button"
        color="primary"
        variant="contained"
        className="sizeS"
        disabled={disabled}
        onClick={() => {
          SystemHelper.copyToClipboard(commandText)
          ToastHelper.success(toastText, toastHeader)
        }}
      >
        {getButtonText()}
      </Button>
    </div>
  )
}

export default memo(CopyCommandButton)
