import { Finding } from './finding'
import { Source } from './source'
import { Scan } from './scan'

export interface ListAnomaliesFilter {
  startAt: number
  endAt: number
  assetIdsList?: Array<string>
  pageSize?: number
  pageToken?: string
}

export interface GetFiltersForListAwsAssetSummariesFilter {
  startAt: number
  endAt: number
}

export enum AnomalyKind {
  ANOMALY_KIND_HIGH_ENTROPY_FILES,
}

interface AnomalyAttrs {
  id: string
  kind: AnomalyKind
  findings: Array<Finding>
  scan: Scan
  source: Source
  totalNumberOfFiles: number
  createdAt: Date
}

export class Anomaly {
  // UUID of the Anomaly
  #id: string

  // A kind of the Anomaly
  #kind: AnomalyKind

  // A list of findings
  #findings: Array<Finding>

  // A scan that detected the Anomaly
  #scan: Scan

  #source: Source

  #totalNumberOfFiles: number

  // The Anomaly creation timestamp
  #createdAt: Date

  constructor(attrs: AnomalyAttrs) {
    this.#id = attrs.id
    this.#kind = attrs.kind
    this.#findings = attrs.findings
    this.#scan = attrs.scan
    this.#source = attrs.source
    this.#totalNumberOfFiles = attrs.totalNumberOfFiles
    this.#createdAt = attrs.createdAt
  }

  get id() {
    return this.#id
  }

  get kind() {
    return this.#kind
  }

  get findings() {
    return this.#findings
  }

  get scan() {
    return this.#scan
  }

  get source() {
    return this.#source
  }

  get assetId() {
    const asset = this.#source.getAsset()
    if (asset) {
      return asset.getId()
    }

    const backup = this.#source.getBackup()

    if (backup) {
      return backup.getAssetId()
    }

    const assetItem = this.#source.getAssetItem()

    if (assetItem) {
      return assetItem.getAssetId()
    }
  }

  get totalNumberOfFiles() {
    return this.#totalNumberOfFiles
  }

  get createdAt() {
    return this.#createdAt
  }
}
