import { StyledDrawer } from './styles'
import { CreateRuleDrawerActionHeader } from './header'
import {
  useCreateRuleDrawerState,
  useCreateRuleDrawerActions,
} from '@features/alerts/create-rule-drawer'
import { ComputedStep } from './computed-step'
import { useRuleDrawerDataActions } from './create-rule-drawer-data-store'
import { useEffect } from 'react'
import ConfirmDeleteModal from '@features/alerts/create-rule-drawer/confirm-delete-modal/confirm-delete-modal'
import { resetAllStores } from '@features/alerts/create-rule-drawer/stepper/steps'

function CreateRuleDrawer() {
  const { isOpen } = useCreateRuleDrawerState()
  const { fetchAllData } = useRuleDrawerDataActions()
  const { closeDrawer } = useCreateRuleDrawerActions()

  useEffect(() => {
    fetchAllData()
  }, [])

  const handleClose = () => {
    closeDrawer()
    resetAllStores()
  }

  return (
    <StyledDrawer open={isOpen} onClose={handleClose}>
      <CreateRuleDrawerActionHeader />
      <ComputedStep />
      <ConfirmDeleteModal />
    </StyledDrawer>
  )
}

export default CreateRuleDrawer
