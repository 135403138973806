import React, { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ValueInterface from '@lib/interfaces/value.interface'
import FilterDashboardV2 from '@components-complex/filter-dashboard-v2/FilterDashboardV2'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  listEc2InventoryPossibleFilters,
  ListInventoryInstances,
  Policy,
} from 'blues-corejs/dist'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'

// eslint-disable-next-line import/no-extraneous-dependencies
import DataHelper from '@lib/helpers/data.helper'
import {
  getInstancesTableColumnDefinitions,
  RETENTION_COLUMN_NAME,
  RPO_COLUMN_NAME,
  RPO_TARGET_COLUMN_NAME,
} from './columns-definition'

import { NO_BACKUPS_ON_SCHEDULE } from '@components-complex/dashboard-pages-v3/inventory-tables-complex/filter-configs'
import { getColumnsSorting } from '@components-complex/dashboard-pages-v3/inventory-tables-complex/shared/columns-sorting'
/* eslint-disable import/no-extraneous-dependencies */
import {
  getAliasNamesForEC2,
  getDataForPossibleEc2InventoryFilters,
} from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/utils'
import FilterFactory from '@lib/factories/filter.factory'
import { pechkinClient } from '@lib/clients/pechkin'
import {
  InventoryInstanceOutput,
  SummarizedInstancesDataOutput,
} from 'blues-corejs/dist/use_cases/list_inventory_instances/types'
import {
  getBackups,
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { useListEc2 } from '@components-complex/dashboard-pages-v3/ec2-inventory-table-complex/use-list-ec2'
import { useList } from '@components-complex/dashboard-pages-v3/ebs-inventory-table-complex/use-list'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { updateDisabledStateForCoveredOptions } from '@components-complex/dashboard-pages-v3/shared'
import { initialPrioritySortForEC2Table } from './initial-priority-sort-for-ec2-table'
import {
  VirtualTable,
  DisabledStateWrapper,
} from '@components/table/table-infinite-scroll-memory'
import { ExportAsCsv } from '@features/common'
import {
  getEc2CvsTableHeaders,
  prepareEc2DataForCsv,
} from './prepare-data-for-ec2-csv'
import { getDetectedThreats } from '@components-complex/dashboard-pages-v3/utils'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'
import {
  ON_DEMAND_SCAN_BUTTON_TEXT,
  ON_DEMAND_BUTTON_GRADIENT_COLORS,
} from '@components-complex/dashboard-pages-v3/const'
import { StyledDemandButton } from '@components-complex/dashboard-pages-v3/styles'
import { useOnDemandScan } from '@components-complex/dashboard-pages-v3/useOnDemandScan'
import { useLayoutContext } from '@features/contexts'
import { useSearchParams } from 'react-router-dom'

const HEADER_TITLE = 'EC2'

function Ec2InventoryTableComplex() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const { setHeaderTitle } = useLayoutContext()
  const filterBackups = 'backupsOnSchedule'
  const showRPO = !!searchParams.get('rpo')
  const showRetention = !!searchParams.get('retention')
  const filterParam = searchParams.get('filter')
  const queryRpo = searchParams.get('rpo') || ''
  const isShowBackupsOnSchedule = filterParam === 'noBackupsOnSchedule'
  const retentionValue = searchParams.get('retention') || ''

  const { ec2Instances, ebsVolumes, ebsSnapshots } = useSelector(getLiveAssets)
  const [isListEc2Active, setIsListEc2Active] = useState(false)

  const listEc2 = useListEc2()

  const [list, actions] = useList(ec2Instances)
  const handleToggleList = (value: boolean) => {
    setIsListEc2Active(value)
    if (value) {
      actions.clear()
      actions.set(listEc2.data)
    } else {
      actions.reset()
    }
  }

  const [isLoading, setLoading] = useState<boolean>(false)
  const [searchEc2, setSearchEc2] = useState('')
  const [selectedFiltersEc2, setSelectedFiltersEc2] = useState<
    Array<ValueInterface>
  >([])

  const threats = useSelector(getThreats)
  const detectedThreat = getDetectedThreats(threats)
  const { fsCheckScans, malwareScans, ransomwareScans } = useSelector(getScans)
  const { firstBackups, lastBackups } = useSelector(getBackups)
  const [policiesList, setPoliciesList] = useState<Array<Policy>>([])
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const { possibleEc2Filters } = useMemo(() => {
    if (!ec2Instances.length) {
      return { possibleEc2Filters: {} }
    }

    return new listEc2InventoryPossibleFilters({
      Ec2s: ec2Instances,
    }).execute()
  }, [ec2Instances.length])

  useEffect(() => {
    if (filterParam && isShowBackupsOnSchedule) {
      setSelectedFiltersEc2(NO_BACKUPS_ON_SCHEDULE)
    }
  }, [ec2Instances.length, filterParam])

  const {
    instances,
    summarizedData,
  }: {
    instances: Array<InventoryInstanceOutput>
    summarizedData: SummarizedInstancesDataOutput
  } = useMemo(() => {
    if (isLiveAssetsLoading) {
      return {
        instances: [],
        summarizedData: {
          instancesCount: 0,
          accountsCount: 0,
          regionsCount: 0,
          osTypesCount: 0,
          statusesCount: 0,
          inventorySize: 0,
        },
      }
    }

    return new ListInventoryInstances({
      instances: list,
      volumes: ebsVolumes,
      firstBackups: firstBackups.backups,
      lastBackups: lastBackups.backups,
      lastEbsSnapshots: ebsSnapshots,
      threats: detectedThreat,
      policies: policiesList,
      lastScans: [...fsCheckScans, ...malwareScans, ...ransomwareScans],
    }).execute(
      DataHelper.getDataForDashboardFilter(selectedFiltersEc2, searchEc2)
    )
  }, [
    policiesList.length,
    JSON.stringify(selectedFiltersEc2),
    searchEc2,
    isLiveAssetsLoading,
    listEc2.data.length,
    list.length,
  ])

  const dataForPossibleEcInventoryFilters =
    getDataForPossibleEc2InventoryFilters(possibleEc2Filters)

  const possibleFiltersViRow = updateDisabledStateForCoveredOptions(
    dataForPossibleEcInventoryFilters.map(
      FilterFactory.buildEc2InventoryFilter
    ),
    isListEc2Active
  )
  const possibleEc2InventoryFilters = !isShowBackupsOnSchedule
    ? possibleFiltersViRow.filter((filter) => filter.name !== filterBackups)
    : possibleFiltersViRow

  const fetchPoliciesList = async () => {
    const policiesResponse = await pechkinClient.listPolicies()

    setPoliciesList(policiesResponse.plansList)
    setLoading(false)
  }

  useEffect(() => {
    setHeaderTitle(HEADER_TITLE)
    setLoading(true)
    fetchPoliciesList()

    const fetchInterval = setInterval(
      fetchPoliciesList,
      INTERVAL_REQUEST_TIMEOUT
    )

    dispatch(getAllRedStacks())

    return () => {
      clearInterval(fetchInterval)
      setHeaderTitle(null)
    }
  }, [])

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    setSelectedFiltersEc2(newFilters)
  }

  const onSearchChange = (value: string) => {
    setSearchEc2(value.trim())
  }

  const { handleDemandButtonClick, isScheduleScanLoading, rowsSelection } =
    useOnDemandScan({
      assetsCoreModel: ec2Instances,
      assets: instances,
    })
  const isDemandButtonDisabled =
    isScheduleScanLoading || !Object.keys(rowsSelection.selectedRows).length

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const aliasNamesWithId: AliasName = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !ec2Instances.length) {
      return {}
    }
    return getAliasNamesForEC2(ec2Instances, allActiveRedStacks)
  }, [ec2Instances.length, allActiveRedStacks.length])

  const sortedInstances = initialPrioritySortForEC2Table(instances)

  const isTableDisabled = isLoading || isScheduleScanLoading

  return (
    <Box className="wrap-1686745562524 newTablesPreview">
      <Box className="wrapEc2InventoryTable" data-testid="ec2-table">
        {isLiveAssetsLoading ? (
          <PreloaderBlock show />
        ) : (
          <Box display="flex">
            <FilterDashboardV2
              searchPlaceholder="Search Instances, Volumes, Snapshots"
              possibleFilters={possibleEc2InventoryFilters}
              selectedFilters={selectedFiltersEc2}
              turnOnSearch
              onFiltersChange={onFiltersChange}
              onSearchChange={onSearchChange}
              disabled={isLoading}
              searchVal={searchEc2}
              showFilterFirst
              tableName=""
              turnOffButton
              className="fiveItemsWithLabel flex-1"
            />
            <Box
              display="flex"
              alignItems="center"
              height="62px"
              sx={{
                color: 'var(--grey800)',
                '.MuiCheckbox-root svg': {
                  width: '18px',
                  height: '18px',
                },
                '.MuiFormControlLabel-label': {
                  fontSize: '14px',
                  fontWeight: '400',
                },
              }}
            >
              <Typography fontWeight="500" fontSize="14px" marginRight="12px">
                Status:
              </Typography>
              <V2Checkbox
                onChange={handleToggleList}
                label="Running / Stopped"
                checked
                disabled
              />
              <V2Checkbox
                onChange={handleToggleList}
                label="Terminated"
                checked={isListEc2Active}
                disabled={listEc2.isLoadingData()}
              />
            </Box>
            <StyledDemandButton
              variant="contained"
              disabled={isDemandButtonDisabled}
              onHoldComplete={handleDemandButtonClick}
              gradientColors={ON_DEMAND_BUTTON_GRADIENT_COLORS}
            >
              {ON_DEMAND_SCAN_BUTTON_TEXT}
            </StyledDemandButton>
          </Box>
        )}

        <ExportAsCsv
          data={prepareEc2DataForCsv(sortedInstances)}
          headers={getEc2CvsTableHeaders(showRPO, showRetention)}
          filename="ec2-inventory.csv"
          shouldShow={!isListEc2Active && sortedInstances.length > 0}
        />
        <DisabledStateWrapper isDisabled={isTableDisabled}>
          <VirtualTable
            config={{
              data: sortedInstances,
              columns: getInstancesTableColumnDefinitions({
                summarizedData,
                aliasNamesWithId,
              }),
              state: {
                rowSelection: rowsSelection.selectedRows,
                isLoading: isLoading,
              },
              onRowSelectionChange: rowsSelection.setSelectedRows,
              initialState: {
                columnVisibility: {
                  [RPO_COLUMN_NAME]: showRPO,
                  [RPO_TARGET_COLUMN_NAME]: showRPO,
                  [RETENTION_COLUMN_NAME]: showRetention,
                },
                sorting: getColumnsSorting({
                  queryRpo,
                  retentionValue,
                }),
              },
            }}
            emptyPlaceholderText="No EC2 Instances found"
          />
        </DisabledStateWrapper>
      </Box>
    </Box>
  )
}

export default memo(Ec2InventoryTableComplex)
