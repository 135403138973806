import React, { memo } from 'react'
import TimePicker from 'rc-time-picker'
import moment from 'moment-timezone'
import { EngineCallback } from '@lib/engine-types'
import HourMinuteInterface from '@lib/interfaces/hour-minute.interface'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import TimeFormatConstants from '@lib/constants/time-format.constant'

interface Props {
  onChange: EngineCallback<HourMinuteInterface>
  readonly data: HourMinuteInterface
  readonly disabled?: boolean
  readonly label?: string
  readonly minuteStep?: number
}

const DEFAULT_MINUTE_STEP = 5

function DefaultTimePicker({
  data,
  onChange,
  disabled = false,
  label = '',
  minuteStep = DEFAULT_MINUTE_STEP,
}: Props) {
  const onChangeHandler = (v: moment.Moment) => {
    onChange({
      minute: v.minute(),
      hour: v.hour(),
      timezone: v.tz() ?? TimeFormatConstants.UTC_TIMEZONE_VALUE,
    })
  }

  return (
    <div className="wrap-1605102427226">
      {label && <div className="timePickerLabel">{label}</div>}
      <div className="timePickerWrap">
        <TimePicker
          format={TimeFormatConstants.TIME_FORMAT_UTC}
          disabled={disabled}
          inputIcon={<AccessTimeIcon />}
          value={moment.utc(data)}
          clearIcon={false}
          use12Hours
          minuteStep={minuteStep}
          showSecond={false}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  )
}

export default memo(DefaultTimePicker)
