import { useDownloadScansMutation } from 'ui-v2/src/hooks/queries/scans'
import { useToast } from 'ui-v2/src/hooks/toast'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { Button, CircularProgress } from '@mui/material'

interface AssetDownloadScanReportProps {
  scan: Scan
}

const AssetDownloadScanReport: React.FC<AssetDownloadScanReportProps> = ({
  scan,
}) => {
  const { mutate, isPending } = useDownloadScansMutation()
  const toast = useToast()

  const downloadScanReport = () => {
    mutate(scan.id, {
      onError: (error) => {
        toast.error(error.message ?? 'Failed to download scan report')
      },
      onSuccess: (scanReportLink) => {
        if (scanReportLink) {
          window.open(scanReportLink, '_blank')
        }
      },
    })
  }

  return (
    <Button
      variant="contained"
      onClick={downloadScanReport}
      disabled={isPending}
      startIcon={
        isPending ? <CircularProgress size={20} color="inherit" /> : null
      }
      sx={{ textTransform: 'capitalize' }}
    >
      Download Report
    </Button>
  )
}

export default AssetDownloadScanReport
