/* eslint-disable import/no-extraneous-dependencies */
import { DetailsDetectedThreatFilters } from 'blues-corejs/dist'
import type { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'
import { default as ThreatsFilterItem } from './threats-filter-item'

import styles from './threats-filter.module.css'

interface Props {
  possibleFilter: DetailsDetectedThreatFilters
  selectedFilters: DetailsDetectedThreatFilters
  onSelectFilter: (evt: SelectChangeEvent<Array<string>>, key: string) => void
}

function ThreatsFilter({
  possibleFilter,
  selectedFilters,
  onSelectFilter,
}: Props) {
  return (
    <div className={styles.FilterWrapper}>
      {Object.keys(possibleFilter).map((key) => {
        let possible = possibleFilter[key as keyof DetailsDetectedThreatFilters]
        let selected =
          selectedFilters[key as keyof DetailsDetectedThreatFilters]

        if (!possible) {
          possible = []
        }

        if (!selected) {
          selected = []
        }

        return (
          <ThreatsFilterItem
            key={key}
            possibleValues={possible}
            selectedValues={selected}
            onSelect={onSelectFilter}
            field={key}
          />
        )
      })}
    </div>
  )
}

export default ThreatsFilter
