/* eslint-disable import/no-extraneous-dependencies */
import { Row } from '@tanstack/react-table'
import { DetailsVolumeOutput } from 'blues-corejs/dist/use_cases/list_detailes_volumes_with_threats'
import {
  DetailsDetectedThreatFilters,
  ListDetailsDetectedThreats,
} from 'blues-corejs/dist'
import { Table } from '@components/table'
import { getDetectedThreatsTableColumnDefinition } from './columns-definition-sub-table'
import React from 'react'
import { EngineCallback } from '@lib/engine-types'
import ThreatSuppressionModal from '@features/threat-suppression/threat-suppression-modal'

interface Props {
  row: Row<DetailsVolumeOutput>
  selectedFilter: DetailsDetectedThreatFilters
  fetchDataThreats: EngineCallback<void>
}

export function SubTableForVolumeWithThreats({
  row,
  selectedFilter = {},
  fetchDataThreats,
}: Props) {
  if (!row.original.threats || row.original.threats.length === 0) {
    return null
  }

  const { threatsData } = new ListDetailsDetectedThreats(
    row.original.threats
  ).execute(selectedFilter)

  return (
    <>
      <Table
        data={threatsData}
        columns={getDetectedThreatsTableColumnDefinition()}
        generalProps={{
          isLoading: false,
          isShowHeader: false,
          noDataMessage: 'No Threats found',
        }}
      />
      <ThreatSuppressionModal fetchDataThreats={fetchDataThreats} />
    </>
  )
}

export default SubTableForVolumeWithThreats
