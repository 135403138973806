import {
  CloudConnectorJobsClient,
  ListCloudConnectorJobsPagination,
  ListCloudConnnectorJobsFilter,
} from '@lib/clients/jobs'
import { DefinedDateRangeTuple } from '@lib/engine-types'
import TimeHelper from '@lib/helpers/time.helper'
import { CloudConnectorJobFiltersUI } from '@lib/interfaces/jobs'

const cloudConnectorJobsClient = new CloudConnectorJobsClient()

async function fetchInitialJobsList(
  selectedFilters: CloudConnectorJobFiltersUI,
  timeRange: DefinedDateRangeTuple
) {
  return cloudConnectorJobsClient.listCloudConnectorJobs(
    new ListCloudConnnectorJobsFilter({
      kindsList: Array.from(selectedFilters.kindsList),
      statusesList: Array.from(selectedFilters.statusesList),
      assetIdsList: Array.from(selectedFilters.assetIdsList),
      timeRange: TimeHelper.transformDateRangeForApi(timeRange),
    })
  )
}

async function fetchJobsListViaPagination(nextPageToken: string) {
  return cloudConnectorJobsClient.listCloudConnectorJobs(
    new ListCloudConnectorJobsPagination(nextPageToken)
  )
}

export { fetchInitialJobsList, fetchJobsListViaPagination }
