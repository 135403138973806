/* eslint-disable import/no-extraneous-dependencies */
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useMemo } from 'react'
import {
  BackupVariant,
  InstanceOutput,
  RiskLevel,
  SnapshotOutput,
  VolumeOutput,
  VulnerabilitiesDetectionStrategy,
} from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'
import {
  AwsSnapshotVulnerability,
  AwsVolumeVulnerability,
} from '@lib/clients/aws-statistics/types'

export interface FilterOptions {
  [key: string]: Array<string>
}

export function initialPrioritySortForEC2Table(
  instances: Array<InstanceOutput>
) {
  return instances.sort((a, b) => {
    const aLastBackupTime = a.lastBackup?.backup.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.backup.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const instanceAName = a.instanceName || a.instanceAwsId
    const instanceBName = b.instanceName || b.instanceAwsId

    return instanceAName.localeCompare(instanceBName)
  })
}

export function initialPrioritySortForEBSVolumeTable(
  volumes: Array<VolumeOutput>
) {
  return volumes.sort((a, b) => {
    const aLastBackupTime = a.lastBackup?.backup.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.backup.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const volumeAName = a.volumeName || a.volumeAwsId
    const volumeBName = b.volumeName || b.volumeAwsId

    return volumeAName.localeCompare(volumeBName)
  })
}

export function initialPrioritySortForEBSSnaphotsTable(
  snapshots: Array<SnapshotOutput>
) {
  return snapshots.sort((a, b) => {
    const aLastBackupTime = a.riskVulnerabilities || 0
    const bLastBackupTime = b.riskVulnerabilities || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const volumeAName = a.volumeId || a.volumeAwsId
    const volumeBName = b.volumeId || b.volumeAwsId

    return volumeAName.localeCompare(volumeBName)
  })
}

export const useLiveAssetsDataForAssetsVulnerabilities = () => {
  const { data: liveAssetsData, isLoading } = useAssetsLiveQuery()
  return useMemo(() => {
    if (!liveAssetsData) {
      return {
        data: undefined,
        isLoading,
      }
    }

    const { ec2Instances, ebsVolumes, scans, lastBackups, lastEbsSnapshots } =
      liveAssetsData

    const lastBackupsMap = new Map<string, BackupVariant>()
    for (const [key, backup] of lastBackups.backups) {
      lastBackupsMap.set(key, { backup })
    }

    const lastScan: Array<Scan> = scans.flatMap(
      (scan) =>
        scan.ransomwareScan ||
        scan.malwareScan ||
        scan.filesystemCheckScan ||
        []
    )

    return {
      data: {
        volumes: ebsVolumes,
        instances: ec2Instances,
        snapshots: lastEbsSnapshots,
        lastBackups: lastBackupsMap,
        scans: lastScan,
      },
      isLoading,
    }
  }, [liveAssetsData, isLoading])
}

export function getRiskNameFormQuery(
  risk?: string | Array<string>
): RiskLevel | undefined {
  switch (risk) {
    case '0':
      return RiskLevel.Low
    case '1':
      return RiskLevel.Medium
    case '2':
      return RiskLevel.High

    default:
      return undefined
  }
}

export function calculateTotalVulnerabilitiesByRisk({
  volumeVulnerabilitiesList,
  snapshotVulnerabilitiesList,
}: {
  volumeVulnerabilitiesList: Array<AwsVolumeVulnerability>
  snapshotVulnerabilitiesList: Array<AwsSnapshotVulnerability>
}) {
  const summaryVulnerabilities = [
    ...volumeVulnerabilitiesList,
    ...snapshotVulnerabilitiesList,
  ]

  return summaryVulnerabilities.reduce(
    (total, vulnerability) => {
      const riskLevel =
        new VulnerabilitiesDetectionStrategy().identifyRiskLevel(
          vulnerability.kind
        )

      switch (riskLevel) {
        case RiskLevel.High:
          return {
            ...total,
            high: total.high + vulnerability.total,
          }
        case RiskLevel.Medium:
          return {
            ...total,
            medium: total.medium + vulnerability.total,
          }
        case RiskLevel.Low:
          return {
            ...total,
            low: total.low + vulnerability.total,
          }
        default:
          return total
      }
    },
    {
      high: 0,
      medium: 0,
      low: 0,
    }
  )
}

export function getRiskName(risk?: string | Array<string>): string {
  switch (risk) {
    case '0':
      return 'Low'
    case '1':
      return 'Medium'
    case '2':
      return 'High'
    default:
      return 'High'
  }
}
