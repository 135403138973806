/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { EBS, EC2 } from 'blues-corejs/dist'
import { useAssetsMap } from '@lib/hooks/api-hooks/assets'
import { useEntropyFiltersActions } from '../entropy-trends-details-store/entropy-trends-details-store.context'
import { StyledVolumeSelect } from './styles'

interface Props {
  asset: EC2
}

function renderSelectionText(volumeId: unknown, volumeList: Array<EBS>) {
  const selectedVolume = volumeList.find((volume) => volume.id === volumeId)
  if (selectedVolume) {
    return (
      <Typography fontSize="14px" variant="caption">
        {selectedVolume.awsId}
      </Typography>
    )
  }

  return (
    <Typography fontSize="14px" variant="caption" color="#999CAA">
      EBS Volume
    </Typography>
  )
}

export function VolumeSelection({ asset }: Props) {
  const { assetsList } = useAssetsMap([...asset.ebsIds])
  const [selectedVolumeId, setSelectedVolumeId] = useState<string | null>(null)

  const volumesList = assetsList.filter(
    (currentAsset) => currentAsset instanceof EBS
  ) as Array<EBS>

  const { fetchEntropyTrendsHistory } = useEntropyFiltersActions()

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newSelectedVolumeId = event.target.value as string

    const newSelectedVolume = volumesList.find(
      (volume) => volume.id === newSelectedVolumeId
    )

    if (!newSelectedVolume) {
      fetchEntropyTrendsHistory({
        assetIdsList: [...asset.ebsIds, asset.id],
      })

      setSelectedVolumeId(null)
      return
    }

    setSelectedVolumeId(newSelectedVolumeId)
    fetchEntropyTrendsHistory({
      assetIdsList: [newSelectedVolume.id],
    })
  }

  const selectValue = selectedVolumeId || ''

  if (!volumesList.length) {
    return null
  }

  return (
    <StyledVolumeSelect
      displayEmpty
      renderValue={(value) => renderSelectionText(value, volumesList)}
      value={selectValue}
      onChange={handleChange}
    >
      <MenuItem value="">All</MenuItem>
      {volumesList.map((volume) => (
        <MenuItem key={volume.id} value={volume.id}>
          {volume.name ? `${volume.name} (${volume.awsId})` : volume.awsId}
        </MenuItem>
      ))}
    </StyledVolumeSelect>
  )
}
