import { removeParamsSuffix } from './remove-params-suffix'

const pdfFormatMapping: Record<number, string> = {
  0: 'PDF',
}

const pdfXlsxFormatMapping: Record<number, string> = {
  0: 'PDF',
  1: 'XLSX',
}

const csvFormatMapping: Record<number, string> = {
  0: 'CSV',
}

const supportedReportFormats: Record<string, Record<string, string>> = {
  ovaMspOperationalReport: pdfXlsxFormatMapping,
  ovaMspExecutiveReport: pdfFormatMapping,
  ovaMspTenantSummaryReport: pdfFormatMapping,
  ovaMspAccountSummaryReport: pdfFormatMapping,
  ovaMspCompletedScansReport: csvFormatMapping,
  ovaMspScannedAssetsSummaryReport: csvFormatMapping,
  ovaMspInfectedAssetsReport: csvFormatMapping,
  ovaMspInfectedFilesReport: csvFormatMapping,
  ovaMspFailedScansReport: csvFormatMapping,
  awsExecutiveReport: pdfFormatMapping,
  awsTenantSummaryReport: pdfFormatMapping,
  awsAccountSummaryReport: pdfFormatMapping,
  awsCompletedScansReport: csvFormatMapping,
  awsScannedAssetsSummaryReport: csvFormatMapping,
  awsInfectedAssetsReport: csvFormatMapping,
  infectedFilesReport: csvFormatMapping,
  failedScansReport: csvFormatMapping,
}

export function mapBackendFormatCodesToUI(
  reportType: string,
  formatsList: any
): Array<string> {
  if (!reportType) {
    return []
  }

  const formatMap = supportedReportFormats[removeParamsSuffix(reportType)]
  if (!formatMap) {
    throw new Error(`Unknown report type: ${reportType}`)
  }

  return formatsList.map((formatCode: any) => {
    const formatName = formatMap[formatCode]
    if (!formatName) {
      const availableFormats = Object.values(formatMap).join(', ')
      throw new Error(
        `Unknown format code ${formatCode} for report type ${reportType}. Available formats: ${availableFormats}`
      )
    }
    return formatName
  })
}
