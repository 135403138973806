import { useEffect } from 'react'

interface PageTitleOwnProps {
  title: string
}

export default function PageTitle({ title }: PageTitleOwnProps) {
  useEffect(() => {
    document.title = title
  }, [title])

  return null
}
