/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Backup, FilesystemScanCheck } from 'blues-corejs/dist'
import { Scan } from 'blues-corejs/dist/models/scans/scan'
import FsCheckIcon from '@inline-img/general/fs-check-icon'
import {
  useComputeFilesystemScanStatusByBackup,
  useComputeFilesystemStatusScans,
} from './use-compute-scans-statuses'

const StyledBox = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  marginRight: '5px',
})

function FsCheckIconByScans({ scans }: { scans: Array<Scan> }) {
  const fsCheckScans = scans.filter(
    (scan): scan is FilesystemScanCheck => scan instanceof FilesystemScanCheck
  )

  const computedScanStatus = useComputeFilesystemStatusScans(fsCheckScans)

  if (!computedScanStatus) {
    return null
  }

  const { tooltipText, color, fill } = computedScanStatus

  return (
    <Tooltip title={tooltipText} placement="top">
      <StyledBox bgcolor={color} data-testid="threat-icon">
        <FsCheckIcon width="24px" height="24px" fill={fill} />
      </StyledBox>
    </Tooltip>
  )
}

function FsCheckIconByBackup({ backup }: { backup: Backup }) {
  const { color, fill, tooltipText } =
    useComputeFilesystemScanStatusByBackup(backup)

  return (
    <Tooltip title={tooltipText} placement="top">
      <StyledBox bgcolor={color} data-testid="threat-icon">
        <FsCheckIcon width="24px" height="24px" fill={fill} />
      </StyledBox>
    </Tooltip>
  )
}

interface FsCheckBlockProps {
  backup?: Backup
  scans?: Array<Scan>
}

export function FsCheckBlock({ backup, scans }: FsCheckBlockProps) {
  if (backup) {
    return <FsCheckIconByBackup backup={backup} />
  }

  if (scans) {
    return <FsCheckIconByScans scans={scans} />
  }

  return null
}
