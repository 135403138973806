import { CircularProgress, Stack, Typography } from '@mui/material'

interface LoadingDisplayProps {
  message?: string
}

const LoadingDisplay = ({ message }: LoadingDisplayProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        py: 4,
      }}
      spacing={2}
    >
      <CircularProgress />
      {message ? <Typography>{message}</Typography> : null}
    </Stack>
  )
}

export default LoadingDisplay
