import {
  DEFAULT_VIEWS,
  TabViewInterface,
  ViewKind,
} from '@features/DashboardV4/management-view'
import { CURRENT_VIEW_VERSION } from '@features/DashboardV4/management-view/consts'
import { UserSettings } from '@lib/constants'
import { StrHelper } from '@lib/helpers'
import { ForbiddenCharactersValidator } from '@lib/models/validator/name.validator'
import SettingsService from '@lib/services/high/settings.service'
import { useLayoutContext } from '@features/contexts'
import { useEffect } from 'react'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { DashboardRoutes } from '@lib/router/routes/dashboard/dashboard'
import {
  convertTagsToTuples,
  DashboardViewForm,
  dashboardViewTypes,
  removeEmptyValuesFromObject,
  useViewData,
} from '@features/management-view'

export function useCreateView() {
  const navigation = useNavigation()

  const { setHeaderTitle } = useLayoutContext()

  useEffect(() => {
    setHeaderTitle('Add dashboard view')
  }, [])

  const { views, handleSubmit, ...formValues } = useViewData()

  async function handleCreateView(data: DashboardViewForm) {
    const formData: TabViewInterface = removeEmptyValuesFromObject({
      name: data.name,
      id: StrHelper.generateUUID(),
      variant: dashboardViewTypes[data.dashboardView],
      slice: {
        accountIds: data.accounts,
        regions: data.regions,
        include_tags: {
          tags: convertTagsToTuples(data.includingTags),
          operator: Number(data.includingTagsOperation),
        },
        exclude_tags: {
          tags: convertTagsToTuples(data.excludingTags),
          operator: Number(data.excludingTagsOperation),
        },
      },
      version: CURRENT_VIEW_VERSION,
      isDefaultDashboard: false,
      kind: ViewKind.AWS,
    })

    const newViews = [...views, formData]

    await SettingsService.updateSetting(UserSettings.TabViews, newViews)

    const viewIndex = [...DEFAULT_VIEWS, ...newViews].findIndex(
      (item) => item.id === formData.id
    )

    navigation.push(DashboardRoutes.rootTab(String(viewIndex)))
  }

  function validateName(value: string) {
    const validator = new ForbiddenCharactersValidator(value, 'Name')

    if (!validator.validate()) {
      return validator.errorMessage
    }

    const isNameAlreadyExist = views.some((view) => view.name === value)

    if (isNameAlreadyExist) {
      return 'This name is already exist'
    }
    return true
  }

  return {
    ...formValues,
    handleCreateView: handleSubmit(handleCreateView),
    validateName,
  }
}
