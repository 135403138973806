import Box from '@mui/material/Box'
import {
  Label,
  MaxWidthFormControl,
  NestedSelect,
  NestedOptions,
} from '@features/alerts/create-rule-drawer/shared'
import { useRuleDrawerDataState } from '@features/alerts/create-rule-drawer'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'

export const transformOvaAccountsToNestedOptions = (
  nestedOptions: NestedOptions,
  accountsName: Map<string, string>
): Array<any> => {
  const sampleOptions: Array<any> = []

  nestedOptions.forEach((backupProviders, accountName) => {
    const accountId = accountsName.get(accountName)
    const children = Array.from(backupProviders).map((providerKey) => {
      const readableName =
        // @ts-ignore
        HUMAN_READABLE_OVA_BACKUP_PROVIDER[providerKey]?.toUpperCase() ||
        providerKey
      return {
        id: `${accountId}||${readableName}`,
        name: readableName,
      }
    })

    sampleOptions.push({
      id: accountId,
      name: accountName,
      children,
    })
  })

  return sampleOptions
}

function OnPrem({ name: nameProp }: { name: string }) {
  const nestedOptions: NestedOptions = new Map<string, Set<string>>()
  const accountsName: Map<string, string> = new Map<string, string>()
  const { listOvaAccounts } = useRuleDrawerDataState()

  listOvaAccounts.forEach(
    ({
      name,
      backupProvidersList,
      id,
    }: {
      name: string
      backupProvidersList: Array<number>
      id: string
    }) => {
      const convertedSet = new Set(backupProvidersList.map(String))
      nestedOptions.set(name, convertedSet)
      accountsName.set(name, id)
    }
  )

  return (
    <Box>
      <Label>Select accounts and backup providers</Label>
      <MaxWidthFormControl maxWidth="35%">
        <NestedSelect
          options={transformOvaAccountsToNestedOptions(
            nestedOptions,
            accountsName
          )}
          name={nameProp}
        />
      </MaxWidthFormControl>
    </Box>
  )
}

export default OnPrem
