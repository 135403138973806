import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import Stepper from '@components-simple/stepper/Stepper'
import { SelectedVariant, TabAssetType } from '@lib/constants/app.constant'
import { IntegrityCheckOptions } from '@lib/constants/integrity-check-options.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import useStepper from '@lib/hooks/useStepper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { addPolicy } from '@store/actions/add-policy.action'
import {
  getAddIntegrityScan,
  getIntegrityCheck,
  getIntegrityScanOption,
  getSelectedVariant,
} from '@store/selectors/add-policy.selector'
import {
  getAssetTypeTabSelectedNum,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import {
  AssetsSelection,
  CloudConnectorsSelection,
  PolicySchedule,
  ProtectionOptions,
  VaultsSelection,
} from './policy-creation-steps'
import { AddPolicyMenu } from './add-policy-menu'
import { useInitializeInitialStateForAddPolicy } from './use-initialize-initial-state-for-add-policy'
import { PoliciesRoutes, useNavigation } from '@lib/router'

enum AddPolicySteps {
  FREQUENCY,
  ISCAN,
  CLOUD_CONNECTORS,
  ASSETS,
  VAULTS,
}

const POLICY_STEPS = [
  '1. Name and Schedule',
  '2. Protection Options',
  '3. Policy Scope',
  '4. Assets',
  '5. Vaults',
]

function AddPolicyComplex() {
  const dispatch = useDispatch()
  const router = useNavigation()
  const integrityScan = useSelector(getAddIntegrityScan)
  const integrityScanOption = useSelector(getIntegrityScanOption)
  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const selectedVariant = useSelector(getSelectedVariant)
  const integrityCheck = useSelector(getIntegrityCheck)
  const selectedTypeVariant = useSelector(getAssetTypeTabSelectedNum)

  const loading = usePreloaderAny([
    PreloaderConstants.ADD_POLICIES,
    PreloaderConstants.GET_ALL_RED_STACKS,
  ])

  const [isS3TableErrors, setIsS3TableErrors] = useState<boolean>(false)
  const [isEfsTableErrors, setIsEfsTableErrors] = useState<boolean>(false)

  const [isPolicyNameValid, setIsPolicyNameValid] = useState(false)

  const [currentStep, moveForward, moveBackward] = useStepper(
    AddPolicySteps.FREQUENCY
  )

  // disable Next button if integrity check is not defined properly
  const isIntegrityCheckStep = currentStep === AddPolicySteps.ISCAN
  const isIntegrityScanOptionSelected =
    integrityScanOption === IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO ||
    integrityScanOption === IntegrityScanOptions.SCAN_WITH_ELASTIO

  const isInegrityScanWithoutVariants =
    integrityCheck === IntegrityCheckOptions.INTEGRITY_CHECK_DISABLED &&
    !integrityScan.scanForMalware &&
    !integrityScan.scanForRansomware

  const isNotIntegrityCheckDefined =
    isIntegrityCheckStep &&
    isIntegrityScanOptionSelected &&
    isInegrityScanWithoutVariants

  const nextBtnDisabled: boolean =
    !isPolicyNameValid || loading || isNotIntegrityCheckDefined

  const handleAssetsSelectionProblems = (
    isS3Invalid: boolean,
    isEFSInvalid: boolean
  ) => {
    setIsS3TableErrors(isS3Invalid)
    setIsEfsTableErrors(isEFSInvalid)
  }

  // now we can activate if at least one asset is selected or EC2/EBS selected
  const isAssetsStep = currentStep === AddPolicySteps.ASSETS
  const isAssetsVariant = selectedVariant === SelectedVariant.AssetsVariant
  const isNoSelectedAssets = selectedAssets.length === 0
  const isS3VariantWithTableError =
    selectedTypeVariant === TabAssetType.S3 && isS3TableErrors
  const isEfsVariantWithTableError =
    selectedTypeVariant === TabAssetType.EFS && isEfsTableErrors

  const addPolicyDisabled: boolean =
    loading ||
    (isNoSelectedAssets && isAssetsStep && isAssetsVariant) ||
    (isS3VariantWithTableError && isAssetsStep) ||
    (isEfsVariantWithTableError && isAssetsStep)

  const isLastStep = currentStep === AddPolicySteps.VAULTS

  const onAddPolicySubmit = () => {
    dispatch(addPolicy(false))
    router.push(PoliciesRoutes.root)
  }

  const onAddPolicyExecute = () => {
    dispatch(addPolicy(true))
    router.push(PoliciesRoutes.root)
  }

  useInitializeInitialStateForAddPolicy()

  return (
    <Box className="innerContent innerContentBlueBackground jsAddPolicyPage">
      <Box className="wrap-1641914510134">
        <Stepper currentStep={currentStep} labels={POLICY_STEPS}>
          <PolicySchedule
            isLoading={loading}
            couldGoNextStep={setIsPolicyNameValid}
          />
          <ProtectionOptions
            isNotEc2AndEbsAssetType={
              selectedTypeVariant !== TabAssetType.Ec2andEbs
            }
          />
          <CloudConnectorsSelection
            isLoading={loading}
            isAssetsVariant={isAssetsVariant}
          />
          <AssetsSelection
            isLoading={loading}
            isAssetsSelectionHasProblems={handleAssetsSelectionProblems}
          />
          <VaultsSelection isLoading={loading} />
        </Stepper>

        <BasePortalButtons alwaysInPortal>
          <Box className="portalBetweenButtons">
            <Stack direction="row" spacing={1}>
              {currentStep === 0 ? (
                <Button
                  onClick={() => router.push(PoliciesRoutes.root)}
                  variant="text"
                  color="primary"
                  className="showButton jsCloseAddPolicyPage"
                  data-testid="cancel-button"
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  className="showButton ml8 jsAddPolicyBackButton"
                  data-testid="back-button"
                  disabled={currentStep === 0}
                  onClick={moveBackward}
                >
                  Back
                </Button>
              )}
            </Stack>
            {isLastStep ? (
              <AddPolicyMenu
                onAddPolicySubmit={onAddPolicySubmit}
                onAddPolicyExecute={onAddPolicyExecute}
                addPolicyDisabled={addPolicyDisabled}
              />
            ) : (
              <Button
                disabled={nextBtnDisabled}
                onClick={moveForward}
                variant="contained"
                color="primary"
                data-testid="next-button"
                className="jsAddPolicyNext"
              >
                Next
              </Button>
            )}
          </Box>
        </BasePortalButtons>
      </Box>
    </Box>
  )
}

export default AddPolicyComplex
