import { useCallback, useEffect, useState } from 'react'
import {
  useNavigate,
  useSearchParams,
  useLocation,
  createSearchParams,
} from 'react-router-dom'

interface UseQueryTabs {
  tab: number
  setTab: (newTab: number) => void
}

const getTabFromSearchParams = (
  searchParams: URLSearchParams,
  defaultTab: number
): number => {
  const queryTab = parseInt(searchParams.get('t') || '')
  return isNaN(queryTab) ? defaultTab : queryTab
}

export function useQueryTabs(defaultTab: number): UseQueryTabs {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const [tab, setTab] = useState<number>(() =>
    getTabFromSearchParams(searchParams, defaultTab)
  )
  const navigate = useNavigate()

  useEffect(() => {
    const currentTab = getTabFromSearchParams(searchParams, defaultTab)
    if (currentTab !== tab) {
      setTab(currentTab)
    }
  }, [defaultTab, tab, searchParams])

  const changeTab = useCallback(
    (newTab: number) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        t: newTab.toString(),
      })

      navigate({
        pathname,
        search: updatedParams.toString(),
      })
      setTab(newTab)
    },
    [pathname]
  )

  return {
    tab,
    setTab: changeTab,
  }
}
