import { ColorSystemOptions } from '@mui/material'

export const lightColorSystem: ColorSystemOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1AA8EA',
      contrastText: '#fff',
    },
    accent: {
      main: '#FF1654',
    },
    background: {
      default: '#f7f9fc',
      secondary: '#fff', // Used for navbar and sidebar
      tertiary: '#fff',
      paper: '#fff',
      drawer: '#fff',
      dialog: '#f1f1f1',
      hover: '#fff',
    },
    text: {
      primary: '#50565c',
      secondary: '#85888c',
      muted: '#878d91',
    },
    icon: {
      main: '#85888c',
    },
    border: {
      main: '#e4dbe940',
    },
    grey: {
      700: '#ccccdc33',
      800: '#f9f9fb',
    },
    success: {
      main: '#50C56A',
      dark: '#2FAD0999',
    },
    error: {
      main: '#ed093f',
      dark: '#94193b',
    },
    warning: {
      main: '#d9ad14',
      dark: '#947919',
    },
  },
}
