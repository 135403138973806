import ArrHelper from '@lib/helpers/arr.helper'
import { useEffect, useState } from 'react'

export function useDnDforArray<T>(data: Array<T>) {
  const [startIndex, setStartIndex] = useState(-1)
  const [draggedArray, setDraggedArray] = useState(Array.from(data))

  const [nativeArray, setNativeArray] = useState(Array.from(data))

  const onDragStart = (index: number) => {
    setStartIndex(index)
  }

  const onDragEnd = (index: number) => {
    const array = Array.from(draggedArray)

    const swappedValues = ArrHelper.swapValues(
      array,
      startIndex,
      index
    ) as Array<T>

    setDraggedArray(swappedValues)
    setStartIndex(index)
  }

  const onDragOver = (e: React.DragEvent<HTMLElement>) => e.preventDefault()

  useEffect(() => {
    setDraggedArray(data)
  }, [JSON.stringify(data)])

  useEffect(() => {
    setNativeArray(data)
  }, [JSON.stringify(data)])

  return {
    onDragStart,
    onDragEnd,
    onDragOver,
    draggedArray,
    setNativeArray,
    setDraggedArray,
    nativeArray,
  }
}
