/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { CellWithButtonLink } from '@components/table'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

interface Props {
  timestamp: Date
  onTimestampClick: () => void
}

function RecoveryPointCompletionTimeCell({
  timestamp,
  onTimestampClick,
}: Props) {
  return (
    <CellWithButtonLink onClick={onTimestampClick}>
      <DateTimeTooltip placement="top-start" date={timestamp} direction="row" />
    </CellWithButtonLink>
  )
}

export { RecoveryPointCompletionTimeCell }
