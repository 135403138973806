import React from 'react'
import CheckboxBlock from '@components-simple/checkbox-block/CheckboxBlock'
import { EngineCallback } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface Props {
  text: string
  data: Array<ValueInterface>
  onChange: EngineCallback<any>
  className?: string
}

function NotificationsCheckboxBlock({
  text,
  data,
  onChange,
  className = '',
}: Props) {
  return (
    <Box className={`notificationErrors ${className}`}>
      {text && (
        <Typography marginBottom="20px" fontWeight={600} fontSize="13px">
          {text}
        </Typography>
      )}
      <CheckboxBlock data={data} onChange={onChange} />
    </Box>
  )
}

export default NotificationsCheckboxBlock
