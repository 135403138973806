import Box from '@mui/material/Box'
import {
  Label,
  NestedSelect,
  MaxWidthFormControl,
  transformNestedOptions,
} from '@features/alerts/create-rule-drawer/shared'
import AssetList from './asset-list'
import { useRuleDrawerDataState } from '@features/alerts/create-rule-drawer'

function AssetSelector({ name }: { name: string }) {
  const { allRedstacks } = useRuleDrawerDataState()

  const redstackAccountRegions = transformNestedOptions(allRedstacks)

  return (
    <Box>
      <Label>Select Accounts & Regions</Label>
      <MaxWidthFormControl maxWidth="30%">
        <NestedSelect options={redstackAccountRegions} name={name} />
      </MaxWidthFormControl>
      <AssetList />
    </Box>
  )
}

export default AssetSelector
