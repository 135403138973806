import { Skeleton, Box } from '@mui/material'

export function ScaleInfoSkeletonLoader() {
  return (
    <Box display="flex" flexDirection="column" width="50%" alignItems="center">
      <Skeleton variant="text" height={36} width={80} />
      <Skeleton variant="text" height={26} width={100} />
    </Box>
  )
}
