/* eslint-disable import/no-extraneous-dependencies */
import { styled, Typography } from '@mui/material'
import {
  Asset,
  EBS,
  EC2,
  EFS,
  GenericHost,
  OvaServer,
  S3Bucket,
} from 'blues-corejs/dist'

const StyledTypography = styled(Typography)({
  fontSize: '12px',
})

const EMPTY_ASSET_KIND = '-'

function computeAssetKind(asset: Asset): string {
  if (asset instanceof EC2) {
    return 'EC2'
  }

  if (asset instanceof EBS) {
    return 'EBS'
  }

  if (asset instanceof S3Bucket) {
    return 'S3'
  }

  if (asset instanceof EFS) {
    return 'EFS'
  }

  if (asset instanceof GenericHost) {
    return 'Generic Host'
  }

  if (asset instanceof OvaServer) {
    return 'Server'
  }

  console.error('Unknown asset kind')
  return ''
}

interface Props {
  asset?: Asset
}

export function AssetKindCell({ asset }: Props) {
  if (!asset) {
    return <StyledTypography>{EMPTY_ASSET_KIND}</StyledTypography>
  }

  const kind = computeAssetKind(asset)

  return <StyledTypography>{kind}</StyledTypography>
}
