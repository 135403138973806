import { useQuery } from '@tanstack/react-query'
import { TimeRange } from 'ui-v2/src/lib/models/types'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { JobsClient } from 'ui-v2/src/lib/clients'

export function useCloudConnectorJobFiltersQuery(timeRange: TimeRange) {
  const jobsClient = useGrpcClient(JobsClient)

  return useQuery({
    queryKey: ['jobs', 'cloud-connector', 'filters', timeRange],
    queryFn: () => {
      if (!jobsClient) {
        throw new Error('Jobs client not initialized')
      }

      return jobsClient.getCloudConnectorJobsFilters(timeRange)
    },
    enabled: !!jobsClient,
  })
}
