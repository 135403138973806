import Label from 'ui-v2/src/components/ui/data-display/label'
import {
  Dialog,
  DialogHeader,
  DialogContent,
} from 'ui-v2/src/components/ui/dialog'
import DialogActions from 'ui-v2/src/components/ui/dialog/actions'
import Button from 'ui-v2/src/components/ui/inputs/button'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import {
  SETTINGS_ROUTE_SEGMENTS,
  USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { FormControl, Grid2, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useInviteUserMutation } from 'ui-v2/src/hooks/queries/users'
import { useListRolesQuery } from 'ui-v2/src/hooks/queries/rbac'
import { useToast } from 'ui-v2/src/hooks/toast'
import RegexConstants from 'ui-v2/src/lib/constants/regex.constant'

interface FormValues {
  email: string
}

const AddUserDialog = () => {
  const { action } = useParams()
  const navigate = useNavigate()

  const { data: rolesData } = useListRolesQuery()
  const { mutate, isPending } = useInviteUserMutation()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()
  const toast = useToast()

  const closeDialog = () => {
    navigate(
      `/settings/${SETTINGS_ROUTE_SEGMENTS.USERS_AND_ACCESS}/${USERS_ACCESS_SETTINGS_ROUTE_SEGMENTS.USERS}`
    )
  }

  const onSubmit = async (data: FormValues) => {
    if (!rolesData || !rolesData[0]) {
      toast.error(
        'Something went wrong while inviting user, please try again later.'
      )
      return
    }

    mutate(
      {
        userEmail: data.email,
        roleId: rolesData[0].id,
      },
      {
        onSuccess: () => {
          closeDialog()
        },
      }
    )
  }

  return (
    <Dialog open={!!action} onClose={closeDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader handleCloseDialog={closeDialog} title="Add New User" />
        <DialogContent>
          <Stack spacing={2}>
            <Grid2 container spacing={2}>
              <Grid2 size={12}>
                <FormControl fullWidth>
                  <Label>Email</Label>
                  <TextField
                    placeholder="Email address"
                    size="small"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: RegexConstants.EMAIL,
                        message: 'Invalid email address',
                      },
                    })}
                  />
                </FormControl>
              </Grid2>
            </Grid2>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            color="inherit"
            onClick={closeDialog}
            type="button"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            type="submit"
            isLoading={isPending}
          >
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddUserDialog
