import { SvgIcon, SvgIconProps } from '@mui/material'

const Dashboard = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      color={props.color}
      sx={{
        width: size,
        height: size,
        fill: 'none',
        strokeWidth: 1.5,
        ...props.sx,
      }}
      {...props}
    >
      <g clipPath="url(#clip0_40000070_1138)">
        <path
          d="M10 12.5H4C3.72614 12.5 3.5 12.2739 3.5 12V4C3.5 3.72614 3.72614 3.5 4 3.5H10C10.2739 3.5 10.5 3.72614 10.5 4V12C10.5 12.2739 10.2739 12.5 10 12.5ZM10 20.5H4C3.72614 20.5 3.5 20.2739 3.5 20V16C3.5 15.7261 3.72614 15.5 4 15.5H10C10.2739 15.5 10.5 15.7261 10.5 16V20C10.5 20.2739 10.2739 20.5 10 20.5ZM20 20.5H14C13.7261 20.5 13.5 20.2739 13.5 20V12C13.5 11.7261 13.7261 11.5 14 11.5H20C20.2739 11.5 20.5 11.7261 20.5 12V20C20.5 20.2739 20.2739 20.5 20 20.5ZM13.5 8V4C13.5 3.72614 13.7261 3.5 14 3.5H20C20.2739 3.5 20.5 3.72614 20.5 4V8C20.5 8.27386 20.2739 8.5 20 8.5H14C13.7261 8.5 13.5 8.27386 13.5 8Z"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_40000070_1138">
          <rect width={size} height={size} />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default Dashboard
