import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Option } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/types'

type WebhooksListOwnProps = {
  webhooks: Array<Option>
}

function WebhookList({ webhooks = [] }: WebhooksListOwnProps) {
  if (webhooks.length === 0) {
    return <Box component="p">No webhooks available.</Box>
  }

  return (
    <>
      {webhooks.map(({ label }, index) => (
        <Typography sx={{ fontSize: '14px' }} key={index}>
          {label}
        </Typography>
      ))}
    </>
  )
}

export default WebhookList
