/* eslint-disable import/no-extraneous-dependencies */
import { Dayjs } from 'dayjs'
import {
  EBS as EBSType,
  EC2 as EC2Type,
  EFS,
  S3Bucket,
} from 'blues-corejs/dist'

export type ProtectionPolicyCoverageState = 0 | 1

export enum PolicyCoverageState {
  COVERED = 0,
  NOT_COVERED = 1,
}
export enum AssetsTab {
  EC2 = 0,
  EBS = 1,
  S3_BUCKET = 2,
  SERVERS = 3,
  GENERIC_HOST = 4,
}

export interface AssetsFilters<T> {
  filters: T
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setPageToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export interface GeneratedReportsFilters<T> {
  startDate: Dayjs
  endDate: Dayjs
  filters: T
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setPageToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export interface GeneratedScanListFilters<T> {
  filters: T
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setPageToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export type ScheduleScanAsset = EC2Type | EBSType | S3Bucket | EFS
