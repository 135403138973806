import React, { FunctionComponent, useState } from 'react'
import {
  EngineCallback,
  EngineCallbackPure,
  VIMatrix,
  VINumberMap,
  VIRow,
} from '@lib/engine-types'
import ControlledVIHead from '@tables/head-vi/ControlledVIHead'
import MenuVIRow from '@tables/rows-vi/MenuVIRow'
import { MenuAvailability } from '@tables/core/table-menu'
import ShowMoreVIRow from '@tables/rows-vi/ShowMoreVIRow'

interface SettingsClickData {
  index: number
  value: boolean
}

interface HeadComponentData {
  data: VIRow
  onSettingsClick: EngineCallback<SettingsClickData>
  columnState: Array<number>
  forceTemplate?: Array<number>
  skipTemplate?: Array<number>
}

interface RowComponentData {
  data: VIRow
  columnState: Array<number>
  forceTemplate?: Array<number>
  menu: Array<string>
  onMenuClick: EngineCallback<OnMenuClickData>
  showMenu: boolean
  columnDrawMapper?: VINumberMap
  menuIsAvailable?: MenuAvailability<string>
  index: number
}

interface OnMenuClickData {
  chosenMenu: string
  data: VIRow
}

interface FooterComponentData {
  data?: VIRow
  colCount: number
  onFooterClick?: EngineCallbackPure
  isShowFooter?: boolean
}

interface Props {
  head: VIRow
  rows: VIMatrix
  className?: string
  menu?: Array<string> | ((v: VIRow) => Array<string>)
  forceTemplate?: Array<number>
  skipTemplate?: Array<number>
  onMenuClick: EngineCallback<OnMenuClickData>
  headComponent?: FunctionComponent<HeadComponentData>
  rowComponent?: FunctionComponent<RowComponentData>
  columnDrawMapper?: VINumberMap
  menuAvailability?: Array<MenuAvailability<string>>
  footerComponent?: FunctionComponent<FooterComponentData>
}

function ControlledVITable({
  head,
  rows,
  className = '',
  menu = [],
  forceTemplate,
  skipTemplate,
  onMenuClick,
  headComponent = ControlledVIHead,
  rowComponent = MenuVIRow,
  footerComponent = ShowMoreVIRow,
  columnDrawMapper = {},
  menuAvailability = [],
}: Props) {
  const [columnState, setColumnState] = useState<Array<number>>(
    head.map((_v, index) =>
      forceTemplate ? Number(forceTemplate[index] ?? 0) : 1
    )
  )
  const onSettingsClick = (data: SettingsClickData) => {
    const result = [...columnState]
    result[data.index] = +data.value
    setColumnState(result)
  }
  const HeadComponent = headComponent
  const RowComponent = rowComponent
  const FooterComponent = footerComponent
  return (
    <table
      className={`wrap-1602747175897 shrinkLastColumn ${className} jsControlledVITable`}
    >
      <thead>
        <HeadComponent
          columnState={columnState}
          onSettingsClick={onSettingsClick}
          data={head}
          forceTemplate={forceTemplate}
          skipTemplate={skipTemplate}
        />
      </thead>
      <tbody>
        {rows.map((v: VIRow, index: number) => (
          <RowComponent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data={v}
            menu={Array.isArray(menu) ? menu : menu(v)}
            onMenuClick={onMenuClick}
            columnState={columnState}
            forceTemplate={forceTemplate}
            columnDrawMapper={columnDrawMapper}
            showMenu={
              v[0]?.options ? (!v[0]?.options[0]?.disabled ?? true) : true
            }
            menuIsAvailable={menuAvailability[index] || (() => true)}
            index={index}
          />
        ))}
        <FooterComponent colCount={head.length} />
      </tbody>
    </table>
  )
}

export default ControlledVITable
