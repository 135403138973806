"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnDemandOrigin = exports.ScheduledOrigin = void 0;
class ScheduledOrigin {
    #id;
    #name;
    #description;
    constructor(args) {
        this.#id = args.id;
        this.#name = args.name;
        this.#description = args.description;
    }
    get id() {
        return this.#id;
    }
    get name() {
        return this.#name;
    }
    get description() {
        return this.#description;
    }
}
exports.ScheduledOrigin = ScheduledOrigin;
class OnDemandOrigin {
    #description;
    constructor(args) {
        this.#description = args.description;
    }
    get description() {
        return this.#description;
    }
}
exports.OnDemandOrigin = OnDemandOrigin;
