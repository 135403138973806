import vaultNameValidator from '@forms/validators/vaultName.validator'
import FormAddVaultInterface from '@lib/interfaces/form/form-add-vault.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormAddVaultInterface,
  FormFieldInterface
> => ({
  region: {
    name: 'region',
    label: 'Region',
    value: '',
    validationRules: {},
  },

  vpc: {
    name: 'vpc',
    label: 'VPC',
    value: '',
    validationRules: { required: true },
  },

  subnets: {
    name: 'subnet',
    label: 'Subnet',
    value: '',
    validationRules: { required: true },
  },

  vaultName: {
    name: 'vaultName',
    label: 'Vault Name',
    value: '',
    validationRules: {
      required: true,
      validate: vaultNameValidator,
    },
  },
  safetyLock: {
    name: 'safetyLock',
    label: 'Safety Lock',
    value: true,
  },
  redStackId: {
    name: 'redStackId',
  },
})

export default buildDefaultFormData
