import { create } from 'zustand'
/* eslint-disable import/no-extraneous-dependencies */
import { UserProfile } from 'blues-corejs/dist/models'
import { NotificationsClient } from '@lib/clients/notifications'
import { WebhooksListItem as WebhooksListItemPB } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import { ListOvaAccountsClient, RexClient, UsersClient } from '@lib/clients'
import GrpcWebhookService from '@lib/services/grpc/notifications/grpc-webhook.service'
import { CloudConnector } from 'blues-corejs/dist/models'
import { OvaAccount } from 'blues-corejs/dist'

const notificationsClient = new NotificationsClient()

const listOvaAccountsClient = new ListOvaAccountsClient()

const usersClient = new UsersClient()

const rexClient = new RexClient()

export interface RuleDrawerDataState {
  listEventKinds: Array<string> | null
  isLoadingListEventKinds: boolean
  errorListEventKinds: string | null

  listOvaAccounts: Array<OvaAccount>
  isLoadingListOvaAccounts: boolean
  errorListOvaAccounts: string | null

  listUsers: Array<UserProfile>
  isLoadingListUsers: boolean
  errorListUsers: string | null

  allRedstacks: Array<CloudConnector>
  isLoadingAllRedstacks: boolean
  errorAllRedstacks: string | null

  webhooksList: Array<WebhooksListItemPB.AsObject>
  isLoadingWebhooksList: boolean
  errorWebhooksList: string | null

  assetId: string | undefined
}

interface RuleDrawerDataActions {
  fetchListEventKinds: () => Promise<void>
  fetchListOvaAccounts: () => Promise<void>
  fetchListUsers: () => Promise<void>
  fetchAllRedstacks: () => Promise<void>
  fetchWebhooksList: () => Promise<void>
  fetchAllData: () => Promise<void>
  setAssetId: (assetId: string) => void
}

export type RuleDrawerDataStore = RuleDrawerDataState & RuleDrawerDataActions

const INITIAL_STATE: RuleDrawerDataState = {
  listEventKinds: null,
  isLoadingListEventKinds: false,
  errorListEventKinds: null,

  listOvaAccounts: [],
  isLoadingListOvaAccounts: false,
  errorListOvaAccounts: null,

  listUsers: [],
  isLoadingListUsers: false,
  errorListUsers: null,

  allRedstacks: [],
  isLoadingAllRedstacks: false,
  errorAllRedstacks: null,

  webhooksList: [],
  isLoadingWebhooksList: false,
  errorWebhooksList: null,

  assetId: undefined,
}

const useRuleDrawerDataStore = create<RuleDrawerDataStore>((set) => ({
  ...INITIAL_STATE,

  fetchAllRedstacks: async () => {
    set({
      isLoadingAllRedstacks: true,
      errorAllRedstacks: null,
    })

    try {
      const allRedstacks = await rexClient.listGetAllRedstacks()

      set({
        allRedstacks,
        isLoadingAllRedstacks: false,
      })
    } catch (error) {
      console.error('Error fetching Event Kinds via gRPC:', error)
      if (error instanceof Error) {
        set({
          errorAllRedstacks: error.message,
          isLoadingAllRedstacks: false,
        })
      } else {
        set({
          errorAllRedstacks: 'An unknown error occurred.',
          isLoadingAllRedstacks: false,
        })
      }
      throw error
    }
  },

  fetchWebhooksList: async () => {
    set({
      isLoadingWebhooksList: true,
      errorWebhooksList: null,
    })

    try {
      const webhooksList = await GrpcWebhookService.getWebhooksList()

      set({
        webhooksList: webhooksList.map((webhook) => webhook.toObject()),
        isLoadingWebhooksList: false,
      })
    } catch (error) {
      console.error('Error fetching Event Kinds via gRPC:', error)
      if (error instanceof Error) {
        set({
          errorAllRedstacks: error.message,
          isLoadingAllRedstacks: false,
        })
      } else {
        set({
          errorAllRedstacks: 'An unknown error occurred.',
          isLoadingAllRedstacks: false,
        })
      }
      throw error
    }
  },

  fetchListEventKinds: async () => {
    set({
      isLoadingListEventKinds: true,
      errorListEventKinds: null,
    })

    try {
      const listEventKinds = await notificationsClient.getListEventKinds()

      set({
        listEventKinds,
        isLoadingListEventKinds: false,
      })
    } catch (error) {
      console.error('Error fetching Event Kinds via gRPC:', error)
      if (error instanceof Error) {
        set({
          errorListEventKinds: error.message,
          isLoadingListEventKinds: false,
        })
      } else {
        set({
          errorListEventKinds: 'An unknown error occurred.',
          isLoadingListEventKinds: false,
        })
      }
      throw error
    }
  },

  fetchListOvaAccounts: async () => {
    set({
      isLoadingListOvaAccounts: true,
      errorListOvaAccounts: null,
    })

    try {
      const listOvaAccounts = await listOvaAccountsClient.getListOvaAccounts()

      set({
        listOvaAccounts,
        isLoadingListOvaAccounts: false,
      })
    } catch (error) {
      console.error('Error fetching Event Kinds via gRPC:', error)
      if (error instanceof Error) {
        set({
          errorListOvaAccounts: error.message,
          isLoadingListOvaAccounts: false,
        })
      } else {
        set({
          errorListOvaAccounts: 'An unknown error occurred.',
          isLoadingListOvaAccounts: false,
        })
      }
      throw error
    }
  },

  fetchListUsers: async () => {
    set({
      isLoadingListUsers: true,
      errorListUsers: null,
    })

    try {
      const listUsers = await usersClient.getList()

      set({
        listUsers,
        isLoadingListUsers: false,
      })
    } catch (error) {
      console.error('Error fetching Event Kinds via gRPC:', error)
      if (error instanceof Error) {
        set({
          errorListUsers: error.message,
          isLoadingListUsers: false,
        })
      } else {
        set({
          errorListUsers: 'An unknown error occurred.',
          isLoadingListUsers: false,
        })
      }
      throw error
    }
  },

  fetchAllData: async () => {
    set({
      isLoadingListEventKinds: true,
      errorListEventKinds: null,
      isLoadingListOvaAccounts: true,
      errorListOvaAccounts: null,
      isLoadingListUsers: true,
      errorListUsers: null,
      isLoadingWebhooksList: true,
      errorWebhooksList: null,
    })

    try {
      const [eventKinds, ovaAccounts, users, allRedstacks, webhooksList] =
        await Promise.all([
          notificationsClient.getListEventKinds(),
          listOvaAccountsClient.getListOvaAccounts(),
          usersClient.getList(),
          rexClient.listGetAllRedstacks(),
          GrpcWebhookService.getWebhooksList(),
        ])

      set({
        listEventKinds: eventKinds,
        isLoadingListEventKinds: false,
        listOvaAccounts: ovaAccounts,
        isLoadingListOvaAccounts: false,
        listUsers: users,
        isLoadingListUsers: false,
        allRedstacks,
        isLoadingAllRedstacks: false,
        webhooksList: webhooksList.map((webhook) => webhook.toObject()),
        isLoadingWebhooksList: false,
      })
    } catch (error) {
      console.error('Error fetching data via gRPC:', error)

      if (error instanceof Error) {
        set({
          errorListEventKinds: error.message,
          isLoadingListEventKinds: false,
          errorListOvaAccounts: error.message,
          isLoadingListOvaAccounts: false,
          errorListUsers: error.message,
          isLoadingListUsers: false,
          errorAllRedstacks: error.message,
          isLoadingAllRedstacks: false,
          errorWebhooksList: error.message,
          isLoadingWebhooksList: false,
        })
      } else {
        set({
          errorListEventKinds: 'An unknown error occurred.',
          isLoadingListEventKinds: false,
          errorListOvaAccounts: 'An unknown error occurred.',
          isLoadingListOvaAccounts: false,
          errorListUsers: 'An unknown error occurred.',
          isLoadingListUsers: false,
          errorAllRedstacks: 'An unknown error occurred.',
          isLoadingAllRedstacks: false,
          errorWebhooksList: 'An unknown error occurred.',
          isLoadingWebhooksList: false,
        })
      }

      throw error
    }
  },
  setAssetId: async (assetId: string) => {
    set({ assetId })
  },
}))

export const setAssetIdRuleDrawerDataStoreStore = (value: string) => {
  useRuleDrawerDataStore.getState().setAssetId(value)
}

export const useRuleDrawerDataState = () =>
  useRuleDrawerDataStore((state) => ({
    listEventKinds: state.listEventKinds,
    listOvaAccounts: state.listOvaAccounts,
    listUsers: state.listUsers,
    allRedstacks: state.allRedstacks,
    webhooksList: state.webhooksList,
    assetId: state.assetId,
  }))

export const useRuleDrawerDataLoading = () =>
  useRuleDrawerDataStore((state) => ({
    isLoadingListEventKinds: state.isLoadingListEventKinds,
    errorListEventKinds: state.errorListEventKinds,
  }))

export const useRuleDrawerDataActions = () =>
  useRuleDrawerDataStore((state) => ({
    fetchListEventKinds: state.fetchListEventKinds,
    fetchAllData: state.fetchAllData,
  }))

export default useRuleDrawerDataStore
