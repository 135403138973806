import ValueInterface from '@lib/interfaces/value.interface'

abstract class TableHelper {
  // according to children creates parent template of
  // selected values, we use it for marking some columns
  // as force showing
  public static calcSelectedTemplate(
    template: Array<number>,
    filter: Array<ValueInterface>
  ): Array<number> {
    const newTemplate = [...template]
    filter.forEach((f) => {
      if (f.index === undefined || f.options === undefined) {
        return
      }
      const selected = f.options.filter((v) => !!v.value).length > 0
      newTemplate[f.index] = selected ? 1 : 0
    })
    return newTemplate
  }

  public static buildStateTemplateFilterFunc(
    columnState: Array<number>,
    forceTemplate: Array<number> = [],
    delta = 0
  ) {
    // eslint-disable-next-line max-len
    return (_v: any, index: number) =>
      (!!forceTemplate && !!forceTemplate[index + delta]) ||
      !!columnState[index + delta]
  }
}

export default TableHelper
