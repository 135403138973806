/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProvider } from 'blues-corejs/dist'
import { HUMAN_READABLE_OVA_BACKUP_PROVIDER } from '@lib/constants/reports/consts'

class OvaBackupProviderHelper {
  static getProviderName(provider: OvaBackupProvider): string {
    return HUMAN_READABLE_OVA_BACKUP_PROVIDER[provider]
  }
}

export default OvaBackupProviderHelper
