import AccessTokenModel from '@lib/models/access-token.model'

export const getTokenList = (state: any): Array<AccessTokenModel> =>
  state.tokenState.list
// eslint-disable-next-line max-len
export const getRevokedTokenList = (state: any): Array<AccessTokenModel> =>
  state.tokenState.revokedList
export const getEditToken = (state: any): AccessTokenModel =>
  state.tokenState.editToken
export const getSecretToken = (state: any): string =>
  state.tokenState.secretToken
export const getNewToken = (state: any): string => state.tokenState.newToken
export const getUpdatedSuccess = (state: any): boolean =>
  state.tokenState.updatedSuccess
