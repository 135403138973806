import React from 'react'

function SearchIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3646 10.2443C13.3042 7.75334 13.1289 4.14957 10.8391 1.85969C8.35952 -0.619896 4.33928 -0.619896 1.85969 1.85969C-0.619898 4.33928 -0.619898 8.35947 1.85969 10.8391C4.14958 13.129 7.75339 13.3041 10.2443 11.3646C10.255 11.3768 10.2662 11.3887 10.2778 11.4003L13.6451 14.7675C13.9551 15.0775 14.4576 15.0775 14.7676 14.7675C15.0775 14.4576 15.0775 13.9551 14.7676 13.6451L11.4003 10.2779C11.3886 10.2663 11.3767 10.2551 11.3646 10.2443ZM9.66518 2.96634C11.5151 4.81619 11.5151 7.81537 9.66518 9.66527C7.81537 11.5151 4.81616 11.5151 2.96631 9.66527C1.11645 7.81537 1.11645 4.81619 2.96631 2.96634C4.81616 1.11648 7.81537 1.11648 9.66518 2.96634Z"
        fill="#8F92A1"
      />
    </svg>
  )
}

export default SearchIcon
