import { AssetKind } from '@lib/constants/grpc/asset-kind.constant'

function useGetSupportedAssetKinds() {
  {
    /*TODO: hidden S3 and Generic tab 08.02.2024 by task 9480 and 9538*/
  }

  return [
    AssetKind.AWS_EC2,
    AssetKind.AWS_EBS,
    // AssetKind.AWS_S3,
    // AssetKind.GENERIC_HOST,
  ]
}

export default useGetSupportedAssetKinds
