import { PagePathConstant } from '@lib/constants'
import { useNavigation } from '@lib/router/contexts/navigation-context'

interface UseServersDrilldownLink {
  handleRedirectWithDrilldown: () => void
  url?: string
}

interface Props {
  queryKey: string
  queryValue: string
}

export function useServersDrilldownLink({
  queryKey,
  queryValue,
}: Props): UseServersDrilldownLink {
  const router = useNavigation()

  const url = `${PagePathConstant.OVA_SERVER_INVENTORY_TABLE}&${queryKey}=${queryValue}`

  const handleWidgetClick = () => {
    router.push(url)
  }

  return {
    handleRedirectWithDrilldown: handleWidgetClick,
    url,
  }
}
