import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'

import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'

import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'

import { ListCloudConnnectorJobsFilterRequestParams } from 'ui-v2/src/lib/models/monitor'
import {
  MONITOR_FILTER_JOB_TYPE,
  MONITOR_FILTER_MAP,
  MONITOR_FILTER_STATUS,
} from 'ui-v2/src/lib/constants/monitor.constant'
import dayjs, { Dayjs } from 'dayjs'
import { transformDateRangeForApi } from 'ui-v2/src/lib/transformers/reports/transform-date-range-for-api'
import { TimeRangeInSeconds } from 'ui-v2/src/lib/models/types'

export interface JobFilters<T> {
  filters: T
  startDate: Dayjs
  endDate: Dayjs
  timeRange: TimeRangeInSeconds
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setPageToken: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const useMonitorFilters =
  (): JobFilters<ListCloudConnnectorJobsFilterRequestParams> => {
    const [pageSize, setPageSize] = useState<number>(
      TABLE_ROWS_PER_PAGE_OPTIONS[0]
    )
    const [pageToken, setPageToken] = useState<string | undefined>(undefined)
    const [searchParams] = useSearchParams()

    const startDate = searchParams.get('start')
      ? dayjs(searchParams.get('start'))
      : dayjs().startOf('month')
    const endDate = searchParams.get('end')
      ? dayjs(searchParams.get('end'))
      : dayjs()

    const timeRange = transformDateRangeForApi([startDate, endDate])

    useEffect(() => {
      setPageToken(undefined)
    }, [searchParams])

    return useMemo(() => {
      return {
        filters: {
          pageSize,
          pageToken,
          assetIdsList: searchParams
            .get(MONITOR_FILTER_MAP.ASSET)
            ?.split(',')
            .filter(Boolean),
          kindsList: EnumHelper.filterToEnumValues(
            searchParams.get(MONITOR_FILTER_MAP.JOB)?.split(',') ?? [],
            MONITOR_FILTER_JOB_TYPE
          ),
          statusesList: EnumHelper.filterToEnumValues(
            searchParams.get(MONITOR_FILTER_MAP.STATUS)?.split(',') ?? [],
            MONITOR_FILTER_STATUS
          ),
          timeRange,
        },
        startDate: startDate,
        endDate: endDate,
        timeRange: timeRange,
        setPageSize,
        setPageToken,
      }
    }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
  }
