import {
  getShouldRenderAssetItemsTab,
  TabsIds,
  useAssetStore,
} from '@features/asset-page/store'
import Tab from '@mui/material/Tab'
import React from 'react'

export default function AssetItemsTab({
  onTabChange,
}: {
  onTabChange: (tabIndex: TabsIds) => void
}) {
  const shouldRenderAssetItemsTab = useAssetStore(getShouldRenderAssetItemsTab)

  function handleTabChange() {
    onTabChange(TabsIds.ASSET_ITEMS)
  }

  return (
    <Tab
      hidden={!shouldRenderAssetItemsTab}
      onClick={handleTabChange}
      tabIndex={TabsIds.ASSET_ITEMS}
      label="Asset items"
      value={TabsIds.ASSET_ITEMS}
    />
  )
}
