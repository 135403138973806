import { ListAnomaliesRequest as ListAnomaliesRequestGrpc } from '@lib/clients/grpc-imports'
import { ListAnomaliesFilter } from './list-anomalies-filter'
import { ListAnomaliesPagination } from './list-anomalies-pagination'

export type { ListAnomaliesRequestParams } from './list-anomalies-filter'

export interface ListAnomaliesRequest {
  toGrpc(): ListAnomaliesRequestGrpc
}

export { ListAnomaliesPagination, ListAnomaliesFilter }
