import React, { memo, useEffect } from 'react'
import { Typography, Chip, Box } from '@mui/material'
import { ElastioMultipleSelect } from '@components/select'
import ClearIcon from '@mui/icons-material/Clear'
import type { Option } from '@components/select'
import {
  Control,
  Controller,
  RegisterOptions,
  UseFormSetValue,
} from 'react-hook-form'
import { StyledFormHelperText } from './styles'
import {
  TagKeyValidator,
  TagValueValidator,
} from '@lib/models/validator/tags-validator.model'
import { ForbiddenCharactersValidator } from '@lib/models/validator/name.validator'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { DashboardViewForm } from '../types'

interface Props {
  title: string
  options: Array<Option>
  selected: Array<string>
  name: keyof DashboardViewForm
  setValue: UseFormSetValue<DashboardViewForm>
  placeholder?: string
  control?: Control<DashboardViewForm, any> | undefined
  showSearchInput?: boolean
  children?: React.ReactElement | string
  showAddNewValueModal?: boolean
  rules?: RegisterOptions<DashboardViewForm, keyof DashboardViewForm>
  isCreatable?: boolean
}

function SelectWithChips({
  title,
  options: optionsProps,
  selected,
  name,
  setValue,
  placeholder,
  control,
  showSearchInput,
  isCreatable,
  children,
  rules,
}: Props) {
  const [options, setOptions] = React.useState<Array<Option>>(optionsProps)
  const [searchError, setSearchError] = React.useState<string>('')

  const handleDelete = (item: string) => {
    setValue(
      name,
      selected.filter((i) => i !== item)
    )
  }

  // React Hook Form really hard to type-safe
  // see: https://github.com/react-hook-form/strictly-typed/issues/9#issuecomment-659822933
  const handleAddNewValue = (
    value: string,
    onChange: (event: Array<any>) => void
  ): boolean => {
    if (!isCreatable) {
      return false
    }

    setSearchError('')

    const inputValueValidator = new ForbiddenCharactersValidator(value, 'Value')

    if (!inputValueValidator.validate()) {
      setSearchError(inputValueValidator.errorMessage)
      return false
    }

    const newValue = value.indexOf('=') === -1 ? `${value}=*` : value

    if (options.some((item) => item.value === newValue)) {
      setSearchError('This value already exists')
      return false
    }

    const [key, tagValue] = newValue.split('=') as [string, string]

    const keyValidator = new TagKeyValidator(key)
    const valueValidator = new TagValueValidator(tagValue)

    if (!keyValidator.validate()) {
      setSearchError(keyValidator.errorMessage)
      return false
    }

    if (!valueValidator.validate()) {
      setSearchError(valueValidator.errorMessage)
      return false
    }

    setOptions([
      {
        value: newValue,
        label: newValue,
      },
      ...options,
    ])

    onChange([...selected, newValue])

    return true
  }

  useEffect(() => {
    setOptions(optionsProps)
  }, [optionsProps.length])

  return (
    <React.Fragment>
      <Typography
        variant="body1"
        sx={{
          marginTop: '16px',
          marginBottom: Boolean(children) ? '0' : '16px',
          lineHeight: '180%',
        }}
        fontSize="16px"
        fontWeight="500"
      >
        {title}
      </Typography>
      {children}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <React.Fragment>
            <ElastioMultipleSelect
              // @ts-ignore
              field={field}
              options={options}
              inputError={searchError}
              selected={selected}
              placeholder={placeholder}
              onCreateNewValue={(value) =>
                handleAddNewValue(value, field.onChange)
              }
              showSearchInput={showSearchInput}
              onChange={field.onChange}
              isCreatable={isCreatable}
              onClose={() => setSearchError('')}
            />
            {!!error && (
              <StyledFormHelperText error>{error.message}</StyledFormHelperText>
            )}
          </React.Fragment>
        )}
      />
      {selected.length > 0 && (
        <Box
          sx={{
            marginTop: '16px',
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
          }}
        >
          {selected.map((item) => (
            <Chip
              key={item}
              label={<LongTextTooltip text={item ?? ''} />}
              onDelete={() => handleDelete(item)}
              deleteIcon={
                <ClearIcon
                  sx={{
                    fontSize: '16px !important',
                    color: 'black !important',
                    marginRight: '12px !important',
                  }}
                />
              }
              sx={{
                backgroundColor: '#E4F7FF',
                fontWeight: 500,
              }}
            />
          ))}
        </Box>
      )}
    </React.Fragment>
  )
}

export default memo(SelectWithChips)
