import { resetNotificationsStore } from './notifications/notifications-store'
import { resetUseEmailStore } from './notifications/email-selection/email-store'
import { resetRuleDetailsStore } from './rule-details/rule-details-store'
import { resetInfrastructureScopeStore } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope'
import { resetAssetTagSelectionStore } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/asset-tag-selection/asset-tag-selection-store'
import { resetReviewEnableStore } from '@features/alerts/create-rule-drawer/stepper/steps/review-enable'
import { resetStepperStore } from '@features/alerts/create-rule-drawer/stepper/stepper-store'

export const resetAllStores = () => {
  resetNotificationsStore()
  resetRuleDetailsStore()
  resetInfrastructureScopeStore()
  resetReviewEnableStore()
  resetStepperStore()
  resetAssetTagSelectionStore()
  resetUseEmailStore()
}
