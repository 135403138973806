/* eslint-disable import/no-extraneous-dependencies */
import { InventoryS3BucketOutput } from 'blues-corejs/dist'

/**
 * Sorts to last scan first, if no last scan, sort by bucket name.
 */
export function initialPrioritySortForS3Table(
  buckets: Array<InventoryS3BucketOutput>
) {
  return buckets.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const bucketAName = a.name || a.awsId
    const bucketBName = b.name || b.awsId

    return bucketAName.localeCompare(bucketBName)
  })
}
