import React, { useCallback } from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import RedStackModel from '@lib/models/red-stack.model'
import LangHelper from '@lib/helpers/lang.helper'
import { Button } from '@mui/material'

interface Props {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<Array<RedStackModel>>
  data: Nullable<Array<RedStackModel>>
  show: boolean
}

function ReconnectModal({ data, onSubmit, onCancel, show }: Props) {
  const onSubmitCallback = useCallback(() => {
    onSubmit(data ?? [])
  }, [data])

  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  let regionsLabel = ''
  if (data) {
    regionsLabel = data
      .map((r: RedStackModel) => r.awsRegion)
      .map((regionName: string) =>
        LangHelper.getAwsRegionSingleTranslation(regionName)
      )
      .join(', ')
  }

  return (
    <BaseModal onBgClick={onCancelCallback} show={show}>
      <div className="modalHeader">Confirmation</div>
      <div className="modalContent">
        You are going to reconnect to these regions:
        <div
          className="mb20 mt20 jsContentRegions fontBold"
          data-testid="jsContentRegions"
        >
          {regionsLabel}
        </div>
        Please confirm the action in order to proceed
      </div>
      <div className="modalControlBlock">
        <Button
          className="jsReconnectModalYes"
          data-testid="jsReconnectModalYes"
          color="primary"
          variant="contained"
          onClick={onSubmitCallback}
          type="button"
        >
          Yes, reconnect
        </Button>
        <Button
          className="jsReconnectModalNo"
          data-testid="jsReconnectModalNo"
          variant="text"
          onClick={onCancelCallback}
          type="button"
        >
          No, cancel
        </Button>
      </div>
    </BaseModal>
  )
}

export default ReconnectModal
