import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const PROPS_TO_FORWARD = ['ransomwareLabelColor', 'cursor']

export const WidgetContainer = styled(Box, {
  shouldForwardProp: (prop: string) => !PROPS_TO_FORWARD.includes(prop),
})<{ cursor: string }>(({ cursor }) => ({
  cursor,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}))

export const RansomwareFoundLabel = styled(Typography, {
  shouldForwardProp: (prop: string) => !PROPS_TO_FORWARD.includes(prop),
})<{
  ransomwareLabelColor: string
}>(({ ransomwareLabelColor }) => ({
  color: ransomwareLabelColor,
  fontSize: '22px',
  fontWeight: '600',
  textAlign: 'right',
  minWidth: '30px',
}))

export const MalwareFoundLabel = styled(Typography, {
  shouldForwardProp: (prop: string) => !PROPS_TO_FORWARD.includes(prop),
})<{
  ransomwareLabelColor: string
}>(({ ransomwareLabelColor }) => ({
  color: ransomwareLabelColor,
  fontSize: '22px',
  fontWeight: '600',
  textAlign: 'right',
  minWidth: '30px',
}))
