import Grid from '@mui/material/Grid2'
import { Stack, FormControl, Typography } from '@mui/material'

import { Controller, useFormContext } from 'react-hook-form'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'

import FrequencyConstant from 'ui-v2/src/lib/constants/frequency.constant'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'

import TimepickerInput from 'ui-v2/src/components/ui/inputs/timepicker-input'
import DayMonthSelector from './day-month-selector'
import DayOfWeekConstant from 'ui-v2/src/lib/constants/day-of-week.constant'
import Label from 'ui-v2/src/components/ui/data-display/label'
import TimeZoneSelector from 'ui-v2/src/components/ui/inputs/time-zone-select'
import FirstRun from './first-run'
import {
  determineShowDayOfMonthInput,
  determineShowDayOfWeekInput,
  determineShowOnlyStart,
  determineShowWindowInputs,
} from 'ui-v2/src/lib/helpers/policies.helper'

const minuteStep = 15

const Schedule = () => {
  const { control, setValue, watch } = useFormContext<PolicyFormData>()
  const scheduleData = watch('schedule')
  const timezone = watch('timezone')
  const showWindowInputs = determineShowWindowInputs(scheduleData.frequency)
  const showDayOfWeekInput = determineShowDayOfWeekInput(scheduleData.frequency)
  const showDayOfMonthInput = determineShowDayOfMonthInput(
    scheduleData.frequency
  )
  const showOnlyStart = determineShowOnlyStart(scheduleData.frequency)

  const frequencyOptions = FrequencyConstant.getAllValid().map((option) => ({
    label: option.name,
    value: option.value?.toString() as string,
    extraValue: option.extraValue,
  }))

  const dayOfWeekOptions = DayOfWeekConstant.getAllValid().map((option) => ({
    label: option.name,
    value: option.value?.toString() as string,
  }))

  return (
    <Grid container rowSpacing={2} columnSpacing={4}>
      <Grid size={6}>
        <FormControl fullWidth size="small">
          <Typography variant="body1" mb={1}>
            Schedule Frequency
          </Typography>
          <Controller
            control={control}
            name="schedule"
            render={({ field }) => (
              <BasicSelect
                value={field.value.frequency.value?.toString() as string}
                onChange={(frequency) => {
                  if (!frequency || typeof frequency !== 'object') {
                    return
                  }

                  field.onChange({
                    ...field.value,
                    frequency: {
                      name: frequency.label,
                      value: +frequency.value,
                      extraValue: frequency.extraValue,
                    },
                    windowStart: {
                      ...field.value.windowStart,
                      timezone: timezone.value,
                    },
                    windowEnd: {
                      ...field.value.windowEnd,
                      timezone: timezone.value,
                    },
                  })
                }}
                options={frequencyOptions}
                size="small"
                isSelectedObj
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid size={12}>
        {!!showWindowInputs && (
          <Stack direction={'row'} spacing={4}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <TimepickerInput
                    {...field}
                    label="Protection window start"
                    value={field.value.windowStart}
                    onChange={(windowStart) => {
                      field.onChange({
                        ...field.value,
                        windowStart: {
                          ...windowStart,
                          timezone: timezone.value,
                        },
                        windowEnd: {
                          ...field.value.windowEnd,
                          timezone: timezone.value,
                        },
                      })
                    }}
                    minuteStep={minuteStep}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <TimepickerInput
                    label="Protection window end"
                    value={field.value.windowEnd}
                    onChange={(windowEnd) => {
                      field.onChange({
                        ...field.value,
                        windowStart: {
                          ...field.value.windowStart,
                          timezone: timezone.value,
                        },
                        windowEnd: {
                          ...windowEnd,
                          timezone: timezone.value,
                        },
                      })
                    }}
                    minuteStep={minuteStep}
                  />
                )}
              />
            </FormControl>
          </Stack>
        )}
        {!!showOnlyStart && (
          <Controller
            control={control}
            name="schedule"
            render={({ field }) => (
              <TimepickerInput
                {...field}
                label="Protection window start"
                value={field.value.windowStart}
                onChange={(windowStart) => {
                  field.onChange({
                    ...field.value,
                    windowStart: {
                      ...windowStart,
                      timezone: timezone.value,
                    },
                  })
                }}
                minuteStep={minuteStep}
              />
            )}
          />
        )}
        {!!showDayOfWeekInput && (
          <Stack direction={'row'} spacing={4}>
            <FormControl fullWidth>
              <Label variant="body1" fontWeight={0}>
                Select day to backup
              </Label>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <BasicSelect
                    value={field.value.dayOfWeek.value?.toString() as string}
                    onChange={(dayOfWeek) => {
                      if (typeof dayOfWeek !== 'object') {
                        return
                      }

                      field.onChange({
                        ...field.value,
                        dayOfWeek,
                      })
                    }}
                    options={dayOfWeekOptions}
                    size="small"
                    isSelectedObj
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <TimepickerInput
                    {...field}
                    label="Protection window start"
                    value={field.value.windowStart}
                    onChange={(windowStart) => {
                      field.onChange({
                        ...field.value,
                        windowStart: {
                          ...windowStart,
                          timezone: timezone.value,
                        },
                      })
                    }}
                    minuteStep={minuteStep}
                  />
                )}
              />
            </FormControl>
          </Stack>
        )}
        {!!showDayOfMonthInput && (
          <Stack direction={'row'} spacing={4}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <DayMonthSelector
                    label="On"
                    value={field.value.dayOfMonth}
                    onChange={(dayOfMonth) =>
                      field.onChange({
                        ...field.value,
                        dayOfMonth,
                      })
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="schedule"
                render={({ field }) => (
                  <TimepickerInput
                    {...field}
                    label="Protection window start"
                    value={field.value.windowStart}
                    onChange={(windowStart) => {
                      field.onChange({
                        ...field.value,
                        windowStart: {
                          ...windowStart,
                          timezone: timezone.value,
                        },
                      })
                    }}
                    minuteStep={minuteStep}
                  />
                )}
              />
            </FormControl>
          </Stack>
        )}
      </Grid>
      <Grid size={12}>
        <Stack direction={'row'} spacing={4}>
          <FormControl fullWidth>
            <Typography variant="body1" mb={1}>
              Time Zone
            </Typography>
            <Controller
              control={control}
              name="schedule"
              render={({ field }) => (
                <TimeZoneSelector
                  value={field.value.windowStart.timezone}
                  onChange={(tz) => {
                    setValue('timezone', tz)
                    field.onChange({
                      ...field.value,
                      windowStart: {
                        ...field.value.windowStart,
                        timezone: tz.value,
                      },
                      windowEnd: {
                        ...field.value.windowEnd,
                        timezone: tz.value,
                      },
                    })
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
      </Grid>
      <FirstRun />
    </Grid>
  )
}

export default Schedule
