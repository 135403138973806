import {
  EngineCallback,
  EngineCallbackPure,
  ValueObject,
} from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormAddUserInterface from '@lib/interfaces/form/form-add-user.interface'
import React, { memo, useEffect, useMemo } from 'react'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import CustomTextField from '@forms/fields/custom-text-field'
import UserRoleModel from '@lib/models/user-role.model'
import ValueHelper from '@lib/helpers/value.helper'
import buildDefaultFormData from './data'
import { Button } from '@mui/material'
import SystemHelper from '@lib/helpers/system.helper'

interface Props {
  possibleRoles: Array<UserRoleModel>
  defaultValues?: ValueObject<FormAddUserInterface>
  onSubmit: EngineCallback<FormAddUserInterface>
  onCancel: EngineCallbackPure
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
}

function AddUserForm({
  possibleRoles,
  defaultValues,
  onSubmit,
  onCancel,
  loading: isLoadingForm,
  errorGroups,
}: Props) {
  const possibleVIRoles = possibleRoles.map(ValueHelper.fromRole)
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )
  const {
    setValue,
    isFormDisabled,
    register,
    submit,
    formState,
    globalFormError,
    watch,
  } = useGeneralForm(
    currentForm,
    onSubmit,
    errorGroups,
    isLoadingForm,
    false,
    'all'
  )

  // we suppose that we always have at least one role
  const role = possibleVIRoles[0] ?? { name: '' }
  useEffect(() => {
    setValue('roleId', role.value)
  }, [role])

  FormHelper.fillErrors(formState.errors, currentForm)

  if (possibleRoles.length === 0) {
    SystemHelper.throwErrorInLocalEnv('Possible roles were not provided')
  }

  const emailValue = watch(currentForm.email.name) ?? ''
  const isEmailEmptyOrHaveError = useMemo(() => {
    const { email } = currentForm

    const emailErrorMessage = (() => {
      const errorMessage = formState.errors[email.name]?.message
      return typeof errorMessage === 'string' ? errorMessage : ''
    })()

    return (
      globalFormError.length > 0 ||
      emailErrorMessage.length > 0 ||
      emailValue.length === 0
    )
  }, [
    formState.errors[currentForm.email.name]?.message,
    globalFormError,
    emailValue,
  ])

  return (
    <form
      className={clsx('wrap-1617284626632', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      <input
        type="hidden"
        {...register(
          currentForm.roleId.name,
          currentForm.roleId.validationRules
        )}
      />

      <div className="newFormSingleRow v2StaticTextInput v2StaticTextInputModal">
        <CustomTextField
          field={currentForm.email}
          disabled={isFormDisabled}
          {...register(
            currentForm.email.name,
            currentForm.email.validationRules
          )}
        />
      </div>
      {/*TODO: hidden 09.02.2024 by ticket #9484*/}
      {/*<div className="newFormSingleRow jsRole">*/}
      {/*  <RolesSelect*/}
      {/*    possible={possibleVIRoles}*/}
      {/*    selected={role}*/}
      {/*    onChangeSelected={setRole}*/}
      {/*    label={currentForm.roleId.label}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="blueRadioSelected brsLast">
        Send user an email invitation
      </div>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}

      <div className="modalFooterV2Row">
        <Button
          disabled={isLoadingForm}
          className="mfCancel"
          data-testid="jsAddUserCancel"
          type="button"
          variant="text"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoadingForm || isEmailEmptyOrHaveError}
          className="mfSubmit"
          data-testid="jsAddUserSubmit"
          color="primary"
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  )
}

export default memo(AddUserForm)
