/* eslint-disable import/no-extraneous-dependencies */
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export const DEPLOYMENT_JOB_KINDS = [
  TenantJobKind.INSTALL_CLOUD_CONNECTOR,
  TenantJobKind.ACTIVATE_CLOUD_CONNECTOR,
  TenantJobKind.UPGRADE_CLOUD_CONNECTOR,
  TenantJobKind.REPAIR_CLOUD_CONNECTOR,
  TenantJobKind.CREATE_VAULT,
  TenantJobKind.REPAIR_VAULT,
  TenantJobKind.DESTROY_VAULT,
  TenantJobKind.UPDATE_VAULT_SUBNETS,
  TenantJobKind.DELETE_CLOUD_CONNECTOR,
  TenantJobKind.ENABLE_ELASTIO_REPORTS_GATHERING,
  TenantJobKind.DISABLE_ELASTIO_REPORTS_GATHERING,
  TenantJobKind.REPAIR_REPORT_GATHERING_INFRA,
]

export const NO_JOBS_MESSAGE = 'No Jobs yet!'
