import { useMemo, useState } from 'react'
import {
  IconButton,
  Stack,
  Typography,
  Box,
  ListItem,
  List,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer, DrawerContent } from 'ui-v2/src/components/ui/drawer'
import AwsAccount from './aws-form/aws-account'
import AwsAccountElastio from './aws-form/aws-account-elastio'
import Regions from './aws-form/regions'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

import { FormProvider, useForm } from 'react-hook-form'
import { SourceFormData } from 'ui-v2/src/lib/models/settings/sources/sources'
import { useDiscoverInstalledCfnQuery } from 'ui-v2/src/hooks/queries/red-stack'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { CfnStatusConstant } from 'ui-v2/src/lib/constants/cfn-status.constant'
import CfnFactory from 'ui-v2/src/lib/models/red-stack/cfn.factory'
import CfnModel from 'ui-v2/src/lib/models/red-stack/cfn.model'

const steps = 3

const AwsDrawer = () => {
  const { action } = useParams()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState<number>(0)

  const [previousEmittedAccountId, setPreviousEmittedAccountId] =
    useState<string>('')

  const [cfn, setCfn] = useState<CfnModel>(CfnFactory.buildEmpty())

  const method = useForm<SourceFormData>({
    defaultValues: {
      awsAccountId: '',
      region: { name: '' },
    },
  })

  const {
    mutate: discoverInstalledCfnMutate,
    isPending: discoverInstalledCfnIsPending,
  } = useDiscoverInstalledCfnQuery()

  const isPending = discoverInstalledCfnIsPending

  const { handleSubmit, reset } = method

  const handleCloseDrawer = () => {
    setActiveStep(0)
    setPreviousEmittedAccountId('')
    setCfn(CfnFactory.buildEmpty())
    reset()
    navigate(
      `/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}`
    )
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const submit = (sourceFormData: SourceFormData) => {
    console.log(sourceFormData)
    if (activeStep === steps - 1) {
    } else {
      handleNext()
    }
  }

  const disabled = useMemo(() => {
    if (activeStep === 0 && cfn.isEmpty) {
      return true
    }
    return false
  }, [activeStep, cfn])

  const onAccountIdChange = (awsAccountId: string) => {
    discoverInstalledCfnMutate(awsAccountId, {
      onSuccess: (data) => {
        if (CfnStatusConstant.INSTALLED === data.status) {
          // requestPossibleVpcList()
        }
        setCfn(data)
      },
      onError: (error) => {
        console.log(error.message)
      },
    })
  }

  return (
    <Drawer anchor="right" open={!!action} onClose={handleCloseDrawer}>
      <DrawerContent>
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(submit)}>
            <List sx={{ paddingX: 0 }}>
              <ListItem
                sx={{
                  paddingX: 2.5,
                  paddingRight: '220px',
                }}
                secondaryAction={
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    {activeStep > 0 && (
                      <Button
                        type="button"
                        color="inherit"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      isLoading={isPending}
                      disabled={disabled}
                    >
                      {activeStep !== steps - 1 ? 'Next' : 'Grant'}
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleCloseDrawer}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                }
              >
                <Typography fontWeight={700} fontSize={22}>
                  {activeStep === 0 && 'Enter your AWS account ID'}
                  {activeStep === 1 && 'Enable your AWS account with Elastio'}
                  {activeStep === 2 && 'Select Regions'} ({activeStep + 1}/3)
                </Typography>
              </ListItem>
            </List>
            <Stack
              spacing={2}
              sx={{
                paddingX: 2.5,
                paddingBottom: 2.5,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  padding: 0,
                }}
              >
                <Box>
                  {activeStep === 0 && (
                    <AwsAccount
                      previousEmittedAccountId={previousEmittedAccountId}
                      setPreviousEmittedAccountId={setPreviousEmittedAccountId}
                      onAccountIdChange={onAccountIdChange}
                    />
                  )}
                  {activeStep === 1 && <AwsAccountElastio cfn={cfn} />}
                  {activeStep === 2 && <Regions />}
                  {/* <AwsAccount onAccountIdChange={onAccountIdChange} /> */}
                  {/* <AwsAccountElastio cfn={cfn} /> */}
                  {/* <Regions /> */}
                </Box>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </DrawerContent>
    </Drawer>
  )
}

export default AwsDrawer
