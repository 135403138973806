/* eslint-disable import/no-extraneous-dependencies */
import { Table } from '@components/table'
import { TabsBlockWrapper } from '@components-complex/dashboard-pages-v3/styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PreloaderConstants from '@lib/constants/preloader.constant'
import usePreloader from '@lib/hooks/usePreloader'
import {
  requestLiveAssetsSelect,
  resetS3SelectedData,
  setAssetsSelectSearch,
  setS3AssetsSelectFilters,
} from '@store/actions/assets-select.action'
import {
  getAssetTypeTabSelectedNum,
  getS3AssetsSelect,
  getS3SelectedAllPaths,
  getS3SelectedSpecificPaths,
} from '@store/selectors/assets-select.selector'
import AddSpecificPathsModal from '@components-composite/modals/AddSpecificPathsModal'
import FilterV3 from '@components-complex/filter-v3/FilterV3'
import { Row } from '@tanstack/react-table'
import { PathsSubRow } from '@features/policies/policy/policy-tables/paths-sub-row'
import {
  useS3Filter,
  getS3PolicyTableColumnDefinitions,
  useS3SelectPath,
  useS3SelectRows,
} from '@features/policies/policy/policy-tables/s3-for-policy'
import {
  isSelectedAllPathsForAsset,
  isSelectedSpecificPathForAsset,
} from '@features/policies/policy/policy-tables'
import { AssetWithRelatedAssets } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { S3Bucket } from 'blues-corejs/dist'
import { VIRow } from '@lib/engine-types'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getIsLiveAssetsLoading } from '@store/selectors/list-live-assets.selector'

interface Props {
  onS3TableErrorChange: React.Dispatch<React.SetStateAction<boolean>>
  initialSelectedFilters: VIRow
}

function S3ForPolicy({ onS3TableErrorChange, initialSelectedFilters }: Props) {
  const dispatch = useDispatch()
  const s3Assets = useSelector(getS3AssetsSelect)
  const selectedSpecificPaths = useSelector(getS3SelectedSpecificPaths)
  const selectedAllPaths = useSelector(getS3SelectedAllPaths)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)
  const isTableLoading =
    usePreloader(PreloaderConstants.REQUEST_ASSETS_SELECT_INVENTORY) ||
    isLiveAssetsLoading

  const {
    possibleFilters,
    selectedFilters,
    searchVal,
    handleSearchChange,
    onFiltersChange,
  } = useS3Filter()

  const {
    selectedRows,
    setSelectedRows,
    expandedRows,
    setExpandedRow,
    onSelectPathsClick,
    pathsAddModalProps,
    onSelectAllPaths,
    handleDeletePath,
  } = useS3SelectPath({ s3Assets })

  const { handleS3Select, handleSelectAllS3Rows } = useS3SelectRows({
    s3Assets,
    onSelectAllPaths,
  })

  useEffect(() => {
    dispatch(resetS3SelectedData())
    dispatch(setS3AssetsSelectFilters(initialSelectedFilters))
    dispatch(requestLiveAssetsSelect())
    dispatch(setAssetsSelectSearch(''))
    dispatch(getAllRedStacks())
  }, [])

  const selectedTypeVariant = useSelector(getAssetTypeTabSelectedNum)

  useEffect(() => {
    if (s3Assets.length > 0) {
      let errors = s3Assets.reduce((errCount, item, index) => {
        const isSelected = selectedRows[index]

        const asset = item.asset
        const isSelectedAllPath = isSelectedAllPathsForAsset(
          selectedAllPaths,
          asset.id
        )
        const isSelectedSpecificPath = isSelectedSpecificPathForAsset(
          selectedSpecificPaths,
          asset.id
        )

        const isAnyPathSelected = isSelectedAllPath || isSelectedSpecificPath

        if (isSelected && !isAnyPathSelected) {
          return errCount + 1
        }
        return errCount
      }, 0)
      const isNotAnyS3AssetsSelected = !Object.keys(selectedRows).length

      if (isNotAnyS3AssetsSelected) {
        errors++
      }

      onS3TableErrorChange(errors > 0)
    }
  }, [
    s3Assets,
    selectedTypeVariant,
    selectedSpecificPaths,
    selectedAllPaths,
    selectedRows,
  ])

  return (
    <TabsBlockWrapper>
      {!isTableLoading && (
        <FilterV3
          possibleFilters={possibleFilters}
          selectedFilters={selectedFilters}
          onFiltersChange={onFiltersChange}
          onSearchChange={handleSearchChange}
          searchVal={searchVal}
          turnOnSearch
          disabled={isTableLoading}
          searchPlaceholder="Search by Asset ID"
          finalLoading={isTableLoading}
          hideClearAllBtn={true}
        />
      )}
      <Table
        columns={getS3PolicyTableColumnDefinitions({
          handleS3Select,
          onSelectAllPaths,
          handleSelectPathsClick: onSelectPathsClick,
          selectedSpecificPaths,
          selectedAllPaths,
          handleSelectAllS3Rows,
        })}
        data={s3Assets}
        generalProps={{
          noDataMessage: 'No S3 found',
          isLoading: isTableLoading,
        }}
        handlers={{
          rowsSelection: {
            selectedRows: selectedRows,
            setSelectedRows: setSelectedRows,
          },
          rowsExpanded: {
            expandedRows: expandedRows,
            setExpandedRows: setExpandedRow,
          },
        }}
        advancedProps={{
          renderSubComponent: ({
            row,
          }: {
            row: Row<AssetWithRelatedAssets<S3Bucket>>
          }) => {
            const { id } = row.original.asset
            return (
              <PathsSubRow
                assetId={id}
                selectedSpecificPaths={selectedSpecificPaths}
                selectedAllPaths={selectedAllPaths}
                onDeletePath={handleDeletePath}
              />
            )
          },
          warningState: ({
            row,
          }: {
            row: Row<AssetWithRelatedAssets<S3Bucket>>
          }) => {
            const { asset } = row.original
            const isSelected = row.getIsSelected()
            const isSelectedAllPath = isSelectedAllPathsForAsset(
              selectedAllPaths,
              asset.id
            )
            const isSelectedSpecificPath = isSelectedSpecificPathForAsset(
              selectedSpecificPaths,
              asset.id
            )
            return isSelected && !isSelectedAllPath && !isSelectedSpecificPath
          },
        }}
      />
      <AddSpecificPathsModal {...pathsAddModalProps} loading={false} />
    </TabsBlockWrapper>
  )
}

export default S3ForPolicy
