import { ValueInterface } from '../engine-types'
import BaseDataConstant from './base-data.constant'

class DayOfWeekConstantInner extends BaseDataConstant<ValueInterface> {
  get DAY_UNDEFINED(): ValueInterface {
    return {
      name: 'Undefined',
      value: -1,
    }
  }

  get DAY_SUNDAY(): ValueInterface {
    return {
      name: 'Sunday',
      value: 0,
    }
  }

  get DAY_MONDAY(): ValueInterface {
    return {
      name: 'Monday',
      value: 1,
    }
  }

  get DAY_TUESDAY(): ValueInterface {
    return {
      name: 'Tuesday',
      value: 2,
    }
  }

  get DAY_WEDNESDAY(): ValueInterface {
    return {
      name: 'Wednesday',
      value: 3,
    }
  }

  get DAY_THURSDAY(): ValueInterface {
    return {
      name: 'Thursday',
      value: 4,
    }
  }

  get DAY_FRIDAY(): ValueInterface {
    return {
      name: 'Friday',
      value: 5,
    }
  }

  get DAY_SATURDAY(): ValueInterface {
    return {
      name: 'Saturday',
      value: 6,
    }
  }
}

const DayOfWeekConstant = DayOfWeekConstantInner.build(
  DayOfWeekConstantInner,
  'DAY_'
)

export default DayOfWeekConstant
