import { PagePathConstant } from '@lib/constants'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export function useAuthentication(shouldHandleAuth: boolean): {
  isAuthenticated: boolean
  isLoading: boolean
} {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0()
  useEffect(() => {
    if (!shouldHandleAuth || isLoading) {
      return
    }
    // TODO: check this
    if (error) {
      window.location.href = PagePathConstant.INIT_ERROR
      return
    }

    if (!isAuthenticated) {
      const { pathname, search } = window.location

      loginWithRedirect({
        appState: {
          returnTo: `${pathname}${search}`,
        },
      })
    }
  }, [shouldHandleAuth, isAuthenticated, isLoading])

  return {
    isAuthenticated,
    isLoading,
  }
}
