/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  ColumnWidth,
  createColumn,
  createDisplayColumn,
  StatusCircle,
} from '@components/table'

import { StrHelper } from '@lib/helpers'
import {
  CellWithDateTimeTooltip,
  CellWithLink,
} from '@components/table/core/cell'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import {
  Backup,
  EC2State,
  InstanceOutput,
  OsKind,
  RiskLevel,
} from 'blues-corejs/dist'
import LangHelper from '@lib/helpers/lang.helper'
import { Nullable } from '@lib/engine-types'
import { TABLE_MISCONFIGURATIONS_ROW_MIN_HEIGHT } from './consts'
import { AssetRoutes } from '@lib/router'

export const instancesColumns = [
  createDisplayColumn<InstanceOutput>({
    id: 'status',
    size: 40,
    cell: () => <StatusCircle isHealthy={false} />,
  }),
  createColumn<InstanceOutput>('instanceId', {
    header: 'Instance',
    meta: {
      height: TABLE_MISCONFIGURATIONS_ROW_MIN_HEIGHT,
    },
    cell: (props) => {
      const instanceId = props.getValue()
      const { instanceName, instanceAwsId } = props.row.original
      const assetPageUrl = AssetRoutes.assetId(instanceId)

      const displayedValue = instanceName || instanceAwsId
      const displayedLabel = instanceName ? instanceAwsId : instanceName
      return (
        <CellWithLink
          href={assetPageUrl}
          value={displayedValue}
          label={displayedLabel}
        />
      )
    },
  }),
  createColumn<InstanceOutput>('volumeVulnerabilities', {
    header: 'Misconfiguration',
    meta: {
      size: ColumnWidth.LARGE,
    },
    cell: (props) => {
      const vulnerabilities = props.getValue() as unknown as Array<string>

      return (
        <LongTextTooltip text={vulnerabilities.join(', ')} maxLength={60} />
      )
    },
  }),
  createColumn<InstanceOutput>('riskVulnerabilities', {
    header: 'Risk',
    cell: (props) => RiskLevel[Number(props.getValue())],
  }),
  createColumn<InstanceOutput>('accountId', {
    header: 'Account ID',
  }),
  createColumn<InstanceOutput>('region', {
    header: 'Region',
    cell: (props) => LangHelper.getAwsRegionSingleTranslation(props.getValue()),
  }),
  createColumn<InstanceOutput>('osKind', {
    header: 'OS Type',
    cell: (props) => {
      // TODO: find out why its  string
      const osKind = props.getValue() as unknown as OsKind

      return osKind
    },
  }),
  createColumn<InstanceOutput>('attachedVolumesCount', {
    header: 'Attached Volumes',
    meta: {
      textAlign: 'right',
      size: ColumnWidth.SMALL,
      whiteSpace: 'normal',
    },
  }),
  createColumn<InstanceOutput>('instanceState', {
    header: 'Status',
    cell: (props) => {
      const instanceState = props.getValue() as unknown as EC2State
      return <>{StrHelper.getEc2StateName(instanceState)}</>
    },
  }),

  createColumn<InstanceOutput>('lastBackup', {
    header: 'Last Backup',
    meta: {
      textAlign: 'right',
    },
    cell: ({ getValue }) => {
      const lastBackup = getValue() as unknown as Nullable<{ backup: Backup }>

      if (!lastBackup?.backup) {
        return null
      }

      return <CellWithDateTimeTooltip date={lastBackup.backup.timestamp} />
    },
  }),
]
