import { Typography, TypographyOwnProps } from '@mui/material'

const DrawerSubHeader: React.FC<TypographyOwnProps> = (props) => {
  return (
    <Typography variant="h6" mb={0.5} {...props}>
      {props.children}
    </Typography>
  )
}

export default DrawerSubHeader
