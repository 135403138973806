import { Dialog as MuiDialog, DialogProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface CustomDialogProps extends DialogProps {
  blur?: number
}

const StyledDialog = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== 'blur',
})<CustomDialogProps>(({ theme, blur = 3 }) => ({
  '& .MuiDialog-paper': {
    background: theme.palette.background.dialog,
  },
  '& .MuiBackdrop-root': {
    backdropFilter: `blur(${blur}px)`,
  },
}))

const Dialog = ({ children, ...props }: CustomDialogProps) => {
  return (
    <StyledDialog fullWidth maxWidth="xs" scroll="paper" {...props}>
      {children}
    </StyledDialog>
  )
}

export default Dialog
