import Drawer from '@mui/material/Drawer'
import { useInfoModalStore } from '@features/alerts/store/alerts-info-modal-store'
import RuleDetailsHeader from '@features/alerts/modals/rule-details/rule-details-header'
import { Box } from '@mui/material'
import RuleDetails from '@features/alerts/modals/rule-details/rule-details'

function RuleInfoModal() {
  const { isOpen, setOpen, setRule } = useInfoModalStore()

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        setRule(undefined)
        setOpen(false)
      }}
      PaperProps={{
        sx: {
          width: '40%',
          minWidth: '640px',
        },
      }}
    >
      <Box>
        <RuleDetailsHeader />
        <RuleDetails />
      </Box>
    </Drawer>
  )
}

export default RuleInfoModal
