import React from 'react'

function CircleStatusIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 120 120"
    >
      <circle
        cx="60"
        cy="60"
        r="50"
        fill="none"
        stroke="#DFEAF5"
        strokeWidth="20"
      />
      <circle
        className="scMainCircle"
        cx="60"
        cy="60"
        r="50"
        fill="none"
        strokeDashoffset="0"
        strokeDasharray="314"
        stroke="#27AE60"
        strokeWidth="20"
      />
      <path
        className="successIcon"
        fill="none"
        transform="scale(5.5) translate(6, 7)"
        d="M8.33341 1.5L3.75008 6.08333L1.66675 4"
        stroke="#27AE60"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="errorIcon"
        fill="#EB5757"
        transform="scale(3.5) translate(9, 7)"
        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
      />
    </svg>
  )
}

export default CircleStatusIcon
