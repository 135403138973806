import React, { useRef } from 'react'
import {
  createEntropyTrendsDetailsStore,
  EntropyFiltersZustandStore,
} from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store'
import { EntropyFiltersContext } from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store.context'

type BearProviderProps = React.PropsWithChildren<{
  startAt: number
  endAt: number
  assetIdsList: Array<string>
}>

export function EntropyFiltersProvider({
  children,
  ...props
}: BearProviderProps) {
  const storeRef = useRef<EntropyFiltersZustandStore>()
  if (!storeRef.current) {
    storeRef.current = createEntropyTrendsDetailsStore(
      props.startAt,
      props.endAt,
      props.assetIdsList
    )
  }

  return (
    <EntropyFiltersContext.Provider value={storeRef.current}>
      {children}
    </EntropyFiltersContext.Provider>
  )
}
