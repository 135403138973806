import dayjs, { Dayjs } from 'dayjs'
import { Nullable } from '../engine-types'
import RegexConstants from '../constants/regex.constant'

abstract class TypeHelper {
  static applyHandlerDef<T>(value: any, defVal: T, handler: any): T {
    if (value === null || value === undefined) {
      return defVal
    }
    return handler(value)
  }

  private static numberHandler(value: any) {
    return +value
  }

  static numberValDef(value: any, defVal: number): number {
    const result = TypeHelper.applyHandlerDef<number>(
      value,
      defVal,
      TypeHelper.numberHandler
    )
    if (isNaN(result)) {
      return defVal
    }
    return result
  }

  private static stringHandler(value: any): string {
    if (value === null) {
      return ''
    }
    return `${value}`
  }

  static stringValDef(value: any, defVal: string): string {
    return TypeHelper.applyHandlerDef<string>(
      value,
      defVal,
      TypeHelper.stringHandler
    )
  }

  private static emailHandler(value: any): Nullable<string> {
    const finalValue = TypeHelper.stringHandler(value)
    if (RegexConstants.EMAIL.test(finalValue)) {
      return finalValue
    }
    return null
  }

  static emailValDef(value: any, defVal: string): string {
    return TypeHelper.applyHandlerDef<string>(
      value,
      defVal,
      TypeHelper.emailHandler
    )
  }

  private static momentHandler(value: any): Dayjs {
    return dayjs.utc(value)
  }

  static applyHandler<T>(value: any, handler: any): Nullable<T> {
    if (value === null || value === undefined) {
      return null
    }
    return handler(value)
  }

  static momentVal(value: any): Nullable<Dayjs> {
    return TypeHelper.applyHandler(value, TypeHelper.momentHandler)
  }

  private static enumHandler(value: any, enumArr: any) {
    for (const key in enumArr) {
      const currentVal = enumArr[key]
      if (currentVal == value) {
        return currentVal
      }
    }
    return null
  }

  static enumValDef<T>(value: any, defVal: T, enumArr: any): T {
    const result = TypeHelper.applyHandlerDef(value, defVal, (v: any) =>
      TypeHelper.enumHandler(v, enumArr)
    )
    if (result === null) {
      return defVal
    }
    return result
  }

  private static arrayStringHandler(value: any): Array<string> {
    if (!Array.isArray(value) || value.length === 0) {
      return []
    }
    return value.map(TypeHelper.stringHandler)
  }

  static arrayStringValDef(value: any, defVal: Array<string>): Array<string> {
    return TypeHelper.applyHandlerDef<Array<string>>(
      value,
      defVal,
      TypeHelper.arrayStringHandler
    )
  }

  private static booleanHandler(value: any): boolean {
    if (value === 'false') {
      return false
    }
    return !!value
  }

  static booleanValDef(value: any, defVal = false): boolean {
    return TypeHelper.applyHandlerDef<boolean>(
      value,
      defVal,
      TypeHelper.booleanHandler
    )
  }

  static numberVal(value: any): Nullable<number> {
    return TypeHelper.applyHandler<number>(value, (value: any) => {
      if (isNaN(value)) {
        return null
      }
      return TypeHelper.numberHandler(value)
    })
  }
}

export default TypeHelper
