import { FormHelperText, styled } from '@mui/material'
import { CSSProperties } from 'react'

export const StyledFormHelperText = styled(FormHelperText)({
  fontSize: '14px',
  marginTop: '10px',
  color: 'red',
})

export const inputPropsStyle: CSSProperties = {
  padding: '8px 12px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  border: '1px solid #E3E8ED',
  fontSize: '14px',
}
