/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { RuleStatus } from 'blues-corejs/dist/models/notifications/rule'

const ruleOptions = [
  {
    label: 'Enabled',
    value: RuleStatus.enabled,
  },
  {
    label: 'Disabled',
    value: RuleStatus.disabled,
  },
]

export const ComputeRuleStatus = (ruleStatus: RuleStatus) => {
  return (
    <RadioGroup row value={ruleStatus}>
      {ruleOptions.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={option.label}
          disabled
        />
      ))}
    </RadioGroup>
  )
}
