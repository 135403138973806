/* eslint-disable import/no-extraneous-dependencies */
import { DefinedDateRangeTuple } from '@lib/engine-types'
import TimeHelper from '@lib/helpers/time.helper'
import { create } from 'zustand'
import { CloudConnectorJobsClient } from '@lib/clients/jobs'
import { fetchAssetByIdsAction } from '..'
import { CloudConnectorFiltersStore } from '.'
import { removeFilter } from './actions'
import { getInitialFiltersState } from './consts'
import { subscribeWithSelector } from 'zustand/middleware'

const cloudConnectorJobsClient = new CloudConnectorJobsClient()

export const useCloudConnectorJobsFiltersStore =
  create<CloudConnectorFiltersStore>()(
    subscribeWithSelector((set, get) => ({
      ...getInitialFiltersState(),
      actions: {
        resetCloudConnectorJobsFilters: () => set(getInitialFiltersState()),
        setTimeRange: (timeRange: DefinedDateRangeTuple) => set({ timeRange }),
        removeFilter: (key, value) =>
          set((state) => removeFilter(key, value, state)),
        setFilters: (selectedFilters) => {
          set({
            selectedFilters: {
              ...get().selectedFilters,
              ...selectedFilters,
            },
          })
        },
        fetchPossibleCloudConnectorJobFilters: async () => {
          const possibleFilters =
            await cloudConnectorJobsClient.getCloudConnectorJobsFilters(
              TimeHelper.transformDateRangeForApi(get().timeRange)
            )

          fetchAssetByIdsAction(Array.from(possibleFilters.assetIdsList))

          set({
            possibleFilters: {
              kindsList: new Set(possibleFilters.kindsList),
              statusesList: new Set(possibleFilters.statusesList),
              assetIdsList: new Set(possibleFilters.assetIdsList),
            },
          })
        },
      },
    }))
  )
