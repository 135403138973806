import {
  DrawerName,
  useDrawerState,
  useDrawerActions,
} from '../../use-drawer-store'
import Drawer from '@mui/material/Drawer'
import { DynamicForm } from './dynamic-form'
import {
  useTabsActions,
  useSelectedFormType,
} from '@features/reports/schedules/use-data-store'

const GENERATE_REPORT_DRAWER_NAME = DrawerName.GenerateReport

function GenerateReportDrawer() {
  const { closeDrawer } = useDrawerActions()
  const { isOpen } = useDrawerState(GENERATE_REPORT_DRAWER_NAME)
  const { getTabItemById, getEditingItemId, setEditingItemId } =
    useTabsActions()
  // @ts-ignore
  const data = getTabItemById(getEditingItemId())

  const selectedFormType = useSelectedFormType()
  const currentFormType = data?.reportType ?? selectedFormType

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        setEditingItemId(undefined)
        closeDrawer(GENERATE_REPORT_DRAWER_NAME)
      }}
      PaperProps={{
        sx: {
          width: '40%',
          minWidth: '640px',
        },
      }}
    >
      <DynamicForm initialFormValue={currentFormType} />
    </Drawer>
  )
}

export default GenerateReportDrawer
