/* eslint-disable import/no-extraneous-dependencies */
import { EBSSnapshot } from 'blues-corejs/dist'
import { SoftDeletedState } from '@features/asset-page/modals/recovery-point-details/components'
import React from 'react'

export function computeEbsSnapshotsRpState(backup: EBSSnapshot) {
  if (backup.isDeleted) {
    return <SoftDeletedState text="Deleting" />
  }

  return null
}
