import React from 'react'
import { EngineCallback, VINumberMap, VIRow } from '@lib/engine-types'
import { OnMenuClickData } from '@tables/core/table-handlers'
import TableHelper from '@lib/helpers/table.helper'
import TableMenu from '@tables/core/table-menu'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import ValueHelper from '@lib/helpers/value.helper'
import ObjHelper from '@lib/helpers/obj.helper'
import { drawVICell } from '@tables/core/table-draw'

interface Props {
  data: VIRow
  columnState: Array<number>
  forceTemplate?: Array<number>
  menu: Array<string>
  onMenuClick: EngineCallback<OnMenuClickData>
  showMenu: boolean
  onCheckboxRowChange: EngineCallback<VIRow>
  columnDrawMapper?: VINumberMap
}

function CheckboxMenuVIRow({
  data,
  columnState,
  forceTemplate,
  menu,
  onMenuClick,
  showMenu,
  onCheckboxRowChange,
  columnDrawMapper = {},
}: Props) {
  const [checkBoxData, ...remainData] = data

  const onCheckboxRowChangeInner = (value: boolean) => {
    const result = [
      ValueHelper.updateValue(checkBoxData ?? { name: '' }, value),
      ...remainData.map(ObjHelper.cloneDeep),
    ]
    onCheckboxRowChange(result)
  }
  return (
    <tr data-testid="jsCheckboxMenuVIRow">
      <td
        style={{
          paddingRight: 0,
          width: '1px',
        }}
      >
        <V2Checkbox
          variant="table"
          checked={!!checkBoxData?.value}
          onChange={onCheckboxRowChangeInner}
        />
      </td>
      {remainData
        .filter(
          TableHelper.buildStateTemplateFilterFunc(columnState, forceTemplate)
        )
        .map((v, index) => drawVICell(columnDrawMapper, v, index, columnState))}
      <td className="tableMenuWrap">
        <TableMenu
          data={data}
          menu={menu}
          showMenu={showMenu}
          onMenuClick={onMenuClick}
        />
      </td>
    </tr>
  )
}

export default CheckboxMenuVIRow
