import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { DashboardRoutes } from './dashboard'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { DashboardV4, OvaDashboard } from '@features'
import {
  AssetsWithThreats,
  InventoryTables,
  RestoreInstance,
  RestoreVolume,
  AssetsWithVulnerabilities,
  MountVolume,
  MountInstance,
} from '@components/components-complex'
import HomePage from '@pages/index'

export const dashboardRoutes: Array<PathRouteProps> = [
  {
    path: DashboardRoutes.defaultRoot,
    element: <HomePage />,
  },
  {
    path: DashboardRoutes.root,
    element: (
      <MainTopBarLayout title="Data Resilience Control Center">
        <DashboardV4 />
      </MainTopBarLayout>
    ),
  },
  {
    path: DashboardRoutes.ovaDashboard,
    element: (
      <MainTopBarLayout title="Data Resilience Control Center">
        <OvaDashboard />
      </MainTopBarLayout>
    ),
  },
  {
    path: DashboardRoutes.inventoryTables,
    element: (
      <MainTopBarLayout title="Inventory">
        <InventoryTables />
      </MainTopBarLayout>
    ),
  },
  {
    path: DashboardRoutes.assetsThreats,
    element: (
      <MainTopBarLayout title="Assets with Threats">
        <AssetsWithThreats />
      </MainTopBarLayout>
    ),
  },

  {
    path: DashboardRoutes.restoreInstance,
    element: (
      <MainTopBarLayout title="Restore">
        <RestoreInstance />
      </MainTopBarLayout>
    ),
  },
  {
    path: DashboardRoutes.restoreVolume,
    element: (
      <MainTopBarLayout title="Restore">
        <RestoreVolume />
      </MainTopBarLayout>
    ),
  },
  {
    path: DashboardRoutes.assetsVulnerabilities,
    element: (
      <MainTopBarLayout title="Assets with Misconfigurations">
        <AssetsWithVulnerabilities />
      </MainTopBarLayout>
    ),
  },

  {
    path: DashboardRoutes.instanceMountRecoveryPoint,
    element: (
      <MainTopBarLayout title="Mount Recovery Point">
        <MountInstance />
      </MainTopBarLayout>
    ),
  },

  {
    path: DashboardRoutes.volumeMountRecoveryPoint,
    element: (
      <MainTopBarLayout title="Mount Recovery Point">
        <MountVolume />
      </MainTopBarLayout>
    ),
  },
]
