/* eslint-disable import/no-extraneous-dependencies */
import {
  DrawerLabelValue,
  DrawerSubHeader,
} from 'ui-v2/src/components/ui/drawer'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import {
  computeBackupName,
  computeThreatStatus,
} from 'ui-v2/src/lib/helpers/backup.helper'
import {
  computeInfectedFilesCount,
  computeCleanFilesCount,
} from 'ui-v2/src/lib/helpers/scan.helper'
import {
  formatDate,
  formatDateToUtc,
  formatTimeDifference,
} from 'ui-v2/src/lib/helpers/time.helper'
import {
  EbsDrawer,
  Ec2Drawer,
  EfsDrawer,
  GenericHostDrawer,
  OvaServerDrawer,
  S3Drawer,
} from 'ui-v2/src/lib/models/assets'
import { Grid2 } from '@mui/material'
import { Asset, OvaBackup } from 'blues-corejs/dist'
import ThreatSummary from './threat-summary'

interface AssetDrawerDetailsProps {
  assetDetails:
    | Ec2Drawer
    | EbsDrawer
    | EfsDrawer
    | S3Drawer
    | OvaServerDrawer
    | GenericHostDrawer
  asset: Asset
}

const AssetDrawerDetails: React.FC<AssetDrawerDetailsProps> = ({
  assetDetails,
  asset,
}) => {
  if (!assetDetails) {
    return null
  }

  return (
    <>
      <Grid2 container spacing={0}>
        <Grid2 size={12}>
          <ThreatSummary asset={asset} />
        </Grid2>
        <Grid2 size={12}>
          <DrawerSubHeader>Asset</DrawerSubHeader>
          <DrawerLabelValue label="Asset Kind" value={assetDetails.assetKind} />
          {assetDetails.name ? (
            <DrawerLabelValue label="Name" value={assetDetails.name} />
          ) : null}

          {assetDetails.awsId ? (
            <DrawerLabelValue label="Asset ID" value={assetDetails.awsId} />
          ) : null}

          {assetDetails.state ? (
            <DrawerLabelValue label="Status" value={assetDetails.state} />
          ) : null}
        </Grid2>
      </Grid2>
      {!(
        assetDetails instanceof GenericHostDrawer ||
        assetDetails instanceof OvaServerDrawer
      ) ? (
        <Grid2 container spacing={0}>
          <Grid2 size={12}>
            <DrawerSubHeader>Account</DrawerSubHeader>
          </Grid2>
          <Grid2 size={6}>
            <DrawerLabelValue
              label="Account ID"
              value={assetDetails.awsAccountId}
            />
            <DrawerLabelValue
              label="Region"
              value={
                LangAwsRegionConstant?.get(assetDetails?.awsRegion)?.en
                  ?.single ?? ''
              }
            />
          </Grid2>
          <Grid2 size={6}>
            {assetDetails.lastBackup ? (
              <DrawerLabelValue
                label="Last Backup"
                value={formatDateToUtc(assetDetails.lastBackup.timestamp)}
              />
            ) : null}
            {assetDetails.lastScan ? (
              <DrawerLabelValue
                label="Last Scan"
                value={formatDateToUtc(assetDetails.lastScan.completedAt)}
              />
            ) : null}
          </Grid2>
        </Grid2>
      ) : null}
      <Grid2 container spacing={2}>
        {assetDetails.lastScan ? (
          <Grid2 size={6}>
            <DrawerSubHeader>Most Recent Scan</DrawerSubHeader>
            <DrawerLabelValue
              label="Scheduled On"
              value={formatDate(assetDetails.lastScan.createdAt)}
            />
            <DrawerLabelValue
              label="Completed On"
              value={formatDate(assetDetails.lastScan.completedAt)}
            />
            <DrawerLabelValue
              label="Duration"
              value={formatTimeDifference(
                assetDetails.lastScan.createdAt,
                assetDetails.lastScan.completedAt
              )}
            />
            <DrawerLabelValue
              label={computeCleanFilesCount(assetDetails.lastScan).label}
              value={computeCleanFilesCount(assetDetails.lastScan).value}
            />
            <DrawerLabelValue
              label={computeInfectedFilesCount(assetDetails.lastScan).label}
              value={computeInfectedFilesCount(assetDetails.lastScan).value}
            />
          </Grid2>
        ) : null}
        {assetDetails.lastBackup ? (
          <Grid2 size={6}>
            <DrawerSubHeader>Most Recent Recovery Point</DrawerSubHeader>
            <DrawerLabelValue
              label="Created On"
              value={formatDate(assetDetails.lastBackup.timestamp)}
            />
            <DrawerLabelValue
              label="Provider"
              value={computeBackupName(assetDetails.lastBackup)}
            />
            <DrawerLabelValue
              label="Ransomware"
              value={computeThreatStatus(
                assetDetails.lastBackup,
                assetDetails.lastBackup.ransomwareCheckStatus
              )}
            />
            <DrawerLabelValue
              label="Malware"
              value={computeThreatStatus(
                assetDetails.lastBackup,
                assetDetails.lastBackup.malwareCheckStatus
              )}
            />
            {!(assetDetails.lastBackup instanceof OvaBackup) ? (
              <DrawerLabelValue
                label="File Corruption"
                value={computeThreatStatus(
                  assetDetails.lastBackup,
                  assetDetails.lastBackup.fsCheckStatus
                )}
              />
            ) : null}
          </Grid2>
        ) : null}
      </Grid2>
    </>
  )
}

export default AssetDrawerDetails
