import FormTokenInterface from '@lib/interfaces/form/form-token.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormTokenInterface,
  FormFieldInterface
> => ({
  tokenName: {
    name: 'tokenName',
    label: 'Name',
    placeholder: 'Access token name',
    validationRules: {
      required: true,
      minLength: 2,
    },
  },
  // for some reason the order here is important
  // might be a problem in the lib
  tokenId: {
    name: 'tokenId',
  },
  scopes: {
    name: 'scopes',
  },
})

export default buildDefaultFormData
