/* eslint-disable import/no-extraneous-dependencies */
import { Grid2, Stack } from '@mui/material'
import AssetScan from './start-scan'
import { Asset, ElastioRecoveryPoint, Nullable } from 'blues-corejs/dist'
import { isAwsAsset } from 'ui-v2/src/lib/helpers/assets.helper'
import { useMemo } from 'react'
import { ScheduleScanAsset } from 'ui-v2/src/lib/models/assets'
import AssetDownloadScanReport from './download-report'
import { Scan } from 'ui-v2/src/lib/models/scans'
//import Link from 'ui-v2/src/components/ui/data-display/link'

interface AssetsDrawerActionsProps {
  asset: Asset
  scan: Nullable<Scan>
  elastioRecoveryPoint: Nullable<ElastioRecoveryPoint>
}

const AssetsDrawerActions: React.FC<AssetsDrawerActionsProps> = ({
  asset,
  scan,
  elastioRecoveryPoint,
}) => {
  const isScannable = useMemo(() => isAwsAsset(asset), [asset])

  if (!isScannable && !elastioRecoveryPoint && !scan) {
    return null
  }

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={12}>
        <Stack spacing={2} direction="row">
          {isScannable ? (
            <AssetScan asset={asset as ScheduleScanAsset} />
          ) : null}
          {/* {elastioRecoveryPoint ? (
            <Link
              to="restore-last-clean-backup"
              preservePath
              preserveSearchParams
            >
              <Button variant="contained" sx={{ textTransform: 'capitalize' }}>
                Restore Last Clean Recovery Point
              </Button>
            </Link>
          ) : null} */}
          {scan ? <AssetDownloadScanReport scan={scan} /> : null}
        </Stack>
      </Grid2>
    </Grid2>
  )
}

export default AssetsDrawerActions
