/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { ChooseAccountRegionStep } from '@components-complex/add-policy-complex/choose-account-region-step'
import {
  getSelectedCloudConnectors,
  getSelectedCloudConnectorVariant,
  getSelectedVaultsList,
} from '@store/selectors/add-policy.selector'
import DataHelper from '@lib/helpers/data.helper'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import {
  setAddPolicyVaults,
  setAddSelectedCloudConnectors,
  setAddSelectedCloudConnectorsVariant,
} from '@store/actions/add-policy.action'
import { SelectedCloudConnectorVariant } from '@lib/constants/policies.constant'
import { VIRow } from '@lib/engine-types'
import { ObjHelper } from '@lib/helpers'
import { getSelectedAssetsSelect } from '@store/selectors/assets-select.selector'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { setAllSelectedAssets } from '@store/actions/assets-select.action'

interface Props {
  isLoading: boolean
  isAssetsVariant: boolean
}

export function CloudConnectorsSelection({
  isLoading,
  isAssetsVariant,
}: Props) {
  const dispatch = useDispatch()
  const selectedCloudConnectorVariant = useSelector(
    getSelectedCloudConnectorVariant
  )
  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const selectedCloudConnectors = useSelector(getSelectedCloudConnectors)
  const selectedVaults = useSelector(getSelectedVaultsList)
  const selectedAssets = useSelector(getSelectedAssetsSelect)

  const accountRegionPairsList =
    DataHelper.accountRegionPairsList(allActiveRedStacks)

  const handleCloudConnectorVariantChange = (newTabIndex: number) => {
    dispatch(setAddSelectedCloudConnectors([]))
    dispatch(
      setAddSelectedCloudConnectorsVariant(
        newTabIndex as SelectedCloudConnectorVariant
      )
    )
  }

  const handleSelectCloudConnectors = (value: VIRow) => {
    const currentSelectedCloudConnectors = ObjHelper.cloneDeep(
      selectedCloudConnectors
    )

    // remove Cloud Connectors from list
    if (currentSelectedCloudConnectors.length > value.length) {
      currentSelectedCloudConnectors.map((item) => {
        if (!value.find((v) => v.name === item.name) || value.length === 0) {
          const deletedRs = allActiveRedStacks.find(
            (rs) =>
              rs.awsAccount === item.value && rs.awsRegion === item.extraValue
          )
          if (deletedRs) {
            const index = selectedVaults.findIndex(
              (v) => v.redStackId === deletedRs.redStackId
            )

            // remove Vaults from deleted Cloud Connector
            if (index !== -1) {
              selectedVaults.splice(index, 1)
              dispatch(setAddPolicyVaults([...selectedVaults]))
            }

            // remove selected Assets from deleted Cloud Connector
            if (isAssetsVariant && selectedAssets.length > 0) {
              const assetsShouldBeRemoved = selectedAssets.filter(
                (v: AssetWithRelatedAssets<Asset>) =>
                  v.asset?.awsRegion === item.extraValue &&
                  v.asset?.awsAccountId === item.value
              )

              if (assetsShouldBeRemoved.length > 0) {
                const assetsWithoutRemoved = selectedAssets.filter(
                  (v: AssetWithRelatedAssets<Asset>) =>
                    v.asset?.awsRegion !== item.extraValue &&
                    v.asset?.awsAccountId !== item.value
                )
                dispatch(setAllSelectedAssets(assetsWithoutRemoved))
              }
            }
            dispatch(setAddSelectedCloudConnectors(value))
          }
        }
      })
    } else {
      dispatch(setAddSelectedCloudConnectors(value))
    }
  }

  return (
    <Box>
      <ChooseAccountRegionStep
        selectedVariant={selectedCloudConnectorVariant}
        selectedCloudConnectors={selectedCloudConnectors}
        possibleCloudConnectors={accountRegionPairsList}
        onCloudConnectorVariantChange={handleCloudConnectorVariantChange}
        onSelectCloudConnectors={handleSelectCloudConnectors}
        loading={isLoading}
      />
    </Box>
  )
}
