import React, { memo } from 'react'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'

// 0 - nothing
// 1 - enabled
// 2 - error
// 3 - enabling

interface Props {
  status: number | string
}

function RegionStatusProgress({ status }: Props) {
  const statusValue = Number(status)
  return (
    <div className="wrap-1626758726432">
      {statusValue === -1 && (
        <>
          <PreloaderSmall show />
        </>
      )}
      {statusValue === 1 && (
        <>
          <div className="rspIcon rspIconEnabled jsEnabled" />
          Enabled!
        </>
      )}
      {statusValue === 2 && (
        <>
          <div className="rspIcon rspIconError jsError" />
          Error
        </>
      )}
      {statusValue === 3 && (
        <>
          <div className="rspIcon rspIconEnabling jsEnabling" />
          Enabling...
        </>
      )}
    </div>
  )
}

export default memo(RegionStatusProgress)
