import React, { memo, useState } from 'react'
import { EngineCallback } from '@lib/engine-types'
import { InputAdornment } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Moment } from 'moment'
import TimeFormatConstants from '@lib/constants/time-format.constant'
import TimeHelper from '@lib/helpers/time.helper'
import CalendarIcon from '@inline-img/inputs/calendar-icon'
import moment from 'moment-timezone'
import { DEFAULT_TIME_ZONE } from '@lib/constants'

export interface Props {
  value: number | undefined
  onChange: EngineCallback<number>
  label?: string
  disablePast?: boolean
  disabled?: boolean
  format?: string
  userTimezone?: string
  minDate?: Moment
  zIndex?: number
}

function Calendar({
  value,
  onChange,
  label,
  disablePast = false,
  disabled = false,
  format = TimeFormatConstants.SHORT_DATE_FORMAT,
  userTimezone,
  minDate,
  zIndex,
}: Props) {
  const timezone = userTimezone ?? DEFAULT_TIME_ZONE().value
  const currentValue = value ? moment(value).tz(timezone) : null

  const [open, setOpen] = useState<boolean>(false) // Стан для відкриття календаря

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onChangeInner = (newValue: Moment | null) => {
    if (newValue) {
      const dateWithTimezone = TimeHelper.getDateWithTimezone(
        newValue.valueOf(),
        timezone
      )
      onChange(TimeHelper.momentToTimestamp(dateWithTimezone))
    }
  }

  return (
    <div className="wrap-1642002481082">
      {label && (
        <div data-testid="calendarLabel" className="calendarLabel">
          {label}
        </div>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disablePast={disablePast}
          value={currentValue}
          onChange={onChangeInner}
          disabled={disabled}
          minDate={minDate}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          format={format}
          slotProps={{
            popper: {
              sx: {
                zIndex: zIndex,
              },
            },
            textField: {
              size: 'small',
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <span
                      onClick={handleOpen}
                      style={{
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <CalendarIcon />
                    </span>
                  </InputAdornment>
                ),
                endAdornment: undefined,
              },
              onClick: handleOpen,
            },
          }}
        />
      </LocalizationProvider>
    </div>
  )
}

export default memo(Calendar)
