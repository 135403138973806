/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import FilterDashboardV2 from '@components-complex/filter-dashboard-v2/FilterDashboardV2'
import ValueInterface from '@lib/interfaces/value.interface'

import DataHelper from '@lib/helpers/data.helper'
import { getS3BucketsTableColumnDefinitions } from '@components-complex/dashboard-pages-v3/s3-bucket-Inventory-table-complex/columns-definition'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import {
  getAliasNamesForS3,
  getDataForPossibleS3InventoryFilters,
} from './utils'
import { AliasNamesForS3Bucket } from '@lib/interfaces/inventory/s3-bucket.interface'
import {
  ListInventoryS3Buckets,
  listS3BucketInventoryPossibleFilters,
} from 'blues-corejs/dist'
import FilterFactory from '@lib/factories/filter.factory'
import {
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
import { Box, Typography } from '@mui/material'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { useListS3 } from './use-list-s3'
import { useList } from '@components-complex/dashboard-pages-v3/ebs-inventory-table-complex/use-list'
import { updateDisabledStateForCoveredOptions } from '@components-complex/dashboard-pages-v3/shared'
import { initialPrioritySortForS3Table } from './initial-priority-sort-for-s3-table'

import { VirtualTable } from '@components/table/table-infinite-scroll-memory'
import { ExportAsCsv } from '@features/common'
import {
  prepareS3bucketDataForCsv,
  S3_BUCKET_CSV_TABLE_HEADERS,
} from './prepare-data-for-csv'
import { getDetectedThreats } from '@components-complex/dashboard-pages-v3/utils'
import { useOnDemandScan } from '@components-complex/dashboard-pages-v3/useOnDemandScan'
import {
  ON_DEMAND_BUTTON_GRADIENT_COLORS,
  ON_DEMAND_SCAN_BUTTON_TEXT,
} from '@components-complex/dashboard-pages-v3/const'
import { StyledDemandButton } from '@components-complex/dashboard-pages-v3/styles'
import { useLayoutContext } from '@features/contexts'

const HEADER_TITLE = 'S3 Buckets'

function S3BucketInventoryTableComplex() {
  const dispatch = useDispatch()
  const { setHeaderTitle } = useLayoutContext()

  const { s3Buckets: s3BucketsList } = useSelector(getLiveAssets)

  const threats = useSelector(getThreats)
  const detectedThreat = getDetectedThreats(threats)

  const { malwareScans, ransomwareScans } = useSelector(getScans)

  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const [search, setSearch] = useState('')
  const [selectedFilters, setSelectedFilters] = useState<Array<ValueInterface>>(
    []
  )

  const [isListS3Active, setIsListS3Active] = useState(false)

  const listS3 = useListS3()

  const [list, actions] = useList(s3BucketsList)
  const { s3Buckets, summarizedData } = new ListInventoryS3Buckets({
    S3Buckets: list,
    threats: detectedThreat,
    lastScans: [...malwareScans, ...ransomwareScans],
  }).execute(DataHelper.getDataForDashboardFilter(selectedFilters, search))

  const { possibleS3BucketFilters } = new listS3BucketInventoryPossibleFilters({
    s3Buckets: s3BucketsList,
  }).execute()

  const handleToggleList = (value: boolean) => {
    setIsListS3Active(value)
    if (value) {
      actions.clear()
      actions.set(listS3.data)
    } else {
      actions.reset()
    }
  }
  const dataForPossibleS3InventoryFilters =
    getDataForPossibleS3InventoryFilters(possibleS3BucketFilters)
  const possibleFilters = updateDisabledStateForCoveredOptions(
    dataForPossibleS3InventoryFilters.map(
      FilterFactory.buildS3BucketInventoryFilter
    ),
    isListS3Active
  )

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const aliasNamesWithId: AliasNamesForS3Bucket = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !s3BucketsList.length) {
      return {}
    }
    return getAliasNamesForS3(s3BucketsList, allActiveRedStacks)
  }, [s3BucketsList.length, allActiveRedStacks.length])

  useEffect(() => {
    dispatch(getAllRedStacks())
    setHeaderTitle(HEADER_TITLE)

    return () => {
      setHeaderTitle(null)
    }
  }, [])

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    setSelectedFilters(newFilters)
  }

  const onSearchChange = (value: string) => {
    setSearch(value?.trim())
  }

  const sortedS3Buckets = initialPrioritySortForS3Table(s3Buckets)

  const { handleDemandButtonClick, isScheduleScanLoading, rowsSelection } =
    useOnDemandScan({
      assetsCoreModel: s3BucketsList,
      assets: s3Buckets,
    })
  const isDemandButtonDisabled =
    isScheduleScanLoading || !Object.keys(rowsSelection.selectedRows).length

  return (
    <Box data-testid="s3-bucket-table">
      {isLiveAssetsLoading ? (
        <PreloaderBlock show />
      ) : (
        <Box display="flex">
          <FilterDashboardV2
            searchPlaceholder="Search by bucket name"
            possibleFilters={possibleFilters}
            selectedFilters={selectedFilters}
            turnOnSearch
            onFiltersChange={onFiltersChange}
            onSearchChange={onSearchChange}
            disabled={false}
            searchVal={search}
            showFilterFirst
            tableName=""
            turnOffButton
            turnOnAddPolicyBtn
            className="fiveItemsWithLabel"
          />
          <Box
            display="flex"
            alignItems="center"
            height="62px"
            sx={{
              color: 'var(--grey800)',
              '.MuiCheckbox-root svg': {
                width: '18px',
                height: '18px',
              },
              '.MuiFormControlLabel-label': {
                fontSize: '14px',
                fontWeight: '400',
              },
            }}
          >
            <Typography fontWeight="500" fontSize="14px" marginRight="12px">
              State:
            </Typography>
            <V2Checkbox
              onChange={handleToggleList}
              label="In use"
              checked
              disabled
            />
            <V2Checkbox
              onChange={handleToggleList}
              label="Deleted"
              checked={isListS3Active}
              disabled={listS3.isLoadingData()}
            />
          </Box>
          <StyledDemandButton
            variant="contained"
            disabled={isDemandButtonDisabled}
            onHoldComplete={handleDemandButtonClick}
            gradientColors={ON_DEMAND_BUTTON_GRADIENT_COLORS}
          >
            {ON_DEMAND_SCAN_BUTTON_TEXT}
          </StyledDemandButton>
        </Box>
      )}
      <ExportAsCsv
        data={prepareS3bucketDataForCsv(sortedS3Buckets)}
        headers={S3_BUCKET_CSV_TABLE_HEADERS}
        filename="s3-buckets-inventory.csv"
        shouldShow={!isListS3Active && sortedS3Buckets.length > 0}
      />
      <VirtualTable
        config={{
          data: sortedS3Buckets,
          columns: getS3BucketsTableColumnDefinitions({
            summarizedData,
            aliasNamesWithId,
          }),

          state: {
            rowSelection: rowsSelection.selectedRows,
            isLoading: isLiveAssetsLoading,
          },
          onRowSelectionChange: rowsSelection.setSelectedRows,
        }}
        emptyPlaceholderText="No S3 Buckets found"
      />
    </Box>
  )
}

export default S3BucketInventoryTableComplex
