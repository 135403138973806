import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { IntegrityScanOptionToHumanReadable } from './types'
import ValueInterface from '@lib/interfaces/value.interface'

export function getIntegrityScanOptionsList(
  selectedValue: number,
  backupOptionsVisibilityInPolicy: boolean
): ValueInterface {
  const options = [
    {
      name: String(
        IntegrityScanOptionToHumanReadable[
          IntegrityScanOptions.SCAN_WITH_ELASTIO
        ]
      ),
      value: IntegrityScanOptions.SCAN_WITH_ELASTIO,
      label:
        IntegrityScanOptionToHumanReadable[
          IntegrityScanOptions.SCAN_WITH_ELASTIO
        ],
    },
  ]

  if (backupOptionsVisibilityInPolicy) {
    options.push(
      {
        name: String(
          IntegrityScanOptionToHumanReadable[
            IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO
          ]
        ),
        value: IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO,
        label:
          IntegrityScanOptionToHumanReadable[
            IntegrityScanOptions.SCAN_AND_BACKUP_WITH_ELASTIO
          ],
      },
      {
        name: String(
          IntegrityScanOptionToHumanReadable[
            IntegrityScanOptions.BACKUP_WITH_ELASTIO
          ]
        ),
        value: IntegrityScanOptions.BACKUP_WITH_ELASTIO,
        label:
          IntegrityScanOptionToHumanReadable[
            IntegrityScanOptions.BACKUP_WITH_ELASTIO
          ],
      }
    )
  }
  return {
    name: 'integrityScanOptions',
    value: selectedValue,
    options: options,
  }
}
