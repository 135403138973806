import { useEffect } from 'react'
import {
  Button,
  Typography,
  Card,
  ListItem,
  List,
  FormControl,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Link } from 'react-router-dom'

import { VIRow } from 'ui-v2/src/lib/engine-types'
import {
  buildCfnDeployCommand,
  buildCloudFormationLink,
} from 'ui-v2/src/lib/helpers/data.helper'
import {
  CopyButtonVariant,
  SourceFormData,
} from 'ui-v2/src/lib/models/settings/sources/sources'
import CopyButtonBlocks from './copy-button-blocks'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import { Controller, useFormContext } from 'react-hook-form'

const AwsNotInstalled = ({
  cloudInformationLinkData,
  listSupportedAwsRegionsData,
}: AwsNotInstalledProps) => {
  const { control, setValue, watch } = useFormContext<SourceFormData>()

  const region = watch('region')

  const commandToastText = 'Run it on the target client machine to deploy CFN.'
  const commandButtonText = buildCfnDeployCommand(
    cloudInformationLinkData,
    region?.name
  )
  const commandButtonsData: VIRow = [
    {
      type: CopyButtonVariant.WINDOWS,
      name: commandButtonText,
      disabled: false,
    },
    {
      type: CopyButtonVariant.MACOS,
      name: commandButtonText,
      disabled: false,
    },
    {
      type: CopyButtonVariant.LINUX,
      name: commandButtonText,
      disabled: false,
    },
  ]

  useEffect(() => {
    const awsRegion = listSupportedAwsRegionsData[0]

    if (awsRegion) {
      setValue('region', {
        label: `${awsRegion.label} ${awsRegion.name}`,
        value: awsRegion.id?.toString() ?? '',
        name: awsRegion.label ?? '',
      })
    } else {
      setValue('region', { name: '' })
    }
  }, [JSON.stringify(listSupportedAwsRegionsData)])

  const supportedAwsRegionOptions = listSupportedAwsRegionsData.map((r) => ({
    label: `${r.label} ${r.name}`,
    value: r.id?.toString() ?? '',
    name: r.label ?? '',
  }))

  return (
    <>
      <Typography
        mb={1}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        To enable your AWS account with Elastio, you will need to deploy Elastio
        CloudFormation stack.
        {!!cloudInformationLinkData && (
          <Typography>
            You can review it{' '}
            <Link
              style={{
                color: '#44acea',
                textDecoration: 'none',
              }}
              target="_blank"
              to={cloudInformationLinkData}
            >
              here
            </Link>
            .
          </Typography>
        )}
      </Typography>
      <Typography variant="body1" fontWeight={400} color="text.secondary">
        You will be redirected to AWS deploy CloudFormation page.
      </Typography>
      <List sx={{ listStyleType: 'dist' }} disablePadding>
        <ListItem
          sx={{
            paddingX: 0,
            paddingY: '5px',
          }}
        >
          <Typography variant="body1" fontWeight={400} color="text.secondary">
            1. Login to your AWS account.
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            paddingX: 0,
            paddingY: '5px',
          }}
        >
          <Typography variant="body1" fontWeight={400} color="text.secondary">
            2. Select a region from the dropdown below and press Deploy
            CloudFormation button.
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            paddingX: 0,
            paddingY: '5px',
          }}
        >
          <Typography variant="body1" fontWeight={400} color="text.secondary">
            3. Click the Create Stack button in the AWS Console.
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            paddingX: 0,
            paddingY: '5px',
          }}
        >
          <Typography variant="body1" fontWeight={400} color="text.secondary">
            4. Once the CloudFormation is deployed successfully, come back to
            this page and proceed to Step 3.
          </Typography>
        </ListItem>
      </List>
      {listSupportedAwsRegionsData?.length > 0 && (
        <Grid container spacing={2} pb={2}>
          <Grid size={12}>
            <FormControl fullWidth size="small">
              <Typography variant="body2" mb={1}>
                Select
              </Typography>
              <Controller
                control={control}
                name="region"
                render={({ field }) => (
                  <BasicSelect
                    value={field.value.value?.toString() as string}
                    onChange={(option) => {
                      field.onChange(option)
                    }}
                    options={supportedAwsRegionOptions}
                    size="small"
                    placeholder={field.value.label ? '' : 'Select'}
                    isSelectedObj
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid size={12}>
            <Button
              variant="contained"
              size="medium"
              disableElevation
              sx={{
                textTransform: 'capitalize',
              }}
              href={buildCloudFormationLink(
                cloudInformationLinkData,
                region?.name
              )}
              target="_blank"
              disabled={!region || !region.name || !cloudInformationLinkData}
            >
              Deploy the CloudFormation Stack
            </Button>
          </Grid>
        </Grid>
      )}
      <Card sx={{ padding: 2 }}>
        <CopyButtonBlocks
          blockHeader="Deploy CFN via CLI"
          blockText="Optionally you can copy the command below and run it
        to deploy CFN"
          blockToastText={commandToastText}
          commands={commandButtonsData}
        />
      </Card>
    </>
  )
}
interface AwsNotInstalledProps {
  cloudInformationLinkData: string
  listSupportedAwsRegionsData: VIRow
}
export default AwsNotInstalled
