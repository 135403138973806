import { EngineCallback } from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormHelper from '@lib/helpers/form.helper'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import clsx from 'clsx'
import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import buildDefaultFormData from '@forms/period/data'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import PeriodConstant from '@lib/constants/data/time/period.constant'
import DurationConstant from '@lib/constants/data/time/duration.constant'
import { Button, TextField } from '@mui/material'
import {
  Controller,
  FieldError,
  useFieldArray,
  Merge,
  FieldErrorsImpl,
} from 'react-hook-form'
import { hasOnlyNumberStartsOneValidator } from '@forms/validators/has-number.validator'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import DeleteRedIcon from '@inline-img/general/delete-red-icon'
import ErrorValidationConstants from '@lib/constants/error-validation.constant'
import {
  FormPeriodInterface,
  RowPeriodInterface,
} from '@lib/interfaces/form/form-period.interface'
import NumHelper from '@lib/helpers/num.helper'
import DataHelper from '@lib/helpers/data.helper'
import ObjHelper from '@lib/helpers/obj.helper'

const PERIOD_CONSTANTS = [
  'All',
  'Hourly',
  'Daily',
  'Weekly',
  'Monthly',
  'Yearly',
]

type PeriodErrors = {
  period?: Array<{
    countDuration?:
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<RowPeriodInterface>>
    duration?:
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<RowPeriodInterface>>
    periodValue?:
      | FieldError
      | Merge<FieldError, FieldErrorsImpl<RowPeriodInterface>>
  }>
}

interface Props {
  defaultValues?: FormPeriodInterface
  onSubmit: EngineCallback<FormPeriodInterface>
  loading: boolean
  errorGroups: Array<ErrorGroupConstants>
  onFormValid?: EngineCallback<boolean>
  onTriggerForm: MutableRefObject<{ submit: EngineCallback<void> }>
}

function PeriodForm({
  defaultValues,
  onSubmit,
  loading,
  errorGroups,
  onTriggerForm,
  onFormValid,
}: Props) {
  const currentForm = useMemo(
    () =>
      FormHelper.fillDefaultValuesPeriodForm(
        buildDefaultFormData(),
        defaultValues
      ),
    []
  )

  const {
    register,
    isFormDisabled,
    submit,
    control,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useGeneralForm(currentForm, onSubmit, errorGroups, loading)

  const [displacedPeriodName, setDisplacedPeriodName] = useState('')

  const { fields, append, remove, replace } = useFieldArray({
    name: 'period',
    control,
  })

  const watchFieldArray: any = watch('period')
  const controlledFields = fields.map((field, index) => {
    // for edit existing field id can be undefined
    if (!field.id) {
      field.id = String(
        NumHelper.numberHash(watchFieldArray[index]?.periodValue)
      )
    }
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  // this block helps submit data without click on Button 'submit'
  const refSubmitButton = useRef<HTMLButtonElement>(null)
  const triggerSubmit = () => {
    refSubmitButton?.current?.click()
  }
  // ____________________________________________________________________

  const onAdd = (value: RowPeriodInterface) => {
    append(value)
  }

  useEffect(() => {
    if (defaultValues) {
      defaultValues?.period?.map((row: Record<string, any>) => {
        onAdd({
          periodValue: row.periodValue,
          countDuration: row.countDuration,
          duration: row.duration,
          removable: !(row.periodValue === 'All'),
        })
      })
    }

    if (onTriggerForm) {
      onTriggerForm.current.submit = triggerSubmit
    }
  }, [])

  useEffect(() => {
    if (onFormValid) {
      onFormValid(isValid)
    }
  }, [isValid])

  const requiredErrorMessage = (value: boolean) => {
    if (ObjHelper.isNotEqual(errors, {}) && value) {
      return ErrorValidationConstants.REQUIRED
    }
  }

  const possiblePeriod = PeriodConstant.getAllValid()
  const possibleDuration = DurationConstant.getAllValid()

  const onRemove = (index: number) => {
    remove(index)
    triggerSubmit()
  }

  return (
    <>
      <PreloaderSmall show={false} top={27} right={220} />
      <form
        className={clsx('wrap-1650643184608 jsPeriodForm', {
          formDisabled: isFormDisabled,
        })}
        onSubmit={(formData) => submit(formData)}
        role="form"
      >
        {controlledFields.map((field, index) => {
          const periodErrors = errors?.period as
            | PeriodErrors['period']
            | undefined
          const isInfinite =
            `${watchFieldArray[index]?.duration}` ===
            DurationConstant.DURATION_INFINITE.name
          const disabledFieldPeriod = watchFieldArray
            .map(({ periodValue }: Record<string, any>) => periodValue)
            .filter((name: string) => name)

          const countDurationError =
            periodErrors?.[index]?.countDuration?.message
          const durationError = periodErrors?.[index]?.duration?.message
          const periodValueError = periodErrors?.[index]?.periodValue?.message

          return (
            <React.Fragment key={field.id}>
              <input
                type="hidden"
                {...register(`period.${index}.periodValue`, {
                  required: requiredErrorMessage(true),
                })}
                name={`period.${index}.periodValue`}
              />
              <input
                type="hidden"
                {...register(`period.${index}.countDuration`, {
                  required: isInfinite
                    ? requiredErrorMessage(false)
                    : requiredErrorMessage(true),
                  validate: isInfinite
                    ? undefined
                    : FormHelper.combineValidators([
                        hasOnlyNumberStartsOneValidator,
                      ]),
                })}
                name={`period.${index}.countDuration`}
              />
              <input
                type="hidden"
                {...register(`period.${index}.duration`, {
                  required: requiredErrorMessage(true),
                })}
                name={`period.${index}.duration`}
              />

              <div className="periodWrap">
                <span className="periodText">Keep</span>

                <div
                  className={clsx({
                    statusErrorClass: !!periodValueError,
                    statusDisplaced:
                      displacedPeriodName ===
                      `${watchFieldArray[index]?.periodValue}`,
                  })}
                >
                  <Controller
                    name={`period.${index}.periodValue`}
                    control={control}
                    render={({ item }: any) => (
                      <ViSelect
                        {...item}
                        possible={possiblePeriod}
                        disabledItems={disabledFieldPeriod}
                        selected={{
                          name: `${watchFieldArray[index]?.periodValue}`,
                        }}
                        onChange={(e) => {
                          setValue(`period.${index}.periodValue`, e.name, {
                            shouldValidate: true,
                          })
                          setValue(`period.${index}.countDuration`, '', {
                            shouldValidate: false,
                          })
                          setValue(`period.${index}.duration`, '', {
                            shouldValidate: false,
                          })
                          replace(
                            watchFieldArray?.sort(
                              (
                                a: Record<string, any>,
                                b: Record<string, any>
                              ) =>
                                PERIOD_CONSTANTS.indexOf(a.periodValue) >
                                PERIOD_CONSTANTS.indexOf(b.periodValue)
                                  ? 1
                                  : -1
                            )
                          )
                          setDisplacedPeriodName(e.name)
                        }}
                        placeholder="Select..."
                        disabled={!watchFieldArray[index]?.removable || loading}
                      />
                    )}
                  />
                  <div className="errorFormText">{periodValueError}</div>
                </div>

                <div className="periodText">
                  <span>recovery points</span>
                  <span> for</span>
                </div>

                <div
                  className={clsx('newFormSingleRow v2StaticTextInput', {
                    statusErrorClass: !!countDurationError,
                  })}
                >
                  <TextField
                    name={`period.${index}.countDuration`}
                    disabled={isInfinite || loading}
                    value={
                      isInfinite
                        ? ''
                        : `${watchFieldArray[index]?.countDuration}`
                    }
                    inputProps={{ maxLength: 4 }}
                    onChange={(e) =>
                      setValue(
                        `period.${index}.countDuration`,
                        e.target.value,
                        { shouldValidate: true }
                      )
                    }
                    label=""
                    variant="outlined"
                    className="staticLabel"
                    helperText={countDurationError}
                    error={!!countDurationError}
                  />
                </div>
                <div
                  className={clsx('jsPeriodFormSelect', {
                    statusErrorClass: !!durationError,
                  })}
                >
                  <Controller
                    name={`period.${index}.duration`}
                    control={control}
                    render={({ item }: any) => (
                      <ViSelect
                        {...item}
                        possible={possibleDuration}
                        selected={{
                          name: `${watchFieldArray[index]?.duration}`,
                        }}
                        disabledItems={DataHelper.disabledFieldDuration(
                          `${watchFieldArray[index]?.periodValue}`,
                          watchFieldArray.length - 1 === index
                        )}
                        onChange={(e) =>
                          setValue(`period.${index}.duration`, e.name, {
                            shouldValidate: true,
                          })
                        }
                        placeholder="Select..."
                        disabled={loading}
                        className="periodFormSelect"
                      />
                    )}
                  />
                  <div className="errorFormText">{durationError}</div>
                </div>

                {watchFieldArray[index]?.removable && !loading && (
                  <Button
                    disabled={false}
                    variant="outlined"
                    className="whiteButton sizeXXS"
                    color="primary"
                    onClick={() => onRemove(index)}
                  >
                    <DeleteRedIcon />
                  </Button>
                )}
              </div>
            </React.Fragment>
          )
        })}

        {!(possiblePeriod?.length === watchFieldArray?.length) &&
          !loading &&
          !watchFieldArray?.find(
            (value: Record<string, any>) =>
              value.duration === DurationConstant.DURATION_INFINITE.name
          ) && (
            <Button
              disabled={false}
              variant="contained"
              className="primary sizeS mr14"
              color="primary"
              onClick={() =>
                onAdd({
                  periodValue: '',
                  countDuration: '',
                  duration: '',
                  removable: true,
                })
              }
            >
              + Add
            </Button>
          )}

        {!loading && (
          <Button
            type="submit"
            disabled={false}
            variant="contained"
            className="primary sizeS"
            color="primary"
            hidden={true}
            ref={refSubmitButton}
          >
            submit
          </Button>
        )}
      </form>
    </>
  )
}

export default PeriodForm
