import { GrpcClient } from '@lib/clients/grpc-client'
import { ThreatsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'

import { Request as RequestSuppressThreat } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/threats/suppress_threat_pb'

import { Request as RequestRemoveSuppressThreat } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/threats/remove_threat_suppression_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export interface ThreatSuppressionCriteria {
  threatId: string
  until: number
  reason: string
}

export interface RemoveThreatSuppressionCriteria {
  threatId: string
}

function createTimestamp(seconds: number) {
  const timestamp = new Timestamp()
  timestamp.setSeconds(seconds)
  return timestamp
}

export class ThreatSuppression extends GrpcClient<ThreatsPromiseClient> {
  #threatsClient: ThreatsPromiseClient

  constructor(hostName = '') {
    super()
    this.#threatsClient = this.getClient(hostName)
  }

  get client(): ThreatsPromiseClient {
    return this.#threatsClient
  }

  async threatSuppression({
    threatId,
    until,
    reason,
  }: ThreatSuppressionCriteria): Promise<void> {
    const request = new RequestSuppressThreat()
    request.setThreatId(threatId)
    request.setUntil(createTimestamp(until))
    request.setReason(reason)

    await this.retryGrpcCall(
      () => this.#threatsClient.suppressThreat(request, this.metadata()),
      {
        requestName: 'ThreatsPromiseClient/suppressThreat',
      }
    )
  }

  async removeThreatSuppression({
    threatId,
  }: RemoveThreatSuppressionCriteria): Promise<void> {
    const request = new RequestRemoveSuppressThreat()
    request.setThreatId(threatId)

    await this.retryGrpcCall(
      () =>
        this.#threatsClient.removeThreatSuppression(request, this.metadata()),
      {
        requestName: 'ThreatsPromiseClient/suppressThreat',
      }
    )
  }

  protected innerClientTypeId(): string {
    return 'ThreatSuppression'
  }

  initClient(hostName = ''): ThreatsPromiseClient {
    return new ThreatsPromiseClient(hostName, null, null)
  }
}
