import { PagePathConstant } from '@lib/constants'
import {
  DEFAULT_VIEWS,
  TabViewInterface,
} from '@features/DashboardV4/management-view'
import { useNavigation } from '@lib/router/contexts/navigation-context'

export const useNavigateToDashboard = () => {
  const router = useNavigation()

  return (formDataId: string, newViews: Array<TabViewInterface>) => {
    const viewIndex = [...DEFAULT_VIEWS, ...newViews].findIndex(
      (item) => item.id === formDataId
    )
    router.push(`${PagePathConstant.DASHBOARD}?t=${viewIndex}`)
  }
}
