import React, { useState } from 'react'
import { ButtonComponent } from './button'
import { PopoverComponent } from './popover'
import { PopoverContent } from './popover-content'

export function HardDeletedState() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <ButtonComponent
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
      />
      <PopoverComponent
        open={open}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      >
        <PopoverContent />
      </PopoverComponent>
    </div>
  )
}
