/* eslint-disable import/no-extraneous-dependencies */
import { secondsToDate } from 'ui-v2/src/lib/helpers/time.helper'
import { RecoveryPoint as ElastioRecoveryPointProto } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/backups/aws/elastio/recovery_point_pb'
import { ElastioRecoveryPoint } from 'blues-corejs/dist'
import {
  BackedUpEntity,
  CheckStatus,
  CorrespondingBackup,
  ElastioRecoveryPointKind,
  ElastioRecoveryPointStatus,
  StatusDeletion,
} from 'blues-corejs/dist/models/backups/aws/elastio/types'

type ElastioRecoveryPointPt = ElastioRecoveryPointProto.AsObject

export class ElastioRecoveryPointTransformer {
  #elastioRecoveryPoint: ElastioRecoveryPointPt

  constructor(elastioRecoveryPoint: ElastioRecoveryPointPt) {
    this.#elastioRecoveryPoint = elastioRecoveryPoint
  }

  transform(): ElastioRecoveryPoint {
    return this.#transformInstance(this.#elastioRecoveryPoint)
  }

  #transformInstance(
    elastioRecoveryPoint: ElastioRecoveryPointPt
  ): ElastioRecoveryPoint {
    return new ElastioRecoveryPoint({
      backupPolicyName: elastioRecoveryPoint.backupPolicyName,
      ccId: elastioRecoveryPoint.ccId,
      ccRpId: elastioRecoveryPoint.ccRpId,
      correspondingBackup: this.#convertCorrespondingBackup(
        elastioRecoveryPoint.correspondingBackup as ElastioRecoveryPointProto.CorrespondingBackup.AsObject
      ),
      createdAt: secondsToDate(elastioRecoveryPoint.createdAt?.seconds),
      deletionStatus: this.#convertDeletionStatus(
        elastioRecoveryPoint.deletionStatus
      ),
      backupEntities: elastioRecoveryPoint.backupEntitiesList.map(
        this.#convertBackupEntity.bind(this)
      ),
      fsCheckStatus: this.#convertCheckStatus(
        elastioRecoveryPoint.fsCheckStatus
      ),
      id: elastioRecoveryPoint.id,
      jobId: elastioRecoveryPoint.jobId,
      kind: this.#convertKind(elastioRecoveryPoint.kind),
      malwareCheckStatus: this.#convertCheckStatus(
        elastioRecoveryPoint.malwareCheckStatus
      ),
      ransomwareCheckStatus: this.#convertCheckStatus(
        elastioRecoveryPoint.ransomwareCheckStatus
      ),
      retentionPolicyName: elastioRecoveryPoint.retentionPolicyName,
      scanIds: elastioRecoveryPoint.scanIdsList,
      sizeInfo:
        elastioRecoveryPoint.sizeInfo as ElastioRecoveryPointProto.SizeStats.AsObject,
      sourceAssetId: elastioRecoveryPoint.sourceAssetId,
      status: this.#convertStatus(elastioRecoveryPoint.status),
      tags: elastioRecoveryPoint.tagsList,
      threatIds: elastioRecoveryPoint.threatIdsList,
      timestamp: secondsToDate(elastioRecoveryPoint.timestamp?.seconds),
      updatedAt: secondsToDate(elastioRecoveryPoint.updatedAt?.seconds),
      vault:
        elastioRecoveryPoint.vault as ElastioRecoveryPointProto.VaultDetails.AsObject,
    })
  }

  #convertBackupEntity(
    backup: ElastioRecoveryPointProto.BackedUpEntity.AsObject
  ): BackedUpEntity {
    const assetItemId = backup.assetItem?.id

    const assetId = backup.asset?.id

    const backedUpEntity: Partial<BackedUpEntity> = {}

    if (assetItemId) {
      backedUpEntity.entity = {
        assetItemId,
      }
    }

    if (assetId) {
      backedUpEntity.entity = {
        assetId,
      }
    }

    return backedUpEntity as BackedUpEntity
  }

  #convertCorrespondingBackup(
    backup?: ElastioRecoveryPointProto.CorrespondingBackup.AsObject
  ): CorrespondingBackup {
    if (!backup) {
      return {} as CorrespondingBackup
    }

    const awsBackupId = backup.awsBackup?.id

    const ebsSnapshotId = backup.ebsSnapshot?.id

    const correspondingBackup: Partial<CorrespondingBackup> = {}

    if (awsBackupId) {
      correspondingBackup.awsBackup = {
        awsbRpId: awsBackupId,
      }
    }

    if (ebsSnapshotId) {
      correspondingBackup.ebsSnapshot = {
        snapId: ebsSnapshotId,
      }
    }

    return correspondingBackup as CorrespondingBackup
  }

  #convertDeletionStatus(
    state: ElastioRecoveryPointProto.DeletionStatus
  ): StatusDeletion {
    const mapping = {
      [ElastioRecoveryPointProto.DeletionStatus.DELETION_STATUS_INSERVICE]:
        StatusDeletion.INSERVICE,
      [ElastioRecoveryPointProto.DeletionStatus.DELETION_STATUS_HARDDELETE]:
        StatusDeletion.HARDDELETE,
      [ElastioRecoveryPointProto.DeletionStatus.DELETION_STATUS_SOFTDELETE]:
        StatusDeletion.SOFTDELETE,
    }

    return mapping[state]
  }

  #convertCheckStatus(
    checkStatus: ElastioRecoveryPointProto.CheckStatus
  ): CheckStatus {
    const mapping = {
      [ElastioRecoveryPointProto.CheckStatus.CHECK_STATUS_UNCHECKED]:
        CheckStatus.UNCHECKED,
      [ElastioRecoveryPointProto.CheckStatus.CHECK_STATUS_GOOD]:
        CheckStatus.GOOD,
      [ElastioRecoveryPointProto.CheckStatus.CHECK_STATUS_BAD]: CheckStatus.BAD,
      [ElastioRecoveryPointProto.CheckStatus.CHECK_STATUS_NOT_APPLICABLE]:
        CheckStatus.NOT_APPLICABLE,
    }

    return mapping[checkStatus]
  }

  #convertKind(kind: ElastioRecoveryPointProto.Kind): ElastioRecoveryPointKind {
    const mapping = {
      [ElastioRecoveryPointProto.Kind.KIND_S3]: ElastioRecoveryPointKind.S3,
      [ElastioRecoveryPointProto.Kind.KIND_EBS]: ElastioRecoveryPointKind.EBS,
      [ElastioRecoveryPointProto.Kind.KIND_FILE]: ElastioRecoveryPointKind.FILE,
      [ElastioRecoveryPointProto.Kind.KIND_STREAM]:
        ElastioRecoveryPointKind.STREAM,
      [ElastioRecoveryPointProto.Kind.KIND_GENERIC_DISK]:
        ElastioRecoveryPointKind.GENERIC_DISK,
      [ElastioRecoveryPointProto.Kind.KIND_POSTGRESQL]:
        ElastioRecoveryPointKind.POSTGRESQL,
      [ElastioRecoveryPointProto.Kind.KIND_EC2]: ElastioRecoveryPointKind.EC2,
      [ElastioRecoveryPointProto.Kind.KIND_GENERIC_VOLUME]:
        ElastioRecoveryPointKind.GENERIC_VOLUME,
    }
    return mapping[kind]
  }

  #convertStatus(
    status: ElastioRecoveryPointProto.Status
  ): ElastioRecoveryPointStatus {
    const mapping = {
      [ElastioRecoveryPointProto.Status.STATUS_PENDING]:
        ElastioRecoveryPointStatus.PENDING,
      [ElastioRecoveryPointProto.Status.STATUS_COMMITTED]:
        ElastioRecoveryPointStatus.COMMITTED,
    }
    return mapping[status]
  }
}
