/* eslint-disable import/no-extraneous-dependencies */
import { JobCloudConnector } from 'blues-corejs/dist'

interface UpdatedJobsInterface {
  updatedData: Array<JobCloudConnector>
  updatedElements: Array<JobCloudConnector>
}

function getIsJobChanged({
  existingJob,
  job,
}: {
  existingJob?: JobCloudConnector
  job: JobCloudConnector
}): boolean {
  if (!existingJob) {
    return false
  }

  const childrenListChanged =
    existingJob.childrenList.length !== job.childrenList.length
  const updatedAtChanged =
    existingJob.updatedAt.getTime() !== job.updatedAt.getTime()
  const statusChanged = existingJob.status !== job.status

  const progressChanged = existingJob.progress !== job.progress

  const someChildStateChanged = existingJob.childrenList.some((child) => {
    return job.childrenList.some((newChild) => {
      return (
        child.id === newChild.id &&
        (child.status !== newChild.status ||
          child.updatedAt.getTime() !== newChild.updatedAt.getTime() ||
          child.progress !== newChild.progress)
      )
    })
  })

  return (
    childrenListChanged ||
    updatedAtChanged ||
    statusChanged ||
    progressChanged ||
    someChildStateChanged
  )
}

export function getUpdatedJobs({
  jobsFromServer,
  jobsInState,
}: {
  jobsInState: Array<JobCloudConnector>
  jobsFromServer: Array<JobCloudConnector>
}): UpdatedJobsInterface {
  const updatedData = [...jobsInState]
  const updatedElements: Array<JobCloudConnector> = []

  for (const job of jobsFromServer) {
    const existingJobIndex = updatedData.findIndex(({ id }) => id === job.id)

    if (existingJobIndex === -1) {
      updatedData.push(job)
      updatedElements.push(job)
      continue
    }

    const existingJob = updatedData[existingJobIndex]

    const isJobChanged = getIsJobChanged({
      existingJob,
      job,
    })

    if (isJobChanged) {
      updatedData[existingJobIndex] = job
      updatedElements.push(job)
    }
  }

  return {
    updatedData,
    updatedElements,
  }
}
