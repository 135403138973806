import FormAccountProfileInterface from '@lib/interfaces/form/form-account-profile.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormAccountProfileInterface,
  FormFieldInterface
> => ({
  userName: {
    name: 'userName',
    label: 'Name of Account',
    validationRules: {
      required: true,
      minLength: 2,
    },
  },

  phoneNumber: {
    name: 'phoneNumber',
    label: 'Phone Number',
    validationRules: {},
  },

  email: {
    name: 'email',
  },
})

export default buildDefaultFormData
