import { useQuery } from '@tanstack/react-query'
import { AssetsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

interface Options {
  enabled?: boolean
}

export function useAssetsListByIdQuery(
  assetIds: Array<string>,
  options?: Options
) {
  const assetsClient = useGrpcClient(AssetsClient)

  return useQuery({
    queryKey: ['assets', 'list-by-id', assetIds],
    queryFn: async () => {
      if (!assetsClient) {
        throw new Error('Assets client not initialized')
      }
      return assetsClient.listAssetsByIds(assetIds)
    },
    enabled:
      !!assetsClient &&
      !!assetIds.length &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
