import { FORM_FIELDS } from '@features/reports/schedules/report/drawer/dynamic-form/schedule/form-field-names'
import { reportPeriodOptions } from '@features/reports/schedules/report/drawer/dynamic-form/schedule'
import {
  getFirstKey,
  getMomentWithTimezone,
  mapBackendFormatCodesToUI,
} from '@features/reports/schedules/shared'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule'

import { getFirstNestedKey } from './get-first-nested-key'
/* eslint-disable import/no-extraneous-dependencies */
import {
  AfterOccurrences,
  Custom,
  Daily,
  Monthly,
  Never,
  OnDate,
  Weekly,
  EveryDay,
  EveryWeek,
  EveryMonth,
} from 'blues-corejs/dist/models/reports/report-schedule/schedule'
import moment from 'moment'
import {
  CustomScheduleState,
  RepeatPeriod,
  ReportEnd,
} from '@features/reports/schedules/custom-schedule/types'
import { getDayNameAsString } from '@lib/clients/reports/schedules/shared/get-day-name'
import { getMonthOptions } from '@features/reports/schedules/custom-schedule/constants'

export function getDefaultValues({
  data,
  reportSourceType,
  recipients,
  formats,
}: {
  data: ReportSchedule
  reportSourceType: string
  recipients: Array<{ label: string; value: string }>
  formats: Array<string>
}) {
  let frequency = 'Daily'

  const scheduleMode = data?.schedule.mode
  const startsOn = data?.schedule.startsOn

  const rangeOfData = getFirstNestedKey(data?.reportKindWithParams, 'period')

  const initialCalendarValue = getMomentWithTimezone(startsOn)

  const inputDate = moment(initialCalendarValue)
  const dayOfMonth = inputDate.date()

  const customSchedule: CustomScheduleState = {
    dateValue: null,
    daysValue: null,
    endValue: ReportEnd.NEVER,
    everyValue: 1,
    monthsValue: '',
    occurrencesValue: null,
    periodValue: RepeatPeriod.DAY,
  }
  if (scheduleMode instanceof Daily) {
    frequency = 'Daily'
  } else if (scheduleMode instanceof Weekly) {
    frequency = `Weekly_${inputDate.format('dddd')}`
  } else if (scheduleMode instanceof Monthly) {
    frequency = `Monthly_${dayOfMonth > 28 ? 0 : dayOfMonth}`
  } else if (scheduleMode instanceof Custom) {
    if (scheduleMode?.expirationMode) {
      if (scheduleMode?.expirationMode instanceof Never) {
        customSchedule.endValue = ReportEnd.NEVER
      } else if (scheduleMode?.expirationMode instanceof OnDate) {
        customSchedule.endValue = ReportEnd.ON
        customSchedule.dateValue = getMomentWithTimezone(
          scheduleMode?.expirationMode.date
        )
      } else if (scheduleMode?.expirationMode instanceof AfterOccurrences) {
        customSchedule.endValue = ReportEnd.AFTER
        customSchedule.occurrencesValue =
          scheduleMode?.expirationMode.afterOccurrences
      }
    }

    if (scheduleMode?.repeatMode) {
      customSchedule.everyValue = scheduleMode?.repeatMode?.stride || 1
      if (scheduleMode?.repeatMode instanceof EveryDay) {
        customSchedule.periodValue = RepeatPeriod.DAY
      } else if (scheduleMode?.repeatMode instanceof EveryWeek) {
        customSchedule.periodValue = RepeatPeriod.WEEK
        customSchedule.daysValue =
          getDayNameAsString(scheduleMode?.repeatMode?.day) || ''
      } else if (scheduleMode?.repeatMode instanceof EveryMonth) {
        customSchedule.periodValue = RepeatPeriod.MONTH
        const scheduleDayOfMonth = scheduleMode?.repeatMode?.dayOfMonth
        const monthOptions = getMonthOptions()
        const monthsValue =
          scheduleDayOfMonth > 1 ? monthOptions[1] : monthOptions[0]
        customSchedule.monthsValue = monthsValue?.value || ''
      }
    }

    frequency = 'Custom'
  }

  const key = getFirstKey(data?.reportKindWithParams)

  // @ts-ignore
  const { formatsList = [] } = data?.reportKindWithParams?.[key as string] || {}

  const formatsMappedToUI = mapBackendFormatCodesToUI(
    key as string,
    formatsList
  )

  const computedFormats = !formatsMappedToUI.length
    ? formats
    : formatsMappedToUI
  return {
    [FORM_FIELDS.REPORT_TYPE]: reportSourceType,
    [FORM_FIELDS.NAME]: data?.name,
    [FORM_FIELDS.DESCRIPTION]: data?.description,
    [FORM_FIELDS.OVA_ACCOUNTS]: [],
    [FORM_FIELDS.BACKUP_PROVIDER]: [],
    [FORM_FIELDS.RECIPIENTS]: recipients,
    [FORM_FIELDS.FREQUENCY]: frequency,
    [FORM_FIELDS.RANGE_OF_DATA]: rangeOfData ?? reportPeriodOptions[0]!.value,
    [FORM_FIELDS.FORMATS]:
      computedFormats?.length === 1 ? [computedFormats[0]] : formatsMappedToUI,
    [FORM_FIELDS.START_TIME_OF_DATA_RANGE]: data?.timezone,
    [FORM_FIELDS.CUSTOM_SCHEDULE]: customSchedule,
  }
}
