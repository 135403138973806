import {
  DisconnectSlackFromProfileRequest,
  GetCustomerNotificationSettingsRequest,
  UpdateCustomerNotificationSettingsRequest,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/notifications_pb'
import { NotificationsPromiseClient } from '../grpc'
import { ClientConfig } from '../models/client'
import { GrpcClient } from './grpc'
import {
  EmailNotificationSettings,
  SlackNotificationSettings,
} from 'blue-stack-libs/notifications-grpc-libs/js/notifications/messages/customer_settings_pb'

export interface EmailNotificationInterface {
  sendErrors: boolean
  sendWarnings: boolean
  sendInfo: boolean
}
export interface SlackNotificationInterface extends EmailNotificationInterface {
  isDisconnected?: boolean
}
export interface NotificationsConfig {
  slack: SlackNotificationInterface
  email: EmailNotificationInterface
}
export class NotificationsClient extends GrpcClient<NotificationsPromiseClient> {
  #notificationsPromiseClient: NotificationsPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#notificationsPromiseClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'NotificationsClient'
  }

  protected initClient(hostName = ''): NotificationsPromiseClient {
    return new NotificationsPromiseClient(hostName, null, null)
  }

  async getCustomerNotificationSettings(): Promise<any> {
    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsPromiseClient.getCustomerNotificationSettings(
            new GetCustomerNotificationSettingsRequest(),
            this.metadata(this.#token)
          ),
        {
          requestName:
            'NotificationsPromiseClient/getCustomerNotificationSettings',
        }
      )
    ).toObject()
    return response
  }

  async disconnectSlackFromProfile(): Promise<any> {
    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsPromiseClient.disconnectSlackFromProfile(
            new DisconnectSlackFromProfileRequest(),
            this.metadata(this.#token)
          ),
        {
          requestName: 'NotificationsPromiseClient/disconnectSlackFromProfile',
        }
      )
    ).toObject()
    return response
  }

  async updateCustomerNotificationSettings(config: NotificationsConfig) {
    const request = new UpdateCustomerNotificationSettingsRequest()
    const { slack, email } = config
    if (!slack.isDisconnected) {
      const slackSettings = new SlackNotificationSettings()
      slackSettings.setSendErrors(slack.sendErrors)
      slackSettings.setSendInfo(slack.sendInfo)
      slackSettings.setSendWarnings(slack.sendWarnings)
      request.setSlackNotificationSettings(slackSettings)
    }
    const emailSettings = new EmailNotificationSettings()
    emailSettings.setSendErrors(email.sendErrors)
    emailSettings.setSendInfo(email.sendInfo)
    emailSettings.setSendWarnings(email.sendWarnings)
    request.setEmailNotificationSettings(emailSettings)
    const response = (
      await this.callGrpcService(
        () =>
          this.#notificationsPromiseClient.updateCustomerNotificationSettings(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName:
            'NotificationsPromiseClient/updateCustomerNotificationSettings',
        }
      )
    ).toObject()
    return response
  }
}
