import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import { ManagementView, EditView } from '@features/management-view'
import { ManagementViewRoutes } from './management-view'

export const managementViewRoutes: Array<PathRouteProps> = [
  {
    path: ManagementViewRoutes.root,
    element: (
      <MainTopBarLayout title="">
        <ManagementView />
      </MainTopBarLayout>
    ),
  },

  {
    path: ManagementViewRoutes.editManagementView,
    element: (
      <MainTopBarLayout title="">
        <EditView />
      </MainTopBarLayout>
    ),
  },
]
