import { useMutation } from '@tanstack/react-query'
import { GenerateOnDemandReportCriteria } from 'ui-v2/src/lib/models/reports'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { GeneratedReportsClient } from 'ui-v2/src/lib/clients/reports/on-demand'

export function useGenerateOnDemandReport() {
  const generatedReportsClient = useGrpcClient(GeneratedReportsClient)

  return useMutation({
    mutationFn: async (data: GenerateOnDemandReportCriteria) => {
      if (!generatedReportsClient) {
        throw new Error('Generated report client not initialized')
      }

      return generatedReportsClient.generateOnDemandReport(data)
    },
  })
}
