/* eslint-disable import/no-extraneous-dependencies */
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export const CIRCLE_STATUS_COLOR = {
  SUCCESS: '#27ae60',
  PROGRESS: '#fec53d',
  WARNING: '#f2994a',
  ERROR: '#eb5757',
  ABORTED: '#dfeaf5',
  NONE: '#dfeaf5',
}

export const STATUS_TO_STYLE_VARIANT_MAPPING = {
  [TenantJobStatus.SCHEDULED]: 'statPendingVariant',
  [TenantJobStatus.STARTING]: 'statPendingVariant',
  [TenantJobStatus.INPROGRESS]: 'statProgressVariant',
  [TenantJobStatus.FAILED]: 'statFailedVariant',
  [TenantJobStatus.SUCCEEDED]: 'statSuccessVariant',
  [TenantJobStatus.ABORTED]: 'statAbortedVariant',
}
