import RegexConstants from '@lib/constants/regex.constant'
import ErrorValidationConstants from '@lib/constants/error-validation.constant'

export function hasNumberValidator(value: string) {
  if (RegexConstants.HAS_NUMBER.test(value)) {
    return true
  }
  return ErrorValidationConstants.HAS_NUMBER
}
export function hasOnlyNumberStartsOneValidator(value: string) {
  if (RegexConstants.HAS_ONLY_NUMBER_STARTS_ONE.test(value)) {
    return true
  }
  return ErrorValidationConstants.HAS_NUMBER
}
