import ErrorDisplay from 'ui-v2/src/components/common/error-display'
import NoDataDisplay from 'ui-v2/src/components/common/no-data'
import { DialogContent as MuiDialogContent } from '@mui/material'
import { CircularProgress, DialogContentProps, Stack } from '@mui/material'

interface CustomDialogContentProps extends DialogContentProps {
  isLoading?: boolean
  isError?: boolean
  isEmpty?: boolean
  errorMessage?: string
  emptyMessage?: string
  children: React.ReactNode
}

const DialogContent: React.FC<CustomDialogContentProps> = ({
  isLoading = false,
  isError = false,
  isEmpty = false,
  errorMessage,
  emptyMessage,
  children,
  ...props
}) => {
  if (isLoading) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: '100%',
          py: 4,
        }}
      >
        <CircularProgress />
      </Stack>
    )
  }

  if (isError) {
    return <ErrorDisplay message={errorMessage} />
  }

  if (isEmpty) {
    return <NoDataDisplay message={emptyMessage} />
  }

  return <MuiDialogContent {...props}>{children}</MuiDialogContent>
}

export default DialogContent
