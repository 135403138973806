import { useState } from 'react'

/* eslint-disable import/no-extraneous-dependencies */
import ObjHelper from '@lib/helpers/obj.helper'
import { Pagination } from '@lib/clients/types'
import { usePagination } from '@lib/hooks'

import { DataFetchStatus, UseLiveAssetsItemsDataFetcher } from './types'
import { ListAssetItemsClient } from '@lib/clients/assets-items/list-asset-items'
import {
  ListLiveAssetsItemsRequestFilters,
  LiveAssetsItems,
  LiveAssetsItemsFilters,
} from '@lib/clients/assets-items/types'
import { Disk, File, LocalVolume, Stream } from 'blues-corejs/dist'

const ListAssetItemsForAssetClient = new ListAssetItemsClient()

export const INITIAL_STATE: LiveAssetsItems = {
  filesList: [],
  streamsList: [],
  volumesList: [],
  disksList: [],
}

interface ExtendedUseLiveAssetsItemsDataFetcher
  extends UseLiveAssetsItemsDataFetcher {
  fetchInitial: (filter?: LiveAssetsItemsFilters) => Promise<void>
  fetchOnRefreshInterval: (filter?: LiveAssetsItemsFilters) => Promise<void>
}

type UnionAssetItemsType =
  | Array<File>
  | Array<Stream>
  | Array<LocalVolume>
  | Array<Disk>

const unionAssetItems = (
  prevAssetItems: UnionAssetItemsType,
  newAssetItems: UnionAssetItemsType
) => {
  const assetItemsMap = new Map(prevAssetItems.map((item) => [item.id, item]))

  newAssetItems.forEach((item) => {
    assetItemsMap.set(item.id, item)
  })

  return Array.from(assetItemsMap.values())
}

export function useAssetItemsForAssetDataFetcher(
  request?: ListLiveAssetsItemsRequestFilters
): ExtendedUseLiveAssetsItemsDataFetcher {
  const [dataFetchStatus, setDataFetchStatus] = useState<DataFetchStatus>(
    DataFetchStatus.IDLE
  )
  const [assetItemsData, setAssetItemsData] =
    useState<LiveAssetsItems>(INITIAL_STATE)

  const {
    updatePageTokenAndFlagFetched,
    pageToken,
    isNextPageAvailable,
    resetPagination,
  } = usePagination()

  const updateAssetItemsData = (assetItemsResponse: LiveAssetsItems) => {
    setAssetItemsData((prevAssetItems) => ({
      filesList: [
        ...prevAssetItems.filesList,
        ...(assetItemsResponse.filesList ?? []),
      ],
      streamsList: [
        ...prevAssetItems.streamsList,
        ...(assetItemsResponse.streamsList ?? []),
      ],
      volumesList: [
        ...prevAssetItems.volumesList,
        ...(assetItemsResponse.volumesList ?? []),
      ],
      disksList: [
        ...prevAssetItems.disksList,
        ...(assetItemsResponse.disksList ?? []),
      ],
    }))
  }

  const updateAssetItemsDataOnRefreshInterval = (
    assetItemsResponse: LiveAssetsItems
  ) => {
    setAssetItemsData((prevAssetItems) => {
      return {
        filesList: unionAssetItems(
          prevAssetItems.filesList,
          assetItemsResponse.filesList
        ) as Array<File>,
        streamsList: unionAssetItems(
          prevAssetItems.streamsList,
          assetItemsResponse.streamsList
        ) as Array<Stream>,
        volumesList: unionAssetItems(
          prevAssetItems.volumesList,
          assetItemsResponse.volumesList
        ) as Array<LocalVolume>,
        disksList: unionAssetItems(
          prevAssetItems.disksList,
          assetItemsResponse.disksList
        ) as Array<Disk>,
      }
    })
  }

  const handleFetchSuccess = (response: LiveAssetsItems & Pagination) => {
    updateAssetItemsData(response)
    updatePageTokenAndFlagFetched(response.pageToken || undefined)
  }

  const resetData = () => {
    setAssetItemsData(INITIAL_STATE)
    resetPagination()
  }

  const isLoadingData = () => dataFetchStatus === DataFetchStatus.IS_LOADING

  const isFetchAllowed = () => {
    return request && ObjHelper.hasValues(request) && !isLoadingData()
  }

  const resetFetchStatus = () => {
    setDataFetchStatus(DataFetchStatus.IDLE)
  }

  const fetchData = async (filter?: LiveAssetsItemsFilters) => {
    if (!isFetchAllowed() || !isNextPageAvailable()) {
      return
    }

    setDataFetchStatus(DataFetchStatus.IS_LOADING)
    try {
      if (dataFetchStatus === DataFetchStatus.IDLE) {
        const response = await ListAssetItemsForAssetClient.listByAssetId({
          ...request,
          ...filter,
          pageToken,
        })

        setDataFetchStatus(DataFetchStatus.SUCCESS)
        handleFetchSuccess(response)
      }
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset requestin ${__filename}: ${error}`
      )
      setDataFetchStatus(DataFetchStatus.FAILURE)
    } finally {
      resetFetchStatus()
    }
  }

  const fetchInitial = async (filter?: LiveAssetsItemsFilters) => {
    resetData()
    setDataFetchStatus(DataFetchStatus.IS_LOADING)
    try {
      if (dataFetchStatus === DataFetchStatus.IDLE) {
        const response = await ListAssetItemsForAssetClient.listByAssetId({
          ...request,
          ...filter,
        })

        setDataFetchStatus(DataFetchStatus.SUCCESS)
        handleFetchSuccess(response)
      }
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset request in ${__filename}: ${error}`
      )
      setDataFetchStatus(DataFetchStatus.FAILURE)
    } finally {
      resetFetchStatus()
    }
  }

  const fetchOnRefreshInterval = async (filter?: LiveAssetsItemsFilters) => {
    try {
      const response = await ListAssetItemsForAssetClient.listByAssetId({
        ...request,
        ...filter,
      })

      updateAssetItemsDataOnRefreshInterval(response)
    } catch (error) {
      console.error(
        `Something went wrong with fetch asset items for asset request in ${__filename}: ${error}`
      )
    }
  }

  return {
    onFetchData: fetchData,
    data: assetItemsData,
    dataFetchStatus,
    resetData,
    isLoadingData,
    fetchInitial,
    fetchOnRefreshInterval,
  }
}
