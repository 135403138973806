/* eslint-disable import/no-extraneous-dependencies */
import { AssetKind } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

export type LangConstant = Record<
  string,
  {
    single: string
    shortName?: string
  }
>

const createRegionLangConstant = (
  single: string,
  shortName?: string
): LangConstant => ({
  en: {
    single,
    ...(shortName ? { shortName } : {}),
  },
})

export const LangAwsRegionConstant = new Map<string, LangConstant>([
  // US Regions
  ['us-east-2', createRegionLangConstant('US East (Ohio)', 'Ohio')],
  [
    'us-east-1',
    createRegionLangConstant('US East (N. Virginia)', 'N. Virginia'),
  ],
  [
    'us-west-1',
    createRegionLangConstant('US West (N. California)', 'N. California'),
  ],
  ['us-west-2', createRegionLangConstant('US West (Oregon)', 'Oregon')],

  // AWS GovCloud
  ['us-gov-east-1', createRegionLangConstant('AWS GovCloud (US-East)')],
  ['us-gov-west-1', createRegionLangConstant('AWS GovCloud (US)')],

  // African Region
  ['af-south-1', createRegionLangConstant('Africa (Cape Town)', 'Cape Town')],

  // Asia Pacific Regions
  [
    'ap-east-1',
    createRegionLangConstant('Asia Pacific (Hong Kong)', 'Hong Kong'),
  ],
  ['ap-south-1', createRegionLangConstant('Asia Pacific (Mumbai)', 'Mumbai')],
  [
    'ap-northeast-3',
    createRegionLangConstant('Asia Pacific (Osaka-Local)', 'Osaka-Local'),
  ],
  ['ap-northeast-2', createRegionLangConstant('Asia Pacific (Seoul)', 'Seoul')],
  [
    'ap-southeast-1',
    createRegionLangConstant('Asia Pacific (Singapore)', 'Singapore'),
  ],
  [
    'ap-southeast-2',
    createRegionLangConstant('Asia Pacific (Sydney)', 'Sydney'),
  ],
  ['ap-northeast-1', createRegionLangConstant('Asia Pacific (Tokyo)', 'Tokyo')],

  // Canadian Region
  ['ca-central-1', createRegionLangConstant('Canada (Central)', 'Central')],

  // Chinese Regions
  ['cn-north-1', createRegionLangConstant('China (Beijing)', 'Beijing')],
  ['cn-northwest-1', createRegionLangConstant('China (Ningxia)', 'Ningxia')],

  // European Regions
  ['eu-central-1', createRegionLangConstant('Europe (Frankfurt)', 'Frankfurt')],
  ['eu-west-1', createRegionLangConstant('Europe (Ireland)', 'Ireland')],
  ['eu-west-2', createRegionLangConstant('Europe (London)', 'London')],
  ['eu-south-1', createRegionLangConstant('Europe (Milan)', 'Milan')],
  ['eu-west-3', createRegionLangConstant('Europe (Paris)', 'Paris')],
  ['eu-north-1', createRegionLangConstant('Europe (Stockholm)', 'Stockholm')],

  // Middle Eastern Region
  ['me-south-1', createRegionLangConstant('Middle East (Bahrain)', 'Bahrain')],

  // South American Region
  [
    'sa-east-1',
    createRegionLangConstant('South America (São Paulo)', 'São Paulo'),
  ],
])

const createAssetKindLangConstant = (single: string): LangConstant => ({
  en: { single },
})

export const LangAssetKindConstant = new Map<AssetKind, LangConstant>([
  [AssetKind.AWS_EC2, createAssetKindLangConstant('EC2')],
  [AssetKind.AZURE_VM, createAssetKindLangConstant('AzureVM')],
  [AssetKind.VM_WARE_VM, createAssetKindLangConstant('VmWareVM')],
  [AssetKind.AWS_EBS, createAssetKindLangConstant('EBS')],
  [AssetKind.AWS_EFS, createAssetKindLangConstant('EFS')],
  [AssetKind.GENERIC_HOST, createAssetKindLangConstant('Generic Host')],
  [AssetKind.GENERIC_FS, createAssetKindLangConstant('GenericFS')],
  [AssetKind.AWS_S3, createAssetKindLangConstant('S3 Bucket')],
])
