/* eslint-disable import/no-extraneous-dependencies */
import { Backup, ElastioRecoveryPoint } from 'blues-corejs/dist'
import { OvaBackup } from 'blues-corejs/dist/models'
import { ElastioRecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/elastio/types'
import { useEffect, useState } from 'react'
import { RecoveryPointDetailsTab } from '../components/recovery-point-details-tabs'

const isBackupSupportsSliderView = (backup: Backup) => {
  const isElastioEc2Backup =
    backup instanceof ElastioRecoveryPoint &&
    backup.kind === ElastioRecoveryPointKind.EC2

  const isOvaBackup = backup instanceof OvaBackup

  return isElastioEc2Backup || isOvaBackup
}

export function useRecoveryPointTabsState(backup: Backup) {
  const [selectedTab, setSelectedTab] = useState(RecoveryPointDetailsTab.SLIDER)

  const getIsTableTabSelected = () =>
    selectedTab === RecoveryPointDetailsTab.TABLE

  const getIsSliderTabSelected = () =>
    selectedTab === RecoveryPointDetailsTab.SLIDER

  const getShouldSelectTableTab = () =>
    !getIsTableTabSelected() && backup && !isBackupSupportsSliderView(backup)

  useEffect(() => {
    const shouldSelectTableTab = getShouldSelectTableTab()

    if (shouldSelectTableTab) {
      setSelectedTab(RecoveryPointDetailsTab.TABLE)
    }
  }, [backup])

  return {
    selectedTab,
    setSelectedTab,
    getIsTableTabSelected,
    getIsSliderTabSelected,
  }
}
