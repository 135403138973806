/* eslint-disable import/no-extraneous-dependencies */
import { Asset, Backup, EC2, OvaBackup } from 'blues-corejs/dist'
import { useEffect, useState } from 'react'
import { MoreDetailsTab } from './threats-summary-details/more-details-tabs-content/more-details-tabs'

const isAssetSupportsSliderView = (asset: Asset, backup?: Backup) => {
  const isEc2Asset = asset instanceof EC2

  const isOvaBackup = backup instanceof OvaBackup

  return isEc2Asset || isOvaBackup
}

export function useMoreDetailsTabsState(asset: Asset, backup?: Backup) {
  const [selectedTab, setSelectedTab] = useState(MoreDetailsTab.SLIDER)

  const getIsTableTabSelected = () => selectedTab === MoreDetailsTab.TABLE

  const getIsSliderTabSelected = () => selectedTab === MoreDetailsTab.SLIDER

  // We should select the table tab if the asset is not an EC2 instance

  useEffect(() => {
    const getShouldSelectTableTab = () =>
      !getIsTableTabSelected() && !isAssetSupportsSliderView(asset, backup)

    setSelectedTab(
      getShouldSelectTableTab() ? MoreDetailsTab.TABLE : MoreDetailsTab.SLIDER
    )
  }, [asset, backup])

  return {
    selectedTab,
    setSelectedTab,
    getIsTableTabSelected,
    getIsSliderTabSelected,
  }
}
