import { FormControl, Grid2 } from '@mui/material'
import RadioGroup from 'ui-v2/src/components/ui/inputs/radio-group'
import Label from 'ui-v2/src/components/ui/data-display/label'
import { Controller, useForm } from 'react-hook-form'
import {
  useTenantSettingsQuery,
  useUpdateTenantMutation,
} from 'ui-v2/src/hooks/queries/settings'

import { useEffect } from 'react'
import { EntropyDetectionNotificationsStatus } from 'ui-v2/src/lib/models/settings/tenant'
import { useCustomFlags } from 'ui-v2/src/lib/helpers/reports/use-custom-flags'
import Button from 'ui-v2/src/components/ui/inputs/button'
import { useToast } from 'ui-v2/src/hooks/toast'

enum EntropyDetectionNotificationsTextStatus {
  ENABLE = 'Enable',
  DISABLE = 'Disable',
}

type EntropyAnalysisFormData = {
  entropyDetectionStatus: EntropyDetectionNotificationsTextStatus
}

function getEntropyDetectionNotificationsTextStatus(
  isEntropyDetectionEnabled: boolean
): EntropyDetectionNotificationsTextStatus {
  return isEntropyDetectionEnabled
    ? EntropyDetectionNotificationsTextStatus.ENABLE
    : EntropyDetectionNotificationsTextStatus.DISABLE
}

const EntopyAnalysis = () => {
  const { entropyDetectionFeature } = useCustomFlags()
  const { data } = useTenantSettingsQuery()
  const { mutate, isPending } = useUpdateTenantMutation()
  const toast = useToast()
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<EntropyAnalysisFormData>({
    defaultValues: {
      entropyDetectionStatus: getEntropyDetectionNotificationsTextStatus(
        !!data?.isEntropyDetectionEnabled
      ),
    },
  })

  const onSubmit = ({ entropyDetectionStatus }: EntropyAnalysisFormData) => {
    const newEntropyDetectionStatus =
      entropyDetectionStatus === EntropyDetectionNotificationsTextStatus.ENABLE
        ? EntropyDetectionNotificationsStatus.ENABLED
        : EntropyDetectionNotificationsStatus.DISABLED

    mutate(
      { entropyDetectionStatus: newEntropyDetectionStatus },
      {
        onSuccess: ({ isEntropyDetectionEnabled }) => {
          toast.success('Entropy Analysis settings updated successfully')
          reset({
            entropyDetectionStatus: getEntropyDetectionNotificationsTextStatus(
              isEntropyDetectionEnabled
            ),
          })
        },
        onError: (error) => {
          toast.error(
            error?.message ?? 'Failed to update Entropy Analysis settings'
          )
        },
      }
    )
  }

  useEffect(() => {
    reset({
      entropyDetectionStatus: getEntropyDetectionNotificationsTextStatus(
        !!data?.isEntropyDetectionEnabled
      ),
    })
  }, [data])

  if (!entropyDetectionFeature) {
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl>
            <Label>Entopy Analysis</Label>
            <Controller
              name="entropyDetectionStatus"
              control={control}
              rules={{ required: 'Please select entopy analysis.' }}
              render={({ field, fieldState }) => (
                <RadioGroup
                  {...field}
                  options={[
                    {
                      label: EntropyDetectionNotificationsTextStatus.ENABLE,
                      value: EntropyDetectionNotificationsTextStatus.ENABLE,
                    },
                    {
                      label: EntropyDetectionNotificationsTextStatus.DISABLE,
                      value: EntropyDetectionNotificationsTextStatus.DISABLE,
                    },
                  ]}
                  error={!!fieldState.error?.message}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </FormControl>
        </Grid2>
        <Grid2>
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            disabled={!isDirty || isPending}
            isLoading={isPending}
          >
            Save
          </Button>
        </Grid2>
      </Grid2>
    </form>
  )
}

export default EntopyAnalysis
