import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { IntegrityScanOptions } from '@lib/constants/integrity-scan-options.constant'
import { ScanSpecificTimestamps } from '@components-composite/scan-specific-timestamps'
import ScanWithTimestampsInterface from '@lib/interfaces/scan-with-timestamps.interface'
import { S3ForPolicy } from '@features/policies'
import { defaultSelectedFiltersForS3Buckets } from '@store/reducers/assets-select.reducer'
import { DataHelper } from '@lib/helpers'
import { getAllNotDeletedRedStacksList } from '@store/selectors/rex.selector'
import WarningTriangleIcon from '@inline-img/general/warning-triangle-icon'
import { RED_STACK_VERSION } from '@lib/constants/cloud-connector-version.constant'
import {
  getEditScanWithTimestamps,
  getEditSelectedVariant,
  getIntegrityScanOption,
} from '@store/selectors/edit-policy.selector'
import {
  setEditScanWithTimestamps,
  setEditSelectedVariant,
} from '@store/actions/edit-policy.action'
import {
  s3ProtectTabsLabels,
  s3ProtectTabsQuestionHint,
} from '@components-complex/add-policy-complex/policy-creation-steps/4-assets-selection/s3-protection-options/consts'
import RadioTabs from '@components-simple/radio-tabs/RadioTabs'
import {
  S3SelectedProtectVariant,
  SelectedVariant,
} from '@lib/constants/app.constant'
import { getS3SelectedAssetsSelect } from '@store/selectors/assets-select.selector'

interface Props {
  onS3TableErrorChange: (isInvalid: boolean) => void
}

const useGetDisabledRadioList = (): Array<S3SelectedProtectVariant> => {
  const selectedS3Assets = useSelector(getS3SelectedAssetsSelect)

  if (selectedS3Assets.length > 0) {
    return [
      S3SelectedProtectVariant.SpecificAssets,
      S3SelectedProtectVariant.AllS3Buckets,
    ]
  }

  return []
}

export function S3ProtectionOptions({ onS3TableErrorChange }: Props) {
  const allNotDeletedRedStack = useSelector(getAllNotDeletedRedStacksList)
  const isNotSupportedVersionCCs = DataHelper.isNotSupportedVersionCCsFunc(
    allNotDeletedRedStack
  )

  const disabledVariants = useGetDisabledRadioList()
  const selectedVariant = useSelector(getEditSelectedVariant)
  const variantValue =
    selectedVariant === SelectedVariant.S3Variant
      ? S3SelectedProtectVariant.SpecificAssets
      : S3SelectedProtectVariant.AllS3Buckets
  const dispatch = useDispatch()
  const integrityScanOption = useSelector(getIntegrityScanOption)
  const scanWithTimestamps = useSelector(getEditScanWithTimestamps)

  const isShowScanSpecificTimestamps =
    integrityScanOption === IntegrityScanOptions.SCAN_WITH_ELASTIO
  const isShowSpecificS3Assets = selectedVariant === SelectedVariant.S3Variant

  const handleScanSpecificTimestampsChange = (
    scan: ScanWithTimestampsInterface
  ) => {
    dispatch(setEditScanWithTimestamps(scan))
  }

  const handleTabChange = (newTabIndex: number) => {
    switch (newTabIndex) {
      case S3SelectedProtectVariant.SpecificAssets:
        dispatch(setEditSelectedVariant(SelectedVariant.S3Variant))
        break
      case S3SelectedProtectVariant.AllS3Buckets:
        dispatch(setEditSelectedVariant(SelectedVariant.AllS3Variant))
    }
  }

  useEffect(() => {
    if (selectedVariant === SelectedVariant.AllS3Variant) {
      onS3TableErrorChange(false)
    }
  }, [selectedVariant])

  return (
    <>
      <Box
        className="policiesBlockSubheader mt25"
        data-testid="jsAddS3TitleTable"
      >
        Select assets
      </Box>
      <RadioTabs
        className="flexColumnJustifyStart assetsSelectBlock jsSelectAssetsRadio"
        tabsLabel="Protect:"
        labels={s3ProtectTabsLabels}
        currentTabIndex={variantValue}
        onCurrentTabChange={handleTabChange}
        tabsQuestionHint={s3ProtectTabsQuestionHint}
        disabledRadioList={disabledVariants}
        mode="default"
      />
      <Box
        className="policiesBlockLabel fontMedium mb20"
        data-testid="jsS3DescriptionTable"
      >
        Select which assets to protect from the table below to add to the
        policy.
      </Box>
      {isNotSupportedVersionCCs ? (
        <Box
          className="wrap-1690454195072 warningMessageBlock"
          data-testid="jsWarningMessageBlock"
        >
          <WarningTriangleIcon />
          <span className="warningMessage">
            Cloud Connectors version should be {RED_STACK_VERSION}
            or higher. Please upgrade to the
            <a href="/sources" className="blueLink latestVersion">
              latest version
            </a>
            of Cloud Connector to get S3 protection.
          </span>
        </Box>
      ) : (
        <>
          {!isShowScanSpecificTimestamps ? null : (
            <div className="mt16 mb16" data-testid="jsEditSpecificTimestamps">
              <ScanSpecificTimestamps
                scanWithTimestamps={scanWithTimestamps}
                onChange={handleScanSpecificTimestampsChange}
              />
            </div>
          )}
          {isShowSpecificS3Assets && (
            <S3ForPolicy
              // @ts-ignore | Property 'onS3TableErrorChange' very strict to the React types, but we need more generic way
              onS3TableErrorChange={onS3TableErrorChange}
              initialSelectedFilters={defaultSelectedFiltersForS3Buckets()}
            />
          )}
        </>
      )}
    </>
  )
}
