/* eslint-disable import/no-extraneous-dependencies */
import { TenantJobKind } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import React, { Fragment } from 'react'
import { TenantJobKindChip } from '../../shared'
import {
  useDeploymentJobsFiltersActions,
  useSelectedDeploymentJobFilters,
} from '../stores'

export function SelectedJobKindsChips() {
  const { kindsList } = useSelectedDeploymentJobFilters()

  const { removeFilter } = useDeploymentJobsFiltersActions()

  if (!kindsList.size) {
    return null
  }

  function handleRemoveJobKind(kind: TenantJobKind) {
    removeFilter('kindsList', kind)
  }

  return (
    <Fragment>
      {Array.from(kindsList).map((kind) => (
        <TenantJobKindChip
          key={kind}
          kind={kind}
          onDelete={handleRemoveJobKind}
        />
      ))}
    </Fragment>
  )
}
