import { DetailInfoCardEntity } from '@lib/constants/detail-info.constant'
import React, { memo, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import QuestionHint from '@components-simple/question-hint/QuestionHint'

interface Props {
  type?: number
  className?: string
  icon?: ReactElement
  hint?: ReactNode | string
  title: string
  children: ReactNode
}

function DetailInfoCard({
  className,
  icon,
  hint,
  title,
  children,
  type,
}: Props) {
  return (
    <div
      className={clsx('wrap-1652943188852 jsDetailInfoCard', className, {
        statusErrorClass: DetailInfoCardEntity.Error == type,
        statusWarningClass: DetailInfoCardEntity.Warning == type,
        statusSuccessClass: DetailInfoCardEntity.Success == type,
        statusFixedClass: DetailInfoCardEntity.Fixed == type,
        statusDefaultClass: DetailInfoCardEntity.Default == type,
      })}
    >
      <div className="header">
        {icon && <div className="icon">{icon}</div>}
        <div className="title">{title}</div>
        {hint && <QuestionHint className="infoHint" text={hint} />}
      </div>
      <div className="value">{children}</div>
    </div>
  )
}

export default memo(DetailInfoCard)
