import { FORM_FIELDS } from '@features/reports/schedules/report/drawer/dynamic-form/on-demand/form-field-names'
import { TimeRangeSelection } from '@features/reports/schedules/shared'

export function getDefaultValues({
  reportType,
  formats,
  autoSelectSingleFormat = true,
}: {
  autoSelectSingleFormat: boolean
  formats: Array<string>
  reportType: string
}) {
  const shouldAutoSelectFormat = autoSelectSingleFormat && formats.length === 1
  return {
    [FORM_FIELDS.REPORT_TYPE]: reportType,
    [FORM_FIELDS.TIME_RANGE]: TimeRangeSelection.TODAY,
    [FORM_FIELDS.FORMATS]: shouldAutoSelectFormat ? [formats[0]] : [],
    [FORM_FIELDS.BACKUP_PROVIDER]: [],
    [FORM_FIELDS.OVA_ACCOUNTS]: [],
    [FORM_FIELDS.START_TIME_OF_DATA_RANGE]: TimeRangeSelection.TODAY,
  }
}
