import React from 'react'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Typography,
  SxProps,
  Theme,
} from '@mui/material'
import { ArrowRight } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import SolidCard from 'ui-v2/src/components/ui/cards/solid-card'

export interface ActionCardProps {
  title: string
  description: string
  buttonText: string
  to: string
  disabled?: boolean
  sx?: SxProps<Theme>
}

const ActionCard: React.FC<ActionCardProps> = ({
  title,
  description,
  buttonText,
  to,
  disabled = false,
  sx,
}) => {
  return (
    <SolidCard
      sx={{
        width: 300,
        minHeight: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Typography
          mb={2}
          fontWeight={700}
          fontSize={16}
          sx={(theme) => ({ color: theme.palette.text.primary })}
        >
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          padding: 2,
          paddingTop: 0.5,
        }}
      >
        <Box display={'flex'}>
          <Button
            component={Link}
            to={to}
            variant="text"
            color="primary"
            disabled={disabled}
            endIcon={<ArrowRight sx={{ ml: '-4px' }} />}
            sx={{
              textTransform: 'capitalize',
              padding: 0,
              fontWeight: 700,
              textDecoration: 'none',
              '& .MuiButton-endIcon': {
                marginLeft: 0,
              },
            }}
          >
            {buttonText}
          </Button>
        </Box>
      </CardActions>
    </SolidCard>
  )
}

export default ActionCard
