import { useSelector } from 'react-redux'
import {
  getIsLiveAssetsLoading,
  getLiveAssets,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
import { StrHelper } from '@lib/helpers'
import { useFetchBackupsByIds } from '@lib/hooks/backups/use-fetch-backups-by-ids'
import { useSearchParams } from 'react-router-dom'
import { CLOUD_PROVIDER_ASSET_ID_QUERY_KEY, RP_ID_QUERY } from '@lib/constants'
import { AssetRoutes, useNavigation } from '@lib/router'

export function useMountInstanceData() {
  const router = useNavigation()
  const [searchParams] = useSearchParams()

  const parsedAssetId = StrHelper.base64Decode(
    searchParams.get(CLOUD_PROVIDER_ASSET_ID_QUERY_KEY)
  )
  const rpId = searchParams.get(RP_ID_QUERY) || ''

  const { ec2Instances, ebsVolumes } = useSelector(getLiveAssets)
  const isLiveAssetLoading = useSelector(getIsLiveAssetsLoading)
  const threats = useSelector(getThreats)

  const instance = ec2Instances.find(({ id }) => id === parsedAssetId)

  const { backupsList, isBackupsLoading } = useFetchBackupsByIds([rpId])

  // get first array element because only one backup was fetched
  const backup = backupsList[0]?.elastioRp

  const isLoading = isLiveAssetLoading || isBackupsLoading

  const handleGoBack = () => {
    if (!instance) {
      return
    }

    router.push(AssetRoutes.assetId(instance.id))
  }

  return {
    instance,
    backup,
    isLoading,
    ebsVolumes,
    threats,
    handleGoBack,
  }
}
