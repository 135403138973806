import React, { memo } from 'react'
import BlueEyeIcon from '@inline-img/general/blue-eye-icon'
import { EngineMouseCallback } from '@lib/engine-types'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'

interface Props {
  label: string
  onClick: EngineMouseCallback
}

function BlueEyeBtn({ label, onClick }: Props) {
  return (
    <div className="wrap-1619508689910">
      <button
        type="button"
        className="blueEyeBtn jsBtn"
        aria-label="blueEyeButton"
        onClick={onClick}
      >
        <div className="labelContent jsLabel">
          {
            <LongTextTooltip
              text={label ?? ''}
              maxLength={40}
              customClass="jsLabelInner"
            />
          }
        </div>
        <BlueEyeIcon />
      </button>
    </div>
  )
}

export default memo(BlueEyeBtn)
