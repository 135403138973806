/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { AWSBRecoveryPoint, Backup } from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import NextLink from '@lib/router/navigation-strategies/link'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'

function buildAwsBackupDetailsUrl(
  region: string,
  backupVault: string,
  arn: string
) {
  // Base URL for AWS Backup Console
  const baseUrl = `https://${region}.console.aws.amazon.com/backup/home?region=${region}#/backupvaults/details/`

  const encodedArn = encodeURIComponent(arn)

  return `${baseUrl}${encodeURIComponent(backupVault)}/${encodedArn}`
}

function TextRow({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  return (
    <Box>
      <Box component="b" fontWeight="500" fontSize="14px" marginRight="5px">
        {title}:
      </Box>
      {children && children}
    </Box>
  )
}

interface Props {
  backup: Backup
}

function AWSBRecoveryPointCreationDate({
  backup,
}: {
  backup: AWSBRecoveryPoint
}) {
  const url = buildAwsBackupDetailsUrl(
    backup.region,
    backup.vault.name,
    backup.arn
  )

  return (
    <>
      <TextRow title="Recovery Point" />
      <NextLink to={url} passHref>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          fontWeight="500"
          fontSize="14px"
        >
          <DateTimeTooltip date={backup.timestamp} direction="row" />
        </Link>
      </NextLink>
    </>
  )
}

export function RecoveryPointCreationDate({ backup }: Props) {
  if (backup instanceof AWSBRecoveryPoint) {
    return <AWSBRecoveryPointCreationDate backup={backup} />
  }

  return (
    <>
      {/*<TextRow title="Recovery Point" />*/}
      <DateTimeTooltip date={backup.timestamp} direction="row" />
    </>
  )
}
