export const AWS = 'AWS'
export const ON_PREM = 'ON_PREM'

export const AWS_LABEL = 'AWS'
export const ON_PREM_LABEL = 'On Prem'

export const infrastructureTypeOptions = [
  {
    value: AWS,
    label: AWS_LABEL,
  },
  {
    value: ON_PREM,
    label: ON_PREM_LABEL,
  },
]
