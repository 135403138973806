/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { S3Bucket } from 'blues-corejs/dist'
import { AssetId, AssetType } from '../shared'
import Grid from '@mui/material/Grid'
import { BucketState } from './state'
import { AssetTags } from '@features/asset-page/more-details/more-details-components'

export function BucketSummary({ asset }: { asset: S3Bucket }) {
  return (
    <Grid container rowSpacing={2}>
      <Grid container item xs={12}>
        <AssetId asset={asset} />
        <AssetType asset={asset} />
        <BucketState asset={asset} />
      </Grid>
      <Grid container item xs={12}>
        <AssetTags asset={asset} />
      </Grid>
    </Grid>
  )
}
