import React from 'react'
import { Box } from '@mui/material'
import { CommonStatistic, StatisticRowType } from './common-statistic'
import styles from './coverage-stats.module.css'
import { CoverageSkeletonLoader } from '@features/DashboardV4/widgets-with-scale/coverage-stats/coverage-skeleton-loader'

interface Props {
  statisticsRows: Array<StatisticRowType>
  children?: React.ReactNode
  isLoading: boolean
}

/**
 * @param {Array<StatisticRow>} statisticsRows - takes an array of rows, each row should contain the following fields:
 * @param {number} statisticsRows.assets - number of assets
 * @param {number} statisticsRows.assetsWithBackups - number of assets with backups
 * @param {string} statisticsRows.label - label for the row
 * @param {React.ReactNode} children - optional children, for now, it is used for ScaleStatic component
 */

export default function CoverageStats({
  statisticsRows,
  children,
  isLoading,
}: Props) {
  const ConditionalRender = () => {
    if (isLoading) {
      return <CoverageSkeletonLoader />
    }
    return (
      <>
        <CommonStatistic rows={statisticsRows} />
        {children}
      </>
    )
  }

  return (
    <Box className={`coverage-stats-root ${styles.root}`}>
      <Box className={styles.container}>
        <ConditionalRender />
      </Box>
    </Box>
  )
}
