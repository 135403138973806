import { Checkbox, FormControlLabel, styled } from '@mui/material'

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  width: '100%',
  boxShadow: '0 4px 6px rgba(0,0,0,.07)',
  border: '1px solid #ececf2',
  padding: 0,
  backgroundColor: '#fff',
  margin: 0,
  '.MuiFormControlLabel-label': {
    fontSize: '12px',
    fontWeight: 500,
  },
}))

export const StyledCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#33BAF2',
  },
  '&.MuiCheckbox-root svg': {
    width: '13px',
    height: '13px',
  },
})
