import { CSSObject } from '@emotion/react'
import { FormControl, FormHelperText, useTheme } from '@mui/material'
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select'

const TimeZoneSelector = ({
  value,
  helperText,
  error,
  onChange,
}: TimeZoneSelectProps) => {
  const theme = useTheme()

  const customStyles = {
    control: (provided: CSSObject): CSSObject => ({
      ...provided,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: 'none',
      padding: '1.5px',
      cursor: 'pointer',
      '&:hover': { borderColor: theme.palette.primary.main },
    }),
    menu: (provided: CSSObject): CSSObject => ({
      ...provided,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    }),
    option: (provided: CSSObject, state: any): CSSObject => ({
      ...provided,
      backgroundColor: state.isFocused
        ? theme.palette.background.secondary
        : theme.palette.background.paper,
      color: theme.palette.text.primary,
      padding: '10px',
      cursor: 'pointer',
      '&:hover': { backgroundColor: theme.palette.action.hover },
    }),
    placeholder: (provided: CSSObject): CSSObject => ({
      ...provided,
      color: theme.palette.text.primary,
      fontStyle: 'italic',
    }),
    singleValue: (provided: CSSObject): CSSObject => ({
      ...provided,
      color: theme.palette.text.primary,
    }),
  }

  return (
    <FormControl error={error}>
      <TimezoneSelect
        value={value}
        // @ts-ignore
        onChange={onChange}
        className="custom-timezone-select"
        styles={customStyles}
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

interface TimeZoneSelectProps {
  value: string
  helperText?: string
  error?: boolean
  onChange: (tz: ITimezoneOption) => void
}

export default TimeZoneSelector
