import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { getAllRedStacks } from '@store/actions/rex.action'
import {
  getBackups,
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAliasNamesForEBS } from '../ebs-inventory-table-complex/utils'
import { getAliasNamesForEC2 } from '../ec2-inventory-table-complex/utils'
import { getAliasNamesForS3 } from '../s3-bucket-Inventory-table-complex/utils'
import { getAliasNamesForEfs } from '../efs-inventory-table-complex/utils'
import { getDetectedThreats } from '@components-complex/dashboard-pages-v3/utils'

function useLiveAssetsData() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllRedStacks())
  }, [])

  const liveAssets = useSelector(getLiveAssets)
  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)
  const threats = useSelector(getThreats)
  const scans = useSelector(getScans)
  const { lastBackups } = useSelector(getBackups)

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const aliasNamesEbsWithId: AliasName = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !liveAssets.ebsVolumes.length) {
      return {}
    }
    return getAliasNamesForEBS(liveAssets.ebsVolumes, allActiveRedStacks)
  }, [liveAssets.ebsVolumes.length, allActiveRedStacks.length])

  const aliasNamesEc2WithId: AliasName = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !liveAssets.ec2Instances.length) {
      return {}
    }
    return getAliasNamesForEC2(liveAssets.ec2Instances, allActiveRedStacks)
  }, [liveAssets.ec2Instances.length, allActiveRedStacks.length])

  const aliasNamesS3WithId: AliasName = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !liveAssets.s3Buckets.length) {
      return {}
    }
    return getAliasNamesForS3(liveAssets.s3Buckets, allActiveRedStacks)
  }, [liveAssets.s3Buckets.length, allActiveRedStacks.length])

  const aliasNamesEfsWithId: AliasName = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !liveAssets.filesystems.length) {
      return {}
    }
    return getAliasNamesForEfs(liveAssets.filesystems, allActiveRedStacks)
  }, [liveAssets.filesystems.length, allActiveRedStacks.length])

  const detectedThreats = getDetectedThreats(threats)

  return {
    liveAssets,
    isLiveAssetsLoading,
    threats,
    detectedThreats,
    scans,
    lastBackups,
    aliasNames: {
      aliasNamesEbsWithId,
      aliasNamesEc2WithId,
      aliasNamesS3WithId,
      aliasNamesEfsWithId,
    },
  }
}

export { useLiveAssetsData }
