/* eslint-disable import/no-extraneous-dependencies */
import CopyButtonsBlock from '@components-composite/copy-buttons-block/CopyButtonsBlock'
import { TextConstant } from '@lib/constants'
import CliCommandModalData from '@lib/interfaces/cli-command-modal-data.interface'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'
import React from 'react'
import { PortalMountButtons } from '../mount-instance/portal-buttons'
import MountRecoveryPoint from '../MountRecoveryPoint'
import { buildMountDetails } from '../utils'
import { useBreadcrumbsForMount } from './use-breadcrumbs-for-mount'
import { useMountVolumeData } from './use-mount-volume-data'

function MountVolume() {
  const { volume, backup, isLoading, handleGoBack } = useMountVolumeData()

  useBreadcrumbsForMount({
    volume,
    backup,
  })

  const mountDetails = buildMountDetails({
    asset: volume,
    backup,
  })

  const updatedMountDetails: CliCommandModalData = {
    ...mountDetails,
    details: [
      ...mountDetails.details,
      {
        name: 'Volume ID',
        label: volume?.awsId || '',
      },
    ],
  }

  const commandToastText = TextConstant.CLI_COMMAND_MOUNT_TOAST
  const commandBlockText = TextConstant.CLI_COMMAND_MOUNT_BLOCK_TEXT

  return (
    <div className="innerContent innerContentBlueBackground jsMountRecoveryPoint">
      <MountRecoveryPoint
        detailsBlockData={updatedMountDetails}
        scansStatus={backup?.summaryCheckStatus || SummaryCheckStatus.UNCHECKED}
        isLoading={isLoading}
      >
        <CopyButtonsBlock
          blockHeader={TextConstant.CLI_MOUNT}
          blockToastText={commandToastText}
          blockText={commandBlockText}
          commands={updatedMountDetails.commands}
          className="mb10"
        />
        <PortalMountButtons
          commandsData={[]}
          onCancelClick={handleGoBack}
          shouldShowPopover={false}
        />
      </MountRecoveryPoint>
    </div>
  )
}

export default MountVolume
