import React, { BaseSyntheticEvent, memo } from 'react'
import clsx from 'clsx'
import useDebouncedFunction from '@lib/hooks/useDebouncedFunction'
import { SearchVariant } from './types'
import { getSearchIconByVariant } from './get-search-icon'
import FormControl from '@mui/material/FormControl'

interface Props {
  placeholder?: string
  variant: SearchVariant
  searchValue: string
  onChange: (value: string) => void
  debounceTime?: number
}

/**
  @description The difference between the default one and this component - the default one is using the useGeneralForm hook, which is not needed here. Because it is simple search, we do not need some validation, errors, etc.
*/
function SearchFormV2({
  variant,
  searchValue,
  onChange,
  placeholder = 'Search ...',
  debounceTime = 300,
}: Props) {
  const submitDebounced = useDebouncedFunction(onChange, debounceTime)
  const onSearchChange = (evt: BaseSyntheticEvent) => {
    submitDebounced(evt.target.value)
  }

  const IconComponent = getSearchIconByVariant(variant)

  return (
    <FormControl
      className={clsx('wrap-1617969467151', [`${variant}Variant`])}
      aria-label="Search Form"
    >
      <label htmlFor="searchFormInput" className="searchWrap">
        <input
          aria-label="Search Form Input"
          id="searchFormInput"
          className="searchInput"
          type="text"
          placeholder={placeholder}
          onChange={onSearchChange}
          value={searchValue}
        />
        <div className="searchIcon">
          <IconComponent />
        </div>
      </label>
    </FormControl>
  )
}

export default memo(SearchFormV2)
