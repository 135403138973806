/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import clsx from 'clsx'
import NumHelper from '@lib/helpers/num.helper'
import { WidgetHeader } from '@features/DashboardV4/common'
import useManagementTags from '@features/DashboardV4/common/use-management-view/useManagementTags'
import LangHelper from '@lib/helpers/lang.helper'
import styles from './recovery-tests.module.css'
import {
  ScansAndTestsSkeletonLoader,
  useFetchAwsFsChecksStats,
  computeFsChecksLast30Days,
} from '@features/DashboardV4/ScansAndTests'
import type { RegionStatistics } from '@lib/clients/krabs-aws-statistics/types'
import Box from '@mui/material/Box'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'

interface Props {
  accountRegionStatisticsList: Array<RegionStatistics>
  isLoadingRegionStatisticsList: boolean
}

export function RecoveryTests({
  isLoadingRegionStatisticsList,
  accountRegionStatisticsList,
}: Props) {
  const { isLoading, healthyFilesystems, corruptedFilesystems } =
    useFetchAwsFsChecksStats()
  const totalFilesystemChecks = computeFsChecksLast30Days(
    accountRegionStatisticsList
  )

  const { isTagsEnabled } = useManagementTags()

  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()

  const hintMessage = isTagsEnabled()
    ? 'The data is shown for all assets in your tenant.'
    : undefined

  const pluralizedTotalFileSystemChecks = LangHelper.pluralizeEn(
    'check',
    totalFilesystemChecks
  )

  const pluralizedCorruptedFileSystems = LangHelper.pluralizeEn(
    'System',
    corruptedFilesystems
  )

  const pluralizedHealthyFileSystems = LangHelper.pluralizeEn(
    'System',
    healthyFilesystems
  )

  const totalFileSystemChecksFormatted = NumHelper.formatLargeNumber(
    totalFilesystemChecks
  )

  const corruptedFileSystemsFormatted =
    NumHelper.formatLargeNumber(corruptedFilesystems)

  const healthyFileSystemsFormatted =
    NumHelper.formatLargeNumber(healthyFilesystems)

  const hasCorruptedFileSystems = corruptedFilesystems > 0

  const ConditionalRender = () => {
    if (isLoading || isLoadingRegionStatisticsList) {
      return <ScansAndTestsSkeletonLoader />
    }
    return (
      <>
        <Box
          className={clsx(styles.totalBlock, {
            [String(styles.entropyLayout)]: isEntropyEnabled,
          })}
          data-testid="jsScansAndTestsCardTotal"
        >
          <Box className={styles.number}>{totalFileSystemChecksFormatted}</Box>
          <Box>
            File System {pluralizedTotalFileSystemChecks} in prior 30 days
          </Box>
        </Box>
        <Box
          className={clsx(styles.summaryWrapper, {
            [String(styles.entropyLayout)]: isEntropyEnabled,
          })}
        >
          <Box
            className={styles.summaryBlock}
            data-testid="jsScansAndTestsCardProblem"
          >
            <Box
              className={clsx(styles.number, {
                [String(styles.red500)]: hasCorruptedFileSystems,
              })}
            >
              {corruptedFileSystemsFormatted}
            </Box>
            <Box className={styles.label}>
              Corrupted File {pluralizedCorruptedFileSystems}
            </Box>
          </Box>
          <Box
            className={styles.summaryBlock}
            data-testid="jsScansAndTestsCardCorrect"
          >
            <Box className={styles.number}>{healthyFileSystemsFormatted}</Box>
            <Box className={styles.label}>
              Healthy File {pluralizedHealthyFileSystems}
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Box className={styles.card} data-testid="jsScansAndTestsCard">
      <WidgetHeader
        hintMessage={hintMessage}
        title="File System Integrity Checks"
      />
      <ConditionalRender />
    </Box>
  )
}
