import { TenantSettingsModelPb } from 'ui-v2/src/lib/grpc'
import {
  EntropyDetectionNotificationsStatus,
  TenantSettings,
} from 'ui-v2/src/lib/models/settings/tenant'

import { EntropyDetectionNotificationsStatus as EntropyDetectionNotificationsStatusPb } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/models/v1/tenant_settings/tenant_settings_pb'

export class TenantSettingsTransformer {
  #tenantSettingsModelPb: TenantSettingsModelPb.AsObject

  constructor(tenantSettingsModelPb: TenantSettingsModelPb.AsObject) {
    this.#tenantSettingsModelPb = tenantSettingsModelPb
  }

  transform(): TenantSettings {
    return new TenantSettings({
      entropyDetectionStatus: this.#transformEntropyDetectionStatus(
        this.#tenantSettingsModelPb.entropyDetectionNotifications
      ),
    })
  }

  #transformEntropyDetectionStatus(
    status: EntropyDetectionNotificationsStatusPb
  ): EntropyDetectionNotificationsStatus {
    const mapping: Record<
      EntropyDetectionNotificationsStatusPb,
      EntropyDetectionNotificationsStatus
    > = {
      [EntropyDetectionNotificationsStatusPb.ENTROPY_DETECTION_NOTIFICATIONS_DISABLED]:
        EntropyDetectionNotificationsStatus.DISABLED,
      [EntropyDetectionNotificationsStatusPb.ENTROPY_DETECTION_NOTIFICATIONS_ENABLED]:
        EntropyDetectionNotificationsStatus.ENABLED,
    }

    return mapping[status]
  }
}
