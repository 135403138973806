import React, { useEffect, useState } from 'react'
import PreloaderConstants from '@lib/constants/preloader.constant'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import PoliciesBackupComplex from '@components-complex/policies/policies-backup-complex/PoliciesBackupComplex'
import { getPoliciesCount } from '@store/selectors/policies.selector'
import { useDispatch, useSelector } from 'react-redux'
import TabBadgeLabel from '@components-simple/tab-badge-label/TabBadgeLabel'
import PoliciesRetentionComplex from '@components-complex/policies/policies-retention-complex/PoliciesRetentionComplex'
import { requestPolicies } from '@store/actions/policies.action'
import { getPoliciesRetentionCount } from '@store/selectors/policies-retention.selector'
import TypeHelper from '@lib/helpers/type.helper'
import { requestPoliciesRetention } from '@store/actions/policies-retention.action'
import CreateProtectionPolicyModal from '@components-composite/modals/CreateProtectionPolicyModal'
import Modal from '@lib/constants/modal.constant'
import { useModal } from '@lib/hooks/useModal'
import ArrowForwardIcon from '@inline-img/general/arrow-forward-icon'
import { PolicyTab, Link, PoliciesRoutes, useNavigation } from '@lib/router'
import { useSearchParams } from 'react-router-dom'

function PoliciesComplex() {
  const [searchParams] = useSearchParams()
  const tabParam = TypeHelper.numberValDef(
    searchParams.get('t'),
    PolicyTab.BACKUP
  )
  const [tab, setTab] = useState<number>(tabParam)
  const dispatch = useDispatch()
  const router = useNavigation()

  useEffect(() => {
    dispatch(requestPolicies())
    dispatch(requestPoliciesRetention())
  }, [])

  const { modalProps, openModal } = useModal<{
    path: string
  }>(Modal.createProtectionPolicy, () => {})

  const policiesCount = useSelector(getPoliciesCount)
  const policiesRetentionCount = useSelector(getPoliciesRetentionCount)

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_POLICIES,
    PreloaderConstants.REQUEST_POLICIES_RETENTION,
    PreloaderConstants.ADD_POLICIES,
    PreloaderConstants.DELETE_POLICY,
    PreloaderConstants.PAUSE_POLICIES,
    PreloaderConstants.RESUME_POLICIES,
    PreloaderConstants.EXECUTE_POLICY,
  ])

  const linkToCreateNewPolicy = (tabNumber: number) => {
    return tabNumber === PolicyTab.BACKUP
      ? PoliciesRoutes.policyBackupCreate
      : PoliciesRoutes.policyRetentionCreate
  }

  const onTabChange = (newTab: number) => {
    setTab(newTab)
    router.replace(`${router.currentPath}?${searchParams.toString()}`)
  }

  return (
    <Box className="innerContent innerContentBlueBackground jsPoliciesPage">
      <Box className="wrap-1638783021633 policiesComplex jsPoliciesComplex">
        <Box className="controlHeaderBlock">
          <Box className="controlHeader">
            <Box className="controlDescSmall">
              <Box>
                Protection Policies describe the name, schedule, integrity scan
                options and assets to protect.
              </Box>
              <Box>
                Retention Policies are used if Elastio vault is configured to
                store backups and define recovery points will be collapsed.
              </Box>
            </Box>
            <Link to={linkToCreateNewPolicy(tab)}>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                className="jsNewPolicyButton sizeS"
              >
                + New policy
              </Button>
            </Link>
          </Box>
          {!policiesCount && !loading && (
            <Box className="firstPolicyBlock">
              <Box className="firstPolicyBody">
                <h1>Protect your assets with policies</h1>
                <p>Set up your first protection policy</p>
                <Button
                  color="primary"
                  variant="contained"
                  className="jsFirstPolicyButton sizeS firstPolicyButton"
                  onClick={() => openModal()}
                >
                  Get started
                  <ArrowForwardIcon />
                </Button>
              </Box>
              <img
                width={400}
                height={350}
                alt="Elastio"
                src="/images/protect-assets.svg"
              />
            </Box>
          )}
        </Box>
        <CustomTabs value={tab} onChangeTab={onTabChange}>
          <Tab
            label={
              <TabBadgeLabel
                labelText="Protection Policies"
                badgeCount={policiesCount}
              />
            }
          />
          <Tab
            label={
              <TabBadgeLabel
                labelText="Retention Policies"
                badgeCount={policiesRetentionCount}
              />
            }
          />
        </CustomTabs>
        <MaterialTab value={tab} index={PolicyTab.BACKUP}>
          <PoliciesBackupComplex />
        </MaterialTab>
        <MaterialTab value={tab} index={PolicyTab.RETENTION}>
          <PoliciesRetentionComplex />
        </MaterialTab>

        <CreateProtectionPolicyModal {...modalProps} />
      </Box>
    </Box>
  )
}

export default PoliciesComplex
