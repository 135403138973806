/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetsEc2DrawerDataAttrs,
  Ec2Drawer,
} from 'ui-v2/src/lib/models/assets'
import { Backup, EBS, EC2, Nullable, Threat } from 'blues-corejs/dist'
import { AssetsEc2Base } from './ec2-base'
import { Scan } from 'ui-v2/src/lib/models/scans'

export class AssetsEc2DrawerData extends AssetsEc2Base {
  protected readonly instances: Array<EC2>

  protected readonly volumes: Map<string, EBS>

  protected readonly threats: Array<Threat>

  readonly #lastBackups: Map<string, Backup>

  readonly #scans: Array<Scan>

  readonly #volumesWithScans: Map<string, Array<Scan>>

  constructor(parameters: AssetsEc2DrawerDataAttrs) {
    super()
    this.instances = parameters.instances
    this.volumes = parameters.volumes.reduce((map, volume) => {
      map.set(volume.id, volume)
      return map
    }, new Map<string, EBS>())
    this.threats = parameters.threats
    this.#lastBackups = parameters.lastBackups
    this.#scans = parameters.scans
    this.#volumesWithScans = this.#getVolumeWithScans()
  }

  getEc2DrawerData() {
    const instance = this.instances[0]
    if (!instance) {
      return undefined
    }
    return new Ec2Drawer({
      id: instance.id,
      name: instance.name,
      awsId: instance.awsId,
      awsAccountId: instance.awsAccountId,
      awsRegion: instance.awsRegion,
      state: this.convertEc2State(instance.state),
      isUnhealthy: this.isUnhealthyInstance(instance),
      lastScan: this.#getLastScanForInstance(instance),
      lastBackup: this.#getLastBackup(instance),
    })
  }

  #getLastBackup(instance: EC2): Nullable<Backup> {
    return this.#lastBackups.get(instance.id)
  }

  #getLastScanForInstance(instance: EC2): Nullable<Scan> {
    const attachedVolumes = this.getAttachedVolumes(instance)

    const volumeScans = attachedVolumes
      .map((volume) => this.#getLastScanForVolume(volume))
      .filter((scan): scan is Scan => scan !== null)

    if (!volumeScans.length) {
      return null
    }

    return volumeScans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      volumeScans[0]
    )
  }

  #getLastScanForVolume(volume: EBS): Nullable<Scan> {
    const scansList = this.#volumesWithScans.get(volume.id)
    if (!scansList || scansList.length === 0) {
      return null
    }

    return scansList.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      scansList[0]
    )
  }

  #getVolumeWithScans(): Map<string, Array<Scan>> {
    const map = new Map<string, Array<Scan>>()

    for (const scan of this.#scans) {
      const volumesScans = map.get(scan.scanTarget.target.assetId)

      if (volumesScans) {
        volumesScans.push(scan)
      } else {
        map.set(scan.scanTarget.target.assetId, [scan])
      }
    }

    return map
  }
}
