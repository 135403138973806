import { Box, Grid, Skeleton } from '@mui/material'

const NUM_SKELETONS = 4

export function CoverageSkeletonLoader() {
  const skeletonData = Array.from(Array(NUM_SKELETONS))
  return (
    <Box className="common-statistic" display="flex" flexDirection="column">
      {skeletonData.map((_, index) => (
        <Grid
          container
          alignItems="center"
          flexWrap="nowrap"
          data-testid="statistics-row"
          columnGap="8px"
          key={index}
        >
          <Skeleton width={60} height={36} />
          <Skeleton width={84} height={26} />
        </Grid>
      ))}
    </Box>
  )
}
