import RegexConstants from '@lib/constants/regex.constant'
import FormAddUserInterface from '@lib/interfaces/form/form-add-user.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormAddUserInterface,
  FormFieldInterface
> => ({
  email: {
    name: 'email',
    label: 'Email',
    value: '',
    validationRules: {
      required: true,
      validate: (value: string) => {
        if (!RegexConstants.EMAILv2.test(value)) {
          return 'The email address is incorrect'
        }
        return true
      },
    },
  },
  roleId: {
    name: 'roleId',
    label: 'Role(s)',
    value: '',
    validationRules: {},
  },
})

export default buildDefaultFormData
