import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import { KrabsOvaStatisticsClient } from '@lib/clients'
import { SystemHelper } from '@lib/helpers'
import { useEffect, useState } from 'react'

type DataScanned = {
  dataScanned: number
  backupsScanned: number
  frontEndDataProtected: number
}

const INITIAL_ASSETS_SCANNED_STATE: DataScanned = {
  dataScanned: 0,
  backupsScanned: 0,
  frontEndDataProtected: 0,
}

const krabsOvaStatisticsClient = new KrabsOvaStatisticsClient()

export function useFetchDataScannedScanned() {
  const ovaAccountsList = useGetOvaAccountList()
  const ovaBackupProvidersList = useGetOvaBackupProvidersList()
  const [isLoading, setIsLoading] = useState(false)
  const [dataScanned, setDataScanned] = useState<DataScanned>(
    INITIAL_ASSETS_SCANNED_STATE
  )

  useEffect(() => {
    let isMounted = true
    const fetchFilesScanned = async () => {
      try {
        setIsLoading(true)

        const dataScannedStatistic =
          await krabsOvaStatisticsClient.getDataScannedStatistics({
            ovaAccountIds: ovaAccountsList,
            backupProviders: ovaBackupProvidersList,
          })

        if (isMounted) {
          setDataScanned({
            backupsScanned: dataScannedStatistic.rpsScanned,
            ...dataScannedStatistic,
          })
        }
      } catch (error) {
        SystemHelper.sendSentryIfProd(
          'getDataScannedStatistics: failed to fetch data scanned statistics'
        )
      } finally {
        setIsLoading(false)
      }
    }

    fetchFilesScanned()

    return () => {
      isMounted = false
      setDataScanned(INITIAL_ASSETS_SCANNED_STATE)
      setIsLoading(false)
    }
  }, [ovaAccountsList, ovaBackupProvidersList])

  return {
    isLoading,
    dataScanned: dataScanned.dataScanned,
    backupsScanned: dataScanned.backupsScanned,
    frontEndDataProtected: dataScanned.frontEndDataProtected,
  }
}
