import { GrpcClient } from './grpc'
import { ClientConfig } from 'ui-v2/src/lib/models/client'
import { ListRolesRequest, RbacPromiseClient } from '../grpc'

export class RbacClient extends GrpcClient<RbacPromiseClient> {
  #rbacClient: RbacPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#rbacClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'RbacClient'
  }

  protected initClient(hostName: string): RbacPromiseClient {
    return new RbacPromiseClient(hostName, null, null)
  }

  async listRoles() {
    const request = new ListRolesRequest()

    const response = (
      await this.callGrpcService(
        () => this.#rbacClient.listRoles(request, this.metadata(this.#token)),
        {
          requestName: 'RbacPromiseClient/listRoles',
        }
      )
    ).toObject()

    return response.rolesList.sort(
      (a, b) => b.scopesList.length - a.scopesList.length
    )
  }
}
