/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react'
import styles from './ConfigurationVulnerabilities.module.css'
import {
  RiskBlock,
  TopConfigurationVulnerabilities,
  RiskSkeletonLoader,
} from './components'
import { RiskLevel } from 'blues-corejs/dist'
import { WidgetHeader } from '../common'
import Box from '@mui/material/Box'
import { useFetchAwsStorageMisconfigurations } from '@features/DashboardV4/ConfigurationVulnerabilities'
import { calculateTotalVulnerabilitiesByRisk } from '@features/DashboardV4/ConfigurationVulnerabilities/utils'
import {
  useNavigation,
  DashboardRoutes,
  VulnerabilitiesTabs,
} from '@lib/router'

function RisksBlock({
  low,
  medium,
  high,
  isLoading,
}: {
  low: number
  medium: number
  high: number
  isLoading: boolean
}) {
  const router = useNavigation()
  const handleClick = (risk: RiskLevel) => {
    router.push(
      DashboardRoutes.assetsVulnerabilitiesWithParams(
        VulnerabilitiesTabs.VOLUMES,
        { risk }
      )
    )
  }

  if (isLoading) {
    return <RiskSkeletonLoader />
  }

  return (
    <React.Fragment>
      <RiskBlock
        count={high}
        variant="high"
        onClick={() => handleClick(RiskLevel.High)}
      />
      <RiskBlock
        count={medium}
        variant="medium"
        onClick={() => handleClick(RiskLevel.Medium)}
      />
      <RiskBlock
        count={low}
        variant="low"
        onClick={() => handleClick(RiskLevel.Low)}
      />
    </React.Fragment>
  )
}

const RISK_BLOCK_TITLE = 'Storage Misconfigurations'

const TABLE_TITLE = 'Top Storage Misconfigurations'

function ConfigurationVulnerabilities() {
  const router = useNavigation()
  const { isLoading, volumeVulnerabilitiesList, snapshotVulnerabilitiesList } =
    useFetchAwsStorageMisconfigurations()
  const totalRisks = calculateTotalVulnerabilitiesByRisk({
    volumeVulnerabilitiesList,
    snapshotVulnerabilitiesList,
  })

  const handleClick = () => {
    router.push(
      DashboardRoutes.assetsVulnerabilitiesTableRoute(
        String(VulnerabilitiesTabs.VOLUMES)
      )
    )
  }

  return (
    <Box className={styles.container}>
      <Box>
        <WidgetHeader title={RISK_BLOCK_TITLE} onClick={handleClick} />
        <Box className={styles.vulnerabilities_risks}>
          <RisksBlock
            low={totalRisks.low}
            high={totalRisks.high}
            medium={totalRisks.medium}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Box>
        <WidgetHeader title={TABLE_TITLE} onClick={handleClick} />
        <TopConfigurationVulnerabilities
          isLoading={isLoading}
          snapshotVulnerabilitiesList={snapshotVulnerabilitiesList}
          volumeVulnerabilitiesList={volumeVulnerabilitiesList}
        />
      </Box>
    </Box>
  )
}

export default memo(ConfigurationVulnerabilities)
