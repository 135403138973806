import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { UsersClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function useUpdateMfaMutation() {
  const usersClient = useGrpcClient(UsersClient)

  return useMutation({
    mutationFn: async (enabled: boolean) => {
      if (!usersClient) {
        throw new Error('Users client not initialized')
      }
      return usersClient.updateMfa(enabled)
    },
  })
}
