import React, { memo, useMemo, useState } from 'react'
import { Button, Tooltip } from '@mui/material'
import FilterGreyIcon from '@inline-img/general/filter-grey-icon'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import ValueInterface from '@lib/interfaces/value.interface'
import { EngineCallback, VIRow } from '@lib/engine-types'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import SearchForm from '@forms/search/search.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import PaginationModel from '@lib/models/pagination.model'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueHelper from '@lib/helpers/value.helper'
import { PoliciesFilterLabel } from '@lib/constants/policies.constant'
import FilterPagination from '@components-complex/filter-v3/filter-pagination'

interface Props {
  possibleFilters: VIRow
  selectedFilters: VIRow
  initSelectedFilters?: VIRow
  onFiltersChange: any
  pagination?: PaginationModel
  onPaginationChange?: EngineCallback<PaginationModel>
  onSearchChange?: EngineCallback<string>
  searchPlaceholder?: string
  searchVal?: string
  turnOnSearch?: boolean
  disabled: boolean
  finalLoading: boolean
  hideClearAllBtn?: boolean
}

function FilterV3({
  disabled,
  onSearchChange,
  searchPlaceholder = 'Search...',
  turnOnSearch = false,
  pagination,
  possibleFilters,
  selectedFilters,
  initSelectedFilters = [],
  onFiltersChange,
  onPaginationChange,
  finalLoading,
  hideClearAllBtn = false,
}: Props) {
  const filtersWithSelection = useMemo(
    () => ValueHelper.fromSelectedToGlobal(selectedFilters, possibleFilters),
    [possibleFilters, selectedFilters]
  )
  const canBeReset = useMemo(
    () => ObjHelper.isNotEqual(initSelectedFilters, selectedFilters),
    [initSelectedFilters, selectedFilters]
  )

  const isProtectedFilter = selectedFilters.some((filter) =>
    filter?.options?.some((f) => f.label === PoliciesFilterLabel.PROTECTED)
  )

  const shouldShowResetButton = canBeReset && !hideClearAllBtn

  const [showFilter, setShowFilter] = useState<boolean>(
    isProtectedFilter ? true : false
  )

  const hideFiltersCategories = () => {
    setShowFilter(!showFilter)
  }

  const onReset = () => {
    onFiltersChange(initSelectedFilters)
  }

  const onCheckboxChange =
    (currentCategory: ValueInterface, filter: ValueInterface) =>
    (value: boolean) => {
      const result = ObjHelper.cloneDeep(selectedFilters)

      // depending on the result we add or remove
      const handleFunc = value
        ? ValueHelper.addUniqueChildToOptions
        : ValueHelper.removeChildFromOptions

      // determine the category
      let categoryIndex = result.findIndex(
        (v) => v.name === currentCategory.name
      )
      if (categoryIndex === -1) {
        result.push({
          ...currentCategory,
          options: [],
        })
        categoryIndex = result.length - 1
      }

      const elem = result[categoryIndex]
      if (elem) {
        result[categoryIndex] = handleFunc(elem, {
          ...filter,
          value,
        })
      }

      // avoid adding empty filter categories
      // otherwise "clear all" would work wrongly
      if (
        result[categoryIndex]?.options?.length === 0 &&
        categoryIndex !== -1
      ) {
        result.splice(categoryIndex, 1)
        onFiltersChange(result)
        return
      }

      onFiltersChange(result)
    }

  const onMultiselectChange =
    (currentCategory: ValueInterface) => (arr: VIRow) => {
      const result = ObjHelper.cloneDeep(selectedFilters)

      // determine category
      let categoryIndex = result.findIndex(
        (v) => v.name === currentCategory.name
      )
      // avoid adding empty filter categories
      // otherwise "clear all" would work wrongly
      if (arr.length === 0 && categoryIndex !== -1) {
        result.splice(categoryIndex, 1)
        onFiltersChange(result)
        return
      }
      if (categoryIndex === -1) {
        result.push({
          ...currentCategory,
          options: [],
        })
        categoryIndex = result.length - 1
      }
      // assign new selected values
      const elem = result[categoryIndex]
      if (elem) {
        elem.options = arr.map((v) => ({
          ...v,
          value: true,
        }))
      }

      onFiltersChange(result)
    }

  return (
    <div className="wrap-1655205834600 jsFilterV3">
      <div className="filterWrap">
        <Button
          disabled={disabled}
          className="filterAssetsBtn jsFilterV3FiltersBtn"
          type="button"
          color="primary"
          variant="contained"
          onClick={hideFiltersCategories}
        >
          <span className="btnLabelWrap">Filter</span>
          <span className="ml10">
            <FilterGreyIcon />
          </span>
        </Button>

        {turnOnSearch && (
          <SearchForm
            placeholder={searchPlaceholder}
            errorGroups={[ErrorGroupConstants.EMPTY]}
            loading={disabled}
            defaultValues={{
              search: '',
            }}
            variant="whiteFilterSearchBlackIcon"
            onSubmit={(formData: FormSearchInterface) => {
              onSearchChange?.(formData.search)
            }}
          />
        )}

        <FilterPagination
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          disabled={disabled}
          isFinalLoading={finalLoading}
        />
      </div>

      {showFilter && (
        <div className="filtersAssetsRow jsFiltersAssetsRow">
          {filtersWithSelection.map((filterCategory, i) => (
            <React.Fragment key={i}>
              {/* Checkbox  */}
              {filterCategory.type === 1 && (
                <>
                  {filterCategory.options?.map((filter) =>
                    filter.disabled &&
                    filter.label === PoliciesFilterLabel.PROTECTED ? (
                      <Tooltip
                        placement="top"
                        title="The Protected/Unprotected filters are disabled in cases, where only protected assets are available."
                        key="protected"
                      >
                        <div>
                          <V2Checkbox
                            className={`${filterCategory.name}FilterCategory ${filter.label}Filter checkboxValue`}
                            onChange={onCheckboxChange(filterCategory, filter)}
                            label={filter.label}
                            checked={Boolean(filter.value)}
                            disabled={filter.disabled}
                            key={filter.label}
                          />
                        </div>
                      </Tooltip>
                    ) : filter.disabled &&
                      filter.label === PoliciesFilterLabel.UNPROTECTED ? (
                      <Tooltip
                        placement="top"
                        title="The Protected/Unprotected filters are disabled in cases, where only protected assets are available."
                        key="unprotected1"
                      >
                        <div>
                          <V2Checkbox
                            className={`${filterCategory.name}FilterCategory ${filter.label}Filter checkboxValue`}
                            onChange={onCheckboxChange(filterCategory, filter)}
                            label={filter.label}
                            checked={Boolean(filter.value)}
                            disabled={filter.disabled}
                            key={filter.label}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <V2Checkbox
                        className={`${filterCategory.name}FilterCategory ${filter.label}Filter checkboxValue`}
                        onChange={onCheckboxChange(filterCategory, filter)}
                        label={filter.label}
                        checked={Boolean(filter.value)}
                        disabled={filter.disabled}
                        key={filter.label}
                      />
                    )
                  )}
                </>
              )}

              {/* Multiselect */}
              {filterCategory.type === 2 && (
                <ViMultipleSelect
                  className={`selectedValue ${filterCategory.name}FilterCategory jsViMultipleSelect`}
                  disabled={disabled || filterCategory.disabled}
                  possible={filterCategory.options ?? []}
                  selected={filterCategory.options?.filter((v) => v.value)}
                  onChange={onMultiselectChange(filterCategory)}
                  placeholder={filterCategory.label}
                  countLabel={filterCategory.label}
                  key={filterCategory.name}
                  title={
                    filterCategory.supplementalValue
                      ? String(filterCategory.supplementalValue)
                      : ''
                  }
                />
              )}
            </React.Fragment>
          ))}

          {shouldShowResetButton && (
            <Button
              className="sizeS"
              disabled={disabled}
              type="button"
              color="primary"
              variant="text"
              onClick={onReset}
            >
              Clear all
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default memo(FilterV3)
