import { useQuery } from '@tanstack/react-query'
import { ListGenericHostFilters } from 'ui-v2/src/lib/models/assets'
import { AssetsClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

export function useAssetsGenericHostsQuery(filters: ListGenericHostFilters) {
  const assetsClient = useGrpcClient(AssetsClient)

  return useQuery({
    queryKey: ['assets', 'generic-host', filters],
    queryFn: async () => {
      if (!assetsClient) {
        throw new Error('Assets client not initialized')
      }
      return assetsClient.listGenericHosts(filters)
    },
    enabled: !!assetsClient,
  })
}
