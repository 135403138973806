/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { EBS } from 'blues-corejs/dist'
import { RowTitle } from '../row-title'

export function AttachedState({ asset }: { asset: EBS }) {
  const attachedState =
    asset.attachedInstanceIds.length > 0 ? 'Attached' : 'Detached'

  return (
    <Grid container item xs={4}>
      <Grid item width="120px">
        <RowTitle title="Attached State" />
      </Grid>
      <Grid item>
        <Typography variant="caption" fontSize="14px" fontWeight="400">
          {attachedState}
        </Typography>
      </Grid>
    </Grid>
  )
}
