/* eslint-disable import/no-extraneous-dependencies */
import { ElastioRecoveryPoint } from 'blues-corejs/dist'
import { ElastioRecoveryPointKind } from 'blues-corejs/dist/models/backups/aws/elastio/types'
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'

export function computeElastioRecoveryPointKind(
  backup: ElastioRecoveryPoint
): string {
  if (backup.kind === ElastioRecoveryPointKind.EC2) {
    return 'EC2'
  }

  if (backup.kind === ElastioRecoveryPointKind.EBS) {
    return 'EBS'
  }

  if (backup.kind === ElastioRecoveryPointKind.S3) {
    return 'S3'
  }

  if (backup.kind === ElastioRecoveryPointKind.POSTGRESQL) {
    return 'PostgreSQL'
  }

  if (backup.kind === ElastioRecoveryPointKind.FILE) {
    return 'File'
  }

  if (backup.kind === ElastioRecoveryPointKind.STREAM) {
    return 'Stream'
  }

  if (backup.kind === ElastioRecoveryPointKind.GENERIC_VOLUME) {
    return 'Generic Volume'
  }

  if (backup.kind === ElastioRecoveryPointKind.GENERIC_DISK) {
    return 'Generic Disk'
  }
  sentryReThrowCatchHandler(
    `computeElastioRecoveryPointKind: Unknown Elastio recovery point kind: ${backup.kind}`
  )
  throw new Error(
    `computeElastioRecoveryPointKind: Unknown Elastio recovery point kind: ${backup.kind}`
  )
}
