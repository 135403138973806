import { CustomScheduleState } from 'ui-v2/src/lib/models/reports'
import dayjs from 'dayjs'

export function formatCustomSchedule(state: CustomScheduleState): string {
  const {
    everyValue,
    periodValue,
    daysValue,
    monthsValue,
    endValue,
    dateValue,
    occurrencesValue,
  } = state

  const startDate = dayjs().format('dddd, MMMM D')

  let recurrence = ''
  if (periodValue === 'week' && daysValue) {
    recurrence = `Every ${everyValue} week${
      everyValue > 1 ? 's' : ''
    } on ${daysValue}`
  } else if (periodValue === 'day') {
    recurrence = `Every ${everyValue} day${everyValue > 1 ? 's' : ''}`
  } else if (periodValue === 'month') {
    recurrence = monthsValue
      ? `Every ${everyValue} month${
          everyValue > 1 ? 's' : ''
        } on ${monthsValue}`
      : `Every ${everyValue} month${everyValue > 1 ? 's' : ''}`
  } else {
    // @ts-ignore
    if (periodValue === 'year') {
      recurrence = monthsValue
        ? `Every ${everyValue} year${
            everyValue > 1 ? 's' : ''
          } in ${monthsValue}`
        : `Every ${everyValue} year${everyValue > 1 ? 's' : ''}`
    }
  }

  let endDescription = ''
  if (endValue === 'on' && dateValue) {
    endDescription = `until ${dayjs(dateValue).format('MMMM D, YYYY')}`
  } else if (endValue === 'after' && occurrencesValue) {
    endDescription = `for ${occurrencesValue} occurrences`
  }

  return `${startDate} ${recurrence} ${endDescription}`.trim()
}

export default formatCustomSchedule
