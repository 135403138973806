import { ErrorOutline } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

interface ErrorDisplayProps {
  message?: string
}

const ErrorDisplay = ({
  message = 'Something went wrong',
}: ErrorDisplayProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        py: 4,
      }}
      spacing={2}
    >
      <ErrorOutline color="error" sx={{ fontSize: 40 }} />
      <Typography color="error.main">{message}</Typography>
    </Stack>
  )
}

export default ErrorDisplay
