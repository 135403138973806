import React, { memo, useEffect, useState } from 'react'
import { EngineCallback, VIRow } from '@lib/engine-types'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueHelper from '@lib/helpers/value.helper'
import CloseBlock from '@components-simple/close-block/CloseBlock'
import ValueInterface from '@lib/interfaces/value.interface'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import clsx from 'clsx'
import { Autocomplete, Button, TextField } from '@mui/material'

interface Props {
  possible: VIRow
  selected: VIRow
  label?: string
  placeholder?: string
  canAddNew?: boolean
  addOnly?: boolean
  onChange: EngineCallback<VIRow>
  template?: RegExp
  templateErrorMessage?: string
}

function BubbleViSelect({
  possible,
  selected,
  label = '',
  placeholder = '',
  canAddNew = true,
  addOnly = false,
  onChange,
  template,
  templateErrorMessage = '',
}: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const [current, setCurrent] = useState<VIRow>(ObjHelper.cloneDeep(selected))
  const [inputValue, setInputValue] = useState<string>('')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setCurrent(ObjHelper.cloneDeep(selected))
  }, [selected])

  const runHandler = (value: ValueInterface | null, handler: any) => {
    if (!value) {
      return
    }
    const result = handler(value, current)
    setCurrent(result)
    onChange(result)
  }

  const addHandler = (_e: any, value: ValueInterface | null) => {
    runHandler(value, ValueHelper.addToArr)
    setInputValue('')
  }

  const removeHandler = (_e: any, value: ValueInterface | null) => {
    runHandler(value, ValueHelper.removeFromArr)
  }

  const onInputKeyup = (e: any) => {
    if (error) {
      setError('')
    }
    const value = e.target.value.trim()
    if (!canAddNew || e.code !== 'Enter' || !value) {
      return
    }

    if (template && !template.test(value)) {
      setError(templateErrorMessage)
      setOpen(false)
      return
    }

    addHandler(null, {
      name: value,
    })
    setOpen(false)
  }

  const onAddClick = () => {
    const inputValueHandled = inputValue.trim()
    if (!inputValueHandled) {
      return
    }
    if (template && !template.test(inputValueHandled)) {
      setError(templateErrorMessage)
      return
    }
    addHandler(null, {
      name: inputValueHandled,
    })
  }

  return (
    <div className={clsx('wrap-1603691878266', { addOnly })}>
      {!!label && <div className="bvsLabel">{label}</div>}
      <div className="bvsControls">
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          disablePortal
          autoHighlight
          clearOnBlur
          clearOnEscape
          onChange={addHandler}
          options={possible}
          getOptionLabel={(v: ValueInterface) => v.name}
          popupIcon={<SelectArrowIcon />}
          inputValue={inputValue}
          renderInput={(params: any) => (
            <TextField
              {...params}
              onKeyUp={onInputKeyup}
              onChange={(e) => setInputValue(e.target.value)}
              className="bsInputWrap"
              placeholder={placeholder}
              variant="outlined"
              error={!!error}
              helperText={error}
            />
          )}
        />
        {addOnly && (
          <Button
            className="bvsAddBtn jsAdd"
            color="primary"
            variant="contained"
            onClick={onAddClick}
          >
            Add
          </Button>
        )}
      </div>
      <ul className="bubblesList">
        {current.map((t) => (
          <li key={t.name}>
            <CloseBlock
              text={t.name}
              onClose={() => removeHandler(null, t)}
              variant="blue"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}
export default memo(BubbleViSelect)
