/* eslint-disable import/no-extraneous-dependencies */
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { useEffect, useState } from 'react'
import { RowSelectionState } from '@tanstack/table-core'
import SystemHelper from '@lib/helpers/system.helper'
import {
  addSelectedAsset,
  removeSelectedAsset,
  selectAllEBSAsset,
  selectAllEC2Asset,
} from '@store/actions/assets-select.action'
import { EBS, EC2 } from 'blues-corejs/dist'
import { useDispatch, useSelector } from 'react-redux'
import { SpecificAssetsTabs } from '@features/policies/policy/policy-tables/specific-assets-for-policy/specific-assets-for-policy'
import { findAssetById } from '@features/policies/policy/policy-tables'
import { getSelectedAssetsSelect } from '@store/selectors/assets-select.selector'
import { getAttachedAndSelectedSpecificAssetIds } from '@features/policies/policy/policy-tables/specific-assets-for-policy/utils'

interface Props {
  assets: Array<AssetWithRelatedAssets<Asset>>
  tab: number
  ebsAssets: Array<AssetWithRelatedAssets<Asset>>
  ec2Assets: Array<AssetWithRelatedAssets<Asset>>
}

export function useSpecificAssetsSelectRows({
  assets,
  tab,
  ebsAssets,
  ec2Assets,
}: Props) {
  const dispatch = useDispatch()
  const selectedAssets = useSelector(getSelectedAssetsSelect)

  const [selectedEbsRows, setSelectedEbsRows] = useState<RowSelectionState>({})
  const [selectedEc2Rows, setSelectedEc2Rows] = useState<RowSelectionState>({})
  const [selectedAssetsIds, setSelectedAssetsIds] = useState<Set<string>>(
    new Set()
  )

  const isEbsTabDisabled = !ebsAssets.length
  const isEc2TabDisabled = !ec2Assets.length

  useEffect(() => {
    if (!selectedAssets.length) {
      return
    }

    const attachedAssetsIds: Array<string> = []
    const selectedRowsForEc2: RowSelectionState = {}
    const selectedRowsForEbs: RowSelectionState = {}

    selectedAssets.forEach((selectedAsset) => {
      const { asset } = selectedAsset

      const selectedRows =
        asset instanceof EBS ? selectedRowsForEbs : selectedRowsForEc2
      const { selectedIndex, attachedIdsList } =
        getAttachedAndSelectedSpecificAssetIds({
          selectedAsset,
          ec2Assets,
          ebsAssets,
        })
      attachedAssetsIds.push(...attachedIdsList)

      if (selectedIndex > -1) {
        selectedRows[selectedIndex] = true
      }
    })

    setSelectedAssetsIds(
      (prevState) => new Set([...prevState, ...attachedAssetsIds])
    )

    setSelectedEc2Rows(selectedRowsForEc2)
    setSelectedEbsRows(selectedRowsForEbs)
  }, [selectedAssets, ec2Assets, ebsAssets])

  const handleRowChange = (assetId: string, isSelected: boolean) => {
    const foundAsset = findAssetById({
      assets,
      assetId,
    })
    if (!foundAsset) {
      SystemHelper.throwErrorInLocalEnv(
        'Cannot find an asset in the assets selector'
      )
      return
    }

    let actionToRun = addSelectedAsset

    const attachedAssetsIds: Array<string> = []

    if (foundAsset.asset instanceof EBS) {
      foundAsset.asset.attachedInstanceIds.forEach((id) => {
        attachedAssetsIds.push(id)
      })
    }

    if (foundAsset.asset instanceof EC2) {
      foundAsset.asset.ebsIds.forEach((id) => {
        attachedAssetsIds.push(id)
      })
    }

    if (!isSelected) {
      setSelectedAssetsIds(
        new Set([...selectedAssetsIds, ...attachedAssetsIds])
      )
    } else {
      const disabledIds = new Set(selectedAssetsIds)
      attachedAssetsIds.forEach((id) => {
        disabledIds.delete(id)
      })

      setSelectedAssetsIds(disabledIds)
    }

    if (isSelected) {
      actionToRun = removeSelectedAsset
    }
    dispatch(actionToRun(foundAsset))
  }

  const onSelectAllRows = (isSelected: boolean) => {
    const attachedAssetsIds: Array<string> = []
    if (tab === SpecificAssetsTabs.EBS) {
      dispatch(selectAllEBSAsset(isSelected))
      assets.forEach((asset) => {
        if (
          asset.asset instanceof EBS &&
          !selectedAssetsIds.has(asset.asset.id)
        ) {
          asset.asset.attachedInstanceIds.forEach((id) => {
            attachedAssetsIds.push(id)
          })
        }
      })
    }
    if (tab === SpecificAssetsTabs.EC2) {
      dispatch(selectAllEC2Asset(isSelected))
      assets.forEach((asset) => {
        if (
          asset.asset instanceof EC2 &&
          !selectedAssetsIds.has(asset.asset.id)
        ) {
          asset.asset.ebsIds.forEach((id) => {
            attachedAssetsIds.push(id)
          })
        }
      })
    }

    if (!isSelected) {
      setSelectedAssetsIds(
        (prevState) => new Set([...attachedAssetsIds, ...prevState])
      )
    } else {
      const disabledIds = new Set(selectedAssetsIds)
      attachedAssetsIds.forEach((id) => {
        disabledIds.delete(id)
      })

      setSelectedAssetsIds(disabledIds)
    }
  }

  function isSelectedAllEbs() {
    return ebsAssets.every((item) => selectedAssetsIds.has(item.asset.id))
  }

  function isSelectedAllEc2() {
    return ec2Assets.every((item) => selectedAssetsIds.has(item.asset.id))
  }

  const isDisabledHeadersCheckboxForEbs = isSelectedAllEbs()
  const isDisabledHeadersCheckboxForEc2 = isSelectedAllEc2()

  return {
    selectedAssetsIds,
    selectedEbsRows,
    selectedEc2Rows,
    isEbsTabDisabled,
    isEc2TabDisabled,
    isDisabledHeadersCheckboxForEbs,
    isDisabledHeadersCheckboxForEc2,
    setSelectedEbsRows,
    setSelectedEc2Rows,
    handleRowChange,
    onSelectAllRows,
  }
}
