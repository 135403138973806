import React from 'react'

interface Props {
  fill?: string
  width?: string
  height?: string
}

export function ServersWithThreatsIcon({
  fill = 'none',
  height = '32',
  width = '32',
}: Props) {
  return (
    <svg
      data-testid="servers-with-threats-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
    >
      <rect width="32" height="32" rx="8" fill="white" />
      <path
        d="M16.0002 7.10938C13.9637 7.10938 12.0542 8.12085 10.896 9.77951C7.80888 9.89329 5.3335 12.44 5.3335 15.5538C5.3335 18.7396 7.9255 21.3316 11.1113 21.3316H17.7779V20.4427C17.7779 20.1254 17.8417 19.8312 17.9342 19.5538H11.1113C8.90594 19.5538 7.11127 17.7592 7.11127 15.5538C7.11127 13.3485 8.90594 11.5538 11.1113 11.5538L11.8752 11.5955L12.1495 11.1215C12.9424 9.74286 14.4179 8.88715 16.0002 8.88715C18.1975 8.88715 20.0398 10.463 20.3838 12.6337L20.5175 13.4878L21.495 13.3594C21.5883 13.3469 21.6811 13.3316 21.7779 13.3316C22.6704 13.3316 23.4704 13.7142 24.0384 14.3177C25.0899 14.5328 25.9863 15.1139 26.6147 15.9201C26.3561 13.5317 24.3949 11.6471 21.9602 11.5573C21.1905 8.93685 18.7993 7.10938 16.0002 7.10938ZM23.1113 15.9983C21.6002 15.9983 20.4446 17.1538 20.4446 18.6649V19.5538C19.9113 19.5538 19.5557 19.9094 19.5557 20.4427V23.9983C19.5557 24.5316 19.9113 24.8872 20.4446 24.8872H25.7779C26.3113 24.8872 26.6668 24.5316 26.6668 23.9983V20.4427C26.6668 19.9094 26.3113 19.5538 25.7779 19.5538V18.6649C25.7779 17.1538 24.6224 15.9983 23.1113 15.9983ZM23.1113 17.3316C23.8224 17.3316 24.4446 17.9538 24.4446 18.6649V19.5538H21.7779V18.6649C21.7779 17.9538 22.4002 17.3316 23.1113 17.3316Z"
        fill={fill}
      />
    </svg>
  )
}
