import React from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import AddSpecificPathsForm from '@forms/add-specific-paths/add-specific-paths.form'
import FormAddSpecificPathsInterface from '@lib/interfaces/form/form-add-specific-paths.interface'
import ModalCloseIcon from '@inline-img/general/modal-close-icon'

export interface AddSpecificPathsModalInputData {
  pathSelector: string
  pathsList: Record<string, Array<string>>
}

interface AddSpecificPathsModalData {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<FormAddSpecificPathsInterface>
  data?: Nullable<AddSpecificPathsModalInputData>
  loading: boolean
  show: boolean
  isShowPrefix?: boolean
}

function AddSpecificPathsModal({
  onCancel,
  onSubmit,
  show,
  loading,
  data,
  isShowPrefix = true,
}: AddSpecificPathsModalData) {
  return (
    <BaseModal className="addPathsModal" onBgClick={onCancel} show={show}>
      <div className="modalHeaderWrapV2">
        <div className="mhTitle">Add Specific Paths</div>
        <div
          className="closableIcon"
          title="Close"
          onClick={onCancel}
          role="button"
        >
          <ModalCloseIcon />
        </div>
      </div>
      <div className="modalFormContentV2">
        <AddSpecificPathsForm
          onCancel={onCancel}
          onSubmit={onSubmit}
          loading={loading}
          data={data}
          isShowPrefix={isShowPrefix}
        />
      </div>
    </BaseModal>
  )
}

export default AddSpecificPathsModal
