import {
  Title,
  ControlledSelect,
  ControlledInput,
  MaxWidthFormControl,
  reportOptions,
  FormVariant,
} from '@features/reports/schedules/shared'
import Box from '@mui/material/Box'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, FormProvider } from 'react-hook-form'
import React, { useEffect, useMemo, useState } from 'react'
import { FormData, FormSchema } from './validations/schema'
import { FORM_FIELDS } from './form-field-names'
import { getDefaultValues } from './default-values'
import moment from 'moment-timezone'
import { AWSForm, BackupConnectorForm } from './forms'
import {
  useReportSourceType,
  useTabsActions,
  useEditingItemId,
} from '@features/reports/schedules/use-data-store'
import { useOvaAccounts } from '@features/reports/schedules/use-ova-accounts-store'
import { transformAccountsToSelectOptions } from '@features/reports/schedules/shared/transform-accounts-to-select-options'
import { useTimezoneState } from '@components-complex/account-profile/timezone/use-timezone-state'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE, UserSettings } from '@lib/constants'
import { useRecipientsByIds } from '@features/reports/schedules/use-users-list-store'
import TimeFormatConstants from '@lib/constants/time-format.constant'

const formViews = {
  [FormVariant.AWS]: AWSForm,
  [FormVariant.BackupConnector]: BackupConnectorForm,
}

const defaultTimezone = {
  value: TimeFormatConstants.UTC_TIMEZONE_VALUE,
  label: TimeFormatConstants.UTC_TIMEZONE_VALUE,
}

function ScheduleForm() {
  const { getTabItemById, setIsFormValid, setReportSourceType, setFormData } =
    useTabsActions()

  const editingItemId = useEditingItemId()
  // @ts-ignore
  const reportSchedule = getTabItemById(editingItemId)
  const reportScheduleRecipients = useRecipientsByIds(
    reportSchedule?.recipientIds
  )

  const timezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value

  const scheduledDate = moment.tz(
    {
      year: reportSchedule?.schedule?.startsOn?.year || moment().year(),
      month:
        (reportSchedule?.schedule?.startsOn?.month || moment().month() + 1) - 1,
      day: reportSchedule?.schedule?.startsOn?.day || moment().date(),
    },
    timezone
  )

  const reportScheduledTimezone = reportSchedule?.timezone
    ? {
        value: reportSchedule.timezone,
        label: reportSchedule.timezone,
      }
    : defaultTimezone

  const [calendarValue, setCalendarValue] = useState(scheduledDate)

  const { selectedTimezone, handleTimezoneSelectChange } = useTimezoneState(
    reportScheduledTimezone
  )

  const reportSourceType = useReportSourceType() as string

  const ovaAccounts = transformAccountsToSelectOptions(useOvaAccounts())

  const defaultReportFormats = reportOptions.find(
    (option) => option.value === reportSourceType
  )?.formats

  const methods = useForm<FormData>({
    // @ts-ignore
    defaultValues: getDefaultValues({
      data: reportSchedule,
      reportSourceType,
      recipients: reportScheduleRecipients,
      formats: defaultReportFormats as Array<string>,
    }),
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
  })

  const selectedReportValue = methods.watch(FORM_FIELDS.REPORT_TYPE)
  const nameValue = methods.watch(FORM_FIELDS.NAME)
  // @ts-ignore
  const descriptionValue = methods.watch(FORM_FIELDS.DESCRIPTION)
  // @ts-ignore
  const frequencyValue = methods.watch(FORM_FIELDS.FREQUENCY)
  // @ts-ignore
  const rangeOfDataValue = methods.watch(FORM_FIELDS.RANGE_OF_DATA)
  const recipients = methods.watch(FORM_FIELDS.RECIPIENTS)
  const selectedFormat = methods.watch(FORM_FIELDS.FORMATS)
  const customSchedule = methods.watch(FORM_FIELDS.CUSTOM_SCHEDULE)

  const { isValid } = methods.formState

  const selectedReportOption = useMemo(
    () => reportOptions.find((option) => option.value === selectedReportValue),
    [selectedReportValue]
  )

  const selectedFormVariant = selectedReportOption?.type

  const SelectedFormComponent = selectedFormVariant
    ? formViews[selectedFormVariant]
    : null

  const availableFormats = selectedReportOption?.formats || []

  const handleChangeCalendar = (value: number) => {
    const dateWithTimezone = moment(value).tz(timezone)

    setCalendarValue(dateWithTimezone)
  }

  useEffect(() => {
    const values = methods.getValues()

    /* eslint-disable no-unused-vars */
    const {
      // @ts-ignore
      name,
      ...valuesRest
    } = values
    const date = moment(calendarValue)
    setIsFormValid(isValid)

    setFormData({
      ...valuesRest,
      name: nameValue,
      recipientsIds: recipients?.map(
        // @ts-ignore
        (recipient: { value: string }) => recipient.value
      ),
      startOn: {
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      },
      timezone: selectedTimezone.value,
      id: reportSchedule?.id,
      customSchedule: customSchedule,
    })
  }, [
    isValid,
    reportSourceType,
    calendarValue,
    selectedTimezone,
    reportSchedule,
    nameValue,
    descriptionValue,
    frequencyValue,
    rangeOfDataValue,
    recipients,
    selectedFormat,
    customSchedule,
  ])

  useEffect(() => {
    setReportSourceType(selectedReportValue)
  }, [selectedReportValue])

  return (
    <FormProvider {...methods}>
      <Box>
        <Title>Report Type</Title>
        <MaxWidthFormControl>
          <ControlledSelect
            disabled={Boolean(editingItemId)}
            name={FORM_FIELDS.REPORT_TYPE}
            isLoading={false}
            errors={{}}
            options={reportOptions.map(({ label, value }) => ({
              label,
              value,
            }))}
            placeholder="Select a Report"
          />
        </MaxWidthFormControl>
        <Title>Name</Title>
        <MaxWidthFormControl>
          <ControlledInput name={FORM_FIELDS.NAME} placeholder="Name" />
        </MaxWidthFormControl>
        <Title>Description (Optional)</Title>
        <ControlledInput
          name={FORM_FIELDS.DESCRIPTION}
          placeholder="Description"
        />
        {SelectedFormComponent && (
          <SelectedFormComponent
            timeZone={selectedTimezone as any}
            calendarValue={calendarValue as any}
            onCalendarChangeValue={handleChangeCalendar}
            formats={availableFormats}
            ovaAccounts={ovaAccounts as any}
            onTimezoneSelectChange={handleTimezoneSelectChange as any}
          />
        )}
      </Box>
    </FormProvider>
  )
}

export default ScheduleForm
