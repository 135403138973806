/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { createColumn, createDisplayColumn, RowHeight } from '@components/table'
import { EngineCallback } from '@lib/engine-types'
import { ScansRun } from 'blues-corejs/dist/models'
import {
  CompletionTimeCell,
  DataSourceCell,
  ScanStatusCell,
  ScanTypeCell,
  ThreatsCell,
} from './cells'

// Direct scan - render only bad/good icons
// Backup - renders grey/bad/good icons

export function getScansTableColumnDefinitions({
  onScansClick,
}: {
  onScansClick: EngineCallback<ScansRun>
}) {
  return [
    createDisplayColumn<ScansRun>({
      id: 'scan-status',
      meta: {
        width: '40',
        height: RowHeight.SMALL,
      },
      cell: ({ row }) => <ScanStatusCell scans={row.original} />,
    }),
    createDisplayColumn<ScansRun>({
      header: 'Risks',
      id: 'threats',
      meta: {
        width: '100',
        height: RowHeight.SMALL,
      },
      cell: ({ row }) => <ThreatsCell scans={row.original} />,
    }),
    createColumn<ScansRun>('malwareScansList', {
      header: 'Scan Time',
      id: 'completion-time',
      sortingFn: ({ original: rowA }, { original: rowB }) => {
        const scansA = [
          ...rowA.filesystemChecksList,
          ...rowA.malwareScansList,
          ...rowA.ransomwareScansList,
        ]

        const scansB = [
          ...rowB.filesystemChecksList,
          ...rowB.malwareScansList,
          ...rowB.ransomwareScansList,
        ]

        const scanTimeA = Math.max(
          ...scansA.map((scan) => scan.createdAt.getTime())
        )

        const scanTimeB = Math.max(
          ...scansB.map((scan) => scan.createdAt.getTime())
        )

        return scanTimeB - scanTimeA
      },
      cell: ({ row }) => (
        <CompletionTimeCell onScansClick={onScansClick} scans={row.original} />
      ),
    }),
    createDisplayColumn<ScansRun>({
      header: 'Scan Type',
      id: 'scan-type',
      cell: ({ row }) => <ScanTypeCell scans={row.original} />,
    }),
    createDisplayColumn<ScansRun>({
      header: 'Source',
      id: 'source',
      cell: ({ row }) => <DataSourceCell scans={row.original} />,
    }),
  ]
}
