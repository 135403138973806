/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useModalFiltersStore } from '@features/jobs/cloud-connector-jobs/filters-modal/cloud-connector-filters-store'
import { FilterCheckbox } from '@features/jobs/cloud-connector-jobs/shared'
import { computeAssetName } from '@features/jobs/cloud-connector-jobs/helpers'
import {
  usePossibleCloudConnectorJobFilters,
  useAssetMap,
} from '@features/jobs/cloud-connector-jobs/stores'
import {
  useModalFilterActions,
  useSelectedModalFilters,
} from '../cloud-connector-filters-store'

export function AssetIdsSection() {
  const { assetIdsList: initialAssetIdsList } =
    usePossibleCloudConnectorJobFilters()

  const assetsMap = useAssetMap()

  const filterSearchValue = useModalFiltersStore(
    (state) => state.filterSearchValue
  )

  const { setFilter } = useModalFilterActions()

  const assetIdsList = useMemo(
    () =>
      Array.from(initialAssetIdsList).filter((assetId) => {
        const assetName = computeAssetName(assetsMap.get(assetId))

        return assetName.toLowerCase().includes(filterSearchValue)
      }),
    [initialAssetIdsList, filterSearchValue]
  )

  const { assetIdsList: selectedAssetIdsList } = useSelectedModalFilters()

  if (assetIdsList.length === 0) {
    return null
  }

  function handleCheckboxChange(checked: boolean, assetId: string) {
    const newAssetList = new Set(selectedAssetIdsList)

    if (checked) {
      newAssetList.add(assetId)
    } else {
      newAssetList.delete(assetId)
    }

    setFilter('assetIdsList', newAssetList)
  }

  return (
    <Fragment>
      <Typography
        fontSize="12px"
        lineHeight="140%"
        marginBottom="10px"
        marginTop="10px"
        color="#71768e"
        fontWeight="700"
      >
        Asset ID
      </Typography>
      <Box display="flex" gap="12px" flexDirection="column">
        {assetIdsList.map((assetId) => {
          const assetName = computeAssetName(assetsMap.get(assetId))

          return (
            <FilterCheckbox
              label={assetName}
              key={assetId}
              onChange={(_event, checked) =>
                handleCheckboxChange(checked, assetId)
              }
              checked={selectedAssetIdsList.has(assetId)}
            />
          )
        })}
      </Box>
    </Fragment>
  )
}
