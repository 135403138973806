import { z } from 'zod'

export const ChannelsSchema = z
  .object({
    slackIntegration: z.boolean().optional(),
    webhooks: z
      .array(
        z.object({
          value: z.string().min(1, 'Webhook value cannot be empty'),
        })
      )
      .optional(),
    emails: z
      .array(
        z.object({
          email: z.string().email('Invalid email format'),
        })
      )
      .optional(),
    existingConsoleUsers: z
      .array(
        z.object({
          value: z.string().min(1, 'Console user value cannot be empty'),
        })
      )
      .optional(),
  })
  .refine(
    (data) =>
      data.slackIntegration ||
      (data.webhooks && data.webhooks.length > 0) ||
      (data.emails && data.emails.length > 0) ||
      (data.existingConsoleUsers && data.existingConsoleUsers.length > 0),
    {
      message: 'At least one field must have a non-empty value.',
    }
  )

export type ChannelsForm = z.infer<typeof ChannelsSchema>
