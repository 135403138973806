import { useRef, useEffect } from 'react'
import Box from '@mui/material/Box'

import styles from './element.module.css'

export type ScaleElementOwnProps = {
  index: number
  content: string
  onMount: (index: number, offsetTop: number) => void
}

function ScaleElement({ onMount, index, content }: ScaleElementOwnProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = ref.current
    if (element) {
      const { offsetTop } = element
      onMount(index, offsetTop)
    }
  }, [index, onMount])
  return (
    <Box ref={ref} className={styles.root}>
      {content}
    </Box>
  )
}

export default ScaleElement
