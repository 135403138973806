import React from 'react'

export default function JobInProgressIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7946_116077"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7946_116077)">
        <path
          d="M7.93734 15.0213C7.65956 15.2019 7.37831 15.2123 7.09359 15.0526C6.80886 14.8928 6.6665 14.6463 6.6665 14.313V5.68798C6.6665 5.35464 6.80886 5.10812 7.09359 4.94839C7.37831 4.78867 7.65956 4.79909 7.93734 4.97964L14.729 9.29214C14.979 9.45881 15.104 9.69492 15.104 10.0005C15.104 10.306 14.979 10.5421 14.729 10.7088L7.93734 15.0213Z"
          fill="#32B6F3"
        />
      </g>
    </svg>
  )
}
