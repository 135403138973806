/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Backup } from 'blues-corejs/dist'
import { computeBackupKind } from '../helpers'
import { RowTitle } from './row-title'
import Typography from '@mui/material/Typography'

export function BackupKind({ backup }: { backup: Backup }) {
  const computedKind = computeBackupKind(backup)

  if (!computedKind) {
    return null
  }

  return (
    <>
      <RowTitle title="Kind" />
      <Typography variant="caption" fontSize="14px" fontWeight="400">
        {computedKind}
      </Typography>
    </>
  )
}
