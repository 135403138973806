import React from 'react'
import { EngineCallback, EngineCallbackPure, Nullable } from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import FormAddUserInterface from '@lib/interfaces/form/form-add-user.interface'
import AddUserForm from '@forms/add-user/add-user.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import UserRoleModel from '@lib/models/user-role.model'

export interface AddUserModalInputData {
  possibleRoles: Nullable<Array<UserRoleModel>>
}

interface AddUserModalData {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<FormAddUserInterface>
  data: Nullable<AddUserModalInputData>
  loading: boolean
  show: boolean
}

function AddUserModal({
  onCancel,
  onSubmit,
  data,
  show,
  loading,
}: AddUserModalData) {
  return (
    <BaseModal className="modalDefaultV2Small" onBgClick={onCancel} show={show}>
      <div className="modalHeaderWrapV2">
        <div className="mhTitle">Add New User</div>
      </div>

      <div className="modalFormContentV2 mt20">
        {data?.possibleRoles && (
          <AddUserForm
            errorGroups={[ErrorGroupConstants.USER]}
            possibleRoles={data.possibleRoles}
            onSubmit={onSubmit}
            onCancel={onCancel}
            loading={loading}
          />
        )}
      </div>
    </BaseModal>
  )
}

export default AddUserModal
