/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { Button, TextField } from '@mui/material'
import RegexConstant from '@lib/constants/regex.constant'
import DataHelper from '@lib/helpers/data.helper'
import { useDispatch, useSelector } from 'react-redux'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { requestPoliciesRetention } from '@store/actions/policies-retention.action'
import {
  getAddPolicyRetentionComplete,
  getPoliciesRetentionNames,
} from '@store/selectors/policies-retention.selector'
import {
  getAddRetentionPolicyName,
  getPolicyRetentionAccountIds,
  getPolicyRetentionAssetSources,
  getPolicyRetentionAssetsSelectedFilters,
  getPolicyRetentionBackupTypes,
  getPolicyRetentionRegions,
  getPolicyRetentionTags,
  getPolicyRetentionVaults,
  getRecoveryPeriod,
} from '@store/selectors/add-retention-policies.selector'
import {
  addRetentionPolicy,
  setAddPolicyRetentionAccountIDs,
  setAddPolicyRetentionAssetSources,
  setAddPolicyRetentionBackupTypes,
  setAddPolicyRetentionComplete,
  setAddPolicyRetentionRegions,
  setAddPolicyRetentionSelectedFilters,
  setAddPolicyRetentionTags,
  setAddPolicyRetentionVaults,
  setAddRetentionPolicyName,
  setRecoveryPeriod,
} from '@store/actions/add-retention-policy.action'
import PeriodForm from '@forms/period/period.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import InfoHeader from '@components-composite/info-header/InfoHeader'
import AssetsSelectComplex from '@components-complex/assets-select-complex/AssetsSelectComplex'
import { getSelectedAssetsSelect } from '@store/selectors/assets-select.selector'
import {
  setAllSelectedAssets,
  setAssetIdsMissingInLiveAssets,
  setAssetsLoadingMarker,
  setAssetsSelectEnabledTabs,
  setAssetsSelectFilters,
} from '@store/actions/assets-select.action'
import RecoveryPointTypes from '@components-complex/recovery-point-types/RecoveryPointTypes'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import { VIRow } from '@lib/engine-types'
import PagePathConstant from '@lib/constants/page-path.constant'
import { EmptyFunc } from '@lib/constants/app.constant'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import {
  AssetLoadingMarker,
  defaultSelectedFilters,
} from '@store/reducers/assets-select.reducer'
import useGetSupportedAssetKinds from './useGetSupportedAssetKinds'
import { INVALID_POLICY_NAME } from '@lib/constants/policies/policies-constants'
import { PoliciesRoutes, PolicyTab, useNavigation } from '@lib/router'

const TEXT_MESSAGE = `Recovery points will be collapsed at 12am on the selected days (except in the case of hourly collapse),
on Mondays and Thursdays for weekly collapse, and the first day of every calendar month in the case of monthly collapse`

interface Props {}

function AddRetentionPolicyComplex({}: Props) {
  const { setBreadcrumbsPaths } = useLayoutContext()
  const dispatch = useDispatch()
  const router = useNavigation()
  const policyRetentionName = useSelector(getAddRetentionPolicyName)
  const existingPoliciesRetentionNames = useSelector(getPoliciesRetentionNames)
  const addCompleted = useSelector(getAddPolicyRetentionComplete)

  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const policyRetentionAccountIds = useSelector(getPolicyRetentionAccountIds)
  const policyRetentionRegions = useSelector(getPolicyRetentionRegions)
  const policyRetentionVaults = useSelector(getPolicyRetentionVaults)
  const policyRetentionBackupTypes = useSelector(getPolicyRetentionBackupTypes)
  const policyRetentionAssetSources = useSelector(
    getPolicyRetentionAssetSources
  )
  const policyRetentionTags = useSelector(getPolicyRetentionTags)
  const periodFormData = useSelector(getRecoveryPeriod)
  const selectedAssetsFilters = useSelector(
    getPolicyRetentionAssetsSelectedFilters
  )

  const isLoading = usePreloader(PreloaderConstants.ADD_RETENTION_POLICY)

  useEffect(() => {
    dispatch(requestPoliciesRetention())
    dispatch(setAddRetentionPolicyName(''))
    dispatch(setAssetsSelectEnabledTabs([]))
    dispatch(setAllSelectedAssets([]))
    dispatch(setAddPolicyRetentionAccountIDs([]))
    dispatch(setAddPolicyRetentionRegions([]))
    dispatch(setAddPolicyRetentionVaults([]))
    dispatch(setAddPolicyRetentionAssetSources([]))
    dispatch(setAddPolicyRetentionBackupTypes([]))
    dispatch(setAddPolicyRetentionTags([]))
    //dispatch(setAddPolicyRetentionSelectedFilters([]))

    setBreadcrumbsPaths([
      {
        href: PagePathConstant.POLICIES_RETENTION,
        text: titlesForBreadcrumb(PagePathConstant.POLICIES) ?? '',
      },
    ])
    dispatch(setAddPolicyRetentionSelectedFilters(defaultSelectedFilters()))
    dispatch(setAssetsSelectFilters([]))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setAssetsSelectFilters([]))
      dispatch(setAssetIdsMissingInLiveAssets([]))
      dispatch(setAssetsLoadingMarker(AssetLoadingMarker.INITIAL))
    }
  }, [])

  useEffect(() => {
    if (!addCompleted) {
      return
    }
    router.push(PoliciesRoutes.policiesTableRoute(PolicyTab.RETENTION))

    return () => {
      dispatch(setAddPolicyRetentionComplete(false))
    }
  }, [addCompleted])

  const [error, setError] = useState<string>('')
  const [showAssetsTable, setShowAssetsTable] = useState<boolean>(false)
  const [formHasError, setFormHasError] = useState<boolean>(false)
  const isSelectedAssets = Boolean(selectedAssets.length)

  const periodFormRef = useRef({ submit: EmptyFunc })
  const selectedAssetsRow =
    DataHelper.getSelectedAssetsForRecoveryPointTypesSelect(selectedAssets)

  const onInputKeyup = (e: any) => {
    // the retention policy name is required, and it must be unique among other
    // existing policies with only alphanumeric, spaces and +-=._:/@ are allowed Maximum 255 characters.

    const trimmedPolicyName: string = e.target.value.trim()
    const policyNameValid: boolean =
      RegexConstant.POLICY_NAME.test(policyRetentionName)
    const policyNameExists: boolean =
      existingPoliciesRetentionNames.includes(trimmedPolicyName)
    const policyNameDefault: boolean =
      DataHelper.isRetentionDefaultPolicy(policyRetentionName)

    if (error) {
      setError('')
    }

    if (
      trimmedPolicyName &&
      policyNameValid &&
      !policyNameExists &&
      !policyNameDefault
    ) {
      return
    }

    if (!trimmedPolicyName) {
      setError('The Name is required')
      return
    }

    if (policyNameExists) {
      setError(`The Name ${trimmedPolicyName} already exists`)
      return
    }

    if (policyNameDefault) {
      setError(
        `"${trimmedPolicyName}" is reserved for a default policy, use another name for custom policy.`
      )
      return
    }

    if (!policyNameValid) {
      setError(INVALID_POLICY_NAME)
      return
    }
  }
  const onRetentionPolicyNameChange = (newPolicyName: string) => {
    dispatch(setAddRetentionPolicyName(newPolicyName))
  }

  const onSelectedFilters = (category: SelectCategory, value: VIRow) => {
    let allAssetsFilters: VIRow = []
    switch (category) {
      case SelectCategory.ACCOUNT_IDS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.ACCOUNT_IDS,
          value,
          selectedAssetsFilters
        )

        dispatch(setAddPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setAddPolicyRetentionAccountIDs(value))
        return
      case SelectCategory.REGIONS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.REGIONS,
          value,
          selectedAssetsFilters
        )

        dispatch(setAddPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setAddPolicyRetentionRegions(value))
        return
      case SelectCategory.VAULTS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.VAULTS,
          value,
          selectedAssetsFilters
        )

        dispatch(setAddPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setAddPolicyRetentionAccountIDs([]))
        dispatch(setAddPolicyRetentionRegions([]))
        dispatch(setAddPolicyRetentionVaults(value))
        return
      case SelectCategory.ASSET_SOURCES:
        const tabsEnabled = DataHelper.getEnabledAssetSelectTabs(value)
        dispatch(setAssetsSelectEnabledTabs(tabsEnabled))
        dispatch(setAddPolicyRetentionAssetSources(value))
        return
      case SelectCategory.BACKUP_TYPES:
        dispatch(setAddPolicyRetentionBackupTypes(value))
        return
      case SelectCategory.TAGS:
        allAssetsFilters = DataHelper.getAllSelectedAssetsFilters(
          SelectCategory.TAGS,
          value,
          selectedAssetsFilters
        )

        dispatch(setAddPolicyRetentionSelectedFilters(allAssetsFilters))
        dispatch(setAddPolicyRetentionTags(value))
        return
      case SelectCategory.ALL:
        dispatch(setAddPolicyRetentionAccountIDs([]))
        dispatch(setAddPolicyRetentionRegions([]))
        dispatch(setAddPolicyRetentionVaults([]))
        dispatch(setAddPolicyRetentionAssetSources([]))
        dispatch(setAddPolicyRetentionBackupTypes([]))
        dispatch(setAddPolicyRetentionTags([]))
        dispatch(setAddPolicyRetentionSelectedFilters(defaultSelectedFilters()))
        dispatch(setAssetsSelectEnabledTabs([]))
        return
    }
  }

  const onCancel = () => {
    dispatch(setAllSelectedAssets([]))
    setShowAssetsTable(false)
  }

  const onRecoveryPeriodChange = (newRecoveryPeriod: FormPeriodInterface) => {
    dispatch(setRecoveryPeriod(newRecoveryPeriod))
  }

  const onAddRetentionPolicySubmit = () => {
    // started submit PeriodForm
    periodFormRef.current.submit()
    if (!formHasError) {
      setTimeout(() => {
        dispatch(addRetentionPolicy())
      }, 5)
    }
  }

  //check feture flags
  const supportedAssetKinds = useGetSupportedAssetKinds()

  return (
    <div className="innerContent innerContentBlueBackground jsAddRetentionPolicy">
      <div className="wrap-1658824321803">
        <div className={clsx({ dNone: showAssetsTable })}>
          <div
            className={clsx('newFormSingleRow v2StaticTextInput', {
              statusErrorClass: !!error,
            })}
          >
            <TextField
              disabled={isLoading}
              value={policyRetentionName}
              onBlur={onInputKeyup}
              onKeyUp={onInputKeyup}
              inputProps={{ maxLength: 255 }}
              onChange={(e) => onRetentionPolicyNameChange(e.target.value)}
              label="Policy name"
              variant="outlined"
              helperText={error}
              className="jsRetentionPolicyNameField"
              error={!!error}
              placeholder="Name..."
            />
          </div>

          {/* Recovery point types Block */}
          <div className="topLabel">Recovery point types</div>
          <div className="addRetentionPolicyWrap jsRecoveryPointTypesWrapper">
            <RecoveryPointTypes
              loading={isLoading}
              onFiltersChange={onSelectedFilters}
              selectedFilters={[
                {
                  name: SelectCategory.ACCOUNT_IDS,
                  options: policyRetentionAccountIds,
                },
                {
                  name: SelectCategory.REGIONS,
                  options: policyRetentionRegions,
                },
                {
                  name: SelectCategory.VAULTS,
                  options: policyRetentionVaults,
                },
                {
                  name: SelectCategory.BACKUP_TYPES,
                  options: policyRetentionBackupTypes,
                },
                {
                  name: SelectCategory.ASSET_SOURCES,
                  options: policyRetentionAssetSources,
                },
                {
                  name: SelectCategory.TAGS,
                  options: policyRetentionTags,
                },
              ]}
              emptyFilters={DataHelper.buildRetentionPolicyEmptyFilters()}
              allSpecificAssets={selectedAssetsRow}
            />
          </div>

          {/* Find asset IDs Block */}
          {!isSelectedAssets && (
            <Button
              disabled={isLoading}
              type="button"
              variant="text"
              color="primary"
              className="mb20 jsShowAssetsTable"
              onClick={() => setShowAssetsTable(true)}
            >
              Add Assets IDs
            </Button>
          )}

          {isSelectedAssets && (
            <div className="flexRowJustifyStart mb20">
              <span className="selectedAssets">
                {selectedAssets.length}{' '}
                {selectedAssets.length === 1
                  ? 'Asset selected'
                  : 'Assets selected'}
              </span>
              <Button
                disabled={isLoading}
                type="button"
                variant="text"
                color="primary"
                onClick={() => setShowAssetsTable(true)}
              >
                Edit Assets IDs
              </Button>
            </div>
          )}
        </div>

        {showAssetsTable && (
          <>
            <div className="controlHeaderBlock">
              <div className="controlHeader">
                <div className="controlHeaderLabelUnderlined jsFindAssetsIdsHeader">
                  Find asset IDs
                </div>
              </div>
            </div>
            <div className="policiesBlockSubheader mt25">
              Select specific assets
            </div>
            <div className="policiesBlockLabel fontMedium mb20">
              Select the specific assets from the table below to add to the
              policy. <br />
              Note that only assets belonging to the Cloud Connectors with
              “Connected” status are displayed below.
            </div>
            <AssetsSelectComplex
              isLoading={isLoading}
              supportedAssetKinds={supportedAssetKinds}
              initSelectedFilters={selectedAssetsFilters}
              storeInitialFilters
              disableProtectionFilter={false}
              isLiveAssets={false}
            />
            <BasePortalButtons alwaysInPortal>
              <div className="portalBetweenButtons">
                <Button
                  className="jsSourceIdCancel"
                  type="button"
                  variant="text"
                  color="primary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="jsAddRetentionPolicyDone"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowAssetsTable(false)}
                >
                  Done
                </Button>
              </div>
            </BasePortalButtons>
          </>
        )}

        {/* Period Block */}
        <div className={clsx({ dNone: showAssetsTable })}>
          <div>
            <div className="topLabel">Recovery period</div>
            <InfoHeader type="info" description={TEXT_MESSAGE} />
          </div>

          <PeriodForm
            defaultValues={periodFormData}
            onSubmit={onRecoveryPeriodChange}
            loading={isLoading}
            errorGroups={[ErrorGroupConstants.POLICIES_RETENTION]}
            onFormValid={(e) => setFormHasError(!e)}
            onTriggerForm={periodFormRef}
          />

          <BasePortalButtons alwaysInPortal>
            <div className="portalBetweenButtons">
              <Button
                className="jsAddRetentionPolicyCancel"
                type="button"
                variant="text"
                color="primary"
                onClick={() =>
                  router.push(
                    PoliciesRoutes.policiesTableRoute(PolicyTab.RETENTION)
                  )
                }
              >
                Cancel
              </Button>
              <Button
                className="jsAddRetentionPolicySubmit"
                disabled={isLoading || !!error || !policyRetentionName?.trim()}
                type="button"
                variant="contained"
                color="primary"
                onClick={onAddRetentionPolicySubmit}
              >
                Save
              </Button>
            </div>
          </BasePortalButtons>
        </div>
      </div>
    </div>
  )
}

export default AddRetentionPolicyComplex
