/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import {
  FilesystemScanCheck,
  MalwareScan,
  RansomwareScan,
} from 'blues-corejs/dist'

import { AssetDetails } from './asset-details'
import { ScanDetails } from './scan-details'
import { DetectedTreatsIcons, ThreatsList } from '@features/asset-page/shared'
import { ThreatSummary } from '@features/asset-page/shared/threat-summary'
import { Asset } from './types'
import { defaultDialogSx } from './styles'

import ScanDetailsHeader from './header'
import useFetchThreatsByIds from '@components-complex/dashboard-pages-v3/hooks/use-fetch-threats-by-ids'

interface Props {
  readonly asset: Asset
  readonly scans: Array<MalwareScan | RansomwareScan | FilesystemScanCheck>
  isOpen: boolean
  onClose: () => void
}

function ScanDetailsModal({ asset, scans, isOpen, onClose }: Props) {
  const threatsIds = scans.flatMap((scan) => scan.threatIds)

  const {
    dataThreats: threats,
    isLoadingThreats,
    fetchThreatsWithoutLoader,
  } = useFetchThreatsByIds(threatsIds)

  function renderDialogContent() {
    if (isLoadingThreats) {
      return <Skeleton variant="rectangular" width="100%" height="100%" />
    }

    return (
      <>
        <ScanDetailsHeader onModalClose={onClose} asset={asset} scans={scans} />
        <AssetDetails asset={asset} />
        <Box display="flex" alignItems="flex-start" gap="32px" marginTop="16px">
          <DetectedTreatsIcons scans={scans} />
          <ThreatSummary threats={threats} />
        </Box>
        <Divider orientation="horizontal" sx={{ marginTop: '24px' }} />
        <ScanDetails scans={scans} />
        <Divider orientation="horizontal" sx={{ marginY: '15px' }} />
        <ThreatsList
          threats={threats}
          scans={scans}
          fetchDataThreats={fetchThreatsWithoutLoader}
        />
      </>
    )
  }

  return (
    <Dialog
      // FIXME: when we use it, the dialog is not fully rendered.
      // TransitionComponent={MoreDetailsDialogTransition}
      open={isOpen}
      onClose={onClose}
      sx={defaultDialogSx}
    >
      <DialogContent>{renderDialogContent()}</DialogContent>
    </Dialog>
  )
}

export default ScanDetailsModal
