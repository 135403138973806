import React, { memo } from 'react'
import { Typography, LinearProgress, Box } from '@mui/material'

function InProgressJobCell({ progress = 0 }: { progress?: number }) {
  const formattedProgress = Math.floor(progress)

  if (progress === 0) {
    return null
  }

  return (
    <Box minWidth="150px">
      <Typography fontSize={14}>In progress ({formattedProgress}%) </Typography>
      <LinearProgress value={progress} variant="determinate" />
    </Box>
  )
}

export default memo(InProgressJobCell)
