/* eslint-disable import/no-extraneous-dependencies */
import { WidgetHeader } from '@features/DashboardV4/common'
import useManagementTags from '@features/DashboardV4/common/use-management-view/useManagementTags'
import React from 'react'
import clsx from 'clsx'
import styles from './file-scans.module.css'
import LangHelper from '@lib/helpers/lang.helper'
import NumHelper from '@lib/helpers/num.helper'
import {
  ScansAndTestsSkeletonLoader,
  useFetchAwsFileScans,
  computeFileScansLast30Days,
} from '@features/DashboardV4/ScansAndTests'
import type { RegionStatistics } from '@lib/clients/krabs-aws-statistics/types'
import Box from '@mui/material/Box'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'
import { useNavigation } from '@lib/router/contexts/navigation-context'
import { ActiveThreatsRoutes } from '@lib/router/routes/active-threats/active-threats'

interface Props {
  accountRegionStatisticsList: Array<RegionStatistics>
  isLoadingRegionStatisticsList: boolean
}

export function FileScans({
  accountRegionStatisticsList,
  isLoadingRegionStatisticsList,
}: Props) {
  const router = useNavigation()

  const { isTagsEnabled } = useManagementTags()

  const { isLoading, cleanFiles, infectedFiles } = useFetchAwsFileScans()

  const fileScansLast30Days = computeFileScansLast30Days(
    accountRegionStatisticsList
  )

  const hintMessage = isTagsEnabled()
    ? 'The data is shown for all assets in your tenant.'
    : undefined

  const pluralizedTotalScannedFiles = LangHelper.pluralizeEn(
    'scan',
    fileScansLast30Days
  )

  const pluralizedTotalInfectedFiles = LangHelper.pluralizeEn(
    'file',
    infectedFiles
  )

  const pluralizedTotalCleanFiles = LangHelper.pluralizeEn('file', cleanFiles)

  const totalScannedFilesFormatted =
    NumHelper.formatLargeNumber(fileScansLast30Days)
  const totalInfectedFilesFormatted = NumHelper.formatLargeNumber(infectedFiles)
  const totalCleanFilesFormatted = NumHelper.formatLargeNumber(cleanFiles)

  const hasInfectedFiles = infectedFiles > 0

  const handleWidgetClick = () => {
    router.push(ActiveThreatsRoutes.awsAssetsActiveThreats)
  }

  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()

  const ConditionalRender = () => {
    if (isLoading || isLoadingRegionStatisticsList) {
      return <ScansAndTestsSkeletonLoader />
    }

    return (
      <Box>
        <Box
          className={clsx(styles.totalBlock, {
            [String(styles.entropyLayout)]: isEntropyEnabled,
          })}
          data-testid="jsScansAndTestsCardTotal"
        >
          <Box className={styles.number}>{totalScannedFilesFormatted}</Box>
          <Box>File {pluralizedTotalScannedFiles} in prior 30 days</Box>
        </Box>
        <Box
          className={clsx(styles.summaryWrapper, {
            [String(styles.entropyLayout)]: isEntropyEnabled,
          })}
        >
          <Box
            onClick={handleWidgetClick}
            data-testid="jsScansAndTestsCardProblem"
            className={clsx(styles.cardLink, styles.summaryBlock)}
          >
            <Box
              className={clsx(styles.number, {
                [String(styles.red500)]: hasInfectedFiles,
              })}
            >
              {totalInfectedFilesFormatted}
            </Box>
            <Box className={styles.label}>
              Infected {pluralizedTotalInfectedFiles}
            </Box>
          </Box>
          <Box
            className={styles.summaryBlock}
            data-testid="jsScansAndTestsCardCorrect"
          >
            <Box className={styles.number}>{totalCleanFilesFormatted}</Box>
            <Box className={styles.label}>
              Clean {pluralizedTotalCleanFiles}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={styles.card} data-testid="jsScansAndTestsCard">
      <WidgetHeader hintMessage={hintMessage} title="File Scans" />
      <ConditionalRender />
    </Box>
  )
}
