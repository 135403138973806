"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneratedOvaMspFailedScansReportParams = exports.GeneratedOvaMspInfectedFilesReportParams = exports.GeneratedOvaMspInfectedAssetsReportParams = exports.GeneratedOvaMspScannedAssetsSummaryReportParams = exports.GeneratedOvaMspCompletedScansReportParams = exports.GeneratedOvaMspAccountSummaryReportParams = exports.GeneratedOvaMspTenantSummaryReportParams = exports.GeneratedFailedScansReportParams = exports.GeneratedInfectedFilesReportParams = exports.GeneratedAwsInfectedAssetsReportParams = exports.GeneratedAwsScannedAssetsSummaryReportParams = exports.GeneratedAwsCompletedScansReportParams = exports.GeneratedAwsAccountSummaryReportParams = exports.GeneratedAwsTenantSummaryReportParams = exports.GeneratedAwsExecutiveReportParams = exports.GeneratedOvaMspExecutiveReportParams = exports.GeneratedOvaMspOperationalReportParams = exports.ReportKindCase = void 0;
var ReportKindCase;
(function (ReportKindCase) {
    ReportKindCase[ReportKindCase["KIND_NOT_SET"] = 0] = "KIND_NOT_SET";
    ReportKindCase[ReportKindCase["OVA_MSP_OPERATIONAL"] = 1] = "OVA_MSP_OPERATIONAL";
    ReportKindCase[ReportKindCase["OVA_MSP_EXECUTIVE"] = 2] = "OVA_MSP_EXECUTIVE";
    ReportKindCase[ReportKindCase["AWS_EXECUTIVE"] = 3] = "AWS_EXECUTIVE";
    ReportKindCase[ReportKindCase["AWS_TENANT_SUMMARY"] = 4] = "AWS_TENANT_SUMMARY";
    ReportKindCase[ReportKindCase["AWS_ACCOUNT_SUMMARY"] = 5] = "AWS_ACCOUNT_SUMMARY";
    ReportKindCase[ReportKindCase["AWS_COMPLETED_SCANS"] = 6] = "AWS_COMPLETED_SCANS";
    ReportKindCase[ReportKindCase["AWS_SCANNED_ASSETS_SUMMARY"] = 7] = "AWS_SCANNED_ASSETS_SUMMARY";
    ReportKindCase[ReportKindCase["AWS_INFECTED_ASSETS"] = 8] = "AWS_INFECTED_ASSETS";
    ReportKindCase[ReportKindCase["INFECTED_FILES"] = 9] = "INFECTED_FILES";
    ReportKindCase[ReportKindCase["FAILED_SCANS"] = 10] = "FAILED_SCANS";
    ReportKindCase[ReportKindCase["OVA_MSP_TENANT_SUMMARY"] = 11] = "OVA_MSP_TENANT_SUMMARY";
    ReportKindCase[ReportKindCase["OVA_MSP_ACCOUNT_SUMMARY"] = 12] = "OVA_MSP_ACCOUNT_SUMMARY";
    ReportKindCase[ReportKindCase["OVA_MSP_COMPLETED_SCANS"] = 13] = "OVA_MSP_COMPLETED_SCANS";
    ReportKindCase[ReportKindCase["OVA_MSP_SCANNED_ASSETS_SUMMARY"] = 14] = "OVA_MSP_SCANNED_ASSETS_SUMMARY";
    ReportKindCase[ReportKindCase["OVA_MSP_INFECTED_ASSETS"] = 15] = "OVA_MSP_INFECTED_ASSETS";
    ReportKindCase[ReportKindCase["OVA_MSP_INFECTED_FILES"] = 16] = "OVA_MSP_INFECTED_FILES";
    ReportKindCase[ReportKindCase["OVA_MSP_FAILED_SCANS"] = 17] = "OVA_MSP_FAILED_SCANS";
})(ReportKindCase || (exports.ReportKindCase = ReportKindCase = {}));
class GeneratedReportBase {
    formats;
    startAt;
    endDate;
    kind;
    constructor(args) {
        this.formats = args.formats;
        this.startAt = args.startAt;
        this.endDate = args.endDate;
        this.kind = args.kind;
    }
}
class GeneratedOvaMspOperationalReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspOperationalReportParams = GeneratedOvaMspOperationalReportParams;
class GeneratedOvaMspExecutiveReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspExecutiveReportParams = GeneratedOvaMspExecutiveReportParams;
class GeneratedAwsExecutiveReportParams extends GeneratedReportBase {
}
exports.GeneratedAwsExecutiveReportParams = GeneratedAwsExecutiveReportParams;
class GeneratedAwsTenantSummaryReportParams extends GeneratedReportBase {
}
exports.GeneratedAwsTenantSummaryReportParams = GeneratedAwsTenantSummaryReportParams;
class GeneratedAwsAccountSummaryReportParams extends GeneratedReportBase {
}
exports.GeneratedAwsAccountSummaryReportParams = GeneratedAwsAccountSummaryReportParams;
class GeneratedAwsCompletedScansReportParams extends GeneratedReportBase {
}
exports.GeneratedAwsCompletedScansReportParams = GeneratedAwsCompletedScansReportParams;
class GeneratedAwsScannedAssetsSummaryReportParams extends GeneratedReportBase {
}
exports.GeneratedAwsScannedAssetsSummaryReportParams = GeneratedAwsScannedAssetsSummaryReportParams;
class GeneratedAwsInfectedAssetsReportParams extends GeneratedReportBase {
}
exports.GeneratedAwsInfectedAssetsReportParams = GeneratedAwsInfectedAssetsReportParams;
class GeneratedInfectedFilesReportParams extends GeneratedReportBase {
}
exports.GeneratedInfectedFilesReportParams = GeneratedInfectedFilesReportParams;
class GeneratedFailedScansReportParams extends GeneratedReportBase {
}
exports.GeneratedFailedScansReportParams = GeneratedFailedScansReportParams;
class GeneratedOvaMspTenantSummaryReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspTenantSummaryReportParams = GeneratedOvaMspTenantSummaryReportParams;
class GeneratedOvaMspAccountSummaryReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspAccountSummaryReportParams = GeneratedOvaMspAccountSummaryReportParams;
class GeneratedOvaMspCompletedScansReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspCompletedScansReportParams = GeneratedOvaMspCompletedScansReportParams;
class GeneratedOvaMspScannedAssetsSummaryReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspScannedAssetsSummaryReportParams = GeneratedOvaMspScannedAssetsSummaryReportParams;
class GeneratedOvaMspInfectedAssetsReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspInfectedAssetsReportParams = GeneratedOvaMspInfectedAssetsReportParams;
class GeneratedOvaMspInfectedFilesReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspInfectedFilesReportParams = GeneratedOvaMspInfectedFilesReportParams;
class GeneratedOvaMspFailedScansReportParams extends GeneratedReportBase {
    ovaAccountIds;
    ovaBackupProviders;
    constructor(params) {
        const { endDate, ovaBackupProviders, ovaAccountIds, startAt, formats, kind, } = params;
        super({ endDate, startAt, formats, kind });
        this.ovaAccountIds = ovaAccountIds;
        this.ovaBackupProviders = ovaBackupProviders;
    }
}
exports.GeneratedOvaMspFailedScansReportParams = GeneratedOvaMspFailedScansReportParams;
