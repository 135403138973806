import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'

interface Props {
  backupsScannedCount: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    flexBasis="50%"
  >
    {children}
  </Box>
)

function BackupsScannedSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          1,456
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" textAlign="center" fontSize="16px">
          Recovery points scanned
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function BackupsScanned({ backupsScannedCount, isLoading }: Props) {
  if (isLoading) {
    return <BackupsScannedSkeleton />
  }

  const backupsScannedCountFormatted = backupsScannedCount.toLocaleString()

  return (
    <Container>
      <Typography fontWeight="600" fontSize="22px">
        {backupsScannedCountFormatted}
      </Typography>
      <Typography fontWeight="400" textAlign="center" fontSize="16px">
        Recovery points scanned
      </Typography>
    </Container>
  )
}
