/* eslint-disable import/no-extraneous-dependencies */
import {
  CellLongTextWithTooltip,
  CellWithDateTimeTooltip,
  ColumnWidth,
  createColumn,
  StatusCircle,
} from '@components/table'
import React from 'react'
import { CopyCell } from '@components/table/core/cell/copy-cell'
import { StrHelper } from '@lib/helpers'
import { Threat } from 'blues-corejs/dist/models/threats'
import { Nullable } from '@lib/engine-types'
import { DetailsOvaSnapshotOutput } from 'blues-corejs/dist/use_cases/list_details_ova_snapshots_with_threats/types'
import PlusToggleBtn from '@components-simple/plus-toggle-btn/PlusToggleBtn'

const EMPTY_THREATS_VALUE = '-'

export function getOvaSnapshotsWithThreatsTableColumnDefinition() {
  return [
    createColumn<DetailsOvaSnapshotOutput>('ovaVolumeId', {
      meta: {
        size: ColumnWidth.EXTRA_SMALL,
        width: '20px',
      },
      enableSorting: false,
      cell: ({ row }) => {
        if (row.original.threats.length === 0) {
          return null
        }

        return (
          <PlusToggleBtn
            expanded={row.getIsExpanded()}
            onChange={row.getToggleExpandedHandler()}
          />
        )
      },
    }),
    createColumn<DetailsOvaSnapshotOutput>('id', {
      meta: {
        size: ColumnWidth.EXTRA_SMALL,
        width: '20px',
      },
      enableSorting: false,
      cell: ({ row }) => (
        <StatusCircle isHealthy={!(row.original.threats.length > 0)} />
      ),
    }),
    createColumn<DetailsOvaSnapshotOutput>('backupProviderVolumeId', {
      header: 'Backup ID',
      meta: {
        width: '460px',
      },
      cell: ({ getValue }) => {
        return <CopyCell copyText={getValue()} />
      },
    }),
    createColumn<DetailsOvaSnapshotOutput>('threats', {
      header: 'Threat',
      meta: {
        width: '400px',
      },
      cell: ({ getValue }) => {
        const threats = getValue() as unknown as Array<Threat>
        const threatsNames = threats
          .map((threat) => StrHelper.capitalize(threat.name))
          .join(', ')

        if (threats.length === 0) {
          return EMPTY_THREATS_VALUE
        }
        return <CellLongTextWithTooltip title={threatsNames} maxLength={45} />
      },
    }),
    createColumn<DetailsOvaSnapshotOutput>('firstDetectedAt', {
      header: 'First Detected',
      cell: ({ getValue }) => {
        const date = getValue() as unknown as Nullable<Date>

        return <CellWithDateTimeTooltip date={date} />
      },
    }),
  ]
}
