/* eslint-disable import/no-extraneous-dependencies */
import { ListLiveAssetsResponse } from 'ui-v2/src/lib/grpc'
import {
  AWSBRecoveryPointTransformer,
  EbsSnapshotTransformer,
  ElastioRecoveryPointTransformer,
} from 'ui-v2/src/lib/transformers/recovery'
import {
  AWSBRecoveryPoint,
  EBSSnapshot,
  ElastioRecoveryPoint,
} from 'blues-corejs/dist'
import { Map as ProtobufMap } from 'google-protobuf'

type BackupType = EBSSnapshot | ElastioRecoveryPoint | AWSBRecoveryPoint

export type BackupsMap = Map<string, BackupType>

type ProtobufBackup =
  | ListLiveAssetsResponse.LastBackup
  | ListLiveAssetsResponse.FirstBackup

export class BackupsTransformer {
  #backups: BackupsMap

  constructor(backups: ProtobufMap<string, ListLiveAssetsResponse.LastBackup>) {
    this.#backups = new Map()
    for (const [key, backup] of backups.entries()) {
      this.#addBackup(key, backup)
    }
  }

  #addBackup(key: string, backup: ProtobufBackup) {
    const awsbRecoveryPoint = backup.getAwsbRecoveryPoint()

    const ebsSnapshot = backup.getEbsSnapshot()

    const elastioRecoveryPoint = backup.getElastioRecoveryPoint()

    if (awsbRecoveryPoint) {
      this.#backups.set(
        key,
        new AWSBRecoveryPointTransformer(
          awsbRecoveryPoint.toObject()
        ).transform()
      )
      return
    }

    if (ebsSnapshot) {
      this.#backups.set(
        key,
        new EbsSnapshotTransformer(ebsSnapshot.toObject()).transform()
      )
      return
    }

    if (elastioRecoveryPoint) {
      this.#backups.set(
        key,
        new ElastioRecoveryPointTransformer(
          elastioRecoveryPoint.toObject()
        ).transform()
      )
      return
    }
  }

  get backups() {
    return this.#backups
  }
}
