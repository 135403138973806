import React from 'react'

function ExpandedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#32B6F3" fillOpacity="0.2" />
      <path d="M13.03 11.717V9.59298H7.81V11.717H13.03Z" fill="#32B6F3" />
    </svg>
  )
}

export default ExpandedIcon
