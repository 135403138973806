import TypeHelper from 'ui-v2/src/lib/helpers/type.helper'
import UserModel from './user'
import { numberHash } from 'ui-v2/src/lib/helpers/num.helper'
import UserRoleModel from './user-role'
import RoleTypeConstant from 'ui-v2/src/lib/constants/user-role.constant'
import { BaseModel } from '../base-model'

abstract class UserFactory {
  public static buildFromGRPC(params: any): UserModel {
    return new UserModel({
      id: numberHash(params.id),
      innerId: TypeHelper.stringValDef(params.id, ''),
      email: TypeHelper.emailValDef(params.email, ''),
      locale: TypeHelper.stringValDef(params.locale, ''),
      name: TypeHelper.stringValDef(params.name, ''),
      nickname: TypeHelper.stringValDef(params.nickname, ''),
      picture: TypeHelper.stringValDef(params.picture, '/images/logo.svg')
        // for some reason cloudinary may send with "http" but works also with "https"
        .replace(/http:\/\//i, 'https://'),
      lastSeen: params.lastSeen
        ? TypeHelper.momentVal(params.lastSeen.seconds * 1000)
        : null,
      status: TypeHelper.stringValDef(params.status, ''),
      phoneNumber: TypeHelper.stringValDef(params.phoneNumber, ''),
      role: UserFactory.buildUserRole(params.role ?? {}),
    })
  }

  public static buildEmptyUser(): UserModel {
    return new UserModel({
      id: '',
      innerId: '',
      email: '',
      locale: '',
      name: '',
      nickname: '',
      picture: '',
      lastSeen: null,
      status: '',
      phoneNumber: '',
      role: UserFactory.buildEmptyRole(),
    })
  }

  public static build(params: any): UserModel {
    const finalParams = { ...params }
    finalParams.role = params.role ?? UserFactory.buildEmptyRole()
    return new UserModel(finalParams)
  }

  public static buildEmptyRole(): UserRoleModel {
    return new UserRoleModel({
      id: '',
      name: '',
      shortName: '',
      type: RoleTypeConstant.UNDEFINED,
      scopesList: [],
    })
  }

  public static buildUserRole(params: any): UserRoleModel {
    return new UserRoleModel({
      id: numberHash(params.id),
      innerId: params.id,
      shortName: TypeHelper.stringValDef(params.shortName, ''),
      name: TypeHelper.stringValDef(params.name, ''),
      type: TypeHelper.enumValDef(
        params.type,
        RoleTypeConstant.UNDEFINED,
        RoleTypeConstant
      ),
      scopesList: TypeHelper.arrayStringValDef(params.scopesList, []),
    })
  }

  public static cloneRole(
    baseRole: UserRoleModel,
    {
      name,
      allScopesForNormalization = [],
      scopesToAdd = [],
      scopesToRemove = [],
    }: {
      name?: string
      allScopesForNormalization?: Array<string>
      scopesToAdd?: Array<string>
      scopesToRemove?: Array<string>
    }
  ): UserRoleModel {
    let finalScopes = baseRole.scopesList
      .filter((s) => !scopesToAdd.includes(s) && !scopesToRemove.includes(s))
      .concat(scopesToAdd)

    // normalization
    // if for example - "write" is selected but "read" not - it will select it
    if (allScopesForNormalization.length > 0) {
      const extraScopesToAdd: Array<string> = []
      const extraScopesToRemove: Array<string> = []
      finalScopes.forEach((fr: string) => {
        if (!/:.*write/i.test(fr)) {
          return
        }
        const categoryName = fr.split(':')[0]
        const callBackToFind = (v: string) => {
          const re = new RegExp(`${categoryName}:.*read`, 'i')
          return re.test(v)
        }
        // try to find among existing
        if (finalScopes.find(callBackToFind)) {
          // if it finds - it means that - "read" and "write" are present in the set
          // and in any case it is correct
          return
        }
        // depending what we want - we can add "read" or remove "write"
        if (scopesToAdd.includes(fr)) {
          // otherwise try to find among possible and add into the current set
          const foundFromPossible =
            allScopesForNormalization.find(callBackToFind)
          if (foundFromPossible) {
            extraScopesToAdd.push(foundFromPossible)
          }
        } else {
          extraScopesToRemove.push(fr)
        }
      })
      // final handling
      finalScopes = finalScopes
        .concat(extraScopesToAdd)
        .filter((fr) => !extraScopesToRemove.includes(fr))
    }

    return BaseModel.clone(baseRole, {
      scopesList: finalScopes,
      name: name ?? baseRole.name,
    })
  }
}

export default UserFactory
