import { useEffect, useState } from 'react'
import { VIRow } from '@lib/engine-types'
import { ObjHelper } from '@lib/helpers'
import { CopyButtonVariant } from '@lib/constants'
import ValueInterface from '@lib/interfaces/value.interface'

export function useCommandsData(commands: VIRow) {
  const [commandsData, setCommandsData] = useState<VIRow>([])

  useEffect(() => {
    setCommandsData(commands)

    return () => setCommandsData([])
  }, [commands.length])

  const updateCommandText = (selected: Array<ValueInterface>) => {
    if (commands.length) {
      const updatedCommands = ObjHelper.cloneDeep(commands)
      const selectedNames = selected.map((el) => el.name)
      const selectedNamesWinFormatted: Array<string> = []
      const selectedNamesLinuxFormatted: Array<string> = []
      selectedNames.map((name) => {
        selectedNamesWinFormatted.push(`"${name};C:\\${name}"`)
        selectedNamesLinuxFormatted.push(`'${name}:/mnt/${name}'`)
      })

      commands.map((command) => {
        switch (command.type) {
          case CopyButtonVariant.WINDOWS:
            const winCommand = `${
              command.name
            } ${selectedNamesWinFormatted.join(' ')}`
            const index = updatedCommands.findIndex(
              (com) => com.type === CopyButtonVariant.WINDOWS
            )
            updatedCommands[index] = {
              ...updatedCommands[index],
              name: winCommand,
            }
            break
          case CopyButtonVariant.LINUX:
            const linuxCommand = `${
              command.name
            } ${selectedNamesLinuxFormatted.join(' ')}`
            const idxCommand = updatedCommands.findIndex(
              (com) => com.type === CopyButtonVariant.LINUX
            )
            updatedCommands[idxCommand] = {
              ...updatedCommands[idxCommand],
              name: linuxCommand,
            }
            break
        }
      })
      setCommandsData(updatedCommands)
    }
  }

  return {
    commandsData,
    setCommandsData,
    updateCommandText,
  }
}
