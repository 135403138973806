import { SvgIcon, SvgIconProps } from '@mui/material'

const LightMode = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 0,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M11.1818 5.45455V3.81818C11.1818 3.36818 11.55 3 12 3C12.45 3 12.8182 3.36818 12.8182 3.81818V5.45455C12.8182 5.90455 12.45 6.27273 12 6.27273C11.55 6.27273 11.1818 5.90455 11.1818 5.45455ZM17.2036 7.95L18.3573 6.78818C18.6764 6.46909 18.6764 5.95364 18.3573 5.63455C18.0382 5.31545 17.5227 5.31545 17.2036 5.63455L16.05 6.79636C15.7309 7.11545 15.7309 7.63091 16.05 7.95C16.3691 8.26909 16.8845 8.26909 17.2036 7.95ZM20.1818 11.1818H18.5455C18.0955 11.1818 17.7273 11.55 17.7273 12C17.7273 12.45 18.0955 12.8182 18.5455 12.8182H20.1818C20.6318 12.8182 21 12.45 21 12C21 11.55 20.6318 11.1818 20.1818 11.1818ZM12 17.7273C11.55 17.7273 11.1818 18.0955 11.1818 18.5455V20.1818C11.1818 20.6318 11.55 21 12 21C12.45 21 12.8182 20.6318 12.8182 20.1818V18.5455C12.8182 18.0955 12.45 17.7273 12 17.7273ZM6.79636 7.95L5.63455 6.79636C5.31545 6.47727 5.31545 5.95364 5.63455 5.64273C5.95364 5.33182 6.47727 5.32364 6.78818 5.64273L7.94182 6.79636C8.26091 7.11545 8.26091 7.63909 7.94182 7.95C7.62273 8.26091 7.10727 8.26909 6.79636 7.95ZM16.05 16.05C15.7309 16.3691 15.7309 16.8927 16.05 17.2036L17.2036 18.3573C17.5227 18.6764 18.0464 18.6764 18.3573 18.3573C18.6764 18.0382 18.6764 17.5145 18.3573 17.2036L17.2036 16.05C16.8927 15.7309 16.3691 15.7309 16.05 16.05ZM3.81818 12.8182H5.45455C5.90455 12.8182 6.27273 12.45 6.27273 12C6.27273 11.55 5.90455 11.1818 5.45455 11.1818H3.81818C3.36818 11.1818 3 11.55 3 12C3 12.45 3.36818 12.8182 3.81818 12.8182ZM6.79636 18.3655L7.95 17.2118C8.26909 16.8927 8.26909 16.3691 7.95 16.0582C7.63091 15.7473 7.10727 15.7391 6.79636 16.0582L5.64273 17.2118C5.32364 17.5309 5.32364 18.0545 5.64273 18.3655C5.95364 18.6845 6.47727 18.6845 6.79636 18.3655ZM12 7.09091C9.29182 7.09091 7.09091 9.29182 7.09091 12C7.09091 14.7082 9.29182 16.9091 12 16.9091C14.7082 16.9091 16.9091 14.7082 16.9091 12C16.9091 9.29182 14.7082 7.09091 12 7.09091Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default LightMode
