import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { ScansClient } from 'ui-v2/src/lib/clients'
import { useMutation } from '@tanstack/react-query'

export function useDownloadScansMutation() {
  const scansClient = useGrpcClient(ScansClient)

  return useMutation({
    mutationFn: async (scanId: string) => {
      if (!scansClient) {
        throw new Error('Scans client not initialized')
      }
      return scansClient.downloadScan(scanId)
    },
  })
}
