/* eslint-disable import/no-extraneous-dependencies */
import { EBS_STATE_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { Backup, EBS, EBSState, Nullable, Threat } from 'blues-corejs/dist'

export abstract class AssetsEbsBase {
  protected abstract readonly volumes: Array<EBS>

  protected abstract readonly scans: Array<Scan>

  protected abstract readonly lastBackups: Map<string, Backup>

  protected abstract readonly threats: Array<Threat>

  protected abstract readonly volumesWithScans: Map<string, Array<Scan>>

  protected convertEbsState(
    state: EBSState
  ): (typeof EBS_STATE_MAP)[keyof typeof EBS_STATE_MAP] {
    return EBS_STATE_MAP[state]
  }

  protected isUnhealthyVolume(volume: EBS): boolean {
    if (volume.state === EBSState.DELETED) {
      return false
    }
    return this.threats.some(
      (threat) =>
        threat.source.asset?.assetId === volume.id ||
        threat.source.assetItem?.assetId === volume.id
    )
  }

  protected getLastBackup(volume: EBS): Nullable<Backup> {
    return this.lastBackups.get(volume.id)
  }

  protected getLastScanForVolume(volume: EBS) {
    const volumeScans = this.volumesWithScans.get(volume.id)

    if (!volumeScans || !volumeScans.length) {
      return null
    }

    return volumeScans.reduce(
      (latest, current) =>
        current.createdAt.getTime() > (latest?.createdAt?.getTime() ?? 0)
          ? current
          : latest,
      volumeScans[0]
    )
  }

  protected getVolumeWithScans(): Map<string, Array<Scan>> {
    const map = new Map<string, Array<Scan>>()

    for (const scan of this.scans) {
      const volumesScans = map.get(scan.scanTarget.target.assetId)

      if (volumesScans) {
        volumesScans.push(scan)
      } else {
        map.set(scan.scanTarget.target.assetId, [scan])
      }
    }

    return map
  }
}
