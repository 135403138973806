/* eslint-disable import/no-extraneous-dependencies */
import {
  CellLongTextWithTooltip,
  CellWithDateTimeTooltip,
  ColumnWidth,
  createColumn,
} from '@components/table'
import { DetailsDetectedThreatOutput } from 'blues-corejs/dist'
import React from 'react'
import MalwareIcon from '@inline-img/general/malware-icon'
import RansomwareIcon from '@inline-img/general/ransomware-icon'
import { ThreatKind } from 'blues-corejs/dist/models/threats/types'
import { StrHelper } from '@lib/helpers'
import ThreatSuppression from '@features/threat-suppression/threat-suppression'

const PATH_COLUMN_WIDTH = '457px'
const THREAT_NAME_COLUMN_WIDTH = '400px'
const ICON_COLUMN_WIDTH = '20px'

export function getDetectedThreatsTableColumnDefinition(
  widthForExpandColumn = 40
) {
  const additionColumn = []
  if (widthForExpandColumn) {
    additionColumn.push(
      createColumn<DetailsDetectedThreatOutput>('status', {
        meta: {
          size: ColumnWidth.EXTRA_SMALL,
          width: `${widthForExpandColumn}px`,
        },
        enableSorting: false,
        header: () => null,
        cell: '',
      })
    )
  }
  return [
    ...additionColumn,
    createColumn<DetailsDetectedThreatOutput>('kind', {
      meta: {
        size: ColumnWidth.EXTRA_SMALL,
        width: ICON_COLUMN_WIDTH,
      },
      enableSorting: false,
      cell: ({ getValue }) => {
        switch (getValue() as unknown as number) {
          case ThreatKind.MALWARE:
            return MalwareIcon({
              fill: 'var(--red400)',
              width: '18',
              height: '18',
            })
          case ThreatKind.RANSOMWARE:
            return RansomwareIcon({
              fill: 'var(--red400)',
              width: '18',
              height: '18',
            })
          default:
            return ''
        }
      },
    }),
    createColumn<DetailsDetectedThreatOutput>('location', {
      meta: {
        width: PATH_COLUMN_WIDTH,
      },
      header: 'Path',
      cell: ({ getValue }) => (
        <CellLongTextWithTooltip title={getValue()} maxLength={50} />
      ),
    }),
    createColumn<DetailsDetectedThreatOutput>('threatName', {
      meta: {
        width: THREAT_NAME_COLUMN_WIDTH,
      },
      header: 'Threat',
      cell: ({ getValue }) => {
        return StrHelper.capitalize(getValue())
      },
    }),
    createColumn<DetailsDetectedThreatOutput>('firstDetectedAt', {
      header: 'First Detected',
      cell: ({ getValue }) => {
        const date: Date = getValue() as unknown as Date

        return <CellWithDateTimeTooltip date={date} />
      },
    }),
    createColumn<DetailsDetectedThreatOutput>('id', {
      header: 'Suppression',
      cell: ({ getValue, row }) => {
        const { threatName, location, firstDetectedAt } = row.original

        return (
          <ThreatSuppression
            threatId={getValue()}
            threatName={threatName}
            threatLocation={location}
            firstDetectedAt={firstDetectedAt}
          />
        )
      },
    }),
  ]
}
