import { useShallow } from 'zustand/react/shallow'
import { useDeploymentJobsStore } from '@features/jobs/tenant-jobs/deployment-jobs/stores/deployment-jobs-store/deployment-jobs-store'

export const useDeploymentJobsStoreActions = () =>
  useDeploymentJobsStore(useShallow((state) => state.actions))

export const useDeploymentJobsList = () =>
  useDeploymentJobsStore(useShallow((state) => state.deploymentJobsList))

export const useDeploymentJobsIsLoading = () =>
  useDeploymentJobsStore((state) => state.loadingState === 'loading')
