import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Chip, styled } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const StyledChip = styled(Chip)`
  background-color: #e4f7ff;
  font-weight: 500;
`

const INITIAL_COUNT = 3

interface OptionType {
  value: string | number
  label: string
}

function SelectedItemsChips({
  items,
  initialCount = INITIAL_COUNT,
  onDelete,
}: {
  items: Array<OptionType>
  initialCount?: number
  onDelete: (item: OptionType) => void
}) {
  const [showAll, setShowAll] = useState(false)

  const handleClick = () => {
    setShowAll(!showAll)
  }

  const displayedItems = showAll ? items : items.slice(0, initialCount)
  const hiddenItemsCount = items.length - displayedItems.length

  return (
    <Box display="flex" gap="8px" flexWrap="wrap">
      {displayedItems.map((item) =>
        item?.label ? (
          <StyledChip
            key={item.label}
            label={item.label}
            onDelete={() => onDelete(item)}
            deleteIcon={
              <ClearIcon
                sx={{
                  fontSize: '16px !important',
                  color: 'black !important',
                  marginRight: '12px !important',
                }}
              />
            }
          />
        ) : null
      )}
      {!showAll && hiddenItemsCount > 0 && (
        <StyledChip label={`+${hiddenItemsCount}`} onClick={handleClick} />
      )}
    </Box>
  )
}

export default SelectedItemsChips
