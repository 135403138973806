/* eslint-disable import/no-extraneous-dependencies */
import {
  EBS,
  EC2,
  EFS,
  GenericHost,
  S3Bucket,
  Asset,
  OvaServer,
} from 'blues-corejs/dist'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import Ec2Icon from '../../../DashboardV4/common/icons/ec2-icon'
import { EbsIcon, S3Icon } from '@features/DashboardV4/common'
import EfsIcon from '@features/DashboardV4/common/icons/efs-icon'
import AssetHeaderAction from './asset-header-action'
import { ParenthesizedDateTimeTooltip } from '@components-simple/date-time-tooltip'
import { CopyToClipboardIcon } from '@features/common'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { useFetchListOvaAccounts } from '@lib/hooks/ova-accounts'

const AssetIcon = {
  ec2: Ec2Icon,
  ebs: EbsIcon,
  efs: EfsIcon,
  s3: S3Icon,
}

type Props = {
  asset: Asset
  onCloseModalClick: () => void
}

function computeIcon(asset: Asset) {
  if (asset instanceof EC2) {
    return AssetIcon.ec2
  } else if (asset instanceof EBS) {
    return AssetIcon.ebs
  } else if (asset instanceof EFS) {
    return AssetIcon.efs
  } else if (asset instanceof S3Bucket) {
    return AssetIcon.s3
  } else if (asset instanceof GenericHost) {
    return AssetIcon.s3
  } else {
    return () => null
  }
}

function computeHeaderText(asset: Asset) {
  if (asset instanceof EC2) {
    return 'EC2 Instance Details'
  } else if (asset instanceof EBS) {
    return 'EBS Volume Details'
  } else if (asset instanceof EFS) {
    return 'EFS File System Details'
  } else if (asset instanceof S3Bucket) {
    return 'S3 Bucket Details'
  } else if (asset instanceof GenericHost) {
    return 'Generic Host Details'
  } else if (asset instanceof OvaServer) {
    return 'Server Details'
  }
}

function renderHeader(asset: Asset) {
  const ComputedIcon = computeIcon(asset)
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <ComputedIcon />
      <Typography fontSize="22px" fontWeight="600">
        {computeHeaderText(asset)}
      </Typography>
    </Box>
  )
}

function computeAssetKind(asset: Asset) {
  if (asset instanceof EC2) {
    return 'Instance ID'
  }

  if (asset instanceof EBS) {
    return 'Volume ID'
  }

  if (asset instanceof EFS) {
    return 'File System ID'
  }

  if (asset instanceof S3Bucket) {
    return 'Bucket Name'
  }

  if (asset instanceof GenericHost) {
    return 'Hostname'
  }
}

function computeAssetName(asset: Asset): string | null {
  if (asset instanceof GenericHost) {
    return null
  } else {
    return asset.name
  }
}

function computeAssetId(asset: Asset): string {
  if (asset instanceof GenericHost) {
    return asset.hostname
  } else if (asset instanceof AWSAsset) {
    return asset.awsId
  } else {
    return asset.ccAssetId
  }
}

function computeAssetCopiableId(asset: Asset): string {
  if (asset instanceof GenericHost) {
    return asset.hostname
  } else if (asset instanceof AWSAsset) {
    return asset.arn
  } else {
    return asset.ccAssetId
  }
}

function AWSAssetIdentifiers({ asset }: { asset: Asset }) {
  const assetName = computeAssetName(asset)
  const assetKind = computeAssetKind(asset)
  const assetId = computeAssetId(asset)
  const assetCopiableId = computeAssetCopiableId(asset)
  if (assetName) {
    return (
      <Box fontSize="14px" display="flex" alignItems="center" fontWeight="400">
        <Typography fontSize="14px" fontWeight="400" marginRight="5px">
          {assetKind}: {assetName}
        </Typography>
        <ParenthesizedDateTimeTooltip>{assetId}</ParenthesizedDateTimeTooltip>
        <CopyToClipboardIcon text={assetCopiableId} fontSize={16} />
      </Box>
    )
  } else {
    return (
      <Box fontSize="14px" display="flex" alignItems="center" fontWeight="400">
        <Typography fontSize="14px" marginRight="5px" fontWeight="400">
          {assetKind}: {assetId}
        </Typography>
        <CopyToClipboardIcon text={assetCopiableId} />
      </Box>
    )
  }
}

function OvaAccountNameSkeleton() {
  return (
    <Box display="flex" alignItems="center" gap="5px">
      <Skeleton>
        <Typography fontSize="14px" fontWeight="600">
          Account:
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontSize="14px">Account 123</Typography>
      </Skeleton>
    </Box>
  )
}

function OvaAccountName({ asset }: { asset: OvaServer }) {
  const { ovaAccountsList, isOvaAccountsLoading } = useFetchListOvaAccounts()

  const ovaAccount = ovaAccountsList.find(
    (account) => account.id === asset.ovaAccountId
  )

  if (isOvaAccountsLoading) {
    return <OvaAccountNameSkeleton />
  }

  if (!ovaAccount) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" gap="5px">
      <Typography fontSize="14px" fontWeight="600">
        Account:
      </Typography>
      <Typography fontSize="14px">{ovaAccount.name}</Typography>
    </Box>
  )
}

function OvaServerIdentifiers({ asset }: { asset: OvaServer }) {
  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <Box display="flex">
        <OvaAccountName asset={asset} />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            marginInline: '8px',
          }}
        />
        <Box display="flex" alignItems="center" gap="5px">
          <Typography fontSize="14px" fontWeight="600">
            Server:
          </Typography>
          <Typography fontSize="14px">{asset.name}</Typography>
          <CopyToClipboardIcon text={asset.ccAssetId} fontSize={16} />
        </Box>
      </Box>
      <Divider flexItem />
    </Box>
  )
}

function renderAssetIdentifiers(asset: Asset) {
  if (asset instanceof OvaServer) {
    return <OvaServerIdentifiers asset={asset} />
  } else {
    return <AWSAssetIdentifiers asset={asset} />
  }
}

function renderAssetCredentials(asset: Asset) {
  return (
    <Box display="flex" alignItems="center">
      {renderAssetIdentifiers(asset)}
    </Box>
  )
}

function AssetDetailHeader({ asset, onCloseModalClick }: Props) {
  return (
    <Grid container fontSize="14px" rowSpacing={2}>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        {renderHeader(asset)}
        <Box display="flex" alignItems="center" gap="5px">
          <AssetHeaderAction asset={asset} />
          <IconButton onClick={onCloseModalClick}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs>
        {renderAssetCredentials(asset)}
      </Grid>
    </Grid>
  )
}

export default AssetDetailHeader
