import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfoHeader from '@components-composite/info-header/InfoHeader'
import BasePortalButtons from '@components-simple/base-portal-buttons/BasePortalButtons'
import PeriodForm from '@forms/period/period.form'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import PagePathConstant from '@lib/constants/page-path.constant'
import { DefaultRetentionPoliciesConstant } from '@lib/constants/policies.constant'
import PreloaderConstants from '@lib/constants/preloader.constant'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import { Button } from '@mui/material'
import {
  requestEditPolicyRetentionDefault,
  setEditPolicyRecoveryPeriod,
  setEditPolicyRetention,
  setEditPolicyRetentionDefaultUpdated,
  updateEditPolicyRetentionDefault,
} from '@store/actions/edit-policy-retention.action'
import {
  getEditPolicyRecoveryPeriod,
  getEditPolicyRetention,
} from '@store/selectors/edit-policy-retention.selector'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { EmptyFunc } from '@lib/constants/app.constant'
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { PoliciesRoutes, useNavigation, Link } from '@lib/router'
import { getEditPolicyRetentionDefaultUpdated } from '@store/selectors/policies-retention.selector'

function EditPolicyRetentionDefault() {
  const { setBreadcrumbsPaths } = useLayoutContext()
  const dispatch = useDispatch()
  const router = useNavigation()
  const policyUpdated = useSelector(getEditPolicyRetentionDefaultUpdated)

  const defaultPolicyName = DefaultRetentionPoliciesConstant.DEFAULT
  const [formHasError, setFormHasError] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setEditPolicyRetention(null))
    dispatch(requestEditPolicyRetentionDefault(defaultPolicyName))
    setBreadcrumbsPaths([
      {
        href: PagePathConstant.POLICIES_RETENTION,
        text: titlesForBreadcrumb(PagePathConstant.POLICIES) ?? '',
      },
    ])
  }, [])

  useEffect(() => {
    if (!policyUpdated) {
      return
    }
    router.push(PoliciesRoutes.retentionTable)
    return () => {
      dispatch(setEditPolicyRetentionDefaultUpdated(false))
    }
  }, [policyUpdated])

  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_EDIT_POLICY_RETENTION,
    PreloaderConstants.UPDATE_EDIT_POLICY_RETENTION_DEFAULT,
  ])

  const editPolicy = useSelector(getEditPolicyRetention)
  const dataForPeriodForm = useSelector(getEditPolicyRecoveryPeriod)

  const periodFormRef = useRef({ submit: EmptyFunc })

  const onRecoveryPeriodChange = (newRecoveryPeriod: FormPeriodInterface) => {
    dispatch(setEditPolicyRecoveryPeriod(newRecoveryPeriod))
  }

  const onUpdatePolicy = () => {
    // started submit PeriodForm
    periodFormRef.current.submit()
    if (editPolicy && !formHasError) {
      setTimeout(() => {
        dispatch(updateEditPolicyRetentionDefault())
      }, 5)
    }
  }

  return (
    <div className="innerContent innerContentBlueBackground jsEditPolicyRetentionDefaultPage">
      <div className="wrap-1660053877194">
        <div className="editPolicyDefaultContainer">
          <div className="addPolicyTitle">Recovery period</div>
          <InfoHeader
            type="info"
            description="Recovery points will be collapsed at 12am on the selected days
          (except in the case of hourly collapse), on Mondays and Thursdays for weekly collapse,
          and the first day of every calendar month in the case of monthly collapse."
          />
          {!loading && editPolicy ? (
            <>
              <PeriodForm
                onSubmit={onRecoveryPeriodChange}
                loading={loading}
                errorGroups={[ErrorGroupConstants.POLICIES_RETENTION]}
                defaultValues={dataForPeriodForm}
                onFormValid={(e) => setFormHasError(!e)}
                onTriggerForm={periodFormRef}
              />
            </>
          ) : (
            <PreloaderBlock show />
          )}
        </div>
        <BasePortalButtons alwaysInPortal>
          <div className="portalBetweenButtons">
            <div>
              <Link to={PagePathConstant.POLICIES_RETENTION} passHref>
                <Button
                  variant="text"
                  color="primary"
                  className="jsCloseEditRetentionPolicyPage"
                  onClick={() => router.push(PoliciesRoutes.retentionTable)}
                >
                  Cancel
                </Button>
              </Link>
            </div>

            <Button
              data-testid="jsSaveRetentionPolicyButton"
              disabled={loading || !editPolicy}
              onClick={onUpdatePolicy}
              variant="contained"
              color="primary"
              className="jsSaveRetentionPolicyButton"
            >
              Save
            </Button>
          </div>
        </BasePortalButtons>
      </div>
    </div>
  )
}

export default EditPolicyRetentionDefault
