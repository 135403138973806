import { Box, styled, Typography } from '@mui/material'

export const TypographyStyled = styled(Typography)`
  font-size: 22px;
  margin-bottom: 12px;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-weight: 700;
  line-height: 140%;
`

TypographyStyled.defaultProps = {
  variant: 'h6',
}

export const FormWrap = styled(Box)`
  background-color: var(--grey50);
  padding: 0 24px 24px 0;
  min-height: 100%;
`
