import { Nullable } from '@lib/engine-types'
import { BackupTimelineChartDataPoint } from '@lib/interfaces/backup-timeline.interface'
import { Chart, ChartType, registerables } from 'chart.js'

function drawCloudConnectorBackupTimelinelineChart(
  data: Array<BackupTimelineChartDataPoint>,
  chartRef: Nullable<HTMLCanvasElement>
): Chart | undefined {
  if (chartRef && data?.length) {
    Chart.register(...registerables)
    const context = chartRef.getContext('2d')

    const gradient = context?.createLinearGradient(0, 0, 0, 250)
    if (gradient) {
      gradient.addColorStop(1, 'rgba(167, 225, 255, 0)')
      gradient.addColorStop(0, 'rgba(167, 225, 255, 1)')
    }

    const chartType: ChartType = 'line'
    const labels = data.map((value) => value.timestamp)

    const tooltipTitle = (tooltipItems: Array<{ label: string }>) =>
      `${tooltipItems[0]?.label} (UTC)`

    const datasets = [
      {
        label: 'Jobs',
        // backgroundColor: '#70D180',
        fill: 'start',
        borderColor: '#32B6F3',
        backgroundColor: gradient,
        stack: 'Stack 0',
        data: data.map(
          ({ success, failed, inProgress, warning }) =>
            success + failed + inProgress + warning
        ),
        borderRadius: 50,
        barThickness: 8,
      },
    ]
    const config = {
      type: chartType,
      data: {
        labels,
        datasets,
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: tooltipTitle,
            },
          },
        },
        layout: {
          autoPadding: false,
          padding: 20,
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            stacked: true,
            ticks: {
              precision: 0,
            },
            grid: {
              display: false,
            },
          },
        },
      },
    }
    const cloudConnectorBackupTimelineChart = new Chart(
      context as CanvasRenderingContext2D,
      config
    )

    return cloudConnectorBackupTimelineChart
  }
}

export default drawCloudConnectorBackupTimelinelineChart
