/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { CoverageStats } from '@features/DashboardV4/widgets-with-scale/coverage-stats'
import { WidgetHeader } from '@features/DashboardV4/common'
import { RpoStatsWgt } from '@features/DashboardV4/widgets-with-scale/rpo'
import { RetentionStatsWgt } from '@features/DashboardV4/widgets-with-scale/retention'
import { Divider, Grid } from '@mui/material'
import { InfoBox } from '@features/DashboardV4/widgets-with-scale'
import {
  useFetchAwsRpoAndRetention,
  useBackupCoverageStats,
  useScanCoverageStats,
} from '@features/DashboardV4/views'
import { useFetchStorageReductionMetrics } from '@features/DashboardV4/views/use-fetch-storage-reduction-metrics'

export function OverviewRightColumnWithEntropyTrends() {
  const { isLoading, retentionStats, rpoStats } = useFetchAwsRpoAndRetention()
  const { isLoading: isLoadingBackupCoverage, backupCoverageStats } =
    useBackupCoverageStats()
  const {
    isLoading: isLoadingStorageReduction,
    storageReductionInfo,
    optimizedSizeInfo,
  } = useFetchStorageReductionMetrics()

  const { scanCoverageStats, isLoading: isLoadingScanCoverage } =
    useScanCoverageStats()

  return (
    <Grid
      container
      item
      spacing={1}
      padding={2}
      bgcolor="white"
      md={12}
      lg={4}
      marginRight="auto"
      gap={3}
    >
      <Grid container item xs={120} gap="24px">
        <Grid item data-testid="scan-coverage-block" xs={12}>
          <WidgetHeader title="Scan Coverage" />
          <CoverageStats
            statisticsRows={scanCoverageStats}
            isLoading={isLoadingScanCoverage}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <InfoBox
            mainText={optimizedSizeInfo.value}
            subText={optimizedSizeInfo.label}
            isLoading={isLoadingStorageReduction}
          />
        </Grid>

        <Grid item xs={12}>
          <WidgetHeader title="Backup Coverage" />
          <CoverageStats
            statisticsRows={backupCoverageStats}
            isLoading={isLoadingBackupCoverage}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs container justifyContent="center" alignItems="center">
          <InfoBox
            mainText={storageReductionInfo.value}
            subText={storageReductionInfo.label}
            isLoading={isLoadingStorageReduction}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <RpoStatsWgt rpoStats={rpoStats} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <RetentionStatsWgt
            retentionStats={retentionStats}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
