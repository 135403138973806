import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export interface FilterComponentProps {
  options: Array<string>
}

const FileSystemIntegrityFilter: React.FC<FilterComponentProps> = ({
  options,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileSystemParam = searchParams.get(
    ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY
  )

  useEffect(() => {
    if (!options.length) {
      return
    }
    if (fileSystemParam?.toLowerCase() === 'all') {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set(
        ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY,
        options.join(',')
      )
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [fileSystemParam, options, setSearchParams])

  const fileSystemIntegrityList = useMemo(() => {
    if (!fileSystemParam) {
      return []
    }
    if (fileSystemParam.toLowerCase() === 'all') {
      return options
    }
    return fileSystemParam.split(',').filter(Boolean)
  }, [fileSystemParam, options])

  const fileSystemIntegrityOptions = useMemo(() => {
    return options.map((fileSystemIntegrity) => ({
      value: fileSystemIntegrity,
      label: fileSystemIntegrity,
    }))
  }, [options])

  const handleFileSystemIntegrityChange = (
    newFileSystemIntegrityList: Array<MultiSelectOption['value']>
  ) => {
    if (newFileSystemIntegrityList.length === 0) {
      searchParams.delete(ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY)
    } else {
      searchParams.set(
        ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY,
        newFileSystemIntegrityList.join(',')
      )
    }

    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={fileSystemIntegrityOptions}
      value={fileSystemIntegrityList}
      onChange={handleFileSystemIntegrityChange}
      label="File System Integrity"
      width={200}
    />
  )
}

export default FileSystemIntegrityFilter
