import { useLayoutContext } from '@features/contexts'
import { useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Asset } from 'blues-corejs/dist'

import {
  computeBreadcrumbs,
  computeHeaderTitle,
  computeCurrentCrumbTitle,
} from './breadcrumbs'

export function useAssetBreadcrumbs(asset?: Asset) {
  const {
    setBreadcrumbsPaths,
    setHeaderTitle,
    setCurrentCrumbTitle,
    setDataLoading,
  } = useLayoutContext()

  useEffect(() => {
    if (!asset) {
      setBreadcrumbsPaths([])
      setHeaderTitle('')
      setDataLoading(true)
      return
    }

    setDataLoading(false)
    setBreadcrumbsPaths(computeBreadcrumbs(asset))
    setHeaderTitle(computeHeaderTitle(asset))
    setCurrentCrumbTitle(computeCurrentCrumbTitle(asset))

    return () => {
      setHeaderTitle(null)
      setCurrentCrumbTitle(null)
    }
  }, [asset?.id])
}
