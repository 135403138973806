import React, { CSSProperties, memo, useState } from 'react'
import { DateRangeTuple, EngineCallback } from '@lib/engine-types'
import ValueInterface from '@lib/interfaces/value.interface'
import ViSelect from '@components-composite/vi-inputs/vi-select/ViSelect'
import DateRangeSelect from '@components-composite/date-range-select/DateRangeSelect'
import {
  DEFAULT_TIME_ZONE,
  EMPTY_DATE_RANGE,
  EMPTY_REPORT_PERIOD,
} from '@lib/constants/time.constant'
import ReportPeriodInterface from '@lib/interfaces/report-period.interface'
import ReportDateRangeConstant, {
  getReportDateRangeName,
  REPORT_DEFAULT_POSSIBLE_PERIODS,
} from '@lib/constants/report-date-range.constant'
import { Tooltip } from '@mui/material'
import moment from 'moment'
import SettingsService from '@lib/services/high/settings.service'
import { UserSettings } from '@lib/constants'
import { getReportDateRangeByPeriod } from '@features/reports/time-utils'

export const CUSTOM_REPORT_PERIOD = (): ValueInterface => ({
  name: getReportDateRangeName(ReportDateRangeConstant.CustomDate),
  value: ReportDateRangeConstant.CustomDate,
})

const TOOLTIP_MESSAGE = `The time frame selector is disabled when the search by recovery point is activated due to
the fact that the search is done across all recovery points.`

interface Props {
  reportPeriod: ReportPeriodInterface
  possiblePeriod?: Array<{ name: string; value: ReportDateRangeConstant }>
  minMaxDate?: DateRangeTuple
  onChange: EngineCallback<ReportPeriodInterface>
  disabled?: boolean
  labelStyle?: CSSProperties
}

function ReportRangeSelector({
  reportPeriod = {
    period: EMPTY_REPORT_PERIOD(),
    dateRange: EMPTY_DATE_RANGE(),
  },
  possiblePeriod = REPORT_DEFAULT_POSSIBLE_PERIODS,
  minMaxDate = [0, moment().valueOf()] as DateRangeTuple,
  onChange,
  disabled,
  labelStyle = {},
}: Props) {
  const timezone =
    SettingsService.getSetting(UserSettings.TimeZone)?.value ??
    DEFAULT_TIME_ZONE().value
  // inner data range implementation does not allow us to transform it
  // easily into the controlled component, under hood that component has
  // memoization which and for case [null, null] - it does not do the update
  const [dataRangeKey, setDataRangeKey] = useState<number>(0)

  // these callbacks implement logic of choosing only one option from two
  const onReportPeriodChangeInner = (value: ValueInterface) => {
    const selectedDateRange = getReportDateRangeByPeriod(value, timezone)
    onChange({
      period: value,
      dateRange:
        value?.value === ReportDateRangeConstant.CustomDate
          ? reportPeriod.dateRange
          : selectedDateRange,
    })
    setDataRangeKey(dataRangeKey + 1)
  }
  const onDateRangeChangeInner = (value: DateRangeTuple) => {
    onChange({
      period: CUSTOM_REPORT_PERIOD(),
      dateRange: value,
    })
  }

  return (
    <div className="wrap-1644488048990 jsReportRangeSelector">
      {disabled ? (
        <Tooltip
          placement="top"
          title={TOOLTIP_MESSAGE}
          key={'rrReportPeriod-tooltip'}
        >
          <div className="rrReportPeriod">
            <ViSelect
              possible={possiblePeriod}
              selected={reportPeriod.period}
              onChange={onReportPeriodChangeInner}
              label="Report Period"
              placeholder="Select Period"
              hint="Select a period"
              disabled={disabled}
              labelStyle={labelStyle}
            />
          </div>
        </Tooltip>
      ) : (
        <div className="rrReportPeriod">
          <ViSelect
            possible={possiblePeriod}
            selected={reportPeriod.period}
            onChange={onReportPeriodChangeInner}
            label="Report Period"
            placeholder="Select Period"
            hint="Select a period"
            disabled={disabled}
            labelStyle={labelStyle}
          />
        </div>
      )}

      {disabled ? (
        <Tooltip
          placement="top"
          title={TOOLTIP_MESSAGE}
          key={'rrRange-tooltip'}
        >
          <div className="rrRange">
            <DateRangeSelect
              key={dataRangeKey}
              label="Date Range"
              value={reportPeriod.dateRange}
              minMaxRange={minMaxDate}
              onChange={onDateRangeChangeInner}
              className="dateRangeSelect"
              hint="Set a date range manually"
              disabled={disabled}
              labelStyle={labelStyle}
            />
          </div>
        </Tooltip>
      ) : (
        <div className="rrReportPeriod">
          <div className="rrRange">
            <DateRangeSelect
              key={dataRangeKey}
              label="Date Range"
              value={reportPeriod.dateRange}
              minMaxRange={minMaxDate}
              onChange={onDateRangeChangeInner}
              className="dateRangeSelect"
              hint="Set a date range manually"
              disabled={disabled}
              labelStyle={labelStyle}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(ReportRangeSelector)
