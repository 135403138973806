/* eslint-disable import/no-extraneous-dependencies */
import { Row } from '@tanstack/react-table'
import {
  DetailsDetectedThreatFilters,
  ListDetailsDetectedThreats,
} from 'blues-corejs/dist'
import { Table } from '@components/table'
import { getOvaDetectedThreatsTableColumnDefinition } from './columns-definition-sub-table'
import React from 'react'
import { DetailsOvaSnapshotOutput } from 'blues-corejs/dist/use_cases/list_details_ova_snapshots_with_threats/types'

interface Props {
  row: Row<DetailsOvaSnapshotOutput>
  selectedFilter: DetailsDetectedThreatFilters
}

export function SubTableForOvaSnapshotWithThreats({
  row,
  selectedFilter = {},
}: Props) {
  if (!row.original.threats || row.original.threats.length === 0) {
    return null
  }

  const { threatsData } = new ListDetailsDetectedThreats(
    row.original.threats
  ).execute(selectedFilter)

  return (
    <Table
      data={threatsData}
      columns={getOvaDetectedThreatsTableColumnDefinition()}
      generalProps={{
        isLoading: false,
        isShowHeader: false,
        noDataMessage: 'No Threats found',
      }}
    />
  )
}

export default SubTableForOvaSnapshotWithThreats
