import { FormPeriodInterface } from '@lib/interfaces/form/form-period.interface'
import { VIRow } from '@lib/engine-types'

export const SET_ADD_RETENTION_POLICY_NAME = 'SET_ADD_RETENTION_PLAN_NAME'
export const SET_RECOVERY_PERIOD = 'SET_RECOVERY_PERIOD'
export const ADD_RETENTION_POLICY = 'ADD_RETENTION_POLICY'
export const SET_ADD_POLICY_RETENTION_ACCOUNTS =
  'SET_EDIT_POLICY_RETENTION_ACCOUNTS'
export const SET_ADD_POLICY_RETENTION_REGIONS =
  'SET_EDIT_POLICY_RETENTION_REGIONS'
export const SET_ADD_POLICY_RETENTION_BACKUP_TYPES =
  'SET_EDIT_POLICY_RETENTION_BACKUP_TYPES'
export const SET_ADD_POLICY_RETENTION_ASSET_SOURCES =
  'SET_EDIT_POLICY_RETENTION_ASSET_SOURCES'
export const SET_ADD_POLICY_RETENTION_TAGS = 'SET_EDIT_POLICY_RETENTION_TAGS'
export const SET_ADD_POLICY_RETENTION_VAULTS =
  'SET_EDIT_POLICY_RETENTION_VAULTS'
export const SET_ADD_POLICY_RETENTION_SELECTED_FILTERS =
  'SET_ADD_POLICY_RETENTION_SELECTED_FILTERS'
export const SET_ADD_POLICY_RETENTION_COMPLETE =
  'SET_ADD_POLICY_RETENTION_COMPLETE'

export const setAddRetentionPolicyName = (policyName: string) => ({
  type: SET_ADD_RETENTION_POLICY_NAME,
  payload: policyName,
})

export const setRecoveryPeriod = (recoveryPeriod: FormPeriodInterface) => ({
  type: SET_RECOVERY_PERIOD,
  payload: recoveryPeriod,
})

export const setAddPolicyRetentionAccountIDs = (accounts: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_ACCOUNTS,
  payload: accounts,
})

export const setAddPolicyRetentionRegions = (regions: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_REGIONS,
  payload: regions,
})

export const setAddPolicyRetentionBackupTypes = (backup: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_BACKUP_TYPES,
  payload: backup,
})

export const setAddPolicyRetentionAssetSources = (source: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_ASSET_SOURCES,
  payload: source,
})

export const setAddPolicyRetentionTags = (tags: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_TAGS,
  payload: tags,
})

export const setAddPolicyRetentionVaults = (vaults: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_VAULTS,
  payload: vaults,
})

export const setAddPolicyRetentionSelectedFilters = (filters: VIRow) => ({
  type: SET_ADD_POLICY_RETENTION_SELECTED_FILTERS,
  payload: filters,
})

export const setAddPolicyRetentionComplete = (complete: boolean) => ({
  type: SET_ADD_POLICY_RETENTION_COMPLETE,
  payload: complete,
})

export const addRetentionPolicy = () => ({
  type: ADD_RETENTION_POLICY,
  payload: null,
})
