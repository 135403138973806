/* eslint-disable import/no-extraneous-dependencies */
import { AssetsPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  Request,
  Response,
} from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/assets/list_asset_items_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  DiskTransformer,
  FileTransformer,
  StreamTransformer,
  VolumeTransformer,
} from '@lib/clients/assets/transformers'
import {
  ListLiveAssetsItemsRequestFilters,
  LiveAssetsItems,
  LiveAssetsItemsPagination,
} from '@lib/clients/assets-items/types'
import { Request as PaginationPB } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/pagination_pb'
import { Nullable } from '@lib/engine-types'
import { AssetItemKind } from '@features/DashboardV4/common'

export class ListAssetItemsClient extends GrpcClient<AssetsPromiseClient> {
  protected initClient(hostName: string): AssetsPromiseClient {
    return new AssetsPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'ListAssetItemsClient'
  }

  #assetsClient: AssetsPromiseClient

  constructor(hostName = '') {
    super()
    this.#assetsClient = this.getClient(hostName)
  }

  async list(): Promise<LiveAssetsItems> {
    const response = (
      await this.retryGrpcCall(
        () => this.#assetsClient.listAssetItems(new Request(), this.metadata()),
        {
          requestName: 'AssetsPromiseClient/listAssetItems',
        }
      )
    ).toObject()

    return this.#transformResponse(response)
  }

  #buildFilter(filters: ListLiveAssetsItemsRequestFilters) {
    const filter = new Request.Initial.Filter()

    if (filters.assetIdList && filters.assetIdList.length > 0) {
      filter.setAssetIdList(filters.assetIdList)
    }

    if (filters.itemIdList) {
      filter.setItemIdList(filters.itemIdList)
    }

    if (filters.kindList) {
      filter.setKindList(
        filters.kindList.map(this.#convertAssetItemKindToGrpcKind.bind(this))
      )
    }

    if (filters.itemNameList) {
      filter.setItemNameList(filters.itemNameList)
    }

    if (filters.retentionPolicyNameList) {
      filter.setRetentionPolicyNameList(filters.retentionPolicyNameList)
    }

    return filter
  }

  #setupPagination(request: Request, pageToken: Nullable<string>) {
    return (
      pageToken &&
      request.setPagination(new PaginationPB().setPageToken(pageToken))
    )
  }

  async listByAssetId(
    filters?: ListLiveAssetsItemsRequestFilters
  ): Promise<LiveAssetsItems & LiveAssetsItemsPagination> {
    const filter = this.#buildFilter(
      filters as ListLiveAssetsItemsRequestFilters
    )

    const initial = new Request.Initial()
      .setPageSize(filters?.pageSize || this.pageSize)
      .setFilter(filter)

    const requestInstance = new Request().setInitial(initial)

    this.#setupPagination(requestInstance, filters?.pageToken)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#assetsClient.listAssetItems(requestInstance, this.metadata()),
        {
          requestName: 'AssetsPromiseClient/listAssetItems',
        }
      )
    ).toObject()

    return {
      ...this.#transformResponse(response),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  #transformResponse(response: Response.AsObject): LiveAssetsItems {
    return {
      filesList: response.filesList.map((file) =>
        new FileTransformer(file).transform()
      ),
      streamsList: response.streamsList.map((stream) =>
        new StreamTransformer(stream).transform()
      ),
      volumesList: response.volumesList.map((volume) =>
        new VolumeTransformer(volume).transform()
      ),
      disksList: response.disksList.map((disk) =>
        new DiskTransformer(disk).transform()
      ),
    }
  }

  #convertAssetItemKindToGrpcKind(kind: AssetItemKind) {
    const mapping = {
      [AssetItemKind.KIND_DISK]: Request.Initial.Filter.Kind.KIND_DISK,
      [AssetItemKind.KIND_FILE]: Request.Initial.Filter.Kind.KIND_FILE,
      [AssetItemKind.KIND_STREAM]: Request.Initial.Filter.Kind.KIND_STREAM,
      [AssetItemKind.KIND_VOLUME]: Request.Initial.Filter.Kind.KIND_VOLUME,
    }

    return mapping[kind]
  }
}
