/* eslint-disable import/no-extraneous-dependencies */
import { MalwareScan } from '@lib/models/scans'
import { MalwareScanStatus } from 'blues-corejs/dist/models/scans/malware/types'
import { MalwareScanBackup } from 'blues-corejs/dist/models/scans/malware/index'
import React, { useEffect, useState } from 'react'
import { DownloadScan } from '@lib/clients/scans/download-scan'
import { ListLiveAssetsClient } from '@lib/clients'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import IconMUI from '@mui/material/Icon'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import { GenericHost, OvaBackup, OvaServer } from 'blues-corejs/dist'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { GetBackupsByIdsClient } from '@lib/clients/backups/list-backups-by-ids'
import { SystemHelper } from '@lib/helpers'

const backupsByIdsClient = new GetBackupsByIdsClient()
const downloadScanClient = new DownloadScan()
const assetsClient = new ListLiveAssetsClient()

function TextRow({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  return (
    <Box>
      <Box component="b" fontWeight="500" fontSize="14px" marginRight="5px">
        {title}:
      </Box>
      {children && children}
    </Box>
  )
}

interface ReportMenuProps {
  options: Array<{
    label: string
    onClick: () => void
  }>
}

export function ReportMenu({ options }: ReportMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          justifyContent: 'flex-start',
          paddingLeft: '0',
          width: 'min-content',
        }}
      >
        Download reports
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.label}
            onClick={() => {
              option.onClick()
              handleClose()
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

type Props = {
  scans: Array<MalwareScan>
}

function renderLoading() {
  return (
    <Skeleton
      animation="wave"
      height={10}
      width="80%"
      style={{ marginBottom: 6 }}
    />
  )
}

function computeColor(value: number) {
  return value > 0 ? 'var(--red500)' : 'var(--grey900)'
}

function computeFontWeight(value: number) {
  return value > 0 ? 'var(--font-weight-medium)' : 'var(--font-weight-normal)'
}

function computeDetectedThreatIcon(scans: Array<MalwareScan>) {
  if (scans.some((scan) => scan.status === MalwareScanStatus.INFECTED)) {
    return {
      icon: ErrorIcon,
      label: 'Detected',
      color: 'el-error-bright',
    } as const
  }

  return {
    icon: CheckCircle,
    label: 'Not found',
    color: 'el-success',
  } as const
}

function mapReportsMenu({
  reportLink,
  assetId,
}: {
  assetId: string
  reportLink: string
}) {
  return {
    label: assetId,
    onClick: () => window.open(reportLink, '_blank'),
  }
}

function DetectedThreat({ scans }: { scans: Array<MalwareScan> }) {
  const computedIcon = computeDetectedThreatIcon(scans)

  const firstScan = scans[0]

  if (!computedIcon || !firstScan) {
    return null
  }

  return (
    <Box display="flex" alignItems="center">
      <TextRow title="Malware"></TextRow>
      <Box display="flex" alignItems="center">
        <IconMUI
          color={computedIcon.color}
          fontSize="small"
          component={computedIcon.icon}
        />
        <Typography marginX="5px" variant="body2" component="span">
          {computedIcon.label}
        </Typography>
        (
        <DateTimeTooltip direction="row" date={firstScan.createdAt} />)
      </Box>
    </Box>
  )
}

function renderMalwareDetails(
  scans: Array<MalwareScan>,
  reportsLinksState: Array<{
    assetId: string
    reportLink: string
  }>
) {
  const infectedFilesCount = scans.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.infectedFilesCount,
    0
  )

  const scannedFilesCount = scans.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.summary.totalFiles,
    0
  )

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <DetectedThreat scans={scans} />
      <TextRow title="Infected Files">
        <Typography
          variant="caption"
          fontSize="14px"
          fontWeight={computeFontWeight(infectedFilesCount)}
          sx={{ color: computeColor(infectedFilesCount) }}
        >
          {infectedFilesCount}
        </Typography>
      </TextRow>
      <TextRow title="Scanned Files">
        <Typography variant="caption" fontSize="14px">
          {scannedFilesCount}
        </Typography>
      </TextRow>
      <ReportMenu options={reportsLinksState.map(mapReportsMenu)} />
    </Box>
  )
}

function getAssetIdFromScanTarget(scan: MalwareScan): string {
  if (scan.scanTarget.target instanceof MalwareScanBackup) {
    return (scan.scanTarget?.target?.source?.asset?.backupAssetId ||
      scan.scanTarget?.target?.source?.assetItem?.backupAssetId) as string
  }
  // Direct scan
  if ('assetId' in scan.scanTarget.target) {
    return scan.scanTarget.target.assetId
  }

  throw new Error('Invalid scan target')
}

function useGetAssetList(assetIds: Array<string>) {
  const [assetList, setAssetList] = useState<
    Array<{
      displayName: string
      id: string
    }>
  >([])

  const [isAssetListLoading, setIsAssetListLoading] = useState(false)

  React.useEffect(() => {
    async function getAssetList() {
      try {
        setIsAssetListLoading(true)
        const response = await assetsClient.listAssetsByIds(assetIds)

        setAssetList(
          response.map((asset) => {
            if (asset instanceof AWSAsset) {
              return {
                displayName: asset.awsId,
                id: asset.id,
              }
            }
            if (asset instanceof GenericHost) {
              return {
                displayName: asset.hostname,
                id: asset.id,
              }
            }

            if (asset instanceof OvaServer) {
              return {
                displayName: asset.name,
                id: asset.id,
              }
            }

            throw new Error('Invalid asset type')
          })
        )
      } catch (e) {
        console.error(e)
      } finally {
        setIsAssetListLoading(false)
      }
    }

    getAssetList()

    return () => setAssetList([])
  }, [assetIds.length])

  return {
    assetList,
    isAssetListLoading,
  }
}

function getAssetWithRelatedScanIds(scans: Array<MalwareScan>) {
  const assetWithRelatedScanIds = new Map<string, MalwareScan>()

  for (const scan of scans) {
    const scanTargetAssetId = getAssetIdFromScanTarget(scan)
    const currentScan = assetWithRelatedScanIds.get(scanTargetAssetId)

    if (!currentScan) {
      assetWithRelatedScanIds.set(scanTargetAssetId, scan)
    }
  }

  return assetWithRelatedScanIds
}

function fetchReportLinksWithRelatedAssetId({
  assetWithRelatedScanIds,
  assetList,
}: {
  assetWithRelatedScanIds: Map<string, MalwareScan>
  assetList: Array<{
    displayName: string
    id: string
  }>
}) {
  return Array.from(assetWithRelatedScanIds, async ([assetId, scan]) => {
    const reportLink = await downloadScanClient.downloadScan(scan.id)
    const displayedAssetName =
      assetList.find((asset) => asset.id === assetId)?.displayName || ''

    return {
      assetId: displayedAssetName,
      reportLink,
    }
  })
}

function useGetReportLinksWithRelatedAssetId({
  scans,
}: {
  scans: Array<MalwareScan>
}) {
  const [isReportLinksLoading, setIsReportLinksLoading] = useState(false)

  const assetWithRelatedScanIds = getAssetWithRelatedScanIds(scans)

  const { assetList, isAssetListLoading } = useGetAssetList(
    scans.map(getAssetIdFromScanTarget)
  )

  const [reportLinksState, setReportLinksState] = useState<
    Array<{
      assetId: string
      reportLink: string
    }>
  >([])

  React.useEffect(() => {
    async function getReportLinksWithRelatedAssetId() {
      if (scans.length === 0 || assetList.length === 0) {
        return
      }
      try {
        setIsReportLinksLoading(true)

        const reportLinks = fetchReportLinksWithRelatedAssetId({
          assetWithRelatedScanIds,
          assetList,
        })

        setReportLinksState(await Promise.all(reportLinks))
      } catch (e) {
        console.error(e)
      } finally {
        setIsReportLinksLoading(false)
      }
    }

    getReportLinksWithRelatedAssetId()

    return () => setReportLinksState([])
  }, [scans.length, assetList.length])

  return {
    isReportLinksLoading: isReportLinksLoading || isAssetListLoading,
    reportLinksState,
  }
}

function OvaBackupMalwareScanDetails({
  scans,
  backup,
}: {
  scans: Array<MalwareScan>
  backup: OvaBackup
}) {
  const [scansWithSnapshots, setScansWithSnapshots] = useState<
    Array<{
      assetId: string
      reportLink: string
    }>
  >([])

  useEffect(() => {
    async function fetchScansWithSnapshots() {
      try {
        const relatedScansWithSnapshots: Array<{
          assetId: string
          reportLink: string
        }> = []

        for (const snapshot of backup.snapshots) {
          const relatedScanId = scans.find(
            (scan) =>
              (scan.scanTarget.target as MalwareScanBackup).source.assetItem
                ?.backupAssetItemId === snapshot.ovaVolumeId
          )

          if (!relatedScanId) {
            continue
          }

          const scanDownloadLink = await downloadScanClient.downloadScan(
            relatedScanId.id
          )

          relatedScansWithSnapshots.push({
            reportLink: scanDownloadLink,
            assetId: snapshot.id,
          })
        }

        setScansWithSnapshots(relatedScansWithSnapshots)
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(
            `Something went wrong in malware-scan-details: ${error.message}`
          )
        }
      }
    }

    fetchScansWithSnapshots()
  }, [scans.length])

  return renderMalwareDetails(scans, scansWithSnapshots)
}

function useGetOvaBackup(scans: Array<MalwareScan>) {
  const [ovaBackup, setOvaBackup] = useState<null | OvaBackup>(null)
  const [isOvaBackupLoading, setIsOvaBackupLoading] = useState(false)

  useEffect(() => {
    async function fetchOvaBackup() {
      try {
        setIsOvaBackupLoading(true)
        const firstScan = scans[0]

        if (!firstScan) {
          return
        }

        if (!(firstScan.scanTarget.target instanceof MalwareScanBackup)) {
          return
        }

        const backupId = firstScan.scanTarget.target.backupId

        const response = await backupsByIdsClient.getBackupsByIds([backupId])

        if (response.backupsList[0]?.ovaBackup) {
          setOvaBackup(response.backupsList[0]?.ovaBackup)
        }
      } catch (error: any) {
        if ('message' in error) {
          SystemHelper.sendSentryIfProd(
            `Something went wrong in malware-scan-details: ${error.message}`
          )
        }
      } finally {
        setIsOvaBackupLoading(false)
      }
    }

    fetchOvaBackup()
  }, [scans.length])

  return {
    ovaBackup,
    isOvaBackupLoading,
  }
}

function AwsMalwareScanDetails({ scans }: Props) {
  const { isReportLinksLoading, reportLinksState } =
    useGetReportLinksWithRelatedAssetId({
      scans,
    })

  if (isReportLinksLoading) {
    return renderLoading()
  }

  return renderMalwareDetails(scans, reportLinksState)
}

function MalwareScanDetails({ scans }: Props) {
  const { isOvaBackupLoading, ovaBackup } = useGetOvaBackup(scans)

  if (isOvaBackupLoading) {
    return renderLoading()
  }

  if (ovaBackup) {
    return <OvaBackupMalwareScanDetails scans={scans} backup={ovaBackup} />
  }

  return <AwsMalwareScanDetails scans={scans} />
}

export default MalwareScanDetails
