/* eslint-disable import/no-extraneous-dependencies */
import React, { memo } from 'react'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'

import MoreBlock from '@components-composite/more-block/MoreBlock'

// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { MOUNT_RP_INFO_DRAW_MAPPER } from '@tables/core/table-vi-draw-mappers'

import CliCommandModalData from '@lib/interfaces/cli-command-modal-data.interface'
import { BackupScanStatus } from './backup-scan-status'
import { CliPopoverBlock } from './components'
import { SummaryCheckStatus } from 'blues-corejs/dist/models/backups/backup'

interface Props {
  detailsBlockData: CliCommandModalData
  scansStatus: SummaryCheckStatus
  isLoading: boolean
}

/**
 * @param {ReactNode} children This could be good done with a slot, but React have only one slot, so we have to use children. This should be used along with the CLI form component.
 * @param {CliCommandModalData} detailsBlockData - data for the details grid block
 * @param {SummaryCheckStatus} scansStatus - data for the scans status block
 */
function MountRecoveryPoint({
  detailsBlockData,
  scansStatus,
  isLoading,
  children,
}: React.PropsWithChildren<Props>) {
  const { details } = detailsBlockData

  if (isLoading) {
    return (
      <div className="wrap-1689065733686">
        <PreloaderBlock show />
      </div>
    )
  }

  return (
    <div className="wrap-1689065733686">
      <BackupScanStatus scanStatus={scansStatus} />
      <CliPopoverBlock />

      {!!details.length && (
        <MoreBlock
          data={details}
          columns={3}
          isButtonShow={false}
          className="dashboardView"
          drawMapper={MOUNT_RP_INFO_DRAW_MAPPER()}
        />
      )}
      {children}
    </div>
  )
}

export default memo(MountRecoveryPoint)
