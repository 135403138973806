import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import {
  ASSET_FILTERS_MAP,
  BACKUP_VENDOR_MAP,
} from 'ui-v2/src/lib/constants/assets.constant'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const BackupVendorFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const backupVendorList =
    searchParams.get(ASSET_FILTERS_MAP.BACKUP_VENDOR)?.split(',') || []

  const backupVendorOptions = useMemo(() => {
    return Object.entries(BACKUP_VENDOR_MAP).map(([key, value]) => ({
      label: value,
      value: key.toString(),
    }))
  }, [])

  const handleBackupVendorChange = (
    newBackupVendorList: Array<MultiSelectOption['value']>
  ) => {
    if (newBackupVendorList.length === 0) {
      searchParams.delete(ASSET_FILTERS_MAP.BACKUP_VENDOR)
    } else {
      searchParams.set(
        ASSET_FILTERS_MAP.BACKUP_VENDOR,
        newBackupVendorList.join(',')
      )
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterMultiSelect
      options={backupVendorOptions}
      value={backupVendorList}
      onChange={handleBackupVendorChange}
      label="Vendor"
      width={200}
    />
  )
}

export default BackupVendorFilter
