import React, { FunctionComponent, useState } from 'react'
import { EngineCallback, VIMatrix, VINumberMap, VIRow } from '@lib/engine-types'
import AssetsVIHead from '@tables/head-vi/AssetsVIHead'
import AssetsVIRow from '@tables/rows-vi/AssetsVIRow'
import {
  HeadComponentData,
  OnChangeData,
  OnMenuClickData,
  RowComponentData,
  SettingsClickData,
} from '@lib/interfaces/tables/assets-table.interface'
import { EmptyFunc } from '@lib/constants/app.constant'

// the functionality is described in
// ./doc/faq/components/[3] assets vi table.md

interface Props {
  head: VIRow
  rows: VIMatrix
  className?: string
  menu?: Array<string>
  forceTemplate?: Array<number>
  skipTemplate?: Array<number>
  onMenuClick?: EngineCallback<OnMenuClickData>
  onHeadChange: EngineCallback<OnChangeData>
  onRowChange: EngineCallback<OnChangeData>
  headComponent?: FunctionComponent<HeadComponentData>
  rowComponent?: FunctionComponent<RowComponentData>
  columnDrawMapper?: VINumberMap
}

function AssetsVITable({
  head,
  rows,
  className = '',
  menu = [],
  forceTemplate,
  skipTemplate,
  onMenuClick = EmptyFunc,
  onHeadChange,
  onRowChange,
  headComponent = AssetsVIHead,
  rowComponent = AssetsVIRow,
  columnDrawMapper = {},
}: Props) {
  const [columnState, setColumnState] = useState(head.map(() => 1))
  const onSettingsClick = (data: SettingsClickData) => {
    const result = [...columnState]
    result[data.index] = +data.value
    setColumnState(result)
  }
  const HeadComponent = headComponent
  const RowComponent = rowComponent
  return (
    <table
      className={`wrap-1602747175897 assetsVITable shrinkLastColumn tableVIUncontrolled ${className}`}
      data-testid="assetsVITable"
    >
      <thead>
        <HeadComponent
          columnState={columnState}
          onSettingsClick={onSettingsClick}
          onChange={onHeadChange}
          data={head}
          forceTemplate={forceTemplate}
          skipTemplate={skipTemplate}
        />
      </thead>
      <tbody>
        {rows.map((v: VIRow, index: number) => (
          <RowComponent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data={v}
            menu={menu}
            onMenuClick={onMenuClick}
            onChange={onRowChange}
            columnState={columnState}
            forceTemplate={forceTemplate}
            columnDrawMapper={columnDrawMapper}
            showMenu
          />
        ))}
      </tbody>
    </table>
  )
}

export default AssetsVITable
