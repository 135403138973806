import { useState, useEffect } from 'react'
import {
  useInfrastructureScopeActions,
  useInfrastructureScopeState,
} from './infrastructure-scope-store'
import { useDebouncedCallback } from 'use-debounce'
import Box from '@mui/material/Box'
import {
  Label,
  RadioGroupController,
  Subtitle,
  Divider,
} from '@features/alerts/create-rule-drawer/shared'
import { FormProvider, useForm } from 'react-hook-form'
import {
  FORM_FIELDS,
  defaultValues,
  AWS,
  infrastructureTypeOptions,
} from './consts'
import { InfrastructureScopeValues } from './types'
import { AssetSelector } from './asset-tag-selection'
import { OnPrem } from './on-prem'
import { useFormValidationActions } from '@features/alerts/create-rule-drawer'

export const DEBOUNCE_DELAY_MS = 300

function InfrastructureScope() {
  const { setIsFormValid } = useFormValidationActions()
  const { setInfrastructureScopeData } = useInfrastructureScopeActions()
  const infraData = useInfrastructureScopeState()
  const INFRASTRUCTURE_TYPE_DEFAULT_VALUE =
    infraData.infrastructureType || defaultValues.infrastructureType
  const methods = useForm<any>({
    defaultValues: {
      [FORM_FIELDS.INFRASTRUCTURE_TYPE]: INFRASTRUCTURE_TYPE_DEFAULT_VALUE,
      [FORM_FIELDS.ACCOUNTS_REGIONS]:
        infraData.accountsRegions || defaultValues.accountsRegions,
      [FORM_FIELDS.ACCOUNTS_BACKUP_PROVIDERS]:
        infraData.accountsBackupProviders ||
        defaultValues.accountsBackupProviders,
    },
    mode: 'onChange',
  })

  const debouncedSave = useDebouncedCallback(
    (values: InfrastructureScopeValues) => {
      setInfrastructureScopeData(values)
    },
    DEBOUNCE_DELAY_MS
  )

  const [shouldRenderAWSForm, setShouldRenderAWSForm] = useState(
    INFRASTRUCTURE_TYPE_DEFAULT_VALUE === AWS
  )
  useEffect(() => {
    const { unsubscribe } = methods.watch((value) => {
      setShouldRenderAWSForm(value[FORM_FIELDS.INFRASTRUCTURE_TYPE] === AWS)
      debouncedSave(value)
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    setIsFormValid(true)
  }, [methods.formState.isValid])

  return (
    <Box>
      <Subtitle>
        Select infrastructure and optionally asset tags (AWS Only)
      </Subtitle>
      <Divider />
      <FormProvider {...methods}>
        <Label>Infrastructure</Label>
        <RadioGroupController
          name={FORM_FIELDS.INFRASTRUCTURE_TYPE}
          defaultValue={INFRASTRUCTURE_TYPE_DEFAULT_VALUE}
          labelOptions={infrastructureTypeOptions}
        />
        {shouldRenderAWSForm ? (
          <AssetSelector name={FORM_FIELDS.ACCOUNTS_REGIONS} />
        ) : (
          <OnPrem name={FORM_FIELDS.ACCOUNTS_BACKUP_PROVIDERS} />
        )}
      </FormProvider>
    </Box>
  )
}

export default InfrastructureScope
