import { Typography } from '@mui/material'
import styles from './index.module.scss'
import Link from '@lib/router/navigation-strategies/link'
import Box from '@mui/material/Box'

interface Props {
  text: string
  href: string
  last: boolean
}

function Crumb({ text, href, last = false }: Props) {
  if (last) {
    return <Typography>{text}</Typography>
  }

  return (
    <Link to={href}>
      <Box component="span" className={styles.crumbLink}>
        {text}
      </Box>
    </Link>
  )
}

export default Crumb
