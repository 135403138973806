import React from 'react'
import Box from '@mui/material/Box'
import { IconPopover } from '@components-simple/icon-popover'
import { useDispatch, useSelector } from 'react-redux'
import { RadioTabs } from '@components-simple/radio-tabs'
import {
  getAssetTypeTabSelectedNum,
  getEfsSelectedAssetsSelect,
  getS3SelectedAssetsSelect,
  getSelectedAssetsSelect,
} from '@store/selectors/assets-select.selector'
import { setEditMainTabAssets } from '@store/actions/assets-select.action'
import { SelectedVariant, TabAssetType } from '@lib/constants/app.constant'
import { getAllNotDeletedRedStacksList } from '@store/selectors/rex.selector'
import { DataHelper } from '@lib/helpers'
import { TabWithTooltip } from '@lib/interfaces/tab-with-tooltip'
import { TextConstant } from '@lib/constants'
import { Ec2EbsProtectionOptions } from './ec2-ebs-protection-options'
import { S3ProtectionOptions } from './s3-protection-options'
import { EfsProtectionOptions } from './efs-protection-options'
import { setEditSelectedVariant } from '@store/actions/edit-policy.action'
import { getEditPolicyTags } from '@store/selectors/edit-policy.selector'

enum AssetVariants {
  EBS = 0,
  S3 = 1,
  EFS = 2,
}

const useGetDisabledTabsTooltips = (): Array<TabWithTooltip> => {
  const allNotDeletedRedStack = useSelector(getAllNotDeletedRedStacksList)
  const isNotSupportedVersionCCs = DataHelper.isNotSupportedVersionCCsFunc(
    allNotDeletedRedStack
  )

  const disabledTabsTooltips: Array<TabWithTooltip> = []

  if (isNotSupportedVersionCCs) {
    disabledTabsTooltips.push({
      tabIndex: TabAssetType.S3,
      tooltip: TextConstant.WARNING_S3_MESSAGE,
    })
  }

  return disabledTabsTooltips
}

const useGetDisabledTabs = (): Array<TabAssetType> => {
  const allNotDeletedRedStack = useSelector(getAllNotDeletedRedStacksList)
  const selectedS3Assets = useSelector(getS3SelectedAssetsSelect)
  const selectedEfsAssets = useSelector(getEfsSelectedAssetsSelect)
  const selectedAssets = useSelector(getSelectedAssetsSelect)
  const policyTags = useSelector(getEditPolicyTags)

  const isNotSupportedVersionCCs = DataHelper.isNotSupportedVersionCCsFunc(
    allNotDeletedRedStack
  )

  const isDisabledTab =
    selectedEfsAssets.length > 0 ||
    selectedS3Assets.length > 0 ||
    policyTags.length > 0 ||
    selectedAssets.length > 0

  const disabledTabs: Array<TabAssetType> = []

  if (isNotSupportedVersionCCs) {
    disabledTabs.push(TabAssetType.S3)
  }

  if (isDisabledTab) {
    return [TabAssetType.Ec2andEbs, TabAssetType.S3, TabAssetType.EFS]
  }

  return disabledTabs
}

interface Props {
  isLoading: boolean
  isAssetsSelectionHasProblems: (
    isS3Invalid: boolean,
    isEFSInvalid: boolean
  ) => void
}

const RADIO_TABS_LABELS = ['EC2/EBS', 'S3', 'EFS']

export function AssetsSelection({
  isLoading,
  isAssetsSelectionHasProblems,
}: Props) {
  const disabledTabs = useGetDisabledTabs()
  const disabledTabsTooltips = useGetDisabledTabsTooltips()
  const dispatch = useDispatch()
  const selectedTypeVariant = useSelector(getAssetTypeTabSelectedNum)

  const [assetsSelectProblems, setAssetsSelectProblems] = React.useState({
    isS3Invalid: false,
    isEFSInvalid: false,
  })

  const handleTypeTabChange = (newTabIndex: number) => {
    dispatch(setEditMainTabAssets(newTabIndex as TabAssetType))

    switch (newTabIndex) {
      case TabAssetType.S3:
        dispatch(setEditSelectedVariant(SelectedVariant.S3Variant))
        break
      case TabAssetType.EFS:
        dispatch(setEditSelectedVariant(SelectedVariant.EfsVariant))
        break
      case TabAssetType.Ec2andEbs:
        dispatch(setEditSelectedVariant(SelectedVariant.AllEC2Variant))
        break
      default:
        throw new Error('Unknown asset tab index')
        break
    }
  }

  const shouldRenderEc2EbsProtectionOptions =
    selectedTypeVariant === AssetVariants.EBS

  const shouldRenderS3BucketProtectionOptions =
    selectedTypeVariant === AssetVariants.S3

  const shouldRenderEfsProtectionOptions =
    selectedTypeVariant === AssetVariants.EFS

  const handleS3TableErrorChange = (isError: boolean) => {
    setAssetsSelectProblems((prev) => ({
      ...prev,
      isS3Invalid: isError,
    }))

    isAssetsSelectionHasProblems(isError, assetsSelectProblems.isEFSInvalid)
  }

  const handleEfsTableErrorChange = (isError: boolean) => {
    setAssetsSelectProblems((prev) => ({
      ...prev,
      isEFSInvalid: isError,
    }))

    isAssetsSelectionHasProblems(assetsSelectProblems.isS3Invalid, isError)
  }

  return (
    <Box>
      <Box className="addPolicyTitle jsAddPolicyChooseAssetsSubTitle">
        Choose Assets To Protect
        <IconPopover
          variant="info"
          message="You can import the latest pre-existing EBS snapshots into your recovery point chain to maintain recovery history and to ensure security compliance. Advanced import options are available from the CLI to import older EBS snapshots."
        />
      </Box>
      <RadioTabs
        className="flexColumnJustifyStart assetTypeSelectBlock jsTabAssetTypeSelectRadio"
        labels={RADIO_TABS_LABELS}
        currentTabIndex={selectedTypeVariant}
        onCurrentTabChange={handleTypeTabChange}
        disabledRadioList={disabledTabs}
        tabsTooltips={disabledTabsTooltips}
      >
        {shouldRenderEc2EbsProtectionOptions && (
          <Ec2EbsProtectionOptions isLoading={isLoading} />
        )}
        {shouldRenderS3BucketProtectionOptions && (
          <S3ProtectionOptions
            onS3TableErrorChange={handleS3TableErrorChange}
          />
        )}
        {shouldRenderEfsProtectionOptions && (
          <EfsProtectionOptions
            onEfsTableErrorChange={handleEfsTableErrorChange}
          />
        )}
      </RadioTabs>
    </Box>
  )
}
