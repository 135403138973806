import { DeleteReportScheduleRequest } from '@lib/clients/grpc-imports'

export interface DeleteReportScheduleCriteria {
  id: string
}

export function buildDeleteReportScheduleRequest({
  id,
}: DeleteReportScheduleCriteria): DeleteReportScheduleRequest {
  return new DeleteReportScheduleRequest().setId(id)
}
