// eslint-disable-next-line import/no-extraneous-dependencies
import { GeneratedReportFormat } from 'blues-corejs/dist/models/reports/report-generated/format-list'

const formatMapping: Record<string, GeneratedReportFormat> = {
  PDF: GeneratedReportFormat.GENERATED_REPORT_FORMAT_PDF,
  CSV: GeneratedReportFormat.GENERATED_REPORT_FORMAT_CSV,
  XLSX: GeneratedReportFormat.GENERATED_REPORT_FORMAT_XLSX,
}

export function mapFormats(
  formats: Array<string>
): Array<GeneratedReportFormat> {
  return formats.map((format) => {
    const mappedFormat = formatMapping[format]
    if (mappedFormat === undefined) {
      throw new Error(`Unsupported format: ${format}`)
    }
    return mappedFormat
  })
}
