import {
  DashboardRoutes,
  DashboardTab,
  InventoryTableParams,
} from '@lib/router/routes/dashboard/dashboard'

const retentionAscParam: InventoryTableParams = {
  retention: 'asc',
}
const retentionDescParam: InventoryTableParams = {
  retention: 'desc',
}

export const INVENTORY_INSTANCES_TABLE =
  DashboardRoutes.inventoryTablesWithParams(
    String(DashboardTab.EC2),
    retentionAscParam
  )
export const INVENTORY_VOLUMES_TABLE =
  DashboardRoutes.inventoryTablesWithParams(
    String(DashboardTab.EBS),
    retentionAscParam
  )
export const INVENTORY_INSTANCES_TABLE_DESC =
  DashboardRoutes.inventoryTablesWithParams(
    String(DashboardTab.EC2),
    retentionDescParam
  )
export const INVENTORY_VOLUMES_TABLE_DESC =
  DashboardRoutes.inventoryTablesWithParams(
    String(DashboardTab.EBS),
    retentionDescParam
  )
