import React from 'react'

function MacosIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2223 11.6204C18.2223 10.1431 18.9502 9.06505 20.3632 8.22656C19.5497 7.14851 18.3507 6.58952 16.7664 6.46974C15.2249 6.34995 13.555 7.2683 12.9555 7.2683C12.3132 7.2683 10.8573 6.50967 9.70121 6.50967C7.30332 6.54959 4.77698 8.26649 4.77698 11.8201C4.77698 12.8582 4.94826 13.9363 5.37645 15.0542C5.9331 16.5316 7.90279 20.1251 9.95813 20.0452C11.0286 20.0452 11.7994 19.3265 13.2124 19.3265C14.5826 19.3265 15.2677 20.0452 16.4667 20.0452C18.5648 20.0452 20.3632 16.7711 20.8771 15.2938C18.0938 14.056 18.2223 11.7003 18.2223 11.6204ZM15.8244 5.07226C16.9805 3.79457 16.8521 2.59673 16.8521 2.15753C15.8244 2.23738 14.6254 2.8363 13.9403 3.555C13.1696 4.35356 12.7414 5.35176 12.827 6.42981C13.9403 6.50967 14.968 5.9906 15.8244 5.07226Z"
        fill="white"
      />
    </svg>
  )
}

export default MacosIcon
