import React, { memo } from 'react'
import { ConfigurationVulnerabilities } from '@features/DashboardV4/ConfigurationVulnerabilities'
import { ScansAndTests } from '@features/DashboardV4/ScansAndTests'
import { ThreatsWrapper } from '@features/DashboardV4/threats-widgets'
import { Divider } from '@mui/material'

function OverviewLeftColumn() {
  return (
    <React.Fragment>
      <ThreatsWrapper />
      <Divider />
      <ConfigurationVulnerabilities />
      <Divider />
      <ScansAndTests />
    </React.Fragment>
  )
}

export default memo(OverviewLeftColumn)
