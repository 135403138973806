/* eslint-disable import/no-extraneous-dependencies */
import { GrpcClient } from '@lib/clients/grpc-client'
import {
  NotificationRulesPromiseClient,
  ListNotificationRulesRequest,
  EnableNotificationRulesRequest,
  DisableNotificationRulesRequest,
  DeleteNotificationRulesRequest,
} from '@lib/clients/grpc-imports'
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'
import GrpcRequestError from '@lib/errors/grpc-error'
import { GrpcCodes } from '@lib/constants/data/error/api-errors.constant'
import ToastHelper from '@lib/helpers/toast.helper'
import { convertRules } from '@lib/clients/notifications/transform-grpc'
import {
  CreateNotificationRuleCriteria,
  UpdateNotificationRuleCriteria,
} from './types'
import { buildCreateNotificationRuleRequest } from './create-notification-rule'
import { updateCreateNotificationRuleRequest } from './update-notification-rule'

const NOTIFICATIONS_RULES_CLIENT_NAME = 'NotificationRulesPromiseClient'

export class NotificationsRulesClient extends GrpcClient<NotificationRulesPromiseClient> {
  #notificationsRulesClient: NotificationRulesPromiseClient

  constructor(hostName = '') {
    super()
    this.#notificationsRulesClient = this.getClient(hostName)
  }

  protected innerClientTypeId(): string {
    return NOTIFICATIONS_RULES_CLIENT_NAME
  }

  protected initClient(hostName = ''): NotificationRulesPromiseClient {
    return new NotificationRulesPromiseClient(hostName, null, null)
  }

  async createNotificationRule(
    createNotificationRuleCriteria: CreateNotificationRuleCriteria
  ): Promise<void> {
    const request = buildCreateNotificationRuleRequest(
      createNotificationRuleCriteria
    )

    try {
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.createNotificationRule(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/CreateNotificationRule`,
        }
      )
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/CreateNotificationRule`,
        })
        throw grpcError
      }
    }
  }

  async updateNotificationRule(
    updateNotificationRuleCriteria: UpdateNotificationRuleCriteria
  ): Promise<void> {
    const request = updateCreateNotificationRuleRequest(
      updateNotificationRuleCriteria
    )

    try {
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.updateNotificationRule(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/UpdateNotificationRule`,
        }
      )
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.ALREADY_EXISTS,
          message: '',
          metadata: {},
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/UpdateNotificationRule`,
        })
        throw grpcError
      }
    }
  }

  async getNotificationRulesList({ search }: { search: string }): Promise<{
    rulesList: Array<NotificationRule>
    pageToken?: string
  }> {
    const request = new ListNotificationRulesRequest()
    const initial = new ListNotificationRulesRequest.Initial()
      .setTextSearch(search)
      .setLimit(15)
    request.setInitial(initial)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.listNotificationRules(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/getNotificationRulesList`,
        }
      )
    ).toObject()

    return {
      rulesList: convertRules(response.rulesList),
      pageToken: response.nextPageToken,
    }
  }

  async getNotificationRulesListPagination({
    pageToken,
  }: {
    pageToken: string
  }): Promise<{
    rulesList: Array<NotificationRule>
    pageToken?: string
  }> {
    const request = new ListNotificationRulesRequest()

    request.setPageToken(pageToken)

    const response = (
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.listNotificationRules(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/getNotificationRulesListPagination`,
        }
      )
    ).toObject()

    return {
      rulesList: convertRules(response.rulesList),
      pageToken: response.nextPageToken,
    }
  }

  async enableNotificationRule({ ids }: { ids: Array<string> }): Promise<void> {
    const request = new EnableNotificationRulesRequest()
    request.setIdsList(ids)

    try {
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.enableNotificationRules(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/enableNotificationRule`,
        }
      )
      ToastHelper.success('Rules enabled')
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.NOT_FOUND,
          message: '',
          metadata: {},
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/enableNotificationRule`,
        })
        throw grpcError
      }
    }
  }

  async disableNotificationRule({
    ids,
  }: {
    ids: Array<string>
  }): Promise<void> {
    const request = new DisableNotificationRulesRequest()
    request.setIdsList(ids)

    try {
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.disableNotificationRules(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/disableNotificationRule`,
        }
      )
      ToastHelper.success('Rules disabled')
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.NOT_FOUND,
          message: '',
          metadata: {},
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/disableNotificationRule`,
        })
        throw grpcError
      }
    }
  }

  async deleteNotificationRule({ ids }: { ids: Array<string> }): Promise<void> {
    const request = new DeleteNotificationRulesRequest()
    request.setIdsList(ids)

    try {
      await this.retryGrpcCall(
        () =>
          this.#notificationsRulesClient.deleteNotificationRules(
            request,
            this.metadata()
          ),
        {
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/deleteNotificationRule`,
        }
      )
      ToastHelper.success('Rules deleted')
    } catch (error) {
      if (error instanceof GrpcRequestError) {
        const grpcError = new GrpcRequestError({
          code: error.code ?? GrpcCodes.NOT_FOUND,
          message: '',
          metadata: {},
          requestName: `${NOTIFICATIONS_RULES_CLIENT_NAME}/deleteNotificationRule`,
        })
        throw grpcError
      }
    }
  }
}
