import moment, { Moment } from 'moment'

import { getTimezone } from './get-timezone'

export const getMomentWithTimezone = (startsOn: {
  year: number
  month: number
  day: number
}): Moment => {
  const timezone = getTimezone()
  return moment.tz(
    {
      year: startsOn?.year,
      month: startsOn?.month - 1,
      day: startsOn?.day,
    },
    timezone
  )
}
