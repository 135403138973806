import { VIRow } from '@lib/engine-types'
import { AssetsEntityType } from '@lib/interfaces/tables/assets-table.interface'
import CheckboxConstant from '@lib/constants/checkbox.constant'
import { TextConstant } from '@lib/constants'

export const ASSETS_POLICY_RETENTION_HEAD: VIRow = [
  { name: 'Resource ID' },
  { name: 'Type' },
  { name: 'Account ID' },
  { name: 'Region' },
]

export const BACKUP_POLICIES_HEAD: VIRow = [
  { name: 'Name' },
  { name: 'Status' },
  { name: 'Last Run' },
  { name: 'Next Run' },
  // columns are temporary hidden by MSKCC Support issues
  /* { name: 'Sources' },
  { name: 'Assets' }, */
  { name: '' },
]

export const POLICIES_RETENTION_HEAD: VIRow = [
  { name: 'Name' },
  { name: 'Status' },
  { name: 'Assets' },
  { name: 'Retention' },
  { name: '' },
]

export const POLICIES_RETENTION_HEAD_HIDDEN_ASSETS: VIRow = [
  { name: 'Name' },
  { name: 'Status' },
  { name: 'Retention' },
  { name: '' },
]

export const WEBHOOK_EVENTS_TABLE_HEAD: VIRow = [
  { name: 'Event type' },
  { name: 'Version' },
  { name: 'Severity' },
  { name: '' },
]

export const WEBHOOK_TABLE_HEAD: VIRow = [
  { name: 'Webhook name' },
  { name: 'Endpoint URL' },
  { name: 'Description' },
  { name: '' },
  { name: '' },
  { name: '' },
]

export const MANAGE_USERS_TABLE_HEAD: VIRow = [
  { name: 'User' },
  /*TODO: hidden 09.02.2024 by ticket #9484*/
  // { name: 'Group' },
  // { name: 'Role' },
  { name: 'Last Logged in' },
  { name: 'Status' },
]

export const MANAGE_GROUPS_TABLE_HEAD: VIRow = [
  { name: 'Group' },
  /*TODO: hidden 09.02.2024 by ticket #9484*/
  // { name: 'Role' },
  { name: '# of Users' },
  { name: 'Last Logged in' },
]

export const RECOVERY_POINTS_TABLE_HEAD: VIRow = [
  { name: '' },
  { name: 'Date' },
  { name: 'Tags' },
  {
    name: 'Backup Size',
    label: 'The total number in bytes to be backed up',
  },
  { name: 'Data Size' },
  {
    name: 'Optimized Size',
    label:
      'The total number of bytes stored in the vault after deduplication and compression. This size might differ from the size of the S3 bucket of the vault, as both successful and unsuccessful backups are stored in S3.',
  },
  { name: 'Kind' },
  { name: '' },
]

export const RECOVERY_POINTS_S3_BUCKET_TABLE_HEAD: VIRow = [
  { name: '' },
  { name: 'Date (UTC)' },
  { name: 'Tag(s)' },
  { name: 'Asset type' },
  {
    name: 'Backup size',
  },
  { name: 'Data size' },
  {
    name: 'Optimized size',
  },
  { name: '' },
]

export const RECOVERY_POINTS_S3_BUCKET_PATH_TABLE_HEAD: VIRow = [
  { name: '' },
  { name: 'Path' },
  { name: 'Tag(s)' },
  { name: 'Asset type' },
  {
    name: 'Backup size',
  },
  { name: 'Data size' },
  {
    name: 'Optimized size',
  },
  { name: '' },
]

export const ACTIVE_SOURCES_TABLE_HEAD: VIRow = [
  { name: '' },
  { name: 'Account ID' },
  { name: 'Alias Name' },
  { name: 'Description' },
  { name: 'Regions' },
  { name: '' },
]

export const REVOKE_TOKENS_HEAD: VIRow = [
  { name: 'Token' },
  { name: 'Scope' },
  { name: 'Status' },
  { name: 'Last Used' },
]

export const REGION_STATUS_PROGRESS_HEAD: VIRow = [
  { name: 'Status' },
  { name: 'Enabling Regions' },
  { name: 'Instances' },
  { name: 'Actions' },
]

export const ON_BOARDING_SOURCES_HEAD = [
  { name: 'Account ID' },
  { name: 'Alias Name' },
  { name: 'Account Region' },
]

export const DASHBOARD_STORAGE_LIST_HEAD: VIRow = [
  { name: 'Alias Name' },
  { name: 'Account ID' },
  { name: 'Backup Data' },
  { name: 'Amount Stored' },
  { name: 'Savings Ratio' },
]

export const ASSETS_POLICIES_VI_HEAD = (
  checkboxState = CheckboxConstant.Empty
): VIRow => [
  // checkbox
  {
    name: '',
    type: AssetsEntityType.Checkbox,
    value: checkboxState,
    selectedOption: {
      name: '',
      label: '',
    },
  },
  // state
  { name: '' },
  { name: 'Resource ID' },
  { name: 'Asset Tag(s)' },
  { name: 'Asset Type' },
  { name: 'Account ID' },
  { name: 'Region' },
  {
    name: 'Last backup',
    label: TextConstant.ASSETS_SELECT_LAST_BACKUP_MESSAGE,
  },
  { name: 'Policy' },
]

export const ASSETS_S3_POLICIES_VI_HEAD = (
  checkboxState = CheckboxConstant.Empty
): VIRow => [
  // checkbox
  {
    name: '',
    type: AssetsEntityType.Checkbox,
    value: checkboxState,
    selectedOption: {
      name: '',
      label: '',
    },
  },
  // state
  { name: '' },
  { name: 'Bucket name' },
  { name: 'Type' },
  { name: 'Account ID' },
  { name: 'Region' },
  { name: 'Policy' },
  { name: 'Paths' },
  { name: '' },
]

export const ASSETS_EFS_POLICIES_VI_HEAD = (
  checkboxState = CheckboxConstant.Empty
): VIRow => [
  // checkbox
  {
    name: '',
    type: AssetsEntityType.Checkbox,
    value: checkboxState,
    selectedOption: {
      name: '',
      label: '',
    },
  },
  // state
  { name: '' },
  { name: 'File system name' },
  { name: 'Type' },
  { name: 'Account ID' },
  { name: 'Region' },
  { name: 'Policy' },
  { name: 'Paths' },
  { name: '' },
]

export const ASSETS_SUB_TABLE_VI_HEAD_FOR_EC2 = (): VIRow => [
  { name: '' },
  { name: '' },
  { name: '' },
  { name: 'Name' },
  { name: 'Volume ID' },
  { name: 'Size (Gib)' },
  { name: 'Volume Type' },
  { name: '' },
  { name: '' },
  { name: '' },
  { name: '' },
]

export const ASSETS_SUB_TABLE_VI_HEAD_FOR_EBS = (): VIRow => [
  { name: '' },
  { name: '' },
  { name: '' },
  { name: 'Resource ID' },
  { name: 'Asset Tag(s)' },
  { name: 'Asset Type' },
  { name: 'Account ID' },
  { name: 'Region(s)' },
  { name: 'Last backup' },
  { name: 'Policies' },
  { name: '' },
]

export const SOURCE_ID_HEADERS: VIRow = [
  { name: 'Enabling Regions' },
  { name: 'Status' },
]

export const JOB_TABLE_HEAD: VIRow = [
  // expand
  { name: '' },
  // data
  { name: 'Scheduled On' },
  { name: 'Job Type' },
  { name: 'Cloud Connector' },
  { name: 'Asset Kind' },
  { name: 'Resource Id' },
  { name: 'Message' },
  { name: 'Task' },
  { name: 'Completed On' },
]

export const ANTI_MALWARE_REPORTS_HEAD: VIRow = [
  { name: '' },
  { name: 'Date' },
  { name: 'Origin' },
  { name: 'Account' },
  { name: 'Region' },
]

export const AWS_SOURCES_DASHBOARD_HEAD = [
  { name: 'Alias Name' },
  { name: 'Region(s)' },
  { name: 'Protected' },
]

export const BACKUP_POLICIES_DASHBOARD_HEAD = [
  { name: 'Name' },
  { name: 'Frequency' },
  { name: 'Status' },
  { name: 'Assets' },
]

export const VAULT_REPLICATION = [
  { name: 'Destination' },
  { name: 'Type' },
  { name: 'Last Sync’ed' },
  { name: 'Next Sync' },
  { name: 'Status' },
  { name: '' },
]

export const MISSING_ASSETS_HEAD: VIRow = [
  { name: 'Resource ID' },
  { name: 'Asset Type' },
  { name: 'Account ID' },
  { name: 'Vault Region' },
]

export const WEBHOOK_SELECTED_EVENTS_HEAD = [
  { name: 'Event type' },
  { name: 'Version' },
  { name: 'Severity' },
  { name: '' },
]

export const EBS_SNAPSHOTS_HEAD: VIRow = [
  {
    name: '',
    extraValue: false,
  },
  {
    name: 'Volume ID',
    extraValue: true,
  },
  {
    name: 'Account no',
    extraValue: true,
  },
  {
    name: 'Region',
    extraValue: true,
  },
  {
    name: 'Snapshot ID',
    extraValue: true,
  },
  {
    name: 'Snapshot name',
    extraValue: true,
  },
  {
    name: 'Cost ($)',
    extraValue: true,
  },
  {
    name: 'Size (GiB)',
    extraValue: true,
  },
  {
    name: 'Age (Days)',
    extraValue: true,
  },
  {
    name: 'Created',
    extraValue: true,
  },
]

export const ASSETS_THREATS_INSTANCES_HEAD: VIRow = [
  {
    name: '',
    extraValue: false,
  },
  {
    name: 'Instance ID',
    extraValue: true,
  },
  {
    name: 'Threats',
    extraValue: true,
  },
  {
    name: 'Account ID',
    extraValue: true,
  },

  {
    name: 'Region',
    extraValue: true,
  },
  {
    name: 'OS Type',
    extraValue: true,
  },
  {
    name: 'Attached volumes',
    extraValue: true,
  },
  {
    name: 'Status',
    extraValue: true,
  },
  {
    name: 'Last backup',
    extraValue: true,
  },
]

export const ASSETS_THREATS_VOLUMES_HEAD: VIRow = [
  {
    name: '',
    extraValue: true,
  },
  {
    name: 'Volume ID',
    extraValue: true,
  },
  {
    name: 'Threats',
    extraValue: true,
  },
  {
    name: 'Account ID',
    extraValue: true,
  },
  {
    name: 'Region',
    extraValue: true,
  },
  {
    name: 'Instance attached',
    extraValue: true,
  },
  {
    name: 'Status',
    extraValue: true,
  },

  {
    name: 'Last backup',
    extraValue: true,
  },
]

export const ASSETS_RECOVERY_EXPOSURE_EC2_HEAD: VIRow = [
  {
    name: '',
    extraValue: false,
  },
  {
    name: 'Instance ID',
    extraValue: true,
  },
  {
    name: 'Threats',
    extraValue: true,
  },
  {
    name: 'Misconfiguration',
    extraValue: true,
  },
  {
    name: 'Account ID',
    extraValue: true,
  },

  {
    name: 'Region',
    extraValue: true,
  },
  {
    name: 'Status',
    extraValue: true,
  },
  {
    name: 'Last backup',
    extraValue: true,
  },
]

export const ASSETS_RECOVERY_EXPOSURE_EBS_HEAD: VIRow = [
  {
    name: '',
    extraValue: true,
  },
  {
    name: 'Volume ID',
    extraValue: true,
  },
  {
    name: 'Threats',
    extraValue: true,
  },
  {
    name: 'Misconfiguration',
    extraValue: true,
  },
  {
    name: 'Account IDs',
    extraValue: true,
  },
  {
    name: 'Region',
    extraValue: true,
  },
  {
    name: 'Instance attached',
    extraValue: true,
  },
  {
    name: 'Status',
    extraValue: true,
  },

  {
    name: 'Last backup',
    extraValue: true,
  },
]
