import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Checkbox, FormControlLabel, Box } from '@mui/material'

interface FormatOption {
  label: string
  value: string
}

interface ControlledFormatCheckboxGroupOwnProps {
  name: string
  options: Array<FormatOption>
}

function ControlledFormatCheckboxGroup({
  name,
  options = [],
}: ControlledFormatCheckboxGroupOwnProps) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const handleToggle = (value: string) => {
          const newFormats = (field.value || []).includes(value)
            ? (field.value || []).filter((v: string) => v !== value)
            : [...(field.value || []), value]
          field.onChange(newFormats)
        }

        return (
          <Box display="flex" gap={2}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={(field.value || []).includes(option.value)}
                    onChange={() => handleToggle(option.value)}
                    aria-label={option.label}
                  />
                }
                label={option.label}
              />
            ))}
          </Box>
        )
      }}
    />
  )
}

export default ControlledFormatCheckboxGroup
