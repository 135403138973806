/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  AssetItemEfsIcon,
  AssetItemFileIcon,
  AssetItemS3Icon,
  AssetItemStreamIcon,
  AssetItemVolumeIcon,
  AssetItemDiskIcon,
  AssetItemFolderIcon,
} from '@features/DashboardV4/common'
import {
  AssetItem,
  Disk,
  EFS,
  EfsItem,
  File,
  GenericHost,
  LocalVolume,
  S3Bucket,
  S3Item,
  Stream,
} from 'blues-corejs/dist'
import SystemHelper from '@lib/helpers/system.helper'

type Asset = S3Bucket | EFS | GenericHost

export function computeAssetIcon(asset: AssetItem | Asset) {
  switch (asset.constructor) {
    case EFS:
      return <AssetItemEfsIcon />
    case S3Bucket:
      return <AssetItemS3Icon />
    case File:
      return <AssetItemFileIcon />
    case Stream:
      return <AssetItemStreamIcon />
    case LocalVolume:
      return <AssetItemVolumeIcon />
    case Disk:
      return <AssetItemDiskIcon />
    case EfsItem:
    case S3Item:
      return <AssetItemFolderIcon />
    default:
      SystemHelper.sendSentryIfProd(
        `computeAssetItemIcon: Unknown asset item type: ${asset.id}`
      )
      return null
  }
}
