import { PolicyStatus } from './policies.constant'

export const RETENTION_POLICY_LIST = {
  defaultRetentionPolicy: {
    defaultPolicy: {
      id: 'Default Retention Policy',
      policy: {
        keepAll: {
          years: 1,
        },
        keepDaily: {
          days: 2,
        },
        keepMonthly: {
          months: 3,
        },
        status: PolicyStatus.POLICY_STATUS_ENABLED,
      },
    },
    coveredAssetCount: 8811,
  },
  infected: {
    customPolicy: {
      id: 'Infected=No',
      policy: {
        keepWeekly: {
          weeks: 2,
        },
        keepYearly: {
          years: 3,
        },
        status: PolicyStatus.POLICY_STATUS_ENABLED,
      },
    },
    coveredAssetCount: 234,
  },
  allEbsRetention: {
    customPolicy: {
      id: 'All EBS Retention',
      policy: {
        keepHourly: {
          hours: 4,
        },
        status: PolicyStatus.POLICY_STATUS_DISABLED,
      },
    },
    coveredAssetCount: 67,
  },
}
