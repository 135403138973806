import { styled } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'

export const SelectTrigger = styled('button')`
  width: 100%;
  padding: 4px 14px;
  background-color: #fff;
  border: 1px solid #d3d8de;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
  height: 37px;
`

export const StyledMenuItem = styled(MenuItem)`
  color: #130638;
  padding: 8px 16px;
  border-bottom: 1px solid #d3d8de;
  font-size: 14px;

  &:hover {
    background-color: #d3d8de;
  }

  &.Mui-selected {
    background-color: var(--blue500);
    color: #fff;

    svg path {
      fill: #fff;
    }

    &:hover {
      background-color: var(--blue500);
      color: #fff;
      svg path {
        fill: #fff;
      }
    }
  }
`

export const StyledPopover = styled(Popover)`
  & .MuiPaper-root {
    color: #fff;
    border-radius: 8px;
    margin-left: 8px;
    width: 250px;
  }
`
