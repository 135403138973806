import { useAccessToken } from '@features/authentication/auth-context'

type ClientConstructor<T> = new (token: string) => T

export function useGrpcClient<T>(ClientClass: ClientConstructor<T>): {
  client: T | undefined
  isClientReady: boolean
} {
  const accessToken = useAccessToken()
  let clientInstance: T | undefined = undefined

  if (accessToken && !clientInstance) {
    clientInstance = new ClientClass(accessToken)
  }

  return {
    client: clientInstance,
    isClientReady: !!clientInstance,
  }
}
