import { useQuery } from '@tanstack/react-query'
import { ListScansForAssetsFilters } from 'ui-v2/src/lib/models/scans'
import { ScansClient } from 'ui-v2/src/lib/clients'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'

interface Options {
  enabled?: boolean
}

export function useAssetsListScansQuery(
  filters: ListScansForAssetsFilters,
  options?: Options
) {
  const scansClient = useGrpcClient(ScansClient)

  return useQuery({
    queryKey: ['scans', 'list', filters],
    queryFn: async () => {
      if (!scansClient) {
        throw new Error('Scans client not initialized')
      }
      return scansClient.listScansForAsset(filters)
    },
    enabled:
      !!scansClient &&
      (options?.enabled === undefined ? true : options.enabled),
  })
}
