/* eslint-disable import/no-extraneous-dependencies */
import { Threat } from 'blues-corejs/dist'
import { OvaBackupSnapshot } from 'blues-corejs/dist/models/backups/ova/backup/ova-backup-snapshot'
import { ThreatKind } from 'blues-corejs/dist/models/threats/types'
import { ReportBlock, ReportStatus } from './types'

interface ReportSliderItemGeneratorAttrs {
  threats: Array<Threat>
  snapshot: OvaBackupSnapshot
}

export class ReportSliderItemGenerator {
  #threats: Array<Threat>

  #snapshot: OvaBackupSnapshot

  constructor({ threats, snapshot: snapshot }: ReportSliderItemGeneratorAttrs) {
    this.#threats = threats
    this.#snapshot = snapshot
  }

  #getThreatsForAsset(
    volumeId: string,
    kinds: Array<ThreatKind>
  ): Array<Threat> {
    return this.#threats.filter(
      (threat) =>
        (threat.source.asset?.assetId === volumeId ||
          threat.source.assetItem?.assetItemId === volumeId) &&
        kinds.includes(threat.kind)
    )
  }

  #determineReportStatus(threats: Array<Threat>): ReportStatus {
    return threats.length > 0 ? ReportStatus.INFECTED : ReportStatus.CLEAN
  }

  get reportBlocks(): Array<ReportBlock> {
    const iScanThreats = this.#getThreatsForAsset(this.#snapshot.ovaVolumeId, [
      ThreatKind.MALWARE,
      ThreatKind.RANSOMWARE,
    ])

    return [
      {
        reportType: 'Integrity scan',
        reportStatus: this.#determineReportStatus(iScanThreats),
      },
    ]
  }
}
