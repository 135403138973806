/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { OvaAccount, OvaBackupProvider } from 'blues-corejs/dist'
import { MultiSelectSearch } from '@components/components-complex/dashboard-pages-v3/ova-server-table/multi-select-search'
import type { OptionType } from '@components/components-complex/dashboard-pages-v3/ova-server-table/multi-select-search/'
import {
  useToolbarFiltersActions,
  useToolbarFiltersState,
} from '../toolbar-filters-store'

interface Props {
  ovaAccountsList: Array<OvaAccount>
}

function buildOvaAccountsOptions(
  ovaAccountsList: Array<OvaAccount>
): Array<OptionType> {
  return ovaAccountsList.map((account) => ({
    label: account.name,
    value: account.id,
  }))
}

function buildPossibleOvaAccountsList(
  ovaAccountsList: Array<OvaAccount>,
  backupProvidersList: Array<OvaBackupProvider>
): Array<OptionType> {
  if (!backupProvidersList.length) {
    return buildOvaAccountsOptions(ovaAccountsList)
  }

  return buildOvaAccountsOptions(
    ovaAccountsList.filter((account) =>
      backupProvidersList.some((provider) =>
        account.backupProvidersList.includes(provider)
      )
    )
  )
}

export function OvaAccountsSelect({ ovaAccountsList }: Props) {
  const { ovaAccountsList: ovaAccountsListState, backupProvidersList } =
    useToolbarFiltersState()

  const { setAccountsList } = useToolbarFiltersActions()

  const ovaAccountsOptions = buildPossibleOvaAccountsList(
    ovaAccountsList,
    backupProvidersList
  )

  const selectedOvaAccountsOptions =
    buildOvaAccountsOptions(ovaAccountsListState)

  const handleAccountsChange = (accountsList: Array<OptionType>) => {
    const selectedAccounts = ovaAccountsList.filter((account) =>
      accountsList.some(
        (selectedAccount) => selectedAccount.value === account.id
      )
    )

    setAccountsList(selectedAccounts)
  }

  return (
    <MultiSelectSearch
      tagsTitle="Account"
      placeholder="Account"
      options={ovaAccountsOptions}
      selectedOptions={selectedOvaAccountsOptions}
      onDone={handleAccountsChange}
    />
  )
}
