/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import RegionIcon from '@mui/icons-material/Place'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { EBS, EC2, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist'
import { AWSAsset } from 'blues-corejs/dist/models/assets/aws/aws-asset'
import LangHelper from '@lib/helpers/lang.helper'
import { RowTitle } from '../row-title'

type Asset = EC2 | EBS | EFS | S3Bucket | GenericHost

export function Region({ asset }: { asset: Asset }) {
  if (!(asset instanceof AWSAsset)) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" marginRight="60px">
      <RowTitle title="Region" />
      <RegionIcon color="disabled" fontSize="inherit" />
      <Typography
        fontSize="14px"
        fontWeight="400"
        variant="caption"
        marginLeft="5px"
      >
        {LangHelper.getAwsRegionSingleTranslation(asset.awsRegion)}
      </Typography>
    </Box>
  )
}
