import React from 'react'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts/main-top-bar'
import VaultDetailedPage from '@features/vault-detailed/vault-detailed'
import { VaultDetailedRoutes } from './vault-detailed'

export const vaultDetailedRoutes: Array<PathRouteProps> = [
  {
    path: VaultDetailedRoutes.withIdPlaceholder,
    element: (
      <MainTopBarLayout title="Sources">
        <VaultDetailedPage />
      </MainTopBarLayout>
    ),
  },
]
