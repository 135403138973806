/* eslint-disable import/no-extraneous-dependencies */
import { sentryReThrowCatchHandler } from '@store/epics/epic-func'
import {
  MalwareScan,
  RansomwareScan,
  FilesystemScanCheck,
} from 'blues-corejs/dist'
import { FilesystemScanBackup } from 'blues-corejs/dist/models'
import { MalwareScanBackup } from 'blues-corejs/dist/models/scans/malware'
import { RansomwareScanBackup } from 'blues-corejs/dist/models/scans/ransomware'
import { Scan } from 'blues-corejs/dist/models/scans/scan'

type ScanTarget = {
  kind: ScanTargetKind
  targetId: string
}

enum ScanTargetKind {
  Asset = 'asset',
  Backup = 'backup',
}

function computeMalwareScanTarget(scan: MalwareScan): ScanTarget {
  if (scan.scanTarget.target instanceof MalwareScanBackup) {
    return {
      kind: ScanTargetKind.Backup,
      targetId: scan.scanTarget.target.backupId,
    }
  } else if ('assetId' in scan.scanTarget.target) {
    return {
      kind: ScanTargetKind.Asset,
      targetId: scan.scanTarget.target.assetId,
    }
  } else {
    sentryReThrowCatchHandler(
      `[MostRecentScan] MalwareScan target not supported: ${scan.id}`
    )
    throw new Error(
      `[MostRecentScan] MalwareScan target not supported: ${scan.id}`
    )
  }
}

function computeRasterwareScanTarget(scan: RansomwareScan): ScanTarget {
  if (scan.scanTarget.target instanceof RansomwareScanBackup) {
    return {
      kind: ScanTargetKind.Backup,
      targetId: scan.scanTarget.target.backupId,
    }
  } else if ('assetId' in scan.scanTarget.target) {
    return {
      kind: ScanTargetKind.Asset,
      targetId: scan.scanTarget.target.assetId,
    }
  } else {
    sentryReThrowCatchHandler(
      `[MostRecentScan] RansomwareScan target not supported: ${scan}`
    )

    throw new Error(
      `[MostRecentScan] RansomwareScan target not supported: ${JSON.stringify(
        scan
      )}`
    )
  }
}

function computeFsCheckScanTarget(scan: FilesystemScanCheck): ScanTarget {
  if (scan.scanTarget.target instanceof FilesystemScanBackup) {
    return {
      kind: ScanTargetKind.Backup,
      targetId: scan.scanTarget.target.backupId,
    }
  } else if ('assetId' in scan.scanTarget.target) {
    return {
      kind: ScanTargetKind.Asset,
      targetId: scan.scanTarget.target.assetId,
    }
  } else {
    sentryReThrowCatchHandler(
      `[MostRecentScan] FilesystemScanCheck target not supported: ${scan}`
    )

    throw new Error(
      `[MostRecentScan] FilesystemScanCheck target not supported: ${JSON.stringify(
        scan
      )}`
    )
  }
}

function computeScanTarget(scan: Scan): ScanTarget {
  if (scan instanceof MalwareScan) {
    return computeMalwareScanTarget(scan)
  } else if (scan instanceof RansomwareScan) {
    return computeRasterwareScanTarget(scan)
  } else if (scan instanceof FilesystemScanCheck) {
    return computeFsCheckScanTarget(scan)
  } else {
    /// Not reachable code
    sentryReThrowCatchHandler(
      `[MostRecentScan] Scan type not supported: ${scan}`
    )
    throw new Error(
      `[MostRecentScan] Scan type not supported: ${JSON.stringify(scan)}`
    )
  }
}

export { computeScanTarget }
