import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'

interface IconBoxWithSizeOwnProps {
  width?: string
  height?: string
}

const IconBoxWithSize = styled(Box)<IconBoxWithSizeOwnProps>`
  height: 25px;

  svg {
    width: ${({ width }) => width || '32px'};
    height: ${({ height }) => height || '25px'};
  }
`

interface HorizontalIconWithTextOwnProps extends IconBoxWithSizeOwnProps {
  icon: SvgIconComponent | any
  title: string
}

function HorizontalIconWithText({
  icon: Icon,
  title,
  width,
  height,
}: HorizontalIconWithTextOwnProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1} mb={2}>
      <IconBoxWithSize width={width} height={height}>
        <Icon />
      </IconBoxWithSize>
      <Typography
        variant="h5"
        fontWeight={700}
        sx={{
          color: '#000000',
          fontSize: '22px',
        }}
      >
        {title}
      </Typography>
    </Stack>
  )
}

export default HorizontalIconWithText
