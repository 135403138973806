import { ObjHelper } from '@lib/helpers'
import { useEffect } from 'react'
import { useDeploymentJobsFiltersActions } from '@features/jobs/tenant-jobs/deployment-jobs/stores/filters-store/selectors'
import { useDeploymentJobsStoreActions } from '@features/jobs/tenant-jobs/deployment-jobs/stores/deployment-jobs-store/selectors'
import { useDeploymentJobsFiltersStore } from '@features/jobs/tenant-jobs/deployment-jobs/stores/filters-store/deployment-jobs-filters-store'

export const useFiltersSubscription = () => {
  const { fetchInitialDeploymentJobs } = useDeploymentJobsStoreActions()
  const { fetchPossibleTenantJobFilters } = useDeploymentJobsFiltersActions()

  useEffect(() => {
    const unsubscribeFn = useDeploymentJobsFiltersStore.subscribe(
      (state) => ({
        selectedFilters: state.selectedFilters,
        timeRange: state.timeRange,
      }),
      ({ selectedFilters, timeRange }) => {
        fetchInitialDeploymentJobs({
          selectedFilters,
          timeRange,
        })
      },
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])

  // Subscribe to timeRange changes to fetch possible filters
  useEffect(() => {
    const unsubscribeFn = useDeploymentJobsFiltersStore.subscribe(
      (state) => ({
        timeRange: state.timeRange,
      }),
      fetchPossibleTenantJobFilters,
      {
        equalityFn: ObjHelper.isEqual,
      }
    )
    return unsubscribeFn
  }, [])
}
