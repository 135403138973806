/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { AWSBRecoveryPoint, Backup } from 'blues-corejs/dist'
import Typography from '@mui/material/Typography'
import LangHelper from '@lib/helpers/lang.helper'

interface Props {
  backup: Backup
}

export default function BackupTargetRegion({ backup }: Props) {
  if (!(backup instanceof AWSBRecoveryPoint)) {
    return null
  }

  const targetRegion = LangHelper.getAwsRegionSingleTranslation(backup.region)

  return <Typography fontSize="14px">{targetRegion}</Typography>
}
