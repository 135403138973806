import {
  EngineCallback,
  EngineCallbackPure,
  ValueObject,
} from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormRoleNameInterface from '@lib/interfaces/form/form-role-name.interface'
import React, { useMemo } from 'react'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import buildDefaultFormData from './data'
import { Button } from '@mui/material'

interface Props {
  defaultValues?: ValueObject<FormRoleNameInterface>
  onCancel: EngineCallbackPure
  onRemove: EngineCallbackPure
  onSubmit: EngineCallback<FormRoleNameInterface>
  shouldShowRemoveButton?: boolean
  shouldDisableSaveButton?: boolean
  loading?: boolean
  errorGroups?: Array<ErrorGroupConstants>
}

function RoleNameForm({
  defaultValues,
  onCancel,
  onRemove,
  onSubmit,
  shouldShowRemoveButton = false,
  shouldDisableSaveButton = false,
  loading = false,
  errorGroups = [],
}: Props) {
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )

  const { isFormDisabled, register, submit, formState, globalFormError } =
    useGeneralForm(currentForm, onSubmit, errorGroups, loading)

  FormHelper.fillErrors(formState.errors, currentForm)

  return (
    <form
      className={clsx('wrap-1630488705182', { formDisabled: isFormDisabled })}
      onSubmit={submit}
    >
      <div className="rnInputWrap">
        <input
          className="jsRoleNameInput"
          id="id_roleName"
          type="text"
          disabled={isFormDisabled}
          defaultValue={String(currentForm.roleName.value)}
          {...register(
            currentForm.roleName.name,
            currentForm.roleName.validationRules
          )}
        />
        {currentForm.roleName.hasError && currentForm.roleName.errorText && (
          <div className="formLocalError">{currentForm.roleName.errorText}</div>
        )}
        {globalFormError && (
          <div className="formGlobalError">{globalFormError}</div>
        )}
      </div>

      <div className="rnButtonsWrap">
        <Button
          disabled={loading || shouldDisableSaveButton}
          type="submit"
          variant="contained"
          className="saveButton jsSave"
        >
          Save
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={onCancel}
          className="jsCancel"
        >
          Cancel
        </Button>
        {shouldShowRemoveButton && (
          <Button
            disabled={loading}
            variant="contained"
            onClick={onRemove}
            className="jsRemove errorButton"
          >
            Remove
          </Button>
        )}
      </div>
    </form>
  )
}

export default RoleNameForm
