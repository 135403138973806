import React from 'react'

function SearchLongIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.12492 0.583984C3.07372 0.583984 0.583252 3.07445 0.583252 6.12565C0.583252 9.17685 3.07372 11.6673 6.12492 11.6673C7.50875 11.6673 8.77305 11.1512 9.74618 10.3066L10.0833 10.6437V11.6673L14.8333 16.4173L16.4166 14.834L11.6666 10.084H10.643L10.3059 9.74691C11.1504 8.77378 11.6666 7.50948 11.6666 6.12565C11.6666 3.07445 9.17612 0.583984 6.12492 0.583984ZM6.12492 2.16732C8.32042 2.16732 10.0833 3.93015 10.0833 6.12565C10.0833 8.32116 8.32042 10.084 6.12492 10.084C3.92941 10.084 2.16659 8.32116 2.16659 6.12565C2.16659 3.93015 3.92941 2.16732 6.12492 2.16732Z"
        fill="#71768E"
      />
    </svg>
  )
}

export default SearchLongIcon
