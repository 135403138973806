/* eslint-disable import/no-extraneous-dependencies */
import React, { Fragment } from 'react'
import { useAssetMap } from '@features/jobs/cloud-connector-jobs/stores'
import { computeAssetName } from '@features/jobs/cloud-connector-jobs/helpers'
import { BaseSelectedChip } from '@features/jobs/cloud-connector-jobs/shared'
import {
  useModalFilterActions,
  useSelectedModalFilters,
} from '../cloud-connector-filters-store'

export function SelectedAssetIdsFilters() {
  const { assetIdsList: selectedAssetsIdsList } = useSelectedModalFilters()

  const { setFilter } = useModalFilterActions()

  const assetsMap = useAssetMap()

  if (selectedAssetsIdsList === undefined) {
    return null
  }

  function handleRemoveFilter(assetId: string) {
    const newAssetIdsList = new Set(selectedAssetsIdsList)

    newAssetIdsList.delete(assetId)

    setFilter('assetIdsList', newAssetIdsList)
  }

  return (
    <Fragment>
      {Array.from(selectedAssetsIdsList).map((assetId) => {
        const assetName = computeAssetName(assetsMap.get(assetId))

        return (
          <BaseSelectedChip
            key={assetId}
            filterName="Asset ID"
            filterValue={assetName}
            onDelete={() => handleRemoveFilter(assetId)}
          />
        )
      })}
    </Fragment>
  )
}
