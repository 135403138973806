/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Stack from '@mui/material/Stack'
import { VirtualTable } from '@components/table'
import { useGetIsDataLoading } from '../entropy-trends-details-store/entropy-trends-details-store.context'
import { useAssetsMap } from '@lib/hooks/api-hooks/assets'
import { getColumns } from './column-definitions'
import {
  getAssetIdFromAnomalies,
  mapAnomaliesListToTableDefinitions,
} from './utils'
import { Anomaly } from '@lib/models/anomalies'
import styles from './entropy-trends-details-table.module.css'
import { useSelector } from 'react-redux'
import { getLiveAssets } from '@store/selectors/list-live-assets.selector'
import { Asset, S3Bucket } from 'blues-corejs/dist'

interface Props {
  anomaliesList: Array<Anomaly>
  asset: Asset
}

export function EntropyTrendsDetailsTable({ anomaliesList, asset }: Props) {
  const mappedAnomaliesList = mapAnomaliesListToTableDefinitions(anomaliesList)
  const liveAssets = useSelector(getLiveAssets)
  const { ec2Instances, allS3Buckets } = liveAssets

  const { assetsList, isAssetsLoading } = useAssetsMap(
    getAssetIdFromAnomalies(anomaliesList)
  )

  const assetsListForTable =
    asset instanceof S3Bucket
      ? [...assetsList, ...allS3Buckets]
      : [...assetsList, ...ec2Instances]

  const isTableDataLoading = useGetIsDataLoading() || isAssetsLoading

  return (
    <Stack padding="4px" className={styles.root}>
      <VirtualTable
        config={{
          state: {
            isLoading: isTableDataLoading,
          },
          columns: getColumns(assetsListForTable, asset),
          data: mappedAnomaliesList,
        }}
        emptyPlaceholderText="No Data available"
      />
    </Stack>
  )
}
