import React, { memo } from 'react'
import { Nullable } from '@lib/engine-types'

function toPixels(value: Nullable<number | string>): string {
  if (typeof value === 'undefined' || value === null) {
    return 'auto'
  }
  return `${+value}px`
}

interface Props {
  show: boolean
  top?: number | string
  bottom?: number | string
  left?: number | string
  right?: number | string
}

function PreloaderSmall({ show, top, bottom, left, right }: Props) {
  if (!show) {
    return null
  }

  const calcStyles = {
    top: toPixels(top),
    bottom: toPixels(bottom),
    left: toPixels(left),
    right: toPixels(right),
  }

  return (
    <div
      style={calcStyles}
      data-testid="preloaderSmall"
      className="wrap-1602572136598"
    >
      <img
        src="/images/preloaders/preloader-small.svg"
        alt="preloader"
        width={24}
        height={24}
      />
    </div>
  )
}

export default memo(PreloaderSmall)
