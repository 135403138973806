import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

export enum IconType {
  calendar = 'calendar',
  arrow = 'arrow',
}

interface TextWithIconLinkOwnProps {
  text: string
  iconType: IconType
  onClick?: () => void
}

function TextWithIconLink({
  text,
  iconType = IconType.calendar,
  onClick,
}: TextWithIconLinkOwnProps) {
  const Icon =
    iconType === IconType.calendar ? CalendarTodayIcon : PlayArrowIcon

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        color: '#32B6F3',
        fontSize: '14px',
        fontWeight: 600,
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      <Typography
        variant="body1"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 600,
          fontSize: '16px',
          gap: '4px',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Typography>
      <Icon
        sx={{
          fontSize: '16px',
          marginLeft: '4px',
        }}
      />
    </Box>
  )
}

export default TextWithIconLink
