/* eslint-disable import/no-extraneous-dependencies */
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'

export function getHumanReadableTenantJobStatus(
  status: TenantJobStatus
): string {
  const mapping: Record<TenantJobStatus, string> = {
    [TenantJobStatus.ABORTED]: 'Aborted',
    [TenantJobStatus.FAILED]: 'Failed',
    [TenantJobStatus.INPROGRESS]: 'In progress',
    [TenantJobStatus.SCHEDULED]: 'Scheduled',
    [TenantJobStatus.STARTING]: 'Starting',
    [TenantJobStatus.SUCCEEDED]: 'Succeeded',
  }

  return mapping[status]
}
