/* eslint-disable import/no-extraneous-dependencies */
import { SystemHelper } from '@lib/helpers'
import { JobCloudConnector } from 'blues-corejs/dist'
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'

export function getHumanReadableCloudConnectorJobKind(
  job: JobCloudConnector
): string {
  const mapping: Record<JobCloudConnectorKind, string> = {
    [JobCloudConnectorKind.UNKNOWN]: 'Unknown',
    [JobCloudConnectorKind.EBS_BACKUP]: 'EBS Backup',
    [JobCloudConnectorKind.EBS_RESTORE]: 'EBS Restore',
    [JobCloudConnectorKind.EC2_BACKUP]: 'EC2 Backup',
    [JobCloudConnectorKind.EC2_RESTORE]: 'EC2 Restore',
    [JobCloudConnectorKind.EFS_BACKUP]: 'EFS Backup',
    [JobCloudConnectorKind.ROLLUP]: 'Rollup',
    [JobCloudConnectorKind.BLOCK_BACKUP]: 'Block Backup',
    [JobCloudConnectorKind.BLOCK_RESTORE]: 'Block Restore',
    [JobCloudConnectorKind.FILE_BACKUP]: 'File Backup',
    [JobCloudConnectorKind.FILE_RESTORE]: 'File Restore',
    [JobCloudConnectorKind.STREAM_BACKUP]: 'Stream Backup',
    [JobCloudConnectorKind.STREAM_RESTORE]: 'Stream Restore',
    [JobCloudConnectorKind.ISCAN]: 'iScan',
    [JobCloudConnectorKind.IMPORT_SNAPSHOTS]: 'Import Snapshots',
    [JobCloudConnectorKind.BACKUP_INTEGRITY_CHECK]: 'Backup Integrity Check',
    [JobCloudConnectorKind.APPLY_RETENTION_POLICY]: 'Apply Retention Policy',
    [JobCloudConnectorKind.FS_CHECK]: 'FS Check',
    [JobCloudConnectorKind.POSTGRES_BACKUP]: 'Postgres Backup',
    [JobCloudConnectorKind.POSTGRES_RESTORE]: 'Postgres Restore',
    [JobCloudConnectorKind.AWS_BACKUP_IMPORT]: 'AWS Backup Import',
    [JobCloudConnectorKind.VAULT_CATALOG_RESTORE]: 'Vault Catalog Restore',
    [JobCloudConnectorKind.DATABASE_MAINTENANCE]: 'Database Maintenance',
    [JobCloudConnectorKind.POSTGRES_RECOVERY_TEST]: 'Postgres Recovery Test',
    [JobCloudConnectorKind.S3_BACKUP]: 'S3 Backup',
    [JobCloudConnectorKind.S3_RESTORE]: 'S3 Restore',
    [JobCloudConnectorKind.AWS_BACKUP_RP_ISCAN]: 'AWS Backup RP IScan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EC2_SCAN]: 'AWS Backup RP EC2 Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EBS_SCAN]: 'AWS Backup RP EBS Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EFS_SCAN]: 'AWS Backup RP EFS Scan',
    [JobCloudConnectorKind.AWS_EFS_SCAN]: 'AWS EFS Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EC2_IMPORT]:
      'AWS Backup RP EC2 Import',
    [JobCloudConnectorKind.AWS_BACKUP_RP_EBS_IMPORT]:
      'AWS Backup RP EBS Import',
    [JobCloudConnectorKind.VAULT_REPLICATION]: 'Vault Replication',
    [JobCloudConnectorKind.AWS_EC2_AMI_SCAN]: 'AWS EC2 AMI Scan',
    [JobCloudConnectorKind.AWS_FSX_ONTAP_SCAN]: 'AWS FSX ONTAP Scan',
    [JobCloudConnectorKind.AWS_EBS_SCAN]: 'AWS EBS Scan',
    [JobCloudConnectorKind.AWS_EC2_SCAN]: 'AWS EC2 Scan',
    [JobCloudConnectorKind.AWS_BACKUP_RP_FSX_ONTAP_SCAN]:
      'AWS Backup RP FSX ONTAP Scan',
    [JobCloudConnectorKind.AWS_EC2_AMI_IMPORT]: 'AWS EC2 AMI Import',
    [JobCloudConnectorKind.AWS_EBS_SNAPSHOT_SCAN]: 'AWS EBS Snapshot Scan',
    [JobCloudConnectorKind.AWS_S3_SCAN]: 'AWS S3 Scan',
    [JobCloudConnectorKind.KIND_AWS_EBS_SNAPSHOTS_SCAN]:
      'AWS EBS Snapshots Scan',
    [JobCloudConnectorKind.KIND_AWS_BACKUP_RP_S3_SCAN]: 'AWS Backup RP S3 Scan',
    [JobCloudConnectorKind.KIND_AWS_BACKUP_RP_VM_SCAN]: 'AWS Backup RP VM Scan',
  }

  const humanReadableJobKind = mapping[job.kind]

  if (!humanReadableJobKind) {
    const errorMessage = `Got unknown cloud connector job kind for job with id ${job.id}, probably UI models are outdated`

    SystemHelper.sendSentryIfProd(errorMessage)
  }

  return humanReadableJobKind || 'Unknown job kind'
}
