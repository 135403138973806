import React from 'react'

function GreenCheckedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.66667C5.40002 1.66667 1.66669 5.4 1.66669 10C1.66669 14.6 5.40002 18.3333 10 18.3333C14.6 18.3333 18.3334 14.6 18.3334 10C18.3334 5.4 14.6 1.66667 10 1.66667ZM7.74169 13.575L4.75002 10.5833C4.42502 10.2583 4.42502 9.73333 4.75002 9.40833C5.07502 9.08333 5.60002 9.08333 5.92502 9.40833L8.33335 11.8083L14.0667 6.075C14.3917 5.75 14.9167 5.75 15.2417 6.075C15.5667 6.4 15.5667 6.925 15.2417 7.25L8.91669 13.575C8.60002 13.9 8.06669 13.9 7.74169 13.575Z"
        fill="#50C56A"
      />
    </svg>
  )
}

export default GreenCheckedIcon
