import { useDispatch, useSelector } from 'react-redux'
import {
  getAssetsSelectAllFilters,
  getAssetsSelectFilters,
  getAssetsSelectSearch,
} from '@store/selectors/assets-select.selector'
import ValueInterface from '@lib/interfaces/value.interface'
import {
  setAssetsSelectFilters,
  setAssetsSelectSearch,
} from '@store/actions/assets-select.action'

export function useSpecificAssetsFilter() {
  const dispatch = useDispatch()
  const possibleFilters = useSelector(getAssetsSelectAllFilters)
  const selectedFilters = useSelector(getAssetsSelectFilters)
  const selectedAssetsFilters = useSelector(getAssetsSelectFilters)
  const searchVal = useSelector(getAssetsSelectSearch)

  const handleFiltersChange = (newFilters: Array<ValueInterface>) => {
    dispatch(setAssetsSelectFilters(newFilters))
  }

  const handleSearchChange = (value: string) => {
    dispatch(setAssetsSelectSearch(value))
  }

  return {
    possibleFilters,
    selectedFilters,
    selectedAssetsFilters,
    searchVal,
    handleFiltersChange,
    handleSearchChange,
  }
}
