import React from 'react'
import { OnDemandForm } from './on-demand'
import { ScheduleForm } from './schedule'
import { ReactNode } from 'react'

export enum FormOptionValue {
  ON_DEMAND = 'OnDemand',
  SCHEDULE = 'Schedule',
}

export interface FormOption {
  value: FormOptionValue
  label: string
  component: ReactNode
}

export const formOptions: Array<FormOption> = [
  {
    value: FormOptionValue.ON_DEMAND,
    label: 'On-Demand',
    component: <OnDemandForm />,
  },
  {
    value: FormOptionValue.SCHEDULE,
    label: 'Schedule',
    component: <ScheduleForm />,
  },
]
