/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'

import { CellProps } from './types'
import Typography from '@mui/material/Typography'
import {
  DrawerName,
  useDrawerActions,
} from '@features/reports/schedules/use-drawer-store'

function SchedulesTableCellWithAction<TData, TValue>({
  cell,
  row,
}: CellProps<TData, TValue> & { row: { original: TData } }) {
  const { openDrawer } = useDrawerActions()

  const handleOpenDrawerWithDataClick = () => {
    openDrawer(DrawerName.ReportDetails, row.original)
  }

  return (
    <Typography
      onClick={handleOpenDrawerWithDataClick}
      variant="caption"
      fontSize="14px"
      sx={{
        color: '#0B97D8',
        cursor: 'pointer',
      }}
    >
      {cell.getValue() as string}
    </Typography>
  )
}

export default SchedulesTableCellWithAction
