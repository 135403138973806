/* eslint-disable import/no-extraneous-dependencies */
import { CellLongTextWithTooltip } from '@components/table'
import React from 'react'
import { Asset } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

const MAX_TEXT_LENGTH = 43

interface Props {
  asset: Asset
}

export function AssetTagsCell({ asset }: Props) {
  const tags = asset.tags.map((tag) => `${tag.key}:${tag.value}`)
  return (
    <>
      {tags.map((tag) => {
        return (
          <CellLongTextWithTooltip
            key={tag}
            title={tag}
            maxLength={MAX_TEXT_LENGTH}
          />
        )
      })}
    </>
  )
}
