/* eslint-disable import/no-extraneous-dependencies */
import { JobTenant } from 'blues-corejs/dist/models'
import { DeploymentFiltersState } from '@features/jobs/tenant-jobs/deployment-jobs/stores/filters-store'

export interface DeploymentJobsState {
  deploymentJobsList: Array<JobTenant>
  pageToken?: string
  loadingState: 'idle' | 'loading' | 'success'
}

export interface DeploymentJobsActions {
  resetDeploymentJobsState: () => void
  setDeploymentJobsList: (deploymentJobsList: Array<JobTenant>) => void
  fetchInitialDeploymentJobs: (
    filters?: Partial<DeploymentFiltersState>
  ) => void
  fetchPaginatedDeploymentJobs: () => Promise<void>
}

export type DeploymentJobsStore = DeploymentJobsState & {
  actions: DeploymentJobsActions
}

export { fetchInitialJobsList, fetchJobsListViaPagination } from './actions'
export {
  useDeploymentJobsIsLoading,
  useDeploymentJobsList,
  useDeploymentJobsStoreActions,
} from './selectors'
