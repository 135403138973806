import { ListGeneratedReportsRequest } from 'ui-v2/src/lib/grpc'
import {
  AWSReports,
  BackupConnectorReports,
  ListGeneratedReportsRequestParams,
} from 'ui-v2/src/lib/models/reports'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export type OptionalReportKind = AWSReports | BackupConnectorReports

export class ListGeneratedReportsFilter extends ListGeneratedReportsRequest {
  #requestParams?: ListGeneratedReportsRequestParams

  constructor(params?: ListGeneratedReportsRequestParams) {
    super()
    this.#requestParams = params
  }

  toGrpc(): ListGeneratedReportsRequest {
    const request = new ListGeneratedReportsRequest()

    const initial = new ListGeneratedReportsRequest.Initial()
    const params = this.#requestParams
    if (!params) {
      return request
    }

    const {
      reportKind,
      textSearch,
      rangeStartAt,
      rangeEndAt,
      pageSize,
      pageToken,
    } = params

    if (rangeStartAt && rangeEndAt) {
      initial.setRangeStartAt(this.#setTimestamp(rangeStartAt))
      initial.setRangeEndAt(this.#setTimestamp(rangeEndAt))
    }
    if (textSearch) {
      initial.setTextSearch(textSearch)
    }

    if (reportKind) {
      const optionalKind = new ListGeneratedReportsRequest.OptionalReportKind()
      const transformedReportKind = this.#transformReportKind(reportKind)
      optionalKind.setKind(transformedReportKind)

      initial.setReportKind(optionalKind)
    }

    initial.setLimit(pageSize ?? 10)

    request.setInitial(initial)

    if (pageToken) {
      request.setPageToken(pageToken)
    }

    return request
  }

  #setTimestamp(date: number): Timestamp {
    const timestamp = new Timestamp()
    timestamp.setSeconds(date)
    return timestamp
  }

  #transformReportKind(kind: OptionalReportKind) {
    const BackendReportKind = ListGeneratedReportsRequest.ReportKind
    switch (kind) {
      case BackupConnectorReports.ovaMspOperationalReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_OPERATIONAL
      case BackupConnectorReports.ovaMspExecutiveReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_EXECUTIVE
      case BackupConnectorReports.ovaMspTenantSummaryReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_TENANT_SUMMARY
      case BackupConnectorReports.ovaMspAccountSummaryReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_ACCOUNT_SUMMARY
      case BackupConnectorReports.ovaMspCompletedScansReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_COMPLETED_SCANS
      case BackupConnectorReports.ovaMspScannedAssetsSummaryReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_SCANNED_ASSETS_SUMMARY
      case BackupConnectorReports.ovaMspInfectedAssetsReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_INFECTED_ASSETS
      case BackupConnectorReports.ovaMspInfectedFilesReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_INFECTED_FILES
      case BackupConnectorReports.ovaMspFailedScansReport:
        return BackendReportKind.REPORT_KIND_OVA_MSP_FAILED_SCANS
      case AWSReports.awsExecutiveReport:
        return BackendReportKind.REPORT_KIND_AWS_EXECUTIVE
      case AWSReports.awsTenantSummaryReport:
        return BackendReportKind.REPORT_KIND_AWS_TENANT_SUMMARY
      case AWSReports.awsAccountSummaryReport:
        return BackendReportKind.REPORT_KIND_AWS_ACCOUNT_SUMMARY
      case AWSReports.awsCompletedScansReport:
        return BackendReportKind.REPORT_KIND_AWS_COMPLETED_SCANS
      case AWSReports.awsScannedAssetsSummaryReport:
        return BackendReportKind.REPORT_KIND_AWS_SCANNED_ASSETS_SUMMARY
      case AWSReports.awsInfectedAssetsReport:
        return BackendReportKind.REPORT_KIND_AWS_INFECTED_ASSETS
      case AWSReports.infectedFilesReport:
        return BackendReportKind.REPORT_KIND_INFECTED_FILES
      case AWSReports.failedScansReport:
        return BackendReportKind.REPORT_KIND_FAILED_SCANS
    }
  }
}
