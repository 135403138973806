/* eslint-disable import/no-extraneous-dependencies */
import RadioGroup from 'ui-v2/src/components/ui/inputs/radio-group'
import {
  SelectedVariant,
  SnapshotImport,
} from 'ui-v2/src/lib/constants/policies.constant'
import {
  AssetVariants,
  AssetVariantType,
  PolicyEc2EbsTableRow,
  PolicyFormData,
} from 'ui-v2/src/lib/models/settings/policies/policies'
import { Stack, Typography } from '@mui/material'

import Grid from '@mui/material/Grid2'

import { Controller, useFormContext } from 'react-hook-form'
import AssetsEc2 from './ec2'
import AssetsEbs from './ebs'
import Tags from './tags'
import { LiveAssets } from 'ui-v2/src/lib/models/assets'
import { AssetsForPolicyCoverage } from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'

const AssetEc2Ebs = ({
  liveAssetsData,
  ec2Assets,
  ebsAssets,
  isLoading,
  handlEc2EbsAllSelect,
  handleAssetsSelect,
}: AssetEc2EbsProps) => {
  const { control, setValue, watch, getValues } =
    useFormContext<PolicyFormData>()
  const selectedVariant = watch('selectedVariant')
  const assetVariant = watch('assetVariant')
  const shouldRenderSpecificAssets =
    selectedVariant === SelectedVariant.AssetsVariant

  const shouldRenderSpecificTags =
    selectedVariant === SelectedVariant.TagsVariant

  const shouldRenderCreateRecoveryPoint =
    watch('snapshotImportVariant') !== SnapshotImport.SKIP

  return (
    <Grid container spacing={2}>
      <Grid size={12}>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Typography variant="body1" mr={1}>
            Select By:
          </Typography>
          <Controller
            name="selectedVariant"
            control={control}
            rules={{
              validate: (value) => {
                const tabAssetTypeSelectedNum = getValues(
                  'tabAssetTypeSelectedNum'
                )
                const policyTags = getValues('policyTags')
                if (
                  tabAssetTypeSelectedNum === AssetVariants.EBS &&
                  value === SelectedVariant.TagsVariant &&
                  policyTags.length === 0
                ) {
                  return 'Please add at least one Tag'
                }
              },
            }}
            render={({ field, fieldState }) => (
              <RadioGroup
                {...field}
                row
                value={field.value}
                options={[
                  // {
                  //   label: 'All EC2 Instances',
                  //   value: SelectedVariant.AllEC2Variant,
                  // },
                  // {
                  //   label: 'All EBS Volumes',
                  //   value: SelectedVariant.AllEBSVariant,
                  // },
                  {
                    label: 'All Assets',
                    value: SelectedVariant.AllEC2EBSVariant,
                  },
                  {
                    label: 'Specific Assets',
                    value: SelectedVariant.AssetsVariant,
                  },
                  {
                    label: 'Asset Tags',
                    value: SelectedVariant.TagsVariant,
                  },
                ]}
                onChange={(e) => {
                  field.onChange(+e.target.value)
                  setValue('assetVariant', AssetVariantType.EC2)
                  setValue('selectedEc2EbsAssetsRows', [])

                  if (+e.target.value === SelectedVariant.AllEC2EBSVariant) {
                    handlEc2EbsAllSelect()
                  } else {
                    setValue('selectedAssets', [])
                  }
                }}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </Grid>
      <Grid size={12}>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Typography variant="body1" mr={1}>
            Scan pre-existing EBS snapshot:
          </Typography>
          <Controller
            name="snapshotImportVariant"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                value={field.value}
                options={[
                  {
                    label: 'Skip',
                    value: SnapshotImport.SKIP,
                  },
                  {
                    label: 'Latest snapshot',
                    value: SnapshotImport.LATEST,
                  },
                  {
                    label: 'All snapshots',
                    value: SnapshotImport.ALL,
                  },
                ]}
                onChange={(e) => {
                  const snapshotImportVariantValue = +e.target.value
                  field.onChange(snapshotImportVariantValue)
                  if (snapshotImportVariantValue === SnapshotImport.SKIP) {
                    setValue('skipEbsBackup', false)
                  } else {
                    setValue('skipEbsBackup', true)
                  }
                }}
              />
            )}
          />
        </Stack>
      </Grid>
      {shouldRenderCreateRecoveryPoint && (
        <Grid size={12}>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" mr={1}>
              Create recovery point:
            </Typography>
            <Controller
              name="skipEbsBackup"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  value={field.value ? '1' : '0'}
                  options={[
                    {
                      label: 'Create',
                      value: '1',
                    },
                    {
                      label: 'Skip',
                      value: '0',
                    },
                  ]}
                  onChange={(e) => {
                    field.onChange(e.target.value === '1' ? true : false)
                  }}
                />
              )}
            />
          </Stack>
        </Grid>
      )}
      <Grid size={12}>
        {shouldRenderSpecificAssets && (
          <>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Typography variant="body1" mr={1}>
                Asset:
              </Typography>
              <Controller
                name="assetVariant"
                control={control}
                rules={{
                  validate: (value) => {
                    const tabAssetTypeSelectedNum = getValues(
                      'tabAssetTypeSelectedNum'
                    )
                    const variant = getValues('selectedVariant')
                    const selectedEc2EbsAssetsRows = getValues(
                      'selectedEc2EbsAssetsRows'
                    )

                    if (
                      tabAssetTypeSelectedNum === AssetVariants.EBS &&
                      variant === SelectedVariant.AssetsVariant
                    ) {
                      if (
                        value === AssetVariantType.EC2 &&
                        ec2Assets?.length &&
                        selectedEc2EbsAssetsRows.length === 0
                      ) {
                        return 'Please select at least one EC2'
                      }

                      if (
                        value === AssetVariantType.EBS &&
                        ebsAssets?.length &&
                        selectedEc2EbsAssetsRows.length === 0
                      ) {
                        return 'Please select at least one EBS'
                      }
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <RadioGroup
                    row
                    value={assetVariant}
                    options={[
                      {
                        label: AssetVariantType.EC2,
                        value: AssetVariantType.EC2,
                      },
                      {
                        label: AssetVariantType.EBS,
                        value: AssetVariantType.EBS,
                      },
                    ]}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      setValue('selectedEc2EbsAssetsRows', [])
                      setValue('selectedAssets', [])
                    }}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Stack>
            {assetVariant === AssetVariantType.EC2 &&
              !!liveAssetsData &&
              !!ec2Assets && (
                <AssetsEc2
                  liveAssetsData={liveAssetsData}
                  ec2Assets={ec2Assets}
                  isLoading={isLoading}
                  handleAssetsSelect={handleAssetsSelect}
                />
              )}
            {assetVariant === AssetVariantType.EBS &&
              !!liveAssetsData &&
              !!ebsAssets && (
                <AssetsEbs
                  liveAssetsData={liveAssetsData}
                  ebsAssets={ebsAssets}
                  isLoading={isLoading}
                  handleAssetsSelect={handleAssetsSelect}
                />
              )}
          </>
        )}

        {shouldRenderSpecificTags && <Tags />}
      </Grid>
    </Grid>
  )
}
interface AssetEc2EbsProps {
  liveAssetsData: LiveAssets | undefined
  ec2Assets: AssetsForPolicyCoverage | undefined
  ebsAssets: AssetsForPolicyCoverage | undefined
  isLoading: boolean
  handlEc2EbsAllSelect: () => void
  handleAssetsSelect: (rows: Array<PolicyEc2EbsTableRow>) => void
}
export default AssetEc2Ebs
