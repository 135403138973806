/* eslint-disable import/no-extraneous-dependencies */
import { VolumeThreatsOutput } from 'blues-corejs/dist'
/**
 * Sorts to last scan first, last backup second key, prioritize last scan.
 * If both have no value, sort by volume name followed by ID.
 */
export function initialPrioritySortForEBSTable(
  volumes: Array<VolumeThreatsOutput>
) {
  return volumes.sort((a, b) => {
    const aLastScanTime = a.lastScan?.getTime() || 0
    const bLastScanTime = b.lastScan?.getTime() || 0

    if (aLastScanTime !== bLastScanTime) {
      return bLastScanTime - aLastScanTime
    }

    const aLastBackupTime = a.lastBackup?.backup.timestamp.getTime() || 0
    const bLastBackupTime = b.lastBackup?.backup.timestamp.getTime() || 0

    if (aLastBackupTime !== bLastBackupTime) {
      return bLastBackupTime - aLastBackupTime
    }

    const volumeAName = a.volumeName || a.volumeAwsId
    const volumeBName = b.volumeName || b.volumeAwsId

    return volumeAName.localeCompare(volumeBName)
  })
}
