/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { ElastioRecoveryPoint } from 'blues-corejs/dist'

const AreaChart = ({
  cleanBackups,
}: {
  cleanBackups: ElastioRecoveryPoint[]
}) => {
  const AreachartRef = useRef(null)

  useEffect(() => {
    if (!AreachartRef.current || !cleanBackups.length) return

    const Areachart = echarts.init(AreachartRef.current)
    const now = dayjs()

    const ages = cleanBackups.map((backup) =>
      now.diff(dayjs(backup.createdAt), 'hour')
    )
    const maxAgeHours = Math.max(...ages)
    const maxAgeDays = Math.ceil(maxAgeHours / 24)

    const backupsPerDay = new Array(maxAgeDays + 1).fill(0)
    cleanBackups.forEach((backup) => {
      const ageInDays = Math.floor(
        now.diff(dayjs(backup.createdAt), 'hour') / 24
      )
      if (ageInDays <= maxAgeDays) {
        backupsPerDay[ageInDays]++
      }
    })

    const smoothDistribution = []
    const totalBackups = cleanBackups.length
    const peak = Math.max(...backupsPerDay)

    for (let i = 0; i <= maxAgeDays; i++) {
      let weight = 0
      let sum = 0
      for (let j = Math.max(0, i - 2); j <= Math.min(maxAgeDays, i + 2); j++) {
        const distance = Math.abs(i - j)
        const currentWeight = 1 / (distance + 1)
        sum += backupsPerDay[j] * currentWeight
        weight += currentWeight
      }
      const smoothedValue = (sum / weight / totalBackups) * 100
      smoothDistribution.push([i, smoothedValue])
    }

    let totalAssets = cleanBackups.length

    const formatAge = (hours: number) => {
      if (hours < 24) {
        return `${hours} Hour${hours !== 1 ? 's' : ''}`
      }
      const days = Math.floor(hours / 24)
      return `${days} Day${days !== 1 ? 's' : ''}`
    }

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params: any) {
          const dayIndex = params[0].data[0]
          const percentage = params[0].data[1].toFixed(1)
          const count = Math.round((percentage / 100) * totalAssets)
          return `Day ${dayIndex}<br/>
                 Backups: ${count}<br/>
                 Percentage: ${percentage}%`
        },
        backgroundColor: 'rgba(50, 50, 50, 0.9)',
        borderColor: '#68A9A5',
        textStyle: {
          color: '#fff',
        },
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: maxAgeDays,
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: Math.ceil(
          Math.max(...smoothDistribution.map((point) => point[1]))
        ),
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          data: smoothDistribution,
          areaStyle: {
            color: '#68A9A5',
            opacity: 0.6,
          },
          lineStyle: {
            width: 2,
            color: '#68A9A5',
          },
          showSymbol: false,
          emphasis: {
            focus: 'series',
          },
        },
      ],
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: '0%',
          style: {
            text: `${totalAssets} Assets`,
            fill: '#FFFFFF',
            font: 'bold 14px Arial',
            textAlign: 'center',
          },
        },
        {
          type: 'text',
          left: '5%',
          bottom: '10%',
          style: {
            text: '0 Hours\nMin, Age of\nRecovery Point',
            fill: '#FFFFFF',
            font: '12px Arial',
            textAlign: 'left',
          },
        },
        {
          type: 'text',
          right: '5%',
          bottom: '10%',
          style: {
            text: `${formatAge(maxAgeHours)}\nMax, Age of\nRecovery Point`,
            fill: '#E74C3C',
            font: '12px Arial',
            textAlign: 'right',
          },
        },
      ],
    }

    Areachart.setOption(option)

    const handleResize = () => {
      Areachart.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      Areachart.dispose()
    }
  }, [cleanBackups])

  if (!cleanBackups.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '240px',
        }}
      >
        No backup data available
      </Box>
    )
  }

  return (
    <Box
      ref={AreachartRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '240px',
      }}
    />
  )
}

export default React.memo(AreaChart)
