import React, { memo, useMemo, useState } from 'react'
import { EngineCallback, VIRow } from '@lib/engine-types'
import FilterGreyIcon from '@inline-img/general/filter-grey-icon'
import { Button } from '@mui/material'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import SearchForm from '@forms/search/search.form'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import ViMultipleSelect from '@components-composite/vi-inputs/vi-multiple-select/ViMultipleSelect'
import ValueInterface from '@lib/interfaces/value.interface'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueHelper from '@lib/helpers/value.helper'
import clsx from 'clsx'
import DemoWrap from '@components-simple/demo-wrap/DemoWrap'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import AddExistingPolicyModal from '@components-composite/modals/AddExistingPolicyModal'
import { useSelector } from 'react-redux'
import { getPolicies } from '@store/selectors/policies.selector'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PolicyModel from '@lib/models/policy.model'

interface Props {
  possibleFilters: VIRow
  selectedFilters: VIRow
  onFiltersChange: any
  onSearchChange?: EngineCallback<string>
  onFilterToggle?: EngineCallback<boolean>
  searchPlaceholder?: string
  searchVal?: string
  turnOnSearch?: boolean
  showFilterFirst?: boolean
  disabled: boolean
  tableName: string
  className?: string
  backButtonHref?: string
  customSearch?: React.ReactNode
  turnOffButton?: boolean
  turnOnAddPolicyBtn?: boolean
}

function FilterDashboardV2({
  possibleFilters,
  selectedFilters,
  onFiltersChange,
  onSearchChange,
  onFilterToggle,
  turnOnSearch = false,
  searchPlaceholder = 'Search...',
  searchVal = '',
  disabled,
  showFilterFirst = false,
  tableName,
  className = '',
  customSearch = null,
  turnOffButton = false,
  turnOnAddPolicyBtn = false,
}: Props) {
  const policies = useSelector(getPolicies)
  const policiesNames = policies.map((policy: PolicyModel) => ({
    name: policy.policyName,
  }))

  const isLoadingPolicies = usePreloader(PreloaderConstants.REQUEST_POLICIES)

  const { categoriesExcludingSecondRow, categoriesIncludingSecondRow } =
    useMemo(() => {
      const withSelection = ValueHelper.fromSelectedToGlobal(
        selectedFilters,
        possibleFilters
      )

      return {
        categoriesExcludingSecondRow: withSelection.filter(
          ({ placeholder }) => placeholder !== 'second row'
        ),
        categoriesIncludingSecondRow: withSelection.filter(
          ({ placeholder }) => placeholder === 'second row'
        ),
      }
    }, [possibleFilters, selectedFilters])

  const hasDataExcludingSecondRow = categoriesExcludingSecondRow.length > 0
  const hasDataIncludingSecondRow = categoriesIncludingSecondRow.length > 0
  const [isShowFilter, setIsShowFilter] = useState<boolean>(showFilterFirst)

  const hideFiltersCategories = () => {
    setIsShowFilter(!isShowFilter)
    onFilterToggle?.(Boolean(!isShowFilter))
  }

  const handleCheckboxChange =
    (currentCategory: ValueInterface, filter: ValueInterface) =>
    (value: boolean) => {
      const result = ObjHelper.cloneDeep(selectedFilters)

      // depending on the result we add or remove
      const handleFunc = value
        ? ValueHelper.addUniqueChildToOptions
        : ValueHelper.removeChildFromOptions

      // determine the category
      let categoryIndex = result.findIndex(
        (v) => v.name === currentCategory.name
      )
      if (categoryIndex === -1) {
        result.push({
          ...currentCategory,
          options: [],
        })
        categoryIndex = result.length - 1
      }

      const elem = result[categoryIndex]
      if (elem) {
        result[categoryIndex] = handleFunc(elem, {
          ...filter,
          value,
        })
      }

      onFiltersChange(result)
    }

  const handleMultiselectChange =
    (currentCategory: ValueInterface) => (arr: VIRow) => {
      const result = ObjHelper.cloneDeep(selectedFilters)

      // determine category
      let categoryIndex = result.findIndex(
        (v) => v.name === currentCategory.name
      )

      if (categoryIndex === -1) {
        result.push({
          ...currentCategory,
          options: [],
        })
        categoryIndex = result.length - 1
      }
      // assign new selected values
      const elem = result[categoryIndex]
      if (elem) {
        elem.options = arr.map((v) => ({
          ...v,
          value: true,
        }))
      }

      onFiltersChange(result)
    }

  // TODO: remove console.log when backend will be ready
  const {
    openModal: openAddExistingPolicyModal,
    modalProps: addExistingPolicyModalProps,
  } = useModal<VIRow>(Modal.addExistingPolicy, (selectedPoliciesNames) => {
    console.log({ selectedPoliciesNames })
  })

  return (
    <div className={`wrap-1678194368647 ${className}`}>
      <div className="flexRowBetween mb12">
        <div className="flexRowJustifyStart">
          <div className="tableName">{tableName}</div>
        </div>
        <div
          className={clsx('flex', {
            w100: !tableName.length,
          })}
        >
          {customSearch}
          <Button
            disabled={disabled}
            className={clsx('filterV4Btn jsDashboardFilterBtn', {
              dNone: turnOffButton,
            })}
            type="button"
            color="primary"
            variant="contained"
            onClick={hideFiltersCategories}
          >
            <span className="btnLabelWrap">Filter</span>
            <span className="ml10">
              <FilterGreyIcon />
            </span>
          </Button>
        </div>
      </div>

      <div className="flexRowBetween">
        {isShowFilter && (
          <>
            {turnOnSearch && (
              <SearchForm
                placeholder={searchPlaceholder}
                errorGroups={[ErrorGroupConstants.EMPTY]}
                loading={disabled}
                defaultValues={{
                  search: searchVal,
                }}
                variant="whiteFilterSearchBlackIcon"
                onSubmit={(formData: FormSearchInterface) => {
                  onSearchChange?.(formData.search)
                }}
              />
            )}
            <div className="filtersScrollRow">
              {hasDataExcludingSecondRow &&
                categoriesExcludingSecondRow.map((filterCategory, i) => (
                  <React.Fragment key={i}>
                    {/* Checkbox  */}
                    {filterCategory.type === 1 && (
                      <div className="filterCategoryWrap">
                        {filterCategory.extraValue && (
                          <div className="checkboxGroupLabel">
                            {filterCategory.extraValue}
                          </div>
                        )}
                        {filterCategory.options?.map((filter) => (
                          <V2Checkbox
                            className={`${filterCategory.name}FilterCategory ${filter.label}Filter checkboxValue`}
                            onChange={handleCheckboxChange(
                              filterCategory,
                              filter
                            )}
                            label={filter.label}
                            checked={Boolean(filter.value)}
                            disabled={filter.disabled}
                            key={filter.label}
                          />
                        ))}
                      </div>
                    )}
                    {/* Multiselect */}
                    {filterCategory.type === 2 && (
                      <ViMultipleSelect
                        className={`selectedValue ${filterCategory.name}FilterCategory`}
                        disabled={disabled || filterCategory.disabled}
                        possible={filterCategory.options ?? []}
                        selected={filterCategory.options?.filter(
                          (v) => v.value
                        )}
                        onChange={handleMultiselectChange(filterCategory)}
                        placeholder={filterCategory.label}
                        countLabel={filterCategory.label}
                        key={filterCategory.name}
                        title={
                          filterCategory.supplementalValue
                            ? String(filterCategory.supplementalValue)
                            : ''
                        }
                      />
                    )}
                  </React.Fragment>
                ))}
            </div>
            <DemoWrap>
              {turnOnAddPolicyBtn && (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    className="addToPolicyBtn jsAddToPolicyBtn sizeS"
                    onClick={() => openAddExistingPolicyModal(policiesNames)}
                  >
                    <span className="btnLabelWrap">Add to policy</span>
                  </Button>
                </div>
              )}
            </DemoWrap>
          </>
        )}
      </div>

      <div className="filtersScrollRow secondRow">
        {hasDataIncludingSecondRow &&
          categoriesIncludingSecondRow.map((filterCategory, idx) => (
            <React.Fragment key={idx}>
              {/* Checkbox  */}
              {filterCategory.type === 1 && (
                <>
                  {filterCategory.extraValue && (
                    <div className="checkboxGroupLabel">
                      {filterCategory.extraValue}
                    </div>
                  )}
                  {filterCategory.options?.map((filter) => (
                    <V2Checkbox
                      className={`${filterCategory.name}FilterCategory ${filter.label}Filter checkboxValue`}
                      onChange={handleCheckboxChange(filterCategory, filter)}
                      label={filter.label}
                      checked={Boolean(filter.value)}
                      disabled={filter.disabled}
                      key={filter.label}
                    />
                  ))}
                </>
              )}
              {/* Multiselect */}
              {filterCategory.type === 2 && (
                <ViMultipleSelect
                  className={`selectedValue ${filterCategory.name}FilterCategory`}
                  disabled={disabled || filterCategory.disabled}
                  possible={filterCategory.options ?? []}
                  selected={filterCategory.options?.filter((v) => v.value)}
                  onChange={handleMultiselectChange(filterCategory)}
                  placeholder={filterCategory.label}
                  countLabel={filterCategory.label}
                  key={filterCategory.name}
                  title={
                    filterCategory.supplementalValue
                      ? String(filterCategory.supplementalValue)
                      : ''
                  }
                />
              )}
            </React.Fragment>
          ))}
      </div>

      <AddExistingPolicyModal
        loading={isLoadingPolicies}
        {...addExistingPolicyModalProps}
      />
    </div>
  )
}

export default memo(FilterDashboardV2)
