import React, { BaseSyntheticEvent, memo, useMemo } from 'react'
import { EngineCallback, ValueObject } from '@lib/engine-types'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import FormHelper from '@lib/helpers/form.helper'
import useGeneralForm from '@lib/hooks/useGeneralForm'
import clsx from 'clsx'
import SearchIcon from '@inline-img/general/search-icon'
import SearchLongIcon from '@inline-img/general/search-long-icon'
import useDebouncedFunction from '@lib/hooks/useDebouncedFunction'
import FormSearchInterface from '@lib/interfaces/form/form-search.interface'
import buildDefaultFormData from './data'
import SearchBlackIcon from '@inline-img/general/search-black-icon'

interface Props {
  placeholder?: string
  variant:
    | 'blackRectangle'
    | 'whiteRounded'
    | 'whiteFilterSearch'
    | 'whiteTopSearch'
    | 'whiteFilterSearchBlackIcon'
  defaultValues?: ValueObject<FormSearchInterface>
  onSubmit: EngineCallback<FormSearchInterface>
  loading?: boolean
  errorGroups?: Array<ErrorGroupConstants>
}

function SearchForm({
  placeholder = 'Search ...',
  variant,
  defaultValues,
  onSubmit,
  loading = false,
  errorGroups = [],
}: Props) {
  const currentForm = useMemo(
    () => FormHelper.fillDefaultValues(buildDefaultFormData(), defaultValues),
    []
  )

  const { isFormDisabled, register, submit, formState, globalFormError } =
    useGeneralForm(currentForm, onSubmit, errorGroups, loading, true)

  // on change search field
  const submitDebounced = useDebouncedFunction(submit, 300)
  const onSearchChange = (e: BaseSyntheticEvent) => {
    submitDebounced(e.target.value)
  }

  let IconComponent = SearchIcon
  if (variant === 'whiteFilterSearch') {
    IconComponent = SearchLongIcon
  }
  if (variant === 'whiteFilterSearchBlackIcon') {
    IconComponent = SearchBlackIcon
  }

  FormHelper.fillErrors(formState.errors, currentForm)

  return (
    <form
      className={clsx(`wrap-1617969467151 ${variant}Variant jsSearchForm`, {
        formDisabled: isFormDisabled,
      })}
      onSubmit={submit}
      aria-label="Search Form"
    >
      <label htmlFor="searchFormInput" className="searchWrap">
        <input
          aria-label="Search Form Input"
          id="searchFormInput"
          className="searchInput"
          type="text"
          placeholder={placeholder}
          onInput={onSearchChange}
          disabled={isFormDisabled}
          defaultValue={String(currentForm.search.value)}
          {...register(
            currentForm.search.name,
            currentForm.search.validationRules
          )}
        />
        <div className="searchIcon">
          <IconComponent />
        </div>
      </label>

      {globalFormError && (
        <div className="formGlobalError">{globalFormError}</div>
      )}
    </form>
  )
}

export default memo(SearchForm)
