/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { JobCloudConnector } from 'blues-corejs/dist'
import { FinishedJobCell } from './finished-job-cell'
import InProgressJobCell from './in-progress-job-cell'

interface Props {
  job: JobCloudConnector
}

export function ProgressCell({ job }: Props) {
  if (job.isInProgress) {
    return <InProgressJobCell progress={job.progress} />
  }

  if (job.isFinished && job.finishedAt) {
    return <FinishedJobCell finishedAt={job.finishedAt} />
  }

  return null
}
