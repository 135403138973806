import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { PechkinClient } from 'ui-v2/src/lib/clients'
import { useQuery } from '@tanstack/react-query'

export function useListPlanQuery() {
  const pechkinClient = useGrpcClient(PechkinClient)

  return useQuery({
    queryKey: ['pechkin', 'list-plan'],
    queryFn: async () => {
      if (!pechkinClient) {
        throw new Error('Pechkin client not initialized')
      }
      return pechkinClient.listPlans()
    },
    enabled: !!pechkinClient,
  })
}
