import { SvgIcon, SvgIconProps } from '@mui/material'

const System = ({ size = 24, ...props }: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 2,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <rect width="16" height="12" x="4" y="4" rx="2" stroke="currentColor" />
      <line x1="8" x2="16" y1="21" y2="21" stroke="currentColor" />
      <line x1="12" x2="12" y1="17" y2="21" stroke="currentColor" />
    </SvgIcon>
  )
}

export default System
