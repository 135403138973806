import {
  ListTenantJobsFilterRequestParams,
  ListTenantJobsPagination,
  TenantJobsClient,
} from '@lib/clients'
import { ListTenantJobsFilter } from '@lib/clients/jobs'
import { DefinedDateRangeTuple } from '@lib/engine-types'
import TimeHelper from '@lib/helpers/time.helper'
import { TenantJobFiltersUI } from '@lib/interfaces/jobs'
import { DEPLOYMENT_JOB_KINDS } from '@features/jobs/tenant-jobs/deployment-jobs/consts'

const tenantsJobsClient = new TenantJobsClient()

async function fetchInitialJobsList(
  selectedFilters: TenantJobFiltersUI,
  timeRange: DefinedDateRangeTuple
) {
  const formatFiltersToRequestParams =
    (): ListTenantJobsFilterRequestParams => {
      const kindsList = selectedFilters.kindsList.size
        ? Array.from(selectedFilters.kindsList)
        : DEPLOYMENT_JOB_KINDS
      const statusesList = Array.from(selectedFilters.statusesList)

      return {
        kindsList,
        statusesList,
        timeRange: TimeHelper.transformDateRangeForApi(timeRange),
      }
    }

  return tenantsJobsClient.listTenantJobs(
    new ListTenantJobsFilter(formatFiltersToRequestParams())
  )
}

async function fetchJobsListViaPagination(nextPageToken: string) {
  return tenantsJobsClient.listTenantJobs(
    new ListTenantJobsPagination(nextPageToken)
  )
}

export { fetchInitialJobsList, fetchJobsListViaPagination }
