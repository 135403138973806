import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import {
  buildCfnUpdatingCommand,
  buildCfnUpdatingLink,
} from 'ui-v2/src/lib/helpers/data.helper'
import CfnModel from 'ui-v2/src/lib/models/red-stack/cfn.model'
import CopyRow from './copy-row'

const AwsUpdateRequired = ({
  cloudInformationLinkData,
  cfn,
}: AwsUpdateRequiredProps) => {
  return (
    <>
      <Typography
        mb={1}
        variant="body1"
        fontWeight={400}
        color="text.secondary"
      >
        The CFN installed in the account is outdated. There is a new
        CloudFormation stack available. Upgrade CFN by pressing the button
        below.
      </Typography>
      <Grid container spacing={2} pb={2}>
        <Grid size={12}>
          <Button
            variant="contained"
            size="medium"
            disableElevation
            sx={{
              textTransform: 'capitalize',
            }}
            href={buildCfnUpdatingLink(cloudInformationLinkData, cfn)}
            target="_blank"
            disabled={!cloudInformationLinkData}
          >
            Update the CloudFormation Stack
          </Button>
        </Grid>
        <Grid size={12}>
          <Typography
            mb={1}
            variant="body1"
            fontWeight={400}
            color="text.secondary"
          >
            The CFN installed in the account is outdated. There is a new
            CloudFormation stack available. Upgrade CFN by pressing the button
            below.
          </Typography>
          {!!cloudInformationLinkData && (
            <CopyRow
              text={buildCfnUpdatingCommand(cloudInformationLinkData, cfn)}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
interface AwsUpdateRequiredProps {
  cloudInformationLinkData: string
  cfn: CfnModel
}
export default AwsUpdateRequired
