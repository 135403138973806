/* eslint-disable import/no-extraneous-dependencies */
import { ScanStatus } from '@features/DashboardV4/common'
import { ThreatIconsBlock } from '@features/DashboardV4/common/threat-icon-group'
import { ScansRun } from 'blues-corejs/dist/models'
import React from 'react'

function getScanStatuses(scans: ScansRun) {
  const ransomwareStatus = scans.ransomwareScansList.reduce(
    (status: ScanStatus, scan) => {
      if (scan.isInfected) {
        return ScanStatus.FAILED
      }
      if (scan.isClean) {
        return ScanStatus.SUCCEEDED
      }
      return status
    },
    ScanStatus.UNSCANNED
  )

  const malwareStatus = scans.malwareScansList.reduce(
    (status: ScanStatus, scan) => {
      if (scan.isInfected) {
        return ScanStatus.FAILED
      }
      if (scan.isClean) {
        return ScanStatus.SUCCEEDED
      }
      return status
    },
    ScanStatus.UNSCANNED
  )

  const fsCheckStatus = scans.filesystemChecksList.reduce(
    (status: ScanStatus, scan) => {
      if (scan.isInfected) {
        return ScanStatus.FAILED
      }
      if (scan.isClean) {
        return ScanStatus.SUCCEEDED
      }
      return status
    },
    ScanStatus.UNSCANNED
  )

  return {
    ransomwareStatus,
    malwareStatus,
    fsCheckStatus,
  }
}

interface Props {
  scans: ScansRun
}

function getThreatIconsProps(scans: ScansRun) {
  const shouldRenderFsCheckStatus = scans.filesystemChecksList.length > 0

  const { ransomwareStatus, malwareStatus, fsCheckStatus } =
    getScanStatuses(scans)

  if (shouldRenderFsCheckStatus) {
    return { fsCheckStatus: fsCheckStatus as unknown as ScanStatus }
  }

  return {
    ransomwareStatus: ransomwareStatus as unknown as ScanStatus,
    malwareStatus: malwareStatus as unknown as ScanStatus,
  }
}

function AssetItemScanCell({ scans }: Props) {
  const threatIconsProps = getThreatIconsProps(scans)

  return <ThreatIconsBlock {...threatIconsProps} />
}

export default AssetItemScanCell
