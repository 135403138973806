import React from 'react'
import ConfirmationDialog from 'ui-v2/src/components/dialogs/confirmation'
import { UserAction } from './column-config'
import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useInviteUserMutation,
} from 'ui-v2/src/hooks/queries/users'

interface UserActionConfirmationProps {
  action: UserAction | undefined
  setAction: React.Dispatch<React.SetStateAction<UserAction | undefined>>
}

type DialogConfig = {
  title: string
  message: string
  confirmText: string
  action: () => void
  isLoading: boolean
}

const UserActionConfirmation: React.FC<UserActionConfirmationProps> = ({
  action,
  setAction,
}) => {
  const { mutate: activateUser, isPending: isActivatingUser } =
    useActivateUserMutation()
  const { mutate: deactivateUser, isPending: isDeactivatingUser } =
    useDeactivateUserMutation()
  const { mutate: deleteUser, isPending: isDeletingUser } =
    useDeleteUserMutation()
  const { mutate: inviteUser, isPending: isInvitingUser } =
    useInviteUserMutation()

  const closeDialog = () => {
    setAction(undefined)
  }

  const getDialogConfig = (useAction: UserAction): DialogConfig => {
    const configs = {
      enable: {
        title: 'Enable User',
        message: `Are you sure you want to enable user ${useAction.row.email}?`,
        confirmText: 'Enable',
        action: () =>
          activateUser(useAction.row.id, {
            onSuccess: closeDialog,
          }),
        isLoading: isActivatingUser,
      },
      disable: {
        title: 'Disable User',
        message: `Are you sure you want to disable user ${useAction.row.email}?`,
        confirmText: 'Disable',
        action: () =>
          deactivateUser(useAction.row.id, {
            onSuccess: closeDialog,
          }),
        isLoading: isDeactivatingUser,
      },
      delete: {
        title: 'Delete User',
        message: `Are you sure you want to delete user ${useAction.row.email}? This action cannot be undone.`,
        confirmText: 'Delete',
        action: () =>
          deleteUser(useAction.row.id, {
            onSuccess: closeDialog,
          }),
        isLoading: isDeletingUser,
      },
      invite: {
        title: 'Resend Invitation',
        message: `Are you sure you want to resend invitation to ${useAction.row.email}?`,
        confirmText: 'Send Invitation',
        action: () =>
          inviteUser(
            { userEmail: useAction.row.email },
            {
              onSuccess: closeDialog,
            }
          ),
        isLoading: isInvitingUser,
      },
    }

    return configs[useAction.type]
  }

  if (!action) {
    return null
  }

  const config = getDialogConfig(action)

  return (
    <ConfirmationDialog
      open={!!action}
      onClose={closeDialog}
      onConfirm={config.action}
      title={config.title}
      confirmText={config.confirmText}
      isLoading={config.isLoading}
    >
      {config.message}
    </ConfirmationDialog>
  )
}

export default UserActionConfirmation
