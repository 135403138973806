import {
  Stack,
  StackOwnProps,
  Typography,
  TypographyOwnProps,
} from '@mui/material'

interface DrawerLabelValueProps extends StackOwnProps {
  label: string | number
  value: string | number
  labelWidth?: number
  labelAlign?: TypographyOwnProps['textAlign']
  variant?: TypographyOwnProps['variant']
  labelProps?: TypographyOwnProps
  valueProps?: TypographyOwnProps
  size?: 'sm' | 'md' | 'lg'
}

const getFontSize = (size: DrawerLabelValueProps['size']): number => {
  switch (size) {
    case 'sm':
      return 12
    case 'md':
      return 14
    case 'lg':
      return 16
    default:
      return 14
  }
}

const DrawerLabelValue: React.FC<DrawerLabelValueProps> = ({
  label,
  value,
  labelWidth = 100,
  labelAlign = 'right',
  spacing = 1,
  variant = 'body2',
  labelProps = {},
  valueProps = {},
  size = 'md',
  ...stackProps
}) => {
  const fontSize = getFontSize(size)
  return (
    <Stack
      direction="row"
      display={'flex'}
      alignItems={'center'}
      spacing={spacing}
      sx={{
        mb: 0.5,
        ...stackProps.sx,
      }}
      {...stackProps}
    >
      <Typography
        variant={variant}
        sx={{
          minWidth: labelWidth,
          fontWeight: 700,
          textAlign: labelAlign,
          fontSize,
          ...labelProps.sx,
        }}
        {...labelProps}
      >
        {label}:
      </Typography>
      <Typography
        variant={variant}
        sx={{
          fontSize,
          ...valueProps.sx,
        }}
        {...valueProps}
      >
        {value}
      </Typography>
    </Stack>
  )
}

export default DrawerLabelValue
