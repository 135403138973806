import { BaseModel } from 'ui-v2/src/lib/models/base-model'
import RedStackStatusConstant from 'ui-v2/src/lib/constants/red-stack-status.constant'
import LangHelper from 'ui-v2/src/lib/helpers/lang.helper'
import { IntegrityCheckErrorKind } from 'ui-v2/src/lib/constants/integrity-check-constant'

export interface IntegrityCheckError {
  kind: IntegrityCheckErrorKind
  message: string
}

class RedStackModel extends BaseModel {
  public readonly redStackId: string

  public readonly accountAlias: string

  public readonly awsRegion: string

  public readonly awsAccount: string

  public readonly status: RedStackStatusConstant

  public readonly iamRole: string

  public readonly accountDescription: string

  public readonly lastErrorMessage: string

  public readonly version: string

  public readonly lastWarningMessage: string

  public readonly awsAccountCfnVersion: string

  // extra
  public readonly isActive: boolean

  public readonly isInactive: boolean

  public readonly isDeleted: boolean

  public readonly awsRegionFormatted: string

  public readonly needRepair: boolean

  public readonly lastIntegrityCheckErrorsList: Array<IntegrityCheckError>

  public readonly cfnUpgradeRequired: boolean

  constructor(readonly params: any = {}) {
    super(params)

    // base
    this.redStackId = params.redStackId
    this.accountAlias = params.accountAlias
    this.awsRegion = params.awsRegion
    this.awsAccount = params.awsAccount
    this.status = params.status
    this.iamRole = params.iamRole
    this.accountDescription = params.accountDescription
    this.lastErrorMessage = params.lastErrorMessage
    this.version = params.version
    this.lastWarningMessage = params.lastWarningMessage
    this.awsAccountCfnVersion = params.awsAccountCfnVersion
    this.lastIntegrityCheckErrorsList = params.lastIntegrityCheckErrorsList
    this.cfnUpgradeRequired = params.cfnUpgradeRequired

    // extra
    this.isActive =
      RedStackStatusConstant.ACTIVE === this.status ||
      RedStackStatusConstant.UPGRADE_REQUIRED === this.status
    this.isInactive = RedStackStatusConstant.INACTIVE === this.status
    this.isDeleted = RedStackStatusConstant.DELETED === this.status
    this.awsRegionFormatted = LangHelper.getAwsRegionSingleTranslation(
      this.awsRegion
    )

    this.needRepair = [
      'Resync has been cancelled',
      'Resync has failed',
      'Catalog processing has encountered an error',
      'Failed to create a vault. Connector does not have any vaults.',
    ].includes(this.lastWarningMessage)
  }
}

export default RedStackModel
