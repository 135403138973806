import React, { memo } from 'react'
import { Button } from '@mui/material'
import CopyRow from '@components-simple/copy-row/CopyRow'
import { AccountAndSettingsRoutes, useNavigation } from '@lib/router'
import { AccountAndSettingsTab } from '@lib/router/routes/account-and-settings/account-and-settings'

interface Props {
  secretToken: string
}

function GeneratedTokenSuccess({ secretToken }: Props) {
  const router = useNavigation()

  return (
    <div className="wrap-1624020019059">
      <div className="generateSuccessContainer">
        <div className="title">
          Congratulations New Token Created!{' '}
          <span role="img" aria-label="icon">
            🔑
          </span>
        </div>
        <div className="info">
          A token has been generated that can be used to access elastio API.{' '}
          <b>
            Make sure to copy your new token now. You won’t be able to see it
            again.
          </b>
        </div>
        {secretToken && <CopyRow text={secretToken} variant="large" />}
        <Button
          className="backBtn"
          color="primary"
          type="button"
          variant="contained"
          onClick={() => {
            router.push(
              AccountAndSettingsRoutes.accountAndSettingsWithParams(
                AccountAndSettingsTab.API_ACCESS
              )
            )
          }}
        >
          Back to Tokens
        </Button>
      </div>
    </div>
  )
}

export default memo(GeneratedTokenSuccess)
