import React, { memo, useState } from 'react'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import ManagementUsers from '@components-complex/management/management-users/ManagementUsers'
import ManagementRoles from '@components-complex/management/management-roles/ManagementRoles'
import { Tab } from '@mui/material'

function ManageUsersComplex() {
  const [tab, setTab] = useState<number>(0)

  return (
    <div className="wrap-1634125416656">
      <CustomTabs value={tab} onChangeTab={setTab}>
        <Tab label="Users" />
        {/*TODO: hidden 09.02.2024 by ticket #9484*/}
        <Tab label="Roles" hidden />
      </CustomTabs>
      <MaterialTab value={tab} index={0}>
        <ManagementUsers />
      </MaterialTab>
      <MaterialTab value={tab} index={1}>
        <ManagementRoles />
      </MaterialTab>
    </div>
  )
}

export default memo(ManageUsersComplex)
