import BaseModal from '@components-composite/modals/BaseModal'
import React, { useCallback, useState } from 'react'
import {
  EngineCallback,
  EngineCallbackPure,
  Nullable,
  VIRow,
} from '@lib/engine-types'
import { Button } from '@mui/material'
import BubbleViSelect from '@components-composite/vi-inputs/bubble-vi-select/BubbleViSelect'
import ModalCloseIcon from '@inline-img/general/modal-close-icon'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import { EmptyFunc } from '@lib/constants/app.constant'

interface Props {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<VIRow>
  data: Nullable<VIRow>
  show: boolean
  loading: boolean
}

function AddExistingPolicyModal({
  data,
  onSubmit,
  show,
  onCancel,
  loading,
}: Props) {
  const [selectedPoliciesName, setSelectedPoliciesName] = useState<VIRow>([])
  const handlePoliciesName = (newPoliciesName: VIRow) => {
    setSelectedPoliciesName(newPoliciesName)
  }

  const onSubmitCallback = useCallback(() => {
    if (!selectedPoliciesName) {
      return
    }
    onSubmit(selectedPoliciesName)
  }, [selectedPoliciesName])

  const onCancelCallback = useCallback(() => {
    onCancel()
  }, [])

  return (
    <BaseModal onBgClick={onCancel} show={show} className="addExistingPolicy">
      <div className="modalHeaderWrapV3 flex jsHeader" aria-label="jsHeader">
        <div className="modalHeader">Add to a new or existing policy</div>
        <span className="dsModalClose" onClick={onCancelCallback}>
          <ModalCloseIcon />
        </span>
      </div>

      <div className="modalContent">
        Select from the policies below, or create a new policy.
        {loading ? (
          <PreloaderBlock show />
        ) : (
          <BubbleViSelect
            possible={data ?? []}
            selected={selectedPoliciesName}
            label="Add to"
            placeholder="Select policy..."
            canAddNew={false}
            onChange={handlePoliciesName}
            templateErrorMessage=""
          />
        )}
      </div>
      <div className="modalControlBlock">
        <Button
          className="sizeXS showButton jsAepCancel"
          data-testid="jsAepCancel"
          variant="contained"
          onClick={onCancelCallback}
          type="button"
        >
          Cancel
        </Button>
        <div>
          <Button
            className="sizeXS jsAepCreateNew"
            data-testid="jsAepCreateNew"
            color="primary"
            variant="contained"
            onClick={EmptyFunc}
            type="button"
          >
            Create new policy
          </Button>
          <Button
            className="sizeXS aepSaveBtn jsAepSave"
            data-testid="jsAepSave"
            color="primary"
            variant="contained"
            onClick={onSubmitCallback}
            type="button"
          >
            Save
          </Button>
        </div>
      </div>
    </BaseModal>
  )
}

export default AddExistingPolicyModal
