import { RefObject, useEffect } from 'react'
import useWindowSize from '@lib/hooks/window/useWindowSize'
import DomHelper from '@lib/helpers/dom.helper'
import PositionInterface from '@lib/interfaces/position.interface'

export function isUpdatingBlocked(
  width: number | undefined,
  elem: any
): boolean {
  // during pre-rendering "width" is "undefined"
  return (
    !width ||
    !elem ||
    // it is possible when we for example using "display: none"
    elem.clientWidth === 0
  )
}

function setDefaultStyles(elem: any, position: PositionInterface) {
  // eslint-disable-next-line no-param-reassign
  elem.style.position = 'absolute'

  if (position.left !== undefined) {
    if (position.left === 0) {
      // eslint-disable-next-line no-param-reassign
      elem.style.left = `${position.left}px`
    } else {
      // eslint-disable-next-line no-param-reassign
      elem.style.left = `calc(100% + ${position.left}px)`
    }
  }
  if (position.right !== undefined) {
    if (position.right === 0) {
      // eslint-disable-next-line no-param-reassign
      elem.style.right = `${position.right}px`
    } else {
      // eslint-disable-next-line no-param-reassign
      elem.style.right = `calc(100% + ${position.right}px)`
    }
  }

  if (position.top !== undefined) {
    // eslint-disable-next-line no-param-reassign
    elem.style.top = `calc(100% + ${position.top}px)`
  }
  if (position.bottom !== undefined) {
    // eslint-disable-next-line no-param-reassign
    elem.style.bottom = `calc(100% + ${position.bottom}px)`
  }
}

function updateElementPosition(
  elem: any,
  differenceInWidth: number,
  position: PositionInterface
) {
  // position X correction
  if (position.left !== undefined && position.right === undefined) {
    if (position.left === 0) {
      // eslint-disable-next-line no-param-reassign
      elem.style.left = `-${differenceInWidth}px`
    } else {
      // eslint-disable-next-line no-param-reassign
      elem.style.left = `calc(100% - ${differenceInWidth}px)`
    }
  }
  if (position.right !== undefined && position.left === undefined) {
    if (position.right === 0) {
      // eslint-disable-next-line no-param-reassign
      elem.style.right = `${differenceInWidth}px`
    } else {
      // eslint-disable-next-line no-param-reassign
      elem.style.right = `calc(100% + ${differenceInWidth}px)`
    }
  }

  // we move popup from the one line with the
  // main block
  if (position.bottom === undefined && position.top === undefined) {
    const delta = position.left ?? position.right ?? 12
    // eslint-disable-next-line no-param-reassign
    elem.style.top = `calc(100% + ${delta}px)`
  }
}

function useMakeViewableByWidth(
  ref: RefObject<any>,
  position: PositionInterface,
  dependencies: Array<any> = []
): void {
  const windowSize = useWindowSize()

  useEffect(() => {
    if (isUpdatingBlocked(windowSize.width, ref.current)) {
      return
    }

    setDefaultStyles(ref.current, position)

    const differenceInWidth = DomHelper.widthDiffBetweenBlockAndWindow(
      ref.current,
      Number(windowSize.width)
    )
    if (differenceInWidth > 0) {
      updateElementPosition(ref.current, differenceInWidth, position)
    }
  }, [windowSize, ...dependencies])
}

export default useMakeViewableByWidth
