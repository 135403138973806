import { SvgIcon, SvgIconProps } from '@mui/material'

const DarkMode = ({
  size = 24,
  ...props
}: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      component="svg"
      viewBox="0 0 24 24"
      sx={{
        width: size,
        height: size,
        strokeWidth: 0,
        fill: 'none',
        ...props.sx,
      }}
      {...props}
    >
      <path
        d="M17.2421 5.64637L15.0753 7.30784L15.8547 9.92851L13.6023 8.37838L11.3499 9.92851L12.1292 7.30784L9.96245 5.64637L12.6945 5.56929L13.6023 3L14.5101 5.56929L17.2421 5.64637ZM20.2396 11.5643L18.8351 12.6348L19.3404 14.3306L17.8844 13.3285L16.4285 14.3306L16.9338 12.6348L15.5292 11.5643L17.2935 11.5215L17.8844 9.85143L18.4754 11.5215L20.2396 11.5643ZM18.2869 15.8036C18.9978 15.7351 19.76 16.7457 19.3061 17.388C19.032 17.7734 18.7409 18.1331 18.3812 18.4757C15.0325 21.8414 9.61132 21.8414 6.27124 18.4757C2.92261 15.1356 2.92261 9.70584 6.27124 6.36577C6.61382 6.02319 6.97352 5.71488 7.35891 5.44082C8.00123 4.98692 9.01182 5.74914 8.9433 6.45997C8.71207 8.90936 9.53424 11.453 11.4184 13.3285C13.294 15.2127 15.829 16.0349 18.2869 15.8036Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default DarkMode
