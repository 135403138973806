import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import SystemHelper from '../../../lib/helpers/system.helper'

type FeatureFlags = {
  auth0Spa: boolean
  entropyDetectionFeature: boolean
  maintenanceMode: boolean
  scanOnlyEnabled: boolean
  genericHostsEnabled: boolean
  scanOnlyReleaseJanuary: boolean
  isInitialized: boolean
  maxAmountOfReportSchedules: number
  uiV2: boolean
  alert: boolean
}

export const useCustomFlags = (): FeatureFlags => {
  const flagsFromLd = useFlags()
  const ldClient = useLDClient()

  const flags = {
    auth0Spa: flagsFromLd.auth0Spa,
    entropyDetectionFeature: flagsFromLd.entropyDetectionFeature,
    maintenanceMode: flagsFromLd.maintenanceMode,
    scanOnlyEnabled: flagsFromLd.scanOnlyEnabled,
    genericHostsEnabled: flagsFromLd.genericHostsEnabled,
    scanOnlyReleaseJanuary: flagsFromLd.scanOnlyReleaseJanuary,
    isInitialized: ldClient !== undefined,
    maxAmountOfReportSchedules: flagsFromLd.maxAmountOfReportSchedules,
    uiV2: flagsFromLd.uiV2 || localStorage?.getItem('useNewUI') === 'true',
    alert: flagsFromLd.alert || localStorage?.getItem('alert') === 'true',
  }

  if (SystemHelper.isPublicE2E()) {
    return {
      ...flags,
      auth0Spa: false,
    }
  }

  return flags
}
