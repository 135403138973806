/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { TimeFormatConstants } from '@lib/constants'
import { DetailsOvaSnapshotOutput } from 'blues-corejs/dist/use_cases/list_details_ova_snapshots_with_threats/types'

dayjs.extend(utc)

function prepareOvaThreatsDataForCsv(
  threatsList: Array<DetailsOvaSnapshotOutput>
) {
  return threatsList.flatMap(({ threats, backupProviderVolumeId }) =>
    threats.map(({ name, location, firstDetectedAt }) => ({
      backupProviderVolumeId,
      threat: name,
      path: location,
      firstDetected: `${dayjs
        .utc(firstDetectedAt)
        .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`,
    }))
  )
}

const THREATS_OVA_CSV_TABLE_HEADERS = [
  {
    label: 'Disk ID',
    key: 'backupProviderVolumeId',
  },
  {
    label: 'Threat',
    key: 'threat',
  },
  {
    label: 'Path',
    key: 'path',
  },
  {
    label: 'First Detected',
    key: 'firstDetected',
  },
]

export { prepareOvaThreatsDataForCsv, THREATS_OVA_CSV_TABLE_HEADERS }
