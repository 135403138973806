import { FORM_FIELDS } from './form-field-names'
import { ALERT_ON_EVENTS } from './mode'

export const defaultValues = {
  [FORM_FIELDS.RULE_NAME]: '',
  [FORM_FIELDS.DESCRIPTION]: '',
  [FORM_FIELDS.MODE]: ALERT_ON_EVENTS,
  [FORM_FIELDS.EVENTS]: [],
  [FORM_FIELDS.SUPPRESS_UNTIL]: undefined,
  [FORM_FIELDS.REASON_SUPPRESSION]: '',
}
