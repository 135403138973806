import { NotificationsConfig } from '@lib/interfaces/notifications.interface'
import BaseModel from '@lib/models/base-model/base.model'

class UserNotificationsModel extends BaseModel {
  private slackConfig?: NotificationsConfig

  constructor(readonly params: any = {}) {
    super(params)

    this.slackConfig = params.slackConfig
  }

  public get isSlackEnabled() {
    return !!this.slackConfig
  }
}

export default UserNotificationsModel
