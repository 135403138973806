import { Chart } from 'react-google-charts'
import Box from '@mui/material/Box'

/**
 * This is a simple empty chart that is used to initialize the google-charts API. Without it, we can not render chart in dialogs.
 * More context: #11390
 */
export function SimpleEmptyChart() {
  return (
    <Box hidden>
      <Chart chartType="LineChart" data={[]} columns={[]} />
    </Box>
  )
}
