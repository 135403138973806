import { CellWithDateTimeTooltip } from '@components/table'
import React from 'react'
import { CellProps } from './types'

function SchedulesTableCellWithDate<TData, TValue>({
  cell,
}: CellProps<TData, TValue>) {
  const value = cell.getValue() as unknown as Date
  return <CellWithDateTimeTooltip date={value} />
}

export default SchedulesTableCellWithDate
