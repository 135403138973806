import React, { memo } from 'react'
import { ConfigurationVulnerabilities } from '@features/DashboardV4/ConfigurationVulnerabilities'
import { ThreatsWrapper } from '@features/DashboardV4/threats-widgets'
import { Divider } from '@mui/material'

function SecurityLeftColumn() {
  return (
    <React.Fragment>
      <ThreatsWrapper />
      <Divider />
      <ConfigurationVulnerabilities />
    </React.Fragment>
  )
}

export default memo(SecurityLeftColumn)
