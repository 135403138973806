import { Box } from '@mui/material'
import React from 'react'
import { WidgetHeader } from '../shared'
import { Container, StyledDivider } from './styles'
import {
  BackupsScanned,
  CumulativeDataScanned,
  DataProtected,
} from './components'
import { useFetchDataScannedScanned } from './use-fetch-data-scanned'

/**
 * Data Scanned widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19404-83698&mode=design&t=2cXpzW2OrwYIdoor-4)
 */
export default function DataScanned() {
  const { isLoading, backupsScanned, frontEndDataProtected, dataScanned } =
    useFetchDataScannedScanned()

  return (
    <Container>
      <WidgetHeader widgetTitle="Data Scanned (Prior 30 days)" />
      <DataProtected
        isLoading={isLoading}
        dataProtectedSize={frontEndDataProtected}
      />
      <StyledDivider />
      <Box display="flex">
        <BackupsScanned
          isLoading={isLoading}
          backupsScannedCount={backupsScanned}
        />
        <CumulativeDataScanned
          isLoading={isLoading}
          cumulativeDataScanned={dataScanned}
        />
      </Box>
    </Container>
  )
}
