import { styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import { CSSProperties } from 'react'

export const StyledModalTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  color: var(--grey900);
  margin-bottom: 8px;
`

export const StyledModalItemTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  color: var(--grey900);
  margin-bottom: 8px;
`

export const SelectDisplayStyle: CSSProperties = {
  position: 'fixed',
  top: '55%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1300,
}

export const CustomScheduleModalBackgroundStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 801,
}
