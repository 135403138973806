/* eslint-disable import/no-extraneous-dependencies */
import {
  CloudConnectorJobStatus,
  JobCloudConnectorKind,
} from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import { TimeRange } from '../types'
import { JobCloudConnector } from 'blues-corejs/dist'
import { AssetList } from '../assets'
import RedStackModel from '../base-model/red-stack.model'

export interface CloudConnectorJobRequestFilters {
  kindsList: Array<JobCloudConnectorKind>
  statusesList: Array<CloudConnectorJobStatus>
  assetIdsList: Array<string>
}

export interface ListCloudConnnectorJobsFilterRequestParams {
  assetIdsList?: Array<string>
  kindsList?: Array<JobCloudConnectorKind>
  statusesList?: Array<CloudConnectorJobStatus>
  ccIdsList?: Array<string>
  timeRange?: TimeRange
  pageSize?: number
  pageToken?: string
}

export interface Options {
  label: string
  id: string
}

export class MonitorTableRow {
  readonly #id: string

  readonly #accountId: string

  readonly #assetId: string

  readonly #status: CloudConnectorJobStatus

  readonly #jobType: string

  readonly #scanStartTime: Date

  readonly #scanEndTime: Date | undefined

  readonly #isFinished: boolean

  readonly #duration: string

  readonly #progress: number

  readonly #awsRegion: string

  readonly #assetKind: string

  readonly #createdAt: Date | undefined

  readonly #name: string

  readonly #message: string

  constructor(parameters: MonitorTableRowAttr) {
    const {
      id,
      accountId,
      assetId,
      status,
      jobType,
      scanStartTime,
      scanEndTime,
      isFinished,
      duration,
      progress,
      awsRegion,
      assetKind,
      createdAt,
      name,
      message,
    } = parameters

    this.#id = id
    this.#accountId = accountId
    this.#assetId = assetId
    this.#status = status
    this.#jobType = jobType
    this.#scanStartTime = scanStartTime
    this.#scanEndTime = scanEndTime
    this.#duration = duration
    this.#isFinished = isFinished
    this.#progress = progress
    this.#awsRegion = awsRegion
    this.#assetKind = assetKind
    this.#createdAt = createdAt
    this.#name = name
    this.#message = message
  }

  get id(): string {
    return this.#id
  }

  get accountId(): string {
    return this.#accountId
  }

  get assetId(): string {
    return this.#assetId
  }

  get status(): CloudConnectorJobStatus {
    return this.#status
  }

  get jobType(): string {
    return this.#jobType
  }

  get scanStartTime(): Date {
    return this.#scanStartTime
  }

  get scanEndTime(): Date | undefined {
    return this.#scanEndTime
  }

  get duration(): string {
    return this.#duration
  }

  get isFinished(): boolean {
    return this.#isFinished
  }

  get progress(): number {
    return this.#progress
  }

  get awsRegion(): string {
    return this.#awsRegion
  }

  get assetKind(): string {
    return this.#assetKind
  }

  get createdAt(): Date | undefined {
    return this.#createdAt
  }

  get message(): string {
    return this.#message
  }

  get name(): string {
    return this.#name
  }
}
interface MonitorTableRowAttr {
  id: string
  accountId: string
  assetId: string
  status: CloudConnectorJobStatus
  jobType: string
  scanStartTime: Date
  scanEndTime: Date | undefined
  duration: string
  isFinished: boolean
  progress: number
  awsRegion: string
  assetKind: string
  createdAt: Date | undefined
  message: string
  name: string
}

export interface MonitorTableDataAttrs {
  monitors: Array<JobCloudConnector>
  assetsList: Array<AssetList>
  redStackListData: Array<RedStackModel>
}
