import React, { useEffect, useMemo, useState } from 'react'
import useRouteTabs from '@lib/hooks/useRouteTabs'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import ManageUsersComplex from '@components-complex/manage-users-complex/ManageUsersComplex'
import SystemHelper from '@lib/helpers/system.helper'
import { BUILD_DATE } from '@lib/constants/app.constant'
import TenantNotificationsComplex from '@components-complex/tenant-notifications-complex/TenantNotificationsComplex'
import { useDispatch, useSelector } from 'react-redux'
import {
  requestTenantNotificationsSettingsShadow,
  updateTenantNotificationsSettings,
} from '@store/actions/tenant-notifications.action'
import {
  getTenantNotificationsSettings,
  getTenantNotificationsSetupUrl,
} from '@store/selectors/tenant-notifications.selector'
import TenantNotificationsFactory from '@lib/factories/tenant-notifications.factory'
import ValueInterface from '@lib/interfaces/value.interface'
import ToastHelper from '@lib/helpers/toast.helper'
import useIntervalIf from '@lib/hooks/useIntervalIf'
import { getUserRoleScopes } from '@store/selectors/user.selector'
import DataHelper from '@lib/helpers/data.helper'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import TenantWebhooks from '@components-complex/tenant-webhooks/TenantWebhooks'
import { requestGetTenant } from '@store/actions/tenant.action'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import { EntropyDetectionNotificationRadio } from './entropy-detection-notification-radio'
import AllWorkflowsLink from '@components-simple/all-workflows-link/AllWorkflowsLink'
import PagePathConstant from '../../../lib/constants/page-path.constant'
import PageHelper from '../../../lib/helpers/page.helper'
import { useNavigation } from '@lib/router/contexts/navigation-context'

enum InstallationStatus {
  OK = 'ok',
  FAILED = 'failed',
  EXPIRED = 'expired',
}

enum Tabs {
  GENERAL = 0,
  MEMBERS = 1,
  ALERTS = 2,
  WEBHOOKS = 3,
}

function SettingsComplex() {
  const router = useNavigation()
  const isPrevOnboarding = PageHelper.isOnboardingPrevious(router.query)
  const tenantName = SystemHelper.getCurrentTenantName()
  const dispatch = useDispatch()

  const tenantSetupUrl = useSelector(getTenantNotificationsSetupUrl)

  const notificationsSettings = useSelector(getTenantNotificationsSettings)

  const userScopes = useSelector(getUserRoleScopes)

  const isUserHasNotificationsScope = useMemo(
    () =>
      userScopes &&
      !!DataHelper.groupScopes(userScopes).find(
        (role) => role.name === 'TenantNotifications'
      ),
    [userScopes]
  )

  const isUserHasWebhooksScope = useMemo(
    () =>
      userScopes &&
      !!DataHelper.groupScopes(userScopes).find(
        (role) => role.name === 'Webhooks'
      ),
    [userScopes]
  )

  const statusQuery = router.query?.status
    ? { status: router.query.status }
    : {}

  const { currentTab, onTabChange } = useRouteTabs({
    additionalQueries: statusQuery,
  })

  const notificationsLoading = usePreloader(
    PreloaderConstants.REQUEST_TENANT_NOTIFICATIONS_SETTINGS
  )

  const {
    errors: sendErrors,
    warnings: sendWarnings,
    info: sendInfo,
  } = TenantNotificationsFactory.buildDataBySettings(
    notificationsSettings,
    !Boolean(tenantSetupUrl),
    notificationsLoading
  )
  const [errors, setErrors] = useState<Array<ValueInterface>>([])
  const [warnings, setWarnings] = useState<Array<ValueInterface>>([])
  const [info, setInfo] = useState<Array<ValueInterface>>([])

  const [emails, setEmails] = useState<Array<string>>([])

  useEffect(() => {
    setEmails(notificationsSettings.emailNotificationSettings.emailsList || [])
  }, [JSON.stringify(notificationsSettings.emailNotificationSettings)])

  const isErrorsChanged = useMemo(
    () =>
      !!errors.filter(
        (data) =>
          !sendErrors?.some(
            (old) => old.name === data.name && old.value === data.value
          )
      ).length,
    [errors, sendErrors]
  )

  const isWarningsChanged = useMemo(
    () =>
      !!warnings.filter(
        (data) =>
          !sendWarnings?.some(
            (old) => old.name === data.name && old.value === data.value
          )
      ).length,
    [warnings, sendWarnings]
  )

  const isInfoChanged = useMemo(
    () =>
      !!info.filter(
        (data) =>
          !sendInfo?.some(
            (old) => old.name === data.name && old.value === data.value
          )
      ).length,
    [info, sendInfo]
  )

  const isDataChanged = [
    isErrorsChanged,
    isWarningsChanged,
    isInfoChanged,
  ].some((value) => value)

  useEffect(() => {
    setErrors(sendErrors || [])
    setWarnings(sendWarnings || [])
    setInfo(sendInfo || [])
  }, [JSON.stringify(notificationsSettings)])

  useIntervalIf(
    router.query?.status === InstallationStatus.OK && !notificationsSettings,
    () => {
      dispatch(requestTenantNotificationsSettingsShadow())
    },
    []
  )

  const isEmailsEnabled = useMemo(() => {
    if (!errors || !warnings || !info) {
      return
    }

    return !!errors[0]?.value || !!warnings[0]?.value
  }, [errors, warnings, info])

  const onSave = () => {
    dispatch(
      updateTenantNotificationsSettings({
        slack: {
          sendErrors: !!errors[1]?.value,
          sendWarnings: !!warnings[1]?.value,
          sendInfo: !!info[0]?.value,
          isDisconnected: Boolean(tenantSetupUrl),
        },
        email: {
          sendErrors: !!errors[0]?.value,
          sendWarnings: !!warnings[0]?.value,
          sendInfo: false,
          emailsList: emails,
        },
      })
    )
  }
  const isEmailsChanged = useMemo(
    () =>
      notificationsSettings.emailNotificationSettings.emailsList?.length !==
        emails?.length ||
      !!emails.filter(
        (email) =>
          !notificationsSettings.emailNotificationSettings.emailsList?.includes(
            email
          )
      ).length ||
      false,
    [
      JSON.stringify(emails),
      JSON.stringify(
        notificationsSettings.emailNotificationSettings.emailsList
      ),
    ]
  )

  useEffect(() => {
    dispatch(requestGetTenant())

    switch (router.query?.status) {
      case InstallationStatus.FAILED:
        ToastHelper.error('Connection to Slack failed.')
        break
      case InstallationStatus.OK:
        ToastHelper.success(
          'Connection to Slack is completed successfully. Slack authorization might take a couple of minutes.'
        )
        break
      case InstallationStatus.EXPIRED:
        ToastHelper.info('Connection to Slack expired.')
        break
      default:
        break
    }
  }, [])

  const isAlertsSaveButtonDisabled =
    !(isEmailsChanged || isDataChanged) || (!emails.length && isEmailsEnabled)

  return (
    <>
      {isPrevOnboarding && (
        <AllWorkflowsLink backUrl={PagePathConstant.ONBOARDING} />
      )}
      <Box className="wrap-1634125547837">
        <CustomTabs value={currentTab} onChangeTab={onTabChange}>
          <Tab label="General" value={0} />
          <Tab label="Team Members" value={1} />
          {isUserHasNotificationsScope && <Tab label="Alerts" value={2} />}
          {isUserHasWebhooksScope && <Tab label="Webhooks" value={3} />}
        </CustomTabs>

        <MaterialTab value={currentTab} index={0}>
          <Box className="scGeneral">
            <Box className="scBlock">
              <Box className="scBlockTitle">Name</Box>
              <Box className="scBlockDesc">{tenantName}</Box>
            </Box>
            <Box className="scBlock">
              <Box className="scBlockTitle">Tenant Version</Box>
              <Box className="scBlockDesc">
                Release: {BUILD_DATE ? BUILD_DATE : ''}
              </Box>
              <Box className="scBlockSubDesc">
                Terms and conditions are defined in the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://elastio.com/subscription-agreement/"
                  className="blueLink"
                >
                  subscription agreement
                </a>
              </Box>
              <EntropyDetectionNotificationRadio />
            </Box>
          </Box>
        </MaterialTab>
        <MaterialTab value={currentTab} index={1}>
          <ManageUsersComplex />
        </MaterialTab>
        {isUserHasNotificationsScope && (
          <MaterialTab value={currentTab} index={2}>
            <TenantNotificationsComplex
              errors={{
                state: errors,
                setState: setErrors,
              }}
              warnings={{
                state: warnings,
                setState: setWarnings,
              }}
              success={{
                state: info,
                setState: setInfo,
              }}
              slackUrl={tenantSetupUrl}
              emailsConfig={{
                disabled: notificationsLoading || !isEmailsEnabled,
                emails,
                setEmails,
              }}
            />
          </MaterialTab>
        )}
        {isUserHasWebhooksScope && (
          <MaterialTab value={currentTab} index={3}>
            <TenantWebhooks />
          </MaterialTab>
        )}
        {currentTab === Tabs.ALERTS && (
          <Box mt={2}>
            <Button
              variant="contained"
              onClick={onSave}
              disabled={isAlertsSaveButtonDisabled}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}

export default SettingsComplex
