import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'

import { Controller, useFormContext } from 'react-hook-form'
import ProtectionOptionsCheckbox from '../protection-options-checkbox'

const EfsRecoverAssuranceSettings = ({
  reviewPolicy,
}: EfsRecoverAssuranceSettingsProps) => {
  const { control } = useFormContext<PolicyFormData>()
  return (
    <>
      <Controller
        name="integrityScan"
        control={control}
        render={({ field }) => (
          <ProtectionOptionsCheckbox
            label="Zero-Day Ransomware Detection"
            description={
              reviewPolicy
                ? ''
                : 'Detects never-before-seen ransomware using an 3 layered behavioral ML models. These models are automatically updated with the latest ransomware research from our dedicated security lab.'
            }
            protectionOptionValue={field.value.scanForRansomware}
            onProtectionOptionChange={(e) => {
              field.onChange({
                ...field.value,
                scanForRansomware: e.target.checked,
              })
            }}
            disabled={reviewPolicy}
          />
        )}
      />

      <Controller
        name="isEntropyDetectionEnabled"
        control={control}
        render={({ field }) => (
          <ProtectionOptionsCheckbox
            label="Insider Threat Detection"
            description={
              reviewPolicy
                ? ''
                : 'Detects internal threats by identifying newly encrypted or suspiciously modified files across your network. Using advanced behavioral analysis, it compares file changes between scans, flags signs of encryption, and includes file type analysis to protect your data.'
            }
            protectionOptionValue={field.value}
            onProtectionOptionChange={(e) => {
              field.onChange(e.target.checked)
            }}
            disabled={reviewPolicy}
          />
        )}
      />

      <Controller
        name="integrityScan"
        control={control}
        render={({ field }) => (
          <ProtectionOptionsCheckbox
            label="Malware Signature Scan"
            description={
              reviewPolicy
                ? ''
                : 'Detects the latest malware including ransomware payloads, Trojans, spyware, adware and crypto-miners. Signatures are automatically updated as frequently as every hour to ensure the latest protection.'
            }
            protectionOptionValue={field.value.scanForMalware}
            onProtectionOptionChange={(e) => {
              field.onChange({
                ...field.value,
                scanForMalware: e.target.checked,
              })
            }}
            disabled={reviewPolicy}
          />
        )}
      />
    </>
  )
}
interface EfsRecoverAssuranceSettingsProps {
  reviewPolicy?: boolean
}
export default EfsRecoverAssuranceSettings
