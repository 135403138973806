/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import FilterDashboardV2 from '@components-complex/filter-dashboard-v2/FilterDashboardV2'
import ValueInterface from '@lib/interfaces/value.interface'
import DataHelper from '@lib/helpers/data.helper'
import { getAllRedStacks } from '@store/actions/rex.action'
import { getAllActiveRedStacksList } from '@store/selectors/rex.selector'
import { AliasNamesForS3Bucket } from '@lib/interfaces/inventory/s3-bucket.interface'
import { getEfsTableColumnDefinitions } from './columns-definition'
import { ListEfsInventory } from 'blues-corejs/dist'
import {
  getAliasNamesForEfs,
  getDataForPossibleEfsInventoryFilters,
} from './utils'
import FilterFactory from '@lib/factories/filter.factory'
import { listEfsInventoryPossibleFilters } from 'blues-corejs/dist/use_cases/list_efs_inventory_possible_filters'
import {
  getIsLiveAssetsLoading,
  getLiveAssets,
  getScans,
  getThreats,
} from '@store/selectors/list-live-assets.selector'
import { Box, Typography } from '@mui/material'
import V2Checkbox from '@components-composite/v2-inputs/v2-checkbox/V2Checkbox'
import { useList } from '@components-complex/dashboard-pages-v3/ebs-inventory-table-complex/use-list'
import { useListEfs } from './use-list-efs'
import { updateDisabledStateForCoveredOptions } from '@components-complex/dashboard-pages-v3/shared'
import { initialPrioritySortForEFSTable } from './initial-priority-sort-for-efs-table'
import { VirtualTable } from '@components/table/table-infinite-scroll-memory'
import { ExportAsCsv } from '@features/common'
import {
  prepareEfsDataForCsv,
  EFS_CSV_TABLE_HEADERS,
} from './prepare-data-for-csv'
import { getDetectedThreats } from '@components-complex/dashboard-pages-v3/utils'
import { useOnDemandScan } from '@components-complex/dashboard-pages-v3/useOnDemandScan'
import {
  ON_DEMAND_BUTTON_GRADIENT_COLORS,
  ON_DEMAND_SCAN_BUTTON_TEXT,
} from '@components-complex/dashboard-pages-v3/const'
import { StyledDemandButton } from '@components-complex/dashboard-pages-v3/styles'
import { useLayoutContext } from '@features/contexts'

const HEADER_TITLE = 'EFS'

function EfsInventoryTableComplex() {
  const { setHeaderTitle } = useLayoutContext()
  const [search, setSearch] = useState('')
  const [selectedFilters, setSelectedFilters] = useState<Array<ValueInterface>>(
    []
  )

  const isLiveAssetsLoading = useSelector(getIsLiveAssetsLoading)

  const { filesystems } = useSelector(getLiveAssets)

  const threats = useSelector(getThreats)
  const detectedThreat = getDetectedThreats(threats)

  const { malwareScans, ransomwareScans } = useSelector(getScans)

  const { possibleEfsFilters } = new listEfsInventoryPossibleFilters({
    filesystems,
  }).execute()

  const listEfs = useListEfs()

  const [isListEfsActive, setIsListEfsActive] = useState(false)

  const [list, actions] = useList(filesystems)
  const handleToggleList = (value: boolean) => {
    setIsListEfsActive(value)
    if (value) {
      actions.clear()
      actions.set(listEfs.data)
    } else {
      actions.reset()
    }
  }
  const { efs: efsTableData, summarizedData } = new ListEfsInventory({
    filesystems: list,
    threats: detectedThreat,
    scans: [...malwareScans, ...ransomwareScans],
  }).execute(DataHelper.getDataForDashboardFilter(selectedFilters, search))

  const dataForPossibleEfsInventoryFilters =
    getDataForPossibleEfsInventoryFilters(possibleEfsFilters)
  const possibleFilters = updateDisabledStateForCoveredOptions(
    dataForPossibleEfsInventoryFilters.map(
      FilterFactory.buildEfsInventoryFilter
    ),
    isListEfsActive
  )
  const dispatch = useDispatch()

  const allActiveRedStacks = useSelector(getAllActiveRedStacksList)
  const aliasNamesWithId: AliasNamesForS3Bucket = useMemo(() => {
    if (allActiveRedStacks.length === 0 || !filesystems.length) {
      return {}
    }
    return getAliasNamesForEfs(filesystems, allActiveRedStacks)
  }, [filesystems.length, allActiveRedStacks.length])

  useEffect(() => {
    dispatch(getAllRedStacks())
    setHeaderTitle(HEADER_TITLE)

    return () => {
      setHeaderTitle(null)
    }
  }, [])

  const onFiltersChange = (newFilters: Array<ValueInterface>) => {
    setSelectedFilters(newFilters)
  }

  const onSearchChange = (value: string) => {
    setSearch(value.trim())
  }

  const sortedFileSystems = initialPrioritySortForEFSTable(efsTableData)

  const { handleDemandButtonClick, isScheduleScanLoading, rowsSelection } =
    useOnDemandScan({
      assetsCoreModel: filesystems,
      assets: efsTableData,
    })

  const isDemandButtonDisabled =
    isScheduleScanLoading || !Object.keys(rowsSelection.selectedRows).length

  return (
    <Box data-testid="efs-table">
      {isLiveAssetsLoading ? (
        <PreloaderBlock show />
      ) : (
        <Box display="flex">
          <FilterDashboardV2
            searchPlaceholder="Search by file system name"
            possibleFilters={possibleFilters}
            selectedFilters={selectedFilters}
            turnOnSearch
            onFiltersChange={onFiltersChange}
            onSearchChange={onSearchChange}
            disabled={false}
            searchVal={search}
            showFilterFirst
            tableName=""
            turnOffButton
            turnOnAddPolicyBtn
            className="fiveItemsWithLabel"
          />
          <Box
            display="flex"
            alignItems="center"
            height="62px"
            sx={{
              color: 'var(--grey800)',
              '.MuiCheckbox-root svg': {
                width: '18px',
                height: '18px',
              },
              '.MuiFormControlLabel-label': {
                fontSize: '14px',
                fontWeight: '400',
              },
            }}
          >
            <Typography fontWeight="500" fontSize="14px" marginRight="12px">
              State:
            </Typography>
            <V2Checkbox
              onChange={handleToggleList}
              label="In use"
              checked
              disabled
            />
            <V2Checkbox
              onChange={handleToggleList}
              label="Deleted"
              checked={isListEfsActive}
              disabled={listEfs.isLoadingData()}
            />
          </Box>
          <StyledDemandButton
            variant="contained"
            disabled={isDemandButtonDisabled}
            onHoldComplete={handleDemandButtonClick}
            gradientColors={ON_DEMAND_BUTTON_GRADIENT_COLORS}
          >
            {ON_DEMAND_SCAN_BUTTON_TEXT}
          </StyledDemandButton>
        </Box>
      )}
      <ExportAsCsv
        data={prepareEfsDataForCsv(sortedFileSystems)}
        headers={EFS_CSV_TABLE_HEADERS}
        filename="efs-inventory.csv"
        shouldShow={!isListEfsActive && sortedFileSystems.length > 0}
      />
      <VirtualTable
        config={{
          data: sortedFileSystems,
          columns: getEfsTableColumnDefinitions({
            summarizedData,
            aliasNamesWithId,
          }),
          state: {
            rowSelection: rowsSelection.selectedRows,
            isLoading: false,
          },
          onRowSelectionChange: rowsSelection.setSelectedRows,
        }}
        emptyPlaceholderText="No EFS found"
      />
    </Box>
  )
}

export default EfsInventoryTableComplex
