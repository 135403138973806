/* eslint-disable import/no-extraneous-dependencies */
import { findSelectedAssetIndex } from '@features/policies/policy/policy-tables'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { EBS, EC2 } from 'blues-corejs/dist'

export function getAttachedAndSelectedSpecificAssetIds({
  selectedAsset,
  ec2Assets,
  ebsAssets,
}: {
  selectedAsset: AssetWithRelatedAssets<Asset>
  ec2Assets: Array<AssetWithRelatedAssets<Asset>>
  ebsAssets: Array<AssetWithRelatedAssets<Asset>>
}): { selectedIndex: number; attachedIdsList: Array<string> } {
  const { asset } = selectedAsset

  const attachedIdsList: Array<string> = []
  const currentAssetsList = asset instanceof EBS ? ebsAssets : ec2Assets

  if (asset instanceof EBS) {
    attachedIdsList.push(...asset.attachedInstanceIds)
  }

  if (asset instanceof EC2) {
    attachedIdsList.push(...asset.ebsIds)
  }

  const selectedIndex = findSelectedAssetIndex({
    assets: currentAssetsList,
    selectedAsset,
  })

  return {
    selectedIndex,
    attachedIdsList,
  }
}
