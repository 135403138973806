/* eslint-disable import/no-extraneous-dependencies */
import {
  AllKinds,
  NotificationRule,
  RuleNotify,
  RuleStatus,
  RuleSuppressUntil,
} from 'blues-corejs/dist/models/notifications/rule'
import { RuleDetailsState } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/rule-details-store'
import { ALERT_ON_EVENTS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts'
import { SUPPRESS_EVENTS } from '@features/alerts/create-rule-drawer/stepper/steps/rule-details/consts/mode'
import { InfrastructureScopeState } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/infrastructure-scope-store'
import { AWS } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/consts'
import { ON_PREM } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/consts/infrastructure-type'
import { UserProfile } from 'blues-corejs/dist/models'
import { WebhooksListItem } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import { NotificationsState } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/notifications-store'
import { Option } from '@features/alerts/create-rule-drawer/stepper/steps/notifications/types'

export const prepareDetailsData = (
  rule: NotificationRule
): RuleDetailsState => {
  const mode =
    rule.mode instanceof RuleNotify ? ALERT_ON_EVENTS : SUPPRESS_EVENTS

  const events: Array<{ label: string; value: string }> =
    rule.criteria.eventKinds instanceof AllKinds
      ? []
      : rule.criteria.eventKinds.kinds.map((kind) => ({
          label: kind,
          value: kind,
        }))

  const reasonSuppression =
    rule.mode instanceof RuleSuppressUntil ? rule.mode.reason : ''
  const suppressUntil =
    rule.mode instanceof RuleSuppressUntil
      ? rule.mode.date.getSeconds()
      : undefined

  return {
    ruleDetailsData: {
      ruleName: rule.name,
      description: rule.description,
      mode,
      events,
      reasonSuppression,
      suppressUntil,
    },
    allKinds: rule.criteria.eventKinds instanceof AllKinds,
    ruleId: rule.id,
  }
}

export const prepareInfrastructureScopeData = (
  rule: NotificationRule
): InfrastructureScopeState => {
  const infrastructureType =
    rule.criteria.awsAccountRegions.length > 0 ? AWS : ON_PREM
  const accountsRegions = rule.criteria.awsAccountRegions.map(
    (region) => `${region.accountId}||${region.region}`
  )
  const accountsBackupProviders = rule.criteria.ovaAccountProviders.map(
    (provider) => `${provider.accountId}||${provider.provider}`
  )

  return {
    infraData: {
      accountsBackupProviders,
      accountsRegions,
      infrastructureType,
    },
  }
}

export const prepareNotificationData = (
  rule: NotificationRule,
  usersList: Array<UserProfile>,
  webHooksList: Array<WebhooksListItem.AsObject>
): NotificationsState => {
  const existingConsoleUsers: Array<Option> =
    rule.channels.tenantMemberEmails.map(({ customer_id }) => ({
      value: customer_id,
      label: usersList.find((user) => user.id === customer_id)?.email || '',
    }))
  const webhooks: Array<Option> = rule.channels.webhooks.map(
    ({ webhookId }) => ({
      value: webhookId,
      label:
        webHooksList.find((webhook) => webhook.id === webhookId)?.name || '',
    })
  )
  return {
    notificationsData: {
      existingConsoleUsers,
      webhooks,
      slackIntegration: rule.channels.slack,
    },
  }
}

export const changeStatusForRules = ({
  rulesList,
  newStatus,
  changedIds,
}: {
  rulesList: Array<NotificationRule>
  changedIds: Array<string>
  newStatus: RuleStatus
}) => {
  return rulesList.map((rule) => {
    const {
      id,
      mode,
      createdAt,
      updatedAt,
      criteria,
      channels,
      name,
      description,
      status,
    } = rule
    return new NotificationRule({
      id,
      name,
      description,
      mode,
      criteria,
      channels,
      createdAt,
      updatedAt,
      status: changedIds.includes(id) ? newStatus : status,
    })
  })
}
