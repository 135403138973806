/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { Asset, Threat } from 'blues-corejs/dist'
import { AssetByThreat } from './types'
import { ListAssetsByIdsClient } from '@lib/clients'

const assetByIdsClient = new ListAssetsByIdsClient()

async function fetchAssetsByIdsContainInThreats(threats: Array<Threat>) {
  const assetByThreatMap: AssetByThreat = new Map()

  const assetIds = threats
    .map((threat) => threat.assetId)
    .filter((assetId): assetId is string => !!assetId)

  const assetsList = await assetByIdsClient.listAssetsByIds(assetIds)

  for (const threat of threats) {
    const assetFromResponse = assetsList.assetsList.find((assetFromList) =>
      [
        assetFromList.ebsVolume,
        assetFromList.ec2Instance,
        assetFromList.s3Bucket,
        assetFromList.efsFilesystem,
        assetFromList.genericHost,
        assetFromList.ovaServer,
      ].some((asset): asset is Asset => asset?.id === threat.assetId)
    )

    if (!assetFromResponse) {
      continue
    }

    const asset = [
      assetFromResponse.ebsVolume,
      assetFromResponse.ec2Instance,
      assetFromResponse.s3Bucket,
      assetFromResponse.efsFilesystem,
      assetFromResponse.genericHost,
      assetFromResponse.ovaServer,
    ].find(
      (assetFromList): assetFromList is Asset =>
        assetFromList?.id === threat.assetId
    )

    if (asset) {
      assetByThreatMap.set(threat, asset)
    }
  }

  return assetByThreatMap
}

export function useThreatsMappedByAsset(threats: Array<Threat>) {
  const [assetByThreat, setAssetByThreat] = useState<AssetByThreat>(new Map())

  const [isAssetLoading, setIsAssetLoading] = useState(false)

  useEffect(() => {
    if (assetByThreat.size === 0) {
      setIsAssetLoading(true)
    }

    fetchAssetsByIdsContainInThreats(threats)
      .then((assetByThreatMap) => {
        setAssetByThreat(assetByThreatMap)
      })
      .finally(() => {
        if (assetByThreat.size === 0) {
          setIsAssetLoading(false)
        }
      })
  }, [threats])

  return {
    assetByThreat,
    isAssetLoading,
  }
}
