import React from 'react'
import { EntropyTrendDateFilter } from '@features/entropy-trends/components/entropy-trends-filters/generic'
import { DateRangeTuple } from '@lib/engine-types'
import moment from 'moment'
import { TimeHelper } from '@lib/helpers'
import {
  useEntropyFiltersActions,
  useGetEntropyFiltersLoading,
  useGetSelectedFilters,
} from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store.context'
import { EntropyTrendsTimeSelection } from '@features/entropy-trends/components/entropy-trends-filters/entropy-trends-filters-store'

function convertTimeRangeToTuple(
  startAt: number,
  endAt: number
): DateRangeTuple {
  return [
    TimeHelper.timestampToMoment(startAt),
    TimeHelper.timestampToMoment(endAt),
  ]
}

interface Props {
  disabled: boolean
}

export function DateRangeFilter({ disabled }: Props) {
  const { startAt, endAt } = useGetSelectedFilters()

  const { fetchFilters } = useEntropyFiltersActions()
  const isFiltersLoading = useGetEntropyFiltersLoading()

  const handleDateRangeChange = (newDateRange: DateRangeTuple) => {
    const start = newDateRange[0]
    const end = newDateRange[1]

    if (moment.isMoment(start) && moment.isMoment(end)) {
      fetchFilters(
        EntropyTrendsTimeSelection.CUSTOM_DATE,
        start.valueOf(),
        end.valueOf()
      )
    }
  }

  return (
    <EntropyTrendDateFilter
      dateRange={convertTimeRangeToTuple(startAt, endAt)}
      onDateChange={handleDateRangeChange}
      disabled={isFiltersLoading || disabled}
    />
  )
}
