/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  ColumnWidth,
  createColumn,
  createDisplayColumn,
  StatusCircle,
} from '@components/table'
import { CellWithLink } from 'components/table/core/cell'
import LangHelper from '@lib/helpers/lang.helper'
import { SnapshotOutput, RiskLevel } from 'blues-corejs/dist'
import LongTextTooltip from '@components-simple/long-text-tooltip/LongTextTooltip'
import { TABLE_MISCONFIGURATIONS_ROW_MIN_HEIGHT } from './consts'
import { AssetRoutes } from '@lib/router'

export const snapshotsColumns = [
  createDisplayColumn<SnapshotOutput>({
    id: 'status',
    size: 40,
    cell: () => <StatusCircle isHealthy={false} />,
  }),
  createColumn<SnapshotOutput>('snapshotId', {
    header: 'Snapshot ID',
    meta: {
      height: TABLE_MISCONFIGURATIONS_ROW_MIN_HEIGHT,
    },
    cell: (props) => {
      const snapshotId = props.getValue()
      const volumeId = props.row.original.volumeId
      const assetPageUrl = AssetRoutes.assetId(volumeId)

      return <CellWithLink href={assetPageUrl} value={snapshotId} />
    },
  }),
  createColumn<SnapshotOutput>('vulnerabilitiesList', {
    header: 'Misconfiguration',
    meta: {
      size: ColumnWidth.LARGE,
    },
    cell: ({ getValue }) => {
      const vulnerabilities = getValue() as unknown as Array<string>

      return (
        <LongTextTooltip text={vulnerabilities.join(', ')} maxLength={60} />
      )
    },
  }),
  createColumn<SnapshotOutput>('riskVulnerabilities', {
    header: 'Risk',
    cell: (props) => RiskLevel[Number(props.getValue())],
  }),
  createColumn<SnapshotOutput>('volumeAwsId', {
    header: 'Volume ID',
  }),
  createColumn<SnapshotOutput>('accountId', {
    header: 'Account ID',
  }),
  createColumn<SnapshotOutput>('region', {
    header: 'Region',
    cell: (props) => LangHelper.getAwsRegionSingleTranslation(props.getValue()),
  }),
]
