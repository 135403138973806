/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Asset } from 'blues-corejs/dist'
import { EntropyTrendsDetailsGraph } from './entropy-trends-details-graph'
import { EntropyTrendsDetailsTable } from './entropy-trends-details-table'
import { EntropyTrendsDetailsFilters } from './entropy-trends-details-filters'
import { EntropyTrendsScanResultSelection } from './entropy-trends-scan-result-selection'
import { useEntropyTrendsDetailsFilterSubscription } from './entropy-trends-details-store'
import Paper from '@mui/material/Paper'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Nullable } from '@lib/engine-types'
import { Anomaly } from '@lib/models/anomalies'
import {
  useEntropyFiltersActions,
  useGetEntropyTrendsDetailsStoreTableData,
  useGetIsDataLoading,
  useGetEntropyTrendsDetailsStoreFilters,
} from '@features/asset-page/more-details/more-details-modal/entropy-trends-details/entropy-trends-details-store/entropy-trends-details-store.context'
import { buildTimeRangeForRequest } from '@features/DashboardV4/entropy-trends/utils'

interface Props {
  asset: Asset
}

export function EntropyTrendsDetails({ asset }: Props) {
  const { fetchInitialEntropyTrendsHistory } = useEntropyFiltersActions()

  const [selectedAnomaly, setSelectedAnomaly] =
    useState<Nullable<Anomaly>>(null)

  const isDataLoading = useGetIsDataLoading()

  const filters = useGetEntropyTrendsDetailsStoreFilters()

  const timeRangeForRequest = buildTimeRangeForRequest(
    filters.timeSelection,
    filters.startAt,
    filters.endAt
  )

  const anomaliesList = useGetEntropyTrendsDetailsStoreTableData()

  const shouldRenderScanResults = !isDataLoading && anomaliesList.length > 0

  const filteredAnomaliesListBySelectedAnomaly = anomaliesList.filter(
    (anomaly) => anomaly.id === selectedAnomaly?.id
  )

  const handleClickOnAnomaly = (anomaly: Nullable<Anomaly>) => {
    if (!anomaly) {
      setSelectedAnomaly(null)
      return
    }

    setSelectedAnomaly(anomaly)
  }

  useEntropyTrendsDetailsFilterSubscription()

  useDeepCompareEffect(() => {
    if (anomaliesList.length > 0) {
      setSelectedAnomaly(anomaliesList[0])
    }
  }, [anomaliesList])

  useEffect(() => {
    fetchInitialEntropyTrendsHistory({
      ...filters,
      startAt: timeRangeForRequest.startDate,
      endAt: timeRangeForRequest.endDate,
      timeGranularity: timeRangeForRequest.timeGranularity,
    })
  }, [])

  return (
    <Stack>
      <Typography fontSize="14px" color="#1B1F26BF">
        Detects potential internal threats by identifying newly encrypted or
        suspiciously modified files across your network. This behavioral model
        analyzes file changes between scans, flagging files that show signs of
        encryption and includes file type analysis to help safeguard your
        company's data.
      </Typography>
      <EntropyTrendsDetailsGraph />
      <Grid container>
        <Grid item xs={12}>
          <EntropyTrendsDetailsFilters
            asset={asset}
            selectedAnomaly={selectedAnomaly}
          />
        </Grid>
        <Grid item container xs={12} marginTop="16px" gap="16px">
          {shouldRenderScanResults && (
            <Grid item xs={2.5} padding="4px">
              <EntropyTrendsScanResultSelection
                isLoading={isDataLoading}
                anomaliesList={anomaliesList}
                onAnomalyClick={handleClickOnAnomaly}
                selectedAnomaly={selectedAnomaly}
              />
            </Grid>
          )}
          <Grid item xs component={Paper}>
            <EntropyTrendsDetailsTable
              anomaliesList={filteredAnomaliesListBySelectedAnomaly}
              asset={asset}
            />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}
