abstract class ArrHelper {
  public static generateArrayWithCallback = <T>(
    length: number,
    callback: (index: number) => T
  ): Array<T> => Array.from({ length }, (_, index) => callback(index))
}

export default ArrHelper

export const generateIncSequence = (
  from: number,
  to: number
): Array<number> => {
  const result = []
  for (let i = from; i < to; ++i) {
    result.push(i)
  }
  return result
}
export const flatDeep = <T>(arr: Array<any>, d = 1): Array<T> => {
  return d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
        []
      )
    : arr.slice()
}

function uniqBy<T>(array: Array<T>, keyFn: (item: T) => unknown): Array<T> {
  const seen = new Set()
  return array.filter((item) => {
    const key = keyFn(item)
    if (seen.has(key)) {
      return false
    }
    seen.add(key)
    return true
  })
}

// TypeScript-specific uniqueBy function
export const uniqueBy = <T>(arr: Array<T>, key: keyof T): Array<T> => {
  return uniqBy(arr, (item) => item[key])
}
