import React, { memo } from 'react'
import ValueInterface from '@lib/interfaces/value.interface'
import { EngineCallback } from '@lib/engine-types'
import ObjHelper from '@lib/helpers/obj.helper'
import ValueHelper from '@lib/helpers/value.helper'
import SelectArrowIcon from '@inline-img/inputs/select-arrow-icon'
import { MenuItem, Select } from '@mui/material'

interface Props {
  data: ValueInterface
  disabled?: boolean
  onChange: EngineCallback<ValueInterface>
  typeFunction?: EngineCallback<any>
  className?: string
}

function SimpleSelect({
  data,
  disabled = false,
  onChange,
  typeFunction,
  className = '',
}: Props) {
  const onChangeInner = (e: any) => {
    const newData: ValueInterface = ObjHelper.cloneDeep(data)
    newData.value = e.target.value
    if (typeFunction) {
      newData.value = typeFunction(newData.value)
    }
    onChange(newData)
  }
  const selected = ValueHelper.getSelectedOption(data)
  if (!selected) {
    return null
  }
  return (
    <div
      className={`wrap-1608289511537 jsSimpleSelect SimpleSelect ${className}`}
    >
      <Select
        disabled={disabled}
        value={data.value}
        variant="outlined"
        data-testid="jsSimpleSelect"
        onChange={onChangeInner}
        IconComponent={() => (
          <SelectArrowIcon className="MuiSvgIcon-root MuiSelect-icon" />
        )}
      >
        {data.options?.map((option) => (
          <MenuItem
            key={option.name}
            data-testid="menuItem"
            value={String(option.value)}
            className={`${option.extraValue}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default memo(SimpleSelect)
