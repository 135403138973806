import { useNavigate } from 'react-router-dom'
import { reportOptions } from 'ui-v2/src/lib/constants/reports.constant'
import {
  FormOptionValueSlug,
  ScheduleTableRow,
} from 'ui-v2/src/lib/models/reports'
import { Typography } from '@mui/material'

const ScheduleNamection = ({ row }: ScheduleNamectionProps) => {
  const navigate = useNavigate()

  const handleReportDetails = () => {
    const reportOption = reportOptions.find(
      (option) => option.value === row.reportTypeKey
    )
    if (reportOption?.slug) {
      navigate(
        `/reports/schedules/details/${FormOptionValueSlug.SCHEDULE}/${reportOption.slug}/${row.id}`
      )
    }
  }

  return (
    <Typography
      variant="body2"
      color="primary"
      onClick={handleReportDetails}
      style={{ cursor: 'pointer' }}
    >
      {row.name}
    </Typography>
  )
}
interface ScheduleNamectionProps {
  row: ScheduleTableRow
}
export default ScheduleNamection
