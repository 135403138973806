/* eslint-disable import/no-extraneous-dependencies */
import { ColumnWidth, createDisplayColumn, RowHeight } from '@components/table'
import { ScansRun } from 'blues-corejs/dist/models'
import {
  AssetItem,
  EFS,
  GenericHost,
  S3Bucket,
  Stream,
  Threat,
} from 'blues-corejs/dist'
import React from 'react'
import {
  AssetItemRecoveryPointStatusCell,
  AssetItemScanCell,
  AssetItemScanCompletionTimeCell,
  AssetItemScanThreatsCell,
} from './cells'

const TIME_COLUMN_WIDTH = '120'

type Asset = S3Bucket | EFS | GenericHost

export function getAssetItemScansColumnDefinitions({
  onCompletionTimeClick,
  assetItem,
}: {
  onCompletionTimeClick: (scan: ScansRun) => void
  assetItem: AssetItem | Asset
}) {
  const isStreamAssetItem = assetItem instanceof Stream

  const shouldHideRisksColumn = isStreamAssetItem

  const completionTimeColumnWidth = shouldHideRisksColumn
    ? 'auto'
    : TIME_COLUMN_WIDTH

  return [
    createDisplayColumn<{
      scans: ScansRun
      threats: Array<Threat>
    }>({
      id: 'status',
      enableSorting: false,
      meta: {
        width: '40',
        height: RowHeight.SMALL,
      },
      cell: ({ row }) => (
        <AssetItemRecoveryPointStatusCell scans={row.original.scans} />
      ),
    }),
    createDisplayColumn<{
      scans: ScansRun
      threats: Array<Threat>
    }>({
      enableSorting: false,
      header: 'Risks',
      meta: {
        width: '100',
        height: RowHeight.SMALL,
        hidden: shouldHideRisksColumn,
      },
      cell: ({ row: { original } }) => {
        return <AssetItemScanCell scans={original.scans} />
      },
    }),
    createDisplayColumn<{
      scans: ScansRun
      threats: Array<Threat>
    }>({
      header: 'Completion Time',
      meta: {
        width: completionTimeColumnWidth,
      },
      cell: ({ row }) => {
        const onTimestampClick = () => {
          onCompletionTimeClick(row.original.scans)
        }

        return (
          <AssetItemScanCompletionTimeCell
            scans={row.original.scans}
            onTimestampClick={onTimestampClick}
          />
        )
      },
    }),
    createDisplayColumn<{
      scans: ScansRun
      threats: Array<Threat>
    }>({
      header: 'Threats',
      meta: {
        size: ColumnWidth.MEDIUM,
        hidden: shouldHideRisksColumn,
      },
      cell: ({ row }) => {
        return <AssetItemScanThreatsCell threats={row.original.threats} />
      },
    }),
  ]
}
