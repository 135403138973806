/* eslint-disable import/no-extraneous-dependencies */
import { useLayoutContext } from '@features/contexts'
import { titlesForBreadcrumb } from '@features/DynamicBreadcrumbs'
import { PagePathConstant } from '@lib/constants'
import { Nullable } from '@lib/engine-types'
import { PageHelper } from '@lib/helpers'
import { EBS, ElastioRecoveryPoint } from 'blues-corejs/dist'
import { useEffect } from 'react'

interface Props {
  volume?: EBS
  backup?: Nullable<ElastioRecoveryPoint>
}

export function useBreadcrumbsForMount({ volume, backup }: Props) {
  const { setBreadcrumbsPaths, setCurrentCrumbTitle } = useLayoutContext()

  useEffect(() => {
    if (!volume) {
      return
    }

    if (backup) {
      setCurrentCrumbTitle(backup.ccRpId)
    }

    setBreadcrumbsPaths([
      {
        href: PagePathConstant.DASHBOARD,
        text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) ?? '',
      },
      {
        href: PagePathConstant.EBS_INVENTORY_TABLE,
        text: titlesForBreadcrumb(PagePathConstant.EBS_INVENTORY_TABLE) ?? '',
      },
      {
        href: PageHelper.buildAssetPageUrl(volume.id),
        text: volume.awsId,
      },
    ])
  }, [JSON.stringify(volume), JSON.stringify(backup)])
}
