import React from 'react'
import Popover from '@mui/material/Popover'

interface Props {
  open: boolean
  anchorEl: HTMLElement | null
  handlePopoverClose: () => void
}

const POPOVER_HORIZONTAL_OFFSET = 150

const POPPER_STYLES = {
  pointerEvents: 'none',
  marginTop: '5px',
  marginRight: '20px',
} as const

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'left',
} as const

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: POPOVER_HORIZONTAL_OFFSET,
} as const

export function PopoverComponent({
  open,
  anchorEl,
  handlePopoverClose,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <Popover
      id="mouse-over-popover"
      sx={POPPER_STYLES}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={ANCHOR_ORIGIN}
      transformOrigin={TRANSFORM_ORIGIN}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      {children}
    </Popover>
  )
}
