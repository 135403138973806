import {
  DateRangeTuple,
  DefinedDateRangeTuple,
} from 'ui-v2/src/lib/engine-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

interface TimeRangeInSeconds {
  start: number
  end: number
}

const DateFormat = 'YYYY-MM-DD HH:mm:ss'

const MILLISECONDS_IN_SECOND = 1000
function convertMillisecondsToSeconds(
  milliseconds: number
): number | undefined {
  if (isFinite(milliseconds)) {
    return Math.floor(milliseconds / MILLISECONDS_IN_SECOND)
  }
}

const transformDateRangeForUTCTime = (
  dateRange: DateRangeTuple
): DefinedDateRangeTuple => {
  const inputStartDate = dayjs(dateRange[0]).startOf('day').format(DateFormat)
  const inputEndDate = dayjs(dateRange[1]).endOf('day').format(DateFormat)

  const startDate = dayjs(inputStartDate, DateFormat).utc().valueOf()
  const endDate = dayjs(inputEndDate, DateFormat).utc().valueOf()

  return [startDate, endDate]
}

export function transformDateRangeForApi(
  dateRange: DateRangeTuple
): TimeRangeInSeconds {
  const [startDate, endDate] = transformDateRangeForUTCTime(dateRange)
  return {
    start: convertMillisecondsToSeconds(startDate) ?? 0,
    end: convertMillisecondsToSeconds(endDate) ?? 0,
  }
}
