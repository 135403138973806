import { useQuery } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { OvaStatistics } from 'ui-v2/src/lib/clients'
import { QUERY_REFETCH_INTERVAL } from 'ui-v2/src/lib/constants/query.constant'
import { OvaStatisticsRequestParams } from 'ui-v2/src/lib/models/ova-stats'

export function useOvaFilesScannedQuery(filters: OvaStatisticsRequestParams) {
  const ovaStatisticsClient = useGrpcClient(OvaStatistics)

  return useQuery({
    queryKey: ['ova-statistics', 'files-scanned', filters],
    queryFn: async () => {
      if (!ovaStatisticsClient) {
        throw new Error('Ova stats client not initialized')
      }
      return ovaStatisticsClient.getFilesScannedStatistics(filters)
    },
    enabled: !!ovaStatisticsClient,
    refetchInterval: QUERY_REFETCH_INTERVAL,
  })
}
