import { Anomaly } from '@lib/models/anomalies'
import { DetectedAnomalyTable } from './entropy-trends-columns-definition'

export function mapAnomaliesListToTableDefinitions(
  anomaliesList: Array<Anomaly>
): Array<DetectedAnomalyTable> {
  return anomaliesList
    .reduce((acc: Array<DetectedAnomalyTable>, anomaly: Anomaly) => {
      if (!anomaly.assetId) {
        return acc
      }
      const paths = anomaly.findings.map((finding) => finding.path)

      return [
        {
          assetId: anomaly.assetId,
          detectedAt: anomaly.createdAt,
          numberOfFindings: anomaly.totalNumberOfFiles,
          path: paths.join(', '),
        },
        ...acc,
      ]
    }, [] as Array<DetectedAnomalyTable>)
    .sort((a, b) => b.detectedAt.getTime() - a.detectedAt.getTime())
}

export function getAssetIdFromAnomalies(
  anomalies: Array<Anomaly>
): Array<string> {
  return anomalies
    .map((anomaly) => anomaly.assetId)
    .filter(Boolean) as Array<string>
}
