/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetItem,
  Backup,
  EFS,
  GenericHost,
  Nullable,
  S3Bucket,
} from 'blues-corejs/dist'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import React, { useEffect, useMemo, useState } from 'react'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import { MaterialTab } from '@components-simple/material-tab'
import {
  useAssetBackupsDataFetcher,
  useAssetItemsForAssetDataFetcher,
  useAssetScansDataFetcher,
  useS3ItemsForAssetDataFetcher,
} from '@components-complex/dashboard-pages-v3'
import { transformDateRangeForApi } from '@components-composite/date-range-picker-with-call-api/transform-date-range-for-api'
import {
  setTimeRangeAction,
  useTimeRange,
} from '@components-context/time-range'
import { RecoveryPointDetails } from './modals/recovery-point-details'
import { ScansTable } from '@features/DashboardV4/scans-table'
import { ScansRun } from 'blues-corejs/dist/models'
import { ScanDetailsModal } from './modals/scan-details-modal'
import {
  AssetItemsTable,
  RecoveryPointsTable,
  ReplicatedRecoveryPointsTable,
  ScanItemsTable,
} from './tables'
import { AssetItemBackupsModal } from './modals'
import { useEfsItemsForAssetDataFetcher } from '@components-complex/dashboard-pages-v3/hooks/use-fetch-efs-items'
import { TimeRange } from '@lib/clients/types'
import {
  fetchAssetStore,
  getActiveTab,
  getAsset,
  getIsAssetStoreLoading,
  resetAssetStore,
  setActiveTab,
  TabsIds,
  useAssetStore,
  useTabsStore,
} from './store'
import { PreloaderBlock } from '@components-simple/preloaders'
import { AssetJobsTable } from './tables/asset-jobs-table'
import {
  AssetItemsTab,
  BackupsTab,
  ReplicatedBackupsTab,
  ScansTab,
  DetailsTab,
  EntropyTabs,
} from './tabs'
import { getScansGrpcConfig, useAssetBreadcrumbs } from './breadcrumbs'
import { KEYS, useAppStoreActions } from '@lib/zustand/use-page-store'
import styles from './asset-page.module.css'
import { isAssetCanAccessToAssetItemsModal } from './determine-asset-components-access'
import { SimpleEmptyChart } from '@features/common/entropy-graph'
import { INTERVAL_REQUEST_TIMEOUT } from '@lib/constants/grpc/interval'
import { ThreatSummaryDetails } from '@features/asset-page/more-details/more-details-modal/threats-summary-details'
import { AssetSummary } from '@features/asset-page/more-details/more-details-components/asset-summary'
import { EntropyForAsset } from '@features/asset-page/more-details/more-details-modal/more-details-with-entropy-trends-dialog'
import { getShouldRenderEntropyTrendsDialog } from './utils'
import { useIsEntropyTrendsEnabled } from '@lib/hooks/entropy-trends'
import { useParams } from 'react-router-dom'

export function AssetPage() {
  const { setValue: setAssetInStore, resetValue: resetAssetInStore } =
    useAppStoreActions()

  const currentAsset = useAssetStore(getAsset)

  const currentAssetCanAccessToAssetItemsModal =
    isAssetCanAccessToAssetItemsModal(currentAsset)

  const isAssetStoreLoading = useAssetStore(getIsAssetStoreLoading)

  const activeTab = useTabsStore(getActiveTab)
  const { id: assetId } = useParams()

  useAssetBreadcrumbs(currentAsset)

  const {
    state: { timeRange },
    dispatch: timeRangeDispatch,
  } = useTimeRange()

  const backupGrpcConfig = useMemo(
    () =>
      currentAsset
        ? {
            assetIdList: [currentAsset.id],
            timeRange,
          }
        : {},
    [currentAsset, timeRange]
  )

  const assetBackups = useAssetBackupsDataFetcher(backupGrpcConfig)

  const assetReplicatedBackups = useAssetBackupsDataFetcher({
    ...backupGrpcConfig,
    replicatedList: [true],
  })

  const assetScans = useAssetScansDataFetcher(
    currentAsset
      ? {
          ...getScansGrpcConfig(currentAsset),
          timeRange,
        }
      : {}
  )

  const assetItemsData = useAssetItemsForAssetDataFetcher(
    currentAsset ? { assetIdList: [currentAsset.id] } : {}
  )

  const s3ItemsData = useS3ItemsForAssetDataFetcher(
    currentAsset && currentAsset instanceof S3Bucket
      ? { assetIdList: [currentAsset.id] }
      : {}
  )

  const efsItemsData = useEfsItemsForAssetDataFetcher(
    currentAsset && currentAsset instanceof EFS
      ? { assetIdList: [currentAsset.id] }
      : {}
  )

  const getAssetItemsFetcher = () => {
    if (!currentAsset) {
      return {
        onFetchData: async () => {},
      }
    }

    if (currentAsset instanceof S3Bucket) {
      return s3ItemsData
    }

    if (currentAsset instanceof EFS) {
      return efsItemsData
    }

    return assetItemsData
  }

  const { isEntropyEnabled } = useIsEntropyTrendsEnabled()
  const shouldRenderEntropyTrends = getShouldRenderEntropyTrendsDialog({
    asset: currentAsset,
    isEntropyEnabled,
  })

  useEffect(() => {
    if (!assetId) {
      return
    }

    fetchAssetStore(assetId as string)

    getAssetItemsFetcher().onFetchData()

    const fetchAssetStoreInterval = setInterval(() => {
      fetchAssetStore(assetId as string, false)
    }, INTERVAL_REQUEST_TIMEOUT)

    return () => {
      clearInterval(fetchAssetStoreInterval)
      resetAssetStore()
    }
  }, [assetId])

  const getFetchedAssetItems = () => {
    if (currentAsset instanceof S3Bucket) {
      return s3ItemsData.data
    }

    if (currentAsset instanceof EFS) {
      return efsItemsData.data
    }

    return [
      ...assetItemsData.data.disksList,
      ...assetItemsData.data.filesList,
      ...assetItemsData.data.volumesList,
      ...assetItemsData.data.streamsList,
    ]
  }

  const fetchS3andEfsAssetItemsOnRefreshInterval = () => {
    if (currentAsset instanceof S3Bucket) {
      s3ItemsData.fetchOnRefreshInterval()
    }

    if (currentAsset instanceof EFS) {
      efsItemsData.fetchOnRefreshInterval()
    }
  }

  const isAssetItemsFetching = () => {
    if (currentAsset instanceof S3Bucket) {
      return s3ItemsData.isLoadingData()
    }

    if (currentAsset instanceof EFS) {
      return efsItemsData.isLoadingData()
    }

    return assetItemsData.isLoadingData()
  }

  const [selectedBackup, setSelectedBackup] = useState<Nullable<Backup>>(null)

  const [selectedScans, setSelectedScans] = useState<Nullable<ScansRun>>(null)
  const [selectedAssetItem, setSelectedAssetItem] =
    useState<Nullable<AssetItem>>(null)

  const handleBackupClick = (backup: Backup) => {
    setSelectedBackup(backup)
  }

  const handleScansClick = (scans: ScansRun) => {
    setSelectedScans(scans)
  }

  const handleAssetBackupDateRangeChange = (currentTimeRange: TimeRange) => {
    assetBackups.fetchInitial({ timeRange: currentTimeRange })
  }

  const handleAssetScansDateRangeChange = (currentTimeRange: TimeRange) => {
    assetScans.fetchInitial({ timeRange: currentTimeRange })
  }

  function handleTabChange(tab: TabsIds) {
    setActiveTab(tab)
    timeRangeDispatch(
      setTimeRangeAction(transformDateRangeForApi([null, null]))
    )
    if (tab === TabsIds.RECOVERY_POINTS) {
      assetBackups.fetchInitial()
    }
    if (tab === TabsIds.SCANS) {
      assetScans.fetchInitial()
    }

    if (tab === TabsIds.REPLICATED_RECOVERY_POINTS) {
      assetReplicatedBackups.fetchInitial()
    }
  }

  useEffect(() => {
    setAssetInStore(KEYS.currentAsset, currentAsset)
    setActiveTab(TabsIds.DETAILS)
    return () => resetAssetInStore(KEYS.currentAsset)
  }, [currentAsset?.id])

  const shouldRenderAssetItemScansTable =
    currentAsset instanceof EFS ||
    currentAsset instanceof S3Bucket ||
    currentAsset instanceof GenericHost

  if (isAssetStoreLoading) {
    return <PreloaderBlock show />
  }

  if (!currentAsset) {
    return null
  }

  return (
    <Paper className={styles.root}>
      <SimpleEmptyChart />
      {currentAssetCanAccessToAssetItemsModal && selectedAssetItem && (
        <AssetItemBackupsModal
          assetItem={selectedAssetItem}
          asset={currentAsset}
          isOpen={Boolean(selectedAssetItem)}
          onClose={() => setSelectedAssetItem(null)}
        />
      )}
      {selectedBackup && (
        <RecoveryPointDetails
          asset={currentAsset}
          backup={selectedBackup}
          isRecoveryPointDetailsOpen={Boolean(selectedBackup)}
          onCloseRecoveryPointDetails={() => setSelectedBackup(null)}
        />
      )}
      {selectedScans && (
        <ScanDetailsModal
          // @ts-ignore
          asset={currentAsset}
          isOpen={Boolean(selectedScans)}
          onClose={() => setSelectedScans(null)}
          scans={[
            ...selectedScans.ransomwareScansList,
            ...selectedScans.malwareScansList,
            ...selectedScans.filesystemChecksList,
          ]}
        />
      )}
      <CustomTabs className={styles['asset-page-tabs']} value={activeTab}>
        <DetailsTab onTabChange={handleTabChange} asset={currentAsset} />
        <AssetItemsTab onTabChange={handleTabChange} />
        <BackupsTab onTabChange={handleTabChange} />
        <ReplicatedBackupsTab onTabChange={handleTabChange} />
        <ScansTab onTabChange={handleTabChange} />
        {shouldRenderEntropyTrends && (
          <EntropyTabs onTabChange={handleTabChange} />
        )}
        <Tab
          tabIndex={TabsIds.JOBS}
          label="Jobs"
          value={TabsIds.JOBS}
          onClick={() => handleTabChange(TabsIds.JOBS)}
        />
      </CustomTabs>
      <MaterialTab value={activeTab} index={TabsIds.DETAILS}>
        <AssetSummary asset={currentAsset} />
        <ThreatSummaryDetails asset={currentAsset} />
      </MaterialTab>

      <MaterialTab value={activeTab} index={TabsIds.ASSET_ITEMS}>
        <AssetItemsTable
          assetItems={[
            ...assetItemsData.data.disksList,
            ...assetItemsData.data.filesList,
            ...assetItemsData.data.volumesList,
            ...assetItemsData.data.streamsList,
          ]}
          isLoading={assetItemsData.isLoadingData() || isAssetItemsFetching()}
          onScrollEnd={assetItemsData.onFetchData}
          filterChangeHandler={assetItemsData.fetchInitial}
          onAssetItemClick={(assetItem) => setSelectedAssetItem(assetItem)}
          fetchOnRefreshInterval={assetItemsData.fetchOnRefreshInterval}
        />
      </MaterialTab>
      <MaterialTab value={activeTab} index={TabsIds.RECOVERY_POINTS}>
        <RecoveryPointsTable
          asset={currentAsset}
          onDateRangeSelect={handleAssetBackupDateRangeChange}
          backups={assetBackups.data}
          onScrollEnd={assetBackups.onFetchData}
          onFetchInitial={assetBackups.fetchInitial}
          onBackupClick={handleBackupClick}
          isLoading={assetBackups.isLoadingData()}
          fetchOnRefreshInterval={assetBackups.fetchOnRefreshInterval}
        />
      </MaterialTab>
      <MaterialTab value={activeTab} index={TabsIds.REPLICATED_RECOVERY_POINTS}>
        <ReplicatedRecoveryPointsTable
          asset={currentAsset}
          onDateRangeSelect={handleAssetBackupDateRangeChange}
          backups={assetReplicatedBackups.data}
          onScrollEnd={assetReplicatedBackups.onFetchData}
          onFetchInitial={assetReplicatedBackups.fetchInitial}
          onBackupClick={handleBackupClick}
          isLoading={assetReplicatedBackups.isLoadingData()}
          fetchOnRefreshInterval={assetReplicatedBackups.fetchOnRefreshInterval}
        />
      </MaterialTab>
      <MaterialTab value={activeTab} index={TabsIds.SCANS}>
        {!shouldRenderAssetItemScansTable && (
          <ScansTable
            isLoading={assetScans.isLoadingData()}
            scans={assetScans.data.allScans}
            onFetchInitial={assetScans.fetchInitial}
            onScansClick={handleScansClick}
            onScrollEnd={assetScans.onFetchData}
            fetchOnRefreshInterval={assetScans.fetchOnRefreshInterval}
            onDateRangeSelect={handleAssetScansDateRangeChange}
          />
        )}
        {shouldRenderAssetItemScansTable && (
          <ScanItemsTable
            isLoading={isAssetItemsFetching()}
            asset={currentAsset}
            items={getFetchedAssetItems()}
            onScrollEnd={getAssetItemsFetcher().onFetchData}
            fetchOnRefreshInterval={fetchS3andEfsAssetItemsOnRefreshInterval}
          />
        )}
      </MaterialTab>
      {shouldRenderEntropyTrends && (
        <MaterialTab value={activeTab} index={TabsIds.ENTROPY}>
          <EntropyForAsset asset={currentAsset} />
        </MaterialTab>
      )}

      <MaterialTab value={activeTab} index={TabsIds.JOBS}>
        <AssetJobsTable asset={currentAsset} />
      </MaterialTab>
    </Paper>
  )
}
