// eslint-disable-next-line import/no-extraneous-dependencies
import { ReportSchedule } from 'blues-corejs/dist/models/reports/report-schedule'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Weekly,
  EveryWeek,
  DayOfWeek,
  OnDate,
  EveryMonth,
  Never,
  Schedule,
  EveryDay,
  Custom,
  Monthly,
  Daily,
  AfterOccurrences,
  ExpirationMode,
  CustomScheduleRepeatMode,
  NaiveDate,
} from 'blues-corejs/dist/models/reports/report-schedule/schedule'
import {
  ListReportSchedulesResponse,
  ListReportSchedulesRequest,
  ReportSchedule as ReportSchedulePb,
  Weekly as WeeklyPb,
  ExpirationMode as ExpirationModePb,
  DayOfWeek as DayOfWeekPb,
  Schedule as SchedulePb,
  Custom as CustomPb,
  Monthly as MonthlyPb,
  CustomScheduleRepeatMode as CustomScheduleRepeatModePb,
} from '@lib/clients/grpc-imports'
import TimeHelper from '@lib/helpers/time.helper'

export function mapDayOfWeek(day: DayOfWeekPb): DayOfWeek {
  const mapping = {
    [DayOfWeekPb.DAY_OF_WEEK_MONDAY]: DayOfWeek.MONDAY,
    [DayOfWeekPb.DAY_OF_WEEK_TUESDAY]: DayOfWeek.TUESDAY,
    [DayOfWeekPb.DAY_OF_WEEK_WEDNESDAY]: DayOfWeek.WEDNESDAY,
    [DayOfWeekPb.DAY_OF_WEEK_THURSDAY]: DayOfWeek.THURSDAY,
    [DayOfWeekPb.DAY_OF_WEEK_FRIDAY]: DayOfWeek.FRIDAY,
    [DayOfWeekPb.DAY_OF_WEEK_SATURDAY]: DayOfWeek.SATURDAY,
    [DayOfWeekPb.DAY_OF_WEEK_SUNDAY]: DayOfWeek.SUNDAY,
  }

  return mapping[day]
}

function transformCustomScheduleRepeatMode(
  mode: CustomScheduleRepeatModePb
): CustomScheduleRepeatMode {
  switch (mode.getRepeatModeCase()) {
    case CustomScheduleRepeatModePb.RepeatModeCase.EVERY_DAY:
      const stride = mode.getEveryDay()?.getStride()
      return new EveryDay(stride as number)

    case CustomScheduleRepeatModePb.RepeatModeCase.EVERY_WEEK:
      const everyWeek = mode.getEveryWeek()
      return new EveryWeek(
        everyWeek?.getStride() as number,
        mapDayOfWeek(everyWeek?.getDay() as number)
      )

    case CustomScheduleRepeatModePb.RepeatModeCase.EVERY_MONTH:
      const everyMonth = mode.getEveryMonth()
      return new EveryMonth(
        everyMonth?.getStride() as number,
        everyMonth?.getDayOfMonth() as number
      )

    default:
      throw new Error('Unknown repeat mode')
  }
}

function transformCustomExpirationMode(
  expirationMode: ExpirationModePb
): ExpirationMode {
  switch (expirationMode.getModeCase()) {
    case ExpirationModePb.ModeCase.NEVER:
      return new Never()

    case ExpirationModePb.ModeCase.ON_DATE:
      const date = expirationMode.getOnDate()
      return new OnDate({
        year: date?.getYear() as number,
        day: date?.getDay() as number,
        month: date?.getMonth() as number,
      })

    case ExpirationModePb.ModeCase.AFTER_OCCURRENCES:
      return new AfterOccurrences(expirationMode.getAfterOccurrences())

    default:
      throw new Error('Unknown expiration mode')
  }
}

function transformCustomMode(customMode: CustomPb): Custom {
  const repeatMode = transformCustomScheduleRepeatMode(
    customMode.getRepeatMode() as CustomScheduleRepeatModePb
  )
  const expirationMode = transformCustomExpirationMode(
    customMode.getExpirationMode() as ExpirationModePb
  )

  return new Custom({
    repeatMode,
    expirationMode,
  })
}

function transformDaily(): Daily {
  return new Daily()
}
function transformWeekly(weekly: WeeklyPb): Weekly {
  return new Weekly(mapDayOfWeek(weekly.getDay() as DayOfWeekPb))
}
function transformMonthly(monthly: MonthlyPb): Monthly {
  return new Monthly(monthly.getDayOfMonth())
}

function transformScheduleMode(schedule: SchedulePb) {
  switch (schedule.getModeCase()) {
    case SchedulePb.ModeCase.DAILY:
      return transformDaily()

    case SchedulePb.ModeCase.WEEKLY:
      return transformWeekly(schedule.getWeekly() as WeeklyPb)

    case SchedulePb.ModeCase.MONTHLY:
      return transformMonthly(schedule.getMonthly() as MonthlyPb)

    case SchedulePb.ModeCase.CUSTOM:
      return transformCustomMode(schedule.getCustom() as CustomPb)

    default:
      throw new Error(`Unhandled schedule mode: ${schedule.getModeCase()}`)
  }
}

function transformSchedule(schedule: SchedulePb): Schedule {
  const datePb = schedule.getStartsOn()
  const date: NaiveDate = {
    day: datePb?.getDay() as number,
    month: datePb?.getMonth() as number,
    year: datePb?.getYear() as number,
  }
  return new Schedule(date, transformScheduleMode(schedule) as any)
}

export function buildListReportSchedulesRequest(): ListReportSchedulesRequest {
  const request = new ListReportSchedulesRequest()

  return request
}

function transformReportKindWithParams(obj: any) {
  const result = {}

  const keyRenamingMap = {
    awsExecutive: 'awsExecutiveReportParams',
    awsAccountSummary: 'awsAccountSummaryReportParams',
    awsTenantSummary: 'awsTenantSummaryReportParams',
    awsCompletedScans: 'awsCompletedScansReportParams',
    awsScannedAssetsSummary: 'awsScannedAssetsSummaryReportParams',
    infectedFiles: 'infectedFilesReportParams',
    failedScans: 'failedScansReportParams',
    awsInfectedAssets: 'awsInfectedAssetsReportParams',
    ovaMspOperational: 'ovaMspOperationalReportParams',
    ovaMspExecutive: 'ovaMspExecutiveReportParams',
    ovaMspTenantSummary: 'ovaMspTenantSummaryReportParams',
    ovaMspAccountSummary: 'ovaMspAccountSummaryReportParams',
    ovaMspCompletedScans: 'ovaMspCompletedScansReportParams',
    ovaMspScannedAssetsSummary: 'ovaMspScannedAssetsSummaryReportParams',
    ovaMspInfectedAssets: 'ovaMspInfectedAssetsReportParams',
    ovaMspInfectedFiles: 'ovaMspInfectedFilesReportParams',
    ovaMspFailedScans: 'ovaMspFailedScansReportParams',
  }

  for (const key in obj) {
    const value = obj[key]

    if (value === undefined) {
      continue
    }

    // @ts-ignore
    const newKey = keyRenamingMap[key] || key

    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        // @ts-ignore
        result[newKey] = value
      } else if (Object.keys(value).length === 0) {
        // @ts-ignore
        result[newKey] = newKey
      } else {
        const transformedValue = transformReportKindWithParams(value)

        if (Object.keys(transformedValue).length > 0) {
          // @ts-ignore
          result[newKey] = transformedValue
        }
      }
    } else {
      // @ts-ignore
      result[newKey] = value
    }
  }

  return result
}

export function convertReportSchedule(
  reportSchedule: ReportSchedulePb
): ReportSchedule {
  return new ReportSchedule({
    id: reportSchedule.getId(),
    createdAt: TimeHelper.secondsToDate(
      reportSchedule.getCreatedAt()?.getSeconds()
    ),
    description: reportSchedule.getDescription(),
    // @ts-ignore
    lastReportGenerationCompletedAt: TimeHelper.maybeSecondsToDate(
      reportSchedule.getLastReportGenerationCompletedAt()?.getSeconds()
    ),
    // @ts-ignore
    lastReportGenerationStartedAt: TimeHelper.maybeSecondsToDate(
      reportSchedule.getLastReportGenerationCompletedAt()?.getSeconds()
    ),
    name: reportSchedule.getName(),
    nextReportGenerationAt: TimeHelper.secondsToDate(
      reportSchedule.getNextReportGenerationAt()?.getSeconds()
    ),
    recipientIds: reportSchedule.getRecipientIdsList(),
    // @ts-ignore
    reportKindWithParams: transformReportKindWithParams(
      reportSchedule.getReportKindWithParams()?.toObject()
    ),

    schedule: transformSchedule(reportSchedule.getSchedule() as SchedulePb),
    tenantName: reportSchedule.getTenantName(),
    timezone: reportSchedule.getTimezone(),
    updatedAt: TimeHelper.secondsToDate(
      reportSchedule.getUpdatedAt()?.getSeconds()
    ),
  })
}

export function convertReportSchedulesList(
  response: ListReportSchedulesResponse
): Array<ReportSchedule> {
  return response.getReportSchedulesList().map(convertReportSchedule)
}
