import FormEditVaultInterface from '@lib/interfaces/form/form-edit-vault.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormEditVaultInterface,
  FormFieldInterface
> => ({
  description: {
    name: 'description',
    label: 'Vault Description',
    value: '',
    validationRules: {},
  },
  vpc: {
    name: 'vpc',
    label: 'Select VPC',
    value: '',
    validationRules: {
      required: true,
    },
  },
  selectedSub: {
    name: 'subnets',
    label: 'Select Subnets',
    value: '',
    validationRules: {
      required: true,
    },
  },
  vpcId: {
    name: 'vpcId',
  },
  subnetIdsList: {
    name: 'subnetIdsList',
  },
  accountId: {
    name: 'accountId',
  },
  region: {
    name: 'region',
  },
  safetyLock: {
    name: 'safetyLock',
    label: 'Safety Lock',
    value: true,
  },
  vaultName: {
    name: 'vaultName',
  },
  version: {
    name: 'version',
  },
})

export default buildDefaultFormData
