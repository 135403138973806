import React, { useEffect, useMemo, useState } from 'react'
import AccountProfile from '@components-complex/account-profile/AccountProfile'
import AccountSecurity from '@components-complex/account-security/AccountSecurity'
import ApiAccess from '@components-complex/api-access/ApiAccess'
import useRouteTabs from '@lib/hooks/useRouteTabs'
import MaterialTab from '@components-simple/material-tab/MaterialTab'
import CustomTabs from '@components-simple/custom-tab/CustomTabs'
import { Button, Tab, Box } from '@mui/material'
import AccountNotificationsComplex from '@components-complex/account-notifications-complex/AccountNotificationsComplex'
import ValueInterface from '@lib/interfaces/value.interface'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserNotificationsSettings,
  getUserNotificationsSetupUrl,
} from '@store/selectors/user-notifications.selector'
import UserNotificationsFactory from '@lib/factories/user-notifications.factory'
import {
  requestUserNotificationsSettingsShadow,
  updateUserNotificationsSettings,
} from '@store/actions/user-notifications.action'
import ToastHelper from '@lib/helpers/toast.helper'
import useIntervalIf from '@lib/hooks/useIntervalIf'
import usePreloader from '@lib/hooks/usePreloader'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { useSearchParams } from 'react-router-dom'

enum InstallationStatus {
  OK = 'ok',
  FAILED = 'failed',
  EXPIRED = 'expired',
}

function AccountAndSettings() {
  const [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  const dispatch = useDispatch()
  const userSetupUrl = useSelector(getUserNotificationsSetupUrl)
  const notificationsSettings = useSelector(getUserNotificationsSettings)

  useEffect(() => {
    switch (status) {
      case InstallationStatus.FAILED:
        ToastHelper.error('Connection to Slack failed.')
        break
      case InstallationStatus.OK:
        ToastHelper.success(
          'Connection to Slack is completed successfully. Slack authorization might take a couple of minutes.'
        )
        break
      case InstallationStatus.EXPIRED:
        ToastHelper.info('Connection to Slack expired.')
        break
      default:
        break
    }
  }, [])
  const statusQuery = status ? { status: status } : {}

  const { currentTab, onTabChange } = useRouteTabs({
    additionalQueries: statusQuery,
  })

  const notificationsLoading = usePreloader(
    PreloaderConstants.REQUEST_USER_NOTIFICATIONS_SETTINGS
  )

  const {
    errors: sendErrors,
    warnings: sendWarnings,
    info: sendInfo,
  } = UserNotificationsFactory.buildDataBySettings(
    notificationsSettings,
    !Boolean(userSetupUrl),
    notificationsLoading
  )

  const [errors, setErrors] = useState<Array<ValueInterface>>([])
  const [warnings, setWarnings] = useState<Array<ValueInterface>>([])
  const [info, setInfo] = useState<Array<ValueInterface>>([])

  const onSave = () =>
    dispatch(
      updateUserNotificationsSettings({
        slack: {
          sendErrors: !!errors[1]?.value,
          sendWarnings: !!warnings[1]?.value,
          sendInfo: !!info[0]?.value,
          isDisconnected: Boolean(userSetupUrl),
        },
        email: {
          sendErrors: !!errors[0]?.value,
          sendWarnings: !!warnings[0]?.value,
          sendInfo: false,
        },
      })
    )

  const isErrorsChanged = useMemo(
    () =>
      !!errors.filter(
        (data) =>
          !sendErrors?.some(
            (old) => old.name === data.name && old.value === data.value
          )
      ).length,
    [errors, sendErrors]
  )

  const isWarningsChanged = useMemo(
    () =>
      !!warnings.filter(
        (data) =>
          !sendWarnings?.some(
            (old) => old.name === data.name && old.value === data.value
          )
      ).length,
    [warnings, sendWarnings]
  )

  const isInfoChanged = useMemo(
    () =>
      !!info.filter(
        (data) =>
          !sendInfo?.some(
            (old) => old.name === data.name && old.value === data.value
          )
      ).length,
    [info, sendInfo]
  )

  const isDataChanged = [
    isErrorsChanged,
    isWarningsChanged,
    isInfoChanged,
  ].some((value) => value)

  useEffect(() => {
    setErrors(sendErrors || [])
    setWarnings(sendWarnings || [])
    setInfo(sendInfo || [])
  }, [JSON.stringify(notificationsSettings)])

  useIntervalIf(
    status === InstallationStatus.OK && !notificationsSettings,
    () => {
      dispatch(requestUserNotificationsSettingsShadow())
    },
    []
  )

  const showSaveBtn = currentTab === 3

  return (
    <div className="innerContent">
      <CustomTabs value={currentTab} onChangeTab={onTabChange}>
        <Tab label="Account profile" />
        <Tab label="Account security" />
        <Tab label="API access" />
        <Tab label="Alerts" />
      </CustomTabs>
      <MaterialTab value={currentTab} index={0}>
        <AccountProfile />
      </MaterialTab>
      <MaterialTab value={currentTab} index={1}>
        <AccountSecurity />
      </MaterialTab>
      <MaterialTab value={currentTab} index={2}>
        <ApiAccess />
      </MaterialTab>
      <MaterialTab value={currentTab} index={3}>
        <AccountNotificationsComplex
          errors={{
            state: errors,
            setState: setErrors,
          }}
          warnings={{
            state: warnings,
            setState: setWarnings,
          }}
          success={{
            state: info,
            setState: setInfo,
          }}
          slackUrl={userSetupUrl}
        />
      </MaterialTab>
      {showSaveBtn && (
        <Box mt={2}>
          <Button
            variant="contained"
            onClick={onSave}
            disabled={!isDataChanged}
          >
            Save
          </Button>
        </Box>
      )}
    </div>
  )
}

export default AccountAndSettings
