import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import {
  CellWithDateTimeTooltip,
  CellWithLink,
  createColumn,
  StatusCircle,
  CellLongTextWithTooltip,
  ColumnWidth,
  CopyCell,
} from '@components/table'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Threat } from 'blues-corejs/dist'
import LangHelper from '@lib/helpers/lang.helper'
import StrHelper from '@lib/helpers/str.helper'
import { Nullable } from '@lib/engine-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { S3BucketThreatsOutput } from 'blues-corejs/dist/use_cases/list_s3buckets_with_threats/types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { AliasName } from '@lib/interfaces/inventory/alias-name.interface'
import { ASSETS_THREATS_TABLE_MIN_HEIGHT } from './consts'
import { AssetRoutes } from '@lib/router'

const columnHelper = createColumnHelper<S3BucketThreatsOutput>()

type InputData = {
  aliasNamesWithId: AliasName
}

function getStatusDummyColumn() {
  return columnHelper.display({
    id: 's3Status',
    cell: () => <StatusCircle isHealthy={false} />,
  })
}

function getS3IDColumn() {
  return createColumn<S3BucketThreatsOutput>('name', {
    header: 'Bucket name',
    meta: {
      height: ASSETS_THREATS_TABLE_MIN_HEIGHT,
    },
    cell: ({ getValue, row }) => {
      const s3Name = getValue()
      const { s3Id, s3AwsId } = row.original
      const assetPageUrl = AssetRoutes.assetId(s3Id)

      return (
        <CellWithLink
          href={assetPageUrl}
          value={s3Name || s3AwsId}
          label={s3Name ? s3AwsId : ''}
        />
      )
    },
  })
}

function getThreatsColumn() {
  return createColumn<S3BucketThreatsOutput>('threats', {
    header: 'Threats',
    cell: ({ getValue }) => {
      const threats = getValue() as unknown as Array<Threat>
      const threatNames: Set<string> = new Set()
      threats.map((threat) => {
        threatNames.add(threat.name)
      })

      return (
        <CellLongTextWithTooltip
          title={[...threatNames].join(', ')}
          maxLength={60}
        />
      )
    },
    meta: {
      size: ColumnWidth.LARGE,
    },
  })
}

function getAccountIdColumn(aliasNamesWithId: AliasName) {
  return createColumn<S3BucketThreatsOutput>('accountId', {
    header: 'Account',
    cell: ({ getValue }) => {
      const className = aliasNamesWithId[getValue()] ? 'copyRow' : ''

      return (
        <CopyCell
          additionalText={aliasNamesWithId[getValue()]}
          copyText={getValue()}
          className={className}
        />
      )
    },
  })
}

function getRegionColumn() {
  return createColumn<S3BucketThreatsOutput>('region', {
    header: 'Region',
    cell: ({ getValue }) => {
      return <>{LangHelper.getAwsRegionSingleTranslation(getValue())}</>
    },
  })
}

function getStatusColumn() {
  return createColumn<S3BucketThreatsOutput>('status', {
    header: 'Status',
    cell: ({ getValue }) => {
      return (
        <>
          {StrHelper.getS3BucketStateName(
            getValue() as unknown as S3BucketState
          )}
        </>
      )
    },
  })
}

function getLastScanColumn() {
  return createColumn<S3BucketThreatsOutput>('lastScan', {
    header: 'Last scan',
    cell: ({ getValue }) => {
      const lastScan = getValue() as unknown as Nullable<Date>

      return <CellWithDateTimeTooltip date={lastScan} />
    },
  })
}

export class S3Columns {
  static getColumns({ aliasNamesWithId }: InputData) {
    return [
      getStatusDummyColumn(),
      getS3IDColumn(),
      getThreatsColumn(),
      getAccountIdColumn(aliasNamesWithId),
      getRegionColumn(),
      getLastScanColumn(),
      getStatusColumn(),
    ]
  }
}
