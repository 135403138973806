import { ScanFiltersTransformer } from './../assets/transformers'
/* eslint-disable import/no-extraneous-dependencies */
import { Request } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui/v1/scans/get_scan_filters_pb'
import { GrpcClient } from '@lib/clients/grpc-client'
import { ScansPromiseClient } from 'blue-stack-libs/blue-stack-grpc-libs/js/blue_stack/ui_api_grpc_web_pb'
import {
  AssetId,
  GetScanFiltersRequest,
  Kind,
  ScanKind,
  ScanTargetKind,
  Status,
} from './types'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

export type GetScanFiltersResponse = {
  accountIdsList: Array<string>
  regionsList: Array<string>
  scanTargetKindsList: Array<ScanTargetKind> //Array<Response.ScanTargetKind>
  humanReadableRecoveryPointIdsList: Array<string>
  assetIdList: Array<AssetId> //Array<Response.AssetId.AsObject>
  statusesList: Array<Status> //Array<Response.Status>
  kindsList: Array<Kind> //Array<Response.Kind>
}

export class GetScanFiltersClient extends GrpcClient<ScansPromiseClient> {
  #assetsClient: ScansPromiseClient

  #transformer: ScanFiltersTransformer

  constructor(hostName = '', transformer = new ScanFiltersTransformer()) {
    super()
    this.#assetsClient = this.getClient(hostName)
    this.#transformer = transformer
  }

  protected initClient(hostName: string): ScansPromiseClient {
    return new ScansPromiseClient(hostName, null, null)
  }

  protected innerClientTypeId(): string {
    return 'GetScanFiltersClient'
  }

  async getScanFilters(
    filters?: GetScanFiltersRequest
  ): Promise<GetScanFiltersResponse> {
    const request = new Request()

    if (filters?.timeRange) {
      const timeRange = new Request.TimeRange()
      if (filters?.timeRange.start) {
        timeRange.setStart(this.#createTimestamp(filters?.timeRange.start))
      }

      if (filters?.timeRange.end) {
        timeRange.setEnd(this.#createTimestamp(filters?.timeRange.end))
      }

      request.setTimeRange(timeRange)
    }

    if (filters?.scanKinds) {
      request.setScanKindsList(
        filters.scanKinds.map((kind) => this.#convertKind(kind))
      )
    }

    const response = await this.retryGrpcCall(
      () => this.#assetsClient.getScanFilters(request, this.metadata()),
      {
        requestName: 'ScansPromiseClient/getScanFilters',
      }
    )

    return this.#transformer.transformResponse(response)
  }

  #createTimestamp(seconds: number) {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }

  #convertKind(scanKind: ScanKind): Request.ScanKind {
    const mapping = {
      [ScanKind.FILE_SYSTEM_CHECK]: Request.ScanKind.KIND_FILE_SYSTEM_CHECK,
      [ScanKind.MALWARE]: Request.ScanKind.KIND_MALWARE,
      [ScanKind.RANSOMWARE]: Request.ScanKind.KIND_RANSOMWARE,
    }

    return mapping[scanKind]
  }
}
