import { DateRangeTuple, DefinedDateRangeTuple } from '@lib/engine-types'
import moment from 'moment/moment'

type TimeRangeInSeconds = {
  start: number
  end: number
}

const DateFormat = 'YYYY-MM-DD HH:mm:ss'

const MILLISECONDS_IN_SECOND = 1000
export function convertMillisecondsToSeconds(
  milliseconds: number
): number | undefined {
  if (isFinite(milliseconds)) {
    return Math.floor(milliseconds / MILLISECONDS_IN_SECOND)
  }
}

const transformDateRangeForUTCTime = (
  dateRange: DateRangeTuple
): DefinedDateRangeTuple => {
  const inputStartDate = moment(dateRange[0]).startOf('day').format(DateFormat)
  const inputEndDate = moment(dateRange[1])
    .parseZone()
    .endOf('day')
    .format(DateFormat)
  const startDate = moment(inputStartDate, DateFormat).parseZone().valueOf()
  const endDate = moment(inputEndDate, DateFormat).parseZone().valueOf()

  return [startDate, endDate]
}

export function transformDateRangeForApi(
  dateRange: DateRangeTuple
): TimeRangeInSeconds {
  const [startDate, endDate] = transformDateRangeForUTCTime(dateRange)
  return {
    start: convertMillisecondsToSeconds(startDate) ?? 0,
    end: convertMillisecondsToSeconds(endDate) ?? 0,
  }
}
