/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import {
  CellWithButtonLink,
  createDisplayColumn,
  RowHeight,
} from '@components/table'
import { EngineCallback } from '@lib/engine-types'
import { OnMenuClickData } from '@tables/core/table-handlers'
import TableMenu from '@tables/core/table-menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { AWSBRecoveryPoint, Backup, CloudConnector } from 'blues-corejs/dist'
import {
  BackupSourceCell,
  BackupTargetAccountId,
  BackupTargetRegion,
  StatusCell,
  ThreatIconsCell,
} from './cells'
import { getAvailableMenuItems } from '@features/asset-page/modals/recovery-point-details/components/recovery-point-details-header/backup-actions'

interface RecoveryPointsColumnDefinitionProps {
  cloudConnectorList: Array<CloudConnector>
  onBackupClick: EngineCallback<Backup>
  onRowMenuClick: EngineCallback<OnMenuClickData>
}

export function getRecoveryPointsTableColumnDefinitions({
  cloudConnectorList,
  onBackupClick,
  onRowMenuClick,
}: RecoveryPointsColumnDefinitionProps) {
  return [
    createDisplayColumn<Backup>({
      id: 'status',
      enableSorting: false,
      meta: {
        width: '40',
        height: RowHeight.SMALL,
      },
      cell: ({ row: { original } }) => {
        return <StatusCell status={original.summaryCheckStatus} />
      },
    }),

    createDisplayColumn<Backup>({
      id: 'scan-status',
      enableSorting: false,
      header: 'Risks',
      meta: {
        width: '100',
        height: RowHeight.SMALL,
      },
      cell: ({ row: { original } }) => {
        return <ThreatIconsCell backup={original} />
      },
    }),

    createDisplayColumn<Backup>({
      enableSorting: false,
      id: 'timestamp',
      header: 'Recovery Point Time',

      cell: ({ row: { original } }) => {
        const onRecoveryPointClick = () => {
          onBackupClick(original)
        }

        const timestamp = original.timestamp

        return (
          <CellWithButtonLink onClick={onRecoveryPointClick}>
            <DateTimeTooltip
              placement="top-start"
              date={timestamp}
              direction="row"
            />
          </CellWithButtonLink>
        )
      },
    }),

    createDisplayColumn<Backup>({
      id: 'backup-type',
      enableSorting: false,
      header: 'Backup Source',

      cell: ({ row: { original } }) => <BackupSourceCell backup={original} />,
    }),

    createDisplayColumn<Backup>({
      id: 'backup-account-id',
      enableSorting: false,
      header: 'Target Account',
      cell: ({ row: { original } }) => (
        <BackupTargetAccountId
          backup={original}
          cloudConnectorList={cloudConnectorList}
        />
      ),
    }),

    createDisplayColumn<Backup>({
      id: 'backup-target-region',
      enableSorting: false,
      header: 'Target Region',
      cell: ({ row: { original } }) => <BackupTargetRegion backup={original} />,
    }),

    createDisplayColumn<Backup>({
      id: 'backup-actions',
      enableSorting: false,
      meta: {
        width: '40',
      },
      cell: ({ row: { original: backup } }) => {
        if (!(backup instanceof AWSBRecoveryPoint)) {
          return null
        }

        const cloudConnector = cloudConnectorList.find(
          (cc) => cc.awsAccountId === backup.accountId
        )

        if (!cloudConnector || !cloudConnector.isActive) {
          return null
        }

        return (
          <TableMenu
            data={backup}
            menu={getAvailableMenuItems(backup)}
            showMenu
            onMenuClick={onRowMenuClick}
            menuIcon={<MoreVertIcon htmlColor="#1A1F28" />}
          />
        )
      },
    }),
  ]
}
