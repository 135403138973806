/* eslint-disable import/no-extraneous-dependencies */
import {
  AllKinds,
  EventKind,
} from 'blues-corejs/dist/models/notifications/rule/criteria'
import { createColumn, Table } from '@components/table'
import { Box } from '@mui/material'
import React from 'react'

interface TableDataInterface {
  kind: string
}

export const computeEventKinds = (eventKinds: EventKind) => {
  if (eventKinds instanceof AllKinds) {
    return null
  }

  const getColumns = () => {
    return [
      createColumn<TableDataInterface>('kind', {
        header: 'Events',
        enableSorting: false,
        cell: ({ cell }) => cell.getValue(),
      }),
    ]
  }

  const tableData: Array<TableDataInterface> = eventKinds.kinds.map((kind) => ({
    kind: kind,
  }))

  return (
    <Box mb={2}>
      <Table
        data={tableData}
        columns={getColumns()}
        generalProps={{
          noDataMessage: 'No event kinds found',
          isLoading: false,
        }}
      />
    </Box>
  )
}
