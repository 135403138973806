import StrHelper from '@lib/helpers/str.helper'
import { ASSET_ID_QUERY_KEY } from '@lib/constants'
import { useParams, useSearchParams } from 'react-router-dom'

interface DecodedURLData {
  assetId: string
  backupId: string
}

function useURLDataDecoder(): DecodedURLData {
  const [searchParams] = useSearchParams()
  const assetId = searchParams.get(ASSET_ID_QUERY_KEY)
  const { id: rpId } = useParams()

  return {
    assetId: StrHelper.base64Decode(assetId),
    backupId: StrHelper.base64Decode(rpId),
  }
}

export { useURLDataDecoder }
