import { Breadcrumbs } from '@mui/material'
import { useEffect, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useLayoutContext } from '@features/contexts'
import {
  CrumbsType,
  startedPaths,
  titlesForBreadcrumb,
} from '@features/DynamicBreadcrumbs'
import { Crumb } from './Crumb'
import { ObjHelper } from '@lib/helpers'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { PagePathConstant } from '@lib/constants'
import { useLocation, useParams } from 'react-router-dom'

const breadcrumbTheme = createTheme({
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '1.5',
          color: '#2F3745',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '1.5',
          color: '#727883',
        },
      },
    },
  },
})

function DynamicBreadcrumbs() {
  const router = useLocation()
  const { id: dynamicId, view } = useParams()
  const defaultCrumb: CrumbsType = {
    href: PagePathConstant.DASHBOARD,
    text: titlesForBreadcrumb(PagePathConstant.DASHBOARD) || '',
  }
  const {
    breadcrumbsPaths,
    currentCrumbTitle,
    isDataLoading,
    resetBreadCrumbs,
    setResetBreadCrumbs,
  } = useLayoutContext()
  const [defaultCrumbs, setDefaultCrumbs] = useState<Array<CrumbsType>>([
    defaultCrumb,
  ])
  const [breadcrumbs, setBreadcrumbs] = useState<Array<CrumbsType>>([])
  const crumbsForRender = breadcrumbs.length > 0 ? breadcrumbs : defaultCrumbs

  useEffect(() => {
    if (
      defaultCrumbs.length === 0 &&
      breadcrumbs.length === 0 &&
      router.pathname.split('/').length > 1
    ) {
      return
    }

    const query = router.search
    const tabQuery = query
      ? query.split('&').find((qItem) => qItem.match(/[t=]/))
      : null

    const pathname = tabQuery
      ? `${router.pathname}?${tabQuery}`
      : router.pathname

    const crumbItem: CrumbsType = {
      href: router.pathname,
      text: titlesForBreadcrumb(pathname, dynamicId, view) ?? '',
    }

    if (startedPaths.includes(router.pathname)) {
      if (router.pathname !== PagePathConstant.DASHBOARD) {
        setBreadcrumbs([defaultCrumb, crumbItem])
      } else {
        setBreadcrumbs([crumbItem])
      }

      setDefaultCrumbs([])
      return
    }

    const matchIndex = crumbsForRender.findIndex(
      (crumb) => crumb.href === router.pathname
    )

    if (matchIndex > -1) {
      const sliceIndex = matchIndex + 1
      setBreadcrumbs([...crumbsForRender.slice(0, sliceIndex - 1), crumbItem])

      return
    }

    if (defaultCrumbs.length > 0) {
      setDefaultCrumbs([])
    }

    setBreadcrumbs([...crumbsForRender, crumbItem])
  }, [router])

  useEffect(() => {
    if (startedPaths.includes(router.pathname)) {
      setBreadcrumbs([
        {
          href: router.pathname,
          text:
            currentCrumbTitle ??
            titlesForBreadcrumb(router.pathname, dynamicId, view) ??
            '',
        },
      ])
      return
    }

    if (!breadcrumbsPaths && breadcrumbs.length === 0) {
      const query = router.search
      const tabQuery = query
        ? query.split('&').find((qItem) => qItem.match(/[t=]/))
        : null

      const pathname = tabQuery
        ? `${router.pathname}?${tabQuery}`
        : router.pathname

      const asPathNestedRoutes = pathname.split('/').filter((v) => v.length > 0)
      const crumbsList: Array<CrumbsType> = asPathNestedRoutes.map((_, idx) => {
        const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/')

        if (idx === asPathNestedRoutes.length - 1) {
          return {
            href,
            text:
              currentCrumbTitle ??
              titlesForBreadcrumb(href, dynamicId, view) ??
              '',
          }
        }
        return {
          href,
          text: titlesForBreadcrumb(href) ?? '',
        }
      })

      setDefaultCrumbs(crumbsList)
    }

    if (
      breadcrumbsPaths &&
      !startedPaths.includes(router.pathname) &&
      breadcrumbs.length <= 1
    ) {
      const crumbsList: Array<CrumbsType> = breadcrumbsPaths.map((cItem) => {
        return cItem
      })

      crumbsList.push({
        href: router.pathname,
        text:
          currentCrumbTitle ??
          titlesForBreadcrumb(router.pathname, dynamicId) ??
          '',
      })
      setBreadcrumbs(crumbsList)
    }
  }, [breadcrumbsPaths])

  useEffect(() => {
    if (currentCrumbTitle && breadcrumbs.length > 1) {
      const breadcrumbsClone = ObjHelper.cloneDeep(breadcrumbs)
      const lastElem = breadcrumbsClone[breadcrumbsClone.length - 1]
      if (lastElem) {
        lastElem.text = currentCrumbTitle
        setBreadcrumbs(breadcrumbsClone)
      }
    }
  }, [currentCrumbTitle])

  useEffect(() => {
    if (!resetBreadCrumbs) {
      return
    }
    setDefaultCrumbs([defaultCrumb])
    setBreadcrumbs([])
    setResetBreadCrumbs(false)
  }, [resetBreadCrumbs])

  if (crumbsForRender.length <= 1) {
    return null
  }
  return (
    <ThemeProvider theme={breadcrumbTheme}>
      <Box pt={3}>
        <Breadcrumbs separator=">" data-testid="breadcrumb">
          {crumbsForRender.map((crumb, idx) => {
            if (crumbsForRender.length - 1 === idx && isDataLoading) {
              return <Skeleton key={idx} animation="wave" width={120} />
            }

            return (
              <Crumb
                {...crumb}
                key={idx}
                last={idx === breadcrumbs.length - 1}
              />
            )
          })}
        </Breadcrumbs>
      </Box>
    </ThemeProvider>
  )
}

export default DynamicBreadcrumbs
