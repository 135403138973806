/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  useGetOvaAccountList,
  useGetOvaBackupProvidersList,
} from '@features/ova-dashboard/ova-dashboard-store'
import {
  useGetOvaServerPossibleFilters,
  useIsOvaServerPossibleFiltersLoading,
} from '@lib/zustand'
import { OvaAccount } from 'blues-corejs/dist'
import { useToolbarFiltersActions } from './toolbar/toolbar-filters-store'

export function useOvaServersDrilldownQuery(accountsList: Array<OvaAccount>) {
  const possibleFilters = useGetOvaServerPossibleFilters()
  const isOvaServerPossibleFiltersLoading =
    useIsOvaServerPossibleFiltersLoading()

  const sliceOvaAccountsList = useGetOvaAccountList()
  const sliceOvaBackupProvidersList = useGetOvaBackupProvidersList()

  const { setToolbarFiltersState } = useToolbarFiltersActions()

  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const malwareQuery = urlParams.get('malware')
  const ransomwareQuery = urlParams.get('ransomware')

  useEffect(() => {
    if (isOvaServerPossibleFiltersLoading) {
      return
    }

    if (!malwareQuery && !ransomwareQuery) {
      return
    }

    const accountsLists = sliceOvaAccountsList.length
      ? accountsList.filter((account) =>
          sliceOvaAccountsList.includes(account.id)
        )
      : []
    const backupProvidersList = sliceOvaBackupProvidersList.length
      ? sliceOvaBackupProvidersList
      : []
    const malwareThreatNames = malwareQuery
      ? possibleFilters.malwareThreatNamesList
      : new Set<string>()
    const ransomwareThreatNames = ransomwareQuery
      ? possibleFilters.ransomwareThreatNamesList
      : new Set<string>()

    setToolbarFiltersState({
      malwareThreatNames: malwareThreatNames,
      ransomwareThreatNames: ransomwareThreatNames,
      ovaAccountsList: accountsLists,
      backupProvidersList,
    })
  }, [
    isOvaServerPossibleFiltersLoading,
    accountsList.length,
    malwareQuery,
    possibleFilters.malwareThreatNamesList.size,
    possibleFilters.ransomwareThreatNamesList.size,
    ransomwareQuery,
    sliceOvaAccountsList.length,
    sliceOvaBackupProvidersList.length,
  ])
}
