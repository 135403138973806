import Label from 'ui-v2/src/components/ui/data-display/label'

import { Button, Grid2, Stack } from '@mui/material'
import AddAccessTokenDrawer from './add-access-token-drawer'

import { useNavigate } from 'react-router-dom'

import ActiveToken from './active-token/'
import RevokedHistory from './revoke-history'

const ApiAccess = () => {
  const navigate = useNavigate()

  const handleOpenDrawer = () => {
    navigate('/settings/general/api-access/add-access-token')
  }

  return (
    <>
      <Stack spacing={2.5} sx={{ paddingBottom: 2.5 }} maxWidth={824}>
        <Grid2 container spacing={2}>
          <Grid2 size={12}>
            <Label>Access Tokens</Label>
            <Label mb={0} variant="body2" fontWeight={600}>
              Access tokens that have been generated to access the Elastio API
            </Label>
          </Grid2>
          <Grid2 size={12}>
            <Button
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => {
                handleOpenDrawer()
              }}
            >
              Add New Access Token
            </Button>
          </Grid2>
        </Grid2>
      </Stack>
      <Stack gap={4}>
        <ActiveToken />
        <RevokedHistory />
      </Stack>
      <AddAccessTokenDrawer />
    </>
  )
}

export default ApiAccess
