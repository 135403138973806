import { useMutation } from '@tanstack/react-query'
import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { ThreatClient } from 'ui-v2/src/lib/clients/threat'
import { ThreatSuppressionCriteria } from '@lib/clients/threats/threat-suppression'

export function useSuppressThreatQuery() {
  const threatClient = useGrpcClient(ThreatClient)

  return useMutation({
    mutationFn: async (data: ThreatSuppressionCriteria) => {
      if (!threatClient) {
        throw new Error('Report schedule client not initialized')
      }

      return threatClient.threatSuppression(data)
    },
  })
}
