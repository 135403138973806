import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RegexConstants from '@lib/constants/regex.constant'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import { StyledInputAdornment } from '@components-composite/specific-emails-block/style'

interface Props {
  disabled: boolean
  emails: Array<string>
  setEmails: React.Dispatch<React.SetStateAction<Array<string>>>
  showTitle?: boolean
  description?: string
}

function SpecificEmailsBlock({
  emails,
  setEmails,
  disabled,
  showTitle = true,
  description = 'This is where notifications will be sent if you select “specific emails” above.',
}: Props) {
  const [emailInput, setEmailInput] = useState('')

  const [inputError, setInputError] = useState('')

  const isEmailCorrect = (value: string): boolean => {
    if (!RegexConstants.EMAILv2.test(value)) {
      setInputError('The email address is incorrect')
      return false
    }

    if (emails.includes(value)) {
      setInputError('This email address is already on the list')
      return false
    }

    return true
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputEvent = event.nativeEvent as InputEvent

    if (inputEvent.inputType === 'insertFromPaste') {
      return
    }

    const value = event.target.value
    setEmailInput(value)

    if (value && !isEmailCorrect(value)) {
      return
    }
    if (!value && !disabled && !emails.length) {
      setInputError('The list of emails is empty')
      return
    }
    setInputError('')
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputError) {
      return
    }
    if (event.key === 'Enter') {
      setEmails([...emails, emailInput])
      setEmailInput('')
    }
  }
  const onPaste = (event: React.ClipboardEvent) => {
    const text = event.clipboardData.getData('text')
    isEmailCorrect(emailInput + text)
    setEmailInput(emailInput + text)
  }

  const onSave = () => {
    if (!emailInput) {
      setInputError('Enter email address')
      return
    }

    setEmails([...emails, emailInput])
    setEmailInput('')
  }

  const onDelete = (deletedEmail: string) => {
    setEmails(emails.filter((email) => email !== deletedEmail))
  }

  useEffect(() => {
    if (!inputError && !disabled && !emails.length) {
      setInputError('The list of emails is empty')
      return
    }
    setInputError('')
  }, [disabled, JSON.stringify(emails)])

  return (
    <Box className="wrap-1673366312777">
      {showTitle && (
        <Box className="header">
          <Box className="emails-icon"></Box>
          <p>Specific emails</p>
        </Box>
      )}
      {!!description && (
        <p data-testid="description" id="description">
          {description}
        </p>
      )}
      <Box className="addEmailsBlock">
        <h3>Add emails</h3>
        <OutlinedInput
          type="email"
          placeholder="Enter email"
          value={emailInput}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          disabled={disabled}
          size="small"
          inputProps={{ 'aria-label': 'email-input' }}
          endAdornment={
            <StyledInputAdornment
              position="end"
              disablePointerEvents={disabled}
            >
              <Box aria-label="icon-wrapper" id="icon-wrapper" onClick={onSave}>
                <AddIcon />
              </Box>
            </StyledInputAdornment>
          }
        />
        {!!inputError && <Box className="formGlobalError">{inputError}</Box>}
      </Box>
      <Box className={`emailsList ${disabled && 'disabled'}`}>
        {!!emails.length &&
          emails.map((email) => (
            <Box
              aria-label={`emails-block-${email}`}
              className="emailBlock"
              key={email}
            >
              <p className="email">{email}</p>
              {!disabled && (
                <span
                  aria-label="delete-email-icon"
                  onClick={() => onDelete(email)}
                >
                  <CloseIcon htmlColor="#1A1F28" />
                </span>
              )}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default SpecificEmailsBlock
