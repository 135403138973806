import { Typography } from '@mui/material'

const AwsInstalling = () => {
  return (
    <Typography variant="body1" fontWeight={400} color="text.secondary">
      Now the CFN is in a "INSTALLING" status
    </Typography>
  )
}

export default AwsInstalling
