import { createDisplayColumn } from '@components/table'
import DeleteIcon from '@inline-img/general/menu/delete-icon'
import React from 'react'
import { StyledDeleteBox } from '@features/policies/policy/policy-tables/paths-sub-row'

type InputData = {
  pathTitle: string
  handleDeletePath: (deletedPath: string, selector: string) => void
}

export function getPathsSubTableColumnDefinitions({
  pathTitle,
  handleDeletePath,
}: InputData) {
  return [
    createDisplayColumn({
      id: 'gap',
      meta: {
        width: '56',
      },
    }),
    createDisplayColumn({
      header: pathTitle,
      id: pathTitle,
      cell: ({ row }) => {
        return row.original
      },
    }),
    createDisplayColumn({
      id: 'delete-path',
      meta: {
        width: '32',
        textAlign: 'right',
      },
      cell: ({ row }) => {
        const path = row.original as unknown as string

        return (
          <StyledDeleteBox
            onClick={() => handleDeletePath(path, pathTitle.toLowerCase())}
          >
            <DeleteIcon fill="#1A1F28" />
          </StyledDeleteBox>
        )
      },
    }),
  ]
}
