import { UserSettings } from '@lib/constants'
import { StrHelper } from '@lib/helpers'
import SettingsService from '@lib/services/high/settings.service'
import { useEffect, useState } from 'react'
import useViewData from '../useViewData'
import {
  convertTagsToTuples,
  DashboardViewForm,
  dashboardViewTypes,
  removeEmptyValuesFromObject,
  setFormValuesFromView,
} from '..'
import { ForbiddenCharactersValidator } from '@lib/models/validator/name.validator'
import { useLayoutContext } from '@features/contexts'
import {
  DEFAULT_VIEWS,
  TabViewInterface,
  ViewKind,
} from '@features/DashboardV4/management-view'
import { CURRENT_VIEW_VERSION } from '@features/DashboardV4/management-view/consts'
import { useNavigation, DashboardRoutes } from '@lib/router'
import { useSearchParams } from 'react-router-dom'

export function useEditView() {
  const router = useNavigation()
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get('t')

  const { setHeaderTitle } = useLayoutContext()

  const { views, setValue, handleSubmit, ...formValues } = useViewData()

  const [editingView, setEditingView] = useState<TabViewInterface>(
    {} as TabViewInterface
  )

  const handleEditView = async (data: DashboardViewForm) => {
    const formData: TabViewInterface = removeEmptyValuesFromObject({
      name: data.name,
      id: editingView.id,
      variant: dashboardViewTypes[data.dashboardView],
      slice: {
        accountIds: data.accounts,
        regions: data.regions,
        include_tags: {
          tags: convertTagsToTuples(data.includingTags),
          operator: Number(data.includingTagsOperation),
        },
        exclude_tags: {
          tags: convertTagsToTuples(data.excludingTags),
          operator: Number(data.excludingTagsOperation),
        },
      },
      version: CURRENT_VIEW_VERSION,
      isDefaultDashboard: false,
      kind: ViewKind.AWS,
    })
    const newViews = views.map((item) =>
      item.id === formData.id ? formData : item
    )

    await SettingsService.updateSetting(UserSettings.TabViews, newViews)

    const viewIndex = [...DEFAULT_VIEWS, ...newViews].findIndex(
      (item) => item.id === formData.id
    )

    router.push(DashboardRoutes.rootTab(String(viewIndex)))
  }

  function validateName(value: string) {
    if (value === editingView.name) {
      return true
    }

    const validator = new ForbiddenCharactersValidator(value, 'Name')

    if (!validator.validate()) {
      return validator.errorMessage
    }

    return true
  }

  useEffect(() => {
    if (!tabIndex) {
      return
    }
    const queryViewIndex = Number(StrHelper.base64Decode(String(tabIndex)))

    const availableViews = [...DEFAULT_VIEWS, ...views]

    const view = availableViews[queryViewIndex]

    if (Number.isNaN(queryViewIndex) || !view) {
      router.push(DashboardRoutes.root)
      return
    }

    setEditingView(view)

    setFormValuesFromView(view, setValue)
  }, [tabIndex])

  useEffect(() => {
    setHeaderTitle('Edit dashboard view')
    return () => setHeaderTitle(null)
  }, [])

  return {
    ...formValues,
    setValue,
    handleEditView: handleSubmit(handleEditView),
    validateName,
  }
}
