import React from 'react'
import TimezoneSelect from 'react-timezone-select'

import { UserSettings } from '@lib/constants/settings.constant'
import SettingsService from '@lib/services/high/settings.service'
import { DEFAULT_TIME_ZONE } from '@lib/constants/time.constant'
import ToastHelper from '@lib/helpers/toast.helper'
import Button from '@mui/material/Button'
import { useTimezoneState } from './use-timezone-state'
import classes from './styles.module.css'

function Timezone() {
  const timeZone =
    SettingsService.getSetting(UserSettings.TimeZone) ?? DEFAULT_TIME_ZONE()

  const { selectedTimezone, isTimezoneChanged, handleTimezoneSelectChange } =
    useTimezoneState(timeZone)

  const handleTimezoneShiftClick = () => {
    SettingsService.updateSetting(UserSettings.TimeZone, selectedTimezone)
    ToastHelper.success('Time Zone has been updated')
  }

  return (
    <>
      <TimezoneSelect
        value={selectedTimezone.value}
        onChange={handleTimezoneSelectChange}
      />
      <div className={classes.wrapBtn}>
        <Button
          onClick={handleTimezoneShiftClick}
          className={classes.btn}
          variant="contained"
          disabled={!isTimezoneChanged}
        >
          Save Changes
        </Button>
      </div>
    </>
  )
}

export default Timezone
