import React, { useState } from 'react'
import VirusCard from '@components-simple/virus-card/VirusCard'
import clsx from 'clsx'
import InfoHeaderButtonsRowElement from '@lib/interfaces/info-header-buttons-row-element.interface'
import { Button, Icon, Tooltip } from '@mui/material'
import ModalCloseIcon from '@inline-img/general/modal-close-icon'
import InfoIconFilled from '@inline-img/general/info-icon-filled'
import { EngineCallbackPure } from '@lib/engine-types'
import { EmptyFunc } from '@lib/constants/app.constant'
import PageHelper from '@lib/helpers/page.helper'
import PagePathConstant from '@lib/constants/page-path.constant'
import WarningTriangleIcon from '@inline-img/general/warning-triangle-icon'
import { useNavigation } from '@lib/router/contexts/navigation-context'

type InfoHeaderType = 'error' | 'successful' | 'info' | 'warning'

export const HeaderIcon = {
  error: '/images/notifications/routed-alert-error.svg',
  successful: '/images/notifications/check-circle.svg',
  info: '#32B6F3',
  warning: '#ffba54',
}

function renderInfoHeaderIcon(type: InfoHeaderType, isHeaderV2 = false) {
  switch (type) {
    case 'error':
      return (
        <div className="imageWrapper">
          <img src={HeaderIcon[type]} alt="icon" width={22} height={22} />
        </div>
      )
    case 'successful':
      return <img src={HeaderIcon[type]} alt="icon" width={22} height={22} />
    case 'info':
      return <InfoIconFilled fill={HeaderIcon[type]} />
    case 'warning':
      return isHeaderV2 ? (
        <WarningTriangleIcon />
      ) : (
        <InfoIconFilled fill={HeaderIcon[type]} />
      )
  }
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type: InfoHeaderType
  buttons?: Array<InfoHeaderButtonsRowElement>
  tags?: Array<string>
  title?: string
  viruses?: Array<string>
  description?: string
  className?: string
  closable?: boolean
  onClose?: EngineCallbackPure
}

function InfoHeader({
  type,
  buttons,
  title,
  description,
  viruses,
  tags,
  closable = false,
  className = '',
  onClose = EmptyFunc,
  ...restProps
}: Props) {
  const [show, setShow] = useState(true)
  const onClick = () => {
    setShow(false)
    onClose()
  }

  const router = useNavigation()

  const onTagClick = (tag: string) => {
    // TODO: make some logic, maybe switch, now its hardcode for hotfix
    if (className === 'cfnAlert') {
      router.push(
        PageHelper.buildUrl(
          PagePathConstant.CLOUD_CONFIGURE_DEPLOYMENT_WITH_ACCOUNT,
          tag
        )
      )
    }
  }

  if (!show) {
    return null
  }

  const isHeaderV2 = className === 'infoHeaderV2'

  return (
    <div
      className={`wrap-1652775489318 ${className} jsInfoHeader`}
      {...restProps}
    >
      <div className={clsx(`details ${type}`)}>
        <div className="icon">{renderInfoHeaderIcon(type, isHeaderV2)}</div>
        <div className="content">
          {title && <div className="title">{title}</div>}
          {closable && (
            <div className="closableIcon" title="Close" onClick={onClick}>
              <ModalCloseIcon />
            </div>
          )}
          {description && <div className="description">{description}</div>}
          {viruses && (
            <div className="virusesArea">
              {viruses?.map((item) => <VirusCard key={item} title={item} />)}
            </div>
          )}
          {!!tags?.length && (
            <div className="tagsArea jsInfoHeaderTags">
              {tags.map((tag, index) => (
                <div key={index}>
                  <Button
                    variant="text"
                    color="primary"
                    key={index}
                    onClick={() => onTagClick(tag)}
                  >
                    {tag}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        {buttons && (
          <div className="actionArea">
            {buttons.map((button, index) => (
              <Tooltip
                title={button.isDisabled ? (button.disabledPopup ?? '') : ''}
                key={typeof button.label === 'string' ? button.label : index}
              >
                <div>
                  <Button
                    className={button.type}
                    onClick={button.onClick}
                    disabled={button.isDisabled}
                  >
                    {button.label}
                    {button.icon && (
                      <Icon className="material-icons-outlined button-icon">
                        {button.icon}
                      </Icon>
                    )}
                  </Button>
                </div>
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoHeader
