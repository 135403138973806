import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { AssetsEbsTableData } from 'ui-v2/src/lib/use-cases/assets'
import {
  useAssetsEbsQuery,
  useAssetsLiveQuery,
} from 'ui-v2/src/hooks/queries/assets'
import { getEbsColumns } from './column-config'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'
import { useAssetsEbsFilters } from 'ui-v2/src/hooks/filters/assets'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'

const AssetsEbs = () => {
  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsEbsFilters()

  const { data: assetsEbsData, isLoading: isAssetsEbsDataLoading } =
    useAssetsEbsQuery(filters)
  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const lastScans = useMemo(
    () =>
      assetsLiveData?.scans.flatMap((scan) => {
        if (scan.ransomwareScan) {
          return scan.ransomwareScan
        }
        if (scan.malwareScan) {
          return scan.malwareScan
        }
        if (scan.filesystemCheckScan) {
          return scan.filesystemCheckScan
        }
        return []
      }),
    [assetsLiveData]
  )

  const data = useMemo(() => {
    if (!assetsEbsData || !assetsLiveData || !lastScans) {
      return undefined
    }

    return new AssetsEbsTableData({
      volumes: assetsEbsData.volumesList,
      lastBackups: assetsLiveData.lastBackups.backups,
      scans: lastScans,
      threats: assetsLiveData.threats,
    }).getEbsTableData()
  }, [assetsEbsData, assetsLiveData, lastScans])

  const columns = useMemo(getEbsColumns, [])

  return (
    <Stack spacing={1}>
      <AssetFilters
        filters={[
          ASSET_FILTERS_MAP.ACCOUNT_ID,
          ASSET_FILTERS_MAP.VOLUME_NAME,
          ASSET_FILTERS_MAP.VOLUME_ID,
          ASSET_FILTERS_MAP.VOLUME_STATE,
          ASSET_FILTERS_MAP.POLICY_COVERAGE,
        ]}
      />

      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isAssetsEbsDataLoading || isAssetsLiveDataLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsEbsData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsEbs
