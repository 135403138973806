import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { ServersWithThreatsIcon } from '../icons'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from '@components-complex/dashboard-pages-v3/ova-server-table/toolbar/consts'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'
import { useServersDrilldownLink } from '@components-complex/dashboard-pages-v3/ova-server-table'

interface Props {
  isLoading: boolean
  serversWithThreats: number
}

function ServersWithThreatsSkeleton() {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      width="100%"
      data-testid="servers-threats-skeleton"
    >
      <Skeleton>
        <ServersWithThreatsIcon />
      </Skeleton>
      <Skeleton>
        <Typography fontSize="22px" fontWeight="600" minWidth="30px">
          23
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontSize="16px">Servers with threats</Typography>
      </Skeleton>
    </Box>
  )
}

function computeServerWithThreatsIconColor(serversWithThreats: number) {
  return serversWithThreats > 0 ? 'var(--red400)' : 'var(--green500)'
}

export function ServersWithThreats({ serversWithThreats, isLoading }: Props) {
  const { handleRedirectWithDrilldown } = useServersDrilldownLink({
    queryKey: STATUS_FILTER_DRILLDOWN_QUERY_KEY,
    queryValue: OvaServerStatus.INFECTED.toString(),
  })

  if (isLoading) {
    return <ServersWithThreatsSkeleton />
  }

  const iconColor = computeServerWithThreatsIconColor(serversWithThreats)

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      sx={{ cursor: 'pointer' }}
      onClick={handleRedirectWithDrilldown}
    >
      <ServersWithThreatsIcon fill={iconColor} />
      <Typography fontSize="22px" fontWeight="600" minWidth="30px">
        {serversWithThreats}
      </Typography>
      <Typography fontSize="16px">Servers with threats</Typography>
    </Box>
  )
}
