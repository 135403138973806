/* eslint-disable import/no-extraneous-dependencies */
import { create } from 'zustand'
import { RowSelectionState } from '@tanstack/table-core'
import { NotificationRule } from 'blues-corejs/dist/models/notifications/rule'

type AlertsListStore = {
  tableData: Array<NotificationRule>
  setTableData: (data: Array<NotificationRule>) => void
  isLoading: boolean
  setLoading: (loading: boolean) => void
  selectedRows: RowSelectionState
  setSelectedRows: (rows: RowSelectionState) => void
}

export const useAlertsListStore = create<AlertsListStore>((set) => ({
  tableData: [],
  isLoading: false,
  selectedRows: {},
  setTableData: (data: Array<NotificationRule>) =>
    set((state) => ({
      ...state,
      tableData: data,
    })),
  setLoading: (loading: boolean) =>
    set((state) => ({
      ...state,
      isLoading: loading,
    })),
  setSelectedRows: (rows: RowSelectionState) =>
    set((state) => ({
      ...state,
      selectedRows: rows,
    })),
}))
