import Link from '@lib/router/navigation-strategies/link'
import { PublicRoutes } from '@lib/router/routes/public'
import { useParams } from 'react-router-dom'
import { StrHelper } from '@lib/helpers'

export default function VerificationStatusIdPage() {
  const { id: dynamicId } = useParams()
  const status = StrHelper.base64Decode(dynamicId)
  if (!status) {
    return null
  }

  let description = ''
  // eslint-disable-next-line default-case
  switch (status) {
    case 'error':
      description = 'Your email address could not be verified.'
      break
    case 'success':
      description = 'Your email address has been verified.'
      break
  }

  return (
    <div className="innerContent jsVerificationStatusPage">
      <div className="verificationContainer">
        <div className="verificationTitle">
          Email Verification
          {' - '}
          <span className="textCapitalize">{status}</span>
        </div>
        <div className="verificationDescription">{description}</div>
        <Link to={PublicRoutes.logout} passHref>
          <button className="verificationPageButton">Back to login page</button>
        </Link>
      </div>
    </div>
  )
}
