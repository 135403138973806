/* eslint-disable import/no-extraneous-dependencies */
import React, { ChangeEvent } from 'react'
import { Row } from '@tanstack/react-table'
import { S3Bucket, EFS } from 'blues-corejs/dist'
import { SelectSpecificPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import { EngineCallback } from '@lib/engine-types'
import {
  isSelectedAllPathsForAsset,
  isSelectedSpecificPathForAsset,
} from '@features/policies/policy/policy-tables/utils'
import { StyledCheckbox } from '@features/policies/policy/policy-tables/styles'

interface Props {
  row: Row<any>
  asset: S3Bucket | EFS
  selectedAllPaths: Array<string>
  selectedSpecificPaths: Array<SelectSpecificPathsInterface>
  handleSelect: EngineCallback<{
    assetId: string
    checked: boolean
  }>
}

export function PolicySelectRowCell({
  row,
  asset,
  selectedAllPaths,
  selectedSpecificPaths,
  handleSelect,
}: Props) {
  const isSelectedAllPath = isSelectedAllPathsForAsset(
    selectedAllPaths,
    asset.id
  )
  const isSelectedSpecificPath = isSelectedSpecificPathForAsset(
    selectedSpecificPaths,
    asset.id
  )
  const isSelectedPath = isSelectedSpecificPath || isSelectedAllPath
  const isSelected = row.getIsSelected()

  const handleRowSelectionAndExpansion = (
    evt: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = evt.target

    row.toggleExpanded(checked && !isSelectedPath)
    row.toggleSelected()
    handleSelect({
      assetId: asset.id,
      checked,
    })
  }

  return (
    <StyledCheckbox
      checked={isSelected}
      onChange={handleRowSelectionAndExpansion}
    />
  )
}
