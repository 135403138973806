import { useFormContext } from 'react-hook-form'
import PolicLabelValue from './policy-label-value'
import { PolicyFormData } from 'ui-v2/src/lib/models/settings/policies/policies'
import {
  determineShowDayOfMonthInput,
  determineShowDayOfWeekInput,
  determineShowOnlyStart,
  determineShowWindowInputs,
  getDayLabel,
} from 'ui-v2/src/lib/helpers/policies.helper'

import { format12HourTime } from 'ui-v2/src/lib/helpers/time.helper'

const ReviewSchedule = () => {
  const { watch } = useFormContext<PolicyFormData>()
  const scheduleData = watch('schedule')

  const showWindowInputs = determineShowWindowInputs(scheduleData.frequency)
  const showDayOfWeekInput = determineShowDayOfWeekInput(scheduleData.frequency)
  const showDayOfMonthInput = determineShowDayOfMonthInput(
    scheduleData.frequency
  )
  const showOnlyStart = determineShowOnlyStart(scheduleData.frequency)

  return (
    <>
      {!!showWindowInputs && (
        <PolicLabelValue
          label="Start:"
          value={`${format12HourTime(
            scheduleData.windowStart.hour,
            scheduleData.windowStart.minute
          )} - ${format12HourTime(
            scheduleData.windowEnd.hour,
            scheduleData.windowEnd.minute
          )} + ${scheduleData.windowStart.timezone}`}
        />
      )}
      {!!showOnlyStart && (
        <PolicLabelValue
          label="Start:"
          value={`${format12HourTime(
            scheduleData.windowStart.hour,
            scheduleData.windowStart.minute
          )} + ${scheduleData.windowStart.timezone}`}
        />
      )}
      {!!showDayOfWeekInput && (
        <PolicLabelValue
          label="Start:"
          value={`${scheduleData.dayOfWeek?.name ?? scheduleData?.dayOfWeek?.label} - ${format12HourTime(
            scheduleData.windowStart.hour,
            scheduleData.windowStart.minute
          )} + ${scheduleData.windowStart.timezone}`}
        />
      )}
      {!!showDayOfMonthInput && (
        <PolicLabelValue
          label="Start:"
          value={`${getDayLabel(scheduleData.dayOfMonth, true)} - ${format12HourTime(
            scheduleData.windowStart.hour,
            scheduleData.windowStart.minute
          )} + ${scheduleData.windowStart.timezone}`}
        />
      )}
    </>
  )
}

export default ReviewSchedule
