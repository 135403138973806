import { createColumn } from '@components/table'
import { Asset } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/asset-tag-selection/asset-tag-selection-store'

export const getTagsColumns = () => {
  return [
    createColumn<Asset>('tagKey', {
      header: 'Tags (Key : Value)',
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
    createColumn<Asset>('tagValue', {
      enableSorting: false,
      cell: ({ cell }) => cell.getValue(),
    }),
  ]
}
