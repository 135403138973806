import accountIdValidator from '@forms/validators/account-id.validator'
import FormAccountIdInterface from '@lib/interfaces/form/form-account-id.interface'
import FormFieldInterface from '@lib/interfaces/form/form-field.interface'

const buildDefaultFormData = (): Record<
  keyof FormAccountIdInterface,
  FormFieldInterface
> => ({
  accountId: {
    name: 'accountId',
    label: 'AWS Account ID',
    validationRules: {
      required: true,
      validate: accountIdValidator,
    },
  },

  iamRole: {
    name: 'iamRole',
    label: 'IAM Role',
    validationRules: {
      // required: true,
    },
  },
})

export default buildDefaultFormData
