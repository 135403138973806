/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Row } from '@tanstack/react-table'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { StyledCheckbox } from '@features/policies/policy/policy-tables/styles'

interface Props {
  row: Row<AssetWithRelatedAssets<Asset>>
  asset: Asset
  selectedAssetsIds: Set<string>
  handleRowChange: (assetId: string, isSelected: boolean) => void
}

export function AssetSelectCell({
  row,
  asset,
  selectedAssetsIds,
  handleRowChange,
}: Props) {
  const isDisabled = selectedAssetsIds.has(asset.id)
  const isSelected = !isDisabled ? row.getIsSelected() : false
  const handleClick = () => {
    handleRowChange(asset.id, isSelected)
  }
  return (
    <StyledCheckbox
      checked={isDisabled || isSelected}
      onChange={row.getToggleSelectedHandler()}
      onClick={handleClick}
      disabled={isDisabled}
    />
  )
}
