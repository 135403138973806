import React, { Fragment, memo } from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { StyledArrowBackIcon, StyledArrowNextIcon } from './styles'

interface Props {
  onScrollBack: () => void
  onScrollNext: () => void
  canScrollBack: boolean
  canScrollNext: boolean
}

function Navigation({
  canScrollBack,
  canScrollNext,
  onScrollBack,
  onScrollNext,
}: Props) {
  return (
    <Fragment>
      {canScrollBack && (
        <StyledArrowBackIcon
          data-testid="back-arrow"
          onClick={onScrollBack}
          children={<ArrowBackIosNewIcon />}
        />
      )}
      {canScrollNext && (
        <StyledArrowNextIcon
          data-testid="next-arrow"
          onClick={onScrollNext}
          children={<ArrowForwardIosIcon />}
        />
      )}
    </Fragment>
  )
}

export default memo(Navigation)
