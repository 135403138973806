import React, { useEffect } from 'react'
import { useNavigation } from '@lib/router/contexts/navigation-context'

export default function InitError() {
  const router = useNavigation()

  const onTryAgain = (event: React.MouseEvent) => {
    event.preventDefault()
    router.back()
  }

  const [counter, setCounter] = React.useState(59)

  useEffect(() => {
    const timer = setInterval(() => setCounter(counter - 1), 1000)
    if (counter <= 0) {
      clearInterval(timer)
      router.back()
    }

    return () => clearInterval(timer)
  }, [counter])

  return (
    <div className="innerContent ilMain">
      <div className="llContent">
        <div className="logoBlockWrap">
          <div className="logoBlock">
            <div className="">
              <img
                width={140}
                height={50}
                alt="Elastio"
                src="/images/elastio-logo.svg"
              />
            </div>
          </div>
        </div>

        <p className="textHeaderBlock">
          <b>Something went wrong</b>
        </p>
        <div className="textBlock">
          <p className="textAlignLeft">
            Reloading in {counter} second(s).
            <br />
            Alternatively, you could refresh the page{' '}
            <a
              className="jsTryAgainLink blueLink underline"
              href="#"
              onClick={onTryAgain}
            >
              <span>now</span>
            </a>
            .
            <br />
            If the problem persists, please get in touch with us at
            <br />
            <a className="blueLink underline" href="mailto:support@elastio.com">
              support@elastio.com
            </a>
          </p>
        </div>
      </div>
      <div className="image">
        <img
          width={575}
          height={441}
          alt="Elastio"
          src="/images/error-waiting.svg"
        />
      </div>
    </div>
  )
}
