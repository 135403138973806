import { Box, styled } from '@mui/material'

export const Container = styled(Box)({
  display: 'flex',
  gap: '24px',

  '.left-column': {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    flexBasis: '60%',
    background: 'var(--white)',
    boxShadow: '0px 11px 27px 0px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
  },

  '.right-column': {
    flexGrow: 1,
    flexBasis: '40%',
    boxShadow: '0px 11px 27px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: 'var(--white)',
    padding: '24px',
    borderRadius: '8px',
  },
})
