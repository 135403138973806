import React from 'react'
import {
  Controller,
  FieldErrors,
  useFormContext,
  FieldValues,
} from 'react-hook-form'
import { SelectChangeEvent } from '@mui/material/Select'
import { BasicSelect, OptionType } from '@components/shared'
import FormControl from '@mui/material/FormControl'

interface ControlledSelectOwnProps<TFormValues extends FieldValues> {
  name: string
  options: Array<OptionType>
  placeholder?: string
  isLoading?: boolean
  disabled?: boolean
  errors?: FieldErrors<TFormValues>
  inputSize?: 'small' | 'medium'
  errorKey?: keyof FieldErrors<TFormValues>
  onChangeCallback?: (value: string | number) => void
}

function ControlledSelect<TFormValues extends FieldValues>({
  onChangeCallback,
  placeholder,
  isLoading = false,
  options,
  disabled = false,
  name,
}: ControlledSelectOwnProps<TFormValues>) {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const handleChange = (event: SelectChangeEvent<string | number>) => {
          const selectedValue = event.target.value
          field.onChange(selectedValue)

          if (onChangeCallback) {
            onChangeCallback(selectedValue)
          }
        }

        return (
          <FormControl fullWidth>
            <BasicSelect
              value={field.value || ''}
              onChange={handleChange}
              options={options}
              disabled={disabled}
              placeholder={placeholder}
              isLoading={isLoading}
              inputSize="small"
            />
          </FormControl>
        )
      }}
    />
  )
}

export default ControlledSelect
