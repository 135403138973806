import MaterialTab from '@components-simple/material-tab/MaterialTab'
import { tabConfigurations } from './tab-configurations'

interface TabContentRendererOwnProps {
  currentTab: number
}

const tabKeys = Object.keys(tabConfigurations).map(Number)

function TabContentRenderer({ currentTab }: TabContentRendererOwnProps) {
  return (
    <>
      {tabKeys.map(
        (tabIndex) =>
          currentTab === tabIndex && (
            <MaterialTab key={tabIndex} value={currentTab} index={tabIndex}>
              {/*
// @ts-ignore */}
              {tabConfigurations[tabIndex].component}
            </MaterialTab>
          )
      )}
    </>
  )
}

export default TabContentRenderer
