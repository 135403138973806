/* eslint-disable import/no-extraneous-dependencies */
import { JobCloudConnectorKind } from 'blues-corejs/dist/models/jobs/cloud-connector/job/types'
import React, { Fragment } from 'react'
import {
  useSelectedCloudConnectorJobFilters,
  useCloudConnectorJobsFiltersActions,
} from '../stores'
import { CloudConnectorJobKindChip } from '../shared'

export function SelectedJobKindsChips() {
  const { kindsList } = useSelectedCloudConnectorJobFilters()

  const { removeFilter } = useCloudConnectorJobsFiltersActions()

  if (!kindsList.size) {
    return null
  }

  function handleRemoveJobKind(kind: JobCloudConnectorKind) {
    removeFilter('kindsList', kind)
  }

  return (
    <Fragment>
      {Array.from(kindsList).map((kind) => (
        <CloudConnectorJobKindChip
          key={kind}
          kind={kind}
          onDelete={handleRemoveJobKind}
        />
      ))}
    </Fragment>
  )
}
