import React, { useEffect } from 'react'
import {
  EngineCallback,
  EngineCallbackPure,
  Nullable,
  VIRow,
} from '@lib/engine-types'
import BaseModal from '@components-composite/modals/BaseModal'
import FormAddVaultInterface from '@lib/interfaces/form/form-add-vault.interface'
import VaultModel from '@lib/models/vault.model'
import { useDispatch, useSelector } from 'react-redux'
import { getPossibleVPCList } from '@store/selectors/rex.selector'
import {
  requestPossibleVpcList,
  setPossibleVpcList,
} from '@store/actions/rex.action'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import ErrorGroupConstants from '@lib/constants/error-group.constant'
import VaultForm from '@forms/vault/vault.form'

export interface AddVaultModalInputData {
  possibleRegionsTranslation: VIRow
  vaults: Array<VaultModel>
  accountId: string
}

interface AddVaultModalInterface {
  onCancel: EngineCallbackPure
  onSubmit: EngineCallback<FormAddVaultInterface>
  data: Nullable<AddVaultModalInputData>
  show: boolean
  loading: boolean
}

const NO_DATA_MESSAGE = 'There is not enough data to create a vault'

function AddVaultModal({
  onSubmit,
  onCancel,
  data,
  show,
  loading,
}: AddVaultModalInterface) {
  const dispatch = useDispatch()
  const possibleVPCList = useSelector(getPossibleVPCList)

  useEffect(() => {
    // reset
    dispatch(setPossibleVpcList([]))
  }, [])

  useEffect(() => {
    if (data?.accountId) {
      dispatch(requestPossibleVpcList(data.accountId))
    }
  }, [data])

  const modalLoading = usePreloaderAny([
    PreloaderConstants.REQUEST_POSSIBLE_VPC_LIST,
  ])

  const isDataLoading = modalLoading && possibleVPCList != null

  const isDataLoaded =
    data &&
    data.possibleRegionsTranslation.length > 0 &&
    possibleVPCList.length > 0

  return (
    <BaseModal
      className="modalDefaultV2Small addVaultModal"
      onBgClick={onCancel}
      show={show}
    >
      <div className="modalHeaderWrapV2">
        <div className="mhTitle">Create a new Vault</div>
      </div>
      <div className="modalFormContentV2">
        {isDataLoading ? (
          <PreloaderBlock show />
        ) : isDataLoaded ? (
          <VaultForm
            possibleRegions={data.possibleRegionsTranslation}
            possibleVPCList={possibleVPCList}
            loading={loading}
            onSubmit={onSubmit}
            onCancel={onCancel}
            errorGroups={[ErrorGroupConstants.VAULT]}
            vaults={data.vaults}
          />
        ) : (
          <div className="emptyResultBlock jsEmpty modalErrorMsg">
            {NO_DATA_MESSAGE}
          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default AddVaultModal
