import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PreloaderConstants from '@lib/constants/preloader.constant'
import { VIRow, VIRowReadonly } from '@lib/engine-types'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import {
  getAllRedStacks,
  requestPossibleRegionsList,
} from '@store/actions/rex.action'
import { requestSourcesVaultList } from '@store/actions/vault.action'
import {
  getAllRedStacksList,
  getPossibleRegionsList,
} from '@store/selectors/rex.selector'
import { getSourcesVaultList } from '@store/selectors/vault.selector'
import { getAssetSourceList } from '@lib/constants/retention-policy/asset-source.constant'
import { getBackupTypeList } from '@lib/constants/retention-policy/backup-type.constant'
import { SelectCategory } from '@lib/constants/retention-policy/select-category.constant'
import DataHelper from '@lib/helpers/data.helper'
import ArrHelper from '@lib/helpers/arr.helper'
import NumHelper from '@lib/helpers/num.helper'
import GeneralFactory from '@lib/factories/general.factory'
import VaultModel from '@lib/models/vault.model'
import usePreloader from '@lib/hooks/usePreloader'
import ObjHelper from '@lib/helpers/obj.helper'
import { getDashboardData } from '@store/selectors/dashboard.selector'
import { requestDashboardData } from '@store/actions/dashboard.action'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SliceCriteriaTag } from 'blues-corejs/dist/models/inventory.model'

export interface RecoveryPointTypesProps {
  loading: boolean
  selectedFilters?: VIRow
  emptyFilters?: VIRow
  onFiltersChange: any
  allSpecificAssets: VIRow
}

function useRecoveryPointTypesData({
  onFiltersChange,
  selectedFilters = [],
  emptyFilters = [],
  loading,
  allSpecificAssets = [],
}: RecoveryPointTypesProps) {
  const dispatch = useDispatch()

  const isLoading = usePreloaderAny([
    PreloaderConstants.GET_ALL_RED_STACKS,
    PreloaderConstants.REQUEST_SOURCES_VAULT_LIST,
    PreloaderConstants.REQUEST_POSSIBLE_REGIONS_LIST,
    PreloaderConstants.REQUEST_DASHBOARD_DATA,
  ])

  const canBeReset = useMemo(
    () => ObjHelper.isNotEqual(emptyFilters, selectedFilters),
    [emptyFilters, selectedFilters]
  )

  const isAllSelectsDisabled = isLoading || loading

  const isVaultsLoading = usePreloader(
    PreloaderConstants.REQUEST_SOURCES_VAULT_LIST
  )

  useEffect(() => {
    dispatch(getAllRedStacks())
    dispatch(requestSourcesVaultList())
    dispatch(requestPossibleRegionsList())
    dispatch(requestDashboardData())
  }, [])

  const allRedStacksList = useSelector(getAllRedStacksList)
  const possibleRegions = useSelector(getPossibleRegionsList)
  const vaultList = useSelector(getSourcesVaultList)
  const inventoryState = useSelector(getDashboardData)

  // Account IDs
  const uniqueEnabledSources =
    DataHelper.getUniqueEnabledSources(allRedStacksList)

  const possibleAccountIds: VIRow = uniqueEnabledSources.map((account) => ({
    name: String(account.awsAccount),
  }))

  const currentSelectedAccounts =
    selectedFilters?.find((s) => s.name === SelectCategory.ACCOUNT_IDS)
      ?.options || []
  const currentPossibleAccountIds = ArrHelper.unionArray(
    currentSelectedAccounts,
    possibleAccountIds,
    'name'
  )

  const [allPossibleAccountIds, setAllPossibleAccountIds] = useState<VIRow>([])

  const [selectedAccounts, setSelectedAccounts] = useState<VIRow>([])

  // Regions
  const currentSelectedRegions =
    selectedFilters?.find((s) => s.name === SelectCategory.REGIONS)?.options ||
    []
  const currentPossibleRegions = ArrHelper.unionArray(
    currentSelectedRegions,
    possibleRegions,
    'name'
  )

  const [allPossibleRegions, setAllPossibleRegions] = useState<VIRow>([])
  const [selectedRegions, setSelectedRegions] = useState<VIRow>([])

  // Vaults
  const getPossibleVaultsFromList = (vaultsList: Array<VaultModel>): VIRow => {
    const result: VIRow = vaultsList
      .sort((a, b) => (a.accountId > b.accountId ? 1 : -1))
      .sort((a, b) => (a.region > b.region ? 1 : -1))
      .map((v) => {
        const vaultLabel = `${v.name} (Account ID: ${v.accountId}, Region: ${v.region})`
        return {
          name: String(NumHelper.numberHash(vaultLabel)),
          value: v.name,
          label: vaultLabel,
          defaultValue: v.accountId,
          extraValue: v.region,
          supplementalValue: v.innerId,
        }
      })

    return result
  }

  const possibleVaults: VIRow = getPossibleVaultsFromList(vaultList)

  const currentSelectedVaults =
    selectedFilters?.find((s) => s.name === SelectCategory.VAULTS)?.options ||
    []

  const currentPossibleVaults = ArrHelper.unionArray(
    currentSelectedVaults,
    possibleVaults,
    'label'
  )

  const [allPossibleVaults, setAllPossibleVaults] = useState<VIRow>([])
  const [selectedVaults, setSelectedVaults] = useState<VIRow>([])

  // Tags
  const possibleTags: VIRow = []
  const tagsFromInventory =
    inventoryState?.getTags() ?? new Set<SliceCriteriaTag>()

  for (const { key, value } of tagsFromInventory) {
    const tagName = `${key}${value ? '=' + value : ''}`

    possibleTags.push({
      name: key,
      value: value ?? '',
      label: tagName,
    })
  }

  const currentSelectedTags =
    selectedFilters?.find((s) => s.name === SelectCategory.TAGS)?.options || []
  const allPossibleTags = ArrHelper.unionArray(
    currentSelectedTags,
    possibleTags,
    'name'
  )

  const [selectedTags, setSelectedTags] = useState<VIRow>([])

  // Asset types
  const possibleAssetSources: VIRowReadonly = getAssetSourceList()
  const currentAssetSources =
    selectedFilters?.find((s) => s.name === SelectCategory.ASSET_SOURCES)
      ?.options ?? []
  const [selectedAssetSources, setSelectedAssetSources] = useState<VIRow>([])

  // Backup types
  const possibleBackupTypes: VIRowReadonly = getBackupTypeList()
  const currentBackupTypes =
    selectedFilters?.find((s) => s.name === SelectCategory.BACKUP_TYPES)
      ?.options ?? []
  const [selectedBackupTypes, setSelectedBackupTypes] = useState<VIRow>([])

  const hasAssetsSelected = allSpecificAssets.length > 0

  const getVaultsForSpecificAssets = (assets: VIRow) => {
    // get unique vaults for Specific Assets
    let vaultsForAssets: Array<VaultModel> = []
    if (assets.length > 0) {
      assets.map((a) => {
        const vList = vaultList.filter(
          (v) => v.accountId === a.value && v.region === a.label
        )
        if (vList.length > 0) {
          vList.forEach((el: VaultModel) => {
            ArrHelper.pushUnique(el, vaultsForAssets)
          })
        }
      })
    } else {
      vaultsForAssets = vaultList
    }

    const newPossibleVaults = ArrHelper.unionArray(
      currentSelectedVaults,
      getPossibleVaultsFromList(vaultsForAssets),
      'label'
    )
    setAllPossibleVaults(newPossibleVaults)
  }

  const getVaultsForAccountsAndRegions = (accounts: VIRow, regions: VIRow) => {
    // get unique vaults for selected accounts and regions
    let vaultsForAccounts: Array<VaultModel> = []
    let vaultsForRegionsAndAccounts: Array<VaultModel> = []
    if (accounts.length > 0) {
      accounts.map((acc) => {
        const vList = vaultList.filter((v) => v.accountId === acc.name)
        if (vList.length > 0) {
          vList.forEach((el: VaultModel) => {
            ArrHelper.pushUnique(el, vaultsForAccounts)
          })
        }
      })
    } else {
      vaultsForAccounts = vaultList
    }

    if (regions.length > 0) {
      let rList: Array<VaultModel> = []
      regions.map((r) => {
        if (vaultsForAccounts.length > 0) {
          rList = vaultsForAccounts.filter((v) => v.region === r.name)
        } else {
          rList = vaultList.filter((v) => v.region === r.name)
        }
        if (rList.length > 0) {
          rList.forEach((el: VaultModel) => {
            ArrHelper.pushUnique(el, vaultsForRegionsAndAccounts)
          })
        }
      })
    } else {
      vaultsForRegionsAndAccounts = vaultsForAccounts
    }

    const newPossibleVaults = ArrHelper.unionArray(
      currentSelectedVaults,
      getPossibleVaultsFromList(vaultsForRegionsAndAccounts),
      'label'
    )

    setAllPossibleVaults(newPossibleVaults)
  }

  useEffect(() => {
    if (!isLoading) {
      setAllPossibleAccountIds(currentPossibleAccountIds)
      setSelectedAccounts(currentSelectedAccounts)
      setAllPossibleRegions(currentPossibleRegions)
      setSelectedRegions(currentSelectedRegions)
      setSelectedTags(currentSelectedTags)
      setSelectedAssetSources(currentAssetSources)
      setSelectedBackupTypes(currentBackupTypes)

      if (hasAssetsSelected) {
        getVaultsForSpecificAssets(allSpecificAssets)
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (!isVaultsLoading) {
      setAllPossibleVaults(currentPossibleVaults)
      setSelectedVaults(currentSelectedVaults)
    }
  }, [vaultList])

  const onClearAll = () => {
    setSelectedAccounts([])
    setAllPossibleAccountIds(currentPossibleAccountIds)
    setSelectedRegions([])
    setAllPossibleRegions(currentPossibleRegions)
    setSelectedVaults([])
    setAllPossibleVaults(currentPossibleVaults)
    setSelectedAssetSources([])
    setSelectedBackupTypes([])
    setSelectedTags([])
    onFiltersChange(SelectCategory.ALL, [])
  }

  const onSelectedAccountsChange = (value: VIRow) => {
    // get unique regions only for selected accounts
    const regionNames: Array<string> = []
    value.map((item) => {
      const acc = uniqueEnabledSources.filter(
        (account) => account.awsAccount === item.name
      )
      if (acc.length > 0) {
        acc[0].awsRegions.forEach((el: string) => {
          if (el !== undefined) {
            ArrHelper.pushUnique(el, regionNames)
          }
        })
      }
    })

    const allRegionsForAccounts =
      GeneralFactory.buildPossibleRegions(regionNames)

    const regionsForAccounts = ArrHelper.unionArray(
      currentSelectedRegions,
      allRegionsForAccounts,
      'name'
    )

    if (value.length > 0) {
      setAllPossibleRegions(regionsForAccounts)
    } else {
      setAllPossibleRegions(currentPossibleRegions)
    }

    setSelectedAccounts(value)
  }

  const onSelectedRegionsChange = (value: VIRow) => {
    // get unique accounts only for selected regions
    const accounts: VIRow = []
    value.map((item) => {
      const acc = uniqueEnabledSources.filter((account) =>
        account.awsRegions.some((r: string) => r === item.name)
      )
      if (acc.length > 0) {
        acc.forEach((el: any) => {
          ArrHelper.pushUnique(
            {
              name: String(el.awsAccount),
            },
            accounts
          )
        })
      }
    })

    const accountsForRegions = ArrHelper.unionArray(
      currentSelectedAccounts,
      accounts,
      'name'
    )

    if (value.length > 0) {
      setAllPossibleAccountIds(accountsForRegions)
    } else {
      setAllPossibleAccountIds(currentPossibleAccountIds)
    }

    setSelectedRegions(value)
  }

  const onSelectedVaultsChange = (value: VIRow) => {
    // disable account ids and regions selects if vault selected
    setSelectedVaults(value)
    if (value.length > 0) {
      setSelectedAccounts([])
      setSelectedRegions([])
    }
  }

  useEffect(() => {
    if (!isLoading) {
      getVaultsForAccountsAndRegions(selectedAccounts, selectedRegions)
    }
  }, [selectedAccounts, selectedRegions])

  const onInnerChange = (category: SelectCategory, value: VIRow) => {
    onFiltersChange(category, value)
    switch (category) {
      case SelectCategory.ACCOUNT_IDS:
        onSelectedAccountsChange(value)
        return
      case SelectCategory.REGIONS:
        onSelectedRegionsChange(value)
        return
      case SelectCategory.VAULTS:
        onSelectedVaultsChange(value)
        return
      case SelectCategory.ASSET_SOURCES:
        setSelectedAssetSources(value)
        return
      case SelectCategory.BACKUP_TYPES:
        setSelectedBackupTypes(value)
        return
      case SelectCategory.TAGS:
        setSelectedTags(value)
        return
    }
  }

  return {
    isLoading,
    isAllSelectsDisabled,
    hasAssetsSelected,
    canBeReset,
    vaults: {
      allPossibleVaults,
      selectedVaults,
    },
    accounts: {
      allPossibleAccountIds,
      selectedAccounts,
    },
    regions: {
      allPossibleRegions,
      selectedRegions,
    },
    assetSources: {
      possibleAssetSources,
      selectedAssetSources,
    },
    backupTypes: {
      possibleBackupTypes,
      selectedBackupTypes,
    },
    tags: {
      allPossibleTags,
      selectedTags,
    },
    onInnerChange,
    onClearAll,
  }
}

export default useRecoveryPointTypesData
