/* eslint-disable import/no-extraneous-dependencies */
import { NotificationChannels } from 'blues-corejs/dist/models/notifications/rule'
import { UserProfile } from 'blues-corejs/dist/models'
import Typography from '@mui/material/Typography'
import { HoverablePopover } from '@features/alerts/create-rule-drawer/stepper/steps/review-enable/hoverable-popover'
import React from 'react'
import { WebhooksListItem } from 'blue-stack-libs/notifications-grpc-libs/js/notifications/webhooks_pb'
import { Stack, Box } from '@mui/material'

const popover = (title: string, data: Array<string>) => (
  <HoverablePopover
    title={title}
    content={
      <>
        {data.map((item, index) => (
          <Typography key={index} variant="body1">
            {item}
          </Typography>
        ))}
      </>
    }
  >
    <Typography
      sx={{
        textDecoration: 'underline',
        cursor: 'pointer',
        color: 'var(--blue500)',
      }}
    >
      {title}
    </Typography>
  </HoverablePopover>
)

export const computeChannels = (
  channels: NotificationChannels,
  usersList: Array<UserProfile>,
  webHooksList: Array<WebhooksListItem.AsObject>
) => {
  const { tenantMemberEmails, emails, webhooks, slack } = channels
  const result = []

  if (emails.length > 0) {
    const emailsList = emails.map(({ email }) => email)
    result.push(popover('Emails', emailsList))
  }
  if (tenantMemberEmails.length > 0) {
    const recipients = tenantMemberEmails.reduce(
      (acc: Array<string>, customer) => {
        const match = usersList.find(({ id }) => id === customer.customer_id)
        if (match) {
          return [...acc, match.email]
        }
        return acc
      },
      []
    )

    result.push(popover('Recipients', recipients))
  }

  if (webhooks.length > 0) {
    const readableWebhooks = webhooks.reduce((acc: Array<string>, webhook) => {
      const match = webHooksList.find(({ id }) => id === webhook.webhookId)
      if (match) {
        return [...acc, match.name]
      }
      return acc
    }, [])
    result.push(popover('Webhook', readableWebhooks))
  }

  if (slack) {
    result.push('Slack')
  }

  return (
    <Stack direction="row" divider={<Box mr={0.5}>,</Box>}>
      {result}
    </Stack>
  )
}
