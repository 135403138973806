"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RuleSuppressUntil = exports.RuleNotify = void 0;
class RuleNotify {
}
exports.RuleNotify = RuleNotify;
class RuleSuppressUntil {
    date;
    reason;
    constructor(date, reason) {
        this.date = date;
        this.reason = reason;
    }
}
exports.RuleSuppressUntil = RuleSuppressUntil;
