/* eslint-disable import/no-extraneous-dependencies */

import { useLiveQuery } from 'dexie-react-hooks'

import { GetTenantIdsForRunningScheduledJobs } from '@features/scheduled-jobs-monitoring/use-cases'
import { ScheduledTenantJobsRepository } from '@features/scheduled-jobs-monitoring/repositories'
import { ScheduledTenantJobKind } from '@features/scheduled-jobs-monitoring/models'

// the main idea - jobs now have an optional field redStackId
// this hook allows to get a list of red stack ids which were found
// in the jobs in progress or in the scheduled tasks,
// in a lot of requests we are showing toast and for it we create a task
// and now we can get this info from them as well, it helps to get rid of
// states in components before we get a list of jobs from the server

const retrieveTenantIdsByScheduledJobKindUseCase =
  new GetTenantIdsForRunningScheduledJobs(
    new ScheduledTenantJobsRepository(console)
  )
export function useTenantIdsFromScheduledJobs(
  jobKinds: Array<ScheduledTenantJobKind>
): Array<string> {
  const ccIdsList = useLiveQuery(async () => {
    const result = await Promise.resolve(
      retrieveTenantIdsByScheduledJobKindUseCase.execute(jobKinds)
    )

    return result
  }, [jobKinds.length])

  return ccIdsList ? [...ccIdsList] : []
}
