import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { useToast } from 'ui-v2/src/hooks/toast'
import { WebhooksClient } from 'ui-v2/src/lib/clients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export function useDeleteWebhookMutation() {
  const webhooksClient = useGrpcClient(WebhooksClient)
  const queryClient = useQueryClient()
  const toast = useToast()

  return useMutation({
    mutationFn: async (userId: string) => {
      if (!webhooksClient) {
        throw new Error('Webhooks client not initialized')
      }
      return webhooksClient.deleteWebhook(userId)
    },
    onError: (error) => {
      toast.error(
        error.message ?? 'Something went wrong while deleting webhook'
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['webhooks'] })
      toast.success('Webhook has been deleted')
    },
  })
}
