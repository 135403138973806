/* eslint-disable import/no-extraneous-dependencies */
import { JobCloudConnector } from 'blues-corejs/dist'

export function getAssetIdsFromJobs(
  jobsList: Array<JobCloudConnector>
): Array<string> {
  return jobsList.flatMap((job) => {
    const childJobsTargetAssetIds = job.childrenList.map(
      (childJob) => childJob.targetAssetId
    ) as Array<string>

    return [job.targetAssetId, ...childJobsTargetAssetIds]
  })
}
