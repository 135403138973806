/* eslint-disable import/no-extraneous-dependencies */
import { convertMillisecondsWithLabel } from '@features/DashboardV4/widgets-with-scale'
import { TimeFormatConstants } from '@lib/constants'
import { Nullable } from '@lib/engine-types'
import { TimeHelper } from '@lib/helpers'
import LangHelper from '@lib/helpers/lang.helper'
import { VolumeGenericBackupKind } from 'blues-corejs/dist/models/inventory.model/volume.model'
import type { InventoryVolumeOutput } from 'blues-corejs/dist/use_cases/list_inventory_volumes/types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

function convertDateToString(date: Nullable<Date>) {
  if (!date) {
    return ''
  }

  return `${dayjs(date)
    .utc()
    .format(TimeFormatConstants.DATETIME_FORMAT)} (UTC)`
}

function convertRetentionToString(milliseconds: Nullable<number>) {
  if (!milliseconds) {
    return ''
  }

  const formattedRetention = convertMillisecondsWithLabel(milliseconds)

  const { label, value } = formattedRetention

  return value ? `${value} ${label}` : ''
}

function convertPoliciesListToString(
  policies: Array<string> | Nullable<VolumeGenericBackupKind>
) {
  let policyCoverageLabel = ''

  if (Array.isArray(policies)) {
    policyCoverageLabel = policies.join(', ')
  } else if (policies === VolumeGenericBackupKind.IMPORTED_AWS_BACKUP) {
    policyCoverageLabel = 'AWS Backup'
  } else if (policies === VolumeGenericBackupKind.AWS_SNAPSHOT) {
    policyCoverageLabel = 'EBS Snapshot'
  }

  return policyCoverageLabel
}

function prepareEbsDataForCsv(volumeList: Array<InventoryVolumeOutput>) {
  return volumeList.map((volume) => {
    return {
      volume: volume.volumeName
        ? `${volume.volumeName} (${volume.volumeId})`
        : volume.volumeId,
      // To prevent converting into number column
      account: `${volume.accountId}‎`,
      region: LangHelper.getAwsRegionSingleTranslation(volume.region),
      lastBackup: convertDateToString(volume.lastBackup?.timestamp),
      lastScan: convertDateToString(volume.lastScan),
      policyCoverage: convertPoliciesListToString(volume.policies),
      rpo: volume.rpo ? TimeHelper.timeAgo(volume.rpo).toString() : '',
      rpoTarget: volume.sla
        ? TimeHelper.timeAgo(volume.sla * 1000).toString()
        : '',
      retention: convertRetentionToString(volume.maxBackupRetention),
    }
  })
}

const getEbsCvsTableHeaders = (showRPO: boolean, showRetention: boolean) => [
  {
    label: 'Volume',
    key: 'volume',
  },
  {
    label: 'Account',
    key: 'account',
  },
  {
    label: 'Region',
    key: 'region',
  },
  {
    label: 'Last Backup',
    key: 'lastBackup',
  },
  ...(showRPO
    ? [
        {
          label: 'RPO',
          key: 'rpo',
        },
        {
          label: 'RPO Target',
          key: 'rpoTarget',
        },
      ]
    : []),
  ...(showRetention
    ? [
        {
          label: 'Retention',
          key: 'retention',
        },
      ]
    : []),
  {
    label: 'Last Scan',
    key: 'lastScan',
  },
  {
    label: 'Policy coverage',
    key: 'policyCoverage',
  },
]

export { prepareEbsDataForCsv, getEbsCvsTableHeaders }
