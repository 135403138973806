/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { ScansRun } from 'blues-corejs/dist/models'
import { DateTimeTooltip } from '@components-simple/date-time-tooltip'
import { CellWithButtonLink } from '@components/table'

interface Props {
  scans: ScansRun
  onTimestampClick: () => void
}

function getLatestScanTimestamp(scans: ScansRun) {
  return [
    ...scans.filesystemChecksList,
    ...scans.malwareScansList,
    ...scans.ransomwareScansList,
  ].reduce((acc: Date, scan) => {
    if (scan.createdAt && scan.createdAt > acc) {
      return scan.completedAt
    }
    return acc
  }, new Date(0))
}

function AssetItemScanCompletionTimeCell({ scans, onTimestampClick }: Props) {
  const latestScanTimestamp = getLatestScanTimestamp(scans)

  return (
    <CellWithButtonLink onClick={onTimestampClick}>
      <DateTimeTooltip
        placement="top-start"
        date={latestScanTimestamp}
        direction="row"
      />
    </CellWithButtonLink>
  )
}

export default AssetItemScanCompletionTimeCell
