import { create } from 'zustand'

interface CreateRuleDrawerActions {
  openDrawer: (isUpdate?: boolean) => void
  closeDrawer: () => void
  toggleDrawer: () => void
}

export interface CreateRuleDrawerState {
  isOpen: boolean
  isUpdate: boolean
}

export type CreateRuleDrawerStateStore = CreateRuleDrawerActions &
  CreateRuleDrawerState

const INITIAL_STATE: CreateRuleDrawerState = {
  isOpen: false,
  isUpdate: false,
}

const useCreateRuleDrawerStore = create<CreateRuleDrawerStateStore>((set) => ({
  ...INITIAL_STATE,
  openDrawer: (isUpdate?: boolean) =>
    set({
      isOpen: true,
      isUpdate: !!isUpdate,
    }),
  closeDrawer: () =>
    set({
      isOpen: false,
      isUpdate: false,
    }),
  toggleDrawer: () =>
    set((state) => ({
      isOpen: !state.isOpen,
    })),
}))

export const openDrawerCreateRuleDrawerStore = () => {
  useCreateRuleDrawerStore.getState().openDrawer()
}

export const useCreateRuleDrawerState = () =>
  useCreateRuleDrawerStore((state) => ({
    isOpen: state.isOpen,
    isUpdate: state.isUpdate,
  }))

export const useCreateRuleDrawerActions = () =>
  useCreateRuleDrawerStore((state) => ({
    openDrawer: state.openDrawer,
    isUpdate: state.isUpdate,
    closeDrawer: state.closeDrawer,
    toggleDrawer: state.toggleDrawer,
  }))
