import React, { memo, ReactNode, useEffect, useState } from 'react'
import ContentBlock from '@components-composite/content-block/ContentBlock'
import ControlledVITable from '@tables/ControlledVITable'
import clsx from 'clsx'
import AssetsVITable from '@tables/AssetsVITable'
import { EngineCallback, VIMatrix } from '@lib/engine-types'
import BaseModel from '@lib/models/base-model/base.model'
import PaginationFactory from '@lib/factories/pagination.factory'
import PaginationModel from '@lib/models/pagination.model'
import ValueInterface from '@lib/interfaces/value.interface'
import CheckboxControlledVITable from '@tables/CheckboxControlledVITable'
import useLocalCheckboxTable from '@lib/hooks/tables/useLocalCheckboxTable'
import ObjHelper from '@lib/helpers/obj.helper'
import { Tooltip } from '@mui/material'
import TextConstant from '@lib/constants/text.constant'

export const ASSETS_VI_SKIP_TEMPLATE = () => [
  1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1,
]

const EMPTY_TABLE_MESSAGE = 'There are no assets yet'

interface Props {
  data: Array<ValueInterface>
  finalLoading: boolean
  loading: boolean
  onRowChange: EngineCallback<any>
  onHeadChangeAssets: EngineCallback<any>
  onPaginationChange: EngineCallback<PaginationModel>
  forceTemplate: Array<number>
  additionalData?: ValueInterface
  onAdditionalDataChange?: EngineCallback<any>
  tabSelected?: number
  children?: ReactNode
  isNotSupportedVersionCCs?: boolean
}

function TableTabs({
  data,
  finalLoading,
  onRowChange,
  forceTemplate,
  onPaginationChange,
  onHeadChangeAssets,
  additionalData,
  onAdditionalDataChange,
  tabSelected = 0,
  children = null,
  isNotSupportedVersionCCs = false,
}: Props) {
  const [currentStep, setCurrentStep] = useState(tabSelected)

  const onButtonHandler = (index: number, total: number) => {
    setCurrentStep(index)
    onPaginationChange(
      BaseModel.clone(PaginationFactory.buildEmpty(), {
        total: total,
      })
    )
  }

  const {
    currentData,
    currentSelectedData,
    globalCheckboxState,
    setGlobalCheckbox,
    setCheckbox,
  } = useLocalCheckboxTable(additionalData?.matrix || [])

  const [baseSelectedData, setBaseSelectedData] = useState<VIMatrix>([])

  const isShowAdditionalData =
    additionalData && additionalData.value && Number(additionalData.value) > 0

  useEffect(() => {
    // if do not check - it provokes re-rendering and recalculating the data on the
    // top component, they will have different references with the same data and
    // it leads to the infinite re-rendering
    if (ObjHelper.isNotEqual(currentSelectedData, baseSelectedData)) {
      setBaseSelectedData(currentSelectedData)
      onAdditionalDataChange?.(currentSelectedData)
    }
  }, [currentSelectedData])

  useEffect(() => {
    const stepData = data[currentStep]
    if (stepData?.disabled) {
      //find first not disabled step to set as current step
      const enabledStepIndex = data.findIndex((el) => !el.disabled)
      if (enabledStepIndex !== -1) {
        setCurrentStep(enabledStepIndex)
        onPaginationChange(
          BaseModel.clone(PaginationFactory.buildEmpty(), {
            total: Number(data[enabledStepIndex]?.value),
          })
        )
      }
    }
  }, [JSON.stringify(data)])

  return (
    <div className="wrap-1657032208790 jsTableTabs">
      <div className="widgetWrap">
        <div className="typeStepperWrap">
          {data.map((step, index) => {
            return (
              <React.Fragment key={index}>
                {isNotSupportedVersionCCs && step.name === 'S3' ? (
                  <Tooltip
                    title={TextConstant.WARNING_S3_MESSAGE}
                    placement="top"
                  >
                    <span>
                      <button
                        key={index}
                        disabled={finalLoading}
                        type="button"
                        className={clsx('typeStepper jsTypeStepper ', {
                          active: index === currentStep,
                          disabled:
                            isNotSupportedVersionCCs && step.name === 'S3',
                        })}
                        onClick={() =>
                          onButtonHandler(index, Number(step.value))
                        }
                      >
                        {step.name}
                        <span
                          className={clsx('typeStepperCount', {
                            disabled: finalLoading,
                          })}
                        >
                          {step.value ?? 0}
                        </span>
                      </button>
                    </span>
                  </Tooltip>
                ) : (
                  <button
                    key={index}
                    disabled={finalLoading}
                    type="button"
                    className={clsx('typeStepper jsTypeStepper ', {
                      active: index === currentStep,
                      disabled: step.disabled,
                    })}
                    onClick={() => onButtonHandler(index, Number(step.value))}
                  >
                    {step.name}
                    <span
                      className={clsx('typeStepperCount', {
                        disabled: finalLoading,
                      })}
                    >
                      {step.value ?? 0}
                    </span>
                  </button>
                )}
              </React.Fragment>
            )
          })}
          {isShowAdditionalData ? (
            <button
              key={data.length + 1}
              disabled={finalLoading}
              type="button"
              className={clsx('typeStepper', {
                active: data.length + 1 === currentStep,
                disabled: additionalData.disabled,
              })}
              onClick={() =>
                onButtonHandler(data.length + 1, Number(additionalData.value))
              }
            >
              {additionalData.name}
              <span
                className={clsx('typeStepperCount', {
                  disabled: finalLoading,
                })}
              >
                {additionalData.value ?? 777}
              </span>
            </button>
          ) : null}
        </div>
        <div className="111">{children}</div>
        {data.map((step, index) => (
          <div key={index + step.name}>
            {index === currentStep && (
              <ContentBlock
                key={index + String(step.value)}
                data={step.matrix ?? []}
                loading={finalLoading}
                noDataMessage={EMPTY_TABLE_MESSAGE}
                EmptyBlock={
                  <ControlledVITable
                    key={index + String(step.value)}
                    head={step.options ?? []}
                    rows={[[{ name: EMPTY_TABLE_MESSAGE }]]}
                    onMenuClick={() => null}
                    className="tableVIUncontrolled tableUpgrade emptyTableUpgrade "
                  />
                }
              >
                <AssetsVITable
                  className={clsx('tableUpgrade', {
                    controlsDisabled: finalLoading,
                  })}
                  skipTemplate={ASSETS_VI_SKIP_TEMPLATE()}
                  forceTemplate={forceTemplate}
                  head={step.options ?? []}
                  rows={step.matrix ?? []}
                  onRowChange={onRowChange}
                  onHeadChange={(e) => onHeadChangeAssets(e, step.name)}
                  key={index + step.name}
                />
              </ContentBlock>
            )}
          </div>
        ))}
        {isShowAdditionalData ? (
          <div key={data.length + 1 + additionalData.name}>
            {data.length + 1 === currentStep && (
              <ContentBlock
                key={data.length + 1 + String(additionalData.value)}
                data={additionalData.matrix ?? []}
                loading={finalLoading}
                noDataMessage={EMPTY_TABLE_MESSAGE}
              >
                <CheckboxControlledVITable
                  className={clsx('tableVIUncontrolled tableUpgrade', {
                    controlsDisabled: finalLoading,
                  })}
                  globalCheckboxState={globalCheckboxState}
                  onCheckboxGlobalChange={setGlobalCheckbox}
                  onCheckboxRowChange={setCheckbox}
                  head={additionalData.options ?? []}
                  rows={currentData}
                  onMenuClick={() => null}
                />
              </ContentBlock>
            )}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default memo(TableTabs)
