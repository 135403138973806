/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import { TenantJobStatus } from 'blues-corejs/dist/models/jobs/tenant/job/types'
import { StyledCheckbox, StyledFormControlLabel } from './styles'
import { getHumanReadableTenantJobStatus } from '@features/jobs/tenant-jobs/helpers'

interface Props {
  status: TenantJobStatus
  onChange: (checked: boolean, status: TenantJobStatus) => void
  isChecked: boolean
}

export function TenantJobStatusCheckbox({
  status,
  onChange,
  isChecked,
}: Props) {
  const handleCheckboxChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onChange(checked, status)
    },
    [status, onChange]
  )

  return (
    <StyledFormControlLabel
      label={getHumanReadableTenantJobStatus(status)}
      control={
        <StyledCheckbox checked={isChecked} onChange={handleCheckboxChange} />
      }
    />
  )
}
