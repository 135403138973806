/* eslint-disable import/no-extraneous-dependencies */
import {
  File,
  AssetItem,
  Disk,
  EfsItem,
  LocalVolume,
  S3Item,
  Stream,
  EFS,
  GenericHost,
  S3Bucket,
} from 'blues-corejs/dist'
import SystemHelper from '@lib/helpers/system.helper'

type Asset = EFS | S3Bucket | GenericHost

export function computeAssetItemName(assetItem: AssetItem | Asset) {
  if (assetItem instanceof GenericHost) {
    return assetItem.hostname
  }

  if (assetItem instanceof S3Bucket) {
    return assetItem.awsId
  }

  if (assetItem instanceof EFS) {
    return assetItem.name
  }

  if (assetItem instanceof File) {
    return assetItem.path
  }

  if (assetItem instanceof Stream) {
    return assetItem.name
  }

  if (assetItem instanceof LocalVolume) {
    return assetItem.deviceName
  }

  if (assetItem instanceof EfsItem) {
    return assetItem.name
  }

  if (assetItem instanceof S3Item) {
    return assetItem.selectors.map((selector) => selector.value).join(', ')
  }

  if (assetItem instanceof Disk) {
    return assetItem.deviceName
  }

  SystemHelper.sendSentryIfProd(
    `computeAssetItemName: Unknown asset item type: ${assetItem.id}`
  )
  return ''
}
