import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { OvaDashboardState, OvaDashboardStoreActions } from './types'
import { getOvaDashboardStoreInitialState } from './consts'
import { subscribeWithSelector } from 'zustand/middleware'
import { useEffect } from 'react'
import { useOvaServerPossibleFiltersStoreActions } from '@lib/zustand/ova-servers-stores'
import { ObjHelper } from '@lib/helpers'

export const useOvaDashboardStore = create<
  OvaDashboardState & { actions: OvaDashboardStoreActions }
>()(
  subscribeWithSelector((set) => ({
    ...getOvaDashboardStoreInitialState(),
    actions: {
      setOvaAccountIds: (value) =>
        set({
          ovaAccountsList: value,
        }),
      // TODO: convert string to enum values
      // @ts-ignore
      setBackupProviders: (value) => {
        set({ ovaBackupProvidersList: value })
      },
    },
  }))
)

export const getOvaDashboardState = useOvaDashboardStore.getState

export const useOvaDashboardStoreActions = () =>
  useOvaDashboardStore(useShallow((state) => state.actions))

export const useGetOvaAccountList = () =>
  useOvaDashboardStore(useShallow((state) => state.ovaAccountsList))

export const useGetOvaBackupProvidersList = () =>
  useOvaDashboardStore(useShallow((state) => state.ovaBackupProvidersList))

export const useOvaDashboardStoreSubscription = () => {
  const { fetchPossibleOvaServersFilters } =
    useOvaServerPossibleFiltersStoreActions()

  useEffect(() => {
    const unsubscribeFn = useOvaDashboardStore.subscribe(
      (state) => ({
        ovaAccountsList: state.ovaAccountsList,
        ovaBackupProvidersList: state.ovaBackupProvidersList,
      }),
      ({ ovaAccountsList, ovaBackupProvidersList }) =>
        fetchPossibleOvaServersFilters({
          ovaAccountIdsList: ovaAccountsList,
          backupProvidersList: ovaBackupProvidersList,
        }),
      { equalityFn: ObjHelper.isEqual }
    )

    return unsubscribeFn
  }, [])
}
