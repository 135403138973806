/* eslint-disable import/no-extraneous-dependencies */
import { createDisplayColumn, RowHeight } from '@components/table'
import { AssetItem, EFS, GenericHost, S3Bucket } from 'blues-corejs/dist/models'
import React from 'react'
import { AssetItemCell } from './asset-item-icon-cell'
import { AssetItemPath } from './asset-item-path-cell'
import { TableDefinition } from './use-process-data-for-table'
import { ThreatsCell } from './threats-icon-cell/threats-icon-cell'
import { ThreatCountCell } from './threat-count-cell'

type Asset = S3Bucket | EFS | GenericHost

export function getAssetItemScansColumnDefinitions({
  asset,
  onRowClick,
}: {
  asset: Asset | AssetItem
  onRowClick: (asset: Asset | AssetItem) => void
}) {
  const shouldDisplayThreatsBlock = !(asset instanceof GenericHost)

  return [
    createDisplayColumn<TableDefinition>({
      id: 'threats-count',
      enableSorting: false,
      meta: {
        width: '40',
      },
      cell: ({ row: { original } }) => {
        return <ThreatCountCell threats={original.threats} />
      },
    }),
    createDisplayColumn<TableDefinition>({
      id: 'scan-statuses',
      enableSorting: false,
      header: 'Risks',
      meta: {
        width: '60',
        height: RowHeight.SMALL,
        hidden: !shouldDisplayThreatsBlock,
      },
      cell: ({ row: { original } }) => {
        return <ThreatsCell asset={original.asset} scans={original.scans} />
      },
    }),
    createDisplayColumn<TableDefinition>({
      id: 'asset-icon',
      enableSorting: false,
      meta: {
        width: '50',
        height: RowHeight.SMALL,
      },
      cell: ({ row: { original } }) => {
        return <AssetItemCell asset={original.asset} />
      },
    }),
    createDisplayColumn<TableDefinition>({
      id: 'asset-paths',
      enableSorting: false,
      header: 'Paths',
      cell: ({ row: { original } }) => {
        const handleAssetPathClick = () => {
          onRowClick(original.asset)
        }

        return (
          <AssetItemPath
            asset={original.asset}
            onAssetPathClick={handleAssetPathClick}
          />
        )
      },
    }),
  ]
}
