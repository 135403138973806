/* eslint-disable import/no-extraneous-dependencies */
import {
  AssetsEc2TableDataAttrs,
  Ec2TableRow,
} from 'ui-v2/src/lib/models/assets'
import { EBS, EC2, Threat } from 'blues-corejs/dist'
import { AssetsEc2Base } from './ec2-base'
import { BackupForAsset } from 'ui-v2/src/lib/models/backups'

export class AssetsEc2TableData extends AssetsEc2Base {
  protected readonly instances: Array<EC2>

  protected readonly volumes: Map<string, EBS>

  protected readonly threats: Array<Threat>

  readonly #lastCleanBackups: Record<string, BackupForAsset>

  constructor(parameters: AssetsEc2TableDataAttrs) {
    super()
    this.instances = parameters.instances
    this.volumes = parameters.volumes.reduce((map, volume) => {
      map.set(volume.id, volume)
      return map
    }, new Map<string, EBS>())
    this.threats = parameters.threats
    this.#lastCleanBackups = parameters.lastCleanBackups
  }

  getEc2TableData(): Array<Ec2TableRow> {
    return this.instances.map(
      (instance) =>
        new Ec2TableRow({
          id: instance.id,
          name: instance.name,
          awsId: instance.awsId,
          state: this.convertEc2State(instance.state),
          backupPolicies: instance.backupPolicies,
          isUnhealthy: this.isUnhealthyInstance(instance),
          createdAt: instance.createdAt,
          lastKnownCleanBackup:
            this.#lastCleanBackups[instance.id]?.elastioRps?.createdAt,
        })
    )
  }
}
