import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { useServersDrilldownLink } from '@components-complex/dashboard-pages-v3/ova-server-table'
import { STATUS_FILTER_DRILLDOWN_QUERY_KEY } from '@components-complex/dashboard-pages-v3/ova-server-table/toolbar/consts'
import { OvaServerStatus } from '@lib/clients/assets/list-ova-servers/list-ova-servers-filter'

interface Props {
  infectedServersCount: number
  isLoading: boolean
}

const Container = ({ children }: { children: React.ReactNode }) => {
  const { handleRedirectWithDrilldown } = useServersDrilldownLink({
    queryKey: STATUS_FILTER_DRILLDOWN_QUERY_KEY,
    queryValue: OvaServerStatus.INFECTED.toString(),
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      flexBasis="50%"
      sx={{ cursor: 'pointer' }}
      onClick={handleRedirectWithDrilldown}
    >
      {children}
    </Box>
  )
}

function InfectedServersSkeleton() {
  return (
    <Container>
      <Skeleton>
        <Typography fontWeight="600" fontSize="22px">
          26
        </Typography>
      </Skeleton>
      <Skeleton>
        <Typography fontWeight="400" fontSize="16px">
          Infected
        </Typography>
      </Skeleton>
    </Container>
  )
}

export function InfectedServers({ infectedServersCount, isLoading }: Props) {
  if (isLoading) {
    return <InfectedServersSkeleton />
  }

  const infectedServersLabelColor =
    infectedServersCount > 0 ? 'var(--red500)' : 'var(--grey900)'

  return (
    <Container>
      <Typography
        fontWeight="600"
        fontSize="22px"
        color={infectedServersLabelColor}
      >
        {infectedServersCount}
      </Typography>
      <Typography fontWeight="400" fontSize="16px">
        Infected
      </Typography>
    </Container>
  )
}
