import { Box } from '@mui/material'
import React from 'react'
import { WidgetHeader } from '../shared'
import { Container, StyledDivider } from './styles'
import { InfectedFiles, CleanFiles, FileScans } from './components'
import { useFetchFilesScannedScanned } from './use-fetch-files-scanned'

/**
 * Files Scanned widget
 *
 * [Link to Figma](https://www.figma.com/file/X1nP5ZMRru7EKB49A2Itlu/Elastio-after-Re%3Ainvent?type=design&node-id=19346-168575&mode=design&t=2cXpzW2OrwYIdoor-4)
 */
export default function FilesScanned() {
  const {
    isLoading,
    cleanFilesCount,
    infectedFilesCount,
    totalFilesScannedCount,
  } = useFetchFilesScannedScanned()

  return (
    <Container>
      <WidgetHeader widgetTitle="Files Scanned" />
      <FileScans
        isLoading={isLoading}
        fileScansCount={totalFilesScannedCount}
      />
      <StyledDivider />
      <Box display="flex">
        <InfectedFiles
          isLoading={isLoading}
          infectedFilesCount={infectedFilesCount}
        />
        <CleanFiles isLoading={isLoading} cleanFilesCount={cleanFilesCount} />
      </Box>
    </Container>
  )
}
