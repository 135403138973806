/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import { Nullable } from '@lib/engine-types'
import {
  Asset,
  AssetWithRelatedAssets,
} from 'blues-corejs/dist/use_cases/list_assets_for_policy_coverage/types'
import { useModal } from '@lib/hooks/useModal'
import { AddSpecificPathsModalInputData } from '@components-composite/modals/AddSpecificPathsModal'
import FormAddSpecificPathsInterface from '@lib/interfaces/form/form-add-specific-paths.interface'
import Modal from '@lib/constants/modal.constant'
import {
  addEfsSelectedAllPaths,
  addEfsSelectedSpecificPaths,
  removeEfsSelectedAllPaths,
} from '@store/actions/assets-select.action'
import { SelectAllPathsInterface } from '@lib/interfaces/assets-s3-select.interface'
import SystemHelper from '@lib/helpers/system.helper'
import { DeletePathInterface } from '@tables/assets-with-paths-table/AssetsWithPathsTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEfsSelectedAssetsSelect,
  getEfsSelectedSpecificPaths,
} from '@store/selectors/assets-select.selector'
import { ExpandedState } from '@tanstack/react-table'
import { RowSelectionState } from '@tanstack/table-core'
import {
  findAssetById,
  findSelectedAssetIndex,
} from '@features/policies/policy/policy-tables'
import { EFS } from '@lib/models/assets'

interface Props {
  efsAssets: Array<AssetWithRelatedAssets<EFS>>
}

export function useEfsSelectPaths({ efsAssets }: Props) {
  const dispatch = useDispatch()
  const efsSelectedAssets = useSelector(getEfsSelectedAssetsSelect)
  const selectedSpecificPaths = useSelector(getEfsSelectedSpecificPaths)

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const [expandedRows, setExpandedRow] = useState<ExpandedState>({})

  const [currentEfs, setCurrentEfs] =
    useState<Nullable<AssetWithRelatedAssets<Asset>>>(null)
  const [currentRowIndex, setCurrentRowIndex] = useState<Nullable<number>>(null)

  useEffect(() => {
    if (!efsSelectedAssets.length) {
      return
    }
    const selectedEfsRows: RowSelectionState = {}
    efsSelectedAssets.forEach((efsSelected) => {
      const selectedIndex = findSelectedAssetIndex({
        assets: efsAssets,
        selectedAsset: efsSelected,
      })

      if (selectedIndex > -1) {
        selectedEfsRows[selectedIndex] = true
      }
    })
    setSelectedRows(selectedEfsRows)
  }, [efsSelectedAssets, efsAssets])

  useEffect(() => {
    if (!selectedSpecificPaths.length) {
      return
    }
    const expandedEfsRows: ExpandedState = {}
    selectedSpecificPaths.forEach((selectedPath) => {
      const expandedIndex = findSelectedAssetIndex({
        assets: efsAssets,
        selectedAsset: selectedPath.asset,
      })

      if (expandedIndex > -1) {
        expandedEfsRows[expandedIndex] = true
      }
    })
    setExpandedRow(expandedEfsRows)
  }, [selectedSpecificPaths, efsAssets])

  const onSelectAllPaths = (params: SelectAllPathsInterface) => {
    const foundAsset = findAssetById({
      assets: efsAssets,
      assetId: params.assetId,
    })
    if (!foundAsset) {
      SystemHelper.throwErrorInLocalEnv(
        'Cannot find an asset in the assets selector'
      )
      return
    }
    let actionToRun = addEfsSelectedAllPaths
    if (params.cbValue) {
      actionToRun = removeEfsSelectedAllPaths
    }
    dispatch(actionToRun(foundAsset))
    return
  }

  const { openModal: openPathsAddModal, modalProps: pathsAddModalProps } =
    useModal<AddSpecificPathsModalInputData, FormAddSpecificPathsInterface>(
      Modal.addSpecificPathsEfs,
      (form) => {
        const { pathsList } = form
        if (currentEfs) {
          setSelectedRows((prevState) => ({
            ...prevState,
            [String(currentRowIndex)]: true,
          }))
          setExpandedRow((prevState) => {
            if (typeof prevState === 'boolean') {
              return true
            }

            return {
              ...prevState,
              [String(currentRowIndex)]: true,
            }
          })

          onSelectAllPaths({
            assetId: currentEfs.asset.id,
            cbValue: true,
          })
          dispatch(
            addEfsSelectedSpecificPaths({
              asset: currentEfs,
              selectedPaths: pathsList,
            })
          )
        }
      }
    )

  const handleSelectPathsClick = (assetId: string, rowIndex: number) => {
    const foundAsset = findAssetById({
      assets: efsAssets,
      assetId: assetId,
    })
    setCurrentEfs(foundAsset)
    setCurrentRowIndex(rowIndex)
    const specificPathsForCurrentAsset = selectedSpecificPaths?.find(
      (item) => item.asset.asset?.id === assetId
    )

    const modalData: AddSpecificPathsModalInputData = {
      pathSelector: 'path',
      pathsList: specificPathsForCurrentAsset
        ? specificPathsForCurrentAsset.selectedPaths
        : {},
    }

    openPathsAddModal(modalData)
  }

  const handleDeletePath = (deleteProps: DeletePathInterface) => {
    const foundAsset = findAssetById({
      assets: efsAssets,
      assetId: deleteProps.assetId,
    })
    const selectedSpecificPathsForAsset = selectedSpecificPaths.find(
      (item) => item.asset.asset?.id === deleteProps.assetId
    )
    if (selectedSpecificPathsForAsset !== undefined) {
      const result = selectedSpecificPathsForAsset.selectedPaths
      Object.entries(selectedSpecificPathsForAsset.selectedPaths).forEach(
        ([key, value]: [string, Array<string>]) => {
          if (key === deleteProps.selector) {
            const values = value.filter((v) => v !== deleteProps.deletedPath)
            if (!values || values.length === 0) {
              delete result[key]
            } else {
              result[key] = values
            }
          }
        }
      )
      if (foundAsset) {
        dispatch(
          addEfsSelectedSpecificPaths({
            asset: foundAsset,
            selectedPaths: result,
          })
        )
      }
    }
  }

  return {
    selectedRows,
    setSelectedRows,
    expandedRows,
    setExpandedRow,
    pathsAddModalProps,
    onSelectAllPaths,
    handleSelectPathsClick,
    handleDeletePath,
  }
}
