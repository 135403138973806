import { TenantJobsClient } from '@lib/clients'
import { DefinedDateRangeTuple } from '@lib/engine-types'
import TimeHelper from '@lib/helpers/time.helper'
import { TenantJobFiltersUI } from '@lib/interfaces/jobs'
import { SystemJobsFiltersStore } from '@features/jobs/tenant-jobs/system-jobs/stores/filters-store'

const tenantsJobsClient = new TenantJobsClient()

type SetMember<T> = T extends Set<infer E> ? E : never

function removeFilterFromSet<
  V extends SetMember<TenantJobFiltersUI[keyof TenantJobFiltersUI]>,
>(value: V, filters: TenantJobFiltersUI[keyof TenantJobFiltersUI]) {
  return new Set([...filters].filter((filter) => filter !== value))
}

function setFilters(
  selectedFilters: TenantJobFiltersUI,
  state: SystemJobsFiltersStore
) {
  return {
    selectedFilters: {
      ...state.selectedFilters,
      ...selectedFilters,
    },
  }
}

function removeFilter(
  key: keyof TenantJobFiltersUI,
  value: SetMember<TenantJobFiltersUI[keyof TenantJobFiltersUI]>,
  state: SystemJobsFiltersStore
) {
  return {
    selectedFilters: {
      ...state.selectedFilters,
      [key]: removeFilterFromSet(value, state.selectedFilters[key]),
    },
  }
}

async function fetchPossibleTenantJobFilters(
  timeRange: DefinedDateRangeTuple
): Promise<SystemJobsFiltersStore['selectedFilters']> {
  const possibleFilters = await tenantsJobsClient.getTenantJobFilters(
    TimeHelper.transformDateRangeForApi(timeRange)
  )

  return {
    kindsList: new Set(possibleFilters.kindsList),
    statusesList: new Set(possibleFilters.statusesList),
  }
}

export { setFilters, removeFilter, fetchPossibleTenantJobFilters }
