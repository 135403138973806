import React, { memo, useEffect } from 'react'
import ManageRolesTable from '@tables/ManageRolesTable'
import { useDispatch, useSelector } from 'react-redux'
import { requestPossibleRoles } from '@store/actions/user.action'
import PreloaderConstants from '@lib/constants/preloader.constant'
import PreloaderBlock from '@components-simple/preloaders/PreloaderBlock/PrelaoderBlock'
import ManageRolesOnChangeInterface from '@lib/interfaces/manage-roles-on-change.interface'
import StrHelper from '@lib/helpers/str.helper'
import ButtonGroupTabs from '@components-simple/button-group-tabs/ButtonGroupTabs'
import {
  canRoleForEditBeRemoved,
  canRoleForEditBeSubmitted,
  getCustomRolesNames,
  getCustomRolesTableData,
  getDefaultRolesNames,
  getDefaultRolesTableData,
  getEditColumnIndex,
  getScopesCategories,
} from '@store/selectors/management-roles.selector'
import {
  cancelRoleForEdit,
  deleteRole,
  setRoleForCreate,
  setRoleForEdit,
  submitRoleForEdit,
  updateRoleForEditScopes,
} from '@store/actions/management-roles.action'
import usePreloaderAny from '@lib/hooks/usePreloaderAny'
import PreloaderSmall from '@components-simple/preloaders/PreloaderSmall/PreloaderSmall'
import DialogModal from '@components-composite/modals/DialogModal'
import { useModal } from '@lib/hooks/useModal'
import Modal from '@lib/constants/modal.constant'
import useLocalModelPagination from '@lib/hooks/pagination/useLocalModelPagination'
import PaginationBlock from '@components-simple/pagination-block/PaginationBlock'
import DataHelper from '@lib/helpers/data.helper'
import { Button } from '@mui/material'

const TOGGLE_BUTTON_LABELS = ['Default Roles', 'Custom Roles']

function ManagementRoles() {
  const dispatch = useDispatch()
  const loading = usePreloaderAny([
    PreloaderConstants.REQUEST_POSSIBLE_ROLES,
    PreloaderConstants.UPDATE_ROLE,
    PreloaderConstants.DELETE_ROLE,
  ])

  useEffect(() => {
    dispatch(requestPossibleRoles())
  }, [])

  const defaultTableData = useSelector(getDefaultRolesTableData)
  const customTableData = useSelector(getCustomRolesTableData)
  const defaultRolesNames = useSelector(getDefaultRolesNames)
  const customRolesNames = useSelector(getCustomRolesNames)
  const scopesCategories = useSelector(getScopesCategories)
  const editColumnIndex = useSelector(getEditColumnIndex)
  const shouldShowRemoveButton = useSelector(canRoleForEditBeRemoved)
  const shouldEnableEditButton = useSelector(canRoleForEditBeSubmitted)

  // pagination
  const {
    paginatedData: paginatedCustomRolesNames,
    pagination,
    setPagination,
  } = useLocalModelPagination<Array<string>>(customRolesNames, 0, 6)
  const paginatedCustomTableData = DataHelper.rolesTableDataPagination(
    customTableData,
    customRolesNames.length,
    pagination.offset,
    pagination.limit
  )

  const onSetRoleForChanging = (roleName: string, index: number) => {
    dispatch(setRoleForEdit(roleName, index))
  }

  const onSetRoleForCreation = () => {
    dispatch(setRoleForCreate())
  }

  const onRolePermissionChange = (
    roleChangeData: ManageRolesOnChangeInterface
  ) => {
    dispatch(
      updateRoleForEditScopes(
        StrHelper.toScopeName(
          roleChangeData.categoryName,
          roleChangeData.permissionName
        ),
        roleChangeData.value
      )
    )
  }

  const onRoleSubmit = (roleName: string) => {
    dispatch(submitRoleForEdit(roleName))
  }

  const onRoleCancel = () => {
    dispatch(cancelRoleForEdit())
  }

  const { openModal, modalProps } = useModal<Array<string>>(
    Modal.deleteRole,
    () => {
      dispatch(deleteRole())
    }
  )

  const isPaginationDisabled = loading || editColumnIndex !== -1

  return (
    <div className="wrap-1617010229972">
      {loading && defaultRolesNames.length === 0 ? (
        <PreloaderBlock show />
      ) : (
        <div>
          <div className="relative">
            <PreloaderSmall left={214} top={6} show={loading} />
          </div>

          <ButtonGroupTabs labels={TOGGLE_BUTTON_LABELS}>
            <ManageRolesTable
              loading={loading}
              rows={defaultTableData}
              rolesNames={defaultRolesNames}
              scopesCategories={scopesCategories}
            />
            <div>
              {customRolesNames.length > 0 ? (
                <div className="jsCustom">
                  <PaginationBlock
                    disabled={isPaginationDisabled}
                    pagination={pagination}
                    onChange={setPagination}
                  />
                  <ManageRolesTable
                    loading={loading}
                    rows={paginatedCustomTableData}
                    rolesNames={paginatedCustomRolesNames}
                    scopesCategories={scopesCategories}
                    onPermissionChange={onRolePermissionChange}
                    onSetRoleForChanging={onSetRoleForChanging}
                    editColumnIndex={editColumnIndex}
                    onAddRole={onSetRoleForCreation}
                    onSubmit={onRoleSubmit}
                    onCancelAddRole={onRoleCancel}
                    onRemoveRole={openModal}
                    shouldShowRemoveButton={shouldShowRemoveButton}
                    shouldDisableSaveButton={!shouldEnableEditButton}
                    canEdit
                  />
                  <PaginationBlock
                    disabled={isPaginationDisabled}
                    pagination={pagination}
                    onChange={setPagination}
                  />
                </div>
              ) : (
                <div className="emptyResultBlock emptyResultBlockNotDelayed">
                  There are no custom roles yet
                  <div className="primaryButtonWrap">
                    <Button
                      className="jsCreateFromEmpty"
                      disabled={loading}
                      variant="contained"
                      color="primary"
                      onClick={onSetRoleForCreation}
                    >
                      Add New Role
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </ButtonGroupTabs>
        </div>
      )}

      <DialogModal
        description="You are going to remove this role,
        please confirm the action in order to proceed"
        {...modalProps}
      />
    </div>
  )
}

export default memo(ManagementRoles)
