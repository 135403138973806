import React from 'react'
import { ReportsRoutes } from './reports'
import { PathRouteProps } from 'react-router-dom'
import { MainTopBarLayout } from '@components/layouts'
import { ReportsSchedule } from '@features/reports/schedules'
import ReportDownload from '@features/reports/schedules/report-download/report-download'

export const reportsRoutes: Array<PathRouteProps> = [
  {
    path: ReportsRoutes.root,
    element: (
      <MainTopBarLayout title="Compliance Reports">
        <ReportsSchedule />
      </MainTopBarLayout>
    ),
  },
  {
    path: ReportsRoutes.reportIdPlaceholder,
    element: (
      <MainTopBarLayout title="Asset">
        <ReportDownload />
      </MainTopBarLayout>
    ),
  },
]
