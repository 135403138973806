/* eslint-disable import/no-extraneous-dependencies */
import { JobCloudConnector } from 'blues-corejs/dist/models'
import { CloudConnectorFiltersState } from '@features/jobs/cloud-connector-jobs/stores/filters-store'

export interface CloudConnectorJobsState {
  cloudConnectorJobsList: Array<JobCloudConnector>
  pageToken?: string
  loadingState: 'idle' | 'loading' | 'success'
}

export interface CloudConnectorJobsActions {
  resetCloudConnectorJobsState: () => void
  setCloudConnectorJobsList: (
    deploymentJobsList: Array<JobCloudConnector>
  ) => void
  fetchInitialCloudConnectorJobs: (
    filters?: Partial<CloudConnectorFiltersState>
  ) => void
  fetchPaginatedCloudConnectorJobs: () => Promise<void>
}

export type CloudConnectorJobsJobsStore = CloudConnectorJobsState & {
  actions: CloudConnectorJobsActions
}

export { fetchInitialJobsList, fetchJobsListViaPagination } from './actions'
export {
  useCloudConnectorJobsActions,
  useCloudConnectorJobsIsLoading,
  useCloudConnectorJobsList,
} from './selectors'
