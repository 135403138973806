import { useForm, Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { ButtonPrimary } from '@components/shared/button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  useAssetTagSelectionState,
  useAssetTagSelectionActions,
} from './asset-tag-selection-store'

import { useConfirmDeleteStore } from '@features/alerts/create-rule-drawer/confirm-delete-modal/use-confirm-delete-modal'
import { Asset } from '@features/alerts/create-rule-drawer/stepper/steps/infrastructure-scope/asset-tag-selection/asset-tag-selection-store'
import {
  Divider,
  Label,
  RadioGroupController,
} from '@features/alerts/create-rule-drawer/shared'

import React from 'react'
import { tagsOperatorOptions, AND } from './consts'
import { defaultValues } from './default-values'
import { FORM_FIELDS } from './form-field-names'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TagsOperator } from 'blues-corejs/dist/models/notifications/rule/criteria'

interface FormInputs {
  tagKey: string
  tagValue: string
  tagsOperator: TagsOperator
}

function AssetList() {
  const assets = useAssetTagSelectionState()
  const { addAsset, removeAsset } = useAssetTagSelectionActions()
  const { setOpen } = useConfirmDeleteStore()
  const { control, handleSubmit, reset } = useForm<FormInputs>({
    defaultValues: {
      [FORM_FIELDS.TAG_KEY]: defaultValues.tagKey,
      [FORM_FIELDS.TAG_VALUE]: defaultValues.tagValue,
      [FORM_FIELDS.TAGS_OPERATOR]: defaultValues.tagsOperator,
    },
  })

  const onSubmit = (data: FormInputs) => {
    addAsset(data)
    reset()
  }

  const handleAssetRemove = (asset: Asset) => {
    setOpen({
      isOpen: true,
      modalText: `Are you sure you want to delete the tag "${asset.tagKey}: ${asset.tagValue}"?`,
      confirmCallback: () => {
        removeAsset(asset.id)
      },
    })
  }

  return (
    <>
      <Divider />
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontSize: '14px',
          fontWeight: '600',
        }}
      >
        Select A Subset Of Assets For These Accounts (Optional)
      </Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <Label>Key:Value Relationship</Label>
        <RadioGroupController
          name={FORM_FIELDS.TAGS_OPERATOR}
          defaultValue={AND}
          labelOptions={tagsOperatorOptions}
        />
      </Stack>

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          gap: 2,
          mb: 3,
          alignItems: 'center',
        }}
      >
        <Controller
          name={FORM_FIELDS.TAG_KEY}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Tag Key"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ flex: 1 }}
            />
          )}
        />

        <Controller
          name={FORM_FIELDS.TAG_VALUE}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Tag Value"
              size="small"
              variant="outlined"
              fullWidth
              sx={{ flex: 1 }}
            />
          )}
        />

        <ButtonPrimary type="submit" variant="contained" size="medium">
          Add
        </ButtonPrimary>
      </Box>

      <List
        sx={{
          width: '100%',
          fontWeight: 400,
          fontSize: 14,
        }}
      >
        {assets.map((asset) => (
          <ListItem
            key={asset.id}
            secondaryAction={
              <IconButton edge="end" onClick={() => handleAssetRemove(asset)}>
                <DeleteIcon />
              </IconButton>
            }
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              py: 1,
            }}
          >
            <ListItemText
              primary={asset.tagKey}
              sx={{ flex: 1 }}
              disableTypography
            />
            <ListItemText
              primary={asset.tagValue}
              sx={{ flex: 1 }}
              disableTypography
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default AssetList
