import { useGrpcClient } from 'ui-v2/src/hooks/grpc-client'
import { PechkinClient } from 'ui-v2/src/lib/clients'
import { ScheduleScanAsset } from 'ui-v2/src/lib/models/assets'
import { useMutation } from '@tanstack/react-query'

export function useScheduleScansMutation() {
  const pechkinClient = useGrpcClient(PechkinClient)

  return useMutation({
    mutationFn: async (assetsList: Array<ScheduleScanAsset>) => {
      if (!pechkinClient) {
        throw new Error('Pechkin client not initialized')
      }
      return pechkinClient.scheduleAssetScans(assetsList)
    },
  })
}
